import { z } from 'zod';
import { SupportedSocialMediaType } from '@/shared/types';
import { SocialAccountType, XhsPostType } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
import { YtTagRankingFilterSchema, YtTagRankingSearchSchema } from '../ProfilePostsList';
import { InfluencerPromotionMethodsType } from './types';

export const InfluencerProfileFilterSchema = z.object({
  id: z.string().optional(),
  lastUpdated: z.string().optional(),
  sm: z
    .nativeEnum(SupportedSocialMediaType)
    .or(z.nativeEnum(InfluencerPromotionMethodsType))
    .or(z.nativeEnum(SocialAccountType))
    .optional(),
  xhsPostType: z.nativeEnum(XhsPostType).optional().default(XhsPostType.NORMAL)
});

export const InfluencerAccountSearchSchema = z
  .object({
    filter: SearchFilterDefaults.filter(InfluencerProfileFilterSchema.merge(YtTagRankingFilterSchema), {
      xhsPostType: XhsPostType.NORMAL,
      endDate: '',
      startDate: ''
    })
  })
  .merge(YtTagRankingSearchSchema.pick({ sort: true, limit: true, page: true }));

export type InfluencerAccountSearchSchemaType = z.infer<typeof InfluencerAccountSearchSchema>;
