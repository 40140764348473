import { useTranslation } from 'react-i18next';
import { useUserPermissions, useUserRoles } from '@/auth';
import { Tabs } from '@/shared/atoms';

export interface TalentAgencyTabsProps {
  talentAgencyId: number;
}

export const TalentAgencyTabs = ({ talentAgencyId }: TalentAgencyTabsProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { hideUserTab } = useUserPermissions();

  return (
    <Tabs
      options={[
        {
          end: true,
          label: t('Talent Agency'),
          to: '/talent-agency/$id',
          params: { id: talentAgencyId.toString() }
        },
        {
          label: t('Business Associates'),
          hidden: !isAdminStaff,
          to: '/talent-agency/$id/business',
          params: { id: talentAgencyId.toString() }
        },
        {
          end: true,
          label: t('User'),
          hidden: hideUserTab,
          to: '/talent-agency/$id/users',
          params: { id: talentAgencyId.toString() }
        }
      ]}
    />
  );
};
