import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonSubmit, FormStyle } from '@/shared/atoms';
import { DisabledFields } from '@/shared/types';
import { PostsPrice, InternalMemo, NoticeSignUpUrl, NoticeConnectUrl } from '../shared';
import { InfluencerAccount, SocialAccounts } from './formSections';
import { TalentInfluencerFormValues, TalentInfluencerFormKeys } from './schemaTypes';

export const Template = () => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<TalentInfluencerFormValues>();

  const values = watch();
  const influencerId = values?.id;
  const isEditMode = !!influencerId;
  // It's just simple so no need to create util for disabledFields
  const disabledFields: DisabledFields<TalentInfluencerFormKeys> = {
    countryId: isEditMode,
    isProAccount: true
  };

  return (
    <div>
      {isEditMode ? <NoticeConnectUrl /> : <NoticeSignUpUrl />}

      <div css={{ display: 'flex', gap: '24px' }}>
        <FormStyle.Wrapper css={{ flex: 1 }} hasBorder>
          <div css={{ padding: '24px' }}>
            <SocialAccounts influencerId={influencerId} />
            <InfluencerAccount disabledFields={disabledFields} />
          </div>

          <FormStyle.ButtonSubmitWrapper>
            <ButtonSubmit
              title={t('Button.Save')}
              loading={{ status: isSubmitting, title: t('Button.Saving') }}
              css={{ minWidth: '146px', marginLeft: 'auto', textTransform: 'uppercase' }}
            />
          </FormStyle.ButtonSubmitWrapper>
        </FormStyle.Wrapper>

        <div css={{ width: '328px' }}>
          <InternalMemo hasBorder />
          <PostsPrice influencerId={influencerId} css={{ marginTop: '24px' }} hasBorder />
        </div>
      </div>
    </div>
  );
};
