import { FormProvider } from '@/shared/atoms';
import { FormAction } from '@/shared/types';
import { AdvertiserFormBasedOnIdSchema } from './schema';
import { Template } from './Template';
import { AdvertiserFormValues } from './types';

export type AdvertiserFormProps = FormAction<AdvertiserFormValues>;

export const AdvertiserForm = ({ onSubmit, defaultValues, className }: AdvertiserFormProps) => (
  <FormProvider<AdvertiserFormValues>
    onSubmit={onSubmit}
    zodSchema={AdvertiserFormBasedOnIdSchema}
    defaultValues={{
      type: defaultValues?.type || 'add',
      advertiserId: defaultValues?.advertiserId,
      advertiserName: defaultValues?.advertiserName || '',
      isNewlyCreated: undefined,
      shouldSendInvitation: true,
      hubspotId: defaultValues?.hubspotId || '',
      countryId: defaultValues?.countryId || '',
      phoneNumber: defaultValues?.phoneNumber || '',
      internalNetsuiteId: defaultValues?.internalNetsuiteId || '',
      ...(defaultValues?.type === 'add' && {
        password: '',
        passwordConfirm: '',
        email: defaultValues.email,
        userName: defaultValues.userName,
        companyUrl: defaultValues?.companyUrl || ''
      }),
      ...(defaultValues?.type === 'edit' && { subscriptionType: defaultValues.subscriptionType })
    }}
  >
    <Template className={className} />
  </FormProvider>
);

export * from './types';
