import { InstagramAnalyticsPostDetails } from '@/graphql';
import { THEME } from '@/shared/constants';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InstagramPostInDateFeedPostDetailsProps {
  className?: string;
  onCloseModal?: () => void;
  data?: InstagramAnalyticsPostDetails;
}

export const InstagramPostInDateFeedPostDetails = ({
  data,
  className,
  onCloseModal
}: InstagramPostInDateFeedPostDetailsProps) => {
  if (!data) {
    onCloseModal?.();

    return null;
  }

  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = {
    caption: data.content,
    socialMedia: 'INSTAGRAM',
    postUrl: data.postUrl || '',
    images: data.mediaUrls || [],
    comments: data.commentList || [],
    info: {
      avatar: data.avatarUrl,
      postDate: data.postDate,
      socialMedia: 'INSTAGRAM',
      followers: data.followers,
      name: data.instagramAccountName
    },
    statistics: {
      likes: data.analysis?.likes,
      comments: data.analysis?.comments,
      views: data.analysis?.views,
      saves: data.analysis?.saves,
      reach: data.analysis?.reach,
      impressions: data.analysis?.impressions
    }
  };

  return (
    <DefaultPostDetailsTemplate className={className} postDetails={postDetails} css={{ width: THEME.modal.size.lv3 }} />
  );
};
