import { SerializedStyles, css } from '@emotion/react';
import { emptyImage2, emptyVideoImage } from '@/shared/assets';
import { Icon, ProgressBar } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { getFileTypeFromUrl, getDecodedUrlFileName } from '@/shared/utils';
import { UploadedFile } from './types';

export interface PreviewProps {
  disabled?: boolean;
  previewWidth?: string;
  uploadedFiles: UploadedFile[];
  previewCss?: SerializedStyles;
  deleteUploadedFile: (index: number) => () => void;
  uploadProgress?: { progress: number; progressUrl: string | null };
}

export const Preview = ({
  disabled,
  previewCss,
  uploadedFiles,
  uploadProgress,
  deleteUploadedFile,
  previewWidth = '31.5%'
}: PreviewProps) => (
  <div css={[styles.wrapper(previewWidth), previewCss]}>
    {uploadedFiles.map(({ url, preview }, index) => {
      const fileType = getFileTypeFromUrl(url);
      const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileType);
      // when we create preview from Blob fileType=[avi, mov, mp4], when we upload file fileType is normal MIME
      const isVideo = ['video/avi', 'avi', 'video/mp4', 'mp4', 'video/quicktime', 'mov'].includes(fileType);
      const isEmptyImage = !(isImage || isVideo);
      const imagePreview = preview && isImage ? preview : emptyImage2;
      const hasProgressBar = url === uploadProgress?.progressUrl && uploadProgress?.progress !== 100;

      return (
        <div css={styles.uploadedFile(previewWidth)} key={index}>
          {isImage && (
            <div css={styles.imageWrapper}>
              <img css={styles.image} src={imagePreview} />
            </div>
          )}
          {isVideo && <img css={{ width: '100%' }} src={emptyVideoImage} />}
          {isEmptyImage && (
            <a download={url} href={url}>
              <img css={{ width: '100%' }} src={imagePreview} />
            </a>
          )}
          {hasProgressBar ? <ProgressBar value={uploadProgress.progress} /> : <div css={{ height: '4px' }} />}
          {!disabled && (
            <span css={styles.removeButton} onClick={deleteUploadedFile(index)}>
              <Icon icon="close" size={6} />
            </span>
          )}
          <p css={styles.title} title={getDecodedUrlFileName(preview)}>
            {getDecodedUrlFileName(preview)}
          </p>
        </div>
      );
    })}
  </div>
);
const styles = {
  wrapper: (previewWidth: string) =>
    css({
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      '&::after': { content: '""', display: 'block', width: previewWidth }
    }),
  uploadedFile: (previewWidth: string) =>
    css({
      overflow: 'hidden',
      marginTop: '12px',
      position: 'relative',
      width: previewWidth
    }),
  imageWrapper: css({
    position: 'relative',
    backgroundColor: '#27313b',
    '&::before': { content: '""', display: 'block', paddingTop: '56.25%' }
  }),
  image: css({
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'contain'
  }),
  title: css({
    marginTop: '2px',
    fontSize: '12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }),
  removeButton: css({
    top: 0,
    right: 0,
    zIndex: 1,
    width: '12px',
    height: '12px',
    padding: '4px',
    cursor: 'pointer',
    borderRadius: '50%',
    position: 'absolute',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    color: THEME.text.colors.white,
    backgroundColor: THEME.background.colors.black,
    transform: 'translate(50%, -50%)',
    transition: 'color 0.3s ease-in-out',
    textShadow: '0 0 4px rgba(0, 0, 0, 0.7)',
    '&:hover': { opacity: 0.8 }
  })
};
