import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { formatIntNumber, formatPercent, formatPrice } from '@/shared/utils';
import { useUserRoles } from '@/auth';
import { PackageInfluencersDownloadButton } from '../PackageDownloadButton';
import { PackageProposalContextType } from '../PackageProposalContext';

interface ProposalSummaryProps {
  proposalSummary?: PackageProposalContextType['proposalSummary'];
  packageId: number;
}
export const PackageSummary = ({ proposalSummary, packageId }: ProposalSummaryProps) => {
  const { t } = useTranslation();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { summary, currency } = proposalSummary || { summary: {}, currency: '' };

  const stats = [
    {
      label: t('General.Total Followers'),
      value: formatIntNumber(summary.followersCount ? +summary.followersCount : null)
    },
    {
      unit: '%',
      label: t('HeaderColumn.Agg Egg Rate by views'),
      value: formatPercent(summary?.engagementViewRate, false)
    },
    {
      unit: '%',
      label: t('HeaderColumn.Agg Egg Rate by followers'),
      value: formatPercent(summary?.engagementFollowerRate, false)
    },
    {
      label: t('JobDescription.Budget'),
      value: formatPrice(summary?.budget, currency)
    },
    ...(isAdminStaffTalentAgencyPartner
      ? [
          {
            label: t('JobDescription.Influencer Cost'),
            value: formatPrice(summary?.influencerCost, currency),
            unit: currency ? t(currency) : ''
          },
          {
            label: t('JobDescription.Profit'),
            value: formatPrice(summary?.profit, currency),
            unit: currency ? t(currency) : ''
          },
          {
            label: t('HeaderColumn.Avg Engagement Proposed'),
            value: formatIntNumber(summary?.avgEngagementProposed)
          },
          {
            label: t('HeaderColumn.Avg Engagement Posted'),
            value: formatIntNumber(summary?.avgEngagementPosted)
          }
        ]
      : [])
  ];

  return (
    <div css={{ backgroundColor: THEME.background.colors.white }}>
      <div css={styles.header}>
        <p css={styles.title}>{t('Proposal List')}</p>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <PackageInfluencersDownloadButton packageId={packageId} />
        </div>
      </div>

      <div css={styles.statsWrapper}>
        {stats.map(({ label, value, unit }, index) => (
          <div key={index} css={styles.statsItem}>
            <div css={styles.statsLabel}>{label}</div>
            <div title={`${value} ${unit || ''}`} css={styles.statsValue}>
              {value}
              &nbsp;
              {unit ? <span>{unit}</span> : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  header: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    borderBottom: THEME.border.base,
    height: '48px',
    boxSizing: 'border-box'
  }),
  title: css({
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    color: THEME.text.colors.black.lv1
  }),
  statsWrapper: css({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',
    padding: '8px',
    gap: '8px'
  }),
  statsItem: css({
    padding: '8px',
    width: '144px',
    borderLeft: THEME.border.base
  }),
  statsLabel: css({
    fontWeight: 600,
    fontSize: '12px',
    color: THEME.text.colors.gray.lv3,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }),
  statsValue: css({
    marginTop: '4px',
    fontWeight: 600,
    fontSize: '16px',
    color: THEME.text.colors.black.lv1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    '> span': {
      fontSize: '12px',
      color: THEME.text.colors.gray.lv3
    }
  })
};
