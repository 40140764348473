import { useTranslation } from 'react-i18next';
import { Table } from '@/shared/atoms';
import { useInfluencerPackagePermissions } from '@/auth';
import { useInfluencerPackagesTable } from '../hooks';
import { useInfluencerPackagesContext } from '../InfluencerPackagesContext';

export const InfluencerPackagesList = () => {
  const { t } = useTranslation();
  const { hideAddPackageBtn } = useInfluencerPackagePermissions();
  const { loading, listRecords } = useInfluencerPackagesContext();
  const { rows, columns } = useInfluencerPackagesTable({ listRecords });

  return (
    <Table
      loading={loading}
      data={{ rows, columns, rowHeight: '64px' }}
      empty={{ linkProps: !hideAddPackageBtn ? { to: '/package/add', label: t('Add package') } : undefined }}
      hasBorder={false}
      noTopBorderRadius
    />
  );
};
