import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Button } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { ActionButtons } from '@/shared/molecules';
import { localizedMonthsShort } from '@/shared/utils';

export interface MonthPickerDropdownProps {
  value?: Date;
  className?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  disabledMonths?: { before?: Date; after?: Date };
  onChange?: (data: { month: number; year: number }) => void;
}

export const MonthPickerDropdown = ({
  value,
  onCancel,
  onSubmit,
  onChange,
  className,
  disabledMonths
}: MonthPickerDropdownProps) => {
  const month = value?.getMonth();
  const year = value?.getFullYear() || new Date().getFullYear();

  const { i18n } = useTranslation();
  const [displayedYear, setDisplayedYear] = useState(year);

  const months = localizedMonthsShort(i18n.language as AppLanguage);

  const checkDisabledMonth = (monthIndex: number) => {
    if (disabledMonths?.before) {
      const disabledBeforeMonth = disabledMonths.before.getMonth();
      const disabledBeforeYear = disabledMonths.before.getFullYear();

      return (
        displayedYear < disabledBeforeYear || (disabledBeforeYear === displayedYear && monthIndex < disabledBeforeMonth)
      );
    }

    if (disabledMonths?.after) {
      const disabledAfterMonth = disabledMonths.after.getMonth();
      const disabledAfterYear = disabledMonths.after.getFullYear();

      return (
        displayedYear > disabledAfterYear || (disabledAfterYear === displayedYear && monthIndex > disabledAfterMonth)
      );
    }
  };

  const checkDisabledYear = (year: number) => {
    if (disabledMonths?.before) {
      const disabledBeforeYear = disabledMonths.before.getFullYear();

      return year < disabledBeforeYear;
    }

    if (disabledMonths?.after) {
      const disabledAfterYear = disabledMonths.after.getFullYear();

      return year > disabledAfterYear;
    }
  };

  return (
    <div className={className}>
      <div css={{ padding: '24px', boxSizing: 'border-box' }}>
        <div css={styles.header}>
          <Button
            variant="default"
            css={styles.arrow}
            disabled={checkDisabledYear(displayedYear - 1)}
            onClick={() => setDisplayedYear(displayedYear - 1)}
            prefixIcon={{ size: 12, icon: 'caret-left', color: THEME.datePicker.colors.arrow }}
          />
          <p css={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', textAlign: 'center' }}>{displayedYear}</p>
          <Button
            variant="default"
            css={styles.arrow}
            disabled={checkDisabledYear(displayedYear + 1)}
            onClick={() => setDisplayedYear(displayedYear + 1)}
            prefixIcon={{ size: 12, icon: 'caret-right', color: THEME.datePicker.colors.arrow }}
          />
        </div>

        <div css={styles.months}>
          {months.map((name, index) => {
            const disabled = checkDisabledMonth(index);

            return (
              <div key={`month-${index}`} css={{ padding: '4px', boxSizing: 'border-box', flex: 'calc(100% / 3) 0 0' }}>
                <button
                  css={styles.month}
                  disabled={disabled}
                  onClick={() => onChange?.({ month: index, year: displayedYear })}
                  className={index === month && displayedYear === year ? 'active' : undefined}
                >
                  {name}
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <ActionButtons
        buttonHeight="32px"
        primaryButtonProps={{ onClick: onSubmit }}
        secondaryButtonProps={{ onClick: onCancel }}
        hasTopBorder
      />
    </div>
  );
};
const styles = {
  header: css({
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  arrow: css({
    padding: 0,
    border: 'none',
    '&:not(:disabled):hover > svg': { filter: 'brightness(0.7)' },
    '&:disabled': { background: 'transparent', opacity: 0.25, cursor: 'default' }
  }),
  months: css({
    width: '280px',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '4px -4px -4px'
  }),
  month: css({
    width: '100%',
    height: '35px',
    display: 'flex',
    borderRadius: '3px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    transition: 'background-color 0.1s ease, color 0.1s ease',
    '&:disabled': {
      opacity: 0.25,
      cursor: 'default',
      pointerEvents: 'none'
    },
    '&:not(:disabled)': {
      '&:hover': {
        cursor: 'pointer',
        color: THEME.datePicker.colors.active.text,
        backgroundColor: THEME.background.colors.blue
      },
      '&.active': {
        color: THEME.datePicker.colors.active.text,
        backgroundColor: THEME.datePicker.colors.active.background
      }
    }
  })
};
