import { useInfluencerYoutubeAnalyticsOverviewQuery } from '@/graphql';
import { useInfluencerProfileSearch, AnalyticsYoutubeDashboardOverviewTemplate } from '@/shared/organisms';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsYoutubeProfileOverviewProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerAnalyticsYoutubeProfileOverview = ({
  socialAccount
}: InfluencerAnalyticsYoutubeProfileOverviewProps) => {
  const { reinitParamsSearch } = useInfluencerProfileSearch();
  const { data, loading } = useInfluencerYoutubeAnalyticsOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: { socialAccountId: socialAccount.id },
    onCompleted: ({ influencerYoutubeAnalyticsOverview }) => {
      reinitParamsSearch({
        lastUpdated: influencerYoutubeAnalyticsOverview?.lastUpdated || '',
        id: socialAccount.id.toString()
      });
    }
  });

  return (
    <div css={{ marginTop: '16px' }}>
      <AnalyticsYoutubeDashboardOverviewTemplate
        loading={loading}
        accountId={socialAccount.id}
        data={data?.influencerYoutubeAnalyticsOverview}
        isInfluencerProfile
      />
    </div>
  );
};
