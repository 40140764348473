import { DownloadButton } from '@/shared/atoms';
import { ListWithPagination } from '@/shared/molecules';
import { useDownloadFile, useParamsSearch } from '@/shared/hooks';
import { useInfluencerAllInstagramStoryPostsQuery } from '@/graphql';
import { convertOrderToORDER, getOffset, getUploadApiUrl, getValuableVariables } from '@/shared/utils';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  InfluencerInstagramStoryPostsList,
  InfluencerInstagramStoryPostsSearchType
} from '@/shared/organisms';
import { useInitialInfluencerPostsListWithName } from '../hooks';

export const InfluencerInstagramStoryPosts = () => {
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName({
    titleWithName: 'appHeader.StoryPostListWithAccountName',
    title: 'appHeader.Story Post List'
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<InfluencerInstagramStoryPostsSearchType>();
  const { data, previousData, loading } = useInfluencerAllInstagramStoryPostsQuery({
    skip: !influencerId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      socialAccountId: mainSocialAccountId,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      endDate: null,
      startDate: null,
      keyword: filter.keyword
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerAllInstagramStoryPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} />

      <ProfilePostsCount count={totalRecords}>
        <DownloadButton
          loading={downloading}
          onClick={() =>
            handleDownloadFile({
              filenamePrefix: 'scraping_posts',
              url: getUploadApiUrl(`analytics/story_posts/influencer/${mainSocialAccountId}/csv/custom`)
            })
          }
        />
      </ProfilePostsCount>

      <InfluencerInstagramStoryPostsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.influencerAllInstagramStoryPosts || []}
      />
    </ListWithPagination>
  );
};
