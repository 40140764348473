import { useUpdateAutoManagedCampaignMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import {
  AutoManagedCampaignForm,
  AutoManagedCampaignFormValues,
  getDefaultAutoManagedCampaignFormValues
} from '@/shared/organisms';
import { FormAction } from '@/shared/types';
import { useAutoManagedCampaignDetailsContext } from '../AutoManagedCampaignDetailsContext';

export const EditAutoManagedCampaign = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { autoManagedCampaign, autoManagedCampaignId } = useAutoManagedCampaignDetailsContext();
  const { callUpdateAutoManagedCampaign } = useUpdateAutoManagedCampaignMutation();

  const handleSubmit: FormAction<AutoManagedCampaignFormValues>['onSubmit'] = async (payload) => {
    try {
      await callUpdateAutoManagedCampaign({
        variables: {
          input: {
            status: payload.status,
            campaignId: autoManagedCampaignId,
            serviceInfo: payload.serviceInfo,
            requirement: payload.requirement,
            marginRate: payload.marginRate ? Number(payload.marginRate) / 100 : 0
          }
        }
      });
      enqueueSnackbar(t('succeededInUpdating', { name: payload.title }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: payload.title }), { variant: 'error' });
      enqueueSnackbar(t(error || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <AutoManagedCampaignForm
      onSubmit={handleSubmit}
      defaultValues={getDefaultAutoManagedCampaignFormValues(autoManagedCampaign)}
    />
  );
};
