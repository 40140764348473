import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Anchor, AnchorProps, Button, Icon, TextCutter } from '@/shared/atoms';
import { ChatDemoMessageType } from './ChatDemoMessagesList';

export type ChatDemoLinkProps = ChatDemoMessageType['searchLink'] & {
  className?: string;
  search?: AnchorProps['search'];
};

export const ChatDemoLink = ({ className, label, href, state, search }: ChatDemoLinkProps) =>
  href && label ? (
    <Anchor href={href} search={search} css={styles.wrapper} className={className} state={state}>
      <Icon
        size={13}
        icon="chat-demo-stacks"
        color={THEME.icon.colors.black}
        css={{ flex: 'none', marginRight: '4px' }}
      />
      <TextCutter css={{ flex: 1, fontSize: '16px', fontWeight: 500, lineHeight: '100%' }} lines={1} text={label} />
      <Button
        as="span"
        variant="white"
        prefixIcon={{ icon: 'arrow-right', size: 12, color: '#6E7C89' }}
        css={{ padding: 0, borderRadius: '50%', flex: 'none', marginLeft: '4px', border: 'none' }}
      />
    </Anchor>
  ) : null;

const styles = {
  wrapper: css({
    flex: 'none',
    height: '48px',
    display: 'flex',
    borderRadius: '8px',
    alignItems: 'center',
    padding: '16px 21px',
    boxSizing: 'border-box',
    border: THEME.border.base,
    backgroundColor: '#EEF3F7',
    '&:not(:disabled):hover': { color: 'currentcolor', textDecoration: 'none', backgroundColor: '#DEE5EC' }
  })
};
