import { useEffect } from 'react';
import { useSearch } from '@tanstack/react-router';
import { Loading } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { EmailForwardingSearchSchemaType } from './schemaTypes';

export const EmailForwarding = () => {
  const { enqueueSnackbar, navigate } = useQueryHelper();
  const search = useSearch({ from: '/_public-routes/redirect_to' }) as EmailForwardingSearchSchemaType;

  const forwardToExternalDomain = search.url;

  useEffect(() => {
    let validRedirectUrl;
    if (forwardToExternalDomain) {
      try {
        validRedirectUrl = atob(forwardToExternalDomain);
        window.location.href = validRedirectUrl;
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Invalid email forwarding link', { variant: 'error' });
        navigate({ to: '/not-found' });
      }
    } else {
      navigate({ to: '/not-found' });
    }
  }, [forwardToExternalDomain]);

  return <Loading />;
};
