import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerCampaignListByPromotionMethod.graphql';
import { Query, QueryinfluencerCampaignListByPromotionMethodArgs } from '../../__generated__/globalTypes';

export const useInfluencerCampaignListByPromotionMethodQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerCampaignListByPromotionMethodArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerCampaignListByPromotionMethodArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerCampaignListByPromotionMethodLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerCampaignListByPromotionMethodArgs>
): QueryResult<Query, QueryinfluencerCampaignListByPromotionMethodArgs> & {
  getInfluencerCampaignListByPromotionMethod: LazyQueryExecFunction<
    Query,
    QueryinfluencerCampaignListByPromotionMethodArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerCampaignListByPromotionMethod, result] = useLazyQuery<
    Query,
    QueryinfluencerCampaignListByPromotionMethodArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerCampaignListByPromotionMethod, ...result };
};

export const useInfluencerCampaignListByPromotionMethodPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerCampaignListByPromotionMethod = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerCampaignListByPromotionMethodArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerCampaignListByPromotionMethodArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerCampaignListByPromotionMethod };
};
