import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useAutoManagedJoinedInfluencersQuery } from '@/graphql';
import {
  AutoManagedJoinedInfluencersList,
  AutoManagedJoinedInfluencersFilter,
  useAutoManagedJoinedInfluencersFilter
} from '@/shared/organisms';
import { useAutoManagedCampaignDetailsContext } from '../AutoManagedCampaignDetailsContext';

export const AutoManagedJoinedInfluencers = () => {
  const { page, limit, filter } = useAutoManagedJoinedInfluencersFilter();
  const { autoManagedCampaignId } = useAutoManagedCampaignDetailsContext();
  const { data, previousData, loading } = useAutoManagedJoinedInfluencersQuery({
    variables: getValuableVariables({
      limit,
      keyword: filter.keyword,
      id: autoManagedCampaignId,
      offset: getOffset(page, limit)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.autoManagedJoinedInfluencersCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <AutoManagedJoinedInfluencersFilter totalRecords={totalRecords} autoManagedCampaignId={autoManagedCampaignId} />
      <AutoManagedJoinedInfluencersList
        loading={loading}
        totalRecords={totalRecords}
        data={result?.autoManagedJoinedInfluencers || []}
      />
    </ListWithPagination>
  );
};
