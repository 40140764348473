import { maskitoParseNumber } from '@maskito/kit';
import { z } from 'zod';

export { maskitoParseNumber };
export const getTransferModalSchema = (prepaidBalance?: number, adAccountBalance?: number) =>
  z
    .object({
      amount: z.string(),
      step: z.enum(['charge', 'withdraw'])
    })
    .superRefine((values, ctx) => {
      const amount = maskitoParseNumber(values.amount);

      if (values.step === 'charge') {
        if (amount > (prepaidBalance || 0)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Insufficient prepaid balance',
            path: ['amount']
          });
        }
      } else {
        if (amount > (adAccountBalance || 0)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Insufficient Ad Account balance',
            path: ['amount']
          });
        }
      }
    });
