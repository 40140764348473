import { useTranslation } from 'react-i18next';
import { TwitterAnalyticsOverviewPayload, TwitterAnalyticsOverviewItem } from '@/graphql';
import { AnalyticsOverviewChartProps } from '../../../../shared';
import { AnalyticsTwitterDashboardGraphKeys as Keys, AnalyticsTwitterDashboarOverviewEngagementType } from '../types';
import { allOverviewEngagements, legendColors } from '../utils';

interface Props {
  selectedLegendKeys: Keys[];
  isInfluencerProfile?: boolean;
  availableEngagementKeys: Keys[];
  data: TwitterAnalyticsOverviewPayload;
}

interface ChartValuesType {
  categories: string[];
  postsCount: number[];
  likesCount: number[];
  viewsCount: number[];
  repliesCount: number[];
  retweetsCount: number[];
  followersCount: number[];
  urlClicksCount: number[];
  engagementCount: number[];
  impressionsCount: number[];
  quotedTweetsCount: number[];
  profileClicksCount: number[];
  engagementRateCount: number[];
}
const defaultChartValues: ChartValuesType = {
  categories: [],
  postsCount: [],
  likesCount: [],
  viewsCount: [],
  repliesCount: [],
  retweetsCount: [],
  followersCount: [],
  urlClicksCount: [],
  engagementCount: [],
  impressionsCount: [],
  quotedTweetsCount: [],
  profileClicksCount: [],
  engagementRateCount: []
};

const getEngagementMetrics = ({
  total,
  growth,
  percentage
}: Pick<TwitterAnalyticsOverviewItem, 'total' | 'growth' | 'percentage'>) => ({
  total: total || 0,
  growth: growth || 0,
  percentage: percentage || 0
});

export const useAnalyticsTwitterDashboardOverviewData = ({
  data,
  selectedLegendKeys,
  isInfluencerProfile,
  availableEngagementKeys
}: Props) => {
  const { t } = useTranslation();

  const { post, view, like, reply, retweet, follower, engagement, impression, quotedTweet } = data;
  const overviewEngagements = allOverviewEngagements.map<AnalyticsTwitterDashboarOverviewEngagementType>((item) => ({
    ...item,
    ...getEngagementMetrics(data[item.key]),
    title: t<string>(item.title)
  }));
  const availableOverviewEngagements = overviewEngagements.filter((eng) => availableEngagementKeys.includes(eng.key));

  const {
    categories,
    postsCount,
    likesCount,
    viewsCount,
    repliesCount,
    retweetsCount,
    followersCount,
    urlClicksCount,
    engagementCount,
    impressionsCount,
    quotedTweetsCount,
    profileClicksCount,
    engagementRateCount
  } = (follower.history || []).reduce<ChartValuesType>((acc, curr) => {
    const date = curr.date || '';

    return {
      categories: [...acc.categories, date],
      followersCount: [...acc.followersCount, Number(curr.count)],
      postsCount: [...acc.postsCount, post.history?.find((item) => item.date === date)?.count || 0],
      likesCount: [...acc.likesCount, like.history?.find((item) => item.date === date)?.count || 0],
      viewsCount: [...acc.viewsCount, view.history?.find((item) => item.date === date)?.count || 0],
      repliesCount: [...acc.repliesCount, reply.history?.find((item) => item.date === date)?.count || 0],
      retweetsCount: [...acc.retweetsCount, retweet.history?.find((item) => item.date === date)?.count || 0],
      quotedTweetsCount: [
        ...acc.quotedTweetsCount,
        quotedTweet.history?.find((item) => item.date === date)?.count || 0
      ],
      engagementCount: [...acc.engagementCount, engagement.history?.find((item) => item.date === date)?.count || 0],
      impressionsCount: [...acc.impressionsCount, impression.history?.find((item) => item.date === date)?.count || 0],
      // Unsupported data
      urlClicksCount: [...acc.urlClicksCount, 0],
      profileClicksCount: [...acc.profileClicksCount, 0],
      engagementRateCount: [...acc.engagementRateCount, 0]
    };
  }, defaultChartValues);

  const chartData: AnalyticsOverviewChartProps<Keys>['data'] = {
    follower: {
      zIndex: 1,
      type: 'line',
      showIncrement: true,
      data: followersCount,
      name: t<string>('Follower'),
      color: legendColors.follower,
      visible: selectedLegendKeys.includes('follower'),
      marker: {
        lineWidth: 2,
        symbol: 'circle',
        fillColor: '#fff',
        lineColor: undefined // inherit from chart
      }
    },
    post: {
      yAxis: 1,
      type: 'column',
      data: postsCount,
      name: t<string>('Post'),
      color: legendColors.post,
      visible: selectedLegendKeys.includes('post')
    },
    like: {
      yAxis: 2,
      type: 'column',
      data: likesCount,
      name: t<string>('Like'),
      color: legendColors.like,
      visible: selectedLegendKeys.includes('like')
    },
    view: {
      yAxis: 3,
      type: 'column',
      data: viewsCount,
      name: t<string>('Views'),
      color: legendColors.view,
      visible: selectedLegendKeys.includes('view')
    },
    reply: {
      yAxis: 4,
      type: 'column',
      data: repliesCount,
      color: legendColors.reply,
      name: t<string>('Reply'),
      visible: selectedLegendKeys.includes('reply')
    },
    retweet: {
      yAxis: 5,
      type: 'column',
      data: retweetsCount,
      name: t<string>('Retweet'),
      color: legendColors.retweet,
      visible: selectedLegendKeys.includes('retweet')
    },
    quotedTweet: {
      yAxis: 6,
      type: 'column',
      data: quotedTweetsCount,
      name: t<string>('Quote Tweet'),
      color: legendColors.quotedTweet,
      visible: selectedLegendKeys.includes('quotedTweet')
    },
    impression: {
      yAxis: 7,
      type: 'column',
      data: impressionsCount,
      name: t<string>('Impression'),
      color: legendColors.impression,
      visible: selectedLegendKeys.includes('impression')
    },
    urlClick: {
      yAxis: 8,
      type: 'column',
      data: urlClicksCount,
      name: t<string>('URL Clicks'),
      color: legendColors.urlClick,
      visible: selectedLegendKeys.includes('urlClick')
    },
    profileClick: {
      yAxis: 9,
      type: 'column',
      data: profileClicksCount,
      color: legendColors.profileClick,
      name: t<string>('User Profile Clicks'),
      visible: selectedLegendKeys.includes('profileClick')
    },
    engagement: {
      yAxis: 10,
      type: 'column',
      data: engagementCount,
      name: t<string>('Engagement'),
      color: legendColors.engagement,
      visible: !isInfluencerProfile && selectedLegendKeys.includes('engagement')
    },
    engagementRate: {
      yAxis: 11,
      type: 'column',
      formatType: 'percentage',
      data: engagementRateCount,
      name: t<string>('Engagement Rate'),
      color: legendColors.engagementRate,
      visible: !isInfluencerProfile && selectedLegendKeys.includes('engagementRate')
    }
  };

  return {
    chartData,
    categories,
    overviewEngagements,
    availableOverviewEngagements
  };
};
