import { AutoManagedCampaignPayload, AutoManagedCampaignStatus } from '@/graphql';
import { floatify } from '@/shared/utils';
import { AutoManagedCampaignFormValues } from './types';

export const getDefaultAutoManagedCampaignFormValues = (
  data?: AutoManagedCampaignPayload
): AutoManagedCampaignFormValues => ({
  title: data?.title || '',
  status: data?.status || AutoManagedCampaignStatus.ACTIVE,
  category: data?.category.name || '',
  marginRate: data?.marginRate ? floatify(Number(data?.marginRate) * 100, 4).toString() : '',
  sellerName: data?.sellerName || '',
  campaignUrl: data?.campaignUrl || '',
  requirement: data?.requirement || '',
  serviceInfo: data?.serviceInfo || '',
  countryName: data?.country.name || '',
  commissionRate: data?.commissionRate ?? null,
  campaignThumbnailUrl: data?.campaignThumbnailUrl || '',
  influencerCommissionRate: data?.influencerCommissionRate ?? null,
  period: {
    endDate: data?.endDate || '',
    startDate: data?.startDate || ''
  }
});
export const defaultAutoManagedCampaignFormValues = getDefaultAutoManagedCampaignFormValues();
