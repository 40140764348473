import { createFileRoute, redirect } from '@tanstack/react-router';
import { packageStaticData } from '../../../package';
export const Route = createFileRoute('/_private-routes/package/$id/content/')({
  staticData: packageStaticData,
  beforeLoad: ({
    params
  }) => redirect({
    to: '/package/$id/content/list',
    params: {
      id: params.id
    }
  })
});