import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useMatchRoute } from '@tanstack/react-router';
import { Outlet } from '@tanstack/react-router';
import { THEME } from '@/shared/constants';
import { Footer, Header } from '@/shared/molecules';
import { useAuthData } from '@/auth';
import { Anchor } from '@/shared/atoms';
import { WithChannelTalk } from '../../components/WithChannelTalk';
import { TikTokLayoutMenu } from './TikTokLayoutMenu';

export const TikTokSignupLayout = () => {
  const { auth } = useAuthData();
  const { t } = useTranslation();
  const matchRoute = useMatchRoute();
  const isEnterPasswordRoute = matchRoute({
    to: '/tiktok-onboarding/password-enter'
  });

  return (
    <div css={{ display: 'flex', minHeight: '100%', backgroundColor: THEME.background.colors.gray.lv1 }}>
      <TikTokLayoutMenu />
      <div css={[styles.content]}>
        <Header>
          {!auth.userId ? (
            <div css={styles.linkWrapper}>
              {t('Already have an account?')}
              <Anchor css={styles.link} variant="blue" to="/tiktok-onboarding/password-enter">
                {t('Sign in')}
              </Anchor>
            </div>
          ) : isEnterPasswordRoute ? (
            <div css={styles.linkWrapper}>
              {t('Don’t have an account?')}
              <Anchor css={styles.link} variant="blue" to="/tiktok-onboarding">
                {t('Sign up')}
              </Anchor>
            </div>
          ) : null}
        </Header>
        <WithChannelTalk>
          <Outlet />
        </WithChannelTalk>
        <Footer hasCombinedTerms />
      </div>
    </div>
  );
};

const styles = {
  content: css({
    flex: 1,
    display: 'flex',
    padding: '16px 8px 28px 24px',
    boxSizing: 'border-box',
    flexDirection: 'column',
    scrollBehavior: 'smooth',
    marginLeft: THEME.container.menu.width
  }),
  linkWrapper: css({
    display: 'flex',
    fontSize: '14px',
    lineHeight: '100%',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    marginLeft: 'auto'
  }),
  link: css({
    marginLeft: '8px',
    fontStretch: 'normal',
    '&:hover': { opacity: 0.8, textDecoration: 'none' }
  })
};
