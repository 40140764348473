import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestProposalPptx.graphql';
import { Mutation, MutationrequestProposalPptxArgs } from '../../__generated__/globalTypes';

export const useRequestProposalPptxMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestProposalPptxArgs>
) => {
  const [callRequestProposalPptx, result] = useMutation<Mutation, MutationrequestProposalPptxArgs>(MUTATION, options);

  return { callRequestProposalPptx, ...result };
};

export type RequestProposalPptxMutationFunction = Mutation['requestProposalPptx'];
