import { z } from 'zod';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { YoutubeAnalyticsTagRankingSortOrder } from '@/graphql';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';

export const YtTagRankingFilterSchema = z.object({
  keyword: FilterValidations.string().optional(),
  endDate: FilterValidations.string().default(getToday()),
  startDate: FilterValidations.string().default(getThirtyDaysAgo()),
  name: z.string().optional()
});
export const YtTagRankingSearchSchema = z.object({
  filter: YtTagRankingFilterSchema.default({}),
  sort: SearchFilterDefaults.sort(YoutubeAnalyticsTagRankingSortOrder).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type YtTagRankingSearchSchemaType = z.infer<typeof YtTagRankingSearchSchema>;
