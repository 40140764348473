import { useState } from 'react';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { convertOrderToORDER, getValuableVariables } from '@/shared/utils';
import { Maybe, ThreadsInfluencer, useAllThreadsInfluencersQuery } from '@/graphql';
import { formatInfluencerProfile } from '../utils';
import { ThreadsInfluencersSearchSchemaType } from '../schemaTypes';
import { useGetInfluencersQueryVariables } from '../../hooks';

type Props = Pick<ParamsSearchReturnType<ThreadsInfluencersSearchSchemaType>, 'page' | 'limit' | 'filter' | 'sort'>;

export const useThreadsInfluencersList = ({ page, sort, limit, filter }: Props) => {
  // Using data directly from query can be empty if skipSearch is true
  // But we still want to keep old data in that case, so we store it in state
  const [data, setData] = useState<{
    totalNumber?: number;
    threadsAccounts?: Maybe<readonly ThreadsInfluencer[]>;
  }>();
  const { skipSearch, variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { loading } = useAllThreadsInfluencersQuery({
    skip: skipSearch || !filter,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: getValuableVariables({
      ...variables,
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) }
    }),
    onCompleted: ({ allThreadsInfluencers, allThreadsInfluencersCount }) => {
      setData({
        threadsAccounts: allThreadsInfluencers,
        totalNumber: allThreadsInfluencersCount?.totalNumber
      });
    }
  });

  return {
    loading,
    totalRecords: data?.totalNumber || 0,
    listRecords: data?.threadsAccounts?.map(formatInfluencerProfile) || []
  };
};
