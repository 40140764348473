import { useTranslation } from 'react-i18next';
import { usePostDetailsParam } from '@/shared/hooks';
import { YoutubeAnalyticsComparePostsSortOrder } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { convertDurationToHhMmSs, formatIntNumber } from '@/shared/utils';
import { InfluencerYoutubeCompareVideosSortType, InfluencerYoutubeCompareVideosType } from '../types';

type CustomColumnProps = ColumnProps<InfluencerYoutubeCompareVideosSortType>;

interface Props {
  data: readonly InfluencerYoutubeCompareVideosType[];
}

export const useYoutubeCompareVideosTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Videos'), sticky: { active: true, hasBorderRight: true } },
    { title: t('HeaderColumn.Post date') },
    { title: t('HeaderColumn.View') },
    { title: t('HeaderColumn.Like') },
    { title: t('HeaderColumn.Dislike') },
    { title: t('HeaderColumn.Comment') },
    getMetricColumnProps({
      title: t('HeaderColumn.Play Time'),
      sortField: YoutubeAnalyticsComparePostsSortOrder.DURATION
    })
  ];
  const rows: RowProps[] = data.map(
    ({ id, views, likes, dislikes, comments, postDate, title, duration, thumbnail }) => [
      <TableCell.Post title={title} thumbnail={thumbnail || ''} onClick={() => setPostDetailsParam(id)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(dislikes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={convertDurationToHhMmSs(duration)} />
    ]
  );

  return { rows, columns };
};
