import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { ColumnProps, RowProps, Table, TableCell } from '@/shared/atoms';
import { getOffset, formatPrice, formatPercent, formatIntNumber, getValuableVariables } from '@/shared/utils';
import { AutoManagedReportByDate, AutoManagedReportSummary, useAutoManagedReportByDateQuery } from '@/graphql';
import { useAutoManagedReportFilter } from '../hooks';
import { formatAffiliatePeriod, styles } from './utils';

interface AutoManagedReportByDateTableProps {
  currency: string;
  campaignId: number;
  summaryData?: AutoManagedReportSummary;
}

export const AutoManagedReportByDateTable = ({
  currency,
  campaignId,
  summaryData
}: AutoManagedReportByDateTableProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const {
    page,
    limit,
    filter: { endDate, startDate }
  } = useAutoManagedReportFilter();
  const { data, loading } = useAutoManagedReportByDateQuery({
    variables: getValuableVariables({
      limit,
      endDate,
      startDate,
      id: campaignId,
      offset: getOffset(page, limit)
    })
  });

  const getStatCells = (rowStats?: Partial<AutoManagedReportByDate> | null) => [
    <TableCell.Number value={formatIntNumber(rowStats?.clicks)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.conversions)} />,
    <TableCell.Number value={formatPercent(rowStats?.cvr, false)} unit="%" />,
    <TableCell.Number value={formatPrice(rowStats?.sales || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.cost || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.influencerCost || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.grossProfit || null, currency)} unit={currency} />
  ];

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Date'), sticky: { active: true, hasBorderRight: true } },
    { title: t('HeaderColumn.Click'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Order'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.CVR'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Store Sales'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Cost'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Influencer Cost'), styles: { textAlign: 'right' }, hidden: !isAdminStaff },
    { title: t('HeaderColumn.Gross Profit'), styles: { textAlign: 'right' }, hidden: !isAdminStaff }
  ];
  const totalRow: RowProps = {
    styles: styles.totalRow,
    cells: [
      <TableCell.Text
        value={formatAffiliatePeriod({
          period: { endDate: endDate || '', startDate: startDate || '' },
          title: t('Total')
        })}
      />,
      ...getStatCells({
        cvr: summaryData?.cvr,
        cost: summaryData?.cost,
        sales: summaryData?.sales,
        clicks: summaryData?.clicks,
        conversions: summaryData?.conversions,
        grossProfit: summaryData?.grossProfit,
        influencerCost: summaryData?.influencerCost
      })
    ]
  };
  const campaignRows: RowProps[] =
    data?.autoManagedReportByDate?.map((stats) => [<TableCell.Date value={stats?.date} />, ...getStatCells(stats)]) ||
    [];

  return (
    <Table
      loading={loading}
      data={{ columns, rows: [totalRow, ...campaignRows], rowHeight: '73px' }}
      pagination={{ totalRecords: data?.autoManagedReportByDateCount?.totalNumber }}
      noTopBorderRadius
      stickyHeader
    />
  );
};
