import { YoutubeAnalyticsPostPayloads } from '@/graphql';
import { YoutubePostInDateDetails, YoutubePostInDateDetailsProps } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface YoutubePostInDateDetailsModalProps {
  paramKey?: string;
  data: YoutubeAnalyticsPostPayloads | null;
}

export const YoutubePostInDateDetailsModal = ({ data, paramKey }: YoutubePostInDateDetailsModalProps) => {
  const posts =
    data?.posts.map<NonNullable<YoutubePostInDateDetailsProps['data']>>((post) => ({
      ...post,
      avatar: data.avatar,
      channelName: data.channelName,
      subscribers: data.subscribers
    })) || [];

  return (
    <CarouselTemplateModal posts={posts} paramKey={paramKey}>
      {({ selectedPost }) => <YoutubePostInDateDetails data={selectedPost} />}
    </CarouselTemplateModal>
  );
};
