import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { LinkifyItInput } from '@/shared/molecules';

interface RequestModificationButtonProps {
  loading?: boolean;
  className?: string;
  onSubmit?: (message: string) => Promise<void> | void;
}

export const RequestModificationButton = ({ loading, className, onSubmit }: RequestModificationButtonProps) => {
  const { t } = useTranslation();
  const formState = useToggleState();
  const [message, setMessage] = useState<string>('');

  return (
    <div css={{ marginTop: '16px' }} className={className}>
      {!formState.status ? (
        <Button
          variant="white"
          disabled={loading}
          onClick={formState.open}
          prefixIcon={{ icon: 'send', size: 12 }}
          title={t('Button.Request modification')}
          css={{ marginTop: '16px', width: '100%' }}
        />
      ) : (
        <div css={{ backgroundColor: '#F6F8FA', padding: '16px' }}>
          <p css={{ fontSize: '14px', lineHeight: '140%' }}>{t('Send modification request message')}:</p>
          <LinkifyItInput value={message} onChange={setMessage} />
          <p css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3, lineHeight: '12px', marginTop: '8px' }}>
            {t('draftPostRequestModificationNote')}
          </p>
          <div css={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: '16px' }}>
            <Button
              variant="white"
              disabled={loading}
              onClick={formState.close}
              title={t('Button.Cancel')}
              css={{ padding: '0 24px' }}
            />
            <Button
              variant="blue"
              loading={loading}
              disabled={!message}
              title={t('Button.Send')}
              css={{ padding: '0 32px' }}
              onClick={() => onSubmit?.(message)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
