import { Trans } from 'react-i18next';
import { Outlet } from '@tanstack/react-router';
import { Anchor, Notice } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useParamsSearch, usePostDetailsParam, useQueryHelper } from '@/shared/hooks';
import { useAllMarketplacePostsQuery, useAllNeedReviewMarketplacePostsQuery } from '@/graphql';
import { MarketplacePostsList, MarketplacePostsFilter, MarketplacePostsSearchSchemaTypes } from '@/shared/organisms';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';

export const MarketplacePosts = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { setPostDetailsParam } = usePostDetailsParam();
  const { marketplace, marketplaceId, isAffiliateCampaign, hasTrackingPost, hasTrackingReferral } =
    useMarketplaceDetailsContext();
  const { page, limit, filter, setFilter } = useParamsSearch<MarketplacePostsSearchSchemaTypes>();
  const { data: needReviewData } = useAllNeedReviewMarketplacePostsQuery({
    variables: { marketplaceId },
    fetchPolicy: 'network-only'
  });
  const { data, previousData, loading } = useAllMarketplacePostsQuery({
    fetchPolicy: 'network-only',
    variables: getValuableVariables({
      limit,
      marketplaceId,
      keyword: filter.keyword,
      status: filter.status || null,
      offset: getOffset(page, limit)
    }),
    onError: (err) => {
      enqueueSnackbar(t(err.message || 'Cannot get post list data'), { variant: 'error' });
    }
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allMarketplacePostsCount?.totalNumber;
  const allNeedReviewMarketplacePosts = needReviewData?.allNeedReviewMarketplacePosts;

  return (
    <>
      <ListWithPagination loading={!filter} loadingProps={{ css: { border: 'none' } }} pagination={{ totalRecords }}>
        {!isAffiliateCampaign && hasTrackingPost ? (
          <Notice
            type="info"
            title={t('How do we pay commissions to creators?')}
            description={<Trans i18nKey="stepsToPayCommissionsToCreators" components={{ br: <br /> }} />}
            hasCloseIcon
          />
        ) : null}

        {allNeedReviewMarketplacePosts?.length ? (
          <Notice
            type="warning"
            title={t('Some posts have been modified, please check and review')}
            description={
              <ul css={{ paddingLeft: '8px', fontWeight: 400, fontSize: '12px', color: THEME.text.colors.gray.lv3 }}>
                {allNeedReviewMarketplacePosts.map((post) => (
                  <li key={post.id} css={{ listStyle: 'disc', listStylePosition: 'inside' }}>
                    <span css={{ marginLeft: '-6px' }}>
                      {t('Post of influencerName', { name: post.influencer.name })}
                    </span>
                    &nbsp;-&nbsp;
                    <Anchor
                      variant="blue"
                      label={t('Review post')}
                      css={{ display: 'inline-flex' }}
                      onClick={(e) => {
                        e.preventDefault();
                        setPostDetailsParam(post.id);
                      }}
                    />
                  </li>
                ))}
              </ul>
            }
          />
        ) : null}

        <MarketplacePostsFilter
          filter={filter}
          setFilter={setFilter}
          totalRecords={totalRecords}
          marketplaceId={marketplaceId}
        />

        <MarketplacePostsList
          loading={loading}
          marketplace={marketplace}
          posts={result?.allMarketplacePosts || []}
          hasTrackingReferral={hasTrackingReferral}
        />
      </ListWithPagination>

      <Outlet />
    </>
  );
};
