import { useFacebookPageAnalyticsPostQuery, useInfluencerFacebookPageAnalyticsPostQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InfluencerFacebookAnalyticsPostDetailsProps
  extends Pick<PostDetailsType, 'id' | 'influencerId' | 'mainSocialAccountId'> {
  className?: string;
  isAnalytics?: boolean;
  onCloseModal?: () => void;
}

export const InfluencerFacebookAnalyticsPostDetails = ({
  id,
  className,
  isAnalytics,
  influencerId,
  onCloseModal,
  mainSocialAccountId
}: InfluencerFacebookAnalyticsPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useInfluencerFacebookPageAnalyticsPostQuery({
    skip: !id || !influencerId || !mainSocialAccountId || isAnalytics,
    variables: { postId: Number(id), socialAccountId: Number(mainSocialAccountId), influencerId: Number(influencerId) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const { data: analyticsData, loading: fetchingAnalyticsData } = useFacebookPageAnalyticsPostQuery({
    skip: !id || !mainSocialAccountId || !isAnalytics,
    variables: {
      facebookPageAnalyticsPostId: Number(id),
      facebookPageAnalyticsAccountId: Number(mainSocialAccountId)
    },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const details = data?.influencerFacebookPageAnalyticsPost || analyticsData?.facebookPageAnalyticsPost;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'FACEBOOK_PAGE',
        postUrl: details?.postUrl,
        caption: details?.content,
        images: details?.image ? [details.image] : [],
        comments:
          details?.postComments?.map((comment) => ({ content: comment.content, username: comment.posterName })) || [],
        statistics: {
          reactions: details?.reactions,
          comments: details?.comments,
          shares: details?.shares,
          reach: details?.adImpressions
        },
        info: {
          name: details?.pageName,
          avatar: details?.avatar,
          postDate: details?.postDate,
          socialMedia: 'FACEBOOK_PAGE',
          followers: details?.subscribers
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      className={className}
      postDetails={postDetails}
      css={{ width: THEME.modal.size.lv3 }}
      loading={loading || fetchingAnalyticsData}
    />
  );
};
