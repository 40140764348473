import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, FormStyle, MediaPreview, Anchor } from '@/shared/atoms';
import { useTranslateCountry } from '@/shared/hooks';
import { RichEditorField } from '@/shared/molecules';
import { formatDate } from '@/shared/utils';
import { AutoManagedCampaignFormKeys, AutoManagedCampaignFormValues } from '../types';

export const CampaignInformation = () => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();
  const { getValues } = useFormContext<AutoManagedCampaignFormValues>();

  const values = getValues();

  return (
    <div css={{ padding: '24px' }}>
      <FormStyle.SectionTitle index={1} label={t('Campaign Information')} />

      <FormStyle.FieldWrapper css={{ display: 'flex', alignItems: 'center' }}>
        <MediaPreview css={{ width: '120px', height: '120px' }} src={values.campaignThumbnailUrl} />
        <Anchor
          lines={2}
          variant="blue"
          target="_blank"
          label={values.title}
          href={values.campaignUrl}
          css={{ fontSize: '14px', marginLeft: '16px', textTransform: 'uppercase', maxWidth: '464px' }}
        />
      </FormStyle.FieldWrapper>

      <FormStyle.FieldWrapper>
        <Label
          iconMargin="4px"
          css={styles.label}
          title={t<string>('TextForm.Campaign Title')}
          help={t('Tooltip.Campaign Title')}
        />
        <p css={[styles.text, { textTransform: 'uppercase' }]}>{values.title}</p>
      </FormStyle.FieldWrapper>

      <FormStyle.FieldsGroup itemsPerRow={4}>
        <FormStyle.FieldWrapper>
          <Label css={styles.label} title={t<string>('TextForm.Seller Name')} />
          <p css={[styles.text, { textTransform: 'uppercase' }]}>{values.sellerName}</p>
        </FormStyle.FieldWrapper>

        <FormStyle.FieldWrapper>
          <Label css={styles.label} title={t<string>('TextForm.Campaign Duration')} />
          <p css={styles.text}>
            {!values.period.startDate && !values.period.endDate
              ? '-'
              : `${formatDate(values.period.startDate)} - ${formatDate(values.period.endDate)}`}
          </p>
        </FormStyle.FieldWrapper>
      </FormStyle.FieldsGroup>

      <FormStyle.FieldsGroup itemsPerRow={4}>
        <FormStyle.FieldWrapper>
          <Label css={styles.label} title={t<string>('TextForm.Campaign Country')} />
          <p css={styles.text}>{tc(values.countryName)}</p>
        </FormStyle.FieldWrapper>

        <FormStyle.FieldWrapper>
          <Label css={styles.label} title={t<string>('TextForm.Campaign Category')} />
          <p css={styles.text}>{t(values.category)}</p>
        </FormStyle.FieldWrapper>
      </FormStyle.FieldsGroup>

      <RichEditorField<AutoManagedCampaignFormKeys>
        name="requirement"
        title={t('Post Requirements')}
        help={t('Post Requirements Help')}
        placeholder={t<string>('TextForm.Post Requirements')}
        required
      />
    </div>
  );
};

const styles = {
  text: css({ fontSize: '14px' }),
  label: css({ padding: 0, fontSize: '14px', marginBottom: '8px' })
};
