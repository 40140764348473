import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { FormStyle, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TextField } from '@/shared/molecules';
import { FormValues } from './types';

interface TemplateProps {
  open: boolean;
  loading: boolean;
  currency: string;
  onClose?: () => void;
  onSubmit?: () => void;
  hasAdAgencyMarginRate?: boolean;
}

export const BudgetBulkEditForm = ({
  open,
  loading,
  currency,
  onClose,
  onSubmit,
  hasAdAgencyMarginRate
}: TemplateProps) => {
  const { t } = useTranslation();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const {
    formState: { isSubmitting }
  } = useFormContext<FormValues>();

  return (
    <Modal open={open} onClose={onClose} css={{ maxWidth: '440px' }} hasCloseIcon>
      <ModalContent>
        <ModalTitle>{t('Dialog.Bulk Edit')}</ModalTitle>

        <FormStyle.FieldsGroup>
          <FormStyle.FieldWrapper css={{ display: 'flex' }}>
            <TextField<keyof FormValues>
              type="number"
              name="budget"
              placeholder="0"
              css={{ padding: '0 !important' }}
              title={t(hasAdAgencyMarginRate ? 'TextForm.Net Budget' : 'TextForm.Budget')}
            />
            <p css={styles.currency}>{currency}</p>
          </FormStyle.FieldWrapper>

          {isAdminStaffTalentAgencyPartner ? (
            <FormStyle.FieldWrapper css={{ display: 'flex' }}>
              <TextField<keyof FormValues>
                type="number"
                placeholder="0"
                name="influencerCost"
                css={{ padding: '0 !important' }}
                title={t('TextForm.Influencer Cost')}
              />
              <p css={styles.currency}>{currency}</p>
            </FormStyle.FieldWrapper>
          ) : null}
        </FormStyle.FieldsGroup>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{ loading: isSubmitting || loading, title: t('Button.Apply'), onClick: onSubmit }}
      />
    </Modal>
  );
};
const styles = {
  currency: css({
    fontSize: '12px',
    marginLeft: '8px',
    lineHeight: '12px',
    fontWeight: 'normal',
    alignSelf: 'self-end',
    color: THEME.text.colors.gray.lv3
  })
};
