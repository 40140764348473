import { THEME } from '@/shared/constants';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { UserForm } from '@/shared/organisms';
import { useCreateUser } from './hooks';

export const AddUser = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'documentTitle.AddUser', appHeader: 'appHeader.Add User', href: '/user' });

  const { createUser } = useCreateUser();

  return (
    <div css={{ margin: '0 auto', width: THEME.container.details.width }}>
      <Header />

      <UserForm onSubmit={createUser} />
    </div>
  );
};
