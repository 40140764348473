import { useEffect } from 'react';
import { useUserRoles } from '@/auth';
import { useGenerateTalentInfluencerSignUpUrlMutation, useGeneratePartnerInfluencerSignUpUrlMutation } from '@/graphql';
import { Anchor, Notice } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';

export const NoticeSignUpUrl = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { isPartner, isTalentAgency } = useUserRoles();
  const {
    data: partnerData,
    loading: partnerLoading,
    callGeneratePartnerInfluencerSignUpUrl
  } = useGeneratePartnerInfluencerSignUpUrlMutation({
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const {
    data: talentAgencyData,
    loading: talentAgencyLoading,
    callGenerateTalentInfluencerSignUpUrl
  } = useGenerateTalentInfluencerSignUpUrlMutation({
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  useEffect(() => {
    if (isPartner) {
      callGeneratePartnerInfluencerSignUpUrl();
    } else if (isTalentAgency) {
      callGenerateTalentInfluencerSignUpUrl();
    }
  }, []);

  const loading = partnerLoading || talentAgencyLoading;
  const partnerUrl = partnerData?.generatePartnerInfluencerSignUpUrl?.url;
  const talentAgencyUrl = talentAgencyData?.generateTalentInfluencerSignUpUrl?.url;
  const signedUpUrl = partnerUrl
    ? `${partnerUrl}&agencyType=partner`
    : talentAgencyUrl
      ? `${talentAgencyUrl}&agencyType=talentAgency`
      : '';

  return signedUpUrl && !loading ? (
    <Notice
      type="info"
      title={t('Information')}
      css={{ marginBottom: '16px' }}
      description={
        <div css={{ display: 'flex' }}>
          <p>{t('If you would like the influencer to register himself, please send this URL')}</p>
          <Anchor css={{ fontWeight: 'inherit' }} variant="blue" href={signedUpUrl} label={signedUpUrl} lines={2} />
        </div>
      }
    />
  ) : null;
};
