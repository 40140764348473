import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CampaignSocialMediaType, EngagementCampaignPostStatusForInsightCheck } from '@/graphql';
import { Button, ButtonSubmit, ListEmpty, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import {
  PostInsightXhs,
  PostInsightTiktok,
  PostInsightDouyin,
  PostInsightTwitter,
  PostInsightYoutube,
  PostInsightFacebook,
  PostInsightInstagram,
  PostInsightInstagramStory
} from './postInsightContent';
import { SC } from './StyledComponents';

export interface TemplateProps {
  isReloading?: boolean;
  onSubmit?: () => void;
  onReloadData?: () => void;
  socialMedia?: CampaignSocialMediaType;
  status?: EngagementCampaignPostStatusForInsightCheck;
}

export const Template = ({ status, socialMedia, isReloading, onReloadData, onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const {
    formState: { isSubmitting }
  } = useFormContext();

  const isConfirmed = status === EngagementCampaignPostStatusForInsightCheck.CONFIRMED;

  const postInsightContent = (() => {
    switch (socialMedia) {
      case CampaignSocialMediaType.INSTAGRAM:
        return <PostInsightInstagram />;
      case CampaignSocialMediaType.INSTAGRAM_STORY:
        return <PostInsightInstagramStory />;
      case CampaignSocialMediaType.YOUTUBE:
        return <PostInsightYoutube />;
      case CampaignSocialMediaType.TWITTER:
        return <PostInsightTwitter />;
      case CampaignSocialMediaType.TIKTOK:
        return <PostInsightTiktok />;
      case CampaignSocialMediaType.DOUYIN:
        return <PostInsightDouyin />;
      case CampaignSocialMediaType.FACEBOOK:
        return <PostInsightFacebook />;
      case CampaignSocialMediaType.XHS:
        return <PostInsightXhs />;
      default:
        return null;
    }
  })();

  return postInsightContent ? (
    <>
      <div css={{ padding: '24px' }}>
        <SC.SectionTitle css={{ marginBottom: '34px', textAlign: 'center' }}>{t('Result')}</SC.SectionTitle>

        {postInsightContent}
      </div>

      <FormStyle.ButtonSubmitWrapper>
        {onReloadData ? (
          <Button
            variant="white"
            onClick={onReloadData}
            title={t('Button.Reload')}
            disabled={isReloading || isSubmitting}
            prefixIcon={{ icon: 'reload', size: '14px' }}
            loading={{ loading: isReloading, title: t('Button.Reloading') }}
          />
        ) : null}

        <ButtonSubmit
          onClick={onSubmit}
          loading={isSubmitting}
          css={{ padding: '0 12px' }}
          disabled={isSubmitting || isReloading}
          variant={isConfirmed ? 'white' : 'blue'}
          title={t(isConfirmed ? 'Button.Connected' : 'Button.Confirmed')} // TODO: fix button names to match translation
          prefixIcon={isConfirmed ? { icon: 'check', size: '10px', color: THEME.icon.colors.blue } : undefined}
        />
      </FormStyle.ButtonSubmitWrapper>
    </>
  ) : (
    <ListEmpty title={t('Not supported yet')} hideDescription />
  );
};
