import { useAuthData, useUserRoles } from '@/auth';
import { useAdvertiserForAddEngagementQuery, useCreateEngagementMutation } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useCustomHeader, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import {
  EngagementCampaignForm,
  EngagementCampaignFormValues,
  formatEngagementFormVariables,
  defaultEngagementCampaignFormValues
} from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const AddEngagement = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    href: '/engagement',
    title: 'documentTitle.AddEngagement',
    appHeader: 'appHeader.Add New Engagement'
  });
  const { auth } = useAuthData();
  const { isAdvertiser, isAgency } = useUserRoles();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateEngagement } = useCreateEngagementMutation();

  const { data, loading } = useAdvertiserForAddEngagementQuery({
    skip: !isAdvertiser,
    variables: { pk: auth.userId },
    fetchPolicy: 'cache-and-network'
  });

  const handleSubmit: FormAction<EngagementCampaignFormValues>['onSubmit'] = async (values) => {
    const input = formatEngagementFormVariables(values);
    const { budget, socialMedias, advertiserId, campaignCategoryId } = input;

    if (!advertiserId || !budget || socialMedias.length === 0 || !campaignCategoryId) {
      return;
    }

    try {
      const { data } = await callCreateEngagement({ variables: { input } });
      const createdId = data?.createEngagement?.id;
      enqueueSnackbar(t('succeededInCreating', { name: values.title }), { variant: 'success' });

      if (!createdId) {
        navigate({ to: '/engagement' });
      } else if (!isAdvertiser && !isAgency) {
        navigate({ to: '/engagement/$id/influencer', params: { id: createdId.toString() } });
      }
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name: values.title }), { variant: 'error' });
      enqueueSnackbar(t(error || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <RenderDataWithFallback loading={loading}>
      <div css={{ margin: '0 auto', width: THEME.container.details.width }}>
        <Header />

        <EngagementCampaignForm
          onSubmit={handleSubmit}
          defaultValues={{
            ...defaultEngagementCampaignFormValues,
            advertiser: data?.advertiserForAdvertiserUser
              ? {
                  value: data.advertiserForAdvertiserUser.id.toString(),
                  label: data?.advertiserForAdvertiserUser.name
                }
              : defaultEngagementCampaignFormValues.advertiser || null
          }}
        />
      </div>
    </RenderDataWithFallback>
  );
};
