import { usePermissions } from '../hooks';

const defaultPermissions = {
  hideAddAnalyticsBtn: false,
  hideEditAnalyticsBtn: false,
  hideDeleteAnalyticsBtn: false,
  hideDeleteCompareItem: false
};
export const useAnalyticsPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.analytics?.noView || permissions?.analytics?.view || permissions?.analytics === null) {
    return {
      hideAddAnalyticsBtn: true,
      hideEditAnalyticsBtn: true,
      hideDeleteAnalyticsBtn: true,
      hideDeleteCompareItem: true
    };
  } else if (permissions?.analytics?.manage) {
    return { ...defaultPermissions, hideDeleteAnalyticsBtn: true };
  }

  return defaultPermissions;
};
