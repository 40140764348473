import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateInternalMemo.graphql';
import { Mutation, MutationupdateInternalMemoArgs } from '../../__generated__/globalTypes';

export const useUpdateInternalMemoMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateInternalMemoArgs>
) => {
  const [callUpdateInternalMemo, result] = useMutation<Mutation, MutationupdateInternalMemoArgs>(MUTATION, options);

  return { callUpdateInternalMemo, ...result };
};

export type UpdateInternalMemoMutationFunction = Mutation['updateInternalMemo'];
