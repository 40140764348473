import React, { createContext, useContext } from 'react';
import { useUserRoles } from '@/auth';
import { AFFILIATE_CAMPAIGN_TYPES } from '@/shared/constants';
import { UpdateDocumentTitleProps, useGetDocumentParentHref, useMatchPaths } from '@/shared/hooks';
import { CampaignTrackingOption, MarketplaceCampaignStatus, MarketplaceDetail } from '@/graphql';

interface ContextType {
  marketplaceId: number;
  hasTrackingTune: boolean;
  hasTrackingPost: boolean;
  hasTrackingReferral: boolean;
  isAffiliateCampaign: boolean;
  marketplace: MarketplaceDetail;
  forceInfluencersParticipateCampaign: boolean;
}

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  value: Pick<ContextType, 'marketplace'>;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const MarketplaceDetailsProvider = ({ children, value: { marketplace } }: Props) => {
  const { isAdminStaff } = useUserRoles();
  const { matchPaths, matchRoute } = useMatchPaths();
  const isReportPage = !!matchPaths(['/marketplace/$id/report', '/marketplace/$id/report-affiliate']);
  const isPostsPage = !!matchRoute({ to: '/marketplace/$id/posts' });

  let documents: UpdateDocumentTitleProps = {
    href: '/marketplace',
    alwaysBackToParent: true,
    title: 'documentTitle.EditMarketplace',
    appHeader: 'appHeader.Campaign Title',
    interpolation: { campaignTitle: marketplace.title }
  };

  if (isPostsPage) {
    documents = {
      ...documents,
      interpolation: { campaignTitle: marketplace.title },
      title: marketplace.title ? 'documentTitle.PostWithTitle' : 'documentTitle.PostDefault',
      appHeader: marketplace.title ? 'appHeader.PostWithTitle' : 'appHeader.PostDefault'
    };
  } else if (isReportPage) {
    documents = {
      ...documents,
      interpolation: { title: marketplace.title },
      title: marketplace.title ? 'documentTitle.PostReportWithTitle' : 'documentTitle.PostReportDefault',
      appHeader: marketplace.title ? 'appHeader.PostReportWithTitle' : 'appHeader.PostReportDefault'
    };
  }

  useGetDocumentParentHref(documents);

  const { id, campaignType, trackingOptions, isPostTracking } = marketplace;

  return (
    <Context.Provider
      value={{
        marketplace,
        marketplaceId: id,
        hasTrackingPost: isPostTracking,
        isAffiliateCampaign: AFFILIATE_CAMPAIGN_TYPES.includes(campaignType),
        hasTrackingTune: !!trackingOptions?.includes(CampaignTrackingOption.TUNE),
        hasTrackingReferral: !!trackingOptions?.includes(CampaignTrackingOption.REFERRAL_CODE),
        forceInfluencersParticipateCampaign:
          isAdminStaff &&
          [MarketplaceCampaignStatus.UPCOMING, MarketplaceCampaignStatus.ONGOING].includes(marketplace.status)
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useMarketplaceDetailsContext = () => useContext<ContextType>(Context);
