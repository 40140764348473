import { useEffect } from 'react';
import {
  useGetYoutubeMCNRedirectUrlForAdminQuery,
  useUpdateYoutubeMCNInfluencerCredentialsForAdminMutation
} from '@/graphql';
import { Button } from '@/shared/atoms';
import { useUpdateDocumentTitle, useQueryHelper } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { manageLocalStorage } from '@/shared/utils';

const callbackUrl = `${window.location.origin}/redirect/hidden/youtube-list-connect`;

export const RedirectHiddenYoutubeListConnect = () => {
  useUpdateDocumentTitle({
    title: 'MCN Influencers',
    appHeader: 'MCN Influencers',
    href: '/report',
    alwaysBackToParent: true
  });
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const search = window.location.search;
  const { data, loading } = useGetYoutubeMCNRedirectUrlForAdminQuery({ variables: { input: { callbackUrl } } });
  const { callUpdateYoutubeMCNInfluencerCredentialsForAdmin } =
    useUpdateYoutubeMCNInfluencerCredentialsForAdminMutation({
      onCompleted: () => {
        enqueueSnackbar(t('Successfully reconnected'), { variant: 'success' });
        // stay on the same page just clear the search params
        navigate({ to: '/redirect/hidden/youtube-list-connect' });
        manageLocalStorage('remove', 'accountType');
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    });

  useEffect(() => {
    const hasCode = new URLSearchParams(search).get('code');

    if (hasCode) {
      callUpdateYoutubeMCNInfluencerCredentialsForAdmin({
        variables: { input: { callbackUrl, response: search.substring(1) } }
      });
    }
  }, [search]);

  const redirectUrl = data?.getYoutubeMCNRedirectUrlForAdmin?.redirectUri;

  const handleReconnect = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return (
    <div css={{ padding: '24px' }}>
      <Header />

      <div css={{ width: '250px', margin: '300px auto' }}>
        <Button
          variant="white"
          isDisabled={!redirectUrl}
          onClick={handleReconnect}
          title={t('Button.Connect')}
          loading={{ status: loading, showIcon: true }}
          css={{
            height: '40px',
            minWidth: '150px',
            marginTop: '24px',
            borderRadius: '20px',
            textTransform: 'uppercase'
          }}
        />
      </div>
    </div>
  );
};
