import { useDeepCompareEffect } from '@/shared/hooks';
import { useInfluencerProfileSearch } from '@/shared/organisms';
import { SocialAccountType, XhsPostType } from '@/graphql';
import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import { getAllAvailableInfluencerAccounts } from '../utils';

export const useInitialInfluencerAccountProfile = () => {
  const { influencerProfile } = useInfluencerProfileContext();
  const { socialMedia, socialAccountId, reinitParamsSearch } = useInfluencerProfileSearch();

  const allAvailableAccounts = getAllAvailableInfluencerAccounts(influencerProfile);
  const firstAccount = allAvailableAccounts.at(0);
  const selectedSocialAccount =
    allAvailableAccounts.find((account) => account.id.toString() === socialAccountId && account.type === socialMedia) ||
    firstAccount;

  useDeepCompareEffect(() => {
    // When come to influencer profile without id & sm params, we will select first account as default
    if ((!socialMedia || !socialAccountId) && firstAccount) {
      reinitParamsSearch({
        id: firstAccount.id.toString(),
        sm: firstAccount.type as SocialAccountType,
        lastUpdated: '',
        xhsPostType: XhsPostType.NORMAL
      });
    }
  }, [socialMedia, socialAccountId, firstAccount]);

  return { allAvailableAccounts, selectedSocialAccount };
};
