import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateSocialAccounts.graphql';
import { Mutation, MutationupdateSocialAccountsArgs } from '../../__generated__/globalTypes';

export const useUpdateSocialAccountsMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateSocialAccountsArgs>
) => {
  const [callUpdateSocialAccounts, result] = useMutation<Mutation, MutationupdateSocialAccountsArgs>(MUTATION, options);

  return { callUpdateSocialAccounts, ...result };
};

export type UpdateSocialAccountsMutationFunction = Mutation['updateSocialAccounts'];
