import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Button, FormStyle } from '@/shared/atoms';
import { SelectedRangeOption, PostReportMode } from '@/shared/types';
import {
  TextSearchField,
  RangeDatePickerField,
  BasicFilterWrapper,
  PostReportFilterFormValues,
  PostReportFilterFormKeys
} from '@/shared/molecules';

export interface TemplateProps {
  children?: ReactNode;
  onSubmit?: () => void;
}

export const Template = ({ onSubmit, children }: TemplateProps) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<PostReportFilterFormValues>();
  const [modeValue, startDateValue, endDateValue] = watch(['mode', 'startDate', 'endDate']);

  const isAllTime = !startDateValue && !endDateValue;
  const isGridMode = modeValue === PostReportMode.GRID;

  const handleModeChange = (mode: PostReportMode) => {
    setValue('mode', mode);
    onSubmit?.();
  };

  return (
    <BasicFilterWrapper css={{ padding: '12px 16px' }}>
      <FormStyle.FieldWrapper css={{ display: 'flex', flex: 'none', marginRight: 'auto' }}>
        <Button
          variant="default"
          title={t('Summary')}
          css={styles.button(isGridMode)}
          onClick={() => handleModeChange(PostReportMode.GRID)}
          prefixIcon={{ icon: 'grid', size: '16px', css: styles.icon }}
        />
        <Button
          variant="default"
          title={t('Details')}
          css={styles.button(!isGridMode)}
          onClick={() => handleModeChange(PostReportMode.TABLE)}
          prefixIcon={{ icon: 'list', size: '16px', css: styles.icon }}
        />
      </FormStyle.FieldWrapper>

      {!isGridMode ? (
        <>
          <TextSearchField<PostReportFilterFormKeys>
            name="keyword"
            onSubmit={onSubmit}
            placeholder={t<string>('Search.PostReportFilter')}
          />
          <RangeDatePickerField<PostReportFilterFormKeys>
            onSubmit={onSubmit}
            css={{ flex: 'none' }}
            clearTitle={t('All time')}
            name={{ startDate: 'startDate', endDate: 'endDate' }}
            customInputTextNode={isAllTime ? <p>{t('All time')}</p> : undefined}
            rangeList={[
              SelectedRangeOption.TODAY,
              SelectedRangeOption.LAST_SEVEN_DAYS,
              SelectedRangeOption.TODAY_TO_MONTH_AGO,
              SelectedRangeOption.THIS_MONTH,
              SelectedRangeOption.LAST_MONTH,
              SelectedRangeOption.CLEAR,
              SelectedRangeOption.CUSTOM
            ]}
          />
        </>
      ) : null}

      {children}
    </BasicFilterWrapper>
  );
};
const styles = {
  icon: css({ marginRight: '4px' }),
  button: (isActive?: boolean) =>
    css({
      minWidth: '93px',
      ...(isActive
        ? { zIndex: 1, color: '#3892E5', borderColor: '#3892E5', background: 'rgba(56, 146, 229, 0.1)' }
        : { color: THEME.text.colors.gray.lv3 }),
      '&:first-of-type': { borderRadius: '3px 0 0 3px' },
      '&:last-of-type': { marginLeft: '-1px', borderRadius: '0 3px 3px 0' }
    })
};
