import { LinkProps, useMatchRoute } from '@tanstack/react-router';

export const useMatchPaths = (defaultPaths: ReadonlyArray<LinkProps['to']> = []) => {
  const matchRoute = useMatchRoute();

  const matchPaths = (paths: ReadonlyArray<LinkProps['to']>) =>
    paths.length > 0 ? paths.some((path) => !!matchRoute({ to: path, includeSearch: false })) : false;

  return {
    matched: matchPaths(defaultPaths),
    matchPaths,
    matchRoute
  };
};
