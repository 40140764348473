import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

interface SliderArrowProps {
  className?: string;
  onClick: () => void;
  hasBgImage?: boolean;
  type: 'next' | 'prev';
}

export const SliderArrow = ({ type, hasBgImage = true, ...rest }: SliderArrowProps) => (
  <div css={styles.wrapper(type)} {...rest}>
    <div css={styles.shadow({ type, hasBgImage })} />
    <Icon size={10} color="#6e7c89" icon={type === 'next' ? 'caret-right' : 'caret-left'} />
  </div>
);
const styles = {
  wrapper: (type: SliderArrowProps['type']) =>
    css({
      top: 0,
      zIndex: 1,
      width: '28px',
      display: 'flex',
      fontSize: '16px',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100% - 4px)',
      border: THEME.border.base,
      color: THEME.text.colors.gray.lv3,
      backgroundColor: THEME.background.colors.white,
      ...(type === 'next' ? { right: 0 } : { left: 0 }),
      '&:hover': { cursor: 'pointer' }
    }),
  shadow: ({ type, hasBgImage }: Pick<SliderArrowProps, 'type' | 'hasBgImage'>) =>
    css({
      top: '-1px',
      width: '36px',
      position: 'absolute',
      height: 'calc(100% + 2px)',
      ...(hasBgImage
        ? type === 'next'
          ? { right: '29px', backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), #f9fbfc)' }
          : { left: '29px', backgroundImage: 'linear-gradient(to left, rgba(255, 255, 255, 0), #f9fbfc)' }
        : {})
    })
};
