import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { tiktokCheckingFailedImage, tiktokCheckingImage } from '@/shared/assets';
import { Button, CircularProgress, Icon, MediaPreview, Modal, ModalContent, ModalProps } from '@/shared/atoms';

export interface CheckingPlanSubscriptionModalProps extends Pick<ModalProps, 'open'> {
  retrying?: boolean;
  refreshing?: boolean;
  onRetry?: () => void;
  onCancel?: () => void;
  onRefresh?: () => void;
  type: 'checking' | 'failed' | 'success' | 'unknown';
}

export const CheckingPlanSubscriptionModal = ({
  type,
  onRetry,
  onCancel,
  onRefresh,
  retrying,
  refreshing,
  ...restProps
}: CheckingPlanSubscriptionModalProps) => {
  const { t } = useTranslation();

  const { icon, title, image, actions, description } = (() => {
    switch (type) {
      case 'failed':
        return {
          title: t('Payment Failed'),
          image: tiktokCheckingFailedImage,
          description: t('failedCampaignDescription'),
          icon: <Icon size={16} icon="fail" color={THEME.icon.colors.red.lv1} />,
          actions: (
            <>
              <Button onClick={onCancel} variant="white">
                {t('Button.Cancel')}
              </Button>
              <Button onClick={onRetry} variant="blue" loading={{ showIcon: true, status: retrying }}>
                {t('Button.Try Again')}
              </Button>
            </>
          )
        };
      case 'unknown':
        return {
          image: tiktokCheckingImage,
          title: t('Campaign Creating'),
          description: t('Please refresh'),
          icon: <CircularProgress size={16} thickness={0.5} backgroundColor={THEME.icon.colors.blue} />,
          actions: (
            <Button variant="blue" onClick={onRefresh} loading={{ showIcon: true, status: refreshing }}>
              {t('Button.Refresh')}
            </Button>
          )
        };
      case 'checking':
      default:
        return {
          actions: null,
          image: tiktokCheckingImage,
          title: t('Campaign Creating'),
          description: t('creatingCampaignDescription'),
          icon: <CircularProgress size={16} thickness={0.5} backgroundColor={THEME.icon.colors.blue} />
        };
    }
  })();

  return (
    <Modal
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      css={{ width: THEME.modal.size.lv1 }}
      style={{ overlay: { left: THEME.container.menu.width } }}
      {...restProps}
    >
      <ModalContent css={{ padding: 0 }}>
        <div css={{ padding: '20px 32px 24px', boxSizing: 'border-box' }}>
          <div css={styles.mediaPreviewWrapper}>
            <MediaPreview css={styles.mediaPreview} src={image} />
          </div>
          <div css={{ height: '16px', width: 'max-content', margin: '8px auto 0' }}>{icon}</div>
          <p css={[styles.text, { fontSize: '16px', fontWeight: 600 }]}>{title}</p>
          <p css={[styles.text, { fontSize: '12px', color: THEME.text.colors.gray.lv3 }]}>{description}</p>
        </div>

        {actions && <div css={styles.footer}>{actions}</div>}
      </ModalContent>
    </Modal>
  );
};
const styles = {
  text: css({
    marginTop: '8px',
    textAlign: 'center',
    lineHeight: '100%'
  }),
  footer: css({
    gap: '8px',
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    borderTop: THEME.border.base
  }),
  mediaPreviewWrapper: css({
    width: '386px',
    height: '174.6px',
    borderRadius: '8px',
    alignContent: 'center',
    margin: '0 auto',
    background: THEME.background.colors.gray.lv1
  }),
  mediaPreview: css({
    width: '169.15px',
    height: '140.15px',
    margin: '0 auto',
    background: 'transparent'
  })
};
