import { useTranslation } from 'react-i18next';
import { ListEmpty, RenderDataWithFallback } from '@/shared/atoms';
import { SelectCampaignPlan } from './SelectCampaignPlan';
import { useAutoCreateTikTokCampaign, useCheckingPlanSubscription, useSelectCampaignPlan } from './hooks';
import { CheckingPlanSubscriptionModal, CheckingPlanSubscriptionModalProps } from './CheckingPlanSubscriptionModal';

export const CheckingPlanSubscription = () => {
  const { t } = useTranslation();
  const { selectedPlanId, handleSelectPlan } = useSelectCampaignPlan();
  const {
    priceId,
    loading,
    packageId,
    currentPlan,
    refreshState,
    handleRefresh,
    paymentFailed,
    paymentSuccess,
    marketplacePlans,
    paymentInProgress,
    checkingStripePayment,
    clearCheckingPlanFlows,
    recalledPricingTableApi,
    recalledPaymentStatusApi
  } = useCheckingPlanSubscription();
  const { creatingCampaign } = useAutoCreateTikTokCampaign({ currentPlan });

  const modalType = ((): CheckingPlanSubscriptionModalProps['type'] => {
    if (creatingCampaign) return 'checking';

    if (paymentInProgress) return refreshState.called ? 'failed' : recalledPaymentStatusApi ? 'unknown' : 'checking';

    if (paymentFailed) return 'failed';

    if (paymentSuccess && !currentPlan)
      return refreshState.called ? 'failed' : recalledPricingTableApi ? 'unknown' : 'checking';

    return 'success';
  })();

  return (
    <RenderDataWithFallback
      loading={loading}
      hasNoData={!marketplacePlans.length}
      noDataNode={<ListEmpty title={t('No plan found')} hideDescription />}
    >
      <SelectCampaignPlan marketplacePlans={marketplacePlans} />

      <CheckingPlanSubscriptionModal
        type={modalType}
        onRefresh={handleRefresh}
        onCancel={clearCheckingPlanFlows}
        refreshing={refreshState.loading}
        open={checkingStripePayment || creatingCampaign}
        retrying={!!priceId && selectedPlanId === priceId}
        onRetry={() => handleSelectPlan(priceId || null, packageId || null)}
      />
    </RenderDataWithFallback>
  );
};
