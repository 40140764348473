import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AgencyLogo.graphql';
import { Query, QueryagencyLogoArgs } from '../../__generated__/globalTypes';

export const useAgencyLogoQuery = (options?: QueryHookOptions<Query, QueryagencyLogoArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryagencyLogoArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAgencyLogoLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryagencyLogoArgs>
): QueryResult<Query, QueryagencyLogoArgs> & { getAgencyLogo: LazyQueryExecFunction<Query, QueryagencyLogoArgs> } => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAgencyLogo, result] = useLazyQuery<Query, QueryagencyLogoArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAgencyLogo, ...result };
};

export const useAgencyLogoPromiseQuery = () => {
  const client = useApolloClient();

  const getAgencyLogo = useCallback(
    (options?: Omit<QueryOptions<QueryagencyLogoArgs, Query>, 'query'>) =>
      client.query<Query, QueryagencyLogoArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAgencyLogo };
};
