import { useTranslation } from 'react-i18next';
import { useInfluencerPermissions, useUserRoles } from '@/auth';
import { ButtonLink } from '@/shared/atoms';

interface ButtonAddInfluencerProps {
  className?: string;
}

export const ButtonAddInfluencer = ({ className }: ButtonAddInfluencerProps) => {
  const { t } = useTranslation();
  const { hideAddInfluencerBtn } = useInfluencerPermissions();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();

  const hasAddInfluencerBtn = isAdminStaffTalentAgencyPartner && !hideAddInfluencerBtn;

  return hasAddInfluencerBtn ? (
    <ButtonLink
      variant="blue"
      className={className}
      title={t('Button.Add Influencer')}
      prefixIcon={{ icon: 'plus', size: '10px' }}
      css={{ minWidth: '136px', textTransform: 'capitalize', marginLeft: 'auto' }}
      to="/influencer/add"
    />
  ) : null;
};
