import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/InfluencerSocialAuthReconnectInstagramAccountAnalytics.graphql';
import {
  Mutation,
  MutationinfluencerSocialAuthReconnectInstagramAccountAnalyticsArgs
} from '../../__generated__/globalTypes';

export const useInfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationinfluencerSocialAuthReconnectInstagramAccountAnalyticsArgs>
) => {
  const [callInfluencerSocialAuthReconnectInstagramAccountAnalytics, result] = useMutation<
    Mutation,
    MutationinfluencerSocialAuthReconnectInstagramAccountAnalyticsArgs
  >(MUTATION, options);

  return { callInfluencerSocialAuthReconnectInstagramAccountAnalytics, ...result };
};

export type InfluencerSocialAuthReconnectInstagramAccountAnalyticsMutationFunction =
  Mutation['influencerSocialAuthReconnectInstagramAccountAnalytics'];
