import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateAgency.graphql';
import { Mutation, MutationupdateAgencyArgs } from '../../__generated__/globalTypes';

export const useUpdateAgencyMutation = (options?: MutationHookOptions<Mutation, MutationupdateAgencyArgs>) => {
  const [callUpdateAgency, result] = useMutation<Mutation, MutationupdateAgencyArgs>(MUTATION, options);

  return { callUpdateAgency, ...result };
};

export type UpdateAgencyMutationFunction = Mutation['updateAgency'];
