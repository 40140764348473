import { useParams } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import {
  useAccountAnalyticDetailsV2Query,
  EditSocialAccountAnalyticsAdvertiserInputV2,
  useEditSocialAccountAnalyticsAdminV2Mutation,
  useEditSocialAccountAnalyticsAdvertiserV2Mutation
} from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useCustomHeader, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { AnalyticsForm, AnalyticsFormValues, getAnalyticsFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const EditAnalyticsAccount = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    title: 'documentTitle.EditAccount',
    appHeader: 'appHeader.Edit Account',
    href: '/analytics'
  });
  const { isAdvertiser } = useUserRoles();
  const params = useParams({ from: '/_private-routes/analytics/$brandAccountId/' });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callEditSocialAccountAnalyticsAdminV2 } = useEditSocialAccountAnalyticsAdminV2Mutation();
  const { callEditSocialAccountAnalyticsAdvertiserV2 } = useEditSocialAccountAnalyticsAdvertiserV2Mutation();

  const brandAccountId = Number(params.brandAccountId);

  const { data, loading } = useAccountAnalyticDetailsV2Query({
    skip: !params.brandAccountId,
    variables: { id: Number(params.brandAccountId) },
    fetchPolicy: 'network-only',
    onError: () => {
      enqueueSnackbar(t(`Account doesn't exist`), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  const handleSubmit: FormAction<AnalyticsFormValues>['onSubmit'] = async (values) => {
    try {
      const input: EditSocialAccountAnalyticsAdvertiserInputV2 = {
        brandAccountId,
        accountName: values.accountName,
        youtubeChannelId: values.youtube?.channelId,
        igFacebookPageId: values.instagram?.fbPageId,
        twitterUserId: values.twitter?.twitterUserId,
        facebookPageId: values.facebookPage?.fbPageId
      };

      if (isAdvertiser) {
        await callEditSocialAccountAnalyticsAdvertiserV2({ variables: { input } });
      } else {
        await callEditSocialAccountAnalyticsAdminV2({
          variables: { input: { ...input, accountManagersPICs: values.accountManagerIds?.map(Number) } }
        });
      }

      enqueueSnackbar(t('succeededInUpdating', { name: values.accountName }), { variant: 'success' });
      navigate({ to: '/analytics' });
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: values.accountName }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
      <Header />

      <RenderDataWithFallback loading={loading}>
        <AnalyticsForm onSubmit={handleSubmit} defaultValues={getAnalyticsFormValues(data?.accountAnalyticDetailsV2)} />
      </RenderDataWithFallback>
    </div>
  );
};
