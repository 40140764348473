import { Trans } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { SelectorField } from '@/shared/molecules';
import { FormAction, Option } from '@/shared/types';
import { useDeepCompareEffect, useQueryHelper } from '@/shared/hooks';
import { MarketplaceParticipateInfluencerStatus } from '@/shared/organisms';
import {
  Modal,
  ModalProps,
  ModalTitle,
  ModalContent,
  FormProvider,
  ModalFooterActions,
  RenderDataWithFallback
} from '@/shared/atoms';
import {
  namedOperations,
  MarketplaceInfluencer,
  CampaignPromotionMethod,
  CampaignSocialMediaType,
  useJoinMarketplaceByStaffMutation,
  MarketplaceCampaignParticipateStatus,
  useInfluencerMultipleSocialAccountsCountLazyQuery
} from '@/graphql';
import { useMarketplaceDetailsContext } from '../../../MarketplaceDetailsContext';
import {
  ConfirmInfluencersParticipateCampaignSchema,
  ConfirmInfluencersParticipateCampaignFormKeys,
  ConfirmInfluencersParticipateCampaignFormValues
} from './types';

export interface ConfirmInfluencersParticipateCampaignModalProps extends Omit<ModalProps, 'children'> {
  resetSelectedInfluencerIds?: () => void;
  selectedInfluencerIds: Array<MarketplaceInfluencer['id']>;
}

export const ConfirmInfluencersParticipateCampaignModal = ({
  open,
  onClose,
  selectedInfluencerIds,
  resetSelectedInfluencerIds,
  ...restProps
}: ConfirmInfluencersParticipateCampaignModalProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const {
    marketplaceId,
    isAffiliateCampaign,
    marketplace: { socialMedia, isPostTracking }
  } = useMarketplaceDetailsContext();
  const {
    data,
    loading: checkingInfluencers,
    getInfluencerMultipleSocialAccountsCount
  } = useInfluencerMultipleSocialAccountsCountLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { socialMedia, influencerIds: selectedInfluencerIds }
  });
  const { callJoinMarketplaceByStaff, loading: joiningInfluencers } = useJoinMarketplaceByStaffMutation({
    refetchQueries: [namedOperations.Query.AllInfluencersForMarketplaceV2]
  });

  useDeepCompareEffect(() => {
    // We don't care that campaign isAffiliateCampaign or not
    // We only care about `isPostTracking: true` because in this case always have a specific socialMedia selected, then we can check the selected influencers with that value
    if (open && isPostTracking && selectedInfluencerIds.length) {
      getInfluencerMultipleSocialAccountsCount();
    }
  }, [open, isPostTracking, selectedInfluencerIds]);

  // Only needSpecifyMethods in cases
  // 1. is Affiliate Campaign and without Post Tracking
  // 2. selected socialMedia has no specific value (UNSELECTED, OTHER), but if `isPostTracking: true` this case never happen because we required user specify a social media so we no need to check this case
  // No need to check Social Media campaign type because isPostTracking always enabled and selected socialMedia always has specific value
  const needSpecifyMethods = isAffiliateCampaign && !isPostTracking;
  const numberOfInfluencerMultipleAccounts = data?.influencerMultipleSocialAccountsCount?.totalNumber;

  const methodsOptions = needSpecifyMethods
    ? [
        ...(socialMedia === CampaignSocialMediaType.UNSELECT
          ? [
              CampaignPromotionMethod.INSTAGRAM,
              CampaignPromotionMethod.INSTAGRAM_STORY,
              CampaignPromotionMethod.FACEBOOK,
              CampaignPromotionMethod.YOUTUBE,
              CampaignPromotionMethod.TWITTER,
              CampaignPromotionMethod.TIKTOK
            ]
          : []),
        CampaignPromotionMethod.WEBSITE,
        CampaignPromotionMethod.EMAIL_NEWSLETTER,
        CampaignPromotionMethod.MOBILE_APP,
        CampaignPromotionMethod.PODCAST,
        CampaignPromotionMethod.OFFLINE
      ].map<Option<CampaignPromotionMethod>>((value) => ({ value, label: t<string>(`PromotionMethod.${value}`) }))
    : [];

  const handleSubmit: FormAction<ConfirmInfluencersParticipateCampaignFormValues>['onSubmit'] = async (
    { methods },
    { reset }
  ) => {
    if (needSpecifyMethods && !methods?.length) return;

    try {
      await callJoinMarketplaceByStaff({
        variables: { input: { methods, campaignId: marketplaceId, influencerIds: selectedInfluencerIds } }
      });

      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      resetSelectedInfluencerIds?.();
      onClose?.();
      reset();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <FormProvider<ConfirmInfluencersParticipateCampaignFormValues>
      onSubmit={handleSubmit}
      defaultValues={{ methods: [] }}
      zodSchema={needSpecifyMethods ? ConfirmInfluencersParticipateCampaignSchema : undefined}
    >
      {({ values, onSubmit }) => (
        <Modal open={open} onClose={onClose} css={{ width: THEME.modal.size.lv1 }} {...restProps}>
          <RenderDataWithFallback loading={checkingInfluencers}>
            <ModalContent>
              <ModalTitle>{t('Modal.Mark as Joined')}</ModalTitle>

              <p css={{ marginTop: '24px', fontSize: '14px', lineHeight: '140%' }}>
                <Trans i18nKey="Modal.MarkAsJoinedDescription" components={{ br: <br /> }} />
              </p>

              {numberOfInfluencerMultipleAccounts ? (
                <div css={{ marginTop: '24px', fontSize: '14px', lineHeight: '140%' }}>
                  <Trans
                    values={{
                      count: numberOfInfluencerMultipleAccounts,
                      status: MarketplaceCampaignParticipateStatus.WAITING
                    }}
                    i18nKey={
                      numberOfInfluencerMultipleAccounts > 1
                        ? 'Modal.MarkAsJoinedNoticePlural'
                        : 'Modal.MarkAsJoinedNoticeSingular'
                    }
                    components={{
                      1: <span css={{ fontWeight: 600 }} />,
                      2: (
                        <MarketplaceParticipateInfluencerStatus
                          status={MarketplaceCampaignParticipateStatus.WAITING}
                          css={{ height: 'max-content', display: 'inline-block', padding: '3px 8px' }}
                        />
                      )
                    }}
                  />
                </div>
              ) : null}

              {needSpecifyMethods ? (
                <SelectorField<ConfirmInfluencersParticipateCampaignFormKeys>
                  name="methods"
                  options={methodsOptions}
                  css={{ marginTop: '16px' }}
                  title={t('Selector.Select promote media')}
                  required
                  multiple
                />
              ) : null}
            </ModalContent>

            <ModalFooterActions
              cancelButtonProps={{ onClick: onClose }}
              submitButtonProps={{
                onClick: onSubmit,
                loading: joiningInfluencers,
                title: t('Button.Mark as Joined'),
                disabled: needSpecifyMethods && !values.methods.length
              }}
            />
          </RenderDataWithFallback>
        </Modal>
      )}
    </FormProvider>
  );
};
