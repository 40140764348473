import { ReactNode } from 'react';
import { CampaignType, EngagementPostDetail } from '@/graphql';
import { PostDetailsType } from '@/shared/types';
import {
  InfluencerPostDetails,
  TikTokSpecialPostDetails,
  EngagementPostDetailsTabsType,
  MarketplacePostDetailsTabsType,
  EngagementInfluencerPostDetails,
  MarketplaceInfluencerPostDetails
} from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

type InfluencerPostDetailsType = Pick<PostDetailsType, 'id' | 'campaignType' | 'influencerId'>;
type PostType<T extends InfluencerPostDetailsType, C = T['campaignType']> = C extends CampaignType.ENGAGEMENT
  ? EngagementPostDetail | null
  : undefined;
type TabType<T extends InfluencerPostDetailsType, C = T['campaignType']> = C extends CampaignType.ENGAGEMENT
  ? EngagementPostDetailsTabsType
  : C extends CampaignType.MARKETPLACE
    ? MarketplacePostDetailsTabsType
    : undefined;

export interface InfluencerPostDetailsModalProps<T extends InfluencerPostDetailsType> {
  posts: T[];
  paramKey?: string;
  hasHistoryTab?: boolean;
  hasSimilarPosts?: boolean;
  hasTrafficSource?: boolean;
  footerNode?: (props: { selectedPost: T; tab: TabType<T>; postDetails: PostType<T> }) => ReactNode;
}

export const InfluencerPostDetailsModal = <T extends InfluencerPostDetailsType>({
  posts,
  paramKey,
  footerNode,
  hasHistoryTab,
  hasSimilarPosts,
  hasTrafficSource
}: InfluencerPostDetailsModalProps<T>) => (
  <CarouselTemplateModal posts={posts} paramKey={paramKey}>
    {({ selectedPost, closeModal }) =>
      selectedPost.campaignType === CampaignType.ENGAGEMENT ? (
        <EngagementInfluencerPostDetails
          id={selectedPost.id}
          onCloseModal={closeModal}
          hasHistoryTab={hasHistoryTab}
          hasTrafficSource={hasTrafficSource}
          footerNode={({ tab, postDetails }) =>
            footerNode?.({ selectedPost, tab: tab as TabType<T>, postDetails: postDetails as PostType<T> })
          }
        />
      ) : selectedPost.campaignType === CampaignType.MARKETPLACE ? (
        <MarketplaceInfluencerPostDetails id={selectedPost.id} onCloseModal={closeModal} />
      ) : selectedPost.campaignType === CampaignType.TIKTOK_SPECIAL ? (
        <TikTokSpecialPostDetails id={selectedPost.id} onCloseModal={closeModal} />
      ) : (
        <InfluencerPostDetails
          id={selectedPost.id}
          onCloseModal={closeModal}
          hasSimilarPosts={hasSimilarPosts}
          influencerId={selectedPost?.influencerId}
        />
      )
    }
  </CarouselTemplateModal>
);
