import { useTranslation } from 'react-i18next';
import { DownloadButton } from '@/shared/atoms';
import { ListWithPagination } from '@/shared/molecules';
import { useDownloadFile, useParamsSearch } from '@/shared/hooks';
import { useYoutubeAnalyticsPostsQuery } from '@/graphql';
import { ProfilePostsCount, ProfilePostsFilter, AnalyticsYoutubeVideosList } from '@/shared/organisms';
import { getOffset, getValuableVariables, getAccountManagementApiUrl } from '@/shared/utils';
import { useInitialAnalyticsPosts } from '../hooks';
import { AnalyticsYtVideosSearchSchemaType } from './schemaTypes';

export const AnalyticsYoutubeVideos = () => {
  const { t } = useTranslation();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { brandAccountId, mainSocialAccountId } = useInitialAnalyticsPosts({
    socialMedia: 'youtube',
    document: (name) => ({
      title: name ? 'appHeader.VideoListWithAccountName' : 'Video List',
      appHeader: name ? 'appHeader.VideoListWithAccountName' : 'Video List'
    })
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<AnalyticsYtVideosSearchSchemaType>();
  const { data, previousData, loading } = useYoutubeAnalyticsPostsQuery({
    skip: !brandAccountId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      orderBy: sort,
      offset: getOffset(page, limit),
      youtubeAnalyticsAccountId: Number(mainSocialAccountId),
      ...filter
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.youtubeAnalyticsPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

      <ProfilePostsCount count={totalRecords} countLabel={t('Videos found')}>
        <DownloadButton
          loading={downloading}
          onClick={() =>
            handleDownloadFile({
              filenamePrefix: 'analyticsYTComments',
              url: getAccountManagementApiUrl(`api/analytics/youtube/comments/${mainSocialAccountId}`),
              params: getValuableVariables({ endDate: filter.endDate, startDate: filter.startDate })
            })
          }
        />
      </ProfilePostsCount>

      <AnalyticsYoutubeVideosList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.youtubeAnalyticsPosts.posts || []}
      />
    </ListWithPagination>
  );
};
