import { DownloadButton } from '@/shared/atoms';
import { ListWithPagination } from '@/shared/molecules';
import { useDownloadFile, useParamsSearch } from '@/shared/hooks';
import { useInfluencerAllInstagramFeedPostsQuery } from '@/graphql';
import { convertOrderToORDER, getOffset, getUploadApiUrl, getValuableVariables } from '@/shared/utils';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  InfluencerInstagramPostsList,
  InfluencerInstagramPostsListSearchType
} from '@/shared/organisms';
import { useInitialInfluencerPostsListWithName } from '../hooks';

export const InfluencerInstagramPosts = () => {
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName();
  const { page, limit, sort, filter, setFilter } = useParamsSearch<InfluencerInstagramPostsListSearchType>();
  const { data, previousData, loading } = useInfluencerAllInstagramFeedPostsQuery({
    skip: !influencerId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      socialAccountId: mainSocialAccountId,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      endDate: null,
      startDate: null,
      keyword: filter.keyword
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerAllInstagramFeedPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} />

      <ProfilePostsCount count={totalRecords}>
        <DownloadButton
          loading={downloading}
          onClick={() =>
            handleDownloadFile({
              filenamePrefix: 'feedPosts',
              url: getUploadApiUrl(`analytics/feed_posts/influencer/${mainSocialAccountId}/csv/custom`)
            })
          }
        />
      </ProfilePostsCount>

      <InfluencerInstagramPostsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.influencerAllInstagramFeedPosts || []}
      />
    </ListWithPagination>
  );
};
