import { useQueryHelper, useSelectItem } from '@/shared/hooks';
import { namedOperations, useClearCmsChannelAssetOwnerMutation } from '@/graphql';
import { YoutubeCMSAsset } from '../types';

export const useDeleteYoutubeChannelAssetOwner = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const {
    selectedItem: deletingAssetOwner,
    setSelectedItem: setDeletingAssetOwner,
    resetSelectedItem: resetDeletingAssetOwner
  } = useSelectItem<YoutubeCMSAsset>();
  const { callClearCmsChannelAssetOwner, loading } = useClearCmsChannelAssetOwnerMutation({
    refetchQueries: [namedOperations.Query.AllYoutubeCmsEstimateAssets]
  });

  const handleDeleteAssetOwner = async () => {
    if (!deletingAssetOwner || !deletingAssetOwner.id || !deletingAssetOwner.ownerChannelId) {
      return;
    }

    try {
      await callClearCmsChannelAssetOwner({
        variables: { input: { id: deletingAssetOwner.id, channelId: deletingAssetOwner.ownerChannelId } }
      });

      enqueueSnackbar(t('Deleted owner successfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      resetDeletingAssetOwner();
    }
  };

  return {
    deleting: loading,
    deletingAssetOwner,
    setDeletingAssetOwner,
    handleDeleteAssetOwner,
    resetDeletingAssetOwner
  };
};
