import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';

export const MenuControlIcon = () => (
  <div css={styles.wrapper}>
    <Icon icon="caret-down" size="10px" color="#27313B" />
  </div>
);
const styles = {
  wrapper: css({
    top: 0,
    right: 0,
    width: '24px',
    border: 'none',
    height: '100%',
    display: 'flex',
    cursor: 'pointer',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  })
};
