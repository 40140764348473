import { FormProvider } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { SignInFormSchema } from '../../SignIn/SignInForm/schema';
import { SignInFormProps } from '../../SignIn/SignInForm/types';
import { Template } from './Template';

export const TikTokEnterPasswordForm = ({ onSubmit, defaultValues = { password: '', email: '' } }: SignInFormProps) => (
  <div
    css={{
      width: '100%',
      backgroundColor: THEME.background.colors.white,
      boxShadow: THEME.shadows.boxShadowContainer,
      borderRadius: '3px'
    }}
  >
    <FormProvider onSubmit={onSubmit} defaultValues={defaultValues} zodSchema={SignInFormSchema}>
      <Template />
    </FormProvider>
  </div>
);
