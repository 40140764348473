import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Anchor, ButtonSubmit, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAllAvailableCountryOptions } from '@/shared/hooks';
import { CheckBoxField, SelectorField, TextField } from '@/shared/molecules';
import { TikTokSignupFormValues, TikTokSignupFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { isSubmitting }
  } = useFormContext<TikTokSignupFormValues>();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();

  const values = getValues();
  const hasAllFieldFilled = Object.values(values).every((field) => !!field);

  return (
    <FormStyle.Wrapper css={styles.wrapper} gapRow={16}>
      <FormStyle.FieldsGroup itemsPerRow={2}>
        <TextField<TikTokSignupFormKeys>
          name="companyName"
          title={t('TextForm.Company Name')}
          placeholder={t<string>('Company Name')}
          required
        />
        <TextField<TikTokSignupFormKeys>
          name="companyUrl"
          title={t('TextForm.Company URL')}
          placeholder="https://anytag.tech"
          required
        />
      </FormStyle.FieldsGroup>

      <FormStyle.FieldsGroup itemsPerRow={2}>
        <TextField<TikTokSignupFormKeys>
          name="phoneNumber"
          placeholder="066020485707"
          title={t('TextForm.Phone Number')}
          required
        />
        <TextField<TikTokSignupFormKeys>
          name="name"
          title={t('TextForm.Name')}
          placeholder={t<string>('Name')}
          required
        />
      </FormStyle.FieldsGroup>
      <FormStyle.FieldsGroup itemsPerRow={2}>
        <TextField<TikTokSignupFormKeys>
          name="email"
          title={t('TextForm.Email')}
          placeholder="your@email.com"
          required
        />
        <SelectorField<TikTokSignupFormKeys>
          name="countryId"
          title={t('Selector.Country')}
          required
          // https://adasiaholdings.atlassian.net/wiki/spaces/NC/pages/3567255574/Onboarding+process+for+TikTok+collab+service#%E2%91%A0Add-information
          options={allAvailableCountryOptions.filter((country) => country.value === 'TH')}
          disabled
        />
      </FormStyle.FieldsGroup>

      <CheckBoxField<TikTokSignupFormKeys>
        name="acceptTerms"
        label={
          <Trans
            i18nKey="I agree to the Privacy Policy and Terms of Service"
            components={{
              a: <Anchor variant="blue" target="_blank" css={styles.link} to="/privacy" />,
              a1: <Anchor variant="blue" target="_blank" css={styles.link} to="/terms" />
            }}
          />
        }
        required
        css={{
          margin: '12px 0 16px',
          justifyItems: 'center',
          fontSize: '12px',
          '.checkbox-label': { color: THEME.text.colors.gray.lv3 }
        }}
      />

      <ButtonSubmit
        variant="blue"
        loading={isSubmitting}
        disabled={!hasAllFieldFilled}
        title={t('Button.Create Account')}
        css={{ width: '348px', marginTop: '16px', margin: '0 auto' }}
      />
    </FormStyle.Wrapper>
  );
};

const styles = {
  link: css({ display: 'inline', fontSize: '12px', lineHeight: '1.4em' }),
  wrapper: css({
    width: '712px',
    margin: '0 auto',
    borderRadius: '3px',
    padding: '48px 40px',
    boxSizing: 'border-box'
  })
};
