import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TwitterAnalyticsPosts.graphql';
import { Query, QuerytwitterAnalyticsPostsArgs } from '../../__generated__/globalTypes';

export const useTwitterAnalyticsPostsQuery = (options?: QueryHookOptions<Query, QuerytwitterAnalyticsPostsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytwitterAnalyticsPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTwitterAnalyticsPostsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytwitterAnalyticsPostsArgs>
): QueryResult<Query, QuerytwitterAnalyticsPostsArgs> & {
  getTwitterAnalyticsPosts: LazyQueryExecFunction<Query, QuerytwitterAnalyticsPostsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTwitterAnalyticsPosts, result] = useLazyQuery<Query, QuerytwitterAnalyticsPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTwitterAnalyticsPosts, ...result };
};

export const useTwitterAnalyticsPostsPromiseQuery = () => {
  const client = useApolloClient();

  const getTwitterAnalyticsPosts = useCallback(
    (options?: Omit<QueryOptions<QuerytwitterAnalyticsPostsArgs, Query>, 'query'>) =>
      client.query<Query, QuerytwitterAnalyticsPostsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTwitterAnalyticsPosts };
};
