import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AIModelName,
  useCreateGeminiThreadMutation,
  useCreateOpenAiThreadMutation,
  useDeleteGeminiThreadMutation,
  useDeleteOpenAiThreadMutation
} from '@/graphql';

interface Props {
  isOpenAiModel?: boolean;
  onCreateSuccess: () => void;
  onCreateError: (message: any) => void;
}

export const useManageChatAiThread = ({ onCreateError, onCreateSuccess, isOpenAiModel = true }: Props) => {
  const { t } = useTranslation();
  const [threadId, setThreadId] = useState<number | null>(null);
  const { callDeleteOpenAiThread } = useDeleteOpenAiThreadMutation();
  const { callCreateOpenAiThread } = useCreateOpenAiThreadMutation();
  const { callDeleteGeminiThread } = useDeleteGeminiThreadMutation();
  const { callCreateGeminiThread } = useCreateGeminiThreadMutation();

  const createChatAiThread = async (aiModel?: AIModelName) => {
    try {
      let openAiThreadId;

      if (typeof aiModel !== 'undefined' ? aiModel === AIModelName.OPENAI : isOpenAiModel) {
        const { data } = await callCreateOpenAiThread();
        openAiThreadId = data?.createOpenAiThread?.threadId;
      } else {
        const { data } = await callCreateGeminiThread();
        openAiThreadId = data?.createGeminiThread?.threadId;
      }

      if (openAiThreadId) {
        setThreadId(openAiThreadId);
        onCreateSuccess();
      } else {
        onCreateError(t<string>('Can not get threadId after created'));
      }
    } catch (error) {
      onCreateError(error.message);
    }
  };

  const deleteChatAiThread = async () => {
    if (!threadId) return;

    try {
      if (isOpenAiModel) {
        await callDeleteOpenAiThread({ variables: { input: { id: threadId } } });
      } else {
        await callDeleteGeminiThread({ variables: { input: { id: threadId } } });
      }

      setThreadId(null);
    } catch (error) {
      console.error(error);
    }
  };

  return { threadId, createChatAiThread, deleteChatAiThread };
};
