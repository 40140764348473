import { useInfluencerYoutubeAnalyticsPostsQuery } from '@/graphql';
import { LIMIT_30 } from '@/shared/constants';
import { InfluencerYoutubeAnalyticsPostDetailsModal } from '@/shared/molecules';
import { AnalyticsYoutubeDashboardVideosTemplate } from '@/shared/organisms';
import { PostDetailsType } from '@/shared/types';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsYoutubeProfileVideosProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerAnalyticsYoutubeProfileVideos = ({
  socialAccount: { id: socialAccountId, name }
}: InfluencerAnalyticsYoutubeProfileVideosProps) => {
  const { data } = useInfluencerYoutubeAnalyticsPostsQuery({
    variables: {
      offset: 0,
      limit: LIMIT_30,
      socialAccountId,
      orderBy: { field: null, order: null }
    }
  });

  const posts = data?.influencerYoutubeAnalyticsPosts.posts || [];

  return (
    <>
      <AnalyticsYoutubeDashboardVideosTemplate
        data={posts}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/feed_videos',
          params: { socialMediaAccountId: socialAccountId.toString() },
          search: { filter: { name } }
        }}
      />

      <InfluencerYoutubeAnalyticsPostDetailsModal
        posts={posts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: socialAccountId }))}
      />
    </>
  );
};
