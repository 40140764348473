import { Outlet } from '@tanstack/react-router';
import { Header } from '@/shared/molecules';
import {
  InfluencerTabs,
  InfluencerProfileProvider,
  useInfluencerProfileSearch,
  RefreshInfluencerProfileButton
} from '@/shared/organisms';
import { InfluencerNewProfileHeaderV2, SocialAccountType } from '@/graphql';

interface TemplateProps {
  influencerProfile: InfluencerNewProfileHeaderV2;
}
export const Template = ({ influencerProfile }: TemplateProps) => {
  const { socialMedia, socialAccountId } = useInfluencerProfileSearch();

  const hasRefreshProfileButton =
    socialMedia && socialAccountId && Object.values(SocialAccountType).includes(socialMedia as SocialAccountType);

  return (
    <InfluencerProfileProvider value={{ influencerProfile }}>
      <Header>
        {hasRefreshProfileButton ? (
          <RefreshInfluencerProfileButton
            css={{ marginLeft: 'auto' }}
            status={influencerProfile.status}
            socialAccountId={+socialAccountId}
            socialMedia={socialMedia as SocialAccountType}
          />
        ) : null}
      </Header>

      <InfluencerTabs influencerProfile={influencerProfile} />

      <Outlet />
    </InfluencerProfileProvider>
  );
};
