import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';

interface YoutubeCMSDescriptionProps {
  className?: string;
}

export const YoutubeCMSDescription = (props: YoutubeCMSDescriptionProps) => {
  const { t } = useTranslation();

  return (
    <p
      css={{
        fontSize: '14px',
        lineHeight: '100%',
        marginTop: '-14px',
        marginBottom: '24px',
        color: THEME.text.colors.gray.lv3
      }}
      {...props}
    >
      {t('youtubeCMSDescription')}
    </p>
  );
};
