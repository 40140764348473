import { forwardRef } from 'react';
import { THEME } from '@/shared/constants';
import { TextCutter } from '../TextCutter';

export interface ErrorMessageProps {
  lines?: number;
  message?: string;
  className?: string;
}

export const ErrorMessage = forwardRef<HTMLParagraphElement, ErrorMessageProps>(
  ({ className, message, lines = 1 }, ref) =>
    message ? (
      <TextCutter
        ref={ref}
        lines={lines}
        className={className}
        text={`* ${message}`}
        css={{ position: 'absolute', color: THEME.input.border.colors.error, fontSize: '11px', padding: '2px 0 0 2px' }}
      />
    ) : null
);
