import { useTranslation } from 'react-i18next';
import {
  useGenerateMaterialSignedUrlPromiseQuery,
  useGeneratePostFileSignedUrlsPromiseQuery,
  useGenerateThumbnailSignedUrlPromiseQuery
} from '@/graphql';
import { DragAndDropProps, DragAndDrop } from '@/shared/atoms';
import { UPLOAD_IMAGE_MIME, UPLOAD_VIDEO_MIME, MIME_ACCEPT } from '@/shared/constants';

enum Target {
  post = 'post',
  material = 'material',
  thumbnail = 'thumbnail'
}

export interface FileFieldProps extends Omit<DragAndDropProps, 'generateSignedUrl'> {
  target: keyof typeof Target;
}

export const FileField = ({ target, notes, ...restProps }: FileFieldProps) => {
  const { t } = useTranslation();
  const { getGenerateMaterialSignedUrl } = useGenerateMaterialSignedUrlPromiseQuery();
  const { getGeneratePostFileSignedUrls } = useGeneratePostFileSignedUrlsPromiseQuery();
  const { getGenerateThumbnailSignedUrl } = useGenerateThumbnailSignedUrlPromiseQuery();

  const fetchMaterialUrl = async (fileNames: string[]) => {
    const { data } = await getGenerateMaterialSignedUrl({ variables: { fileNames } });

    return data?.generateMaterialSignedUrl.fileUploadUrls || null;
  };

  const fetchThumbnailUrl = async (fileNames: string[]) => {
    const { data } = await getGenerateThumbnailSignedUrl({ variables: { fileNames } });

    return data?.generateThumbnailSignedUrl.fileUploadUrls || null;
  };

  const fetchPostUrl = async (fileNames: string[]) => {
    const { data } = await getGeneratePostFileSignedUrls({ variables: { fileNames } });

    return data?.generatePostFileSignedUrls.fileUploadUrls || null;
  };

  switch (target) {
    case Target.post:
      return (
        <DragAndDrop
          generateSignedUrl={fetchPostUrl}
          accept={{ ...MIME_ACCEPT.IMAGE, ...MIME_ACCEPT.GIF, ...MIME_ACCEPT.VIDEO }}
          notes={
            notes || [
              t('General.DragAndDropInfo'),
              t('General.DragAndDropMaxSize', { maxSize: '1GB' }),
              t('General.DragAndDropMIME', { MIME: `${UPLOAD_IMAGE_MIME}, ${UPLOAD_VIDEO_MIME}` })
            ]
          }
          {...restProps}
        />
      );

    case Target.thumbnail:
      return (
        <DragAndDrop
          generateSignedUrl={fetchThumbnailUrl}
          accept={{ ...MIME_ACCEPT.IMAGE, ...MIME_ACCEPT.GIF }}
          notes={
            notes || [
              t('General.DragAndDropMaxSize', { maxSize: '10MB' }),
              t('General.DragAndDropMIME', { MIME: 'PNG, GIF, JPEG' }),
              t('General.DragAndDropImageRatio', { ratio: '16:9' })
            ]
          }
          {...restProps}
        />
      );

    case Target.material:
      return (
        <DragAndDrop
          generateSignedUrl={fetchMaterialUrl}
          accept={{ ...MIME_ACCEPT.IMAGE, ...MIME_ACCEPT.GIF, ...MIME_ACCEPT.VIDEO }}
          notes={
            notes || [
              t('General.DragAndDropInfo'),
              t('General.DragAndDropMaxSize', { maxSize: '1GB' }),
              t('General.DragAndDropMIME', { MIME: `${UPLOAD_IMAGE_MIME}, ${UPLOAD_VIDEO_MIME}` })
            ]
          }
          {...restProps}
        />
      );

    default:
      return null;
  }
};

FileField.displayName = 'FileField';
