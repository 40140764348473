import { useTranslation } from 'react-i18next';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { useYoutubeAnalyticsPostsQuery } from '@/graphql';
import { getOffset, getValuableVariables } from '@/shared/utils';
import {
  ProfilePostsFilter,
  ProfilePostsCount,
  AnalyticsYoutubeCompareVideosList,
  AnalyticsYoutubeCompareVideosType
} from '@/shared/organisms';
import { AnalyticsYoutubeMainSearchSchemaType } from './schemaTypes';

interface MainAccountTemplateProps {
  mainSocialAccountId: number;
}

export const MainAccountTemplate = ({ mainSocialAccountId }: MainAccountTemplateProps) => {
  const { t } = useTranslation();
  const {
    sort,
    page,
    limit,
    setFilter,
    filter: { endDate, startDate, keyword, accountId }
  } = useParamsSearch<AnalyticsYoutubeMainSearchSchemaType>();

  const { data, previousData, loading } = useYoutubeAnalyticsPostsQuery({
    skip: !accountId,
    variables: getValuableVariables({
      limit,
      keyword,
      endDate,
      startDate,
      orderBy: sort,
      offset: getOffset(page, limit),
      youtubeAnalyticsAccountId: Number(accountId)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.youtubeAnalyticsPostsCount.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter
        css={{ borderRadius: 0 }}
        filter={{ keyword, endDate, startDate }}
        setFilter={(values) => setFilter({ ...values, accountId })}
        hasPeriod
      />

      <ProfilePostsCount count={totalRecords} countLabel={t('Videos found')} />

      <AnalyticsYoutubeCompareVideosList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={
          result?.youtubeAnalyticsPosts.posts.map<AnalyticsYoutubeCompareVideosType>((post) => ({
            ...post,
            title: post.videoTitle,
            duration: post.videoDuration,
            thumbnail: post.videoThumbnail
          })) || []
        }
      />
    </ListWithPagination>
  );
};
