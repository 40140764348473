import React, { createContext, useContext } from 'react';
import { useMatchRoute } from '@tanstack/react-router';
import { AutoManagedCampaignPayload } from '@/graphql';
import { useGetDocumentParentHref } from '@/shared/hooks';

interface ContextType {
  autoManagedCampaignId: number;
  autoManagedCampaign: AutoManagedCampaignPayload;
}

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  children: React.ReactElement | Array<React.ReactElement>;
  value: Pick<ContextType, 'autoManagedCampaign'>;
}

export const AutoManagedCampaignDetailsProvider = ({ children, value: { autoManagedCampaign } }: Props) => {
  const matchByPath = useMatchRoute();
  const isReportPage = !!matchByPath({ to: '/auto-managed/$id/report' });

  useGetDocumentParentHref(
    isReportPage
      ? {
          href: '/auto-managed',
          alwaysBackToParent: true,
          interpolation: { title: autoManagedCampaign.title },
          title: autoManagedCampaign.title ? 'documentTitle.PostReportWithTitle' : 'documentTitle.PostReportDefault',
          appHeader: autoManagedCampaign.title ? 'appHeader.PostReportWithTitle' : 'appHeader.PostReportDefault'
        }
      : {
          href: '/auto-managed',
          alwaysBackToParent: true,
          title: 'appHeader.Edit Auto Managed Campaign',
          interpolation: { campaignTitle: autoManagedCampaign.title },
          appHeader: autoManagedCampaign.title ? 'appHeader.Campaign Title' : 'appHeader.Edit Auto Managed Campaign'
        }
  );

  return (
    <Context.Provider
      value={{
        autoManagedCampaign,
        autoManagedCampaignId: autoManagedCampaign.id
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAutoManagedCampaignDetailsContext = () => useContext<ContextType>(Context);
