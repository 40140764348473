import { useEffect } from 'react';
import { useParams } from '@tanstack/react-router';
import {
  AnalyticsRedirectType,
  useSocialAuthReconnectInstagramAccountAnalyticsMutation,
  useSocialAuthReconnectTwitterAccountAnalyticsV2Mutation,
  useSocialAuthReconnectYouTubeAccountAnalyticsV2Mutation,
  useSocialAuthReconnectFacebookPageAccountAnalyticsMutation
} from '@/graphql';
import { Loading } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { getAnalyticsAccountFormState } from '@/shared/organisms';
import { getCallbackUrl, manageLocalStorage } from '@/shared/utils';

export const RedirectAnalyticsConnect = () => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { provider } = useParams({ from: '/_private-routes/redirect/analytics-connect/$provider' });
  const response = window.location.search.slice(1);
  const { callSocialAuthReconnectInstagramAccountAnalytics } =
    useSocialAuthReconnectInstagramAccountAnalyticsMutation();
  const { callSocialAuthReconnectYouTubeAccountAnalyticsV2 } =
    useSocialAuthReconnectYouTubeAccountAnalyticsV2Mutation();
  const { callSocialAuthReconnectFacebookPageAccountAnalytics } =
    useSocialAuthReconnectFacebookPageAccountAnalyticsMutation();
  const { callSocialAuthReconnectTwitterAccountAnalyticsV2 } =
    useSocialAuthReconnectTwitterAccountAnalyticsV2Mutation();

  useEffect(() => {
    reconnectAnalyticsAccount();
  }, []);

  const stringifiedData = manageLocalStorage('get', 'analyticsAccountFormState_json');
  const { to, params, formState } = getAnalyticsAccountFormState('parse', { stringifiedData });

  const goToAnalyticsPage = () => {
    if (to) {
      navigate({ to, params });
    } else {
      enqueueSnackbar(t('Missing redirect back route'), { variant: 'error' });
    }
  };

  const reconnectAnalyticsAccount = async () => {
    if (!formState) {
      enqueueSnackbar(t('Stored analytics account was not found'), { variant: 'error' });

      return;
    }

    try {
      switch (provider) {
        case 'instagram':
          await callSocialAuthReconnectInstagramAccountAnalytics({
            variables: {
              input: {
                response,
                redirectType: AnalyticsRedirectType.ANALYTICS_CONNECT,
                fbPageId: formState?.instagram?.fbPageId || null,
                accountId: Number(formState?.instagram?.id || ''),
                username: String(formState?.instagram?.instagramAccountName),
                businessAccountId: String(formState?.instagram?.businessAccountId),
                callbackUrl: getCallbackUrl('instagram', AnalyticsRedirectType.ANALYTICS_CONNECT)
              }
            }
          });
          break;
        case 'youtube':
          await callSocialAuthReconnectYouTubeAccountAnalyticsV2({
            variables: {
              input: {
                response,
                channelId: formState?.youtube?.channelId || '',
                callbackUrl: getCallbackUrl('youtube', AnalyticsRedirectType.ANALYTICS_CONNECT)
              }
            }
          });
          break;
        case 'facebook':
          await callSocialAuthReconnectFacebookPageAccountAnalytics({
            variables: {
              input: {
                response,
                fbPageId: formState?.facebookPage?.fbPageId || '',
                accountId: Number(formState?.facebookPage?.id || ''),
                callbackUrl: getCallbackUrl('facebook', AnalyticsRedirectType.ANALYTICS_CONNECT)
              }
            }
          });
          break;
        case 'twitter':
          await callSocialAuthReconnectTwitterAccountAnalyticsV2({
            variables: {
              input: {
                response,
                callbackUrl: getCallbackUrl('twitter', AnalyticsRedirectType.ANALYTICS_CONNECT)
              }
            }
          });
          break;
        default:
          break;
      }

      enqueueSnackbar(t('Your account has been reconnected successfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message || 'Fail to reconnect account, please try again later'), { variant: 'error' });
    } finally {
      goToAnalyticsPage();
    }
  };

  return <Loading />;
};
