import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles, useInfluencerPermissions } from '@/auth';
import { MarketplaceAffiliateJoinedInfluencer, MarketplaceDetail } from '@/graphql';
import { Anchor, RowProps, TableCell, ColumnProps } from '@/shared/atoms';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { InfluencerAvatar } from '@/shared/molecules';
import { formatPercent } from '@/shared/utils';
import { UpdateCostsFormValues } from '../../../hooks';
import { getRevenueItems } from '../../utils';

interface Props {
  marketplace: MarketplaceDetail;
  data: readonly MarketplaceAffiliateJoinedInfluencer[];
}

export const useMarketplaceJoinedInfluencersTable = ({ data, marketplace: { id, currency } }: Props) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { hideSelectInfluencers } = useInfluencerPermissions();
  const { generateProfileLink } = useGenerateInfluencerProfileLink({ disabled: hideSelectInfluencers });

  const { control, handleSubmit, formState, reset } = useForm<UpdateCostsFormValues>({
    reValidateMode: 'onChange',
    values: data?.reduce<UpdateCostsFormValues>((rs, ji) => {
      const { influencerCommissionRate, revenuePerAction, revenuePerClick } = getRevenueItems(ji.revenueItems);

      rs[`${ji.influencer.id}_influencerCommissionRate`] = formatPercent(influencerCommissionRate?.value, false) || '';
      rs[`${ji.influencer.id}_revenuePerClick`] = revenuePerClick?.value?.toString() || '';
      rs[`${ji.influencer.id}_revenuePerAction`] = revenuePerAction?.value?.toString() || '';

      return rs;
    }, {})
  });

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Influencer'), sticky: { active: true, hasBorderRight: false } },
    { title: t('HeaderColumn.Social Media / Promotion method'), styles: { width: '20%' } },
    {
      title: t('HeaderColumn.Influencer Commission Rate'),
      styles: { textAlign: 'left', width: '20%' },
      hidden: !isAdminStaff
    },
    { title: t('HeaderColumn.Revenue per Click'), hidden: !isAdminStaff },
    { title: t('HeaderColumn.Revenue per Conversion'), hidden: !isAdminStaff },
    { title: t('HeaderColumn.Joined Date') }
  ];

  const rows: RowProps[] = data.map(
    ({ joinedDate, revenueItems, costEditable, promotionMethods, influencer: { id: influencerId, name, avatar } }) => {
      const profileLink = generateProfileLink(influencerId);
      const { influencerCommissionRate, revenuePerAction, revenuePerClick } = getRevenueItems(revenueItems);

      return [
        <div css={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
          <InfluencerAvatar css={{ borderRadius: 0 }} size="48px" src={avatar} id={influencerId} />
          <Anchor css={{ marginLeft: '8px' }} label={name} {...profileLink} />
        </div>,
        <TableCell.Text value={promotionMethods.join(', ') || '-'} />,
        influencerCommissionRate !== null && isAdminStaff ? (
          <TableCell.ControlledRevenueInput
            name={`${influencerId}_influencerCommissionRate`}
            control={control}
            revenueItem={influencerCommissionRate}
            input={{
              unit: '%',
              type: 'number',
              disabled: !costEditable
            }}
          />
        ) : (
          <TableCell.Text value={formatPercent(influencerCommissionRate?.value, false)} />
        ),
        <TableCell.ControlledRevenueInput
          name={`${influencerId}_revenuePerClick`}
          control={control}
          revenueItem={revenuePerClick}
          input={{
            type: 'number',
            disabled: !costEditable,
            unit: currency
          }}
        />,
        <TableCell.ControlledRevenueInput
          name={`${influencerId}_revenuePerAction`}
          control={control}
          revenueItem={revenuePerAction}
          input={{
            type: 'number',
            disabled: !costEditable,
            unit: currency
          }}
        />,
        <TableCell.Date value={joinedDate} />
      ];
    }
  );

  return {
    rows,
    reset,
    columns,
    formState,
    handleSubmit
  };
};
