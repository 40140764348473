import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations } from '@/shared/validations';
import { EngagementCampaignPostStatusForInsightCheck } from '@/graphql';
import { LIMIT } from '@/shared/constants';

export const PostsInsightFilterSchema = z.object({
  keyword: FilterValidations.string(),
  status: FilterValidations.enum(EngagementCampaignPostStatusForInsightCheck)
});
export const PostsInsightSearchFilterSchema = z.object({
  filter: PostsInsightFilterSchema.default({}),
  limit: z.number().default(LIMIT),
  page: z.number().default(1)
});

export type PostsInsightSearchFilterSchemaType = z.infer<typeof PostsInsightSearchFilterSchema>;
export type PostsInsightFilterFormValues = z.infer<typeof PostsInsightFilterSchema>;
export type PostsInsightFilterFormKeys = UnionOfKeys<PostsInsightFilterFormValues>;
