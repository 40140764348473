import { useTranslation } from 'react-i18next';
import { Prompt } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useSelectedInfluencersContext } from '../SelectedInfluencersContext';

export const LeaveConfirmModal = () => {
  const { t } = useTranslation();
  const { hasRowChange, confirmModal, resetViewMode } = useSelectedInfluencersContext();

  return (
    <Prompt
      when={!!hasRowChange}
      open={confirmModal.status}
      title={t('Dialog.Save your changes')}
      css={{ maxWidth: THEME.modal.size.lv2 }}
      submitButtonProps={({ reset }) => ({
        title: t('Button.Keep editing'),
        onClick: () => {
          reset?.();
          confirmModal.close();
        }
      })}
      cancelButtonProps={({ proceed }) => ({
        title: t('Button.Leave without saving'),
        onClick: () => {
          proceed?.();
          resetViewMode();
          confirmModal.close();
        }
      })}
    >
      <p css={{ fontSize: '14px', lineHeight: '18px', marginTop: '16px' }}>{t('leaveWithoutSaveWarningText')}</p>
    </Prompt>
  );
};
