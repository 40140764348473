import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Anchor } from '@/shared/atoms';

export const TermsSelfServiceEn = ({ className }: { className?: string }) => (
  <div css={styles.container} className={className}>
    <h2>Terms and Conditions of AnyTag Self-Serve Service</h2>
    <p>
      AnyTag Self-Serve Service is one of the services offered on AnyTag Platform, provided by AnyMind Group Pte. Ltd.
      and its subsidiaries (hereinafter referred to as "AnyMind"). AnyMind has partnered with the third party applicable
      ad platforms to create a one-stop-shop for advertising campaigns for client(s) (hereinafter referred to as
      "Client") to manage ad campaigns, select influencers based on the categorize globally, optimize advertisement,
      analyze the performance of the influencers and monitor the performance campaign 24/7 (hereinafter referred to as
      "AnyTag Self-Serve Service").
    </p>
    <p>
      AnyMind has made every attempt to ensure the reliability and accuracy of information provided on Platform, but as
      such, the information available on Platform is provided without warranty of any kind. AnyMind does not and will
      not accept liability or responsibility of any manner for the completeness, accuracy, content, legality, or
      reliability of the information on this. AnyTag Self-Serve Service, as well as all information, products, contents,
      and other services included, are made available to Client on an "as is" basis unless specified in writing. Client
      agrees that Client uses the AnyTag Self-Serve Service and Platform at its Client's sole risk.
    </p>
    <p>
      By accessing, using, and browsing this, Client acknowledges that Client has read, understood, and agreed to be
      bound by these terms and conditions below, and to comply with all applicable laws, rules and regulations.
    </p>

    <h3>1. AGREEMENT</h3>
    <p>
      In consideration of the terms outlined in this Terms and Conditions of AnyTag Self-Serve Service, Client and
      AnyMind agree to enter into an agreement whereby AnyMind shall provide access to Platform and AnyTag Self-Serve
      Service to Client with Subscription Plans (as defined here below) and Influencer Marketing Packages (as defined
      here below).
    </p>

    <h3>2. DEFINITIONS</h3>
    <p>
      <strong>Ad Management Dashboard:</strong> A tool provided by the third party applicable ad platforms (e.g.,
      TikTok) that allows Client to manage, monitor, and optimize their advertising campaigns.
      <br />
      <strong>Content:</strong> Any materials, information, or media provided by Client and uploaded via third party
      applicable ads platforms.
      <br />
      <strong>AnyTag Dashboard:</strong> A tool provided by AnyMind on the Platform that enables Client to monitor,
      track and optimize their advertising campaigns in real time.
      <br />
      <strong>Client:</strong> The legal or natural person(s), or entity that is using AnyMind's services covered in
      AnyTag Self-Serve Service.
      <br />
      <strong>Influencer(s):</strong> The legal or natural person(s), or entity providing services to AnyMind and its
      Clients through Platform.
      <br />
      <strong>Platform:</strong> An online platform under the ownership of AnyMind which can be utilized by Client to
      connect with Influencers to promote Client's product and analyze the performance in real-time.
      <br />
      <strong>Business Account:</strong> Client's business account or business center registered with third party
      applicable ads platforms (i.e. TikTok Platform) which shall be used to connect with the Platforms to use AnyTag
      Self-Serve Service.
      <br />
      <strong>Ad Account:</strong> New Ads Account of third party applicable ads platforms (i.e. TikTok Platform)
      registered via AnyTag Self-Serve on Platform and connect with the Platforms to use AnyTag Self-Serve Service.
      <br />
      <strong>Live Marketplace Campaign:</strong> A project uploaded by Client which requires bidding by different
      Influencers, who shall then propose a method of rolling out the Campaign to be agreed upon by Client.
      <br />
      <strong>Subscription Plans:</strong> The Fees payable by Client to AnyMind for using the AnyTag Platform on a
      monthly basis.
      <br />
      <strong>Influencer Marketing Plans:</strong> The service plans that Client may purchase from time to time,
      allowing them to create Live Marketplace Campaigns by connecting with influencers to promote products or services,
      all for a one-time payment.
      <br />
      <strong>Third-Party Payment Processor:</strong> An entity that enables AnyMind to accept credit card payments,
      online payments, and other cashless payment methods.
      <br />
      <strong>Premium Support Service:</strong> A specialized offering for Enterprise plan users, providing one-on-one
      consultation and tailored support to address specific needs.
    </p>

    <h3>3. ACCEPTANCE</h3>
    <ol css={styles.numberedList}>
      <li>
        AnyMind provides access to Platform and Anytag Self-Serve Service subject to the general terms and conditions
        that are given below. Client completely and unequivocally agrees to be bound by{' '}
        <Anchor
          to="/terms"
          target="_blank"
          variant="blue"
          css={{ textDecoration: 'underline', display: 'inline-block' }}
        >
          Terms and Conditions of Service on AnyTag Platform
        </Anchor>{' '}
        and this Terms and Conditions of AnyTag Self-Serve Service (hereinafter referred to as "Terms and Conditions, or
        Terms") without modification. If Client does not intend to be legally bound to the Terms and Conditions, Client
        may not access or otherwise use the Platform or AnyTag Self-Serve Service. AnyMind reserves the right, at any
        time, to update and change any or all of these Terms and Conditions, at its sole discretion.
      </li>

      <li>
        Client accepts Terms and Conditions of AnyTag Self-Serve Service on AnyTag Platform (hereinafter referred to as
        "Terms and Conditions, Terms") in the following events by: (1) executing these Terms and Conditions by
        electronically accepting these Terms and Conditions, and by accessing Platform thereafter; or (2) subsequently
        executing Subscription Plans that will further detail specifics of this agreement on a case by case basis.
      </li>

      <li>
        By using Platform, Client represents and warrants that Client has the right, authority and capacity to enter
        into the Agreement. If Client does not meet all of these requirements, Client must not access or use Platform or
        AnyTag Self-Serve Service.
      </li>

      <li>
        AnyMind makes no claims that AnyTag Self-Serve Service/Platform or any of its contents is accessible or
        appropriate based on the domicile of Client. Access to AnyTag Self-Serve Service/Platform may not be legal for
        certain persons or in certain countries. If Client accesses Platform, Client does so on its own initiative and
        as such, shall be responsible for compliance with local laws.
      </li>

      <li>
        By using AnyTag Self-Serve Service, Client agrees to accept all the terms and conditions of Privacy Policy,
        Terms of Services, Return and Refund policy on third party applicable ads platforms including TikTok platform,
        and other terms and conditions as set forth by such platform or AnyMind which may be updated from time to time.
      </li>
    </ol>

    <h3>4. TRADEMARKS</h3>
    <p>
      AnyMind's name, logo and all related names, logos, product and service names, designs and slogans including but
      not limited to AnyTag, AnyTag Self-Serve Service are trademarks of AnyMind or its Affiliates. Client must not use
      such marks without prior written permission from AnyMind. Client's names, logos, product and service names,
      designs and slogans are the trademarks of Client. All other names, logos, product and service names, designs and
      slogans on Platform are the trademarks of their respective owners. Any trademarks, name, logo and all related
      names, logos, product and service names, designs and slogans provided by Client (collectively, "Client Marks")
      remain the property of Client. By using the Platform, Client grants AnyMind a non-exclusive, royalty-free,
      worldwide license to use, reproduce, and display the Client Marks solely in connection with providing the
      Platform's services, including marketing, campaign creation, and other related activities.
    </p>

    <h3>5. REGISTRATION AND SUBSCRIPTION PLAN</h3>
    <ol css={[styles.numberedList, styles.sectionN(5)]}>
      <li>
        By signing up for an account to use AnyTag Self-Serve Service, Client shall agree to these Terms and submit an
        application to register with AnyMind and provide certain information as prescribed by AnyMind according to the
        method as prescribed by AnyMind.
      </li>

      <li>
        Client agrees to provide accurate and complete information, including full name, email address, and any other
        information required during the registration process.
      </li>

      <li>
        After submitting the required information, Client shall receive a verification link to the Client's email. Once
        Client verifies their email, Client will be able to log in to the AnyTag platform.
      </li>

      <li>
        When registration of Client as set forth in the preceding section is completed and approved, Client will be
        entitled to use AnyTag Self-Serve Service according to these Terms.
      </li>

      <li>
        After the registration successfully, AnyMind offers various Subscription Plans and Influencer Marketing Plans
        that give Client access to premium features and content. By subscribing, Client agrees to the following terms:
      </li>
    </ol>

    <div css={styles.subsection}>
      <h4>a) Subscription Plans</h4>
      <div css={styles.subsectionContent}>
        <ol css={styles.romanList}>
          <li>
            AnyMind shall offer different Subscription Plans (e.g., Beginner, Standard or Enterprise Plans). Details of
            the packages, including pricing, features, and billing cycles, are available on the Subscription Plan Page.
          </li>
          <li>
            Subscription Plans may include free trials, discounts, or promotional offers, which are subject to change or
            termination at any time, at AnyMind's sole discretion.
          </li>
        </ol>
      </div>

      <h4>b) Influencer Marketing Plans</h4>
      <div css={styles.subsectionContent}>
        <ol css={styles.romanList}>
          <li>
            For the use of the Marketplace Campaign, AnyMind shall offer different Influencer Marketing Plans (e.g.,
            Start, AnyTag Plan or AnyTag Pro Plans). Details of the packages, including pricing, and features (i.e. ads
            boosting budget, number of joining influencers, estimated total number of followers), are available on the
            Influencer Marketing Plans Page.
          </li>
          <li>
            Influencer Marketing Plans may include free trials, discounts, or promotional offers, which are subject to
            change or termination at any time, at AnyMind's sole discretion.
          </li>
        </ol>
      </div>

      <h4>c) Billing and Payment</h4>
      <div css={styles.subsectionContent}>
        <ol css={styles.romanList}>
          <li>
            All payments for Subscription Plans, Influencer Marketing Plans and other services that may be offered on
            AnyTag Self-Serve Service must be made through the Third-Party Payment Processor. By purchasing any service,
            package or subscription, Client agrees to provide accurate and complete payment information and to pay the
            applicable fees via the Third-Party Payment Processor.
          </li>
          <li>
            The prices for Subscription Plans, Influencer Marketing Plans or other payments are available on the
            Platform and may be amended with notices on the Platform. The same applies to changes to the composition and
            the contents of subscription types and add-on modules.
          </li>
          <li>
            The Subscription Fees will be charged at 00:00 SGT on the 1st day of each month. For example, the fee for
            August will be charged at 00:00 SGT on September 1st. The latest selected plan will be applied as the fee
            for the month, and it will be charged at 00:00 SGT on the 1st day of the following month. The fees are not
            calculated on a pro-rata basis. Plan Upgrades: When a user upgrades their plan during the month, the new
            plan is applied immediately, and the Dashboard and feature are updated to reflect the new plan. The fee for
            the new plan will be charged on the 1st day of the following month. Plan Downgrades: When a user downgrades
            their plan during the month, the new plan will only take effect from the 1st day of the following month. The
            Dashboard and feature will not be updated immediately. The fee for the previous plan will be charged on the
            1st day of the next month, and the new plan's fee will be applied from the 1st day of the month after that.
          </li>
          <li>
            Client acknowledges that payments processed by the Third-Party Payment Processor are subject to their Terms
            of Service and Privacy Policy. AnyMind shall not store or have access to Client payment information, as all
            transactions are securely handled by the Third-Party Payment Processor.
          </li>
          <li>
            Any issues related to payment disputes, refunds, or chargebacks must be addressed directly with the
            Third-Party Payment Processor. AnyMind shall not be responsible for any financial losses, damages, or
            inconveniences caused by payment processing failures or errors. Recurring payments for Subscription Plans
            will be charged automatically at the beginning of each billing cycle through the Third-Party Payment
            Processor. Client agrees to ensure that the payment details are up-to-date to avoid any interruptions to the
            subscription service.
          </li>
          <li>
            All payments made by the Client under this agreement are exclusive of any taxes, duties, levies, or similar
            governmental charges, including but not limited to sales tax, value-added tax (VAT), goods and services tax
            (GST), or withholding tax (collectively, "Taxes"). Client is responsible for the payment of any applicable
            Taxes imposed in connection with the services provided by AnyMind, unless otherwise specified by applicable
            laws and regulations.
          </li>
          <li>
            Client agrees to provide AnyMind with all necessary documentation, including but not limited to
            certificates, tax exemption certificates, or any other relevant documents required under applicable law, in
            a timely manner. If Client fails to provide the required documentation, AnyMind reserves the right to
            terminate or suspend the services on Platform until the necessary documentation is provided. Client will
            bear responsibility for any additional charges, penalties, or taxes that may arise from the failure to
            provide such documentation.
          </li>
          <li>
            AnyMind reserves the right to update or change the Third-Party Payment Processor or payment processing
            methods and terms at any time. Any such changes will be communicated through the platform or via email.
          </li>
          <li>
            For the avoidance of doubt, ads boosting budget included in the Influencer Marketing Plans will be
            transferred to the third party applicable ads platforms i.e. TikTok platform at the time of purchase any
            issues related to payment disputes, refunds, or chargebacks must be addressed directly with the third party
            applicable ads platforms.
          </li>
          <li>
            AnyMind shall not be liable for any issues arising from the payment process, including but not limited to
            transaction failures, unauthorized transactions, payment delays, or any disputes between Client and the
            Third-Party Payment Processor or the third party applicable ads platforms.
          </li>
        </ol>
      </div>
    </div>

    <h3>6. ANYTAG SELF-SERVE SERVICE</h3>
    <ol css={[styles.numberedList, styles.sectionN(6)]}>
      <li>
        Upon completion of the Registration, as described in this Terms, Client shall access the AnyTag Dashboard for
        use in the AnyTag Self-Serve Service.
      </li>

      <li>
        Ad Self-Serve Service
        <ol css={styles.letterList}>
          <li>Client shall connect the Client's Ad Account with the Platform.</li>
          <li>
            Client shall use the AnyTag Self-Serve Service by selecting the Budget Allocation section and allocating the
            available ads boosting budget to each Ad Account connected through Platform. Afterwards, Client shall manage
            and operate the Ads via the Ad Management Dashboard of the third party applicable ads platforms i.e. TikTok
            platform.
          </li>
          <li>Campaign Report will be displayed on the Ad Report section of the AnyTag Dashboard.</li>
          <li>
            Client acknowledges and agrees that the Budget Allocation will only be used for the boosting of Content
            created by Influencer(s) that join the Live Marketplace Campaign and will only reflect on the Platform not
            on the third party applicable ads platform.
          </li>
        </ol>
      </li>

      <li>
        Marketplace Campaign
        <ol css={styles.letterList}>
          <li>
            Upon purchasing the Influencer Marketing Plans, Client shall create a Live Marketplace Campaign on the
            Platform to engage Influencers. The engagement of Influencers, including the number of influencers, their
            followers, and the reach or views, shall be subject to the Package selected and paid for by Client.
          </li>
          <li>
            Before releasing a Live Marketplace Campaign, AnyMind may review and may approve, reject, or request
            revisions, based on its criteria and at its sole discretion. Client acknowledges that AnyMind is not
            obligated to perform such reviews, nor does AnyMind guarantee or warrant the accuracy or legal compliance of
            Client's Live Marketplace Campaign.
          </li>
          <li>
            Once a Live Marketplace Campaign is approved, Influencers may view and request to join the campaign. If
            Client accepts an Influencer's request, Client agrees to be contractually obligated to pay the service fee
            in accordance with the terms and conditions outlined herein, as well as any subsequent parameters specific
            to the particular Live Marketplace Campaign.
          </li>
          <li>
            Any such Live Marketplace Campaign remains valid for the terms and conditions of that Live Marketplace
            Campaign unless superseded, extended, or altered by mutual terms and conditions of Client and AnyMind as a
            case may be.
          </li>
          <li>Any such extension or alteration must be agreed in writing through email exchange for consent.</li>
          <li>
            In the event that a Marketplace Campaign requires an influencer to purchase products or services to create
            content, and the cost of such products or services exceeds THB 100 (which is included in the Influencer
            Marketing Plans), Client agrees to deliver the products or services directly to the influencers at its own
            cost and expense. The Client further agrees and acknowledges that AnyMind shall not be liable for the
            delivery of products or services managed by the Client.
          </li>
        </ol>
      </li>

      <li>
        Premium Support Service
        <p>
          Client agrees and acknowledges that only those who purchase the Enterprise.
          <br />
          Plan are eligible to use the Premium Support Service.
        </p>
        <ol css={[styles.letterList, { paddingLeft: 0 }]}>
          <li>
            Premium Support Service Allocation
            <div css={styles.subsectionContent}>
              <ol css={styles.romanList}>
                <li>
                  Client is entitled to 4 hours of Premium Support Service per billing cycle, with each session having a
                  minimum duration of 1 hour.
                </li>
                <li>
                  To utilize the Premium Support Service, Client is required to book an advanced book up to maximum of 4
                  one-hour sessions per billing cycle.
                </li>
              </ol>
            </div>
          </li>
          <li>
            Reservation Rules
            <ol css={styles.romanList}>
              <li>Premium Support slots must be reserved at least 1 working day in advance.</li>
              <li>Slots are limited and are available on a first-come, first-served basis.</li>
              <li>
                When reserving the Premium Support Service, Client must provide the clear topics of which will be
                concluded in the session and must fully comply with the Premium Support Service Guideline provided by
                AnyMind.
              </li>
            </ol>
          </li>
          <li>
            Unused Premium Support Service Forfeiture
            <ol css={styles.romanList}>
              <li>
                Client agrees and acknowledges that any unused Premium Support Service will not carry over to the next
                billing cycle and is forfeited at the end of each cycle. Furthermore, Client understands that quotas
                cannot be redeemed for other forms of compensation or service credit.
              </li>
            </ol>
          </li>
          <li>
            Session Expectations
            <ol css={styles.romanList}>
              <li>
                While AnyMind provides expert advice and recommendations, AnyMind cannot guarantee specific outcomes,
                including but not limited to achieving viral success, reaching particular engagement metrics, or any
                other desired results. Client acknowledges that the results of any campaign or service are dependent on
                various external factors, including but not limited to market conditions, audience behaviour, and
                platform algorithms.
              </li>
              <li>
                Client is required to ensure full preparation for their session in accordance with the Premium Support
                Service Guideline. Failure to provide all necessary resources, such as artwork, documentation, or any
                other required materials, at least 24 hours prior to the session will result in the cancellation of the
                session without compensation. Additionally, no follow-up support will be provided for missed sessions
                due to insufficient preparation. Client acknowledges that the session cannot proceed without the
                necessary materials.
              </li>
              <li>
                Sessions will typically be conducted via Google Meet or another agreed-upon communication platform. Any
                changes to the communication channel must be mutually agreed upon and finalized at least 24 hours prior
                to the session date to ensure smooth execution. Client acknowledges that failure to finalize the channel
                in advance may result in session delays or cancellations.
              </li>
            </ol>
          </li>
          <li>
            Attendance and Cancellations
            <ol css={styles.romanList}>
              <li>Client must be ready at the scheduled time for the session.</li>
              <li>
                No-shows within 10 minutes of the scheduled start time or cancellations without prior rescheduling will
                result in the forfeiture of the allocated quota for that session.
              </li>
              <li>
                In the case of late attendance, the session duration will not be extended, and the session will conclude
                at the scheduled time.
              </li>
              <li>
                Rescheduling is permitted with a minimum notice of 1 working day. Any rescheduled session must be
                completed within the same billing cycle.
              </li>
              <li>
                Reservations for Premium Support Services may only be made during working hours, which are from 10:00 AM
                to 5:00 PM, Monday to Friday, excluding public holidays. Any requests made outside of these hours will
                be processed on the next working day.
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>

    <h3>7. ANYMIND RIGHTS AND RESPONSIBILITIES</h3>
    <ol css={[styles.numberedList, styles.sectionN(7)]}>
      <li>
        AnyMind reserves the right to provide access, withdraw or amend the access to AnyTag Self-Serve Service or
        Platform, and any service or material that AnyMind provides for AnyTag Self-Serve Service or on Platform, at its
        sole discretion and without notice. AnyMind will not be liable if for any reason all or any part of AnyTag
        Self-Serve Service is unavailable at any time or for any period. From time to time, AnyMind may restrict access
        to some parts of AnyTag Self-Serve Service or Platform, or the entire AnyTag Self-Serve Service or Platform.
      </li>

      <li>AnyMind is solely responsible for the remuneration of Influencers on the Platform.</li>

      <li>AnyMind will provide the report of the campaign in AnyMind's reporting format.</li>

      <li>
        At its sole discretion, AnyMind has the right to:
        <ol css={styles.letterList}>
          <li>
            Remove or refuse to post any Influencer content or Client's Live Marketplace Campaign for any or no reason;
          </li>
          <li>
            AnyMind has the right to disable any user name, password or other identifier, whether chosen by Influencer,
            Client or provided by AnyMind, at any time at its sole discretion for any or no reason, including if, in
            AnyMind's opinion, Influencer or Client has violated any clauses of the Terms and Conditions;
          </li>
          <li>
            Block, ban, deactivate, refrain or take any action to temporarily/permanently suspend Client's account on
            Platform without further notice if AnyMind believes that Influencer and Client are directly engaging and/or
            are in contact in connection with the AnyTag Self-Serve Service and purpose hereof without using Platform,
            or whereby Influencer's content, Client's Live Marketplace Campaign or the use of Platform violates all or
            part thereof of the Terms and Conditions. AnyMind reserves the right to claim for compensation from
            Influencer and Client for any damages resulting from such direct or indirect of the action that Influencer
            and Client direct engage;
          </li>
          <li>
            Take any action with respect to any Influencer's content, Client's Live Marketplace Campaign that AnyMind
            deems necessary or appropriate, including if AnyMind believes that such Influencer's content, Client's Live
            Marketplace Campaign violates the Terms and Conditions or violates any applicable laws, including the
            content standards, infringes any intellectual property right or other right of any person or entity,
            threatens the personal safety of users of Platform or the public or could create liability for AnyMind;
          </li>
          <li>
            Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or
            unauthorized use of AnyTag Self-Serve Service or Platform.
          </li>
        </ol>
      </li>

      <li>
        Without limiting the foregoing, AnyMind has the right to fully cooperate with any law enforcement authorities or
        court order requesting or directing the disclosure of the identity or other information of anyone posting any
        materials on or through Platform. Client waives and indemnifies AnyMind and its affiliates, licensees and
        service providers from any claims resulting from any action taken by AnyMind any of the foregoing parties during
        or as a result of its investigations and from any actions taken as a consequence of investigations by either
        AnyMind or law enforcement authorities. However, AnyMind does not undertake to review material before it is
        posted on Platform, any social media channels or the internet, and therefore cannot ensure prompt removal of
        objectionable material after it has been posted. Accordingly, AnyMind assumes no liability for any action or
        inaction regarding transmissions, communications or contents provided by any user, Influencer, Client or third
        party. AnyMind has no liability or responsibility to anyone for performance or nonperformance of the activities
        described in this section either by AnyMind, Influencer or Client.
      </li>

      <li>
        AnyMind only agrees to provide AnyTag Self-Serve Service to Client who accept these Terms and Conditions and
        abide by all the terms and conditions provided herein.
      </li>
    </ol>

    <h3>8. CLIENT RESPONSIBILITIES</h3>
    <p>Client responsibilities are as follows:</p>
    <ol css={[styles.numberedList, styles.sectionN(8)]}>
      <li>
        Client must provide certain registration details or other information as required by AnyMind to use certain
        features of AnyTag Self-Serve and Influencer Marketplace. Client guarantees that the information of Client
        provided to AnyMind for the use of AnyTag Self-Serve Service and Platform is accurate, current complete and up
        to date;
      </li>

      <li>Client shall adhere to the conditions of using the Platform and Content Standard and Prohibited Use;</li>

      <li>
        Client shall ensure that all persons who have access to Client's account on Platform and access either through
        Client's internet or any network connection are aware of the Terms and Conditions and comply with them. Client
        shall be wholly responsible for access to its own account. AnyMind shall not be held liable for any damages
        caused by or on Client's account;
      </li>

      <li>
        Client shall ensure that the material to be posted on Platform, any social media channels or internet is
        reviewed and complies with all local laws and regulations;
      </li>

      <li>
        Client agrees to provide the brief, instructions, content and materials and Client certifies and guarantees that
        the materials and advertisement contents:
        <ol css={styles.letterList}>
          <li>
            do not violate any law or regulation, including but not limited to any law or regulation governing false or
            deceptive advertising, sweepstakes, gambling, file-sharing, or trade disparagement;
          </li>
          <li>
            do not contain any misrepresentations or content that is defamatory or violates any rights of privacy or
            publicity;
          </li>
          <li>
            do not contain any adult sexual content, pornographic content, any child pornography, or link(s) to such
            content;
          </li>
          <li>do not contain or link to any malware, spyware or other malicious code;</li>
          <li>do not contain any link(s) to software piracy;</li>
          <li>
            do not contain any link(s) to any form of illegal activity or services, drugs, drug paraphernalia, or any
            controlled substances;
          </li>
          <li>
            do not contain any gratuitous displays of violence, self-harm, obscene or vulgar language, or abusive
            content, or content which endorses or threatens physical harm;
          </li>
          <li>
            do not contain any content promoting any type of hate mongering (i.e. racial, political, ethnic, religious,
            gender-based, sexuality-based, or personal);
          </li>
          <li>
            do not and will not infringe any copyright, related right, moral right, database right, trademark, patent,
            trade secret, or other proprietary right
          </li>
        </ol>
      </li>

      <li>
        Client acknowledges that Influencers are independent. AnyMind cannot and will not be held liable for the
        non-performance of Influencers within the scope of time given. In such a case, AnyMind will not refund any
        payment for AnyTag Self-Serve Service Client has made. The only remedy shall be for Client to choose a different
        time slot and/or Influencers;
      </li>

      <li>
        Client agrees to provide personal information of the person in charge to AnyMind for registration on Platform.
        This personal information is used in accordance with{' '}
        <Anchor to="/privacy" variant="blue" css={{ display: 'inline' }}>
          Privacy Policy
        </Anchor>
        , used to match Influencers with Client as well as for the management of such Live Marketplace Campaign. In
        cases where AnyMind shares personal information of any Influencers to Client for the purpose of AnyTag
        Self-Serve Service, Client agrees not to use such personal information for other purposes and not to share or
        store it without the Influencer's prior written consent.
      </li>
    </ol>

    <h3>9. CONFIDENTIAL INFORMATION</h3>
    <ol css={[styles.numberedList, styles.sectionN(9)]}>
      <li>
        Client acknowledges that Client's account is personal to Client and agrees not to provide any other person with
        access to Platform or portions of it using Client's user name, password or other security information.
      </li>

      <li>
        Client also acknowledges that Client must treat all information gathered from AnyMind, Platform or AnyTag
        Self-Serve Service as confidential and Client must not disclose it to any other person or entity or third party
        without the prior written consent from AnyMind.
      </li>

      <li>
        Client agrees to notify AnyMind immediately of any unauthorized access to or use of its user name or password or
        any other breach of security. Client also agrees to ensure that Client shall exit or sign out from Client's
        account on Platform at the end of each session. Client should use particular caution when accessing Client's
        account from a public or shared computer so that others are not able to view or record the password or other
        personal information.
      </li>

      <li>
        AnyMind shall not be liable for any loss or damage arising from Client's failure to comply with the above
        requirements.
      </li>

      <li>
        AnyMind shall not be liable for any data privacy law violation arising from Client's failure to comply with the
        above requirements. Client agrees that Client shall be solely responsible for such failure and shall indemnify
        and hold harmless AnyMind from all claim against Client's failure to comply with the above requirement.
      </li>

      <li>
        Client agrees and acknowledges that Client shall use confidential information of Influencer and AnyMind for the
        specific purpose of Live Campaign only and shall not use any confidential information of Influencer or AnyMind
        for other purposes without the prior written of Influencer or AnyMind.
      </li>
    </ol>

    <h3>10. INDEMNIFICATION</h3>
    <div css={styles.subsectionContent}>
      <p>
        Client agrees to defend, indemnify and hold harmless AnyMind, its Affiliates, licensors and service providers,
        and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and
        assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees
        (including reasonable attorneys' fees) arising out of or relating to Client's violation of the Terms and
        Conditions or Client's use of Platform, including, but not limited to Client's Live Marketplace Campaign's
        scope, any use of AnyTag Self-Serve Service's content, services and products other than as expressly authorized
        in the Terms and Conditions or Client's use of any information obtained from AnyMind.
      </p>
    </div>

    <h3>11. LIMITATION OF LIABILITY</h3>
    <div css={styles.subsectionContent}>
      <p>
        AnyMind will not be liable for any damage incurred by Client or third parties from the use of Platform or AnyTag
        Self-Serve Service, including, but not limited to Client's Live Marketplace Campaign unless such damage is
        attributable to the intentional or grossly negligent acts of AnyMind; provided, however, that in the case that
        AnyMind commits intentional or grossly negligent acts, AnyMind responsibility for the damages shall be limited
        to the total amount paid by Client in preceding 1 month prior to the event giving rise to the claim.
      </p>
    </div>

    <h3>12. CANCELLATION OF PLANS OR PACKAGES</h3>
    <ol css={[styles.numberedList, styles.sectionN(12)]}>
      <li>
        <div css={styles.subsection}>
          Cancellation Subscription Plans
          <div css={styles.subsection}>
            <ol css={styles.letterList}>
              <li>
                Client may cancel the Subscription Plans at any time. However, to avoid being charged for the next
                billing cycle, cancellations must be made before the next billing date.
              </li>
              <li>
                To cancel the Subscription Plans, please log into Client's account and navigate to the Billing Page, and
                click on "Contact Us", or contact our customer support team at anytag_selfserve@anymindgroup.com.
              </li>
              <li>
                Upon cancellation, Client will continue to have access to the AnyTag Dashboard until the end of the
                current billing cycle. Client will not be charged for the next billing period. Some features (such as
                Budget Allocation, Marketing Campaign, etc.) will be disabled at 00:00 SGT on the 1st day of the next
                month. However, Client still have access to the AnyTag Dashboard until the end of the current billing
                cycle.
              </li>
              <li>
                AnyMind does not offer partial refunds for any unused portion of the billing period. Once payment has
                been processed, it is non-refundable, and Client will have access to the platform until the end of
                Client current billing cycle.
              </li>
              <li>
                No refunds will be issued after payment has been processed, even if the service is cancelled prior to
                the end of the billing period.
              </li>
            </ol>
          </div>
        </div>
      </li>
      <li>
        <div css={styles.subsection}>
          AnyMind reserves the right to modify or update this Cancellation Policy at any time. Any changes will be
          communicated to Client via email or through notifications on the Platform. By continuing to use the platform
          after such changes, Client agrees to the revised cancellation policy.
        </div>
      </li>
      <li>
        <div css={styles.subsection}>
          Refund
          <div css={styles.subsection}>
            <ol css={styles.letterList}>
              <li>
                All payments for services, ads boosting budget, subscriptions, and packages on AnyTag Self-Serve Service
                are generally non-refundable, except in specific cases as outlined in this Refund.
              </li>
              <li>
                A refund will be considered by default if:
                <ol css={styles.romanList}>
                  <li>
                    Service Failure: AnyMind fails to deliver the services or features as promised or advertised due to
                    the reasons attributable to AnyMind; or
                  </li>
                  <li>
                    Incorrect Charges: If Client was incorrectly charged due to a system error, payment processing
                    mistake, or any other technical issue.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </li>
    </ol>
    <h3>13. TERMINATION</h3>
    <ol css={[styles.numberedList, styles.sectionN(13)]}>
      <li>
        AnyMind has the right to terminate or suspend Client's access to all or part of Platform for any or no reason,
        including without limitation, any violation of the Terms and Conditions. Upon termination of Terms and
        Conditions, Client shall immediately pay AnyMind all amounts owed by Client to AnyMind within seven (7) days (if
        any). AnyMind will not have any liability whatsoever to Client for any termination of Terms and Conditions,
        including for termination of Client's account or deletion of Client content.
      </li>
      <li>
        AnyMind reserves the right to modify or discontinue any or all of the AnyTag Self-Serve Service including
        returning the refund with or without notice to Client. AnyMind will not be liable to Client or any third party
        for termination of any Service on Platform.
      </li>
    </ol>
    <h3>14. WAIVER AND SEVERABILITY</h3>
    <ol css={[styles.numberedList, styles.sectionN(14)]}>
      <li>
        No omission or delay on AnyMind's part in exercising any or part of its rights under these Terms and Conditions
        shall operate as a waiver thereof.
      </li>
      <li>
        If any provision of these Terms and Conditions is held by a court or other tribunal of competent jurisdiction to
        be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the
        minimum extent such that the remaining provisions of the Terms and Conditions will continue in full force and
        effect.
      </li>
    </ol>
    <h3>15. FORMAL COMMUNICATION</h3>
    <ol css={[styles.numberedList, styles.sectionN(15)]}>
      <li>
        All formal communications between AnyMind and Client shall be executed through the following electronic mail
        and/or app notification.
      </li>
      <li>
        Client shall provide Client electronic mail address or app inbox/chat on Client's account on the Platform. This
        address is the only address where correspondence shall be sent from and received with regard to the contractual
        relationship between Client and AnyMind.
      </li>
      <li>
        Electronic mail sent and received in the above address or app inbox shall be deemed to have been formally and
        properly received and posted. Client cannot claim to not have received or not acknowledged a certain email or
        notification that has been sent or received in the address or inbox to be provided on Client's account.
      </li>
    </ol>
    <h3>16. EXCLUSION OF ANTI-SOCIAL FORCES</h3>
    <p>
      Client and AnyMind represent to each other that neither he/she/it/they nor his/her/its/their representatives,
      officers or other persons substantially involved in his/her/its/their management currently fall under an organized
      crime group, an organized crime group member, a quasi-member of an organized crime group, an enterprise associated
      with an organized crime group, a corporate racketeer (sokaiya), a social campaign advocate racketeer
      (shakai-undo-hyobo-goro), a political campaign racketeer (seiji-katsudo-hyobo-goro), an organized crime group with
      special intelligence, or a person having a close relationship with such entities or persons equivalent thereto,
      and affirm that he/she/it/they will not fall under any of the foregoing in the future.
    </p>
    <p>
      If Client or AnyMind breaches the above representations, the other party may immediately cancel use of the
      Platform and Terms and Conditions relating to AnyTag Self-Serve Service without warning.
    </p>
    <h3>17. FORCE MAJEURE</h3>
    <p>
      Except for payment obligations, neither Client nor AnyMind shall be liable for any failure to perform its
      obligations hereunder where such failure results from any cause beyond either Client's or AnyMind's reasonable
      control, including the elements; fire; flood; severe weather; earthquake; vandalism; accidents; sabotage; power
      failure; denial of service attacks or similar attacks; Internet failure; acts of God and the public enemy; acts of
      war; acts of terrorism; riots; civil or public disturbances; strikes, lock-outs, or labour disruptions; and any
      laws, orders, rules, regulations, acts, or restraints of any government or governmental body or authority, civil
      or military, including the orders and judgments of courts.
    </p>
    <h3>18. GOVERNING LAW AND JURISDICTION</h3>
    <ol css={[styles.numberedList, styles.sectionN(18)]}>
      <li>
        Any dispute arising out of or in connection with this contract, including any question regarding its existence,
        validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore
        International Arbitration Centre ("SIAC") in accordance with the Arbitration Rules of the Singapore
        International Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be
        incorporated by reference in this clause. The seat of the arbitration shall be Singapore. The tribunal shall
        consist of 3 arbitrator(s). The language of the arbitration shall be English.
      </li>
      <li>This contract is governed by the laws of Singapore.</li>
    </ol>
    <h3>19. RESTRICTED MATTERS</h3>
    <ol css={[styles.numberedList, styles.sectionN(19)]}>
      <li>
        AnyMind prohibits Influencer and Client from engaging in any of the following acts when using Platform or
        AnyCreator Service:
        <ol css={styles.letterList}>
          <li>
            Acts that violate the laws and regulations, court verdicts, resolutions or orders, or administrative
            measures that are legally binding;
          </li>
          <li>Acts that may be in violation of public order, morals or customs;</li>
          <li>
            Acts that infringe intellectual property rights, such as copyrights, trademarks and patent rights, rights to
            fame, privacy, and all other rights granted by law or by a contract with AnyMind and/or a third party;
          </li>
          <li>
            Acts of posting or transmitting excessively violent or explicit sexual expressions; expressions that amount
            to child pornography or child abuse; expressions that lead to discrimination by race, national origin,
            creed, gender, social status, family origin, etc.; expressions that induce or encourage suicide,
            self-injurious behaviour or drug abuse; or expressions that include anti-social content and lead to the
            discomfort of others;
          </li>
          <li>
            Acts that lead to the misrepresentation of AnyMind and/or a third party or that intentionally spread false
            information;
          </li>
          <li>
            Acts of using Platform for sales, marketing, advertising, solicitation or other commercial purposes (except
            for those approved by AnyMind); using Platform for the purpose of sexual conduct or obscene acts; using
            Platform for the purpose of meeting or engaging in sexual encounters with an unknown third party; using
            Platform for the purpose of harassment or libellous attacks against others; or otherwise using Platform for
            purposes other than as intended by Platform;
          </li>
          <li>
            Acts of transferring, assigning, commercially exploiting, reselling, or sublicensing access to AnyTag
            Self-Serve Services or Platform to any third party.
          </li>
          <li>Acts that benefit or involve collaboration with anti-social groups;</li>
          <li>Acts that are related to religious activities or invitations to certain religious groups;</li>
          <li>
            Acts of unauthorized or improper collection, disclosure, or provision of any other person's personal
            information, registered information, user history, or the like;
          </li>
          <li>
            Acts of interfering with the servers and/or network systems of Platform; fraudulently manipulating Platform
            by means of bots, cheat tools, or other technical measures; deliberately using defects of Platform; making
            unreasonable inquiries and/or undue claims such as repeatedly asking the same questions beyond what is
            necessary, and other acts of interfering with or hindering AnyMind's operation of Platform or others use of
            Platform;
          </li>
          <li>
            Acts of decoding the source code of Platform, such as by way of reverse engineering, disassembling or the
            like, for unreasonable purposes or in an unfair manner;
          </li>
          <li>Acts that aid or encourage any acts stated above; and</li>
          <li>Acts other than those set forth above that AnyMind reasonably deems to be inappropriate.</li>
        </ol>
      </li>
    </ol>
    <h3>20. LEGAL DISCLAIMER</h3>
    <p>
      AnyTag Self-Serve Service includes content provided by third parties, including materials provided by Influencer
      or Client, other users, bloggers and third party licensors, syndicators, aggregators and/or reporting services.
      All statements and/or opinions expressed in these materials, and all articles and responses to questions and other
      content, other than the content provided by AnyMind, are solely the opinions and the responsibility of the person
      or entity providing those materials. These materials do not necessarily reflect the opinion of AnyMind. AnyMind is
      not responsible, or liable to Influencer, Client or any third party, for the content or accuracy of any materials
      provided by any third parties.
    </p>
    <p>
      AnyMind shall provide no warranty, either expressly or impliedly, with respect to AnyTag Self-Serve Service or
      Platform, that there are no defects (including, without limitation, faults with respect to security, etc., errors
      or bugs, or violations of rights) or as to the safety, reliability, accuracy, completeness, effectiveness, and
      fitness for a particular purpose. AnyMind will in no way be responsible for providing Influencer or Client with
      AnyTag Self-Serve Service after deleting such defects.
    </p>
    <p>
      No warranties, promises, or representations of any kind, whether expressed or implied, are given as to the
      accuracy or standard of the information provided. AnyMind cannot and will not guarantee that this platform is
      bereft of viruses, malware, or anything else that might have destructive qualities. Any relianceInfluencer places
      on information from the platform is strictly at the Influencer’s own risk. AnyMind disclaims all liability and
      responsibility arising from any reliance placed on such materials by the Influencer or any other visitor(s) to
      AnyTag Self-Serve Service, or by anyone who may be informed of any of its contents.
    </p>
    <p>
      In no event will AnyMind, its affiliates or their licensors, service providers, employees, agents, officers or
      directors be liable for damages of any kind, arising out of or in connection with the use of, or inability to use,
      AnyTag Self-Serve Service, Platform, any websites or social network service linked to it, any content on AnyTag
      Self-Serve Service, Platform, or such other websites, or any services or items obtained through AnyTag Self-Serve
      Service, including any indirect, special, incidental, consequential or punitive damages, including but not limited
      to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or
      anticipated savings, loss of use, loss of goodwill, loss of data, and whether AnyMind used by tort (including
      negligence), breach of contract or otherwise, even if foreseeable.
    </p>
    <p>
      Any misuse of action or claim the Influencer may have arising out of or relating to the Terms and Conditions or
      AnyMind must be commenced within one (1) year after the misuse of action accrues, otherwise, such misuse of action
      or claim is permanently barred.
    </p>
    <p>
      The foregoing does not affect any liability which cannot be excluded or limited under applicable law. If any
      provision of these Terms and Conditions are held to be illegal, inapplicable or unenforceable in any jurisdiction,
      only that clause shall be voided. The remainder of the contract shall still be applicable and enforceable in the
      court of law.
    </p>
    <p>
      Certain countries’ laws do not allow limitations on implied warranties or the exclusion or limitation of certain
      kinds of damages. If these laws apply to Client, some or all of the above disclaimer, limitations, or exclusions
      may not apply to Client, and Client might have additional rights.
    </p>
  </div>
);

const styles = {
  container: css({
    padding: '48px',
    lineHeight: '1.6',
    '& h2': {
      fontSize: '20px',
      textAlign: 'center',
      fontWeight: 'normal',
      marginBottom: '40px'
    },
    '& h3': {
      marginTop: '40px',
      marginBottom: '16px',
      fontSize: '18px',
      fontWeight: 400
    },
    '& p': {
      marginBottom: '16px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '140%',
      color: THEME.text.colors.black.lv1
    },
    '& strong': {
      fontWeight: 600
    }
  }),
  subsection: css({
    paddingLeft: '24px',
    '& h4': {
      fontSize: '14px',
      fontWeight: 600,
      marginTop: '16px',
      marginBottom: '12px'
    },
    '& p': {
      fontSize: '14px',
      fontWeight: 400,
      marginTop: '12px',
      marginBottom: '8px'
    }
  }),
  subsectionContent: css({
    paddingLeft: '24px'
  }),
  numberedList: css({
    counterReset: 'section',
    listStyle: 'none',
    padding: 0,
    '> li': {
      position: 'relative',
      paddingLeft: '32px',
      marginBottom: '16px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '140%',
      color: THEME.text.colors.black.lv1,
      '&:before': {
        counterIncrement: 'section',
        content: '"3." counter(section)',
        position: 'absolute',
        left: 0,
        fontWeight: 600
      }
    }
  }),
  letterList: css({
    counterReset: 'letter',
    paddingLeft: '24px',
    listStyle: 'none',
    margin: 0,
    '&& > li': {
      position: 'relative',
      paddingLeft: '24px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '140%',
      color: THEME.text.colors.black.lv1,
      marginBottom: '8px',
      '&:before': {
        position: 'absolute',
        left: 0,
        fontWeight: 600,
        counterIncrement: 'letter',
        content: 'counter(letter, lower-alpha) ")"'
      }
    }
  }),
  romanList: css({
    counterReset: 'roman',
    paddingLeft: '24px',
    listStyle: 'none',
    margin: 0,
    '> li': {
      position: 'relative',
      paddingLeft: '24px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '140%',
      color: THEME.text.colors.black.lv1,
      marginBottom: '8px',
      '&:before': {
        position: 'absolute',
        left: 0,
        fontWeight: 600,
        counterIncrement: 'roman !important',
        content: 'counter(roman, lower-roman) ")" !important'
      }
    }
  }),
  sectionN: (n: number) =>
    css({
      '& li:before': {
        counterIncrement: 'section',
        content: `"${n}." counter(section)`
      }
    })
};
