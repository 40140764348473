import { useTranslation } from 'react-i18next';
import {
  MAX_AGE,
  MIN_AGE,
  MAX_FOLLOWERS,
  MIN_FOLLOWERS,
  GENDER_OPTIONS,
  MIN_MAX_AGE_OPTIONS,
  MIN_MAX_FOLLOWERS_OPTIONS,
  formatMinMaxAgeFilterLabel
} from '@/shared/constants';
import { useTranslateOptions } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';
import { CheckBoxesField, RangeSliderInputField } from '../RHFFields';
import { SimilarInfluencerPopperFilterFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const genderOptions = useTranslateOptions(GENDER_OPTIONS);

  return (
    <div css={{ width: '250px', padding: '16px' }}>
      <CheckBoxesField<SimilarInfluencerPopperFilterFormKeys>
        name="genders"
        options={genderOptions}
        title={t('General.Gender')}
      />
      <RangeSliderInputField<SimilarInfluencerPopperFilterFormKeys>
        title={t('General.Age')}
        options={MIN_MAX_AGE_OPTIONS}
        range={{ min: MIN_AGE, max: MAX_AGE }}
        minField={{ name: 'minAge', placeholder: '18-' }}
        maxField={{ name: 'maxAge', placeholder: '50+' }}
        formatLabel={formatMinMaxAgeFilterLabel}
      />
      <RangeSliderInputField<SimilarInfluencerPopperFilterFormKeys>
        step={10000}
        formatLabel={formatIntNumber}
        options={MIN_MAX_FOLLOWERS_OPTIONS}
        title={t('General.Number of Followers')}
        range={{ min: MIN_FOLLOWERS, max: MAX_FOLLOWERS }}
        minField={{ name: 'minFollowers', placeholder: '0' }}
        maxField={{ name: 'maxFollowers', placeholder: '1,000,000+' }}
      />
    </div>
  );
};
