import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';

export const SparkAdsSettingsSchema = z.object({
  adAccountSystemId: z.string(),
  adGroupId: z.string(),
  adName: z.string(),
  authCode: z.string(),
  callToAction: z.boolean(),
  tkCampaignId: z.string(),
  landingPageUrl: z.string().optional()
});
export type SparkAdsSettingsFormValues = z.infer<typeof SparkAdsSettingsSchema>;
export type SparkAdsSettingsFormKeys = UnionOfKeys<SparkAdsSettingsFormValues>;
