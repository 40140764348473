import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateTalentInfluencer.graphql';
import { Mutation, MutationupdateTalentInfluencerArgs } from '../../__generated__/globalTypes';

export const useUpdateTalentInfluencerMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateTalentInfluencerArgs>
) => {
  const [callUpdateTalentInfluencer, result] = useMutation<Mutation, MutationupdateTalentInfluencerArgs>(
    MUTATION,
    options
  );

  return { callUpdateTalentInfluencer, ...result };
};

export type UpdateTalentInfluencerMutationFunction = Mutation['updateTalentInfluencer'];
