import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramAnalyticsHashtags.graphql';
import { Query, QueryinstagramAnalyticsHashtagsArgs } from '../../__generated__/globalTypes';

export const useInstagramAnalyticsHashtagsQuery = (
  options?: QueryHookOptions<Query, QueryinstagramAnalyticsHashtagsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramAnalyticsHashtagsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramAnalyticsHashtagsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramAnalyticsHashtagsArgs>
): QueryResult<Query, QueryinstagramAnalyticsHashtagsArgs> & {
  getInstagramAnalyticsHashtags: LazyQueryExecFunction<Query, QueryinstagramAnalyticsHashtagsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramAnalyticsHashtags, result] = useLazyQuery<Query, QueryinstagramAnalyticsHashtagsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInstagramAnalyticsHashtags, ...result };
};

export const useInstagramAnalyticsHashtagsPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramAnalyticsHashtags = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramAnalyticsHashtagsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramAnalyticsHashtagsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramAnalyticsHashtags };
};
