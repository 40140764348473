import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { SC } from '../StyledComponents';
import { PostInsightFormKeys } from '../types';

export const PostInsightXhs = () => {
  const { t } = useTranslation();

  return (
    <>
      <SC.SectionWrapper>
        <SC.SectionTitle>{t('Engagements')}</SC.SectionTitle>

        <FormStyle.FieldsGroup itemsPerRow={4}>
          <SC.TextField<PostInsightFormKeys> type="number" name="likes" title={t('TextForm.Likes')} placeholder="0" />
          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="comments"
            placeholder="0"
            title={t('TextForm.Comments')}
          />
          <SC.TextField<PostInsightFormKeys> type="number" name="saves" placeholder="0" title={t('TextForm.Saves')} />
          <SC.TextField<PostInsightFormKeys> type="number" name="views" title={t('TextForm.Views')} placeholder="0" />
        </FormStyle.FieldsGroup>

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="0"
            name="averageViewDurationSeconds"
            title={t('TextForm.Avg View Duration')}
          />
          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="0"
            name="newFollowers"
            title={t('TextForm.New Followers')}
          />
        </FormStyle.FieldsGroup>
      </SC.SectionWrapper>

      <SC.SectionWrapper>
        <SC.SectionTitle>{t('Viewer Source')}</SC.SectionTitle>

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="0"
            name="viewsFromHome"
            title={t('TextForm.View from Home')}
          />
          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="0"
            name="viewsFromProfile"
            title={t('TextForm.View from Profile')}
          />
          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="0"
            name="viewsFromSearch"
            title={t('TextForm.View from Searching')}
          />
          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="0"
            name="viewsFromFollowing"
            title={t('TextForm.Views from Following')}
          />
          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="0"
            name="viewsFromOther"
            title={t('TextForm.Views from Other')}
          />
        </FormStyle.FieldsGroup>
      </SC.SectionWrapper>

      <SC.SectionWrapper css={{ borderBottom: 'none' }}>
        <SC.SectionTitle css={{ marginBottom: '16px' }}>{t('Viewer Demographics')}</SC.SectionTitle>

        <SC.SectionTitle>{t('General.Gender')}</SC.SectionTitle>
        <FormStyle.FieldsGroup itemsPerRow={2}>
          <SC.TextField<PostInsightFormKeys>
            unit="%"
            type="number"
            placeholder="0"
            name="malePercentage"
            title={t('TextForm.Male')}
          />
          <SC.TextField<PostInsightFormKeys>
            unit="%"
            type="number"
            placeholder="0"
            name="femalePercentage"
            title={t('TextForm.Female')}
          />
        </FormStyle.FieldsGroup>

        <SC.SectionTitle>{t('General.Age')}</SC.SectionTitle>
        <FormStyle.FieldsGroup itemsPerRow={5}>
          <SC.TextField<PostInsightFormKeys>
            unit="%"
            title="<18"
            type="number"
            placeholder="0"
            name="ageUp17Percentage"
          />
          <SC.TextField<PostInsightFormKeys>
            unit="%"
            title="18-24"
            type="number"
            placeholder="0"
            name="age1824Percentage"
          />
          <SC.TextField<PostInsightFormKeys>
            unit="%"
            title="25-34"
            type="number"
            placeholder="0"
            name="age2534Percentage"
          />
          <SC.TextField<PostInsightFormKeys>
            unit="%"
            title="35-44"
            type="number"
            placeholder="0"
            name="age3544Percentage"
          />
          <SC.TextField<PostInsightFormKeys>
            unit="%"
            title=">44"
            type="number"
            placeholder="0"
            name="age45UpPercentage"
          />
        </FormStyle.FieldsGroup>
      </SC.SectionWrapper>
    </>
  );
};
