import { ChartsContainer } from '@/shared/atoms';
import { getValuableVariables } from '@/shared/utils';
import { useQueryHelper } from '@/shared/hooks';
import { useTwitterAnalyticsPostsStatisticQuery } from '@/graphql';
import { PostingHabits, AverageEngagement } from '@/shared/molecules';
import {
  eyeBlueImage,
  likePinkImage,
  videoPinkImage,
  chatGreenImage,
  eyePurpleImage,
  networkBlueImage
} from '@/shared/assets';
import { AnalyticsPostsOverview } from '../../shared';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { useAnalyticsProfileSearch } from '../../hooks';

interface AnalyticsTwitterDashboardPostsOverviewProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsTwitterDashboardPostsOverview = ({
  analyticsAccount
}: AnalyticsTwitterDashboardPostsOverviewProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useTwitterAnalyticsPostsStatisticQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      twitterAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsPostsOverview help="" loading={loading} data={data?.twitterAnalyticsPostsStatistic}>
      {({ data: { averageEngagement, postingHabits } }) => (
        <ChartsContainer css={{ height: '324px' }}>
          <AverageEngagement
            itemsPerRow={3}
            data={[
              {
                icon: videoPinkImage,
                label: t('Avg posts/week'),
                value: averageEngagement.avgPostsWeek?.total,
                growth: averageEngagement.avgPostsWeek?.growth
              },
              {
                icon: likePinkImage,
                label: t('Avg Like'),
                value: averageEngagement.avgLike.total,
                growth: averageEngagement.avgLike.growth
              },
              {
                icon: eyeBlueImage,
                label: t('Avg View'),
                value: averageEngagement.avgView?.total,
                growth: averageEngagement.avgView?.growth
              },
              {
                icon: eyePurpleImage,
                label: t('Avg Impression'),
                value: averageEngagement.avgImpression?.total,
                growth: averageEngagement.avgImpression?.growth
              },
              {
                icon: chatGreenImage,
                label: t('Avg Reply'),
                value: averageEngagement.avgReply.total,
                growth: averageEngagement.avgReply.growth
              },
              {
                icon: networkBlueImage,
                label: t('Avg Retweet'),
                value: averageEngagement.avgRetweet.total,
                growth: averageEngagement.avgRetweet.growth
              }
            ]}
          />

          <PostingHabits data={postingHabits} />
        </ChartsContainer>
      )}
    </AnalyticsPostsOverview>
  );
};
