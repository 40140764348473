import { z } from 'zod';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { SocialAccountType } from '@/graphql';

export const EggPostFilterSchema = z.object({
  id: FilterValidations.number(),
  name: FilterValidations.string(),
  socialAccountId: FilterValidations.number(),
  socialAccountName: FilterValidations.string(),
  socialAccountMedia: FilterValidations.enum(SocialAccountType)
});
export const EggPostSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(EggPostFilterSchema, {
    id: '',
    name: '',
    socialAccountId: '',
    socialAccountName: '',
    socialAccountMedia: ''
  })
});

export type EggPostSearchSchemaType = z.infer<typeof EggPostSearchSchema>;
