import { css } from '@emotion/react';
import { Button, ButtonProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

interface SplitButtonProps {
  options: (ButtonProps & { isActive?: boolean })[];
  isActive?: boolean;
  className?: string;
  minWidth?: string;
}
export const SplitButton = ({ options, className, minWidth = '100px' }: SplitButtonProps) => (
  <div css={{ display: 'flex', alignItems: 'center' }} className={className}>
    {options.map(({ css, isActive, ...restOption }, i) => (
      <Button key={i} css={[styles.button(isActive, minWidth), css]} {...restOption} />
    ))}
  </div>
);

const styles = {
  button: (isActive?: boolean, minWidth?: string) =>
    css({
      letterSpacing: 0,
      minWidth,
      '&:first-of-type': { borderRadius: '3px 0 0 3px' },
      '&:last-of-type': { marginLeft: '-1px', borderRadius: '0 3px 3px 0' },
      ...(isActive
        ? {
            zIndex: 1,
            boxSizing: 'border-box',
            color: THEME.text.colors.blue,
            borderColor: THEME.border.colors.blue.lv2,
            backgroundColor: 'rgba(56, 146, 229, 0.1)'
          }
        : { '&:hover': { opacity: 0.7 } })
    })
};
