import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdatePackageProposalSocialAccountsBudget.graphql';
import { Mutation, MutationupdatePackageProposalSocialAccountsBudgetArgs } from '../../__generated__/globalTypes';

export const useUpdatePackageProposalSocialAccountsBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationupdatePackageProposalSocialAccountsBudgetArgs>
) => {
  const [callUpdatePackageProposalSocialAccountsBudget, result] = useMutation<
    Mutation,
    MutationupdatePackageProposalSocialAccountsBudgetArgs
  >(MUTATION, options);

  return { callUpdatePackageProposalSocialAccountsBudget, ...result };
};

export type UpdatePackageProposalSocialAccountsBudgetMutationFunction =
  Mutation['updatePackageProposalSocialAccountsBudget'];
