import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AnalyticsSocialAuthRedirectUrl.graphql';
import { Query, QueryanalyticsSocialAuthRedirectUrlArgs } from '../../__generated__/globalTypes';

export const useAnalyticsSocialAuthRedirectUrlQuery = (
  options?: QueryHookOptions<Query, QueryanalyticsSocialAuthRedirectUrlArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryanalyticsSocialAuthRedirectUrlArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAnalyticsSocialAuthRedirectUrlLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryanalyticsSocialAuthRedirectUrlArgs>
): QueryResult<Query, QueryanalyticsSocialAuthRedirectUrlArgs> & {
  getAnalyticsSocialAuthRedirectUrl: LazyQueryExecFunction<Query, QueryanalyticsSocialAuthRedirectUrlArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAnalyticsSocialAuthRedirectUrl, result] = useLazyQuery<Query, QueryanalyticsSocialAuthRedirectUrlArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getAnalyticsSocialAuthRedirectUrl, ...result };
};

export const useAnalyticsSocialAuthRedirectUrlPromiseQuery = () => {
  const client = useApolloClient();

  const getAnalyticsSocialAuthRedirectUrl = useCallback(
    (options?: Omit<QueryOptions<QueryanalyticsSocialAuthRedirectUrlArgs, Query>, 'query'>) =>
      client.query<Query, QueryanalyticsSocialAuthRedirectUrlArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAnalyticsSocialAuthRedirectUrl };
};
