import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { MarketplaceInfluencer, SocialAccountStatus } from '@/graphql';
import { bigIntFormatter, formatIntNumber, formatPercent } from '@/shared/utils';
import { InfluencerAvatar, InfluencerWidgetInformation } from '@/shared/molecules';
import {
  StatsSection,
  SocialMediasSection,
  ProfileWidgetTemplate,
  WidgetSelectButtonProps
} from '../../../Influencers';
import { MarketplaceParticipateInfluencerStatus } from '../../MarketplaceStatus';

interface ProfileWidgetProps {
  className?: string;
  profile: MarketplaceInfluencer;
  selectButtonProps?: WidgetSelectButtonProps;
}

export const ProfileWidget = ({
  className,
  selectButtonProps,
  profile: {
    id,
    age,
    name,
    email,
    avatar,
    gender,
    status,
    country,
    twitter,
    tiktok,
    youtube,
    facebook,
    instagram,
    followersCount,
    participateStatus,
    marketplaceJoinedCount,
    marketplacePostedCount,
    engagement: { averageEngagement, engagementRate }
  }
}: ProfileWidgetProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const stats = [
    { label: t('HeaderColumn.Total Followers'), value: bigIntFormatter(followersCount, 0) },
    { label: t('HeaderColumn.Engagement'), value: formatIntNumber(averageEngagement) },
    { label: t('HeaderColumn.ER'), value: formatPercent(engagementRate) },
    { label: t('HeaderColumn.Joined'), value: formatIntNumber(marketplaceJoinedCount) },
    { label: t('HeaderColumn.Posted'), value: formatIntNumber(marketplacePostedCount) }
  ];

  return (
    <ProfileWidgetTemplate className={className} selectButtonProps={selectButtonProps}>
      <InfluencerAvatar size="80px" src={avatar} />

      <InfluencerWidgetInformation
        age={age}
        name={name}
        gender={gender}
        country={country}
        isSignedUp={status === SocialAccountStatus.SIGNED_UP}
        profileUrl={generateProfileLink(id)}
      />

      <div css={{ marginLeft: '32px' }}>
        <p css={styles.statusLabel}>{t('General.Status')}</p>
        <MarketplaceParticipateInfluencerStatus css={{ minWidth: '80px' }} status={participateStatus} />
      </div>

      <StatsSection id={id} stats={stats} css={{ marginRight: '16px' }} />

      <SocialMediasSection
        tiktok={tiktok ? true : undefined}
        twitter={twitter ? true : undefined}
        youtube={youtube ? true : undefined}
        facebook={facebook ? true : undefined}
        instagram={instagram ? true : undefined}
        email={email ? true : undefined}
      />
    </ProfileWidgetTemplate>
  );
};
const styles = {
  statusLabel: css({
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '100%',
    textAlign: 'center',
    marginBottom: '4px',
    letterSpacing: '0.28px',
    color: THEME.text.colors.gray.lv3
  })
};
