import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { InstagramBrandAmbassadorPostsByUsername } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TableData } from '@/shared/types';
import { AnalyticsSelectedAccountInfo } from '../../AnalyticsProfile';
import { AmbassadorPostWidget } from './AmbassadorPostWidget';

export interface AnalyticsInstagramAmbassadorPostsListProps extends TableData<InstagramBrandAmbassadorPostsByUsername> {
  className?: string;
  hasBorder?: boolean;
  analyticsAccountId: AnalyticsSelectedAccountInfo['id'];
}

export const AnalyticsInstagramAmbassadorPostsList = ({
  loading,
  className,
  data = [],
  analyticsAccountId
}: AnalyticsInstagramAmbassadorPostsListProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ backgroundColor: THEME.background.colors.white, borderRadius: '0 0 3px 3px' }} className={className}>
      <RenderDataWithFallback loading={loading} hasNoData={!data.length}>
        <div css={styles.header}>
          <div css={{ padding: '0 16px', width: '190px' }}>{t('HeaderColumn.User')}</div>
          <div css={{ padding: '0 16px 0 20px', width: '100%' }}>{t('HeaderColumn.Brand mentions')}</div>
        </div>

        {data.map((post, index) => (
          <AmbassadorPostWidget key={index} data={post} analyticsAccountId={analyticsAccountId} />
        ))}
      </RenderDataWithFallback>
    </div>
  );
};
const styles = {
  header: css({
    height: '32px',
    display: 'flex',
    fontWeight: 600,
    fontSize: '12px',
    alignItems: 'center',
    color: THEME.text.colors.black.lv1,
    backgroundColor: THEME.background.colors.gray.lv1
  })
};
