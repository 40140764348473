import { FormProvider } from '@/shared/atoms';
import { AutoManagedCampaignFormSchema } from './schema';
import { Template } from './Template';
import { AutoManagedCampaignFormProps, AutoManagedCampaignFormValues } from './types';
import { defaultAutoManagedCampaignFormValues, getDefaultAutoManagedCampaignFormValues } from './utils';

export const AutoManagedCampaignForm = ({
  onSubmit,
  defaultValues = defaultAutoManagedCampaignFormValues
}: AutoManagedCampaignFormProps) => (
  <FormProvider<AutoManagedCampaignFormValues>
    onSubmit={onSubmit}
    defaultValues={defaultValues}
    zodSchema={AutoManagedCampaignFormSchema}
  >
    <Template />
  </FormProvider>
);

export * from './types';
export { getDefaultAutoManagedCampaignFormValues };
