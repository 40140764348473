import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteInstagramHashtagAnalytics.graphql';
import { Mutation, MutationdeleteInstagramHashtagAnalyticsArgs } from '../../__generated__/globalTypes';

export const useDeleteInstagramHashtagAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteInstagramHashtagAnalyticsArgs>
) => {
  const [callDeleteInstagramHashtagAnalytics, result] = useMutation<
    Mutation,
    MutationdeleteInstagramHashtagAnalyticsArgs
  >(MUTATION, options);

  return { callDeleteInstagramHashtagAnalytics, ...result };
};

export type DeleteInstagramHashtagAnalyticsMutationFunction = Mutation['deleteInstagramHashtagAnalytics'];
