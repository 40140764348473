import { z } from 'zod';
import { BaseValidations } from '@/shared/validations';

export const BaseSchema = z.object({
  hubspotId: z.string().nullable(),
  phoneNumber: z.string(),
  internalNetsuiteId: z.string(),
  companyUrl: z.string(),
  countryId: BaseValidations.CountryIdSchema,
  advertiserName: BaseValidations.CompanyNameSchema,
  advertiserId: z.number().or(z.string()),
  isNewlyCreated: z.boolean().optional()
});
export const AddAdvertiserSchema = z.object({
  type: z.literal('add'),
  email: z.string(),
  password: z.string().optional(),
  userName: z.string(),
  passwordConfirm: z.string().optional(),
  shouldSendInvitation: z.boolean()
});
export const EditAdvertiserSchema = z.object({
  type: z.literal('edit'),
  subscriptionType: BaseValidations.AccountManagerPlanSchema
});
export const AdvertiserFormBasedOnIdSchema = z
  .discriminatedUnion('type', [AddAdvertiserSchema, EditAdvertiserSchema])
  .and(BaseSchema)
  .superRefine((values, ctx) => {
    if (values.type === 'add') {
      if (values.userName || values.password || values.passwordConfirm) {
        const result = BaseValidations.EmailSchema.safeParse(values.email);
        if (result.success === false) {
          ctx.addIssue({
            message: result.error.issues.at(0)?.message,
            code: z.ZodIssueCode.custom,
            path: ['email']
          });
        }
      }
      if (values.email || values.password || values.passwordConfirm) {
        const result = BaseValidations.NameSchema.safeParse(values.userName);
        if (result.success === false) {
          ctx.addIssue({
            message: result.error.issues.at(0)?.message,
            code: z.ZodIssueCode.custom,
            path: ['userName']
          });
        }
      }
      if (!values.shouldSendInvitation && (values.email || values.userName || values.passwordConfirm)) {
        const result = BaseValidations.PasswordSchema.safeParse(values.password);
        if (result.success === false) {
          ctx.addIssue({
            message: result.error.issues.at(0)?.message,
            code: z.ZodIssueCode.custom,
            path: ['password']
          });
        }
      }
      if (!values.shouldSendInvitation && (values.email || values.userName || values.password)) {
        const result = BaseValidations.PasswordConfirmSchema.safeParse(values.passwordConfirm);
        if (result.success === false) {
          ctx.addIssue({
            message: result.error.issues.at(0)?.message,
            code: z.ZodIssueCode.custom,
            path: ['passwordConfirm']
          });
        }
      }
    }
  });
