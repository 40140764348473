import { PostDetailsType } from '@/shared/types';
import { InstagramComparePostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface InstagramComparePostDetailsModalProps {
  posts: readonly PostDetailsType[];
}

export const InstagramComparePostDetailsModal = ({ posts }: InstagramComparePostDetailsModalProps) => (
  <CarouselTemplateModal posts={posts}>
    {({ selectedPost, closeModal }) => <InstagramComparePostDetails id={selectedPost.id} onCloseModal={closeModal} />}
  </CarouselTemplateModal>
);
