import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CampaignType, JoinedCampaign, SocialAccountType, useFindJoinedCampaignsQuery } from '@/graphql';
import { Icon, Modal, Anchor, FormStyle, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useSelectItem, useToggleState } from '@/shared/hooks';
import { getSocialMediaText } from '@/shared/utils';
import {
  SocialMediaInputFieldAction,
  SocialMediaInputFieldsAction,
  SocialMediaInputFieldsActionProps
} from './SocialMediaInputFieldsAction';
import { SocialAccountsFormValues } from './types';

interface SocialAccountsProps {
  influencerId?: number;
}

export const SocialAccounts = ({ influencerId }: SocialAccountsProps) => {
  const { t } = useTranslation();
  const warningModal = useToggleState();
  const confirmModal = useToggleState();
  const { watch } = useFormContext<SocialAccountsFormValues>();
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem<{
    callback: () => void;
    socialAccountId?: number;
    socialMedia: SocialAccountType;
    joinedCampaigns: JoinedCampaign[];
  }>();
  const { data } = useFindJoinedCampaignsQuery({
    skip: !influencerId,
    fetchPolicy: 'cache-first',
    variables: { influencerId: Number(influencerId) }
  });

  const [facebookAccountValue, douyinAccountsValues, xhsAccountsValues] = watch([
    'facebookAccount',
    'douyinAccounts',
    'xhsAccounts'
  ]);
  const joinedCampaigns = data?.findJoinedCampaigns || [];
  const socialMediaText = getSocialMediaText(selectedItem?.socialMedia);

  const getSocialMediaHelp = (username: string, link: string) => (
    <div>
      {`${t('Tooltip.eg')} ${link}`}
      <br />
      {`=> ${t('Tooltip.username')} : '${username}'`}
    </div>
  );

  const handleDeleteAccount: SocialMediaInputFieldsActionProps['onDelete'] = ({
    callback,
    socialMedia,
    account: { socialAccountId }
  }) => {
    const accountJoinedCampaigns = joinedCampaigns.filter(
      (c) => c.socialAccountId === socialAccountId && c.socialAccountType === socialMedia
    );

    if (socialAccountId) {
      setSelectedItem({
        callback,
        socialMedia,
        socialAccountId,
        joinedCampaigns: accountJoinedCampaigns
      });

      if (accountJoinedCampaigns.length) {
        warningModal.open();
      } else {
        confirmModal.open();
      }
    } else {
      callback();
    }
  };

  return (
    <>
      <FormStyle.SectionTitle index={1} label={t('Social Account')} />

      <SocialMediaInputFieldAction
        placeholder="zuck"
        influencerId={influencerId}
        account={facebookAccountValue}
        name="facebookAccount.username"
        help={t('Tooltip.Extension Help')}
        title={t('TextForm.Facebook Username')}
        socialMedia={SocialAccountType.FACEBOOK}
        disabled={!facebookAccountValue.isAdding}
      />

      <SocialMediaInputFieldsAction
        name="facebookPages"
        placeholder="AnyTag"
        influencerId={influencerId}
        onDelete={handleDeleteAccount}
        help={t('Tooltip.Extension Help')}
        title={t('TextForm.Facebook Page Name')}
        socialMedia={SocialAccountType.FACEBOOK_PAGE}
        addButtonProps={{ title: t('Button.add page') }}
      />

      <SocialMediaInputFieldsAction
        influencerId={influencerId}
        name="instagramAccounts"
        placeholder="anymindgroup"
        onDelete={handleDeleteAccount}
        title={t('TextForm.Instagram Username')}
        socialMedia={SocialAccountType.INSTAGRAM}
        help={getSocialMediaHelp('anymindgroup', 'https://www.instagram.com/anymindgroup/')}
      />

      <SocialMediaInputFieldsAction
        name="twitterAccounts"
        placeholder="AnyMindGroup"
        influencerId={influencerId}
        onDelete={handleDeleteAccount}
        title={t('TextForm.X(Twitter) Username')}
        socialMedia={SocialAccountType.TWITTER}
        help={getSocialMediaHelp('AnyMindGroup', 'https://twitter.com/AnyMindGroup')}
      />

      <SocialMediaInputFieldsAction
        name="youtubeAccounts"
        influencerId={influencerId}
        onDelete={handleDeleteAccount}
        title={t('TextForm.YouTube URL')}
        socialMedia={SocialAccountType.YOUTUBE}
        addButtonProps={{ title: t('Button.add channel') }}
        placeholder="https://www.youtube.com/c/AnyMindGroup1"
        help={getSocialMediaHelp('AnyMindGroup1', 'https://www.youtube.com/c/AnyMindGroup1')}
      />

      <SocialMediaInputFieldsAction
        name="tiktokAccounts"
        influencerId={influencerId}
        onDelete={handleDeleteAccount}
        placeholder="anymind_creatorgrowth"
        title={t('TextForm.TikTok Username')}
        socialMedia={SocialAccountType.TIKTOK}
        help={getSocialMediaHelp('anymind_creatorgrowth', 'https://www.tiktok.com/@anymind_creatorgrowth')}
      />

      {douyinAccountsValues?.length ? (
        <SocialMediaInputFieldsAction
          name="douyinAccounts"
          influencerId={influencerId}
          title={t('TextForm.Douyin Username')}
          socialMedia={SocialAccountType.DOUYIN}
        />
      ) : null}

      <SocialMediaInputFieldsAction
        name="threadsAccounts"
        placeholder="anymindgroup"
        influencerId={influencerId}
        onDelete={handleDeleteAccount}
        title={t('TextForm.Threads Username')}
        socialMedia={SocialAccountType.THREADS}
        help={getSocialMediaHelp('anymindgroup', 'https://www.threads.net/@anymindgroup/')}
      />

      {xhsAccountsValues?.length ? (
        <SocialMediaInputFieldsAction
          name="xhsAccounts"
          influencerId={influencerId}
          title={t('TextForm.Xiaohongshu Username')}
          socialMedia={SocialAccountType.XHS}
        />
      ) : null}

      <Modal open={confirmModal.status} onClose={confirmModal.close} css={{ width: '504px' }}>
        <ModalContent>
          <ModalTitle css={{ display: 'flex' }}>
            <Icon size={20} icon="warning" color={THEME.text.colors.red.lv2} css={{ marginRight: '8px' }} />
            {t('Dialog.Delete social account', { socialMedia: socialMediaText })}
          </ModalTitle>

          <p css={{ marginTop: '16px' }}>
            {t('deleteSocialAccountConfirmText01', { socialMedia: socialMediaText })}
            <br />
            {t('deleteSocialAccountConfirmText02')}
          </p>
        </ModalContent>

        <ModalFooterActions
          cancelButtonProps={{
            onClick: () => {
              confirmModal.close();
              resetSelectedItem();
            }
          }}
          submitButtonProps={{
            variant: 'red',
            onClick: () => {
              selectedItem?.callback();
              confirmModal.close();
              resetSelectedItem();
            }
          }}
        />
      </Modal>

      <Modal open={warningModal.status} onClose={warningModal.close} css={{ width: '600px' }}>
        <ModalContent>
          <ModalTitle css={{ display: 'flex' }}>
            <Icon size={20} icon="warning" color={THEME.attention.colors.yellow} css={{ marginRight: '8px' }} />
            {t('Dialog.This account cannot be deleted')}
          </ModalTitle>

          <p css={{ marginTop: '16px' }}>{t('deleteSocialAccountWhileJoinedCampaignWarningText')}</p>

          <div css={{ marginTop: '16px', lineHeight: '22px' }}>
            {selectedItem?.joinedCampaigns
              ?.slice(0, 5)
              .map(({ id, type, title }) => (
                <Anchor
                  key={`joined-campaign-${id}`}
                  label={title}
                  variant="blue"
                  target="_blank"
                  to={type === CampaignType.ENGAGEMENT ? '/engagement/$id/proposal' : '/marketplace/$id'}
                  params={{ id: id.toString() }}
                />
              ))}
          </div>
        </ModalContent>

        <ModalFooterActions
          cancelButtonProps={{
            onClick: () => {
              warningModal.close();
              resetSelectedItem();
            }
          }}
        />
      </Modal>
    </>
  );
};
