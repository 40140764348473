import { usePermissions } from '.';

const defaultPermissions = {
  hidePaymentAndAddressWidgets: false,
  disablePaymentEditing: false,
  disableAddressEditing: false
};
export const useAddressAndPaymentInfoPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (
    permissions?.influencerAddressAndPaymentInformation?.noView ||
    permissions?.influencerAddressAndPaymentInformation === null
  ) {
    return {
      hidePaymentAndAddressWidgets: true,
      disablePaymentEditing: true,
      disableAddressEditing: true
    };
  } else if (permissions?.influencerAddressAndPaymentInformation?.view) {
    return { hidePaymentAndAddressWidgets: false, disablePaymentEditing: true, disableAddressEditing: true };
  }

  return defaultPermissions;
};
