import { z } from 'zod';
import { getToday } from '@/shared/utils';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';
import { getThirtyDaysAgo } from '@/shared/utils';
import { FilterValidations } from '@/shared/validations';
import { LIMIT } from '@/shared/constants';

export const InfluencerYoutubeShortsSearchSchema = z.object({
  filter: z
    .object({
      keyword: z.string().optional(),
      endDate: z.string().default(getToday()),
      startDate: z.string().default(getThirtyDaysAgo()),
      name: z.string().optional()
    })
    .default({}),
  sort: FilterValidations.sort(YoutubeAnalyticsPostsSortOrder).default(FilterValidations.sort({}).parse({})),
  page: z.number().default(1),
  limit: z.number().default(LIMIT)
});
export type InfluencerYoutubeShortsSearchSchemaType = z.infer<typeof InfluencerYoutubeShortsSearchSchema>;
