import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { SupportedSocialMediaType } from '@/shared/types';
import { getSocialMediaTextLink } from '@/shared/utils';
import { Anchor } from '../../Anchor';
import { SocialIcon } from '../../Icon';
import { MediaPreview } from '../../MediaPreview';

interface PostProps {
  size?: string;
  title?: string;
  line?: number;
  thumbnail?: string;
  className?: string;
  onClick: () => void;
  postUrl?: string | null;
  socialPostType?: keyof typeof SupportedSocialMediaType;
}

export const Post = ({
  title,
  postUrl,
  onClick,
  line = 2,
  className,
  size = '50px',
  thumbnail = '',
  socialPostType
}: PostProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.postsWithThumbnail} className={className}>
      <MediaPreview
        src={thumbnail}
        caption={title}
        onClick={onClick}
        captionProps={{ margin: 4 }}
        css={{ width: size, height: size, borderRadius: '2px' }}
      />
      {postUrl || title ? (
        <div css={styles.captionWrapper}>
          {title ? (
            <Anchor
              href="#"
              label={title}
              lines={line}
              onClick={(e) => {
                e.preventDefault();
                onClick();
              }}
            />
          ) : null}
          {socialPostType && socialPostType !== 'INSTAGRAM_STORY' ? (
            <Anchor
              variant="blue"
              target="_blank"
              href={postUrl || '#'}
              css={{ width: '170px', fontSize: '12px', display: 'flex', alignItems: 'center' }}
            >
              <SocialIcon size="14px" type={socialPostType} css={{ marginRight: '4px' }} />
              {t('View on', { socialMedia: getSocialMediaTextLink(socialPostType) })}
            </Anchor>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const styles = {
  postsWithThumbnail: css({ display: 'flex', minWidth: '200px', maxWidth: '300px', alignItems: 'center' }),
  captionWrapper: css({ gap: '2px', display: 'flex', marginLeft: '8px', overflow: 'hidden', flexDirection: 'column' })
};
