import { useUpdateAgencyMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { AgencyForm, AgencyFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';
import { useAgencyDetailsContext } from './AgencyDetailsContext';

export const EditAgency = () => {
  const { agency } = useAgencyDetailsContext();
  const { callUpdateAgency } = useUpdateAgencyMutation();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();

  const handleSubmit: FormAction<AgencyFormValues>['onSubmit'] = async ({
    logo,
    hubspotId,
    countryId,
    companyUrl,
    agencyName,
    phoneNumber,
    advertiserIds,
    internalNetsuiteId
  }) => {
    try {
      await callUpdateAgency({
        variables: {
          input: {
            countryId,
            agencyName,
            id: agency.id,
            logo: logo || null,
            hubspotId: hubspotId || null,
            companyUrl: companyUrl || null,
            phoneNumber: phoneNumber || null,
            advertiserIds: advertiserIds.map(Number),
            internalNetsuiteId: internalNetsuiteId || null
          }
        }
      });
      enqueueSnackbar(t('succeededInUpdating', { name: agencyName }), { variant: 'success' });
      navigate({ to: '/agency' });
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: agencyName }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <AgencyForm
      defaultValues={{
        type: 'edit',
        id: agency.id,
        logo: agency.logo || '',
        agencyName: agency.name,
        countryId: agency.country.id,
        hubspotId: agency.hubspotId,
        companyUrl: agency.companyUrl,
        phoneNumber: agency.phoneNumber || '',
        advertiserIds: agency.advertiserIds.map(String),
        internalNetsuiteId: agency.internalNetsuiteId || ''
      }}
      onSubmit={handleSubmit}
    />
  );
};
