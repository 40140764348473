import { PostDetailsType } from '@/shared/types';
import { InstagramHashtagPostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface InstagramHashtagPostDetailsModalProps {
  posts: PostDetailsType[];
}

export const InstagramHashtagPostDetailsModal = ({ posts }: InstagramHashtagPostDetailsModalProps) => (
  <CarouselTemplateModal posts={posts}>
    {({ selectedPost, closeModal }) => <InstagramHashtagPostDetails id={selectedPost.id} onCloseModal={closeModal} />}
  </CarouselTemplateModal>
);
