import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { linkOptions } from '@tanstack/react-router';
import { Anchor, CheckBox, RowProps, Status, TableCell, TextCutter } from '@/shared/atoms';
import { formatIntNumber, formatPercent, formatPrice } from '@/shared/utils';
import { AllTiktokAdReportSummary, Maybe, TiktokAdDetailReport, TikTokAdReportType, TikTokAdStatus } from '@/graphql';
import { THEME } from '@/shared/constants';
import { getAdReportStatusColumnProps } from '../utils';
import { useAdCampaignReportColumns } from './useAdCampaignReportColumns';

export interface AdCampaignReportTableProps {
  loading?: boolean;
  adCampaignIdFromUrl?: string;
  selectedAdCampaignIds: string[];
  adReportType: TikTokAdReportType;
  summaryData?: AllTiktokAdReportSummary | null;
  onClickCampaignId: (id: string | string[]) => void;
  allTiktokAdsReport: readonly TiktokAdDetailReport[];
}

export const useAdCampaignReportTable = ({
  summaryData,
  adReportType,
  onClickCampaignId,
  allTiktokAdsReport,
  adCampaignIdFromUrl,
  selectedAdCampaignIds
}: AdCampaignReportTableProps) => {
  const { t } = useTranslation();
  const { columns, selectedColumns } = useAdCampaignReportColumns({
    adReportType,
    total: summaryData?.total
  });

  const currency = t(summaryData?.currency || '');

  const getStatCells = (
    rowStats?: (Omit<TiktokAdDetailReport, 'status'> & { status: Maybe<TikTokAdStatus> }) | null
  ) => [
    rowStats?.status === null ? '-' : <Status {...getAdReportStatusColumnProps(rowStats?.status, t)} />,
    <TableCell.Number
      unit={rowStats?.adBudget ? currency : ''}
      value={formatPrice(rowStats?.adBudget || null, currency)}
    />,
    <TableCell.Number
      unit={rowStats?.adCost ? currency : ''}
      value={formatPrice(rowStats?.adCost || null, currency)}
    />,
    <TableCell.Number value={formatIntNumber(rowStats?.adImpressions)} />,
    <TableCell.Number unit={rowStats?.adCpm ? currency : ''} value={formatPrice(rowStats?.adCpm || null, currency)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.adReach)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.adFrequency)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.adClicks)} />,
    <TableCell.Number unit={rowStats?.adCpc ? currency : ''} value={formatPrice(rowStats?.adCpc || null, currency)} />,
    <TableCell.Number value={formatPercent(rowStats?.adCtr, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.adResult)} />,
    <TableCell.Number
      unit={rowStats?.adCostPerResult ? currency : ''}
      value={formatPrice(rowStats?.adCostPerResult || null, currency)}
    />,
    <TableCell.Number
      unit={rowStats?.adCostPer1000Reached ? currency : ''}
      value={formatPrice(rowStats?.adCostPer1000Reached || null, currency)}
    />,
    <TableCell.Number value={formatPercent(rowStats?.adResultRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.videoPlayActions)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.videoWatched2s)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.videoWatched6s)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.videoViewsP25)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.videoViewsP50)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.videoViewsP75)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.videoViewsP100)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.averageVideoPlayPerUser)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.averageVideoPlay)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.adEngagements)} />,
    <TableCell.Number value={formatPercent(rowStats?.adEr, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.adFollows)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.adLikes)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.adComments)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.adShares)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.interactiveAddOnImpressions)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.interactiveAddOnDestinationClicks)} />
  ];

  const totalRow: RowProps = {
    styles: css({
      height: '44px',
      pointerEvents: 'none',
      '> td': { fontWeight: 600, backgroundColor: THEME.table.header.background.colors.lv2 }
    }),
    cells: [
      <div css={{ minWidth: '290px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span css={{ fontSize: '11px' }}>{t('HeaderColumn.Total')}</span>
        <span>{summaryData?.total}</span>
      </div>,
      ...getStatCells({ ...summaryData?.summary, status: null })
    ]
  };
  const campaignRows: RowProps[] = allTiktokAdsReport.map(
    ({ adCampaignName, adGroupName, adName, adCampaignId, adGroupId, adId, postId, campaignId, ...stats }) => {
      let nextLevelLink;
      let itemId;
      let itemName;

      switch (adReportType) {
        case TikTokAdReportType.AD:
          itemId = adId;
          itemName = adName;
          nextLevelLink = linkOptions({
            to: '/tiktok/campaigns/$id/posts/$postId',
            params: {
              id: campaignId?.toString() || '',
              postId: postId?.toString() || ''
            }
          });
          break;
        case TikTokAdReportType.AD_GROUP:
          itemId = adGroupId;
          itemName = adGroupName;
          nextLevelLink = linkOptions({
            to: '/tiktok/ad-campaign-report/$id/$groupId',
            params: { id: adCampaignIdFromUrl || '', groupId: adGroupId || '' }
          });
          break;

        case TikTokAdReportType.AD_CAMPAIGN:
        default:
          itemId = adCampaignId;
          itemName = adCampaignName;
          nextLevelLink = linkOptions({ to: '/tiktok/ad-campaign-report/$id', params: { id: adCampaignId || '' } });
      }

      return [
        <div css={{ display: 'flex', gap: '8px' }}>
          <CheckBox
            checked={itemId ? selectedAdCampaignIds.includes(itemId) : false}
            onClick={() => (itemId ? onClickCampaignId(itemId) : null)}
          />
          <Anchor
            {...nextLevelLink}
            disabled={adReportType === TikTokAdReportType.AD && stats.status === TikTokAdStatus.DISABLE}
          >
            <TextCutter text={itemName} />
          </Anchor>
        </div>,
        ...getStatCells(stats)
      ];
    }
  );

  return { columns, selectedColumns, rows: [totalRow, ...campaignRows] };
};
