import { useState } from 'react';

export const useToggle = (initialState?: boolean) => {
  const [on, setOnState] = useState(initialState || false);

  const toggle = () => setOnState((o) => !o);
  const setOn = () => setOnState(true);
  const setOff = () => setOnState(false);

  return { on, toggle, setOn, setOff } as const;
};

export type UseToggleReturnType = ReturnType<typeof useToggle>;
