import { DownloadButton } from '@/shared/atoms';
import { ListWithPagination } from '@/shared/molecules';
import { convertOrderToORDER, getOffset, getRestApiUrl, getValuableVariables } from '@/shared/utils';
import { useDownloadFile, useParamsSearch } from '@/shared/hooks';
import { SocialPostType, useAllInfluencerAccountPostsQuery } from '@/graphql';
import { ProfilePostsCount, InfluencerXhsPostsList, InfluencerXhsPostsFilter } from '@/shared/organisms';
import { useInitialInfluencerPostsListWithName } from '../hooks';
import { getIsSponsoredPostForAPI } from './utils';
import { InfluencerXhsPostsSearchSchemaType } from './schemaTypes';

export const InfluencerXhsPicturePosts = () => {
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName({
    title: 'appHeader.Picture Post List',
    titleWithName: 'appHeader.PicturePostListWithAccountName'
  });
  const { page, limit, sort, filter, setSort, setFilter } = useParamsSearch<InfluencerXhsPostsSearchSchemaType>();

  const { data, loading } = useAllInfluencerAccountPostsQuery({
    skip: !influencerId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      socialPostType: SocialPostType.XHS,
      socialAccountId: mainSocialAccountId,
      isSponsoredPost: getIsSponsoredPostForAPI(filter.postType),
      isVideo: false,
      keyword: filter.keyword,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) }
    })
  });

  const totalRecords = data?.allInfluencerAccountPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <InfluencerXhsPostsFilter filter={filter} setFilter={setFilter} />

      <ProfilePostsCount count={totalRecords}>
        <DownloadButton
          loading={downloading}
          onClick={() =>
            handleDownloadFile({
              filenamePrefix: 'scraping_posts',
              url: getRestApiUrl(`influencer/social_post/${mainSocialAccountId}/${SocialPostType.XHS}/csv/`),
              params: {
                isVideo: 'false'
              }
            })
          }
        />
      </ProfilePostsCount>

      <InfluencerXhsPostsList
        sort={sort}
        setSort={setSort}
        loading={loading}
        influencerId={influencerId}
        totalRecords={totalRecords}
        socialPostType={SocialPostType.XHS}
        data={data?.allInfluencerAccountPosts || []}
      />
    </ListWithPagination>
  );
};
