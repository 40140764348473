import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AllowInfluencersUserProposal.graphql';
import { Mutation, MutationallowInfluencersUserProposalArgs } from '../../__generated__/globalTypes';

export const useAllowInfluencersUserProposalMutation = (
  options?: MutationHookOptions<Mutation, MutationallowInfluencersUserProposalArgs>
) => {
  const [callAllowInfluencersUserProposal, result] = useMutation<Mutation, MutationallowInfluencersUserProposalArgs>(
    MUTATION,
    options
  );

  return { callAllowInfluencersUserProposal, ...result };
};

export type AllowInfluencersUserProposalMutationFunction = Mutation['allowInfluencersUserProposal'];
