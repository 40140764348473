import { useRef } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useDeepCompareEffect } from '@/shared/hooks';
import { ChatDemoMessageType } from './types';
import { ChatDemoMessage } from './ChatDemoMessage';

export interface ChatDemoMessagesListProps {
  messages: ChatDemoMessageType[];
}

export const ChatDemoMessagesList = ({ messages }: ChatDemoMessagesListProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const rect = ref.current?.getBoundingClientRect();

  useDeepCompareEffect(() => {
    if (ref.current) {
      const listItems = ref.current.children.item(0)?.children;
      const length = listItems?.length || 0;

      // We ignore scrolling behavior from 2 firstly messages
      if (listItems && length > 2) {
        // get lastest messages sent by user and scroll to there
        if (messages.at(-2)?.position === 'right') {
          const scrollingElement = listItems.item(listItems.length - 2);

          if (scrollingElement) {
            setTimeout(() => {
              scrollingElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
            }, 10); // Way for rendering a bit
          }
        }
      }
    }
  }, [messages]); // Listen message list changes

  return (
    <div css={{ flex: '1 1 0', overflow: 'hidden', position: 'relative' }}>
      <div ref={ref} css={styles.wrapper}>
        <div css={styles.messages}>
          {messages.map((message, index) => (
            <ChatDemoMessage
              message={message}
              key={`message-${index}`}
              css={messages.length > 2 && index === messages.length - 1 && rect && { minHeight: rect.height }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    flex: '1 0 0',
    maxHeight: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100% !important',
    '::-webkit-scrollbar': { display: 'none' }
  }),
  messages: css({
    gap: '24px',
    display: 'flex',
    minHeight: 'inherit',
    boxSizing: 'border-box',
    flexDirection: 'column'
  }),
  scrollDownButton: css({
    padding: 0,
    left: '50%',
    flex: 'none',
    bottom: '8px',
    height: '24px',
    minWidth: '24px',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    border: `1.5px solid ${THEME.border.colors.white}`
  })
};
