import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { ButtonLink, FadeInImage } from '@/shared/atoms';
import {
  analyticsImage01,
  analyticsImage02,
  analyticsImage03,
  analyticsImage04,
  analyticsGroupImage
} from '@/shared/assets';

export const AnalyticsOnboarding = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.pageGrid}>
      <div css={styles.smAnalyticsTile}>
        <h3 css={styles.smAnalyticsTileHeader}>{t('Social Media Analytics')}</h3>
        <p css={styles.smAnalyticsTileDescription}>
          {t('You can also add on other options including competitor, hashtag, trend, and follower analytics')}
        </p>
        <ButtonLink
          variant="blue"
          to="/analytics/add"
          title={t('Analytics.AddAnAnalytics')}
          css={{ marginTop: '24px', padding: '0 32px', width: 'max-content' }}
        />
      </div>
      <FadeInImage src={analyticsGroupImage} css={{ width: '103%' }} />

      <div css={{ display: 'flex', gap: '24px' }}>
        <div css={styles.imagesContainer}>
          <FadeInImage src={analyticsImage01} css={styles.analyticsItemImg} />
          <h3 css={styles.analyticsItemHeader}>{t('Social Media Management')}</h3>
          <p css={styles.analyticsItemDescription}>
            {t('Analyze daily changes in key indicators including reach and web traffic, changes post insights')}
          </p>
        </div>
        <div css={styles.imagesContainer}>
          <FadeInImage src={analyticsImage02} css={styles.analyticsItemImg} />
          <h3 css={styles.analyticsItemHeader}>{t('Competitor analytics')}</h3>
          <p css={styles.analyticsItemDescription}>
            {t(
              "Track competitor accounts' follower trends, audience attributes, posts and more to drive your own account"
            )}
          </p>
        </div>
      </div>
      <div css={{ display: 'flex', gap: '24px' }}>
        <div css={styles.imagesContainer}>
          <FadeInImage src={analyticsImage03} css={styles.analyticsItemImg} />
          <h3 css={styles.analyticsItemHeader}>{t('Hashtag and trend analytics')}</h3>
          <p css={styles.analyticsItemDescription}>
            {t('Measure the effectiveness of your own hashtags and those of your competitors and trending keywords')}
          </p>
        </div>
        <div css={styles.imagesContainer}>
          <FadeInImage src={analyticsImage04} css={styles.analyticsItemImg} />
          <h3 css={styles.analyticsItemHeader}>{t('Find your passionate fans')}</h3>
          <p css={styles.analyticsItemDescription}>
            {t('Track follower accounts who are true fans and UGC posts they created')}
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  imagesContainer: css({ flex: '1 1 0' }),
  analyticsItemImg: css({ width: '100%', marginBottom: '16px' }),
  analyticsItemHeader: css({ fontWeight: 600, fontSize: '14px', marginBottom: '8px' }),
  smAnalyticsTileHeader: css({ fontWeight: 600, fontSize: '22px', marginBottom: '8px' }),
  analyticsItemDescription: css({ fontWeight: 400, fontSize: '12px', lineHeight: '140%' }),
  smAnalyticsTileDescription: css({ fontWeight: 400, fontSize: '14px', lineHeight: '140%' }),
  smAnalyticsTile: css({ display: 'flex', placeContent: 'center', flexDirection: 'column' }),
  pageGrid: css({
    display: 'grid',
    padding: '40px',
    gap: '12px 24px',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: THEME.background.colors.white
  })
};
