import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteSocialAccountPackage.graphql';
import { Mutation, MutationdeleteSocialAccountPackageArgs } from '../../__generated__/globalTypes';

export const useDeleteSocialAccountPackageMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteSocialAccountPackageArgs>
) => {
  const [callDeleteSocialAccountPackage, result] = useMutation<Mutation, MutationdeleteSocialAccountPackageArgs>(
    MUTATION,
    options
  );

  return { callDeleteSocialAccountPackage, ...result };
};

export type DeleteSocialAccountPackageMutationFunction = Mutation['deleteSocialAccountPackage'];
