import { css } from '@emotion/react';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { Icon } from './Icon';

export const ClearIcon = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div {...props} css={clearIconStyles.iconWrapper}>
    <Icon icon="close" size="7px" color="#fff" />
  </div>
);

export const clearIconStyles = {
  iconWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
    marginRight: '4px',
    borderRadius: '100%',
    backgroundColor: '#C5D0DA',
    flexShrink: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  })
};
