import { Navigate } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import { THEME } from '@/shared/constants';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { AddInfluencerForOthers } from './AddInfluencerForOthers';
import { AddInfluencerForTalentAgency } from './AddInfluencerForTalentAgency';

export const AddInfluencer = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    href: '/influencers',
    alwaysBackToParent: true,
    title: 'documentTitle.AddInfluencer',
    appHeader: 'appHeader.Add New Influencer'
  });
  const { isTalentAgency, isAdminStaffPartner } = useUserRoles();

  return (
    <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
      <Header />

      {isTalentAgency ? (
        <AddInfluencerForTalentAgency />
      ) : isAdminStaffPartner ? (
        <AddInfluencerForOthers />
      ) : (
        <Navigate to="/influencers" />
      )}
    </div>
  );
};
