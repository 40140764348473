import { z } from 'zod';
import { BcaRequestStatus } from '@/graphql';

export const BrandContentPermissionsTypeSchema = z.object({
  disabled: z.boolean().optional(),
  instagram_account_id: z.string(),
  instagram_account_name: z.string(),
  brand_id: z.string(),
  isResendable: z.boolean().nullable(),
  status: z.nativeEnum(BcaRequestStatus).nullable()
});

export const BrandContentPermissionsSchema = z.object({
  brandContentPermission: z.array(BrandContentPermissionsTypeSchema).nullable()
});
