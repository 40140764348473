import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { IconNames } from '@/shared/assets';
import { Icon } from '../Icon';
import { NotiIcon } from '../Notification';
import { useChatBoxContext } from './ChatBoxContext';

export const ChatBoxToastMessage = () => {
  const { toastMessage, clearToastMessage } = useChatBoxContext();

  const { backgroundColor, prefixIcon } = ((): { backgroundColor: string; prefixIcon: IconNames | null } => {
    switch (toastMessage?.type) {
      case 'error':
        return {
          prefixIcon: 'info',
          backgroundColor: THEME.attention.colors.red
        };
      case 'success':
        return {
          prefixIcon: null,
          backgroundColor: THEME.attention.colors.green
        };
      case 'info':
      default:
        return { backgroundColor: THEME.attention.colors.blue, prefixIcon: 'info' };
    }
  })();

  return toastMessage ? (
    <div css={[styles.wrapper, { backgroundColor }]}>
      {prefixIcon ? (
        <NotiIcon
          size={14}
          icon={prefixIcon}
          color={THEME.icon.colors.white}
          css={{ margin: 0, transform: 'translateY(2px)' }}
        />
      ) : null}
      <div>{toastMessage.message}</div>
      <Icon
        size={10}
        icon="close"
        onClick={clearToastMessage}
        color={THEME.icon.colors.white}
        css={{ cursor: 'pointer', marginLeft: 'auto' }}
      />
    </div>
  ) : null;
};

const styles = {
  wrapper: css({
    gap: '4px',
    top: '8px',
    left: '50%',
    padding: '8px',
    display: 'flex',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '100%',
    fontStyle: 'normal',
    width: 'max-content',
    position: 'absolute',
    borderRadius: '8.4px',
    alignItems: 'baseline',
    letterSpacing: 'normal',
    boxSizing: 'border-box',
    maxWidth: 'calc(100% - 32px)',
    transform: 'translateX(-50%)',
    color: THEME.text.colors.white
  })
};
