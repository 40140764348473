import {
  InfluencerInstagramProfileSimilarInfluencers,
  InfluencerInstagramProfileSimilarInfluencersProps
} from '../../InfluencerProfiles';

type InfluencerAnalyticsInstagramProfileSimilarInfluencersProps = InfluencerInstagramProfileSimilarInfluencersProps;

export const InfluencerAnalyticsInstagramProfileSimilarInfluencers = (
  props: InfluencerAnalyticsInstagramProfileSimilarInfluencersProps
) => <InfluencerInstagramProfileSimilarInfluencers {...props} />;
