import { useTranslation } from 'react-i18next';
import { YoutubeCompareOverviewSummaryData, YoutubeCompareAccountOverview } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { InfluencerAvatar } from '@/shared/molecules';
import { AnalyticsCompareOverviewTable } from '../../shared';
import { AnalyticsYoutubeComparableAccountType } from '../types';

export interface AnalyticsCompareYoutubeOverviewTableProps {
  data: YoutubeCompareAccountOverview[];
  comparableAccounts: AnalyticsYoutubeComparableAccountType[];
}

const getNumberGrowthProps = (data?: YoutubeCompareOverviewSummaryData) => ({
  total: data?.total || 0,
  growth: data?.growth || 0,
  rate: data?.percentage || 0
});

export const AnalyticsCompareYoutubeOverviewTable = ({
  data,
  comparableAccounts
}: AnalyticsCompareYoutubeOverviewTableProps) => {
  const { t } = useTranslation();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Account'), sticky: { active: true, hasBorderRight: true } },
    { title: t('HeaderColumn.Subscribers (Total)'), help: t<string>('Tooltip.compareSubscribersTotal') },
    { title: t('HeaderColumn.Posts (Total)'), help: t<string>('Tooltip.comparePostsTotal') },
    { title: t('HeaderColumn.Avg Views'), help: t<string>('Tooltip.compareAvgViews') },
    { title: t('HeaderColumn.Maximum Views'), help: t<string>('Tooltip.compareMaximumViews') },
    { title: t('HeaderColumn.Avg Likes'), help: t<string>('Tooltip.compareAvgLikes') },
    { title: t('HeaderColumn.Maximum Likes'), help: t<string>('Tooltip.compareMaximumLikes') },
    { title: t('HeaderColumn.Avg Comments'), help: t<string>('Tooltip.compareAvgComments') }
  ];
  const rows: RowProps[] = comparableAccounts.map(({ id, username, avatarUrl }) => {
    const summary = data.find((item) => item.accountId === id)?.summary;

    return [
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <InfluencerAvatar size="42px" src={avatarUrl} css={{ borderRadius: 0 }} />
        <TableCell.Text value={username} css={{ marginLeft: '8px' }} />
      </div>,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.totalSubscribers)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.totalPosts)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.averageViews)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.maximumViews)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.averageLikes)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.maximumLikes)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.averageComments)} />
    ];
  });

  return comparableAccounts.length ? (
    <AnalyticsCompareOverviewTable data={{ rows, columns, rowHeight: '62px' }} />
  ) : null;
};
