import { Tooltip as MantineTooltip, TooltipProps as MantineTooltipProps } from '@mantine/core';
import { ReactNode } from 'react';

export interface TooltipProps extends Omit<MantineTooltipProps, 'label' | 'sx'> {
  help?: ReactNode;
  children: ReactNode;
}

/**
 * @description if children of Tooltip is some custom component - it must forwardRef to work TODO: reouter maybe update to
 * https://v7.mantine.dev/core/tooltip
 */
export const Tooltip = ({ children, help, disabled, withArrow = true, className, ...restProps }: TooltipProps) => (
  <MantineTooltip
    withArrow={withArrow}
    arrowRadius={1}
    arrowSize={6}
    label={help}
    disabled={disabled || !help}
    withinPortal
    {...restProps}
  >
    {children}
  </MantineTooltip>
);
