import { FilterAction } from '@/shared/types';
import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { PackageProposalFilterFormValues } from '../PackageContentList';
import { Template } from './Template';

export interface PackageContentFilterProps extends FilterAction<PackageProposalFilterFormValues> {
  loading: boolean;
}

export const PackageContentFilter = ({ filter, setFilter, loading }: PackageContentFilterProps) => (
  <FilterWrapper>
    <FilterProvider initialValues={filter} onSubmit={setFilter} basicFilter={<Template loading={loading} />} />
  </FilterWrapper>
);
