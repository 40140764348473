import { useTranslation } from 'react-i18next';
import { getSocialMediaTextLink } from '@/shared/utils';
import { SupportedSocialMediaType } from '@/shared/types';
import { Anchor, TextCutter, SocialIcon, MediaPreview } from '@/shared/atoms';
import { POST_REPORT_STICKY_COL_DIMS } from './utils';

interface PostProps {
  url: string;
  size?: string;
  content: string;
  onClickPost: () => void;
  thumbNail: string | null;
}

export const Post = ({ url, content, thumbNail, onClickPost, size = '40px' }: PostProps) => {
  const { t } = useTranslation();

  return (
    <div
      css={{
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
        width: POST_REPORT_STICKY_COL_DIMS.postCaptionLinkWidth
      }}
    >
      <MediaPreview
        objectFit="contain"
        onClick={onClickPost}
        css={{ width: size, height: size, borderRadius: '2px' }}
        src={thumbNail}
        caption={content}
        captionProps={{ margin: 4 }}
      />
      <div css={{ display: 'flex', marginLeft: '8px', justifyContent: 'space-between', gap: '8px', minWidth: '100px' }}>
        <div>
          <TextCutter
            lines={2}
            onClick={onClickPost}
            text={content || '-'}
            css={{ fontSize: '12px', cursor: 'pointer', lineHeight: '12px' }}
          />
          {url ? (
            <Anchor
              href={url}
              target="_blank"
              variant="blue"
              css={{ display: 'flex', alignItems: 'center', marginTop: '4px', fontSize: '12px' }}
            >
              <SocialIcon size="16px" type={SupportedSocialMediaType.TIKTOK} css={{ marginRight: '4px' }} />
              {t('View on', { socialMedia: getSocialMediaTextLink(SupportedSocialMediaType.TIKTOK) })}
            </Anchor>
          ) : null}
        </div>
      </div>
    </div>
  );
};
