import { useTranslation } from 'react-i18next';
import { useMarketplacePermissions } from '@/auth';
import { ApplicantInfluencer } from '@/graphql';
import { useSelectItems } from '@/shared/hooks';
import { MarketplaceApplicantsSortProps, MarketplaceApplicantsListProps } from '@/shared/organisms';
import { formatIntNumber } from '@/shared/utils';

interface Props {
  totalRecords: number;
  listApplicants: readonly ApplicantInfluencer[];
}

export const useToggleSelectedApplicants = ({ totalRecords, listApplicants }: Props) => {
  const { t } = useTranslation();
  const { hideToggleApplicantFromProposalBtn } = useMarketplacePermissions();
  const {
    selectedItems: selectedApplicants,
    selectedItemIds: selectedApplicantIds,
    hasAllItemsSelected: hasAllApplicantsSelected,
    selectItem: selectApplicant,
    setSelectedItemIds: setSelectedApplicantIds,
    toggleSelectAllItems: toggleSelectAllApplicants
  } = useSelectItems({ listItems: listApplicants, key: 'id' });

  const sortSelectButtonProps: MarketplaceApplicantsSortProps['selectButtonProps'] = {
    onClick: toggleSelectAllApplicants,
    disabled: listApplicants.length === 0,
    checked: selectedApplicantIds.length > 0,
    indeterminate: listApplicants.length > 0 && !hasAllApplicantsSelected,
    label:
      selectedApplicantIds.length > 0
        ? t('influencer selected', { count: selectedApplicantIds.length })
        : `${formatIntNumber(totalRecords)} ${t('Influencers found')}`
  };

  const generateListSelectButtonProps: MarketplaceApplicantsListProps['generateSelectButtonProps'] = ({ id }) => ({
    type: 'checkbox',
    onClick: () => selectApplicant(id),
    checked: selectedApplicantIds.includes(id)
  });

  return {
    selectedApplicants,
    setSelectedApplicantIds,
    onSelectAll: listApplicants.length > 0 ? toggleSelectAllApplicants : undefined,
    sortSelectButtonProps: !hideToggleApplicantFromProposalBtn ? sortSelectButtonProps : undefined,
    generateListSelectButtonProps: !hideToggleApplicantFromProposalBtn ? generateListSelectButtonProps : undefined
  };
};
