import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { SocialAccountType } from '@/graphql';
import { Icon, TableCell, Table, SocialIcon, ColumnProps, RowProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface KeyFeaturesTableProps {
  className?: string;
}

export const KeyFeaturesTable = ({ className }: KeyFeaturesTableProps) => {
  const { t } = useTranslation();

  const checkIcon = (
    <div css={{ display: 'flex', justifyContent: 'center' }}>
      <Icon icon="check" color="#3892e5" size={12} />
    </div>
  );
  const supportedSocialMedias = (
    <div css={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
      <SocialIcon size="16px" type={SocialAccountType.FACEBOOK} />
      <SocialIcon size="16px" type={SocialAccountType.YOUTUBE} />
      <SocialIcon size="16px" type={SocialAccountType.INSTAGRAM} />
    </div>
  );

  const columns: ColumnProps[] = [
    { title: t('Key Features'), styles: styles.column },
    { title: t('Free Trial'), styles: styles.column },
    { title: t('Basic'), styles: styles.column },
    { title: t('Enterprise'), styles: styles.column }
  ];
  /* eslint-disable react/jsx-key */
  const rows: RowProps[] = [
    {
      styles: styles.cell,
      cells: [
        <TableCell.Text value={t('Supported SNS')} css={styles.featureName} />,
        supportedSocialMedias,
        supportedSocialMedias,
        supportedSocialMedias
      ]
    },
    {
      styles: styles.cell,
      cells: [
        <TableCell.Text value={t('Analytics Dashboard')} css={styles.featureName} />,
        checkIcon,
        checkIcon,
        checkIcon
      ]
    },
    {
      styles: styles.cell,
      cells: [
        <TableCell.Text value={t('Compare Accounts')} css={styles.featureName} />,
        checkIcon,
        checkIcon,
        checkIcon
      ]
    },
    {
      styles: styles.cell,
      cells: [
        <TableCell.Text value={t('HashTags Analysis')} css={styles.featureName} />,
        checkIcon,
        checkIcon,
        checkIcon
      ]
    },
    {
      styles: styles.cell,
      cells: [<TableCell.Text value={t('Fan Analysis')} css={styles.featureName} />, checkIcon, null, checkIcon]
    },
    {
      styles: styles.cell,
      cells: [<TableCell.Text value={t('UGC Management')} css={styles.featureName} />, checkIcon, null, checkIcon]
    },
    {
      styles: styles.cell,
      cells: [
        <TableCell.Text value={t('Compare Accounts')} css={styles.featureName} />,
        <TableCell.Text css={styles.text} value="5" />,
        <TableCell.Text css={styles.text} value="5" />,
        <TableCell.Text css={styles.text} value="10" />
      ]
    },
    {
      styles: styles.cell,
      cells: [
        <TableCell.Text value={t('Hashtags ')} css={styles.featureName} />,
        <TableCell.Text css={styles.text} value="5" />,
        <TableCell.Text css={styles.text} value="5" />,
        <TableCell.Text css={styles.text} value="10" />
      ]
    }
  ];

  return (
    <div css={styles.wrapper} className={className}>
      <Table hasBorder={false} data={{ rows, columns, rowHeight: '40px' }} />
    </div>
  );
};

const styles = {
  featureName: css({ color: THEME.text.colors.gray.lv3, fontSize: '12px' }),
  text: css({ fontSize: '12px', minWidth: 'unset', fontWeight: 700, textAlign: 'center' }),
  wrapper: css({ borderRadius: '3px', borderLeft: 'solid 1px #dee5ec', borderRight: 'solid 1px #dee5ec' }),
  column: css({
    fontSize: '12px',
    borderTop: THEME.border.base,
    '&:last-of-type': { paddingRight: '8px' },
    '&:not(:first-of-type)': { textAlign: 'center' }
  }),
  cell: css({
    '&:first-of-type': { borderTop: THEME.border.base },
    '&:last-of-type': { borderBottom: THEME.border.base },
    '> td': {
      '&:last-of-type': { paddingRight: '8px' },
      '&:not(:first-of-type)': { borderLeft: '1px solid #eef3f7' }
    }
  })
};
