import { useTranslation } from 'react-i18next';
import { TwitterAnalyticsPostsSortOrder, TwitterAnalyticsPostItemPayload } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatIntNumber, formatPercent } from '@/shared/utils';

type CustomColumnProps = ColumnProps<TwitterAnalyticsPostsSortOrder, keyof TwitterAnalyticsPostItemPayload>;

interface Props {
  data: readonly TwitterAnalyticsPostItemPayload[];
}

export const useAnalyticsTwitterPostsListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Posts'), sticky: { active: true, hasBorderRight: true } },
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      sortField: TwitterAnalyticsPostsSortOrder.LIKES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.View'),
      sortField: TwitterAnalyticsPostsSortOrder.VIEWS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Reply'),
      sortField: TwitterAnalyticsPostsSortOrder.REPLIES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Retweet'),
      sortField: TwitterAnalyticsPostsSortOrder.RETWEETS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Imps'),
      sortField: TwitterAnalyticsPostsSortOrder.IMPRESSIONS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement'),
      sortField: TwitterAnalyticsPostsSortOrder.ENGAGEMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement Rate'),
      sortField: TwitterAnalyticsPostsSortOrder.ENGAGEMENT_RATE
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      sortField: TwitterAnalyticsPostsSortOrder.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({ id, view, like, reply, title, retweet, postDate, thumbnail, impression, engagement, engagementRate }) => [
      <TableCell.Post title={title} thumbnail={thumbnail || ''} onClick={() => setPostDetailsParam(id)} />,
      <TableCell.Number value={formatIntNumber(like)} />,
      <TableCell.Number value={formatIntNumber(view)} />,
      <TableCell.Number value={formatIntNumber(reply)} />,
      <TableCell.Number value={formatIntNumber(retweet)} />,
      <TableCell.Number value={formatIntNumber(impression)} />,
      <TableCell.Number value={formatIntNumber(engagement)} />,
      <TableCell.Number value={formatPercent(engagementRate, false)} unit="%" />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
