import { useForm } from 'react-hook-form';
import { linkOptions, useSearch } from '@tanstack/react-router';
import { useMarketplacePermissions, useUserRoles } from '@/auth';
import { InfluencerAvatar, MarketplacePostStatus } from '@/shared/molecules';
import { MarketplacePost, MarketplaceDetail, MarketplaceCampaignPostStatusForAdvertiser } from '@/graphql';
import { useGenerateInfluencerProfileLink, useQueryHelper, useSelectItems } from '@/shared/hooks';
import {
  Icon,
  Anchor,
  Tooltip,
  RowProps,
  CheckBox,
  TableCell,
  SocialIcon,
  ColumnProps,
  MediaPreview,
  SocialIconProps
} from '@/shared/atoms';
import { getRevenueItems } from '../../../MarketplaceJoinedInfluencers/utils';
import { UpdateCostsFormValues, usePostPricesAvailability } from '../../../hooks';

interface Props {
  hasTrackingReferral?: boolean;
  marketplace: MarketplaceDetail;
  posts: readonly MarketplacePost[];
}

export const useMarketplacePostsListTable = ({
  posts,
  hasTrackingReferral,
  marketplace: { isGiftingCampaign, socialMedia, currency, id: mkpId }
}: Props) => {
  const searchParams = useSearch({ strict: false }) as {
    filter: {
      draftPost: string | null;
    };
  };
  const { t, navigate } = useQueryHelper();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const { isAdvertiser, isAdminStaffAgency, isAdminStaff } = useUserRoles();
  const { hideApproveRejectInappropriatePostBtn } = useMarketplacePermissions();
  const allPostPrices = usePostPricesAvailability(socialMedia);

  const { control, handleSubmit, formState, reset } = useForm<UpdateCostsFormValues>({
    reValidateMode: 'onChange',
    values: posts?.reduce<UpdateCostsFormValues>((rs, post) => {
      const {
        revenuePerPost,
        revenuePerLike,
        revenuePerView,
        revenuePerShare,
        revenuePerClick,
        revenuePerAction,
        revenuePerComment
      } = getRevenueItems(post.revenueItems);

      rs[`${post.influencer.id}_revenuePerPost`] = revenuePerPost?.value?.toString() || '';
      rs[`${post.influencer.id}_revenuePerLike`] = revenuePerLike?.value?.toString() || '';
      rs[`${post.influencer.id}_revenuePerView`] = revenuePerView?.value?.toString() || '';
      rs[`${post.influencer.id}_revenuePerShare`] = revenuePerShare?.value?.toString() || '';
      rs[`${post.influencer.id}_revenuePerClick`] = revenuePerClick?.value?.toString() || '';
      rs[`${post.influencer.id}_revenuePerComment`] = revenuePerComment?.value?.toString() || '';
      rs[`${post.influencer.id}_revenuePerAction`] = revenuePerAction?.value?.toString() || '';

      return rs;
    }, {})
  });

  const checkHasPostStatusControl = (post: Pick<MarketplacePost, 'id' | 'status'>) =>
    !hideApproveRejectInappropriatePostBtn &&
    post.id &&
    post.status &&
    [
      MarketplaceCampaignPostStatusForAdvertiser.EFFECTIVE,
      MarketplaceCampaignPostStatusForAdvertiser.WARNING,
      ...(isGiftingCampaign ? [MarketplaceCampaignPostStatusForAdvertiser.JOINED] : [])
    ].includes(post.status);

  const postsActions = useSelectItems<MarketplacePost, keyof MarketplacePost>({
    key: 'id',
    listItems: posts.reduce<MarketplacePost[]>(
      (acc, curr) => (checkHasPostStatusControl(curr) ? [...acc, curr] : acc),
      []
    )
  });

  const { allItemIds, selectedItems, selectItem, toggleSelectAllItems } = postsActions;
  const hasSelectAllCheckBox = !hideApproveRejectInappropriatePostBtn && allItemIds.length;
  const columns: ColumnProps[] = [
    {
      sticky: { active: true },
      hidden: !hasSelectAllCheckBox,
      title: (
        <CheckBox
          onClick={toggleSelectAllItems}
          disabled={!allItemIds.length}
          tooltipProps={{ help: t('Select all') }}
        />
      )
    },
    {
      title: <div>{t('HeaderColumn.Influencer')}</div>,
      sticky: { active: true, hasBorderRight: true, left: hasSelectAllCheckBox ? '40px' : undefined }
    },
    { title: t('HeaderColumn.Content') },
    { title: t('HeaderColumn.Referral Code'), hidden: !hasTrackingReferral },
    { title: t('HeaderColumn.Social'), styles: { textAlign: 'center' } },
    { title: t('HeaderColumn.Post Status') },
    { title: t('HeaderColumn.Revenue per Posting'), hidden: !isAdminStaff || !allPostPrices.costPerPost },
    { title: t('HeaderColumn.Revenue per Like'), hidden: !isAdminStaff || !allPostPrices.costPerLike },
    { title: t('HeaderColumn.Revenue per View'), hidden: !isAdminStaff || !allPostPrices.costPerView },
    { title: t('HeaderColumn.Revenue per Share'), hidden: !isAdminStaff || !allPostPrices.costPerShare },
    { title: t('HeaderColumn.Revenue per Click'), hidden: !isAdminStaff || !allPostPrices.costPerClick },
    { title: t('HeaderColumn.Revenue per Comment'), hidden: !isAdminStaff || !allPostPrices.costPerComment },
    { title: t('HeaderColumn.Revenue per Conversion'), hidden: !isAdminStaff || !allPostPrices.costPerAction },
    { title: t('HeaderColumn.Post date') }
  ];
  const rows: RowProps[] = posts.map(
    ({
      id,
      status,
      draftId,
      content,
      thumbNail,
      influencer,
      postedDate,
      couponCode,
      needReview,
      socialMedia,
      revenueItems,
      costEditable,
      warningReason
    }) => {
      const isDraftPost = !!draftId && !id && (isAdvertiser || isAdminStaffAgency);
      const isReviewingDraftPost = isDraftPost && status === MarketplaceCampaignPostStatusForAdvertiser.DRAFT;
      const isSelectedPost = selectedItems.findIndex((p) => p.id === id && p.influencer.id === influencer.id) !== -1;
      const postDetailsUrl =
        !!id || !!draftId
          ? linkOptions({
              to: '/marketplace/$id/posts/$postId',
              params: { id: mkpId.toString(), postId: isDraftPost ? draftId.toString() : id?.toString() || '' },
              search: {
                ...searchParams,
                filter: { ...searchParams.filter, draftPost: draftId ? Boolean(true).toString() : null }
              }
            })
          : undefined;
      const {
        revenuePerPost,
        revenuePerLike,
        revenuePerView,
        revenuePerShare,
        revenuePerClick,
        revenuePerAction,
        revenuePerComment
      } = getRevenueItems(revenueItems);

      const goToPostDetails = () => {
        if (postDetailsUrl) {
          navigate(postDetailsUrl);
        }
      };

      return [
        checkHasPostStatusControl({ id, status }) ? (
          <CheckBox
            checked={isSelectedPost}
            onClick={id ? () => selectItem(id) : undefined}
            tooltipProps={{ help: t(isSelectedPost ? 'Unselect' : 'Select') }}
          />
        ) : (
          <div />
        ),
        <TableCell.Link
          label={influencer.name}
          css={{ display: 'flex', alignItems: 'center', minWidth: '100px' }}
          {...generateProfileLink(influencer.id)}
        >
          <InfluencerAvatar size="40px" src={influencer.avatar} asChild />
          <TableCell.Text css={{ marginLeft: '8px', minWidth: '100px' }} value={influencer.name} />
        </TableCell.Link>,
        <div
          onClick={goToPostDetails}
          css={{ cursor: postDetailsUrl ? 'pointer' : 'default', display: 'flex', alignItems: 'center', gap: '8px' }}
        >
          <MediaPreview src={thumbNail} css={{ width: '60px', height: '40px' }} />
          <Anchor
            lines={2}
            css={{ maxWidth: '300px', width: 'max-content' }}
            variant={isReviewingDraftPost ? 'blue' : 'default'}
            label={isReviewingDraftPost ? t('Review draft post') : content || '-'}
          />
          {needReview ? (
            <Tooltip position="top" help={t('Tooltip.Post is modified, please review')}>
              <div css={{ marginLeft: '4px' }}>
                <Icon icon="warning" size={16} color="#40B87C" />
              </div>
            </Tooltip>
          ) : undefined}
        </div>,
        <TableCell.Text value={couponCode || ''} css={{ minWidth: '80px' }} />,
        <div css={{ display: 'block', margin: '0 auto', width: 'max-content' }}>
          <SocialIcon type={socialMedia as SocialIconProps['type']} />
        </div>,
        status ? <MarketplacePostStatus status={status} warningReason={warningReason || undefined} /> : '',
        <TableCell.ControlledRevenueInput
          control={control}
          revenueItem={revenuePerPost}
          name={`${influencer.id}_revenuePerPost`}
          input={{
            type: 'number',
            disabled: !costEditable,
            unit: currency
          }}
        />,
        <TableCell.ControlledRevenueInput
          name={`${influencer.id}_revenuePerLike`}
          control={control}
          revenueItem={revenuePerLike}
          input={{
            type: 'number',
            disabled: !costEditable,
            unit: currency
          }}
        />,
        <TableCell.ControlledRevenueInput
          name={`${influencer.id}_revenuePerView`}
          control={control}
          revenueItem={revenuePerView}
          input={{
            type: 'number',
            disabled: !costEditable,
            unit: currency
          }}
        />,
        <TableCell.ControlledRevenueInput
          name={`${influencer.id}_revenuePerShare`}
          control={control}
          revenueItem={revenuePerShare}
          input={{
            type: 'number',
            disabled: !costEditable,
            unit: currency
          }}
        />,
        <TableCell.ControlledRevenueInput
          name={`${influencer.id}_revenuePerClick`}
          control={control}
          revenueItem={revenuePerClick}
          input={{
            type: 'number',
            disabled: !costEditable,
            unit: currency
          }}
        />,
        <TableCell.ControlledRevenueInput
          name={`${influencer.id}_revenuePerComment`}
          control={control}
          revenueItem={revenuePerComment}
          input={{
            type: 'number',
            disabled: !costEditable,
            unit: currency
          }}
        />,
        <TableCell.ControlledRevenueInput
          name={`${influencer.id}_revenuePerAction`}
          control={control}
          revenueItem={revenuePerAction}
          input={{
            type: 'number',
            disabled: !costEditable,
            unit: currency
          }}
        />,
        <TableCell.Date value={postedDate || ''} />
      ];
    }
  );

  return { rows, columns, postsActions, handleSubmit, formState, reset };
};
