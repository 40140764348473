import { useTranslation } from 'react-i18next';
import { TableData } from '@/shared/types';
import { ApplicantInfluencer } from '@/graphql';
import { useInfluencerPermissions } from '@/auth';
import { ListEmpty, RenderDataWithFallback } from '@/shared/atoms';
import { WidgetSelectButtonProps } from '../../../Influencers';
import { ProfileWidget } from './ProfileWidget';

export interface MarketplaceApplicantsListProps extends TableData<ApplicantInfluencer> {
  noInfluencersJoined?: boolean;
  generateSelectButtonProps?: (influencer: ApplicantInfluencer) => WidgetSelectButtonProps | undefined;
}

export const MarketplaceApplicantsList = ({
  loading,
  data = [],
  noInfluencersJoined,
  generateSelectButtonProps
}: MarketplaceApplicantsListProps) => {
  const { t } = useTranslation();
  const { hideAddInfluencerBtn } = useInfluencerPermissions();

  return (
    <RenderDataWithFallback
      loading={loading}
      hasNoData={data?.length === 0}
      noDataNode={
        <ListEmpty
          hideDescription={noInfluencersJoined}
          title={noInfluencersJoined ? t('Please wait until influencers apply for this campaign') : undefined}
          linkProps={
            !noInfluencersJoined && hideAddInfluencerBtn
              ? { to: '/influencer/add', label: t('Influencer.AddAnInfluencer') }
              : undefined
          }
        />
      }
    >
      {data.map((profile, index) => (
        <ProfileWidget
          profile={profile}
          key={`${profile.id}-${index}`}
          selectButtonProps={generateSelectButtonProps?.(profile)}
        />
      ))}
    </RenderDataWithFallback>
  );
};
