import { useTranslation } from 'react-i18next';
import { DISPLAY_CAMPAIGN_OPTIONS } from '@/shared/constants';
import { useTranslateOptions } from '@/shared/hooks';
import { RadiosField, RadiosFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const CampaignDisplayField = (props: Omit<RadiosFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const displayCampaignOptions = useTranslateOptions(DISPLAY_CAMPAIGN_OPTIONS);

  return (
    <RadiosField<InfluencerFormKeys>
      help={t('Tooltip.DisplayCampaignHelp')}
      options={displayCampaignOptions}
      title={t('TextForm.Display Campaign')}
      name={InfluencerSharedKeys.campaignDisplay}
      {...props}
    />
  );
};
