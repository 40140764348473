import { CampaignType } from '@/graphql';
import { ReportFilterFormValues } from '../ReportFilter';
import { ReportColumnKeys } from './types';

export const initialValuesReport: Partial<ReportColumnKeys>[] = [
  'cost',
  'view',
  'like',
  'share',
  'comment',
  'followers',
  'engagement',
  'engagementRate'
];

// based on https://docs.google.com/spreadsheets/d/1lgx393NppgpNa90ACUgcNNLtlwXVNj_CqdhA-9-f7N4/edit#gid=301943529
export const defaultSelectedColumns: Partial<ReportColumnKeys>[] = [
  'cost',
  'view',
  'like',
  'reach',
  'share',
  'sales',
  'comment',
  'followers',
  'conversion',
  'engagement',
  'impressions',
  'costsPerSale',
  'engagementRate',
  'costsPerFollower',
  'costsPerEngagement'
];

export const getReportQueryVariables = (filter: ReportFilterFormValues) => ({
  ...filter,
  campaignCategoryIds: filter.campaignCategoryIds.map(Number),
  campaigns: filter.campaigns.map((campaign) => {
    const [campaignType, id] = campaign.split('/');

    return {
      id: Number(id),
      type: campaignType === 'marketplace' ? CampaignType.MARKETPLACE : CampaignType.ENGAGEMENT
    };
  })
});
