import { usePermissions } from '../hooks';

const defaultPermissions = {
  hideAddAgencyBtn: false,
  hideEditAgencyBtn: false,
  hideDeleteAgencyBtn: false
};
export const useAgencyPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.agency?.noView || permissions?.agency?.view || permissions?.agency === null) {
    return {
      hideAddAgencyBtn: true,
      hideEditAgencyBtn: true,
      hideDeleteAgencyBtn: true
    };
  } else if (permissions?.agency?.manage) {
    return { ...defaultPermissions, hideDeleteAgencyBtn: true };
  }

  return defaultPermissions;
};
