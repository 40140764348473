import { useTranslation } from 'react-i18next';
import { formatPercent, formatPrice } from '@/shared/utils';
import { CampaignSocialMediaType, Marketplace } from '@/graphql';

interface ItemProps {
  label: string;
  rate?: boolean;
  currency?: string;
  value?: number | null;
}

const Item = ({ value, label, rate, currency = '' }: ItemProps) => (
  <div css={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
    <span css={{ fontSize: '13px' }}>{rate ? formatPercent(value) : formatPrice(value, currency)}</span>
    <span css={{ fontSize: '8px', marginLeft: '6px' }}>{`${!rate ? currency : ''} / ${label}`}</span>
  </div>
);

export interface CostsProps
  extends Pick<
    Marketplace,
    | 'socialMedia'
    | 'costPerPost'
    | 'costPerLike'
    | 'costPerView'
    | 'costPerOrder'
    | 'costPerClick'
    | 'costPerShare'
    | 'costPerAction'
    | 'costPerComment'
    | 'costPerFollower'
    | 'commissionRate'
  > {
  currency: string;
  className?: string;
}

export const Costs = ({
  currency,
  className,
  socialMedia,
  costPerPost,
  costPerLike,
  costPerView,
  costPerOrder,
  costPerClick,
  costPerShare,
  costPerAction,
  costPerComment,
  costPerFollower,
  commissionRate
}: CostsProps) => {
  const { t } = useTranslation();

  const items: ItemProps[] = [
    { label: t('Post'), value: costPerPost },
    { label: t(socialMedia === CampaignSocialMediaType.FACEBOOK ? 'Reaction' : 'Like'), value: costPerLike },
    { label: t('View'), value: costPerView },
    { label: t('Share'), value: costPerShare },
    { label: t('Comment'), value: costPerComment },
    { label: t('Follower'), value: costPerFollower },
    { label: t('Click'), value: costPerClick },
    { label: t('Action'), value: costPerAction },
    { label: t('Order'), value: costPerOrder },
    { label: t('Commission'), value: commissionRate, rate: true }
  ]
    .filter((i) => i.value)
    .splice(0, 2);

  return (
    <div className={className}>
      {items.map((item, index) => (
        <Item key={index} currency={currency} {...item} />
      ))}
    </div>
  );
};
