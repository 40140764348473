import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateUserProposalSocialAccountsBudget.graphql';
import { Mutation, MutationupdateUserProposalSocialAccountsBudgetArgs } from '../../__generated__/globalTypes';

export const useUpdateUserProposalSocialAccountsBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateUserProposalSocialAccountsBudgetArgs>
) => {
  const [callUpdateUserProposalSocialAccountsBudget, result] = useMutation<
    Mutation,
    MutationupdateUserProposalSocialAccountsBudgetArgs
  >(MUTATION, options);

  return { callUpdateUserProposalSocialAccountsBudget, ...result };
};

export type UpdateUserProposalSocialAccountsBudgetMutationFunction = Mutation['updateUserProposalSocialAccountsBudget'];
