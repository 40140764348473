import { css } from '@emotion/react';
import { useMatchRoute } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import {
  ExportIGPresentationType,
  AnalyticsAuthSocialAccountType,
  ExportYoutubeAccountAnalyticsType,
  useExportYoutubeDashboardAccountAnalyticsMutation,
  useExportInstagramAccountDashboardAnalyticsMutation
} from '@/graphql';
import { Button, ButtonDropdown } from '@/shared/atoms';
import { useDownloadFile, useQueryHelper } from '@/shared/hooks';
import { DownloadFileType, DropdownOption, PeriodType } from '@/shared/types';
import { getUploadApiUrl, getAccountManagementApiUrl } from '@/shared/utils';
import { AnalyticsSelectedAccountInfo } from '../../types';

interface DownloadAnalyticsAccountsButtonProps {
  filter: PeriodType;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const DownloadAnalyticsAccountsButton = ({
  filter,
  analyticsAccount: { id, socialAccountType: socialMedia }
}: DownloadAnalyticsAccountsButtonProps) => {
  const { isAdminStaff } = useUserRoles();
  const matchRoute = useMatchRoute();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const isUGCTab = matchRoute({ to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc' });
  const { callExportInstagramAccountDashboardAnalytics, loading: downloadingIgReport } =
    useExportInstagramAccountDashboardAnalyticsMutation();
  const { callExportYoutubeDashboardAccountAnalytics, loading: downloadingYTReport } =
    useExportYoutubeDashboardAccountAnalyticsMutation();

  const isYoutube = socialMedia === AnalyticsAuthSocialAccountType.YOUTUBE;
  const isTwitter = socialMedia === AnalyticsAuthSocialAccountType.TWITTER;
  const isInstagram = socialMedia === AnalyticsAuthSocialAccountType.INSTAGRAM;
  const downloadOptions: DropdownOption<DownloadFileType>[] = [
    ...(isAdminStaff ? [{ label: t<string>('Google Slide'), value: DownloadFileType.GOOGLE_SLIDE }] : []),
    { label: t<string>('Powerpoint'), value: DownloadFileType.PPTX },
    ...(isInstagram ? [{ label: t<string>('CSV'), value: DownloadFileType.CSV }] : [])
  ];

  const handleDownloadIgAndYtReport = async ({ value }: DropdownOption<DownloadFileType>) => {
    if (!id || !value) return;

    try {
      switch (value) {
        case DownloadFileType.CSV:
          if (isInstagram) {
            await handleDownloadFile({
              params: filter,
              extension: '.csv',
              filenamePrefix: 'analytics',
              url: getUploadApiUrl(`analytics/presentation/csv/${id}`)
            });
          }
          break;
        default:
          if (isInstagram) {
            await callExportInstagramAccountDashboardAnalytics({
              variables: {
                input: {
                  ...filter,
                  instagramAccountId: id,
                  type: value as unknown as ExportIGPresentationType
                }
              }
            });
          } else if (isYoutube) {
            await callExportYoutubeDashboardAccountAnalytics({
              variables: {
                input: {
                  ...filter,
                  youtubeAccountId: id,
                  type: value as unknown as ExportYoutubeAccountAnalyticsType
                }
              }
            });
          }
          enqueueSnackbar(t('Analytics account report has been sent to your email'), { variant: 'success' });
          break;
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return isUGCTab ? null : isInstagram || isYoutube ? (
    <ButtonDropdown
      variant="white"
      css={styles.exportButton}
      options={downloadOptions}
      title={t('Button.Export')}
      onSelectOption={handleDownloadIgAndYtReport}
      disabled={downloadingIgReport || downloadingYTReport || downloading}
    />
  ) : isTwitter ? (
    <Button
      variant="white"
      disabled={downloading}
      css={styles.exportButton}
      title={t('Button.Export')}
      onClick={() => {
        handleDownloadFile({
          params: filter,
          extension: '.csv',
          filenamePrefix: 'analytics',
          url: getAccountManagementApiUrl(`api/analytics/presentation/twitter/csv/${id}`)
        });
      }}
    />
  ) : (
    // Facebook page
    // Currently we haven't support download FB yet, just show disabled button
    <Button variant="white" css={styles.exportButton} title={t('Button.Export')} disabled />
  );
};
const styles = {
  exportButton: css({ minWidth: '108px', textTransform: 'uppercase' })
};
