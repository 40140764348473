import Lottie from 'react-lottie';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { starsImageAnimation } from '@/shared/assets';
import { ChatDemoLink } from '../ChatDemoLink';
import { ChatDemoMessageType } from './types';
import { ChatDemoSkeleton } from './ChatDemoSkeleton';
import { ChatDemoMarkdownReader } from './ChatDemoMarkdownReader';

export interface ChatDemoMessageProps {
  className?: string;
  message?: ChatDemoMessageType | null;
}
export const ChatDemoMessage = ({ message, className }: ChatDemoMessageProps) => {
  const isLeft = message?.position === 'left';

  return message ? (
    <div css={[styles.wrapper, message.typing && { width: '100%' }]} className={className}>
      <div css={[styles.text(isLeft), message.searchLink ? { backgroundColor: 'transparent', padding: 0 } : undefined]}>
        {message.searchLink ? (
          <ChatDemoLink {...message.searchLink} />
        ) : isLeft ? (
          <>
            <div css={[styles.typing, { height: message.typing ? '40px' : 0 }]}>
              <Lottie
                width={24}
                height={24}
                style={{ margin: 'unset' }}
                options={{ loop: true, autoplay: true, animationData: starsImageAnimation }}
              />
              <p>検索しています...</p>
            </div>

            {message.typing ? <ChatDemoSkeleton /> : <ChatDemoMarkdownReader value={message.text} />}
          </>
        ) : (
          <ChatDemoMarkdownReader value={message.text} />
        )}
      </div>
    </div>
  ) : null;
};

const styles = {
  wrapper: css({
    width: '100%',
    boxSizing: 'border-box',
    alignSelf: 'flex-start',
    fontFamily: 'Hiragino Sans, Inter, sans-serif'
  }),
  text: (isLeft: boolean) =>
    css({
      // this var also used for child components
      '--textColor': '#394959',

      fontSize: '16px',
      lineHeight: '2.0em',
      borderRadius: '14px',
      whiteSpace: 'pre-line',
      boxSizing: 'border-box',
      color: 'var(--textColor)',
      padding: isLeft ? '24px' : 0,
      backgroundColor: isLeft ? THEME.background.colors.white : 'transparent'
    }),
  typing: css({
    gap: '4px',
    display: 'flex',
    overflow: 'hidden',
    boxSizing: 'border-box',
    transition: 'height 0.23s linear'
  })
};
