import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';

interface TableTitleProps {
  title: string;
  className?: string;
}

export const TableTitle = ({ title, className }: TableTitleProps) => (
  <div css={styles.wrapper} className={className}>
    {title}
  </div>
);

const styles = {
  wrapper: css({
    fontWeight: 600,
    height: '48px',
    display: 'flex',
    fontSize: '16px',
    lineHeight: '16px',
    padding: '8px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    color: THEME.text.colors.black.lv1,
    backgroundColor: THEME.background.colors.white
  })
};
