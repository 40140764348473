import { FetchResult } from '@apollo/client';
import { useState } from 'react';
import { Mutation } from '@/graphql';
import { useQueryHelper, useToggleState } from '@/shared/hooks';

interface DeleteItem {
  name?: string;
  id: number | string;
}

export const useDeleteMutation = <T extends DeleteItem>(item: T | null = null) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [deletingItem, setDeletingItem] = useState<T | null>(item);
  const { status: deleting, toggleStatus: toggleDeleting } = useToggleState();
  const { status: isOpenConfirmModal, toggleStatus: toggleConfirmModal } = useToggleState();

  const handleDeleteItem = async (mutation: () => Promise<FetchResult<Mutation>>) => {
    if (!deletingItem) {
      return;
    }

    try {
      toggleDeleting();
      await mutation();

      enqueueSnackbar(t('succeededInDeleting', { name: deletingItem.name }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: deletingItem.name }), { variant: 'error' });
    } finally {
      toggleDeleting();
      toggleConfirmModal();
    }
  };

  return {
    deleting,
    deletingItem,
    isOpenConfirmModal,
    setDeletingItem,
    handleDeleteItem,
    toggleConfirmModal
  };
};
