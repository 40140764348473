import { CSV_EXPORT_TYPE, DownloadCsvPagesAndRowsModalValues } from './types';

export const getDownloadCSVRangeRows = (values: DownloadCsvPagesAndRowsModalValues, itemsOnThePage: number) => ({
  firstRow:
    values.exportType === CSV_EXPORT_TYPE.PAGES
      ? values.firstPage === '1'
        ? 1
        : (+values.firstPage * values.limit - itemsOnThePage)
      : values.firstRow,
  lastRow: values.exportType === CSV_EXPORT_TYPE.PAGES ? +values.lastPage * values.limit : values.lastRow
});
