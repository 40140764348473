import { ReactNode } from 'react';

import {
  SocialAccountType,
  InfluencerPostSection,
  InfluencerNewProfileHeaderV2,
  useInfluencerProfilePostsQuery
} from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';

import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerProfilePostsWrapperProps {
  className?: string;
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
  children: (props: { data?: InfluencerPostSection }) => ReactNode;
}

export const InfluencerProfilePostsWrapper = ({
  children,
  className,
  socialAccount,
  influencerProfile
}: InfluencerProfilePostsWrapperProps) => {
  const { data, loading } = useInfluencerProfilePostsQuery({
    variables: {
      pk: influencerProfile.id,
      socialAccountId: socialAccount.id,
      socialMedia: socialAccount.type as SocialAccountType
    }
  });

  return (
    <div css={{ marginTop: '16px' }} className={className}>
      <RenderDataWithFallback loading={loading}>
        {children({ data: data?.influencerProfilePosts || undefined })}
      </RenderDataWithFallback>
    </div>
  );
};
