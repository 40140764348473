import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { SocialButton } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar } from '@/shared/molecules';
import { SupportedSocialMediaType } from '@/shared/types';
import { formatIntNumber } from '@/shared/utils';

export interface ConnectableAccountType {
  id: string;
  name: string;
  avatar: string;
  isUsed?: boolean;
  friends?: number;
  followers?: number;
  isSelected?: boolean;
  socialMedia?: keyof typeof SupportedSocialMediaType;
}

interface ConnectableAccountProps {
  account: ConnectableAccountType;
  socialMedia?: keyof typeof SupportedSocialMediaType;
}

export const ConnectableAccount = ({ account, socialMedia }: ConnectableAccountProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div css={{ flex: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {socialMedia ? (
          <SocialButton css={styles.icon} socialMedia={socialMedia} iconProps={{ size: '24' }} hasIcon />
        ) : null}
        <InfluencerAvatar size="42px" src={account.avatar} css={styles.avatar} />
      </div>

      <div css={{ flex: 1, marginLeft: '8px', color: THEME.text.colors.black.lv1 }}>
        <p css={{ lineHeight: 1, fontSize: '14px' }}>{account.name}</p>
        <p css={styles.label}>
          <span>{formatIntNumber(account.followers ?? account.friends)}</span>&nbsp;
          <span>{t(typeof account.friends === 'number' ? 'Friends' : 'Followers')}</span>
        </p>
      </div>
    </div>
  );
};

const styles = {
  icon: css({
    padding: 0,
    width: '42px',
    height: '42px',
    borderRadius: '50%',
    justifyContent: 'center'
  }),
  avatar: css({
    width: '48px',
    height: '48px',
    marginLeft: '-8px',
    borderRadius: '50%',
    border: `3px solid ${THEME.border.colors.white}`
  }),
  label: css({
    marginTop: '5px',
    fontSize: '12px',
    lineHeight: 1.17,
    letterSpacing: 'normal',
    color: THEME.text.colors.gray.lv3
  })
};
