import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateGeminiThread.graphql';
import { Mutation } from '../../__generated__/globalTypes';

export const useCreateGeminiThreadMutation = (options?: MutationHookOptions<Mutation>) => {
  const [callCreateGeminiThread, result] = useMutation<Mutation>(MUTATION, options);

  return { callCreateGeminiThread, ...result };
};

export type CreateGeminiThreadMutationFunction = Mutation['createGeminiThread'];
