import { Outlet, redirect } from '@tanstack/react-router';
import { router, RouterIds } from '@/router';
import { objectEntries } from '@/shared/assets';
import { checkSignedIn } from '@/shared/utils';
import { VerifyTokenAsync } from '../components/VerifyToken';
import { ChatDemoProvider } from '../pages/ChatDemo';
import { NotFound } from '../pages/Public';
import { RouterMapType } from './types';
import { PrivateRouter } from './_private-routes';
import { PublicRoutes } from './_public-routes';

const routerMap = {
  __root__: [
    () => (
      <VerifyTokenAsync>
        <ChatDemoProvider>
          <Outlet />
          {/* causes crash of HMR not sure why yet */}
          {/* <TanStackRouterDevtools position="bottom-right" /> */}
        </ChatDemoProvider>
      </VerifyTokenAsync>
    ),
    {}
  ],
  '/': [
    () => <Outlet />,
    {
      beforeLoad: ({ context, location }) => {
        const isSignedIn = checkSignedIn();

        if (isSignedIn) {
          throw redirect({ to: context.defaultRoute || '/report' });
        }
        if (!isSignedIn) {
          throw redirect({ to: '/sign-in' });
        }
      }
    }
  ],
  /** @private */
  ...PrivateRouter,
  /** @public */
  ...PublicRoutes,
  '/not-found': [NotFound, {}]
} as const satisfies RouterMapType<RouterIds>;

objectEntries(routerMap).forEach(([path, [component, options]]) => {
  const foundRoute = router.routesById[path as RouterIds];

  foundRoute.update({
    component: component
  });
  if ('validateSearch' in options) {
    foundRoute.options.validateSearch = options.validateSearch;
  }
  if ('beforeLoad' in options) {
    foundRoute.options.beforeLoad = options.beforeLoad;
  }
  if ('loader' in options) {
    foundRoute.options.loader = options.loader;
  }
  if ('loaderDeps' in options) {
    foundRoute.options.loaderDeps = options.loaderDeps;
  }
  if ('params' in options) {
    foundRoute.options.params = options.params;
  }
});
