import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EngagementDetail } from '@/graphql';
import { Table } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useSelectItems } from '@/shared/hooks';
import { TableTitle } from '../../shared';
import { UseEngagementProposalTable } from '../hooks';
import { getInitialRowsData } from '../utils';
import { ProposalDerivedProps } from '../../shared/types';
import { RejectInfluencersActionBar } from './RejectInfluencersActionBar';

interface RejectedInfluencersTableProps
  extends Pick<ProposalDerivedProps, 'jdMode' | 'rejectedInfluencers' | 'formattedRejectedInfluencers'> {
  engagement: EngagementDetail;
  hasTrackingReferral?: boolean;
  hasInfluencerCostPermission?: boolean;
}

export const RejectedInfluencersTable = ({
  jdMode,
  engagement,
  rejectedInfluencers,
  hasTrackingReferral,
  formattedRejectedInfluencers,
  hasInfluencerCostPermission
}: RejectedInfluencersTableProps) => {
  const { t } = useTranslation();
  const {
    allItemIds: allInfluencerIds,
    selectedItemIds: selectedInfluencerIds,
    hasAllItemsSelected: hasAllInfluencersSelected,
    selectItem: selectInfluencer,
    setSelectedItemIds: setSelectedInfluencerIds,
    toggleSelectAllItems: toggleSelectAllInfluencers
  } = useSelectItems({ listItems: formattedRejectedInfluencers, key: 'influencerId' });

  useEffect(() => {
    setSelectedInfluencerIds([]);
  }, [rejectedInfluencers.length]);

  const handleSelectAllIds = () => {
    setSelectedInfluencerIds(allInfluencerIds);
  };

  const { rows, columns } = UseEngagementProposalTable({
    jdMode,
    engagement,
    hasTrackingReferral,
    hasInfluencerCostPermission,
    onRowSelect: selectInfluencer,
    listSelectedIds: selectedInfluencerIds,
    rowsData: getInitialRowsData(rejectedInfluencers, jdMode),
    selectButtonProps: { onClick: handleSelectAllIds }
  });

  return (
    <div css={{ borderTop: THEME.border.base }}>
      <TableTitle title={t('Rejected Influencers')} />

      <div css={{ position: 'relative' }}>
        {selectedInfluencerIds.length > 0 ? (
          <RejectInfluencersActionBar
            engagement={engagement}
            onSelectAll={handleSelectAllIds}
            hasAllSelected={hasAllInfluencersSelected}
            onToggleSelect={toggleSelectAllInfluencers}
            selectedInfluencerIds={selectedInfluencerIds}
            numberSelected={selectedInfluencerIds.length}
          />
        ) : null}

        <Table
          noTopBorderRadius
          data={{ rows, columns, rowHeight: '62px' }}
          css={css({ td: { backgroundColor: `${THEME.table.row.background.colors.disabled} !important` } })}
          stickyHeader
        />
      </div>
    </div>
  );
};
