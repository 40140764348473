import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { InfluencerFollowerGrowthSection } from '@/graphql';
import {
  userPinkImage,
  pictureImage,
  smileYellowImage,
  websiteViewPurpleImage,
  bulbLightYellowImage
} from '@/shared/assets';
import { Label, MediaPreview } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatIntNumber, formatPercent } from '@/shared/utils';

interface InfluencerProfileOverviewSummaryProps {
  data: InfluencerFollowerGrowthSection;
}

export const InfluencerProfileXhsFollowerGrowth = ({
  data: { followersCount, postsCount, activeFollowersCount, readFansRate, engageFansRate }
}: InfluencerProfileOverviewSummaryProps) => {
  const { t } = useTranslation();

  const stats = [
    { label: t('Follower'), value: formatIntNumber(followersCount), image: userPinkImage },
    { label: t('Post'), value: formatIntNumber(postsCount), image: pictureImage },
    {
      label: t('Active Followers'),
      value: formatIntNumber(activeFollowersCount),
      image: smileYellowImage,
      helpText: t('Tooltip.activeFollowersHelp')
    },
    {
      label: t('Reading Followers'),
      value: formatPercent(readFansRate),
      image: websiteViewPurpleImage,
      helpText: t('Tooltip.readingFollowersHelp')
    },
    {
      label: t('Engaging Followers'),
      value: formatPercent(engageFansRate),
      image: bulbLightYellowImage,
      helpText: t('Tooltip.engagingFollowersHelp')
    }
  ];

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(183px, 1fr))'
      }}
    >
      {stats.map(({ label, image, value, helpText }, index) => (
        <div css={styles.item} key={index}>
          <div css={{}}>
            <Label
              title={label}
              help={helpText}
              iconMargin="2px"
              css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3 }}
            />
            <p css={{ fontWeight: 600, fontSize: '20px' }}>{value}</p>
          </div>
          <MediaPreview css={{ width: '32px', height: '32px', background: 'transparent' }} src={image} />
        </div>
      ))}
    </div>
  );
};

const styles = {
  item: css({
    padding: '24px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderWidth: '0 1px 1px 0',
    borderStyle: 'solid',
    borderColor: THEME.border.colors.gray.lv1,
    marginRight: '-1px'
  })
};
