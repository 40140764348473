import { FormProvider } from '@/shared/atoms';
import { FilterAction } from '@/shared/types';
import { PostReportFilterFormValues } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';

export type PostReportFilterProps = FilterAction<PostReportFilterFormValues> & Omit<TemplateProps, 'onSubmit'>;

export const PostReportFilter = ({ filter, setFilter, ...templateProps }: PostReportFilterProps) => (
  <FormProvider values={filter} onSubmit={setFilter}>
    <Template {...templateProps} />
  </FormProvider>
);
