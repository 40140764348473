import { QueryHookOptions } from '@apollo/client';

import { Query, QueryallBanksArgs, useAllBanksQuery } from '@/graphql';
import { Option } from '@/shared/types';
import { getOptions } from '@/shared/utils';

export const useAllBankOptions = (options?: QueryHookOptions<Query, QueryallBanksArgs>) => {
  const { data, loading } = useAllBanksQuery({ fetchPolicy: 'cache-first', ...options });

  const allBanks = data?.allBanks || [];

  return {
    loading,
    allBanks,
    allBankOptions: getOptions(allBanks),
    allBankNameOptions: allBanks.map<Option>((bank) => ({ label: bank?.name || '', value: bank?.name || '' }))
  };
};
