import { FacebookPageAnalyticsPostsPayload } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerFacebookAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useInfluencerFacebookPostsListTable } from './hooks';

export interface InfluencerFacebookPostsListProps extends TableData<FacebookPageAnalyticsPostsPayload> {
  influencerId?: number;
  mainSocialAccountId?: number;
}

export const InfluencerFacebookPostsList = ({
  loading,
  data = [],
  influencerId,
  mainSocialAccountId
}: InfluencerFacebookPostsListProps) => {
  const { rows, columns } = useInfluencerFacebookPostsListTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerFacebookAnalyticsPostDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), influencerId, mainSocialAccountId }))}
      />
    </>
  );
};
