import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ExportInstagramAccountDashboardAnalytics.graphql';
import { Mutation, MutationexportInstagramAccountDashboardAnalyticsArgs } from '../../__generated__/globalTypes';

export const useExportInstagramAccountDashboardAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationexportInstagramAccountDashboardAnalyticsArgs>
) => {
  const [callExportInstagramAccountDashboardAnalytics, result] = useMutation<
    Mutation,
    MutationexportInstagramAccountDashboardAnalyticsArgs
  >(MUTATION, options);

  return { callExportInstagramAccountDashboardAnalytics, ...result };
};

export type ExportInstagramAccountDashboardAnalyticsMutationFunction =
  Mutation['exportInstagramAccountDashboardAnalytics'];
