import { useTranslation } from 'react-i18next';
import { formatPrice } from '@/shared/utils';
import { ColorVariant } from '@/shared/types';
import { TiktokAdAccountInfo, TiktokAdAccountStatus } from '@/graphql';
import { ColumnProps, RowProps, Status, StatusProps, TableCell } from '@/shared/atoms';

interface Props {
  data: readonly TiktokAdAccountInfo[];
}

export const useAdvertiserAdAccountsTable = ({ data }: Props) => {
  const { t } = useTranslation();

  const getStatusColumnProps = (status: TiktokAdAccountStatus): StatusProps => {
    switch (status) {
      case TiktokAdAccountStatus.VERIFIED:
        return { label: t('Option.Verified'), variant: ColorVariant.LIGHT_GREEN };
      case TiktokAdAccountStatus.UNVERIFIED:
        return { label: t('Option.Unverified'), variant: ColorVariant.LIGHT_RED };
      case TiktokAdAccountStatus.DISCONNECT:
        return { label: t('Option.Disconnected'), variant: ColorVariant.LIGHT_GREY };
      default:
        return { label: '', variant: ColorVariant.LIGHT_GREY };
    }
  };

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Ad Account Name') },
    { title: t('HeaderColumn.Ad Account ID') },
    { title: t('HeaderColumn.Status') },
    { title: t('HeaderColumn.Balance') }
  ];
  const rows: RowProps[] = data.map(({ id, name, status, balanceAmount, currencyId }) => [
    <TableCell.Text value={name} />,
    <TableCell.Text value={id} />,
    <Status {...getStatusColumnProps(status)} />,
    <TableCell.Text
      value={
        <span>
          {formatPrice(balanceAmount, null, 0)}&nbsp;{currencyId}
        </span>
      }
    />
  ]);

  return { rows, columns };
};
