import { QueryHookOptions } from '@apollo/client';

import { Query, useAllCountriesQuery } from '@/graphql';
import { CountryOption } from '@/shared/types';
import { getOptions } from '@/shared/utils';

import { useTranslateCountryOptions } from './useTranslateOptions';

interface Props extends QueryHookOptions<Query> {
  hasAllCountryOption?: boolean;
  filterFunction?: (country: CountryOption) => boolean;
}

export const useAllCountryOptions = ({ hasAllCountryOption, filterFunction, ...options }: Props = {}) => {
  const { data, loading } = useAllCountriesQuery({ fetchPolicy: 'cache-first', ...options });
  const countries = getOptions(data?.allCountries);
  const allCountries = hasAllCountryOption ? [{ label: 'All', value: '' }, ...countries] : countries;

  return {
    loading,
    allCountryOptions: useTranslateCountryOptions(allCountries),
    filteredCountries: useTranslateCountryOptions(filterFunction ? allCountries.filter(filterFunction) : allCountries)
  };
};
