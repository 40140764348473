import { ReactNode, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Modal, Anchor, ModalProps, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { useDownloadFile } from '@/shared/hooks';
import { FileDrop, FileDropProps } from '@/shared/molecules';
import { DownloadFileProps } from '@/shared/utils';
import { MIME_ACCEPT } from '@/shared/constants';

export interface UploadCsvModal
  extends Pick<ModalProps, 'open' | 'onClose'>,
    Omit<FileDropProps, 'title' | 'generateSignedUrl'> {
  title?: ReactNode;
  uploading?: boolean;
  onFileUploaded?: () => void;
  downloadTranslationKey?: string;
  downloadFile?: DownloadFileProps;
  uploadFile?: { url: URL; fileNamePrefix: string };
  onCustomUpload?: (acceptedFile: File) => void | Promise<void>;
}

export const UploadCsvModal = ({
  open,
  title,
  onClose,
  uploading,
  uploadFile,
  notes = [],
  downloadFile,
  onCustomUpload,
  downloadTranslationKey = 'User can download CSV template and also upload it',
  ...restProps
}: UploadCsvModal) => {
  const { t } = useTranslation();
  const childRef = useRef<{ newFile: File; requestUpload: () => void }>(null);
  const { downloading, handleDownloadFile } = useDownloadFile();

  return (
    <Modal open={open} onClose={onClose} css={{ width: '560px' }}>
      <ModalContent>
        <ModalTitle>{title || t('Upload CSV')}</ModalTitle>
        <FileDrop
          notes={notes}
          ref={childRef}
          multiple={false}
          accept={{ ...MIME_ACCEPT.CSV }}
          uploadURL={uploadFile?.url?.toString()}
          uploadFileName={uploadFile?.fileNamePrefix}
          description={
            downloadTranslationKey && downloadFile ? (
              <Trans
                i18nKey={downloadTranslationKey}
                components={{
                  a: (
                    <Anchor
                      variant="blue"
                      css={{ display: 'inline' }}
                      onClick={(e) => {
                        e.preventDefault();

                        if (downloadFile) {
                          handleDownloadFile(downloadFile);
                        }
                      }}
                    />
                  )
                }}
              />
            ) : undefined
          }
          {...restProps}
        />
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{
          title: t('Button.Upload'),
          loading: downloading || uploading,
          onClick: async () => {
            if (childRef.current) {
              if (onCustomUpload && childRef.current?.newFile) {
                await onCustomUpload(childRef.current.newFile);
              } else {
                childRef.current.requestUpload();
              }
            }
            onClose?.();
          }
        }}
      />
    </Modal>
  );
};
