import { Navigate } from '@tanstack/react-router';
import { useInstagramCompareAccountsQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { useInitialAnalyticsPosts } from '../hooks';
import { TemplateProps, Template } from './Template';

export const AnalyticsInstagramComparePosts = () => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { goBackUrl, mainSocialAccountId, params } = useInitialAnalyticsPosts({
    socialMedia: 'instagram',
    analyticsProfileUrl: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare',
    document: (name) => ({
      alwaysBackToParent: true,
      appHeader: name ? 'appHeader.PostListWithAccountName' : 'appHeader.Post List',
      title: name ? 'documentTitle.PostListWithAccountName' : 'documentTitle.Post List'
    })
  });

  const { data, loading, called } = useInstagramCompareAccountsQuery({
    skip: !mainSocialAccountId,
    fetchPolicy: 'cache-and-network',
    variables: { instagramAnalyticsAccountId: Number(mainSocialAccountId) },
    onCompleted: ({ instagramCompareAccounts: { mainAccount, compareAccounts } }) => {
      if (!mainAccount && !compareAccounts.length) {
        enqueueSnackbar(t('Can not find comparable accounts information'), { variant: 'error' });
        navigate({ to: goBackUrl, params });
      }
    },
    onError: (err) => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
      navigate({ to: goBackUrl, params });
    }
  });

  const allAccounts = data?.instagramCompareAccounts;
  const comparableAccounts: TemplateProps['comparableAccounts'] = [
    ...(allAccounts?.mainAccount ? [allAccounts.mainAccount] : []),
    ...(allAccounts?.compareAccounts || [])
  ];

  if (called && !loading && !comparableAccounts.length) {
    return <Navigate to={goBackUrl} params={params} />;
  }

  return (
    <RenderDataWithFallback loading={loading} noDataNode={null}>
      {comparableAccounts?.length ? (
        <Template mainSocialAccountId={mainSocialAccountId} comparableAccounts={comparableAccounts} />
      ) : null}
    </RenderDataWithFallback>
  );
};
