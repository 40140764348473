import { useAllAgencyUsersQuery } from '@/graphql';
import { AccountUsersList } from '../../Accounts';

export interface AgencyUsersListProps {
  agencyId: number;
}

export const AgencyUsersList = ({ agencyId }: AgencyUsersListProps) => {
  const { data, loading } = useAllAgencyUsersQuery({ variables: { agencyId } });

  return (
    <AccountUsersList
      loading={loading}
      data={data?.allAgencyUsers || []}
      refetchQueriesAfterDelete={['AllAgencyUsers']}
    />
  );
};
