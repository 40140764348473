import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/InfluencerAddYoutubeCompareAccountAnalytics.graphql';
import { Mutation, MutationinfluencerAddYoutubeCompareAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useInfluencerAddYoutubeCompareAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationinfluencerAddYoutubeCompareAccountAnalyticsArgs>
) => {
  const [callInfluencerAddYoutubeCompareAccountAnalytics, result] = useMutation<
    Mutation,
    MutationinfluencerAddYoutubeCompareAccountAnalyticsArgs
  >(MUTATION, options);

  return { callInfluencerAddYoutubeCompareAccountAnalytics, ...result };
};

export type InfluencerAddYoutubeCompareAccountAnalyticsMutationFunction =
  Mutation['influencerAddYoutubeCompareAccountAnalytics'];
