import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useDeepCompareEffect, useQueryHelper } from '@/shared/hooks';
import { PricingTableItem, useCreateTiktokSpecialCampaignMutation } from '@/graphql';
import { defaultTikTokCampaignFormValues, getTikTokCampaignFormInput } from '@/shared/organisms';

interface Props {
  currentPlan?: PricingTableItem;
}

export const useAutoCreateTikTokCampaign = ({ currentPlan }: Props) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateTiktokSpecialCampaign, called } = useCreateTiktokSpecialCampaignMutation();

  // If has currentPlan => create campaign automatically => redirect to campaign details
  const hasCurrentPlan = !!currentPlan;

  useDeepCompareEffect(() => {
    if (hasCurrentPlan) {
      if (!called) {
        handleCreateCampaign();
      } else {
        goToCampaignsList();
      }
    }
  }, [hasCurrentPlan, called]);

  const goToCampaignsList = () => {
    navigate({ to: '/tiktok/campaigns' });
  };

  const handleCreateCampaign = async () => {
    const draftCampaignTitle = 'New Campaign'; // hardcode

    try {
      const { data } = await callCreateTiktokSpecialCampaign({
        variables: {
          input: getTikTokCampaignFormInput({
            ...defaultTikTokCampaignFormValues,
            isDraft: true,
            title: draftCampaignTitle
          })
        }
      });
      const createdCampaignId = data?.createTiktokSpecialCampaign?.id;

      if (createdCampaignId) {
        navigate({ to: '/tiktok/campaigns/$id', params: { id: createdCampaignId.toString() } });
      } else {
        enqueueSnackbar(t('succeededInCreating', { name: draftCampaignTitle }), { variant: 'success' });
        goToCampaignsList();
      }
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name: draftCampaignTitle }), { variant: 'error' });
      enqueueSnackbar(t(error || UNEXPECTED_ERROR), { variant: 'error' });
      goToCampaignsList();
    }
  };

  return { creatingCampaign: hasCurrentPlan && !called };
};
