import { useState } from 'react';
import { UploadButton, DownloadButton } from '@/shared/atoms';
import { ListWithPagination, UploadCsvModal } from '@/shared/molecules';
import { useDownloadFile, useToggleState } from '@/shared/hooks';
import { InstagramFeedPost, useAllInstagramFeedPostsQuery } from '@/graphql';
import { getOffset, getUploadApiUrl, getValuableVariables, convertOrderToORDER } from '@/shared/utils';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  AnalyticsInstagramPostsList,
  defaultSelectedAnalyticsInstagramPostsColumns,
  selectableAnalyticsInstagramPostsColumnsOptions,
  defaultSelectedAnalyticsInstagramComparePostsColumns
} from '@/shared/organisms';
import { useAnalyticsIgComparePostsFilter } from '../AnalyticsInstagramComparePosts/hooks';

export interface AnalyticsInstagramPostsTemplateProps {
  isComparePage?: boolean;
  mainSocialAccountId: number;
}

export const AnalyticsInstagramPostsTemplate = ({
  isComparePage,
  mainSocialAccountId
}: AnalyticsInstagramPostsTemplateProps) => {
  const uploadModal = useToggleState();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const [selectedColumns, setSelectedColumns] = useState<Array<keyof InstagramFeedPost>>(
    isComparePage ? defaultSelectedAnalyticsInstagramComparePostsColumns : defaultSelectedAnalyticsInstagramPostsColumns
  );
  const { page, limit, sort, filter, setFilter } = useAnalyticsIgComparePostsFilter();
  const { data, previousData, loading, refetch } = useAllInstagramFeedPostsQuery({
    skip: !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      accountId: Number(mainSocialAccountId),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      ...filter
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allInstagramFeedPostsCount?.totalNumber;
  const selectableColumns = selectableAnalyticsInstagramPostsColumnsOptions.map((item) => item.value);

  return (
    <>
      <ListWithPagination pagination={{ totalRecords }}>
        <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

        <ProfilePostsCount count={totalRecords}>
          <UploadButton onClick={uploadModal.open} css={{ marginRight: '8px' }} />
          <DownloadButton
            loading={downloading}
            onClick={() =>
              handleDownloadFile({
                params: getValuableVariables({
                  endDate: filter.endDate,
                  startDate: filter.startDate,
                  selectedColumns: selectedColumns.filter((column) => selectableColumns.includes(column))
                }),
                filenamePrefix: 'feedPosts',
                url: getUploadApiUrl(`analytics/feed_posts/${mainSocialAccountId}/csv/custom`)
              })
            }
          />
        </ProfilePostsCount>

        <AnalyticsInstagramPostsList
          loading={loading}
          isComparePage={isComparePage}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          data={result?.allInstagramFeedPosts || []}
        />
      </ListWithPagination>

      <UploadCsvModal
        onFileUploaded={refetch}
        open={uploadModal.status}
        onClose={uploadModal.close}
        uploadFile={{
          fileNamePrefix: 'feed_posts.csv',
          url: getUploadApiUrl(`analytics/feed_posts/${mainSocialAccountId}/csv`)
        }}
        downloadFile={{
          extension: '.csv',
          filenamePrefix: 'feed_posts',
          params: { startDate: filter.startDate, endDate: filter.endDate },
          url: getUploadApiUrl(`analytics/feed_posts/${mainSocialAccountId}/csv`)
        }}
      />
    </>
  );
};
