export enum InfluencerSharedKeys {
  id = 'id',
  name = 'name',
  email = 'email',
  status = 'status',
  gender = 'gender',
  regionId = 'regionId',
  countryId = 'countryId',
  introduce = 'introduce',
  partnerIds = 'partnerIds',
  dateOfBirth = 'dateOfBirth',
  phoneNumber = 'phoneNumber',
  categoryIds = 'categoryIds',
  internalMemo = 'internalMemo',
  isProAccount = 'isProAccount',
  brandAccount = 'brandAccount',
  talentAgencyIds = 'talentAgencyIds',
  campaignDisplay = 'campaignDisplay',
  notificationSetting = 'notificationSetting',
  talentAgencyOrPartnerIds = 'talentAgencyOrPartnerIds',
  defaultEngagementSelectionReason = 'defaultEngagementSelectionReason'
}
