import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ClearCmsChannelAssetOwner.graphql';
import { Mutation, MutationclearCmsChannelAssetOwnerArgs } from '../../__generated__/globalTypes';

export const useClearCmsChannelAssetOwnerMutation = (
  options?: MutationHookOptions<Mutation, MutationclearCmsChannelAssetOwnerArgs>
) => {
  const [callClearCmsChannelAssetOwner, result] = useMutation<Mutation, MutationclearCmsChannelAssetOwnerArgs>(
    MUTATION,
    options
  );

  return { callClearCmsChannelAssetOwner, ...result };
};

export type ClearCmsChannelAssetOwnerMutationFunction = Mutation['clearCmsChannelAssetOwner'];
