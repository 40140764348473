import { useState } from 'react';
import {
  TwitterAnalyticsPostsByDatePayload,
  useTwitterAnalyticsPostsByDatePromiseQuery,
  useInfluencerTwitterAnalyticsPostsByDatePromiseQuery
} from '@/graphql';
import { DAILY_POST_PARAM_KEY } from '@/shared/constants';
import { useFetchDailyPostsOnChart, usePostDetailsParam, useQueryHelper } from '@/shared/hooks';
import { AnalyticsOverviewChartProps } from '../../../../shared';
import { AnalyticsSelectedAccountInfo } from '../../../../types';
import { AnalyticsTwitterDashboardGraphKeys as Keys } from '../types';
import { legendColors } from '../utils';

interface Props {
  isInfluencerProfile?: boolean;
  accountId: AnalyticsSelectedAccountInfo['id'];
  chartData: AnalyticsOverviewChartProps<Keys>['data'];
}

export const useAnalyticsTwitterDashboardOverviewPosts = ({ chartData, accountId, isInfluencerProfile }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { setPostDetailsParam } = usePostDetailsParam(DAILY_POST_PARAM_KEY);
  const [dailyPosts, setDailyPosts] = useState<TwitterAnalyticsPostsByDatePayload | null>(null);
  const { getTwitterAnalyticsPostsByDate } = useTwitterAnalyticsPostsByDatePromiseQuery();
  const { getInfluencerTwitterAnalyticsPostsByDate } = useInfluencerTwitterAnalyticsPostsByDatePromiseQuery();

  const fetchDailyPosts = async (date: string) => {
    if (!date) return;

    try {
      const { data } = isInfluencerProfile
        ? await getInfluencerTwitterAnalyticsPostsByDate({ variables: { date, socialAccountId: accountId } })
        : await getTwitterAnalyticsPostsByDate({ variables: { date, twitterAnalyticsAccountId: accountId } });

      const postsInDate = isInfluencerProfile
        ? data?.influencerTwitterAnalyticsPostsByDate
        : data?.twitterAnalyticsPostsByDate;
      const posts = postsInDate.posts || [];

      if (posts.length) {
        setDailyPosts(postsInDate);
        setPostDetailsParam(posts.at(0)?.id);
      }
    } catch (error) {
      enqueueSnackbar(t('Can not get daily posts data, please try again later '), { variant: 'error' });
    }
  };

  const { customChartOptions } = useFetchDailyPostsOnChart({
    onFetchPosts: fetchDailyPosts,
    postsData: [chartData.post.data],
    postColors: [legendColors.post]
  });

  return { dailyPosts, customChartOptions };
};
