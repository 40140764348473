import { RenderDataWithFallback } from '@/shared/atoms';
import { useInstagramAnalyticsHashtagsQuery } from '@/graphql';
import { useAnalyticsInstagramHashtagsFilter } from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';
import { Template } from './Template';
import { hashtagsMock } from './hashtags.mock';

export const AnalyticsInstagramHashtags = () => {
  const {
    selectedSocialAccount,
    brandAccount: { subscriptionPlan }
  } = useAnalyticsProfileContext();
  const hasFreePlan = subscriptionPlan.availableFeatures?.hashtagsEnabled === false;

  const { filter, setFilter } = useAnalyticsInstagramHashtagsFilter(); // Init search params
  const { data, loading, called } = useInstagramAnalyticsHashtagsQuery({
    skip: !filter.date || hasFreePlan,
    variables: { accountId: selectedSocialAccount.id },
    onCompleted: ({ instagramAnalyticsHashtags }) => {
      const isValidHashtag =
        instagramAnalyticsHashtags?.hashTags?.findIndex((hashtag) => String(hashtag.id) === filter.hashtagId) !== -1;

      if (!filter.hashtagId || !isValidHashtag) {
        setFilter({ hashtagId: String(instagramAnalyticsHashtags?.hashTags?.at(0)?.id || '') }, { replace: true });
      }
    }
  });

  const hashtags = hasFreePlan ? hashtagsMock : data?.instagramAnalyticsHashtags?.hashTags || [];

  return (
    <RenderDataWithFallback loading={loading && !called} hasNoData={!filter.date}>
      <Template hashtags={hashtags} hasFreePlan={hasFreePlan} />
    </RenderDataWithFallback>
  );
};
