import { useEffect } from 'react';
import { ListWithPagination } from '@/shared/molecules';
import { RenderDataWithFallback } from '@/shared/atoms';
import {
  YoutubeCMSTabs,
  YoutubeCMSFilter,
  YoutubeCMSSummary,
  useYoutubeCMSFilter,
  YoutubeCMSAssetsList,
  YoutubeCMSDescription,
  YoutubeCMSRevenueType,
  YoutubeCMSChannelsList
} from '@/shared/organisms';
import { useYoutubeCMSContext } from '../YoutubeCMSContext';
import { useYoutubeCMSConfirmedData, useYoutubeCMSDocumentTitle } from '../hooks';

export const YoutubeCMSConfirmedRevenue = () => {
  useYoutubeCMSDocumentTitle();
  const { canModifyYoutubeCMSData, confirmedRevenueLatestMonth } = useYoutubeCMSContext();
  const { page, limit, filter, setFilter, isSegmentAssets, searchPlaceholder } = useYoutubeCMSFilter();
  const { summary, assetsData, channelsData, totalRecords, fetchingData, fetchingSummary } =
    useYoutubeCMSConfirmedData();

  useEffect(() => {
    // We will only reset the filter month if latest confirmed date older than the selecting date
    if (canModifyYoutubeCMSData) {
      setFilter({ ...filter, month: confirmedRevenueLatestMonth || filter.month });
    }
  }, [canModifyYoutubeCMSData, confirmedRevenueLatestMonth, filter.month]);

  const type = YoutubeCMSRevenueType.CONFIRMED_REVENUE;
  const defaultListProps = {
    type,
    page,
    limit,
    totalRecords,
    modifiable: false,
    loading: fetchingData
  };

  return (
    <>
      <YoutubeCMSDescription />

      <ListWithPagination pagination={{ totalRecords }}>
        <YoutubeCMSTabs />

        <YoutubeCMSFilter
          searchPlaceholder={searchPlaceholder}
          disabledMonths={confirmedRevenueLatestMonth ? { after: new Date(confirmedRevenueLatestMonth) } : undefined}
        />

        <RenderDataWithFallback loading={fetchingSummary}>
          <YoutubeCMSSummary
            type={type}
            data={summary}
            segment={filter.segment || undefined}
            onSegmentChange={(segment) => setFilter({ ...filter, segment })}
          />

          {isSegmentAssets ? (
            <YoutubeCMSAssetsList {...defaultListProps} data={assetsData} />
          ) : (
            <YoutubeCMSChannelsList {...defaultListProps} data={channelsData} />
          )}
        </RenderDataWithFallback>
      </ListWithPagination>
    </>
  );
};
