import {
  ProposalJobDescriptionBreakDownMode,
  UserProposalSocialAcccountBudgetInput,
  namedOperations,
  useUpdateUserProposalInfluencersBudgetMutation,
  useUpdateUserProposalSocialAccountsBudgetMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { RowData } from '../schemaTypes';

interface SaveProposalProps {
  hasRowChange: boolean;
  rowValues: RowData[];
  jdMode?: ProposalJobDescriptionBreakDownMode;
  resetEditingRowsData: () => void;
}
export const useSaveProposal = ({ hasRowChange, rowValues, jdMode, resetEditingRowsData }: SaveProposalProps) => {
  const { callUpdateUserProposalInfluencersBudget, loading: updatingInfluencersBudget } =
    useUpdateUserProposalInfluencersBudgetMutation({ refetchQueries: [namedOperations.Query.UserProposal] });
  const { callUpdateUserProposalSocialAccountsBudget, loading: updatingAccountsBudget } =
    useUpdateUserProposalSocialAccountsBudgetMutation({
      refetchQueries: [namedOperations.Query.UserProposal]
    });
  const { t, enqueueSnackbar } = useQueryHelper();
  const handleSaveProposal = async () => {
    if (!hasRowChange) {
      return;
    }

    try {
      if (jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN) {
        await callUpdateUserProposalInfluencersBudget({
          variables: {
            input: {
              influencers:
                rowValues?.map(({ influencer, stats }) => ({
                  profit: stats.profit,
                  budget: stats.budget,
                  influencerId: influencer.id,
                  influencerCost: stats.influencerCost
                })) || []
            }
          }
        });
      } else {
        await callUpdateUserProposalSocialAccountsBudget({
          variables: {
            input: {
              socialAccounts: rowValues.reduce<UserProposalSocialAcccountBudgetInput[]>(
                (results, { stats, influencer, socialAccount }) => {
                  if (socialAccount) {
                    return [
                      ...results,
                      {
                        profit: stats.profit,
                        budget: stats.budget,
                        influencerId: influencer.id,
                        socialAccountId: socialAccount.id,
                        influencerCost: stats.influencerCost,
                        socialAccountMedia: socialAccount.socialMedia
                      }
                    ];
                  }

                  return results;
                },
                []
              )
            }
          }
        });
      }

      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
      resetEditingRowsData();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return {
    handleSaveProposal,
    updatingInfluencersBudget,
    updatingAccountsBudget
  };
};
