import { useTranslation } from 'react-i18next';
import { useEngagementPermissions, useMarketplacePermissions } from '@/auth';
import { useInfluencerCampaignSummaryQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { CampaignsListSummary } from '../../shared';

export interface InfluencerProfileCampaignsSummaryProps {
  influencerId: number;
  className?: string;
}
export const InfluencerProfileCampaignsSummary = ({
  influencerId,
  className
}: InfluencerProfileCampaignsSummaryProps) => {
  const { t } = useTranslation();
  const { hideEngagementReportLink } = useEngagementPermissions();
  const { hideMarketplaceReportLink } = useMarketplacePermissions();
  const { data, loading } = useInfluencerCampaignSummaryQuery({
    fetchPolicy: 'network-only',
    variables: { pk: influencerId }
  });

  const summaries = data?.influencerCampaignSummary;

  return (
    <RenderDataWithFallback
      noDataNode={null}
      loading={loading}
      hasNoData={
        !summaries?.engagementJoinedCount && !summaries?.marketplaceJoinedCount && !summaries?.autoManagedJoinedCount
      }
    >
      <CampaignsListSummary
        hideMetrics={{
          engagement: hideEngagementReportLink,
          marketplace: hideMarketplaceReportLink
        }}
        summaries={[
          {
            name: t('Joined Campaigns'),
            engagementValue: summaries?.engagementJoinedCount,
            marketplaceValue: summaries?.marketplaceJoinedCount,
            autoManagedValue: summaries?.autoManagedJoinedCount
          },
          {
            name: t('Average Cost/Commission'),
            engagementValue: summaries?.engagementAvgCost,
            marketplaceValue: summaries?.marketplaceAvgCost,
            autoManagedValue: summaries?.autoManagedAvgCost,
            unit: summaries?.currency
          },
          { name: t('Average Reach'), value: summaries?.avgReach },
          { name: t('Average Like'), value: summaries?.avgLikes },
          { name: t('Average Comment'), value: summaries?.avgComments },
          { name: t('Average Engagement'), value: summaries?.avgEngagement }
        ]}
        className={className}
      />
    </RenderDataWithFallback>
  );
};
