import { FilterProvider } from '@/shared/molecules';
import { useCampaignInfluencersContext } from '../CampaignInfluencersContext';
import { BasicFilter } from './BasicFilter';

export const CampaignInfluencersFilter = () => {
  const { filter, setFilter, isEngagementPage } = useCampaignInfluencersContext();

  return (
    <FilterProvider
      onSubmit={setFilter}
      initialValues={filter}
      basicFilter={<BasicFilter isEngagementPage={isEngagementPage} />}
    />
  );
};
