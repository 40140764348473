import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateMarketplacePostStatusV2.graphql';
import { Mutation, MutationupdateMarketplacePostStatusV2Args } from '../../__generated__/globalTypes';

export const useUpdateMarketplacePostStatusV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationupdateMarketplacePostStatusV2Args>
) => {
  const [callUpdateMarketplacePostStatusV2, result] = useMutation<Mutation, MutationupdateMarketplacePostStatusV2Args>(
    MUTATION,
    options
  );

  return { callUpdateMarketplacePostStatusV2, ...result };
};

export type UpdateMarketplacePostStatusV2MutationFunction = Mutation['updateMarketplacePostStatusV2'];
