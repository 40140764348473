import {
  SocialAccountType,
  useDeleteEngagementSocialAccountProposalMutation,
  useUpdateEngagementSocialAccountProposalListMutation
} from '@/graphql';
import {
  NOT_ENOUGH_REFERRAL_CODE,
  CAN_NOT_ADD_MORE_TTCM_ACCOUNT,
  CAN_NOT_ADD_TIKTOK_ACCOUNT_WITH_LOW_FOLLOWERS
} from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { useEngagementDetailsContext } from '../../EngagementDetailsContext';

interface ToggleSmSelectedInluencerType {
  name?: string;
  isChecked?: boolean;
  influencerIds: number[];
  socialAccountIds: number[];
  socialAccountMedia: SocialAccountType;
}

export const useToggleSelectedSocialInfluencers = () => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { engagementId } = useEngagementDetailsContext();
  const { callUpdateEngagementSocialAccountProposalList, loading: isUpdatingSocialAccountProposal } =
    useUpdateEngagementSocialAccountProposalListMutation({
      onCompleted: invalidateRouteLoader
    });
  const { callDeleteEngagementSocialAccountProposal, loading: isDeletingSocialAccountProposal } =
    useDeleteEngagementSocialAccountProposalMutation({
      onCompleted: invalidateRouteLoader
    });

  const toggleSocialMediaSelectedInfluencer = async ({
    influencerIds,
    name,
    isChecked,
    socialAccountIds,
    socialAccountMedia
  }: ToggleSmSelectedInluencerType) => {
    let error;
    const variables = {
      input: {
        influencerIds,
        socialAccountIds,
        socialAccountMedia,
        campaignId: engagementId
      }
    };
    try {
      if (isChecked) {
        await callDeleteEngagementSocialAccountProposal({ variables });
      } else {
        await callUpdateEngagementSocialAccountProposalList({ variables });
      }
    } catch (err) {
      error = err.message;

      switch (error) {
        case NOT_ENOUGH_REFERRAL_CODE:
          break;
        case CAN_NOT_ADD_MORE_TTCM_ACCOUNT:
          enqueueSnackbar(t('You can select one TikTok account for each influencer for TTCM campaign'), {
            variant: 'error'
          });
          break;
        case CAN_NOT_ADD_TIKTOK_ACCOUNT_WITH_LOW_FOLLOWERS:
          enqueueSnackbar(t('Cannot add tiktok account with low followers count'), { variant: 'error' });
          break;
        default:
          if (name) {
            // we only notify user on each save/delete, will ignore if save/delete multiple
            const errorMsg = name ? t('failedToUpdate', { name }) : t('failedToSave');
            enqueueSnackbar(errorMsg, { variant: 'error' });
          } else {
            enqueueSnackbar(t(err.message), { variant: 'error' });
          }
          break;
      }
    }

    return { error };
  };

  return {
    toggleSocialMediaSelectedInfluencer,
    loading: isUpdatingSocialAccountProposal || isDeletingSocialAccountProposal
  };
};
