import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateAutoManagedCampaignsStatus.graphql';
import { Mutation, MutationupdateAutoManagedCampaignsStatusArgs } from '../../__generated__/globalTypes';

export const useUpdateAutoManagedCampaignsStatusMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateAutoManagedCampaignsStatusArgs>
) => {
  const [callUpdateAutoManagedCampaignsStatus, result] = useMutation<
    Mutation,
    MutationupdateAutoManagedCampaignsStatusArgs
  >(MUTATION, options);

  return { callUpdateAutoManagedCampaignsStatus, ...result };
};

export type UpdateAutoManagedCampaignsStatusMutationFunction = Mutation['updateAutoManagedCampaignsStatus'];
