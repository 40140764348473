import { CampaignSocialMediaType } from '@/graphql';
import { useTranslateOptions } from '@/shared/hooks';
import { Option } from '@/shared/types';
import { getSocialMediaText } from '@/shared/utils';

export const useMarketplacePromotionMethodOptions = () => {
  const socialOptions: Option<CampaignSocialMediaType>[] = [
    CampaignSocialMediaType.FACEBOOK,
    CampaignSocialMediaType.INSTAGRAM,
    CampaignSocialMediaType.YOUTUBE,
    CampaignSocialMediaType.INSTAGRAM_STORY,
    CampaignSocialMediaType.TWITTER,
    CampaignSocialMediaType.TIKTOK
  ].map((value) => ({ value, label: getSocialMediaText(value) }));

  const promotionMethodOptions: Option<CampaignSocialMediaType>[] = useTranslateOptions([
    { label: 'Select all', value: CampaignSocialMediaType.UNSELECT },
    ...socialOptions,
    { label: 'Others(Website, Offline)', value: CampaignSocialMediaType.OTHER }
  ]);

  return { promotionMethodOptions };
};
