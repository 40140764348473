import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { Logo } from '@/shared/molecules';
import { LanguageSelector, MenuItem } from '@/shared/organisms';
import { Anchor, CrowdinButton, Icon } from '@/shared/atoms';
import { MenuTitle } from '@/router';
import { useGetHelpPageUrl } from '@/shared/hooks';
import { getStepStyles, StepType, useTiktokSignupSteps } from './useSignupSteps';

export const TikTokLayoutMenu = () => {
  const { stepOne, stepTwo, stepThree } = useTiktokSignupSteps();
  const { t } = useTranslation();
  const helpCenterUrl = useGetHelpPageUrl();

  return (
    <div css={styles.wrapper}>
      <div css={styles.navigation}>
        <Logo type="black" to="/" css={{ width: '137px', height: '40px' }} />

        <div css={styles.stepsContainer}>
          {/* step 1 */}
          <Anchor to={stepOne.href} css={styles.stepLink(stepOne)}>
            <span css={[styles.circleBase, getStepStyles(stepOne)]} />
            <div>
              <h3 css={{ fontWeight: 'bold', marginBottom: '4px' }}>{t('Account Registration')}</h3>
              <p>{t('Create your TikTok Account')}</p>
            </div>
          </Anchor>
          <div css={[styles.verticalLine, { height: '34px', top: 38 }]} />
          {/* step 2 */}
          <Anchor to={stepTwo.href} css={styles.stepLink(stepTwo)}>
            <span css={[styles.circleBase, getStepStyles(stepTwo)]} />
            <div>
              <h3 css={{ fontWeight: 'bold', marginBottom: '4px' }}>{t('appHeader.Select Plan')}</h3>
              <p>{t('Multiple Plans to select')}</p>
            </div>
          </Anchor>
          <div css={[styles.verticalLine, { height: '24px', top: 122 }]} />
          {/* step 3 */}
          <Anchor to={stepThree.href} css={styles.stepLink(stepThree)}>
            <span css={[styles.circleBase, getStepStyles(stepThree)]} />
            <div>
              <h3 css={{ fontWeight: 'bold', marginBottom: '4px' }}>{t('TikTok Ad Account')}</h3>
              <p>{t('Link and Create your Ad Account')}</p>
            </div>
          </Anchor>
        </div>
      </div>
      <CrowdinButton />
      <div css={{ borderTop: THEME.border.base, padding: '14px 12px' }}>
        <LanguageSelector />
        <MenuItem
          key="help-center"
          target="_blank"
          size={22}
          title={MenuTitle.HelpCenter}
          href={helpCenterUrl}
          rightNode={<Icon icon="external-link" width={16} height={16} color="#6E7C89" />}
        />
      </div>
    </div>
  );
};

const styles = {
  navigation: css({
    padding: '16px 24px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    zIndex: 11,
    height: '100vh',
    position: 'fixed',
    width: THEME.container.menu.width,
    backgroundColor: THEME.background.colors.white,
    boxSizing: 'border-box',
    boxShadow: '0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25)'
  }),
  stepsContainer: css({ position: 'relative', marginTop: '40px', display: 'grid', rowGap: '40px' }),
  stepLink: (step: StepType) =>
    css({
      display: 'flex',
      columnGap: '14px',
      opacity: step.isActive || step.isCompleted ? 1 : 0.3,
      pointerEvents: step.isDisabled ? 'none' : 'auto'
    }),
  verticalLine: css({
    position: 'absolute',
    width: '1px',
    background: THEME.icon.colors.black,
    opacity: 0.2,
    left: 11.5
  }),
  circleBase: css({
    position: 'relative',
    flex: 'none',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    boxSizing: 'border-box',
    border: `1px solid ${THEME.icon.colors.gray.lv2}`
  })
};
