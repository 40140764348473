import { css } from '@emotion/react';
import { forwardRef, ReactNode } from 'react';

export interface PopoverItemProps {
  id?: string;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}
export const PopoverItem = forwardRef<HTMLDivElement, PopoverItemProps>(({ children, ...restProps }, ref) => (
  <div css={styles.menuItem} {...restProps} ref={ref}>
    {children}
  </div>
));
PopoverItem.displayName = 'PopoverItem';

const styles = {
  menuItem: css({
    padding: '8px',
    display: 'flex',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    alignItems: 'center',
    '&:hover': { background: '#f6f8fa' }
  })
};
