export interface HorizontalLineProps {
  gap?: number;
  className?: string;
}

export const HorizontalLine = ({ gap, className }: HorizontalLineProps) => (
  <hr
    css={{
      height: '1px',
      margin: '0 auto',
      backgroundColor: '#eef3f7',
      width: `calc(100% - ${2 * (gap ?? 0)}px)`
    }}
    className={className}
  />
);
