import { useTranslation } from 'react-i18next';
import { FormProvider, Modal } from '@/shared/atoms';
import { FormAction } from '@/shared/types';
import { Template, TemplateProps } from './Template';
import { CSV_EXPORT_TYPE, DownloadCsvPagesAndRowsModalValues } from './types';
import { getCsvPagesAndRowsValidationSchema } from './validationSchema';

export interface DownloadCsvPagesAndRowsModalProps
  extends TemplateProps,
    FormAction<DownloadCsvPagesAndRowsModalValues> {
  limit?: number;
  open?: boolean;
}

export const DownloadCsvPagesAndRowsModal = ({
  open,
  limit,
  maxRows,
  onClose,
  onSubmit,
  ...templateProps
}: DownloadCsvPagesAndRowsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} css={{ maxWidth: '480px' }} hasCloseIcon>
      <FormProvider<DownloadCsvPagesAndRowsModalValues>
        onSubmit={onSubmit}
        defaultValues={{ limit, exportType: CSV_EXPORT_TYPE.PAGES }}
        zodSchema={getCsvPagesAndRowsValidationSchema(t, maxRows)}
      >
        <Template maxRows={maxRows} {...templateProps} onClose={onClose} />
      </FormProvider>
    </Modal>
  );
};

export * from './types';
