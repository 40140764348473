import { FormProvider } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TikTokSignupFormRegisteredSchema } from './schema';
import { Template } from './Template';
import { TikTokSignupFormRegisteredProps, TikTokSignupFormRegisteredValues } from './types';

export const TikTokSignupFormRegistered = ({ onSubmit, defaultValues }: TikTokSignupFormRegisteredProps) => (
  <FormProvider
    onSubmit={onSubmit}
    values={defaultValues as TikTokSignupFormRegisteredValues}
    zodSchema={TikTokSignupFormRegisteredSchema}
    css={{
      borderRadius: '3px',
      boxShadow: THEME.shadows.boxShadowContainer,
      backgroundColor: THEME.background.colors.white
    }}
  >
    <Template />
  </FormProvider>
);

export { type TikTokSignupFormRegisteredValues };
