import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthTalentSignInFacebook.graphql';
import { Mutation, MutationsocialAuthTalentSignInFacebookArgs } from '../../__generated__/globalTypes';

export const useSocialAuthTalentSignInFacebookMutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthTalentSignInFacebookArgs>
) => {
  const [callSocialAuthTalentSignInFacebook, result] = useMutation<
    Mutation,
    MutationsocialAuthTalentSignInFacebookArgs
  >(MUTATION, options);

  return { callSocialAuthTalentSignInFacebook, ...result };
};

export type SocialAuthTalentSignInFacebookMutationFunction = Mutation['socialAuthTalentSignInFacebook'];
