import { FacebookPageAnalyticsOverviewPayload } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { DAILY_POST_PARAM_KEY } from '@/shared/constants';
import { useDeepCompareEffect, useSelectItemIds } from '@/shared/hooks';
import { FacebookPostInDateDetailsModal } from '@/shared/molecules';
import { NoteAggregateLastThirtyDays } from '@/shared/organisms';
import {
  AnalyticsOverviewChart,
  AnalyticsOverviewLegend,
  AnalyticsOverviewWrapper,
  AnalyticsOverviewEngagement
} from '../../../shared';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import {
  useAnalyticsFacebookDashboardOverviewData,
  useAnalyticsFacebookDashboardOverviewPosts,
  useAnalyticsFacebookDashboardOverviewLegend
} from './hooks';
import { AnalyticsFacebookDashboardGraphKeys as Keys } from './types';

interface TemplateProps {
  influencerId?: number;
  data: FacebookPageAnalyticsOverviewPayload;
  accountId: AnalyticsSelectedAccountInfo['id'];
  isInfluencerProfile?: boolean;
}

const Template = ({ data, accountId, influencerId, isInfluencerProfile }: TemplateProps) => {
  const { availableLegendOptions, ButtonSelectMoreLegendOptions } = useAnalyticsFacebookDashboardOverviewLegend({
    isInfluencerProfile
  });
  const {
    selectItem: setSelectedLegendKey,
    selectedItemIds: selectedLegendKeys,
    setSelectedItemIds: setSelectedLegendKeys
  } = useSelectItemIds<Keys>({ defaultSelectedIds: ['follower', 'post'] });
  const { chartData, categories, overviewEngagements, availableOverviewEngagements } =
    useAnalyticsFacebookDashboardOverviewData({
      data,
      selectedLegendKeys,
      isInfluencerProfile,
      availableEngagementKeys: availableLegendOptions.map((option) => option.value)
    });
  const { dailyPosts, customChartOptions } = useAnalyticsFacebookDashboardOverviewPosts({
    chartData,
    accountId,
    influencerId,
    isInfluencerProfile
  });

  useDeepCompareEffect(() => {
    // Reset selected lengend keys when user unselect dropdown option
    const availableLegendKeys = availableLegendOptions.map<Keys>((option) => option.value);
    const newSelectedLegendKeys = selectedLegendKeys.filter((key) => availableLegendKeys.includes(key));
    setSelectedLegendKeys(newSelectedLegendKeys);
  }, [availableLegendOptions]);

  const hasNoData = !overviewEngagements.some((item) => !!item.total);

  return (
    <>
      <AnalyticsOverviewWrapper
        help=""
        dataNotAvailable={hasNoData}
        headerChildren={
          !hasNoData ? (
            <>
              {isInfluencerProfile ? <NoteAggregateLastThirtyDays /> : null}
              {ButtonSelectMoreLegendOptions}
            </>
          ) : null
        }
      >
        <AnalyticsOverviewEngagement
          engagement={availableOverviewEngagements}
          itemsPerRow={isInfluencerProfile ? 4 : 5}
        />
        <AnalyticsOverviewLegend
          options={availableLegendOptions}
          selectedOptions={selectedLegendKeys}
          onSelectOption={({ value }) => setSelectedLegendKey(value)}
        />
        <AnalyticsOverviewChart
          data={chartData}
          categories={categories}
          customOptions={customChartOptions}
          customTooltip={{
            metrics: [
              ['follower', 'post', 'reaction'],
              ['comment', 'share', 'impression'],
              ['engagement', 'engagementRate', 'pageCta'],
              ['pageLikes', 'pageImpression']
            ]
          }}
        />
      </AnalyticsOverviewWrapper>

      <FacebookPostInDateDetailsModal data={dailyPosts} paramKey={DAILY_POST_PARAM_KEY} />
    </>
  );
};

export interface AnalyticsFacebookDashboardOverviewTemplateProps
  extends Pick<TemplateProps, 'accountId' | 'influencerId' | 'isInfluencerProfile'> {
  loading?: boolean;
  data?: FacebookPageAnalyticsOverviewPayload | null;
}
export const AnalyticsFacebookDashboardOverviewTemplate = ({
  data,
  loading,
  ...restProps
}: AnalyticsFacebookDashboardOverviewTemplateProps) => (
  <RenderDataWithFallback loading={loading} skipDelayed>
    {data ? <Template {...restProps} data={data} /> : null}
  </RenderDataWithFallback>
);
