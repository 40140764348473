import { useUserRoles } from '@/auth';
import { EngagementDetail } from '@/graphql';
import { FormStyle, UploadButton } from '@/shared/atoms';
import { useToggleState } from '@/shared/hooks';
import { PostReportFilterFormValues, UploadCsvModal } from '@/shared/molecules';
import { FilterAction } from '@/shared/types';
import { getRestApiUrl } from '@/shared/utils';
import { DownloadPostReportsButton, DownloadPostReportsButtonProps, PostReportFilter } from '../../../PostReport';
import { useUploadEngagementReportFile } from './useUploadEngagementReportFile';

export interface EngagementReportFilterProps extends FilterAction<PostReportFilterFormValues> {
  engagement: EngagementDetail;
  segmentState: DownloadPostReportsButtonProps['segmentState'];
}
export const EngagementReportFilter = ({
  filter,
  setFilter,
  segmentState,
  engagement: { id, title, startDate, endDate }
}: EngagementReportFilterProps) => {
  const uploadModal = useToggleState();
  const { isAdminStaff } = useUserRoles();
  const { uploading, handleUploadFile } = useUploadEngagementReportFile({ campaignId: id });

  return (
    <PostReportFilter
      filter={{ ...filter, startDate: filter.startDate || startDate, endDate: filter.endDate || endDate }}
      setFilter={setFilter}
    >
      {isAdminStaff ? (
        <>
          <FormStyle.FieldWrapper css={{ flex: 'none' }}>
            <UploadButton onClick={uploadModal.open} loading={uploading} />
          </FormStyle.FieldWrapper>

          <UploadCsvModal
            uploading={uploading}
            open={uploadModal.status}
            onClose={uploadModal.close}
            onCustomUpload={handleUploadFile}
            downloadTranslationKey="User can manual upload template and also upload it"
            downloadFile={{
              extension: '.csv',
              filenamePrefix: `manual-upload-template (${title})`,
              url: getRestApiUrl(`report/campaign/engagement/${id}/upload_report/csv/`)
            }}
          />
        </>
      ) : null}

      <FormStyle.FieldWrapper css={{ flex: 'none' }}>
        <DownloadPostReportsButton filter={filter} campaignId={id} segmentState={segmentState} />
      </FormStyle.FieldWrapper>
    </PostReportFilter>
  );
};
