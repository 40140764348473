import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { CampaignType } from '@/graphql';
import { DEFAULT_SELECTED_COLUMNS } from './PostReportTable/utils';
import { PostReportTableStatKeys } from './PostReportTable';

interface PostReportContextType {
  hasTracking: boolean;
  campaignType: CampaignType;
  isOnlyTikTokTtcm?: boolean;
  selectedColumns: PostReportTableStatKeys[];
  setSelectedColumns: Dispatch<SetStateAction<PostReportTableStatKeys[]>>;
}

const PostReportContext = createContext<PostReportContextType>({} as PostReportContextType);

export const usePostReportContext = () => useContext(PostReportContext);

interface InfluencerProviderProps {
  children: React.ReactElement | Array<React.ReactElement>;
  value: Pick<PostReportContextType, 'hasTracking' | 'campaignType' | 'isOnlyTikTokTtcm'>;
}

export const PostReportProvider = ({
  children,
  value: { hasTracking, campaignType, isOnlyTikTokTtcm }
}: InfluencerProviderProps) => {
  const [selectedColumns, setSelectedColumns] = useState<PostReportTableStatKeys[]>(DEFAULT_SELECTED_COLUMNS);

  return (
    <PostReportContext.Provider
      value={{
        hasTracking,
        campaignType,
        selectedColumns,
        isOnlyTikTokTtcm,
        setSelectedColumns
      }}
    >
      {children}
    </PostReportContext.Provider>
  );
};
