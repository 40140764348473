import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Anchor, ButtonSubmit, FormStyle, Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PasswordField } from '@/shared/molecules';

import { ResetPasswordFormValues, ResetPasswordFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<ResetPasswordFormValues>();

  const values = watch();

  return (
    <FormStyle.Wrapper css={{ position: 'relative' }}>
      <Anchor to="/" css={{ position: 'absolute', top: '4px', left: '4px' }}>
        <Icon size={16} icon="arrow-left" color={THEME.icon.colors.blue} />
      </Anchor>

      <h1 css={{ fontSize: '20px', lineHeight: '100%', textAlign: 'center' }}>{t('Change your password')}</h1>
      <p css={{ fontSize: '14px', lineHeight: '140%', padding: '16px 0', textAlign: 'center' }}>
        {t('Please set a new password')}
      </p>

      <PasswordField<ResetPasswordFormKeys> name="password" title={t('Password.New Password')} dynamicError required />
      <PasswordField<ResetPasswordFormKeys> name="passwordConfirm" title={t('Confirm new password')} required />

      <ButtonSubmit
        variant="blue"
        loading={isSubmitting}
        title={t('Button.Update')}
        css={{ width: '100%', marginTop: '16px' }}
        disabled={!values.password || !values.passwordConfirm}
      />
    </FormStyle.Wrapper>
  );
};
