import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateTalentAgency.graphql';
import { Mutation, MutationupdateTalentAgencyArgs } from '../../__generated__/globalTypes';

export const useUpdateTalentAgencyMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateTalentAgencyArgs>
) => {
  const [callUpdateTalentAgency, result] = useMutation<Mutation, MutationupdateTalentAgencyArgs>(MUTATION, options);

  return { callUpdateTalentAgency, ...result };
};

export type UpdateTalentAgencyMutationFunction = Mutation['updateTalentAgency'];
