import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useEngagementPermissions, useMarketplacePermissions } from '@/auth';
import { CampaignType } from '@/graphql';
import { THEME } from '@/shared/constants';
import { getMkpReportLink, MkpReportLinkProps } from '@/shared/utils';
import { Anchor } from '../../Anchor';
import { MediaPreview } from '../../MediaPreview';
import { TextCutter } from '../../TextCutter';
import { CampaignReportLinkType } from '../types';

export interface CampaignProps extends MkpReportLinkProps {
  title: string;
  type: CampaignType;
  src: string | null;
  className?: string;
}

export const Campaign = ({ id, mkpCampaignType, isPostTracking, src, title, type, className }: CampaignProps) => {
  const { t } = useTranslation();
  const { hideEngagementReportLink } = useEngagementPermissions();
  const { hideMarketplaceReportLink } = useMarketplacePermissions();
  const campaignId = id?.toString() || '';

  const hideReportLink =
    type === CampaignType.ENGAGEMENT
      ? hideEngagementReportLink
      : type === CampaignType.MARKETPLACE
        ? hideMarketplaceReportLink
        : false;

  const { campaignTitle, backgroundColor, to, params, search } = (() => {
    switch (type) {
      case CampaignType.ENGAGEMENT:
        return {
          backgroundColor: THEME.campaign.engagement.color,
          campaignTitle: 'Engagement',
          to: '/engagement/$id/report' as CampaignReportLinkType['to'],
          params: { id: campaignId },
          search: undefined
        };
      case CampaignType.AUTO_MANAGED:
        return {
          backgroundColor: THEME.campaign.autoManaged.color,
          campaignTitle: 'eCommerce Affiliate',
          to: '/auto-managed/$id/report' as CampaignReportLinkType['to'],
          params: { id: campaignId },
          search: undefined
        };
      case CampaignType.MARKETPLACE:
      default:
        return {
          backgroundColor: THEME.campaign.marketplace.color,
          campaignTitle: 'Marketplace',
          ...getMkpReportLink({
            id: campaignId,
            mkpCampaignType,
            isPostTracking
          })
        };
    }
  })();

  return (
    <Anchor
      to={to}
      params={params}
      search={search}
      className={className}
      css={[styles.wrapper, { pointerEvents: hideReportLink ? 'none' : 'auto' }]}
    >
      <MediaPreview css={styles.image} src={src} className="image" />
      <div css={{ marginLeft: '8px' }}>
        <TextCutter text={title} lines={2} />
        <div css={[styles.badge, { backgroundColor }]}>{t(campaignTitle)}</div>
      </div>
    </Anchor>
  );
};
const styles = {
  image: css({ width: '96px', height: '54px', borderRadius: '2px', border: '1px solid #f5f5f5' }),
  wrapper: css({
    display: 'flex',
    margin: '8px 0',
    alignItems: 'center',
    '&:hover': { textDecoration: 'none', '.image': { opacity: 0.7 } }
  }),
  badge: css({
    fontWeight: 600,
    padding: '0 4px',
    fontSize: '11px',
    marginTop: '8px',
    lineHeight: '16px',
    width: 'max-content',
    borderRadius: '8px',
    pointerEvents: 'none',
    color: THEME.text.colors.white
  })
};
