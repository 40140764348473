import { useState } from 'react';

// Using for any component has toggle state like: Modal, Popup, Tooltip, Dropdown menu, Switch,....
export const useToggleState = (init = false) => {
  const [status, setStatus] = useState<boolean>(init);

  const open = () => {
    setStatus(true);
  };

  const close = () => {
    setStatus(false);
  };

  const toggleStatus = () => {
    setStatus((prevState) => !prevState);
  };

  return { status, setStatus, open, close, toggleStatus };
};
