import { useTranslation } from 'react-i18next';
import { TextField, TextFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const ServiceUrlField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  ...restProps
}: Partial<TextFieldProps<T>>) => {
  const { t } = useTranslation();

  return (
    <TextField<T>
      title={t('TextForm.Service URL')}
      help={t('Tooltip.Visit URL Help')}
      placeholder="https://anytag.jp/"
      name={name || (MarketplaceFormKeys.clickUrl as T)}
      {...restProps}
    />
  );
};
