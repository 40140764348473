import { useState } from 'react';
import { useInfluencerPackagePermissions } from '@/auth';
import { UserProposalSocialAcccountBudgetInput } from '@/graphql';
import { BlackBar, BlackBarProps, Button } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { getSocialPairsIds } from '@/shared/utils';
import { AddPackageWithAccounts } from '../shared';
import { useProposeInfluencersApi } from './hooks';
import { ProposeInfluencersDownloadButton } from './ProposeInfluencersDownloadButton';

interface SelectInfluencersActionBarProps extends Omit<BlackBarProps, 'children'> {
  isViewMode?: boolean;
  numberSelected: number;
  hasAllSelected: boolean;
  onSelectAll: () => void;
  onToggleSelect: () => void;
  selectedInfluencerAccountIds: number[];
  onToggleBulkEditModal: () => void;
  isSocialMediaAccountsSelection?: boolean;
  selectedSocialAccounts: UserProposalSocialAcccountBudgetInput[];
}

export const SelectProposedInfluencersActionBar = ({
  isViewMode,
  onSelectAll,
  numberSelected,
  hasAllSelected,
  onToggleSelect,
  onToggleBulkEditModal,
  selectedInfluencerAccountIds,
  selectedSocialAccounts,
  isSocialMediaAccountsSelection
}: SelectInfluencersActionBarProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { denyInfluencer } = useProposeInfluencersApi();
  const { hideAddPackageBtn } = useInfluencerPackagePermissions();
  const [isRejecting, setRejecting] = useState<boolean>(false);

  const handleRejectInfluencers = async () => {
    try {
      setRejecting(true);
      await denyInfluencer({
        variables: {
          input: {
            influencerIds: selectedInfluencerAccountIds
          }
        }
      });
      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t((error as any).message), { variant: 'error' });
    } finally {
      setRejecting(false);
    }
  };

  const { socialPairs } = getSocialPairsIds(selectedSocialAccounts);

  return (
    <BlackBar
      selectAllProps={{ onClick: onSelectAll }}
      checkboxProps={{
        onClick: onToggleSelect,
        indeterminate: !hasAllSelected,
        label: t('Selected', { count: numberSelected })
      }}
    >
      {isViewMode ? (
        <>
          <Button
            variant="red"
            loading={isRejecting}
            disabled={isRejecting}
            title={t('Button.Reject')}
            onClick={handleRejectInfluencers}
            css={{ padding: '0 16px', marginLeft: '16px' }}
          />
          <ProposeInfluencersDownloadButton
            selectedInfluencerIds={selectedInfluencerAccountIds}
            css={{ marginLeft: 'auto' }}
          />
          {!hideAddPackageBtn ? (
            <AddPackageWithAccounts
              influencerIds={selectedInfluencerAccountIds}
              socialPairs={socialPairs}
              isInfluencerAccount={!isSocialMediaAccountsSelection}
              css={{ marginLeft: '8px' }}
            />
          ) : null}
        </>
      ) : (
        <>
          <Button
            variant="white"
            title={t('Button.Bulk Edit')}
            onClick={onToggleBulkEditModal}
            css={{ padding: '0 16px', marginLeft: '16px' }}
          />
          {!hideAddPackageBtn ? (
            <AddPackageWithAccounts
              influencerIds={selectedInfluencerAccountIds}
              socialPairs={socialPairs}
              isInfluencerAccount={!isSocialMediaAccountsSelection}
              css={{ marginLeft: 'auto' }}
            />
          ) : null}
        </>
      )}
    </BlackBar>
  );
};
