import { Table } from '@/shared/atoms';
import { TiktokSpecialCampaignReport } from '@/graphql';
import { TikTokSpecialPostDetailsModal } from '@/shared/molecules';
import { useTikTokCampaignReportTable } from './hooks';

export interface TikTokCampaignReportTableProps {
  loading?: boolean;
  data: TiktokSpecialCampaignReport;
}

export const TikTokCampaignReportTable = ({ data, loading }: TikTokCampaignReportTableProps) => {
  const { rows, columns, allPosts } = useTikTokCampaignReportTable({ data });

  return (
    <>
      <Table
        loading={loading}
        noTopBorderRadius
        maxHeight="820px"
        data={{ rows, columns, rowHeight: '56px' }}
        stickyHeader
      />

      <TikTokSpecialPostDetailsModal posts={allPosts} />
    </>
  );
};
