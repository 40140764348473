import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Label,
  Modal,
  ModalTitle,
  ModalProps,
  LabelProps,
  InputProps,
  ModalContent,
  ModalFooterActions
} from '@/shared/atoms';

export interface AddAnalyticsAccountModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  loading?: boolean;
  onSubmit?: (accountName: string) => void;
  customModal: {
    title: ReactNode;
    description?: ReactNode;
    input?: { title?: LabelProps['title']; placeholder?: InputProps['placeholder'] };
  };
}
export const AddAnalyticsComparableAccountModal = ({
  open,
  loading,
  onClose,
  onSubmit,
  customModal
}: AddAnalyticsAccountModalProps) => {
  const { t } = useTranslation();
  const [newAccount, setNewAccount] = useState('');

  useEffect(() => () => setNewAccount(''), []);

  const handleAddNewAccount = () => {
    if (!newAccount) return;

    onSubmit?.(newAccount);
  };

  return (
    <Modal open={open} onClose={onClose} css={{ width: '445px' }}>
      <ModalContent>
        <ModalTitle>{customModal?.title}</ModalTitle>

        {customModal.description ? (
          <div css={{ fontSize: '13px', marginTop: '16px' }}>{customModal.description}</div>
        ) : null}

        {customModal.input?.title ? <Label title={customModal.input.title} css={{ marginTop: '16px' }} /> : null}
        <Input
          onChange={(e) => setNewAccount(e.target.value)}
          placeholder={customModal.input?.placeholder || ''}
          autoFocus
        />
      </ModalContent>
      <ModalFooterActions
        cancelButtonProps={{ title: t('Button.Cancel'), onClick: onClose }}
        submitButtonProps={{ loading, disabled: !newAccount, title: t('Button.Add'), onClick: handleAddNewAccount }}
      />
    </Modal>
  );
};
