import { useTranslation } from 'react-i18next';
import { InstagramUGCManagementListPostsItemPayload } from '@/graphql';
import { ListEmpty, RenderDataWithFallback } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { UGCPost, UGCPostDetailsModal } from '../../shared';

export interface AnalyticsInstagramUGCPostsListProps {
  loading?: boolean;
  onRefetchList?: () => void;
  analyticsAccount: AnalyticsSelectedAccountInfo;
  data?: readonly InstagramUGCManagementListPostsItemPayload[];
}

export const AnalyticsInstagramUGCPostsList = ({
  loading,
  data = [],
  onRefetchList,
  analyticsAccount
}: AnalyticsInstagramUGCPostsListProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  return (
    <RenderDataWithFallback
      loading={loading}
      hasNoData={!data.length}
      noDataNode={<ListEmpty title={t('No posts with the given tag')} description={t('Let’s register another tag')} />}
    >
      <div css={{ display: 'flex', flexWrap: 'wrap', margin: '8px -8px -8px' }}>
        {data.map((post) => (
          <div
            key={post.postSnsId}
            css={{ padding: '8px', borderRadius: '3px', boxSizing: 'border-box', flex: 'calc(100% / 6) 0 0' }}
          >
            <UGCPost post={post} onClick={() => (post.postSnsId ? setPostDetailsParam(post.postSnsId) : undefined)} />
          </div>
        ))}
      </div>

      <UGCPostDetailsModal
        onRefetchList={onRefetchList}
        analyticsAccount={analyticsAccount}
        posts={data.reduce<PostDetailsType[]>(
          (acc, { postSnsId }) => (postSnsId ? [...acc, { id: postSnsId }] : acc),
          []
        )}
      />
    </RenderDataWithFallback>
  );
};
