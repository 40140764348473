import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramDashboardPostAnalytics.graphql';
import { Query, QueryinstagramDashboardPostAnalyticsArgs } from '../../__generated__/globalTypes';

export const useInstagramDashboardPostAnalyticsQuery = (
  options?: QueryHookOptions<Query, QueryinstagramDashboardPostAnalyticsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramDashboardPostAnalyticsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramDashboardPostAnalyticsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramDashboardPostAnalyticsArgs>
): QueryResult<Query, QueryinstagramDashboardPostAnalyticsArgs> & {
  getInstagramDashboardPostAnalytics: LazyQueryExecFunction<Query, QueryinstagramDashboardPostAnalyticsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramDashboardPostAnalytics, result] = useLazyQuery<Query, QueryinstagramDashboardPostAnalyticsArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getInstagramDashboardPostAnalytics, ...result };
};

export const useInstagramDashboardPostAnalyticsPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramDashboardPostAnalytics = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramDashboardPostAnalyticsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramDashboardPostAnalyticsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramDashboardPostAnalytics };
};
