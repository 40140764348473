import { useTranslation } from 'react-i18next';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { useAllInstagramAmbassadorPostsQuery } from '@/graphql';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { ProfilePostsCount, ProfilePostsFilter, AnalyticsInstagramAmbassadorPostsList } from '@/shared/organisms';
import { useInitialAnalyticsPosts } from '../hooks';
import { AnalyticsIgBrandAmbassadorPostsSearchSchemaType } from './schemaTypes';

export const AnalyticsInstagramBrandAmbassadorPosts = () => {
  const { t } = useTranslation();
  const { brandAccountId, mainSocialAccountId } = useInitialAnalyticsPosts({
    socialMedia: 'instagram',
    analyticsProfileUrl: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction',
    document: (name) => ({
      appHeader: name ? 'appHeader.UserAnalysisListWithAccountName' : 'appHeader.Brand Ambassadors List',
      title: name ? 'documentTitle.UserAnalysisListWithAccountName' : 'documentTitle.Brand Ambassadors List'
    })
  });
  const { page, limit, filter, setFilter } = useParamsSearch<AnalyticsIgBrandAmbassadorPostsSearchSchemaType>();
  const { data, previousData, loading } = useAllInstagramAmbassadorPostsQuery({
    skip: !brandAccountId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      endDate: filter.endDate,
      startDate: filter.startDate,
      offset: getOffset(page, limit),
      accountId: Number(mainSocialAccountId)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allInstagramAmbassadorPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasKeyword={false} hasPeriod />

      <ProfilePostsCount count={totalRecords} countLabel={t('Users found')} />

      <AnalyticsInstagramAmbassadorPostsList
        loading={loading}
        analyticsAccountId={mainSocialAccountId}
        data={result?.allInstagramAmbassadorPosts?.ambassadorPosts}
      />
    </ListWithPagination>
  );
};
