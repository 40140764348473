import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { EngagementDetail, InfluencerSearchSortField, ReadPackageAccount, ReadPackageInfluencers } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
import { WidgetSelectButtonProps } from '../shared';
import { DefaultInfluencersProps, InfluencersFilterSchema } from '../types';

export const InfluencerPackagesFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  keyword: true,
  countryIds: true
});
export const InfluencerPackagesSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(InfluencerPackagesFilterSchema, {
    keyword: '',
    tab: '',
    countryIds: []
  }),
  ...SearchFilterDefaults.pageLimit(),
  sort: SearchFilterDefaults.sort(InfluencerSearchSortField).default({ field: null, order: null })
});

export type InfluencerPackagesSearchSchemaType = z.infer<typeof InfluencerPackagesSearchSchema>;
export type InfluencerPackagesFilterFormValues = z.infer<typeof InfluencerPackagesFilterSchema>;
export type InfluencerPackagesFilterFormKeys = UnionOfKeys<InfluencerPackagesFilterFormValues>;

export interface InfluencerPackagesProps extends DefaultInfluencersProps<ReadPackageAccount> {
  // Currently Influencers packages list only used in Engagement page so `engagementId` much has value
  // Other Influencers search list can be use in both Engagement & Influencers page, so we don't move this prop into common interface
  engagementId: EngagementDetail['id'];
  getInfluencersListSelectButtonProps?: (
    packageAccount: ReadPackageAccount,
    influencer: ReadPackageInfluencers
  ) => WidgetSelectButtonProps;
}
