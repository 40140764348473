import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateMarketplace.graphql';
import { Mutation, MutationcreateMarketplaceArgs } from '../../__generated__/globalTypes';

export const useCreateMarketplaceMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateMarketplaceArgs>
) => {
  const [callCreateMarketplace, result] = useMutation<Mutation, MutationcreateMarketplaceArgs>(MUTATION, options);

  return { callCreateMarketplace, ...result };
};

export type CreateMarketplaceMutationFunction = Mutation['createMarketplace'];
