import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { WidgetSelectButton, WidgetSelectButtonProps } from './WidgetSelectButton';

export interface ProfileWidgetTemplateProps {
  className?: string;
  children: ReactNode;
  selectButtonProps?: WidgetSelectButtonProps;
}

export const ProfileWidgetTemplate = ({ className, children, selectButtonProps }: ProfileWidgetTemplateProps) => (
  <div css={styles.wrapper} className={className}>
    {selectButtonProps ? <WidgetSelectButton {...selectButtonProps} className={className} /> : null}

    <div css={styles.content}>{children}</div>
  </div>
);

const styles = {
  wrapper: css({
    height: '96px',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  }),
  content: css({
    flex: 1,
    height: '100%',
    padding: '8px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  })
};
