import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllDouyinInfluencers.graphql';
import { Query, QueryallDouyinInfluencersArgs } from '../../__generated__/globalTypes';

export const useAllDouyinInfluencersQuery = (options?: QueryHookOptions<Query, QueryallDouyinInfluencersArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallDouyinInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllDouyinInfluencersLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallDouyinInfluencersArgs>
): QueryResult<Query, QueryallDouyinInfluencersArgs> & {
  getAllDouyinInfluencers: LazyQueryExecFunction<Query, QueryallDouyinInfluencersArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllDouyinInfluencers, result] = useLazyQuery<Query, QueryallDouyinInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllDouyinInfluencers, ...result };
};

export const useAllDouyinInfluencersPromiseQuery = () => {
  const client = useApolloClient();

  const getAllDouyinInfluencers = useCallback(
    (options?: Omit<QueryOptions<QueryallDouyinInfluencersArgs, Query>, 'query'>) =>
      client.query<Query, QueryallDouyinInfluencersArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllDouyinInfluencers };
};
