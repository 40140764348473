import { useUserRoles } from '@/auth';
import { Anchor, TextCutter } from '@/shared/atoms';
import { InfluencerAvatar } from '@/shared/molecules';
import { useGenerateInfluencerProfileLink, usePostDetailsParam } from '@/shared/hooks';
import { Post } from './Post';
import { InfluencerPostReportStats } from './types';
import { POST_REPORT_STICKY_COL_DIMS } from './utils';

type InfluencerPostProps = InfluencerPostReportStats['influencerDetails'];

export const InfluencerPost = ({ id, name, thumbNail, post }: InfluencerPostProps) => {
  const { isAdvertiserFromTikTok } = useUserRoles();
  const { setPostDetailsParam } = usePostDetailsParam();
  const { generateProfileLink } = useGenerateInfluencerProfileLink({ disabled: isAdvertiserFromTikTok });

  return (
    <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Anchor
        css={{ display: 'flex', alignItems: 'center', width: POST_REPORT_STICKY_COL_DIMS.influencerProfileWidth }}
        {...generateProfileLink(id)}
      >
        <InfluencerAvatar
          size="48px"
          src={thumbNail}
          css={{ borderRadius: '2px', border: '1px solid #f5f5f5' }}
          asChild
        />
        <TextCutter lines={2} css={{ fontSize: '13px', marginLeft: '8px' }} text={name || '-'} />
      </Anchor>

      {post && (
        <Post
          url={post.url}
          content={post.content}
          thumbNail={post.thumbNail}
          onClickPost={() => setPostDetailsParam(post.id)}
        />
      )}
    </div>
  );
};
