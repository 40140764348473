import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ApproveCmsInvoice.graphql';
import { Mutation, MutationapproveCmsInvoiceArgs } from '../../__generated__/globalTypes';

export const useApproveCmsInvoiceMutation = (
  options?: MutationHookOptions<Mutation, MutationapproveCmsInvoiceArgs>
) => {
  const [callApproveCmsInvoice, result] = useMutation<Mutation, MutationapproveCmsInvoiceArgs>(MUTATION, options);

  return { callApproveCmsInvoice, ...result };
};

export type ApproveCmsInvoiceMutationFunction = Mutation['approveCmsInvoice'];
