import React, { createContext, useContext } from 'react';
import { useToggleState } from '@/shared/hooks';
import { useInitChatDemo } from './hook';
import { ChatDemoHistorySidebar } from './shared';

interface ContextType extends ReturnType<typeof useInitChatDemo> {
  toggleHistoryList: () => void;
}

const ChatDemoContext = createContext<ContextType>({} as ContextType);

export interface ChatDemoProviderProps {
  children: React.ReactElement | Array<React.ReactElement>;
}

export const ChatDemoProvider = ({ children }: ChatDemoProviderProps) => {
  const states = useInitChatDemo();
  const historyStates = useToggleState();

  return (
    <ChatDemoContext.Provider value={{ ...states, toggleHistoryList: historyStates.toggleStatus }}>
      {children}

      {historyStates.status ? <ChatDemoHistorySidebar /> : null}
    </ChatDemoContext.Provider>
  );
};

export const useChatDemoContext = () => useContext<ContextType>(ChatDemoContext);
