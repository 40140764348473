import { useFormContext } from 'react-hook-form';
import { useMarketplaceHashtagCloudLazyQuery } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { HashtagSuggestionField, HashtagSuggestionFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys, MarketplaceFormValues } from '../types';
import { generateHashtagVariables } from '../utils';

export const HashtagField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  ...restProps
}: Partial<HashtagSuggestionFieldProps<T>>) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { getValues } = useFormContext<MarketplaceFormValues>();
  const { getMarketplaceHashtagCloud, data } = useMarketplaceHashtagCloudLazyQuery();

  const getMarketplaceHashtagsCloud = async (formValues: Partial<MarketplaceFormValues>) => {
    try {
      const variables = generateHashtagVariables(formValues);
      await getMarketplaceHashtagCloud({ variables });
    } catch (error) {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <HashtagSuggestionField<T>
      maxHashtags={10000} // We don't have limitation
      name={name || (MarketplaceFormKeys.hashtags as T)}
      hashtagsCloud={data?.marketplaceHashtagCloud || []}
      getHashtagsCloud={() => getMarketplaceHashtagsCloud(getValues())}
      {...restProps}
    />
  );
};
