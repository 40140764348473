import { useState } from 'react';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useDeepCompareEffect, useParamsSearch } from '@/shared/hooks';
import {
  PaymentPlanType,
  PaymentPackageStatus,
  usePricingTableQuery,
  usePaymentPackageStatusLazyQuery
} from '@/graphql';
import { CheckingPlanSubscriptionSearchSchemaType } from '../schemaTypes';

/**
 * FLOWS:
 * 1. Call Checking Payment Status Api if no current plan found
 *  - If status return INVALID ==> No plan subscribed ==> Let select mkp plan
 *  - If status return IN_PROGRESS ==> Plan selected and checking payment (2) ==> Show creating modal
 *  - If status return FAILED ==> Plan selected and payment failed ==> Show failed modal
 *  - If status return PAID ==> Try to refetch currentPlan ==> Navigate to add page
 * 2. Plan selected and checking payment
 *  - Automatically call payment status every 5 seconds, limit 10s (mean 3 times 0-5-10)
 *    - If status return INVALID, interrupt api calling ==> Let select plan as usual because we are checking invalid sessionId, it might not belong to this user so let ignore
 *    - If status return IN_PROGRESS after 10s, interrupt api calling ==> Show refreshing modal ==> Try recall last one ==> Show failed payment modal if same value
 *    - If status return FAILED, interrupt api calling ==> Show failed modal
 *    - If status return PAID, interrupt api calling, recall PricingTable api to get current plan
 *      - If has current plan, payment success & sync data ==> Show campaign form for adding
 *      - If no current plan, try recall flows same with checking payment status
 *        - If no current plan after 10s, interrupt api calling ==> Show refreshing modal ==> Try recall last one ==> Show failed payment modal if same value
 *        - If has current plan, payment success & sync data ==> Show campaign form for adding
 * @returns
 */
export const useCheckingPlanSubscription = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [recalledPricingTableApi, setRecalledPricingTableApi] = useState(false);
  const [recalledPaymentStatusApi, setRecalledPaymentStatusApi] = useState(false);
  const [refreshState, setRefreshState] = useState({ loading: false, called: false });
  const {
    setFilter,
    filter: { priceId, packageId, sessionId }
  } = useParamsSearch<CheckingPlanSubscriptionSearchSchemaType>();
  const {
    data,
    called,
    loading,
    refetch: refetchPricingTable
  } = usePricingTableQuery({
    fetchPolicy: 'no-cache', // Must no cache to get the newest plans
    variables: { planType: PaymentPlanType.MARKETPLACE }
  });
  const {
    getPaymentPackageStatus,
    data: paymentData,
    refetch: refetchPaymentPackageStatus
  } = usePaymentPackageStatusLazyQuery({ fetchPolicy: 'no-cache' }); // Must no cache to get payment status

  const marketplacePlans = data?.pricingTable?.plans || [];
  const currentPlan = marketplacePlans.find((plan) => plan.isCurrentPlan);
  const checkingStripePayment = !!priceId && !!packageId && !!sessionId && !currentPlan;
  const hasPriceId = !!priceId;
  // Payment state
  const paymentPackageStatus = paymentData?.paymentPackageStatus;
  const isPaid = paymentPackageStatus?.status === PaymentPackageStatus.PAID;
  const paymentSuccess = hasPriceId && isPaid;
  const hasNoPayment = !hasPriceId || paymentPackageStatus?.status === PaymentPackageStatus.INVALID;
  const paymentFailed = hasPriceId && paymentPackageStatus?.status === PaymentPackageStatus.FAILED;
  const paymentInProgress = hasPriceId && paymentPackageStatus?.status === PaymentPackageStatus.IN_PROGRESS;

  useDeepCompareEffect(() => {
    if (checkingStripePayment && sessionId && called) {
      getPaymentPackageStatus({ variables: { sessionId } });
    }
  }, [checkingStripePayment, sessionId, called]);

  useDeepCompareEffect(() => {
    // After checkout Stripe, have a gap time to sync data to our BE
    // We will try to refetch NO LIMIT the api to get the status
    // Cancelation will happen when user click button or component unmounted
    let timer: NodeJS.Timeout;

    const interruptRefetching = () => {
      clearInterval(timer);
    };

    // Refetch api
    if (checkingStripePayment) {
      if (hasNoPayment) {
        interruptRefetching();
        clearCheckingPlanFlows();
      } else if (paymentFailed) {
        interruptRefetching();
      } else if (paymentInProgress) {
        if (!currentPlan) {
          // Try refetch to get payment status
          let counter = 2; // re-call 2 times

          timer = setInterval(() => {
            if (counter > 0) {
              refetchPaymentPackageStatus();
            } else {
              // Reach limit refetch times, interupt the refetching
              interruptRefetching();
              setRecalledPaymentStatusApi(true);
            }

            counter--;
          }, 5000);
        } else {
          // While refetching but we receved currentPlan=true, interupt the refetching
          interruptRefetching();
          clearCheckingPlanFlows();
        }
      } else {
        interruptRefetching(); // interupt current checking payment status

        // Something wrong: we paid but no current plan found
        if (isPaid && !currentPlan) {
          // Try refetch to get current plan after payment status success
          let counter = 2; // re-call 2 times

          timer = setInterval(() => {
            if (counter > 0) {
              refetchPricingTable();
            } else {
              // Reach limit refetch times, interupt the refetching
              interruptRefetching();
              setRecalledPricingTableApi(true);
              enqueueSnackbar(t('failedToGetCurrentPlan'), { variant: 'error' });
            }

            counter--;
          }, 5000);
        }
      }
    }

    return interruptRefetching;
  }, [checkingStripePayment, currentPlan, isPaid, hasNoPayment, paymentFailed, paymentInProgress]);

  const clearCheckingPlanFlows = () => {
    setFilter({ priceId: '', packageId: '', sessionId: '' });
  };

  const handleRefresh = async () => {
    try {
      setRefreshState((prevState) => ({ ...prevState, loading: true }));

      if (paymentInProgress) {
        await refetchPaymentPackageStatus();
      } else if (!currentPlan) {
        await refetchPricingTable();
      }
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    } finally {
      setRefreshState({ loading: false, called: true });
    }
  };

  return {
    loading,
    priceId,
    packageId,
    currentPlan,
    refreshState,
    paymentFailed,
    paymentSuccess,
    handleRefresh,
    marketplacePlans,
    paymentInProgress,
    checkingStripePayment,
    clearCheckingPlanFlows,
    recalledPricingTableApi,
    recalledPaymentStatusApi
  };
};
