import { FormProvider } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

import { ResetPasswordFormSchema } from './schema';
import { Template } from './Template';
import { ResetPasswordFormProps, ResetPasswordFormValues } from './types';

export const ResetPasswordForm = ({
  onSubmit,
  defaultValues = { password: '', passwordConfirm: '' }
}: ResetPasswordFormProps) => (
  <div css={{ width: THEME.container.dashboard.width, margin: '0 auto' }}>
    <FormProvider onSubmit={onSubmit} defaultValues={defaultValues} zodSchema={ResetPasswordFormSchema}>
      <Template />
    </FormProvider>
  </div>
);

export { type ResetPasswordFormValues };
