import { InfluencersFilterWrapper } from '../shared';
import { InfluencerPostsProps } from './schemaTypes';
import { InfluencerPostsList } from './InfluencerPostsList';
import { InfluencerPostsSort } from './InfluencerPostsSort';
import { InfluencerPostsFilter } from './InfluencerPostsFilter';
import { InfluencerPostsProvider } from './InfluencerPostsContext';

export const InfluencerPosts = (props: InfluencerPostsProps) => (
  <InfluencerPostsProvider value={props}>
    <InfluencersFilterWrapper>
      <InfluencerPostsFilter />
      <InfluencerPostsSort />
    </InfluencersFilterWrapper>

    <InfluencerPostsList />
  </InfluencerPostsProvider>
);

export * from './schemaTypes';
