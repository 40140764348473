import { z } from 'zod';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { SearchFilterDefaults } from '@/shared/validations';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';

export const AnalyticsYtVideosSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(ProfilePostsFilterSchema, {
    startDate: getThirtyDaysAgo(),
    endDate: getToday()
  }),
  sort: SearchFilterDefaults.sort(YoutubeAnalyticsPostsSortOrder).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsYtVideosSearchSchemaType = z.infer<typeof AnalyticsYtVideosSearchSchema>;
