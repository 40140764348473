import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { ButtonLink, FadeInImage } from '@/shared/atoms';
import { reportOnboardingImage01, reportOnboardingImage02, reportOnboardingImage03 } from '@/shared/assets';

export const ReportOnboarding = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.pageGrid}>
      <div css={styles.campaignTile}>
        <h3 css={styles.campaignTileHeader}>{t('Campaign Management and Reporting')}</h3>
        <p css={styles.campaignTileDescription}>
          {t(
            'AnyTag enables you to run data-driven, scalable and effective influencer marketing campaigns that drive real results'
          )}
        </p>
        <ButtonLink
          variant="blue"
          to="/marketplace"
          css={styles.linkButton}
          title={t('Button.Create Marketplace Campaign')}
        />
      </div>
      <div css={{ padding: '8%', paddingTop: '0' }}>
        <FadeInImage src={reportOnboardingImage03} css={{ width: '100%', aspectRatio: 'auto 340 / 212' }} />
      </div>

      <div css={styles.itemWrapper}>
        <FadeInImage src={reportOnboardingImage01} css={styles.itemImg} />
        <div>
          <h3 css={styles.campaignItemHeader}>
            {t('Run affiliate campaigns on e-commerce marketplace or your own brand site')}
          </h3>
          <p css={styles.campaignItemDescription}>
            {t(
              'Run affiliate campaigns on multiple channels across the e-commerce landscape from marketplaces to brand stores'
            )}
          </p>
        </div>
      </div>
      <div css={styles.itemWrapper}>
        <FadeInImage src={reportOnboardingImage02} css={styles.itemImg} />
        <div>
          <h3 css={styles.campaignItemHeader}>{t('Increase user-generated content (UGC) to drive your sales')}</h3>
          <p css={styles.campaignItemDescription}>
            {t(
              'Based on your campaign setup, AnyTag pushes the details to targeted influencers as joinable performance campaigns on AnyCreator'
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  itemImg: css({ width: '80px', height: '80px' }),
  itemWrapper: css({ gap: '16px', display: 'flex' }),
  linkButton: css({ padding: '0 32px', marginTop: '24px', width: 'max-content' }),
  campaignItemHeader: css({ fontWeight: 600, fontSize: '14px', marginBottom: '8px' }),
  campaignTileHeader: css({ fontWeight: 600, fontSize: '22px', marginBottom: '8px' }),
  campaignItemDescription: css({ fontWeight: 400, fontSize: '12px', lineHeight: '140%' }),
  campaignTileDescription: css({ fontWeight: 400, fontSize: '14px', lineHeight: '140%' }),
  campaignTile: css({ display: 'flex', placeContent: 'center', flexDirection: 'column' }),
  pageGrid: css({
    display: 'grid',
    padding: '40px',
    gap: '12px 24px',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: THEME.background.colors.white
  })
};
