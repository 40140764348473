import {
  AnalyticsInstagramInteractionPosts,
  AnalyticsInstagramInteractionOverview,
  AnalyticsInstagramInteractionBrandAmbassadors
} from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';
import { FreePlanContentOpacity } from '../../shared/AnalyticsUpgradePlan';
import { interactionMock } from './interaction-mock';

export const AnalyticsInstagramInteraction = () => {
  const {
    selectedSocialAccount,
    brandAccount: { brandAccountName, subscriptionPlan }
  } = useAnalyticsProfileContext();
  const hasFreePlan = subscriptionPlan.availableFeatures?.interactionEnabled === false;

  return (
    <FreePlanContentOpacity hasFreePlan={hasFreePlan} tab="interaction-ig">
      <AnalyticsInstagramInteractionOverview
        analyticsAccount={selectedSocialAccount}
        hasFreePlan={hasFreePlan}
        mockOverview={interactionMock.overview}
      />
      <AnalyticsInstagramInteractionBrandAmbassadors
        brandAccountName={brandAccountName}
        analyticsAccount={selectedSocialAccount}
        hasFreePlan={hasFreePlan}
        mockBrandAmbassadorsPosts={interactionMock.brandAmbassadorPosts}
      />
      <AnalyticsInstagramInteractionPosts
        brandAccountName={brandAccountName}
        analyticsAccount={selectedSocialAccount}
        hasFreePlan={hasFreePlan}
        mockTaggedPosts={interactionMock.taggedPosts}
        mockMentionedPosts={interactionMock.mentionedPosts}
      />
    </FreePlanContentOpacity>
  );
};
