import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { Radio } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatNumber } from '@/shared/utils';
import { YoutubeCmsConfirmedChannelSummary, YoutubeCmsEstimateChannelSummary } from '@/graphql';
import { YoutubeCMSRevenueType, YoutubeCMSSegmentMode } from '../types';

export interface YoutubeCMSSummaryProps {
  className?: string;
  type: YoutubeCMSRevenueType;
  segment?: YoutubeCMSSegmentMode;
  data?: YoutubeCmsConfirmedChannelSummary &
    Partial<Pick<YoutubeCmsEstimateChannelSummary, 'rpm' | 'taRpm' | 'taRevenue' | 'taProfit'>>;
  onSegmentChange?: (segment: YoutubeCMSSegmentMode) => void;
}

export const YoutubeCMSSummary = ({ data, type, segment, className, onSegmentChange }: YoutubeCMSSummaryProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();

  const currency = t(data?.currency || '');
  const isConfirmedRevenue = type === YoutubeCMSRevenueType.CONFIRMED_REVENUE;
  const summaries = [
    {
      unit: currency,
      value: formatNumber(data?.rpm),
      label: t('HeaderColumn.Estimated RPM'),
      hidden: !isAdminStaff || isConfirmedRevenue
    },
    {
      unit: currency,
      hidden: isConfirmedRevenue,
      value: formatNumber(data?.taRpm),
      label: t('HeaderColumn.Estimated talent agency RPM')
    },
    {
      unit: currency,
      hidden: !isAdminStaff,
      value: formatNumber(data?.partnerRevenue),
      label: t(isConfirmedRevenue ? 'HeaderColumn.Confirmed partner revenue' : 'HeaderColumn.Estimated partner revenue')
    },
    {
      unit: currency,
      hidden: isConfirmedRevenue,
      value: formatNumber(data?.taRevenue),
      label: t('HeaderColumn.Estimated talent agency revenue')
    },
    {
      unit: currency,
      value: formatNumber(data?.shareCreator),
      label: t(
        isConfirmedRevenue ? 'HeaderColumn.Confirmed share to creator' : 'HeaderColumn.Estimated share to creator'
      )
    },
    {
      unit: currency,
      hidden: !isAdminStaff,
      value: formatNumber(data?.profit),
      label: t(isConfirmedRevenue ? 'HeaderColumn.Confirmed gross profit' : 'HeaderColumn.Estimated GP')
    },
    {
      unit: currency,
      hidden: isConfirmedRevenue,
      value: formatNumber(data?.taProfit),
      label: t('HeaderColumn.Estimated talent agency GP')
    }
  ].filter((summary) => !summary.hidden);

  return (
    <div css={styles.wrapper} className={className}>
      <div css={{ gap: '16px 0', display: 'flex', flexWrap: 'wrap' }}>
        {summaries.map((summary, index) => (
          <div key={index} css={styles.summaryWrapper}>
            <div css={{ fontSize: '12px', lineHeight: '20px', textTransform: 'uppercase' }}>{summary.label}</div>
            <div css={{ display: 'flex', alignItems: 'baseline', paddingRight: '12px', lineHeight: '24px' }}>
              <div css={{ fontSize: '20px' }}>{summary.value}</div>
              {!!summary.unit && <div css={{ marginLeft: '6px', fontSize: '12px' }}>{summary.unit}</div>}
            </div>
          </div>
        ))}
      </div>

      {segment && onSegmentChange ? (
        <div css={styles.segmentWrapper}>
          <p>{t('Segment')}:</p>
          <Radio
            label={t('Show by channel')}
            checked={segment === YoutubeCMSSegmentMode.CHANNELS}
            onClick={() => onSegmentChange(YoutubeCMSSegmentMode.CHANNELS)}
          />
          <Radio
            label={t('Show by asset')}
            checked={segment === YoutubeCMSSegmentMode.ASSETS}
            onClick={() => onSegmentChange(YoutubeCMSSegmentMode.ASSETS)}
          />
        </div>
      ) : null}
    </div>
  );
};
const styles = {
  wrapper: css({
    padding: '24px 16px',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white,
    borderTop: `1px solid ${THEME.border.colors.gray.lv1}`
  }),
  summaryWrapper: css({
    flexBasis: '25%',
    fontWeight: 600,
    padding: '0 12px',
    boxSizing: 'border-box',
    borderLeft: '2px solid #dee5ec'
  }),
  segmentWrapper: css({
    gap: '16px',
    display: 'flex',
    marginTop: '24px',
    alignItems: 'center',
    alignSelf: 'flex-end'
  })
};
