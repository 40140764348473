import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdatePackageProposalMode.graphql';
import { Mutation, MutationupdatePackageProposalModeArgs } from '../../__generated__/globalTypes';

export const useUpdatePackageProposalModeMutation = (
  options?: MutationHookOptions<Mutation, MutationupdatePackageProposalModeArgs>
) => {
  const [callUpdatePackageProposalMode, result] = useMutation<Mutation, MutationupdatePackageProposalModeArgs>(
    MUTATION,
    options
  );

  return { callUpdatePackageProposalMode, ...result };
};

export type UpdatePackageProposalModeMutationFunction = Mutation['updatePackageProposalMode'];
