import { namedOperations, useCreateTiktokAdAccountMutation } from '@/graphql';
import { Button, Modal, FormProvider } from '@/shared/atoms';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useToggle } from '@/shared/hooks';
import { FormAction } from '@/shared/types';
import { Template } from './Template';
import { CreateAdAccountFormValues, CreateAdAccountProps } from './types';
import { CreateAdAccountSchema } from './schema';

export const CreateAdAccount = ({ className, bcList, hasAccountsLimitWarning }: CreateAdAccountProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { on, setOn, setOff } = useToggle();
  const { callCreateTiktokAdAccount, loading } = useCreateTiktokAdAccountMutation({
    refetchQueries: [namedOperations.Query.TiktokBusinessAdAccounts]
  });

  let reset: () => void | undefined;

  const handleSubmit: FormAction<CreateAdAccountFormValues>['onSubmit'] = async (
    { businessCenterId, industryId, adAccountName, adAccountCompanyName, timezone, locationCode, promotionLink },
    methods
  ) => {
    reset = methods.reset;

    try {
      await callCreateTiktokAdAccount({
        variables: {
          input: {
            bcId: businessCenterId,
            industryId: Number(industryId),
            company: adAccountCompanyName,
            name: adAccountName,
            promotionLink,
            timezone,
            registeredArea: locationCode
          }
        }
      });

      enqueueSnackbar(t('succeededInCreating', { name: adAccountName }), { variant: 'success' });
      setOff();
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <>
      <Button
        variant="white"
        title={t('Button.Create New')}
        onClick={setOn}
        className={className}
        css={{ padding: '0 24px', width: 'max-content' }}
        disabled={hasAccountsLimitWarning}
      />
      <Modal
        open={on}
        css={{ width: THEME.modal.size.lv1 }}
        onClose={setOff}
        onAfterClose={() => reset?.()}
        shouldCloseOnOverlayClick
        hasCloseIcon
      >
        <FormProvider<CreateAdAccountFormValues>
          onSubmit={handleSubmit}
          zodSchema={CreateAdAccountSchema}
          defaultValues={{
            industryId: '',
            businessCenterId: '',
            adAccountCompanyName: '',
            adAccountName: '',
            promotionLink: '',
            timezone: '',
            locationCode: 'TH'
          }}
        >
          <Template onClose={setOff} loading={loading} bcList={bcList} />
        </FormProvider>
      </Modal>
    </>
  );
};
