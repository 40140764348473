import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/FacebookPageAnalyticsPost.graphql';
import { Query, QueryfacebookPageAnalyticsPostArgs } from '../../__generated__/globalTypes';

export const useFacebookPageAnalyticsPostQuery = (
  options?: QueryHookOptions<Query, QueryfacebookPageAnalyticsPostArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryfacebookPageAnalyticsPostArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useFacebookPageAnalyticsPostLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryfacebookPageAnalyticsPostArgs>
): QueryResult<Query, QueryfacebookPageAnalyticsPostArgs> & {
  getFacebookPageAnalyticsPost: LazyQueryExecFunction<Query, QueryfacebookPageAnalyticsPostArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getFacebookPageAnalyticsPost, result] = useLazyQuery<Query, QueryfacebookPageAnalyticsPostArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getFacebookPageAnalyticsPost, ...result };
};

export const useFacebookPageAnalyticsPostPromiseQuery = () => {
  const client = useApolloClient();

  const getFacebookPageAnalyticsPost = useCallback(
    (options?: Omit<QueryOptions<QueryfacebookPageAnalyticsPostArgs, Query>, 'query'>) =>
      client.query<Query, QueryfacebookPageAnalyticsPostArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getFacebookPageAnalyticsPost };
};
