import { z } from 'zod';
import { BaseValidations } from '@/shared/validations';

export const TikTokSignupFormSchema = z.object({
  email: BaseValidations.EmailSchema,
  name: z.string(),
  countryId: z.string(),
  companyUrl: z.string(),
  companyName: z.string(),
  phoneNumber: BaseValidations.PhoneNumberSchema,
  acceptTerms: z.boolean()
});
