import { ReactNode, createContext, useContext, useRef, useState } from 'react';
import { ResizableProps } from 're-resizable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useSensor, DndContext, useSensors, MouseSensor, TouchSensor, PointerSensor } from '@dnd-kit/core';
import { useDeepCompareEffect } from '@/shared/hooks';
import { ChatBoxRndCoordinates } from './utils';
import { ChatBoxCoordinates, ChatBoxSize } from './types';

interface ContextType {
  draggable?: boolean;
  chatBoxSize: ChatBoxSize | undefined;
  chatBoxCoordinates: ChatBoxCoordinates | null;
  resizable?: boolean | ResizableProps['enable'];
  rndItemRef: ReturnType<typeof useRef<HTMLElement | null>>;
  setChatBoxSize: React.Dispatch<React.SetStateAction<ChatBoxSize | undefined>>;
  setChatBoxCoordinates: React.Dispatch<React.SetStateAction<ChatBoxCoordinates | null>>;
}

const ChatBoxRndContext = createContext<ContextType>({} as ContextType);

export const useChatBoxRndContext = () => useContext(ChatBoxRndContext);

export interface ChatBoxRndProviderProps extends Pick<ContextType, 'resizable' | 'draggable'> {
  children: ReactNode;
}

export const ChatBoxRndProvider = ({ children, resizable, draggable }: ChatBoxRndProviderProps) => {
  const rndItemRef = useRef<HTMLElement | null>(null);
  const [chatBoxSize, setChatBoxSize] = useState<ChatBoxSize | undefined>(undefined);
  const [chatBoxCoordinates, setChatBoxCoordinates] = useState<ChatBoxCoordinates | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } })
  );

  // Init chatBoxCoordinates
  useDeepCompareEffect(() => {
    // Must get latest rect
    const rect = rndItemRef.current?.getBoundingClientRect();

    if (!chatBoxCoordinates && rect) {
      setChatBoxCoordinates(new ChatBoxRndCoordinates(rect).getNearestEgdes());
    }
  }, [chatBoxCoordinates]);

  return (
    <ChatBoxRndContext.Provider
      value={{
        rndItemRef,
        chatBoxSize,
        chatBoxCoordinates,
        resizable: resizable && !!rndItemRef.current, // Element must ready before resizing
        draggable: draggable && !!rndItemRef.current, // Element must ready before dragging
        setChatBoxSize,
        setChatBoxCoordinates
      }}
    >
      <DndContext
        sensors={sensors}
        modifiers={[restrictToWindowEdges]}
        onDragEnd={() => {
          // Must get latest rect where drag end
          const rect = rndItemRef.current?.getBoundingClientRect();

          if (rect) {
            setChatBoxCoordinates(new ChatBoxRndCoordinates(rect).getNearestEgdes());
          }
        }}
      >
        {children}
      </DndContext>
    </ChatBoxRndContext.Provider>
  );
};
