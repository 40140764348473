import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateEngagementPostV2.graphql';
import { Mutation, MutationcreateEngagementPostV2Args } from '../../__generated__/globalTypes';

export const useCreateEngagementPostV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationcreateEngagementPostV2Args>
) => {
  const [callCreateEngagementPostV2, result] = useMutation<Mutation, MutationcreateEngagementPostV2Args>(
    MUTATION,
    options
  );

  return { callCreateEngagementPostV2, ...result };
};

export type CreateEngagementPostV2MutationFunction = Mutation['createEngagementPostV2'];
