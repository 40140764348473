import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerTwitterAnalyticsOverview.graphql';
import { Query, QueryinfluencerTwitterAnalyticsOverviewArgs } from '../../__generated__/globalTypes';

export const useInfluencerTwitterAnalyticsOverviewQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerTwitterAnalyticsOverviewArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerTwitterAnalyticsOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerTwitterAnalyticsOverviewLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerTwitterAnalyticsOverviewArgs>
): QueryResult<Query, QueryinfluencerTwitterAnalyticsOverviewArgs> & {
  getInfluencerTwitterAnalyticsOverview: LazyQueryExecFunction<Query, QueryinfluencerTwitterAnalyticsOverviewArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerTwitterAnalyticsOverview, result] = useLazyQuery<
    Query,
    QueryinfluencerTwitterAnalyticsOverviewArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerTwitterAnalyticsOverview, ...result };
};

export const useInfluencerTwitterAnalyticsOverviewPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerTwitterAnalyticsOverview = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerTwitterAnalyticsOverviewArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerTwitterAnalyticsOverviewArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerTwitterAnalyticsOverview };
};
