import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteEngagementProposalJoinedAccount.graphql';
import { Mutation, MutationdeleteEngagementProposalJoinedAccountArgs } from '../../__generated__/globalTypes';

export const useDeleteEngagementProposalJoinedAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteEngagementProposalJoinedAccountArgs>
) => {
  const [callDeleteEngagementProposalJoinedAccount, result] = useMutation<
    Mutation,
    MutationdeleteEngagementProposalJoinedAccountArgs
  >(MUTATION, options);

  return { callDeleteEngagementProposalJoinedAccount, ...result };
};

export type DeleteEngagementProposalJoinedAccountMutationFunction = Mutation['deleteEngagementProposalJoinedAccount'];
