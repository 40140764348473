import { SocialIcon } from '@/shared/atoms';
import { TextField, TextFieldProps } from '@/shared/molecules';
import { SupportedSocialMediaType } from '@/shared/types';

export interface SocialMediaInputFieldProps<T extends string = string> extends TextFieldProps<T> {
  socialMedia: keyof typeof SupportedSocialMediaType;
}

export const SocialMediaInputField = <T extends string>({
  title,
  socialMedia,
  ...restProps
}: SocialMediaInputFieldProps<T>) => (
  <TextField<T>
    {...restProps}
    title={
      title ? (
        <>
          <SocialIcon type={socialMedia} css={{ marginRight: '4px' }} />
          {title}
        </>
      ) : null
    }
    triggerFormRevalidation
  />
);
