import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { Option } from '@/shared/types';
import { ColumnProps } from '@/shared/atoms';
import { ColumnCustomiser } from '@/shared/molecules';
import { TiktokSpecialCampaignReport, TikTokSpecialReportOrderField } from '@/graphql';
import { TikTokCampaignReportTableStatKeys } from '../types';
import { useTikTokCampaignReportContext } from '../../TikTokCampaignReportContext';
import { DEFAULT_TIKTOK_CAMPAIGN_COLUMNS, POST_REPORT_STICKY_COL_DIMS } from '../utils';

type CustomColumnProps = ColumnProps<TikTokSpecialReportOrderField, TikTokCampaignReportTableStatKeys>;

interface Props {
  data: TiktokSpecialCampaignReport;
}

export const useTikTokCampaignReportColumns = ({ data: { postCount } }: Props) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { selectedColumns, setSelectedColumns } = useTikTokCampaignReportContext();

  const getMetricColumnProps = ({ key, hidden, styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    key,
    styles: { textAlign: 'right', ...styles },
    hidden: hidden || (!!key && !selectedColumns.includes(key))
  });

  const allReportMetrics: CustomColumnProps[] = [
    {
      key: 'cost',
      title: t('HeaderColumn.Cost'),
      help: t<string>('Tooltip.costHelp'),
      sortField: TikTokSpecialReportOrderField.COST,
      hidden: !isAdminStaff
    },
    {
      key: 'followers',
      title: t('HeaderColumn.Follower'),
      help: t<string>('Tooltip.UniqueReachHelp'),
      sortField: TikTokSpecialReportOrderField.FOLLOWERS
    },
    {
      key: 'engagement',
      title: t('HeaderColumn.Engagement'),
      help: t<string>('Tooltip.TotalEngagementsHelp'),
      sortField: TikTokSpecialReportOrderField.ENGAGEMENT
    },
    {
      key: 'engagementRate',
      help: t<string>('Tooltip.engagementRateHelp'),
      title: t('HeaderColumn.Egg Rate by followers'),
      sortField: TikTokSpecialReportOrderField.ENGAGEMENT_RATE
    },
    {
      key: 'engagementViewsRate',
      title: t('HeaderColumn.Egg Rate by views'),
      help: t<string>('Tooltip.engagementViewsRateHelp'),
      sortField: TikTokSpecialReportOrderField.ENGAGEMENT_VIEWS_RATE
    },
    {
      key: 'views',
      title: t('HeaderColumn.Video view'),
      help: t<string>('Tooltip.videoViewHelp'),
      sortField: TikTokSpecialReportOrderField.VIEWS
    },
    {
      key: 'adImpressions',
      title: t('HeaderColumn.Ad Impression'),
      help: t<string>('Tooltip.adImpressionHelp'),
      sortField: TikTokSpecialReportOrderField.AD_IMPRESSIONS
    },

    {
      key: 'adImpressionsRate',
      title: t('HeaderColumn.Ad Impression Rate'),
      help: t<string>('Tooltip.adImpressionsRateHelp'),
      sortField: TikTokSpecialReportOrderField.AD_IMPRESSIONS_RATE
    },
    {
      key: 'adReach',
      title: t('HeaderColumn.Ad Reach'),
      help: t<string>('Tooltip.adReachHelp'),
      sortField: TikTokSpecialReportOrderField.AD_REACH
    },
    {
      key: 'adReachRate',
      title: t('HeaderColumn.Ad Reach Rate'),
      help: t<string>('Tooltip.adReachRateHelp'),
      sortField: TikTokSpecialReportOrderField.AD_REACH_RATE
    },
    {
      key: 'likes',
      title: t('HeaderColumn.Like'),
      help: t<string>('Tooltip.LikeReactionHelp'),
      sortField: TikTokSpecialReportOrderField.LIKES
    },
    {
      key: 'comments',
      title: t('HeaderColumn.Comment'),
      help: t<string>('Tooltip.commentHelp'),
      sortField: TikTokSpecialReportOrderField.COMMENTS
    },
    {
      key: 'shares',
      title: t('HeaderColumn.Share'),
      help: t<string>('Tooltip.shareHelp'),
      sortField: TikTokSpecialReportOrderField.SHARES
    },
    {
      key: 'costsPerFollower',
      title: t('HeaderColumn.Cost / Follower'),
      sortField: TikTokSpecialReportOrderField.COSTS_PER_FOLLOWER,
      hidden: !isAdminStaff
    },
    {
      key: 'costsPerView',
      title: t('HeaderColumn.Cost / Video view'),
      sortField: TikTokSpecialReportOrderField.COSTS_PER_VIEW,
      hidden: !isAdminStaff
    },
    {
      key: 'costsPerEngagement',
      title: t('HeaderColumn.Cost / Engagement'),
      sortField: TikTokSpecialReportOrderField.COSTS_PER_ENGAGEMENT,
      hidden: !isAdminStaff
    },
    {
      key: 'costsPerLike',
      title: t('HeaderColumn.Cost / Like'),
      sortField: TikTokSpecialReportOrderField.COSTS_PER_LIKE,
      hidden: !isAdminStaff
    },
    {
      key: 'costsPerComment',
      title: t('HeaderColumn.Cost / Comment'),
      sortField: TikTokSpecialReportOrderField.COSTS_PER_COMMENT,
      hidden: !isAdminStaff
    },
    {
      key: 'costsPerShare',
      title: t('HeaderColumn.Cost / Share'),
      sortField: TikTokSpecialReportOrderField.COSTS_PER_SHARE,
      hidden: !isAdminStaff
    }
  ];
  const metricColumns: CustomColumnProps[] = allReportMetrics.map(getMetricColumnProps);
  const selectableColumns = allReportMetrics.reduce<Option<TikTokCampaignReportTableStatKeys>[]>(
    (acc, curr) =>
      curr.hidden
        ? acc
        : [...acc, { label: curr.title as string, value: curr.key as TikTokCampaignReportTableStatKeys }],
    []
  );

  const handleSelectColumns = (newSelectedColumns: TikTokCampaignReportTableStatKeys[]) => {
    const orderedSelectedColumns = metricColumns.reduce<TikTokCampaignReportTableStatKeys[]>(
      (results, metric) => (metric.key && newSelectedColumns.includes(metric.key) ? [...results, metric.key] : results),
      []
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const postColumn: CustomColumnProps = {
    title: (
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {t('HeaderColumn.Total Post')}

        <ColumnCustomiser<TikTokCampaignReportTableStatKeys>
          options={selectableColumns}
          defaultOptions={selectedColumns}
          savedValuesKey="reportTableColumns"
          initialValues={DEFAULT_TIKTOK_CAMPAIGN_COLUMNS}
          setSelectedColumns={handleSelectColumns}
          position={postCount < 6 ? 'right-end' : 'right-start'}
        />
      </div>
    ),
    sticky: { active: true, hasBorderRight: true },
    styles: { width: POST_REPORT_STICKY_COL_DIMS.totalWidth }
  };

  return { columns: [postColumn, ...metricColumns] };
};
