import { z } from 'zod';
import { PostListSortField, SocialPostType } from '@/graphql';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { LIMIT } from '@/shared/constants';

export const InfluencerScrapingPostsListSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(
    z.object({
      type: FilterValidations.enum(SocialPostType),
      keyword: FilterValidations.string().optional(),
      name: FilterValidations.string().optional()
    }),
    {
      type: '',
      keyword: '',
      name: ''
    }
  ),
  sort: FilterValidations.sort(PostListSortField).default({ field: null, order: null }),
  limit: z.number().default(LIMIT),
  page: z.number().default(1)
});

export type InfluencerScrapingPostsListSearchSchemaType = z.infer<typeof InfluencerScrapingPostsListSearchSchema>;
