import { Outlet } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { PermissionRoute } from '@/app-components';
import {
  adminStaffAgencyAdvertiserTalentAgencyPartnerRoles,
  adminStaffRoles,
  adminStaffTalentAgencyPartnerRoles
} from '@/shared/constants';
import {
  InfluencerAccountSearchSchema,
  InfluencerFacebookPostsListSearchSchema,
  InfluencerInstagramPostsListSearchSchema,
  InfluencerInstagramReelPostsListSearchSchema,
  InfluencerInstagramStoryPostsSearchSchema,
  InfluencerProfileCampaignsSearchSchema,
  InfluencerProfileCompareSearchSchema,
  InfluencerScrapingPostsListSearchSchema,
  InfluencerSponsoredPostsListSearchSchema,
  InfluencerTwitterPostsSearchSchema,
  InfluencerYtSponsoredPostsListSearchSchema,
  TalentUserAddLocationSearchStateSchema,
  YtTagRankingSearchSchema,
  YtVideosSearchSchema
} from '@/shared/organisms';
import {
  AddInfluencer,
  CompareAccountTemplate,
  InfluencerAccountProfile,
  InfluencerDetails,
  InfluencerFacebookPosts,
  InfluencerInstagramPosts,
  InfluencerInstagramReelPosts,
  InfluencerInstagramStoryPosts,
  InfluencerProfile,
  InfluencerProfileCampaigns,
  InfluencerProfileCompare,
  InfluencerScrapingPosts,
  InfluencerSponsoredPosts,
  InfluencerTwitterPosts,
  InfluencerXhsPicturePosts,
  InfluencerXhsPostsSearchSchema,
  InfluencerXhsVideoPosts,
  InfluencerYoutubeCompareMainSearchSchema,
  InfluencerYoutubeCompareVideos,
  InfluencerYoutubeShorts,
  InfluencerYoutubeShortsSearchSchema,
  InfluencerYoutubeSponsoredPosts,
  InfluencerYoutubeTagsRanking,
  InfluencerYoutubeVideos,
  YouTubeCompareVideosMainAccount
} from '@/pages/InfluencerDetails';
import { PartialRouter } from '../../types';

export const InfluencerRouter = {
  '/_private-routes/influencer': [() => <Outlet />, {}],
  '/_private-routes/influencer/': [() => <PermissionRoute permissions={adminStaffTalentAgencyPartnerRoles} />, {}],
  '/_private-routes/influencer/$id': [
    () => <Outlet />,
    { validateSearch: zodValidator(TalentUserAddLocationSearchStateSchema) }
  ],
  '/_private-routes/influencer/$id/': [
    () => <PermissionRoute element={<InfluencerDetails />} permissions={adminStaffTalentAgencyPartnerRoles} />,
    {}
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/facebook_feed_post': [
    InfluencerFacebookPosts,
    { validateSearch: zodValidator(InfluencerFacebookPostsListSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/feed_videos': [
    InfluencerYoutubeVideos,
    { validateSearch: zodValidator(YtVideosSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_feed_post': [
    InfluencerInstagramPosts,
    { validateSearch: zodValidator(InfluencerInstagramPostsListSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_reel_post': [
    InfluencerInstagramReelPosts,
    { validateSearch: zodValidator(InfluencerInstagramReelPostsListSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_story_post': [
    InfluencerInstagramStoryPosts,
    { validateSearch: zodValidator(InfluencerInstagramStoryPostsSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/scraping_feed_posts': [
    InfluencerScrapingPosts,
    { validateSearch: zodValidator(InfluencerScrapingPostsListSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/shorts': [
    InfluencerYoutubeShorts,
    { validateSearch: zodValidator(InfluencerYoutubeShortsSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_feed_posts': [
    InfluencerSponsoredPosts,
    { validateSearch: zodValidator(InfluencerSponsoredPostsListSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts': [
    InfluencerYoutubeSponsoredPosts,
    { validateSearch: zodValidator(InfluencerYtSponsoredPostsListSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/twitter_feed_posts': [
    InfluencerTwitterPosts,
    { validateSearch: zodValidator(InfluencerTwitterPostsSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_picture_posts': [
    InfluencerXhsPicturePosts,
    { validateSearch: zodValidator(InfluencerXhsPostsSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_video_posts': [
    InfluencerXhsVideoPosts,
    { validateSearch: zodValidator(InfluencerXhsPostsSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos': [
    () => (
      <PermissionRoute permissions={adminStaffTalentAgencyPartnerRoles} element={<InfluencerYoutubeCompareVideos />} />
    ),
    {}
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/': [
    () => <YouTubeCompareVideosMainAccount />,
    { validateSearch: zodValidator(InfluencerYoutubeCompareMainSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId': [
    () => <CompareAccountTemplate />,
    { validateSearch: zodValidator(InfluencerYoutubeCompareMainSearchSchema) }
  ],
  '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_tags_ranking': [
    InfluencerYoutubeTagsRanking,
    { validateSearch: zodValidator(YtTagRankingSearchSchema) }
  ],
  '/_private-routes/influencer/$id/profile': [
    () => (
      <PermissionRoute
        permissions={adminStaffAgencyAdvertiserTalentAgencyPartnerRoles}
        element={<InfluencerProfile />}
      />
    ),
    {}
  ],
  '/_private-routes/influencer/$id/profile/': [
    InfluencerAccountProfile,
    { validateSearch: zodValidator(InfluencerAccountSearchSchema) }
  ],
  '/_private-routes/influencer/$id/profile/compare': [
    () => <PermissionRoute element={<InfluencerProfileCompare />} permissions={adminStaffTalentAgencyPartnerRoles} />,
    { validateSearch: zodValidator(InfluencerProfileCompareSearchSchema) }
  ],
  '/_private-routes/influencer/$id/profile/campaigns': [
    () => <PermissionRoute permissions={adminStaffRoles} element={<InfluencerProfileCampaigns />} />,
    { validateSearch: zodValidator(InfluencerProfileCampaignsSearchSchema) }
  ],
  '/_private-routes/influencer/add': [
    () => <PermissionRoute element={<AddInfluencer />} permissions={adminStaffTalentAgencyPartnerRoles} />,
    { validateSearch: zodValidator(TalentUserAddLocationSearchStateSchema) }
  ]
} as const satisfies PartialRouter;
