import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PostDetailsType, SupportedSocialMediaType } from '@/shared/types';

export interface PostCommentsProps {
  className?: string;
  comments?: PostDetailsType['commentsContent'];
  socialMedia?: keyof typeof SupportedSocialMediaType;
}

export const PostComments = ({ comments = [], className, socialMedia }: PostCommentsProps) => {
  const { t } = useTranslation();

  return comments.length > 0 ? (
    <div css={styles.wrapper} className={className}>
      <p css={styles.title}>
        {t(socialMedia === SupportedSocialMediaType.INSTAGRAM_STORY ? 'General.Replies' : 'General.Comments')}
      </p>

      <div css={styles.commentWrapper}>
        {comments.map((comment, index) => (
          <div key={`comment-${index}`} css={{ display: 'flex', padding: '8px' }}>
            <TextCutter
              text={comment.username || t('no username')}
              css={{ width: '72px', fontWeight: 600, fontSize: '12px' }}
            />
            <p css={styles.comment}>{comment.content}</p>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
const styles = {
  wrapper: css({
    flex: '1 1 0',
    overflow: 'hidden',
    borderRadius: '3px',
    backgroundColor: THEME.background.colors.gray.lv1
  }), 
  title: css({
    fontSize: '10px',
    fontWeight: 400,
    color: THEME.text.colors.gray.lv3,
    padding: '8px 8px 0 8px'
  }),
  commentWrapper: css({
    flex: '1 1 0',
    marginTop: '8px',
    overflowY: 'auto',
    height: 'calc(100% - 32px)'
  }),
  comment: css({
    fontSize: '12px',
    marginLeft: '10px',
    lineHeight: '17px',
    width: 'fit-content',
    wordBreak: 'break-all',
    color: THEME.text.colors.black.lv1
  })
};
