import { z } from 'zod';

import { BaseValidations } from '@/shared/validations';

export const PackageFormSchema = z.object({
  id: z.number().optional(),
  companies: z.array(z.string()),
  name: BaseValidations.PackageNameSchema,
  countryId: BaseValidations.CountryIdSchema
});
