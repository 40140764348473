import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllTiktokAdsReport.graphql';
import { Query, QueryallTiktokAdsReportArgs } from '../../__generated__/globalTypes';

export const useAllTiktokAdsReportQuery = (options?: QueryHookOptions<Query, QueryallTiktokAdsReportArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallTiktokAdsReportArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllTiktokAdsReportLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallTiktokAdsReportArgs>
): QueryResult<Query, QueryallTiktokAdsReportArgs> & {
  getAllTiktokAdsReport: LazyQueryExecFunction<Query, QueryallTiktokAdsReportArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllTiktokAdsReport, result] = useLazyQuery<Query, QueryallTiktokAdsReportArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllTiktokAdsReport, ...result };
};

export const useAllTiktokAdsReportPromiseQuery = () => {
  const client = useApolloClient();

  const getAllTiktokAdsReport = useCallback(
    (options?: Omit<QueryOptions<QueryallTiktokAdsReportArgs, Query>, 'query'>) =>
      client.query<Query, QueryallTiktokAdsReportArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllTiktokAdsReport };
};
