import { FormProvider } from '@/shared/atoms';
import { FormAction } from '@/shared/types';
import { Template, TemplateProps } from './Template';
import { PostInsightFormValues } from './types';

export type PostInsightFormProps = FormAction<PostInsightFormValues> & Omit<TemplateProps, 'onSubmit'>;

export const PostInsightForm = ({ onSubmit, defaultValues, ...templateProps }: PostInsightFormProps) => (
  <FormProvider values={defaultValues} onSubmit={onSubmit}>
    <Template {...templateProps} />
  </FormProvider>
);

export * from './types';
