import { ChartsContainer } from '@/shared/atoms';
import { getValuableVariables } from '@/shared/utils';
import { useFacebookPageAnalyticsAudienceQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { AgeBreakdownChart, AreaBreakdownChart, GenderBreakdownChart } from '@/shared/molecules';
import { AnalyticsAudienceWrapper } from '../../shared';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { useAnalyticsProfileSearch } from '../../hooks';

export interface AnalyticsFacebookDashboardAudienceProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsFacebookDashboardAudience = ({ analyticsAccount }: AnalyticsFacebookDashboardAudienceProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useFacebookPageAnalyticsAudienceQuery({
    fetchPolicy: 'cache-first',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      facebookPageAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsAudienceWrapper loading={loading} data={data?.facebookPageAnalyticsAudience}>
      {({ data: { ageRate, genderRate, regionRate } }) => (
        <ChartsContainer css={{ height: '385px' }}>
          <GenderBreakdownChart {...genderRate} />
          <AgeBreakdownChart {...ageRate} />
          <AreaBreakdownChart avatar={analyticsAccount.avatar} followerRegionRate={regionRate} />
        </ChartsContainer>
      )}
    </AnalyticsAudienceWrapper>
  );
};
