import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { EngagementDetail, SocialAccountType } from '@/graphql';
import { EngagementPostFormValues } from '../types';
import { DefaultTemplate } from './DefaultTemplate';
import { TTCMTemplate } from './TTCMTemplate';

export interface TemplateProps {
  onClose: () => void;
  onSubmit?: () => void;
  engagement: EngagementDetail;
  initialValues: EngagementPostFormValues;
}
export const Template = ({ engagement: { id: engagementId, isTtcmCampaign }, ...restProps }: TemplateProps) => {
  const { watch, setValue } = useFormContext<EngagementPostFormValues>();

  const socialAccountMediaValue = watch('socialAccountMedia');
  const isTtcmForm = isTtcmCampaign && socialAccountMediaValue === SocialAccountType.TIKTOK;

  useEffect(() => {
    setValue('isTtcmForm', isTtcmForm);
  }, [isTtcmForm]);

  return isTtcmForm ? (
    <TTCMTemplate engagementId={engagementId} {...restProps} />
  ) : (
    <DefaultTemplate engagementId={engagementId} {...restProps} />
  );
};
