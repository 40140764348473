import { InfluencerNewProfileHeaderV2, InfluencerRateCardPayload } from '@/graphql';
import { SocialIcon } from '@/shared/atoms';
import { Option, SupportedSocialMediaType } from '@/shared/types';

const getSnsFollowers = (accounts?: readonly { followersCount?: number | null }[] | null) =>
  accounts?.reduce((acc, curr) => acc + (curr.followersCount ?? 0), 0) || 0;

export const getSnsFollowerOptions = ({
  xhsAccounts,
  facebookPages,
  tiktokAccounts,
  douyinAccounts,
  twitterAccounts,
  youtubeAccounts,
  threadsAccounts,
  facebookAccount,
  instagramAccounts
}: InfluencerNewProfileHeaderV2): Option<number | string, keyof typeof SupportedSocialMediaType>[] => {
  const facebookPageFollowers = getSnsFollowers(facebookPages);
  const facebookFollowers = getSnsFollowers(facebookAccount ? [facebookAccount] : []);
  const xiaohongshuFollowers = xhsAccounts?.length ? '-' : 0;

  return [
    {
      prefixIcon: <SocialIcon size="16px" type="FACEBOOK" />,
      value: facebookFollowers + facebookPageFollowers || xiaohongshuFollowers
    },
    {
      prefixIcon: <SocialIcon size="16px" type="INSTAGRAM" />,
      value: getSnsFollowers(instagramAccounts) || xiaohongshuFollowers
    },
    {
      prefixIcon: <SocialIcon size="16px" type="TWITTER" />,
      value: getSnsFollowers(twitterAccounts) || xiaohongshuFollowers
    },
    {
      prefixIcon: <SocialIcon size="16px" type="YOUTUBE" />,
      value: getSnsFollowers(youtubeAccounts) || xiaohongshuFollowers
    },
    {
      prefixIcon: <SocialIcon size="16px" type="TIKTOK" />,
      value: getSnsFollowers(tiktokAccounts) || xiaohongshuFollowers
    },
    {
      prefixIcon: <SocialIcon size="16px" type="THREADS" />,
      value: getSnsFollowers(threadsAccounts)
    },
    { prefixIcon: <SocialIcon size="16px" type="DOUYIN" />, value: getSnsFollowers(douyinAccounts) },
    { prefixIcon: <SocialIcon size="16px" type="XHS" />, value: getSnsFollowers(xhsAccounts) }
  ].filter((value) => !!value);
};
export const getPostPriceOptions = (
  data?: InfluencerRateCardPayload | null
): Option<number | string, keyof typeof SupportedSocialMediaType>[] => {
  if (!data) return [];

  return [
    { prefixIcon: <SocialIcon size="16px" type="FACEBOOK" />, value: data.facebook?.price || '-' },
    { prefixIcon: <SocialIcon size="16px" type="TWITTER" />, value: data.twitter?.price || '-' },
    { prefixIcon: <SocialIcon size="16px" type="INSTAGRAM" />, value: data.instagram?.price || '-' },
    { prefixIcon: <SocialIcon size="16px" type="INSTAGRAM_STORY" />, value: data.instagramStory?.price || '-' },
    { prefixIcon: <SocialIcon size="16px" type="YOUTUBE" />, value: data.youtube?.price || '-' },
    { prefixIcon: <SocialIcon size="16px" type="TIKTOK" />, value: data.tiktok?.price || '-' },
    { prefixIcon: <SocialIcon size="16px" type="THREADS" />, value: data.threads?.price || '-' },
    {
      prefixIcon: <SocialIcon size="16px" type="DOUYIN" />,
      value: data.douyin.length ? data.douyin.reduce((acc, curr) => acc + curr.price, 0) / data.douyin.length : '-'
    },
    {
      prefixIcon: <SocialIcon size="16px" type="XHS" />,
      value: data.xhs.length ? data.xhs.reduce((acc, curr) => acc + curr.price, 0) / data.xhs.length : '-'
    }
  ];
};
