import {
  AuthSocialAccountType,
  AnalyticsAuthSocialAccountType,
  useAnalyticsSocialAuthRedirectUrlPromiseQuery,
  useSocialAuthRedirectUrlForInfluencerPromiseQuery,
  useTalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPromiseQuery
} from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { RedirectType, SupportedSocialMediaType } from '@/shared/types';
import { getCallbackUrl, getSocialMediaToProviderType } from '@/shared/utils';
import { useQueryHelper } from './useQueryHelper';
import { useToggleState } from './useToggleState';

interface Props {
  redirectType: keyof typeof RedirectType;
  query:
    | 'AnalyticsSocialAuthRedirectUrl'
    | 'SocialAuthRedirectUrlForInfluencer'
    | 'TalentAgencyReconnectAnalyticsSocialAuthRedirectUrl';
}

export const useGetSocialMediaRedirectUrl = ({ query, redirectType }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { status: loading, toggleStatus: toggleLoading } = useToggleState(false);
  const { getAnalyticsSocialAuthRedirectUrl } = useAnalyticsSocialAuthRedirectUrlPromiseQuery();
  const { getSocialAuthRedirectUrlForInfluencer } = useSocialAuthRedirectUrlForInfluencerPromiseQuery();
  const { getTalentAgencyReconnectAnalyticsSocialAuthRedirectUrl } =
    useTalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPromiseQuery();

  const getSocialMediaRedirectUrl = async (
    socialMedia: keyof typeof SupportedSocialMediaType,
    customProvider?: string
  ) => {
    let redirectUrl;

    try {
      toggleLoading();
      const provider = customProvider || socialMedia;
      const callbackUrl = getCallbackUrl(getSocialMediaToProviderType(socialMedia), redirectType);

      switch (query) {
        case 'AnalyticsSocialAuthRedirectUrl':
          {
            const { data, error } = await getAnalyticsSocialAuthRedirectUrl({
              variables: { callbackUrl, provider: provider as AnalyticsAuthSocialAccountType }
            });
            redirectUrl = data?.analyticsSocialAuthRedirectUrl?.redirectUri || null;

            if (error) {
              throw error;
            }
          }
          break;
        case 'SocialAuthRedirectUrlForInfluencer':
          {
            const { data, error } = await getSocialAuthRedirectUrlForInfluencer({
              variables: { input: { callbackUrl, provider: provider as AuthSocialAccountType } }
            });
            redirectUrl = data?.socialAuthRedirectUrlForInfluencer?.redirectUri || null;

            if (error) {
              throw error;
            }
          }
          break;
        case 'TalentAgencyReconnectAnalyticsSocialAuthRedirectUrl':
          {
            const { data, error } = await getTalentAgencyReconnectAnalyticsSocialAuthRedirectUrl({
              variables: { provider: provider as AnalyticsAuthSocialAccountType, callbackUrl }
            });
            redirectUrl = data?.talentAgencyReconnectAnalyticsSocialAuthRedirectUrl?.redirectUri || null;

            if (error) {
              throw error;
            }
          }
          break;
        default:
          break;
      }
    } catch (error) {
      redirectUrl = null;
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    } finally {
      toggleLoading();
    }

    return redirectUrl;
  };

  return { loading, getSocialMediaRedirectUrl };
};
