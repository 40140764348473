import { css } from '@emotion/react';
import { formatDate } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { useChannelIOApi } from 'react-channel-plugin';
import { useBillingInformationQuery, useCustomerPortalLinkQuery } from '@/graphql';
import { tiktokCheckingFailedImage } from '@/shared/assets';
import { ButtonLink } from '@/shared/atoms';
import { CURRENCY_SYMBOLS, THEME } from '@/shared/constants';
import { getExpiresCardDate, useTikTokAdvertiserSummaryData, getPlanName } from '@/shared/organisms';
import { formatPrice } from '@/shared/utils';

export const TikTokFailedPayment = () => {
  const { t } = useTranslation();
  const { summaryData } = useTikTokAdvertiserSummaryData();
  const { showMessenger } = useChannelIOApi();
  const { data } = useBillingInformationQuery();
  const { data: customerPortalLinkData } = useCustomerPortalLinkQuery({
    variables: {
      redirectUrl: window.location.href
    }
  });
  const billingData = data?.billingInformation;
  const hasBilling = billingData ? Object.values(billingData).some(Boolean) : false;

  return (
    <div css={styles.wrapper}>
      <div css={{ padding: '20px 32px 32px 32px' }}>
        <h3 css={{ fontWeight: 'bold', fontSize: '18px' }}>{t('Payment Failed')}</h3>
        <div css={{ display: 'flex' }}>
          <div css={styles.paymentNotice}>
            <p css={{ marginTop: '8px' }}>
              <Trans
                i18nKey="unableToProcessPaymentWithDate"
                components={{
                  span: <span css={{ fontWeight: 600 }}></span>
                }}
                values={{
                  date: formatDate(new Date(), 'MMMM yyyy')
                }}
              />
            </p>
            <div css={{ marginTop: '16px', color: THEME.text.colors.gray.lv3 }} onClick={showMessenger}>
              <Trans
                i18nKey="If you have any questions, please contact us"
                components={{
                  span: (
                    <span
                      css={{
                        cursor: 'pointer',
                        color: THEME.text.colors.blue,
                        '&:hover': { textDecoration: 'underline' }
                      }}
                    />
                  )
                }}
              />
            </div>
          </div>
          <img src={tiktokCheckingFailedImage} css={{ marginLeft: 'auto' }} alt="failed payment" />
        </div>

        <div css={styles.billingAndPlanSection}>
          <div>
            <h3 css={{ fontSize: '13px', fontWeight: 600 }}>{t('Plan Information')}</h3>
            {summaryData ? (
              <div css={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '16px', fontSize: '14px' }}>
                <h3 css={{ fontWeight: 600 }}>{t('Your Plan')}</h3>
                <h3 css={{ fontWeight: 600 }}>{t('Plan Cost')}</h3>
                <span css={{ height: '32px', alignContent: 'center' }}>
                  {getPlanName(summaryData.subscriptionPlan)}
                </span>
                <span css={{ height: '32px', alignContent: 'center' }}>
                  {CURRENCY_SYMBOLS[summaryData.currencyId]}
                  {formatPrice(summaryData.planPrice, summaryData.currencyId, 0)}
                </span>
              </div>
            ) : null}
          </div>
          <div css={{ marginTop: '24px' }}>
            <h3 css={{ fontSize: '13px', fontWeight: 600 }}>{t('Billing Information')}</h3>
            {billingData && hasBilling ? (
              <div css={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '16px', fontSize: '14px' }}>
                <h3 css={{ fontWeight: 600 }}>{t('Credit Card')}</h3>
                <h3 css={{ fontWeight: 600 }}>{t('Expires')}</h3>
                <span css={{ height: '32px', alignContent: 'center' }}>
                  <span css={{ textTransform: 'uppercase' }}>{billingData.cardBrand || ''}</span>
                  <span> {t('ending in')} </span>
                  <span>{billingData.cardLast4 || ''}</span>
                </span>
                {billingData.cardExpMonth && billingData.cardExpYear ? (
                  <span css={{ height: '32px', alignContent: 'center' }}>{getExpiresCardDate(billingData)}</span>
                ) : (
                  '-'
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div css={{ padding: '16px', borderTop: `1px solid ${THEME.border.colors.gray.lv1}` }}>
        <ButtonLink
          as={'a'}
          variant="blue"
          title={t('Button.Reset Billing Information')}
          css={{ marginLeft: 'auto', width: 'max-content', padding: '0 24px' }}
          href={customerPortalLinkData?.customerPortalLink?.url}
        />
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    width: '800px',
    boxShadow: THEME.shadows.boxShadow,
    margin: 'auto',
    borderRadius: '5px',
    background: THEME.background.colors.white
  }),
  paymentNotice: css({
    width: '442px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%' /* 19.6px */
  }),
  billingAndPlanSection: css({
    marginTop: '16px',
    padding: '16px',
    background: THEME.background.colors.gray.lv1,
    border: THEME.border.base,
    borderRadius: '5px'
  })
};
