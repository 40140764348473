import { useTranslation } from 'react-i18next';
import { InfluencerCampaignDetail } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerPostDetailsModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { useInfluencerProfileCampaignListTable } from './hooks';

export type InfluencerProfileCampaignsListProps = TableData<InfluencerCampaignDetail>;

export const InfluencerProfileCampaignsList = ({ loading, data = [] }: InfluencerProfileCampaignsListProps) => {
  const { t } = useTranslation();
  const { rows, columns, allPosts } = useInfluencerProfileCampaignListTable({ data });

  return (
    <>
      <Table
        loading={loading}
        data={{ rows, columns, rowHeight: '70px' }}
        empty={{ hideDescription: true, title: t('No campaigns has been found') }}
        noTopBorderRadius
        hasBorder
      />

      <InfluencerPostDetailsModal posts={allPosts} />
    </>
  );
};
