import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/MarketplaceDraftPost.graphql';
import { Query, QuerymarketplaceDraftPostArgs } from '../../__generated__/globalTypes';

export const useMarketplaceDraftPostQuery = (options?: QueryHookOptions<Query, QuerymarketplaceDraftPostArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerymarketplaceDraftPostArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useMarketplaceDraftPostLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerymarketplaceDraftPostArgs>
): QueryResult<Query, QuerymarketplaceDraftPostArgs> & {
  getMarketplaceDraftPost: LazyQueryExecFunction<Query, QuerymarketplaceDraftPostArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getMarketplaceDraftPost, result] = useLazyQuery<Query, QuerymarketplaceDraftPostArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getMarketplaceDraftPost, ...result };
};

export const useMarketplaceDraftPostPromiseQuery = () => {
  const client = useApolloClient();

  const getMarketplaceDraftPost = useCallback(
    (options?: Omit<QueryOptions<QuerymarketplaceDraftPostArgs, Query>, 'query'>) =>
      client.query<Query, QuerymarketplaceDraftPostArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getMarketplaceDraftPost };
};
