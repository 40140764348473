import React, { createContext, useContext } from 'react';
import { useMatchRoute } from '@tanstack/react-router';
import { EngagementDetail, EngagementProposal, CampaignTrackingOption, CampaignSocialMediaType } from '@/graphql';
import { UpdateDocumentTitleProps, useGetDocumentParentHref, useToggle, UseToggleReturnType } from '@/shared/hooks';

interface ContextType {
  engagementId: number;
  hasTrackingTune: boolean;
  isOnlyTikTokTtcm?: boolean;
  engagement: EngagementDetail;
  hasTrackingReferral: boolean;
  engagementProposal: EngagementProposal;
  uploadModal: UseToggleReturnType;
}

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  children: React.ReactElement | Array<React.ReactElement>;
  value: Pick<ContextType, 'engagement' | 'engagementProposal'>;
}

export const EngagementDetailsProvider = ({ children, value: { engagement, engagementProposal } }: Props) => {
  const uploadModal = useToggle();
  const matchByPath = useMatchRoute();
  const isReportPage = !!matchByPath({ to: '/engagement/$id/report' });
  const isPostsPage = !!matchByPath({ to: '/engagement/$id/posts' });

  let documents: UpdateDocumentTitleProps = {
    href: '/engagement',
    alwaysBackToParent: true,
    title: 'documentTitle.EditEngagement',
    appHeader: 'appHeader.Campaign Title',
    interpolation: { campaignTitle: engagement.title }
  };
  if (isPostsPage) {
    documents = {
      ...documents,
      interpolation: { campaignTitle: engagement.title },
      title: engagement.title ? 'documentTitle.PostWithTitle' : 'documentTitle.PostDefault',
      appHeader: engagement.title ? 'appHeader.PostWithTitle' : 'appHeader.PostDefault'
    };
  } else if (isReportPage) {
    documents = {
      ...documents,
      interpolation: { title: engagement.title },
      title: engagement.title ? 'documentTitle.PostReportWithTitle' : 'documentTitle.PostReportDefault',
      appHeader: engagement.title ? 'appHeader.PostReportWithTitle' : 'appHeader.PostReportDefault'
    };
  }

  useGetDocumentParentHref(documents);

  const { isTtcmCampaign, trackingOptions, socialMedias } = engagement;

  return (
    <Context.Provider
      value={{
        engagement,
        uploadModal,
        engagementProposal,
        engagementId: engagement.id,
        hasTrackingTune: !!trackingOptions?.includes(CampaignTrackingOption.TUNE),
        hasTrackingReferral: !!trackingOptions?.includes(CampaignTrackingOption.REFERRAL_CODE),
        isOnlyTikTokTtcm: isTtcmCampaign && socialMedias.every((sm) => sm === CampaignSocialMediaType.TIKTOK)
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useEngagementDetailsContext = () => useContext<ContextType>(Context);
