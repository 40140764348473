import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TrafficSourceChart, TrafficSourceChartProps } from './TrafficSourceChart';

export interface TrafficSourceProps extends TrafficSourceChartProps {
  title?: ReactNode;
  className?: string;
}

export const TrafficSource = ({ className, data, title }: TrafficSourceProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ marginTop: '16px' }} className={className}>
      <h3 css={{ fontWeight: 600, fontSize: '14px', marginBottom: '16px' }}>{title || t('Traffic Source')}</h3>
      <TrafficSourceChart data={data} />
    </div>
  );
};
