import { Navigate } from '@tanstack/react-router';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';
import { useAnalyticsProfileContext } from '../AnalyticsProfileContext';
import { AnalyticsFacebookDashboard } from './AnalyticsFacebookDashboard';
import { AnalyticsInstagramDashboard } from './AnalyticsInstagramDashboard';
import { AnalyticsTwitterDashboard } from './AnalyticsTwitterDashboard';
import { AnalyticsYoutubeDashboard } from './AnalyticsYoutubeDashboard';

// TODO: Seem we don't check `subscriptionPlan.availableFeatures?.dashboardEnabled` like other tab flows (based old source code).
export const AnalyticsDashboard = () => {
  const { selectedSocialAccount } = useAnalyticsProfileContext();

  switch (selectedSocialAccount.socialMedia) {
    case AnalyticsSupportedSocialMediaType.instagram:
      return <AnalyticsInstagramDashboard />;
    case AnalyticsSupportedSocialMediaType.twitter:
      return <AnalyticsTwitterDashboard />;
    case AnalyticsSupportedSocialMediaType.facebook:
      return <AnalyticsFacebookDashboard />;
    case AnalyticsSupportedSocialMediaType.youtube:
      return <AnalyticsYoutubeDashboard />;
    default:
      return <Navigate to="/analytics" />;
  }
};
