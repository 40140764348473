import { usePermissions } from '../hooks';

const defaultPermissions = {
  hidePartnerTab: false,
  hideAddPartnerBtn: false,
  hideEditPartnerBtn: false,
  hideDeletePartnerBtn: false
};
export const usePartnerPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.partnerAgency?.noView || permissions?.partnerAgency === null) {
    return {
      hidePartnerTab: true,
      hideAddPartnerBtn: true,
      hideEditPartnerBtn: true,
      hideDeletePartnerBtn: true
    };
  } else if (permissions?.partnerAgency?.view) {
    return {
      hidePartnerTab: false,
      hideAddPartnerBtn: true,
      hideEditPartnerBtn: true,
      hideDeletePartnerBtn: true
    };
  } else if (permissions?.partnerAgency?.manage) {
    return { ...defaultPermissions, hideDeletePartnerBtn: true };
  }

  return defaultPermissions;
};
