import {
  InfluencerInstagramProfileMentionedAccounts,
  InfluencerInstagramProfileMentionedAccountsProps
} from '../../InfluencerProfiles';

type InfluencerAnalyticsInstagramProfileMentionedAccountsProps = InfluencerInstagramProfileMentionedAccountsProps;

export const InfluencerAnalyticsInstagramProfileMentionedAccounts = (
  props: InfluencerAnalyticsInstagramProfileMentionedAccountsProps
) => (
  <InfluencerInstagramProfileMentionedAccounts
    {...props}
    wrapperLink={{ url: '' }}
    wrapperText="No brand mentioned posts to show"
  />
);
