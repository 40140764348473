import { QueryHookOptions } from '@apollo/client';

import { Query, useAllPartnersQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';

export const useAllPartnerOptions = (options?: QueryHookOptions<Query>) => {
  const { data, loading } = useAllPartnersQuery({ fetchPolicy: 'cache-and-network', ...options });

  return {
    loading,
    allPartnerOptions: getOptions(data?.allPartners)
  };
};
