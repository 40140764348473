import { css } from '@emotion/react';
import { linkOptions, useSearch } from '@tanstack/react-router';
import { useEngagementPermissions, useUserRoles } from '@/auth';
import {
  WarningReason,
  EngagementPost,
  EngagementDetail,
  SocialAccountStatus,
  CampaignSocialMediaType,
  EngagementCampaignStatus,
  EngagementCampaignSocialAccountPostStatus
} from '@/graphql';
import {
  Anchor,
  RowProps,
  CheckBox,
  TableCell,
  SocialIcon,
  ColumnProps,
  MediaPreview,
  SocialIconProps
} from '@/shared/atoms';
import { useQueryHelper, useSelectItems, usePostDetailsParam, useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { EngagementPostStatus, InfluencerAvatar, SpreadButton } from '@/shared/molecules';
import { checkIsReelPost } from '@/shared/utils';
import { THEME } from '@/shared/constants';
import { getDottedLineMessage, isStatusChangeable } from '../utils';
import { EngagementPostsSearchSchemaType } from '../../EngagementPostsFilter';

interface Props {
  engagement: EngagementDetail;
  posts: readonly EngagementPost[];
}

export const useEngagementPostsListTable = ({
  posts,
  engagement: { id, status: engagementStatus, isTtcmCampaign }
}: Props) => {
  const eggId = id.toString();
  const search = useSearch({ from: '/_private-routes/engagement/$id/posts' }) as EngagementPostsSearchSchemaType;
  const { setPostDetailsParam } = usePostDetailsParam();
  const { t, navigate } = useQueryHelper();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const { hideEditPostBtn, hideApproveRejectInappropriatePostBtn } = useEngagementPermissions();

  const checkHasPostStatusControl = (post: Pick<EngagementPost, 'id' | 'status'>) =>
    !hideApproveRejectInappropriatePostBtn && !!post.id && !!post.status && isStatusChangeable(post.status);

  const postsActions = useSelectItems<EngagementPost, keyof EngagementPost>({
    key: 'id',
    listItems: posts.reduce<EngagementPost[]>(
      (acc, curr) => (checkHasPostStatusControl(curr) ? [...acc, curr] : acc),
      []
    )
  });

  const { allItemIds, selectItem, selectedItemIds, toggleSelectAllItems } = postsActions;
  const joinedStatuses = [
    EngagementCampaignSocialAccountPostStatus.JOINED,
    EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_ORDER,
    EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_INVITATION
  ];
  const isCampaignApproved = [EngagementCampaignStatus.ONGOING, EngagementCampaignStatus.FINISHED].includes(
    engagementStatus
  );
  const columns: ColumnProps[] = [
    {
      title: !hideApproveRejectInappropriatePostBtn ? (
        <CheckBox onClick={toggleSelectAllItems} disabled={!allItemIds.length} />
      ) : (
        <div css={{ width: '16px' }} />
      )
    },
    { title: t('HeaderColumn.Influencer') },
    { title: t('HeaderColumn.Content') },
    { title: t('HeaderColumn.Social'), styles: { textAlign: 'center' } },
    { title: t('HeaderColumn.Post Status') },
    { title: t('HeaderColumn.Planned Post Date'), hidden: isTtcmCampaign },
    { title: t('HeaderColumn.Post date') }
  ];
  const rows: RowProps[] = posts.map(
    ({
      id,
      status,
      content,
      postUrl,
      thumbNail,
      influencer,
      postedDate,
      socialMedia,
      warningReason,
      planedPostDate,
      socialAccountId,
      socialAccountStatus
    }) => {
      const isSelectedPost = !!id && selectedItemIds.includes(id);
      const engagementAddPostUrl = linkOptions({
        to: '/engagement/$id/posts/add',
        params: { id: eggId },
        search: {
          ...search,
          filter: {
            ...search.filter,
            id: String(influencer.id),
            socialAccountMedia: socialMedia,
            socialAccountId: String(socialAccountId || '')
          }
        }
      });
      const handlePostClick = () => (id ? setPostDetailsParam(id) : navigate(engagementAddPostUrl));

      const isJoinedStatuses = status && joinedStatuses.includes(status);
      const isRejectStatus = status === EngagementCampaignSocialAccountPostStatus.REJECT;
      const isReviewingStatus = status === EngagementCampaignSocialAccountPostStatus.REVIEWING;
      const rejectedByTikTok = isRejectStatus && warningReason === WarningReason.API_ERROR;
      const hasAddPostDottedPreview = status === EngagementCampaignSocialAccountPostStatus.JOINED;
      const isWaitingForRepost = isTtcmCampaign && isRejectStatus; // means rejected by Admin|Staff
      const isTikTokPostFromTtcm = isTtcmCampaign && socialMedia === CampaignSocialMediaType.TIKTOK;
      const disabledPostInteractions =
        status &&
        [
          EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_INVITATION,
          EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_ORDER
        ].includes(status);
      const needsAdminStaffReview = isTikTokPostFromTtcm && isReviewingStatus;
      const hasDottedLine = isTikTokPostFromTtcm
        ? (status &&
            [
              EngagementCampaignSocialAccountPostStatus.WARNING,
              EngagementCampaignSocialAccountPostStatus.JOINED,
              EngagementCampaignSocialAccountPostStatus.TTCM_REVIEWING,
              EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_ORDER,
              EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_INVITATION
            ].includes(status)) ||
          rejectedByTikTok
        : false;

      return [
        checkHasPostStatusControl({ id, status }) ? (
          <CheckBox
            checked={isSelectedPost}
            onClick={id ? () => selectItem(id) : undefined}
            tooltipProps={{ help: t(isSelectedPost ? 'Unselect' : 'Select') }}
          />
        ) : (
          <div css={{ width: '16px' }} />
        ),
        <TableCell.Link
          label={influencer.name}
          css={{ display: 'flex', alignItems: 'center', minWidth: '100px' }}
          {...generateProfileLink(influencer.id)}
        >
          <InfluencerAvatar size="40px" src={influencer.avatar} asChild />
          <TableCell.Text css={{ marginLeft: '8px', minWidth: '100px' }} value={influencer.name} />
        </TableCell.Link>,
        <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {(hasDottedLine || hasAddPostDottedPreview) && !(isTtcmCampaign && isCampaignApproved) ? (
            <div
              onClick={!disabledPostInteractions ? handlePostClick : undefined}
              css={[
                { gap: '8px', display: 'flex', alignItems: 'center' },
                !disabledPostInteractions && { cursor: 'pointer', '&:hover': { color: THEME.text.colors.blue } }
              ]}
            >
              <div css={styles.dottedBlock} />
              {hasAddPostDottedPreview ? (
                <Anchor variant="blue" {...engagementAddPostUrl} label={`+ ${t('Button.Add Post')}`} />
              ) : (
                <div>{getDottedLineMessage({ t, status, postContent: content || '' })}</div>
              )}
            </div>
          ) : (
            <div
              onClick={handlePostClick}
              css={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <MediaPreview src={thumbNail} css={{ width: '60px', height: '40px' }} isReel={checkIsReelPost(postUrl)} />
              <Anchor
                lines={2}
                variant={isReviewingStatus || needsAdminStaffReview ? 'blue' : 'default'}
                label={
                  isWaitingForRepost
                    ? t<string>('Waiting for re-posting')
                    : isReviewingStatus
                      ? t<string>('Review post')
                      : needsAdminStaffReview
                        ? t('Review video')
                        : content || '-'
                }
              />
            </div>
          )}

          {(!isTikTokPostFromTtcm && !isJoinedStatuses) ||
          (isTikTokPostFromTtcm &&
            !!id &&
            ((!thumbNail && !content) || socialAccountStatus === SocialAccountStatus.SCRAPING)) ? (
            <SpreadButton
              css={{ marginLeft: '16px' }}
              moreButtonProps={{ disabled: hideEditPostBtn && !hasAddPostDottedPreview }}
              options={[
                {
                  icon: 'edit',
                  label: t('Edit'),
                  tooltip: { help: t('Button.Edit') },
                  disabled: !isAdminStaffTalentAgencyPartner || !id,
                  hidden: hideEditPostBtn && !hasAddPostDottedPreview,
                  to: '/engagement/$id/posts/$postId',
                  params: { id: eggId, postId: id?.toString() },
                  search
                }
              ]}
            />
          ) : null}
        </div>,
        <div css={{ display: 'block', margin: '0 auto', width: 'max-content' }}>
          <SocialIcon type={socialMedia as SocialIconProps['type']} />
        </div>,
        status ? <EngagementPostStatus status={status} warningReason={warningReason || undefined} /> : '',
        <TableCell.Date value={planedPostDate} />,
        <TableCell.Date value={postedDate} />
      ];
    }
  );

  return { rows, columns, postsActions };
};

const styles = {
  dottedBlock: css({
    '--width': '60px',

    width: 'var(--width)',
    minWidth: ' var(--width)',
    height: '40px',
    borderRadius: '3px',
    border: '1px dashed #dee5ec',
    backgroundColor: THEME.background.colors.white
  }),
  waitingForRepost: css({
    width: '60px',
    height: '40px',
    display: 'flex',
    borderRadius: '3px',
    background: '#f6f8fa',
    placeContent: 'center'
  })
};
