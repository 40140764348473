import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteInstagramUserHashtag.graphql';
import { Mutation, MutationdeleteInstagramUserHashtagArgs } from '../../__generated__/globalTypes';

export const useDeleteInstagramUserHashtagMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteInstagramUserHashtagArgs>
) => {
  const [callDeleteInstagramUserHashtag, result] = useMutation<Mutation, MutationdeleteInstagramUserHashtagArgs>(
    MUTATION,
    options
  );

  return { callDeleteInstagramUserHashtag, ...result };
};

export type DeleteInstagramUserHashtagMutationFunction = Mutation['deleteInstagramUserHashtag'];
