import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDouyinAccountInterestQuery } from '@/graphql';
import { ChartsContainer, NoDataTitle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { GeneralCategoriesChart, PieChart, VerticalBarChart } from '@/shared/molecules';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerProfileAccountInterestWrapper } from '../shared';
import { PIE_CHART_COLORS } from './utils';

export interface InfluencerDouyinProfileAccountInterestProps {
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerDouyinProfileAccountInterest = ({
  influencerId,
  socialAccount
}: InfluencerDouyinProfileAccountInterestProps) => {
  const { t } = useTranslation();
  const { data, loading } = useDouyinAccountInterestQuery({
    variables: { pk: influencerId, socialAccountId: socialAccount.id },
    onCompleted: ({ douyinAccountInterest }) => {
      setSelectedCategory(douyinAccountInterest?.generalCategory?.at(0)?.name || '');
    }
  });
  const [selectedCategory, setSelectedCategory] = useState('');

  return (
    <InfluencerProfileAccountInterestWrapper loading={loading} data={data?.douyinAccountInterest}>
      {({ data: { videoType, generalCategory } }) => (
        <ChartsContainer css={{ height: '385px' }}>
          <PieChart
            dataNotAvailable={!videoType?.length}
            header={<NoDataTitle title={t('Video type')} />}
            data={
              [...(videoType || [])]
                ?.sort((a, b) => a.rate - b.rate)
                .map((vt, i) => ({ y: vt.rate, name: vt.name, color: PIE_CHART_COLORS.at(i) || '' })) || []
            }
          />
          <GeneralCategoriesChart
            dataNotAvailable={!generalCategory?.length}
            header={<NoDataTitle title={t('General category')} />}
            categories={generalCategory?.map((el) => el.name)}
            totalRate={[
              {
                type: 'bar',
                data: generalCategory?.map((cat) => ({
                  y: cat.count,
                  selected: cat.name === selectedCategory,
                  color: cat.name === selectedCategory ? THEME.chart.colors.violet.lv2 : THEME.chart.colors.violet.lv1
                })),
                name: t<string>('Total'),
                point: {
                  events: {
                    click() {
                      setSelectedCategory(this.category as string);
                    }
                  }
                }
              }
            ]}
          />
          <VerticalBarChart
            dataNotAvailable={!generalCategory?.length}
            header={<NoDataTitle title={`${t(selectedCategory)} ${t('Details')}`} />}
            categories={generalCategory
              ?.find((el) => el.name === selectedCategory)
              ?.detailCategory?.map((el) => el.name)}
            series={[
              {
                type: 'column',
                data: generalCategory?.find((el) => el.name === selectedCategory)?.detailCategory?.map((dc) => dc.rate),
                name: 'Total',
                color: THEME.chart.colors.violet.lv2
              }
            ]}
          />
        </ChartsContainer>
      )}
    </InfluencerProfileAccountInterestWrapper>
  );
};
