import { InfluencerPostV3 } from '@/graphql';
import { getToday, getThirtyDaysAgo } from '@/shared/utils';
import { InfluencersTabType } from '@/shared/types';
import { InfluencerPostProfile, InfluencerPostsFilterFormValues } from './schemaTypes';

export const getInitialInfluencerPostsFormValues = (
  defaultFilter?: Partial<InfluencerPostsFilterFormValues>
): Partial<InfluencerPostsFilterFormValues> => ({
  tab: InfluencersTabType.POST,
  maxPostDate: getToday(),
  minPostDate: getThirtyDaysAgo(),
  ...defaultFilter
});

export const formatInfluencerPostProfile = (
  infl: InfluencerPostV3,
  allPosts: readonly InfluencerPostV3[]
): InfluencerPostProfile => ({
  id: infl.influencerId,
  age: infl.age,
  name: infl.name,
  gender: infl.gender,
  postId: infl.postId,
  postDate: infl.postDate,
  avatar: infl.avatar || '',
  followers: infl.followers,
  postThumbnail: infl.thumbNail,
  socialMedia: infl.socialMedia,
  status: infl.socialAccountStatus,
  postDescription: infl.content || '',
  isBrandAccount: infl.isBrandAccount,
  engagementRate: infl.engagementRate,
  socialAccountId: infl.socialAccountId,
  country: { id: '', name: infl.country || '' },
  posts:
    allPosts.map(({ postId, thumbNail, influencerId }) => ({
      id: String(postId ?? ''),
      thumbNail,
      influencerId,
      campaignId: null,
      campaignType: null
    })) || []
});
