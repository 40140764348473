import { useTranslation } from 'react-i18next';

import { Anchor, Modal, ModalContent, ModalProps, ModalTitle } from '@/shared/atoms';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter } from '@/shared/utils';

export interface GoogleSlidesHistoryModalProps extends ModalProps {
  data: { date: string | number; url: string }[];
}

export const GoogleSlidesHistoryModal = ({
  data,
  hasCloseIcon = true,
  ...restProps
}: GoogleSlidesHistoryModalProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Modal hasCloseIcon={hasCloseIcon} css={{ width: '480px' }} {...restProps}>
      <ModalContent>
        <ModalTitle>{t('Previously exported Google Slides')}</ModalTitle>

        <ul css={{ marginTop: '24px', paddingLeft: '16px' }}>
          {data?.map((history, index) => (
            <li key={index} css={[{ listStyle: 'disc' }, index !== 0 && { marginTop: '16px' }]}>
              <p css={{ lineHeight: '140%', fontWeight: 400 }}>
                <span css={{ fontSize: '14px' }}>
                  {localizedDateFormatter(history.date, 'PPP', i18n.language as AppLanguage)}
                </span>
                <br />
                <Anchor
                  variant="blue"
                  target="_blank"
                  href={history.url}
                  label={history.url}
                  css={{ fontSize: '12px', display: 'inline-block' }}
                />
              </p>
            </li>
          ))}
        </ul>
      </ModalContent>
    </Modal>
  );
};
