import { useState } from 'react';
import {
  SocialAccountStatus,
  useRefreshTwitterAccountMutation,
  useRefreshTwitterAccountAvailabilityQuery,
  useInfluencerAnalyticsTwitterRefreshMutation,
  useInfluencerAnalyticsTwitterRefreshAvailabilityQuery,
  namedOperations
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { RefreshProfileButton } from './RefreshProfileButton';

interface TwitterRefreshButtonProps {
  className?: string;
  socialAccountId: number;
  status: SocialAccountStatus;
}
export const TwitterRefreshButton = ({ socialAccountId, status, className }: TwitterRefreshButtonProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const isScraping = status === SocialAccountStatus.SCRAPING;
  const signedUpRefreshability = useInfluencerAnalyticsTwitterRefreshAvailabilityQuery({
    skip: isScraping,
    fetchPolicy: 'network-only',
    variables: { socialAccountId }
  });
  const scrapingRefreshability = useRefreshTwitterAccountAvailabilityQuery({
    skip: !isScraping,
    fetchPolicy: 'network-only',
    variables: { input: { socialAccountId } }
  });
  const signedUpRefresh = useInfluencerAnalyticsTwitterRefreshMutation({
    refetchQueries: [namedOperations.Query.InfluencerAnalyticsTwitterRefreshAvailability]
  });
  const scrapingRefresh = useRefreshTwitterAccountMutation({
    refetchQueries: [namedOperations.Query.RefreshTwitterAccountAvailability]
  });

  const [disableAfterUpdate, setDisableAfterUpdate] = useState(false); // callInfluencerAnalyticsTwitterRefresh can be a bit slow to update on refetchQueries, after each success update we can set this flag to hide the button

  const handleRefresh = async () => {
    try {
      if (isScraping) {
        await scrapingRefresh.callRefreshTwitterAccount({ variables: { input: { socialAccountId } } });
      } else {
        await signedUpRefresh.callInfluencerAnalyticsTwitterRefresh({ variables: { input: { socialAccountId } } });
      }
      enqueueSnackbar(t('Account data refreshed successfully'), { variant: 'success' });
      setDisableAfterUpdate(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const isRefreshable =
    (isScraping
      ? scrapingRefreshability.data?.refreshTwitterAccountAvailability?.isRefreshAvailable
      : signedUpRefreshability.data?.influencerAnalyticsTwitterRefreshAvailability.isRefreshAvailable) &&
    !disableAfterUpdate;
  const checkingAvailability = scrapingRefreshability.loading || signedUpRefreshability.loading;
  const loading = signedUpRefresh.loading || scrapingRefresh.loading;

  return !checkingAvailability ? (
    <RefreshProfileButton loading={loading} className={className} onClick={handleRefresh} disabled={!isRefreshable} />
  ) : null;
};
