import { useTranslation } from 'react-i18next';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { TikTokCampaignsFilterFormKeys } from './types';
import { useTikTokCampaignStatusOptions } from './hooks';

export interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { statusOptions } = useTikTokCampaignStatusOptions();

  return (
    <BasicFilterWrapper>
      <TextSearchField<TikTokCampaignsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.TiktokCampaignsFilter')}
      />
      <SelectorField<TikTokCampaignsFilterFormKeys>
        name="status"
        onSubmit={onSubmit}
        options={statusOptions}
        css={{ maxWidth: '184px' }}
        placeholder={t<string>('Selector.Status')}
      />
    </BasicFilterWrapper>
  );
};
