import { useTranslation } from 'react-i18next';
import { useToggleState } from '@/shared/hooks';
import {
  AnalyticsComparableAccounts,
  AnalyticsComparableAccountsProps,
  AddAnalyticsComparableAccountModal,
  DeleteAnalyticsComparableAccountModal
} from '@/shared/organisms';
import { InfluencerYoutubeComparableAccountType } from '../types';
import { useCreateDeleteInfluencerYoutubeComparableAccount } from './hooks';

export type InfluencerCompareYoutubeAccountsProps = Pick<
  AnalyticsComparableAccountsProps<InfluencerYoutubeComparableAccountType>,
  'accounts' | 'maxAccounts' | 'mainAccountId' | 'hiddenAccountIds' | 'onSelectAccount'
>;

export const InfluencerCompareYoutubeAccounts = ({
  mainAccountId,
  ...restProps
}: InfluencerCompareYoutubeAccountsProps) => {
  const { t } = useTranslation();
  const addModal = useToggleState();
  const {
    adding,
    deleting,
    deletingAccount,
    setDeletingAccount,
    resetDeletingAccount,
    handleAddNewComparableAccount,
    handleDeleteComparableAccount
  } = useCreateDeleteInfluencerYoutubeComparableAccount({ mainAccountId });

  return (
    <>
      <AnalyticsComparableAccounts
        {...restProps}
        onAddNewAccount={addModal.open}
        mainAccountId={mainAccountId}
        onDeleteAccount={setDeletingAccount}
      />

      <AddAnalyticsComparableAccountModal
        loading={adding}
        open={addModal.status}
        onClose={addModal.close}
        onSubmit={(accountName) => handleAddNewComparableAccount(accountName, addModal.close)}
        customModal={{
          title: t('Dialog.Add Channel To Compare'),
          description: t('Dialog.Please enter YouTube channel URL that you wish to compare to'),
          input: { title: t('TextForm.YouTube'), placeholder: t<string>('YouTube channel URL') }
        }}
      />

      <DeleteAnalyticsComparableAccountModal
        loading={deleting}
        onClose={resetDeletingAccount}
        deletingAccount={deletingAccount}
        onSubmit={handleDeleteComparableAccount}
        customModal={{ title: t('Dialog.Delete comparable Channel') }}
      />
    </>
  );
};
