import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DouyinDistributionType, useDouyinProfileAudienceQuery } from '@/graphql';
import { ChartsContainer, SplitButton } from '@/shared/atoms';
import { HAS_NO_ESTIMATED_TEXT } from '@/shared/constants';
import { AgeBreakdownChart, AreaBreakdownChart, GenderBreakdownChart } from '@/shared/molecules';
import { useInfluencerProfileOpacityContent } from '../../hooks';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerProfileAudienceWrapper } from '../shared';

export interface InfluencerDouyinProfileAudienceProps {
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerDouyinProfileAudience = ({
  influencerId,
  socialAccount
}: InfluencerDouyinProfileAudienceProps) => {
  const { t } = useTranslation();
  const [distributionType, setDistributionType] = useState<DouyinDistributionType>(DouyinDistributionType.ANYONE);
  const { opacityContent, hasAudienceDemographics } = useInfluencerProfileOpacityContent({
    influencerId,
    socialAccount
  });
  const { data, loading } = useDouyinProfileAudienceQuery({
    variables: { pk: influencerId, socialAccountId: socialAccount.id, distributionType }
  });

  const chartOpacityContent = hasAudienceDemographics ? opacityContent : { wrapperText: HAS_NO_ESTIMATED_TEXT };

  return (
    <InfluencerProfileAudienceWrapper
      loading={loading}
      data={data?.douyinProfileAudience}
      hasAudienceDemographics={hasAudienceDemographics}
      headerChildren={
        <SplitButton
          minWidth="90px"
          options={[
            {
              title: t('Anyone'),
              variant: 'default',
              onClick: () => setDistributionType(DouyinDistributionType.ANYONE),
              isActive: distributionType === DouyinDistributionType.ANYONE
            },
            {
              title: t('Followers'),
              variant: 'default',
              onClick: () => setDistributionType(DouyinDistributionType.FOLLOWER),
              isActive: distributionType === DouyinDistributionType.FOLLOWER
            }
          ]}
          css={{ marginLeft: 'auto' }}
        />
      }
    >
      {({ data: { maleRate, femaleRate, ageRates, regionRates } }) => (
        <ChartsContainer css={{ height: '385px' }}>
          <GenderBreakdownChart maleRate={maleRate} femaleRate={femaleRate} {...chartOpacityContent} />
          <AgeBreakdownChart ageGroup={ageRates.ageGroup} totalRate={ageRates.rate} {...chartOpacityContent} />
          <AreaBreakdownChart followerRegionRate={regionRates || []} />
        </ChartsContainer>
      )}
    </InfluencerProfileAudienceWrapper>
  );
};
