import { useTranslation } from 'react-i18next';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useTalentAgencyBusinessAssociateCompaniesQuery } from '@/graphql';
import { AssociatedAccountsList, AssociatedAccountsFilter, useAssociatedAccountsFilter } from '@/shared/organisms';

export interface TalentAgencyAssociatedAccountsProps {
  talentAgencyId: number;
}

export const TalentAgencyAssociatedAccounts = ({ talentAgencyId }: TalentAgencyAssociatedAccountsProps) => {
  const { t } = useTranslation();
  const { page, limit, filter } = useAssociatedAccountsFilter();
  const { data, previousData, loading } = useTalentAgencyBusinessAssociateCompaniesQuery({
    fetchPolicy: 'network-only',
    variables: getValuableVariables({ limit, talentAgencyId, offset: getOffset(page, limit), ...filter })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.talentAgencyBusinessAssociateCompaniesCount?.totalNumber || 0;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <AssociatedAccountsFilter keywordPlaceholder={t<string>('Search.Search by Company Name')} />
      <AssociatedAccountsList
        loading={loading}
        data={result?.talentAgencyBusinessAssociateCompanies || []}
        refetchQueries={['TalentAgencyBusinessAssociateCompanies']}
      />
    </ListWithPagination>
  );
};
