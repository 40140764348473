import { Outlet } from '@tanstack/react-router';
import { useCustomHeader } from '@/shared/hooks';
import { YoutubeCMSHeader } from '@/shared/organisms';
import { YoutubeCMSProvider } from './YoutubeCMSContext';

export const YoutubeCMS = () => {
  useCustomHeader();

  return (
    <>
      <YoutubeCMSHeader />
      <YoutubeCMSProvider>
        <Outlet />
      </YoutubeCMSProvider>
    </>
  );
};
