import { useTranslation } from 'react-i18next';
import { useUserRoles, useReportPermissions, useMarketplacePermissions } from '@/auth';
import { Tabs } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { AutoManagedCampaignPayload } from '@/graphql';

export const AutoManagedCampaignTabs = ({ id }: Pick<AutoManagedCampaignPayload, 'id'>) => {
  const { t } = useTranslation();
  const { isAdmin, isStaff } = useUserRoles();
  const { hideReportLink } = useReportPermissions();
  const { hideCommissionsTab } = useMarketplacePermissions();
  const params = { id: id.toString() };

  return (
    <Tabs
      hasIndex
      css={THEME.campaign.customTabStyles}
      options={[
        {
          end: true,
          label: t('Campaign Information'),
          to: '/auto-managed/$id',
          params
        },
        {
          end: true,
          label: t('Joined Influencers'),
          to: '/auto-managed/$id/joined-influencers',
          params
        },
        {
          end: true,
          hidden: hideReportLink,
          label: t('General.Report'),
          to: '/auto-managed/$id/report',
          params
        },
        {
          end: true,
          label: t('General.Commissions'), // TODO: translation is "Approve Commissions" it is same as in affiliate campaigns
          hidden: hideCommissionsTab || !(isAdmin || isStaff),
          to: '/auto-managed/$id/commissions',
          params
        }
      ]}
    />
  );
};
