import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllInstagramStoryPosts.graphql';
import { Query, QueryallInstagramStoryPostsArgs } from '../../__generated__/globalTypes';

export const useAllInstagramStoryPostsQuery = (options?: QueryHookOptions<Query, QueryallInstagramStoryPostsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallInstagramStoryPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllInstagramStoryPostsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallInstagramStoryPostsArgs>
): QueryResult<Query, QueryallInstagramStoryPostsArgs> & {
  getAllInstagramStoryPosts: LazyQueryExecFunction<Query, QueryallInstagramStoryPostsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllInstagramStoryPosts, result] = useLazyQuery<Query, QueryallInstagramStoryPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllInstagramStoryPosts, ...result };
};

export const useAllInstagramStoryPostsPromiseQuery = () => {
  const client = useApolloClient();

  const getAllInstagramStoryPosts = useCallback(
    (options?: Omit<QueryOptions<QueryallInstagramStoryPostsArgs, Query>, 'query'>) =>
      client.query<Query, QueryallInstagramStoryPostsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllInstagramStoryPosts };
};
