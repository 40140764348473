import { useState } from 'react';
import { PackageProposalSocialAcccountBudgetInput } from '@/graphql';
import { useDeepCompareEffect } from '@/shared/hooks';
import { PackageProposedInfluencer } from '../types';

interface InfluencerAccountForPackageProps {
  packageInfluencers: PackageProposedInfluencer[];
}
export const useInfluencerAccountForPackage = ({ packageInfluencers }: InfluencerAccountForPackageProps) => {
  const [selectedInfluencerAccountIds, setSelectedInfluencerAccountIds] = useState<number[]>([]);

  useDeepCompareEffect(() => {
    setSelectedInfluencerAccountIds([]);
  }, [packageInfluencers]);

  const allInfluencers: Pick<
    PackageProposalSocialAcccountBudgetInput,
    'budget' | 'profit' | 'influencerId' | 'influencerCost'
  >[] = [];
  const packageInfluencerIds = packageInfluencers.map(({ id, budget, influencerCost, profit }) => {
    allInfluencers.push({
      influencerId: id,
      profit: profit || 0,
      budget: budget || 0,
      influencerCost: influencerCost || 0
    });

    return id;
  });
  const selectedPackageInfluencers = allInfluencers.filter((infl) =>
    selectedInfluencerAccountIds.includes(infl.influencerId)
  );

  const handleToggleSelectAllInfluencers = () => {
    setSelectedInfluencerAccountIds(
      selectedInfluencerAccountIds.length === packageInfluencerIds.length ? [] : packageInfluencerIds
    );
  };

  const handleSelectSingleInfluencer = (id: number) => {
    const newIds = [...selectedInfluencerAccountIds];

    if (newIds.includes(id)) {
      const index = newIds.findIndex((newId) => newId === id);
      newIds.splice(index, 1);
    } else {
      newIds.push(id);
    }

    setSelectedInfluencerAccountIds(newIds);
  };

  return {
    packageInfluencerIds,
    selectedPackageInfluencers,
    selectedInfluencerAccountIds,
    setSelectedInfluencerAccountIds,
    handleSelectSingleInfluencer,
    handleToggleSelectAllInfluencers
  };
};
