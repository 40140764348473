import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';

export type FieldWrapperProps = React.HTMLAttributes<HTMLDivElement> & { hasBorderBottom?: boolean };

export const FieldWrapper = forwardRef<HTMLDivElement, FieldWrapperProps>(
  ({ className, hasBorderBottom, ...restProps }, ref) => (
    <div ref={ref} css={styles.wrapper({ hasBorderBottom })} className={`${className} field-wrapper`} {...restProps} />
  )
);
FieldWrapper.displayName = 'FieldWrapper';

const styles = {
  wrapper:
    ({ hasBorderBottom }: Pick<FieldWrapperProps, 'hasBorderBottom'>) =>
    () =>
      css({
        flex: '1 1 0',
        position: 'relative',
        boxSizing: 'border-box',
        paddingTop: `${THEME.form.gapRow / 2}px`,
        paddingBottom: `${THEME.form.gapRow / 2}px`,
        ...(hasBorderBottom
          ? {
              position: 'relative',
              paddingBottom: '16px',
              marginBottom: `${THEME.form.gapRow / 2}px`,
              '&::after': {
                bottom: 0,
                left: '50%',
                content: '""',
                height: '1px',
                position: 'absolute',
                backgroundColor: '#eef3f7',
                transform: 'translateX(-50%)',
                width: `calc(100% - ${THEME.form.gapColumn}px)`
              }
            }
          : {})
      })
};
