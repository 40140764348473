import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormStyle, Label } from '@/shared/atoms';
import { formatNumber } from '@/shared/utils';

import { MarketplaceFormValues } from '../types';
import { calculateInfluencerCommissionRate } from '../utils';

export const InfluencerCommissionRateField = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<MarketplaceFormValues>();

  const { marginRate, costPerSaleTune, costPerSaleReferral } = getValues();
  const influencerCommissionRate = calculateInfluencerCommissionRate({
    marginRate,
    costPerSaleTune,
    costPerSaleReferral
  });

  return (
    <FormStyle.FieldWrapper>
      <Label
        css={{ padding: 0, marginBottom: '8px' }}
        help={t('Tooltip.influencerCommissionRateHelp')}
        title={t('TextForm.Influencer Commission Rate')}
      />
      <p css={{ fontSize: '14px' }}>{formatNumber(influencerCommissionRate)}%</p>
    </FormStyle.FieldWrapper>
  );
};
