import { useAllPartnerAgencyCompanyQuery } from '@/graphql';
import { Header, ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { PartnersFilter, PartnersList, useAccountsFilter } from '@/shared/organisms';

export const Partners = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'appHeader.Partner', appHeader: 'appHeader.Partner' });
  const { page, limit, filter } = useAccountsFilter();
  const { data, previousData, loading } = useAllPartnerAgencyCompanyQuery({
    fetchPolicy: 'network-only',
    variables: getValuableVariables({
      ...filter,
      limit,
      offset: getOffset(page, limit),
      verifiedStatus: filter.verifiedStatus || null
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allPartnerAgenciesCount?.totalNumber;

  return (
    <>
      <Header />

      <ListWithPagination pagination={{ totalRecords }}>
        <PartnersFilter />
        <PartnersList loading={loading} totalRecords={totalRecords} data={result?.allPartnerAgencyCompany || []} />
      </ListWithPagination>
    </>
  );
};
