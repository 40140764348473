import {
  AccountAnalyticsTwitterAccount,
  AccountAnalyticsYouTubeAccount,
  AnalyticsAuthSocialAccountType,
  AccountAnalyticsInstagramAccount,
  AccountAnalyticsSocialMediaAccount,
  AccountAnalyticsFacebookPageAccount
} from '@/graphql';
import { AnalyticsSelectedAccountInfo } from '@/shared/organisms';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';

interface Props {
  socialMediaAccountId: number;
  socialMedia: AnalyticsSupportedSocialMediaType;
  socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
}

export const getAnalyticsBasicSelectedAccountInfo = ({
  socialMedia,
  socialMediaAccountId,
  socialMediaAccounts
}: Props): AnalyticsSelectedAccountInfo => {
  const throwError = (invalid: boolean) => {
    if (invalid) {
      throw new Error(`Social media "${socialMedia}" doesn't exist`);
    }
  };

  switch (socialMedia) {
    case AnalyticsSupportedSocialMediaType.youtube: {
      const youtube = socialMediaAccounts?.youtube as AccountAnalyticsYouTubeAccount;
      throwError(!youtube || socialMediaAccountId !== youtube.id);

      return {
        socialMedia,
        id: youtube.id,
        avatar: youtube.avatar,
        socialAccountId: youtube?.channelId,
        needReconnect: youtube.needReconnect,
        socialAccountName: youtube.channelName,
        socialAccountType: AnalyticsAuthSocialAccountType.YOUTUBE,
        socialMediaUrl: `https://www.youtube.com/channel/${youtube.channelId}/`
      };
    }
    case AnalyticsSupportedSocialMediaType.twitter: {
      const twitter = socialMediaAccounts?.twitter as AccountAnalyticsTwitterAccount;
      throwError(!twitter || socialMediaAccountId !== twitter.id);

      return {
        socialMedia,
        id: twitter.id,
        avatar: twitter.avatar,
        socialAccountName: twitter.name,
        needReconnect: twitter.needReconnect,
        socialAccountId: twitter.twitterUserId,
        socialAccountType: AnalyticsAuthSocialAccountType.TWITTER,
        socialMediaUrl: `https://twitter.com/${twitter.username}/`
      };
    }
    case AnalyticsSupportedSocialMediaType.instagram: {
      const instagram = socialMediaAccounts?.instagram as AccountAnalyticsInstagramAccount;
      throwError(!instagram || socialMediaAccountId !== instagram.id);

      return {
        socialMedia,
        id: instagram.id,
        avatar: instagram.avatar,
        needReconnect: instagram.needReconnect,
        socialAccountId: instagram.businessAccountId,
        socialAccountName: instagram.instagramAccountName,
        socialAccountType: AnalyticsAuthSocialAccountType.INSTAGRAM,
        socialMediaUrl: `https://www.instagram.com/${instagram.instagramAccountName}/`,
        linkedAccount: {
          socialAccountId: instagram.fbPageId,
          socialAccountName: instagram.fbPageName,
          socialAccountType: AnalyticsAuthSocialAccountType.FACEBOOK_PAGE
        }
      };
    }
    case AnalyticsSupportedSocialMediaType.facebook: {
      const facebookPage = socialMediaAccounts?.facebookPage as AccountAnalyticsFacebookPageAccount;
      throwError(!facebookPage || socialMediaAccountId !== facebookPage.id);

      return {
        socialMedia,
        id: facebookPage.id,
        avatar: facebookPage.avatar,
        socialMediaUrl: facebookPage.pageUrl,
        socialAccountId: facebookPage.fbPageId,
        needReconnect: facebookPage.needReconnect,
        socialAccountName: facebookPage.fbPageName,
        socialAccountType: AnalyticsAuthSocialAccountType.FACEBOOK_PAGE
      };
    }
    default:
      throw new Error(`Unsupported Social media type ${socialMedia}`);
  }
};
