import { useTranslation } from 'react-i18next';
import { FormStyle, Label } from '@/shared/atoms';
import { FileUploadField, FileUploadFieldProps, TextField } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

interface MaterialUploadFieldProps<T extends string = keyof typeof MarketplaceFormKeys>
  extends Omit<FileUploadFieldProps<T>, 'name' | 'target'> {
  urlFieldName?: T;
  materialsFieldName?: T;
}

export const MaterialUploadField = <T extends string = keyof typeof MarketplaceFormKeys>({
  help,
  title,
  required,
  disabled,
  className,
  urlFieldName,
  materialsFieldName,
  ...restProps
}: Partial<MaterialUploadFieldProps<T>>) => {
  const { t } = useTranslation();

  return (
    <FormStyle.FieldWrapper className={className}>
      <Label
        required={required}
        title={title || t('General.CampaignMaterial')}
        help={help || t('Tooltip.CampaignMaterialHelp')}
      />

      <TextField<T>
        disabled={disabled}
        css={{ padding: 0 }}
        name={urlFieldName || (MarketplaceFormKeys.sampleUrl as T)}
        placeholder={t<string>('Input the URL such as Google Slides')}
      />

      <FileUploadField<T>
        itemsPerRow={4}
        target="material"
        disabled={disabled}
        css={{ padding: '8px 0 0' }}
        name={materialsFieldName || (MarketplaceFormKeys.materials as T)}
        multiple
        {...restProps}
      />
    </FormStyle.FieldWrapper>
  );
};
