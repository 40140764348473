import { useInfluencerYoutubeAnalyticsAudienceQuery } from '@/graphql';
import { AnalyticsYoutubeDashboardAudienceTemplate } from '@/shared/organisms';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsYoutubeProfileAudienceProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerAnalyticsYoutubeProfileAudience = ({
  socialAccount
}: InfluencerAnalyticsYoutubeProfileAudienceProps) => {
  const { data, loading } = useInfluencerYoutubeAnalyticsAudienceQuery({
    fetchPolicy: 'no-cache',
    variables: { socialAccountId: socialAccount.id }
  });

  return (
    <AnalyticsYoutubeDashboardAudienceTemplate loading={loading} data={data?.influencerYoutubeAnalyticsAudience} />
  );
};
