import {
  Genders,
  UserRoles,
  SocialAccountStatus,
  TalentAgencyOrPartner,
  CreateInfluencerInputV2,
  InfluencerUpdateDetailV2,
  InfluencerSocialNetworkAccountInput
} from '@/graphql';
import { ToggleOption } from '@/shared/types';
import { splitPartnerAndTalentAgenciesIds } from '@/shared/utils';
import { SocialNetworkAccount } from './SocialAccounts';
import { InfluencerFormValues } from './types';

export const defaultSocialNetworkAccount: SocialNetworkAccount = {
  username: '',
  isAdding: true,
  socialAccountId: 0,
  status: SocialAccountStatus.SCRAPING // we don't use this status for API just for type consistency
};

const pickPartnerOrTalentAgencyIds = (
  allTalentAgenciesPartners: readonly TalentAgencyOrPartner[],
  talentAgencyIds: string[] = [],
  partnerIds: string[] = []
) => {
  const { selectedRole, selectedIds } = talentAgencyIds?.length
    ? { selectedRole: UserRoles.TALENT_AGENCY, selectedIds: talentAgencyIds }
    : { selectedRole: UserRoles.PARTNER, selectedIds: partnerIds };

  return allTalentAgenciesPartners.reduce<string[]>((acc, curr) => {
    if (selectedRole === curr.role && selectedIds?.includes(curr.idOfTalentOrPartner.toString())) {
      return [...acc, curr.id.toString()];
    } else {
      return acc;
    }
  }, []);
};

export const getInfluencerFormValues = (
  infl?: InfluencerUpdateDetailV2,
  allTalentAgenciesPartners: readonly TalentAgencyOrPartner[] = []
): InfluencerFormValues => {
  const partnerIds = infl?.partners?.map(({ id }) => String(id)) || [];
  const talentAgencyIds = infl?.talentAgencies?.map(({ id }) => String(id)) || [];

  return {
    partnerIds,
    talentAgencyIds,
    paymentInformation: null,
    brandContentPermission: null,
    id: infl?.id,
    name: infl?.name || '',
    email: infl?.email || '',
    introduce: infl?.introduce || '',
    countryId: infl?.country.id || '',
    phoneNumber: infl?.phoneNumber || '',
    dateOfBirth: infl?.dateOfBirth || '',
    internalMemo: infl?.internalMemo || '',
    regionId: String(infl?.region?.id || ''),
    isProAccount: infl?.isProAccount ?? false,
    isMenaCountry: infl?.country.isMena ?? false,
    status: infl?.status || SocialAccountStatus.SCRAPING,
    notificationSetting: infl?.notificationSetting ?? true,
    categoryIds: infl?.categories.map(({ id }) => String(id)) || [],
    brandAccount: infl?.isBrandAccount ? ToggleOption.TRUE : ToggleOption.FALSE,
    defaultEngagementSelectionReason: infl?.defaultEngagementSelectionReason || '',
    campaignDisplay: infl?.campaignDisplay ? ToggleOption.TRUE : ToggleOption.FALSE,
    gender: (infl?.gender === Genders.UNKNOWN ? Genders.NOT_APPLICABLE : infl?.gender) || '',
    addressInformation: { city: '', address: '', address2: '', fullName: '', province: '', postalCode: '' },
    managerIds: infl?.managerIds?.length ? infl?.managerIds.map((id) => ({ id: String(id) })) : [{ id: '' }],
    talentAgencyOrPartnerIds: pickPartnerOrTalentAgencyIds(allTalentAgenciesPartners, talentAgencyIds, partnerIds),
    rateCards: { tiktok: '', youtube: '', twitter: '', threads: '', facebook: '', instagram: '', instagramStory: '' },
    /** Accounts */
    facebookAccount: infl?.facebookAccount ?? defaultSocialNetworkAccount,
    xhsAccounts: infl?.xhsAccounts?.length ? infl.xhsAccounts : [],
    douyinAccounts: infl?.douyinAccounts?.length ? infl.douyinAccounts : [],
    facebookPages: infl?.facebookPages?.length ? infl.facebookPages : [defaultSocialNetworkAccount],
    tiktokAccounts: infl?.tiktokAccounts?.length ? infl.tiktokAccounts : [defaultSocialNetworkAccount],
    twitterAccounts: infl?.twitterAccounts?.length ? infl.twitterAccounts : [defaultSocialNetworkAccount],
    threadsAccounts: infl?.threadsAccounts?.length ? infl.threadsAccounts : [defaultSocialNetworkAccount],
    youtubeAccounts: infl?.youtubeAccounts?.length ? infl.youtubeAccounts : [defaultSocialNetworkAccount],
    instagramAccounts: infl?.instagramAccounts?.length ? infl.instagramAccounts : [defaultSocialNetworkAccount]
  };
};
export const defaultInfluencerFormValues = getInfluencerFormValues();

const formatAccountForApi = (accounts: readonly SocialNetworkAccount[]) =>
  accounts.reduce<InfluencerSocialNetworkAccountInput[]>(
    (rs, { username }) => (username ? [...rs, { username }] : rs),
    []
  );

export const getCreateUpdateInfluencerInput = (
  {
    name,
    email,
    gender,
    regionId,
    countryId,
    introduce,
    rateCards,
    managerIds,
    dateOfBirth,
    phoneNumber,
    categoryIds,
    internalMemo,
    brandAccount,
    campaignDisplay,
    paymentInformation,
    addressInformation,
    notificationSetting,
    talentAgencyOrPartnerIds,
    defaultEngagementSelectionReason,
    xhsAccounts,
    facebookPages,
    tiktokAccounts,
    douyinAccounts,
    facebookAccount,
    threadsAccounts,
    youtubeAccounts,
    twitterAccounts,
    instagramAccounts
  }: InfluencerFormValues,
  allTalentAgenciesPartners: readonly TalentAgencyOrPartner[]
): CreateInfluencerInputV2 => {
  const { partnerIds, talentAgencyIds } = splitPartnerAndTalentAgenciesIds(
    talentAgencyOrPartnerIds.map((el) => +el),
    allTalentAgenciesPartners
  );

  return {
    name,
    tags: [], // We don't have it in form but api required
    introduce,
    countryId,
    partnerIds,
    phoneNumber,
    internalMemo,
    talentAgencyIds,
    paymentInformation,
    addressInformation,
    notificationSetting,
    email: email || null,
    dateOfBirth: dateOfBirth || null,
    defaultEngagementSelectionReason,
    categoryIds: categoryIds.map(Number),
    gender: gender || Genders.NOT_APPLICABLE,
    regionId: regionId ? Number(regionId) : null,
    managerIds: managerIds.reduce<number[]>((rs, { id }) => (!id ? rs : [...rs, Number(id)]), []),
    isBrandAccount: brandAccount === ToggleOption.TRUE,
    campaignDisplay: campaignDisplay === ToggleOption.TRUE,
    rateCards: {
      tiktok: rateCards.tiktok ? { price: Number(rateCards.tiktok) } : null,
      twitter: rateCards.twitter ? { price: Number(rateCards.twitter) } : null,
      youtube: rateCards.youtube ? { price: Number(rateCards.youtube) } : null,
      threads: rateCards.threads ? { price: Number(rateCards.threads) } : null,
      facebook: rateCards.facebook ? { price: Number(rateCards.facebook) } : null,
      instagram: rateCards.instagram ? { price: Number(rateCards.instagram) } : null,
      instagramStory: rateCards.instagramStory ? { price: Number(rateCards.instagramStory) } : null
    },
    // Accounts
    xhsAccounts: formatAccountForApi(xhsAccounts),
    facebookPages: formatAccountForApi(facebookPages),
    tiktokAccounts: formatAccountForApi(tiktokAccounts),
    douyinAccounts: formatAccountForApi(douyinAccounts),
    threadsAccounts: formatAccountForApi(threadsAccounts),
    youtubeAccounts: formatAccountForApi(youtubeAccounts),
    twitterAccounts: formatAccountForApi(twitterAccounts),
    instagramAccounts: formatAccountForApi(instagramAccounts),
    facebookAccount: facebookAccount ? formatAccountForApi([facebookAccount]).at(0) : null
  };
};
