import { useTranslation } from 'react-i18next';
import { TableData } from '@/shared/types';
import { MarketplaceInfluencer } from '@/graphql';
import { ListEmpty, RenderDataWithFallback } from '@/shared/atoms';
import { WidgetSelectButtonProps } from '../../../Influencers';
import { ProfileWidget } from './ProfileWidget';

export interface MarketplaceInfluencersListProps extends TableData<MarketplaceInfluencer> {
  generateSelectButtonProps?: (influencer: MarketplaceInfluencer) => WidgetSelectButtonProps | undefined;
}

export const MarketplaceInfluencersList = ({
  loading,
  data = [],
  generateSelectButtonProps
}: MarketplaceInfluencersListProps) => {
  const { t } = useTranslation();

  return (
    <RenderDataWithFallback
      loading={loading}
      hasNoData={data?.length === 0}
      noDataNode={<ListEmpty linkProps={{ to: '/influencer/add', label: t('Influencer.AddAnInfluencer') }} />}
    >
      {data.map((profile, index) => (
        <ProfileWidget
          profile={profile}
          key={`${profile.id}-${index}`}
          selectButtonProps={generateSelectButtonProps?.(profile)}
        />
      ))}
    </RenderDataWithFallback>
  );
};
