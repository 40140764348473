import { ReactNode, useState } from 'react';

import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';

import { Icon } from '../Icon';
import { Popover, PopoverItem } from '../Popover';
import { TextCutter } from '../TextCutter';

export interface MenuItemWithCheckBoxProps<T extends Option> {
  label?: ReactNode;
  className?: string;
  value?: string | null;
  submenu?: T[];
  onClickSubmenu?: (option: T) => void;
}

export const MultiLevelMenu = <T extends Option>(props: MenuItemWithCheckBoxProps<T>) => {
  const { label, value = null, submenu = [], onClickSubmenu } = props;
  const [openedId, setOpenedId] = useState<string | null>(null);

  return (
    <Popover
      open={openedId === value}
      position="right"
      clickOutsideEvents={[]}
      closeOnClickOutside={false}
      offset={-3}
      target={() => (
        <div
          onMouseOver={() => {
            setOpenedId(value);
          }}
          onMouseOut={() => {
            setOpenedId(null);
          }}
        >
          <PopoverItem key={value} id={value || undefined}>
            {typeof label === 'string' ? <TextCutter text={label} /> : label}
            <Icon icon="caret-right" size="10px" color={THEME.icon.colors.gray.lv2} css={{ marginLeft: 'auto' }} />
          </PopoverItem>
        </div>
      )}
    >
      <div
        onMouseEnter={() => {
          setOpenedId(value);
        }}
        onMouseLeave={() => {
          setOpenedId(null);
        }}
        css={{ padding: '8px' }}
      >
        {submenu.map((s) => (
          <PopoverItem
            key={s.value}
            id={s.gtmId}
            onClick={() => {
              onClickSubmenu?.(s);
            }}
          >
            {s.prefixIcon}
            {typeof s.label === 'string' ? <TextCutter text={s.label} id={s.gtmId} /> : s.label}
          </PopoverItem>
        ))}
      </div>
    </Popover>
  );
};
