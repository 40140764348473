import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '../Button';
import { ModalFooter, ModalFooterProps } from './ModalFooter';

// Same with ModalFooter but has some default buttons. If you want to custom, please use ModalFooter instead
export interface ModalFooterActionsProps extends Omit<ModalFooterProps, 'children'> {
  cancelButtonProps?: ButtonProps;
  submitButtonProps?: ButtonProps;
}

export const ModalFooterActions = ({ cancelButtonProps, submitButtonProps, ...restProps }: ModalFooterActionsProps) => {
  const { t } = useTranslation();

  return (
    <ModalFooter css={{ gap: '10px', justifyContent: 'end' }} {...restProps}>
      {cancelButtonProps ? (
        <Button variant="white" css={{ padding: '0 24px' }} title={t('Button.Cancel')} {...cancelButtonProps} />
      ) : null}

      {submitButtonProps ? (
        <Button variant="blue" title={t('Button.OK')} css={{ padding: '0 24px' }} {...submitButtonProps} />
      ) : null}
    </ModalFooter>
  );
};
