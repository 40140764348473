import { AssociateCompanyType, useDeleteAgencyMutation, useDeleteAdvertiserMutation } from '@/graphql';
import { Table } from '@/shared/atoms';
import { ADVERTISER_ALREADY_RUN_CAMPAIGN_BEFORE } from '@/shared/constants';
import { useSelectItem, useQueryHelper, useToggleState } from '@/shared/hooks';
import { ConfirmDeleteModal, WarningModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { useAssociatedAccountsTable } from './hooks';
import { AssociatedAccountType } from './types';

export interface AssociatedAccountsListProps extends TableData<AssociatedAccountType> {
  refetchQueries?: string[];
}

export const AssociatedAccountsList = ({ loading, data = [], refetchQueries }: AssociatedAccountsListProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { status: isOpenAlertModal, toggleStatus: toggleAlertModal } = useToggleState(false);
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem<AssociatedAccountType>();
  const { rows, columns } = useAssociatedAccountsTable({ data, onDelete: setSelectedItem });
  const { callDeleteAgency, loading: deletingAgency } = useDeleteAgencyMutation({ refetchQueries });
  const { callDeleteAdvertiser, loading: deletingAdvertiser } = useDeleteAdvertiserMutation({ refetchQueries });

  const handleDeleteAccount = async () => {
    if (!selectedItem) {
      return;
    }

    try {
      if (selectedItem.companyType === AssociateCompanyType.ADVERTISER) {
        await callDeleteAdvertiser({ variables: { id: selectedItem.id } });
      } else {
        await callDeleteAgency({ variables: { id: selectedItem.id } });
      }

      enqueueSnackbar(t('succeededInDeleting', { name: selectedItem.name }), { variant: 'success' });
    } catch (error) {
      if (error.message.includes(ADVERTISER_ALREADY_RUN_CAMPAIGN_BEFORE)) {
        toggleAlertModal();
      } else {
        enqueueSnackbar(t('failedToDelete', { name: selectedItem.name }), { variant: 'error' });
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    } finally {
      resetSelectedItem();
    }
  };

  return (
    <>
      <Table
        loading={loading}
        data={{ rows, columns }}
        empty={{ hideDescription: true, title: t('No Associated Companies were found') }}
        noTopBorderRadius
      />

      <ConfirmDeleteModal
        onClose={resetSelectedItem}
        deletingItem={selectedItem}
        onDelete={handleDeleteAccount}
        deleting={deletingAgency || deletingAdvertiser}
      />

      <WarningModal
        open={isOpenAlertModal}
        onClose={toggleAlertModal}
        description={`${t('Dialog.Has Campaign Before')}?`}
      />
    </>
  );
};
