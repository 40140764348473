import { THEME } from '@/shared/constants';
import { SimilarInfluencersTemplate, WidgetSelectButtonProps } from '../../shared';
import { RefetchSimilarInfluencersVariables } from '../../types';
import { useTwitterSimilarInfluencersList } from '../hooks';
import { TwitterInfluencerProfile } from '../schemaTypes';
import { ProfileWidget } from './ProfileWidget';

interface SimilarInfluencersListProps {
  socialAccountId: number;
  isSectionOpened: boolean;
  getListSelectButtonProps?: (influencer: TwitterInfluencerProfile) => WidgetSelectButtonProps;
}

export const SimilarInfluencersList = ({
  socialAccountId,
  isSectionOpened,
  getListSelectButtonProps
}: SimilarInfluencersListProps) => {
  const { limit, loading, totalRecords, listRecords, onFetchData, onRefetchData } = useTwitterSimilarInfluencersList({
    socialAccountId
  });

  const handleRefetchData = async (variables: RefetchSimilarInfluencersVariables) => {
    await onRefetchData?.(variables);
  };

  return (
    <SimilarInfluencersTemplate
      isSectionOpened={isSectionOpened}
      loading={loading}
      fetchLimit={limit}
      onFetchData={onFetchData}
      totalRecords={totalRecords}
      onRefetchData={handleRefetchData}
    >
      {({ currentLimit }) =>
        listRecords
          .slice(0, currentLimit)
          .map((profile, index) => (
            <ProfileWidget
              profile={profile}
              key={`similar-influencer-${profile.id}_${index}`}
              selectButtonProps={getListSelectButtonProps?.(profile)}
              css={{ boxShadow: 'none', backgroundColor: THEME.background.colors.gray.lv1 }}
            />
          ))
      }
    </SimilarInfluencersTemplate>
  );
};
