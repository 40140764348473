import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType } from '@/graphql';
import { useDeepCompareEffect } from '@/shared/hooks';
import { SelectedSocialMediaAccountsType } from '@/shared/organisms';
import { useSelectPackageInfluencersMapper } from './useSelectPackageInfluencersMapper';
import { useToggleSelectedSocialAccountsForPackage } from './useToggleSelectedSocialAccountsForPackage';

interface Props {
  socialMedia: keyof typeof SocialAccountType;
}

export const useSocialAccountSelectButtonProps = <
  Profile extends Record<string, any> & {
    id: number | string;
    socialAccountId?: number | string | null;
    socialAccountType: SocialAccountType;
  }
>({
  socialMedia
}: Props) => {
  const { t } = useTranslation();
  const { toggleSocialMediaSelectedInfluencer } = useToggleSelectedSocialAccountsForPackage();
  const { selectedInfluencersMapIds, proposalSummary } = useSelectPackageInfluencersMapper();
  const [togglingState, setTogglingState] = useState<{ id: number | null; loading: boolean }>({
    id: null,
    loading: false
  });

  const resetLoadingState = () => {
    setTogglingState({ id: null, loading: false });
  };

  useDeepCompareEffect(() => {
    resetLoadingState();
  }, [selectedInfluencersMapIds]);

  const getSortSelectButtonProps = (listRecords: readonly Profile[]) => {
    const { selectedIds, selectedSocialMediaAccounts } = listRecords.reduce<{
      selectedIds: number[];
      selectedSocialMediaAccounts: SelectedSocialMediaAccountsType[];
    }>(
      (rs, infl) => {
        const inflId = Number(infl.id);
        const socialAccountId = Number(infl.socialAccountId);

        if (
          selectedInfluencersMapIds.get(inflId)?.get(socialMedia)?.includes(socialAccountId) &&
          !rs.selectedIds.includes(socialAccountId)
        ) {
          rs.selectedIds.push(socialAccountId);
          rs.selectedSocialMediaAccounts.push({
            socialAccountId: infl.socialAccountId,
            socialAccountType: infl.socialAccountType,
            influencerId: inflId
          });
        }
        return rs;
      },
      { selectedIds: [], selectedSocialMediaAccounts: [] }
    );
    const listRecordsLength = listRecords.length;
    const loading = !togglingState.id && togglingState.loading;
    const numOfSelectedAccountsInPage = selectedIds.length;
    const hasAtLeastOneAccountSelectedInPage = numOfSelectedAccountsInPage > 0;
    const hasAllAccountsSelectedInPage = listRecordsLength > 0 && selectedIds.length >= listRecordsLength;

    return {
      loading,
      hasAllSelected: hasAllAccountsSelectedInPage,
      indeterminate: numOfSelectedAccountsInPage !== listRecordsLength,
      selectedSocialMediaAccounts,
      checked: hasAtLeastOneAccountSelectedInPage,
      onClick: async () => {
        setTogglingState({ id: null, loading: true });
        const { error } = await toggleSocialMediaSelectedInfluencer({
          isChecked: hasAllAccountsSelectedInPage,
          socialType: SocialAccountType[socialMedia],
          socialAccountIds: listRecords.map((i) => Number(i.socialAccountId)) || [],
          selectedSocialMediaAccounts: listRecords.map((acc) => ({
            socialAccountType: acc.socialAccountType,
            socialAccountId: acc.socialAccountId,
            influencerId: Number(acc.id)
          }))
        });
        if (error) {
          resetLoadingState();
        }
      },
      disabled: loading || listRecords.length === 0,
      label: hasAtLeastOneAccountSelectedInPage
        ? t('numberInfluencersAddedToPackage', {
            count: numOfSelectedAccountsInPage,
            packageName: proposalSummary?.name
          })
        : t('addInfluencersToPackage', { packageName: proposalSummary?.name })
    };
  };

  const getListSelectButtonProps = (profile: Profile) => {
    const id = Number(profile.id);
    const socialAccountId = Number(profile.socialAccountId);
    const socialAccountMedia = SocialAccountType[socialMedia];
    const loading = togglingState.id === profile.socialAccountId && togglingState.loading;
    const isSelected = selectedInfluencersMapIds?.get(id)?.get(socialAccountMedia)?.includes(socialAccountId) || false;

    return {
      loading,
      checked: isSelected,
      disabled: loading,
      onClick: async () => {
        setTogglingState({ id: Number(profile.socialAccountId), loading: true });
        const { error } = await toggleSocialMediaSelectedInfluencer({
          socialType: socialAccountMedia,
          isChecked: isSelected,
          selectedSocialMediaAccounts: [
            {
              socialAccountId: Number(profile.socialAccountId),
              socialAccountType: socialAccountMedia,
              influencerId: id
            }
          ],
          socialAccountIds: [socialAccountId]
        });
        if (error) {
          resetLoadingState();
        }
      }
    };
  };

  return {
    getSortSelectButtonProps,
    getListSelectButtonProps
  };
};
