import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DenyInfluencersUserProposal.graphql';
import { Mutation, MutationdenyInfluencersUserProposalArgs } from '../../__generated__/globalTypes';

export const useDenyInfluencersUserProposalMutation = (
  options?: MutationHookOptions<Mutation, MutationdenyInfluencersUserProposalArgs>
) => {
  const [callDenyInfluencersUserProposal, result] = useMutation<Mutation, MutationdenyInfluencersUserProposalArgs>(
    MUTATION,
    options
  );

  return { callDenyInfluencersUserProposal, ...result };
};

export type DenyInfluencersUserProposalMutationFunction = Mutation['denyInfluencersUserProposal'];
