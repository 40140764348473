import { linkOptions } from '@tanstack/react-router';
import { KeywordFilter } from '@/shared/molecules';
import { LIMIT_6 } from '@/shared/constants';
import { useYoutubeAnalyticsCommentsQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { AnalyticsFeedPostsWrapper } from '../../shared';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { AnalyticsYoutubeCommentsList } from '../../../AnalyticsPostsList';
import { useAnalyticsProfileSearch } from '../../hooks';

interface AnalyticsYoutubeDashboardCommentsProps {
  brandAccountName?: string;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsYoutubeDashboardComments = ({
  analyticsAccount,
  brandAccountName
}: AnalyticsYoutubeDashboardCommentsProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter, params } = useAnalyticsProfileSearch();
  const { data, loading } = useYoutubeAnalyticsCommentsQuery({
    variables: getValuableVariables({
      offset: 0,
      limit: LIMIT_6,
      endDate: filter.endDate,
      startDate: filter.startDate,
      youtubeAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  const comments = data?.youtubeAnalyticsComments.comments || [];

  const getMoreLink = (keyword?: string) =>
    linkOptions({
      to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments',
      params,
      search: { filter: { keyword, brandAccountName } }
    });

  return (
    <AnalyticsFeedPostsWrapper
      loading={loading}
      dataNotAvailable={!comments.length}
      title={t('HeaderColumn.Comments')}
      moreDetailProps={{ ...getMoreLink() }}
    >
      <KeywordFilter
        filter={{ keyword: '' }}
        css={{ borderRadius: 0, padding: '0 16px' }}
        setFilter={({ keyword }) => navigate(getMoreLink(keyword))}
      />
      <AnalyticsYoutubeCommentsList totalRecords={comments.length} data={comments} css={{ borderTop: 0 }} />
    </AnalyticsFeedPostsWrapper>
  );
};
