import { useTranslation } from 'react-i18next';
import { Tabs } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useReportPermissions } from '@/auth';
import { TikTokSpecialCampaignDetail } from '@/graphql';
import { getAccessTikTokCampaignTab } from './utils';

interface TikTokCampaignTabsProps {
  tiktokSpecialCampaign: TikTokSpecialCampaignDetail;
}

export const TikTokCampaignTabs = ({ tiktokSpecialCampaign }: TikTokCampaignTabsProps) => {
  const { t } = useTranslation();
  const { hideReportLink } = useReportPermissions();

  const campaignId = String(tiktokSpecialCampaign?.id || '');

  return (
    <Tabs
      hasIndex
      css={THEME.campaign.customTabStyles}
      options={[
        {
          end: true,
          label: t('Campaign Information'),
          to: '/tiktok/campaigns/$id',
          params: { id: campaignId }
        },
        {
          end: false,
          label: t('Available Influencers'),
          to: '/tiktok/campaigns/$id/influencers',
          search: { filter: { packageId: tiktokSpecialCampaign.priceItem.packageId?.toString() || '' } }
        },
        {
          end: false,
          label: t('General.Posts'),
          hidden: !getAccessTikTokCampaignTab('Posts', tiktokSpecialCampaign?.status),
          to: '/tiktok/campaigns/$id/posts',
          params: { id: campaignId }
        },
        {
          end: true,
          label: t('General.Report'),
          to: '/tiktok/campaigns/$id/report',
          params: { id: campaignId },
          hidden: !getAccessTikTokCampaignTab('Report', tiktokSpecialCampaign?.status) || hideReportLink
        }
      ]}
    />
  );
};
