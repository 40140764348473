import { useTranslation } from 'react-i18next';
import { useParams } from '@tanstack/react-router';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { AnalyticsYoutubeCompareVideosType, ProfilePostsCount, ProfilePostsFilter } from '@/shared/organisms';
import { useInfluencerYoutubeAnalyticsPostsQuery } from '@/graphql';
import { InfluencerYoutubeCompareMainSearchType } from '../types';
import { YoutubeCompareVideosTable } from '../YoutubeCompareVideosTable';

export const YouTubeCompareVideosMainAccount = () => {
  const { socialMediaAccountId: mainAccountId } = useParams({
    from: '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos'
  });
  const { t } = useTranslation();
  const {
    page,
    sort,
    limit,
    setFilter,
    filter: { endDate, startDate, keyword }
  } = useParamsSearch<InfluencerYoutubeCompareMainSearchType>();
  const { data, previousData, loading } = useInfluencerYoutubeAnalyticsPostsQuery({
    skip: !mainAccountId,
    variables: getValuableVariables({
      limit,
      keyword,
      endDate,
      startDate,
      orderBy: sort,
      offset: getOffset(page, limit),
      socialAccountId: Number(mainAccountId)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerYoutubeAnalyticsPostsCount.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter
        setFilter={setFilter}
        css={{ borderRadius: 0 }}
        filter={{ keyword, endDate, startDate }}
        hasPeriod
      />

      <ProfilePostsCount count={totalRecords} countLabel={t('Videos found')} />

      <YoutubeCompareVideosTable
        loading={loading}
        socialMediaAccountId={Number(mainAccountId)}
        data={
          result?.influencerYoutubeAnalyticsPosts.posts.map<AnalyticsYoutubeCompareVideosType>((post) => ({
            ...post,
            title: post.videoTitle,
            duration: post.videoDuration,
            thumbnail: post.videoThumbnail
          })) || []
        }
      />
    </ListWithPagination>
  );
};
