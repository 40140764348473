import { css } from '@emotion/react';
import { InstagramCompareFollowers } from '@/graphql';
import { TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { GenderChart, AgeGenderChart, InfluencerAvatar } from '@/shared/molecules';
import { AnalyticsInstagramComparableAccountType } from '../types';

interface TemplateProps {
  itemsPerRow?: number;
  data: InstagramCompareFollowers;
  comparableAccounts: AnalyticsInstagramComparableAccountType[];
}

export const Template = ({ data, itemsPerRow = 2, comparableAccounts }: TemplateProps) => {
  const composeData = [
    data.mainAccount,
    ...(data.compareAccounts.filter((account) => account.id !== data.mainAccount.id) || [])
  ];

  return (
    <div css={{ display: 'flex', flexWrap: 'wrap' }}>
      {comparableAccounts.map(({ id, username, avatarUrl }) => {
        const audienceData = composeData.find((item) => item.id === id);
        const noGenderData = !(
          audienceData?.followerGenderRate.femaleRate || audienceData?.followerGenderRate.maleRate
        );

        return (
          <div key={id} css={styles.item(itemsPerRow, comparableAccounts.length)}>
            <div css={styles.header}>
              <div css={{ display: 'flex', alignItems: 'center', width: '30%' }}>
                <InfluencerAvatar size="40px" src={avatarUrl} css={{ borderRadius: '50%' }} />
                <TextCutter text={username} css={{ fontSize: '13px', marginLeft: '8px' }} />
              </div>

              <GenderChart
                male={audienceData?.followerGenderRate.maleRate}
                female={audienceData?.followerGenderRate.femaleRate}
                css={[{ width: '70%' }, noGenderData && { zoom: 0.5, minHeight: 'inherit' }]}
              />
            </div>

            <AgeGenderChart
              maleRate={audienceData?.followerAgeRate.maleRate}
              femaleRate={audienceData?.followerAgeRate.femaleRate}
              ageGroup={
                audienceData?.followerAgeRate.ageGroup?.map((item) =>
                  item.start === 65 ? '65+' : `${item.start}-${item.end}`
                ) || []
              }
            />
          </div>
        );
      })}
    </div>
  );
};
const styles = {
  header: css({
    gap: '16px',
    display: 'flex',
    minHeight: '116px',
    marginBottom: '12px',
    alignItems: 'center'
  }),
  item: (itemsPerRow: number, totalRecords: number) =>
    css({
      '--size': `calc(100% / ${itemsPerRow})`,

      padding: '16px',
      flex: 'var(--size)',
      boxSizing: 'border-box',
      maxWidth: 'var(--size)',
      borderRight: THEME.border.base,
      borderBottom: THEME.border.base,

      // Remove last element in row and last row border
      [`&:nth-of-type(${itemsPerRow}n + ${itemsPerRow})`]: { borderRight: 'none' },
      [`&:nth-last-of-type(-n + ${totalRecords % itemsPerRow || itemsPerRow})`]: { borderBottom: 'none' }
    })
};
