import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserRoles } from '@/auth';
import { FormStyle } from '@/shared/atoms';
import { useAllCountryOptions, useAllPackageCompanyOptions } from '@/shared/hooks';
import { SelectorField, TextField } from '@/shared/molecules';

import { PackageFormValues, PackageFormKeys } from './types';

interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { allCountryOptions } = useAllCountryOptions();
  const { isAdmin, isStaff, isPartner, isTalentAgency } = useUserRoles();
  const {
    getValues,
    formState: { isSubmitting }
  } = useFormContext<PackageFormValues>();
  const values = getValues();
  const { allPackageCompanyOptions } = useAllPackageCompanyOptions({
    skip: !(isAdmin || (!!values.id && (isStaff || isPartner || isTalentAgency)))
  });

  return (
    <FormStyle.Wrapper css={{ margin: 0 }} hasBorder>
      <div css={{ padding: '24px' }}>
        <FormStyle.SectionTitle label={values.name || t('New Package')} />

        <TextField<PackageFormKeys>
          name="name"
          title={t('TextForm.Package Name')}
          placeholder={t<string>('package name')}
          required
        />

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <SelectorField<PackageFormKeys>
            name="countryId"
            title={t('Selector.Country')}
            options={allCountryOptions}
            required
          />

          {allPackageCompanyOptions.length ? (
            <SelectorField<PackageFormKeys>
              title={t('Selector.Company')}
              name="companies"
              options={allPackageCompanyOptions}
              disabled={isStaff || isPartner || isTalentAgency}
              multiple
            />
          ) : null}
        </FormStyle.FieldsGroup>
      </div>

      <FormStyle.ButtonSubmitWrapper>
        <FormStyle.ButtonSubmit
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={isSubmitting}
          title={t('Button.Save')}
        />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};
