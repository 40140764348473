import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { FilterAction } from '@/shared/types';
import { getRestApiUrl } from '@/shared/utils';
import { DownloadButton } from '@/shared/atoms';
import { useDownloadFile } from '@/shared/hooks';
import { DayPickerRange } from '@/shared/molecules';
import { AutoManagedReportFilterFormValues } from './schemaTypes';

interface AffiliateReportFilterProps extends FilterAction<AutoManagedReportFilterFormValues> {
  campaignId: number;
}
export const AutoManagedReportFilter = ({ filter, setFilter, campaignId }: AffiliateReportFilterProps) => {
  const { downloading, handleDownloadFile } = useDownloadFile();

  return (
    <div css={styles.wrapper}>
      <DayPickerRange
        rangeList={[]}
        handleChangePeriod={setFilter}
        disabledRange={{ after: new Date() }}
        period={{ endDate: filter.endDate || '', startDate: filter.startDate || '' }}
      />
      <DownloadButton
        disabled={downloading}
        onClick={() =>
          handleDownloadFile({
            filenamePrefix: `report_by_${filter.mode}`,
            params: { endDate: filter.endDate, startDate: filter.startDate, showBy: filter.mode },
            url: getRestApiUrl(`report/campaign/auto_managed/${campaignId}/affiliate_report/csv/`)
          })
        }
      />
    </div>
  );
};
const styles = {
  wrapper: css({
    gap: '8px',
    display: 'flex',
    padding: '16px',
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
    backgroundColor: THEME.background.colors.white
  })
};
