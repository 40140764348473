import { CSSObject } from '@emotion/react';
import { THEME } from '@/shared/constants';

export const stickyInfluencersSearchHeight = '98px';
export const stickyInfluencersSearch: CSSObject = {
  position: 'sticky',
  zIndex: 10,
  top: stickyInfluencersSearchHeight
};
export const stickyInfluencersSearchHeader: CSSObject = {
  position: 'sticky',
  top: 0,
  zIndex: 10,
  height: stickyInfluencersSearchHeight,
  // before & after are used to cover box-shadow of the scrollable list, it becomes slightly visible next to Header
  '&:before': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: '-2px',
    display: 'block',
    width: '2px',
    height: '50px',
    background: THEME.background.colors.gray.lv1
  },
  '&:after': {
    content: "''",
    position: 'absolute',
    top: 0,
    right: '-2px',
    display: 'block',
    width: '2px',
    height: '50px',
    background: THEME.background.colors.gray.lv1
  }
};
