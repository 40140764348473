import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthData } from '@/auth';
import { useGetCouponsListPromiseQuery } from '@/graphql';
import { iphoneImage } from '@/shared/assets';
import { Icon, Portal } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useDeepCompareEffect } from '@/shared/hooks';
import { MarketplaceFormValues } from '../types';
import { DIMENSIONS, postFormValuesToPreviewIframe } from './utils';

declare const ANY_CREATOR_ORIGIN: string;

export const AnyCreatorIframePreview = () => {
  const {
    auth: { role }
  } = useAuthData();
  const { t, i18n } = useTranslation();
  const [opened, setOpened] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { watch } = useFormContext<MarketplaceFormValues>();
  const [couponCodes, setCouponCodes] = useState<string[]>([]);
  const { getGetCouponsList } = useGetCouponsListPromiseQuery();

  useEffect(() => {
    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [couponCodes]);

  const values = watch();

  const handlePostMessage = (
    event: MessageEvent<{
      type?: string;
    }>
  ) => {
    if (event?.data?.type === 'preview_iframe_ready') {
      postFormValuesToPreviewIframe({ t, values, iframeRef, language: i18n.language, couponCodes, role });
    }
  };

  useDeepCompareEffect(() => {
    postFormValuesToPreviewIframe({ t, values, iframeRef, language: i18n.language, couponCodes, role });
  }, [values, iframeRef, i18n.language, couponCodes]);

  const getGetCouponsListHandler = async () => {
    const res = await Promise.all(
      values.couponUploadFiles.map(async (fileUrl) => {
        const { data } = await getGetCouponsList({ variables: { fileUrl } });

        return data.getCouponsList || [];
      })
    );
    setCouponCodes(res.flat());
  };

  useEffect(() => {
    getGetCouponsListHandler();
  }, [values.couponUploadFiles.length]);

  const activeClass = opened ? 'active' : 'inactive';
  const iframeIcon = opened ? 'caret-down' : 'preview';

  const today = new Date();
  const formattedTime = `${today.getHours()}:${today.getMinutes()}`;

  return (
    <Portal portalId="portal-root">
      <div css={styles.iphoneStatusBar} className={activeClass}>
        <span css={[styles.time, styles.backdropBlur]}>{formattedTime}</span>
        <img src={iphoneImage} width="68px" css={[{ marginRight: '14px' }, styles.backdropBlur]} alt="Iphone frame" />
      </div>

      <iframe
        title="preview of AnyCreator web app"
        ref={iframeRef}
        src={`${ANY_CREATOR_ORIGIN}/public/campaign_details`}
        loading="eager"
        css={styles.iframe}
        className={activeClass}
      />

      <div css={styles.toggle} onClick={() => setOpened((prevState) => !prevState)} className={activeClass}>
        <Icon icon={iframeIcon} width="16px" color="#fff" />
        {!opened ? <span css={styles.toggleText}>{t('Mobile preview')}</span> : null}
      </div>
    </Portal>
  );
};

const styles = {
  backdropBlur: css({ backdropFilter: 'blur(2px)' }),
  toggleText: css({ color: THEME.text.colors.white, fontSize: '14px', fontWeight: 600 }),
  time: css({
    width: '54px',
    height: '18px',
    fontWeight: 600,
    fontSize: '15px',
    marginLeft: '20px',
    lineHeight: '18px',
    color: THEME.text.colors.black.lv2
  }),
  toggle: css({
    zIndex: 10,
    gap: '12px',
    width: '40px',
    height: '40px',
    bottom: '24px',
    display: 'flex',
    position: 'fixed',
    cursor: 'pointer',
    marginLeft: 'auto',
    alignItems: 'center',
    borderRadius: '9999px',
    justifyContent: 'center',
    backgroundColor: '#27313b',
    right: `${DIMENSIONS.rightDistance}px`,
    transition: 'all 0.3s ease-in-out',
    '&:hover': { opacity: 0.9 },
    '&.inactive': {
      minWidth: '180px',
      '& span': {
        opacity: 1,
        width: 'auto',
        marginLeft: '12px',
        transition: 'opacity 0.3s ease-in-out'
      }
    },
    '& span': {
      opacity: 0,
      width: 0,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      transition: 'all 0.3s ease-in-out'
    },
    '& i': {
      transition: 'transform 0.3s ease-in-out'
    },
    '&.active i': {
      transform: 'rotate(180deg)'
    }
  }),
  iframe: css({
    zIndex: 10,
    position: 'fixed',
    borderStyle: 'none',
    borderRadius: '23px',
    visibility: 'visible',
    width: `${DIMENSIONS.width}px`,
    height: `${DIMENSIONS.height}px`,
    right: `${DIMENSIONS.rightDistance}px`,
    bottom: `${DIMENSIONS.bottomDistance}px`,
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0 2px 1px rgba(110, 124, 137, 0.1), 0 3px 5px rgba(110, 124, 137, 0.2)',
    transition: 'all 0.3s ease-in-out',
    '&.inactive': {
      opacity: 0,
      transform: 'translateY(20px)',
      visibility: 'hidden',
      width: 0,
      height: 0
    }
  }),
  iphoneStatusBar: css({
    zIndex: 11,
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    borderRadius: '32px 32px 0 0',
    justifyContent: 'space-between',
    width: `${DIMENSIONS.width}px`,
    right: `${DIMENSIONS.rightDistance}px`,
    height: `${DIMENSIONS.statusBarHeight}px`,
    bottom: `calc(${DIMENSIONS.bottomDistance}px + ${DIMENSIONS.height}px - ${DIMENSIONS.statusBarHeight}px)`,
    transition: 'all 0.3s ease-in-out',
    '&.inactive': {
      opacity: 0,
      transform: 'translateY(20px)',
      visibility: 'hidden',
      width: 0,
      height: 0
    }
  })
};
