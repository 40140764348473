import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';

export const ProfilePostsFilterSchema = z.object({
  keyword: z.string().optional(),
  endDate: z.string().optional(),
  startDate: z.string().optional(),
  brandAccountName: z.string().optional()
});

export type ProfilePostsFilterFormValues = z.infer<typeof ProfilePostsFilterSchema>;
export type ProfilePostsFilterFormKeys = UnionOfKeys<ProfilePostsFilterFormValues>;
