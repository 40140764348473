import { useTranslation } from 'react-i18next';
import { Button, TextCutter } from '@/shared/atoms';
import { Option } from '@/shared/types';
import { BaseSelector } from '../BaseSelector';
import { MenuControlIcon } from './MenuControlIcon';
import { MenuItemsWithCheckbox } from './MenuItemsWithCheckbox';
import { MenuItemWithCheckBoxProps } from './MenuItemWithCheckBox';
import { MultiSelectorProps, styles } from './MultiSelector';
import { SelectAll } from './SelectAll';
import { getInitialSelectedSubmenuItems } from './utils';

export const MultiSelectorWithSubmenu = (props: MultiSelectorProps) => {
  const {
    name,
    value = [],
    options,
    hasError,
    disabled,
    isAlwaysOpen,
    hasSelectAll = true,
    placeholder,
    onChange,
    onClose,
    canOnlyAddMore,
    initialValues,
    menuCss,
    loading,
    subSelector,
    onClickInputBox,
    menuItemsProps
  } = props;
  const { t } = useTranslation();

  // Items
  const selectedSubmenuOptions = getInitialSelectedSubmenuItems(value, options);
  const setSelectedValue = (items: readonly Option[]) => {
    if (onChange) {
      onChange(items.map((item) => String(item.value)));
    }
  };
  const removeValue = (item: Option) => {
    const isInitialValue = item?.value && initialValues?.includes(item?.value);
    if (canOnlyAddMore && isInitialValue) {
      return;
    }

    setSelectedValue(selectedSubmenuOptions.filter((i) => i.value !== item.value));
  };
  const handleClear = () => {
    onChange?.([]);
  };

  return (
    <BaseSelector
      name={name}
      items={{ selectedValue: selectedSubmenuOptions, setSelectedValue, removeValue }}
      hasError={hasError}
      options={options}
      loading={loading}
      disabled={disabled}
      disabledInputBox={false}
      isAlwaysOpen={isAlwaysOpen}
      onClickInputBox={onClickInputBox}
      placeholder={value.length === 0 ? placeholder : ''}
      onClose={onClose}
      MenuControlIcon={MenuControlIcon}
      renderSelectedValue={({ selectedValue: values }) => (
        <TextCutter
          lines={1}
          text={
            values.length > 2 ? t('Selected', { count: values.length }) : values.map((item) => item.label).join(', ')
          }
          css={{ position: 'absolute', padding: '0 16px 0 8px' }}
        />
      )}
      menuCss={menuCss}
      renderMenu={({ items, search, menu }) => {
        const isSelectedAll = search.filteredValue
          .reduce<Option[][]>((acc, curr) => (curr.submenu ? [...acc, curr.submenu] : acc), [])
          .flat()
          .every((v) => selectedSubmenuOptions.map((o) => o.value).includes(v.value));

        const isSelectedNoting = items.selectedValue.length === 0;

        const handleSelectAll = () => {
          if (disabled) {
            return;
          }

          items.setSelectedValue(
            isSelectedAll ? [] : options.map<Option[]>((o) => (o.submenu || []).map((s) => s)).flat()
          );
        };

        const handleClickItem = (item: Option) => {
          if (disabled) {
            return;
          }
          const isSelected = items.selectedValue.some((o) => o.value === item.value);

          // Remove Item
          if (isSelected) {
            items.removeValue(item);

            return;
          }
          // Add Item
          items.setSelectedValue([...items.selectedValue, item]);
        };

        const handleClickSubmenuGroup: MenuItemWithCheckBoxProps['handleClickSubmenuGroup'] = (
          submenuItems,
          allSubmenusSelected
        ) => {
          if (disabled) {
            return;
          }

          if (allSubmenusSelected) {
            const filteredItems = items.selectedValue.filter((item) =>
              submenuItems.every((s) => s.value !== item.value)
            );
            items.setSelectedValue(filteredItems);
          } else {
            items.setSelectedValue([...items.selectedValue, ...submenuItems]);
          }
        };

        return (
          <>
            {hasSelectAll && (
              <div css={{ display: 'flex', borderBottom: '1px solid #e4e4e4' }}>
                <SelectAll
                  disabled={disabled}
                  css={{ border: 'none' }}
                  onClick={handleSelectAll}
                  checked={!isSelectedNoting}
                  indeterminate={!isSelectedNoting && !isSelectedAll}
                />

                {subSelector}
              </div>
            )}
            <div css={styles.menuWrapper(undefined, menuItemsProps?.type === 'checkbox-with-submenu')}>
              <MenuItemsWithCheckbox
                disabled={disabled}
                options={search.filteredValue}
                handleClick={handleClickItem}
                handleClickSubmenuGroup={handleClickSubmenuGroup}
                checkIsSelected={(option: Option) => items.selectedValue.some((o) => o.value === option.value)}
                {...menuItemsProps}
              />
            </div>
            <div
              css={{
                borderTop: '1px solid #e4e4e4',
                padding: '8px 12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Button
                variant="white"
                title={t('Button.Clear')}
                onClick={handleClear}
                css={{ height: '24px', minWidth: '64px', textTransform: 'capitalize' }}
              />
              <Button
                variant="blue"
                title={t('Button.Apply')}
                onClick={() => {
                  menu.setIsOpen(false);
                  onClose?.();
                }}
                css={{ justifySelf: 'flex-end', height: '24px', minWidth: '67px', textTransform: 'capitalize' }}
              />
            </div>
          </>
        );
      }}
    />
  );
};
