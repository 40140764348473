import { THEME } from '@/shared/constants';
import { FormAction } from '@/shared/types';
import { SingleMonthPickerField } from '@/shared/molecules';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { useExportCmsInvoiceToSpreadsheetMutation } from '@/graphql';
import { DownloadButton, FormProvider, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { useInvoicesFilter } from '../InvoicesFilter';

interface ExportFormValues {
  month: string;
}

interface ExportButtonProps {
  hasNoData?: boolean;
}

export const ExportButton = ({ hasNoData }: ExportButtonProps) => {
  const modal = useToggleState();
  const { filter } = useInvoicesFilter();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { loading, callExportCmsInvoiceToSpreadsheet } = useExportCmsInvoiceToSpreadsheetMutation();

  const handleExport: FormAction<ExportFormValues>['onSubmit'] = async ({ month }) => {
    try {
      await callExportCmsInvoiceToSpreadsheet({ variables: { input: { month } } });
      enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
      modal.close();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <DownloadButton
        onClick={modal.open}
        disabled={hasNoData}
        title={t('Button.Export')}
        css={{ paddingRight: '16px', minWidth: 'unset', width: 'max-content' }}
      />

      <Modal
        open={modal.status}
        onClose={modal.close}
        css={{ width: THEME.modal.size.lv1 }}
        style={{ overlay: { zIndex: 290 } }} // Fix: set zIndex lower than DatePicker to avoid raising submit event when click range options
      >
        <FormProvider<ExportFormValues> defaultValues={{ month: filter.month }} onSubmit={handleExport}>
          {({ values, onSubmit }) => (
            <>
              <ModalContent>
                <ModalTitle>{t('Invoice Export')}</ModalTitle>
                <SingleMonthPickerField
                  name="month"
                  clearable={false}
                  title={t('Select Month')}
                  css={{ marginTop: '16px' }}
                />
              </ModalContent>
              <ModalFooterActions
                cancelButtonProps={{ onClick: modal.close }}
                submitButtonProps={{
                  onClick: onSubmit,
                  disabled: !values.month,
                  title: t('Button.Export'),
                  loading: { status: loading, showIcon: true }
                }}
              />
            </>
          )}
        </FormProvider>
      </Modal>
    </>
  );
};
