import { z } from 'zod';
import getDeepValue from 'lodash/get';
import { TFunction } from 'i18next';
import { Option } from '@/shared/types';
import { Genders, UserRoles } from '@/graphql';

export const formatDynamicErrorMessage = (t: TFunction, msg?: string) => {
  if (!msg) {
    return '';
  }
  const [base, n] = msg.split('_');

  return t<string>(base || '', { n });
};
export const getOptions = <T extends { id: number | string; name: string }>(items?: ReadonlyArray<T | null> | null) =>
  items?.reduce((rs, cr) => (cr ? [...rs, { value: String(cr.id), label: cr.name }] : rs), [] as Option[]) || [];

export const getGenderString = (gender: Genders | null) => {
  switch (gender) {
    case Genders.MALE:
      return 'Male';
    case Genders.FEMALE:
      return 'Female';
    case Genders.NOT_APPLICABLE:
    case Genders.UNKNOWN:
      return 'Others';
    default:
      return '';
  }
};
export const splitPartnerAndTalentAgenciesIds = (
  talentAgencyOrPartnerIds: number[],
  allTalentAgenciesPartners: readonly { id: number; idOfTalentOrPartner: number; name: string; role: UserRoles }[]
) =>
  allTalentAgenciesPartners.reduce<{ partnerIds: number[]; talentAgencyIds: number[] }>(
    (acc, curr) => {
      if (talentAgencyOrPartnerIds.includes(curr.id)) {
        return curr.role === UserRoles.PARTNER
          ? { talentAgencyIds: acc.talentAgencyIds, partnerIds: [...acc.partnerIds, curr.idOfTalentOrPartner] }
          : { partnerIds: acc.partnerIds, talentAgencyIds: [...acc.talentAgencyIds, curr.idOfTalentOrPartner] };
      }

      return acc;
    },
    { talentAgencyIds: [], partnerIds: [] }
  );

export const superRefineField = (values: object, ctx: z.RefinementCtx) => ({
  refineField: (path: string, schema: z.ZodType) => {
    const validationResult = schema.safeParse(getDeepValue(values, path));

    if (!validationResult.success) {
      ctx.addIssue({
        path: [path],
        code: z.ZodIssueCode.custom,
        message: validationResult.error.issues[0]?.message
      });
    }
  }
});
