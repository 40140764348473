import { useTranslation } from 'react-i18next';
import { InfluencerSocialPost, PostListSortField, SocialPostType } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { SortAction } from '@/shared/types';
import { formatIntNumber } from '@/shared/utils';

type CustomColumnProps = ColumnProps<PostListSortField>;

interface Props extends SortAction<PostListSortField> {
  data: readonly InfluencerSocialPost[];
  socialPostType: SocialPostType;
}

export const useInfluencerXhsPostsListTable = ({ data, socialPostType }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    {
      title: <div css={{ display: 'flex', alignItems: 'center' }}>{t('HeaderColumn.Post')}</div>,
      sticky: { active: true, hasBorderRight: true }
    },
    getMetricColumnProps({
      title: t('HeaderColumn.View'),
      sortField: PostListSortField.VIEW,
      help: t<string>('Tooltip.ViewHelp'),
      styles: { width: '15%', minWidth: '120px' }
    }),
    getMetricColumnProps({
      sortField: PostListSortField.LIKE,
      help: t<string>('Tooltip.LikeHelp'),
      title: t('HeaderColumn.Like'),
      styles: { width: '15%', minWidth: '120px' }
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Save'),
      sortField: PostListSortField.SAVED,
      help: t<string>('Tooltip.SaveHelp'),
      styles: { width: '15%', minWidth: '120px' }
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Post Type'),
      help: t<string>('Tooltip.PostTypeHelp'),
      styles: { paddingRight: '28px' }
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      sortField: PostListSortField.POST_DATE,
      help: t<string>('Tooltip.PostDateHelp')
    })
  ];
  const rows: RowProps[] = data.map(
    ({ id, views, likes, saved, caption, date, thumbnail, postUrl, isSponsoredPost }) => [
      <TableCell.Post
        postUrl={postUrl}
        title={caption || ''}
        thumbnail={thumbnail || ''}
        socialPostType={socialPostType}
        onClick={() => setPostDetailsParam(id)}
      />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(saved)} />,
      <TableCell.Text value={isSponsoredPost ? 'Sponsored Post' : 'Normal Post'} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={date} />
    ]
  );

  return { rows, columns };
};
