import { useEffect } from 'react';
import { useSearch } from '@tanstack/react-router';
import { useAuthTiktokBusinessAccountMutation } from '@/graphql';
import { Loading } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';

export const RedirectTikTokBusinessConnect = () => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callAuthTiktokBusinessAccount } = useAuthTiktokBusinessAccountMutation();

  useEffect(() => {
    reconnectAccount();
  }, []);

  const search = useSearch({
    from: '/_private-routes/redirect/connect/tiktok-business'
  }) satisfies { auth_code: string };

  const reconnectAccount = async () => {
    try {
      if (search.auth_code) {
        await callAuthTiktokBusinessAccount({ variables: { input: { authCode: search.auth_code } } });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message || 'Fail to connect account, please try again later'), { variant: 'error' });
    } finally {
      navigate({ to: '/tiktok-onboarding/ad-account' });
    }
  };

  return <Loading />;
};
