import { useState } from 'react';
import { POPUP_PARAM_KEY } from '@/shared/constants';
import { PostDetailsType } from '@/shared/types';
import { useDeepCompareEffect } from './useDeepCompareEffect';
import { usePostDetailsParam } from './usePostDetailsParam';

type PostDetailsModalType = Pick<PostDetailsType, 'id'>;

interface Props<T extends PostDetailsModalType> {
  posts: readonly T[];
  paramKey?: string;
}

export const useControlPostDetailsModal = <T extends PostDetailsModalType>({
  posts,
  paramKey = POPUP_PARAM_KEY
}: Props<T>) => {
  const { urlPostId, setPostDetailsParam, onClosePostDetails } = usePostDetailsParam(paramKey);
  const [selectedPost, setSelectedPost] = useState<T | null>(null);

  const filteredPosts = posts.filter((post) => !!post.id);

  useDeepCompareEffect(() => {
    const post = urlPostId
      ? filteredPosts.find((p) => (typeof p.id === 'number' ? Number(p.id) === Number(urlPostId) : p.id === urlPostId))
      : null;
    setSelectedPost(post || null);
  }, [urlPostId, filteredPosts]);

  const currentIndex = filteredPosts.findIndex((post) => post.id === selectedPost?.id);
  const prevId = filteredPosts[currentIndex - 1]?.id || null;
  const nextId = filteredPosts[currentIndex + 1]?.id || null;

  const handleClickNext = () => {
    setPostDetailsParam(nextId);
  };

  const handleClickPrev = () => {
    setPostDetailsParam(prevId);
  };

  return {
    closePost: onClosePostDetails,
    selectedPost,
    handleClickPrev,
    handleClickNext,
    siblingPostId: { prevId, nextId }
  };
};
