import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { PackageProposalSocialAcccountBudgetInput } from '@/graphql';
import { BlackBar, BlackBarProps, Button, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { PackageInfluencersDownloadButton } from '../PackageDownloadButton';

interface PackageActionBarProps extends Omit<BlackBarProps, 'children'> {
  packageId?: number | null;
  isViewMode?: boolean;
  numberSelected: number;
  hasAllSelected: boolean;
  isRemoving?: boolean;
  onSelectAll: () => void;
  onToggleSelect: () => void;
  selectedInfluencerAccountIds: number[];
  onToggleBulkEditModal: () => void;
  isSocialMediaAccountsSelection?: boolean;
  onRemove: (callback: () => void) => void;
  selectedSocialAccounts: PackageProposalSocialAcccountBudgetInput[];
}

export const PackageActionBar = ({
  packageId,
  isViewMode,
  onSelectAll,
  onRemove,
  isRemoving,
  numberSelected,
  hasAllSelected,
  onToggleSelect,
  onToggleBulkEditModal,
  selectedInfluencerAccountIds
}: PackageActionBarProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const removeModal = useToggleState();

  return (
    <BlackBar
      selectAllProps={{ onClick: onSelectAll }}
      checkboxProps={{
        onClick: onToggleSelect,
        indeterminate: !hasAllSelected,
        label: t('Selected', { count: numberSelected })
      }}
    >
      {isViewMode ? (
        <div css={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: 'auto' }}>
          {isAdminStaff && (
            <>
              <Button variant="red" onClick={removeModal.open} title={t('Button.Remove')} css={{ padding: '0 24px' }} />
              <Modal
                open={removeModal.status}
                onClose={removeModal.close}
                css={{ width: THEME.modal.size.lv1 }}
                hasCloseIcon
              >
                <ModalContent>
                  <ModalTitle>{t('Modal.RemoveInfluencerTitle')}</ModalTitle>
                  <p css={{ fontSize: '14px', marginTop: '24px', lineHeight: '140%' }}>
                    {t('Modal.RemoveInfluencerDescription')}
                  </p>
                </ModalContent>

                <ModalFooterActions
                  cancelButtonProps={{ onClick: removeModal.close }}
                  submitButtonProps={{
                    variant: 'red',
                    title: t('Button.Remove'),
                    onClick: () => onRemove(removeModal.close),
                    loading: { showIcon: true, status: isRemoving }
                  }}
                />
              </Modal>
            </>
          )}
          <PackageInfluencersDownloadButton
            packageId={Number(packageId)}
            selectedInfluencerIds={selectedInfluencerAccountIds}
          />
        </div>
      ) : (
        <Button
          variant="white"
          title={t('Button.Bulk Edit')}
          onClick={onToggleBulkEditModal}
          css={{ padding: '0 16px', marginLeft: '16px' }}
        />
      )}
    </BlackBar>
  );
};
