import { ParsedLocation } from '@tanstack/react-router';
import { useState } from 'react';

export const useManageLastLocation = () => {
  // TODO DEMO: show the "Back to list" link
  const [savedLocation, setSavedLocation] = useState<ParsedLocation | undefined>(undefined);

  return {
    savedLocation,
    setSavedLocation,
    clearLastLocation: () => {
      setSavedLocation(undefined);
    }
  };
};
