import { useTranslation } from 'react-i18next';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { ReadPackageInfluencers, SocialAccountStatus } from '@/graphql';
import { bigIntFormatter, formatIntNumber, formatPercent } from '@/shared/utils';
import { InfluencerAvatar, InfluencerWidgetInformation } from '@/shared/molecules';
import { StatsSection, SocialMediasSection, ProfileWidgetTemplate, ProfileWidgetTemplateProps } from '../../shared';

interface ProfileWidgetProps {
  profile: ReadPackageInfluencers;
  selectButtonProps?: ProfileWidgetTemplateProps['selectButtonProps'];
}

export const ProfileWidget = ({
  selectButtonProps,
  profile: {
    age,
    name,
    avatar,
    status,
    gender,
    country,
    influencerId,
    followersCount,
    averageEngagement,
    averageEngagementRate,
    averageEngagementViewsRate,
    isAnySignedUpAccountForTiktok,
    isAnySignedUpAccountForTwitter,
    isAnySignedUpAccountForYoutube,
    isAnySignedUpAccountForFacebook,
    isAnySignedUpAccountForInstagram
  }
}: ProfileWidgetProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const stats = [
    { label: t('HeaderColumn.Total Followers'), value: bigIntFormatter(followersCount, 0) },
    { label: t('HeaderColumn.Engagement'), value: formatIntNumber(averageEngagement) },
    { label: t('HeaderColumn.Egg Rate by views'), value: formatPercent(averageEngagementViewsRate) },
    { label: t('HeaderColumn.Egg Rate by followers'), value: formatPercent(averageEngagementRate) }
  ];
  const isSignedUp = status === SocialAccountStatus.SIGNED_UP;
  const profileUrl = generateProfileLink(influencerId);

  return (
    <ProfileWidgetTemplate selectButtonProps={selectButtonProps}>
      <InfluencerAvatar size="80px" src={avatar} />

      <InfluencerWidgetInformation
        name={name}
        age={age}
        gender={gender}
        country={country}
        profileUrl={profileUrl}
        isSignedUp={isSignedUp}
      />

      <StatsSection id={influencerId} stats={stats} css={{ marginRight: '16px' }} />

      <SocialMediasSection
        profileUrl={profileUrl}
        tiktok={isAnySignedUpAccountForTiktok ?? undefined}
        twitter={isAnySignedUpAccountForTwitter ?? undefined}
        youtube={isAnySignedUpAccountForYoutube ?? undefined}
        facebook={isAnySignedUpAccountForFacebook ?? undefined}
        instagram={isAnySignedUpAccountForInstagram ?? undefined}
      />
    </ProfileWidgetTemplate>
  );
};
