import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { useHashtagsAnalyticsFilter } from './hooks';

export interface HashtagsAnalyticsFilterProps extends Pick<TemplateProps, 'hashtags'> {
  className?: string;
}

export const HashtagsAnalyticsFilter = ({ className, ...templateProps }: HashtagsAnalyticsFilterProps) => {
  const { filter, setFilterForm } = useHashtagsAnalyticsFilter();

  return (
    <FilterWrapper
      css={{ borderRadius: 'none', boxShadow: 'none', maxHeight: '40vh', overflow: 'auto' }}
      className={className}
    >
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template {...templateProps} />} />
    </FilterWrapper>
  );
};

export { useHashtagsAnalyticsFilter };
export * from './schemaTypes';
