import { SeriesOptionsType } from 'highcharts';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import {
  ChartTooltipMultipleMetrics,
  ChartTooltipMultipleMetricsProps,
  ChartTooltipMultipleMetricsSeriesDataType
} from '@/shared/atoms';
import { HighChart } from '@/shared/molecules';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter, generateYAxisBasedOnDisplayedCharts } from '@/shared/utils';

export interface AnalyticsOverviewChartProps<T extends string>
  extends Pick<ChartTooltipMultipleMetricsProps<T>, 'categories' | 'customTooltip'> {
  customOptions?: Highcharts.Options;
  data: Record<T, SeriesOptionsType & ChartTooltipMultipleMetricsSeriesDataType>;
}

export const AnalyticsOverviewChart = <T extends string>({
  data,
  categories,
  customTooltip,
  customOptions
}: AnalyticsOverviewChartProps<T>) => {
  const { i18n } = useTranslation();

  const keys = Object.keys(data) as T[];
  const series = Object.values<SeriesOptionsType>(data);
  const options: Highcharts.Options = {
    series,
    title: { text: '' },
    chart: { type: 'column' },
    legend: { enabled: false },
    yAxis: generateYAxisBasedOnDisplayedCharts(keys, false),
    plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
    xAxis: {
      categories,
      crosshair: true,
      type: 'category',
      labels: {
        formatter() {
          return localizedDateFormatter(this.value, 'MMM do', i18n.language as AppLanguage);
        }
      }
    },
    tooltip: {
      useHTML: true,
      shadow: false,
      outside: true,
      borderRadius: 3,
      borderColor: '#27313b',
      formatter() {
        return renderToStaticMarkup(
          <ChartTooltipMultipleMetrics
            data={data}
            categories={categories}
            selectedCategory={this.key as string}
            customTooltip={customTooltip}
          />
        );
      }
    }
  };

  return <HighChart options={{ ...options, ...customOptions }} />;
};
