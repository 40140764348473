import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagementInfluencersBudget.graphql';
import { Mutation, MutationupdateEngagementInfluencersBudgetArgs } from '../../__generated__/globalTypes';

export const useUpdateEngagementInfluencersBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateEngagementInfluencersBudgetArgs>
) => {
  const [callUpdateEngagementInfluencersBudget, result] = useMutation<
    Mutation,
    MutationupdateEngagementInfluencersBudgetArgs
  >(MUTATION, options);

  return { callUpdateEngagementInfluencersBudget, ...result };
};

export type UpdateEngagementInfluencersBudgetMutationFunction = Mutation['updateEngagementInfluencersBudget'];
