import 'react-day-picker/dist/style.css';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter } from '@/shared/utils';
import { ClearIcon, Icon, TextCutter } from '@/shared/atoms';

export interface MonthPickerInputProps {
  value?: Date;
  hasError?: boolean;
  disabled?: boolean;
  className?: string;
  clearable?: boolean;
  placeholder?: string;
  onClick?: () => void;
  onClear?: () => void;
}
export const MonthPickerInput = ({
  value,
  onClick,
  onClear,
  disabled,
  hasError,
  className,
  placeholder,
  clearable = true
}: MonthPickerInputProps) => {
  const { i18n } = useTranslation();

  const hasValue = !!value;

  return (
    <div onClick={onClick} className={className} css={styles.wrapper({ hasError, disabled })}>
      <Icon icon="calendar" size="16px" color={THEME.datePicker.colors.calendar} className="calendar-icon" />

      <TextCutter
        lines={1}
        css={styles.placeholder({ value, disabled })}
        text={value ? localizedDateFormatter(value, 'yyyy/MM', i18n.language as AppLanguage) : placeholder}
      />

      {hasValue && clearable && !disabled ? (
        <ClearIcon
          css={{ flex: 'none' }}
          onClick={(e) => {
            e.stopPropagation();
            onClear?.();
          }}
        />
      ) : (
        <Icon
          size={12}
          icon="caret-down"
          color={THEME.icon.colors.gray.lv2}
          css={{ flex: 'none', cursor: disabled ? 'default' : 'pointer' }}
        />
      )}
    </div>
  );
};
const styles = {
  wrapper: ({ hasError, disabled }: Pick<MonthPickerInputProps, 'hasError' | 'disabled'>) =>
    css({
      gap: '4px',
      display: 'flex',
      padding: '0 8px',
      fontSize: '14px',
      alignSelf: 'center',
      alignItems: 'center',
      position: 'relative',
      border: '1px solid transparent',
      height: THEME.input.base.height.lv2,
      borderRadius: THEME.input.base.borderRadius,
      backgroundColor: disabled ? THEME.input.background.colors.disabled : THEME.input.background.colors.default,
      borderColor: hasError ? THEME.input.border.colors.error : THEME.input.border.colors.default,
      '&:not(:disabled):focus': { borderColor: THEME.input.border.colors.focus }
    }),
  placeholder: ({ value, disabled }: Pick<MonthPickerInputProps, 'value' | 'disabled'>) =>
    css({
      flex: 1,
      fontWeight: 400,
      fontSize: 'inherit',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      cursor: disabled ? 'default' : 'pointer',
      color: value ? THEME.input.text.colors.default : THEME.input.text.colors.placeholder
    })
};
