import { Table } from '@/shared/atoms';
import { useMarketplacePermissions } from '@/auth';
import { FormAction, TableData } from '@/shared/types';
import { ConfirmDeleteModal } from '@/shared/molecules';
import { useQueryHelper, useSelectItem, useToggleState } from '@/shared/hooks';
import { Marketplace, namedOperations, useCloneMarketplaceMutation, useDeleteMarketplaceMutation } from '@/graphql';
import { useMarketplaceCampaignsTable } from './hooks';
import { SelectedMarketplaceCampaignType } from './type';
import { DuplicateCampaignFormValues, DuplicateCampaignModal } from './DuplicateCampaignModal';

export type MarketplaceCampaignsListProps = TableData<Marketplace>;

export const MarketplaceCampaignsList = ({ loading, data = [] }: MarketplaceCampaignsListProps) => {
  const duplicateModal = useToggleState();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { hideAddMarketplaceBtn } = useMarketplacePermissions();
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem<SelectedMarketplaceCampaignType>();
  const { callCloneMarketplace, loading: cloning } = useCloneMarketplaceMutation({
    refetchQueries: [namedOperations.Query.AllMarketplaces]
  });
  const { callDeleteMarketplace, loading: deleting } = useDeleteMarketplaceMutation({
    refetchQueries: [namedOperations.Query.AllMarketplaces]
  });
  const { rows, columns } = useMarketplaceCampaignsTable({
    data,
    onDelete: setSelectedItem,
    onDuplicate: (campaign) => {
      duplicateModal.open();
      setSelectedItem(campaign);
    }
  });

  const handleDeleteMarketplace = async () => {
    if (!selectedItem || selectedItem.type !== 'delete') {
      return;
    }

    try {
      await callDeleteMarketplace({ variables: { id: selectedItem.id } });
      enqueueSnackbar(t('succeededInDeleting', { name: selectedItem.title }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: selectedItem.title }), { variant: 'error' });
    } finally {
      resetSelectedItem();
    }
  };

  const handleDuplicateMarketplace: FormAction<DuplicateCampaignFormValues>['onSubmit'] = async ({ title }) => {
    if (!selectedItem || selectedItem.type !== 'duplicate') {
      return;
    }

    try {
      const { data } = await callCloneMarketplace({
        variables: { input: { title, duplicateId: selectedItem.id } }
      });
      const createdCampaignId = data?.cloneMarketplace?.id;

      if (createdCampaignId) {
        navigate({ to: '/marketplace/$id', params: { id: createdCampaignId.toString() } });
      } else {
        enqueueSnackbar(t('succeededInCreating', { name: selectedItem.title }), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      resetSelectedItem();
      duplicateModal.close();
    }
  };

  return (
    <>
      <Table
        loading={loading}
        data={{ columns, rows }}
        empty={{
          linkProps: !hideAddMarketplaceBtn ? { to: '/marketplace/add', label: t('General.add a campaign') } : undefined
        }}
        hasBorder
        noTopBorderRadius
      />

      <ConfirmDeleteModal
        deleting={deleting}
        onClose={resetSelectedItem}
        onDelete={handleDeleteMarketplace}
        deletingItem={selectedItem?.type === 'delete' ? { id: selectedItem.id, name: selectedItem.title } : undefined}
      />

      <DuplicateCampaignModal
        cloning={cloning}
        open={duplicateModal.status}
        onClose={duplicateModal.close}
        onDuplicate={handleDuplicateMarketplace}
      />
    </>
  );
};
