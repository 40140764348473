import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { useTableMaxHeight } from '@/shared/hooks';
import { YoutubeCmsEstimateAsset } from '@/graphql';
import { useYoutubeCMSChannelsAssetsTable } from './hooks';

export type YoutubeCMSChannelsAssetsListProps = TableData<YoutubeCmsEstimateAsset>;

export const YoutubeCMSChannelsAssetsList = ({ loading, data = [] }: YoutubeCMSChannelsAssetsListProps) => {
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();
  const { rows, columns } = useYoutubeCMSChannelsAssetsTable({ data });

  return (
    <Table
      ref={offsetRef}
      loading={loading}
      maxHeight={defaultTableMaxHeight}
      data={{ columns, rows, rowHeight: '64px' }}
      noTopBorderRadius
      stickyHeader
    />
  );
};
