import { z } from 'zod';
import { SearchFilterDefaults } from '@/shared/validations';
import { getToday, getThirtyDaysAgo } from '@/shared/utils';
import { YoutubeAnalyticsTagRankingSortOrder } from '@/graphql';
import { ProfilePostsFilterSchema } from '../../ProfilePostsList';

export const ytTagRankingSearchListSchema = z.object({
  filter: SearchFilterDefaults.filter(ProfilePostsFilterSchema, {
    startDate: getThirtyDaysAgo(),
    endDate: getToday()
  }),
  sort: SearchFilterDefaults.sort(YoutubeAnalyticsTagRankingSortOrder).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type YtTagRankingSearchListSchemaType = z.infer<typeof ytTagRankingSearchListSchema>;
