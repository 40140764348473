import { EngagementCampaignsFilterFormValues } from './schemaTypes';

export const defaultEngagementCampaignsFilterValues: EngagementCampaignsFilterFormValues = {
  status: '',
  keyword: '',
  countryIds: [],
  salesPicIds: [],
  hubspotDealIds: [],
  influencerPicIds: [],
  campaignCategoryIds: []
};
