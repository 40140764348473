import { useDeleteUserMutation } from '@/graphql';
import { useSelectItem, useQueryHelper, useToggleState } from '@/shared/hooks';

interface Props {
  refetchQueriesAfterDelete?: string[];
}

export const useDeleteAccountUser = ({ refetchQueriesAfterDelete = [] }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { status: deleting, toggleStatus: toggleDeleting } = useToggleState();
  const {
    selectedItem: deletingItem,
    setSelectedItem: setDeletingItem,
    resetSelectedItem: resetDeletingItem
  } = useSelectItem();
  const { callDeleteUser } = useDeleteUserMutation({ refetchQueries: refetchQueriesAfterDelete });

  const handleDeleteUser = async () => {
    if (!deletingItem) {
      return;
    }

    try {
      toggleDeleting();
      await callDeleteUser({ variables: { id: deletingItem.id } });

      const successMessage = t('succeededInDeleting', { name: deletingItem.name });
      enqueueSnackbar(successMessage, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: deletingItem.name }), { variant: 'error' });
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      toggleDeleting();
      resetDeletingItem();
    }
  };

  return {
    deleting,
    deletingItem,
    setDeletingItem,
    handleDeleteUser,
    resetDeletingItem
  };
};
