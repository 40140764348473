import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import {
  SocialAccountType,
  SocialAccountStatus,
  useSponsoredPostsQuery,
  InfluencerNewProfileHeaderV2,
  useInfluencerYoutubeAnalyticsSponsoredPostsQuery
} from '@/graphql';
import {
  eyeUserOrangeImage,
  bulbLightYellowImage,
  chartLightYellowImage,
  bookmarkLightBlueImage
} from '@/shared/assets';
import { MediaPreviewWithMetrics, TextCutter } from '@/shared/atoms';
import { LIMIT, THEME } from '@/shared/constants';
import { usePostDetailsParam } from '@/shared/hooks';
import {
  ImageSlider,
  SectionWithHeader,
  InfluencerPostDetailsModal,
  InfluencerYoutubeAnalyticsPostDetailsModal
} from '@/shared/molecules';
import { bigIntFormatter, formatIntNumber, formatPercent } from '@/shared/utils';
import { PostDetailsType } from '@/shared/types';
import { InfluencerSelectedAccountInfo } from '../../types';

const SPONSORED_POST_PARAMS_KEY = 'sponsoredPopup';

export interface InfluencerProfileSponsoredPostsProps {
  className?: string;
  hasYouTubeSponsoredPostsQuery?: boolean;
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerProfileSponsoredPosts = ({
  className,
  hasYouTubeSponsoredPostsQuery,
  socialAccount: { id: socialAccountId, type },
  influencerProfile: { id: influencerId, status, name, hideSensitiveMetrics }
}: InfluencerProfileSponsoredPostsProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam(SPONSORED_POST_PARAMS_KEY);
  const { data } = useSponsoredPostsQuery({
    skip: hasYouTubeSponsoredPostsQuery,
    variables: {
      offset: 0,
      influencerId,
      limit: LIMIT,
      socialAccountId,
      orderBy: { order: null, field: null },
      socialMedia: type as SocialAccountType
    }
  });
  const { data: ytData } = useInfluencerYoutubeAnalyticsSponsoredPostsQuery({
    skip: !hasYouTubeSponsoredPostsQuery,
    variables: { offset: 0, limit: LIMIT, socialAccountId }
  });

  const sponsoredData = data?.sponsoredPosts;
  const ytSponsoredData = ytData?.influencerYoutubeAnalyticsSponsoredPosts;
  const { sponsoredPosts, averageEngagement, averageEngagementRate } = (() => ({
    averageEngagement: hasYouTubeSponsoredPostsQuery
      ? ytSponsoredData?.averageEngagement
      : sponsoredData?.averageEngagement,
    averageEngagementRate: hasYouTubeSponsoredPostsQuery
      ? ytSponsoredData?.averageEngagementRate
      : sponsoredData?.averageEngagementRate,
    sponsoredPosts: hasYouTubeSponsoredPostsQuery
      ? ytSponsoredData?.posts?.map((post) => ({ ...post, campaignType: undefined }))
      : sponsoredData?.posts?.map((post) => ({ ...post, thumbnail: post.thumbNail }))
  }))();
  const averageOptions = [
    {
      icon: chartLightYellowImage,
      label: t('HeaderColumn.Avg Engagement Rate'),
      value: formatPercent(averageEngagementRate || 0)
    },
    {
      icon: bulbLightYellowImage,
      label: t('HeaderColumn.Avg Engagement'),
      value: formatIntNumber(averageEngagement || 0)
    },
    ...(!hideSensitiveMetrics && type === SocialAccountType.INSTAGRAM
      ? [
          {
            icon: eyeUserOrangeImage,
            label: t('HeaderColumn.Avg Reach'),
            value: formatIntNumber(sponsoredData?.averageReach || 0)
          },
          {
            icon: bookmarkLightBlueImage,
            label: t('HeaderColumn.Avg Save'),
            value: status === SocialAccountStatus.SIGNED_UP ? formatIntNumber(sponsoredData?.averageSaved || 0) : '-'
          }
        ]
      : [])
  ];

  return sponsoredPosts?.length ? (
    <>
      <SectionWithHeader
        className={className}
        css={{ marginTop: '16px' }}
        title={t('Sponsored Posts')}
        prefixIcon={{ icon: 'post', size: 24, color: THEME.text.colors.gray.lv3 }}
        moreDetailProps={
          hasYouTubeSponsoredPostsQuery
            ? {
                to: '/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts',
                params: { socialMediaAccountId: socialAccountId.toString() },
                search: { filter: { name } }
              }
            : {
                to: '/influencer/$id/$socialMediaAccountId/sponsored_feed_posts',
                params: { socialMediaAccountId: socialAccountId.toString() },
                search: { filter: { name, sm: type as SocialAccountType, status } }
              }
        }
      >
        <div css={styles.averageWrapper}>
          {averageOptions.map(({ label, value, icon }, index) => (
            <div key={index} css={styles.averageItem}>
              <TextCutter text={label} css={styles.averageLabel} />
              <p css={styles.averageValue}>{value}</p>
              <img src={icon} css={styles.averageIcon} alt={label} />
            </div>
          ))}
        </div>

        <ImageSlider css={styles.imageSlider} total={sponsoredPosts.length}>
          {sponsoredPosts.map(({ id, views, likes, content, comments, thumbnail }) => (
            <MediaPreviewWithMetrics
              key={id}
              src={thumbnail}
              objectFit="contain"
              title={content || ''}
              onClick={() => setPostDetailsParam(id)}
              css={THEME.mediaPreview.hasCaptionOrTitle}
              statistics={[
                { label: t('View'), value: bigIntFormatter(views, 0, { k: true, m: true }) },
                { label: t('Like'), value: bigIntFormatter(likes, 0, { k: true, m: true }) },
                { label: t('General.Comments'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
              ]}
              showIcon
            />
          ))}
        </ImageSlider>
      </SectionWithHeader>

      {hasYouTubeSponsoredPostsQuery ? (
        <InfluencerYoutubeAnalyticsPostDetailsModal
          paramKey={SPONSORED_POST_PARAMS_KEY}
          posts={
            sponsoredPosts?.map(
              ({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId: socialAccountId })
            ) || []
          }
        />
      ) : (
        <InfluencerPostDetailsModal
          paramKey={SPONSORED_POST_PARAMS_KEY}
          posts={
            sponsoredPosts?.map(
              (post): PostDetailsType => ({ id: post.id, campaignType: post.campaignType, influencerId })
            ) || []
          }
        />
      )}
    </>
  ) : null;
};
const styles = {
  averageWrapper: css({ display: 'flex' }),
  averageItem: css({
    height: '75px',
    flexBasis: '25%',
    overflow: 'hidden',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '16px 32px 16px 16px',
    '&:not(:nth-of-type(4))': { borderRight: THEME.border.base }
  }),
  averageLabel: css({
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.28px'
  }),
  averageValue: css({
    fontWeight: 600,
    marginTop: '5px',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.3px'
  }),
  averageIcon: css({
    top: 0,
    right: 0,
    width: '32px',
    height: '32px',
    position: 'absolute'
  }),
  imageSlider: css({
    boxSizing: 'border-box',
    padding: '16px 16px 0 16px',
    borderTop: THEME.border.base
  })
};
