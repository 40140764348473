import { FormatOptionLabelMeta, StylesConfig } from 'react-select';
import { Radio } from '@/shared/atoms';
import { Option } from '@/shared/types';
import { CheckBox } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { OptionLabel } from './shared/OptionLabel';
import { CommonSelectProps } from './types';

export const SELECT_OPTION_HEIGHT = 32;

export const getDefaultSelectStyles = ({
  hasError,
  disabled,
  menuWidth,
  styles = {}
}: Pick<CommonSelectProps<any, any, any>, 'hasError' | 'disabled' | 'styles' | 'menuWidth'>): StylesConfig<any> => {
  const menuWidthAutoStyles =
    menuWidth === 'auto' ? { minWidth: '100%', width: 'max-content', maxWidth: 'max-content' } : {};

  return {
    ...styles,
    menu: (baseCss, optProps) => ({
      ...baseCss,
      zIndex: 10,
      marginTop: '4px',
      width: typeof menuWidth === 'number' ? `${menuWidth}px` : '100%',
      ...menuWidthAutoStyles,
      ...styles?.menu?.({}, optProps) // Don't pass 'baseCss' into menu, we already done above, same with below
    }),
    option: (baseCss, optProps) => ({
      ...baseCss,
      height: SELECT_OPTION_HEIGHT,
      color: optProps.isSelected ? THEME.input.text.colors.default : baseCss.color,
      backgroundColor: optProps.isSelected
        ? optProps.isMulti
          ? 'transparent'
          : 'rgba(23, 156, 215, 0.15)'
        : 'transparent',
      ...(!disabled &&
        !optProps.isDisabled && {
          cursor: 'pointer',
          ':hover': { backgroundColor: 'rgba(23, 156, 215, 0.15)' }
        }),
      ...menuWidthAutoStyles,
      ...styles?.option?.({}, optProps)
    }),
    container: (baseCss, optProps) => ({
      ...baseCss,
      width: '100%',
      ...styles?.container?.({}, optProps)
    }),
    control: (baseCss, optProps) => ({
      ...baseCss,
      cursor: 'pointer',
      boxShadow: 'none',
      borderRadius: '3px',
      padding: '0 0 0 4px',
      height: SELECT_OPTION_HEIGHT,
      minHeight: SELECT_OPTION_HEIGHT,
      background: disabled ? THEME.input.background.colors.disabled : baseCss.background,
      borderColor: hasError
        ? THEME.input.border.colors.error
        : optProps.menuIsOpen
          ? THEME.input.border.colors.focus
          : THEME.input.border.colors.default,
      ':hover': {
        borderColor: hasError
          ? THEME.input.border.colors.error
          : optProps.menuIsOpen
            ? THEME.input.border.colors.focus
            : THEME.input.border.colors.hover
      },
      ...styles?.control?.({}, optProps)
    }),
    singleValue: (baseCss, optProps) => ({
      ...baseCss,
      color: THEME.input.text.colors.default,
      ...styles?.singleValue?.({}, optProps)
    }),
    valueContainer: (baseCss, optProps) => ({
      ...baseCss,
      padding: '0 4px',
      flexWrap: 'nowrap',
      ...styles?.valueContainer?.({}, optProps)
    }),
    placeholder: (baseCss, optProps) => ({
      ...baseCss,
      fontWeight: 400,
      fontSize: '14px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: THEME.input.text.colors.placeholder,
      ...styles?.placeholder?.({}, optProps)
    })
  };
};

export const formatOptionLabel = (
  optProps: Option<any, any>,
  meta: FormatOptionLabelMeta<Option<any, any>>,
  {
    multiple,
    disabled,
    menuWidth,
    optionLabelType
  }: Pick<CommonSelectProps<any, any, any>, 'menuWidth' | 'disabled' | 'optionLabelType' | 'multiple'>
) => {
  const isDisabled = optProps.disabled || disabled;

  if (multiple && meta.context === 'menu') {
    return (
      <CheckBox
        disabled={isDisabled}
        label={optProps.label}
        checked={meta.selectValue.findIndex((v) => v.value === optProps.value) !== -1}
      />
    );
  }

  // single select custom
  if (optionLabelType === 'radio' && meta.context === 'menu') {
    return (
      <Radio
        disabled={isDisabled}
        label={optProps.label}
        checked={meta.selectValue.findIndex((v) => v.value === optProps.value) !== -1}
      />
    );
  }

  // single select default
  return <OptionLabel {...optProps} meta={meta} menuWidth={menuWidth} disabled={isDisabled} />;
};

// Keep both disabled and isDisabled. React-select is using isDisabled
export const formatOptions = <O extends Option<string, any>>(options?: O[]) =>
  options?.map<O>((option) => ({ ...option, isDisabled: option.disabled }));
