import { useEffect, useState } from 'react';
import { InfluencerV4, SocialAccountDescription, useAllInfluencersV4PromiseQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { debounce } from '@/shared/molecules';
import { Option } from '@/shared/types';

export interface OptionWithSocialAccounts extends Option {
  socialAccounts: readonly SocialAccountDescription[];
}
interface InfluencersOptionsProps {
  inputValue?: string;
  influencerIds?: string[];
}

export const useAllInfluencersLoadOptions = ({ includeIds }: { includeIds: string[] }) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [allInfluencersOptions, setAllInfluencersOptions] = useState<readonly OptionWithSocialAccounts[]>([]);
  const { getAllInfluencersV4 } = useAllInfluencersV4PromiseQuery();
  const [fetchState, setFetchState] = useState<'idle' | 'fetched' | 'loading'>('idle');

  const fetchInfluencersOptions = async ({ inputValue, influencerIds }: InfluencersOptionsProps) => {
    let influencersList = [] as readonly InfluencerV4[];
    try {
      setFetchState('loading');
      const { data, errors } = await getAllInfluencersV4({
        variables: {
          offset: 0,
          limit: 200, // currently set based on approx outcome, we cannot use unlimited search due to high load on the BE side
          orderBy: {
            field: null,
            order: null
          },
          keyword: inputValue,
          includeIds: influencerIds?.map(Number)
        }
      });
      influencersList = data.allInfluencersV4.influencers || [];

      if (errors?.length) {
        enqueueSnackbar(t(errors.at(0)?.message || ''), { variant: 'error' });
      }

      const formattedResponse =
        influencersList.map((influencers) => ({
          value: influencers.id.toString(),
          label: influencers.name,
          socialAccounts: influencers.socialAccounts
        })) || [];

      setAllInfluencersOptions(formattedResponse);
      setFetchState('fetched');
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      setFetchState('idle');
    }
  };

  useEffect(() => {
    if (includeIds.length) {
      fetchInfluencersOptions({ influencerIds: includeIds });
    }
  }, []);

  const debouncedLoadAllInfluencersOptions: ({ inputValue, influencerIds }: InfluencersOptionsProps) => void = debounce<
    (_: InfluencersOptionsProps) => void
  >(({ inputValue, influencerIds }) => {
    fetchInfluencersOptions({ inputValue, influencerIds });
  }, 300);

  return {
    isLoading: fetchState === 'loading',
    fetchState,
    setFetchState,
    fetchInfluencersOptions,
    debouncedLoadAllInfluencersOptions,
    allInfluencersIncludedOptions:
      fetchState === 'idle'
        ? allInfluencersOptions.filter((o) => o.value && includeIds.includes(o.value))
        : allInfluencersOptions
  };
};
