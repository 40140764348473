import { useTranslation } from 'react-i18next';
import { TableCell, ColumnProps, RowProps, Anchor, MediaPreview, ButtonLink } from '@/shared/atoms';
import { useTranslateCountry } from '@/shared/hooks';
import { formatPercent, formatIntNumber } from '@/shared/utils';
import { AutoManagedCampaign } from '../types';

interface Props {
  listRecords: AutoManagedCampaign[];
}

export const useMarketplaceAutoManagedCampaignTable = ({ listRecords }: Props) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Product Name'), sticky: { active: true } },
    { title: t('HeaderColumn.Marketplace') },
    { title: t('HeaderColumn.Country') },
    { title: t('HeaderColumn.Seller Name') },
    { title: t('HeaderColumn.Category') },
    { title: t('HeaderColumn.Commission Rate'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Price'), styles: { textAlign: 'right' } }
  ];
  const rows: RowProps[] = listRecords?.map(
    ({
      id,
      title,
      price,
      country,
      category,
      sellerName,
      thumbnailUrl,
      commissionRate,
      landingPageUrl,
      autoManagedCampaignType
    }) => [
      <div css={{ width: '300px', display: 'flex', alignItems: 'flex-end' }}>
        <Anchor target="_blank" href={landingPageUrl || ''}>
          <MediaPreview src={thumbnailUrl} css={{ width: '80px', height: '80px' }} />
        </Anchor>
        <div css={{ marginLeft: '8px', width: '100%' }}>
          <Anchor
            label={title}
            target="_blank"
            variant="blue"
            css={{ fontSize: '16px', fontWeight: 600 }}
            href={landingPageUrl || ''}
          />
          <ButtonLink
            variant="white"
            title={t('Select Offer')}
            css={{ marginTop: '15px' }}
            to="/marketplace/add/e-commerce/$autoMangedCampaignId"
            params={{ autoMangedCampaignId: id.toString() }}
          />
        </div>
      </div>,
      <TableCell.Text value={autoManagedCampaignType} css={{ minWidth: 'unset' }} />,
      <TableCell.Text value={tc(country.name)} css={{ minWidth: 'unset' }} />,
      <TableCell.Text value={sellerName} css={{ minWidth: 'unset' }} />,
      <TableCell.Text value={category.name} css={{ minWidth: 'unset' }} />,
      <TableCell.Number value={formatPercent(commissionRate, false)} unit="%" />,
      <TableCell.Number value={formatIntNumber(price.amount)} unit={t(price.currencyId)} css={{ minWidth: '100px' }} />
    ]
  );

  return { rows, columns };
};
