import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { ThreadsInfluencer, SocialAccountType, SocialAccountInfluencerListSortField } from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { FilterValidations } from '@/shared/validations';
import { PostType, DefaultInfluencersProps, InfluencersFilterSchema, InfluencersBaseSearchSchema } from '../types';
import { getInitialThreadsInfluencersFormValues } from './utils';

export const ThreadsInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  minReach: true,
  maxReach: true,
  regionIds: true,
  interests: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  audienceAge: true,
  minSaveRate: true,
  maxSaveRate: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  minStoryPrice: true,
  maxStoryPrice: true,
  audienceRatio: true,
  audienceGender: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  followerGrowthPeriod: true,
  followerGrowthPercentage: true,
  talentAgencyOrPartnerIds: true
});
export const ThreadsInfluencersSearchSchema = z.object({
  filter: ThreadsInfluencersFilterSchema.default(
    ThreadsInfluencersFilterSchema.parse(getInitialThreadsInfluencersFormValues())
  ),
  page: z.number().default(1),
  sort: FilterValidations.sort(SocialAccountInfluencerListSortField).default({ field: null, order: null }),
  limit: z.number().default(LIMIT),
  state: InfluencersBaseSearchSchema.shape.state
});

export type ThreadsInfluencersSearchSchemaType = z.infer<typeof ThreadsInfluencersSearchSchema>;
export type ThreadsInfluencersFilterFormValues = z.infer<typeof ThreadsInfluencersFilterSchema>;
export type ThreadsInfluencersFilterFormKeys = UnionOfKeys<ThreadsInfluencersFilterFormValues>;
export type ThreadsInfluencersProps = DefaultInfluencersProps<ThreadsInfluencerProfile>;
export type ThreadsInfluencerProfile = { posts: PostType[]; socialAccountType: SocialAccountType } & Pick<
  ThreadsInfluencer,
  | 'id'
  | 'age'
  | 'name'
  | 'gender'
  | 'avatar'
  | 'country'
  | 'averageLikes'
  | 'isBrandAccount'
  | 'socialAccountId'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementFollowersRate'
>;
