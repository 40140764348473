import { useAllTiktokSimilarInfluencersLazyQuery } from '@/graphql';
import { TK_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY, DEFAULT_SIMILAR_INFLUENCERS_LIMITATION } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { formatInfluencerProfile } from '../utils';

interface Props {
  socialAccountId: number;
}

export const useTiktokSimilarInfluencersList = ({ socialAccountId }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const variables = {
    accountId: socialAccountId,
    limit: DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.LIST.FETCH
  };
  const { data, error, loading, refetch, getAllTiktokSimilarInfluencers } = useAllTiktokSimilarInfluencersLazyQuery({
    variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      if (err.message !== TK_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY) {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      }
    }
  });

  const similarInfluencers = data?.allTiktokSimilarInfluencers || [];

  return {
    error,
    loading,
    onRefetchData: refetch,
    limit: variables.limit,
    totalRecords: similarInfluencers.length || 0,
    onFetchData: getAllTiktokSimilarInfluencers,
    listRecords: similarInfluencers?.map(formatInfluencerProfile) || []
  };
};
