import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { SupportedSocialMediaType } from '@/shared/types';
import { SocialIcon, SocialIconProps } from '../Icon';
import { ButtonLink, ButtonLinkProps } from './ButtonLink';

export interface SocialButtonProps extends Omit<ButtonLinkProps, 'variant'> {
  hasIcon?: boolean;
  iconProps?: Omit<SocialIconProps, 'type'>;
  socialMedia: keyof typeof SupportedSocialMediaType;
}

export const SocialButton = ({
  title,
  hasIcon,
  loading,
  onClick,
  disabled,
  iconProps,
  socialMedia,
  ...restProps
}: SocialButtonProps) => {
  const css = (() => {
    switch (socialMedia) {
      case SupportedSocialMediaType.FACEBOOK:
      case SupportedSocialMediaType.FACEBOOK_PAGE:
        return { backgroundColor: '#4460a0' };
      case SupportedSocialMediaType.INSTAGRAM:
      case SupportedSocialMediaType.INSTAGRAM_REEL:
      case SupportedSocialMediaType.INSTAGRAM_STORY:
      case SupportedSocialMediaType.INSTAGRAM_BUSINESS:
        return { backgroundImage: 'linear-gradient(to right, #ae3bb0, #ff6641)' };
      case SupportedSocialMediaType.YOUTUBE:
        return { backgroundColor: '#ce1312' };
      case SupportedSocialMediaType.TWITTER:
      case SupportedSocialMediaType.TIKTOK:
      case SupportedSocialMediaType.THREADS:
        return { backgroundColor: '#000' };
      default:
        return { backgroundColor: '#dee5ec' };
    }
  })();

  return (
    <ButtonLink
      {...restProps}
      variant="default"
      loading={loading}
      disabled={disabled}
      css={[styles.button(hasIcon, !!(typeof loading === 'boolean' ? loading : loading?.status) || disabled), css]}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      title={
        <>
          {hasIcon ? <SocialIcon variant="white" type={socialMedia} {...iconProps} /> : null}
          {title}
        </>
      }
    />
  );
};

const styles = {
  button: (hasIcon?: boolean, disabled?: boolean) =>
    css({
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      width: 'max-content',
      color: THEME.text.colors.white,
      ...(hasIcon ? { gap: '8px', justifyContent: 'flex-start' } : {}),
      ...(disabled ? { opacity: 0.5, pointerEvents: 'none' } : {})
    })
};
