import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RemoveSelectedInfluencers.graphql';
import { Mutation, MutationremoveSelectedInfluencersArgs } from '../../__generated__/globalTypes';

export const useRemoveSelectedInfluencersMutation = (
  options?: MutationHookOptions<Mutation, MutationremoveSelectedInfluencersArgs>
) => {
  const [callRemoveSelectedInfluencers, result] = useMutation<Mutation, MutationremoveSelectedInfluencersArgs>(
    MUTATION,
    options
  );

  return { callRemoveSelectedInfluencers, ...result };
};

export type RemoveSelectedInfluencersMutationFunction = Mutation['removeSelectedInfluencers'];
