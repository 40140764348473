import { useTranslation } from 'react-i18next';
import { RichEditorField, RichEditorFieldProps } from '@/shared/molecules';
import { SunEditorRef, SunEditorRefType } from '@/shared/atoms';
import { MarketplaceFormKeys } from '../types';

export const PostRequirementField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  getSunEditorInstance,
  ...restProps
}: Partial<RichEditorFieldProps<T>> & {
  getSunEditorInstance?: (seRef: SunEditorRef, refType: SunEditorRefType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <RichEditorField<T>
      title={t('Post Requirements')}
      help={t('Post Requirements Help')}
      name={name || (MarketplaceFormKeys.requirement as T)}
      placeholder={t<string>('TextForm.Post Requirements')}
      getSunEditorInstance={(seRef) => getSunEditorInstance?.(seRef, 'postReq')}
      {...restProps}
    />
  );
};
