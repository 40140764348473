import * as Highcharts from 'highcharts';
import { AppLanguage } from '@/shared/types';
import { hourIntervals, localizedWeekdays } from './date';
import { bigIntFormatter } from './format';

export const generateYAxisBasedOnDisplayedCharts = <T>(yAxisCharts: T[], hasYMin: boolean | undefined = true) =>
  yAxisCharts.map(
    (_, i): Highcharts.YAxisOptions => ({
      min: hasYMin ? 0 : undefined,
      title: {
        text: ''
      },
      labels: {
        enabled: i === 0,
        formatter() {
          return bigIntFormatter(Number(this.value));
        }
      },
      allowDecimals: false
    })
  );

export const getBarDataBasedOnPointer = (
  chart: Highcharts.Chart,
  e: Highcharts.ChartClickEventObject,
  postsColors: string[]
) => {
  let selectedPoint: Highcharts.Point[] | undefined;

  const x = Math.round(e.xAxis.at(0)?.value ?? 0);
  chart.series.forEach((seria) => {
    if (seria.visible) {
      seria.points.forEach((point) => {
        if (point.x === x && postsColors.includes(`${point.color}`)) {
          selectedPoint = [...(selectedPoint || []), point];
        }
      });
    }
  });

  return selectedPoint;
};

export const generateTimeSlots = (
  postsHabit?:
    | readonly {
        hour: number;
        weekday: number;
        averageLikes: number;
        engagementRate: number;
        averageComments: number;
      }[]
    | null,
  locale?: AppLanguage
) => {
  // we need to be aware of Apollo's freezing query results for better caching since v.2.6 -> https://github.com/apollographql/apollo-client/issues/5987
  //  we have to copy array before sorting it due to it is immutable after beign retrieved from query results
  const topEngagementRateHabits = postsHabit
    ? [...postsHabit].sort((a, b) => (a.engagementRate > b.engagementRate ? -1 : 1)).slice(0, 3)
    : [];

  return topEngagementRateHabits.map(
    (habit) =>
      `${localizedWeekdays(locale).at(habit?.weekday)?.slice(0, 3)} ${hourIntervals.at(habit?.hour)?.toUpperCase()}`
  );
};
