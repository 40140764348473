import { css, keyframes } from '@emotion/react';

export interface CircularProgressProps {
  size?: number;
  speed?: string;
  reversed?: boolean;
  className?: string;
  thickness?: number;
  backgroundColor?: string;
}

export const CircularProgress = ({
  reversed,
  className,
  size = 12,
  speed = '1s',
  thickness = 1,
  backgroundColor = '#fff'
}: CircularProgressProps) => (
  <span
    css={css({
      display: 'block',
      width: `${size}px`,
      padding: `${thickness}mm`,
      backgroundColor,
      aspectRatio: '1',
      borderRadius: '50%',
      '--mask': reversed
        ? `conic-gradient(#fff, #ffffff00), linear-gradient(#fff 0 0) content-box`
        : `conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box`,
      WebkitMask: 'var(--mask)',
      mask: 'var(--mask)',
      WebkitMaskComposite: 'source-out',
      maskComposite: 'subtract', // fix for FireFox
      boxSizing: 'border-box',
      animation: `${keyframes`from {transform: rotate(${reversed ? 360 : 0}deg)} to {transform: rotate(${reversed ? 0 : 360}deg)}`} ${speed} linear infinite`
    })}
    className={className}
  />
);
