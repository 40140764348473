import { useTranslation } from 'react-i18next';
import { TextAreaField, TextAreaFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const ReasonField = (props: Omit<TextAreaFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return (
    <TextAreaField<InfluencerFormKeys>
      help={t('Tooltip.Default Selection Reason Help')}
      title={t('TextForm.Default Selection Reason')}
      placeholder={t<string>('TextForm.Contents Placeholder')}
      name={InfluencerSharedKeys.defaultEngagementSelectionReason}
      {...props}
    />
  );
};
