import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/LinkCmsChannelAssets.graphql';
import { Mutation, MutationlinkCmsChannelAssetsArgs } from '../../__generated__/globalTypes';

export const useLinkCmsChannelAssetsMutation = (
  options?: MutationHookOptions<Mutation, MutationlinkCmsChannelAssetsArgs>
) => {
  const [callLinkCmsChannelAssets, result] = useMutation<Mutation, MutationlinkCmsChannelAssetsArgs>(MUTATION, options);

  return { callLinkCmsChannelAssets, ...result };
};

export type LinkCmsChannelAssetsMutationFunction = Mutation['linkCmsChannelAssets'];
