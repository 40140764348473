import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserRoles } from '@/graphql';
import { Option } from '@/shared/types';
import { FormStyle } from '@/shared/atoms';
import {
  useAllStaffOptions,
  useTranslateOptions,
  useAllCountryOptions,
  useAllAvailableCountryOptions,
  useAllAdvertisersForSearchOptions
} from '@/shared/hooks';
import {
  TextField,
  RadiosField,
  SelectorField,
  PasswordField,
  CheckBoxField,
  SwitchToggleField
} from '@/shared/molecules';
import { DEFAULT_VALUES } from './utils';
import { UserLinkedFormValues, UserLinkedFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    watch,
    reset,
    setValue,
    formState: { isSubmitting }
  } = useFormContext<UserLinkedFormValues>();
  const values = watch();
  const { allCountryOptions } = useAllCountryOptions();
  const { allAdvertiserOptions } = useAllAdvertisersForSearchOptions();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const { allStaffOptions } = useAllStaffOptions({
    variables: { offset: 0, limit: 1000, countryId: values.countryId }
  });
  const userTypeOptions: Option<UserLinkedFormValues['userType'], string>[] = useTranslateOptions([
    { value: UserRoles.STAFF, label: 'Staff' },
    { value: UserRoles.ADVERTISER, label: 'Advertiser' }
  ]);

  return (
    <FormStyle.Wrapper css={{ flex: 1 }} hasBorder>
      <div css={{ padding: '24px' }}>
        <FormStyle.SectionTitle index={1} label={t('User Type')} />

        <RadiosField<UserLinkedFormKeys, UserLinkedFormValues['userType']>
          name="userType"
          options={userTypeOptions}
          onChange={(_, value) => reset({ ...DEFAULT_VALUES[value], advertiserId: values.advertiserId })}
        />

        <FormStyle.SectionTitle index={2} css={{ marginTop: '24px' }} label={t('User Information')} />

        <SelectorField<UserLinkedFormKeys>
          title={t('Selector.Advertiser')}
          name="advertiserId"
          help={t('Tooltip.User Advertiser')}
          options={allAdvertiserOptions}
          disabled
          required
        />

        {values.userType === UserRoles.STAFF ? (
          <SelectorField<UserLinkedFormKeys>
            title={t('Selector.Staff')}
            name="staffIds"
            options={allStaffOptions}
            placeholder={t<string>('Selector.Select staff users')}
            multipleSelectorProps={{
              subSelector: (
                <SelectorField<UserLinkedFormKeys>
                  name="countryId"
                  options={allCountryOptions}
                  placeholder={t<string>('Selector.Country')}
                  css={{ padding: 0, margin: '2px 12px 2px auto', zIndex: 12, maxWidth: '150px' }}
                  onChange={(name, value) => {
                    setValue(name, value);
                    setValue('staffIds', []);
                  }}
                />
              )
            }}
            multiple
          />
        ) : (
          <>
            <TextField<UserLinkedFormKeys>
              name="name"
              title={t('TextForm.Name')}
              help={t('Tooltip.Username')}
              placeholder={t<string>('NameExample')}
              required
            />

            <FormStyle.FieldsGroup itemsPerRow={2}>
              <TextField<UserLinkedFormKeys>
                name="email"
                title={t('TextForm.Email Address')}
                help={t('Tooltip.User Email Address')}
                placeholder="anytag@email.com"
                required
              />
              <SelectorField<UserLinkedFormKeys>
                title={t('Selector.Country')}
                name="countryId"
                help={t('Tooltip.User Country')}
                options={allAvailableCountryOptions}
                required
              />
            </FormStyle.FieldsGroup>

            {!values.hasInvitationEmail ? (
              <FormStyle.FieldsGroup itemsPerRow={2}>
                <PasswordField<UserLinkedFormKeys>
                  name="password"
                  help={t('Tooltip.User Password')}
                  title={t('Password.Password')}
                  dynamicError
                  required
                />

                <PasswordField<UserLinkedFormKeys>
                  help={t('Tooltip.User Password')}
                  name="passwordConfirm"
                  title={t('Password.Confirm Password')}
                  required
                  dynamicError
                />
              </FormStyle.FieldsGroup>
            ) : null}

            <CheckBoxField<UserLinkedFormKeys>
              name="hasInvitationEmail"
              label={t('Send invitation email')}
              onChange={(_: string, value: boolean) => {
                reset({ ...values, password: '', passwordConfirm: '', hasInvitationEmail: value });
              }}
            />

            <SwitchToggleField<UserLinkedFormKeys>
              name="notificationSetting"
              help={t('Tooltip.Notification settings help')}
              title={t('TextForm.Notification Settings')}
            />
          </>
        )}
      </div>

      <FormStyle.ButtonSubmitWrapper>
        <FormStyle.ButtonSubmit title={t('Button.Save')} loading={isSubmitting} />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};
