import { InfluencersTabType } from '@/shared/types';
import { XhsInfluencers, XhsInfluencerProfile } from '@/shared/organisms';
import { useSocialAccountSelectButtonProps } from './hooks';

export const XhsPackageInfluencersSearch = () => {
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialAccountSelectButtonProps<XhsInfluencerProfile>({ socialMedia: InfluencersTabType.XHS });

  return (
    <XhsInfluencers
      isPackagePage
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
