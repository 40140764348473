import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { PaymentPlanName, PaymentPlanType, PricingTableItem, usePricingTableQuery } from '@/graphql';

interface CustomPaymentPlanType {
  features: Array<{ label: ReactNode; value: ReactNode }>;
}

export const useGetPaymentPlanData = (plans?: readonly PricingTableItem[]) => {
  const { t } = useTranslation();
  const { data } = usePricingTableQuery({
    skip: !!plans?.length, // if plans fetched, use it
    fetchPolicy: 'cache-and-network',
    variables: { planType: PaymentPlanType.MARKETPLACE }
  });

  const pricingTablePlans = plans?.length ? plans : data?.pricingTable?.plans || [];

  // https://adasiaholdings.atlassian.net/wiki/spaces/NC/pages/3567321097/TikTok+special+Marketplace+campaign#%5BhardBreak%5DThings-required-for-the-UI-display
  const getPaymentPlan = ({
    name,
    formatJoinerInfluencers = false
  }: {
    name?: PaymentPlanName;
    formatJoinerInfluencers?: boolean;
  }): CustomPaymentPlanType => {
    switch (name) {
      // For PaymentPlanType.TIKTOK_ADS
      case PaymentPlanName.BEGINNER:
        return {
          features: [
            { label: t('Quickly issued TikTok ads account'), value: '' },
            { label: t('Technical support'), value: '' },
            { label: t('Ads Spending'), value: '' },
            { label: t('Ad account numbers', { count: 1 }), value: '' },
            { label: t('Target ads worldwide (+55 markets)'), value: '' }
          ]
        };
      case PaymentPlanName.STANDARD:
        return {
          features: [
            { label: t('Quickly issued TikTok ads account'), value: '' },
            { label: t('Technical support'), value: '' },
            { label: t('Ads Spending'), value: '' },
            { label: t('Ad account numbers', { count: 5 }), value: '' },
            { label: t('Target ads worldwide (+55 markets)'), value: '' },
            { label: t('AnyMind Exclusive Package'), value: '' },
            { label: t('Boost posts as ads'), value: '' }
          ]
        };
      case PaymentPlanName.ENTERPRISE:
        return {
          features: [
            { label: t('Quickly issued TikTok ads account'), value: '' },
            { label: t('Technical support'), value: '' },
            { label: t('Ads Spending'), value: '' },
            { label: t('Ad account numbers', { count: 20 }), value: '' },
            { label: t('Target ads worldwide (+55 markets)'), value: '' },
            { label: t('Recommendation system'), value: '' },
            { label: t('AnyMind Exclusive Package'), value: '' },
            { label: t('Boost posts as ads'), value: '' },
            { label: t('Technical support (Special)'), value: '' }
          ]
        };
      // For PaymentPlanType.MARKETPLACE
      case PaymentPlanName.BASIC_CAMPAIGN: {
        const pricingTablePlan = pricingTablePlans.find((p) => p.name === name);

        return {
          features: [
            { label: t('Ad Boosting Budget'), value: '0 THB' },
            {
              label: t('Joiner Influencers'),
              value: formatJoinerInfluencers ? (
                <JoinerInfluencers numberOfInfluencers={pricingTablePlan?.numberOfInfluencers} />
              ) : (
                pricingTablePlan?.numberOfInfluencers || 0
              )
            },
            { label: <Trans i18nKey="Est.Follower" components={{ br: <br /> }} />, value: '3,000+' },
            { label: <Trans i18nKey="Est.Organic view" components={{ br: <br /> }} />, value: '300~450' },
            { label: <Trans i18nKey="Est.Organic engagement" components={{ br: <br /> }} />, value: '150~300+' }
          ]
        };
      }
      case PaymentPlanName.ADVANCED_CAMPAIGN: {
        const pricingTablePlan = pricingTablePlans.find((p) => p.name === name);

        return {
          features: [
            { label: t('Ad Boosting Budget'), value: '3,000 THB' },
            {
              label: t('Joiner Influencers'),
              value: formatJoinerInfluencers ? (
                <JoinerInfluencers numberOfInfluencers={pricingTablePlan?.numberOfInfluencers} freeInfluencer={1} />
              ) : (
                pricingTablePlan?.numberOfInfluencers || 0
              )
            },
            { label: <Trans i18nKey="Est.Follower" components={{ br: <br /> }} />, value: '10,000+' },
            { label: <Trans i18nKey="Est.Organic view" components={{ br: <br /> }} />, value: '1,000~1,500+' },
            { label: <Trans i18nKey="Est.Organic engagement" components={{ br: <br /> }} />, value: '500~1,000+' }
          ]
        };
      }
      case PaymentPlanName.PRO_CAMPAIGN: {
        const pricingTablePlan = pricingTablePlans.find((p) => p.name === name);

        return {
          features: [
            { label: t('Ad Boosting Budget'), value: '5,000 THB' },
            {
              label: t('Joiner Influencers'),
              value: formatJoinerInfluencers ? (
                <JoinerInfluencers numberOfInfluencers={pricingTablePlan?.numberOfInfluencers} freeInfluencer={2} />
              ) : (
                pricingTablePlan?.numberOfInfluencers || 0
              )
            },
            { label: <Trans i18nKey="Est.Follower" components={{ br: <br /> }} />, value: '17,000+' },
            { label: <Trans i18nKey="Est.Organic view" components={{ br: <br /> }} />, value: '1,700~2,550+' },
            { label: <Trans i18nKey="Est.Organic engagement" components={{ br: <br /> }} />, value: '850~1,700+' }
          ]
        };
      }
      default:
        return { features: [] };
    }
  };

  return { getPaymentPlan };
};

interface JoinerInfluencersProps {
  freeInfluencer?: number;
  numberOfInfluencers?: number | null;
}

const JoinerInfluencers = ({ numberOfInfluencers = 0, freeInfluencer = 0 }: JoinerInfluencersProps) => {
  const { t } = useTranslation();

  return !!freeInfluencer && !!numberOfInfluencers && numberOfInfluencers > freeInfluencer ? (
    <>
      {numberOfInfluencers - freeInfluencer}
      <span
        css={{
          height: '17px',
          fontWeight: 500,
          fontSize: '12px',
          padding: '0 4px',
          marginLeft: '2px',
          lineHeight: '100%',
          borderRadius: '13px',
          alignItems: 'center',
          display: 'inline-flex',
          boxSizing: 'border-box',
          justifyContent: 'center',
          color: THEME.text.colors.red.lv2,
          backgroundColor: 'rgba(255, 207, 207, 0.50)'
        }}
      >
        {`+${freeInfluencer} ${t('free')}!`}
      </span>
    </>
  ) : (
    numberOfInfluencers
  );
};
