import { Navigate } from '@tanstack/react-router';
import { SocialAccountType } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '@/shared/organisms';
import { InfluencerDouyinProfile } from './InfluencerDouyinProfile';
import { InfluencerFacebookProfile } from './InfluencerFacebookProfile';
import { InfluencerInstagramProfile } from './InfluencerInstagramProfile';
import { InfluencerThreadsProfile } from './InfluencerThreadsProfile';
import { InfluencerTiktokProfile } from './InfluencerTiktokProfile';
import { InfluencerTwitterProfile } from './InfluencerTwitterProfile';
import { InfluencerYoutubeProfile } from './InfluencerYoutubeProfile';
import { InfluencerXhsProfile } from './InfluencerXhsProfile';

interface InfluencerDefaultProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerDefaultProfile = ({ socialAccount }: InfluencerDefaultProfileProps) => {
  const defaultProps = { socialAccount };

  switch (socialAccount.type) {
    case SocialAccountType.INSTAGRAM:
      return <InfluencerInstagramProfile {...defaultProps} />;
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return <InfluencerFacebookProfile {...defaultProps} />;
    case SocialAccountType.YOUTUBE:
      return <InfluencerYoutubeProfile {...defaultProps} />;
    case SocialAccountType.TWITTER:
      return <InfluencerTwitterProfile {...defaultProps} />;
    case SocialAccountType.TIKTOK:
      return <InfluencerTiktokProfile {...defaultProps} />;
    case SocialAccountType.THREADS:
      return <InfluencerThreadsProfile {...defaultProps} />;
    case SocialAccountType.DOUYIN:
      return <InfluencerDouyinProfile {...defaultProps} />;
    case SocialAccountType.XHS:
      return <InfluencerXhsProfile {...defaultProps} />;
    default:
      return <Navigate to="/influencers" />;
  }
};
