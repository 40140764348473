import { EngagementCampaignStatus, useUpdateEngagementStatusMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';

interface Props {
  id: number;
  title: string;
  status: EngagementCampaignStatus;
}

export const useUpdateEngagementStatus = ({ id, title, status }: Props) => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { callUpdateEngagementStatus, loading } = useUpdateEngagementStatusMutation({
    onCompleted: invalidateRouteLoader
  });

  const nextStatus =
    status === EngagementCampaignStatus.DRAFT
      ? EngagementCampaignStatus.REVIEWING
      : status === EngagementCampaignStatus.REVIEWING
        ? EngagementCampaignStatus.UPCOMING
        : null;

  if (nextStatus === null) {
    return { updateEngagementStatus: undefined };
  }

  const updateEngagementStatus = async (isDemoPublished?: boolean) => {
    try {
      await callUpdateEngagementStatus({
        variables: { input: { id, status: isDemoPublished ? EngagementCampaignStatus.DEMO : nextStatus } }
      });

      enqueueSnackbar(t('succeededInUpdating', { name: title }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: title }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return { loading, updateEngagementStatus };
};
