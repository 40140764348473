import { useTranslation } from 'react-i18next';
import { CartesianGrid, Legend, AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { AffiliateCampaignChartReport } from '@/graphql';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter, numberFormatter, tickFormatter } from '@/shared/utils';

export interface AffiliateReportChartProps {
  className?: string;
  data?: readonly AffiliateCampaignChartReport[];
}
// This chart used for Marketplace/AutoManaged report page
export const AffiliateReportChart = ({ data, className }: AffiliateReportChartProps) => {
  const { t, i18n } = useTranslation();

  return (
    <ResponsiveContainer
      css={{ height: '325px !important', backgroundColor: THEME.background.colors.white }}
      className={className}
    >
      <AreaChart margin={{ top: 0, right: 0, bottom: 20, left: 0 }} data={data as AffiliateCampaignChartReport[]}>
        <CartesianGrid stroke={THEME.chart.colors.report.stroke} strokeDasharray="3 3" />
        <YAxis
          yAxisId="cost"
          tick={{ fill: THEME.chart.colors.report.fill, fontSize: 12 }}
          stroke={THEME.chart.colors.report.stroke}
          tickFormatter={tickFormatter}
        />
        <YAxis
          stroke={THEME.chart.colors.report.stroke}
          orientation="right"
          yAxisId="grossProfit"
          tick={{ fill: THEME.chart.colors.report.fill, fontSize: 12 }}
          tickFormatter={tickFormatter}
        />
        <XAxis
          name="Date"
          dataKey="date"
          stroke={THEME.chart.colors.report.stroke}
          interval="preserveStartEnd"
          tick={{ fill: THEME.chart.colors.report.fill, fontSize: 12 }}
          tickFormatter={(e) => localizedDateFormatter(e, 'MMM do', i18n.language as AppLanguage)}
        />
        <Legend
          align="left"
          iconSize={20}
          iconType="line"
          verticalAlign="top"
          wrapperStyle={{ paddingBottom: '5px', paddingLeft: '16px', fontSize: 12, lineHeight: '36px' }}
        />
        <Tooltip
          formatter={numberFormatter}
          itemStyle={{ fontSize: 13, fontWeight: 400 }}
          labelStyle={{ paddingBottom: '4px', color: THEME.chart.colors.report.fill, fontSize: 13, fontWeight: 600 }}
        />
        <Area
          type="linear"
          dataKey="cost"
          yAxisId="cost"
          fill={THEME.chart.colors.report.fillRed}
          stroke={THEME.chart.colors.report.red}
          strokeWidth={1.2}
          animationDuration={800}
          name={t<string>('Cost')}
          dot
        />
        <Area
          type="linear"
          fill={THEME.chart.colors.report.fillBlue}
          stroke={THEME.chart.colors.report.blue}
          strokeWidth={1.2}
          dataKey="grossProfit"
          yAxisId="grossProfit"
          animationDuration={800}
          name={t<string>('Gross Profit')}
          dot
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
