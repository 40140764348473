import { Navigate } from '@tanstack/react-router';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';
import { useAnalyticsProfileContext } from '../AnalyticsProfileContext';
import { AnalyticsTrendingYoutubePosts } from './AnalyticsTrendingYoutubePosts';

export const AnalyticsTrend = () => {
  const {
    selectedSocialAccount,
    brandAccount: { subscriptionPlan }
  } = useAnalyticsProfileContext();

  if (!subscriptionPlan.availableFeatures?.trendEnabled) {
    return <Navigate to="/analytics" />;
  }

  // Analytics just support youtube trending posts for now. We might have others later
  switch (selectedSocialAccount.socialMedia) {
    case AnalyticsSupportedSocialMediaType.youtube:
      return <AnalyticsTrendingYoutubePosts />;
    default:
      return <Navigate to="/analytics" />;
  }
};
