import { YoutubeTrafficSource } from '@/graphql';

export const mapYoutubeTrafficSourceToUserContent = (key: YoutubeTrafficSource) => {
  switch (key) {
    case YoutubeTrafficSource.ADVERTISING:
      return {
        name: 'Source.Advertising',
        help: 'Tooltip.The viewer was referred to the video by an advertisement'
      };
    case YoutubeTrafficSource.ANNOTATION:
      return {
        name: 'Source.Annotation',
        help: 'Tooltip.Viewers reached the video by clicking on an annotation in another video'
      };
    case YoutubeTrafficSource.CAMPAIGN_CARD:
      return {
        name: 'Source.Campaign card',
        help: 'Tooltip.Views originated from claimed, user-uploaded videos that the content owner used to promote the viewed content'
      };
    case YoutubeTrafficSource.END_SCREEN:
      return {
        name: 'Source.End screen',
        help: 'Tooltip.The views were referred from the end screen of another video'
      };
    case YoutubeTrafficSource.EXT_URL:
      return {
        name: 'Source.Ext URL',
        help: 'Tooltip.The video views were referred from a link on another website'
      };
    case YoutubeTrafficSource.HASHTAGS:
      return {
        name: 'Source.Hashtags',
        help: 'Tooltip.Views originated from VOD hashtag pages or Shorts hashtag pivot pages'
      };
    case YoutubeTrafficSource.LIVE_REDIRECT:
      return {
        name: 'Source.Live redirect',
        help: 'Tooltip.The video views were referred from Live Redirects'
      };
    case YoutubeTrafficSource.NO_LINK_EMBEDDED:
      return {
        name: 'Source.No link embedded',
        help: 'Tooltip.The video was embedded on another website when it was viewed'
      };
    case YoutubeTrafficSource.NO_LINK_OTHER:
      return {
        name: 'Source.No link other',
        help: 'Tooltip.YouTube did not identify a referrer for the traffic This category encompasses direct traffic to a video as well as traffic on mobile apps'
      };
    case YoutubeTrafficSource.NOTIFICATION:
      return {
        name: 'Source.Notification',
        help: 'Tooltip.The video views were referred from an email or notification from YouTube'
      };
    case YoutubeTrafficSource.PLAYLIST:
      return {
        name: 'Source.Playlist',
        help: 'Tooltip.The video views occurred while the video was being played as part of a playlist'
      };
    case YoutubeTrafficSource.PRODUCT_PAGE:
      return {
        name: 'Source.Product page',
        help: 'Tooltip.The video views were referred from a Product page'
      };
    case YoutubeTrafficSource.PROMOTED:
      return {
        name: 'Source.Promoted',
        help: 'Tooltip.The video views were referred from an unpaid YouTube promotion, such as the YouTube "Spotlight Videos" page'
      };
    case YoutubeTrafficSource.RELATED_VIDEO:
      return {
        name: 'Source.Related video',
        help: 'Tooltip.The video views were referred from a related video listing on another video watch page'
      };
    case YoutubeTrafficSource.SHORTS:
      return {
        name: 'Source.Youtube shorts',
        help: 'Tooltip.The viewer was referred by swiping vertically from the previous video in the Shorts viewing experience'
      };
    case YoutubeTrafficSource.SOUND_PAGE:
      return {
        name: 'Source.Sound page',
        help: 'Tooltip.Views originated from Shorts sound pivot pages'
      };
    case YoutubeTrafficSource.SUBSCRIBER:
      return {
        name: 'Source.Subscriber',
        help: 'Tooltip.The video views were referred from feeds on the YouTube homepage or from YouTube subscription features'
      };
    case YoutubeTrafficSource.YT_CHANNEL:
      return {
        name: 'Source.Youtube channel',
        help: 'Tooltip.The video views occurred on a channel page'
      };
    case YoutubeTrafficSource.YT_OTHER_PAGE:
      return {
        name: 'Source.Youtube other pages',
        help: 'Tooltip.The video views were referred from a link other than a search result or related video link that appeared on a YouTube page'
      };
    case YoutubeTrafficSource.YT_PLAYLIST_PAGE:
      return {
        name: 'Source.Youtube playlist page',
        help: 'Tooltip.The video views originated from a page that lists all of the videos in a playlist'
      };
    case YoutubeTrafficSource.YT_SEARCH:
      return {
        name: 'Source.Youtube search',
        help: 'Tooltip.The video views were referred from YouTube search results'
      };
    case YoutubeTrafficSource.VIDEO_REMIXES:
      return {
        name: 'Source.Video remixes',
        help: 'Tooltip.The video views were referred from the remixed video link in the Shorts player'
      };

    default:
      return {
        name: '',
        help: 'Tooltip.'
      };
  }
};
