import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalProps,
  ModalTitle,
  ModalContent,
  ModalFooterActions,
  ModalFooterActionsProps
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface WarningModalProps extends Omit<ModalProps, 'children'> {
  description: ReactNode;
  footerProps?: ModalFooterActionsProps;
}

export const WarningModal = ({ description, onClose, footerProps, ...restProps }: WarningModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} css={{ width: 'max-content', maxWidth: THEME.modal.size.lv2 }} {...restProps}>
      <ModalContent>
        <ModalTitle>{description}</ModalTitle>
      </ModalContent>

      <ModalFooterActions
        {...footerProps}
        cancelButtonProps={{ onClick: onClose, title: t('Button.OK'), ...footerProps?.cancelButtonProps }}
      />
    </Modal>
  );
};
