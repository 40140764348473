import { useTalentInfluencerDetailsForEditQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { Template } from './Template';

interface EditInfluencerForTalentAgencyProps {
  influencerId: number;
}

export const EditInfluencerForTalentAgency = ({ influencerId }: EditInfluencerForTalentAgencyProps) => {
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { data, loading, networkStatus } = useTalentInfluencerDetailsForEditQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { input: { influencerId } },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/influencer/add' });
    }
  });

  const talentInfluencer = data?.talentInfluencerDetailsForEdit || null;

  return (
    <RenderDataWithFallback loading={loading && networkStatus === 1}>
      {talentInfluencer ? <Template influencer={talentInfluencer} influencerId={influencerId} /> : null}
    </RenderDataWithFallback>
  );
};
