import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { SimilarPost, SocialPostType } from '@/graphql';
import { Anchor, MediaPreview } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { SupportedSocialMediaType } from '@/shared/types';

interface SimilarPostsProps {
  className?: string;
  posts?: readonly SimilarPost[];
  socialMedia?: keyof typeof SupportedSocialMediaType;
}

export const SimilarPosts = ({ posts = [], className, socialMedia }: SimilarPostsProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  return posts?.length ? (
    <div css={styles.wrapper} className={className}>
      <p css={styles.title}>{t('Similar Image Search')}</p>
      <div css={styles.posts}>
        {posts.slice(0, 4).map(({ image, influencerId: id, socialAccountId, username, postType }, index) => {
          const isReel = postType === SocialPostType.INSTAGRAM_REEL;

          return (
            <div key={index} css={{ boxSizing: 'border-box', padding: '4px' }}>
              <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <MediaPreview
                  src={image}
                  isReel={isReel}
                  css={{ width: '150px', height: '150px', borderRadius: '3px' }}
                  showIcon
                />
                <Anchor
                  variant="blue"
                  title={username}
                  label={username}
                  css={{ marginTop: '8px' }}
                  {...generateProfileLink(
                    id,
                    socialAccountId ? { filter: { id: String(socialAccountId), sm: socialMedia || '' } } : undefined
                  )}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};
const styles = {
  title: css({
    margin: 0,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: THEME.text.colors.black.lv1
  }),
  wrapper: css({
    marginTop: '16px',
    boxSizing: 'border-box',
    padding: '16px',
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  posts: css({
    display: 'grid',
    gridAutoColumns: '1fr',
    margin: '12px -4px -4px -4px',
    gridTemplateColumns: 'repeat(4, 1fr)'
  })
};
