import { useTranslation } from 'react-i18next';
import { MarketplaceDetail } from '@/graphql';
import { Drawer } from '@/shared/atoms';
import { DefaultTemplate } from './DefaultTemplate';
import { DraftTemplate } from './DraftTemplate';

export interface MarketplacePostFormProps {
  postId: number;
  onClose: () => void;
  isDraftPost?: boolean;
  isAffiliateCampaign?: boolean;
  marketplaceId: MarketplaceDetail['id'];
}

export const MarketplacePostForm = ({
  postId,
  onClose,
  isDraftPost,
  marketplaceId,
  isAffiliateCampaign
}: MarketplacePostFormProps) => {
  const { t } = useTranslation();

  return (
    <Drawer
      size="470px"
      onClose={onClose}
      title={t(isDraftPost ? 'Review draft post' : 'Post Detail')}
      closeOnClickOutside
      opened
    >
      {isDraftPost ? (
        <DraftTemplate postId={postId} onClose={onClose} />
      ) : (
        <DefaultTemplate
          postId={postId}
          onClose={onClose}
          marketplaceId={marketplaceId}
          isAffiliateCampaign={isAffiliateCampaign}
        />
      )}
    </Drawer>
  );
};
