import { css } from '@emotion/react';
import { Fragment, useState } from 'react';
import { useEngagementPermissions } from '@/auth';
import {
  EngagementDetail,
  ProposalInfluencer,
  EngagementCampaignStatus,
  useDenyEngagementInfluencerProposalListMutation,
  useAllowEngagementInfluencerProposalListMutation
} from '@/graphql';
import { Button, Label, RichEditor } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';
import { ConfirmDenyingInfluencerModal } from '../../shared';
import { useEngagementProposalInfluencerDetailsParam } from '../hooks';

interface InfluencerOverviewProps {
  data: ProposalInfluencer;
  engagement: EngagementDetail;
}

export const InfluencerOverview = ({
  data: {
    id,
    name,
    reasons,
    isDenied,
    requirements,
    summary: { budget }
  },
  engagement: { id: campaignId, currency, status }
}: InfluencerOverviewProps) => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { hideAllowDenyForModal } = useEngagementPermissions();
  const { setInfluencerDetailsParam } = useEngagementProposalInfluencerDetailsParam();
  const [denyingInfluencerId, setDenyingInfluencerId] = useState<number | null>(null);
  const { callAllowEngagementInfluencerProposalList, loading: allowing } =
    useAllowEngagementInfluencerProposalListMutation({ onCompleted: invalidateRouteLoader });
  const { callDenyEngagementInfluencerProposalList, loading: denying } =
    useDenyEngagementInfluencerProposalListMutation({ onCompleted: invalidateRouteLoader });

  const hasAllowDenyButtons =
    !hideAllowDenyForModal &&
    [EngagementCampaignStatus.REVIEWING, EngagementCampaignStatus.DRAFT, EngagementCampaignStatus.ONGOING].includes(
      status
    );

  const handleAllowInfluencer = async () => {
    try {
      await callAllowEngagementInfluencerProposalList({ variables: { input: { campaignId, influencerIds: [id] } } });
      enqueueSnackbar(t('succeededInUpdating', { name }), { variant: 'success' });
      setInfluencerDetailsParam(null);
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name }), { variant: 'error' });
    }
  };

  const handleDenyInfluencer = async (deniedReason: string) => {
    if (!deniedReason) return;

    try {
      await callDenyEngagementInfluencerProposalList({
        variables: { input: { campaignId, influencerIds: [id], deniedReason } }
      });
      enqueueSnackbar(t('succeededInUpdating', { name }), { variant: 'success' });
      setDenyingInfluencerId(null);
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name }), { variant: 'error' });
    }
  };

  return (
    <>
      <div css={styles.wrapper}>
        <div css={styles.titleWrapper}>
          <p css={{ fontSize: '18px' }}>{t('Influencer.Influencer Introduction')}</p>
          {isDenied ? <div css={styles.badge}>{t('General.Denied')}</div> : null}
          {hasAllowDenyButtons ? (
            isDenied ? (
              <Button
                variant="blue"
                css={styles.button}
                onClick={handleAllowInfluencer}
                title={t('Button.Allow Again')}
                loading={{ status: allowing, showIcon: true }}
              />
            ) : (
              <Button
                variant="white"
                css={styles.button}
                title={t('Button.Deny This Influencer')}
                onClick={() => setDenyingInfluencerId(id)}
                prefixIcon={{ icon: 'trash', size: '14px' }}
              />
            )
          ) : null}
        </div>

        <div css={{ display: 'flex', padding: '16px', boxSizing: 'border-box', gap: '16px' }}>
          <div css={{ flex: 1 }}>
            <Label title={t('TextForm.Influencer Budget')} />
            <div css={[styles.contentWrapper, { display: 'flex' }]}>
              <p css={{ margin: 'auto' }}>
                <span css={{ fontSize: '18px', fontWeight: '600' }}>{formatIntNumber(budget)}</span>
                <span css={{ marginLeft: '2px', fontSize: '11px', color: THEME.text.colors.gray.lv3 }}>
                  {t(currency || '')}
                </span>
              </p>
            </div>
          </div>

          <div css={{ flex: 1.2 }}>
            <Label title={t('TextForm.Post Requirements')} />
            <RichEditor
              height="120px"
              readOnly={true}
              value={requirements || ''}
              editableCss={css({ padding: '8px 12px' })}
              setOptions={{ mode: 'balloon', resizingBar: false }}
              hideToolbar
            />
          </div>

          <div css={{ flex: 1.2 }}>
            <Label title={t('TextForm.Reasons for Selection')} />
            <div css={styles.contentWrapper}>
              <p css={{ padding: '8px 12px' }}>
                {reasons?.split('\n')?.map((item, key) => (
                  <Fragment key={key}>
                    {item}
                    <br />
                  </Fragment>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDenyingInfluencerModal
        loading={denying}
        open={!!denyingInfluencerId}
        onSubmit={handleDenyInfluencer}
        onClose={() => setDenyingInfluencerId(null)}
      />
    </>
  );
};
const styles = {
  wrapper: css({
    borderRadius: '3px',
    margin: '24px 24px 16px',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  }),
  titleWrapper: css({
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: THEME.border.base
  }),
  badge: css({
    fontWeight: 600,
    fontSize: '12px',
    marginLeft: '8px',
    padding: '5px 8px',
    borderRadius: '3px',
    boxSizing: 'border-box',
    backgroundColor: '#ff2b52',
    color: THEME.text.colors.white
  }),
  button: css({
    marginLeft: 'auto',
    padding: '0 24px',
    textTransform: 'uppercase'
  }),
  contentWrapper: css({
    height: '120px',
    overflowY: 'auto',
    border: THEME.border.base
  })
};
