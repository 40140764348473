import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/Address.graphql';
import { Query, QueryaddressArgs } from '../../__generated__/globalTypes';

export const useAddressQuery = (options?: QueryHookOptions<Query, QueryaddressArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryaddressArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAddressLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryaddressArgs>
): QueryResult<Query, QueryaddressArgs> & { getAddress: LazyQueryExecFunction<Query, QueryaddressArgs> } => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAddress, result] = useLazyQuery<Query, QueryaddressArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAddress, ...result };
};

export const useAddressPromiseQuery = () => {
  const client = useApolloClient();

  const getAddress = useCallback(
    (options?: Omit<QueryOptions<QueryaddressArgs, Query>, 'query'>) =>
      client.query<Query, QueryaddressArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAddress };
};
