import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Anchor, Icon } from '@/shared/atoms';
import { Maybe, TiktokAdAccountInfo, TiktokBusinessAccountInfo } from '@/graphql';
import { THEME, TIKTOK_HELP_LINK } from '@/shared/constants';
import { styles, TikTokSectionTitle } from './styles';
import { CreateAdAccount } from './CreateAdAccountModal';
import { DisconnectAdAccountModal } from './DisconnectAdAccountModal';

interface TikTokAdAccountProps {
  tiktokBusinessAdAccounts?: Maybe<ReadonlyArray<TiktokAdAccountInfo>>;
  tiktokBusinessAccountInfo?: Maybe<TiktokBusinessAccountInfo>;
  hasAccountsLimitWarning?: boolean;
  isOnboardingFlow?: boolean;
}
export const TikTokAdAccount = ({
  isOnboardingFlow,
  tiktokBusinessAdAccounts,
  tiktokBusinessAccountInfo,
  hasAccountsLimitWarning
}: TikTokAdAccountProps) => {
  const { t } = useTranslation();
  const [disconnectAdAccountId, setDisconnectAdAccountId] = useState('');

  return (
    <div css={styles.section}>
      <TikTokSectionTitle
        index={3}
        isActive={!!tiktokBusinessAccountInfo?.bcList?.length}
        isConnected={!!tiktokBusinessAdAccounts?.length}
        title={t('TikTok Ad Account')}
      />
      {tiktokBusinessAccountInfo?.bcList?.length ? (
        <>
          <div>
            <Trans
              i18nKey="Create / connect your TikTok Ad Account"
              components={{
                a: (
                  <Anchor variant="blue" href={TIKTOK_HELP_LINK.needHelp} target="_blank" css={{ display: 'inline' }} />
                )
              }}
            />
          </div>
          <CreateAdAccount
            bcList={tiktokBusinessAccountInfo?.bcList ?? []}
            hasAccountsLimitWarning={hasAccountsLimitWarning}
            css={{ marginTop: '24px' }}
          />
          {hasAccountsLimitWarning ? (
            <div css={styles.warning}>
              <Icon icon="warning" size={16} color={THEME.attention.colors.yellow} />
              <span css={{ margin: '0 8px', fontWeight: 600 }}>{t('Attention')}</span>
              <Trans
                i18nKey="AdAccountsLimitWarning"
                components={{
                  a: (
                    <Anchor
                      variant="blue"
                      to={isOnboardingFlow ? '/tiktok-onboarding/select-plan' : '/tiktok/budget-allocation'}
                      css={{ marginLeft: '4px' }}
                    />
                  )
                }}
              />
            </div>
          ) : null}
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              marginTop: !hasAccountsLimitWarning ? '24px' : 0
            }}
          >
            {tiktokBusinessAdAccounts?.map((adAccount) => {
              const isDisconnected = adAccount.status === 'DISCONNECT';

              return (
                <div
                  key={adAccount.id}
                  css={[
                    styles.bcList,
                    { flexDirection: 'row', alignItems: 'center', paddingRight: '12px', gap: '16px' }
                  ]}
                >
                  <div css={styles.adAccountChain(isDisconnected)}>
                    {isDisconnected ? (
                      <Icon icon="link-broken" size="14px" color={THEME.colors.black['60']} />
                    ) : (
                      <Icon icon="link-chain" size="14px" color={THEME.attention.colors.green} />
                    )}
                  </div>
                  <div
                    css={[
                      { display: 'flex', flexDirection: 'column', gap: '4px' },
                      isDisconnected ? { opacity: 0.3 } : ''
                    ]}
                  >
                    <span css={{ fontSize: '14px' }}>{adAccount.name}</span>
                    <span css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3 }}>
                      {t('ID')}: {adAccount.id}
                    </span>
                    <span css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3 }}>
                      {t('Business Center')}: {adAccount.bcName}
                    </span>
                  </div>
                  <DisconnectAdAccountModal
                    hasAccountsLimitWarning={hasAccountsLimitWarning}
                    isOpen={disconnectAdAccountId === adAccount.id}
                    onClose={() => setDisconnectAdAccountId('')}
                    adAccount={adAccount}
                    onDisconnect={() => setDisconnectAdAccountId(adAccount.id)}
                    isDisconnected={isDisconnected}
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};
