import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchFilterDefaults } from '@/shared/validations';
import { getYesterday } from '@/shared/utils';
import { AnalyticsProfileFilterSchema } from '../../../schemaTypes';

export const AnalyticsTrendingYoutubePostsFilterSchema = z
  .object({
    date: z.string().default(getYesterday()),
    country: z.string().default(''),
    category: z.string().default('24')
  })
  .merge(AnalyticsProfileFilterSchema);
export const AnalyticsTrendingYoutubePostsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(AnalyticsTrendingYoutubePostsFilterSchema, {
    date: getYesterday(),
    country: '',
    category: '24',
    brandAccountName: '',
    endDate: '',
    startDate: ''
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsTrendingYoutubePostsSearchSchemaType = z.infer<typeof AnalyticsTrendingYoutubePostsSearchSchema>;
export type AnalyticsTrendingYoutubePostsFilterFormValues = z.infer<typeof AnalyticsTrendingYoutubePostsFilterSchema>;
export type AnalyticsTrendingYoutubePostsFilterFormKeys = UnionOfKeys<AnalyticsTrendingYoutubePostsFilterFormValues>;
