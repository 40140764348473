import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/FetchAutoManagedCustomShopeeCampaigns.graphql';
import { Mutation, MutationfetchAutoManagedCustomShopeeCampaignsArgs } from '../../__generated__/globalTypes';

export const useFetchAutoManagedCustomShopeeCampaignsMutation = (
  options?: MutationHookOptions<Mutation, MutationfetchAutoManagedCustomShopeeCampaignsArgs>
) => {
  const [callFetchAutoManagedCustomShopeeCampaigns, result] = useMutation<
    Mutation,
    MutationfetchAutoManagedCustomShopeeCampaignsArgs
  >(MUTATION, options);

  return { callFetchAutoManagedCustomShopeeCampaigns, ...result };
};

export type FetchAutoManagedCustomShopeeCampaignsMutationFunction = Mutation['fetchAutoManagedCustomShopeeCampaigns'];
