import { getValuableVariables } from '@/shared/utils';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { useSearchEngCampaignsSelectInfluencersQuery } from '@/graphql';
import { CampaignInfluencersSearchSchemaType } from '../schemaTypes';
import { useGetInfluencersQueryVariables } from '../../hooks';

interface Props extends Pick<ParamsSearchReturnType<CampaignInfluencersSearchSchemaType>, 'page' | 'limit' | 'filter'> {
  campaignId?: number;
}

export const useCampaignInfluencersList = ({ page, limit, filter, campaignId }: Props) => {
  const { variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { data, loading } = useSearchEngCampaignsSelectInfluencersQuery({
    skip: !campaignId || !filter,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: getValuableVariables({
      ...variables,
      categoryIds: variables.campaignCategoryIds?.map(Number),
      currentCampaignId: Number(campaignId)
    })
  });

  return {
    loading,
    listRecords: data?.searchEngCampaignsSelectInfluencers || [],
    totalRecords: data?.searchEngCampaignsSelectInfluencersCount?.totalNumber || 0
  };
};
