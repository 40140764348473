import { Outlet } from '@tanstack/react-router';
import { useAllEngagementPostsQuery } from '@/graphql';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useParamsSearch, useQueryHelper } from '@/shared/hooks';
import { EngagementPostsList, EngagementPostsFilter, EngagementPostsSearchSchemaType } from '@/shared/organisms';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';

export const EngagementPosts = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { engagement, engagementId, isOnlyTikTokTtcm } = useEngagementDetailsContext();
  const { page, limit, filter, setFilter } = useParamsSearch<EngagementPostsSearchSchemaType>();

  const { data, loading } = useAllEngagementPostsQuery({
    fetchPolicy: 'network-only',
    variables: getValuableVariables({
      limit,
      engagementId,
      keyword: filter.keyword,
      offset: getOffset(page, limit),
      status: filter.status || null
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message || 'Cannot get post list data'), { variant: 'error' });
    }
  });

  return (
    <ListWithPagination pagination={{ totalRecords: data?.allEngagementPostsCount?.totalNumber }}>
      <EngagementPostsFilter
        filter={filter}
        setFilter={setFilter}
        engagement={engagement}
        isOnlyTikTokTtcm={isOnlyTikTokTtcm}
      />
      <EngagementPostsList loading={loading} engagement={engagement} posts={data?.allEngagementPosts || []} />

      <Outlet />
    </ListWithPagination>
  );
};
