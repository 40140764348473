import { css } from '@emotion/react';
import { ReactNode, useEffect, useState } from 'react';
import { THEME } from '@/shared/constants';
import { Option, PostDetailsType } from '@/shared/types';

export interface PostTabsProps<V> extends Pick<PostDetailsType, 'id'> {
  defaultTab?: V;
  className?: string;
  hasHistoryTab?: boolean;
  tabs: Option<V, ReactNode>[];
  onChange?: (selectedTab: V) => void;
  children: (props: { selectedTab: V }) => ReactNode;
}
export const PostTabs = <V,>({ id, tabs, children, onChange, className, defaultTab }: PostTabsProps<V>) => {
  const [selectedTab, setSelectedTab] = useState<V>(defaultTab || (tabs.at(0)?.value as V));

  const hasTab = tabs.length > 1 && !!selectedTab;

  useEffect(() => {
    if (hasTab) {
      setSelectedTab(tabs.at(0)?.value as V);
    }
  }, [id, hasTab]);

  return hasTab ? (
    <div css={styles.wrapper} className={className}>
      <div css={styles.tabs}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className="tab-item"
            onClick={() => {
              setSelectedTab(tab.value);
              onChange?.(tab.value);
            }}
            css={styles.tabItem(selectedTab === tab.value)}
          >
            {tab.label}
          </div>
        ))}
      </div>

      {children({ selectedTab })}
    </div>
  ) : (
    children({ selectedTab })
  );
};
const styles = {
  wrapper: css({
    '> div:nth-of-type(2)': { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
  }),
  tabs: css({
    height: '40px',
    backgroundColor: THEME.background.colors.white,
    display: 'flex',
    borderRadius: '5px 5px 0 0',
    borderBottom: THEME.border.base
  }),
  tabItem: (isActive?: boolean) =>
    css({
      display: 'flex',
      cursor: 'pointer',
      textAlign: 'center',
      minWidth: '60px',
      marginLeft: '20px',
      padding: '0 8px',
      alignItems: 'center',
      boxSizing: 'border-box',
      justifyContent: 'center',
      color: THEME.text.colors.gray.lv3,
      borderBottomWidth: '3px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'transparent',
      ...(isActive
        ? {
            color: THEME.text.colors.black.lv1,
            borderBottom: `3px solid ${THEME.border.colors.blue.lv2}`
          }
        : {})
    })
};
