import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { EngagementDetail, ProposalInfluencer } from '@/graphql';
import { Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useControlPostDetailsModal } from '@/shared/hooks';
import { CarouselModal } from '@/shared/molecules';
import { useEngagementProposalInfluencerDetailsParam } from '../hooks';
import { ProposalDerivedProps } from '../../shared/types';
import { InfluencerOverview } from './InfluencerOverview';
import { InfluencersCarousel } from './InfluencersCarousel';
import { ProposalInfluencerAccountProfile } from './ProposalInfluencerAccountProfile';

interface ProposalInfluencersDetailsModalProps {
  engagement: EngagementDetail;
  allInfluencers: ProposalDerivedProps['allInfluencers'];
}
export const ProposalInfluencersDetailsModal = ({
  allInfluencers,
  engagement
}: ProposalInfluencersDetailsModalProps) => {
  const { t } = useTranslation();
  const { paramKey, influencerPopupId } = useEngagementProposalInfluencerDetailsParam();
  const { selectedPost, siblingPostId, closePost, handleClickNext, handleClickPrev } =
    useControlPostDetailsModal<ProposalInfluencer>({ posts: allInfluencers, paramKey });

  const selectedInfluencer = allInfluencers.find((influencer) => influencer.id === influencerPopupId);

  return (
    <CarouselModal
      onClose={closePost}
      open={!!selectedPost}
      hasCloseIcon={false}
      onClickNext={handleClickNext}
      onClickPrev={handleClickPrev}
      siblingPostId={siblingPostId}
      style={{ overlay: { backgroundColor: THEME.background.colors.black } }}
    >
      <div css={styles.wrapper}>
        <div css={{ display: 'flex', marginBottom: '24px', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <h2 css={{ color: THEME.text.colors.white, fontSize: '18px', lineHeight: '24px' }}>
            {t('General.Selected Influencer')}
          </h2>

          <button onClick={closePost} css={styles.closeButton}>
            <span css={{ fontSize: '13px', color: THEME.text.colors.gray.lv1 }}>{t('Button.Close')}</span>
            <Icon icon="close" color={THEME.icon.colors.gray.lv1} size={12} css={{ marginLeft: '8px' }} />
          </button>
        </div>

        <InfluencersCarousel influencers={allInfluencers} />

        {selectedInfluencer ? (
          <div
            css={{
              backgroundColor: THEME.background.colors.gray.lv1,
              overflowY: 'auto',
              marginTop: '16px',
              paddingBottom: '24px'
            }}
          >
            <InfluencerOverview data={selectedInfluencer} engagement={engagement} />
            <ProposalInfluencerAccountProfile influencerId={selectedInfluencer.id} engagementId={engagement.id} />
          </div>
        ) : null}
      </div>
    </CarouselModal>
  );
};
const styles = {
  wrapper: css({
    width: '85vw',
    height: '95vh',
    display: 'flex',
    margin: '0 auto',
    overflow: 'hidden',
    flexDirection: 'column'
  }),
  closeButton: css({
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    textTransform: 'uppercase',
    '&:hover': { opacity: 0.6 }
  })
};
