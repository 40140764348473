import { css } from '@emotion/react';

import { signInBgImage, signInIpadImage } from '@/shared/assets';
import { THEME } from '@/shared/constants';

export const SignInCover = () => (
  <div css={{ position: 'relative', width: '428px' }}>
    <div css={styles.cover} />
    <img css={styles.image} src={signInIpadImage} />
  </div>
);
const styles = {
  image: css({
    right: 0,
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)'
  }),
  cover: css({
    height: '100%',
    width: 'inherit',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: `url(${signInBgImage})`,
    borderTopRightRadius: THEME.container.dashboard.borderRadius,
    borderBottomRightRadius: THEME.container.dashboard.borderRadius
  })
};
