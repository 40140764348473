import React from 'react';
import { useTranslation } from 'react-i18next';
import { RenderDataWithFallback, ListEmpty } from '@/shared/atoms';
import { useInfluencerAddRoute } from '@/shared/hooks';
import { useDouyinInfluencersContext } from '../DouyinInfluencersContext';
import { ProfileWidget } from './ProfileWidget';

export const DouyinInfluencersList = () => {
  const { t } = useTranslation();
  const { allowAddInfluencer, addInfluencerRoute } = useInfluencerAddRoute();
  const { loading, listRecords, getListSelectButtonProps } = useDouyinInfluencersContext();

  return (
    <RenderDataWithFallback
      loading={loading}
      hasNoData={listRecords.length === 0}
      noDataNode={
        <ListEmpty
          linkProps={allowAddInfluencer ? { label: t('add an influencer'), href: addInfluencerRoute } : undefined}
        />
      }
    >
      {listRecords.map((profile, index) => (
        <React.Fragment key={`influencer-${profile.id}-${index}`}>
          <ProfileWidget
            profile={profile}
            css={{ '.info-group': { marginTop: '4px' } }}
            selectButtonProps={getListSelectButtonProps?.(profile)}
          />
        </React.Fragment>
      ))}
    </RenderDataWithFallback>
  );
};
