import {
  PackageInfluencer,
  ProposalJobDescriptionBreakDownMode,
  SocialAccountType,
  namedOperations,
  useDeleteInfluencerPackageMutation
} from '@/graphql';
import { Table } from '@/shared/atoms';
import { useQueryHelper, useSelectItem, useToggle } from '@/shared/hooks';
import { TableData } from '@/shared/types';
import { BudgetBulkEditModal, ProposeInfluencersTableMode } from '@/shared/organisms';
import { usePackageProposalContext } from '../PackageProposalContext';
import { ToggleJdModeAndBudgetEdit } from './ToggleJdModeAndBudgetEdit';
import { PackageActionBar } from './PackageActionBar';
import {
  usePackageBulkEdit,
  usePackageInfluencersTable,
  useSocialAccountsForPackage,
  useInfluencerAccountForPackage
} from './hooks';

export interface PackageContentListProps extends TableData<PackageInfluencer> {
  packageId: number;
}
type DeletingItem = {
  id: number;
  name: string;
  socialMedia: SocialAccountType;
};

export const PackageContentList = ({ loading, packageId }: PackageContentListProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { on: openBulkModal, toggle: toggleBulkModal } = useToggle();
  const { toggle: toggleConfirmModal } = useToggle();
  const { resetSelectedItem } = useSelectItem<DeletingItem>();
  const { proposalSummary, packageInfluencers, packageSocialAccounts, packageSocialAccountIds, filter, setFilter } =
    usePackageProposalContext();
  const { tableViewMode, jdMode } = filter;
  const {
    packageInfluencerIds,
    handleSelectSingleInfluencer,
    selectedInfluencerAccountIds,
    setSelectedInfluencerAccountIds,
    handleToggleSelectAllInfluencers
  } = useInfluencerAccountForPackage({ packageInfluencers });
  const {
    selectedSocialAccounts,
    selectedSocialAccountIds,
    setSelectedSocialAccountIds,
    handleSelectSingleAccount,
    handleToggleSelectAllAccounts
  } = useSocialAccountsForPackage({ packageSocialAccounts, packageSocialAccountIds });
  const { bulkLoading, handleInfluencersBudgetSubmit, handleSocialAccountBudgetSubmit } = usePackageBulkEdit({
    packageId,
    selectedSocialAccounts,
    selectedInfluencerAccountIds
  });
  const { callDeleteInfluencerPackage, loading: isDeleting } = useDeleteInfluencerPackageMutation({
    refetchQueries: [namedOperations.Query.PackageProposal]
  });

  const isViewMode = tableViewMode === ProposeInfluencersTableMode.VIEW;
  const isSocialMediaAccountsSelection =
    proposalSummary?.jdMode === ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN && !isViewMode;

  const handleRowSelect = (influencerId: number, socialAccountId?: number) => {
    if (isSocialMediaAccountsSelection) {
      handleSelectSingleAccount(socialAccountId as number);
    } else {
      handleSelectSingleInfluencer(influencerId);
    }
  };
  const handleSelectAllIds = () => {
    if (isSocialMediaAccountsSelection) {
      setSelectedSocialAccountIds(packageSocialAccountIds);
    } else {
      setSelectedInfluencerAccountIds(packageInfluencerIds);
    }
  };
  const handleToggleAllIds = () => {
    if (isSocialMediaAccountsSelection) {
      handleToggleSelectAllAccounts();
    } else {
      handleToggleSelectAllInfluencers();
    }
  };

  const { rows, columns, rowValues, hasRowChange, resetEditingRowsData, deleting } = usePackageInfluencersTable({
    tableViewMode: tableViewMode as ProposeInfluencersTableMode,
    currency: proposalSummary?.currency || '',
    onRowSelect: handleRowSelect,
    jdMode: jdMode as ProposalJobDescriptionBreakDownMode,
    influencers: packageInfluencers,
    selectButtonProps: { onClick: handleSelectAllIds },
    listSelectedIds: isSocialMediaAccountsSelection ? selectedSocialAccountIds : selectedInfluencerAccountIds,
    resetSelectedItem,
    packageId
  });

  const handleTableViewModeChange = (mode: ProposeInfluencersTableMode) => {
    if (hasRowChange) {
      toggleConfirmModal();
    } else {
      if (mode === ProposeInfluencersTableMode.EDIT) {
        setSelectedSocialAccountIds([]);
      } else {
        setSelectedInfluencerAccountIds([]);
      }

      setFilter({ tableViewMode: mode });
    }
  };

  const handleRemoveInfluencers = async (callback?: () => void) => {
    try {
      await callDeleteInfluencerPackage({ variables: { influencerIds: selectedAccountsIds, packageId } });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      callback?.();
    }
  };

  const selectedAccountsIds = isSocialMediaAccountsSelection ? selectedSocialAccountIds : selectedInfluencerAccountIds;
  const selectedAccountsIdsLength = selectedAccountsIds.length;
  const hasAllSelectedIds = isSocialMediaAccountsSelection
    ? selectedAccountsIdsLength === packageSocialAccountIds.length
    : selectedAccountsIdsLength === packageInfluencerIds.length;

  return (
    <>
      <ToggleJdModeAndBudgetEdit
        rowValues={rowValues}
        resetEditingRowsData={resetEditingRowsData}
        tableViewMode={tableViewMode as ProposeInfluencersTableMode}
        hasRowChange={hasRowChange}
        handleTableViewModeChange={handleTableViewModeChange}
      />
      <div css={{ position: 'relative' }}>
        {selectedAccountsIdsLength > 0 ? (
          <PackageActionBar
            isRemoving={isDeleting}
            packageId={proposalSummary?.id}
            isViewMode={isViewMode}
            onSelectAll={handleSelectAllIds}
            onRemove={handleRemoveInfluencers}
            hasAllSelected={hasAllSelectedIds}
            onToggleSelect={handleToggleAllIds}
            onToggleBulkEditModal={toggleBulkModal}
            numberSelected={selectedAccountsIdsLength}
            selectedSocialAccounts={selectedSocialAccounts}
            selectedInfluencerAccountIds={selectedInfluencerAccountIds}
            isSocialMediaAccountsSelection={isSocialMediaAccountsSelection}
          />
        ) : null}
        <Table
          loading={loading}
          data={{ rows, columns, rowHeight: '62px' }}
          empty={{
            description: t('Please add your social accounts'),
            title: t('No social accounts have been added to the package'),
            linkProps: {
              href: '/package/$id/content/search',
              params: { id: packageId.toString() },
              label: t('in the select influencers tab')
            }
          }}
          noTopBorderRadius
          hasBorder
          hasLoadingOverlay={deleting}
        />
      </div>
      <BudgetBulkEditModal
        openModal={openBulkModal}
        toggleModal={toggleBulkModal}
        onSuccess={() => resetEditingRowsData()}
        currency={proposalSummary?.currency || ''}
        jdMode={jdMode as ProposalJobDescriptionBreakDownMode}
        loading={bulkLoading}
        onInfluencersBudgetSubmit={handleInfluencersBudgetSubmit}
        onSocialAccountBudgetSubmit={handleSocialAccountBudgetSubmit}
      />
    </>
  );
};
