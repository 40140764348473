import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchFilterDefaults } from '@/shared/validations';
import { UGCPostType, UGCPostStatus, InstagramUGCPostsSortOrder } from '@/graphql';
import { LIMIT_36 } from '@/shared/constants';
import { defaultInstagramUGCPostsFilterFormValues } from './utils';

// This UI mixed Filter & Sort together
export const AnalyticsInstagramUGCPostsSortSchema = SearchFilterDefaults.sort(InstagramUGCPostsSortOrder);
export const AnalyticsInstagramUGCPostsFilterSchema = z
  .object({
    keyword: z.string(),
    hashtagId: z.string(),
    postType: z.nativeEnum(UGCPostType),
    status: z.nativeEnum(UGCPostStatus).or(z.literal('')),
    startDate: z.string().optional(),
    endDate: z.string().optional()
  })
  .merge(AnalyticsInstagramUGCPostsSortSchema);

export const AnalyticsInstagramUGCPostsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(AnalyticsInstagramUGCPostsFilterSchema, defaultInstagramUGCPostsFilterFormValues),
  ...SearchFilterDefaults.pageLimit(LIMIT_36)
});

export type AnalyticsInstagramUGCPostsSearchSchemaType = z.infer<typeof AnalyticsInstagramUGCPostsSearchSchema>;
export type AnalyticsInstagramUGCPostsFilterFormValues = z.infer<typeof AnalyticsInstagramUGCPostsFilterSchema>;
export type AnalyticsInstagramUGCPostsFilterFormKeys = UnionOfKeys<AnalyticsInstagramUGCPostsFilterFormValues>;
