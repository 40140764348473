import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/SocialAuthRedirectUrlForInfluencer.graphql';
import { Query, QuerysocialAuthRedirectUrlForInfluencerArgs } from '../../__generated__/globalTypes';

export const useSocialAuthRedirectUrlForInfluencerQuery = (
  options?: QueryHookOptions<Query, QuerysocialAuthRedirectUrlForInfluencerArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerysocialAuthRedirectUrlForInfluencerArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useSocialAuthRedirectUrlForInfluencerLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerysocialAuthRedirectUrlForInfluencerArgs>
): QueryResult<Query, QuerysocialAuthRedirectUrlForInfluencerArgs> & {
  getSocialAuthRedirectUrlForInfluencer: LazyQueryExecFunction<Query, QuerysocialAuthRedirectUrlForInfluencerArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getSocialAuthRedirectUrlForInfluencer, result] = useLazyQuery<
    Query,
    QuerysocialAuthRedirectUrlForInfluencerArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getSocialAuthRedirectUrlForInfluencer, ...result };
};

export const useSocialAuthRedirectUrlForInfluencerPromiseQuery = () => {
  const client = useApolloClient();

  const getSocialAuthRedirectUrlForInfluencer = useCallback(
    (options?: Omit<QueryOptions<QuerysocialAuthRedirectUrlForInfluencerArgs, Query>, 'query'>) =>
      client.query<Query, QuerysocialAuthRedirectUrlForInfluencerArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getSocialAuthRedirectUrlForInfluencer };
};
