import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllInfluencersV4.graphql';
import { Query, QueryallInfluencersV4Args } from '../../__generated__/globalTypes';

export const useAllInfluencersV4Query = (options?: QueryHookOptions<Query, QueryallInfluencersV4Args>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallInfluencersV4Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllInfluencersV4LazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallInfluencersV4Args>
): QueryResult<Query, QueryallInfluencersV4Args> & {
  getAllInfluencersV4: LazyQueryExecFunction<Query, QueryallInfluencersV4Args>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllInfluencersV4, result] = useLazyQuery<Query, QueryallInfluencersV4Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllInfluencersV4, ...result };
};

export const useAllInfluencersV4PromiseQuery = () => {
  const client = useApolloClient();

  const getAllInfluencersV4 = useCallback(
    (options?: Omit<QueryOptions<QueryallInfluencersV4Args, Query>, 'query'>) =>
      client.query<Query, QueryallInfluencersV4Args>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllInfluencersV4 };
};
