import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { MarketplaceCampaignDetailType } from '@/graphql';
import { useAllCountryOptions, useTranslateOptions } from '@/shared/hooks';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { useMarketplaceStatusOptions } from '../hooks';
import { MarketplaceCampaignsFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { allCountryOptions } = useAllCountryOptions();
  const { isAdmin, isStaff } = useUserRoles();
  const { marketplaceStatusOptions } = useMarketplaceStatusOptions(true);

  const campaignDetailTypeOptions = useTranslateOptions([
    { label: 'Social media campaign', value: MarketplaceCampaignDetailType.NORMAL },
    { label: 'E-commerce marketplace type', value: MarketplaceCampaignDetailType.E_COMMERCE },
    { label: 'Referral code tracking', value: MarketplaceCampaignDetailType.REFERRAL_CODE },
    { label: 'URL tracking', value: MarketplaceCampaignDetailType.URL_TRACKING }
  ]);

  return (
    <BasicFilterWrapper>
      <TextSearchField<MarketplaceCampaignsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.MarketplaceFilter')}
      />
      <SelectorField<MarketplaceCampaignsFilterFormKeys>
        name="status"
        onSubmit={onSubmit}
        css={{ maxWidth: '158px' }}
        options={marketplaceStatusOptions}
        placeholder={t<string>('Selector.Status')}
      />
      <SelectorField<MarketplaceCampaignsFilterFormKeys>
        name="countryId"
        onSubmit={onSubmit}
        options={allCountryOptions}
        css={{ maxWidth: '158px' }}
        placeholder={t<string>('Selector.Countries')}
      />
      {isAdmin || isStaff ? (
        <SelectorField<MarketplaceCampaignsFilterFormKeys>
          onSubmit={onSubmit}
          name="campaignDetailType"
          css={{ maxWidth: '180px' }}
          options={campaignDetailTypeOptions}
          placeholder={t<string>('Selector.Campaign type')}
          singleSelectorProps={{
            menuStyles: {
              width: 'max-content',
              maxWidth: '300px',
              right: 0
            }
          }}
        />
      ) : null}
    </BasicFilterWrapper>
  );
};
