import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const MarketplaceJoinedInfluencersFilterSchema = FilterValidations.keyword;
export const MarketplaceJoinedInfluencersSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(MarketplaceJoinedInfluencersFilterSchema, {
    keyword: ''
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type MarketplaceJoinedInfluencersSearchSchemaTypes = z.infer<typeof MarketplaceJoinedInfluencersSearchSchema>;
export type MarketplaceJoinedInfluencersFilterValues = z.infer<typeof MarketplaceJoinedInfluencersFilterSchema>;
export type MarketplaceJoinedInfluencersFilterFormKeys = UnionOfKeys<MarketplaceJoinedInfluencersFilterValues>;
