import { InstagramInfluencerV3, SocialAccountStatus, SocialAccountType } from '@/graphql';
import { InfluencersTabType } from '@/shared/types';
import { InstagramInfluencerProfile, InstagramInfluencersFilterFormValues } from './schemaTypes';

export const getInitialInstagramInfluencersFormValues = (
  defaultFilter?: Partial<InstagramInfluencersFilterFormValues>,
  isChatDemoAccount?: boolean
): Partial<InstagramInfluencersFilterFormValues> => ({
  tab: InfluencersTabType.INSTAGRAM,
  // TODO DEMO: Default filter: https://adasiaholdings.atlassian.net/browse/AT-6247
  ...(isChatDemoAccount
    ? { minFollowers: 10000, countryIds: ['TH', 'ID', 'MY'], status: SocialAccountStatus.SCRAPING }
    : {}),
  ...defaultFilter
});

export const formatInfluencerProfile = (infl: InstagramInfluencerV3): InstagramInfluencerProfile => ({
  id: infl.id,
  age: infl.age,
  name: infl.name,
  gender: infl.gender,
  avatar: infl.avatar,
  country: infl.country,
  averageLikes: infl.averageLikes,
  averageReach: infl.averageReach,
  isBrandAccount: infl.isBrandAccount,
  socialAccountId: infl.socialAccountId,
  analyticsWarning: infl.analyticsWarning,
  socialAccountStatus: infl.socialAccountStatus,
  socialAccountType: SocialAccountType.INSTAGRAM,
  socialAccountFollowers: infl.socialAccountFollowers,
  socialAccountEngagementRate: infl.socialAccountEngagementRate,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail, type, caption }) => ({
      type,
      caption,
      thumbNail,
      id: postId,
      influencerId: Number(infl.id)
    })) || []
});
