import 'highcharts/modules/heatmap';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import {
  NoDataTitle,
  NoDataContentWrapper,
  NoDataContentWrapperProps,
  ChartTooltipMultipleMetrics,
  ChartTooltipMultipleMetricsProps
} from '@/shared/atoms';
import { AppLanguage } from '@/shared/types';
import { hourIntervals, localizedWeekdays } from '@/shared/utils';
import { HighChart, Highcharts } from '../HighChart';

const FanActivityDataKeys = {
  onlineCount: 'onlineCount'
} as const;

type Keys = keyof typeof FanActivityDataKeys;
type TooltipPropsType = Pick<ChartTooltipMultipleMetricsProps<Keys>, 'categories' | 'data'>;

export interface FanActivityProps
  extends Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'>,
    Pick<ChartTooltipMultipleMetricsProps<Keys>, 'customTooltip'>,
    Pick<Highcharts.ChartOptions, 'height'> {
  data?: readonly { hour: number; weekday: number; onlineCount: number }[];
}
export const FanActivity = ({ data, header, height = 350, customTooltip, ...noDataContentProps }: FanActivityProps) => {
  const { t, i18n } = useTranslation();

  const hasNoData = !data || data.every((item) => item.onlineCount === 0);
  const chartData = !hasNoData
    ? data.map((item) => ({
        ...item,
        x: item.hour,
        y: item.weekday,
        value: item.onlineCount,
        key: `${item.weekday}-${item.hour}`,
        category: `${localizedWeekdays(i18n.language as AppLanguage)[item.weekday]} ${hourIntervals[item.hour]}`
      }))
    : [];
  const tooltipProps = chartData.reduce<TooltipPropsType>(
    (acc, curr) => {
      acc.categories.push(curr.category);
      (Object.keys(FanActivityDataKeys) as Keys[]).forEach((key) => {
        acc.data[key].data.push(curr[key]);
      });

      return acc;
    },
    {
      categories: [],
      data: { onlineCount: { name: t<string>('General.Online Count'), data: [] } }
    }
  );
  const options: Highcharts.Options = {
    title: { text: '' },
    legend: { enabled: false },
    chart: { height, marginTop: 0, type: 'heatmap', inverted: true },
    colorAxis: { min: 0, minColor: '#eef3f7', maxColor: '#ed183e' },
    plotOptions: { heatmap: { borderWidth: 2, borderColor: '#fff' } },
    series: [
      {
        type: 'heatmap',
        data: chartData,
        name: 'Fan Activity',
        borderColor: 'transparent',
        dataLabels: { enabled: false }
      }
    ],
    xAxis: {
      min: 0,
      offset: 35,
      opposite: true,
      lineColor: '#FFFFFF',
      gridLineColor: 'transparent',
      labels: { x: 0, align: 'right', style: { fontSize: '11px' } },
      categories: hourIntervals.map((item) => item.replace(/\s/g, ''))
    },
    yAxis: {
      min: 0,
      lineColor: '#fff',
      title: { text: '' },
      gridLineColor: 'transparent',
      labels: { style: { fontSize: '10px' } },
      categories: localizedWeekdays(i18n.language as AppLanguage).map((item) => item.slice(0, 3))
    },
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      headerFormat: '',
      borderColor: '#27313b',
      formatter() {
        return renderToStaticMarkup(
          <ChartTooltipMultipleMetrics
            {...tooltipProps}
            customTooltip={customTooltip}
            selectedCategory={this.category as string}
          />
        );
      }
    }
  };

  return (
    <NoDataContentWrapper
      css={{ padding: '10px 8px 0' }}
      dataNotAvailable={hasNoData}
      header={
        header ?? (
          <NoDataTitle
            css={{ margin: '0 0 8px 10px' }}
            title={t('Influencer.FanActivity')}
            help={t<string>('Tooltip.FanActivity')}
          />
        )
      }
      {...noDataContentProps}
    >
      <HighChart options={options} />
    </NoDataContentWrapper>
  );
};
