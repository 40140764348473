import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerTwitterAnalyticsPostsByDate.graphql';
import { Query, QueryinfluencerTwitterAnalyticsPostsByDateArgs } from '../../__generated__/globalTypes';

export const useInfluencerTwitterAnalyticsPostsByDateQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerTwitterAnalyticsPostsByDateArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerTwitterAnalyticsPostsByDateArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerTwitterAnalyticsPostsByDateLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerTwitterAnalyticsPostsByDateArgs>
): QueryResult<Query, QueryinfluencerTwitterAnalyticsPostsByDateArgs> & {
  getInfluencerTwitterAnalyticsPostsByDate: LazyQueryExecFunction<
    Query,
    QueryinfluencerTwitterAnalyticsPostsByDateArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerTwitterAnalyticsPostsByDate, result] = useLazyQuery<
    Query,
    QueryinfluencerTwitterAnalyticsPostsByDateArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerTwitterAnalyticsPostsByDate, ...result };
};

export const useInfluencerTwitterAnalyticsPostsByDatePromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerTwitterAnalyticsPostsByDate = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerTwitterAnalyticsPostsByDateArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerTwitterAnalyticsPostsByDateArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerTwitterAnalyticsPostsByDate };
};
