import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { NoDataTitle, NoDataContentWrapper, ChartTooltipSingleMetric, NoDataContentWrapperProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';
import { formatPercent } from '@/shared/utils';
import { HighChart, Highcharts } from '../HighChart';

export interface SingleMetricBarChartProps
  extends Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'> {
  valueOptions: Option<number>[];
}

export const SingleAreaBreakdownChart = ({
  header,
  valueOptions = [],
  ...noDataContentProps
}: SingleMetricBarChartProps) => {
  const { t } = useTranslation();

  const { categories, seriesData } = valueOptions.reduce<{ categories: string[]; seriesData: number[] }>(
    (acc, curr) => ({
      categories: curr.label ? [...acc.categories, curr.label] : acc.categories,
      seriesData: curr.value ? [...acc.seriesData, curr.value] : acc.seriesData
    }),
    { categories: [], seriesData: [] }
  );

  const options: Highcharts.Options = {
    title: { text: '' },
    xAxis: { categories },
    chart: { type: 'bar', height: 300 },
    plotOptions: { series: { stacking: 'normal' } },
    series: [{ type: 'bar', data: seriesData, color: '#FFCA98', name: t<string>('Total') }],
    yAxis: {
      min: 0,
      title: { text: '' },
      labels: {
        formatter() {
          return formatPercent(Number(this.value), true, 0);
        }
      }
    },
    legend: {
      reversed: true,
      symbolWidth: 15,
      symbolRadius: 2,
      symbolHeight: 15,
      itemMarginBottom: 6,
      itemStyle: { fontSize: '13px', color: THEME.text.colors.black.lv1, fontWeight: 'normal' }
    },
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: THEME.background.colors.black,
      style: { color: THEME.text.colors.white },
      formatter(): string {
        return renderToStaticMarkup(
          <ChartTooltipSingleMetric
            category={this.x}
            name={`${this.series.name}:`}
            color={this.color as string}
            value={formatPercent(this.y)}
          />
        );
      }
    }
  };

  return (
    <NoDataContentWrapper
      css={{ padding: '24px 16px' }}
      dataNotAvailable={!valueOptions?.length}
      header={
        header ?? (
          <NoDataTitle
            title={t('Area Breakdown')}
            css={{ marginBottom: '14px' }}
            help={t<string>('Tooltip.areaBreakdown')}
          />
        )
      }
      {...noDataContentProps}
    >
      <HighChart options={options} />
    </NoDataContentWrapper>
  );
};
