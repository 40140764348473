import { useUserRoles } from '@/auth';
import { Maybe, TiktokAdvertiserSummaryData, useTiktokAdvertiserSummaryDataQuery } from '@/graphql';
import { getToken } from '@/shared/utils';

export interface TikTokSummaryDataContext {
  summaryData?: Maybe<TiktokAdvertiserSummaryData>;
  loading?: boolean;
}

export const useTikTokAdvertiserSummaryData = () => {
  const { isAdvertiserFromTikTok } = useUserRoles();
  const { data, loading } = useTiktokAdvertiserSummaryDataQuery({
    skip: !getToken() || !isAdvertiserFromTikTok
  });

  return { summaryData: data?.tiktokAdvertiserSummaryData, loading };
};
