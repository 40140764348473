import { z } from 'zod';
import { ToggleOption } from '@/shared/types';
import { BaseValidations } from '@/shared/validations';

export const PartnerFormSchema = z.object({
  id: z.number().optional(),
  countryId: BaseValidations.CountryIdSchema,
  companyUrl: z.string(),
  netsuiteId: z.string(),
  logo: z.string().optional(),
  agencyIds: z.array(z.string()),
  phoneNumber: z.string(),
  advertiserIds: z.array(z.string()),
  partnerAgencyName: BaseValidations.CompanyNameSchema,
  privateInfluencersOptions: z.array(BaseValidations.GenericOptionShape),
  influencerCostPermission: z.nativeEnum(ToggleOption)
});
