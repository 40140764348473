import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { CampaignIdentifierType, CampaignPostSocialType, CampaignPostSortField } from '@/graphql';
import { LIMIT } from '@/shared/constants';

export const CampaignPostsDiscoveryFilterSchema = z
  .object({
    keyword: FilterValidations.string(),
    campaigns: FilterValidations.arrayString(),
    countryIds: FilterValidations.arrayString(),
    categoryIds: FilterValidations.arrayString(),
    influencerIds: FilterValidations.arrayString(),
    advertiserIds: FilterValidations.arrayString(),
    campaignTypes: FilterValidations.arrayEnum(CampaignIdentifierType),
    socialPostTypes: FilterValidations.arrayEnum(CampaignPostSocialType)
  })
  .merge(FilterValidations.period);
export const CampaignPostsDiscoverySearchSchema = z.object({
  filter: SearchFilterDefaults.filter(CampaignPostsDiscoveryFilterSchema, {
    keyword: '',
    campaigns: [],
    countryIds: [],
    categoryIds: [],
    influencerIds: [],
    advertiserIds: [],
    campaignTypes: [],
    socialPostTypes: [],
    endDate: '',
    startDate: ''
  }),
  limit: z.number().default(LIMIT),
  page: z.number().default(1),
  sort: FilterValidations.sort(CampaignPostSortField).default({ field: null, order: null })
});

export type CampaignPostsDiscoverySearchType = z.infer<typeof CampaignPostsDiscoverySearchSchema>;
export type CampaignPostsDiscoveryFilterFormValues = z.infer<typeof CampaignPostsDiscoveryFilterSchema>;
export type CampaignPostsDiscoveryFilterFormKeys = UnionOfKeys<CampaignPostsDiscoveryFilterFormValues>;
