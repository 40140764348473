import { useTranslation } from 'react-i18next';
import { getValuableVariables } from '@/shared/utils';
import { HAS_NO_SELECTED_ACCOUNTS } from '@/shared/constants';
import { InstagramCompareFollowers, useInstagramCompareFollowersQuery } from '@/graphql';
import { AnalyticsAudienceWrapper } from '../../../shared';
import { AnalyticsCompareInstagramAccountsProps } from '../AnalyticsCompareInstagramAccounts';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { Template } from './Template';

export interface AnalyticsCompareInstagramAudienceProps
  extends Pick<AnalyticsCompareInstagramAccountsProps, 'accounts' | 'mainAccountId' | 'hiddenAccountIds'> {
  hasFreePlan?: boolean;
  audienceMockData?: InstagramCompareFollowers;
}

export const AnalyticsCompareInstagramAudience = ({
  accounts,
  mainAccountId,
  hiddenAccountIds,
  hasFreePlan,
  audienceMockData
}: AnalyticsCompareInstagramAudienceProps) => {
  const { t } = useTranslation();
  const { filter } = useAnalyticsProfileSearch();

  const comparableAccountIds = accounts.reduce<number[]>(
    (acc, curr) => (curr.id !== mainAccountId ? [...acc, curr.id] : acc),
    []
  );

  const { data, loading } = useInstagramCompareFollowersQuery({
    skip: !comparableAccountIds.length || hasFreePlan,
    variables: getValuableVariables({
      comparableAccountIds,
      endDate: filter.endDate,
      startDate: filter.startDate,
      accountId: mainAccountId
    })
  });

  const audienceData = hasFreePlan ? audienceMockData : data?.instagramCompareFollowers;
  const comparableAccounts = accounts.filter((account) => !hiddenAccountIds?.includes(account.id));

  return (
    <AnalyticsAudienceWrapper
      loading={loading}
      data={audienceData}
      help={t('Tooltip.compareAudience')}
      wrapperText={HAS_NO_SELECTED_ACCOUNTS}
      dataNotAvailable={!comparableAccounts.length || !audienceData}
    >
      {({ data }) => <Template data={data} comparableAccounts={comparableAccounts} />}
    </AnalyticsAudienceWrapper>
  );
};
