import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { FollowersGrowthPeriodEnum, useTrendingFacebookInfluencersQuery } from '@/graphql';
import {
  TrendingInfluencersList,
  TrendingInfluencersTabType,
  useTrendingInfluencersFilter,
  TrendingInfluencerProfileType
} from '@/shared/organisms';

export const TrendingFacebookInfluencers = () => {
  const { page, limit, filter } = useTrendingInfluencersFilter();
  const { data, loading } = useTrendingFacebookInfluencersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      ...getValuableVariables({
        limit,
        countryId: filter.countryId,
        offset: getOffset(page, limit),
        minFollowers: filter.minFollowers ? Number(filter.minFollowers) : null,
        followerGrowthPeriod: filter.followerGrowthPeriod as FollowersGrowthPeriodEnum
      }),
      categoryIds: filter.categoryIds.map(Number)
    }
  });

  const trendingInfluencers = data?.trendingFacebookInfluencers;
  const totalRecords = trendingInfluencers?.totalNumber;
  const listRecords: TrendingInfluencerProfileType[] =
    trendingInfluencers?.trending.map((infl) => ({
      ...infl,
      socialMedia: infl.facebookType,
      popularPosts: infl.popularPosts?.map(({ postId, thumbNail, type }) => ({ id: postId, thumbNail, type })) || []
    })) || [];

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <TrendingInfluencersList
        loading={loading}
        data={listRecords}
        totalRecords={totalRecords}
        tab={TrendingInfluencersTabType.FACEBOOK}
      />
    </ListWithPagination>
  );
};
