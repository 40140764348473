import { useTranslation } from 'react-i18next';
import { useTranslateOptions, useAllAvailableCountryOptions } from '@/shared/hooks';
import { AnalyticsAdvertiserPlanFilter, NetSuiteVerifiedStatus, TikTokAdvertiserPlanFilter } from '@/graphql';
import { Option } from '@/shared/types';

export const useAccountsFilterOptions = () => {
  const { t } = useTranslation();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const verifiedOptions = useTranslateOptions([
    { label: 'Verified', value: NetSuiteVerifiedStatus.VERIFIED },
    { label: 'Not Verified', value: NetSuiteVerifiedStatus.NOT_VERIFIED },
    { label: 'No Status', value: NetSuiteVerifiedStatus.NO_STATUS }
  ]);
  const analyticsPlanOptions: Option<AnalyticsAdvertiserPlanFilter, string>[] = [
    { label: t<string>(`Plan.${AnalyticsAdvertiserPlanFilter.FREE}`), value: AnalyticsAdvertiserPlanFilter.FREE },
    { label: t<string>(`Plan.${AnalyticsAdvertiserPlanFilter.TRIAL}`), value: AnalyticsAdvertiserPlanFilter.TRIAL },
    { label: t<string>(`Plan.${AnalyticsAdvertiserPlanFilter.BASIC}`), value: AnalyticsAdvertiserPlanFilter.BASIC },
    {
      label: t<string>(`Plan.${AnalyticsAdvertiserPlanFilter.ENTERPRISE}`),
      value: AnalyticsAdvertiserPlanFilter.ENTERPRISE
    }
  ];
  const tikTokAdvertiserPlanOptions: Option<TikTokAdvertiserPlanFilter, string>[] = [
    { label: t<string>(`Plan.${TikTokAdvertiserPlanFilter.BEGINNER}`), value: TikTokAdvertiserPlanFilter.BEGINNER },
    { label: t<string>(`Plan.${TikTokAdvertiserPlanFilter.STANDARD}`), value: TikTokAdvertiserPlanFilter.STANDARD },
    { label: t<string>(`Plan.${TikTokAdvertiserPlanFilter.ENTERPRISE}`), value: TikTokAdvertiserPlanFilter.ENTERPRISE },
    { label: t<string>('Option.No plan'), value: TikTokAdvertiserPlanFilter.NO_PLAN }
  ];

  return {
    verifiedOptions,
    analyticsPlanOptions,
    allAvailableCountryOptions,
    tikTokAdvertiserPlanOptions
  };
};
