import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Table } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useBudgetAllocationTable, useBudgetAllocationTableProps } from './useBudgetAllocationTable';

interface BudgetAllocationTableProps {
  data: useBudgetAllocationTableProps['data'];
  loading: boolean;
  prepaidBalance: number;
}
export const BudgetAllocationTable = ({ data, loading, prepaidBalance }: BudgetAllocationTableProps) => {
  const { rows, columns } = useBudgetAllocationTable({ data, prepaidBalance });
  const { t } = useTranslation();

  return (
    <Table data={{ rows, columns }} skipNoDataNode>
      {data.length === 0 && !loading ? <div css={styles.emptyTable}>{t('No Data')}</div> : null}
    </Table>
  );
};

const styles = {
  emptyTable: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '48px',
    backgroundColor: THEME.background.colors.white,
    borderRadius: '0 0 3px 3px',
    boxShadow: THEME.shadows.boxShadow,
    color: THEME.text.colors.gray.lv3
  })
};
