import { useQueryHelper } from '@/shared/hooks';
import { usePostsFilter } from '@/shared/organisms';
import { useAllInfluencerPostsV3Query } from '@/graphql';
import {
  MIN_VIEWS,
  MAX_VIEWS,
  MIN_FOLLOWERS,
  MAX_FOLLOWERS,
  MIN_ENGAGEMENT_RATE,
  MAX_ENGAGEMENT_RATE
} from '@/shared/constants';
import {
  getOffset,
  convertGenders,
  getVariableNumber,
  getVariableRateValue,
  getValuableVariables
} from '@/shared/utils';

export const usePostsData = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { page, limit, filter } = usePostsFilter();
  const { data, loading } = useAllInfluencerPostsV3Query({
    skip: !filter.socialMedia,
    variables: getValuableVariables({
      limit,
      orderBy: { field: filter.field, order: filter.order },
      offset: getOffset(page, limit),
      keyword: filter.keyword,
      countryId: filter.countryId,
      minPostDate: filter.minPostDate,
      maxPostDate: filter.maxPostDate,
      genders: convertGenders(filter.genders),
      socialPostType: filter.socialMedia || null,
      categoryIds: filter.categoryIds.map(Number),
      minViews: getVariableNumber(filter.minViews, MIN_VIEWS),
      maxViews: getVariableNumber(filter.maxViews, MAX_VIEWS),
      minFollowers: getVariableNumber(filter.minFollowers, MIN_FOLLOWERS),
      maxFollowers: getVariableNumber(filter.maxFollowers, MAX_FOLLOWERS),
      minEngagementRate: getVariableRateValue(filter.minEngagementRate, MIN_ENGAGEMENT_RATE),
      maxEngagementRate: getVariableRateValue(filter.maxEngagementRate, MAX_ENGAGEMENT_RATE)
    }),
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      enqueueSnackbar(t(err.message || 'Cannot get post list data'), { variant: 'error' });
    }
  });

  return {
    loading,
    posts: data?.allInfluencerPostsV3.posts,
    totalRecords: data?.allInfluencerPostsV3.totalCount || 0
  };
};
