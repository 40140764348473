export const GTM_IDS = {
  report: {
    csv_download: 'report_csv_download_btn'
  },
  tiktokSpecial: {
    report_csv_download: 'tiktok_speial_report_csv_download'
  },
  marketplace: {
    posts_csv_download: 'marketplace_posts_csv_download',
    report_csv_download: 'marketplace_report_csv_download',
    report_google_slide_download: 'marketplace_report_google_slide_download',
    report_pptx_download: 'marketplace_report_pptx_download'
  },
  engagement: {
    posts_csv_download: 'engagement_posts_csv_download_btn',
    report_csv_download: 'engagement_report_csv_download',
    report_google_slide_download: 'engagement_report_google_slide_download',
    report_pptx_download: 'engagement_report_pptx_download',
    proposal_csv_download: 'engagement_proposal_csv_download',
    proposal_pptx_download: 'engagement_proposal_pptx_download',
    proposal_spreadsheet_download: 'engagement_proposal_spreadsheet_download',
    proposal_excel_download: 'engagement_proposal_excel_download',
    proposal_google_slide_download: 'engagement_proposal_google_slide_download'
  },
  influencer: {
    profile_google_slide_download: 'profile_google_slide_download',
    profile_pptx_download: 'profile_pptx_download',
    profile_csv_download: 'profile_csv_download'
  },
  package: {
    content_csv_download: 'content_csv_download',
    content_spreadsheet_download: 'content_spreadsheet_download'
  }
};
