import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { AutoManagedCampaignsList, AutoManagedCampaignsFilter } from '@/shared/organisms';
import { CustomShopeeCampaignsTrigger, Header, ListWithPagination } from '@/shared/molecules';
import { useAutoManagedCampaignsData } from './hooks';

export const AutoManagedCampaigns = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    title: 'documentTitle.Auto Managed Campaign',
    appHeader: 'appHeader.Auto Managed Campaign'
  });
  const { loading, campaigns, totalRecords } = useAutoManagedCampaignsData();

  return (
    <>
      <Header>
        <CustomShopeeCampaignsTrigger css={{ alignSelf: 'center', marginLeft: 'auto' }} />
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <AutoManagedCampaignsFilter />
        <AutoManagedCampaignsList data={campaigns} loading={loading} totalRecords={totalRecords} />
      </ListWithPagination>
    </>
  );
};
