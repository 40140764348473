import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SwitchRoleToPartner.graphql';
import { Mutation, MutationswitchRoleToPartnerArgs } from '../../__generated__/globalTypes';

export const useSwitchRoleToPartnerMutation = (
  options?: MutationHookOptions<Mutation, MutationswitchRoleToPartnerArgs>
) => {
  const [callSwitchRoleToPartner, result] = useMutation<Mutation, MutationswitchRoleToPartnerArgs>(MUTATION, options);

  return { callSwitchRoleToPartner, ...result };
};

export type SwitchRoleToPartnerMutationFunction = Mutation['switchRoleToPartner'];
