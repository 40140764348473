import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { CampaignTrackingOption, CampaignSocialMediaType } from '@/graphql';
import { useMarketplacePromotionMethodOptions, usePostPricesAvailability } from '../../hooks';
import { AnyCreatorIframePreview } from '../AnyCreatorIframePreview';
import {
  TitleField,
  RegionField,
  BudgetField,
  CountryField,
  RevenueField,
  SalePICField,
  HashtagField,
  HashtagsNotice,
  AdvertiserField,
  MarginRateField,
  HubspotDealField,
  SocialMediaField,
  CostPerLikeField,
  CostPerPostField,
  CostPerViewField,
  CostPerShareField,
  CostPerClickField,
  ApprovePostsField,
  AgeRangeInputField,
  FormLayoutTemplate,
  PreLaunchDateField,
  CostPerActionField,
  MaterialUploadField,
  LandingPageURLField,
  CostPerSaleTuneField,
  PostRequirementField,
  ThumbnailUploadField,
  GenderCheckBoxesField,
  AgencyMarginRateField,
  CampaignCategoryField,
  AllowNewInfluencerField,
  ServiceInformationField,
  InfluencerCategoryField,
  FollowerRangeInputField,
  ApproveInfluencersField,
  TrackingCreatorPostField,
  DraftPostSubmissionField,
  CampaignMaterialLinkField,
  AdditionalRequirementField,
  InfluencerManagementPICField,
  InfluencerCommissionRateField,
  CampaignPeriodWithLogicsField
} from '../shared';
import { DefaultFormTemplateProps, MarketplaceFormKeys, MarketplaceFormValues } from '../types';
import { marketplaceSectionTitles } from '../utils';
import { AiRainbowSpinner, DescriptionGenerator, useDescriptionGeneratorState } from '../../DescriptionGenerator';

export const Template = ({ defaultValues, disabledFields, onSubmit }: DefaultFormTemplateProps) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<MarketplaceFormValues>();
  const { isAdminStaff, isAdvertiser, isAdminStaffAgency } = useUserRoles();
  const { promotionMethodOptions } = useMarketplacePromotionMethodOptions();
  const descriptionActions = useDescriptionGeneratorState();

  const { socialMedia, trackingOptions } = watch();
  const defaultStep = isAdvertiser ? -1 : 0;
  const hasTrackingPost = trackingOptions.includes(CampaignTrackingOption.POST);
  const allPostPrices = usePostPricesAvailability(socialMedia);

  const handleTrackingPostChange = (active?: boolean) => {
    if (!active) {
      setValue(MarketplaceFormKeys.hashtags, []);
      setValue(MarketplaceFormKeys.requireDraftPost, false);
    }
    if (active && socialMedia === CampaignSocialMediaType.UNSELECT) {
      setValue(MarketplaceFormKeys.socialMedia, '');
    }
  };

  return (
    <FormLayoutTemplate defaultValues={defaultValues} onSubmit={onSubmit}>
      {!isAdvertiser ? (
        <>
          <FormStyle.SectionTitle
            index={defaultStep + 1}
            css={{ marginBottom: '24px' }}
            label={t(marketplaceSectionTitles.picSettings)}
          />
          <FormStyle.FieldsGroup itemsPerRow={2}>
            {isAdminStaffAgency ? <AdvertiserField disabled={disabledFields.advertiser} required /> : null}
            {isAdminStaff ? (
              <>
                <HubspotDealField disabled={disabledFields.hubspotDealIds} />
                <SalePICField disabled={disabledFields.salesPicIds} required />
                <InfluencerManagementPICField disabled={disabledFields.influencerManagementPicIds} required />
              </>
            ) : null}
          </FormStyle.FieldsGroup>
          <FormStyle.HorizontalLine css={{ margin: '24px auto' }} />
        </>
      ) : null}

      <FormStyle.SectionTitle index={defaultStep + 2} label={t(marketplaceSectionTitles.campaignInformation)} />

      <TrackingCreatorPostField disabled={disabledFields.trackingOptions} onChange={handleTrackingPostChange} />

      <FormStyle.FieldsGroup itemsPerRow={2}>
        <PreLaunchDateField disabled={disabledFields.preLaunchDate} />
        <CampaignPeriodWithLogicsField disabled={disabledFields.period} required />
      </FormStyle.FieldsGroup>

      <FormStyle.FieldsGroup itemsPerRow={3}>
        <CountryField disabled={disabledFields.countryId} required />
        <RegionField disabled={disabledFields.regionIds} />
        <CampaignCategoryField disabled={disabledFields.campaignCategoryId} required />
        <SocialMediaField
          required={hasTrackingPost}
          title={t('Selector.Social Media / Promotion method')}
          disabled={disabledFields.socialMedia}
          {...(!hasTrackingPost ? { options: promotionMethodOptions } : {})}
        />
        <InfluencerCategoryField disabled={disabledFields.influencerCategoryIds} required />
      </FormStyle.FieldsGroup>

      <FormStyle.FieldsGroup itemsPerRow={3}>
        <GenderCheckBoxesField disabled={disabledFields.genders} required />
      </FormStyle.FieldsGroup>

      <FormStyle.FieldsGroup itemsPerRow={3}>
        <AgeRangeInputField
          minField={{ disabled: disabledFields.minAge }}
          maxField={{ disabled: disabledFields.maxAge }}
        />
        <FollowerRangeInputField
          minField={{ disabled: disabledFields.minFollowers }}
          maxField={{ disabled: disabledFields.maxFollowers }}
          socialMedia={socialMedia}
        />
      </FormStyle.FieldsGroup>

      <FormStyle.FieldWrapper>
        <div css={{ padding: '0 16px', border: `1px solid ${THEME.border.colors.gray.lv1}` }}>
          <ApproveInfluencersField disabled={disabledFields.isAutoInfluencerApproval} />
          <ApprovePostsField disabled={disabledFields.isAllowMultiplePosts} />
          <AllowNewInfluencerField disabled={disabledFields.allowNewInfluencer} />
        </div>
      </FormStyle.FieldWrapper>

      <FormStyle.HorizontalLine css={{ margin: '24px auto' }} />
      <FormStyle.SectionTitle
        index={defaultStep + 3}
        css={{ marginBottom: '4px' }}
        label={t(marketplaceSectionTitles.trackingSettings)}
      />

      {hasTrackingPost ? (
        <>
          <HashtagsNotice css={{ marginTop: '24px' }} />
          <HashtagField disabled={disabledFields.hashtags} />
        </>
      ) : null}
      <LandingPageURLField disabled={disabledFields.landingPageUrl} required />

      <FormStyle.HorizontalLine css={{ margin: '24px auto' }} />
      <DescriptionGenerator {...descriptionActions}>
        <FormStyle.SectionTitle
          index={defaultStep + 4}
          css={{ marginBottom: '4px' }}
          label={t(marketplaceSectionTitles.campaignDetails)}
        />
      </DescriptionGenerator>

      <TitleField
        disabled={disabledFields.title || descriptionActions.loading}
        required
        labelLoader={descriptionActions.loading ? <AiRainbowSpinner css={{ marginLeft: '4px' }} /> : undefined}
      />
      <ServiceInformationField
        disabled={disabledFields.serviceInformation || descriptionActions.loading}
        required
        labelLoader={descriptionActions.loading ? <AiRainbowSpinner css={{ marginLeft: '4px' }} /> : undefined}
        getSunEditorInstance={descriptionActions.getSunEditorInstance}
      />
      <PostRequirementField
        disabled={disabledFields.requirement || descriptionActions.loading}
        required
        labelLoader={descriptionActions.loading ? <AiRainbowSpinner css={{ marginLeft: '4px' }} /> : undefined}
        getSunEditorInstance={descriptionActions.getSunEditorInstance}
      />
      {hasTrackingPost ? <DraftPostSubmissionField disabled={disabledFields.requireDraftPost} /> : null}
      <ThumbnailUploadField disabled={disabledFields.thumbnails} required />
      <MaterialUploadField disabled={disabledFields.materials} />
      <AdditionalRequirementField disabled={disabledFields.additionalRequirement} />
      <CampaignMaterialLinkField disabled={disabledFields.campaignMaterialLink} />

      <FormStyle.HorizontalLine css={{ margin: '12px auto 24px' }} />
      <FormStyle.SectionTitle index={defaultStep + 5} label={t(marketplaceSectionTitles.paymentInformation)} />

      <FormStyle.FieldsGroup itemsPerRow={4}>
        {hasTrackingPost ? (
          <>
            {allPostPrices.costPerPost ? <CostPerPostField disabled={disabledFields.costPerPost} /> : null}
            {allPostPrices.costPerLike ? <CostPerLikeField disabled={disabledFields.costPerLike} /> : null}
            {allPostPrices.costPerView ? <CostPerViewField disabled={disabledFields.costPerView} /> : null}
            {allPostPrices.costPerShare ? <CostPerShareField disabled={disabledFields.costPerShare} /> : null}
          </>
        ) : null}
        {allPostPrices.costPerClick ? <CostPerClickField disabled={disabledFields.costPerClick} /> : null}
        {allPostPrices.costPerAction ? <CostPerActionField disabled={disabledFields.costPerAction} /> : null}
        {allPostPrices.costPerSaleTune ? <CostPerSaleTuneField disabled={disabledFields.costPerSaleTune} /> : null}
      </FormStyle.FieldsGroup>

      <FormStyle.HorizontalLine css={{ margin: '24px auto' }} />
      <FormStyle.SectionTitle index={defaultStep + 6} label={t(marketplaceSectionTitles.budgetSettings)} />

      <FormStyle.FieldsGroup itemsPerRow={4}>
        <RevenueField disabled={disabledFields.maximumRevenuePerInfluencer} />
        {isAdminStaffAgency ? <AgencyMarginRateField disabled={disabledFields.agencyMarginRate} /> : null}
      </FormStyle.FieldsGroup>

      <FormStyle.FieldsGroup itemsPerRow={4}>
        {isAdminStaff ? <MarginRateField disabled={disabledFields.marginRate} required /> : null}
        {!isAdvertiser ? <InfluencerCommissionRateField /> : null}
      </FormStyle.FieldsGroup>

      <FormStyle.FieldsGroup itemsPerRow={4}>
        <BudgetField disabled={disabledFields.budget} required />
      </FormStyle.FieldsGroup>

      <AnyCreatorIframePreview />
    </FormLayoutTemplate>
  );
};
