import React, { createContext, useContext } from 'react';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { PostListSortFieldV3, SocialPostType } from '@/graphql';
import { InfluencersContextReturnType } from '../types';
import { useInfluencerPostsList } from './hooks';
import {
  InfluencerPostsProps,
  InfluencerPostProfile,
  InfluencerPostsFilterFormValues,
  InfluencersPostsSearchSchemaType
} from './schemaTypes';

type ContextType = InfluencersContextReturnType<
  InfluencerPostProfile,
  InfluencerPostsFilterFormValues,
  PostListSortFieldV3
>;

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  value: InfluencerPostsProps;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const InfluencerPostsProvider = ({
  children,
  value: { isTtcmCampaign, isEngagementPage, isPackagePage, getSortSelectButtonProps, getListSelectButtonProps }
}: Props) => {
  const paramsSearch = useParamsSearch<InfluencersPostsSearchSchemaType>();
  const { page, sort, limit, filter } = paramsSearch;
  const listProps = useInfluencerPostsList({ page, sort, limit, filter });

  return (
    <Context.Provider
      value={{
        ...paramsSearch,
        ...listProps,
        isTtcmCampaign,
        isEngagementPage,
        getListSelectButtonProps,
        sortSelectButtonProps: getSortSelectButtonProps?.(listProps.listRecords, filter.socialMedia as SocialPostType)
      }}
    >
      <ListWithPagination pagination={{ totalRecords: listProps.totalRecords }}>{children}</ListWithPagination>
    </Context.Provider>
  );
};

export const useInfluencerPostsContext = () => useContext<ContextType>(Context);
