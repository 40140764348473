// TODO. Remake component using react-select
import { SerializedStyles, css } from '@emotion/react';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';
import { THEME } from '@/shared/constants';
import { useDeepCompareEffect } from '@/shared/hooks';
import { Option } from '@/shared/types';
import { DisabledProps } from '../types';
import { InputBox } from './InputBox';
import { useClickOutside } from './useClickOutside';
import { useMenuOpen } from './useMenuOpen';
import { useSearch } from './useSearch';

enum KeyName {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ENTER = 'Enter'
}
interface ItemsType<V = Option> {
  selectedValue: readonly V[];
  setSelectedValue: (value: readonly V[]) => void;
  removeValue: (item: V) => void;
}

interface RenderSelectedValueProps<V = Option> {
  selectedValue: readonly V[];
}

type SearchType<T extends Option = Option> = ReturnType<typeof useSearch<T>>;
export type MenuType = ReturnType<typeof useMenuOpen>;
interface RenderMenuProps<V extends Option = Option> {
  items: ItemsType<V>;
  search: SearchType<V>;
  menu: MenuType;
  cursor: Option | null;
  setCursor: (option: Option | null) => void;
}
export interface BaseSelectorProps<V extends Option = Option> {
  name: string;
  hasError?: boolean;
  options: readonly V[];
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  hideInput?: boolean;
  items: ItemsType<V>;
  onClose?: () => void;
  isAlwaysOpen?: boolean;
  skipFiltering?: boolean;
  disabledInputBox?: boolean;
  menuCss?: SerializedStyles;
  autoSelectMatched?: boolean;
  placeholder?: string | null;
  dropdownCss?: SerializedStyles;
  onClickInputBox?: (menu: MenuType) => void;
  onSearchChange?: (inputText: string) => void;
  setSelectedValue?: (item: readonly V[]) => void;
  MenuControlIcon?: React.ComponentType<DisabledProps>;
  renderMenu: (props: RenderMenuProps<V>) => React.ReactNode;
  renderSelectedValue: (props: RenderSelectedValueProps<V>) => React.ReactNode;
}

export const BaseSelector = <V extends Option = Option>(props: BaseSelectorProps<V>) => {
  const {
    items,
    hasError,
    menuCss,
    loading,
    options,
    onClose,
    disabled,
    className,
    hideInput,
    renderMenu,
    placeholder,
    dropdownCss,
    skipFiltering,
    onSearchChange,
    onClickInputBox,
    MenuControlIcon,
    disabledInputBox,
    setSelectedValue,
    autoSelectMatched,
    renderSelectedValue,
    isAlwaysOpen = false
  } = props;

  const initialCursor = items.selectedValue || null;

  const [cursor, setCursor] = useState<any | null>(initialCursor);

  // Search items via InputText
  const search = useSearch<V>(options, skipFiltering);

  // Menu StateHandler -> for cases where menu should be always open we are setting default menu state to open (it will help us to close it properly on click outside)
  const menu = useMenuOpen(isAlwaysOpen);

  // Close menu if click outside of selector
  const { ref: selectorRef, clickedOutside } = useClickOutside();
  useEffect(() => {
    if (clickedOutside && !isAlwaysOpen && menu.isOpen) {
      menu.setIsOpen(false);
      onClose?.();
    }
  }, [menu.isOpen, clickedOutside, isAlwaysOpen]);

  useDeepCompareEffect(() => {
    setCursor(items.selectedValue);
  }, [items.selectedValue]);

  // Ref for input area
  const inputRef = useRef<HTMLInputElement>(null);

  const isFirstRun = useRef(true);
  useEffect(() => {
    // Skip first run
    if (isFirstRun.current) {
      isFirstRun.current = false;

      return;
    }
    if (!menu.isOpen) {
      search.setSearchText('');
    }
  }, [menu.isOpen]);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    search.setSearchText(e.target.value);
    onSearchChange?.(e.target.value);
  };

  const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Backspace' && !search.searchText) {
      if (items.selectedValue instanceof Array) {
        items.removeValue(items.selectedValue[items.selectedValue.length - 1] as V);
      } else {
        items.removeValue(items.selectedValue as V);
      }
    }

    const { filteredValue } = search;

    if (key === KeyName.ARROW_UP) {
      setCursor((prevState: Option | null) => {
        const index = filteredValue.findIndex((o) => prevState && o.value === prevState.value);

        return index === -1 ? null : index - 1 > -1 ? filteredValue[index - 1] : null;
      });
    }

    if (key === KeyName.ARROW_DOWN) {
      setCursor((prevState: Option | null) => {
        const index = filteredValue.findIndex((o) => prevState && o.value === prevState.value);

        return index === -1
          ? filteredValue[0]
          : index + 1 < filteredValue.length
            ? filteredValue[index + 1]
            : filteredValue[filteredValue.length - 1];
      });
    }

    if (key === KeyName.ENTER && setSelectedValue && cursor?.value) {
      let matched: Option | undefined = undefined;
      if (autoSelectMatched) {
        matched = options.find((option) => option.label === search.searchText);
        if (matched) {
          setCursor(matched);
        }
      }
      setSelectedValue(autoSelectMatched && !isEmpty(matched) ? matched : cursor);
      if (!isAlwaysOpen) {
        menu.setIsOpen(false);
        onClose?.();
      }
      // lost focus from input which trigger onBlur and form submit
      // eslint-disable-next-line no-unused-expressions
      inputRef?.current?.blur();
    }
  };

  const handleClickInput = (e: React.MouseEvent<HTMLInputElement>) => {
    e.persist();
    e.stopPropagation();
    menu.setIsOpen(true);
  };

  const handleClickInputBox = () => {
    if (disabledInputBox) {
      return;
    }
    if (onClickInputBox) {
      onClickInputBox(menu);
      // if selector is closed we need to open it on click action
    } else if (!menu.isOpen) {
      menu.setIsOpen(true);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div ref={selectorRef} css={[styles.wrapper(disabled), dropdownCss]}>
      <InputBox
        hasError={hasError}
        loading={loading}
        disabled={disabled}
        inputRef={inputRef}
        className={className}
        hideInput={hideInput}
        isMenuOpen={menu.isOpen}
        searchText={search.searchText}
        placeholder={placeholder}
        handleChange={handleSearchTextChange}
        handleKeyDown={handleKeyDown}
        MenuControlIcon={MenuControlIcon}
        handleClickInput={handleClickInput}
        handleClickInputBox={handleClickInputBox}
        renderSelectedValue={() => renderSelectedValue({ selectedValue: items.selectedValue })}
      />
      {menu.isOpen && (
        <div css={[styles.menuWrapper, dropdownCss, menuCss]}>
          {renderMenu({ items, search, menu, cursor, setCursor })}
        </div>
      )}
    </div>
  );
};
const styles = {
  wrapper: (disabled?: boolean) =>
    css({ width: '100%', position: 'relative', pointerEvents: disabled ? 'none' : 'auto' }),
  menuWrapper: css({
    zIndex: 10,
    width: 'inherit',
    marginTop: '4px',
    borderRadius: '3px',
    position: 'absolute',
    boxSizing: 'border-box',
    transition: 'opacity 0.1s ease',
    border: THEME.border.base,
    boxShadow: THEME.shadows.boxShadow,
    backgroundColor: THEME.background.colors.white
  })
};
