import { useEffect, useState } from 'react';
import { useQueryHelper } from '@/shared/hooks';
import { FormAction } from '@/shared/types';
import { manageLocalStorage } from '@/shared/utils';
import { AnalyticsFormValues } from '../schemaTypes';
import { defaultAnalyticsFormValues, getAnalyticsAccountFormState } from '../utils';

interface Props {
  defaultValues?: FormAction<AnalyticsFormValues>['defaultValues'];
}

export const useInitAnalyticsFormValues = ({ defaultValues }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [values, setValues] = useState<AnalyticsFormValues | undefined>(undefined);

  const isAddMode = !defaultValues?.id;

  useEffect(() => {
    initAnalyticsFromValues();
  }, []);

  useEffect(() => {
    if (values) {
      manageLocalStorage('remove', 'analyticsAccountFormState_json');
    }
  }, [values]);

  const initAnalyticsFromValues = () => {
    // in add mode we can set state from router state and localstorage first,
    // in edit more there is an API call to get connected accounts and setting state here cause race condition
    if (isAddMode) {
      const stringifiedData = manageLocalStorage('get', 'analyticsAccountFormState_json');
      if (stringifiedData) {
        const { formState } = getAnalyticsAccountFormState('parse', { stringifiedData }, () => {
          enqueueSnackbar(t(`Sorry we can't access your previously saved form values, please fill them again`), {
            variant: 'error'
          });
        });

        setValues(formState);
      } else if (!values) {
        setValues({ ...defaultAnalyticsFormValues, ...defaultValues });
      }
    } else {
      setValues({ ...defaultAnalyticsFormValues, ...defaultValues });
    }
  };

  return { values };
};
