import { useState } from 'react';
import { UploadButton, DownloadButton } from '@/shared/atoms';
import { ListWithPagination, UploadCsvModal } from '@/shared/molecules';
import { useDownloadFile, useToggleState, useParamsSearch } from '@/shared/hooks';
import { InstagramStoryPost, useAllInstagramStoryPostsQuery } from '@/graphql';
import { getOffset, getUploadApiUrl, getValuableVariables, convertOrderToORDER } from '@/shared/utils';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  AnalyticsInstagramStoryPostsList,
  defaultSelectedAnalyticsInstagramStoryPostsColumns,
  selectableAnalyticsInstagramStoryPostsColumnOptions
} from '@/shared/organisms';
import { AnalyticsIgStoryPostsListSearchSchemaType, useInitialAnalyticsPosts } from '../hooks';

export const AnalyticsInstagramStoryPosts = () => {
  const uploadModal = useToggleState();

  const { downloading, handleDownloadFile } = useDownloadFile();
  const [selectedColumns, setSelectedColumns] = useState<Array<keyof InstagramStoryPost>>(
    defaultSelectedAnalyticsInstagramStoryPostsColumns
  );
  const { brandAccountId, mainSocialAccountId } = useInitialAnalyticsPosts({
    socialMedia: 'instagram',
    document: (name) => ({
      appHeader: name ? 'appHeader.StoryPostListWithAccountName' : 'appHeader.Story Post List',
      title: name ? 'documentTitle.StoryPostListWithAccountName' : 'documentTitle.Story Post List'
    })
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<AnalyticsIgStoryPostsListSearchSchemaType>();
  const { data, previousData, loading, refetch } = useAllInstagramStoryPostsQuery({
    skip: !brandAccountId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      accountId: Number(mainSocialAccountId),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      ...filter
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allInstagramStoryPostsCount?.totalNumber;
  const selectableColumns = selectableAnalyticsInstagramStoryPostsColumnOptions.map((item) => item.value);

  return (
    <>
      <ListWithPagination pagination={{ totalRecords }}>
        <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

        <ProfilePostsCount count={totalRecords}>
          <UploadButton onClick={uploadModal.open} css={{ marginRight: '8px' }} />
          <DownloadButton
            loading={downloading}
            onClick={() =>
              handleDownloadFile({
                params: getValuableVariables({
                  endDate: filter.endDate,
                  startDate: filter.startDate,
                  selectedColumns: selectedColumns.filter((column) => selectableColumns.includes(column))
                }),
                filenamePrefix: 'storyPosts',
                url: getUploadApiUrl(`analytics/story_posts/${mainSocialAccountId}/csv/custom`)
              })
            }
          />
        </ProfilePostsCount>

        <AnalyticsInstagramStoryPostsList
          loading={loading}
          mainSocialAccountId={mainSocialAccountId}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          data={result?.allInstagramStoryPosts || []}
        />
      </ListWithPagination>

      <UploadCsvModal
        onFileUploaded={refetch}
        open={uploadModal.status}
        onClose={uploadModal.close}
        uploadFile={{
          fileNamePrefix: 'story_posts.csv',
          url: getUploadApiUrl(`analytics/story_posts/${mainSocialAccountId}/csv`)
        }}
        downloadFile={{
          extension: '.csv',
          filenamePrefix: 'story_posts',
          params: { startDate: filter.startDate, endDate: filter.endDate },
          url: getUploadApiUrl(`analytics/story_posts/${mainSocialAccountId}/csv`)
        }}
      />
    </>
  );
};
