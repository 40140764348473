import { useTranslation } from 'react-i18next';
import { Notice } from '@/shared/atoms';
import { InfluencerWhitelist } from '@/graphql';
import { FilterWrapper, ListWithPagination } from '@/shared/molecules';
import {
  MarketplaceInfluencersSort,
  MarketplaceInfluencersList,
  MarketplaceInfluencersFilter
} from '@/shared/organisms';
import { useMarketplaceInfluencersList } from '../hooks';
import { useMarketplaceDetailsContext } from '../../MarketplaceDetailsContext';
import { useToggleSelectedInfluencers } from './hooks';

export interface MarketplaceInfluencersForOtherUsersProps {
  influencerWhitelist: InfluencerWhitelist;
}

export const MarketplaceInfluencersForOtherUsers = ({
  influencerWhitelist
}: MarketplaceInfluencersForOtherUsersProps) => {
  const { t } = useTranslation();
  const { marketplace } = useMarketplaceDetailsContext();
  const { fetchingInfluencersList, listInfluencers, totalRecords } = useMarketplaceInfluencersList();
  const { AddAllInfluencersModal, RemoveAllInfluencersModal, sortSelectButtonProps, generateListSelectButtonProps } =
    useToggleSelectedInfluencers({ totalRecords, listInfluencers, influencerWhitelist });

  return (
    <>
      <ListWithPagination pagination={{ totalRecords }}>
        <Notice type="info" title={t('Information')} description={t('Influencer.SelectInfluencerHelp')} />

        <FilterWrapper>
          <MarketplaceInfluencersFilter marketplace={marketplace} />
          <MarketplaceInfluencersSort selectButtonProps={sortSelectButtonProps} />
        </FilterWrapper>

        <MarketplaceInfluencersList
          data={listInfluencers}
          totalRecords={totalRecords}
          loading={fetchingInfluencersList}
          generateSelectButtonProps={generateListSelectButtonProps}
        />
      </ListWithPagination>

      {AddAllInfluencersModal}
      {RemoveAllInfluencersModal}
    </>
  );
};
