import { useTranslation } from 'react-i18next';
import { Tabs } from '@/shared/atoms';
import { useChatDemo } from '@/shared/hooks';
import { THEME } from '@/shared/constants';

export const InfluencersTabs = () => {
  const { t } = useTranslation();
  const { isChatDemoAccount } = useChatDemo();

  return (
    <Tabs
      options={[
        {
          end: false,
          label: t('General.Select Influencers'),
          to: '/influencers/search'
        },
        {
          end: false,
          disabled: isChatDemoAccount,
          label: t('Propose Influencers'),
          to: '/influencers/proposal'
        }
      ]}
      css={{
        padding: '0 10px',
        boxShadow: THEME.shadows.boxShadow,
        '.tab-title': { padding: '0 8px' }
      }}
    />
  );
};
