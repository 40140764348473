import { css } from '@emotion/react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Modal,
  ModalTitle,
  NoDataTitle,
  ModalContent,
  ModalFooterActions,
  NoDataContentWrapper,
  ChartTooltipSingleMetric,
  NoDataContentWrapperProps
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useToggle } from '@/shared/hooks';
import { formatPercent } from '@/shared/utils';
import { AccountAudienceDataRate } from '@/graphql';
import { HighChart } from '../HighChart';
import { InfluencerAvatar } from '../InfluencerAvatar';

export interface BarChartWithMoreModalProps
  extends Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'>,
    Pick<Highcharts.ChartOptions, 'width'> {
  avatar?: string;
  hasRelativeMaxY?: boolean;
  dataset: readonly AccountAudienceDataRate[];
  title?: string;
  modalTitle?: string;
}

export const BarChartWithMoreModal = ({
  width,
  header,
  avatar,
  dataset,
  title = 'Area',
  hasRelativeMaxY,
  modalTitle = 'Audience Area',
  ...noDataContentProps
}: BarChartWithMoreModalProps) => {
  const { t } = useTranslation();
  const modalState = useToggle();

  const dataNotAvailable = dataset.every(({ rate }) => rate === 0);
  const { categories, seriesData } = dataset.reduce<{ categories: string[]; seriesData: number[] }>(
    (acc, { name, rate }) => ({
      categories: [...acc.categories, name],
      seriesData: [...acc.seriesData, rate]
    }),
    { categories: [], seriesData: [] }
  );

  const chartOptions = (showAll?: boolean): Highcharts.Options => ({
    title: { text: '' },
    credits: { enabled: false },
    plotOptions: { bar: { showInLegend: false, dataLabels: { enabled: false } } },
    chart: { type: 'bar', width: showAll ? 440 : width, height: showAll ? null : 300, spacingLeft: 0 },
    xAxis: {
      title: { text: null },
      categories: showAll ? categories : categories.slice(0, 7),
      labels: {
        align: 'left',
        x: -5,
        reserveSpace: true
      }
    },
    series: [{ type: 'bar', color: '#9ea0e0', data: showAll ? seriesData : seriesData.slice(0, 7) }],
    yAxis: {
      min: 0,
      max: hasRelativeMaxY ? null : 1,
      title: { text: '' },
      labels: {
        overflow: 'justify',
        formatter() {
          return formatPercent(Number(this.value), true, 0);
        }
      }
    },
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      borderColor: THEME.chart.tooltip.border.colors.green,
      backgroundColor: THEME.chart.tooltip.background.colors.black,
      style: { color: THEME.chart.tooltip.text.colors.white },
      formatter() {
        return renderToStaticMarkup(
          <ChartTooltipSingleMetric
            name={t(String(this.category))}
            value={formatPercent(this.y)}
            color={this.color as string}
          />
        );
      }
    }
  });

  return (
    <>
      <NoDataContentWrapper
        css={{ padding: '16px' }}
        dataNotAvailable={dataNotAvailable}
        header={header ?? <NoDataTitle css={{ marginBottom: '24px' }} title={t(title)} />}
        {...noDataContentProps}
      >
        <HighChart options={chartOptions()} />

        <div css={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <div css={{ cursor: 'pointer' }} onClick={modalState.toggle}>
            <span css={{ fontSize: '12px', fontWeight: 600, color: THEME.text.colors.gray.lv3 }}>{t('MORE')}</span>
            <Icon icon="arrow-right" color={THEME.icon.colors.blue} size="11px" css={{ marginLeft: '4px' }} />
          </div>
        </div>
      </NoDataContentWrapper>

      <Modal open={modalState.on} onClose={modalState.setOff} css={{ width: '488px' }}>
        <ModalContent>
          <ModalTitle css={styles.modalTitle}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <InfluencerAvatar src={avatar} size="24px" css={{ borderRadius: '50%' }} />
              <p css={{ marginLeft: '8px' }}>{t(modalTitle)}</p>
            </div>
          </ModalTitle>

          <HighChart options={chartOptions(true)} />
        </ModalContent>

        <ModalFooterActions cancelButtonProps={{ onClick: modalState.setOff }} />
      </Modal>
    </>
  );
};

const styles = {
  modalTitle: css({
    display: 'flex',
    marginBottom: '24px',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  labelBlock: css({
    width: '15px',
    height: '15px',
    marginRight: '8px',
    borderRadius: '2px',
    position: 'relative',
    backgroundColor: '#9ea0e0'
  }),
  regionsCountriesTab: (disabled?: boolean, active?: boolean) =>
    css({
      fontSize: '13px',
      position: 'relative',
      '&:hover': { opacity: 0.8, cursor: 'pointer' },
      '&:not(:first-of-type)': {
        marginLeft: '16px',
        '&::before': {
          top: 0,
          left: '-8px',
          width: '1px',
          content: '""',
          height: '18px',
          position: 'absolute',
          backgroundColor: '#dee5ec'
        }
      },
      ...(active ? { color: THEME.text.colors.blue } : {}),
      ...(disabled ? { pointerEvents: 'none', opacity: 0.5 } : {})
    })
};
