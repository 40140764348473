import { useTranslation } from 'react-i18next';
import { TextSearchField, BasicFilterWrapper, RangeDatePickerField } from '@/shared/molecules';
import { SelectedRangeOption } from '@/shared/types';
import { ProfilePostsFilterFormKeys } from './types';

export interface TemplateProps {
  hasPeriod?: boolean;
  hasKeyword?: boolean;
  onSubmit?: () => void;
  keywordPlaceholder?: string;
}

export const Template = ({ onSubmit, hasPeriod, hasKeyword = true, keywordPlaceholder }: TemplateProps) => {
  const { t } = useTranslation();
  const rangeList = [
    SelectedRangeOption.TODAY,
    SelectedRangeOption.LAST_SEVEN_DAYS,
    SelectedRangeOption.TODAY_TO_MONTH_AGO,
    SelectedRangeOption.THIS_MONTH,
    SelectedRangeOption.LAST_MONTH,
    SelectedRangeOption.CUSTOM
  ];

  return (
    <BasicFilterWrapper css={{ padding: '12px 16px' }}>
      {hasKeyword ? (
        <TextSearchField<ProfilePostsFilterFormKeys>
          name="keyword"
          onSubmit={onSubmit}
          title={t('TextForm.Keyword')}
          placeholder={keywordPlaceholder || t<string>('Search.PostsInsightFilter')}
        />
      ) : null}

      {hasPeriod ? (
        <RangeDatePickerField<ProfilePostsFilterFormKeys>
          onSubmit={onSubmit}
          rangeList={rangeList}
          css={{ flex: 'none' }}
          title={t('Selector.Date Range')}
          disabledRange={{ after: new Date() }}
          name={{ startDate: 'startDate', endDate: 'endDate' }}
        />
      ) : null}
    </BasicFilterWrapper>
  );
};
