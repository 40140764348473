import { css } from '@emotion/react';
import { Trans } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { getRestApiUrl } from '@/shared/utils';
import { UploadCSVCouponModal } from '@/shared/molecules';
import { NOT_ENOUGH_REFERRAL_CODE } from '@/shared/constants';
import { useDownloadFile, useQueryHelper, useToggleState } from '@/shared/hooks';
import { Modal, Button, BlackBar, ModalTitle, ModalContent, ModalFooterActions, BlackBarProps } from '@/shared/atoms';
import {
  MarketplaceDetail,
  ApplicantInfluencer,
  MarketplaceCampaignStatus,
  MarketplaceCampaignAppliedStatus,
  useApproveApplicantInfluencersMutation,
  useUpdateMarketplaceCouponListMutation,
  useUnApproveApplicantInfluencersMutation,
  namedOperations
} from '@/graphql';

export interface ActionBarProps {
  onSelectAll?: () => void;
  marketplace: MarketplaceDetail;
  resetSelectedApplicants: () => void;
  selectedApplicants: ApplicantInfluencer[];
  selectButtonProps?: BlackBarProps['checkboxProps'];
}

export const ActionBar = ({
  onSelectAll,
  selectButtonProps,
  selectedApplicants,
  resetSelectedApplicants,
  marketplace: { id, status }
}: ActionBarProps) => {
  const rejectModal = useToggleState();
  const approveModal = useToggleState();
  const uploadCouponModal = useToggleState();

  const { isAdminStaff } = useUserRoles();
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { callApproveApplicantInfluencers, loading: approving } = useApproveApplicantInfluencersMutation({
    refetchQueries: [namedOperations.Query.AllApplicantInfluencersForMarketplace]
  });
  const { callUnApproveApplicantInfluencers, loading: unApproving } = useUnApproveApplicantInfluencersMutation({
    refetchQueries: [namedOperations.Query.AllApplicantInfluencersForMarketplace]
  });
  const { callUpdateMarketplaceCouponList, loading: uploading } = useUpdateMarketplaceCouponListMutation({
    onCompleted: invalidateRouteLoader
  });

  const loading = approving || unApproving;
  const isSingleApplicant = selectedApplicants.length === 1;
  const selectedApplicantIds = selectedApplicants.map(({ id }) => id);
  const hasAllApproved = selectedApplicants.every(
    ({ appliedStatus }) => appliedStatus === MarketplaceCampaignAppliedStatus.APPROVED
  );
  const hasAllRejected = selectedApplicants.every(
    ({ appliedStatus }) => appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED
  );

  const toggleInfluencers = async () => {
    try {
      const toggle = approveModal.status ? callApproveApplicantInfluencers : callUnApproveApplicantInfluencers;
      await toggle({ variables: { input: { marketplaceCampaignId: id, influencerIds: selectedApplicantIds } } });

      enqueueSnackbar(t('Applicants have been updated successfully'), { variant: 'success' });
      resetSelectedApplicants();
    } catch (error) {
      if (error.message === NOT_ENOUGH_REFERRAL_CODE) {
        uploadCouponModal.open();
      } else {
        enqueueSnackbar(t(error), { variant: 'error' });
      }
    } finally {
      if (approveModal.status) {
        approveModal.close();
      } else {
        rejectModal.close();
      }
    }
  };

  const handleUploadFile = async (couponUploadFileUrl: string) => {
    try {
      await callUpdateMarketplaceCouponList({ variables: { input: { id, couponUploadFileUrl } } });

      enqueueSnackbar(t('fileUploadSuccess'), { variant: 'success' });
      uploadCouponModal.close();
    } catch (error) {
      enqueueSnackbar(t(error.message || 'fileUploadFail'), { variant: 'error' });
    }
  };

  return (
    <>
      <BlackBar
        css={styles.blackBar}
        checkboxProps={selectButtonProps}
        selectAllProps={{ onClick: onSelectAll }}
        disabled={selectButtonProps?.disabled || loading}
      >
        {status !== MarketplaceCampaignStatus.FINISHED ? (
          <>
            <Button
              variant="red"
              disabled={hasAllRejected}
              onClick={rejectModal.open}
              title={t('Button.Reject Influencer')}
              css={{ padding: '0 16px', marginLeft: '8px' }}
            />
            <Button
              variant="blue"
              disabled={hasAllApproved}
              onClick={approveModal.open}
              title={t('Button.Approve Influencer')}
              css={{ padding: '0 16px', marginLeft: '8px' }}
            />
          </>
        ) : null}

        {isAdminStaff ? (
          <Button
            variant="white"
            loading={downloading}
            title={t('Button.Download')}
            onClick={() =>
              handleDownloadFile({
                filenamePrefix: 'list-applicants',
                url: getRestApiUrl(`marketplace/${id}/applicant/csv`),
                params: { influencerIds: selectedApplicantIds }
              })
            }
            css={{ padding: '0 16px', marginLeft: 'auto' }}
            prefixIcon={{ icon: 'download', size: '14px' }}
          />
        ) : null}
      </BlackBar>

      <Modal open={rejectModal.status} onClose={rejectModal.close} css={{ maxWidth: '528px' }}>
        <ModalContent>
          <ModalTitle>{t(isSingleApplicant ? 'Reject Influencer?' : 'Reject Influencers?')}</ModalTitle>
          <p css={{ marginTop: '16px', fontSize: '14px' }}>
            {t('Approved influencers you selected will get an email and app notification if you click [Reject] below')}
          </p>
        </ModalContent>
        <ModalFooterActions
          cancelButtonProps={{ onClick: rejectModal.close }}
          submitButtonProps={{
            variant: 'red',
            disabled: loading,
            loading: unApproving,
            title: t('Button.Reject'),
            onClick: toggleInfluencers
          }}
        />
      </Modal>

      <Modal open={approveModal.status} onClose={approveModal.close} css={{ maxWidth: '528px' }}>
        <ModalContent>
          <ModalTitle>
            {t(
              hasAllRejected && isSingleApplicant
                ? 'Approve Influencer BACK?'
                : isSingleApplicant
                  ? 'Approve Influencer?'
                  : 'Approve Influencers?'
            )}
          </ModalTitle>
          <p css={{ marginTop: '16px', fontSize: '14px' }}>
            {hasAllRejected && isSingleApplicant ? (
              <>
                ⚠{t('You have REJECTED this influencer before')}
                <br />
                {t('Influencer will not receive any notification when you approve again, please notify her/him')}
              </>
            ) : (
              t('Approved influencers you selected will get an email and app notification if you click [Approve] below')
            )}
          </p>
        </ModalContent>
        <ModalFooterActions
          cancelButtonProps={{ onClick: approveModal.close }}
          submitButtonProps={{
            disabled: loading,
            loading: approving,
            onClick: toggleInfluencers,
            title: t('Button.Approve')
          }}
        />
      </Modal>

      <UploadCSVCouponModal
        loading={uploading}
        title="Upload referral codes"
        onUploadFile={handleUploadFile}
        isOpen={uploadCouponModal.status}
        onClose={uploadCouponModal.close}
        description={
          <div css={{ marginBottom: '16px' }}>
            <Trans i18nKey="uploadCouponModalDescription" components={{ br: <br /> }} />
          </div>
        }
      />
    </>
  );
};

const styles = {
  blackBar: css({
    padding: '0 8px',
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit'
  })
};
