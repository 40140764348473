import { useTranslation } from 'react-i18next';
import { useInfluencerPackagePermissions } from '@/auth';
import { Table } from '@/shared/atoms';
import { useCampaignInfluencersContext } from '../CampaignInfluencersContext';
import { useCampaignInfluencersTable } from '../hooks';

export const CampaignInfluencersList = () => {
  const { t } = useTranslation();
  const { hideAddPackageBtn } = useInfluencerPackagePermissions();
  const { loading, campaignId, listRecords } = useCampaignInfluencersContext();
  const { rows, columns } = useCampaignInfluencersTable({ listRecords, campaignId });

  return (
    <Table
      loading={loading}
      data={{ rows, columns }}
      empty={{ linkProps: !hideAddPackageBtn ? { to: '/package/add', label: t('Add package') } : undefined }}
      noTopBorderRadius
      hasBorder
    />
  );
};
