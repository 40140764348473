import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TikTokReviewInfluencersButton } from '@/shared/organisms';
import { PricingTableItem, TikTokProductCategory } from '@/graphql';
import { useSelectItem, useUpdateDocumentTitle } from '@/shared/hooks';
import { CampaignPlansList } from './CampaignPlansList';
import { ProductCategoriesList } from './ProductCategoriesList';

interface SelectCampaignPlanProps {
  marketplacePlans: readonly PricingTableItem[];
}

export const SelectCampaignPlan = ({ marketplacePlans }: SelectCampaignPlanProps) => {
  useUpdateDocumentTitle({
    alwaysBackToParent: true,
    href: '/tiktok/campaigns',
    title: 'documentTitle.TikTok Influencer Campaigns Select Plan',
    appHeader: 'appHeader.TikTok Influencer Campaigns Select Plan'
  });
  const { t } = useTranslation();
  const selectItemProps = useSelectItem<Partial<Pick<TikTokProductCategory, 'id' | 'packageId'>>>();

  const { selectedItem } = selectItemProps;

  return (
    <>
      <div css={styles.sectionWrapper}>
        <FormStyle.SectionTitle index={1} css={styles.sectionTitle} label={t('Choose your product category')} />
        <ProductCategoriesList {...selectItemProps} />
      </div>

      <div css={[styles.sectionWrapper, { marginTop: '16px' }]}>
        <FormStyle.SectionTitle
          index={2}
          label={t('Select a campaign plan')}
          css={[styles.sectionTitle, { marginBottom: 0 }]}
        >
          <TikTokReviewInfluencersButton
            disabled={!selectedItem?.packageId}
            to="/tiktok/campaigns/review-influencers"
            search={{ filter: { packageId: selectedItem?.packageId } }}
            css={{ marginLeft: 'auto', width: '100%', maxWidth: '312px' }}
          />
        </FormStyle.SectionTitle>

        <CampaignPlansList marketplacePlans={marketplacePlans} selectedItem={selectedItem} />
      </div>
    </>
  );
};
const styles = {
  sectionWrapper: css({
    padding: '24px',
    borderRadius: '3px',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0px 2px 1px 0px rgba(110, 124, 137, 0.05), 0px 0px 2px 0px rgba(110, 124, 137, 0.25)'
  }),
  sectionTitle: css({ marginBottom: '24px', fontWeight: 600 })
};
