import { useTranslation } from 'react-i18next';
import { useUserRoles, useInfluencerPackagePermissions } from '@/auth';
import { ReadPackageAccount } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { useTranslateCountry } from '@/shared/hooks';
import { SpreadButton } from '@/shared/molecules';
import { formatIntNumber } from '@/shared/utils';

interface Props {
  data: readonly ReadPackageAccount[];
  canDeletePackage?: boolean;
  onDeletePackage: (data: { id: number; name: string }) => void;
}

export const usePackageListTable = ({ data, canDeletePackage, onDeletePackage }: Props) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();
  const { isAdminStaff } = useUserRoles();
  const { hideEditPackageBtn, hideDeletePackageBtn } = useInfluencerPackagePermissions();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Package') },
    { title: t('HeaderColumn.Company'), hidden: !isAdminStaff },
    { title: t('HeaderColumn.Country') },
    { title: t('HeaderColumn.Account'), styles: { textAlign: 'right' } },
    { title: null, hidden: !canDeletePackage }
  ];
  const rows: RowProps[] = data.map(({ id, name, country, accounts, companies }) => [
    <TableCell.Link label={name} to="/package/$id/content" params={{ id: id.toString() }} />,
    <TableCell.Text
      lines={2}
      value={companies?.map((el) => el?.name).join(', ') || '-'}
      tooltip={{
        help: companies?.length ? (
          <ul>{companies?.map((company) => <li key={company?.id}>{company?.name}</li>)}</ul>
        ) : null
      }}
    />,
    <TableCell.Text value={tc(country.name)} />,
    <TableCell.Number value={formatIntNumber(accounts || 0)} />,
    <SpreadButton
      collapseFrom={0}
      css={{ width: '60px', justifyContent: 'center' }}
      moreButtonProps={{ disabled: hideEditPackageBtn && hideDeletePackageBtn }}
      options={[
        {
          icon: 'edit',
          label: t('Edit'),
          hidden: hideEditPackageBtn,
          to: '/package/$id',
          params: { id: id.toString() },
          tooltip: { help: t('Button.Edit') }
        },
        {
          icon: 'delete',
          label: t('Delete'),
          disabled: !canDeletePackage,
          hidden: hideDeletePackageBtn,
          onClick: () => onDeletePackage({ id, name }),
          tooltip: { help: t('Button.Delete') }
        }
      ]}
    />
  ]);

  return { rows, columns };
};
