import { useUpdatePartnerAgencyMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PartnerForm, PartnerFormValues } from '@/shared/organisms';
import { FormAction, ToggleOption } from '@/shared/types';
import { usePartnerDetailsContext } from './PartnerDetailsContext';

export const EditPartner = () => {
  const { partner } = usePartnerDetailsContext();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callUpdatePartnerAgency } = useUpdatePartnerAgencyMutation();

  const handleSubmit: FormAction<PartnerFormValues>['onSubmit'] = async ({
    logo,
    agencyIds,
    countryId,
    netsuiteId,
    companyUrl,
    phoneNumber,
    advertiserIds,
    partnerAgencyName,
    privateInfluencersOptions,
    influencerCostPermission
  }) => {
    try {
      await callUpdatePartnerAgency({
        variables: {
          input: {
            logo,
            countryId,
            id: partner.id,
            partnerAgencyName,
            companyUrl: companyUrl || null,
            netsuiteId: netsuiteId || null,
            phoneNumber: phoneNumber || null,
            agencyIds: agencyIds.map(Number),
            advertiserIds: advertiserIds.map(Number),
            privateInfluencersIds: privateInfluencersOptions.map((el) => Number(el.value)),
            influencerCostPermission: influencerCostPermission === ToggleOption.TRUE ? true : false
          }
        }
      });
      enqueueSnackbar(t('succeededInUpdating', { name: partnerAgencyName }), { variant: 'success' });
      navigate({ to: '/partner' });
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: partnerAgencyName }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <PartnerForm
      defaultValues={{
        id: partner.id,
        logo: partner.logo || '',
        countryId: partner.country?.id,
        partnerAgencyName: partner.name,
        companyUrl: partner.companyUrl || '',
        netsuiteId: partner.netsuiteId || '',
        phoneNumber: partner.phoneNumber || '',
        agencyIds: partner.agencyIds.map(String),
        advertiserIds: partner.advertiserIds.map(String),
        influencerCostPermission: partner.influencerCostPermission ? ToggleOption.TRUE : ToggleOption.FALSE,
        privateInfluencersOptions:
          partner.privateInfluencers.map((infl) => ({ value: infl.id.toString(), label: infl.name })) || []
      }}
      onSubmit={handleSubmit}
    />
  );
};
