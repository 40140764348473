import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PostDetailsType } from '@/shared/types';
import { PostTabsProps, PostTabs } from '../shared';
import { EngagementHistoryDetailsTab, EngagementHistoryDetailsTabProps } from './EngagementHistoryDetailsTab';
import { EngagementPostDetailsTab, EngagementPostDetailsTabProps } from './EngagementPostDetailsTab';
import { EngagementPostDetailsTabsType } from './types';

const EGG_POST_DETAILS_WIDTH = '856px';

export interface TemplateProps
  extends Pick<PostDetailsType, 'id'>,
    Pick<EngagementHistoryDetailsTabProps, 'historyData'>,
    Pick<EngagementPostDetailsTabProps, 'postStatus' | 'postDetails' | 'trafficSourceData'> {
  loading?: boolean;
  hasHistoryTab?: boolean;
  footerNode?: (props: { tab: EngagementPostDetailsTabsType }) => ReactNode;
}

export const Template = ({
  id,
  loading,
  postStatus,
  footerNode,
  historyData,
  postDetails,
  hasHistoryTab,
  trafficSourceData
}: TemplateProps) => {
  const { t } = useTranslation();

  const tabs: PostTabsProps<EngagementPostDetailsTabsType>['tabs'] = [
    { label: t('Post Detail'), value: EngagementPostDetailsTabsType.Details },
    ...(hasHistoryTab ? [{ label: t('History'), value: EngagementPostDetailsTabsType.History }] : [])
  ];

  return (
    <PostTabs id={id} tabs={tabs}>
      {({ selectedTab }) =>
        selectedTab === 'History' ? (
          <EngagementHistoryDetailsTab
            historyData={historyData}
            css={{ width: EGG_POST_DETAILS_WIDTH, padding: '0' }}
            footerNode={footerNode?.({ tab: selectedTab })}
          />
        ) : (
          <EngagementPostDetailsTab
            loading={loading}
            postStatus={postStatus}
            postDetails={postDetails}
            trafficSourceData={trafficSourceData}
            css={{ width: EGG_POST_DETAILS_WIDTH }}
            footerNode={footerNode?.({ tab: selectedTab })}
          />
        )
      }
    </PostTabs>
  );
};
