import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TiktokAdAccounts.graphql';
import { Query, QuerytiktokAdAccountsArgs } from '../../__generated__/globalTypes';

export const useTiktokAdAccountsQuery = (options?: QueryHookOptions<Query, QuerytiktokAdAccountsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytiktokAdAccountsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTiktokAdAccountsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytiktokAdAccountsArgs>
): QueryResult<Query, QuerytiktokAdAccountsArgs> & {
  getTiktokAdAccounts: LazyQueryExecFunction<Query, QuerytiktokAdAccountsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTiktokAdAccounts, result] = useLazyQuery<Query, QuerytiktokAdAccountsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTiktokAdAccounts, ...result };
};

export const useTiktokAdAccountsPromiseQuery = () => {
  const client = useApolloClient();

  const getTiktokAdAccounts = useCallback(
    (options?: Omit<QueryOptions<QuerytiktokAdAccountsArgs, Query>, 'query'>) =>
      client.query<Query, QuerytiktokAdAccountsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTiktokAdAccounts };
};
