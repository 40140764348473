import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { SpreadButton } from '@/shared/molecules';
import { THEME } from '@/shared/constants';
import { useTranslateCountry } from '@/shared/hooks';
import { formatNumber, getMkpReportLink } from '@/shared/utils';
import { TableCell, ColumnProps, RowProps, SocialIcon, SocialIconProps } from '@/shared/atoms';
import { useUserRoles, useReportPermissions, useAdvertiserPermissions, useMarketplacePermissions } from '@/auth';
import {
  Marketplace,
  CampaignSocialMediaType,
  MarketplaceCampaignStatus,
  MarketplaceCampaignDetailType
} from '@/graphql';
import { MarketplaceStatus } from '../../MarketplaceStatus';
import { SelectedMarketplaceCampaignType } from '../type';

interface Props {
  data: readonly Marketplace[];
  onDelete: (campaign: SelectedMarketplaceCampaignType) => void;
  onDuplicate: (campaign: SelectedMarketplaceCampaignType) => void;
}

export const useMarketplaceCampaignsTable = ({ data, onDelete, onDuplicate }: Props) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();
  const { hideReportLink } = useReportPermissions();
  const { hideEditAdvertiserBtn } = useAdvertiserPermissions();
  const { isAdminStaff, isAdminStaffAgency, isAdvertiser } = useUserRoles();
  const { hideEditMarketplaceBtn, hideDeleteMarketplaceBtn, hideMarketplaceReportLink } = useMarketplacePermissions();

  const hideCampaignLink = isAdvertiser ? hideMarketplaceReportLink : hideEditMarketplaceBtn;
  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Campaign Title'), sticky: { active: true, hasBorderRight: true } },
    { title: t('HeaderColumn.Country') },
    { title: t('HeaderColumn.Status') },
    { title: t('HeaderColumn.Advertiser'), hidden: !isAdminStaffAgency },
    { title: t('HeaderColumn.Date Range') },
    { title: t('HeaderColumn.Social Media'), styles: { textAlign: 'center' } },
    { title: t('HeaderColumn.Unit Cost'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Budget'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Sales PIC'), hidden: !isAdminStaff },
    { title: t('HeaderColumn.Influencer Management PIC'), hidden: !isAdminStaff }
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      title,
      status,
      budget,
      country,
      endDate,
      currency,
      startDate,
      salesPics,
      advertiser,
      socialMedia,
      costPerPost,
      costPerLike,
      costPerView,
      currentCost,
      costPerShare,
      costPerClick,
      costPerOrder,
      campaignType,
      costPerAction,
      costPerComment,
      commissionRate,
      isPostTracking,
      costPerFollower,
      isSelectionCampaign,
      influencerManagementPics
    }) => {
      const isECommerceCampaign = campaignType === MarketplaceCampaignDetailType.E_COMMERCE;
      const hasBadge = isSelectionCampaign || isECommerceCampaign;
      const stringId = id.toString();
      const hasReportLink =
        isAdvertiser &&
        !hideReportLink &&
        [
          MarketplaceCampaignStatus.ONGOING,
          MarketplaceCampaignStatus.FINISHED,
          MarketplaceCampaignStatus.SUSPENDED
        ].includes(status);
      const reportLink = getMkpReportLink({
        id: id.toString(),
        mkpCampaignType: campaignType,
        isPostTracking
      });

      /* eslint-disable react/jsx-key */
      return [
        <div css={styles.nameColumnWrapper}>
          <div css={hideCampaignLink ? { pointerEvents: 'none' } : ''}>
            <TableCell.Link
              label={title}
              to={hasReportLink ? reportLink.to : '/marketplace/$id'}
              params={{ id: stringId }}
              css={{ fontSize: '12px' }}
            />
            {hasBadge ? (
              <div css={{ display: 'flex', alignItems: 'center', marginTop: '4px', gap: '4px' }}>
                {isECommerceCampaign ? <div css={styles.badge('#40B87C')}>{t('Special Deal')}</div> : null}
                {isSelectionCampaign ? <div css={styles.badge('#FFB63D')}>{t('Selection')}</div> : null}
              </div>
            ) : null}
          </div>

          <SpreadButton
            collapseFrom={2}
            css={{ marginLeft: '4px' }}
            options={[
              {
                icon: 'post',
                label: t('Posts'),
                disabled: !isPostTracking,
                tooltip: { help: t('Button.Posts') },
                to: '/marketplace/$id/posts',
                params: { id: stringId }
              },
              {
                icon: 'chart',
                label: t('Report'),
                hidden: hideReportLink,
                tooltip: { help: t('Button.Report') },
                ...reportLink,
                disabled: [MarketplaceCampaignStatus.REVIEWING, MarketplaceCampaignStatus.UPCOMING].includes(status)
              },
              {
                icon: 'edit',
                label: t('Edit'),
                tooltip: { help: t('Button.Edit') },
                to: '/marketplace/$id',
                params: { id: stringId },
                hidden: hideEditMarketplaceBtn || hideDeleteMarketplaceBtn
              },
              {
                icon: 'copy',
                hidden: !isAdminStaff,
                label: t('Duplicate'),
                tooltip: { help: t('Button.Duplicate') },
                onClick: () => onDuplicate({ id, title, type: 'duplicate' })
              },
              {
                icon: 'delete',
                label: t('Delete'),
                hidden: hideDeleteMarketplaceBtn,
                onClick: () => onDelete({ id, title, type: 'delete' }),
                disabled: status !== MarketplaceCampaignStatus.REVIEWING
              }
            ]}
          />
        </div>,
        <TableCell.Text value={tc(country.name)} css={{ minWidth: '100px' }} />,
        <MarketplaceStatus status={status} />,
        <TableCell.Link
          label={advertiser.name}
          disabled={hideEditAdvertiserBtn}
          to="/advertiser/$id"
          params={{ id: advertiser.id.toString() }}
        />,
        <TableCell.Period period={{ startDate, endDate }} />,
        [CampaignSocialMediaType.UNSELECT, CampaignSocialMediaType.OTHER].includes(socialMedia) ? (
          <TableCell.Text value="-" css={{ minWidth: 'unset', textAlign: 'center' }} />
        ) : (
          <div css={{ display: 'block', margin: '0 auto', width: 'max-content' }}>
            <SocialIcon type={socialMedia as SocialIconProps['type']} />
          </div>
        ),
        <TableCell.Costs
          currency={t(currency)}
          socialMedia={socialMedia}
          costPerPost={costPerPost}
          costPerLike={costPerLike}
          costPerView={costPerView}
          costPerOrder={costPerOrder}
          costPerClick={costPerClick}
          costPerShare={costPerShare}
          costPerAction={costPerAction}
          costPerComment={costPerComment}
          commissionRate={commissionRate}
          costPerFollower={costPerFollower}
        />,
        <TableCell.Number value={`${formatNumber(currentCost)} / ${formatNumber(budget)}`} unit={t(currency)} />,
        <TableCell.Text
          value={salesPics
            ?.slice(0, 2)
            ?.map((pic) => pic.name)
            ?.join(', ')}
        />,
        <TableCell.Text
          value={influencerManagementPics
            ?.slice(0, 2)
            ?.map((pic) => pic.name)
            ?.join(', ')}
        />
      ];
    }
  );

  return { rows, columns };
};

const styles = {
  nameColumnWrapper: css({
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  }),
  badge: (backgroundColor: string) =>
    css({
      gap: '4px',
      fontSize: '10px',
      fontWeight: 400,
      alignItems: 'center',
      display: 'inline-flex',
      color: THEME.text.colors.gray.lv3,
      '&::before': {
        backgroundColor,
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '50%'
      }
    })
};
