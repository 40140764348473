import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { linkOptions, Outlet, useParams } from '@tanstack/react-router';
import { useCustomHeader } from '@/shared/hooks';
import { THEME } from '@/shared/constants';
import { Anchor, ButtonLink, Icon, TextCutter } from '@/shared/atoms';
import { TikTokAdReportType, useAllTiktokAdReportSummaryQuery } from '@/graphql';
import { TikTokSummaryDataContext } from '@/shared/organisms';

export const TikTokAdsReportRoot = ({ summaryData, loading }: TikTokSummaryDataContext) => {
  const params = useParams({ strict: false });
  const { t } = useTranslation();
  const { updateGlobalLayout } = useCustomHeader();

  const adCampaignId = params?.id || '';
  const adGroupId = params?.groupId || '';

  const [{ adCampaignName, adGroupName }, setState] = useState<{
    adGroupName?: string | null;
    adCampaignName?: string | null;
  }>({
    adGroupName: '',
    adCampaignName: ''
  });

  useAllTiktokAdReportSummaryQuery({
    skip: !adCampaignId,
    variables: { adReportType: TikTokAdReportType.AD_GROUP, adCampaignId },
    onCompleted: ({ allTiktokAdReportSummary }) => {
      if (allTiktokAdReportSummary?.adCampaignName) {
        setState((prev) => ({ ...prev, adCampaignName: allTiktokAdReportSummary.adCampaignName }));
      }
    }
  });
  useAllTiktokAdReportSummaryQuery({
    skip: !adCampaignId || !adGroupId,
    variables: { adReportType: TikTokAdReportType.AD, adCampaignId, adGroupId },
    onCompleted: ({ allTiktokAdReportSummary }) => {
      if (allTiktokAdReportSummary?.adGroupName) {
        setState((prevState) => ({ ...prevState, adGroupName: allTiktokAdReportSummary.adGroupName }));
      }
    }
  });

  useEffect(() => {
    if (summaryData?.numberCreatedCampaigns === 0) {
      updateGlobalLayout({ hasCustomHeader: true });
    }
  }, [summaryData?.numberCreatedCampaigns]);

  const goBackHref = adGroupId
    ? linkOptions({ to: '/tiktok/ad-campaign-report/$id', params: { id: adCampaignId } })
    : linkOptions({ to: '/tiktok/ad-campaign-report', params: undefined });

  return (
    <>
      {summaryData?.numberCreatedCampaigns !== 0 && !loading ? (
        <header css={styles.header}>
          {adGroupId || adCampaignId ? (
            <ButtonLink
              prefixIcon={{ icon: 'caret-left', size: 11 }}
              to={goBackHref.to}
              params={goBackHref.params}
              variant="white"
              css={{ marginRight: '8px' }}
            />
          ) : null}
          <Anchor to={adCampaignId ? '/tiktok/ad-campaign-report' : undefined} css={styles.title}>
            {t('appHeader.Ad Report')}
          </Anchor>
          {adCampaignId ? (
            <>
              <Icon icon="caret-right" size={9} color={THEME.text.colors.black.lv1} />
              <Anchor
                css={styles.title}
                to={adGroupId ? '/tiktok/ad-campaign-report/$id' : undefined}
                params={{ id: adCampaignId }}
              >
                <TextCutter text={adCampaignName} />
              </Anchor>
            </>
          ) : null}
          {adGroupId ? (
            <>
              <Icon icon="caret-right" size={9} color={THEME.text.colors.black.lv1} />
              <Anchor css={styles.title}>
                <TextCutter text={adGroupName} />
              </Anchor>
            </>
          ) : null}
        </header>
      ) : null}
      <Outlet />
    </>
  );
};

const styles = {
  header: css({
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '16px',
    boxSizing: 'border-box',
    height: THEME.container.header.height,
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  title: css({
    boxSizing: 'border-box',
    overflow: 'hidden',
    fontSize: '18px',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    maxWidth: '400px'
  })
};
