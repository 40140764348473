import { THEME } from '@/shared/constants';

export const PIE_CHART_COLORS = [
  THEME.chart.colors.pink,
  THEME.chart.colors.blue,
  THEME.chart.colors.yellow,
  THEME.chart.colors.green,
  THEME.chart.colors.orange,
  THEME.chart.colors.grey
] as const;
