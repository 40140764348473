import {
  AnalyticsFacebookDashboardOverview,
  AnalyticsFacebookDashboardAudience,
  AnalyticsFacebookDashboardFeedPosts,
  AnalyticsFacebookDashboardPostsOverview
} from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';

export const AnalyticsFacebookDashboard = () => {
  const { brandAccount, selectedSocialAccount } = useAnalyticsProfileContext();

  return (
    <>
      <AnalyticsFacebookDashboardOverview analyticsAccount={selectedSocialAccount} />
      <AnalyticsFacebookDashboardAudience analyticsAccount={selectedSocialAccount} />
      <AnalyticsFacebookDashboardPostsOverview analyticsAccount={selectedSocialAccount} />
      <AnalyticsFacebookDashboardFeedPosts
        analyticsAccount={selectedSocialAccount}
        brandAccountName={brandAccount.brandAccountName}
      />
    </>
  );
};
