import { useTranslation } from 'react-i18next';
import { SortOption } from '@/shared/types';
import { FormProvider } from '@/shared/atoms';
import { SocialAccountInfluencerListSortField, Order } from '@/graphql';
import { useTikTokCampaignInfluencersFilter } from '../TikTokCampaignInfluencersFilter';
import { Template, TemplateProps } from './Template';
import { TikTokCampaignInfluencersSortFormValues } from './types';

export type TikTokCampaignInfluencersSortProps = Pick<TemplateProps, 'totalRecords'>;

export const TikTokCampaignInfluencersSort = ({ totalRecords }: TikTokCampaignInfluencersSortProps) => {
  const { t } = useTranslation();
  const { limit, sort, setSortAndLimit } = useTikTokCampaignInfluencersFilter();

  const sortOptions: SortOption<SocialAccountInfluencerListSortField>[] = [
    {
      order: Order.ASC,
      label: t('Follower (ASC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_FOLLOWERS
    },
    {
      order: Order.DESC,
      label: t('Follower (DESC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_FOLLOWERS
    },
    {
      order: Order.ASC,
      label: t('Engagement (ASC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT
    },
    {
      order: Order.DESC,
      label: t('Engagement (DESC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT
    },
    {
      order: Order.ASC,
      label: t('ER by views (ASC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE
    },
    {
      order: Order.DESC,
      label: t('ER by views (DESC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE
    },
    {
      order: Order.ASC,
      label: t('ER by followers (ASC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE
    },
    {
      order: Order.DESC,
      label: t('ER by followers (DESC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE
    }
  ].map((option) => ({ ...option, value: `${option.field}-${option.order}` }));

  return (
    <div css={{ position: 'relative', borderRadius: 'inherit' }}>
      <FormProvider<TikTokCampaignInfluencersSortFormValues>
        onSubmit={(values) => {
          setSortAndLimit({
            sort: { field: values.field, order: values.order },
            limit: +values.limit
          });
        }}
        values={{
          ...sort,
          limit: limit.toString(),
          sort: sortOptions.find((o) => o.field === sort.field && o.order === sort.order)?.value || ''
        }}
      >
        <Template totalRecords={totalRecords} sortOptions={sortOptions} />
      </FormProvider>
    </div>
  );
};
