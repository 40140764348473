import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { formatIntNumber } from '@/shared/utils';

export interface ProfilePostsCountProps {
  className?: string;
  children?: ReactNode;
  count?: number | null;
  countLabel?: ReactNode;
}

export const ProfilePostsCount = ({ count, children, className, countLabel }: ProfilePostsCountProps) => {
  const { t } = useTranslation();

  return count ? (
    <div css={styles.wrapper} className={className}>
      <p css={{ fontSize: '13px', lineHeight: '18px', flex: '1 1 auto' }}>
        <span css={{ fontWeight: 600 }}>{formatIntNumber(count)}</span>
        &nbsp;
        {countLabel || t('Posts found')}
      </p>

      {children}
    </div>
  ) : null;
};
const styles = {
  wrapper: css({
    display: 'flex',
    minHeight: '51px',
    padding: '8px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0px 2px 1px rgba(110, 124, 137, 0.05), 0px 0px 2px rgba(110, 124, 137, 0.25)'
  })
};
