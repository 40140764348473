import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/GeneratePartnerInfluencerSignUpUrl.graphql';
import { Mutation } from '../../__generated__/globalTypes';

export const useGeneratePartnerInfluencerSignUpUrlMutation = (options?: MutationHookOptions<Mutation>) => {
  const [callGeneratePartnerInfluencerSignUpUrl, result] = useMutation<Mutation>(MUTATION, options);

  return { callGeneratePartnerInfluencerSignUpUrl, ...result };
};

export type GeneratePartnerInfluencerSignUpUrlMutationFunction = Mutation['generatePartnerInfluencerSignUpUrl'];
