import { css } from '@emotion/react';
import { TableCell, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface StatsSectionProps {
  className?: string;
  id: string | number;
  stats: { label: string; value?: string | number; isDate?: boolean }[];
}

export const StatsSection = ({ id, stats, className }: StatsSectionProps) => (
  <div css={styles.wrapper} className={className}>
    {stats.map(({ label, value, isDate }) => (
      <div key={`${id}-${label}`} css={styles.item(isDate)}>
        <TextCutter
          lines={2}
          text={label}
          css={{
            wordBreak: 'break-word',
            color: THEME.text.colors.gray.lv3,
            fontSize: '10px',
            letterSpacing: '0.28px'
          }}
        />
        {isDate ? (
          <TableCell.Date className="value" value={value as string} css={{ marginTop: '4px' }} />
        ) : (
          <TextCutter
            text={value}
            className="value"
            css={{ marginTop: '4px', color: THEME.text.colors.black.lv1, fontSize: '14px' }}
          />
        )}
      </div>
    ))}
  </div>
);
const styles = {
  wrapper: css({
    fontWeight: 600,
    display: 'flex',
    flex: '1 1 auto',
    marginRight: '48px',
    justifyContent: 'space-around'
  }),
  item: (isDate?: boolean) =>
    css({
      flex: 1,
      display: 'flex',
      padding: '0 8px',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: isDate ? 'left' : 'right'
    })
};
