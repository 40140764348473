import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdatePaymentStatus.graphql';
import { Mutation, MutationupdatePaymentStatusArgs } from '../../__generated__/globalTypes';

export const useUpdatePaymentStatusMutation = (
  options?: MutationHookOptions<Mutation, MutationupdatePaymentStatusArgs>
) => {
  const [callUpdatePaymentStatus, result] = useMutation<Mutation, MutationupdatePaymentStatusArgs>(MUTATION, options);

  return { callUpdatePaymentStatus, ...result };
};

export type UpdatePaymentStatusMutationFunction = Mutation['updatePaymentStatus'];
