import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useFacebookPageAnalyticsPostsQuery } from '@/graphql';
import { ProfilePostsCount, ProfilePostsFilter, AnalyticsFacebookPostsList } from '@/shared/organisms';
import { useInitialAnalyticsPosts } from '../hooks';
import { AnalyticsFbFeedPostSearchSchemaType } from './schemaTypes';

export const AnalyticsFacebookPosts = () => {
  const { brandAccountId, mainSocialAccountId } = useInitialAnalyticsPosts({
    socialMedia: 'facebook'
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<AnalyticsFbFeedPostSearchSchemaType>();
  const { data, previousData, loading } = useFacebookPageAnalyticsPostsQuery({
    skip: !brandAccountId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      orderBy: sort,
      offset: getOffset(page, limit),
      facebookPageAnalyticsAccountId: Number(mainSocialAccountId),
      ...filter
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.facebookPageAnalyticsPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

      <ProfilePostsCount count={totalRecords} />

      <AnalyticsFacebookPostsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.facebookPageAnalyticsPosts.posts || []}
      />
    </ListWithPagination>
  );
};
