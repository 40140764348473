import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { TikTokCampaignInfluencersTemplate } from '@/shared/organisms';

export const TiktokReviewInfluencers = () => {
  useUpdateDocumentTitle({
    alwaysBackToParent: true,
    href: '/tiktok/campaigns/add',
    title: 'documentTitle.TikTok Advanced Campaign',
    appHeader: 'appHeader.TikTok Advanced Campaign'
  });
  const { t } = useTranslation();

  return (
    <div css={{ boxShadow: THEME.shadows.boxShadow }}>
      <div
        css={{
          padding: '16px 24px',
          boxSizing: 'border-box',
          borderBottom: THEME.border.base,
          backgroundColor: THEME.background.colors.white
        }}
      >
        <p css={{ fontSize: '14px', lineHeight: '90%', fontWeight: 600 }}>{t('Advanced Campaign')}</p>
        <p css={{ fontSize: '12px', lineHeight: '90%', marginTop: '4px', color: THEME.text.colors.gray.lv3 }}>
          {t('Only selected influencers will see the campaign')}
        </p>
      </div>

      <TikTokCampaignInfluencersTemplate css={{ boxShadow: 'none' }} />
    </div>
  );
};
