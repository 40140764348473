import { redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { adminStaffAgencyTalentAgencyPartnerRoles } from '@/shared/constants';
import { PostsFilterSearchSchema } from '@/shared/organisms';
import { PermissionRoute } from '@/app-components';
import { Posts } from '@/pages/Posts';
import { PartialRouter } from '../../types';

export const PostsRouter = {
  '/_private-routes/posts': [
    () => <PermissionRoute element={<Posts />} permissions={adminStaffAgencyTalentAgencyPartnerRoles} />,
    {
      validateSearch: zodValidator(PostsFilterSearchSchema),
      loaderDeps: ({ search }) => search,
      loader: ({ deps, context }) => {
        // a bit of a hack to make sure that the countryId is set
        if (deps.filter.countryId === 'null') {
          return redirect({
            to: '/posts',
            replace: true,
            search: {
              ...deps,
              filter: {
                ...deps.filter,
                countryId: context.auth?.countryId || ''
              }
            }
          });
        }
      }
    }
  ]
} as const satisfies PartialRouter;
