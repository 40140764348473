import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonSubmit, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAllAvailableCountryOptions } from '@/shared/hooks';
import { SelectorField, TextField } from '@/shared/molecules';
import { TikTokSignupFormRegisteredValues, TikTokSignupRegisteredFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { isSubmitting }
  } = useFormContext<TikTokSignupFormRegisteredValues>();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();

  const values = getValues();
  const hasAllFieldFilled = Object.values(values).every((field) => !!field);

  return (
    <>
      <FormStyle.Wrapper css={styles.wrapper} gapRow={16}>
        <TextField<TikTokSignupRegisteredFormKeys>
          name="companyName"
          title={t('TextForm.Company Name')}
          placeholder={t<string>('Company Name')}
          required
        />
        <TextField<TikTokSignupRegisteredFormKeys>
          name="companyUrl"
          title={t('TextForm.Company URL')}
          placeholder="https://anytag.tech"
          required
        />

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <TextField<TikTokSignupRegisteredFormKeys>
            name="phoneNumber"
            placeholder="066020485707"
            title={t('TextForm.Phone Number')}
            required
          />
          <SelectorField<TikTokSignupRegisteredFormKeys>
            name="countryId"
            title={t('Selector.Country')}
            options={allAvailableCountryOptions}
            required
          />
        </FormStyle.FieldsGroup>
      </FormStyle.Wrapper>
      <div css={styles.submitBox}>
        <ButtonSubmit
          variant="blue"
          loading={isSubmitting}
          disabled={!hasAllFieldFilled}
          title={t('Button.Save')}
          css={{ padding: '0 24px', marginLeft: 'auto' }}
        />
      </div>
    </>
  );
};

const styles = {
  link: css({ display: 'inline', fontSize: '12px', lineHeight: '1.4em' }),
  wrapper: css({
    width: '712px',
    margin: '0 auto',
    borderRadius: '3px',
    padding: '48px 40px 16px',
    boxSizing: 'border-box'
  }),
  submitBox: css({
    padding: '16px',
    backgroundColor: THEME.background.colors.white,
    borderTop: THEME.border.base,
    borderRadius: '0 0 3px 3px'
  })
};
