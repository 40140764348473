import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SortOption } from '@/shared/types';
import { getNumberValidFilter } from '@/shared/utils';
import { PostListSortFieldV3 } from '@/graphql';
import { SelectorField, TextSearchField, SelectorFieldV2, BasicFilterWrapper } from '@/shared/molecules';
import { AdvancedFilter } from './AdvancedFilter';
import { PostsFilterFormKeys, PostsFilterFormValues } from './schemaTypes';
import { usePostsFilterOptions } from './hooks';

interface BasicFilterProps {
  onSubmit?: () => void;
  sortOptions: SortOption<PostListSortFieldV3>[];
}

export const BasicFilter = ({ sortOptions, onSubmit }: BasicFilterProps) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<PostsFilterFormValues>();
  const values = watch();
  const { allCountryOptions, socialMediaOptions, isCNCountrySupported } = usePostsFilterOptions();

  const basicFilterKeys: PostsFilterFormKeys[] = ['keyword', 'socialMedia', 'field', 'order', 'countryId'];
  const numOfValidFilter = getNumberValidFilter(values, basicFilterKeys);

  const handleSortChange = (
    field: PostsFilterFormKeys,
    value: PostListSortFieldV3,
    options: SortOption<PostListSortFieldV3>[] = []
  ) => {
    const selectedOption = options.find((o) => o.value === value);
    setValue(field, value);
    setValue('field', selectedOption?.field || null);
    setValue('order', selectedOption?.order || null);
  };

  return (
    <BasicFilterWrapper>
      <TextSearchField<PostsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.PostContent')}
      />

      <SelectorFieldV2<PostsFilterFormKeys>
        menuWidth="auto"
        menuHeight="auto"
        name="socialMedia"
        onSubmit={onSubmit}
        css={{ maxWidth: '168px' }}
        options={socialMediaOptions}
        placeholder={t<string>('Selector.Social Media')}
        clearable={false}
      />

      <SelectorField<PostsFilterFormKeys>
        name="countryId"
        onSubmit={onSubmit}
        css={{ maxWidth: '128px' }}
        options={allCountryOptions}
        placeholder={t<string>('Selector.Country')}
        singleSelectorProps={{ hideDeselectOption: true, disabled: isCNCountrySupported }}
      />

      <AdvancedFilter count={numOfValidFilter} onSubmit={onSubmit} />

      <SelectorField<PostsFilterFormKeys, SortOption<PostListSortFieldV3>>
        name="sort"
        onSubmit={onSubmit}
        options={sortOptions}
        css={{ maxWidth: '108px' }}
        onChange={handleSortChange}
        placeholder={t<string>('Sort')}
        singleSelectorProps={{
          type: 'radio',
          styles: { menu: (base) => ({ ...base, width: 'max-content', right: 0 }) }
        }}
      />
    </BasicFilterWrapper>
  );
};
