import { GTM_IDS } from '@/shared/constants';
import { getRestApiUrl } from '@/shared/utils';
import { TikTokSpecialCampaignDetail } from '@/graphql';
import { PostReportSegmentKeys } from '@/shared/organisms';
import { useDownloadFile, useQueryHelper } from '@/shared/hooks';
import { DownloadFileType, DropdownOption } from '@/shared/types';
import { DownloadButton, DownloadButtonProps } from '@/shared/atoms';
import { useTikTokCampaignReportContext } from '../TikTokCampaignReportContext';
import { useTikTokCampaignReportFilter } from './hooks';

type PostDownloadOption = DropdownOption<DownloadFileType>;

export interface DownloadTikTokReportButtonProps extends Pick<DownloadButtonProps, 'disabled'> {
  tiktokSpecialCampaign: TikTokSpecialCampaignDetail;
}
export const DownloadTikTokReportButton = ({
  tiktokSpecialCampaign: { id },
  ...buttonProps
}: DownloadTikTokReportButtonProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { segmentStatus } = useTikTokCampaignReportContext();
  const {
    filter: { keyword, endDate, startDate }
  } = useTikTokCampaignReportFilter();

  const options: PostDownloadOption[] = [
    {
      label: t<string>('CSV'),
      value: DownloadFileType.CSV,
      gtmId: GTM_IDS.tiktokSpecial.report_csv_download
    }
  ];

  const handleDownloadPostReport = async (fileType?: PostDownloadOption['value']) => {
    // Just support CSV for now
    if (!fileType || fileType !== DownloadFileType.CSV) {
      return;
    }

    try {
      switch (fileType) {
        case DownloadFileType.CSV:
          await handleDownloadFile({
            filenamePrefix: 'tiktok_special_report',
            url: getRestApiUrl(`tiktok_special/campaign/${id}/report/csv/`),
            params: {
              keyword,
              endDate,
              startDate,
              groups: (Object.keys(segmentStatus) as PostReportSegmentKeys[])
                .reduce<PostReportSegmentKeys[]>((acc, curr) => (segmentStatus[curr] ? [...acc, curr] : acc), [])
                .join()
            }
          });
          break;
        default:
          break;
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <DownloadButton<PostDownloadOption>
      {...buttonProps}
      options={options}
      loading={downloading}
      onSelectOption={({ value }) => handleDownloadPostReport(value)}
    />
  );
};
