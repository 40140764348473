import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { CampaignSocialMediaType, CampaignTrackingOption } from '@/graphql';
import { FormStyle, Switch } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

import { MarketplaceFormValues, MarketplaceFormKeys } from '../types';

interface TrackingCreatorPostFieldProps {
  className?: string;
  disabled?: boolean;
  onChange?: (active?: boolean) => void;
}

export const TrackingCreatorPostField = ({ className, disabled, onChange }: TrackingCreatorPostFieldProps) => {
  const { t } = useTranslation();
  const { watch, trigger, setValue, getValues } = useFormContext<MarketplaceFormValues>();

  const { socialMedia } = getValues();
  const trackingOptionsValue = watch(MarketplaceFormKeys.trackingOptions);
  const isTrackingCreatorPost = trackingOptionsValue.includes(CampaignTrackingOption.POST);

  const handleToggleSwitch = () => {
    const newValue = [...trackingOptionsValue];

    if (isTrackingCreatorPost) {
      const index = newValue.findIndex((option) => option === CampaignTrackingOption.POST);
      newValue.splice(index, 1);
    } else {
      newValue.push(CampaignTrackingOption.POST);
    }

    if (socialMedia === CampaignSocialMediaType.OTHER) {
      setValue(MarketplaceFormKeys.socialMedia, '', { shouldValidate: true });
    }
    setValue(MarketplaceFormKeys.trackingOptions, newValue, { shouldValidate: true });
    onChange?.(!isTrackingCreatorPost);
    trigger([MarketplaceFormKeys.socialMedia]);
  };

  return (
    <FormStyle.FieldWrapper className={className}>
      <div css={{ border: '1px solid #DEE5EC', borderRadius: '3px', padding: '16px' }}>
        <p css={{ marginBottom: '4px', fontSize: '13px', fontWeight: 600 }}>{t("Track creator's posts")}</p>

        <Switch disabled={disabled} isChecked={isTrackingCreatorPost} onChange={handleToggleSwitch} />

        <p css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3, marginTop: '4px', lineHeight: '18px' }}>
          <Trans i18nKey="trackCreatorPostsDescription" components={{ b: <b /> }} />
        </p>
      </div>
    </FormStyle.FieldWrapper>
  );
};
