import { css } from '@emotion/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { TikTokSpecialPostHistory, TikTokSpecialPostHistoryStatus } from '@/graphql';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter } from '@/shared/utils';
import { LinkifyItPreview } from '@/shared/molecules';

interface HistorySectionProps {
  data?: readonly TikTokSpecialPostHistory[] | null;
}

export const HistorySection = ({ data }: HistorySectionProps) => {
  const { t, i18n } = useTranslation();

  if (!data?.length) {
    return null;
  }

  const getCustomHistoryPostReason = ({ reason, status, operatedUserName }: TikTokSpecialPostHistory) => {
    switch (status) {
      case TikTokSpecialPostHistoryStatus.CREATED:
        return t('System fetched the post');
      case TikTokSpecialPostHistoryStatus.APPROVED:
        return t('username approved the post', { username: operatedUserName });
      case TikTokSpecialPostHistoryStatus.REJECTED:
        return reason
          ? t('username rejected the post', { username: operatedUserName, reason })
          : t('username rejected the post without a reason', { username: operatedUserName });
      default:
        return reason;
    }
  };

  return (
    <div css={{ flex: '1 1 auto', padding: '24px 24px 48px', backgroundColor: '#F6F8FA' }}>
      <h3 css={{ fontSize: '18px', fontWeight: 600 }}>{t('History')}</h3>

      <div css={{ marginTop: '16px', rowGap: '24px', display: 'grid', gridTemplateColumns: 'max-content auto' }}>
        {data.map((history, index) => {
          const { operatedDate, detailedReason } = history;
          const reason = getCustomHistoryPostReason(history);

          return (
            <Fragment key={index}>
              <p css={styles.historyDate(index === 0)}>
                {localizedDateFormatter(operatedDate, 'PPP', i18n.language as AppLanguage)}
              </p>

              <div css={{ marginLeft: '48px', flex: '1 1 auto' }}>
                {reason ? <p css={styles.reason}>{reason}</p> : null}

                {detailedReason ? <LinkifyItPreview value={detailedReason} css={{ marginTop: '8px' }} /> : null}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  reason: css({ fontSize: '14px', lineHeight: '24px' }),
  historyDate: (firstItem: boolean) =>
    css({
      flex: 'none',
      fontSize: '14px',
      lineHeight: '24px',
      whiteSpace: 'nowrap',
      position: 'relative',

      '&::before': firstItem
        ? {
            top: '8px',
            zIndex: 2,
            flex: 'none',
            width: '10px',
            height: '8px',
            content: '"•"',
            right: '-24px',
            display: 'flex',
            fontSize: '15px',
            color: '#3892E5',
            borderRadius: '50%',
            paddingBottom: '2px',
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center',
            backgroundColor: '#AFD3F5',
            transform: 'translateX(calc(50% - 0.5px))'
          }
        : {
            top: '8px',
            zIndex: 1,
            flex: 'none',
            width: '8px',
            height: '8px',
            content: '""',
            right: '-24px',
            borderRadius: '50%',
            position: 'absolute',
            backgroundColor: '#C5D0DA',
            transform: 'translateX(calc(50% - 0.5px))'
          },
      '&::after': {
        top: '8px',
        content: '""',
        width: '1px',
        right: '-24px',
        position: 'absolute',
        backgroundColor: '#DEE5EC',
        height: 'calc(100% + 24px)'
      }
    }),
  draftPostContent: css({
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '120%',
    marginTop: '8px'
  }),
  draftPostMaterialWrapper: css({
    height: '68px',
    display: 'flex',
    overflow: 'auto'
  }),
  toggleDraftPostButton: css({
    gap: '8px',
    display: 'flex',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    alignItems: 'center',
    color: THEME.text.colors.blue
  })
};
