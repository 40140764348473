import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { PeriodType } from '@/shared/types';
import { formatDate } from '@/shared/utils';

export const formatAffiliatePeriod = ({ period, title }: { period: Partial<PeriodType>; title: string }) =>
  `${title}\u00A0\u00A0(${formatDate(period.startDate || '')} - ${formatDate(period.endDate || '')})`;

export const styles = {
  totalRow: css({
    height: '32px',
    pointerEvents: 'none',
    '> td': {
      fontSize: '14px',
      fontWeight: 600,
      backgroundColor: THEME.table.header.background.colors.lv2,
      '> *': { fontSize: 'inherit' }
    }
  }),
  averageRow: css({
    height: '32px',
    borderTop: 'none',
    pointerEvents: 'none',
    '> td': {
      fontWeight: 600,
      fontSize: '12px',
      backgroundColor: THEME.table.header.background.colors.lv2,
      color: THEME.text.colors.gray.lv3,
      '> *': { fontSize: 'inherit' }
    }
  })
};
