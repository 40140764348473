import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfluencerNewProfileHeaderV2 } from '@/graphql';
import { Button } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useDeepCompareEffect, useToggleState } from '@/shared/hooks';
import { InfluencerTagsPopover } from '../../Influencers';

interface TagsProps {
  influencerId: number;
  tags: InfluencerNewProfileHeaderV2['tags'];
}

export const Tags = ({ tags, influencerId }: TagsProps) => {
  const { t } = useTranslation();
  const popupState = useToggleState();
  const [tagsValue, setTagsValue] = useState(tags);

  useDeepCompareEffect(() => {
    setTagsValue(tags);
  }, [tags]);

  const hasTags = !!tagsValue.length;

  return (
    <InfluencerTagsPopover
      tags={tagsValue}
      position="bottom"
      onTagsChange={setTagsValue}
      influencerId={influencerId}
      dropdownCss={{ width: `${THEME.container.influencerProfile.intro.width + 16}px` }}
      target={({ setOpen }) => (
        <div css={hasTags && THEME.shadows.pseudoHover} onClick={() => (hasTags ? setOpen(true) : null)}>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3 css={{ fontSize: '12px' }}>{t('BasicInfo.Tags')}</h3>

            {!hasTags ? (
              <div onClick={() => setOpen(true)} css={styles.addLabel}>
                + {t('Button.Add Tag')}
              </div>
            ) : null}
          </div>

          {hasTags ? (
            <div
              onClick={() => setOpen(true)}
              css={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginTop: '8px' }}
            >
              {tagsValue.map((tag, index) => (
                <div key={index} css={[styles.tag, { padding: '0 8px' }]} title={tag}>
                  <span css={styles.label}>{tag}</span>
                </div>
              ))}

              <Button
                variant="white"
                css={styles.plusButton}
                onClick={popupState.open}
                prefixIcon={{ icon: 'plus', size: 10 }}
              />
            </div>
          ) : undefined}
        </div>
      )}
    />
  );
};

const styles = {
  tag: css({
    maxWidth: 240,
    borderRadius: 3,
    padding: '4px 8px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
    background: '#EEF3F7',
    display: 'inline-block',
    boxSizing: 'border-box',
    textOverflow: 'ellipsis'
  }),
  addLabel: css({
    borderRadius: 3,
    fontWeight: 400,
    fontSize: '12px',
    color: '#3892E5',
    cursor: 'pointer',
    padding: '2px 8px',
    background: '#F6F8FA',
    ':hover': { opacity: 0.8 }
  }),
  label: css({
    flex: 1,
    fontSize: 12,
    overflow: 'hidden',
    lineHeight: '12px',
    paddingRight: '8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: THEME.text.colors.black.lv1
  }),
  plusButton: css({ width: '20px', height: '20px', padding: 0, minWidth: 'unset' })
};
