import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateSocialUsername.graphql';
import { Mutation, MutationupdateSocialUsernameArgs } from '../../__generated__/globalTypes';

export const useUpdateSocialUsernameMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateSocialUsernameArgs>
) => {
  const [callUpdateSocialUsername, result] = useMutation<Mutation, MutationupdateSocialUsernameArgs>(MUTATION, options);

  return { callUpdateSocialUsername, ...result };
};

export type UpdateSocialUsernameMutationFunction = Mutation['updateSocialUsername'];
