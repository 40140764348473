import { FilterProvider } from '@/shared/molecules';
import { useXhsInfluencersContext } from '../XhsInfluencersContext';
import { BasicFilter } from './BasicFilter';

export const XhsInfluencersFilter = () => {
  const { filter, setFilter, isEngagementPage, isPackagePage } = useXhsInfluencersContext();

  return (
    <FilterProvider
      onSubmit={setFilter}
      initialValues={filter}
      basicFilter={<BasicFilter isEngagementPage={isEngagementPage} isPackagePage={isPackagePage} />}
    />
  );
};
