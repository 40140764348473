import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  OperationVariables,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeAnalyticsCountriesCategories.graphql';
import { Query } from '../../__generated__/globalTypes';

export const useYoutubeAnalyticsCountriesCategoriesQuery = (options?: QueryHookOptions<Query>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeAnalyticsCountriesCategoriesLazyQuery = (
  options?: LazyQueryHookOptions<Query>
): QueryResult<Query> & {
  getYoutubeAnalyticsCountriesCategoriesQuery: LazyQueryExecFunction<Query, OperationVariables>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  // TODO: should be getYoutubeAnalyticsCountriesCategories
  const [getYoutubeAnalyticsCountriesCategoriesQuery, result] = useLazyQuery<Query>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeAnalyticsCountriesCategoriesQuery, ...result };
};

export const useYoutubeAnalyticsCountriesCategoriesPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeAnalyticsCountriesCategories = useCallback(
    (options?: Omit<QueryOptions<Record<string, unknown>, Query>, 'query'>) =>
      client.query<Query>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeAnalyticsCountriesCategories };
};
