import { CampaignSocialMediaType, NewCampaignStatsItemRow } from '@/graphql';

export enum PostReportSegmentKeys {
  post = 'post',
  total = 'total',
  influencer = 'influencer'
}

export type PostReportTableStatKeys =
  | keyof Omit<
      NewCampaignStatsItemRow,
      | 'net'
      | 'order'
      | 'profit'
      | 'salesTune'
      | 'profitRate'
      | 'costsPerOrder'
      | 'salesReferral'
      | 'costsPerSaleTune'
      | 'costsPerSaleReferral'
      | 'averageViewDurationSeconds'
    >
  | 'retweets' // retweets: custom field for twitter
  | 'insightDataAcquisition';

export interface PostReportStats
  extends Omit<Record<PostReportTableStatKeys, number | null>, 'insightDataAcquisition'> {
  insightDataAcquisition: string | null;
}

export interface PostReportDetails {
  id: number;
  url: string;
  content: string;
  thumbNail: string | null;
  hasTrafficSource: boolean | null;
  socialMedia: CampaignSocialMediaType;
}

export interface InfluencerPostReportStats {
  stats: PostReportStats;
  influencerDetails: {
    id: number;
    name: string;
    thumbNail: string;
    posts?: { stats: PostReportStats; details: PostReportDetails }[];
  };
}

// Segment section
export interface PostReportSegmentDataType {
  date: string;
  stats: PostReportStats;
}
export interface PostReportSegmentData {
  [PostReportSegmentKeys.total]: PostReportSegmentDataType[];
  [PostReportSegmentKeys.post]: { [id: string]: PostReportSegmentDataType[] } | null;
  [PostReportSegmentKeys.influencer]: { [id: string]: PostReportSegmentDataType[] } | null;
}
export interface PostReportSegment {
  [PostReportSegmentKeys.total]: { loading?: boolean; data: PostReportSegmentData['total'] };
  [PostReportSegmentKeys.post]?: { loading?: boolean; data: PostReportSegmentData['post'] };
  [PostReportSegmentKeys.influencer]?: { loading?: boolean; data: PostReportSegmentData['influencer'] };
}
