import { DouyinInfluencerProfile, DouyinInfluencers } from '@/shared/organisms';
import { useDefaultInfluencerSearchProps, useSocialInfluencersSelectButtonsProps } from './hooks';

export const EngagementDouyinInfluencersSearch = () => {
  const { isEngagementPage, isTtcmCampaign } = useDefaultInfluencerSearchProps();
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialInfluencersSelectButtonsProps<DouyinInfluencerProfile>({ socialMedia: 'DOUYIN' });

  return (
    <DouyinInfluencers
      isTtcmCampaign={isTtcmCampaign}
      isEngagementPage={isEngagementPage}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
