import { QueryHookOptions } from '@apollo/client';

import { Query, useAllAdvertisersForSearchQuery, QueryallAdvertisersForSearchArgs } from '@/graphql';
import { getOptions } from '@/shared/utils';

export const useAllAdvertisersForSearchOptions = (
  options?: QueryHookOptions<Query, QueryallAdvertisersForSearchArgs>
) => {
  const { data, loading } = useAllAdvertisersForSearchQuery({ fetchPolicy: 'cache-and-network', ...options });

  return {
    loading,
    allAdvertiserOptions: getOptions(data?.allAdvertisersForSearch.advertisers)
  };
};
