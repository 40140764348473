import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

export interface ChatBoxHeaderProps {
  className?: string;
  children: ReactNode;
  onClose?: () => void;
  onRefresh?: () => void;
}

export const ChatBoxHeader = ({ children, onRefresh, onClose, ...restProps }: ChatBoxHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper} {...restProps}>
      {onClose ? (
        <Tooltip help={t('Tooltip.Close')}>
          <div css={{ display: 'flex' }}>
            <Icon size={12} css={styles.icon} color={THEME.text.colors.white} icon="close-thin" onClick={onClose} />
          </div>
        </Tooltip>
      ) : null}

      <div css={styles.title}>{children}</div>

      {onRefresh ? (
        <Tooltip help={t('Tooltip.RefreshChat')}>
          <div css={{ display: 'flex' }}>
            <Icon size={16} icon="reset" css={styles.icon} onClick={onRefresh} color={THEME.text.colors.white} />
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

const styles = {
  wrapper: css({
    gap: '18px',
    flex: 'none',
    height: '48px',
    display: 'flex',
    padding: '0 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    backgroundColor: THEME.background.colors.black
  }),
  title: css({
    flex: 1,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '100%',
    overflow: 'hidden',
    fontStyle: 'normal',
    color: THEME.text.colors.white
  }),
  icon: css({
    display: 'block',
    cursor: 'pointer',
    '&:hover': { opacity: 0.8 }
  })
};
