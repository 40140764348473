import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Option } from '@/shared/types';
import { SocialPostType } from '@/graphql';
import { SocialIcon } from '@/shared/atoms';
import { useAllCountryOptions } from '@/shared/hooks';
import { PostsFilterFormValues } from '../schemaTypes';

// This hook make Posts search and ENG-Influencer posts search have the same behavior
export const usePostsFilterOptions = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<Pick<PostsFilterFormValues, 'socialMedia' | 'countryId'>>();
  const { allCountryOptions, filteredCountries } = useAllCountryOptions({
    hasAllCountryOption: true,
    filterFunction: (c) => c.value === 'CN'
  });

  const values = watch();
  const isCNCountrySupported =
    !!values.socialMedia && [SocialPostType.DOUYIN, SocialPostType.XHS].includes(values.socialMedia);

  useEffect(() => {
    if (isCNCountrySupported) {
      setValue('countryId', 'CN');
    }
  }, [values.socialMedia]);

  const socialMediaOptions: Option[] = [
    { label: t<string>('Facebook'), value: SocialPostType.FACEBOOK, prefixIcon: <SocialIcon type="FACEBOOK" /> },
    {
      label: t<string>('Facebook Page'),
      value: SocialPostType.FACEBOOK_PAGE,
      prefixIcon: <SocialIcon type="FACEBOOK" />
    },
    { label: t<string>('Instagram'), value: SocialPostType.INSTAGRAM, prefixIcon: <SocialIcon type="INSTAGRAM" /> },
    {
      label: t<string>('Instagram Story'),
      value: SocialPostType.INSTAGRAM_STORY,
      prefixIcon: <SocialIcon type="INSTAGRAM_STORY" />
    },
    { label: t<string>('X(Twitter)'), value: SocialPostType.TWITTER, prefixIcon: <SocialIcon type="TWITTER" /> },
    { label: t<string>('Youtube'), value: SocialPostType.YOUTUBE, prefixIcon: <SocialIcon type="YOUTUBE" /> },
    { label: t<string>('TikTok'), value: SocialPostType.TIKTOK, prefixIcon: <SocialIcon type="TIKTOK" /> },
    { label: t<string>('Douyin'), value: SocialPostType.DOUYIN, prefixIcon: <SocialIcon type="DOUYIN" /> },
    { label: t<string>('Threads'), value: SocialPostType.THREADS, prefixIcon: <SocialIcon type="THREADS" /> },
    { label: t<string>('Xiaohongshu'), value: SocialPostType.XHS, prefixIcon: <SocialIcon type="XHS" /> }
  ];

  return {
    socialMediaOptions,
    isCNCountrySupported,
    allCountryOptions: isCNCountrySupported ? filteredCountries : allCountryOptions
  };
};
