import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { TikTokSpecialCampaignPost, TikTokSpecialCampaignPostStatus, TikTokSpecialCampaignStatus } from '@/graphql';
import { BlackBar, Button } from '@/shared/atoms';
import { useSelectItems, useToggleState } from '@/shared/hooks';
import { SingleSelector } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { useUpdateTikTokPostsStatus } from './hooks';

interface ActionBarProps
  extends ReturnType<typeof useSelectItems<TikTokSpecialCampaignPost, keyof TikTokSpecialCampaignPost>> {
  status: TikTokSpecialCampaignStatus;
  campaignId: number;
}

export const ActionBar = ({
  status,
  allItemIds,
  campaignId,
  selectedItems,
  selectedItemIds,
  setSelectedItemIds,
  hasAllItemsSelected,
  toggleSelectAllItems
}: ActionBarProps) => {
  const { t } = useTranslation();
  const loadingState = useToggleState();
  const { isAdminStaff } = useUserRoles();
  const { handleSelectStatus, UpdateTikTokSpecialPostStatusModal } = useUpdateTikTokPostsStatus(campaignId);

  const numberSelectedIds = selectedItemIds.length;
  const options = [
    {
      label: t<string>('Option.Approve post'),
      value: TikTokSpecialCampaignPostStatus.APPROVED,
      hidden: status !== TikTokSpecialCampaignStatus.FINISHED || !isAdminStaff
    },
    {
      label: t<string>('Option.Request to modify'),
      value: TikTokSpecialCampaignPostStatus.WARNING
    },
    {
      label: t<string>('Option.Change to “Effective”'),
      value: TikTokSpecialCampaignPostStatus.WARNING_SOLVED
    },
    {
      hidden: !isAdminStaff,
      label: t<string>('Option.Mark as “Ineffective”'),
      value: TikTokSpecialCampaignPostStatus.INEFFECTIVE
    }
  ].filter((option) => !option.hidden) as Option<TikTokSpecialCampaignPostStatus>[];

  return numberSelectedIds > 0 ? (
    <>
      <BlackBar
        disabled={loadingState.status}
        selectAllProps={{ onClick: toggleSelectAllItems }}
        checkboxProps={
          allItemIds.length
            ? {
                onClick: toggleSelectAllItems,
                indeterminate: !hasAllItemsSelected,
                label: t('Selected', { count: numberSelectedIds })
              }
            : undefined
        }
      >
        <SingleSelector
          name="status"
          options={options}
          disabled={loadingState.status}
          css={{ width: '180px', marginLeft: '16px' }}
          onChange={(value) => handleSelectStatus(value as TikTokSpecialCampaignPostStatus, selectedItems)}
          hideDeselectOption
        />
        <Button variant="blue" title={t('Button.Save')} type="submit" css={{ marginLeft: 'auto', padding: '0 16px' }} />
      </BlackBar>

      <UpdateTikTokSpecialPostStatusModal onSuccess={() => setSelectedItemIds([])} />
    </>
  ) : null;
};
