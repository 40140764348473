import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Button, CheckBox, Popover } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useSelectItemIds } from '@/shared/hooks';
import { AnalyticsFacebookDashboardLegendType, AnalyticsFacebookDashboardGraphKeys as Keys } from '../types';
import { getAllLegendOptions, getDropdownLegendKeys } from '../utils';

interface Props {
  isInfluencerProfile?: boolean;
}
export const useAnalyticsFacebookDashboardOverviewLegend = ({ isInfluencerProfile }: Props) => {
  const { t } = useTranslation();
  const { setSelectedItemIds: setSelectedDropdownLegendKeys, selectedItemIds: selectedDropdownLegendKeys } =
    useSelectItemIds<Keys>();
  const {
    selectItem: setSelectingDropdownLegendKey,
    selectedItemIds: selectingDropdownLegendKeys,
    setSelectedItemIds: setSelectingDropdownLegendKeys
  } = useSelectItemIds<Keys>();

  const allLegendOptions = getAllLegendOptions(isInfluencerProfile);
  const dropdownLegendKeys = getDropdownLegendKeys(isInfluencerProfile);
  const legendOptions: AnalyticsFacebookDashboardLegendType[] = allLegendOptions.map((option) => ({
    ...option,
    label: option.label ? t<string>(option.label) : ''
  }));
  const availableLegendOptions = legendOptions.filter(
    ({ value }) => !dropdownLegendKeys.includes(value) || selectedDropdownLegendKeys.includes(value)
  );

  return {
    availableLegendOptions,
    ButtonSelectMoreLegendOptions: (
      <Popover
        onClose={() => setSelectingDropdownLegendKeys(selectedDropdownLegendKeys)}
        position="bottom-end"
        target={({ togglePopover }) => (
          <Button
            variant="default"
            onClick={togglePopover}
            prefixIcon={{ icon: 'plus', size: 12 }}
            css={{
              marginLeft: isInfluencerProfile ? '12px' : 'auto',
              backgroundColor: THEME.background.colors.gray.lv1
            }}
          />
        )}
      >
        {({ togglePopover }) => (
          <div css={{ minWidth: '248px' }}>
            <div css={{ padding: '8px' }}>
              {dropdownLegendKeys.map((key) => (
                <CheckBox
                  key={key}
                  css={styles.checkbox}
                  onClick={() => setSelectingDropdownLegendKey(key)}
                  checked={selectingDropdownLegendKeys.includes(key)}
                  label={legendOptions.find((item) => item.value === key)?.label}
                />
              ))}
            </div>
            <div css={{ padding: '8px', borderTop: THEME.border.base }}>
              <Button
                variant="blue"
                title={t('Button.Save')}
                css={{ minWidth: '66px', height: '24px', marginLeft: 'auto', textTransform: 'uppercase' }}
                onClick={() => {
                  setSelectedDropdownLegendKeys(selectingDropdownLegendKeys);
                  togglePopover();
                }}
              />
            </div>
          </div>
        )}
      </Popover>
    )
  };
};
const styles = {
  checkbox: css({ '&:not(:first-of-type)': { marginTop: '16px' } })
};
