import { Outlet } from '@tanstack/react-router';
import { useUserProposalQuery } from '@/graphql';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { ButtonAddInfluencer, stickyInfluencersSearchHeader, UserProposalProvider } from '@/shared/organisms';
import { InfluencersTabs } from './InfluencersTabs';

export const InfluencersRoot = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'documentTitle.Influencer', appHeader: 'appHeader.Influencer', href: '' });
  const { data, loading } = useUserProposalQuery();

  return (
    <>
      <div css={stickyInfluencersSearchHeader}>
        <Header>
          <ButtonAddInfluencer css={{ marginLeft: 'auto' }} />
        </Header>
        <InfluencersTabs />
      </div>
      <UserProposalProvider value={{ userProposal: data?.userProposal, loading }}>
        <Outlet />
      </UserProposalProvider>
    </>
  );
};
