import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { DouyinInfluencer, SocialAccountInfluencerListSortFieldV2, SocialAccountType } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
import { PostType, InfluencersFilterSchema, DefaultInfluencersProps, InfluencersBaseSearchSchema } from '../types';
import { getInitialDouyinInfluencersFormValues } from './utils';

export const DouyinInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  includeIds: true,
  ttcmStatus: true,
  countryIds: true,
  categoryIds: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  minEngagementViewsRate: true,
  maxEngagementViewsRate: true,
  minEngagementFollowersRate: true,
  maxEngagementFollowersRate: true,
  talentAgencyOrPartnerIds: true,
  // audience section is different from other SM
  demographicAge: true,
  demographicGender: true,
  demographicAgeMinRate: true,
  demographicGenderMinRate: true
});
export const DouyinInfluencersSearchSchema = z.object({
  filter: DouyinInfluencersFilterSchema.default(getInitialDouyinInfluencersFormValues()),
  ...SearchFilterDefaults.pageLimit(),
  sort: SearchFilterDefaults.sort(SocialAccountInfluencerListSortFieldV2).default({}),
  state: InfluencersBaseSearchSchema.shape.state
});

export type DouyinInfluencersSearchSchemaType = z.infer<typeof DouyinInfluencersSearchSchema>;
export type DouyinInfluencersFilterFormValues = z.infer<typeof DouyinInfluencersFilterSchema>;
export type DouyinInfluencersFilterFormKeys = UnionOfKeys<DouyinInfluencersFilterFormValues>;
export type DouyinInfluencersProps = DefaultInfluencersProps<DouyinInfluencerProfile>;
export type DouyinInfluencerProfile = {
  posts: PostType[];
  socialAccountType: SocialAccountType;
} & Pick<
  DouyinInfluencer,
  | 'id'
  | 'age'
  | 'name'
  | 'avatar'
  | 'gender'
  | 'country'
  | 'averageLikes'
  | 'averageViews'
  | 'socialAccountId'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementViewsRate'
  | 'socialAccountEngagementFollowersRate'
>;
