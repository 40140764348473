import { DraggableSyntheticListeners } from '@dnd-kit/core';
import React, { createContext, useContext } from 'react';

interface ContextType {
  listeners: DraggableSyntheticListeners;
  attributes: NonNullable<unknown>;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<ContextType>({
  ref: () => null,
  attributes: {},
  listeners: undefined
});

export const useSortableItemContext = () => useContext(SortableItemContext);

interface PostReportProviderProps {
  value: ContextType;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const SortableItemProvider = ({ value, children }: PostReportProviderProps) => (
  <SortableItemContext.Provider value={value}>{children}</SortableItemContext.Provider>
);
