import { isAfter } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { DecodedTokenShape } from '@/shared/types';
import { manageLocalStorage } from './localStorage';

export const setToken = (token: string) => {
  if (window?.localStorage) {
    manageLocalStorage('set', 'accessToken', token);
  }
};
export const getToken = () => manageLocalStorage('get', 'accessToken');
export const removeToken = () => manageLocalStorage('remove', 'accessToken');
export const checkSignedIn = () => {
  const token = getToken();
  if (!token) {
    return false;
  }

  try {
    const decoded = decodeAuthToken(token);

    return decoded ? isAfter(decoded.exp * 1000, new Date()) : false;
  } catch (e) {
    removeToken();

    return false;
  }
};
export const getAuthHeaders = () => {
  const token = getToken();

  const authHeaders = new Headers();
  authHeaders.append('Authorization', `${token}`);

  return authHeaders;
};
export const decodeAuthToken = (token: string) => {
  let decoded;

  try {
    decoded = jwtDecode<DecodedTokenShape>(token);
  } catch (e) {
    console.error('Invalid token provided', e);
  }

  return decoded;
};
