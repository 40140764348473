import { useTranslation } from 'react-i18next';
import { useInfluencerCampaignListByPromotionMethodQuery } from '@/graphql';
import { Table } from '@/shared/atoms';
import { useCampaignsListTable } from './hooks';

interface CampaignsSummaryProps {
  influencerId: number;
  socialAccountId: number;
}
export const CampaignsList = ({ influencerId, socialAccountId }: CampaignsSummaryProps) => {
  const { t } = useTranslation();
  const { data, loading } = useInfluencerCampaignListByPromotionMethodQuery({
    fetchPolicy: 'network-only',
    variables: { influencerId, promotionMethodId: socialAccountId }
  });
  const { rows, columns } = useCampaignsListTable({ data: data?.influencerCampaignListByPromotionMethod || [] });

  return (
    <Table
      loading={loading}
      data={{ rows, columns, rowHeight: '70px' }}
      empty={{ hideDescription: true, title: t('No campaigns has been found') }}
      noTopBorderRadius
      hasBorder
    />
  );
};
