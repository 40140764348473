import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/GenerateUploadReportSignedUrl.graphql';
import { Query, QuerygenerateUploadReportSignedUrlArgs } from '../../__generated__/globalTypes';

export const useGenerateUploadReportSignedUrlQuery = (
  options?: QueryHookOptions<Query, QuerygenerateUploadReportSignedUrlArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerygenerateUploadReportSignedUrlArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useGenerateUploadReportSignedUrlLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerygenerateUploadReportSignedUrlArgs>
): QueryResult<Query, QuerygenerateUploadReportSignedUrlArgs> & {
  getGenerateUploadReportSignedUrl: LazyQueryExecFunction<Query, QuerygenerateUploadReportSignedUrlArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getGenerateUploadReportSignedUrl, result] = useLazyQuery<Query, QuerygenerateUploadReportSignedUrlArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getGenerateUploadReportSignedUrl, ...result };
};

export const useGenerateUploadReportSignedUrlPromiseQuery = () => {
  const client = useApolloClient();

  const getGenerateUploadReportSignedUrl = useCallback(
    (options?: Omit<QueryOptions<QuerygenerateUploadReportSignedUrlArgs, Query>, 'query'>) =>
      client.query<Query, QuerygenerateUploadReportSignedUrlArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getGenerateUploadReportSignedUrl };
};
