import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from '@/shared/atoms';
import { CampaignPostSortField } from '@/graphql';
import { ColumnCustomiser } from '@/shared/molecules';
import {
  PostDiscoveryMetric,
  PostDiscoveryStatsKey,
  getOrderSelectedColumn,
  defaultPostDiscoveryMetrics
} from '../utils';

export type PostDiscoveryColumnProps = ColumnProps<CampaignPostSortField, PostDiscoveryStatsKey>;

export const useAllCampaignPostsListColumns = () => {
  const { t } = useTranslation();
  const [selectedColumns, setSelectedColumns] = useState<PostDiscoveryStatsKey[]>(defaultPostDiscoveryMetrics);

  const getMetricColumnProps = ({
    key,
    hidden,
    styles,
    ...columnProps
  }: PostDiscoveryColumnProps): PostDiscoveryColumnProps => ({
    ...columnProps,
    key,
    styles: { textAlign: 'right', ...styles },
    hidden: hidden || (key && !selectedColumns.includes(key))
  });

  const postDiscoveryMetrics: PostDiscoveryMetric[] = [
    {
      key: 'cost',
      title: t('HeaderColumn.Cost'),
      sortField: CampaignPostSortField.COST
    },
    {
      key: 'followers',
      title: t('HeaderColumn.Follower'),
      sortField: CampaignPostSortField.FOLLOWERS
    },
    {
      key: 'impressions',
      title: t('HeaderColumn.Impression'),
      sortField: CampaignPostSortField.IMPRESSIONS
    },
    {
      key: 'impressionsRate',
      title: t('HeaderColumn.Impression Rate'),
      sortField: CampaignPostSortField.IMPRESSIONS_RATE
    },
    {
      key: 'impressionsFromDiscovery',
      title: t('HeaderColumn.Impression from Discovery'),
      sortField: CampaignPostSortField.IMPRESSIONS_FROM_DISCOVERY
    },
    {
      key: 'impressionsFromHashtag',
      title: t('HeaderColumn.Impression from Hashtag'),
      sortField: CampaignPostSortField.IMPRESSIONS_FROM_HASHTAG
    },
    {
      key: 'impressionsFromHome',
      title: t('HeaderColumn.Impression from Home'),
      sortField: CampaignPostSortField.IMPRESSIONS_FROM_HOME
    },
    {
      key: 'impressionsFromProfile',
      title: t('HeaderColumn.Impression from Profile'),
      sortField: CampaignPostSortField.IMPRESSIONS_FROM_PROFILE
    },
    {
      key: 'impressionsFromOther',
      title: t('HeaderColumn.Impression from Other'),
      sortField: CampaignPostSortField.IMPRESSIONS_FROM_OTHER
    },
    {
      key: 'impressionsClickThroughRate',
      title: t('HeaderColumn.Impressions Click Through Rate'),
      sortField: CampaignPostSortField.IMPRESSIONS_CLICK_THROUGH_RATE
    },
    {
      key: 'totalPlayTime',
      title: t('HeaderColumn.Total play time'),
      sortField: CampaignPostSortField.TOTAL_PLAY_TIME
    },
    {
      key: 'averageViewTime',
      title: t('HeaderColumn.Average view time'),
      sortField: CampaignPostSortField.AVERAGE_VIEW_TIME
    },
    {
      key: 'videoCompletionRate',
      title: t('HeaderColumn.Video completion rate'),
      sortField: CampaignPostSortField.VIDEO_COMPLETION_RATE
    },
    {
      key: 'views',
      title: t('HeaderColumn.Video view'),
      sortField: CampaignPostSortField.VIEWS
    },
    {
      key: 'averageViewDurationSeconds',
      title: t('HeaderColumn.Average View Duration (m)'),
      sortField: CampaignPostSortField.AVERAGE_VIEW_DURATION_SECONDS
    },
    {
      key: 'averageViewDurationPercentage',
      title: t('HeaderColumn.Average View Duration Rate'),
      sortField: CampaignPostSortField.AVERAGE_VIEW_DURATION_PERCENTAGE
    },
    {
      key: 'reach',
      title: t('HeaderColumn.Reach'),
      sortField: CampaignPostSortField.REACH
    },
    {
      key: 'reachRate',
      title: t('HeaderColumn.Reach Rate'),
      sortField: CampaignPostSortField.REACH_RATE
    },
    {
      key: 'nonFollowerReach',
      title: t('HeaderColumn.Non Follower Reach'),
      sortField: CampaignPostSortField.NON_FOLLOWER_REACH
    },
    {
      key: 'nonFollowerReachRate',
      title: t('HeaderColumn.Non Follower Reach Rate'),
      sortField: CampaignPostSortField.NON_FOLLOWER_REACH_RATE
    },
    {
      key: 'engagement',
      title: t('HeaderColumn.Engagement'),
      sortField: CampaignPostSortField.ENGAGEMENT
    },
    {
      key: 'engagementFollowersRate',
      title: t('HeaderColumn.Egg Rate by followers'),
      sortField: CampaignPostSortField.ENGAGEMENT_FOLLOWERS_RATE
    },
    {
      key: 'engagementViewsRate',
      title: t('HeaderColumn.Egg Rate by views'),
      sortField: CampaignPostSortField.ENGAGEMENT_VIEWS_RATE
    },
    {
      key: 'likes',
      title: t('HeaderColumn.Like'),
      sortField: CampaignPostSortField.LIKES
    },
    {
      key: 'dislikes',
      title: t('HeaderColumn.Dislike'),
      sortField: CampaignPostSortField.DISLIKES
    },
    {
      key: 'comments',
      title: t('HeaderColumn.Comment'),
      sortField: CampaignPostSortField.COMMENTS
    },
    {
      key: 'shares',
      title: t('HeaderColumn.Share'),
      sortField: CampaignPostSortField.SHARES
    },
    {
      key: 'saved',
      title: t('HeaderColumn.Save'),
      sortField: CampaignPostSortField.SAVED
    },
    {
      key: 'interaction',
      title: t('HeaderColumn.Interaction'),
      sortField: CampaignPostSortField.INTERACTION
    },
    {
      key: 'linkClicks',
      title: t('HeaderColumn.Link Clicks'),
      sortField: CampaignPostSortField.LINK_CLICKS
    },
    {
      key: 'stickerTaps',
      title: t('HeaderColumn.Sticker Taps'),
      sortField: CampaignPostSortField.STICKER_TAPS
    },
    {
      key: 'clicks',
      title: t('HeaderColumn.Click'),
      sortField: CampaignPostSortField.CLICKS
    },
    {
      key: 'conversions',
      title: t('HeaderColumn.Conversion'),
      sortField: CampaignPostSortField.CONVERSIONS
    },
    {
      key: 'revenue',
      title: t('HeaderColumn.Revenue'),
      sortField: CampaignPostSortField.REVENUE
    },
    {
      key: 'orders',
      title: t('HeaderColumn.Orders'),
      sortField: CampaignPostSortField.ORDERS
    },
    {
      key: 'sales',
      title: t('HeaderColumn.Sales'),
      sortField: CampaignPostSortField.SALES
    },
    {
      key: 'salesCost',
      title: t('HeaderColumn.Sales Cost'),
      sortField: CampaignPostSortField.SALES_COST
    },
    {
      key: 'salesReferral',
      title: t('HeaderColumn.Sales (Referral)'),
      sortField: CampaignPostSortField.SALES_REFERRAL
    },
    {
      key: 'femalePercentage',
      title: t('HeaderColumn.Female Rate'),
      sortField: CampaignPostSortField.FEMALE_PERCENTAGE
    },
    {
      key: 'malePercentage',
      title: t('HeaderColumn.Male Rate'),
      sortField: CampaignPostSortField.MALE_PERCENTAGE
    },
    {
      key: 'age18to24Percentage',
      title: t('HeaderColumn.Age (18-24)'),
      sortField: CampaignPostSortField.AGE_18_TO_24_PERCENTAGE
    },
    {
      key: 'age25to34Percentage',
      title: t('HeaderColumn.Age (25-34)'),
      sortField: CampaignPostSortField.AGE_25_TO_34_PERCENTAGE
    },
    {
      key: 'age35upPercentage',
      title: t('HeaderColumn.Age (35+)'),
      sortField: CampaignPostSortField.AGE_35_UP_PERCENTAGE
    },
    {
      key: 'back',
      title: t('HeaderColumn.Back'),
      sortField: CampaignPostSortField.BACK
    },
    {
      key: 'exited',
      title: t('HeaderColumn.Exited'),
      sortField: CampaignPostSortField.EXITED
    },
    {
      key: 'forward',
      title: t('HeaderColumn.Forward'),
      sortField: CampaignPostSortField.FORWARD
    },
    {
      key: 'nextStory',
      title: t('HeaderColumn.Next Story'),
      sortField: CampaignPostSortField.NEXT_STORY
    },
    {
      key: 'navigation',
      title: t('HeaderColumn.Navigation'),
      sortField: CampaignPostSortField.NAVIGATION
    },
    {
      key: 'profileActivity',
      title: t('HeaderColumn.Profile Activity'),
      sortField: CampaignPostSortField.PROFILE_ACTIVITY
    },
    {
      key: 'audienceRetention',
      title: t('HeaderColumn.Audience Retention'),
      sortField: CampaignPostSortField.AUDIENCE_RETENTION
    }
  ];

  const metricColumns: PostDiscoveryColumnProps[] = postDiscoveryMetrics.map(getMetricColumnProps);
  const selectableColumnOptions = postDiscoveryMetrics.reduce<{ label: string; value: PostDiscoveryStatsKey }[]>(
    (rs, cr) => (cr.hidden ? rs : [...rs, { label: cr.title as string, value: cr.key as PostDiscoveryStatsKey }]),
    []
  );

  const handleSelectColumns = (newSelectedColumns: PostDiscoveryStatsKey[]) => {
    setSelectedColumns(getOrderSelectedColumn(postDiscoveryMetrics, newSelectedColumns));
  };

  const postTitleColumn: PostDiscoveryColumnProps = {
    title: (
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {t('HeaderColumn.Total Post')}
        <div css={{ display: 'flex', gap: '8px' }}>
          <ColumnCustomiser<PostDiscoveryStatsKey>
            options={selectableColumnOptions}
            defaultOptions={selectedColumns}
            savedValuesKey="postDiscoveryTableColumns"
            initialValues={defaultPostDiscoveryMetrics}
            setSelectedColumns={handleSelectColumns}
            position="right-start"
            buttonProps={{
              tooltip: {
                help: <span css={{ fontSize: '12px' }}>{t('Customize metrics')}</span>,
                position: 'top'
              }
            }}
          />
        </div>
      </div>
    ),
    sticky: { active: true, hasBorderRight: true },
    hidden: false,
    styles: { width: '396px' }
  };

  return { selectedColumns, columns: [postTitleColumn, ...metricColumns] };
};
