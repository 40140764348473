import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { AppLanguage } from '@/shared/types';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar } from '@/shared/molecules';
import { YoutubeAnalyticsTrendPayload } from '@/graphql';
import { formatIntNumber, localizedDateFormatter, formatDate } from '@/shared/utils';
import { useGenerateInfluencerProfileLink, usePostDetailsParam } from '@/shared/hooks';
import { Anchor, ColumnProps, RowProps, MediaPreview, TextCutter, TableCell } from '@/shared/atoms';
import { useAnalyticsTrendingYoutubePostsFilter } from '../../AnalyticsTrendingYoutubePostsFilter';

interface Props {
  data: readonly YoutubeAnalyticsTrendPayload[];
}

export const useAnalyticsTrendingYoutubePostsTable = ({ data }: Props) => {
  const { t, i18n } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();
  const { page, limit } = useAnalyticsTrendingYoutubePostsFilter();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Post') },
    { title: t('HeaderColumn.Channel') },
    { title: t('HeaderColumn.View'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Comments'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Post date') }
  ];
  const rows: RowProps[] = data.map(
    (
      {
        id,
        views,
        channel,
        postDate,
        comments,
        videoTitle,
        videoCaption,
        influencerId,
        channelAvatar,
        videoThumbnail,
        socialAccountId
      },
      index
    ) => {
      const rank = page && limit ? (page - 1) * limit + index + 1 : 0;
      const parsedDate = postDate?.replace('Z', '') || null;

      return [
        <div css={{ maxWidth: '500px', display: 'flex', alignItems: 'center', padding: '8px 0', position: 'relative' }}>
          <div css={[styles.rank, { backgroundColor: rank < 4 ? '#FF2B52' : '#27313B' }]}>{rank}</div>
          <MediaPreview
            src={videoThumbnail}
            onClick={() => setPostDetailsParam(id)}
            css={{ width: '184px', height: '104px' }}
          />
          <div css={{ marginLeft: '8px' }}>
            <Anchor
              onClick={(e) => {
                e.preventDefault();
                setPostDetailsParam(id);
              }}
              label={videoTitle}
            />
            <TextCutter text={videoCaption} lines={4} css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3 }} />
          </div>
        </div>,
        <TableCell.Link
          css={{ maxWidth: '200px', minWidth: '100px', display: 'flex', alignItems: 'center' }}
          {...generateProfileLink(
            influencerId?.toString(),
            socialAccountId ? { filter: { id: String(socialAccountId), sm: 'YOUTUBE' } } : undefined
          )}
        >
          <InfluencerAvatar size="32px" src={channelAvatar} css={{ borderRadius: '50%' }} asChild />
          <TableCell.Text value={channel} css={{ marginLeft: '8px' }} />
        </TableCell.Link>,
        <TableCell.Number css={{ minWidth: '100px' }} value={formatIntNumber(views)} />,
        <TableCell.Number css={{ minWidth: '100px' }} value={formatIntNumber(comments)} />,
        <TableCell.Text
          css={{ minWidth: '150px' }}
          value={`${localizedDateFormatter(parsedDate, 'MMM dd', i18n.language as AppLanguage)} at ${formatDate(
            parsedDate,
            'hh:mm a'
          )}`}
        />
      ];
    }
  );

  return { rows, columns };
};
const styles = {
  rank: css({
    top: 0,
    left: 0,
    zIndex: 1,
    width: '24px',
    height: '24px',
    display: 'flex',
    fontSize: '16px',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    color: THEME.text.colors.white
  })
};
