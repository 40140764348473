import axios from 'axios';
import { useState, ReactNode } from 'react';
import { Accept } from 'react-dropzone';
import { SerializedStyles, css } from '@emotion/react';
import { useQueryHelper } from '@/shared/hooks';
import { VIDEO_UPLOAD_LIMIT, DEFAULT_UPLOAD_LIMIT, THEME } from '@/shared/constants';
import { Preview } from './Preview';
import { EmptyPreview } from './EmptyPreview';

interface GeneratedSignedUrl {
  fileName: string;
  signedUrl: string;
}

export interface DragAndDropProps {
  name: string;
  value: string[];
  accept?: Accept;
  notes?: string[];
  hasError?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
  itemsPerRow?: number;
  customLabel?: ReactNode;
  maxUploadedFiles?: number;
  previewCss?: SerializedStyles;
  emptyPreviewCss?: SerializedStyles;
  setIsFileUploading?: (isUploading: boolean) => void;
  onChange?: (name: string, values: string[]) => void;
  generateSignedUrl: (fileNames: string[]) => Promise<readonly GeneratedSignedUrl[] | null>;
}

export const DragAndDrop = ({
  name,
  value,
  notes,
  accept,
  hasError,
  onChange,
  disabled,
  className,
  previewCss,
  itemsPerRow,
  emptyPreviewCss,
  multiple = false,
  generateSignedUrl,
  setIsFileUploading,
  maxUploadedFiles = 10
}: DragAndDropProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [uploadProgress, setUploadProgress] = useState<{ progressUrl: string | null; progress: number }>({
    progressUrl: null,
    progress: 0
  });
  const uploadedFiles = value.map((url) => ({ url, preview: url }));

  const uploadFileToGCS = async (generatedSignedUrls: readonly GeneratedSignedUrl[], files: File[]) => {
    if (!generatedSignedUrls.length || !files.length || generatedSignedUrls.length !== files.length) {
      return;
    }

    if (setIsFileUploading) {
      setIsFileUploading(true);
    }

    try {
      await Promise.all(
        generatedSignedUrls.map(({ signedUrl, fileName }) => {
          const videoUrlName = signedUrl.split('?').at(0) || '';
          const file = files.find((f) => f.name === fileName);

          if (!file) {
            return null;
          }

          return axios(signedUrl, {
            data: file,
            method: 'PUT',
            onUploadProgress: (p) => {
              setUploadProgress({ progress: (p.loaded / (p.total || 1)) * 100, progressUrl: videoUrlName });
            }
          });
        })
      );

      const fileNameUrls = generatedSignedUrls.map(({ signedUrl }) => signedUrl.split('?').at(0) || '');
      onChange?.(name, multiple ? [...value, ...fileNameUrls] : fileNameUrls);
    } catch (err) {
      enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });
    } finally {
      setUploadProgress({ progress: 100, progressUrl: null });
    }

    setIsFileUploading?.(false);
  };

  const onDrop = async (files: File[]) => {
    if (!files.length) {
      return;
    }

    if (uploadedFiles.length + files.length > maxUploadedFiles) {
      enqueueSnackbar(t('Upload maximum files', { count: maxUploadedFiles }), { variant: 'error' });

      return;
    }

    const fileError = files.find((file) => {
      const isVideoFile = file && ['video/mp4', 'video/quicktime'].includes(file.type);
      const sizeLimit = isVideoFile ? VIDEO_UPLOAD_LIMIT : DEFAULT_UPLOAD_LIMIT;

      return file && file.size > sizeLimit;
    });

    if (fileError) {
      enqueueSnackbar(t('General.UploadSizeError'), { variant: 'error' });
      console.error('Over size: ', fileError.name);

      return;
    }

    const fileNames = files.map((file) => file.name);
    const generatedSignedUrl = await generateSignedUrl(fileNames);

    if (generatedSignedUrl?.length) {
      await uploadFileToGCS(generatedSignedUrl, files);
    }
  };

  const deleteUploadedFile = (index: number) => {
    const newMaterialsValue = value.filter((materialUrl) => materialUrl !== uploadedFiles[index]?.url);

    onChange?.(name, newMaterialsValue);
  };

  return (
    <div className={className} css={[styles.wrapper, hasError && { borderColor: THEME.input.border.colors.error }]}>
      {uploadedFiles.length === 0 ? (
        <EmptyPreview
          name={name}
          notes={notes}
          accept={accept}
          onDrop={onDrop}
          multiple={multiple}
          disabled={disabled}
          css={emptyPreviewCss}
        />
      ) : (
        <Preview
          name={name}
          accept={accept}
          onDrop={onDrop}
          css={previewCss}
          disabled={disabled}
          multiple={multiple}
          onChange={onChange}
          itemsPerRow={itemsPerRow}
          onDelete={deleteUploadedFile}
          uploadedFiles={uploadedFiles}
          uploadProgress={uploadProgress}
          maxUploadedFiles={maxUploadedFiles}
        />
      )}
    </div>
  );
};
const styles = {
  wrapper: css({
    borderRadius: '2px',
    background: '#f6f8fa',
    boxSizing: 'border-box',
    border: '2px dashed #dee5ec'
  })
};
