import { LinkProps } from '@tanstack/react-router';
import { useAuthData, usePermissions } from '@/auth';
import { UserRoles } from '@/graphql';
import { getAllMenuItems, getAllowMenuTitlesFromPermission } from '@/shared/utils';

type AppRoute = (typeof defaultRoute)[number];

const defaultRoute = [
  '/influencers',
  '/report',
  '/marketplace',
  '/engagement',
  '/auto-managed',
  '/tiktok/ad-campaign-report',
  '/campaign-posts-discovery'
] as const satisfies LinkProps['to'][];

export const useDefaultAppRoute = () => {
  const {
    auth: { role, company }
  } = useAuthData();
  const { permissions } = usePermissions();

  const defaultRoute = ((): AppRoute => {
    switch (role) {
      case UserRoles.TALENT_AGENCY:
        return '/influencers';

      case UserRoles.PARTNER: {
        const allowedRoutes = getAllowMenuTitlesFromPermission(permissions);
        const flattenAllMenuItems = getAllMenuItems({ isAdvertiser: false })
          .map((item) => item.subMenus || item)
          .flat();
        const partnerRootRoute = flattenAllMenuItems.find((item) => item?.title === allowedRoutes[0])?.to || '/report';

        return partnerRootRoute as AppRoute; // it is hard to narrow it better, but it is safe to use as AppRoute because MenuType is protected
      }

      case UserRoles.ADVERTISER:
        return company === 'TIKTOK' ? '/tiktok/ad-campaign-report' : '/report';
      default:
        return '/report';
    }
  })();

  return { defaultRoute };
};
