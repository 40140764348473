import { zodValidator } from '@tanstack/zod-adapter';
import { PostsInsightSearchFilterSchema } from '@/shared/organisms';
import { PostInsightDetailsSearchFilterSchema, PostsInsightDetails } from '@/pages/PostsInsight/PostInsightDetails';
import { PostsInsight } from '@/pages/PostsInsight';
import { adminStaffAgencyPartnerAdvertiserRoles } from '@/shared/constants';
import { PermissionRoute } from '@/app-components';
import { PartialRouter } from '../../types';

export const PostsInsightRouter = {
  '/_private-routes/posts-insight': [
    () => <PermissionRoute permissions={adminStaffAgencyPartnerAdvertiserRoles} />,
    {}
  ],
  '/_private-routes/posts-insight/': [PostsInsight, { validateSearch: zodValidator(PostsInsightSearchFilterSchema) }],
  '/_private-routes/posts-insight/$id': [
    PostsInsightDetails,
    { validateSearch: zodValidator(PostInsightDetailsSearchFilterSchema) }
  ],
  '/_private-routes/posts-insight/$id/$postId': [
    PostsInsightDetails,
    { validateSearch: zodValidator(PostInsightDetailsSearchFilterSchema) }
  ]
} as const satisfies PartialRouter;
