import { useEffect } from 'react';
import { ReconnectAnalyticsAccountType } from '@/shared/types';
import { manageLocalStorage } from '@/shared/utils';
import { useGetSocialMediaRedirectUrl, useQueryHelper, useSelectItem, useToggle } from '@/shared/hooks';
import { getAnalyticsListReconnectState, setAnalyticsListReconnectState } from '../utils';
import { useAnalyticsFilter } from '../../AnalyticsFilter';

export const useAnalyticsReconnectAccount = () => {
  const reconnectState = useToggle();
  const { on: reconnected, toggle: toggleReconnected } = useToggle();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { page, filter } = useAnalyticsFilter();
  const { getSocialMediaRedirectUrl } = useGetSocialMediaRedirectUrl({
    redirectType: 'ANALYTICS_LIST_CONNECT',
    query: 'AnalyticsSocialAuthRedirectUrl'
  });
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem<ReconnectAnalyticsAccountType>();

  const handleReconnectAccount = async () => {
    if (!selectedItem) return;

    reconnectState.setOn();
    const redirectUrl = await getSocialMediaRedirectUrl(selectedItem.socialAccountType);
    if (redirectUrl) {
      window.location.href = redirectUrl;

      manageLocalStorage(
        'set',
        'analyticsReconnectFromListState_json',
        setAnalyticsListReconnectState('stringify', {
          params: { page, filter, reconnectingAccount: selectedItem }
        })
      );
    } else {
      // UX: only close if failed, else go to redirectUrl
      reconnectState.setOff();
    }
  };

  const resetReconnectingAccount = () => {
    resetSelectedItem();
    toggleReconnected();
  };

  // if we come back with reconnect success then set the flag for opening success reconnect modal
  useEffect(() => {
    const stringifiedData = manageLocalStorage('get', 'analyticsReconnectFromListState_json');
    const parsedData = getAnalyticsListReconnectState('parse', { stringifiedData }, () => {
      enqueueSnackbar(t('system fails to restore your reconnect data, please try again'), { variant: 'error' });
    });

    const reconnectStatus = manageLocalStorage('get', 'reconnectStatus');

    if (reconnectStatus === 'success' && parsedData) {
      toggleReconnected();
      setSelectedItem(parsedData.reconnectingAccount);
    }
    manageLocalStorage('remove', 'reconnectType');
    manageLocalStorage('remove', 'reconnectStatus');

    manageLocalStorage('remove', 'analyticsReconnectFromListState_json');
  }, []);

  return {
    reconnected,
    handleReconnectAccount,
    resetReconnectingAccount,
    reconnectingAccount: selectedItem,
    reconnecting: reconnectState.on,
    setReconnectingAccount: setSelectedItem
  };
};
