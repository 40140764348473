import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@/shared/atoms';
import { DEFAULT_SIMILAR_INFLUENCERS_LIMITATION, THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { useInstagramSimilarInfluencersList } from '../../Influencers';
import { SimilarAmbassadorPostWidget } from './SimilarAmbassadorPostWidget';

interface SimilarAmbassadorPostsListProps {
  username: string;
}

export const SimilarAmbassadorPostsList = ({ username }: SimilarAmbassadorPostsListProps) => {
  const { t } = useTranslation();
  const moreState = useToggleState();
  const { listRecords, loading, onFetchData } = useInstagramSimilarInfluencersList({ username });
  const [limit, setLimit] = useState<number>(DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.ANALYTICS.INTERACTION.DISPLAY);

  useEffect(() => {
    onFetchData();
  }, []);

  const handleMoreClick = () => {
    setLimit(
      moreState.status
        ? DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.ANALYTICS.INTERACTION.DISPLAY
        : DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.ANALYTICS.INTERACTION.FETCH
    );
    moreState.toggleStatus();
  };

  return (
    <div css={[styles.wrapper, moreState.status && { height: '250px', overflow: 'auto' }]}>
      {!loading ? (
        listRecords.length > 0 ? (
          <>
            {listRecords.slice(0, limit).map((profile, index) => (
              <SimilarAmbassadorPostWidget key={`${profile.id}_${index}`} profile={profile} />
            ))}

            {listRecords.length > DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.ANALYTICS.INTERACTION.DISPLAY && (
              <Button
                loading={loading}
                onClick={handleMoreClick}
                css={{ padding: '0 16px', margin: '8px auto' }}
                title={t(moreState.status ? 'Button.Less' : 'Button.More')}
              />
            )}
          </>
        ) : (
          <div css={{ padding: '32px 0', textAlign: 'center' }}>{t('Can not find any similar accounts')}</div>
        )
      ) : (
        <div css={styles.loadingWrapper}>
          <CircularProgress size={32} backgroundColor={THEME.loading.color} />
        </div>
      )}
    </div>
  );
};
const styles = {
  wrapper: css({
    position: 'relative',
    borderBottom: THEME.border.base,
    backgroundColor: THEME.background.colors.white,
    '&::before, &::after': {
      left: 0,
      width: '100%',
      height: '8px',
      content: '""',
      position: 'absolute'
    },
    '&::before': {
      top: 0,
      opacity: 0.7,
      background: 'linear-gradient(180deg, #eef3f7 0%, rgba(238, 243, 247, 0) 100%)'
    },
    '&::after': {
      bottom: 0,
      opacity: 0.5,
      background: 'linear-gradient(0deg, #eef3f7 0%, rgba(238, 243, 247, 0) 100%)'
    }
  }),
  loadingWrapper: css({ display: 'flex', padding: '22px 0', alignItems: 'center', justifyContent: 'center' })
};
