import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeCmsEstimateAssetsSummary.graphql';
import { Query, QueryyoutubeCmsEstimateAssetsSummaryArgs } from '../../__generated__/globalTypes';

export const useYoutubeCmsEstimateAssetsSummaryQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeCmsEstimateAssetsSummaryArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeCmsEstimateAssetsSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeCmsEstimateAssetsSummaryLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeCmsEstimateAssetsSummaryArgs>
): QueryResult<Query, QueryyoutubeCmsEstimateAssetsSummaryArgs> & {
  getYoutubeCmsEstimateAssetsSummary: LazyQueryExecFunction<Query, QueryyoutubeCmsEstimateAssetsSummaryArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeCmsEstimateAssetsSummary, result] = useLazyQuery<Query, QueryyoutubeCmsEstimateAssetsSummaryArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getYoutubeCmsEstimateAssetsSummary, ...result };
};

export const useYoutubeCmsEstimateAssetsSummaryPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeCmsEstimateAssetsSummary = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeCmsEstimateAssetsSummaryArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeCmsEstimateAssetsSummaryArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeCmsEstimateAssetsSummary };
};
