import { ReactNode } from 'react';
import { CSSObject, css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { Icon, IconProps } from '../Icon';

export interface NoticeV2Props {
  open?: boolean;
  title: ReactNode;
  className?: string;
  hasCloseIcon?: boolean;
  description?: ReactNode;
  type?: 'info' | 'success' | 'warning' | 'error' | 'none';
}

export const NoticeV2 = ({
  title,
  className,
  description,
  hasCloseIcon,
  open = true,
  type = 'none'
}: NoticeV2Props) => {
  const { status, toggleStatus } = useToggleState(open);

  const { iconProps, boxStyles } = ((): { iconProps: IconProps; boxStyles: CSSObject } => {
    switch (type) {
      case 'error':
        return {
          iconProps: { icon: 'fail', color: THEME.attention.colors.red },
          boxStyles: { backgroundColor: '#ffcfcf33', borderColor: THEME.border.colors.red.lv1 }
        };
      case 'info':
      default:
        return {
          iconProps: { icon: 'info', color: THEME.attention.colors.blue },
          boxStyles: { backgroundColor: 'rgba(195, 222, 247, 0.20)', borderColor: THEME.border.colors.blue.lv1 }
        };
    }
  })();

  return status ? (
    <div css={[styles.wrapper, boxStyles]} className={className}>
      <Icon size={14} {...iconProps} />

      <div>
        <div css={{ fontSize: '14px', fontWeight: 600, lineHeight: '100%' }}>{title}</div>
        {description ? <div css={{ fontSize: '12px', fontWeight: 400, lineHeight: '140%' }}>{description}</div> : null}
      </div>

      {hasCloseIcon ? (
        <Icon
          size={10}
          icon="close"
          onClick={toggleStatus}
          color={THEME.icon.colors.gray.lv3}
          css={{ marginLeft: 'auto', marginTop: '3px', cursor: 'pointer' }}
        />
      ) : null}
    </div>
  ) : null;
};
const styles = {
  wrapper: css({
    gap: '8px',
    padding: '8px',
    display: 'flex',
    borderRadius: '3px',
    boxSizing: 'border-box',
    wordBreak: 'break-word',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  })
};
