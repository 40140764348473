import { ReactNode, createContext, useContext } from 'react';
import { TalentAgencyWithCompanies } from '@/graphql';
import { useUpdateDocumentTitle } from '@/shared/hooks';

interface ContextType {
  talentAgency: TalentAgencyWithCompanies;
}

const TalentAgencyDetailsContext = createContext<ContextType>({} as ContextType);

interface Props {
  children: ReactNode;
  values: ContextType;
}

export const TalentAgencyDetailsProvider = ({ values: { talentAgency }, children }: Props) => {
  useUpdateDocumentTitle({
    href: '/talent-agency',
    title: 'appHeader.Edit Talent Agency',
    appHeader: talentAgency.name ? 'appHeader.EditWithTheName' : 'appHeader.Edit Talent Agency',
    ...(talentAgency.name ? { interpolation: { name: talentAgency.name } } : {})
  });

  return <TalentAgencyDetailsContext.Provider value={{ talentAgency }}>{children}</TalentAgencyDetailsContext.Provider>;
};

export const useTalentAgencyDetailsContext = () => useContext<ContextType>(TalentAgencyDetailsContext);
