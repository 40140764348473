import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { useYoutubeCMSFilter } from '../hooks';
import { Template, TemplateProps } from './Template';

export interface YoutubeCMSFilterProps extends Omit<TemplateProps, 'onSubmit'> {
  className?: string;
}

export const YoutubeCMSFilter = ({ className, ...templateProps }: YoutubeCMSFilterProps) => {
  const { filter, setFilterForm } = useYoutubeCMSFilter();

  return (
    <FilterWrapper css={{ boxShadow: 'none' }} className={className}>
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template {...templateProps} />} />
    </FilterWrapper>
  );
};

export * from './schemaTypes';
