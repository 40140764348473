import { InfluencerPackages } from '@/shared/organisms';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';
import { useDefaultInfluencerSearchProps, useInfluencerPackagesSelectButtonsProps } from './hooks';

export const EngagementInfluencerPackagesSearch = () => {
  const { engagementId } = useEngagementDetailsContext();
  const { isEngagementPage } = useDefaultInfluencerSearchProps();
  const { getSortSelectButtonProps, getListSelectButtonProps, getInfluencersListSelectButtonProps } =
    useInfluencerPackagesSelectButtonsProps();

  return (
    <InfluencerPackages
      engagementId={engagementId}
      isEngagementPage={isEngagementPage}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
      getInfluencersListSelectButtonProps={getInfluencersListSelectButtonProps}
    />
  );
};
