import { useTranslation } from 'react-i18next';
import { PostDetailsType } from '@/shared/types';
import { PostTabs, PostTabsProps } from '../shared';
import { MarketplaceHistoryDetailsTab, MarketplaceHistoryDetailsTabProps } from './MarketplaceHistoryDetailsTab';
import { MarketplacePostDetailsTab, MarketplacePostDetailsTabProps } from './MarketplacePostDetailsTab';
import { MarketplacePostDetailsTabsType } from './types';

const MKP_POST_DETAILS_WIDTH = '856px';

export interface TemplateProps
  extends Pick<PostDetailsType, 'id'>,
    Pick<MarketplaceHistoryDetailsTabProps, 'historyData'>,
    Pick<MarketplacePostDetailsTabProps, 'postStatus' | 'hasInteraction' | 'postDetails'> {
  loading?: boolean;
}

export const Template = ({ id, loading, postStatus, historyData, hasInteraction, postDetails }: TemplateProps) => {
  const { t } = useTranslation();

  const tabs: PostTabsProps<MarketplacePostDetailsTabsType>['tabs'] = [
    { label: t('Post Detail'), value: MarketplacePostDetailsTabsType.Details },
    // MKP History tab always shown if has data
    ...(historyData?.length ? [{ label: t('History'), value: MarketplacePostDetailsTabsType.History }] : [])
  ];

  return (
    <PostTabs id={id} tabs={tabs}>
      {({ selectedTab }) =>
        selectedTab === 'History' ? (
          <MarketplaceHistoryDetailsTab
            historyData={historyData}
            css={{ width: MKP_POST_DETAILS_WIDTH, padding: '0', overflow: 'auto' }}
          />
        ) : (
          <MarketplacePostDetailsTab
            loading={loading}
            postStatus={postStatus}
            postDetails={postDetails}
            hasInteraction={hasInteraction}
            css={{ width: MKP_POST_DETAILS_WIDTH }}
          />
        )
      }
    </PostTabs>
  );
};
