import { SocialAccountType, namedOperations, useUpdateSocialUsernameMutation } from '@/graphql';
import { Modal, Button, ModalTitle, ButtonProps, FormProvider, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { FormAction, SupportedSocialMediaType } from '@/shared/types';
import { getSocialMediaText } from '@/shared/utils';
import { SocialMediaInputField, SocialMediaInputFieldProps } from '../../../shared';
import { EditUsernameSchema } from './schema';
import { EditUsernameFormValues } from './types';

export interface EditAccountButtonProps {
  influencerId: number;
  socialAccountId: number;
  socialMedia: keyof typeof SupportedSocialMediaType;
  buttonProps?: Pick<ButtonProps, 'tooltip' | 'disabled'>;
  inputProps: Pick<SocialMediaInputFieldProps, 'title' | 'placeholder'>;
}
export const EditAccountButton = ({
  socialMedia,
  buttonProps,
  influencerId,
  socialAccountId,
  inputProps: { title, placeholder }
}: EditAccountButtonProps) => {
  const editModal = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callUpdateSocialUsername } = useUpdateSocialUsernameMutation({
    refetchQueries: [namedOperations.Query.InfluencerUpdateDetailV2]
  });

  const socialMediaName = getSocialMediaText(socialMedia);

  const handleSubmit: FormAction<EditUsernameFormValues>['onSubmit'] = async ({ username }) => {
    try {
      await callUpdateSocialUsername({
        variables: { input: { username, influencerId, socialAccountId, socialMedia: socialMedia as SocialAccountType } }
      });

      enqueueSnackbar(t('Username has been updated'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message || 'failedToCreate'), { variant: 'error' });
    } finally {
      editModal.close();
    }
  };

  return (
    <>
      <Button
        {...buttonProps}
        variant="white"
        onClick={editModal.open}
        prefixIcon={{ icon: 'pencil', size: 16 }}
        tooltip={{ help: t('Tooltip.Edit Username'), ...buttonProps?.tooltip }}
      />

      <Modal open={editModal.status} onClose={editModal.close} css={{ width: '455px' }}>
        <FormProvider<EditUsernameFormValues>
          onSubmit={handleSubmit}
          zodSchema={EditUsernameSchema}
          defaultValues={{ username: '' }}
        >
          {({ values, onSubmit, formState }) => (
            <>
              <ModalContent>
                <ModalTitle>{t(`Edit ${socialMediaName} account`)}</ModalTitle>

                <p css={{ marginTop: '16px', lineHeight: '18px' }}>
                  {t(`Change your ${socialMediaName} account`)}
                  <br />
                  {t('It will overwrite your account, so your previous data will remain')}
                </p>

                <SocialMediaInputField<keyof EditUsernameFormValues>
                  title={title}
                  name="username"
                  socialMedia={socialMedia}
                  css={{ marginTop: '16px' }}
                  placeholder={placeholder || t<string>('enter username')}
                  autoFocus
                />
              </ModalContent>

              <ModalFooterActions
                cancelButtonProps={{ onClick: editModal.close }}
                submitButtonProps={{
                  type: 'button', // Avoid conflict with parent
                  onClick: onSubmit,
                  title: t('Button.Save'),
                  disabled: !values.username,
                  loading: formState.isSubmitting
                }}
              />
            </>
          )}
        </FormProvider>
      </Modal>
    </>
  );
};
