import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { useMarketplaceCommissionsFilter } from './hooks';

export type MarketplaceCommissionsFilterProps = TemplateProps;

export const MarketplaceCommissionsFilter = (props: MarketplaceCommissionsFilterProps) => {
  const { filter, setFilterForm } = useMarketplaceCommissionsFilter();

  return (
    <FilterWrapper css={{ padding: '16px' }}>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<Template {...props} />} />
    </FilterWrapper>
  );
};

export { useMarketplaceCommissionsFilter };
export * from './schemaTypes';
