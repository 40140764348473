import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AddInfluencerToUserProposal.graphql';
import { Mutation, MutationaddInfluencerToUserProposalArgs } from '../../__generated__/globalTypes';

export const useAddInfluencerToUserProposalMutation = (
  options?: MutationHookOptions<Mutation, MutationaddInfluencerToUserProposalArgs>
) => {
  const [callAddInfluencerToUserProposal, result] = useMutation<Mutation, MutationaddInfluencerToUserProposalArgs>(
    MUTATION,
    options
  );

  return { callAddInfluencerToUserProposal, ...result };
};

export type AddInfluencerToUserProposalMutationFunction = Mutation['addInfluencerToUserProposal'];
