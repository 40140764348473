import { useTranslation } from 'react-i18next';
import { NoDataContentWrapper, NoDataContentWrapperProps } from '@/shared/atoms';
import { SectionWithHeader, SectionWithHeaderProps } from '@/shared/molecules';

export type AnalyticsOverviewWrapperProps = SectionWithHeaderProps &
  Pick<NoDataContentWrapperProps, 'dataNotAvailable'>;

export const AnalyticsOverviewWrapper = ({
  help,
  children,
  dataNotAvailable,
  ...restProps
}: AnalyticsOverviewWrapperProps) => {
  const { t } = useTranslation();

  return (
    <SectionWithHeader
      title={t('OverView')}
      help={help ?? t('Tooltip.dashboardOverview')}
      prefixIcon={{ icon: 'pad-two-tones', size: '24px' }}
      {...restProps}
    >
      <NoDataContentWrapper dataNotAvailable={dataNotAvailable}>{children}</NoDataContentWrapper>
    </SectionWithHeader>
  );
};
