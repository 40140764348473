import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AssignAdvertiserToStaffs.graphql';
import { Mutation, MutationassignAdvertiserToStaffsArgs } from '../../__generated__/globalTypes';

export const useAssignAdvertiserToStaffsMutation = (
  options?: MutationHookOptions<Mutation, MutationassignAdvertiserToStaffsArgs>
) => {
  const [callAssignAdvertiserToStaffs, result] = useMutation<Mutation, MutationassignAdvertiserToStaffsArgs>(
    MUTATION,
    options
  );

  return { callAssignAdvertiserToStaffs, ...result };
};

export type AssignAdvertiserToStaffsMutationFunction = Mutation['assignAdvertiserToStaffs'];
