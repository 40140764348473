import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerYoutubeAnalyticsTagsTopRanking.graphql';
import { Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs } from '../../__generated__/globalTypes';

export const useInfluencerYoutubeAnalyticsTagsTopRankingQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerYoutubeAnalyticsTagsTopRankingLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs>
): QueryResult<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs> & {
  getInfluencerYoutubeAnalyticsTagsTopRanking: LazyQueryExecFunction<
    Query,
    QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerYoutubeAnalyticsTagsTopRanking, result] = useLazyQuery<
    Query,
    QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerYoutubeAnalyticsTagsTopRanking, ...result };
};

export const useInfluencerYoutubeAnalyticsTagsTopRankingPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerYoutubeAnalyticsTagsTopRanking = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerYoutubeAnalyticsTagsTopRanking };
};
