import { css } from '@emotion/react';
import { BasicAccountAnalyticsPayload } from '@/graphql';
import { SocialIcon } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { SingleSelectField } from '@/shared/molecules';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { useAnalyticsProfileSearch } from '../../hooks';
import { AnalyticsSelectedAccountInfo } from '../../types';

interface AnalyticsAccountsSelectorProps {
  brandAccount: BasicAccountAnalyticsPayload;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

const ADD_ACCOUNT = 'add_account';

export const AnalyticsAccountsSelector = ({
  analyticsAccount,
  brandAccount: { brandAccountId, socialMediaAccounts }
}: AnalyticsAccountsSelectorProps) => {
  const { t, navigate } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();

  const allSupportedSocialMedias = [
    {
      label: t<string>('Youtube'),
      value: String(socialMediaAccounts.youtube?.id || ''),
      socialMedia: AnalyticsSupportedSocialMediaType.youtube,
      prefixIcon: <SocialIcon size={20} css={styles.socialMediaIcon} type="YOUTUBE" />
    },
    {
      label: t<string>('Instagram'),
      value: String(socialMediaAccounts.instagram?.id || ''),
      socialMedia: AnalyticsSupportedSocialMediaType.instagram,
      prefixIcon: <SocialIcon size={20} css={styles.socialMediaIcon} type="INSTAGRAM" variant="transform" />
    },
    {
      label: t<string>('Facebook Page'),
      socialMedia: AnalyticsSupportedSocialMediaType.facebook,
      value: String(socialMediaAccounts.facebookPage?.id || ''),
      prefixIcon: <SocialIcon size={20} css={styles.socialMediaIcon} type="FACEBOOK" />
    },
    {
      label: t<string>('X(Twitter)'),
      value: String(socialMediaAccounts.twitter?.id || ''),
      socialMedia: AnalyticsSupportedSocialMediaType.twitter,
      prefixIcon: <SocialIcon size={20} css={styles.socialMediaIcon} type="TWITTER" />
    }
  ] as const;
  const validSupportedAccountOptions = allSupportedSocialMedias.filter((option) => Boolean(option.value));
  const socialAccountOptions = [
    ...validSupportedAccountOptions,
    ...(validSupportedAccountOptions.length < allSupportedSocialMedias.length
      ? [
          {
            label: '',
            socialMedia: '' as AnalyticsSupportedSocialMediaType,
            value: ADD_ACCOUNT,
            prefixIcon: (
              <div css={{ color: '#3892E5' }}>
                <span css={{ fontSize: '18px', lineHeight: '16px', paddingRight: '4px' }}>+</span>
                <span>{t('Add Social Account')}</span>
              </div>
            )
          }
        ]
      : [])
  ];

  const handleSocialAccountChange = (_: string | string[], value: string) => {
    if (value === ADD_ACCOUNT) {
      navigate({ to: '/analytics/$brandAccountId', params: { brandAccountId: brandAccountId.toString() } });
    } else {
      const account = socialAccountOptions.find((acc) => acc.value === value);

      if (account) {
        navigate({
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard',
          params: {
            brandAccountId: brandAccountId.toString(),
            socialMedia: account.socialMedia,
            socialMediaAccountId: account.value
          },
          search: {
            filter: {
              ...filter,
              // YT requires startDate and endDate but some IG tabs can have them as "", zod default values will be not set in that case
              startDate: filter.startDate || getThirtyDaysAgo(),
              endDate: filter.endDate || getToday()
            }
          }
        });
      }
    }
  };

  return (
    <div css={{ width: '216px' }}>
      <SingleSelectField
        name="Account"
        options={socialAccountOptions}
        value={String(analyticsAccount.id)}
        setFieldValue={handleSocialAccountChange}
        hideDeselectOption
        hideInput
      />
    </div>
  );
};
const styles = {
  socialMediaIcon: css({ marginRight: '4px' })
};
