import { useUserRoles } from '@/auth';
import {
  EngagementCampaignStatus,
  ProposalJobDescriptionBreakDownMode,
  useUpdateEngagementBreakdownModeMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { TableTitle } from '../../shared';
import { LeaveConfirmModal } from './LeaveConfirmModal';
import { SelectedInfluencersProvider } from './SelectedInfluencersContext';
import { SelectedInfluencersTable, SelectedInfluencersTableProps } from './SelectedInfluencersTable';
import { TableModes } from './TableModes';

export const SelectedInfluencers = ({
  jdMode,
  engagement,
  selectedInfluencers,
  hasTrackingReferral,
  formattedInfluencers,
  formattedSocialAccounts,
  hasInfluencerCostPermission
}: SelectedInfluencersTableProps) => {
  const { id, status } = engagement;
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { callUpdateEngagementBreakdownMode, loading } = useUpdateEngagementBreakdownModeMutation({
    onCompleted: invalidateRouteLoader
  });
  const handleJdModeChange = async (mode: ProposalJobDescriptionBreakDownMode) => {
    if (mode === jdMode) {
      return;
    }

    try {
      await callUpdateEngagementBreakdownMode({ variables: { input: { campaignId: id, jdMode: mode } } });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <SelectedInfluencersProvider value={{ jdMode, selectedInfluencers }}>
      <>
        <TableTitle title={t('Suggested Influencers')} />

        {selectedInfluencers.length > 0 &&
        isAdminStaffTalentAgencyPartner &&
        status !== EngagementCampaignStatus.FINISHED ? (
          <TableModes jdMode={jdMode} engagementId={id} onEditJdMode={handleJdModeChange} />
        ) : null}

        <SelectedInfluencersTable
          jdMode={jdMode}
          loading={loading}
          engagement={engagement}
          selectedInfluencers={selectedInfluencers}
          formattedInfluencers={formattedInfluencers}
          formattedSocialAccounts={formattedSocialAccounts}
          hasInfluencerCostPermission={hasInfluencerCostPermission}
          hasTrackingReferral={hasTrackingReferral}
        />

        <LeaveConfirmModal />
      </>
    </SelectedInfluencersProvider>
  );
};
