import { Table } from '@/shared/atoms';
import { TikTokAdReportType } from '@/graphql';
import {
  AdCampaignReportTableProps,
  useAdCampaignReportTable,
  useAdCampaignDownloadReport,
  AdCampaignDownloadReportProps
} from './hooks';
import { ActionBar } from './ActionBar';

export const AdCampaignReportTable = ({
  filter,
  loading,
  summaryData,
  adReportType,
  allTiktokAdsReport,
  adCampaignIdFromUrl
}: Pick<
  AdCampaignReportTableProps,
  'summaryData' | 'allTiktokAdsReport' | 'adReportType' | 'loading' | 'adCampaignIdFromUrl'
> &
  Pick<AdCampaignDownloadReportProps, 'filter'>) => {
  const { downloading, downloadOptions, handleDownloadReport, allItemIds, selectedItemIds, onClickCampaignId } =
    useAdCampaignDownloadReport({
      filter,
      allItemIds: allTiktokAdsReport
        .map((rep) =>
          adReportType === TikTokAdReportType.AD_CAMPAIGN
            ? rep.adCampaignId
            : adReportType === TikTokAdReportType.AD_GROUP
              ? rep.adGroupId
              : rep.adId
        )
        .filter((v) => v !== null && v !== undefined),
      adReportType
    });
  const { rows, columns } = useAdCampaignReportTable({
    summaryData,
    adReportType,
    allTiktokAdsReport,
    adCampaignIdFromUrl,
    selectedAdCampaignIds: selectedItemIds,
    onClickCampaignId
  });

  return (
    <Table
      maxHeight="820px"
      loading={loading}
      data={{ rows, columns, rowHeight: '73px' }}
      noTopBorderRadius
      stickyHeader
      hasBorder
      css={{ position: 'relative' }}
    >
      <ActionBar
        selectedIds={selectedItemIds}
        hasAllItemsSelected={allItemIds.length === selectedItemIds.length}
        toggleSelectAllItems={() => onClickCampaignId(selectedItemIds)}
        loading={downloading}
        allItemIds={allItemIds}
        downloadOptions={downloadOptions}
        handleDownloadReport={handleDownloadReport}
      />
    </Table>
  );
};
