import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { getFirstDay } from '@/shared/utils';
import { YoutubeCMSSegmentMode } from '../types';

export const YoutubeCMSFilterSchema = z.object({
  month: FilterValidations.string(),
  keyword: FilterValidations.string(),
  segment: FilterValidations.enum(YoutubeCMSSegmentMode)
});
export const YoutubeCMSSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(YoutubeCMSFilterSchema, {
    month: getFirstDay(),
    keyword: '',
    segment: YoutubeCMSSegmentMode.CHANNELS
  }),
  ...SearchFilterDefaults.pageLimit()
});
export const YoutubeCMSChannelDetailsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(YoutubeCMSFilterSchema, {
    month: getFirstDay(),
    keyword: '',
    segment: YoutubeCMSSegmentMode.ASSETS
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type YoutubeCMSSearchSchemaType = z.infer<typeof YoutubeCMSSearchSchema>;
export type YoutubeCMSFilterFormValues = z.infer<typeof YoutubeCMSFilterSchema>;
export type YoutubeCMSFilterFormKeys = UnionOfKeys<YoutubeCMSFilterFormValues>;
