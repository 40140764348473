import { InstagramInfluencers, InstagramInfluencerProfile } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSocialAccountSelectButtonProps } from './hooks';

export const InstagramPackageInfluencersSearch = () => {
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialAccountSelectButtonProps<InstagramInfluencerProfile>({
      socialMedia: InfluencersTabType.INSTAGRAM
    });

  return (
    <InstagramInfluencers
      isPackagePage
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
