import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { useTwitterAnalyticsPostsQuery } from '@/graphql';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { ProfilePostsCount, ProfilePostsFilter, AnalyticsTwitterPostsList } from '@/shared/organisms';
import { useInitialAnalyticsPosts } from '../hooks';
import { AnalyticsTwitterPostsSearchSchemaType } from './schemaTypes';

export const AnalyticsTwitterPosts = () => {
  const { brandAccountId, mainSocialAccountId } = useInitialAnalyticsPosts({
    socialMedia: 'twitter',
    document: (name) => ({
      alwaysBackToParent: true,
      appHeader: name ? 'appHeader.FeedPostsListWithAccountName' : 'appHeader.Feed Posts List',
      title: name ? 'documentTitle.FeedPostsListWithAccountName' : 'documentTitle.Feed Posts List'
    })
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<AnalyticsTwitterPostsSearchSchemaType>();
  const { data, previousData, loading } = useTwitterAnalyticsPostsQuery({
    skip: !brandAccountId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      orderBy: sort,
      offset: getOffset(page, limit),
      twitterAnalyticsAccountId: Number(mainSocialAccountId),
      ...filter
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.twitterAnalyticsPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

      <ProfilePostsCount count={totalRecords} />

      <AnalyticsTwitterPostsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.twitterAnalyticsPosts.posts || []}
      />
    </ListWithPagination>
  );
};
