import { useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { InfluencerFollowerGrowthSection } from '@/graphql';
import { ChartTooltipSingleMetric } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { usePostDetailsParam } from '@/shared/hooks';
import { HighChart, Highcharts, InfluencerPostDetailsModal } from '@/shared/molecules';
import { AppLanguage } from '@/shared/types';
import { bigIntFormatter, formatIntNumber, localizedDateFormatter } from '@/shared/utils';

const colors = { post: '#FFD7C1', follower: '#FF2B52' };

interface InfluencerProfileOverviewChartProps {
  influencerId: number;
  data: InfluencerFollowerGrowthSection;
  xAxisFormat?: string;
}

export const InfluencerProfileOverviewChart = ({
  influencerId,
  data: { postsGrowth, followersGrowth },
  xAxisFormat = 'MMM do'
}: InfluencerProfileOverviewChartProps) => {
  const { t, i18n } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam('popup_overview');
  const [selectedPostIds, setSelectedPostIds] = useState<readonly string[]>([]);

  const legendOptions = [
    { label: t('Follower'), color: colors.follower },
    { label: t('Post'), color: colors.post }
  ];
  const postSeries = postsGrowth?.map((el) => el.postsCount);
  const { followersCategories, followersSeries } = followersGrowth.reduce<{
    followersSeries: (number | null)[];
    followersCategories: string[];
  }>(
    (acc, curr) => ({
      followersCategories: [...acc.followersCategories, curr.time],
      followersSeries: [...acc.followersSeries, curr.followersCount ?? null]
    }),
    { followersSeries: [], followersCategories: [] }
  );
  const options: Highcharts.Options = {
    title: { text: '' },
    chart: { height: 190 },
    legend: { enabled: false },
    xAxis: {
      categories: followersCategories,
      labels: {
        formatter() {
          return localizedDateFormatter(this.value, xAxisFormat, i18n.language as AppLanguage);
        }
      }
    },
    yAxis: [
      {
        title: { text: '' },
        allowDecimals: false,
        labels: {
          formatter() {
            return bigIntFormatter(Number(this.value));
          }
        }
      },
      { title: { text: '' }, allowDecimals: false, labels: { enabled: false } }
    ],
    series: [
      {
        zIndex: 1,
        type: 'line',
        data: followersSeries,
        color: colors.follower,
        marker: { symbol: 'circle' }
      },
      {
        name: 'Post',
        type: 'column',
        data: postSeries,
        color: colors.post,
        yAxis: 1,
        cursor: 'pointer',
        point: {
          events: {
            click() {
              const postIds = postsGrowth?.find((el) => el.date === this.category)?.postIds || [];

              if (postIds.length) {
                setSelectedPostIds(postIds.map((id) => String(id)));
                setPostDetailsParam(postIds[0]);
              }
            }
          }
        }
      }
    ],
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      pointFormat: '',
      headerFormat: '',
      borderColor: THEME.border.colors.black,
      style: { color: THEME.text.colors.white },
      backgroundColor: THEME.background.colors.black,
      formatter() {
        return renderToStaticMarkup(
          <ChartTooltipSingleMetric
            name={t(String(this.key))}
            color={this.color as string}
            value={formatIntNumber(this.y)}
          />
        );
      }
    }
  };

  return (
    <>
      <div css={{ padding: '16px', boxSizing: 'border-box' }}>
        <div css={{ marginBottom: '8px', display: 'flex', gap: '16px' }}>
          {legendOptions.map(({ label, color: backgroundColor }, index) => (
            <div key={index} css={{ display: 'flex', alignItems: 'center', fontSize: '12px', lineHeight: '100%' }}>
              <div css={{ width: '8px', height: '8px', marginRight: '4px', borderRadius: '2px', backgroundColor }} />
              {label}
            </div>
          ))}
        </div>

        <HighChart options={options} />
      </div>

      <InfluencerPostDetailsModal
        posts={selectedPostIds.map((id) => ({ id, influencerId }))}
        paramKey="popup_overview"
      />
    </>
  );
};
