import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { YoutubeAnalyticsTagRankingPayload } from '@/graphql';
import { useInfluencerYoutubeTagsRankingTable } from './hooks';

type YoutubeTagsRankingTableProps = TableData<YoutubeAnalyticsTagRankingPayload>;

export const YoutubeTagsRankingTable = ({ loading, data = [] }: YoutubeTagsRankingTableProps) => {
  const { rows, columns } = useInfluencerYoutubeTagsRankingTable({ data });

  return <Table loading={loading} data={{ rows, columns, rowHeight: '40px' }} noTopBorderRadius hasBorder />;
};
