import { useParams, Navigate } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import { THEME } from '@/shared/constants';
import { useCustomHeader } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { EditInfluencerForOthers } from './EditInfluencerForOthers';
import { EditInfluencerForTalentAgency } from './EditInfluencerForTalentAgency';

export const InfluencerDetails = () => {
  useCustomHeader();
  const params = useParams({ from: '/_private-routes/influencer/$id' });
  const { isTalentAgency, isAdminStaffPartner } = useUserRoles();

  const influencerId = params?.id ? Number(params?.id) : undefined;

  if (!influencerId) {
    return <Navigate to="/influencer/add" />;
  }

  return (
    <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
      <Header />

      {isTalentAgency ? (
        <EditInfluencerForTalentAgency influencerId={influencerId} />
      ) : isAdminStaffPartner ? (
        <EditInfluencerForOthers influencerId={influencerId} />
      ) : (
        <Navigate to="/influencer/add" />
      )}
    </div>
  );
};
