import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllEngagementPosts.graphql';
import { Query, QueryallEngagementPostsArgs } from '../../__generated__/globalTypes';

export const useAllEngagementPostsQuery = (options?: QueryHookOptions<Query, QueryallEngagementPostsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallEngagementPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllEngagementPostsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallEngagementPostsArgs>
): QueryResult<Query, QueryallEngagementPostsArgs> & {
  getAllEngagementPosts: LazyQueryExecFunction<Query, QueryallEngagementPostsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllEngagementPosts, result] = useLazyQuery<Query, QueryallEngagementPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllEngagementPosts, ...result };
};

export const useAllEngagementPostsPromiseQuery = () => {
  const client = useApolloClient();

  const getAllEngagementPosts = useCallback(
    (options?: Omit<QueryOptions<QueryallEngagementPostsArgs, Query>, 'query'>) =>
      client.query<Query, QueryallEngagementPostsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllEngagementPosts };
};
