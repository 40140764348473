import { useState } from 'react';
import { useUserRoles } from '@/auth';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import {
  Modal,
  Label,
  Button,
  Textarea,
  ModalTitle,
  ButtonProps,
  ModalContent,
  ModalFooterActions
} from '@/shared/atoms';
import {
  TikTokSpecialCampaignDetail,
  TikTokSpecialCampaignStatus,
  useRejectTiktokSpecialCampaignMutation
} from '@/graphql';

interface RejectButtonProps extends ButtonProps {
  tiktokSpecialCampaign?: TikTokSpecialCampaignDetail;
}

export const RejectButton = ({ tiktokSpecialCampaign, ...buttonProps }: RejectButtonProps) => {
  const confirmModal = useToggleState();
  const { isAdminStaff } = useUserRoles();
  const [reason, setReason] = useState('');
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { callRejectTiktokSpecialCampaign, loading } = useRejectTiktokSpecialCampaignMutation({
    onCompleted: invalidateRouteLoader
  });

  // TODO: we might need to check permissions here as well
  // Asking: https://anymindgroup.slack.com/archives/C02C5J5B7KL/p1730428908925939
  const hasRejectButton =
    isAdminStaff && tiktokSpecialCampaign && tiktokSpecialCampaign?.status === TikTokSpecialCampaignStatus.REVIEWING;

  const handleReject = async () => {
    if (!hasRejectButton) return;

    try {
      await callRejectTiktokSpecialCampaign({ variables: { input: { campaignId: tiktokSpecialCampaign.id, reason } } });

      enqueueSnackbar(t('succeededInUpdating', { name: tiktokSpecialCampaign.title }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    } finally {
      confirmModal.close();
    }
  };

  return (
    hasRejectButton && (
      <>
        <Button
          variant="red"
          title={t('Button.Reject')}
          onClick={confirmModal.open}
          css={{ padding: '0 24px', minWidth: '100px' }}
          {...buttonProps}
        />

        <Modal
          open={confirmModal.status}
          onClose={confirmModal.close}
          css={{ width: 'max-content', maxWidth: THEME.modal.size.lv1 }}
          hasCloseIcon
        >
          <ModalContent>
            <ModalTitle>{t('Are you sure you want to reject the campaign?')}</ModalTitle>
            <p css={{ margin: '16px 0' }}>{t('rejectMkpCampaignReasonDescription')}</p>
            <Label title={t('Reason of rejection')} optional />
            <Textarea css={{ minHeight: '104px' }} value={reason} onChange={(e) => setReason(e.target.value)} />
          </ModalContent>
          <ModalFooterActions
            cancelButtonProps={{ onClick: confirmModal.close }}
            submitButtonProps={{
              variant: 'red',
              onClick: handleReject,
              loading: { showIcon: true, status: loading },
              title: t(loading ? 'Button.Rejecting' : 'Button.Reject')
            }}
          />
        </Modal>
      </>
    )
  );
};
