import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateMarketplace.graphql';
import { Mutation, MutationupdateMarketplaceArgs } from '../../__generated__/globalTypes';

export const useUpdateMarketplaceMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateMarketplaceArgs>
) => {
  const [callUpdateMarketplace, result] = useMutation<Mutation, MutationupdateMarketplaceArgs>(MUTATION, options);

  return { callUpdateMarketplace, ...result };
};

export type UpdateMarketplaceMutationFunction = Mutation['updateMarketplace'];
