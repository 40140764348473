import {
  endOfWeek,
  format,
  isBefore,
  isDate,
  isLastDayOfMonth,
  isSameDay,
  isToday,
  isValid,
  isYesterday,
  parse,
  startOfMonth,
  startOfWeek,
  subDays,
  subWeeks
} from 'date-fns';
import { enUS, ja, zhTW, zhCN, ko } from 'date-fns/locale';
import { AppLanguage, Language, SelectedRangeOption } from '@/shared/types';
import { localizedDateFormatter } from '@/shared/utils';
import { Period, PickerDate, Range } from './types';

export const datesSeparator = '\u00A0\u00A0\u2013\u00A0\u00A0';
export const placeholderMulti = `Start Date${datesSeparator}End Date`;
export const placeholderSingle = 'Jan 01, 2020';

export const getDefaultDate = (date?: string) => (date ? new Date(date) : undefined);

export const getSelectedRange = (from: PickerDate, to: PickerDate, initialPeriod?: Period) => {
  const fromFormat = from === undefined ? 0 : from;
  const toFormat = to === undefined ? 0 : to;
  const today = new Date();
  const oneWeekAgo = subWeeks(today, 1);
  const lastDayOfPrevMonth = subDays(startOfMonth(today), 1);
  const initialFrom = initialPeriod ? new Date(initialPeriod.startDate) : today;
  const initialTo = initialPeriod ? new Date(initialPeriod.endDate) : today;

  if (isToday(fromFormat) && (isToday(toFormat) || !to)) {
    return SelectedRangeOption.TODAY;
  }
  if (isYesterday(fromFormat) && (isYesterday(toFormat) || !to)) {
    return SelectedRangeOption.YESTERDAY;
  }
  if (isSameDay(fromFormat, startOfMonth(today)) && isLastDayOfMonth(toFormat)) {
    return SelectedRangeOption.THIS_MONTH;
  }
  if (isToday(toFormat) && isSameDay(fromFormat, subDays(toFormat, 13))) {
    return SelectedRangeOption.LAST_TWO_WEEKS;
  }
  if (isToday(toFormat) && isSameDay(fromFormat, startOfWeek(toFormat))) {
    return SelectedRangeOption.THIS_WEEK;
  }
  if (isToday(toFormat) && isSameDay(fromFormat, subDays(toFormat, 6))) {
    return SelectedRangeOption.LAST_SEVEN_DAYS;
  }
  if (isSameDay(toFormat, endOfWeek(oneWeekAgo)) && isSameDay(fromFormat, startOfWeek(oneWeekAgo))) {
    return SelectedRangeOption.LAST_WEEK;
  }
  if (isToday(toFormat) && isSameDay(fromFormat, subDays(toFormat, 29))) {
    return SelectedRangeOption.TODAY_TO_MONTH_AGO;
  }
  if (isSameDay(toFormat, lastDayOfPrevMonth) && isSameDay(fromFormat, startOfMonth(lastDayOfPrevMonth))) {
    return SelectedRangeOption.LAST_MONTH;
  }
  if (isSameDay(toFormat, initialTo) && isSameDay(fromFormat, initialFrom)) {
    return SelectedRangeOption.ALL_TIME;
  }
  if (toFormat === 0 && fromFormat === 0) {
    return SelectedRangeOption.CLEAR;
  }

  return SelectedRangeOption.CUSTOM;
};

export const CloseOverlayAfterFocus = (inputRef: { current: { hideAfterDayClick: () => void } }) => {
  const activeElement = document.activeElement;
  const overlayContainer = document.getElementsByClassName('DayPickerInput-Overlay');

  if (overlayContainer && !overlayContainer[0]?.contains(activeElement)) {
    inputRef.current.hideAfterDayClick();
  }
};

export const parseDate = (str: string, parseFormat: string) => {
  if (str.length !== parseFormat.length) {
    return undefined;
  }
  const parsed = parse(str, parseFormat, new Date());

  if (isDate(parsed) && isValid(parsed)) {
    return parsed;
  }

  return undefined;
};

export const formatDate = (date: Date, pickerFormat: string) => format(date, pickerFormat);

export const getDatePickerLocale = (language: string) => {
  switch (language as Language) {
    case 'ja':
      return ja;
    case 'cn':
      return zhCN;
    case 'tw':
      return zhTW;
    case 'ko':
      return ko;

    case 'en':
    default:
      return enUS;
  }
};

export const getInputText = (appLanguage: AppLanguage, hoverTo: PickerDate, hoverFrom: PickerDate) => {
  const fromForInput = hoverFrom ? localizedDateFormatter(hoverFrom, 'PPP', appLanguage) : '';
  const toForInput = hoverTo ? localizedDateFormatter(hoverTo, 'PPP', appLanguage) : '';
  const showSingleDay = !!hoverTo && !!hoverFrom ? isSameDay(hoverTo, hoverFrom) : false;

  const resultInput =
    !showSingleDay && !!hoverTo && !!hoverFrom
      ? isBefore(hoverFrom, hoverTo)
        ? `${fromForInput}${datesSeparator}${toForInput}`
        : `${toForInput}${datesSeparator}${fromForInput}`
      : fromForInput;

  return resultInput;
};

export const getInputState = (period: Period): Range => ({
  from: getDefaultDate(period.startDate),
  to: getDefaultDate(period.endDate),
  hoverTo: getDefaultDate(period.endDate),
  hoverFrom: getDefaultDate(period.startDate)
});
