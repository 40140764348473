import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/GenerateMarketplaceCampaignDetail.graphql';
import { Mutation, MutationgenerateMarketplaceCampaignDetailArgs } from '../../__generated__/globalTypes';

export const useGenerateMarketplaceCampaignDetailMutation = (
  options?: MutationHookOptions<Mutation, MutationgenerateMarketplaceCampaignDetailArgs>
) => {
  const [callGenerateMarketplaceCampaignDetail, result] = useMutation<
    Mutation,
    MutationgenerateMarketplaceCampaignDetailArgs
  >(MUTATION, options);

  return { callGenerateMarketplaceCampaignDetail, ...result };
};

export type GenerateMarketplaceCampaignDetailMutationFunction = Mutation['generateMarketplaceCampaignDetail'];
