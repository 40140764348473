import { useState } from 'react';
import { Option } from '@/shared/types';
import { filterItems } from '../utils';

export const useSearch = <T extends Option>(options: readonly T[], skipFiltering?: boolean) => {
  const [searchText, setSearchText] = useState('');
  const filteredValue = skipFiltering ? options : filterItems<T>(options, searchText);

  return { searchText, setSearchText, filteredValue };
};
