import { useState } from 'react';
import { useFetchDailyPostsOnChart } from '@/shared/hooks';
import {
  getCompareAccountColorOpacity,
  supportedCompareAccountColors,
  AnalyticsCompareOverviewChartProps
} from '../../../shared';
import { AnalyticsYoutubeCompareGraphKeys as Keys } from '../types';

interface Props {
  chartData: AnalyticsCompareOverviewChartProps<Keys>['data'];
}

export const useAnalyticsYoutubeCompareOverviewPosts = ({ chartData }: Props) => {
  const [selectedPostDay, setSelectedPostDay] = useState('');

  const { customChartOptions } = useFetchDailyPostsOnChart({
    onFetchPosts: setSelectedPostDay,
    postsData: chartData.reduce<number[][]>((acc, curr) => [...acc, curr.data.posts.data], []),
    postColors: Object.values(supportedCompareAccountColors).map((color) => getCompareAccountColorOpacity(color))
  });

  return { selectedPostDay, setSelectedPostDay, customChartOptions };
};
