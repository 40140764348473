import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/JoinMarketplaceByStaff.graphql';
import { Mutation, MutationjoinMarketplaceByStaffArgs } from '../../__generated__/globalTypes';

export const useJoinMarketplaceByStaffMutation = (
  options?: MutationHookOptions<Mutation, MutationjoinMarketplaceByStaffArgs>
) => {
  const [callJoinMarketplaceByStaff, result] = useMutation<Mutation, MutationjoinMarketplaceByStaffArgs>(
    MUTATION,
    options
  );

  return { callJoinMarketplaceByStaff, ...result };
};

export type JoinMarketplaceByStaffMutationFunction = Mutation['joinMarketplaceByStaff'];
