import { StylesConfig } from 'react-select';
import { THEME } from '@/shared/constants';

export const AsyncSelectStyles = (hasError?: boolean) =>
  ({
    control: (base, props) => ({
      ...base,
      borderRadius: '2px',
      minHeight: '32px',
      outline: 'none',
      boxShadow: 'none',
      lineHeight: '15px',
      background: props.isDisabled ? THEME.input.background.colors.disabled : base.background,
      border: `1px solid ${hasError ? THEME.input.border.colors.error : THEME.input.border.colors.default}`,
      '&:hover': { borderColor: THEME.input.border.colors.hover }
    }),
    menu: (base) => ({
      ...base,
      border: THEME.border.base,
      borderRadius: '3px',
      borderTop: 'none',
      borderTopLeftRadius: 'unset',
      borderTopRightRadius: 'unset',
      marginTop: 0,
      boxShadow: 'unset'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
      paddingRight: '7px',
      cursor: 'pointer',

      svg: {
        color: THEME.text.colors.gray.lv3,
        height: '17px'
      }
    }),
    placeholder: (base) => ({
      ...base,
      fontWeight: 400,
      fontSize: '14px',
      marginLeft: '5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '95%',
      color: THEME.input.text.colors.placeholder
    })
  }) as StylesConfig<any>;

export const emptyNsCustomer = {
  value: '',
  label: '',
  companyUrl: '',
  countryId: '',
  phoneNumber: ''
};

export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor = 300) => {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      result = callback(...args);
    }, waitFor);

    return result;
  };
};
