import { useInstagramComparePostDetailsQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InstagramComparePostDetailsProps extends Pick<PostDetailsType, 'id'> {
  className?: string;
  onCloseModal?: () => void;
}

export const InstagramComparePostDetails = ({ id, className, onCloseModal }: InstagramComparePostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useInstagramComparePostDetailsQuery({
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: { postId: Number(id) },
    onError: (err) => {
      onCloseModal?.();
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  });

  const details = data?.instagramComparePostDetails;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'INSTAGRAM',
        postUrl: details.postUrl,
        caption: details.content,
        comments: details.commentList || [],
        images: details.postImageUrl ? [details.postImageUrl] : [],
        statistics: { likes: details.analysis?.likes, comments: details.analysis?.comments },
        info: {
          socialMedia: 'INSTAGRAM',
          avatar: details.avatarUrl,
          postDate: details.postDate,
          followers: details.followers,
          name: details.instagramAccountName
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      loading={loading}
      className={className}
      postDetails={postDetails}
      css={{ width: postDetails?.images?.length ? THEME.modal.size.lv3 : THEME.modal.size.lv1 }}
    />
  );
};
