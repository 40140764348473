import { useEngagementPermissions, useUserRoles } from '@/auth';
import {
  EngagementDetail,
  EngagementCampaignStatus,
  useDeleteEngagementInfluencerProposalMutation,
  useAllowEngagementInfluencerProposalListMutation
} from '@/graphql';
import { BlackBar, Button } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';

interface RejectInfluencersActionBarProps {
  numberSelected: number;
  onSelectAll: () => void;
  hasAllSelected?: boolean;
  onToggleSelect: () => void;
  engagement: EngagementDetail;
  selectedInfluencerIds: number[];
}

export const RejectInfluencersActionBar = ({
  onSelectAll,
  onToggleSelect,
  numberSelected,
  hasAllSelected,
  selectedInfluencerIds,
  engagement: { id, status }
}: RejectInfluencersActionBarProps) => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { hideAllowDenyForModal, hideToggleInfluencerFromProposalBtn } = useEngagementPermissions();
  const { callAllowEngagementInfluencerProposalList, loading: backingToList } =
    useAllowEngagementInfluencerProposalListMutation({ onCompleted: invalidateRouteLoader });
  const { callDeleteEngagementInfluencerProposal, loading: deletingFromList } =
    useDeleteEngagementInfluencerProposalMutation({ onCompleted: invalidateRouteLoader });

  const loading = backingToList || deletingFromList;
  const hasRemoveButton = !hideToggleInfluencerFromProposalBtn && isAdminStaffTalentAgencyPartner;
  const hasAllowButton =
    !hideAllowDenyForModal &&
    [EngagementCampaignStatus.REVIEWING, EngagementCampaignStatus.DRAFT, EngagementCampaignStatus.ONGOING].includes(
      status
    );

  const handleAllowSelectedInfluencers = async () => {
    if (selectedInfluencerIds.length === 0) {
      return;
    }

    try {
      await callAllowEngagementInfluencerProposalList({
        variables: { input: { campaignId: id, influencerIds: selectedInfluencerIds } }
      });
      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleDeleteSelectedInfluencers = async () => {
    if (selectedInfluencerIds.length === 0) {
      return;
    }

    try {
      await callDeleteEngagementInfluencerProposal({
        variables: { input: { campaignId: id, influencerIds: selectedInfluencerIds } }
      });
      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <BlackBar
      disabled={loading}
      selectAllProps={{ onClick: onSelectAll }}
      checkboxProps={{
        onClick: onToggleSelect,
        indeterminate: !hasAllSelected,
        label: t('Selected', { count: numberSelected })
      }}
    >
      {hasAllowButton && (
        <Button
          variant="blue"
          disabled={loading}
          loading={backingToList}
          title={t('Button.Back To The List')}
          onClick={handleAllowSelectedInfluencers}
          css={{ padding: '0 16px', marginLeft: '16px' }}
        />
      )}

      {hasRemoveButton && (
        <Button
          variant="red"
          loading={deletingFromList}
          title={t('Button.Delete')}
          onClick={handleDeleteSelectedInfluencers}
          css={{ padding: '0 16px', marginLeft: '16px' }}
          disabled={
            loading ||
            ![
              EngagementCampaignStatus.DRAFT,
              EngagementCampaignStatus.REVIEWING,
              EngagementCampaignStatus.ONGOING,
              EngagementCampaignStatus.UPCOMING
            ].includes(status)
          }
        />
      )}
    </BlackBar>
  );
};
