import { linkOptions } from '@tanstack/react-router';
import { MarketplaceCampaignDetailType } from '@/graphql';
import { AFFILIATE_CAMPAIGN_TYPES } from '@/shared/constants';

export interface MkpReportLinkProps {
  mkpCampaignType?: MarketplaceCampaignDetailType | null;
  isPostTracking?: boolean;
  id: string;
}
export const getMkpReportLink = ({ id, mkpCampaignType, isPostTracking }: MkpReportLinkProps) => {
  const isAffiliateCampaign = mkpCampaignType && AFFILIATE_CAMPAIGN_TYPES.includes(mkpCampaignType);
  const hasAffiliateReport = isAffiliateCampaign && !isPostTracking;

  return hasAffiliateReport
    ? linkOptions({
        to: '/marketplace/$id/report-affiliate',
        params: { id }
      })
    : linkOptions({
        to: '/marketplace/$id/report',
        params: { id }
      });
};
