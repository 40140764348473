import { TFunction } from 'i18next';
import * as z from 'zod';
import { AllTranslationKeys } from '@/shared/assets';
import { CSV_EXPORT_TYPE } from './types';

export const getCsvPagesAndRowsValidationSchema = (t: TFunction, maxRowsLimit: number) =>
  z
    .discriminatedUnion('exportType', [
      z.object({
        limit: z.number(),
        exportType: z.literal(CSV_EXPORT_TYPE.PAGES),
        firstPage: z
          .string()
          .min(1, { message: t<string>('requiredFirstPage') })
          .refine((v) => v !== '0', t<string>('should be more than 0')),
        lastPage: z.string().min(1, { message: t<string>('requiredLastPage') })
      }),
      z.object({
        limit: z.number(),
        exportType: z.literal(CSV_EXPORT_TYPE.ROWS),
        firstRow: z
          .string()
          .min(1, { message: t<string>('requiredFirstRow') })
          .refine((v) => v !== '0', t<string>('should be more than 0')),
        lastRow: z.string().min(1, { message: t<string>('requiredLastRow') })
      })
    ])
    .superRefine((values, ctx) => {
      if (values.exportType === CSV_EXPORT_TYPE.PAGES) {
        const firstPageNumber = +values.firstPage === 1 ? 0 : +values.firstPage;
        const lastPageNumber = +values.lastPage;

        if (lastPageNumber <= firstPageNumber) {
          ctx.addIssue({
            message: t<string>(AllTranslationKeys.Error.lastPageMoreThanFirstPage),
            code: z.ZodIssueCode.custom,
            path: ['lastPage']
          });
        } else if (lastPageNumber - firstPageNumber > Math.round(maxRowsLimit / +values.limit)) {
          ctx.addIssue({
            message: t<string>('maxExportPages', { count: Math.round(maxRowsLimit / +values.limit) }),
            code: z.ZodIssueCode.custom,
            path: ['lastPage']
          });
        }
      } else if (values.exportType === CSV_EXPORT_TYPE.ROWS) {
        const firstRowNumber = +values.firstRow === 1 ? 0 : +values.firstRow;
        const lastRowNumber = +values.lastRow;

        if (lastRowNumber <= firstRowNumber) {
          ctx.addIssue({
            message: t<string>('Last row should be greater than the first row'),
            code: z.ZodIssueCode.custom,
            path: ['lastRow']
          });
        } else if (lastRowNumber - firstRowNumber > maxRowsLimit) {
          ctx.addIssue({
            message: t<string>('maxExportRows', { count: maxRowsLimit }),
            code: z.ZodIssueCode.custom,
            path: ['lastRow']
          });
        }
      }
    });
