import { useUserRoles } from '@/auth';
import { FormProvider, RenderDataWithFallback } from '@/shared/atoms';
import { FormAction } from '@/shared/types';
import { useInitAnalyticsFormValues } from './hooks';
import { getAnalyticsFormSchema } from './schema';
import { Template } from './Template';
import { AnalyticsFormValues } from './schemaTypes';

export type AnalyticsFormProps = FormAction<AnalyticsFormValues>;

export const AnalyticsForm = ({ onSubmit, defaultValues }: AnalyticsFormProps) => {
  const { role } = useUserRoles();
  const { values } = useInitAnalyticsFormValues({ defaultValues });

  return (
    <RenderDataWithFallback loading={!values}>
      <FormProvider onSubmit={onSubmit} values={values} zodSchema={getAnalyticsFormSchema(role)}>
        <Template />
      </FormProvider>
    </RenderDataWithFallback>
  );
};

export * from './utils';
export * from './schemaTypes';
