import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { Modal, ModalContent, ModalFooterActions, ModalProps, ModalTitle } from '@/shared/atoms';

export interface DeleteHashtagModalProps extends ModalProps {
  hashtag?: string;
  loading?: boolean;
  onDelete: () => void;
}

export const DeleteHashtagModal = ({ hashtag, loading, onClose, onDelete, ...restProps }: DeleteHashtagModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} css={{ width: THEME.modal.size.lv1 }} {...restProps}>
      <ModalContent>
        <ModalTitle>
          <>
            <span>{t('Dialog.Delete Hashtag')}</span>
            {hashtag ? (
              <>
                &nbsp;<span>{hashtag}</span>
              </>
            ) : null}
          </>
        </ModalTitle>

        <p css={{ fontSize: '14px', marginTop: '16px' }}>{t('Dialog.DeleteComparableHashtagWarning')}</p>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{ variant: 'red', onClick: onDelete, title: t('Button.Delete') }}
      />
    </Modal>
  );
};
