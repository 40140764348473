import { useTranslation } from 'react-i18next';
import { InstagramReelPost } from '@/graphql';
import { THEME } from '@/shared/constants';
import { MediaPreviewWithMetrics } from '@/shared/atoms';
import { bigIntFormatter } from '@/shared/utils';
import { usePostDetailsParam } from '@/shared/hooks';
import { ImageSlider, InfluencerInstagramAnalyticsReelDetailsModal } from '@/shared/molecules';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { AnalyticsFeedPostsWrapper, AnalyticsFeedPostsWrapperProps } from '../../../shared';
import { useAnalyticsProfileSearch } from '../../../hooks';

export interface AnalyticsInstagramDashboardReelPostsTemplateProps
  extends Pick<AnalyticsFeedPostsWrapperProps, 'title' | 'help' | 'moreDetailProps'> {
  data?: readonly InstagramReelPost[];
}

export const AnalyticsInstagramDashboardReelPostsTemplate = ({
  data = [],
  ...restProps
}: AnalyticsInstagramDashboardReelPostsTemplateProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  return (
    <AnalyticsFeedPostsWrapper
      dataNotAvailable={!data.length}
      title={t('HeaderColumn.Reel Posts')}
      help={t('Tooltip.dashboardReelPosts')}
      {...restProps}
    >
      <ImageSlider total={data.length} css={{ paddingLeft: '16px' }}>
        {data.map(({ id, thumbNail, likes, comments }) => (
          <MediaPreviewWithMetrics
            key={id}
            src={thumbNail}
            css={THEME.mediaPreview.igReelOrStory}
            onClick={() => setPostDetailsParam(id)}
            statistics={[
              { label: t('Like'), value: bigIntFormatter(likes, 0, { k: true, m: true }) },
              { label: t('General.Comments'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
            ]}
            isReel
            showIcon
          />
        ))}
      </ImageSlider>
    </AnalyticsFeedPostsWrapper>
  );
};

export interface AnalyticsInstagramDashboardReelPostsProps {
  brandAccountName?: string;
  data?: readonly InstagramReelPost[];
  accountId: AnalyticsSelectedAccountInfo['id'];
}

export const AnalyticsInstagramDashboardReelPosts = ({
  data = [],
  accountId,
  brandAccountName
}: AnalyticsInstagramDashboardReelPostsProps) => {
  const { filter, params } = useAnalyticsProfileSearch();

  return (
    <>
      <AnalyticsInstagramDashboardReelPostsTemplate
        data={data}
        moreDetailProps={{
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post',
          params,
          search: {
            filter: {
              brandAccountName,
              endDate: filter.endDate,
              startDate: filter.startDate
            }
          }
        }}
      />

      <InfluencerInstagramAnalyticsReelDetailsModal
        isAnalytics
        posts={data.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: accountId }))}
      />
    </>
  );
};
