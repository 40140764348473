import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Modal,
  ModalTitle,
  NoDataTitle,
  ModalContent,
  ModalFooterActions,
  NoDataContentWrapper,
  ChartTooltipSingleMetric,
  NoDataContentWrapperProps
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAllCountryOptions, useToggleState } from '@/shared/hooks';
import { formatPercent } from '@/shared/utils';
import { HighChart } from '../HighChart';
import { InfluencerAvatar } from '../InfluencerAvatar';

interface AreaBreakdownData {
  followerCountryRate?: readonly { id: string; rate: number }[];
  followerRegionRate?: readonly { name: string; rate: number }[];
}

export interface AreaBreakdownChartProps
  extends AreaBreakdownData,
    Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'>,
    Pick<Highcharts.ChartOptions, 'width'> {
  avatar?: string;
  hasCountriesRates?: boolean;
  needFormatRegionName?: boolean;
  hasRelativeMaxY?: boolean;
}

export const AreaBreakdownChart = ({
  width,
  header,
  avatar,
  hasCountriesRates,
  needFormatRegionName,
  followerRegionRate = [],
  followerCountryRate = [],
  hasRelativeMaxY,
  ...noDataContentProps
}: AreaBreakdownChartProps) => {
  const { t } = useTranslation();
  const modalState = useToggleState();
  const { allCountryOptions } = useAllCountryOptions();
  const [chartType, setChartType] = useState<'regions' | 'countries'>('regions');

  useEffect(() => {
    if (hasCountriesRates && !followerRegionRate.length) {
      setChartType('countries');
    }
  }, []);

  const { regionsRates, regions } = followerRegionRate.reduce<{ regionsRates: number[]; regions: string[] }>(
    (acc, curr) => ({ regionsRates: [...acc.regionsRates, curr.rate], regions: [...acc.regions, t(curr.name)] }),
    { regionsRates: [], regions: [] }
  );
  const { countriesRates, countries } = followerCountryRate.reduce<{ countriesRates: number[]; countries: string[] }>(
    (acc, curr) => ({ countriesRates: [...acc.countriesRates, curr.rate], countries: [...acc.countries, t(curr.id)] }),
    { countriesRates: [], countries: [] }
  );

  const regionsNamesFromCode = regions.map<string>((c) => allCountryOptions.find((el) => el.value === c)?.label || '');
  const countriesNamesFromCode = countries.map<string>(
    (c) => allCountryOptions.find((el) => el.value === c)?.label || ''
  );
  const seriesData = chartType === 'regions' ? regionsRates : countriesRates;
  const regionsCategories = needFormatRegionName ? regionsNamesFromCode : regions;
  const categories = chartType === 'regions' ? regionsCategories : countriesNamesFromCode;
  const dataNotAvailable = regionsRates.every((rate) => rate === 0) && countriesRates.every((rate) => rate === 0);

  const chartOptions = (showAll?: boolean): Highcharts.Options => ({
    title: { text: '' },
    credits: { enabled: false },
    plotOptions: { bar: { showInLegend: false, dataLabels: { enabled: false } } },
    chart: { type: 'bar', width: showAll ? 440 : width, height: showAll ? null : 265 },
    xAxis: { title: { text: null }, categories: showAll ? categories : categories.slice(0, 7) },
    series: [{ type: 'bar', color: '#9ea0e0', data: showAll ? seriesData : seriesData.slice(0, 7) }],
    yAxis: {
      min: 0,
      max: hasRelativeMaxY ? null : 1,
      title: { text: '' },
      labels: {
        overflow: 'justify',
        formatter() {
          return formatPercent(Number(this.value), true, 0);
        }
      }
    },
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      borderColor: THEME.chart.tooltip.border.colors.green,
      backgroundColor: THEME.chart.tooltip.background.colors.black,
      style: { color: THEME.chart.tooltip.text.colors.white },
      formatter() {
        return renderToStaticMarkup(
          <ChartTooltipSingleMetric
            name={t(String(this.category))}
            value={formatPercent(this.y)}
            color={this.color as string}
          />
        );
      }
    },
    legend: {
      symbolWidth: 15,
      symbolRadius: 2,
      symbolHeight: 15,
      itemStyle: { fontSize: '13px', color: THEME.text.colors.black.lv1, fontWeight: 'normal' }
    }
  });

  const RegionsCountriesTabs = (
    <div css={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
      <p
        onClick={() => setChartType('regions')}
        css={styles.regionsCountriesTab(!followerRegionRate.length, chartType === 'regions')}
      >
        {t('Regions')}
      </p>
      <p
        onClick={() => setChartType('countries')}
        css={styles.regionsCountriesTab(!followerCountryRate.length, chartType === 'countries')}
      >
        {t('General.Countries')}
      </p>
    </div>
  );

  return (
    <>
      <NoDataContentWrapper
        css={{ padding: '24px 16px' }}
        dataNotAvailable={dataNotAvailable}
        header={
          header ?? (
            <NoDataTitle
              css={{ marginBottom: '24px' }}
              help={t<string>('Tooltip.areaBreakdown')}
              title={t(hasCountriesRates ? 'Area' : 'Area Breakdown')}
            >
              {hasCountriesRates && !dataNotAvailable ? RegionsCountriesTabs : null}
            </NoDataTitle>
          )
        }
        {...noDataContentProps}
      >
        <HighChart options={chartOptions()} />

        <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <span css={styles.labelBlock} />
            <p css={{ color: THEME.text.colors.black.lv1, fontSize: '13px' }}>{t('Total')}</p>
          </div>

          <div css={{ cursor: 'pointer' }} onClick={modalState.open}>
            <span css={{ fontSize: '12px', fontWeight: 600, color: THEME.text.colors.gray.lv3 }}>{t('MORE')}</span>
            <Icon icon="arrow-right" color={THEME.icon.colors.blue} size="11px" css={{ marginLeft: '4px' }} />
          </div>
        </div>
      </NoDataContentWrapper>

      <Modal open={modalState.status} onClose={modalState.close} css={{ width: '488px' }}>
        <ModalContent>
          <ModalTitle css={styles.modalTitle}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <InfluencerAvatar src={avatar} size="24px" css={{ borderRadius: '50%' }} />
              <p css={{ marginLeft: '8px' }}>{t('Follower Area')}</p>
            </div>
            {hasCountriesRates ? RegionsCountriesTabs : null}
          </ModalTitle>

          <HighChart options={chartOptions(true)} />
        </ModalContent>

        <ModalFooterActions cancelButtonProps={{ onClick: modalState.close }} />
      </Modal>
    </>
  );
};

const styles = {
  modalTitle: css({
    display: 'flex',
    marginBottom: '24px',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  labelBlock: css({
    width: '15px',
    height: '15px',
    marginRight: '8px',
    borderRadius: '2px',
    position: 'relative',
    backgroundColor: '#9ea0e0'
  }),
  regionsCountriesTab: (disabled?: boolean, active?: boolean) =>
    css({
      fontSize: '13px',
      position: 'relative',
      '&:hover': { opacity: 0.8, cursor: 'pointer' },
      '&:not(:first-of-type)': {
        marginLeft: '16px',
        '&::before': {
          top: 0,
          left: '-8px',
          width: '1px',
          content: '""',
          height: '18px',
          position: 'absolute',
          backgroundColor: '#dee5ec'
        }
      },
      ...(active ? { color: THEME.text.colors.blue } : {}),
      ...(disabled ? { pointerEvents: 'none', opacity: 0.5 } : {})
    })
};
