import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { ToggleOption } from '@/shared/types';
import { RadiosField, RadiosFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const ApproveInfluencersField = ({
  className,
  required,
  disabled
}: Pick<RadiosFieldProps, 'className' | 'disabled' | 'required'>) => {
  const { t } = useTranslation();

  const options = [
    {
      value: ToggleOption.TRUE,
      label: t('Auto-approve applicant influencers'),
      help: t('Tooltip.Auto-approve applicant influencers')
    },
    {
      value: ToggleOption.FALSE,
      label: t('Manual-approve applicant influencers'),
      help: t('Tooltip.Manual-approve applicant influencers')
    }
  ];

  return (
    <FormStyle.FieldWrapper className={className}>
      <p css={{ fontWeight: 600, fontSize: '14px', marginBottom: '16px' }}>{t('Choose how to approve influencers')}</p>
      <RadiosField
        itemsPerRow={2}
        options={options}
        required={required}
        disabled={disabled}
        css={{ padding: 0, fontSize: '14px' }}
        name={MarketplaceFormKeys.isAutoInfluencerApproval}
      />
    </FormStyle.FieldWrapper>
  );
};
