import { useTranslation } from 'react-i18next';

import { useAllPicUserOptions } from '@/shared/hooks';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';

import { MarketplaceFormKeys } from '../types';

export const InfluencerManagementPICField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { allPicsUserOptions } = useAllPicUserOptions({ fetchPolicy: 'cache-first' });

  return (
    <SelectorField
      options={allPicsUserOptions}
      title={t('Selector.Influencer Management PIC')}
      help={t('Tooltip.Influencer Management PIC')}
      multipleSelectorProps={{ hasSelectAll: false }}
      name={MarketplaceFormKeys.influencerManagementPicIds}
      multiple
      {...props}
    />
  );
};
