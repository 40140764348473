import { useTranslation } from 'react-i18next';

import { Payment, PaymentRequestStatus } from '@/graphql';
import { Table } from '@/shared/atoms';
import { WarningModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';

import { usePaymentsTable, useUpdatePayment } from './hooks';

export type PaymentsListProps = TableData<Payment>;

export const PaymentsList = ({ loading, data = [] }: PaymentsListProps) => {
  const { t } = useTranslation();
  const { updating, selectedPayment, setSelectedPayment, updatePaymentStatus } = useUpdatePayment();
  const { rows, columns } = usePaymentsTable({ data, onSelectedPayment: setSelectedPayment });

  const handleCloseModal = () => {
    setSelectedPayment(null);
  };

  return (
    <>
      <Table loading={loading} data={{ columns, rows }} noTopBorderRadius />

      <WarningModal
        open={!!selectedPayment}
        onClose={handleCloseModal}
        description={`${t(
          selectedPayment?.status === PaymentRequestStatus.PAID
            ? 'Dialog.Are you sure you want to pay to'
            : 'Dialog.Are you sure you want to change the status to Failed for',
          { name: selectedPayment?.name }
        )}?`}
        footerProps={{
          cancelButtonProps: { title: t('Button.Cancel'), onClick: handleCloseModal },
          submitButtonProps: {
            loading: updating,
            onClick: updatePaymentStatus,
            title: t(selectedPayment?.status === PaymentRequestStatus.PAID ? 'Button.Pay' : 'Button.Update')
          }
        }}
      />
    </>
  );
};
