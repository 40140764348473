import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { FormStyle } from '@/shared/atoms';
import {
  useAllAvailableCountryOptions,
  useAllAgenciesForSearchOptions,
  useAllAdvertisersForSearchOptions
} from '@/shared/hooks';
import {
  TextField,
  SelectorField,
  PasswordField,
  CheckBoxField,
  CustomerValues,
  BigQuerySearchField,
  PrivateInfluencersSearchField
} from '@/shared/molecules';
import { TalentAgencyFormKeys, TalentAgencyFormValues } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const { allAgencyOptions } = useAllAgenciesForSearchOptions();
  const { allAdvertiserOptions } = useAllAdvertisersForSearchOptions();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const { isAdmin, isStaff, isAdminStaffTalentAgencyPartner } = useUserRoles();
  const {
    reset,
    watch,
    formState: { isSubmitting }
  } = useFormContext<TalentAgencyFormValues>();

  const values = watch();
  const isAddMode = values.type === 'add';

  const handleClickSendEmail = (_: string, value: boolean) => {
    // we need to clear password fields each time user click send email to prevent validation err on password
    reset({ ...values, password: '', passwordConfirm: '', hasInvitationEmail: value });
  };

  const handleNetsuiteIdChange = (_: TalentAgencyFormKeys, customerValues: CustomerValues) => {
    reset({
      ...values,
      talentAgencyName: customerValues.label,
      netsuiteId: customerValues.value || '',
      countryId: customerValues.countryId || '',
      companyUrl: customerValues.companyUrl || ''
    });
  };

  return (
    <FormStyle.Wrapper css={{ margin: 0, flex: 1 }} hasBorder>
      <div css={{ padding: '24px' }}>
        <FormStyle.SectionTitle index={isAddMode ? 1 : undefined} label={t('Talent Agency Information')} />

        {isAdminStaffTalentAgencyPartner ? (
          <BigQuerySearchField<TalentAgencyFormKeys>
            name="netsuiteId"
            type="netsuiteVendor"
            title={t('Selector.NetSuite Vendor')}
            onChange={handleNetsuiteIdChange}
          />
        ) : null}

        <TextField<TalentAgencyFormKeys>
          name="talentAgencyName"
          help={t<string>('Tooltip.Talent Agency Name')}
          title={t('TextForm.Talent Agency Name')}
          placeholder={t<string>('TextForm.AdAsiaLtd')}
          required
        />

        <SelectorField<TalentAgencyFormKeys>
          title={t('Selector.Agency')}
          name="agencyIds"
          help={t<string>('Tooltip.User Agency')}
          options={allAgencyOptions}
          multiple
        />

        <SelectorField<TalentAgencyFormKeys>
          title={t('Selector.Advertiser')}
          name="advertiserIds"
          help={t<string>('Tooltip.User Advertiser')}
          options={allAdvertiserOptions}
          multiple
        />

        <TextField<TalentAgencyFormKeys>
          name="companyUrl"
          help={t<string>('Tooltip.Talent Agency URL')}
          placeholder="https://app.anytag.jp"
          title={t('TextForm.Talent Agency URL')}
        />

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <TextField<TalentAgencyFormKeys>
            name="phoneNumber"
            help={t<string>('Tooltip.Phone Number')}
            placeholder="+66020485707"
            title={t('TextForm.Phone Number')}
          />

          <SelectorField<TalentAgencyFormKeys>
            title={t('Selector.Country')}
            name="countryId"
            help={t<string>('Tooltip.Agency Country')}
            options={allAvailableCountryOptions}
            required
          />
        </FormStyle.FieldsGroup>

        {isAddMode ? (
          <>
            <FormStyle.SectionTitle index={2} css={{ marginTop: '24px' }} label={t('User Information')} />

            <TextField<TalentAgencyFormKeys>
              name="name"
              title={t('TextForm.Name')}
              placeholder={t<string>('NameExample')}
              required
            />

            <FormStyle.FieldsGroup itemsPerRow={2}>
              <TextField<TalentAgencyFormKeys>
                name="email"
                placeholder="your@email.com"
                title={t('TextForm.Email Address')}
                required
              />
            </FormStyle.FieldsGroup>

            {!values.hasInvitationEmail && (
              <FormStyle.FieldsGroup itemsPerRow={2}>
                <PasswordField<TalentAgencyFormKeys>
                  name="password"
                  title={t('Password.Password')}
                  required
                  dynamicError
                />
                <PasswordField<TalentAgencyFormKeys>
                  name="passwordConfirm"
                  title={t('Password.Confirm Password')}
                  required
                  dynamicError
                />
              </FormStyle.FieldsGroup>
            )}

            <CheckBoxField<TalentAgencyFormKeys>
              name="hasInvitationEmail"
              onChange={handleClickSendEmail}
              label={t('Send invitation email')}
            />
          </>
        ) : values.type === 'edit' ? (
          <>
            <PrivateInfluencersSearchField<TalentAgencyFormKeys>
              name="privateInfluencersIds"
              agencyType="talent"
              disabled={!isAdmin}
              help={t<string>('Tooltip.Private influencers')}
              title={t<string>('Selector.Private influencers')}
              agencyId={Number(values.id)}
              placeholder={t<string>('Influencer name')}
            />

            <FormStyle.FieldsGroup itemsPerRow={2}>
              <TextField<TalentAgencyFormKeys>
                unit="%"
                placeholder="10"
                name="cmsMarginRate"
                title={t('TextForm.CG BGM Margin Rate')}
                help={t('Tooltip.Talent Agency Rate Help Text')}
              />
            </FormStyle.FieldsGroup>
          </>
        ) : null}
      </div>

      <FormStyle.ButtonSubmitWrapper>
        <FormStyle.ButtonSubmit disabled={isStaff} type="submit" loading={isSubmitting} title={t('Button.Save')} />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};
