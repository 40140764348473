import { InstagramDashboardFollowerAnalytics } from '@/graphql';
import { ChartsContainer } from '@/shared/atoms';
import { AgeBreakdownChart, AreaBreakdownChart, GenderBreakdownChart } from '@/shared/molecules';
import { AnalyticsAudienceWrapper } from '../../../shared';
import { AnalyticsSelectedAccountInfo } from '../../../types';

export interface AnalyticsInstagramDashboardAudienceTemplateProps {
  loading?: boolean;
  className?: string;
  data?: InstagramDashboardFollowerAnalytics | null;
  accountAvatar?: AnalyticsSelectedAccountInfo['avatar'];
}

export const AnalyticsInstagramDashboardAudienceTemplate = ({
  data,
  loading,
  className,
  accountAvatar
}: AnalyticsInstagramDashboardAudienceTemplateProps) => (
  <AnalyticsAudienceWrapper loading={loading} data={data} className={className}>
    {({ data: { followerAgeRate, followerGenderRate, followerRegionRate, followerCountryRate } }) => (
      <ChartsContainer css={{ height: '385px' }}>
        <GenderBreakdownChart {...followerGenderRate} />
        <AgeBreakdownChart {...followerAgeRate} />
        <AreaBreakdownChart
          avatar={accountAvatar}
          followerRegionRate={followerRegionRate || []}
          followerCountryRate={followerCountryRate || []}
          hasCountriesRates
        />
      </ChartsContainer>
    )}
  </AnalyticsAudienceWrapper>
);
