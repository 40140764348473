import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignChartReport, CampaignSocialMediaType } from '@/graphql';
import { Highcharts } from '@/shared/molecules';
import { AppLanguage } from '@/shared/types';
import { formatIntNumber, localizedDateFormatter, generateYAxisBasedOnDisplayedCharts } from '@/shared/utils';
import { EngagementReportChartMetricKeys, EngagementReportOption } from './types';

interface Props {
  data?: readonly CampaignChartReport[];
  socialMedias: readonly CampaignSocialMediaType[];
}

export const useEngagementReportChart = ({ data, socialMedias }: Props) => {
  const { t, i18n } = useTranslation();
  const allMetrics: Record<EngagementReportChartMetricKeys, EngagementReportOption> = {
    cost: { label: t<string>('Cost'), value: 'cost', color: '#FF2B52' },
    view: { label: t<string>('Views'), value: 'view', color: '#E88924' },
    like: { label: t<string>('Likes'), value: 'like', color: '#E5918F' },
    share: { label: t<string>('Shares'), value: 'share', color: '#E9BAA6' },
    comment: { label: t<string>('Comments'), value: 'comment', color: '#E9BAA6' },
    engagement: { label: t<string>('Engagement'), value: 'engagement', color: '#FF772B' },
    averageViewDurationSeconds: {
      label: t<string>('Average view duration'),
      value: 'averageViewDurationSeconds',
      color: '#4992C3'
    }
  };
  const [selectedMetric, setSelectedMetric] = useState<EngagementReportOption['value']>(allMetrics.cost.value);

  const availableMetricOptions = (() => {
    // Mixed case
    if ((socialMedias?.length || 0) > 1) {
      return [allMetrics.cost, allMetrics.engagement, allMetrics.like];
    } else if (socialMedias?.length === 1) {
      switch (socialMedias[0]) {
        case CampaignSocialMediaType.TWITTER:
        case CampaignSocialMediaType.INSTAGRAM:
        case CampaignSocialMediaType.INSTAGRAM_STORY:
          return [allMetrics.cost, allMetrics.engagement, allMetrics.like, allMetrics.comment];
        case CampaignSocialMediaType.THREADS:
          return [
            allMetrics.cost,
            allMetrics.engagement,
            allMetrics.like,
            { ...allMetrics.comment, label: t<string>('Replies') }
          ];
        case CampaignSocialMediaType.FACEBOOK:
          return [allMetrics.cost, allMetrics.engagement, allMetrics.like];
        case CampaignSocialMediaType.TIKTOK: {
          const hasLikeMetric = data?.map((item) => item.like).some((item) => item !== null);
          const hasViewMetric = data?.map((item) => item.view).some((item) => item !== null);

          return [
            allMetrics.cost,
            allMetrics.engagement,
            ...(hasLikeMetric ? [allMetrics.like] : []),
            ...(hasViewMetric ? [allMetrics.view] : [])
          ];
        }
        case CampaignSocialMediaType.YOUTUBE:
          return [
            allMetrics.cost,
            allMetrics.engagement,
            allMetrics.like,
            allMetrics.view,
            allMetrics.share,
            allMetrics.averageViewDurationSeconds
          ];
        default:
          return [];
      }
    }

    return [];
  })();
  const categories = data?.map((d) => d.date) || [];
  const yAxis = generateYAxisBasedOnDisplayedCharts(Object.keys(allMetrics), false);
  const series = [
    {
      zIndex: 1,
      type: 'line',
      name: selectedMetric ? allMetrics[selectedMetric]?.label : '',
      color: selectedMetric ? allMetrics[selectedMetric]?.color : '',
      data: data?.map((item) => (selectedMetric && item[selectedMetric]) ?? 0) || [],
      marker: { lineWidth: 2, symbol: 'circle', fillColor: '#fff', lineColor: undefined }
    }
  ];
  const tooltipData = categories.reduce<Record<string, string>>(
    (rs, cat, index) => ({ ...rs, [cat]: formatIntNumber(series.at(0)?.data[index]) }),
    {}
  );
  const chartOptions: Highcharts.Options = {
    chart: { type: 'column' },
    title: { text: '' },
    xAxis: {
      categories,
      crosshair: true,
      type: 'category',
      labels: {
        formatter: function formatter() {
          return localizedDateFormatter(this.value, 'MMM do', i18n.language as AppLanguage);
        }
      }
    },
    yAxis,
    legend: { enabled: false },
    tooltip: {
      useHTML: true,
      borderRadius: 3,
      borderColor: '#27313b',
      shadow: false,
      formatter: function formatter(): string {
        return `
          <div style="font-size: 12px; padding: 4px; box-sizing: border-box; min-width: 100px;">
            <h3 style="margin-bottom: 8px; font-size: 13px; color: #fff;">${this.key ?? ''}</h3>                        
            <p style="color: #c5d0da; margin-bottom: 4px; font-size: 11px;">${t(
              (selectedMetric && allMetrics[selectedMetric]?.label) || ''
            )}</p>
            <p style="font-size: 13px; color: #fff;">${this.key ? tooltipData[this.key] : ''}</p>
            </div>
          </div>
        `;
      },
      pointFormat: '',
      style: { zIndex: 1000 }
    },
    series: series as Highcharts.SeriesOptionsType[]
  };

  return {
    chartOptions,
    selectedMetric,
    setSelectedMetric,
    availableMetricOptions
  };
};
