import {
  InfluencerAnalyticsFacebookProfileAudience,
  InfluencerAnalyticsFacebookProfileFeedPosts,
  InfluencerAnalyticsFacebookProfilePostsOverview
} from '../../InfluencerAnalyticsProfiles';
import { InfluencerAnalyticsFacebookProfileOverview } from '../../InfluencerAnalyticsProfiles/InfluencerAnalyticsFacebookProfile/InfluencerAnalyticsFacebookProfileOverview';
import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsFacebookProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerAnalyticsFacebookProfile = ({ socialAccount }: InfluencerAnalyticsFacebookProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerAnalyticsFacebookProfileOverview socialAccount={socialAccount} influencerId={influencerProfile.id} />
      <InfluencerAnalyticsFacebookProfileAudience socialAccount={socialAccount} influencerProfile={influencerProfile} />
      <InfluencerAnalyticsFacebookProfilePostsOverview
        socialAccount={socialAccount}
        influencerId={influencerProfile.id}
      />
      <InfluencerAnalyticsFacebookProfileFeedPosts socialAccount={socialAccount} influencerId={influencerProfile.id} />
    </>
  );
};
