import { getValuableVariables } from '@/shared/utils';
import { useInstagramDashboardOverviewQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { AnalyticsInstagramDashboardOverviewTemplate } from './AnalyticsInstagramDashboardOverviewTemplate';

export interface AnalyticsInstagramDashboardOverviewProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsInstagramDashboardOverview = ({ analyticsAccount }: AnalyticsInstagramDashboardOverviewProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useInstagramDashboardOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      accountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsInstagramDashboardOverviewTemplate
      loading={loading}
      accountId={analyticsAccount.id}
      data={data?.instagramDashboardOverview}
    />
  );
};
