import { ReactNode, useEffect, useRef, useState } from 'react';

export const useChatBoxToastMessage = () => {
  const toastTimer = useRef<NodeJS.Timeout>();
  const [toastMessage, setToastMessage] = useState<{ type: 'error' | 'info' | 'success'; message: ReactNode } | null>(
    null
  );

  useEffect(() => {
    if (toastTimer.current) {
      clearTimeout(toastTimer.current);
    }

    if (toastMessage) {
      toastTimer.current = setTimeout(() => {
        clearToastMessage();
      }, 5000);
    }

    return () => {
      clearTimeout(toastTimer.current);
    };
  }, [toastTimer, toastMessage]);

  const clearToastMessage = () => {
    setToastMessage(null);
  };

  return { toastMessage, setToastMessage, clearToastMessage };
};
