import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllTiktokSpecialCampaigns.graphql';
import { Query, QueryallTiktokSpecialCampaignsArgs } from '../../__generated__/globalTypes';

export const useAllTiktokSpecialCampaignsQuery = (
  options?: QueryHookOptions<Query, QueryallTiktokSpecialCampaignsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallTiktokSpecialCampaignsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllTiktokSpecialCampaignsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallTiktokSpecialCampaignsArgs>
): QueryResult<Query, QueryallTiktokSpecialCampaignsArgs> & {
  getAllTiktokSpecialCampaigns: LazyQueryExecFunction<Query, QueryallTiktokSpecialCampaignsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllTiktokSpecialCampaigns, result] = useLazyQuery<Query, QueryallTiktokSpecialCampaignsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllTiktokSpecialCampaigns, ...result };
};

export const useAllTiktokSpecialCampaignsPromiseQuery = () => {
  const client = useApolloClient();

  const getAllTiktokSpecialCampaigns = useCallback(
    (options?: Omit<QueryOptions<QueryallTiktokSpecialCampaignsArgs, Query>, 'query'>) =>
      client.query<Query, QueryallTiktokSpecialCampaignsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllTiktokSpecialCampaigns };
};
