import { SocialPostType, useInfluencerPostDetailQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailStatistics, PostDetailsType } from '@/shared/types';
import { checkIsReelPost } from '@/shared/utils';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InfluencerPostDetailsProps extends Pick<PostDetailsType, 'id' | 'influencerId' | 'campaignType'> {
  className?: string;
  onCloseModal?: () => void;
  hasSimilarPosts?: boolean;
}

export const InfluencerPostDetails = ({
  id,
  className,
  influencerId,
  onCloseModal,
  hasSimilarPosts = false
}: InfluencerPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useInfluencerPostDetailQuery({
    skip: !id || !influencerId,
    variables: { postId: Number(id), pk: Number(influencerId), needSimilarPosts: hasSimilarPosts },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const details = data?.influencerPostDetail;
  const statistics: PostDetailStatistics = (() => {
    switch (details?.socialMedia) {
      case SocialPostType.INSTAGRAM:
        return { likes: details?.likes, reach: undefined, comments: details?.comments };
      case SocialPostType.INSTAGRAM_REEL:
      case SocialPostType.YOUTUBE:
        return { likes: details?.likes, views: details?.views, comments: details?.comments };
      case SocialPostType.TWITTER:
        return { likes: details?.likes, retweets: details?.shares, replies: details?.comments };
      case SocialPostType.THREADS:
        return { likes: details?.likes, replies: details?.comments };
      case SocialPostType.DOUYIN:
        return { likes: details?.likes, views: details?.views, shares: details.shares, comments: details?.comments };
      case SocialPostType.TIKTOK:
        return {
          likes: details?.likes,
          views: details?.views,
          comments: details?.comments,
          shares: details?.shares
        };
      case SocialPostType.FACEBOOK:
      case SocialPostType.FACEBOOK_PAGE:
        return {
          reactions: details?.reactions,
          comments: details?.comments,
          shares: details?.shares,
          engagement: details?.engagement
        };
      case SocialPostType.XHS:
        return {
          likes: details?.likes,
          views: details?.views,
          saves: details?.saves
        };
      default:
        return {};
    }
  })();
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        statistics,
        caption: details?.caption,
        postUrl: details?.postUrl || '',
        similarPosts: details.similarPosts || [],
        comments: details?.commentsContent || [],
        isReel: checkIsReelPost(details?.postUrl),
        socialMedia: details?.socialMedia as SocialPostType,
        images: !details?.thumbNail ? [] : [details.thumbNail],
        info: {
          avatar: details?.avatar,
          id: String(details?.influencerId ?? ''),
          postDate: details?.postDate,
          name: details?.influencerName,
          followers: details?.followersCount
        }
      }
    : undefined;

  return <DefaultPostDetailsTemplate loading={loading} className={className} postDetails={postDetails} />;
};
