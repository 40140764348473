import tlds from 'tlds';
import LinkifyIt from 'linkify-it';
import { useTranslation } from 'react-i18next';
import { Label, Textarea } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

interface LinkifyItInputProps {
  value: string;
  onChange: (value: string) => void;
}
export const LinkifyItInput = ({ value, onChange }: LinkifyItInputProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t<string>('draftPostModificationPlaceholder')}
        css={{ marginTop: '8px', height: '114px', whiteSpace: 'pre-line' }}
      />

      <Label title={t('Preview')} css={{ marginTop: '16px', fontSize: '14px', fontWeight: 400 }} />
      <LinkifyItPreview value={value} css={{ marginTop: '8px' }} />
    </div>
  );
};

export const LinkifyItPreview = ({ value, className }: { value: string; className?: string }) => {
  const linkify = new LinkifyIt();
  linkify.tlds(tlds);

  // Function to convert text with URLs into clickable links
  const parseText = (text: string) => {
    const matches = linkify.match(text);
    if (!matches) return text;

    let lastIndex = 0;
    const elements = [];

    matches.forEach((match, index) => {
      // Add text before the link
      if (match.index > lastIndex) {
        elements.push(<span key={`text-${index}`}>{text.slice(lastIndex, match.index)}</span>);
      }

      // Add the link
      elements.push(
        <a
          key={`link-${index}`}
          href={match.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue', textDecoration: 'underline', display: 'inline' }}
        >
          {match.text}
        </a>
      );

      lastIndex = match.lastIndex;
    });

    // Add remaining text after the last link
    if (lastIndex < text.length) {
      elements.push(' ' + text.slice(lastIndex));
    }

    return elements;
  };

  return (
    <div
      css={{
        overflow: 'auto',
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces',
        maxHeight: '400px',
        padding: '12px',
        borderRadius: '3px',
        border: THEME.border.base,
        background: THEME.background.colors.white
      }}
      className={className}
    >
      {parseText(value)}
    </div>
  );
};
