import { useTranslation } from 'react-i18next';
import Dropzone, { Accept, DropEvent, FileRejection } from 'react-dropzone';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Button } from '../Button';

interface EmptyPreviewProps {
  name: string;
  accept?: Accept;
  error?: boolean;
  notes?: string[];
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
  onDrop: (acceptedFiles: File[], rejectedFiles?: FileRejection[], event?: DropEvent) => void;
}

export const EmptyPreview = ({
  notes,
  name,
  accept,
  onDrop,
  multiple,
  className,
  disabled = false
}: EmptyPreviewProps) => {
  const { t } = useTranslation();

  return (
    <div css={className}>
      <Dropzone multiple={multiple} accept={accept} onDrop={onDrop} disabled={disabled}>
        {({ getRootProps, getInputProps }) => (
          <div css={styles.content(disabled)} {...getRootProps()}>
            <Button variant="white" title={t('Upload File')} disabled={disabled} css={{ padding: '0 16px' }} />
            <p css={styles.label}>{t('drop the file and upload')}</p>
            {notes?.map((note) => (
              <p css={styles.note} key={note}>
                {note}
              </p>
            ))}
            <input name={name} {...getInputProps()} />
          </div>
        )}
      </Dropzone>
    </div>
  );
};
const styles = {
  label: css({
    margin: '16px 0',
    fontSize: '14px',
    fontWeight: 'normal',
    color: THEME.text.colors.gray.lv3
  }),
  note: css({
    width: '100%',
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'center',
    color: THEME.text.colors.gray.lv3
  }),
  content: (disabled?: boolean) =>
    css({
      width: '100%',
      height: '100%',
      outline: 'none',
      display: 'flex',
      padding: '16px',
      alignItems: 'center',
      flexDirection: 'column',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      cursor: disabled ? 'not-allowed' : 'pointer'
    })
};
