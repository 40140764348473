import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { EngagementCampaignPostStatusForSearch } from '@/graphql';

export const EngagementPostsFilterSchema = z.object({
  keyword: FilterValidations.string(),
  status: FilterValidations.enum(EngagementCampaignPostStatusForSearch)
});
export const EngagementPostsSearchSchema = z.object({
  filter: EngagementPostsFilterSchema.default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type EngagementPostsSearchSchemaType = z.infer<typeof EngagementPostsSearchSchema>;
export type EngagementPostsFilterFormValues = z.infer<typeof EngagementPostsFilterSchema>;
export type EngagementPostsFilterFormKeys = UnionOfKeys<EngagementPostsFilterFormValues>;
