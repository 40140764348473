import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthConnectableFacebookPageAnalytics.graphql';
import { Mutation, MutationsocialAuthConnectableFacebookPageAnalyticsArgs } from '../../__generated__/globalTypes';

export const useSocialAuthConnectableFacebookPageAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthConnectableFacebookPageAnalyticsArgs>
) => {
  const [callSocialAuthConnectableFacebookPageAnalytics, result] = useMutation<
    Mutation,
    MutationsocialAuthConnectableFacebookPageAnalyticsArgs
  >(MUTATION, options);

  return { callSocialAuthConnectableFacebookPageAnalytics, ...result };
};

export type SocialAuthConnectableFacebookPageAnalyticsMutationFunction =
  Mutation['socialAuthConnectableFacebookPageAnalytics'];
