import { FormStyle } from '@/shared/atoms';
import { PostReportFilter } from '@/shared/organisms';
import { TikTokSpecialCampaignDetail } from '@/graphql';
import { useTikTokCampaignReportFilter } from './hooks';
import { DownloadTikTokReportButton } from './DownloadTikTokReportButton';

export interface TikTokCampaignReportFilterProps {
  hasNoData?: boolean;
  tiktokSpecialCampaign: TikTokSpecialCampaignDetail;
}
export const TikTokCampaignReportFilter = ({ hasNoData, tiktokSpecialCampaign }: TikTokCampaignReportFilterProps) => {
  const { filter, setFilterForm } = useTikTokCampaignReportFilter();

  return (
    <PostReportFilter filter={filter} setFilter={setFilterForm}>
      <FormStyle.FieldWrapper css={{ flex: 'none' }}>
        <DownloadTikTokReportButton disabled={hasNoData} tiktokSpecialCampaign={tiktokSpecialCampaign} />
      </FormStyle.FieldWrapper>
    </PostReportFilter>
  );
};
