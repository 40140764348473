import { FormProvider } from '@/shared/atoms';
import { Template } from './Template';
import { TalentAgencyFormSchema } from './schema';
import { TalentAgencyFormProps, TalentAgencyFormValues } from './types';

export const TalentAgencyForm = ({ onSubmit, defaultValues }: TalentAgencyFormProps) => (
  <FormProvider<TalentAgencyFormValues>
    onSubmit={onSubmit}
    defaultValues={{
      type: defaultValues?.type,
      agencyIds: defaultValues?.agencyIds || [],
      countryId: defaultValues?.countryId || '',
      companyUrl: defaultValues?.companyUrl || '',
      netsuiteId: defaultValues?.netsuiteId || '',
      phoneNumber: defaultValues?.phoneNumber || '',
      advertiserIds: defaultValues?.advertiserIds || [],
      talentAgencyName: defaultValues?.talentAgencyName || '',
      ...(defaultValues?.type === 'add' && {
        name: defaultValues?.name || '',
        email: defaultValues?.email || '',
        password: defaultValues?.password || '',
        passwordConfirm: defaultValues?.passwordConfirm || '',
        hasInvitationEmail: true
      }),
      ...(defaultValues?.type === 'edit' && {
        id: defaultValues.id,
        cmsMarginRate: defaultValues?.cmsMarginRate || '',
        privateInfluencersIds: defaultValues?.privateInfluencersIds || []
      })
    }}
    zodSchema={TalentAgencyFormSchema}
  >
    <Template />
  </FormProvider>
);

export * from './types';
