import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { mkpOnboardingImage } from '@/shared/assets';
import { Anchor, ButtonLink, FadeInImage } from '@/shared/atoms';

export const MarketplaceOnboarding = () => {
  const { t, i18n } = useTranslation();

  return (
    <div css={styles.wrapper}>
      <FadeInImage src={mkpOnboardingImage} css={{ width: '250px', aspectRatio: 'auto 242 / 160' }} />
      <h3 css={styles.heading}>{t('Create Marketplace Campaigns')}</h3>
      <p css={styles.description}>
        {t(
          'Influencers can join campaigns for creating UGC content on social media, enabling you to quickly scale your campaigns'
        )}
      </p>
      <ButtonLink
        variant="blue"
        to="/marketplace/add"
        css={styles.linkButton}
        title={t('Button.Select campaign type')}
      />
      <div css={styles.helpLink}>
        <span>{t('Learn more about')}</span>&nbsp;
        <Anchor
          variant="blue"
          target="_blank"
          label={t('Marketplace campaign')}
          href={
            i18n.language === 'ja'
              ? 'https://anymind-group.gitbook.io/anytag-3/campaign/marketplace'
              : 'https://anymind-group.gitbook.io/anytag-3/v/english/campaign/marketplace '
          }
        />
      </div>
    </div>
  );
};

const styles = {
  linkButton: css({ padding: '0 32px', marginTop: '40px', width: 'max-content' }),
  helpLink: css({ display: 'flex', fontSize: '14px', marginTop: '24px', fontWeight: '400' }),
  heading: css({ fontSize: '16px', fontWeight: '600', marginTop: '24px', lineHeight: '130%' }),
  wrapper: css({
    display: 'flex',
    padding: '32px',
    placeItems: 'center',
    flexDirection: 'column',
    backgroundColor: THEME.background.colors.white
  }),
  description: css({
    width: '460px',
    fontWeight: 400,
    fontSize: '14px',
    marginTop: '16px',
    lineHeight: '120%',
    textAlign: 'center'
  })
};
