import { useTranslation } from 'react-i18next';
import { SocialAccountStatus, SponsoredPostSortField, InfluencerSponsoredPost } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';

type CustomColumnProps = ColumnProps<SponsoredPostSortField>;

interface Props {
  status?: SocialAccountStatus;
  data: readonly InfluencerSponsoredPost[];
}

export const useInfluencerSponsoredPostsListTable = ({ data, status }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const isSignedUp = status === SocialAccountStatus.SIGNED_UP;
  const columns: CustomColumnProps[] = [
    {
      title: <div css={{ display: 'flex', alignItems: 'center' }}>{t('HeaderColumn.Post')}</div>,
      sticky: { active: true, hasBorderRight: true }
    },
    getMetricColumnProps({
      title: t('HeaderColumn.View'),
      help: t<string>('Tooltip.ViewHelp'),
      sortField: SponsoredPostSortField.VIEW
    }),
    getMetricColumnProps({
      help: t<string>('Tooltip.ReachPostHelp'),
      sortField: SponsoredPostSortField.REACH,
      title: t(isSignedUp ? 'HeaderColumn.Reach' : 'HeaderColumn.Est Reach')
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      help: t<string>('Tooltip.LikeHelp'),
      sortField: SponsoredPostSortField.LIKE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      help: t<string>('Tooltip.CommentHelp'),
      sortField: SponsoredPostSortField.COMMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.share'),
      help: t<string>('Tooltip.shareHelp'),
      sortField: SponsoredPostSortField.SHARE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Save'),
      help: t<string>('Tooltip.SaveHelp'),
      sortField: SponsoredPostSortField.SAVED
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      help: t<string>('Tooltip.PostDateHelp'),
      sortField: SponsoredPostSortField.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({ id, views, reaches, likes, saved, shares, content, postedDate, comments, thumbNail, postUrl, socialMedia }) => [
      <TableCell.Post
        postUrl={postUrl}
        title={content || ''}
        thumbnail={thumbNail || ''}
        socialPostType={socialMedia}
        onClick={() => setPostDetailsParam(id)}
      />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={formatIntNumber(reaches)} />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(shares || 0)} />,
      <TableCell.Number value={formatIntNumber(saved)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postedDate} />
    ]
  );

  return { rows, columns };
};
