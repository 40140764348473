import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { YoutubeCmsInvoice } from '@/graphql';
import { useInvoicesTable } from './hooks';

export type InvoicesListProps = TableData<YoutubeCmsInvoice>;

export const InvoicesList = ({ loading, data = [] }: InvoicesListProps) => {
  const { rows, columns } = useInvoicesTable({ data });

  return <Table loading={loading} data={{ columns, rows, rowHeight: '48px' }} noTopBorderRadius />;
};
