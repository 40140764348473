import { z } from 'zod';
import { superRefineField } from '@/shared/utils';
import { BaseValidations } from '@/shared/validations';
import { TikTokSpecialCampaignStatus } from '@/graphql';

export const TikTokCampaignSchema = z
  .object({
    id: z.number().optional(),
    isDraft: z.boolean(),
    sampleUrl: z.string(),
    productUrl: z.string(),
    materialUrl: z.string(),
    saveOnLeave: z.boolean(), // Flag to detect save draft by submit button or prompt button
    title: BaseValidations.CampaignTitleSchema,
    status: z.nativeEnum(TikTokSpecialCampaignStatus),
    materials: z.array(z.string()).or(z.tuple([])).readonly(),
    // required fields
    endDate: z.string(),
    startDate: z.string(),
    requirement: z.string(),
    preLaunchDate: z.string(),
    campaignCategoryId: z.string(),
    hashtags: z.array(z.string()),
    serviceInformation: z.string(),
    thumbnails: z.array(z.string()).or(z.tuple([])).readonly()
  })
  .superRefine((values, ctx) => {
    if (!values.isDraft) {
      const { refineField } = superRefineField(values, ctx);

      refineField('hashtags', BaseValidations.HashtagsSchema);
      refineField('requirement', BaseValidations.RequirementSchema);
      refineField('thumbnails', BaseValidations.ThumbnailsSchema.readonly());
      refineField('campaignCategoryId', BaseValidations.CampaignCategorySchema);
      refineField('serviceInformation', BaseValidations.ServiceInformationSchema);
      refineField('endDate', BaseValidations.PeriodEndDateRequiredSchema);
      refineField('startDate', BaseValidations.PeriodStartDateRequiredSchema);
    }
  });
