import { Maybe, TikTokSpecialDraftAdPostInfo } from '@/graphql';

// can be draft or boosted
export const getSparkAdsSettingsDefaultValues = (boostedInfo?: Maybe<TikTokSpecialDraftAdPostInfo>) => ({
  callToAction: boostedInfo?.callToAction ?? false,
  authCode: boostedInfo?.authPostCode || '',
  adAccountSystemId: boostedInfo?.adAccountSystemId?.toString() || '',
  tkCampaignId: boostedInfo?.adCampaignId || '',
  adGroupId: boostedInfo?.adGroupId || '',
  adName: boostedInfo?.adName || '',
  landingPageUrl: boostedInfo?.landingUrl || ''
});
