import { PostDetailsType } from '@/shared/types';
import { InstagramInteractionTaggedPostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface InstagramInteractionTaggedPostDetailsModalProps {
  posts: PostDetailsType[];
}

export const InstagramInteractionTaggedPostDetailsModal = ({
  posts
}: InstagramInteractionTaggedPostDetailsModalProps) => (
  <CarouselTemplateModal posts={posts}>
    {({ selectedPost, closeModal }) => (
      <InstagramInteractionTaggedPostDetails
        id={selectedPost.id}
        onCloseModal={closeModal}
        mainSocialAccountId={selectedPost.mainSocialAccountId}
      />
    )}
  </CarouselTemplateModal>
);
