import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UploadEngagementXhsInsightCheck.graphql';
import { Mutation, MutationuploadEngagementXhsInsightCheckArgs } from '../../__generated__/globalTypes';

export const useUploadEngagementXhsInsightCheckMutation = (
  options?: MutationHookOptions<Mutation, MutationuploadEngagementXhsInsightCheckArgs>
) => {
  const [callUploadEngagementXhsInsightCheck, result] = useMutation<
    Mutation,
    MutationuploadEngagementXhsInsightCheckArgs
  >(MUTATION, options);

  return { callUploadEngagementXhsInsightCheck, ...result };
};

export type UploadEngagementXhsInsightCheckMutationFunction = Mutation['uploadEngagementXhsInsightCheck'];
