import { zodValidator } from '@tanstack/zod-adapter';
import { PermissionRoute } from '@/app-components';
import { Invoices } from '@/pages/Invoices';
import { adminStaffRoles } from '@/shared/constants';
import { InvoicesSearchSchema } from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const InvoicesRouter = {
  '/_private-routes/invoices': [
    () => <PermissionRoute permissions={adminStaffRoles} element={<Invoices />} />,
    { validateSearch: zodValidator(InvoicesSearchSchema) }
  ]
} as const satisfies PartialRouter;
