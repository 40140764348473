import { SocialMediaProviderType, SupportedSocialMediaType } from '@/shared/types';

export const getSocialMediaText = <T extends string = SupportedSocialMediaType>(socialMedia?: T): string => {
  switch (socialMedia) {
    case SupportedSocialMediaType.FACEBOOK:
      return 'Facebook';
    case SupportedSocialMediaType.FACEBOOK_PAGE:
      return 'Facebook Fanpage';
    case SupportedSocialMediaType.INSTAGRAM:
    case SupportedSocialMediaType.INSTAGRAM_REEL:
      return 'Instagram';
    case SupportedSocialMediaType.INSTAGRAM_STORY:
    case SupportedSocialMediaType.INSTAGRAM_BUSINESS:
      return 'Instagram Story';
    case SupportedSocialMediaType.YOUTUBE:
      return 'YouTube';
    case SupportedSocialMediaType.TWITTER:
      return 'X(Twitter)';
    case SupportedSocialMediaType.TIKTOK:
      return 'TikTok';
    case SupportedSocialMediaType.THREADS:
      return 'Threads';
    case SupportedSocialMediaType.DOUYIN:
      return 'Douyin';
    case SupportedSocialMediaType.XHS:
      return 'Xiaohongshu';
    default:
      return '';
  }
};
export const getSocialMediaTextLink = <T extends string = SupportedSocialMediaType>(socialMedia?: T): string => {
  switch (socialMedia) {
    case SupportedSocialMediaType.FACEBOOK:
    case SupportedSocialMediaType.FACEBOOK_PAGE:
      return 'Facebook';
    case SupportedSocialMediaType.INSTAGRAM:
    case SupportedSocialMediaType.INSTAGRAM_REEL:
    case SupportedSocialMediaType.INSTAGRAM_STORY:
    case SupportedSocialMediaType.INSTAGRAM_BUSINESS:
      return 'Instagram';
    case SupportedSocialMediaType.YOUTUBE:
      return 'YouTube';
    case SupportedSocialMediaType.TWITTER:
      return 'X(Twitter)';
    case SupportedSocialMediaType.TIKTOK:
      return 'TikTok';
    case SupportedSocialMediaType.THREADS:
      return 'Threads';
    case SupportedSocialMediaType.DOUYIN:
      return 'Douyin';
    case SupportedSocialMediaType.XHS:
      return 'Xiaohongshu';
    default:
      return '';
  }
};
export const getSocialMediaBackgroundColor = <T extends string = SupportedSocialMediaType>(socialMedia?: T): string => {
  switch (socialMedia) {
    case SupportedSocialMediaType.FACEBOOK:
    case SupportedSocialMediaType.FACEBOOK_PAGE:
      return '#4460a0';
    case SupportedSocialMediaType.INSTAGRAM:
      return 'linear-gradient(94deg, #d82e7c 0%, #ff8404 99%)';
    case SupportedSocialMediaType.TWITTER:
      return '#000';
    case SupportedSocialMediaType.YOUTUBE:
      return '#ce1312';
    case SupportedSocialMediaType.TIKTOK:
    case SupportedSocialMediaType.DOUYIN:
    case SupportedSocialMediaType.THREADS:
      return '#27313B';
    case SupportedSocialMediaType.XHS:
      return '#EB4553';
    default:
      return '#F6F8FA'; // for email signed up accounts without SM connected
  }
};

export const getSocialMediaToProviderType = (
  socialMedia: keyof typeof SupportedSocialMediaType
): SocialMediaProviderType => {
  switch (socialMedia) {
    case 'INSTAGRAM':
      return 'instagram';
    case 'FACEBOOK':
    case 'FACEBOOK_PAGE':
      return 'facebook';
    case 'TWITTER':
      return 'twitter';
    case 'YOUTUBE':
      return 'youtube';

    default:
      throw new Error('SocialMedia type mismatch');
  }
};
// https://adasiaholdings.atlassian.net/browse/AT-3872: (Dat) BE return only IG and need detect ig story based on post URL
export const checkIsInstagramReelPost = (postUrl: string) => postUrl.includes('https://www.instagram.com/reel');
export const checkIsInstagramStoryPost = (postUrl: string) => postUrl.includes('https://www.instagram.com/stories');
