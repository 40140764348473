import { AutoManagedCampaignStatus, useUpdateAutoManagedCampaignsStatusMutation } from '@/graphql';
import { BlackBar, Button } from '@/shared/atoms';
import { useQueryHelper, useSelectItemIds } from '@/shared/hooks';

type ActionBarProps = Pick<
  ReturnType<typeof useSelectItemIds<number>>,
  'selectedItemIds' | 'hasAllItemsSelected' | 'toggleSelectAllItems' | 'setSelectedItemIds'
>;

export const ActionBar = ({
  selectedItemIds,
  hasAllItemsSelected,
  setSelectedItemIds,
  toggleSelectAllItems
}: ActionBarProps) => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { loading: updatingStatus, callUpdateAutoManagedCampaignsStatus } = useUpdateAutoManagedCampaignsStatusMutation(
    { onCompleted: invalidateRouteLoader }
  );

  const numberSelectedIds = selectedItemIds.length;

  const handleChangeStatus = async (status: AutoManagedCampaignStatus) => {
    try {
      await callUpdateAutoManagedCampaignsStatus({
        variables: { input: { campaignIds: selectedItemIds, status } }
      });
      enqueueSnackbar(t('succeededUpdateStatus'), { variant: 'success' });
      setSelectedItemIds([]);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return numberSelectedIds > 0 ? (
    <BlackBar
      disabled={updatingStatus}
      selectAllProps={{ onClick: toggleSelectAllItems }}
      checkboxProps={{
        onClick: toggleSelectAllItems,
        indeterminate: !hasAllItemsSelected,
        label: t('Selected', { count: numberSelectedIds })
      }}
    >
      <Button
        variant="blue"
        title={t('Activate')}
        loading={updatingStatus}
        css={{ padding: '0 16px', marginLeft: '16px' }}
        tooltip={{ help: t('Show campaign to creators'), placement: 'bottom' }}
        onClick={() => handleChangeStatus(AutoManagedCampaignStatus.ACTIVE)}
      />
      <Button
        variant="white"
        title={t('Deactivate')}
        loading={updatingStatus}
        css={{ padding: '0 16px', marginLeft: '16px' }}
        tooltip={{ help: t('Hide campaign from creators'), placement: 'bottom' }}
        onClick={() => handleChangeStatus(AutoManagedCampaignStatus.INACTIVE)}
      />
    </BlackBar>
  ) : null;
};
