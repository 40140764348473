import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ApproveApplicantInfluencers.graphql';
import { Mutation, MutationapproveApplicantInfluencersArgs } from '../../__generated__/globalTypes';

export const useApproveApplicantInfluencersMutation = (
  options?: MutationHookOptions<Mutation, MutationapproveApplicantInfluencersArgs>
) => {
  const [callApproveApplicantInfluencers, result] = useMutation<Mutation, MutationapproveApplicantInfluencersArgs>(
    MUTATION,
    options
  );

  return { callApproveApplicantInfluencers, ...result };
};

export type ApproveApplicantInfluencersMutationFunction = Mutation['approveApplicantInfluencers'];
