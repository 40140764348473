import { z } from 'zod';
import { AllTranslationKeys } from '@/shared/assets';
import { BaseValidations } from '@/shared/validations';

export const PasswordFormSchema = z
  .object({
    password: BaseValidations.PasswordAdvancedSchemaDynamic,
    passwordConfirm: BaseValidations.PasswordConfirmSchema
  })
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    message: AllTranslationKeys.Error.passwordsDoNotMatch,
    path: ['passwordConfirm']
  });
