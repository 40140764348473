import { AnalyticsAuthSocialAccountType } from '@/graphql';

export enum AnalyticsSupportedSocialMediaType {
  youtube = 'youtube',
  twitter = 'twitter',
  facebook = 'facebook',
  instagram = 'instagram'
}

export interface ReconnectAnalyticsAccountType {
  id?: number | null;
  socialMediaUrl?: string;
  needReconnect?: boolean;
  socialAccountId?: string;
  socialAccountName?: string;
  socialMedia: AnalyticsSupportedSocialMediaType;
  socialAccountType: AnalyticsAuthSocialAccountType;
  linkedAccount?: Pick<ReconnectAnalyticsAccountType, 'socialAccountName' | 'socialAccountId' | 'socialAccountType'>; // IG links with FB
}
