import { ChatBoxRndItemProps, ChatBoxRndItem } from './ChatBoxRndItem';
import { ChatBoxRndProviderProps, ChatBoxRndProvider } from './ChatBoxRndContext';

export type ChatBoxRndProps = ChatBoxRndItemProps & Pick<ChatBoxRndProviderProps, 'resizable' | 'draggable'>;

// Rnd: Resize and Drag
export const ChatBoxRnd = ({ resizable = false, draggable = true, ...draggableItemProps }: ChatBoxRndProps) => (
  <ChatBoxRndProvider draggable={draggable} resizable={resizable}>
    <ChatBoxRndItem {...draggableItemProps} />
  </ChatBoxRndProvider>
);
