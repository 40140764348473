import { AdvertiserUserSettingsDetails } from '@/graphql';
import { TikTokSettingsFormValues } from './types';

export const getTikTokSettingsFormValues = (data?: AdvertiserUserSettingsDetails | null): TikTokSettingsFormValues => ({
  password: '*********',
  name: data?.name || '',
  email: data?.email || '',
  countryId: data?.country.id || 'TH',
  advertiserName: data?.advertiser.name || '',
  advertiserCountryId: data?.country.id || '',
  notificationSetting: data?.notificationSetting ?? true,
  advertiserCompanyUrl: data?.advertiser.companyUrl || '',
  advertiserPhoneNumber: data?.advertiser.phoneNumber || ''
});

export const defaultTikTokSettingsFormValues = getTikTokSettingsFormValues();
