import { useTranslation } from 'react-i18next';

import { MIN_MAX_AGE_OPTIONS_V2 } from '@/shared/constants';
import { RangeSliderInputFieldProps, RangeSliderInputField } from '@/shared/molecules';

import { MarketplaceFormKeys } from '../types';

interface AgeRangeInputFieldProps extends Omit<RangeSliderInputFieldProps, 'minField' | 'maxField' | 'options'> {
  minField: Omit<RangeSliderInputFieldProps['minField'], 'name' | 'value' | 'defaultValue'>;
  maxField: Omit<RangeSliderInputFieldProps['maxField'], 'name' | 'value' | 'defaultValue'>;
}

export const AgeRangeInputField = ({ minField, maxField, ...props }: AgeRangeInputFieldProps) => {
  const { t } = useTranslation();

  return (
    <RangeSliderInputField
      title={t('General.Age')}
      help={t('Tooltip.Campaign Age')}
      options={MIN_MAX_AGE_OPTIONS_V2}
      minField={{ ...minField, name: MarketplaceFormKeys.minAge }}
      maxField={{ ...maxField, name: MarketplaceFormKeys.maxAge }}
      hideDeselectOption
      hideSlider
      {...props}
    />
  );
};
