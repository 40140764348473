import {
  PackageProposalSocialAcccountBudgetInput,
  ProposalJobDescriptionBreakDownMode,
  namedOperations,
  useUpdatePackageProposalInfluencersBudgetMutation,
  useUpdatePackageProposalSocialAccountsBudgetMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { RowPackageData } from '../PackageContentList/types';

interface SaveProposalProps {
  hasRowChange: boolean;
  rowValues: RowPackageData[];
  jdMode?: ProposalJobDescriptionBreakDownMode;
  resetEditingRowsData: () => void;
  packageId: number;
}
export const useUpdatePackageProposal = ({
  hasRowChange,
  rowValues,
  jdMode,
  resetEditingRowsData,
  packageId
}: SaveProposalProps) => {
  const { callUpdatePackageProposalInfluencersBudget, loading: updatingInfluencersBudget } =
    useUpdatePackageProposalInfluencersBudgetMutation({ refetchQueries: [namedOperations.Query.PackageProposal] });
  const { callUpdatePackageProposalSocialAccountsBudget, loading: updatingAccountsBudget } =
    useUpdatePackageProposalSocialAccountsBudgetMutation({
      refetchQueries: [namedOperations.Query.PackageProposal]
    });
  const { t, enqueueSnackbar } = useQueryHelper();
  const handleSaveProposal = async () => {
    if (!hasRowChange) {
      return;
    }

    try {
      if (jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN) {
        await callUpdatePackageProposalInfluencersBudget({
          variables: {
            input: {
              packageId,
              influencers:
                rowValues?.map(({ influencer, stats }) => ({
                  profit: stats.profit,
                  budget: stats.budget,
                  influencerId: influencer.id,
                  influencerCost: stats.influencerCost
                })) || []
            }
          }
        });
      } else {
        await callUpdatePackageProposalSocialAccountsBudget({
          variables: {
            input: {
              packageId,
              socialAccounts: rowValues.reduce<PackageProposalSocialAcccountBudgetInput[]>(
                (results, { stats, influencer, socialAccount }) => {
                  if (socialAccount) {
                    return [
                      ...results,
                      {
                        profit: stats.profit,
                        budget: stats.budget,
                        influencerId: influencer.id,
                        socialAccountId: socialAccount.id,
                        influencerCost: stats.influencerCost,
                        socialAccountType: socialAccount.socialMedia
                      }
                    ];
                  }

                  return results;
                },
                []
              )
            }
          }
        });
      }

      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
      resetEditingRowsData();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return {
    handleSaveProposal,
    updatingInfluencersBudget,
    updatingAccountsBudget
  };
};
