import { useParams } from '@tanstack/react-router';
import { useQueryHelper } from '@/shared/hooks';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useYoutubeCMSFilter } from '@/shared/organisms';
import {
  useYoutubeCmsChannelDetailQuery,
  useAllYoutubeCmsEstimateAssetsQuery,
  useYoutubeCmsEstimateAssetsSummaryQuery
} from '@/graphql';

export const useYoutubeCMSChannelDetailsData = () => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/youtube-cms/$cmsChannelId' });
  const {
    page,
    limit,
    filter: { keyword, month }
  } = useYoutubeCMSFilter();

  const cmsChannelId = params.cmsChannelId || '';
  const variables = getValuableVariables({ keyword, month, cmsChannelId: Number(cmsChannelId) });
  const listVariables = { ...variables, limit, offset: getOffset(page, limit) };
  const { data, loading } = useYoutubeCmsChannelDetailQuery({
    skip: !month || !cmsChannelId,
    variables: { id: Number(cmsChannelId), month },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/youtube-cms' });
    }
  });
  const { data: summaryData, loading: fetchingSummary } = useYoutubeCmsEstimateAssetsSummaryQuery({
    variables,
    skip: !month || !cmsChannelId,
    notifyOnNetworkStatusChange: true
  });
  const { data: assetsData, loading: fetchingYtAssets } = useAllYoutubeCmsEstimateAssetsQuery({
    variables: listVariables,
    skip: !month || !cmsChannelId,
    notifyOnNetworkStatusChange: true
  });

  return {
    cmsChannelId,
    fetchingData: fetchingYtAssets,
    fetchingSummary: loading || fetchingSummary,
    cmsChannelDetail: data?.youtubeCmsChannelDetail,
    assetsData: assetsData?.allYoutubeCmsEstimateAssets || [],
    summaryData: summaryData?.youtubeCmsEstimateAssetsSummary || undefined,
    totalRecords: assetsData?.allYoutubeCmsEstimateAssetsCount?.totalNumber
  };
};
