import { z } from 'zod';
import { AllTranslationKeys } from '@/shared/assets';
import { BaseValidations } from '@/shared/validations';

const AddFormSchema = z.object({
  type: z.literal('add'),
  email: BaseValidations.EmailSchema,
  name: BaseValidations.NameSchema,
  hasInvitationEmail: z.boolean(),
  password: BaseValidations.PasswordAdvancedSchemaDynamic.or(z.literal('')),
  passwordConfirm: BaseValidations.PasswordAdvancedSchemaDynamic.or(z.literal(''))
});
const EditFormSchema = z.object({
  id: z.number(),
  type: z.literal('edit'),
  cmsMarginRate: z.string(),
  privateInfluencersIds: z.array(BaseValidations.GenericOptionShape)
});
export const BaseSchema = z.object({
  countryId: BaseValidations.CountryIdSchema,
  companyUrl: BaseValidations.UrlSchema,
  talentAgencyName: BaseValidations.TalentAgencySchema,
  netsuiteId: z.string(),
  phoneNumber: z.string(),
  agencyIds: z.array(z.string()),
  advertiserIds: z.array(z.string())
});
export const TalentAgencyFormSchema = z
  .discriminatedUnion('type', [AddFormSchema, EditFormSchema])
  .and(BaseSchema)
  .superRefine((values, ctx) => {
    if (values.type === 'add') {
      if (values.password !== values.passwordConfirm) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: AllTranslationKeys.Error.passwordsDoNotMatch,
          path: ['passwordConfirm']
        });
      }

      if (!values.hasInvitationEmail) {
        if (!values.password) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: AllTranslationKeys.Error.requiredPasswordMessage,
            path: ['password']
          });
        }
        if (!values.passwordConfirm) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: AllTranslationKeys.Error.requiredPasswordConfirmMessage,
            path: ['passwordConfirm']
          });
        }
      }
    }
  });
