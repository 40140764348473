import { useMatchRoute, useParams, Outlet } from '@tanstack/react-router';
import { useUserPermissions } from '@/auth';
import { useTalentAgencyQuery, TalentAgencyWithCompanies } from '@/graphql';
import { ButtonLink, RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useCustomHeader, useQueryHelper } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { TalentAgencyTabs } from '@/shared/organisms';
import { TalentAgencyDetailsProvider } from './TalentAgencyDetailsContext';

export const TalentAgencyDetails = () => {
  useCustomHeader();
  const { hideAddUserBtn } = useUserPermissions();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/talent-agency/$id' });
  const match = useMatchRoute();
  const { data, loading } = useTalentAgencyQuery({
    fetchPolicy: 'network-only',
    variables: { pk: Number(params.id) },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/talent-agency' });
    }
  });

  const talentAgency = data?.talentAgency;
  const hasAddUserLink = !hideAddUserBtn && match({ to: '/talent-agency/$id/users' });

  return (
    <RenderDataWithFallback loading={loading} hasNoData={!talentAgency}>
      <TalentAgencyDetailsProvider values={{ talentAgency: talentAgency as TalentAgencyWithCompanies }}>
        <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
          <Header>
            {hasAddUserLink ? (
              <ButtonLink
                variant="blue"
                title={t('Button.Add User')}
                to="/user/add"
                prefixIcon={{ icon: 'plus', size: '10px' }}
                css={{ minWidth: '109px', padding: '0 16px', textTransform: 'capitalize', marginLeft: 'auto' }}
              />
            ) : null}
          </Header>

          <div css={{ boxShadow: THEME.shadows.boxShadow }}>
            <TalentAgencyTabs talentAgencyId={Number(talentAgency?.id)} />
            <Outlet />
          </div>
        </div>
      </TalentAgencyDetailsProvider>
    </RenderDataWithFallback>
  );
};
