import { RenderDataWithFallback } from '@/shared/atoms';
import { PostReportEmpty, PostReportGrid } from '@/shared/organisms';
import { TikTokSpecialCampaignDetail, useTiktokSpecialCampaignReportGridQuery } from '@/graphql';
import { useTikTokCampaignReportContext } from '../TikTokCampaignReportContext';

export interface TikTokCampaignReportGridProps {
  tiktokSpecialCampaign: TikTokSpecialCampaignDetail;
}
export const TikTokCampaignReportGrid = ({ tiktokSpecialCampaign }: TikTokCampaignReportGridProps) => {
  const { hasTracking, campaignType } = useTikTokCampaignReportContext();
  const { data, loading } = useTiktokSpecialCampaignReportGridQuery({
    variables: { campaignId: tiktokSpecialCampaign.id }
  });

  const gridData = data?.tiktokSpecialCampaignReportGrid || undefined;

  return (
    <RenderDataWithFallback loading={loading} hasNoData={!gridData} noDataNode={<PostReportEmpty />}>
      {gridData ? (
        <PostReportGrid
          data={gridData}
          campaignType={campaignType}
          hasTrackingOption={hasTracking}
          // Order same ttcm campaign
          metricsOrder={['engagementRate', 'reachRate', 'viewRate', 'shareRate', 'saveRate', 'sales']}
        />
      ) : null}
    </RenderDataWithFallback>
  );
};
