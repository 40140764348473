import {
  likePinkImage,
  userPinkImage,
  videoPinkImage,
  chatGreenImage,
  dislikeBlueImage,
  thunderLightYellowImage
} from '@/shared/assets';
import {
  AnalyticsYoutubeDashboardGraphKeys,
  AnalyticsYoutubeDashboardLegendType,
  AnalyticsYoutubeDashboarOverviewEngagementType
} from './types';

export const legendColors: Record<AnalyticsYoutubeDashboardGraphKeys, string> = {
  post: '#ffd7c1',
  view: '#FFB6B7',
  like: '#FF96AE',
  comment: '#a0c8df',
  dislike: '#E9B8D7',
  subscriber: '#ff2b52'
};

export const allLegendOptions: AnalyticsYoutubeDashboardLegendType[] = [
  { label: 'Subscriber', value: 'subscriber', color: legendColors.subscriber },
  { label: 'Post', value: 'post', color: legendColors.post },
  { label: 'Views', value: 'view', color: legendColors.view },
  { label: 'Like', value: 'like', color: legendColors.like },
  { label: 'Dislike', value: 'dislike', color: legendColors.dislike },
  { label: 'Comments', value: 'comment', color: legendColors.comment }
];

export const allOverviewEngagements: Pick<
  AnalyticsYoutubeDashboarOverviewEngagementType,
  'key' | 'title' | 'icon' | 'css' | 'isPercentage'
>[] = [
  { key: 'subscriber', title: 'Subscriber (Total)', icon: userPinkImage },
  { key: 'post', title: 'Post', icon: videoPinkImage },
  { key: 'view', title: 'Views (Total)', icon: thunderLightYellowImage },
  { key: 'like', title: 'Like', icon: likePinkImage },
  { key: 'dislike', title: 'Dislike', icon: dislikeBlueImage },
  { key: 'comment', title: 'Comments', icon: chatGreenImage }
];
