import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestPowerpointCustomisedReport.graphql';
import { Mutation, MutationrequestPowerpointCustomisedReportArgs } from '../../__generated__/globalTypes';

export const useRequestPowerpointCustomisedReportMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestPowerpointCustomisedReportArgs>
) => {
  const [callRequestPowerpointCustomisedReport, result] = useMutation<
    Mutation,
    MutationrequestPowerpointCustomisedReportArgs
  >(MUTATION, options);

  return { callRequestPowerpointCustomisedReport, ...result };
};

export type RequestPowerpointCustomisedReportMutationFunction = Mutation['requestPowerpointCustomisedReport'];
