import { THEME } from '@/shared/constants';
import { Anchor, AnchorProps } from '@/shared/atoms';

export const SC = {
  RightContent: (props: React.HTMLAttributes<HTMLDivElement>) => (
    <div css={{ flex: 1, paddingLeft: '16px' }} {...props} />
  ),
  WrapperContent: (props: React.HTMLAttributes<HTMLDivElement>) => (
    <div css={{ display: 'flex', paddingLeft: '8px' }} {...props} />
  ),
  ListWrapper: (props: React.HTMLAttributes<HTMLUListElement>) => (
    <ul css={{ paddingLeft: '16px', marginBottom: '8px' }} {...props} />
  ),
  Link: (props: AnchorProps) => <Anchor css={{ display: 'inline', color: THEME.text.colors.blue }} {...props} />,
  OrderedListWrapper: (props: React.HTMLAttributes<HTMLUListElement>) => (
    <ol css={{ paddingLeft: '16px', marginBottom: '8px' }} {...props} />
  ),
  Title: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h2 css={{ fontSize: '20px', textAlign: 'center', fontWeight: 'normal' }} {...props} />
  ),
  Wrapper: (props: React.HTMLAttributes<HTMLDivElement>) => (
    <div
      css={{
        padding: '48px',
        borderRadius: '3px',
        boxSizing: 'border-box',
        color: THEME.text.colors.black.lv1,
        width: THEME.container.dashboard.maxWidth,
        boxShadow: '0 3px 10px 1px rgba(0, 0, 0, 0.2)',
        backgroundColor: THEME.background.colors.white
      }}
      {...props}
    />
  ),
  Head: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h3
      css={{
        lineHeight: 1.22,
        fontSize: '18px',
        marginTop: '40px',
        marginBottom: '8px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        letterSpacing: 'normal'
      }}
      {...props}
    />
  ),
  Text: (props: React.HTMLAttributes<HTMLParagraphElement>) => (
    <p
      css={{
        lineHeight: 1.38,
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        '& + &': { marginTop: '8px' }
      }}
      {...props}
    />
  ),
  SubTitle: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h4
      css={{
        fontSize: '13px',
        padding: '40px 0',
        textAlign: 'center',
        fontWeight: 'normal',
        color: THEME.text.colors.gray.lv3
      }}
      {...props}
    />
  ),
  ListItem: ({ idx, ...restProps }: React.HTMLAttributes<HTMLLIElement> & { idx?: string }) => (
    <li
      css={{ marginBottom: '4px', listStylePosition: 'outside', listStyleType: idx ? `'${idx} '` : 'initial' }}
      {...restProps}
    />
  )
};
