import { useFormContext } from 'react-hook-form';
import { FILTER_SECTIONS, MoreFilterPopover } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { useChatDemo } from '@/shared/hooks';
import {
  AgeField,
  ReachField,
  RegionField,
  SaveRateField,
  FollowerField,
  PostPriceField,
  StoryPriceField,
  DemographicField,
  FollowerGrowthRate,
  EngagementRateField,
  CampaignCategoryField,
  InfluencerStatusField,
  InfluencerCategoryField,
  TalentAgencyPartnerField
} from '../../shared';
import { AdvancedFilterDefaultProps } from '../../types';
import { InstagramInfluencersFilterFormValues } from '../schemaTypes';
import { getInitialInstagramInfluencersFormValues } from '../utils';

export const AdvancedFilter = ({ count, onSubmit }: AdvancedFilterDefaultProps) => {
  const { isChatDemoAccount } = useChatDemo();
  const { reset, setValue, getValues } = useFormContext<InstagramInfluencersFilterFormValues>();

  const values = getValues();
  const emptyValues = getInitialInstagramInfluencersFormValues(undefined, isChatDemoAccount);

  const handleClear = () => {
    reset({
      ...emptyValues,
      // Basic filter
      keyword: values.keyword,
      genders: values.genders,
      countryIds: values.countryIds
    });
  };

  const handleCampaignCategoriesChange = (
    fieldName: string,
    selectedValues: string[],
    campaignCategoryOptions: Option[] = []
  ) => {
    setValue(fieldName as keyof InstagramInfluencersFilterFormValues, selectedValues);
    setValue(
      'interests',
      campaignCategoryOptions.reduce<string[]>(
        (arr, category) => (selectedValues.includes(String(category.value)) ? [...arr, String(category.label)] : arr),
        []
      )
    );
  };

  return (
    <MoreFilterPopover
      count={count}
      onClear={handleClear}
      onSubmit={onSubmit}
      items={[
        {
          value: FILTER_SECTIONS.InfluencerAttributes,
          title: FILTER_SECTIONS.InfluencerAttributes,
          node: (
            <>
              <AgeField />
              <InfluencerStatusField />
              <TalentAgencyPartnerField />
              <InfluencerCategoryField />
              <RegionField countryId={(values.countryIds.length === 1 && values.countryIds[0]) || ''} />
              <CampaignCategoryField onChange={handleCampaignCategoriesChange} />
            </>
          )
        },
        {
          value: FILTER_SECTIONS.Audiences,
          title: FILTER_SECTIONS.Audiences,
          node: <DemographicField />
        },
        {
          value: FILTER_SECTIONS.PerformanceFilter,
          title: FILTER_SECTIONS.PerformanceFilter,
          node: (
            <>
              <FollowerField />
              <EngagementRateField />
              <ReachField />
              <SaveRateField />
              <PostPriceField />
              <StoryPriceField />
              <FollowerGrowthRate />
            </>
          )
        }
      ]}
    />
  );
};
