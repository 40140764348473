import { linkOptions } from '@tanstack/react-router';
import { InstagramAnalyticsComments } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { KeywordFilter } from '@/shared/molecules';
import { AnalyticsInstagramCommentsList } from '../../../../AnalyticsPostsList';
import { AnalyticsFeedPostsWrapper } from '../../../shared';
import { useAnalyticsProfileSearch } from '../../../hooks';

interface AnalyticsInstagramDashboardCommentsProps {
  brandAccountName?: string;
  data?: InstagramAnalyticsComments[];
}

export const AnalyticsInstagramDashboardComments = ({
  data = [],
  brandAccountName
}: AnalyticsInstagramDashboardCommentsProps) => {
  const { t, navigate } = useQueryHelper();
  const { params } = useAnalyticsProfileSearch();

  const getMoreLink = (keyword?: string) =>
    linkOptions({
      to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments',
      params,
      search: { filter: { keyword, brandAccountName } }
    });

  return (
    <AnalyticsFeedPostsWrapper
      css={{ minHeight: '250px' }}
      dataNotAvailable={!data.length}
      title={t('HeaderColumn.Comments')}
      moreDetailProps={getMoreLink()}
    >
      <KeywordFilter
        filter={{ keyword: '' }}
        css={{ borderRadius: 0, padding: '0 16px' }}
        setFilter={({ keyword }) => navigate(getMoreLink(keyword))}
      />
      <AnalyticsInstagramCommentsList totalRecords={data.length} data={data} css={{ borderTop: 0 }} />
    </AnalyticsFeedPostsWrapper>
  );
};
