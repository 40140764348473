import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramAnalyticsStoryDetails.graphql';
import { Query, QueryinstagramAnalyticsStoryDetailsArgs } from '../../__generated__/globalTypes';

export const useInstagramAnalyticsStoryDetailsQuery = (
  options?: QueryHookOptions<Query, QueryinstagramAnalyticsStoryDetailsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramAnalyticsStoryDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramAnalyticsStoryDetailsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramAnalyticsStoryDetailsArgs>
): QueryResult<Query, QueryinstagramAnalyticsStoryDetailsArgs> & {
  getInstagramAnalyticsStoryDetails: LazyQueryExecFunction<Query, QueryinstagramAnalyticsStoryDetailsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramAnalyticsStoryDetails, result] = useLazyQuery<Query, QueryinstagramAnalyticsStoryDetailsArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getInstagramAnalyticsStoryDetails, ...result };
};

export const useInstagramAnalyticsStoryDetailsPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramAnalyticsStoryDetails = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramAnalyticsStoryDetailsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramAnalyticsStoryDetailsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramAnalyticsStoryDetails };
};
