import {
  InfluencerYoutubeProfileSponsoredPosts,
  InfluencerYoutubeProfileSponsoredPostsProps
} from '../../InfluencerProfiles';

type InfluencerAnalyticsYoutubeProfileSponsoredPostsProps = InfluencerYoutubeProfileSponsoredPostsProps;

export const InfluencerAnalyticsYoutubeProfileSponsoredPosts = (
  props: InfluencerAnalyticsYoutubeProfileSponsoredPostsProps
) => <InfluencerYoutubeProfileSponsoredPosts {...props} hasYouTubeSponsoredPostsQuery />;
