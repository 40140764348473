import { CampaignInfluencers } from '@/shared/organisms';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';
import { useDefaultInfluencerSearchProps } from './hooks';

export const EngagementCampaignInfluencersSearch = () => {
  const { engagementId } = useEngagementDetailsContext();
  const { isEngagementPage } = useDefaultInfluencerSearchProps();

  return <CampaignInfluencers campaignId={engagementId} isEngagementPage={isEngagementPage} />;
};
