import { FormProvider, Modal } from '@/shared/atoms';
import { FormAction } from '@/shared/types';
import { EngagementBulkEditSchema } from './schema';
import { Template } from './Template';
import { EngagementBulkEditFormValues } from './types';

interface BulkEditModalProps {
  open?: boolean;
  currency?: string;
  onClose?: () => void;
  hasAdAgencyMarginRate?: boolean;
  onSubmit?: FormAction<EngagementBulkEditFormValues>['onSubmit'];
}

export const BulkEditModal = ({
  open,
  onClose,
  onSubmit,
  currency = '',
  hasAdAgencyMarginRate
}: BulkEditModalProps) => (
  <Modal open={open} onClose={onClose} css={{ maxWidth: '440px' }} hasCloseIcon>
    <FormProvider<EngagementBulkEditFormValues>
      onSubmit={onSubmit}
      defaultValues={{ netBudget: '', influencerCost: '' }}
      zodSchema={EngagementBulkEditSchema}
    >
      <Template onClose={onClose} currency={currency} hasAdAgencyMarginRate={hasAdAgencyMarginRate} />
    </FormProvider>
  </Modal>
);

export * from './types';
