import {
  Outlet,
  LinkProps,
  useLocation,
  useMatches,
  useMatchRoute,
  useRouteContext,
  redirect
} from '@tanstack/react-router';
import { useIntercomInitializer, useGaTracker } from '@/shared/hooks';
import { Layout } from '@/shared/organisms';
import { MenuTitle, router } from '@/router';
import { checkSignedIn, manageLocalStorage } from '@/shared/utils';
import { RouterContext } from '@/shared/types';
import { PartialRouter } from '../types';
import { AdvertiserRouter } from './advertiser';
import { AgencyRouter } from './agency';
import { AnalyticsRouter } from './analytics';
import { AssetPreviewRouter } from './asset-preview';
import { AutoManagedRouter } from './auto-managed';
import { CampaignPostsDiscoveryRouter } from './campaign-posts-discovery';
import { ChatDemoRouter } from './chat-demo';
import { EngagementRouter } from './engagement';
import { HashtagAnalyticsRouter } from './hashtag-analytics';
import { InfluencerRouter } from './influencer';
import { InfluencersRouter } from './influencers';
import { InvoicesRouter } from './invoices';
import { MarketplaceRouter } from './marketplace';
import { PackageRouter } from './package';
import { PartnerRouter } from './partner';
import { PaymentsRouter } from './payments';
import { PostsRouter } from './posts';
import { PostsInsightRouter } from './posts-insight';
import { RedirectRouter } from './redirect';
import { ReportRouter } from './report';
import { SettingsRouter } from './settings/settings';
import { TalentAgencyRouter } from './talent-agency';
import { TikTokRouter } from './tiktok';
import { TrendingRouter } from './trending';
import { UserRouter } from './user';
import { UserSettingsRouter } from './user-settings';
import { YoutubeCmsRouter } from './youtube-cms';

const protectedRoutesWithoutLayout = [
  '/posts-insight',
  '/posts-insight/$id',
  '/hashtag-analytics',
  '/posts-insight/$id/$postId',
  '/redirect/hidden/youtube-list-connect'
] as const satisfies Array<LinkProps['to']>;

const internalLayout = [
  '/invoices',
  '/youtube-cms',
  '/youtube-cms/$cmsChannelId',
  '/youtube-cms/confirmed-revenue'
] as const satisfies Array<LinkProps['to']>;

const PrivateRoutes = () => {
  useGaTracker();
  useIntercomInitializer();
  const matches = useMatches();
  const matchRoute = useMatchRoute();
  const location = useLocation();

  router.history.subscribe(({ action }) => {
    const prevLocation = sessionStorage.getItem('location');
    try {
      const parsedPrevLocation = prevLocation ? JSON.parse(prevLocation) : null;
      if (action.type === 'PUSH' && parsedPrevLocation?.state?.key !== location.state?.key) {
        sessionStorage.setItem('location', JSON.stringify(location));
      }
    } catch (e) {
      console.warn('Error parsing location from sessionStorage:', e);
    }
  });

  const currentMatch = matches.find((m) => matchRoute({ to: m.fullPath as LinkProps['to'], params: m.params }));
  const state = useRouteContext({ from: '/_private-routes' }) as RouterContext;

  state.activeRouteId = currentMatch?.routeId;
  state.activeRoutePath = currentMatch?.fullPath;

  if (
    !currentMatch ||
    (currentMatch?.fullPath &&
      protectedRoutesWithoutLayout.includes(currentMatch.fullPath as (typeof protectedRoutesWithoutLayout)[number]))
  ) {
    return <Outlet />;
  }

  const staticData = currentMatch?.staticData as { hasCombinedTerms: boolean; menuTitle: MenuTitle };

  return (
    <Layout
      title={staticData?.menuTitle}
      hasCombinedTerms={staticData?.hasCombinedTerms}
      internal={
        !!currentMatch?.fullPath && internalLayout.includes(currentMatch?.fullPath as (typeof internalLayout)[number])
      }
    />
  );
};

export const PrivateRouter = {
  '/_private-routes': [
    PrivateRoutes,
    {
      beforeLoad: ({ location }) => {
        const isSignedIn = checkSignedIn();

        if (!isSignedIn) {
          manageLocalStorage('set', 'redirectUrlBeforeSignIn', location.href);
          throw redirect({ to: '/sign-in' });
        }
      }
    }
  ],
  ...AdvertiserRouter,
  ...AgencyRouter,
  ...AnalyticsRouter,
  ...AutoManagedRouter,
  ...ChatDemoRouter,
  ...EngagementRouter,
  ...AssetPreviewRouter,
  ...CampaignPostsDiscoveryRouter,
  ...HashtagAnalyticsRouter,
  ...InfluencerRouter,
  ...InfluencersRouter,
  ...InvoicesRouter,
  ...MarketplaceRouter,
  ...PackageRouter,
  ...PartnerRouter,
  ...PaymentsRouter,
  ...PostsInsightRouter,
  ...PostsRouter,
  ...RedirectRouter,
  ...ReportRouter,
  ...SettingsRouter,
  ...TalentAgencyRouter,
  ...TikTokRouter,
  ...TrendingRouter,
  ...UserSettingsRouter,
  ...UserRouter,
  ...YoutubeCmsRouter
} as const satisfies PartialRouter;
