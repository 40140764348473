import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { Icon } from '../Icon';
import { ChatBoxNotice, ChatBoxNoticeProps } from './ChatBoxNotice';

export interface ChatBoxErrorsProps {
  className?: string;
  onRegenerateResponse?: () => void;
  message?: ChatBoxNoticeProps['message'];
  type?: 'network-error' | 'over-requests' | 'generate-failed';
}

export const ChatBoxErrors = ({ type, message, className, onRegenerateResponse }: ChatBoxErrorsProps) => {
  const { t } = useTranslation();

  const renderError = () => {
    switch (type) {
      case 'over-requests':
        return <ChatBoxNotice type="error" message={t('Error.Too many request')} />;
      case 'network-error':
        return (
          <>
            <ChatBoxNotice type="error" message={t('Error.AI stopped generating')} />

            <div css={[styles.buttonWrapper(), { backgroundColor: '#FFCFCF' }]}>
              <div css={styles.iconWrapper}>
                <Icon icon="wifi-off" size={12} color={THEME.icon.colors.red.lv1} />
              </div>
              <p css={{ color: THEME.text.colors.red.lv1 }}>{t('Network Error')}</p>
            </div>
          </>
        );
      case 'generate-failed':
        return (
          <>
            <ChatBoxNotice type="error" message={t('Error.Generating failed')} />

            <div
              onClick={onRegenerateResponse}
              css={[styles.buttonWrapper(!!onRegenerateResponse), { backgroundColor: '#EEF3F7' }]}
            >
              <div css={styles.iconWrapper}>
                <Icon icon="refresh" size={12} color="#6E7C89" />
              </div>
              <p>{t('Regenerate response')}</p>
            </div>
          </>
        );
      default:
        return message ? <ChatBoxNotice type="error" message={message} /> : null;
    }
  };

  return (
    <div css={{ padding: '24px 16px' }} className={className}>
      {renderError()}
    </div>
  );
};

const styles = {
  buttonWrapper: (canClick?: boolean) =>
    css({
      gap: '4px',
      height: '40px',
      display: 'flex',
      fontSize: '12px',
      fontWeight: 400,
      padding: '0 16px',
      lineHeight: '120%',
      fontStyle: 'normal',
      width: 'max-content',
      borderRadius: '28px',
      alignItems: 'center',
      margin: '24px auto 0',
      boxSizing: 'border-box',
      cursor: canClick ? 'pointer' : 'default'
    }),
  iconWrapper: css({
    flex: 'none',
    width: '24px',
    height: '24px',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: THEME.background.colors.white
  })
};
