import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ReportTiktokSpecialPostStatus.graphql';
import { Mutation, MutationreportTiktokSpecialPostStatusArgs } from '../../__generated__/globalTypes';

export const useReportTiktokSpecialPostStatusMutation = (
  options?: MutationHookOptions<Mutation, MutationreportTiktokSpecialPostStatusArgs>
) => {
  const [callReportTiktokSpecialPostStatus, result] = useMutation<Mutation, MutationreportTiktokSpecialPostStatusArgs>(
    MUTATION,
    options
  );

  return { callReportTiktokSpecialPostStatus, ...result };
};

export type ReportTiktokSpecialPostStatusMutationFunction = Mutation['reportTiktokSpecialPostStatus'];
