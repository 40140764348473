import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Button, Icon } from '@/shared/atoms';
import { AddHashtagModal, DeleteHashtagModal } from '@/shared/molecules';
import { useManageHashtags, ManageHashtagsProps } from './hooks';

export type HashtagsListProps = ManageHashtagsProps;

const LIMIT = 200;

export const HashtagsList = ({ hashtags, onSubmit, className }: HashtagsListProps) => {
  const { t } = useTranslation();
  const {
    creating,
    deleting,
    addModalState,
    deletingHashtag,
    setDeletingHashtag,
    handleSelectHashtag,
    handleDeleteHashtag,
    handleCreateHashtag,
    resetDeletingHashtag,
    checkActivatingHashtag
  } = useManageHashtags({ hashtags, onSubmit });

  const disabledAddButton = hashtags.length > LIMIT;

  return (
    <>
      <div css={styles.wrapper} className={className}>
        <div css={styles.itemWrapper}>
          <Button
            title="+"
            variant="white"
            onClick={addModalState.open}
            disabled={disabledAddButton}
            css={[styles.item(false), { padding: 0 }]}
            tooltip={{
              help: disabledAddButton ? t('Can’t add tags more than', { count: LIMIT }) : t('Tooltip.Add Hashtag')
            }}
          />
        </div>

        {hashtags.map((hashtag) => {
          const active = checkActivatingHashtag(hashtag.hashtagId);

          return (
            <div key={hashtag.hashtagId} css={styles.itemWrapper}>
              <Button
                css={styles.item(active)}
                variant={active ? 'black' : 'white'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectHashtag?.(hashtag);
                }}
              >
                <p css={styles.itemName(active)}>#{hashtag.hashtag}</p>

                <div
                  css={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeletingHashtag(hashtag);
                  }}
                >
                  <Icon size={8} icon="close" color="inherit" />
                </div>
              </Button>
            </div>
          );
        })}
      </div>

      <AddHashtagModal
        loading={creating}
        open={addModalState.status}
        onAdd={handleCreateHashtag}
        onClose={addModalState.close}
      />

      <DeleteHashtagModal
        loading={deleting}
        onClose={resetDeletingHashtag}
        onDelete={handleDeleteHashtag}
        open={!!deletingHashtag?.hashtagId}
        hashtag={`#${deletingHashtag?.hashtag}`}
      />
    </>
  );
};
const styles = {
  wrapper: css({
    margin: '-4px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box'
  }),
  itemWrapper: css({
    padding: '4px',
    boxSizing: 'border-box'
  }),
  item: (active?: boolean) =>
    css({
      minWidth: '120px',
      padding: '0 12px 0 16px',
      color: active ? THEME.text.colors.white : THEME.text.colors.black.lv1
    }),
  itemName: (active?: boolean) =>
    css({
      flex: 1,
      fontSize: '12px',
      lineHeight: '100%',
      letterSpacing: '0.5px',
      fontWeight: active ? 600 : 400
    }),
  icon: css({
    flex: 'none',
    width: '12px',
    height: '12px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center'
  })
};
