import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAllAvailableCountryOptions } from '@/shared/hooks';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';

import { MarketplaceFormKeys, MarketplaceFormValues } from '../types';

export const CountryField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const { setValue, watch } = useFormContext<MarketplaceFormValues>();
  const countryIdValue = watch(MarketplaceFormKeys.countryId);

  useEffect(() => {
    if (allAvailableCountryOptions.length) {
      const currency = allAvailableCountryOptions.find((country) => country.value === countryIdValue)?.currency || '';
      setValue(MarketplaceFormKeys.currency, currency);
    }
  }, [countryIdValue, allAvailableCountryOptions.length]);

  return (
    <SelectorField
      title={t('Selector.Campaign Country')}
      help={t('Tooltip.Select Campaign Help')}
      options={allAvailableCountryOptions}
      name={MarketplaceFormKeys.countryId}
      {...props}
    />
  );
};
