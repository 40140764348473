import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/GetUserDefaultPermissions.graphql';
import { Query, QuerygetUserDefaultPermissionsArgs } from '../../__generated__/globalTypes';

export const useGetUserDefaultPermissionsQuery = (
  options?: QueryHookOptions<Query, QuerygetUserDefaultPermissionsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerygetUserDefaultPermissionsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useGetUserDefaultPermissionsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerygetUserDefaultPermissionsArgs>
): QueryResult<Query, QuerygetUserDefaultPermissionsArgs> & {
  getGetUserDefaultPermissions: LazyQueryExecFunction<Query, QuerygetUserDefaultPermissionsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getGetUserDefaultPermissions, result] = useLazyQuery<Query, QuerygetUserDefaultPermissionsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getGetUserDefaultPermissions, ...result };
};

export const useGetUserDefaultPermissionsPromiseQuery = () => {
  const client = useApolloClient();

  const getGetUserDefaultPermissions = useCallback(
    (options?: Omit<QueryOptions<QuerygetUserDefaultPermissionsArgs, Query>, 'query'>) =>
      client.query<Query, QuerygetUserDefaultPermissionsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getGetUserDefaultPermissions };
};
