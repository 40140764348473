import { ErrorMessage } from '@/shared/atoms';
import { Option } from '@/shared/types';
import { SingleSelect, CommonSelectProps, MultipleSelect } from '../SelectorV2';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export type SelectorFieldV2Props<
  T extends string = string,
  O extends Option<string, any> = Option<string, any>
> = DefaultFieldProps<T, any, O> &
  Omit<CommonSelectProps<any, O, any>, 'name' | 'options' | 'onChange' | 'placeholder'>;

export const SelectorFieldV2 = <T extends string, O extends Option<string, any> = Option<string, any>>(
  props: SelectorFieldV2Props<T, O>
) => {
  const {
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldRegister: { ref, ...restRegister },
    fieldProps: { name, value, options = [], multiple, onSubmit, onChange, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  const commonProps = {
    ...restRegister,
    ...restFieldProps,
    options,
    setValueOptions,
    onSubmit,
    onChange: (value: string | string[]) =>
      onChange ? onChange(name, value, options) : setValue<string>(name, value, setValueOptions)
  };

  return (
    <RHFFieldWrapper {...fieldWrapperProps} hideError>
      {({ hasError, errorMessage }) => (
        <>
          {multiple ? (
            <MultipleSelect {...commonProps} hasError={hasError} value={value as string[]} />
          ) : (
            <SingleSelect {...commonProps} hasError={hasError} value={value as string} />
          )}
          <ErrorMessage ref={ref} message={errorMessage} />
        </>
      )}
    </RHFFieldWrapper>
  );
};
