import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useUserRoles } from '@/auth';
import { SelectedRangeOption } from '@/shared/types';
import { useReportFilterOptions } from '@/shared/hooks';
import { SelectorField, RangeDatePickerField, BasicFilterWrapper } from '@/shared/molecules';
import { ReportFilterFormValues, ReportFilterFormKeys } from './types';

interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { watch } = useFormContext<ReportFilterFormValues>();
  const values = watch();
  const { endDate, startDate, campaigns, campaignTypes } = values;
  const {
    allCampaignTypes,
    allCountryOptions,
    allCampaignsOptions,
    allSocialMediaOptions,
    allCampaignCategoryOptions,
    allCampaignsSelectedOptions
  } = useReportFilterOptions({ campaigns, campaignTypes });

  const isAllTime = !startDate && !endDate;

  return (
    <BasicFilterWrapper css={{ padding: '4px 16px' }}>
      <SelectorField<ReportFilterFormKeys>
        name="campaigns"
        onSubmit={onSubmit}
        css={{ flexBasis: '100%' }}
        placeholder={t<string>('Selector.Select campaigns')}
        options={[...allCampaignsSelectedOptions, ...allCampaignsOptions]}
        multipleSelectorProps={{ menuItemsProps: { isVirtualized: true } }}
        multiple
      />

      <RangeDatePickerField<ReportFilterFormKeys>
        onSubmit={onSubmit}
        clearTitle={t('All time')}
        name={{ startDate: 'startDate', endDate: 'endDate' }}
        customInputTextNode={isAllTime ? <p>{t('All time')}</p> : undefined}
        rangeList={[
          SelectedRangeOption.TODAY,
          SelectedRangeOption.LAST_SEVEN_DAYS,
          SelectedRangeOption.TODAY_TO_MONTH_AGO,
          SelectedRangeOption.THIS_MONTH,
          SelectedRangeOption.LAST_MONTH,
          SelectedRangeOption.CUSTOM,
          SelectedRangeOption.CLEAR
        ]}
      />

      <SelectorField<ReportFilterFormKeys>
        name="campaignTypes"
        onSubmit={onSubmit}
        options={allCampaignTypes}
        placeholder={t<string>('Selector.Campaign Type')}
        multiple
      />

      <SelectorField<ReportFilterFormKeys>
        name="countryId"
        onSubmit={onSubmit}
        options={allCountryOptions}
        placeholder={t<string>('Selector.Country')}
      />

      <SelectorField<ReportFilterFormKeys>
        name="socialMedias"
        onSubmit={onSubmit}
        options={allSocialMediaOptions}
        placeholder={t<string>('Selector.Social Platform')}
        multiple
      />

      {isAdminStaff ? (
        <SelectorField<ReportFilterFormKeys>
          onSubmit={onSubmit}
          name="campaignCategoryIds"
          options={allCampaignCategoryOptions}
          placeholder={t<string>('Selector.Campaign Category')}
          multipleSelectorProps={{
            menuItemsProps: { type: 'checkbox-with-submenu' },
            menuCss: css({ minWidth: '200px' })
          }}
          multiple
        />
      ) : null}
    </BasicFilterWrapper>
  );
};
