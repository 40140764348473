import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteInfluencer.graphql';
import { Mutation, MutationdeleteInfluencerArgs } from '../../__generated__/globalTypes';

export const useDeleteInfluencerMutation = (options?: MutationHookOptions<Mutation, MutationdeleteInfluencerArgs>) => {
  const [callDeleteInfluencer, result] = useMutation<Mutation, MutationdeleteInfluencerArgs>(MUTATION, options);

  return { callDeleteInfluencer, ...result };
};

export type DeleteInfluencerMutationFunction = Mutation['deleteInfluencer'];
