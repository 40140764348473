import { css } from '@emotion/react';

import { useMarketplacePermissions, useUserRoles } from '@/auth';
import {
  WarningReason,
  MarketplaceDetail,
  useMarketplacePostQuery,
  MarketplaceCampaignPostStatus,
  useMarketplacePostHistoryQuery,
  useUpdateMarketplacePostStatusV2Mutation,
  MarketplaceCampaignPostStatusForAdvertiser,
  namedOperations
} from '@/graphql';
import { Button, FormStyle, RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailInfluencerInfo } from '@/shared/types';
import { useRejectMarketplacePost, useUpdateMarketplacePostsStatus } from '../hooks';
import { HistorySection, PostInfoSection, RequestModificationMessage } from './shared';

interface DefaultTemplateProps {
  postId: number;
  onClose: () => void;
  isAffiliateCampaign?: boolean;
  marketplaceId: MarketplaceDetail['id'];
}

export const DefaultTemplate = ({ postId, onClose, marketplaceId, isAffiliateCampaign }: DefaultTemplateProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { hideApproveRejectInappropriatePostBtn } = useMarketplacePermissions();
  const { isAdvertiser, isAdminStaffAgencyTalentAgencyPartner } = useUserRoles();
  const { handleRejectPost, RejectMarketplacePostModal } = useRejectMarketplacePost();
  const { updating, handleSelectStatus, UpdateMarketplacePostStatusModal } = useUpdateMarketplacePostsStatus({
    marketplaceId
  });
  const { callUpdateMarketplacePostStatusV2, loading: updatingPostStatus } = useUpdateMarketplacePostStatusV2Mutation({
    refetchQueries: [namedOperations.Query.AllMarketplacePosts]
  });
  const { data, loading } = useMarketplacePostQuery({
    variables: { pk: postId },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      onClose?.();
    }
  });
  const { data: historyData, loading: fetchingHistory } = useMarketplacePostHistoryQuery({
    variables: { pk: postId },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      onClose?.();
    }
  });

  const marketplacePost = data?.marketplacePost;

  if (!marketplacePost) {
    return null;
  }

  const {
    images,
    status,
    caption,
    reason,
    postUrl,
    postedDate,
    socialMedia,
    warningReason,
    detailedReason,
    postDetailInfluencer
  } = marketplacePost;
  const hasRequestModificationButton =
    !hideApproveRejectInappropriatePostBtn &&
    (isAdminStaffAgencyTalentAgencyPartner || isAdvertiser) &&
    status &&
    [MarketplaceCampaignPostStatus.EFFECTIVE, MarketplaceCampaignPostStatus.WARNING_SOLVED].includes(status);
  const hasWarningSolvedButton =
    status && status === MarketplaceCampaignPostStatus.WARNING && warningReason === WarningReason.INVALID_CONTENT;
  const hasApproveButton =
    !hideApproveRejectInappropriatePostBtn &&
    !isAffiliateCampaign &&
    status === MarketplaceCampaignPostStatus.EFFECTIVE;

  const handleSetWarningSolvedStatus = async () => {
    try {
      const influencerId = Number(marketplacePost?.postDetailInfluencer.id);

      await callUpdateMarketplacePostStatusV2({
        variables: {
          input: {
            postIds: [postId],
            influencerIds: [influencerId],
            marketplaceId: marketplaceId as number,
            status: MarketplaceCampaignPostStatus.WARNING_SOLVED
          }
        }
      });

      onClose();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <RenderDataWithFallback loading={loading || fetchingHistory}>
      <div css={{ padding: '24px' }}>
        <PostInfoSection
          hasImageDownload
          caption={caption}
          images={[...(images || [])]}
          influencer={{
            postDate: postedDate,
            postUrl: postUrl || '',
            id: postDetailInfluencer.id,
            name: postDetailInfluencer.name,
            avatar: postDetailInfluencer.avatar,
            followers: postDetailInfluencer.followersCount,
            socialMedia: socialMedia as PostDetailInfluencerInfo['socialMedia']
          }}
        />

        {detailedReason || reason ? (
          <RequestModificationMessage message={detailedReason || reason} />
        ) : hasRequestModificationButton ? (
          <Button
            variant="white"
            prefixIcon={{ icon: 'send', size: 12 }}
            title={t('Button.Request modification')}
            css={{ marginTop: '16px', width: '100%' }}
            onClick={() => handleRejectPost(marketplacePost)}
          />
        ) : null}
      </div>

      <HistorySection data={historyData?.marketplacePostHistory} />

      {hasApproveButton ? (
        <FormStyle.ButtonSubmitWrapper css={styles.buttonSubmitWrapper}>
          <p css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3, maxWidth: '204px' }}>
            {t('You can send feedback to creator via Email and AnyCreator')}
          </p>
          <Button
            variant="blue"
            loading={updating}
            css={styles.button}
            title={t('Button.Approve Post')}
            onClick={() =>
              status
                ? handleSelectStatus(status, [
                    {
                      ...marketplacePost,
                      joinedDate: '',
                      revenueItems: [], // don't used here, just to match the MarketplacePost type
                      needReview: false,
                      influencer: marketplacePost.postDetailInfluencer,
                      // TODO: BE improvement, they need to make post status type consistent
                      // On the posts list page BE return the post status as MarketplaceCampaignPostStatusForAdvertiser
                      // But which same post on detail, they return post status as MarketplaceCampaignPostStatus
                      status: marketplacePost.status as unknown as MarketplaceCampaignPostStatusForAdvertiser
                    }
                  ])
                : undefined
            }
          />
        </FormStyle.ButtonSubmitWrapper>
      ) : hasWarningSolvedButton ? (
        <FormStyle.ButtonSubmitWrapper css={styles.buttonSubmitWrapper}>
          <Button
            variant="blue"
            css={styles.button}
            loading={updatingPostStatus}
            title={t('Button.Make effective')}
            onClick={handleSetWarningSolvedStatus}
          />
        </FormStyle.ButtonSubmitWrapper>
      ) : null}

      <RejectMarketplacePostModal />
      <UpdateMarketplacePostStatusModal />
    </RenderDataWithFallback>
  );
};

const styles = {
  buttonSubmitWrapper: css({
    zIndex: 5,
    bottom: 0,
    position: 'sticky',
    backgroundColor: THEME.background.colors.white
  }),
  button: css({
    minWidth: 'unset',
    padding: '0 32px',
    marginLeft: 'auto'
  })
};
