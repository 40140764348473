import update from 'immutability-helper';
import React, { useEffect, useState } from 'react';
import { useQueryHelper } from '@/shared/hooks';
import {
  namedOperations,
  CmsChannelAssetInput,
  useLinkCmsChannelAssetsMutation,
  useYoutubeCmsChannelInfoPromiseQuery
} from '@/graphql';

interface RowState {
  id: number;
  value?: string;
  error?: boolean;
  defaultValue?: string;
}

export const useUpdateYoutubeAssetChannelId = () => {
  const { t, search, enqueueSnackbar } = useQueryHelper();
  const [editingRows, setEditingRows] = useState<RowState[]>([]);
  const { getYoutubeCmsChannelInfo } = useYoutubeCmsChannelInfoPromiseQuery();
  const { callLinkCmsChannelAssets, loading } = useLinkCmsChannelAssetsMutation({
    refetchQueries: [namedOperations.Query.AllYoutubeCmsEstimateAssets]
  });

  useEffect(
    () => () => {
      setEditingRows([]);
    },
    [search]
  );

  const handleSaveChange = async () => {
    if (!editingRows.length) {
      return;
    }

    const channelIds = editingRows.reduce<string[]>(
      (acc, curr) => (!!curr.value && !acc.includes(curr.value) ? [...acc, curr.value] : acc),
      []
    );
    try {
      const { data } = await getYoutubeCmsChannelInfo({ variables: { channelIds } });
      const channelInfo = data.youtubeCmsChannelInfo;

      if (channelInfo?.length !== channelIds.length) {
        const error = t<string>('Cannot find the channel');
        const channelInfoIds = channelInfo?.map((channel) => channel.channelId) || [];

        setEditingRows(
          update(editingRows, {
            $apply: (rows: RowState[]) =>
              rows.map((row) => ({ ...row, error: !!row.value && !channelInfoIds.includes(row.value) }))
          })
        );

        throw new Error(error);
      }

      await callLinkCmsChannelAssets({
        variables: {
          input: {
            records: editingRows.reduce<CmsChannelAssetInput[]>((acc, curr) => {
              const channel = channelInfo.find((c) => c.channelId === curr.value);

              return channel
                ? [
                    ...acc,
                    {
                      id: curr.id,
                      channelId: channel.channelId,
                      channelAvatar: channel.avatar,
                      channelName: channel.channelName
                    }
                  ]
                : acc;
            }, [])
          }
        }
      });

      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      setEditingRows([]);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveChange();
    }
  };

  const handleRowChange = (editingRow: RowState) => {
    const index = editingRows.findIndex((row) => row.id === editingRow.id);

    setEditingRows(
      index !== -1
        ? editingRow.value === editingRow.defaultValue
          ? update(editingRows, { $splice: [[index, 1]] }) // Remove if nothing changes
          : update(editingRows, { [index]: { value: { $set: editingRow.value } } }) // Update new value
        : update(editingRows, { $push: [editingRow] }) // Add new
    );
  };

  return {
    handleEnter,
    editingRows,
    handleRowChange,
    saving: loading,
    handleSaveChange
  };
};
