import { useTranslation } from 'react-i18next';
import { SetValueConfig, useFormContext } from 'react-hook-form';
import { ErrorMessage, FormStyle } from '@/shared/atoms';
import { RangeWithSelectable, RangeWithSelectableProps } from '../RangeWithSelectable';

export interface RangeSliderInputFieldProps<T extends string = string>
  extends Omit<RangeWithSelectableProps<T>, 'name' | 'minField' | 'maxField' | 'setValue' | 'setFieldValue'> {
  onSubmit?: () => void;
  hasBorderBottom?: boolean;
  setValueOptions?: SetValueConfig;
  onChange?: (field: string, value: string | number, options?: T[]) => void;
  minField: Omit<RangeWithSelectableProps<T>['minField'], 'value' | 'defaultValue'>;
  maxField: Omit<RangeWithSelectableProps<T>['maxField'], 'value' | 'defaultValue'>;
}

export const RangeSliderInputField = <T extends string = string>({
  minField,
  maxField,
  onSubmit,
  onChange,
  className,
  hasBorderBottom,
  setValueOptions = { shouldDirty: true, shouldValidate: true },
  ...restProps
}: RangeSliderInputFieldProps<T>) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    formState: { errors }
  } = useFormContext<{ [key: string]: number | '' }>();
  const [minValue, maxValue] = watch([minField.name, maxField.name]);

  const handleInputChange = (field: string, value?: string | number) => {
    if (onChange) {
      onChange(field, value !== '' ? Number(value) : value);
    } else {
      setValue(field, value !== '' ? Number(value) : value, setValueOptions);
    }
    onSubmit?.();
  };

  return (
    <FormStyle.FieldWrapper className={className} hasBorderBottom={hasBorderBottom}>
      <RangeWithSelectable
        {...restProps}
        setFieldValue={handleInputChange}
        minField={{ ...minField, value: minValue, defaultValue: minValue }}
        maxField={{ ...maxField, value: maxValue, defaultValue: maxValue }}
      />
      <ErrorMessage message={t<string>(errors[minField.name]?.message || errors[maxField.name]?.message || '')} />
    </FormStyle.FieldWrapper>
  );
};
