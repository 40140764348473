import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/BoostTiktokAdPost.graphql';
import { Mutation, MutationboostTiktokAdPostArgs } from '../../__generated__/globalTypes';

export const useBoostTiktokAdPostMutation = (
  options?: MutationHookOptions<Mutation, MutationboostTiktokAdPostArgs>
) => {
  const [callBoostTiktokAdPost, result] = useMutation<Mutation, MutationboostTiktokAdPostArgs>(MUTATION, options);

  return { callBoostTiktokAdPost, ...result };
};

export type BoostTiktokAdPostMutationFunction = Mutation['boostTiktokAdPost'];
