import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteAgency.graphql';
import { Mutation, MutationdeleteAgencyArgs } from '../../__generated__/globalTypes';

export const useDeleteAgencyMutation = (options?: MutationHookOptions<Mutation, MutationdeleteAgencyArgs>) => {
  const [callDeleteAgency, result] = useMutation<Mutation, MutationdeleteAgencyArgs>(MUTATION, options);

  return { callDeleteAgency, ...result };
};

export type DeleteAgencyMutationFunction = Mutation['deleteAgency'];
