import { z } from 'zod';
import { Genders, SocialAccountInfluencerListSortField } from '@/graphql';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const TikTokCampaignInfluencersFilterSchema = z.object({
  keyword: FilterValidations.string(),
  packageId: FilterValidations.number(),
  countryIds: FilterValidations.arrayString(),
  categoryIds: FilterValidations.arrayString(),
  genders: FilterValidations.arrayEnum(Genders)
});
export const TikTokCampaignInfluencersSearchSchema = z.object({
  filter: TikTokCampaignInfluencersFilterSchema.default(TikTokCampaignInfluencersFilterSchema.parse({})),
  sort: SearchFilterDefaults.sort(SocialAccountInfluencerListSortField).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type TikTokCampaignInfluencersSearchType = z.infer<typeof TikTokCampaignInfluencersSearchSchema>;
export type TikTokCampaignInfluencersFilterFormValues = z.infer<typeof TikTokCampaignInfluencersFilterSchema>;
export type TikTokCampaignInfluencersFilterFormKeys = UnionOfKeys<TikTokCampaignInfluencersFilterFormValues>;
