import { z } from 'zod';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { YoutubeAnalyticsComparePostsSortOrder, YoutubeAnalyticsPostsSortOrder } from '@/graphql';
import { getToday } from '@/shared/utils';
import { getThirtyDaysAgo } from '@/shared/utils';

export interface CompareAccountType {
  accountId: number;
  avatarUrl: string;
  channelName: string;
}
export const AnalyticsYoutubeCompareFilterSchema = z
  .object({ accountId: FilterValidations.string() })
  .merge(ProfilePostsFilterSchema);
const AnalyticsYoutubeCompareSearchBase = z.object({
  filter: AnalyticsYoutubeCompareFilterSchema.default({
    endDate: getToday(),
    startDate: getThirtyDaysAgo(),
    accountId: ''
  }),
  ...SearchFilterDefaults.pageLimit()
});
export const AnalyticsYoutubeCompareSearchSchema = AnalyticsYoutubeCompareSearchBase.merge(
  z.object({
    sort: SearchFilterDefaults.sort(YoutubeAnalyticsComparePostsSortOrder).default({})
  })
);
export const AnalyticsYoutubeMainSearchSchema = AnalyticsYoutubeCompareSearchBase.merge(
  z.object({
    sort: SearchFilterDefaults.sort(YoutubeAnalyticsPostsSortOrder).default({})
  })
);

export type AnalyticsYoutubeMainSearchSchemaType = z.infer<typeof AnalyticsYoutubeMainSearchSchema>;
export type AnalyticsYoutubeCompareSearchSchemaType = z.infer<typeof AnalyticsYoutubeCompareSearchSchema>;
export type AnalyticsYoutubeCompareFilterFormValues = z.infer<typeof AnalyticsYoutubeCompareFilterSchema>;
