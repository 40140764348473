import { useTranslation } from 'react-i18next';
import { TextField, TextFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const EmailField = (props: Omit<TextFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return (
    <TextField<InfluencerFormKeys>
      name={InfluencerSharedKeys.email}
      placeholder="info@anytag.com"
      help={t('Tooltip.Email Address')}
      title={t('TextForm.Email Address')}
      {...props}
    />
  );
};
