import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerAnalyticsMultipleInstagramAccessTokenWarning.graphql';
import {
  Query,
  QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs
} from '../../__generated__/globalTypes';

export const useInfluencerAnalyticsMultipleInstagramAccessTokenWarningQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerAnalyticsMultipleInstagramAccessTokenWarningLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs>
): QueryResult<Query, QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs> & {
  getInfluencerAnalyticsMultipleInstagramAccessTokenWarning: LazyQueryExecFunction<
    Query,
    QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerAnalyticsMultipleInstagramAccessTokenWarning, result] = useLazyQuery<
    Query,
    QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerAnalyticsMultipleInstagramAccessTokenWarning, ...result };
};

export const useInfluencerAnalyticsMultipleInstagramAccessTokenWarningPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerAnalyticsMultipleInstagramAccessTokenWarning = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs>({
        ...options,
        query: QUERY
      }),
    [client]
  );

  return { getInfluencerAnalyticsMultipleInstagramAccessTokenWarning };
};
