import { useTranslation } from 'react-i18next';
import { Option } from '@/shared/types';
import { getSocialMediaText } from '@/shared/utils';
import { CampaignSocialMediaType } from '@/graphql';
import { useTranslateOptions } from '@/shared/hooks';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const SocialMediaField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const options: Option<CampaignSocialMediaType>[] = useTranslateOptions(
    [
      CampaignSocialMediaType.FACEBOOK,
      CampaignSocialMediaType.INSTAGRAM,
      CampaignSocialMediaType.YOUTUBE,
      CampaignSocialMediaType.INSTAGRAM_STORY,
      CampaignSocialMediaType.TWITTER,
      CampaignSocialMediaType.TIKTOK
    ].map((value) => ({ value, label: getSocialMediaText(value) }))
  );

  return (
    <SelectorField
      options={options}
      title={t('Selector.Social Media')}
      help={t('Tooltip.Social Media')}
      name={MarketplaceFormKeys.socialMedia}
      {...props}
    />
  );
};
