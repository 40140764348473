import { useNavigate } from '@tanstack/react-router';
import { Option } from '@/shared/types';
import { SocialAccountType, XhsPostType } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { SingleSelector } from '@/shared/molecules';
import { SocialIcon, SocialIconProps } from '@/shared/atoms';
import { checkSocialAccountType } from '../utils';
import { InfluencerSelectedAccountInfo } from '../types';
import { InfluencerAccountSearchSchemaType } from '../schemaTypes';

type AccountOption = Option & Pick<InfluencerSelectedAccountInfo, 'id' | 'type'>;

interface InfluencerAccountsSelectorProps {
  socialAccount: InfluencerSelectedAccountInfo;
  allAvailableAccounts: InfluencerSelectedAccountInfo[];
}

export const InfluencerAccountsSelector = ({
  socialAccount,
  allAvailableAccounts
}: InfluencerAccountsSelectorProps) => {
  const { t } = useQueryHelper();
  const navigate = useNavigate({ from: '/influencer/$id/profile/' });

  const accountOptions = allAvailableAccounts.map<AccountOption>((account) => ({
    id: account.id,
    type: account.type,
    value: `${account.id}-${account.type}`,
    label: `${account.name}${account.type === SocialAccountType.FACEBOOK_PAGE ? ` (${t('Page')})` : ''}`,
    prefixIcon: checkSocialAccountType(account.type) ? (
      <SocialIcon size="16px" type={account.type as SocialIconProps['type']} css={{ marginRight: '8px' }} />
    ) : undefined
  }));

  const handleAccountChange = (value: string) => {
    const selectedAccount = accountOptions.find((o) => o.value === value);

    if (selectedAccount && (selectedAccount.id !== socialAccount.id || selectedAccount.type !== socialAccount.type)) {
      navigate({
        search: (current: InfluencerAccountSearchSchemaType) => ({
          ...current,
          filter: {
            ...current.filter,
            id: selectedAccount.id.toString(),
            sm: selectedAccount.type,
            lastUpdated: '',
            xhsPostType: XhsPostType.NORMAL
          }
        })
      });
    }
  };

  return (
    <SingleSelector
      name="account"
      hideInput={true}
      options={accountOptions}
      hideDeselectOption={true}
      onChange={handleAccountChange}
      styles={{ container: (base) => ({ ...base, width: '214px' }) }}
      value={accountOptions.find((o) => o.id === socialAccount.id && o.type === socialAccount.type)?.value}
    />
  );
};
