import {
  namedOperations,
  useGetAdvertiserSettingsInfoQuery,
  useUpdateAdvertiserUserSettingsMutation,
  useUpdatePasswordByAdvertiserUserMutation
} from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useQueryHelper, useToggle, useUpdateDocumentTitle } from '@/shared/hooks';
import {
  SelectedPlanInfo,
  TikTokSettingsForm,
  TikTokSettingsFormProps,
  getTikTokSettingsFormValues
} from '@/shared/organisms';
import { ChangePasswordFormModal, ChangePasswordFormActionType } from '@/shared/molecules';

export const TikTokAccountDetails = () => {
  useUpdateDocumentTitle({ title: 'appHeader.Account', appHeader: 'appHeader.Account' });
  const { t, enqueueSnackbar } = useQueryHelper();
  const modalState = useToggle();
  const { callUpdateAdvertiserUserSettings } = useUpdateAdvertiserUserSettingsMutation({
    refetchQueries: [namedOperations.Query.GetAdvertiserSettingsInfo]
  });
  const { loading: passwordLoading, callUpdatePasswordByAdvertiserUser } = useUpdatePasswordByAdvertiserUserMutation();
  const { data, loading } = useGetAdvertiserSettingsInfoQuery({
    onError: (error) => {
      enqueueSnackbar(t(error?.message || 'cannot get account settings information'), { variant: 'error' });
    }
  });

  const handleSubmit: TikTokSettingsFormProps['onSubmit'] = async ({
    name,
    email,
    countryId,
    advertiserName,
    advertiserCountryId,
    notificationSetting,
    advertiserCompanyUrl,
    advertiserPhoneNumber
  }) => {
    try {
      await callUpdateAdvertiserUserSettings({
        variables: {
          input: {
            name,
            email,
            countryId,
            advertiserName,
            advertiserCountryId,
            notificationSetting,
            advertiserCompanyUrl,
            advertiserPhoneNumber
          }
        }
      });

      enqueueSnackbar(t('Account Settings were successfully saved'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangePassword: ChangePasswordFormActionType['onSubmit'] = async ({ password, currentPassword }) => {
    try {
      await callUpdatePasswordByAdvertiserUser({
        variables: { input: { newPassword: password, originalPassword: currentPassword } }
      });
      enqueueSnackbar(t('New Account Password was saved successfully'), { variant: 'success' });
      modalState.setOff();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <RenderDataWithFallback loading={loading}>
      <TikTokSettingsForm
        onSubmit={handleSubmit}
        onOpenPasswordModal={modalState.setOn}
        defaultValues={getTikTokSettingsFormValues(data?.advertiserUserSettingsDetails)}
        css={{ width: '100%' }}
      />
      <SelectedPlanInfo css={{ marginTop: '24px' }} />
      <ChangePasswordFormModal
        loading={passwordLoading}
        open={modalState.on}
        onClose={modalState.setOff}
        onSubmit={handleChangePassword}
      />
    </RenderDataWithFallback>
  );
};
