import { zodValidator } from '@tanstack/zod-adapter';
import {
  YoutubeCMSConfirmedRevenue,
  YoutubeCMSChannelDetails,
  YoutubeCMSEstimatedRevenue,
  YoutubeCMS
} from '@/pages/YoutubeCMS';
import { adminStaffRoles, adminStaffTalentAgencyRoles } from '@/shared/constants';
import { YoutubeCMSChannelDetailsSearchSchema, YoutubeCMSSearchSchema } from '@/shared/organisms';
import { PermissionRoute } from '@/app-components';
import { PartialRouter } from '../../types';

export const YoutubeCmsRouter = {
  '/_private-routes/youtube-cms': [
    () => <PermissionRoute permissions={adminStaffTalentAgencyRoles} element={<YoutubeCMS />} />,
    {}
  ],
  '/_private-routes/youtube-cms/': [
    YoutubeCMSEstimatedRevenue,
    { validateSearch: zodValidator(YoutubeCMSSearchSchema) }
  ],
  '/_private-routes/youtube-cms/$cmsChannelId': [
    YoutubeCMSChannelDetails,
    { validateSearch: zodValidator(YoutubeCMSChannelDetailsSearchSchema) }
  ],
  '/_private-routes/youtube-cms/confirmed-revenue': [
    () => <PermissionRoute permissions={adminStaffRoles} element={<YoutubeCMSConfirmedRevenue />} />,
    { validateSearch: zodValidator(YoutubeCMSSearchSchema) }
  ]
} as const satisfies PartialRouter;
