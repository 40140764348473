import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { MarketplaceCampaignDetailType, MarketplaceCampaignStatus } from '@/graphql';

export const MarketplaceCampaignsFilterSchema = z.object({
  keyword: FilterValidations.string(),
  countryId: FilterValidations.string(),
  status: FilterValidations.enum(MarketplaceCampaignStatus),
  campaignDetailType: FilterValidations.enum(MarketplaceCampaignDetailType)
});
export const MarketplaceCampaignsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(MarketplaceCampaignsFilterSchema, {
    keyword: '',
    countryId: '',
    status: '',
    campaignDetailType: ''
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type MarketplaceCampaignsSearchSchemaType = z.infer<typeof MarketplaceCampaignsSearchSchema>;
export type MarketplaceCampaignsFilterFormValues = z.infer<typeof MarketplaceCampaignsFilterSchema>;
export type MarketplaceCampaignsFilterFormKeys = UnionOfKeys<MarketplaceCampaignsFilterFormValues>;
