import React, { ReactNode } from 'react';

export interface ModalFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: ReactNode;
}

export const ModalFooter = ({ children, ...restProps }: ModalFooterProps) => (
  <div
    css={{
      display: 'flex',
      padding: '16px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: '#F6F8FA',
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit'
    }}
    {...restProps}
  >
    {children}
  </div>
);
