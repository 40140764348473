import { LinkProps } from '@tanstack/react-router';
import { TikTokCampaignTabsType } from '../TikTokCampaigns';

const tiktokCampaignRoutes = [
  '/tiktok/campaigns/$id/posts',
  '/tiktok/campaigns/$id/report',
  '/tiktok/campaigns/$id/influencers',
  '/tiktok/campaigns/$id'
] as const satisfies LinkProps['to'][];
const tiktokCampaignsListRoute = '/tiktok/campaigns' as const satisfies LinkProps['to'];

export const useTikTokCampaignRoutes = () => {
  const getTikTokCampaignDetailsRoute = (
    tab: keyof typeof TikTokCampaignTabsType
  ): (typeof tiktokCampaignRoutes)[number] => {
    switch (tab) {
      case 'Posts':
        return '/tiktok/campaigns/$id/posts';
      case 'Report':
        return '/tiktok/campaigns/$id/report';
      case 'Influencers':
        return '/tiktok/campaigns/$id/influencers';

      default:
      case 'Details':
        return '/tiktok/campaigns/$id';
    }
  };

  const getTikTokCampaignsListRoute = (): typeof tiktokCampaignsListRoute => '/tiktok/campaigns';

  return { getTikTokCampaignDetailsRoute, getTikTokCampaignsListRoute };
};
