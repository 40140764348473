import { ListWithPagination } from '@/shared/molecules';
import { useYoutubeCmsInvoiceListQuery } from '@/graphql';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import {
  InvoicesList,
  InvoicesHeader,
  InvoicesFilter,
  useInvoicesFilter,
  YoutubeCMSDescription
} from '@/shared/organisms';

export const Invoices = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'documentTitle.Invoices', appHeader: 'appHeader.Invoices' });
  const {
    page,
    limit,
    filter: { keyword, month }
  } = useInvoicesFilter();
  const { data, previousData, loading } = useYoutubeCmsInvoiceListQuery({
    skip: !month,
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      limit,
      month,
      keyword,
      offset: getOffset(page, limit)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.youtubeCmsInvoiceCount?.totalNumber;

  return (
    <>
      <InvoicesHeader hasNoData={!totalRecords} />

      <YoutubeCMSDescription />

      <ListWithPagination pagination={{ totalRecords }}>
        <InvoicesFilter />
        <InvoicesList loading={loading} totalRecords={totalRecords} data={result?.youtubeCmsInvoiceList || []} />
      </ListWithPagination>
    </>
  );
};
