import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template } from './Template';
import { usePackagesFilter } from './hooks';

export const PackagesFilter = () => {
  const { filter, setFilterForm } = usePackagesFilter();

  return (
    <FilterWrapper>
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template />} />
    </FilterWrapper>
  );
};

export { usePackagesFilter };
export * from './schemaTypes';
