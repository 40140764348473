import { CampaignSocialMediaType, useEngagementPostDetailQuery, useEngagementPostCommentsQuery } from '@/graphql';
import { POST_COMMENTS_LIMIT } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailStatistics, PostDetailsType } from '@/shared/types';
import { checkIsReelPost } from '@/shared/utils';
import { PostDetailsTemplateType } from '../../types';
import { EngagementPostDetailsTabProps } from '../EngagementPostDetailsTab';
import { EngagementPostStatus } from '../EngagementPostStatus';

interface Props extends Pick<PostDetailsType, 'id'> {
  onCloseModal?: () => void;
}

export const useEngagementPostDetailsData = ({ id, onCloseModal }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useEngagementPostDetailQuery({
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: { pk: Number(id) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const { data: commentsData, loading: fetchingComments } = useEngagementPostCommentsQuery({
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: { postId: Number(id), limit: POST_COMMENTS_LIMIT }
  });

  const details = data?.engagementPostDetail;
  const statistics: PostDetailStatistics = (() => {
    switch (details?.socialMedia) {
      case CampaignSocialMediaType.INSTAGRAM:
        return { likes: details?.likes, reach: undefined, comments: details?.comments };
      case CampaignSocialMediaType.INSTAGRAM_STORY:
        return { likes: details?.likes, reach: undefined, replies: details?.comments };
      case CampaignSocialMediaType.YOUTUBE:
        return { likes: details?.likes, views: details?.views, comments: details?.comments };
      case CampaignSocialMediaType.TWITTER:
        return { likes: details?.likes, retweets: details?.shares, replies: details?.comments };
      case CampaignSocialMediaType.THREADS:
        return { likes: details?.likes, replies: details?.comments };
      case CampaignSocialMediaType.FACEBOOK:
        return { likes: details?.likes, comments: details?.comments, shares: details?.shares };
      case CampaignSocialMediaType.TIKTOK:
      case CampaignSocialMediaType.DOUYIN:
        return {
          likes: details?.likes,
          views: details?.views,
          comments: details?.comments,
          shares: details?.shares
        };
      case CampaignSocialMediaType.XHS:
        return {
          likes: details?.likes,
          views: details?.views,
          saves: details?.saves
        };
      default:
        return {};
    }
  })();
  const postDetails: EngagementPostDetailsTabProps['postDetails'] = details
    ? {
        statistics,
        caption: details?.caption,
        postUrl: details?.postUrl || '',
        socialMedia: details?.socialMedia as PostDetailsTemplateType['socialMedia'], // we don't support type OTHERS, UNSELECT, ...
        isReel: checkIsReelPost(details?.postUrl),
        comments: commentsData?.engagementPostComments || [],
        images: details?.images?.length ? details?.images : !details?.thumbNail ? [] : [details.thumbNail],
        info: {
          postDate: details?.postedDate,
          id: String(details?.engagementPostDetailInfluencer?.id ?? ''),
          name: details?.engagementPostDetailInfluencer?.name,
          avatar: details?.engagementPostDetailInfluencer?.avatar,
          followers: details?.engagementPostDetailInfluencer?.followersCount
        }
      }
    : undefined;

  return {
    details,
    postDetails,
    loading: loading || fetchingComments,
    postStatus: details?.status ? (
      <EngagementPostStatus
        warningReason={details.warningReason || undefined}
        status={details?.status}
        css={{ fontSize: '10px', height: '16px' }}
      />
    ) : null
  };
};
