import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { THEME } from '@/shared/constants';
import { HelpIcon } from '../Icon';

interface NoDataTitleProps {
  title: string;
  help?: string;
  className?: string;
  children?: ReactNode;
}
export const NoDataTitle = ({ help, title, children, className }: NoDataTitleProps) => (
  <div css={styles.wrapper} className={className}>
    {title}
    {help ? <HelpIcon help={help} position="top" css={{ marginLeft: '6px' }} /> : null}
    {children}
  </div>
);
const styles = {
  wrapper: css({
    display: 'flex',
    fontSize: '16px',
    alignItems: 'center',
    color: THEME.text.colors.black.lv1
  })
};
