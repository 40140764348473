import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/BulkUpdateUserProposalInfluencersBudget.graphql';
import { Mutation, MutationbulkUpdateUserProposalInfluencersBudgetArgs } from '../../__generated__/globalTypes';

export const useBulkUpdateUserProposalInfluencersBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationbulkUpdateUserProposalInfluencersBudgetArgs>
) => {
  const [callBulkUpdateUserProposalInfluencersBudget, result] = useMutation<
    Mutation,
    MutationbulkUpdateUserProposalInfluencersBudgetArgs
  >(MUTATION, options);

  return { callBulkUpdateUserProposalInfluencersBudget, ...result };
};

export type BulkUpdateUserProposalInfluencersBudgetMutationFunction =
  Mutation['bulkUpdateUserProposalInfluencersBudget'];
