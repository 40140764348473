import { z } from 'zod';
import { FilterValidations } from '@/shared/validations';
import {
  PackageSummary,
  SocialAccountType,
  PackageInfluencer,
  SocialAccountStatus,
  PackageSocialAccount,
  ProposalJobDescriptionBreakDownMode
} from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { ProposeInfluencersTableMode } from '../../../Influencers';

export interface PackageProposedInfluencer extends PackageSummary, Omit<PackageInfluencer, 'accountList' | 'summary'> {
  accountList: readonly PackageSocialAccount[];
}
export interface RowPackageInfluencerData {
  id: number;
  name: string;
  avatar?: string | null;
  status: SocialAccountStatus;
}

export interface RowPackageSocialAccountData {
  id: number;
  name?: string | null;
  lastIndex?: boolean;
  socialMedia: SocialAccountType;
}
export interface RowPackageStatsData {
  budget: number;
  profit: number;
  influencerCost: number;
  addedDate?: string | null;
  followersCount?: number | null;
  engagementRateByViews?: number | null;
  engagementRateByFollowers?: number | null;
  engagementPosted?: number | null;
  engagementProposed?: number | null;
}

export interface RowPackageData {
  index: number;
  stats: RowPackageStatsData;
  influencer: RowPackageInfluencerData;
  socialAccount?: RowPackageSocialAccountData;
}

export enum EditablePackageInfluencersFieldKeys {
  budget = 'budget',
  profit = 'profit',
  influencerCost = 'influencerCost'
}
export enum PackageContentTabType {
  INFLUENCERS_LIST = 'INFLUENCERS_LIST',
  ADD_INFLUENCER = 'ADD_INFLUENCER'
}
export const PackageProposalFilterSchema = z.object({
  keyword: FilterValidations.string().optional(),
  tableViewMode: FilterValidations.enum(ProposeInfluencersTableMode).optional(),
  jdMode: FilterValidations.enum(ProposalJobDescriptionBreakDownMode).optional()
});
export const PackageProposalSearchSchema = z.object({
  filter: PackageProposalFilterSchema.default({
    tableViewMode: ProposeInfluencersTableMode.VIEW,
    jdMode: ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN
  }),
  page: z.number().default(1),
  limit: z.number().default(LIMIT)
});

export type PackageProposalSearchSchemaType = z.infer<typeof PackageProposalSearchSchema>;
export type PackageProposalFilterFormValues = z.infer<typeof PackageProposalFilterSchema>;
