import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useUserRoles } from '@/auth';
import { MIME_ACCEPT } from '@/shared/constants';
import { FormStyle, Label } from '@/shared/atoms';
import { useAllAvailableCountryOptions, useAllAdvertisersForSearchOptions } from '@/shared/hooks';
import {
  TextField,
  SelectorField,
  PasswordField,
  CheckBoxField,
  CustomerValues,
  UploadAgencyLogo,
  BigQuerySearchField
} from '@/shared/molecules';
import { AgencyFormValues, AgencyFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { allAdvertiserOptions } = useAllAdvertisersForSearchOptions();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const {
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting }
  } = useFormContext<AgencyFormValues>();

  const values = watch();
  const isAddMode = values?.type === 'add';

  const handleClickSendEmail = (_: string, value: boolean) => {
    if (isAddMode) {
      // we need to clear password fields each time user click send email to prevent validation err on password
      reset({ ...values, password: '', passwordConfirm: '', hasInvitationEmail: value });
    }
  };

  const handleHubSpotChange = (_: AgencyFormKeys, customerValues: CustomerValues) => {
    reset({
      ...values,
      agencyName: customerValues.label,
      hubspotId: customerValues.value || '',
      internalNetsuiteId: customerValues.internalNetsuiteId || ''
    });
  };

  return (
    <FormStyle.Wrapper css={{ flex: 1 }} hasBorder>
      <div css={{ padding: '24px' }}>
        <FormStyle.SectionTitle label={t('Agency Information')} index={isAddMode ? 1 : undefined} />

        {isAdminStaff ? (
          <BigQuerySearchField<AgencyFormKeys> name="hubspotId" onChange={handleHubSpotChange} type="hubspotCustomer" />
        ) : null}

        <TextField<AgencyFormKeys>
          name="agencyName"
          help={t('Tooltip.Agency Name')}
          title={t('TextForm.Agency Name')}
          placeholder={t<string>('TextForm.AdAsiaLtd')}
          required
        />

        <SelectorField<AgencyFormKeys>
          title={t('Selector.Advertisers')}
          name="advertiserIds"
          help={t('Tooltip.User Advertiser')}
          options={allAdvertiserOptions}
          required
          multiple
        />

        <TextField<AgencyFormKeys>
          name="companyUrl"
          help={t('Tooltip.Agency URL')}
          title={t('TextForm.Agency URL')}
          placeholder="https://app.anytag.jp"
        />

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <TextField<AgencyFormKeys>
            name="phoneNumber"
            help={t('Tooltip.Phone Number')}
            title={t('TextForm.Phone Number')}
            placeholder="+66020485707"
          />

          <SelectorField<AgencyFormKeys>
            title={t('Selector.Country')}
            name="countryId"
            help={t('Tooltip.Agency Country')}
            options={allAvailableCountryOptions}
            required
          />
        </FormStyle.FieldsGroup>

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <FormStyle.FieldWrapper css={styles.logoWrapper}>
            <Label title={t('Agency Logo')} help={t('Tooltip.customLogoHelp')} />
            <UploadAgencyLogo
              name="logo"
              multiple={false}
              hasError={!!errors.logo}
              value={values.logo ? [values.logo] : []}
              accept={{ ...MIME_ACCEPT.IMAGE, ...MIME_ACCEPT.GIF }}
              onChange={(_, value) => setValue('logo', value[0] || '')}
              notes={[
                t('General.DragAndDropInfo'),
                t('General.DragAndDropMaxSize', { maxSize: '10MB' }),
                t('General.DragAndDropMIME', { MIME: 'PNG, GIF, JPEG' }),
                t('General.DragAndDropImageRatio', { ratio: '16:9' })
              ]}
            />
          </FormStyle.FieldWrapper>
        </FormStyle.FieldsGroup>

        {isAddMode ? (
          <>
            <FormStyle.SectionTitle index={2} label={t('User Information')} css={{ marginTop: '24px' }} />

            <TextField<AgencyFormKeys>
              name="name"
              title={t('TextForm.Name')}
              placeholder={t<string>('NameExample')}
              required
            />

            <FormStyle.FieldsGroup itemsPerRow={2}>
              <TextField<AgencyFormKeys>
                name="email"
                placeholder="your@email.com"
                title={t('TextForm.Email Address')}
                required
              />
            </FormStyle.FieldsGroup>

            {!values.hasInvitationEmail && (
              <FormStyle.FieldsGroup itemsPerRow={2}>
                <PasswordField<AgencyFormKeys> name="password" title={t('Password.Password')} dynamicError />
                <PasswordField<AgencyFormKeys>
                  name="passwordConfirm"
                  title={t('Password.Confirm Password')}
                  dynamicError
                />
              </FormStyle.FieldsGroup>
            )}

            <CheckBoxField<AgencyFormKeys>
              name="hasInvitationEmail"
              onChange={handleClickSendEmail}
              label={t('Send invitation email')}
            />
          </>
        ) : null}
      </div>

      <FormStyle.ButtonSubmitWrapper>
        <FormStyle.ButtonSubmit title={t('Button.Save')} loading={isSubmitting} />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};

const styles = {
  logoWrapper: css({
    '& > div > div:first-of-type': {
      border: '1px dashed #e0e8ed',
      '> div > i': { margin: 0, border: 'none', padding: '8px 24px 24px' }
    }
  })
};
