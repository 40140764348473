import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllYoutubeCmsEstimateChannels.graphql';
import { Query, QueryallYoutubeCmsEstimateChannelsArgs } from '../../__generated__/globalTypes';

export const useAllYoutubeCmsEstimateChannelsQuery = (
  options?: QueryHookOptions<Query, QueryallYoutubeCmsEstimateChannelsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallYoutubeCmsEstimateChannelsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllYoutubeCmsEstimateChannelsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallYoutubeCmsEstimateChannelsArgs>
): QueryResult<Query, QueryallYoutubeCmsEstimateChannelsArgs> & {
  getAllYoutubeCmsEstimateChannels: LazyQueryExecFunction<Query, QueryallYoutubeCmsEstimateChannelsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllYoutubeCmsEstimateChannels, result] = useLazyQuery<Query, QueryallYoutubeCmsEstimateChannelsArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getAllYoutubeCmsEstimateChannels, ...result };
};

export const useAllYoutubeCmsEstimateChannelsPromiseQuery = () => {
  const client = useApolloClient();

  const getAllYoutubeCmsEstimateChannels = useCallback(
    (options?: Omit<QueryOptions<QueryallYoutubeCmsEstimateChannelsArgs, Query>, 'query'>) =>
      client.query<Query, QueryallYoutubeCmsEstimateChannelsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllYoutubeCmsEstimateChannels };
};
