import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAllCountryOptions } from '@/shared/hooks';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys, InfluencersFilterFormValues } from '../types';

interface CountryFieldProps extends Omit<SelectorFieldProps, 'name'> {
  hasAllCountryOption?: boolean;
}

export const CountryField = ({ multiple, options, hasAllCountryOption, onSubmit, ...props }: CountryFieldProps) => {
  const { t } = useTranslation();
  const { allCountryOptions } = useAllCountryOptions({ hasAllCountryOption: hasAllCountryOption && !multiple });
  const { watch, setValue } = useFormContext<InfluencersFilterFormValues>();

  const countryIdsValue = watch('categoryIds');

  const handleSubmitCountry = async () => {
    // clear regionIds if multiple countries campaign
    if (multiple && countryIdsValue.length >= 2) {
      setValue('regionIds', []);
      await Promise.resolve();
    }

    onSubmit?.();
  };

  return (
    <SelectorField<InfluencersFilterFormKeys>
      multiple={multiple}
      onSubmit={handleSubmitCountry}
      options={options ?? allCountryOptions}
      placeholder={t<string>('Selector.Countries')}
      singleSelectorProps={{ hideDeselectOption: true }}
      name={multiple ? 'countryIds' : 'countryId'}
      {...props}
    />
  );
};
