import { useTranslation } from 'react-i18next';
import { InstagramStoryPost } from '@/graphql';
import { THEME } from '@/shared/constants';
import { MediaPreviewWithMetrics } from '@/shared/atoms';
import { bigIntFormatter } from '@/shared/utils';
import { usePostDetailsParam } from '@/shared/hooks';
import { ImageSlider, InfluencerInstagramAnalyticsStoryDetailsModal } from '@/shared/molecules';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { AnalyticsFeedPostsWrapper, AnalyticsFeedPostsWrapperProps } from '../../../shared';
import { useAnalyticsProfileSearch } from '../../../hooks';

export interface AnalyticsInstagramDashboardStoryPostsTemplateProps
  extends Pick<AnalyticsFeedPostsWrapperProps, 'title' | 'help' | 'moreDetailProps'> {
  data?: readonly InstagramStoryPost[];
}

export const AnalyticsInstagramDashboardStoryPostsTemplate = ({
  data = [],
  ...restProps
}: AnalyticsInstagramDashboardStoryPostsTemplateProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  return (
    <AnalyticsFeedPostsWrapper
      dataNotAvailable={!data.length}
      title={t('HeaderColumn.Story Posts')}
      help={t('Tooltip.dashboardStoryPosts')}
      headerChildren={
        <p css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3, marginLeft: '8px' }}>
          {t('Annotation.Story Posts')}
        </p>
      }
      {...restProps}
    >
      <ImageSlider total={data.length} css={{ paddingLeft: '16px' }}>
        {data.map(({ id, thumbNail, reach, comments }) => (
          <MediaPreviewWithMetrics
            key={id}
            src={thumbNail}
            css={THEME.mediaPreview.igReelOrStory}
            onClick={() => setPostDetailsParam(id)}
            statistics={[
              { label: t('Reach'), value: bigIntFormatter(reach, 0, { k: true, m: true }) },
              { label: t('General.Replies'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
            ]}
          />
        ))}
      </ImageSlider>
    </AnalyticsFeedPostsWrapper>
  );
};

export interface AnalyticsInstagramDashboardStoryPostsProps {
  brandAccountName?: string;
  data?: readonly InstagramStoryPost[];
  accountId: AnalyticsSelectedAccountInfo['id'];
}

export const AnalyticsInstagramDashboardStoryPosts = ({
  data = [],
  accountId,
  brandAccountName
}: AnalyticsInstagramDashboardStoryPostsProps) => {
  const { filter, params } = useAnalyticsProfileSearch();

  return (
    <>
      <AnalyticsInstagramDashboardStoryPostsTemplate
        data={data}
        moreDetailProps={{
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post',
          params,
          search: {
            filter: {
              brandAccountName,
              endDate: filter.endDate,
              startDate: filter.startDate
            }
          }
        }}
      />

      <InfluencerInstagramAnalyticsStoryDetailsModal
        isAnalytics
        posts={data.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: accountId }))}
      />
    </>
  );
};
