import { useEngagementPermissions } from '@/auth';
import {
  EngagementDetail,
  EngagementCampaignStatus,
  EngagementSocialAcccountBudgetInput,
  ProposalJobDescriptionBreakDownMode,
  useDenyEngagementInfluencerProposalListMutation
} from '@/graphql';
import { BlackBar, Button } from '@/shared/atoms';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { ProposalDownloadButton } from '../../../../shared';
import { useSelectedInfluencersContext } from '../../SelectedInfluencersContext';
import { BulkEditModal } from './BulkEditModal';
import { useBulkUpdateInfluencerAccountsBudget } from './hooks';

interface SelectInfluencersActionBarProps {
  numberSelected: number;
  onSelectAll: () => void;
  hasAllSelected?: boolean;
  onToggleSelect: () => void;
  engagement: EngagementDetail;
  selectedInfluencerIds: number[];
  jdMode: ProposalJobDescriptionBreakDownMode;
  selectedAccounts: EngagementSocialAcccountBudgetInput[];
}

export const SelectInfluencersActionBar = ({
  jdMode,
  onSelectAll,
  numberSelected,
  hasAllSelected,
  onToggleSelect,
  selectedAccounts,
  selectedInfluencerIds,
  engagement: { id, currency, status, adAgencyMarginRate }
}: SelectInfluencersActionBarProps) => {
  const bulkModal = useToggleState();
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { isEditMode } = useSelectedInfluencersContext();
  const { hideAllowDenyForModal } = useEngagementPermissions();
  const { callDenyEngagementInfluencerProposalList, loading: rejecting } =
    useDenyEngagementInfluencerProposalListMutation({ onCompleted: invalidateRouteLoader });
  const { updateBulkInfluencerAccountsBudget } = useBulkUpdateInfluencerAccountsBudget({
    jdMode,
    engagementId: id,
    selectedAccounts,
    selectedInfluencerIds
  });

  const hasRejectButton =
    !hideAllowDenyForModal &&
    [EngagementCampaignStatus.REVIEWING, EngagementCampaignStatus.DRAFT, EngagementCampaignStatus.ONGOING].includes(
      status
    );

  const handleRejectInfluencers = async () => {
    try {
      await callDenyEngagementInfluencerProposalList({
        variables: {
          input: { campaignId: id, deniedReason: '', influencerIds: selectedInfluencerIds }
        }
      });
      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <BlackBar
        disabled={rejecting}
        selectAllProps={{ onClick: onSelectAll }}
        checkboxProps={{
          onClick: onToggleSelect,
          indeterminate: !hasAllSelected,
          label: t('Selected', { count: numberSelected })
        }}
      >
        {!isEditMode ? (
          <>
            {hasRejectButton && (
              <Button
                variant="red"
                loading={rejecting}
                disabled={rejecting}
                title={t('Button.Reject')}
                onClick={handleRejectInfluencers}
                css={{ padding: '0 16px', marginLeft: '16px' }}
              />
            )}

            <ProposalDownloadButton
              engagementId={id}
              css={{ marginLeft: 'auto' }}
              influencerIds={selectedInfluencerIds}
            />
          </>
        ) : (
          <Button
            variant="white"
            onClick={bulkModal.open}
            title={t('Button.Bulk Edit')}
            css={{ padding: '0 16px', marginLeft: '16px' }}
          />
        )}
      </BlackBar>

      <BulkEditModal
        open={bulkModal.status}
        onClose={bulkModal.close}
        currency={currency || ''}
        hasAdAgencyMarginRate={!!adAgencyMarginRate}
        onSubmit={updateBulkInfluencerAccountsBudget}
      />
    </>
  );
};
