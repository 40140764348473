import { Navigate, Outlet, ReactNode } from '@tanstack/react-router';
import { useAuthData } from '@/auth';
import { UserRoles } from '@/graphql';
import { InitialLoading } from '@/shared/molecules';

export interface PermissionRouteProps {
  permissions?: UserRoles[];
  element?: ReactNode;
}

export const PermissionRoute = ({ permissions = [], element = <Outlet /> }: PermissionRouteProps) => {
  const {
    auth: { role, status, company }
  } = useAuthData();

  if (status === 'idle') {
    return <InitialLoading />;
  }

  if (role === UserRoles.ADVERTISER && company === 'TIKTOK') {
    return <Navigate to="/tiktok/ad-campaign-report" />;
  }

  if (!role || (permissions.length > 0 && !permissions.includes(role))) {
    return <Navigate to="/not-found" />;
  }

  return element;
};
