import { useCreateAdvertiserWithUserV2Mutation } from '@/graphql';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useCustomHeader, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { AdvertiserForm, AdvertiserFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const AddAdvertiser = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    title: 'documentTitle.AddAdvertiser',
    appHeader: 'appHeader.Add New Advertiser',
    href: '/advertiser'
  });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateAdvertiserWithUserV2 } = useCreateAdvertiserWithUserV2Mutation();

  const handleSubmit: FormAction<AdvertiserFormValues>['onSubmit'] = async ({
    hubspotId,
    countryId,
    companyUrl,
    phoneNumber,
    advertiserId,
    isNewlyCreated,
    advertiserName,
    internalNetsuiteId,
    ...rest
  }) => {
    try {
      await callCreateAdvertiserWithUserV2({
        variables: {
          input: {
            selectedAdvertiserId: isNewlyCreated ? null : Number(advertiserId),
            newAdvertiserInput: isNewlyCreated
              ? {
                  hubspotId: hubspotId || null,
                  internalNetsuiteId: internalNetsuiteId || null,
                  advertiserName: advertiserName || '',
                  countryId,
                  phoneNumber: phoneNumber || null,
                  companyUrl: companyUrl || null
                }
              : null,
            user:
              rest.type === 'add' && (rest.userName || rest.email)
                ? {
                    email: rest.email,
                    userName: rest.userName,
                    password: rest.password || undefined,
                    hasInvitationEmail: rest.shouldSendInvitation
                  }
                : null
          }
        }
      });
      enqueueSnackbar(t('succeededInCreating', { name: advertiserName }), { variant: 'success' });
      navigate({ to: '/advertiser' });
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name: advertiserName }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <div css={{ margin: '0 auto', width: THEME.container.details.width }}>
      <Header />

      <AdvertiserForm
        onSubmit={handleSubmit}
        css={{ '.advertiser-form-wrapper': { boxShadow: THEME.shadows.boxShadow } }}
      />
    </div>
  );
};
