import { useUserRoles } from '@/auth';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { Modal, ModalContent, ModalTitle, Button, ButtonProps, ModalFooterActions } from '@/shared/atoms';
import {
  TikTokSpecialCampaignDetail,
  TikTokSpecialCampaignStatus,
  useApproveTiktokSpecialCampaignMutation
} from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';

interface ApproveButtonProps extends ButtonProps {
  tiktokSpecialCampaign?: TikTokSpecialCampaignDetail;
}

export const ApproveButton = ({ tiktokSpecialCampaign, ...buttonProps }: ApproveButtonProps) => {
  const confirmModal = useToggleState();
  const { isAdminStaff } = useUserRoles();
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { callApproveTiktokSpecialCampaign, loading } = useApproveTiktokSpecialCampaignMutation({
    onCompleted: invalidateRouteLoader
  });

  // TODO: we might need to check permissions here as well
  // Asking: https://anymindgroup.slack.com/archives/C02C5J5B7KL/p1730428908925939
  const hasApproveButton =
    isAdminStaff && tiktokSpecialCampaign && tiktokSpecialCampaign?.status === TikTokSpecialCampaignStatus.REVIEWING;

  const handleApprove = async () => {
    if (!hasApproveButton) return;

    try {
      await callApproveTiktokSpecialCampaign({ variables: { input: { campaignId: tiktokSpecialCampaign.id } } });

      enqueueSnackbar(t('succeededInUpdating', { name: tiktokSpecialCampaign.title }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    } finally {
      confirmModal.close();
    }
  };

  return (
    hasApproveButton && (
      <>
        <Button
          variant="blue"
          onClick={confirmModal.open}
          title={t('Button.Approve')}
          css={{ padding: '0 24px', minWidth: '100px' }}
          {...buttonProps}
        />

        {/* TODO: we don't have design for this yet  */}
        <Modal
          open={confirmModal.status}
          onClose={confirmModal.close}
          css={{ width: 'max-content', maxWidth: '600px' }}
          hasCloseIcon
        >
          <ModalContent>
            <ModalTitle>{t('Modal.approveTikTokCampaignTitle')}</ModalTitle>
            <p css={{ marginTop: '24px' }}>{t('Modal.approveTikTokCampaignDescription')}</p>
          </ModalContent>
          <ModalFooterActions
            cancelButtonProps={{ onClick: confirmModal.close }}
            submitButtonProps={{
              onClick: handleApprove,
              loading: { showIcon: true, status: loading },
              title: t(loading ? 'Button.Approving' : 'Button.Approve')
            }}
          />
        </Modal>
      </>
    )
  );
};
