import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { FormStyle, Icon } from '@/shared/atoms';

export const styles = {
  section: css({
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignSelf: 'stretch',
    borderRadius: '3px',
    background: THEME.background.colors.white,
    boxShadow: THEME.shadows.boxShadowContainer
  }),
  label: css({
    fontSize: '16px',
    fontWeight: 600
  }),
  bcList: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    background: THEME.background.colors.white,
    padding: '16px 24px',
    borderRadius: '3px',
    border: THEME.border.base
  }),
  warning: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3px',
    padding: '8px',
    border: `1px solid ${THEME.colors.yellow[20]}`,
    background: '#FFFBF3',
    fontSize: '12px',
    marginBottom: '16px',
    marginTop: '24px'
  }),
  adAccountChain: (isDisconnected: boolean) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: isDisconnected ? '#D4D8DC' : THEME.colors.darkGreen['20']
    })
};

interface TikTokSectionTitleProps {
  isConnected: boolean;
  isActive?: boolean;
  title: string;
  index?: number;
  className?: string;
}
export const TikTokSectionTitle = ({ isConnected, title, className, index = 1, isActive }: TikTokSectionTitleProps) =>
  isConnected ? (
    <div css={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px' }} className={className}>
      <Icon
        icon="check"
        color={THEME.background.colors.white}
        size={12}
        css={{ backgroundColor: THEME.attention.colors.green, padding: '4px', borderRadius: '50%' }}
      />
      <label css={styles.label}>{title}</label>
    </div>
  ) : (
    <FormStyle.SectionTitle
      index={index}
      size={20}
      label={title}
      css={[styles.label, { opacity: isActive ? 1 : 0.3, marginBottom: isActive ? '8px' : 0 }]}
      className={className}
    />
  );
