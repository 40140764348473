import { useTranslation } from 'react-i18next';
import { TableData } from '@/shared/types';
import { useTableMaxHeight } from '@/shared/hooks';
import { BlackBar, Button, Table } from '@/shared/atoms';
import { YoutubeCMSChannel } from '../types';
import { useYoutubeCMSChannelsTable } from './hooks';

export type YoutubeCMSChannelsListProps = TableData<YoutubeCMSChannel> &
  Pick<Parameters<typeof useYoutubeCMSChannelsTable>[0], 'type' | 'modifiable'>;

export const YoutubeCMSChannelsList = ({ type, loading, data = [], modifiable }: YoutubeCMSChannelsListProps) => {
  const { t } = useTranslation();
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();
  const { rows, saving, columns, hasRowChange, handleSaveChange } = useYoutubeCMSChannelsTable({
    data,
    type,
    modifiable
  });

  return (
    <div css={{ position: 'relative' }}>
      {hasRowChange ? (
        <BlackBar>
          <Button
            title={t('Button.Save')}
            variant="blue"
            loading={saving}
            id="skipOutsideClick"
            onClick={handleSaveChange}
            css={{ marginLeft: 'auto', padding: '0 16px' }}
          />
        </BlackBar>
      ) : null}

      <Table
        ref={offsetRef}
        loading={loading}
        maxHeight={defaultTableMaxHeight}
        data={{ columns, rows, rowHeight: '64px' }}
        noTopBorderRadius
        stickyHeader
      />
    </div>
  );
};
