import 'highcharts/modules/wordcloud';
import { NoDataContentWrapper, NoDataContentWrapperProps } from '@/shared/atoms';
import { HighChart, Highcharts } from '../HighChart';

export interface TagsChartProps
  extends Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText' | 'dataNotAvailable'> {
  height?: string;
  data?: { name: string; weight: number }[];
}

export const TagsChart = ({ height = '250px', data, dataNotAvailable, ...noDataContentProps }: TagsChartProps) => {
  const options: Highcharts.Options = {
    title: { text: '' },
    legend: { enabled: false },
    tooltip: { enabled: false },
    chart: {
      height,
      spacing: [0, 0, 0, 0],
      // min|max fontSize doesn't work for single word - it takes all available space, to fix it we can set margins to make it smaller
      margin: data?.length === 1 ? 80 : 0
    },
    series: [
      {
        data,
        minFontSize: 5,
        maxFontSize: 15,
        type: 'wordcloud',
        name: 'Occurrences',
        colors: ['#CE2E22', '#AC655D', '#DA9384', '#F1C5E0', '#CC70D7', '#852D40', '#391D84', '#EA90C4']
      }
    ]
  };

  return (
    <NoDataContentWrapper
      dataNotAvailable={!data?.length || dataNotAvailable}
      css={{
        minHeight: height,
        '.highcharts-root': { letterSpacing: 'normal' }
      }}
      {...noDataContentProps}
    >
      <HighChart options={options} />
    </NoDataContentWrapper>
  );
};
