import { FormAction } from '@/shared/types';
import { useQueryHelper } from '@/shared/hooks';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useUpdateTiktokSpecialCampaignMutation } from '@/graphql';
import {
  TikTokCampaignForm,
  getTikTokCampaignFormInput,
  TikTokCampaignFormValues,
  getTikTokCampaignFormValues
} from '@/shared/organisms';
import { useTikTokCampaignDetailsContext } from '../TikTokCampaignDetailsContext';

export const TikTokCampaignDetailsForm = () => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { currentPlan, tiktokSpecialCampaign } = useTikTokCampaignDetailsContext();
  const { callUpdateTiktokSpecialCampaign } = useUpdateTiktokSpecialCampaignMutation({
    onCompleted: invalidateRouteLoader
  });

  const handleSubmit: FormAction<TikTokCampaignFormValues>['onSubmit'] = async (values, methods) => {
    try {
      await callUpdateTiktokSpecialCampaign({
        variables: { input: { ...getTikTokCampaignFormInput(values), id: tiktokSpecialCampaign.id } }
      });

      enqueueSnackbar(t('succeededInUpdating', { name: values.title }), { variant: 'success' });
    } catch (error) {
      // Trigger prompt stay when has error
      methods.setError('root.server', { type: 'server', message: error.message });
      enqueueSnackbar(t('failedToUpdate', { name: values.title }), { variant: 'error' });
      enqueueSnackbar(t(error || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <div css={{ boxShadow: THEME.shadows.boxShadow }}>
      <TikTokCampaignForm
        onSubmit={handleSubmit}
        currentPlan={currentPlan}
        defaultValues={getTikTokCampaignFormValues(tiktokSpecialCampaign)}
      />
    </div>
  );
};
