import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ConnectPackageWithInfluencerAccounts.graphql';
import { Mutation, MutationconnectPackageWithInfluencerAccountsArgs } from '../../__generated__/globalTypes';

export const useConnectPackageWithInfluencerAccountsMutation = (
  options?: MutationHookOptions<Mutation, MutationconnectPackageWithInfluencerAccountsArgs>
) => {
  const [callConnectPackageWithInfluencerAccounts, result] = useMutation<
    Mutation,
    MutationconnectPackageWithInfluencerAccountsArgs
  >(MUTATION, options);

  return { callConnectPackageWithInfluencerAccounts, ...result };
};

export type ConnectPackageWithInfluencerAccountsMutationFunction = Mutation['connectPackageWithInfluencerAccounts'];
