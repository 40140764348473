import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, ModalTitle, ModalFooter, ModalContent } from '@/shared/atoms';

export interface PublishModalProps {
  open?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onUpdateStatus?: (isDemoPublished?: boolean) => void;
}

export const PublishModal = ({ open, onClose, loading, onUpdateStatus }: PublishModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} css={{ maxWidth: '600px' }} hasCloseIcon>
      <ModalContent>
        <ModalTitle>{t('Publish')}</ModalTitle>
        <div css={{ paddingTop: '16px' }}>
          <p>{t('ConfirmationForMakingCampaignPublic')}</p>
          <p css={{ color: '#ff2b52' }}>{t('DemoPublishInfo')}</p>
        </div>
      </ModalContent>

      <ModalFooter css={{ gap: '10px' }}>
        <Button variant="white" css={styles.btnPadding} title={t('Button.Cancel')} onClick={onClose} />
        <Button
          variant="purple"
          loading={loading}
          css={styles.btnPadding}
          title={t('Button.Demo Publish')}
          onClick={() => onUpdateStatus?.(true)}
        />
        <Button
          variant="blue"
          loading={loading}
          css={styles.btnPadding}
          title={t('Button.Publish')}
          onClick={() => onUpdateStatus?.(false)}
        />
      </ModalFooter>
    </Modal>
  );
};

const styles = {
  btnPadding: css({ padding: '0 16px', minWidth: '105px' })
};
