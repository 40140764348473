import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeAnalyticsPosts.graphql';
import { Query, QueryyoutubeAnalyticsPostsArgs } from '../../__generated__/globalTypes';

export const useYoutubeAnalyticsPostsQuery = (options?: QueryHookOptions<Query, QueryyoutubeAnalyticsPostsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeAnalyticsPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeAnalyticsPostsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeAnalyticsPostsArgs>
): QueryResult<Query, QueryyoutubeAnalyticsPostsArgs> & {
  getYoutubeAnalyticsPosts: LazyQueryExecFunction<Query, QueryyoutubeAnalyticsPostsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeAnalyticsPosts, result] = useLazyQuery<Query, QueryyoutubeAnalyticsPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeAnalyticsPosts, ...result };
};

export const useYoutubeAnalyticsPostsPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeAnalyticsPosts = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeAnalyticsPostsArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeAnalyticsPostsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeAnalyticsPosts };
};
