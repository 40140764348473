import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mailOpenedImage } from '@/shared/assets';
import { ButtonLink, ButtonSubmit, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TextField } from '@/shared/molecules';

import { RefreshPasswordFormKeys, RefreshPasswordFormValues } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<RefreshPasswordFormValues>();

  const values = watch();

  return (
    <FormStyle.Wrapper>
      {values.isSuccess ? (
        <>
          <img width="48px" height="48px" alt="mail" css={{ margin: '0 auto' }} src={mailOpenedImage} />
          <h1 css={styles.title}>{t('Password.Reset your password')}</h1>
          <p css={styles.email}>{values.email}</p>
          <p css={[styles.info, { marginTop: '40px' }]}>{t('checkInbox')}</p>
          <p css={styles.info}>{t('resetFollowInstruction')}</p>
          <ButtonLink
            to="/"
            title={t('Button.Back')}
            css={{ margin: '0 auto', padding: '0 24px', width: 'max-content', marginTop: '24px' }}
          />
        </>
      ) : (
        <>
          <h1 css={styles.title}>{t('Password.Reset your password')}</h1>
          <p css={{ fontSize: '14px', margin: '16px 0', lineHeight: '140%', textAlign: 'center' }}>
            {t('Please reset the password from the email sent')}
          </p>

          <TextField<RefreshPasswordFormKeys>
            name="email"
            title={t('TextForm.Email')}
            placeholder={t<string>('TextForm.EnterEmail')}
            autoFocus
            required
          />

          <div css={{ width: '100%', display: 'flex', marginTop: '16px' }}>
            <ButtonLink variant="white" to="/" title={t('Back')} css={{ flex: 1 }} />
            <ButtonSubmit
              variant="blue"
              loading={isSubmitting}
              disabled={!values.email}
              title={t('Button.Send')}
              css={{ flex: 1, marginLeft: '16px' }}
            />
          </div>
        </>
      )}
    </FormStyle.Wrapper>
  );
};
const styles = {
  title: css({
    fontSize: '20px',
    lineHeight: '100%',
    textAlign: 'center'
  }),
  info: css({
    fontSize: '14px',
    marginTop: '16px',
    lineHeight: '140%',
    textAlign: 'center',
    color: THEME.text.colors.black.lv1
  }),
  email: css({
    display: 'block',
    fontSize: '14px',
    lineHeight: '160%',
    padding: '4px 8px',
    fontStyle: 'normal',
    width: 'max-content',
    margin: '16px auto 0',
    fontStretch: 'normal',
    boxSizing: 'border-box',
    letterSpacing: 'normal',
    backgroundColor: THEME.background.colors.gray.lv1
  })
};
