import { useParams } from '@tanstack/react-router';
import { ProfilePostsCount } from '@/shared/organisms';
import { ProfilePostsFilter } from '@/shared/organisms';
import { InfluencerYoutubeShortsList } from '@/shared/organisms';
import { useDownloadFile, useParamsSearch, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { getAccountManagementApiUrl, getOffset, getValuableVariables } from '@/shared/utils';
import { ListWithPagination } from '@/shared/molecules';
import { useYoutubeAnalyticsShortsQuery } from '@/graphql';
import { DownloadButton } from '@/shared/atoms';
import { AnalyticsYoutubeShortsSearchSchemaType } from './schemaTypes';

export const AnalyticsYoutubeShorts = () => {
  const { t } = useQueryHelper();
  const params = useParams({
    from: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts'
  });
  const youtubeAnalyticsAccountId = Number(params.socialMediaAccountId);

  const { downloading, handleDownloadFile } = useDownloadFile();
  const { page, limit, sort, filter, setFilter } = useParamsSearch<AnalyticsYoutubeShortsSearchSchemaType>();
  useUpdateDocumentTitle({
    href: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts',
    interpolation: { title: filter.brandAccountName || '' },
    title: filter.brandAccountName ? 'appHeader.ShortsWithAccountName' : 'Shorts List',
    appHeader: filter.brandAccountName ? 'appHeader.ShortsWithAccountName' : 'Shorts List'
  });
  const { data, previousData, loading } = useYoutubeAnalyticsShortsQuery({
    skip: !params?.socialMediaAccountId,
    variables: getValuableVariables({
      limit,
      youtubeAnalyticsAccountId,
      orderBy: sort,
      offset: getOffset(page, limit),
      ...filter
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.youtubeAnalyticsShortsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

      <ProfilePostsCount count={totalRecords} countLabel={t('Shorts found')}>
        <DownloadButton
          loading={downloading}
          onClick={() =>
            handleDownloadFile({
              params: getValuableVariables({
                endDate: filter.endDate,
                startDate: filter.startDate
              }),
              filenamePrefix: 'youtubeShorts',
              url: getAccountManagementApiUrl(`api/analytics/youtube/comments/${youtubeAnalyticsAccountId}`)
            })
          }
        />
      </ProfilePostsCount>

      <InfluencerYoutubeShortsList
        isAnalytics
        loading={loading}
        mainSocialAccountId={Number(params.socialMediaAccountId)}
        data={result?.youtubeAnalyticsShorts.shorts || []}
      />
    </ListWithPagination>
  );
};
