import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AFFILIATE_CAMPAIGN_TYPES } from '@/shared/constants';
import { CampaignTrackingOption, MarketplaceCampaignStatus } from '@/graphql';
import { MarketplaceFormValues } from '../types';
import { CampaignPeriodField, CampaignPeriodFieldProps } from './CampaignPeriodField';

type CampaignPeriodWithLogicsFieldProps = Pick<CampaignPeriodFieldProps, 'required' | 'disabled' | 'className'>;

export const CampaignPeriodWithLogicsField = (props: CampaignPeriodWithLogicsFieldProps) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<MarketplaceFormValues>();

  const { status, campaignType, trackingOptions } = getValues();
  const isOnGoingCampaign = status === MarketplaceCampaignStatus.ONGOING;
  const hasTrackingPost = trackingOptions.includes(CampaignTrackingOption.POST);
  const isAffiliateCampaign = AFFILIATE_CAMPAIGN_TYPES.includes(campaignType);

  return (
    <CampaignPeriodField
      {...props}
      startDateProps={{ disabled: isOnGoingCampaign }}
      endDateProps={{ title: isAffiliateCampaign && !hasTrackingPost ? `(${t('Optional')})` : undefined }}
    />
  );
};
