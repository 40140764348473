import { TFunction } from 'i18next';
import { Maybe, TikTokAdStatus } from '@/graphql';
import { StatusProps } from '@/shared/atoms';
import { ColorVariant } from '@/shared/types';
import { AdCampaignReportColumnKeys } from './types';

export const initialValuesReport: Partial<AdCampaignReportColumnKeys>[] = [
  'adCost',
  'adImpressions',
  'adCpm',
  'adReach',
  'adCostPer1000Reached',
  'adFrequency',
  'adClicks',
  'adCpc',
  'adCtr',
  'adResult',
  'adCostPerResult',
  'adResultRate'
];

// based on https://adasiaholdings.atlassian.net/wiki/spaces/NC/pages/3567157291/Ad+Report#The-metrics-we-wanna-show
export const defaultSelectedColumns: Partial<AdCampaignReportColumnKeys>[] = [
  'status',
  'adCost',
  'adImpressions',
  'adCpm',
  'adReach',
  'adFrequency',
  'adClicks',
  'adCpc',
  'adCtr',
  'videoPlayActions',
  'videoWatched2s',
  'videoWatched6s'
];

export const getAdReportStatusColumnProps = (status: Maybe<TikTokAdStatus> | undefined, t: TFunction): StatusProps => {
  switch (status) {
    case TikTokAdStatus.ENABLE:
      return { label: t('Option.Active'), variant: ColorVariant.LIGHT_GREEN };
    case TikTokAdStatus.DISABLE:
      return { label: t('Option.Inactive'), variant: ColorVariant.LIGHT_GREY };
    default:
      return { label: '', variant: ColorVariant.LIGHT_GREY };
  }
};
