import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { THEME } from '@/shared/constants';

import { Icon } from '../Icon';

interface MenuTabButtonProps {
  onClick: () => void;
  active?: boolean;
  icon: 'menu' | 'eraser';
  title: string;
}
export const MenuTabButton = ({ onClick, active, icon, title }: MenuTabButtonProps) => {
  const { t } = useTranslation();

  return (
    <button onClick={onClick} css={[styles.mode(active)]}>
      {getMenuTabIcon({ icon, active })}
      {t(title)}
    </button>
  );
};

const styles = {
  mode: (active?: boolean) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      border: THEME.border.base,
      height: '32px',
      minWidth: '120px',
      padding: '0 8px',
      fontWeight: 600,
      fontSize: '12px',
      position: 'relative',
      transition: 'opacity 0.3s ease',
      ...(active
        ? {
            backgroundColor: 'rgba(56, 146, 229, 0.1)', // TODO
            zIndex: 1,
            boxSizing: 'border-box',
            color: THEME.text.colors.blue,
            borderColor: THEME.text.colors.blue
          }
        : {
            color: THEME.text.colors.black.lv1,
            backgroundColor: 'transparent',
            '&:hover': {
              opacity: 0.7
            }
          }),
      ':first-of-type': {
        borderRadius: '3px 0 0 3px'
      },
      ':last-of-type': {
        borderRadius: '0 3px 3px 0',
        marginLeft: '-1px'
      }
    }),
  modeImg: css({
    width: '16px',
    height: '16px',
    marginRight: '4px'
  })
};

export const getMenuTabIcon = ({ active, icon }: Pick<MenuTabButtonProps, 'active' | 'icon'>) => {
  switch (icon) {
    case 'menu':
      return (
        <Icon
          icon={active ? 'hamburger-menu' : 'menu-tab-two-tones'} // we cannot change the color on 2 tones icon
          size="14px"
          css={{ marginRight: '8px' }}
          color={active ? THEME.text.colors.blue : THEME.text.colors.gray.lv3}
        />
      );
    case 'eraser':
      return (
        <Icon
          icon="eraser"
          size="12px"
          css={{ marginRight: '8px' }}
          color={active ? THEME.text.colors.blue : THEME.text.colors.gray.lv3}
        />
      );

    default:
      return undefined;
  }
};
