import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { SelectedRangeOption } from '@/shared/types';
import { RangeDatePickerField, RangeDatePickerFieldProps } from '@/shared/molecules';
import { InfluencersFilterFormKeys, InfluencersFilterFormValues } from '../types';

export const PostDateField = (props: Omit<RangeDatePickerFieldProps, 'name' | 'rangeList'>) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<InfluencersFilterFormValues>();
  const [minPostDateValue, maxPostDateValue] = watch(['minPostDate', 'maxPostDate']);

  const rangeList = [
    SelectedRangeOption.TODAY,
    SelectedRangeOption.LAST_SEVEN_DAYS,
    SelectedRangeOption.TODAY_TO_MONTH_AGO,
    SelectedRangeOption.THIS_MONTH,
    SelectedRangeOption.LAST_MONTH,
    SelectedRangeOption.CUSTOM,
    SelectedRangeOption.CLEAR
  ];
  const isAllTime = !minPostDateValue && !maxPostDateValue;

  return (
    <RangeDatePickerField<InfluencersFilterFormKeys>
      clearTitle={t('All time')}
      rangeList={rangeList}
      hasCalendarIcon={false}
      css={styles.datePickerRange}
      title={t('Selector.Post Date')}
      disabledRange={{ after: new Date() }}
      name={{ startDate: 'minPostDate', endDate: 'maxPostDate' }}
      customInputTextNode={isAllTime ? <p>{t('All time')}</p> : undefined}
      {...props}
    />
  );
};

const styles = {
  datePickerRange: css({
    '& > div': { minWidth: '215px' },
    '& div > p': { paddingLeft: '8px' }
  })
};
