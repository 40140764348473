import { useTranslation } from 'react-i18next';
import { Notice } from '@/shared/atoms';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useMarketplaceAffiliateCommissionInfluencersQuery } from '@/graphql';
import {
  MarketplaceCommissionsList,
  MarketplaceCommissionsFilter,
  useMarketplaceCommissionsFilter
} from '@/shared/organisms';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';

export const MarketplaceCommissions = () => {
  const { t } = useTranslation();
  const { marketplaceId } = useMarketplaceDetailsContext();
  const { page, limit, filter } = useMarketplaceCommissionsFilter();
  const { data, previousData, loading } = useMarketplaceAffiliateCommissionInfluencersQuery({
    variables: getValuableVariables({
      ...filter,
      limit,
      marketplaceId: marketplaceId,
      offset: getOffset(page, limit),
      status: filter.status || null,
      fixedDate: filter.month ? filter.month.split('_')[0] : null
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.marketplaceAffiliateCommissionInfluencersCount?.totalNumber;

  return (
    <>
      <Notice type="info" title={t('Commission Information')} description={t('InfluencerCommissionFeeNotice')} />

      <ListWithPagination pagination={{ totalRecords }}>
        <MarketplaceCommissionsFilter marketplaceId={marketplaceId} />
        <MarketplaceCommissionsList
          loading={loading}
          marketplaceId={marketplaceId}
          data={result?.marketplaceAffiliateCommissionInfluencers || []}
        />
      </ListWithPagination>
    </>
  );
};
