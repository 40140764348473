import { SocialAccountType } from '@/graphql';
import { FormProvider, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { FormAction } from '@/shared/types';
import { SelectAccountModalContent, SelectableFanpagesModalContent } from './SelectedModalContent';
import { Template } from './Template';
import { TalentInfluencerFormValues, TalentInfluencerFormSchema } from './schemaTypes';
import { useConnectedTalentAccountsFromState } from './useConnectedTalentAccountsFromState';
import { defaultTalentInfluencerFormValues } from './utils';

export type TalentInfluencerFormProps = FormAction<TalentInfluencerFormValues>;

export const TalentInfluencerForm = ({
  onSubmit,
  defaultValues = defaultTalentInfluencerFormValues
}: TalentInfluencerFormProps) => {
  const {
    t,
    modalTitle,
    onCloseFb,
    onCloseIgOrYt,
    hasIgModalOpened,
    selectDraftIgAccount,
    selectDraftFacebookPage,
    selectDraftYtChannel,
    setModalStep,
    addSelectedDraftFbPageAndIgAccounts,
    allConnectableIgAccounts,
    addSelectedDraftIgAccount,
    addSelectedDraftYtChannel,
    state
  } = useConnectedTalentAccountsFromState(defaultValues as TalentInfluencerFormValues);

  return (
    <>
      {state.connectType === SocialAccountType.FACEBOOK ? (
        <Modal
          open={Boolean(state.isModalOpen && state.connectableFbFanpages?.length)}
          onClose={onCloseFb}
          css={{ width: 'max-content', maxWidth: '600px' }}
        >
          <ModalContent>
            <ModalTitle css={{ display: 'flex', alignItems: 'center' }}>
              {t(state.step === 1 ? 'Choose Facebook pages' : 'Choose Instagram Account')}
            </ModalTitle>
            <SelectableFanpagesModalContent
              allConnectableIgAccounts={allConnectableIgAccounts}
              selectDraftIgAccount={selectDraftIgAccount}
              selectDraftFacebookPage={selectDraftFacebookPage}
              state={state}
            />
          </ModalContent>
          <ModalFooterActions
            cancelButtonProps={{ onClick: onCloseFb, title: t(state.step === 1 ? 'Cancel' : 'Previous') }}
            submitButtonProps={
              state.step === 1 && allConnectableIgAccounts?.length
                ? {
                    title: t('Next'),
                    onClick: () => setModalStep(2)
                  }
                : {
                    title: t('Save'),
                    onClick: () => addSelectedDraftFbPageAndIgAccounts()
                  }
            }
          />
        </Modal>
      ) : (
        <Modal
          open={Boolean(
            state.isModalOpen && (state.connectableIgAccounts?.length || state.connectableYtChannels?.length)
          )}
          onClose={onCloseIgOrYt}
          css={{ width: 'max-content', maxWidth: '600px' }}
        >
          <ModalContent>
            <ModalTitle css={{ display: 'flex', alignItems: 'center' }}>{t(modalTitle)}</ModalTitle>

            <SelectAccountModalContent
              state={state}
              selectDraftIgAccount={selectDraftIgAccount}
              selectDraftYtChannel={selectDraftYtChannel}
            />
          </ModalContent>
          <ModalFooterActions
            cancelButtonProps={{ onClick: onCloseIgOrYt }}
            submitButtonProps={{
              onClick: hasIgModalOpened ? addSelectedDraftIgAccount : addSelectedDraftYtChannel
            }}
          />
        </Modal>
      )}
      <FormProvider<TalentInfluencerFormValues>
        onSubmit={onSubmit}
        values={state.savedFormValues}
        zodSchema={TalentInfluencerFormSchema}
      >
        <Template />
      </FormProvider>
    </>
  );
};

export * from './schemaTypes';
export * from './formSections';
export {
  getTalentInfluencerFormValues,
  defaultTalentInfluencerFormValues,
  getCreateUpdateTalentInfluencerInput
} from './utils';
