import { CSSObject, SerializedStyles } from '@emotion/react';
import { useToggleState } from '@/shared/hooks';
import { DropdownOption } from '@/shared/types';
import { DropdownSingleSelectMenu, DropdownSingleSelectMenuProps } from '../DropdownMenu';
import { Popover, PopoverProps } from '../Popover';
import { Button, ButtonProps } from './Button';

export interface ButtonDropdownSingleSelectProps<T extends DropdownOption>
  extends ButtonProps,
    DropdownSingleSelectMenuProps<T> {
  position?: PopoverProps['position'];
  dropdownClassName?: CSSObject | SerializedStyles;
}

export const ButtonDropdownSingleSelect = <T extends DropdownOption>({
  options,
  selectedOption,
  onSelectOption,
  dropdownClassName,
  position = 'bottom-end',
  ...restProps
}: ButtonDropdownSingleSelectProps<T>) => {
  const { status: isOpening, toggleStatus, close } = useToggleState(false);

  const handleSelectOption = (option: T) => {
    onSelectOption(option);
    close();
  };

  return (
    <Popover
      open={isOpening}
      position={position}
      onClose={close}
      target={<Button onClick={toggleStatus} {...restProps} />}
    >
      <DropdownSingleSelectMenu
        options={options}
        css={dropdownClassName}
        selectedOption={selectedOption}
        onSelectOption={handleSelectOption}
      />
    </Popover>
  );
};
