import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { FilterAction } from '@/shared/types';
import { Template, TemplateProps } from './Template';
import { EngagementPostsFilterFormValues } from './schemaTypes';

export type EngagementPostsFilterProps = FilterAction<EngagementPostsFilterFormValues> &
  Omit<TemplateProps, 'onSubmit'>;

export const EngagementPostsFilter = ({ filter, setFilter, ...templateProps }: EngagementPostsFilterProps) => (
  <FilterWrapper>
    <FilterProvider onSubmit={setFilter} initialValues={filter} basicFilter={<Template {...templateProps} />} />
  </FilterWrapper>
);

export * from './schemaTypes';
