import { FormProvider } from '@/shared/atoms';
import { AgencyFormSchema } from './schema';
import { Template } from './Template';
import type { AgencyFormProps, AgencyFormValues } from './types';

export const AgencyForm = ({ onSubmit, defaultValues }: AgencyFormProps) => (
  <FormProvider<AgencyFormValues>
    onSubmit={onSubmit}
    defaultValues={{
      logo: defaultValues?.logo,
      type: defaultValues?.type || 'add',
      countryId: defaultValues?.countryId,
      hubspotId: defaultValues?.hubspotId,
      agencyName: defaultValues?.agencyName,
      companyUrl: defaultValues?.companyUrl,
      phoneNumber: defaultValues?.phoneNumber,
      advertiserIds: defaultValues?.advertiserIds || [],
      internalNetsuiteId: defaultValues?.internalNetsuiteId,
      hasInvitationEmail: defaultValues?.hasInvitationEmail ?? true,
      ...(defaultValues?.type === 'add' && {
        password: '',
        passwordConfirm: '',
        name: defaultValues?.name,
        email: defaultValues.email
      }),
      ...(defaultValues?.type === 'edit' && { id: defaultValues?.id })
    }}
    zodSchema={AgencyFormSchema}
  >
    <Template />
  </FormProvider>
);

export * from './types';
