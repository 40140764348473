import { css } from '@emotion/react';
import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountStatus } from '@/graphql';
import { Icon, Label, Button, SocialIcon, SocialButton, SocialLinkedAccounts } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { SupportedSocialMediaType } from '@/shared/types';
import { ConnectAccountType, ReconnectAccountType } from './types';

interface ConnectedAccountsProps {
  limit?: number;
  loading?: boolean;
  className?: string;
  warning?: ReactNode;
  accounts: ConnectAccountType[];
  socialMedia: keyof typeof SupportedSocialMediaType;
  onSignIn?: (account?: ConnectAccountType) => void;
  onReconnect?: (data: ReconnectAccountType) => void;
}

export const ConnectedAccounts = ({
  loading,
  accounts,
  onSignIn,
  className,
  limit = 10,
  socialMedia,
  onReconnect
}: ConnectedAccountsProps) => {
  const { t } = useTranslation();

  const length = accounts.length;
  const { label, addMoreTitle, signInTitle } = (() => {
    switch (socialMedia) {
      case 'INSTAGRAM':
        return {
          label: t('Instagram Username'),
          addMoreTitle: t('Button.add account'),
          signInTitle: t('Sign in with Instagram')
        };
      case 'YOUTUBE':
        return {
          label: t('YouTube Username'),
          addMoreTitle: t('Button.add channel'),
          signInTitle: t('Sign in with YouTube')
        };
      case 'FACEBOOK':
        return {
          addMoreTitle: t('Button.add page'),
          signInTitle: t('Sign in with Facebook'),
          label: t('Facebook Individual Username')
        };
      case 'TWITTER':
        return {
          label: t('X(Twitter) Username'),
          addMoreTitle: t('Button.add account'),
          signInTitle: t('Sign in with Twitter') // translation itself updated with X
        };
      default:
        return { label: '', signInTitle: '', addMoreTitle: '' };
    }
  })();

  return length ? (
    <div className={className}>
      <Label
        title={
          <>
            <SocialIcon type={socialMedia} css={{ marginRight: '4px' }} />
            {label}
          </>
        }
      />

      {accounts.map((account) => {
        const { name, warning, status, reconnectData, canReconnect, linkedAccounts } = account;
        const isScrapping = status === SocialAccountStatus.SCRAPING;

        return (
          <Fragment key={name}>
            {isScrapping ? (
              <div css={styles.scrapingWrapper}>
                <div css={styles.name}>{name}</div>
                <SocialButton
                  loading={loading}
                  title={signInTitle}
                  socialMedia={socialMedia}
                  onClick={() => onSignIn?.(account)}
                  css={{ gap: '16px', width: '197px', padding: '0 16px' }}
                  hasIcon
                />
              </div>
            ) : (
              <div css={styles.signedUpWrapper}>
                <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <SocialLinkedAccounts accounts={[{ name, type: socialMedia }, ...(linkedAccounts || [])]} />

                  {reconnectData?.needReconnect ? (
                    canReconnect && !!onReconnect ? (
                      <SocialButton
                        loading={loading}
                        title={t('Reconnect')}
                        socialMedia={socialMedia}
                        onClick={() => onReconnect?.(reconnectData)}
                        css={{ padding: '0 16px', textTransform: 'uppercase' }}
                      />
                    ) : (
                      <Icon size={21} icon="warning" color="#FFB63D" />
                    )
                  ) : !warning ? (
                    <Icon size={21} icon="success" color="#3fb87c" />
                  ) : null}
                </div>

                {warning ? <div css={styles.warning}>{warning}</div> : null}
              </div>
            )}
          </Fragment>
        );
      })}

      {length < limit && (
        <Button
          variant="white"
          loading={loading}
          title={addMoreTitle}
          onClick={() => onSignIn?.()}
          css={{ height: '30px', marginTop: '8px' }}
          prefixIcon={{ icon: 'plus', size: '10px' }}
        />
      )}
    </div>
  ) : (
    <SocialButton
      loading={loading}
      title={signInTitle}
      socialMedia={socialMedia}
      onClick={() => onSignIn?.()}
      css={{ gap: '16px', width: '197px', padding: '0 16px', marginTop: '16px' }}
      hasIcon
    />
  );
};
const styles = {
  warning: css({ color: '#ff2b52', marginTop: '8px', fontSize: '13px', lineHeight: 1.38 }),
  name: css({
    flex: 1,
    height: '32px',
    minWidth: '200px',
    overflow: 'hidden',
    paddingLeft: '8px',
    lineHeight: '30px',
    borderRadius: '3px',
    background: '#f6f8fa',
    boxSizing: 'border-box',
    border: THEME.border.base
  }),
  scrapingWrapper: css({
    gap: '16px',
    width: '100%',
    display: 'flex',
    marginTop: '16px',
    boxSizing: 'border-box'
  }),
  signedUpWrapper: css({
    marginTop: '8px',
    padding: '8px 16px',
    borderRadius: '3px',
    boxSizing: 'border-box',
    border: 'solid 1px #dee5ec'
  })
};
