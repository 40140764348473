import { getValuableVariables } from '@/shared/utils';
import { useQueryHelper } from '@/shared/hooks';
import { useEngagementCampaignTrafficSourceQuery } from '@/graphql';
import { PostReportFilterFormValues } from './schemaTypes';
import { Template, TemplateProps } from './Template';

export interface EngagementCampaignTrafficSourceModalProps extends Pick<TemplateProps, 'open' | 'onClose'> {
  campaignId: number;
  filter: PostReportFilterFormValues;
}

export const EngagementCampaignTrafficSourceModal = ({
  open,
  filter,
  onClose,
  campaignId
}: EngagementCampaignTrafficSourceModalProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useEngagementCampaignTrafficSourceQuery({
    variables: getValuableVariables({ pk: campaignId, ...filter }),
    onCompleted: ({ engagementCampaignTrafficSource }) => {
      if (!engagementCampaignTrafficSource?.length) {
        onClose?.();
        enqueueSnackbar(t('Traffic source information was not found for this campaign'), { variant: 'info' });
      }
    },
    onError: (error) => {
      onClose?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  return (
    <Template
      open={open}
      onClose={onClose}
      loading={loading}
      engagementCampaignTrafficSource={data?.engagementCampaignTrafficSource}
    />
  );
};
