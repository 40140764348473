import React, { useRef } from 'react';
import { InputSearch, InputProps } from '@/shared/atoms';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export type TextSearchFieldProps<T extends string = string> = DefaultFieldProps<T, string> &
  Omit<InputProps, 'name' | 'value' | 'title' | 'onChange'>;

export const TextSearchField = <T extends string = string>(props: TextSearchFieldProps<T>) => {
  const inputRef = useRef<HTMLInputElement | null>(null); // TODO: can we use fieldRegister.ref?
  const {
    fieldRegister,
    fieldWrapperProps,
    fieldProps: { name, onSubmit, onChange, onBlur, onKeyPress, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  const handlePressEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit?.();
      inputRef.current?.blur();
    }
  };

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <InputSearch
        {...fieldRegister}
        {...restFieldProps}
        onBlur={onBlur || onSubmit}
        onKeyPress={onKeyPress || handlePressEnter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange?.(name, e.target.value) || fieldRegister.onChange(e)
        }
        ref={(e) => {
          fieldRegister.ref(e);
          inputRef.current = e;
        }}
      />
    </RHFFieldWrapper>
  );
};
