import {
  GetAutoManagedCampaignsInput,
  AutoManagedCampaignInfoPayload,
  GetAutoManagedCampaignsCountInput
} from '@/graphql';
import { MAX_COMMISSION_RATE, MAX_PRICE, MIN_PRICE } from '@/shared/constants';
import { getOffset, rateToApiFormat } from '@/shared/utils';
import { MarketplaceAutoManagedCampaignFilterFormValues } from '../MarketplaceAutoManagedCampaignFilter/schemaTypes';
import { AutoManagedCampaign } from './types';

export const formatMarketplaceAutoMangedCampaigns = (campaigns: readonly AutoManagedCampaignInfoPayload[] = []) =>
  campaigns.map<AutoManagedCampaign>(
    ({
      id,
      title,
      price,
      country,
      category,
      sellerName,
      thumbnailUrl,
      landingPageUrl,
      commissionRate,
      autoManagedCampaignType
    }) => ({
      id,
      title,
      price,
      country,
      sellerName,
      commissionRate,
      landingPageUrl,
      autoManagedCampaignType,
      category: category || '',
      thumbnailUrl: thumbnailUrl || ''
    })
  );

export const getMarketplaceAutoManagedCampaignVariables = ({
  filter: {
    keyword,
    minPrice,
    maxPrice,
    countryId,
    categoryIds,
    marketplaceType,
    minCommissionRate,
    maxCommissionRate
  },
  page,
  limit
}: {
  filter: MarketplaceAutoManagedCampaignFilterFormValues;
  page: number;
  limit: number;
}) => {
  const countInput: GetAutoManagedCampaignsCountInput = {
    categoryIds,
    // Optional fields
    ...(keyword ? { keyword } : {}),
    ...(countryId ? { countryId } : {}),
    ...(marketplaceType ? { marketplaceType } : {}),
    ...(minPrice && Number(minPrice) !== MIN_PRICE ? { minPrice: Number(minPrice) } : {}),
    ...(maxPrice && Number(maxPrice) !== MAX_PRICE ? { maxPrice: Number(maxPrice) } : {}),
    minCommissionRate: rateToApiFormat(minCommissionRate), // BE logic require both min|max values to be provided
    ...(maxCommissionRate && Number(maxCommissionRate) !== MAX_COMMISSION_RATE
      ? { maxCommissionRate: rateToApiFormat(maxCommissionRate) }
      : {})
  };
  const input: GetAutoManagedCampaignsInput = {
    ...countInput,
    limit,
    offset: getOffset(page, limit),
    orderBy: { field: null, order: null } // we don't need to provide an order function here
  };

  return { input, countInput };
};
