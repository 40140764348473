import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ResetPasswordAndLogin.graphql';
import { Mutation, MutationresetPasswordAndLoginArgs } from '../../__generated__/globalTypes';

export const useResetPasswordAndLoginMutation = (
  options?: MutationHookOptions<Mutation, MutationresetPasswordAndLoginArgs>
) => {
  const [callResetPasswordAndLogin, result] = useMutation<Mutation, MutationresetPasswordAndLoginArgs>(
    MUTATION,
    options
  );

  return { callResetPasswordAndLogin, ...result };
};

export type ResetPasswordAndLoginMutationFunction = Mutation['resetPasswordAndLogin'];
