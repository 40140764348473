import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagement.graphql';
import { Mutation, MutationupdateEngagementArgs } from '../../__generated__/globalTypes';

export const useUpdateEngagementMutation = (options?: MutationHookOptions<Mutation, MutationupdateEngagementArgs>) => {
  const [callUpdateEngagement, result] = useMutation<Mutation, MutationupdateEngagementArgs>(MUTATION, options);

  return { callUpdateEngagement, ...result };
};

export type UpdateEngagementMutationFunction = Mutation['updateEngagement'];
