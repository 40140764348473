import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template } from './Template';
import { useAnalyticsFilter } from './hooks';

export const AnalyticsFilter = () => {
  const { filter, setFilterForm } = useAnalyticsFilter();

  return (
    <FilterWrapper>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<Template />} />
    </FilterWrapper>
  );
};

export { useAnalyticsFilter };
export * from './schemaTypes';
