import { EngagementDetail, ProposalInfluencer, useInfluencerProfileForEngagementProposalQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { InfluencerProfileProvider, InfluencerProfileTemplates } from '@/shared/organisms';

interface ProposalInfluencerAccountProfileProps {
  engagementId: EngagementDetail['id'];
  influencerId: ProposalInfluencer['id'];
}

export const ProposalInfluencerAccountProfile = ({
  influencerId,
  engagementId
}: ProposalInfluencerAccountProfileProps) => {
  const { data, loading } = useInfluencerProfileForEngagementProposalQuery({
    variables: { influencerId, engagementId }
  });

  // TODO: interface InfluencerProfileForEngagementProposal and InfluencerNewProfileHeaderV2 are the same
  // We might need to ask BE combine into 1 type
  const influencerProfile = data?.influencerProfileForEngagementProposal;

  return (
    <RenderDataWithFallback loading={loading} hasNoData={!influencerProfile}>
      {influencerProfile ? (
        <InfluencerProfileProvider value={{ influencerProfile }} isEngagementProposal>
          <InfluencerProfileTemplates isEngagementProposal />
        </InfluencerProfileProvider>
      ) : null}
    </RenderDataWithFallback>
  );
};
