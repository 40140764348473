import { TTCMStatus, SocialAccountStatus, EngagementProposalSocialMediaType } from '@/graphql';

export enum EditableFieldKeys {
  budget = 'budget',
  profit = 'profit',
  netBudget = 'netBudget',
  influencerCost = 'influencerCost'
}

export interface RowInfluencerData {
  id: number;
  name: string;
  avatar?: string | null;
  ttcmCampaignCode?: string;
  status: SocialAccountStatus;
  ttcmStatus?: TTCMStatus | null;
  hasOnlyOneTiktokAccount?: boolean;
}

export interface RowSocialAccountData {
  id: number;
  lastIndex?: boolean;
  name?: string | null;
  socialMedia: EngagementProposalSocialMediaType;
}

export interface RowStatsData {
  budget: number;
  profit: number;
  netBudget: number;
  influencerCost: number;
  addedDate?: string | null;
  couponCode?: string | null;
  followersCount?: number | null;
  engagementRateByViews?: number | null;
  engagementRateByFollowers?: number | null;
  engagementPosted?: number | null;
  engagementProposed?: number | null;
  ttcmStatus?: TTCMStatus | null;
  ttcmCampaignCode?: string | null;
  ttcmCampaignInviteLink?: string | null;
}

export interface RowData {
  index: number;
  stats: RowStatsData;
  influencer: RowInfluencerData;
  socialAccount?: RowSocialAccountData;
}
