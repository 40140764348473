import { Outlet, useLoaderData } from '@tanstack/react-router';
import { useEffect } from 'react';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useCustomHeader, useQueryHelper } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { AutoManagedCampaignTabs } from '@/shared/organisms';
import { AutoManagedRouter } from '../../../app/_private-routes/auto-managed';
import { AutoManagedCampaignDetailsProvider } from './AutoManagedCampaignDetailsContext';

export const AutoManagedCampaignDetails = () => {
  useCustomHeader();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { loading, networkStatus, autoManagedCampaign, errorMessage } = useLoaderData({
    from: '/_private-routes/auto-managed/$id'
  }) as Awaited<ReturnType<(typeof AutoManagedRouter)['/_private-routes/auto-managed/$id']['1']['loader']>>;

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(t(errorMessage), { variant: 'error' });
      navigate({ to: '/auto-managed' });
    }
  }, [errorMessage]);

  return (
    <RenderDataWithFallback loading={loading && networkStatus === 1}>
      {autoManagedCampaign ? (
        <AutoManagedCampaignDetailsProvider value={{ autoManagedCampaign }}>
          <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
            <Header />

            <AutoManagedCampaignTabs id={autoManagedCampaign.id} />
            <Outlet />
          </div>
        </AutoManagedCampaignDetailsProvider>
      ) : null}
    </RenderDataWithFallback>
  );
};
