import { LinkProps } from '@tanstack/react-router';
import { MenuTitle } from '@/router';

export enum MenuGroup {
  Campaigns = 'Campaigns',
  Influencers = 'Influencers',
  Trends = 'Trends',
  Accounts = 'Accounts',
  InfluencerCampaign = 'Influencer Campaign'
}

interface SubMenuType extends Pick<LinkProps, 'href' | 'params'> {
  to?: (typeof MENU_LINKS)[number];
  title?: MenuTitle;
  groupTitle?: MenuGroup;
  activeTitle?: MenuTitle;
}

const MENU_LINKS = [
  '/report',
  '/marketplace',
  '/engagement',
  '/auto-managed',
  '/influencers',
  '/tiktok/campaigns',
  '/analytics',
  '/trending/influencers',
  '/tiktok/ad-campaign-report',
  '/tiktok/ad-accounts',
  '/tiktok/budget-allocation',
  '/tiktok/advertiser-details',
  '/tiktok/subscription-billing',
  '/youtube-cms',
  '/campaign-posts-discovery',
  '/package',
  '/posts',
  '/trending/posts',
  '/advertiser',
  '/partner',
  '/invoices',
  '/asset-preview',
  '/talent-agency',
  '/agency',
  '/user',
  '/payments',
  '/settings',
  '/chat-demo'
] as const satisfies Array<LinkProps['to']>;
export interface MenuType extends SubMenuType {
  to?: (typeof MENU_LINKS)[number];
  params?: LinkProps['params'];
  title?: MenuTitle;
  groupTitle?: MenuGroup;
  activeTitle?: MenuTitle;
  subMenus?: Partial<SubMenuType[]>;
}
