import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle, Label } from '@/shared/atoms';
import { MultiSelector } from '@/shared/molecules';
import { CampaignPostsDiscoveryFilterFormValues } from '../schemaTypes';
import { useAdvertisersLoadOptions } from './useAdvertisersLoadOptions';

export interface AdvertiserSearchSelectorMultipleProps {
  onSubmit?: () => void;
  className?: string;
  title?: string;
}
export const AdvertiserSearchSelectorMultiple = ({
  onSubmit,
  className,
  title
}: AdvertiserSearchSelectorMultipleProps) => {
  const { setValue, watch } = useFormContext<CampaignPostsDiscoveryFilterFormValues>();
  const advertiserIds = watch('advertiserIds');
  const { t } = useTranslation();

  const { isLoading, setFetchState, debouncedLoadAdvertisersOptions, allAdvertisersIncludedOptions } =
    useAdvertisersLoadOptions(advertiserIds);

  return (
    <FormStyle.FieldWrapper className={className}>
      {!!title && <Label title={title} />}
      <MultiSelector
        name="advertiserIds"
        hasSelectAll={false}
        skipFiltering
        onClose={() => {
          onSubmit?.();
          setFetchState('idle');
        }}
        value={advertiserIds}
        loading={isLoading}
        noOptionPlaceholder={t('Type to search and display options')}
        options={allAdvertisersIncludedOptions}
        onSearchChange={(v) =>
          debouncedLoadAdvertisersOptions({
            advertisersIds: advertiserIds,
            inputValue: v
          })
        }
        onChange={(selectedIds) => {
          setValue('advertiserIds', selectedIds);
        }}
        onClear={() => {
          setValue('advertiserIds', []);
        }}
      />
    </FormStyle.FieldWrapper>
  );
};
