import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { TableData } from '@/shared/types';
import { formatIntNumber } from '@/shared/utils';
import { InfluencerAvatar } from '@/shared/molecules';
import { useGenerateInfluencerProfileLink, usePostDetailsParam } from '@/shared/hooks';
import { Anchor, ColumnProps, MediaPreview, RowProps, TableCell } from '@/shared/atoms';
import { InstagramPostSortOrder, InstagramHashtagFeedPostV2, SocialAccountType } from '@/graphql';

type CustomColumnProps = ColumnProps<InstagramPostSortOrder>;
type Props = Pick<TableData<InstagramHashtagFeedPostV2>, 'data'>;

export const useAnalyticsInstagramHashtagPostsListTable = ({ data = [] }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    getMetricColumnProps({
      title: t('HeaderColumn.Rank'),
      styles: { textAlign: 'center', paddingRight: '16px' },
      sortField: InstagramPostSortOrder.RANK
    }),
    { title: t('HeaderColumn.Influencer') },
    { title: t('HeaderColumn.Post') },
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement'),
      sortField: InstagramPostSortOrder.ENGAGEMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      sortField: InstagramPostSortOrder.LIKES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      sortField: InstagramPostSortOrder.COMMENTS
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      sortField: InstagramPostSortOrder.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      name,
      rank,
      likes,
      images,
      avatar,
      content,
      comments,
      postDate,
      accountId,
      engagement,
      influencerId,
      followersCount
    }) => {
      const profileLink = generateProfileLink(influencerId, {
        filter: {
          id: String(accountId),
          sm: SocialAccountType.INSTAGRAM
        }
      });

      return [
        <div>{formatIntNumber(rank + 1)}</div>,
        <div css={{ width: '200px', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <InfluencerAvatar {...profileLink} size="40px" src={avatar} css={{ borderRadius: 0 }} />
          <div>
            <Anchor {...profileLink} label={name || '-'} css={{ fontSize: '14px' }} />
            <div css={{ fontSize: '10px', marginTop: '4px' }}>
              {t('Follower')}:&nbsp;{formatIntNumber(followersCount)}
            </div>
          </div>
        </div>,
        <div onClick={() => setPostDetailsParam(id)} css={styles.postWrapper}>
          <MediaPreview
            src={images.at(0)}
            objectFit="contain"
            css={styles.postPreview}
            counter={{ index: 1, total: images.length }}
          />
          <Anchor lines={3} label={content || '-'} />
        </div>,
        <TableCell.Number value={formatIntNumber(engagement)} />,
        <TableCell.Number value={formatIntNumber(likes)} />,
        <TableCell.Number value={formatIntNumber(comments)} />,
        <TableCell.Date value={postDate} />
      ];
    }
  );

  return { rows, columns };
};
const styles = {
  postWrapper: css({
    gap: '16px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center'
  }),
  postPreview: css({
    width: '56px',
    height: '56px',
    borderRadius: '5px',
    backgroundColor: THEME.background.colors.gray.lv1
  })
};
