import { useGetAdvertiserSettingsInfoQuery, useUpdateAdvertiserUserSettingsMutation } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useCustomHeader, useUpdateDocumentTitle, useQueryHelper } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { SettingsForm, SettingsFormValues, getSettingsFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const Settings = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'documentTitle.Settings', appHeader: 'appHeader.Settings', href: '' });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callUpdateAdvertiserUserSettings } = useUpdateAdvertiserUserSettingsMutation();
  const { data, loading } = useGetAdvertiserSettingsInfoQuery({
    onError: (error) => {
      enqueueSnackbar(t(error?.message || 'cannot get account settings information'), { variant: 'error' });
      navigate({ to: '/' });
    }
  });

  const handleSubmit: FormAction<SettingsFormValues>['onSubmit'] = async ({
    name,
    email,
    countryId,
    advertiserName,
    advertiserCountryId,
    notificationSetting,
    advertiserCompanyUrl,
    advertiserPhoneNumber
  }) => {
    try {
      await callUpdateAdvertiserUserSettings({
        variables: {
          input: {
            name,
            email,
            countryId,
            advertiserName,
            advertiserCountryId,
            notificationSetting,
            advertiserCompanyUrl,
            advertiserPhoneNumber
          }
        }
      });

      enqueueSnackbar(t('Account Settings were successfully saved'), { variant: 'success' });
      navigate({ to: '/' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <RenderDataWithFallback loading={loading}>
      <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
        <Header />

        <SettingsForm
          onSubmit={handleSubmit}
          defaultValues={getSettingsFormValues(data?.advertiserUserSettingsDetails)}
        />
      </div>
    </RenderDataWithFallback>
  );
};
