import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { BasicFilter } from './BasicFilter';
import { useEngagementCampaignsFilter } from './hooks';

export const EngagementCampaignsFilter = () => {
  const { filter, setFilterForm } = useEngagementCampaignsFilter();

  return (
    <FilterWrapper>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<BasicFilter />} />
    </FilterWrapper>
  );
};
export { useEngagementCampaignsFilter };
export * from './schemaTypes';
