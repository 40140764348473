import { zodValidator } from '@tanstack/zod-adapter';
import { Suspense } from 'react';
import { SendReferralTrackingData } from '@/pages/Public';
import { InitialLoading } from '@/shared/molecules';
import { ReferralTrackingSearchSchema } from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const LazyReferralRouter = {
  '/_public-routes/egg/referral': [
    () => (
      <Suspense fallback={<InitialLoading />}>
        <SendReferralTrackingData trackingPage="engagement" />
      </Suspense>
    ),
    { validateSearch: zodValidator(ReferralTrackingSearchSchema) }
  ],
  '/_public-routes/mkp/referral': [
    () => (
      <Suspense fallback={<InitialLoading />}>
        <SendReferralTrackingData trackingPage="marketplace" />
      </Suspense>
    ),
    { validateSearch: zodValidator(ReferralTrackingSearchSchema) }
  ]
} as const satisfies PartialRouter;
