import { InitialLoading } from '@/shared/molecules';
import { WithChannelTalk } from '@/app-components';
import { useUserRoles } from '@/auth';
import { TikTokPermissionRoute } from './permission-route';

export const TiktokRoot = () => {
  const { status } = useUserRoles();

  if (status === 'idle' || status === 'loading') {
    return <InitialLoading />;
  }

  return (
    <WithChannelTalk>
      <TikTokPermissionRoute />
    </WithChannelTalk>
  );
};
