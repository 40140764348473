import { QueryHookOptions } from '@apollo/client';
import { useUserRoles } from '@/auth';
import { Query, useAllTalentAgenciesOrPartnersQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';

export const useAllTalentAgencyOrPartnerOptions = (options?: QueryHookOptions<Query>) => {
  const { isAdminStaffPartner } = useUserRoles();
  const { data, loading } = useAllTalentAgenciesOrPartnersQuery({
    fetchPolicy: 'cache-first',
    skip: !isAdminStaffPartner,
    ...options
  });
  const allTalentAgenciesPartners = data?.allTalentAgenciesPartners || [];

  return {
    loading,
    allTalentAgenciesPartners,
    allTalentAgencyPartnerOptions: getOptions(allTalentAgenciesPartners)
  };
};
