import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations } from '@/shared/validations';
import { INITIAL_PAGE_NUMBER, LIMIT } from '@/shared/constants';

export const UsersFilterSchema = z.object({
  keyword: FilterValidations.string(),
  countryId: FilterValidations.string(),
  advertiserIds: FilterValidations.arrayString()
});
export const UsersSearchSchema = z.object({
  filter: UsersFilterSchema.default(UsersFilterSchema.parse({})),
  page: z.number().default(INITIAL_PAGE_NUMBER),
  limit: z.number().default(LIMIT)
});

export type UsersSearchSchemaType = z.infer<typeof UsersSearchSchema>;
export type UsersFilterFormValues = z.infer<typeof UsersFilterSchema>;
export type UsersFilterFormKeys = UnionOfKeys<UsersFilterFormValues>;
