import { useParams } from '@tanstack/react-router';
import { InstagramComparePosts, useInstagramComparePostsQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { HAS_NO_SELECTED_ACCOUNTS } from '@/shared/constants';
import { AnalyticsPostsOverview } from '../../../shared';
import { AnalyticsCompareInstagramAccountsProps } from '../AnalyticsCompareInstagramAccounts';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { Template } from './Template';

export interface AnalyticsCompareInstagramPostsProps
  extends Pick<AnalyticsCompareInstagramAccountsProps, 'accounts' | 'mainAccountId' | 'hiddenAccountIds'> {
  brandAccountName?: string;
  hasFreePlan?: boolean;
  postsMockData?: InstagramComparePosts;
}

export const AnalyticsCompareInstagramPosts = ({
  accounts,
  mainAccountId,
  brandAccountName,
  hiddenAccountIds,
  hasFreePlan,
  postsMockData
}: AnalyticsCompareInstagramPostsProps) => {
  const { t } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const params = useParams({
    from: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/'
  });

  const comparableAccountIds = accounts.reduce<number[]>(
    (acc, curr) => (curr.id !== mainAccountId ? [...acc, curr.id] : acc),
    []
  );

  const { data, loading } = useInstagramComparePostsQuery({
    skip: !comparableAccountIds.length || hasFreePlan,
    variables: getValuableVariables({
      comparableAccountIds,
      endDate: filter.endDate,
      startDate: filter.startDate,
      accountId: mainAccountId
    })
  });

  const postsData = hasFreePlan ? postsMockData : data?.instagramComparePosts;
  const comparableAccounts = accounts.filter((account) => !hiddenAccountIds?.includes(account.id));

  return (
    <AnalyticsPostsOverview
      data={postsData}
      loading={loading}
      help={t('Tooltip.comparePosts')}
      dataNotAvailable={!comparableAccounts.length || !postsData}
      wrapperText={!comparableAccounts.length ? HAS_NO_SELECTED_ACCOUNTS : undefined}
      moreDetailProps={{
        to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post',
        params,
        search: {
          filter: {
            accountId: comparableAccounts.at(0)?.id || 0,
            brandAccountName,
            endDate: filter.endDate,
            startDate: filter.startDate
          }
        }
      }}
    >
      {({ data }) => <Template data={data} comparableAccounts={comparableAccounts} />}
    </AnalyticsPostsOverview>
  );
};
