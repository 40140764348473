import { QueryHookOptions } from '@apollo/client';

import { Query, useAllAdvertisersForAnalyticsSearchQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';

export const useAllAdvertisersForAnalyticsSearchOptions = (options?: QueryHookOptions<Query>) => {
  const { data, loading } = useAllAdvertisersForAnalyticsSearchQuery({ fetchPolicy: 'cache-and-network', ...options });

  const allAdvertisersOptions = data?.allAdvertisersForSearch
    ? getOptions(data.allAdvertisersForSearch.advertisers)
    : [];

  return { loading, allAdvertisersOptions };
};
