import { SocialPostType } from '@/graphql';
import { getToday, getThirtyDaysAgo } from '@/shared/utils';
import { PostsFilterFormValues } from './schemaTypes';

export const defaultPostsFilterValues: PostsFilterFormValues = {
  sort: '', // sort for selector = `${field}-${order}
  field: null,
  order: null,
  keyword: '',
  genders: [],
  minViews: '',
  maxViews: '',
  countryId: '',
  categoryIds: [],
  minFollowers: '',
  maxFollowers: '',
  minEngagementRate: '',
  maxEngagementRate: '',
  maxPostDate: getToday(),
  minPostDate: getThirtyDaysAgo(),
  socialMedia: SocialPostType.INSTAGRAM
};
