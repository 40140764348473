import { useTranslation } from 'react-i18next';
import { InstagramStoryPost, InstagramStoryPostSortField } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { ColumnCustomiser } from '@/shared/molecules';
import { SelectColumnsAction } from '@/shared/types';
import { formatIntNumber } from '@/shared/utils';
import {
  defaultSelectedAnalyticsInstagramStoryPostsColumns,
  selectableAnalyticsInstagramStoryPostsColumnOptions
} from '../utils';

type CustomColumnProps = ColumnProps<InstagramStoryPostSortField, keyof InstagramStoryPost>;

interface Props extends SelectColumnsAction<keyof InstagramStoryPost> {
  data: readonly InstagramStoryPost[];
}

export const useAnalyticsInstagramStoryPostsListTable = ({ data, selectedColumns, setSelectedColumns }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ key, hidden, styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    key,
    styles: { textAlign: 'right', ...styles },
    hidden: hidden || (key && !selectedColumns.includes(key))
  });

  const columns: CustomColumnProps[] = [
    {
      title: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          {t('HeaderColumn.Story Post')}
          <ColumnCustomiser<keyof InstagramStoryPost>
            css={{ marginLeft: 'auto' }}
            savedValuesKey="igStoryPost"
            setSelectedColumns={setSelectedColumns}
            options={selectableAnalyticsInstagramStoryPostsColumnOptions}
            initialValues={defaultSelectedAnalyticsInstagramStoryPostsColumns}
            defaultOptions={defaultSelectedAnalyticsInstagramStoryPostsColumns}
          />
        </div>
      ),
      sticky: { active: true, hasBorderRight: true }
    },
    getMetricColumnProps({
      key: 'follow',
      title: t('HeaderColumn.Follow'),
      help: t<string>('Tooltip.FollowHelp'),
      sortField: InstagramStoryPostSortField.FOLLOW
    }),
    getMetricColumnProps({
      key: 'impressions',
      help: t<string>('Tooltip.ImpsHelp'),
      title: t('HeaderColumn.Impression'),
      sortField: InstagramStoryPostSortField.IMPRESSIONS
    }),
    getMetricColumnProps({
      key: 'reach',
      title: t('HeaderColumn.Reach'),
      help: t<string>('Tooltip.ReachPostHelp'),
      sortField: InstagramStoryPostSortField.REACH
    }),
    getMetricColumnProps({
      key: 'comments',
      title: t('HeaderColumn.Reply'),
      help: t<string>('Tooltip.CommentHelp'),
      sortField: InstagramStoryPostSortField.COMMENT
    }),
    getMetricColumnProps({
      key: 'tapsForward',
      title: t('HeaderColumn.Tap Forward'),
      help: t<string>('Tooltip.TapForward'),
      sortField: InstagramStoryPostSortField.TAPS_FORWARD
    }),
    getMetricColumnProps({
      key: 'swipeForward',
      title: t('HeaderColumn.Swipe Forward'),
      help: t<string>('Tooltip.SwipeForward'),
      sortField: InstagramStoryPostSortField.SWIPE_FORWARD
    }),
    getMetricColumnProps({
      key: 'tapsBack',
      title: t('HeaderColumn.Tap Back'),
      help: t<string>('Tooltip.TapBack'),
      sortField: InstagramStoryPostSortField.TAPS_BACK
    }),
    getMetricColumnProps({
      key: 'exits',
      title: t('HeaderColumn.Exits'),
      help: t<string>('Tooltip.ExitsHelp'),
      sortField: InstagramStoryPostSortField.EXITS
    }),
    getMetricColumnProps({
      key: 'interaction',
      title: t('HeaderColumn.Interaction'),
      help: t<string>('Tooltip.interactionHelp'),
      sortField: InstagramStoryPostSortField.INTERACTION
    }),
    getMetricColumnProps({
      key: 'linkClicks',
      title: t('HeaderColumn.Link Clicks'),
      help: t<string>('Tooltip.linkClicksHelp'),
      sortField: InstagramStoryPostSortField.LINK_CLICKS
    }),
    getMetricColumnProps({
      key: 'stickerTaps',
      title: t('HeaderColumn.stickerTaps'),
      help: t<string>('Tooltip.stickerTapsHelp'),
      sortField: InstagramStoryPostSortField.STICKER_TAPS
    }),
    getMetricColumnProps({
      key: 'productPageView',
      title: t('HeaderColumn.Product Page View'),
      help: t<string>('Tooltip.productPageViewHelp'),
      sortField: InstagramStoryPostSortField.PRODUCT_PAGE_VIEW
    }),
    getMetricColumnProps({
      key: 'productButtonClick',
      title: t('HeaderColumn.Product Button Click'),
      help: t<string>('Tooltip.productButtonClickHelp'),
      sortField: InstagramStoryPostSortField.PRODUCT_BUTTON_CLICK
    }),
    getMetricColumnProps({
      key: 'postDate',
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      help: t<string>('Tooltip.PostDateHelp'),
      sortField: InstagramStoryPostSortField.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      reach,
      exits,
      follow,
      content,
      tapsBack,
      comments,
      postDate,
      thumbNail,
      linkClicks,
      interaction,
      impressions,
      tapsForward,
      stickerTaps,
      swipeForward,
      productPageView,
      productButtonClick
    }) => [
      <TableCell.Post
        title={content || ''}
        thumbnail={thumbNail || ''}
        css={{ maxWidth: '275px' }}
        onClick={() => setPostDetailsParam(id)}
      />,
      <TableCell.Number value={formatIntNumber(follow || 0)} />,
      <TableCell.Number value={formatIntNumber(impressions)} />,
      <TableCell.Number value={formatIntNumber(reach)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(tapsForward)} />,
      <TableCell.Number value={formatIntNumber(swipeForward)} />,
      <TableCell.Number value={formatIntNumber(tapsBack)} />,
      <TableCell.Number value={formatIntNumber(exits)} />,
      <TableCell.Number value={formatIntNumber(interaction || 0)} />,
      <TableCell.Number value={formatIntNumber(linkClicks || 0)} />,
      <TableCell.Number value={formatIntNumber(stickerTaps || 0)} />,
      <TableCell.Number value={formatIntNumber(productPageView || 0)} />,
      <TableCell.Number value={formatIntNumber(productButtonClick || 0)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
