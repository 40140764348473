import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ApproveMarketplaceDraftPost.graphql';
import { Mutation, MutationapproveMarketplaceDraftPostArgs } from '../../__generated__/globalTypes';

export const useApproveMarketplaceDraftPostMutation = (
  options?: MutationHookOptions<Mutation, MutationapproveMarketplaceDraftPostArgs>
) => {
  const [callApproveMarketplaceDraftPost, result] = useMutation<Mutation, MutationapproveMarketplaceDraftPostArgs>(
    MUTATION,
    options
  );

  return { callApproveMarketplaceDraftPost, ...result };
};

export type ApproveMarketplaceDraftPostMutationFunction = Mutation['approveMarketplaceDraftPost'];
