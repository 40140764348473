import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { CheckBox, CheckBoxProps } from '../CheckBox';

export interface BlackBarProps {
  label?: ReactNode;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  selectAllProps?: { label?: ReactNode; onClick?: () => void };
  checkboxProps?: Omit<CheckBoxProps, 'loading'> & { loading?: boolean };
}

export const BlackBar = ({ label, disabled, children, className, checkboxProps, selectAllProps }: BlackBarProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper} className={className}>
      {checkboxProps ? (
        <CheckBox
          checked
          size="16px"
          label={label}
          disabled={disabled}
          css={{ fontSize: '14px', fontWeight: 600, color: THEME.text.colors.white }}
          {...checkboxProps}
          loading={{ color: THEME.text.colors.white, status: checkboxProps.loading }}
          tooltipProps={{
            position: 'top-start',
            help: t<string>(checkboxProps?.indeterminate ? 'Select all' : 'Unselect all'),
            ...checkboxProps.tooltipProps
          }}
        />
      ) : null}

      {checkboxProps?.indeterminate && !!selectAllProps?.onClick ? (
        <button css={styles.button} onClick={selectAllProps.onClick} disabled={disabled} className="select-all-btn">
          {selectAllProps.label ?? t('Select all items')}
        </button>
      ) : null}

      {children}
    </div>
  );
};
const styles = {
  wrapper: css({
    top: 0,
    left: 0,
    zIndex: 7,
    width: '100%',
    height: '48px',
    display: 'flex',
    padding: '0 16px',
    position: 'absolute',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.black
  }),
  button: css({
    fontSize: '14px',
    cursor: 'pointer',
    marginLeft: '16px',
    fontWeight: 'normal',
    textDecoration: 'underline',
    color: THEME.text.colors.white,
    '&:hover': { opacity: 0.85 },
    '&:disabled': { opacity: 0.8, cursor: 'not-allowed' }
  })
};
