import { useTranslation } from 'react-i18next';
import { RenderDataWithFallback, TableSegment, WithLoadingOverlay } from '@/shared/atoms';
import { PostReportEmpty, PostReportTable, PostReportSegmentKeys } from '../../../PostReport';
import { EngagementReportChart } from '../EngagementReportChart';
import { EngagementReportTableDataProps, useEngagementReportTableData } from './hooks';

export const EngagementReportTable = (props: EngagementReportTableDataProps) => {
  const { t } = useTranslation();
  const { reportData, loading, segment, networkStatus, segmentStatus, handleSegmentChange } =
    useEngagementReportTableData(props);

  return (
    <RenderDataWithFallback
      loading={loading && networkStatus === 1} // maybe we should call initialLoading
      hasNoData={!reportData}
      noDataNode={<PostReportEmpty />}
    >
      {reportData ? (
        <WithLoadingOverlay loading={loading}>
          <EngagementReportChart data={reportData.chartData} socialMedias={props.engagement.socialMedias} />
          <TableSegment
            type="switch"
            onChange={handleSegmentChange}
            options={[
              {
                key: PostReportSegmentKeys.total,
                label: t('Total'),
                value: segmentStatus.total,
                loading: segment.total.loading
              },
              {
                key: PostReportSegmentKeys.influencer,
                label: t('General.Influencer'),
                value: segmentStatus.influencer,
                loading: segment.influencer?.loading
              },
              {
                key: PostReportSegmentKeys.post,
                label: t('General.Post'),
                value: segmentStatus.post,
                loading: segment.post?.loading
              }
            ]}
          />
          <PostReportTable
            loading={loading}
            data={reportData}
            segment={segment}
            pagination={{ totalRecords: props.totalRecords }}
          />
        </WithLoadingOverlay>
      ) : null}
    </RenderDataWithFallback>
  );
};
