import { useTranslation } from 'react-i18next';
import { AnalyticsTwitterDashboardLegendType } from '../types';
import { getAllLegendOptions } from '../utils';

interface Props {
  isInfluencerProfile?: boolean;
}
export const useAnalyticsTwitterDashboardOverviewLegend = ({ isInfluencerProfile }: Props) => {
  const { t } = useTranslation();

  const allLegendOptions = getAllLegendOptions(isInfluencerProfile);
  const availableLegendOptions = allLegendOptions.reduce<AnalyticsTwitterDashboardLegendType[]>(
    (acc, curr) =>
      curr.color // Ignore unsupported keys
        ? [...acc, { ...curr, label: curr.label ? t<string>(curr.label) : '' }]
        : acc,
    []
  );

  return { allLegendOptions, availableLegendOptions };
};
