import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateSocialAccountAnalyticsAdminV2.graphql';
import { Mutation, MutationcreateSocialAccountAnalyticsAdminV2Args } from '../../__generated__/globalTypes';

export const useCreateSocialAccountAnalyticsAdminV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationcreateSocialAccountAnalyticsAdminV2Args>
) => {
  const [callCreateSocialAccountAnalyticsAdminV2, result] = useMutation<
    Mutation,
    MutationcreateSocialAccountAnalyticsAdminV2Args
  >(MUTATION, options);

  return { callCreateSocialAccountAnalyticsAdminV2, ...result };
};

export type CreateSocialAccountAnalyticsAdminV2MutationFunction = Mutation['createSocialAccountAnalyticsAdminV2'];
