import {
  ProposalJobDescriptionBreakDownMode,
  EngagementSocialAcccountBudgetInput,
  useUpdateEngagementInfluencersBudgetMutation,
  useUpdateEngagementSocialAccountsBudgetMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { RowData } from '../../../types';
import { useSelectedInfluencersContext } from '../../SelectedInfluencersContext';

interface Props {
  engagementId: number;
  jdMode: ProposalJobDescriptionBreakDownMode;
}

export const useUpdateInfluencerAccountsBudget = ({ jdMode, engagementId }: Props) => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { resetViewMode } = useSelectedInfluencersContext();
  const { callUpdateEngagementInfluencersBudget, loading: updatingInfluencersBudget } =
    useUpdateEngagementInfluencersBudgetMutation({ onCompleted: invalidateRouteLoader });
  const { callUpdateEngagementSocialAccountsBudget, loading: updatingAccountsBudget } =
    useUpdateEngagementSocialAccountsBudgetMutation({
      onCompleted: invalidateRouteLoader
    });

  const updateInfluencerAccountsBudget = async (rowValues: RowData[]) => {
    try {
      if (jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN) {
        await callUpdateEngagementInfluencersBudget({
          variables: {
            input: {
              campaignId: engagementId,
              influencers:
                rowValues?.map(({ influencer, stats }) => ({
                  profit: stats.profit,
                  netBudget: stats.netBudget,
                  influencerId: influencer.id,
                  influencerCost: stats.influencerCost
                })) || []
            }
          }
        });
      } else {
        await callUpdateEngagementSocialAccountsBudget({
          variables: {
            input: {
              campaignId: engagementId,
              socialAccounts: rowValues.reduce<EngagementSocialAcccountBudgetInput[]>(
                (results, { stats, influencer, socialAccount }) => {
                  if (socialAccount) {
                    return [
                      ...results,
                      {
                        profit: stats.profit,
                        netBudget: stats.netBudget,
                        influencerId: influencer.id,
                        socialAccountId: socialAccount.id,
                        influencerCost: stats.influencerCost,
                        socialAccountMedia: socialAccount.socialMedia
                      }
                    ];
                  }

                  return results;
                },
                []
              )
            }
          }
        });
      }

      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
      resetViewMode();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return {
    updateInfluencerAccountsBudget,
    loading: updatingAccountsBudget || updatingInfluencersBudget
  };
};
