import { css } from '@emotion/react';

import { THEME } from '@/shared/constants';
import { DropdownOption } from '@/shared/types';

import { TextCutter } from '../TextCutter';

import { MultiLevelMenu } from './MultiLevelMenu';
import { DropdownDefaultProps } from './types';

export interface DropdownMenuProps<T extends DropdownOption> extends DropdownDefaultProps<T> {
  onSelectOption?: (option: T) => void;
}

export const DropdownMenu = <T extends DropdownOption>({
  options,
  className,
  onSelectOption
}: DropdownMenuProps<T>) => (
  <div css={[styles.dropdownWrapper]} className={className}>
    {options.map((option, index) => {
      const { label, gtmId, active, disabled, css } = option;

      return option.menuType === 'multi-level' ? (
        <MultiLevelMenu
          key={index}
          value={option.value}
          label={option.label}
          submenu={option.submenu}
          onClickSubmenu={(o) => onSelectOption?.(o as T)}
        />
      ) : (
        <div
          id={gtmId}
          key={index}
          css={[styles.dropdownItem(active, disabled), css]}
          onClick={() => (!disabled ? onSelectOption?.(option) : undefined)}
        >
          {typeof label === 'string' ? <TextCutter text={label} /> : label}
        </div>
      );
    })}
  </div>
);

const styles = {
  dropdownWrapper: css({
    zIndex: 10,
    minWidth: '165px',
    maxWidth: '350px',
    padding: '8px',
    borderRadius: '3px',
    width: 'max-content',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white
  }),
  dropdownItem: (active?: boolean, disabled?: boolean) =>
    css({
      padding: '10px',
      display: 'flex',
      fontSize: '12px',
      alignItems: 'center',
      textTransform: 'none',
      transition: 'background-color 0.1s ease',
      cursor: disabled ? 'default' : 'pointer',
      backgroundColor: active ? THEME.background.colors.gray.lv1 : THEME.background.colors.white,
      ...(!disabled ? { '&:hover': { backgroundColor: THEME.dropdown.hover } } : {})
    })
};
