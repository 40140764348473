import { useTranslation } from 'react-i18next';
import { Drawer } from '@/shared/atoms';
import { DefaultTemplate, DefaultTemplateProps } from './DefaultTemplate';

export const TikTokCampaignPostForm = ({
  postId,
  onClose,
  campaignId,
  campaignFinished
}: Pick<DefaultTemplateProps, 'campaignId' | 'postId' | 'onClose' | 'campaignFinished'>) => {
  const { t } = useTranslation();

  return (
    <Drawer size="470px" onClose={onClose} title={t('Post Detail')} closeOnClickOutside opened>
      <DefaultTemplate postId={postId} onClose={onClose} campaignFinished={campaignFinished} campaignId={campaignId} />
    </Drawer>
  );
};
