import { zodValidator } from '@tanstack/zod-adapter';
import { Outlet, redirect } from '@tanstack/react-router';
import { TrendingInfluencers, TrendingPosts } from '@/pages/TrendingInfluencers';
import { PermissionRoute } from '@/app-components';
import { getYesterday } from '@/shared/utils';
import { adminStaffAgencyAdvertiserTalentAgencyPartnerRoles } from '@/shared/constants';
import {
  TrendingInfluencersSearchFilterSchema,
  TrendingInfluencersTabType,
  TrendingPostsFilterSearchSchema
} from '@/shared/organisms';
import { FollowersGrowthPeriodEnum } from '@/graphql';
import { PartialRouter } from '../../types';

export const TrendingRouter = {
  '/_private-routes/trending': [
    () => <PermissionRoute permissions={adminStaffAgencyAdvertiserTalentAgencyPartnerRoles} />,
    {}
  ],
  '/_private-routes/trending/': [
    () => <Outlet />,
    {
      beforeLoad: () => {
        throw redirect({
          to: '/trending/influencers',
          search: { filter: { tab: TrendingInfluencersTabType.INSTAGRAM } }
        });
      }
    }
  ],
  '/_private-routes/trending/influencers': [
    TrendingInfluencers,
    {
      validateSearch: zodValidator(TrendingInfluencersSearchFilterSchema),
      loaderDeps: ({ search }) => search,
      loader: ({ context, deps }) => {
        if (!context.auth) {
          return {
            loading: true,
            networkStatus: 0,
            errorMessage: null,
            tiktokSpecialCampaign: null
          };
        }
        // Only redirect and set countryIds if no filters have been set yet
        if (!deps.filter && context.auth.countryId) {
          return redirect({
            to: '/trending/influencers',
            replace: true,
            search: {
              filter: {
                categoryIds: [],
                minFollowers: '10000',
                countryId: context.auth.countryId || 'JP',
                followerGrowthPeriod: FollowersGrowthPeriodEnum.ONE_MONTH
              }
            }
          });
        }
      }
    }
  ],
  '/_private-routes/trending/posts': [
    TrendingPosts,
    {
      loaderDeps: ({ search }) => search,
      loader: ({ context, deps }) => {
        if (!context.auth) {
          return {
            loading: true,
            networkStatus: 0,
            errorMessage: null,
            tiktokSpecialCampaign: null
          };
        }
        // Only redirect and set countryIds if no filters have been set yet
        if (!deps.filter && context.auth.countryId) {
          return redirect({
            to: '/trending/posts',
            replace: true,
            search: {
              filter: {
                categoryId: '24',
                date: getYesterday(),
                countryId: context.auth.countryId || 'JP'
              }
            }
          });
        }
      },
      validateSearch: zodValidator(TrendingPostsFilterSearchSchema)
    }
  ]
} as const satisfies PartialRouter;
