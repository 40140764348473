import { useState } from 'react';
import { BlackBar, BlackBarProps, Button } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { useProposeInfluencersApi } from '../hooks';

interface RejectProposeInfluencersActionBarProps extends Omit<BlackBarProps, 'children'> {
  selectedInfluencerIds: number[];
  numberSelected: number;
  hasAllSelected: boolean;
  onSelectAll: () => void;
  onToggleSelect: () => void;
}

export const RejectProposeInfluencersActionBar = ({
  numberSelected,
  hasAllSelected,
  selectedInfluencerIds,
  onSelectAll,
  onToggleSelect
}: RejectProposeInfluencersActionBarProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { allowInfluencer, handleRemoveInfluencers } = useProposeInfluencersApi();
  const [isBackingToList, setBackingToList] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const isCallingApi = isBackingToList || isDeleting;

  const handleAllowSelectedInfluencers = async () => {
    try {
      setBackingToList(true);
      await allowInfluencer({
        variables: {
          input: {
            influencerIds: selectedInfluencerIds
          }
        }
      });
      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setBackingToList(false);
    }
  };

  const handleDeleteSelectedInfluencers = async () => {
    try {
      setDeleting(true);
      await handleRemoveInfluencers(selectedInfluencerIds);
      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <BlackBar
      selectAllProps={{ onClick: onSelectAll }}
      checkboxProps={{
        onClick: onToggleSelect,
        indeterminate: !hasAllSelected,
        label: t('Selected', { count: numberSelected })
      }}
    >
      <Button
        variant="blue"
        disabled={isCallingApi}
        loading={isBackingToList}
        title={t('Button.Back To The List')}
        onClick={handleAllowSelectedInfluencers}
        css={{ padding: '0 16px', marginLeft: '16px' }}
      />
      <Button
        variant="red"
        loading={isDeleting}
        title={t('Button.Delete')}
        onClick={handleDeleteSelectedInfluencers}
        css={{ padding: '0 16px', marginLeft: '16px' }}
        disabled={isCallingApi}
      />
    </BlackBar>
  );
};
