import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InfluencerSearchKeywordField } from '@/graphql';
import { IconNames } from '@/shared/assets';
import { FormStyle, Icon, DropdownMenu } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useOnClickOutside, useToggle } from '@/shared/hooks';
import { TextFieldProps, TextSearchField } from '@/shared/molecules';
import { DropdownOption, Option } from '@/shared/types';
import { InfluencersFilterFormKeys, InfluencersFilterFormValues } from '../types';

interface KeywordFieldProps extends Omit<TextFieldProps, 'name' | 'onFocus'> {
  searchByCategories?: boolean;
}

export const KeywordField = ({ className, searchByCategories, onSubmit, ...restProps }: KeywordFieldProps) => {
  const { t } = useTranslation();
  const { on: isOpening, setOn: openPopover, setOff: closePopover } = useToggle(false);
  const { watch, setValue } = useFormContext<InfluencersFilterFormValues>();

  const handleCloseSubmit = () => {
    onSubmit?.();
    closePopover();
  };

  const { ref } = useOnClickOutside<HTMLDivElement>(isOpening ? handleCloseSubmit : () => undefined);
  const [keywordValue, keywordFieldValue] = watch(['keyword', 'keywordField']);

  const categories: { value: InfluencerSearchKeywordField | ''; icon: IconNames; label: string }[] = [
    { value: '', icon: 'search', label: 'influencersSearchAllCategories' },
    { value: InfluencerSearchKeywordField.NAME, icon: 'user', label: 'searchByInfluencerName' },
    { value: InfluencerSearchKeywordField.SOCIAL_USERNAME, icon: 'user', label: 'searchByUserName' },
    { value: InfluencerSearchKeywordField.BIO, icon: 'user', label: 'searchByBio' },
    { value: InfluencerSearchKeywordField.PROFILE, icon: 'user', label: 'searchByProfile' },
    { value: InfluencerSearchKeywordField.TAG, icon: 'tag', label: 'searchByTag' },
    { value: InfluencerSearchKeywordField.EMAIL, icon: 'mail', label: 'searchByEmail' }
  ];
  const options: DropdownOption<string>[] = categories.map((att) => ({
    value: att.value,
    active: !!keywordFieldValue && att.value === keywordFieldValue,
    label: (
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <Icon icon={att.icon} size="16px" color="#C5D0DA" />
        <p css={{ marginLeft: '8px', fontWeight: 600 }}>{keywordValue}</p>
        <p css={{ marginLeft: '16px', color: THEME.text.colors.gray.lv3 }}>{t(att.label)}</p>
      </div>
    )
  }));

  const handleSelectOption = ({ value }: Option<string, ReactNode>) => {
    setValue('keywordField', value as InfluencerSearchKeywordField);
    setTimeout(handleCloseSubmit, 0);
  };

  return (
    <FormStyle.FieldWrapper ref={ref} css={{ flex: '1 1 auto', position: 'static' }} className={className}>
      <TextSearchField<InfluencersFilterFormKeys>
        placeholder={t<string>('InfluencerFilter')}
        {...restProps}
        name="keyword"
        onBlur={() => undefined} // Prevent submit onBlur
        onSubmit={handleCloseSubmit}
        css={{ padding: '0 !important' }}
        onChange={(field, value) => {
          setValue(field, value);
          if (keywordFieldValue) {
            setValue('keywordField', '');
          }
        }}
        autoComplete={searchByCategories ? 'off' : 'on'}
        onFocus={searchByCategories ? openPopover : undefined}
      />
      {isOpening && keywordValue && searchByCategories ? (
        <DropdownMenu<DropdownOption<string>>
          options={options}
          onSelectOption={handleSelectOption}
          css={{
            position: 'absolute',
            width: '100%',
            left: '8px',
            marginTop: THEME.dropdown.offset,
            maxWidth: 'calc(100% - 16px)',
            border: THEME.border.base
          }}
        />
      ) : null}
    </FormStyle.FieldWrapper>
  );
};
