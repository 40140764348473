import { css } from '@emotion/react';
import update from 'immutability-helper';
import { useFormContext } from 'react-hook-form';

import { usePermissions } from '@/auth';
import { CustomPermissionLevel, UserRoles, useGetUserDefaultPermissionsQuery } from '@/graphql';
import { FormStyle, Radio } from '@/shared/atoms';
import { SUPPORTED_USER_ROLES, THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { SwitchToggleField } from '@/shared/molecules';

import { UserFormValues } from './types';
import { permissionsFieldsNameMapping } from './utils';

const allPermissionTypes: Array<keyof CustomPermissionLevel> = ['noView', 'view', 'manage', 'manageAndDelete'];

interface UserPermissionsProps {
  role: UserRoles;
  isEditMode?: boolean;
  isRoleChanged?: boolean;
}
export const UserPermissions = ({ role, isEditMode, isRoleChanged }: UserPermissionsProps) => {
  const { permissions } = usePermissions();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { watch, setValue } = useFormContext<UserFormValues>();

  const permissionsValue = watch('permissions');
  const hasCustomPermissions = permissions
    ? !!permissions?.customizeUserPermissions?.manage && SUPPORTED_USER_ROLES.includes(role)
    : false;
  const isTurnedOn = !!permissionsValue?.customizeUserPermissions?.manage;

  useGetUserDefaultPermissionsQuery({
    variables: { role },
    skip: !hasCustomPermissions || (isEditMode && !isRoleChanged),
    onError: (err) => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    onCompleted: ({ getUserDefaultPermissions }) => {
      if (getUserDefaultPermissions?.permissions) {
        setValue('permissions', getUserDefaultPermissions.permissions);
      }
    }
  });

  const handleClick = (field: keyof UserFormValues['permissions'], permissionToChange: keyof CustomPermissionLevel) => {
    const clickedValue = permissionsValue?.[field][permissionToChange];

    // turn on means if any other fields has value === true, we need to set it false
    if (clickedValue === false) {
      const toggleRadio = (name: keyof CustomPermissionLevel, val?: boolean | null) =>
        permissionToChange === name ? true : val !== null ? false : val;

      setValue(
        'permissions',
        update(permissionsValue, {
          [field]: {
            $apply(x: CustomPermissionLevel) {
              return {
                noView: toggleRadio('noView', x.noView),
                view: toggleRadio('view', x.view),
                manage: toggleRadio('manage', x.manage),
                manageAndDelete: toggleRadio('manageAndDelete', x.manageAndDelete)
              };
            }
          }
        })
      );
    }
  };

  const toggleCustomizePermission = (field: string, value: boolean) => {
    if (permissionsValue?.customizeUserPermissions) {
      setValue(
        'permissions',
        update(permissionsValue, {
          customizeUserPermissions: {
            $apply() {
              return {
                noView: isTurnedOn,
                view: null,
                manage: !isTurnedOn,
                manageAndDelete: null
              };
            }
          }
        })
      );
    }
  };

  if (!hasCustomPermissions || !permissionsValue) {
    return null;
  }

  return (
    <>
      <FormStyle.SectionTitle index={3} label={t('User Permissions')} css={{ marginTop: '24px' }} />

      <SwitchToggleField
        name="permissions.customizeUserPermissions.manage"
        label={t('Customize permissions')}
        onChange={toggleCustomizePermission}
      />

      <table css={styles.table}>
        <thead>
          <tr css={styles.row}>
            <th>{t('Permission')}</th>
            <th>{t('No View')}</th>
            <th>{t('View')}</th>
            <th>{t('Manage')}</th>
            <th>{t('Manage and Delete')}</th>
          </tr>
        </thead>
        <tbody>
          {(Object.keys(permissionsValue || {}) as Array<keyof UserFormValues['permissions']>).map(
            (field, rowIndex) => {
              if (!permissionsValue?.[field] || field === 'customizeUserPermissions') {
                return null;
              }

              return (
                <tr css={styles.row} key={rowIndex}>
                  <td css={{ fontSize: '13px', paddingLeft: '16px' }}>{t(permissionsFieldsNameMapping(field))}</td>
                  {allPermissionTypes.map((permissionName, index) => {
                    const permissionValue = permissionsValue?.[field][permissionName];
                    const disabled = permissionValue === null;

                    return (
                      <td
                        key={`${rowIndex}-${index}`}
                        onClick={() => handleClick(field, permissionName)}
                        css={[styles.radioTd(disabled), permissionValue && { background: 'rgba(56, 146, 229, 0.1)' }]}
                      >
                        <Radio checked={!!permissionValue} disabled={disabled} css={{ justifyContent: 'center' }} />
                      </td>
                    );
                  })}
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
};

const styles = {
  table: css({
    width: '100%',
    marginTop: '12px',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    border: THEME.border.base,
    color: THEME.text.colors.black.lv1,
    backgroundColor: THEME.background.colors.white
  }),
  row: css({
    height: '40px',
    th: {
      fontWeight: 600,
      fontSize: '13px',
      border: THEME.border.base,
      backgroundColor: THEME.background.colors.gray.lv1,
      '&:first-of-type': { width: '30%', paddingLeft: '16px' },
      '&:not(:first-of-type)': { width: '20%', textAlign: 'center' }
    },
    td: {
      border: THEME.border.base,
      '&:first-of-type': { backgroundColor: THEME.background.colors.gray.lv1 }
    }
  }),
  toggleLabel: css({
    fontWeight: 400,
    fontSize: '13px',
    letterSpacing: 0,
    marginLeft: '16px',
    lineHeight: '13px',
    fontStyle: 'normal'
  }),
  radioTd: (disabled?: boolean) =>
    disabled
      ? css({
          backgroundColor: THEME.background.colors.gray.lv1,
          div: {
            borderColor: '#c5d0da',
            backgroundColor: '#dee5ec',
            '&::after': { backgroundColor: '#dee5ec' }
          }
        })
      : css({ cursor: 'pointer', '&:hover': { opacity: 0.8 } })
};
