import { PostDetailsType } from '@/shared/types';
import { InfluencerInstagramAnalyticsPostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface InfluencerInstagramAnalyticsPostDetailsModalProps {
  isAnalytics?: boolean;
  posts: PostDetailsType[];
}

export const InfluencerInstagramAnalyticsPostDetailsModal = ({
  posts,
  isAnalytics
}: InfluencerInstagramAnalyticsPostDetailsModalProps) => (
  <CarouselTemplateModal posts={posts}>
    {({ selectedPost, closeModal }) => (
      <InfluencerInstagramAnalyticsPostDetails
        id={selectedPost.id}
        onCloseModal={closeModal}
        isAnalytics={isAnalytics}
        mainSocialAccountId={selectedPost?.mainSocialAccountId}
      />
    )}
  </CarouselTemplateModal>
);
