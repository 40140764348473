import { CSSProperties, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { MenuListProps as Props } from 'react-select';
import { SELECT_OPTION_HEIGHT } from '../utils';
import { CommonSelectProps } from '../types';

const ROWS = 8; // number of items to render

export type MenuListProps = Props<any, any, any> & Pick<CommonSelectProps<any, any, any>, 'menuWidth' | 'menuHeight'>;

// Custom MenuList component for react-select
// Support virtualization for large options list, better load performance
export const MenuList = (props: MenuListProps) => {
  const { children, options, getValue, innerRef, menuWidth, menuHeight } = props;

  // Store the measured width of the options to support max-width menu
  const [maxMenuWidth, setMaxMenuWidth] = useState<number | null>(null);

  // Initial offset for virtualization based on the 1st selected value
  const [value] = getValue();
  const initialOffset =
    options.indexOf(value) !== -1
      ? Array.isArray(children) && children.length >= ROWS
        ? options.indexOf(value) >= ROWS
          ? options.indexOf(value) * SELECT_OPTION_HEIGHT
          : 0
        : 0
      : 0;
  const menuWidthAuto = menuWidth === 'auto';
  const menuHeightAuto = menuHeight === 'auto';

  const handleSetMeasuredWidth = (width: number) => {
    // Ignore unnecessary cases when set maxMenuWidth
    if (menuWidthAuto && (maxMenuWidth === null || width > maxMenuWidth)) {
      setMaxMenuWidth(width);
    }
  };

  return Array.isArray(children) ? (
    <FixedSizeList
      outerRef={innerRef}
      itemCount={children.length}
      itemSize={SELECT_OPTION_HEIGHT}
      initialScrollOffset={initialOffset}
      overscanCount={menuHeightAuto ? children.length : undefined} // Show all items when menuHeight is auto
      width={menuWidthAuto ? maxMenuWidth || '100%' : menuWidth || '100%'}
      height={
        menuHeightAuto
          ? children.length * SELECT_OPTION_HEIGHT
          : typeof menuHeight === 'number'
            ? menuHeight * SELECT_OPTION_HEIGHT
            : children.length >= ROWS
              ? SELECT_OPTION_HEIGHT * ROWS
              : children.length * SELECT_OPTION_HEIGHT
      }
    >
      {({ style, index }) => (
        <ListItem
          index={index}
          onSetMeasuredWidth={handleSetMeasuredWidth}
          style={{ ...style, ...(menuWidthAuto ? { minWidth: '100%', width: 'max-content' } : {}) }}
        >
          {children.at(index)}
        </ListItem>
      )}
    </FixedSizeList>
  ) : (
    <div>{children}</div> // No option
  );
};

interface ListItemProps {
  index: number;
  children: ReactNode;
  style: CSSProperties;
  onSetMeasuredWidth: (width: number) => void; // Support css max-width for Virtualized List, it use position absolute to align the option so we need to get the width of the option manually
}
const ListItem = ({ index, style, children, onSetMeasuredWidth }: ListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);

  // using useLayoutEffect prevents bounciness of options of re-renders
  useLayoutEffect(() => {
    if (ref.current) {
      onSetMeasuredWidth(ref.current.getBoundingClientRect().width);
    }
  }, [ref.current]);

  return (
    <div ref={ref} style={style} key={`option-${index}`}>
      {children}
    </div>
  );
};
