import { MonthPickerSingle, MonthPickerSingleProps } from '../MonthPicker';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';
import { RHFFieldWrapper } from './shared';

export interface SingleMonthPickerFieldProps<T extends string = string>
  extends DefaultFieldProps<T, string>,
    Omit<MonthPickerSingleProps, 'value' | 'onChange'> {}

export const SingleMonthPickerField = <T extends string = string>(props: SingleMonthPickerFieldProps<T>) => {
  const {
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, onChange, onSubmit, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  const handleChange = (date: string) => {
    if (onChange) {
      onChange(name, date);
    } else {
      setValue<string>(name, date, setValueOptions);
    }
    onSubmit?.();
  };

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <MonthPickerSingle {...restFieldProps} onChange={handleChange} />
    </RHFFieldWrapper>
  );
};
