import {
  InstagramBrandAmbassadorPostsByUsername,
  InstagramInteractionCommonPostData,
  InstagramInteractionCommonTaggedPostData,
  InstagramInteractionOverview
} from '@/graphql';
import {
  igBrandMentionedPost1,
  igBrandMentionedPost2,
  igBrandMentionedPost3,
  igBrandMentionedPost4
} from '@/shared/assets';

const dates = ['2025-01-01', '2025-01-02', '2025-01-03', '2025-01-04', '2025-01-05', '2025-01-06', '2025-01-07'];
const followers = [2415, 2500, 2513, 2545, 2563, 2612, 2625];
const taggedPosts = [0, 1, 0, 1, 0, 2, 0];

export const interactionMock = {
  overview: {
    graph: {
      followers: dates.map((date, index) => ({ count: followers[index], date })),
      taggedPosts: dates.map((date, index) => ({ count: taggedPosts[index], date })),
      taggedComments: [],
      taggedLikes: [],
      mentionedComments: [],
      mentionedLikes: [],
      mentionedPosts: []
    },
    summaries: {
      mentionComments: {
        total: 20,
        growth: 16,
        percentage: 0.8
      },
      mentionLikes: {
        total: 214,
        growth: 30,
        percentage: 0.16
      },
      mentionPosts: {
        total: 2,
        growth: 1,
        percentage: 0.5
      },
      taggedComments: {
        total: 20,
        growth: 5,
        percentage: 0.5
      },
      taggedLikes: {
        total: 29,
        growth: 3,
        percentage: 0.33
      },
      taggedPosts: {
        total: 4,
        growth: 2,
        percentage: 0.5
      }
    }
  } satisfies InstagramInteractionOverview,
  brandAmbassadorPosts: [
    {
      igUsername: 'jojosilver',
      brandMentionedPosts: 2,
      posts: [
        {
          postId: '1',
          postUrl: 'https://via.placeholder.com/1',
          thumbNail: igBrandMentionedPost1,
          comments: 3,
          likes: 5
        },
        {
          postId: '2',
          postUrl: 'https://via.placeholder.com/2',
          thumbNail: igBrandMentionedPost2,
          comments: 4,
          likes: 8
        }
      ]
    },
    {
      igUsername: 'kaletutane',
      brandMentionedPosts: 1,
      posts: [
        {
          postId: '3',
          postUrl: 'https://via.placeholder.com/1',
          thumbNail: igBrandMentionedPost3,
          comments: 5,
          likes: 6
        }
      ]
    },
    {
      igUsername: 'j_anita',
      brandMentionedPosts: 1,
      posts: [
        {
          postId: '4',
          postUrl: 'https://via.placeholder.com/4',
          thumbNail: igBrandMentionedPost4,
          comments: 8,
          likes: 10
        }
      ]
    }
  ] satisfies InstagramBrandAmbassadorPostsByUsername[],
  taggedPosts: [
    {
      id: 1,
      postUrl: 'https://via.placeholder.com/1',
      thumbNail: igBrandMentionedPost4,
      postDate: dates.at(0),
      comments: 8,
      likes: 10
    },
    {
      id: 2,
      postUrl: 'https://via.placeholder.com/2',
      thumbNail: igBrandMentionedPost1,
      postDate: dates.at(1),
      comments: 3,
      likes: 5
    },
    {
      id: 3,
      postUrl: 'https://via.placeholder.com/3',
      thumbNail: igBrandMentionedPost2,
      postDate: dates.at(2),
      comments: 4,
      likes: 8
    }
  ] as InstagramInteractionCommonTaggedPostData[],
  mentionedPosts: [
    {
      id: 1,
      postUrl: 'https://via.placeholder.com/4',
      comments: 8,
      likes: 10,
      mediaUrl: igBrandMentionedPost3
    }
  ] as InstagramInteractionCommonPostData[]
};
