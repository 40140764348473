import { GlobalError, get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { SingleDatePickerField, SingleDatePickerFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export interface CampaignPeriodFieldProps<T extends string = keyof typeof MarketplaceFormKeys> {
  className?: string;
  required?: boolean;
  disabled?: boolean;
  endDateProps?: Partial<Omit<SingleDatePickerFieldProps<T>, 'required'>>;
  startDateProps?: Partial<Omit<SingleDatePickerFieldProps<T>, 'required'>>;
}

export const CampaignPeriodField = <T extends string = keyof typeof MarketplaceFormKeys>({
  className,
  disabled,
  required,
  endDateProps,
  startDateProps
}: CampaignPeriodFieldProps<T>) => {
  const { t } = useTranslation();
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext<Record<string, string>>();

  const endDateName = (endDateProps?.name || 'period.endDate') as T;
  const startDateName = (startDateProps?.name || 'period.startDate') as T;
  const startDateValue = watch(startDateName);
  const { ref } = register(startDateName);

  const endDateError = get(errors, endDateName) as GlobalError;

  return (
    <FormStyle.TwoDayPickersInARow css={{ flex: 1, position: 'relative', paddingRight: '8px' }} className={className}>
      <SingleDatePickerField<T>
        required={required}
        disabledDays={{ before: new Date() }}
        placeholder={t<string>('Choose date')}
        title={t('Selector.Campaign Duration')}
        disabled={disabled}
        {...startDateProps}
        name={startDateName}
      />
      <span css={{ alignSelf: 'end', paddingBottom: '20px' }}>{'\u2013'}</span>
      <FormStyle.FocusInput ref={ref} />
      <SingleDatePickerField<T>
        hasError={!!endDateError}
        css={{ label: { height: '18px' } }}
        help={t('Tooltip.Campaign Duration')}
        placeholder={t<string>('Choose date')}
        disabledDays={{ before: startDateValue ? new Date(startDateValue) : new Date() }}
        disabled={disabled}
        {...endDateProps}
        title={endDateProps?.title || <span />}
        name={endDateProps?.name || ('period.endDate' as T)}
      />
    </FormStyle.TwoDayPickersInARow>
  );
};
