import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteMarketplace.graphql';
import { Mutation, MutationdeleteMarketplaceArgs } from '../../__generated__/globalTypes';

export const useDeleteMarketplaceMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteMarketplaceArgs>
) => {
  const [callDeleteMarketplace, result] = useMutation<Mutation, MutationdeleteMarketplaceArgs>(MUTATION, options);

  return { callDeleteMarketplace, ...result };
};

export type DeleteMarketplaceMutationFunction = Mutation['deleteMarketplace'];
