import { useTranslation } from 'react-i18next';
import { InstagramReelPostItem, InstagramReelPostSortField } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';

type CustomColumnProps = ColumnProps<InstagramReelPostSortField, keyof InstagramReelPostItem>;

interface Props {
  data: readonly InstagramReelPostItem[];
}

export const useAnalyticsInstagramReelPostsListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Post'), sticky: { active: true, hasBorderRight: true } },
    getMetricColumnProps({
      title: t('HeaderColumn.View'),
      help: t<string>('Tooltip.ViewHelp'),
      sortField: InstagramReelPostSortField.VIEWS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Reach'),
      help: t<string>('Tooltip.ReachPostHelp'),
      sortField: InstagramReelPostSortField.REACH
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      help: t<string>('Tooltip.LikeHelp'),
      sortField: InstagramReelPostSortField.LIKES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      help: t<string>('Tooltip.CommentHelp'),
      sortField: InstagramReelPostSortField.COMMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.share'),
      help: t<string>('Tooltip.shareHelp'),
      sortField: InstagramReelPostSortField.SHARES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Save'),
      help: t<string>('Tooltip.SaveHelp'),
      sortField: InstagramReelPostSortField.SAVED
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      help: t<string>('Tooltip.PostDateHelp'),
      sortField: InstagramReelPostSortField.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({ id, views, shares, reach, likes, saved, content, postDate, comments, thumbNail }) => [
      <TableCell.Post title={content || ''} thumbnail={thumbNail || ''} onClick={() => setPostDetailsParam(id)} />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={formatIntNumber(reach)} />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(shares)} />,
      <TableCell.Number value={formatIntNumber(saved)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
