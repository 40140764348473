import { t } from 'i18next';
import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { getCsvPagesAndRowsValidationSchema } from './validationSchema';

export enum CSV_EXPORT_TYPE {
  PAGES = 'PAGES',
  ROWS = 'ROWS'
}
const SCHEMA = getCsvPagesAndRowsValidationSchema(t, 1);
export type DownloadCsvPagesAndRowsModalValues = z.infer<typeof SCHEMA>;

export type DownloadCsvPagesAndRowsModalKeys = UnionOfKeys<DownloadCsvPagesAndRowsModalValues>;
