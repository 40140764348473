export enum InfluencersTabType {
  XHS = 'XHS',
  POST = 'POST',
  TIKTOK = 'TIKTOK',
  DOUYIN = 'DOUYIN',
  PACKAGE = 'PACKAGE',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  THREADS = 'THREADS',
  CAMPAIGN = 'CAMPAIGN',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  INFLUENCERS = 'INFLUENCERS',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE'
}
