import { Navigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { ListWithPagination } from '@/shared/molecules';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { RenderDataWithFallback } from '@/shared/atoms';
import {
  YoutubeCMSFilter,
  YoutubeCMSSummary,
  YoutubeCMSRevenueType,
  YoutubeCMSChannelsAssetsList
} from '@/shared/organisms';
import { useYoutubeCMSChannelDetailsData } from '../hooks';
import { YoutubeCMSChannelInformation } from './YoutubeCMSChannelInformation';

export const YoutubeCMSChannelDetails = () => {
  useUpdateDocumentTitle({ appHeader: 'Back', href: '/youtube-cms', alwaysBackToParent: true });
  const { t } = useTranslation();
  const { cmsChannelId, fetchingData, fetchingSummary, cmsChannelDetail, assetsData, summaryData, totalRecords } =
    useYoutubeCMSChannelDetailsData();

  if (!cmsChannelId) return <Navigate to="/youtube-cms" />;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      {cmsChannelDetail ? <YoutubeCMSChannelInformation cmsChannelId={cmsChannelId} data={cmsChannelDetail} /> : null}

      <YoutubeCMSFilter
        css={cmsChannelDetail ? { borderRadius: 0 } : undefined}
        searchPlaceholder={t<string>('Search.YoutubeCMSChannelAssets')}
      />

      <RenderDataWithFallback loading={fetchingSummary}>
        <YoutubeCMSSummary type={YoutubeCMSRevenueType.ESTIMATED_REVENUE} data={summaryData} />
        <YoutubeCMSChannelsAssetsList data={assetsData} loading={fetchingData} totalRecords={totalRecords} />
      </RenderDataWithFallback>
    </ListWithPagination>
  );
};
