import { createRouter, createBrowserHistory, RouteIds } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';

const browserHistory = createBrowserHistory();
export const router = createRouter({
  routeTree,
  history: browserHistory,
  context: {
    auth: undefined,
    defaultRoute: '/',
    queryClient: undefined,
    activeRouteId: undefined,
    activeRoutePath: undefined
  },
  scrollRestoration: true,
  defaultOnCatch(error, errorInfo) {
    console.error(error, errorInfo);
  },
  // defaultPendingComponent: InitialLoading,
  // This make the loader only wait 200ms before showing the pending component, instead of the default 1000ms
  // defaultPendingMs: 200,
  defaultPreload: 'intent',
  defaultStaleTime: 60_000, // 1 minute for Loader data to be valid
  defaultPreloadStaleTime: 60_000 // 1 minute for Loader preload=true data to be valid
});

export type RouterType = typeof router;
export type RouterIds = RouteIds<RouterType['routeTree']>;
