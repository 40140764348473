import { useTranslation } from 'react-i18next';
import { Tabs } from '@/shared/atoms';
import { AFFILIATE_CAMPAIGN_TYPES, THEME } from '@/shared/constants';
import { useUserRoles, useReportPermissions, useInfluencerPermissions, useMarketplacePermissions } from '@/auth';
import {
  MarketplaceDetail,
  CampaignTrackingOption,
  MarketplaceCampaignStatus,
  MarketplaceCampaignDetailType,
  MarketplaceCampaignParticipateStatus
} from '@/graphql';
import { getMkpReportLink } from '@/shared/utils';

export interface MarketplaceTabsProps {
  marketplace: MarketplaceDetail;
}

export const MarketplaceTabs = ({
  marketplace: { id, status, campaignType, isPostTracking, trackingOptions, isSelectionCampaign, startDate, endDate }
}: MarketplaceTabsProps) => {
  const { t } = useTranslation();
  const { hideReportLink } = useReportPermissions();
  const { hideSelectInfluencers } = useInfluencerPermissions();
  const { hideEditMarketplaceBtn, hideCommissionsTab } = useMarketplacePermissions();
  const { isAgency, isAdvertiser, isTalentAgency, isPartner, isAdminStaff } = useUserRoles();

  const isAffiliateCampaign = AFFILIATE_CAMPAIGN_TYPES.includes(campaignType);
  const hasTrackingTune = !!trackingOptions?.includes(CampaignTrackingOption.TUNE);
  const hasTrackingReferral = !!trackingOptions?.includes(CampaignTrackingOption.REFERRAL_CODE);

  return (
    <Tabs
      hasIndex
      css={THEME.campaign.customTabStyles}
      options={[
        {
          end: true,
          label: t('Campaign Information'),
          hidden: hideEditMarketplaceBtn,
          to: '/marketplace/$id'
        },
        {
          end: true,
          label: t('URL Tracking'),
          hidden: !hasTrackingTune || campaignType === MarketplaceCampaignDetailType.E_COMMERCE,
          to: '/marketplace/$id/tracking'
        },
        {
          end: true,
          label: t('Referral Code Tracking'),
          hidden: !hasTrackingReferral,
          to: '/marketplace/$id/tracking-referral-codes'
        },
        {
          end: false,
          label: t('General.Select Influencers'),
          hidden: isAgency || isAdvertiser || hideSelectInfluencers,
          to: '/marketplace/$id/influencer',
          search: {
            filter: {
              participateStatuses:
                status === MarketplaceCampaignStatus.REVIEWING
                  ? [MarketplaceCampaignParticipateStatus.AVAILABLE, MarketplaceCampaignParticipateStatus.UNAVAILABLE]
                  : [
                      MarketplaceCampaignParticipateStatus.JOINED,
                      MarketplaceCampaignParticipateStatus.AVAILABLE,
                      MarketplaceCampaignParticipateStatus.UNAVAILABLE,
                      ...(isPostTracking ? [MarketplaceCampaignParticipateStatus.WAITING] : [])
                    ]
            }
          }
        },
        {
          end: true,
          label: t('General.ApplicantInfluencers'),
          hidden: isTalentAgency || isPartner || hideSelectInfluencers || !isSelectionCampaign,
          to: '/marketplace/$id/applicant'
        },
        {
          end: false,
          label: t('General.Posts'),
          hidden: !isPostTracking,
          to: '/marketplace/$id/posts'
        },
        {
          end: true,
          label: t('Joined Influencers'),
          hidden:
            !isAffiliateCampaign ||
            isPostTracking ||
            [MarketplaceCampaignStatus.REVIEWING, MarketplaceCampaignStatus.REJECTED].includes(status),
          to: '/marketplace/$id/joined-influencers'
        },
        {
          end: true,
          label: t('General.Report'),
          hidden:
            hideReportLink ||
            ![
              MarketplaceCampaignStatus.ONGOING,
              MarketplaceCampaignStatus.FINISHED,
              MarketplaceCampaignStatus.SUSPENDED
            ].includes(status),
          ...getMkpReportLink({
            id: id.toString(),
            mkpCampaignType: campaignType,
            isPostTracking
          })
        },
        {
          end: true,
          label: t('General.Commissions'),
          hidden:
            isPostTracking ||
            hideCommissionsTab ||
            !isAdminStaff ||
            [MarketplaceCampaignStatus.UPCOMING, MarketplaceCampaignStatus.REVIEWING].includes(status),
          to: '/marketplace/$id/commissions'
        }
      ]}
    />
  );
};
