import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { PostDetailsType } from '@/shared/types';

export interface PostCaptionProps extends Pick<PostDetailsType, 'caption'> {
  className?: string;
}

export const PostCaption = ({ caption, className }: PostCaptionProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper} className={className}>
      <p css={styles.title}>{t("Post's Caption")}</p>
      <div css={styles.captionWrapper}>
        <div css={styles.captionContent}>
          <p css={styles.caption}>{caption}</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    flex: '1 1 0',
    overflow: 'hidden',
    borderRadius: '3px',
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  title: css({
    fontSize: '10px',
    fontWeight: 400,
    color: THEME.text.colors.gray.lv3,
    padding: '8px 8px 0 8px'
  }),
  captionWrapper: css({
    flex: '1 1 0',
    marginTop: '8px',
    overflowY: 'auto',
    height: 'calc(100% - 32px)'
  }),
  captionContent: css({
    padding: '0 8px 8px 8px'
  }),
  caption: css({
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '140%',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
    color: THEME.text.colors.black.lv1
  })
};
