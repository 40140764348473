// TODO: Refactor
import { useTranslation } from 'react-i18next';
import { TableColumnSelector, TableColumnSelectorProps } from '../Selector';
import { MultiSelectorProps } from '../Selector/MultiSelector';

export interface Option {
  label: string;
  value: string;
}

export interface TableColumnSelectorGroupProps<T>
  extends Omit<MultiSelectorProps, 'value' | 'initialValues' | 'onChange' | 'onClose'> {
  className?: string;
  title?: string;
  value?: T[];
  help?: string;
  setCheckboxValues: (value: T[]) => void;
  handleSubmit: TableColumnSelectorProps['handleSubmit'];
  initialValues: T[];
  closeModal: () => void;
  defaultFields: T[];
}

export const TableColumnSelectorGroup = <T extends string>(props: TableColumnSelectorGroupProps<T>) => {
  const { t } = useTranslation();
  const {
    className,
    name,
    placeholder,
    value,
    options,
    hasError,
    hasSelectAll,
    handleSubmit,
    setCheckboxValues,
    canOnlyAddMore,
    initialValues,
    closeModal,
    defaultFields
  } = props;
  const disabled = props.disabled || options.length === 0;

  const handleSetValues = (items: T[]) => {
    setCheckboxValues([...initialValues, ...items]);
  };

  return (
    <div className={className}>
      <TableColumnSelector<T>
        options={options}
        name={name}
        disabled={disabled}
        placeholder={disabled ? '' : placeholder ?? t('Selector.Please Select')}
        value={value}
        hasError={hasError}
        hasSelectAll={hasSelectAll}
        onChange={handleSetValues}
        onClose={closeModal}
        canOnlyAddMore={canOnlyAddMore}
        initialValues={initialValues}
        closeModal={closeModal}
        defaultFields={defaultFields}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};
