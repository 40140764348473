import { WarningReason } from '@/graphql';
import { ColorVariant } from '@/shared/types';

export const checkIsReelPost = (postUrl?: string | null) => !!postUrl && postUrl?.includes('/reel/');

export const getPostWarningReasonStatusProps = (warningReason: WarningReason) => {
  switch (warningReason) {
    case WarningReason.AD_FRAUD:
      return { label: 'Option.Ad fraud', variant: ColorVariant.DARK_YELLOW };
    case WarningReason.API_ERROR:
      return { label: 'Option.Upload insight', variant: ColorVariant.LIGHT_YELLOW };
    case WarningReason.SCRAPING_ERROR:
      return { label: 'Option.Upload insight', variant: ColorVariant.LIGHT_RED };
    case WarningReason.COUPON_MISSING:
      return { label: 'Option.Code missing', variant: ColorVariant.LIGHT_RED };
    case WarningReason.DELETED_POST:
      return { label: 'Option.Deleted post', variant: ColorVariant.DARK_GREY };
    case WarningReason.INVALID_CONTENT:
      return { label: 'Option.Invalid content', variant: ColorVariant.LIGHT_RED };
    case WarningReason.INVALID_TOKEN:
    case WarningReason.EXPIRED_TOKEN:
      return { label: 'Option.Invalid token', variant: ColorVariant.LIGHT_RED };
    default:
      return { label: '', variant: ColorVariant.DARK_GREY };
  }
};
