import {
  BulkUserProposalSocialAccount,
  UserProposalSocialAcccountBudgetInput,
  namedOperations,
  useBulkUpdateUserProposalAccountsBudgetMutation,
  useBulkUpdateUserProposalInfluencersBudgetMutation
} from '@/graphql';
import { DefaultBudgetInputType } from '@/shared/organisms';

interface ProposalBulkEditProps {
  selectedInfluencerAccountIds: number[];
  selectedSocialAccounts: UserProposalSocialAcccountBudgetInput[];
}
export const useProposalBulkEdit = ({
  selectedSocialAccounts,
  selectedInfluencerAccountIds
}: ProposalBulkEditProps) => {
  const { callBulkUpdateUserProposalInfluencersBudget, loading: updatingInfluencersBudget } =
    useBulkUpdateUserProposalInfluencersBudgetMutation({ refetchQueries: [namedOperations.Query.UserProposal] });
  const { callBulkUpdateUserProposalAccountsBudget, loading: updatingAccountsBudget } =
    useBulkUpdateUserProposalAccountsBudgetMutation({ refetchQueries: [namedOperations.Query.UserProposal] });

  const handleInfluencersBudgetSubmit = async (defaultInput: DefaultBudgetInputType) => {
    await callBulkUpdateUserProposalInfluencersBudget({
      variables: {
        input: { ...defaultInput, influencerIds: selectedInfluencerAccountIds }
      }
    });
  };
  const handleSocialAccountBudgetSubmit = async (defaultInput: DefaultBudgetInputType) => {
    const socialAccounts: BulkUserProposalSocialAccount[] = selectedSocialAccounts.map(
      ({ influencerId, socialAccountId, socialAccountMedia }) => ({
        influencerId,
        socialAccountId,
        socialAccountMedia
      })
    );

    await callBulkUpdateUserProposalAccountsBudget({
      variables: {
        input: { ...defaultInput, socialAccounts }
      }
    });
  };

  return {
    handleInfluencersBudgetSubmit,
    handleSocialAccountBudgetSubmit,
    bulkLoading: updatingAccountsBudget || updatingInfluencersBudget
  };
};
