import { useLayoutEffect, useRef, useState } from 'react';

import { THEME } from '@/shared/constants';

export const useTableMaxHeight = () => {
  const offsetRef = useRef<HTMLDivElement>(null);
  const [offsetTop, setOffsetTop] = useState(0);

  useLayoutEffect(() => {
    if (offsetRef.current) {
      setOffsetTop(offsetRef.current.offsetTop || (offsetRef.current?.offsetParent as HTMLElement)?.offsetTop || 0);
    }

    return () => {
      setOffsetTop(0);
    };
  }, [offsetRef.current]);

  return {
    offsetRef,
    distanceToTheScreenTop: offsetTop,
    distanceToTheScreenBottom: window.innerHeight - offsetTop,
    /**
     * @description in the ideal situation we subtract default Layout padding and Pagination height
     */
    defaultTableMaxHeight: `calc(${window.innerHeight - offsetTop}px - ${THEME.table.pagination.height} - 24px)`,
    footerHeight: THEME.container.footer.height,
    /**
     * @description mostly our layout has 24px padding, but we need to adjust based on the in-place situation
     */
    normalLayoutPadding: '24px'
  };
};
