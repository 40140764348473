import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateTalentAgencyWithUser.graphql';
import { Mutation, MutationcreateTalentAgencyWithUserArgs } from '../../__generated__/globalTypes';

export const useCreateTalentAgencyWithUserMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateTalentAgencyWithUserArgs>
) => {
  const [callCreateTalentAgencyWithUser, result] = useMutation<Mutation, MutationcreateTalentAgencyWithUserArgs>(
    MUTATION,
    options
  );

  return { callCreateTalentAgencyWithUser, ...result };
};

export type CreateTalentAgencyWithUserMutationFunction = Mutation['createTalentAgencyWithUser'];
