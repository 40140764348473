import { useTranslation } from 'react-i18next';
import { SortOption } from '@/shared/types';
import { InfluencerListSortField, Order } from '@/graphql';
import { BlackBar, BlackBarProps, FormProvider } from '@/shared/atoms';
import { useMarketplaceInfluencersFilter } from '../MarketplaceInfluencersFilter';
import { Template } from './Template';
import { MarketplaceInfluencersSortFormValues } from './types';

export interface MarketplaceInfluencersSortProps {
  selectButtonProps: BlackBarProps;
}

export const MarketplaceInfluencersSort = ({ selectButtonProps }: MarketplaceInfluencersSortProps) => {
  const { t } = useTranslation();
  const { limit, sort, setSortAndLimit } = useMarketplaceInfluencersFilter();

  const sortOptions: SortOption<InfluencerListSortField>[] = [
    { field: InfluencerListSortField.FOLLOWERS, label: t<string>('Total Followers (ASC)'), order: Order.ASC },
    { field: InfluencerListSortField.FOLLOWERS, label: t<string>('Total Followers (DESC)'), order: Order.DESC },
    { field: InfluencerListSortField.ENGAGEMENT, label: t<string>('Engagement (ASC)'), order: Order.ASC },
    { field: InfluencerListSortField.ENGAGEMENT, label: t<string>('Engagement (DESC)'), order: Order.DESC },
    { field: InfluencerListSortField.ENGAGEMENT_RATE, label: t<string>('ER (ASC)'), order: Order.ASC },
    { field: InfluencerListSortField.ENGAGEMENT_RATE, label: t<string>('ER (DESC)'), order: Order.DESC },
    { field: InfluencerListSortField.MARKETPLACE_JOINED_COUNT, label: t<string>('MKP Joined (ASC)'), order: Order.ASC },
    {
      field: InfluencerListSortField.MARKETPLACE_JOINED_COUNT,
      label: t<string>('MKP Joined (DESC)'),
      order: Order.DESC
    },
    { field: InfluencerListSortField.MARKETPLACE_POSTED_COUNT, label: t<string>('MKP Posted (ASC)'), order: Order.ASC },
    {
      field: InfluencerListSortField.MARKETPLACE_POSTED_COUNT,
      label: t<string>('MKP Posted (DESC)'),
      order: Order.DESC
    }
  ].map((option) => ({ ...option, value: `${option.field}-${option.order}` }));

  return (
    <div css={{ position: 'relative', borderRadius: 'inherit' }}>
      {selectButtonProps.checkboxProps?.checked ? <BlackBar css={{ padding: '0 8px' }} {...selectButtonProps} /> : null}

      <FormProvider<MarketplaceInfluencersSortFormValues>
        onSubmit={(values) => {
          setSortAndLimit({
            sort: { field: values.field, order: values.order },
            limit: +values.limit
          });
        }}
        values={{
          ...sort,
          limit: String(limit),
          sort: sortOptions.find((o) => o.field === sort.field && o.order === sort.order)?.value || ''
        }}
      >
        <Template checkboxProps={selectButtonProps.checkboxProps} sortOptions={sortOptions} />
      </FormProvider>
    </div>
  );
};
