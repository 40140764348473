import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SocialAccountStatus, SocialAccountType } from '@/graphql';
import { Button, ButtonProps } from '@/shared/atoms';
import { SocialMediaInputFieldProps } from '../../../shared';
import { defaultSocialNetworkAccount } from '../../utils';
import { SocialAccountsFormValues, SocialNetworkAccount } from '../types';
import { SocialMediaInputFieldAction } from './SocialMediaInputFieldAction';

const ADDING_ACCOUNTS_LIMIT = 10;

type Accounts = Pick<
  SocialAccountsFormValues,
  | 'xhsAccounts'
  | 'facebookPages'
  | 'tiktokAccounts'
  | 'douyinAccounts'
  | 'twitterAccounts'
  | 'threadsAccounts'
  | 'youtubeAccounts'
  | 'instagramAccounts'
>;

export interface SocialMediaInputFieldsActionProps<T extends keyof Accounts = keyof Accounts>
  extends Omit<SocialMediaInputFieldProps, 'name'> {
  name: T;
  influencerId?: number;
  addButtonProps?: ButtonProps;
  onDelete?: (props: { account: SocialNetworkAccount; socialMedia: SocialAccountType; callback: () => void }) => void;
}

export const SocialMediaInputFieldsAction = <T extends keyof Accounts>({
  name,
  title,
  onDelete,
  socialMedia,
  influencerId,
  addButtonProps,
  ...inputProps
}: SocialMediaInputFieldsActionProps<T>) => {
  const { t } = useTranslation();
  const { watch, control } = useFormContext<SocialAccountsFormValues>();
  const { fields, append, remove, update } = useFieldArray<SocialAccountsFormValues, keyof Accounts>({ name, control });

  const values = watch();
  const accounts = watch(name) as SocialNetworkAccount[];
  const allAccounts: SocialNetworkAccount[] = [
    ...(name !== 'facebookPages' ? [...values.facebookPages] : []),
    ...(name !== 'tiktokAccounts' ? [...values.tiktokAccounts] : []),
    ...(name !== 'youtubeAccounts' ? [...values.youtubeAccounts] : []),
    ...(name !== 'tiktokAccounts' ? [...values.twitterAccounts] : []),
    ...(name !== 'threadsAccounts' ? [...values.threadsAccounts] : []),
    ...(name !== 'instagramAccounts' ? [...values.instagramAccounts] : [])
  ];
  const hasAddAccountButton =
    accounts.length < ADDING_ACCOUNTS_LIMIT &&
    ![SocialAccountType.DOUYIN, SocialAccountType.XHS].includes(socialMedia as SocialAccountType);
  const hasFBAccount = !!values.facebookAccount.username && !values.facebookAccount.isAdding;
  const hasOtherSocialMediaLinkedAccounts = hasFBAccount || allAccounts.some((acc) => acc.username && !acc.isAdding);

  return (
    <div css={{ '& + &': { marginTop: '8px' } }}>
      {fields.map((account, index) => {
        const { id, username, status, isAdding } = account;
        const isFirstAccount = index === 0;
        const hasDeleteOption =
          (status === SocialAccountStatus.SCRAPING || isAdding) &&
          ((hasOtherSocialMediaLinkedAccounts && username && !isAdding) || (accounts.length > 0 && !isFirstAccount));

        const handleDelete = () => {
          if (onDelete) {
            onDelete({
              account,
              socialMedia: socialMedia as SocialAccountType,
              callback: () => (fields.length === 1 ? update(0, defaultSocialNetworkAccount) : remove(index))
            });
          } else {
            remove(index);
          }
        };

        return (
          <SocialMediaInputFieldAction
            {...inputProps}
            key={id}
            account={account}
            socialMedia={socialMedia}
            influencerId={influencerId}
            disabled={!account.isAdding}
            name={`${name}[${index}].username`}
            title={isFirstAccount ? title : undefined}
            onDelete={hasDeleteOption ? handleDelete : undefined}
          />
        );
      })}

      {hasAddAccountButton && (
        <Button
          variant="white"
          title={t('Button.add account')}
          css={{ textTransform: 'capitalize' }}
          prefixIcon={{ icon: 'plus', size: '10px' }}
          onClick={() => append(defaultSocialNetworkAccount)}
          {...addButtonProps}
        />
      )}
    </div>
  );
};
