import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Genders } from '@/graphql';
import { warningYellowImage, signedUpImage } from '@/shared/assets';
import { Anchor, AnchorPropsInternal, TTCMBadge, Tooltip } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useTranslateCountry } from '@/shared/hooks';
import { formatIntNumber, getGenderString } from '@/shared/utils';
import { SpreadButton, SpreadButtonProps } from '../SpreadButton';

export interface InfluencerWidgetInformationProps {
  name: string;
  className?: string;
  age?: number | null;
  isWarning?: boolean;
  gender: Genders | '';
  isSignedUp?: boolean;
  children?: ReactNode;
  isTTCMCreator?: boolean;
  customButton?: ReactNode;
  spreadButtonProps?: SpreadButtonProps;
  country: { id?: string; name?: string };
  profileUrl?: Pick<AnchorPropsInternal, 'href' | 'params' | 'search' | 'target' | 'disabled'>;
}

export const InfluencerWidgetInformation = ({
  age,
  name,
  gender,
  country,
  children,
  isWarning,
  className,
  isSignedUp,
  profileUrl,
  isTTCMCreator,
  spreadButtonProps,
  customButton = null
}: InfluencerWidgetInformationProps) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();

  const info = [
    { label: 'HeaderColumn.Country', value: tc(country.name as string) },
    { label: 'HeaderColumn.Gender', value: t(`Option.${getGenderString(gender as Genders)}`) },
    { label: 'HeaderColumn.Age', value: formatIntNumber(age) }
  ];

  return (
    <div css={styles.infoWrapper} className={className}>
      <div css={styles.nameWrapper}>
        <Anchor target="_blank" label={name} css={styles.name} {...profileUrl} />
        {isSignedUp && (
          <Tooltip
            help={t(
              isWarning ? 'Tooltip.Account is disconnected' : 'Tooltip.This Influencer is Signed Up to AnyCreator'
            )}
          >
            <img
              src={isWarning ? warningYellowImage : signedUpImage}
              css={styles.profileStatus({ isWarning })}
              alt="profile status"
            />
          </Tooltip>
        )}
        {isTTCMCreator ? <TTCMBadge>{t('TTCM')}</TTCMBadge> : null}
        {spreadButtonProps ? <SpreadButton {...spreadButtonProps} css={{ marginLeft: 'auto' }} /> : customButton}
      </div>
      <div css={styles.infoGroup} className="info-group">
        {info.map(({ label, value }) => (
          <div key={label}>
            <div title={t<string>(label)} css={styles.label} className="label">
              {t(label)}
            </div>
            <div title={value} css={styles.value} className="value">
              {value}
            </div>
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

const styles = {
  infoWrapper: css({
    width: '212px',
    marginTop: '4px',
    overflow: 'hidden',
    paddingLeft: '16px',
    paddingRight: '16px',
    boxSizing: 'border-box',
    borderRight: THEME.border.base
  }),
  nameWrapper: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  name: css({
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '18px'
  }),
  label: css({
    color: THEME.text.colors.gray.lv3,
    fontWeight: 'inherit',
    letterSpacing: '0.28px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  value: css({
    color: THEME.text.colors.black.lv1,
    fontWeight: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  infoGroup: css({
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'normal',
    '.label': {
      fontSize: '11px'
    },
    '.value': {
      fontSize: '12px'
    },
    '> div:nth-of-type(1),> div:nth-of-type(2)': {
      flex: '40% 0 0',
      maxWidth: '40%'
    }
  }),
  profileStatus: ({ isWarning }: Pick<InfluencerWidgetInformationProps, 'isWarning'>) =>
    css({
      width: '16px',
      height: '16px',
      alignSelf: 'center',
      margin: '0 4px 0 1px',
      cursor: isWarning ? 'pointer' : 'initial'
    })
};
