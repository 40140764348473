import { useTranslation } from 'react-i18next';
import { TableData } from '@/shared/types';
import { useTableMaxHeight } from '@/shared/hooks';
import { BlackBar, Button, Table } from '@/shared/atoms';
import { MarketplaceAffiliateJoinedInfluencer, MarketplaceDetail, namedOperations } from '@/graphql';
import { useUpdatePostPrices } from '../../hooks';
import { useMarketplaceJoinedInfluencersFilter } from '../MarketplaceJoinedInfluencersFilter';
import { useMarketplaceJoinedInfluencersTable } from './hooks';

export interface MarketplaceJoinedInfluencersListProps extends TableData<MarketplaceAffiliateJoinedInfluencer> {
  marketplace: MarketplaceDetail;
}
export const MarketplaceJoinedInfluencersList = ({
  loading,
  data = [],
  marketplace
}: MarketplaceJoinedInfluencersListProps) => {
  const { t } = useTranslation();
  const { filter } = useMarketplaceJoinedInfluencersFilter();
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();
  const { formState, reset, handleSubmit, rows, columns } = useMarketplaceJoinedInfluencersTable({
    data,
    marketplace
  });
  const { onSubmit, costLoading } = useUpdatePostPrices({
    reset,
    marketplaceId: marketplace.id,
    defaultValues: formState.defaultValues,
    refetchQueries: [namedOperations.Query.MarketplaceAffiliateJoinedInfluencers]
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} css={{ position: 'relative' }}>
      {formState.isDirty ? (
        <BlackBar>
          <Button
            variant="blue"
            loading={costLoading}
            id="skipOutsideClick"
            title={t('Button.Save')}
            type="submit"
            css={{ marginLeft: 'auto', padding: '0 16px' }}
          />
        </BlackBar>
      ) : null}

      <Table
        ref={offsetRef}
        loading={loading}
        maxHeight={defaultTableMaxHeight}
        data={{ rows, columns, rowHeight: '64px' }}
        empty={{ description: filter.keyword ? undefined : t('Nobody joined this campaign yet') }}
        noTopBorderRadius
        stickyHeader
      />
    </form>
  );
};
