import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface SimilarInfluencersButtonProps extends ButtonProps {
  isActive?: boolean;
}

export const SimilarInfluencersButton = ({ isActive, ...restProps }: SimilarInfluencersButtonProps) => {
  const { t } = useTranslation();

  return <Button variant="white" title={t('Similar Influencers')} css={styles.toggleButton(isActive)} {...restProps} />;
};

const styles = {
  toggleButton: (isActive?: boolean) =>
    css({
      width: '150px',
      height: '20px',
      marginTop: '6px',
      fontSize: '11px',
      fontWeight: 600,
      ...(isActive ? { borderColor: THEME.border.colors.blue.lv2, color: THEME.text.colors.blue } : {})
    })
};
