import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { CampaignType } from '@/graphql';
import { PostReportSegmentKeys } from '@/shared/organisms';
import { TikTokCampaignReportTableStatKeys, DEFAULT_TIKTOK_CAMPAIGN_COLUMNS } from './TikTokCampaignReportTable';

type PostReportSegmentStatus = Record<PostReportSegmentKeys, boolean>;

interface TikTokCampaignReportContextType {
  hasTracking: boolean;
  campaignType: CampaignType;
  segmentStatus: PostReportSegmentStatus;
  selectedColumns: TikTokCampaignReportTableStatKeys[];
  setSegmentStatus: Dispatch<SetStateAction<PostReportSegmentStatus>>;
  setSelectedColumns: Dispatch<SetStateAction<TikTokCampaignReportTableStatKeys[]>>;
}

const TikTokCampaignReportContext = createContext<TikTokCampaignReportContextType>(
  {} as TikTokCampaignReportContextType
);

export const useTikTokCampaignReportContext = () => useContext(TikTokCampaignReportContext);

interface InfluencerProviderProps {
  children: React.ReactElement | Array<React.ReactElement>;
}

export const TikTokCampaignReportProvider = ({ children }: InfluencerProviderProps) => {
  const [selectedColumns, setSelectedColumns] = useState<TikTokCampaignReportTableStatKeys[]>(
    DEFAULT_TIKTOK_CAMPAIGN_COLUMNS
  );
  const [segmentStatus, setSegmentStatus] = useState<PostReportSegmentStatus>({
    post: false,
    total: false,
    influencer: false
  });

  return (
    <TikTokCampaignReportContext.Provider
      value={{
        segmentStatus,
        selectedColumns,
        setSegmentStatus,
        setSelectedColumns,
        hasTracking: true, // Tiktok special campaign always has tracking POST by default
        campaignType: CampaignType.TIKTOK_SPECIAL
      }}
    >
      {children}
    </TikTokCampaignReportContext.Provider>
  );
};
