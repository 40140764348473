import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Anchor, ButtonSubmit, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PasswordField } from '@/shared/molecules';
import { PasswordFormValues, PasswordFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<PasswordFormValues>();

  const values = watch();

  return (
    <FormStyle.Wrapper css={{ position: 'relative' }}>
      <h1 css={{ fontSize: '20px', lineHeight: '100%', textAlign: 'center' }}>{t('Set a password and sign in')}</h1>
      <p css={{ fontSize: '14px', lineHeight: '140%', padding: '16px 0', textAlign: 'center' }}>
        <Trans i18nKey="SignUp.Description" components={{ br: <br /> }} />
      </p>

      <PasswordField<PasswordFormKeys> name="password" title={t('Password.Password')} dynamicError required />
      <PasswordField<PasswordFormKeys> name="passwordConfirm" title={t('Password.Confirm Password')} required />

      <ButtonSubmit
        variant="blue"
        loading={isSubmitting}
        title={t('Button.Sign Up')}
        css={{ width: '100%', marginTop: '16px' }}
        disabled={!values.password || !values.passwordConfirm}
      />

      <p css={{ marginTop: '32px', color: THEME.text.colors.gray.lv3, fontSize: '11px', lineHeight: '140%' }}>
        <Trans
          i18nKey="SignInConfirmation"
          components={{
            a1: <Anchor variant="blue" css={{ display: 'inline', lineHeight: 'inherit' }} to="/privacy" />,
            a2: <Anchor variant="blue" css={{ display: 'inline', lineHeight: 'inherit' }} to="/terms" />
          }}
        />
      </p>
    </FormStyle.Wrapper>
  );
};
