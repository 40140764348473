import { ReactNode } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Order } from '@/graphql';

export interface GenericOption<V, L = string> {
  value: V;
  label?: L;
}

export interface Option<V = string, L = string> extends GenericOption<V, L> {
  order?: Order | null;
  gtmId?: string;
  title?: string;
  active?: boolean;
  disabled?: boolean;
  __isNew__?: boolean;
  prefixIcon?: ReactNode;
  submenu?: GenericOption<V>[];
  css?: Interpolation<Theme>;
}

export interface CountryOption<V = string, L = string> extends GenericOption<V, L> {
  currency?: string;
}

export enum ToggleOption {
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

export enum SelectedRangeOption {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This week',
  LAST_WEEK = 'Last week',
  LAST_SEVEN_DAYS = 'Last 7 days',
  LAST_TWO_WEEKS = 'Last two weeks',
  LAST_MONTH = 'Last month',
  THIS_MONTH = 'This month',
  ALL_TIME = 'All time',
  TODAY_TO_MONTH_AGO = 'Last 30 days',
  CUSTOM = 'Custom',
  CLEAR = 'Clear'
}
export enum TtcmStatusOption {
  ALL = 'ALL',
  TTCM = 'TTCM',
  NON_TTCM = 'NON_TTCM'
}
export interface DropdownOption<T = string, L = ReactNode> extends GenericOption<T, L> {
  gtmId?: string;
  active?: boolean;
  css?: Interpolation<Theme>;
  submenu?: Option[];
  menuType?: 'multi-level';
  disabled?: boolean;
}

// Make some optional fields
export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
