import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { PaymentRequestStatus } from '@/graphql';
import { FilterValidations } from '@/shared/validations';
import { getLastDay, getThirtyDaysAgo } from '@/shared/utils';
import { INITIAL_PAGE_NUMBER, LIMIT } from '@/shared/constants';

export const PaymentFilterSchema = z.object({
  keyword: FilterValidations.string(),
  endDate: FilterValidations.string(),
  countryId: FilterValidations.string(),
  startDate: FilterValidations.string(),
  status: FilterValidations.enum(PaymentRequestStatus)
});

export const PaymentsSearchSchema = z.object({
  filter: PaymentFilterSchema.default({
    endDate: getLastDay(),
    startDate: getThirtyDaysAgo()
  }),
  page: z.number().default(INITIAL_PAGE_NUMBER),
  limit: z.number().default(LIMIT)
});

export type PaymentSearchSchemaType = z.infer<typeof PaymentsSearchSchema>;
export type PaymentFilterFormValues = z.infer<typeof PaymentFilterSchema>;
export type PaymentFilterFormKeys = UnionOfKeys<PaymentFilterFormValues>;
