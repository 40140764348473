import { useState } from 'react';
import { Button, FormProvider, Modal } from '@/shared/atoms';
import { useQueryHelper, useToggle } from '@/shared/hooks';
import { wait, namedOperations, useRechargeAdvertiserAccountMutation } from '@/graphql';
import { THEME } from '@/shared/constants';
import { FormAction } from '@/shared/types';
import { getRechargeModalSchema, maskitoParseNumber } from './schema';
import { RechargeModalFormValues, RechargeModalProps } from './types';
import { Template } from './Template';

export const RechargeModal = ({ prepaidBalance, currencyId, className }: RechargeModalProps) => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const { on, setOn, setOff } = useToggle();
  let reset: () => void | undefined;

  const { callRechargeAdvertiserAccount, loading } = useRechargeAdvertiserAccountMutation({
    refetchQueries: [namedOperations.Query.TiktokAdvertiserSummaryData],
    onCompleted: () => {
      wait(500);
      invalidateRouteLoader();
    }
  });

  const handleSubmit: FormAction<RechargeModalFormValues>['onSubmit'] = async ({ amount }, methods) => {
    reset = methods.reset;

    await callRechargeAdvertiserAccount({
      variables: { input: { amount: maskitoParseNumber(amount), redirectUrl: window.location.href } }
    })
      .then(({ data }) => {
        if (data?.rechargeAdvertiserAccount?.ok) {
          // if user billing is OK wi will automatically charge them
          enqueueSnackbar(t('Selected amount recharged successfully'), { variant: 'success' });
          setOff();
          reset();
        } else if (data?.rechargeAdvertiserAccount?.checkoutUrl) {
          // if user has not enough balance or some issue, we need to redirect the to Stripe with callback
          setCheckoutUrl(data.rechargeAdvertiserAccount.checkoutUrl);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        reset();
        setOff();
      });
  };

  return (
    <div className={className}>
      <Button
        variant="blue"
        prefixIcon={{ icon: 'plus', size: '11px' }}
        title={t('Button.Recharge')}
        onClick={setOn}
        css={{ padding: '0 16px' }}
      />
      <Modal
        open={on}
        css={{ width: THEME.modal.size.lv1 }}
        onClose={setOff}
        onAfterClose={() => reset?.()}
        shouldCloseOnOverlayClick
        hasCloseIcon
      >
        <FormProvider<RechargeModalFormValues>
          onSubmit={handleSubmit}
          zodSchema={getRechargeModalSchema()}
          defaultValues={{
            amount: '',
            step: 'input'
          }}
        >
          <Template
            onClose={setOff}
            currencyId={currencyId}
            prepaidBalance={prepaidBalance}
            checkoutUrl={checkoutUrl}
            loading={loading}
          />
        </FormProvider>
      </Modal>
    </div>
  );
};
