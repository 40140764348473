import { useTranslation } from 'react-i18next';
import {
  WarningReason,
  MarketplaceCampaignPostStatus,
  MarketplaceCampaignPostStatusForAdvertiser
} from '@/graphql';
import { Status, StatusProps } from '@/shared/atoms';
import { ColorVariant } from '@/shared/types';
import { getPostWarningReasonStatusProps } from '@/shared/utils';

export interface MarketplacePostStatusProps {
  className?: string;
  warningReason?: WarningReason;
  status: MarketplaceCampaignPostStatus | MarketplaceCampaignPostStatusForAdvertiser;
}

export const MarketplacePostStatus = ({ status, warningReason, className }: MarketplacePostStatusProps) => {
  const { t } = useTranslation();

  const hasReason =
    [MarketplaceCampaignPostStatus.WARNING, MarketplaceCampaignPostStatusForAdvertiser.TRACKING].includes(status) &&
    !!warningReason;
  const statusProps: StatusProps = (() => {
    if (hasReason) {
      const { label, variant } = getPostWarningReasonStatusProps(warningReason);

      return { label: t(label), variant };
    } else {
      switch (status) {
        case MarketplaceCampaignPostStatus.APPROVED:
          return { label: t('Option.Approved'), variant: ColorVariant.LIGHT_GREEN };
        case MarketplaceCampaignPostStatus.EFFECTIVE:
        case MarketplaceCampaignPostStatus.WARNING_SOLVED:
          return { label: t('Option.Effective'), variant: ColorVariant.LIGHT_GREEN };
        case MarketplaceCampaignPostStatus.WARNING:
          return { label: t('Option.Warning'), variant: ColorVariant.LIGHT_RED };
        case MarketplaceCampaignPostStatus.INEFFECTIVE:
          return { label: t('Option.Ineffective'), variant: ColorVariant.LIGHT_GREY };
        case MarketplaceCampaignPostStatus.SHIPPED:
          return { label: t('Option.Shipped'), variant: ColorVariant.LIGHT_YELLOW };
        case MarketplaceCampaignPostStatusForAdvertiser.JOINED:
          return { label: t('Option.Joined'), variant: ColorVariant.LIGHT_BLUE };
        case MarketplaceCampaignPostStatusForAdvertiser.TRACKING:
          return { label: t('Option.Tracking'), variant: ColorVariant.LIGHT_PURPLE };
        case MarketplaceCampaignPostStatusForAdvertiser.DRAFT:
          return { label: t('Option.Reviewing'), variant: ColorVariant.LIGHT_PURPLE };
        case MarketplaceCampaignPostStatusForAdvertiser.DRAFT_REJECTED:
          return { label: t('Option.Feedbacked'), variant: ColorVariant.LIGHT_YELLOW };
        case MarketplaceCampaignPostStatusForAdvertiser.READY_TO_POST:
          return { label: t('Option.Ready to Post'), variant: ColorVariant.LIGHT_GREEN };
        default:
          return { label: '', variant: ColorVariant.DARK_GREY };
      }
    }
  })();

  return <Status {...statusProps} className={className} css={{ fontSize: '10px', height: '16px' }} />;
};
