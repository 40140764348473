import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerProfileForEngagementProposal.graphql';
import { Query, QueryinfluencerProfileForEngagementProposalArgs } from '../../__generated__/globalTypes';

export const useInfluencerProfileForEngagementProposalQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerProfileForEngagementProposalArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerProfileForEngagementProposalArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerProfileForEngagementProposalLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerProfileForEngagementProposalArgs>
): QueryResult<Query, QueryinfluencerProfileForEngagementProposalArgs> & {
  getInfluencerProfileForEngagementProposal: LazyQueryExecFunction<
    Query,
    QueryinfluencerProfileForEngagementProposalArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerProfileForEngagementProposal, result] = useLazyQuery<
    Query,
    QueryinfluencerProfileForEngagementProposalArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerProfileForEngagementProposal, ...result };
};

export const useInfluencerProfileForEngagementProposalPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerProfileForEngagementProposal = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerProfileForEngagementProposalArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerProfileForEngagementProposalArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerProfileForEngagementProposal };
};
