import { useTranslation } from 'react-i18next';

import { SocialAccountType, useWordCloudListQuery } from '@/graphql';
import { NoDataContentWrapperProps, RenderDataWithFallback } from '@/shared/atoms';
import { SectionWithHeader, TagsChart, TagsChartProps } from '@/shared/molecules';

import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerProfileTagsProps extends Pick<NoDataContentWrapperProps, 'wrapperText' | 'wrapperLink'> {
  className?: string;
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerProfileTags = ({
  className,
  influencerId,
  wrapperLink,
  wrapperText,
  socialAccount: { id: socialAccountId, type }
}: InfluencerProfileTagsProps) => {
  const { t } = useTranslation();
  const { data, loading } = useWordCloudListQuery({
    variables: {
      influencerId,
      socialAccountId,
      socialMedia: type as SocialAccountType
    }
  });

  const tags: TagsChartProps['data'] =
    data?.wordCloudList?.map(({ hashtag, count }) => ({
      weight: count,
      name: hashtag.length > 15 ? `${hashtag.substring(0, 15)}...` : hashtag
    })) || [];

  return (
    <div css={{ marginTop: '16px' }} className={className}>
      <RenderDataWithFallback loading={loading} loadingProps={{ css: { height: '320px' } }}>
        <SectionWithHeader
          title={t('Tag Cloud (Post)')}
          css={{ '.section-header': { borderBottom: 'none' } }}
          prefixIcon={{ icon: 'align-left-two-tones', size: '16px' }}
        >
          <TagsChart
            data={tags}
            height="250px"
            wrapperLink={wrapperLink}
            wrapperText={wrapperText || t('General.Unable to retrieve data')}
          />
        </SectionWithHeader>
      </RenderDataWithFallback>
    </div>
  );
};
