import { Options } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { CommonSelectProps } from '../types';
import { SelectAll } from './SelectAll';
import { MenuList, MenuListProps } from './MenuList';

type MenuListMultipleProps = MenuListProps &
  Pick<CommonSelectProps<any, any, true>, 'hasSelectAll' | 'onChange' | 'menuWidth' | 'menuHeight' | 'selectAllProps'>;

export const MenuListMultiple = ({
  onChange,
  menuWidth,
  menuHeight,
  hasSelectAll,
  selectAllProps,
  ...menuListProps
}: MenuListMultipleProps) => {
  const { t } = useTranslation();

  const value = menuListProps.getValue();
  const availableOptions = menuListProps.options.filter((option) => !option.disabled) as Options<any>[];

  return (
    <div>
      {!!hasSelectAll && (
        <SelectAll
          {...selectAllProps}
          checked={value.length > 0}
          disabled={menuListProps.selectProps.isDisabled}
          indeterminate={value.length !== availableOptions.length}
          onClick={() => onChange?.(value.length === availableOptions.length ? [] : availableOptions)}
        />
      )}

      <MenuList {...menuListProps} menuWidth={menuWidth} menuHeight={menuHeight} />

      <div css={{ display: 'flex', borderTop: THEME.border.base, padding: '8px 12px', boxSizing: 'border-box' }}>
        <Button
          variant="blue"
          title={t('Button.Apply')}
          onClick={menuListProps.selectProps.onMenuClose}
          css={{ marginLeft: 'auto', height: THEME.button.base.height.lv1, minWidth: '67px' }}
        />
      </div>
    </div>
  );
};
