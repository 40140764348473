import { InstagramFeedPost } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerInstagramAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType, SelectColumnsAction, TableData } from '@/shared/types';
import { useAnalyticsInstagramPostsListTable } from './hooks';

export type AnalyticsInstagramPostsListProps = TableData<InstagramFeedPost> &
  SelectColumnsAction<keyof InstagramFeedPost> & {
    isComparePage?: boolean;
  };

export const AnalyticsInstagramPostsList = ({
  loading,
  data = [],
  isComparePage,
  selectedColumns,
  setSelectedColumns
}: AnalyticsInstagramPostsListProps) => {
  const { rows, columns } = useAnalyticsInstagramPostsListTable({
    data,
    isComparePage,
    selectedColumns,
    setSelectedColumns
  });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerInstagramAnalyticsPostDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id) }))}
        isAnalytics
      />
    </>
  );
};
