import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeAnalyticsRelatedPosts.graphql';
import { Query, QueryyoutubeAnalyticsRelatedPostsArgs } from '../../__generated__/globalTypes';

export const useYoutubeAnalyticsRelatedPostsQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeAnalyticsRelatedPostsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeAnalyticsRelatedPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeAnalyticsRelatedPostsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeAnalyticsRelatedPostsArgs>
): QueryResult<Query, QueryyoutubeAnalyticsRelatedPostsArgs> & {
  getYoutubeAnalyticsRelatedPosts: LazyQueryExecFunction<Query, QueryyoutubeAnalyticsRelatedPostsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeAnalyticsRelatedPosts, result] = useLazyQuery<Query, QueryyoutubeAnalyticsRelatedPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeAnalyticsRelatedPosts, ...result };
};

export const useYoutubeAnalyticsRelatedPostsPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeAnalyticsRelatedPosts = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeAnalyticsRelatedPostsArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeAnalyticsRelatedPostsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeAnalyticsRelatedPosts };
};
