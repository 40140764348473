import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { checkIconImage } from '@/shared/assets';
import {
  Icon,
  Modal,
  ModalTitle,
  ModalProps,
  ModalContent,
  SocialButton,
  ModalFooterActions,
  SocialLinkedAccounts,
  SocialLinkedAccountsProps
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { SupportedSocialMediaType } from '@/shared/types';

export interface SocialReconnectModalProps extends ModalProps {
  reconnected?: boolean;
  reconnecting?: boolean;
  onReconnect?: () => void;
  accounts?: SocialLinkedAccountsProps['accounts'];
  socialMedia?: keyof typeof SupportedSocialMediaType;
}

export const SocialReconnectModal = ({
  open,
  onClose,
  reconnected,
  onReconnect,
  socialMedia,
  hasCloseIcon,
  reconnecting,
  accounts = []
}: SocialReconnectModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} hasCloseIcon={hasCloseIcon} css={{ width: 'max-content', maxWidth: '600px' }}>
      <ModalContent>
        <ModalTitle css={{ display: 'flex', alignItems: 'center' }}>
          {!reconnected ? (
            <>
              <Icon size={20} icon="warning" color="#ffb63d" css={{ marginRight: '8px' }} />
              {t('Dialog.Please reconnect account')}
            </>
          ) : (
            t('Dialog.Successfully connected')
          )}
        </ModalTitle>

        {!reconnected ? <p css={{ marginTop: '16px' }}>{t('PleaseReconnectYourAccount')}</p> : null}

        <div css={styles.reconnectWrapper}>
          <SocialLinkedAccounts accounts={accounts} />

          {!reconnected && socialMedia && onReconnect ? (
            <SocialButton
              onClick={onReconnect}
              socialMedia={socialMedia}
              title={t('Button.Reconnect')}
              loading={{ status: reconnecting, showIcon: true }}
              css={{ marginLeft: 'auto', padding: '0 16px', textTransform: 'uppercase' }}
            />
          ) : (
            <img alt="" src={checkIconImage} css={{ marginLeft: 'auto' }} />
          )}
        </div>
      </ModalContent>

      <ModalFooterActions
        {...(!reconnected
          ? { cancelButtonProps: { onClick: onClose } }
          : { submitButtonProps: { onClick: onClose, title: t('Button.Save') } })}
      />
    </Modal>
  );
};
const styles = {
  reconnectWrapper: css({
    display: 'flex',
    padding: '16px',
    marginTop: '24px',
    minWidth: '400px',
    borderRadius: '5px',
    alignItems: 'center',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  })
};
