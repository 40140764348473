import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useUserRoles } from '@/auth';
import { InfluencerNewProfileHeaderV2, namedOperations, useUpdateInternalMemoMutation } from '@/graphql';
import { Button, Label, Textarea } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';

export interface InfluencerProfileInternalMemoProps {
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerProfileInternalMemo = ({
  influencerProfile: { id, internalMemo }
}: InfluencerProfileInternalMemoProps) => {
  const [value, setValue] = useState('');
  const { isAdminStaff } = useUserRoles();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callUpdateInternalMemo, loading } = useUpdateInternalMemoMutation({
    refetchQueries: [namedOperations.Query.InfluencerProfileV2]
  });

  useEffect(() => {
    setValue(internalMemo || '');
  }, [internalMemo]);

  const handleSave = async () => {
    try {
      await callUpdateInternalMemo({ variables: { input: { id, internalMemo: value } } });
      enqueueSnackbar(t('Internal Memo updated successfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <div css={styles.wrapper}>
      <div css={{ flex: 1 }}>
        <Label
          title={
            <>
              <span css={{ fontWeight: 600 }}>{t('TextForm.Internal memo')}</span>
              &nbsp;
              <span css={{ color: THEME.text.colors.black.lv1 }}>{t<string>('TextForm.Internal Memo Note')}</span>
            </>
          }
          help={t(
            isAdminStaff ? 'Tooltip.Internal Memo Help Admin&Staff' : 'Tooltip.Internal Memo Help External Users'
          )}
        />
        <Textarea value={value} css={{ minHeight: '52px' }} onChange={(e) => setValue(e.target.value)} heightAuto />
      </div>

      <Button
        variant="blue"
        loading={loading}
        onClick={handleSave}
        title={t('Button.Save')}
        css={{ minWidth: '78px' }}
        disabled={!value || internalMemo === value}
      />
    </div>
  );
};

const styles = {
  wrapper: css({
    gap: '16px',
    display: 'flex',
    borderRadius: 3,
    marginTop: '16px',
    padding: '20px 16px',
    alignItems: 'flex-end',
    boxSizing: 'border-box',
    boxShadow: THEME.shadows.boxShadow,
    backgroundColor: THEME.background.colors.white
  })
};
