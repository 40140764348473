export const HELP_PAGE_LINK = {
  EN: {
    DEFAULT: 'https://anymind-group.gitbook.io/english-1/',
    PARTNER: 'https://anymind-group.gitbook.io/english-2/',
    ADVERTISER: 'https://anymind-group.gitbook.io/anytag-3/v/english/'
  },
  JA: {
    DEFAULT: 'https://anymind-group.gitbook.io/anytag-1/',
    PARTNER: 'https://anymind-group.gitbook.io/anytag-2/',
    ADVERTISER: 'https://anymind-group.gitbook.io/anytag-3'
  }
};

/**
 * @description temporal link for tiktok onboarding, will be replaced with real link after feature tested on stg
 */
export const TIKTOK_HELP_LINK = {
  learnMoreAboutThisService: 'https://anymind-group.gitbook.io/anytag-self-serve-1/anytag-self-serve-1',
  learnMoreAboutPlans: 'https://anymind-group.gitbook.io/anytag-self-serve-1/undefined-1/undefined',
  needHelp: 'https://anymind-group.gitbook.io/anytag-self-serve-1/undefined/tiktok',
  needHelpBC: 'https://anymind-group.gitbook.io/anytag-self-serve-1/undefined/tiktok/tiktok-business-center',
  helpCenter: 'https://anymind-group.gitbook.io/anytag-self-serve-1',
  howToCreateCampaign: 'https://anymind-group.gitbook.io/anytag-self-serve-1/anytag-self-serve-2/tiktok-tiktok-ads'
};
