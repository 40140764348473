import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddressAndPaymentInfoPermissions, useUserRoles } from '@/auth';
import { SocialAccountStatus } from '@/graphql';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import {
  NameField,
  EmailField,
  PostsPrice,
  ReasonField,
  RegionField,
  CountryField,
  GendersField,
  InternalMemo,
  IntroduceField,
  ProAccountField,
  NoticeSignUpUrl,
  NoticeConnectUrl,
  PhoneNumberField,
  DateOfBirthField,
  AccountTypeField,
  NotificationField,
  InfluencerManagers,
  AddressInformation,
  PaymentInformation,
  CampaignDisplayField,
  BrandContentPermissions,
  NoticeUseChromeExtension,
  TalentAgencyPartnerField,
  InfluencerCategoriesField
} from '../shared';
import { SocialAccounts } from './SocialAccounts';
import { InfluencerFormValues } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const { hidePaymentAndAddressWidgets } = useAddressAndPaymentInfoPermissions();
  const { isPartner, isAdmin, isAdminStaff, isTalentAgencyPartner } = useUserRoles();
  const {
    watch,
    formState: { defaultValues, isSubmitting }
  } = useFormContext<InfluencerFormValues>();

  const values = watch();
  const influencerId = values?.id;
  const isEditMode = !!influencerId;
  const isSignedUp = values.status === SocialAccountStatus.SIGNED_UP;
  const hasSelectedAgency = !!defaultValues?.partnerIds?.length || !!defaultValues?.talentAgencyIds?.length;
  const hasTalentAgencyField = isPartner ? !hasSelectedAgency || !!values.talentAgencyOrPartnerIds.length : true;

  return (
    <div css={{ margin: '0 auto' }}>
      {isPartner ? isEditMode ? <NoticeConnectUrl /> : <NoticeSignUpUrl /> : <NoticeUseChromeExtension />}

      <div css={{ display: 'flex', gap: '24px' }}>
        <FormStyle.Wrapper css={{ flex: 1, height: 'max-content' }} hasBorder>
          <div css={{ padding: '24px' }}>
            <SocialAccounts influencerId={influencerId} />

            <hr css={{ height: '1px', background: THEME.border.colors.gray.lv1, marginTop: '24px' }} />

            <FormStyle.SectionTitle index={2} css={{ margin: '16px 0 0' }} label={t('Influencer Account')} />

            <NameField required />

            <FormStyle.FieldsGroup itemsPerRow={2}>
              <InfluencerCategoriesField required />
              <GendersField required />
              <CountryField disabled={isSignedUp} required />
              <RegionField help={t('Tooltip.Region')} title={t('Selector.Region')} countryId={values.countryId} />
              <EmailField />
              <PhoneNumberField />
            </FormStyle.FieldsGroup>

            <DateOfBirthField />
            {hasTalentAgencyField ? (
              <TalentAgencyPartnerField disabled={isPartner ? hasSelectedAgency : !isAdmin} />
            ) : null}
            <IntroduceField />
            <ReasonField />
            <NotificationField />
            <AccountTypeField disabled={isTalentAgencyPartner} required />

            {isSignedUp && isEditMode ? (
              <>
                <CampaignDisplayField required />
                <ProAccountField disabled={!isAdmin} />
              </>
            ) : null}
          </div>

          <FormStyle.ButtonSubmitWrapper>
            <FormStyle.ButtonSubmit
              title={t('Button.Save')}
              loading={{ status: isSubmitting, title: t('Button.Saving') }}
            />
          </FormStyle.ButtonSubmitWrapper>
        </FormStyle.Wrapper>

        <div css={{ width: '328px' }}>
          <InternalMemo hasBorder />
          <PostsPrice influencerId={influencerId} css={{ marginTop: '24px' }} hasBorder />

          {isAdminStaff ? (
            <>
              <InfluencerManagers css={{ marginTop: '24px' }} hasBorder />

              {isEditMode && !hidePaymentAndAddressWidgets ? (
                <>
                  {isAdmin ? (
                    <PaymentInformation influencerId={influencerId} css={{ marginTop: '24px' }} hasBorder />
                  ) : null}

                  <AddressInformation influencerId={influencerId} css={{ marginTop: '24px' }} hasBorder />
                </>
              ) : null}

              {isEditMode && values.instagramAccounts.length ? (
                <BrandContentPermissions css={{ marginTop: '24px' }} influencerId={influencerId} hasBorder />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
