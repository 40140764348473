import { format as formatDate, getDaysInMonth } from 'date-fns';
import range from 'lodash/range';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { SingleSelector } from '../Selector';

export interface YearMonthDayPickerProps {
  format: string;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  className?: string;
  hasError?: boolean;
  value?: Date | string;
  onChange?: (date: string) => void;
}

export const YearMonthDayPicker = ({
  disabled,
  format,
  maxDate,
  minDate,
  value,
  onChange,
  className,
  hasError
}: YearMonthDayPickerProps) => {
  const { t } = useTranslation();
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('');
  const today = new Date();
  const currentYear = today.getFullYear();

  const leftPad = (val: number) => val.toString().padStart(2, '0');

  const days = useMemo(() => {
    if (selectedYear && selectedMonth) {
      const dayList = range(getDaysInMonth(new Date(Number(selectedYear), Number(selectedMonth) - 1)))
        .filter((day) => {
          let status = true;

          // to check available days if selected minDate/maxDate year & month
          if (
            minDate &&
            minDate.getFullYear() === Number(selectedYear) &&
            minDate.getMonth() === Number(selectedMonth) - 1
          ) {
            status = minDate.getDate() <= day + 1;
          }
          if (
            status &&
            maxDate &&
            maxDate.getFullYear() === Number(selectedYear) &&
            maxDate.getMonth() === Number(selectedMonth) - 1
          ) {
            status = maxDate.getDate() > day;
          }

          return status;
        })
        .map((day) => {
          const val = leftPad(day + 1);

          return { label: val, value: val };
        });

      const isInSelection = dayList.find((day) => day.value === selectedDay);
      if (!isInSelection) {
        setSelectedDay('');
      }

      return dayList;
    }

    return [];
  }, [selectedYear, selectedMonth]);

  const months = useMemo(() => {
    if (selectedYear) {
      const monthList = range(12)
        .filter((month) => {
          let status = true;

          // to check available months if selected minDate/maxDate year
          if (minDate && minDate.getFullYear() === Number(selectedYear)) {
            status = month >= minDate.getMonth();
          }
          if (status && maxDate && maxDate.getFullYear() === Number(selectedYear)) {
            status = month <= maxDate.getMonth();
          }

          return status;
        })
        .map((month) => {
          const val = leftPad(month + 1);

          return { label: val, value: val };
        });

      const isInSelection = monthList.find((month) => month.value === selectedMonth);
      if (!isInSelection) {
        setSelectedDay('');
        setSelectedMonth('');
      }

      return monthList;
    }

    return [];
  }, [selectedYear]);

  const years = range(currentYear - 70, currentYear + 70)
    .filter((year) => {
      let status = true;

      // to check available years between minDate & maxDate
      if (minDate) {
        status = minDate.getFullYear() <= year;
      }
      if (status && maxDate) {
        status = maxDate.getFullYear() >= year;
      }

      return status;
    })
    .map((year) => {
      const val = year.toString();

      return { label: val, value: val };
    });

  useEffect(() => {
    const isValidateDate = value && new Date(value);
    if (isValidateDate) {
      setSelectedDay(leftPad(new Date(value!).getDate()));
      setSelectedMonth(leftPad(new Date(value!).getMonth() + 1));
      setSelectedYear(new Date(value!).getFullYear().toString());
    }
  }, []);

  useEffect(() => {
    let date;

    if (selectedYear && selectedMonth && selectedDay) {
      const selectedDate = new Date([selectedYear, selectedMonth, selectedDay].join('-'));
      date = format ? formatDate(selectedDate, format) : selectedDate.toString();
    } else {
      date = '';
    }

    if (value !== date) {
      onChange?.(date);
    }
  }, [value, selectedYear, selectedMonth, selectedDay]);

  return (
    <div css={{ display: 'flex', gap: '8px' }} className={className}>
      <FormStyle.FieldWrapper css={{ padding: 0 }}>
        <SingleSelector
          disabled={disabled}
          name="year"
          options={years}
          placeholder={t<string>('Selector.Year')}
          onChange={(val) => setSelectedYear(val)}
          value={selectedYear}
          hasError={hasError}
        />
      </FormStyle.FieldWrapper>

      <FormStyle.FieldWrapper css={{ padding: 0 }}>
        <SingleSelector
          disabled={disabled}
          name="month"
          options={months}
          placeholder={t<string>('Selector.Month')}
          onChange={(val) => setSelectedMonth(val)}
          value={selectedMonth}
          hasError={hasError}
        />
      </FormStyle.FieldWrapper>

      <FormStyle.FieldWrapper css={{ padding: 0 }}>
        <SingleSelector
          disabled={disabled}
          name="day"
          options={days}
          placeholder={t<string>('Selector.Day')}
          onChange={(val) => setSelectedDay(val)}
          value={selectedDay}
          hasError={hasError}
        />
      </FormStyle.FieldWrapper>
    </div>
  );
};
