import { useGenerateAgencyLogoSignedUrlPromiseQuery } from '@/graphql';
import { DragAndDropProps, DragAndDrop } from '@/shared/atoms';

export type UploadAgencyLogoProps = Omit<DragAndDropProps, 'generateSignedUrl'>;

export const UploadAgencyLogo = (props: UploadAgencyLogoProps) => {
  const { getGenerateAgencyLogoSignedUrl } = useGenerateAgencyLogoSignedUrlPromiseQuery();

  const fetchFileUrl = async (fileNames: string[]) => {
    const { data } = await getGenerateAgencyLogoSignedUrl({ variables: { fileNames } });

    return data?.generateAgencyLogoSignedUrl.fileUploadUrls || null;
  };

  return <DragAndDrop generateSignedUrl={fetchFileUrl} {...props} />;
};
