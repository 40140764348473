import { Outlet, useLoaderData } from '@tanstack/react-router';
import { useEffect } from 'react';
import { THEME } from '@/shared/constants';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useCustomHeader, useQueryHelper } from '@/shared/hooks';
import {
  TikTokCampaignTabs,
  TikTokCampaignNotice,
  TikTokCampaignHeader,
  useTikTokCampaignRoutes
} from '@/shared/organisms';
import { TikTokRouter } from '../../../app/_private-routes/tiktok';
import { TikTokCampaignDetailsProvider } from './TikTokCampaignDetailsContext';

export const TikTokCampaignDetails = () => {
  useCustomHeader();
  const { getTikTokCampaignsListRoute } = useTikTokCampaignRoutes();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { loading, networkStatus, errorMessage, tiktokSpecialCampaign } = useLoaderData({
    from: '/_private-routes/tiktok/campaigns/$id'
  }) as Awaited<ReturnType<(typeof TikTokRouter)['/_private-routes/tiktok/campaigns/$id']['1']['loader']>>;

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(t(errorMessage), { variant: 'error' });
      navigate({ to: getTikTokCampaignsListRoute() });
    }
  }, [errorMessage]);

  return (
    <RenderDataWithFallback loading={loading && networkStatus === 1}>
      <div css={{ width: THEME.container.details.mkpWidth, margin: '0 auto' }}>
        {tiktokSpecialCampaign && (
          <TikTokCampaignDetailsProvider value={{ tiktokSpecialCampaign }}>
            <TikTokCampaignHeader tiktokSpecialCampaign={tiktokSpecialCampaign} />
            <TikTokCampaignNotice css={{ marginBottom: '8px' }} tiktokSpecialCampaign={tiktokSpecialCampaign} />
            <TikTokCampaignTabs tiktokSpecialCampaign={tiktokSpecialCampaign} />
            <Outlet />
          </TikTokCampaignDetailsProvider>
        )}
      </div>
    </RenderDataWithFallback>
  );
};
