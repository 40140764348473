import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/HubspotDealsList.graphql';
import { Query, QueryhubspotDealsListArgs } from '../../__generated__/globalTypes';

export const useHubspotDealsListQuery = (options?: QueryHookOptions<Query, QueryhubspotDealsListArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryhubspotDealsListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useHubspotDealsListLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryhubspotDealsListArgs>
): QueryResult<Query, QueryhubspotDealsListArgs> & {
  getHubspotDealsList: LazyQueryExecFunction<Query, QueryhubspotDealsListArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getHubspotDealsList, result] = useLazyQuery<Query, QueryhubspotDealsListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getHubspotDealsList, ...result };
};

export const useHubspotDealsListPromiseQuery = () => {
  const client = useApolloClient();

  const getHubspotDealsList = useCallback(
    (options?: Omit<QueryOptions<QueryhubspotDealsListArgs, Query>, 'query'>) =>
      client.query<Query, QueryhubspotDealsListArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getHubspotDealsList };
};
