import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { AllInfluencersProps } from './schemaTypes';
import { AllInfluencersSort } from './AllInfluencersSort';
import { AllInfluencersList } from './AllInfluencersList';
import { AllInfluencersFilter } from './AllInfluencersFilter';
import { AllInfluencersProvider } from './AllInfluencersContext';

export const AllInfluencers = (props: AllInfluencersProps) => (
  <AllInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <AllInfluencersFilter />
      <AllInfluencersSort />
    </InfluencersFilterWrapper>

    <AllInfluencersList />
  </AllInfluencersProvider>
);

export * from './schemaTypes';
export * from './AllInfluencersFilter';
export * from './AllInfluencersSort';
export * from './AllInfluencersList';
