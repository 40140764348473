import { useUserRoles } from '@/auth';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import {
  Icon,
  Prompt,
  ChatBox,
  ChatBoxRnd,
  ChatAIButton,
  ChatBoxInput,
  ChatBoxHeader,
  ChatBoxErrors,
  ChatBoxContent,
  ChatBoxMessagesList
} from '@/shared/atoms';
import {
  useInfluencerAISearchPathnames,
  useInitInfluencerAISearchWidget,
  useInfluencerAISearchUploadFiles
} from './hook';

export const InfluencerAISearchWidget = () => {
  const { isAdminStaff } = useUserRoles();
  const warningModal = useToggleState();
  const { t, navigate } = useQueryHelper();
  const widgetState = useInitInfluencerAISearchWidget();
  const { supportedInfluencerSearchPaths } = useInfluencerAISearchPathnames();
  const { multiple, accept, uploading, handleUploadFiles } = useInfluencerAISearchUploadFiles(widgetState);

  // https://bitbucket.org/adasiaholdings/casting_asia_django/pull-requests/5649/chat-ai-enabled-only-for-admin?link_source=email
  // https://adasiaholdings.atlassian.net/browse/AT-6248
  if (!isAdminStaff) {
    return null;
  }

  const {
    messages,
    isOpening,
    openChatBox,
    closeChatBox,
    chatBoxError,
    refreshChatBox,
    addNewMessages,
    isSupportedSNS,
    regenerateMessage,
    regenerateResponse,
    resetCurrentSearchParams
  } = widgetState;

  return (
    <>
      <ChatBoxRnd id="chatbox-influencer-ai-search" resizable={isOpening} css={{ borderRadius: '8px' }}>
        {({ draggedElementProps }) =>
          // Use inline style to avoid rerender className multiple times when dragging
          isOpening ? (
            <ChatBox uploadFileConfigs={{ accept, multiple }}>
              <ChatBoxHeader
                onClose={warningModal.open}
                onRefresh={async () => {
                  await refreshChatBox();
                  resetCurrentSearchParams();
                }}
                {...draggedElementProps}
              >
                <div css={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Icon icon="chat-bot" size={24} />
                  <p>{t('Search by AI')}</p>
                </div>
              </ChatBoxHeader>

              <ChatBoxContent>
                {chatBoxError ? (
                  <ChatBoxErrors {...chatBoxError} onRegenerateResponse={regenerateResponse} />
                ) : (
                  <ChatBoxMessagesList messages={messages} onRegenerate={regenerateMessage} />
                )}
              </ChatBoxContent>

              <ChatBoxInput
                loading={uploading}
                onSubmit={({ message, files }, callback) => {
                  // Don't combine 2 conditions below cause make wrong display text & images position
                  if (files) {
                    handleUploadFiles(files).then((fileUrls) => {
                      const imageUrl = fileUrls?.at(0); // currently we only support upload 1 image

                      addNewMessages([
                        {
                          text: message,
                          position: 'right',
                          file: imageUrl ? { type: 'image', url: imageUrl } : undefined
                        }
                      ]);

                      callback();
                    });
                  } else if (message) {
                    addNewMessages([{ position: 'right', text: message }]);
                    callback();
                  }
                }}
              />
            </ChatBox>
          ) : (
            <ChatAIButton
              onClick={openChatBox}
              disabled={!isSupportedSNS}
              tooltip={{ help: !isSupportedSNS ? t('chatAiButtonDisabledHelp') : undefined }}
              {...draggedElementProps}
            />
          )
        }
      </ChatBoxRnd>

      <Prompt
        open={warningModal.status} // Open prompt manually
        when={!!messages.length && isOpening} // Open prompt automatically when leave the page
        css={{ maxWidth: THEME.modal.size.lv1 }}
        limitPaths={supportedInfluencerSearchPaths} // Only trigger prompt when next leaving location matched paths
        title={t('Are you sure to close the chat')}
        cancelButtonProps={{ onClick: warningModal.close }}
        submitButtonProps={({ next, reset }) => ({
          variant: 'red',
          title: t<string>('Button.Sure'),
          onClick: async () => {
            await closeChatBox(); // Close chat box - delete chat thread

            // Case: Close chat box and stay at current page
            if (!next) {
              resetCurrentSearchParams();
            } else {
              // Case: Leave the page
              // Custom Prompt location to reset all search params if navigate to another tab while using chatbox
              // https://adasiaholdings.atlassian.net/browse/AT-5990
              navigate({ to: next.pathname });
              reset();
            }

            warningModal.close();
          }
        })}
      >
        <p css={{ marginTop: '24px', fontSize: '14px', lineHeight: '140%' }}>
          {t('It will clear all AI filters and chat history')}
        </p>
      </Prompt>
    </>
  );
};
