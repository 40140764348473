import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagementStatus.graphql';
import { Mutation, MutationupdateEngagementStatusArgs } from '../../__generated__/globalTypes';

export const useUpdateEngagementStatusMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateEngagementStatusArgs>
) => {
  const [callUpdateEngagementStatus, result] = useMutation<Mutation, MutationupdateEngagementStatusArgs>(
    MUTATION,
    options
  );

  return { callUpdateEngagementStatus, ...result };
};

export type UpdateEngagementStatusMutationFunction = Mutation['updateEngagementStatus'];
