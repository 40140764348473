import { Table } from '@/shared/atoms';
import { InfluencerYoutubeAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useAnalyticsYoutubeCompareVideosListTable } from './hooks';
import { AnalyticsYoutubeCompareVideosType } from './types';

export interface AnalyticsYoutubeCompareVideosListProps extends TableData<AnalyticsYoutubeCompareVideosType> {
  mainSocialAccountId?: number | null;
}

export const AnalyticsYoutubeCompareVideosList = ({
  loading,
  data = [],
  mainSocialAccountId
}: AnalyticsYoutubeCompareVideosListProps) => {
  const { rows, columns } = useAnalyticsYoutubeCompareVideosListTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerYoutubeAnalyticsPostDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId }))}
        isAnalytics
      />
    </>
  );
};
