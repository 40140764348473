import * as assets from '@/shared/assets';

const AssetPreview = () => {
  const assetEntries = Object.entries(assets).filter(
    ([key, value]) =>
      typeof value === 'string' &&
      (value.endsWith('.svg') || value.endsWith('.png') || value.endsWith('.jpg') || value.endsWith('.gif'))
  );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
        gap: '20px',
        padding: '20px'
      }}
    >
      {assetEntries.map(([name, Asset]) => (
        <div key={name} style={{ textAlign: 'center' }}>
          <img src={Asset as string} alt={name} style={{ maxWidth: '100%', height: 'auto' }} />
          <p>{name}</p>
        </div>
      ))}
    </div>
  );
};

export default AssetPreview;
