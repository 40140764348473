import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { SC } from '../StyledComponents';
import { PostInsightFormKeys } from '../types';

export const PostInsightDouyin = () => {
  const { t } = useTranslation();

  return (
    <SC.SectionWrapper css={{ borderBottom: 'none' }}>
      <SC.SectionTitle>{t('Engagements')}</SC.SectionTitle>

      <FormStyle.FieldsGroup itemsPerRow={4} css={{ paddingBottom: '24px' }}>
        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="followersCount"
          placeholder="10000000"
          title={t('TextForm.Followers')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="likes"
          title={t('TextForm.Likes')}
          placeholder="10000000"
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="comments"
          placeholder="10000000"
          title={t('TextForm.Comments')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="shares"
          placeholder="10000000"
          title={t('TextForm.Shares')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="saves"
          placeholder="10000000"
          title={t('TextForm.Saves')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="views"
          title={t('TextForm.Plays')}
          placeholder="10000000"
        />
      </FormStyle.FieldsGroup>
    </SC.SectionWrapper>
  );
};
