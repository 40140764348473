import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { MkpFormFields } from '@/shared/organisms';
import { TikTokCampaignDisabledFieldsType, TikTokCampaignFormKeys } from '../types';

interface CampaignInformationProps {
  disabledFields: TikTokCampaignDisabledFieldsType;
}
export const CampaignInformation = ({ disabledFields }: CampaignInformationProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ padding: '24px' }}>
      <FormStyle.SectionTitle index={2} label={t('Campaign Detail')} />
      <MkpFormFields.TitleField<TikTokCampaignFormKeys> name="title" disabled={disabledFields.title} required />
      <MkpFormFields.ServiceInformationField<TikTokCampaignFormKeys>
        name="serviceInformation"
        disabled={disabledFields.serviceInformation}
        required
      />
      <MkpFormFields.PostRequirementField<TikTokCampaignFormKeys>
        name="requirement"
        disabled={disabledFields.requirement}
        required
      />
      <MkpFormFields.ThumbnailUploadField<TikTokCampaignFormKeys>
        name="thumbnails"
        disabled={disabledFields.thumbnails}
        required
      />
      <MkpFormFields.HashtagField<TikTokCampaignFormKeys> name="hashtags" disabled={disabledFields.hashtags} required />
      <MkpFormFields.MaterialUploadField<TikTokCampaignFormKeys>
        urlFieldName="sampleUrl"
        materialsFieldName="materials"
        disabled={disabledFields.materials}
      />
      <MkpFormFields.ServiceUrlField<TikTokCampaignFormKeys> name="productUrl" disabled={disabledFields.productUrl} />
      <MkpFormFields.CampaignMaterialLinkField<TikTokCampaignFormKeys>
        name="materialUrl"
        disabled={disabledFields.materialUrl}
      />
    </div>
  );
};
