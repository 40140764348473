import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/SponsoredPosts.graphql';
import { Query, QuerysponsoredPostsArgs } from '../../__generated__/globalTypes';

export const useSponsoredPostsQuery = (options?: QueryHookOptions<Query, QuerysponsoredPostsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerysponsoredPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useSponsoredPostsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerysponsoredPostsArgs>
): QueryResult<Query, QuerysponsoredPostsArgs> & {
  getSponsoredPosts: LazyQueryExecFunction<Query, QuerysponsoredPostsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getSponsoredPosts, result] = useLazyQuery<Query, QuerysponsoredPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getSponsoredPosts, ...result };
};

export const useSponsoredPostsPromiseQuery = () => {
  const client = useApolloClient();

  const getSponsoredPosts = useCallback(
    (options?: Omit<QueryOptions<QuerysponsoredPostsArgs, Query>, 'query'>) =>
      client.query<Query, QuerysponsoredPostsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getSponsoredPosts };
};
