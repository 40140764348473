import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerProfileAudience.graphql';
import { Query, QueryinfluencerProfileAudienceArgs } from '../../__generated__/globalTypes';

export const useInfluencerProfileAudienceQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerProfileAudienceArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerProfileAudienceArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerProfileAudienceLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerProfileAudienceArgs>
): QueryResult<Query, QueryinfluencerProfileAudienceArgs> & {
  getInfluencerProfileAudience: LazyQueryExecFunction<Query, QueryinfluencerProfileAudienceArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerProfileAudience, result] = useLazyQuery<Query, QueryinfluencerProfileAudienceArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerProfileAudience, ...result };
};

export const useInfluencerProfileAudiencePromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerProfileAudience = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerProfileAudienceArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerProfileAudienceArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerProfileAudience };
};
