import { useRef } from 'react';
import { ActionMeta, GroupBase, OptionsOrGroups, SingleValue, SelectInstance } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Label } from '@/shared/atoms';
import { ClearIndicator, DropdownIndicator, getDefaultSingleSelectStyles } from '@/shared/molecules';
import { AdvertiserOption } from '../types';

export type SingleSelectOption = SingleValue<AdvertiserOption>;
export interface SingleCreatableSelectProps {
  className?: string;
  title: string;
  required?: boolean;
  clearable?: boolean;
  help?: string;
  setFieldValue: (value: SingleSelectOption) => void;
  placeholder: string;
  disabled?: boolean;
  value: SingleSelectOption;
  helpInterpol?: { [key: string]: number };
  hasError?: boolean;
  loadOptions: (
    inputValue: string,
    callback: (options: OptionsOrGroups<SingleSelectOption, GroupBase<SingleSelectOption>>) => void
  ) => Promise<any> | void;
}

export const SingleCreatableSelect = ({
  className,
  title,
  required,
  placeholder,
  value: valueOption,
  help,
  setFieldValue,
  disabled = false,
  clearable = true,
  loadOptions,
  hasError
}: SingleCreatableSelectProps) => {
  const ref = useRef<SelectInstance<SingleSelectOption, false>>(null);

  const defaultAdvertiserOption = {
    value: '',
    label: '',
    phoneNumber: '',
    companyUrl: '',
    countryId: '',
    hubspotId: ''
  };

  const onChange = (selectedValues: SingleSelectOption, { action }: ActionMeta<SingleSelectOption>) => {
    switch (action) {
      case 'select-option':
        if (selectedValues?.value === '') {
          setFieldValue(defaultAdvertiserOption);
        } else if (selectedValues?.value) {
          setFieldValue({ ...selectedValues, isNew: false } as SingleSelectOption);
        }
        break;
      case 'create-option':
        setFieldValue({ ...selectedValues, isNew: true } as SingleSelectOption);
        break;
      case 'clear':
        setFieldValue(defaultAdvertiserOption);

        break;

      default:
        break;
    }
    ref.current?.blur();
  };

  return (
    <div className={className}>
      <Label title={title} required={required} help={help} />
      <AsyncCreatableSelect<SingleSelectOption>
        ref={ref}
        cacheOptions={true}
        loadOptions={loadOptions}
        isMulti={false}
        value={valueOption?.value ? valueOption : null}
        styles={getDefaultSingleSelectStyles({ hasError, disabled })}
        isDisabled={disabled}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        onChange={onChange}
        isClearable={clearable}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: (dropdownProps) => (!dropdownProps.hasValue ? <DropdownIndicator /> : null),
          ClearIndicator: (clearProps) => (clearProps.hasValue ? <ClearIndicator {...clearProps} ref={ref} /> : null)
        }}
      />
    </div>
  );
};
