import {
  Pagination,
  PaginationProps,
  RenderDataWithFallback,
  RenderDataWithFallbackProps
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface ListWithPaginationProps extends RenderDataWithFallbackProps {
  pagination?: PaginationProps;
  className?: string;
}
export const ListWithPagination = ({ pagination, className, ...props }: ListWithPaginationProps) => (
  <>
    <div css={{ boxShadow: THEME.shadows.boxShadow }} className={className}>
      <RenderDataWithFallback {...props} />
    </div>
    {!props.loading && !!pagination ? <Pagination {...pagination} /> : null}
  </>
);
