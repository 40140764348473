import { useRef } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { ChatBoxMessageType } from './types';
import {
  ChatBoxFile,
  ChatBoxSkeleton,
  ChatBoxCopyButton,
  ChatBoxMarkdownReader,
  ChatBoxRegenerateButton
} from './components';

export interface ChatBoxMessageProps {
  className?: string;
  onRegenerate?: () => void;
  message?: ChatBoxMessageType | null;
}
export const ChatBoxMessage = ({ message, className, onRegenerate }: ChatBoxMessageProps) => {
  const textRef = useRef<HTMLDivElement>(null);

  const isLeft = message?.position === 'left';

  return message ? (
    <div css={[styles.wrapper(isLeft), message.typing && { width: '100%' }]} className={className}>
      {message.file ? (
        <ChatBoxFile file={message.file} />
      ) : (
        <div css={[styles.text(isLeft), message.typing && { padding: '16px' }]}>
          {isLeft && message.typing ? (
            <ChatBoxSkeleton />
          ) : (
            <ChatBoxMarkdownReader ref={textRef} value={message.text} />
          )}

          {!message.typing && (message.copyable || (message.regenerable && !!onRegenerate)) ? (
            <div css={{ marginTop: '5px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              {message.copyable ? (
                <ChatBoxCopyButton
                  copyText={textRef.current?.textContent || textRef.current?.innerText} // Get Markdown text without HTML tags
                />
              ) : null}

              {message.regenerable && onRegenerate ? <ChatBoxRegenerateButton onRegenerate={onRegenerate} /> : null}
            </div>
          ) : null}
        </div>
      )}
    </div>
  ) : null;
};

const styles = {
  wrapper: (isLeft: boolean) =>
    css({
      maxWidth: '100%',
      width: 'max-content',
      boxSizing: 'border-box',
      alignSelf: isLeft ? 'flex-start' : 'flex-end'
    }),
  text: (isLeft: boolean) =>
    css({
      // this var also used for child components
      '--textColor': isLeft ? THEME.text.colors.white : THEME.text.colors.black.lv1,

      fontSize: '16px',
      lineHeight: '24px',
      padding: '8px 16px',
      borderRadius: '14px',
      whiteSpace: 'pre-line',
      boxSizing: 'border-box',
      color: 'var(--textColor)',
      backgroundColor: isLeft ? THEME.background.colors.black : THEME.background.colors.gray.lv1
    })
};
