import { useTranslation } from 'react-i18next';
import { AccountsFilter } from '../../Accounts';

export const TalentAgenciesFilter = () => {
  const { t } = useTranslation();

  return (
    <AccountsFilter keywordPlaceholder={t<string>('Search.TalentAgencyFilterPlaceholder')} domain="talent-agency" />
  );
};
