import { ReactNode, createContext, useContext } from 'react';
import { AgencyWithAdvertisers } from '@/graphql';
import { useGetDocumentParentHref } from '@/shared/hooks';

interface ContextType {
  agency: AgencyWithAdvertisers;
}

const AgencyDetailsContext = createContext<ContextType>({} as ContextType);

interface Props {
  children: ReactNode;
  values: ContextType;
}

export const AgencyDetailsProvider = ({ values: { agency }, children }: Props) => {
  useGetDocumentParentHref({
    href: '/agency',
    alwaysBackToParent: true,
    title: 'appHeader.Edit Agency',
    appHeader: agency.name ? 'appHeader.EditWithTheName' : 'appHeader.Edit Agency',
    ...(agency.name ? { interpolation: { name: agency.name } } : {})
  });
  return <AgencyDetailsContext.Provider value={{ agency }}>{children}</AgencyDetailsContext.Provider>;
};

export const useAgencyDetailsContext = () => useContext<ContextType>(AgencyDetailsContext);
