import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { formatPrice } from '@/shared/utils';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useTiktokBudgetAllocationQuery } from '@/graphql';
import { CURRENCY_SYMBOLS, THEME } from '@/shared/constants';
import { AdvertiserAdAccountsList } from '@/shared/organisms';
import { useAdvertiserDetailsContext } from './AdvertiserDetailsContext';

export const AdvertiserBudgetAllocation = () => {
  const { t } = useTranslation();
  const { advertiser } = useAdvertiserDetailsContext();
  const { data, loading } = useTiktokBudgetAllocationQuery({ variables: { advertiserId: advertiser.id } });

  const tiktokBudgetAllocation = data?.tiktokBudgetAllocation;
  const currencyId = tiktokBudgetAllocation?.currencyId;

  return (
    <RenderDataWithFallback loading={loading}>
      <div css={styles.prepaidBalance}>
        <p css={{ fontSize: '14px', fontWeight: 600, lineHeight: '100%' }}>{t('Prepaid balance')}</p>
        <p css={{ fontSize: '32px', fontWeight: 600, lineHeight: '100%', marginTop: '16px' }}>
          {currencyId ? CURRENCY_SYMBOLS[currencyId] : ''}
          {formatPrice(tiktokBudgetAllocation?.prepaidBalance || 0, null, 0)}
        </p>
      </div>

      <AdvertiserAdAccountsList loading={loading} data={tiktokBudgetAllocation?.adAccounts || []} />
    </RenderDataWithFallback>
  );
};
const styles = {
  prepaidBalance: css({
    padding: '24px',
    margin: '24px 0',
    borderRadius: '3px',
    boxSizing: 'border-box',
    boxShadow: THEME.shadows.boxShadow,
    backgroundColor: THEME.background.colors.white
  })
};
