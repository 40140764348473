import { useTranslation } from 'react-i18next';
import { SocialAccountType, InfluencerNewProfileHeaderV2, useInfluencerProfilePostsQuery } from '@/graphql';
import {
  eyeBlueImage,
  likePinkImage,
  chatGreenImage,
  videoPinkImage,
  eyePurpleImage,
  networkBlueImage
} from '@/shared/assets';
import { ChartsContainer } from '@/shared/atoms';
import { PostingHabits, AverageEngagement } from '@/shared/molecules';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerAnalyticsPostsOverview } from '../shared';

interface InfluencerAnalyticsTwitterProfilePostsOverviewProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: InfluencerNewProfileHeaderV2['id'];
}

export const InfluencerAnalyticsTwitterProfilePostsOverview = ({
  influencerId,
  socialAccount
}: InfluencerAnalyticsTwitterProfilePostsOverviewProps) => {
  const { t } = useTranslation();
  const { data } = useInfluencerProfilePostsQuery({
    variables: {
      pk: influencerId,
      socialAccountId: socialAccount.id,
      socialMedia: socialAccount.type as SocialAccountType
    }
  });

  return (
    <InfluencerAnalyticsPostsOverview data={data?.influencerProfilePosts}>
      {({
        data: {
          postsHabit,
          averageLikes,
          averageViews,
          averageShares,
          averageComments,
          averageReactions,
          averagePostsPerWeek
        }
      }) => (
        <ChartsContainer css={{ height: '324px' }}>
          <AverageEngagement
            data={[
              { icon: videoPinkImage, label: t('Avg posts/week'), value: averagePostsPerWeek },
              { icon: likePinkImage, label: t('Avg Like'), value: averageLikes || 0 },
              { icon: eyeBlueImage, label: t('Avg View'), value: averageViews || 0 },
              { icon: eyePurpleImage, label: t('Avg Impression'), value: averageReactions || 0 },
              { icon: chatGreenImage, label: t('Avg Reply'), value: averageComments || 0 },
              { icon: networkBlueImage, label: t('Avg Retweet'), value: averageShares || 0 }
            ]}
          />

          {postsHabit ? <PostingHabits data={postsHabit} /> : null}
        </ChartsContainer>
      )}
    </InfluencerAnalyticsPostsOverview>
  );
};
