import { useTranslation } from 'react-i18next';
import { SocialAccountType, InfluencerNewProfileHeaderV2, useInfluencerProfilePostsQuery } from '@/graphql';
import { heartImage, pictureImage, chatGreenImage, networkBlueImage, bookmarkLightBlueImage } from '@/shared/assets';
import { ChartsContainer } from '@/shared/atoms';
import { HAS_NO_ESTIMATED_TEXT } from '@/shared/constants';
import { PostingHabits, AverageEngagement, AccountsInterestChart } from '@/shared/molecules';
import { useInfluencerProfileOpacityContent } from '@/shared/organisms';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerAnalyticsPostsOverview } from '../shared';

interface InfluencerAnalyticsInstagramProfilePostsOverviewProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerAnalyticsInstagramProfilePostsOverview = ({
  socialAccount,
  influencerProfile: { id, hideSensitiveMetrics }
}: InfluencerAnalyticsInstagramProfilePostsOverviewProps) => {
  const { t } = useTranslation();
  const { data } = useInfluencerProfilePostsQuery({
    variables: { pk: id, socialAccountId: socialAccount.id, socialMedia: socialAccount.type as SocialAccountType }
  });
  const { hasAccountInterests } = useInfluencerProfileOpacityContent({
    socialAccount,
    influencerId: id,
    skipEstimateContent: true
  });

  return (
    <InfluencerAnalyticsPostsOverview data={data?.influencerProfilePosts}>
      {({
        data: {
          postsHabit,
          averageLikes,
          averageSaved,
          averageComments,
          accountInterests,
          averagePostsPerWeek,
          engagementFollowersRate
        }
      }) => (
        <ChartsContainer css={{ height: '324px' }}>
          <AverageEngagement
            data={[
              { icon: pictureImage, label: t('Avg posts/week'), value: averagePostsPerWeek },
              {
                icon: networkBlueImage,
                label: t('HeaderColumn.Avg ER'),
                value: engagementFollowersRate,
                isPercentage: true
              },
              { icon: heartImage, value: averageLikes, label: t('Avg Likes/latest 30 posts') },
              ...(!hideSensitiveMetrics
                ? [{ icon: bookmarkLightBlueImage, label: t('Avg Saves/latest 30 posts'), value: averageSaved }]
                : []),
              { icon: chatGreenImage, value: averageComments, label: t('Avg Comments/latest 30 posts') }
            ]}
          />

          <AccountsInterestChart
            data={accountInterests}
            wrapperText={HAS_NO_ESTIMATED_TEXT}
            dataNotAvailable={!hasAccountInterests}
          />

          {postsHabit ? <PostingHabits data={postsHabit} /> : null}
        </ChartsContainer>
      )}
    </InfluencerAnalyticsPostsOverview>
  );
};
