/* eslint-disable react/jsx-key */
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { linkOptions } from '@tanstack/react-router';
import { useAdvertiserPermissions, useAgencyPermissions, useUserRoles } from '@/auth';
import { AssociateCompanyType } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { SpreadButton } from '@/shared/molecules';
import { getUserRoleString, formatIntNumber } from '@/shared/utils';
import { AssociatedAccountType } from '../types';

interface Props {
  data: readonly AssociatedAccountType[];
  onDelete?: (account: AssociatedAccountType) => void;
}

export const useAssociatedAccountsTable = ({ data, onDelete }: Props) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { hideEditAgencyBtn, hideDeleteAgencyBtn } = useAgencyPermissions();
  const { hideEditAdvertiserBtn, hideDeleteAdvertiserBtn } = useAdvertiserPermissions();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.CompanyName') },
    { title: t('HeaderColumn.Company Type') },
    { title: t('HeaderColumn.Phone Number') },
    { title: t('HeaderColumn.User'), styles: { textAlign: 'right' } }
  ];
  const rows: RowProps[] = data.map((account) => {
    const { id, name, phoneNumber, companyType, usersCount } = account;
    const isAdvertiser = companyType === AssociateCompanyType.ADVERTISER;
    const disabledEditLink = isAdvertiser ? hideEditAdvertiserBtn : hideEditAgencyBtn;
    const disabledDeleteLink = isAdvertiser ? hideDeleteAdvertiserBtn : hideDeleteAgencyBtn;
    const editLink = linkOptions({
      to: isAdvertiser ? '/advertiser/$id' : '/agency/$id',
      params: {
        id: id.toString()
      }
    });

    return [
      <div css={styles.nameColumnWrapper}>
        <TableCell.Link {...editLink} label={name} disabled={disabledEditLink} />
        <SpreadButton
          collapseFrom={0}
          moreButtonProps={{ disabled: hideDeleteAdvertiserBtn && hideEditAdvertiserBtn }}
          options={[
            {
              label: t('Edit'),
              icon: 'edit',
              ...editLink,
              hidden: disabledEditLink,
              tooltip: { help: t('Button.Edit') }
            },
            {
              icon: 'delete',
              label: t('Delete'),
              disabled: !isAdminStaff,
              hidden: disabledDeleteLink,
              onClick: () => onDelete?.(account),
              tooltip: { help: t('Button.Delete') }
            }
          ]}
        />
      </div>,
      <TableCell.Text value={t(`Option.${getUserRoleString(companyType)}`)} />,
      <TableCell.Text value={phoneNumber || '-'} />,
      <TableCell.Number value={formatIntNumber(usersCount)} />
    ];
  });

  return { rows, columns };
};

const styles = {
  nameColumnWrapper: css({
    display: 'flex',
    maxWidth: '400px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  })
};
