import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const ReferralTrackingFilterSchema = z.object({
  campaign_id: FilterValidations.string(),
  sale_amount: FilterValidations.string(),
  referral_code: FilterValidations.string(),
  transaction_id: FilterValidations.string()
});
export const ReferralTrackingSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(ReferralTrackingFilterSchema, {
    campaign_id: '',
    sale_amount: '',
    referral_code: '',
    transaction_id: ''
  })
});

export type ReferralTrackingSearchSchemaType = z.infer<typeof ReferralTrackingSearchSchema>;
export type ReferralTrackingFilterFormValues = z.infer<typeof ReferralTrackingFilterSchema>;
export type ReferralTrackingFilterFormKeys = UnionOfKeys<ReferralTrackingFilterFormValues>;
export const REFERRAL_TRACKING_PARAMS = {
  referral_code: 'referral_code',
  transaction_id: 'transaction_id',
  campaign_id: 'campaign_id',
  sale_amount: 'sale_amount'
};
