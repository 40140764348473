import { useAllPartnerAgencyUsersQuery } from '@/graphql';
import { AccountUsersList } from '../../Accounts';

export interface PartnerUsersListProps {
  partnerId: number;
}

export const PartnerUsersList = ({ partnerId }: PartnerUsersListProps) => {
  const { data, loading } = useAllPartnerAgencyUsersQuery({ variables: { partnerAgencyId: partnerId } });

  return (
    <AccountUsersList
      loading={loading}
      data={data?.allPartnerAgencyUsers || []}
      refetchQueriesAfterDelete={['AllPartnerAgencyUsers']}
    />
  );
};
