import { useUserProposalContext } from '@/shared/organisms';

export const useSelectInfluencersMapper = () => {
  const { userProposal } = useUserProposalContext();
  const selectedInfluencersMapIds = (() =>
    (userProposal?.influencers || []).reduce((rs, el) => {
      const socialMediaHashTable = el.accountList.reduce(
        (tb, acc) => tb.set(acc.socialMedia, [...(tb.get(acc.socialMedia) || []), acc.id]),
        new Map<string, number[]>()
      );

      return rs.set(el.id, socialMediaHashTable);
    }, new Map<number, Map<string, number[]>>()))();

  return {
    selectedInfluencersMapIds
  };
};
