import { InfluencerNewProfileHeaderV2 } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfilePostsWrapper } from '../../shared';
import { InfluencerTiktokProfileFeedPosts } from './InfluencerTiktokProfileFeedPosts';
import { InfluencerTiktokProfilePostsOverview } from './InfluencerTiktokProfilePostsOverview';

export interface InfluencerTiktokProfilePostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerTiktokProfilePosts = ({
  socialAccount,
  influencerProfile
}: InfluencerTiktokProfilePostsProps) => (
  <InfluencerProfilePostsWrapper socialAccount={socialAccount} influencerProfile={influencerProfile}>
    {({ data }) => (
      <>
        <InfluencerTiktokProfilePostsOverview
          data={data}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
        <InfluencerTiktokProfileFeedPosts
          data={data?.feedPosts || []}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
      </>
    )}
  </InfluencerProfilePostsWrapper>
);
