import { InfluencerNewProfileHeaderV2, useInfluencerFacebookPageAnalyticsOverviewQuery } from '@/graphql';
import { useInfluencerProfileSearch, AnalyticsFacebookDashboardOverviewTemplate } from '@/shared/organisms';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsFacebookProfileOverviewProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: InfluencerNewProfileHeaderV2['id'];
}

export const InfluencerAnalyticsFacebookProfileOverview = ({
  influencerId,
  socialAccount
}: InfluencerAnalyticsFacebookProfileOverviewProps) => {
  const { reinitParamsSearch } = useInfluencerProfileSearch();
  const { data, loading } = useInfluencerFacebookPageAnalyticsOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: { socialAccountId: socialAccount.id, influencerId },
    onCompleted: ({ influencerFacebookPageAnalyticsOverview }) => {
      reinitParamsSearch({ lastUpdated: influencerFacebookPageAnalyticsOverview?.lastUpdated || '' });
    }
  });

  return (
    <div css={{ marginTop: '16px' }}>
      <AnalyticsFacebookDashboardOverviewTemplate
        isInfluencerProfile
        loading={loading}
        influencerId={influencerId}
        accountId={socialAccount.id}
        data={data?.influencerFacebookPageAnalyticsOverview}
      />
    </div>
  );
};
