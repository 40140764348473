import { useParams, useSearch } from '@tanstack/react-router';
import { TikTokCampaignPostForm } from '@/shared/organisms';
import { useQueryHelper } from '@/shared/hooks';
import { useTikTokCampaignDetailsContext } from '../TikTokCampaignDetailsContext';

export const TikTokCampaignEditPost = () => {
  const { navigate } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/tiktok/campaigns/$id/posts/$postId' });
  const search = useSearch({ from: '/_private-routes/tiktok/campaigns/$id/posts/$postId' });
  const { tiktokSpecialCampaign } = useTikTokCampaignDetailsContext();

  const postId = Number(params.postId || '');

  const goToPostsList = () => {
    navigate({
      to: '/tiktok/campaigns/$id/posts',
      params: { id: params.id },
      search,
      replace: true
    });
  };

  if (postId === null) {
    goToPostsList();

    return null;
  }

  return (
    <TikTokCampaignPostForm
      postId={postId}
      onClose={goToPostsList}
      campaignFinished={tiktokSpecialCampaign.status === 'FINISHED'}
      campaignId={tiktokSpecialCampaign.id}
    />
  );
};
