import { FormProvider } from '@/shared/atoms';
import { SignInFormSchema } from './schema';
import { Template } from './Template';
import { SignInFormProps, SignInFormValues } from './types';

export const SignInForm = ({ onSubmit, defaultValues = { email: '', password: '' } }: SignInFormProps) => (
  <FormProvider onSubmit={onSubmit} defaultValues={defaultValues} zodSchema={SignInFormSchema}>
    <Template />
  </FormProvider>
);

export { type SignInFormValues };
