import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { MarketplaceAffiliateCommissionStatus } from '@/graphql';

export const MarketplaceCommissionsFilterSchema = z.object({
  month: FilterValidations.string(),
  keyword: FilterValidations.string(),
  status: FilterValidations.enum(MarketplaceAffiliateCommissionStatus)
});
export const MarketplaceCommissionsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(MarketplaceCommissionsFilterSchema, {
    month: '',
    keyword: '',
    status: ''
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type MarketplaceCommissionsSearchSchemaType = z.infer<typeof MarketplaceCommissionsSearchSchema>;
export type MarketplaceCommissionsFilterFormValues = z.infer<typeof MarketplaceCommissionsFilterSchema>;
export type MarketplaceCommissionsFilterFormKeys = UnionOfKeys<MarketplaceCommissionsFilterFormValues>;
