import { useTranslation } from 'react-i18next';
import { InstagramStoryPost, InstagramStoryPostSortField } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';

type CustomColumnProps = ColumnProps<InstagramStoryPostSortField>;

interface Props {
  data: readonly InstagramStoryPost[];
}

export const useInfluencerInstagramStoryPostsListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    {
      title: <div>{t('HeaderColumn.Story Post')}</div>,
      sticky: { active: true, hasBorderRight: true }
    },
    getMetricColumnProps({
      title: t('HeaderColumn.Impression'),
      help: t<string>('Tooltip.ImpsHelp'),
      sortField: InstagramStoryPostSortField.IMPRESSIONS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Reach'),
      help: t<string>('Tooltip.ReachPostHelp'),
      sortField: InstagramStoryPostSortField.REACH
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Reply'),
      help: t<string>('Tooltip.CommentHelp'),
      sortField: InstagramStoryPostSortField.COMMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Tap Forward'),
      help: t<string>('Tooltip.TapForward'),
      sortField: InstagramStoryPostSortField.TAPS_FORWARD
    }),
    getMetricColumnProps({
      key: 'swipeForward',
      title: t('HeaderColumn.Swipe Forward'),
      help: t<string>('Tooltip.SwipeForward'),
      sortField: InstagramStoryPostSortField.SWIPE_FORWARD
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Tap Back'),
      help: t<string>('Tooltip.TapBack'),
      sortField: InstagramStoryPostSortField.TAPS_BACK
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Exits'),
      help: t<string>('Tooltip.ExitsHelp'),
      sortField: InstagramStoryPostSortField.EXITS
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      help: t<string>('Tooltip.PostDateHelp'),
      sortField: InstagramStoryPostSortField.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      reach,
      exits,
      content,
      tapsBack,
      comments,
      postDate,
      thumbNail,
      impressions,
      tapsForward,
      swipeForward
    }) => [
      <TableCell.Post title={content || ''} thumbnail={thumbNail || ''} onClick={() => setPostDetailsParam(id)} />,
      <TableCell.Number value={formatIntNumber(impressions)} />,
      <TableCell.Number value={formatIntNumber(reach)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(tapsForward)} />,
      <TableCell.Number value={formatIntNumber(swipeForward)} />,
      <TableCell.Number value={formatIntNumber(tapsBack)} />,
      <TableCell.Number value={formatIntNumber(exits)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
