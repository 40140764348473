import { Outlet } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { EmailForwarding, EmailForwardingSearchSchema } from '@/pages/Public';
import { PartialRouter } from '../types';
import { LazyReferralRouter } from './lazy-referral';
import { PasswordRouter } from './password';
import { SignInUpRouter } from './sign-in-up';
import { TermsPrivacyPolicyRouter } from './terms-privacy-policy';
import { TikTokOnboardingRouter } from './tiktok-onboarding';

export const PublicRoutes = {
  '/_public-routes': [() => <Outlet />, {}],
  ...SignInUpRouter,
  ...PasswordRouter,
  ...TikTokOnboardingRouter,
  ...LazyReferralRouter,
  ...TermsPrivacyPolicyRouter,
  '/_public-routes/redirect_to': [EmailForwarding, { validateSearch: zodValidator(EmailForwardingSearchSchema) }]
} as const satisfies PartialRouter;
