import { useTranslation } from 'react-i18next';
import { TextField, TextFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const PhoneNumberField = (props: Omit<TextFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return (
    <TextField<InfluencerFormKeys>
      placeholder="+6563867368"
      help={t('Tooltip.Phone Number')}
      title={t('TextForm.Phone Number')}
      name={InfluencerSharedKeys.phoneNumber}
      {...props}
    />
  );
};
