import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateSubscriptionPlan.graphql';
import { Mutation, MutationupdateSubscriptionPlanArgs } from '../../__generated__/globalTypes';

export const useUpdateSubscriptionPlanMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateSubscriptionPlanArgs>
) => {
  const [callUpdateSubscriptionPlan, result] = useMutation<Mutation, MutationupdateSubscriptionPlanArgs>(
    MUTATION,
    options
  );

  return { callUpdateSubscriptionPlan, ...result };
};

export type UpdateSubscriptionPlanMutationFunction = Mutation['updateSubscriptionPlan'];
