import { ReactNode } from 'react';
import { css, Theme } from '@emotion/react';

export interface FieldsGroupProps {
  className?: string;
  children: ReactNode;
  itemsPerRow?: number;
  hasError?: boolean;
}

export const FieldsGroup = ({ itemsPerRow, className, hasError: _, ...restProps }: FieldsGroupProps) => (
  <div css={styles.wrapper({ itemsPerRow })} className={`${className} fields-group`} {...restProps} />
);
const styles = {
  wrapper:
    ({ itemsPerRow }: Pick<FieldsGroupProps, 'itemsPerRow'>) =>
    (theme: Theme) =>
      css({
        '--size': `${100 / (itemsPerRow || 1)}%`,
        flex: '1 1 0',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        margin: `0 ${-theme.form.gapColumn / 2}px`,
        '.field-wrapper': {
          flexShrink: 1,
          paddingLeft: `${theme.form.gapColumn / 2}px`,
          paddingRight: `${theme.form.gapColumn / 2}px`,
          ...(itemsPerRow ? { flex: `var(--size) 0 0`, maxWidth: 'var(--size)' } : {})
        }
      })
};
