import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/CheckoutSessionLink.graphql';
import { Query, QuerycheckoutSessionLinkArgs } from '../../__generated__/globalTypes';

export const useCheckoutSessionLinkQuery = (options?: QueryHookOptions<Query, QuerycheckoutSessionLinkArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerycheckoutSessionLinkArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useCheckoutSessionLinkLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerycheckoutSessionLinkArgs>
): QueryResult<Query, QuerycheckoutSessionLinkArgs> & {
  getCheckoutSessionLink: LazyQueryExecFunction<Query, QuerycheckoutSessionLinkArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getCheckoutSessionLink, result] = useLazyQuery<Query, QuerycheckoutSessionLinkArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getCheckoutSessionLink, ...result };
};

export const useCheckoutSessionLinkPromiseQuery = () => {
  const client = useApolloClient();

  const getCheckoutSessionLink = useCallback(
    (options?: Omit<QueryOptions<QuerycheckoutSessionLinkArgs, Query>, 'query'>) =>
      client.query<Query, QuerycheckoutSessionLinkArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getCheckoutSessionLink };
};
