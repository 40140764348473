import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template } from './Template';
import { useMarketplaceAutoManagedCampaignFilter } from './hooks';

export const MarketplaceAutoManagedCampaignFilter = () => {
  const { filter, setFilterForm } = useMarketplaceAutoManagedCampaignFilter();

  return (
    <FilterWrapper>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<Template />} />
    </FilterWrapper>
  );
};

export { useMarketplaceAutoManagedCampaignFilter };
export * from './schemaTypes';
