import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useBillingInformationQuery, useUpdatePaymentMethodLinkQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { Anchor } from '@/shared/atoms';
import { getExpiresCardDate } from './utils';

export const TikTokBillingInformation = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { data: billingData } = useBillingInformationQuery();
  const { data } = useUpdatePaymentMethodLinkQuery({
    variables: {
      redirectUrl: window.location.href
    }
  });

  const billingInformation = billingData?.billingInformation;
  const hasBilling = billingInformation ? Object.values(billingInformation).some(Boolean) : false; // normally this shouldn't happen, but if no billing we can show `-`

  return (
    <div css={styles.wrapper} className={className}>
      <div css={{ display: 'flex', flex: 1, justifyContent: 'space-between', gap: '16px' }}>
        <h3 css={{ fontSize: '16px', fontWeight: 600 }}>{t('Billing information')}</h3>
        <Anchor
          label={t('Button.Change')}
          variant="blue"
          css={{ lineHeight: '22px', flex: 'none' }}
          href={data?.updatePaymentMethodLink?.url}
        />
      </div>

      {billingInformation && hasBilling ? (
        <div css={{ display: 'flex', flexDirection: 'column', gap: '8px', fontSize: '14px', marginTop: '16px' }}>
          <div>
            <span css={{ textTransform: 'uppercase' }}>{billingInformation.cardBrand}</span>
            <span> {t('ending in')} </span>
            <span>{billingInformation.cardLast4}</span>
          </div>
          {billingInformation.cardExpMonth && billingInformation.cardExpYear ? (
            <span>
              {t('Expires')} {getExpiresCardDate(billingInformation)}
            </span>
          ) : (
            '-'
          )}
        </div>
      ) : (
        '-'
      )}
    </div>
  );
};

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    width: '280px',
    height: 'max-content',
    backgroundColor: THEME.background.colors.white,
    boxShadow: THEME.shadows.boxShadow
  })
};
