import { useTranslation } from 'react-i18next';
import { AllTiktokAdReportSummary } from '@/graphql';
import { ReportSummary as Summary, ReportSummaryType } from '@/shared/atoms';
import { formatIntNumber } from '@/shared/utils';

export interface AdCampaignsReportProps {
  data?: AllTiktokAdReportSummary | null;
}

export const AdCampaignsReportSummary = ({ data }: AdCampaignsReportProps) => {
  const { t } = useTranslation();

  const currency = t(data?.currency || '');
  const summaries: ReportSummaryType[] = [
    { label: t<string>('Total Cost'), value: formatIntNumber(data?.summary?.adCost), unit: currency },
    { label: t<string>('CPM'), value: formatIntNumber(data?.summary?.adCpm), unit: currency },
    { label: t<string>('Impressions'), value: formatIntNumber(data?.summary?.adImpressions) },
    { label: t<string>('Clicks'), value: formatIntNumber(data?.summary?.adClicks) },
    { label: t<string>('Video View'), value: formatIntNumber(data?.summary?.videoPlayActions) }
  ];

  return <Summary summaries={summaries} css={{ '.summary-label': { textTransform: 'capitalize' } }} />;
};
