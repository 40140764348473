declare const API_URL: string;
declare const REST_API_URL: string;
declare const CSV_UPLOAD_URL: string;
declare const GOOGLE_STORAGE_URL: string;
declare const ADVANCED_SEARCH_URL: string;
declare const ACCOUNT_MANAGEMENT_URL: string;

export const REST_URL = `${REST_API_URL}api/`;
export const UPLOAD_URL = `${CSV_UPLOAD_URL}/`;
export const GRAPHQL_URL = `${API_URL}graphql/`;
export const GRAPHQL_SERVER_VERSION_URL = `${API_URL}version/`;
export const SEARCH_URL = `${ADVANCED_SEARCH_URL}/`;
export const GG_STORAGE_URL = `${GOOGLE_STORAGE_URL}/`;
export const MANAGEMENT_URL = `${ACCOUNT_MANAGEMENT_URL}/`;
