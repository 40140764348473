import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@/shared/atoms';
import { useToggleState } from '@/shared/hooks';
import { EngagementCampaignTrafficSourceModal, EngagementCampaignTrafficSourceModalProps } from '@/shared/molecules';

interface EngagementCampaignTrafficSourceProps extends ButtonProps, EngagementCampaignTrafficSourceModalProps {}

export const EngagementCampaignTrafficSource = ({
  filter,
  campaignId,
  ...restProps
}: EngagementCampaignTrafficSourceProps) => {
  const { t } = useTranslation();
  const modalState = useToggleState();

  return (
    <>
      <Button
        variant="white"
        onClick={modalState.open}
        title={t('Traffic Source')}
        prefixIcon={{ icon: 'bar-chart', size: 12 }}
        {...restProps}
      />

      <EngagementCampaignTrafficSourceModal
        filter={filter}
        campaignId={campaignId}
        open={modalState.status}
        onClose={modalState.close}
      />
    </>
  );
};
