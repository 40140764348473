import React, { createContext, useContext } from 'react';
import { useParams } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { InfluencerNewProfileHeaderV2 } from '@/graphql';

interface ContextType {
  influencerId: number;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

const Context = createContext<ContextType>({} as ContextType); // Details context must have values, so we won't set empty values here

interface Props {
  value: Pick<ContextType, 'influencerProfile'>;
  children: React.ReactElement | Array<React.ReactElement>;
  isEngagementProposal?: boolean;
}

export const InfluencerProfileProvider = ({ children, value: { influencerProfile }, isEngagementProposal }: Props) => {
  const { isAdvertiser } = useUserRoles();
  const params = useParams({ strict: false });
  useUpdateDocumentTitle({
    href: '/influencers',
    alwaysBackToParent: !isAdvertiser,
    title: 'documentTitle.InfluencerProfile',
    interpolation: { name: influencerProfile.name },
    appHeader: 'appHeader.InfluencerDetailWithName',
    skipUpdate: { title: !!isEngagementProposal, appHeader: !!isEngagementProposal }
  });

  return (
    <Context.Provider
      value={{
        influencerProfile,
        influencerId: Number(params.id)
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useInfluencerProfileContext = () => useContext<ContextType>(Context);
