import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { CampaignTrackingOption, CampaignSocialMediaType } from '@/graphql';
import { FormStyle, Icon, Button } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { UploadCSVCouponModal } from '@/shared/molecules';
import { useMarketplacePromotionMethodOptions, usePostPricesAvailability } from '../../hooks';
import { AnyCreatorIframePreview } from '../AnyCreatorIframePreview';
import {
  TitleField,
  RegionField,
  BudgetField,
  CountryField,
  RevenueField,
  SalePICField,
  HashtagField,
  AdvertiserField,
  MarginRateField,
  HubspotDealField,
  SocialMediaField,
  CostPerLikeField,
  CostPerPostField,
  CostPerViewField,
  CostPerShareField,
  CostPerOrderField,
  ApprovePostsField,
  AgeRangeInputField,
  FormLayoutTemplate,
  PreLaunchDateField,
  MaterialUploadField,
  PostRequirementField,
  ThumbnailUploadField,
  GenderCheckBoxesField,
  AgencyMarginRateField,
  CampaignCategoryField,
  AllowNewInfluencerField,
  ReferralCodeUploadField,
  ServiceInformationField,
  InfluencerCategoryField,
  FollowerRangeInputField,
  ApproveInfluencersField,
  TrackingCreatorPostField,
  CostPerSaleReferralField,
  DraftPostSubmissionField,
  CampaignMaterialLinkField,
  AdditionalRequirementField,
  InfluencerManagementPICField,
  InfluencerCommissionRateField,
  CampaignPeriodWithLogicsField
} from '../shared';
import { MarketplaceFormValues, MarketplaceFormKeys, DefaultFormTemplateProps } from '../types';
import { marketplaceSectionTitles } from '../utils';
import { AiRainbowSpinner, DescriptionGenerator, useDescriptionGeneratorState } from '../../DescriptionGenerator';

export const Template = ({ defaultValues, disabledFields, onSubmit }: DefaultFormTemplateProps) => {
  const { t } = useTranslation();
  const { isAdminStaff, isAdvertiser, isAdminStaffAgency } = useUserRoles();
  const { promotionMethodOptions } = useMarketplacePromotionMethodOptions();
  const { watch, setValue } = useFormContext<MarketplaceFormValues>();
  const { open: openCouponModal, close: closeCouponModal, status: isOpeningCouponModal } = useToggleState();
  const descriptionActions = useDescriptionGeneratorState();

  const { couponUploadFiles, id, trackingOptions, socialMedia, needCouponsCount } = watch();
  const isEditMode = !!id;
  const defaultStep = isAdvertiser ? -1 : 0;
  const hasTrackingPost = trackingOptions.includes(CampaignTrackingOption.POST);
  const allPostPrices = usePostPricesAvailability(socialMedia);

  const handleFileUpload = (fileUrl: string) => {
    if (fileUrl) {
      setValue(MarketplaceFormKeys.couponUploadFiles, [...couponUploadFiles, fileUrl]);
      closeCouponModal();
    }
  };

  const handleTrackingPostChange = (active?: boolean) => {
    if (!active) {
      setValue(MarketplaceFormKeys.hashtags, []);
      setValue(MarketplaceFormKeys.requireDraftPost, false);
    }
    if (active && socialMedia === CampaignSocialMediaType.UNSELECT) {
      setValue(MarketplaceFormKeys.socialMedia, '');
    }
  };

  return (
    <>
      {isEditMode && needCouponsCount !== null ? (
        <div css={styles.warningWrapper}>
          <Icon size="14px" icon="warning" color="#ffb63d" css={{ marginTop: '4px', alignSelf: 'flex-start' }} />
          <div css={{ marginLeft: '8px', fontSize: '14px', marginRight: '8px' }}>
            <p css={{ fontWeight: 600 }}>{t('Upload extra referral codes')}</p>
            <p css={{ marginTop: '8px' }}>
              {t('Please upload extra referral codes so that other influencers can see and join this campaign')}
            </p>
          </div>
          <Button
            variant="white"
            onClick={openCouponModal}
            title={t('Upload referral codes')}
            css={{ padding: '0 16px', marginLeft: 'auto' }}
          />
        </div>
      ) : null}

      <FormLayoutTemplate defaultValues={defaultValues} onSubmit={onSubmit}>
        {!isAdvertiser ? (
          <>
            <FormStyle.SectionTitle
              index={defaultStep + 1}
              css={{ marginBottom: '24px' }}
              label={t(marketplaceSectionTitles.picSettings)}
            />
            <FormStyle.FieldsGroup itemsPerRow={2}>
              {isAdminStaffAgency ? <AdvertiserField disabled={disabledFields.advertiser} required /> : null}
              {isAdminStaff ? (
                <>
                  <HubspotDealField disabled={disabledFields.hubspotDealIds} />
                  <SalePICField disabled={disabledFields.salesPicIds} required />
                  <InfluencerManagementPICField disabled={disabledFields.influencerManagementPicIds} required />
                </>
              ) : null}
            </FormStyle.FieldsGroup>
            <FormStyle.HorizontalLine css={{ margin: '24px auto' }} />
          </>
        ) : null}

        <FormStyle.SectionTitle index={defaultStep + 2} label={t(marketplaceSectionTitles.campaignInformation)} />

        <TrackingCreatorPostField disabled={disabledFields.trackingOptions} onChange={handleTrackingPostChange} />

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <PreLaunchDateField disabled={disabledFields.preLaunchDate} />
          <CampaignPeriodWithLogicsField disabled={disabledFields.period} required />
        </FormStyle.FieldsGroup>

        <FormStyle.FieldsGroup itemsPerRow={3}>
          <CountryField disabled={disabledFields.countryId} required />
          <RegionField disabled={disabledFields.regionIds} />
          <CampaignCategoryField disabled={disabledFields.campaignCategoryId} required />
          <SocialMediaField
            required={hasTrackingPost}
            title={t('Selector.Social Media / Promotion method')}
            disabled={disabledFields.socialMedia}
            {...(!hasTrackingPost ? { options: promotionMethodOptions } : {})}
          />
          <InfluencerCategoryField disabled={disabledFields.influencerCategoryIds} required />
        </FormStyle.FieldsGroup>

        <FormStyle.FieldsGroup itemsPerRow={3}>
          <GenderCheckBoxesField disabled={disabledFields.genders} required />
        </FormStyle.FieldsGroup>

        <FormStyle.FieldsGroup itemsPerRow={3}>
          <AgeRangeInputField
            minField={{ disabled: disabledFields.minAge }}
            maxField={{ disabled: disabledFields.maxAge }}
          />
          <FollowerRangeInputField
            minField={{ disabled: disabledFields.minFollowers }}
            maxField={{ disabled: disabledFields.maxFollowers }}
            socialMedia={socialMedia}
          />
        </FormStyle.FieldsGroup>

        <FormStyle.FieldWrapper>
          <div css={{ padding: '0 16px', border: `1px solid ${THEME.border.colors.gray.lv1}` }}>
            <ApproveInfluencersField disabled={disabledFields.isAutoInfluencerApproval} />
            <ApprovePostsField disabled={disabledFields.isAllowMultiplePosts} />
            <AllowNewInfluencerField disabled={disabledFields.allowNewInfluencer} />
          </div>
        </FormStyle.FieldWrapper>

        <FormStyle.HorizontalLine css={{ margin: '24px auto' }} />
        <DescriptionGenerator {...descriptionActions}>
          <FormStyle.SectionTitle
            index={defaultStep + 3}
            css={{ marginBottom: '4px' }}
            label={t(marketplaceSectionTitles.campaignDetails)}
          />
        </DescriptionGenerator>

        <TitleField
          disabled={disabledFields.title || descriptionActions.loading}
          required
          labelLoader={descriptionActions.loading ? <AiRainbowSpinner css={{ marginLeft: '4px' }} /> : undefined}
        />
        <ServiceInformationField
          disabled={disabledFields.serviceInformation || descriptionActions.loading}
          required
          labelLoader={descriptionActions.loading ? <AiRainbowSpinner css={{ marginLeft: '4px' }} /> : undefined}
          getSunEditorInstance={descriptionActions.getSunEditorInstance}
        />
        <PostRequirementField
          disabled={disabledFields.requirement || descriptionActions.loading}
          required
          labelLoader={descriptionActions.loading ? <AiRainbowSpinner css={{ marginLeft: '4px' }} /> : undefined}
          getSunEditorInstance={descriptionActions.getSunEditorInstance}
        />
        <ThumbnailUploadField disabled={disabledFields.thumbnails} required />
        {hasTrackingPost ? (
          <>
            <DraftPostSubmissionField disabled={disabledFields.requireDraftPost} />
            <HashtagField disabled={disabledFields.hashtags} required />
          </>
        ) : null}
        <MaterialUploadField disabled={disabledFields.materials} />
        <AdditionalRequirementField disabled={disabledFields.additionalRequirement} />
        <CampaignMaterialLinkField disabled={disabledFields.campaignMaterialLink} />

        <FormStyle.HorizontalLine css={{ margin: '12px auto 24px' }} />
        <FormStyle.SectionTitle index={defaultStep + 4} label={t(marketplaceSectionTitles.trackingSettings)} />

        <ReferralCodeUploadField disabled={disabledFields.couponUploadFiles} openCouponModal={openCouponModal} />

        <FormStyle.HorizontalLine css={{ margin: '12px auto 24px' }} />
        <FormStyle.SectionTitle index={defaultStep + 5} label={t(marketplaceSectionTitles.paymentInformation)} />

        <FormStyle.FieldsGroup itemsPerRow={4}>
          {hasTrackingPost ? (
            <>
              {allPostPrices.costPerPost ? <CostPerPostField disabled={disabledFields.costPerPost} /> : null}
              {allPostPrices.costPerLike ? <CostPerLikeField disabled={disabledFields.costPerLike} /> : null}
              {allPostPrices.costPerView ? <CostPerViewField disabled={disabledFields.costPerView} /> : null}
              {allPostPrices.costPerShare ? <CostPerShareField disabled={disabledFields.costPerShare} /> : null}
            </>
          ) : null}
          {allPostPrices.costPerOrder ? <CostPerOrderField disabled={disabledFields.costPerOrder} /> : null}
          {allPostPrices.costPerSaleReferral ? (
            <CostPerSaleReferralField disabled={disabledFields.costPerSaleReferral} />
          ) : null}
        </FormStyle.FieldsGroup>

        <FormStyle.HorizontalLine css={{ margin: '24px auto' }} />
        <FormStyle.SectionTitle index={defaultStep + 6} label={t(marketplaceSectionTitles.budgetSettings)} />

        <FormStyle.FieldsGroup itemsPerRow={4}>
          <RevenueField disabled={disabledFields.maximumRevenuePerInfluencer} />
          {isAdminStaffAgency ? <AgencyMarginRateField disabled={disabledFields.agencyMarginRate} /> : null}
        </FormStyle.FieldsGroup>

        <FormStyle.FieldsGroup itemsPerRow={4}>
          {isAdminStaff ? <MarginRateField disabled={disabledFields.marginRate} required /> : null}
          {!isAdvertiser ? <InfluencerCommissionRateField /> : null}
        </FormStyle.FieldsGroup>

        <FormStyle.FieldsGroup itemsPerRow={4}>
          <BudgetField disabled={disabledFields.budget} required />
        </FormStyle.FieldsGroup>
      </FormLayoutTemplate>

      <UploadCSVCouponModal isOpen={isOpeningCouponModal} onClose={closeCouponModal} onUploadFile={handleFileUpload} />

      <AnyCreatorIframePreview />
    </>
  );
};
const styles = {
  warningWrapper: css({
    display: 'flex',
    padding: '12px 8px',
    borderRadius: '3px',
    margin: '0 auto 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderLeft: '4px solid #ffb63d',
    boxShadow: '0 1px 5px rgba(39, 49, 59, 0.2)',
    backgroundColor: THEME.background.colors.white
  })
};
