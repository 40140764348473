import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/MarketplaceAffiliateReportSummary.graphql';
import { Query, QuerymarketplaceAffiliateReportSummaryArgs } from '../../__generated__/globalTypes';

export const useMarketplaceAffiliateReportSummaryQuery = (
  options?: QueryHookOptions<Query, QuerymarketplaceAffiliateReportSummaryArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerymarketplaceAffiliateReportSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useMarketplaceAffiliateReportSummaryLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerymarketplaceAffiliateReportSummaryArgs>
): QueryResult<Query, QuerymarketplaceAffiliateReportSummaryArgs> & {
  getMarketplaceAffiliateReportSummary: LazyQueryExecFunction<Query, QuerymarketplaceAffiliateReportSummaryArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getMarketplaceAffiliateReportSummary, result] = useLazyQuery<
    Query,
    QuerymarketplaceAffiliateReportSummaryArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getMarketplaceAffiliateReportSummary, ...result };
};

export const useMarketplaceAffiliateReportSummaryPromiseQuery = () => {
  const client = useApolloClient();

  const getMarketplaceAffiliateReportSummary = useCallback(
    (options?: Omit<QueryOptions<QuerymarketplaceAffiliateReportSummaryArgs, Query>, 'query'>) =>
      client.query<Query, QuerymarketplaceAffiliateReportSummaryArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getMarketplaceAffiliateReportSummary };
};
