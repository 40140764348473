import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateMarketplaceCouponList.graphql';
import { Mutation, MutationupdateMarketplaceCouponListArgs } from '../../__generated__/globalTypes';

export const useUpdateMarketplaceCouponListMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateMarketplaceCouponListArgs>
) => {
  const [callUpdateMarketplaceCouponList, result] = useMutation<Mutation, MutationupdateMarketplaceCouponListArgs>(
    MUTATION,
    options
  );

  return { callUpdateMarketplaceCouponList, ...result };
};

export type UpdateMarketplaceCouponListMutationFunction = Mutation['updateMarketplaceCouponList'];
