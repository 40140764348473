import { z } from 'zod';
import { AnalyticsSubscriptionPlanType, CampaignSocialMediaType, Genders } from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';
import { SUN_EDITOR_EMPTY_VALUE } from '@/shared/constants';

export const getPositiveNumberSchema = (
  requiredError: string | undefined = AllTranslationKeys.Error.invalidNumberMessage
) =>
  z
    .union([z.string(), z.number()])
    .refine((v) => v !== '', requiredError)
    .refine((v) => Number(v) >= 0, AllTranslationKeys.Error.numberMustBePositiveMessage);

export const NameSchema = z.string().min(1, AllTranslationKeys.Error.requiredNameMessage);
export const EmailSchemaOptional = z
  .string()
  .email(AllTranslationKeys.invalidEmailMessage)
  .optional()
  .or(z.literal(''));
export const EmailSchema = z
  .string()
  .min(1, AllTranslationKeys.requiredEmailMessage)
  .email(AllTranslationKeys.invalidEmailMessage);

export const PasswordSchema = z.string().min(1, AllTranslationKeys.Error.requiredPasswordMessage);
export const PasswordSchemaWithRegex = PasswordSchema.regex(
  /[\x21-\x7e]+/,
  AllTranslationKeys.onlyAlphanumericOrSymbolsMessage
);
export const PasswordAdvancedSchemaDynamic = PasswordSchemaWithRegex.min(
  8,
  `${AllTranslationKeys.leastLetterMessage}_8`
).max(64, `${AllTranslationKeys.lessLetterMessage}_64`);
export const PasswordConfirmSchema = z.string().min(1, AllTranslationKeys.Error.requiredPasswordConfirmMessage);
export const CountryIdSchema = z.string().min(1, AllTranslationKeys.Error.requiredCountryMessage);
export const PreLaunchDateSchema = z.string().min(1, AllTranslationKeys.Error.requiredPreLaunchDateMessage);
export const CountryIdsSchema = z.array(CountryIdSchema).min(1, AllTranslationKeys.Error.requiredCountryMessage);
export const CompanyNameSchema = z.string().min(1, AllTranslationKeys.requiredCompanyMessage);
export const AgencyNameSchema = z.string().min(1, AllTranslationKeys.Error.requiredAgencyNameMessage);
export const CompanyUrlSchema = z.string().min(1, AllTranslationKeys.Error.requiredAdvertiserUrl);
export const PhoneNumberSchema = z.string().regex(/^\+?[0-9]+$/, AllTranslationKeys.invalidPhoneNumberMessage);
export const PhoneNumberOptionalSchema = PhoneNumberSchema.optional().or(z.literal(''));
export const AdvertiserIdsSchema = z.array(z.string()).min(1, AllTranslationKeys.Error.requiredAdvertiserIdsMessage);
export const AccountManagerPlanSchema = z.nativeEnum(AnalyticsSubscriptionPlanType).or(z.literal(''));
export const AccountManagerPlanRequireSchema = AccountManagerPlanSchema.refine(
  (z) => z !== '',
  AllTranslationKeys.Error.accountManagerPlanIsRequired
);
export const BudgetSchema = getPositiveNumberSchema(AllTranslationKeys.Error.requiredBudgetMessage);
export const UrlSchema = z.string().url(AllTranslationKeys.Error.invalidUrlMessage).or(z.literal(''));
export const CampaignTitleSchema = z
  .string({ required_error: AllTranslationKeys.Error.requiredTitleMessage })
  .trim()
  .min(1, AllTranslationKeys.Error.requiredTitleMessage)
  .max(250, `${AllTranslationKeys.leastLetterMessage}_250`);
export const CampaignDetails = z.string().trim().min(1, AllTranslationKeys.Error.requiredCampaignDetails);
export const SocialMediasSchema = z
  .array(z.nativeEnum(CampaignSocialMediaType))
  .min(1, AllTranslationKeys.Error.requiredSocialMediaMessage);
export const AdvertiserSchemaOptional = z
  .object({
    value: z.string(),
    label: z.string().optional()
  })
  .nullish();
export const AdvertiserSchema = AdvertiserSchemaOptional.refine(
  (adv) => adv,
  AllTranslationKeys.Error.requiredAdvertiserMessage
);
export const AdvertiserIdSchema = z.string().min(1, AllTranslationKeys.Error.requiredAdvertiserMessage);
export const CampaignCategorySchema = z.string().min(1, AllTranslationKeys.Error.requiredCampaignCategoryMessage);
export const PeriodWithStartDateRequiredSchema = z.object({
  startDate: z.coerce
    .date({ invalid_type_error: AllTranslationKeys.Error.invalidDateMessage })
    .or(z.string().min(1, AllTranslationKeys.Error.requiredPeriodMessage)),
  endDate: z.coerce
    .date({ invalid_type_error: AllTranslationKeys.Error.invalidDateMessage })
    .min(new Date(new Date().setHours(0, 0, 0, 0)), AllTranslationKeys.Error.endDateIsTodayOrAfter)
    .or(z.string())
});
export const PeriodStartDateRequiredSchema = z.coerce
  .date({ invalid_type_error: AllTranslationKeys.Error.invalidDateMessage })
  .or(z.string().min(1, AllTranslationKeys.Error.startDateRequired));
export const PeriodEndDateRequiredSchema = z.coerce
  .date({ invalid_type_error: AllTranslationKeys.Error.invalidDateMessage })
  .min(new Date(new Date().setHours(0, 0, 0, 0)), AllTranslationKeys.Error.endDateIsTodayOrAfter)
  .or(z.string().min(1, AllTranslationKeys.Error.endDateRequired));

export const SalesPicsSchema = z.array(z.string()).min(1, AllTranslationKeys.Error.requiredSalesPicMessage);
export const InfluencerManagementPicsSchema = z
  .array(z.string())
  .min(1, AllTranslationKeys.Error.requiredInfluencerManagementPicMessage);
export const IntegerSchema = z.coerce.number().int(AllTranslationKeys.Error.mustBeAnInteger);
export const GenderSchema = z
  .nativeEnum(Genders, { required_error: AllTranslationKeys.Error.requiredGenderMessage })
  .or(z.literal(''))
  .refine((v) => v.length > 1, { message: AllTranslationKeys.Error.requiredGenderMessage });
export const GendersArraySchema = z.array(z.nativeEnum(Genders)).min(1, AllTranslationKeys.Error.requiredGenderMessage);
export const RegionIdSchema = z.string().min(1, AllTranslationKeys.Error.requiredRegion);
export const CategoryIdsSchema = z.array(z.string()).min(1, AllTranslationKeys.Error.requiredCategoriesMessage);
export const getMaxCategoryIdsSchema = (max: number | undefined = 3) =>
  CategoryIdsSchema.max(max, AllTranslationKeys.Error.categoriesLessOrEqualTo3);
export const DateOfBirthSchema = z.string().min(1, AllTranslationKeys.Error.requiredDateOfBirth);
export const PackageNameSchema = z.string().min(1, AllTranslationKeys.Error.requiredPackageNameMessage);
export const ThumbnailsSchema = z.array(z.string()).min(1, AllTranslationKeys.Error.requiredThumbnailMessage);
export const MinAgeSchema = z
  .number()
  .min(0, AllTranslationKeys.Error.requiredMinAgeMessage)
  .or(z.string().min(1, AllTranslationKeys.Error.requiredMinAgeMessage));
export const MaxAgeSchema = z
  .number()
  .min(1, AllTranslationKeys.Error.requiredMaxAgeMessage)
  .or(z.string().min(1, AllTranslationKeys.Error.requiredMaxAgeMessage));
export const MinFollowersSchema = z
  .number()
  .min(0, AllTranslationKeys.Error.requiredMinFollowersMessage)
  .or(z.string().min(1, AllTranslationKeys.Error.requiredMinFollowersMessage));
export const MaxFollowersSchema = z
  .number()
  .min(1, AllTranslationKeys.Error.requiredMaxFollowersMessage)
  .or(z.string().min(1, AllTranslationKeys.Error.requiredMaxFollowersMessage));
export const MatchPositiveDecimalOrEmptyStringSchema = z
  .string()
  .regex(/^\d*\.?\d*$/, {
    message: AllTranslationKeys.Error.numberMustBePositiveMessage
  })
  .or(z.literal(''));

export const getSunEditorSchema = (message = 'field is required') =>
  z.string().refine((v) => v !== SUN_EDITOR_EMPTY_VALUE && v.length > 0, {
    message
  });
export const RequirementSchema = getSunEditorSchema(AllTranslationKeys.Error.requiredRequirementMessage);
export const ServiceInformationSchema = getSunEditorSchema(AllTranslationKeys.Error.requiredServiceInformationMessage);
export const MarginRateSchema = getPositiveNumberSchema(AllTranslationKeys.Error.requiredMarginRate);
export const HashtagsSchema = z
  .array(z.string())
  .min(3, 'leastHashtagsMessage_3')
  .refine((hashtags) => hashtags.length === new Set(hashtags).size, { message: 'HashTagsMustUniqueMessage' });
export const TalentAgencySchema = z.string().min(1, AllTranslationKeys.Error.requiredTaMessage);

export const GenericOptionShape = z.object({
  value: z.string(),
  label: z.string().optional()
});

export const TimezoneSchema = z.string().min(1, AllTranslationKeys.Error.requiredTimezone);
