import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllPartnerAgencyUsers.graphql';
import { Query, QueryallPartnerAgencyUsersArgs } from '../../__generated__/globalTypes';

export const useAllPartnerAgencyUsersQuery = (options?: QueryHookOptions<Query, QueryallPartnerAgencyUsersArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallPartnerAgencyUsersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllPartnerAgencyUsersLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallPartnerAgencyUsersArgs>
): QueryResult<Query, QueryallPartnerAgencyUsersArgs> & {
  getAllPartnerAgencyUsers: LazyQueryExecFunction<Query, QueryallPartnerAgencyUsersArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllPartnerAgencyUsers, result] = useLazyQuery<Query, QueryallPartnerAgencyUsersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllPartnerAgencyUsers, ...result };
};

export const useAllPartnerAgencyUsersPromiseQuery = () => {
  const client = useApolloClient();

  const getAllPartnerAgencyUsers = useCallback(
    (options?: Omit<QueryOptions<QueryallPartnerAgencyUsersArgs, Query>, 'query'>) =>
      client.query<Query, QueryallPartnerAgencyUsersArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllPartnerAgencyUsers };
};
