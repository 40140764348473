import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { usePostsInsightFilter } from './hooks';
import { Template, TemplateProps } from './Template';

export type PostsInsightFilterProps = Omit<TemplateProps, 'onSubmit'>;

export const PostsInsightFilter = (props: PostsInsightFilterProps) => {
  const { filter, setFilterForm } = usePostsInsightFilter();

  return (
    <FilterWrapper>
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template {...props} />} />
    </FilterWrapper>
  );
};

export { usePostsInsightFilter };
export * from './types';
