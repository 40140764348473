import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SendGeminiMessage.graphql';
import { Mutation, MutationsendGeminiMessageArgs } from '../../__generated__/globalTypes';

export const useSendGeminiMessageMutation = (
  options?: MutationHookOptions<Mutation, MutationsendGeminiMessageArgs>
) => {
  const [callSendGeminiMessage, result] = useMutation<Mutation, MutationsendGeminiMessageArgs>(MUTATION, options);

  return { callSendGeminiMessage, ...result };
};

export type SendGeminiMessageMutationFunction = Mutation['sendGeminiMessage'];
