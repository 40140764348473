import { zodValidator } from '@tanstack/zod-adapter';
import { Outlet, redirect } from '@tanstack/react-router';
import { PermissionRoute } from '@/app-components';
import {
  Analytics,
  AddAnalyticsAccount,
  EditAnalyticsAccount,
  AnalyticsProfile,
  AnalyticsAccountParamsSchema,
  AnalyticsHashtag,
  AnalyticsTrend,
  AnalyticsUGC,
  AnalyticsCompare,
  AnalyticsInstagramComparePosts,
  AnalyticsIgFeedPostsListSearchSchema,
  AnalyticsYoutubeCompareVideos,
  AnalyticsYoutubeCompareSearchSchema,
  AnalyticsDashboard,
  AnalyticsFacebookPosts,
  AnalyticsFbFeedPostSearchSchema,
  AnalyticsYoutubeVideos,
  AnalyticsYtVideosSearchSchema,
  AnalyticsInstagramComments,
  AnalyticsIgCommentsSearchSchema,
  AnalyticsInstagramPosts,
  AnalyticsInstagramReelPosts,
  AnalyticsIgReelPostsListSearchSchema,
  AnalyticsInstagramStoryPosts,
  AnalyticsIgStoryPostsListSearchSchema,
  AnalyticsYoutubeShorts,
  AnalyticsYoutubeShortsSearchSchema,
  AnalyticsYoutubeTagsRanking,
  AnalyticsTwitterPosts,
  AnalyticsTwitterPostsSearchSchema,
  AnalyticsYoutubeComments,
  AnalyticsYoutubeCommentsSearchSchema,
  AnalyticsInteraction,
  AnalyticsInstagramBrandAmbassadorPosts,
  AnalyticsIgBrandAmbassadorPostsSearchSchema,
  AnalyticsInstagramMentionedPosts,
  AnalyticsIgMentionedPostsSearchSchema,
  AnalyticsInstagramTaggedPosts,
  AnalyticsIgTaggedPostsSearchSchema,
  AnalyticsAccountParamsSchemaType
} from '@/pages/Analytics';
import { adminStaffAgencyAdvertiserTalentAgencyPartnerRoles } from '@/shared/constants';
import {
  AnalyticsSearchSchema,
  AnalyticsConnectableAccountsSearchStateSchema,
  AnalyticsInstagramHashtagsSearchSchema,
  AnalyticsTrendingYoutubePostsSearchSchema,
  AnalyticsInstagramUGCPostsSearchSchema,
  AnalyticsProfileSearchSchema,
  YtTagRankingSearchSchema
} from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const AnalyticsRouter = {
  '/_private-routes/analytics': [
    () => <PermissionRoute permissions={adminStaffAgencyAdvertiserTalentAgencyPartnerRoles} />,
    {}
  ],
  '/_private-routes/analytics/': [
    Analytics,
    {
      validateSearch: zodValidator(AnalyticsSearchSchema)
    }
  ],

  '/_private-routes/analytics/add': [
    AddAnalyticsAccount,
    { validateSearch: zodValidator(AnalyticsConnectableAccountsSearchStateSchema) }
  ],
  '/_private-routes/analytics/$brandAccountId/': [EditAnalyticsAccount, {}],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId': [
    AnalyticsProfile,
    {
      params: {
        parse: AnalyticsAccountParamsSchema.parse
      }
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/': [
    () => <Outlet />,
    {
      beforeLoad: ({ params }) => {
        redirect({
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard',
          params: params as AnalyticsAccountParamsSchemaType
        });
      },
      params: {
        parse: AnalyticsAccountParamsSchema.parse
      }
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag': [
    AnalyticsHashtag,
    {
      validateSearch: zodValidator(AnalyticsInstagramHashtagsSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend': [
    AnalyticsTrend,
    {
      validateSearch: zodValidator(AnalyticsTrendingYoutubePostsSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc': [
    AnalyticsUGC,
    {
      validateSearch: zodValidator(AnalyticsInstagramUGCPostsSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/': [
    AnalyticsCompare,
    {
      validateSearch: zodValidator(AnalyticsProfileSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post': [
    AnalyticsInstagramComparePosts,
    {
      validateSearch: zodValidator(AnalyticsIgFeedPostsListSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos': [
    AnalyticsYoutubeCompareVideos,
    {
      validateSearch: zodValidator(AnalyticsYoutubeCompareSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/': [
    AnalyticsDashboard,
    {
      validateSearch: zodValidator(AnalyticsProfileSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post': [
    AnalyticsFacebookPosts,
    {
      validateSearch: zodValidator(AnalyticsFbFeedPostSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos': [
    AnalyticsYoutubeVideos,
    {
      validateSearch: zodValidator(AnalyticsYtVideosSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments': [
    AnalyticsInstagramComments,
    {
      validateSearch: zodValidator(AnalyticsIgCommentsSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post': [
    AnalyticsInstagramPosts,
    {
      validateSearch: zodValidator(AnalyticsIgFeedPostsListSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post': [
    AnalyticsInstagramReelPosts,
    {
      validateSearch: zodValidator(AnalyticsIgReelPostsListSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post': [
    AnalyticsInstagramStoryPosts,
    {
      validateSearch: zodValidator(AnalyticsIgStoryPostsListSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts': [
    AnalyticsYoutubeShorts,
    {
      validateSearch: zodValidator(AnalyticsYoutubeShortsSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list': [
    AnalyticsYoutubeTagsRanking,
    {
      validateSearch: zodValidator(YtTagRankingSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts': [
    AnalyticsTwitterPosts,
    {
      validateSearch: zodValidator(AnalyticsTwitterPostsSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments': [
    AnalyticsYoutubeComments,
    {
      validateSearch: zodValidator(AnalyticsYoutubeCommentsSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/': [
    AnalyticsInteraction,
    {
      validateSearch: zodValidator(AnalyticsProfileSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors': [
    AnalyticsInstagramBrandAmbassadorPosts,
    {
      validateSearch: zodValidator(AnalyticsIgBrandAmbassadorPostsSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts': [
    AnalyticsInstagramMentionedPosts,
    {
      validateSearch: zodValidator(AnalyticsIgMentionedPostsSearchSchema)
    }
  ],
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts': [
    AnalyticsInstagramTaggedPosts,
    {
      validateSearch: zodValidator(AnalyticsIgTaggedPostsSearchSchema)
    }
  ]
} as const satisfies PartialRouter;
