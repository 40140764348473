import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '@/shared/types';

declare const IS_DISABLED_ON_PROD: boolean;

export const CrowdinButton = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation();

  const injectCrowdin = useCallback(() => {
    if (window._jipt) {
      return;
    } else {
      window._jipt = [['project', 'anytag_admin']];

      const crowdinWidget = document.createElement('script');
      crowdinWidget.src = '//cdn.crowdin.com/jipt/jipt.js';
      crowdinWidget.type = 'text/javascript';
      crowdinWidget.async = true;

      document.getElementsByTagName('body')[0]?.appendChild(crowdinWidget);
      i18n.changeLanguage(Language['en-JA']);
    }
  }, [i18n.language]);

  return !IS_DISABLED_ON_PROD ? (
    <button
      onClick={injectCrowdin}
      css={{
        width: 'max-content',
        cursor: 'pointer',
        margin: '4px',
        textAlign: 'left',
        padding: '8px 12px',
        borderRadius: '3px',
        '&:hover': {
          boxShadow: '0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);'
        }
      }}
      className={className}
    >
      Translate
    </button>
  ) : null;
};
