import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateMarketplaceInfluencerCost.graphql';
import { Mutation, MutationupdateMarketplaceInfluencerCostArgs } from '../../__generated__/globalTypes';

export const useUpdateMarketplaceInfluencerCostMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateMarketplaceInfluencerCostArgs>
) => {
  const [callUpdateMarketplaceInfluencerCost, result] = useMutation<
    Mutation,
    MutationupdateMarketplaceInfluencerCostArgs
  >(MUTATION, options);

  return { callUpdateMarketplaceInfluencerCost, ...result };
};

export type UpdateMarketplaceInfluencerCostMutationFunction = Mutation['updateMarketplaceInfluencerCost'];
