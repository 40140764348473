import { useState } from 'react';
import { BaseValidations } from '@/shared/validations';
import { MarketplaceFormValues } from '../../MarketplaceCampaignForms';

interface ErrorsKeys {
  productUrl: string;
  websiteUrl: string;
  prompt: string;
}
// since the MKP Description generator form in nested under MKP campaign form, it is hard to separate properly with RHF validation
export const useValidateDescriptionFields = () => {
  const [descriptionErrors, setErrors] = useState<ErrorsKeys>({ productUrl: '', websiteUrl: '', prompt: '' });

  const handleValidateDescriptionInputs = (values: MarketplaceFormValues) => {
    let hasErrors = false;
    if (
      values.descriptionGenerator_additionalInstructions?.length &&
      values.descriptionGenerator_additionalInstructions.length > 10000 // DiFy approx prompt length https://adasiaholdings.atlassian.net/wiki/spaces/NC/pages/3491594241/Campaign+Description+Generation
    ) {
      setErrors((prev) => ({
        ...prev,
        prompt: 'Additional Instructions are too long, max length is 10000 characters'
      }));
      hasErrors = true;
    }
    if (
      values.descriptionGenerator_websiteUrl &&
      !BaseValidations.UrlSchema.safeParse(values.descriptionGenerator_websiteUrl).success
    ) {
      setErrors((prev) => ({ ...prev, websiteUrl: 'Please input a valid URL' }));
      hasErrors = true;
    }
    if (
      values.descriptionGenerator_productUrl &&
      !BaseValidations.UrlSchema.safeParse(values.descriptionGenerator_productUrl).success
    ) {
      setErrors((prev) => ({ ...prev, productUrl: 'Please input a valid URL' }));
      hasErrors = true;
    }

    return hasErrors;
  };
  const resetErrors = (name: keyof ErrorsKeys) => {
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  return {
    isFormValid: !descriptionErrors.productUrl && !descriptionErrors.websiteUrl && !descriptionErrors.prompt,
    resetErrors,
    descriptionErrors,
    handleValidateDescriptionInputs
  };
};
