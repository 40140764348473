import { z } from 'zod';

export const EditEngagementCampaignSearchSchema = z.object({
  state: z
    .object({
      redirectUrlAfterUpdateEngagement: z.string().optional()
    })
    .default({})
    .catch({})
});

export type EditEngagementCampaignSearchSchemaType = z.infer<typeof EditEngagementCampaignSearchSchema>;
