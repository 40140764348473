import { useTranslation } from 'react-i18next';
import { ACCOUNT_TYPE_OPTIONS } from '@/shared/constants';
import { useTranslateOptions } from '@/shared/hooks';
import { RadiosField, RadiosFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const AccountTypeField = (props: Omit<RadiosFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const accountTypeOptions = useTranslateOptions(ACCOUNT_TYPE_OPTIONS);

  return (
    <RadiosField<InfluencerFormKeys>
      help={t('Tooltip.DisplayCampaignHelp')}
      options={accountTypeOptions}
      title={t('Label.Account Type')}
      name={InfluencerSharedKeys.brandAccount}
      {...props}
    />
  );
};
