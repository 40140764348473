import { getValuableVariables } from '@/shared/utils';
import { useFacebookPageAnalyticsOverviewQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { AnalyticsFacebookDashboardOverviewTemplate } from './AnalyticsFacebookDashboardOverviewTemplate';

export interface AnalyticsFacebookDashboardOverviewProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsFacebookDashboardOverview = ({ analyticsAccount }: AnalyticsFacebookDashboardOverviewProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();

  const { data, loading } = useFacebookPageAnalyticsOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      facebookPageAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsFacebookDashboardOverviewTemplate
      loading={loading}
      accountId={analyticsAccount.id}
      data={data?.facebookPageAnalyticsOverview}
    />
  );
};
