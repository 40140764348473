import { InfluencerNewProfileHeaderV2, SocialAccountType } from '@/graphql';
import { InfluencerSelectedAccountInfo, InfluencerPromotionMethodsType } from '@/shared/organisms';
import { SupportedSocialMediaType } from '@/shared/types';

export const getAllAvailableInfluencerAccounts = (
  influencerProfile: InfluencerNewProfileHeaderV2
): InfluencerSelectedAccountInfo[] => [
  ...(influencerProfile.instagramAccounts?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    url: acc.url || '',
    profile: acc.profile || '',
    name: acc.name || 'Instagram',
    type: SocialAccountType.INSTAGRAM,
    analyticsEnabled: acc.analyticsEnabled
  })) || []),
  ...(influencerProfile.youtubeAccounts?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    url: acc.url || '',
    profile: acc.profile || '',
    name: acc.name || 'Youtube',
    type: SocialAccountType.YOUTUBE,
    analyticsEnabled: acc.analyticsEnabled
  })) || []),
  ...(influencerProfile.facebookPages?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    url: acc.url || '',
    profile: acc.profile || '',
    name: acc.name || 'Facebook Page',
    type: SocialAccountType.FACEBOOK_PAGE,
    analyticsEnabled: acc.analyticsEnabled
  })) || []),
  ...(influencerProfile.facebookAccount
    ? [
        {
          type: SocialAccountType.FACEBOOK,
          id: influencerProfile.facebookAccount.id,
          url: influencerProfile.facebookAccount.url || '',
          profile: influencerProfile.facebookAccount.profile || '',
          name: influencerProfile.facebookAccount.name || 'Facebook'
        }
      ]
    : []),
  ...(influencerProfile.twitterAccounts?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    url: acc.url || '',
    profile: acc.profile || '',
    name: acc.name || 'X(Twitter)',
    type: SocialAccountType.TWITTER,
    analyticsEnabled: acc.analyticsEnabled
  })) || []),
  ...(influencerProfile.tiktokAccounts?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    url: acc.url || '',
    profile: acc.profile || '',
    name: acc.name || 'Tiktok',
    type: SocialAccountType.TIKTOK,
    ttcmStatus: acc.ttcmStatus || undefined
  })) || []),
  ...(influencerProfile.threadsAccounts?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    url: acc.url || '',
    profile: acc.profile || '',
    name: acc.name || 'Threads',
    type: SocialAccountType.THREADS
  })) || []),
  ...(influencerProfile.douyinAccounts?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    url: acc.url || '',
    profile: acc.profile || '',
    name: acc.name || 'Douyin',
    type: SocialAccountType.DOUYIN
  })) || []),
  ...(influencerProfile.xhsAccounts?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    name: acc.name || 'Xiaohongshu',
    url: acc.url || '',
    profile: acc.profile || '',
    type: SupportedSocialMediaType.XHS
  })) || []),
  ...(influencerProfile.websites?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    name: 'Website',
    url: acc.url || '',
    profile: acc.description || '',
    type: InfluencerPromotionMethodsType.websites
  })) || []),
  ...(influencerProfile.emailNewsLetters?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    url: acc.email || '',
    name: 'Email/Newsletter',
    profile: acc.description || '',
    type: InfluencerPromotionMethodsType.emailNewsLetters
  })) || []),
  ...(influencerProfile.offlines?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    name: 'Other',
    profile: acc.description || '',
    type: InfluencerPromotionMethodsType.offlines
  })) || []),
  ...(influencerProfile.mobileApps?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    url: acc.url || '',
    name: 'Mobile App',
    profile: acc.mobileAppType || '',
    type: InfluencerPromotionMethodsType.mobileApps
  })) || []),
  ...(influencerProfile.podCasts?.map<InfluencerSelectedAccountInfo>((acc) => ({
    id: acc.id,
    name: 'Podcast',
    url: acc.url || '',
    profile: acc.description || '',
    type: InfluencerPromotionMethodsType.podCasts
  })) || [])
];
