import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/User.graphql';
import { Query, QueryuserArgs } from '../../__generated__/globalTypes';

export const useUserQuery = (options?: QueryHookOptions<Query, QueryuserArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryuserArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useUserLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryuserArgs>
): QueryResult<Query, QueryuserArgs> & { getUser: LazyQueryExecFunction<Query, QueryuserArgs> } => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getUser, result] = useLazyQuery<Query, QueryuserArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getUser, ...result };
};

export const useUserPromiseQuery = () => {
  const client = useApolloClient();

  const getUser = useCallback(
    (options?: Omit<QueryOptions<QueryuserArgs, Query>, 'query'>) =>
      client.query<Query, QueryuserArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getUser };
};
