import { isAfter, isBefore, isSameDay } from 'date-fns';
import { MouseEvent } from 'react';
import { DayModifiers } from 'react-day-picker';
import { PickerDate, Range } from './types';

export const useHandlers = (state: Range, defaultInputState: Range, setState: (range: Range) => void) => {
  const { from, to, hoverTo, hoverFrom, isRangeSet } = state;

  const isSelectingFirstDay = () => {
    const isRangeSelected = Boolean(from && to && hoverFrom && hoverTo);

    return !from || isRangeSelected;
  };

  const handleResetClick = () => {
    setState(defaultInputState);
  };

  const handleDayClick = (day: PickerDate, _: DayModifiers, e: MouseEvent<Element>) => {
    const isDayDisabled = (e.target as HTMLDivElement).getAttribute('aria-disabled') === 'true';

    if (isDayDisabled) {
      return;
    }

    if (state.isRangeSet) {
      handleResetClick();
    }

    const isFirstDay = isSelectingFirstDay();
    if (isFirstDay) {
      setState({
        from: day,
        to: undefined,
        hoverTo: undefined,
        hoverFrom: day,
        isRangeSet: false
      });
    } else if (state.from && day && isSameDay(state.from, day)) {
      setState({
        from: day,
        hoverFrom: day,
        to: day,
        hoverTo: day,
        isRangeSet: false
      });
    } else if (state.from && day && isAfter(state.from, day)) {
      setState({
        from: day,
        hoverFrom: day,
        to: from,
        hoverTo: from,
        isRangeSet: true
      });
    } else {
      setState({ ...state, to: day, hoverTo: day, isRangeSet: true });
    }
  };

  const handleDayMouseEnter = (day: PickerDate, modifiers: DayModifiers) => {
    const clickedDayProp = Object.keys(modifiers);
    const isDayDisabled = clickedDayProp.some((prop) => prop.includes('disabled'));

    if (isDayDisabled || isRangeSet) {
      return;
    }

    const isHoverToBeforeFrom = state.from && day ? isBefore(day, state.savedForReverse || state.from) : false;

    if (!from) {
      setState({
        from: undefined,
        hoverFrom: day,
        to: undefined,
        hoverTo: undefined
      });
    } else if (isHoverToBeforeFrom) {
      // means "to" is behind "from", so we need to swap them
      setState({
        from: day,
        hoverFrom: undefined,
        to: state.savedForReverse || hoverFrom,
        hoverTo: undefined,
        savedForReverse: state.savedForReverse || hoverFrom
      });
    } else {
      setState({
        from: from,
        hoverFrom: undefined,
        to: day,
        hoverTo: undefined,
        savedForReverse: undefined
      });
    }
  };

  return {
    handleResetClick,
    handleDayClick,
    handleDayMouseEnter
  };
};
