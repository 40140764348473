import { useTranslation } from 'react-i18next';
import { InstagramFeedPost, InstagramFeedPostSortField } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';

type CustomColumnProps = ColumnProps<InstagramFeedPostSortField>;

interface Props {
  data: readonly InstagramFeedPost[];
}

export const useInfluencerInstagramPostsListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    {
      title: <div css={{ display: 'flex', alignItems: 'center' }}>{t('HeaderColumn.Post')}</div>,
      sticky: { active: true, hasBorderRight: true }
    },
    getMetricColumnProps({
      title: t('HeaderColumn.Impression'),
      help: t<string>('Tooltip.ImpsHelp'),
      sortField: InstagramFeedPostSortField.IMPRESSIONS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Reach'),
      help: t<string>('Tooltip.ReachPostHelp'),
      sortField: InstagramFeedPostSortField.REACH
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      help: t<string>('Tooltip.LikeHelp'),
      sortField: InstagramFeedPostSortField.LIKE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      help: t<string>('Tooltip.CommentHelp'),
      sortField: InstagramFeedPostSortField.COMMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Save'),
      help: t<string>('Tooltip.SaveHelp'),
      sortField: InstagramFeedPostSortField.SAVED
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      help: t<string>('Tooltip.PostDateHelp'),
      sortField: InstagramFeedPostSortField.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({ id, reach, likes, saved, content, postDate, comments, thumbNail, impressions }) => [
      <TableCell.Post
        title={content || ''}
        thumbnail={thumbNail || ''}
        css={{ maxWidth: 'unset' }}
        onClick={() => setPostDetailsParam(id)}
      />,
      <TableCell.Number value={formatIntNumber(impressions)} />,
      <TableCell.Number value={formatIntNumber(reach)} />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(saved)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
