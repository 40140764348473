import { useSearch } from '@tanstack/react-router';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import {
  TrendingInfluencersFilter,
  TrendingInfluencersSearchFilterSchemaType,
  TrendingInfluencersTabType
} from '@/shared/organisms';
import { TrendingInstagramInfluencers } from './TrendingInstagramInfluencers';
import { TrendingFacebookInfluencers } from './TrendingFacebookInfluencers';
import { TrendingYoutubeInfluencers } from './TrendingYoutubeInfluencers';

export const TrendingInfluencers = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.Trending Influencers', appHeader: 'appHeader.Trending Influencers' });
  const { filter } = useSearch({
    from: '/_private-routes/trending/influencers'
  }) satisfies TrendingInfluencersSearchFilterSchemaType;

  return (
    <>
      <TrendingInfluencersFilter />
      {filter.tab === TrendingInfluencersTabType.FACEBOOK ? (
        <TrendingFacebookInfluencers />
      ) : filter.tab === TrendingInfluencersTabType.YOUTUBE ? (
        <TrendingYoutubeInfluencers />
      ) : (
        <TrendingInstagramInfluencers />
      )}
    </>
  );
};
