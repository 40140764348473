import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from '@tanstack/react-router';
import { AppProviders, client } from '@/providers';
import { useAuthData } from '@/auth';
import { router } from '@/router';
import { RouterContext } from '@/shared/types';
import { useDefaultAppRoute } from '@/shared/hooks';
import { NotFound } from '../pages/Public/NotFound';
import './init-router-pages';

declare const GA_TRACKING_CODE: string;
declare const GTM_ID: string;

const initializeReactGA = (trackingCode: string) => {
  ReactGA.initialize(trackingCode);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

export const App = () => {
  const { i18n } = useTranslation();
  const { auth } = useAuthData();
  const { defaultRoute } = useDefaultAppRoute();

  useEffect(() => {
    const root = document.getElementById('container');

    if (root) {
      root.setAttribute('lang', i18n.language);
    }
  }, [i18n.language, i18n.isInitialized]);

  useEffect(() => {
    if (GA_TRACKING_CODE) {
      initializeReactGA(GA_TRACKING_CODE);
    }
    if (GTM_ID) {
      TagManager.initialize({ gtmId: GTM_ID });
    }
  }, []);

  return (
    <AppProviders>
      <RouterProvider
        router={router}
        context={{ auth, defaultRoute, queryClient: client } satisfies RouterContext}
        defaultNotFoundComponent={NotFound}
      />
    </AppProviders>
  );
};
