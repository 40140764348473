import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField, TextFieldProps } from '@/shared/molecules';

import { MarketplaceFormValues, MarketplaceFormKeys } from '../types';

export const BudgetField = (props: Omit<TextFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<MarketplaceFormValues>();
  const currency = watch(MarketplaceFormKeys.currency);

  return (
    <TextField
      unit={t(currency)}
      placeholder="1000000"
      help={t('Tooltip.Budget')}
      title={t('TextForm.Budget')}
      name={MarketplaceFormKeys.budget}
      {...props}
    />
  );
};
