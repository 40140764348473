import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { InstagramInteractionOverview } from '@/graphql';
import { objectKeys } from '@/shared/assets';
import { THEME } from '@/shared/constants';
import { useSelectItemIds } from '@/shared/hooks';
import {
  AnalyticsOverviewChart,
  AnalyticsOverviewLegend,
  AnalyticsOverviewLegendType,
  AnalyticsOverviewEngagement,
  AnalyticsOverviewWrapper
} from '../../../shared';
import { useAnalyticsInstagramInteractionOverviewData } from './hooks';
import { InstagramInteractionGraphKeys as Keys } from './types';

interface TemplateProps {
  data: InstagramInteractionOverview;
}

export const Template = ({ data }: TemplateProps) => {
  const { t } = useTranslation();
  const { selectItem: selectLegendMetric, selectedItemIds: selectedLegendMetrics } = useSelectItemIds<Keys>({
    defaultSelectedIds: ['followers', 'taggedPosts', 'mentionedPosts']
  });
  const { engagement, chartData, categories } = useAnalyticsInstagramInteractionOverviewData({
    data,
    selectedMetrics: selectedLegendMetrics
  });

  const legendOptions = objectKeys(chartData).map<AnalyticsOverviewLegendType<Keys>>((key) => {
    const { name, color } = chartData[key];

    return { label: name, value: key, color: color as string };
  });

  const handleSelectLegendOption = ({ value }: AnalyticsOverviewLegendType<Keys>) => {
    if (!value) return;

    selectLegendMetric(value);
  };

  return (
    <AnalyticsOverviewWrapper
      help={t('Tooltip.interactionOverview')}
      dataNotAvailable={!engagement.some((item) => !!item.percentage)}
    >
      <div>
        <AnalyticsOverviewEngagement engagement={engagement} />

        <div css={styles.chartWrapper}>
          <AnalyticsOverviewLegend
            options={legendOptions}
            selectedOptions={selectedLegendMetrics}
            onSelectOption={handleSelectLegendOption}
          />
          <AnalyticsOverviewChart
            data={chartData}
            categories={categories}
            customTooltip={{
              metrics: [
                ['followers'],
                ['taggedPosts', 'taggedLikes', 'taggedComments'],
                ['mentionedPosts', 'mentionedLikes', 'mentionedComments']
              ]
            }}
          />
        </div>
      </div>
    </AnalyticsOverviewWrapper>
  );
};
const styles = {
  chartWrapper: css({
    color: THEME.text.colors.black.lv1,
    backgroundColor: THEME.background.colors.white,
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    borderTop: 0
  })
};
