import { css, Global } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { emptyAdCampaignReportBlurImage, adManagerReportImage, adReportChartImage } from '@/shared/assets';
import { ButtonLink } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export const EmptyAdCampaign = () => {
  const { t } = useTranslation();

  return (
    <div css={{ position: 'relative' }}>
      <Global
        styles={css({
          '.layout-wrapper': {
            padding: '0 !important' // remove padding from global Layout component
          }
        })}
      />
      <img src={emptyAdCampaignReportBlurImage} css={styles.bgBlur} alt="tiktok report" />
      <div css={styles.modalContainer}>
        <div css={styles.gradientLayer1} />
        <div css={styles.gradientLayer2} />
        <div css={styles.topTextContainer}>
          <h3 css={{ fontSize: '24px', fontWeight: 600 }}>{t('Welcome to AnyTag')}</h3>
          <p css={{ fontSize: '14px', marginTop: '6px' }}>{t('We will support your TikTok Ads')}</p>
        </div>
        <div css={styles.topImagesContainer}>
          <img src={adManagerReportImage} alt="ad-manager-ui" css={styles.reportSvg} />
          <svg width="14" height="42" viewBox="0 0 14 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L13 21L1 41" stroke="#C5D0DA" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          <img src={adReportChartImage} alt="anytag-report-preview" css={styles.reportSvg} />
        </div>

        <div css={[styles.bottomImageTextContainer, { left: 70 }]}>
          <h3 css={{ fontSize: '14px', fontWeight: 600 }}>{t('Create Campaign')}</h3>
          <p css={{ marginTop: '2px', fontSize: '13px', color: THEME.text.colors.gray.lv3 }}>
            {t('Create and run a campaign in Ads Manager')}
          </p>
        </div>
        <div css={[styles.bottomImageTextContainer, { right: 70 }]}>
          <h3 css={{ fontSize: '14px', fontWeight: 600 }}>{t('Visualize Ads Report')}</h3>
          <p css={{ marginTop: '2px', fontSize: '13px', color: THEME.text.colors.gray.lv3 }}>
            {t('TikTok Ads Report will be displayed in AnyTag')}
          </p>
        </div>
        <div css={styles.btnWrapper}>
          <ButtonLink
            variant="blue"
            title={t('Button.Create campaign')}
            as={'a'}
            href="https://ads.tiktok.com/i18n/home"
            target="_blank"
            rel="noopener noreferrer"
            css={{ minWidth: '146px', width: 'max-content', margin: 'auto', textTransform: 'capitalize' }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalContainer: css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '24px',
    width: '560px',
    height: '486px',
    background: THEME.background.colors.white
  }),
  bgBlur: css({ width: '100%', height: '100vh', objectFit: 'cover' }),
  gradientLayer1: css({
    width: 560,
    height: 244,
    left: 0,
    top: 0,
    position: 'absolute',
    borderRadius: '24px 24px 0 0',
    background:
      'linear-gradient(97deg, rgba(254, 111, 131, 0.50) 0%, rgba(252.42, 140.05, 63.97, 0.50) 45%, rgba(255, 179.87, 94, 0.50) 100%)'
  }),
  gradientLayer2: css({
    width: 560,
    height: 201,
    left: 0,
    top: 45,
    position: 'absolute',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 100%)'
  }),
  topTextContainer: css({ position: 'absolute', top: 56, width: '100%', textAlign: 'center' }),
  topImagesContainer: css({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
    bottom: 201,
    left: 73
  }),
  reportSvg: css({ width: '169px', borderRadius: '8px', boxShadow: '0px 4px 10px 0px rgba(110, 124, 137, 0.16);' }),
  btnWrapper: css({
    padding: '16px 0',
    display: 'flex',
    alignContent: 'center',
    position: 'absolute',
    top: 395,
    width: '100%',
    textAlign: 'center'
  }),
  bottomImageTextContainer: css({
    display: '',
    position: 'absolute',
    top: 308,
    width: '169px',
    textAlign: 'center'
  })
};
