import { InstagramInteractionPost } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InstagramInteractionMentionedPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useAnalyticsInstagramMentionedPostsListTable } from './hooks';

export interface AnalyticsInstagramMentionedPostsListProps extends TableData<InstagramInteractionPost> {
  mainSocialAccountId: number;
}

export const AnalyticsInstagramMentionedPostsList = ({
  loading,
  data = [],
  mainSocialAccountId
}: AnalyticsInstagramMentionedPostsListProps) => {
  const { rows, columns } = useAnalyticsInstagramMentionedPostsListTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InstagramInteractionMentionedPostDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId }))}
      />
    </>
  );
};
