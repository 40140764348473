import { FormProvider } from '@/shared/atoms';
import { PackageFormSchema } from './schema';
import { Template } from './Template';
import { PackageFormProps, PackageFormValues } from './types';

export const PackageForm = ({ onSubmit, defaultValues }: PackageFormProps) => (
  <FormProvider<PackageFormValues>
    onSubmit={onSubmit}
    values={{
      id: defaultValues?.id,
      name: defaultValues?.name || '',
      countryId: defaultValues?.countryId || '',
      companies: defaultValues?.companies?.length ? defaultValues.companies : []
    }}
    zodSchema={PackageFormSchema}
  >
    <Template />
  </FormProvider>
);
export * from './types';
