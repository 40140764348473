import { InfluencersFilterWrapper } from '../shared';
import { CampaignInfluencersProps } from './schemaTypes';
import { CampaignInfluencersList } from './CampaignInfluencersList';
import { CampaignInfluencersFilter } from './CampaignInfluencersFilter';
import { CampaignInfluencersProvider } from './CampaignInfluencersContext';

export const CampaignInfluencers = (props: CampaignInfluencersProps) => (
  <CampaignInfluencersProvider value={props}>
    <InfluencersFilterWrapper>
      <CampaignInfluencersFilter />
    </InfluencersFilterWrapper>

    <CampaignInfluencersList />
  </CampaignInfluencersProvider>
);

export * from './schemaTypes';
