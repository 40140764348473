import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useUserRoles } from '@/auth';
import { THEME } from '@/shared/constants';
import { YoutubeCmsEstimateAsset } from '@/graphql';
import { formatNumber, formatPercent } from '@/shared/utils';
import { ColumnProps, RowProps, TableCell, TextCutter } from '@/shared/atoms';

interface Props {
  data: readonly YoutubeCmsEstimateAsset[];
}

export const useYoutubeCMSChannelsAssetsTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Asset name'), sticky: { active: true, hasBorderRight: isAdminStaff } },
    { title: t('HeaderColumn.Estimated RPM'), styles: { textAlign: 'right' }, hidden: !isAdminStaff },
    { title: t('HeaderColumn.Estimated talent agency RPM'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Estimated partner revenue'), styles: { textAlign: 'right' }, hidden: !isAdminStaff },
    { title: t('HeaderColumn.Estimated talent agency revenue'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Estimated share to creator'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Estimated GP'), styles: { textAlign: 'right' }, hidden: !isAdminStaff },
    { title: t('HeaderColumn.Estimated talent agency GP'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Talent agency margin rate'), styles: { textAlign: 'right' }, hidden: !isAdminStaff }
  ];
  const rows: RowProps[] = data.map(
    ({
      rpm,
      taRpm,
      profit,
      assetId,
      isOwner,
      currency,
      taProfit,
      taRevenue,
      assetName,
      taShareRate,
      shareCreator,
      partnerRevenue
    }) => [
      <div css={{ display: 'flex', minWidth: '200px', alignItems: 'center' }}>
        <div css={{ lineHeight: '100%' }}>
          <TextCutter css={{ fontSize: '14px' }} text={assetName || '-'} lines={2} />
          <p css={{ fontSize: '10px', color: THEME.text.colors.gray.lv3, marginTop: '4px' }}>
            {t('id')}: {assetId}
          </p>
        </div>
        {isOwner ? <div css={styles.owner}>{t('Owner')}</div> : null}
      </div>,
      <TableCell.Number value={formatNumber(rpm)} unit={t(currency)} />,
      <TableCell.Number value={formatNumber(taRpm)} unit={t(currency)} />,
      <TableCell.Number value={formatNumber(partnerRevenue)} unit={t(currency)} />,
      <TableCell.Number value={formatNumber(taRevenue)} unit={t(currency)} />,
      <TableCell.Number value={formatNumber(shareCreator)} unit={t(currency)} />,
      <TableCell.Number value={formatNumber(profit || null)} unit={t(currency)} />,
      <TableCell.Number value={formatNumber(taProfit || null)} unit={t(currency)} />,
      <TableCell.Number value={formatPercent(taShareRate, false)} unit="%" />
    ]
  );

  return { rows, columns };
};
const styles = {
  owner: css({
    marginLeft: '16px',
    padding: '3px 8px',
    width: 'max-content',
    borderRadius: '35px',
    boxSizing: 'border-box',
    backgroundColor: '#eef3f7'
  })
};
