import { ReconnectAnalyticsAccountType } from '@/shared/types';
import { jsonParseOrStringify } from '@/shared/utils';
import { AnalyticsFilterFormValues } from '../AnalyticsFilter';

interface LocalStorageValueShape {
  reconnectingAccount: ReconnectAnalyticsAccountType;
  page?: number;
  filter: AnalyticsFilterFormValues;
}
interface AnalyticsAccountFormStateProps {
  params?: LocalStorageValueShape;
  stringifiedData?: string | null;
}

export const setAnalyticsListReconnectState = jsonParseOrStringify<AnalyticsAccountFormStateProps>;
export const getAnalyticsListReconnectState = jsonParseOrStringify<
  AnalyticsAccountFormStateProps,
  LocalStorageValueShape
>;
