import { useUserRoles } from '@/auth';
import {
  useRequestProposalPptxMutation,
  useRequestProposalExcelMutation,
  useRequestProposalSpreadsheetMutation
} from '@/graphql';
import { ButtonDropdown, DownloadSubmenuIcon } from '@/shared/atoms';
import { GTM_IDS } from '@/shared/constants';
import { useDownloadFile, useQueryHelper } from '@/shared/hooks';
import { DownloadFileType, DropdownOption } from '@/shared/types';
import { getRestApiUrl } from '@/shared/utils';

export interface ProposalDownloadButtonProps {
  disabled?: boolean;
  className?: string;
  engagementId: number;
  influencerIds?: number[];
}

export const ProposalDownloadButton = ({
  disabled,
  className,
  engagementId,
  influencerIds = []
}: ProposalDownloadButtonProps) => {
  const { isAdminStaff, isPartnerFromUuum } = useUserRoles();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { callRequestProposalPptx, loading: downloadingPptx } = useRequestProposalPptxMutation();
  const { callRequestProposalExcel, loading: downloadingExcel } = useRequestProposalExcelMutation();
  const { callRequestProposalSpreadsheet, loading: downloadingSpreadsheet } = useRequestProposalSpreadsheetMutation();

  const loading = downloading || downloadingPptx || downloadingExcel || downloadingSpreadsheet;
  const canDownloadReport = isAdminStaff || isPartnerFromUuum;

  const downloadOptions: DropdownOption[] = [
    {
      value: 'Profile Sheet',
      menuType: 'multi-level',
      label: t('Profile Sheet'),
      submenu: [
        {
          label: t<string>('PowerPoint'),
          value: DownloadFileType.PPTX,
          gtmId: GTM_IDS.engagement.proposal_pptx_download,
          prefixIcon: <DownloadSubmenuIcon icon="powerPoint" />
        },
        ...(canDownloadReport
          ? [
              {
                label: t<string>('Google Slide'),
                value: DownloadFileType.GOOGLE_SLIDE,
                gtmId: GTM_IDS.engagement.proposal_google_slide_download,
                prefixIcon: <DownloadSubmenuIcon icon="googleSlide" />
              }
            ]
          : [])
      ]
    },
    {
      value: 'Proposal List',
      menuType: 'multi-level',
      label: t('Proposal List'),
      submenu: [
        {
          label: t<string>('CSV'),
          value: DownloadFileType.CSV,
          gtmId: GTM_IDS.engagement.proposal_csv_download,
          prefixIcon: <DownloadSubmenuIcon icon="csv" />
        },
        {
          label: t<string>('Excel'),
          value: DownloadFileType.EXCEL,
          gtmId: GTM_IDS.engagement.proposal_excel_download,
          prefixIcon: <DownloadSubmenuIcon icon="excel" />
        },
        ...(canDownloadReport
          ? [
              {
                label: t('Spreadsheet'),
                value: DownloadFileType.SPREADSHEET,
                gtmId: GTM_IDS.engagement.proposal_spreadsheet_download,
                prefixIcon: <DownloadSubmenuIcon icon="spreadsheet" />
              }
            ]
          : [])
      ]
    }
  ];

  const handleClickDownloadProposal = (type: DownloadFileType) => async () => {
    const variables = { engagementId, influencerIds: influencerIds.length > 0 ? influencerIds : null };

    try {
      switch (type) {
        case DownloadFileType.EXCEL:
          callRequestProposalExcel({ variables });
          break;
        case DownloadFileType.SPREADSHEET:
          callRequestProposalSpreadsheet({ variables });
          break;
        case DownloadFileType.PPTX:
          callRequestProposalPptx({ variables: { ...variables, format: 'PPTX' } });
          break;
        case DownloadFileType.GOOGLE_SLIDE:
          callRequestProposalPptx({ variables: { ...variables, format: 'GOOGLE_SLIDE' } });
          break;
        default:
          break;
      }

      enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToDownload'), { variant: 'error' });
    }
  };

  const handleSelectOption = async (option: DropdownOption) => {
    switch (option.value) {
      case DownloadFileType.CSV:
        handleDownloadFile({
          filenamePrefix: 'proposal',
          params: { ids: influencerIds },
          url: getRestApiUrl(`engagement/${engagementId}/proposal/csv/`)
        });
        break;
      case DownloadFileType.PPTX:
        handleClickDownloadProposal(DownloadFileType.PPTX)();
        break;
      case DownloadFileType.SPREADSHEET:
        handleClickDownloadProposal(DownloadFileType.SPREADSHEET)();
        break;
      case DownloadFileType.EXCEL:
        handleClickDownloadProposal(DownloadFileType.EXCEL)();
        break;
      case DownloadFileType.GOOGLE_SLIDE:
        handleClickDownloadProposal(DownloadFileType.GOOGLE_SLIDE)();
        break;
      default:
        break;
    }
  };

  return (
    <ButtonDropdown
      variant="white"
      loading={loading}
      disabled={disabled}
      className={className}
      options={downloadOptions}
      css={{ minWidth: '133px' }}
      title={t('Button.Download')}
      onSelectOption={handleSelectOption}
      prefixIcon={{ icon: 'download', size: '14px' }}
    />
  );
};
