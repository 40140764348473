import { useTranslation } from 'react-i18next';
import {
  WarningReason,
  EngagementCampaignPostStatus,
  EngagementCampaignSocialAccountPostStatus
} from '@/graphql';
import { Status, StatusProps } from '@/shared/atoms';
import { ColorVariant } from '@/shared/types';
import { getPostWarningReasonStatusProps } from '@/shared/utils';

export interface EngagementPostStatusProps {
  className?: string;
  warningReason?: WarningReason;
  status: EngagementCampaignPostStatus | EngagementCampaignSocialAccountPostStatus;
}

export const EngagementPostStatus = ({ status, warningReason, className }: EngagementPostStatusProps) => {
  const { t } = useTranslation();

  const hasReason = status === EngagementCampaignPostStatus.WARNING && !!warningReason;
  const statusProps: StatusProps = (() => {
    if (hasReason) {
      const { label, variant } = getPostWarningReasonStatusProps(warningReason);

      return { label: t(label), variant };
    } else {
      switch (status) {
        case EngagementCampaignPostStatus.REVIEWING:
          return { label: t('Option.Reviewing'), variant: ColorVariant.LIGHT_PURPLE };
        case EngagementCampaignPostStatus.READY_TO_POST:
          return { label: t('Option.Ready to Post'), variant: ColorVariant.LIGHT_GREEN };
        case EngagementCampaignPostStatus.EFFECTIVE:
          return { label: t('Option.Effective'), variant: ColorVariant.LIGHT_GREEN };
        case EngagementCampaignPostStatus.WARNING:
          return { label: t('Option.Warning'), variant: ColorVariant.LIGHT_RED };
        case EngagementCampaignPostStatus.WARNING_SOLVED:
          return { label: t('Option.Effective'), variant: ColorVariant.LIGHT_GREEN };
        case EngagementCampaignPostStatus.APPROVED:
          return { label: t('Option.Approved'), variant: ColorVariant.LIGHT_GREEN };
        case EngagementCampaignPostStatus.INEFFECTIVE:
          return { label: t('Option.Ineffective'), variant: ColorVariant.LIGHT_GREY };
        case EngagementCampaignPostStatus.REJECT:
          return { label: t('Option.Rejected'), variant: ColorVariant.LIGHT_GREY };
        case EngagementCampaignPostStatus.TTCM_REVIEWING:
          return { label: t('Option.TikTok Review'), variant: ColorVariant.LIGHT_YELLOW };
        case EngagementCampaignSocialAccountPostStatus.JOINED:
        case EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_ORDER:
        case EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_INVITATION:
          return { label: t('Option.Joined'), variant: ColorVariant.LIGHT_BLUE };
        default:
          return { label: '', variant: ColorVariant.DARK_GREY };
      }
    }
  })();

  return <Status {...statusProps} className={className} />;
};
