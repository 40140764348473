import { FilterProvider } from '@/shared/molecules';
import { useThreadsInfluencersContext } from '../ThreadsInfluencersContext';
import { BasicFilter } from './BasicFilter';

export const ThreadsInfluencersFilter = () => {
  const { filter, setFilter, isEngagementPage, isPackagePage } = useThreadsInfluencersContext();

  return (
    <FilterProvider
      onSubmit={setFilter}
      initialValues={filter}
      basicFilter={<BasicFilter isEngagementPage={isEngagementPage} isPackagePage={isPackagePage} />}
    />
  );
};
