import { z } from 'zod';
import { AllTranslationKeys } from '@/shared/assets';
import { BaseValidations } from '@/shared/validations';

const BaseSchema = z.object({
  logo: z.string().optional(),
  hasInvitationEmail: z.boolean(),
  hubspotId: z.string().nullable().optional(),
  companyUrl: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  internalNetsuiteId: z.string().nullable().optional(),
  countryId: BaseValidations.CountryIdSchema,
  agencyName: BaseValidations.AgencyNameSchema,
  advertiserIds: BaseValidations.AdvertiserIdsSchema
});

const AddAgencyFormSchema = z.object({
  type: z.literal('add'),
  password: z.string().optional(),
  name: BaseValidations.NameSchema,
  email: BaseValidations.EmailSchema,
  passwordConfirm: z.string().optional()
});
const EditAgencyFormSchema = z.object({
  type: z.literal('edit'),
  id: z.number()
});
export const AgencyFormSchema = z
  .discriminatedUnion('type', [AddAgencyFormSchema, EditAgencyFormSchema])
  .and(BaseSchema)
  .superRefine((values, ctx) => {
    if (!values.hasInvitationEmail && values.type === 'add') {
      const passwordResult = BaseValidations.PasswordAdvancedSchemaDynamic.safeParse(values.password);
      const confirmPasswordResult = BaseValidations.PasswordAdvancedSchemaDynamic.safeParse(values.passwordConfirm);
      if (passwordResult.success === false && values.passwordConfirm) {
        ctx.addIssue({
          message: passwordResult.error.issues.at(0)?.message,
          code: z.ZodIssueCode.custom,
          path: ['password']
        });
      }

      if (confirmPasswordResult.success === false && values.password) {
        ctx.addIssue({
          message: confirmPasswordResult.error.issues.at(0)?.message,
          code: z.ZodIssueCode.custom,
          path: ['passwordConfirm']
        });
      }

      if (confirmPasswordResult.success && passwordResult.success && values.passwordConfirm !== values.password) {
        ctx.addIssue({
          message: AllTranslationKeys.Error.passwordsDoNotMatch,
          code: z.ZodIssueCode.custom,
          path: ['passwordConfirm']
        });
      }
    }
  });
