import { css } from '@emotion/react';
import { ReadPackageAccount } from '@/graphql';
import { Button, RowProps, TableCell } from '@/shared/atoms';
import { formatIntNumber } from '@/shared/utils';
import { useTranslateCountry, useSelectItemIds } from '@/shared/hooks';
import { WidgetSelectButton } from '../../shared';
import { InfluencersListWidget } from '../InfluencersListWidget';
import { useInfluencerPackagesContext } from '../InfluencerPackagesContext';

export const useInfluencerPackagesRowWidget = () => {
  const { tc } = useTranslateCountry();
  const { selectedItemIds, selectItem } = useSelectItemIds();
  const { getListSelectButtonProps } = useInfluencerPackagesContext();

  const generateInfluencerPackagesRowWidget = ({
    columnsLength,
    packageAccount
  }: {
    columnsLength: number;
    packageAccount: ReadPackageAccount;
  }): RowProps[] => {
    const { id, name, country, accounts, companies } = packageAccount;
    const isExpanded = selectedItemIds.includes(id);
    const rowStyles = css({ 'td:first-of-type': { paddingLeft: 0, paddingRight: 0 } });

    return [
      {
        styles: rowStyles,
        cells: [
          <WidgetSelectButton {...getListSelectButtonProps?.(packageAccount)} />,
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <TableCell.Link
              label={name}
              variant="blue"
              target="_blank"
              to="/package/$id/content"
              params={{ id: id.toString() }}
            />
          </div>,
          <TableCell.Text
            lines={2}
            value={companies?.map((el) => el?.name).join(', ') || '-'}
            tooltip={{
              help: companies?.length ? (
                <ul>{companies?.map((company) => <li key={company?.id}>{company?.name}</li>)}</ul>
              ) : null
            }}
          />,
          <TableCell.Text value={tc(country.name)} />,
          <TableCell.Number value={formatIntNumber(accounts || 0)} />,
          <Button
            variant="white"
            onClick={() => selectItem(id)}
            prefixIcon={{ icon: isExpanded ? 'caret-up' : 'caret-down', size: '8px' }}
            css={{ marginLeft: 'auto', height: '24px', minWidth: '24px', padding: 0 }}
          />
        ]
      },
      ...(isExpanded
        ? [
            {
              styles: rowStyles,
              cells: [
                {
                  props: { colSpan: columnsLength },
                  value: <InfluencersListWidget packageAccount={packageAccount} />
                }
              ]
            }
          ]
        : [])
    ];
  };

  return { generateInfluencerPackagesRowWidget };
};
