import { useParamsSearch } from '@/shared/hooks';
import { LIMIT_10 } from '@/shared/constants';
import { getValuableVariables } from '@/shared/utils';
import { AnalyticsYoutubeDashboardTagsRankingTemplate, YtTagRankingSearchSchemaType } from '@/shared/organisms';
import { useInfluencerYoutubeAnalyticsTagsTopRankingQuery } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsYoutubeProfileTagsRankingProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerAnalyticsYoutubeProfileTagsRanking = ({
  socialAccount: { id: socialAccountId, name }
}: InfluencerAnalyticsYoutubeProfileTagsRankingProps) => {
  const { sort } = useParamsSearch<YtTagRankingSearchSchemaType>();
  const { data, previousData, loading } = useInfluencerYoutubeAnalyticsTagsTopRankingQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({ limit: LIMIT_10, offset: 0, orderBy: sort, socialAccountId })
  });

  const result = loading ? previousData : data;

  return (
    <AnalyticsYoutubeDashboardTagsRankingTemplate
      loading={loading}
      data={result?.influencerYoutubeAnalyticsTagsTopRanking.tags}
      moreDetailProps={{
        to: '/influencer/$id/$socialMediaAccountId/youtube_tags_ranking',
        params: { socialMediaAccountId: socialAccountId.toString() },
        search: { filter: { name } }
      }}
    />
  );
};
