import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataContentWrapper, RenderDataWithFallback, NoDataContentWrapperProps } from '@/shared/atoms';
import { SectionWithHeader, SectionWithHeaderProps } from '@/shared/molecules';

export interface AnalyticsCompareOverviewWrapperProps<T = unknown>
  extends Omit<SectionWithHeaderProps, 'children'>,
    Pick<NoDataContentWrapperProps, 'dataNotAvailable'> {
  data?: T | null;
  loading?: boolean;
  children?: ReactNode | ((props: { data: T }) => ReactNode);
}

export const AnalyticsCompareOverviewWrapper = <T,>({
  data,
  help,
  loading,
  children,
  className,
  dataNotAvailable,
  ...restProps
}: AnalyticsCompareOverviewWrapperProps<T>) => {
  const { t } = useTranslation();

  return (
    <div css={{ marginTop: '24px' }} className={className}>
      <RenderDataWithFallback loading={loading}>
        <SectionWithHeader
          title={t('OverView')}
          help={help ?? t('Tooltip.compareOverview')}
          prefixIcon={{ icon: 'pad-two-tones', size: '24px' }}
          {...restProps}
        >
          <NoDataContentWrapper dataNotAvailable={dataNotAvailable ?? !data}>
            {!data ? null : typeof children === 'function' ? children({ data }) : children}
          </NoDataContentWrapper>
        </SectionWithHeader>
      </RenderDataWithFallback>
    </div>
  );
};
