import { useAllPackagesQuery } from '@/graphql';
import { getValuableVariables } from '@/shared/utils';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { useGetInfluencersQueryVariables } from '../../hooks';
import { InfluencerPackagesProps, InfluencerPackagesSearchSchemaType } from '../schemaTypes';

type Props = Pick<ParamsSearchReturnType<InfluencerPackagesSearchSchemaType>, 'page' | 'limit' | 'filter'> &
  Pick<InfluencerPackagesProps, 'engagementId'>;

export const useInfluencerPackagesList = ({ page, limit, filter, engagementId }: Props) => {
  const { variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { data, loading } = useAllPackagesQuery({
    skip: !filter,
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({ ...variables, campaignId: engagementId })
  });

  return {
    loading,
    listRecords: data?.allPackages || [],
    totalRecords: data?.allPackagesCount?.totalNumber || 0
  };
};
