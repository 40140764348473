import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateInfluencerV2.graphql';
import { Mutation, MutationupdateInfluencerV2Args } from '../../__generated__/globalTypes';

export const useUpdateInfluencerV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationupdateInfluencerV2Args>
) => {
  const [callUpdateInfluencerV2, result] = useMutation<Mutation, MutationupdateInfluencerV2Args>(MUTATION, options);

  return { callUpdateInfluencerV2, ...result };
};

export type UpdateInfluencerV2MutationFunction = Mutation['updateInfluencerV2'];
