import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { Genders, SocialPostType, PostListSortFieldV3 } from '@/graphql';
import { LIMIT_36 } from '@/shared/constants';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';

// This UI mixed Filter & Sort together
export const PostsSortSchema = FilterValidations.sort(PostListSortFieldV3);
const defaultValues = {
  sort: '',
  keyword: '',
  countryId: '',
  minPostDate: getThirtyDaysAgo(),
  maxPostDate: getToday(),
  categoryIds: [],
  genders: [],
  socialMedia: SocialPostType.INSTAGRAM,
  minViews: '',
  maxViews: '',
  minFollowers: '',
  maxFollowers: '',
  minEngagementRate: '',
  maxEngagementRate: '',
  field: null,
  order: null
};
export const PostsFilterSchema = z
  .object({
    sort: FilterValidations.string(), // This is selected sort option, convert selected option to a string value for selector component
    keyword: FilterValidations.string(),
    countryId: FilterValidations.string().default(null),
    minPostDate: z.string().default(defaultValues.minPostDate),
    maxPostDate: z.string().default(defaultValues.maxPostDate),
    categoryIds: FilterValidations.arrayString(),
    genders: FilterValidations.arrayEnum(Genders),
    socialMedia: z.nativeEnum(SocialPostType).default(defaultValues.socialMedia),
    minViews: FilterValidations.number(),
    maxViews: FilterValidations.number(),
    minFollowers: FilterValidations.number(),
    maxFollowers: FilterValidations.number(),
    minEngagementRate: FilterValidations.number(),
    maxEngagementRate: FilterValidations.number()
  })
  .merge(PostsSortSchema);
export const PostsFilterSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(PostsFilterSchema, defaultValues as z.infer<typeof PostsFilterSchema>),
  limit: z.number().default(LIMIT_36),
  page: z.number().default(1)
});

export type PostsFilterSearchSchemaType = z.infer<typeof PostsFilterSearchSchema>;
export type PostsFilterFormValues = z.infer<typeof PostsFilterSchema>;
export type PostsFilterFormKeys = UnionOfKeys<PostsFilterFormValues>;
