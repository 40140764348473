import {
  Order,
  InfluencerNewProfileHeaderV2,
  FacebookPageAnalyticsPostsSortOrder,
  useInfluencerFacebookPageAnalyticsPostsQuery
} from '@/graphql';
import { LIMIT_30 } from '@/shared/constants';
import { InfluencerFacebookAnalyticsPostDetailsModal } from '@/shared/molecules';
import { AnalyticsFacebookDashboardFeedPostsTemplate } from '@/shared/organisms';
import { PostDetailsType } from '@/shared/types';
import { InfluencerSelectedAccountInfo } from '../../types';

interface InfluencerAnalyticsFacebookProfileFeedPostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: InfluencerNewProfileHeaderV2['id'];
}

export const InfluencerAnalyticsFacebookProfileFeedPosts = ({
  influencerId,
  socialAccount: { id: socialAccountId, name }
}: InfluencerAnalyticsFacebookProfileFeedPostsProps) => {
  const { data, loading } = useInfluencerFacebookPageAnalyticsPostsQuery({
    variables: {
      offset: 0,
      influencerId,
      limit: LIMIT_30,
      socialAccountId,
      orderBy: { field: FacebookPageAnalyticsPostsSortOrder.POST_DATE, order: Order.DESC }
    }
  });

  const posts = data?.influencerFacebookPageAnalyticsPosts.posts || [];

  return (
    <>
      <AnalyticsFacebookDashboardFeedPostsTemplate
        data={posts}
        loading={loading}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/facebook_feed_post',
          params: { socialMediaAccountId: socialAccountId.toString() },
          search: { filter: { name } }
        }}
      />

      <InfluencerFacebookAnalyticsPostDetailsModal
        posts={posts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: socialAccountId, influencerId }))}
      />
    </>
  );
};
