import { Control, Controller, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MarketplaceRevenueItem } from '@/graphql';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter } from '@/shared/utils';

import { InputUnit, InputUnitProps } from '../../Input';
import { TextCutter } from '../../TextCutter';

interface ControlledFormRevenueInputProps<T extends Record<string, string | undefined>> {
  name: Path<T>;
  input: InputUnitProps;
  control: Control<T, any>;
  revenueItem?: MarketplaceRevenueItem;
}
export const ControlledRevenueInput = <T extends Record<string, string | undefined>>({
  name,
  input,
  control,
  revenueItem
}: ControlledFormRevenueInputProps<T>) => {
  const { t, i18n } = useTranslation();

  return (
    <Controller<T>
      name={name}
      control={control}
      render={({ field }) => (
        <div css={{ minWidth: '120px' }}>
          <InputUnit
            {...field}
            css={{
              width: '80px',
              marginRight: 'auto'
            }}
            {...input}
          />
          <TextCutter
            text={`${t('LUD')}: ${localizedDateFormatter(revenueItem?.updatedDate || null, 'PPP', i18n.language as AppLanguage)}`}
            lines={1}
            css={{ color: THEME.text.colors.gray.lv3, fontSize: '12px', marginTop: '2px' }}
          />
        </div>
      )}
    />
  );
};
