import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useUserRoles } from '@/auth';
import {
  SocialAccountType,
  useRefreshProfileAccountMutation,
  RefreshProfileAvailabilityStatus,
  useRefreshProfileAccountAvailabilityQuery,
  namedOperations
} from '@/graphql';
import { Anchor, Modal, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { Language } from '@/shared/types';
import { RefreshProfileButton } from './RefreshProfileButton';

interface DefaultRefreshButtonProps {
  className?: string;
  socialAccountId: number;
  socialMedia: SocialAccountType;
}
export const DefaultRefreshButton = ({ socialMedia, socialAccountId, className }: DefaultRefreshButtonProps) => {
  const noDataModal = useToggleState();
  const { isAdminStaff } = useUserRoles();
  const { t, i18n, enqueueSnackbar } = useQueryHelper();
  const [disableAfterUpdate, setDisableAfterUpdate] = useState(false); // callRefreshProfileAccount can be a bit slow to update on refetchQueries, after each success update we can set this flag to hide the button
  const { data, loading: checkingAvailability } = useRefreshProfileAccountAvailabilityQuery({
    fetchPolicy: 'network-only',
    skip: socialMedia === SocialAccountType.XHS,
    variables: { input: { socialMedia: socialMedia as any as SocialAccountType, socialAccountId } }
  });
  const { callRefreshProfileAccount, loading } = useRefreshProfileAccountMutation({
    refetchQueries: [namedOperations.Query.RefreshProfileAccountAvailability]
  });

  const status = data?.refreshProfileAccountAvailability?.status;

  const handleRefresh = async () => {
    if (!status) return;

    if (status === RefreshProfileAvailabilityStatus.NO_DATA) {
      noDataModal.open();

      return;
    }

    try {
      await callRefreshProfileAccount({
        variables: { input: { socialMedia: socialMedia as any as SocialAccountType, socialAccountId } }
      });

      enqueueSnackbar(t('Account data refreshed successfully'), { variant: 'success' });
      setDisableAfterUpdate(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return !checkingAvailability && status ? (
    <>
      <RefreshProfileButton
        loading={loading}
        className={className}
        onClick={handleRefresh}
        disabled={disableAfterUpdate || status === RefreshProfileAvailabilityStatus.NOT_AVAILABLE}
      />

      <Modal
        open={noDataModal.status}
        onClose={noDataModal.close}
        css={{ width: 'max-content', minWidth: THEME.modal.size.lv2 }}
      >
        <ModalContent>
          <ModalTitle>{t('Failed to update the profile data')}</ModalTitle>
          <p css={{ marginTop: '16px' }}>
            <Trans
              components={{ br: <br /> }}
              i18nKey={isAdminStaff ? 'refreshProfileDescriptionForAdmin' : 'refreshProfileDescription'}
            />
          </p>

          {isAdminStaff ? (
            <p css={{ marginTop: '16px' }}>
              <Trans
                i18nKey="refreshProfileDescriptionDownloadExtension"
                components={{
                  a: (
                    <Anchor
                      variant="blue"
                      target="_blank"
                      css={{ display: 'inline-flex' }}
                      href={
                        i18n.language === Language.ja
                          ? 'https://anymind-group.gitbook.io/anytag-1/infuruens/ekusutenshon'
                          : 'https://anymind-group.gitbook.io/english-1/infuruens/extension'
                      }
                    />
                  )
                }}
              />
            </p>
          ) : null}
        </ModalContent>
        <ModalFooterActions cancelButtonProps={{ title: t('Button.Close'), onClick: noDataModal.close }} />
      </Modal>
    </>
  ) : null;
};
