import { css, keyframes } from '@emotion/react';

export const AiRainbowSpinner = ({ className }: { className?: string }) => (
  <div css={styles.spinnerStyle} className={className} />
);

const keyframesStyles = {
  spin: keyframes({
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }),
  colorShift: keyframes({
    '0%': {
      backgroundPosition: '0% 108%'
    },
    '100%': {
      backgroundPosition: '200% 100%'
    }
  }),
  opacityPulse: keyframes({
    '0%, 100%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.3
    }
  })
};

const styles = {
  spinnerStyle: css({
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    mask: 'radial-gradient(farthest-side, transparent calc(100% - 2px), white calc(100% - 2px))',
    WebkitMask: 'radial-gradient(farthest-side, transparent calc(100% - 2px), white calc(100% - 2px))',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-100%',
      left: '-100%',
      right: '-100%',
      bottom: '-100%',
      background: 'linear-gradient(90deg, #5c5bff 0%, #15d4ff 25%, #ffd235 50%, #ff942e 75%, #5c5bff 100%)',
      backgroundSize: '200% 200%',
      animation: `${keyframesStyles.spin} 1s linear infinite, 
                ${keyframesStyles.colorShift} 2s linear infinite, 
                ${keyframesStyles.opacityPulse} 2s ease-in-out infinite`
    }
  })
};
