import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SwitchRoleToStaff.graphql';
import { Mutation, MutationswitchRoleToStaffArgs } from '../../__generated__/globalTypes';

export const useSwitchRoleToStaffMutation = (
  options?: MutationHookOptions<Mutation, MutationswitchRoleToStaffArgs>
) => {
  const [callSwitchRoleToStaff, result] = useMutation<Mutation, MutationswitchRoleToStaffArgs>(MUTATION, options);

  return { callSwitchRoleToStaff, ...result };
};

export type SwitchRoleToStaffMutationFunction = Mutation['switchRoleToStaff'];
