import { THEME } from '@/shared/constants';
import { getToday, getThirtyDaysAgo } from '@/shared/utils';
import { DownloadFileType, FormAction } from '@/shared/types';
import { FormProvider, Modal, ModalProps } from '@/shared/atoms';
import { Template, TemplateProps } from './Template';
import { DownloadInstagramHashtagsModalValues, DownloadInstagramHashtagsModalSchema } from './types';

export type DownloadInstagramHashtagsModalProps = TemplateProps &
  FormAction<DownloadInstagramHashtagsModalValues> &
  Pick<ModalProps, 'open'>;

export const DownloadInstagramHashtagsModal = ({
  open,
  onClose,
  onSubmit,
  defaultValues,
  ...templateProps
}: DownloadInstagramHashtagsModalProps) => (
  <Modal
    open={open}
    onClose={onClose}
    style={{ overlay: { zIndex: 290 } }} // Fix: set zIndex lower than DatePicker to avoid raising submit event when click range options
    css={{ maxWidth: THEME.modal.size.lv1 }}
    hasCloseIcon
    shouldCloseOnEsc={false}
    shouldCloseOnOverlayClick={false}
  >
    <FormProvider<DownloadInstagramHashtagsModalValues>
      onSubmit={onSubmit}
      zodSchema={DownloadInstagramHashtagsModalSchema}
      defaultValues={{
        type: DownloadFileType.CSV,
        endDate: defaultValues?.endDate ?? getToday(),
        startDate: defaultValues?.startDate ?? getThirtyDaysAgo()
      }}
    >
      <Template {...templateProps} onClose={onClose} />
    </FormProvider>
  </Modal>
);
