import { CampaignSocialMediaType } from '@/graphql';

import { MarketplaceFormKeys, MarketplaceFormValues } from '../MarketplaceCampaignForms';

export const usePostPricesAvailability = (socialMedia?: MarketplaceFormValues['socialMedia']) => {
  const isSelectedInstagramStory = socialMedia === CampaignSocialMediaType.INSTAGRAM_STORY;
  const isSelectedInstagram = socialMedia === CampaignSocialMediaType.INSTAGRAM;

  return {
    [MarketplaceFormKeys.costPerPost]: true,
    [MarketplaceFormKeys.costPerLike]: !isSelectedInstagramStory,
    [MarketplaceFormKeys.costPerShare]: !isSelectedInstagram && !isSelectedInstagramStory,
    [MarketplaceFormKeys.costPerView]: true,
    [MarketplaceFormKeys.costPerClick]: true,
    [MarketplaceFormKeys.costPerComment]: true,
    [MarketplaceFormKeys.costPerFollower]: true,
    [MarketplaceFormKeys.costPerSaleReferral]: true,
    [MarketplaceFormKeys.costPerAction]: true,
    [MarketplaceFormKeys.costPerOrder]: true,
    [MarketplaceFormKeys.costPerSaleTune]: true
  };
};
