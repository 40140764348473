import { XhsPostType } from '@/graphql';

/* isSponsoredPost is a boolean, but we have array of post types
 * so we need to check if the post type is sponsored or normal
 * but if both are select or unselect we send null
 */
export const getIsSponsoredPostForAPI = (postTypes: XhsPostType[]) => {
  const hasSponsoredType = postTypes.includes(XhsPostType.SPONSORED);
  const hasNormalType = postTypes.includes(XhsPostType.NORMAL);

  if ((!hasSponsoredType && !hasNormalType) || (hasSponsoredType && hasNormalType)) {
    return null;
  }

  return hasSponsoredType;
};
