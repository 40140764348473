import { z } from 'zod';
import { SocialLinkedAccountsProps } from '@/shared/atoms';
import { AnalyticsFormSchema } from './schema';

export type AnalyticsFormValues = z.infer<typeof AnalyticsFormSchema>;
export type AnalyticsFormKeys = keyof AnalyticsFormValues;
export interface AnalyticsConnectAccountType {
  name: string;
  needReconnect?: boolean;
  linkedAccounts?: SocialLinkedAccountsProps['accounts'];
}

const AccountType = z.object({
  avatar: z.string().readonly().nullish(),
  followersCount: z.number().readonly(),
  id: z.string().readonly(),
  name: z.string().readonly()
});

export const AnalyticsConnectableAccountsSchema = z.object({
  youtube: z
    .array(
      z.object({
        avatar: z.string().readonly(),
        channelId: z.string().readonly(),
        channelName: z.string().readonly(),
        isUsed: z.boolean().readonly(),
        subscribersCount: z.number().readonly()
      })
    )
    .readonly()
    .nullish(),
  instagram: z
    .array(
      z.object({
        igAccount: AccountType.readonly(),
        isAlreadyUsed: z.boolean().readonly(),
        page: AccountType
      })
    )
    .nullish()
    .readonly(),
  twitter: z
    .object({
      avatar: z.string().readonly(),
      followersCount: z.number().readonly(),
      isUsed: z.boolean().readonly(),
      name: z.string().readonly(),
      twitterUserId: z.string().readonly(),
      username: z.string().readonly()
    })
    .optional(),
  facebookPage: z
    .object({
      account: z
        .object({
          avatar: z.string().readonly(),
          friendCount: z.number().readonly(),
          id: z.string().readonly(),
          name: z.string().readonly()
        })
        .nullish(),
      pages: z
        .array(
          z.object({
            avatar: z.string().readonly(),
            followers: z.number().readonly(),
            id: z.string().readonly(),
            name: z.string().readonly(),
            isAlreadyUsed: z.boolean().readonly()
          })
        )
        .readonly()
    })
    .optional()
});
export const AnalyticsConnectableAccountsSearchStateSchema = z.object({
  state: z.object({ connectableAccounts: AnalyticsConnectableAccountsSchema }).optional()
});

export type AnalyticsConnectableAccountsSearchStateSchemaType = z.infer<
  typeof AnalyticsConnectableAccountsSearchStateSchema
>;
export type AnalyticsConnectableAccountsType = z.infer<typeof AnalyticsConnectableAccountsSchema>;
