import { usePermissions } from '../hooks';

const defaultPermissions = {
  hideReportLink: false
};
export const useReportPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.report?.noView || permissions?.report === null) {
    return { hideReportLink: true };
  }

  return defaultPermissions;
};
