import { default as EN_US } from './languages/en_US.json';

export type AllTranslationKeysType = keyof typeof EN_US;
export const objectKeys = <Obj extends object>(obj: Obj): (keyof Obj)[] => Object.keys(obj) as (keyof Obj)[];
export const objectValues = <Obj extends object>(obj: Obj): valueOf<Obj>[] => Object.values(obj) as valueOf<Obj>[];
export const objectEntries = <Obj extends object>(obj: Obj): [keyof Obj, valueOf<Obj>][] => Object.entries(obj) as [keyof Obj, valueOf<Obj>][];
export type valueOf<T> = T[keyof T];

type NestedObjectKeys<T> = T extends object
  ? {
      [K in keyof T]: T[K] extends object ? NestedObjectKeys<T[K]> : K;
    }
  : never;

const createNestedKeys = <T extends object = object>(obj: T, parentKey?: string): NestedObjectKeys<T> => {
  const result = {} as any;

  for (const key in obj) {
    result[key] = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result[key] = createNestedKeys(obj[key] as object, key);
    }
  }
  return result as NestedObjectKeys<T>;
};

export const AllTranslationKeys = createNestedKeys(EN_US);
