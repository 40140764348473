import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface NoteAggregateLastThirtyDaysProps {
  className?: string;
  message?: ReactNode;
}
export const NoteAggregateLastThirtyDays = ({ className, message }: NoteAggregateLastThirtyDaysProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ fontSize: '13px', marginLeft: 'auto' }} className={className}>
      {message || t('aggregate last 30 days')}
    </div>
  );
};
