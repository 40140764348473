import { Outlet } from '@tanstack/react-router';
import { Header } from '@/shared/molecules';
import { THEME } from '@/shared/constants';
import { useUpdateDocumentTitle, useCustomHeader } from '@/shared/hooks';

export const AddMarketplace = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    href: '/marketplace',
    alwaysBackToParent: true,
    title: 'documentTitle.AddMarketplace',
    appHeader: 'appHeader.Add New Marketplace'
  });

  return (
    <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
      <Header />
      <Outlet />
    </div>
  );
};
