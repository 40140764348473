import { THEME } from '@/shared/constants';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { InstagramAnalyticsHashtag, useInstagramHashtagFeedPostsV2Query } from '@/graphql';
import {
  AnalyticsInstagramHashtagsFilter,
  AnalyticsInstagramHashtagPostsList,
  useAnalyticsInstagramHashtagsFilter
} from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';
import { FreePlanContentOpacity } from '../../shared/AnalyticsUpgradePlan';
import { postsMock, hashtagsMock } from './hashtags.mock';

interface TemplateProps {
  hashtags: readonly InstagramAnalyticsHashtag[];
  hasFreePlan: boolean;
}

export const Template = ({ hashtags, hasFreePlan }: TemplateProps) => {
  const {
    selectedSocialAccount,
    brandAccount: { subscriptionPlan }
  } = useAnalyticsProfileContext();
  const { page, sort, limit, filter } = useAnalyticsInstagramHashtagsFilter();
  const { data, loading } = useInstagramHashtagFeedPostsV2Query({
    skip: !filter.hashtagId || !filter.date || hasFreePlan,
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      ...filter,
      limit,
      orderBy: sort,
      offset: getOffset(page, limit),
      hashtagId: Number(filter.hashtagId),
      instagramAnalyticsAccountId: selectedSocialAccount.id
    })
  });

  const postsData = hasFreePlan ? postsMock : data?.instagramHashtagFeedPostsV2;
  const totalRecords = postsData?.count;

  return (
    <ListWithPagination css={{ backgroundColor: THEME.background.colors.white }}>
      <FreePlanContentOpacity hasFreePlan={hasFreePlan} tab="hashtag-ig">
        <AnalyticsInstagramHashtagsFilter
          defaultHashtagId={hasFreePlan ? hashtagsMock[0].id.toString() : undefined}
          analyticsAccountId={selectedSocialAccount.id}
          maxHashtags={subscriptionPlan.capabilities.maxHashtags}
          hashtags={hashtags.map((hashtag) => ({
            id: String(hashtag.id),
            name: hashtag.hashTag,
            url: hashtag.hashTagUrl,
            blocked: hashtag.blocked
          }))}
        />
        <AnalyticsInstagramHashtagPostsList
          loading={loading}
          totalRecords={totalRecords}
          data={postsData?.posts || []}
        />
      </FreePlanContentOpacity>
    </ListWithPagination>
  );
};
