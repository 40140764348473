import { FilterProvider } from '@/shared/molecules';
import { BasicFilter } from './BasicFilter';
import { useTikTokCampaignInfluencersFilter } from './hooks';

export const TikTokCampaignInfluencersFilter = () => {
  const { filter, setFilterForm } = useTikTokCampaignInfluencersFilter();

  return <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<BasicFilter />} />;
};

export { useTikTokCampaignInfluencersFilter };
export * from './types';
