import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

interface ArrowProps {
  className?: string;
  to: string;
  onClick?: () => void;
}

export const LeftArrow = ({ className, to, onClick }: ArrowProps) => (
  <button
    type="button"
    aria-label={to}
    onClick={onClick}
    css={styles.button}
    className={`button button--text button--icon ${className}`}
  >
    <Icon css={styles.icon} icon="arrow-left" size={20} color={THEME.text.colors.white} />
  </button>
);

export const RightArrow = ({ className, to, onClick }: ArrowProps) => (
  <button
    type="button"
    aria-label={to}
    onClick={onClick}
    css={styles.button}
    className={`button button--text button--icon ${className}`}
  >
    <Icon css={styles.icon} icon="arrow-right" size={20} color={THEME.text.colors.white} />
  </button>
);
const styles = {
  button: css({
    width: '32px',
    textAlign: 'center'
  }),
  icon: css({
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15))',
  })
};
