import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { ConfirmDeleteModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { useAccountUsersTable, useDeleteAccountUser } from './hooks';
import { AccountUserType } from './types';

export interface AccountUsersListProps extends TableData<AccountUserType> {
  title?: string;
  className?: string;
  refetchQueriesAfterDelete?: string[];
}

export const AccountUsersList = ({
  title,
  loading,
  className,
  data = [],
  refetchQueriesAfterDelete = []
}: AccountUsersListProps) => {
  const { t } = useTranslation();
  const { deleting, deletingItem, setDeletingItem, resetDeletingItem, handleDeleteUser } = useDeleteAccountUser({
    refetchQueriesAfterDelete
  });
  const { rows, columns } = useAccountUsersTable({ data, onDelete: setDeletingItem });

  return (
    <div className={className}>
      <div css={styles.header}>
        <h3 style={{ fontSize: '18px' }}>{title || t('Users')}</h3>
      </div>

      <Table loading={loading} data={{ rows, columns }} empty={{ hideDescription: true }} hasBorder noTopBorderRadius />

      <ConfirmDeleteModal
        deleting={deleting}
        onClose={resetDeletingItem}
        deletingItem={deletingItem}
        onDelete={handleDeleteUser}
      />
    </div>
  );
};
const styles = {
  header: css({
    height: '62px',
    display: 'flex',
    padding: '0 16px',
    alignItems: 'center',
    borderBottom: 'unset',
    justifyContent: 'space-between',
    backgroundColor: THEME.background.colors.white
  })
};

export * from './types';
