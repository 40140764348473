import { Textarea, TextareaProps } from '@/shared/atoms';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export interface TextAreaFieldProps<T extends string = string>
  extends DefaultFieldProps<T, string>,
    Pick<TextareaProps, 'heightAuto' | 'onBlur'> {
  onChangeCallback?: () => void;
}

export const TextAreaField = <T extends string = string>(props: TextAreaFieldProps<T>) => {
  const {
    fieldRegister,
    fieldWrapperProps,
    fieldProps: { name, onChange, onChangeCallback, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <Textarea
        {...fieldRegister}
        {...restFieldProps}
        css={{ height: '120px' }}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          onChangeCallback?.();
          onChange?.(name, e.target.value) || fieldRegister.onChange(e);
        }}
      />
    </RHFFieldWrapper>
  );
};
