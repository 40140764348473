import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllEngagementPostsForInsightCheck.graphql';
import { Query, QueryallEngagementPostsForInsightCheckArgs } from '../../__generated__/globalTypes';

export const useAllEngagementPostsForInsightCheckQuery = (
  options?: QueryHookOptions<Query, QueryallEngagementPostsForInsightCheckArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallEngagementPostsForInsightCheckArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllEngagementPostsForInsightCheckLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallEngagementPostsForInsightCheckArgs>
): QueryResult<Query, QueryallEngagementPostsForInsightCheckArgs> & {
  getAllEngagementPostsForInsightCheck: LazyQueryExecFunction<Query, QueryallEngagementPostsForInsightCheckArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllEngagementPostsForInsightCheck, result] = useLazyQuery<
    Query,
    QueryallEngagementPostsForInsightCheckArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllEngagementPostsForInsightCheck, ...result };
};

export const useAllEngagementPostsForInsightCheckPromiseQuery = () => {
  const client = useApolloClient();

  const getAllEngagementPostsForInsightCheck = useCallback(
    (options?: Omit<QueryOptions<QueryallEngagementPostsForInsightCheckArgs, Query>, 'query'>) =>
      client.query<Query, QueryallEngagementPostsForInsightCheckArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllEngagementPostsForInsightCheck };
};
