import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ExportInstagramHashtagPostsToGoogleSpreadsheet.graphql';
import { Mutation, MutationexportInstagramHashtagPostsToGoogleSpreadsheetArgs } from '../../__generated__/globalTypes';

export const useExportInstagramHashtagPostsToGoogleSpreadsheetMutation = (
  options?: MutationHookOptions<Mutation, MutationexportInstagramHashtagPostsToGoogleSpreadsheetArgs>
) => {
  const [callExportInstagramHashtagPostsToGoogleSpreadsheet, result] = useMutation<
    Mutation,
    MutationexportInstagramHashtagPostsToGoogleSpreadsheetArgs
  >(MUTATION, options);

  return { callExportInstagramHashtagPostsToGoogleSpreadsheet, ...result };
};

export type ExportInstagramHashtagPostsToGoogleSpreadsheetMutationFunction =
  Mutation['exportInstagramHashtagPostsToGoogleSpreadsheet'];
