import { Trans } from 'react-i18next';
import { useState } from 'react';
import { css } from '@emotion/react';
import { Button, ButtonProps, Icon, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import {
  delayRefetchedQuery,
  Maybe,
  namedOperations,
  PaymentPlanName,
  PricingTableItem,
  useCheckoutSessionLinkPromiseQuery,
  useUpdateSubscriptionPlanMutation
} from '@/graphql';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { getFirstDayOfNthMonth } from './utils';

interface UpdatePlanModalWithButtonProps {
  className?: string;
  plan: PricingTableItem;
  isUpcomingPlan: boolean;
  title?: ButtonProps['title'];
  selectedPlan?: Maybe<PricingTableItem>;
}
export const UpdatePlanModalWithButton = ({
  plan,
  title,
  isUpcomingPlan,
  selectedPlan,
  className
}: UpdatePlanModalWithButtonProps) => {
  const { t, i18n, enqueueSnackbar } = useQueryHelper();
  const { getCheckoutSessionLink } = useCheckoutSessionLinkPromiseQuery();
  const { callUpdateSubscriptionPlan, loading } = useUpdateSubscriptionPlanMutation({
    refetchQueries: [namedOperations.Query.TiktokAdvertiserSummaryData, namedOperations.Query.PricingTable],
    onQueryUpdated: delayRefetchedQuery // for some reasons BE not instantly reflect changes after plan update
  });

  const [updateType, setUpdateType] = useState<{
    type: 'upgrade' | 'downgrade' | '';
    nextPlan: PaymentPlanName;
    planId: number;
  }>();

  const buttonTitle = isUpcomingPlan
    ? t("Button.Next Month's Plan")
    : plan.isCurrentPlan
      ? t('Button.Current Plan')
      : title || t('Button.Start');

  const handleUpdatePlan = async (priceId: number) => {
    try {
      await callUpdateSubscriptionPlan({
        variables: { input: { priceId } }
      });

      enqueueSnackbar(t('Plan updated successfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error?.message), { variant: 'error' });
    } finally {
      setUpdateType(undefined);
    }
  };

  const handleStartSubscription = async (priceId: number) => {
    try {
      const { data } = await getCheckoutSessionLink({
        variables: {
          priceId,
          redirectUrl: `${window.location.origin}/tiktok-onboarding/select-plan`
        }
      });
      const planUrl = data.checkoutSessionLink?.url;

      if (planUrl) {
        window.location.href = planUrl;
      }
    } catch (error) {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <>
      <Button
        variant={plan.isCurrentPlan || isUpcomingPlan ? 'white' : 'blue'}
        onClick={() => {
          if (plan.isCurrentPlan || isUpcomingPlan) {
            return;
          } else if (selectedPlan) {
            setUpdateType({
              type: plan.price > selectedPlan.price ? 'upgrade' : 'downgrade',
              nextPlan: plan.name,
              planId: plan.id
            });
          } else {
            handleStartSubscription(plan.id);
          }
        }}
        loading={{
          showIcon: true,
          status: updateType?.planId === plan.id
        }}
        title={buttonTitle}
        css={[
          plan.isCurrentPlan ? { pointerEvents: 'none' } : {},
          isUpcomingPlan ? styles.upcomingBtn : {},
          styles.defaultBtn
        ]}
        className={className}
      />

      <Modal open={!!updateType} onClose={() => setUpdateType(undefined)} css={{ width: THEME.modal.size.lv1 }}>
        <ModalContent>
          <ModalTitle>{t('Dialog.Change Plan')}</ModalTitle>
          <div css={{ marginTop: '16px', lineHeight: '140%' }}>
            <Trans
              i18nKey="The previous plan was"
              values={{ name: t(`Plan.${selectedPlan?.name}`) }}
              components={{ b: <b css={{ fontWeight: 600 }} /> }}
            />
            <br />
            {updateType?.type === 'upgrade' ? (
              <>
                <Trans
                  i18nKey="Would you like to upgrade to"
                  values={{ name: t(`Plan.${updateType?.nextPlan}`) }}
                  components={{ b: <b css={{ fontWeight: 600 }} /> }}
                />
                <div css={styles.infoNotice}>
                  <Icon icon="info" size={16} css={styles.infoIcon} />
                  <p>
                    <Trans
                      i18nKey="The new plan will be applied right away, and payment will be processed on"
                      values={{ date: getFirstDayOfNthMonth(i18n.language) }}
                      components={{ b: <b css={{ fontWeight: 600 }} /> }}
                      css={styles.infoNotice}
                    />
                  </p>
                </div>
              </>
            ) : (
              <>
                <Trans
                  i18nKey="Would you like to downgrade to"
                  values={{ name: t(`Plan.${updateType?.nextPlan}`) }}
                  components={{ b: <b css={{ fontWeight: 600 }} /> }}
                />
                <div css={styles.infoNotice}>
                  <Icon icon="info" size={16} css={styles.infoIcon} />
                  <p>
                    <Trans
                      i18nKey="The new plan will be applied from and payment will be processed on"
                      values={{
                        fromDate: getFirstDayOfNthMonth(i18n.language),
                        processDate: getFirstDayOfNthMonth(i18n.language, 2)
                      }}
                      components={{ b: <b css={{ fontWeight: 600 }} />, b1: <b css={{ fontWeight: 600 }} /> }}
                      css={styles.infoNotice}
                    />
                  </p>
                </div>
              </>
            )}
          </div>
        </ModalContent>
        <ModalFooterActions
          submitButtonProps={{
            title: t(updateType?.type === 'upgrade' ? 'Button.Upgrade Plan' : 'Button.Downgrade Plan'),
            onClick: () => (updateType ? handleUpdatePlan(updateType.planId) : undefined),
            loading
          }}
          cancelButtonProps={{ title: t('Button.Cancel'), onClick: () => setUpdateType(undefined) }}
        />
      </Modal>
    </>
  );
};

const styles = {
  infoNotice: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3px',
    padding: '8px 0 8px 8px',
    border: `1px solid #c9e2f8`,
    background: '#f3f8fe',
    fontSize: '12px',
    margin: '8px 0'
  }),
  infoIcon: css({ color: THEME.attention.colors.blue, marginRight: '8px', alignSelf: 'flex-start' }),
  defaultBtn: css({ borderRadius: '3px', width: '100%' }),
  upcomingBtn: css({ pointerEvents: 'none', color: THEME.text.colors.gray.lv1 })
};
