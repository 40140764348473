import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useUpdatePasswordByAdvertiserUserMutation } from '@/graphql';
import { Button, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAllAvailableCountryOptions, useQueryHelper, useToggleState } from '@/shared/hooks';
import {
  TextField,
  PasswordField,
  SelectorField,
  SwitchToggleField,
  ChangePasswordFormModal,
  ChangePasswordFormValues
} from '@/shared/molecules';
import { FormAction } from '@/shared/types';
import { getSubscriptionTypeLabel } from '@/shared/utils';
import { SettingsFormKeys, SettingsFormValues } from './types';

export const Template = () => {
  const modalState = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions({ hasUSCountry: true });
  const { loading, callUpdatePasswordByAdvertiserUser } = useUpdatePasswordByAdvertiserUserMutation();
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<SettingsFormValues>();

  const values = watch();
  const planTitle = getSubscriptionTypeLabel(values.planType);

  const handleChangePassword: FormAction<ChangePasswordFormValues>['onSubmit'] = async ({
    password,
    currentPassword
  }) => {
    try {
      await callUpdatePasswordByAdvertiserUser({
        variables: { input: { newPassword: password, originalPassword: currentPassword } }
      });
      enqueueSnackbar(t('New Account Password was saved successfully'), { variant: 'success' });
      modalState.close();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <div css={{ display: 'flex', gap: '24px' }}>
        <FormStyle.Wrapper css={{ flex: 1, padding: '24px' }}>
          <FormStyle.SectionTitle label={t('Company Information')} />

          <TextField<SettingsFormKeys>
            name="advertiserName"
            placeholder="AnyMindgroup"
            title={t('TextForm.Company Name')}
            required
          />

          <TextField<SettingsFormKeys>
            name="advertiserCompanyUrl"
            title={t('TextForm.Company URL')}
            placeholder="https://anytag.tech"
            required
          />

          <FormStyle.FieldsGroup>
            <TextField<SettingsFormKeys>
              placeholder="03-1111-1111"
              name="advertiserPhoneNumber"
              title={t('TextForm.Phone Number')}
              required
            />

            <SelectorField<SettingsFormKeys>
              name="advertiserCountryId"
              title={t('Selector.Country')}
              options={allAvailableCountryOptions}
              required
            />
          </FormStyle.FieldsGroup>

          <FormStyle.SectionTitle css={{ marginTop: '40px' }} label={t('User Information')} />

          <TextField<SettingsFormKeys> name="name" title={t('TextForm.Name')} placeholder="Naoya Kurita" required />

          <FormStyle.FieldsGroup>
            <TextField<SettingsFormKeys>
              name="email"
              placeholder="myemail@gmail.com"
              title={t('TextForm.Email Address')}
              required
            />

            <SelectorField<SettingsFormKeys>
              name="countryId"
              title={t('Selector.Country')}
              options={allAvailableCountryOptions}
              required
            />
          </FormStyle.FieldsGroup>

          <FormStyle.FieldsGroup css={{ width: '380px', alignItems: 'flex-end' }}>
            <PasswordField<SettingsFormKeys>
              name="password"
              title={t('TextForm.Password')}
              placeholder={t<string>('Password.Your Password')}
              required
              disabled
            />

            <FormStyle.FieldWrapper css={{ flex: 'none' }}>
              <Button
                variant="white"
                onClick={modalState.open}
                title={t('Button.Change Password')}
                css={{ opacity: modalState.status ? 0 : 1, textTransform: 'uppercase' }}
              />
            </FormStyle.FieldWrapper>
          </FormStyle.FieldsGroup>

          <SwitchToggleField<SettingsFormKeys>
            name="notificationSetting"
            title={t('TextForm.Notification Settings')}
            help={t('Tooltip.Notification for Advertiser settings help')}
          />

          <FormStyle.ButtonSubmit
            title={t('Button.Save')}
            loading={{ status: isSubmitting, title: t('Button.Saving') }}
            css={{ minWidth: '146px', marginLeft: 'auto', textTransform: 'uppercase' }}
          />
        </FormStyle.Wrapper>

        <div css={styles.accountManagerPlan}>
          <FormStyle.SectionTitle label={t('Account Manager Plan')} css={{ marginBottom: 0 }} />

          {planTitle ? (
            <span css={styles.accountPlan}>{t(planTitle)}</span>
          ) : (
            <span css={{ fontSize: '14px', fontWeight: 400 }}>{t('No plan subscription')}</span>
          )}
          <p css={{ fontSize: '12px', fontWeight: 400, color: THEME.text.colors.gray.lv3 }}>
            {t('Please contact our staff to upgrade subscriptions')}
          </p>
        </div>
      </div>

      <ChangePasswordFormModal
        loading={loading}
        open={modalState.status}
        onClose={modalState.close}
        onSubmit={handleChangePassword}
      />
    </>
  );
};
const styles = {
  accountPlan: css({
    fontWeight: 600,
    fontSize: '16px',
    margin: '0 auto'
  }),
  accountManagerPlan: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 'none',
    width: '320px',
    rowGap: '16px',
    padding: '24px',
    borderRadius: '3px',
    height: 'max-content',
    backgroundColor: THEME.background.colors.white,
    boxShadow: THEME.shadows.boxShadow
  })
};
