import { useTranslation } from 'react-i18next';
import { EngagementDetail, EngagementPostEditV2 } from '@/graphql';
import { Drawer, FormProvider, RenderDataWithFallback } from '@/shared/atoms';
import { FormAction } from '@/shared/types';
import { EngagementPostSchema } from './schema';
import { Template } from './Template';
import { EngagementPostFormValues } from './types';
import { getEngagementPostFormValues } from './utils';

export interface EngagementPostFormProps extends Pick<FormAction<EngagementPostFormValues>, 'onSubmit'> {
  loading?: boolean;
  onClose: () => void;
  engagement: EngagementDetail;
  data?: Partial<EngagementPostEditV2> | null;
}

export const EngagementPostForm = ({ data, loading, onSubmit, onClose, engagement }: EngagementPostFormProps) => {
  const { t } = useTranslation();

  const initialValues = getEngagementPostFormValues(data);

  return (
    <Drawer size="515px" onClose={onClose} title={t(engagement.isTtcmCampaign ? 'Video Detail' : 'Post Detail')} opened>
      <RenderDataWithFallback loading={loading}>
        <FormProvider onSubmit={onSubmit} values={initialValues} zodSchema={EngagementPostSchema}>
          <Template onClose={onClose} engagement={engagement} initialValues={initialValues} />
        </FormProvider>
      </RenderDataWithFallback>
    </Drawer>
  );
};

export * from './types';
