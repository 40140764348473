import { css, keyframes } from '@emotion/react';
import { CSSObject } from '@emotion/serialize';

// https://www.figma.com/file/VVKblM8mDjRpkaSwyPw0ky/2024-Components?node-id=6371%3A30232&mode=dev
const colors = {
  white: { 100: '#fff' },
  black: {
    100: '#000',
    90: '#27313B',
    80: '#333',
    70: '#3b4146',
    60: '#6E7C89',
    50: '#A8B4BF',
    40: '#C5D0DA',
    30: '#DEE5EC',
    20: '#EEF3F7',
    10: '#F6F8FA'
  },
  red: {
    100: '#C92A2A',
    90: '#CC2242',
    80: '#F03E3E',
    70: '#FF2B52',
    60: '#FF5575',
    50: '#FF5F5F',
    40: '#FF8097',
    30: '#FFAABA',
    20: '#FFCFCF',
    10: '#FFF5F5'
  },
  blue: {
    100: '#1864AB',
    90: '#2D75B7',
    80: '#1C7ED6',
    70: '#3892E5',
    60: '#5BA3E6',
    50: '#60A8EA',
    40: '#7DB3E6',
    30: '#AFD3F5',
    20: '#C3DEF7',
    10: '#E8F3FC'
  },
  cyan: {
    100: '#0B7285',
    90: '#009DA5',
    80: '#1098AD',
    70: '#00C4CD',
    60: '#33D0D7',
    50: '#66DCE1',
    40: '#66DCE1',
    30: '#B3EDF0',
    20: '#C7F1F4',
    10: '#E3FAFC'
  },
  lightGreen: {
    100: '#5C940D',
    90: '#739C2A',
    80: '#82B02F',
    70: '#90C435',
    60: '#A1CB58',
    50: '#B2D27C',
    40: '#A6D05D',
    30: '#C3D79E',
    20: '#E9FAC8',
    10: '#F4FCE3'
  },
  darkGreen: {
    100: '#2B8A3E',
    90: '#2F9E44',
    80: '#37B24D',
    70: '#40B87C',
    60: '#61C191',
    50: '#81CAA5',
    40: '#8CE99A',
    30: '#A3D3BB',
    20: '#C6EAD8',
    10: '#EBFBEE'
  },
  yellow: {
    100: '#B49521',
    90: '#C4A525',
    80: '#DDB929',
    70: '#F5CE2E',
    60: '#F2D353',
    50: '#F7D858',
    40: '#EFD878',
    30: '#EBDB9B',
    20: '#FFE9C5',
    10: '#FFF9DB'
  },
  orange: {
    100: '#C58043',
    90: '#DB8E4A',
    80: '#E6964E',
    70: '#F39E52',
    60: '#F0AC70',
    50: '#F5B175',
    40: '#EEBB8D',
    30: '#EAC8AA',
    20: '#FFE8CC',
    10: '#FFF4E6'
  },
  // TODO: Design doesn't support purple color set yet
  purple: {
    70: '#966acc',
    60: '#966acc90'
  },
  pink: {
    100: '#FF4A8B ',
    10: '#ffdde7'
  }
} as const;

export const gradients = {
  aiRainbow: '#5c5bff 0%, #15d4ff 40%, #ffd235 70%, #ff942e 100%'
};

export const setPseudoGradientBorder = (zIndex: number): CSSObject => ({
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: -1,
    zIndex,
    background: `conic-gradient(from var(--gradient-angle), ${gradients.aiRainbow})`,
    borderRadius: 'inherit'
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    inset: 0,
    background: 'white',
    borderRadius: 'inherit',
    zIndex
  }
});

const shadows = {
  boxShadow: '0px 1px 2px #dee5ec',
  boxShadowContainer: '0px 2px 1px 0px rgba(110, 124, 137, 0.05), 0px 0px 2px 0px rgba(110, 124, 137, 0.25)',
  dropShadow: 'drop-shadow(0 1px 4px rgba(222, 229, 236, 0.50)) drop-shadow(0 2px 5px #DEE5EC)',
  scroll: {
    '--containerBg': '#fff',
    '--gradientStart': 'rgba(0, 0, 0, 0.15)',
    '--gradientEnd': 'rgba(255, 255, 255, 0.25)',

    backgroundImage: `linear-gradient(to right, var(--containerBg), var(--containerBg)),
  linear-gradient(to right, var(--containerBg), var(--containerBg)),
  linear-gradient(to right, var(--gradientStart), var(--gradientEnd)),
  linear-gradient(to left, var(--gradientStart), var(--gradientEnd))`,
    backgroundPosition: 'left center, right center, left center, right center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'var(--containerBg)',
    backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
    backgroundAttachment: 'local, local, scroll, scroll'
  },
  pseudoHover: css({
    position: 'relative',
    cursor: 'pointer',
    ':hover::before': {
      content: '""',
      top: '-8px',
      left: '-8px',
      right: '-8px',
      bottom: '-8px',
      borderRadius: '3px',
      position: 'absolute',
      pointerEvents: 'none',
      backgroundColor: 'transparent',
      boxShadow: '0px 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5)'
    }
  })
};

const shiftGradient = keyframes`
  from, to, 0% {
    --gradient-angle: 108deg;
  }
  100% {
    --gradient-angle: 468deg;
  }
`;

// Idea: General styles - Own styles - Mixed styles (General + Own)
// - We have common `colors` above but we won't use it directly
// - We will use it via each object. Sample: text.colors.gray, background.colors.white,... -> Format <entity>.<property>.<value>
// - An object can use the general styles. Sample THEME2.text, THEME2.colors, THEME2.background... This is layout general styles
// - An object also has its own styles. Sample THEME2.chart<...>, THEME2.button<...>, THEME2.chatbox<...>...
// - Color used in common styles should be got from `colors`
// - Color used inside object can be got from `colors` and also fixed color -> So when `colors` changed, some places inside object won't be affected by fixed color
// - A property can has multiple levels. It should be sorted and defined by `lv` prefix. Sample `gray.lv1`
// TODO: We don't have dark theme yet so we don't know where should use general or fixed styles. Now still testing and we'll update more later
export const THEME = {
  colors,
  shadows,
  button: {
    base: {
      borderRadius: '3px',
      height: { lv1: '24px', lv2: '32px', lv3: '40px', lv4: '48px' }
    },
    // https://www.figma.com/file/VVKblM8mDjRpkaSwyPw0ky/2024-Components?node-id=6209%3A29991&mode=dev
    variants: {
      default: {
        opacity: 1,
        borderColor: colors.black['30'],
        color: colors.black['60'],
        background: 'transparent',
        transition: 'opacity 0.3s ease',
        '&:not(:[aria-disabled="true"]):hover': { opacity: 0.85 }
      },
      white: {
        color: colors.black['60'],
        borderColor: colors.black['30'],
        background: colors.white['100'],
        click: { background: colors.black['20'] },
        hover: { color: colors.black['90'], borderColor: colors.black['40'] }
      },
      black: {
        color: colors.white['100'],
        background: colors.black['90'],
        click: { background: colors.black['100'] },
        hover: { background: colors.black['60'] },
        disabled: { color: colors.white['100'], background: colors.black['40'] }
      },
      blue: {
        color: colors.white['100'],
        background: colors.blue['70'],
        click: { background: colors.blue['90'] },
        hover: { background: colors.blue['50'] },
        disabled: { color: colors.white['100'], background: colors.black['30'], border: 'none' }
      },
      blueInverted: {
        color: colors.blue['70'],
        borderColor: colors.blue['70'],
        background: colors.white['100'],
        click: { color: colors.blue['90'], borderColor: colors.blue['90'] },
        hover: { color: colors.blue['50'], borderColor: colors.blue['50'] }
      },
      red: {
        color: colors.white['100'],
        background: colors.red['70'],
        click: { background: colors.red['90'] },
        hover: { background: colors.red['60'] }
      },
      pinkRed: {
        color: colors.white['100'],
        background: colors.red['70'],
        hover: { background: colors.red['90'] }
      },
      pinkRedInverted: {
        color: colors.red['70'],
        borderColor: colors.red['70'],
        background: colors.white['100'],
        hover: { color: colors.red['90'], borderColor: colors.red['90'] }
      },
      attentionRed: {
        color: colors.white['100'],
        background: colors.red['50'],
        click: { background: colors.red['70'] },
        hover: { background: colors.red['80'] }
      },
      green: {
        color: colors.white['100'],
        background: colors.darkGreen['70'],
        click: { background: colors.darkGreen['90'] },
        hover: { background: colors.darkGreen['60'] }
      },
      // TODO: We need purple colors set and button state set in design
      purple: {
        color: colors.white['100'],
        background: colors.purple['70'],
        click: { background: colors.purple['70'] },
        hover: { background: colors.purple['60'] }
      },
      anyAiGradient: {
        color: colors.black['60'],
        background: colors.white['100'],
        borderImage: {
          position: 'relative',
          zIndex: 0,
          borderRadius: '7px',
          '--gradient-angle': '108deg',
          '@property --gradient-angle': {
            syntax: "'<angle>'",
            initialValue: '108deg',
            inherits: 'false'
          },
          ...setPseudoGradientBorder(-1)
        },
        hover: {
          color: colors.black['90'],
          '&::before': {
            animation: `${shiftGradient} 6s linear infinite`
          }
        },
        disabled: { '&::before': { background: colors.black['10'] } }
      },
      orange: {
        color: colors.red['50'],
        borderColor: colors.red['50'],
        background: colors.white['100'],
        click: { borderColor: colors.red['80'], color: colors.red['80'] },
        hover: { color: colors.red['90'], borderColor: colors.red['90'] }
      },
      coralRed: {
        color: colors.white['100'],
        background: '#E14F4A', // https://www.figma.com/design/SAiql1EuH80Pat9Zrg6dbj?node-id=3451-48358&m=dev#946303259
        click: { background: '#B43F3B' },
        hover: { background: '#E7726E' },
        disabled: { background: '#F6F8FA', border: `1px solid ${colors.black['30']}` }
      },
      disabled: {
        color: colors.black['50'],
        border: `1px solid ${colors.black['30']}`,
        background: colors.black['10'],
        cursor: 'default'
      }
    },
    // https://www.figma.com/file/VVKblM8mDjRpkaSwyPw0ky/2024-Components?node-id=6209%3A31126&mode=dev
    group: {
      default: {
        color: colors.black['60'],
        borderColor: colors.black['30'],
        hover: { color: colors.black['90'] },
        click: { color: colors.black['90'], backgroundColor: colors.black['40'] }
      },
      active: {
        color: colors.blue['70'],
        borderColor: colors.blue['70'],
        backgroundColor: 'rgba(56, 146, 229, 0.10)'
      }
    }
  },
  icon: {
    colors: {
      red: {
        lv1: colors.red['70'],
        lv2: colors.red['50']
      },
      blue: colors.blue['70'],
      black: colors.black['90'],
      white: colors.white['100'],
      gray: {
        lv1: colors.black['40'],
        lv2: colors.black['60'],
        lv3: colors.black['50']
      }
    }
  },
  loading: { color: '#e0e8ed' },
  // Used for input/selector/textarea/...
  input: {
    base: {
      borderRadius: '3px',
      height: { lv1: '24px', lv2: '32px', lv3: '40px', lv4: '48px' }
    },
    text: {
      lineHeight: 'calc(1em + 1ex)', // This is special line-height used for input's label
      colors: {
        error: colors.red['50'],
        default: colors.black['90'],
        disabled: colors.black['50'],
        placeholder: colors.black['50'],
        success: colors.darkGreen['70']
      }
    },
    background: {
      colors: {
        default: colors.white['100'],
        disabled: colors.black['10']
      }
    },
    border: {
      colors: {
        error: colors.red['50'],
        focus: colors.blue['70'],
        hover: colors.black['40'],
        default: colors.black['30'],
        disabled: colors.black['40'],
        success: colors.darkGreen['70']
      }
    }
  },
  // Used for common text: header, title, text,...
  text: {
    colors: {
      red: {
        lv1: colors.red['70'],
        lv2: colors.red['50']
      },
      blue: colors.blue['70'],
      white: colors.white['100'],
      black: {
        lv1: colors.black['90'],
        lv2: colors.black['100']
      },
      gray: {
        lv1: colors.black['40'],
        lv2: colors.black['50'],
        lv3: colors.black['60']
      },
      pink: {
        lv1: colors.pink['100']
      },
      green: {
        lv1: colors.darkGreen['70']
      }
    }
  },
  background: {
    colors: {
      red: {
        lv1: colors.red['50']
      },
      blue: colors.blue['70'],
      black: colors.black['90'],
      white: colors.white['100'],
      gray: { lv1: colors.black['10'], lv2: colors.black['30'] },
      green: { lv1: colors.darkGreen['70'] }
    }
  },
  container: {
    header: { height: '48px' },
    menu: { width: '240px' },
    footer: { height: '32px' },
    details: { width: '984px', mkpWidth: '1152px' }, // For almost details pages: campaign, influencer, package,....
    influencerProfile: { intro: { width: 228 } },
    analytics: {
      header: { height: '121px' },
      freePlanNotice: { height: '40px' }
    },
    // For Sign in/up, forgot/reset/register, ... pages
    dashboard: {
      width: '348px',
      maxWidth: '856px',
      borderRadius: '3px'
    }
  },
  border: {
    base: `1px solid ${colors.black['30']}`,
    colors: {
      black: colors.black['90'],
      white: colors.white['100'],
      blue: {
        lv1: colors.blue['20'],
        lv2: colors.blue['70']
      },
      gray: {
        lv1: colors.black['20'],
        lv2: colors.black['30'],
        lv3: colors.black['40']
      },
      pink: colors.pink['10'],
      red: { lv1: colors.red['20'] }
    }
  },
  attention: {
    colors: {
      yellow: '#FFB63D', // TODO: design not matching with any color asset
      red: colors.red['50'],
      blue: colors.blue['70'],
      green: colors.darkGreen['70']
    }
  },
  chart: {
    colors: {
      pink: '#FF8097',
      blue: '#707FC0',
      grey: '#C5D0DA',
      green: '#A1CB58',
      orange: '#F0AC70',
      yellow: '#FFCA98',
      violet: { lv1: '#E7E7F7', lv2: '#9EA0E0' },
      gender: { male: '#707fc0', female: '#ff8a94', other: '#ffca98', total: '#9EA0E0' },
      report: {
        stroke: '#e5e5e5',
        fill: '#757575',
        red: '#ff2b52',
        fillRed: '#fbeaed',
        blue: '#3892e5',
        fillBlue: '#ecf4fb'
      }
    },
    tooltip: {
      border: { colors: { green: 'green', black: colors.black['90'] } },
      text: { colors: { black: colors.black['90'], white: colors.white['100'] } },
      background: { colors: { black: colors.black['90'], white: colors.white['100'] } }
    }
  },
  campaign: {
    engagement: { color: '#966acc' },
    marketplace: { color: '#ffb63d' },
    autoManaged: { color: '#6adae0' },
    customTabStyles: {
      padding: '0 12px',
      borderRadius: '3px 3px 0 0',
      boxShadow: shadows.boxShadow,
      '.tab-title': { padding: '0 8px 0' }
    }
  },
  chatbox: {
    position: {
      zIndex: 290,
      right: '24px',
      bottom: '24px',
      position: 'fixed'
    },
    size: { width: '400px', height: '576px' },
    dragging: {
      '*': { cursor: 'grabbing' },
      boxShadow: '-1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)'
    }
  },
  modal: { size: { lv1: '500px', lv2: '600px', lv3: '916px' } },
  growth: { positive: '#40b87c', negative: '#ff2b52' },
  table: {
    pagination: { offsetTop: '12px', height: '34px' },
    header: { background: { colors: { lv1: '#f6f8fa', lv2: '#fffde3' } } },
    row: {
      border: '1px solid #E6ECF0',
      background: { colors: { default: '#fff', hover: '#f6f8fa', disabled: '#f6f8fa' } }
    }
  },
  datePicker: {
    colors: {
      calendar: '#6e7c89',
      arrow: '#6e7c89',
      hover: { text: '', background: '#e1e2e3' },
      range: { text: colors.blue['70'], background: '#ebf4fc' },
      active: { text: colors.white['100'], background: colors.blue['70'] }
    }
  },
  animation: {
    flipX: (open?: boolean) => ({
      transform: open ? 'rotateX(180deg)' : 'rotateX(360deg)',
      transition: 'transform .3s ease-in-out'
    })
  },
  mediaPreview: {
    default: { width: '160px', height: '160px' },
    ytShort: { width: '160px', height: '357px' },
    igReelOrStory: { width: '160px', height: '280px' },
    feedPostCaptionDims: { margin: 16, lineHeight: 14 },
    hasCaptionOrTitle: { width: '184px', height: '177px' }
  },
  dropdown: { offset: 4, hover: '#179cd726' },
  skeleton: { baseColor: colors.black['20'], highlightColor: colors.black['60'] },
  form: { gapRow: 24, gapColumn: 16 }
} as const;
