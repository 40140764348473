import { z } from 'zod';
import { TwitterAnalyticsPostsSortOrder } from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { FilterValidations } from '@/shared/validations';

export const InfluencerTwitterPostsSearchSchema = z.object({
  filter: z.object({
    keyword: z.string().optional(),
    name: z.string().optional()
  }),
  limit: z.number().default(LIMIT),
  page: z.number().default(1),
  sort: FilterValidations.sort(TwitterAnalyticsPostsSortOrder).default({ field: null, order: null })
});

export type InfluencerTwitterPostsSearchSchemaType = z.infer<typeof InfluencerTwitterPostsSearchSchema>;
