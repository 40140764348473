import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserRoles } from '@/auth';
import { PaymentRequestStatus } from '@/graphql';
import { Button, Popover, FormStyle, DropdownMenu } from '@/shared/atoms';
import { useAllAvailableCountryOptions, useToggleState, useTranslateOptions } from '@/shared/hooks';
import {
  WarningModal,
  SelectorField,
  TextSearchField,
  BasicFilterWrapper,
  RangeDatePickerField
} from '@/shared/molecules';
import { SelectedRangeOption } from '@/shared/types';

import { useDownloadPayments } from './hooks';
import { PaymentFilterFormKeys, PaymentFilterFormValues } from './types';

export interface TemplateProps {
  onSubmit?: () => void;
  disabledDownload: boolean;
}

export const Template = ({ onSubmit, disabledDownload }: TemplateProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useUserRoles();
  const { getValues } = useFormContext<PaymentFilterFormValues>();
  const { status: isOpening, toggleStatus: toggleWarningModal } = useToggleState();
  const values = getValues();
  const { downloading, downloadPaymentOptions, downloadPayments } = useDownloadPayments({ filter: values });
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const statusOptions = useTranslateOptions([
    { label: 'Reviewing', value: PaymentRequestStatus.REVIEWING },
    { label: 'Paid', value: PaymentRequestStatus.PAID },
    { label: 'Failed', value: PaymentRequestStatus.FAILED }
  ]);

  return (
    <BasicFilterWrapper css={{ padding: '12px 16px 16px' }}>
      <TextSearchField<PaymentFilterFormKeys>
        name="keyword"
        title={t('Search.Keyword')}
        onSubmit={onSubmit}
        placeholder={t<string>('Search.PaymentFilter')}
      />

      <SelectorField<PaymentFilterFormKeys>
        title={t('Selector.Country')}
        name="countryId"
        onSubmit={onSubmit}
        css={{ maxWidth: '190px' }}
        options={allAvailableCountryOptions}
      />

      <SelectorField<PaymentFilterFormKeys>
        name="status"
        title={t('Selector.Status')}
        onSubmit={onSubmit}
        options={statusOptions}
        css={{ maxWidth: '190px' }}
      />

      <RangeDatePickerField<PaymentFilterFormKeys>
        onSubmit={onSubmit}
        title={t('Selector.Date Range')}
        name={{ startDate: 'startDate', endDate: 'endDate' }}
        css={css({ maxWidth: '250px', '& > div > div': { minWidth: '225px' } })}
        rangeList={[
          SelectedRangeOption.TODAY,
          SelectedRangeOption.LAST_SEVEN_DAYS,
          SelectedRangeOption.TODAY_TO_MONTH_AGO,
          SelectedRangeOption.THIS_MONTH,
          SelectedRangeOption.LAST_MONTH,
          SelectedRangeOption.CUSTOM
        ]}
      />

      {isAdmin && (
        <>
          <FormStyle.FieldWrapper css={{ flex: 'none' }}>
            <Popover
              target={({ togglePopover }) => (
                <Button
                  variant="white"
                  loading={downloading}
                  title={t('Button.Download')}
                  disabled={disabledDownload}
                  prefixIcon={{ icon: 'download', size: '14px' }}
                  css={{ minWidth: '133px', textTransform: 'uppercase' }}
                  onClick={(e) => {
                    if (!values.countryId) {
                      e?.preventDefault();
                      toggleWarningModal();
                    } else {
                      togglePopover();
                    }
                  }}
                />
              )}
              position="bottom-end"
            >
              {({ togglePopover }) => (
                <DropdownMenu
                  css={{ minWidth: '133px', position: 'relative', margin: 0 }}
                  onSelectOption={(option) => {
                    downloadPayments(option.value);
                    togglePopover();
                  }}
                  options={downloadPaymentOptions}
                />
              )}
            </Popover>
          </FormStyle.FieldWrapper>

          <WarningModal
            open={isOpening}
            onClose={toggleWarningModal}
            description={t('Dialog.Please select a country')}
          />
        </>
      )}
    </BasicFilterWrapper>
  );
};
