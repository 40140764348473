export const TIMEZONE_OPTIONS = [
  { value: 'Africa/Accra', label: 'Africa/Accra (UTC+00:00)' },
  { value: 'Africa/Cairo', label: 'Africa/Cairo (UTC+02:00)' },
  { value: 'Africa/Casablanca', label: 'Africa/Casablanca (UTC+01:00)' },
  { value: 'Africa/Johannesburg', label: 'Africa/Johannesburg (UTC+02:00)' },
  { value: 'Africa/Lagos', label: 'Africa/Lagos (UTC+01:00)' },
  { value: 'Africa/Nairobi', label: 'Africa/Nairobi (UTC+03:00)' },
  { value: 'Africa/Tunis', label: 'Africa/Tunis (UTC+01:00)' },
  { value: 'America/Anchorage', label: 'America/Anchorage (UTC-09:00)' },
  { value: 'America/Argentina/Buenos_Aires', label: 'America/Argentina/Buenos_Aires (UTC-03:00)' },
  { value: 'America/Argentina/Salta', label: 'America/Argentina/Salta (UTC-03:00)' },
  { value: 'America/Argentina/San_Luis', label: 'America/Argentina/San_Luis (UTC-03:00)' },
  { value: 'America/Asuncion', label: 'America/Asuncion (UTC-04:00)' },
  { value: 'America/Atikokan', label: 'America/Atikokan (UTC-05:00)' },
  { value: 'America/Belem', label: 'America/Belem (UTC-03:00)' },
  { value: 'America/Blanc-Sablon', label: 'America/Blanc-Sablon (UTC-04:00)' },
  { value: 'America/Bogota', label: 'America/Bogota (UTC-05:00)' },
  { value: 'America/Campo_Grande', label: 'America/Campo_Grande (UTC-04:00)' },
  { value: 'America/Caracas', label: 'America/Caracas (UTC-04:00)' },
  { value: 'America/Chicago', label: 'America/Chicago (UTC-06:00)' },
  { value: 'America/Costa_Rica', label: 'America/Costa_Rica (UTC-06:00)' },
  { value: 'America/Dawson', label: 'America/Dawson (UTC-08:00)' },
  { value: 'America/Dawson_Creek', label: 'America/Dawson_Creek (UTC-07:00)' },
  { value: 'America/Denver', label: 'America/Denver (UTC-07:00)' },
  { value: 'America/Edmonton', label: 'America/Edmonton (UTC-07:00)' },
  { value: 'America/El_Salvador', label: 'America/El_Salvador (UTC-06:00)' },
  { value: 'America/Guatemala', label: 'America/Guatemala (UTC-06:00)' },
  { value: 'America/Guayaquil', label: 'America/Guayaquil (UTC-05:00)' },
  { value: 'America/Halifax', label: 'America/Halifax (UTC-04:00)' },
  { value: 'America/Hermosillo', label: 'America/Hermosillo (UTC-07:00)' },
  { value: 'America/Iqaluit', label: 'America/Iqaluit (UTC-05:00)' },
  { value: 'America/Jamaica', label: 'America/Jamaica (UTC-05:00)' },
  { value: 'America/La_Paz', label: 'America/La_Paz (UTC-04:00)' },
  { value: 'America/Lima', label: 'America/Lima (UTC-05:00)' },
  { value: 'America/Los_Angeles', label: 'America/Los_Angeles (UTC-08:00)' },
  { value: 'America/Managua', label: 'America/Managua (UTC-06:00)' },
  { value: 'America/Mazatlan', label: 'America/Mazatlan (UTC-07:00)' },
  { value: 'America/Mexico_City', label: 'America/Mexico_City (UTC-06:00)' },
  { value: 'America/Montevideo', label: 'America/Montevideo (UTC-03:00)' },
  { value: 'America/Nassau', label: 'America/Nassau (UTC-05:00)' },
  { value: 'America/New_York', label: 'America/New_York (UTC-05:00)' },
  { value: 'America/Noronha', label: 'America/Noronha (UTC-02:00)' },
  { value: 'America/Panama', label: 'America/Panama (UTC-05:00)' },
  { value: 'America/Phoenix', label: 'America/Phoenix (UTC-07:00)' },
  { value: 'America/Port_of_Spain', label: 'America/Port_of_Spain (UTC-04:00)' },
  { value: 'America/Puerto_Rico', label: 'America/Puerto_Rico (UTC-04:00)' },
  { value: 'America/Rainy_River', label: 'America/Rainy_River (UTC-06:00)' },
  { value: 'America/Regina', label: 'America/Regina (UTC-06:00)' },
  { value: 'America/Santiago', label: 'America/Santiago (UTC-04:00)' },
  { value: 'America/Santo_Domingo', label: 'America/Santo_Domingo (UTC-04:00)' },
  { value: 'America/Sao_Paulo', label: 'America/Sao_Paulo (UTC-03:00)' },
  { value: 'America/St_Johns', label: 'America/St_Johns (UTC-04:00)' },
  { value: 'America/Tegucigalpa', label: 'America/Tegucigalpa (UTC-06:00)' },
  { value: 'America/Tijuana', label: 'America/Tijuana (UTC-08:00)' },
  { value: 'America/Toronto', label: 'America/Toronto (UTC-05:00)' },
  { value: 'America/Vancouver', label: 'America/Vancouver (UTC-08:00)' },
  { value: 'Asia/Amman', label: 'Asia/Amman (UTC+02:00)' },
  { value: 'Asia/Baghdad', label: 'Asia/Baghdad (UTC+03:00)' },
  { value: 'Asia/Bahrain', label: 'Asia/Bahrain (UTC+03:00)' },
  { value: 'Asia/Bangkok', label: 'Asia/Bangkok (UTC+07:00)' },
  { value: 'Asia/Beirut', label: 'Asia/Beirut (UTC+02:00)' },
  { value: 'Asia/Colombo', label: 'Asia/Colombo (UTC+05:30)' },
  { value: 'Asia/Dhaka', label: 'Asia/Dhaka (UTC+06:00)' },
  { value: 'Asia/Dubai', label: 'Asia/Dubai (UTC+04:00)' },
  { value: 'Asia/Gaza', label: 'Asia/Gaza (UTC+02:00)' },
  { value: 'Asia/Ho_Chi_Minh', label: 'Asia/Ho_Chi_Minh (UTC+07:00)' },
  { value: 'Asia/Hong_Kong', label: 'Asia/Hong_Kong (UTC+08:00)' },
  { value: 'Asia/Irkutsk', label: 'Asia/Irkutsk (UTC+08:00)' },
  { value: 'Asia/Jakarta', label: 'Asia/Jakarta (UTC+07:00)' },
  { value: 'Asia/Jayapura', label: 'Asia/Jayapura (UTC+09:00)' },
  { value: 'Asia/Jerusalem', label: 'Asia/Jerusalem (UTC+02:00)' },
  { value: 'Asia/Kamchatka', label: 'Asia/Kamchatka (UTC+12:00)' },
  { value: 'Asia/Karachi', label: 'Asia/Karachi (UTC+05:00)' },
  { value: 'Asia/Kolkata', label: 'Asia/Kolkata (UTC+05:30)' },
  { value: 'Asia/Krasnoyarsk', label: 'Asia/Krasnoyarsk (UTC+07:00)' },
  { value: 'Asia/Kuala_Lumpur', label: 'Asia/Kuala_Lumpur (UTC+08:00)' },
  { value: 'Asia/Kuwait', label: 'Asia/Kuwait (UTC+03:00)' },
  { value: 'Asia/Magadan', label: 'Asia/Magadan (UTC+11:00)' },
  { value: 'Asia/Makassar', label: 'Asia/Makassar (UTC+08:00)' },
  { value: 'Asia/Manila', label: 'Asia/Manila (UTC+08:00)' },
  { value: 'Asia/Muscat', label: 'Asia/Muscat (UTC+04:00)' },
  { value: 'Asia/Nicosia', label: 'Asia/Nicosia (UTC+02:00)' },
  { value: 'Asia/Omsk', label: 'Asia/Omsk (UTC+06:00)' },
  { value: 'Asia/Phnom_Penh', label: 'Asia/Phnom_Penh (UTC+07:00)' },
  { value: 'Asia/Qatar', label: 'Asia/Qatar (UTC+03:00)' },
  { value: 'Asia/Riyadh', label: 'Asia/Riyadh (UTC+03:00)' },
  { value: 'Asia/Seoul', label: 'Asia/Seoul (UTC+09:00)' },
  { value: 'Asia/Shanghai', label: 'Asia/Shanghai (UTC+08:00)' },
  { value: 'Asia/Singapore', label: 'Asia/Singapore (UTC+08:00)' },
  { value: 'Asia/Taipei', label: 'Asia/Taipei (UTC+08:00)' },
  { value: 'Asia/Tokyo', label: 'Asia/Tokyo (UTC+09:00)' },
  { value: 'Asia/Vladivostok', label: 'Asia/Vladivostok (UTC+10:00)' },
  { value: 'Asia/Yakutsk', label: 'Asia/Yakutsk (UTC+09:00)' },
  { value: 'Asia/Yekaterinburg', label: 'Asia/Yekaterinburg (UTC+05:00)' },
  { value: 'Atlantic/Azores', label: 'Atlantic/Azores (UTC-01:00)' },
  { value: 'Atlantic/Canary', label: 'Atlantic/Canary (UTC+00:00)' },
  { value: 'Atlantic/Reykjavik', label: 'Atlantic/Reykjavik (UTC+00:00)' },
  { value: 'Australia/Broken_Hill', label: 'Australia/Broken_Hill (UTC+09:00)' },
  { value: 'Australia/Perth', label: 'Australia/Perth (UTC+08:00)' },
  { value: 'Australia/Sydney', label: 'Australia/Sydney (UTC+10:00)' },
  { value: 'Europe/Amsterdam', label: 'Europe/Amsterdam (UTC+01:00)' },
  { value: 'Europe/Athens', label: 'Europe/Athens (UTC+02:00)' },
  { value: 'Europe/Belgrade', label: 'Europe/Belgrade (UTC+01:00)' },
  { value: 'Europe/Berlin', label: 'Europe/Berlin (UTC+01:00)' },
  { value: 'Europe/Bratislava', label: 'Europe/Bratislava (UTC+01:00)' },
  { value: 'Europe/Brussels', label: 'Europe/Brussels (UTC+01:00)' },
  { value: 'Europe/Bucharest', label: 'Europe/Bucharest (UTC+02:00)' },
  { value: 'Europe/Budapest', label: 'Europe/Budapest (UTC+01:00)' },
  { value: 'Europe/Copenhagen', label: 'Europe/Copenhagen (UTC+01:00)' },
  { value: 'Europe/Dublin', label: 'Europe/Dublin (UTC+01:00)' },
  { value: 'Europe/Helsinki', label: 'Europe/Helsinki (UTC+02:00)' },
  { value: 'Europe/Istanbul', label: 'Europe/Istanbul (UTC+03:00)' },
  { value: 'Europe/Kaliningrad', label: 'Europe/Kaliningrad (UTC+02:00)' },
  { value: 'Europe/Kiev', label: 'Europe/Kiev (UTC+02:00)' },
  { value: 'Europe/Lisbon', label: 'Europe/Lisbon (UTC+00:00)' },
  { value: 'Europe/Ljubljana', label: 'Europe/Ljubljana (UTC+01:00)' },
  { value: 'Europe/London', label: 'Europe/London (UTC+00:00)' },
  { value: 'Europe/Luxembourg', label: 'Europe/Luxembourg (UTC+01:00)' },
  { value: 'Europe/Madrid', label: 'Europe/Madrid (UTC+01:00)' },
  { value: 'Europe/Malta', label: 'Europe/Malta (UTC+01:00)' },
  { value: 'Europe/Moscow', label: 'Europe/Moscow (UTC+03:00)' },
  { value: 'Europe/Oslo', label: 'Europe/Oslo (UTC+01:00)' },
  { value: 'Europe/Paris', label: 'Europe/Paris (UTC+01:00)' },
  { value: 'Europe/Prague', label: 'Europe/Prague (UTC+01:00)' },
  { value: 'Europe/Riga', label: 'Europe/Riga (UTC+02:00)' },
  { value: 'Europe/Rome', label: 'Europe/Rome (UTC+01:00)' },
  { value: 'Europe/Samara', label: 'Europe/Samara (UTC+04:00)' },
  { value: 'Europe/Sarajevo', label: 'Europe/Sarajevo (UTC+01:00)' },
  { value: 'Europe/Skopje', label: 'Europe/Skopje (UTC+01:00)' },
  { value: 'Europe/Sofia', label: 'Europe/Sofia (UTC+02:00)' },
  { value: 'Europe/Stockholm', label: 'Europe/Stockholm (UTC+01:00)' },
  { value: 'Europe/Tallinn', label: 'Europe/Tallinn (UTC+02:00)' },
  { value: 'Europe/Vienna', label: 'Europe/Vienna (UTC+01:00)' },
  { value: 'Europe/Vilnius', label: 'Europe/Vilnius (UTC+02:00)' },
  { value: 'Europe/Warsaw', label: 'Europe/Warsaw (UTC+01:00)' },
  { value: 'Europe/Zagreb', label: 'Europe/Zagreb (UTC+01:00)' },
  { value: 'Europe/Zurich', label: 'Europe/Zurich (UTC+01:00)' },
  { value: 'Indian/Maldives', label: 'Indian/Maldives (UTC+05:00)' },
  { value: 'Indian/Mauritius', label: 'Indian/Mauritius (UTC+04:00)' },
  { value: 'Pacific/Apia', label: 'Pacific/Apia (UTC+13:00)' },
  { value: 'Pacific/Auckland', label: 'Pacific/Auckland (UTC+12:00)' },
  { value: 'Pacific/Easter', label: 'Pacific/Easter (UTC-06:00)' },
  { value: 'Pacific/Galapagos', label: 'Pacific/Galapagos (UTC-06:00)' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu (UTC-10:00)' },
  { value: 'Pacific/Majuro', label: 'Pacific/Majuro (UTC+12:00)' },
  { value: 'Pacific/Midway', label: 'Pacific/Midway (UTC-11:00)' },
  { value: 'Pacific/Niue', label: 'Pacific/Niue (UTC-11:00)' },
  { value: 'Pacific/Pago_Pago', label: 'Pacific/Pago_Pago (UTC-11:00)' }
];
