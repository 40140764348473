import { InfluencerNewProfileHeaderV2 } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfilePostsWrapper } from '../../shared';
import { InfluencerInstagramProfileFeedPosts } from './InfluencerInstagramProfileFeedPosts';
import { InfluencerInstagramProfilePostsOverview } from './InfluencerInstagramProfilePostsOverview';
import { InfluencerInstagramProfileReelPosts } from './InfluencerInstagramProfileReelPosts';

export interface InfluencerInstagramProfilePostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerInstagramProfilePosts = ({
  socialAccount,
  influencerProfile
}: InfluencerInstagramProfilePostsProps) => (
  <InfluencerProfilePostsWrapper socialAccount={socialAccount} influencerProfile={influencerProfile}>
    {({ data }) => (
      <>
        <InfluencerInstagramProfilePostsOverview
          data={data}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
        <InfluencerInstagramProfileFeedPosts
          socialAccount={socialAccount}
          data={data?.feedPosts || []}
          influencerProfile={influencerProfile}
        />
        {data?.reelPosts !== null ? (
          <InfluencerInstagramProfileReelPosts
            socialAccount={socialAccount}
            data={data?.reelPosts || []}
            influencerProfile={influencerProfile}
          />
        ) : null}
      </>
    )}
  </InfluencerProfilePostsWrapper>
);
