import {
  InfluencerAnalyticsInstagramProfileTags,
  InfluencerAnalyticsInstagramProfileOverview,
  InfluencerAnalyticsInstagramProfileAudience,
  InfluencerAnalyticsInstagramProfileFeedPosts,
  InfluencerAnalyticsInstagramProfileReelPosts,
  InfluencerAnalyticsInstagramProfileStoryPosts,
  InfluencerAnalyticsInstagramProfilePostsOverview,
  InfluencerAnalyticsInstagramProfileSponsoredPosts,
  InfluencerAnalyticsInstagramProfileMentionedAccounts,
  InfluencerAnalyticsInstagramProfileSimilarInfluencers
} from '../../InfluencerAnalyticsProfiles';
import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsInstagramProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerAnalyticsInstagramProfile = ({ socialAccount }: InfluencerAnalyticsInstagramProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerAnalyticsInstagramProfileOverview socialAccount={socialAccount} />
      <InfluencerAnalyticsInstagramProfileAudience socialAccount={socialAccount} />
      <InfluencerAnalyticsInstagramProfilePostsOverview
        socialAccount={socialAccount}
        influencerProfile={influencerProfile}
      />
      <InfluencerAnalyticsInstagramProfileFeedPosts socialAccount={socialAccount} influencerId={influencerProfile.id} />
      <InfluencerAnalyticsInstagramProfileReelPosts socialAccount={socialAccount} influencerId={influencerProfile.id} />
      <InfluencerAnalyticsInstagramProfileStoryPosts
        socialAccount={socialAccount}
        influencerId={influencerProfile.id}
      />
      <InfluencerAnalyticsInstagramProfileSponsoredPosts
        socialAccount={socialAccount}
        influencerProfile={influencerProfile}
      />
      <InfluencerAnalyticsInstagramProfileMentionedAccounts
        socialAccount={socialAccount}
        influencerId={influencerProfile.id}
      />
      <InfluencerAnalyticsInstagramProfileTags socialAccount={socialAccount} influencerId={influencerProfile.id} />
      <InfluencerAnalyticsInstagramProfileSimilarInfluencers
        socialAccount={socialAccount}
        influencerProfile={influencerProfile}
      />
    </>
  );
};
