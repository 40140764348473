import { useRef } from 'react';

import { useDimensions } from './useDimensions';

interface Props {
  hasWidthSmallerThan?: number;
}
export const useContainerQuery = ({ hasWidthSmallerThan }: Props = { hasWidthSmallerThan: undefined }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width, height } = useDimensions(containerRef);

  const isSmaller = width && hasWidthSmallerThan ? width < hasWidthSmallerThan : false;

  return {
    containerRef,
    width,
    height,
    isSmaller
  };
};
