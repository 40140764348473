import { css } from '@emotion/react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { EngagementPostTrafficSource, YoutubeTrafficSource } from '@/graphql';
import { TextCutter, Tooltip } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatIntNumber, formatPercent } from '@/shared/utils';
import { HighChart, Highcharts } from '../../../HighChart';
import { mapYoutubeTrafficSourceToUserContent } from './utils';

export interface TrafficSourceChartProps {
  className?: string;
  data: readonly EngagementPostTrafficSource[]; // Only support Engagement for now
}
export const TrafficSourceChart = ({ data, className }: TrafficSourceChartProps) => {
  const { t } = useTranslation();
  const totalViews = data?.reduce((sum, curr) => curr.views + sum, 0);
  const formattedSourceData =
    totalViews && data
      ? data.map((item, i) => ({
          name: item.sourceType,
          y: parseFloat((item.views / totalViews).toFixed(4)),
          value: item.views,
          color: styles.colors[i]
        }))
      : [];
  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      height: 400,
      width: 400,
      borderRadius: 0,
      backgroundColor: '#F6F8FA'
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        innerSize: 120,
        dataLabels: {
          enabled: false
        },
        showInLegend: false
      }
    },
    series: [
      {
        type: 'pie',
        colors: styles.colors,
        data: formattedSourceData
      }
    ],
    tooltip: {
      formatter(): string {
        return renderToStaticMarkup(
          <div>
            <div css={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '4px' }}>
              <span css={[styles.sourceIndicator(this.color as string)]} />
              <span css={{ fontSize: '14px', color: 'white' }}>
                {t(mapYoutubeTrafficSourceToUserContent(this.name as YoutubeTrafficSource).name)}
              </span>
            </div>
            <span css={{ fontSize: '13px', color: 'white' }}>{formatPercent(this.y)}</span>
          </div>
        );
      },
      shadow: false,
      padding: 8,
      borderWidth: 0,
      borderRadius: 8,
      backgroundColor: THEME.chart.tooltip.background.colors.black,
      style: { color: THEME.chart.tooltip.text.colors.white },
      useHTML: true
    }
  };

  return (
    <div css={{ display: 'flex', columnGap: 24 }} className={className}>
      <div css={{ flex: 1 }}>
        <HighChart options={options} />
      </div>
      <div css={{ flex: 1 }}>
        <table css={styles.table}>
          <thead>
            <tr css={{ fontWeight: 600 }}>
              <th>{t('Traffic Source')}</th>
              <th>{t('HeaderColumn.Video view')}</th>
            </tr>
          </thead>
          <tbody>
            {formattedSourceData.map((source) => {
              const selectedContent = mapYoutubeTrafficSourceToUserContent(source.name);

              return (
                <tr key={source.name}>
                  <td>
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                      <span css={[styles.sourceIndicator(source?.color), { marginRight: '8px' }]} />
                      <Tooltip help={t(selectedContent.help)}>
                        <span
                          css={{
                            cursor: 'pointer',
                            ':hover': {
                              textDecoration: 'underline'
                            }
                          }}
                        >
                          {t(selectedContent.name)}
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                  <td>
                    <div css={{ display: 'flex', alignItems: 'baseline' }}>
                      <TextCutter text={formatIntNumber(source.value)} lines={1} />
                      &nbsp;
                      <span css={{ color: THEME.text.colors.gray.lv3, fontSize: '10px' }}>
                        {formatPercent(source.y)}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  table: css({
    table: {
      borderCollapse: 'collapse'
    },
    'td, th': {
      width: '50%',
      border: '1px solid #DEE5EC',
      padding: '10px'
    }
  }),
  sourceIndicator: (color?: string) =>
    css({
      display: 'inline-block',
      marginRight: 4,
      height: '4px',
      width: '4px',
      borderRadius: '50%',
      backgroundColor: color,
      flexShrink: 0
    }),
  colors: [
    '#A32530',
    '#4992C3',
    '#FDCDBE',
    '#F43E08',
    '#F89499',
    '#68A5CD',
    '#E28018',
    '#F9DEC2',
    '#79AFD3',
    '#D85262',
    '#EC9E4A',
    '#FDC8B7',
    '#8CBAD9',
    '#F5676E',
    '#FCCACD',
    '#F2C08A',
    '#B3D1E6',
    '#FEDDDF',
    '#F5CDA2',
    '#FCAD94',
    '#F24049',
    '#589BC8'
  ]
};
