import { QueryHookOptions } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Query, useAllCategoriesQuery } from '@/graphql';

interface AllAvailableCountriesProps extends QueryHookOptions<Query> {
  hasUSCountry?: boolean;
}
export const useAllCategoryOptions = ({ hasUSCountry, ...options }: AllAvailableCountriesProps = {}) => {
  const { t } = useTranslation();
  const { data, loading } = useAllCategoriesQuery({ fetchPolicy: 'cache-first', ...options });

  return {
    loading,
    allCategoryOptions: data?.allCategories?.map((category) => ({
      value: category.id.toString(),
      label: t(`Option.${category.name}`),
      submenu: category.minorCategories.map((s) => ({ value: s.id.toString(), label: t(`Option.${s.name}`) }))
    }))
  };
};
