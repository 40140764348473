export const LOCATION_LIST_OPTIONS = [
  { value: 'TH', label: 'Thailand' } // https://adasiaholdings.atlassian.net/browse/AT-6557
  // { value: 'EG', label: 'Egypt' },
  // { value: 'JP', label: 'Japan' },
  // { value: 'KR', label: 'Korea' },
  // { value: 'KW', label: 'Kuwait' },
  // { value: 'MA', label: 'Morocco' },
  // { value: 'QA', label: 'Qatar' },
  // { value: 'SA', label: 'Saudi Arabia' },
  // { value: 'ZA', label: 'South Africa' },
  // { value: 'TR', label: 'Turkey' },
  // { value: 'AE', label: 'United Arab Emirates' }
];
