import { useInfluencerUpdateDetailV2Query } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useAllTalentAgencyOrPartnerOptions, useQueryHelper } from '@/shared/hooks';
import { Template } from './Template';

interface EditInfluencerForOthersProps {
  influencerId: number;
}

export const EditInfluencerForOthers = ({ influencerId }: EditInfluencerForOthersProps) => {
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { allTalentAgenciesPartners, loading: fetchingTalentAgencyPartner } = useAllTalentAgencyOrPartnerOptions();
  const { data, loading, networkStatus } = useInfluencerUpdateDetailV2Query({
    fetchPolicy: 'network-only',
    variables: { pk: influencerId },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/influencer/add' });
    }
  });

  const influencer = data?.influencerUpdateDetailV2 || null;

  return (
    <RenderDataWithFallback loading={(loading || fetchingTalentAgencyPartner) && networkStatus === 1}>
      {influencer ? <Template influencer={influencer} allTalentAgenciesPartners={allTalentAgenciesPartners} /> : null}
    </RenderDataWithFallback>
  );
};
