import { ApolloError } from '@apollo/client';
import update from 'immutability-helper';
import { useEffect, useState } from 'react';
import { useParams } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import { SocialAccountStatus, SocialAccountType, useGenerateTalentInfluencerSignUpUrlMutation } from '@/graphql';
import { useParamsSearch, useQueryHelper } from '@/shared/hooks';
import { SocialAccountsFormValues } from './formSections';
import {
  TalentInfluencerFormValues,
  TalentUserAddLocationSearchStateType,
  TalentUserAddLocationStateType
} from './schemaTypes';
import { defaultTalentInfluencerFormValues } from './utils';

export interface AddAccountStateType {
  connectableIgAccounts?: TalentUserAddLocationStateType['connectableIgAccounts'];
  connectableYtChannels?: TalentUserAddLocationStateType['connectableYtChannels'];
  connectableFbFanpages?: TalentUserAddLocationStateType['connectableFbFanpages'];
  allPagesResponse?: TalentUserAddLocationStateType['connectableIgAccounts'];
  draftIgAccounts: SocialAccountsFormValues['connectedInstagrams'];
  draftFacebookAccount?: TalentUserAddLocationStateType['connectableFbAccount'];
  draftYtChannel?: SocialAccountsFormValues['connectedYouTubes'][number];
  savedFormValues: TalentInfluencerFormValues;
  isModalOpen: boolean;
  signUpUrl?: string;
  connectType: SocialAccountType | '';
  draftFbPagesIds: string[];
  step: 1 | 2;
}
const defaultDraftIgAccount = {
  fbPageName: '',
  instagramAccountName: '',
  id: 0,
  fbPageId: '',
  needReconnect: false
};
export const useConnectedTalentAccountsFromState = (initialFormValue?: TalentInfluencerFormValues) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const params = useParams({ strict: false }); // both add/edit pages use this hook
  const { isTalentAgency } = useUserRoles();
  const { id } = params as { id: string };
  const { state: routerState } = useParamsSearch<TalentUserAddLocationSearchStateType>();
  const { callGenerateTalentInfluencerSignUpUrl } = useGenerateTalentInfluencerSignUpUrlMutation();

  useEffect(() => {
    // Set Initial Value if passed in props even before POP
    // this is because on next/back tab we need to set the form values.
    if (routerState?.talentAgencyUserFormValues) {
      setFormValuesFromLocalStorage(routerState.talentAgencyUserFormValues);
    } else if (initialFormValue) {
      setFormValuesFromLocalStorage(initialFormValue);
    }

    if (!id) {
      // we can see TA or Partner influencer creation link only for add page
      getSignUpInfluencerUrl();
    }

    // Manage Router State
    if (routerState) {
      if (routerState?.connectType === SocialAccountType.INSTAGRAM) {
        if (routerState?.connectableFbAccount && routerState?.connectableIgAccounts?.length) {
          setState((prevState) => ({
            ...prevState,
            isModalOpen: true,
            connectableIgAccounts: routerState.connectableIgAccounts,
            draftFbAccount: routerState.connectableFbAccount,
            connectType: SocialAccountType.INSTAGRAM
          }));
        }
      } else if (routerState?.connectType === SocialAccountType.FACEBOOK) {
        if (routerState?.connectableFbFanpages?.length) {
          setState((prevState) => ({
            ...prevState,
            isModalOpen: true,
            draftFbAccount: routerState.connectableFbAccount,
            connectableIgAccounts: routerState.connectableIgAccounts,
            connectableFbFanpages: routerState.connectableFbFanpages,
            connectType: SocialAccountType.FACEBOOK
          }));
          // if there are no fanpages to connect we need to add fb account or upgrade scrapping to signUp(override it)
        } else if (routerState.connectableFbAccount) {
          setState((prevState) => ({
            ...prevState,
            savedFormValues: {
              ...prevState.savedFormValues,
              connectedFacebook: routerState.connectableFbAccount
            }
          }));
        }
      } else if (routerState?.connectType === SocialAccountType.YOUTUBE) {
        if (routerState?.connectableYtChannels?.length) {
          setState((prevState) => ({
            ...prevState,
            isModalOpen: true,
            connectableYtChannels: routerState.connectableYtChannels,
            connectType: SocialAccountType.YOUTUBE
          }));
        }
      } else if (routerState?.connectType === SocialAccountType.TWITTER) {
        if (routerState?.connectableTwAccount) {
          if (routerState.parsedScrappingAccountInfo?.screenName === routerState.connectableTwAccount.twScreenName) {
            setState((prevState) => {
              const scrappingAccIdx = prevState.savedFormValues?.connectedTwitters?.findIndex(
                (el) => el.twName === routerState.connectableTwAccount?.twScreenName
              );

              return scrappingAccIdx !== undefined && scrappingAccIdx > -1
                ? {
                    ...prevState,
                    savedFormValues: {
                      ...prevState.savedFormValues,
                      connectedTwitters: routerState.connectableTwAccount
                        ? update(prevState.savedFormValues?.connectedTwitters, {
                            [scrappingAccIdx]: { $set: routerState.connectableTwAccount }
                          })
                        : prevState.savedFormValues?.connectedTwitters
                    },
                    connectType: SocialAccountType.TWITTER
                  }
                : { ...prevState };
            });
          } else {
            setState((prevState) => ({
              ...prevState,
              savedFormValues: {
                ...prevState.savedFormValues,
                connectedTwitters: routerState.connectableTwAccount
                  ? [...(prevState.savedFormValues?.connectedTwitters || []), routerState.connectableTwAccount]
                  : prevState.savedFormValues?.connectedTwitters
              },
              connectType: SocialAccountType.TWITTER
            }));
          }
        }
      }
      navigate({ replace: true });
    }
  }, []);

  const [state, setState] = useState<AddAccountStateType>({
    connectableIgAccounts: undefined,
    connectableYtChannels: undefined,
    draftIgAccounts: [],
    isModalOpen: false,
    draftFacebookAccount: undefined,
    draftYtChannel: undefined,
    savedFormValues: defaultTalentInfluencerFormValues,
    connectType: '',
    draftFbPagesIds: [],
    step: 1
  });

  const selectDraftIgAccount = (igAccount: AddAccountStateType['draftIgAccounts'][number]) => {
    const hasAlreadyBeenAdded = state.draftIgAccounts.findIndex((acc) => acc.accountId === igAccount.accountId) > -1;

    setState((prevState) => ({
      ...prevState,
      draftIgAccounts: hasAlreadyBeenAdded
        ? prevState.draftIgAccounts.filter((acc) => acc.accountId !== igAccount.accountId)
        : [...prevState.draftIgAccounts, igAccount]
    }));
  };

  const selectDraftFacebookPage = (fbPageId: string) => {
    const hasAlreadyBeenAdded = state.draftFbPagesIds.findIndex((pageId) => pageId === fbPageId) > -1;

    setState((prevState) => ({
      ...prevState,
      draftFbPagesIds: hasAlreadyBeenAdded
        ? prevState.draftFbPagesIds.filter((pageId) => pageId !== fbPageId)
        : [...prevState.draftFbPagesIds, fbPageId]
    }));
  };

  const addSelectedDraftIgAccount = () => {
    let igSocialAccounts: AddAccountStateType['draftIgAccounts'];
    const igScrappingAccName = routerState?.parsedScrappingAccountInfo?.instagramAccountName;
    if (igScrappingAccName) {
      const scrappingAccIdx = state.savedFormValues?.connectedInstagrams?.findIndex(
        (el) => el.igName === igScrappingAccName
      );

      const signedAccFromDraftToReplace = state.draftIgAccounts.find((acc) => acc.igName === igScrappingAccName);

      if (scrappingAccIdx !== undefined && scrappingAccIdx > -1 && signedAccFromDraftToReplace) {
        igSocialAccounts = update(state.savedFormValues?.connectedInstagrams, {
          [scrappingAccIdx]: { $set: signedAccFromDraftToReplace }
        });
      } else {
        igSocialAccounts = state.draftIgAccounts.length
          ? [...(state.savedFormValues?.connectedInstagrams || []), ...state.draftIgAccounts]
          : state.savedFormValues?.connectedInstagrams;
      }
    } else {
      igSocialAccounts = state.draftIgAccounts.length
        ? [...(state.savedFormValues?.connectedInstagrams || []), ...state.draftIgAccounts]
        : state.savedFormValues?.connectedInstagrams;
    }

    setState((prevState) => ({
      ...prevState,
      savedFormValues: {
        ...prevState.savedFormValues,
        connectedInstagrams: igSocialAccounts,
        connectedFacebook:
          !prevState.savedFormValues?.connectedFacebook ||
          prevState.savedFormValues?.connectedFacebook.status === SocialAccountStatus.SCRAPING
            ? prevState.draftFacebookAccount
            : prevState.savedFormValues.connectedFacebook
      },
      draftIgAccount: defaultDraftIgAccount,
      draftFbAccount: undefined,
      isModalOpen: false,
      connectType: ''
    }));
  };
  const addSelectedDraftYtChannel = () => {
    let ytSocialAccounts: TalentInfluencerFormValues['connectedYouTubes'];
    const ytScrappingChannelId = routerState?.parsedScrappingAccountInfo?.channelId;

    if (ytScrappingChannelId) {
      const scrappingAccIdx = state.savedFormValues?.connectedYouTubes?.findIndex(
        (el) => el.channelId === ytScrappingChannelId
      );

      if (scrappingAccIdx !== undefined && scrappingAccIdx > -1 && state.draftYtChannel) {
        ytSocialAccounts = update(state.savedFormValues?.connectedYouTubes, {
          [scrappingAccIdx]: { $set: state.draftYtChannel }
        });
      }
    } else {
      ytSocialAccounts = state.draftYtChannel
        ? [...(state.savedFormValues?.connectedYouTubes || []), state.draftYtChannel]
        : state.savedFormValues?.connectedYouTubes;
    }

    setState((prevState) => ({
      ...prevState,
      savedFormValues: {
        ...prevState.savedFormValues,
        connectedYouTubes: ytSocialAccounts
      },
      draftYtChannel: undefined,
      connectableYtChannels: undefined,
      isModalOpen: false,
      connectType: ''
    }));
  };
  const addSelectedDraftFbPageAndIgAccounts = () => {
    addSelectedDraftIgAccount();
    const hasDraftFBFanpages = !!state.draftFbPagesIds.length;
    const fbPagesToAdd = hasDraftFBFanpages
      ? state.connectableIgAccounts
          ?.filter((acc) => state.draftFbPagesIds.includes(acc.page.id))
          .map((el) => ({ pageName: el.page.name, pageId: el.page.id, status: SocialAccountStatus.SIGNED_UP })) || []
      : [];
    // when upgrading from scrapping - override all scrapping fanpages
    const prevFanpages =
      (routerState?.parsedScrappingAccountInfo?.needOverrideFbFanpages
        ? state.savedFormValues?.connectedFacebookPages?.filter((fp) => fp.status !== SocialAccountStatus.SCRAPING)
        : state.savedFormValues?.connectedFacebookPages) || [];

    setState((prevState) => ({
      ...prevState,
      savedFormValues: {
        ...prevState.savedFormValues,
        connectedFacebookPages: hasDraftFBFanpages ? [...prevFanpages, ...fbPagesToAdd] : prevFanpages
      },
      draftIgAccount: defaultDraftIgAccount,
      draftFbAccount: undefined,
      draftFbPagesIds: [],
      isModalOpen: false
    }));
  };
  const selectDraftYtChannel = (draftYtChannel: AddAccountStateType['draftYtChannel']) => {
    setState((prevState) => ({
      ...prevState,
      draftYtChannel
    }));
  };
  const onCloseIgOrYt = () => {
    setState((prevState) => ({
      ...prevState,
      isModalOpen: false,
      draftFbAccount: undefined,
      draftIgAccount: defaultDraftIgAccount
    }));
  };
  const setFormValuesFromLocalStorage = (formValues: TalentInfluencerFormValues) => {
    setState((prevState) => ({
      ...prevState,
      savedFormValues: formValues
    }));
  };
  const setModalStep = (step: 1 | 2) => {
    setState((prevState) => ({
      ...prevState,
      step
    }));
  };
  const getSignUpInfluencerUrl = async () => {
    if (isTalentAgency) {
      await callGenerateTalentInfluencerSignUpUrl()
        .then((result) => {
          if (result?.data?.generateTalentInfluencerSignUpUrl?.url) {
            setState((prevState) => ({
              ...prevState,
              signUpUrl: result?.data?.generateTalentInfluencerSignUpUrl?.url
            }));
          }
        })
        .catch((e: ApolloError) => {
          enqueueSnackbar(t(e.message), { variant: 'error' });
        });
    }
  };
  const hasIgModalOpened = !!state.connectableIgAccounts?.length;

  return {
    t,
    state,
    hasIgModalOpened,
    modalTitle: hasIgModalOpened ? 'Choose Instagram Account' : 'Choose YouTube Channel',
    onCloseFb: () => (state.step === 1 ? onCloseIgOrYt() : setModalStep(1)),
    onCloseIgOrYt,
    selectDraftIgAccount,
    setState,
    addSelectedDraftIgAccount,
    selectDraftYtChannel,
    addSelectedDraftYtChannel,
    selectDraftFacebookPage,
    setModalStep,
    addSelectedDraftFbPageAndIgAccounts,
    allConnectableIgAccounts: state.connectableIgAccounts?.filter(
      (acc) =>
        state.draftFbPagesIds?.includes(acc.page.id) ||
        state.savedFormValues?.connectedFacebookPages?.map((cfp) => cfp.pageId).includes(acc.page.id)
    )
  };
};
