import { css } from '@emotion/react';
import { useEffect } from 'react';
import Slider from 'react-slick';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';
import { Icon, MediaPreview } from '@/shared/atoms';
import { POST_THUMBNAIL_DIM, THEME } from '@/shared/constants';
import { downloadFileFromUrl, isVideoThumbnail } from '@/shared/utils';
import { LeftArrow, RightArrow } from './CustomArrows';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface CarouselProps {
  isReel?: boolean;
  sources: readonly string[];
  className?: string;
  showIcon?: boolean;
  currSlideIdx?: number;
  afterChangeHandler?: (currentSlide: number) => void;
  imageDownload?: { allowed: boolean; filename: string };
}

const SETTINGS = {
  speed: 500,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  prevArrow: <LeftArrow to="prev" />,
  nextArrow: <RightArrow to="next" />
};

export const Carousel = ({
  isReel,
  showIcon,
  className,
  sources = [],
  currSlideIdx,
  afterChangeHandler,
  imageDownload
}: CarouselProps) => {
  const { t } = useTranslation();
  let sliderMethods: Slider | null;
  const multipleImages = sources.length > 1;
  const multipleImagesOrPlayableMedia = sources.length > 0; // MediaPreview component can't play a video, so we need to use Slider

  useEffect(() => {
    if (sliderMethods && currSlideIdx !== undefined) {
      sliderMethods.slickGoTo(currSlideIdx);
    }
  }, [currSlideIdx]);

  const downloadAllMedia = async (sources: readonly string[]) => {
    const zip = new JSZip();

    try {
      const fetchPromises = sources.map(async (src) => {
        const response = await fetch(src, {
          method: 'GET'
        });
        const blob = await response.blob();
        const fileName = src.split('/').pop() || 'file';
        return { fileName, blob };
      });

      const files = await Promise.all(fetchPromises);

      files.forEach(({ fileName, blob }) => {
        zip.file(fileName, blob);
      });
      // Generate and download zip
      const content = await zip.generateAsync({ type: 'blob' });
      downloadFileFromUrl(window.URL.createObjectURL(content), imageDownload?.filename || 'media.zip');
    } catch (error) {
      console.error('Failed to download files:', error);
    }
  };

  return multipleImagesOrPlayableMedia ? (
    <Slider
      css={styles.slider}
      className={className}
      afterChange={afterChangeHandler}
      ref={(slider) => (sliderMethods = slider)}
      arrows={multipleImages}
      dots={multipleImages}
      {...SETTINGS}
    >
      {sources.map((source) => {
        const isVideo = isVideoThumbnail(source);

        return (
          <div key={source} css={styles.wrapper}>
            {imageDownload?.allowed && (
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering parent onClick
                  if (sources?.length) {
                    downloadAllMedia(sources);
                  } else if (source) {
                    downloadAllMedia([source]);
                  }
                }}
                css={styles.iconDownload}
              >
                <Icon icon="download" size="12px" color={THEME.text.colors.white} />
                {t('Media Download')}
              </button>
            )}
            {isVideo ? (
              <div css={{ position: 'relative', width: '100%', height: '100%' }}>
                <video
                  src={source}
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  playsInline={true}
                  controls={true}
                  className="sliderImage"
                  css={{ width: '100%', height: '100%' }}
                />
                {showIcon && <Icon size="16px" color="#fff" icon={isReel ? 'clapper' : 'play'} css={styles.icon} />}
              </div>
            ) : (
              //  image type is a default for now, change it if we'll have some other types
              <MediaPreview
                src={source}
                isReel={isReel}
                className="sliderImage"
                css={{ width: '100%', height: '100%' }}
                showIcon={sources.length > 1 ? showIcon : isReel}
              />
            )}
          </div>
        );
      })}
    </Slider>
  ) : (
    <MediaPreview src={null} isReel={isReel} showIcon={false} css={{ width: '100%', height: '100%' }} />
  );
};

const styles = {
  slider: css({
    flex: 'none',
    width: POST_THUMBNAIL_DIM.width,
    height: POST_THUMBNAIL_DIM.height,
    '& .slick-list': {
      height: '100% !important'
    },
    '& .slick-track, & .slick-slide': {
      height: '100%'
    },
    '& div': {
      borderRadius: '8px',
      width: '100%',
      height: '100%',
      outline: 'none !important'
    },
    '& .slick-arrow': {
      zIndex: 100
    },
    '& .slick-arrow.slick-next': {
      right: 0
    },
    '& .slick-arrow.slick-prev': {
      left: 0
    },
    '& .slick-arrow::before': {
      fontSize: '24px',
      textShadow: '0 0 10px rgba(0, 0, 0, 0.4)',
      opacity: 0.8,
      content: '""',
      backgroundColor: THEME.background.colors.white
    },
    '& .slick-dots': {
      bottom: '16px',
      right: 0,
      left: 0,
      margin: 'auto',
      backgroundColor: THEME.background.colors.white,
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '200px',
      padding: '0 2px',
      li: {
        margin: 0,
        width: '14px',
        height: '14px',
        'button::before': {
          fontSize: '11px',
          lineHeight: '13px',
          margin: 0,
          width: '14px',
          color: THEME.attention.colors.blue
        }
      }
    }
  }),
  wrapper: css({
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    position: 'relative',
    '> div:first-of-type': {
      width: '100%',
      height: '100%',
      'video, img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      }
    }
  }),
  icon: css({
    position: 'absolute',
    right: '4px',
    top: '4px',
    zIndex: 1
  }),
  igReelNoPreview: css({
    display: 'flex',
    width: POST_THUMBNAIL_DIM.width,
    height: POST_THUMBNAIL_DIM.height,
    alignItems: 'center',
    justifyContent: 'center',
    background: '#27313b'
  }),
  igReelNoPreviewText: css({
    display: 'flex',
    width: '260px',
    height: '80px',
    gap: '16px',
    flexDirection: 'column',
    alignItems: 'center',
    color: THEME.text.colors.white,
    fontWeight: 400,
    fontSize: '14px'
  }),
  iconDownload: css({
    zIndex: 1,
    position: 'absolute',
    cursor: 'pointer',
    left: '8px',
    top: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '4px 8px',
    borderRadius: '20px',
    fontSize: '12px',
    fontWeight: 600,
    color: THEME.text.colors.white,
    backgroundColor: 'rgba(39, 49, 59, 0.50)',
    '&:hover': {
      backgroundColor: THEME.colors.black['100']
    }
  })
};
