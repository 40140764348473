import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeAnalyticsComments.graphql';
import { Query, QueryyoutubeAnalyticsCommentsArgs } from '../../__generated__/globalTypes';

export const useYoutubeAnalyticsCommentsQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeAnalyticsCommentsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeAnalyticsCommentsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeAnalyticsCommentsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeAnalyticsCommentsArgs>
): QueryResult<Query, QueryyoutubeAnalyticsCommentsArgs> & {
  getYoutubeAnalyticsComments: LazyQueryExecFunction<Query, QueryyoutubeAnalyticsCommentsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeAnalyticsComments, result] = useLazyQuery<Query, QueryyoutubeAnalyticsCommentsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeAnalyticsComments, ...result };
};

export const useYoutubeAnalyticsCommentsPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeAnalyticsComments = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeAnalyticsCommentsArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeAnalyticsCommentsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeAnalyticsComments };
};
