import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import {
  UGCPostStatus as UGCPostStatusType,
  InstagramUGCManagementPostDetailsPayload,
  useRequestInstagramUGCManagementPostMutation,
  namedOperations
} from '@/graphql';
import { Anchor, Button, Icon, Loading, SocialIcon, TextCutter, Textarea } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { Carousel, SingleSelectField, WarningModal } from '@/shared/molecules';
import { checkIsReelPost, formatIntNumber } from '@/shared/utils';
import { AnalyticsSelectedAccountInfo } from '../../../../types';
import { useUGCPostOptions, useUpdateUGCPostStatus } from '../../../hooks';
import { UGCPostStatus } from '../../UGCPostStatus';

interface StaffTemplateProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
  data: InstagramUGCManagementPostDetailsPayload;
}

const MAX_COMMENT_LENGTH = 175;

export const StaffTemplate = ({
  analyticsAccount,
  data: { status, postUrl, postSNSId, likes, comments, content, mediaURLs, staffComment, commentingAllowed }
}: StaffTemplateProps) => {
  const confirmModal = useToggleState();
  const { statusOptions } = useUGCPostOptions();
  const { t, enqueueSnackbar } = useQueryHelper();
  const [comment, setComment] = useState<string>(staffComment?.content || '');
  const { handleChangeStatus, updating } = useUpdateUGCPostStatus({ postSNSId, analyticsAccount });
  const { callRequestInstagramUGCManagementPost, loading } = useRequestInstagramUGCManagementPostMutation({
    refetchQueries: [namedOperations.Query.InstagramUGCManagementPostDetails]
  });

  useEffect(() => {
    setComment(staffComment?.content || '');
  }, [staffComment?.content]);

  // TODO: Specs: For the first release, we don’t need to let users log in with different IG accounts. Please set the IG account (linked with Analytics) as default.
  const isLoggedIn = true;

  const handleCommentInputChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (value.length > MAX_COMMENT_LENGTH) {
      return;
    }

    setComment(value);
  };

  const handlePostComment = async () => {
    try {
      await callRequestInstagramUGCManagementPost({
        variables: {
          input: {
            content: comment,
            postSnsId: postSNSId,
            analyticsAccountId: analyticsAccount.id
          }
        }
      });
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      confirmModal.close();
    }
  };

  return (
    <>
      {updating ? <Loading css={styles.loading} /> : null}

      <div css={styles.wrapper}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <p css={{ fontWeight: 600, fontSize: '18px' }}>{t('Request Rights')}</p>
        </div>

        <div css={{ display: 'flex', gap: '24px', marginTop: '16px' }}>
          <div css={{ fontSize: '14px', boxSizing: 'border-box', flex: 1 }}>
            <p>{t('UGC.Request description')}</p>

            <div css={styles.authorizationWrapper}>
              <p css={{ fontWeight: 600, color: THEME.text.colors.white }}>{t('Authorization status')}</p>
              <SingleSelectField
                name="Status"
                value={status}
                hideDeselectOption
                disabled={updating}
                options={statusOptions}
                css={{ width: '143px' }}
                setFieldValue={(_, value) => handleChangeStatus(value as UGCPostStatusType)}
              />
            </div>

            <div css={{ border: '1px solid #DEE5EC', borderRadius: '3px', marginTop: '16px' }}>
              <div css={{ display: 'flex', alignItems: 'center', padding: '10px 16px' }}>
                <Icon size="24px" icon="sending-mail" />
                <p css={{ fontWeight: 600, marginLeft: '8px' }}>{t('Ask permission by Instagram DM')}</p>
              </div>

              <div css={{ padding: '16px', borderTop: '1px solid #DEE5EC' }}>
                <p>{t('Ask creator permission by your Instagram DM')}</p>
                <Anchor css={[styles.link, { marginTop: '12px' }]} variant="blue" href={postUrl} target="_blank">
                  {t('Contact via Instagram')}
                  <Icon icon="external-link" size="16px" color="#3892e5" css={{ marginLeft: '6px' }} />
                </Anchor>
              </div>
            </div>

            <div css={{ border: '1px solid #DEE5EC', borderRadius: '3px', marginTop: '16px' }}>
              <div css={{ display: 'flex', alignItems: 'center', padding: '13px 16px' }}>
                <Icon size="20px" icon="comment" color="#6E7C89" />
                <p css={{ fontWeight: 600, marginLeft: '8px' }}>{t('Add comment on post to notify your DM')}</p>
              </div>

              <div css={{ padding: '16px', borderTop: '1px solid #DEE5EC' }}>
                <div css={{ display: 'flex', alignItems: 'start' }}>
                  <div css={{ flex: '50% 0 0' }}>
                    <p>{t('Post as')}&nbsp;:</p>
                    <p
                      css={{
                        maxWidth: '130px',
                        color: THEME.text.colors.gray.lv3,
                        fontSize: '12px',
                        lineHeight: '16px'
                      }}
                    >
                      ({t('connect your business account')})
                    </p>
                  </div>

                  {isLoggedIn ? (
                    <div css={{ display: 'flex', alignItems: 'center', flex: '50% 0 0', marginTop: '6px' }}>
                      <SocialIcon size="16px" css={{ marginRight: '4px' }} type={analyticsAccount.socialAccountType} />
                      <TextCutter text={analyticsAccount.socialAccountName} css={{ fontWeight: 600 }} />
                      <Anchor css={styles.link} target="_blank" variant="blue" href={analyticsAccount.socialMediaUrl}>
                        <Icon icon="external-link" size="16px" color="#3892e5" css={{ marginLeft: '6px' }} />
                      </Anchor>
                    </div>
                  ) : (
                    <Button variant="white" title={t('Log in with brand Account')} />
                  )}
                </div>

                <div css={{ display: 'flex', marginTop: '8px' }}>
                  <p css={{ flex: '50% 0 0' }}>{t('Comment date')}&nbsp;:</p>
                  <p css={{ flex: '50% 0 0' }}>
                    {isLoggedIn && !!staffComment?.date
                      ? new Date(staffComment.date).toISOString().split('T')[0] || '-'
                      : '---'}
                  </p>
                </div>

                <p css={{ marginTop: '24px' }}>{t('Add comment on this post ')}</p>
                <Textarea
                  value={comment}
                  css={{ marginTop: '4px', height: '120px' }}
                  disabled={!commentingAllowed}
                  onChange={handleCommentInputChange}
                />

                <div css={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
                  <p css={{ color: THEME.text.colors.gray.lv3 }}>
                    {comment.length}/{MAX_COMMENT_LENGTH}
                  </p>
                  <Button
                    variant="white"
                    title={t('Post comment')}
                    onClick={confirmModal.open}
                    disabled={!isLoggedIn || !commentingAllowed || !comment}
                    css={{ padding: '0 16px', marginLeft: 'auto' }}
                    tooltip={{
                      disabled: isLoggedIn,
                      help: t<string>('Please log in with the account you want to post')
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div css={styles.info}>
            <div css={{ position: 'relative' }}>
              <Carousel
                showIcon
                isReel={checkIsReelPost(postUrl)}
                css={{ width: '364px', height: '364px' }}
                sources={mediaURLs.length > 0 ? mediaURLs : ['']}
              />
              <UGCPostStatus status={status} />
            </div>

            <div css={styles.postMetricWrapper}>
              <div css={{ display: 'flex', alignItems: 'start' }}>
                <SocialIcon size="16px" css={{ marginRight: '18px' }} type={analyticsAccount.socialAccountType} />
                <p css={styles.statistic}>
                  <span>{formatIntNumber(likes)}</span>
                  <br />
                  <span>{t('Likes')}</span>
                </p>
                <p css={styles.statistic}>
                  <span>{formatIntNumber(comments)}</span>
                  <br />
                  <span>{t('Comments')}</span>
                </p>
              </div>

              <p css={{ fontWeight: 600, fontSize: '12px', marginTop: '16px' }}>{t('Post’s Caption')}</p>

              <div css={{ border: '1px solid #EEF3F7', padding: '8px', marginTop: '8px', flex: 1 }}>
                <TextCutter lines={6} text={content} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <WarningModal
        open={confirmModal.status}
        description={t('Are you sure to publish this comment?')}
        footerProps={{
          cancelButtonProps: { title: t('Button.Cancel'), onClick: confirmModal.close },
          submitButtonProps: { loading, title: t('Button.OK'), onClick: handlePostComment }
        }}
      />
    </>
  );
};
const styles = {
  wrapper: css({
    padding: '24px',
    overflow: 'auto',
    boxSizing: 'border-box',
    maxHeight: 'calc(100vh - 48px)'
  }),
  info: css({
    flex: 'none',
    width: '364px',
    display: 'flex',
    fontSize: '14px',
    boxSizing: 'border-box',
    flexDirection: 'column'
  }),
  link: css({
    display: 'flex',
    fontSize: '14px',
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    alignItems: 'center'
  }),
  postMetricWrapper: css({
    display: 'flex',
    padding: '16px',
    flex: '1 1 auto',
    borderTop: 'none',
    flexDirection: 'column',
    border: THEME.border.base,
    borderRadius: '0 0 3px 3px;}'
  }),
  statistic: css({
    padding: '0 8px',
    boxSizing: 'border-box',
    'span:first-of-type': { fontSize: '13px', lineHeight: '16px' },
    'span:last-of-type': { fontSize: '11px', lineHeight: '13px', color: THEME.text.colors.gray.lv3 },
    '& + &': { borderLeft: '1px solid #eef3f7' }
  }),
  authorizationWrapper: css({
    padding: '8px',
    display: 'flex',
    marginTop: '16px',
    borderRadius: '3px',
    alignItems: 'center',
    backgroundColor: '#27313b',
    justifyContent: 'space-between'
  }),
  loading: css({
    top: 0,
    left: 0,
    margin: 0,
    zIndex: 5,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.4)'
  })
};
