import { useTranslation } from 'react-i18next';

import { TextField, TextFieldProps } from '@/shared/molecules';

import { MarketplaceFormKeys } from '../types';

export const CostPerSaleTuneField = (props: Omit<TextFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return (
    <TextField
      unit="%"
      placeholder="0"
      title={t('TextForm.Commission rate')}
      help={t('Tooltip.Commission rate help')}
      name={MarketplaceFormKeys.costPerSaleTune}
      {...props}
    />
  );
};
