import { useState } from 'react';
import { GroupBase, OptionProps } from 'react-select';
import { css } from '@emotion/react';
import { Icon, Popover, TextCutter } from '@/shared/atoms';
import { Option } from '@/shared/types';
import { THEME } from '@/shared/constants';

interface SingleSelectSubmenuOptionProps {
  options: OptionProps<Option<string, string>, false, GroupBase<Option<string, string>>>;
  selectedOption?: {
    value: string;
    label?: string | undefined;
    parent: Option<string, string>;
  };
}
export const SingleSelectSubmenuOption = ({ options, selectedOption }: SingleSelectSubmenuOptionProps) => {
  const [openedId, setOpenedId] = useState<string | null>(null);

  return (
    <Popover
      position="right"
      open={openedId === options.data.value}
      closeOnClickOutside={false}
      withinPortal={false}
      offset={-3}
      target={() => (
        <div
          onMouseOver={() => {
            setOpenedId(options.data.value);
          }}
          onMouseOut={() => {
            setOpenedId(null);
          }}
        >
          <div css={styles.option(selectedOption?.parent?.value === options.data.value)}>
            <TextCutter text={options.data.label} />
            <Icon icon="caret-right" size="10px" color={THEME.text.colors.gray.lv3} />
          </div>
        </div>
      )}
    >
      <div
        onMouseEnter={() => {
          setOpenedId(options.data.value);
        }}
        onMouseLeave={() => {
          setOpenedId(null);
        }}
        css={{ minWidth: '186px' }}
      >
        {options.data?.submenu?.map((s) => (
          <div
            id="skipOutsideClick"
            key={s.value}
            onClick={() => {
              options.selectOption(s);
            }}
          >
            <div css={styles.option(selectedOption?.value === s.value)}>{s.label}</div>
          </div>
        ))}
      </div>
    </Popover>
  );
};

const styles = {
  option: (selected?: boolean) =>
    css({
      display: 'flex',
      padding: '8px',
      height: '32px',
      boxSizing: 'border-box',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontWeight: selected ? 'bold' : 'normal',
      cursor: 'pointer',
      '&:hover': { backgroundColor: THEME.background.colors.gray.lv1 }
    })
};
