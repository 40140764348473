import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ProposalInfluencer } from '@/graphql';
import { Icon, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useDimensions } from '@/shared/hooks';
import { InfluencerAvatar } from '@/shared/molecules';
import { useEngagementProposalInfluencerDetailsParam } from '../hooks';

const slideWidth = 80;
interface InfluencersCarouselProps {
  influencers: readonly ProposalInfluencer[];
}
export const InfluencersCarousel = ({ influencers }: InfluencersCarouselProps) => {
  const { influencerPopupId, setInfluencerDetailsParam } = useEngagementProposalInfluencerDetailsParam();
  const sliderRef = useRef<Slider>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useDimensions(ref);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const [maxInfluencersToShow, setMaxInfluencersToShow] = useState<number>(0);

  const numberOfInfluencers = influencers.length;
  const currentSlideIdx = influencers.findIndex((influencer) => influencer.id === influencerPopupId);
  const slidesToScroll = Math.floor(width / slideWidth) - 1; // this should scroll approx on numbers of slides fits into view - 1
  const settings: Settings = {
    slidesToScroll,
    arrows: false,
    infinite: false,
    centerMode: false,
    variableWidth: true,
    className: 'slider variable-width',
    afterChange: (currentSlide: number) => handleChangeSlide(currentSlide)
  };

  const handleChangeSlide = (currentSlide: number) => {
    setShowLeftArrow(currentSlide > 0);
    setShowRightArrow(currentSlide <= numberOfInfluencers - maxInfluencersToShow);
  };

  useEffect(() => {
    setMaxInfluencersToShow(Math.ceil(width / slideWidth) - 2); // 2: slider's offset
    setShowRightArrow(width < numberOfInfluencers * slideWidth);
  }, [width]);

  // Go to slide's part contain the selected index
  useEffect(() => {
    if (sliderRef.current && maxInfluencersToShow && currentSlideIdx >= maxInfluencersToShow) {
      sliderRef.current.slickGoTo(maxInfluencersToShow);
      handleChangeSlide(maxInfluencersToShow);
    }
  }, [currentSlideIdx, maxInfluencersToShow]);

  return (
    <div css={{ position: 'relative' }} ref={ref}>
      {showLeftArrow && (
        <div css={[styles.arrowWrapper, { left: 0 }]} onClick={() => sliderRef.current?.slickPrev()}>
          <Icon size={10} color="#6e7c89" icon="caret-left" />
        </div>
      )}

      <Slider
        {...settings}
        ref={sliderRef}
        css={css({ '& .slick-track': { display: 'flex', width: 'calc(100vw - 340px) !important' } })}
      >
        {influencers.map(({ id, avatar, name }) => {
          const isActive = id === influencerPopupId;

          return (
            <div
              key={id}
              onClick={() => setInfluencerDetailsParam(id)}
              css={{ cursor: 'pointer', '&:focus': { outline: 'none' } }}
            >
              <InfluencerAvatar objectFit="contain" size="64px" src={avatar} css={styles.avatar(isActive)} />
              <TextCutter text={name} css={styles.name(isActive)} />
            </div>
          );
        })}
      </Slider>

      {showRightArrow && (
        <div css={[styles.arrowWrapper, { right: 0 }]} onClick={() => sliderRef.current?.slickNext()}>
          <Icon size={10} color="#6e7c89" icon="caret-right" />
        </div>
      )}
    </div>
  );
};

const styles = {
  wrapper: css({
    display: 'flex',
    overflowX: 'unset',
    position: 'relative',
    scrollBehavior: 'smooth'
  }),
  avatar: (isActive?: boolean) =>
    css({
      margin: 'auto',
      borderRadius: '50%',
      boxSizing: 'content-box',
      backgroundColor: 'transparent',
      border: `4px solid ${isActive ? '#ff2b52' : 'transparent'}`
    }),
  name: (isActive?: boolean) =>
    css({
      fontSize: '12px',
      lineHeight: '2ch',
      textAlign: 'center',
      width: `${slideWidth}px`,
      color: isActive ? '#fff' : '#6e7c89'
    }),
  arrowWrapper: css({
    zIndex: 1,
    top: '50%',
    width: '48px',
    height: '48px',
    display: 'flex',
    fontSize: '16px',
    borderRadius: '50%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#dee5ec',
    color: THEME.text.colors.gray.lv3,
    transform: 'translateY(-50%)',
    '&:hover': { cursor: 'pointer' }
  })
};
