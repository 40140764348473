import { css } from '@emotion/react';
import { Trans } from 'react-i18next';
import { useEngagementPermissions, useUserRoles } from '@/auth';
import { UserRoles, EngagementDetail, EngagementProposal, EngagementCampaignStatus } from '@/graphql';
import { Anchor, Button, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { useUpdateEngagementStatus } from './hooks';
import { ApproveModal, PublishModal, MissingHubSpotModal, WarningCouponModal } from './Modals';

interface ProposalActionsProps {
  engagement: EngagementDetail;
  hasTrackingReferral?: boolean;
  engagementProposal: EngagementProposal;
}

export const ProposalActions = ({
  hasTrackingReferral,
  engagementProposal: { couponCodeCount, influencers },
  engagement: {
    id,
    title,
    status,
    salesPics,
    hubspotDealIds,
    isTtcmCampaign,
    campaignCreator,
    influencerManagementPics
  }
}: ProposalActionsProps) => {
  const { t, i18n } = useQueryHelper();
  const draftDialog = useToggleState();
  const publishDialog = useToggleState();
  const setDealIdDialog = useToggleState();
  const warningCouponDialog = useToggleState();
  const { hideApproveCampaignBtn, hideMakePublicBtn } = useEngagementPermissions();
  const { isAdvertiser, isAdminStaff, isAdminStaffAgencyTalentAgencyPartner } = useUserRoles();
  const { updateEngagementStatus, loading } = useUpdateEngagementStatus({ id, status, title });

  const couponCodes = couponCodeCount || 0;
  const isDraft = status === EngagementCampaignStatus.DRAFT;
  const isOngoing = status === EngagementCampaignStatus.ONGOING;
  const isUpcoming = status === EngagementCampaignStatus.UPCOMING;
  const isReviewing = status === EngagementCampaignStatus.REVIEWING;
  const hasMakeCampaignPublic = isDraft && isAdminStaffAgencyTalentAgencyPartner;
  const hasPicsForApproveCampaign = !!(salesPics?.length && influencerManagementPics?.length);
  const hasApproveProposal = !isAdvertiser ? isReviewing : isReviewing && hasPicsForApproveCampaign;
  const hasPublishButton = !hideMakePublicBtn && hasMakeCampaignPublic;
  const hasApproveButton = !hideApproveCampaignBtn && hasApproveProposal;
  const needToSetDealIdBeforeApprove =
    isAdminStaff &&
    hubspotDealIds?.length === 0 &&
    campaignCreator &&
    [UserRoles.ADMINISTRATOR, UserRoles.STAFF].includes(campaignCreator.role);
  const numberOfSelectedInfluencers = influencers.filter((infl) => !infl.isDenied).length;
  const isCouponLessThanSelectedInfluencers = couponCodes < numberOfSelectedInfluencers;
  const isHideActions = isTtcmCampaign
    ? !(isDraft || isOngoing || isUpcoming || isReviewing) && !hasPublishButton && !hasApproveButton // Show temporary for ongoing campaign. Details https://adasiaholdings.atlassian.net/browse/AT-4817
    : !hasPublishButton && !hasApproveButton;

  const handleUpdateEngagementStatus = (callback: () => void) => async (isDemoPublished?: boolean) => {
    await updateEngagementStatus?.(isDemoPublished);
    callback();
  };

  if (isHideActions) {
    return null;
  }

  return (
    <>
      <div css={styles.wrapper(isTtcmCampaign)}>
        <div css={styles.item(!isDraft, isUpcoming || isOngoing ? '#DEE5EC' : '')} className="item-wrapper">
          <TextCutter css={styles.title} text={`1. ${t('PublishProposalTitle')}`} />
          <div css={styles.group} className="group">
            <TextCutter css={styles.description} text={t('PublishProposalDescription')} lines={2} />
            <Button
              variant="blue"
              className="action-button"
              css={styles.actionButton}
              disabled={!hasPublishButton || loading}
              onClick={isDraft ? draftDialog.open : publishDialog.open}
              title={t(loading ? 'Button.Updating' : 'Button.Publish Proposal')}
            />
          </div>
        </div>

        <div
          css={styles.item(isDraft || isOngoing || !hasApproveButton, isDraft ? '#DEE5EC' : '')}
          className="item-wrapper"
        >
          <TextCutter css={styles.title} text={`2. ${t('ApproveProposalTitle')}`} />
          <div css={styles.group} className="group">
            <TextCutter css={styles.description} text={t('ApproveProposalDescription')} lines={2} />
            <Button
              variant="blue"
              className="action-button"
              css={styles.actionButton}
              disabled={!hasApproveButton || loading}
              title={t(loading ? 'Button.Updating' : 'Button.Approve Proposal')}
              onClick={
                needToSetDealIdBeforeApprove
                  ? setDealIdDialog.open
                  : hasTrackingReferral && isCouponLessThanSelectedInfluencers
                    ? warningCouponDialog.open
                    : publishDialog.open
              }
            />
          </div>
        </div>

        {isAdminStaff && isTtcmCampaign ? (
          <div css={styles.item(!(isUpcoming || isOngoing))} className="item-wrapper">
            <TextCutter css={styles.title} text={`3. ${t('Share TTCM invitation link')}`} />
            <p css={styles.description}>
              <Trans
                i18nKey="ShareTTCMInvitationLinkDescription"
                components={{
                  br: <br />,
                  a: (
                    <Anchor
                      variant="blue"
                      target="_blank"
                      css={{ display: 'inline' }}
                      href={
                        i18n.language === 'ja'
                          ? 'https://anymind-group.gitbook.io/anytag-1/kyanpn/tiktok-creator-marketplacettcm-kyanpn/ttcm-kyanpnkdowonieru'
                          : 'https://anymind-group.gitbook.io/english-1/kyanpn/engjimento/tiktok-creator-marketplace-ttcm-campaign'
                      }
                    />
                  )
                }}
              />
            </p>
          </div>
        ) : null}
      </div>

      <PublishModal
        loading={loading}
        open={draftDialog.status}
        onClose={draftDialog.close}
        onUpdateStatus={handleUpdateEngagementStatus(draftDialog.close)}
      />

      <ApproveModal
        open={publishDialog.status}
        onClose={publishDialog.close}
        isTtcmCampaign={isTtcmCampaign}
        onUpdateStatus={handleUpdateEngagementStatus(publishDialog.close)}
      />

      <MissingHubSpotModal engagementId={id} open={setDealIdDialog.status} onClose={setDealIdDialog.close} />

      <WarningCouponModal
        engagementId={id}
        couponCodes={couponCodes}
        influencers={influencers}
        open={warningCouponDialog.status}
        onClose={warningCouponDialog.close}
      />
    </>
  );
};

const styles = {
  group: css({ display: 'flex', justifyContent: 'space-between' }),
  description: css({ flex: 1, fontWeight: 400, color: 'inherit', fontSize: '12px', lineHeight: '18px' }),
  title: css({ fontWeight: 600, color: 'inherit', fontSize: '14px', lineHeight: '18px', marginBottom: '8px' }),
  actionButton: css({ padding: '0 24px', marginLeft: '24px', width: 'max-content', textTransform: 'uppercase' }),
  wrapper: (isTtcmCampaign?: boolean) =>
    css({
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
      borderBottom: THEME.border.base,
      height: isTtcmCampaign ? '132px' : '112px',
      ...(isTtcmCampaign
        ? {
            '.group': { flexDirection: 'column' },
            '.item-wrapper': {
              '&:not(:last-of-type)': {
                '&::after, &::before': { borderTopWidth: '66px', borderBottomWidth: '66px' }
              }
            },
            '.action-button': { marginLeft: 0, marginTop: '8px' }
          }
        : {})
    }),
  item: (disabled?: boolean, beforeBorderColor?: string) =>
    css({
      flex: '1 0 0',
      position: 'relative',
      alignSelf: 'stretch',
      boxSizing: 'border-box',
      padding: '16px 16px 16px 24px',
      color: disabled ? '#6e7c89' : '#27313b',
      backgroundColor: disabled ? '#F6F8FA' : '#fff',
      '&:not(:first-of-type)': { paddingLeft: '48px' },
      '&:not(:last-of-type)': {
        '&::after, &::before': {
          top: 0,
          width: 0,
          zIndex: 2,
          content: '""',
          right: '-32px',
          height: '100%',
          position: 'absolute',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          borderTop: '56px solid transparent',
          borderBottom: '56px solid transparent',
          borderLeft: `32px solid ${disabled ? '#F6F8FA' : '#fff'}`
        },
        '&::before': { zIndex: 1, right: '-34px', borderLeftColor: beforeBorderColor || '#f6f8fa' }
      }
    })
};
