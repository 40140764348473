import { useAllYoutubeSimilarInfluencersLazyQuery } from '@/graphql';
import { DEFAULT_SIMILAR_INFLUENCERS_LIMITATION, YT_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { formatInfluencerProfile } from '../utils';

interface Props {
  socialAccountId: string;
}

export const useYoutubeSimilarInfluencersList = ({ socialAccountId }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const variables = {
    accountId: Number(socialAccountId),
    limit: DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.LIST.FETCH
  };
  const { data, error, loading, refetch, getAllYoutubeSimilarInfluencers } = useAllYoutubeSimilarInfluencersLazyQuery({
    variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      if (error.message !== YT_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY) {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    }
  });

  const similarInfluencers = data?.allYoutubeSimilarInfluencers || [];

  return {
    error,
    loading,
    onRefetchData: refetch,
    limit: variables.limit,
    totalRecords: similarInfluencers.length || 0,
    onFetchData: getAllYoutubeSimilarInfluencers,
    listRecords: similarInfluencers?.map(formatInfluencerProfile) || []
  };
};
