import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { Option, SortOption } from '@/shared/types';
import { InfluencerListSortField } from '@/graphql';
import { FormStyle, CheckBox, CheckBoxProps } from '@/shared/atoms';
import { LIMIT_100, LIMIT_50, LIMIT, THEME } from '@/shared/constants';
import { BasicFilterWrapper, SelectorField } from '@/shared/molecules';
import { MarketplaceInfluencersSortFormKeys, MarketplaceInfluencersSortFormValues } from './types';

interface TemplateProps {
  onSubmit?: () => void;
  checkboxProps?: CheckBoxProps;
  sortOptions: SortOption<InfluencerListSortField>[];
}

export const Template = ({ onSubmit, sortOptions, checkboxProps }: TemplateProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<MarketplaceInfluencersSortFormValues>();

  const limitOptions: Option[] = [
    { value: LIMIT.toString(), label: t<string>('View_limit', { count: LIMIT }) },
    { value: LIMIT_50.toString(), label: t<string>('View_limit', { count: LIMIT_50 }) },
    { value: LIMIT_100.toString(), label: t<string>('View_limit', { count: LIMIT_100 }) }
  ];

  const handleSortChange = (
    field: MarketplaceInfluencersSortFormKeys,
    value: string,
    options: SortOption<InfluencerListSortField>[] = []
  ) => {
    const selectedOption = options.find((o) => o.value === value);
    setValue(field, value);
    setValue('field', selectedOption?.field || null);
    setValue('order', selectedOption?.order || null);
  };

  return (
    <BasicFilterWrapper alignItems="center" css={styles.wrapper}>
      {checkboxProps ? (
        <FormStyle.FieldWrapper css={{ flex: 'none' }}>
          <CheckBox
            tooltipProps={{ help: t('Select all') }}
            css={{ fontSize: '14px', fontWeight: 600, label: { color: '#3b4146' } }}
            {...checkboxProps}
          />
        </FormStyle.FieldWrapper>
      ) : null}

      <SelectorField<MarketplaceInfluencersSortFormKeys, SortOption<InfluencerListSortField>>
        name="sort"
        onSubmit={onSubmit}
        options={sortOptions}
        onChange={handleSortChange}
        placeholder={t<string>('Selector.Sort')}
        css={{ marginLeft: 'auto', minWidth: '108px', maxWidth: 'max-content' }}
        singleSelectorProps={{ type: 'radio', styles: { menu: (base) => ({ ...base, width: 'max-content' }) } }}
      />

      <SelectorField<MarketplaceInfluencersSortFormKeys>
        name="limit"
        onSubmit={onSubmit}
        options={limitOptions}
        css={{ maxWidth: '113px' }}
        singleSelectorProps={{ placeholder: '', hideDeselectOption: true }}
      />
    </BasicFilterWrapper>
  );
};

const styles = {
  wrapper: css({
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: THEME.border.base,
    borderBottom: THEME.border.base
  })
};
