import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllInfluencerPostsV3.graphql';
import { Query, QueryallInfluencerPostsV3Args } from '../../__generated__/globalTypes';

export const useAllInfluencerPostsV3Query = (options?: QueryHookOptions<Query, QueryallInfluencerPostsV3Args>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallInfluencerPostsV3Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllInfluencerPostsV3LazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallInfluencerPostsV3Args>
): QueryResult<Query, QueryallInfluencerPostsV3Args> & {
  getAllInfluencerPostsV3: LazyQueryExecFunction<Query, QueryallInfluencerPostsV3Args>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllInfluencerPostsV3, result] = useLazyQuery<Query, QueryallInfluencerPostsV3Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllInfluencerPostsV3, ...result };
};

export const useAllInfluencerPostsV3PromiseQuery = () => {
  const client = useApolloClient();

  const getAllInfluencerPostsV3 = useCallback(
    (options?: Omit<QueryOptions<QueryallInfluencerPostsV3Args, Query>, 'query'>) =>
      client.query<Query, QueryallInfluencerPostsV3Args>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllInfluencerPostsV3 };
};
