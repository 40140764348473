import { zodValidator } from '@tanstack/zod-adapter';
import {
  TalentAgencyBusiness,
  EditTalentAgency,
  TalentAgencyDetails,
  TalentAgencyUsers,
  TalentAgencies,
  AddTalentAgency
} from '@/pages/TalentAgencies';
import { adminStaffTalentAgencyPartnerRoles } from '@/shared/constants';
import { PermissionRoute } from '@/app-components';
import { AccountFilterSearchSchema, AssociatedAccountSearchSchema } from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const TalentAgencyRouter = {
  '/_private-routes/talent-agency': [() => <PermissionRoute permissions={adminStaffTalentAgencyPartnerRoles} />, {}],
  '/_private-routes/talent-agency/': [TalentAgencies, { validateSearch: zodValidator(AccountFilterSearchSchema) }],
  '/_private-routes/talent-agency/add': [AddTalentAgency, {}],
  '/_private-routes/talent-agency/$id': [TalentAgencyDetails, {}],
  '/_private-routes/talent-agency/$id/': [EditTalentAgency, {}],
  '/_private-routes/talent-agency/$id/business': [
    TalentAgencyBusiness,
    { validateSearch: zodValidator(AssociatedAccountSearchSchema) }
  ],
  '/_private-routes/talent-agency/$id/users': [TalentAgencyUsers, {}]
} as const satisfies PartialRouter;
