import { useUserRoles } from '@/auth';
import { AutoManagedCampaignPayload, MarketplaceCampaignDetailType } from '@/graphql';
import { FormProvider } from '@/shared/atoms';
import { DefaultFormProps } from '../types';
import { getMarketplaceInitialFormValues } from '../utils';
import { getEcommerceCampaignFormSchema } from './schema';
import { Template } from './Template';

export interface EcommerceCampaignFormProps extends DefaultFormProps {
  autoManagedCampaignData?: AutoManagedCampaignPayload | null;
}

export const EcommerceCampaignForm = ({
  data,
  onSubmit,
  disabledFields,
  defaultAdvertiser,
  autoManagedCampaignData
}: EcommerceCampaignFormProps) => {
  const { isAgency, role } = useUserRoles();

  const defaultValues = getMarketplaceInitialFormValues({
    data,
    isAgency,
    defaultAdvertiser,
    autoManagedCampaignData,
    campaignType: MarketplaceCampaignDetailType.E_COMMERCE
  });

  return (
    <FormProvider onSubmit={onSubmit} defaultValues={defaultValues} zodSchema={getEcommerceCampaignFormSchema(role)}>
      <Template defaultValues={defaultValues} disabledFields={disabledFields} />
    </FormProvider>
  );
};
