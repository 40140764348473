import { getValuableVariables } from '@/shared/utils';
import { useQueryHelper } from '@/shared/hooks';
import { useInstagramDashboardFollowerAnalyticsQuery } from '@/graphql';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { AnalyticsInstagramDashboardAudienceTemplate } from './AnalyticsInstagramDashboardAudienceTemplate';

export interface AnalyticsInstagramDashboardAudienceProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsInstagramDashboardAudience = ({ analyticsAccount }: AnalyticsInstagramDashboardAudienceProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useInstagramDashboardFollowerAnalyticsQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      accountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsInstagramDashboardAudienceTemplate
      loading={loading}
      accountAvatar={analyticsAccount.avatar}
      data={data?.instagramDashboardFollowerAnalytics}
    />
  );
};
