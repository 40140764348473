import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { TikTokSpecialPackageInfluencer } from '@/graphql';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { bigIntFormatter, formatIntNumber, formatPercent } from '@/shared/utils';
import {
  PostsSection,
  InfluencerAvatar,
  InfluencerPostDetailsModal,
  InfluencerWidgetInformation
} from '@/shared/molecules';
import { ProfileWidgetTemplate, StatsSection } from '../../../../Influencers';

interface ProfileWidgetProps {
  className?: string;
  profile: TikTokSpecialPackageInfluencer;
}

export const ProfileWidget = ({
  className,
  profile: {
    id,
    age,
    name,
    avatar,
    gender,
    country,
    followers,
    engagement,
    popularPosts,
    averageViews,
    engagementViewsRate,
    engagementFollowersRate
  }
}: ProfileWidgetProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const posts = popularPosts?.map((post) => ({ ...post, id: post.postId, influencerId: id })) || [];
  const stats = [
    { label: t('HeaderColumn.Total Followers'), value: bigIntFormatter(followers, 0) },
    { label: t('HeaderColumn.Engagement'), value: formatIntNumber(engagement) },
    { label: t('HeaderColumn.Avg Views'), value: bigIntFormatter(averageViews, 0) },
    { label: t('HeaderColumn.Egg Rate by followers'), value: formatPercent(engagementFollowersRate) },
    { label: t('HeaderColumn.Egg Rate by views'), value: formatPercent(engagementViewsRate) }
  ];

  return (
    <ProfileWidgetTemplate className={className}>
      <InfluencerAvatar size="80px" src={avatar} />

      <InfluencerWidgetInformation
        age={age}
        name={name}
        gender={gender}
        country={country}
        profileUrl={isAdminStaff ? generateProfileLink(id) : undefined}
      />

      <StatsSection id={id} stats={stats} css={{ marginRight: '16px' }} />

      <PostsSection posts={posts} influencerId={id} css={{ marginLeft: '16px' }} />
      <InfluencerPostDetailsModal posts={posts} />
    </ProfileWidgetTemplate>
  );
};
