import { useInstagramAmbassadorPostDetailsQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InstagramAmbassadorPostDetailsProps extends Pick<PostDetailsType, 'id' | 'mainSocialAccountId'> {
  className?: string;
  onCloseModal?: () => void;
}

export const InstagramAmbassadorPostDetails = ({
  id,
  className,
  onCloseModal,
  mainSocialAccountId
}: InstagramAmbassadorPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useInstagramAmbassadorPostDetailsQuery({
    skip: !id || !mainSocialAccountId,
    variables: { postId: String(id), accountId: Number(mainSocialAccountId) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const details = data?.instagramAmbassadorPostDetails;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'INSTAGRAM',
        postUrl: details?.postUrl,
        caption: details?.content,
        images: details?.thumbNail ? [details.thumbNail] : [],
        info: { socialMedia: 'INSTAGRAM', name: details?.username },
        statistics: { likes: details?.likes, comments: details?.comments }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      loading={loading}
      className={className}
      css={{ width: '816px' }}
      postDetails={postDetails}
    />
  );
};
