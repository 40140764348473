import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { InfluencerPostV3 } from '@/graphql';
import { usePostDetailsParam } from '@/shared/hooks';
import { PostDetailsType, TableData } from '@/shared/types';
import { InfluencerPostDetailsModal } from '@/shared/molecules';
import { MediaPreviewWithMetrics, RenderDataWithFallback } from '@/shared/atoms';
import { checkIsReelPost, formatPercent } from '@/shared/utils';

export interface PostsListProps extends TableData<InfluencerPostV3> {
  hasSimilarPosts?: boolean;
}

export const PostsList = ({ data = [], loading, hasSimilarPosts }: PostsListProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  return (
    <>
      <RenderDataWithFallback loading={loading} hasNoData={!data.length}>
        <div css={styles.wrapper}>
          {data.map(({ postId, postUrl, content, thumbNail, followers, engagementRate }) => (
            <div css={styles.itemWrapper} key={postId}>
              <div css={styles.squareRatio}>
                <div css={styles.item} onClick={() => setPostDetailsParam(postId)}>
                  <MediaPreviewWithMetrics
                    src={thumbNail}
                    caption={content}
                    isReel={checkIsReelPost(postUrl)}
                    statistics={[
                      // Hide followers - https://adasiaholdings.atlassian.net/browse/AT-6691
                      // { label: t('Followers'), value: bigIntFormatter(followers, 0, { k: true, m: true }) },
                      { label: t('EngRate'), value: formatPercent(engagementRate) }
                    ]}
                    showIcon
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </RenderDataWithFallback>

      <InfluencerPostDetailsModal
        hasSimilarPosts={hasSimilarPosts}
        posts={data.map((post) => ({ ...post, id: String(post.postId) })) as PostDetailsType[]}
      />
    </>
  );
};
const styles = {
  wrapper: css({
    padding: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderTop: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  }),
  itemWrapper: css({
    '--size': 'calc(100% / 6)',

    padding: '8px',
    flex: 'var(--size)',
    boxSizing: 'border-box',
    maxWidth: 'min(var(--size), 250px)'
  }),
  squareRatio: css({
    height: 0,
    paddingTop: '100%',
    overflow: 'hidden',
    position: 'relative'
  }),
  item: css({
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '3px',
    position: 'absolute'
  })
};
