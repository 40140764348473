import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateAdvertiserUser.graphql';
import { Mutation, MutationupdateAdvertiserUserArgs } from '../../__generated__/globalTypes';

export const useUpdateAdvertiserUserMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateAdvertiserUserArgs>
) => {
  const [callUpdateAdvertiserUser, result] = useMutation<Mutation, MutationupdateAdvertiserUserArgs>(MUTATION, options);

  return { callUpdateAdvertiserUser, ...result };
};

export type UpdateAdvertiserUserMutationFunction = Mutation['updateAdvertiserUser'];
