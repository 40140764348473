import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { SocialPostType } from '@/graphql';
import { MediaPreview } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';

export interface PostsSectionProps {
  size?: string;
  maxPosts?: number;
  className?: string;
  influencerId: number;
  posts: PostDetailsType[];
}

export const PostsSection = ({ posts, className, maxPosts = 3, influencerId, size = '72px' }: PostsSectionProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const listPosts = posts || [];

  return (
    <div css={styles.wrapper(maxPosts)} className={className}>
      {listPosts.length > 0 ? (
        listPosts.slice(0, maxPosts).map((post, index) => {
          const isReel = post.type === SocialPostType.INSTAGRAM_REEL;

          return (
            <div css={styles.post} key={`${influencerId}-${index}`} className="post-section">
              <MediaPreview
                isReel={isReel}
                objectFit="contain"
                src={post.thumbNail}
                caption={post.caption}
                onClick={() => (post.id ? setPostDetailsParam(post.id) : null)}
                css={{ width: size, height: size, backgroundColor: '#dee5ec' }}
                showIcon
              />
            </div>
          );
        })
      ) : (
        <p css={{ width: '100%', textAlign: 'center' }}>{t('No post data')}</p>
      )}
    </div>
  );
};
const styles = {
  wrapper: (amount: number) =>
    css({
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      justifyContent: 'flex-end',
      width: `${amount * 80 - 8}px`
    }),
  post: css({
    width: '72px',
    height: '72px',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:not(:first-of-type)': { marginLeft: '8px' }
  })
};
