import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { SocialAccountStatus, useInfluencerAnalyticsMultipleInstagramAccessTokenWarningQuery } from '@/graphql';
import { TalentInfluencerFormValues } from '../../../schemaTypes';
import { ConnectAccountType } from '../types';

interface Props {
  influencerId?: number;
  values: TalentInfluencerFormValues;
}

export const useInitialTalentSocialAccounts = ({
  influencerId,
  values: { connectedFacebookPages, connectedInstagrams, connectedTwitters, connectedYouTubes, connectedFacebook }
}: Props) => {
  const { t } = useTranslation();
  const { isTalentAgency, isInfluencer } = useUserRoles();
  const { data } = useInfluencerAnalyticsMultipleInstagramAccessTokenWarningQuery({
    skip: !influencerId || !connectedInstagrams.length,
    variables: { socialAccountIds: connectedInstagrams.map((i) => Number(i.accountId)).filter(Boolean) }
  });

  const igReconnectStatuses = data?.influencerAnalyticsMultipleInstagramAccessTokenWarning.reconnectStatuses || [];

  const igAccounts = connectedInstagrams.map<ConnectAccountType>((account) => {
    const isScrapping = account.status === SocialAccountStatus.SCRAPING;
    const hasIgNonBusinessWarning = !account.pageId && !isScrapping;
    const reconnectStatus = igReconnectStatuses.find((ig) => ig.socialAccountId === account.accountId);

    return {
      name: account.igName,
      id: account.accountId,
      status: account.status,
      canReconnect: isTalentAgency || isInfluencer,
      warning: hasIgNonBusinessWarning ? t('ReconnectTalentAgencyNonBusinessIgAccountMessage') : '',
      linkedAccounts:
        account.pageId && account.pageName ? [{ id: account.pageId, name: account.pageName, type: 'FACEBOOK' }] : [],
      reconnectData: {
        needReconnect: reconnectStatus?.needReconnect,
        socialAccountId: reconnectStatus?.socialAccountId,
        analyticsAccountId: reconnectStatus?.dataForReconnect.analyticsAccountId
      }
    };
  });

  const ytAccounts = connectedYouTubes.map<ConnectAccountType>((account) => ({
    canReconnect: true,
    id: account.channelId,
    status: account.status,
    name: account.channelName
  }));

  const twitterAccounts = connectedTwitters.map<ConnectAccountType>((account) => ({
    canReconnect: true,
    name: account.twName,
    status: account.status,
    id: account.twUserId || ''
  }));

  const fbAccounts = (() => {
    const result: ConnectAccountType[] = [];

    if (connectedFacebook?.fbName) {
      result.push({
        canReconnect: true,
        name: connectedFacebook.fbName,
        status: connectedFacebook.status,
        id: connectedFacebook.fbUserId || ''
      });
    }

    connectedFacebookPages.forEach((account) => {
      result.push({ canReconnect: true, name: account.pageName, status: account.status, id: account.pageId || '' });
    });

    return result;
  })();

  return { igAccounts, fbAccounts, ytAccounts, twitterAccounts };
};
