import { css } from '@emotion/react';
import { MarketplaceForReorder } from '@/graphql';
import { Icon, MediaPreview, SortableItem, SortableDragArea, Anchor } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { MarketplaceStatus } from '@/shared/organisms';

interface CampaignOrderCardProps {
  index?: number;
  className?: string;
  data: MarketplaceForReorder;
}
export const CampaignOrderCard = ({
  index = 0,
  className,
  data: { id, thumbnail, title, status }
}: CampaignOrderCardProps) => (
  <SortableItem id={id}>
    {({ isDragging }) => (
      <div className={className} css={[styles.campaign, isDragging && { visibility: 'hidden' }]}>
        <SortableDragArea>
          <Icon icon="drag" size={12} color="#C5D0DA" css={{ cursor: 'move' }} />
        </SortableDragArea>
        <p css={styles.orderNumber}>{index + 1}</p>
        <MediaPreview src={thumbnail} css={{ width: '85px', height: '48px' }} />
        <Anchor css={styles.title} to={'/marketplace/$id'} params={{ id: id.toString() }} label={title} lines={2} />
        <MarketplaceStatus status={status} />
      </div>
    )}
  </SortableItem>
);

const styles = {
  campaign: css({
    gap: '24px',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px 16px 16px',
    boxShadow: THEME.shadows.boxShadow,
    backgroundColor: THEME.background.colors.white
  }),
  orderNumber: css({
    width: '48px',
    display: 'flex',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    fontStyle: 'normal',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  title: css({
    flex: 1,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '120%;',
    fontstyle: 'normal'
  })
};
