import { useTranslation } from 'react-i18next';
import { useParamsSearch } from '@/shared/hooks';
import { YoutubeCMSSegmentMode } from '../types';
import { YoutubeCMSSearchSchemaType } from '../YoutubeCMSFilter';

export const useYoutubeCMSFilter = () => {
  const { t } = useTranslation();
  const paramsSearch = useParamsSearch<YoutubeCMSSearchSchemaType>();
  const { segment } = paramsSearch.filter;
  const isSegmentAssets = segment === YoutubeCMSSegmentMode.ASSETS;
  const isSegmentChannels = segment === YoutubeCMSSegmentMode.CHANNELS;
  const searchPlaceholder = t<string>(isSegmentAssets ? 'Search.YoutubeCMSAssets' : 'Search.YoutubeCMSChannels');

  return {
    ...paramsSearch,
    isSegmentAssets,
    isSegmentChannels,
    searchPlaceholder
  };
};
