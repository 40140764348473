import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserRoles } from '@/graphql';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import {
  TextField,
  RadiosField,
  CheckBoxField,
  PasswordField,
  SelectorField,
  SwitchToggleField
} from '@/shared/molecules';
import { Option } from '@/shared/types';
import { useDisabledFields, useUserFormDropdownsOptions } from './hooks';
import { UserFormKeys, UserFormValues } from './types';
import { UserPermissions } from './UserPermissions';

interface TemplateProps {
  userRoleOptions: Option<UserRoles>[];
  isUserSettingsForAdvertisers?: boolean;
}

export const Template = ({ userRoleOptions, isUserSettingsForAdvertisers }: TemplateProps) => {
  const { t } = useTranslation();
  const {
    watch,
    reset,
    setValue,
    clearErrors,
    formState: { defaultValues, isSubmitting }
  } = useFormContext<UserFormValues>();
  const values = watch();
  const {
    allAgencyOptions,
    allCountryOptions,
    allPartnerOptions,
    allAdvertiserOptions,
    allTalentAgencyOptions,
    allAvailableCountryOptions
  } = useUserFormDropdownsOptions({ countryId: values.userType === UserRoles.STAFF ? values.advertiserCountryId : '' });
  const disabledFields = useDisabledFields({
    defaultValues: defaultValues as UserFormValues,
    formValues: values,
    isUserSettingsForAdvertisers
  });

  const isEditMode = values.mode === 'edit';
  const isRoleChanged = defaultValues?.userType !== values.userType;

  const handleClickSendEmail = (_: string, value: boolean) => {
    // we need to clear password fields each time user click send email to prevent validation err on password
    reset({ ...values, password: '', passwordConfirm: '', hasInvitationEmail: value });
  };

  const handleUserTypeChange = (field: UserFormKeys, value: string) => {
    setValue(field, value);
    clearErrors(); // for better UX, every time we switch the userType it is basically a new form, even though the validation fields maybe be the same

    if (value === UserRoles.STAFF && values.advertiserIds.length > 0) {
      setValue('advertiserIds', []);
      setValue('advertiserCountryId', '');
    }
  };

  return (
    <FormStyle.Wrapper css={{ flex: 1, boxShadow: THEME.shadows.boxShadow }} hasBorder>
      <div css={{ padding: '24px' }}>
        <FormStyle.SectionTitle index={1} label={t('User Type')} />

        <RadiosField<UserFormKeys>
          name="userType"
          options={userRoleOptions}
          onChange={handleUserTypeChange}
          disabled={disabledFields.userType}
          required
        />

        <FormStyle.SectionTitle index={2} label={t('User Information')} css={{ marginTop: '24px' }} />

        {values.userType === UserRoles.AGENCY ? (
          <SelectorField<UserFormKeys>
            name="agencyId"
            title={t('Selector.Agency')}
            options={allAgencyOptions}
            help={t('Tooltip.User Agency')}
            disabled={disabledFields.agencyId}
            required
          />
        ) : null}

        {values.userType === UserRoles.STAFF ? (
          <>
            <div css={{ position: 'relative' }}>
              <SelectorField<keyof UserFormValues>
                title={t('Selector.Advertiser')}
                name="advertiserIds"
                help={t('Tooltip.User Advertiser')}
                options={allAdvertiserOptions}
                disabled={disabledFields.advertiserIds}
                multipleSelectorProps={{
                  subSelector: (
                    <SelectorField<UserFormKeys>
                      name="advertiserCountryId"
                      options={allCountryOptions}
                      disabled={disabledFields.advertiserIds}
                      placeholder={t<string>('Selector.Country')}
                      css={{ padding: 0, margin: '2px 12px 2px auto', zIndex: 12, maxWidth: '150px' }}
                      onChange={(name, value) => {
                        setValue(name, value);
                        setValue('advertiserIds', []);
                      }}
                    />
                  )
                }}
                // https://adasiaholdings.atlassian.net/browse/ATTA-836
                // https://adasiaholdings.atlassian.net/browse/ATTA-1251
                required
                multiple
              />
            </div>

            <CheckBoxField<UserFormKeys>
              name="autoLinkToAdvertiser"
              label={t('autoLinkToAdvertiser')}
              help={t('Tooltip.autoLinkToAdvertiserHelp')}
            />
          </>
        ) : null}

        {values.userType === UserRoles.ADVERTISER ? (
          <SelectorField<UserFormKeys>
            title={t('Selector.Advertiser')}
            name="advertiserId"
            options={allAdvertiserOptions}
            help={t('Tooltip.User Advertiser')}
            disabled={disabledFields.advertiserId}
            required
          />
        ) : null}

        {values.userType === UserRoles.TALENT_AGENCY ? (
          <SelectorField<UserFormKeys>
            name="talentAgencyId"
            title={t('Selector.Talent Agency')}
            options={allTalentAgencyOptions}
            help={t('Tooltip.User Talent Agency')}
            disabled={disabledFields.talentAgencyId}
            required
          />
        ) : null}

        {values.userType === UserRoles.PARTNER ? (
          <SelectorField<UserFormKeys>
            name="partnerId"
            title={t('Selector.Partner')}
            options={allPartnerOptions}
            help={t('Tooltip.User Partner')}
            disabled={disabledFields.partnerId}
            required
          />
        ) : null}

        <TextField<UserFormKeys>
          name="name"
          title={t('TextForm.Name')}
          help={t('Tooltip.Username')}
          disabled={disabledFields.name}
          placeholder={t<string>('NameExample')}
          required
        />

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <TextField<UserFormKeys>
            name="email"
            disabled={disabledFields.email}
            placeholder="advertiser@email.com"
            title={t('TextForm.Email Address')}
            help={t('Tooltip.User Email Address')}
            required
          />
          <SelectorField<UserFormKeys>
            title={t('Selector.Country')}
            name="countryId"
            help={t('Tooltip.User Country')}
            options={allAvailableCountryOptions}
            disabled={disabledFields.countryId}
            required
          />
        </FormStyle.FieldsGroup>

        {(!values.hasInvitationEmail || isEditMode) && (
          <FormStyle.FieldsGroup itemsPerRow={2}>
            <PasswordField<UserFormKeys>
              name="password"
              title={t('Password.Password')}
              help={t('Tooltip.User Password')}
              disabled={disabledFields.password}
              dynamicError
              required={!isEditMode}
            />

            <PasswordField<UserFormKeys>
              name="passwordConfirm"
              title={t('Password.Confirm Password')}
              disabled={disabledFields.passwordConfirm}
              dynamicError
              required={!isEditMode}
            />
          </FormStyle.FieldsGroup>
        )}

        {values.mode === 'add' && (
          <CheckBoxField<UserFormKeys>
            name="hasInvitationEmail"
            onChange={handleClickSendEmail}
            label={t('Send invitation email')}
            disabled={disabledFields.hasInvitationEmail}
          />
        )}

        {values.userType === UserRoles.ADVERTISER && (
          <SwitchToggleField<UserFormKeys>
            name="notificationSetting"
            title={t('TextForm.Notification Settings')}
            disabled={disabledFields.notificationSetting}
            help={t('Tooltip.Notification for User Page help')}
          />
        )}

        <UserPermissions role={values.userType} isEditMode={isEditMode} isRoleChanged={isRoleChanged} />
      </div>

      <FormStyle.ButtonSubmitWrapper>
        <FormStyle.ButtonSubmit type="submit" title={t('Button.Save')} loading={isSubmitting} />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};
