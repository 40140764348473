import { useTalentAgencyPermissions } from '@/auth';
import { namedOperations, useDeleteTalentAgencyMutation } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { TableData } from '@/shared/types';
import { AccountType, AccountsList, DeleteAccountType } from '../../Accounts';

export type TalentAgenciesListProps = TableData<AccountType>;

export const TalentAgenciesList = (props: TalentAgenciesListProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callDeleteTalentAgency } = useDeleteTalentAgencyMutation({
    refetchQueries: [namedOperations.Query.AllTalentAgencies]
  });
  const { hideAddTalentAgencyBtn, hideEditTalentAgencyBtn, hideDeleteTalentAgencyBtn } = useTalentAgencyPermissions();

  const handleDeleteAccount = async (account: DeleteAccountType) => {
    try {
      await callDeleteTalentAgency({ variables: { id: account.id } });
      enqueueSnackbar(t('succeededInDeleting', { name: account.name }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: account.name }), { variant: 'error' });
    }
  };

  return (
    <AccountsList
      {...props}
      domain="talent-agency"
      firstColumnTitle={t('HeaderColumn.Talent Agency Name')}
      editRoute={!hideEditTalentAgencyBtn ? '/talent-agency/$id' : undefined}
      onDeleteAccount={!hideDeleteTalentAgencyBtn ? handleDeleteAccount : undefined}
      empty={{
        linkProps: !hideAddTalentAgencyBtn ? { to: '/talent-agency/add', label: t('Add an Talent Agency') } : undefined
      }}
    />
  );
};
