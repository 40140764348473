import { useAllTalentAgencyUsersQuery } from '@/graphql';

import { AccountUsersList } from '../../Accounts';

export interface TalentAgencyUsersListProps {
  talentAgencyId: number;
}

export const TalentAgencyUsersList = ({ talentAgencyId }: TalentAgencyUsersListProps) => {
  const { data, loading } = useAllTalentAgencyUsersQuery({ variables: { talentAgencyId } });

  return (
    <AccountUsersList
      loading={loading}
      data={data?.allTalentAgencyUsers || []}
      refetchQueriesAfterDelete={['AllTalentAgencyUsers']}
    />
  );
};
