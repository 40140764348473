import { QueryHookOptions } from '@apollo/client';
import { Query, useAllInfluencerCategoriesQuery } from '@/graphql';
import { useTranslateOptions } from './useTranslateOptions';

export const useAllInfluencerCategoryOptions = (options?: QueryHookOptions<Query>) => {
  const { data, loading } = useAllInfluencerCategoriesQuery({ fetchPolicy: 'cache-first', ...options });

  const allInfluencerCategoryOptions = useTranslateOptions(
    data?.allInfluencerCategories?.map((item) => ({ value: String(item.id), label: item.description })) || []
  );

  return { loading, allInfluencerCategoryOptions };
};
