import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { PasswordField } from '../../RHFFields';
import { ChangePasswordFormValues, ChangePasswordFormKeys } from './types';

export interface TemplateProps {
  open?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

export const Template = ({ open, loading, onClose, onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { reset } = useFormContext<ChangePasswordFormValues>();

  const handleCloseModal = () => {
    onClose?.();
    reset();
  };

  return (
    <Modal open={open} onClose={handleCloseModal} css={{ maxWidth: '548px' }}>
      <ModalContent>
        <ModalTitle>{t('Dialog.Change Password')}</ModalTitle>

        <FormStyle.Wrapper css={{ width: '500px' }}>
          <PasswordField<ChangePasswordFormKeys>
            name="currentPassword"
            title={t('Password.Current Password')}
            placeholder={t<string>('Password.Current Password')}
            required
          />

          <PasswordField<ChangePasswordFormKeys>
            name="password"
            title={t('Password.New Password')}
            placeholder={t<string>('Password.Fill New Password')}
            dynamicError
            required
          />

          <PasswordField<ChangePasswordFormKeys>
            name="passwordConfirm"
            title={t('Password.Confirm Password')}
            placeholder={t<string>('Password.Confirm new password')}
            dynamicError
            required
          />
        </FormStyle.Wrapper>
      </ModalContent>
      <ModalFooterActions
        cancelButtonProps={{ onClick: handleCloseModal }}
        submitButtonProps={{ loading, title: t('Button.Change'), onClick: onSubmit }}
      />
    </Modal>
  );
};
