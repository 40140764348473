import { ReactNode } from 'react';

export interface TwoDayPickersInARowProps {
  className?: string;
  children: ReactNode;
}

export const TwoDayPickersInARow = (props: TwoDayPickersInARowProps) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      '> div': { width: '100%', input: { width: '100%', minWidth: '80px' } }
    }}
    {...props}
  />
);
