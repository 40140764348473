import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { YoutubeInfluencersProps } from './schemaTypes';
import { YoutubeInfluencersSort } from './YoutubeInfluencersSort';
import { YoutubeInfluencersList } from './YoutubeInfluencersList';
import { YoutubeInfluencersFilter } from './YoutubeInfluencersFilter';
import { YoutubeInfluencersProvider } from './YoutubeInfluencersContext';

export const YoutubeInfluencers = (props: YoutubeInfluencersProps) => (
  <YoutubeInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <YoutubeInfluencersFilter />
      <YoutubeInfluencersSort />
    </InfluencersFilterWrapper>

    <YoutubeInfluencersList />
  </YoutubeInfluencersProvider>
);

export * from './schemaTypes';
