import { useCreateTalentAgencyWithUserMutation } from '@/graphql';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useUpdateDocumentTitle, useQueryHelper, useCustomHeader } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { TalentAgencyFormValues, TalentAgencyForm } from '@/shared/organisms';

export const AddTalentAgency = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    title: 'appHeader.Add New Talent Agency',
    appHeader: 'appHeader.Add New Talent Agency',
    href: '/talent-agency'
  });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateTalentAgencyWithUser } = useCreateTalentAgencyWithUserMutation();

  const handleSubmit = async ({
    countryId,
    agencyIds,
    companyUrl,
    netsuiteId,
    phoneNumber,
    advertiserIds,
    talentAgencyName,
    ...restProps
  }: TalentAgencyFormValues) => {
    try {
      if (restProps.type === 'add') {
        await callCreateTalentAgencyWithUser({
          variables: {
            input: {
              name: restProps.name,
              email: restProps.email,
              countryId,
              talentAgencyName,
              hasInvitationEmail: restProps.hasInvitationEmail,
              netsuiteId: netsuiteId || null,
              companyUrl: companyUrl || null,
              password: restProps.password || undefined,
              phoneNumber: phoneNumber || null,
              agencyIds: agencyIds.map(Number),
              advertiserIds: advertiserIds.map(Number)
            }
          }
        });
      }
      enqueueSnackbar(t('succeededInCreating', { name: talentAgencyName }), { variant: 'success' });
      navigate({ to: '/talent-agency' });
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name: talentAgencyName }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <div css={{ margin: '0 auto', width: THEME.container.details.width }}>
      <Header />

      <TalentAgencyForm onSubmit={handleSubmit} defaultValues={{ type: 'add' }} />
    </div>
  );
};
