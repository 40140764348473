import {
  useYoutubeCompareAllPostsInDateQuery,
  useInfluencerYoutubeCompareAllPostsInDateQuery
} from '@/graphql';
import { Modal } from '@/shared/atoms';
import { getYoutubePostUrl, getYoutubeVideoUrl } from '@/shared/utils';
import {
  ComparePostsDetails,
  ComparePostsDetailsProps,
  ComparePostsDetailsPostType,
  ComparePostsDetailsAccountType
} from '../../PostDetails';

export interface YoutubeComparePostsDetailsModalProps {
  onClose: () => void;
  mainAccountId: number;
  isAnalytics?: boolean;
  postDate?: string | null;
  comparableAccounts: ComparePostsDetailsAccountType[];
}

export const YoutubeComparePostsDetailsModal = ({
  onClose,
  postDate,
  isAnalytics,
  mainAccountId,
  comparableAccounts
}: YoutubeComparePostsDetailsModalProps) => {
  const comparableAccountIds = comparableAccounts.reduce<number[]>(
    (acc, curr) => (curr.id !== mainAccountId ? [...acc, curr.id] : acc),
    []
  );
  const { data, loading } = useYoutubeCompareAllPostsInDateQuery({
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
    skip: !isAnalytics || !postDate || !comparableAccountIds.length,
    variables: { date: postDate as string, mainAccountId }
  });
  const { data: inflData, loading: fetchingInflData } = useInfluencerYoutubeCompareAllPostsInDateQuery({
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
    skip: isAnalytics || !postDate || !comparableAccountIds.length,
    variables: { date: postDate as string, socialAccountId: mainAccountId }
  });

  const postsData = isAnalytics ? data?.youtubeCompareAllPostsInDate : inflData?.influencerYoutubeCompareAllPostsInDate;
  const comparePostsData = postsData
    ? comparableAccounts.reduce<ComparePostsDetailsProps['data']>((acc, curr) => {
        const comparableAccountData = comparableAccounts.find((account) => account.id === curr.id);
        if (curr.id === mainAccountId) {
          acc[curr.id] = [
            ...(postsData.mainAccount.posts?.map<ComparePostsDetailsPostType>((post) => ({
              socialMedia: 'YOUTUBE',
              caption: post.videoCaption,
              postUrl: getYoutubePostUrl(post.videoId),
              video: { tags: post.videoTags, title: post.videoTitle, url: getYoutubeVideoUrl(post.videoId) },
              comments: post.videoComments.map((comment) => ({
                content: comment.content,
                username: comment.posterName
              })),
              statistics: {
                views: post.views,
                averageViewDuration: post.averageViewDuration,
                averageViewPercentage: post.averageViewPercentage,
                likes: post.likes,
                dislikes: post.dislikes,
                comments: post.comments
              },
              info: {
                ...comparableAccountData,
                socialMedia: 'YOUTUBE',
                postDate: post.postDate,
                followers: post.subscribers
              }
            })) || [])
          ];
        } else {
          const feedPosts = postsData.compareAccounts.find(({ accountId }) => accountId === curr.id)?.posts;
          acc[curr.id] = [
            ...(feedPosts?.map<ComparePostsDetailsPostType>((post) => ({
              socialMedia: 'YOUTUBE',
              caption: post.videoCaption,
              postUrl: getYoutubePostUrl(post.videoId),
              video: { tags: post.videoTags, title: post.videoTitle, url: getYoutubeVideoUrl(post.videoId) },
              comments: post.videoComments.map((comment) => ({
                content: comment.content,
                username: comment.posterName
              })),
              statistics: {
                views: post.views,
                likes: post.likes,
                dislikes: post.dislikes,
                comments: post.comments
              },
              info: {
                ...comparableAccountData,
                socialMedia: 'YOUTUBE',
                postDate: post.postDate,
                followers: post.subscribers
              }
            })) || [])
          ];
        }

        return acc;
      }, {})
    : undefined;

  return comparableAccounts.length ? (
    <Modal
      onClose={onClose}
      css={{ width: 'max-content' }}
      open={!!(postDate && !loading && !fetchingInflData && comparePostsData)}
      hasCloseIcon
    >
      {comparePostsData ? <ComparePostsDetails data={comparePostsData} accounts={comparableAccounts} /> : null}
    </Modal>
  ) : null;
};
