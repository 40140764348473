import { useFormContext } from 'react-hook-form';
import { AddHashtagModalProps } from '@/shared/molecules';
import { useQueryHelper, useSelectItem, useToggleState } from '@/shared/hooks';
import {
  namedOperations,
  InstagramUserHashtag,
  useCreateInstagramUserHashtagMutation,
  useDeleteInstagramUserHashtagMutation
} from '@/graphql';
import { HashtagsAnalyticsFilterFormValues } from '../schemaTypes';

export interface ManageHashtagsProps {
  onSubmit?: () => void;
  hashtags: readonly InstagramUserHashtag[];
  className?: string;
}

export const useManageHashtags = ({ hashtags, onSubmit }: ManageHashtagsProps) => {
  const addModalState = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { watch, setValue } = useFormContext<HashtagsAnalyticsFilterFormValues>();
  const { callCreateInstagramUserHashtag, loading: creating } = useCreateInstagramUserHashtagMutation({
    refetchQueries: [namedOperations.Query.InstagramUserHashtags]
  });
  const { callDeleteInstagramUserHashtag, loading: deleting } = useDeleteInstagramUserHashtagMutation({
    refetchQueries: [namedOperations.Query.InstagramUserHashtags]
  });
  const {
    selectedItem: deletingHashtag,
    setSelectedItem: setDeletingHashtag,
    resetSelectedItem: resetDeletingHashtag
  } = useSelectItem<InstagramUserHashtag>();

  const hashtagIdValue = watch('hashtagId');

  const checkActivatingHashtag = (hashtagId: InstagramUserHashtag['hashtagId']) => hashtagId === hashtagIdValue;

  const handleCreateHashtag: AddHashtagModalProps['onAdd'] = async (hashtag) => {
    try {
      await callCreateInstagramUserHashtag({ variables: { input: { hashtag } } });
      addModalState.close();
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name: hashtag }), { variant: 'error' });
    }
  };

  const handleDeleteHashtag = async () => {
    if (!deletingHashtag) return;

    try {
      await callDeleteInstagramUserHashtag({ variables: { input: { hashtagId: deletingHashtag.hashtagId } } });

      resetDeletingHashtag();

      if (checkActivatingHashtag(deletingHashtag.hashtagId)) {
        setValue('hashtagId', hashtags.at(0)?.hashtagId || '');
        onSubmit?.();
      }
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: deletingHashtag.hashtag }), { variant: 'error' });
    }
  };

  const handleSelectHashtag = (hashtag: InstagramUserHashtag) => {
    setValue('hashtagId', checkActivatingHashtag(hashtag.hashtagId) ? '' : hashtag.hashtagId);
    onSubmit?.();
  };

  return {
    creating,
    deleting,
    addModalState,
    deletingHashtag,
    setDeletingHashtag,
    handleDeleteHashtag,
    handleCreateHashtag,
    handleSelectHashtag,
    resetDeletingHashtag,
    checkActivatingHashtag
  };
};
