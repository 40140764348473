import { css } from '@emotion/react';
import { useYoutubeAnalyticsRelatedPostsQuery } from '@/graphql';
import {
  Modal,
  Anchor,
  ModalProps,
  TextCutter,
  ModalContent,
  MediaPreview,
  ModalFooterActions,
  RenderDataWithFallback
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { bigIntFormatter, formatDate, getYoutubeVideoUrl } from '@/shared/utils';

export interface YoutubeAnalyticsRelatedPostsModalProps extends Omit<ModalProps, 'open'> {
  tag?: string | null;
  postId?: number | null;
  channelId?: number | null;
  channelName?: string | null;
}

export const YoutubeAnalyticsRelatedPostsModal = ({
  tag,
  postId,
  onClose,
  channelId,
  channelName,
  ...modalProps
}: YoutubeAnalyticsRelatedPostsModalProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const hasData = !!(tag && postId && channelId);
  const { data, loading } = useYoutubeAnalyticsRelatedPostsQuery({
    skip: !hasData,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      tag: String(tag),
      youtubeAnalyticsPostId: postId as number,
      youtubeAnalyticsAccountId: channelId as number
    },
    onCompleted: ({ youtubeAnalyticsRelatedPosts }) => {
      if (!youtubeAnalyticsRelatedPosts.posts.length) {
        enqueueSnackbar(t('There are no related videos found'), { variant: 'info' });
        onClose?.();
      }
    },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      onClose?.();
    }
  });

  const relatedPosts = data?.youtubeAnalyticsRelatedPosts.posts || [];

  return (
    <Modal open={hasData} onClose={onClose} css={{ width: '792px' }} {...modalProps}>
      <ModalContent
        css={[{ paddingTop: '30px', overflow: 'auto', maxHeight: '600px' }, loading && { minHeight: '400px' }]}
      >
        <RenderDataWithFallback loading={loading} loadingProps={{ css: { height: 'unset', minHeight: 'inherit' } }}>
          <div css={styles.title}>
            <span css={{ fontWeight: 600 }}>#{tag}</span>
            &nbsp;{t('Related Videos')}
          </div>

          <div css={{ padding: '16px 32px' }}>
            {relatedPosts.map(
              ({ id, views, comments, postDate, videoId, videoTitle, videoCaption, videoThumbnail }) => (
                <div key={id} css={styles.post}>
                  <MediaPreview src={videoThumbnail} objectFit="cover" css={{ width: '180px', height: '104px' }} />

                  <div css={{ marginLeft: '16px' }}>
                    <Anchor target="_blank" href={getYoutubeVideoUrl(videoId)} label={videoTitle} />
                    <div css={{ marginTop: '4px', display: 'flex', alignItems: 'center' }}>
                      {channelName ? (
                        <>
                          <p>{channelName}</p>
                          <p css={styles.delimiter}>/</p>
                        </>
                      ) : null}

                      <p css={styles.value}>{bigIntFormatter(views, 0)}</p>
                      <p css={styles.label}>{t('General.Views')}</p>

                      <p css={styles.delimiter}>/</p>
                      <p css={styles.value}>{bigIntFormatter(comments, 0)}</p>
                      <p css={styles.label}>{t('General.Comments')}</p>

                      <p css={styles.delimiter}>/</p>
                      <p css={{ fontSize: '13px' }}>{formatDate(postDate)}</p>
                    </div>
                    <TextCutter lines={3} text={videoCaption} css={styles.caption} />
                  </div>
                </div>
              )
            )}
          </div>
        </RenderDataWithFallback>
      </ModalContent>
      <ModalFooterActions cancelButtonProps={{ onClick: onClose }} />
    </Modal>
  );
};
const styles = {
  title: css({
    lineHeight: 1.2,
    fontSize: '20px',
    padding: '16px 32px',
    color: THEME.text.colors.black.lv1,
    borderBottom: THEME.border.base
  }),
  post: css({
    display: 'flex',
    '&:not(:first-of-type)': { marginTop: '16px' }
  }),
  delimiter: css({
    margin: '0 6px'
  }),
  label: css({
    fontSize: '13px',
    textTransform: 'lowercase'
  }),
  value: css({
    fontWeight: 600,
    fontSize: '12px',
    marginRight: '4px'
  }),
  caption: css({
    fontSize: '12px',
    lineHeight: 1.42,
    letterSpacing: '0.31px',
    color: THEME.text.colors.gray.lv3
  })
};
