import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RechargeAdvertiserAccount.graphql';
import { Mutation, MutationrechargeAdvertiserAccountArgs } from '../../__generated__/globalTypes';

export const useRechargeAdvertiserAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationrechargeAdvertiserAccountArgs>
) => {
  const [callRechargeAdvertiserAccount, result] = useMutation<Mutation, MutationrechargeAdvertiserAccountArgs>(
    MUTATION,
    options
  );

  return { callRechargeAdvertiserAccount, ...result };
};

export type RechargeAdvertiserAccountMutationFunction = Mutation['rechargeAdvertiserAccount'];
