import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerAnalyticsTwitterAccessTokenWarning.graphql';
import { Query, QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs } from '../../__generated__/globalTypes';

export const useInfluencerAnalyticsTwitterAccessTokenWarningQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerAnalyticsTwitterAccessTokenWarningLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs>
): QueryResult<Query, QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs> & {
  getInfluencerAnalyticsTwitterAccessTokenWarning: LazyQueryExecFunction<
    Query,
    QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerAnalyticsTwitterAccessTokenWarning, result] = useLazyQuery<
    Query,
    QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerAnalyticsTwitterAccessTokenWarning, ...result };
};

export const useInfluencerAnalyticsTwitterAccessTokenWarningPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerAnalyticsTwitterAccessTokenWarning = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerAnalyticsTwitterAccessTokenWarning };
};
