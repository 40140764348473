import { Outlet } from '@tanstack/react-router';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset } from '@/shared/utils';
import { useParamsSearch, useQueryHelper } from '@/shared/hooks';
import { useTiktokSpecialCampaignPostsQuery } from '@/graphql';
import {
  TikTokCampaignPostsFilter,
  TikTokCampaignPostsList,
  TikTokCampaignPostsSearchSchemaType
} from '@/shared/organisms';
import { useTikTokCampaignDetailsContext } from '../TikTokCampaignDetailsContext';

export const TikTokCampaignDetailsPosts = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { tiktokSpecialCampaign } = useTikTokCampaignDetailsContext();
  const { page, limit, filter, setFilter } = useParamsSearch<TikTokCampaignPostsSearchSchemaType>();

  const { data, previousData, loading } = useTiktokSpecialCampaignPostsQuery({
    fetchPolicy: 'network-only',
    skip: !tiktokSpecialCampaign?.id,
    variables: {
      offset: getOffset(page, limit),
      limit,
      campaignId: Number(tiktokSpecialCampaign.id),
      keyword: filter.keyword,
      status: filter.status || undefined
    },
    onError: (err) => {
      enqueueSnackbar(t(err.message || 'Cannot get post list data'), { variant: 'error' });
    }
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.tiktokSpecialCampaignPostsCount?.totalNumber;

  return (
    <ListWithPagination loading={!filter} loadingProps={{ css: { border: 'none' } }} pagination={{ totalRecords }}>
      <TikTokCampaignPostsFilter filter={filter} setFilter={setFilter} totalRecords={totalRecords} />

      <TikTokCampaignPostsList
        loading={loading}
        tiktokSpecialCampaign={tiktokSpecialCampaign}
        posts={result?.tiktokSpecialCampaignPosts || []}
      />
      <Outlet />
    </ListWithPagination>
  );
};
