import { Trans, useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { Anchor, Icon, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { formatNumber, formatPrice } from '@/shared/utils';
import { CURRENCY_SYMBOLS, THEME } from '@/shared/constants';
import { closeIconImage } from '@/shared/assets';
import { MoneyField } from '../TransferModal/MoneyField';
import { RechargeModalFormValues, RechargeModalTemplateProps } from './types';
import { maskitoParseNumber } from './schema';
import { CardInfo } from './CardInfo';

export const Template = ({
  loading,
  onClose,
  onSubmit,
  currencyId,
  checkoutUrl,
  prepaidBalance
}: RechargeModalTemplateProps) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<RechargeModalFormValues>();

  const { step, amount } = watch();
  const currencyPrefix = CURRENCY_SYMBOLS[currencyId];
  const unmaskedAmount = amount ? maskitoParseNumber(amount) : 0;

  return (
    <>
      <ModalContent css={{ paddingBottom: '32px', '& > *': { lineHeight: '100%' } }}>
        <ModalTitle css={{ marginBottom: '24px' }}>{t('Recharge')}</ModalTitle>
        <div>
          {step === 'confirm' ? (
            <>
              <p css={{ fontSize: '14px', fontWeight: 400 }}>
                <Trans
                  i18nKey="Are you sure to recharge"
                  values={{ amount: `${amount} ${currencyId}` }}
                  components={{
                    b: <b css={{ fontWeight: 600, fontSize: '14px', lineHeight: '24px' }} />
                  }}
                />
                <br />
                <span css={{ lineHeight: '24px' }}>{t('This process cannot be undone')}</span>
              </p>
              {checkoutUrl ? (
                <>
                  <div css={styles.errorNotice}>
                    <img src={closeIconImage} css={{ width: '16px', height: '16px' }} alt="red close icon" />
                    <span css={{ marginLeft: '8px', fontSize: '12px', fontWeight: 400 }}>
                      {t('The payment has failed Please check your credit card information')}
                    </span>
                  </div>
                  <Anchor href={checkoutUrl} target="_self" variant="blue" css={{ fontSize: '14px', fontWeight: 400 }}>
                    <div css={{ display: 'inline-flex', gap: '4px' }}>
                      <Icon
                        icon="external-link"
                        size={14}
                        color={THEME.text.colors.blue}
                        css={{ alignSelf: 'center' }}
                      />
                      <span>{t('Recheck your Card information')}</span>
                    </div>
                  </Anchor>
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              <MoneyField
                name="amount"
                placeholder={formatPrice(10000, null, 0)}
                required
                triggerFormRevalidation
                prefix={currencyId ? CURRENCY_SYMBOLS[currencyId] : ''}
              />
              <p css={styles.prepaidBalance}>
                <div css={{ fontWeight: 600 }}>{t('New Prepaid Balance')}</div>
                <span css={{ fontWeight: 600, color: THEME.text.colors.black.lv1 }}>
                  {prepaidBalance !== undefined && prepaidBalance !== null
                    ? `${currencyPrefix}${formatNumber(prepaidBalance + unmaskedAmount, 0)}`
                    : '-'}
                </span>
              </p>

              <CardInfo css={{ boxShadow: 'none', padding: 0 }} />
            </>
          )}
        </div>
      </ModalContent>
      <ModalFooterActions
        css={{
          backgroundColor: THEME.background.colors.white,
          borderTop: THEME.border.base,
          '> button': { padding: '0 24px' }
        }}
        cancelButtonProps={
          step === 'input'
            ? {
                onClick: onClose,
                title: t('Button.Cancel')
              }
            : { onClick: () => setValue('step', 'input'), title: t('Button.Back'), css: css({ minWidth: '90px' }) }
        }
        submitButtonProps={
          step === 'input'
            ? {
                onClick: () => setValue('step', 'confirm'),
                title: t('Button.Recharge'),
                disabled: !amount || Number(amount) < 10
              }
            : {
                onClick: onSubmit,
                title: t('Sure'),
                loading: {
                  status: loading,
                  showIcon: true
                },
                css: css({ minWidth: '105px' })
              }
        }
      />
    </>
  );
};

const styles = {
  prepaidBalance: css({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: 400,
    color: THEME.text.colors.gray.lv3,
    marginTop: '4px',
    marginBottom: '8px'
  }),
  errorNotice: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3px',
    padding: '8px 0 8px 8px',
    border: `1px solid ${THEME.colors.red[20]}`,
    background: THEME.colors.red[10],
    fontSize: '12px',
    margin: '10px 0'
  })
};
