import { LinkProps } from '@tanstack/react-router';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useSelectItem } from '@/shared/hooks';
import { PricingTableItem, useCampaignCheckoutSessionLinkPromiseQuery } from '@/graphql';

const goBackUrl = '/tiktok/campaigns/add' as const satisfies LinkProps['to'];

export const useSelectCampaignPlan = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { selectedItem, setSelectedItem } = useSelectItem<PricingTableItem['id']>();
  const { getCampaignCheckoutSessionLink } = useCampaignCheckoutSessionLinkPromiseQuery();

  const handleSelectPlan = async (priceId: number | null, packageId: number | null) => {
    if (!priceId || !packageId) return;

    try {
      setSelectedItem(priceId);
      const { data } = await getCampaignCheckoutSessionLink({
        variables: {
          priceId,
          packageId,
          redirectUrl: `${window.location.origin}${goBackUrl}?priceId=${priceId}&packageId=${packageId}&sessionId={CHECKOUT_SESSION_ID}`
        }
      });
      const planUrl = data.campaignCheckoutSessionLink?.url;

      if (planUrl) {
        window.location.href = planUrl;
      }
    } catch (error) {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    } finally {
      setSelectedItem(null);
    }
  };

  return {
    handleSelectPlan,
    selectedPlanId: selectedItem
  };
};
