import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/EngagementCampaignCouponTracking.graphql';
import { Mutation, MutationengagementCampaignCouponTrackingArgs } from '../../__generated__/globalTypes';

export const useEngagementCampaignCouponTrackingMutation = (
  options?: MutationHookOptions<Mutation, MutationengagementCampaignCouponTrackingArgs>
) => {
  const [callEngagementCampaignCouponTracking, result] = useMutation<
    Mutation,
    MutationengagementCampaignCouponTrackingArgs
  >(MUTATION, options);

  return { callEngagementCampaignCouponTracking, ...result };
};

export type EngagementCampaignCouponTrackingMutationFunction = Mutation['engagementCampaignCouponTracking'];
