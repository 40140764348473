import { TwitterInfluencerProfile, TwitterInfluencers } from '@/shared/organisms';
import { useDefaultInfluencerSearchProps, useSocialInfluencersSelectButtonsProps } from './hooks';

export const EngagementTwitterInfluencersSearch = () => {
  const { isEngagementPage, isTtcmCampaign } = useDefaultInfluencerSearchProps();
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialInfluencersSelectButtonsProps<TwitterInfluencerProfile>({ socialMedia: 'TWITTER' });

  return (
    <TwitterInfluencers
      isTtcmCampaign={isTtcmCampaign}
      isEngagementPage={isEngagementPage}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
