import { useState } from 'react';
import { useUserRoles } from '@/auth';
import { useQueryHelper } from '@/shared/hooks';
import { useAnalyticsFilter } from '@/shared/organisms';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { SHOW_ON_BOARDING_DUE_TO_NO_DATA } from '@/shared/constants';
import { useAllAccountAnalyticsForAdminV2Query, useAllAccountAnalyticsForAdvertiserV2Query } from '@/graphql';

export const useInitialAnalytics = () => {
  const { isAdvertiser } = useUserRoles();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { page, limit, filter } = useAnalyticsFilter();
  const [isOnboarding, setIsOnboarding] = useState(false);
  const { data: adminData, loading: fetchingAdminData } = useAllAccountAnalyticsForAdminV2Query({
    skip: isAdvertiser,
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      ...filter,
      subscriptionPlan: filter.subscriptionPlan || null,
      advertiserId: filter.advertiserId ? Number(filter.advertiserId) : null
    })
  });
  const { data: advertiserData, loading: fetchingAdvertiserData } = useAllAccountAnalyticsForAdvertiserV2Query({
    skip: !isAdvertiser,
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({ keywork: filter.keyword, subscriptionPlan: filter.subscriptionPlan || null }),
    onCompleted: () => {
      setIsOnboarding(false);
    },
    onError: (err) => {
      if (err.message === SHOW_ON_BOARDING_DUE_TO_NO_DATA) {
        setIsOnboarding(true);
      } else {
        enqueueSnackbar(t(err.message), { variant: 'error' });
        setIsOnboarding(false);
      }
    }
  });

  const analyticsAccountsAdmin = adminData?.allAccountAnalyticsForAdminV2?.accounts || [];
  const analyticsAccountsAdvertiser = advertiserData?.allAccountAnalyticsForAdvertiserV2?.accounts || [];

  return {
    isOnboarding,
    loading: isAdvertiser ? fetchingAdvertiserData : fetchingAdminData,
    accounts: isAdvertiser ? analyticsAccountsAdvertiser : analyticsAccountsAdmin,
    totalRecords: adminData?.allAccountAnalyticsCountForAdminV2.totalNumber
  };
};
