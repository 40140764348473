import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/GenerateMaterialSignedUrl.graphql';
import { Query, QuerygenerateMaterialSignedUrlArgs } from '../../__generated__/globalTypes';

export const useGenerateMaterialSignedUrlQuery = (
  options?: QueryHookOptions<Query, QuerygenerateMaterialSignedUrlArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerygenerateMaterialSignedUrlArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useGenerateMaterialSignedUrlLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerygenerateMaterialSignedUrlArgs>
): QueryResult<Query, QuerygenerateMaterialSignedUrlArgs> & {
  getGenerateMaterialSignedUrl: LazyQueryExecFunction<Query, QuerygenerateMaterialSignedUrlArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getGenerateMaterialSignedUrl, result] = useLazyQuery<Query, QuerygenerateMaterialSignedUrlArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getGenerateMaterialSignedUrl, ...result };
};

export const useGenerateMaterialSignedUrlPromiseQuery = () => {
  const client = useApolloClient();

  const getGenerateMaterialSignedUrl = useCallback(
    (options?: Omit<QueryOptions<QuerygenerateMaterialSignedUrlArgs, Query>, 'query'>) =>
      client.query<Query, QuerygenerateMaterialSignedUrlArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getGenerateMaterialSignedUrl };
};
