import { FormatOptionLabelMeta, StylesConfig } from 'react-select';
import { CSSObject } from '@emotion/react';
import { Order } from '@/graphql';
import { Option } from '@/shared/types';
import { THEME } from '@/shared/constants';
import { Radio, TextCutter } from '@/shared/atoms';
import { MenuItemsProps } from './MenuItems';

export const getInitialSelectedItem = (
  options: Option[],
  value: string | undefined,
  order?: Order | null
): Option | undefined => options.find((option) => option.value === value && option?.order === order);

export const separator = (Math.random() + 1).toString(36).substring(5).toString();
export const setOrderOption = (value?: string, order?: Order | null) =>
  order !== undefined ? `${value}${separator}${order}` : value;

export interface DefaultSingleSelectStylesProps {
  height?: string;
  hasError?: boolean;
  disabled?: boolean;
  menuStyles?: CSSObject;
  styles?: StylesConfig<any, boolean, any>;
}
export const getDefaultSingleSelectStyles = ({
  height = '32px',
  hasError,
  disabled,
  menuStyles,
  styles = {}
}: DefaultSingleSelectStylesProps): StylesConfig<any> => ({
  ...styles,
  menu: (baseCss, optProps) => ({
    ...baseCss,
    marginTop: '4px',
    zIndex: 10,
    ...menuStyles,
    ...styles?.menu?.({}, optProps) // Don't pass 'baseCss' into menu, we already done above, same with below
  }),
  option: (baseCss, optProps) => ({
    ...baseCss,
    height,
    backgroundColor: optProps.isSelected ? ' rgba(23, 156, 215, 0.15)' : baseCss.backgroundColor,
    color: optProps.isSelected ? THEME.input.text.colors.default : baseCss.color,
    ...styles?.option?.({}, optProps)
  }),
  container: (baseCss, optProps) => ({ ...baseCss, width: '100%', ...styles?.container?.({}, optProps) }),
  control: (baseCss, optProps) => ({
    ...baseCss,
    cursor: 'pointer',
    minHeight: height,
    height,
    padding: '0 0 0 4px',
    borderRadius: '3px',
    borderColor: hasError
      ? THEME.input.border.colors.error
      : optProps.menuIsOpen
        ? THEME.input.border.colors.focus
        : THEME.input.border.colors.default,
    ':hover': {
      borderColor: hasError
        ? THEME.input.border.colors.error
        : optProps.menuIsOpen
          ? THEME.input.border.colors.focus
          : THEME.input.border.colors.hover
    },
    boxShadow: 'none',
    background: disabled ? THEME.input.background.colors.disabled : baseCss.background,
    ...styles?.control?.({}, optProps)
  }),
  singleValue: (baseCss, optProps) => ({
    ...baseCss,
    color: THEME.input.text.colors.default,
    ...styles?.singleValue?.({}, optProps)
  }),
  valueContainer: (baseCss, optProps) => ({
    ...baseCss,
    padding: '0 4px',
    ...styles?.valueContainer?.({}, optProps)
  }),
  placeholder: (baseCss, optProps) => ({
    ...baseCss,
    fontWeight: 400,
    fontSize: '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: THEME.input.text.colors.placeholder,
    ...styles?.placeholder?.({}, optProps)
  })
});

interface FormatOptionLabelProps {
  optProps: Option;
  formatOptionLabelMeta: FormatOptionLabelMeta<Option>;
  type?: MenuItemsProps['type'];
}
export const formatOptionLabel = ({ type, optProps, formatOptionLabelMeta }: FormatOptionLabelProps) => {
  const isActive = optProps.value === formatOptionLabelMeta.selectValue?.[0]?.value;

  if (type === 'radio' && formatOptionLabelMeta?.context === 'menu') {
    return <Radio checked={isActive} label={optProps.label} css={{ height: '100%' }} />;
  } else {
    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          placeItems: 'center',
          maxWidth: '100%'
        }}
      >
        {optProps.prefixIcon}
        <TextCutter css={{ whiteSpace: 'normal' }} text={optProps.label} lines={1} />
      </div>
    );
  }
};
