import { PostDetailsType } from '@/shared/types';
import { InstagramInteractionMentionedPostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface InstagramInteractionMentionedPostDetailsModalProps {
  posts: PostDetailsType[];
}

export const InstagramInteractionMentionedPostDetailsModal = ({
  posts
}: InstagramInteractionMentionedPostDetailsModalProps) => (
  <CarouselTemplateModal posts={posts}>
    {({ selectedPost, closeModal }) => (
      <InstagramInteractionMentionedPostDetails
        id={selectedPost.id}
        onCloseModal={closeModal}
        mainSocialAccountId={selectedPost.mainSocialAccountId}
      />
    )}
  </CarouselTemplateModal>
);
