import { useInstagramInteractionMentionedPostDetailsQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InstagramInteractionMentionedPostDetailsProps
  extends Pick<PostDetailsType, 'id' | 'mainSocialAccountId'> {
  className?: string;
  onCloseModal?: () => void;
}

export const InstagramInteractionMentionedPostDetails = ({
  id,
  className,
  onCloseModal,
  mainSocialAccountId
}: InstagramInteractionMentionedPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useInstagramInteractionMentionedPostDetailsQuery({
    skip: !id || !mainSocialAccountId,
    variables: { postId: Number(id), accountId: Number(mainSocialAccountId) },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      onCloseModal?.();
    }
  });

  const details = data?.instagramInteractionMentionedPostDetails;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'INSTAGRAM',
        postUrl: details?.postUrl,
        caption: details?.content,
        comments: details.commentList || [],
        images: details?.postImageUrl ? [details.postImageUrl] : [],
        statistics: { likes: details?.likes, comments: details?.comments },
        info: {
          followers: null,
          socialMedia: 'INSTAGRAM',
          avatar: details?.avatarUrl,
          postDate: details?.postDate,
          name: details?.instagramAccountName
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      loading={loading}
      className={className}
      postDetails={postDetails}
      css={{ width: THEME.modal.size.lv3 }}
    />
  );
};
