import { AllInfluencers } from '@/shared/organisms';
import { useDefaultInfluencerSearchProps, useAllInfluencersSelectButtonsProps } from './hooks';

export const EngagementAllInfluencersSearch = () => {
  const { isTtcmCampaign, isEngagementPage } = useDefaultInfluencerSearchProps();
  const { getSortSelectButtonProps, getListSelectButtonProps } = useAllInfluencersSelectButtonsProps();

  return (
    <AllInfluencers
      isTtcmCampaign={isTtcmCampaign}
      isEngagementPage={isEngagementPage}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
