import { useTranslation } from 'react-i18next';
import { InstagramBrandAmbassadorPost } from '@/graphql';
import { MediaPreviewWithMetrics } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { ImageSlider, InstagramAmbassadorPostDetailsModal } from '@/shared/molecules';
import { bigIntFormatter, checkIsReelPost } from '@/shared/utils';

interface AmbassadorPostsSliderProps {
  analyticsAccountId: number;
  posts: readonly InstagramBrandAmbassadorPost[];
}
export const AmbassadorPostsSlider = ({ analyticsAccountId, posts }: AmbassadorPostsSliderProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  return (
    <>
      <ImageSlider
        total={posts.length}
        css={{ paddingLeft: '16px', maxWidth: 'calc(100% - 212px)', borderLeft: '1px solid #eef3f7' }}
      >
        {posts.map(({ postId, postUrl, thumbNail, likes, comments }) => {
          const isReel = checkIsReelPost(postUrl);

          return (
            <MediaPreviewWithMetrics
              key={postId}
              size="144px"
              src={thumbNail}
              isReel={isReel}
              showIcon={isReel}
              objectFit="contain"
              onClick={() => setPostDetailsParam(postId)}
              statistics={[
                { label: t('Like'), value: bigIntFormatter(likes, 0, { k: true, m: true }) },
                { label: t('Comment'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
              ]}
            />
          );
        })}
      </ImageSlider>

      <InstagramAmbassadorPostDetailsModal
        posts={posts.map(({ postId }) => ({ id: postId, socialAccountId: analyticsAccountId }))}
      />
    </>
  );
};
