import { usePermissions } from './usePermissions';

const defaultPermissions = {
  hideAddUserBtn: false,
  hideEditUserBtn: false,
  hideDeleteUserBtn: false,
  hideUserTab: false
};
export const useUserPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.user?.noView || permissions?.user === null) {
    return {
      hideAddUserBtn: true,
      hideEditUserBtn: true,
      hideDeleteUserBtn: true,
      hideUserTab: true
    };
  } else if (permissions?.user?.view) {
    return {
      hideAddUserBtn: true,
      hideEditUserBtn: true,
      hideDeleteUserBtn: true,
      hideUserTab: false
    };
  } else if (permissions?.user?.manage) {
    return { ...defaultPermissions, hideDeleteUserBtn: true };
  }

  return defaultPermissions;
};
