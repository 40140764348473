import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerAnalyticsInstagramAccessTokenWarning.graphql';
import { Query, QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs } from '../../__generated__/globalTypes';

export const useInfluencerAnalyticsInstagramAccessTokenWarningQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerAnalyticsInstagramAccessTokenWarningLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs>
): QueryResult<Query, QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs> & {
  getInfluencerAnalyticsInstagramAccessTokenWarning: LazyQueryExecFunction<
    Query,
    QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerAnalyticsInstagramAccessTokenWarning, result] = useLazyQuery<
    Query,
    QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerAnalyticsInstagramAccessTokenWarning, ...result };
};

export const useInfluencerAnalyticsInstagramAccessTokenWarningPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerAnalyticsInstagramAccessTokenWarning = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerAnalyticsInstagramAccessTokenWarning };
};
