import update from 'immutability-helper';
import { useEffect, useState } from 'react';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { AIModelName, useAiChatModelLazyQuery } from '@/graphql';
import { ChatBoxMessageType, ChatBoxErrorsProps } from '@/shared/atoms';
import { useManageChatAiThread } from './useManageChatAiThread';

const TYPING_MESSAGE: ChatBoxMessageType = { position: 'left', typing: true };

export const useManageChatBoxStates = () => {
  const chatboxState = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, getAiChatModelQuery } = useAiChatModelLazyQuery();
  const [messages, setMessages] = useState<ChatBoxMessageType[]>([TYPING_MESSAGE]);
  const [chatBoxError, setChatBoxError] = useState<(ChatBoxErrorsProps & { runId?: string }) | null>(null);

  const aiChatModel = data?.aiChatModel.modelName || AIModelName.OPENAI;
  const isOpenAiModel = aiChatModel === AIModelName.OPENAI;

  const { threadId, createChatAiThread, deleteChatAiThread } = useManageChatAiThread({
    isOpenAiModel,
    onCreateSuccess: () => {
      updateMessages([{ position: 'left', text: t<string>('AIChatBoxGetStarted'), copyable: true }]);
    },
    onCreateError: (message) => {
      setChatBoxError({ message });
    }
  });

  useEffect(
    () => () => {
      deleteChatAiThread();
    },
    [threadId]
  );

  const removeLastTypingMessage = () => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages.at(-1);

      return lastMessage?.typing ? update(prevMessages, { $splice: [[-1, 1]] }) : prevMessages;
    });
  };

  const clearCharBoxError = () => {
    setChatBoxError(null);
    removeLastTypingMessage();
  };

  const updateMessages = (newMessages: ChatBoxMessageType[]) => {
    setMessages((prevMessages) => {
      const prevMessage = prevMessages.at(-1);

      return prevMessage?.typing
        ? update(prevMessages, { $splice: [[-1, 1]], $push: newMessages }) // Remove typing message
        : update(prevMessages, { $push: newMessages });
    });
  };

  const clearMessages = (fromIndex: number) => {
    if (fromIndex <= 0) {
      setMessages([]);
    } else {
      setMessages((prevMessages) => update(prevMessages, { $splice: [[fromIndex]] }));
    }
  };

  const openChatBox = async () => {
    chatboxState.open();

    try {
      const { data } = await getAiChatModelQuery();

      // We only call once time after user visits the page and open the chat box
      if (!threadId) {
        await createChatAiThread(data?.aiChatModel.modelName);
      }
    } catch (error) {
      enqueueSnackbar('Cannot get open AI model', { variant: 'error' });
    }
  };

  const closeChatBox = async () => {
    chatboxState.close();
    setMessages([TYPING_MESSAGE]);
    setChatBoxError(null);

    await deleteChatAiThread();
  };

  const refreshChatBox = async () => {
    setMessages([TYPING_MESSAGE]);
    setChatBoxError(null);

    await deleteChatAiThread();
    await createChatAiThread();
  };

  return {
    threadId,
    messages,
    chatBoxError,
    isOpenAiModel,
    isOpening: chatboxState.status,
    openChatBox,
    closeChatBox,
    clearMessages,
    updateMessages,
    refreshChatBox,
    setChatBoxError,
    clearCharBoxError,
    removeLastTypingMessage
  };
};
