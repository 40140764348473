import {
  AnalyticsPostsOverview,
  AnalyticsPostsOverviewProps,
  NoteAggregateLastThirtyDays
} from '@/shared/organisms';

export const InfluencerAnalyticsPostsOverview = <T,>({
  headerChildren,
  ...restProps
}: AnalyticsPostsOverviewProps<T>) => (
  <AnalyticsPostsOverview css={{ marginTop: '16px' }} headerChildren={<NoteAggregateLastThirtyDays />} {...restProps} />
);
