import { Trans } from 'react-i18next';
import { css } from '@emotion/react';
import { useUserRoles } from '@/auth';
import { useQueryHelper } from '@/shared/hooks';
import { tiktokCreateFailedImage } from '@/shared/assets';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { Button, Icon, MediaPreview, Modal, ModalContent } from '@/shared/atoms';
import { getTikTokCampaignFormInput, defaultTikTokCampaignFormValues } from '@/shared/organisms';
import { usePricingTableQuery, PaymentPlanType, useCreateTiktokSpecialCampaignMutation } from '@/graphql';

interface CreateCampaignFailedModalProps {
  onRefetchCampaignsList: () => void;
}

export const CreateCampaignFailedModal = ({ onRefetchCampaignsList }: CreateCampaignFailedModalProps) => {
  const { isAdvertiserFromTikTok } = useUserRoles();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { called, error, callCreateTiktokSpecialCampaign } = useCreateTiktokSpecialCampaignMutation();
  const { data, loading, refetch } = usePricingTableQuery({
    fetchPolicy: 'no-cache', // Must no cache to get the newest plans
    skip: !isAdvertiserFromTikTok,
    variables: { planType: PaymentPlanType.MARKETPLACE }
  });

  // This modal will be shown if user purchased a plan but failed to create a campaign
  // So user must complete the campaign creation step before do other actions
  const plans = data?.pricingTable?.plans || [];
  const hasCurrentPlan = !!plans.length && data?.pricingTable?.plans.findIndex((plan) => plan.isCurrentPlan) !== -1;
  const isCreating = called && !error && hasCurrentPlan; // loading combined both create campaign and refetch pricing table

  const handleRecreateCampaign = async () => {
    const draftCampaignTitle = 'New Campaign'; // hardcode

    try {
      const { data } = await callCreateTiktokSpecialCampaign({
        variables: {
          input: getTikTokCampaignFormInput({
            ...defaultTikTokCampaignFormValues,
            isDraft: true,
            title: draftCampaignTitle
          })
        }
      });
      const createdCampaignId = data?.createTiktokSpecialCampaign?.id;

      if (createdCampaignId) {
        navigate({ to: '/tiktok/campaigns/$id', params: { id: createdCampaignId.toString() } });
      } else {
        refetch(); // close modal
        onRefetchCampaignsList();
      }
      enqueueSnackbar(t('succeededInCreating', { name: draftCampaignTitle }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <Modal
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      css={{ width: THEME.modal.size.lv1 }}
      style={{ overlay: { left: THEME.container.menu.width } }}
      open={isAdvertiserFromTikTok && !loading && hasCurrentPlan}
    >
      <ModalContent css={{ padding: 0 }}>
        <div css={{ padding: '20px 32px 24px', boxSizing: 'border-box' }}>
          <MediaPreview css={styles.mediaPreview} src={tiktokCreateFailedImage} />
          <div css={styles.iconWrapper}>
            <Icon icon="link-broken" size={8} color={THEME.icon.colors.white} />
          </div>
          <p css={[styles.text, { fontSize: '16px', fontWeight: 600 }]}>{t('Modal.Campaign Create Failed')}</p>
          <p css={[styles.text, { fontSize: '12px', color: THEME.text.colors.gray.lv3 }]}>
            <Trans i18nKey="Modal.campaignCreateFailedDecription" components={{ br: <br /> }} />
          </p>
        </div>

        <div css={styles.footer}>
          <Button
            variant="blue"
            css={{ padding: '0 24px' }}
            onClick={handleRecreateCampaign}
            loading={{ showIcon: true, status: isCreating }}
            title={t(isCreating ? 'Button.Recreating' : 'Button.Recreate')}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};
const styles = {
  text: css({
    marginTop: '8px',
    textAlign: 'center',
    lineHeight: '100%'
  }),
  footer: css({
    gap: '8px',
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    borderTop: THEME.border.base
  }),
  mediaPreview: css({
    width: '386px',
    margin: '0 auto',
    height: '174.6px',
    borderRadius: '8px',
    alignContent: 'center',
    background: THEME.background.colors.gray.lv1
  }),
  iconWrapper: css({
    width: '16px',
    height: '16px',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    margin: '8px auto 0',
    justifyContent: 'center',
    backgroundColor: THEME.background.colors.black
  })
};
