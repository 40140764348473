import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthData } from '@/auth';
import { InfluencerRateCardPayload, useGetInfluencerRateCardQuery } from '@/graphql';
import { FormStyle, Label } from '@/shared/atoms';
import { useDeepCompareEffect } from '@/shared/hooks';
import { defaultInfluencerFormValues } from '../../InfluencerForm';
import { SocialMediaInputField } from '../../shared';
import { PostsPriceFormValues } from './types';

interface PostPricesProps {
  title?: ReactNode;
  className?: string;
  hasBorder?: boolean;
  influencerId?: number;
  isProfilePage?: boolean;
}

export const PostsPrice = ({ title, className, hasBorder, influencerId, isProfilePage }: PostPricesProps) => {
  const { t } = useTranslation();
  const { auth } = useAuthData();
  const {
    setValue,
    formState: { defaultValues }
  } = useFormContext<PostsPriceFormValues>();

  const setRateCardsValue = (payload?: InfluencerRateCardPayload | null) => {
    const { rateCards } = defaultInfluencerFormValues;
    setValue('rateCards', {
      tiktok: String(payload?.tiktok?.price ?? rateCards.tiktok),
      twitter: String(payload?.twitter?.price ?? rateCards.twitter),
      youtube: String(payload?.youtube?.price ?? rateCards.youtube),
      threads: String(payload?.threads?.price ?? rateCards.threads),
      facebook: String(payload?.facebook?.price ?? rateCards.facebook),
      instagram: String(payload?.instagram?.price ?? rateCards.instagram),
      instagramStory: String(payload?.instagramStory?.price ?? rateCards.instagramStory)
    });
  };

  const { data } = useGetInfluencerRateCardQuery({
    skip: !influencerId,
    variables: { input: { influencerId: Number(influencerId) } },
    fetchPolicy: isProfilePage ? 'cache-first' : 'cache-and-network',
    onCompleted: ({ getInfluencerRateCard }) => {
      if (getInfluencerRateCard) {
        setRateCardsValue(getInfluencerRateCard);
      }
    }
  });

  useDeepCompareEffect(() => {
    // When parent form reset to update new values, we need to sync current value into parent form as well
    setRateCardsValue(data?.getInfluencerRateCard);
  }, [defaultValues]);

  const priceCurrencyValue = influencerId ? data?.getInfluencerRateCard?.currencyId : auth.currencyId;

  return (
    <FormStyle.Wrapper
      className={className}
      hasBorder={hasBorder}
      gapRow={isProfilePage ? 8 : undefined}
      gapColumn={isProfilePage ? 8 : undefined}
      css={{ padding: isProfilePage ? 0 : '24px' }}
    >
      <Label title={title || t('post price')} css={{ fontWeight: '600' }} />

      <FormStyle.FieldsGroup itemsPerRow={2}>
        <SocialMediaInputField
          type="number"
          placeholder="0"
          title="Facebook"
          socialMedia="FACEBOOK"
          name="rateCards.facebook"
          unit={priceCurrencyValue}
        />

        <SocialMediaInputField
          type="number"
          placeholder="0"
          title="Instagram"
          socialMedia="INSTAGRAM"
          unit={priceCurrencyValue}
          name="rateCards.instagram"
        />

        <SocialMediaInputField
          type="number"
          title="X(Twitter)"
          placeholder="0"
          socialMedia="TWITTER"
          name="rateCards.twitter"
          unit={priceCurrencyValue}
        />

        <SocialMediaInputField
          type="number"
          placeholder="0"
          title="IG Story"
          unit={priceCurrencyValue}
          socialMedia="INSTAGRAM_STORY"
          name="rateCards.instagramStory"
        />

        <SocialMediaInputField
          type="number"
          title="YouTube"
          placeholder="0"
          socialMedia="YOUTUBE"
          name="rateCards.youtube"
          unit={priceCurrencyValue}
        />

        <SocialMediaInputField
          type="number"
          title="TikTok"
          placeholder="0"
          socialMedia="TIKTOK"
          name="rateCards.tiktok"
          unit={priceCurrencyValue}
        />

        <SocialMediaInputField
          type="number"
          title="Threads"
          placeholder="0"
          socialMedia="THREADS"
          name="rateCards.threads"
          unit={priceCurrencyValue}
        />
      </FormStyle.FieldsGroup>
    </FormStyle.Wrapper>
  );
};
