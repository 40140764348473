import { css } from '@emotion/react';
import { UGCPostStatus } from '@/graphql';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';

type StatusType = UGCPostStatus | ''; // '': All option

export interface UGCPostStatusFilterProps {
  activeStatus?: StatusType;
  options: Option<StatusType>[];
  onChange?: (status: StatusType) => void;
}

export const UGCPostStatusFilter = ({ options, activeStatus, onChange }: UGCPostStatusFilterProps) => (
  <FormStyle.FieldWrapper css={{ display: 'flex', flex: 1 }}>
    {options.map(({ label, value }, index) => (
      <div css={styles.status(activeStatus === value)} key={index} onClick={() => onChange?.(value)}>
        {label}
      </div>
    ))}
  </FormStyle.FieldWrapper>
);
const styles = {
  status: (active?: boolean) =>
    css({
      height: '32px',
      fontSize: '12px',
      padding: '0 24px',
      lineHeight: '32px',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      fontWeight: active ? 600 : 'normal',
      color: active ? '#3892e5' : '#6e7c89',
      cursor: active ? 'default' : 'pointer',
      backgroundColor: active ? '#ebf4fc' : '#fff',
      border: `1px solid ${active ? THEME.border.colors.blue : THEME.border.colors.gray.lv2}`,
      transition: 'background-color 0.3s ease, border-color 0.3s ease',
      ...(active ? { position: 'relative', zIndex: 1 } : {}),
      '&:not(:first-of-type)': { marginLeft: '-1px' },
      '&:first-of-type': { borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px' },
      '&:last-of-type': { borderTopRightRadius: '3px', borderBottomRightRadius: '3px' }
    })
};
