import { useTranslation } from 'react-i18next';
import { useAnalyticsPermissions } from '@/auth';
import { Header, ListWithPagination } from '@/shared/molecules';
import { ButtonLink } from '@/shared/atoms';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { AnalyticsFilter, AnalyticsList, AnalyticsOnboarding } from '@/shared/organisms';
import { useInitialAnalytics } from './hooks';

export const Analytics = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'documentTitle.Analytics', appHeader: 'appHeader.Analytics' });
  const { t } = useTranslation();
  const { hideAddAnalyticsBtn } = useAnalyticsPermissions();
  const { loading, accounts, isOnboarding, totalRecords } = useInitialAnalytics();

  return (
    <>
      {!isOnboarding ? (
        <Header>
          {!hideAddAnalyticsBtn ? (
            <ButtonLink
              variant="blue"
              to="/analytics/add"
              title={t('Button.add account')}
              prefixIcon={{ icon: 'plus', size: '10px' }}
              css={{ minWidth: '156px', textTransform: 'capitalize', marginLeft: 'auto' }}
            />
          ) : null}
        </Header>
      ) : null}

      <ListWithPagination pagination={{ totalRecords }} hasNoData={!!isOnboarding} noDataNode={<AnalyticsOnboarding />}>
        <AnalyticsFilter />
        <AnalyticsList data={accounts} loading={loading} totalRecords={totalRecords} />
      </ListWithPagination>
    </>
  );
};
