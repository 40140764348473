import { z } from 'zod';
import { SearchFilterDefaults } from '@/shared/validations';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';

export const AnalyticsYoutubeShortsFilterSchema = z.object({
  keyword: z.string().optional(),
  brandAccountName: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional()
});
export const AnalyticsYoutubeShortsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(AnalyticsYoutubeShortsFilterSchema, {}),
  sort: SearchFilterDefaults.sort(YoutubeAnalyticsPostsSortOrder).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsYoutubeShortsSearchSchemaType = z.infer<typeof AnalyticsYoutubeShortsSearchSchema>;
export type AnalyticsYoutubeShortsFilterSchemaType = z.infer<typeof AnalyticsYoutubeShortsFilterSchema>;
