import { isEqual } from 'lodash';
import { EffectCallback, DependencyList, useEffect, useRef } from 'react';

// A trick from useDeepCompareEffect(react-use library) to put object into dependencies
// useEffect's dependencies will receive the same memory address if nothing changes
export const useDeepCompareEffect = <TDeps extends DependencyList>(effect: EffectCallback, deps: TDeps) => {
  const ref = useRef<TDeps | undefined>(undefined);

  if (!ref.current || !isEqual(deps, ref.current)) {
    ref.current = deps;
  }

  useEffect(effect, ref.current);
};
