import { useUserRoles } from '@/auth';
import {
  CreateSocialAccountAnalyticsAdvertiserInputV2,
  useCreateSocialAccountAnalyticsAdminV2Mutation,
  useCreateSocialAccountAnalyticsAdvertiserV2Mutation
} from '@/graphql';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useCustomHeader, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { AnalyticsForm, AnalyticsFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const AddAnalyticsAccount = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    title: 'documentTitle.AddAccount',
    appHeader: 'appHeader.Add Account',
    href: '/analytics',
    alwaysBackToParent: true
  });
  const { isAdvertiser } = useUserRoles();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateSocialAccountAnalyticsAdminV2 } = useCreateSocialAccountAnalyticsAdminV2Mutation();
  const { callCreateSocialAccountAnalyticsAdvertiserV2 } = useCreateSocialAccountAnalyticsAdvertiserV2Mutation();

  const handleSubmit: FormAction<AnalyticsFormValues>['onSubmit'] = async (values) => {
    try {
      const input: CreateSocialAccountAnalyticsAdvertiserInputV2 = {
        accountName: values.accountName,
        youtubeChannelId: values.youtube?.channelId,
        twitterUserId: values.twitter?.twitterUserId,
        facebookPageId: values.facebookPage?.fbPageId,
        instagramUserId: values.instagram?.id ? String(values.instagram?.id) : undefined
      };

      if (isAdvertiser) {
        await callCreateSocialAccountAnalyticsAdvertiserV2({ variables: { input } });
      } else {
        await callCreateSocialAccountAnalyticsAdminV2({
          variables: {
            input: {
              ...input,
              advertiserId: Number(values.advertiserId),
              accountManagersPICs: values.accountManagerIds?.map(Number)
            }
          }
        });
      }

      enqueueSnackbar(t('succeededInCreating', { name: values.accountName }), { variant: 'success' });
      navigate({ to: '/analytics' });
    } catch (error) {
      enqueueSnackbar(t('failedToCreateAnalyticsAccount'), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
      <Header />

      <AnalyticsForm onSubmit={handleSubmit} />
    </div>
  );
};
