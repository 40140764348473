import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { useAutoManagedJoinedInfluencersFilter } from './hooks';

export type AutoManagedJoinedInfluencersFilterProps = Omit<TemplateProps, 'onSubmit'>;

export const AutoManagedJoinedInfluencersFilter = (props: AutoManagedJoinedInfluencersFilterProps) => {
  const { filter, setFilterForm } = useAutoManagedJoinedInfluencersFilter();

  return (
    <FilterWrapper css={{ padding: '16px' }}>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<Template {...props} />} />
    </FilterWrapper>
  );
};

export { useAutoManagedJoinedInfluencersFilter };
export * from './schemaTypes';
