import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteTalentAgency.graphql';
import { Mutation, MutationdeleteTalentAgencyArgs } from '../../__generated__/globalTypes';

export const useDeleteTalentAgencyMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteTalentAgencyArgs>
) => {
  const [callDeleteTalentAgency, result] = useMutation<Mutation, MutationdeleteTalentAgencyArgs>(MUTATION, options);

  return { callDeleteTalentAgency, ...result };
};

export type DeleteTalentAgencyMutationFunction = Mutation['deleteTalentAgency'];
