import { useUserRoles } from '@/auth';
import { useQueryHelper } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { NOT_FOUND, NO_IN_CHARGE_ADVERTISER } from '@/shared/constants';
import { getReportQueryVariables, useReportFilter } from '@/shared/organisms';
import { CampaignChartReport, useAllCampaignsSummaryQuery, useCampaignsChartForAllTimeQuery } from '@/graphql';

export const useInitialReport = () => {
  const { filter } = useReportFilter();
  const { isAdvertiser } = useUserRoles();
  const { t, enqueueSnackbar } = useQueryHelper();

  const variables = getValuableVariables(getReportQueryVariables(filter));
  const isAllTime = !filter.startDate && !filter.endDate;

  const checkIsOnboarding = (message?: string) => message === NOT_FOUND && isAdvertiser;

  const { data, previousData, loading, error } = useAllCampaignsSummaryQuery({
    variables,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      if (checkIsOnboarding(error.message) || error.message === NO_IN_CHARGE_ADVERTISER) {
        return;
      }

      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const {
    data: allTimeChartData,
    previousData: previousAllTimeChartData,
    loading: fetchingAllTimeChartData
  } = useCampaignsChartForAllTimeQuery({
    variables,
    skip: !isAllTime,
    fetchPolicy: 'cache-and-network'
  });

  const result = loading ? previousData : data;
  const allTimeChartDataResult = fetchingAllTimeChartData ? previousAllTimeChartData : allTimeChartData;

  return {
    isAllTime,
    loading: loading || fetchingAllTimeChartData,
    totalRecords: result?.allCampaignsSummary?.total,
    isOnboarding: checkIsOnboarding(error?.message),
    summaryData: result?.allCampaignsSummary || undefined,
    chartData: isAllTime
      ? allTimeChartDataResult?.campaignsChartForAllTime.map<CampaignChartReport>((item) => ({
          cost: item.cost,
          date: item.midDate,
          engagement: item.engagement
        }))
      : result?.allCampaignsSummary?.chartData
  };
};
