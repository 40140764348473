import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { SC } from '../StyledComponents';
import { PostInsightFormKeys } from '../types';

export const PostInsightYoutube = () => {
  const { t } = useTranslation();

  return (
    <>
      <SC.SectionWrapper>
        <SC.SectionTitle>{t('Impression')}</SC.SectionTitle>

        <FormStyle.FieldsGroup itemsPerRow={2} css={{ paddingBottom: '24px' }}>
          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="impressions"
            placeholder="10000000"
            title={t('TextForm.Impression')}
          />

          <SC.TextField<PostInsightFormKeys>
            unit="%"
            type="number"
            placeholder="33"
            name="impressionsClickThroughRate"
            title={t('TextForm.Impressions click-through rate')}
          />
        </FormStyle.FieldsGroup>
      </SC.SectionWrapper>

      <SC.SectionWrapper>
        <SC.SectionTitle>{t('View')}</SC.SectionTitle>

        <FormStyle.FieldsGroup itemsPerRow={2} css={{ paddingBottom: '24px' }}>
          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="views"
            title={t('TextForm.View')}
            placeholder="10000"
          />

          <SC.TextField<PostInsightFormKeys>
            unit="m"
            type="number"
            name="averageViewDurationMinutes"
            title={t('TextForm.Average view duration')}
            placeholder="10000"
          />
        </FormStyle.FieldsGroup>
      </SC.SectionWrapper>

      <SC.SectionWrapper css={{ borderBottom: 'none' }}>
        <SC.SectionTitle>{t('Audience retention')}</SC.SectionTitle>

        <FormStyle.FieldsGroup itemsPerRow={3}>
          <SC.TextField<PostInsightFormKeys>
            unit="%"
            type="number"
            placeholder="33"
            name="audienceRetention"
            title={t('TextForm.Audience retention')}
          />
        </FormStyle.FieldsGroup>
      </SC.SectionWrapper>
    </>
  );
};
