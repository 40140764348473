import { useTranslation } from 'react-i18next';
import { RangeSliderInputField } from '@/shared/molecules';
import { MIN_MAX_SAVE_RATE_OPTIONS, MIN_SAVE_RATE, MAX_SAVE_RATE } from '@/shared/constants';
import { InfluencersFilterFormKeys } from '../types';

export const SaveRateField = () => {
  const { t } = useTranslation();

  return (
    <RangeSliderInputField<InfluencersFilterFormKeys>
      step={0.1}
      title={t('Save Rate')}
      options={MIN_MAX_SAVE_RATE_OPTIONS}
      range={{ min: MIN_SAVE_RATE, max: MAX_SAVE_RATE }}
      minField={{ placeholder: `${MIN_SAVE_RATE}%`, name: 'minSaveRate' }}
      maxField={{ placeholder: `${MAX_SAVE_RATE}%`, name: 'maxSaveRate' }}
    />
  );
};
