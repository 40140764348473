import {
  AnalyticsYoutubeDashboardVideos,
  AnalyticsYoutubeDashboardShorts,
  AnalyticsYoutubeDashboardOverview,
  AnalyticsYoutubeDashboardAudience,
  AnalyticsYoutubeDashboardComments,
  AnalyticsYoutubeDashboardTagsRanking,
  AnalyticsYoutubeDashboardPostsOverview
} from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';

export const AnalyticsYoutubeDashboard = () => {
  const { brandAccount, selectedSocialAccount } = useAnalyticsProfileContext();

  return (
    <>
      <AnalyticsYoutubeDashboardOverview analyticsAccount={selectedSocialAccount} />
      <AnalyticsYoutubeDashboardAudience analyticsAccount={selectedSocialAccount} />
      <AnalyticsYoutubeDashboardPostsOverview analyticsAccount={selectedSocialAccount} />
      <AnalyticsYoutubeDashboardVideos
        analyticsAccount={selectedSocialAccount}
        brandAccountName={brandAccount.brandAccountName}
      />
      <AnalyticsYoutubeDashboardShorts
        analyticsAccount={selectedSocialAccount}
        brandAccountName={brandAccount.brandAccountName}
      />
      <AnalyticsYoutubeDashboardTagsRanking
        analyticsAccount={selectedSocialAccount}
        brandAccountName={brandAccount.brandAccountName}
      />
      <AnalyticsYoutubeDashboardComments
        analyticsAccount={selectedSocialAccount}
        brandAccountName={brandAccount.brandAccountName}
      />
    </>
  );
};
