import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Label, Textarea, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';

export interface ConfirmDenyingInfluencerModalProps {
  open?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onSubmit?: (reason: string) => void;
}

export const ConfirmDenyingInfluencerModal = ({
  open,
  onClose,
  loading,
  onSubmit
}: ConfirmDenyingInfluencerModalProps) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    if (!reason) return;

    onSubmit?.(reason);
  };

  return (
    <Modal open={open} onClose={onClose} css={{ maxWidth: '500px' }} hasCloseIcon>
      <ModalContent>
        <ModalTitle>{t('Dialog.Deny This Influencer')}</ModalTitle>
        <p css={{ paddingTop: '16px' }}>{t('Dialog.Deny Influencer Explained')}</p>

        <Label css={{ marginTop: '24px' }} title={t('TextForm.Reasons for Denial')} required />
        <Textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder={t<string>('JobDescription.Contents Placeholder')}
        />
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{
          loading,
          variant: 'red',
          disabled: !reason,
          onClick: handleSubmit,
          title: t('Button.Deny')
        }}
      />
    </Modal>
  );
};
