import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { LabelProps } from '@/shared/atoms';
import { DayPickerSingle, DayPickerSingleProps } from '../DayPicker';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export interface SingleDatePickerFieldProps<T extends string = string>
  extends Omit<DefaultFieldProps<T, string>, 'title'>,
    Omit<DayPickerSingleProps, 'classes' | 'value' | 'handleChangeDay' | 'placeholder'> {
  title?: LabelProps['title'];
}

export const SingleDatePickerField = <T extends string = string>(props: SingleDatePickerFieldProps<T>) => {
  const { t } = useTranslation();
  const {
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, onChange, onSubmit, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  const handleChangeDate = (day: string) => {
    if (onChange) {
      onChange(name, day);
    } else {
      setValue<string>(name, day, setValueOptions);
    }
    onSubmit?.();
  };

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <DayPickerSingle
        placeholder={t<string>('Choose date')}
        {...restFieldProps}
        css={styles.dateSelector}
        handleChangeDay={handleChangeDate}
      />
    </RHFFieldWrapper>
  );
};

const styles = {
  dateSelector: css({
    '.date-picker-input': {
      width: 'auto',
      height: '30px',
      borderRadius: '3px',
      '> p': { height: '100%', display: 'flex', alignItems: 'center' }
    }
  })
};
