import { useTranslation } from 'react-i18next';
import { InstagramPopularHashtags } from '@/graphql';
import {
  Modal,
  Table,
  TableCell,
  TextCutter,
  ModalTitle,
  ModalProps,
  ModalContent,
  ModalFooterActions
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar } from '@/shared/molecules';
import { formatIntNumber } from '@/shared/utils';

export interface AnalyticsCompareInstagramHashtagsModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  className?: string;
  hashtags?: readonly InstagramPopularHashtags[];
  account?: { name?: string; avatar?: string };
}

export const AnalyticsCompareInstagramHashtagsModal = ({
  open,
  account,
  onClose,
  hashtags = []
}: AnalyticsCompareInstagramHashtagsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} css={{ width: '488px' }}>
      <ModalContent>
        {account ? (
          <ModalTitle css={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
            <InfluencerAvatar size="24px" src={account?.avatar} css={{ borderRadius: '50%', marginRight: '8px' }} />
            <TextCutter lines={1} text={t('Dialog.PostingHabits', { name: account?.name })} />
          </ModalTitle>
        ) : null}

        <Table
          css={{ marginTop: '24px', border: THEME.border.base }}
          data={{
            columns: [
              { title: t('HeaderColumn.Hash Tag') },
              { title: t('HeaderColumn.Used Post') },
              { title: t('HeaderColumn.Ave like') },
              { title: t('HeaderColumn.Ave Comment') },
              { title: t('HeaderColumn.Ave Eng') }
            ],
            rows: hashtags.map((item) => [
              <TableCell.Text css={{ minWidth: 'unset' }} value={item.hashtag} />,
              <TableCell.Number value={formatIntNumber(item.usedPosts)} />,
              <TableCell.Number value={formatIntNumber(item.averageLikes)} />,
              <TableCell.Number value={formatIntNumber(item.averageComments)} />,
              <TableCell.Number value={formatIntNumber(item.averageEngagement)} />
            ])
          }}
          noTopBorderRadius
        />
      </ModalContent>
      <ModalFooterActions cancelButtonProps={{ onClick: onClose }} />
    </Modal>
  );
};
