import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerAllInstagramPostsInDate.graphql';
import { Query, QueryinfluencerAllInstagramPostsInDateArgs } from '../../__generated__/globalTypes';

export const useInfluencerAllInstagramPostsInDateQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerAllInstagramPostsInDateArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerAllInstagramPostsInDateArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerAllInstagramPostsInDateLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerAllInstagramPostsInDateArgs>
): QueryResult<Query, QueryinfluencerAllInstagramPostsInDateArgs> & {
  getInfluencerAllInstagramPostsInDate: LazyQueryExecFunction<Query, QueryinfluencerAllInstagramPostsInDateArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerAllInstagramPostsInDate, result] = useLazyQuery<
    Query,
    QueryinfluencerAllInstagramPostsInDateArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerAllInstagramPostsInDate, ...result };
};

export const useInfluencerAllInstagramPostsInDatePromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerAllInstagramPostsInDate = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerAllInstagramPostsInDateArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerAllInstagramPostsInDateArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerAllInstagramPostsInDate };
};
