import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerFacebookPageAnalyticsOverview.graphql';
import { Query, QueryinfluencerFacebookPageAnalyticsOverviewArgs } from '../../__generated__/globalTypes';

export const useInfluencerFacebookPageAnalyticsOverviewQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerFacebookPageAnalyticsOverviewArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerFacebookPageAnalyticsOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerFacebookPageAnalyticsOverviewLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerFacebookPageAnalyticsOverviewArgs>
): QueryResult<Query, QueryinfluencerFacebookPageAnalyticsOverviewArgs> & {
  getInfluencerFacebookPageAnalyticsOverview: LazyQueryExecFunction<
    Query,
    QueryinfluencerFacebookPageAnalyticsOverviewArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerFacebookPageAnalyticsOverview, result] = useLazyQuery<
    Query,
    QueryinfluencerFacebookPageAnalyticsOverviewArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerFacebookPageAnalyticsOverview, ...result };
};

export const useInfluencerFacebookPageAnalyticsOverviewPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerFacebookPageAnalyticsOverview = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerFacebookPageAnalyticsOverviewArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerFacebookPageAnalyticsOverviewArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerFacebookPageAnalyticsOverview };
};
