import { linkOptions } from '@tanstack/react-router';
import { useUpdateTalentInfluencerMutation, TalentInfluencerDetailsForEditPayload } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import {
  TalentInfluencerForm,
  TalentInfluencerFormValues,
  getTalentInfluencerFormValues,
  getCreateUpdateTalentInfluencerInput
} from '@/shared/organisms';
import { FormAction } from '@/shared/types';

interface TemplateProps {
  influencerId: number;
  influencer: TalentInfluencerDetailsForEditPayload; // TODO: "id" should be included. Ask BE
}

export const Template = ({ influencer, influencerId }: TemplateProps) => {
  const goBackUrl = linkOptions({ to: '/influencer/$id/profile', params: { id: influencerId.toString() } });

  useUpdateDocumentTitle({
    ...goBackUrl,
    title: 'documentTitle.EditInfluencer',
    appHeader: influencer.name || 'appHeader.Edit Influencer'
  });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callUpdateTalentInfluencer } = useUpdateTalentInfluencerMutation();

  const handleSubmit: FormAction<TalentInfluencerFormValues>['onSubmit'] = async (values) => {
    try {
      const { countryId, ...input } = getCreateUpdateTalentInfluencerInput(values);
      await callUpdateTalentInfluencer({ variables: { input: { ...input, influencerId } } });

      enqueueSnackbar(t('succeededInUpdating', { name: values.name }), { variant: 'success' });
      navigate(goBackUrl);
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: values.name }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <TalentInfluencerForm
      onSubmit={handleSubmit}
      defaultValues={getTalentInfluencerFormValues(influencerId, influencer)}
    />
  );
};
