import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AddStaffAdvertisersToAnotherStaff.graphql';
import { Mutation, MutationaddStaffAdvertisersToAnotherStaffArgs } from '../../__generated__/globalTypes';

export const useAddStaffAdvertisersToAnotherStaffMutation = (
  options?: MutationHookOptions<Mutation, MutationaddStaffAdvertisersToAnotherStaffArgs>
) => {
  const [callAddStaffAdvertisersToAnotherStaff, result] = useMutation<
    Mutation,
    MutationaddStaffAdvertisersToAnotherStaffArgs
  >(MUTATION, options);

  return { callAddStaffAdvertisersToAnotherStaff, ...result };
};

export type AddStaffAdvertisersToAnotherStaffMutationFunction = Mutation['addStaffAdvertisersToAnotherStaff'];
