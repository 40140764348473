import * as SunLang from 'suneditor/src/lang';
import Lang from 'suneditor-react/dist/types/lang';

import { AppLanguage } from '@/shared/types';

export const DEFAULT_BUTTON_LIST = [
  ['undo', 'redo'],
  ['bold', 'underline', 'italic', 'strike'],
  ['fontColor', 'hiliteColor', 'fontSize'],
  ['removeFormat'],
  ['indent', 'outdent'],
  ['list'],
  ['link']
];

export const mapSuneditorLanguage = (anytagLanguage: string): Lang => {
  switch (anytagLanguage as AppLanguage) {
    case 'ja':
      return SunLang['ja'];
    case 'ko':
      return SunLang['ko'];
    case 'tw':
      return SunLang['zh_cn'];

    case 'en':
    default:
      return SunLang['en'];
  }
};
