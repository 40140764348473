import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { TabType, Tabs, TabsProps } from '@/shared/atoms';
import { getSubscriptionTypeLabel } from '@/shared/utils';
import { BasicAccountAnalyticsPayload, AnalyticsSubscriptionPlanType, AnalyticsAuthSocialAccountType } from '@/graphql';
import { AnalyticsSelectedAccountInfo } from '../types';
import { useAnalyticsProfileSearch } from '../hooks';
import { AnalyticsTabType } from './types';

export interface AnalyticsTabsProps {
  brandAccount: BasicAccountAnalyticsPayload;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsTabs = ({
  analyticsAccount,
  brandAccount: {
    country,
    subscriptionPlan: { type, availableFeatures }
  }
}: AnalyticsTabsProps) => {
  const { t } = useTranslation();
  const { filter } = useAnalyticsProfileSearch();

  const planTitle = getSubscriptionTypeLabel(type);

  const getAnalyticsTab = (tab: AnalyticsTabType, options?: Partial<TabType>): TabType => {
    switch (tab) {
      case AnalyticsTabType.UGC:
        return {
          end: false,
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc',
          label: t('General.UGC'),
          ...options
        };
      case AnalyticsTabType.HASHTAG:
        return {
          end: false,
          label: t('General.Hashtag'),
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag',
          ...options
        };
      case AnalyticsTabType.COMPARE:
        return {
          end: false,
          label: t('General.Compare'),
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare',
          ...options
        };
      case AnalyticsTabType.INTERACTION:
        return {
          end: false,
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction',
          label: t('Brand mentions'),
          ...options
        };
      case AnalyticsTabType.TREND:
        return {
          end: false,
          label: t('General.Trend'),
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend',
          ...options,
          search: { filter: { ...filter, country } }
        };
      case AnalyticsTabType.DASHBOARD:
      default:
        return {
          end: false,
          label: t('General.Dashboard'),
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard',
          ...options
        };
    }
  };

  const options: TabsProps['options'] = (() => {
    switch (analyticsAccount.socialAccountType) {
      case AnalyticsAuthSocialAccountType.YOUTUBE:
        return [
          getAnalyticsTab(AnalyticsTabType.DASHBOARD),
          getAnalyticsTab(AnalyticsTabType.COMPARE),
          // Some tabs have EmptyUI so they will handle `availableFeatures[key]` within component
          getAnalyticsTab(AnalyticsTabType.TREND, { hidden: !availableFeatures.trendEnabled })
        ];
      case AnalyticsAuthSocialAccountType.TWITTER:
        return [getAnalyticsTab(AnalyticsTabType.DASHBOARD)];
      case AnalyticsAuthSocialAccountType.FACEBOOK_PAGE:
        return [getAnalyticsTab(AnalyticsTabType.DASHBOARD)];
      case AnalyticsAuthSocialAccountType.INSTAGRAM:
        return [
          getAnalyticsTab(AnalyticsTabType.DASHBOARD),
          getAnalyticsTab(AnalyticsTabType.COMPARE),
          getAnalyticsTab(AnalyticsTabType.HASHTAG),
          getAnalyticsTab(AnalyticsTabType.INTERACTION),
          getAnalyticsTab(AnalyticsTabType.UGC, { hidden: type === AnalyticsSubscriptionPlanType.FREE })
        ];
      default:
        return [];
    }
  })();

  return options.length ? (
    <Tabs options={options} hasBorder={false} css={styles.tab}>
      {!!planTitle && <div css={styles.plan}>{t(planTitle)}</div>}
    </Tabs>
  ) : null;
};

const styles = {
  tab: css({ padding: '0 24px', borderTop: THEME.border.base }),
  plan: css({
    height: '24px',
    padding: '0 8px',
    fontSize: '13px',
    fontWeight: 600,
    overflow: 'hidden',
    lineHeight: '24px',
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textOverflow: 'ellipsis',
    color: THEME.text.colors.white,
    backgroundColor: THEME.background.colors.black
  })
};
