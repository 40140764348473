import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { PaymentPlanType, usePricingTableQuery } from '@/graphql';
import {
  PricingPlan,
  FeaturesTable,
  getSubscriptionFeatures,
  useTikTokAdvertiserSummaryData
} from '@/shared/organisms';

export const TikTokSubscription = () => {
  useUpdateDocumentTitle({
    title: 'appHeader.Select Plan',
    appHeader: 'appHeader.Select Plan',
    href: '/tiktok-onboarding',
    alwaysBackToParent: true
  });
  const { t } = useTranslation();
  const { summaryData } = useTikTokAdvertiserSummaryData();
  const { data } = usePricingTableQuery({
    variables: {
      planType: PaymentPlanType.TIKTOK_ADS
    }
  });
  const subscriptionGroups = getSubscriptionFeatures(t);

  return (
    <div css={styles.wrapper}>
      <PricingPlan plans={data?.pricingTable?.plans || []} summaryData={summaryData} />
      <div css={{ padding: '16px 16px 0' }}>
        <h3 css={styles.sectionTitle}>{subscriptionGroups.group1.title}</h3>
        <FeaturesTable features={subscriptionGroups.group1.features} />
      </div>
      <div css={{ padding: '24px 16px 0' }}>
        <h3 css={styles.sectionTitle}>{subscriptionGroups.group2.title}</h3>
        <FeaturesTable features={subscriptionGroups.group2.features} />
      </div>
      <div css={{ padding: '24px 16px 0' }}>
        <h3 css={styles.sectionTitle}>{subscriptionGroups.group3.title}</h3>
        <FeaturesTable features={subscriptionGroups.group3.features} />
      </div>
      <div css={{ padding: '24px 16px 0' }}>
        <h3 css={styles.sectionTitle}>{subscriptionGroups.group4.title}</h3>
        <FeaturesTable features={subscriptionGroups.group4.features} skipLastBorder />
      </div>
    </div>
  );
};
const styles = {
  wrapper: css({
    position: 'relative',
    backgroundColor: THEME.background.colors.white
  }),
  sectionTitle: css({
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '140%' /* 19.6px */,
    paddingBottom: '16px'
  })
};
