import { FormAction } from '@/shared/types';
import { useDownloadFile } from '@/shared/hooks';
import { CampaignPostSortField } from '@/graphql';
import { getAdvancedSearchApiUrl } from '@/shared/utils';
import { DownloadCsvPagesAndRowsModalValues, getDownloadCSVRangeRows } from '@/shared/molecules';
import { PostDiscoveryStatsKey } from '../utils';
import { useCampaignPostsDiscoveryFilter } from '../../CampaignPostsDiscoveryFilter';
import { PostDiscoveryColumnProps } from './useAllCampaignPostsListColumns';

interface Props {
  onClose?: () => void;
  columns: PostDiscoveryColumnProps[];
  selectedColumns: PostDiscoveryStatsKey[];
}

export const useDownloadCampaignPostsDiscovery = ({ columns, onClose, selectedColumns }: Props) => {
  const { downloading, handleDownloadFile } = useDownloadFile();
  const {
    sort,
    limit,
    filter: {
      keyword,
      endDate,
      startDate,
      campaigns,
      countryIds,
      categoryIds,
      campaignTypes,
      advertiserIds,
      influencerIds,
      socialPostTypes
    }
  } = useCampaignPostsDiscoveryFilter();

  const handleDownloadCsv: FormAction<DownloadCsvPagesAndRowsModalValues>['onSubmit'] = async (formValues) => {
    const { firstRow, lastRow } = getDownloadCSVRangeRows(formValues, limit);
    const filteredColumns = columns.reduce<CampaignPostSortField[]>(
      (rs, col) => (col.key && col.sortField && selectedColumns.includes(col.key) ? [...rs, col.sortField] : rs),
      []
    );

    await handleDownloadFile({
      filenamePrefix: `post_discovery_csv_${new Date().toUTCString()}`,
      method: 'GET',
      url: getAdvancedSearchApiUrl('api/campaign_post/csv'),
      params: {
        firstRow,
        lastRow,
        keyword,
        campaigns,
        countryIds,
        categoryIds,
        campaignTypes,
        advertiserIds,
        influencerIds,
        socialPostTypes,
        maxPostDate: endDate,
        minPostDate: startDate,
        orderByField: sort.field,
        orderByOrder: sort.order,
        selectedColumns: filteredColumns
      }
    });

    onClose?.();
  };

  return { limit, downloading, handleDownloadCsv };
};
