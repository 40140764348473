/* eslint-disable react/jsx-key */
import { useQueryHelper, useSelectItem } from '@/shared/hooks';
import { AnchorProps, ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { YoutubeCmsInvoice, useCmsInvoiceDownloadUrlPromiseQuery } from '@/graphql';
import { downloadFileFromUrl, formatIntNumber, localizedDateFormatter } from '@/shared/utils';

interface Props {
  data: readonly YoutubeCmsInvoice[];
}

export const useInvoicesTable = ({ data }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { selectedItem, setSelectedItem } = useSelectItem<number>(null);
  const { getCmsInvoiceDownloadUrl } = useCmsInvoiceDownloadUrlPromiseQuery();

  const handleDownloadInvoice =
    (invoiceId: number): AnchorProps['onClick'] =>
    async (e) => {
      e.preventDefault();
      setSelectedItem(invoiceId);

      try {
        const { data } = await getCmsInvoiceDownloadUrl({ variables: { id: invoiceId } });

        const url = data.cmsInvoiceDownloadUrl?.url;

        if (url) {
          downloadFileFromUrl(url, `invoice-${invoiceId}.pdf`);
        } else {
          enqueueSnackbar(t('Failed to download invoice'), { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      } finally {
        setSelectedItem(null);
      }
    };

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.AnyCreator name') },
    { title: t('HeaderColumn.Invoice name') },
    { title: t('HeaderColumn.Payment total') },
    { title: t('HeaderColumn.Invoice') },
    { title: t('HeaderColumn.Created date') },
    { title: t('HeaderColumn.Approve date') }
  ];
  const rows: RowProps[] = data.map(
    ({ id, name, currency, createdDate, approvedDate, influencerName, paymentTotal }) => [
      <TableCell.Text css={{ minWidth: 'unset' }} value={influencerName} />,
      <TableCell.Text css={{ minWidth: 'unset' }} value={name} />,
      <TableCell.Text
        css={{ minWidth: 'unset' }}
        value={
          <>
            <span css={{ fontSize: '12px' }}>{formatIntNumber(paymentTotal)}</span>
            <span css={{ fontSize: '10px' }}>{t(currency)}</span>
          </>
        }
      />,
      <TableCell.Link
        variant="blue"
        disabled={!!selectedItem}
        css={{ minWidth: '100px' }}
        onClick={handleDownloadInvoice(id)}
        label={t(selectedItem === id ? 'Button.Downloading' : 'Button.Download')}
      />,
      <TableCell.Text
        css={{ minWidth: 'unset' }}
        value={createdDate ? localizedDateFormatter(createdDate, 'yyyy/MM/dd') : '-'}
      />,
      <TableCell.Text
        css={{ minWidth: 'unset' }}
        value={approvedDate ? localizedDateFormatter(approvedDate, 'yyyy/MM/dd') : '-'}
      />
    ]
  );

  return { rows, columns };
};
