import { Option } from './Option';
import { MenuList } from './MenuList';
import { SelectAll } from './SelectAll';
import { BaseSelect } from './BaseSelect';
import { MultiValue } from './MultiValue';
import { OptionLabel } from './OptionLabel';
import { ClearIndicator } from './ClearIndicator';
import { MenuListMultiple } from './MenuListMultiple';
import { DropdownIndicator } from './DropdownIndicator';

export * from './hooks';
// Custom react-select components
export const SelectComponents = {
  Option,
  MenuList,
  SelectAll,
  MultiValue,
  BaseSelect,
  OptionLabel,
  ClearIndicator,
  MenuListMultiple,
  DropdownIndicator,
  IndicatorSeparator: () => null
};
