import { Header } from '@/shared/molecules';
import { useUserRoles, useMarketplacePermissions } from '@/auth';
import { MarketplaceDetail, MarketplaceCampaignStatus } from '@/graphql';
import { useUpdateMarketplaceStatus } from '../hooks';
import { MarketplaceStatus } from '../MarketplaceStatus';

export interface MarketplaceHeaderProps {
  marketplace: MarketplaceDetail;
}

export const MarketplaceHeader = ({ marketplace }: MarketplaceHeaderProps) => {
  const { isAgency, isAdvertiser } = useUserRoles();
  const { hideApproveCampaignBtn } = useMarketplacePermissions();
  const { hasApproveRejectNotice, ApproveButton, DialogModals } = useUpdateMarketplaceStatus({ marketplace });

  const { status, salesPics, influencerManagementPics } = marketplace;
  // Adv users can only approve campaign if sales&Infl PICS were added;
  const hasPicsForApproveCampaign = !!(salesPics?.length && influencerManagementPics?.length);
  const hasApproveButtonForAdvertiser = !isAdvertiser ? true : hasPicsForApproveCampaign;
  const hasApproveButton =
    !hasApproveRejectNotice &&
    !isAgency &&
    !hideApproveCampaignBtn &&
    hasApproveButtonForAdvertiser &&
    status === MarketplaceCampaignStatus.REVIEWING;

  return (
    <Header>
      <MarketplaceStatus status={marketplace?.status} />

      {hasApproveButton && (
        <>
          <ApproveButton css={{ marginLeft: 'auto' }} />
          {DialogModals}
        </>
      )}
    </Header>
  );
};
