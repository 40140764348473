import { useTranslation } from 'react-i18next';
import { SortOption } from '@/shared/types';
import { Order, PostListSortFieldV3 } from '@/graphql';
import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { usePostsFilter } from './hooks';
import { BasicFilter } from './BasicFilter';

export const PostsFilter = () => {
  const { t } = useTranslation();
  const { filter, setFilterForm } = usePostsFilter();

  const sortOptions: SortOption<PostListSortFieldV3>[] = [
    { field: PostListSortFieldV3.ENGAGEMENT_RATE, label: t('ER (ASC)'), order: Order.ASC },
    { field: PostListSortFieldV3.ENGAGEMENT_RATE, label: t('ER (DESC)'), order: Order.DESC },
    { field: PostListSortFieldV3.FOLLOWERS, label: t('Follower (ASC)'), order: Order.ASC },
    { field: PostListSortFieldV3.FOLLOWERS, label: t('Follower (DESC)'), order: Order.DESC },
    { field: PostListSortFieldV3.POST_DATE, label: t('Post Date (ASC)'), order: Order.ASC },
    { field: PostListSortFieldV3.POST_DATE, label: t('Post Date (DESC)'), order: Order.DESC },
    { field: PostListSortFieldV3.RELEVANT, label: t('Relevant (DESC)'), order: Order.DESC }
  ].map((option) => ({ ...option, value: `${option.field}-${option.order}` }));
  const selectedSortOption = sortOptions.find((o) => o.field === filter.field && o.order === filter.order);

  return (
    <FilterWrapper>
      <FilterProvider
        onSubmit={setFilterForm}
        basicFilter={<BasicFilter sortOptions={sortOptions} />}
        initialValues={{ ...filter, sort: selectedSortOption?.value || '' }}
      />
    </FilterWrapper>
  );
};

export * from './schemaTypes';
export * from './hooks';
