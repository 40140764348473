import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { SingleSelectField } from '@/shared/molecules';
import { PostInsightStatus } from '@/shared/organisms';
import { EngagementPostForInsightCheck } from '@/graphql';
import { Anchor, SocialIcon, SocialIconProps } from '@/shared/atoms';
import { usePostsInsightStatusOptions, useQueryHelper } from '@/shared/hooks';
import { usePostInsightFilter } from './hooks';

interface PostsListProps {
  className?: string;
  campaignId: number;
  posts: readonly EngagementPostForInsightCheck[];
  postId: number | null;
}

export const PostsList = ({ posts, className, campaignId, postId }: PostsListProps) => {
  const { filter, setFilter } = usePostInsightFilter();
  const { t, navigate } = useQueryHelper();
  const { postsInsightStatusOptions } = usePostsInsightStatusOptions();

  return (
    <div css={styles.postsWrapper} className={className}>
      <div style={{ padding: '8px', borderBottom: '1px solid #DEE5EC' }}>
        <SingleSelectField
          name="status"
          value={filter.status}
          options={postsInsightStatusOptions}
          placeholder={t<string>('Selector.Status')}
          setFieldValue={(_, status) => setFilter({ status })}
        />
      </div>

      <div css={{ overflow: 'auto' }}>
        {posts.map(({ id, status, influencerName, socialMedia }) => (
          <div
            key={id}
            css={[styles.post, postId === id && { backgroundColor: THEME.background.colors.gray.lv1 }]}
            onClick={() =>
              navigate({
                to: '/posts-insight/$id/$postId',
                params: { id: campaignId.toString(), postId: id.toString() },
                search: { filter },
                replace: true
              })
            }
          >
            <div css={{ display: 'flex', fontSize: '12px', margin: '0 0 8px', alignItems: 'center' }}>
              <SocialIcon size="16px" type={socialMedia as SocialIconProps['type']} css={{ marginRight: '4px' }} />
              <Anchor
                target="_blank"
                label={influencerName}
                to="/engagement/$id/posts"
                params={{ id: campaignId.toString() }}
                search={{ popup: String(postId) }}
              />
            </div>
            <PostInsightStatus css={{ fontSize: '10px', height: '16px' }} status={status} />
          </div>
        ))}
      </div>
    </div>
  );
};
const styles = {
  postsWrapper: css({
    flex: 'none',
    width: '218px',
    display: 'flex',
    height: 'inherit',
    flexDirection: 'column',
    borderRight: THEME.border.base
  }),
  post: css({
    display: 'block',
    cursor: 'pointer',
    padding: '11px 16px',
    textDecoration: 'none',
    boxSizing: 'border-box',
    borderBottom: THEME.border.base,
    '&:hover': { backgroundColor: THEME.background.colors.gray.lv1 }
  })
};
