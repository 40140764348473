import { MarketplaceAffiliateJoinedInfluencer, MarketplaceCampaignRevenueType } from '@/graphql';

export const getRevenueItems = (revenueItems: MarketplaceAffiliateJoinedInfluencer['revenueItems']) => ({
  revenuePerAction: findItemByType(revenueItems, MarketplaceCampaignRevenueType.REVENUE_PER_ACTION),
  revenuePerClick: findItemByType(revenueItems, MarketplaceCampaignRevenueType.REVENUE_PER_CLICK),
  revenuePerComment: findItemByType(revenueItems, MarketplaceCampaignRevenueType.REVENUE_PER_COMMENT),
  revenuePerLike: findItemByType(revenueItems, MarketplaceCampaignRevenueType.REVENUE_PER_LIKE),
  revenuePerPost: findItemByType(revenueItems, MarketplaceCampaignRevenueType.REVENUE_PER_POST),
  revenuePerShare: findItemByType(revenueItems, MarketplaceCampaignRevenueType.REVENUE_PER_SHARE),
  revenuePerView: findItemByType(revenueItems, MarketplaceCampaignRevenueType.REVENUE_PER_VIEW),
  influencerCommissionRate: findItemByType(revenueItems, MarketplaceCampaignRevenueType.INFLUENCER_COMMISSION_RATE)
});

const findItemByType = (
  revenueItems: MarketplaceAffiliateJoinedInfluencer['revenueItems'],
  revenueType: MarketplaceCampaignRevenueType
) => revenueItems.find((item) => item.revenueType === revenueType);
