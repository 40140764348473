import { ReactNode } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot as RecoilProvider } from 'recoil';
import { AuthProvider } from '@/auth';
import { ApolloProvider } from './ApolloProvider';
import { NotificationProvider } from './NotificationProvider';
import { ThemeProvider } from './ThemeProvider';

declare const INTERCOM_ID: string;

interface AppProvidersProps {
  children: ReactNode;
}

export const AppProviders = ({ children }: AppProvidersProps) => (
  <ApolloProvider>
    <AuthProvider>
      <RecoilProvider>
        <IntercomProvider appId={INTERCOM_ID} autoBoot>
          <ThemeProvider>
            {children}
            <div id="portal-root" />
            <NotificationProvider />
          </ThemeProvider>
        </IntercomProvider>
      </RecoilProvider>
    </AuthProvider>
  </ApolloProvider>
);
