import { useTranslation } from 'react-i18next';
import { FilterAction } from '@/shared/types';
import { TableSegment } from '@/shared/atoms';
import { AffiliateReportShowBy, MarketplaceAffiliateReportSummary } from '@/graphql';
import { MarketplaceAffiliateReportFilterFormValues } from '../MarketplaceAffiliateReportFilter';
import { MarketplaceAffiliateReportByDateTable } from './MarketplaceAffiliateReportByDateTable';
import { MarketplaceAffiliateReportByInfluencerTable } from './MarketplaceAffiliateReportByInfluencerTable';

export interface MarketplaceAffiliateReportTableProps extends FilterAction<MarketplaceAffiliateReportFilterFormValues> {
  currency: string;
  campaignId: number;
  isReferralCampaign?: boolean;
  summaryData?: MarketplaceAffiliateReportSummary;
}

export const MarketplaceAffiliateReportTable = ({
  filter,
  currency,
  setFilter,
  campaignId,
  summaryData,
  isReferralCampaign
}: MarketplaceAffiliateReportTableProps) => {
  const { t } = useTranslation();

  const isShowByDate = filter.mode === AffiliateReportShowBy.DATE;
  const defaultTableProps = { currency, campaignId, summaryData, isReferralCampaign };

  return (
    <>
      <TableSegment
        type="radio"
        onChange={({ key: mode }) => setFilter({ mode })}
        options={[
          { key: AffiliateReportShowBy.DATE, label: t('Show by date'), value: isShowByDate },
          { key: AffiliateReportShowBy.INFLUENCER, label: t('Show by Influencer'), value: !isShowByDate }
        ]}
      />

      {isShowByDate ? (
        <MarketplaceAffiliateReportByDateTable {...defaultTableProps} />
      ) : (
        <MarketplaceAffiliateReportByInfluencerTable {...defaultTableProps} />
      )}
    </>
  );
};
