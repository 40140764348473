import { debounce } from 'lodash';
import { Options } from 'react-select';
import { useHubspotDealsListPromiseQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { Option } from '@/shared/types';

export const useHubspotDealsLoadOptions = (countryId?: string) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { getHubspotDealsList } = useHubspotDealsListPromiseQuery();

  const loadHubspotDealsOptions = async (keyword: string, callback: (options: Options<Option>) => void) => {
    if (keyword.length >= 1) {
      try {
        const { data, errors } = await getHubspotDealsList({ variables: { keyword, countryId: countryId || null } });
        const entitiesList: Option[] =
          data.hubspotDealsList?.map((hubspotDeal) => ({
            value: hubspotDeal.id,
            label: hubspotDeal.dealName,
            ...hubspotDeal
          })) || [];

        if (errors?.length) {
          enqueueSnackbar(t(errors.at(0)?.message || ''), { variant: 'error' });
        }

        callback(entitiesList);
      } catch (error) {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    }
  };

  // we can't inline debounce func in render, it will break debounce logic each renred
  const debouncedLoadHubspotDealsOptions: (searchTerm: string, callback: (options: Options<Option>) => void) => void =
    debounce((searchTerm, callback) => {
      loadHubspotDealsOptions(searchTerm, callback);
    }, 300);

  return {
    loadHubspotDealsOptions,
    debouncedLoadHubspotDealsOptions
  };
};
