import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagementBreakdownMode.graphql';
import { Mutation, MutationupdateEngagementBreakdownModeArgs } from '../../__generated__/globalTypes';

export const useUpdateEngagementBreakdownModeMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateEngagementBreakdownModeArgs>
) => {
  const [callUpdateEngagementBreakdownMode, result] = useMutation<Mutation, MutationupdateEngagementBreakdownModeArgs>(
    MUTATION,
    options
  );

  return { callUpdateEngagementBreakdownMode, ...result };
};

export type UpdateEngagementBreakdownModeMutationFunction = Mutation['updateEngagementBreakdownMode'];
