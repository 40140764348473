import {
  NoDataContentWrapper,
  RenderDataWithFallback,
  NoDataContentWrapperProps
} from '@/shared/atoms';
import { SectionWithHeader, SectionWithHeaderProps } from '@/shared/molecules';

export interface AnalyticsFeedPostsWrapperProps
  extends SectionWithHeaderProps,
    Pick<NoDataContentWrapperProps, 'dataNotAvailable'> {
  loading?: boolean;
}

export const AnalyticsFeedPostsWrapper = ({
  loading,
  children,
  dataNotAvailable,
  moreDetailProps,
  ...restProps
}: AnalyticsFeedPostsWrapperProps) => (
  <RenderDataWithFallback loading={loading}>
    <SectionWithHeader
      moreDetailProps={!dataNotAvailable ? moreDetailProps : undefined}
      css={{ borderRadius: 0, marginTop: '1px', minHeight: '200px', '.section-header': { borderBottom: 'none' } }}
      {...restProps}
    >
      <NoDataContentWrapper
        className="no-data-wrapper"
        dataNotAvailable={dataNotAvailable}
        css={{ border: 'none', minHeight: '120px' }}
      >
        {children}
      </NoDataContentWrapper>
    </SectionWithHeader>
  </RenderDataWithFallback>
);
