import { css } from '@emotion/react';
import { PeriodType } from '@/shared/types';
import { formatDate } from '@/shared/utils';
import { THEME } from '@/shared/constants';

export const formatAffiliatePeriod = ({ period, title }: { period: PeriodType; title: string }) =>
  `${title}\u00A0\u00A0(${formatDate(period.startDate)} - ${formatDate(period.endDate)})`;

export const styles = {
  totalRow: css({
    height: '32px',
    pointerEvents: 'none',
    '> td': {
      fontWeight: 600,
      fontSize: '14px',
      backgroundColor: THEME.table.header.background.colors.lv2,
      '> *': { fontSize: 'inherit' }
    }
  })
};
