import { useTranslation } from 'react-i18next';
import { TextField, TextFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const TitleField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  ...restProps
}: Partial<TextFieldProps<T>>) => {
  const { t } = useTranslation();

  return (
    <TextField<T>
      help={t('Tooltip.Campaign Title')}
      title={t('TextForm.Campaign Title')}
      name={name || (MarketplaceFormKeys.title as T)}
      placeholder={t<string>('TextForm.InstagramCampaign')}
      {...restProps}
    />
  );
};
