import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateInstagramUserHashtag.graphql';
import { Mutation, MutationcreateInstagramUserHashtagArgs } from '../../__generated__/globalTypes';

export const useCreateInstagramUserHashtagMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateInstagramUserHashtagArgs>
) => {
  const [callCreateInstagramUserHashtag, result] = useMutation<Mutation, MutationcreateInstagramUserHashtagArgs>(
    MUTATION,
    options
  );

  return { callCreateInstagramUserHashtag, ...result };
};

export type CreateInstagramUserHashtagMutationFunction = Mutation['createInstagramUserHashtag'];
