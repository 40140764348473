import { SocialAccountStatus } from '@/graphql';
import { Button, FormStyle } from '@/shared/atoms';
import { SupportedSocialMediaType } from '@/shared/types';
import { SocialMediaInputFieldProps, SocialMediaInputField } from '../../../shared';
import { EditAccountButton } from '../EditAccountButton/EditAccountButton';
import { SocialNetworkAccount } from '../types';

export interface SocialMediaInputFieldActionProps extends SocialMediaInputFieldProps {
  influencerId?: number;
  onDelete?: () => void;
  account?: SocialNetworkAccount;
}

export const SocialMediaInputFieldAction = ({
  title,
  account,
  onDelete,
  socialMedia,
  influencerId,
  ...inputProps
}: SocialMediaInputFieldActionProps) => {
  const hideEditButton =
    !account?.username ||
    !account?.socialAccountId ||
    (account?.status !== SocialAccountStatus.SCRAPING && socialMedia !== SupportedSocialMediaType.TIKTOK);

  const hasEditButton =
    influencerId &&
    !hideEditButton &&
    ![SupportedSocialMediaType.DOUYIN, SupportedSocialMediaType.XHS].includes(socialMedia as SupportedSocialMediaType);

  return (
    <div css={{ gap: '8px', display: 'flex', alignItems: 'flex-end', marginBottom: '8px' }}>
      <SocialMediaInputField title={title} socialMedia={socialMedia} {...inputProps} />

      {hasEditButton ? (
        <FormStyle.FieldWrapper css={{ flex: 'none' }}>
          <EditAccountButton
            inputProps={{ title }}
            socialMedia={socialMedia}
            influencerId={influencerId}
            socialAccountId={account.socialAccountId}
          />
        </FormStyle.FieldWrapper>
      ) : null}

      {onDelete ? (
        <FormStyle.FieldWrapper css={{ flex: 'none', alignSelf: 'baseline' }}>
          <Button variant="white" prefixIcon={{ icon: 'trash', size: 16 }} onClick={onDelete} />
        </FormStyle.FieldWrapper>
      ) : null}
    </div>
  );
};
