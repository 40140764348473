import { useTranslation } from 'react-i18next';

import { useMarketplacePermissions } from '@/auth';
import { useInfluencerCampaignSummaryQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';

import { CampaignsListSummary } from '../shared';

interface CampaignsSummaryProps {
  influencerId: number;
}
export const CampaignsSummary = ({ influencerId }: CampaignsSummaryProps) => {
  const { t } = useTranslation();
  const { hideMarketplaceReportLink } = useMarketplacePermissions();
  const { data, loading } = useInfluencerCampaignSummaryQuery({
    fetchPolicy: 'network-only',
    variables: { pk: influencerId }
  });

  const summaries = data?.influencerCampaignSummary;

  return (
    <RenderDataWithFallback loading={loading}>
      <CampaignsListSummary
        hideMetrics={{
          engagement: true, // FIXME: control on permission level is not working for signed up with Email accounts
          marketplace: hideMarketplaceReportLink
        }}
        summaries={[
          {
            name: t('Joined Campaigns'),
            marketplaceValue: summaries?.marketplaceJoinedCount,
            autoManagedValue: summaries?.autoManagedJoinedCount
          },
          {
            name: t('Average Commission'),
            marketplaceValue: summaries?.marketplaceAvgCost,
            autoManagedValue: summaries?.autoManagedAvgCost,
            unit: summaries?.currency
          }
        ]}
      />
    </RenderDataWithFallback>
  );
};
