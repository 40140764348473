import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Accept, DropEvent, FileRejection } from 'react-dropzone';
import { AddMoreItem } from '../AddMoreItem';
import { SortableList } from '../../SortableList';
import { PreviewItem, PreviewItemProps } from './PreviewItem';

export interface PreviewProps extends Pick<PreviewItemProps, 'uploadProgress'> {
  name: string;
  accept?: Accept;
  error?: boolean;
  disabled?: boolean;
  className?: string;
  multiple?: boolean;
  itemsPerRow?: number;
  maxUploadedFiles?: number;
  onDelete: (index: number) => void;
  uploadedFiles: { url: string; preview: string }[];
  onChange?: (name: string, values: string[]) => void;
  onDrop: (acceptedFiles: File[], rejectedFiles?: FileRejection[], event?: DropEvent) => void;
}

export const Preview = ({
  name,
  accept,
  onDrop,
  disabled,
  multiple,
  onChange,
  onDelete,
  className,
  uploadedFiles,
  uploadProgress,
  maxUploadedFiles = 10,
  itemsPerRow: numberItemInRow = 3
}: PreviewProps) => {
  const widthPercentage = `${Number(100 / numberItemInRow).toFixed(2)}%`;
  // We need state to update ordered lists immediately after drop
  const [reorderedFiles, setReorderedFiles] = useState<{ id: number; url: string; preview: string }[]>(
    uploadedFiles.map((file, index) => ({ ...file, id: index }))
  );

  const isDraggable = reorderedFiles.length > 1;

  useEffect(() => {
    setReorderedFiles(uploadedFiles.map((file, index) => ({ ...file, id: index })));
    // Must be length because we only update reorderedFiles if we remove/add file
    // We don't need to update when reorder
  }, [uploadedFiles.length]);

  return (
    <div css={styles.wrapper} className={className}>
      <SortableList
        items={reorderedFiles}
        sortableContextProps={{ disabled: !isDraggable }}
        onChange={(reorderFiles) => {
          setReorderedFiles(reorderFiles);
          onChange?.(
            name,
            reorderFiles.map(({ url }) => url)
          );
        }}
        customOverlayItem={({ id, url, preview }, index) => (
          <PreviewItem
            id={id}
            url={url}
            preview={preview}
            disabled={disabled}
            widthPercentage="100%"
            css={{ cursor: 'grabbing' }}
            uploadProgress={uploadProgress}
            onDelete={() => onDelete(index)}
          />
        )}
        renderItem={({ id, url, preview }, index) => (
          <PreviewItem
            id={id}
            url={url}
            preview={preview}
            disabled={disabled}
            uploadProgress={uploadProgress}
            onDelete={() => onDelete(index)}
            widthPercentage={widthPercentage}
            css={{ cursor: isDraggable ? 'grab' : undefined }}
          />
        )}
      />

      {multiple && !disabled && uploadedFiles.length < maxUploadedFiles ? (
        <AddMoreItem width={widthPercentage} name={name} accept={accept} onDrop={onDrop} multiple={multiple} />
      ) : null}
    </div>
  );
};
const styles = {
  wrapper: css({
    width: '100%',
    padding: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box'
  })
};
