import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/MarketplacePostComments.graphql';
import { Query, QuerymarketplacePostCommentsArgs } from '../../__generated__/globalTypes';

export const useMarketplacePostCommentsQuery = (
  options?: QueryHookOptions<Query, QuerymarketplacePostCommentsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerymarketplacePostCommentsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useMarketplacePostCommentsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerymarketplacePostCommentsArgs>
): QueryResult<Query, QuerymarketplacePostCommentsArgs> & {
  getMarketplacePostComments: LazyQueryExecFunction<Query, QuerymarketplacePostCommentsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getMarketplacePostComments, result] = useLazyQuery<Query, QuerymarketplacePostCommentsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getMarketplacePostComments, ...result };
};

export const useMarketplacePostCommentsPromiseQuery = () => {
  const client = useApolloClient();

  const getMarketplacePostComments = useCallback(
    (options?: Omit<QueryOptions<QuerymarketplacePostCommentsArgs, Query>, 'query'>) =>
      client.query<Query, QuerymarketplacePostCommentsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getMarketplacePostComments };
};
