import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { CampaignType, InfluencerCampaignListSortField, InfluencerCampaignDetailByPromotion } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { formatIntNumber, formatPrice } from '@/shared/utils';

type CustomColumnProps = ColumnProps<InfluencerCampaignListSortField>;

interface Props {
  data: readonly InfluencerCampaignDetailByPromotion[];
}

export const useCampaignsListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { isTalentAgencyPartner } = useUserRoles();

  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Campaign'), sticky: { active: true } },
    { title: t('HeaderColumn.Promotion Methods') },
    { title: t('HeaderColumn.Sales'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Clicks'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Orders'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Actions'), styles: { textAlign: 'right' } }
  ];
  const rows: RowProps[] = data.map(({ currency, campaignInfo, summary }) => {
    const canAccessMarketplace =
      !(campaignInfo.type === CampaignType.MARKETPLACE && isTalentAgencyPartner) &&
      // no campaign ID means that current campaign is not associated with logged in user, so we should disable the links
      campaignInfo.id;

    return [
      <div>
        <TableCell.Campaign
          type={campaignInfo.type}
          title={campaignInfo.title || ''}
          src={campaignInfo.thumbNail || ''}
          id={campaignInfo.id?.toString() || ''}
          mkpCampaignType={campaignInfo.mkpDetailType}
          isPostTracking={!!campaignInfo.hasPostTracking}
          css={{
            padding: 0,
            width: '240px',
            border: 'none',
            backgroundColor: 'transparent',
            pointerEvents: canAccessMarketplace ? 'auto' : 'none'
          }}
        />
      </div>,
      <TableCell.Text value={campaignInfo.promotionMethod} />,
      <TableCell.Number value={formatPrice(summary.sales, currency)} unit={summary.sales ? t(currency) : ''} />,
      <TableCell.Number value={formatIntNumber(summary.clicks)} />,
      <TableCell.Number value={formatIntNumber(summary.orders)} />,
      <TableCell.Number value={formatIntNumber(summary.actions)} />
    ];
  });

  return { rows, columns };
};
