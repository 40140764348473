import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AddInstagramCompareAccountAnalytics.graphql';
import { Mutation, MutationaddInstagramCompareAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useAddInstagramCompareAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationaddInstagramCompareAccountAnalyticsArgs>
) => {
  const [callAddInstagramCompareAccountAnalytics, result] = useMutation<
    Mutation,
    MutationaddInstagramCompareAccountAnalyticsArgs
  >(MUTATION, options);

  return { callAddInstagramCompareAccountAnalytics, ...result };
};

export type AddInstagramCompareAccountAnalyticsMutationFunction = Mutation['addInstagramCompareAccountAnalytics'];
