import { YearMonthDayPicker, YearMonthDayPickerProps } from '../YearMonthDayPicker';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export type YearMonthDayPickerFieldProps<T extends string = string> = DefaultFieldProps<T, string> &
  Pick<YearMonthDayPickerProps, 'disabled' | 'minDate' | 'maxDate' | 'format'>;

export const YearMonthDayPickerField = <T extends string = string>(props: YearMonthDayPickerFieldProps<T>) => {
  const {
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, onChange, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <YearMonthDayPicker
        {...restFieldProps}
        onChange={(date) => (onChange ? onChange(name, date) : setValue<string>(name, date, setValueOptions))}
      />
    </RHFFieldWrapper>
  );
};
