import { useTranslation } from 'react-i18next';
import { MIN_AGE, MAX_AGE, MIN_MAX_AGE_OPTIONS, formatMinMaxAgeFilterLabel } from '@/shared/constants';
import { RangeSliderInputField, RangeSliderInputFieldProps } from '@/shared/molecules';
import { formatIntNumber } from '@/shared/utils';
import { InfluencersFilterFormKeys } from '../types';

type AgeFieldProps = Pick<RangeSliderInputFieldProps, 'hideSlider'>;
export const AgeField = ({ hideSlider }: AgeFieldProps) => {
  const { t } = useTranslation();

  return (
    <RangeSliderInputField<InfluencersFilterFormKeys>
      step={1}
      title={t('General.Age')}
      options={MIN_MAX_AGE_OPTIONS}
      range={{ min: MIN_AGE, max: MAX_AGE }}
      minField={{ name: 'minAge', placeholder: `${formatIntNumber(MIN_AGE)}-` }}
      maxField={{ name: 'maxAge', placeholder: `${formatIntNumber(MAX_AGE)}+` }}
      formatLabel={formatMinMaxAgeFilterLabel}
      hideSlider={hideSlider}
    />
  );
};
