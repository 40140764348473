import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations } from '@/shared/validations';
import { XhsPostType } from '@/graphql';

export const InfluencerXhsPostsFilterSchema = z.object({
  keyword: FilterValidations.string().optional(),
  postType: z.array(z.nativeEnum(XhsPostType)).default([])
});
export type InfluencerXhsPostsFilterFormValues = z.infer<typeof InfluencerXhsPostsFilterSchema>;
export type InfluencerXhsPostsFilterFormKeys = UnionOfKeys<InfluencerXhsPostsFilterFormValues>;
