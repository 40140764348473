import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/BrandAccounts.graphql';
import { Query, QuerybrandAccountsArgs } from '../../__generated__/globalTypes';

export const useBrandAccountsQuery = (options?: QueryHookOptions<Query, QuerybrandAccountsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerybrandAccountsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useBrandAccountsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerybrandAccountsArgs>
): QueryResult<Query, QuerybrandAccountsArgs> & {
  getBrandAccounts: LazyQueryExecFunction<Query, QuerybrandAccountsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getBrandAccounts, result] = useLazyQuery<Query, QuerybrandAccountsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getBrandAccounts, ...result };
};

export const useBrandAccountsPromiseQuery = () => {
  const client = useApolloClient();

  const getBrandAccounts = useCallback(
    (options?: Omit<QueryOptions<QuerybrandAccountsArgs, Query>, 'query'>) =>
      client.query<Query, QuerybrandAccountsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getBrandAccounts };
};
