import { useTranslation } from 'react-i18next';
import { YoutubeAnalyticsPostsPayload, YoutubeAnalyticsPostsSortOrder } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { convertDurationToHhMmSs, formatIntNumber, formatPercent } from '@/shared/utils';

type CustomColumnProps = ColumnProps<YoutubeAnalyticsPostsSortOrder, keyof YoutubeAnalyticsPostsPayload>;

interface Props {
  data: readonly YoutubeAnalyticsPostsPayload[];
}

export const useAnalyticsYoutubeVideosListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Videos'), sticky: { active: true, hasBorderRight: true } },
    getMetricColumnProps({
      title: t('HeaderColumn.View'),
      sortField: YoutubeAnalyticsPostsSortOrder.VIEWS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Play Time'),
      sortField: YoutubeAnalyticsPostsSortOrder.DURATION
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Average View Duration'),
      sortField: YoutubeAnalyticsPostsSortOrder.AVERAGE_VIEW_DURATION
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Average View Duration Rate'),
      sortField: YoutubeAnalyticsPostsSortOrder.AVERAGE_VIEW_PERCENTAGE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      sortField: YoutubeAnalyticsPostsSortOrder.LIKE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Dislike'),
      sortField: YoutubeAnalyticsPostsSortOrder.DISLIKE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      sortField: YoutubeAnalyticsPostsSortOrder.COMMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Share'),
      sortField: YoutubeAnalyticsPostsSortOrder.SHARE
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      sortField: YoutubeAnalyticsPostsSortOrder.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({
      views,
      likes,
      shares,
      postDate,
      dislikes,
      comments,
      videoTitle,
      videoDuration,
      videoThumbnail,
      averageViewDuration,
      averageViewPercentage,
      id
    }) => [
      <TableCell.Post
        size="44px"
        title={videoTitle}
        thumbnail={videoThumbnail || ''}
        onClick={() => setPostDetailsParam(id)}
      />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={convertDurationToHhMmSs(videoDuration)} />,
      <TableCell.Number value={convertDurationToHhMmSs(averageViewDuration ?? null)} />,
      <TableCell.Number value={formatPercent(averageViewPercentage, false)} unit="%" />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(dislikes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(shares)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
