import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/NetsuiteVendorName.graphql';
import { Query, QuerynetsuiteVendorNameArgs } from '../../__generated__/globalTypes';

export const useNetsuiteVendorNameQuery = (options?: QueryHookOptions<Query, QuerynetsuiteVendorNameArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerynetsuiteVendorNameArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useNetsuiteVendorNameLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerynetsuiteVendorNameArgs>
): QueryResult<Query, QuerynetsuiteVendorNameArgs> & {
  getNetsuiteVendorName: LazyQueryExecFunction<Query, QuerynetsuiteVendorNameArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getNetsuiteVendorName, result] = useLazyQuery<Query, QuerynetsuiteVendorNameArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getNetsuiteVendorName, ...result };
};

export const useNetsuiteVendorNamePromiseQuery = () => {
  const client = useApolloClient();

  const getNetsuiteVendorName = useCallback(
    (options?: Omit<QueryOptions<QuerynetsuiteVendorNameArgs, Query>, 'query'>) =>
      client.query<Query, QuerynetsuiteVendorNameArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getNetsuiteVendorName };
};
