import { useTranslation } from 'react-i18next';
import { UGCPostStatus } from '@/graphql';
import { Option } from '@/shared/types';

interface Props {
  hasAllOption?: boolean;
}

export const useUGCPostOptions = ({ hasAllOption }: Props = { hasAllOption: false }) => {
  const { t } = useTranslation();

  const statusOptions = [
    ...(hasAllOption ? [{ label: t<string>('Option.UGC All'), value: '' }] : []),
    { label: t<string>('Option.UGC Not requested'), value: UGCPostStatus.NOT_REQUESTED },
    { label: t<string>('Option.UGC Requested'), value: UGCPostStatus.REQUESTED },
    { label: t<string>('Option.UGC Approved'), value: UGCPostStatus.APPROVED },
    { label: t<string>('Option.UGC Rejected'), value: UGCPostStatus.REJECTED }
  ];

  return { statusOptions: statusOptions as Option<UGCPostStatus | ''>[] };
};
