import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { UGCPostStatus as UGCPostStatusType, InstagramUGCManagementListPostsItemPayload } from '@/graphql';
import { MediaPreview, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatIntNumber } from '@/shared/utils';
import { UGCPostStatus } from '../UGCPostStatus';

export interface UGCPostProps {
  onClick?: () => void;
  post: InstagramUGCManagementListPostsItemPayload;
}

export const UGCPost = ({ post, onClick }: UGCPostProps) => {
  const { t } = useTranslation();

  const isNotRequested = post.status === UGCPostStatusType.NOT_REQUESTED;

  return (
    <div css={styles.squareRatio}>
      <div css={styles.wrapper(isNotRequested)} onClick={!isNotRequested ? onClick : undefined}>
        <MediaPreview src={post.thumbnail} />

        <UGCPostStatus status={post.status} />

        <div css={styles.statistic}>
          <div>
            <p>{t('Like')}</p>
            <TextCutter text={formatIntNumber(post.likes)} />
          </div>
          <div>
            <p>{t('Comment')}</p>
            <TextCutter text={formatIntNumber(post.comments)} />
          </div>
        </div>

        <div css={styles.permission} className="permission-asking">
          <button onClick={onClick}>{t('Ask Permission')}</button>
        </div>
      </div>
    </div>
  );
};
const styles = {
  squareRatio: css({
    paddingTop: '100%',
    position: 'relative',
    borderRadius: 'inherit',
    backgroundColor: '#000'
  }),
  wrapper: (havePermissionAsking?: boolean) =>
    css({
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '3px',
      position: 'absolute',
      cursor: havePermissionAsking ? 'default' : 'pointer',
      ...(havePermissionAsking ? { '&:hover': { '.permission-asking': { display: 'flex' } } } : {})
    }),
  statistic: css({
    left: 0,
    bottom: 0,
    zIndex: 1,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    boxSizing: 'border-box',
    borderRadius: 'inherit',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.8) 100%)',
    div: {
      padding: '8px',
      flex: '50% 0 0',
      position: 'relative',
      boxSizing: 'border-box',
      color: THEME.text.colors.white,
      '&:first-of-type::after': {
        right: 0,
        opacity: 0.4,
        content: '""',
        top: '50%',
        width: '1px',
        height: '26px',
        position: 'absolute',
        transform: 'translateY(-50%)',
        backgroundColor: THEME.background.colors.white
      },
      'p:first-of-type': { fontSize: '11px', lineHeight: '13px', letterSpacing: '0.28px' },
      'p:last-of-type': { marginTop: '4px', fontSize: '12px', lineHeight: '15px' }
    }
  }),
  permission: css({
    left: 0,
    bottom: 0,
    zIndex: 2,
    width: '100%',
    height: '100%',
    display: 'none',
    overflow: 'hidden',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'inherit',
    backgroundColor: 'rgba(39, 49, 59, 0.4)',
    button: {
      fontWeight: 600,
      fontSize: '11px',
      cursor: 'pointer',
      lineHeight: '11px',
      borderRadius: '5px',
      padding: '10px 16px',
      color: THEME.text.colors.white,
      background: THEME.background.colors.black
    }
  })
};
