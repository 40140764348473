import { useInfluencerPermissions } from '@/auth';
import { SocialAccountStatus, SocialAccountType } from '@/graphql';
import { DefaultRefreshButton } from './DefaultRefreshButton';
import { TwitterRefreshButton } from './TwitterRefreshButton';

const supportedSocialMedias = [
  SocialAccountType.XHS,
  SocialAccountType.TIKTOK,
  SocialAccountType.TWITTER,
  SocialAccountType.FACEBOOK,
  SocialAccountType.INSTAGRAM,
  SocialAccountType.FACEBOOK_PAGE
];

export interface RefreshInfluencerProfileButtonProps {
  className?: string;
  socialAccountId: number;
  status: SocialAccountStatus;
  socialMedia: SocialAccountType;
}
export const RefreshInfluencerProfileButton = ({
  status,
  className,
  socialMedia,
  socialAccountId
}: RefreshInfluencerProfileButtonProps) => {
  const { hideViewInfluencerProfile } = useInfluencerPermissions();

  if (hideViewInfluencerProfile || !supportedSocialMedias.includes(socialMedia)) {
    return null;
  }

  return socialMedia === SocialAccountType.TWITTER ? (
    <TwitterRefreshButton className={className} socialAccountId={socialAccountId} status={status} />
  ) : (
    <DefaultRefreshButton className={className} socialAccountId={socialAccountId} socialMedia={socialMedia} />
  );
};
