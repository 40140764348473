import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllPackages.graphql';
import { Query, QueryallPackagesArgs } from '../../__generated__/globalTypes';

export const useAllPackagesQuery = (options?: QueryHookOptions<Query, QueryallPackagesArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallPackagesArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllPackagesLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallPackagesArgs>
): QueryResult<Query, QueryallPackagesArgs> & {
  getAllPackages: LazyQueryExecFunction<Query, QueryallPackagesArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllPackages, result] = useLazyQuery<Query, QueryallPackagesArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllPackages, ...result };
};

export const useAllPackagesPromiseQuery = () => {
  const client = useApolloClient();

  const getAllPackages = useCallback(
    (options?: Omit<QueryOptions<QueryallPackagesArgs, Query>, 'query'>) =>
      client.query<Query, QueryallPackagesArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllPackages };
};
