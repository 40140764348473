import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { ChatBoxContextType, ChatBoxProvider } from './ChatBoxContext';

export interface ChatBoxProps extends Pick<ChatBoxContextType, 'uploadFileConfigs'> {
  className?: string;
  children: ReactNode;
}

export const ChatBox = ({ uploadFileConfigs, ...restProps }: ChatBoxProps) => (
  <ChatBoxProvider value={{ uploadFileConfigs }}>
    <div css={styles.wrapper} {...restProps} />
  </ChatBoxProvider>
);

const styles = {
  wrapper: css({
    ...THEME.chatbox.size,
    display: 'flex',
    fontSize: '16px',
    overflow: 'hidden',
    lineHeight: '120%',
    borderRadius: '8px',
    fontStyle: 'normal',
    flexDirection: 'column',
    transition: 'height .1s linear',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0px 4px 10px 0px rgba(39, 49, 59, 0.10)'
  })
};
