import { css } from '@emotion/react';
import React, { DetailedHTMLProps, forwardRef, useState } from 'react';

export const FadeInImage = forwardRef<
  HTMLImageElement,
  DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
>((props, ref) => {
  const [className, setClassName] = useState('');

  return (
    <img
      alt=""
      ref={ref}
      css={styles.img}
      onLoad={() => {
        setClassName('fade-in');
      }}
      width="100%"
      height="auto"
      {...props}
      className={[className, props.className].join(' ')}
    />
  );
});
FadeInImage.displayName = 'FadeInImage';

const styles = {
  img: css({
    opacity: 0,
    height: 'auto',
    maxWidth: '100%',

    '@keyframes fadein': { from: { opacity: 0 }, to: { opacity: 1 } },
    '&.fade-in': { animation: 'fadein 0.35s ease forwards' }
  })
};
