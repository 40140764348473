import {
  InfluencerTwitterProfileSimilarInfluencers,
  InfluencerTwitterProfileSimilarInfluencersProps
} from '../../InfluencerProfiles';

type InfluencerAnalyticsTwitterProfileSimilarInfluencersProps = InfluencerTwitterProfileSimilarInfluencersProps;

export const InfluencerAnalyticsTwitterProfileSimilarInfluencers = (
  props: InfluencerAnalyticsTwitterProfileSimilarInfluencersProps
) => <InfluencerTwitterProfileSimilarInfluencers {...props} />;
