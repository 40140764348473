import { useParams, useSearch } from '@tanstack/react-router';
import { FormAction } from '@/shared/types';
import { useLastLocation, useParamsSearch, useQueryHelper } from '@/shared/hooks';
import { EngagementPostForm, EngagementPostFormValues } from '@/shared/organisms';
import {
  namedOperations,
  SocialAccountType,
  useCreateEngagementPostV2Mutation,
  EngagementPostInfluencerWithSocialAccount
} from '@/graphql';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';
import { EggPostSearchSchemaType } from './schemaTypes';

export const EngagementAddPost = () => {
  const search = useSearch({ from: '/_private-routes/engagement/$id/posts/add' });
  const params = useParams({ from: '/_private-routes/engagement/$id/posts/add' });
  const { lastLocation } = useLastLocation();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { engagementId, engagement } = useEngagementDetailsContext();
  const { callCreateEngagementPostV2 } = useCreateEngagementPostV2Mutation({
    refetchQueries: [namedOperations.Query.AllEngagementPosts]
  });
  const { filter } = useParamsSearch<EggPostSearchSchemaType>();

  const influencer: EngagementPostInfluencerWithSocialAccount = {
    name: filter.name || '',
    id: Number(filter.id || 0),
    socialAccountName: filter.socialAccountName || '',
    socialAccountId: Number(filter.socialAccountId || 0),
    socialAccountMedia: filter.socialAccountMedia || null
  };

  const goToPostsList = () => {
    navigate({
      to: '/engagement/$id/posts',
      search: lastLocation?.search || search,
      params,
      replace: true
    });
  };

  const handleSubmit: FormAction<EngagementPostFormValues>['onSubmit'] = async (values) => {
    try {
      await callCreateEngagementPostV2({
        variables: {
          input: {
            campaignId: engagementId,
            content: values.content,
            postUrl: values.postUrl,
            insightUrls: values.insightUrls,
            materialsUrl: values.materialsUrl,
            planedPostDate: values.planedPostDate,
            influencerId: Number(values.influencerId),
            socialAccountId: Number(values.socialAccountId),
            insightDataAcquisition: values.insightDataAcquisition,
            socialAccountMedia: values.socialAccountMedia as SocialAccountType
          }
        }
      });
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      goToPostsList();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <EngagementPostForm data={{ influencer }} onSubmit={handleSubmit} onClose={goToPostsList} engagement={engagement} />
  );
};
