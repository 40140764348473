import { useTranslation } from 'react-i18next';
import { FacebookPageAnalyticsPostsSortOrder, FacebookPageAnalyticsPostsPayload } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';

type CustomColumnProps = ColumnProps<FacebookPageAnalyticsPostsSortOrder, keyof FacebookPageAnalyticsPostsPayload>;

interface Props {
  data: readonly FacebookPageAnalyticsPostsPayload[];
}

export const useAnalyticsFacebookPostsListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Posts'), sticky: { active: true, hasBorderRight: true } },
    getMetricColumnProps({
      title: t('HeaderColumn.Reach'),
      sortField: FacebookPageAnalyticsPostsSortOrder.AD_IMPRESSIONS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement'),
      sortField: FacebookPageAnalyticsPostsSortOrder.ENGAGEMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Reaction'),
      sortField: FacebookPageAnalyticsPostsSortOrder.REACTIONS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      sortField: FacebookPageAnalyticsPostsSortOrder.COMMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Share'),
      sortField: FacebookPageAnalyticsPostsSortOrder.SHARE
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      sortField: FacebookPageAnalyticsPostsSortOrder.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({ id, image, shares, content, comments, postDate, reactions, engagement, adImpressions }) => [
      <TableCell.Post title={content || ''} thumbnail={image || ''} onClick={() => setPostDetailsParam(id)} />,
      <TableCell.Number value={formatIntNumber(adImpressions)} />,
      <TableCell.Number value={formatIntNumber(engagement)} />,
      <TableCell.Number value={formatIntNumber(reactions)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(shares)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
