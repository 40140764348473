import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/DouyinAccountInterest.graphql';
import { Query, QuerydouyinAccountInterestArgs } from '../../__generated__/globalTypes';

export const useDouyinAccountInterestQuery = (options?: QueryHookOptions<Query, QuerydouyinAccountInterestArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerydouyinAccountInterestArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useDouyinAccountInterestLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerydouyinAccountInterestArgs>
): QueryResult<Query, QuerydouyinAccountInterestArgs> & {
  getDouyinAccountInterest: LazyQueryExecFunction<Query, QuerydouyinAccountInterestArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getDouyinAccountInterest, result] = useLazyQuery<Query, QuerydouyinAccountInterestArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getDouyinAccountInterest, ...result };
};

export const useDouyinAccountInterestPromiseQuery = () => {
  const client = useApolloClient();

  const getDouyinAccountInterest = useCallback(
    (options?: Omit<QueryOptions<QuerydouyinAccountInterestArgs, Query>, 'query'>) =>
      client.query<Query, QuerydouyinAccountInterestArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getDouyinAccountInterest };
};
