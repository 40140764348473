import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { SC } from '../StyledComponents';
import { PostInsightFormKeys } from '../types';

export const PostInsightInstagramStory = () => {
  const { t } = useTranslation();

  return (
    <SC.SectionWrapper css={{ borderBottom: 'none' }}>
      <SC.SectionTitle>{t('General.Overview')}</SC.SectionTitle>

      <FormStyle.FieldsGroup itemsPerRow={3}>
        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="reaches"
          placeholder="10000000"
          title={t('TextForm.Reach')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="impressions"
          placeholder="10000000"
          title={t('TextForm.Impression')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="interaction"
          placeholder="10000000"
          title={t('TextForm.Interaction')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="linkClicks"
          placeholder="10000000"
          title={t('TextForm.Link Clicks')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="navigation"
          placeholder="10000000"
          title={t('TextForm.Navigation')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="forward"
          placeholder="10000000"
          title={t('TextForm.Forward')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="exited"
          placeholder="10000000"
          title={t('TextForm.Exited')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="nextStory"
          placeholder="10000000"
          title={t('TextForm.Next Story')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="back"
          title={t('TextForm.Back')}
          placeholder="10000000"
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="profileActivity"
          placeholder="10000000"
          title={t('TextForm.Profile Activity')}
        />
      </FormStyle.FieldsGroup>
    </SC.SectionWrapper>
  );
};
