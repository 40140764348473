import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Anchor, ButtonSubmit, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAllAvailableCountryOptions } from '@/shared/hooks';
import { SelectorField, TextField } from '@/shared/molecules';
import { SignUpFormValues, SignUpFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { isSubmitting }
  } = useFormContext<SignUpFormValues>();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();

  const values = getValues();
  const hasAllFieldFilled = Object.values(values).every((field) => !!field);

  return (
    <FormStyle.Wrapper css={styles.wrapper} gapRow={16}>
      <h1 css={{ fontSize: '20px', lineHeight: '100%', textAlign: 'center' }}>{t('Sign up')}</h1>

      <p css={{ fontSize: '14px', textAlign: 'center', padding: '16px 0', lineHeight: '140%' }}>
        <Trans i18nKey="SignUp.Description" components={{ br: <br /> }} />
      </p>

      <TextField<SignUpFormKeys>
        name="companyName"
        title={t('TextForm.Company Name')}
        placeholder={t<string>('Company Name')}
        required
      />

      <TextField<SignUpFormKeys>
        name="companyUrl"
        title={t('TextForm.Company URL')}
        placeholder="https://anytag.tech"
        required
      />

      <FormStyle.FieldsGroup itemsPerRow={2}>
        <TextField<SignUpFormKeys>
          name="phoneNumber"
          placeholder="066020485707"
          title={t('TextForm.Phone Number')}
          required
        />
        <SelectorField<SignUpFormKeys>
          name="countryId"
          title={t('Selector.Country')}
          options={allAvailableCountryOptions}
          required
        />
      </FormStyle.FieldsGroup>

      <TextField<SignUpFormKeys> name="name" title={t('TextForm.Name')} placeholder={t<string>('Name')} required />
      <TextField<SignUpFormKeys> name="email" title={t('TextForm.Email')} placeholder="your@email.com" required />

      <ButtonSubmit
        variant="blue"
        loading={isSubmitting}
        disabled={!hasAllFieldFilled}
        title={t('Button.Create Account')}
        css={{ width: '100%', marginTop: '16px' }}
      />

      <p css={{ paddingTop: '24px', color: THEME.text.colors.gray.lv3, fontSize: '11px', lineHeight: '140%' }}>
        <Trans
          i18nKey="SignUpConfirmation"
          components={{
            a1: <Anchor variant="blue" css={styles.link} to="/privacy" />,
            a2: <Anchor variant="blue" css={styles.link} to="/terms" />
          }}
        />
      </p>
    </FormStyle.Wrapper>
  );
};

const styles = {
  link: css({ display: 'inline', fontSize: '12px', lineHeight: '1.4em' }),
  wrapper: css({
    width: '428px',
    margin: '0 auto',
    borderRadius: '3px',
    padding: '48px 40px',
    boxSizing: 'border-box'
  })
};
