export enum ColorVariant {
  DARK_RED = 'DARK_RED',
  DARK_BLUE = 'DARK_BLUE',
  DARK_GREY = 'DARK_GREY',
  DARK_GREEN = 'DARK_GREEN',
  DARK_YELLOW = 'DARK_YELLOW',
  DARK_PURPLE = 'DARK_PURPLE',
  DARK_ORANGE = 'DARK_ORANGE',
  LIGHT_RED = 'LIGHT_RED',
  LIGHT_BLUE = 'LIGHT_BLUE',
  LIGHT_GREY = 'LIGHT_GREY',
  LIGHT_CYAN = 'LIGHT_CYAN',
  LIGHT_GREEN = 'LIGHT_GREEN',
  LIGHT_PURPLE = 'LIGHT_PURPLE',
  LIGHT_YELLOW = 'LIGHT_YELLOW'
}
