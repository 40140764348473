import { TableData } from '@/shared/types';
import { WarningModal } from '@/shared/molecules';
import { useAdvertiserPermissions } from '@/auth';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { namedOperations, useDeleteAdvertiserMutation } from '@/graphql';
import { ADVERTISER_ALREADY_RUN_CAMPAIGN_BEFORE } from '@/shared/constants';
import { AccountType, AccountsList, DeleteAccountType } from '../../Accounts';

export type AdvertisersListProps = TableData<AccountType>;

export const AdvertisersList = (props: AdvertisersListProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { status, toggleStatus } = useToggleState();
  const { callDeleteAdvertiser, loading: deleting } = useDeleteAdvertiserMutation({
    refetchQueries: [namedOperations.Query.AllAdvertisers]
  });
  const { hideEditAdvertiserBtn, hideAddAdvertiserBtn, hideDeleteAdvertiserBtn } = useAdvertiserPermissions();

  const handleDeleteAccount = async (account: DeleteAccountType) => {
    try {
      await callDeleteAdvertiser({ variables: { id: account.id } });
      enqueueSnackbar(t('succeededInDeleting', { name: account.name }), { variant: 'success' });
    } catch (error) {
      if (error.message === ADVERTISER_ALREADY_RUN_CAMPAIGN_BEFORE) {
        toggleStatus();
      } else {
        enqueueSnackbar(t('failedToDelete', { name: account.name }), { variant: 'error' });
      }
    }
  };

  return (
    <>
      <AccountsList
        {...props}
        deleting={deleting}
        firstColumnTitle={t('HeaderColumn.Company Name')}
        editRoute={!hideEditAdvertiserBtn ? '/advertiser/$id' : undefined}
        onDeleteAccount={!hideDeleteAdvertiserBtn ? handleDeleteAccount : undefined}
        domain="advertiser"
        empty={{
          linkProps: !hideAddAdvertiserBtn
            ? { to: '/advertiser/add', label: t('Advertiser.AddAnAdvertiser') }
            : undefined
        }}
      />

      <WarningModal open={status} onClose={toggleStatus} description={`${t('Dialog.Has Campaign Before')}?`} />
    </>
  );
};
