import { useMarketplaceTrackingQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TrackingUrl } from '@/shared/organisms';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';

export const MarketplaceTrackingUrl = () => {
  const { marketplaceId } = useMarketplaceDetailsContext();
  const { data, loading } = useMarketplaceTrackingQuery({ fetchPolicy: 'cache-first', variables: { marketplaceId } });

  return (
    <RenderDataWithFallback loading={loading}>
      <TrackingUrl css={{ boxShadow: THEME.shadows.boxShadow }} postbackUrl={data?.marketplaceTracking?.postbackUrl} />
    </RenderDataWithFallback>
  );
};
