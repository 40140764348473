import { AddHashtagModalProps } from '@/shared/molecules';
import { useSelectItem, useToggleState } from '@/shared/hooks';
import { AnalyticsHashtagType } from '../../types';

interface Props<T extends AnalyticsHashtagType> {
  onDeleteHashtag?: (hashtag: T, cb?: () => void) => void;
  onAddNewHashtag?: (hashtag: string, cb?: () => void) => void;
}
export const useManageHashtags = <T extends AnalyticsHashtagType>({ onAddNewHashtag, onDeleteHashtag }: Props<T>) => {
  const addModalState = useToggleState();
  const {
    selectedItem: deletingHashtag,
    setSelectedItem: setDeletingHashtag,
    resetSelectedItem: resetDeletingHashtag
  } = useSelectItem<T>();

  const handleCreateHashtag: AddHashtagModalProps['onAdd'] = (hashtag) => {
    if (!hashtag) return;

    onAddNewHashtag?.(hashtag, addModalState.close);
  };

  const handleDeleteHashtag = () => {
    if (!deletingHashtag || deletingHashtag.blocked) return;

    onDeleteHashtag?.(deletingHashtag, resetDeletingHashtag);
  };

  return {
    addModalState,
    deletingHashtag,
    setDeletingHashtag,
    handleDeleteHashtag,
    handleCreateHashtag,
    resetDeletingHashtag
  };
};
