import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerYoutubeAnalyticsShorts.graphql';
import { Query, QueryinfluencerYoutubeAnalyticsShortsArgs } from '../../__generated__/globalTypes';

export const useInfluencerYoutubeAnalyticsShortsQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsShortsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerYoutubeAnalyticsShortsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerYoutubeAnalyticsShortsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsShortsArgs>
): QueryResult<Query, QueryinfluencerYoutubeAnalyticsShortsArgs> & {
  getInfluencerYoutubeAnalyticsShorts: LazyQueryExecFunction<Query, QueryinfluencerYoutubeAnalyticsShortsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerYoutubeAnalyticsShorts, result] = useLazyQuery<Query, QueryinfluencerYoutubeAnalyticsShortsArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getInfluencerYoutubeAnalyticsShorts, ...result };
};

export const useInfluencerYoutubeAnalyticsShortsPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerYoutubeAnalyticsShorts = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerYoutubeAnalyticsShortsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerYoutubeAnalyticsShortsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerYoutubeAnalyticsShorts };
};
