import { QueryHookOptions } from '@apollo/client';
import { useParams } from '@tanstack/react-router';
import {
  Query,
  SocialAccountType,
  useInfluencerHasEstimateApiQuery,
  QueryinfluencerHasEstimateApiArgs
} from '@/graphql';
import { InfluencerSelectedAccountInfo } from '../types';

interface Props {
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
  options?: QueryHookOptions<Query, QueryinfluencerHasEstimateApiArgs>;
}

export const useInfluencerProfileEstimate = ({ options, socialAccount: { id, type } }: Props) => {
  const socialMedia = type as SocialAccountType;
  const { id: influencerId } = useParams({ strict: false });

  const { data } = useInfluencerHasEstimateApiQuery({
    ...options,
    skip:
      ![
        SocialAccountType.INSTAGRAM,
        SocialAccountType.YOUTUBE,
        SocialAccountType.TIKTOK,
        SocialAccountType.FACEBOOK,
        SocialAccountType.FACEBOOK_PAGE
      ].includes(socialMedia) || options?.skip,
    variables: { pk: Number(influencerId), socialAccountId: id, socialMedia }
  });

  const estimateApi = data?.influencerHasEstimateApi;

  return {
    estimateMessage: estimateApi?.message || undefined,
    hasAccountInterests: !!estimateApi?.accountInterest,
    hasAudienceDemographics: !!estimateApi?.audienceDemographics
  };
};
