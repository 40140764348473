import { useFormContext } from 'react-hook-form';
import { FILTER_SECTIONS, MoreFilterPopover } from '@/shared/molecules';
import {
  AgeField,
  RegionField,
  FollowerField,
  PostPriceField,
  TTCMStatusField,
  AudienceDemographics,
  InfluencerStatusField,
  InfluencerCategoryField,
  TalentAgencyPartnerField,
  EngagementFollowersAndViewsRateField
} from '../../shared';
import { AdvancedFilterDefaultProps } from '../../types';
import { TiktokInfluencersFilterFormValues } from '../schemaTypes';
import { getInitialTiktokInfluencersFormValues } from '../utils';

export const AdvancedFilter = ({ count, onSubmit, isTtcmCampaign, isEngagementPage }: AdvancedFilterDefaultProps) => {
  const { reset, getValues } = useFormContext<TiktokInfluencersFilterFormValues>();

  const values = getValues();
  const emptyValues = getInitialTiktokInfluencersFormValues();

  const handleClear = () => {
    reset({
      ...emptyValues,
      // Basic filter
      keyword: values.keyword,
      genders: values.genders,
      countryIds: values.countryIds
    });
  };

  return (
    <MoreFilterPopover
      count={count}
      onClear={handleClear}
      onSubmit={onSubmit}
      items={[
        {
          value: FILTER_SECTIONS.InfluencerAttributes,
          title: FILTER_SECTIONS.InfluencerAttributes,
          node: (
            <>
              <AgeField />
              <InfluencerStatusField />
              {isEngagementPage && isTtcmCampaign ? <TTCMStatusField /> : null}
              <TalentAgencyPartnerField />
              <InfluencerCategoryField />
              <RegionField countryId={(values.countryIds.length === 1 && values.countryIds[0]) || ''} />
            </>
          )
        },
        {
          value: FILTER_SECTIONS.PerformanceFilter,
          title: FILTER_SECTIONS.PerformanceFilter,
          node: (
            <>
              <FollowerField />
              <EngagementFollowersAndViewsRateField />
              <PostPriceField />
            </>
          )
        },
        {
          value: FILTER_SECTIONS.Audiences,
          title: 'Audience Demographics',
          node: <AudienceDemographics />
        }
      ]}
    />
  );
};
