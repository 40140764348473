import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CloneMarketplace.graphql';
import { Mutation, MutationcloneMarketplaceArgs } from '../../__generated__/globalTypes';

export const useCloneMarketplaceMutation = (options?: MutationHookOptions<Mutation, MutationcloneMarketplaceArgs>) => {
  const [callCloneMarketplace, result] = useMutation<Mutation, MutationcloneMarketplaceArgs>(MUTATION, options);

  return { callCloneMarketplace, ...result };
};

export type CloneMarketplaceMutationFunction = Mutation['cloneMarketplace'];
