import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ReconnectTiktokAdAccount.graphql';
import { Mutation, MutationreconnectTiktokAdAccountArgs } from '../../__generated__/globalTypes';

export const useReconnectTiktokAdAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationreconnectTiktokAdAccountArgs>
) => {
  const [callReconnectTiktokAdAccount, result] = useMutation<Mutation, MutationreconnectTiktokAdAccountArgs>(
    MUTATION,
    options
  );

  return { callReconnectTiktokAdAccount, ...result };
};

export type ReconnectTiktokAdAccountMutationFunction = Mutation['reconnectTiktokAdAccount'];
