import { ChatBoxMessageType } from '../types';
import { MediaPreview } from '../../../MediaPreview';

export interface ChatBoxFileProps {
  file?: ChatBoxMessageType['file'];
}
export const ChatBoxFile = ({ file }: ChatBoxFileProps) =>
  //  Only support image type message for now
  file?.type === 'image' && file.url ? (
    <MediaPreview src={file.url} css={{ width: '80px', height: '80px', borderRadius: '8px' }} />
  ) : null;
