import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { Button, ButtonProps } from './Button';

export type ChatAIButtonProps = ButtonProps;

export const ChatAIButton = (props: ChatAIButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="black"
      css={styles.button}
      title={t('Search by AI')}
      prefixIcon={{ icon: 'chat-bot', color: THEME.icon.colors.white, size: 24, css: { marginRight: '16px' } }}
      {...props}
    />
  );
};

const styles = {
  button: css({
    height: '48px',
    fontWeight: 600,
    fontSize: '16px',
    cursor: 'pointer',
    padding: '0 16px',
    lineHeight: '120%',
    fontStyle: 'normal',
    borderRadius: '8px'
  })
};
