import { useTranslation } from 'react-i18next';
import { CheckBox, CheckBoxProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

type SelectAllProps = CheckBoxProps;

export const SelectAll = (props: SelectAllProps) => {
  const { t } = useTranslation();

  return (
    <CheckBox
      label={t('Select all')}
      css={{ padding: '8px', backgroundColor: THEME.background.colors.white, borderBottom: '1px solid #e4e4e4' }}
      {...props}
    />
  );
};
