import { EngagementDetail, NewCampaignReportSummary } from '@/graphql';
import { PostReportSummary } from '../../../PostReport';

export interface EngagementReportSummaryProps {
  engagement: EngagementDetail;
  data?: NewCampaignReportSummary;
}
export const EngagementReportSummary = ({
  data,
  engagement: { socialMedias, trackingOptions }
}: EngagementReportSummaryProps) => (
  <PostReportSummary
    data={data}
    currency={data?.currency}
    socialMedias={socialMedias}
    trackingOptions={trackingOptions || []}
  />
);
