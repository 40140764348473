import { z } from 'zod';
import { SocialAccountStatus } from '@/graphql';
import { ToggleOption } from '@/shared/types';
import { BaseValidations } from '@/shared/validations';
import {
  PostsPriceSchema,
  InternalMemoSchema,
  AddressInformationSchema,
  InfluencerManagersSchema,
  PaymentInformationSchema
} from '../shared';
import { BrandContentPermissionsSchema } from '../shared';
import { InfluencerSharedKeys } from '../shared/types';
import { SocialAccountsSchema } from './SocialAccounts';

export const InfluencerFormSchema = z
  .object({
    [InfluencerSharedKeys.regionId]: z.string(),
    [InfluencerSharedKeys.introduce]: z.string(),
    [InfluencerSharedKeys.dateOfBirth]: z.string(),
    [InfluencerSharedKeys.isProAccount]: z.boolean(),
    [InfluencerSharedKeys.partnerIds]: z.array(z.string()),
    [InfluencerSharedKeys.name]: BaseValidations.NameSchema,
    [InfluencerSharedKeys.notificationSetting]: z.boolean(),
    [InfluencerSharedKeys.gender]: BaseValidations.GenderSchema,
    [InfluencerSharedKeys.talentAgencyIds]: z.array(z.string()),
    [InfluencerSharedKeys.brandAccount]: z.nativeEnum(ToggleOption),
    [InfluencerSharedKeys.status]: z.nativeEnum(SocialAccountStatus),
    [InfluencerSharedKeys.email]: BaseValidations.EmailSchemaOptional,
    [InfluencerSharedKeys.countryId]: BaseValidations.CountryIdSchema,
    [InfluencerSharedKeys.campaignDisplay]: z.nativeEnum(ToggleOption),
    [InfluencerSharedKeys.defaultEngagementSelectionReason]: z.string(),
    [InfluencerSharedKeys.talentAgencyOrPartnerIds]: z.array(z.string()),
    [InfluencerSharedKeys.phoneNumber]: BaseValidations.PhoneNumberOptionalSchema,
    [InfluencerSharedKeys.categoryIds]: BaseValidations.getMaxCategoryIdsSchema()
  })
  .and(SocialAccountsSchema)
  .and(PostsPriceSchema)
  .and(InternalMemoSchema)
  .and(AddressInformationSchema)
  .and(InfluencerManagersSchema)
  .and(PaymentInformationSchema)
  .and(BrandContentPermissionsSchema);
