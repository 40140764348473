import { useYoutubeCMSFilter } from '@/shared/organisms';
import { getOffset, getValuableVariables } from '@/shared/utils';
import {
  useAllYoutubeCmsEstimateAssetsQuery,
  useAllYoutubeCmsEstimateChannelsQuery,
  useYoutubeCmsEstimateAssetsSummaryQuery,
  useYoutubeCmsEstimateChannelsSummaryQuery
} from '@/graphql';

export const useYoutubeCMSEstimatedData = () => {
  const {
    page,
    limit,
    isSegmentAssets,
    isSegmentChannels,
    filter: { keyword, month }
  } = useYoutubeCMSFilter();

  const variables = getValuableVariables({ keyword, month });
  const listVariables = { ...variables, limit, offset: getOffset(page, limit) };

  const { data: channelSummaryData, loading: fetchingChannelSummary } = useYoutubeCmsEstimateChannelsSummaryQuery({
    variables,
    skip: !isSegmentChannels,
    notifyOnNetworkStatusChange: true
  });
  const { data: assetsSummaryData, loading: fetchingAssetsSummary } = useYoutubeCmsEstimateAssetsSummaryQuery({
    variables,
    skip: !isSegmentAssets,
    notifyOnNetworkStatusChange: true
  });
  const { data: channelsData, loading: fetchingYtChannels } = useAllYoutubeCmsEstimateChannelsQuery({
    skip: !isSegmentChannels,
    variables: listVariables,
    notifyOnNetworkStatusChange: true
  });
  const { data: assetsData, loading: fetchingYtAssets } = useAllYoutubeCmsEstimateAssetsQuery({
    skip: !isSegmentAssets,
    variables: listVariables,
    notifyOnNetworkStatusChange: true
  });

  return {
    assetsData: assetsData?.allYoutubeCmsEstimateAssets || [],
    channelsData: channelsData?.allYoutubeCmsEstimateChannels || [],
    fetchingData: fetchingYtChannels || fetchingYtAssets,
    fetchingSummary: fetchingChannelSummary || fetchingAssetsSummary,
    totalRecords: isSegmentAssets
      ? assetsData?.allYoutubeCmsEstimateAssetsCount?.totalNumber
      : channelsData?.allYoutubeCmsEstimateChannelsCount?.totalNumber,
    summary: isSegmentAssets
      ? assetsSummaryData?.youtubeCmsEstimateAssetsSummary || undefined
      : channelSummaryData?.youtubeCmsEstimateChannelsSummary || undefined
  };
};
