import { useNavigate, useParams } from '@tanstack/react-router';
import { MarketplacePostForm } from '@/shared/organisms';
import { useParamsSearch } from '@/shared/hooks';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';
import { MarketplaceEditPostSearchSchemaType } from './schemaTypes';

export const MarketplaceEditPost = () => {
  const navigate = useNavigate({ from: '/marketplace/$id/posts' });
  const params = useParams({ from: '/_private-routes/marketplace/$id/posts/$postId' });
  const { marketplaceId, isAffiliateCampaign } = useMarketplaceDetailsContext();
  const search = useParamsSearch<MarketplaceEditPostSearchSchemaType>();

  const postId = Number(params.postId ?? '');

  const goToPostsList = () => {
    navigate({
      to: '/marketplace/$id/posts',
      search,
      replace: true
    });
  };

  if (!postId) {
    goToPostsList();

    return null;
  }

  return (
    <MarketplacePostForm
      postId={postId}
      onClose={goToPostsList}
      marketplaceId={marketplaceId}
      isAffiliateCampaign={isAffiliateCampaign}
      isDraftPost={search.filter.draftPost === Boolean(true).toString()}
    />
  );
};
