import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { SelectorField } from '@/shared/molecules';
import { InfluencersTabType } from '@/shared/types';
import { useTranslateOptions } from '@/shared/hooks';
import {
  AUDIENCE_RATIO_OPTIONS,
  AUDIENCE_GENDER_OPTIONS,
  XHS_AUDIENCE_AGE_GROUPS_OPTIONS,
  TIKTOK_AUDIENCE_AGE_GROUPS_OPTIONS,
  DOUYIN_AUDIENCE_AGE_GROUPS_OPTIONS
} from '@/shared/constants';
import { InfluencersFilterFormKeys, InfluencersFilterFormValues } from '../types';

export const AudienceDemographics = () => {
  const { t } = useTranslation();
  const genderOptions = useTranslateOptions(AUDIENCE_GENDER_OPTIONS);
  const { watch, setValue } = useFormContext<InfluencersFilterFormValues>();

  const values = watch();
  const audienceAgeOptions = (() => {
    switch (values.tab) {
      case InfluencersTabType.TIKTOK:
        return TIKTOK_AUDIENCE_AGE_GROUPS_OPTIONS;
      case InfluencersTabType.DOUYIN:
        return DOUYIN_AUDIENCE_AGE_GROUPS_OPTIONS;
      case InfluencersTabType.XHS:
        return XHS_AUDIENCE_AGE_GROUPS_OPTIONS;
      default:
        return [];
    }
  })();

  return (
    <>
      <div css={{ display: 'flex', gap: '8px' }}>
        <SelectorField<InfluencersFilterFormKeys>
          title={t('Selector.Age')}
          options={audienceAgeOptions}
          name="demographicAge"
          onChange={(field, val) => {
            setValue(field, val);
            if (!val) {
              setValue('demographicAgeMinRate', '');
            }
          }}
        />
        <SelectorField<InfluencersFilterFormKeys>
          title={t('Selector.Age Ratio')}
          name="demographicAgeMinRate"
          options={AUDIENCE_RATIO_OPTIONS}
          disabled={!values.demographicAge}
          singleSelectorProps={{ hideDeselectOption: true }}
        />
      </div>
      <div css={{ display: 'flex', gap: '8px' }}>
        <SelectorField<InfluencersFilterFormKeys>
          title={t('Selector.Gender')}
          name="demographicGender"
          options={genderOptions}
          onChange={(field, val) => {
            setValue(field, val);
            if (!val) {
              setValue('demographicGenderMinRate', '');
            }
          }}
        />
        <SelectorField<InfluencersFilterFormKeys>
          title={t('Selector.Gender Ratio')}
          name="demographicGenderMinRate"
          options={AUDIENCE_RATIO_OPTIONS}
          disabled={!values.demographicGender}
        />
      </div>
    </>
  );
};
