import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerInstagramAnalyticsReelDetails.graphql';
import { Query, QueryinfluencerInstagramAnalyticsReelDetailsArgs } from '../../__generated__/globalTypes';

export const useInfluencerInstagramAnalyticsReelDetailsQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerInstagramAnalyticsReelDetailsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerInstagramAnalyticsReelDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerInstagramAnalyticsReelDetailsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerInstagramAnalyticsReelDetailsArgs>
): QueryResult<Query, QueryinfluencerInstagramAnalyticsReelDetailsArgs> & {
  getInfluencerInstagramAnalyticsReelDetails: LazyQueryExecFunction<
    Query,
    QueryinfluencerInstagramAnalyticsReelDetailsArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerInstagramAnalyticsReelDetails, result] = useLazyQuery<
    Query,
    QueryinfluencerInstagramAnalyticsReelDetailsArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerInstagramAnalyticsReelDetails, ...result };
};

export const useInfluencerInstagramAnalyticsReelDetailsPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerInstagramAnalyticsReelDetails = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerInstagramAnalyticsReelDetailsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerInstagramAnalyticsReelDetailsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerInstagramAnalyticsReelDetails };
};
