import { CampaignType, useEngagementCampaignReportSummaryQuery } from '@/graphql';
import { getValuableVariables } from '@/shared/utils';
import {
  PostReportProvider,
  EngagementReportGrid,
  EngagementReportTable,
  EngagementReportFilter,
  EngagementReportSummary
} from '@/shared/organisms';
import { usePostReportSearch } from '@/shared/molecules';
import { PostReportMode } from '@/shared/types';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';

export const EngagementReport = () => {
  const { engagement, isOnlyTikTokTtcm, hasTrackingTune, hasTrackingReferral } = useEngagementDetailsContext();

  const { filter, setFilter, state } = usePostReportSearch();
  const { data, previousData, loading } = useEngagementCampaignReportSummaryQuery({
    variables: getValuableVariables({
      keyword: filter.keyword,
      endDate: filter.endDate,
      campaignId: engagement.id,
      startDate: filter.startDate
    })
  });

  const result = loading ? previousData : data;
  const summaryData = result?.engagementCampaignReportSummary || undefined;
  const totalRecords = summaryData?.postCount || 0; // TODO: remove after BE totalCount

  return (
    <PostReportProvider
      value={{
        isOnlyTikTokTtcm,
        campaignType: CampaignType.ENGAGEMENT,
        hasTracking: hasTrackingTune || hasTrackingReferral
      }}
    >
      <EngagementReportFilter filter={filter} segmentState={state} setFilter={setFilter} engagement={engagement} />

      <EngagementReportSummary data={summaryData} engagement={engagement} />

      {filter.mode === PostReportMode.GRID ? (
        <EngagementReportGrid engagement={engagement} />
      ) : (
        <EngagementReportTable engagement={engagement} totalRecords={totalRecords} />
      )}
    </PostReportProvider>
  );
};
