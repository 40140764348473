// TODO: Refactor later
import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';
import { Option } from '@/shared/types';
import { ActionButtons } from '../../ActionButtons';
import { BaseSelector } from '../BaseSelector';
import { MenuItemsWithCheckbox } from '../MultiSelector/MenuItemsWithCheckbox';
import { SelectAll } from '../MultiSelector/SelectAll';
import { getInitialSelectedItems } from '../MultiSelector/utils';

export interface TableColumnSelectorProps<T extends string = string> {
  className?: string;
  value?: T[];
  name: string;
  options: readonly Option[];
  hasError?: boolean;
  disabled?: boolean;
  placeholder?: string | null;
  hasSelectAll?: boolean;
  onChange?: (items: T[]) => void;
  onClose?: () => void;
  canOnlyAddMore?: boolean;
  initialValues?: T[];
  closeModal: () => void;
  defaultFields: T[];
  handleSubmit: () => void;
}

export const TableColumnSelector = <T extends string>(props: TableColumnSelectorProps<T>) => {
  const {
    name,
    value = [],
    options,
    hasError,
    disabled,
    hasSelectAll = true,
    placeholder,
    onChange,
    onClose,
    canOnlyAddMore,
    initialValues,
    closeModal,
    handleSubmit
  } = props;

  // Items
  const selectedValue = getInitialSelectedItems(value as string[], options);
  const setSelectedValue = (items: readonly Option[]) => {
    if (onChange) {
      onChange(items.map((item) => item.value) as T[]);
    }
  };
  const removeValue = (item: Option) => {
    const isInitalValue = initialValues?.includes(item?.value as T);
    if (canOnlyAddMore && isInitalValue) {
      return;
    }

    setSelectedValue(selectedValue.filter((i) => i !== item));
  };

  return (
    <BaseSelector
      name={name}
      items={{ selectedValue, setSelectedValue, removeValue }}
      hasError={hasError}
      options={options}
      disabled={disabled}
      disabledInputBox={false}
      placeholder={selectedValue.length === 0 ? placeholder : ''}
      onClose={onClose}
      MenuControlIcon={() => <Icon css={styles.icon} icon="search" size={14} />}
      renderSelectedValue={() => null}
      renderMenu={({ items, search }) => {
        const isSelectedAll = search.filteredValue.every((v) => items.selectedValue.includes(v));
        const isSelectedNoting = items.selectedValue.length === 0;

        const handleSelectAll = () => {
          if (disabled) {
            return;
          }
          // can unselect all values except initialValues
          const allUnselectedValues = canOnlyAddMore
            ? search.filteredValue.filter((val) => initialValues?.includes(val.value as T))
            : [];
          items.setSelectedValue(isSelectedAll ? allUnselectedValues : search.filteredValue);
        };

        const handleClickItem = (item: Option) => {
          if (disabled) {
            return;
          }
          const isSelected = items.selectedValue.includes(item);

          // Remove Item
          if (isSelected) {
            items.removeValue(item);

            return;
          }

          // Add Item
          items.setSelectedValue([...items.selectedValue, item]);
        };

        return (
          <>
            {hasSelectAll && (
              <SelectAll
                label="All"
                disabled={disabled}
                onClick={handleSelectAll}
                checked={!isSelectedNoting}
                indeterminate={!isSelectedNoting && !isSelectedAll}
              />
            )}
            <div css={styles.menu}>
              <MenuItemsWithCheckbox
                disabled={disabled}
                options={search.filteredValue}
                handleClick={handleClickItem}
                checkIsSelected={(option: Option) =>
                  items.selectedValue.map((item) => item.value).includes(option.value)
                }
                initialValues={initialValues as string[]}
              />
            </div>
            <ActionButtons
              hasTopBorder={true}
              primaryButtonProps={{
                onClick: () => {
                  handleSubmit();
                  closeModal();
                }
              }}
              secondaryButtonProps={{
                onClick: closeModal
              }}
            />
          </>
        );
      }}
      isAlwaysOpen
      css={{ paddingLeft: '20px' }}
    />
  );
};
const styles = {
  menu: css({
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative'
  }),
  icon: css({
    top: '50%',
    left: '8px',
    cursor: 'pointer',
    position: 'absolute',
    transform: 'translateY(-50%)'
  })
};
