import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionsOrGroups, SelectInstance } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { Option } from '@/shared/types';
import { ClearIndicator, DropdownIndicator } from '../Selector';
import { getDefaultSingleSelectStyles } from '../Selector/SingleSelector/utils';

export interface CreatableSingleSelectorProps extends CreatableProps<Option, false, any> {
  height?: string;
  hasError?: boolean;
  hideDeselectOption?: boolean;
  setFieldValue?: (field: string, value: string) => void;
  loadOptions?: (inputValue: string, callback: (options: OptionsOrGroups<Option, any>) => void) => Promise<any> | void;
}
export type CreatableSelectRef = ForwardedRef<SelectInstance<Option<string>, false, any>>;

export const CreatableSingleSelector = forwardRef(
  (
    {
      value,
      options,
      hasError,
      hideDeselectOption,
      setFieldValue,
      onChange,
      height,
      styles,
      isDisabled,
      ...props
    }: CreatableSingleSelectorProps,
    ref: CreatableSelectRef
  ) => {
    const { t } = useTranslation();
    const handleOnChange: CreatableProps<Option, false, any>['onChange'] = (selectedOption, action) => {
      if (onChange) {
        onChange(selectedOption, action);
      } else if (!!setFieldValue && action.name) {
        setFieldValue(action.name, selectedOption?.value || '');
      }
    };

    return (
      <CreatableSelect
        ref={ref}
        styles={{
          ...getDefaultSingleSelectStyles({
            hasError,
            disabled: isDisabled,
            height
          }),
          ...styles
        }}
        onChange={handleOnChange}
        formatCreateLabel={(userInput) => t('Custom') + `: ${userInput}`}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: (indicatorProps) => (!indicatorProps.hasValue ? <DropdownIndicator /> : null),
          ClearIndicator: (clearProps) => (clearProps.hasValue ? <ClearIndicator {...clearProps} ref={ref} /> : null)
        }}
        {...props}
        value={value || null}
        options={options}
        isMulti={false}
        isDisabled={isDisabled}
        isClearable={!hideDeselectOption}
      />
    );
  }
);
CreatableSingleSelector.displayName = 'CreatableSingleSelector';
