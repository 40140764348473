import { useTranslation } from 'react-i18next';
import { useInfluencerAddRoute } from '@/shared/hooks';
import { ListEmpty, RenderDataWithFallback } from '@/shared/atoms';
import { useXhsInfluencersContext } from '../XhsInfluencersContext';
import { ProfileWidget } from './ProfileWidget';

export const XhsInfluencersList = () => {
  const { t } = useTranslation();
  const { allowAddInfluencer, addInfluencerRoute } = useInfluencerAddRoute();
  const { loading, listRecords, getListSelectButtonProps } = useXhsInfluencersContext();

  return (
    <RenderDataWithFallback
      loading={loading}
      hasNoData={listRecords.length === 0}
      noDataNode={
        <ListEmpty
          linkProps={allowAddInfluencer ? { label: t('add an influencer'), href: addInfluencerRoute } : undefined}
        />
      }
    >
      {listRecords.map((profile, index) => (
        <ProfileWidget
          key={`influencer-${profile.id}-${index}`}
          profile={profile}
          css={{ '.info-group': { marginTop: '4px' } }}
          selectButtonProps={getListSelectButtonProps?.(profile)}
        />
      ))}
    </RenderDataWithFallback>
  );
};
