import { useTranslation } from 'react-i18next';
import { AutoManagedCommissionStatus, useAutoManagedCommissionFixedDatesQuery } from '@/graphql';
import { Notice } from '@/shared/atoms';
import { useTranslateOptions } from '@/shared/hooks';
import { BasicFilterWrapper, TextSearchField, SelectorField } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { AutoManagedCommissionsFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
  autoManagedCampaignId: number;
}

export const Template = ({ onSubmit, autoManagedCampaignId }: TemplateProps) => {
  const { t } = useTranslation();
  const { data } = useAutoManagedCommissionFixedDatesQuery({ variables: { id: autoManagedCampaignId } });

  const monthOptions: Option[] =
    data?.autoManagedCommissionFixedDates?.map((date, i) => ({
      value: `${date}_${i}`,
      label: typeof date === 'string' ? date : '-'
    })) || [];
  const statusOptions = useTranslateOptions([
    { label: t<string>('Approved'), value: AutoManagedCommissionStatus.APPROVED },
    { label: t<string>('Not Approved'), value: AutoManagedCommissionStatus.UNAPPROVED }
  ]);

  return (
    <>
      <Notice
        type="info"
        css={{ marginBottom: '16px' }}
        title={t('Commission Information')}
        description={t('InfluencerCommissionFeeNotice')}
      />

      <BasicFilterWrapper css={{ padding: 0 }}>
        <TextSearchField<AutoManagedCommissionsFilterFormKeys>
          name="keyword"
          onSubmit={onSubmit}
          placeholder={t<string>('Search.InfluencerFilter')}
        />

        <SelectorField<AutoManagedCommissionsFilterFormKeys>
          name="month"
          onSubmit={onSubmit}
          options={monthOptions}
          css={{ maxWidth: '198px' }}
          placeholder={t<string>('Selector.Month')}
        />

        <SelectorField<AutoManagedCommissionsFilterFormKeys>
          name="status"
          onSubmit={onSubmit}
          options={statusOptions}
          css={{ maxWidth: '198px' }}
          placeholder={t<string>('Selector.Status')}
        />
      </BasicFilterWrapper>
    </>
  );
};
