import { InfluencerNewProfileHeaderV2, useAllInstagramSimilarInfluencersV3Query } from '@/graphql';
import { DEFAULT_SIMILAR_INFLUENCERS_LIMITATION } from '@/shared/constants';
import { useAllAvailableCountryOptions } from '@/shared/hooks';
import { useInfluencerProfileSimilarInfluencersFilter } from '../../hooks';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerProfileSimilarInfluencers, InfluencerProfileSimilarInfluencersProps } from '../shared';

const DEFAULT_FETCH_LIMITATION = DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.PROFILE.FETCH;

export interface InfluencerInstagramProfileSimilarInfluencersProps {
  className?: string;
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerInstagramProfileSimilarInfluencers = ({
  socialAccount,
  influencerProfile: { country }
}: InfluencerInstagramProfileSimilarInfluencersProps) => {
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const { data, loading, refetch } = useAllInstagramSimilarInfluencersV3Query({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { genders: [], accountId: socialAccount.id, limit: DEFAULT_FETCH_LIMITATION },
    skip: allAvailableCountryOptions.findIndex((option) => option.value === country.id) === -1
  });
  const { filter, handleFilterChange } = useInfluencerProfileSimilarInfluencersFilter({
    refetch,
    limit: DEFAULT_FETCH_LIMITATION
  });

  const similarInfluencers: InfluencerProfileSimilarInfluencersProps['data'] =
    data?.allInstagramSimilarInfluencersV3?.map((infl) => ({
      id: infl.id,
      name: infl.name,
      avatar: infl.avatar,
      gender: infl.gender,
      countryName: infl.country.name,
      socialAccountId: infl.socialAccountId,
      followers: infl.socialAccountFollowers,
      engagementRate: infl.socialAccountEngagementRate
    })) || [];

  return (
    <InfluencerProfileSimilarInfluencers
      filter={filter}
      loading={loading}
      socialMedia="INSTAGRAM"
      data={similarInfluencers}
      onFilterChange={handleFilterChange}
    />
  );
};
