import { z } from 'zod';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';

export const YtVideosSearchSchema = z.object({
  filter: z
    .object({
      keyword: z.string().optional(),
      name: z.string().optional()
    })
    .default({}),
  sort: SearchFilterDefaults.sort(YoutubeAnalyticsPostsSortOrder).default({}),
  ...SearchFilterDefaults.pageLimit()
});
export type YtVideosSearchSchemaType = z.infer<typeof YtVideosSearchSchema>;
