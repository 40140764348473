import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { FormStyle } from '@/shared/atoms';
import { formatIntNumber } from '@/shared/utils';
import { Option, SortOption } from '@/shared/types';
import { SocialAccountInfluencerListSortField } from '@/graphql';
import { LIMIT_100, LIMIT_50, LIMIT, THEME } from '@/shared/constants';
import { BasicFilterWrapper, SelectorField } from '@/shared/molecules';
import { TikTokCampaignInfluencersSortFormKeys, TikTokCampaignInfluencersSortFormValues } from './types';

export interface TemplateProps {
  totalRecords?: number;
  onSubmit?: () => void;
  sortOptions: SortOption<SocialAccountInfluencerListSortField>[];
}

export const Template = ({ onSubmit, sortOptions, totalRecords = 0 }: TemplateProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<TikTokCampaignInfluencersSortFormValues>();

  const limitOptions: Option[] = [
    { value: LIMIT.toString(), label: t<string>('View_limit', { count: LIMIT }) },
    { value: LIMIT_50.toString(), label: t<string>('View_limit', { count: LIMIT_50 }) },
    { value: LIMIT_100.toString(), label: t<string>('View_limit', { count: LIMIT_100 }) }
  ];

  const handleSortChange = (
    field: TikTokCampaignInfluencersSortFormKeys,
    value: string,
    options: SortOption<SocialAccountInfluencerListSortField>[] = []
  ) => {
    const selectedOption = options.find((o) => o.value === value);
    setValue(field, value);
    setValue('field', selectedOption?.field || null);
    setValue('order', selectedOption?.order || null);
  };

  return (
    <BasicFilterWrapper alignItems="center" css={styles.wrapper}>
      <FormStyle.FieldWrapper css={{ flex: 'none' }}>
        <p css={{ fontSize: '14px', fontWeight: 600 }}>
          <Trans i18nKey="Accounts found" values={{ count: formatIntNumber(totalRecords) }} />
        </p>
      </FormStyle.FieldWrapper>

      <SelectorField<TikTokCampaignInfluencersSortFormKeys, SortOption<SocialAccountInfluencerListSortField>>
        name="sort"
        onSubmit={onSubmit}
        options={sortOptions}
        onChange={handleSortChange}
        placeholder={t<string>('Selector.Sort')}
        css={{ marginLeft: 'auto', minWidth: '108px', maxWidth: 'max-content' }}
        singleSelectorProps={{ type: 'radio', styles: { menu: (base) => ({ ...base, width: 'max-content' }) } }}
      />

      <SelectorField<TikTokCampaignInfluencersSortFormKeys>
        name="limit"
        onSubmit={onSubmit}
        options={limitOptions}
        css={{ maxWidth: '113px' }}
        singleSelectorProps={{ placeholder: '', hideDeselectOption: true }}
      />
    </BasicFilterWrapper>
  );
};

const styles = {
  wrapper: css({
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: THEME.border.base,
    borderBottom: THEME.border.base
  })
};
