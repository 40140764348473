import { PropsWithChildren, forwardRef } from 'react';
import { ClearIndicatorProps, SelectInstance } from 'react-select';
import { Icon, clearIconStyles } from '@/shared/atoms';

export const ClearIndicator = forwardRef<
  SelectInstance<any>,
  PropsWithChildren<ClearIndicatorProps<any, boolean, any>>
>((props, ref) => (
  <div
    {...props.innerProps}
    onClick={(e) => {
      e.preventDefault();
      props?.clearValue();
      if (typeof ref === 'object') {
        ref?.current?.blur();
      }
    }}
    css={clearIconStyles.iconWrapper}
    className={props.className}
  >
    <Icon icon="close" size="7px" color="#fff" />
  </div>
));
ClearIndicator.displayName = 'ClearIndicator';
