import { usePermissions } from '@/auth';
import { useGetUserPermissionsQuery } from '@/graphql';

export const useLoggedInUserPermissions = () => {
  const { dispatchPermissions, canCallCustomPermissions } = usePermissions();
  const { data, loading } = useGetUserPermissionsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !canCallCustomPermissions,
    onCompleted: ({ getUserPermissions }) => {
      dispatchPermissions(getUserPermissions?.permissions || null);
    }
  });

  return {
    permissionLoading: loading,
    permissions: data?.getUserPermissions?.permissions || null
  };
};
