import { ChatBoxCoordinates } from './types';

export const formatNumber = (num?: number | null) => Math.ceil(num || 0);
export const formatPositiveNumber = (num?: number | null) => Math.max(0, num || 0);

export class ChatBoxRndCoordinates {
  private rect: DOMRect;
  private coordinates: ChatBoxCoordinates;
  private gap = 24;
  private isSmallWidth = false;
  private isSmallHeight = false;
  private viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  private viewportHeight = window.innerHeight || document.documentElement.clientHeight;

  constructor(rect: DOMRect) {
    this.rect = rect;
    this.coordinates = {
      top: formatPositiveNumber(this.rect.top),
      left: formatPositiveNumber(this.rect.left),
      right: formatPositiveNumber(this.viewportWidth - this.rect.x - this.rect.width),
      bottom: formatPositiveNumber(this.viewportHeight - this.rect.y - this.rect.height)
    };
    this.isSmallWidth = this.rect.width + this.gap > this.viewportWidth;
    this.isSmallHeight = this.rect.height + this.gap > this.viewportHeight;

    this.getValue();
  }

  // Keep nearest edges only
  // Instead of holding full 4 top/left/right/bottom positions, we only keep 2 of them.
  // This will support toggling behavior the chat box & chat button smoother and reduce flows for checking if toggled UI is in the viewport or not
  getNearestEgdes() {
    const isOnTheTop = formatPositiveNumber(this.coordinates.top) + this.rect.height / 2 <= this.viewportHeight / 2;
    const isOnTheLeft = formatPositiveNumber(this.coordinates.left) + this.rect.width / 2 <= this.viewportWidth / 2;

    if (this.isSmallHeight) {
      this.coordinates.top = this.coordinates.top ?? 0;
      this.coordinates.bottom = undefined;
    } else if (isOnTheTop) {
      this.coordinates.bottom = undefined;
    } else {
      this.coordinates.top = undefined;
    }

    if (this.isSmallWidth) {
      this.coordinates.left = this.coordinates.left ?? 0;
      this.coordinates.right = undefined;
    } else if (isOnTheLeft) {
      this.coordinates.right = undefined;
    } else {
      this.coordinates.left = undefined;
    }

    return this.coordinates;
  }

  checkInViewport() {
    return (
      this.rect.top >= 0 &&
      this.rect.left >= 0 &&
      this.rect.right <= this.viewportWidth &&
      this.rect.bottom <= this.viewportHeight
    );
  }

  // Align chatbox's position inside the viewport
  alignInViewport() {
    // Adjust left/right
    if (this.isSmallWidth) {
      this.coordinates.left = 0;
      this.coordinates.right = undefined;
    } else if (this.rect.left < 0) {
      this.coordinates.left = this.gap;
      this.coordinates.right = this.viewportWidth - this.coordinates.left - this.rect.width;
    } else if (this.rect.right > this.viewportWidth) {
      this.coordinates.right = this.gap;
      this.coordinates.left = this.viewportWidth - this.coordinates.right - this.rect.width;
    }

    // Adjust top/bottom
    if (this.isSmallHeight) {
      this.coordinates.top = 0;
      this.coordinates.bottom = undefined;
    } else if (this.rect.top < 0) {
      this.coordinates.top = this.gap;
      this.coordinates.bottom = this.viewportHeight - this.coordinates.top - this.rect.height;
    } else if (this.rect.bottom > this.viewportHeight) {
      this.coordinates.bottom = this.gap;
      this.coordinates.top = this.viewportHeight - this.coordinates.bottom - this.rect.height;
    }

    return this;
  }

  getValue() {
    return this.coordinates;
  }

  getTopLeft(): ChatBoxCoordinates {
    return { top: this.coordinates.top, left: this.coordinates.left };
  }
}
