import { Order, InstagramUGCPostsSortOrder } from '@/graphql';
import { defaultUGCPostsHashtags } from '../../shared';
import { AnalyticsInstagramUGCPostsFilterFormValues } from './schemaTypes';

export const defaultInstagramUGCPostsFilterFormValues: AnalyticsInstagramUGCPostsFilterFormValues = {
  status: '',
  endDate: '',
  keyword: '',
  startDate: '',
  order: Order.DESC,
  field: InstagramUGCPostsSortOrder.POST_DATE,
  hashtagId: defaultUGCPostsHashtags.mentioned.id,
  postType: defaultUGCPostsHashtags.mentioned.postType
};
