import { FormProvider } from '@/shared/atoms';
import { FormAction } from '@/shared/types';

import { SettingsFormSchema } from './schema';
import { Template } from './Template';
import { SettingsFormValues } from './types';
import { defaultSettingsFormValues } from './utils';

export type SettingsFormProps = FormAction<SettingsFormValues>;

export const SettingsForm = ({ onSubmit, defaultValues = defaultSettingsFormValues }: SettingsFormProps) => (
  <FormProvider onSubmit={onSubmit} zodSchema={SettingsFormSchema} defaultValues={defaultValues}>
    <Template />
  </FormProvider>
);

export * from './types';
export { getSettingsFormValues } from './utils';
