import { useSudoLimitedLoginMutation } from '@/graphql';
import { Button } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';

declare const ANY_CREATOR_ORIGIN: string;

export const AnyCreatorAccessPreviewButton = ({ userId }: { userId: number }) => {
  const { callSudoLimitedLogin, loading } = useSudoLimitedLoginMutation();
  const { enqueueSnackbar, t } = useQueryHelper();

  const handleSudoLimitedLogin = async () => {
    try {
      const { data } = await callSudoLimitedLogin({
        variables: {
          input: {
            userId
          }
        }
      });
      if (data?.sudoLimitedLogin) {
        window.open(
          `${ANY_CREATOR_ORIGIN}/creator_access_preview?token=${data.sudoLimitedLogin.token}`,
          '_blank',
          'noopener,noreferrer'
        );
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <Button
      variant="black"
      onClick={handleSudoLimitedLogin}
      loading={{
        showIcon: true,
        status: loading
      }}
      css={{
        zIndex: 1000,
        position: 'fixed',
        bottom: 28,
        right: 28,
        borderRadius: '32px',
        height: '48px',
        fontSize: '14px',
        fontWeight: 600,
        padding: '0 20px',
        cursor: 'pointer',
        fontFamily: 'Inter'
      }}
      title={t('Mobile preview')}
      prefixIcon={{
        icon: 'preview',
        size: '24px'
      }}
    />
  );
};
