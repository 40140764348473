import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { BasicFilter } from './BasicFilter';
import { useCampaignPostsDiscoveryFilter } from './hooks';

export const CampaignPostsDiscoveryFilter = () => {
  const { filter, setFilterForm } = useCampaignPostsDiscoveryFilter();

  return (
    <FilterWrapper>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<BasicFilter />} />
    </FilterWrapper>
  );
};

export { useCampaignPostsDiscoveryFilter };
export * from './schemaTypes';
