import { z } from 'zod';
import { SearchFilterDefaults } from '@/shared/validations';

export const AnalyticsYoutubeCommentsFilterSchema = z.object({
  keyword: z.string().optional(),
  brandAccountName: z.string().optional()
});
export const AnalyticsYoutubeCommentsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(AnalyticsYoutubeCommentsFilterSchema, {}),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsYoutubeCommentsSearchSchemaType = z.infer<typeof AnalyticsYoutubeCommentsSearchSchema>;
export type AnalyticsYoutubeCommentsFilterSchemaType = z.infer<typeof AnalyticsYoutubeCommentsFilterSchema>;
