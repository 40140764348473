import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateTtcmPost.graphql';
import { Mutation, MutationupdateTtcmPostArgs } from '../../__generated__/globalTypes';

export const useUpdateTtcmPostMutation = (options?: MutationHookOptions<Mutation, MutationupdateTtcmPostArgs>) => {
  const [callUpdateTtcmPost, result] = useMutation<Mutation, MutationupdateTtcmPostArgs>(MUTATION, options);

  return { callUpdateTtcmPost, ...result };
};

export type UpdateTtcmPostMutationFunction = Mutation['updateTtcmPost'];
