import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListEmpty, RenderDataWithFallback } from '@/shared/atoms';
import { useInfluencerAddRoute, useSelectItemIds } from '@/shared/hooks';
import { useInstagramInfluencersContext } from '../InstagramInfluencersContext';
import { ProfileWidget } from './ProfileWidget';
import { SimilarInfluencersList } from './SimilarInfluencersList';

export const InstagramInfluencersList = () => {
  const { t } = useTranslation();
  const { selectedItemIds, selectItem } = useSelectItemIds<string>();
  const { allowAddInfluencer, addInfluencerRoute } = useInfluencerAddRoute();
  const { loading, listRecords, getListSelectButtonProps } = useInstagramInfluencersContext();

  return (
    <RenderDataWithFallback
      loading={loading}
      hasNoData={listRecords.length === 0}
      noDataNode={
        <ListEmpty
          linkProps={allowAddInfluencer ? { label: t('add an influencer'), href: addInfluencerRoute } : undefined}
        />
      }
    >
      {listRecords.map((profile, index) => {
        const isSectionOpened = selectedItemIds.includes(profile.socialAccountId);

        return (
          <React.Fragment key={`influencer-${profile.id}-${index}`}>
            <ProfileWidget
              profile={profile}
              isSectionOpened={isSectionOpened}
              css={{ '.info-group': { marginTop: '4px' } }}
              selectButtonProps={getListSelectButtonProps?.(profile)}
              toggleSimilarInfluencersSection={() => selectItem(profile.socialAccountId)}
            />
            {profile.socialAccountId ? (
              <SimilarInfluencersList
                isSectionOpened={isSectionOpened}
                socialAccountId={profile.socialAccountId}
                getListSelectButtonProps={getListSelectButtonProps}
              />
            ) : null}
          </React.Fragment>
        );
      })}
    </RenderDataWithFallback>
  );
};
