import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateAdvertiserWithUserV2.graphql';
import { Mutation, MutationcreateAdvertiserWithUserV2Args } from '../../__generated__/globalTypes';

export const useCreateAdvertiserWithUserV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationcreateAdvertiserWithUserV2Args>
) => {
  const [callCreateAdvertiserWithUserV2, result] = useMutation<Mutation, MutationcreateAdvertiserWithUserV2Args>(
    MUTATION,
    options
  );

  return { callCreateAdvertiserWithUserV2, ...result };
};

export type CreateAdvertiserWithUserV2MutationFunction = Mutation['createAdvertiserWithUserV2'];
