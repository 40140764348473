import { useParams, useSearch } from '@tanstack/react-router';
import { useTikTokSpecialPermissions, useUserRoles } from '@/auth';
import { InfluencerAvatar, TikTokSpecialPostStatus } from '@/shared/molecules';
import { CampaignSocialMediaType, TikTokSpecialCampaignPost, TikTokSpecialCampaignPostStatus } from '@/graphql';
import { useGenerateInfluencerProfileLink, useQueryHelper, useSelectItems } from '@/shared/hooks';
import { Anchor, RowProps, CheckBox, TableCell, ColumnProps, MediaPreview } from '@/shared/atoms';

export interface TikTokCampaignPostsListTableProps {
  posts: readonly TikTokSpecialCampaignPost[];
}

export const useTikTokCampaignPostsListTable = ({ posts }: TikTokCampaignPostsListTableProps) => {
  const searchParams = useSearch({ from: '/_private-routes/tiktok/campaigns/$id/posts' });
  const { t, navigate } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/tiktok/campaigns/$id/posts' });
  const { isAdminStaff } = useUserRoles();
  const { generateProfileLink } = useGenerateInfluencerProfileLink({ disabled: !isAdminStaff }); // permissions logic not implemented on BE for tiktok special advertisers
  const { hideRejectInappropriatePostBtn } = useTikTokSpecialPermissions();

  const checkHasPostStatusControl = (post: Pick<TikTokSpecialCampaignPost, 'id' | 'status'>) =>
    !hideRejectInappropriatePostBtn &&
    post.id &&
    post.status &&
    [TikTokSpecialCampaignPostStatus.EFFECTIVE, TikTokSpecialCampaignPostStatus.WARNING].includes(post.status);

  const postsActions = useSelectItems<TikTokSpecialCampaignPost, keyof TikTokSpecialCampaignPost>({
    key: 'id',
    listItems: posts.reduce<TikTokSpecialCampaignPost[]>(
      (acc, curr) => (checkHasPostStatusControl(curr) ? [...acc, curr] : acc),
      []
    )
  });

  const { allItemIds, selectedItems, selectItem, toggleSelectAllItems } = postsActions;
  const hasSelectAllCheckBox = !hideRejectInappropriatePostBtn && allItemIds.length;
  const columns: ColumnProps[] = [
    {
      sticky: { active: true },
      hidden: !hasSelectAllCheckBox,
      title: (
        <CheckBox
          onClick={toggleSelectAllItems}
          disabled={!allItemIds.length}
          tooltipProps={{ help: t('Select all') }}
        />
      )
    },
    {
      title: <div>{t('HeaderColumn.Influencer')}</div>,
      sticky: { active: true, hasBorderRight: true, left: hasSelectAllCheckBox ? '40px' : undefined }
    },
    { title: t('HeaderColumn.Content') },
    { title: t('HeaderColumn.Post Status') },
    { title: t('HeaderColumn.Revenue per Posting'), hidden: !isAdminStaff },
    { title: t('HeaderColumn.Post Code') },
    { title: t('HeaderColumn.Phone Number'), hidden: !isAdminStaff },
    { title: t('HeaderColumn.Post date') }
  ];
  const rows: RowProps[] = posts.map(
    ({ id, status, content, thumbNail, influencer, postedDate, postCode, phoneNumber, revenuePerPost, currency }) => {
      const isSelectedPost = selectedItems.findIndex((p) => p.id === id && p.influencer.id === influencer.id) !== -1;
      const postDetailsUrl = id
        ? {
            to: '/tiktok/campaigns/$id/posts/$postId',
            params: { id: params.id, postId: id?.toString() },
            search: searchParams
          }
        : undefined;

      const goToPostDetails = () => {
        if (postDetailsUrl) {
          navigate(postDetailsUrl);
        }
      };

      return [
        checkHasPostStatusControl({ id, status }) ? (
          <CheckBox
            checked={isSelectedPost}
            onClick={id ? () => selectItem(id) : undefined}
            tooltipProps={{ help: t(isSelectedPost ? 'Unselect' : 'Select') }}
          />
        ) : (
          <div />
        ),
        <TableCell.Link
          label={influencer.name}
          css={{ display: 'flex', alignItems: 'center', minWidth: '100px' }}
          {...generateProfileLink(influencer.id.toString())}
        >
          <InfluencerAvatar size="40px" src={influencer.avatar} asChild />
          <TableCell.Text css={{ marginLeft: '8px', minWidth: '100px' }} value={influencer.name} />
        </TableCell.Link>,
        <div
          onClick={goToPostDetails}
          css={{ cursor: postDetailsUrl ? 'pointer' : 'default', display: 'flex', alignItems: 'center', gap: '8px' }}
        >
          <MediaPreview src={thumbNail} css={{ width: '60px', height: '40px' }} />
          <Anchor lines={2} css={{ maxWidth: '300px', width: 'max-content' }} label={content || '-'} />
        </div>,
        status ? <TikTokSpecialPostStatus status={status} /> : '',
        <TableCell.Costs
          costPerPost={revenuePerPost}
          currency={currency}
          socialMedia={CampaignSocialMediaType.TIKTOK}
        />,
        <TableCell.Text value={postCode || ''} css={{ minWidth: '80px' }} />,
        <TableCell.Text value={phoneNumber || ''} css={{ minWidth: '80px' }} />,
        <TableCell.Date value={postedDate || ''} />
      ];
    }
  );

  return { rows, columns, postsActions };
};
