import { useEffect } from 'react';
import { useQueryHelper } from '@/shared/hooks';
import { SectionWithHeader } from '@/shared/molecules';
import { NoteAggregateLastThirtyDays } from '@/shared/organisms';
import { NoDataContentWrapper, RenderDataWithFallback } from '@/shared/atoms';
import { ACCOUNT_PRIVATE_OR_DELETED, NO_POSTS_LAST_30_DAYS } from '@/shared/constants';
import { SocialAccountType, useInfluencerProfileFollowerGrowthQuery } from '@/graphql';
import { useInfluencerProfileSearch } from '../../../hooks';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfileOverviewChart } from './InfluencerProfileOverviewChart';
import { InfluencerProfileOverviewSummary } from './InfluencerProfileOverviewSummary';
import { InfluencerProfileXhsFollowerGrowth } from './InfluencerProfileXhsFollowerGrowth';

const customErrorMessage = (message?: string) =>
  !!message && [NO_POSTS_LAST_30_DAYS, ACCOUNT_PRIVATE_OR_DELETED].includes(message) ? message : undefined;

export interface InfluencerProfileOverviewProps {
  className?: string;
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerProfileOverview = ({
  className,
  influencerId,
  socialAccount: { id, type }
}: InfluencerProfileOverviewProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { reinitParamsSearch } = useInfluencerProfileSearch();
  const { data, error, loading } = useInfluencerProfileFollowerGrowthQuery({
    fetchPolicy: 'network-only',
    variables: {
      pk: influencerId,
      socialAccountId: id,
      socialMedia: type as SocialAccountType
    },
    onError: (err) => {
      if (!customErrorMessage(err.message)) {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (data?.influencerProfileFollowerGrowth?.lastUpdatedDatetime) {
      reinitParamsSearch({ lastUpdated: data.influencerProfileFollowerGrowth.lastUpdatedDatetime });
    }
  }, [data?.influencerProfileFollowerGrowth?.lastUpdatedDatetime]);

  const overviewData = data?.influencerProfileFollowerGrowth;
  const isXhs = type === SocialAccountType.XHS;

  return (
    <div css={{ marginTop: '16px' }} className={className}>
      <RenderDataWithFallback loading={loading} loadingProps={{ css: { height: '375px' } }} skipDelayed>
        <SectionWithHeader
          title={t('Follower Growth')}
          prefixIcon={{ icon: 'pad-two-tones', size: '24px' }}
          headerChildren={isXhs ? <NoteAggregateLastThirtyDays /> : null}
        >
          <NoDataContentWrapper
            emptyImage="magnifying-glass"
            wrapperText={customErrorMessage(error?.message)}
            dataNotAvailable={!(overviewData?.followersGrowth.length || overviewData?.postsGrowth.length)}
          >
            {overviewData ? (
              isXhs ? (
                <>
                  <InfluencerProfileXhsFollowerGrowth data={overviewData} />
                  <InfluencerProfileOverviewChart influencerId={influencerId} data={overviewData} />
                </>
              ) : (
                <>
                  <InfluencerProfileOverviewSummary data={overviewData} />
                  <InfluencerProfileOverviewChart influencerId={influencerId} data={overviewData} />
                </>
              )
            ) : null}
          </NoDataContentWrapper>
        </SectionWithHeader>
      </RenderDataWithFallback>
    </div>
  );
};
