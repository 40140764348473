import { css } from '@emotion/react';
import { Accordion } from '@mantine/core';
import { isAfter, isSameDay } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { EngagementCampaignStatus, HubspotDealData } from '@/graphql';
import { FormStyle, Icon, Label } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import {
  useAllCountryOptions,
  useAllPicUserOptions,
  useAllAvailableCountryOptions,
  useAllCampaignCategoryOptions
} from '@/shared/hooks';
import {
  TextField,
  SelectorFieldV2,
  TextAreaField,
  RichEditorField,
  FileUploadField,
  HubspotDealsField,
  RangeDatePickerField,
  AdvertiserSearchField,
  SingleDatePickerField
} from '@/shared/molecules';
import { DisabledFields } from '@/shared/types';
import { EngagementCampaignFormKeys, EngagementCampaignFormValues } from '../types';

interface CampaignInformationProps {
  disabledFields: DisabledFields<EngagementCampaignFormKeys>;
}
export const CampaignInformation = ({ disabledFields }: CampaignInformationProps) => {
  const { t } = useTranslation();
  const {
    isAdminStaff,
    isAdvertiser,
    isAgencyTalentAgencyPartner,
    isAdminStaffTalentAgencyPartner,
    isAdminStaffAgencyTalentAgencyPartner
  } = useUserRoles();
  const { allCountryOptions } = useAllCountryOptions();
  const { allCampaignCategoryOptions } = useAllCampaignCategoryOptions();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions({ hasUSCountry: true });
  const { allPicsUserOptions } = useAllPicUserOptions({ skip: !isAdminStaffTalentAgencyPartner });
  const {
    watch,
    setValue,
    formState: { defaultValues }
  } = useFormContext<EngagementCampaignFormValues>();

  const currencyValue = watch('currency');
  const isAddPage = !defaultValues?.id;
  const canOnlyEditEndDate = [EngagementCampaignStatus.UPCOMING, EngagementCampaignStatus.FINISHED].includes(
    defaultValues?.status as EngagementCampaignStatus
  );

  const handleCountryIdsChange = (name: EngagementCampaignFormKeys, selectedCountryIds: string[]) => {
    setValue(name, selectedCountryIds, { shouldValidate: true });

    const { currency, needToSendCurrency } = (() => {
      if (!selectedCountryIds.length) {
        return { currency: '', needToSendCurrency: false };
      }

      if (selectedCountryIds.length === 1) {
        const country = allAvailableCountryOptions.find((country) => country.value === selectedCountryIds[0]);

        return { needToSendCurrency: !country, currency: country?.currency || currencyValue || 'USD' };
      }

      return { needToSendCurrency: true, currency: 'USD' };
    })();

    setValue('currency', currency, { shouldValidate: true });
    setValue('needToSendCurrency', needToSendCurrency);
  };

  const handleHubspotDealsChange = (
    field: EngagementCampaignFormKeys,
    values: string[],
    hubspotDeals: HubspotDealData[] = []
  ) => {
    setValue(field, values);

    const hubspotDeal = hubspotDeals.length === 1 ? hubspotDeals[0] : undefined;

    if (isAddPage) {
      // this may not work cause ownerName from bigQuery may not match our PICs name
      const salesPicId = hubspotDeal?.ownerName
        ? allPicsUserOptions.find((el) => el.label === hubspotDeal?.ownerName)?.value
        : null;
      setValue('salesPics', salesPicId ? [salesPicId] : [], { shouldValidate: true });
      setValue('countryIds', [hubspotDeal?.country || ''], { shouldValidate: true });
      const isCampaignPeriodValid =
        hubspotDeal?.startDate &&
        (isAfter(new Date(hubspotDeal?.startDate), new Date()) ||
          isSameDay(new Date(hubspotDeal?.startDate), new Date()));
      if (isCampaignPeriodValid) {
        // if campaign has correct start date it should have correct endDate too
        setValue(
          'period',
          { startDate: hubspotDeal?.startDate || '', endDate: hubspotDeal?.endDate || '' },
          { shouldValidate: true }
        );
      }

      setValue('title', hubspotDeal?.dealName || '', { shouldValidate: true });
    }
  };

  return (
    <div css={{ padding: '24px' }}>
      <FormStyle.SectionTitle index={1} label={t('Campaign Information')} />

      {!isAdvertiser ? (
        <FormStyle.FieldsGroup itemsPerRow={2}>
          {isAdminStaffAgencyTalentAgencyPartner ? (
            <AdvertiserSearchField<EngagementCampaignFormKeys>
              name="advertiser"
              disabled={disabledFields.advertiser}
              required
            />
          ) : null}

          {isAdminStaff ? (
            <HubspotDealsField<EngagementCampaignFormKeys>
              name="hubspotDealIds"
              onChange={handleHubspotDealsChange}
              disabled={disabledFields?.hubspotDealIds}
              placeholder={t<string>('Search by Deal name or Deal ID')}
            />
          ) : null}

          {isAdminStaffTalentAgencyPartner && (
            <>
              <SelectorFieldV2<EngagementCampaignFormKeys>
                name="salesPics"
                options={allPicsUserOptions}
                help={t('Tooltip.Sales PIC')}
                title={t('Selector.Sales PIC')}
                disabled={disabledFields?.salesPics}
                hasSelectAll={false}
                multiple
                required
              />

              <SelectorFieldV2<EngagementCampaignFormKeys>
                options={allPicsUserOptions}
                name="influencerManagementPics"
                help={t('Tooltip.Influencer Management PIC')}
                title={t('Selector.Influencer Management PIC')}
                disabled={disabledFields?.influencerManagementPics}
                hasSelectAll={false}
                multiple
                required
              />
            </>
          )}
        </FormStyle.FieldsGroup>
      ) : null}

      <TextField<EngagementCampaignFormKeys>
        name="title"
        disabled={disabledFields?.title}
        help={t('Tooltip.Campaign Title')}
        title={t('TextForm.Campaign Title')}
        placeholder={t<string>('TextForm.InstagramCampaign')}
        required
        dynamicError
      />

      <FileUploadField<EngagementCampaignFormKeys>
        name="thumbnail"
        target="thumbnail"
        itemsPerRow={3}
        title={t('General.CampaignThumbnail')}
        disabled={disabledFields?.thumbnail}
        help={t('Tooltip.CampaignThumbnailHelp')}
      />

      <FormStyle.FieldsGroup>
        <FormStyle.FieldWrapper css={{ flex: canOnlyEditEndDate ? 2 : 1 }}>
          <Label title={t('Selector.Campaign Duration')} help={'Campaign Duration'} required />
          {canOnlyEditEndDate ? (
            <FormStyle.TwoDayPickersInARow>
              <SingleDatePickerField css={{ padding: '0 !important' }} name="period.startDate" disabled />
              <span css={{ padding: '0 6px' }}>{'\u2013'}</span>
              <SingleDatePickerField
                name="period.endDate"
                disabled={disabledFields.period}
                css={{ padding: '0 !important' }}
                disabledDays={{ before: new Date() }}
              />
            </FormStyle.TwoDayPickersInARow>
          ) : (
            <RangeDatePickerField
              css={{ padding: '0 !important' }}
              disabledRange={{ before: new Date() }}
              name={{ startDate: 'period.startDate', endDate: 'period.endDate' }}
            />
          )}
        </FormStyle.FieldWrapper>

        <SelectorFieldV2<EngagementCampaignFormKeys>
          name="countryIds"
          options={allCountryOptions}
          onChange={handleCountryIdsChange}
          disabled={disabledFields?.countryIds}
          title={t('Selector.Campaign Country')}
          help={t('Tooltip.CampaignCountryHelp')}
          hasSelectAll={false}
          multiple
          required
        />

        <SelectorFieldV2<EngagementCampaignFormKeys>
          name="campaignCategoryId"
          options={allCampaignCategoryOptions}
          help={t('Tooltip.Campaign Category')}
          title={t('Selector.Campaign Category')}
          disabled={disabledFields?.campaignCategoryId}
          required
        />
      </FormStyle.FieldsGroup>

      <TextAreaField<EngagementCampaignFormKeys>
        name="objective"
        help={t('Tooltip.Campaign Help')}
        disabled={disabledFields?.objective}
        title={t('TextForm.Campaign Objective')}
        css={css({ textarea: { height: '80px' } })}
        placeholder={t<string>('TextForm.Campaign Objective Placeholder')}
        required
      />

      <FormStyle.FieldsGroup css={{ marginBottom: '16px' }}>
        <TextField<EngagementCampaignFormKeys>
          name="budget"
          placeholder="1000000"
          help={t('Tooltip.Budget')}
          css={{ maxWidth: '240px' }}
          title={t('TextForm.Budget')}
          disabled={disabledFields?.budget}
          unit={currencyValue ? t(currencyValue) : undefined}
          required
          type="number"
        />

        {isAdminStaffAgencyTalentAgencyPartner && (
          <TextField<EngagementCampaignFormKeys>
            unit="%"
            placeholder="10"
            name="agencyMarginRate"
            css={{ maxWidth: '164px' }}
            help={t('Tooltip.Agency Rate Help Text')}
            disabled={isAdvertiser || disabledFields?.agencyMarginRate}
            title={t(
              !isAgencyTalentAgencyPartner ? 'TextForm.Ad Agency Margin Rate' : 'TextForm.Ad Agency profit rate'
            )}
          />
        )}
      </FormStyle.FieldsGroup>

      {/* Product Information */}
      <Accordion
        chevron={<Icon icon="caret-down" size={9} />}
        chevronPosition="left"
        variant="default"
        styles={{
          control: {
            background: '#fff !important',
            borderRadius: '3px',
            boxSizing: 'border-box',
            border: THEME.border.base,
            '&[data-active]': {
              border: 'none',
              borderTop: THEME.border.base,
              borderRadius: '3px 3px 0 0'
            }
          },
          label: {
            display: 'flex',
            backgroundColor: THEME.background.colors.white
          },
          item: {
            borderBottom: 'none',
            backgroundColor: THEME.background.colors.white,
            '&[data-active]': {
              border: THEME.border.base,
              borderTop: 'none',
              borderRadius: '3px'
            }
          }
        }}
      >
        <Accordion.Item value="product information" css={{ backgroundColor: THEME.background.colors.white }}>
          <Accordion.Control>
            <p css={{ lineHeight: 1.5, fontSize: '14px' }}>{t('Product Information')}</p>
            <p css={{ marginLeft: 'auto', color: THEME.text.colors.gray.lv3, fontSize: '12px' }}>{t('optional')}</p>
          </Accordion.Control>
          <Accordion.Panel>
            <RichEditorField<EngagementCampaignFormKeys>
              name="serviceInformation"
              title={t('TextForm.Service Info')}
              disabled={disabledFields?.serviceInformation}
              help={t('Tooltip.Product or Service Information')}
              placeholder={t<string>('TextForm.Service Information Placeholder')}
            />

            <FileUploadField<EngagementCampaignFormKeys>
              name="materials"
              target="material"
              itemsPerRow={3}
              title={t('General.CampaignMaterial')}
              disabled={disabledFields?.materials}
              help={t('Tooltip.CampaignMaterialHelp')}
              multiple
            />

            <TextField<EngagementCampaignFormKeys>
              name="serviceUrl"
              placeholder="https://anytag.jp/"
              title={t('TextForm.Service URL')}
              help={t('Tooltip.Service URL Help')}
              disabled={disabledFields?.serviceUrl}
            />

            <FormStyle.FieldWrapper css={{ marginBottom: 0 }}>
              <TextField<EngagementCampaignFormKeys>
                placeholder="DL Link"
                name="campaignMaterialLink"
                help={t('Tooltip.CampaignMaterialLinkHelp')}
                disabled={disabledFields?.campaignMaterialLink}
                title={t('TextForm.Campaign material link (Google drive, Dropbox etc.)')}
              />
            </FormStyle.FieldWrapper>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
