import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { emptyProposeInfluencers } from '@/shared/assets';
import { ButtonLink } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

interface TableTitleProps {
  title: string;
  className?: string;
}

export const ProposeInfluencersTableTitle = ({ title, className }: TableTitleProps) => (
  <div css={styles.title} className={className}>
    {title}
  </div>
);

export const ProposeInfluencersTableEmpty = () => {
  const { t } = useTranslation();

  return (
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '80px auto' }}>
      <img
        src={emptyProposeInfluencers}
        alt="people are looking for influencers"
        width="198px"
        css={{ marginBottom: '23px' }}
      />
      <h3 css={{ fontSize: '16px', fontWeight: 600, marginBottom: '16px' }}>{t('Create Influencer Proposal List')}</h3>
      <p css={{ width: '461px', fontSize: '14px', fontWeight: 400, marginBottom: '23px' }}>
        {t('CreateInfluencerProposalListHelp')}
      </p>
      <ButtonLink
        title={t('General.Select Influencers')}
        variant="blue"
        css={{ width: 'max-content', padding: '10px 24px' }}
        to="/influencers/search"
      />
    </div>
  );
};

const styles = {
  title: css({
    height: '48px',
    display: 'flex',
    fontSize: '16px',
    fontWeight: 600,
    padding: '8px 16px',
    lineHeight: '16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    color: THEME.text.colors.black.lv1,
    backgroundColor: THEME.background.colors.white
  })
};
