import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { getNumberValidFilter } from '@/shared/utils';
import { useReportFilterOptions } from '@/shared/hooks';
import { FILTER_SECTIONS, MoreFilterPopover, SelectorField, SelectorFieldV2 } from '@/shared/molecules';
import { AdvertiserSearchSelectorMultiple } from './AsyncSearchSelectors';
import { CampaignPostsDiscoveryFilterFormKeys, CampaignPostsDiscoveryFilterFormValues } from './schemaTypes';

interface AdvancedFilterProps {
  onSubmit?: () => void;
}

export const AdvancedFilter = ({ onSubmit }: AdvancedFilterProps) => {
  const { t } = useTranslation();
  const { watch, reset } = useFormContext<CampaignPostsDiscoveryFilterFormValues>();

  const values = watch();
  const numOfValidFilter = getNumberValidFilter<CampaignPostsDiscoveryFilterFormKeys>(values, [
    'keyword',
    'endDate',
    'startDate',
    'influencerIds'
  ]);

  const {
    loading,
    allCampaignTypes,
    allCountryOptions,
    allCampaignsOptions = [],
    allSocialMediaPostOptions,
    allCampaignCategoryOptions,
    allCampaignsSelectedOptions
  } = useReportFilterOptions({ campaigns: values.campaigns, campaignTypes: values.campaignTypes });

  const handleClearAll = () => {
    reset({
      countryIds: [],
      advertiserIds: [],
      socialPostTypes: [],
      campaignTypes: [],
      categoryIds: [],
      campaigns: []
    });
    onSubmit?.();
  };

  return (
    <MoreFilterPopover
      count={numOfValidFilter}
      onClear={handleClearAll}
      onSubmit={onSubmit}
      defaultOpened={[FILTER_SECTIONS.CampaignPostsDiscovery]}
      items={[
        {
          value: FILTER_SECTIONS.CampaignPostsDiscovery,
          node: (
            <>
              <SelectorField<CampaignPostsDiscoveryFilterFormKeys>
                name="countryIds"
                title={t('Selector.Country')}
                options={allCountryOptions}
                placeholder={t<string>('Selector.Country')}
                singleSelectorProps={{ hideDeselectOption: true }}
                multiple
              />
              <AdvertiserSearchSelectorMultiple title={t<string>('Selector.Advertiser')} />
              <SelectorFieldV2<CampaignPostsDiscoveryFilterFormKeys>
                clearable={false}
                name="socialPostTypes"
                title={t('Selector.Social Media')}
                options={allSocialMediaPostOptions}
                placeholder={t<string>('Selector.Social Media')}
                multiple
              />
              <SelectorField<CampaignPostsDiscoveryFilterFormKeys>
                name="campaignTypes"
                options={allCampaignTypes}
                title={t('Selector.Campaign type')}
                multiple
              />
              <SelectorField<CampaignPostsDiscoveryFilterFormKeys>
                name="categoryIds"
                options={allCampaignCategoryOptions}
                title={t<string>('Campaign Category')}
                placeholder={t<string>('Selector.Campaign Category')}
                multiple
                multipleSelectorProps={{
                  menuItemsProps: { type: 'checkbox-with-submenu' },
                  menuCss: css({ minWidth: '200px' })
                }}
              />
              <SelectorField<CampaignPostsDiscoveryFilterFormKeys>
                name="campaigns"
                title={t('Selector.Campaign Name')}
                placeholder={t<string>('Selector.Select campaigns')}
                options={[...allCampaignsSelectedOptions, ...allCampaignsOptions]}
                multiple
                loading={loading}
                multipleSelectorProps={{ menuItemsProps: { isVirtualized: true } }}
              />
            </>
          )
        }
      ]}
    />
  );
};
