import { useTranslation } from 'react-i18next';

import { AppLanguage } from '@/shared/types';
import { formatDate, localizedDateFormatter } from '@/shared/utils';

interface DateProps {
  value?: string | null;
  className?: string;
}

export const Date = ({ value, className }: DateProps) => {
  const { i18n } = useTranslation();

  return (
    <div css={{ fontSize: '13px', textAlign: 'left', whiteSpace: 'nowrap' }} className={className}>
      {value ? (
        <>
          {localizedDateFormatter(value, 'MMM do', i18n.language as AppLanguage)}
          <br />
          <span css={{ color: '#8694a2', fontSize: '11px' }}>{formatDate(value, 'yyyy')}</span>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
