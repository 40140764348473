import { useNavigate } from '@tanstack/react-router';
import { useDeepCompareEffect, useParamsSearch } from '@/shared/hooks';
import {
  useInfluencerProfileContext,
  InfluencerComparableAccountType,
  InfluencerProfileCompareSearchSchemaType
} from '@/shared/organisms';

export const useInitialInfluencerProfileCompare = () => {
  const navigate = useNavigate();
  const { influencerProfile } = useInfluencerProfileContext();

  // We only support compare Youtube Account for now
  const defaultComparableAccount = influencerProfile.youtubeAccounts?.find((acc) => acc.analyticsEnabled);

  const { filter, setFilter } = useParamsSearch<InfluencerProfileCompareSearchSchemaType>();

  const compareAccount: InfluencerComparableAccountType | undefined = (() => {
    const account = filter.id
      ? influencerProfile.youtubeAccounts?.find((acc) => acc.id === Number(filter.id) && acc.analyticsEnabled)
      : defaultComparableAccount;

    return account
      ? {
          id: account.id,
          username: account.name || '',
          profileUrl: account.url || '',
          avatarUrl: account.avatar || ''
        }
      : undefined;
  })();

  useDeepCompareEffect(() => {
    // This influencer doesn't have any comparable account found
    if (!compareAccount) {
      navigate({ to: '/influencers' });
    } else if (compareAccount.id !== Number(filter.id) || !filter.endDate || !filter.startDate) {
      // When come to influencer profile without id param, we will select first comparable account as default
      setFilter({ id: String(compareAccount.id) }, { replace: true });
    }
  }, [compareAccount]);

  return { filter, setFilter, compareAccount };
};
