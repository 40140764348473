import { css } from '@emotion/react';
import { useUserRoles } from '@/auth';
import { SocialAccountType, useRequestInfluencerProfilePptxMutation } from '@/graphql';
import { DownloadButton, DownloadButtonProps } from '@/shared/atoms';
import { GTM_IDS } from '@/shared/constants';
import { useDownloadFile, useQueryHelper } from '@/shared/hooks';
import { DownloadFileType, DropdownOption } from '@/shared/types';
import { getToday, getUploadApiUrl, getThirtyDaysAgo } from '@/shared/utils';
import { InfluencerSelectedAccountInfo } from '../types';
import { checkSocialAccountType } from '../utils';

interface DownloadInfluencerStatsProps {
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const DownloadInfluencerStats = ({
  influencerId,
  socialAccount: { id, type, analyticsEnabled }
}: DownloadInfluencerStatsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { handleDownloadFile, downloading } = useDownloadFile();
  const { isAdminStaff, isAdminStaffTalentAgencyPartner, isPartnerFromUuum } = useUserRoles();
  const { callRequestInfluencerProfilePptx, loading } = useRequestInfluencerProfilePptxMutation();

  const isSocialAccount = checkSocialAccountType(type);
  const downloadOptions: DropdownOption<DownloadFileType>[] = [
    {
      hidden: !isAdminStaff && !isPartnerFromUuum,
      label: t<string>('Google Slide'),
      value: DownloadFileType.GOOGLE_SLIDE,
      gtmId: GTM_IDS.influencer.profile_google_slide_download
    },
    {
      label: t<string>('Powerpoint'),
      value: DownloadFileType.PPTX,
      gtmId: GTM_IDS.influencer.profile_pptx_download
    },
    {
      label: t<string>('CSV'),
      value: DownloadFileType.CSV,
      gtmId: GTM_IDS.influencer.profile_csv_download,
      hidden: type !== SocialAccountType.INSTAGRAM || !analyticsEnabled
    }
  ].filter((option) => !option.hidden);
  const buttonProps: DownloadButtonProps = {
    title: '',
    disabled: !isSocialAccount,
    loading: loading || downloading,
    tooltip: { help: t('Download this account') }
  };

  const handleDownload = async (fileType?: DownloadFileType) => {
    if (
      !fileType ||
      !isSocialAccount ||
      ![DownloadFileType.CSV, DownloadFileType.GOOGLE_SLIDE, DownloadFileType.PPTX].includes(fileType)
    ) {
      return;
    }

    try {
      switch (fileType) {
        case DownloadFileType.CSV:
          await handleDownloadFile({
            filenamePrefix: 'report_post',
            params: { endDate: getToday(), startDate: getThirtyDaysAgo() }, // Influencer does not have period
            url: getUploadApiUrl(`analytics/presentation/influencer/csv/${id}`)
          });
          break;
        case DownloadFileType.GOOGLE_SLIDE:
        case DownloadFileType.PPTX:
          await callRequestInfluencerProfilePptx({
            variables: {
              influencerId,
              format: fileType,
              socialMedia: type,
              socialAccountId: id
            }
          });

          if (analyticsEnabled) {
            enqueueSnackbar(t('Analytics account report has been sent to your email'), { variant: 'success' });
          } else {
            enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
          }
          break;
        default:
          break;
      }
    } catch (error) {
      enqueueSnackbar(t(analyticsEnabled ? error.message : 'failedToDownload'), { variant: 'error' });
    }
  };

  return !isAdminStaffTalentAgencyPartner ? (
    <DownloadButton
      {...buttonProps}
      css={styles.downloadButton}
      onClick={() => handleDownload(DownloadFileType.PPTX)}
    />
  ) : (
    <DownloadButton
      {...buttonProps}
      options={downloadOptions}
      css={styles.downloadButton}
      onSelectOption={({ value }) => handleDownload(value)}
    />
  );
};
const styles = {
  downloadButton: css({ minWidth: 'unset', marginLeft: '8px' })
};
