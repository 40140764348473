import { TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useSelectItem } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { InfluencerAvatar } from '../../InfluencerAvatar';
import { DefaultPostDetailsTemplate, PostMediaSliderPreview, PostTabs, PostTabsProps } from '../shared';
import { ComparePostsDetailsAccountType, ComparePostsDetailsPostType } from './types';

type Id = ComparePostsDetailsAccountType['id'];

export interface ComparePostsDetailsProps extends Pick<PostDetailsType, 'id'> {
  accounts: ComparePostsDetailsAccountType[];
  data: Record<Id, ComparePostsDetailsPostType[]>;
}

export const ComparePostsDetails = ({ id, data, accounts }: ComparePostsDetailsProps) => {
  const { selectedItem, setSelectedItem } = useSelectItem<{ selectedAccountId: Id; slideIndex: number }>();
  const tabs = accounts.reduce<PostTabsProps<Id>['tabs']>(
    (acc, curr) =>
      data[curr.id]?.length
        ? [
            ...acc,
            {
              label: (
                <div css={{ display: 'flex', alignItems: 'center' }}>
                  <InfluencerAvatar size="34px" src={curr.avatar} css={{ borderRadius: '50%' }} />
                  <TextCutter text={curr.name} css={{ marginLeft: '4px', fontSize: '13px', lineHeight: 1.38 }} />
                </div>
              ),
              value: curr.id
            }
          ]
        : acc,
    []
  );

  return (
    <PostTabs
      id={id}
      tabs={tabs}
      css={{ '.tab-item': { maxWidth: '185px', justifyContent: 'flex-start' } }}
      onChange={(selectedAccountId) => setSelectedItem({ selectedAccountId, slideIndex: 0 })}
    >
      {({ selectedTab: selectedAccountId }) => {
        const postData = selectedAccountId ? data[selectedAccountId] || [] : [];
        const { images, mappedPostIndexByImageIndex } = postData.reduce<{
          images: string[];
          mappedPostIndexByImageIndex: number[];
        }>(
          (acc, curr, index) => {
            acc.images = [...acc.images, ...(curr?.images || [])];
            if (curr?.images?.length) {
              acc.mappedPostIndexByImageIndex = [
                ...acc.mappedPostIndexByImageIndex,
                ...Array(curr.images.length).fill(index)
              ];
            }

            return acc;
          },
          { images: [], mappedPostIndexByImageIndex: [] }
        );
        const currentMediaIndex = selectedItem?.slideIndex || 0;
        const currentPostIndex =
          (selectedItem?.selectedAccountId === selectedAccountId &&
            selectedItem?.slideIndex &&
            mappedPostIndexByImageIndex[selectedItem.slideIndex]) ||
          0;

        return (
          <DefaultPostDetailsTemplate
            css={{ width: THEME.modal.size.lv3 }}
            currentMediaIndex={currentMediaIndex}
            onMediaChange={(slideIndex) => setSelectedItem({ slideIndex, selectedAccountId })}
            postDetails={{ ...postData[currentPostIndex], images } as ComparePostsDetailsPostType}
          >
            {images.length > 1 ? (
              <PostMediaSliderPreview
                data={images}
                currentMediaIndex={currentMediaIndex}
                onMediaChange={(slideIndex) => setSelectedItem({ slideIndex, selectedAccountId })}
              />
            ) : null}
          </DefaultPostDetailsTemplate>
        );
      }}
    </PostTabs>
  );
};
