import { RenderDataWithFallback } from '@/shared/atoms';
import { CampaignType, useMarketplaceCampaignReportSummaryQuery } from '@/graphql';
import { getValuableVariables } from '@/shared/utils';
import {
  PostReportEmpty,
  PostReportProvider,
  MarketplaceReportGrid,
  MarketplaceReportTable,
  MarketplaceReportFilter,
  MarketplaceReportSummary
} from '@/shared/organisms';
import { usePostReportSearch } from '@/shared/molecules';
import { PostReportMode } from '@/shared/types';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';

export const MarketplaceReport = () => {
  const { marketplace, hasTrackingTune, hasTrackingReferral } = useMarketplaceDetailsContext();

  const { id } = marketplace;
  const { filter, setFilter, state } = usePostReportSearch();
  const { data, loading } = useMarketplaceCampaignReportSummaryQuery({
    variables: getValuableVariables({
      campaignId: id,
      keyword: filter.keyword,
      endDate: filter.endDate,
      startDate: filter.startDate
    })
  });

  const summaryData = data?.marketplaceCampaignReportSummary || undefined;

  return (
    <PostReportProvider
      value={{ campaignType: CampaignType.MARKETPLACE, hasTracking: hasTrackingTune || hasTrackingReferral }}
    >
      <MarketplaceReportFilter filter={filter} setFilter={setFilter} campaignId={id} segmentState={state} />

      <RenderDataWithFallback loading={loading} hasNoData={!summaryData?.postCount} noDataNode={<PostReportEmpty />}>
        <MarketplaceReportSummary data={summaryData} marketplace={marketplace} />

        {filter.mode === PostReportMode.GRID ? (
          <MarketplaceReportGrid marketplace={marketplace} />
        ) : (
          <MarketplaceReportTable marketplace={marketplace} />
        )}
      </RenderDataWithFallback>
    </PostReportProvider>
  );
};
