import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface MarketplacePostDetailsTabProps extends DefaultPostDetailsTemplateProps {
  hasInteraction?: boolean;
}

// TODO: implement more functions inside MKP details
export const MarketplacePostDetailsTab = ({ hasInteraction, ...restProps }: MarketplacePostDetailsTabProps) => (
  <DefaultPostDetailsTemplate css={{ width: '856px' }} {...restProps}>
    {/* TODO: other function like approve/reject */}
  </DefaultPostDetailsTemplate>
);
