import { Header } from '@/shared/molecules';
import { TikTokSpecialCampaignDetail } from '@/graphql';
import { TikTokCampaignStatus } from '../TikTokCampaignStatus';
import { RejectButton } from './RejectButton';
import { ApproveButton } from './ApproveButton';

export interface TikTokCampaignHeaderProps {
  tiktokSpecialCampaign?: TikTokSpecialCampaignDetail;
}

export const TikTokCampaignHeader = ({ tiktokSpecialCampaign }: TikTokCampaignHeaderProps) => (
  <Header>
    <TikTokCampaignStatus status={tiktokSpecialCampaign?.status} />

    <RejectButton tiktokSpecialCampaign={tiktokSpecialCampaign} css={{ marginLeft: 'auto' }} />
    <ApproveButton tiktokSpecialCampaign={tiktokSpecialCampaign} />
  </Header>
);
