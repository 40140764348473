import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { AnchorProps, CustomSocialIcon, SocialMediaIconType, CustomSocialIconProps } from '@/shared/atoms';

interface SocialMediaType {
  value?: boolean;
  type: CustomSocialIconProps['type'];
}

export interface SocialMediasSectionProps {
  xhs?: boolean;
  email?: boolean;
  tiktok?: boolean;
  douyin?: boolean;
  twitter?: boolean;
  threads?: boolean;
  youtube?: boolean;
  facebook?: boolean;
  instagram?: boolean;
  className?: string;
  profileUrl?: Pick<AnchorProps, 'href' | 'params' | 'target' | 'disabled'>;
}

export const SocialMediasSection = ({
  xhs,
  email,
  tiktok,
  douyin,
  twitter,
  youtube,
  threads,
  facebook,
  instagram,
  className,
  profileUrl
}: SocialMediasSectionProps) => {
  const { t } = useTranslation();
  const socialMedias: SocialMediaType[] = [
    { type: SocialMediaIconType.FACEBOOK, value: facebook },
    { type: SocialMediaIconType.INSTAGRAM, value: instagram },
    { type: SocialMediaIconType.YOUTUBE, value: youtube },
    { type: SocialMediaIconType.TWITTER, value: twitter },
    { type: SocialMediaIconType.TIKTOK, value: tiktok },
    { type: SocialMediaIconType.DOUYIN, value: douyin },
    { type: SocialMediaIconType.XHS, value: xhs },
    ...(typeof threads !== 'undefined'
      ? [{ type: SocialMediaIconType.THREADS, value: threads }]
      : [{ type: SocialMediaIconType.EMAIL, value: email }])
  ];

  return (
    <div css={styles.wrapper} className={className}>
      {socialMedias.map(({ type, value: isSignedUp }) => (
        <CustomSocialIcon
          key={type}
          size="18px"
          type={type}
          target="_blank"
          isSignedUp={isSignedUp}
          active={isSignedUp !== undefined}
          css={{ width: '32px', height: '28px' }}
          help={t(isSignedUp ? 'Signed Up' : 'Scraping')}
          {...profileUrl}
        />
      ))}
    </div>
  );
};
const styles = {
  wrapper: css({
    gap: '8px',
    display: 'grid',
    marginLeft: '16px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr'
  })
};
