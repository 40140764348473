import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/WordCloudList.graphql';
import { Query, QuerywordCloudListArgs } from '../../__generated__/globalTypes';

export const useWordCloudListQuery = (options?: QueryHookOptions<Query, QuerywordCloudListArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerywordCloudListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useWordCloudListLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerywordCloudListArgs>
): QueryResult<Query, QuerywordCloudListArgs> & {
  getWordCloudList: LazyQueryExecFunction<Query, QuerywordCloudListArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getWordCloudList, result] = useLazyQuery<Query, QuerywordCloudListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getWordCloudList, ...result };
};

export const useWordCloudListPromiseQuery = () => {
  const client = useApolloClient();

  const getWordCloudList = useCallback(
    (options?: Omit<QueryOptions<QuerywordCloudListArgs, Query>, 'query'>) =>
      client.query<Query, QuerywordCloudListArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getWordCloudList };
};
