import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllXHSInfluencers.graphql';
import { Query, QueryallXHSInfluencersArgs } from '../../__generated__/globalTypes';

export const useAllXHSInfluencersQuery = (options?: QueryHookOptions<Query, QueryallXHSInfluencersArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallXHSInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllXHSInfluencersLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallXHSInfluencersArgs>
): QueryResult<Query, QueryallXHSInfluencersArgs> & {
  getAllXHSInfluencers: LazyQueryExecFunction<Query, QueryallXHSInfluencersArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllXHSInfluencers, result] = useLazyQuery<Query, QueryallXHSInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllXHSInfluencers, ...result };
};

export const useAllXHSInfluencersPromiseQuery = () => {
  const client = useApolloClient();

  const getAllXHSInfluencers = useCallback(
    (options?: Omit<QueryOptions<QueryallXHSInfluencersArgs, Query>, 'query'>) =>
      client.query<Query, QueryallXHSInfluencersArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllXHSInfluencers };
};
