import { AutoManagedCampaignStatus } from '@/graphql';
import { AutoManagedCampaignsFilterFormValues } from './schemaTypes';

export const defaultAutoManagedCampaignFilterValues: AutoManagedCampaignsFilterFormValues = {
  keyword: '',
  minPrice: '',
  maxPrice: '',
  countryId: '',
  categoryIds: [],
  marketplaceType: '',
  minCommissionRate: '',
  maxCommissionRate: '',
  status: AutoManagedCampaignStatus.ACTIVE
};
