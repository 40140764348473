import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeAnalyticsOverview.graphql';
import { Query, QueryyoutubeAnalyticsOverviewArgs } from '../../__generated__/globalTypes';

export const useYoutubeAnalyticsOverviewQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeAnalyticsOverviewArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeAnalyticsOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeAnalyticsOverviewLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeAnalyticsOverviewArgs>
): QueryResult<Query, QueryyoutubeAnalyticsOverviewArgs> & {
  getYoutubeAnalyticsOverview: LazyQueryExecFunction<Query, QueryyoutubeAnalyticsOverviewArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeAnalyticsOverview, result] = useLazyQuery<Query, QueryyoutubeAnalyticsOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeAnalyticsOverview, ...result };
};

export const useYoutubeAnalyticsOverviewPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeAnalyticsOverview = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeAnalyticsOverviewArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeAnalyticsOverviewArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeAnalyticsOverview };
};
