/* eslint-disable react/jsx-key */
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { linkOptions } from '@tanstack/react-router';
import { useReportPermissions } from '@/auth';
import { SpreadButton } from '@/shared/molecules';
import { TikTokSpecialCampaign } from '@/graphql';
import { TableCell, ColumnProps, RowProps } from '@/shared/atoms';
import { localizedDateFormatter } from '@/shared/utils';
import { useTikTokCampaignRoutes } from '../../../hooks';
import { TikTokCampaignStatus } from '../../TikTokCampaignStatus';
import { TikTokCampaignTabsType, getAccessTikTokCampaignTab } from '../../TikTokCampaignTabs';

interface Props {
  data: readonly TikTokSpecialCampaign[];
}

export const useTikTokCampaignsTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { hideReportLink } = useReportPermissions();
  const { getTikTokCampaignDetailsRoute } = useTikTokCampaignRoutes();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Campaign Title'), sticky: { active: true, hasBorderRight: true } },
    { title: t('HeaderColumn.Plan') },
    { title: t('HeaderColumn.Advertiser') },
    { title: t('HeaderColumn.Status') },
    { title: t('HeaderColumn.Pre Launch date') },
    { title: t('HeaderColumn.Campaign Duration') },
    { title: t('HeaderColumn.Joined Influencers') }
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      plan,
      title,
      status,
      endDate,
      startDate,
      advertiser,
      preLaunchDate,
      maxNumberInfluencers,
      joinedInfluencersCount
    }) => {
      const getDetailsUrl = (tab: keyof typeof TikTokCampaignTabsType) =>
        linkOptions({ to: getTikTokCampaignDetailsRoute(tab), params: { id: id.toString() } });
      const detailsUrl = getDetailsUrl('Details');

      return [
        <div css={styles.nameColumnWrapper}>
          <TableCell.Link label={title} css={{ fontSize: '12px' }} to={detailsUrl.to} params={detailsUrl.params} />
          <SpreadButton
            collapseFrom={2}
            css={{ marginLeft: '4px' }}
            options={[
              {
                icon: 'post',
                label: t('Posts'),
                to: getDetailsUrl('Posts').to,
                params: getDetailsUrl('Posts').params,
                tooltip: { help: t('Button.Posts') },
                disabled: !getAccessTikTokCampaignTab('Posts', status)
              },
              {
                icon: 'chart',
                label: t('Report'),
                hidden: hideReportLink,
                to: getDetailsUrl('Report').to,
                params: getDetailsUrl('Report').params,
                tooltip: { help: t('Button.Report') },
                disabled: !getAccessTikTokCampaignTab('Report', status)
              }
            ]}
          />
        </div>,
        <TableCell.Text value={t(`Plan.${plan}`)} />,
        <TableCell.Text value={advertiser.name || '-'} />,
        <TikTokCampaignStatus status={status} />,
        <TableCell.Text value={localizedDateFormatter(preLaunchDate || null, 'yyyy/MM/dd')} />,
        <TableCell.Period period={{ startDate, endDate }} />,
        <TableCell.Text value={`${joinedInfluencersCount}/${maxNumberInfluencers}`} />
      ];
    }
  );

  return { rows, columns };
};

const styles = {
  nameColumnWrapper: css({
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  })
};
