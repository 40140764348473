import { css } from '@emotion/react';
import { TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import {
  formatPercent,
  bigIntFormatter,
  getGrowthArrow,
  getGrowthPrefix,
  intlNumberFormat
} from '@/shared/utils';
import { AverageEngagementType } from './types';

export interface GridTemplateProps {
  itemsPerRow?: number;
  data: AverageEngagementType[];
}
export const GridTemplate = ({ itemsPerRow = 3, data }: GridTemplateProps) => (
  <div css={{ display: 'flex', flexWrap: 'wrap', height: '100%' }}>
    {data.map(({ label, value, icon, growth, isPercentage }, index) => {
      const formattedValue = isPercentage ? formatPercent(value) : bigIntFormatter(value, 0);
      const formattedValueTitle = isPercentage ? formattedValue : intlNumberFormat(value);

      return (
        <div key={index} css={styles.item(itemsPerRow)}>
          {icon ? <img src={icon} css={{ width: '48px', height: '48px', marginLeft: 'auto' }} /> : null}

          <div css={{ paddingLeft: '16px' }}>
            <TextCutter lines={2} text={label} css={{ fontWeight: 600, fontSize: '11px', wordBreak: 'break-word' }} />
            <p css={{ fontSize: '20px', fontWeight: 600, marginTop: '4px' }} title={formattedValueTitle}>
              {formattedValue}
            </p>
            {growth ? (
              <p
                css={{
                  fontSize: '13px',
                  fontWeight: '600',
                  marginTop: '4px',
                  color: growth > 0 ? THEME.growth.positive : THEME.growth.negative
                }}
                title={intlNumberFormat(growth)}
              >
                {getGrowthPrefix(growth)}
                {bigIntFormatter(growth, 0)}&nbsp;
                {getGrowthArrow(growth)}
              </p>
            ) : null}
          </div>
        </div>
      );
    })}
  </div>
);
const styles = {
  item: (itemsPerRow: number) =>
    css({
      boxSizing: 'border-box',
      borderTop: THEME.border.base,
      flex: `calc(100% / ${itemsPerRow})`,
      borderRight: THEME.border.base,
      [`&:nth-of-type(${itemsPerRow}n + ${itemsPerRow})`]: { borderRight: 'none' }
    })
};
