import { LinkProps, useMatchRoute } from '@tanstack/react-router';
import { usePermissions, useInfluencerPermissions } from '@/auth';
import { UserRoles } from '@/graphql';
import { useAuthData } from './useAuthData';

type ValidatorKeyType = 'InfluencerProfileLink';
const validatedPaths = [
  '/engagement/$id/posts',
  '/engagement/$id/report',
  '/marketplace/$id/posts',
  '/marketplace/$id/report',
  '/marketplace/$id/report-affiliate',
  '/engagement/$id/posts',
  '/marketplace/$id/posts'
] as const satisfies LinkProps['to'][];

const PERMISSION_DENIED_SCHEMA: Record<UserRoles, Record<ValidatorKeyType, (typeof validatedPaths)[number][]>> = {
  [UserRoles.ADVERTISER]: {
    InfluencerProfileLink: [
      '/engagement/$id/posts',
      '/engagement/$id/report',
      '/marketplace/$id/posts',
      '/marketplace/$id/report',
      '/marketplace/$id/report-affiliate'
    ]
  },
  [UserRoles.PARTNER]: { InfluencerProfileLink: [] },
  [UserRoles.ADMINISTRATOR]: { InfluencerProfileLink: [] },
  [UserRoles.AGENCY]: { InfluencerProfileLink: ['/engagement/$id/posts', '/marketplace/$id/posts'] },
  [UserRoles.INFLUENCER]: { InfluencerProfileLink: [] },
  [UserRoles.STAFF]: { InfluencerProfileLink: [] },
  [UserRoles.TALENT_AGENCY]: { InfluencerProfileLink: [] },
  [UserRoles.EXTERNAL]: { InfluencerProfileLink: [] },
  [UserRoles.CREATOR_STAFF]: { InfluencerProfileLink: [] }
};

export const usePermissionChecker = (validatorKey: ValidatorKeyType) => {
  const {
    auth: { role }
  } = useAuthData();
  const { canCallCustomPermissions } = usePermissions();
  // https://bitbucket.org/adasiaholdings/casting_asia_advertiser_frontend/pull-requests/4111/diff#chg-src/libs/hooks/usePermissionChecker.ts
  const { hideViewInfluencerProfile } = useInfluencerPermissions();
  const matchRoute = useMatchRoute();

  if (!role) {
    return false;
  }

  if (canCallCustomPermissions) {
    return hideViewInfluencerProfile;
  }

  return PERMISSION_DENIED_SCHEMA[role][validatorKey].some((path) => !!matchRoute({ to: path }));
};
