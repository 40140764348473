import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/SelectableBcaBrands.graphql';
import { Query, QueryselectableBcaBrandsArgs } from '../../__generated__/globalTypes';

export const useSelectableBcaBrandsQuery = (options?: QueryHookOptions<Query, QueryselectableBcaBrandsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryselectableBcaBrandsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useSelectableBcaBrandsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryselectableBcaBrandsArgs>
): QueryResult<Query, QueryselectableBcaBrandsArgs> & {
  getSelectableBcaBrands: LazyQueryExecFunction<Query, QueryselectableBcaBrandsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getSelectableBcaBrands, result] = useLazyQuery<Query, QueryselectableBcaBrandsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getSelectableBcaBrands, ...result };
};

export const useSelectableBcaBrandsPromiseQuery = () => {
  const client = useApolloClient();

  const getSelectableBcaBrands = useCallback(
    (options?: Omit<QueryOptions<QueryselectableBcaBrandsArgs, Query>, 'query'>) =>
      client.query<Query, QueryselectableBcaBrandsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getSelectableBcaBrands };
};
