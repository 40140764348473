import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations } from '@/shared/validations';
import { TikTokSpecialCampaignPostStatus } from '@/graphql';
import { LIMIT, INITIAL_PAGE_NUMBER } from '@/shared/constants';

export const TikTokCampaignPostsFilterSchema = z.object({
  keyword: FilterValidations.string(),
  status: FilterValidations.enum(TikTokSpecialCampaignPostStatus)
});
export const TikTokCampaignPostsSearchSchema = z.object({
  filter: TikTokCampaignPostsFilterSchema.default(TikTokCampaignPostsFilterSchema.parse({})),
  limit: z.number().default(LIMIT),
  page: z.number().default(INITIAL_PAGE_NUMBER)
});

export type TikTokCampaignPostsSearchSchemaType = z.infer<typeof TikTokCampaignPostsSearchSchema>;
export type TikTokCampaignPostsFilterFormValues = z.infer<typeof TikTokCampaignPostsFilterSchema>;
export type TikTokCampaignPostsFilterFormKeys = UnionOfKeys<TikTokCampaignPostsFilterFormValues>;
