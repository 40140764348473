import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllTiktokSimilarInfluencers.graphql';
import { Query, QueryallTiktokSimilarInfluencersArgs } from '../../__generated__/globalTypes';

export const useAllTiktokSimilarInfluencersQuery = (
  options?: QueryHookOptions<Query, QueryallTiktokSimilarInfluencersArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallTiktokSimilarInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllTiktokSimilarInfluencersLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallTiktokSimilarInfluencersArgs>
): QueryResult<Query, QueryallTiktokSimilarInfluencersArgs> & {
  getAllTiktokSimilarInfluencers: LazyQueryExecFunction<Query, QueryallTiktokSimilarInfluencersArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllTiktokSimilarInfluencers, result] = useLazyQuery<Query, QueryallTiktokSimilarInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllTiktokSimilarInfluencers, ...result };
};

export const useAllTiktokSimilarInfluencersPromiseQuery = () => {
  const client = useApolloClient();

  const getAllTiktokSimilarInfluencers = useCallback(
    (options?: Omit<QueryOptions<QueryallTiktokSimilarInfluencersArgs, Query>, 'query'>) =>
      client.query<Query, QueryallTiktokSimilarInfluencersArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllTiktokSimilarInfluencers };
};
