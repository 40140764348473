import { ReactNode, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  MarketplacePost,
  MarketplaceDetail,
  MarketplaceCampaignPostStatus,
  useUpdateMarketplacePostStatusV2Mutation,
  MarketplaceCampaignPostStatusForAdvertiser,
  namedOperations
} from '@/graphql';
import { Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useToggle } from '@/shared/hooks';
import { PostsWithoutRevenue } from './PostsWithoutRevenue';

const getPostStatusValidator = (newStatus: MarketplaceCampaignPostStatus) => {
  switch (newStatus) {
    case MarketplaceCampaignPostStatus.WARNING:
      return (status: MarketplaceCampaignPostStatusForAdvertiser) =>
        [
          MarketplaceCampaignPostStatusForAdvertiser.WARNING,
          MarketplaceCampaignPostStatusForAdvertiser.EFFECTIVE
        ].includes(status);
    case MarketplaceCampaignPostStatus.WARNING_SOLVED:
      return (status: MarketplaceCampaignPostStatusForAdvertiser) =>
        status === MarketplaceCampaignPostStatusForAdvertiser.WARNING;
    case MarketplaceCampaignPostStatus.APPROVED:
    case MarketplaceCampaignPostStatus.SHIPPED:
    case MarketplaceCampaignPostStatus.INEFFECTIVE:
      return (_: MarketplaceCampaignPostStatusForAdvertiser) => true;
    default:
      return null;
  }
};

interface Props {
  marketplaceId: MarketplaceDetail['id'];
}

export const useUpdateMarketplacePostsStatus = ({ marketplaceId }: Props) => {
  const loadingState = useToggle();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callUpdateMarketplacePostStatusV2 } = useUpdateMarketplacePostStatusV2Mutation({
    refetchQueries: [namedOperations.Query.AllMarketplacePosts]
  });
  const [errorModalState, setErrorModalState] = useState<{
    title: string;
    content?: ReactNode;
    newStatus: MarketplaceCampaignPostStatus;
  } | null>(null);
  const [confirmModalState, setConfirmModalState] = useState<{
    selectedPosts: MarketplacePost[];
    newStatus: MarketplaceCampaignPostStatus;
    updatingPostStatuses: MarketplaceCampaignPostStatusForAdvertiser[];
  } | null>(null);

  // MKP posts list which have status is MarketplaceCampaignPostStatusForAdvertiser
  // But BE set the update API status field is MarketplaceCampaignPostStatus
  // So it hard to maintain the post status type
  // Even through MarketplaceCampaignPostStatusForAdvertiser is "for advertiser"
  // But actually just naming for some reasons and it really not clear
  const statuses = {
    [MarketplaceCampaignPostStatus.SHIPPED]: { color: '#ffb63d', text: t('Option.Shipped') },
    [MarketplaceCampaignPostStatus.WARNING]: { color: '#ffb63d', text: t('Option.Warning') },
    [MarketplaceCampaignPostStatus.APPROVED]: { color: '#40b87c', text: t('Option.Approved') },
    [MarketplaceCampaignPostStatus.INEFFECTIVE]: { color: '#ff2b52', text: t('Option.Ineffective') },
    [MarketplaceCampaignPostStatus.EFFECTIVE]: { color: 'rgb(56, 146, 229)', text: t('Option.Effective') },
    [MarketplaceCampaignPostStatus.WARNING_SOLVED]: { color: '#ffb63d', text: t('Option.Warning Solved') },
    // Unsupported changes
    [MarketplaceCampaignPostStatus.TRACKING]: { color: '', text: '' },
    [MarketplaceCampaignPostStatus.AFFILIATE]: { color: '', text: '' },
    [MarketplaceCampaignPostStatusForAdvertiser.DRAFT]: { color: '', text: '' },
    [MarketplaceCampaignPostStatusForAdvertiser.JOINED]: { color: '', text: '' },
    [MarketplaceCampaignPostStatusForAdvertiser.READY_TO_POST]: { color: '', text: '' },
    [MarketplaceCampaignPostStatusForAdvertiser.DRAFT_REJECTED]: { color: '', text: '' }
  };

  const handleSelectStatus = (newStatus: MarketplaceCampaignPostStatus, selectedPosts: MarketplacePost[]) => {
    const updatingPostStatuses = selectedPosts.reduce<MarketplaceCampaignPostStatusForAdvertiser[]>(
      (acc, curr) => (curr.status && curr.status.valueOf() !== newStatus.valueOf() ? [...acc, curr.status] : acc),
      []
    );

    if (!updatingPostStatuses.length) {
      setErrorModalState({ newStatus, title: t('Dialog.No need to update status') });

      return;
    }

    const checkPostStatus = getPostStatusValidator(newStatus);
    if (!checkPostStatus) {
      setErrorModalState({ newStatus, title: t('Dialog.Unable to save'), content: t('Dialog.Invalid status') });

      return;
    }

    const invalidPostStatus = updatingPostStatuses.filter((status) => !checkPostStatus(status)).at(0);

    if (invalidPostStatus) {
      setErrorModalState({
        newStatus,
        title: t('Dialog.Unable to save'),
        content: (
          <Trans
            i18nKey="Dialog.FailedToChangeMarketplacePostStatus"
            components={{
              1: <span css={{ color: statuses[newStatus].color }} />,
              3: <span css={{ color: statuses[invalidPostStatus].color }} />
            }}
            values={{ to: statuses[newStatus].text, from: statuses[invalidPostStatus].text }}
          />
        )
      });
    } else {
      setConfirmModalState({ newStatus, selectedPosts, updatingPostStatuses });
    }
  };

  const resetConfirmModalState = () => {
    setConfirmModalState(null);
  };

  const resetErrorModalState = () => {
    setErrorModalState(null);
  };

  // If postId null, we will get influencerId instead, don't get both of them for a post
  const { postIds, influencerIds } = (confirmModalState?.selectedPosts || []).reduce<{
    postIds: (number | null)[];
    influencerIds: number[];
  }>(
    (acc, { id, influencer }) => ({
      postIds: [...acc.postIds, id ?? null],
      influencerIds: [...acc.influencerIds, influencer.id]
    }),
    { postIds: [], influencerIds: [] }
  );

  const handleUpdatePostsStatus = async (onSuccess?: () => void) => {
    if (
      !confirmModalState?.newStatus ||
      !confirmModalState.selectedPosts.length ||
      !confirmModalState.updatingPostStatuses.length
    ) {
      return;
    }
    loadingState.setOn();

    try {
      await callUpdateMarketplacePostStatusV2({
        variables: { input: { postIds, marketplaceId, influencerIds, status: confirmModalState.newStatus } }
      });
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      onSuccess?.();
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }

    resetConfirmModalState();
    loadingState.setOff();
  };

  return {
    handleSelectStatus,
    updating: loadingState.on,
    selectedStatus: confirmModalState?.newStatus || errorModalState?.newStatus,
    UpdateMarketplacePostStatusModal: ({ onSuccess }: { onSuccess?: () => void }) => (
      <>
        <Modal onClose={resetConfirmModalState} open={!!confirmModalState?.newStatus} css={{ width: 'max-content' }}>
          <ModalContent>
            <ModalTitle>{t('Dialog.Confirm Changes')}</ModalTitle>

            <PostsWithoutRevenue marketplacePostIds={postIds}>
              <div>
                {confirmModalState?.newStatus ? (
                  <Trans
                    i18nKey="Dialog.PostUpdateNotice"
                    values={{ status: statuses[confirmModalState.newStatus].text }}
                    components={{ 1: <span css={{ color: statuses[confirmModalState.newStatus].color }} /> }}
                  />
                ) : null}
              </div>
            </PostsWithoutRevenue>
          </ModalContent>
          <ModalFooterActions
            cancelButtonProps={{ onClick: resetConfirmModalState }}
            submitButtonProps={{
              title: t('Button.Approve'),
              loading: loadingState.on,
              onClick: () => handleUpdatePostsStatus(onSuccess)
            }}
          />
        </Modal>

        <Modal open={!!errorModalState?.title} onClose={resetErrorModalState} css={{ width: 'max-content' }}>
          <ModalContent>
            <ModalTitle>{errorModalState?.title}</ModalTitle>

            {errorModalState?.content ? <div css={{ marginTop: '16px' }}>{errorModalState.content}</div> : null}
          </ModalContent>
          <ModalFooterActions cancelButtonProps={{ title: t('Button.OK'), onClick: resetErrorModalState }} />
        </Modal>
      </>
    )
  };
};
