import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  OperationVariables,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TiktokAdvertiserAccountInfo.graphql';
import { Query } from '../../__generated__/globalTypes';

export const useTiktokAdvertiserAccountInfoQuery = (options?: QueryHookOptions<Query>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTiktokAdvertiserAccountInfoLazyQuery = (
  options?: LazyQueryHookOptions<Query>
): QueryResult<Query> & { getTiktokAdvertiserAccountInfoQuery: LazyQueryExecFunction<Query, OperationVariables> } => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  // TODO: should be getTiktokAdvertiserAccountInfo
  const [getTiktokAdvertiserAccountInfoQuery, result] = useLazyQuery<Query>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTiktokAdvertiserAccountInfoQuery, ...result };
};

export const useTiktokAdvertiserAccountInfoPromiseQuery = () => {
  const client = useApolloClient();

  const getTiktokAdvertiserAccountInfo = useCallback(
    (options?: Omit<QueryOptions<Record<string, unknown>, Query>, 'query'>) =>
      client.query<Query>({ ...options, query: QUERY }),
    [client]
  );

  return { getTiktokAdvertiserAccountInfo };
};
