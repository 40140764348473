import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const AutoManagedJoinedInfluencersFilterSchema = FilterValidations.keyword;
export const AutoManagedJoinedInfluencersSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(AutoManagedJoinedInfluencersFilterSchema, {
    keyword: ''
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type AutoManagedJoinedInfluencersSearchSchemaType = z.infer<typeof AutoManagedJoinedInfluencersSearchSchema>;
export type AutoManagedJoinedInfluencersFilterFormValues = z.infer<typeof AutoManagedJoinedInfluencersFilterSchema>;
export type AutoManagedJoinedInfluencersFilterFormKeys = UnionOfKeys<AutoManagedJoinedInfluencersFilterFormValues>;
