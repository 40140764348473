import { InstagramComparePrimaryAccount } from '@/graphql';
import { useSelectItems } from '@/shared/hooks';
import {
  InstagramComparePostsInDateDetailsModal,
  InstagramComparePostsInDateDetailsModalProps
} from '@/shared/molecules';
import {
  AnalyticsCompareOverviewChart,
  AnalyticsCompareOverviewLegend,
  AnalyticsCompareOverviewAccounts
} from '../../shared';
import { AnalyticsInstagramComparableAccountType } from '../types';
import { AnalyticsCompareInstagramOverviewTable } from './AnalyticsCompareInstagramOverviewTable';
import {
  useAnalyticsInstagramCompareOverviewData,
  useAnalyticsInstagramCompareOverviewPosts,
  useAnalyticsInstagramCompareOverviewLegend,
  UseAnalyticsInstagramCompareOverviewLegendProps
} from './hooks';

interface TemplateProps extends UseAnalyticsInstagramCompareOverviewLegendProps {
  mainAccountId: number;
  data: InstagramComparePrimaryAccount[];
  comparableAccounts: AnalyticsInstagramComparableAccountType[];
}

const MAX_VISIBLE_ACCOUNTS = 5;

export const Template = ({ data, mainAccountId, comparableAccounts, defaultSelectedLegendKeys }: TemplateProps) => {
  const { legendOptions, selectedLegendKeys, handleSelectLegendOption } = useAnalyticsInstagramCompareOverviewLegend({
    defaultSelectedLegendKeys
  });
  const { selectedItemIds: visibleAccountIds, setSelectedItemIds: setVisibleAccountIds } = useSelectItems({
    key: 'id',
    listItems: comparableAccounts,
    defaultSelectedIds: comparableAccounts.slice(0, MAX_VISIBLE_ACCOUNTS).map((acc) => acc.id)
  });
  const { chartData, allCategories } = useAnalyticsInstagramCompareOverviewData({
    data,
    visibleAccountIds,
    selectedLegendKeys,
    comparableAccounts
  });
  const { selectedPostDay, setSelectedPostDay, customChartOptions } = useAnalyticsInstagramCompareOverviewPosts({
    chartData
  });

  return (
    <>
      <div css={{ padding: '16px 12px' }}>
        <AnalyticsCompareOverviewLegend
          options={legendOptions}
          css={{ marginBottom: '20px' }}
          selectedOptions={selectedLegendKeys}
          onSelectOption={handleSelectLegendOption}
        />
        <AnalyticsCompareOverviewAccounts
          visibleAccountIds={visibleAccountIds}
          comparableAccounts={comparableAccounts}
          onSetVisibleAccountIds={setVisibleAccountIds}
        />
      </div>

      <AnalyticsCompareOverviewChart data={chartData} categories={allCategories} customOptions={customChartOptions} />

      <AnalyticsCompareInstagramOverviewTable data={data} comparableAccounts={comparableAccounts} />

      <InstagramComparePostsInDateDetailsModal
        postDate={selectedPostDay}
        mainAccountId={mainAccountId}
        onClose={() => setSelectedPostDay('')}
        comparableAccounts={comparableAccounts.reduce<
          InstagramComparePostsInDateDetailsModalProps['comparableAccounts']
        >(
          (acc, curr) =>
            visibleAccountIds.includes(curr.id)
              ? [...acc, { id: curr.id, name: curr.username, avatar: curr.avatarUrl }]
              : acc,
          []
        )}
      />
    </>
  );
};
