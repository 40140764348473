import { ErrorMessage } from '@/shared/atoms';
import { GenericOption, Option } from '@/shared/types';
import { MultiSelectField, MultiSelectFieldProps } from '../MultiSelectField';
import { ReactSingleSelectProps, SingleSelectField, SingleSelectFieldProps } from '../SingleSelectedField';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export interface SelectorFieldProps<T extends string = string, O extends GenericOption<string, any> = Option>
  // TODO: fix any type
  extends DefaultFieldProps<T, any, O> {
  loading?: boolean;
  hasBorderBottom?: boolean;
  multipleSelectorProps?: Partial<MultiSelectFieldProps>;
  singleSelectorProps?: Partial<SingleSelectFieldProps & ReactSingleSelectProps>;
}

export const SelectorField = <T extends string, O extends GenericOption<string, any> = GenericOption<string, any>>(
  props: SelectorFieldProps<T, O>
) => {
  const {
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldRegister: { ref, ...restRegister },
    fieldProps: {
      value,
      options = [],
      multiple,
      onSubmit,
      onChange,
      singleSelectorProps,
      multipleSelectorProps,
      ...restFieldProps
    }
  } = useFormatRHFFieldProps(props);

  const commonProps = {
    ...restRegister,
    ...restFieldProps,
    options,
    setValueOptions,
    handleSubmit: onSubmit,
    setFieldValue: onChange ? (field: string, value: any) => onChange(field as T, value, options) : setValue
  };

  return (
    <RHFFieldWrapper {...fieldWrapperProps} hideError>
      {({ hasError, errorMessage }) => (
        <>
          {multiple ? (
            <MultiSelectField
              {...commonProps}
              {...multipleSelectorProps}
              hasError={hasError}
              value={value as string[]}
            />
          ) : (
            <SingleSelectField {...commonProps} {...singleSelectorProps} hasError={hasError} value={value as string} />
          )}
          <ErrorMessage ref={ref} message={errorMessage} />
        </>
      )}
    </RHFFieldWrapper>
  );
};
