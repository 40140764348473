import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode, useMemo } from 'react';

import { SortableItemProvider } from './SortableItemContext';

export interface SortableItemProps {
  id: UniqueIdentifier;
  children?: ((props: { isDragging?: boolean }) => ReactNode) | ReactNode;
}

export const SortableItem = ({ children, id }: SortableItemProps) => {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id
  });
  const context = useMemo(
    () => ({ listeners, attributes, ref: setActivatorNodeRef }),
    [attributes, listeners, setActivatorNodeRef]
  );

  return (
    <SortableItemProvider value={context}>
      <div
        ref={setNodeRef}
        css={[
          { transition, transform: CSS.Translate.toString(transform) },
          isDragging && { zIndex: 99, position: 'relative' }
        ]}
      >
        {typeof children === 'function' ? children({ isDragging }) : children}
      </div>
    </SortableItemProvider>
  );
};
