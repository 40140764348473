import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { MediaPreview } from '@/shared/atoms';
import { ImageSlider } from '@/shared/molecules';

export interface PostMediaSliderPreviewProps {
  data?: string[];
  className?: string;
  currentMediaIndex?: number;
  onMediaChange?: (index: number, media: string) => void;
}

export const PostMediaSliderPreview = ({
  className,
  data = [],
  onMediaChange,
  currentMediaIndex
}: PostMediaSliderPreviewProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper}>
      <p css={{ fontSize: '12px', fontWeight: 600, marginBottom: '8px' }}>
        {`${t('General.Post')} ${(currentMediaIndex || 0) + 1} / ${data.length}`}
      </p>
      <ImageSlider total={data.length} className={className} css={{ '& .slick-track': { marginLeft: '0' } }}>
        {data.map((image, index) => (
          <MediaPreview
            key={index}
            src={image}
            objectFit="contain"
            onClick={() => onMediaChange?.(index, image)}
            css={styles.mediaPreview(currentMediaIndex === index)}
          />
        ))}
      </ImageSlider>
    </div>
  );
};
const styles = {
  wrapper: css({
    marginTop: '8px',
  }),
  mediaPreview: (isActive?: boolean) =>
    css({
      width: '96px',
      height: '96px',
      border: `3px solid ${isActive ? 'red' : 'transparent'}`
    })
};
