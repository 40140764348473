import { ReactElement, useEffect, useState } from 'react';

export interface DelayedProps {
  asChild?: boolean;
  awaitedTime?: number;
  children: ReactElement;
}

export const Delayed = ({ asChild, children, awaitedTime = 500 }: DelayedProps) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    let timeOutFunction: ReturnType<typeof setTimeout>;

    if (!asChild) {
      timeOutFunction = setTimeout(() => {
        setIsShown(true);
      }, awaitedTime);
    }

    return () => {
      if (timeOutFunction) {
        clearTimeout(timeOutFunction);
      }
    };
  }, [awaitedTime, asChild]);

  return isShown || asChild ? children : null;
};
