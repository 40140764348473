import { PostDetailStatistics } from '@/shared/types';
import { bigIntFormatter, convertDurationToHhMmSs, formatPercent } from '@/shared/utils';

type PostDetailStatisticsWithCollections = PostDetailStatistics & { collections?: number | null };
interface Props {
  statistics: PostDetailStatisticsWithCollections;
}

// Each place with same socialMedia has different metrics to show on UI based on API
// We can't handle for all cases in this component, so let control them outside
export const getSupportedStatistics = ({ statistics }: Props) => {
  const keys = Object.keys(statistics) as (keyof PostDetailStatisticsWithCollections)[];
  const {
    likes,
    reach,
    views,
    exits,
    saves,
    shares,
    replies,
    dislikes,
    tapsBack,
    comments,
    retweets,
    reactions,
    engagement,
    quoteTweet,
    tapsForward,
    collections,
    impressions,
    swipeForward,
    averageViewDuration,
    averageViewPercentage
  } = statistics;
  const allItems: Record<keyof PostDetailStatisticsWithCollections, { label: string; value: string }> = {
    views: { label: 'General.Views', value: bigIntFormatter(views, 0) },
    saves: { label: 'General.Save', value: bigIntFormatter(saves, 0) },
    exits: { label: 'General.Exits', value: bigIntFormatter(exits, 0) },
    likes: { label: 'General.Likes', value: bigIntFormatter(likes, 0) },
    reach: { label: 'General.Reach', value: bigIntFormatter(reach, 0) },
    dislikes: { label: 'Dislikes', value: bigIntFormatter(dislikes, 0) },
    shares: { label: 'General.Shares', value: bigIntFormatter(shares, 0) },
    replies: { label: 'General.Replies', value: bigIntFormatter(replies, 0) },
    tapsBack: { label: 'General.Tap back', value: bigIntFormatter(tapsBack, 0) },
    collections: { label: 'General.Collections', value: bigIntFormatter(collections, 0) },
    retweets: { label: 'General.Retweets', value: bigIntFormatter(retweets, 0) },
    comments: { label: 'General.Comments', value: bigIntFormatter(comments, 0) },
    impressions: { label: 'General.Imps', value: bigIntFormatter(impressions, 0) },
    reactions: { label: 'General.Reactions', value: bigIntFormatter(reactions, 0) },
    engagement: { label: 'General.Engagement', value: bigIntFormatter(engagement, 0) },
    quoteTweet: { label: 'General.Quote Tweet', value: bigIntFormatter(quoteTweet, 0) },
    tapsForward: { label: 'General.Tapforward', value: bigIntFormatter(tapsForward, 0) },
    swipeForward: { label: 'General.Swipeforward', value: bigIntFormatter(swipeForward, 0) },
    averageViewDuration: {
      label: 'General.Average View Duration',
      value: convertDurationToHhMmSs(averageViewDuration ?? null)
    },
    averageViewPercentage: {
      value: formatPercent(averageViewPercentage),
      label: 'General.Average View Duration Rate'
    }
  };

  return keys.reduce((rs, key) => [...rs, allItems[key]], [] as { label: string; value: string }[]);
};
