import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestInfluencerProfilePptx.graphql';
import { Mutation, MutationrequestInfluencerProfilePptxArgs } from '../../__generated__/globalTypes';

export const useRequestInfluencerProfilePptxMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestInfluencerProfilePptxArgs>
) => {
  const [callRequestInfluencerProfilePptx, result] = useMutation<Mutation, MutationrequestInfluencerProfilePptxArgs>(
    MUTATION,
    options
  );

  return { callRequestInfluencerProfilePptx, ...result };
};

export type RequestInfluencerProfilePptxMutationFunction = Mutation['requestInfluencerProfilePptx'];
