import { z } from 'zod';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const MarketplaceEditPostSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(
    z.object({
      draftPost: FilterValidations.string()
    }),
    {
      draftPost: ''
    }
  )
});

export type MarketplaceEditPostSearchSchemaType = z.infer<typeof MarketplaceEditPostSearchSchema>;
