import { css } from '@emotion/react';
import {
  InstagramInteractionCommonPostData,
  InstagramInteractionCommonTaggedPostData,
  useInstagramInteractionPostsQuery
} from '@/graphql';
import { CAN_NOT_GET_ANALYTICS_ACCOUNT, THEME } from '@/shared/constants';
import { bigIntFormatter, getValuableVariables } from '@/shared/utils';
import { usePostDetailsParam, useQueryHelper } from '@/shared/hooks';
import { NoDataContentWrapper, RenderDataWithFallback, MediaPreviewWithMetrics } from '@/shared/atoms';
import {
  ImageSlider,
  SectionWithHeader,
  InstagramInteractionTaggedPostDetailsModal,
  InstagramInteractionMentionedPostDetailsModal
} from '@/shared/molecules';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';

export interface AnalyticsInstagramInteractionPostsProps {
  brandAccountName?: string;
  analyticsAccount: AnalyticsSelectedAccountInfo;
  hasFreePlan: boolean;
  mockTaggedPosts?: InstagramInteractionCommonTaggedPostData[];
  mockMentionedPosts?: InstagramInteractionCommonPostData[];
}

export const AnalyticsInstagramInteractionPosts = ({
  brandAccountName,
  analyticsAccount,
  hasFreePlan,
  mockTaggedPosts,
  mockMentionedPosts = []
}: AnalyticsInstagramInteractionPostsProps) => {
  const { setPostDetailsParam } = usePostDetailsParam();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter, params } = useAnalyticsProfileSearch();
  const { data, loading } = useInstagramInteractionPostsQuery({
    fetchPolicy: 'network-only',
    skip: hasFreePlan,
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      analyticsAccountId: analyticsAccount.id
    }),
    onError: () => {
      enqueueSnackbar(t(CAN_NOT_GET_ANALYTICS_ACCOUNT), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  const interactionPosts = data?.instagramInteractionPosts;
  const taggedPosts = (hasFreePlan ? mockTaggedPosts : interactionPosts?.taggedPosts) || [];
  const mentionedPosts = hasFreePlan ? mockMentionedPosts : interactionPosts?.mentionedPosts || [];

  return (
    <div css={{ marginTop: '24px' }}>
      <RenderDataWithFallback loading={loading}>
        <SectionWithHeader
          title={t('HeaderColumn.Tagged Posts')}
          help={t('Tooltip.interactionTaggedPosts')}
          css={[styles.customHeader, styles.taggedPostsWrapper]}
          moreDetailProps={
            !loading && taggedPosts.length
              ? {
                  to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts',
                  params,
                  search: {
                    filter: {
                      brandAccountName,
                      endDate: filter.endDate,
                      startDate: filter.startDate
                    }
                  }
                }
              : undefined
          }
        >
          <NoDataContentWrapper css={styles.noDataContentWrapper} dataNotAvailable={!taggedPosts?.length}>
            <ImageSlider total={taggedPosts.length} css={{ margin: '0 16px' }}>
              {taggedPosts.map(({ id, likes, thumbNail, comments }) => (
                <MediaPreviewWithMetrics
                  key={id}
                  src={thumbNail}
                  objectFit="contain"
                  css={THEME.mediaPreview.default}
                  onClick={() => setPostDetailsParam(id)}
                  statistics={[
                    { label: t('Like'), value: bigIntFormatter(likes, 0, { k: true, m: true }) },
                    { label: t('General.Comments'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
                  ]}
                />
              ))}
            </ImageSlider>
          </NoDataContentWrapper>

          <InstagramInteractionTaggedPostDetailsModal
            posts={taggedPosts.map(
              (post): PostDetailsType => ({ id: post.id, mainSocialAccountId: analyticsAccount.id })
            )}
          />
        </SectionWithHeader>

        <SectionWithHeader
          title={t('HeaderColumn.Mentioned Posts')}
          help={t('Tooltip.interactionMentionedPosts')}
          css={[styles.customHeader, styles.mentionedPostsWrapper]}
          moreDetailProps={
            !loading && mentionedPosts.length
              ? {
                  to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts',
                  params,
                  search: {
                    filter: {
                      brandAccountName,
                      endDate: filter.endDate,
                      startDate: filter.startDate
                    }
                  }
                }
              : undefined
          }
        >
          <NoDataContentWrapper css={styles.noDataContentWrapper} dataNotAvailable={!mentionedPosts?.length}>
            <ImageSlider total={mentionedPosts.length} css={{ margin: '0 16px' }}>
              {mentionedPosts.map(({ id, likes, mediaUrl, comments }) => (
                <MediaPreviewWithMetrics
                  key={id}
                  src={mediaUrl}
                  objectFit="contain"
                  css={THEME.mediaPreview.default}
                  onClick={() => setPostDetailsParam(id)}
                  statistics={[
                    { label: t('Like'), value: bigIntFormatter(likes, 0, { k: true, m: true }) },
                    { label: t('General.Comments'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
                  ]}
                />
              ))}
            </ImageSlider>
          </NoDataContentWrapper>

          <InstagramInteractionMentionedPostDetailsModal
            posts={mentionedPosts.map(
              (post): PostDetailsType => ({ id: post.id, mainSocialAccountId: analyticsAccount.id })
            )}
          />
        </SectionWithHeader>
      </RenderDataWithFallback>
    </div>
  );
};
const styles = {
  noDataContentWrapper: css({ minHeight: '150px' }),
  customHeader: css({ '.section-header': { borderBottom: 'none' } }),
  taggedPostsWrapper: css({ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }),
  mentionedPostsWrapper: css({ marginTop: '1px', borderTopLeftRadius: 0, borderTopRightRadius: 0 })
};
