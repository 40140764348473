import { z } from 'zod';

export const PostsPriceSchema = z.object({
  rateCards: z.object({
    tiktok: z.string(),
    twitter: z.string(),
    youtube: z.string(),
    threads: z.string(),
    facebook: z.string(),
    instagram: z.string(),
    instagramStory: z.string()
  })
});
