import { TFunction } from 'i18next';
import { XhsEngagementInsightSection } from '@/graphql';
import { eyeBlueImage, pictureImage, chartLightYellowImage, heartImage, bookmarkLightBlueImage } from '@/shared/assets';

// https://adasiaholdings.atlassian.net/wiki/spaces/NC/pages/3845881901/Xiaohongshu+Influencer+Profile
export const getXhsAvgEngagement = ({
  xhsProfilePosts,
  t
}: {
  xhsProfilePosts?: XhsEngagementInsightSection | null;
  t: TFunction;
}) => [
  {
    icon: pictureImage,
    label: t('Avg posts/week'),
    value: xhsProfilePosts?.averagePostsPerWeek
  },
  {
    icon: chartLightYellowImage,
    label: t('Avg Engagement Views Rate'),
    value: xhsProfilePosts?.engagementViewsRate
  },
  {
    icon: eyeBlueImage,
    value: xhsProfilePosts?.averageViews,
    label: t('Avg Views')
  },
  {
    icon: heartImage,
    value: xhsProfilePosts?.averageLikes || null,
    label: t('Avg Likes')
  },
  {
    icon: bookmarkLightBlueImage,
    value: xhsProfilePosts?.averageSaved || null,
    label: t('Avg Saves')
  }
];
