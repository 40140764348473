import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { Button, ButtonProps, MenuTabButton, RadioButtons } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useUserRoles } from '@/auth';
import { ProposeInfluencersTableMode } from './schemaTypes';

export interface ProposeInfluencersTableModesProps {
  loading?: boolean;
  saveButtonProps?: ButtonProps;
  tableViewMode: ProposeInfluencersTableMode;
  jdMode?: ProposalJobDescriptionBreakDownMode;
  onTableViewModeChange: (mode: ProposeInfluencersTableMode) => void;
  onEditJdMode: (mode: ProposalJobDescriptionBreakDownMode) => void;
}

export const ProposeInfluencersTableModes = ({
  jdMode,
  tableViewMode,
  saveButtonProps,
  onTableViewModeChange,
  onEditJdMode
}: ProposeInfluencersTableModesProps) => {
  const { t } = useTranslation();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();

  const budgetOptions = [
    { label: t('Social Media'), value: ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN },
    { label: t('General.Influencer'), value: ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN }
  ];

  if (!isAdminStaffTalentAgencyPartner) return null;

  return (
    <div css={styles.wrapper}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <MenuTabButton
          icon="menu"
          title="View"
          onClick={() => onTableViewModeChange(ProposeInfluencersTableMode.VIEW)}
          active={tableViewMode === ProposeInfluencersTableMode.VIEW}
        />
        <MenuTabButton
          icon="eraser"
          title="Budget Edit"
          onClick={() => onTableViewModeChange(ProposeInfluencersTableMode.EDIT)}
          active={tableViewMode === ProposeInfluencersTableMode.EDIT}
        />
      </div>

      <div css={{ display: 'flex', alignItems: 'center' }}>
        <p css={styles.targetLabel}>{t('SetBudgetBy')}:</p>
        <RadioButtons items={budgetOptions} handleSelect={(value) => onEditJdMode(value)} value={jdMode} />
        <Button
          variant="blue"
          title={t('Button.Save')}
          css={{ padding: '0 16px', marginLeft: '8px' }}
          {...saveButtonProps}
        />
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    height: '48px',
    borderRadius: '3px 3px 0 0',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white
  }),
  targetLabel: css({
    fontSize: '12px',
    lineHeight: '18px',
    marginRight: '8px',
    fontWeight: 'normal',
    color: THEME.text.colors.black.lv1
  })
};
