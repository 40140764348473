import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import {
  InfluencerInstagramProfileTags,
  InfluencerInstagramProfilePosts,
  InfluencerInstagramProfileOverview,
  InfluencerInstagramProfileAudience,
  InfluencerInstagramProfileSponsoredPosts,
  InfluencerInstagramProfileMentionedAccounts,
  InfluencerInstagramProfileSimilarInfluencers
} from '../../InfluencerProfiles';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerInstagramProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerInstagramProfile = ({ socialAccount }: InfluencerInstagramProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerInstagramProfileOverview influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerInstagramProfileAudience influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerInstagramProfilePosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
      <InfluencerInstagramProfileSponsoredPosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
      <InfluencerInstagramProfileMentionedAccounts influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerInstagramProfileTags influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerInstagramProfileSimilarInfluencers
        influencerProfile={influencerProfile}
        socialAccount={socialAccount}
      />
    </>
  );
};
