import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RefreshPassword.graphql';
import { Mutation, MutationrefreshPasswordArgs } from '../../__generated__/globalTypes';

export const useRefreshPasswordMutation = (options?: MutationHookOptions<Mutation, MutationrefreshPasswordArgs>) => {
  const [callRefreshPassword, result] = useMutation<Mutation, MutationrefreshPasswordArgs>(MUTATION, options);

  return { callRefreshPassword, ...result };
};

export type RefreshPasswordMutationFunction = Mutation['refreshPassword'];
