import { useUpdateMarketplaceMutation } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import {
  MarketplaceFormValues,
  MarketplaceCampaignForms,
  useUpdateMarketplaceStatus,
  getCreateUpdateMarketplaceInput
} from '@/shared/organisms';
import { FormAction } from '@/shared/types';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';

export const EditMarketplaceCampaign = () => {
  const { marketplace } = useMarketplaceDetailsContext();
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { ApproveRejectWarningBar, DialogModals } = useUpdateMarketplaceStatus({ marketplace });
  const { callUpdateMarketplace } = useUpdateMarketplaceMutation({
    onCompleted: invalidateRouteLoader
  });

  const handleSubmit: FormAction<MarketplaceFormValues>['onSubmit'] = async (values) => {
    try {
      await callUpdateMarketplace({
        variables: { input: { ...getCreateUpdateMarketplaceInput(values), id: marketplace.id } }
      });
      enqueueSnackbar(t('succeededInUpdating', { name: values.title }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <div css={{ boxShadow: THEME.shadows.boxShadow }}>
      <ApproveRejectWarningBar />
      <MarketplaceCampaignForms onSubmit={handleSubmit} data={marketplace} type={marketplace.campaignType} />
      {DialogModals}
    </div>
  );
};
