import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Maybe, TiktokAdvertiserSummaryData } from '@/graphql';
import { CURRENCY_SYMBOLS, THEME } from '@/shared/constants';
import { formatPrice } from '@/shared/utils';
import { RechargeModal } from './RechargeModal';

interface PrepaidBalanceSummaryDataProps {
  summaryData: Maybe<TiktokAdvertiserSummaryData> | undefined;
}
export const PrepaidBalanceSummaryData = ({ summaryData }: PrepaidBalanceSummaryDataProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper}>
      <div>
        <h3 css={styles.balance}>{t('Prepaid balance')}</h3>
        <span css={{ fontSize: '32px', fontWeight: 600, lineHeight: '100%' }}>
          {summaryData ? CURRENCY_SYMBOLS[summaryData?.currencyId] : ''}
          {formatPrice(summaryData?.prepaidBalance)}
        </span>
      </div>
      {summaryData ? (
        <RechargeModal prepaidBalance={summaryData.prepaidBalance} currencyId={summaryData.currencyId} />
      ) : null}
    </div>
  );
};

const styles = {
  wrapper: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    backgroundColor: THEME.background.colors.white,
    borderRadius: '3px',
    boxShadow: THEME.shadows.boxShadow
  }),
  balance: css({ fontSize: '16px', fontWeight: 400, marginBottom: '6px', lineHeight: '100%' })
};
