import { useTranslation } from 'react-i18next';
import {
  TextSearchField,
  BasicFilterWrapper,
  SingleMonthPickerField,
  SingleMonthPickerFieldProps
} from '@/shared/molecules';
import { YoutubeCMSFilterFormKeys } from './schemaTypes';

export interface TemplateProps extends Pick<SingleMonthPickerFieldProps, 'disabledMonths'> {
  onSubmit?: () => void;
  searchPlaceholder?: string;
}

export const Template = ({ onSubmit, searchPlaceholder, disabledMonths }: TemplateProps) => {
  const { t } = useTranslation();

  return (
    <BasicFilterWrapper css={{ padding: '16px', borderRadius: '3px 3px 0 0' }}>
      <TextSearchField<YoutubeCMSFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={searchPlaceholder || t<string>('Search.YoutubeCMSChannels')}
      />
      <SingleMonthPickerField<YoutubeCMSFilterFormKeys>
        name="month"
        clearable={false}
        onSubmit={onSubmit}
        css={{ maxWidth: '184px' }}
        disabledMonths={disabledMonths}
      />
    </BasicFilterWrapper>
  );
};
