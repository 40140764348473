import { useTranslation } from 'react-i18next';
import { useAdvertiserPermissions } from '@/auth';
import { useAllAdvertisersQuery } from '@/graphql';
import { ButtonLink } from '@/shared/atoms';
import { Header, ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { AdvertisersFilter, AdvertisersList, useAccountsFilter } from '@/shared/organisms';

export const AdvertisersPage = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'documentTitle.Advertiser', appHeader: 'appHeader.Advertisers' });
  const { t } = useTranslation();
  const { hideAddAdvertiserBtn } = useAdvertiserPermissions();
  const { page, limit, filter } = useAccountsFilter();
  const { data, previousData, loading } = useAllAdvertisersQuery({
    fetchPolicy: 'network-only',
    variables: getValuableVariables({
      ...filter,
      limit,
      offset: getOffset(page, limit),
      tiktokPlan: filter.tiktokPlan || null,
      analyticsPlan: filter.analyticsPlan || null,
      verifiedStatus: filter.verifiedStatus || null
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allAdvertisersCount?.totalNumber;

  return (
    <>
      <Header>
        {!hideAddAdvertiserBtn ? (
          <ButtonLink
            to="/advertiser/add"
            title={t<string>('Button.Add Advertiser')}
            variant="blue"
            prefixIcon={{ icon: 'plus', size: '10px' }}
            css={{ minWidth: '140px', textTransform: 'capitalize', marginLeft: 'auto' }}
          />
        ) : null}
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <AdvertisersFilter />
        <AdvertisersList loading={loading} totalRecords={totalRecords} data={result?.allAdvertisers || []} />
      </ListWithPagination>
    </>
  );
};
