import { RangeSlider, RangeSliderProps } from '@mantine/core';
import { DefaultTFuncReturn } from 'i18next';
import { useRef, KeyboardEvent, ReactNode } from 'react';
import { GroupBase, InputActionMeta, SelectInstance } from 'react-select';
import { Label } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';
import { CreatableSingleSelector } from '../CreatableSingleSelector';

interface Field<T extends string = string> {
  name: T;
  disabled?: boolean;
  value?: number | '';
  placeholder?: string;
  defaultValue?: number | '';
}

export interface RangeWithSelectableProps<T extends string = string> {
  step?: number;
  help?: ReactNode;
  title?: DefaultTFuncReturn;
  minField: Field<T>;
  maxField: Field<T>;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  hideSlider?: boolean;
  hideInputs?: boolean;
  options: Option[];
  range?: { min: number; max: number };
  formatLabel?: (label: number) => string;
  setFieldValue: (field: string, value?: string | number) => void;
  hideDeselectOption?: boolean;
}

export const RangeWithSelectable = <T extends string = string>({
  help,
  title,
  range,
  options,
  step = 1,
  disabled,
  required,
  className,
  formatLabel,
  setFieldValue,
  hideSlider = false,
  hideInputs = false,
  hideDeselectOption,
  minField: {
    name: minName,
    value: minValue,
    disabled: minDisabled,
    placeholder: minPlaceholder,
    defaultValue: minDefaultValue
  },
  maxField: {
    name: maxName,
    value: maxValue,
    disabled: maxDisabled,
    placeholder: maxPlaceholder,
    defaultValue: maxDefaultValue
  }
}: RangeWithSelectableProps<T>) => {
  const minInputRef = useRef<SelectInstance<Option<string>, false, GroupBase<Option>>>(null);
  const maxInputRef = useRef<SelectInstance<Option<string>, false, GroupBase<Option>>>(null);

  const initialMinValue = minValue ?? minDefaultValue;
  const initialMaxValue = maxValue ?? maxDefaultValue;

  const minOptions =
    typeof initialMaxValue == 'number' ? options.filter((o) => Number(o.value) < initialMaxValue) : options;
  const maxOptions =
    typeof initialMinValue == 'number' ? options.filter((o) => Number(o.value) > initialMinValue) : options;
  const minInputValue: Option = {
    label: formatLabel
      ? formatLabel(Number(initialMinValue))
      : minOptions.find((o) => Number(o.value) === Number(initialMinValue))?.label || String(initialMinValue),
    value: String(initialMinValue)
  };
  const maxInputValue: Option = {
    label: formatLabel
      ? formatLabel(Number(initialMaxValue))
      : maxOptions.find((o) => Number(o.value) === Number(initialMaxValue))?.label || String(initialMaxValue),
    value: String(initialMaxValue)
  };

  const handleSliderChange: RangeSliderProps['onChange'] = (value) => {
    if (typeof value === 'object') {
      if (!minDisabled) {
        setFieldValue(minName, value[0]);
      }
      if (!maxDisabled) {
        setFieldValue(maxName, value[1]);
      }
    }
  };

  const handleInputChange = (name: string, value: string, { action }: InputActionMeta) => {
    if (name && action === 'input-change') {
      const newValue = value.replace(/[^0-9.]/g, '');
      setFieldValue(name, newValue);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLElement>, name: string, value?: string) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (minInputRef.current) {
        minInputRef.current.blur();
      }

      if (maxInputRef.current) {
        maxInputRef.current.blur();
      }

      setFieldValue(name, value);
    }
  };

  return (
    <div className={className} css={{ pointerEvents: disabled ? 'none' : 'auto' }}>
      {title ? <Label title={title} required={required} help={help} /> : null}

      {!hideSlider ? (
        <RangeSlider
          step={step}
          color="#3892E5"
          min={range?.min}
          max={range?.max}
          disabled={disabled}
          size={6}
          styles={{
            track: {
              '& :nth-child(1 of div.mantine-Slider-thumb)': {
                backgroundColor: minDisabled ? '#dee5ec' : '#fff',
                cursor: minDisabled ? 'not-allowed' : 'auto'
              },
              '& :nth-child(2 of div.mantine-Slider-thumb)': {
                backgroundColor: maxDisabled ? '#dee5ec' : '#fff',
                cursor: maxDisabled ? 'not-allowed' : 'auto'
              }
            },
            thumb: {
              height: '16px',
              width: '16px',
              backgroundColor: THEME.background.colors.white,
              borderWidth: '1px',
              borderColor: THEME.border.colors.gray.lv2
            }
          }}
          showLabelOnHover={false}
          label={null}
          css={{ margin: '0 2px 8px' }}
          onChange={handleSliderChange}
          aria-labelledby="range-slider"
          value={[Number(minValue), Number(maxValue)]}
          defaultValue={[Number(minDefaultValue), Number(maxDefaultValue)]}
        />
      ) : null}

      {!hideInputs ? (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box'
          }}
        >
          <CreatableSingleSelector
            ref={minInputRef}
            name={minName}
            options={minOptions}
            placeholder={minPlaceholder}
            setFieldValue={setFieldValue}
            isDisabled={minDisabled || disabled}
            value={minInputValue.value ? minInputValue : null}
            onInputChange={(value: string, action: InputActionMeta) => handleInputChange(minName, value, action)}
            onKeyDown={(e: KeyboardEvent<HTMLElement>) => onKeyDown(e, minName, minInputValue.value)}
            hideDeselectOption={hideDeselectOption}
            css={{ flex: 1 }}
          />
          <span
            css={{ margin: 'auto 8px', fontWeight: 'normal', fontSize: '14px', color: THEME.text.colors.black.lv1 }}
          >
            〜
          </span>
          <CreatableSingleSelector
            ref={maxInputRef}
            name={maxName}
            options={maxOptions}
            placeholder={maxPlaceholder}
            setFieldValue={setFieldValue}
            isDisabled={maxDisabled || disabled}
            value={maxInputValue.value ? maxInputValue : null}
            onInputChange={(value: string, action: InputActionMeta) => handleInputChange(maxName, value, action)}
            onKeyDown={(e: KeyboardEvent<HTMLElement>) => onKeyDown(e, maxName, maxInputValue.value)}
            hideDeselectOption={hideDeselectOption}
            css={{ flex: 1 }}
          />
        </div>
      ) : null}
    </div>
  );
};
