import { css } from '@emotion/react';
import { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Modal, Radio, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useParamsSearch, useQueryHelper } from '@/shared/hooks';
import { AnalyticsConnectableAccountsSearchStateSchemaType, AnalyticsFormValues } from '../schemaTypes';
import { ConnectableAccount, ConnectableAccountType } from './ConnectableAccount';

interface ConnectableAccountsModalState {
  title: string;
  open: boolean;
  description: string;
  data: { accounts?: ConnectableAccountType[]; mainAccount?: Omit<ConnectableAccountType, 'isUsed' | 'isSelected'> }[];
}

export const ConnectableAccountsModal = () => {
  const { watch, setValue } = useFormContext<AnalyticsFormValues>();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { state } = useParamsSearch<AnalyticsConnectableAccountsSearchStateSchemaType>();

  const connectableAccounts = state?.connectableAccounts || {};

  useEffect(() => {
    // We set twitter account directly because it has 1 connectable account only
    if (connectableAccounts.twitter) {
      setValue('twitter', {
        name: connectableAccounts.twitter.name,
        twitterUserId: connectableAccounts.twitter.twitterUserId
      });
      enqueueSnackbar(t('succeededInConnecting', { name: connectableAccounts.twitter.name }), { variant: 'success' });
    }
  }, []);

  const values = watch();
  const { open, title, data, description } = (() => {
    let modalState: ConnectableAccountsModalState = { data: [], title: '', open: false, description: '' };

    if (connectableAccounts.instagram?.length) {
      modalState = {
        open: true,
        title: t('Choose Instagram Account'),
        description: t('Select an Instagram account to connect to'),
        data: connectableAccounts.instagram.map((account) => ({
          mainAccount: {
            id: account.page.id,
            name: account.page.name,
            socialMedia: 'FACEBOOK_PAGE',
            avatar: account.page.avatar || '',
            followers: account.page.followersCount
          },
          accounts: [
            {
              socialMedia: 'INSTAGRAM',
              id: account.igAccount.id,
              name: account.igAccount.name,
              isUsed: account.isAlreadyUsed,
              avatar: account.igAccount.avatar || '',
              followers: account.igAccount.followersCount,
              isSelected: values.instagram?.id === +account.igAccount.id
            }
          ]
        }))
      };
    } else if (connectableAccounts.youtube?.length) {
      modalState = {
        open: true,
        title: t('Choose Youtube Channel'),
        description: t('Select an YouTube channel to connect to'),
        data: [
          {
            accounts: connectableAccounts.youtube.map((account) => ({
              socialMedia: 'YOUTUBE',
              id: account.channelId,
              avatar: account.avatar,
              isUsed: account.isUsed,
              name: account.channelName,
              followers: account.subscribersCount,
              isSelected: values.youtube?.channelId === account.channelId
            }))
          }
        ]
      };
    } else if (connectableAccounts.facebookPage?.pages.length) {
      modalState = {
        open: true,
        title: t('Choose Facebook Page'),
        description: t('Select the Facebook Page to connect to'),
        data: [
          {
            mainAccount: connectableAccounts.facebookPage.account
              ? {
                  socialMedia: 'FACEBOOK_PAGE',
                  id: connectableAccounts.facebookPage.account.id,
                  name: connectableAccounts.facebookPage.account.name,
                  avatar: connectableAccounts.facebookPage.account.avatar,
                  friends: connectableAccounts.facebookPage.account.friendCount
                }
              : undefined,
            accounts: connectableAccounts.facebookPage.pages.map((account) => ({
              id: account.id,
              name: account.name,
              avatar: account.avatar,
              socialMedia: 'FACEBOOK_PAGE',
              followers: account.followers,
              isUsed: account.isAlreadyUsed,
              isSelected: values.facebookPage?.fbPageId === account.id
            }))
          }
        ]
      };
    }

    return modalState;
  })();

  const closeConnectableAccountsModal = () => {
    navigate({});
  };

  const handleSelectAccount = (account: ConnectableAccountType, mainAccount?: ConnectableAccountType) => {
    switch (account.socialMedia) {
      case 'YOUTUBE':
        setValue('youtube', values.youtube ? undefined : { channelId: account.id, channelName: account.name });
        break;
      case 'INSTAGRAM':
        setValue(
          'instagram',
          values.instagram
            ? undefined
            : {
                id: +account.id,
                businessAccountId: '',
                fbPageName: account.name,
                fbPageId: mainAccount?.id || '',
                instagramAccountName: mainAccount?.name || ''
              }
        );
        break;
      case 'FACEBOOK_PAGE':
        setValue('facebookPage', values.facebookPage ? undefined : { fbPageId: account.id, fbPageName: account.name });
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      open={open}
      css={{ width: 'max-content' }}
      style={{
        content: {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
      shouldCloseOnOverlayClick={false}
      onClose={closeConnectableAccountsModal}
    >
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>

        {description ? <p css={styles.description}>{description}</p> : null}

        <div css={{ minWidth: '450px', marginTop: '24px' }}>
          {data.map(({ mainAccount, accounts }, index) => (
            <Fragment key={index}>
              {mainAccount ? <ConnectableAccount account={mainAccount} socialMedia={mainAccount.socialMedia} /> : null}
              {accounts?.map((account, idx) => (
                <div key={`account-${account.id}-${idx}`} css={{ position: 'relative', marginTop: '8px' }}>
                  <Radio
                    disabled={account.isUsed}
                    checked={account.isSelected}
                    css={styles.radio(account.isSelected)}
                    onClick={() => handleSelectAccount(account, mainAccount)}
                    label={<ConnectableAccount account={account} socialMedia={account.socialMedia} />}
                  />

                  {account.isUsed && <div css={styles.warning}>{t('This account already exists')}</div>}
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: closeConnectableAccountsModal }}
        submitButtonProps={{ title: t('Button.Add'), onClick: closeConnectableAccountsModal }}
      />
    </Modal>
  );
};

const styles = {
  description: css({
    marginTop: '12px',
    fontSize: '13px',
    lineHeight: 1.38
  }),
  radio: (checked?: boolean) =>
    css({
      gap: '12px',
      padding: '10px',
      borderRadius: '5px',
      border: `2px solid ${checked ? THEME.border.colors.blue.lv2 : THEME.border.colors.gray.lv2}`
    }),
  warning: css({
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '70px',
    display: 'flex',
    lineHeight: 1,
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'default',
    borderRadius: '5px',
    position: 'absolute',
    alignItems: 'center',
    letterSpacing: 'normal',
    justifyContent: 'center',
    color: THEME.text.colors.white,
    background: 'rgba(39, 49, 59, 0.6)'
  })
};
