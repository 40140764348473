import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/RequestedBcaBrands.graphql';
import { Query, QueryrequestedBcaBrandsArgs } from '../../__generated__/globalTypes';

export const useRequestedBcaBrandsQuery = (options?: QueryHookOptions<Query, QueryrequestedBcaBrandsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryrequestedBcaBrandsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useRequestedBcaBrandsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryrequestedBcaBrandsArgs>
): QueryResult<Query, QueryrequestedBcaBrandsArgs> & {
  getRequestedBcaBrands: LazyQueryExecFunction<Query, QueryrequestedBcaBrandsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getRequestedBcaBrands, result] = useLazyQuery<Query, QueryrequestedBcaBrandsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getRequestedBcaBrands, ...result };
};

export const useRequestedBcaBrandsPromiseQuery = () => {
  const client = useApolloClient();

  const getRequestedBcaBrands = useCallback(
    (options?: Omit<QueryOptions<QueryrequestedBcaBrandsArgs, Query>, 'query'>) =>
      client.query<Query, QueryrequestedBcaBrandsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getRequestedBcaBrands };
};
