import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useAllAdvertisersForSearchPromiseQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { GenericOption } from '@/shared/types';

interface AdvertisersOptionsProps {
  inputValue?: string;
  advertisersIds?: string[];
}

export const useAdvertisersLoadOptions = (includeIds: string[]) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { getAllAdvertisersForSearch } = useAllAdvertisersForSearchPromiseQuery();
  const [allAdvertisersOptions, setAllAdvertisersOptions] = useState<GenericOption<string>[]>([]);
  const [fetchState, setFetchState] = useState<'idle' | 'fetched' | 'loading'>('idle');

  const fetchAdvertisersOptions = async ({ inputValue, advertisersIds }: AdvertisersOptionsProps) => {
    let entitiesList = [] as GenericOption<string>[];
    try {
      setFetchState('loading');
      const { data, errors } = await getAllAdvertisersForSearch({
        variables: { keyword: inputValue, includeIds: advertisersIds?.map(Number) }
      });
      entitiesList =
        (advertisersIds?.length && !inputValue // initial load when we have already selected IDs
          ? data.allAdvertisersForSearch.includedAdvertisers
          : inputValue && advertisersIds?.length // mixed search with already selected IDs
            ? [...data.allAdvertisersForSearch.includedAdvertisers, ...data.allAdvertisersForSearch.advertisers]
            : data.allAdvertisersForSearch.advertisers
        ).map((o) => ({
          value: o.id.toString(),
          label: o.name
        })) || [];

      if (errors?.length) {
        enqueueSnackbar(t(errors.at(0)?.message || ''), { variant: 'error' });
      }

      setAllAdvertisersOptions(entitiesList.length ? entitiesList : []);
      setFetchState('fetched');
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      setFetchState('idle');
    }
  };

  useEffect(() => {
    if (includeIds?.length) {
      fetchAdvertisersOptions({ advertisersIds: includeIds });
    }
  }, []);

  const debouncedLoadAdvertisersOptions: ({ inputValue, advertisersIds }: AdvertisersOptionsProps) => void = debounce(
    ({ inputValue, advertisersIds }) => {
      fetchAdvertisersOptions({ inputValue, advertisersIds });
    },
    300
  );

  return {
    isLoading: fetchState === 'loading',
    fetchState,
    setFetchState,
    fetchAdvertisersOptions,
    debouncedLoadAdvertisersOptions,
    allAdvertisersIncludedOptions:
      fetchState === 'idle'
        ? allAdvertisersOptions.filter((o) => o.value && includeIds?.includes(o.value))
        : allAdvertisersOptions
  };
};
