import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAllCountryOptions } from '@/shared/hooks';
import { SelectorField, TextSearchField, BasicFilterWrapper } from '@/shared/molecules';
import { useEngagementStatusOptions } from '../hooks';
import { AdvancedFilter } from './AdvancedFilter';
import { EngagementCampaignsFilterFormValues, EngagementCampaignsFilterFormKeys } from './schemaTypes';

interface BasicFilterProps {
  onSubmit?: () => void;
}

export const BasicFilter = ({ onSubmit }: BasicFilterProps) => {
  const { t } = useTranslation();
  const { allCountryOptions } = useAllCountryOptions();
  const { engagementStatusOptions } = useEngagementStatusOptions();
  const { getValues } = useFormContext<EngagementCampaignsFilterFormValues>();

  const values = getValues();
  const advancedFilterValues = [
    values.salesPicIds,
    values.hubspotDealIds,
    values.influencerPicIds,
    values.campaignCategoryIds
  ];
  const numOfValidFilter = advancedFilterValues.filter((value) => value.length).length;

  return (
    <BasicFilterWrapper>
      <TextSearchField<EngagementCampaignsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.EngagementFilter')}
      />

      <SelectorField<EngagementCampaignsFilterFormKeys>
        name="status"
        onSubmit={onSubmit}
        css={{ maxWidth: '158px' }}
        options={engagementStatusOptions}
        placeholder={t<string>('Selector.Status')}
      />

      <SelectorField<EngagementCampaignsFilterFormKeys>
        name="countryIds"
        onSubmit={onSubmit}
        options={allCountryOptions}
        css={{ maxWidth: '158px' }}
        placeholder={t<string>('Selector.Countries')}
        multiple
      />

      <AdvancedFilter count={numOfValidFilter} onSubmit={onSubmit} />
    </BasicFilterWrapper>
  );
};
