import { useTranslation } from 'react-i18next';
import { NoDataTitle, NoDataContentWrapper, NoDataContentWrapperProps } from '@/shared/atoms';
import { DefaultTemplate, DefaultTemplateProps } from './DefaultTemplate';
import { GridTemplate, GridTemplateProps } from './GridTemplate';

export type AverageEngagementProps = Pick<
  NoDataContentWrapperProps,
  'header' | 'className' | 'wrapperLink' | 'wrapperText'
> &
  Pick<DefaultTemplateProps, 'width' | 'data'> &
  Pick<GridTemplateProps, 'itemsPerRow'> & {
    title?: string;
  };

export const AverageEngagement = ({
  data,
  width,
  header,
  title = 'Average Engagement',
  itemsPerRow = 1,
  ...noDataContentProps
}: AverageEngagementProps) => {
  const { t } = useTranslation();

  const hasNoData = !data.some((item) => !!item.value);

  return (
    <NoDataContentWrapper
      dataNotAvailable={hasNoData}
      header={header ?? <NoDataTitle css={{ padding: '10px' }} title={t(title)} />}
      {...noDataContentProps}
    >
      {itemsPerRow > 1 ? (
        <GridTemplate itemsPerRow={itemsPerRow} data={data} />
      ) : (
        <DefaultTemplate width={width} data={data} />
      )}
    </NoDataContentWrapper>
  );
};
