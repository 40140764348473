import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { DropdownOption } from '@/shared/types';

import { Button, ButtonProps } from './Button';
import { ButtonDropdown, ButtonDropdownProps } from './ButtonDropdown';

export type DownloadButtonProps<T extends DropdownOption = DropdownOption> = ButtonProps &
  Partial<Pick<ButtonDropdownProps<T>, 'options' | 'onSelectOption'>>;

export const DownloadButton = <T extends DropdownOption>({
  title,
  options,
  onSelectOption,
  ...restProps
}: DownloadButtonProps<T>) => {
  const { t } = useTranslation();

  const defaultProps: ButtonProps = {
    variant: 'white',
    title: title ?? t('Button.Download'),
    prefixIcon: { icon: 'download', size: '14px' },
    ...restProps
  };

  return options && onSelectOption ? (
    <ButtonDropdown
      css={!!title && styles.button}
      {...defaultProps}
      options={options}
      onSelectOption={onSelectOption}
    />
  ) : (
    <Button css={!!title && styles.button} {...defaultProps} />
  );
};
const styles = {
  button: css({ minWidth: '133px' })
};
