import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagementSocialAccountsBudget.graphql';
import { Mutation, MutationupdateEngagementSocialAccountsBudgetArgs } from '../../__generated__/globalTypes';

export const useUpdateEngagementSocialAccountsBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateEngagementSocialAccountsBudgetArgs>
) => {
  const [callUpdateEngagementSocialAccountsBudget, result] = useMutation<
    Mutation,
    MutationupdateEngagementSocialAccountsBudgetArgs
  >(MUTATION, options);

  return { callUpdateEngagementSocialAccountsBudget, ...result };
};

export type UpdateEngagementSocialAccountsBudgetMutationFunction = Mutation['updateEngagementSocialAccountsBudget'];
