import { CustomPermissions, UserDetail } from '@/graphql';

import { UserFormValues } from './types';

export const permissionsFieldsNameMapping = (key: keyof CustomPermissions) => {
  switch (key) {
    case 'report':
      return 'Report';
    case 'marketplace':
      return 'Marketplace';
    case 'engagement':
      return 'Engagement';
    case 'influencer':
      return 'General.Influencer';
    case 'influencerPackage':
      return 'Influencer package';
    case 'influencerAddressAndPaymentInformation':
      return 'Influencer personal information';
    case 'analytics':
      return 'General.Analytics';
    case 'partnerAgency':
      return 'Partner agency';
    case 'talentAgency':
      return 'Talent agency';
    case 'agency':
      return 'Agency';
    case 'advertiser':
      return 'Advertiser.Advertiser';
    case 'user':
      return 'User';
    case 'payment':
      return 'Payment';
    case 'customizeUserPermissions':
      return 'Customize permissions';
    default:
      return '';
  }
};

export const getUserFormValues = (
  userData?: UserDetail | null,
  mode: UserFormValues['mode'] | undefined = 'add'
): UserFormValues => ({
  mode,
  password: '',
  id: userData?.id,
  passwordConfirm: '',
  advertiserCountryId: '',
  hasInvitationEmail: true,
  name: userData?.name || '',
  email: userData?.email || '',
  agencyId: userData?.agencyId?.toString() || '',
  countryId: userData?.country.id || '',
  partnerId: userData?.partnerId?.toString() || '',
  permissions: userData?.permissions || null,
  advertiserId:
    userData?.advertisers.reduce<string | undefined>((prev, cr) => (cr ? String(cr.id) : prev), undefined) || '',
  advertiserIds: userData?.advertisers?.map((adv) => adv.id.toString()) || [],
  talentAgencyId: userData?.talentAgencyId?.toString() || '',
  notificationSetting: userData?.notificationSetting ?? true,
  autoLinkToAdvertiser: userData?.autoLinkToAdvertiser ?? false,
  userType: userData?.role as UserFormValues['userType']
});
export const defaultUserFormValues = getUserFormValues();
