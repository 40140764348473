import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Switch } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface TableSegmentOption<T extends string> {
  key: T;
  value: boolean;
  label?: ReactNode;
  loading?: boolean;
}

export interface TableSegmentProps<T extends string> {
  title?: ReactNode;
  className?: string;
  type: 'radio' | 'switch';
  options: TableSegmentOption<T>[];
  onChange: (option: TableSegmentOption<T>) => void;
}

export const TableSegment = <T extends string>({ type, title, options, onChange, className }: TableSegmentProps<T>) => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper} className={className}>
      <label css={{ fontWeight: 600 }}>{title || t('General.Day Segment')}</label>

      {options.map((option) => {
        const { key, label, value, loading } = option;

        return type === 'radio' ? (
          <Radio key={key} label={label} checked={value} css={{ marginLeft: '8px' }} onClick={() => onChange(option)} />
        ) : (
          <div css={styles.switchWrapper} key={key}>
            <p>{label}</p>
            <Switch loading={loading} onChange={() => onChange(option)} isChecked={value} />
          </div>
        );
      })}
    </div>
  );
};
const styles = {
  wrapper: css({
    height: '44px',
    display: 'flex',
    padding: '0 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderTop: '1px solid #eef3f7',
    backgroundColor: THEME.background.colors.white
  }),
  switchWrapper: css({
    gap: '5px',
    display: 'flex',
    marginLeft: '24px',
    alignItems: 'center',
    fontWeight: 'normal'
  })
};
