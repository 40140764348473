import { defaultAvatarImage } from '@/shared/assets';
import { THEME } from '@/shared/constants';
import { formatIntNumber, formatPercent, getGrowthArrow, getGrowthPrefix } from '@/shared/utils';

import {
  ChartTooltipMultipleMetricsDataType,
  ChartTooltipMultipleMetricsValueType,
  ChartTooltipMultipleMetricsSeriesDataType
} from './types';

export interface ChartTooltipMultipleMetricsProps<T extends string> {
  categories: string[]; // XAxis
  selectedCategory: string;
  data: Record<T, ChartTooltipMultipleMetricsSeriesDataType>;
  account?: { name?: string | null; avatar?: string | null };
  customTooltip?: {
    // Use one of props below at same time
    metrics?: T[][]; // Define metrics per row. Ex: [[followers], ['views', 'likes']], if undefined we will generate based on 'data' keys
    itemsPerRow?: number; // Default 3
  };
}

export const ChartTooltipMultipleMetrics = <T extends string>({
  data,
  account,
  categories,
  customTooltip,
  selectedCategory
}: ChartTooltipMultipleMetricsProps<T>) => {
  const keys = Object.keys(data) as T[];
  const itemsPerRow = customTooltip?.itemsPerRow || 3;
  const metricsPerRows = !customTooltip?.metrics
    ? keys.reduce<T[][]>((acc, _, index, array) => {
        const newRow = array.slice(itemsPerRow * index, itemsPerRow * (index + 1));

        return newRow.length ? [...acc, newRow] : acc;
      }, [])
    : customTooltip.metrics;
  const numberMetricsPerRows = metricsPerRows.map((row) => row.length);
  const largestNumberItemInRow = [...numberMetricsPerRows].sort((a, b) => a - b)[numberMetricsPerRows.length - 1];
  const tooltipData = categories.reduce<ChartTooltipMultipleMetricsDataType<T>>(
    (acc, curr, index) => ({
      ...acc,
      [curr]: keys.reduce((acc, key) => {
        const serie = data[key];
        const value = serie.data[index] as number;
        const prevValue = serie.data[index - 1] as number;

        return {
          ...acc,
          [key]: {
            increment: serie.showIncrement && index !== 0 ? value - prevValue : 0,
            value: serie.formatType === 'percentage' ? formatPercent(value) : formatIntNumber(value)
          }
        };
      }, {} as ChartTooltipMultipleMetricsValueType<T>)
    }),
    {}
  );

  // Must be inline-style because this component will be rendered via renderToStaticMarkup where it used
  return (
    <div
      style={{
        margin: '-2px',
        display: 'flex',
        fontSize: '12px',
        borderRadius: '3px',
        padding: '13px 16px',
        flexDirection: 'column',
        backgroundColor: '#27313b',
        border: '2px solid #27313b'
      }}
    >
      {account ? (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          {account.avatar ? (
            <img
              alt={account.name || ''}
              src={account.avatar || defaultAvatarImage}
              style={{ width: '24px', height: '24px', borderRadius: '50%' }}
            />
          ) : null}
          <p style={{ color: THEME.text.colors.white, fontSize: '13px', fontWeight: 600, marginLeft: '8px' }}>
            {account.name}
          </p>
        </div>
      ) : null}

      <h3 style={{ marginBottom: '8px', fontSize: '13px', color: THEME.text.colors.white }}>{selectedCategory}</h3>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${largestNumberItemInRow}, minmax(max-content, auto))`
        }}
      >
        {metricsPerRows.map((row, index) =>
          row.map((key, rowIndex) => {
            const increment = tooltipData[selectedCategory!]?.[key]?.increment;
            // Dynamic grid items per row
            const customizedItem =
              numberMetricsPerRows[index] === 1
                ? { gridColumn: `1 / span ${largestNumberItemInRow}` }
                : (numberMetricsPerRows[index] as number) < (largestNumberItemInRow as number) &&
                    rowIndex === row.length - 1
                  ? { gridColumn: `${rowIndex + 1} / -1` }
                  : undefined;

            return (
              <div
                style={{
                  minWidth: '50px',
                  padding: '6px 2px',
                  width: 'max-content',
                  boxSizing: 'border-box',
                  borderLeft: '1px solid #6e7c89',
                  marginLeft: rowIndex === 0 ? '0' : '8px',
                  ...customizedItem
                }}
              >
                <p style={{ color: THEME.text.colors.gray.lv1, fontSize: '11px' }}>{data[key].name}</p>
                <p style={{ fontSize: '13px', color: THEME.text.colors.white, whiteSpace: 'nowrap' }}>
                  {tooltipData[selectedCategory!]?.[key]?.value}
                  {increment ? (
                    <span style={{ color: `${increment > 0 ? '#40b87c' : '#ff2b52'}` }}>
                      {getGrowthPrefix(increment)}
                      {increment}
                      {getGrowthArrow(increment)}
                    </span>
                  ) : null}
                </p>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
