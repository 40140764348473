import { zodValidator } from '@tanstack/zod-adapter';
import { CampaignPostsDiscovery } from '@/pages/CampaignPostsDiscovery';
import { CampaignPostsDiscoverySearchSchema } from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const CampaignPostsDiscoveryRouter = {
  '/_private-routes/campaign-posts-discovery': [
    CampaignPostsDiscovery,
    { validateSearch: zodValidator(CampaignPostsDiscoverySearchSchema) }
  ]
} as const satisfies PartialRouter;
