import { z } from 'zod';
import { EngagementCampaignPostStatusForInsightCheck } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
export const PostInsightDetailsSearchFilterSchema = z.object({
  filter: SearchFilterDefaults.filter(
    z.object({ status: z.nativeEnum(EngagementCampaignPostStatusForInsightCheck).optional() }),
    {}
  )
});

export type PostInsightDetailsSearchFilterSchemaType = z.infer<typeof PostInsightDetailsSearchFilterSchema>;
