import { useTranslation } from 'react-i18next';
import { DownloadButton } from '@/shared/atoms';
import { ListWithPagination } from '@/shared/molecules';
import { useDownloadFile, useParamsSearch } from '@/shared/hooks';
import { getOffset, getValuableVariables, getAccountManagementApiUrl } from '@/shared/utils';
import { useInfluencerYoutubeAnalyticsPostsQuery } from '@/graphql';
import { ProfilePostsCount, ProfilePostsFilter, YtVideosSearchSchemaType } from '@/shared/organisms';
import { useInitialInfluencerPostsListWithName } from '../hooks';
import { InfluencerYoutubeVideosTable } from './InfluencerYoutubeVideosTable';

export const InfluencerYoutubeVideos = () => {
  const { t } = useTranslation();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName({
    titleWithName: 'appHeader.VideoListWithAccountName',
    title: 'Video List'
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<YtVideosSearchSchemaType>();
  const { data, previousData, loading } = useInfluencerYoutubeAnalyticsPostsQuery({
    skip: !influencerId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      socialAccountId: mainSocialAccountId,
      orderBy: sort,
      endDate: null,
      startDate: null,
      keyword: filter.keyword,
      offset: getOffset(page, limit)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerYoutubeAnalyticsPostsCount.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} />

      <ProfilePostsCount count={totalRecords} countLabel={t('Videos found')}>
        <DownloadButton
          loading={downloading}
          onClick={() =>
            handleDownloadFile({
              filenamePrefix: 'influencersYTComments',
              url: getAccountManagementApiUrl(`api/analytics/youtube/influencer/comments/${mainSocialAccountId}`)
            })
          }
        />
      </ProfilePostsCount>

      <InfluencerYoutubeVideosTable
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.influencerYoutubeAnalyticsPosts?.posts || []}
      />
    </ListWithPagination>
  );
};
