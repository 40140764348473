import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter } from '@/shared/utils';
import { THEME } from '@/shared/constants';
import { Maybe } from '@/graphql';
import { LinkifyItPreview } from '@/shared/molecules';

interface RequestModificationMessageProps {
  date?: string;
  className?: string;
  message?: Maybe<string>;
}

export const RequestModificationMessage = ({ className, date = '', message }: RequestModificationMessageProps) => {
  const { t, i18n } = useTranslation();

  return message ? (
    <div css={{ backgroundColor: '#F6F8FA', padding: '16px', marginTop: '16px' }} className={className}>
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <p>{t('Request message')}:</p>
        {date ? <p>{localizedDateFormatter(date, 'PPP', i18n.language as AppLanguage)}</p> : null}
      </div>
      <LinkifyItPreview value={message} css={styles.rejectedMessage} />
    </div>
  ) : null;
};

const styles = {
  rejectedMessage: css({
    maxHeight: '300px',
    marginTop: '8px',
    background: THEME.background.colors.gray.lv1
  })
};
