import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, InternalLinkProps, HelpIcon, Icon, IconProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface SectionWithHeaderProps {
  help?: ReactNode;
  title?: ReactNode;
  className?: string;
  hasHeader?: boolean;
  children?: ReactNode;
  headerChildren?: ReactNode;
  prefixIcon?: IconProps | string;
  moreDetailProps?: InternalLinkProps & { hasTopBorder?: boolean; title?: string };
}

// Usually use this component to wrap section in Analytics and Influencer Profile page
export const SectionWithHeader = ({
  help,
  title,
  children,
  hasHeader = true,
  className,
  prefixIcon,
  headerChildren,
  moreDetailProps
}: SectionWithHeaderProps) => {
  const { t } = useTranslation();

  const { title: moreLinkText, hasTopBorder, ...moreLinkProps } = moreDetailProps || {};

  return (
    <div css={styles.wrapper} className={className}>
      {hasHeader ? (
        <div css={styles.header} className="section-header">
          {!prefixIcon ? null : typeof prefixIcon === 'string' ? (
            <img src={prefixIcon} css={{ marginRight: '8px', width: '24px' }} alt="header icon" />
          ) : (
            <Icon css={{ marginRight: '8px' }} size={24} {...prefixIcon} />
          )}
          <div css={{ fontSize: '16px', fontWeight: 400, lineHeight: '1.22' }}>{title}</div>
          {help && <HelpIcon help={help} position="top" css={{ marginLeft: '6px' }} />}
          {headerChildren}
        </div>
      ) : null}

      {children}

      {moreDetailProps ? (
        <div css={styles.moreLinkWrapper(hasTopBorder)}>
          <Anchor css={styles.link} {...moreLinkProps}>
            <span>{moreLinkText || t('Button.More')}</span>
            <Icon icon="arrow-right" color={THEME.icon.colors.blue} size="11px" css={{ marginLeft: '4px' }} />
          </Anchor>
        </div>
      ) : null}
    </div>
  );
};
const styles = {
  wrapper: css({
    borderRadius: '3px',
    boxSizing: 'border-box',
    boxShadow: THEME.shadows.boxShadow,
    backgroundColor: THEME.background.colors.white
  }),
  header: css({
    height: '48px',
    display: 'flex',
    padding: '0 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: '1px solid #e6ecf0'
  }),
  moreLinkWrapper: (hasTopBorder?: boolean) =>
    css({
      padding: '16px',
      boxSizing: 'border-box',
      ...(hasTopBorder ? { marginTop: '-1px', borderTop: '1px solid #E6ECF0' } : {})
    }),
  link: css({
    display: 'flex',
    fontWeight: 600,
    fontSize: '12px',
    textAlign: 'right',
    marginLeft: 'auto',
    width: 'max-content',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: THEME.text.colors.gray.lv3,
    '&:hover': { opacity: 0.8, color: THEME.text.colors.gray.lv3, textDecoration: 'none' }
  })
};
