import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/CustomerPortalLink.graphql';
import { Query, QuerycustomerPortalLinkArgs } from '../../__generated__/globalTypes';

export const useCustomerPortalLinkQuery = (options?: QueryHookOptions<Query, QuerycustomerPortalLinkArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerycustomerPortalLinkArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useCustomerPortalLinkLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerycustomerPortalLinkArgs>
): QueryResult<Query, QuerycustomerPortalLinkArgs> & {
  getCustomerPortalLink: LazyQueryExecFunction<Query, QuerycustomerPortalLinkArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getCustomerPortalLink, result] = useLazyQuery<Query, QuerycustomerPortalLinkArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getCustomerPortalLink, ...result };
};

export const useCustomerPortalLinkPromiseQuery = () => {
  const client = useApolloClient();

  const getCustomerPortalLink = useCallback(
    (options?: Omit<QueryOptions<QuerycustomerPortalLinkArgs, Query>, 'query'>) =>
      client.query<Query, QuerycustomerPortalLinkArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getCustomerPortalLink };
};
