import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, FormStyle } from '@/shared/atoms';
import { TalentInfluencerFormValues } from '../../schemaTypes';
import { ConnectedAccounts } from './ConnectedAccounts';
import { useInitialTalentSocialAccounts, useTalentInfluencerConnectSocialAccount } from './hooks';

interface SocialAccountsProps {
  influencerId?: number;
}

export const SocialAccounts = ({ influencerId }: SocialAccountsProps) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors }
  } = useFormContext<TalentInfluencerFormValues>();
  const values = getValues();
  const { igAccounts, ytAccounts, fbAccounts, twitterAccounts } = useInitialTalentSocialAccounts({
    influencerId,
    values
  });
  const { handleConnect, handleReconnect, connecting, reconnecting } = useTalentInfluencerConnectSocialAccount({
    influencerId,
    values
  });

  return (
    <div css={{ position: 'relative', paddingBottom: '24px' }}>
      <FormStyle.SectionTitle index={1} label={t('Social Account')} css={{ marginBottom: '24px' }} />

      <ConnectedAccounts
        socialMedia="INSTAGRAM"
        accounts={igAccounts}
        loading={connecting || reconnecting}
        onReconnect={(data) => handleReconnect('INSTAGRAM', data)}
        onSignIn={(account) => handleConnect('INSTAGRAM', { instagramAccountName: account?.name })}
      />
      <ConnectedAccounts
        socialMedia="YOUTUBE"
        accounts={ytAccounts}
        css={{ marginTop: '24px' }}
        loading={connecting || reconnecting}
        onSignIn={(account) => handleConnect('YOUTUBE', { channelId: account?.id })}
      />
      <ConnectedAccounts
        limit={10000} // just set unlimited adding
        socialMedia="FACEBOOK"
        accounts={fbAccounts}
        css={{ marginTop: '24px' }}
        loading={connecting || reconnecting}
        onSignIn={() => handleConnect('FACEBOOK', { needOverrideFbFanpages: true })}
      />
      <ConnectedAccounts
        socialMedia="TWITTER"
        accounts={twitterAccounts}
        css={{ marginTop: '24px' }}
        loading={connecting || reconnecting}
        onSignIn={(account) => handleConnect('TWITTER', { screenName: account?.name })}
      />
      <ErrorMessage message={t<string>(errors.connectedFacebook?.message || '')} css={{ bottom: '0' }} />
    </div>
  );
};
