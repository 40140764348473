import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreatePartnerUser.graphql';
import { Mutation, MutationcreatePartnerUserArgs } from '../../__generated__/globalTypes';

export const useCreatePartnerUserMutation = (
  options?: MutationHookOptions<Mutation, MutationcreatePartnerUserArgs>
) => {
  const [callCreatePartnerUser, result] = useMutation<Mutation, MutationcreatePartnerUserArgs>(MUTATION, options);

  return { callCreatePartnerUser, ...result };
};

export type CreatePartnerUserMutationFunction = Mutation['createPartnerUser'];
