import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { getYesterday } from '@/shared/utils';
import { LIMIT_50 } from '@/shared/constants';
import { InstagramPostSortOrder, Order } from '@/graphql';

export const AnalyticsInstagramHashtagsFilterSchema = z.object({
  date: FilterValidations.string().default(getYesterday()),
  keyword: FilterValidations.string(),
  hashtagId: FilterValidations.string()
});
export const AnalyticsInstagramHashtagsSearchSchema = z.object({
  filter: AnalyticsInstagramHashtagsFilterSchema.default({}),
  sort: SearchFilterDefaults.sort(InstagramPostSortOrder).default({
    order: Order.ASC,
    field: InstagramPostSortOrder.RANK
  }),
  ...SearchFilterDefaults.pageLimit(LIMIT_50)
});

export type AnalyticsInstagramHashtagsFilterFormValues = z.infer<typeof AnalyticsInstagramHashtagsFilterSchema>;
export type AnalyticsInstagramHashtagsFilterFormKeys = UnionOfKeys<AnalyticsInstagramHashtagsFilterFormValues>;
export type AnalyticsInstagramHashtagsSearchSchemaType = z.infer<typeof AnalyticsInstagramHashtagsSearchSchema>;
