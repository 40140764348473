import { useCreateTalentInfluencerMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import {
  TalentInfluencerForm,
  TalentInfluencerFormValues,
  getCreateUpdateTalentInfluencerInput
} from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const AddInfluencerForTalentAgency = () => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateTalentInfluencer } = useCreateTalentInfluencerMutation();

  const handleSubmit: FormAction<TalentInfluencerFormValues>['onSubmit'] = async (values) => {
    try {
      const input = getCreateUpdateTalentInfluencerInput(values);
      await callCreateTalentInfluencer({ variables: { input } });

      enqueueSnackbar(t('succeededInCreating', { name: values.name }), { variant: 'success' });
      navigate({ to: '/influencers' });
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name: values.name }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return <TalentInfluencerForm onSubmit={handleSubmit} />;
};
