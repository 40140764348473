import { useTranslation } from 'react-i18next';
import { AnalyticsYoutubeDashboardLegendType } from '../types';
import { allLegendOptions } from '../utils';

export const useAnalyticsYoutubeDashboardOverviewLegend = () => {
  const { t } = useTranslation();

  const availableLegendOptions: AnalyticsYoutubeDashboardLegendType[] = allLegendOptions.map((option) => ({
    ...option,
    label: option.label ? t<string>(option.label) : ''
  }));

  return { availableLegendOptions };
};
