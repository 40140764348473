import { z } from 'zod';
import { CheckBoxProps } from '@/shared/atoms';
import { FilterValidations } from '@/shared/validations';
import {
  SortType,
  SortAction,
  UnionOfKeys,
  LimitAction,
  FilterAction,
  ToggleOption,
  TtcmStatusOption,
  InfluencersTabType
} from '@/shared/types';
import {
  Genders,
  CampaignType,
  SocialPostType,
  SocialAccountType,
  SocialAccountStatus,
  QuerySocialAccountType,
  InfluencerStatusForSearch,
  FollowersGrowthPeriodEnum,
  InfluencerSearchKeywordField,
  QueryallInstagramSimilarInfluencersV3Args
} from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { WidgetSelectButtonProps } from './shared';

export const InfluencersFilterBaseSchema = z.object({
  tab: FilterValidations.enum(InfluencersTabType)
});
export const InfluencersFilterSchema = InfluencersFilterBaseSchema.extend({
  keyword: FilterValidations.string(),
  countryId: FilterValidations.string(),
  audienceAge: FilterValidations.string(),
  minPostDate: FilterValidations.string(),
  maxPostDate: FilterValidations.string(),
  audienceRatio: FilterValidations.string(),
  regionIds: FilterValidations.arrayString(),
  interests: FilterValidations.arrayString(),
  includeIds: FilterValidations.arrayString(),
  countryIds: FilterValidations.arrayString(),
  categoryIds: FilterValidations.arrayString(),
  campaignCategoryIds: FilterValidations.arrayString(),
  talentAgencyOrPartnerIds: FilterValidations.arrayString(),
  minAge: FilterValidations.number(),
  maxAge: FilterValidations.number(),
  minViews: FilterValidations.number(),
  maxViews: FilterValidations.number(),
  minReach: FilterValidations.number(),
  maxReach: FilterValidations.number(),
  minSaveRate: FilterValidations.number(),
  maxSaveRate: FilterValidations.number(),
  maxPostPrice: FilterValidations.number(),
  minPostPrice: FilterValidations.number(),
  minFollowers: FilterValidations.number(),
  maxFollowers: FilterValidations.number(),
  minStoryPrice: FilterValidations.number(),
  maxStoryPrice: FilterValidations.number(),
  minVideoPrice: FilterValidations.number(),
  maxVideoPrice: FilterValidations.number(),
  minPicturePrice: FilterValidations.number(),
  maxPicturePrice: FilterValidations.number(),
  minEngagementRate: FilterValidations.number(),
  maxEngagementRate: FilterValidations.number(),
  minCampaignsJoined: FilterValidations.number(),
  maxCampaignsJoined: FilterValidations.number(),
  minEngagementViewsRate: FilterValidations.number(),
  maxEngagementViewsRate: FilterValidations.number(),
  minEngagementFollowersRate: FilterValidations.number(),
  maxEngagementFollowersRate: FilterValidations.number(),
  followerGrowthPercentage: FilterValidations.number(),
  audienceGender: FilterValidations.enum(Genders),
  status: FilterValidations.enum(SocialAccountStatus),
  ttcmStatus: FilterValidations.enum(TtcmStatusOption),
  campaignDisplay: FilterValidations.enum(ToggleOption),
  keywordField: FilterValidations.enum(InfluencerSearchKeywordField),
  followerGrowthPeriod: FilterValidations.enum(FollowersGrowthPeriodEnum),
  socialMedia: FilterValidations.enum({ ...QuerySocialAccountType, ...SocialPostType }),
  genders: FilterValidations.arrayEnum(Genders),
  statuses: FilterValidations.arrayEnum(InfluencerStatusForSearch),
  socialMedias: FilterValidations.arrayEnum(QuerySocialAccountType),
  demographicAge: FilterValidations.string(), // for API we will split to {start: Int!, end: Int!}
  demographicGender: FilterValidations.enum(Genders),
  demographicAgeMinRate: FilterValidations.string(),
  demographicGenderMinRate: FilterValidations.string()
});
export const InfluencersBaseSearchSchema = z.object({
  filter: InfluencersFilterSchema.partial().default(InfluencersFilterSchema.parse({})),
  sort: FilterValidations.sort({} as any)
    .default({
      field: null,
      order: null
    })
    .optional(),
  page: z.number().default(1),
  limit: z.number().default(LIMIT),
  state: z
    .object({ skipSearch: z.boolean().default(false).optional() })
    .default({})
    .optional()
});

export type InfluencersBaseSearchSchemaType = z.infer<typeof InfluencersBaseSearchSchema>;
export type InfluencersFilterFormValues = z.infer<typeof InfluencersFilterSchema>;
export type InfluencersFilterFormKeys = UnionOfKeys<InfluencersFilterFormValues>;

export interface BasicFilterDefaultProps {
  onSubmit?: () => void;
  isEngagementPage?: boolean;
  isTtcmCampaign?: boolean;
  isPackagePage?: boolean;
}

export interface AdvancedFilterDefaultProps {
  count: number;
  onSubmit?: () => void;
  isTtcmCampaign?: boolean;
  isEngagementPage?: boolean;
}

export interface DefaultInfluencersProps<Profile = never> {
  isPackagePage?: boolean;
  isTtcmCampaign?: boolean;
  isEngagementPage?: boolean;
  getListSelectButtonProps?: (influencer: Profile) => WidgetSelectButtonProps;
  getSortSelectButtonProps?: (listRecords: readonly Profile[], totalRecords: number) => SortSelectButtonProps;
}

export interface SelectedSocialMediaAccountsType {
  influencerId: number;
  socialAccountId?: number | string | null;
  socialAccountType: SocialAccountType | null;
}

export interface SortSelectButtonProps extends CheckBoxProps {
  hasAllSelected?: boolean;
  isInfluencerAccount?: boolean;
  selectedAccountsInPage?: number[];
  selectedSocialMediaAccounts?: SelectedSocialMediaAccountsType[];
}

export interface InfluencersContextReturnType<
  Profile,
  F extends Partial<InfluencersFilterFormValues>,
  S extends string = string
> extends LimitAction,
    SortAction<S>,
    FilterAction<F> {
  page: number;
  loading: boolean;
  totalRecords: number;
  listRecords: readonly Profile[];
  isPackagePage?: boolean;
  isTtcmCampaign?: boolean;
  isEngagementPage?: boolean;
  sortSelectButtonProps?: SortSelectButtonProps;
  getListSelectButtonProps?: (influencer: Profile) => WidgetSelectButtonProps;
  setSortAndLimit: ({ sort, limit }: { sort: SortType<S>; limit: number }) => void;
}

export interface InfluencersSortFormValues<T extends string = string> {
  sort: SortType<T>;
  limit: string;
}

export type InfluencersSortFormKeys = keyof InfluencersSortFormValues<string>;

export interface PostType {
  caption?: string;
  id?: string | null;
  type?: SocialPostType;
  thumbNail?: string | null;
  influencerId: number | null;
  campaignId?: number | null;
  campaignType?: CampaignType | null;
}

export type RefetchSimilarInfluencersVariables = Pick<
  QueryallInstagramSimilarInfluencersV3Args,
  'limit' | 'minAge' | 'maxAge' | 'genders' | 'minFollowers' | 'maxFollowers'
>;

export interface DefaultProfileWidgetProps<Profile> {
  profile: Profile;
  className?: string;
  isSectionOpened?: boolean;
  hasSimilarInfluencers?: boolean;
  selectButtonProps?: WidgetSelectButtonProps;
  toggleSimilarInfluencersSection?: () => void;
}
