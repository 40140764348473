import { Trans, useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { useMarketplacePostsNoRevenueQuery } from '@/graphql';
import { RowProps, Table, TableCell } from '@/shared/atoms';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar, PostsSection } from '@/shared/molecules';

interface PostsWithoutRevenueProps {
  children?: ReactNode;
  marketplacePostIds: (number | null)[];
}
export const PostsWithoutRevenue = ({ marketplacePostIds, children }: PostsWithoutRevenueProps) => {
  const { data } = useMarketplacePostsNoRevenueQuery({
    variables: { marketplacePostIds: marketplacePostIds.filter((p) => p !== null) as number[] }
  });
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const { t } = useTranslation();

  return (
    <div>
      <div css={{ marginTop: '16px' }}>
        {data?.marketplacePostsNoRevenue?.count ? (
          <div css={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <span
              role="img"
              aria-label="yellow warning emoji"
              css={{
                fontFamily: 'Roboto, sans-serif' // it will add color to ⚠️ emoji
              }}
            >
              ⚠️
            </span>
            <Trans
              i18nKey="posts have 0 revenue"
              count={data?.marketplacePostsNoRevenue?.count}
              components={{ b: <b /> }}
            />
          </div>
        ) : null}
        {children}
      </div>

      {data?.marketplacePostsNoRevenue?.count ? (
        <Table
          hasBorder={false}
          maxHeight="290px"
          data={{
            rows: (data?.marketplacePostsNoRevenue?.posts || []).map(
              (post) =>
                [
                  <TableCell.Link
                    label={post.influencerName}
                    css={{ display: 'flex', alignItems: 'center', minWidth: '100px' }}
                    {...generateProfileLink(post.influencerId)}
                  >
                    <InfluencerAvatar size="40px" src={post.influencerAvatar} asChild />
                    <TableCell.Text css={{ marginLeft: '8px', minWidth: '100px' }} value={post.influencerName} />
                  </TableCell.Link>,
                  <PostsSection
                    size="40px"
                    influencerId={post.influencerId}
                    posts={post.thumbnails.map((thumbnail) => ({ thumbNail: thumbnail }))}
                    css={{
                      width: 'auto',
                      maxWidth: '144px',
                      '.post-section': {
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center'
                      }
                    }}
                  />,
                  <TableCell.Text value={post.content} css={{ maxWidth: '200px' }} />,
                  <TableCell.Date value={post.postedDate} />
                ] as RowProps
            ),
            columns: [
              { title: t('HeaderColumn.Influencer') },
              { title: t('HeaderColumn.Post') },
              { title: '' }, // post content doesn't have a title
              { title: t('HeaderColumn.Post date') }
            ],
            rowHeight: '61px'
          }}
          stickyHeader
          css={{ border: THEME.border.base, borderRadius: '3px', marginTop: '16px' }}
        />
      ) : null}
    </div>
  );
};
