import { useState } from 'react';
import { useUpdatePaymentStatusMutation, PaymentRequestStatus, namedOperations } from '@/graphql';
import { PERMISSION_DENIED } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';

export const useUpdatePayment = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callUpdatePaymentStatus, loading } = useUpdatePaymentStatusMutation({
    refetchQueries: [namedOperations.Query.AllPayments]
  });
  const [selectedPayment, setSelectedPayment] = useState<{
    id: number;
    name: string;
    status: PaymentRequestStatus;
  } | null>(null);

  const updatePaymentStatus = async () => {
    if (!selectedPayment) return;

    try {
      await callUpdatePaymentStatus({
        variables: { input: { id: selectedPayment.id, status: selectedPayment.status } }
      });

      enqueueSnackbar(t('succeededInUpdating', { name: selectedPayment.name }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: selectedPayment.name }), { variant: 'error' });
      if (error.message === PERMISSION_DENIED) {
        enqueueSnackbar(t('PermissionDenied'), { variant: 'error' });
      }
    } finally {
      setSelectedPayment(null);
    }
  };

  return {
    updating: loading,
    selectedPayment,
    setSelectedPayment,
    updatePaymentStatus
  };
};
