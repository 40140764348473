import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { Genders, SocialAccountInfluencerListSortFieldV2, SocialAccountStatus, SocialAccountType } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
import { PostType, DefaultInfluencersProps, InfluencersFilterSchema, InfluencersBaseSearchSchema } from '../types';
import { getInitialFacebookPageInfluencersFormValues } from './utils';

export const FacebookPageInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  minViews: true,
  maxViews: true,
  regionIds: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  audienceAge: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  audienceRatio: true,
  audienceGender: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  followerGrowthPeriod: true,
  followerGrowthPercentage: true,
  talentAgencyOrPartnerIds: true
});
export const FacebookPageInfluencersSearchSchema = z.object({
  filter: FacebookPageInfluencersFilterSchema.default(
    FacebookPageInfluencersFilterSchema.parse(getInitialFacebookPageInfluencersFormValues())
  ),
  ...SearchFilterDefaults.pageLimit(),
  sort: SearchFilterDefaults.sort(SocialAccountInfluencerListSortFieldV2).default({}),
  state: InfluencersBaseSearchSchema.shape.state
});

export type FacebookPageInfluencersSearchSchemaType = z.infer<typeof FacebookPageInfluencersSearchSchema>;
export type FacebookPageInfluencersFilterFormValues = z.infer<typeof FacebookPageInfluencersFilterSchema>;
export type FacebookPageInfluencersFilterFormKeys = UnionOfKeys<FacebookPageInfluencersFilterFormValues>;
export type FacebookPageInfluencersProps = DefaultInfluencersProps<FacebookPageInfluencerProfile>;
export interface FacebookPageInfluencerProfile {
  id: number;
  name: string;
  avatar?: string;
  posts: PostType[];
  age?: number | null;
  gender: Genders | '';
  likes?: number | null;
  isBrandAccount?: boolean;
  followers?: number | null;
  analyticsWarning?: boolean;
  engagementRate?: number | null;
  socialAccountId?: number | null;
  status: SocialAccountStatus | null;
  socialAccountType: SocialAccountType;
  country: { id?: string; name?: string };
}
