import { useTranslation } from 'react-i18next';
import { SortOption } from '@/shared/types';
import { InfluencerSearchSortField, Order } from '@/graphql';
import { useAllInfluencersContext } from '../AllInfluencersContext';
import { InfluencerSortActionBarTemplate, InfluencersSortTemplate } from '../../shared';

export const AllInfluencersSort = () => {
  const { t } = useTranslation();
  const { sort, limit, setSort, setLimit, isEngagementPage, isPackagePage, sortSelectButtonProps } =
    useAllInfluencersContext();

  const sortOptions: SortOption<InfluencerSearchSortField>[] = [
    {
      key: 0,
      order: Order.ASC,
      label: t('Total Followers (ASC)'),
      field: InfluencerSearchSortField.FOLLOWERS
    },
    {
      key: 1,
      order: Order.DESC,
      label: t('Total Followers (DESC)'),
      field: InfluencerSearchSortField.FOLLOWERS
    },
    {
      key: 2,
      order: Order.ASC,
      label: t('Engagement (ASC)'),
      field: InfluencerSearchSortField.ENGAGEMENT
    },
    {
      key: 3,
      order: Order.DESC,
      label: t('Engagement (DESC)'),
      field: InfluencerSearchSortField.ENGAGEMENT
    },
    {
      key: 4,
      order: Order.ASC,
      label: t('ER by views (ASC)'),
      field: InfluencerSearchSortField.ENGAGEMENT_VIEWS_RATE
    },
    {
      key: 5,
      order: Order.DESC,
      label: t('ER by views (DESC)'),
      field: InfluencerSearchSortField.ENGAGEMENT_VIEWS_RATE
    },
    {
      key: 6,
      order: Order.ASC,
      label: t('ER by followers (ASC)'),
      field: InfluencerSearchSortField.ENGAGEMENT_FOLLOWERS_RATE
    },
    {
      key: 7,
      order: Order.DESC,
      label: t('ER by followers (DESC)'),
      field: InfluencerSearchSortField.ENGAGEMENT_FOLLOWERS_RATE
    },
    {
      key: 8,
      order: Order.ASC,
      label: t('Campaign Participated (ASC)'),
      field: InfluencerSearchSortField.CAMPAIGNS_JOINED
    },
    {
      key: 9,
      order: Order.DESC,
      label: t('Campaign Participated (DESC)'),
      field: InfluencerSearchSortField.CAMPAIGNS_JOINED
    }
  ].map((option) => ({ ...option, value: `${option.field}-${option.order}` }));

  return (
    <div css={{ position: 'relative' }}>
      <InfluencersSortTemplate
        setSort={setSort}
        setLimit={setLimit}
        sortOptions={sortOptions}
        selectButtonProps={sortSelectButtonProps}
        initialValues={{ sort, limit: String(limit) }}
      />

      {!isEngagementPage && sortSelectButtonProps?.checked && !isPackagePage ? (
        <InfluencerSortActionBarTemplate selectButtonProps={sortSelectButtonProps} />
      ) : null}
    </div>
  );
};
