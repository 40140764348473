import { ProposalInfluencer, EngagementProposalSocialMediaType, ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { RowData, RowInfluencerData } from './types';

export const getInitialRowsData = (
  allInfluencers: readonly ProposalInfluencer[],
  jdMode: ProposalJobDescriptionBreakDownMode
) =>
  allInfluencers.reduce<RowData[]>((results, { accountList, summary, ...influencer }) => {
    const currentIndex = results.length;
    const hasSocialAccount = jdMode === ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN && !!accountList;
    const influencerData: RowInfluencerData = {
      id: influencer.id,
      name: influencer.name,
      status: influencer.status,
      avatar: influencer.avatar,
      ttcmStatus: influencer.ttcmStatus,
      hasOnlyOneTiktokAccount:
        accountList.filter((a) => a.socialMedia === EngagementProposalSocialMediaType.TIKTOK).length === 1
    };
    results.push({
      index: currentIndex,
      influencer: influencerData,
      stats: {
        addedDate: influencer.addedDate,
        budget: summary.budget || 0,
        profit: summary.profit || 0,
        netBudget: summary.netBudget || 0,
        ttcmStatus: influencer.ttcmStatus,
        couponCode: influencer.couponCode,
        engagementRateByViews: summary.engagementViewRate,
        engagementRateByFollowers: summary.engagementFollowerRate,
        engagementProposed: summary.engagementProposedCount,
        engagementPosted: summary.engagementPostedCount,
        followersCount: summary.followersCount,
        influencerCost: summary.influencerCost || 0,
        ttcmCampaignCode: influencer.ttcmCampaignCode || '-',
        ttcmCampaignInviteLink: !hasSocialAccount ? influencer.ttcmCampaignInviteLink : ''
      }
    });

    if (hasSocialAccount) {
      accountList.forEach(
        (
          { summary, addedDate, id: accId, name: accName, socialMedia: accSm, ttcmCampaignInviteLink, ttcmStatus },
          index
        ) => {
          results.push({
            index: currentIndex + index + 1,
            influencer: influencerData,
            socialAccount: {
              id: accId,
              name: accName,
              socialMedia: accSm,
              lastIndex: index === accountList.length - 1
            },
            stats: {
              addedDate: addedDate,
              budget: summary.budget || 0,
              profit: summary.profit || 0,
              netBudget: summary.netBudget || 0,
              ttcmStatus,
              engagementRateByViews: summary.engagementViewRate,
              engagementRateByFollowers: summary.engagementFollowerRate,
              engagementProposed: summary.engagementProposedCount,
              engagementPosted: summary.engagementPostedCount,
              followersCount: summary.followersCount,
              influencerCost: summary.influencerCost || 0,
              ttcmCampaignInviteLink: ttcmCampaignInviteLink
            }
          });
        }
      );
    }

    return results;
  }, []);
