import omit from 'lodash/omit';
import { Genders } from '@/graphql';
import { LIMIT } from '@/shared/constants';

export const getOffset = (page = 1, limit = LIMIT) => limit * (page - 1);

export const getNumberValidFilter = <T extends string>(formValues: Record<string, any>, omitKeys: T[] = []) =>
  Object.values(omit(formValues, omitKeys)).filter((value) =>
    Array.isArray(value) ? !!value.length : typeof value === 'number' || Boolean(value)
  ).length || 0;

// Add UNKNOWN gender if "OTHERS" is selected if it is not already there
export const convertGenders = (genders: Genders[]) => {
  if (genders.length === 1 && genders[0] === Genders.UNKNOWN) {
    return [];
  }

  return genders.includes(Genders.NOT_APPLICABLE) ? [...new Set([...genders, Genders.UNKNOWN])] : genders;
};
