import { FacebookInfluencers, FacebookInfluencerProfile } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSocialAccountSelectButtonProps } from './hooks';

export const FacebookInfluencersSearch = () => {
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialAccountSelectButtonProps<FacebookInfluencerProfile>({
      socialMedia: InfluencersTabType.FACEBOOK
    });

  return (
    <FacebookInfluencers
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
