import { Trans } from 'react-i18next';
import {
  TikTokSpecialPostDetail,
  CampaignSocialMediaType,
  useReportTiktokSpecialPostStatusMutation,
  namedOperations
} from '@/graphql';
import { Modal, ModalTitle, ModalContent, ModalFooterActions, FormProvider } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useToggleState, useQueryHelper, useSelectItem } from '@/shared/hooks';
import { SelectorField, TextAreaField } from '@/shared/molecules';
import { getSocialMediaText } from '@/shared/utils';

interface RejectFormValues {
  reason: string;
  detailedReason: string;
}

export const useRejectTikTokCampaignPost = () => {
  const loadingState = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem<TikTokSpecialPostDetail>();
  const { callReportTiktokSpecialPostStatus } = useReportTiktokSpecialPostStatusMutation({
    refetchQueries: [
      namedOperations.Query.TiktokSpecialCampaignPosts,
      namedOperations.Query.TiktokSpecialPost,
      namedOperations.Query.TiktokSpecialPostHistory
    ]
  });

  const hasAskToEdit = !!selectedItem;
  const hasAskToRePost = !!selectedItem;

  const handleSubmit = async ({ reason, detailedReason }: RejectFormValues) => {
    if (!selectedItem?.id) return;

    loadingState.open();

    try {
      await callReportTiktokSpecialPostStatus({
        variables: { input: { id: selectedItem.id, reason, detailedReason } }
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      loadingState.close();
      resetSelectedItem();
    }
  };

  return {
    reporting: loadingState.status,
    handleRejectPost: setSelectedItem,
    RejectMarketplacePostModal: () => (
      <Modal css={{ width: THEME.modal.size.lv2 }} open={!!selectedItem?.id} onClose={resetSelectedItem}>
        <FormProvider<RejectFormValues>
          onSubmit={handleSubmit}
          defaultValues={{ reason: selectedItem?.reason || '', detailedReason: selectedItem?.detailedReason || '' }}
        >
          {({ values }) => (
            <>
              <ModalContent>
                <ModalTitle css={{ marginBottom: '10px' }}>{t('Dialog.Report Inappropriate')}</ModalTitle>

                {hasAskToEdit || hasAskToRePost ? (
                  <p css={{ marginBottom: '16px' }}>
                    {hasAskToEdit ? (
                      <>
                        {getSocialMediaText(CampaignSocialMediaType.TIKTOK)}&nbsp;
                        <Trans i18nKey="Dialog.ReportInappropriateEditDescription" components={{ b: <b /> }} />
                      </>
                    ) : hasAskToRePost ? (
                      <Trans i18nKey="Dialog.ReportInappropriateRePostDescription" components={{ b: <b /> }} />
                    ) : null}
                  </p>
                ) : null}

                <SelectorField<keyof RejectFormValues>
                  name="reason"
                  title={t('Selector.Reason')}
                  options={[
                    { label: t<string>('Option.Inappropriate contents'), value: 'Inappropriate contents' },
                    {
                      label: t<string>('Option.Doesn’t match the conditions'),
                      value: 'Doesn’t match the conditions'
                    }
                  ]}
                  required
                />

                <TextAreaField<keyof RejectFormValues>
                  name="detailedReason"
                  title={t('TextForm.Detailed Reason')}
                  help={t('Tooltip.Detailed Reason Help')}
                  placeholder={t<string>('TextForm.Detailed Reason Placeholder')}
                  required
                />
              </ModalContent>

              <ModalFooterActions
                cancelButtonProps={{ onClick: resetSelectedItem }}
                submitButtonProps={{
                  type: 'submit',
                  title: t('Button.Send'),
                  loading: loadingState.status,
                  disabled: !values.reason || !values.detailedReason
                }}
              />
            </>
          )}
        </FormProvider>
      </Modal>
    )
  };
};
