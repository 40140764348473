import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ColumnProps, RowProps, Status, StatusProps, TableCell } from '@/shared/atoms';
import { formatPrice } from '@/shared/utils';
import { TiktokAdAccountInfo, TiktokAdAccountStatus } from '@/graphql';
import { ColorVariant } from '@/shared/types';
import { TransferModal } from './TransferModal';

export interface useBudgetAllocationTableProps {
  data: readonly TiktokAdAccountInfo[];
  prepaidBalance: number;
}

const getStatusColumnProps = (status: TiktokAdAccountStatus, t: TFunction): StatusProps => {
  switch (status) {
    case TiktokAdAccountStatus.VERIFIED:
      return { label: t('Option.Approved'), variant: ColorVariant.LIGHT_GREEN };
    case TiktokAdAccountStatus.UNVERIFIED:
      return { label: t('Option.Not Approved'), variant: ColorVariant.LIGHT_RED };
    case TiktokAdAccountStatus.DISCONNECT:
    default:
      return { label: t('Option.Disconnected'), variant: ColorVariant.LIGHT_GREY };
  }
};

export const useBudgetAllocationTable = ({ data, prepaidBalance }: useBudgetAllocationTableProps) => {
  const { t } = useTranslation();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Ad Account Name') },
    { title: t('HeaderColumn.Ad Account ID') },
    { title: t('HeaderColumn.Status') },
    { title: t('HeaderColumn.Balance') },
    { title: t('HeaderColumn.Allocation') }
  ];
  const rows: RowProps[] = data.map((adAccount) => [
    <TableCell.Text value={adAccount.name} />,
    <TableCell.Text value={adAccount.id} />,
    <Status {...getStatusColumnProps(adAccount.status, t)} />,
    <TableCell.Number
      value={formatPrice(adAccount.balanceAmount, adAccount.currencyId)}
      unit={adAccount.currencyId}
      css={{ justifyContent: 'flex-start' }}
    />,
    <TransferModal adAccount={adAccount} prepaidBalance={prepaidBalance} />
  ]);

  return { rows, columns };
};
