import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { anyTagWhileImage } from '@/shared/assets';
import { THEME } from '@/shared/constants';

export const InitialLoading = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper}>
      <img src={anyTagWhileImage} width="185px" alt="" />
      <span css={{ marginTop: '16px', fontSize: '14px', lineHeight: 1.29, color: THEME.text.colors.white }}>
        {`${t('Loading')} ...`}
      </span>
    </div>
  );
};
const styles = {
  wrapper: css({
    top: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#27313b'
  })
};
