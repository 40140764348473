import { useTranslation } from 'react-i18next';
import { InstagramPostAverageEngagement, InstagramPostsHabit } from '@/graphql';
import { heartImage, pictureImage, chatGreenImage, bookmarkLightBlueImage } from '@/shared/assets';
import { ChartsContainer } from '@/shared/atoms';
import { PostingHabits, AverageEngagement } from '@/shared/molecules';
import { AnalyticsPostsOverview } from '../../../shared';

interface AnalyticsInstagramDashboardPostsOverviewProps {
  postsHabit?: readonly InstagramPostsHabit[];
  engagement?: InstagramPostAverageEngagement;
}

export const AnalyticsInstagramDashboardPostsOverview = (props: AnalyticsInstagramDashboardPostsOverviewProps) => {
  const { t } = useTranslation();

  return (
    <AnalyticsPostsOverview css={{ margin: 0 }} data={props}>
      {({ data: { engagement, postsHabit } }) => (
        <ChartsContainer css={{ height: '324px' }}>
          {engagement ? (
            <AverageEngagement
              data={[
                {
                  icon: pictureImage,
                  label: t('Avg posts/week'),
                  value: engagement.averagePostsPerWeek,
                  growth: engagement.averagePostsGrowth
                },
                {
                  icon: heartImage,
                  label: t('Avg Likes/selected period'),
                  value: engagement.averageLikes,
                  growth: engagement.averageLikesGrowth
                },
                {
                  icon: bookmarkLightBlueImage,
                  label: t('Avg Saves/selected period'),
                  value: engagement.averageSaved,
                  growth: engagement.averageSavedGrowth
                },
                {
                  icon: chatGreenImage,
                  label: t('Avg Comments/selected period'),
                  value: engagement.averageComments,
                  growth: engagement.averageCommentsGrowth
                }
              ]}
            />
          ) : null}

          {postsHabit ? <PostingHabits data={postsHabit} /> : null}
        </ChartsContainer>
      )}
    </AnalyticsPostsOverview>
  );
};
