import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdatePasswordByAdvertiserUser.graphql';
import { Mutation, MutationupdatePasswordByAdvertiserUserArgs } from '../../__generated__/globalTypes';

export const useUpdatePasswordByAdvertiserUserMutation = (
  options?: MutationHookOptions<Mutation, MutationupdatePasswordByAdvertiserUserArgs>
) => {
  const [callUpdatePasswordByAdvertiserUser, result] = useMutation<
    Mutation,
    MutationupdatePasswordByAdvertiserUserArgs
  >(MUTATION, options);

  return { callUpdatePasswordByAdvertiserUser, ...result };
};

export type UpdatePasswordByAdvertiserUserMutationFunction = Mutation['updatePasswordByAdvertiserUser'];
