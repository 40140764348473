import { Trans } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { localizedDateFormatter } from '@/shared/utils';
import { NoticeV2, NoticeV2Props } from '@/shared/atoms';
import { TikTokSpecialCampaignDetail, TikTokSpecialCampaignStatus } from '@/graphql';

export interface TikTokCampaignNoticeProps {
  className?: string;
  tiktokSpecialCampaign: TikTokSpecialCampaignDetail;
}

export const TikTokCampaignNotice = ({
  className,
  tiktokSpecialCampaign: { status, rejectedDate, createdDate, rejectedReason }
}: TikTokCampaignNoticeProps) => {
  const { isAdvertiserFromTikTok } = useUserRoles();

  const noticeProps: NoticeV2Props | undefined = (() => {
    switch (status) {
      case TikTokSpecialCampaignStatus.DRAFT:
        return isAdvertiserFromTikTok && createdDate
          ? {
              type: 'info',
              hasCloseIcon: true,
              title: (
                <Trans
                  i18nKey="tiktokCampaignDraftNoticeTitle"
                  values={{ createdDate: localizedDateFormatter(createdDate, 'yyyy/MM/dd') }}
                  components={{ span: <span css={{ fontSize: '12px', fontWeight: 400, lineHeight: '140%' }} /> }}
                />
              )
            }
          : undefined;
      case TikTokSpecialCampaignStatus.REVIEWING:
        return isAdvertiserFromTikTok
          ? {
              type: 'info',
              title: (
                <Trans
                  i18nKey="tiktokCampaignReviewingNoticeTitle"
                  components={{ span: <span css={{ fontSize: '12px', fontWeight: 400, lineHeight: '140%' }} /> }}
                />
              )
            }
          : undefined;
      case TikTokSpecialCampaignStatus.REJECTED:
        return {
          type: 'error',
          title: (
            <Trans
              i18nKey="tiktokCampaignRejectedNoticeTitle"
              values={{ date: rejectedDate ? localizedDateFormatter(rejectedDate, 'yyyy/MM/dd') : '-' }}
            />
          ),
          description: rejectedReason ? (
            <Trans i18nKey="tiktokCampaignRejectedNoticeDescription" values={{ reason: rejectedReason }} />
          ) : null
        };
      default:
        return undefined;
    }
  })();

  return status && noticeProps && <NoticeV2 className={className} {...noticeProps} />;
};
