import { YoutubeAnalyticsPostPayload, YoutubeAnalyticsPostPayloads } from '@/graphql';
import { getYoutubePostUrl, getYoutubeVideoUrl } from '@/shared/utils';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface YoutubePostInDateDetailsProps {
  className?: string;
  data: Pick<YoutubeAnalyticsPostPayloads, 'avatar' | 'channelName' | 'subscribers'> & YoutubeAnalyticsPostPayload;
}

export const YoutubePostInDateDetails = ({ data, className }: YoutubePostInDateDetailsProps) => {
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = {
    socialMedia: 'YOUTUBE',
    caption: data.videoCaption,
    postUrl: getYoutubePostUrl(data.videoId),
    video: { title: data.videoTitle, url: getYoutubeVideoUrl(data.videoId) },
    comments: data.videoComments.map((comment) => ({ content: comment.content, username: comment.posterName })) || [],
    info: {
      socialMedia: 'YOUTUBE',
      avatar: data.avatar,
      name: data.channelName,
      postDate: data.postDate,
      followers: data.subscribers
    },
    statistics: {
      views: data.views,
      averageViewDuration: data.averageViewDuration,
      averageViewPercentage: data.averageViewPercentage,
      likes: data.likes,
      dislikes: data.dislikes,
      comments: data.comments
    }
  };

  return <DefaultPostDetailsTemplate className={className} css={{ width: '970px' }} postDetails={postDetails} />;
};
