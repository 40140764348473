import { useTranslation } from 'react-i18next';
import { MediaPreviewWithMetrics } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { usePostDetailsParam } from '@/shared/hooks';
import { ImageSlider, InfluencerPostDetailsModal } from '@/shared/molecules';
import { bigIntFormatter } from '@/shared/utils';
import { videoCassetteImage } from '@/shared/assets';
import { InfluencerFeedPost, SocialPostType } from '@/graphql';
import { InfluencerProfileFeedPostsWrapper } from '../shared';

const FEED_POST_PARAMS_KEY = 'feedPostPopup';

interface InfluencerXhsProfilePicturePostsProps {
  picturePosts: readonly InfluencerFeedPost[];
  influencerId: number;
  socialMediaAccountId: string;
  accountName: string;
}

export const InfluencerXhsProfilePicturePosts = ({
  picturePosts,
  influencerId,
  socialMediaAccountId,
  accountName
}: InfluencerXhsProfilePicturePostsProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam(FEED_POST_PARAMS_KEY);

  return (
    <>
      <InfluencerProfileFeedPostsWrapper
        dataNotAvailable={!picturePosts.length}
        title={t('HeaderColumn.Posts')}
        prefixIcon={videoCassetteImage}
        css={{ marginTop: '16px', '.section-header': { borderBottom: THEME.border.base } }}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/xhs_picture_posts',
          params: { id: influencerId.toString(), socialMediaAccountId: socialMediaAccountId.toString() },
          search: {
            filter: {
              type: SocialPostType.XHS,
              name: accountName
            }
          }
        }}
      >
        <div css={{ fontSize: '16px', fontWeight: 400, lineHeight: '18px', padding: '12px 16px 0' }}>
          {t('Picture Posts')}
        </div>
        <ImageSlider total={picturePosts.length} css={{ padding: '12px 16px' }}>
          {picturePosts?.map(({ id, thumbnail, likes, comments }) => (
            <MediaPreviewWithMetrics
              key={id}
              src={thumbnail}
              css={THEME.mediaPreview.default}
              onClick={() => setPostDetailsParam(id)}
              objectFit="cover"
              radius="8px"
              statistics={[
                { label: t('General.Like'), value: bigIntFormatter(likes, 0, { k: true, m: true }) },
                { label: t('General.Save'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
              ]}
            />
          ))}
        </ImageSlider>
      </InfluencerProfileFeedPostsWrapper>

      <InfluencerPostDetailsModal
        paramKey={FEED_POST_PARAMS_KEY}
        posts={picturePosts?.map(({ id }) => ({ id, influencerId: influencerId }))}
      />
    </>
  );
};
