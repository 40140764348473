import {
  InstagramCompareFollowers,
  InstagramCompareOverview,
  InstagramComparePosts,
  InstagramFollowersAgeGroup,
  YoutubeCompareOverview,
  YoutubeComparePosts,
  YoutubeCompareTagRanking
} from '@/graphql';
import {
  igCompareAvatar1,
  igCompareAvatar2,
  igCompareAvatar3,
  igCompareThumbnailCompare1,
  igCompareThumbnailCompare2,
  igCompareThumbnailCompare3,
  igCompareThumbnailCompare4,
  igCompareThumbnailMain1,
  igCompareThumbnailMain2,
  igCompareThumbnailMain3
} from '@/shared/assets';

const days = ['2025-01-01', '2025-01-02', '2025-01-03', '2025-01-04', '2025-01-05', '2025-01-06'];
const followers_main = [2415, 2500, 2513, 2545, 2563, 2612, 2625];
const posts_main = [0, 0, 1, 1, 0, 0, 1];
const followers_compare_1 = [12010, 12024, 12020, 12055, 12060, 12060, 12064];
const posts_compare_1 = [1, 1, 0, 1, 0, 0, 1];
const followers_compare_2 = [5423, 5480, 5620, 5712, 5865, 5923, 6012];
const posts_compare_2 = [1, 1, 1, 0, 1, 2, 1];
const main_account = {
  posts: [
    {
      id: 1,
      comments: 0,
      likes: 10,
      thumbNail: igCompareThumbnailMain1
    },
    {
      id: 2,
      comments: 2,
      likes: 18,
      thumbNail: igCompareThumbnailMain2
    },
    {
      id: 3,
      comments: 1,
      likes: 12,
      thumbNail: igCompareThumbnailMain3
    }
  ],
  habits: [
    { weekday: 4, hour: 17, averageComments: 0, averageLikes: 0, engagementRate: 0 },
    { weekday: 2, hour: 18, averageComments: 0, averageLikes: 0, engagementRate: 0 },
    { weekday: 6, hour: 15, averageComments: 0, averageLikes: 0, engagementRate: 0 }
  ]
};

const compare_account_1 = {
  posts: [
    {
      id: 1,
      comments: 2,
      likes: 51,
      thumbNail: igCompareThumbnailCompare1
    },
    {
      id: 2,
      comments: 6,
      likes: 82,
      thumbNail: igCompareThumbnailCompare2
    }
  ],
  habits: [
    { weekday: 2, hour: 11, averageComments: 0, averageLikes: 0, engagementRate: 0 },
    { weekday: 0, hour: 11, averageComments: 0, averageLikes: 0, engagementRate: 0 },
    { weekday: 4, hour: 12, averageComments: 0, averageLikes: 0, engagementRate: 0 }
  ]
};

const compare_account_2 = {
  posts: [
    {
      id: 1,
      comments: 1,
      likes: 15,
      thumbNail: igCompareThumbnailCompare3
    },
    {
      id: 2,
      comments: 3,
      likes: 62,
      thumbNail: igCompareThumbnailCompare4
    }
  ],
  habits: [
    { weekday: 4, hour: 19, averageComments: 0, averageLikes: 0, engagementRate: 0 },
    { weekday: 5, hour: 19, averageComments: 0, averageLikes: 0, engagementRate: 0 },
    { weekday: 6, hour: 18, averageComments: 0, averageLikes: 0, engagementRate: 0 }
  ]
};

export const compareMock = {
  accounts: [
    {
      id: 1,
      blocked: false,
      username: 'superfood',
      avatarUrl: igCompareAvatar2,
      profileUrl: 'https://example.com/profile'
    },
    {
      id: 2,
      blocked: false,
      username: 'gohigher_indonesia',
      avatarUrl: igCompareAvatar1,
      profileUrl: 'https://example.com/profile'
    },
    {
      id: 3,
      blocked: false,
      username: 'tabetein_',
      avatarUrl: igCompareAvatar3,
      profileUrl: 'https://example.com/profile'
    }
  ],
  overview: {
    mainAccount: {
      graph: {
        followers: days.map((day, index) => ({ date: day, count: followers_main[index] })),
        posts: days.map((day, index) => ({ date: day, count: posts_main[index] })),
        likes: [],
        comments: [],
        engagement: []
      },
      id: 1,
      summary: {
        totalFollowers: {
          total: 2625,
          growth: 210,
          percentage: 0.087
        },
        totalPosts: {
          total: 3
        },
        averageComments: {
          total: 1
        },
        averageLikes: {
          total: 15
        },
        maximumLikes: {
          total: 26
        }
      }
    },
    compareAccounts: [
      {
        graph: {
          followers: days.map((day, index) => ({ date: day, count: followers_compare_1[index] })),
          posts: days.map((day, index) => ({ date: day, count: posts_compare_1[index] })),
          likes: [],
          comments: [],
          engagement: []
        },
        id: 2,
        summary: {
          totalFollowers: {
            total: 12064,
            growth: 62,
            percentage: 0.0052
          },
          totalPosts: {
            total: 4
          },
          averageComments: {
            total: 5
          },
          averageLikes: {
            total: 62
          },
          maximumLikes: {
            total: 125
          }
        }
      },
      {
        graph: {
          followers: days.map((day, index) => ({ date: day, count: followers_compare_2[index] })),
          posts: days.map((day, index) => ({ date: day, count: posts_compare_2[index] })),
          likes: [],
          comments: [],
          engagement: []
        },
        id: 3,
        summary: {
          totalFollowers: {
            total: 6012,
            growth: 592,
            percentage: 0.1092
          },
          totalPosts: {
            total: 7
          },
          averageComments: {
            total: 2
          },
          averageLikes: {
            total: 35
          },
          maximumLikes: {
            total: 98
          }
        }
      }
    ]
  } satisfies InstagramCompareOverview,
  overview_yt: {
    mainAccount: {
      graph: {
        subscribers: days.map((day, index) => ({ date: day, count: followers_main[index] })),
        posts: days.map((day, index) => ({ date: day, count: posts_main[index] })),
        likes: [],
        dislikes: [],
        comments: [],
        views: []
      },
      accountId: 1,
      summary: {
        totalSubscribers: {
          total: 2625,
          growth: 210,
          percentage: 0.087
        },
        totalPosts: {
          total: 3,
          growth: 210,
          percentage: 0.087
        },
        averageComments: {
          total: 1,
          growth: 210,
          percentage: 0.087
        },
        averageLikes: {
          total: 15,
          growth: 210,
          percentage: 0.087
        },
        maximumLikes: {
          total: 26,
          growth: 210,
          percentage: 0.087
        },
        averageViews: {
          total: 1,
          growth: 210,
          percentage: 0.087
        },
        maximumViews: {
          total: 26,
          growth: 210,
          percentage: 0.087
        }
      }
    },
    compareAccounts: [
      {
        graph: {
          subscribers: days.map((day, index) => ({ date: day, count: followers_compare_1[index] })),
          posts: days.map((day, index) => ({ date: day, count: posts_compare_1[index] })),
          likes: [],
          dislikes: [],
          comments: [],
          views: []
        },
        accountId: 2,
        summary: {
          totalSubscribers: {
            total: 12064,
            growth: 62,
            percentage: 0.0052
          },
          totalPosts: {
            total: 4,
            growth: 210,
            percentage: 0.087
          },
          averageComments: {
            total: 5,
            growth: 210,
            percentage: 0.087
          },
          averageLikes: {
            total: 62,
            growth: 210,
            percentage: 0.087
          },
          maximumLikes: {
            total: 125,
            growth: 210,
            percentage: 0.087
          },
          averageViews: {
            total: 1,
            growth: 210,
            percentage: 0.087
          },
          maximumViews: {
            total: 26,
            growth: 210,
            percentage: 0.087
          }
        }
      },
      {
        graph: {
          subscribers: days.map((day, index) => ({ date: day, count: followers_compare_2[index] })),
          posts: days.map((day, index) => ({ date: day, count: posts_compare_2[index] })),
          likes: [],
          dislikes: [],
          comments: [],
          views: []
        },
        accountId: 3,
        summary: {
          totalSubscribers: {
            total: 6012,
            growth: 592,
            percentage: 0.1092
          },
          totalPosts: {
            total: 7,
            growth: 210,
            percentage: 0.087
          },
          averageComments: {
            total: 2,
            growth: 210,
            percentage: 0.087
          },
          averageLikes: {
            total: 35,
            growth: 210,
            percentage: 0.087
          },
          maximumLikes: {
            total: 98,
            growth: 210,
            percentage: 0.087
          },
          averageViews: {
            total: 1,
            growth: 210,
            percentage: 0.087
          },
          maximumViews: {
            total: 26,
            growth: 210,
            percentage: 0.087
          }
        }
      }
    ]
  } satisfies YoutubeCompareOverview,
  tagsRanking: {
    mainAccount: {
      accountId: 1,
      tagsRanking: [
        { tag: '#bestsweetsforyou', rank: 1, views: 25025 },
        { tag: '#gurumi', rank: 2, views: 13452 },
        { tag: '#supergiveaway', rank: 3, views: 8995 }
      ]
    },
    compareAccounts: [
      {
        accountId: 2,
        tagsRanking: [
          { tag: '#gohighersalad', rank: 1, views: 56802 },
          { tag: '#healthymonday', rank: 2, views: 36850 }
        ]
      },
      {
        accountId: 3,
        tagsRanking: [
          { tag: '#tabetfun', rank: 1, views: 29851 },
          { tag: '#fridaynight', rank: 2, views: 16826 }
        ]
      }
    ]
  } satisfies YoutubeCompareTagRanking,
  audience: {
    mainAccount: {
      id: 1,
      followerAgeRate: {
        ageGroup: [
          {
            start: 13,
            end: 17
          },
          {
            start: 18,
            end: 24
          },
          {
            start: 25,
            end: 34
          },
          {
            start: 35,
            end: 44
          },
          {
            start: 45,
            end: 54
          },
          {
            start: 55,
            end: 64
          },
          {
            start: 65,
            end: 100
          }
        ] satisfies InstagramFollowersAgeGroup[],
        femaleRate: [0, 0.07, 0.3, 0.15, 0.7, 0.3, 0.01],
        maleRate: [0, 0.03, 0.16, 0.1, 0.03, 0.04, 0.01],
        otherRate: []
      },
      followerGenderRate: {
        femaleRate: 0.63,
        maleRate: 0.37,
        otherRate: 0.0
      }
    },
    compareAccounts: [
      {
        id: 2,
        followerAgeRate: {
          ageGroup: [
            {
              start: 13,
              end: 17
            },
            {
              start: 18,
              end: 24
            },
            {
              start: 25,
              end: 34
            },
            {
              start: 35,
              end: 44
            },
            {
              start: 45,
              end: 54
            },
            {
              start: 55,
              end: 64
            },
            {
              start: 65,
              end: 100
            }
          ] satisfies InstagramFollowersAgeGroup[],
          femaleRate: [0.02, 0.1, 0.16, 0.11, 0.03, 0, 0],
          maleRate: [0.5, 0.14, 0.23, 0.12, 0.04, 0, 0],
          otherRate: []
        },
        followerGenderRate: {
          femaleRate: 0.42,
          maleRate: 0.58,
          otherRate: 0.0
        }
      },
      {
        id: 3,
        followerAgeRate: {
          ageGroup: [
            {
              start: 13,
              end: 17
            },
            {
              start: 18,
              end: 24
            },
            {
              start: 25,
              end: 34
            },
            {
              start: 35,
              end: 44
            },
            {
              start: 45,
              end: 54
            },
            {
              start: 55,
              end: 64
            },
            {
              start: 65,
              end: 100
            }
          ] satisfies InstagramFollowersAgeGroup[],
          femaleRate: [0.04, 0.12, 0.15, 0.08, 0.01, 0, 0],
          maleRate: [0.4, 0.19, 0.2, 0.12, 0.05, 0, 0],
          otherRate: []
        },
        followerGenderRate: {
          femaleRate: 0.4,
          maleRate: 0.6,
          otherRate: 0.0
        }
      }
    ]
  } satisfies InstagramCompareFollowers,
  posts: {
    mainAccount: {
      id: 1,
      feedPosts: main_account.posts,
      popularHashtags: [{ hashtag: '#bestsweetsforyou' }, { hashtag: '#gurumi' }, { hashtag: '#supergiveaway' }],
      postsHabit: main_account.habits
    },
    compareAccounts: [
      {
        id: 2,
        feedPosts: compare_account_1.posts,
        popularHashtags: [{ hashtag: '#gohighersalad' }, { hashtag: '#healthymonday' }],
        postsHabit: compare_account_1.habits
      },
      {
        id: 3,
        feedPosts: compare_account_2.posts,
        popularHashtags: [{ hashtag: '#tabetfun' }, { hashtag: '#fridaynight' }],
        postsHabit: compare_account_2.habits
      }
    ]
  } satisfies InstagramComparePosts,
  posts_yt: {
    mainAccount: {
      accountId: 1,
      posts: main_account.posts.map((post) => ({
        ...post,
        thumbnail: post.thumbNail || '',
        duration: 100,
        title: ' ',
        views: 100
      })),
      postHabit: main_account.habits
    },
    compareAccounts: [
      {
        accountId: 2,
        posts: compare_account_1.posts.map((post) => ({
          ...post,
          thumbnail: post.thumbNail || '',
          duration: 100,
          title: ' ',
          views: 100
        })),
        postHabit: compare_account_1.habits
      },
      {
        accountId: 3,
        posts: compare_account_2.posts.map((post) => ({
          ...post,
          thumbnail: post.thumbNail || '',
          duration: 100,
          title: ' ',
          views: 100
        })),
        postHabit: compare_account_2.habits
      }
    ]
  } satisfies YoutubeComparePosts
};
