import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { AutoManagedCommissionStatus } from '@/graphql';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const AutoManagedCommissionsFilterSchema = z.object({
  month: FilterValidations.string(),
  keyword: FilterValidations.string(),
  status: FilterValidations.enum(AutoManagedCommissionStatus)
});
export const AutoManagedCommissionsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(AutoManagedCommissionsFilterSchema, {
    month: '',
    keyword: '',
    status: ''
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type AutoManagedCommissionsSearchSchemaType = z.infer<typeof AutoManagedCommissionsSearchSchema>;
export type AutoManagedCommissionsFilterFormValues = z.infer<typeof AutoManagedCommissionsFilterSchema>;
export type AutoManagedCommissionsFilterFormKeys = UnionOfKeys<AutoManagedCommissionsFilterFormValues>;
