import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, get, useFormContext } from 'react-hook-form';
import { ErrorMessage, FormStyle, Label } from '@/shared/atoms';
import { PeriodType } from '@/shared/types';
import { DayPickerRange } from '../DayPicker';
import { DefaultFieldProps } from './types';

export interface RangeDatePickerFieldProps<T extends string = string>
  extends Omit<DefaultFieldProps<T>, 'name' | 'onChange'>,
    Omit<ComponentProps<typeof DayPickerRange>, 'period' | 'container' | 'handleChangePeriod'> {
  name: { startDate: T; endDate: T };
  onChange?: (period: PeriodType) => void;
}

export const RangeDatePickerField = <T extends string = string>({
  name,
  help,
  title,
  required,
  onSubmit,
  onChange,
  className,
  hasBorderBottom,
  setValueOptions = { shouldDirty: true, shouldValidate: true },
  ...restProps
}: RangeDatePickerFieldProps<T>) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    formState: { errors }
  } = useFormContext<Record<string, string>>();
  const [startDate, endDate] = watch([name.startDate, name.endDate]);
  const periodErrors = ((get(errors, name.startDate) || get(errors, name.endDate)) as FieldError)?.message;

  const handleChangePeriod = (period: PeriodType) => {
    if (onChange) {
      onChange(period);
    } else {
      setValue<string>(name.startDate, period.startDate, setValueOptions);
      setValue<string>(name.endDate, period.endDate, setValueOptions);
    }
    onSubmit?.();
  };

  return (
    <FormStyle.FieldWrapper className={className} hasBorderBottom={hasBorderBottom}>
      {title ? <Label title={title} help={help} required={required} /> : null}
      <DayPickerRange
        {...restProps}
        period={{ startDate, endDate }}
        handleChangePeriod={handleChangePeriod}
        hasError={!!periodErrors}
      />
      <ErrorMessage message={t<string>(periodErrors || '')} />
    </FormStyle.FieldWrapper>
  );
};
