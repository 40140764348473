import { useTranslation } from 'react-i18next';
import { SocialAccountStatus } from '@/graphql';
import { MediaPreview, TextCutter } from '@/shared/atoms';
import { useGenerateInfluencerProfileLink, usePostDetailsParam } from '@/shared/hooks';
import { InfluencerAvatar, InfluencerWidgetInformation, InfluencerPostDetailsModal } from '@/shared/molecules';
import { bigIntFormatter, formatPercent } from '@/shared/utils';
import { StatsSection, ProfileWidgetTemplate, WidgetSelectButtonProps } from '../../shared';
import { InfluencerPostProfile } from '../schemaTypes';

interface InfluencerPostWidgetProps {
  className?: string;
  profile: InfluencerPostProfile;
  selectButtonProps?: WidgetSelectButtonProps;
}

export const InfluencerPostWidget = ({
  className,
  selectButtonProps,
  profile: {
    id,
    age,
    name,
    posts,
    postId,
    avatar,
    gender,
    status,
    country,
    postDate,
    followers,
    socialMedia,
    postThumbnail,
    engagementRate,
    isBrandAccount,
    postDescription,
    socialAccountId
  }
}: InfluencerPostWidgetProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const isSignedUp = status === SocialAccountStatus.SIGNED_UP;
  const stats = [
    { label: t('HeaderColumn.Followers'), value: bigIntFormatter(followers) },
    { label: t('HeaderColumn.ER'), value: formatPercent(engagementRate) },
    { label: t('HeaderColumn.Post Date'), value: postDate, isDate: true }
  ];
  const profileUrl = generateProfileLink(id, { filter: { id: String(socialAccountId), sm: socialMedia || '' } });

  return (
    <>
      <ProfileWidgetTemplate className={className} selectButtonProps={selectButtonProps}>
        <InfluencerAvatar size="80px" src={avatar} isBrandAccount={isBrandAccount} socialAccountType={socialMedia} />

        <InfluencerWidgetInformation
          age={age}
          name={name}
          gender={gender}
          country={country}
          profileUrl={profileUrl}
          isSignedUp={isSignedUp}
        />

        <div css={{ width: '40%', display: 'flex', alignItems: 'center' }}>
          <MediaPreview
            src={postThumbnail}
            onClick={() => setPostDetailsParam(postId)}
            css={{ width: '72px', height: '72px', borderRadius: '5px', backgroundColor: '#dee5ec' }}
          />
          <TextCutter
            lines={3}
            text={postDescription as string}
            onClick={() => setPostDetailsParam(postId)}
            css={{ fontWeight: 600, cursor: 'pointer', marginLeft: '8px' }}
          />
        </div>

        <StatsSection id={id} stats={stats} />
      </ProfileWidgetTemplate>

      <InfluencerPostDetailsModal posts={posts} />
    </>
  );
};
