import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { Genders, SocialAccountType } from '@/graphql';
import {
  Anchor,
  Button,
  TextCutter,
  NoDataContentWrapper,
  RenderDataWithFallback,
  NoDataContentWrapperProps
} from '@/shared/atoms';
import { DEFAULT_SIMILAR_INFLUENCERS_LIMITATION, THEME } from '@/shared/constants';
import { useGenerateInfluencerProfileLink, useToggleState, useTranslateCountry } from '@/shared/hooks';
import {
  InfluencerAvatar,
  SectionWithHeader,
  SectionWithHeaderProps,
  SimilarInfluencerPopperFilter,
  SimilarInfluencerPopperFilterProps
} from '@/shared/molecules';
import { formatIntNumber, formatPercent, getGenderString } from '@/shared/utils';

export interface InfluencerProfileSimilarInfluencersProps
  extends Omit<SectionWithHeaderProps, 'children' | 'headerChildren'>,
    Pick<NoDataContentWrapperProps, 'dataNotAvailable' | 'wrapperText' | 'wrapperLink'> {
  loading?: boolean;
  className?: string;
  socialMedia: keyof typeof SocialAccountType;
  filter?: SimilarInfluencerPopperFilterProps['defaultValues'];
  onFilterChange?: SimilarInfluencerPopperFilterProps['onSubmit'];
  data: {
    name: string;
    gender: Genders;
    id: string | number;
    countryName: string;
    avatar?: string | null;
    followers?: number | null;
    engagementRate?: number | null;
    socialAccountId: string | number;
  }[];
}

export const InfluencerProfileSimilarInfluencers = ({
  data,
  filter,
  loading,
  className,
  wrapperLink,
  wrapperText,
  socialMedia,
  onFilterChange,
  moreDetailProps,
  dataNotAvailable,
  ...restProps
}: InfluencerProfileSimilarInfluencersProps) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();
  const toggleButton = useToggleState();
  const { isAdvertiser, isAgencyTalentAgency } = useUserRoles();
  const { generateProfileLink } = useGenerateInfluencerProfileLink({ disabled: isAdvertiser || isAgencyTalentAgency });

  const influencers = toggleButton.status
    ? data
    : data.slice(0, DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.PROFILE.DISPLAY);

  return (
    <SectionWithHeader
      className={className}
      title={t('Similar Influencers')}
      prefixIcon={{ icon: 'binoculars', size: '20px' }}
      css={{ marginTop: '16px', '.section-header': { borderBottom: 'none' } }}
      headerChildren={
        filter && onFilterChange && data.length ? (
          <SimilarInfluencerPopperFilter defaultValues={filter} onSubmit={onFilterChange} />
        ) : null
      }
      {...restProps}
    >
      <NoDataContentWrapper
        wrapperLink={wrapperLink}
        wrapperText={wrapperText}
        dataNotAvailable={dataNotAvailable ?? !influencers.length}
        css={{ minHeight: !influencers.length ? '200px' : 'unset' }}
      >
        <RenderDataWithFallback loading={loading} loadingProps={{ css: { height: '300px' } }} skipDelayed>
          <div css={{ padding: '0 16px 16px', boxSizing: 'border-box' }}>
            <div css={{ display: 'flex', flexWrap: 'wrap', margin: '-8px', flex: 1 }}>
              {influencers.map(
                ({ id, socialAccountId, name, gender, avatar, countryName, followers, engagementRate }, index) => (
                  <div key={index} css={{ flexBasis: '33.33%', boxSizing: 'border-box', padding: '8px' }}>
                    <div css={styles.item}>
                      <InfluencerAvatar size="75px" src={avatar} css={{ borderRadius: '50%' }} />
                      <Anchor
                        lines={1}
                        label={name}
                        css={styles.name}
                        to="/influencer/$id/profile"
                        {...generateProfileLink(id.toString(), {
                          filter: { id: socialAccountId as string, sm: socialMedia as SocialAccountType }
                        })}
                      />
                      <TextCutter css={styles.description} text={tc(countryName)} />
                      <TextCutter css={styles.description} text={t(`Option.${getGenderString(gender)}`)} />
                      <div css={styles.metricsWrapper}>
                        <div css={styles.metricsItem}>
                          <TextCutter css={styles.metricsLabel} text={t('HeaderColumn.Followers')} />
                          <TextCutter css={styles.metricsValue} text={formatIntNumber(followers)} />
                        </div>
                        <div css={styles.metricsItem}>
                          <TextCutter css={styles.metricsLabel} text={t('HeaderColumn.ER')} />
                          <TextCutter css={styles.metricsValue} text={formatPercent(engagementRate)} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            {data.length > 8 ? (
              <Button
                variant="white"
                onClick={toggleButton.toggleStatus}
                css={{ minWidth: '216px', margin: '16px auto 0' }}
                title={t(toggleButton.status ? 'Button.Less' : 'Button.More')}
              />
            ) : null}
          </div>
        </RenderDataWithFallback>
      </NoDataContentWrapper>
    </SectionWithHeader>
  );
};
const styles = {
  item: css({
    padding: '16px',
    display: 'flex',
    flexBasis: '25%',
    borderRadius: '5px',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  }),
  name: css({
    width: '100%',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center'
  }),
  description: css({
    fontSize: '11px',
    marginBottom: '4px',
    fontWeight: 'normal',
    letterSpacing: '0.28px',
    color: THEME.text.colors.gray.lv3
  }),
  metricsWrapper: css({
    width: '100%',
    display: 'flex',
    marginTop: '12px',
    alignItems: 'baseline',
    justifyContent: 'space-around'
  }),
  metricsItem: css({
    padding: '0 4px',
    flexBasis: '50%',
    boxSizing: 'border-box'
  }),
  metricsLabel: css({
    fontSize: '11px',
    textAlign: 'center',
    marginBottom: '4px',
    fontWeight: 'normal',
    letterSpacing: '0.28px',
    color: THEME.text.colors.gray.lv3
  }),
  metricsValue: css({
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'center'
  })
};
