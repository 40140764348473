import { useTranslation } from 'react-i18next';
import { PostListSortFieldV3, Order } from '@/graphql';
import { SortOption } from '@/shared/types';
import { InfluencersSortTemplate } from '../../shared';
import { useInfluencerPostsContext } from '../InfluencerPostsContext';

export const InfluencerPostsSort = () => {
  const { t } = useTranslation();
  const { sort, limit, setSort, setLimit, sortSelectButtonProps } = useInfluencerPostsContext();

  const sortOptions: SortOption<PostListSortFieldV3>[] = [
    { order: Order.ASC, label: t('ER (ASC)'), field: PostListSortFieldV3.ENGAGEMENT_RATE },
    { order: Order.DESC, label: t('ER (DESC)'), field: PostListSortFieldV3.ENGAGEMENT_RATE },
    { order: Order.ASC, label: t('Follower (ASC)'), field: PostListSortFieldV3.FOLLOWERS },
    { order: Order.DESC, label: t('Follower (DESC)'), field: PostListSortFieldV3.FOLLOWERS },
    { order: Order.ASC, label: t('Post Date (ASC)'), field: PostListSortFieldV3.POST_DATE },
    { order: Order.DESC, label: t('Post Date (DESC)'), field: PostListSortFieldV3.POST_DATE },
    { order: Order.DESC, label: t('Relevant (DESC)'), field: PostListSortFieldV3.RELEVANT }
  ].map((option) => ({ ...option, value: `${option.field}-${option.order}` }));

  return (
    <InfluencersSortTemplate
      setSort={setSort}
      setLimit={setLimit}
      sortOptions={sortOptions}
      selectButtonProps={sortSelectButtonProps}
      initialValues={{ sort, limit: String(limit) }}
    />
  );
};
