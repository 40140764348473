import { z } from 'zod';
import { PaymentInformationInput } from '@/graphql';
import { getSchemaForType } from '@/shared/types';

export const PaymentInformationSchema = z.object({
  countryId: z.string(),
  isMenaCountry: z.boolean(),
  paymentInformation: getSchemaForType<PaymentInformationInput>()(
    z.object({
      accountName: z.string(),
      accountNumber: z.string(),
      bankName: z.string(),
      branchName: z.string()
    })
  ).nullable()
});
