import { useTranslation } from 'react-i18next';

import { THEME } from '@/shared/constants';

import { Template, TemplateProps } from './Template';

export interface PostReportGridProps extends TemplateProps {
  className?: string;
  hideTitle?: boolean;
}

export const PostReportGrid = ({
  data,
  className,
  hideTitle,
  campaignType,
  metricsOrder,
  hasTrackingOption
}: PostReportGridProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ backgroundColor: THEME.background.colors.white, borderTop: THEME.border.base }} className={className}>
      {!hideTitle ? (
        <p css={{ fontSize: '18px', padding: '16px', borderBottom: THEME.border.base }}>{t('All Insights')}</p>
      ) : null}

      <Template
        data={data}
        campaignType={campaignType}
        metricsOrder={metricsOrder}
        hasTrackingOption={hasTrackingOption}
      />
    </div>
  );
};
