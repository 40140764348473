import { getValuableVariables } from '@/shared/utils';
import { ChartsContainer, NoDataTitle } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { AverageEngagement, FanActivity } from '@/shared/molecules';
import { useFacebookPageAnalyticsPostsAnalyticsQuery } from '@/graphql';
import { pictureImage, likePinkImage, chatGreenImage, eyePurpleImage, networkBlueImage } from '@/shared/assets';
import { AnalyticsPostsOverview } from '../../shared';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { useAnalyticsProfileSearch } from '../../hooks';

interface AnalyticsFacebookDashboardPostsOverviewProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsFacebookDashboardPostsOverview = ({
  analyticsAccount
}: AnalyticsFacebookDashboardPostsOverviewProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useFacebookPageAnalyticsPostsAnalyticsQuery({
    fetchPolicy: 'cache-first',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      facebookPageAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsPostsOverview loading={loading} data={data?.facebookPageAnalyticsPostsAnalytics}>
      {({ data: { averageEngagement, fanActivity } }) => (
        <ChartsContainer css={{ height: '390px' }}>
          {averageEngagement ? (
            <AverageEngagement
              header={
                <NoDataTitle
                  css={{ padding: '10px' }}
                  title={t('Average Engagement')}
                  help={t<string>('Tooltip.averageEngagement')}
                />
              }
              itemsPerRow={3}
              data={[
                {
                  icon: pictureImage,
                  label: t('Avg posts/week'),
                  value: averageEngagement.averagePostsPerWeek?.value,
                  growth: averageEngagement.averagePostsPerWeek?.growth
                },
                {
                  icon: likePinkImage,
                  label: t('Avg Reactions/selected period'),
                  value: averageEngagement.averageReactions?.value,
                  growth: averageEngagement.averageReactions?.growth
                },
                {
                  icon: eyePurpleImage,
                  label: t('Avg Reach/selected period'),
                  value: averageEngagement.averageImpressions?.value,
                  growth: averageEngagement.averageImpressions?.growth
                },
                {
                  icon: chatGreenImage,
                  label: t('Avg Comments/selected period'),
                  value: averageEngagement.averageComments?.value,
                  growth: averageEngagement.averageComments?.growth
                },
                {
                  icon: networkBlueImage,
                  label: t('Avg Shares/selected period'),
                  value: averageEngagement.averageShares?.value,
                  growth: averageEngagement.averageShares?.growth
                }
              ]}
            />
          ) : null}

          {fanActivity ? <FanActivity data={fanActivity} /> : null}
        </ChartsContainer>
      )}
    </AnalyticsPostsOverview>
  );
};
