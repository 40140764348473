import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TiktokAdvertiserPlanData.graphql';
import { Query, QuerytiktokAdvertiserPlanDataArgs } from '../../__generated__/globalTypes';

export const useTiktokAdvertiserPlanDataQuery = (
  options?: QueryHookOptions<Query, QuerytiktokAdvertiserPlanDataArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytiktokAdvertiserPlanDataArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTiktokAdvertiserPlanDataLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytiktokAdvertiserPlanDataArgs>
): QueryResult<Query, QuerytiktokAdvertiserPlanDataArgs> & {
  getTiktokAdvertiserPlanData: LazyQueryExecFunction<Query, QuerytiktokAdvertiserPlanDataArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTiktokAdvertiserPlanData, result] = useLazyQuery<Query, QuerytiktokAdvertiserPlanDataArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTiktokAdvertiserPlanData, ...result };
};

export const useTiktokAdvertiserPlanDataPromiseQuery = () => {
  const client = useApolloClient();

  const getTiktokAdvertiserPlanData = useCallback(
    (options?: Omit<QueryOptions<QuerytiktokAdvertiserPlanDataArgs, Query>, 'query'>) =>
      client.query<Query, QuerytiktokAdvertiserPlanDataArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTiktokAdvertiserPlanData };
};
