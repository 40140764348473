import { QueryHookOptions } from '@apollo/client';

import { Query, QueryallBankBranchesArgs, useAllBankBranchesQuery } from '@/graphql';
import { Option } from '@/shared/types';
import { getOptions } from '@/shared/utils';

export const useAllBankBrandOptions = (options?: QueryHookOptions<Query, QueryallBankBranchesArgs>) => {
  const { data, loading } = useAllBankBranchesQuery({ fetchPolicy: 'cache-first', ...options });

  return {
    loading,
    allBankBranchOptions: getOptions(data?.allBankBranches),
    allBankBranchNameOptions:
      data?.allBankBranches?.map<Option>((branch) => ({
        label: branch?.name || '',
        value: branch?.name || ''
      })) || []
  };
};
