import { FormProvider } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PasswordFormSchema } from './schema';
import { Template } from './Template';
import { PasswordFormProps, PasswordFormValues } from './types';

export const PasswordForm = ({
  onSubmit,
  defaultValues = { password: '', passwordConfirm: '' }
}: PasswordFormProps) => (
  <div css={{ width: THEME.container.dashboard.width, margin: '0 auto' }}>
    <FormProvider onSubmit={onSubmit} defaultValues={defaultValues} zodSchema={PasswordFormSchema}>
      <Template />
    </FormProvider>
  </div>
);

export { type PasswordFormValues };
