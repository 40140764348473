import { z } from 'zod';

import { UserRoles } from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';
import { BaseValidations } from '@/shared/validations';

const StaffSchema = z.object({
  userType: z.literal(UserRoles.STAFF),
  staffIds: z.array(z.string())
});
const AdvertiserSchema = z.object({
  userType: z.literal(UserRoles.ADVERTISER),
  name: BaseValidations.NameSchema,
  email: BaseValidations.EmailSchema,
  notificationSetting: z.boolean(),
  hasInvitationEmail: z.boolean(),
  password: BaseValidations.PasswordAdvancedSchemaDynamic.or(z.literal('')),
  passwordConfirm: BaseValidations.PasswordAdvancedSchemaDynamic.or(z.literal(''))
});
export const BaseSchema = z.object({
  countryId: z.string(),
  advertiserId: BaseValidations.AdvertiserIdSchema,
  userType: z.nativeEnum(UserRoles)
});

export const UserLinkedFormSchema = z
  .discriminatedUnion('userType', [StaffSchema, AdvertiserSchema])
  .and(BaseSchema)
  .superRefine((values, ctx) => {
    if (values.userType === UserRoles.ADVERTISER) {
      if (!values.countryId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: AllTranslationKeys.Error.requiredCountryMessage,
          path: ['countryId']
        });
      }

      if (values.password !== values.passwordConfirm) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: AllTranslationKeys.Error.passwordsDoNotMatch,
          path: ['passwordConfirm']
        });
      }

      if (!values.hasInvitationEmail) {
        if (!values.password) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: AllTranslationKeys.Error.requiredPasswordMessage,
            path: ['password']
          });
        }
        if (!values.passwordConfirm) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: AllTranslationKeys.Error.requiredPasswordConfirmMessage,
            path: ['passwordConfirm']
          });
        }
      }
    }
  });
