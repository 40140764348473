import { Fragment } from 'react';
import { SupportedSocialMediaType } from '@/shared/types';
import { Anchor } from '../Anchor';
import { Icon, SocialIcon } from '../Icon';

export interface SocialLinkedAccountsProps {
  className?: string;
  accounts: { name?: string; href?: string; type?: keyof typeof SupportedSocialMediaType }[];
}

export const SocialLinkedAccounts = ({ className, accounts }: SocialLinkedAccountsProps) => (
  <div css={{ display: 'flex', alignItems: 'center', gap: '12px' }} className={className}>
    {accounts.map((account, index) => (
      <Fragment key={index}>
        {index > 0 ? <Icon icon="chain" size={16} /> : null}

        <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {account.type ? <SocialIcon type={account.type} /> : null}
          <Anchor href={account.href} target="_blank" label={account.name} disabled={!account.href} />
        </div>
      </Fragment>
    ))}
  </div>
);
