import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ReportEngagementPostStatus.graphql';
import { Mutation, MutationreportEngagementPostStatusArgs } from '../../__generated__/globalTypes';

export const useReportEngagementPostStatusMutation = (
  options?: MutationHookOptions<Mutation, MutationreportEngagementPostStatusArgs>
) => {
  const [callReportEngagementPostStatus, result] = useMutation<Mutation, MutationreportEngagementPostStatusArgs>(
    MUTATION,
    options
  );

  return { callReportEngagementPostStatus, ...result };
};

export type ReportEngagementPostStatusMutationFunction = Mutation['reportEngagementPostStatus'];
