export const INITIAL_PAGE_NUMBER = 1;

export const LIMIT = 20;
export const LIMIT_5 = 5;
export const LIMIT_6 = 6;
export const LIMIT_50 = 50;
export const LIMIT_10 = 10;
export const LIMIT_30 = 30;
export const LIMIT_36 = 36;
export const LIMIT_100 = 100;
export const POST_COMMENTS_LIMIT = 10;
export const DEFAULT_SIMILAR_INFLUENCERS_LIMITATION = {
  LIST: { FETCH: 20, DISPLAY: 5 },
  PROFILE: { FETCH: 12, DISPLAY: 6 },
  ANALYTICS: {
    INTERACTION: { FETCH: 5, DISPLAY: 2 }
  }
};

export const MIN_AGE_V2 = 0;
export const MAX_AGE_V2 = 100;
export const MIN_AGE = 18; // TODO: remove later once API support across other filters
export const MAX_AGE = 50; // TODO: remove later once API support across other filters
export const MIN_VIEWS = 0;
export const MAX_VIEWS = 1000000;
export const MIN_REACH = 0;
export const MAX_REACH = 1000000;
export const MIN_FOLLOWERS = 0;
export const MAX_FOLLOWERS = 1000000;
export const MAX_FOLLOWERS_MARKETPLACE = 1000000;
export const MAX_FOLLOWERS_ENGAGEMENT = 1000000;
export const FOLLOWER_STEP_MARKETPLACE = 500;
export const FOLLOWER_STEP_ENGAGEMENT = 5000;
export const MIN_ENGAGEMENT_RATE = 0;
export const MAX_ENGAGEMENT_RATE = 20;
export const MIN_SAVE_RATE = MIN_ENGAGEMENT_RATE;
export const MAX_SAVE_RATE = MAX_ENGAGEMENT_RATE;
export const MIN_JOINED_CAMPAIGNS = 0;
export const MAX_JOINED_CAMPAIGNS = 50;
export const MIN_PRICE = 0;
export const MAX_PRICE = 1000000;
export const MIN_POST_ENGAGEMENT = 0;
export const MAX_POST_ENGAGEMENT = 1000000;
export const MIN_COMMISSION_RATE = 0; // TODO: check value from document
export const MAX_COMMISSION_RATE = 20; // TODO: check value from document

export const formatMinMaxAgeFilterLabel = (value: number) =>
  value === MIN_AGE ? `${value}-` : value === MAX_AGE ? `${value}+` : String(value);
