import { useEngagementPermissions, useUserRoles } from '@/auth';
import {
  CampaignType,
  EngagementPost,
  EngagementDetail,
  EngagementCampaignSocialAccountPostStatus
} from '@/graphql';
import { Table } from '@/shared/atoms';
import { useTableMaxHeight } from '@/shared/hooks';
import { EngagementPostDetailsTabsType, InfluencerPostDetailsModal } from '@/shared/molecules';
import { ActionBar } from './ActionBar';
import { useRejectEngagementPost, useEngagementPostsListTable, useUpdateEngagementPostsStatus } from './hooks';
import { FooterNode } from './FooterNode';

export interface EngagementPostsListProps {
  loading?: boolean;
  engagement: EngagementDetail;
  posts: readonly EngagementPost[];
}

const refetchQueries = ['AllEngagementPosts', 'EngagementPostDetail'];

export const EngagementPostsList = ({ posts, loading, engagement }: EngagementPostsListProps) => {
  const { isTtcmCampaign } = engagement;
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();
  const { hideApproveRejectInappropriatePostBtn } = useEngagementPermissions();
  const { isAdvertiser, isAdminStaffAgencyTalentAgencyPartner } = useUserRoles();
  const { rows, columns, postsActions } = useEngagementPostsListTable({ posts, engagement });
  const { handleRejectPost, RejectEngagementPostModal } = useRejectEngagementPost({ refetchQueries, isTtcmCampaign });
  const { handleSelectStatus, UpdateEngagementPostStatusModal } = useUpdateEngagementPostsStatus({ refetchQueries });

  return (
    <>
      <div css={{ position: 'relative' }}>
        <Table
          ref={offsetRef}
          loading={loading}
          maxHeight={defaultTableMaxHeight}
          data={{ rows, columns, rowHeight: '61px' }}
          noTopBorderRadius
          stickyHeader
          hasBorder
        />

        <ActionBar {...postsActions} isTtcmCampaign={isTtcmCampaign} />
      </div>

      <InfluencerPostDetailsModal
        hasHistoryTab
        posts={posts.map((post) => ({ ...post, campaignType: CampaignType.ENGAGEMENT }))}
        footerNode={({ tab, selectedPost, postDetails }) => {
          if (!postDetails || tab !== EngagementPostDetailsTabsType.Details) return;

          const isReviewing = selectedPost.status === EngagementCampaignSocialAccountPostStatus.REVIEWING;
          const hasReportInappropriate =
            (isAdminStaffAgencyTalentAgencyPartner || isAdvertiser) &&
            selectedPost.status &&
            [
              EngagementCampaignSocialAccountPostStatus.EFFECTIVE,
              EngagementCampaignSocialAccountPostStatus.WARNING_SOLVED
            ].includes(selectedPost.status) &&
            !hideApproveRejectInappropriatePostBtn;

          return !hideApproveRejectInappropriatePostBtn && (isReviewing || hasReportInappropriate) ? (
            <FooterNode
              isReviewing={isReviewing}
              hasReportInappropriate={hasReportInappropriate}
              handleRejectPost={handleRejectPost}
              handleSelectStatus={handleSelectStatus}
              postDetails={postDetails}
              selectedPost={selectedPost}
            />
          ) : null;
        }}
      />

      {/* Reset selected items on success to clear unchangable posts were included */}
      <RejectEngagementPostModal onSuccess={() => postsActions.setSelectedItemIds([])} />
      <UpdateEngagementPostStatusModal onSuccess={() => postsActions.setSelectedItemIds([])} />
    </>
  );
};
