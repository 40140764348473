import { usePermissions } from '../hooks';

const defaultPermissions = {
  hidePaidBtn: false,
  hideFailedBtn: false
};
export const usePaymentPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.payment?.noView || permissions?.payment?.view || permissions?.payment === null) {
    return {
      hidePaidBtn: true,
      hideFailedBtn: true
    };
  }

  return defaultPermissions;
};
