import { FormProvider } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

import { RefreshPasswordSchema } from './schema';
import { Template } from './Template';
import { RefreshPasswordFormProps, RefreshPasswordFormValues } from './types';

export const RefreshPasswordForm = ({
  onSubmit,
  defaultValues = { email: '', isSuccess: false }
}: RefreshPasswordFormProps) => (
  <div css={{ width: THEME.container.dashboard.width, margin: '0 auto' }}>
    <FormProvider onSubmit={onSubmit} defaultValues={defaultValues} zodSchema={RefreshPasswordSchema}>
      <Template />
    </FormProvider>
  </div>
);

export { type RefreshPasswordFormValues };
