import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllInfluencersForMarketplaceV2.graphql';
import { Query, QueryallInfluencersForMarketplaceV2Args } from '../../__generated__/globalTypes';

export const useAllInfluencersForMarketplaceV2Query = (
  options?: QueryHookOptions<Query, QueryallInfluencersForMarketplaceV2Args>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallInfluencersForMarketplaceV2Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllInfluencersForMarketplaceV2LazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallInfluencersForMarketplaceV2Args>
): QueryResult<Query, QueryallInfluencersForMarketplaceV2Args> & {
  getAllInfluencersForMarketplaceV2: LazyQueryExecFunction<Query, QueryallInfluencersForMarketplaceV2Args>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllInfluencersForMarketplaceV2, result] = useLazyQuery<Query, QueryallInfluencersForMarketplaceV2Args>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getAllInfluencersForMarketplaceV2, ...result };
};

export const useAllInfluencersForMarketplaceV2PromiseQuery = () => {
  const client = useApolloClient();

  const getAllInfluencersForMarketplaceV2 = useCallback(
    (options?: Omit<QueryOptions<QueryallInfluencersForMarketplaceV2Args, Query>, 'query'>) =>
      client.query<Query, QueryallInfluencersForMarketplaceV2Args>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllInfluencersForMarketplaceV2 };
};
