import { format, getMonth, getYear, subDays, subYears, subQuarters, isValid, parseISO } from 'date-fns';
import { DEFAULT_DATE_FNS_FORMAT, DEFAULT_FNS_DATE_TIME_FORMAT } from '@/shared/constants';
import { AppLanguage, Language } from '@/shared/types';
import { getDateLocal } from './i18next';

export const getNow = (dateFormat = DEFAULT_FNS_DATE_TIME_FORMAT) => format(new Date(), dateFormat);
export const getToday = (dateFormat: string = DEFAULT_DATE_FNS_FORMAT) => format(new Date(), dateFormat);
// note use 29 day, because today is not included
export const getThirtyDaysAgo = (dateFormat: string = DEFAULT_DATE_FNS_FORMAT) =>
  format(subDays(new Date(), 29), dateFormat);
export const getYesterday = (dateFormat: string = DEFAULT_DATE_FNS_FORMAT) =>
  format(subDays(new Date(), 1), dateFormat);

export const getLastDay = (dateFormat: string = DEFAULT_DATE_FNS_FORMAT) => {
  const y = getYear(new Date());
  const m = getMonth(new Date());
  const lastDay = new Date(y, m + 1, 0);

  return format(lastDay, dateFormat);
};

export const getFirstDay = (dateFormat: string = DEFAULT_DATE_FNS_FORMAT, date = new Date()) => {
  const y = getYear(date);
  const m = getMonth(date);
  const firstDay = new Date(y, m, 1);

  return format(firstDay, dateFormat);
};

export const getOneQuarterAgo = (dateFormat: string = DEFAULT_DATE_FNS_FORMAT) =>
  format(subQuarters(new Date(), 1), dateFormat);

export const getHalfYearAgo = (dateFormat: string = DEFAULT_DATE_FNS_FORMAT) =>
  format(subQuarters(new Date(), 2), dateFormat);

export const getOneYearAgo = (dateFormat: string = DEFAULT_DATE_FNS_FORMAT) =>
  format(subYears(new Date(), 1), dateFormat);

export const getTwoYearsAgo = (dateFormat: string = DEFAULT_DATE_FNS_FORMAT) =>
  format(subYears(new Date(), 2), dateFormat);

export const hourIntervals = [
  '12 am',
  '1 am',
  '2 am',
  '3 am',
  '4 am',
  '5 am',
  '6 am',
  '7 am',
  '8 am',
  '9 am',
  '10 am',
  '11 am',
  '12 pm',
  '1 pm',
  '2 pm',
  '3 pm',
  '4 pm',
  '5 pm',
  '6 pm',
  '7 pm',
  '8 pm',
  '9 pm',
  '10 pm',
  '11 pm'
];

const WEEKDAYS_LONG = {
  [Language.en]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [Language.ja]: ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'],
  [Language.ko]: ['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일'],
  [Language.cn]: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
  // TODO:
  [Language.tw]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [Language['en-JA']]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
};

const MONTHS = {
  [Language.en]: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  [Language.ja]: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  [Language.ko]: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  [Language.cn]: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
  // TODO:
  [Language.tw]: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  [Language['en-JA']]: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
};

const MONTHS_SHORT = {
  [Language.en]: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  [Language.ja]: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  [Language.ko]: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  [Language.cn]: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  [Language.tw]: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  [Language['en-JA']]: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
};

export const localizedWeekdays = (locale: AppLanguage = Language.en) => WEEKDAYS_LONG[locale];

export const localizedMonths = (locale: AppLanguage = Language.en) => MONTHS[locale];

export const localizedMonthsShort = (locale: AppLanguage = Language.en) => MONTHS_SHORT[locale];

// Calendar library utils
const CALENDAR_WEEKDAYS_LONG = {
  [Language.en]: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  [Language.ja]: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  [Language.ko]: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
  [Language.cn]: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
  // TODO:
  [Language.tw]: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  [Language['en-JA']]: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
};
const CALENDAR_WEEKDAYS_SHORT = {
  [Language.en]: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  [Language.ja]: ['日', '月', '火', '水', '木', '金', '土'],
  [Language.ko]: ['일', '월', '화', '수', '목', '금', '토'],
  [Language.cn]: ['日', '一', '二', '三', '四', '五', '六'],
  // TODO:
  [Language.tw]: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  [Language['en-JA']]: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
};
const formatDay = (d: Date, locale: AppLanguage = Language.en) =>
  `${CALENDAR_WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
const formatMonthTitle = (d: Date, locale: string = Language.en) => {
  switch (locale as AppLanguage) {
    case 'ja':
      return `${d.getFullYear()}年${MONTHS[locale as AppLanguage][d.getMonth()]}`;

    default:
      return `${MONTHS[locale as AppLanguage][d.getMonth()]} ${d.getFullYear()}`;
  }
};
const formatWeekdayShort = (i: number, locale: AppLanguage = Language.en) => CALENDAR_WEEKDAYS_SHORT[locale][i];
const formatWeekdayLong = (i: number, locale: AppLanguage = Language.en) => CALENDAR_WEEKDAYS_SHORT[locale][i];
export const localeUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong
};

export const convertDurationToHhMmSs = (seconds: number | null) =>
  seconds === null ? '-' : new Date(seconds * 1000).toISOString().substr(11, 8);

export const localizedDateFormatter = (
  date: Date | number | string | null,
  dateFormat = 'MMM do',
  local: AppLanguage = Language.en
): string => {
  const dateIsIso = typeof date === 'string' ? isValid(parseISO(date)) : false;
  if (!date || (!dateIsIso && !isValid(date))) {
    return '-';
  }

  // https://stackoverflow.com/questions/65699256/datefns-format-is-not-completely-formatted
  // We want Dec 23, 2020 in english (not full date), but 2020年2月15日 in japanese (full date)
  if (dateFormat === 'PPP' && local !== 'ja') {
    dateFormat = 'MMM dd, yyyy';
  }

  return format(new Date(date), dateFormat, { locale: getDateLocal(local) });
};
export const formatDate = (date: string | null, dateFormat = 'MMM d, Y') => {
  if (!date || !isValid(parseISO(date))) {
    return '-';
  }

  return format(new Date(date), dateFormat);
};
