import { useTranslation } from 'react-i18next';
import { ColorVariant } from '@/shared/types';
import { Status, StatusProps } from '@/shared/atoms';
import { MarketplaceCampaignParticipateStatus } from '@/graphql';

export interface MarketplaceParticipateInfluencerStatusProps {
  className?: string;
  status: MarketplaceCampaignParticipateStatus;
}

export const MarketplaceParticipateInfluencerStatus = ({
  status,
  className
}: MarketplaceParticipateInfluencerStatusProps) => {
  const { t } = useTranslation();

  const statusProps: StatusProps = (() => {
    switch (status) {
      case MarketplaceCampaignParticipateStatus.JOINED:
        return { label: t('Option.Joined'), variant: ColorVariant.LIGHT_BLUE };
      case MarketplaceCampaignParticipateStatus.REJECTED:
        return { label: t('Option.Rejected'), variant: ColorVariant.LIGHT_RED };
      case MarketplaceCampaignParticipateStatus.WAITING:
        return { label: t('Option.Waiting'), variant: ColorVariant.LIGHT_CYAN };
      case MarketplaceCampaignParticipateStatus.APPLIED:
        return { label: t('Option.Applied'), variant: ColorVariant.LIGHT_YELLOW };
      case MarketplaceCampaignParticipateStatus.UNAVAILABLE:
        return { label: t('Option.Unavailable'), variant: ColorVariant.LIGHT_GREY };
      case MarketplaceCampaignParticipateStatus.AVAILABLE:
      default:
        return { label: t('Option.Available'), variant: ColorVariant.LIGHT_PURPLE };
    }
  })();

  return <Status className={className} {...statusProps} />;
};
