import { useTranslation } from 'react-i18next';
import { GENDER_OPTIONS } from '@/shared/constants';
import { useAllInfluencerCategoryOptions, useTranslateOptions } from '@/shared/hooks';
import { SelectorField, TextSearchField, BasicFilterWrapper } from '@/shared/molecules';
import { TikTokCampaignInfluencersFilterFormKeys } from './types';

export interface BasicFilterProps {
  onSubmit?: () => void;
}

export const BasicFilter = ({ onSubmit }: BasicFilterProps) => {
  const { t } = useTranslation();
  const genderOptions = useTranslateOptions(GENDER_OPTIONS);
  const { allInfluencerCategoryOptions } = useAllInfluencerCategoryOptions();

  return (
    <BasicFilterWrapper>
      <TextSearchField<TikTokCampaignInfluencersFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.TikTokCampaignInfluencers')}
      />

      <SelectorField<TikTokCampaignInfluencersFilterFormKeys>
        name="genders"
        onSubmit={onSubmit}
        options={genderOptions}
        css={{ maxWidth: '184px' }}
        placeholder={t<string>('Selector.Gender')}
        multiple
      />

      <SelectorField<TikTokCampaignInfluencersFilterFormKeys>
        name="categoryIds"
        onSubmit={onSubmit}
        css={{ maxWidth: '184px' }}
        options={allInfluencerCategoryOptions}
        placeholder={t<string>('Selector.Categories')}
        multiple
      />
    </BasicFilterWrapper>
  );
};
