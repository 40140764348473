import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/MarketplaceCampaignCouponTracking.graphql';
import { Mutation, MutationmarketplaceCampaignCouponTrackingArgs } from '../../__generated__/globalTypes';

export const useMarketplaceCampaignCouponTrackingMutation = (
  options?: MutationHookOptions<Mutation, MutationmarketplaceCampaignCouponTrackingArgs>
) => {
  const [callMarketplaceCampaignCouponTracking, result] = useMutation<
    Mutation,
    MutationmarketplaceCampaignCouponTrackingArgs
  >(MUTATION, options);

  return { callMarketplaceCampaignCouponTracking, ...result };
};

export type MarketplaceCampaignCouponTrackingMutationFunction = Mutation['marketplaceCampaignCouponTracking'];
