import { css } from '@emotion/react';
import { Icon, Modal, ModalProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface CarouselModalProps extends ModalProps {
  onClickNext?: () => void;
  onClickPrev?: () => void;
  siblingPostId?: { prevId: string | number | null; nextId: string | number | null };
}

export const CarouselModal = ({
  children,
  onClickNext,
  onClickPrev,
  siblingPostId,
  ...restProps
}: CarouselModalProps) => {
  const hasLeftButton = !!siblingPostId?.prevId;
  const hasRightButton = !!siblingPostId?.nextId;

  return (
    <Modal css={{ maxWidth: 'max-content' }} {...restProps}>
      <div css={{ display: 'flex', alignItems: 'center', borderRadius: 'inherit' }}>
        <button onClick={onClickPrev} disabled={!hasLeftButton} css={styles.button(hasLeftButton)}>
          <Icon icon="caret-left" size={15} color={THEME.colors.black[90]} className="arrow-icon" />
        </button>
        {children}
        <button onClick={onClickNext} disabled={!hasRightButton} css={styles.button(hasRightButton)}>
          <Icon icon="caret-right" size={15} color={THEME.colors.black[90]} className="arrow-icon" />
        </button>
      </div>
    </Modal>
  );
};
const styles = {
  button: (hasButton?: boolean) =>
    css({
      zIndex: 1,
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      top: '50%',
      cursor: 'pointer',
      backgroundColor: THEME.colors.white[100],
      position: 'absolute',
      transform: 'translateY(-50%)',
      display: hasButton ? 'flex' : 'none',
      '&:first-of-type': { left: '-46px', '.arrow-icon': { left: '7px' } },
      '&:last-of-type': { right: '-46px', '.arrow-icon': { right: '8px' } },
      '.arrow-icon': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
      },
      ':hover': {
        opacity: 0.8
      }
    })
};
