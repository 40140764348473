import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormStyle, TextCutter, Icon, Button } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

import { MarketplaceFormValues, MarketplaceFormKeys } from '../types';

interface ReferralCodeUploadFieldProps {
  disabled?: boolean;
  className?: string;
  openCouponModal: () => void;
}

export const ReferralCodeUploadField = ({ className, disabled, openCouponModal }: ReferralCodeUploadFieldProps) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<MarketplaceFormValues>();

  const couponUploadFilesValue = watch(MarketplaceFormKeys.couponUploadFiles);
  const couponFileNames = (() => {
    const fileNames: string[] = [];

    couponUploadFilesValue.forEach((file) => {
      const decodeUrl = decodeURI(file);
      fileNames.push(decodeUrl.substring(decodeUrl.lastIndexOf('/') + 1));
    });

    return fileNames;
  })();

  return (
    <FormStyle.FieldWrapper className={className}>
      <p css={{ fontSize: '12px' }}>{t('TrackingSettings.ReferralDescription')}</p>
      <p css={{ fontSize: '12px', color: 'tomato', marginTop: '8px' }}>
        {t('Note that uploading enough amount of referral codes so that a lot of influencers will join')}
      </p>

      {couponFileNames.length > 0
        ? couponFileNames.map((fileName, index) => (
            <div css={styles.uploadedFile} key={index}>
              <Icon icon="file" css={{ marginRight: '6px' }} size="14px" />
              <TextCutter css={{ fontSize: '14px' }} text={fileName} />
              {!disabled ? (
                <Icon
                  size="10px"
                  icon="close"
                  color="#6E7C89"
                  css={{ marginLeft: 'auto', cursor: 'pointer' }}
                  onClick={() => {
                    const newCouponUploadFilesValue = [...couponUploadFilesValue];
                    newCouponUploadFilesValue.splice(index, 1);
                    setValue(MarketplaceFormKeys.couponUploadFiles, newCouponUploadFilesValue);
                  }}
                />
              ) : null}
            </div>
          ))
        : null}

      <Button
        variant="white"
        onClick={openCouponModal}
        title={t('Upload CSV')}
        css={{ marginTop: '8px', padding: '0 24px' }}
      />
    </FormStyle.FieldWrapper>
  );
};
const styles = {
  uploadedFile: css({
    display: 'flex',
    padding: '16px',
    marginTop: '8px',
    borderRadius: '5px',
    alignItems: 'center',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.gray.lv1
  })
};
