import { useTranslation } from 'react-i18next';
import { GENDER_OPTIONS } from '@/shared/constants';
import { useTranslateOptions } from '@/shared/hooks';
import { SelectorField, SelectorFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const GendersField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const genderOptions = useTranslateOptions(GENDER_OPTIONS);

  return (
    <SelectorField<InfluencerFormKeys>
      title={t('Selector.Gender')}
      help={t('Tooltip.Gender')}
      options={genderOptions}
      name={InfluencerSharedKeys.gender}
      {...props}
    />
  );
};
