import {
  useDeleteEngagementInfluencerProposalMutation,
  useUpdateEngagementInfluencerProposalListMutation
} from '@/graphql';
import {
  NOT_ENOUGH_REFERRAL_CODE,
  CAN_NOT_ADD_MORE_TTCM_ACCOUNT,
  CAN_NOT_ADD_TIKTOK_ACCOUNT_WITH_LOW_FOLLOWERS
} from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { useEngagementDetailsContext } from '../../EngagementDetailsContext';

export const useToggleSelectedInfluencers = () => {
  const { engagementId } = useEngagementDetailsContext();
  const { t, enqueueSnackbar, trimDotsFromMessage, invalidateRouteLoader } = useQueryHelper();
  const { callUpdateEngagementInfluencerProposalList, loading: isUpdatingProposal } =
    useUpdateEngagementInfluencerProposalListMutation({ onCompleted: invalidateRouteLoader });
  const { callDeleteEngagementInfluencerProposal, loading: isDeletingProposal } =
    useDeleteEngagementInfluencerProposalMutation({ onCompleted: invalidateRouteLoader });

  const toggleSelectedInfluencer = async (influencerIds: number[], isChecked: boolean) => {
    let error;
    const variables = { input: { campaignId: engagementId, influencerIds } };

    try {
      if (isChecked) {
        await callDeleteEngagementInfluencerProposal({ variables });
      } else {
        await callUpdateEngagementInfluencerProposalList({ variables });
      }
      // we don't want to notify user on each save/delete, just show an error if happens
    } catch (err) {
      error = err.message;

      switch (error) {
        case NOT_ENOUGH_REFERRAL_CODE:
          break;
        case CAN_NOT_ADD_MORE_TTCM_ACCOUNT:
          enqueueSnackbar(t('You can select one TikTok account for each influencer for TTCM campaign'), {
            variant: 'error'
          });
          break;
        case CAN_NOT_ADD_TIKTOK_ACCOUNT_WITH_LOW_FOLLOWERS:
          enqueueSnackbar(t('Cannot add tiktok account with low followers count'), { variant: 'error' });
          break;
        default:
          enqueueSnackbar(t(trimDotsFromMessage(err.message)), { variant: 'error' });
          break;
      }
    }

    return { error };
  };

  return { toggleSelectedInfluencer, loading: isUpdatingProposal || isDeletingProposal };
};
