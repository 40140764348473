import { AnyRouter, NavigateOptions, useNavigate, useSearch } from '@tanstack/react-router';
import { POPUP_PARAM_KEY, INITIAL_PAGE_NUMBER } from '@/shared/constants';

type CustomNavigateOptions = {
  hashScrollIntoView?: boolean | ScrollIntoViewOptions;
  replace?: boolean;
  resetScroll?: boolean;
};
export type ParamsSearchProps = {
  filter?: any;
  sort?: any;
  limit?: number;
  page?: number;
  popup?: string;
  state?: Record<string, any>;
  hash?: string;
};

export const useParamsSearch = <T extends ParamsSearchProps>(paramKey = POPUP_PARAM_KEY) => {
  const search = useSearch({ strict: false });

  const {
    filter,
    sort,
    limit,
    page,
    [paramKey]: popup,
    state,
    hash
  } = search as {
    filter: T['filter'];
    sort: T['sort'];
    limit: number;
    page: number;
    [key: string]: any;
    state: T['state'];
    hash: string;
  };
  const navigate = useNavigate();

  const setSort = (newSort: T['sort'], options?: CustomNavigateOptions) => {
    if (newSort.field === sort.field && newSort.order === sort.order) return;

    navigate({
      search: (prev: T) => ({ ...prev, sort: newSort, page: INITIAL_PAGE_NUMBER }),
      ...options
    } as NavigateOptions<AnyRouter, T['sort']>);
  };

  const setFilter = (newFilter: Partial<T['filter']>, options?: CustomNavigateOptions) => {
    navigate({
      search: (prev: T) => ({ ...prev, filter: { ...prev.filter, ...newFilter }, page: INITIAL_PAGE_NUMBER }),
      ...options
    } as NavigateOptions<AnyRouter, T['filter']>);
  };

  // Same with setFilter but use for RHF, 2nd params is Form methods
  const setFilterForm = (newFilter: Partial<T['filter']>) => {
    navigate({
      search: (prev: T) => ({ ...prev, filter: { ...prev.filter, ...newFilter }, page: INITIAL_PAGE_NUMBER })
    } as NavigateOptions<AnyRouter, T['filter']>);
  };
  const setSortAndLimit = (newFilter: Pick<T, 'sort' | 'limit'>) => {
    navigate({
      search: (prev: T) => ({ ...prev, sort: newFilter.sort, limit: newFilter.limit, page: INITIAL_PAGE_NUMBER })
    } as NavigateOptions<AnyRouter, T['filter']>);
  };

  const setLimit = (newLimit: number, options?: CustomNavigateOptions) => {
    navigate({
      search: (prev: T) => ({ ...prev, limit: newLimit, page: INITIAL_PAGE_NUMBER }),
      ...options
    } as NavigateOptions<AnyRouter, string>);
  };

  const setPage = (newPage: number, options?: CustomNavigateOptions) => {
    navigate({ search: (prev: T) => ({ ...prev, page: newPage }), ...options } as NavigateOptions<AnyRouter, string>);
  };

  const setPostId = (newPostId: number | string, options?: CustomNavigateOptions) => {
    navigate({
      search: (prev: T) => ({ ...prev, [paramKey]: newPostId }),
      resetScroll: false,
      ...options
    } as NavigateOptions<AnyRouter, string>);
  };

  // TODO: if filter type value mismatch Zod definition (e.g. string vs number), component can get's broken or re-render unexpectedly, not sure maybe transformer can help
  const reinitParamsSearch = (newParams: Partial<T['filter']>) => {
    // You only can reinit if you don't search anything manually yet. `p` will be added after each new filter
    navigate({
      search: (prev: T) => ({ ...prev, filter: { ...prev.filter, ...newParams } }),
      replace: true
    } as NavigateOptions<AnyRouter, string>);
  };

  const setParamsState = (newState: Partial<T['state']>, options?: CustomNavigateOptions) => {
    navigate({
      search: (prev: T) => ({ ...prev, state: { ...prev.state, ...newState } }),
      replace: false,
      resetScroll: false,
      ...options
    } as NavigateOptions<AnyRouter, string>);
  };

  return {
    setSort,
    navigate,
    setPage,
    setLimit,
    setPostId,
    setFilter,
    setFilterForm,
    setParamsState,
    setSortAndLimit,
    reinitParamsSearch,
    hash,
    page,
    sort,
    limit,
    state,
    filter,
    urlPostId: popup
  };
};

export type ParamsSearchReturnType<T extends ParamsSearchProps> = ReturnType<typeof useParamsSearch<T>>;
