import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/GenerateTalentInfluencerSignUpUrl.graphql';
import { Mutation } from '../../__generated__/globalTypes';

export const useGenerateTalentInfluencerSignUpUrlMutation = (options?: MutationHookOptions<Mutation>) => {
  const [callGenerateTalentInfluencerSignUpUrl, result] = useMutation<Mutation>(MUTATION, options);

  return { callGenerateTalentInfluencerSignUpUrl, ...result };
};

export type GenerateTalentInfluencerSignUpUrlMutationFunction = Mutation['generateTalentInfluencerSignUpUrl'];
