import { useState } from 'react';
import {
  InstagramAnalyticsPostsInDate,
  useAllInstagramPostsInDatePromiseQuery,
  useInfluencerAllInstagramPostsInDatePromiseQuery
} from '@/graphql';
import { DAILY_POST_PARAM_KEY } from '@/shared/constants';
import { useFetchDailyPostsOnChart, usePostDetailsParam, useQueryHelper } from '@/shared/hooks';
import { AnalyticsOverviewChartProps } from '../../../../shared';
import { AnalyticsSelectedAccountInfo } from '../../../../types';
import { AnalyticsInstagramDashboardGraphKeys as Keys } from '../types';
import { legendColors } from '../utils';

interface Props {
  isInfluencerProfile?: boolean;
  accountId: AnalyticsSelectedAccountInfo['id'];
  chartData: AnalyticsOverviewChartProps<Keys>['data'];
}

export const useAnalyticsInstagramDashboardOverviewPosts = ({ chartData, accountId, isInfluencerProfile }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { setPostDetailsParam } = usePostDetailsParam(DAILY_POST_PARAM_KEY);
  const [dailyPosts, setDailyPosts] = useState<InstagramAnalyticsPostsInDate | null>(null);
  const { getAllInstagramPostsInDate } = useAllInstagramPostsInDatePromiseQuery();
  const { getInfluencerAllInstagramPostsInDate } = useInfluencerAllInstagramPostsInDatePromiseQuery();

  const fetchDailyPosts = async (postDate: string) => {
    if (!postDate) return;

    try {
      const { data } = isInfluencerProfile
        ? await getInfluencerAllInstagramPostsInDate({ variables: { socialAccountId: accountId, postDate } })
        : await getAllInstagramPostsInDate({ variables: { accountId, postDate } });

      const postsInDate = isInfluencerProfile ? data?.influencerAllInstagramPostsInDate : data?.allInstagramPostsInDate;
      const posts: InstagramAnalyticsPostsInDate = postsInDate || {
        feedPosts: [],
        reelPosts: [],
        storyPosts: []
      };
      const postIds = [
        ...(posts.feedPosts?.map((post) => post.id) || []),
        ...(posts.reelPosts?.map((post) => post.id) || []),
        ...(posts.storyPosts?.map((post) => post.id) || [])
      ];

      if (postIds.length) {
        setDailyPosts(posts);
        setPostDetailsParam(postIds[0]);
      }
    } catch (error) {
      enqueueSnackbar(t('Can not get daily posts data, please try again later '), { variant: 'error' });
    }
  };

  const { customChartOptions } = useFetchDailyPostsOnChart({
    onFetchPosts: fetchDailyPosts,
    postsData: [chartData.posts.data],
    postColors: [legendColors.posts]
  });

  return { dailyPosts, customChartOptions };
};
