import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useCopy } from '@/shared/hooks';
import { THEME } from '@/shared/constants';
import { Anchor, Button, FormStyle } from '@/shared/atoms';
import {
  shopifySettingsImage,
  urlTrackingDiagramImage,
  conversionTracking1Image,
  conversionTracking2Image
} from '@/shared/assets';

const scrollIntoViewOptions: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start', inline: 'nearest' };

export interface TrackingUrlProps {
  className?: string;
  postbackUrl?: string | null;
}

export const TrackingUrl = ({ postbackUrl, className }: TrackingUrlProps) => {
  const { t } = useTranslation();
  const { copyToClipboard } = useCopy();
  const ifShopifyRef = useRef<HTMLHeadingElement>(null);
  const ifNotShopifyRef = useRef<HTMLHeadingElement>(null);
  const ifOwnTrackingToolRef = useRef<HTMLHeadingElement>(null);

  const defaultPixelCode = `<script>
    !function(){var o=window.tdl=window.tdl||[];if(o.invoked)window.console&&console.error&&console.error("Tune snippet has been included more than once.");else{o.invoked=!0,o.methods=["init","identify","convert"],o.factory=function(n){return function(){var e=Array.prototype.slice.call(arguments);return e.unshift(n),o.push(e),o}};for(var e=0;e<o.methods.length;e++){var n=o.methods[e];o[n]=o.factory(n)}o.init=function(e){var n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://js.go2sdk.com/v2/tune.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(n,t),o.domain=e}}}(); 
    tdl.init("https://anymindgroup.go2cloud.org");
    tdl.identify();
  </script>`;
  const bodyPixelCode = `<script>
    tdl.convert({ 'amount': 'PLEASE_INSERT_NUMBERS_HERE' });
  </script>`;
  const conversionWithoutAmount = `<script>
    tdl.convert();
  </script>`;
  const shopifyTotalAmountCode = `!function(){var o=window.tdl=window.tdl||[];if(o.invoked)window.console&&console.error&&console.error("Tune snippet has been included more than once.");else{o.invoked=!0,o.methods=["init","identify","convert"],o.factory=function(n){return function(){var e=Array.prototype.slice.call(arguments);return e.unshift(n),o.push(e),o}};for(var e=0;e<o.methods.length;e++){var n=o.methods[e];o[n]=o.factory(n)}o.init=function(e){var n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://js.go2sdk.com/v2/tune.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(n,t),o.domain=e}}}();
async function getRates(){try{let t=new Date,e=\`\${t.getFullYear()}-\${(t.getMonth()+1+"").padStart(2,"0")}\`,n=\`https://currency.adasiaholdings.com/getCurrency?month=\${e}\`,r=await fetch(n);if(!r.ok)throw Error("Network response was not ok");let a=await r.json();return a||{}}catch(o){return{}}}async function getConversionAmount(t,e){try{let n=await getRates(),r=n[e];if(isNaN(r))throw Error("Invalid rate");return{amount:t/r}}catch(a){return{amount:t,promo_code:e}}}
  tdl.init("https://anymindgroup.go2cloud.org");
  tdl.identify();

  analytics.subscribe("checkout_completed", async (event) => {
    const conversionData = await getConversionAmount(
    event.data.checkout.totalPrice.amount,
    event.data.checkout.currencyCode
    );
  });
  tdl.convert(conversionData);`;

  const shopifySubtotalAmountCode = `!function(){var o=window.tdl=window.tdl||[];if(o.invoked)window.console&&console.error&&console.error("Tune snippet has been included more than once.");else{o.invoked=!0,o.methods=["init","identify","convert"],o.factory=function(n){return function(){var e=Array.prototype.slice.call(arguments);return e.unshift(n),o.push(e),o}};for(var e=0;e<o.methods.length;e++){var n=o.methods[e];o[n]=o.factory(n)}o.init=function(e){var n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://js.go2sdk.com/v2/tune.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(n,t),o.domain=e}}}();
async function getRates(){try{let t=new Date,e=\`\${t.getFullYear()}-\${(t.getMonth()+1+"").padStart(2,"0")}\`,n=\`https://currency.adasiaholdings.com/getCurrency?month=\${e}\`,r=await fetch(n);if(!r.ok)throw Error("Network response was not ok");let a=await r.json();return a||{}}catch(o){return{}}}async function getConversionAmount(t,e){try{let n=await getRates(),r=n[e];if(isNaN(r))throw Error("Invalid rate");return{amount:t/r}}catch(a){return{amount:t,promo_code:e}}}
  
  tdl.init("https://anymindgroup.go2cloud.org");
  tdl.identify();
  
  analytics.subscribe("checkout_completed", async (event) => {
    const conversionData = await getConversionAmount(
    event.data.checkout.subtotalPrice.amount,
    event.data.checkout.currencyCode
    );
  });
  tdl.convert(conversionData);`;

  return (
    <FormStyle.Wrapper css={{ padding: '27px 24px 24px' }} className={className} hasBorder>
      <h2 css={styles.header}>{t('Tracking.Conversion Tracking Settings')}</h2>
      <div css={styles.description}>{t('Tracking.ConversionTrackingDescription_1')}</div>
      <div css={styles.description}>{t('Tracking.ConversionTrackingDescription_2')}</div>
      <div css={[styles.description, { marginTop: '16px' }]}>{t('How URL Tracking works?')}</div>

      <div
        css={{
          padding: '16px',
          marginTop: '8px',
          borderRadius: '5px',
          backgroundColor: THEME.background.colors.gray.lv1
        }}
      >
        <img alt="" src={urlTrackingDiagramImage} css={{ width: '100%' }} />
      </div>
      <ol css={styles.stepsList}>
        <li>1. {t('Tracking.ConversionFlowStep1')}</li>
        <li>2. {t('Tracking.ConversionFlowStep2')}</li>
        <li>3. {t('Tracking.ConversionFlowStep3')}</li>
        <li>4. {t('Tracking.ConversionFlowStep4')}</li>
        <li>5. {t('Tracking.ConversionFlowStep5')}</li>
        <li>6. {t('Tracking.ConversionFlowStep6')}</li>
      </ol>
      <h2 css={[styles.header, { marginTop: '24px' }]}>{t('Where do you place your tracking tags?')}</h2>
      <Button
        variant="white"
        title={t('Client is using a Shopify website')}
        css={[styles.scrollIntoViewBtn, { marginBottom: '16px' }]}
        onClick={() => ifShopifyRef?.current?.scrollIntoView(scrollIntoViewOptions)}
      />
      <Button
        variant="white"
        title={t('Client is not using the Shopify website')}
        css={[styles.scrollIntoViewBtn, { marginBottom: '16px' }]}
        onClick={() => ifNotShopifyRef?.current?.scrollIntoView(scrollIntoViewOptions)}
      />
      <Button
        variant="white"
        css={styles.scrollIntoViewBtn}
        title={t('Client is using its own tracking tool')}
        onClick={() => ifOwnTrackingToolRef?.current?.scrollIntoView(scrollIntoViewOptions)}
      />
      <hr css={[styles.hr, { margin: '40px 0 24px 0' }]} />
      <h2 css={[styles.header, { marginBottom: '4px' }]} ref={ifShopifyRef}>
        {t('If your client uses Shopify website')}
      </h2>
      <Trans i18nKey="You need to put two types of AnyTag tracking tags on their website" components={{ b: <b /> }} />
      <h3 css={styles.subHeader}>① {t('General Tag')}</h3>
      <ol css={styles.stepsList}>
        <li>1. {t('Tracking.ShopifyGeneralTagStep1')}</li>
        <li>2. {t('Tracking.ShopifyGeneralTagStep2')}</li>
        <li>3. {t('Tracking.ShopifyGeneralTagStep3')}</li>
        <img alt="" src={shopifySettingsImage} css={{ margin: '8px 0' }} />
        <li>4. {t('Tracking.ShopifyGeneralTagStep4')}</li>
        <li>5. {t('Tracking.ShopifyGeneralTagStep5')}</li>
        <li>6. {t('Tracking.ShopifyGeneralTagStep6')}</li>
      </ol>
      <textarea css={styles.code} rows={10} readOnly defaultValue={defaultPixelCode} />
      <Button
        variant="white"
        onClick={() => copyToClipboard(defaultPixelCode)}
        title={t('Button.Copy')}
        css={[styles.copyButton, { marginBottom: '24px' }]}
      />
      <h3 css={styles.subHeader}>② {t('Conversion tracking tag')}</h3>
      <ol css={styles.stepsList}>
        <li>1. {t('Tracking.ShopifyConversionTrackingTagStep1')}</li>
        <li>2. {t('Tracking.ShopifyConversionTrackingTagStep2')}</li>
        <li>3. {t('Tracking.ShopifyConversionTrackingTagStep3')}</li>
        <img src={conversionTracking1Image} alt="1" css={{ width: '80%', margin: '8px 0' }} />
        <li>4. {t('Tracking.ShopifyConversionTrackingTagStep4')}</li>
        <li>5. {t('Tracking.ShopifyConversionTrackingTagStep5')}</li>
        <li>6. {t('Tracking.ShopifyConversionTrackingTagStep6')}</li>
        <img src={conversionTracking2Image} alt="1" css={{ width: '80%', margin: '8px 0' }} />
        <li>6-a. {t('Tracking.ShopifyConversionTrackingTagStep6a')}</li>
        <p>{t('Tracking.ShopifyConversionTrackingTagStep6description')}</p>
        <textarea rows={12} css={styles.code} readOnly defaultValue={`${shopifyTotalAmountCode}`} />
        <Button
          variant="white"
          title={t('Button.Copy')}
          css={[styles.copyButton, { marginBottom: '16px' }]}
          onClick={() => copyToClipboard(`${shopifyTotalAmountCode}`)}
        />
        <li>6-b. {t('Tracking.ShopifyConversionTrackingTagStep6b')}</li>
        <p>{t('Tracking.ShopifyConversionTrackingTagStep6description')}</p>
        <textarea rows={12} css={styles.code} readOnly defaultValue={`${shopifySubtotalAmountCode}`} />
        <Button
          variant="white"
          title={t('Button.Copy')}
          css={[styles.copyButton, { marginBottom: '16px' }]}
          onClick={() => copyToClipboard(`${shopifySubtotalAmountCode}`)}
        />
      </ol>

      <hr css={[styles.hr, { margin: '24px 0' }]} />

      <h2 css={[styles.header, { marginBottom: '8px' }]} ref={ifNotShopifyRef}>
        {t('If your client does not use Shopify website')}
      </h2>

      <div css={styles.description}>
        <Trans i18nKey="You need to put two types of AnyTag tracking tags on their website" components={{ b: <b /> }} />
      </div>
      <div css={styles.description}>{t('You can use Google Tag Manager to set tracking tags')}</div>
      <div css={styles.description}>
        <Anchor
          variant="blue"
          target="_blank"
          label={t('How to use Google Tag Manager? (English only)')}
          css={{ fontSize: '13px', lineHeight: '18px', marginBottom: '16px', fontWeight: 'normal' }}
          href="https://anymind-group.gitbook.io/english-1/kyanpn/tracking/integrating-anytag-trackers-with-google-tag-manager"
        />
      </div>

      <h3 css={styles.subHeader}>① {t('General Tag')}</h3>
      <div css={[styles.description, { marginTop: '8px' }]}>{t('Tracking.NonShopifyGeneralTagStep1')}</div>
      <textarea css={[styles.code, { margin: '8px 0' }]} rows={10} readOnly defaultValue={defaultPixelCode} />
      <Button
        variant="white"
        title={t('Button.Copy')}
        onClick={() => copyToClipboard(defaultPixelCode)}
        css={[styles.copyButton, { marginBottom: '24px' }]}
      />

      <h3 css={styles.subHeader}>② {t('Conversion tracking tag')}</h3>
      <ul css={styles.stepsList}>
        <li>{t('Tracking.NonShopifyConversionTrackingStep1')}</li>
        <li>
          <Trans i18nKey="Tracking.NonShopifyConversionTrackingStep2" components={{ b: <b /> }} />
        </li>
      </ul>

      <div css={[styles.description, { marginTop: '8px' }]}>
        {<Trans i18nKey="Tracking.NonShopifyConversionTrackingStep2a" components={{ b: <b /> }} />}
      </div>
      <textarea css={styles.code} rows={3} readOnly defaultValue={conversionWithoutAmount} />
      <Button
        variant="white"
        title={t('Button.Copy')}
        css={[styles.copyButton, { marginBottom: '24px' }]}
        onClick={() => copyToClipboard(conversionWithoutAmount)}
      />

      <div css={[styles.description, { marginTop: '8px' }]}>
        <Trans i18nKey="Tracking.NonShopifyConversionTrackingStep2b" components={{ b: <b /> }} />
      </div>
      <div css={styles.description}>{t('Tracking.NonShopifyConversionTrackingStep2b_1')}</div>
      <textarea css={styles.code} rows={3} readOnly defaultValue={bodyPixelCode} />
      <Button
        variant="white"
        title={t('Button.Copy')}
        onClick={() => copyToClipboard(bodyPixelCode)}
        css={[styles.copyButton, { marginBottom: '24px' }]}
      />

      {postbackUrl && (
        <>
          <hr css={styles.hr} />
          <h2 css={styles.header} ref={ifOwnTrackingToolRef}>
            {t(`If client uses own tracking tool, please ask to set "post back URL" on the client's tracking tool`)}
          </h2>
          <div css={styles.description}>
            <Trans i18nKey="Tracking.PostbackTrackingStep1" components={{ b: <b /> }} />
          </div>
          <textarea css={styles.code} rows={1} readOnly defaultValue={postbackUrl} />
          <Button
            variant="white"
            css={styles.copyButton}
            title={t('Button.Copy')}
            onClick={() => copyToClipboard(postbackUrl)}
          />
        </>
      )}
    </FormStyle.Wrapper>
  );
};

const styles = {
  stepsList: css({ margin: '8px 0', lineHeight: '20px' }),
  hr: css({ height: '1px', margin: '24px 0', backgroundColor: '#eef3f7' }),
  subHeader: css({ fontSize: '14px', fontWeight: '600', marginTop: '16px' }),
  description: css({ color: THEME.text.colors.black.lv1, fontSize: '14px', lineHeight: '18px' }),
  copyButton: css({ minWidth: '85px', marginTop: '8px', textTransform: 'uppercase' }),
  header: css({
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: '16px',
    lineHeight: 'normal',
    color: THEME.text.colors.black.lv1
  }),
  scrollIntoViewBtn: css({
    height: '51px',
    fontSize: '16px',
    minWidth: '332px',
    overflow: 'hidden',
    paddingLeft: '16px',
    letterSpacing: 'unset',
    textOverflow: 'ellipsis',
    justifyContent: 'flex-start'
  }),
  code: css({
    width: '100%',
    lineHeight: 1.38,
    fontSize: '13px',
    marginTop: '16px',
    resize: 'vertical',
    borderRadius: '2px',
    boxSizing: 'border-box',
    border: THEME.border.base,
    padding: '8px 16px 8px 14px',
    color: THEME.text.colors.black.lv1,
    backgroundColor: THEME.background.colors.gray.lv1
  })
};
