import * as Sentry from '@sentry/browser';

declare const IS_STG: boolean;

interface SentryConfigType {
  dsn: string;
  debug: boolean;
  ignoreErrors: string[];
  environment: 'staging' | 'production';
}

export const initSentry = () => {
  const ignoreErrors = ['ResizeObserver loop limit exceeded'];

  const config: SentryConfigType = IS_STG
    ? {
        debug: true,
        ignoreErrors,
        environment: 'staging',
        dsn: 'https://e0e40a1b7033418da6c192bccd32dfe4@sentry.io/5168725'
      }
    : {
        ignoreErrors,
        debug: false,
        environment: 'production',
        dsn: 'https://e0e40a1b7033418da6c192bccd32dfe4@sentry.io/5168725'
      };

  const isDEV = process.env.NODE_ENV === 'development';

  if (!isDEV) {
    Sentry.init(config);
  }
};
