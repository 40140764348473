import { CampaignsList } from './CampaignsList';
import { CampaignsSummary } from './CampaignsSummary';

export interface InfluencerSignedUpProfileProps {
  influencerId: number;
  socialAccountId: number;
}
export const InfluencerSignedUpProfile = ({ influencerId, socialAccountId }: InfluencerSignedUpProfileProps) => (
  <>
    <CampaignsSummary influencerId={influencerId} />
    <CampaignsList influencerId={influencerId} socialAccountId={socialAccountId} />
  </>
);
