import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useUserRoles } from '@/auth';
import { THEME } from '@/shared/constants';
import { formatNumber, formatPercent } from '@/shared/utils';
import { ColumnProps, Input, RowProps, TableCell, TextCutter } from '@/shared/atoms';
import { useUpdateYoutubeAssetChannelId } from '../../hooks';
import { YoutubeCMSAsset, YoutubeCMSRevenueType } from '../../types';

interface Props {
  modifiable?: boolean;
  type: YoutubeCMSRevenueType;
  data: readonly YoutubeCMSAsset[];
  deletingAssetOwner: YoutubeCMSAsset | null;
  onSetDeletingAssetOwner: (owner: YoutubeCMSAsset | null) => void;
}

export const useYoutubeCMSAssetsTable = ({
  type,
  data,
  modifiable = true,
  deletingAssetOwner,
  onSetDeletingAssetOwner
}: Props) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { saving, editingRows, handleEnter, handleRowChange, handleSaveChange } = useUpdateYoutubeAssetChannelId();

  const isConfirmedRevenue = type === YoutubeCMSRevenueType.CONFIRMED_REVENUE;
  const canEditChannelId = !isConfirmedRevenue && modifiable;
  const columns: ColumnProps[] = [
    {
      title: t('HeaderColumn.Asset name'),
      sticky: { active: true, hasBorderRight: isAdminStaff && !isConfirmedRevenue }
    },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Estimated RPM'),
      hidden: !isAdminStaff || isConfirmedRevenue
    },
    {
      hidden: isConfirmedRevenue,
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Estimated talent agency RPM')
    },
    {
      hidden: !isAdminStaff,
      styles: { textAlign: 'right' },
      title: t(isConfirmedRevenue ? 'HeaderColumn.Confirmed partner revenue' : 'HeaderColumn.Estimated partner revenue')
    },
    {
      hidden: isConfirmedRevenue,
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Estimated talent agency revenue')
    },
    {
      styles: { textAlign: 'right' },
      title: t(
        isConfirmedRevenue ? 'HeaderColumn.Confirmed share to creator' : 'HeaderColumn.Estimated share to creator'
      )
    },
    {
      hidden: !isAdminStaff,
      styles: { textAlign: 'right' },
      title: t(isConfirmedRevenue ? 'HeaderColumn.Confirmed gross profit' : 'HeaderColumn.Estimated GP')
    },
    {
      hidden: isConfirmedRevenue,
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Estimated talent agency GP')
    },
    {
      styles: { textAlign: 'right' },
      hidden: !isAdminStaff || isConfirmedRevenue,
      title: t('HeaderColumn.Talent agency margin rate')
    },
    { title: t('HeaderColumn.Asset owner channel id') },
    { title: t('HeaderColumn.Asset owner channel name') }
  ];
  const rows: RowProps[] = data.map((item) => {
    const {
      id,
      rpm,
      taRpm,
      profit,
      isOwner,
      assetId,
      taProfit,
      currency,
      taRevenue,
      assetName,
      taShareRate,
      shareCreator,
      ownerChannelId,
      partnerRevenue,
      ownerChannelName
    } = item;
    const unit = t(currency || '');
    const savedRow = editingRows.find((row) => row.id === id);
    const formattedChannelId = savedRow?.value || ownerChannelId || '';

    return [
      <div css={{ display: 'flex', minWidth: '200px' }}>
        <div css={{ lineHeight: '100%' }}>
          <TextCutter css={{ fontSize: '14px' }} text={assetName || '-'} lines={2} />
          <p css={{ fontSize: '10px', color: THEME.text.colors.gray.lv3, marginTop: '4px' }}>
            {t('id')}: {assetId}
          </p>
        </div>
      </div>,
      <TableCell.Number value={formatNumber(rpm)} unit={unit} />,
      <TableCell.Number value={formatNumber(taRpm)} unit={unit} />,
      <TableCell.Number value={formatNumber(partnerRevenue)} unit={unit} />,
      <TableCell.Number value={formatNumber(taRevenue)} unit={unit} />,
      <TableCell.Number value={formatNumber(shareCreator)} unit={unit} />,
      <TableCell.Number value={formatNumber(profit || null)} unit={unit} />,
      <TableCell.Number value={formatNumber(taProfit || null)} unit={unit} />,
      <TableCell.Number value={formatPercent(taShareRate || null)} unit="%" />,
      <div css={{ minWidth: '170px' }}>
        {!canEditChannelId || !id || ownerChannelId ? (
          <TextCutter css={styles.channelId} text={ownerChannelId} />
        ) : (
          <Input
            disabled={!!ownerChannelId}
            onKeyPress={handleEnter}
            css={{ width: '258px' }}
            hasError={savedRow?.error}
            value={formattedChannelId}
            placeholder={t<string>('TextForm.Input channel id')}
            onChange={({ target: { value } }) => handleRowChange({ id, value, defaultValue: ownerChannelId || '' })}
          />
        )}

        {isOwner && canEditChannelId && id && ownerChannelId ? (
          <p
            onClick={() => onSetDeletingAssetOwner(item)}
            css={{ marginTop: '4px', fontSize: '12px', color: THEME.text.colors.blue, cursor: 'pointer' }}
          >
            {t('Clear owner')}
          </p>
        ) : null}
      </div>,
      <TableCell.Text value={ownerChannelName} lines={2} />
    ];
  });

  return {
    rows,
    saving,
    columns,
    handleSaveChange,
    deletingAssetOwner,
    hasRowChange: !!editingRows.length
  };
};
const styles = {
  channelId: css({
    height: '32px',
    width: '258px',
    display: 'flex',
    padding: '0 8px',
    userSelect: 'all',
    borderRadius: '3px',
    background: '#f6f8fa',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: '1px solid #c5d0da'
  })
};
