import { useAuthData } from '@/auth';
import {
  AdvertiserType,
  useCreateSelfRegisteredAdvertiserUserMutation,
  useTiktokAdvertiserAccountInfoQuery,
  useUpdateTiktokAdvertiserInfoMutation
} from '@/graphql';
import { useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import {
  TikTokSignupForm,
  TikTokSignupFormRegistered,
  TikTokSignupFormValues,
  TikTokSignupFormRegisteredValues
} from '@/shared/organisms';
import { manageLocalStorage } from '@/shared/utils';

export const TikTokRegistration = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.SignUp', appHeader: 'documentTitle.SignUp' });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { auth } = useAuthData();
  const { callCreateSelfRegisteredAdvertiserUser } = useCreateSelfRegisteredAdvertiserUserMutation();
  const { callUpdateTiktokAdvertiserInfo } = useUpdateTiktokAdvertiserInfoMutation();
  const { data } = useTiktokAdvertiserAccountInfoQuery({ skip: !auth.userId });

  const handleSubmit = async (payload: TikTokSignupFormValues) => {
    manageLocalStorage('set', 'confirmationEmail', payload.email);

    try {
      await callCreateSelfRegisteredAdvertiserUser({
        variables: {
          input: {
            advertiserCompanyName: payload.companyName,
            advertiserCompanyUrl: payload.companyUrl,
            advertiserPhoneNumber: payload.phoneNumber,
            countryId: payload.countryId,
            userName: payload.name,
            userEmail: payload.email,
            advertiserType: AdvertiserType.TIKTOK_BUSINESS
          }
        }
      });

      enqueueSnackbar(t('Your account was created successfully'), {
        variant: 'success'
      });
      navigate({ to: '/tiktok-onboarding/success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleSubmitUpdate = async (payload: TikTokSignupFormRegisteredValues) => {
    try {
      await callUpdateTiktokAdvertiserInfo({
        variables: {
          input: {
            advertiserName: payload.companyName,
            advertiserUrl: payload.companyUrl,
            countryId: payload.countryId,
            phoneNumber: payload.phoneNumber
          }
        }
      });

      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const accountInfo = data?.tiktokAdvertiserAccountInfo;

  return auth.userId ? (
    <TikTokSignupFormRegistered
      onSubmit={handleSubmitUpdate}
      defaultValues={{
        companyName: accountInfo?.advertiserName,
        companyUrl: accountInfo?.advertiserUrl,
        phoneNumber: accountInfo?.phoneNumber,
        countryId: accountInfo?.countryId
      }}
    />
  ) : (
    <TikTokSignupForm onSubmit={handleSubmit} />
  );
};
