import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TiktokSpecialPostHistory.graphql';
import { Query, QuerytiktokSpecialPostHistoryArgs } from '../../__generated__/globalTypes';

export const useTiktokSpecialPostHistoryQuery = (
  options?: QueryHookOptions<Query, QuerytiktokSpecialPostHistoryArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytiktokSpecialPostHistoryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTiktokSpecialPostHistoryLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytiktokSpecialPostHistoryArgs>
): QueryResult<Query, QuerytiktokSpecialPostHistoryArgs> & {
  getTiktokSpecialPostHistory: LazyQueryExecFunction<Query, QuerytiktokSpecialPostHistoryArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTiktokSpecialPostHistory, result] = useLazyQuery<Query, QuerytiktokSpecialPostHistoryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTiktokSpecialPostHistory, ...result };
};

export const useTiktokSpecialPostHistoryPromiseQuery = () => {
  const client = useApolloClient();

  const getTiktokSpecialPostHistory = useCallback(
    (options?: Omit<QueryOptions<QuerytiktokSpecialPostHistoryArgs, Query>, 'query'>) =>
      client.query<Query, QuerytiktokSpecialPostHistoryArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTiktokSpecialPostHistory };
};
