import { css } from '@emotion/react';
import { useChatDemoContext } from './ChatDemoProvider';
import { ChatDemoHeader, ChatDemoMessagesList, ChatDemoInput, ChatDemoLink } from './shared';

export const ChatDemoChat = () => {
  const { messages, savedLocation, refreshChatDemo, addNewMessages } = useChatDemoContext();

  const hasBackToListItem = !!savedLocation;

  return (
    <>
      <ChatDemoHeader onClear={refreshChatDemo} />

      <div css={[styles.wrapper, hasBackToListItem && { marginTop: '24px' }]}>
        {hasBackToListItem ? (
          <ChatDemoLink
            state={savedLocation.state}
            label="インフルエンサー検索の結果"
            css={{ marginBottom: '24px' }}
            href={savedLocation.pathname}
            search={savedLocation.search}
          />
        ) : null}

        <ChatDemoMessagesList messages={messages} />
        <ChatDemoInput
          css={{ marginTop: '24px' }}
          onSubmit={(message, callback) => {
            if (message) {
              addNewMessages([{ position: 'right', text: message }]);
              callback();
            }
          }}
        />
      </div>
    </>
  );
};

const styles = {
  wrapper: css({
    flex: '1 0 0',
    width: '800px',
    height: '100%',
    display: 'flex',
    margin: '48px auto',
    flexDirection: 'column',
    alignItems: 'space-between'
  })
};
