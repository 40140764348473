import { useAllInstagramComparePostsInDateQuery } from '@/graphql';
import { Modal } from '@/shared/atoms';
import {
  ComparePostsDetails,
  ComparePostsDetailsProps,
  ComparePostsDetailsPostType,
  ComparePostsDetailsAccountType
} from '../../PostDetails';

export interface InstagramComparePostsInDateDetailsModalProps {
  onClose: () => void;
  mainAccountId: number;
  postDate?: string | null;
  comparableAccounts: ComparePostsDetailsAccountType[];
}

export const InstagramComparePostsInDateDetailsModal = ({
  onClose,
  postDate,
  mainAccountId,
  comparableAccounts
}: InstagramComparePostsInDateDetailsModalProps) => {
  const comparableAccountIds = comparableAccounts.reduce<number[]>(
    (acc, curr) => (curr.id !== mainAccountId ? [...acc, curr.id] : acc),
    []
  );
  const { data, loading } = useAllInstagramComparePostsInDateQuery({
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
    skip: !postDate || !comparableAccountIds.length,
    variables: { postDate: postDate as string, accountId: mainAccountId, comparableAccountIds }
  });

  const postsData = data?.allInstagramComparePostsInDate;
  const comparePostsData = postsData
    ? comparableAccounts.reduce<ComparePostsDetailsProps['data']>((acc, curr) => {
        const comparableAccountData = comparableAccounts.find((account) => account.id === curr.id);
        if (curr.id === mainAccountId) {
          const { feedPosts, storyPosts } = postsData.mainAccount;
          acc[curr.id] = [
            ...(feedPosts?.map<ComparePostsDetailsPostType>((post) => ({
              caption: post.content,
              postUrl: post.postUrl,
              socialMedia: 'INSTAGRAM',
              images: post.mediaUrls || [],
              comments: post.commentList || [],
              statistics: {
                likes: post.analysis?.likes,
                comments: post.analysis?.comments,
                views: post.analysis?.views,
                saves: post.analysis?.saves,
                reach: post.analysis?.reach,
                impressions: post.analysis?.impressions
              },
              info: {
                ...comparableAccountData,
                postDate: post.postDate,
                socialMedia: 'INSTAGRAM',
                followers: post.followers
              }
            })) || []),
            ...(storyPosts?.map<ComparePostsDetailsPostType>((post) => ({
              caption: post.content,
              postUrl: post.postUrl,
              socialMedia: 'INSTAGRAM',
              images: post.postImageUrl ? [post.postImageUrl] : [],
              statistics: {
                comments: post.comments,
                reach: post.reach,
                impressions: post.impressions,
                exits: post.exits,
                tapsForward: post.tapsForward,
                tapsBack: post.tapsBack
              },
              info: {
                ...comparableAccountData,
                postDate: post.postDate,
                socialMedia: 'INSTAGRAM',
                followers: post.followers
              }
            })) || [])
          ];
        } else {
          const feedPosts = postsData.compareAccounts.find(({ accountId }) => accountId === curr.id)?.feedPosts;
          acc[curr.id] = [
            ...(feedPosts?.map<ComparePostsDetailsPostType>((post) => ({
              caption: post.content,
              postUrl: post.postUrl,
              socialMedia: 'INSTAGRAM',
              comments: post.commentList || [],
              images: post.postImageUrl ? [post.postImageUrl] : [],
              statistics: { likes: post.analysis?.likes, comments: post.analysis?.comments },
              info: {
                ...comparableAccountData,
                postDate: post.postDate,
                socialMedia: 'INSTAGRAM',
                followers: post.followers
              }
            })) || [])
          ];
        }

        return acc;
      }, {})
    : undefined;

  return comparableAccounts.length ? (
    <Modal
      onClose={onClose}
      css={{ width: 'max-content' }}
      open={!!(postDate && !loading && comparePostsData)}
      hasCloseIcon
    >
      {comparePostsData ? <ComparePostsDetails data={comparePostsData} accounts={comparableAccounts} /> : null}
    </Modal>
  ) : null;
};
