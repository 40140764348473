import { useTranslation } from 'react-i18next';
import { YoutubeAnalyticsOverviewItem, YoutubeAnalyticsOverviewPayload } from '@/graphql';
import { AnalyticsOverviewChartProps } from '../../../../shared';
import { AnalyticsYoutubeDashboardGraphKeys as Keys, AnalyticsYoutubeDashboarOverviewEngagementType } from '../types';
import { allOverviewEngagements, legendColors } from '../utils';

interface Props {
  selectedLegendKeys: Keys[];
  availableEngagementKeys: Keys[];
  data: YoutubeAnalyticsOverviewPayload;
}

interface ChartValuesType {
  categories: string[];
  postsCount: number[];
  likesCount: number[];
  viewsCount: number[];
  dislikesCount: number[];
  commentsCount: number[];
  subscribersCount: number[];
}
const defaultChartValues: ChartValuesType = {
  categories: [],
  postsCount: [],
  likesCount: [],
  viewsCount: [],
  dislikesCount: [],
  commentsCount: [],
  subscribersCount: []
};

const getEngagementMetrics = ({
  total,
  growth,
  percentage
}: Pick<YoutubeAnalyticsOverviewItem, 'total' | 'growth' | 'percentage'>) => ({
  total: total || 0,
  growth: growth || 0,
  percentage: percentage || 0
});

export const useAnalyticsYoutubeDashboardOverviewData = ({
  data,
  selectedLegendKeys,
  availableEngagementKeys
}: Props) => {
  const { t } = useTranslation();

  const { post, view, like, dislike, comment, subscriber } = data;
  const overviewEngagements = allOverviewEngagements.map<AnalyticsYoutubeDashboarOverviewEngagementType>((item) => ({
    ...item,
    ...getEngagementMetrics(data[item.key]),
    title: t<string>(item.title)
  }));
  const availableOverviewEngagements = overviewEngagements.filter((eng) => availableEngagementKeys.includes(eng.key));

  const { categories, postsCount, likesCount, viewsCount, dislikesCount, commentsCount, subscribersCount } = (
    subscriber.history || []
  ).reduce<ChartValuesType>((acc, curr) => {
    const date = curr.date || '';

    return {
      categories: [...acc.categories, date],
      subscribersCount: [...acc.subscribersCount, Number(curr.count)],
      postsCount: [...acc.postsCount, post.history?.find((item) => item.date === date)?.count || 0],
      likesCount: [...acc.likesCount, like.history?.find((item) => item.date === date)?.count || 0],
      viewsCount: [...acc.viewsCount, view.history?.find((item) => item.date === date)?.count || 0],
      dislikesCount: [...acc.dislikesCount, dislike.history?.find((item) => item.date === date)?.count || 0],
      commentsCount: [...acc.commentsCount, comment.history?.find((item) => item.date === date)?.count || 0]
    };
  }, defaultChartValues);

  const chartData: AnalyticsOverviewChartProps<Keys>['data'] = {
    subscriber: {
      zIndex: 1,
      type: 'line',
      showIncrement: true,
      data: subscribersCount,
      name: t<string>('Subscriber'),
      color: legendColors.subscriber,
      visible: selectedLegendKeys.includes('subscriber'),
      marker: {
        lineWidth: 2,
        symbol: 'circle',
        fillColor: '#fff',
        lineColor: undefined // inherit from chart
      }
    },
    post: {
      yAxis: 1,
      type: 'column',
      data: postsCount,
      name: t<string>('Post'),
      color: legendColors.post,
      visible: selectedLegendKeys.includes('post')
    },
    view: {
      yAxis: 2,
      type: 'column',
      data: viewsCount,
      name: t<string>('Views'),
      color: legendColors.view,
      visible: selectedLegendKeys.includes('view')
    },
    like: {
      yAxis: 3,
      type: 'column',
      data: likesCount,
      name: t<string>('Like'),
      color: legendColors.like,
      visible: selectedLegendKeys.includes('like')
    },
    dislike: {
      yAxis: 4,
      type: 'column',
      data: dislikesCount,
      color: legendColors.dislike,
      name: t<string>('Dislike'),
      visible: selectedLegendKeys.includes('dislike')
    },
    comment: {
      yAxis: 5,
      type: 'column',
      data: commentsCount,
      name: t<string>('Comments'),
      color: legendColors.comment,
      visible: selectedLegendKeys.includes('comment')
    }
  };

  return {
    chartData,
    categories,
    overviewEngagements,
    availableOverviewEngagements
  };
};
