import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { useAllPicUserOptions, useAllCampaignCategoryOptions } from '@/shared/hooks';
import { FILTER_SECTIONS, HubspotDealsField, MoreFilterPopover, SelectorField } from '@/shared/molecules';
import { EngagementCampaignsFilterFormValues, EngagementCampaignsFilterFormKeys } from './schemaTypes';
import { defaultEngagementCampaignsFilterValues } from './utils';

interface AdvancedFilterProps {
  count: number;
  onSubmit?: () => void;
}

export const AdvancedFilter = ({ count, onSubmit }: AdvancedFilterProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { allPicsUserOptions } = useAllPicUserOptions();
  const { allCampaignCategoryOptions } = useAllCampaignCategoryOptions();
  const { watch, reset } = useFormContext<EngagementCampaignsFilterFormValues>();

  const values = watch();

  const handleClear = () => {
    reset({
      ...defaultEngagementCampaignsFilterValues,
      // Basic filter
      status: values.status,
      keyword: values.keyword,
      countryIds: values.countryIds
    });
  };

  return (
    <MoreFilterPopover
      count={count}
      onClear={handleClear}
      onSubmit={onSubmit}
      defaultOpened={[FILTER_SECTIONS.CampaignInformation]}
      items={[
        {
          value: FILTER_SECTIONS.CampaignInformation,
          node: (
            <>
              <SelectorField<EngagementCampaignsFilterFormKeys>
                title={t('Selector.Campaign Category')}
                name="campaignCategoryIds"
                options={allCampaignCategoryOptions}
                multiple
              />
              {isAdminStaff ? (
                <>
                  <SelectorField<EngagementCampaignsFilterFormKeys>
                    help={t('Tooltip.Sales PIC')}
                    title={t('Selector.Sales PIC')}
                    name="salesPicIds"
                    options={allPicsUserOptions}
                    multipleSelectorProps={{ hasSelectAll: false }}
                    multiple
                  />

                  <SelectorField<EngagementCampaignsFilterFormKeys>
                    name="influencerPicIds"
                    options={allPicsUserOptions}
                    help={t('Tooltip.Influencer Management PIC')}
                    title={t('Selector.Influencer Management PIC')}
                    multipleSelectorProps={{ hasSelectAll: false }}
                    multiple
                  />

                  <HubspotDealsField<EngagementCampaignsFilterFormKeys>
                    name="hubspotDealIds"
                    placeholder={t<string>('Search by Deal name or Deal ID')}
                  />
                </>
              ) : null}
            </>
          )
        }
      ]}
    />
  );
};
