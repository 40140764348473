import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { RowProps, TableCell } from '@/shared/atoms';
import { formatIntNumber, formatPercent, formatPrice } from '@/shared/utils';
import { CampaignDetailReport, CampaignReportSummary, AllCampaignsReportSummary } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useReportColumns } from './useReportColumns';

interface Props {
  data: readonly CampaignDetailReport[];
  summaryData?: AllCampaignsReportSummary;
}

export const useReportTable = ({ data, summaryData }: Props) => {
  const { t } = useTranslation();
  const { columns, selectedColumns } = useReportColumns({ total: summaryData?.total });

  const currency = t(summaryData?.currency || '');

  const getStatCells = (rowStats?: CampaignReportSummary | null) => [
    <TableCell.Number unit={rowStats?.cost ? currency : ''} value={formatPrice(rowStats?.cost || null, currency)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.followers)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressions)} />,
    <TableCell.Number value={formatPercent(rowStats?.impressionRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromDiscovery)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromHashtag)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromHome)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromProfile)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromOther)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.view)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.averageViewDurationMinutes)} unit="m" />,
    <TableCell.Number value={formatPercent(rowStats?.averageViewDurationPercentage, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.reach)} />,
    <TableCell.Number value={formatPercent(rowStats?.reachRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.nonFollowerReach)} />,
    <TableCell.Number value={formatPercent(rowStats?.nonFollowerReachRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.engagement)} />,
    <TableCell.Number value={formatPercent(rowStats?.engagementRate, false)} unit="%" />,
    <TableCell.Number value={formatPercent(rowStats?.engagementViewsRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.like)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.dislikes)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.comment)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.share)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.share)} />, // Retweets
    <TableCell.Number value={formatIntNumber(rowStats?.saved)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.interaction)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.linkClicks)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.stickerTaps)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.click)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.conversion)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.orders)} />,
    <TableCell.Number value={formatPrice(rowStats?.sales || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerSale || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerFollower || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerImpressions || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerView || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerReach || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerEngagement || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerLike || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerComment || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerShare || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerSaved || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerClick || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerAction || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerOrder || null, currency)} unit={currency} />,
    <TableCell.Number value={formatIntNumber(rowStats?.back)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.exited)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.forward)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.nextStory)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.navigation)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.profileActivity)} />
  ];

  const totalRow: RowProps = {
    styles: css({
      height: '44px',
      pointerEvents: 'none',
      '> td': { fontWeight: 600, backgroundColor: THEME.table.header.background.colors.lv2 }
    }),
    cells: [
      <div css={{ minWidth: '290px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span css={{ fontSize: '11px' }}>{t('HeaderColumn.Total')}</span>
        <span>{summaryData?.total}</span>
      </div>,
      ...getStatCells(summaryData?.summary)
    ]
  };
  const campaignRows: RowProps[] = data.map(({ campaign, ...stats }) => [
    <TableCell.Campaign
      type={campaign.type}
      title={campaign.title}
      src={campaign.thumbNail}
      id={campaign.id.toString()}
      mkpCampaignType={campaign.mkpDetailType}
      isPostTracking={!!campaign.hasPostTracking}
    />,
    ...getStatCells(stats)
  ]);

  return { columns, selectedColumns, rows: [totalRow, ...campaignRows] };
};
