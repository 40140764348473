import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteUserProposalSocialAccountIndividually.graphql';
import { Mutation, MutationdeleteUserProposalSocialAccountIndividuallyArgs } from '../../__generated__/globalTypes';

export const useDeleteUserProposalSocialAccountIndividuallyMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteUserProposalSocialAccountIndividuallyArgs>
) => {
  const [callDeleteUserProposalSocialAccountIndividually, result] = useMutation<
    Mutation,
    MutationdeleteUserProposalSocialAccountIndividuallyArgs
  >(MUTATION, options);

  return { callDeleteUserProposalSocialAccountIndividually, ...result };
};

export type DeleteUserProposalSocialAccountIndividuallyMutationFunction =
  Mutation['deleteUserProposalSocialAccountIndividually'];
