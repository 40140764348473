import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { notFoundImage } from '@/shared/assets';
import { Anchor } from '@/shared/atoms';
import { useDefaultAppRoute, useUpdateDocumentTitle } from '@/shared/hooks';

export const NotFound = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.NotFound' });
  const { t } = useTranslation();
  const { defaultRoute } = useDefaultAppRoute();

  return (
    <div css={{ background: `url(${notFoundImage}) center/cover no-repeat` }}>
      <div css={styles.shade} className="error_wrapper">
        <div css={styles.content}>
          <h2 css={styles.header}>404</h2>
          <h3 css={styles.subHeader}>{t('The page you are looking for does not exist')}</h3>
          <p css={styles.text}>{t('Sorry the page you are looking for could not be found')}</p>
          <Anchor css={styles.link} variant="blue" to={defaultRoute}>
            {t('Go to top page')}
          </Anchor>
        </div>
      </div>
    </div>
  );
};
const styles = {
  text: css({ fontWeight: 600, fontSize: '18px', marginTop: '10px', lineHeight: '1.4em' }),
  subHeader: css({ fontWeight: 600, fontSize: '22px', marginTop: '20px', padding: '0 24px', lineHeight: '1.4em' }),
  shade: css({
    width: '100%',
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
  }),
  content: css({
    padding: '0 5%',
    margin: '0 auto',
    color: '#f0f0f0',
    maxWidth: '740px',
    textAlign: 'center',
    textShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)'
  }),
  header: css({
    color: '#333',
    fontWeight: 600,
    fontSize: '80px',
    lineHeight: '60px',
    textShadow: '2px 2px 10px #f0f0f0',
    '@media screen and(max-width: 850px)': { fontSize: '60px' }
  }),
  link: css({
    fontSize: '16px',
    marginTop: '24px',
    textAlign: 'center',
    textDecoration: 'underline',
    transition: 'color 0.3s ease-in-out',
    '&:hover': { textDecoration: 'underline' }
  })
};
