import { css } from '@emotion/react';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { Anchor, CheckBox, RowProps, TableCell, SocialIcon, ColumnProps, CheckBoxProps } from '@/shared/atoms';
import { useDeepCompareEffect, useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { InfluencerAvatar, SpreadButton } from '@/shared/molecules';
import { formatIntNumber, formatNumber, formatPercent } from '@/shared/utils';
import {
  EditableProposeInfluencersFieldKeys,
  ProposeInfluencersTableMode,
  ProposedInfluencer,
  RowData,
  RowSocialAccountData,
  RowStatsData
} from '../schemaTypes';
import { getProposedInfluencersRowsData } from '../utils';
import { useProposeInfluencersApi } from './useProposeInfluencersApi';

interface Props {
  currency: string;
  listSelectedIds?: number[];
  tableViewMode: ProposeInfluencersTableMode;
  influencers: ProposedInfluencer[];
  selectButtonProps: CheckBoxProps;
  jdMode?: ProposalJobDescriptionBreakDownMode;
  onRowSelect: (influencerId: number, socialAccountId?: number) => void;
}

export const useProposedInfluencersTable = ({
  jdMode,
  currency,
  onRowSelect,
  influencers,
  tableViewMode,
  selectButtonProps,
  listSelectedIds = []
}: Props) => {
  const { t } = useTranslation();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const { handleRemoveSmAccountFromProposal } = useProposeInfluencersApi();
  const initialRowData = getProposedInfluencersRowsData(influencers, jdMode);
  const [hasRowChange, setHasRowChange] = useState<boolean>(false);
  const [rowsData, setRowsData] = useState<RowData[]>(initialRowData);

  useDeepCompareEffect(() => {
    const newInitialRowData = getProposedInfluencersRowsData(influencers, jdMode);
    setRowsData(newInitialRowData);
  }, [influencers, jdMode]);

  const isViewMode = tableViewMode === ProposeInfluencersTableMode.VIEW;
  const isEditMode = tableViewMode === ProposeInfluencersTableMode.EDIT;

  const resetEditingRowsData = () => {
    setRowsData(initialRowData);
    setHasRowChange(false);
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['.', ',', 'e', '+', '-'].includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleRowChange = debounce((index: number, stats: RowStatsData) => {
    const newAllRowValues = [...rowsData];
    newAllRowValues[index] = { ...(newAllRowValues[index] as RowData), stats };

    setRowsData(newAllRowValues);

    if (!hasRowChange) {
      setHasRowChange(true);
    }
  }, 500);

  const handleStatsChange = (name: string, value: string, index: number, stats: RowStatsData) => {
    // Make number always positive
    const inputValue = Math.abs(value ? Number(value) : 0);
    if (isEditMode) {
      const newValues: RowStatsData = { ...stats };
      switch (name) {
        case EditableProposeInfluencersFieldKeys.budget:
          newValues.budget = inputValue;
          newValues.profit = inputValue - newValues.influencerCost;
          break;
        case EditableProposeInfluencersFieldKeys.influencerCost:
          newValues.influencerCost = inputValue;
          newValues.profit = newValues.budget - inputValue;
          break;
        default:
          break;
      }

      handleRowChange(index, newValues);
    }
  };

  const renderStatCells = ({ index, stats }: RowData, editable?: boolean) => {
    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      handleStatsChange(name, value, index, stats);
    };

    return [
      <TableCell.Number key={`followers-${index}`} value={formatIntNumber(stats.followersCount)} />,
      <TableCell.Number
        key={`er-by-views-${index}`}
        value={formatPercent(stats.engagementRateByViews, false)}
        unit="%"
      />,
      <TableCell.Number
        key={`er-by-followers${index}`}
        value={formatPercent(stats.engagementRateByFollowers, false)}
        unit="%"
      />,
      !editable ? (
        <TableCell.Number key={`budget-${index}`} value={formatNumber(stats.budget, 0)} unit={t(currency)} />
      ) : (
        <TableCell.Input
          min={0}
          type="number"
          placeholder="0"
          unit={currency}
          key={`budget-input-${index}`}
          onChange={handleInputChange}
          defaultValue={stats.budget}
          onKeyPress={handleInputKeyPress}
          name={EditableProposeInfluencersFieldKeys.budget}
          css={{ justifyContent: 'flex-end' }}
        />
      ),
      !editable ? (
        <TableCell.Number
          key={`influencer-cost-${index}`}
          value={formatNumber(stats.influencerCost, 0)}
          unit={t(currency)}
        />
      ) : (
        <TableCell.Input
          key={`influencer-cost-input-${index}`}
          min={0}
          type="number"
          placeholder="0"
          unit={currency}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          defaultValue={stats.influencerCost}
          css={{ justifyContent: 'flex-end' }}
          name={EditableProposeInfluencersFieldKeys.influencerCost}
        />
      ),
      <TableCell.Number key={`profit-${index}`} value={formatNumber(stats.profit, 0)} unit={t(currency)} />,
      <TableCell.Number key={`engagement-proposed-${index}`} value={formatNumber(stats.engagementProposed, 0)} />,
      <TableCell.Number key={`engagement-posted-${index}`} value={formatNumber(stats.engagementPosted, 0)} />,
      <TableCell.Date key={`date-${index}`} css={{ minWidth: '80px' }} value={stats.addedDate} />
    ];
  };

  const renderInfluencerRow = (rowData: RowData): RowProps => {
    const { influencer } = rowData;
    const checked = listSelectedIds.includes(influencer.id);

    return [
      isViewMode || jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN ? (
        <CheckBox
          checked={checked}
          key={`checkbox-${influencer.id}`}
          onClick={() => onRowSelect(influencer.id)}
          tooltipProps={{ help: t(checked ? 'Unselect' : 'Select') }}
        />
      ) : (
        <div key={`checkbox-blank-${influencer.id}`} css={styles.blankCheckBoxCell} />
      ),
      <TableCell.Link
        key={`influencer-${influencer.id}`}
        css={{ maxWidth: '200px', minWidth: '100px', display: 'flex', alignItems: 'center' }}
        {...generateProfileLink(influencer.id)}
      >
        <InfluencerAvatar size="32px" src={influencer.avatar} css={{ borderRadius: '50%' }} asChild />
        <TableCell.Text value={influencer.name} css={{ marginLeft: '8px' }} />
      </TableCell.Link>,
      ...renderStatCells(
        rowData,
        (jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN && isEditMode) || false
      )
    ];
  };

  const renderSocialAccountRow = (rowData: RowData): RowProps => {
    const { influencer, socialAccount } = rowData;
    const account = socialAccount as RowSocialAccountData;
    const checked = listSelectedIds.includes(account.id);

    return {
      styles: css({
        height: 'auto',
        borderTop: 'none',
        ...(account.lastIndex
          ? {
              '> td': {
                paddingBottom: '16px'
              }
            }
          : {})
      }),
      cells: [
        isEditMode ? (
          <CheckBox
            checked={checked}
            key={`checkbox-${socialAccount?.id}`}
            onClick={() => onRowSelect(influencer.id, account.id)}
            tooltipProps={{ help: t(checked ? 'Unselect' : 'Select') }}
          />
        ) : (
          <div key={`checkbox-blank-${socialAccount?.id}`} css={styles.blankCheckBoxCell} />
        ),
        <div key={`social-account-${socialAccount?.id}`} css={styles.influencerWrapper}>
          <SocialIcon size="24px" type={account.socialMedia} />
          <Anchor
            label={account.name}
            css={{ fontSize: '12px', marginLeft: '16px' }}
            {...generateProfileLink(influencer.id, {
              filter: {
                id: account.id,
                sm: account.socialMedia
              }
            })}
          />
          {isViewMode ? (
            <SpreadButton
              options={[
                {
                  icon: 'delete',
                  label: 'Remove',
                  onClick: () =>
                    handleRemoveSmAccountFromProposal({
                      influencerId: influencer.id,
                      socialAccountId: account.id,
                      socialAccountMedia: account.socialMedia
                    })
                }
              ]}
              css={styles.spreadButton}
            />
          ) : null}
        </div>,
        ...renderStatCells(rowData, isEditMode)
      ]
    };
  };

  const columns: ColumnProps[] = [
    {
      sticky: { active: true },
      styles: { width: '48px', minWidth: '48px' },
      title: <CheckBox tooltipProps={{ help: t('Select all') }} {...selectButtonProps} />
    },
    {
      title: t('HeaderColumn.Influencers'),
      sticky: { active: true, left: '48px', hasBorderRight: true },
      styles: { minWidth: '268px', width: '380px', paddingLeft: '0 !important' }
    },
    { title: t('HeaderColumn.Followers'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Avg ER by views'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Avg ER by followers'), styles: { textAlign: 'right' } },
    {
      title: t('HeaderColumn.Budget'),
      styles: { textAlign: 'right' }
    },
    {
      title: t('HeaderColumn.Influencer Cost'),
      styles: { textAlign: 'right' },
      hidden: !isAdminStaffTalentAgencyPartner
    },
    {
      title: t('HeaderColumn.Profit'),
      styles: { textAlign: 'right' },
      hidden: !isAdminStaffTalentAgencyPartner
    },
    {
      title: t('HeaderColumn.Engagement Proposed'),
      styles: { textAlign: 'right' },
      hidden: !isAdminStaffTalentAgencyPartner
    },
    {
      title: t('HeaderColumn.Engagement Posted'),
      styles: { textAlign: 'right' },
      hidden: !isAdminStaffTalentAgencyPartner
    },
    { title: t('HeaderColumn.List Added Date') }
  ];

  const rows: RowProps[] = rowsData.map((rowData) =>
    rowData.socialAccount ? renderSocialAccountRow(rowData) : renderInfluencerRow(rowData)
  );

  return {
    rows,
    columns,
    hasRowChange,
    rowValues: rowsData,
    resetEditingRowsData,
    setRowValues: setRowsData
  };
};

const styles = {
  spreadButton: css({ marginLeft: 'auto', img: { width: 'auto', height: 'auto' } }),
  blankCheckBoxCell: css({ flex: 'none', width: '24px', height: 'auto', boxSizing: 'border-box' }),
  influencerWrapper: css({
    height: '40px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    boxSizing: 'border-box',

    '&::before': {
      top: '0',
      left: '0',
      width: '1px',
      content: '""',
      height: 'inherit',
      margin: '0 32px 0 16px',
      backgroundColor: '#dee5ef'
    }
  })
};
