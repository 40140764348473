import { useMemo } from 'react';
import { useUserRoles } from '@/auth';
import { Option, SupportedSocialMediaType } from '@/shared/types';
import { getCampaignsFromUrlString, getSocialMediaText } from '@/shared/utils';
import { CampaignIdentifierType, CampaignPostSocialType, CampaignType, useAllCampaignsForFilterQuery } from '@/graphql';
import { useQueryHelper } from './useQueryHelper';
import { useAllCategoryOptions } from './useAllCategoryOptions';
import { useAllAvailableCountryOptions } from './useAllAvailableCountryOptions';
import { useCampaignTypesOptionsWithPermissions } from './useCampaignTypesOptionsWithPermissions';

interface ReportFilterOptionsProps {
  campaigns: string[];
  campaignTypes?: Array<CampaignType | CampaignIdentifierType>;
}
const SOCIAL_MEDIA_OPTIONS = [
  SupportedSocialMediaType.FACEBOOK,
  SupportedSocialMediaType.INSTAGRAM,
  SupportedSocialMediaType.INSTAGRAM_STORY,
  SupportedSocialMediaType.TWITTER,
  SupportedSocialMediaType.YOUTUBE,
  SupportedSocialMediaType.TIKTOK,
  SupportedSocialMediaType.DOUYIN,
  SupportedSocialMediaType.XHS,
  SupportedSocialMediaType.THREADS
].map((campaignType) => ({ label: getSocialMediaText(campaignType), value: campaignType }));

export const useReportFilterOptions = ({ campaigns, campaignTypes }: ReportFilterOptionsProps) => {
  const { isAdminStaff } = useUserRoles();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const { allCategoryOptions } = useAllCategoryOptions();
  const { data, loading } = useAllCampaignsForFilterQuery({
    variables: {
      campaignTypes: campaignTypes as CampaignType[],
      campaigns: getCampaignsFromUrlString(campaigns)
    },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const allCampaignsOptions =
    useMemo(
      () =>
        data?.allCampaignsForFilter.campaigns.reduce<Option[]>((results, campaign) => {
          if (campaign) {
            results.push({ label: campaign.title, value: `${campaign.type.valueOf().toLowerCase()}/${campaign.id}` });

            return results;
          }

          return results;
        }, []),
      [data]
    ) || [];

  const allCampaignsSelectedOptions =
    useMemo(
      () =>
        data?.allCampaignsForFilter.includedCampaigns?.reduce<Option[]>((results, campaign) => {
          if (campaign) {
            results.push({ label: campaign.title, value: `${campaign.type.valueOf().toLowerCase()}/${campaign.id}` });

            return results;
          }

          return results;
        }, []),
      [data]
    ) || [];

  const allCampaignTypes = [
    { value: CampaignType.ENGAGEMENT, label: t('Engagement') },
    { value: CampaignType.MARKETPLACE, label: t('Marketplace') },
    ...(isAdminStaff ? [{ value: CampaignType.AUTO_MANAGED, label: t('eCommerce Affiliate') }] : [])
  ];
  const allCampaignIdentifierTypes = [
    { value: CampaignIdentifierType.ENGAGEMENT, label: t('Engagement') },
    { value: CampaignIdentifierType.MARKETPLACE, label: t('Marketplace') }
  ];
  const allowedCampaignsTypes = useCampaignTypesOptionsWithPermissions(allCampaignTypes);
  const socialMediaPostOptions = [
    { label: t('Facebook'), value: CampaignPostSocialType.FACEBOOK },
    { label: t('Facebook Page'), value: CampaignPostSocialType.FACEBOOK_PAGE },
    { label: t('Instagram'), value: CampaignPostSocialType.INSTAGRAM },
    { label: t('X(Twitter)'), value: CampaignPostSocialType.TWITTER },
    { label: t('YouTube'), value: CampaignPostSocialType.YOUTUBE },
    { label: t('TikTok'), value: CampaignPostSocialType.TIKTOK },
    { label: t('Douyin'), value: CampaignPostSocialType.DOUYIN },
    { label: t('Xiaohongshu'), value: CampaignPostSocialType.XHS },
    { label: t('Threads'), value: CampaignPostSocialType.THREADS }
  ];

  return {
    loading,
    isAdminStaff,
    allCampaignsOptions,
    allCampaignCategoryOptions: allCategoryOptions,
    allCampaignIdentifierTypes,
    allCampaignsSelectedOptions,
    allCampaignTypes: allowedCampaignsTypes,
    allCountryOptions: allAvailableCountryOptions,
    allSocialMediaOptions: SOCIAL_MEDIA_OPTIONS,
    allSocialMediaPostOptions: socialMediaPostOptions
  };
};
