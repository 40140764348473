import { zodValidator } from '@tanstack/zod-adapter';
import { SignIn, SignUp, SignUpSuccess } from '@/pages/Auth';
import { LogoSchema } from '@/shared/molecules';
import { PartialRouter } from '../../types';

export const SignInUpRouter = {
  '/_public-routes/sign-in': [
    SignIn,
    {
      validateSearch: zodValidator(LogoSchema)
    }
  ],
  '/_public-routes/sign-up/': [SignUp, {}],
  '/_public-routes/sign-up/success': [SignUpSuccess, {}]
} as const satisfies PartialRouter;
