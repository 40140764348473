import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter, getSocialMediaTextLink, getSocialMediaBackgroundColor } from '@/shared/utils';
import { checkSocialAccountType } from '../utils';
import { useInfluencerProfileSearch } from '../hooks';
import { InfluencerSelectedAccountInfo } from '../types';
import { DownloadInfluencerStats } from './DownloadInfluencerStats';
import { InfluencerAccountsSelector } from './InfluencerAccountsSelector';
import { InfluencerProfileUrlDescription } from './InfluencerProfileUrlDescription';

export interface InfluencerProfileStatsOverviewProps {
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
  allAvailableAccounts: InfluencerSelectedAccountInfo[];
}

export const InfluencerProfileStatsOverview = ({
  influencerId,
  socialAccount,
  allAvailableAccounts
}: InfluencerProfileStatsOverviewProps) => {
  const { t, i18n } = useTranslation();
  const { filter } = useInfluencerProfileSearch();

  const { name, type } = socialAccount;

  return (
    <div css={{ boxShadow: '0px 2px 1px rgba(110, 124, 137, 0.05), 0px 0px 2px rgba(110, 124, 137, 0.25);' }}>
      <div css={[styles.headerWrapper, { background: getSocialMediaBackgroundColor(type) }]}>
        <h2
          css={{
            marginRight: 'auto',
            color: checkSocialAccountType(type) ? '#fff' : '#27313B',
            fontSize: '18px'
          }}
        >
          <span css={{ fontWeight: 600 }}>{getSocialMediaTextLink(type) || name}</span>
          &nbsp;
          {t('HeaderColumn.Stats Overview')}
        </h2>

        {filter?.lastUpdated ? (
          <div css={{ color: THEME.text.colors.white, marginRight: '20px', fontSize: '13px' }}>
            {t('Last Update')} : {localizedDateFormatter(filter.lastUpdated, 'PPP', i18n.language as AppLanguage)}
          </div>
        ) : null}

        <InfluencerAccountsSelector socialAccount={socialAccount} allAvailableAccounts={allAvailableAccounts} />
        <DownloadInfluencerStats influencerId={influencerId} socialAccount={socialAccount} />
      </div>

      <InfluencerProfileUrlDescription socialAccount={socialAccount} />
    </div>
  );
};
const styles = {
  headerWrapper: css({
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '0 8px 0 16px',
    borderRadius: '3px 3px 0 0',
    justifyContent: 'space-between',
    boxShadow: THEME.shadows.boxShadow
  })
};
