import { useRouter } from '@tanstack/react-router';
import { POPUP_PARAM_KEY } from '@/shared/constants';
import { useQueryHelper } from './useQueryHelper';
import { useParamsSearch } from './useParamsSearch';
import { useLastLocation } from './useLastLocation';

export const usePostDetailsParam = (urlPostIdKey = POPUP_PARAM_KEY) => {
  const router = useRouter();
  const { lastLocation } = useLastLocation();
  const { navigate } = useQueryHelper();
  const { setPostId, urlPostId } = useParamsSearch(urlPostIdKey);

  const setPostDetailsParam = (postId?: number | string | null) => {
    // Ideally: When post details is opening
    // 1. If user click browser back button we want to keep user stays in current page and close the post details
    // 2. User still keep next/back another post details and click outside modal or click close button, we will close post details. Then if user click browser back button, we will go back to previous page as usual instead of backing to the post details url which just closed
    if (!postId && !!urlPostId) {
      if (lastLocation?.pathname === router.latestLocation.pathname) {
        // We will go back if post details was opened from same url
        router.history.back();
      } else {
        // If user get shared link, lastLocation = null or lastLocation.key === 'default' so go back will incorrect, we need to remove `paramKey` only
        // setUrlParams({ [paramKey]: '' }, true, { replace: true, preventScrollReset: true });
        navigate({ [urlPostIdKey]: '', resetScroll: false });
      }
    } else {
      setPostId(postId?.toString() || '', {
        resetScroll: false,
        replace: !!urlPostId
      });
    }
  };

  const onClosePostDetails = () => {
    setPostId('');
  };

  return { urlPostId, setPostDetailsParam, onClosePostDetails };
};
