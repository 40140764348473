import { css } from '@emotion/react';
import { CheckBox } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { AnalyticsOverviewLegendType } from './types';

export interface AnalyticsOverviewLegendProps<T extends string> {
  className?: string;
  selectedOptions: T[];
  options: AnalyticsOverviewLegendType<T>[];
  onSelectOption?: (option: AnalyticsOverviewLegendType<T>) => void;
}
export const AnalyticsOverviewLegend = <T extends string>({
  options,
  className,
  onSelectOption,
  selectedOptions
}: AnalyticsOverviewLegendProps<T>) => (
  <div css={{ display: 'flex', flexWrap: 'wrap', padding: '16px 12px' }} className={className}>
    {options.map((option, index) => {
      const { label, value, color } = option;
      const isChecked = !!value && selectedOptions.includes(value);

      return (
        <div css={styles.item} key={index} onClick={() => onSelectOption?.(option)}>
          <CheckBox checked={isChecked} />
          <p css={styles.label}>{label}</p>
          {color ? <div style={{ width: '8px', height: '8px', borderRadius: '2px', background: color }} /> : null}
        </div>
      );
    })}
  </div>
);
const styles = {
  item: css({
    padding: '4px',
    display: 'flex',
    cursor: 'pointer',
    marginRight: '8px',
    marginBottom: '8px',
    borderRadius: '3px',
    alignItems: 'center',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  label: css({
    lineHeight: 1.17,
    padding: '0 4px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: THEME.text.colors.black.lv1
  })
};
