import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { ButtonDropdown, Table } from '@/shared/atoms';
import { AllCampaignsReportSummary, useAllCampaignsReportQuery } from '@/graphql';
import { convertOrderToORDER, getOffset, getValuableVariables } from '@/shared/utils';
import { useReportFilter } from '../ReportFilter';
import { getReportQueryVariables } from './utils';
import { useDownloadReport, useReportTable } from './hooks';

export interface ReportTableProps {
  summaryData?: AllCampaignsReportSummary;
}

export const ReportTable = ({ summaryData }: ReportTableProps) => {
  const { t } = useTranslation();
  const { filter, page, limit, sort } = useReportFilter();
  const { data, previousData, loading } = useAllCampaignsReportQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      ...getReportQueryVariables(filter)
    })
  });
  const { rows, columns, selectedColumns } = useReportTable({
    summaryData,
    data: (loading ? previousData?.allCampaignsReport : data?.allCampaignsReport) || []
  });
  const { downloading, downloadOptions, handleDownloadReport } = useDownloadReport({ columns, selectedColumns });

  return (
    <>
      <div css={{ padding: '8px 16px', backgroundColor: THEME.background.colors.white, borderTop: THEME.border.base }}>
        <ButtonDropdown
          variant="white"
          options={downloadOptions}
          title={t('Button.Download')}
          prefixIcon={{ icon: 'download', size: '14px' }}
          loading={{ showIcon: true, status: downloading }}
          onSelectOption={({ value }) => handleDownloadReport(value)}
          css={{ width: 'max-content', minWidth: '133px', marginLeft: 'auto', textTransform: 'uppercase' }}
        />
      </div>

      <Table
        maxHeight="820px"
        loading={loading}
        data={{ rows, columns, rowHeight: '73px' }}
        noTopBorderRadius
        stickyHeader
        hasBorder
      />
    </>
  );
};
