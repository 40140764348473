import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramHashtagPostDetails.graphql';
import { Query, QueryinstagramHashtagPostDetailsArgs } from '../../__generated__/globalTypes';

export const useInstagramHashtagPostDetailsQuery = (
  options?: QueryHookOptions<Query, QueryinstagramHashtagPostDetailsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramHashtagPostDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramHashtagPostDetailsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramHashtagPostDetailsArgs>
): QueryResult<Query, QueryinstagramHashtagPostDetailsArgs> & {
  getInstagramHashtagPostDetails: LazyQueryExecFunction<Query, QueryinstagramHashtagPostDetailsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramHashtagPostDetails, result] = useLazyQuery<Query, QueryinstagramHashtagPostDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInstagramHashtagPostDetails, ...result };
};

export const useInstagramHashtagPostDetailsPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramHashtagPostDetails = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramHashtagPostDetailsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramHashtagPostDetailsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramHashtagPostDetails };
};
