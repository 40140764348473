import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ReportMarketplacePostStatus.graphql';
import { Mutation, MutationreportMarketplacePostStatusArgs } from '../../__generated__/globalTypes';

export const useReportMarketplacePostStatusMutation = (
  options?: MutationHookOptions<Mutation, MutationreportMarketplacePostStatusArgs>
) => {
  const [callReportMarketplacePostStatus, result] = useMutation<Mutation, MutationreportMarketplacePostStatusArgs>(
    MUTATION,
    options
  );

  return { callReportMarketplacePostStatus, ...result };
};

export type ReportMarketplacePostStatusMutationFunction = Mutation['reportMarketplacePostStatus'];
