import { useState } from 'react';
import { getValuableVariables } from '@/shared/utils';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { FacebookPageInfluencerSearchResultV2, useAllFacebookPageInfluencersV2Query } from '@/graphql';
import { formatInfluencerProfile } from '../utils';
import { useGetInfluencersQueryVariables } from '../../hooks';
import { FacebookPageInfluencersSearchSchemaType } from '../schemaTypes';

type Props = Pick<
  ParamsSearchReturnType<FacebookPageInfluencersSearchSchemaType>,
  'page' | 'limit' | 'filter' | 'sort'
>;

export const useFacebookPageInfluencersList = ({ page, sort, limit, filter }: Props) => {
  // Using data directly from query can be empty if skipSearch is true
  // But we still want to keep old data in that case, so we store it in state
  const [data, setData] = useState<FacebookPageInfluencerSearchResultV2>();
  const { skipSearch, variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { loading } = useAllFacebookPageInfluencersV2Query({
    skip: skipSearch || !filter,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: getValuableVariables({ ...variables, orderBy: sort }),
    onCompleted: ({ allFacebookPageInfluencersV2 }) => {
      setData(allFacebookPageInfluencersV2);
    }
  });

  return {
    loading,
    totalRecords: data?.totalNumber || 0,
    listRecords: data?.facebookPages?.map(formatInfluencerProfile) || []
  };
};
