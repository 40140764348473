import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { ListEmpty, RenderDataWithFallback } from '@/shared/atoms';
import { CampaignChart, ListWithPagination } from '@/shared/molecules';
import { ReportTable, ReportFilter, ReportSummary, ReportOnboarding } from '@/shared/organisms';
import { useInitialReport } from './useInitialReport';

export const Report = () => {
  useUpdateDocumentTitle({ title: 'appHeader.Campaign Reports', appHeader: 'appHeader.Campaign Reports' });
  const { t } = useTranslation();
  const { isAdvertiser } = useUserRoles();
  const { totalRecords, loading, isAllTime, chartData, isOnboarding, summaryData } = useInitialReport();

  return (
    <ListWithPagination
      hasNoData={isOnboarding}
      loading={isAdvertiser && loading}
      noDataNode={<ReportOnboarding />}
      pagination={{ totalRecords }}
    >
      <ReportFilter />

      <RenderDataWithFallback
        loading={loading}
        hasNoData={!totalRecords}
        noDataNode={
          <ListEmpty
            title={t('General.No report data has been found')}
            description={t('General.Please try a different query or wait the data is reflected')}
          />
        }
      >
        <ReportSummary data={summaryData} />
        <CampaignChart data={chartData} isAllTime={isAllTime} />
        <ReportTable summaryData={summaryData} />
      </RenderDataWithFallback>
    </ListWithPagination>
  );
};
