import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField, TextFieldProps } from '@/shared/molecules';

import { MarketplaceFormValues, MarketplaceFormKeys } from '../types';

export const CostPerCommentField = (props: Omit<TextFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<MarketplaceFormValues>();
  const currency = watch(MarketplaceFormKeys.currency);

  return (
    <TextField
      placeholder="5.5"
      unit={t(currency)}
      help={t('Tooltip.Cost Comment Help')}
      title={t('TextForm.Cost per Comment')}
      name={MarketplaceFormKeys.costPerComment}
      {...props}
    />
  );
};
