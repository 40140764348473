import { z } from 'zod';
import { AutoManagedCampaignStatus } from '@/graphql';
import { BaseValidations } from '@/shared/validations';

export const AutoManagedCampaignFormSchema = z.object({
  title: z.string(),
  category: z.string(),
  period: z.object({
    startDate: z.string(),
    endDate: z.string()
  }),
  sellerName: z.string(),
  countryName: z.string(),
  campaignUrl: z.string(),
  serviceInfo: z.string(),
  marginRate: z.number().or(z.string()),
  campaignThumbnailUrl: z.string(),
  commissionRate: z.number().nullable(),
  status: z.nativeEnum(AutoManagedCampaignStatus),
  influencerCommissionRate: z.number().nullable(),
  requirement: BaseValidations.RequirementSchema
});
