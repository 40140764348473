import {
  Active,
  useSensor,
  DndContext,
  useSensors,
  PointerSensor,
  closestCenter,
  KeyboardSensor,
  DndContextProps,
  UniqueIdentifier
} from '@dnd-kit/core';
import { SortableContext, SortableContextProps, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import React, { useMemo, useState, ReactNode } from 'react';

import { THEME } from '@/shared/constants';

import { SortableOverlay } from './SortableOverlay';

interface SortItem {
  id: UniqueIdentifier;
}

export interface SortableListProps<T extends SortItem> {
  items: T[];
  onChange: (items: T[]) => void;
  dndContextProps?: DndContextProps;
  renderItem: (item: T, index: number) => ReactNode;
  customOverlayItem?: (item: T, index: number) => ReactNode;
  sortableContextProps?: Omit<SortableContextProps, 'items' | 'children'>;
}

export const SortableList = <T extends SortItem>({
  items,
  onChange,
  renderItem,
  dndContextProps,
  customOverlayItem,
  sortableContextProps
}: SortableListProps<T>) => {
  const [activatingItem, setActivatingItem] = useState<Active | null>(null);
  // Cached activating item while dragging
  const { activeItem, activeItemIndex } = useMemo(() => {
    const index = items.findIndex((item) => item.id === activatingItem?.id);

    return { activeItem: items.at(index), activeItemIndex: index };
  }, [activatingItem, items]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragCancel={() => setActivatingItem(null)}
      onDragStart={({ active }) => setActivatingItem(active)}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActivatingItem(null);
      }}
      {...dndContextProps}
    >
      <SortableContext {...sortableContextProps} items={items}>
        {items.map((item, index) => (
          <React.Fragment key={item.id}>{renderItem(item, index)}</React.Fragment>
        ))}
      </SortableContext>

      <SortableOverlay>
        {activeItem ? (
          <div css={{ boxShadow: THEME.shadows.boxShadow }}>
            {customOverlayItem?.(activeItem, activeItemIndex) || renderItem(activeItem, activeItemIndex)}
          </div>
        ) : null}
      </SortableOverlay>
    </DndContext>
  );
};
