import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTranslateOptions } from '@/shared/hooks';
import { getNumberValidFilter } from '@/shared/utils';
import { SelectorField, TextSearchField, BasicFilterWrapper } from '@/shared/molecules';
import { MarketplaceDetail, MarketplaceCampaignStatus, MarketplaceCampaignParticipateStatus } from '@/graphql';
import { AdvancedFilter } from './AdvancedFilter';
import { MarketplaceInfluencersFilterFormKeys, MarketplaceInfluencersFilterFormValues } from './schemaTypes';

export interface BasicFilterProps {
  onSubmit?: () => void;
  marketplace: MarketplaceDetail;
}

export const BasicFilter = ({ onSubmit, marketplace }: BasicFilterProps) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<MarketplaceInfluencersFilterFormValues>();

  const { status, isPostTracking, isSelectionCampaign } = marketplace;

  // https://anymindgroup.slack.com/archives/C02C5J5B7KL/p1726220512394789?thread_ts=1725954179.466569&cid=C02C5J5B7KL
  const participateStatusOptions = useTranslateOptions([
    ...(status !== MarketplaceCampaignStatus.REVIEWING
      ? [
          { value: MarketplaceCampaignParticipateStatus.JOINED, label: 'Joined' },
          ...(isPostTracking ? [{ value: MarketplaceCampaignParticipateStatus.WAITING, label: 'Waiting' }] : []),
          ...(isSelectionCampaign
            ? [
                { value: MarketplaceCampaignParticipateStatus.APPLIED, label: 'Applied' },
                { value: MarketplaceCampaignParticipateStatus.REJECTED, label: 'Rejected' }
              ]
            : [])
        ]
      : []),
    { value: MarketplaceCampaignParticipateStatus.AVAILABLE, label: 'Available' },
    { value: MarketplaceCampaignParticipateStatus.UNAVAILABLE, label: 'Unavailable' }
  ]);

  const values = getValues();
  const basicFilterKeys: MarketplaceInfluencersFilterFormKeys[] = ['keyword', 'participateStatuses'];
  const numOfValidFilter = getNumberValidFilter(values, basicFilterKeys);

  return (
    <BasicFilterWrapper>
      <TextSearchField<MarketplaceInfluencersFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.InfluencerFilter')}
      />

      <SelectorField<MarketplaceInfluencersFilterFormKeys>
        onSubmit={onSubmit}
        name="participateStatuses"
        css={{ maxWidth: '188px' }}
        options={participateStatusOptions}
        placeholder={t<string>('Selector.Status')}
        multiple
      />

      <AdvancedFilter count={numOfValidFilter} onSubmit={onSubmit} marketplace={marketplace} />
    </BasicFilterWrapper>
  );
};
