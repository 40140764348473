import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthTalentSignInYouTube.graphql';
import { Mutation, MutationsocialAuthTalentSignInYouTubeArgs } from '../../__generated__/globalTypes';

export const useSocialAuthTalentSignInYouTubeMutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthTalentSignInYouTubeArgs>
) => {
  const [callSocialAuthTalentSignInYouTube, result] = useMutation<Mutation, MutationsocialAuthTalentSignInYouTubeArgs>(
    MUTATION,
    options
  );

  return { callSocialAuthTalentSignInYouTube, ...result };
};

export type SocialAuthTalentSignInYouTubeMutationFunction = Mutation['socialAuthTalentSignInYouTube'];
