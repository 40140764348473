import { useTranslation } from 'react-i18next';
import { GENDER_OPTIONS } from '@/shared/constants';
import { useTranslateOptions } from '@/shared/hooks';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../types';

export const GenderField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const genderOptions = useTranslateOptions(GENDER_OPTIONS);

  return (
    <SelectorField<InfluencersFilterFormKeys>
      name="genders"
      options={genderOptions}
      placeholder={t<string>('Selector.Gender')}
      multiple
      {...props}
    />
  );
};
