import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteYoutubeCompareAccountAnalytics.graphql';
import { Mutation, MutationdeleteYoutubeCompareAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useDeleteYoutubeCompareAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteYoutubeCompareAccountAnalyticsArgs>
) => {
  const [callDeleteYoutubeCompareAccountAnalytics, result] = useMutation<
    Mutation,
    MutationdeleteYoutubeCompareAccountAnalyticsArgs
  >(MUTATION, options);

  return { callDeleteYoutubeCompareAccountAnalytics, ...result };
};

export type DeleteYoutubeCompareAccountAnalyticsMutationFunction = Mutation['deleteYoutubeCompareAccountAnalytics'];
