import { useUpdateDocumentTitle } from '@/shared/hooks';
import { PaymentPlanType, usePricingTableQuery } from '@/graphql';
import {
  TikTokBillingPlans,
  TikTokBillingHistory,
  TikTokBillingInformation,
  useTikTokAdvertiserSummaryData
} from '@/shared/organisms';

export const TikTokBilling = () => {
  useUpdateDocumentTitle({ title: 'appHeader.Billing', appHeader: 'appHeader.Billing' });
  const { summaryData } = useTikTokAdvertiserSummaryData();
  const { data } = usePricingTableQuery({
    variables: {
      planType: PaymentPlanType.TIKTOK_ADS
    }
  });

  return (
    <>
      <div css={{ display: 'flex', gap: '24px' }}>
        <TikTokBillingPlans plans={data?.pricingTable?.plans || []} summaryData={summaryData} />
        <TikTokBillingInformation />
      </div>
      <TikTokBillingHistory />
    </>
  );
};
