import { useState } from 'react';
import { getValuableVariables } from '@/shared/utils';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { AllInfluencerV4, useAllInfluencersV4Query } from '@/graphql';
import { formatInfluencerProfile } from '../utils';
import { AllInfluencersSearchSchemaType } from '../schemaTypes';
import { useGetInfluencersQueryVariables } from '../../hooks';

type Props = Pick<ParamsSearchReturnType<AllInfluencersSearchSchemaType>, 'page' | 'limit' | 'filter' | 'sort'>;

export const useAllInfluencersList = ({ page, sort, limit, filter }: Props) => {
  // Using data directly from query can be empty if skipSearch is true
  // But we still want to keep old data in that case, so we store it in state
  const [data, setData] = useState<AllInfluencerV4>();
  const { skipSearch, variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { loading } = useAllInfluencersV4Query({
    skip: skipSearch || !filter,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: getValuableVariables({
      ...variables,
      orderBy: sort,
      minAvgPostPrice: variables.minPostPrice,
      maxAvgPostPrice: variables.maxPostPrice
    }),
    onCompleted: ({ allInfluencersV4 }) => {
      setData(allInfluencersV4);
    }
  });

  return {
    loading,
    totalRecords: data?.totalCount || 0,
    listRecords: data?.influencers?.map(formatInfluencerProfile) || []
  };
};
