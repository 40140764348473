import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { eggOnboardingImage } from '@/shared/assets';
import { FadeInImage, ButtonLink, Anchor } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export const EngagementOnboarding = () => {
  const { t, i18n } = useTranslation();

  return (
    <div css={styles.wrapper}>
      <FadeInImage src={eggOnboardingImage} css={{ width: '250px', aspectRatio: 'auto 242 / 160' }} />
      <h3 css={styles.heading}>{t('Create Casting Campaigns')}</h3>
      <p css={styles.description}>
        {t(
          'Our team of experts provides you with support for in-market influencer activations, including campaign executions'
        )}
      </p>
      <ButtonLink title={t('Button.Create campaign')} to="/engagement/add" variant="blue" css={styles.linkButton} />
      <div css={styles.helpLink}>
        <span>{t('Learn more about')}</span>&nbsp;
        <Anchor
          variant="blue"
          target="_blank"
          label={t('Casting campaign')}
          href={
            i18n.language === 'ja'
              ? 'https://anymind-group.gitbook.io/anytag-3/campaign/engagement'
              : 'https://anymind-group.gitbook.io/anytag-3/v/english/campaign/engagement'
          }
        />
      </div>
    </div>
  );
};

const styles = {
  linkButton: css({ padding: '0 32px', marginTop: '40px', width: 'max-content' }),
  helpLink: css({ fontWeight: 400, display: 'flex', fontSize: '14px', marginTop: '24px' }),
  heading: css({ fontWeight: 600, fontSize: '16px', lineHeight: '130%', marginTop: '24px' }),
  wrapper: css({
    display: 'flex',
    padding: '32px',
    placeItems: 'center',
    flexDirection: 'column',
    backgroundColor: THEME.background.colors.white
  }),
  description: css({
    fontWeight: 400,
    width: '460px',
    fontSize: '14px',
    marginTop: '16px',
    lineHeight: '120%',
    textAlign: 'center'
  })
};
