import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Anchor, Button } from '@/shared/atoms';
import { Maybe, PricingTable, TiktokAdvertiserSummaryData } from '@/graphql';
import { formatPrice } from '@/shared/utils';
import { CURRENCY_SYMBOLS, THEME, TIKTOK_HELP_LINK } from '@/shared/constants';
import { UpdatePlanModalWithButton } from '../../TikTokInApp';

export interface PricingPlanProps {
  plans: PricingTable['plans'];
  summaryData?: Maybe<TiktokAdvertiserSummaryData>;
  className?: string;
}
export const PricingPlan = ({ plans, summaryData, className }: PricingPlanProps) => {
  const { t } = useTranslation();
  const selectedPlan = plans.find((p) => p.isCurrentPlan);

  return (
    <div css={styles.planGridStyle} className={className}>
      <div>
        <h3 css={{ fontSize: '18px', fontWeight: 600, lineHeight: '36px', marginTop: '24px' }}>
          {t('Please Select your Plan')}
        </h3>
        <p css={{ fontSize: '12px', marginBottom: '30px' }}>{t('check features as below')}</p>
        <Button
          as={Anchor}
          href={TIKTOK_HELP_LINK.learnMoreAboutThisService}
          target="_blank"
          variant="white"
          title={t('Learn more about the service')}
          suffixIcon={{ icon: 'external-link', size: 12 }}
          css={{ padding: '0 12px' }}
        />
      </div>
      {plans.map((plan) => {
        const isUpcomingPlan = plan.name === summaryData?.upcomingSubscriptionPlan;

        return (
          <div key={plan.id} css={styles.planCardStyle}>
            <h3 css={styles.planNameStyle}>{t(`Plan.${plan.name}`)}</h3>
            <p css={styles.planPriceStyle}>
              <span>
                {CURRENCY_SYMBOLS[plan.currency]}
                {formatPrice(plan.price, null, 0)}
              </span>
              <span
                css={{
                  fontSize: '12px',
                  fontWeight: 'normal'
                }}
              >
                /{plan.priceType.toLowerCase()}
              </span>
            </p>
            <UpdatePlanModalWithButton
              plan={plan}
              selectedPlan={selectedPlan}
              isUpcomingPlan={isUpcomingPlan}
              title={t(selectedPlan ? 'Button.Change Plan' : 'Button.Start')}
            />
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  planGridStyle: css({
    display: 'grid',
    gridTemplateColumns: '1fr repeat(3, minmax(0, 208px))',
    padding: '16px',
    borderBottom: THEME.border.base
  }),
  planCardStyle: css({
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'column'
  }),
  planNameStyle: css({
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '16px'
  }),
  planPriceStyle: css({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '32px',
    fontWeight: 600,
    marginBottom: '16px'
  })
};
