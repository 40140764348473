import { useCallback, useMemo, useRef } from 'react';
import { GroupBase, MultiValue, MenuListProps, SelectInstance } from 'react-select';
import { Option } from '@/shared/types';
import { useToggleState } from '@/shared/hooks';
import { CommonSelectProps } from '../types';
import { SelectComponents } from '../shared';

export type MultipleSelectProps<V extends string, O extends Option<V, any>> = Omit<
  CommonSelectProps<V, O, true>,
  'multiple'
>;

export const MultipleSelect = <V extends string, O extends Option<V, any>>({
  value,
  styles,
  disabled,
  onChange,
  clearable,
  menuWidth,
  menuHeight,
  options = [],
  selectAllProps,
  searchable = true,
  hasSelectAll = true,
  closeMenuOnSelect = false,
  hideSelectedOptions = false,
  ...restProps
}: MultipleSelectProps<V, O>) => {
  const menuState = useToggleState();
  const ref = useRef<SelectInstance<O, true, GroupBase<O>> | null>(null);
  const customValue = useMemo(() => options.filter((option) => value?.includes(option.value)), [value, options]);

  const handleChange = (selectedOptions: MultiValue<O>) => {
    onChange?.(selectedOptions.map((option) => (option.value || '') as V));
  };

  // Issue: https://github.com/JedWatson/react-select/issues/1634
  const MenuList = useCallback(
    (props: MenuListProps<any, any, any>) => (
      <SelectComponents.MenuListMultiple
        {...props}
        menuWidth={menuWidth}
        menuHeight={menuHeight}
        onChange={handleChange}
        hasSelectAll={hasSelectAll}
        selectAllProps={selectAllProps}
      />
    ),
    [hasSelectAll, menuWidth, menuHeight, selectAllProps]
  );

  return (
    <SelectComponents.BaseSelect<V, O, true>
      {...restProps}
      ref={ref}
      options={options}
      disabled={disabled}
      value={customValue}
      menuWidth={menuWidth}
      menuHeight={menuHeight}
      onChange={handleChange}
      searchable={searchable}
      hasSelectAll={hasSelectAll}
      onMenuOpen={menuState.open}
      menuIsOpen={menuState.status}
      onMenuClose={menuState.close}
      closeMenuOnSelect={closeMenuOnSelect}
      clearable={clearable ?? !hasSelectAll} // Always has 1 option to clear all, except both props set false
      hideSelectedOptions={hideSelectedOptions}
      components={{ MenuList, MultiValue: SelectComponents.MultiValue }}
      styles={{
        ...styles,
        option: (_, optProps) => ({ padding: '8px', ...styles?.option?.({}, optProps) })
      }}
      multiple
    />
  );
};
