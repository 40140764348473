import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEngagementPermissions } from '@/auth';
import { SocialAccountType, SocialPostType } from '@/graphql';
import { useDeepCompareEffect } from '@/shared/hooks';
import { InfluencerPostProfile } from '@/shared/organisms';
import { getEngagementProposalAddRemoveButtonDisableState } from '../utils';
import { useDefaultInfluencerSearchProps } from './useDefaultInfluencerSearchProps';
import { useToggleSelectedSocialInfluencers } from './useToggleSelectedSocialInfluencers';

export const useInfluencerPostsSelectButtonsProps = () => {
  const { t } = useTranslation();
  const { hideToggleInfluencerFromProposalBtn } = useEngagementPermissions();
  const { toggleSocialMediaSelectedInfluencer } = useToggleSelectedSocialInfluencers();
  const { engagementStatus, showUploadCouponModal, selectedInfluencersMapIds } = useDefaultInfluencerSearchProps();
  const [togglingState, setTogglingState] = useState<{ id: number | null; loading: boolean }>({
    id: null,
    loading: false
  });

  const resetLoadingState = () => {
    setTogglingState({ id: null, loading: false });
  };

  useDeepCompareEffect(() => {
    resetLoadingState();
  }, [selectedInfluencersMapIds]);

  const getSortSelectButtonProps = (
    listRecords: InfluencerPostProfile[],
    filterPostSocialMedia: SocialAccountType | SocialPostType
  ) => {
    const allInfluencerIdsInPage = listRecords.map((infl) => Number(infl.id));
    const selectedAccountsInPage = listRecords.reduce<number[]>(
      (rs, infl) =>
        infl.socialAccountId &&
        selectedInfluencersMapIds.get(infl.id)?.get(filterPostSocialMedia)?.includes(infl.socialAccountId) &&
        !rs.includes(infl.socialAccountId)
          ? [...rs, infl.socialAccountId]
          : rs,
      []
    );
    const listRecordsLength = listRecords.length;
    const loading = !togglingState.id && togglingState.loading;
    const numOfSelectedAccountsInPage = selectedAccountsInPage.length;
    const hasAtLeastOneAccountSelectedInPage = numOfSelectedAccountsInPage > 0;
    const hasAllAccountsSelectedInPage = listRecordsLength > 0 && selectedAccountsInPage.length >= listRecordsLength;

    return {
      loading,
      checked: hasAtLeastOneAccountSelectedInPage,
      hasAllSelected: hasAllAccountsSelectedInPage,
      hasAtLeastSelected: hasAtLeastOneAccountSelectedInPage,
      indeterminate: numOfSelectedAccountsInPage !== listRecordsLength,
      onClick: async () => {
        setTogglingState({ id: null, loading: true });
        const { error } = await toggleSocialMediaSelectedInfluencer({
          influencerIds: allInfluencerIdsInPage,
          isChecked: hasAllAccountsSelectedInPage,
          socialAccountMedia: filterPostSocialMedia as SocialAccountType,
          socialAccountIds: listRecords.map((i) => Number(i.socialAccountId))
        });
        if (error) {
          resetLoadingState();
          showUploadCouponModal(error);
        }
      },
      disabled:
        loading ||
        listRecords.length === 0 ||
        getEngagementProposalAddRemoveButtonDisableState(
          hasAllAccountsSelectedInPage ? 'Remove' : 'Add',
          engagementStatus
        ),
      label: hasAtLeastOneAccountSelectedInPage
        ? t('numberInfluencersAddedToProposal', { count: numOfSelectedAccountsInPage })
        : t('addInfluencersToProposal')
    };
  };

  const getListSelectButtonProps = ({ id, name, socialAccountId, socialMedia }: InfluencerPostProfile) => {
    const socialAccountMedia = socialMedia as SocialAccountType;
    const loading = togglingState.id === id && togglingState.loading;
    const isSelected =
      selectedInfluencersMapIds?.get(id)?.get(socialAccountMedia)?.includes(Number(socialAccountId)) || false;

    return {
      loading,
      checked: isSelected,
      disabled:
        loading || getEngagementProposalAddRemoveButtonDisableState(isSelected ? 'Remove' : 'Add', engagementStatus),
      onClick: async () => {
        if (socialAccountId) {
          setTogglingState({ id, loading: true });
          const { error } = await toggleSocialMediaSelectedInfluencer({
            name,
            socialAccountMedia,
            influencerIds: [id],
            isChecked: isSelected,
            socialAccountIds: [socialAccountId]
          });
          if (error) {
            resetLoadingState();
            showUploadCouponModal(error);
          }
        }
      }
    };
  };

  return {
    getSortSelectButtonProps,
    getListSelectButtonProps: !hideToggleInfluencerFromProposalBtn ? getListSelectButtonProps : undefined
  };
};
