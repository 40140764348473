import { css } from '@emotion/react';
import { TagCloud } from 'react-tagcloud';
import { HashtagType } from '@/graphql';

const mainColor = 'rgba(92, 170, 225, 0.85)';
const secondColor = 'rgba(92, 170, 225, 0.6)';
const thirdColor = 'rgba(255, 43, 82, 0.5)';

const customRenderer = (tag: { value: string; count: number }, size: number) => {
  const color = size === 3 ? mainColor : size === 2 ? secondColor : thirdColor;

  return (
    <span css={[styles.tag(size), { color }]} key={tag.value}>
      #{tag.value}
    </span>
  );
};

export interface HashtagCloudProps {
  data: readonly HashtagType[];
  onSelect: (value: string) => void;
}

export const HashtagCloud = ({ onSelect, data }: HashtagCloudProps) => (
  <TagCloud
    minSize={1}
    maxSize={3}
    tags={data as HashtagType[]}
    renderer={customRenderer}
    css={{ textAlign: 'center' }}
    onClick={(tag: { value: string; count: number }) => onSelect(`#${tag.value}`)}
  />
);

const styles = {
  tag: (size: number) =>
    css({
      margin: '3px',
      padding: '0 3px',
      cursor: 'pointer',
      fontSize: `${size}em`,
      display: 'inline-block',
      '&:hover': { textDecoration: 'underline' },
      '@media (max-width: 450px)': { fontSize: `${size * 0.8}em` }
    })
};
