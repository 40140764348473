import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { CampaignTrackingOption, CampaignSocialMediaType } from '@/graphql';
import { FormStyle, MediaPreview, Anchor } from '@/shared/atoms';
import { AUTO_MANAGED_CAMPAIGN_TYPE_OPTIONS, THEME } from '@/shared/constants';
import { useMarketplacePromotionMethodOptions, usePostPricesAvailability } from '../../hooks';
import { AnyCreatorIframePreview } from '../AnyCreatorIframePreview';
import {
  TitleField,
  BudgetField,
  CountryField,
  RevenueField,
  SalePICField,
  HashtagField,
  HashtagsNotice,
  AdvertiserField,
  MarginRateField,
  HubspotDealField,
  SocialMediaField,
  CostPerLikeField,
  CostPerPostField,
  CostPerViewField,
  CostPerShareField,
  CostPerOrderField,
  ApprovePostsField,
  FormLayoutTemplate,
  PreLaunchDateField,
  MaterialUploadField,
  PostRequirementField,
  ThumbnailUploadField,
  CostPerSaleTuneField,
  AgencyMarginRateField,
  CampaignCategoryField,
  AllowNewInfluencerField,
  InfluencerCategoryField,
  ServiceInformationField,
  ApproveInfluencersField,
  TrackingCreatorPostField,
  DraftPostSubmissionField,
  AdditionalRequirementField,
  InfluencerManagementPICField,
  CampaignPeriodWithLogicsField,
  InfluencerCommissionRateField
} from '../shared';
import { DefaultFormTemplateProps, MarketplaceFormKeys, MarketplaceFormValues } from '../types';
import { marketplaceSectionTitles } from '../utils';
import { AiRainbowSpinner, DescriptionGenerator, useDescriptionGeneratorState } from '../../DescriptionGenerator';

export const Template = ({ defaultValues, disabledFields, onSubmit }: DefaultFormTemplateProps) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext<MarketplaceFormValues>();
  const { isAdminStaff, isAdvertiser, isAdminStaffAgency } = useUserRoles();
  const { promotionMethodOptions } = useMarketplacePromotionMethodOptions();
  const descriptionActions = useDescriptionGeneratorState();

  const formValues = getValues();
  const hasTrackingPost = formValues.trackingOptions.includes(CampaignTrackingOption.POST);
  const allPostPrices = usePostPricesAvailability(formValues.socialMedia);
  const hashtagSectionIndex = hasTrackingPost ? 4 : 3;

  const handleTrackingPostChange = (active?: boolean) => {
    if (!active) {
      setValue(MarketplaceFormKeys.hashtags, []);
      setValue(MarketplaceFormKeys.requireDraftPost, false);
    }
    if (active && formValues.socialMedia === CampaignSocialMediaType.UNSELECT) {
      setValue(MarketplaceFormKeys.socialMedia, '');
    }
  };

  return (
    <FormLayoutTemplate defaultValues={defaultValues} onSubmit={onSubmit} hideSubmit={!isAdminStaff}>
      <FormStyle.SectionTitle index={1} label={t(marketplaceSectionTitles.offerInformation)} />
      <p css={{ fontSize: '14px', marginBottom: '12px' }}>
        {t('Select offer that you want to provide with special deal')}
      </p>

      <FormStyle.FieldWrapper css={{ display: 'flex', alignItems: 'center' }}>
        <MediaPreview src={formValues.productThumbnail} css={{ width: '120px', height: '120px' }} />
        <Anchor
          lines={2}
          variant="blue"
          target="_blank"
          label={formValues.title}
          href={formValues.campaignUrl}
          css={{ fontSize: '14px', marginLeft: '16px', textTransform: 'uppercase', maxWidth: '464px' }}
        />
      </FormStyle.FieldWrapper>

      <FormStyle.FieldsGroup itemsPerRow={2}>
        {isAdminStaffAgency ? <AdvertiserField disabled={disabledFields.advertiser} required /> : null}
        {isAdminStaff ? (
          <>
            <HubspotDealField disabled={disabledFields.hubspotDealIds} countryId={formValues.countryId} />
            <SalePICField disabled={disabledFields.salesPicIds} required />
            <InfluencerManagementPICField disabled={disabledFields.influencerManagementPicIds} required />
          </>
        ) : null}
      </FormStyle.FieldsGroup>

      <FormStyle.HorizontalLine css={{ margin: '12px auto 24px' }} />
      <FormStyle.SectionTitle index={2} label={t(marketplaceSectionTitles.campaignInformation)} />

      <TrackingCreatorPostField disabled={disabledFields.trackingOptions} onChange={handleTrackingPostChange} />

      <FormStyle.FieldsGroup itemsPerRow={2}>
        <PreLaunchDateField disabled={disabledFields.preLaunchDate} />
        <CampaignPeriodWithLogicsField disabled={disabledFields.period} required />
        <CountryField disabled={disabledFields.countryId} required />
        <CampaignCategoryField disabled={disabledFields.campaignCategoryId} required />
        <InfluencerCategoryField disabled={disabledFields.influencerCategoryIds} />
        <SocialMediaField
          required={hasTrackingPost}
          title={t('Selector.Social Media / Promotion method')}
          disabled={disabledFields.socialMedia}
          {...(!hasTrackingPost ? { options: promotionMethodOptions } : {})}
        />
      </FormStyle.FieldsGroup>
      <FormStyle.FieldWrapper>
        <div css={{ padding: '0 16px', border: `1px solid ${THEME.border.colors.gray.lv1}` }}>
          <ApproveInfluencersField disabled={disabledFields.isAutoInfluencerApproval} />
          <ApprovePostsField disabled={disabledFields.isAllowMultiplePosts} />
          <AllowNewInfluencerField disabled={disabledFields.allowNewInfluencer} />
        </div>
      </FormStyle.FieldWrapper>

      <DescriptionGenerator {...descriptionActions}>
        <FormStyle.SectionTitle
          index={3}
          label={t(marketplaceSectionTitles.campaignDetails)}
          css={{ marginTop: '8px' }}
        />
      </DescriptionGenerator>

      <TitleField
        disabled={disabledFields.title || descriptionActions.loading}
        required
        labelLoader={descriptionActions.loading ? <AiRainbowSpinner css={{ marginLeft: '4px' }} /> : undefined}
      />
      <ServiceInformationField
        disabled={disabledFields.serviceInformation || descriptionActions.loading}
        required
        labelLoader={descriptionActions.loading ? <AiRainbowSpinner css={{ marginLeft: '4px' }} /> : undefined}
        getSunEditorInstance={descriptionActions.getSunEditorInstance}
      />
      <PostRequirementField
        disabled={disabledFields.requirement || descriptionActions.loading}
        required
        labelLoader={descriptionActions.loading ? <AiRainbowSpinner css={{ marginLeft: '4px' }} /> : undefined}
        getSunEditorInstance={descriptionActions.getSunEditorInstance}
      />
      <ThumbnailUploadField disabled={disabledFields.thumbnails} required />
      <MaterialUploadField disabled={disabledFields.materials} />
      <AdditionalRequirementField disabled={disabledFields.additionalRequirement} />

      {hasTrackingPost ? (
        <>
          <DraftPostSubmissionField disabled={disabledFields.requireDraftPost} />
          <FormStyle.HorizontalLine css={{ margin: '24px auto' }} />
          <FormStyle.SectionTitle
            index={hashtagSectionIndex}
            css={{ marginBottom: '24px' }}
            label={t(marketplaceSectionTitles.trackingSettings)}
          />
          <HashtagsNotice />
          <HashtagField disabled={disabledFields.hashtags} />
          <div css={{ marginBottom: '24px' }}>
            <h3>
              {t('landingPageTitle_dynamic', {
                name: AUTO_MANAGED_CAMPAIGN_TYPE_OPTIONS.find((ct) => ct.value === formValues.autoManagedCampaignType)
                  ?.label
              })}
            </h3>
            <span css={{ color: THEME.text.colors.gray.lv3, fontSize: '12px' }}>{t('landingPageDescription_1')}</span>
            <br />
            <span css={{ color: THEME.text.colors.gray.lv3, fontSize: '12px' }}>{t('landingPageDescription_2')}</span>
          </div>
        </>
      ) : null}

      <FormStyle.HorizontalLine css={{ margin: '12px auto 24px' }} />
      <FormStyle.SectionTitle index={hashtagSectionIndex + 1} label={t(marketplaceSectionTitles.budgetSettings)} />

      <FormStyle.FieldsGroup itemsPerRow={4}>
        {hasTrackingPost ? (
          <>
            {allPostPrices.costPerPost ? <CostPerPostField disabled={disabledFields.costPerPost} /> : null}
            {allPostPrices.costPerLike ? <CostPerLikeField disabled={disabledFields.costPerLike} /> : null}
            {allPostPrices.costPerView ? <CostPerViewField disabled={disabledFields.costPerView} /> : null}
            {allPostPrices.costPerShare ? <CostPerShareField disabled={disabledFields.costPerShare} /> : null}
          </>
        ) : null}
        {allPostPrices.costPerOrder ? <CostPerOrderField disabled={disabledFields.costPerOrder} /> : null}
        {allPostPrices.costPerSaleReferral ? <CostPerSaleTuneField disabled={disabledFields.costPerSaleTune} /> : null}
      </FormStyle.FieldsGroup>

      <FormStyle.FieldsGroup itemsPerRow={4}>
        <RevenueField disabled={disabledFields.maximumRevenuePerInfluencer} />
        {isAdminStaffAgency ? <AgencyMarginRateField disabled={disabledFields.agencyMarginRate} /> : null}
        {isAdvertiser ? <BudgetField disabled={disabledFields.budget} /> : null}
      </FormStyle.FieldsGroup>

      <FormStyle.FieldsGroup itemsPerRow={4}>
        {isAdminStaff ? <MarginRateField disabled={disabledFields.marginRate} required /> : null}
        {!isAdvertiser ? <InfluencerCommissionRateField /> : null}
        {!isAdvertiser ? <BudgetField disabled={disabledFields.budget} /> : null}
      </FormStyle.FieldsGroup>

      <AnyCreatorIframePreview />
    </FormLayoutTemplate>
  );
};
