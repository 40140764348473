import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchFilterDefaults } from '@/shared/validations';
import { CampaignType, CampaignSocialMediaType, InfluencerCampaignListSortField } from '@/graphql';

export const InfluencerProfileCampaignsFilterSchema = z.object({
  keyword: z.string().default(''),
  campaignType: z.nativeEnum(CampaignType).optional(),
  socialMedias: z.array(z.nativeEnum(CampaignSocialMediaType)).default([])
});
export const InfluencerProfileCampaignsSearchSchema = z.object({
  filter: InfluencerProfileCampaignsFilterSchema.default({}),
  sort: SearchFilterDefaults.sort(InfluencerCampaignListSortField).default({})
});

export type InfluencerProfileCampaignsSearchSchemaType = z.infer<typeof InfluencerProfileCampaignsSearchSchema>;
export type InfluencerProfileCampaignsFilterFormValues = z.infer<typeof InfluencerProfileCampaignsFilterSchema>;
export type InfluencerProfileCampaignsFilterFormKeys = UnionOfKeys<InfluencerProfileCampaignsFilterFormValues>;
