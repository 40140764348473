import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { InstagramComparePosts } from '@/graphql';
import { RowProps, HelpIcon, ColumnProps, MediaPreviewWithMetrics } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { usePostDetailsParam, useSelectItem } from '@/shared/hooks';
import { InstagramComparePostDetailsModal } from '@/shared/molecules';
import { bigIntFormatter } from '@/shared/utils';
import {
  AnalyticsComparePostsHabit,
  AnalyticsComparePostsTable,
  AnalyticsComparePostsSlider,
  AnalyticsComparePostsAccount,
  AnalyticsComparePostsHashtags,
  AnalyticsComparePostsHabitModal,
  AnalyticsComparePostsHabitModalProps
} from '../../shared';
import { AnalyticsInstagramComparableAccountType } from '../types';
import {
  AnalyticsCompareInstagramHashtagsModal,
  AnalyticsCompareInstagramHashtagsModalProps
} from './AnalyticsCompareInstagramHashtagsModal';

interface TemplateProps {
  data: InstagramComparePosts;
  comparableAccounts: AnalyticsInstagramComparableAccountType[];
}

export const Template = ({ data, comparableAccounts }: TemplateProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();
  const {
    setSelectedItem: setPostingHabit,
    selectedItem: selectedPostingHabit,
    resetSelectedItem: resetPostingHabit
  } = useSelectItem<Pick<AnalyticsComparePostsHabitModalProps, 'account' | 'data'>>();
  const {
    setSelectedItem: setHashtags,
    selectedItem: selectedHashtags,
    resetSelectedItem: resetHashtags
  } = useSelectItem<Pick<AnalyticsCompareInstagramHashtagsModalProps, 'account' | 'hashtags'>>();

  const composeData = [
    data.mainAccount,
    ...(data.compareAccounts.filter((account) => account.id !== data.mainAccount.id) || [])
  ];
  const columns: ColumnProps[] = [
    {
      title: t('HeaderColumn.Account')
    },
    {
      styles: css({ paddingLeft: 0 }),
      title: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div css={{ flex: 1.2, display: 'flex', alignItems: 'center', padding: '0 16px' }}>
            <HelpIcon css={{ marginRight: '4px' }} help={t('Tooltip.comparePostingHabits')} />
            {t('HeaderColumn.Posting Habits')}
          </div>
          <div css={{ flex: 1, display: 'flex', alignItems: 'center', paddingLeft: '16px' }}>
            <HelpIcon css={{ marginRight: '4px' }} help={t('Tooltip.comparePopularHashtags')} />
            {t('HeaderColumn.Popular Hashtags')}
          </div>
        </div>
      )
    }
  ];

  const rows: RowProps[] = comparableAccounts.map((account) => {
    const rowData = composeData.find((item) => item.id === account.id);

    return {
      styles: css({
        'td:first-of-type': { width: '160px', borderRight: THEME.border.base },
        'td:last-of-type': { paddingLeft: 0, paddingRight: 0 }
      }),
      cells: [
        <AnalyticsComparePostsAccount account={{ name: account.username, avatar: account.avatarUrl }} />,
        <>
          <div css={{ display: 'flex', minHeight: '90px', padding: '12px 0', boxSizing: 'border-box' }}>
            <AnalyticsComparePostsHabit
              postsHabit={rowData?.postsHabit || []}
              css={{ flex: 1.2, paddingTop: '4px', paddingBottom: '4px', borderRight: THEME.border.base }}
              buttonProps={{
                onClick: () =>
                  account && rowData?.postsHabit
                    ? setPostingHabit({
                        data: rowData.postsHabit,
                        account: { name: account.username, avatar: account.avatarUrl }
                      })
                    : undefined
              }}
            />

            <AnalyticsComparePostsHashtags
              css={{ flex: 1, paddingTop: '4px', paddingBottom: '4px' }}
              hashtags={
                rowData?.popularHashtags?.reduce<string[]>(
                  (acc, curr) => (curr?.hashtag ? [...acc, curr.hashtag] : acc),
                  []
                ) || []
              }
              buttonProps={{
                onClick: () =>
                  account && rowData?.popularHashtags?.length
                    ? setHashtags({
                        hashtags: rowData?.popularHashtags,
                        account: { name: account.username, avatar: account.avatarUrl }
                      })
                    : undefined
              }}
            />
          </div>

          <AnalyticsComparePostsSlider
            posts={rowData?.feedPosts || []}
            renderPost={(post) => (
              <MediaPreviewWithMetrics
                objectFit="contain"
                src={post.thumbNail}
                css={THEME.mediaPreview.default}
                onClick={() => setPostDetailsParam?.(post.id)}
                statistics={[
                  { label: t('Like'), value: bigIntFormatter(post.likes, 0, { k: true, m: true }) },
                  { label: t('General.Comments'), value: bigIntFormatter(post.comments, 0, { k: true, m: true }) }
                ]}
              />
            )}
          />

          <InstagramComparePostDetailsModal posts={rowData?.feedPosts?.map((post) => ({ id: post.id })) || []} />
        </>
      ]
    };
  });

  return (
    <>
      <AnalyticsComparePostsTable data={{ rows, columns }} />

      <AnalyticsComparePostsHabitModal
        onClose={resetPostingHabit}
        open={!!selectedPostingHabit}
        {...selectedPostingHabit}
      />

      <AnalyticsCompareInstagramHashtagsModal open={!!selectedHashtags} onClose={resetHashtags} {...selectedHashtags} />
    </>
  );
};
