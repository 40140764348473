import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/EngagementPostInfluencers.graphql';
import { Query, QueryengagementPostInfluencersArgs } from '../../__generated__/globalTypes';

export const useEngagementPostInfluencersQuery = (
  options?: QueryHookOptions<Query, QueryengagementPostInfluencersArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryengagementPostInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useEngagementPostInfluencersLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryengagementPostInfluencersArgs>
): QueryResult<Query, QueryengagementPostInfluencersArgs> & {
  getEngagementPostInfluencers: LazyQueryExecFunction<Query, QueryengagementPostInfluencersArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getEngagementPostInfluencers, result] = useLazyQuery<Query, QueryengagementPostInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getEngagementPostInfluencers, ...result };
};

export const useEngagementPostInfluencersPromiseQuery = () => {
  const client = useApolloClient();

  const getEngagementPostInfluencers = useCallback(
    (options?: Omit<QueryOptions<QueryengagementPostInfluencersArgs, Query>, 'query'>) =>
      client.query<Query, QueryengagementPostInfluencersArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getEngagementPostInfluencers };
};
