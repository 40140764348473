import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { Link } from '@tanstack/react-router';
import { THEME } from '@/shared/constants';
import { TabType } from './types';

export interface TabsProps {
  options: TabType[];
  hasIndex?: boolean;
  className?: string;
  hasBorder?: boolean;
  children?: ReactNode;
}

export const Tabs = ({ options, className, hasBorder = true, hasIndex, children }: TabsProps) => (
  <div css={styles.wrapper(hasBorder)} className={className}>
    <div css={styles.navTab}>
      {options
        .filter((tab) => !tab.hidden)
        .map(({ label, end, to, onClick, disabled, params, search, includeSearch }, index) => (
          <Link
            css={[styles.navLink, disabled && { cursor: 'default', color: THEME.text.colors.gray.lv3 }]}
            key={`tab-${index}`}
            to={to}
            params={params}
            search={search}
            className="tab-title"
            // Avoid reset params when click same path
            activeProps={{ style: { pointerEvents: 'none' }, className: 'active' }}
            activeOptions={{ exact: end, includeSearch: includeSearch ?? false }}
            onClick={(e) => {
              if (disabled) {
                e.preventDefault();
              }

              onClick?.(e);
            }}
          >
            {hasIndex ? `${index + 1}. ${label}` : label}
          </Link>
        ))}
    </div>

    {children}
  </div>
);
const styles = {
  wrapper: (hasBorder?: boolean) =>
    css({
      height: '48px',
      display: 'flex',
      padding: '0 24px',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: THEME.background.colors.white,
      ...(hasBorder
        ? {
            borderTop: `1px solid ${THEME.border.colors.gray.lv1}`,
            borderBottom: `1px solid ${THEME.border.colors.gray.lv1}`
          }
        : {})
    }),
  navTab: css({
    gap: '16px',
    display: 'flex',
    fontWeight: 600,
    fontSize: '13px',
    alignItems: 'center',
    color: THEME.text.colors.black.lv1
  }),
  navLink: css({
    padding: '0 15px',
    cursor: 'pointer',
    overflow: 'hidden',
    lineHeight: '44px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    borderBottom: '4px solid transparent',
    '&.active': { color: THEME.text.colors.black.lv1, borderBottomColor: THEME.border.colors.blue.lv2 }
  })
};
