import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Anchor, AnchorProps } from '../Anchor';

export interface ListEmptyProps {
  title?: ReactNode;
  className?: string;
  hideTitle?: boolean;
  description?: ReactNode;
  linkProps?: AnchorProps;
  hideDescription?: boolean;
}

export const ListEmpty = ({ linkProps, title, className, description, hideTitle, hideDescription }: ListEmptyProps) => {
  const { t } = useTranslation();

  const customTitle = title || t('General.Nothing has been found');
  const customDescription =
    description ||
    (linkProps && t('General.Please try a different query or')) ||
    t('General.Please try a different query');
  const customLink = linkProps ? (
    <>
      &nbsp;
      <Anchor variant="blue" css={{ display: 'inline-flex' }} {...linkProps} />
    </>
  ) : null;

  return (
    <div css={styles.wrapper} className={className}>
      <div css={{ width: '100%' }}>
        {!hideTitle ? <div css={{ color: '#757575', fontSize: '20px', fontWeight: 500 }}>{customTitle}</div> : null}

        {!hideDescription ? (
          <div css={{ color: '#757575', marginTop: '8px', fontSize: '14px' }}>
            {customDescription}
            {customLink}
          </div>
        ) : null}
      </div>
    </div>
  );
};
const styles = {
  wrapper: css({
    width: '100%',
    display: 'flex',
    minHeight: '360px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: THEME.background.colors.white
  })
};
