import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { NoDataContentWrapper, NoDataContentWrapperProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatPercent } from '@/shared/utils';

export interface GenderChartProps
  extends Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'> {
  male?: number | null;
  female?: number | null;
}

const customFormatPercent = (value?: number | null) => (value && value > 0.1 ? formatPercent(value, true, 1) : '');

export const GenderChart = ({ male, female, ...noDataContentProps }: GenderChartProps) => {
  const { t } = useTranslation();

  const defaultMale = male ?? 0;
  const defaultFemale = female ?? 0;

  const getWidth = (value: number) => `${(value / (defaultFemale + defaultMale) || 1) * 100}%`;

  return (
    <NoDataContentWrapper
      dataNotAvailable={!male || !female}
      css={{ width: '100%', minHeight: 'unset' }}
      {...noDataContentProps}
    >
      <div css={{ width: '100%' }}>
        <div css={{ display: 'flex', color: THEME.text.colors.white, height: '32px' }}>
          <div css={[styles.chart, { width: getWidth(defaultFemale) }]} title={formatPercent(female, true, 1)}>
            {customFormatPercent(female)}
          </div>
          <div css={[styles.chart, { width: getWidth(defaultMale) }]} title={formatPercent(male, true, 1)}>
            {customFormatPercent(male)}
          </div>
        </div>

        <div css={styles.labelWrapper}>
          <p>{t('Female')}</p>
          <p>{t('Male')}</p>
        </div>
      </div>
    </NoDataContentWrapper>
  );
};
const styles = {
  labelWrapper: css({
    display: 'flex',
    marginTop: '4px',
    fontSize: '11px',
    fontWeight: 600,
    color: THEME.text.colors.black.lv1,
    justifyContent: 'space-between'
  }),
  chart: css({
    fontWeight: 600,
    lineHeight: 1.5,
    display: 'flex',
    fontSize: '16px',
    cursor: 'pointer',
    fontStyle: 'normal',
    alignItems: 'center',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    justifyContent: 'center',
    color: THEME.text.colors.white,
    '&:first-of-type': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      backgroundColor: THEME.chart.colors.gender.female
    },
    '&:last-of-type': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      backgroundColor: THEME.chart.colors.gender.male
    },
    '&:hover': {
      marginTop: '-2px',
      marginBottom: '2px',
      boxShadow: '0 3px 4px 0 rgba(39, 49, 59, 0.2)'
    }
  })
};
