import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { formatIntNumber, formatPercent, getOffset } from '@/shared/utils';
import { YoutubeAnalyticsTagRankingPayload, YoutubeAnalyticsTagRankingSortOrder } from '@/graphql';
import { useYtTagRankingSearchFilter } from '../..';

type CustomColumnProps = ColumnProps<YoutubeAnalyticsTagRankingSortOrder>;

interface Props {
  data: readonly YoutubeAnalyticsTagRankingPayload[];
}

export const useAnalyticsYoutubeTagsRankingListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { page, limit } = useYtTagRankingSearchFilter();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const firstIndex = page && limit ? getOffset(page, limit) + 1 : 1;
  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Tag'), sticky: { active: true, hasBorderRight: true } },
    getMetricColumnProps({
      title: t('HeaderColumn.Post'),
      sortField: YoutubeAnalyticsTagRankingSortOrder.POST
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.View'),
      sortField: YoutubeAnalyticsTagRankingSortOrder.VIEWS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Ave like'),
      sortField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_LIKE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Ave dislike'),
      sortField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_DISLIKE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Ave Comment'),
      sortField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_COMMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Ave Eng'),
      sortField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_ENGAGEMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Ave share'),
      sortField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_SHARE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Eng rate'),
      sortField: YoutubeAnalyticsTagRankingSortOrder.ENGAGEMENT_RATE
    })
  ];
  const rows: RowProps[] = data.map(
    (
      {
        tag,
        posts,
        views,
        averageLike,
        averageShare,
        engagementRate,
        averageDislike,
        averageComment,
        averageEngagement
      },
      index
    ) => [
      <div css={styles.tagWrapper}>
        <div css={[styles.tagNumberBlock, { backgroundColor: firstIndex + index <= 3 ? '#ff2b52' : '#c5d0da' }]}>
          {firstIndex + index}
        </div>
        <p title={tag}>{tag}</p>
      </div>,
      <TableCell.Number value={formatIntNumber(posts)} />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={formatIntNumber(averageLike)} />,
      <TableCell.Number value={formatIntNumber(averageDislike)} />,
      <TableCell.Number value={formatIntNumber(averageComment)} />,
      <TableCell.Number value={formatIntNumber(averageEngagement)} />,
      <TableCell.Number value={formatIntNumber(averageShare)} />,
      <TableCell.Number value={formatPercent(engagementRate, false)} unit="%" />
    ]
  );

  return { rows, columns };
};
const styles = {
  tagWrapper: css({
    width: '220px',
    display: 'flex',
    alignItems: 'center',
    p: { maxWidth: '250px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
  }),
  tagNumberBlock: css({
    flex: 'none', // prevent flex shrink when text is too long
    width: '24px',
    height: '24px',
    display: 'flex',
    fontWeight: 600,
    fontSize: '16px',
    marginRight: '8px',
    alignItems: 'center',
    placeContent: 'center',
    color: THEME.text.colors.white
  })
};
