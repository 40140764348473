import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RegionField as RHFRegionField, RegionFieldProps } from '@/shared/molecules';

import { MarketplaceFormValues, MarketplaceFormKeys } from '../types';

export const RegionField = (props: Omit<RegionFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<MarketplaceFormValues>();
  const countryIdValue = watch(MarketplaceFormKeys.countryId);

  return (
    <RHFRegionField
      title={t('Selector.Region')}
      help={t('Tooltip.Marketplace Region')}
      countryId={countryIdValue}
      name={MarketplaceFormKeys.regionIds}
      {...props}
    />
  );
};
