import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/atoms';
import { formatIntNumber } from '@/shared/utils';
import { useSelectItemIds, useToggleState } from '@/shared/hooks';
import { MarketplaceInfluencersSortProps, MarketplaceInfluencersListProps } from '@/shared/organisms';
import { InfluencerWhitelist, MarketplaceInfluencer, MarketplaceCampaignParticipateStatus } from '@/graphql';
import { useMarketplaceDetailsContext } from '../../../MarketplaceDetailsContext';

interface Props {
  totalRecords?: number;
  listInfluencers: readonly MarketplaceInfluencer[];
  influencerWhitelist?: InfluencerWhitelist | null;
}

export const useMarketplaceInfluencersSelectButtonsProps = ({
  totalRecords = 0,
  listInfluencers,
  influencerWhitelist
}: Props) => {
  const { t } = useTranslation();
  const markAsJoinedModal = useToggleState();
  const markAsAvailableModal = useToggleState();
  const { forceInfluencersParticipateCampaign } = useMarketplaceDetailsContext();

  const isWhitelist = !!influencerWhitelist?.isWhitelist;
  const influencerIds = influencerWhitelist?.influencerIds || [];
  // Filter selectable influencers
  const { joinableInfluencerIds, unavailableInfluencerIds } = listInfluencers.reduce<{
    joinableInfluencerIds: MarketplaceInfluencer['id'][];
    unavailableInfluencerIds: MarketplaceInfluencer['id'][];
  }>(
    (acc, curr) => {
      const index = influencerIds.findIndex((inflId) => inflId === curr.id);
      const isValidInfluencer = isWhitelist ? index !== -1 : index === -1;
      const isAvailable = curr.participateStatus === MarketplaceCampaignParticipateStatus.AVAILABLE;
      const isUnavailable = curr.participateStatus === MarketplaceCampaignParticipateStatus.UNAVAILABLE;

      if (isUnavailable) {
        // Which unavailable influencers, we allow to select to mark as available
        acc.unavailableInfluencerIds.push(curr.id);
      } else if (isAvailable && isValidInfluencer) {
        // Which available influencers, we need to check if they are in whitelist then allow to select and mark as joined
        acc.joinableInfluencerIds.push(curr.id);
      }

      return acc;
    },
    { joinableInfluencerIds: [], unavailableInfluencerIds: [] }
  );
  const selectableInfluencerIds = [...joinableInfluencerIds, ...unavailableInfluencerIds];
  const { selectedItemIds, selectItem, setSelectedItemIds } = useSelectItemIds({ allItemIds: selectableInfluencerIds });

  const selectedJoinableInfluencerIds = selectedItemIds.filter((id) => joinableInfluencerIds.includes(id));
  const selectedUnavailableInfluencerIds = selectedItemIds.filter((id) => unavailableInfluencerIds.includes(id));

  const sortSelectButtonProps: MarketplaceInfluencersSortProps['selectButtonProps'] = (() => {
    const checked = !!selectedItemIds.length;
    const disabled = selectableInfluencerIds.length === 0 || !forceInfluencersParticipateCampaign;
    const indeterminate =
      !!selectedItemIds.length && !selectableInfluencerIds.every((id) => selectedItemIds.includes(id));
    const toggleSelectAllItems = () => {
      checked
        ? indeterminate
          ? setSelectedItemIds((prevSelectedItemIds) =>
              Array.from(new Set([...prevSelectedItemIds, ...selectableInfluencerIds]))
            )
          : setSelectedItemIds([])
        : setSelectedItemIds(selectableInfluencerIds);
    };
    return {
      selectAllProps: { label: t('Select all influencers'), onClick: toggleSelectAllItems },
      checkboxProps: {
        checked,
        disabled,
        indeterminate,
        onClick: toggleSelectAllItems,
        label: selectedItemIds.length
          ? t('influencer selected', { count: selectedItemIds.length })
          : `${formatIntNumber(totalRecords)} ${t('Influencers found')}`
      },
      children: (
        <>
          <Button
            variant="blue"
            onClick={markAsAvailableModal.open}
            title={t('Button.Mark as Available')}
            css={{ padding: '0 24px', marginLeft: '8px' }}
            disabled={disabled || !selectedUnavailableInfluencerIds.length}
          />
          <Button
            variant="blue"
            onClick={markAsJoinedModal.open}
            title={t('Button.Mark as Joined')}
            css={{ padding: '0 24px', marginLeft: '8px' }}
            disabled={disabled || !selectedJoinableInfluencerIds.length}
          />
        </>
      )
    };
  })();

  const generateListSelectButtonProps: MarketplaceInfluencersListProps['generateSelectButtonProps'] = ({
    id,
    participateStatus
  }) => ({
    type: 'checkbox',
    onClick: () => selectItem(id),
    disabled:
      !forceInfluencersParticipateCampaign || selectableInfluencerIds.findIndex((inflId) => inflId === id) === -1,
    checked:
      selectedItemIds.includes(id) ||
      [MarketplaceCampaignParticipateStatus.WAITING, MarketplaceCampaignParticipateStatus.JOINED].includes(
        participateStatus
      )
  });

  return {
    // For sort/list select button
    sortSelectButtonProps,
    generateListSelectButtonProps,
    // For join confirm modal
    markAsJoinedModal,
    selectedJoinableInfluencerIds, // Influencers that can be marked as joined
    resetSelectedJoinableInfluencerIds: () => setSelectedItemIds(selectedUnavailableInfluencerIds),
    // For available confirm modal
    markAsAvailableModal,
    selectedUnavailableInfluencerIds, // Influencers that can be marked as available
    resetSelectedUnavailableInfluencerIds: () => setSelectedItemIds(selectedJoinableInfluencerIds)
  };
};
