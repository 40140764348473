import { useTranslation } from 'react-i18next';
import { Anchor, Notice } from '@/shared/atoms';

declare const IS_DISABLED_ON_PROD: boolean;

export const NoticeConnectUrl = () => {
  const { t } = useTranslation();
  const connectUrl = `https://${IS_DISABLED_ON_PROD ? 'app' : 'stg-app'}.any-creator.com/profile/settings/connect`;

  return (
    <Notice
      title={t('Information')}
      type="info"
      css={{ marginBottom: '16px' }}
      description={
        <div css={{ display: 'flex' }}>
          <p>
            {t(
              'If you want this influencer to add a new account, please share this URL and ask them to register from there'
            )}
          </p>
          <Anchor css={{ fontWeight: 'inherit' }} variant="blue" href={connectUrl} label={connectUrl} lines={2} />
        </div>
      }
    />
  );
};
