import { Option } from '@/shared/types';
import { MultiSearchSelector, MultiSearchSelectorProps } from '../MultiSearchSelector';
import { useSearchPrivateInfluencers } from './useSearchPrivateInfluencers';

export interface MultiPrivateInfluencersSearchSelectorProps
  extends Omit<MultiSearchSelectorProps<Option>, 'loadOptions'> {
  agencyId: number;
  agencyType: 'talent' | 'partner';
}

export const MultiPrivateInfluencersSearchSelector = (props: MultiPrivateInfluencersSearchSelectorProps) => {
  const { agencyId, agencyType, ...restProps } = props;
  const { searchPrivateInfluencers } = useSearchPrivateInfluencers({
    agencyId,
    agencyType,
    alreadySelectedInfluencers: props.value.map((infl) => Number(infl.value))
  });

  return <MultiSearchSelector {...restProps} loadOptions={searchPrivateInfluencers} creatable={false} />;
};
