import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicFilterWrapper } from '../Filter';
import { TextSearchField } from '../RHFFields';
import { KeywordFilterFormValues } from './types';

export interface TemplateProps {
  placeholder?: string;
  onSubmit?: () => void;
  children?: ReactNode;
}
export const Template = ({ onSubmit, children, placeholder }: TemplateProps) => {
  const { t } = useTranslation();

  return (
    <BasicFilterWrapper css={{ padding: 0 }}>
      <TextSearchField<keyof KeywordFilterFormValues>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={placeholder ?? t<string>('Search.keywords')}
      />

      {children}
    </BasicFilterWrapper>
  );
};
