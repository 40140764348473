import { Accept } from 'react-dropzone';

export const UPLOAD_VIDEO_MIME = 'MP4, MOV';
export const UPLOAD_IMAGE_MIME = 'PNG, GIF, JPEG';
export const IMAGE_MIME = 'image/jpeg, image/png, image/gif';
export const VIDEO_MIME = 'video/mp4, video/quicktime';

// https://react-dropzone.js.org/#!/Accepting%20specific%20file%20types
export const MIME_ACCEPT: Record<string, Accept> = {
  GIF: { 'image/gif': [] },
  CSV: { 'text/csv': ['.csv'] },
  VIDEO: { 'video/mp4': [], 'video/quicktime': [] },
  IMAGE: { 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }
};
