import { Ref, forwardRef } from 'react';
import { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';

export interface MultiSearchSelectorProps<T extends Option = Option> {
  value: T[];
  className?: string;
  placeholder: string;
  disabled?: boolean;
  creatable?: boolean;
  defaultOptions?: T[];
  componentProps?: object; // those props can be accessed in custom components
  closeMenuOnSelect?: boolean;
  setFieldValue: (value: T[]) => void;
  loadOptions: (inputValue: string, callback: (options: T[]) => void) => Promise<any> | void;
  components?: Partial<typeof AsyncCreatableSelect> | Partial<typeof AsyncSelect>;
}

const Template = <T extends Option = Option>(
  {
    value,
    className,
    components,
    placeholder,
    loadOptions,
    setFieldValue,
    defaultOptions,
    componentProps,
    closeMenuOnSelect,
    disabled = false,
    creatable = true
  }: MultiSearchSelectorProps<T>,
  ref: Ref<HTMLDivElement>
) => {
  const handleChange = (
    values: OnChangeValue<T, any>,
    { action, removedValue }: ActionMeta<any> & OnChangeValue<any, any>
  ) => {
    let listOptions = value;

    switch (action) {
      case 'create-option':
      case 'select-option':
        listOptions = values as T[];
        break;
      case 'remove-value':
      case 'pop-value':
        listOptions = listOptions.filter((v) => v.value !== removedValue.value);
        break;
      case 'clear':
        listOptions = [];
        break;
    }

    setFieldValue(listOptions);
  };

  const defaultProps = {
    value,
    components,
    className,
    loadOptions,
    placeholder,
    defaultOptions,
    closeMenuOnSelect,
    isMulti: true,
    styles: styles,
    cacheOptions: true,
    isDisabled: disabled,
    onChange: handleChange,
    ...componentProps
  };

  return (
    <div ref={ref}>{creatable ? <AsyncCreatableSelect {...defaultProps} /> : <AsyncSelect {...defaultProps} />}</div>
  );
};

const styles = {
  control: (base, props) => ({
    ...base,
    border: `1px solid ${props.isFocused ? THEME.input.border.colors.focus : THEME.input.border.colors.default}`,
    borderRadius: '2px',
    minHeight: '32px',
    outline: 'none',
    boxShadow: 'none',
    lineHeight: '15px',
    '&:hover': {
      borderColor: props.isFocused ? THEME.input.border.colors.focus : THEME.input.border.colors.default
    }
  }),
  menu: (base) => ({
    ...base,
    border: THEME.border.base,
    borderRadius: '3px',
    borderTop: 'none',
    borderTopLeftRadius: 'unset',
    borderTopRightRadius: 'unset',
    marginTop: 0,
    boxShadow: 'unset'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  indicatorsContainer: (base) => ({
    ...base,

    div: {
      padding: '0 8px'
    }
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    paddingRight: '7px',
    cursor: 'pointer',

    svg: {
      color: THEME.text.colors.gray.lv3,
      height: '17px'
    }
  }),
  placeholder: (base) => ({
    ...base,
    opacity: 0.3,
    marginLeft: '5px',
    width: '100%'
  })
} satisfies StylesConfig<any>;

export const MultiSearchSelector = forwardRef(Template) as <T extends Option>(
  props: MultiSearchSelectorProps<T> & { ref?: Ref<HTMLDivElement> }
) => ReturnType<typeof Template>;
