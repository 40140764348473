import { useParams } from '@tanstack/react-router';
import { useAuthData, useUserRoles } from '@/auth';
import {
  useCreateMarketplaceMutation,
  MarketplaceCampaignDetailType,
  useGetAutoManagedCampaignByIdQuery,
  useAdvertiserForAddMarketplaceQuery
} from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { MarketplaceFormValues, MarketplaceCampaignForms, getCreateUpdateMarketplaceInput } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export interface MarketplaceCampaignFormProps {
  type: MarketplaceCampaignDetailType;
}

export const MarketplaceCampaignForm = ({ type }: MarketplaceCampaignFormProps) => {
  const {
    auth: { userId }
  } = useAuthData();
  const { isAdvertiser } = useUserRoles();
  const { autoMangedCampaignId } = useParams({
    strict: false
  });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateMarketplace } = useCreateMarketplaceMutation();

  const { data, loading } = useAdvertiserForAddMarketplaceQuery({
    skip: !isAdvertiser,
    fetchPolicy: 'cache-first',
    variables: { pk: userId },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const { data: autoManagedData, loading: fetchingAutoManagedCampaign } = useGetAutoManagedCampaignByIdQuery({
    fetchPolicy: 'network-only',
    variables: { input: { id: Number(autoMangedCampaignId) } },
    skip: type !== MarketplaceCampaignDetailType.E_COMMERCE || !autoMangedCampaignId,
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/auto-managed' });
    }
  });

  const handleSubmit: FormAction<MarketplaceFormValues>['onSubmit'] = async (formValues) => {
    const input = getCreateUpdateMarketplaceInput(formValues);
    try {
      await callCreateMarketplace({ variables: { input } });
      enqueueSnackbar(t('succeededInCreating', { name: input.title }), { variant: 'success' });
      navigate({ to: '/marketplace' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <RenderDataWithFallback loading={loading || fetchingAutoManagedCampaign}>
      <MarketplaceCampaignForms
        type={type}
        onSubmit={handleSubmit}
        autoManagedCampaignData={autoManagedData?.getAutoManagedCampaignById}
        defaultAdvertiser={
          data?.advertiserForAdvertiserUser
            ? { id: data?.advertiserForAdvertiserUser.id, name: data?.advertiserForAdvertiserUser.name }
            : undefined
        }
      />
    </RenderDataWithFallback>
  );
};
