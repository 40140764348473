import { useCallback } from 'react';
import { MarketplaceDetail, AutoManagedCampaignPayload, MarketplaceCampaignDetailType } from '@/graphql';
import { FormAction } from '@/shared/types';
import { EcommerceCampaignForm } from './EcommerceCampaignForm';
import { ReferralCodeTrackingForm } from './ReferralCodeTrackingForm';
import { SocialMediaTrackingForm } from './SocialMediaTrackingForm';
import { DefaultFormProps, MarketplaceFormValues } from './types';
import { URLTrackingForm } from './URLTrackingForm';
import { getMarketplaceDisabledFields } from './utils';

export interface MarketplaceCampaignFormsProps extends FormAction<MarketplaceFormValues> {
  data?: MarketplaceDetail;
  defaultAdvertiser?: MarketplaceDetail['advertiser'];
  type: MarketplaceCampaignDetailType;
  autoManagedCampaignData?: AutoManagedCampaignPayload | null;
}

export const MarketplaceCampaignForms = ({
  type,
  data,
  onSubmit,
  defaultAdvertiser,
  autoManagedCampaignData
}: MarketplaceCampaignFormsProps) => {
  // RHF provider put inside each form will reset the form after submit values without useCallback
  // because of re-running the switch/case to select form
  const Form = useCallback(() => {
    const formProps: DefaultFormProps = {
      data,
      onSubmit,
      defaultAdvertiser,
      disabledFields: getMarketplaceDisabledFields({ campaignStatus: data?.status, campaignType: type })
    };

    switch (type) {
      case MarketplaceCampaignDetailType.NORMAL:
        return <SocialMediaTrackingForm {...formProps} />;
      case MarketplaceCampaignDetailType.URL_TRACKING:
        return <URLTrackingForm {...formProps} />;
      case MarketplaceCampaignDetailType.REFERRAL_CODE:
        return <ReferralCodeTrackingForm {...formProps} />;
      case MarketplaceCampaignDetailType.E_COMMERCE:
        return <EcommerceCampaignForm {...formProps} autoManagedCampaignData={autoManagedCampaignData} />;
      default:
        return null;
    }
  }, [type, data]);

  return <Form />;
};
