import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/InfluencerDeleteYoutubeCompareAccountAnalytics.graphql';
import { Mutation, MutationinfluencerDeleteYoutubeCompareAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useInfluencerDeleteYoutubeCompareAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationinfluencerDeleteYoutubeCompareAccountAnalyticsArgs>
) => {
  const [callInfluencerDeleteYoutubeCompareAccountAnalytics, result] = useMutation<
    Mutation,
    MutationinfluencerDeleteYoutubeCompareAccountAnalyticsArgs
  >(MUTATION, options);

  return { callInfluencerDeleteYoutubeCompareAccountAnalytics, ...result };
};

export type InfluencerDeleteYoutubeCompareAccountAnalyticsMutationFunction =
  Mutation['influencerDeleteYoutubeCompareAccountAnalytics'];
