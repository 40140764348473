import { DouyinInfluencerProfile, DouyinInfluencers } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSocialAccountSelectButtonProps } from './hooks';

export const DouyinInfluencersSearch = () => {
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialAccountSelectButtonProps<DouyinInfluencerProfile>({
      socialMedia: InfluencersTabType.DOUYIN
    });

  return (
    <DouyinInfluencers
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
