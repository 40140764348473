import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  NoDataContentWrapper,
  RenderDataWithFallback,
  NoDataContentWrapperProps
} from '@/shared/atoms';
import { SectionWithHeader, SectionWithHeaderProps } from '@/shared/molecules';

export interface InfluencerProfileAccountInterestWrapperProps<T = NonNullable<unknown>>
  extends Omit<SectionWithHeaderProps, 'children'>,
    Pick<NoDataContentWrapperProps, 'dataNotAvailable' | 'wrapperText' | 'wrapperLink'> {
  data?: T | null;
  loading?: boolean;
  className?: string;
  children?: ReactNode | ((props: { data: T }) => ReactNode);
}

export const InfluencerProfileAccountInterestWrapper = <T,>({
  data,
  loading,
  children,
  className,
  wrapperLink,
  wrapperText,
  dataNotAvailable,
  ...restProps
}: InfluencerProfileAccountInterestWrapperProps<T>) => {
  const { t } = useTranslation();

  return (
    <div css={{ marginTop: '16px' }} className={className}>
      <SectionWithHeader
        title={t('Account interest')}
        prefixIcon={{ icon: 'user-two-tones', size: '20px' }}
        {...restProps}
      >
        <RenderDataWithFallback loading={loading} loadingProps={{ css: { height: '385px' } }} skipDelayed>
          <NoDataContentWrapper
            wrapperLink={wrapperLink}
            wrapperText={wrapperText}
            dataNotAvailable={dataNotAvailable ?? !data}
          >
            {!data ? null : typeof children === 'function' ? children({ data }) : children}
          </NoDataContentWrapper>
        </RenderDataWithFallback>
      </SectionWithHeader>
    </div>
  );
};
