import React, { ReactElement, useRef } from 'react';
import { css } from '@emotion/react';
import { useDimensions } from '@/shared/hooks';

export interface ChartsContainerProps {
  className?: string;
  children: (ReactElement | null | undefined)[];
}

// Sometimes we will use the Fragment to wrap 2 or more charts with same condition, in this case it will clone the fragment instead of charts
const flattenElements = (elements: ChartsContainerProps['children'], results: ReactElement[] = []) => {
  React.Children.forEach(elements, (child) => {
    if (child) {
      // This will remove null|undefined elements
      if (child.type === React.Fragment) {
        return flattenElements(child.props.children, results);
      }

      results.push(child);
    }

    return;
  });

  return results;
};

// Usually used in Analytics/Influencer/Report page, where displays 2/3 charts horizontal
export const ChartsContainer = ({ className, children }: ChartsContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useDimensions(containerRef);

  const elements = flattenElements(children);
  const chartWidth = width ? (width - 16 * 6) / (elements.length || 1) : undefined;

  return (
    <div css={{ display: 'flex' }} ref={containerRef} className={className}>
      {elements.map((el, index) => (
        <div key={index} css={styles.item}>
          {React.cloneElement(el, { ...el.props, width: chartWidth })}
        </div>
      ))}
    </div>
  );
};
const styles = {
  item: css({
    flex: '1 1 0',
    overflow: 'hidden',
    borderRight: '1px solid #e6ecf0',
    '&:last-of-type': { borderRightStyle: 'none' }
  })
};
