import { useParams } from '@tanstack/react-router';
import {
  InputMaybe,
  SocialAccountType,
  namedOperations,
  useConnectPackageWithSocialAccountsMutation,
  useDeleteSocialAccountPackageMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { getSocialPairsIds } from '@/shared/utils';
import { SelectedSocialMediaAccountsType } from '@/shared/organisms';

interface ToggleSocialAccountProps {
  isChecked?: boolean;
  selectedSocialMediaAccounts: SelectedSocialMediaAccountsType[];
  socialAccountIds: number[];
  readonly socialType?: InputMaybe<SocialAccountType>;
}

export const useToggleSelectedSocialAccountsForPackage = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/package/$id/content/search' });

  const { callConnectPackageWithSocialAccounts, loading: isUpdatingProposal } =
    useConnectPackageWithSocialAccountsMutation({
      refetchQueries: [namedOperations.Query.PackageProposal]
    });
  const { callDeleteSocialAccountPackage, loading: isDeletingProposal } = useDeleteSocialAccountPackageMutation({
    refetchQueries: [namedOperations.Query.PackageProposal]
  });

  const toggleSocialMediaSelectedInfluencer = async ({
    isChecked,
    socialAccountIds,
    socialType,
    selectedSocialMediaAccounts
  }: ToggleSocialAccountProps) => {
    let error;
    const { socialPairs } = getSocialPairsIds(
      selectedSocialMediaAccounts.map((acc) => ({
        ...acc,
        socialAccountMedia: acc.socialAccountType,
        socialAccountId: Number(acc.socialAccountId)
      }))
    );
    const packageId = Number(params.id);

    try {
      if (isChecked) {
        await callDeleteSocialAccountPackage({
          variables: {
            packageId,
            socialAccountIds,
            socialMedia: socialType as SocialAccountType
          }
        });
      } else {
        await callConnectPackageWithSocialAccounts({
          variables: {
            input: {
              socialPairs,
              packageId
            }
          }
        });
      }
      // we don't want to notify user on each save/delete, just show an error if happens
    } catch (err) {
      error = err.message;
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }

    return { error };
  };

  return { toggleSocialMediaSelectedInfluencer, loading: isUpdatingProposal || isDeletingProposal };
};
