import { CampaignType, MarketplaceCampaignReport } from '@/graphql';
import { PaginationProps, Table } from '@/shared/atoms';
import { InfluencerPostDetailsModal } from '@/shared/molecules';
import { usePostReportContext } from '../PostReportContext';
import { usePostReportTable } from './hooks';
import { PostReportSegment } from './types';

export interface PostReportTableProps {
  loading?: boolean;
  segment: PostReportSegment;
  data: MarketplaceCampaignReport;
  pagination?: PaginationProps;
}

export const PostReportTable = ({ data, segment, pagination, loading }: PostReportTableProps) => {
  const { campaignType } = usePostReportContext();
  const { rows, columns, allPosts } = usePostReportTable({ data, segment });

  return (
    <>
      <Table
        loading={loading}
        pagination={pagination}
        noTopBorderRadius
        data={{ rows, columns, rowHeight: '64px' }}
        stickyHeader
        hasBorder
        maxHeight="820px"
      />

      <InfluencerPostDetailsModal posts={allPosts} hasTrafficSource={campaignType === CampaignType.ENGAGEMENT} />
    </>
  );
};
