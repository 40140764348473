import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { YoutubeAnalyticsTagRankingPayload } from '@/graphql';
import { useAnalyticsYoutubeTagsRankingListTable } from './hooks';

export interface AnalyticsYoutubeTagsRankingListProps extends TableData<YoutubeAnalyticsTagRankingPayload> {
  className?: string;
}

export const AnalyticsYoutubeTagsRankingList = ({
  loading,
  className,
  data = []
}: AnalyticsYoutubeTagsRankingListProps) => {
  const { rows, columns } = useAnalyticsYoutubeTagsRankingListTable({ data });

  return (
    <Table
      loading={loading}
      className={className}
      data={{ rows, columns, rowHeight: '40px' }}
      noTopBorderRadius
      hasBorder
    />
  );
};
