import { MarketplaceApplicantsFilterFormValues } from './schemaTypes';

export const defaultMarketplaceApplicantsFilterValues: MarketplaceApplicantsFilterFormValues = {
  minAge: '',
  maxAge: '',
  keyword: '',
  genders: [],
  categoryIds: [],
  minFollowers: '',
  maxFollowers: '',
  appliedStatus: '',
  minEngagementRate: '',
  maxEngagementRate: ''
};
