import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { DisabledFields } from '@/shared/types';
import {
  NameField,
  EmailField,
  ReasonField,
  RegionField,
  CountryField,
  GendersField,
  IntroduceField,
  ProAccountField,
  PhoneNumberField,
  DateOfBirthField,
  InfluencerCategoriesField
} from '../../shared';
import { TalentInfluencerFormKeys, TalentInfluencerFormValues } from '../schemaTypes';

interface InfluencerAccountProps {
  disabledFields?: DisabledFields<TalentInfluencerFormKeys>;
}

export const InfluencerAccount = ({ disabledFields }: InfluencerAccountProps) => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { defaultValues }
  } = useFormContext<TalentInfluencerFormValues>();

  const values = watch();
  const isEditMode = !!defaultValues?.id;

  return (
    <>
      <FormStyle.SectionTitle index={2} css={{ margin: '40px 0 0' }} label={t('Influencer Account')} />

      <NameField disabled={disabledFields?.name} required />

      <FormStyle.FieldsGroup itemsPerRow={2}>
        <InfluencerCategoriesField disabled={disabledFields?.categoryIds} required />
        <GendersField disabled={disabledFields?.gender} required />
        <CountryField disabled={disabledFields?.countryId} hasAllAvailableCountries required />
        <RegionField countryId={values.countryId} required />
        <EmailField disabled={disabledFields?.email} required />
        <PhoneNumberField disabled={disabledFields?.phoneNumber} />
      </FormStyle.FieldsGroup>

      <DateOfBirthField required />
      <IntroduceField disabled={disabledFields?.introduce} />
      <ReasonField disabled={disabledFields?.introduce} />
      {isEditMode ? <ProAccountField disabled={disabledFields?.isProAccount} /> : null}
    </>
  );
};
