import { Navigate } from '@tanstack/react-router';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';
import { useAnalyticsProfileContext } from '../AnalyticsProfileContext';
import { AnalyticsCompareInstagram } from './AnalyticsCompareInstagram';
import { AnalyticsCompareYoutube } from './AnalyticsCompareYoutube';

export const AnalyticsCompare = () => {
  const {
    selectedSocialAccount,
    brandAccount: { subscriptionPlan }
  } = useAnalyticsProfileContext();
  const hasFreePlan = subscriptionPlan.availableFeatures?.compareEnabled === false;

  switch (selectedSocialAccount.socialMedia) {
    case AnalyticsSupportedSocialMediaType.youtube:
      return <AnalyticsCompareYoutube hasFreePlan={hasFreePlan} />;
    case AnalyticsSupportedSocialMediaType.instagram:
      return <AnalyticsCompareInstagram hasFreePlan={hasFreePlan} />;
    default:
      return <Navigate to="/analytics" />;
  }
};
