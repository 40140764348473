import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AddYoutubeCompareAccountAnalytics.graphql';
import { Mutation, MutationaddYoutubeCompareAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useAddYoutubeCompareAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationaddYoutubeCompareAccountAnalyticsArgs>
) => {
  const [callAddYoutubeCompareAccountAnalytics, result] = useMutation<
    Mutation,
    MutationaddYoutubeCompareAccountAnalyticsArgs
  >(MUTATION, options);

  return { callAddYoutubeCompareAccountAnalytics, ...result };
};

export type AddYoutubeCompareAccountAnalyticsMutationFunction = Mutation['addYoutubeCompareAccountAnalytics'];
