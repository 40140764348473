import { createFileRoute, redirect } from '@tanstack/react-router';
import { packageStaticData } from '../../../package';
export const Route = createFileRoute('/_private-routes/package/$id/content/list')({
  staticData: packageStaticData,
  beforeLoad: ({
    params
  }) => {
    if (!params.id) {
      return redirect({
        to: '/package'
      });
    }
  }
});