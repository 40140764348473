import { z } from 'zod';
import { SocialAccountType } from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';

// TODO: Update translation key to AllTranslationKeys.Error.<message> instead of AllTranslationKeys.<message>
export const EngagementPostSchema = z
  .object({
    id: z.number().optional(),
    postUrl: z.string(),
    content: z.string(),
    isTtcmForm: z.boolean(),
    planedPostDate: z.string(),
    insightUrls: z.array(z.string()),
    materialsUrl: z.array(z.string()),
    insightDataAcquisition: z.string(),
    influencerId: z.string().min(1, AllTranslationKeys.requiredInfluencerMessage),
    // Social account field
    socialAccountId: z.string(),
    socialAccountMedia: z.nativeEnum(SocialAccountType).or(z.literal('')),
    selectedAccount: z.string().min(1, AllTranslationKeys.requiredSocialAccountId)
  })
  .superRefine((values, ctx) => {
    if (!values.isTtcmForm && !values.planedPostDate) {
      ctx.addIssue({
        path: ['planedPostDate'],
        code: z.ZodIssueCode.custom,
        message: AllTranslationKeys.requiredPlanedPostDateMessage
      });
    }
  });
