import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template } from './Template';
import { useAnalyticsTrendingYoutubePostsFilter } from './hooks';

export const AnalyticsTrendingYoutubePostsFilter = () => {
  const { filter, setFilterForm } = useAnalyticsTrendingYoutubePostsFilter();

  return (
    <FilterWrapper>
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template />} />
    </FilterWrapper>
  );
};

export { useAnalyticsTrendingYoutubePostsFilter };
export * from './schemaTypes';
