import { FormProvider } from '@/shared/atoms';
import { ToggleOption } from '@/shared/types';
import { PartnerFormSchema } from './schema';
import { Template } from './Template';
import { PartnerFormProps, PartnerFormValues } from './types';

export const PartnerForm = ({ onSubmit, defaultValues }: PartnerFormProps) => (
  <FormProvider<PartnerFormValues>
    onSubmit={onSubmit}
    defaultValues={{
      id: defaultValues?.id,
      logo: defaultValues?.logo,
      countryId: defaultValues?.countryId || '',
      agencyIds: defaultValues?.agencyIds || [],
      companyUrl: defaultValues?.companyUrl || '',
      netsuiteId: defaultValues?.netsuiteId || '',
      phoneNumber: defaultValues?.phoneNumber || '',
      advertiserIds: defaultValues?.advertiserIds || [],
      partnerAgencyName: defaultValues?.partnerAgencyName || '',
      privateInfluencersOptions: defaultValues?.privateInfluencersOptions || [],
      influencerCostPermission: defaultValues?.influencerCostPermission || ToggleOption.TRUE
    }}
    zodSchema={PartnerFormSchema}
  >
    <Template />
  </FormProvider>
);

export * from './types';
