import { useTranslation } from 'react-i18next';
import { UserRoles } from '@/graphql';
import { useUserRoles } from '@/auth';
import { HELP_PAGE_LINK } from '@/shared/constants';

export const useGetHelpPageUrl = () => {
  const { role } = useUserRoles();
  const { i18n } = useTranslation();
  const isJa = i18n.language === 'ja';

  switch (role) {
    case UserRoles.PARTNER:
    case UserRoles.TALENT_AGENCY:
      return isJa ? HELP_PAGE_LINK.JA.PARTNER : HELP_PAGE_LINK.EN.PARTNER;

    case UserRoles.ADVERTISER:
    case UserRoles.AGENCY:
      return isJa ? HELP_PAGE_LINK.JA.ADVERTISER : HELP_PAGE_LINK.EN.ADVERTISER;

    default:
      return isJa ? HELP_PAGE_LINK.JA.DEFAULT : HELP_PAGE_LINK.EN.DEFAULT;
  }
};
