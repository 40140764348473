import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { useSelectItemIds } from '@/shared/hooks';
import { AutoManagedCampaignInfoPayload } from '@/graphql';
import { ActionBar } from './ActionBar';
import { useAutoManagedCampaignsTable } from './hooks';

export type AutoManagedCampaignsListProps = TableData<AutoManagedCampaignInfoPayload>;

export const AutoManagedCampaignsList = ({ loading, data = [] }: AutoManagedCampaignsListProps) => {
  const { selectItem, selectedItemIds, hasAllItemsSelected, setSelectedItemIds, toggleSelectAllItems } =
    useSelectItemIds({ allItemIds: data.map((campaign) => campaign.id) });
  const { rows, columns } = useAutoManagedCampaignsTable({
    data,
    selectItem,
    selectedItemIds,
    hasAllItemsSelected,
    toggleSelectAllItems
  });

  return (
    <div css={{ position: 'relative' }}>
      <ActionBar
        selectedItemIds={selectedItemIds}
        setSelectedItemIds={setSelectedItemIds}
        hasAllItemsSelected={hasAllItemsSelected}
        toggleSelectAllItems={toggleSelectAllItems}
      />

      <Table loading={loading} data={{ rows, columns }} noTopBorderRadius hasBorder />
    </div>
  );
};
