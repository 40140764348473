import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramUGCManagementPostDetails.graphql';
import { Query, QueryinstagramUGCManagementPostDetailsArgs } from '../../__generated__/globalTypes';

export const useInstagramUGCManagementPostDetailsQuery = (
  options?: QueryHookOptions<Query, QueryinstagramUGCManagementPostDetailsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramUGCManagementPostDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramUGCManagementPostDetailsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramUGCManagementPostDetailsArgs>
): QueryResult<Query, QueryinstagramUGCManagementPostDetailsArgs> & {
  getInstagramUGCManagementPostDetails: LazyQueryExecFunction<Query, QueryinstagramUGCManagementPostDetailsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramUGCManagementPostDetails, result] = useLazyQuery<
    Query,
    QueryinstagramUGCManagementPostDetailsArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInstagramUGCManagementPostDetails, ...result };
};

export const useInstagramUGCManagementPostDetailsPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramUGCManagementPostDetails = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramUGCManagementPostDetailsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramUGCManagementPostDetailsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramUGCManagementPostDetails };
};
