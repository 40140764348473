import { useFormContext } from 'react-hook-form';
import { Button, FormStyle } from '@/shared/atoms';
import { useAllAvailableCountryOptions, useQueryHelper, useToggle } from '@/shared/hooks';
import { TextField, PasswordField, SelectorField, SwitchToggleField } from '@/shared/molecules';
import { TikTokSettingsFormKeys, TikTokSettingsFormValues } from './types';

export interface TikTokSettingsTemplateFormProps {
  onOpenPasswordModal: () => void;
}
export const Template = ({ onOpenPasswordModal }: TikTokSettingsTemplateFormProps) => {
  const modalState = useToggle();
  const { t } = useQueryHelper();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions({ hasUSCountry: true });
  const {
    formState: { isSubmitting }
  } = useFormContext<TikTokSettingsFormValues>();

  return (
    <div>
      <FormStyle.Wrapper css={{ flex: 1, padding: '24px' }}>
        <FormStyle.SectionTitle label={t('Company Information')} css={{ fontWeight: 600 }} />

        <TextField<TikTokSettingsFormKeys>
          name="advertiserName"
          placeholder="AnyMindgroup"
          title={t('TextForm.Company Name')}
          required
        />

        <TextField<TikTokSettingsFormKeys>
          name="advertiserCompanyUrl"
          title={t('TextForm.Company URL')}
          placeholder="https://anytag.tech"
          required
        />

        <FormStyle.FieldsGroup>
          <TextField<TikTokSettingsFormKeys>
            placeholder="03-1111-1111"
            name="advertiserPhoneNumber"
            title={t('TextForm.Phone Number')}
            required
          />

          <SelectorField<TikTokSettingsFormKeys>
            name="advertiserCountryId"
            title={t('Selector.Country')}
            required
            // // https://adasiaholdings.atlassian.net/wiki/spaces/NC/pages/3567255574/Onboarding+process+for+TikTok+collab+service#%E2%91%A0Add-information
            disabled
            options={allAvailableCountryOptions.filter((option) => option.value === 'TH')}
          />
        </FormStyle.FieldsGroup>

        <FormStyle.SectionTitle css={{ marginTop: '40px' }} label={t('User Information')} />

        <TextField<TikTokSettingsFormKeys> name="name" title={t('TextForm.Name')} placeholder="Naoya Kurita" required />

        <FormStyle.FieldsGroup>
          <TextField<TikTokSettingsFormKeys>
            name="email"
            placeholder="myemail@gmail.com"
            title={t('TextForm.Email Address')}
            required
          />

          <SelectorField<TikTokSettingsFormKeys>
            name="countryId"
            title={t('Selector.Country')}
            options={allAvailableCountryOptions}
            required
          />
        </FormStyle.FieldsGroup>

        <FormStyle.FieldsGroup css={{ alignItems: 'flex-end' }}>
          <PasswordField<TikTokSettingsFormKeys>
            name="password"
            title={t('TextForm.Password')}
            placeholder={t<string>('Password.Your Password')}
            required
            disabled
          />

          <FormStyle.FieldWrapper css={{ flex: 1 }}>
            <Button
              variant="white"
              onClick={onOpenPasswordModal}
              title={t('Button.Change Password')}
              css={{ opacity: modalState.on ? 0 : 1, textTransform: 'uppercase', padding: '0 24px' }}
            />
          </FormStyle.FieldWrapper>
        </FormStyle.FieldsGroup>

        <SwitchToggleField<TikTokSettingsFormKeys>
          name="notificationSetting"
          title={t('TextForm.Notification Settings')}
          help={t('Tooltip.Notification for Advertiser settings help')}
        />

        <FormStyle.ButtonSubmit
          title={t('Button.Save')}
          loading={{ status: isSubmitting, title: t('Button.Saving') }}
          css={{ minWidth: '146px', marginLeft: 'auto', textTransform: 'uppercase' }}
        />
      </FormStyle.Wrapper>
    </div>
  );
};
