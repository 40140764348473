import { useTranslation } from 'react-i18next';
import { useAllCountryOptions } from '@/shared/hooks';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { PackagesFilterFormKeys } from './schemaTypes';

interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { allCountryOptions } = useAllCountryOptions();

  return (
    <BasicFilterWrapper>
      <TextSearchField<PackagesFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.InfluencerPackageFilter')}
      />

      <SelectorField<PackagesFilterFormKeys>
        name="countryIds"
        onSubmit={onSubmit}
        css={{ maxWidth: '138px' }}
        options={allCountryOptions}
        placeholder={t<string>('Selector.Countries')}
        multiple
      />
    </BasicFilterWrapper>
  );
};
