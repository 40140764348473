import { renderToStaticMarkup } from 'react-dom/server';
import { NoDataContentWrapper, ChartTooltipSingleMetric, NoDataContentWrapperProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { HighChart, Highcharts } from '../HighChart';

export interface AgeGenderChartProps
  extends Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'> {
  ageGroup: string[];
  maleRate?: readonly number[] | null;
  femaleRate?: readonly number[] | null;
}

export const AgeGenderChart = ({ ageGroup, maleRate, femaleRate, ...noDataContentProps }: AgeGenderChartProps) => {
  const options: Highcharts.Options = {
    legend: { enabled: false },
    chart: { type: 'bar', height: 220 },
    plotOptions: { series: { stacking: 'normal' } },
    yAxis: { title: { text: null }, labels: { enabled: false } },
    title: {
      text: '',
      margin: 16,
      style: { fontSize: '13px', fontWeight: '600', fontFamily: 'Inter' }
    },
    xAxis: [
      {
        reversed: false,
        categories: ageGroup,
        labels: { step: 1 }
      },
      {
        linkedTo: 0,
        opposite: true,
        reversed: false,
        categories: ageGroup,
        labels: { step: 1, enabled: false }
      }
    ],
    series: [
      {
        type: 'bar',
        name: 'Female',
        color: THEME.chart.colors.gender.female,
        data: femaleRate?.map((item) => item * -100)
      },
      {
        type: 'bar',
        name: 'Male',
        color: THEME.chart.colors.gender.male,
        data: maleRate?.map((item) => item * 100)
      }
    ],
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      pointFormat: '',
      headerFormat: '',
      borderColor: THEME.chart.tooltip.border.colors.black,
      backgroundColor: THEME.chart.tooltip.background.colors.black,
      style: { color: THEME.chart.tooltip.text.colors.white },
      formatter() {
        return renderToStaticMarkup(
          <ChartTooltipSingleMetric
            color={this.color as string}
            name={`${this.series.name} ${this.key}`}
            value={`${Highcharts.numberFormat(Math.abs(this.y || 0), 1)}%`}
          />
        );
      }
    }
  };

  return (
    <NoDataContentWrapper
      css={{ width: '100%', height: 'unset', minHeight: '220px' }}
      dataNotAvailable={!(maleRate?.length && femaleRate?.length)}
      {...noDataContentProps}
    >
      <HighChart options={options} />
    </NoDataContentWrapper>
  );
};
