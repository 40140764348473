import { useTranslation } from 'react-i18next';
import { EngagementCampaignPostStatusForInsightCheck } from '@/graphql';
import { Status, StatusProps } from '@/shared/atoms';
import { ColorVariant } from '@/shared/types';

export interface PostInsightStatusProps {
  status?: EngagementCampaignPostStatusForInsightCheck;
  className?: string;
}

export const PostInsightStatus = ({ status, className }: PostInsightStatusProps) => {
  const { t } = useTranslation();

  const statusProps = ((): StatusProps => {
    switch (status) {
      case EngagementCampaignPostStatusForInsightCheck.CONFIRMED:
        return { label: t('Option.Confirmed'), variant: ColorVariant.LIGHT_BLUE };
      case EngagementCampaignPostStatusForInsightCheck.UNCONFIRMED:
        return { label: t('Option.Unconfirmed'), variant: ColorVariant.LIGHT_YELLOW };
      default:
        return { label: '', variant: ColorVariant.DARK_GREY };
    }
  })();

  return <Status {...statusProps} css={{ padding: '0 8px' }} className={className} />;
};
