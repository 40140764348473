import { useTranslation } from 'react-i18next';
import { TableData } from '@/shared/types';
import { useTableMaxHeight } from '@/shared/hooks';
import { Table, Modal, Button, BlackBar, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { YoutubeCMSAsset } from '../types';
import { useDeleteYoutubeChannelAssetOwner } from '../hooks';
import { useYoutubeCMSAssetsTable } from './hooks';

export type YoutubeCMSAssetsListProps = TableData<YoutubeCMSAsset> &
  Pick<Parameters<typeof useYoutubeCMSAssetsTable>[0], 'type' | 'modifiable'>;

export const YoutubeCMSAssetsList = ({ type, loading, modifiable, data = [] }: YoutubeCMSAssetsListProps) => {
  const { t } = useTranslation();
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();
  const { deleting, deletingAssetOwner, setDeletingAssetOwner, resetDeletingAssetOwner, handleDeleteAssetOwner } =
    useDeleteYoutubeChannelAssetOwner();
  const { rows, saving, columns, hasRowChange, handleSaveChange } = useYoutubeCMSAssetsTable({
    data,
    type,
    modifiable,
    deletingAssetOwner,
    onSetDeletingAssetOwner: setDeletingAssetOwner
  });

  return (
    <>
      <div css={{ position: 'relative' }}>
        {hasRowChange ? (
          <BlackBar>
            <Button
              title={t('Button.Save')}
              variant="blue"
              loading={saving}
              id="skipOutsideClick"
              onClick={handleSaveChange}
              css={{ marginLeft: 'auto', padding: '0 16px' }}
            />
          </BlackBar>
        ) : null}

        <Table
          ref={offsetRef}
          loading={loading}
          maxHeight={defaultTableMaxHeight}
          data={{ columns, rows, rowHeight: '64px' }}
          noTopBorderRadius
          stickyHeader
        />
      </div>

      <Modal open={!!deletingAssetOwner} onClose={resetDeletingAssetOwner} css={{ width: '816px' }}>
        <ModalContent>
          <ModalTitle>{t('Are you sure you want to clear this owner?')}</ModalTitle>
          <p css={{ fontSize: '14px', marginTop: '24px' }}>{t('clearAssetOwnerDescription')}</p>
        </ModalContent>
        <ModalFooterActions
          cancelButtonProps={{ onClick: resetDeletingAssetOwner }}
          submitButtonProps={{ loading: deleting, title: t('Button.Remove'), onClick: handleDeleteAssetOwner }}
        />
      </Modal>
    </>
  );
};
