import { TikTokSpecialCampaignStatus } from '@/graphql';
import { TikTokCampaignTabsType } from './types';

export const getAccessTikTokCampaignTab = (
  tab: keyof typeof TikTokCampaignTabsType,
  status?: TikTokSpecialCampaignStatus
) => {
  if (!status) return false;

  switch (tab) {
    case 'Posts':
    case 'Report':
      return ![
        TikTokSpecialCampaignStatus.DRAFT,
        TikTokSpecialCampaignStatus.REVIEWING,
        TikTokSpecialCampaignStatus.UPCOMING
      ].includes(status);
    default:
      return true;
  }
};
