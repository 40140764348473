import { useAuthData } from '@/auth';
import { MenuTitle } from '@/router';
import { useMatchPaths } from './useMatchPaths';

declare const IS_DISABLED_ON_PROD: boolean;

// TODO: Add more specify accounts
// Hide for production but keep on staging: https://anymindgroup.slack.com/archives/C02C5J5B7KL/p1721709140480789
const TESTING_EMAILS = IS_DISABLED_ON_PROD ? [] : ['chat-demo@anymindgroup.com'];
export const checkDemoAccounts = (email?: string | null) => !!email && TESTING_EMAILS.includes(email);
// TODO DEMO: remove later
export const useChatDemo = () => {
  const { auth } = useAuthData();

  const { matched: isChatDemoPage } = useMatchPaths(['/chat-demo', '/chat-demo/chat']);

  const enabledMenus = [MenuTitle.ChatDemo, MenuTitle.Influencers, MenuTitle.SignOut];

  return {
    enabledMenus,
    isChatDemoPage,
    checkDemoAccounts,
    isChatDemoAccount: checkDemoAccounts(auth.email)
  };
};
