import { useTranslation } from 'react-i18next';
import { TextField, TextFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const CampaignMaterialLinkField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  ...restProps
}: Partial<TextFieldProps<T>>) => {
  const { t } = useTranslation();

  return (
    <TextField<T>
      placeholder="DL Link"
      help={t('Tooltip.CampaignMaterialLinkHelp')}
      name={name || (MarketplaceFormKeys.campaignMaterialLink as T)}
      title={t('TextForm.Campaign material link (Google drive, Dropbox etc.)')}
      {...restProps}
    />
  );
};
