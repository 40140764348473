import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TiktokAdCampaigns.graphql';
import { Query, QuerytiktokAdCampaignsArgs } from '../../__generated__/globalTypes';

export const useTiktokAdCampaignsQuery = (options?: QueryHookOptions<Query, QuerytiktokAdCampaignsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytiktokAdCampaignsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTiktokAdCampaignsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytiktokAdCampaignsArgs>
): QueryResult<Query, QuerytiktokAdCampaignsArgs> & {
  getTiktokAdCampaigns: LazyQueryExecFunction<Query, QuerytiktokAdCampaignsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTiktokAdCampaigns, result] = useLazyQuery<Query, QuerytiktokAdCampaignsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTiktokAdCampaigns, ...result };
};

export const useTiktokAdCampaignsPromiseQuery = () => {
  const client = useApolloClient();

  const getTiktokAdCampaigns = useCallback(
    (options?: Omit<QueryOptions<QuerytiktokAdCampaignsArgs, Query>, 'query'>) =>
      client.query<Query, QuerytiktokAdCampaignsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTiktokAdCampaigns };
};
