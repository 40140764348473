import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType } from '@/graphql';
import { defaultAvatarImage } from '@/shared/assets';
import { Anchor, SocialIcon, MediaPreview, SocialIconProps, MediaPreviewProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';

export interface AnalyticsProfileProps extends Pick<MediaPreviewProps, 'src' | 'objectFit'> {
  to?: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard';
  params?: {
    brandAccountId: string;
    socialMedia?: AnalyticsSupportedSocialMediaType;
    socialMediaAccountId: string;
  };
  size?: string;
  className?: string;
  disabled?: boolean;
  src?: string | null;
  isBrandAccount?: boolean;
  socialAccountType?: SocialAccountType | '';
  search?: {
    filter?: {
      id: string;
      sm?: SocialAccountType;
    };
  };
}

export const AnalyticsProfile = ({
  to,
  params,
  src,
  disabled,
  search,
  className,
  objectFit = 'contain',
  size = '32px',
  isBrandAccount,
  socialAccountType,
  ...restProps
}: AnalyticsProfileProps) => {
  const { t } = useTranslation();

  return (
    <Anchor
      to={to}
      params={params}
      search={search}
      disabled={disabled}
      css={styles.anchor(size)}
      className={className}
      {...restProps}
    >
      <MediaPreview
        src={src}
        alt="avatar"
        objectFit={objectFit}
        defaultImage={defaultAvatarImage}
        css={{ width: size, height: size, backgroundColor: 'inherit' }}
      />
      {socialAccountType ? (
        <div css={styles.socialIconWrapper}>
          <SocialIcon size="16px" type={socialAccountType as SocialIconProps['type']} css={{ display: 'block' }} />
        </div>
      ) : null}
      {isBrandAccount && <div css={styles.brandBadge}>{t('Option.Brand')}</div>}
    </Anchor>
  );
};

const styles = {
  anchor: (size: string) =>
    css({
      flex: 'none',
      position: 'relative',
      borderRadius: '5px',
      backgroundColor: THEME.background.colors.white,
      boxSizing: 'border-box',
      width: size,
      height: size
    }),
  socialIconWrapper: css({
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '4px',
    borderRadius: '4px 0 5px 0',
    backgroundColor: THEME.background.colors.white
  }),
  brandBadge: css({
    left: 0,
    bottom: 0,
    width: '100%',
    fontSize: '10px',
    lineHeight: '14px',
    padding: '2px',
    position: 'absolute',
    textAlign: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#27313b',
    borderRadius: '0 0 5px 5px',
    color: THEME.text.colors.white
  })
};
