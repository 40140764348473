import {
  AnalyticsTwitterDashboardOverview,
  AnalyticsTwitterDashboardFeedPosts,
  AnalyticsTwitterDashboardPostsOverview
} from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';

export const AnalyticsTwitterDashboard = () => {
  const { brandAccount, selectedSocialAccount } = useAnalyticsProfileContext();

  return (
    <>
      <AnalyticsTwitterDashboardOverview analyticsAccount={selectedSocialAccount} />
      <AnalyticsTwitterDashboardPostsOverview analyticsAccount={selectedSocialAccount} />
      <AnalyticsTwitterDashboardFeedPosts
        analyticsAccount={selectedSocialAccount}
        brandAccountName={brandAccount.brandAccountName}
      />
    </>
  );
};
