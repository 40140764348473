import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RejectTiktokSpecialCampaign.graphql';
import { Mutation, MutationrejectTiktokSpecialCampaignArgs } from '../../__generated__/globalTypes';

export const useRejectTiktokSpecialCampaignMutation = (
  options?: MutationHookOptions<Mutation, MutationrejectTiktokSpecialCampaignArgs>
) => {
  const [callRejectTiktokSpecialCampaign, result] = useMutation<Mutation, MutationrejectTiktokSpecialCampaignArgs>(
    MUTATION,
    options
  );

  return { callRejectTiktokSpecialCampaign, ...result };
};

export type RejectTiktokSpecialCampaignMutationFunction = Mutation['rejectTiktokSpecialCampaign'];
