import { useTranslation } from 'react-i18next';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import {
  TiktokBusinessWarningStatus,
  useTiktokBusinessAccountInfoQuery,
  useTiktokBusinessAdAccountsQuery
} from '@/graphql';
import { TikTokAdAccount, useTikTokAdvertiserSummaryData } from '@/shared/organisms';
import { ButtonLink } from '@/shared/atoms';
import { animatedArrows } from '@/shared/assets';

export const TikTokBusinessAccountCenter = () => {
  useUpdateDocumentTitle({
    title: 'appHeader.TikTok Ad Account',
    appHeader: 'appHeader.TikTok Ad Account',
    href: '/tiktok-onboarding/select-plan',
    alwaysBackToParent: true
  });
  const { t } = useTranslation();
  const { data } = useTiktokBusinessAccountInfoQuery();
  const { data: adData } = useTiktokBusinessAdAccountsQuery();
  const { summaryData } = useTikTokAdvertiserSummaryData();

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <TikTokAdAccount
        isOnboardingFlow
        tiktokBusinessAdAccounts={adData?.tiktokBusinessAdAccounts}
        tiktokBusinessAccountInfo={data?.tiktokBusinessAccountInfo}
        hasAccountsLimitWarning={summaryData?.warningsList.includes(
          TiktokBusinessWarningStatus.REACH_ADS_ACCOUNTS_LIMIT
        )}
      />
      {adData?.tiktokBusinessAdAccounts?.length ? (
        <ButtonLink
          variant="coralRed"
          title={t("Button.Let's start")}
          to="/tiktok/ad-campaign-report"
          css={{
            width: 'max-content',
            padding: '0 16px 0 24px',
            marginInlineStart: 'auto'
          }}
        >
          <img
            src={animatedArrows}
            css={{ width: '32px', height: '32px', rotate: '270deg' }}
            alt="3 animated arrows pointing to the right"
          />
        </ButtonLink>
      ) : null}
    </div>
  );
};
