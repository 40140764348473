import {
  InfluencerYoutubeProfileMentionedAccounts,
  InfluencerYoutubeProfileMentionedAccountsProps
} from '../../InfluencerProfiles';

type InfluencerAnalyticsYoutubeProfileMentionedAccountsProps = InfluencerYoutubeProfileMentionedAccountsProps;

export const InfluencerAnalyticsYoutubeProfileMentionedAccounts = (
  props: InfluencerAnalyticsYoutubeProfileMentionedAccountsProps
) => (
  <InfluencerYoutubeProfileMentionedAccounts
    {...props}
    wrapperLink={{ url: '' }}
    wrapperText="No brand mentioned posts to show"
  />
);
