import { z } from 'zod';
import { SocialAccountStatus, TalentInfluencerDetailsForEditPayload } from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';
import { getSchemaForType } from '@/shared/types';

export const SocialAccountsTaSchema = getSchemaForType<
  Pick<
    TalentInfluencerDetailsForEditPayload,
    'connectedFacebook' | 'connectedFacebookPages' | 'connectedInstagrams' | 'connectedTwitters' | 'connectedYouTubes'
  >
>()(
  z
    .object({
      connectedFacebook: z
        .object({
          fbName: z.string(),
          fbUserId: z.string().nullish(),
          status: z.nativeEnum(SocialAccountStatus)
        })
        .nullish(),
      connectedFacebookPages: z
        .array(
          z.object({
            pageId: z.string().nullish(),
            pageName: z.string(),
            status: z.nativeEnum(SocialAccountStatus)
          })
        )
        .readonly(),
      connectedInstagrams: z
        .array(
          z.object({
            accountId: z.number(),
            igName: z.string(),
            igUserId: z.string().nullish(),
            pageId: z.string().nullish(),
            pageName: z.string().nullish(),
            status: z.nativeEnum(SocialAccountStatus)
          })
        )
        .readonly(),
      connectedTwitters: z
        .array(
          z.object({
            twName: z.string(),
            twUserId: z.string().nullable().optional(),
            status: z.nativeEnum(SocialAccountStatus)
          })
        )
        .readonly(),
      connectedYouTubes: z
        .array(
          z.object({
            channelId: z.string(),
            channelName: z.string(),
            status: z.nativeEnum(SocialAccountStatus)
          })
        )
        .readonly()
    })
    .refine(
      (v) =>
        !!(
          v.connectedFacebook?.fbName ||
          v.connectedFacebookPages.length ||
          v.connectedInstagrams.length ||
          v.connectedTwitters.length ||
          v.connectedYouTubes.length
        ),
      {
        path: ['connectedFacebook'],
        message: AllTranslationKeys.Error.atLeastOneConnectedSocialMedia
      }
    )
);
