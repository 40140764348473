import { TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TextField, TextFieldProps } from '@/shared/molecules';

export const SC = {
  TextField: <T extends string = string>({ title, ...restProps }: TextFieldProps<T>) => (
    <TextField<T>
      {...restProps}
      css={{ alignSelf: 'flex-end' }}
      title={title && typeof title === 'string' ? <TextCutter text={title} lines={0} /> : title}
    />
  ),
  SectionWrapper: (props: React.HTMLAttributes<HTMLDivElement>) => (
    <div css={{ borderBottom: THEME.border.base, '&:not(:first-of-type)': { marginTop: '16px' } }} {...props} />
  ),
  SectionTitle: (props: React.HTMLAttributes<HTMLParagraphElement>) => (
    <p css={{ fontWeight: 600, fontSize: '14px', marginBottom: '4px' }} {...props} />
  )
};
