import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { CampaignTrackingOption, CampaignSocialMediaType, NewCampaignReportSummary } from '@/graphql';
import { ReportSummary, ReportSummaryType } from '@/shared/atoms';
import { formatIntNumber } from '@/shared/utils';
import { usePostReportContext } from '../PostReportContext';

export interface PostReportSummaryProps {
  currency?: string;
  data?: NewCampaignReportSummary;
  socialMedias: readonly CampaignSocialMediaType[];
  trackingOptions: readonly CampaignTrackingOption[];
}

export const PostReportSummary = ({ data, socialMedias, trackingOptions, currency = '' }: PostReportSummaryProps) => {
  const { t } = useTranslation();
  const { hasTracking } = usePostReportContext();

  const hasSalesValue = !!data?.sales; // TODO: Deprecated
  const summaries: ReportSummaryType[] = [
    { label: t<string>('total post'), value: formatIntNumber(data?.postCount) },
    { label: t<string>('cost'), value: formatIntNumber(data?.cost), unit: t<string>(currency) },
    { label: t<string>('engagement'), value: formatIntNumber(data?.engagement) },
    {
      value: formatIntNumber(data?.like),
      label: t<string>(data?.campaignSocialMedias[0] === CampaignSocialMediaType.FACEBOOK ? 'reaction' : 'like')
    },
    {
      value: formatIntNumber(data?.comment),
      label: t<string>(
        socialMedias.length === 1 &&
          [CampaignSocialMediaType.INSTAGRAM_STORY, CampaignSocialMediaType.THREADS].includes(
            socialMedias.at(0) as CampaignSocialMediaType
          )
          ? 'General.Replies'
          : 'comment'
      )
    },
    ...(hasTracking
      ? [
          { value: formatIntNumber(data?.conversions), label: t<string>(hasSalesValue ? 'Conversions' : 'Actions') },
          ...(hasSalesValue
            ? [{ value: formatIntNumber(data?.sales), label: t<string>('Sales'), unit: t<string>(currency) }]
            : [])
        ]
      : [])
  ];

  return <ReportSummary summaries={summaries} css={css({ '> div': { padding: '0 48px 0 16px' } })} />;
};
