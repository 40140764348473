import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { formatPrice } from '@/shared/utils';
import { getPlanName } from '../../Billing/utils';
import { useTikTokAdvertiserSummaryData } from '../../hooks';

interface SelectedPlanInfoProps {
  className?: string;
}
export const SelectedPlanInfo = ({ className }: SelectedPlanInfoProps) => {
  const { t } = useTranslation();
  const { summaryData } = useTikTokAdvertiserSummaryData();

  return (
    <div css={styles.wrapper} className={className}>
      <h3 css={{ fontWeight: 'bold', fontSize: '18px' }}>{t('TikTok Special Plan')}</h3>
      <div css={{ display: 'flex', marginTop: '24px' }}>
        <div css={styles.planSection}>
          <span css={{ fontSize: '14px' }}>{t('Subscription plan')}</span>
          <span css={{ fontWeight: 600 }}>{getPlanName(summaryData?.subscriptionPlan)}</span>
        </div>
        <div css={styles.planSection}>
          <span css={{ fontSize: '14px' }}>{t('Plan Cost')}</span>
          <span css={{ fontWeight: 600 }}>
            {formatPrice(summaryData?.planPrice, summaryData?.currencyId)} {summaryData?.currencyId}
          </span>
        </div>
        <div css={styles.planSection}>
          <span css={{ fontSize: '14px' }}>{t('Prepaid Balance')}</span>
          <span css={{ fontWeight: 600 }}>
            {formatPrice(summaryData?.prepaidBalance, summaryData?.currencyId)} {summaryData?.currencyId}
          </span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    padding: '24px',
    backgroundColor: THEME.background.colors.white,
    borderRadius: '3px',
    boxShadow: THEME.shadows.boxShadow
  }),
  planSection: css({ display: 'flex', flexDirection: 'column', flex: 1, gap: '4px' })
};
