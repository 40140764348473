import React, { createContext, useContext } from 'react';
import { ListWithPagination } from '@/shared/molecules';
import { EngagementCampaignSearchSelectInfluencers } from '@/graphql';
import { useInfluencerParamsSearch } from '../hooks';
import { InfluencersContextReturnType } from '../types';
import { useCampaignInfluencersList } from './hooks';
import {
  CampaignInfluencersProps,
  CampaignInfluencersFilterFormValues,
  CampaignInfluencersSearchSchemaType
} from './schemaTypes';

interface ContextType
  extends InfluencersContextReturnType<EngagementCampaignSearchSelectInfluencers, CampaignInfluencersFilterFormValues> {
  campaignId: number;
}

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  value: CampaignInfluencersProps;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const CampaignInfluencersProvider = ({
  children,
  value: { campaignId, isTtcmCampaign, isPackagePage, isEngagementPage }
}: Props) => {
  const paramsSearch = useInfluencerParamsSearch<CampaignInfluencersSearchSchemaType>();
  const { page, limit, filter } = paramsSearch;
  const listProps = useCampaignInfluencersList({ page, limit, filter, campaignId });

  return (
    <Context.Provider
      value={{
        ...paramsSearch,
        ...listProps,
        campaignId,
        isTtcmCampaign,
        isEngagementPage,
        sort: { field: null, order: null } // We don't support search for this page
      }}
    >
      <ListWithPagination pagination={{ totalRecords: listProps.totalRecords }}>{children}</ListWithPagination>
    </Context.Provider>
  );
};

export const useCampaignInfluencersContext = () => useContext<ContextType>(Context);
