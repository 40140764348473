import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, get, FieldError } from 'react-hook-form';
import { Option } from '@/shared/types';
import { THEME } from '@/shared/constants';
import { formatDynamicErrorMessage } from '@/shared/utils';
import { ErrorMessage, FieldWrapperProps, FormStyle, Label, TextCutter } from '@/shared/atoms';
import { DefaultFieldProps } from '../types';

export interface RHFFieldWrapperProps<T extends string, V = string, O = Option>
  extends Partial<Omit<DefaultFieldProps<T, V, O>, 'onChange' | 'onSubmit'>>,
    Pick<FieldWrapperProps, 'onMouseLeave' | 'onMouseEnter'> {
  hideError?: boolean; // TODO: Currently selector has different flows a bit. Will update after refactor those components
  children: ReactElement | ReactElement[] | ((props: { hasError?: boolean; errorMessage?: string }) => ReactNode);
}

export const RHFFieldWrapper = <T extends string, V = string, O = Option>({
  name,
  help,
  note,
  title,
  required,
  optional,
  children,
  className,
  hideError,
  customError,
  labelLoader,
  dynamicError,
  hasBorderBottom,
  onMouseEnter,
  onMouseLeave
}: RHFFieldWrapperProps<T, V, O>) => {
  const { t } = useTranslation();
  const {
    formState: { errors }
  } = useFormContext<Record<string, string>>();

  const error = (() => {
    let message = undefined;

    if (name) {
      message = (get(errors, name) as FieldError)?.message;
    }

    return message ? (dynamicError ? formatDynamicErrorMessage(t, message) : t<string>(message)) : message;
  })();
  const hasError = !!error;
  const errorMessage = hasError ? customError || error : undefined;

  return (
    <FormStyle.FieldWrapper {...{ className, hasBorderBottom, onMouseEnter, onMouseLeave }}>
      {!!title && <Label {...{ help, title, required, optional, labelLoader }} />}
      {note && <TextCutter text={note} css={{ marginBottom: '4px', color: THEME.text.colors.red.lv1 }} lines={1} />}
      {Array.isArray(children)
        ? children
        : typeof children === 'function'
          ? children({ hasError, errorMessage })
          : // auto pass hasError to children
            React.cloneElement(children, { ...children.props, hasError })}
      {!hideError && <ErrorMessage message={errorMessage} />}
    </FormStyle.FieldWrapper>
  );
};
