import { useAllAdvertisersForSearchLazyQuery } from '@/graphql';
import { AdvertiserOption } from '../types';
import { SingleCreatableSelect, SingleCreatableSelectProps } from './SingleCreatableSelect';

export interface AdvertiserSearchSelectorProps extends Omit<SingleCreatableSelectProps, 'loadOptions'> {
  countryId?: string;
}

export const AdvertiserSearchSelector = (props: AdvertiserSearchSelectorProps) => {
  const { getAllAdvertisersForSearch } = useAllAdvertisersForSearchLazyQuery();

  const promiseOptions = async (inputValue: string): Promise<AdvertiserOption[] | PromiseLike<AdvertiserOption>[]> =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await getAllAdvertisersForSearch({
          variables: { keyword: inputValue, countryId: props?.countryId }
        });
        const formattedData =
          data?.allAdvertisersForSearch.advertisers.map(
            ({ id, name, companyUrl, country, phoneNumber, hubspotId }) => ({
              value: id.toString(),
              label: name,
              companyUrl,
              countryId: country.id,
              phoneNumber,
              hubspotId
            })
          ) || [];

        resolve(formattedData);
      }, 500);
    });

  return <SingleCreatableSelect {...props} loadOptions={promiseOptions} />;
};
