import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/PackageProposalSummary.graphql';
import { Query, QuerypackageProposalSummaryArgs } from '../../__generated__/globalTypes';

export const usePackageProposalSummaryQuery = (options?: QueryHookOptions<Query, QuerypackageProposalSummaryArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerypackageProposalSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const usePackageProposalSummaryLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerypackageProposalSummaryArgs>
): QueryResult<Query, QuerypackageProposalSummaryArgs> & {
  getPackageProposalSummary: LazyQueryExecFunction<Query, QuerypackageProposalSummaryArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getPackageProposalSummary, result] = useLazyQuery<Query, QuerypackageProposalSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getPackageProposalSummary, ...result };
};

export const usePackageProposalSummaryPromiseQuery = () => {
  const client = useApolloClient();

  const getPackageProposalSummary = useCallback(
    (options?: Omit<QueryOptions<QuerypackageProposalSummaryArgs, Query>, 'query'>) =>
      client.query<Query, QuerypackageProposalSummaryArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getPackageProposalSummary };
};
