import {
  EngagementDetail,
  EngagementInfluencerBudgetInput,
  EngagementProposal,
  EngagementSocialAcccountBudgetInput,
  ProposalInfluencer
} from '@/graphql';
import { THEME } from '@/shared/constants';
import { ProposalActions } from './ProposalActions';
import { ProposalInfluencers } from './ProposalInfluencers';
import { ProposalSummary } from './ProposalSummary';

export interface EngagementCampaignProposalProps {
  engagement: EngagementDetail;
  hasTrackingReferral?: boolean;
  engagementProposal: EngagementProposal;
  hasInfluencerCostPermission?: boolean;
}

export const EngagementCampaignProposal = ({
  engagement,
  hasTrackingReferral,
  engagementProposal,
  hasInfluencerCostPermission
}: EngagementCampaignProposalProps) => {
  const { selectedInfluencers, rejectedInfluencers } = engagementProposal.influencers.reduce<{
    rejectedInfluencers: ProposalInfluencer[];
    selectedInfluencers: ProposalInfluencer[];
  }>(
    (rs, infl) => ({
      rejectedInfluencers: infl.isDenied ? [...rs.rejectedInfluencers, infl] : rs.rejectedInfluencers,
      selectedInfluencers: !infl.isDenied ? [...rs.selectedInfluencers, infl] : rs.selectedInfluencers
    }),
    {
      selectedInfluencers: [],
      rejectedInfluencers: []
    }
  );
  const formattedInfluencers = selectedInfluencers.map<EngagementInfluencerBudgetInput>(
    ({ id, summary: { netBudget, influencerCost, profit } }) => ({
      influencerId: id,
      profit: profit || 0,
      netBudget: netBudget || 0,
      influencerCost: influencerCost || 0
    })
  );
  const formattedRejectedInfluencers = rejectedInfluencers.map<EngagementInfluencerBudgetInput>(
    ({ id, summary: { netBudget, influencerCost, profit } }) => ({
      influencerId: id,
      profit: profit || 0,
      netBudget: netBudget || 0,
      influencerCost: influencerCost || 0
    })
  );
  const formattedSocialAccounts = selectedInfluencers.reduce<EngagementSocialAcccountBudgetInput[]>((rs, infl) => {
    const accounts = infl.accountList.map<EngagementSocialAcccountBudgetInput>(
      ({ id, socialMedia, summary: { netBudget, influencerCost, profit } }) => ({
        socialAccountId: id,
        influencerId: infl.id,
        socialAccountMedia: socialMedia,
        profit: profit || 0,
        netBudget: netBudget || 0,
        influencerCost: influencerCost || 0
      })
    );

    return [...rs, ...accounts];
  }, []);

  return (
    <div css={{ boxShadow: THEME.shadows.boxShadow }}>
      <ProposalActions
        engagement={engagement}
        hasTrackingReferral={hasTrackingReferral}
        engagementProposal={engagementProposal}
      />

      <ProposalSummary
        jdMode={engagementProposal.jdMode}
        engagement={engagement}
        hasTrackingReferral={hasTrackingReferral}
        engagementProposal={engagementProposal}
        formattedInfluencers={formattedInfluencers}
        formattedSocialAccounts={formattedSocialAccounts}
        hasInfluencerCostPermission={hasInfluencerCostPermission}
      />

      <ProposalInfluencers
        engagement={engagement}
        jdMode={engagementProposal.jdMode}
        hasTrackingReferral={hasTrackingReferral}
        selectedInfluencers={selectedInfluencers}
        rejectedInfluencers={rejectedInfluencers}
        formattedInfluencers={formattedInfluencers}
        formattedSocialAccounts={formattedSocialAccounts}
        formattedRejectedInfluencers={formattedRejectedInfluencers}
        allInfluencers={engagementProposal.influencers}
        hasInfluencerCostPermission={hasInfluencerCostPermission}
      />
    </div>
  );
};
