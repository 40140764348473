import 'highcharts/modules/heatmap';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import {
  NoDataTitle,
  NoDataContentWrapper,
  NoDataContentWrapperProps,
  ChartTooltipMultipleMetrics,
  ChartTooltipMultipleMetricsProps
} from '@/shared/atoms';
import { AppLanguage } from '@/shared/types';
import { formatPercent, hourIntervals, localizedWeekdays } from '@/shared/utils';
import { HighChart, Highcharts } from '../HighChart';

const PostingHabitsDataKeys = {
  averageLikes: 'averageLikes',
  engagementRate: 'engagementRate',
  averageComments: 'averageComments'
} as const;

type Keys = keyof typeof PostingHabitsDataKeys;
type TooltipPropsType = Pick<ChartTooltipMultipleMetricsProps<Keys>, 'categories' | 'data'>;

export interface PostingHabitsProps
  extends Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'>,
    Pick<ChartTooltipMultipleMetricsProps<Keys>, 'customTooltip'>,
    Pick<Highcharts.ChartOptions, 'height'> {
  data?: readonly {
    hour: number;
    weekday: number;
    averageLikes: number;
    engagementRate: number;
    averageComments: number;
  }[];
}
export const PostingHabits = ({
  data,
  header,
  height = 284,
  customTooltip,
  ...noDataContentProps
}: PostingHabitsProps) => {
  const { t, i18n } = useTranslation();

  const hasNoData = !data || data.every((item) => item.engagementRate === 0);
  const chartData = !hasNoData
    ? data.map((item) => ({
        ...item,
        x: item.hour,
        y: item.weekday,
        key: `${item.weekday}-${item.hour}`,
        value: parseFloat(formatPercent(item.engagementRate, false)),
        category: `${localizedWeekdays(i18n.language as AppLanguage)[item.weekday]} ${hourIntervals[item.hour]}`
      }))
    : [];
  const tooltipProps = chartData.reduce<TooltipPropsType>(
    (acc, curr) => {
      acc.categories.push(curr.category);
      (Object.keys(PostingHabitsDataKeys) as Keys[]).forEach((key) => {
        acc.data[key].data.push(curr[key]);
      });

      return acc;
    },
    {
      categories: [],
      data: {
        averageLikes: { name: t<string>('General.Likes'), data: [] },
        averageComments: { name: t<string>('General.Comments'), data: [] },
        engagementRate: { name: t<string>('General.Engagement Rate'), data: [], formatType: 'percentage' }
      }
    }
  );
  const options: Highcharts.Options = {
    title: { text: '' },
    legend: { enabled: false },
    chart: { height, marginTop: 0, type: 'heatmap', inverted: true },
    colorAxis: { min: 0, minColor: '#eef3f7', maxColor: '#ed183e' },
    plotOptions: { heatmap: { borderWidth: 2, borderColor: '#fff' } },
    series: [{ type: 'heatmap', name: 'Posting Habits', data: chartData, dataLabels: { enabled: false } }],
    xAxis: {
      min: 0,
      offset: 35,
      opposite: true,
      lineColor: '#FFF',
      categories: hourIntervals.map((item) => item.replace(/\s/g, '').toUpperCase()),
      labels: {
        x: 0,
        align: 'right',
        style: { fontSize: '11px' },
        formatter: function () {
          return this.pos % 2 === 0 ? String(this.value) : ''; // Only show labels for even indices
        }
      }
    },
    yAxis: {
      min: 0,
      lineColor: '#FFF',
      title: { text: '' },
      gridLineColor: 'transparent',
      labels: { style: { fontSize: '10px' }, y: 10, overflow: 'justify' },
      categories: localizedWeekdays(i18n.language as AppLanguage).map((item) => item.slice(0, 3))
    },
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      headerFormat: '',
      borderColor: '#27313b',
      formatter() {
        return renderToStaticMarkup(
          <ChartTooltipMultipleMetrics
            {...tooltipProps}
            customTooltip={customTooltip}
            selectedCategory={this.category as string}
          />
        );
      }
    }
  };

  return (
    <NoDataContentWrapper
      css={{ padding: '10px 8px 0', height: 'auto' }}
      dataNotAvailable={hasNoData}
      header={
        header ?? (
          <NoDataTitle
            css={{ margin: '0 0 8px 10px' }}
            title={t('Influencer.PostingHabits')}
            help={t<string>('Tooltip.postingHabits')}
          />
        )
      }
      {...noDataContentProps}
    >
      <HighChart options={options} />
    </NoDataContentWrapper>
  );
};
