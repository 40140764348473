import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { SC } from '../StyledComponents';
import { PostInsightFormKeys } from '../types';

export const PostInsightFacebook = () => {
  const { t } = useTranslation();

  return (
    <SC.SectionWrapper css={{ borderBottom: 'none' }}>
      <FormStyle.FieldsGroup itemsPerRow={3}>
        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="impressions"
          placeholder="10000000"
          title={t('TextForm.Impression')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="comments"
          placeholder="10000000"
          title={t('TextForm.Comments')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="likes"
          placeholder="10000000"
          title={t('TextForm.Reactions')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="reaches"
          placeholder="10000000"
          title={t('TextForm.Reach')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="shares"
          placeholder="10000000"
          title={t('TextForm.Shares')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="linkClicks"
          placeholder="10000000"
          title={t('TextForm.Link Clicks')}
        />
      </FormStyle.FieldsGroup>
    </SC.SectionWrapper>
  );
};
