import { css } from '@emotion/react';
import { useState } from 'react';
import { linkOptions } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import { SocialAccountType, useUpdateSocialAccountsMutation } from '@/graphql';
import { CircularProgress, NoDataContentWrapperProps } from '@/shared/atoms';
import { AWAIT_ESTIMATED_DATA_TEXT } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { InfluencerSelectedAccountInfo } from '../types';
import { useInfluencerProfileEstimate } from './useInfluencerProfileEstimate';

interface InfluencerProfileOpacityContentType extends Pick<NoDataContentWrapperProps, 'wrapperText' | 'wrapperLink'> {
  hasEstimatedDemographics?: boolean;
}

interface Props {
  influencerId: number;
  skipEstimateContent?: boolean;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const useInfluencerProfileOpacityContent = ({ influencerId, socialAccount, skipEstimateContent }: Props) => {
  const { isAdminStaff, isTalentAgencyPartner } = useUserRoles();
  const influencerProfileEstimate = useInfluencerProfileEstimate({
    influencerId,
    socialAccount,
    options: { fetchPolicy: 'cache-first' }
  });

  const { estimateMessage, hasAudienceDemographics } = influencerProfileEstimate;
  const awaitedEstimatedContent: InfluencerProfileOpacityContentType | undefined = skipEstimateContent
    ? undefined
    : estimateMessage
      ? { wrapperText: estimateMessage, hasEstimatedDemographics: hasAudienceDemographics }
      : {
          wrapperText: AWAIT_ESTIMATED_DATA_TEXT,
          hasEstimatedDemographics: hasAudienceDemographics,
          wrapperLink: { customLink: <RefetchEstimatedData influencerId={influencerId} /> }
        };
  const canUseExtensionContent: InfluencerProfileOpacityContentType = {
    wrapperText: 'please use extension to update the data',
    wrapperLink: {
      text: 'use extension',
      href: 'https://docs.google.com/presentation/d/1EvmSV__YeJwARdg8-jJN1pF_r9vw1XotafifP35MaY0/edit?usp=sharing'
    },
    hasEstimatedDemographics: awaitedEstimatedContent?.hasEstimatedDemographics
  };
  const pleaseGoEditProfileContent = awaitedEstimatedContent || {
    wrapperText: 'please go to edit profile and save social account data',
    wrapperLink: {
      linkOption: linkOptions({ to: '/influencer/$id', params: { id: String(influencerId) } }),
      text: 'edit profile'
    }
  };
  const unableToRetrieveDataContent: InfluencerProfileOpacityContentType = {
    wrapperLink: undefined,
    wrapperText: 'Unable to retrieve data',
    hasEstimatedDemographics: awaitedEstimatedContent?.hasEstimatedDemographics
  };

  const yesWithAsteriskContent = isAdminStaff
    ? canUseExtensionContent
    : isTalentAgencyPartner
      ? pleaseGoEditProfileContent
      : unableToRetrieveDataContent;

  const yesContent = isAdminStaff || isTalentAgencyPartner ? pleaseGoEditProfileContent : unableToRetrieveDataContent;

  const opacityContent = (() => {
    switch (socialAccount.type) {
      case SocialAccountType.FACEBOOK:
        return yesWithAsteriskContent;
      // if signed-up account, we don't use extension because we can get from token
      // if scraping account, we also don't use extension because we use public discovery API
      case SocialAccountType.INSTAGRAM:
      case SocialAccountType.FACEBOOK_PAGE:
      case SocialAccountType.TWITTER:
      case SocialAccountType.YOUTUBE:
      default:
        return yesContent;
    }
  })();

  return { ...influencerProfileEstimate, opacityContent };
};

interface RefetchEstimatedDataProps {
  influencerId: number;
}
const RefetchEstimatedData = ({ influencerId }: RefetchEstimatedDataProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callUpdateSocialAccounts, loading } = useUpdateSocialAccountsMutation({
    variables: { input: { influencerId } }
  });
  const [debouncedLoading, setLoading] = useState(loading);

  const isLoading = loading || debouncedLoading;

  const handleUpdate = async () => {
    try {
      const { data } = await callUpdateSocialAccounts();
      setLoading(true);
      if (data?.updateSocialAccounts?.ok) {
        enqueueSnackbar(t('Please wait for 2-5 mins until the estimated model will be generated'), { variant: 'info' });
        setTimeout(() => {
          setLoading(false);
          navigate({ replace: true });
        }, 5000); // Data will be roughly updated within 5 seconds, we can increase if not enough
      } else {
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <button disabled={isLoading} onClick={handleUpdate} css={styles.refetchButton(isLoading)}>
      <span css={{ color: isLoading ? '#dee5ec' : '#3892e5' }}>
        {t(isLoading ? 'Page will be reloaded soon' : 'Analyze age & gender')}
      </span>
      {isLoading ? <CircularProgress size={16} thickness={0.3} backgroundColor="#3892e5" /> : null}
    </button>
  );
};
const styles = {
  refetchButton: (loading: boolean) =>
    css({
      gap: '4px',
      display: 'flex',
      marginTop: '8px',
      cursor: loading ? 'auto' : 'pointer',
      '&:hover': { opacity: '0.8' }
    })
};
