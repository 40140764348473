import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeCmsInvoiceList.graphql';
import { Query, QueryyoutubeCmsInvoiceListArgs } from '../../__generated__/globalTypes';

export const useYoutubeCmsInvoiceListQuery = (options?: QueryHookOptions<Query, QueryyoutubeCmsInvoiceListArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeCmsInvoiceListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeCmsInvoiceListLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeCmsInvoiceListArgs>
): QueryResult<Query, QueryyoutubeCmsInvoiceListArgs> & {
  getYoutubeCmsInvoiceList: LazyQueryExecFunction<Query, QueryyoutubeCmsInvoiceListArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeCmsInvoiceList, result] = useLazyQuery<Query, QueryyoutubeCmsInvoiceListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeCmsInvoiceList, ...result };
};

export const useYoutubeCmsInvoiceListPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeCmsInvoiceList = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeCmsInvoiceListArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeCmsInvoiceListArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeCmsInvoiceList };
};
