import { useTranslation } from 'react-i18next';
import { useUserPermissions, useUserRoles } from '@/auth';
import { Tabs } from '@/shared/atoms';

export interface PartnerTabsProps {
  partnerId: number;
}

export const PartnerTabs = ({ partnerId }: PartnerTabsProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { hideUserTab } = useUserPermissions();

  return (
    <Tabs
      options={[
        {
          end: true,
          label: t('Partner Info'),
          to: '/partner/$id',
          params: { id: partnerId.toString() }
        },
        {
          label: t('Business Associates'),
          hidden: !isAdminStaff,
          to: '/partner/$id/business',
          params: { id: partnerId.toString() }
        },
        {
          end: true,
          label: t('User'),
          hidden: hideUserTab,
          to: '/partner/$id/users',
          params: { id: partnerId.toString() }
        }
      ]}
    />
  );
};
