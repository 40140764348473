import { FilterProvider } from '@/shared/molecules';
import { useMarketplaceApplicantsFilter } from './hooks';
import { BasicFilter, BasicFilterProps } from './BasicFilter';

export type MarketplaceApplicantsFilterProps = Omit<BasicFilterProps, 'onSubmit'>;

export const MarketplaceApplicantsFilter = (props: MarketplaceApplicantsFilterProps) => {
  const { filter, setFilterForm } = useMarketplaceApplicantsFilter();

  return <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<BasicFilter {...props} />} />;
};

export { useMarketplaceApplicantsFilter };
export * from './schemaTypes';
