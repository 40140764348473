import { useTranslation } from 'react-i18next';
import { InfluencerSocialPost, PostListSortField, SocialPostType } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';

type CustomColumnProps = ColumnProps<PostListSortField>;

interface Props {
  data: readonly InfluencerSocialPost[];
  socialPostType: SocialPostType;
}

export const useInfluencerScrapingPostsListTable = ({ data, socialPostType }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const isYoutube = socialPostType === SocialPostType.YOUTUBE;
  const isTwitter = socialPostType === SocialPostType.TWITTER;
  const isThreads = socialPostType === SocialPostType.THREADS;
  const isInstagram = socialPostType === SocialPostType.INSTAGRAM;
  const isFacebook = [SocialPostType.FACEBOOK, SocialPostType.FACEBOOK_PAGE].includes(socialPostType);
  const isInstagramTypes = [
    SocialPostType.INSTAGRAM,
    SocialPostType.INSTAGRAM_REEL,
    SocialPostType.INSTAGRAM_STORY
  ].includes(socialPostType);

  const columns: CustomColumnProps[] = [
    {
      title: <div css={{ display: 'flex', alignItems: 'center' }}>{t('HeaderColumn.Post')}</div>,
      sticky: { active: true, hasBorderRight: true }
    },
    getMetricColumnProps({
      title: t('HeaderColumn.Impressions'),
      sortField: PostListSortField.IMPRESSION,
      hidden: !(isTwitter || isFacebook || isInstagram),
      help: t<string>('Tooltip.impressionsHelpForScrapingAccounts')
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.View'),
      sortField: PostListSortField.VIEW,
      help: t<string>('Tooltip.ViewHelp'),
      hidden: isTwitter || isFacebook || isThreads || isInstagram
    }),
    getMetricColumnProps({
      hidden: !isInstagramTypes,
      title: t('HeaderColumn.Reach'),
      sortField: PostListSortField.REACH,
      help: t<string>('Tooltip.ReachPostHelp')
    }),
    getMetricColumnProps({
      sortField: PostListSortField.LIKE,
      help: t<string>('Tooltip.LikeHelp'),
      title: t(isFacebook ? 'HeaderColumn.Reaction' : 'HeaderColumn.Like')
    }),
    getMetricColumnProps({
      sortField: PostListSortField.COMMENT,
      help: t<string>('Tooltip.CommentHelp'),
      title: t(isTwitter || isThreads ? 'HeaderColumn.Replies' : 'HeaderColumn.Comment')
    }),
    getMetricColumnProps({
      hidden: isYoutube || isThreads,
      sortField: PostListSortField.SHARE,
      help: t<string>('Tooltip.shareHelp'),
      title: t(isTwitter ? 'HeaderColumn.Retweets' : 'HeaderColumn.share')
    }),
    getMetricColumnProps({
      hidden: !isInstagramTypes,
      title: t('HeaderColumn.Save'),
      sortField: PostListSortField.SAVED,
      help: t<string>('Tooltip.SaveHelp')
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      sortField: PostListSortField.POST_DATE,
      help: t<string>('Tooltip.PostDateHelp')
    })
  ];
  const rows: RowProps[] = data.map(
    ({ id, views, reach, likes, saved, shares, caption, date, comments, thumbnail, postUrl, impressions }) => [
      <TableCell.Post
        postUrl={postUrl}
        title={caption || ''}
        thumbnail={thumbnail || ''}
        socialPostType={socialPostType}
        onClick={() => setPostDetailsParam(id)}
      />,
      <TableCell.Number value={formatIntNumber(impressions)} />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={formatIntNumber(reach)} />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(shares)} />,
      <TableCell.Number value={formatIntNumber(saved)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={date} />
    ]
  );

  return { rows, columns };
};
