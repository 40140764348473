import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Anchor, ButtonSubmit, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PasswordField, TextField } from '@/shared/molecules';
import { SignInFormKeys, SignInFormValues } from './types';

export const Template = () => {
  const {
    formState: { isSubmitting }
  } = useFormContext<SignInFormValues>();
  const { t } = useTranslation();

  return (
    <FormStyle.Wrapper css={styles.wrapper} gapRow={16}>
      <h1 css={{ fontSize: '20px', lineHeight: '100%', textAlign: 'center' }}>{t('Password.Sign In')}</h1>

      <p css={{ fontSize: '14px', textAlign: 'center', padding: '16px 0 8px', lineHeight: '140%' }}>
        {t('Password.Please Sign In')}
        <br />
        {t('Password.Email And Password')}
      </p>

      <TextField<SignInFormKeys> name="email" title={t('TextForm.Email')} placeholder="your@email.com" />

      <PasswordField<SignInFormKeys>
        name="password"
        title={t('Password.Password')}
        placeholder={t<string>('Password.Your Password')}
      />

      <ButtonSubmit
        variant="blue"
        loading={isSubmitting}
        title={t('Button.Sign In')}
        css={{ width: '100%', marginTop: '24px' }}
      />

      <Anchor variant="blue" css={styles.forgotPassword} to="/password_forgot">
        {t('Password.Forgot Password')}
      </Anchor>

      <p css={{ marginTop: '70px', color: THEME.text.colors.gray.lv3, fontSize: '11px', lineHeight: '140%' }}>
        <Trans
          i18nKey="SignInConfirmation"
          components={{
            a1: <Anchor variant="blue" css={styles.link} to="/privacy" />,
            a2: <Anchor variant="blue" css={styles.link} to="/terms-combined" />
          }}
        />
      </p>
    </FormStyle.Wrapper>
  );
};

const styles = {
  wrapper: css({
    width: '428px',
    boxSizing: 'border-box',
    padding: '48px 40px 24px 40px',
    borderRadius: THEME.container.dashboard.borderRadius
  }),
  link: css({ display: 'inline', lineHeight: 'inherit' }),
  forgotPassword: css({
    fontSize: '12px',
    marginTop: '24px',
    lineHeight: '100%',
    textAlign: 'center'
  })
};
