import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { useTiktokSpecialPostQuery } from '@/graphql';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface TikTokSpecialPostDetailsProps extends Pick<PostDetailsType, 'id'> {
  className?: string;
  onCloseModal?: () => void;
}

export const TikTokSpecialPostDetails = ({ id, className, onCloseModal }: TikTokSpecialPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useTiktokSpecialPostQuery({
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: { pk: Number(id) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const post = data?.tiktokSpecialPost;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = {
    socialMedia: 'TIKTOK',
    caption: post?.caption,
    images: post?.images || [],
    postUrl: post?.postUrl || '',
    info: {
      socialMedia: 'TIKTOK',
      id: post?.influencer.id,
      postDate: post?.postedDate,
      name: post?.influencer.name,
      avatar: post?.influencer.avatar,
      followers: post?.influencer.followersCount
    },
    statistics: {
      likes: post?.likes,
      views: post?.views,
      shares: post?.shares,
      comments: post?.comments
    }
  };

  return (
    <DefaultPostDetailsTemplate
      loading={loading}
      className={className}
      postDetails={postDetails}
      css={{ width: postDetails.images?.length ? THEME.modal.size.lv3 : THEME.modal.size.lv1 }}
    />
  );
};
