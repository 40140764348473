import { useTranslation } from 'react-i18next';
import { SortOption } from '@/shared/types';
import { FormProvider } from '@/shared/atoms';
import { InfluencerListSortField, Order } from '@/graphql';
import { useMarketplaceApplicantsFilter } from '../MarketplaceApplicantsFilter';
import { Template } from './Template';
import { ActionBar, ActionBarProps } from './ActionBar';
import { MarketplaceApplicantsSortFormValues } from './types';

export type MarketplaceApplicantsSortProps = ActionBarProps;

export const MarketplaceApplicantsSort = ({
  onSelectAll,
  marketplace,
  selectButtonProps,
  selectedApplicants,
  resetSelectedApplicants
}: MarketplaceApplicantsSortProps) => {
  const { t } = useTranslation();
  const { limit, sort, setSortAndLimit } = useMarketplaceApplicantsFilter();

  const sortOptions: SortOption<InfluencerListSortField>[] = [
    { field: InfluencerListSortField.FOLLOWERS, label: t('Total Followers (ASC)'), order: Order.ASC },
    { field: InfluencerListSortField.FOLLOWERS, label: t('Total Followers (DESC)'), order: Order.DESC },
    { field: InfluencerListSortField.ENGAGEMENT, label: t('Engagement (ASC)'), order: Order.ASC },
    { field: InfluencerListSortField.ENGAGEMENT, label: t('Engagement (DESC)'), order: Order.DESC },
    { field: InfluencerListSortField.ENGAGEMENT_RATE, label: t('ER (ASC)'), order: Order.ASC },
    { field: InfluencerListSortField.ENGAGEMENT_RATE, label: t('ER (DESC)'), order: Order.DESC }
  ].map((option) => ({ ...option, value: `${option.field}-${option.order}` }));

  return (
    <div css={{ position: 'relative', borderRadius: 'inherit' }}>
      {selectButtonProps?.checked ? (
        <ActionBar
          marketplace={marketplace}
          onSelectAll={onSelectAll}
          selectButtonProps={selectButtonProps}
          selectedApplicants={selectedApplicants}
          resetSelectedApplicants={resetSelectedApplicants}
        />
      ) : null}

      <FormProvider<MarketplaceApplicantsSortFormValues>
        onSubmit={(values) => {
          setSortAndLimit({
            sort: { field: values.field, order: values.order },
            limit: +values.limit
          });
        }}
        values={{
          ...sort,
          limit: String(limit),
          sort: sortOptions.find((o) => o.field === sort.field && o.order === sort.order)?.value || ''
        }}
      >
        <Template sortOptions={sortOptions} checkboxProps={selectButtonProps} />
      </FormProvider>
    </div>
  );
};
