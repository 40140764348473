import { UserRoles } from '@/graphql';
import { useAuthData } from './useAuthData';

export const useUserRoles = () => {
  const { auth } = useAuthData();
  const roles = {
    isAdmin: auth.role === UserRoles.ADMINISTRATOR,
    isStaff: auth.role === UserRoles.STAFF,
    isAgency: auth.role === UserRoles.AGENCY,
    isPartner: auth.role === UserRoles.PARTNER,
    isExternal: auth.role === UserRoles.EXTERNAL,
    isAdvertiser: auth.role === UserRoles.ADVERTISER,
    isInfluencer: auth.role === UserRoles.INFLUENCER,
    isTalentAgency: auth.role === UserRoles.TALENT_AGENCY
  };
  const isAdminStaff = roles.isAdmin || roles.isStaff;
  const isAdminStaffAgency = isAdminStaff || roles.isAgency;
  const isAgencyTalentAgency = roles.isAgency || roles.isTalentAgency;
  const isAdminStaffTalentAgency = isAdminStaff || roles.isTalentAgency;
  const isTalentAgencyPartner = roles.isTalentAgency || roles.isPartner;
  const isAgencyTalentAgencyPartner = isAgencyTalentAgency || roles.isPartner;

  return {
    ...roles,
    status: auth.status,
    role: auth.role as UserRoles,
    // Common combination roles
    isAdminStaff,
    isAdminStaffAgency,
    isAgencyTalentAgency,
    isTalentAgencyPartner,
    isAdminStaffTalentAgency,
    isAgencyTalentAgencyPartner,
    isAdminStaffPartner: isAdminStaff || roles.isPartner,
    isAdminStaffTalentAgencyPartner: isAdminStaff || isTalentAgencyPartner,
    isAdminStaffAgencyTalentAgencyPartner: isAdminStaff || isAgencyTalentAgencyPartner,
    isPartnerFromUuum: roles.isPartner && auth.company === 'UUUM',
    isAdvertiserFromTikTok: roles.isAdvertiser && auth.company === 'TIKTOK'
  };
};
