import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllMarketplacesForReorder.graphql';
import { Query, QueryallMarketplacesForReorderArgs } from '../../__generated__/globalTypes';

export const useAllMarketplacesForReorderQuery = (
  options?: QueryHookOptions<Query, QueryallMarketplacesForReorderArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallMarketplacesForReorderArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllMarketplacesForReorderLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallMarketplacesForReorderArgs>
): QueryResult<Query, QueryallMarketplacesForReorderArgs> & {
  getAllMarketplacesForReorder: LazyQueryExecFunction<Query, QueryallMarketplacesForReorderArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllMarketplacesForReorder, result] = useLazyQuery<Query, QueryallMarketplacesForReorderArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllMarketplacesForReorder, ...result };
};

export const useAllMarketplacesForReorderPromiseQuery = () => {
  const client = useApolloClient();

  const getAllMarketplacesForReorder = useCallback(
    (options?: Omit<QueryOptions<QueryallMarketplacesForReorderArgs, Query>, 'query'>) =>
      client.query<Query, QueryallMarketplacesForReorderArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllMarketplacesForReorder };
};
