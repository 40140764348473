import { ListWithPagination } from '@/shared/molecules';
import { useYoutubeAnalyticsTrendsQuery } from '@/graphql';
import { getOffset, getValuableVariables } from '@/shared/utils';
import {
  AnalyticsTrendingYoutubePostsList,
  AnalyticsTrendingYoutubePostsFilter,
  useAnalyticsTrendingYoutubePostsFilter
} from '@/shared/organisms';

export const AnalyticsTrendingYoutubePosts = () => {
  const { page, limit, filter } = useAnalyticsTrendingYoutubePostsFilter();
  const { data, previousData, loading } = useYoutubeAnalyticsTrendsQuery({
    skip: !filter.category || !filter.country,
    variables: getValuableVariables({
      limit,
      date: filter.date,
      country: filter.country,
      offset: getOffset(page, limit),
      category: Number(filter.category)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.youtubeAnalyticsTrendsCount.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <AnalyticsTrendingYoutubePostsFilter />
      <AnalyticsTrendingYoutubePostsList
        loading={loading}
        totalRecords={totalRecords}
        data={result?.youtubeAnalyticsTrends.posts}
      />
    </ListWithPagination>
  );
};
