import { css } from '@emotion/react';
import { ReactNode, Ref, forwardRef, useEffect, useState } from 'react';
import { THEME } from '@/shared/constants';
import { useMeasure, useParamsSearch } from '@/shared/hooks';
import { ListEmptyProps, ListEmpty } from '../ListEmpty';
import { Pagination, PaginationProps } from '../Pagination';
import { RenderDataWithFallback } from '../RenderDataWithFallback';
import Table, { TableProps } from './Table';

export interface TemplateProps<SortField extends string = string> {
  id?: string;
  loading?: boolean;
  className?: string;
  maxHeight?: string;
  hasBorder?: boolean;
  stickyHeader?: boolean;
  empty?: ListEmptyProps;
  data: TableProps<SortField>;
  noTopBorderRadius?: boolean;
  pagination?: PaginationProps;
  children?: ReactNode;
  hasLoadingOverlay?: boolean;
  skipNoDataNode?: boolean;
}

const Template = <SortField extends string>(
  {
    id,
    data,
    empty,
    loading,
    children,
    hasBorder,
    className,
    pagination,
    stickyHeader,
    skipNoDataNode,
    noTopBorderRadius,
    hasLoadingOverlay,
    maxHeight = '500px'
  }: TemplateProps<SortField>,
  forwardedRef: Ref<HTMLDivElement>
) => {
  const [wrapperRef, wrapperDims] = useMeasure();
  const [tableRef, tableDims] = useMeasure();
  const [mounted, setMounted] = useState(false);
  const { sort, setSort } = useParamsSearch();

  useEffect(() => {
    if (!loading) {
      setMounted(true);
    }
  }, [loading]);

  const hasData = !!data.rows.length;
  const hasTableBorder = hasBorder ?? (!loading && hasData);
  const isOverflow = tableDims.width && wrapperDims.width && tableDims.width > wrapperDims.width;

  const loadingTableFirstTime = loading && (!hasData || !mounted); // We want to make loading 1st time and keep table once it rendered and rerender the data only
  const loadingTableAfterMounted = loading && mounted;

  return (
    <div
      id={id}
      ref={forwardedRef}
      css={hasLoadingOverlay || loadingTableAfterMounted ? styles.loadingOverlay : {}}
      className={className}
    >
      <div
        className="table-wrapper"
        css={[styles.tableWrapper(hasTableBorder, noTopBorderRadius), { maxHeight: stickyHeader ? maxHeight : 'none' }]}
        ref={wrapperRef}
      >
        <RenderDataWithFallback
          loading={loadingTableFirstTime}
          hasNoData={!hasData && !loadingTableFirstTime && !skipNoDataNode}
          noDataNode={<ListEmpty {...empty} />}
          loadingProps={{ css: stickyHeader ? { maxHeight: 'inherit' } : undefined }}
        >
          <Table<SortField>
            {...data}
            sort={sort}
            ref={tableRef}
            isOverflow={!!isOverflow}
            stickyHeader={stickyHeader}
            setSort={(newSort) => setSort(newSort, { resetScroll: false })}
          />
        </RenderDataWithFallback>
      </div>
      {children}
      {!loading && !!pagination ? <Pagination {...pagination} /> : null}
    </div>
  );
};
const styles = {
  tableWrapper: (hasBorder?: boolean, noTopBorderRadius?: boolean) =>
    css({
      width: '100%',
      overflow: 'auto',
      boxSizing: 'border-box',
      backgroundColor: THEME.background.colors.white,
      borderRadius: noTopBorderRadius ? '0 0 3px 3px' : '3px',
      ...(hasBorder ? { borderTop: THEME.border.base } : {})
    }),
  loadingOverlay: css({
    opacity: '0.5',
    pointerEvents: 'none',
    '*': {
      pointerEvents: 'none'
    }
  })
};

export default forwardRef(Template) as <SortField extends string>(
  props: TemplateProps<SortField> & { ref?: Ref<HTMLDivElement> }
) => ReturnType<typeof Template>;
