import { useParams } from '@tanstack/react-router';
import { useParamsSearch } from '@/shared/hooks';
import { AnalyticsProfileSearchSchemaType } from '../schemaTypes';

// Get period if search url exists or set new period as default
export const useAnalyticsProfileSearch = () => {
  const params = useParams({
    from: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId'
  });

  return { ...useParamsSearch<AnalyticsProfileSearchSchemaType>(), params };
};
