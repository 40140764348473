import { EngagementCampaignPostStatusForInsightCheck } from '@/graphql';

import { useTranslateOptions } from './useTranslateOptions';

export const usePostsInsightStatusOptions = () => {
  const postsInsightStatusOptions = useTranslateOptions([
    { label: 'Confirmed', value: EngagementCampaignPostStatusForInsightCheck.CONFIRMED },
    { label: 'Unconfirmed', value: EngagementCampaignPostStatusForInsightCheck.UNCONFIRMED }
  ]);

  return { postsInsightStatusOptions };
};
