import { Button, ButtonProps, NoDataContentWrapper, TextCutter } from '@/shared/atoms';

export interface AnalyticsComparePostsHashtagsProps {
  className?: string;
  hashtags?: string[];
  buttonProps?: ButtonProps;
  maxDisplayHashtags?: number;
}

export const AnalyticsComparePostsHashtags = ({
  hashtags,
  className,
  buttonProps,
  maxDisplayHashtags = 4
}: AnalyticsComparePostsHashtagsProps) => {
  const hasNoHashtag = !hashtags?.length;

  return (
    <NoDataContentWrapper
      className={className}
      dataNotAvailable={hasNoHashtag}
      css={[
        { padding: '16px', minHeight: 'unset', height: 'auto', backgroundColor: 'transparent' },
        hasNoHashtag ? { zoom: 0.5 } : { display: 'flex', justifyContent: 'center' }
      ]}
    >
      <div css={{ display: 'flex', height: '100%', alignItems: 'center' }}>
        <div css={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
          {hashtags?.slice(0, maxDisplayHashtags).map((hashtag, index) => (
            <div
              key={`hashtag-${index}`}
              css={{ padding: '6px', flex: '50%', maxWidth: '50%', boxSizing: 'border-box' }}
            >
              <TextCutter text={hashtag} css={{ fontSize: '11px', lineHeight: 'normal' }} />
            </div>
          ))}
        </div>

        {buttonProps ? (
          <Button prefixIcon={{ icon: 'plus', size: '10px' }} css={{ marginLeft: '8px' }} {...buttonProps} />
        ) : null}
      </div>
    </NoDataContentWrapper>
  );
};
