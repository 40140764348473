import { getValuableVariables } from '@/shared/utils';
import { useYoutubeCompareOverviewQuery, YoutubeCompareOverview } from '@/graphql';
import { AnalyticsCompareOverviewWrapper } from '../../shared';
import { AnalyticsCompareYoutubeAccountsProps } from '../AnalyticsCompareYoutubeAccounts';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { Template } from './Template';

export type AnalyticsCompareYoutubeOverviewProps = Pick<
  AnalyticsCompareYoutubeAccountsProps,
  'accounts' | 'mainAccountId' | 'hiddenAccountIds' | 'hasFreePlan'
> & {
  overviewDataMock?: YoutubeCompareOverview;
};

export const AnalyticsCompareYoutubeOverview = ({
  accounts,
  mainAccountId,
  hiddenAccountIds,
  hasFreePlan,
  overviewDataMock
}: AnalyticsCompareYoutubeOverviewProps) => {
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useYoutubeCompareOverviewQuery({
    fetchPolicy: 'cache-and-network',
    skip: !accounts.length || hasFreePlan,
    variables: getValuableVariables({
      mainAccountId,
      endDate: filter.endDate,
      startDate: filter.startDate
    })
  });

  const overviewData = hasFreePlan ? overviewDataMock : data?.youtubeCompareOverview;

  return (
    <AnalyticsCompareOverviewWrapper
      loading={loading}
      data={overviewData}
      dataNotAvailable={!overviewData || !(overviewData.compareAccounts.length || overviewData.mainAccount)}
    >
      {({ data }) => (
        <Template
          mainAccountId={mainAccountId}
          comparableAccounts={accounts.filter((account) => !hiddenAccountIds?.includes(account.id))}
          data={[
            data.mainAccount,
            ...data.compareAccounts.filter((account) => account.accountId !== data.mainAccount.accountId)
          ]}
        />
      )}
    </AnalyticsCompareOverviewWrapper>
  );
};
