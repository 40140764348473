import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { EngagementPost, EngagementCampaignPostStatus, namedOperations } from '@/graphql';
import { BlackBar } from '@/shared/atoms';
import { useSelectItems, useToggleState } from '@/shared/hooks';
import { SingleSelector } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { useUpdateEngagementPostsStatus } from './hooks';

interface ActionBarProps extends ReturnType<typeof useSelectItems<EngagementPost, keyof EngagementPost>> {
  isTtcmCampaign?: boolean;
}

export const ActionBar = ({
  selectedItems,
  isTtcmCampaign,
  selectedItemIds,
  setSelectedItemIds,
  hasAllItemsSelected,
  toggleSelectAllItems
}: ActionBarProps) => {
  const { t } = useTranslation();
  const loadingState = useToggleState();
  const { isAgency, isAdvertiser, isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { handleSelectStatus, UpdateEngagementPostStatusModal } = useUpdateEngagementPostsStatus({
    refetchQueries: [namedOperations.Query.AllEngagementPosts]
  });

  const numberSelectedIds = selectedItemIds.length;
  const options = [
    {
      label: t<string>('Option.Approved'),
      value: EngagementCampaignPostStatus.READY_TO_POST
    },
    {
      hidden: isTtcmCampaign,
      label: t<string>('Option.Warning'),
      value: EngagementCampaignPostStatus.WARNING
    },
    {
      label: t<string>('Option.Warning Solved'),
      value: EngagementCampaignPostStatus.WARNING_SOLVED,
      hidden: isTtcmCampaign || !(isAdminStaffTalentAgencyPartner || isAdvertiser || isAgency)
    },
    {
      label: t<string>('Option.Ineffective'),
      hidden: !isAdminStaffTalentAgencyPartner,
      value: EngagementCampaignPostStatus.INEFFECTIVE
    }
  ].filter((option) => !option.hidden) as Option<EngagementCampaignPostStatus>[];

  return numberSelectedIds > 0 ? (
    <>
      <BlackBar
        disabled={loadingState.status}
        selectAllProps={{ onClick: toggleSelectAllItems }}
        checkboxProps={{
          onClick: toggleSelectAllItems,
          indeterminate: !hasAllItemsSelected,
          label: t('Selected', { count: numberSelectedIds })
        }}
      >
        <SingleSelector
          name="status"
          options={options}
          disabled={loadingState.status}
          css={{ width: '180px', marginLeft: '16px' }}
          onChange={(value) => handleSelectStatus(value as EngagementCampaignPostStatus, selectedItems)}
          hideDeselectOption
        />
      </BlackBar>

      <UpdateEngagementPostStatusModal onSuccess={() => setSelectedItemIds([])} />
    </>
  ) : null;
};
