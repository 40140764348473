import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerTwitterAnalyticsPost.graphql';
import { Query, QueryinfluencerTwitterAnalyticsPostArgs } from '../../__generated__/globalTypes';

export const useInfluencerTwitterAnalyticsPostQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerTwitterAnalyticsPostArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerTwitterAnalyticsPostArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerTwitterAnalyticsPostLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerTwitterAnalyticsPostArgs>
): QueryResult<Query, QueryinfluencerTwitterAnalyticsPostArgs> & {
  getInfluencerTwitterAnalyticsPost: LazyQueryExecFunction<Query, QueryinfluencerTwitterAnalyticsPostArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerTwitterAnalyticsPost, result] = useLazyQuery<Query, QueryinfluencerTwitterAnalyticsPostArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getInfluencerTwitterAnalyticsPost, ...result };
};

export const useInfluencerTwitterAnalyticsPostPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerTwitterAnalyticsPost = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerTwitterAnalyticsPostArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerTwitterAnalyticsPostArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerTwitterAnalyticsPost };
};
