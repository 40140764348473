import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CampaignTrackingOption, EngagementCampaignStatus } from '@/graphql';
import { Button, FormStyle, Icon, Radio, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { TextField, TextAreaField, CheckBoxesField, UploadCSVCouponModal } from '@/shared/molecules';
import { DisabledFields } from '@/shared/types';
import { EngagementCampaignFormValues, EngagementCampaignFormKeys } from '../types';

interface TrackingProps {
  disabledFields: DisabledFields<EngagementCampaignFormKeys>;
}

export const Tracking = ({ disabledFields }: TrackingProps) => {
  const { t } = useTranslation();
  const { status: isOpening, open: openPopover, close: closePopover } = useToggleState();
  const {
    reset,
    setValue,
    getValues,
    formState: { defaultValues }
  } = useFormContext<EngagementCampaignFormValues>();
  const values = getValues();
  const { trackingOptions, oldCouponList, couponUploadFiles } = values;
  const { status: isUploadCouponByFile, setStatus: setUploadCouponByFile } = useToggleState(
    !!values.couponUploadFiles.length
  );

  const status = defaultValues?.status;
  const isOngoing = status === EngagementCampaignStatus.ONGOING;
  const isFinished = status === EngagementCampaignStatus.FINISHED;
  const hasTrackingTune = !!trackingOptions?.includes(CampaignTrackingOption.TUNE);
  const hasTrackingReferral = !!trackingOptions?.includes(CampaignTrackingOption.REFERRAL_CODE);
  const couponFileNames = (() => {
    const fileNames: string[] = [];

    couponUploadFiles.forEach((file) => {
      const decodeUrl = decodeURI(file);
      fileNames.push(decodeUrl.substring(decodeUrl.lastIndexOf('/') + 1));
    });

    return fileNames;
  })();

  const handleFileUpload = (fileUrl: string) => {
    if (fileUrl) {
      setValue('couponList', '');
      setValue('couponUploadFiles', [...couponUploadFiles, fileUrl]);

      closePopover();
    }
  };

  const handleTrackingTuneChecked = () => {
    // Currently, we only support 1 of Tune or Referral tracking as the same time
    const { trackingOptions, ...restValues } = values;
    reset({
      ...restValues,
      couponList: '',
      couponUploadFiles: [],
      trackingOptions: trackingOptions?.includes(CampaignTrackingOption.TUNE) ? [] : [CampaignTrackingOption.TUNE]
    });
  };

  const handleTrackingReferralChecked = () => {
    // Currently, we only support 1 of Tune or Referral tracking as the same time
    const { trackingOptions, ...restValues } = values;
    reset({
      ...restValues,
      landingPageUrl: '',
      trackingOptions: trackingOptions?.includes(CampaignTrackingOption.REFERRAL_CODE)
        ? []
        : [CampaignTrackingOption.REFERRAL_CODE]
    });
  };

  return (
    <>
      <div css={{ padding: '24px' }}>
        <FormStyle.SectionTitle index={3} label={t('General.Tracking')} />

        <CheckBoxesField<EngagementCampaignFormKeys>
          name="trackingOptions"
          onChange={handleTrackingTuneChecked}
          disabled={disabledFields?.trackingOptions}
          options={[{ label: t<string>('Track clicks/actions on the website'), value: CampaignTrackingOption.TUNE }]}
        />

        {hasTrackingTune && (
          <TextField<EngagementCampaignFormKeys>
            name="landingPageUrl"
            placeholder="https://anytag.tech"
            title={t('TextForm.Landing URL')}
            help={t('Tooltip.Landing URL Help')}
            disabled={disabledFields?.landingPageUrl}
            required
          />
        )}

        <CheckBoxesField<EngagementCampaignFormKeys>
          name="trackingOptions"
          onChange={handleTrackingReferralChecked}
          disabled={disabledFields?.trackingOptions}
          options={[
            {
              label: t<string>('Track conversions/sales by referral code'),
              value: CampaignTrackingOption.REFERRAL_CODE
            }
          ]}
        />

        {hasTrackingReferral ? (
          <FormStyle.FieldWrapper css={{ paddingLeft: '24px' }}>
            <div css={{ display: 'flex' }}>
              <Radio
                label={t('Manual')}
                checked={!isUploadCouponByFile}
                disabled={disabledFields?.couponList}
                onClick={() => setUploadCouponByFile(false)}
              />
              <Radio
                label={t('Upload File')}
                css={{ marginLeft: '24px' }}
                checked={isUploadCouponByFile}
                disabled={disabledFields?.couponUploadFiles}
                onClick={() => setUploadCouponByFile(true)}
              />
            </div>

            {!isUploadCouponByFile ? (
              <>
                <p css={{ fontSize: '14px', lineHeight: '16px', marginTop: '16px' }}>{t('trackConversionNote2')}</p>

                {(isOngoing || isFinished) && oldCouponList ? (
                  <p css={{ fontSize: '13px', marginTop: '16px', wordBreak: 'break-word' }}>
                    {oldCouponList.replace(/,/g, ', ')}
                  </p>
                ) : null}

                {isOngoing ? (
                  <TextAreaField<EngagementCampaignFormKeys>
                    name="newCouponList"
                    disabled={isFinished}
                    onChange={(field, value) => {
                      setValue(field, value);
                      setValue('couponUploadFiles', []);
                      setValue('couponList', [oldCouponList, value].join(', '));
                    }}
                    placeholder="code0001, code0002, code0003, code0004"
                    css={{ padding: 0, marginTop: '8px', height: '120px' }}
                  />
                ) : (
                  <TextAreaField<EngagementCampaignFormKeys>
                    name="couponList"
                    disabled={isFinished}
                    onChange={(field, value) => {
                      setValue(field, value);
                      setValue('couponUploadFiles', []);
                    }}
                    placeholder="code0001, code0002, code0003, code0004"
                    css={{ padding: 0, marginTop: '8px', height: '120px' }}
                  />
                )}
              </>
            ) : (
              <>
                {couponFileNames.length > 0
                  ? couponFileNames.map((fileName, index) => (
                      <div key={index} css={styles.uploadFile}>
                        <Icon icon="file" css={{ marginRight: '6px' }} size="14px" />
                        <TextCutter css={{ fontSize: '14px' }} text={fileName} />
                        {!disabledFields?.couponUploadFiles ? (
                          <Icon
                            size="10px"
                            icon="close"
                            color="#6E7C89"
                            css={{ marginLeft: 'auto', cursor: 'pointer' }}
                            onClick={() => {
                              const newCouponUploadFilesValue = [...couponUploadFiles];
                              newCouponUploadFilesValue.splice(index, 1);
                              setValue('couponUploadFiles', newCouponUploadFilesValue);
                            }}
                          />
                        ) : null}
                      </div>
                    ))
                  : null}

                <Button
                  variant="white"
                  onClick={openPopover}
                  disabled={isFinished}
                  title={t('Upload CSV')}
                  css={{ marginTop: '8px', padding: '0 24px' }}
                />
              </>
            )}
          </FormStyle.FieldWrapper>
        ) : null}
      </div>

      <UploadCSVCouponModal isOpen={isOpening} onClose={closePopover} onUploadFile={handleFileUpload} />
    </>
  );
};

const styles = {
  uploadFile: css({
    display: 'flex',
    padding: '16px',
    marginTop: '8px',
    borderRadius: '5px',
    alignItems: 'center',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.gray.lv1
  })
};
