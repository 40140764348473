import { Outlet, useMatchRoute, useParams } from '@tanstack/react-router';
import { useUserPermissions } from '@/auth';
import { AgencyWithAdvertisers, useAgencyQuery } from '@/graphql';
import { ButtonLink, RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useCustomHeader, useQueryHelper } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { AgencyTabs } from '@/shared/organisms';
import { AgencyDetailsProvider } from './AgencyDetailsContext';

export const AgencyDetails = () => {
  useCustomHeader();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/agency/$id' });
  const { hideAddUserBtn } = useUserPermissions();
  const matchRoute = useMatchRoute();
  const { data, loading } = useAgencyQuery({
    fetchPolicy: 'network-only',
    variables: { pk: Number(params.id) },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/agency' });
    }
  });
  const hasAddUserLink = !hideAddUserBtn && !!matchRoute({ to: '/agency/$id/users' });
  const agency = data?.agency;

  return (
    <RenderDataWithFallback loading={loading} hasNoData={!agency}>
      <AgencyDetailsProvider values={{ agency: agency as AgencyWithAdvertisers }}>
        <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
          <Header>
            {hasAddUserLink ? (
              <ButtonLink
                variant="blue"
                title={t('Button.Add User')}
                to="/user/add"
                prefixIcon={{ icon: 'plus', size: '10px' }}
                css={{ minWidth: '109px', padding: '0 16px', textTransform: 'capitalize', marginLeft: 'auto' }}
              />
            ) : null}
          </Header>

          <div css={{ boxShadow: THEME.shadows.boxShadow }}>
            <AgencyTabs agencyId={Number(agency?.id)} />
            <Outlet />
          </div>
        </div>
      </AgencyDetailsProvider>
    </RenderDataWithFallback>
  );
};
