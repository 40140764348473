import { ReactNode } from 'react';

import { TextCutter } from '../../TextCutter';
import { Tooltip, TooltipProps } from '../../Tooltip';

interface TextProps {
  lines?: number;
  value?: ReactNode;
  className?: string;
  tooltip?: Omit<TooltipProps, 'children'>;
}

export const Text = ({ value = '', lines, tooltip, className }: TextProps) => (
  <Tooltip {...tooltip} position={tooltip?.position || 'right'}>
    <TextCutter
      text={value}
      lines={lines}
      className={className}
      title={tooltip?.help ? '' : undefined} // ignore title when using tooltip
      css={{ minWidth: '200px', maxWidth: '350px', fontSize: '13px', lineHeight: '16px' }}
    />
  </Tooltip>
);
