import { ReactNode, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  EngagementPost,
  CampaignSocialMediaType,
  EngagementCampaignPostStatus,
  useUpdateEngagementPostStatusMutation,
  EngagementCampaignSocialAccountPostStatus
} from '@/graphql';
import { Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useToggleState, useQueryHelper } from '@/shared/hooks';

const getPostStatusValidator = (
  newStatus: EngagementCampaignPostStatus | EngagementCampaignSocialAccountPostStatus
) => {
  switch (newStatus) {
    case EngagementCampaignSocialAccountPostStatus.READY_TO_POST:
      return (status: EngagementCampaignSocialAccountPostStatus) =>
        EngagementCampaignSocialAccountPostStatus.REVIEWING === status;
    case EngagementCampaignSocialAccountPostStatus.WARNING:
      return (status: EngagementCampaignSocialAccountPostStatus) =>
        [
          EngagementCampaignSocialAccountPostStatus.WARNING,
          EngagementCampaignSocialAccountPostStatus.WARNING_SOLVED,
          EngagementCampaignSocialAccountPostStatus.EFFECTIVE
        ].includes(status);
    case EngagementCampaignSocialAccountPostStatus.WARNING_SOLVED:
      return (status: EngagementCampaignSocialAccountPostStatus) =>
        [
          EngagementCampaignSocialAccountPostStatus.WARNING,
          EngagementCampaignSocialAccountPostStatus.WARNING_SOLVED,
          EngagementCampaignSocialAccountPostStatus.REJECT
        ].includes(status);
    case EngagementCampaignSocialAccountPostStatus.INEFFECTIVE:
      return (status: EngagementCampaignSocialAccountPostStatus) =>
        [
          EngagementCampaignSocialAccountPostStatus.WARNING,
          EngagementCampaignSocialAccountPostStatus.WARNING_SOLVED
        ].includes(status);
    default:
      return null;
  }
};

interface Props {
  refetchQueries?: string[];
}

export const useUpdateEngagementPostsStatus = ({ refetchQueries }: Props) => {
  const loadingState = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callUpdateEngagementPostStatus } = useUpdateEngagementPostStatusMutation({ refetchQueries });
  const [errorModalState, setErrorModalState] = useState<{
    title: string;
    content?: ReactNode;
    newStatus: EngagementCampaignPostStatus;
  } | null>(null);
  const [confirmModalState, setConfirmModalState] = useState<{
    selectedPosts: EngagementPost[];
    newStatus: EngagementCampaignPostStatus;
    updatingPostStatuses: EngagementCampaignSocialAccountPostStatus[];
  } | null>(null);

  // If status was REJECTED, next status is REVIEWING, if status in INVALID CONTENT, next status is EFFECTIVE.
  // newStatus is EFFECTIVE by default so we need to replace by REVIEWING only in REJECTED case
  // We show a special message in the case we move selected both status, else we just replace effective by reviewing when we
  // select a rejected post.
  const hasRejectedPost = confirmModalState?.updatingPostStatuses.includes(
    EngagementCampaignSocialAccountPostStatus.REJECT
  );
  const hasWarningPost = confirmModalState?.updatingPostStatuses.includes(
    EngagementCampaignSocialAccountPostStatus.WARNING
  );
  const showSpecialMessage =
    hasRejectedPost && hasWarningPost && confirmModalState?.newStatus === EngagementCampaignPostStatus.WARNING_SOLVED;
  const statuses = (() => {
    const joinedStatus = { color: '#ffb63d', text: t('Option.Joined') };
    const effectiveStatus = { color: 'rgb(56, 146, 229)', text: t('Option.Effective') };
    const isApprovingDouyinPosts =
      confirmModalState?.newStatus === EngagementCampaignPostStatus.READY_TO_POST &&
      confirmModalState.selectedPosts.every((post) => post.socialMedia === CampaignSocialMediaType.DOUYIN);

    return {
      [EngagementCampaignSocialAccountPostStatus.JOINED]: joinedStatus,
      [EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_ORDER]: joinedStatus,
      [EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_INVITATION]: joinedStatus,
      [EngagementCampaignSocialAccountPostStatus.EFFECTIVE]: effectiveStatus,
      [EngagementCampaignSocialAccountPostStatus.REJECT]: { color: '#ffb63d', text: t('Option.Rejected') },
      [EngagementCampaignSocialAccountPostStatus.APPROVED]: { color: '#40b87c', text: t('Option.Approved') },
      [EngagementCampaignSocialAccountPostStatus.REVIEWING]: { color: '#ffb63d', text: t('Option.Reviewing') },
      [EngagementCampaignSocialAccountPostStatus.WARNING]: { color: '#ff2b52', text: t('Option.Invalid content') }, // "Warning" should be displayed as "Invalid Content" in the Popup
      [EngagementCampaignSocialAccountPostStatus.INEFFECTIVE]: { color: '#ff2b52', text: t('Option.Ineffective') },
      [EngagementCampaignSocialAccountPostStatus.READY_TO_POST]: isApprovingDouyinPosts
        ? effectiveStatus
        : { color: '#40b87c', text: t('Option.Ready to Post') },
      [EngagementCampaignSocialAccountPostStatus.TTCM_REVIEWING]: { color: '#ffb63d', text: t('Option.TikTok Review') },
      [EngagementCampaignSocialAccountPostStatus.WARNING_SOLVED]: { color: '#ffb63d', text: t('Option.Warning Solved') }
    };
  })();

  const handleSelectStatus = (newStatus: EngagementCampaignPostStatus, selectedPosts: EngagementPost[]) => {
    const updatingPostStatuses = selectedPosts.reduce<EngagementCampaignSocialAccountPostStatus[]>(
      (acc, curr) => (!!curr.status && curr.status.valueOf() !== newStatus.valueOf() ? [...acc, curr.status] : acc),
      []
    );

    if (!updatingPostStatuses.length) {
      setErrorModalState({ newStatus, title: t('Dialog.No need to update status') });

      return;
    }

    const checkPostStatus = getPostStatusValidator(newStatus);
    if (!checkPostStatus) {
      setErrorModalState({ newStatus, title: t('Dialog.Unable to save'), content: t('Dialog.Invalid status') });

      return;
    }

    const invalidPostStatus = updatingPostStatuses.filter((status) => !checkPostStatus(status)).at(0);

    if (invalidPostStatus) {
      setErrorModalState({
        newStatus,
        title: t('Dialog.Unable to save'),
        content:
          newStatus === EngagementCampaignPostStatus.READY_TO_POST ? (
            <Trans
              i18nKey="Dialog.FailedToApproveEngagementPost"
              values={{ from: statuses[invalidPostStatus].text }}
              components={{ 1: <span css={{ color: statuses[invalidPostStatus].color }} /> }}
            />
          ) : (
            <Trans
              i18nKey="Dialog.FailedToChangeEngagementPostStatus"
              components={{
                1: <span css={{ color: statuses[newStatus].color }} />,
                3: <span css={{ color: statuses[invalidPostStatus].color }} />
              }}
              values={{ to: statuses[newStatus].text, from: statuses[invalidPostStatus].text }}
            />
          )
      });
    } else {
      setConfirmModalState({ newStatus, selectedPosts, updatingPostStatuses });
    }
  };

  const resetConfirmModalState = () => {
    setConfirmModalState(null);
  };

  const resetErrorModalState = () => {
    setErrorModalState(null);
  };

  const handleUpdatePostsStatus = async (onSuccess?: () => void) => {
    if (
      !confirmModalState?.newStatus ||
      !confirmModalState.selectedPosts.length ||
      !confirmModalState.updatingPostStatuses.length
    ) {
      return;
    }

    loadingState.open();

    // TODO Request BE: callUpdateEngagementPostStatus['id'] should support multiple instead of calling multiple query
    const allResults = await Promise.all(
      confirmModalState.selectedPosts.map(({ id }) =>
        callUpdateEngagementPostStatus({
          variables: { input: { id: id as number, status: confirmModalState.newStatus } }
        })
      )
    )
      .then((results) =>
        results.map((result, index) => {
          const influencerName = confirmModalState.selectedPosts.at(index)?.influencer?.name;

          return result?.data?.updateEngagementPostStatus
            ? {
                influencerName,
                ok: result.data.updateEngagementPostStatus.ok,
                error: result.errors && result.errors.length > 0 ? result.errors.at(0)?.message : null
              }
            : { ok: false, influencerName, error: null };
        })
      )
      .catch((e) =>
        confirmModalState.selectedPosts.map((post) => ({
          ok: false,
          error: e.message,
          influencerName: post.influencer.name
        }))
      );

    allResults.forEach(({ ok, influencerName, error }) => {
      if (ok && !error) {
        const successMessage = t('succeededInUpdating', { name: influencerName });
        enqueueSnackbar(successMessage, { variant: 'success' });
        onSuccess?.();
      } else {
        const failureMessage = t('failedToUpdate', { name: influencerName });
        const errorMessage = t(error || UNEXPECTED_ERROR);
        enqueueSnackbar(`${failureMessage}: ${errorMessage}`, { variant: 'error' });
      }
    });

    resetConfirmModalState();
    loadingState.close();
  };

  return {
    handleSelectStatus,
    selectedStatus: confirmModalState?.newStatus || errorModalState?.newStatus,
    UpdateEngagementPostStatusModal: ({ onSuccess }: { onSuccess?: () => void }) => (
      <>
        <Modal
          onClose={resetConfirmModalState}
          open={!!confirmModalState?.newStatus}
          css={{ width: THEME.modal.size.lv2 }}
        >
          <ModalContent>
            <ModalTitle>{t('Dialog.Confirm Changes')}</ModalTitle>

            <div css={{ marginTop: '16px' }}>
              {showSpecialMessage ? (
                <Trans
                  i18nKey="Dialog.PostUpdateNoticeSpecial"
                  components={{
                    1: <span css={{ color: statuses[EngagementCampaignSocialAccountPostStatus.WARNING].color }} />,
                    2: <span css={{ color: statuses[EngagementCampaignSocialAccountPostStatus.EFFECTIVE].color }} />,
                    3: <span css={{ color: statuses[EngagementCampaignSocialAccountPostStatus.REJECT].color }} />,
                    4: <span css={{ color: statuses[EngagementCampaignSocialAccountPostStatus.REVIEWING].color }} />
                  }}
                  values={{
                    warning: statuses[EngagementCampaignSocialAccountPostStatus.WARNING].text,
                    effective: statuses[EngagementCampaignSocialAccountPostStatus.EFFECTIVE].text,
                    rejected: statuses[EngagementCampaignSocialAccountPostStatus.REJECT].text,
                    reviewing: statuses[EngagementCampaignSocialAccountPostStatus.REVIEWING].text
                  }}
                />
              ) : confirmModalState?.newStatus ? (
                <Trans
                  i18nKey="Dialog.PostUpdateNotice"
                  values={{ status: statuses[confirmModalState.newStatus].text }}
                  components={{ 1: <span css={{ color: statuses[confirmModalState.newStatus].color }} /> }}
                />
              ) : null}
            </div>
          </ModalContent>
          <ModalFooterActions
            cancelButtonProps={{ onClick: resetConfirmModalState }}
            submitButtonProps={{
              title: t('Button.Update'),
              loading: loadingState.status,
              onClick: () => handleUpdatePostsStatus(onSuccess)
            }}
          />
        </Modal>

        <Modal open={!!errorModalState?.title} onClose={resetErrorModalState} css={{ width: THEME.modal.size.lv2 }}>
          <ModalContent>
            <ModalTitle>{errorModalState?.title}</ModalTitle>

            {errorModalState?.content ? <div css={{ marginTop: '16px' }}>{errorModalState.content}</div> : null}
          </ModalContent>
          <ModalFooterActions cancelButtonProps={{ title: t('Button.OK'), onClick: resetErrorModalState }} />
        </Modal>
      </>
    )
  };
};
