import {
  TiktokBusinessWarningStatus,
  useTiktokBusinessAccountInfoQuery,
  useTiktokBusinessAdAccountsQuery
} from '@/graphql';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { TikTokAdAccount, useTikTokAdvertiserSummaryData } from '@/shared/organisms';

export const TikTokAdAccountsCenter = () => {
  useUpdateDocumentTitle({ title: 'appHeader.TikTok Ad Account', appHeader: 'appHeader.TikTok Ad Account' });
  const { data } = useTiktokBusinessAccountInfoQuery();
  const { data: adData } = useTiktokBusinessAdAccountsQuery();
  const { summaryData } = useTikTokAdvertiserSummaryData();

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <TikTokAdAccount
        tiktokBusinessAdAccounts={adData?.tiktokBusinessAdAccounts}
        tiktokBusinessAccountInfo={data?.tiktokBusinessAccountInfo}
        hasAccountsLimitWarning={summaryData?.warningsList.includes(
          TiktokBusinessWarningStatus.REACH_ADS_ACCOUNTS_LIMIT
        )}
      />
    </div>
  );
};
