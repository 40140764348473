import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalTitle, ModalProps, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { InfluencerAvatar } from '@/shared/molecules';
import { AnalyticsComparableAccountType } from './types';

export interface DeleteAnalyticsComparableAccountModalProps<T extends AnalyticsComparableAccountType>
  extends Pick<ModalProps, 'onClose'> {
  loading?: boolean;
  onSubmit?: () => void;
  deletingAccount?: T | null;
  customModal?: { title?: ReactNode; description?: ReactNode };
}
export const DeleteAnalyticsComparableAccountModal = <T extends AnalyticsComparableAccountType>({
  loading,
  onClose,
  onSubmit,
  customModal,
  deletingAccount
}: DeleteAnalyticsComparableAccountModalProps<T>) => {
  const { t } = useTranslation();

  return (
    <Modal open={!!deletingAccount} onClose={onClose} css={{ width: '600px' }}>
      <ModalContent>
        <ModalTitle>{customModal?.title || 'Dialog.Delete comparable account'}</ModalTitle>
        <div css={{ fontSize: '13px', marginTop: '16px' }}>
          {customModal?.description || t('Dialog.DeleteComparableAccountWarning')}
        </div>

        <div css={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
          <InfluencerAvatar src={deletingAccount?.avatarUrl} size="40px" css={{ borderRadius: '50%' }} />
          <p css={{ marginLeft: '8px', fontSize: '13px' }}>{deletingAccount?.username}</p>
        </div>
      </ModalContent>
      <ModalFooterActions
        cancelButtonProps={{ title: t('Button.Cancel'), onClick: onClose }}
        submitButtonProps={{ loading, variant: 'red', onClick: onSubmit, title: t('Button.Delete') }}
      />
    </Modal>
  );
};
