import {
  InfluencerInstagramProfileSponsoredPosts,
  InfluencerInstagramProfileSponsoredPostsProps
} from '../../InfluencerProfiles';

type InfluencerAnalyticsInstagramProfileSponsoredPostsProps = InfluencerInstagramProfileSponsoredPostsProps;

export const InfluencerAnalyticsInstagramProfileSponsoredPosts = (
  props: InfluencerAnalyticsInstagramProfileSponsoredPostsProps
) => <InfluencerInstagramProfileSponsoredPosts {...props} />;
