import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter } from '@/shared/utils';
import { BillingInformation, Maybe, PaymentPlanName, PaymentPriceType } from '@/graphql';

export const getPlanName = (planName?: Maybe<PaymentPlanName>) => {
  switch (planName) {
    case PaymentPlanName.BEGINNER:
      return 'Beginner Plan';
    case PaymentPlanName.STANDARD:
      return 'Standard Plan';
    case PaymentPlanName.ENTERPRISE:
      return 'Enterprise Plan';
    default:
      return '';
  }
};

export const getPriceTypeLabel = (planType?: PaymentPriceType) => {
  switch (planType) {
    case PaymentPriceType.MONTHLY:
      return 'Month';
    case PaymentPriceType.SIX_MONTHS:
      return '6 months';
    case PaymentPriceType.TWELVE_MONTHS:
      return '12 months';
    case PaymentPriceType.ONE_TIME:
      return 'One-time purchase';
    default:
      return '';
  }
};

export const getFirstDayOfNthMonth = (language: string, month = 1) => {
  const today = new Date();

  return localizedDateFormatter(
    new Date(today.getFullYear(), today.getMonth() + month, 1),
    'MMMM d, yyyy',
    language as AppLanguage
  );
};

export const getExpiresCardDate = (billingInformation: BillingInformation) =>
  `${billingInformation.cardExpMonth || ''}/${billingInformation.cardExpYear?.toString().slice(-2) || ''}`;
