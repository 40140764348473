import { useState, useRef } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useScrollElementBottom } from '@/shared/hooks';
import { Button, CircularProgress, IconProps } from '@/shared/atoms';

export interface ChatDemoInputProps {
  loading?: boolean;
  className?: string;
  submitIcon?: IconProps;
  onSubmit?: (message: string, onSuccess: () => void) => void;
}

export const ChatDemoInput = ({ loading, onSubmit, submitIcon, ...restProps }: ChatDemoInputProps) => {
  const [message, setMessage] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const { scrollToBottom } = useScrollElementBottom(textAreaRef, [message]);

  const handleSendMessage = () => {
    if (message) {
      onSubmit?.(message, () => {
        setMessage('');
      });
    }
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Shift + Enter for breaking line and Enter for sending message
    // https://adasiaholdings.atlassian.net/browse/AT-6191
    if (e.key === 'Enter') {
      e.preventDefault();

      // User can move the focus point somewhere then break the line. Default Enter key of textarea will handle this behavior
      // But we are custom the breakline keyboard so we need to handle it manually
      if (e.shiftKey) {
        const currentValue = e.currentTarget.value;
        const selectionEnd = e.currentTarget.selectionEnd;
        const selectionStart = e.currentTarget.selectionStart;

        // Insert breakline after the focusing point but don't set
        e.currentTarget.value = `${currentValue.substring(0, selectionStart)}\r\n${currentValue.substring(selectionEnd, currentValue.length)}`;

        // Scroll down to bottom if break line is the last line
        if (selectionEnd === currentValue.length) {
          scrollToBottom();
        }

        // Move the current focus to after new break line
        e.currentTarget.selectionEnd = selectionStart + 1;
        e.currentTarget.selectionStart = selectionStart + 1;
      } else {
        handleSendMessage();
      }
    }
  };

  return (
    <div css={styles.wrapper} {...restProps}>
      <div css={{ flex: 1, gap: '8px', display: 'flex', alignItems: 'center' }}>
        <Button
          variant="default"
          css={styles.uploadButton}
          prefixIcon={{ size: 20, icon: 'chat-demo-file', color: THEME.icon.colors.gray.lv2 }}
          disabled
        />

        <textarea
          value={message}
          ref={textAreaRef}
          css={styles.input}
          autoCorrect="off"
          autoComplete="off"
          onKeyPress={handlePressEnter}
          placeholder="何か質問してください"
          onChange={(e) => setMessage(e.target.value)}
        />

        <Button
          variant="black"
          disabled={!message}
          css={styles.sendButton}
          onClick={handleSendMessage}
          loading={{
            showIcon: true,
            status: loading,
            icon: (
              <div
                css={{
                  flex: 'none',
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  borderRadius: '50%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'linear-gradient(132.09deg, #5C5BFF 7.57%, #15D4FF 38.38%, #FFD235 69.19%, #FF942E 100%)'
                }}
              >
                <CircularProgress size={22} thickness={0.6} backgroundColor={THEME.text.colors.white} reversed />
              </div>
            )
          }}
          prefixIcon={{ icon: 'arrow-up', size: 15, color: THEME.icon.colors.white, ...submitIcon }}
        />
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    flex: 'none',
    display: 'flex',
    padding: '0 12px',
    minHeight: '56px',
    alignItems: 'center',
    borderRadius: '60px',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0px 0px 0px 8px rgba(185, 192, 200, 0.20)'
  }),
  input: css({
    flex: 1,
    fontWeight: 400,
    fontSize: '16px',
    padding: '16px 0',
    lineHeight: '1.5em',
    fontStyle: 'normal',
    boxSizing: 'border-box',
    backgroundColor: 'inherit',
    color: THEME.input.text.colors.default,
    '&::placeholder': { color: '#C5D0DA' },
    /* stylelint-disable */
    appearance: 'textfield',
    MozAppearance: 'textfield',
    '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
  }),
  sendButton: css({
    padding: 0,
    flex: 'none',
    height: '32px',
    borderRadius: '50%',
    '&:disabled': { backgroundColor: '#DEE5EC' }
  }),
  uploadButton: css({
    padding: 0,
    flex: 'none',
    height: '32px',
    border: 'none',
    minWidth: '24px',
    '&:disabled': { backgroundColor: 'transparent' }
  })
};
