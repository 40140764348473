import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/CmsInvoiceDownloadUrl.graphql';
import { Query, QuerycmsInvoiceDownloadUrlArgs } from '../../__generated__/globalTypes';

export const useCmsInvoiceDownloadUrlQuery = (options?: QueryHookOptions<Query, QuerycmsInvoiceDownloadUrlArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerycmsInvoiceDownloadUrlArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useCmsInvoiceDownloadUrlLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerycmsInvoiceDownloadUrlArgs>
): QueryResult<Query, QuerycmsInvoiceDownloadUrlArgs> & {
  getCmsInvoiceDownloadUrl: LazyQueryExecFunction<Query, QuerycmsInvoiceDownloadUrlArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getCmsInvoiceDownloadUrl, result] = useLazyQuery<Query, QuerycmsInvoiceDownloadUrlArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getCmsInvoiceDownloadUrl, ...result };
};

export const useCmsInvoiceDownloadUrlPromiseQuery = () => {
  const client = useApolloClient();

  const getCmsInvoiceDownloadUrl = useCallback(
    (options?: Omit<QueryOptions<QuerycmsInvoiceDownloadUrlArgs, Query>, 'query'>) =>
      client.query<Query, QuerycmsInvoiceDownloadUrlArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getCmsInvoiceDownloadUrl };
};
