import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignType, CampaignGridReport, CampaignSocialMediaType } from '@/graphql';
import {
  eyePurpleImage,
  bulbYellowImage,
  moneyYellowImage,
  networkBlueImage,
  bookmarkBlueImage,
  thunderYellowImage
} from '@/shared/assets';
import { Anchor, SocialIcon, TextCutter, MediaPreview, RenderDataWithFallback, SocialIconProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useGenerateInfluencerProfileLink, usePostDetailsParam } from '@/shared/hooks';
import { InfluencerPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType } from '@/shared/types';
import { checkIsInstagramStoryPost, checkIsInstagramReelPost, formatIntNumber, formatPercent } from '@/shared/utils';
import { PostReportEmpty } from '../PostReportEmpty';
import { PostReportCaption } from './PostReportCaption';

export interface TemplateProps {
  data: CampaignGridReport;
  campaignType: CampaignType;
  hasTrackingOption: boolean;
  metricsOrder?: (keyof CampaignGridReport)[];
}

export const Template = ({
  data,
  campaignType,
  hasTrackingOption,
  metricsOrder = ['sales', 'reachRate', 'engagementRate', 'saveRate', 'shareRate', 'viewRate']
}: TemplateProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const [postsModal, setPostsModal] = useState<PostDetailsType[]>([]);

  const rank = [
    { color: '#ff2b52', title: '1st' },
    { color: '#ff5575', title: '2nd' },
    { color: '#ff8097', title: '3rd' },
    { color: '#ffaaba', title: '4th' }
  ];
  const rateTypes = {
    sales: { icon: moneyYellowImage, title: 'Sales' },
    reachRate: { icon: thunderYellowImage, title: 'Reach Rate' },
    engagementRate: { icon: bulbYellowImage, title: 'Engagement Rate' },
    saveRate: { icon: bookmarkBlueImage, title: 'Save Rate' },
    shareRate: { icon: networkBlueImage, title: 'Share Rate' },
    viewRate: { icon: eyePurpleImage, title: 'View Rate' }
  };

  const { engagementRate, reachRate, saveRate, shareRate, viewRate, sales } = data;
  const hasNoData =
    !sales?.posts.length &&
    !viewRate?.posts.length &&
    !saveRate?.posts.length &&
    !shareRate?.posts.length &&
    !reachRate?.posts.length &&
    !engagementRate.posts.length;

  const handlePostClick = (rate: keyof CampaignGridReport, postId: number) => {
    const selectedPosts: PostDetailsType[] =
      data[rate]?.posts.map((post) => ({ id: String(post.postInfo.id), campaignType })) || [];
    setPostsModal(selectedPosts);
    setPostDetailsParam(postId);
  };

  return (
    <RenderDataWithFallback hasNoData={hasNoData} noDataNode={<PostReportEmpty />}>
      <div css={{ padding: '8px 16px 16px' }}>
        {metricsOrder.map((key) => {
          const engagementType = data[key];
          const rateType = rateTypes[key];
          const isSales = key === 'sales';
          const hasSalesValue = isSales && !!engagementType?.averageMetricRate;

          return engagementType ? (
            <div css={{ padding: '8px 0' }} key={key}>
              <div css={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <img alt="icon" height="40" width="40" src={rateType.icon} />
                <span css={{ fontSize: '16px', fontWeight: 600 }}>{`${t(rateType.title)}: Avg. ${
                  isSales
                    ? `${formatIntNumber(engagementType.averageMetricRate)} ${engagementType.currency || 'JPY'}`
                    : formatPercent(engagementType.averageMetricRate, true, 2)
                }`}</span>
              </div>

              <div css={{ margin: '-8px', display: 'flex', flexWrap: 'wrap' }}>
                {engagementType.posts.map((post, index) => {
                  const {
                    id,
                    content,
                    postUrl,
                    thumbNail,
                    socialMedia,
                    influencerId,
                    socialUsername,
                    socialAccountId,
                    socialAccountAvatar
                  } = post.postInfo;

                  const rankInfo = rank.at(index);
                  const isReel = checkIsInstagramReelPost(postUrl);
                  const isInstagramStory = checkIsInstagramStoryPost(postUrl);
                  // https://adasiaholdings.atlassian.net/browse/AT-3983: (Joe) show impression for IG and Twitter, not includes IG_STORY
                  const isInstagramOrTwitter = [
                    CampaignSocialMediaType.TWITTER,
                    CampaignSocialMediaType.INSTAGRAM
                  ].includes(socialMedia);
                  const isThreads = socialMedia === CampaignSocialMediaType.THREADS;

                  return (
                    <div key={index} css={{ padding: '8px', flex: '50% 0 0', boxSizing: 'border-box' }}>
                      <div css={{ display: 'flex', boxSizing: 'border-box', border: THEME.border.base }}>
                        <div css={[styles.rank, { backgroundColor: rankInfo?.color }]}>{rankInfo?.title}</div>
                        <MediaPreview
                          src={thumbNail}
                          isReel={isReel}
                          objectFit="contain"
                          onClick={() => handlePostClick(key, id)}
                          css={{ width: '240px', height: '240px' }}
                          caption={content}
                          postReportCaption={
                            <PostReportCaption
                              caption={content}
                              username={socialUsername}
                              avatarUrl={socialAccountAvatar || ''}
                            />
                          }
                          showIcon
                        />
                        <div css={{ flex: 1, padding: '16px', boxSizing: 'border-box' }}>
                          <div css={{ display: 'flex' }}>
                            <SocialIcon size="16px" type={socialMedia as SocialIconProps['type']} />
                            <Anchor
                              label={socialUsername}
                              css={{ fontSize: '13px', fontWeight: 600, marginLeft: '8px' }}
                              {...generateProfileLink(influencerId, {
                                filter: { id: String(socialAccountId), sm: socialMedia }
                              })}
                            />
                          </div>

                          <div css={styles.contentWrapper} onClick={() => handlePostClick(key, id)}>
                            <TextCutter text={content} lines={2} />
                          </div>

                          <div css={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                            <div css={{ fontSize: '12px' }}>{t(rateType.title)}</div>
                            <div css={{ whiteSpace: 'nowrap', fontSize: '18px', fontWeight: 600 }}>
                              {isSales
                                ? `${formatIntNumber(post.sales)} ${
                                    hasSalesValue ? engagementType.currency || 'JPY' : ''
                                  }`
                                : formatPercent(post.rate, true, 2)}
                            </div>
                          </div>

                          <div css={styles.engagement}>
                            <p>
                              {`${t('General.Like')} :`}
                              <span>{formatIntNumber(post.like)}</span>
                            </p>
                            {!isThreads ? (
                              <>
                                <p>
                                  {`${t('General.Reach')} :`}
                                  <span>{formatIntNumber(post.reach)}</span>
                                </p>
                                <p>
                                  {`${t('General.Save')} :`}
                                  <span>{formatIntNumber(post.saved)}</span>
                                </p>
                                <p>
                                  {`${t('General.Share')} :`}
                                  <span>{formatIntNumber(post.share)}</span>
                                </p>
                                <p>
                                  {`${t(
                                    isInstagramStory || isInstagramOrTwitter ? 'General.impressions' : 'General.View'
                                  )} :`}
                                  <span>
                                    {formatIntNumber(
                                      isInstagramStory || isInstagramOrTwitter ? post.impressions : post.view
                                    )}
                                  </span>
                                </p>
                              </>
                            ) : null}
                            <p>
                              {`${t(
                                socialMedia === CampaignSocialMediaType.INSTAGRAM_STORY || isThreads
                                  ? 'General.Replies'
                                  : 'General.Comment'
                              )} :`}
                              <span>{formatIntNumber(post.comment)}</span>
                            </p>
                            <p className="full-width">
                              {`${t('General.ER')} :`}
                              <span>{formatPercent(post.engagementRate, true, 2)}</span>
                            </p>
                            {hasTrackingOption ? (
                              <p className="full-width">
                                {`${t(!hasSalesValue ? 'Actions' : 'Conversion')} :`}
                                <span>{formatIntNumber(post.conversion)}</span>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null;
        })}
      </div>

      <InfluencerPostDetailsModal posts={postsModal} />
    </RenderDataWithFallback>
  );
};
const styles = {
  rank: css({
    zIndex: 1,
    width: '40px',
    height: '40px',
    fontWeight: 600,
    display: 'flex',
    fontSize: '18px',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    color: THEME.text.colors.white
  }),
  contentWrapper: css({
    height: '48px',
    display: 'flex',
    cursor: 'pointer',
    marginTop: '16px',
    marginBottom: '16px',
    alignItems: 'center',
    paddingBottom: '8px',
    boxSizing: 'border-box',
    borderBottom: '1px solid #e6ecf0'
  }),
  engagement: css({
    display: 'flex',
    flexWrap: 'wrap',
    '> p': {
      fontSize: '12px',
      flexBasis: '50%',
      whiteSpace: 'nowrap',
      '&.full-width': { flexBasis: '100%' },
      '&:nth-of-type(2n):not(.full-width)': { textAlign: 'right' },
      '& span': { fontWeight: 600, marginLeft: '8px' }
    }
  })
};
