import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { AutoManagedReportSummary as AutoManagedReportSummaryType } from '@/graphql';
import { ReportSummary, ReportSummaryType } from '@/shared/atoms';
import { formatPercent, formatIntNumber } from '@/shared/utils';

interface AutoManagedReportSummaryProps {
  currency: string;
  data?: AutoManagedReportSummaryType;
}

export const AutoManagedReportSummary = ({ data, currency }: AutoManagedReportSummaryProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();

  const summaries: ReportSummaryType[] = [
    { label: t<string>('Total Participants'), value: formatIntNumber(data?.joinedCount) },
    { label: t<string>('Clicks'), value: formatIntNumber(data?.clicks) },
    { label: t<string>('Orders'), value: formatIntNumber(data?.conversions) },
    { label: t<string>('CVR'), value: formatPercent(data?.cvr, false), unit: '%' },
    { label: t<string>('Store Sales'), value: formatIntNumber(data?.sales), unit: t<string>(currency) },
    { label: t<string>('Cost'), value: formatIntNumber(data?.cost), unit: t<string>(currency) },
    ...(isAdminStaff
      ? [
          {
            unit: t<string>(currency),
            value: formatIntNumber(data?.influencerCost),
            label: t<string>('HeaderColumn.Influencer Cost')
          },
          { label: t<string>('Gross Profit'), unit: t<string>(currency), value: formatIntNumber(data?.grossProfit) }
        ]
      : [])
  ];

  return <ReportSummary summaries={summaries} />;
};
