import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Carousel, InfluencerInfo, InfluencerInfoProps } from '@/shared/molecules';

interface PostInfoSectionProps {
  caption: string;
  images?: string[];
  influencer: InfluencerInfoProps['info'];
  hasImageDownload?: boolean;
}

export const PostInfoSection = ({ caption, images, influencer, hasImageDownload }: PostInfoSectionProps) => (
  <>
    <InfluencerInfo css={{ padding: 0 }} info={influencer} />
    {images?.length ? (
      <Carousel
        css={styles.carousel}
        sources={images}
        showIcon
        imageDownload={{
          allowed: !!hasImageDownload,
          filename: `${influencer.name}_post_images_on_${influencer.postDate}`
        }}
      />
    ) : null}
    <div css={styles.caption}>{caption}</div>
  </>
);

const styles = {
  caption: css({
    padding: '8px',
    height: '152px',
    overflow: 'auto',
    marginTop: '16px',
    boxSizing: 'border-box',
    border: THEME.border.base
  }),
  carousel: css({
    '--imageSize': '422px',
    width: '100%',
    marginTop: '24px',

    '.sliderImage': {
      height: 'var(--imageSize)',
      width: 'var(--imageSize)'
    }
  })
};
