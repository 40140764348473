import { InfluencerPosts } from '@/shared/organisms';
import { useDefaultInfluencerSearchProps, useInfluencerPostsSelectButtonsProps } from './hooks';

export const EngagementInfluencerPostsSearch = () => {
  const { isEngagementPage, isTtcmCampaign } = useDefaultInfluencerSearchProps();
  const { getSortSelectButtonProps, getListSelectButtonProps } = useInfluencerPostsSelectButtonsProps();

  return (
    <InfluencerPosts
      isTtcmCampaign={isTtcmCampaign}
      isEngagementPage={isEngagementPage}
      getListSelectButtonProps={getListSelectButtonProps}
      getSortSelectButtonProps={getSortSelectButtonProps}
    />
  );
};
