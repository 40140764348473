import { CSSProperties, ReactNode } from 'react';
import { Resizable } from 're-resizable';
import { CSS, Transform } from '@dnd-kit/utilities';
import { UniqueIdentifier, useDraggable } from '@dnd-kit/core';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { ChatBoxCoordinates } from './types';
import { useChatBoxResizable } from './hooks';
import { useChatBoxRndContext } from './ChatBoxRndContext';

export interface ChatBoxRndItemProps {
  className?: string;
  id: UniqueIdentifier; // Unique id among draggable elements in page
  children: (props: {
    draggedElementProps: any; // NOTE: in children wrapper element must set {...restProps}
  }) => ReactNode;
}

export const ChatBoxRndItem = ({ id, className, children }: ChatBoxRndItemProps) => {
  const { rndItemRef, draggable, resizable, chatBoxSize, chatBoxCoordinates } = useChatBoxRndContext();
  // Call useDraggable inside each hook won't work properly
  const { node, listeners, transform, isDragging, setNodeRef, attributes } = useDraggable({ id, disabled: !draggable });
  const { initialSize, resizableRef, handleResizeStop, handleResizeStart } = useChatBoxResizable({ node });

  return (
    // Don't move this component inside hook cause issue "react maximum update depth exceeded"
    <Resizable
      ref={resizableRef}
      bounds="window" // Need prop boundsByDirection=true
      boundsByDirection
      className={className}
      onResizeStop={handleResizeStop}
      onResizeStart={handleResizeStart}
      css={[THEME.chatbox.position, isDragging && styles.dragging]}
      minWidth={resizable ? initialSize.current?.width : undefined}
      minHeight={resizable ? initialSize.current?.height : undefined}
      // Can not set size undefined cause makes some weird behavior
      // Resizable opening issue when enable switch true/false value: https://github.com/bokuweb/re-resizable/issues/184
      size={resizable ? chatBoxSize : { width: 'auto', height: 'auto' }}
      style={styles.resizableInlineStyles({ transform, isDragging, chatBoxCoordinates })}
      enable={!!chatBoxSize?.width && !!chatBoxSize.height && (resizable === true ? undefined : resizable ?? false)} // resizable === true ? undefined -> This component always resizable and default this prop is undefined else is false or customization
    >
      <div
        ref={(e) => {
          setNodeRef(e);
          rndItemRef.current = e;
        }}
        css={[styles.draggable, resizable && chatBoxSize && styles.resizable]}
      >
        {children({ draggedElementProps: { ...listeners, ...attributes } })}
      </div>
    </Resizable>
  );
};

const styles = {
  // When dragging, children will keep the width/height, parent only focus to the coordinates
  draggable: css({
    width: 'max-content',
    height: 'max-content',
    '& [aria-disabled="false"][aria-roledescription="draggable"]': { cursor: 'grab' }
  }),
  // When resizing, parent will keep the width/height so we need to override the children's size
  resizable: css({
    width: '100%',
    height: '100%',
    minWidth: 'inherit',
    minHeight: 'inherit',
    '& > *:first-of-type': {
      width: 'inherit !important',
      height: 'inherit !important',
      minWidth: 'inherit !important',
      minHeight: 'inherit !important'
    }
  }),
  dragging: css({ cursor: 'grabbing !important', '*': { cursor: 'grabbing !important' } }),
  resizableInlineStyles: ({
    transform,
    isDragging,
    chatBoxCoordinates
  }: {
    isDragging: boolean;
    transform: Transform | null;
    chatBoxCoordinates: ChatBoxCoordinates | null;
  }): CSSProperties => ({
    position: 'fixed',
    transform: CSS.Translate.toString(transform),
    // Override current position
    // This should use for inline style to avoid regenerate className while dragging
    ...(chatBoxCoordinates
      ? {
          top: chatBoxCoordinates?.top ?? 'auto',
          left: chatBoxCoordinates?.left ?? 'auto',
          right: chatBoxCoordinates?.right ?? 'auto',
          bottom: chatBoxCoordinates?.bottom ?? 'auto'
        }
      : {}),
    ...(isDragging ? { boxShadow: '-1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)' } : {})
  })
};
