import { PostDetailsType } from '@/shared/types';
import { YoutubeTrendingPostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface YoutubeTrendPostDetailsModalProps {
  posts: PostDetailsType[];
}

export const YoutubeTrendPostDetailsModal = ({ posts }: YoutubeTrendPostDetailsModalProps) => (
  <CarouselTemplateModal posts={posts}>
    {({ selectedPost, closeModal }) => <YoutubeTrendingPostDetails id={selectedPost.id} onCloseModal={closeModal} />}
  </CarouselTemplateModal>
);
