import { useSearch } from '@tanstack/react-router';
import { ToggleOption, TtcmStatusOption } from '@/shared/types';
import { useAllTalentAgencyOrPartnerOptions } from '@/shared/hooks';
import { Genders, SocialAccountInfluencerAudience } from '@/graphql';
import { getOffset, convertGenders, getVariableRateValue, splitPartnerAndTalentAgenciesIds } from '@/shared/utils';
import {
  MIN_AGE,
  MAX_AGE,
  MIN_VIEWS,
  MAX_VIEWS,
  MIN_REACH,
  MAX_REACH,
  MIN_PRICE,
  MAX_PRICE,
  MIN_FOLLOWERS,
  MAX_FOLLOWERS,
  MIN_SAVE_RATE,
  MAX_SAVE_RATE,
  MIN_JOINED_CAMPAIGNS,
  MAX_JOINED_CAMPAIGNS,
  MIN_ENGAGEMENT_RATE,
  MAX_ENGAGEMENT_RATE
} from '@/shared/constants';
import { InfluencersBaseSearchSchemaType, InfluencersFilterFormValues } from '../types';

type Props = {
  page: number;
  limit: number;
  filter: Partial<InfluencersFilterFormValues>;
};

export const useGetInfluencersQueryVariables = ({
  page,
  limit,
  filter: {
    minAge,
    maxAge,
    status,
    keyword,
    genders,
    statuses,
    minViews,
    maxViews,
    minReach,
    maxReach,
    regionIds,
    interests,
    countryId,
    includeIds,
    ttcmStatus,
    countryIds,
    categoryIds,
    socialMedia,
    minSaveRate,
    maxSaveRate,
    minPostDate,
    maxPostDate,
    minPostPrice,
    maxPostPrice,
    keywordField,
    minFollowers,
    maxFollowers,
    socialMedias,
    minStoryPrice,
    maxStoryPrice,
    minVideoPrice,
    maxVideoPrice,
    audienceAge,
    audienceRatio,
    audienceGender,
    campaignDisplay,
    minPicturePrice,
    maxPicturePrice,
    minEngagementRate,
    maxEngagementRate,
    minEngagementViewsRate,
    maxEngagementViewsRate,
    minEngagementFollowersRate,
    maxEngagementFollowersRate,
    minCampaignsJoined,
    maxCampaignsJoined,
    campaignCategoryIds,
    followerGrowthPeriod,
    talentAgencyOrPartnerIds,
    followerGrowthPercentage,
    demographicGender,
    demographicAge,
    demographicAgeMinRate,
    demographicGenderMinRate
  }
}: Props) => {
  const { state } = useSearch({ strict: false }) as InfluencersBaseSearchSchemaType;
  const { allTalentAgenciesPartners } = useAllTalentAgencyOrPartnerOptions();

  const [start, end] = audienceAge?.split(', ') || ['', ''];
  const [dStart, dEnd] = demographicAge?.split(', ') || ['', ''];
  const hasAudienceAge = start && end;
  const { talentAgencyIds, partnerIds } = splitPartnerAndTalentAgenciesIds(
    talentAgencyOrPartnerIds?.map(Number) || [],
    allTalentAgenciesPartners
  );

  // Don't reset this state right after receiving cause make API call again immediately
  // We need to keep Empty UI showing until next filter coming

  return {
    skipSearch: state?.skipSearch ?? false,
    variables: {
      limit,
      offset: getOffset(page, limit),
      // Optional fields
      ...(!!status && { status }),
      ...(!!keyword && { keyword }),
      ...(!!countryId && { countryId }),
      ...(!!minPostDate && { minPostDate }),
      ...(!!maxPostDate && { maxPostDate }),
      ...(!!socialMedia && { socialMedia }),
      ...(!!keywordField && { keywordField }),
      ...(!!statuses?.length && { statuses }),
      ...(!!interests?.length && { interests }),
      ...(!!partnerIds.length && { partnerIds }),
      ...(!!countryIds?.length && { countryIds }),
      ...(!!socialMedias?.length && { socialMedias }),
      ...(!!talentAgencyIds.length && { talentAgencyIds }),
      ...(!!campaignCategoryIds?.length && { campaignCategoryIds }),
      ...(!!genders?.length && { genders: convertGenders(genders) }),
      ...(!!regionIds?.length && { regionIds: regionIds.map(Number) }),
      ...(!!includeIds?.length && { includeIds: includeIds.map(Number) }),
      ...(!!categoryIds?.length && { categoryIds: categoryIds.map(Number) }),
      ...(!!campaignDisplay && { campaignDisplay: campaignDisplay === ToggleOption.TRUE }),
      ...(typeof minAge === 'number' && minAge !== MIN_AGE && { minAge }),
      ...(typeof maxAge === 'number' && maxAge !== MAX_AGE && { maxAge }),
      ...(typeof minViews === 'number' && minViews !== MIN_VIEWS && { minViews }),
      ...(typeof maxViews === 'number' && maxViews !== MAX_VIEWS && { maxViews }),
      ...(typeof minReach === 'number' && minReach !== MIN_REACH && { minReach }),
      ...(typeof maxReach === 'number' && maxReach !== MAX_REACH && { maxReach }),
      ...(typeof minPostPrice === 'number' && minPostPrice !== MIN_PRICE && { minPostPrice }),
      ...(typeof maxPostPrice === 'number' && maxPostPrice !== MAX_PRICE && { maxPostPrice }),
      ...(typeof minStoryPrice === 'number' && minStoryPrice !== MIN_PRICE && { minStoryPrice }),
      ...(typeof maxStoryPrice === 'number' && maxStoryPrice !== MAX_PRICE && { maxStoryPrice }),
      ...(typeof minVideoPrice === 'number' && minVideoPrice !== MIN_PRICE && { minVideoPrice }),
      ...(typeof maxVideoPrice === 'number' && maxVideoPrice !== MAX_PRICE && { maxVideoPrice }),
      ...(typeof minFollowers === 'number' && minFollowers !== MIN_FOLLOWERS && { minFollowers }),
      ...(typeof maxFollowers === 'number' && maxFollowers !== MAX_FOLLOWERS && { maxFollowers }),
      ...(typeof minPicturePrice === 'number' && minPicturePrice !== MIN_PRICE && { minPicturePrice }),
      ...(typeof maxPicturePrice === 'number' && maxPicturePrice !== MAX_PRICE && { maxPicturePrice }),
      ...(typeof minCampaignsJoined === 'number' &&
        minCampaignsJoined !== MIN_JOINED_CAMPAIGNS && { minCampaignsJoined }),
      ...(typeof maxCampaignsJoined === 'number' &&
        maxCampaignsJoined !== MAX_JOINED_CAMPAIGNS && { maxCampaignsJoined }),
      ...(typeof minSaveRate === 'number' &&
        minSaveRate !== MIN_SAVE_RATE && { minSaveRate: getVariableRateValue(minSaveRate) }),
      ...(typeof maxSaveRate === 'number' &&
        maxSaveRate !== MAX_SAVE_RATE && { maxSaveRate: getVariableRateValue(maxSaveRate) }),
      ...(typeof minEngagementRate === 'number' &&
        minEngagementRate !== MIN_ENGAGEMENT_RATE && { minEngagementRate: getVariableRateValue(minEngagementRate) }),
      ...(typeof maxEngagementRate === 'number' &&
        maxEngagementRate !== MAX_ENGAGEMENT_RATE && { maxEngagementRate: getVariableRateValue(maxEngagementRate) }),
      ...(typeof minEngagementViewsRate === 'number' &&
        minEngagementViewsRate !== MIN_ENGAGEMENT_RATE && {
          minEngagementViewsRate: getVariableRateValue(minEngagementViewsRate)
        }),
      ...(typeof maxEngagementViewsRate === 'number' &&
        maxEngagementViewsRate !== MAX_ENGAGEMENT_RATE && {
          maxEngagementViewsRate: getVariableRateValue(maxEngagementViewsRate)
        }),
      ...(typeof minEngagementFollowersRate === 'number' &&
        minEngagementFollowersRate !== MIN_ENGAGEMENT_RATE && {
          minEngagementFollowersRate: getVariableRateValue(minEngagementFollowersRate)
        }),
      ...(typeof maxEngagementFollowersRate === 'number' &&
        maxEngagementFollowersRate !== MAX_ENGAGEMENT_RATE && {
          maxEngagementFollowersRate: getVariableRateValue(maxEngagementFollowersRate)
        }),
      ...(ttcmStatus && ttcmStatus !== TtcmStatusOption.ALL && { isTtcm: ttcmStatus === TtcmStatusOption.TTCM }),
      ...(audienceGender &&
        audienceRatio && {
          audience: {
            gender: audienceGender as Genders,
            minPercentage: Number(audienceRatio),
            age: hasAudienceAge ? { start: Number(start), end: Number(end) } : null
          } as SocialAccountInfluencerAudience
        }),
      ...(followerGrowthPeriod && { followerGrowthPeriod }),
      ...(followerGrowthPercentage && {
        followerGrowthPercentage: getVariableRateValue(Number(followerGrowthPercentage))
      }),
      ...(demographicAge &&
        demographicAgeMinRate && {
          demographicsAge: {
            age: { end: +(dEnd ?? 0), start: +(dStart ?? 0) },
            minPercentage: +demographicAgeMinRate
          }
        }),
      ...(demographicGender &&
        demographicGenderMinRate && {
          demographicsGender: {
            gender: demographicGender,
            minPercentage: +demographicGenderMinRate
          }
        })
    }
  };
};
