import { ColorVariant } from '@/shared/types';
import { Status, StatusProps } from '@/shared/atoms';
import { TikTokSpecialCampaignStatus } from '@/graphql';
import { useTikTokCampaignStatusOptions } from '../TikTokCampaignsFilter';

export interface TikTokCampaignStatusProps {
  className?: string;
  status?: TikTokSpecialCampaignStatus;
}

export const TikTokCampaignStatus = ({ status, className }: TikTokCampaignStatusProps) => {
  const { statusOptions } = useTikTokCampaignStatusOptions();

  const label = statusOptions.find((o) => o.value === status)?.label || '';
  const statusProps: StatusProps = (() => {
    switch (status) {
      case TikTokSpecialCampaignStatus.DRAFT:
        return { label, variant: ColorVariant.LIGHT_YELLOW };
      case TikTokSpecialCampaignStatus.REVIEWING:
        return { label, variant: ColorVariant.LIGHT_PURPLE };
      case TikTokSpecialCampaignStatus.UPCOMING:
        return { label, variant: ColorVariant.LIGHT_BLUE };
      case TikTokSpecialCampaignStatus.ONGOING:
        return { label, variant: ColorVariant.LIGHT_GREEN };
      case TikTokSpecialCampaignStatus.SUSPENDED:
        return { label, variant: ColorVariant.LIGHT_RED };
      case TikTokSpecialCampaignStatus.REJECTED:
      default:
        return { label, variant: ColorVariant.LIGHT_GREY };
    }
  })();

  return <Status className={className} {...statusProps} />;
};
