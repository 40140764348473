import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthReconnectFacebookPageAccountAnalytics.graphql';
import { Mutation, MutationsocialAuthReconnectFacebookPageAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useSocialAuthReconnectFacebookPageAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthReconnectFacebookPageAccountAnalyticsArgs>
) => {
  const [callSocialAuthReconnectFacebookPageAccountAnalytics, result] = useMutation<
    Mutation,
    MutationsocialAuthReconnectFacebookPageAccountAnalyticsArgs
  >(MUTATION, options);

  return { callSocialAuthReconnectFacebookPageAccountAnalytics, ...result };
};

export type SocialAuthReconnectFacebookPageAccountAnalyticsMutationFunction =
  Mutation['socialAuthReconnectFacebookPageAccountAnalytics'];
