import { FormStyle } from '@/shared/atoms';
import { FileFieldProps, FileField } from '../FileField';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export type FileUploadFieldProps<T extends string = string> = DefaultFieldProps<T, string | string[]> &
  Pick<FileFieldProps, 'notes' | 'target' | 'accept' | 'itemsPerRow'>;

export const FileUploadField = <T extends string>(props: FileUploadFieldProps<T>) => {
  const {
    fieldRegister,
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, value, multiple, onChange, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  const handleChangeFiles = (_: string, files: string[]) => {
    const newValue = multiple ? files : files.at(0) || '';
    if (onChange) {
      onChange(name, newValue);
    } else {
      setValue<string>(name, newValue, setValueOptions);
    }
  };

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      {({ hasError }) => (
        <>
          <FormStyle.FocusInput ref={fieldRegister.ref} />
          <FileField
            itemsPerRow={3}
            {...restFieldProps}
            name={name}
            hasError={hasError}
            multiple={multiple}
            onChange={handleChangeFiles}
            value={Array.isArray(value) ? value : value ? [value] : []}
          />
        </>
      )}
    </RHFFieldWrapper>
  );
};
