import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { NoDataTitle, NoDataContentWrapper, ChartTooltipSingleMetric, NoDataContentWrapperProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatPercent, getEstimatedWrapperText } from '@/shared/utils';
import { HighChart, Highcharts } from '../HighChart';

interface GenderBreakdownData {
  maleRate?: number | null;
  femaleRate?: number | null;
}

export interface GenderBreakdownChartProps
  extends GenderBreakdownData,
    Pick<Highcharts.ChartOptions, 'width'>,
    Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'> {
  hasEstimatedDemographics?: boolean;
}

export const GenderBreakdownChart = ({
  width,
  header,
  maleRate,
  femaleRate,
  wrapperText,
  hasEstimatedDemographics,
  ...noDataContentProps
}: GenderBreakdownChartProps) => {
  const { t } = useTranslation();
  const options: Highcharts.Options = {
    title: { text: '' },
    chart: { width, type: 'pie', height: 320, spacingBottom: 0 },
    plotOptions: {
      pie: {
        cursor: 'pointer',
        showInLegend: true,
        allowPointSelect: true,
        dataLabels: {
          distance: -20,
          enabled: true,
          borderRadius: 5,
          backgroundColor: 'white',
          color: THEME.text.colors.black.lv1,
          formatter() {
            return formatPercent(this.y || null);
          }
        }
      }
    },
    legend: {
      symbolHeight: 15,
      symbolWidth: 15,
      symbolRadius: 2,
      itemMarginBottom: 6,
      itemStyle: { fontSize: '13px', color: THEME.text.colors.black.lv1, fontWeight: 'normal' }
    },
    series: [
      {
        type: 'pie',
        innerSize: '50%',
        data: [
          { y: femaleRate, color: THEME.chart.colors.gender.female, name: t<string>('Female') },
          { y: maleRate, color: THEME.chart.colors.gender.male, name: t<string>('Male') }
        ]
      }
    ],
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      borderColor: THEME.chart.tooltip.border.colors.green,
      backgroundColor: THEME.chart.tooltip.background.colors.black,
      style: { color: THEME.chart.tooltip.text.colors.white },
      formatter() {
        return renderToStaticMarkup(
          <ChartTooltipSingleMetric name={this.name} value={formatPercent(this.y)} color={this.color as string} />
        );
      }
    }
  };

  return (
    <NoDataContentWrapper
      css={{ padding: '24px 16px' }}
      dataNotAvailable={!femaleRate && !maleRate}
      wrapperText={getEstimatedWrapperText({ type: 'gender', wrapperText, hasEstimatedDemographics })}
      header={header ?? <NoDataTitle title={t(hasEstimatedDemographics ? 'Estimated Gender' : 'BasicInfo.Gender')} />}
      {...noDataContentProps}
    >
      <HighChart options={options} />
    </NoDataContentWrapper>
  );
};
