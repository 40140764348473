import { useTranslation } from 'react-i18next';
import { FileUploadFieldProps, FileUploadField } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const ThumbnailUploadField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  ...restProps
}: Partial<FileUploadFieldProps<T>>) => {
  const { t } = useTranslation();

  return (
    <FileUploadField<T>
      target="thumbnail"
      itemsPerRow={4}
      title={t('General.CampaignThumbnail')}
      help={t('Tooltip.CampaignThumbnailHelp')}
      name={name || (MarketplaceFormKeys.thumbnails as T)}
      multiple
      {...restProps}
    />
  );
};
