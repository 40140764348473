import { TikTokSpecialCampaignDetail } from '@/graphql';
import { Table } from '@/shared/atoms';
import { useTableMaxHeight } from '@/shared/hooks';
import { ActionBar } from './ActionBar';
import {
  TikTokCampaignPostsListTableProps,
  useTikTokCampaignPostsListTable,
  useUpdateTikTokPostsStatus
} from './hooks';

export interface TikTokCampaignPostsListProps extends TikTokCampaignPostsListTableProps {
  loading?: boolean;
  tiktokSpecialCampaign: TikTokSpecialCampaignDetail;
}

export const TikTokCampaignPostsList = ({ posts, loading, tiktokSpecialCampaign }: TikTokCampaignPostsListProps) => {
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();
  const { UpdateTikTokSpecialPostStatusModal } = useUpdateTikTokPostsStatus(tiktokSpecialCampaign.id);
  const { rows, columns, postsActions } = useTikTokCampaignPostsListTable({
    posts
  });

  return (
    <div css={{ position: 'relative' }}>
      <Table
        ref={offsetRef}
        loading={loading}
        noTopBorderRadius
        maxHeight={defaultTableMaxHeight}
        data={{ rows, columns, rowHeight: '61px' }}
        stickyHeader
      />
      <ActionBar {...postsActions} status={tiktokSpecialCampaign.status} campaignId={tiktokSpecialCampaign.id} />

      <UpdateTikTokSpecialPostStatusModal onSuccess={() => postsActions.setSelectedItemIds([])} />
    </div>
  );
};
