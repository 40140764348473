import { Table } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TableData } from '@/shared/types';
import { TiktokAdAccountInfo } from '@/graphql';
import { useAdvertiserAdAccountsTable } from './hooks';

export type AdvertiserAdAccountsListProps = TableData<TiktokAdAccountInfo>;

export const AdvertiserAdAccountsList = ({ loading, data = [] }: AdvertiserAdAccountsListProps) => {
  const { rows, columns } = useAdvertiserAdAccountsTable({ data });

  return (
    <Table
      loading={loading}
      data={{ rows, columns }}
      empty={{ hideDescription: true }}
      css={{ boxShadow: THEME.shadows.boxShadow }}
      noTopBorderRadius
    />
  );
};
