import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/WithdrawMoneyFromAdAccount.graphql';
import { Mutation, MutationwithdrawMoneyFromAdAccountArgs } from '../../__generated__/globalTypes';

export const useWithdrawMoneyFromAdAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationwithdrawMoneyFromAdAccountArgs>
) => {
  const [callWithdrawMoneyFromAdAccount, result] = useMutation<Mutation, MutationwithdrawMoneyFromAdAccountArgs>(
    MUTATION,
    options
  );

  return { callWithdrawMoneyFromAdAccount, ...result };
};

export type WithdrawMoneyFromAdAccountMutationFunction = Mutation['withdrawMoneyFromAdAccount'];
