import { TFunction } from 'i18next';
import { EngagementCampaignSocialAccountPostStatus } from '@/graphql';
import { HelpIcon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export const isStatusChangeable = (status: EngagementCampaignSocialAccountPostStatus | null) =>
  !!status &&
  [
    EngagementCampaignSocialAccountPostStatus.REVIEWING,
    EngagementCampaignSocialAccountPostStatus.EFFECTIVE,
    EngagementCampaignSocialAccountPostStatus.WARNING,
    EngagementCampaignSocialAccountPostStatus.WARNING_SOLVED,
    EngagementCampaignSocialAccountPostStatus.REJECT,
    EngagementCampaignSocialAccountPostStatus.JOINED
  ].includes(status);

interface DottedLineMessageProps {
  t: TFunction;
  postContent: string | null;
  status?: EngagementCampaignSocialAccountPostStatus | null;
}
export const getDottedLineMessage = ({ t, status, postContent }: DottedLineMessageProps) => {
  switch (status) {
    case EngagementCampaignSocialAccountPostStatus.JOINED:
      return t('Add Post');
    case EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_ORDER:
      return t('Not posted yet');
    case EngagementCampaignSocialAccountPostStatus.JOINED_WITH_PENDING_INVITATION:
      return t('TTCM invitation has been sent');
    case EngagementCampaignSocialAccountPostStatus.TTCM_REVIEWING:
      return t('Option.TikTok Review');
    case EngagementCampaignSocialAccountPostStatus.REJECT:
      return (
        <span css={{ display: 'flex' }}>
          {t(`TikTok's review failed`)}
          &nbsp;
          <HelpIcon
            position="top"
            css={{ color: THEME.icon.colors.gray.lv2 }}
            help={t('Tooltip.Ask creator to re-post a video')}
          />
        </span>
      );
    case EngagementCampaignSocialAccountPostStatus.WARNING:
      return postContent || '-';

    default:
      return '';
  }
};
