import { UserRoles, AssociateCompanyType } from '@/graphql';

export const getUserRoleString = (role: UserRoles | AssociateCompanyType | null) => {
  switch (role) {
    case UserRoles.ADMINISTRATOR:
      return 'Administrator';
    case UserRoles.STAFF:
      return 'Staff';
    case UserRoles.ADVERTISER:
      return 'Advertiser';
    case UserRoles.AGENCY:
      return 'Agency';
    case UserRoles.TALENT_AGENCY:
      return 'Talent Agency';
    case UserRoles.PARTNER:
      return 'Partner';
    default:
      return '';
  }
};
