import { useTranslation } from 'react-i18next';
import { DayPickerRange } from '@/shared/molecules';
import { SelectedRangeOption } from '@/shared/types';
import { BasicAccountAnalyticsPayload } from '@/graphql';
import { getToday, getTwoYearsAgo } from '@/shared/utils';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { useAnalyticsProfileSearch } from '../../hooks';
import { AnalyticsAccountsSelector } from './AnalyticsAccountsSelector';
import { DownloadAnalyticsAccountsButton } from './DownloadAnalyticsAccountsButton';

interface AnalyticsHeaderActionsProps {
  className?: string;
  brandAccount: BasicAccountAnalyticsPayload;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsHeaderActions = ({ className, brandAccount, analyticsAccount }: AnalyticsHeaderActionsProps) => {
  const { t } = useTranslation();
  const { filter, setFilter } = useAnalyticsProfileSearch();
  const isAllTime = !filter?.startDate && !filter?.endDate;

  return (
    <div css={{ display: 'flex', gap: '8px' }} className={className}>
      <AnalyticsAccountsSelector analyticsAccount={analyticsAccount} brandAccount={brandAccount} />

      <DownloadAnalyticsAccountsButton filter={filter} analyticsAccount={analyticsAccount} />

      <DayPickerRange
        period={filter}
        clearable={false}
        handleChangePeriod={setFilter}
        disabledRange={{ after: new Date() }}
        initialPeriod={{ startDate: getTwoYearsAgo(), endDate: getToday() }} // for All_Time
        customInputTextNode={isAllTime ? <p css={{ color: '#d5d5d5' }}>{t('Select post date')}</p> : undefined}
        rangeList={[
          SelectedRangeOption.TODAY,
          SelectedRangeOption.TODAY_TO_MONTH_AGO,
          SelectedRangeOption.THIS_MONTH,
          SelectedRangeOption.LAST_MONTH,
          SelectedRangeOption.CUSTOM
        ]}
      />
    </div>
  );
};
