import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Anchor, ButtonSubmit, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PasswordField, TextField } from '@/shared/molecules';
import { SignInFormKeys, SignInFormValues } from '../../SignIn/SignInForm/types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    formState: { isSubmitting }
  } = useFormContext<SignInFormValues>();

  return (
    <FormStyle.Wrapper css={{ position: 'relative', margin: '48px auto', width: 'max-content' }}>
      <div css={{ width: '348px', margin: '0 auto' }}>
        <TextField<SignInFormKeys> name="email" title={t('TextForm.Email')} placeholder="your@email.com" />
        <PasswordField<SignInFormKeys> name="password" title={t('Password.Your Password')} required />

        <ButtonSubmit
          variant="blue"
          loading={isSubmitting}
          title={t('Button.Sign In')}
          css={{ width: '100%', marginTop: '24px' }}
        />
      </div>

      <p css={{ marginTop: '32px', color: THEME.text.colors.gray.lv3, fontSize: '11px', lineHeight: '140%' }}>
        <Trans
          i18nKey="SignInConfirmation"
          components={{
            a1: <Anchor variant="blue" css={{ display: 'inline', lineHeight: 'inherit' }} to="/privacy" />,
            a2: <Anchor variant="blue" css={{ display: 'inline', lineHeight: 'inherit' }} to="/terms" />
          }}
        />
      </p>
    </FormStyle.Wrapper>
  );
};
