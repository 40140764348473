import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Anchor } from '@/shared/atoms';

export const TermsAnytagEn = () => (
  <div css={styles.container}>
    <h2 css={{ alignSelf: 'center' }}>
      TERMS AND CONDITIONS OF SERVICE
      <br />
      FOR USERS OF THE ANYTAG PLATFORM
    </h2>
    <p>
      AnyTag® Platform (hereinafter referred to as “Platform”) is a social influencer marketing service and social
      media analysis service provided by AnyMind Group Pte. Ltd. and its subsidiaries (hereinafter referred to as
      “AnyMind”), which provide one-stop solution for Client to select influencer based on the categorize globally,
      manage the campaign Client intends to promote, analyze the performance of the influencer and monitor the
      performance of the campaign 24/7 (hereinafter referred to as “AnyTag Service”).
    </p>
    <p>
      AnyMind has made every attempt to ensure the reliability and accuracy of information provided on Platform, but as
      such, the information available on Platform is provided without warranty of any kind. AnyMind does not and will
      not accept liability or responsibility of any manner for the completeness, accuracy, content, legality, or
      reliability of the information on this. AnyTag Service, as well as all information, products, contents, and other
      services included, are made available to you on an "as is" basis unless specified in writing. You also agree and
      acknowledge that the analytical function which links to third parties platforms may not reflect analytics or
      campaign figures due to the circumstances of data sources beyond AnyMind’s control, AnyMind shall bear no
      liability or accuracy of all data provided by third parties platforms whenever such events occur. You agree that
      you use the AnyTag Marketplace AnyMind at your sole risk.
    </p>
    <p>
      By accessing, using, and browsing this, you acknowledge that you have read, understood, and agree to be bound by
      these terms and conditions below, and to comply with all applicable laws, rules and regulations.
    </p>
    <h3>1. AGREEMENT</h3>
    <p>
      In consideration of the terms outlined in this Agreement, Client and AnyMind agree to enter into an agreement
      whereby AnyMind shall provide access to Platform and AnyTag Service to Client for a specific price and duration as
      mutually agreed specified in sale order.
    </p>
    <h3>2. DEFINITIONS</h3>
    <p>
      <strong>Client:</strong> The legal or natural person(s), or entity that is using AnyMind's services covered in
      AnyTag Marketplace.
    </p>
    <p>
      <strong>Influencer(s):</strong> The legal or natural person(s), or entity providing services to AnyMind and its
      Clients through Platform.
    </p>
    <p>
      <strong>Sale Order:</strong> A supplementary contract issued either electronically or as a physical copy which
      details certain aspects of the agreement between the Client and AnyMind, such as the scope of services required,
      fees, payment details, and the timeline for a certain Live Campaign.
    </p>
    <p>
      <strong>Platform:</strong> an online platform under the ownership of AnyMind which can be utilized by Client to
      connect with Influencers to market the Client’s product and analyze the performance in real-time.
    </p>
    <p>
      <strong>Live Campaign:</strong> a project uploaded by Client which requires bidding by different Influencers, who
      shall then propose a method of rolling out the Campaign to be agreed upon by the Client.
    </p>
    <h3>3. TERMS AND CONDITIONS</h3>
    <p>
      AnyMind provides access to Platform and Anytag Service subject to the general terms and conditions that are given
      below. Client completely and unequivocally agree to be bound by Terms and Conditions of Service on AnyTag Platform
      (hereinafter referred to as “Terms and Conditions, Terms”) without modification. If Client does not intend to be
      legally bound to the Terms and Conditions, Client may not access or otherwise use the Platform or AnyTag Service.
      AnyMind reserves the right, at any time, to update and change any or all of these Terms and Conditions, at its
      sole discretion.
    </p>
    <p>
      Client accepts Terms and Conditions of Service on AnyTag Platform (hereinafter referred to as “Terms and
      Conditions, Terms”) in the following events by:
    </p>
    <p>
      (1) executing these Terms and Conditions by electronically accepting these Terms and Conditions , and by accessing
      Platform thereafter; or
    </p>
    <p>
      (2) subsequently executing an Sale Order that will further detail specifics of this agreement on a case by case
      basis.
    </p>
    <p>
      By using Platform, Client represents and warrants that Client has the right, authority and capacity to enter into
      the Agreement. If the Client does not meet all of these requirements, the Client must not access or use Platform
      or AnyTag Service.
    </p>
    <p>
      AnyMind makes no claims that AnyTag Service/Platform or any of its contents is accessible or appropriate based on
      the domicile of Client. Access to AnyTag Service/Platform may not be legal for certain persons or in certain
      countries. If Client accesses Platform, Client does so on its own initiative and as such, shall be responsible for
      compliance with local laws.
    </p>
    <h3>4. TRADEMARKS</h3>
    <p>
      AnyMind’s name, logo and all related names, logos, product and service names, designs and slogans including but
      not limited to AnyTag are trademarks of AnyMind or its Affiliates. Client must not use such marks without the
      prior written permission from AnyMind. Client’s names, logos, product and service names, designs and slogans are
      the trademarks of Client. All other names, logos, product and service names, designs and slogans on Platform are
      the trademarks of their respective owners.
    </p>
    <h3>5. SCOPE DEFINITION</h3>
    <p>
      AnyTag Service associated with these Terms and Conditions will be defined with a detailed statement of what is
      required from the Influencer in a particular Live Campaign provided by Client. Client agrees and certifies that
      all requirements shall be complied with the applicable laws and regulations. Client will upload the Live Campaign
      on the Platform for Influencers to view and choose from. If Client accepts Influencer, Client agrees that Client
      is now under a contractual obligation to pay for the service fee under the terms and conditions contained herein
      and subsequent parameters for a particular Live Campaign. Any such Live Campaign remains valid for the terms and
      conditions of that Live Campaign unless superseded, extended, or altered by mutual terms and conditions of Client
      and AnyMind as a case maybe. Any such extension or alteration must be agreed in writing through email exchange for
      consent.
    </p>
    <h3>6. FEES AND PAYMENT</h3>
    <p>
      <strong>Sale Order:</strong> If Sale Order is issued for the purchase of AnyTag Service, Client agrees to pay all
      fees as and when described in the applicable Sale Order. AnyMind shall invoice Client for the fees in the currency
      set forth on the applicable Sale Order. Unless otherwise stated on the Sale Order, all invoices shall be amounts.
      Client shall make payments to the entity and address set forth in the invoice submitted by AnyMind in relation to
      the applicable Sale Order. AnyMind may from time to time vary any fee and/or introduce new fees in relation to the
      AnyTag Service. The fees to be paid shall be exclusive of all applicable local government taxes or levies that
      AnyMind incurs.
    </p>
    <p>
      <strong>Late Payments:</strong> If any amounts due hereunder are not received by AnyMind by the due date, interest
      will accrue over the original service fee AnyMind the rate of 15% per annum, from the date such payment was due
      until the date paid. In addition, upon 10 days written notice, AnyMind may suspend Client’s access to Platform if
      AnyMind does not receive the amounts invoiced hereunder the expiration of such period.
    </p>
    <p>
      Additional terms and conditions may also apply to specific portions, services or features of AnyTag Service. All
      such additional terms and conditions are hereby incorporated by this reference into the Agreement. All settlements
      and fund transfers are to be carried out by a relevant financial institution or bank which AnyMind will specify
      from time to time.
    </p>
    <h3>7. ANYMIND RESPONSIBILITIES</h3>
    <p>
      7.1 AnyMind reserves the right to provide access, withdraw or amend the access to AnyTag Service or Platform, and
      any service or material that AnyMind provides for AnyTag Service or on Platform, at its sole discretion and
      without notice. AnyMind will not be liable if for any reason all or any part of AnyTag Service is unavailable at
      any time or for any period. From time to time, AnyMind may restrict access to some parts of AnyTag Service or
      Platform, or the entire AnyTag Service or Platform.
    </p>
    <p>
      7.2 AnyMind may provide Live Campaign from Client on the Platform which shall include the following but not
      limited to, the scope of work required by the Client, the detailed statement of work to be performed by the
      Influencer, the fees associated with such statement of work, and/or any additional conditions agreed upon between
      the parties.
    </p>
    <p>7.3 AnyMind is solely responsible for the remuneration of Influencers on the Platform.</p>
    <p>7.4 AnyMind will provide the report of the campaign in AnyMind’s reporting format.</p>
    <p>7.5 At its sole discretion, AnyMind has the right to:</p>
    <ul css={styles.bulletList}>
      <li>Remove or refuse to post any Influencer content or Client’s Live Campaign for any or no reason;</li>
      <li>
        AnyMind has the right to disable any user name, password or other identifier, whether chosen by Influencer,
        Client or provided by AnyMind, at any time at its sole discretion for any or no reason, including if, in
        AnyMind’s opinion, Influencer or Client has violated any clauses of the Terms and Conditions;
      </li>
      <li>
        Block, ban, deactivate, refrain or take any action to temporarily/permanently suspend Client’s account on
        Platform without further notice if AnyMind believes that Influencer and Client are directly engaging and/or are
        in contact in connection with the AnyTag Service and purpose hereof without using Platform, or whereby
        Influencer’s content, Client’s Live Campaign or the use of Platform violates all or part thereof of the Terms
        and Conditions. AnyMind reserves the right to claim for a compensation from Influencer and Client for any
        damages resulting from such direct or indirect of the action that Influencer and Client direct engage;
      </li>
      <li>
        Take any action with respect to any Influencer’s content, Client’s Live Campaign that AnyMind deems necessary or
        appropriate, including if AnyMind believes that such Influencer’s content, Client’s Live Campaign violates the
        Terms and Conditions or violates any applicable laws, including the content standards, infringes any
        intellectual property right or other right of any person or entity, threatens the personal safety of users of
        Platform or the public or could create liability for AnyMind; and
      </li>
      <li>
        Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or
        unauthorized use of AnyTag Service or Platform.
      </li>
    </ul>
    <p>
      7.6 Without limiting the foregoing, AnyMind has the right to fully cooperate with any law enforcement authorities
      or court order requesting or directing the disclosure of the identity or other information of anyone posting any
      materials on or through Platform. Client waives and indemnifies AnyMind and its affiliates, licensees and service
      providers from any claims resulting from any action taken by AnyMind any of the foregoing parties during or as a
      result of its investigations and from any actions taken as a consequence of investigations by either AnyMind or
      law enforcement authorities. However, AnyMind does not undertake to review material before it is posted on
      Platform, any social media channels or the internet, and therefore cannot ensure prompt removal of objectionable
      material after it has been posted. Accordingly, AnyMind assumes no liability for any action or inaction regarding
      transmissions, communications or contents provided by any user, Influencer, Client or third party. AnyMind has no
      liability or responsibility to anyone for performance or nonperformance of the activities described in this
      section either by AnyMind, Influencer or Client.
    </p>
    <p>
      7.7 AnyMind only agrees to provide AnyTag Service to Client who accept these Terms and Conditions and abide by all
      the terms and conditions provided herein.
    </p>
    <h3>8. CLIENT RESPONSIBILITIES</h3>
    <p>Client responsibilities are as follows:</p>
    <p>
      8.1 Client must provide certain registration details or other information as required by AnyMind to use certain
      features of AnyTag Marketplace. The Client guarantees that the information of the Client provided to AnyMind for
      the use of AnyTag Service and Platform is correct, current and complete and up to date;
    </p>
    <p>
      8.2 Client must provide detailed requirements to AnyMind for AnyTag Service in order for AnyMind to prepare the
      proposal;
    </p>
    <p>8.3 Client shall make payments as per the Sale Order and Invoices placed by AnyMind;</p>
    <p>
      8.4 Client shall adhere to the conditions of using the AnyTag Marketplace and the content standards set out by
      AnyMind in Appendix A;
    </p>
    <p>
      8.5 Client shall ensure that all persons who have access to Client’s account on Platform and access either through
      Client’s internet or any network connection are aware of the Terms and Conditions and comply with them. Client
      shall be wholly responsible for access to its own account. AnyMind shall not be held liable for any damages caused
      by or on the Client’s account;
    </p>
    <p>
      8.6 Client shall ensure that the material to be posted on Platform, any social media channels or internet is
      reviewed and complies with all local laws and regulations;
    </p>
    <p>8.7 Client shall pay for additional service fee if Client requests for additional Services or reports;</p>
    <p>
      8.8 Client acknowledges that Influencers are independent. AnyMind cannot and will not be held liable for
      non-performance of Influencers within the scope of time given. In any case, AnyMind will not refund any payment
      for AnyTag Service Client has made. The only remedy shall be for Client to choose a different time slot and/or
      Influencers; and
    </p>
    <p>
      8.9 Client agrees to provide personal information of the person in charge to AnyMind for registration on Platform.
      This personal information is used in accordance with Privacy Policy( available at [ ] ), used to match Influencers
      with Client as well as for the management of such Live Campaign. In cases where AnyMind shares personal
      information of any Influencers to Client for the purpose of AnyTag Service, Client agrees not to use such personal
      information for other purposes and not to share or store it without the Influencer's prior written consent.
    </p>
    <h3>9. CONFIDENTIAL INFORMATION</h3>
    <p>
      9.1 Client acknowledges that the Client’s account is personal to Client and agrees not to provide any other person
      with access to Platform or portions of it using Client’s user name, password or other security information.
    </p>
    <p>
      9.2 Client also acknowledges that Client must treat all information gathered from AnyMind, Platform or AnyTag
      Service as confidential and Client must not disclose it to any other person or entity or third-party without the
      prior written consent from AnyMind.
    </p>
    <p>
      9.3 Client agrees to notify AnyMind immediately of any unauthorized access to or use of its user name or password
      or any other breach of security. Client also agrees to ensure that Client shall exit or sign out from Client’s
      account on Platform at the end of each session. Client should use particular caution when accessing Client’s
      account from a public or shared computer so that others are not able to view or record the password or other
      personal information.
    </p>
    <p>
      9.4 AnyMind shall not be liable for any loss or damage arising from Client’s failure to comply with the above
      requirements.
    </p>
    <p>
      9.5 AnyMind shall not be liable for any data privacy law violation arising from Client’s failure to comply with
      the above requirements. Client agrees that Client shall be solely responsible for such failure and shall indemnify
      and hold harmless AnyMind from all claims against Client’s failure to comply with the above requirement.
    </p>
    <p>
      9.6 Client agrees and acknowledges that Client shall use confidential information of Influencer and AnyMind for
      specific purpose of Live Campaign only and shall not use any confidential information of Influencer or AnyMind for
      other purposes without the prior written of Influencer or AnyMind.
    </p>
    <h3>10. INDEMNIFICATION</h3>
    <p>
      Client agrees to defend, indemnify and hold harmless AnyMind, its Affiliates, licensors and service providers, and
      their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns
      from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including
      reasonable attorneys' fees) arising out of or relating to the Client’s violation of the Terms and Conditions or
      Client’s use of Platform, including, but not limited to Client’s Live Campaign’s scope, any use of AnyTag
      Service’s content, services and products other than as expressly authorized in the Terms and Conditions or
      Client’s use of any information obtained from AnyMind.
    </p>
    <h3>11. LIMITATION OF LIABILITY</h3>
    <p>
      AnyMind will not be liable for any damage incurred by Client or third parties from the use of Platform or AnyTag
      Service, unless such damage is attributable to the intentional or grossly negligent acts of AnyMind; provided,
      however, that in the case that AnyMind commits intentional or grossly negligent acts, AnyMind responsibility for
      the damages shall be limited to the fee paid by Client Client on a case by case basis.
    </p>
    <h3>12. TERMINATION</h3>
    <p>
      AnyMind has the right to terminate or suspend the Client’s access to all or part of Platform for any or no reason,
      including without limitation, any violation of the Terms and Conditions. Upon termination of Terms and Conditions,
      Client shall immediately pay AnyMind all amounts owed by Client to AnyMind within seven (7) days (if any). AnyMind
      will not have any liability whatsoever to Client for any termination of Terms and Conditions, including for
      termination of Client’s account or deletion of Client content.
    </p>
    <p>
      AnyMind reserves the right to modify or discontinue any or all of the AnyTag Service including returning the
      remaining value of the Credit with or without notice to Client. AnyMind will not be liable to Client or any
      third-party for termination of any Service on Platform.
    </p>
    <h3>13. WAIVER AND SEVERABILITY</h3>
    <p>
      13.1 No omission or delay on AnyMind's part in exercising any or part of its rights under these Terms and
      Conditions shall operate as a waiver thereof.
    </p>
    <p>
      13.2 If any provision of these Terms and Conditions is held by a court or other tribunal of competent jurisdiction
      to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the
      minimum extent such that the remaining provisions of the Terms and Conditions will continue in full force and
      effect.
    </p>
    <h3>14. FORMAL COMMUNICATION</h3>
    <p>
      All formal communications between AnyMind and Client shall be executed through the following electronic mail
      and/or app notification.
    </p>
    <p>
      Client shall provide Client electronic mail address or app inbox on Client’s account on the Platform. This address
      is the only address where correspondence shall be sent from and received with regards to the contractual
      relationship between Client and AnyMind.
    </p>
    <p>
      Electronic mail sent and received in the above address or app inbox shall be deemed to have been formally and
      properly received and posted. Client cannot claim to not have received or not acknowledged a certain email or
      notification that has been sent or received in the address or inbox to be provided on the Client’s account.
    </p>

    <h3>15. EXCLUSION OF ANTI-SOCIAL FORCES</h3>
    <p>
      Client and AnyMind represent to each other that neither he/she/it/they nor his/her/its/their representatives,
      officers or other persons substantially involved in his/her/its/their management currently fall under an organized
      crime group, an organized crime group member, a quasi-member of an organized crime group, an enterprise associated
      with an organized crime group, a corporate racketeer (sokaiya), a social campaign advocate racketeer
      (shakai-undo-hyobo-goro), a political campaign racketeer (seiji-katsudo-hyobo-goro), an organized crime group with
      special intelligence, or a person having a close relationship with such entities or persons equivalent thereto,
      and affirm that he/she/it/they will not fall under any of the foregoing in the future.
    </p>
    <p>
      If Client or AnyMind breaches the above representations, the other party may immediately cancel use of the
      Platform and Terms and Conditions relating to AnyTag Service without warning.
    </p>

    <h3>16. FORCE MAJEURE</h3>
    <p>
      Except for payment obligations, neither Client nor AnyMind shall be liable for any failure to perform its
      obligations hereunder where such failure results from any cause beyond either Client’s or AnyMind’s reasonable
      control, including the elements; fire; flood; severe weather; earthquake; vandalism; accidents; sabotage; power
      failure; denial of service attacks or similar attacks; Internet failure; acts of god and the public enemy; acts of
      war; acts of terrorism; riots; civil or public disturbances; strikes, lock-outs, or labour disruptions; and any
      laws, orders, rules, regulations, acts, or restraints of any government or governmental body or authority, civil
      or military, including the orders and judgments of courts.
    </p>

    <h3>17. GOVERNING LAW AND JURISDICTION</h3>
    <p>
      17.1 Any dispute arising out of or in connection with this contract, including any question regarding its
      existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the
      Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore
      International Arbitration Centre (“SIAC Rules”) for the time being in force, which rules are deemed to be
      incorporated by reference in this clause.
      <br />
      The seat of the arbitration shall be Singapore.
      <br />
      The Tribunal shall consist of 3 arbitrator(s).
      <br />
      The language of the arbitration shall be English.
    </p>
    <p>
      17.2 Parties should also include an applicable law clause. The following is recommended: This contract is governed
      by the laws of Singapore.
    </p>

    <h3>18. SERVICES MAY USE YOUTUBE’S APPLICATION INTERFACE PROGRAM (API)</h3>
    <p>
      By using our Services, Influencer and/or Client is agreeing to be bound by (1) the{' '}
      <Anchor
        href="https://www.youtube.com/t/terms"
        target="_blank"
        css={{ display: 'inline-block', textDecoration: 'underline' }}
      >
        YouTube Terms of Service
      </Anchor>{' '}
      and (2) the{' '}
      <Anchor
        href="http://www.google.com/policies/privacy"
        target="_blank"
        css={{ display: 'inline-block', textDecoration: 'underline' }}
      >
        privacy policy
      </Anchor>
      . AnyMind collects influencer’s information such as subscribers and viewers and engagements by using YouTube API.
    </p>
    <p>
      In addition to AnyMind's normal procedure for deleting stored data, Influencers can revoke that AnyMind’s access
      to their data at any time via the Google security settings page at
      <Anchor
        href="https://security.google.com/settings/security/permissions"
        target="_blank"
        css={{ display: 'inline-block', textDecoration: 'underline' }}
      >
        https://security.google.com/settings/security/permissions
      </Anchor>
    </p>

    <h3>19. RESTRICTED MATTERS</h3>
    <p>
      AnyMind prohibits Influencer and Client from engaging in any of the following acts when using Platform or AnyTag
      Service:
    </p>
    <ul css={styles.bulletList}>
      <li>
        Acts that violate the laws and regulations, court verdicts, resolutions or orders, or administrative measures
        that are legally binding;
      </li>
      <li>Acts that may be in violation of public order, morals or customs;</li>
      <li>
        Acts that infringe intellectual property rights, such as copyrights, trademarks and patent rights, rights to
        fame, privacy, and all other rights granted by law or by a contract with AnyMind and/or a third party;
      </li>
      <li>
        Acts of posting or transmitting excessively violent or explicit sexual expressions; expressions that amount to
        child pornography or child abuse; expressions that lead to discrimination by race, national origin, creed,
        gender, social status, family origin, etc.; expressions that induce or encourage suicide, self-injurious
        behavior or drug abuse; or expressions that include anti-social content and lead to the discomfort of others;
      </li>
      <li>
        Acts that lead to the misrepresentation of AnyMind and/or a third party or that intentionally spread false
        information;
      </li>
      <li>
        Acts of using Platform for sales, marketing, advertising, solicitation or other commercial purposes (except for
        those approved by AnyMind); using Platform for the purpose of sexual conduct or obscene acts; using Platform for
        the purpose of meeting or engaging in sexual encounters with an unknown third party; using Platform for the
        purpose of harassment or libelous attacks against others; or otherwise using Platform for purposes other than as
        intended by Platform;
      </li>
      <li>Acts that benefit or involve collaboration with anti-social groups;</li>
      <li>Acts that are related to religious activities or invitations to certain religious groups;</li>
      <li>
        Acts of unauthorized or improper collection, disclosure, or provision of any other person's personal
        information, registered information, user history, or the like;
      </li>
      <li>
        Acts of interfering with the servers and/or network systems of Platform; fraudulently manipulating Platform by
        means of bots, cheat tools, or other technical measures; deliberately using defects of Platform; making
        unreasonable inquires and/or undue claims such as repeatedly asking the same questions beyond what is necessary,
        and other acts of interfering with or hindering AnyMind's operation of Platform or others use of Platform;
      </li>
      <li>
        Acts of decoding the source code of Platform, such as by way of reverse engineering, disassembling or the like,
        for unreasonable purposes or in an unfair manner;
      </li>
      <li>Acts that aid or encourage any acts stated above; and</li>
      <li>Acts other than those set forth above that AnyMind reasonably deems to be inappropriate.</li>
    </ul>

    <h3>20. LEGAL DISCLAIMER</h3>
    <p>
      AnyTag Service includes content provided by third parties, including materials provided by Influencer or Client,
      other users, bloggers and third party licensors, syndicators, aggregators and/or reporting services. All
      statements and/or opinions expressed in these materials, and all articles and responses to questions and other
      content, other than the content provided by AnyMind, are solely the opinions and the responsibility of the person
      or entity providing those materials. These materials do not necessarily reflect the opinion of AnyMind. AnyMind is
      not responsible, or liable to Influencer, Client or any third party, for the content or accuracy of any materials
      provided by any third parties.
    </p>
    <p>
      AnyMind shall provide no warranty, either expressly or impliedly, with respect to AnyTag Service or Platform, that
      there are no defects (including, without limitation, faults with respect to security, etc., errors or bugs, or
      violations of rights) or as to the safety, reliability, accuracy, completeness, effectiveness, and fitness for a
      particular purpose. AnyMind will in no way be responsible for providing Influencer or Client with AnyTag Service
      after deleting such defects.
    </p>
    <p>
      No warranties, promises, representations of any kind, whether expressed or implied, are given as to the accuracy
      or standard of the information provided. AnyMind cannot and will not guarantee that this platform is bereft of
      viruses, malware, or anything else that might have destructive qualities. Any relianceInfluencer places on
      information from the platform is strictly at the Influencer’s own risk. AnyMind disclaims all liability and
      responsibility arising from any reliance placed on such materials by the Influencer or any other visitor(s) to
      AnyTag Service, or by anyone who may be informed of any of its contents.
    </p>
    <p>
      In no event will AnyMind, its affiliates or their licensors, service providers, employees, agents, officers or
      directors be liable for damages of any kind, arising out of or in connection with the use of, or inability to use,
      AnyTag Service, Platform, any websites or social network service linked to it, any content on AnyTag Service,
      Platform, or such other websites, or any services or items obtained through AnyTag Service, including any
      indirect, special, incidental, consequential or punitive damages, including but not limited to, personal injury,
      pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings,
      loss of use, loss of goodwill, loss of data, and whether AnyMind used by tort (including negligence), breach of
      contract or otherwise, even if foreseeable.
    </p>
    <p>
      Any misuse of action or claim the Influencer may have arising out of or relating to the Terms and Conditions or
      AnyMind must be commenced within one (1) year after the misuse of action accrues, otherwise, such misuse of action
      or claim is permanently barred.
    </p>
    <p>
      The foregoing does not affect any liability which cannot be excluded or limited under applicable law. If any
      provision of these Terms and Conditions are held to be illegal, inapplicable or unenforceable in any jurisdiction,
      only that clause shall be voided. The remainder of the contract shall still be appliAnyMindble and enforceable in
      the court of law.
    </p>
    <p>
      Certain countries’ laws do not allow limitations on implied warranties or the exclusion or limitation of certain
      kinds of damages. If these laws apply to you, some or all of the above disclaimer, limitations, or exclusions may
      not apply to Client, and Client might have additional rights.
    </p>

    <p> Revised on 1 January 2025</p>

    <br />
    <br />
    <br />
    <br />

    <h2 css={{ textAlign: 'center' }}>
      APPENDIX A<br />
      CONTENT STANDARDS AND PROHIBITED USES
    </h2>
    <p>
      Any Client’s content that Client posts will be considered non-confidential and non-proprietary. By providing any
      Client’s content on AnyTag Service, Client grants AnyMind and its Affiliates the right to use, reproduce, modify,
      perform, display, distribute and otherwise disclose to third parties any such material.
    </p>
    <p>Client represents and warrants that:</p>
    <p>
      1. Client owns or controls all rights in and to Client’s contents and has the right to grant permission for usage
      by AnyMind, Affiliates and respective licensees, successors and assignees.
    </p>
    <p>2. All Client’s contents do and will comply with the Terms and Conditions and applicable laws.</p>
    <p>3. Client’s contents are, at all times, correct, current and complete, and up to date.</p>
    <p>4. Client shall have one account per person.Client can not hold multiple accounts.</p>
    <p>
      5. Client understands and acknowledges that Client has full responsibility of Client’s contents, including its
      legality, reliability, accuracy and appropriateness.
    </p>
    <p>
      6. AnyMind is not responsible, or liable to any third party, for the content or accuracy of any Client’s content
      posted by Client or any other user of AnyTag Service.
    </p>

    <p>
      These content standards apply to any and all Client’s contents. The content must in its entirety comply with all
      laws and regulations. Without limiting the foregoing, Client content must not:
    </p>
    <ul css={styles.bulletList}>
      <li>
        Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful,
        inflammatory or otherwise objectionable;
      </li>
      <li>
        Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion,
        nationality, political leanings, disability, sexual orientation or age;
      </li>
      <li>
        Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any
        other person;
      </li>
      <li>
        Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that
        could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be
        in conflict with the Terms and Conditions; and
      </li>
      <li>Promote any illegal activity, or advocate, promote or assist any unlawful act.</li>
    </ul>

    <p>Client also acknowledges and accepts that:</p>
    <ul css={styles.bulletList}>
      <li>
        AnyMind may take measures that AnyMind considers necessary and appropriate, if AnyMind notices that the Client
        is using AnyTag Service in a way which violates the Terms and Conditions;
      </li>
      <li>
        In the case where AnyMind has suffered loss/damage or has been charged an expense directly or indirectly due to
        the Client violating applicable laws or the Terms and Conditions while using AnyTag Service, Client shall
        immediately compensate AnyMind upon its and/or others’ request without limitation.
      </li>
    </ul>

    <p>Revised on 1 January 2025</p>

    <br />
    <br />
    <br />
    <br />

    <h2 css={{ textAlign: 'center' }}>
      APPENDIX B<br />
      PROHIBITED USES OF PLATFORM
    </h2>
    <p>
      Client may use Platform only for lawful purposes and in accordance with the Terms and Conditions. Client agrees
      not to use AnyTag Service and Platform:
    </p>
    <ul css={styles.bulletList}>
      <li>In any way that violates any law or regulation.</li>
      <li>To directly solicit AnyMind’s Clients or Influencers from AnyTag Service;</li>
      <li>
        To send, knowingly receive, upload, download, use or re-use any material which does not comply with the content
        standards set out in the Terms and Conditions;
      </li>
      <li>
        To distribute, transmit, or procure the sending of, any advertising or promotional material, including any "junk
        mail", "chain letter" or "spam" or any other similar solicitation;
      </li>
      <li>
        To impersonate or attempt to impersonate AnyMind, a Company employee, another user or any other person or entity
        (including, without limitation, by using e-mail addresses or screen names, social media accounts, associated
        with any of the foregoing);
      </li>
      <li>
        To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of AnyMind, or which, as
        determined by us, may harm AnyMind or users of Platform or expose them to liability.
      </li>
    </ul>

    <p>Additionally, Client agrees not to:</p>
    <ul css={styles.bulletList}>
      <li>
        Use Platform in any manner that could disable, overburden, damage, or impair the site or interfere with any
        other party's use of Platform, including their ability to engage in real time activities through Platform;
      </li>
      <li>
        Use any robots or devices, processes or means to access AnyTag Service for any purpose, including monitoring or
        copying any of the material on AnyTag Service;
      </li>
      <li>
        Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically
        harmful;
      </li>
      <li>
        Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of AnyMind, the server on
        which Platform is stored, or any server, computer or database connected to Platform;
      </li>
      <li>Otherwise attempt to interfere with the proper working of Platform;</li>
      <li>
        Comply with all laws and regulations of the countryInfluencer is domiciled in, as well as the laws and
        regulations of where the Campaign is to go live. This responsibility rests with Client but the Client may ask
        AnyMind for assistance when absolutely necessary. This, by no means, gives rise to AnyMind’s liability. On the
        contrary, AnyMind may choose to assist or not assist the Client. The responsibility rests solely with the
        Client.
      </li>
    </ul>

    <p>Revised on 1 January 2025</p>

    <br />
    <br />
    <br />
    <br />

    <h2 css={{ textAlign: 'center' }}>
      APPENDIX C<br />
      INTELLECTUAL PROPERTY RIGHTS
    </h2>
    <p>
      AnyMind and its entire contents, features and functionality (including but not limited to all information,
      software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned
      by AnyMind, its licensors or other providers of such material and are protected by copyright, trademark, patent,
      trade secret and other intellectual property or proprietary rights laws.
    </p>
    <p>
      The Terms and Conditions permits Influencer or Client to use AnyTag Service and Platform only for the purpose
      prescribed by AnyMind. Influencer must not reproduce, distribute, modify, copy, create derivative works of,
      publicly display, publicly perform, republish, download, store or transmit any of the material on Platform
      including, but not limited to:
    </p>
    <p>* Modifying copies of any materials from this site.</p>
    <p>
      * Using any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying
      text.
    </p>
    <p>
      * Deleting or altering any copyright, trademark or other proprietary rights notices from copies of materials from
      this site.
    </p>
    <p>
      If Influencer or Client prints, copies, modifies, downloads or otherwise uses or provides any other person with
      access to any part of Platform in breach of the Terms and Conditions, Influencer’s or Client’s right to use
      Platform will cease immediately and Influencer or Client must, at AnyMind’s option, return or destroy any copies
      of the materials that Influencer or Client has made.
    </p>
    <p>
      No right, title or interest in or to AnyMind or any content on Platform is transferred to Influencer or Client,
      and all rights not expressly granted are reserved by AnyMind.
    </p>
    <p>
      Any use of Platform not expressly permitted by Terms and Conditions is a breach of the Terms and Conditions and
      may violate copyright, trademark and other laws.
    </p>
  </div>
);

const styles = {
  container: css({
    padding: '48px',
    lineHeight: '1.6',
    '& h2': {
      fontSize: '20px',
      textAlign: 'center',
      fontWeight: 'normal',
      marginBottom: '40px'
    },
    '& h3': {
      marginTop: '40px',
      marginBottom: '16px',
      fontSize: '18px',
      fontWeight: 400
    },
    '& p': {
      marginBottom: '16px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '140%',
      color: THEME.text.colors.black.lv1
    },
    '& strong': {
      fontWeight: 600
    }
  }),
  bulletList: css({
    '&&': {
      paddingLeft: '32px',
      margin: '16px 0',
      '& > li': {
        display: 'list-item !important',
        listStyle: 'disc outside !important',
        marginBottom: '16px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '140%',
        color: THEME.text.colors.black.lv1
      }
    }
  })
};
