import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { Icon, IconProps } from '../Icon';

export interface NoticeProps {
  open?: boolean;
  title?: ReactNode;
  className?: string;
  children?: ReactNode;
  prefixIcon?: IconProps;
  hasCloseIcon?: boolean;
  description?: ReactNode;
  type?: 'info' | 'success' | 'warning' | 'error' | 'none';
}

export const Notice = ({
  title,
  children,
  className,
  prefixIcon,
  open = true,
  description,
  hasCloseIcon,
  type = 'none'
}: NoticeProps) => {
  const { status, toggleStatus } = useToggleState(open);

  const defaultPrefixIcon: IconProps | undefined = (() => {
    switch (type) {
      case 'info':
        return { icon: 'info', color: THEME.attention.colors.blue };
      case 'error':
        return { icon: 'warning', color: THEME.attention.colors.red };
      case 'success':
        return { icon: 'success', color: THEME.attention.colors.green };
      case 'warning':
        return { icon: 'warning', color: THEME.attention.colors.yellow };
      default:
        return undefined;
    }
  })();

  return status ? (
    <div css={styles.wrapper(defaultPrefixIcon?.color)} className={className}>
      {prefixIcon || defaultPrefixIcon ? (
        <Icon
          icon=""
          size={20}
          css={{ alignSelf: 'center', marginRight: '12px' }}
          {...defaultPrefixIcon}
          {...prefixIcon}
        />
      ) : null}

      {title || description ? (
        <div css={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {title ? <div css={{ fontWeight: 600 }}>{title}</div> : null}
          {description ? (
            <div css={{ color: THEME.text.colors.gray.lv3, fontWeight: '500', marginTop: title ? '4px' : 0 }}>
              {description}
            </div>
          ) : null}
        </div>
      ) : null}

      {children}

      {hasCloseIcon ? <Icon css={styles.icon} size={12} icon="close" color="#C5D0DA" onClick={toggleStatus} /> : null}
    </div>
  ) : null;
};
const styles = {
  wrapper: (borderLeftColor?: string) =>
    css({
      display: 'flex',
      position: 'relative',
      padding: '14px 16px',
      boxSizing: 'border-box',
      wordBreak: 'break-word',
      borderLeft: '3px solid #3892e5',
      borderBottom: THEME.border.base,
      backgroundColor: THEME.background.colors.white,
      borderLeftColor: borderLeftColor || 'transparent'
    }),
  icon: css({
    right: '12px',
    cursor: 'pointer',
    position: 'absolute',
    '&:hover': { opacity: 0.8 }
  })
};
