import { useTranslation } from 'react-i18next';
import { InstagramFeedPost, InstagramFeedPostSortField } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { ColumnCustomiser } from '@/shared/molecules';
import { SelectColumnsAction } from '@/shared/types';
import { formatIntNumber, formatPercent } from '@/shared/utils';
import {
  defaultSelectedAnalyticsInstagramPostsColumns,
  selectableAnalyticsInstagramPostsColumnsOptions
} from '../utils';

type CustomColumnProps = ColumnProps<InstagramFeedPostSortField, keyof InstagramFeedPost>;

interface Props extends SelectColumnsAction<keyof InstagramFeedPost> {
  data: readonly InstagramFeedPost[];
  isComparePage?: boolean;
}

export const useAnalyticsInstagramPostsListTable = ({
  data,
  isComparePage,
  selectedColumns,
  setSelectedColumns
}: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ key, hidden, styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles },
    hidden: hidden || (!!key && !selectedColumns.includes(key))
  });

  const columns: CustomColumnProps[] = [
    {
      title: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          {t('HeaderColumn.Post')}
          {!isComparePage ? (
            <ColumnCustomiser<keyof InstagramFeedPost>
              css={{ marginLeft: 'auto' }}
              savedValuesKey="igFeedPost"
              setSelectedColumns={setSelectedColumns}
              options={selectableAnalyticsInstagramPostsColumnsOptions}
              initialValues={defaultSelectedAnalyticsInstagramPostsColumns}
              defaultOptions={defaultSelectedAnalyticsInstagramPostsColumns}
            />
          ) : null}
        </div>
      ),
      sticky: { active: true, hasBorderRight: true }
    },
    getMetricColumnProps({
      key: 'follow',
      help: t<string>('Tooltip.FollowHelp'),
      title: t('HeaderColumn.Follow'),
      sortField: InstagramFeedPostSortField.FOLLOW
    }),
    getMetricColumnProps({
      help: t<string>('Tooltip.ImpsHelp'),
      key: 'impressions',
      title: t('HeaderColumn.Impression'),
      sortField: InstagramFeedPostSortField.IMPRESSIONS
    }),
    getMetricColumnProps({
      key: 'impressionFromDiscovery',
      title: t('HeaderColumn.Impression from Discovery'),
      help: t<string>('Tooltip.impressionsFromDiscoveryHelp'),
      sortField: InstagramFeedPostSortField.IMPRESSION_FROM_DISCOVERY
    }),
    getMetricColumnProps({
      key: 'impressionFromHashtag',
      title: t('HeaderColumn.Impression from Hashtag'),
      help: t<string>('Tooltip.impressionFromHashtagHelp'),
      sortField: InstagramFeedPostSortField.IMPRESSION_FROM_HASHTAG
    }),
    getMetricColumnProps({
      key: 'reach',
      title: t('HeaderColumn.Reach'),
      help: t<string>('Tooltip.ReachPostHelp'),
      sortField: InstagramFeedPostSortField.REACH
    }),
    getMetricColumnProps({
      key: 'nonFollowerReach',
      title: t('HeaderColumn.Non Follower Reach'),
      help: t<string>('Tooltip.nonFollowerReachHelp'),
      sortField: InstagramFeedPostSortField.NON_FOLLOWER_REACH
    }),
    getMetricColumnProps({
      key: 'engagement',
      title: t('HeaderColumn.Engagement'),
      help: t<string>('Tooltip.EngagementHelp'),
      sortField: InstagramFeedPostSortField.ENGAGEMENT
    }),
    getMetricColumnProps({
      key: 'engagementRate',
      title: t('HeaderColumn.Engagement Rate'),
      help: t<string>('Tooltip.EngagementRateHelp'),
      sortField: InstagramFeedPostSortField.ENGAGEMENT_RATE
    }),
    getMetricColumnProps({
      key: 'likes',
      title: t('HeaderColumn.Like'),
      help: t<string>('Tooltip.LikeHelp'),
      sortField: InstagramFeedPostSortField.LIKE
    }),
    getMetricColumnProps({
      key: 'comments',
      title: t('HeaderColumn.Comment'),
      help: t<string>('Tooltip.CommentHelp'),
      sortField: InstagramFeedPostSortField.COMMENT
    }),
    getMetricColumnProps({
      key: 'share',
      title: t('HeaderColumn.share'),
      help: t<string>('Tooltip.shareHelp'),
      sortField: InstagramFeedPostSortField.SHARE
    }),
    getMetricColumnProps({
      key: 'saved',
      title: t('HeaderColumn.Save'),
      help: t<string>('Tooltip.SaveHelp'),
      sortField: InstagramFeedPostSortField.SAVED
    }),
    getMetricColumnProps({
      key: 'interaction',
      title: t('HeaderColumn.Interaction'),
      help: t<string>('Tooltip.interactionHelp'),
      sortField: InstagramFeedPostSortField.INTERACTION
    }),
    getMetricColumnProps({
      key: 'productPageView',
      title: t('HeaderColumn.Product Page View'),
      help: t<string>('Tooltip.productPageViewHelp'),
      sortField: InstagramFeedPostSortField.PRODUCT_PAGE_VIEW
    }),
    getMetricColumnProps({
      key: 'productButtonClick',
      title: t('HeaderColumn.Product Button Click'),
      help: t<string>('Tooltip.productButtonClickHelp'),
      sortField: InstagramFeedPostSortField.PRODUCT_BUTTON_CLICK
    }),
    getMetricColumnProps({
      key: 'postDate',
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      help: t<string>('Tooltip.PostDateHelp'),
      sortField: InstagramFeedPostSortField.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      reach,
      likes,
      saved,
      share,
      follow,
      content,
      postDate,
      comments,
      thumbNail,
      engagement,
      interaction,
      impressions,
      engagementRate,
      productPageView,
      nonFollowerReach,
      productButtonClick,
      impressionFromHashtag,
      impressionFromDiscovery
    }) => [
      <TableCell.Post
        title={content || ''}
        thumbnail={thumbNail || ''}
        css={{ maxWidth: 'unset' }}
        onClick={() => setPostDetailsParam(id)}
      />,
      <TableCell.Number value={formatIntNumber(follow || 0)} />,
      <TableCell.Number value={formatIntNumber(impressions)} />,
      <TableCell.Number value={formatIntNumber(impressionFromDiscovery || 0)} />,
      <TableCell.Number value={formatIntNumber(impressionFromHashtag || 0)} />,
      <TableCell.Number value={formatIntNumber(reach)} />,
      <TableCell.Number value={formatIntNumber(nonFollowerReach || 0)} />,
      <TableCell.Number value={formatIntNumber(engagement)} />,
      <TableCell.Number value={formatPercent(engagementRate, false, 2)} unit="%" />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(share || 0)} />,
      <TableCell.Number value={formatIntNumber(saved)} />,
      <TableCell.Number value={formatIntNumber(interaction || 0)} />,
      <TableCell.Number value={formatIntNumber(productPageView || 0)} />,
      <TableCell.Number value={formatIntNumber(productButtonClick || 0)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
