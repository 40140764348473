import { getValuableVariables } from '@/shared/utils';
import { useYoutubeAnalyticsAudienceQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { AnalyticsYoutubeDashboardAudienceTemplate } from './AnalyticsYoutubeDashboardAudienceTemplate';

export interface AnalyticsYoutubeDashboardAudienceProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsYoutubeDashboardAudience = ({ analyticsAccount }: AnalyticsYoutubeDashboardAudienceProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useYoutubeAnalyticsAudienceQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      youtubeAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsYoutubeDashboardAudienceTemplate
      loading={loading}
      data={data?.youtubeAnalyticsAudience}
      accountAvatar={analyticsAccount.avatar}
    />
  );
};
