import { DisabledFields } from '@/shared/types';
import { formatHashTags } from '@/shared/utils';
import { TikTokSpecialCampaignStatus, TikTokSpecialCampaignDetail, CreateTikTokSpecialCampaignInput } from '@/graphql';
import { TikTokCampaignFormKeys, TikTokCampaignFormValues, TikTokCampaignDisabledFieldsType } from './types';

// https://adasiaholdings.atlassian.net/wiki/spaces/NC/pages/3567321097/TikTok+special+Marketplace+campaign#[hardBreak]Things-required-for-the-UI-display
const disabledFieldsOnGoing: TikTokCampaignFormKeys[] = ['hashtags', 'startDate', 'materialUrl', 'preLaunchDate'];
const disabledFieldsFinished: TikTokCampaignFormKeys[] = [
  ...disabledFieldsOnGoing,
  'title',
  'endDate',
  'materials',
  'sampleUrl',
  'productUrl',
  'thumbnails',
  'materialUrl',
  'requirement',
  'campaignCategoryId',
  'serviceInformation'
];

const getDisabledFields = (disabledFields: TikTokCampaignFormKeys[] = []) =>
  disabledFields.reduce<TikTokCampaignDisabledFieldsType>((acc, curr) => ({ ...acc, [curr]: true }), {});

export const getTikTokCampaignDisabledFields = (
  status?: TikTokSpecialCampaignStatus
): DisabledFields<TikTokCampaignFormKeys> => {
  switch (status) {
    case TikTokSpecialCampaignStatus.DRAFT:
    case TikTokSpecialCampaignStatus.REJECTED:
    case TikTokSpecialCampaignStatus.UPCOMING:
    case TikTokSpecialCampaignStatus.REVIEWING:
      return {};
    case TikTokSpecialCampaignStatus.ONGOING:
    case TikTokSpecialCampaignStatus.SUSPENDED:
      return getDisabledFields(disabledFieldsOnGoing);
    case TikTokSpecialCampaignStatus.FINISHED:
    default:
      return getDisabledFields(disabledFieldsFinished);
  }
};

export const getTikTokCampaignFormValues = (data?: TikTokSpecialCampaignDetail): TikTokCampaignFormValues => ({
  id: data?.id,
  isDraft: false,
  saveOnLeave: false,
  title: data?.title || '',
  endDate: data?.endDate || '',
  startDate: data?.startDate || '',
  materials: data?.materials || [],
  sampleUrl: data?.sampleUrl || '',
  productUrl: data?.productUrl || '',
  thumbnails: data?.thumbnails || [],
  materialUrl: data?.materialUrl || '',
  requirement: data?.requirement || '',
  preLaunchDate: data?.preLaunchDate || '',
  serviceInformation: data?.serviceInformation || '',
  status: data?.status || TikTokSpecialCampaignStatus.DRAFT,
  campaignCategoryId: String(data?.campaignCategoryId || ''),
  hashtags: data?.hashtags ? formatHashTags(data.hashtags) : []
});

export const getTikTokCampaignFormInput = ({
  status: _status,
  saveOnLeave: _saveOnLeave,
  endDate,
  startDate,
  preLaunchDate,
  campaignCategoryId,
  ...restValues
}: TikTokCampaignFormValues): CreateTikTokSpecialCampaignInput => ({
  ...restValues,
  endDate: endDate || null,
  startDate: startDate || null,
  preLaunchDate: preLaunchDate || null,
  campaignCategoryId: campaignCategoryId ? Number(campaignCategoryId) : null
});

export const defaultTikTokCampaignFormValues = getTikTokCampaignFormValues();
