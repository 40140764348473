import { useTranslation } from 'react-i18next';
import { YearMonthDayPickerFieldProps, YearMonthDayPickerField } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const DateOfBirthField = (props: Omit<YearMonthDayPickerFieldProps, 'name' | 'format'>) => {
  const { t } = useTranslation();

  return (
    <YearMonthDayPickerField<InfluencerFormKeys>
      format="yyyy-MM-dd"
      help={t('Tooltip.Date Of Birth')}
      maxDate={new Date()}
      title={t('TextForm.Date Of Birth')}
      name={InfluencerSharedKeys.dateOfBirth}
      {...props}
    />
  );
};
