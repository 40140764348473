import { z } from 'zod';
import { TwitterAnalyticsPostsSortOrder } from '@/graphql';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { SearchFilterDefaults } from '@/shared/validations';

export const AnalyticsTwitterPostsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(ProfilePostsFilterSchema, { startDate: getThirtyDaysAgo(), endDate: getToday() }),
  sort: SearchFilterDefaults.sort(TwitterAnalyticsPostsSortOrder).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsTwitterPostsSearchSchemaType = z.infer<typeof AnalyticsTwitterPostsSearchSchema>;
