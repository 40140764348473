import { forwardRef } from 'react';

import { Icon } from '../Icon';

import { Input, InputProps } from './Input';

export type InputSearchProps = InputProps;

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(({ className, ...restProps }, ref) => (
  <div css={{ position: 'relative' }} className={className}>
    <Icon icon="search" size="16px" color="#C5D0DA" css={{ position: 'absolute', translate: '50% 50%' }} />
    <Input ref={ref} css={{ paddingLeft: '28px' }} {...restProps} />
  </div>
));
InputSearch.displayName = 'InputSearch';
