import {
  FOLLOWER_GROWTH_PERIOD_OPTIONS,
  FOLLOWER_GROWTH_RATE_RATIO_OPTIONS
} from '@/shared/constants';

import { useTranslateOptions } from './useTranslateOptions';

export const useFollowerGrowthOptions = () => {
  const followerGrowthRatioOptions = FOLLOWER_GROWTH_RATE_RATIO_OPTIONS;
  const followerGrowthPeriodOptions = useTranslateOptions(FOLLOWER_GROWTH_PERIOD_OPTIONS);

  return { followerGrowthRatioOptions, followerGrowthPeriodOptions };
};
