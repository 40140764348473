import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteBrandAccountAnalytics.graphql';
import { Mutation, MutationdeleteBrandAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useDeleteBrandAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteBrandAccountAnalyticsArgs>
) => {
  const [callDeleteBrandAccountAnalytics, result] = useMutation<Mutation, MutationdeleteBrandAccountAnalyticsArgs>(
    MUTATION,
    options
  );

  return { callDeleteBrandAccountAnalytics, ...result };
};

export type DeleteBrandAccountAnalyticsMutationFunction = Mutation['deleteBrandAccountAnalytics'];
