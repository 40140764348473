import { z } from 'zod';
import { FilterValidations } from '@/shared/validations';
import { FilterAction, UnionOfKeys } from '@/shared/types';
import { TikTokAdReportOrderField, TikTokAdReportType, TikTokAdStatus } from '@/graphql';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { INITIAL_PAGE_NUMBER, LIMIT } from '@/shared/constants';

export const AdsReportFilterSchema = z.object({
  adCampaignId: FilterValidations.string(),
  adGroupId: FilterValidations.string(),
  adReportType: FilterValidations.enum(TikTokAdReportType),
  endDate: FilterValidations.string().default(getToday()),
  keyword: FilterValidations.string(),
  startDate: FilterValidations.string().default(getThirtyDaysAgo()),
  status: FilterValidations.enum(TikTokAdStatus)
});
export const adsReportSortValidation = FilterValidations.sort(TikTokAdReportOrderField);
export const AdReportSearchSchema = z.object({
  filter: AdsReportFilterSchema.default({
    endDate: getToday(),
    startDate: getThirtyDaysAgo()
  }),
  sort: adsReportSortValidation.default({
    field: null,
    order: null
  }),
  page: z.number().default(INITIAL_PAGE_NUMBER),
  limit: z.number().default(LIMIT)
});

export type AdReportSearchSchemaType = z.infer<typeof AdReportSearchSchema>;
export type AdsReportFilterFormValues = z.infer<typeof AdsReportFilterSchema>;
export type AdsReportFilterFormKeys = UnionOfKeys<AdsReportFilterFormValues>;
export type AdsReportFilterProps = FilterAction<AdsReportFilterFormValues>;
export type AdsReportFilterSort = z.infer<typeof adsReportSortValidation>;
