import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SocialAccountInfluencerListSortField, SocialAccountType, TwitterInfluencer } from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { FilterValidations } from '@/shared/validations';
import { PostType, DefaultInfluencersProps, InfluencersFilterSchema, InfluencersBaseSearchSchema } from '../types';
import { getInitialTwitterInfluencersFormValues } from './utils';

export const TwitterInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  talentAgencyOrPartnerIds: true
});
export const TwitterInfluencersSearchSchema = z.object({
  filter: TwitterInfluencersFilterSchema.default(
    TwitterInfluencersFilterSchema.parse(getInitialTwitterInfluencersFormValues())
  ),
  page: z.number().default(1),
  sort: FilterValidations.sort(SocialAccountInfluencerListSortField).default({ field: null, order: null }),
  limit: z.number().default(LIMIT),
  state: InfluencersBaseSearchSchema.shape.state
});

export type TwitterInfluencersSearchSchemaType = z.infer<typeof TwitterInfluencersSearchSchema>;
export type TwitterInfluencersFilterFormValues = z.infer<typeof TwitterInfluencersFilterSchema>;
export type TwitterInfluencersFilterFormKeys = UnionOfKeys<TwitterInfluencersFilterFormValues>;
export type TwitterInfluencersProps = DefaultInfluencersProps<TwitterInfluencerProfile>;
export type TwitterInfluencerProfile = { posts: PostType[]; socialAccountType: SocialAccountType } & Pick<
  TwitterInfluencer,
  | 'id'
  | 'age'
  | 'name'
  | 'avatar'
  | 'gender'
  | 'country'
  | 'averageLikes'
  | 'isBrandAccount'
  | 'socialAccountId'
  | 'analyticsWarning'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagement'
  | 'socialAccountEngagementFollowersRate'
>;
