import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { SelectorField, SelectorFieldProps } from '@/shared/molecules';
import { useAllAvailableCountryOptions, useAllCountryOptions } from '@/shared/hooks';
import { InfluencerFormKeys, InfluencerFormValues } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

interface CountryFieldProps extends Omit<SelectorFieldProps, 'name'> {
  hasAllAvailableCountries?: boolean;
}
export const CountryField = ({ hasAllAvailableCountries, ...restProps }: CountryFieldProps) => {
  const { t } = useTranslation();
  const { allCountryOptions } = useAllCountryOptions({ skip: hasAllAvailableCountries });
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions({ skip: !hasAllAvailableCountries });
  const { watch, setValue } = useFormContext<InfluencerFormValues>();

  const countryIdValue = watch('countryId');

  return (
    <SelectorField<InfluencerFormKeys>
      help={t('Tooltip.Country')}
      title={t('Selector.Country')}
      name={InfluencerSharedKeys.countryId}
      options={hasAllAvailableCountries ? allAvailableCountryOptions : allCountryOptions}
      onChange={(name, value) => {
        if (value !== countryIdValue) {
          setValue(name, value, { shouldValidate: true });
          setValue(InfluencerSharedKeys.regionId, '', { shouldValidate: true });
        }
      }}
      {...restProps}
    />
  );
};
