import { useTranslation } from 'react-i18next';
import { RichEditorFieldProps, RichEditorField } from '@/shared/molecules';
import { SunEditorRef, SunEditorRefType } from '@/shared/atoms';
import { MarketplaceFormKeys } from '../types';

export const ServiceInformationField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  getSunEditorInstance,
  ...restProps
}: Partial<RichEditorFieldProps<T>> & {
  getSunEditorInstance?: (seRef: SunEditorRef, refType: SunEditorRefType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <RichEditorField<T>
      title={t('TextForm.Service Info')}
      help={t('Tooltip.Product or Service Information')}
      name={name || (MarketplaceFormKeys.serviceInformation as T)}
      placeholder={t<string>('TextForm.Service Information Placeholder')}
      getSunEditorInstance={(seRef) => getSunEditorInstance?.(seRef, 'serviceInfo')}
      {...restProps}
    />
  );
};
