import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { linkOptions } from '@tanstack/react-router';
import { useUserPermissions, useUserRoles } from '@/auth';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { SpreadButton } from '@/shared/molecules';
import { getUserRoleString } from '@/shared/utils';
import { AccountUserType } from '../types';

interface Props {
  data: readonly AccountUserType[];
  onDelete: (account: { id: number; name: string }) => void;
}

export const useAccountUsersTable = ({ data, onDelete }: Props) => {
  const { t } = useTranslation();
  const { isAdmin, isStaff } = useUserRoles();
  const { hideEditUserBtn, hideDeleteUserBtn } = useUserPermissions();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Name') },
    { title: t('HeaderColumn.Role') },
    { title: t('HeaderColumn.CompanyName'), hidden: isStaff }
  ];
  const rows: RowProps[] = data.map(({ id, name, role, companyName }) => {
    const editUserLink = linkOptions({ to: '/user/$id', params: { id: id.toString() } });

    return [
      <div css={styles.nameColumnWrapper}>
        <TableCell.Link {...editUserLink} label={name} disabled={hideEditUserBtn} />
        <SpreadButton
          collapseFrom={0}
          moreButtonProps={{ disabled: hideDeleteUserBtn && hideEditUserBtn }}
          options={[
            {
              label: t('Edit'),
              icon: 'edit',
              ...editUserLink,
              hidden: hideEditUserBtn,
              tooltip: { help: t('Button.Edit') }
            },
            {
              icon: 'delete',
              label: t('Delete'),
              onClick: () => onDelete({ id, name }),
              hidden: !(isAdmin && !hideDeleteUserBtn),
              tooltip: { help: t('Button.Delete') }
            }
          ]}
        />
      </div>,
      <TableCell.Text value={t(`Option.${getUserRoleString(role)}`)} />,
      <TableCell.Text value={companyName || '-'} lines={2} />
    ];
  });

  return { rows, columns };
};

const styles = {
  nameColumnWrapper: css({
    display: 'flex',
    maxWidth: '400px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  })
};
