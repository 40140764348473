import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import {
  CampaignType,
  InfluencerCampaignStat,
  CampaignPromotionMethod,
  CampaignSocialMediaType,
  MarketplaceCampaignType,
  InfluencerCampaignDetail,
  InfluencerCampaignPostData,
  InfluencerCampaignListSortField,
  MarketplaceCampaignDetailType
} from '@/graphql';
import {
  Anchor,
  RowProps,
  TableCell,
  TextCutter,
  SocialIcon,
  ColumnProps,
  MediaPreview,
  SocialIconProps
} from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { formatPrice, formatPercent, formatIntNumber, getSocialMediaTextLink } from '@/shared/utils';

interface Row {
  currency: string;
  numberOfPosts: number;
  showCampaignInfo: boolean;
  joinedDate: string | null;
  post?: {
    id?: number | null;
    url?: string | null;
    content?: string | null;
    thumbnail?: string | null;
    socialMedia: CampaignSocialMediaType;
    promotionMethods: readonly CampaignPromotionMethod[];
    mkpCampaignType?: MarketplaceCampaignType | null;
  };
  campaign: {
    id?: number | null;
    type: CampaignType;
    title: string | null;
    thumbnail: string | null;
    hasPostTracking: boolean | null;
    mkpDetailType?: MarketplaceCampaignDetailType | null;
  };
  summary: InfluencerCampaignStat;
}

type CustomColumnProps = ColumnProps<InfluencerCampaignListSortField>;

interface Props {
  data: readonly InfluencerCampaignDetail[];
}

export const useInfluencerProfileCampaignListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { isTalentAgencyPartner } = useUserRoles();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const listRows = data.reduce<Row[]>((acc, { campaignInfo, currency, summary, posts, joinedDate }) => {
    const numberOfPosts = posts.length;
    const campaign: Row['campaign'] = {
      id: campaignInfo.id,
      type: campaignInfo.type,
      title: campaignInfo.title,
      mkpDetailType: campaignInfo.mkpDetailType,
      thumbnail: campaignInfo.thumbNail || '',
      hasPostTracking: !!campaignInfo.hasPostTracking
    };

    const newRow: Row = {
      summary,
      currency,
      campaign,
      joinedDate,
      numberOfPosts,
      post: undefined,
      showCampaignInfo: true
    };

    if (numberOfPosts === 1) {
      const { postInfo } = posts.at(0) as InfluencerCampaignPostData;

      return [
        ...acc,
        {
          ...newRow,
          post: {
            id: postInfo.id,
            url: postInfo.postUrl,
            content: postInfo.content,
            thumbnail: postInfo.thumbNail,
            socialMedia: postInfo.socialMedia,
            mkpCampaignType: postInfo.mkpCampaignType,
            promotionMethods: postInfo.promotionMethods
          }
        }
      ];
    }

    const newRows = posts.map(({ postInfo, summary: postSummary }) => ({
      currency,
      campaign,
      numberOfPosts,
      summary: postSummary,
      joinedDate,
      showCampaignInfo: false,
      post: {
        id: postInfo.id,
        url: postInfo.postUrl,
        content: postInfo.content,
        thumbnail: postInfo.thumbNail,
        socialMedia: postInfo.socialMedia,
        mkpCampaignType: postInfo.mkpCampaignType,
        promotionMethods: postInfo.promotionMethods
      }
    }));

    return [...acc, newRow, ...newRows];
  }, []);
  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Campaign'), sticky: { active: true } },
    { title: t('HeaderColumn.Content'), sticky: { active: true, left: '264px', hasBorderRight: true } },
    getMetricColumnProps({
      title: t('HeaderColumn.Influencer Cost'),
      sortField: InfluencerCampaignListSortField.COST
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement'),
      sortField: InfluencerCampaignListSortField.ENGAGEMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement Rate'),
      sortField: InfluencerCampaignListSortField.ENGAGEMENT_RATE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      sortField: InfluencerCampaignListSortField.LIKE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      sortField: InfluencerCampaignListSortField.COMMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Share'),
      sortField: InfluencerCampaignListSortField.SHARE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Reach'),
      sortField: InfluencerCampaignListSortField.REACH
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Click'),
      sortField: InfluencerCampaignListSortField.CLICK
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Conversion'),
      sortField: InfluencerCampaignListSortField.CONVERSION
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.CV Rate'),
      sortField: InfluencerCampaignListSortField.CVR
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Joined Date'),
      sortField: InfluencerCampaignListSortField.JOINED_DATE
    })
  ];
  const rows: RowProps[] = listRows.map(
    ({ currency, numberOfPosts, showCampaignInfo, campaign, post, summary, joinedDate }) => {
      const canAccessMarketplace =
        !(campaign.type === CampaignType.MARKETPLACE && isTalentAgencyPartner) &&
        // no campaign ID means that current campaign is not associated with logged in user, so we should disable the links
        campaign.id;

      return [
        showCampaignInfo ? (
          <TableCell.Campaign
            type={campaign.type}
            src={campaign.thumbnail}
            id={campaign.id?.toString() || ''}
            isPostTracking={!!campaign.hasPostTracking}
            mkpCampaignType={campaign.mkpDetailType}
            title={campaign.title || ''}
            css={{
              padding: 0,
              width: '240px',
              border: 'none',
              backgroundColor: 'transparent',
              pointerEvents: canAccessMarketplace ? 'auto' : 'none'
            }}
          />
        ) : (
          <div />
        ),
        post ? (
          !campaign.hasPostTracking ? (
            <span>
              {post.promotionMethods?.map((promoMethod) => t(`PromotionMethod.${promoMethod}`)).join(', ') || '-'}
            </span>
          ) : (
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <MediaPreview
                src={post.thumbnail}
                css={{ width: '48px', height: '48px', marginRight: '8px' }}
                onClick={() => (canAccessMarketplace ? setPostDetailsParam(post.id) : null)}
              />
              <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '170px' }}>
                <TextCutter
                  lines={2}
                  text={post.content}
                  onClick={() => (canAccessMarketplace ? setPostDetailsParam(post.id) : null)}
                />
                {post.url ? (
                  <Anchor
                    variant="blue"
                    href={post.url}
                    target="_blank"
                    css={{ display: 'flex', alignItems: 'center', fontSize: '12px', marginTop: '6px' }}
                  >
                    <SocialIcon
                      size="18px"
                      type={post.socialMedia as SocialIconProps['type']}
                      css={{ marginRight: '4px' }}
                    />
                    {t('View on', { socialMedia: getSocialMediaTextLink(post.socialMedia) })}
                  </Anchor>
                ) : null}
              </div>
            </div>
          )
        ) : (
          <TableCell.Text
            value={
              numberOfPosts === 0 ? (
                t(campaign?.type === CampaignType.MARKETPLACE ? 'Joined the campaign' : 'Selected for the campaign')
              ) : (
                <>
                  <b>{numberOfPosts}</b>&nbsp;
                  {t('Posted')}
                </>
              )
            }
          />
        ),
        <TableCell.Number value={formatPrice(summary.cost, currency)} unit={summary.cost ? t(currency) : ''} />,
        <TableCell.Number value={formatIntNumber(summary.engagement)} />,
        <TableCell.Number value={formatPercent(summary.engagementRate, false)} unit="%" />,
        <TableCell.Number value={formatIntNumber(summary.like)} />,
        <TableCell.Number value={formatIntNumber(summary.comment)} />,
        <TableCell.Number value={formatIntNumber(summary.share)} />,
        <TableCell.Number value={formatIntNumber(summary.reach)} />,
        <TableCell.Number value={formatIntNumber(summary.click)} />,
        <TableCell.Number value={formatIntNumber(summary.conversion)} />,
        <TableCell.Number value={formatPercent(summary.cvr, false)} unit="%" />,
        <TableCell.Date value={joinedDate} />
      ];
    }
  );

  return {
    rows,
    columns,
    allPosts: listRows.reduce<PostDetailsType[]>(
      (acc, { post, campaign }) =>
        post?.id && campaign.hasPostTracking ? [...acc, { id: String(post.id), campaignType: campaign.type }] : acc,
      []
    )
  };
};
