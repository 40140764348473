import { css } from '@emotion/react';

import { TextCutter, Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar } from '@/shared/molecules';

export interface PostReportCaptionProps {
  caption: string;
  username: string;
  avatarUrl: string | null;
}
export const PostReportCaption = ({ avatarUrl, username, caption }: PostReportCaptionProps) => (
  <div css={styles.wrapper}>
    <div css={styles.nameAndAvatar}>
      <TextCutter text={username} lines={1} css={{ fontSize: '10px', fontWeight: 600 }} />
      <InfluencerAvatar src={avatarUrl} size="24px" css={{ borderRadius: '50%' }} />
    </div>
    <div css={styles.captionWrapper}>
      <Icon icon="double-quote" size={22} css={{ position: 'absolute', top: '-10px', left: '10px' }} />
      <TextCutter
        text={caption}
        lines={5}
        css={{
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: 'calc(1em + 1ex)'
        }}
      />
    </div>
  </div>
);

const styles = {
  wrapper: css({
    width: '208px',
    margin: '18px',
    display: 'flex',
    height: '202px',
    borderRadius: '14px',
    flexDirection: 'column',
    color: THEME.text.colors.black.lv1,
    backgroundColor: THEME.background.colors.white
  }),
  nameAndAvatar: css({
    gap: '4px',
    display: 'flex',
    marginTop: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '5px 16px 5px 24px'
  }),
  captionWrapper: css({
    height: '150px',
    position: 'relative',
    margin: '0 16px 15px',
    boxSizing: 'border-box',
    padding: '8px 18px 18px',
    borderRadius: '12px 0 12px 12px',
    backgroundColor: THEME.background.colors.gray.lv1
  })
};
