import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthData } from '@/auth';
import { FormStyle } from '@/shared/atoms';
import { AutoManagedCampaignType } from '@/graphql';
import {
  MIN_PRICE,
  MAX_PRICE,
  MIN_COMMISSION_RATE,
  MAX_COMMISSION_RATE,
  MIN_MAX_PRICE_OPTIONS,
  AUTO_MANAGED_COUNTRY_OPTIONS,
  MIN_MAX_COMMISSION_RATE_OPTIONS,
  AUTO_MANAGED_CAMPAIGN_TYPE_OPTIONS
} from '@/shared/constants';
import { useAllInfluencerCategoryOptions, useTranslateCountryOptions } from '@/shared/hooks';
import {
  SelectorField,
  TextSearchField,
  RangeSliderInputField,
  FetchAutoManagedCustomShopeeCampaigns
} from '@/shared/molecules';
import {
  MarketplaceAutoManagedCampaignFilterFormValues,
  MarketplaceAutoManagedCampaignFilterKeys
} from './schemaTypes';

interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const {
    auth: { currencyId }
  } = useAuthData();
  const { allInfluencerCategoryOptions } = useAllInfluencerCategoryOptions();
  const allAvailableAutoManagedCountriesTranslated = useTranslateCountryOptions(AUTO_MANAGED_COUNTRY_OPTIONS);
  const { getValues } = useFormContext<MarketplaceAutoManagedCampaignFilterFormValues>();
  const marketplaceType = getValues('marketplaceType');

  return (
    <div css={{ padding: '24px 24px 16px 24px' }}>
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <p css={{ fontSize: '16px', fontWeight: 600 }}>{t('Select Offer')}</p>
          <p css={{ fontSize: '14px', marginTop: '8px' }}>
            {t('Select offer that you want to provide with special deal')}
          </p>
        </div>
        {marketplaceType === AutoManagedCampaignType.SHOPEE ? (
          <FetchAutoManagedCustomShopeeCampaigns css={{ alignSelf: 'flex-start' }} />
        ) : null}
      </div>

      <FormStyle.Wrapper gapRow={16} gapColumn={8}>
        <FormStyle.FieldsGroup>
          <SelectorField<MarketplaceAutoManagedCampaignFilterKeys>
            onSubmit={onSubmit}
            name="marketplaceType"
            css={{ maxWidth: '160px' }}
            options={AUTO_MANAGED_CAMPAIGN_TYPE_OPTIONS}
            placeholder={t<string>('Selector.Marketplace')}
          />
          <TextSearchField<MarketplaceAutoManagedCampaignFilterKeys>
            name="keyword"
            onSubmit={onSubmit}
            placeholder={t<string>('Search.Search by keyword, brand')}
          />
          <SelectorField<MarketplaceAutoManagedCampaignFilterKeys>
            name="countryId"
            onSubmit={onSubmit}
            placeholder={t<string>('Selector.Country')}
            options={allAvailableAutoManagedCountriesTranslated}
            css={{ maxWidth: '160px' }}
          />
          <SelectorField<MarketplaceAutoManagedCampaignFilterKeys>
            onSubmit={onSubmit}
            placeholder={t<string>('Selector.Categories')}
            name="categoryIds"
            css={{ maxWidth: '160px' }}
            options={allInfluencerCategoryOptions}
            multiple
            multipleSelectorProps={{
              menuCss: css({
                width: 'max-content',
                maxWidth: '300px',
                marginTop: '4px',
                right: 0
              })
            }}
          />
        </FormStyle.FieldsGroup>

        <FormStyle.FieldsGroup css={{ justifyContent: 'flex-end' }}>
          <div css={{ display: 'flex', alignItems: 'center', flexBasis: '50%', maxWidth: '320px' }}>
            <p css={{ fontSize: '14px', marginRight: '4px' }}>{t('Commission rate')}</p>
            <RangeSliderInputField<MarketplaceAutoManagedCampaignFilterKeys>
              onSubmit={onSubmit}
              options={MIN_MAX_COMMISSION_RATE_OPTIONS}
              minField={{ name: 'minCommissionRate', placeholder: `${MIN_COMMISSION_RATE}%` }}
              maxField={{ name: 'maxCommissionRate', placeholder: `${MAX_COMMISSION_RATE}%` }}
              hideSlider
            />
          </div>
          <div css={{ display: 'flex', alignItems: 'center', flexBasis: '50%', marginLeft: '12px', maxWidth: '358px' }}>
            {/* TODO: currently we are showing User's currency here, but BE has no idea about it, probably we need to update API with it */}
            <p css={{ fontSize: '14px', marginRight: '4px' }}>
              {t('Price')}
              {currencyId ? ` (${t(currencyId)})` : ''}
            </p>
            <RangeSliderInputField<MarketplaceAutoManagedCampaignFilterKeys>
              onSubmit={onSubmit}
              options={MIN_MAX_PRICE_OPTIONS}
              minField={{ name: 'minPrice', placeholder: String(MIN_PRICE) }}
              maxField={{ name: 'maxPrice', placeholder: `${MAX_PRICE}+` }}
              hideSlider
            />
          </div>
        </FormStyle.FieldsGroup>
      </FormStyle.Wrapper>
    </div>
  );
};
