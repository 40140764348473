import { getValuableVariables } from '@/shared/utils';
import { InstagramInteractionOverview, useInstagramInteractionOverviewQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { CAN_NOT_GET_ANALYTICS_ACCOUNT } from '@/shared/constants';
import { RenderDataWithFallbackV2 } from '@/shared/atoms';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { Template } from './Template';

export interface AnalyticsInstagramInteractionOverviewProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
  hasFreePlan: boolean;
  mockOverview?: InstagramInteractionOverview;
}

export const AnalyticsInstagramInteractionOverview = ({
  analyticsAccount,
  hasFreePlan,
  mockOverview
}: AnalyticsInstagramInteractionOverviewProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data } = useInstagramInteractionOverviewQuery({
    fetchPolicy: 'no-cache',
    skip: hasFreePlan,
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      analyticsAccountId: analyticsAccount.id
    }),
    onError: () => {
      enqueueSnackbar(t(CAN_NOT_GET_ANALYTICS_ACCOUNT), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <RenderDataWithFallbackV2 data={hasFreePlan ? mockOverview : data?.instagramInteractionOverview}>
      {(readyData) => <Template data={readyData} />}
    </RenderDataWithFallbackV2>
  );
};
