import { css } from '@emotion/react';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useUserRoles } from '@/auth';
import {
  UserRoles,
  MarketplaceDetail,
  MarketplaceCampaignStatus,
  useApproveMarketplaceMutation,
  useRejectMarketplaceCampaignMutation
} from '@/graphql';
import {
  Modal,
  Label,
  Input,
  Button,
  Notice,
  ModalTitle,
  ButtonProps,
  ModalContent,
  ModalFooterActions
} from '@/shared/atoms';
import { UNEXPECTED_ERROR, THEME, AFFILIATE_CAMPAIGN_TYPES } from '@/shared/constants';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { localizedDateFormatter } from '@/shared/utils';

interface Props {
  marketplace: MarketplaceDetail;
}

export const useUpdateMarketplaceStatus = ({
  marketplace: {
    id,
    status,
    title,
    rejectedInfo,
    campaignType,
    hubspotDealIds,
    isPostTracking,
    campaignCreator,
    allowNewInfluencer,
    isAllowMultiplePosts,
    isAutoInfluencerApproval
  }
}: Props) => {
  const rejectDialog = useToggleState();
  const approveDialog = useToggleState();
  const setDealIdDialog = useToggleState();
  const [reason, setReason] = useState('');

  const { isAdminStaff, isAdvertiser } = useUserRoles();
  const { t, navigate, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { callApproveMarketplace, loading: approving } = useApproveMarketplaceMutation({
    onCompleted: invalidateRouteLoader
  });
  const { callRejectMarketplaceCampaign, loading: rejecting } = useRejectMarketplaceCampaignMutation({
    onCompleted: invalidateRouteLoader
  });

  // Adv users can only approve campaign if sales&Infl PICS were added;
  // admin|staff users only can't approve if campaign is created by them and has no deal_id
  const needToSetDealIdBeforeApprove =
    isAdminStaff &&
    hubspotDealIds?.length === 0 &&
    campaignCreator?.role &&
    [UserRoles.ADMINISTRATOR, UserRoles.STAFF].includes(campaignCreator.role);

  const handleApproveMarketplaceClick = async () => {
    try {
      await callApproveMarketplace({ variables: { input: { id } } });

      enqueueSnackbar(t('succeededInUpdating', { name: title }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: title }), { variant: 'error' });
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    } finally {
      approveDialog.close();
    }
  };
  const handleRejectMarketplaceClick = async () => {
    try {
      await callRejectMarketplaceCampaign({ variables: { input: { marketplaceId: id, reason } } });

      enqueueSnackbar(t('succeededInUpdating', { name: title }), { variant: 'success' }); // TODO: has been rejected!
    } catch (error) {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    } finally {
      rejectDialog.close();
    }
  };

  const hasApproveRejectNotice =
    isAdminStaff && campaignCreator?.role === UserRoles.ADVERTISER && status === MarketplaceCampaignStatus.REVIEWING;
  const rejectedByAdminStaff = status === MarketplaceCampaignStatus.REJECTED;
  const hasCustomApproveRejectWarningBar = hasApproveRejectNotice || (isAdvertiser && rejectedByAdminStaff);

  return {
    hasApproveRejectNotice,
    hasCustomApproveRejectWarningBar,
    ApproveButton: (props: ButtonProps) => (
      <Button
        variant="blue"
        loading={approving}
        css={{ minWidth: '200px', textTransform: 'uppercase' }}
        title={t(approving ? 'Button.Updating' : 'Button.Approve this campaign')}
        onClick={needToSetDealIdBeforeApprove ? setDealIdDialog.open : approveDialog.open}
        {...props}
      />
    ),
    ApproveRejectWarningBar: () => (
      <>
        <Notice
          type="warning"
          css={{ margin: '16px 0' }}
          open={rejectedByAdminStaff}
          description={t(rejectedInfo?.reason || '')}
          title={
            <Trans
              i18nKey="CampaignIsRejectedByNameOnDate"
              components={{ span: <span css={{ fontWeight: 400 }} /> }}
              values={{
                name: isAdminStaff ? rejectedInfo?.operatedUser.name : 'AnyMind Staff',
                date: localizedDateFormatter(rejectedInfo?.operatedDate || '', 'yyyy/MM/dd')
              }}
            />
          }
        />

        <Notice
          type="info"
          css={{ margin: '16px 0' }}
          open={hasApproveRejectNotice}
          title={
            rejectedInfo ? (
              <Trans
                i18nKey="CampaignIsModifiedByNameOnDate"
                components={{ span: <span css={{ fontWeight: 400 }} /> }}
                values={{
                  name: campaignCreator?.name,
                  date: localizedDateFormatter(rejectedInfo?.operatedDate, 'yyyy/MM/dd')
                }}
              />
            ) : (
              t('This campaign is created by', { name: campaignCreator?.name })
            )
          }
          description={
            rejectedInfo
              ? `${t('TextForm.Reason for rejection')}: ${rejectedInfo?.reason || '-'}`
              : t('Please check the details and approve or reject this campaign')
          }
        >
          <div css={{ gap: '16px', display: 'flex' }}>
            <Button
              variant="red"
              loading={rejecting}
              title={t('Button.Reject')}
              onClick={rejectDialog.open}
              css={{ minWidth: '69px', padding: '0 16px' }}
            />
            <Button
              variant="blue"
              loading={approving}
              title={t('Button.Approve')}
              onClick={approveDialog.open}
              css={{ minWidth: '89px', padding: '0 16px' }}
            />
          </div>
        </Notice>
      </>
    ),
    DialogModals: (
      <>
        <Modal
          open={approveDialog.status}
          onClose={approveDialog.close}
          css={{ width: 'max-content', maxWidth: '600px' }}
        >
          <ModalContent>
            <ModalTitle>{t('Check the campaign settings before you start campaign')}</ModalTitle>
            <p css={{ marginTop: '24px' }}>
              <Trans
                i18nKey="These settings cannot be edited once you approve this campaign"
                components={{ b: <b /> }}
              />
            </p>
            <ul css={styles.approveUl}>
              {AFFILIATE_CAMPAIGN_TYPES.includes(campaignType) ? (
                <li>
                  {t(`Track creator's posts:`)}&nbsp;
                  <span css={{ fontWeight: 600 }}>{t(isPostTracking ? 'On' : 'Off')}</span>
                </li>
              ) : null}
              <li>
                {t('How to approve influencers:')}&nbsp;
                <span css={{ fontWeight: 600 }}>{t(isAutoInfluencerApproval ? 'Auto-approve' : 'Manual-approve')}</span>
              </li>
              <li>
                {t('Multi-Posting for creator:')}&nbsp;
                <span css={{ fontWeight: 600 }}>{t(isAllowMultiplePosts ? 'Enable' : 'Disable')}</span>
              </li>
              <li>
                {t('Normal or Closed campaign:')}&nbsp;
                <span css={{ fontWeight: 600 }}>{t(allowNewInfluencer ? 'Normal' : 'Closed')}</span>
              </li>
            </ul>
          </ModalContent>
          <ModalFooterActions
            cancelButtonProps={{ onClick: approveDialog.close }}
            submitButtonProps={{
              loading: approving,
              onClick: handleApproveMarketplaceClick,
              title: t(approving ? 'Button.Updating' : 'Button.Approve')
            }}
          />
        </Modal>

        <Modal open={rejectDialog.status} onClose={rejectDialog.close} css={{ maxWidth: '600px' }}>
          <ModalContent>
            <ModalTitle>{t('Are you sure you want to reject the campaign?')}</ModalTitle>
            <p css={{ margin: '16px 0' }}>{t('rejectMkpCampaignReasonDescription')}</p>
            <Label title={t('Reason of rejection')} optional />
            <Input value={reason} onChange={(e) => setReason(e.target.value)} />
          </ModalContent>

          <ModalFooterActions
            cancelButtonProps={{ onClick: rejectDialog.close }}
            submitButtonProps={{
              variant: 'red',
              loading: rejecting,
              title: t('Button.Reject'),
              onClick: handleRejectMarketplaceClick
            }}
          />
        </Modal>

        <Modal css={{ width: '500px' }} open={setDealIdDialog.status} onClose={setDealIdDialog.close} hasCloseIcon>
          <ModalContent css={{ padding: '24px' }}>
            <ModalTitle>{t('Set HubSpot deal')}</ModalTitle>
            <div css={{ marginTop: '16px' }}>{t('You need to set HubSpot deal in order to approve this campaign')}</div>
          </ModalContent>

          <ModalFooterActions
            cancelButtonProps={{ onClick: setDealIdDialog.close }}
            submitButtonProps={{
              onClick: () => {
                navigate({ to: '/marketplace/$id', params: { id: id.toString() } });
                setDealIdDialog.close();
              }
            }}
          />
        </Modal>
      </>
    )
  };
};

const styles = {
  approveUl: css({
    marginTop: '24px',
    backgroundColor: THEME.background.colors.gray.lv1,
    padding: '8px',
    li: {
      fontSize: '14px',
      lineHeight: '20px',
      '&::before': {
        content: '"•"',
        fontSize: '10px',
        lineHeight: '20px',
        verticalAlign: 'top',
        marginRight: '4px'
      }
    }
  })
};
