import { zodValidator } from '@tanstack/zod-adapter';
import { PermissionRoute } from '@/app-components';
import {
  AdvertiserDetails,
  AddAdvertiser,
  AdvertisersPage,
  EditAdvertiser,
  AdvertiserUsers,
  AdvertiserBudgetAllocation
} from '@/pages/AdvertisersPage';
import { adminStaffAgencyTalentAgencyPartnerRoles } from '@/shared/constants';
import { AccountFilterSearchSchema } from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const AdvertiserRouter = {
  '/_private-routes/advertiser': [() => <PermissionRoute permissions={adminStaffAgencyTalentAgencyPartnerRoles} />, {}],
  '/_private-routes/advertiser/$id': [AdvertiserDetails, {}],
  '/_private-routes/advertiser/add': [AddAdvertiser, {}],
  '/_private-routes/advertiser/': [AdvertisersPage, { validateSearch: zodValidator(AccountFilterSearchSchema) }],
  '/_private-routes/advertiser/$id/': [EditAdvertiser, {}],
  '/_private-routes/advertiser/$id/users': [AdvertiserUsers, {}],
  '/_private-routes/advertiser/$id/budget-allocation': [AdvertiserBudgetAllocation, {}]
} as const satisfies PartialRouter;
