import { TwitterAnalyticsOverviewPayload } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { DAILY_POST_PARAM_KEY } from '@/shared/constants';
import { useSelectItemIds } from '@/shared/hooks';
import { TwitterPostInDateDetailsModal } from '@/shared/molecules';
import { NoteAggregateLastThirtyDays } from '@/shared/organisms';
import {
  AnalyticsOverviewChart,
  AnalyticsOverviewLegend,
  AnalyticsOverviewWrapper,
  AnalyticsOverviewEngagement
} from '../../../shared';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import {
  useAnalyticsTwitterDashboardOverviewData,
  useAnalyticsTwitterDashboardOverviewPosts,
  useAnalyticsTwitterDashboardOverviewLegend
} from './hooks';
import { AnalyticsTwitterDashboardGraphKeys as Keys } from './types';

interface TemplateProps {
  data: TwitterAnalyticsOverviewPayload;
  accountId: AnalyticsSelectedAccountInfo['id'];
  isInfluencerProfile?: boolean;
}

const Template = ({ data, accountId, isInfluencerProfile }: TemplateProps) => {
  const { allLegendOptions, availableLegendOptions } = useAnalyticsTwitterDashboardOverviewLegend({
    isInfluencerProfile
  });
  const { selectItem: setSelectedLegendKey, selectedItemIds: selectedLegendKeys } = useSelectItemIds<Keys>({
    defaultSelectedIds: ['follower', 'post']
  });
  const { chartData, categories, overviewEngagements, availableOverviewEngagements } =
    useAnalyticsTwitterDashboardOverviewData({
      data,
      selectedLegendKeys,
      isInfluencerProfile,
      availableEngagementKeys: allLegendOptions.map((item) => item.value)
    });
  const { dailyPosts, customChartOptions } = useAnalyticsTwitterDashboardOverviewPosts({
    chartData,
    accountId,
    isInfluencerProfile
  });

  const hasNoData = !overviewEngagements.some((item) => !!item.total);

  return (
    <>
      <AnalyticsOverviewWrapper
        help=""
        dataNotAvailable={hasNoData}
        headerChildren={!hasNoData && isInfluencerProfile ? <NoteAggregateLastThirtyDays /> : null}
      >
        <AnalyticsOverviewEngagement
          engagement={availableOverviewEngagements}
          itemsPerRow={isInfluencerProfile ? 5 : 6}
        />
        <AnalyticsOverviewLegend
          options={availableLegendOptions}
          selectedOptions={selectedLegendKeys}
          onSelectOption={({ value }) => setSelectedLegendKey(value)}
        />
        <AnalyticsOverviewChart
          data={chartData}
          categories={categories}
          customTooltip={{
            metrics: [
              ['follower', 'post', 'like'],
              ['view', 'reply', 'retweet'],
              ['quotedTweet', 'impression', 'engagement'],
              ['engagementRate', 'urlClick', 'profileClick']
            ]
          }}
          customOptions={customChartOptions}
        />
      </AnalyticsOverviewWrapper>

      <TwitterPostInDateDetailsModal data={dailyPosts} paramKey={DAILY_POST_PARAM_KEY} />
    </>
  );
};

export interface AnalyticsTwitterDashboardOverviewTemplateProps
  extends Pick<TemplateProps, 'accountId' | 'isInfluencerProfile'> {
  loading?: boolean;
  data?: TwitterAnalyticsOverviewPayload | null;
}
export const AnalyticsTwitterDashboardOverviewTemplate = ({
  data,
  loading,
  ...restProps
}: AnalyticsTwitterDashboardOverviewTemplateProps) => (
  <RenderDataWithFallback loading={loading} skipDelayed>
    {data ? <Template {...restProps} data={data} /> : null}
  </RenderDataWithFallback>
);
