import { CampaignFilter, CampaignIdentifierInput, CampaignIdentifierType, CampaignType } from '@/graphql';

export const getCampaignType = (type?: string | null) => {
  switch (type) {
    case 'engagement':
      return CampaignType.ENGAGEMENT;
    case 'marketplace':
      return CampaignType.MARKETPLACE;
    case 'affiliate':
      return CampaignType.AFFILIATE;
    case 'auto_managed':
      return CampaignType.AUTO_MANAGED;

    default:
      return null;
  }
};
export const getCampaignIdentifierType = (type?: string | null) => {
  switch (type) {
    case 'engagement':
      return CampaignIdentifierType.ENGAGEMENT;
    case 'marketplace':
      return CampaignIdentifierType.MARKETPLACE;

    default:
      return null;
  }
};

export const getCampaignsFromUrlString = (campaigns: string[]) =>
  campaigns.reduce<CampaignFilter[]>((rs, campaign) => {
    const [campaignType, id] = campaign.split('/');
    const type = getCampaignType(campaignType);

    if (type && id) {
      return [...rs, { id: Number(id), type }];
    } else {
      return rs;
    }
  }, []) || [];

export const getCampaignsIdentifierFromUrlString = (campaigns: string[]) =>
  campaigns.reduce<CampaignIdentifierInput[]>((rs, campaign) => {
    const [campaignType, id] = campaign.split('/');
    const type = getCampaignIdentifierType(campaignType);

    if (type && id) {
      return [...rs, { campaignId: Number(id), campaignType: type }];
    } else {
      return rs;
    }
  }, []) || [];
