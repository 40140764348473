import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Radio } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { FilterAction } from '@/shared/types';
import { AffiliateReportShowBy, AutoManagedReportSummary } from '@/graphql';
import { AutoManagedReportFilterFormValues } from '../schemaTypes';
import { AutoManagedReportByDateTable } from './AutoManagedReportByDateTable';
import { AutoManagedReportByInfluencerTable } from './AutoManagedReportByInfluencerTable';

interface AutoManagedReportTableProps extends FilterAction<AutoManagedReportFilterFormValues> {
  currency: string;
  campaignId: number;
  summaryData?: AutoManagedReportSummary;
}

export const AutoManagedReportTable = ({
  filter,
  currency,
  setFilter,
  campaignId,
  summaryData
}: AutoManagedReportTableProps) => {
  const { t } = useTranslation();

  const isShowByDate = filter.mode === AffiliateReportShowBy.DATE;
  const defaultTableProps = { currency, campaignId, summaryData };

  const handleModeChange = (mode: AffiliateReportShowBy) => {
    setFilter({ mode });
  };

  return (
    <>
      <div css={styles.wrapper}>
        <label css={{ fontWeight: 600 }}>{t('General.Day Segment')}</label>
        <Radio
          label={t('Show by date')}
          checked={isShowByDate}
          onClick={() => handleModeChange(AffiliateReportShowBy.DATE)}
        />
        <Radio
          label={t('Show by Influencer')}
          checked={!isShowByDate}
          onClick={() => handleModeChange(AffiliateReportShowBy.INFLUENCER)}
        />
      </div>

      {isShowByDate ? (
        <AutoManagedReportByDateTable {...defaultTableProps} />
      ) : (
        <AutoManagedReportByInfluencerTable {...defaultTableProps} />
      )}
    </>
  );
};
const styles = {
  wrapper: css({
    gap: '8px',
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderTop: '1px solid #eef3f7',
    backgroundColor: THEME.background.colors.white
  })
};
