import { Date } from './Date';

interface PeriodProps {
  className?: string;
  period: { startDate?: string | null; endDate?: string | null };
}

export const Period = ({ period: { startDate, endDate }, className }: PeriodProps) =>
  !startDate && !endDate ? (
    <div>-</div>
  ) : (
    <div css={{ display: 'flex' }} className={className}>
      <Date value={startDate} />
      <span css={{ margin: 'auto 6px' }}>-</span>
      <Date value={endDate} />
    </div>
  );
