import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateInstagramUGCManagementHashtag.graphql';
import { Mutation, MutationcreateInstagramUGCManagementHashtagArgs } from '../../__generated__/globalTypes';

export const useCreateInstagramUGCManagementHashtagMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateInstagramUGCManagementHashtagArgs>
) => {
  const [callCreateInstagramUGCManagementHashtag, result] = useMutation<
    Mutation,
    MutationcreateInstagramUGCManagementHashtagArgs
  >(MUTATION, options);

  return { callCreateInstagramUGCManagementHashtag, ...result };
};

export type CreateInstagramUGCManagementHashtagMutationFunction = Mutation['createInstagramUGCManagementHashtag'];
