import { DropdownOption } from '@/shared/types';
import { Radio } from '../Radio';
import { DropdownMenu } from './DropdownMenu';
import { DropdownDefaultProps } from './types';

export interface DropdownSingleSelectMenuProps<T extends DropdownOption> extends DropdownDefaultProps<T> {
  selectedOption?: T;
  onSelectOption: (option: T) => void;
}

export const DropdownSingleSelectMenu = <T extends DropdownOption>({
  options,
  selectedOption,
  ...restProps
}: DropdownSingleSelectMenuProps<T>) => (
  <DropdownMenu<T>
    {...restProps}
    options={options.map((option) => ({
      ...option,
      label: <Radio checked={selectedOption?.value === option.value} label={option.label as string} />
    }))}
  />
);
