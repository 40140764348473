import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeCmsEstimateChannelsSummary.graphql';
import { Query, QueryyoutubeCmsEstimateChannelsSummaryArgs } from '../../__generated__/globalTypes';

export const useYoutubeCmsEstimateChannelsSummaryQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeCmsEstimateChannelsSummaryArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeCmsEstimateChannelsSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeCmsEstimateChannelsSummaryLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeCmsEstimateChannelsSummaryArgs>
): QueryResult<Query, QueryyoutubeCmsEstimateChannelsSummaryArgs> & {
  getYoutubeCmsEstimateChannelsSummary: LazyQueryExecFunction<Query, QueryyoutubeCmsEstimateChannelsSummaryArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeCmsEstimateChannelsSummary, result] = useLazyQuery<
    Query,
    QueryyoutubeCmsEstimateChannelsSummaryArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeCmsEstimateChannelsSummary, ...result };
};

export const useYoutubeCmsEstimateChannelsSummaryPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeCmsEstimateChannelsSummary = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeCmsEstimateChannelsSummaryArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeCmsEstimateChannelsSummaryArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeCmsEstimateChannelsSummary };
};
