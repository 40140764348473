import { zodValidator } from '@tanstack/zod-adapter';
import { Outlet, redirect } from '@tanstack/react-router';
import { PermissionRoute } from '@/app-components';
import {
  adminStaffTalentAgencyPartnerRoles,
  adminStaffAgencyAdvertiserTalentAgencyPartnerRoles
} from '@/shared/constants';
import {
  AddPackage,
  AllPackageInfluencersSearch,
  DouyinPackageInfluencersSearch,
  FacebookPackageInfluencersSearch,
  FacebookPagePackageInfluencersSearch,
  InstagramPackageInfluencersSearch,
  PackageContentListDetails,
  PackageContentRoot,
  PackageDetails,
  Packages,
  ThreadsPackageInfluencersSearch,
  TiktokPackageInfluencersSearch,
  TwitterPackageInfluencersSearch,
  XhsPackageInfluencersSearch,
  YoutubePackageInfluencersSearch
} from '@/pages/Packages';
import {
  AllInfluencersSearchSchema,
  DouyinInfluencersSearchSchema,
  FacebookInfluencersSearchSchema,
  FacebookPageInfluencersSearchSchema,
  InstagramInfluencersSearchSchema,
  PackageProposalSearchSchema,
  PackagesSearchSchema,
  ThreadsInfluencersSearchSchema,
  TiktokInfluencersSearchSchema,
  TwitterInfluencersSearchSchema,
  XhsInfluencersSearchSchema,
  YoutubeInfluencersSearchSchema
} from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const PackageRouter = {
  '/_private-routes/package': [
    () => <PermissionRoute permissions={adminStaffAgencyAdvertiserTalentAgencyPartnerRoles} />,
    {}
  ],
  '/_private-routes/package/': [
    Packages,
    {
      validateSearch: zodValidator(PackagesSearchSchema),
      loaderDeps: ({ search }) => search,
      loader: ({ context, deps }) => {
        // Only redirect and set countryIds if no filters have been set yet
        if (deps.filter?.countryIds === undefined) {
          return redirect({
            to: '/package',
            replace: true,
            search: {
              filter: {
                countryIds: context.auth?.countryId ? [context.auth.countryId] : []
              }
            }
          });
        }
      }
    }
  ],
  '/_private-routes/package/add': [
    () => <PermissionRoute permissions={adminStaffTalentAgencyPartnerRoles} element={<AddPackage />} />,
    {}
  ],
  '/_private-routes/package/$id': [() => <Outlet />, {}],
  '/_private-routes/package/$id/': [
    () => <PermissionRoute permissions={adminStaffTalentAgencyPartnerRoles} element={<PackageDetails />} />,
    {}
  ],
  '/_private-routes/package/$id/content': [PackageContentRoot, {}],
  '/_private-routes/package/$id/content/': [() => <Outlet />, {}],
  '/_private-routes/package/$id/content/list': [
    PackageContentListDetails,
    { validateSearch: zodValidator(PackageProposalSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search': [() => <Outlet />, {}],
  '/_private-routes/package/$id/content/search/': [
    AllPackageInfluencersSearch,
    { validateSearch: zodValidator(AllInfluencersSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search/douyin': [
    DouyinPackageInfluencersSearch,
    { validateSearch: zodValidator(DouyinInfluencersSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search/facebook_page': [
    FacebookPagePackageInfluencersSearch,
    { validateSearch: zodValidator(FacebookPageInfluencersSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search/facebook': [
    FacebookPackageInfluencersSearch,
    { validateSearch: zodValidator(FacebookInfluencersSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search/instagram': [
    InstagramPackageInfluencersSearch,
    { validateSearch: zodValidator(InstagramInfluencersSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search/threads': [
    ThreadsPackageInfluencersSearch,
    { validateSearch: zodValidator(ThreadsInfluencersSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search/tiktok': [
    TiktokPackageInfluencersSearch,
    { validateSearch: zodValidator(TiktokInfluencersSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search/twitter': [
    TwitterPackageInfluencersSearch,
    { validateSearch: zodValidator(TwitterInfluencersSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search/xhs': [
    XhsPackageInfluencersSearch,
    { validateSearch: zodValidator(XhsInfluencersSearchSchema) }
  ],
  '/_private-routes/package/$id/content/search/youtube': [
    YoutubePackageInfluencersSearch,
    { validateSearch: zodValidator(YoutubeInfluencersSearchSchema) }
  ]
} as const satisfies PartialRouter;
