import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateTiktokAdvertiserInfo.graphql';
import { Mutation, MutationupdateTiktokAdvertiserInfoArgs } from '../../__generated__/globalTypes';

export const useUpdateTiktokAdvertiserInfoMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateTiktokAdvertiserInfoArgs>
) => {
  const [callUpdateTiktokAdvertiserInfo, result] = useMutation<Mutation, MutationupdateTiktokAdvertiserInfoArgs>(
    MUTATION,
    options
  );

  return { callUpdateTiktokAdvertiserInfo, ...result };
};

export type UpdateTiktokAdvertiserInfoMutationFunction = Mutation['updateTiktokAdvertiserInfo'];
