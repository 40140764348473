import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icon, Popover } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { FormAction } from '@/shared/types';
import { ActionButtons } from '../ActionButtons';
import { SimilarInfluencersPopperFilterSchema } from './schema';
import { Template } from './Template';
import { SimilarInfluencerPopperFilterFormValues } from './types';

export type SimilarInfluencerPopperFilterProps = FormAction<SimilarInfluencerPopperFilterFormValues>;

export const SimilarInfluencerPopperFilter = ({ onSubmit, defaultValues }: SimilarInfluencerPopperFilterProps) => {
  const { t } = useTranslation();
  const methods = useForm<SimilarInfluencerPopperFilterFormValues>({
    defaultValues,
    resolver: zodResolver(SimilarInfluencersPopperFilterSchema)
  });

  return (
    <Popover
      target={({ togglePopover, open }) => (
        <div css={styles.button} onClick={togglePopover}>
          <span>{t('Filter')}</span>
          <Icon
            size={11}
            icon="caret-down"
            color="#3B4145"
            css={{ marginLeft: '10px', ...THEME.animation.flipX(open) }}
          />
        </div>
      )}
      position="bottom-end"
    >
      {({ togglePopover }) => (
        <div>
          <FormProvider {...methods}>
            <Template />
          </FormProvider>
          <ActionButtons
            hasTopBorder
            secondaryButtonProps={{
              onClick: () => {
                togglePopover();
                methods.reset();
              }
            }}
            primaryButtonProps={{
              onClick: () => {
                onSubmit(methods.getValues(), methods);
                togglePopover();
              }
            }}
          />
        </div>
      )}
    </Popover>
  );
};
const styles = {
  button: css({
    padding: '8px',
    display: 'flex',
    cursor: 'pointer',
    marginLeft: 'auto',
    borderRadius: '3px',
    alignItems: 'center',
    width: 'max-content',
    boxSizing: 'border-box',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.white,
    '&:hover': { borderColor: '#c5d0da' }
  })
};

export * from './types';
