import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTranslateOptions } from '@/shared/hooks';
import { getNumberValidFilter } from '@/shared/utils';
import { MarketplaceCampaignAppliedStatus, MarketplaceDetail } from '@/graphql';
import { SelectorField, TextSearchField, BasicFilterWrapper } from '@/shared/molecules';
import { AdvancedFilter } from './AdvancedFilter';
import { MarketplaceApplicantsFilterFormKeys, MarketplaceApplicantsFilterFormValues } from './schemaTypes';

export interface BasicFilterProps {
  onSubmit?: () => void;
  marketplace: MarketplaceDetail;
}

export const BasicFilter = ({ onSubmit, marketplace }: BasicFilterProps) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<MarketplaceApplicantsFilterFormValues>();
  const allAppliedStatusesOptions = useTranslateOptions([
    { value: MarketplaceCampaignAppliedStatus.APPROVED, label: 'Approved' },
    { value: MarketplaceCampaignAppliedStatus.REJECTED, label: 'Rejected' },
    { value: MarketplaceCampaignAppliedStatus.APPLYING, label: 'Applied' }
  ]);

  const values = getValues();
  const basicFilterKeys: MarketplaceApplicantsFilterFormKeys[] = ['keyword', 'appliedStatus'];
  const numOfValidFilter = getNumberValidFilter(values, basicFilterKeys);

  return (
    <BasicFilterWrapper>
      <TextSearchField<MarketplaceApplicantsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.InfluencerFilter')}
      />
      <SelectorField<MarketplaceApplicantsFilterFormKeys>
        onSubmit={onSubmit}
        name="appliedStatus"
        css={{ maxWidth: '198px' }}
        options={allAppliedStatusesOptions}
        placeholder={t<string>('Selector.Status')}
      />
      <AdvancedFilter count={numOfValidFilter} onSubmit={onSubmit} marketplace={marketplace} />
    </BasicFilterWrapper>
  );
};
