import { useTranslation } from 'react-i18next';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { ProfilePostsCount, ProfilePostsFilter, YtTagRankingSearchSchemaType } from '@/shared/organisms';
import {
  useInfluencerYoutubeAnalyticsTagsTopRankingQuery,
  useInfluencerYoutubeAnalyticsTagsTopRankingCountQuery
} from '@/graphql';
import { useInitialInfluencerPostsListWithName } from '../hooks';
import { YoutubeTagsRankingTable } from './YoutubeTagsRankingTable';

export const InfluencerYoutubeTagsRanking = () => {
  const { t } = useTranslation();
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName({
    title: 'Tag Ranking',
    titleWithName: 'appHeader.TagRankingWithAccountName'
  });

  const { page, limit, sort, filter, setFilter } = useParamsSearch<YtTagRankingSearchSchemaType>();

  const skip = !influencerId || !mainSocialAccountId;
  const variables = { ...filter, socialAccountId: mainSocialAccountId };

  const { data, previousData, loading } = useInfluencerYoutubeAnalyticsTagsTopRankingQuery({
    skip,
    variables: getValuableVariables({ ...variables, limit, orderBy: sort, offset: getOffset(page, limit) })
  });
  const {
    data: countData,
    previousData: countPreviousData,
    loading: fetchingCount
  } = useInfluencerYoutubeAnalyticsTagsTopRankingCountQuery({
    skip,
    variables: getValuableVariables(variables)
  });

  const result = loading ? previousData : data;
  const countResult = fetchingCount ? countPreviousData : countData;
  const totalRecords = countResult?.influencerYoutubeAnalyticsTagsTopRankingCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

      <ProfilePostsCount count={totalRecords} countLabel={t('Tag found')} />

      <YoutubeTagsRankingTable
        totalRecords={totalRecords}
        loading={loading || fetchingCount}
        data={result?.influencerYoutubeAnalyticsTagsTopRanking?.tags || []}
      />
    </ListWithPagination>
  );
};
