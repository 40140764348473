import { useTikTokAdvertiserSummaryData } from '@/shared/organisms';
import { TikTokAdCampaignReport, TikTokAdsReportRoot } from '../../../pages/TikTokAdvertiserPages';

export const AdCampaignReportRoot = () => {
  const { summaryData, loading } = useTikTokAdvertiserSummaryData();

  return <TikTokAdsReportRoot summaryData={summaryData} loading={loading} />;
};

export const AdCampaignReport = () => {
  const { summaryData, loading } = useTikTokAdvertiserSummaryData();

  return <TikTokAdCampaignReport summaryData={summaryData} loading={loading} />;
};
