import { css } from '@emotion/react';
import { NoDataContentWrapper } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { ImageSlider } from '@/shared/molecules';
import { bigIntFormatter } from '@/shared/utils';

interface TagType {
  tag?: string | null;
  views?: number | null;
}

export interface AnalyticsCompareTagsRankingSliderProps<T extends TagType> {
  data?: readonly T[];
  className?: string;
}

export const AnalyticsCompareTagsRankingSlider = <T extends TagType>({
  className,
  data = []
}: AnalyticsCompareTagsRankingSliderProps<T>) => {
  const hasNoData = !data.length;

  return (
    <div css={{ minHeight: '90px', position: 'relative', borderTop: THEME.border.base }} className={className}>
      <NoDataContentWrapper
        dataNotAvailable={hasNoData}
        css={[
          {
            width: '100%',
            height: 'unset',
            backgroundColor: 'transparent',
            minHeight: hasNoData ? 'inherit' : 'calc(100% - 32px)'
          },
          // We need to use `absolute` here, hard to control table width with element has dynamic width as ImageSlider
          !hasNoData ? { top: 0, left: 0, position: 'absolute' } : { zoom: 0.5 }
        ]}
      >
        {data ? (
          <ImageSlider total={data.length} css={{ padding: '16px' }}>
            {data.map((item, index) => (
              <div css={styles.tag} key={index}>
                #{item.tag}
                <div css={styles.count} title={String(item.views ?? '')}>
                  {bigIntFormatter(item.views, 0)}
                </div>
              </div>
            ))}
          </ImageSlider>
        ) : null}
      </NoDataContentWrapper>
    </div>
  );
};
const styles = {
  tag: css({
    height: '60px',
    display: 'flex',
    fontSize: '13px',
    width: 'max-content',
    position: 'relative',
    alignItems: 'center'
  }),
  count: css({
    height: '32px',
    display: 'flex',
    padding: '0 8px',
    marginLeft: '11px',
    lineHeight: '32px',
    backgroundColor: '#6e7c89',
    borderRadius: '0 3px 3px 0',
    color: THEME.text.colors.white
  })
};
