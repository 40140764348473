import { Trans } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { Modal, ModalProps, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { namedOperations, MarketplaceInfluencer, useUnblockInfluencerMarketplaceMutation } from '@/graphql';
import { useMarketplaceDetailsContext } from '../../../MarketplaceDetailsContext';

export interface ConfirmMarkInfluencersAsAvailableModalProps extends Omit<ModalProps, 'children'> {
  resetSelectedInfluencerIds?: () => void;
  selectedInfluencerIds: Array<MarketplaceInfluencer['id']>;
}

export const ConfirmMarkInfluencersAsAvailableModal = ({
  open,
  onClose,
  selectedInfluencerIds,
  resetSelectedInfluencerIds,
  ...restProps
}: ConfirmMarkInfluencersAsAvailableModalProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { marketplaceId } = useMarketplaceDetailsContext();
  const { callUnblockInfluencerMarketplace, loading } = useUnblockInfluencerMarketplaceMutation({
    refetchQueries: [namedOperations.Query.InfluencerWhitelist, namedOperations.Query.AllInfluencersForMarketplaceV2]
  });

  const handleMarkAsAvailable = async () => {
    try {
      await callUnblockInfluencerMarketplace({
        variables: { input: { campaignId: marketplaceId, influencerIds: selectedInfluencerIds } }
      });
      resetSelectedInfluencerIds?.();
    } catch (error) {
      enqueueSnackbar(t('failedUpdateWhitelist', { name: 'Update Whitelist Failed' }), { variant: 'error' });
    } finally {
      onClose?.();
    }
  };

  return (
    <Modal open={open} onClose={onClose} css={{ width: THEME.modal.size.lv1 }} {...restProps}>
      <ModalContent>
        <ModalTitle>{t('Modal.Mark as Available')}</ModalTitle>

        <p css={{ marginTop: '24px', fontSize: '14px', lineHeight: '140%' }}>
          <Trans i18nKey="Modal.MarkAsAvailableDescription" components={{ br: <br /> }} />
        </p>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{
          loading,
          onClick: handleMarkAsAvailable,
          title: t('Button.Mark as Available')
        }}
      />
    </Modal>
  );
};
