import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { ColorVariant } from '@/shared/types';
import { HelpIcon } from '../Icon';

export interface StatusProps {
  label: ReactNode;
  help?: ReactNode;
  className?: string;
  variant: keyof typeof ColorVariant;
}

export const Status = ({ variant, help, className, label = '-' }: StatusProps) =>
  label ? (
    <div css={[styles.wrapper, statusStyles[variant]]} className={className}>
      {label}
      {help && <HelpIcon position="top" help={help} css={{ padding: 0 }} />}
    </div>
  ) : null;

const statusStyles = {
  [ColorVariant.DARK_RED]: { color: THEME.text.colors.white, backgroundColor: 'rgb(255, 43, 82)' },
  [ColorVariant.DARK_BLUE]: { color: THEME.text.colors.white, backgroundColor: 'rgb(56, 146, 229)' },
  [ColorVariant.DARK_GREY]: { color: THEME.text.colors.white, backgroundColor: 'rgb(110, 124, 137)' },
  [ColorVariant.DARK_GREEN]: { color: THEME.text.colors.white, backgroundColor: 'rgb(64, 184, 124)' },
  [ColorVariant.DARK_YELLOW]: { color: THEME.text.colors.white, backgroundColor: 'rgb(255, 182, 61)' },
  [ColorVariant.DARK_PURPLE]: { color: THEME.text.colors.white, backgroundColor: 'rgb(150, 106, 204)' },
  [ColorVariant.DARK_ORANGE]: { color: THEME.text.colors.white, backgroundColor: '#ff6e30' },
  [ColorVariant.LIGHT_RED]: { color: THEME.text.colors.black.lv1, backgroundColor: '#ffcfcf' },
  [ColorVariant.LIGHT_BLUE]: { color: THEME.text.colors.black.lv1, backgroundColor: '#c3def7' },
  [ColorVariant.LIGHT_GREY]: { color: THEME.text.colors.black.lv1, backgroundColor: '#d4d8dc' },
  [ColorVariant.LIGHT_CYAN]: { color: THEME.text.colors.black.lv1, backgroundColor: '#c7f1f4' },
  [ColorVariant.LIGHT_GREEN]: { color: THEME.text.colors.black.lv1, backgroundColor: '#c6ead8' },
  [ColorVariant.LIGHT_PURPLE]: { color: THEME.text.colors.black.lv1, backgroundColor: '#ebc6f8' },
  [ColorVariant.LIGHT_YELLOW]: { color: THEME.text.colors.black.lv1, backgroundColor: '#ffe9c5' }
};
const styles = {
  wrapper: css({
    gap: '4px',
    flex: 'none',
    height: '26px',
    display: 'flex',
    fontWeight: 400,
    padding: '0 8px',
    fontSize: '12px',
    position: 'relative',
    width: 'max-content',
    alignItems: 'center',
    borderRadius: '35px',
    wordBreak: 'keep-all',
    boxSizing: 'border-box',
    justifyContent: 'center'
  })
};
