import { z } from 'zod';
import { SocialAccountStatus } from '@/graphql';
import { AllTranslationKeys, objectKeys } from '@/shared/assets';

export const ConnectedAccountSchema = z.object({
  username: z.string(),
  socialAccountId: z.number(),
  isAdding: z.boolean().optional(),
  status: z.nativeEnum(SocialAccountStatus)
});

export const SocialAccountsSchema = z
  .object({
    id: z.number().optional(),
    facebookAccount: ConnectedAccountSchema,
    facebookPages: z.array(ConnectedAccountSchema).readonly(),
    tiktokAccounts: z.array(ConnectedAccountSchema).readonly(),
    twitterAccounts: z.array(ConnectedAccountSchema).readonly(),
    youtubeAccounts: z.array(ConnectedAccountSchema).readonly(),
    threadsAccounts: z.array(ConnectedAccountSchema).readonly(),
    instagramAccounts: z.array(ConnectedAccountSchema).readonly(),
    douyinAccounts: z.array(ConnectedAccountSchema).readonly(), // we don't validate, only for edit mode and always disabled https://adasiaholdings.atlassian.net/browse/AT-5616
    xhsAccounts: z.array(ConnectedAccountSchema).readonly() // we don't validate, only for edit mode and always disabled https://adasiaholdings.atlassian.net/wiki/spaces/NC/pages/3845881901/Xiaohongshu+Influencer+Profile#Edit-Profile-area
  })
  .superRefine(({ id, facebookAccount, douyinAccounts, ...arrayShapeAccounts }, ctx) => {
    const arrayOfArrayShapeKeys = objectKeys(arrayShapeAccounts);
    if (
      !id && // only do this for Add account flow
      !facebookAccount.username &&
      arrayOfArrayShapeKeys.every((key) => arrayShapeAccounts[key].every((acc) => !acc.username))
    ) {
      ctx.addIssue({
        message: AllTranslationKeys.Error.requiredAtLeastOneOfSocialMediasMessage,
        code: z.ZodIssueCode.custom,
        path: ['facebookAccount', 'username']
      });
      arrayOfArrayShapeKeys.forEach((errorPath) => {
        ctx.addIssue({
          message: AllTranslationKeys.Error.requiredAtLeastOneOfSocialMediasMessage,
          code: z.ZodIssueCode.custom,
          path: [errorPath, '[0]', 'username']
        });
      });
    }
  });
