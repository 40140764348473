type CallbackType<T> = (props: T) => void;
interface DoubleClickProps<ST, DT> {
  delay?: number;
  longDelay?: number;
  singleClickCb?: CallbackType<ST>;
  doubleClickCb?: CallbackType<DT>;
}
export const useDoubleClick = <ST, DT>({
  delay = 200,
  longDelay = 400,
  singleClickCb,
  doubleClickCb
}: DoubleClickProps<ST, DT>) => {
  let timer: NodeJS.Timeout;
  let prevent = false;

  const onSingleClick: DoubleClickProps<ST, DT>['singleClickCb'] = (props) => {
    timer = setTimeout(() => {
      if (!prevent) {
        singleClickCb?.(props);
      }
    }, delay);
  };
  const onDoubleClick: DoubleClickProps<ST, DT>['doubleClickCb'] = (props) => {
    clearTimeout(timer);
    prevent = true;
    doubleClickCb?.(props);

    setTimeout(() => {
      prevent = false;
    }, longDelay);
  };

  return { onSingleClick, onDoubleClick };
};
