import { css } from '@emotion/react';
import { useTikTokSpecialPermissions, useUserRoles } from '@/auth';
import {
  WarningReason,
  useTiktokSpecialPostQuery,
  useTiktokSpecialPostHistoryQuery,
  TikTokSpecialCampaignPostStatus
} from '@/graphql';
import { Button, FormStyle, RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { useRejectTikTokCampaignPost } from '../hooks';
import { useUpdateTikTokPostsStatus } from '../TikTokCampaignPostsList/hooks';
import { HistorySection } from './HistorySection';
import { PostInfoSection } from './PostInfoSection';
import { RequestModificationMessage } from './RequestModification';
import { SparkAdsSettings } from './SparkAdsSettings';

export interface DefaultTemplateProps {
  postId: number;
  onClose: () => void;
  campaignFinished: boolean;
  campaignId: number;
}

export const DefaultTemplate = ({ postId, onClose, campaignFinished, campaignId }: DefaultTemplateProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { isAdvertiserFromTikTok, isAdminStaff } = useUserRoles();
  const { handleRejectPost, RejectMarketplacePostModal } = useRejectTikTokCampaignPost();

  const { hideApprovePostBtn, hideRejectInappropriatePostBtn } = useTikTokSpecialPermissions();
  const { updating, handleSelectStatus, UpdateTikTokSpecialPostStatusModal, callUpdateTiktokSpecialPostStatus } =
    useUpdateTikTokPostsStatus(campaignId);

  const { data, loading } = useTiktokSpecialPostQuery({
    variables: { pk: postId },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      onClose?.();
    }
  });
  const { data: historyData, loading: fetchingHistory } = useTiktokSpecialPostHistoryQuery({
    variables: { pk: postId },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      onClose?.();
    }
  });

  const tiktokSpecialPost = data?.tiktokSpecialPost;

  if (!tiktokSpecialPost) {
    return null;
  }

  const { images, status, caption, reason, postUrl, postedDate, warningReason, detailedReason, influencer } =
    tiktokSpecialPost;
  const hasRequestModificationButton =
    !hideRejectInappropriatePostBtn &&
    (isAdminStaff || isAdvertiserFromTikTok) &&
    status &&
    [TikTokSpecialCampaignPostStatus.EFFECTIVE, TikTokSpecialCampaignPostStatus.WARNING_SOLVED].includes(status);
  const hasWarningSolvedButton =
    !hideRejectInappropriatePostBtn &&
    status &&
    status === TikTokSpecialCampaignPostStatus.WARNING &&
    warningReason === WarningReason.INVALID_CONTENT;
  const hasApproveButton = !hideApprovePostBtn && status === TikTokSpecialCampaignPostStatus.EFFECTIVE;

  const handleSetWarningSolvedStatus = async () => {
    try {
      await callUpdateTiktokSpecialPostStatus({
        variables: {
          input: {
            campaignId,
            postIds: [postId],
            status: TikTokSpecialCampaignPostStatus.WARNING_SOLVED
          }
        }
      });

      onClose();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <RenderDataWithFallback loading={loading || fetchingHistory}>
      <div css={{ padding: '24px' }}>
        <PostInfoSection
          caption={caption}
          images={[...(images || [])]}
          influencer={{
            postDate: postedDate,
            postUrl: postUrl || '',
            id: influencer.id,
            name: influencer.name,
            avatar: influencer.avatar,
            followers: influencer.followersCount,
            socialMedia: 'TIKTOK'
          }}
        />

        {detailedReason || reason ? (
          <RequestModificationMessage message={detailedReason || reason} />
        ) : hasRequestModificationButton ? (
          <Button
            variant="white"
            prefixIcon={{ icon: 'send', size: 12 }}
            title={t('Button.Request modification')}
            css={{ marginTop: '16px', width: '100%' }}
            onClick={() => handleRejectPost(tiktokSpecialPost)}
          />
        ) : null}
        <hr css={{ height: '1px', background: THEME.border.colors.gray.lv1, margin: '16px 0' }} />
        <SparkAdsSettings
          postSystemId={tiktokSpecialPost.id}
          boostedInfo={tiktokSpecialPost.boostedInfo}
          draftBoostedInfo={tiktokSpecialPost.draftBoostedInfo}
          campaignFinished={campaignFinished}
        />
      </div>
      <HistorySection data={historyData?.tiktokSpecialPostHistory} />

      {hasApproveButton ? (
        <FormStyle.ButtonSubmitWrapper css={styles.buttonSubmitWrapper}>
          <p css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3, maxWidth: '204px' }}>
            {t('You can send feedback to creator via Email and AnyCreator')}
          </p>
          <Button
            variant="blue"
            loading={updating}
            css={styles.button}
            title={t('Button.Approve Post')}
            onClick={() => (status ? handleSelectStatus(status, [tiktokSpecialPost]) : undefined)}
          />
        </FormStyle.ButtonSubmitWrapper>
      ) : hasWarningSolvedButton ? (
        <FormStyle.ButtonSubmitWrapper css={styles.buttonSubmitWrapper}>
          <Button
            variant="blue"
            css={styles.button}
            loading={updating}
            title={t('Button.Make effective')}
            onClick={handleSetWarningSolvedStatus}
          />
        </FormStyle.ButtonSubmitWrapper>
      ) : null}

      <RejectMarketplacePostModal />
      <UpdateTikTokSpecialPostStatusModal />
    </RenderDataWithFallback>
  );
};

const styles = {
  buttonSubmitWrapper: css({
    zIndex: 5,
    bottom: 0,
    position: 'sticky',
    backgroundColor: THEME.background.colors.white
  }),
  button: css({
    minWidth: 'unset',
    padding: '0 32px',
    marginLeft: 'auto'
  })
};
