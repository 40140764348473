import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { AnalyticsSubscriptionPlanType } from '@/graphql';

export const AnalyticsFilterSchema = z.object({
  keyword: FilterValidations.string(),
  countryId: FilterValidations.string(),
  advertiserId: FilterValidations.string(),
  subscriptionPlan: FilterValidations.enum(AnalyticsSubscriptionPlanType)
});
export const AnalyticsSearchSchema = z.object({
  filter: AnalyticsFilterSchema.default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsSearchSchemaType = z.infer<typeof AnalyticsSearchSchema>;
export type AnalyticsFilterFormValues = z.infer<typeof AnalyticsFilterSchema>;
export type AnalyticsFilterFormKeys = UnionOfKeys<AnalyticsFilterFormValues>;
