import { css } from '@emotion/react';
import { APP_NAME } from '@/shared/constants';
import { Anchor, AnchorProps, Loading } from '@/shared/atoms';
import { anyTagBlackImage, anyTagWhileImage } from '@/shared/assets';
import { useCustomLogo } from './useCustomLogo';

export interface LogoProps extends AnchorProps {
  type?: 'white' | 'black';
}

export const Logo = ({ type = 'white', ...restProps }: LogoProps) => {
  const { customLogo, loading } = useCustomLogo();

  const defaultLogo = (() => {
    switch (type) {
      case 'black':
        return anyTagBlackImage;
      default:
        return anyTagWhileImage;
    }
  })();

  return (
    <Anchor css={styles.wrapper} {...restProps}>
      {loading ? (
        <div css={{ height: 'inherit', aspectRatio: '1/1' }}>
          <Loading thickness={0.9} css={styles.loading} />
        </div>
      ) : (
        <img
          css={{ width: 'inherit', height: 'inherit' }}
          src={customLogo.url || defaultLogo}
          alt={customLogo.alt || `${APP_NAME} Influencer Marketing Platform`}
        />
      )}
    </Anchor>
  );
};

const styles = {
  wrapper: css({
    flex: 'none',
    height: '29px',
    display: 'block',
    width: 'max-content'
  }),
  loading: css({
    width: '100%',
    height: '100%',
    '& > span': { width: '100%', height: '100%' }
  })
};
