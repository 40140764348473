import { useState } from 'react';
import { PackageProposalSocialAcccountBudgetInput } from '@/graphql';

interface SocialAccountsStateHandlerProps {
  packageSocialAccounts: PackageProposalSocialAcccountBudgetInput[];
  packageSocialAccountIds: number[];
}
export const useSocialAccountsForPackage = ({
  packageSocialAccounts,
  packageSocialAccountIds
}: SocialAccountsStateHandlerProps) => {
  const [selectedSocialAccountIds, setSelectedSocialAccountIds] = useState<number[]>([]);

  const selectedSocialAccounts = packageSocialAccounts.filter((acc) =>
    selectedSocialAccountIds.includes(acc.socialAccountId)
  );

  const handleToggleSelectAllAccounts = () => {
    setSelectedSocialAccountIds(
      selectedSocialAccountIds.length === packageSocialAccounts.length ? [] : packageSocialAccountIds
    );
  };

  const handleSelectSingleAccount = (id: number) => {
    const newIds = [...selectedSocialAccountIds];

    if (newIds.includes(id)) {
      const index = newIds.findIndex((newId) => newId === id);
      newIds.splice(index, 1);
    } else {
      newIds.push(id);
    }

    setSelectedSocialAccountIds(newIds);
  };

  return {
    selectedSocialAccounts,
    selectedSocialAccountIds,
    setSelectedSocialAccountIds,
    handleToggleSelectAllAccounts,
    handleSelectSingleAccount
  };
};
