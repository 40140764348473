import { useState } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { CarouselModal } from '@/shared/molecules';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';
import { PostInsightForm, PostInsightStatus } from '@/shared/organisms';
import { Anchor, Icon, RenderDataWithFallback, SocialIcon, SocialIconProps } from '@/shared/atoms';
import {
  CampaignSocialMediaType,
  EngagementPostForInsightCheck,
  useEngagementPostMetricsForInsightCheckQuery
} from '@/graphql';
import { usePostsInsightFormProps } from './hooks';

interface PostDetailsProps {
  postIndex: number;
  totalPosts: number;
  campaignId: number;
  post: EngagementPostForInsightCheck;
  onMoveToPost?: (index: number) => void;
}

export const PostDetails = ({
  postIndex,
  totalPosts,
  campaignId,
  onMoveToPost,
  post: { id: postId, influencerName }
}: PostDetailsProps) => {
  const [imageIndex, setImageIndex] = useState<number>(0);
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { status: isOpening, toggleStatus: toggleModal } = useToggleState();
  const { data, loading } = useEngagementPostMetricsForInsightCheckQuery({
    variables: { postId },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/posts-insight/$id', params: { id: campaignId.toString() } });
    }
  });

  const postData = data?.engagementPostMetricsForInsightCheck || null;
  const socialMedia = postData?.socialMedia as CampaignSocialMediaType;
  const postInsightUrls = postData?.insightUrls || [];
  const postInsightUrlsLength = postInsightUrls.length;
  const postInsightFormProps = usePostsInsightFormProps({
    postId,
    postData,
    currentImageUrl: postInsightUrls.at(imageIndex) || ''
  });

  const handleClickNextImage = () => {
    setImageIndex(imageIndex + 1);
  };

  const handleClickPrevImage = () => {
    setImageIndex(imageIndex - 1);
  };

  return (
    <RenderDataWithFallback loading={loading}>
      {postData ? (
        <>
          <div css={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
            <div css={styles.header}>
              <SocialIcon size="18px" type={socialMedia as SocialIconProps['type']} css={{ marginRight: '4px' }} />
              <Anchor
                target="_blank"
                label={influencerName}
                css={{ fontSize: '14px' }}
                to="/engagement/$id/posts"
                params={{ id: campaignId.toString() }}
                search={{ popup: String(postId) }}
              />
              <PostInsightStatus
                status={postData.status}
                css={{ fontSize: '12px', padding: '6px 8px', margin: '0 auto 0 8px;' }}
              />
              {onMoveToPost ? (
                <div css={{ display: 'flex', alignItems: 'center' }}>
                  <button
                    css={styles.headerButton}
                    disabled={postIndex === 1}
                    onClick={() => onMoveToPost(postIndex - 1)}
                  >
                    <Icon size={10} icon="caret-left" />
                  </button>
                  <span css={{ fontSize: '14px', padding: '0 24px' }}>
                    {`${formatIntNumber(postIndex)}/${formatIntNumber(totalPosts)}`}
                  </span>
                  <button
                    css={styles.headerButton}
                    disabled={postIndex === totalPosts}
                    onClick={() => onMoveToPost(postIndex + 1)}
                  >
                    <Icon size={10} icon="caret-right" />
                  </button>
                </div>
              ) : null}
            </div>

            <div css={{ height: '100%', display: 'flex', overflow: 'hidden' }}>
              <div css={styles.section}>
                {postInsightUrlsLength > 1 ? (
                  <>
                    {imageIndex > 0 ? (
                      <button
                        onClick={handleClickPrevImage}
                        css={[styles.headerButton, styles.imageButton, { left: '24px' }]}
                      >
                        <Icon size={10} icon="caret-left" />
                      </button>
                    ) : null}

                    {imageIndex !== postInsightUrlsLength - 1 ? (
                      <button
                        css={[styles.headerButton, styles.imageButton, { right: '24px' }]}
                        onClick={handleClickNextImage}
                      >
                        <Icon size={10} icon="caret-right" />
                      </button>
                    ) : null}
                  </>
                ) : null}
                <img
                  css={styles.image}
                  onClick={toggleModal}
                  alt={t<string>('Posts Insight')}
                  src={postInsightUrls[imageIndex] || ''}
                />
              </div>
              <div css={styles.section}>
                <PostInsightForm {...postInsightFormProps} />
              </div>
            </div>
          </div>

          <CarouselModal
            open={isOpening}
            onClose={toggleModal}
            onClickNext={handleClickNextImage}
            onClickPrev={handleClickPrevImage}
            siblingPostId={{
              prevId: imageIndex - 1 >= 0 ? String(imageIndex - 1) : null,
              nextId: imageIndex + 1 < postInsightUrlsLength ? String(imageIndex + 1) : null
            }}
          >
            <div css={styles.imageModal}>
              <img
                alt={t<string>('Posts Insight')}
                src={postInsightUrls[imageIndex] || ''}
                css={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>
          </CarouselModal>
        </>
      ) : null}
    </RenderDataWithFallback>
  );
};
const styles = {
  header: css({
    flex: 'none',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px 0 20px',
    borderBottom: THEME.border.base
  }),
  headerButton: css({
    padding: 0,
    border: 'none',
    background: 'transparent',
    '&:disabled': { opacity: 0.5, cursor: 'not-allowed' },
    '&:not(:disabled):hover': { opacity: 0.5, cursor: 'pointer' }
  }),
  section: css({
    flex: '50% 0 0',
    overflow: 'auto',
    position: 'relative',
    '& + &': { borderLeft: THEME.border.base }
  }),
  image: css({
    top: 0,
    left: '50%',
    cursor: 'pointer',
    maxHeight: '100%',
    position: 'absolute',
    transform: 'translateX(-50%)'
  }),
  imageModal: css({
    height: '100vh',
    display: 'flex',
    maxWidth: '80vw',
    maxHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  imageButton: css({ zIndex: 1, top: '24px', position: 'absolute' })
};
