import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { getYesterday } from '@/shared/utils';
import { SearchFilterDefaults } from '@/shared/validations';

export enum TrendingPostsTabType {
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE'
}
const defaultValues = {
  date: getYesterday(),
  countryId: 'JP',
  categoryId: '24',
  tab: TrendingPostsTabType.YOUTUBE
};
export const TrendingPostsFilterSchema = z.object({
  date: z.string().default(defaultValues.date),
  countryId: z.string().default(defaultValues.countryId),
  categoryId: z.string().default(defaultValues.categoryId),
  tab: z.nativeEnum(TrendingPostsTabType).default(defaultValues.tab)
});
export const TrendingPostsFilterSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(TrendingPostsFilterSchema, defaultValues),
  ...SearchFilterDefaults.pageLimit()
});

export type TrendingPostsFilterSearchSchemaType = z.infer<typeof TrendingPostsFilterSearchSchema>;
export type TrendingPostsFilterFormValues = z.infer<typeof TrendingPostsFilterSchema>;
export type TrendingPostsFilterFormKeys = UnionOfKeys<TrendingPostsFilterFormValues>;
