import {
  Genders,
  SocialAccountType,
  SocialAccountStatus,
  QuerySocialAccountType,
  CreateTalentInfluencerInput,
  SelectedOauthPageAndIgAccount,
  TalentInfluencerDetailsForEditPayload
} from '@/graphql';
import { TalentInfluencerFormValues } from './schemaTypes';

export const getTalentInfluencerFormValues = (
  influencerId?: number,
  infl?: TalentInfluencerDetailsForEditPayload // TODO: why BE not return id for details ???
): TalentInfluencerFormValues => ({
  id: influencerId, // Because BE doesn't return id like others so we have to get it from url
  name: infl?.name || '',
  email: infl?.email || '',
  introduce: infl?.introduce || '',
  countryId: infl?.country.id || '',
  phoneNumber: infl?.phoneNumber || '',
  dateOfBirth: infl?.dateOfBirth || '',
  internalMemo: infl?.internalMemo || '',
  regionId: String(infl?.region?.id || ''),
  isProAccount: infl?.isProAccount ?? false,
  categoryIds: infl?.category.map(({ id }) => String(id)) || [],
  defaultEngagementSelectionReason: infl?.defaultEngagementSelectionReason || '',
  gender: infl?.gender ? (infl.gender === Genders.UNKNOWN ? Genders.NOT_APPLICABLE : infl.gender) : '',
  rateCards: { tiktok: '', youtube: '', twitter: '', threads: '', facebook: '', instagram: '', instagramStory: '' },
  // Accounts
  connectedFacebook: infl?.connectedFacebook,
  connectedYouTubes: infl?.connectedYouTubes || [],
  connectedTwitters: infl?.connectedTwitters || [],
  connectedInstagrams: infl?.connectedInstagrams || [],
  connectedFacebookPages: infl?.connectedFacebookPages || []
});
export const defaultTalentInfluencerFormValues = getTalentInfluencerFormValues();

export const getCreateUpdateTalentInfluencerInput = ({
  name,
  email,
  gender,
  regionId,
  countryId,
  introduce,
  rateCards,
  dateOfBirth,
  phoneNumber,
  categoryIds,
  internalMemo,
  connectedTwitters,
  connectedYouTubes,
  connectedFacebook,
  connectedInstagrams,
  connectedFacebookPages,
  defaultEngagementSelectionReason
}: TalentInfluencerFormValues): CreateTalentInfluencerInput => ({
  name,
  tags: [], // We don't have it in form but api required
  introduce,
  countryId,
  dateOfBirth,
  internalMemo,
  email: email || '',
  gender: gender as Genders,
  phoneNumber: phoneNumber || '',
  defaultEngagementSelectionReason,
  regionId: Number(regionId || ''),
  categoryIds: categoryIds.map(Number),
  rateCards: {
    tiktok: rateCards.tiktok ? { price: Number(rateCards.tiktok) } : null,
    twitter: rateCards.twitter ? { price: Number(rateCards.twitter) } : null,
    youtube: rateCards.youtube ? { price: Number(rateCards.youtube) } : null,
    // threads: rateCards.threads ? { price: Number(rateCards.threads) } : null, // API not support
    facebook: rateCards.facebook ? { price: Number(rateCards.facebook) } : null,
    instagram: rateCards.instagram ? { price: Number(rateCards.instagram) } : null,
    instagramStory: rateCards.instagramStory ? { price: Number(rateCards.instagramStory) } : null
  },
  selectedSocialAccounts: {
    fbUserId: connectedFacebook?.fbUserId || null,
    pageIds: connectedFacebookPages.reduce<string[]>((rs, cr) => (cr?.pageId ? [...rs, cr.pageId] : rs), []),
    twitterUserIds: connectedTwitters.reduce<string[]>((rs, cr) => (cr.twUserId ? [...rs, cr.twUserId] : rs), []),
    pagesAndIgAccounts: connectedInstagrams.reduce<SelectedOauthPageAndIgAccount[]>(
      (rs, cr) => (cr.pageId && cr.igUserId ? [...rs, { pageId: cr.pageId, igUserId: cr.igUserId }] : rs),
      []
    ),
    youTubeChannelIds: connectedYouTubes.reduce<string[]>(
      (rs, cr) => (cr.channelId && cr.status === SocialAccountStatus.SIGNED_UP ? [...rs, cr.channelId] : rs),
      []
    )
  }
});

export const switchSocialConnectColor = (socialMedia: QuerySocialAccountType | SocialAccountType): string => {
  switch (socialMedia) {
    case QuerySocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return '#4267b2';
    case QuerySocialAccountType.INSTAGRAM:
      return 'transparent';
    case QuerySocialAccountType.TWITTER:
      return '#38a1f3';
    default:
      return '#c4302b';
  }
};
