import { Navigate } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import { CheckingPlanSubscription } from './CheckingPlanSubscription';

export const AddTikTokCampaign = () => {
  const { isAdvertiserFromTikTok } = useUserRoles();

  if (!isAdvertiserFromTikTok) {
    return <Navigate to="/" />;
  }

  return <CheckingPlanSubscription />;
};
