import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle, SocialIcon, SocialIconProps } from '@/shared/atoms';
import { MultiSelector } from '@/shared/molecules';
import { CampaignPostsDiscoveryFilterFormValues } from '../schemaTypes';
import { OptionWithSocialAccounts } from './useAllInfluencersLoadOptions';
import { useAllInfluencersLoadOptions } from './useAllInfluencersLoadOptions';

export interface MultiPrivateInfluencersSearchSelectorPropsV2 {
  onSubmit?: () => void;
  className?: string;
}

export const InfluencersMultiSearchSelector = ({
  onSubmit,
  className
}: MultiPrivateInfluencersSearchSelectorPropsV2) => {
  const { watch, setValue } = useFormContext<CampaignPostsDiscoveryFilterFormValues>();
  const influencerIdsValue = watch('influencerIds');
  const { t } = useTranslation();

  const { isLoading, setFetchState, allInfluencersIncludedOptions, debouncedLoadAllInfluencersOptions } =
    useAllInfluencersLoadOptions({
      includeIds: influencerIdsValue
    });

  return (
    <FormStyle.FieldWrapper className={className}>
      <MultiSelector<OptionWithSocialAccounts>
        name="influencerIds"
        placeholder={t('General.Influencer')}
        hasSelectAll={false}
        skipFiltering
        onClose={() => {
          onSubmit?.();
          setFetchState('idle');
        }}
        value={influencerIdsValue}
        loading={isLoading}
        options={allInfluencersIncludedOptions}
        noOptionPlaceholder={t('Type to search and display options')}
        onSearchChange={(v) =>
          debouncedLoadAllInfluencersOptions({
            influencerIds: influencerIdsValue,
            inputValue: v
          })
        }
        onChange={(selectedIds) => {
          setValue('influencerIds', selectedIds);
        }}
        onClear={() => {
          setValue('influencerIds', []);
        }}
        menuItemsProps={{
          menuItemChildren: ({ option }) => (
            <>
              {option.socialAccounts.map(({ socialAccountName, socialAccountType }, i) => (
                <div key={i} css={{ display: 'flex', alignItems: 'center', gap: '4px', marginLeft: '16px' }}>
                  <SocialIcon type={socialAccountType as SocialIconProps['type']} />
                  <span>{socialAccountName}</span>
                </div>
              ))}
            </>
          )
        }}
      />
    </FormStyle.FieldWrapper>
  );
};
