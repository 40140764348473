import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useMarketplacePermissions } from '@/auth';
import { SocialAccountStatus, ApplicantInfluencer, MarketplaceCampaignAppliedStatus } from '@/graphql';
import { ColorVariant } from '@/shared/types';
import { Status, StatusProps } from '@/shared/atoms';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { bigIntFormatter, formatIntNumber, formatPercent } from '@/shared/utils';
import { InfluencerAvatar, InfluencerWidgetInformation } from '@/shared/molecules';
import { THEME } from '@/shared/constants';
import {
  StatsSection,
  SocialMediasSection,
  ProfileWidgetTemplate,
  WidgetSelectButtonProps
} from '../../../Influencers';

interface ProfileWidgetProps {
  className?: string;
  profile: ApplicantInfluencer;
  selectButtonProps?: WidgetSelectButtonProps;
}

export const ProfileWidget = ({
  className,
  selectButtonProps,
  profile: {
    id,
    age,
    name,
    email,
    avatar,
    gender,
    status,
    country,
    twitter,
    tiktok,
    youtube,
    facebook,
    instagram,
    appliedStatus,
    followersCount,
    engagement: { averageEngagement, engagementViewsRate, engagementFollowersRate }
  }
}: ProfileWidgetProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const { hideToggleApplicantFromProposalBtn } = useMarketplacePermissions();

  const stats = [
    { label: t('HeaderColumn.Total Followers'), value: bigIntFormatter(followersCount, 0) },
    { label: t('HeaderColumn.Engagement'), value: formatIntNumber(averageEngagement) },
    { label: t('HeaderColumn.Egg Rate by followers'), value: formatPercent(engagementFollowersRate) },
    { label: t('HeaderColumn.Egg Rate by views'), value: formatPercent(engagementViewsRate) }
  ];

  const getStatusProps = (status: MarketplaceCampaignAppliedStatus): StatusProps => {
    switch (status) {
      case MarketplaceCampaignAppliedStatus.APPLYING:
        return { label: t('Option.Applied'), variant: ColorVariant.LIGHT_YELLOW };
      case MarketplaceCampaignAppliedStatus.APPROVED:
        return { label: t('Option.Approved'), variant: ColorVariant.LIGHT_GREEN };
      case MarketplaceCampaignAppliedStatus.REJECTED:
        return { label: t('Option.Rejected'), variant: ColorVariant.LIGHT_RED };
      default:
        return { label: '', variant: ColorVariant.LIGHT_GREY };
    }
  };

  return (
    <ProfileWidgetTemplate
      className={className}
      selectButtonProps={!hideToggleApplicantFromProposalBtn ? selectButtonProps : undefined}
    >
      <InfluencerAvatar size="80px" src={avatar} />

      <InfluencerWidgetInformation
        age={age}
        name={name}
        gender={gender}
        country={country}
        isSignedUp={status === SocialAccountStatus.SIGNED_UP}
        profileUrl={generateProfileLink(id)}
      />

      <div css={styles.statusWrapper}>
        <p css={{ fontSize: '10px', fontWeight: 600, color: THEME.text.colors.gray.lv3 }}>{t('HeaderColumn.Status')}</p>
        <Status css={{ marginTop: '4px' }} {...getStatusProps(appliedStatus)} />
      </div>

      <StatsSection id={id} stats={stats} css={styles.statsSection} />

      <SocialMediasSection
        tiktok={tiktok ? true : undefined}
        twitter={twitter ? true : undefined}
        youtube={youtube ? true : undefined}
        facebook={facebook ? true : undefined}
        instagram={instagram ? true : undefined}
        email={email ? true : undefined}
      />
    </ProfileWidgetTemplate>
  );
};

const styles = {
  statsSection: css({ marginRight: '16px', '.value': { marginTop: '8px' } }),
  statusWrapper: css({
    height: '37px',
    display: 'flex',
    minWidth: '80px',
    padding: '0 24px',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column'
  })
};
