import { useTranslation } from 'react-i18next';
import { MediaPreviewWithMetrics } from '@/shared/atoms';
import { LIMIT_30, THEME } from '@/shared/constants';
import { bigIntFormatter } from '@/shared/utils';
import { usePostDetailsParam, useQueryHelper } from '@/shared/hooks';
import { ImageSlider, InfluencerFacebookAnalyticsPostDetailsModal } from '@/shared/molecules';
import {
  Order,
  FacebookPageAnalyticsPostsPayload,
  useFacebookPageAnalyticsPostsQuery,
  FacebookPageAnalyticsPostsSortOrder
} from '@/graphql';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { AnalyticsFeedPostsWrapper, AnalyticsFeedPostsWrapperProps } from '../../shared';
import { useAnalyticsProfileSearch } from '../../hooks';

export interface AnalyticsFacebookDashboardFeedPostsTemplateProps
  extends Pick<AnalyticsFeedPostsWrapperProps, 'title' | 'loading' | 'moreDetailProps'> {
  data?: readonly FacebookPageAnalyticsPostsPayload[];
}

export const AnalyticsFacebookDashboardFeedPostsTemplate = ({
  data = [],
  ...restProps
}: AnalyticsFacebookDashboardFeedPostsTemplateProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  return (
    <AnalyticsFeedPostsWrapper
      dataNotAvailable={!data.length}
      title={t('HeaderColumn.Feed Posts')}
      help={t('Tooltip.dashboardFeedPosts')}
      {...restProps}
    >
      <ImageSlider total={data.length} css={{ paddingLeft: '16px' }}>
        {data.map(({ id, image, reactions, comments }) => (
          <MediaPreviewWithMetrics
            key={id}
            src={image}
            css={THEME.mediaPreview.default}
            onClick={() => setPostDetailsParam(id)}
            statistics={[
              { label: t('Reaction'), value: bigIntFormatter(reactions, 0, { k: true, m: true }) },
              { label: t('General.Comments'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
            ]}
          />
        ))}
      </ImageSlider>
    </AnalyticsFeedPostsWrapper>
  );
};

export interface AnalyticsFacebookDashboardFeedPostsProps {
  brandAccountName?: string;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsFacebookDashboardFeedPosts = ({
  analyticsAccount,
  brandAccountName
}: AnalyticsFacebookDashboardFeedPostsProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter, params } = useAnalyticsProfileSearch();
  const { data, loading } = useFacebookPageAnalyticsPostsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      offset: 0,
      limit: LIMIT_30,
      endDate: filter.endDate,
      startDate: filter.startDate,
      facebookPageAnalyticsAccountId: analyticsAccount.id,
      orderBy: { field: FacebookPageAnalyticsPostsSortOrder.POST_DATE, order: Order.DESC }
    },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  const posts = data?.facebookPageAnalyticsPosts.posts || [];

  return (
    <>
      <AnalyticsFacebookDashboardFeedPostsTemplate
        data={posts}
        loading={loading}
        moreDetailProps={{
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post',
          params,
          search: {
            filter: {
              brandAccountName,
              endDate: filter.endDate,
              startDate: filter.startDate
            }
          }
        }}
      />

      <InfluencerFacebookAnalyticsPostDetailsModal
        posts={posts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: analyticsAccount.id }))}
        isAnalytics
      />
    </>
  );
};
