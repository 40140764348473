import { PostDetailsType } from '@/shared/types';
import { InfluencerYoutubeAnalyticsPostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface InfluencerYoutubeAnalyticsPostDetailsModalProps {
  paramKey?: string;
  isAnalytics?: boolean;
  posts: PostDetailsType[];
}

export const InfluencerYoutubeAnalyticsPostDetailsModal = ({
  posts,
  paramKey,
  isAnalytics
}: InfluencerYoutubeAnalyticsPostDetailsModalProps) => (
  <CarouselTemplateModal posts={posts} paramKey={paramKey}>
    {({ selectedPost, closeModal }) => (
      <InfluencerYoutubeAnalyticsPostDetails
        id={selectedPost.id}
        onCloseModal={closeModal}
        isAnalytics={isAnalytics}
        mainSocialAccountId={selectedPost?.mainSocialAccountId}
      />
    )}
  </CarouselTemplateModal>
);
