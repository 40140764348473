import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllAgencies.graphql';
import { Query, QueryallAgenciesArgs } from '../../__generated__/globalTypes';

export const useAllAgenciesQuery = (options?: QueryHookOptions<Query, QueryallAgenciesArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallAgenciesArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllAgenciesLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallAgenciesArgs>
): QueryResult<Query, QueryallAgenciesArgs> & {
  getAllAgencies: LazyQueryExecFunction<Query, QueryallAgenciesArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllAgencies, result] = useLazyQuery<Query, QueryallAgenciesArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllAgencies, ...result };
};

export const useAllAgenciesPromiseQuery = () => {
  const client = useApolloClient();

  const getAllAgencies = useCallback(
    (options?: Omit<QueryOptions<QueryallAgenciesArgs, Query>, 'query'>) =>
      client.query<Query, QueryallAgenciesArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllAgencies };
};
