import { useTranslation } from 'react-i18next';
import { useAuthData } from '@/auth';
import { formatIntNumber } from '@/shared/utils';
import { RangeSliderInputField } from '@/shared/molecules';
import { MIN_MAX_PRICE_OPTIONS, MIN_PRICE, MAX_PRICE } from '@/shared/constants';
import { InfluencersFilterFormKeys } from '../types';

export const PostPriceField = () => {
  const {
    auth: { currencyId }
  } = useAuthData();
  const { t } = useTranslation();

  return (
    <RangeSliderInputField<InfluencersFilterFormKeys>
      step={10000}
      title={t('post price')}
      options={MIN_MAX_PRICE_OPTIONS}
      range={{ min: MIN_PRICE, max: MAX_PRICE }}
      minField={{ name: 'minPostPrice', placeholder: `${formatIntNumber(MIN_PRICE)} ${currencyId}` }}
      maxField={{ name: 'maxPostPrice', placeholder: `${formatIntNumber(MAX_PRICE)}+ ${currencyId}` }}
    />
  );
};
