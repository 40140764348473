import { Table } from '@/shared/atoms';
import { useMarketplaceAutoManagedCampaignFilter } from '@/shared/organisms';
import { useGetAutoManagedCampaignsQuery, useGetAutoManagedCampaignsCountQuery } from '@/graphql';
import { useMarketplaceAutoManagedCampaignTable } from './hooks';
import { formatMarketplaceAutoMangedCampaigns, getMarketplaceAutoManagedCampaignVariables } from './utils';

export const MarketplaceAutoManagedCampaignList = () => {
  const { page, limit, filter } = useMarketplaceAutoManagedCampaignFilter();
  const { input, countInput } = getMarketplaceAutoManagedCampaignVariables({ filter, page, limit });
  const { data, loading } = useGetAutoManagedCampaignsQuery({ variables: { input }, fetchPolicy: 'cache-and-network' });
  const { data: countData, loading: fetchingCount } = useGetAutoManagedCampaignsCountQuery({
    variables: { input: countInput },
    fetchPolicy: 'cache-and-network'
  });
  const { rows, columns } = useMarketplaceAutoManagedCampaignTable({
    listRecords: formatMarketplaceAutoMangedCampaigns(data?.getAutoManagedCampaigns?.campaigns)
  });

  return (
    <Table
      loading={loading || fetchingCount}
      data={{ rows, columns, rowHeight: '112px' }}
      pagination={{ totalRecords: countData?.getAutoManagedCampaignsCount?.count || 0 }}
      noTopBorderRadius
    />
  );
};
