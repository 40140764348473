import { UseFormReturn } from 'react-hook-form';
import { SearchMode, useFetchAutoManagedCustomShopeeCampaignsMutation } from '@/graphql';
import { Button, FormProvider, Modal } from '@/shared/atoms';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { CustomShopeeCampaignForm } from './CustomShopeeCampaignForm';
import { CustomShopeeCampaignsFormValues } from './types';

export const CustomShopeeCampaignsTrigger = ({ className }: { className?: string }) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { status, toggleStatus } = useToggleState();
  let onAfterClose;

  const { callFetchAutoManagedCustomShopeeCampaigns, loading } = useFetchAutoManagedCustomShopeeCampaignsMutation();

  const onSubmit = async (
    { countryId, keyword, searchMode, itemId }: CustomShopeeCampaignsFormValues,
    methods: UseFormReturn<CustomShopeeCampaignsFormValues, unknown>
  ) => {
    onAfterClose = methods.reset;

    try {
      await callFetchAutoManagedCustomShopeeCampaigns({
        variables: {
          input: {
            countryId,
            searchMode,
            ...(searchMode === SearchMode.KEYWORD_SEARCH ? { keyword } : { itemId })
          }
        }
      });
      enqueueSnackbar(
        t('Request to update campaigns list has been submitted, please wait around 5 min until they are fetched'),
        { variant: 'success' }
      );
      toggleStatus();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <Button variant="blue" title={t('Button.Pull more campaigns')} onClick={toggleStatus} className={className} />
      <Modal
        open={status}
        css={{ width: '500px' }}
        hasCloseIcon
        shouldCloseOnOverlayClick
        onClose={toggleStatus}
        onAfterClose={onAfterClose}
      >
        <FormProvider<CustomShopeeCampaignsFormValues>
          defaultValues={{ keyword: '', countryId: '' }}
          onSubmit={onSubmit}
        >
          <CustomShopeeCampaignForm loading={loading} toggleStatus={toggleStatus} />
        </FormProvider>
      </Modal>
    </>
  );
};
