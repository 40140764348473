import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { chatImage } from '@/shared/assets';
import {
  NoDataContentWrapper,
  RenderDataWithFallback,
  NoDataContentWrapperProps
} from '@/shared/atoms';
import { SectionWithHeader, SectionWithHeaderProps } from '@/shared/molecules';

import { NoteAggregateLastThirtyDays } from '../../shared';

export interface InfluencerProfilePostsOverviewWrapperProps<T = unknown>
  extends Omit<SectionWithHeaderProps, 'children'>,
    Pick<NoDataContentWrapperProps, 'dataNotAvailable' | 'wrapperText'> {
  data?: T | null;
  loading?: boolean;
  className?: string;
  children?: ReactNode | ((props: { data: T }) => ReactNode);
}

export const InfluencerProfilePostsOverviewWrapper = <T,>({
  help,
  data,
  loading,
  children,
  className,
  wrapperText,
  moreDetailProps,
  dataNotAvailable,
  ...restProps
}: InfluencerProfilePostsOverviewWrapperProps<T>) => {
  const { t } = useTranslation();

  const hasNoData = dataNotAvailable ?? !data;

  return (
    <div css={{ marginTop: '16px' }} className={className}>
      <RenderDataWithFallback loading={loading}>
        <SectionWithHeader
          title={t('Engagement Insights')}
          prefixIcon={chatImage}
          headerChildren={<NoteAggregateLastThirtyDays />}
          moreDetailProps={!hasNoData ? moreDetailProps : undefined}
          {...restProps}
        >
          <NoDataContentWrapper dataNotAvailable={hasNoData} wrapperText={wrapperText}>
            {!data ? null : typeof children === 'function' ? children({ data }) : children}
          </NoDataContentWrapper>
        </SectionWithHeader>
      </RenderDataWithFallback>
    </div>
  );
};
