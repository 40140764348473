import { THEME } from '@/shared/constants';
import { ListEmpty, RenderDataWithFallback } from '@/shared/atoms';
import { convertOrderToORDER, getValuableVariables } from '@/shared/utils';
import { ReadPackageAccount, usePackageInfluencersQuery } from '@/graphql';
import { useInfluencerPackagesContext } from '../InfluencerPackagesContext';
import { ProfileWidget } from './ProfileWidget';

export interface InfluencersListWidgetProps {
  packageAccount: ReadPackageAccount;
}

export const InfluencersListWidget = ({ packageAccount }: InfluencersListWidgetProps) => {
  const { sort, getInfluencersListSelectButtonProps } = useInfluencerPackagesContext();
  const { data, loading } = usePackageInfluencersQuery({
    skip: !packageAccount.accounts,
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      packageId: packageAccount.id,
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) }
    })
  });

  const influencers = data?.packageInfluencers || [];

  return (
    <RenderDataWithFallback
      loading={loading}
      hasNoData={!influencers.length}
      loadingProps={{ css: { height: '200px' } }}
      noDataNode={<ListEmpty css={{ minHeight: '200px' }} hideDescription />}
      skipDelayed
    >
      <div css={{ padding: '16px', boxSizing: 'border-box', backgroundColor: THEME.background.colors.gray.lv2 }}>
        {influencers.map((influencer) => (
          <ProfileWidget
            profile={influencer}
            key={`package-${packageAccount.id}-influencer-${influencer.influencerId}`}
            selectButtonProps={getInfluencersListSelectButtonProps?.(packageAccount, influencer)}
          />
        ))}
      </div>
    </RenderDataWithFallback>
  );
};
