import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateSocialAccountAnalyticsAdvertiserV2.graphql';
import { Mutation, MutationcreateSocialAccountAnalyticsAdvertiserV2Args } from '../../__generated__/globalTypes';

export const useCreateSocialAccountAnalyticsAdvertiserV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationcreateSocialAccountAnalyticsAdvertiserV2Args>
) => {
  const [callCreateSocialAccountAnalyticsAdvertiserV2, result] = useMutation<
    Mutation,
    MutationcreateSocialAccountAnalyticsAdvertiserV2Args
  >(MUTATION, options);

  return { callCreateSocialAccountAnalyticsAdvertiserV2, ...result };
};

export type CreateSocialAccountAnalyticsAdvertiserV2MutationFunction =
  Mutation['createSocialAccountAnalyticsAdvertiserV2'];
