import { ReactNode } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Option } from '@/shared/types';
import { CheckBox, FormStyle } from '@/shared/atoms';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export type CheckBoxesFieldProps<T extends string = string, V = string> = DefaultFieldProps<
  T,
  Array<V>,
  Option<V, ReactNode>
>;

export const CheckBoxesField = <T extends string, V = string>(props: CheckBoxesFieldProps<T, V>) => {
  const {
    fieldRegister,
    fieldWrapperProps,
    formContext: { control },
    fieldProps: { name, disabled, value, onChange, options = [] }
  } = useFormatRHFFieldProps(props);
  const { append, remove } = useFieldArray<any>({ name, control });

  const handleCheckBoxChange = (option: Option<V, ReactNode>, idx: number) => (checked?: boolean) => {
    if (onChange) {
      const newValues = [...value];

      if (checked) {
        newValues.push(option.value);
      } else {
        newValues.splice(idx, 1);
      }

      onChange(name, newValues, options);

      return;
    }

    if (checked) {
      append(option.value);
    } else {
      remove(idx);
    }
  };

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <FormStyle.FocusInput ref={fieldRegister.ref} />
      <div css={{ display: 'flex', gap: '24px' }}>
        {options.map((option, index) => {
          const idx = value.indexOf(option.value);

          return (
            <CheckBox
              key={index}
              checked={idx !== -1}
              label={option?.label}
              disabled={option?.disabled || disabled}
              onClick={handleCheckBoxChange(option, idx)}
            />
          );
        })}
      </div>
    </RHFFieldWrapper>
  );
};
