import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AnalyticsSubscriptionPlanByAdvId.graphql';
import { Query, QueryanalyticsSubscriptionPlanByAdvIdArgs } from '../../__generated__/globalTypes';

export const useAnalyticsSubscriptionPlanByAdvIdQuery = (
  options?: QueryHookOptions<Query, QueryanalyticsSubscriptionPlanByAdvIdArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryanalyticsSubscriptionPlanByAdvIdArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAnalyticsSubscriptionPlanByAdvIdLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryanalyticsSubscriptionPlanByAdvIdArgs>
): QueryResult<Query, QueryanalyticsSubscriptionPlanByAdvIdArgs> & {
  getAnalyticsSubscriptionPlanByAdvId: LazyQueryExecFunction<Query, QueryanalyticsSubscriptionPlanByAdvIdArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAnalyticsSubscriptionPlanByAdvId, result] = useLazyQuery<Query, QueryanalyticsSubscriptionPlanByAdvIdArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getAnalyticsSubscriptionPlanByAdvId, ...result };
};

export const useAnalyticsSubscriptionPlanByAdvIdPromiseQuery = () => {
  const client = useApolloClient();

  const getAnalyticsSubscriptionPlanByAdvId = useCallback(
    (options?: Omit<QueryOptions<QueryanalyticsSubscriptionPlanByAdvIdArgs, Query>, 'query'>) =>
      client.query<Query, QueryanalyticsSubscriptionPlanByAdvIdArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAnalyticsSubscriptionPlanByAdvId };
};
