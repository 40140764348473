import { redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { NetworkStatus } from '@apollo/client';
import { PermissionRoute } from '@/app-components';
import {
  Query,
  QuerygetAutoManagedCampaignByIdArgs,
  GET_AUTO_MANAGED_CAMPAIGN_BY_ID_QUERY,
  AffiliateReportShowBy
} from '@/graphql';
import {
  AutoManagedCampaigns,
  AutoManagedCampaignDetails,
  EditAutoManagedCampaign,
  AutoManagedCommissions,
  AutoManagedJoinedInfluencers,
  AutoManagedCampaignReport
} from '@/pages/AutoManagedCampaigns';
import { adminStaffRoles } from '@/shared/constants';
import {
  AutoManagedCampaignsSearchSchema,
  AutoManagedCommissionsSearchSchema,
  AutoManagedJoinedInfluencersSearchSchema,
  AutoManagedReportSearchSchema,
  AutoManagedReportSearchSchemaType
} from '@/shared/organisms';
import { getLastDay, getThirtyDaysAgo } from '@/shared/utils';
import { PartialRouter } from '../../types';

export const AutoManagedRouter = {
  '/_private-routes/auto-managed': [() => <PermissionRoute permissions={adminStaffRoles} />, {}],
  '/_private-routes/auto-managed/': [
    AutoManagedCampaigns,
    { validateSearch: zodValidator(AutoManagedCampaignsSearchSchema) }
  ],
  '/_private-routes/auto-managed/$id': [
    AutoManagedCampaignDetails,
    {
      loader: async ({ context, params }) => {
        if (!context.queryClient) {
          return {
            loading: true,
            networkStatus: NetworkStatus.loading,
            errorMessage: null,
            autoManagedCampaign: null
          };
        }

        const { data, loading, errors, networkStatus } = await context.queryClient.query<
          Query,
          QuerygetAutoManagedCampaignByIdArgs
        >({
          query: GET_AUTO_MANAGED_CAMPAIGN_BY_ID_QUERY,
          variables: {
            input: {
              id: Number(params.id)
            }
          },
          notifyOnNetworkStatusChange: true
        });

        return {
          loading,
          networkStatus,
          errorMessage: errors?.at(0)?.message,
          autoManagedCampaign: data?.getAutoManagedCampaignById || null
        };
      }
    }
  ],
  '/_private-routes/auto-managed/$id/': [EditAutoManagedCampaign, {}],
  '/_private-routes/auto-managed/$id/commissions': [
    AutoManagedCommissions,
    { validateSearch: zodValidator(AutoManagedCommissionsSearchSchema) }
  ],
  '/_private-routes/auto-managed/$id/joined-influencers': [
    AutoManagedJoinedInfluencers,
    { validateSearch: zodValidator(AutoManagedJoinedInfluencersSearchSchema) }
  ],
  '/_private-routes/auto-managed/$id/report': [
    AutoManagedCampaignReport,
    {
      validateSearch: zodValidator(AutoManagedReportSearchSchema),
      loaderDeps: ({ search }) => search as AutoManagedReportSearchSchemaType,
      loader: async ({ context, params, deps }) => {
        if (!context.queryClient) {
          return {
            loading: true,
            networkStatus: NetworkStatus.loading,
            errorMessage: null,
            autoManagedCampaign: null
          };
        }
        const data = context.queryClient.readQuery<Query, QuerygetAutoManagedCampaignByIdArgs>({
          query: GET_AUTO_MANAGED_CAMPAIGN_BY_ID_QUERY,
          variables: {
            input: {
              id: Number(params.id)
            }
          }
        });

        if (deps.filter.startDate === undefined && deps.filter.endDate === undefined) {
          throw redirect({
            replace: true,
            to: '/auto-managed/$id/report',
            params: {
              id: params.id
            },
            search: {
              filter: {
                mode: AffiliateReportShowBy.DATE,
                endDate: data?.getAutoManagedCampaignById?.endDate || getLastDay(),
                startDate: data?.getAutoManagedCampaignById?.startDate || getThirtyDaysAgo()
              }
            }
          });
        }
      }
    }
  ]
} as const satisfies PartialRouter;
