import { ApolloQueryResult } from '@apollo/client';
import { useState } from 'react';
import { QueryallInstagramSimilarInfluencersV3Args, Query } from '@/graphql';
import { MIN_FOLLOWERS, MAX_FOLLOWERS, MIN_AGE, MAX_AGE } from '@/shared/constants';
import { SimilarInfluencerPopperFilterFormValues } from '@/shared/molecules';

type VariableType = Partial<
  Pick<
    QueryallInstagramSimilarInfluencersV3Args,
    'limit' | 'genders' | 'minAge' | 'maxAge' | 'minFollowers' | 'maxFollowers'
  >
>;

interface Props {
  limit: number;
  refetch: (variables?: VariableType) => Promise<ApolloQueryResult<Query>>;
}

export const useInfluencerProfileSimilarInfluencersFilter = ({ limit, refetch }: Props) => {
  const [filter, setFilter] = useState<SimilarInfluencerPopperFilterFormValues>({
    genders: [],
    minFollowers: MIN_FOLLOWERS,
    maxFollowers: MAX_FOLLOWERS,
    minAge: MIN_AGE,
    maxAge: MAX_AGE
  });

  const handleFilterChange = async (formValues: SimilarInfluencerPopperFilterFormValues) => {
    setFilter(formValues);
    await refetch({
      limit,
      genders: formValues.genders,
      minAge: +formValues.minAge <= MIN_AGE ? null : +formValues.minAge,
      maxAge: +formValues.maxAge >= MAX_AGE ? null : +formValues.maxAge,
      minFollowers: +formValues.minFollowers <= MIN_FOLLOWERS ? null : +formValues.minFollowers,
      maxFollowers: +formValues.maxFollowers >= MAX_FOLLOWERS ? null : +formValues.maxFollowers
    });
  };

  return { filter, setFilter, handleFilterChange };
};
