import { getValuableVariables } from '@/shared/utils';
import { useYoutubeAnalyticsOverviewQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { AnalyticsYoutubeDashboardOverviewTemplate } from './AnalyticsYoutubeDashboardOverviewTemplate';

export interface AnalyticsYoutubeDashboardOverviewProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsYoutubeDashboardOverview = ({ analyticsAccount }: AnalyticsYoutubeDashboardOverviewProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useYoutubeAnalyticsOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      youtubeAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsYoutubeDashboardOverviewTemplate
      loading={loading}
      accountId={analyticsAccount.id}
      data={data?.youtubeAnalyticsOverview}
    />
  );
};
