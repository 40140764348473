import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { AnalyticsSubscriptionPlanType } from '@/graphql';
import { THEME } from '@/shared/constants';

export interface AnalyticsFreePlanNoticeProps {
  className?: string;
  subscriptionPlan: AnalyticsSubscriptionPlanType;
}

export const AnalyticsFreePlanNotice = ({ className, subscriptionPlan }: AnalyticsFreePlanNoticeProps) => {
  const { t } = useTranslation();

  return subscriptionPlan === AnalyticsSubscriptionPlanType.FREE ? (
    <div css={styles.wrapper} className={className}>
      {t('upgradeTrialPlanWarning')}
    </div>
  ) : null;
};
const styles = {
  wrapper: css({
    width: '100%',
    height: THEME.container.analytics.freePlanNotice.height,
    display: 'flex',
    fontWeight: 600,
    lineHeight: 1.38,
    fontSize: '13px',
    fontStyle: 'normal',
    alignItems: 'center',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    justifyContent: 'center',
    backgroundColor: '#ff2b52',
    color: THEME.text.colors.white
  })
};
