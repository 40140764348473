import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useTranslateOptions } from '@/shared/hooks';
import { INFLUENCER_STATUS_OPTIONS } from '@/shared/constants';
import { FILTER_SECTIONS, MoreFilterPopover } from '@/shared/molecules';
import {
  AgeField,
  RegionField,
  FollowerField,
  PostPriceField,
  SocialMediaField,
  CampaignJoinedField,
  CampaignDisplayField,
  InfluencerStatusField,
  InfluencerCategoryField,
  TalentAgencyPartnerField,
  EngagementFollowersAndViewsRateField
} from '../../shared';
import { AdvancedFilterDefaultProps } from '../../types';
import { AllInfluencersFilterFormValues } from '../schemaTypes';
import { getInitialAllInfluencersFormValues } from '../utils';

export const AdvancedFilter = ({ count, onSubmit, isEngagementPage }: AdvancedFilterDefaultProps) => {
  const { t } = useTranslation();
  const { reset, getValues } = useFormContext<AllInfluencersFilterFormValues>();

  const values = getValues();
  const emptyValues = getInitialAllInfluencersFormValues();
  const statusOptions = useTranslateOptions(INFLUENCER_STATUS_OPTIONS);

  const handleClear = () => {
    reset({
      ...emptyValues,
      // Basic filter
      keyword: values.keyword,
      genders: values.genders,
      countryIds: values.countryIds
    });
  };

  return (
    <MoreFilterPopover
      count={count}
      onClear={handleClear}
      onSubmit={onSubmit}
      items={[
        {
          value: FILTER_SECTIONS.InfluencerAttributes,
          title: FILTER_SECTIONS.InfluencerAttributes,
          node: (
            <>
              <AgeField hideSlider />
              <InfluencerStatusField multiple options={statusOptions} />
              <SocialMediaField title={t('Selector.Social Medias')} multiple />
              <TalentAgencyPartnerField />
              <InfluencerCategoryField />
              <RegionField
                disabled={!values.countryIds.length}
                countryId={(values.countryIds.length === 1 && values.countryIds.at(0)) || ''}
              />
              {!isEngagementPage ? <CampaignDisplayField /> : null}
            </>
          )
        },
        {
          value: FILTER_SECTIONS.PerformanceFilter,
          title: FILTER_SECTIONS.PerformanceFilter,
          node: (
            <>
              <FollowerField />
              <CampaignJoinedField />
              <EngagementFollowersAndViewsRateField />
              <PostPriceField />
            </>
          )
        }
      ]}
    />
  );
};
