import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '@/shared/types';
import { ColumnCustomiser } from '@/shared/molecules';
import { TikTokAdReportOrderField, TikTokAdReportType } from '@/graphql';
import { AdCampaignReportColumnKeys, AdCampaignReportColumnProps } from '../types';
import { defaultSelectedColumns, initialValuesReport } from '../utils';

export interface AdCampaignReportColumnsProps {
  total?: number;
  adReportType: TikTokAdReportType;
}

export const useAdCampaignReportColumns = ({ total = 0, adReportType }: AdCampaignReportColumnsProps) => {
  const { t } = useTranslation();
  const [selectedColumns, setSelectedColumns] = useState<AdCampaignReportColumnKeys[]>(defaultSelectedColumns);

  const getMetricColumnProps = ({
    key,
    hidden,
    styles,
    ...columnProps
  }: AdCampaignReportColumnProps): AdCampaignReportColumnProps => ({
    ...columnProps,
    key,
    styles: { textAlign: 'right', ...styles },
    hidden: hidden || (key && !selectedColumns.includes(key))
  });

  const { title, trailingKey } =
    adReportType === TikTokAdReportType.AD_CAMPAIGN
      ? { title: 'Campaign', trailingKey: 'campaign' }
      : adReportType === TikTokAdReportType.AD_GROUP
        ? { title: 'Ad Group', trailingKey: 'group' }
        : adReportType === TikTokAdReportType.AD
          ? { title: 'Ad', trailingKey: 'ad' }
          : { title: '', trailingKey: '' };

  const reportMetrics: AdCampaignReportColumnProps[] = [
    {
      key: 'status',
      title: t('HeaderColumn.Status'),
      help: t<string>(`Tooltip.adStatusHelp_${trailingKey}`),
      sortField: TikTokAdReportOrderField.AD_STATUS,
      styles: { textAlign: 'left' }
    },
    {
      key: 'adBudget',
      title: t('HeaderColumn.Budget'),
      help: t<string>(`Tooltip.adBudgetHelp_${trailingKey}`),
      sortField: TikTokAdReportOrderField.AD_BUDGET
    },
    {
      key: 'adCost',
      title: t('HeaderColumn.Total Cost'),
      help: t<string>('Tooltip.adCostHelp'),
      sortField: TikTokAdReportOrderField.AD_COST
    },
    {
      key: 'adImpressions',
      title: t('HeaderColumn.Ad Impressions'),
      help: t<string>('Tooltip.adImpressionsHelp'),
      sortField: TikTokAdReportOrderField.AD_IMPRESSIONS
    },
    {
      key: 'adCpm',
      title: t('HeaderColumn.CPM'),
      help: t<string>('Tooltip.adCpmHelp'),
      sortField: TikTokAdReportOrderField.AD_CPM
    },
    {
      key: 'adReach',
      title: t('HeaderColumn.Ad Reach'),
      help: t<string>('Tooltip.adReachHelp'),
      sortField: TikTokAdReportOrderField.AD_REACH
    },
    {
      key: 'adFrequency',
      title: t('HeaderColumn.Frequency'),
      help: t<string>('Tooltip.adFrequencyHelp'),
      sortField: TikTokAdReportOrderField.AD_FREQUENCY
    },
    {
      key: 'adClicks',
      title: t('HeaderColumn.Clicks (destination)'),
      help: t<string>('Tooltip.adClicksHelp'),
      sortField: TikTokAdReportOrderField.AD_CLICKS
    },
    {
      key: 'adCpc',
      title: t('HeaderColumn.CPC (destination)'),
      help: t<string>('Tooltip.adCpcHelp'),
      sortField: TikTokAdReportOrderField.AD_CPC
    },
    {
      key: 'adCtr',
      title: t('HeaderColumn.CTR (destination)'),
      help: t<string>('Tooltip.adCtrHelp'),
      sortField: TikTokAdReportOrderField.AD_CTR
    },
    {
      key: 'adResult',
      title: t('HeaderColumn.Results'),
      help: t<string>('Tooltip.adResultHelp'),
      sortField: TikTokAdReportOrderField.AD_RESULT
    },
    {
      key: 'adCostPerResult',
      title: t('HeaderColumn.Cost Per Result'),
      help: t<string>('Tooltip.adCostPerResultHelp'),
      sortField: TikTokAdReportOrderField.AD_COST_PER_RESULT
    },
    {
      key: 'adCostPer1000Reached',
      title: t('HeaderColumn.Cost per 1000 people reached'),
      help: t<string>('Tooltip.adCostPer1000ReachedHelp'),
      sortField: TikTokAdReportOrderField.AD_COST_PER_1000_REACHED
    },
    {
      key: 'adResultRate',
      title: t('HeaderColumn.Result Rate'),
      help: t<string>('Tooltip.aadResultRateHelp'),
      sortField: TikTokAdReportOrderField.AD_RESULT_RATE
    },
    {
      key: 'videoPlayActions',
      title: t('HeaderColumn.Video View'),
      help: t<string>('Tooltip.videoPlayActionsHelp'),
      sortField: TikTokAdReportOrderField.VIDEO_PLAY_ACTIONS
    },
    {
      key: 'videoWatched2s',
      title: t('HeaderColumn.2-second video views'),
      help: t<string>('Tooltip.videoWatched2sHelp'),
      sortField: TikTokAdReportOrderField.VIDEO_WATCHED_2S
    },
    {
      key: 'videoWatched6s',
      title: t('HeaderColumn.6-second video views'),
      help: t<string>('Tooltip.videoWatched6sHelp'),
      sortField: TikTokAdReportOrderField.VIDEO_WATCHED_6S
    },
    {
      key: 'videoViewsP25',
      title: t('HeaderColumn.Video views at 25%'),
      help: t<string>('Tooltip.videoViewsP25Help'),
      sortField: TikTokAdReportOrderField.VIDEO_VIEWS_P25
    },
    {
      key: 'videoViewsP50',
      title: t('HeaderColumn.Video views at 50%'),
      help: t<string>('Tooltip.videoViewsP50Help'),
      sortField: TikTokAdReportOrderField.VIDEO_VIEWS_P50
    },
    {
      key: 'videoViewsP75',
      title: t('HeaderColumn.Video views at 75%'),
      help: t<string>('Tooltip.videoViewsP75Help'),
      sortField: TikTokAdReportOrderField.VIDEO_VIEWS_P75
    },
    {
      key: 'videoViewsP100',
      title: t('HeaderColumn.Video views at 100%'),
      help: t<string>('Tooltip.videoViewsP100Help'),
      sortField: TikTokAdReportOrderField.VIDEO_VIEWS_P100
    },
    {
      key: 'averageVideoPlayPerUser',
      title: t('HeaderColumn.Average play time per user'),
      help: t<string>('Tooltip.averageVideoPlayPerUserHelp'),
      sortField: TikTokAdReportOrderField.AVERAGE_VIDEO_PLAY_PER_USER
    },
    {
      key: 'averageVideoPlay',
      title: t('HeaderColumn.Average play time per video view'),
      help: t<string>('Tooltip.averageVideoPlayHelp'),
      sortField: TikTokAdReportOrderField.AVERAGE_VIDEO_PLAY
    },
    {
      key: 'adEngagements',
      title: t('HeaderColumn.Clicks (all)'),
      help: t<string>('Tooltip.adEngagementsHelp'),
      sortField: TikTokAdReportOrderField.AD_ENGAGEMENTS
    },
    {
      key: 'adEr',
      title: t('HeaderColumn.CTR (all)'),
      help: t<string>('Tooltip.adErHelp'),
      sortField: TikTokAdReportOrderField.AD_ER
    },
    {
      key: 'adFollows',
      title: t('HeaderColumn.Paid Follows'),
      help: t<string>('Tooltip.adFollowsHelp'),
      sortField: TikTokAdReportOrderField.AD_FOLLOWS
    },

    {
      key: 'adLikes',
      title: t('HeaderColumn.Paid Likes'),
      help: t<string>('Tooltip.adLikesHelp'),
      sortField: TikTokAdReportOrderField.AD_LIKES
    },
    {
      key: 'adComments',
      title: t('HeaderColumn.Paid Comments'),
      help: t<string>('Tooltip.adCommentsHelp'),
      sortField: TikTokAdReportOrderField.AD_COMMENTS
    },
    {
      key: 'adShares',
      title: t('HeaderColumn.Paid Shares'),
      help: t<string>('Tooltip.adSharesHelp'),
      sortField: TikTokAdReportOrderField.AD_SHARES
    },
    {
      key: 'interactiveAddOnImpressions',
      title: t('HeaderColumn.Interactive add-on impressions'),
      help: t<string>('Tooltip.interactiveAddOnImpressionsHelp'),
      sortField: TikTokAdReportOrderField.INTERACTIVE_ADD_ON_IMPRESSIONS
    },
    {
      key: 'interactiveAddOnDestinationClicks',
      title: t('HeaderColumn.Interactive add-on destination clicks'),
      help: t<string>('Tooltip.interactiveAddOnDestinationClicksHelp'),
      sortField: TikTokAdReportOrderField.INTERACTIVE_ADD_ON_DESTINATION_CLICKS
    }
  ];

  const metricColumns: AdCampaignReportColumnProps[] = reportMetrics.map(getMetricColumnProps);
  const selectableColumnOptions = reportMetrics.reduce<Option<AdCampaignReportColumnKeys>[]>(
    (rs, cr) => (cr.hidden ? rs : [...rs, { label: cr.title as string, value: cr.key as AdCampaignReportColumnKeys }]),
    []
  );

  const campaignTitleColumn: AdCampaignReportColumnProps = {
    title: (
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {t(`HeaderColumn.${title}`)}
        <ColumnCustomiser<AdCampaignReportColumnKeys>
          defaultOptions={selectedColumns}
          initialValues={initialValuesReport}
          savedValuesKey="adReportTableColumns"
          setSelectedColumns={setSelectedColumns}
          options={selectableColumnOptions}
          position={total < 6 ? 'right-end' : 'right-start'}
        />
      </div>
    ),
    sticky: { active: true, hasBorderRight: true }
  };

  return { selectedColumns, columns: [campaignTitleColumn, ...metricColumns] };
};
