import {
  FacebookPageAnalyticsPostPayload,
  FacebookPageAnalyticsPostsByDatePayload
} from '@/graphql';
import { THEME } from '@/shared/constants';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface FacebookPostInDateDetailsProps {
  className?: string;
  data: Pick<FacebookPageAnalyticsPostsByDatePayload, 'avatar' | 'followers' | 'pageName'> &
    FacebookPageAnalyticsPostPayload;
}

export const FacebookPostInDateDetails = ({ data, className }: FacebookPostInDateDetailsProps) => {
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = {
    caption: data.content,
    socialMedia: 'FACEBOOK',
    postUrl: data.postUrl || '',
    images: data.image ? [data.image] : [],
    comments: data.postComments.map((comment) => ({ content: comment.content, username: comment.posterName })) || [],
    info: {
      name: data.pageName,
      avatar: data.avatar,
      socialMedia: 'FACEBOOK',
      postDate: data.postDate,
      followers: data.followers
    },
    statistics: {
      reactions: data.reactions,
      comments: data.comments,
      shares: data.shares,
      reach: data.adImpressions
    }
  };

  return (
    <DefaultPostDetailsTemplate className={className} postDetails={postDetails} css={{ width: THEME.modal.size.lv3 }} />
  );
};
