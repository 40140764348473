import { useParams } from '@tanstack/react-router';
import { useInfluencerProfileV2Query } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useCustomHeader, useQueryHelper } from '@/shared/hooks';
import { Template } from './Template';

export const InfluencerProfile = () => {
  useCustomHeader();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const params = useParams({ strict: false });
  const { data, loading } = useInfluencerProfileV2Query({
    skip: !params.id,
    variables: { pk: Number(params.id) },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/influencers' });
    }
  });

  const influencerProfile = data?.influencerProfileV2;

  return (
    <RenderDataWithFallback loading={loading} hasNoData={!influencerProfile}>
      {influencerProfile ? <Template influencerProfile={influencerProfile} /> : null}
    </RenderDataWithFallback>
  );
};
