import { TextCutter } from '@/shared/atoms';
import { Option, SupportedSocialMediaType } from '@/shared/types';
import { formatPrice } from '@/shared/utils';

interface SocialMediasWithNumberProps {
  className?: string;
  data: Option<string | number | null | undefined, keyof typeof SupportedSocialMediaType>[];
  currency?: string | null;
}
export const SocialMediasWithNumber = ({ data, className, currency }: SocialMediasWithNumberProps) => (
  <div className={className} css={{ display: 'grid', gap: '4px 16px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
    {data
      .filter(({ value }) => !!value)
      .map(({ prefixIcon, value }, index) => (
        <div key={index} css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {prefixIcon}
          <TextCutter
            css={{ fontSize: '14px', fontWeight: 600, marginInlineStart: '2px' }}
            text={typeof value === 'number' ? formatPrice(value, currency) : value}
          />
        </div>
      ))}
  </div>
);
