import { useUserRoles } from '@/auth';
import {
  useUpdateAdvertiserMutation,
  AnalyticsSubscriptionPlanType,
  useSubscribeAnalyticsPlanMutation
} from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import {
  AdvertiserForm,
  AdvertiserFormValues,
  AccountManagerPlanForm,
  AccountManagerPlanFormValues
} from '@/shared/organisms';
import { FormAction } from '@/shared/types';
import { useAdvertiserDetailsContext } from './AdvertiserDetailsContext';

export const EditAdvertiser = () => {
  const { isTalentAgencyPartner } = useUserRoles();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callUpdateAdvertiser } = useUpdateAdvertiserMutation();
  const { advertiser, subscription } = useAdvertiserDetailsContext();
  const { callSubscribeAnalyticsPlan } = useSubscribeAnalyticsPlanMutation();

  const handleSubmit: FormAction<AdvertiserFormValues>['onSubmit'] = async ({
    hubspotId,
    countryId,
    companyUrl,
    phoneNumber,
    advertiserName,
    internalNetsuiteId
  }) => {
    try {
      await callUpdateAdvertiser({
        variables: {
          input: {
            name: advertiserName,
            countryId,
            id: advertiser.id,
            hubspotId: hubspotId || null,
            companyUrl: companyUrl || null,
            phoneNumber: phoneNumber || null,
            internalNetsuiteId: internalNetsuiteId || null
          }
        }
      });
      enqueueSnackbar(t('succeededInUpdating', { name: advertiserName }), { variant: 'success' });
      navigate({ to: '/advertiser' });
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: advertiserName }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  const handleSubmitPlan = async ({ accountManagerPlan }: AccountManagerPlanFormValues) => {
    if (!accountManagerPlan) {
      return;
    }

    try {
      await callSubscribeAnalyticsPlan({
        variables: { input: { advertiserId: advertiser.id, plan: accountManagerPlan as AnalyticsSubscriptionPlanType } }
      });

      enqueueSnackbar(t('Account Manager Plan was successfully set'), { variant: 'success' });
      navigate({ to: '/advertiser' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <AdvertiserForm
        defaultValues={{
          type: 'edit',
          advertiserId: advertiser.id,
          advertiserName: advertiser.name,
          countryId: advertiser.country.id,
          hubspotId: advertiser.hubspotId || '',
          companyUrl: advertiser.companyUrl || '',
          phoneNumber: advertiser.phoneNumber || '',
          subscriptionType: subscription?.type || '',
          internalNetsuiteId: advertiser.internalNetsuiteId || ''
        }}
        onSubmit={handleSubmit}
      />

      {!isTalentAgencyPartner ? (
        <AccountManagerPlanForm
          css={{ marginTop: '16px' }}
          onSubmit={handleSubmitPlan}
          defaultValues={{ advertiserId: advertiser.id, accountManagerPlan: subscription?.type }}
        />
      ) : null}
    </>
  );
};
