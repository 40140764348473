import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations } from '@/shared/validations';
import { LIMIT } from '@/shared/constants';

export const AssociatedAccountFilterSchema = FilterValidations.keyword;
export const AssociatedAccountSearchSchema = z.object({
  filter: AssociatedAccountFilterSchema.default(AssociatedAccountFilterSchema.parse({})),
  page: z.number().default(1),
  limit: z.number().default(LIMIT)
});

export type AssociatedAccountSearchSchemaType = z.infer<typeof AssociatedAccountSearchSchema>;
export type AssociatedAccountFilterFormValues = z.infer<typeof AssociatedAccountFilterSchema>;
export type AssociatedAccountFilterFormKeys = UnionOfKeys<AssociatedAccountFilterFormValues>;
