import { css } from '@emotion/react';
import { NoDataWrapper, NoDataWrapperProps } from './NoDataWrapper';

export type NoDataContentWrapperProps = NoDataWrapperProps;
export const NoDataContentWrapper = ({
  wrapperText = 'The system is collecting data, please wait until the data is ready',
  ...restProps
}: NoDataContentWrapperProps) => <NoDataWrapper css={styles.wrapper} wrapperText={wrapperText} {...restProps} />;

const styles = {
  wrapper: css({
    padding: 0,
    height: '100%',
    border: 'none',
    display: 'flex',
    borderRadius: 0,
    boxShadow: 'none',
    overflow: 'hidden',
    minHeight: '300px',
    flexDirection: 'column',
    backgroundColor: ' #fff'
  })
};
