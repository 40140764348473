import {
  CampaignType,
  EngagementDetail,
  useEngagementCampaignReportGridQuery,
  useEngagementCampaignPostReportAudienceBreakdownQuery
} from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { PostReportEmpty, PostReportGrid } from '../../../PostReport';
import { EngagementReportAudienceBreakdown } from './EngagementReportAudienceBreakdown';

export interface EngagementReportGridProps {
  engagement: EngagementDetail;
}
export const EngagementReportGrid = ({ engagement }: EngagementReportGridProps) => {
  const { id, isTtcmCampaign, trackingOptions } = engagement;

  const { data, loading } = useEngagementCampaignReportGridQuery({ variables: { campaignId: id } });
  const { data: audienceBreakdownData, loading: fetchingAudienceBreakdown } =
    useEngagementCampaignPostReportAudienceBreakdownQuery({ fetchPolicy: 'no-cache', variables: { campaignId: id } });

  const gridData = data?.engagementCampaignReportGrid || undefined;
  const audienceData = audienceBreakdownData?.engagementCampaignPostReportAudienceBreakdown || undefined;

  return (
    <RenderDataWithFallback
      hasNoData={!gridData}
      noDataNode={<PostReportEmpty />}
      loading={loading || fetchingAudienceBreakdown}
    >
      {gridData ? (
        <>
          {audienceData ? <EngagementReportAudienceBreakdown data={audienceData} engagement={engagement} /> : null}

          <PostReportGrid
            data={gridData}
            hideTitle={!audienceData}
            hasTrackingOption={!!trackingOptions}
            campaignType={CampaignType.ENGAGEMENT}
            metricsOrder={
              isTtcmCampaign ? ['engagementRate', 'reachRate', 'viewRate', 'shareRate', 'saveRate', 'sales'] : undefined
            }
          />
        </>
      ) : null}
    </RenderDataWithFallback>
  );
};
