import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { CheckBoxProps } from '@/shared/atoms';
import { SocialAccountType, SocialPostType, Genders, SocialAccountStatus, PostListSortFieldV3 } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
import { PostType, DefaultInfluencersProps, InfluencersFilterSchema } from '../types';

export const InfluencerPostsFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  keyword: true,
  genders: true,
  minViews: true,
  maxViews: true,
  countryId: true,
  includeIds: true,
  socialMedia: true,
  categoryIds: true,
  minPostDate: true,
  maxPostDate: true,
  minFollowers: true,
  maxFollowers: true,
  minEngagementRate: true,
  maxEngagementRate: true
});
export const InfluencersPostsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(InfluencerPostsFilterSchema, {
    tab: '',
    keyword: '',
    genders: [],
    minViews: '',
    maxViews: '',
    countryId: '',
    includeIds: [],
    socialMedia: '',
    categoryIds: [],
    minPostDate: '',
    maxPostDate: '',
    minFollowers: '',
    maxFollowers: '',
    minEngagementRate: '',
    maxEngagementRate: ''
  }),
  ...SearchFilterDefaults.pageLimit(),
  sort: SearchFilterDefaults.sort(PostListSortFieldV3).default({})
});

export type InfluencersPostsSearchSchemaType = z.infer<typeof InfluencersPostsSearchSchema>;
export type InfluencerPostsFilterFormValues = z.infer<typeof InfluencerPostsFilterSchema>;
export type InfluencerPostsFilterFormKeys = UnionOfKeys<InfluencerPostsFilterFormValues>;
export interface InfluencerPostsProps
  extends Omit<DefaultInfluencersProps<InfluencerPostProfile>, 'getSortSelectButtonProps'> {
  getSortSelectButtonProps: (
    listRecords: InfluencerPostProfile[],
    filterPostSocialMedia: SocialAccountType | SocialPostType
  ) => CheckBoxProps;
}
export interface InfluencerPostProfile {
  id: number;
  name: string;
  postDate?: string | number;
  avatar?: string;
  posts: PostType[];
  age?: number | null;
  gender: Genders | '';
  postId?: number | null;
  isBrandAccount?: boolean;
  postDescription?: string;
  followers?: number | null;
  postThumbnail?: string | null;
  engagementRate?: number | null;
  socialAccountId?: number | null;
  status?: SocialAccountStatus | null;
  socialMedia?: SocialAccountType | '';
  country: { id?: string; name?: string };
}
