import { FormStyle, RichEditor, RichEditorProps } from '@/shared/atoms';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export type RichEditorFieldProps<T extends string = string> = DefaultFieldProps<T, string>;

export const RichEditorField = <T extends string = string>(
  props: RichEditorFieldProps<T> & Pick<RichEditorProps, 'getSunEditorInstance'>
) => {
  const {
    fieldRegister,
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, onChange, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      {({ hasError }) => (
        <>
          <FormStyle.FocusInput ref={fieldRegister.ref} />
          <RichEditor
            height="auto"
            {...restFieldProps}
            hasError={hasError}
            getSunEditorInstance={props.getSunEditorInstance}
            onChange={(value) => onChange?.(name, value) ?? setValue<string>(name, value, setValueOptions)}
          />
        </>
      )}
    </RHFFieldWrapper>
  );
};
