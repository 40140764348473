import { useTranslation } from 'react-i18next';
import { useAllRegionsQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';
import { MultiSelectField, MultiSelectFieldProps } from '../MultiSelectField';

export interface RegionMultiSelectorProps extends Omit<MultiSelectFieldProps, 'options'> {
  countryId: string;
}

export const RegionMultiSelector = ({
  className,
  name,
  value,
  countryId,
  disabled,
  innerRef,
  ...field
}: RegionMultiSelectorProps) => {
  const { t } = useTranslation();
  const { data, error } = useAllRegionsQuery({
    variables: { countryId },
    skip: !countryId,
    fetchPolicy: 'cache-and-network'
  });
  const options = data ? getOptions(data.allRegions) : [];
  const translatedOptions = options.map((item) => ({ ...item, label: t(item.label || '') }));

  return (
    <MultiSelectField
      className={className}
      name={name}
      value={value}
      disabled={disabled || !!error || options.length === 0}
      options={translatedOptions}
      {...field}
      innerRef={innerRef}
    />
  );
};
RegionMultiSelector.displayName = 'RegionMultiSelector';
