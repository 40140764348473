import { useEngagementPermissions, useMarketplacePermissions } from '@/auth';
import { CampaignType } from '@/graphql';
import { Option } from '@/shared/types';

const CAMPAIGN_TYPE_OPTIONS: Option<CampaignType | ''>[] = [
  { value: CampaignType.ENGAGEMENT, label: 'Engagement' },
  { value: CampaignType.MARKETPLACE, label: 'Marketplace' },
  { value: '', label: 'All' }
];

export const useCampaignTypesOptionsWithPermissions = (options: Option[] = CAMPAIGN_TYPE_OPTIONS) => {
  const { hideMarketplaceReportLink } = useMarketplacePermissions();
  const { hideEngagementReportLink } = useEngagementPermissions();

  const allowedCampaignTypes = [
    ...(!hideMarketplaceReportLink ? [CampaignType.MARKETPLACE] : []),
    ...(!hideEngagementReportLink ? [CampaignType.ENGAGEMENT] : []),
    ''
  ];

  return options.filter((type) =>
    hideMarketplaceReportLink || hideEngagementReportLink
      ? type.value && allowedCampaignTypes.includes(type.value)
      : true
  );
};
