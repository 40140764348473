import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { grayedChartImage, magnifyingGlassImage } from '@/shared/assets';
import { THEME } from '@/shared/constants';
import { Anchor } from '../Anchor';
import { HelpIcon } from '../Icon';

export interface NoDataWrapperProps {
  icon?: string;
  help?: string;
  header?: ReactNode;
  className?: string;
  children?: ReactNode;
  title?: string | null;
  wrapperText?: ReactNode;
  dataNotAvailable?: boolean;
  emptyImage?: 'magnifying-glass' | 'default';
  wrapperLink?: { url?: string; href?: string; text?: string; customLink?: ReactNode };
}
export const NoDataWrapper = ({
  help,
  icon,
  title,
  header,
  children,
  className,
  wrapperLink,
  emptyImage = 'default',
  dataNotAvailable = false,
  wrapperText = 'General.Unable to retrieve data'
}: NoDataWrapperProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper} className={className}>
      {header
        ? header
        : title && (
            <div css={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
              <img css={{ width: '24px', height: '24px', marginRight: '8px' }} src={icon} />
              <h2 css={{ color: THEME.text.colors.black.lv1, fontSize: '13px', fontWeight: 600 }}>{title}</h2>
              {help && <HelpIcon help={help} css={{ marginLeft: '6px' }} />}
            </div>
          )}
      {dataNotAvailable ? (
        <div css={styles.focusedBlock}>
          <img src={emptyImage === 'magnifying-glass' ? magnifyingGlassImage : grayedChartImage} />
          {typeof wrapperText === 'string' ? <p css={styles.text}>{t(wrapperText)}</p> : wrapperText}
          {wrapperLink?.href && wrapperLink.text ? (
            <Anchor variant="blue" href={wrapperLink.href} target="_blank">
              {t(wrapperLink.text)}
            </Anchor>
          ) : wrapperLink?.url && wrapperLink.text ? (
            <Anchor variant="blue" href={wrapperLink.url}>
              {t(wrapperLink.text)}
            </Anchor>
          ) : (
            wrapperLink?.customLink || null
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
};
const styles = {
  wrapper: css({
    padding: '16px',
    position: 'relative',
    borderRadius: '3px',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5)'
  }),
  text: css({
    fontSize: '13px',
    marginTop: '8px',
    lineHeight: '18px',
    textAlign: 'center',
    color: THEME.text.colors.black.lv1
  }),
  focusedBlock: css({
    zIndex: 'auto',
    top: '50%',
    left: '50%',
    display: 'flex',
    width: 'fit-content',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)'
  })
};
