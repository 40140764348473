import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { GenericOption } from '@/shared/types';

export interface ReportSummaryType extends GenericOption<string> {
  unit?: string;
}

export interface ReportSummaryProps {
  className?: string;
  summaries: ReportSummaryType[];
}

export const ReportSummary = ({ className, summaries }: ReportSummaryProps) => (
  <div css={styles.wrapper} className={className}>
    {summaries.map((summary, index) => (
      <div key={index} css={{ fontWeight: 600, padding: '0 16px', borderLeft: '2px solid #dee5ec' }}>
        <div css={{ fontSize: '11px', lineHeight: '20px', textTransform: 'uppercase' }} className="summary-label">
          {summary.label}
        </div>
        <div css={{ display: 'flex', alignItems: 'baseline', paddingRight: '12px' }}>
          <div css={{ fontSize: '20px' }}>{summary.value}</div>
          {!!summary.unit && <div css={{ marginLeft: '6px', fontSize: '10px' }}>{summary.unit}</div>}
        </div>
      </div>
    ))}
  </div>
);

const styles = {
  wrapper: css({
    padding: '16px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    borderTop: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  })
};
