import { useTranslation } from 'react-i18next';
import { FormStyle, ButtonLink } from '@/shared/atoms';
import { BasicFilterWrapper } from '@/shared/molecules';
import { BasicFilterDefaultProps } from '../../types';
import { InfluencerTabField, KeywordField, CountryField } from '../../shared';

export const BasicFilter = ({ onSubmit, isEngagementPage }: BasicFilterDefaultProps) => {
  const { t } = useTranslation();

  return (
    <BasicFilterWrapper>
      <InfluencerTabField isEngagementPage={isEngagementPage} onSubmit={onSubmit} />
      <KeywordField onSubmit={onSubmit} placeholder={t<string>('Search.InfluencerPackageFilter')} />
      <CountryField onSubmit={onSubmit} css={{ maxWidth: '138px' }} multiple />
      <FormStyle.FieldWrapper css={{ maxWidth: '151px' }}>
        <ButtonLink
          variant="blueInverted"
          to="/package/add"
          title={`+ ${t('Button.Add Package')}`}
          css={{ width: '100%', textTransform: 'uppercase' }}
        />
      </FormStyle.FieldWrapper>
    </BasicFilterWrapper>
  );
};
