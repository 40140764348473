import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useAutoManagedCommissionInfluencersQuery } from '@/graphql';
import {
  AutoManagedCommissionsList,
  AutoManagedCommissionsFilter,
  useAutoManagedCommissionsFilter
} from '@/shared/organisms';
import { useAutoManagedCampaignDetailsContext } from '../AutoManagedCampaignDetailsContext';

export const AutoManagedCommissions = () => {
  const { page, limit, filter } = useAutoManagedCommissionsFilter();
  const { autoManagedCampaignId } = useAutoManagedCampaignDetailsContext();
  const { data, previousData, loading } = useAutoManagedCommissionInfluencersQuery({
    variables: getValuableVariables({
      ...filter,
      limit,
      id: autoManagedCampaignId,
      status: filter.status || null,
      offset: getOffset(page, limit),
      fixedDate: filter.month ? filter.month.split('_')[0] : null
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.autoManagedCommissionInfluencersCounts?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <AutoManagedCommissionsFilter autoManagedCampaignId={autoManagedCampaignId} />
      <AutoManagedCommissionsList
        loading={loading}
        totalRecords={totalRecords}
        autoManagedCampaignId={autoManagedCampaignId}
        data={result?.autoManagedCommissionInfluencers || []}
      />
    </ListWithPagination>
  );
};
