import { useXhsProfilePostsQuery } from '@/graphql';
import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import {
  InfluencerXhsProfileAudience,
  InfluencerXhsProfileOverview,
  InfluencerXhsProfileVideos,
  InfluencerXhsProfilePicturePosts,
  InfluencerXhsProfileEngagementInsights
} from '../../InfluencerProfiles';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerXhsProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerXhsProfile = ({ socialAccount }: InfluencerXhsProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  const { data } = useXhsProfilePostsQuery({
    variables: {
      pk: influencerProfile.id,
      socialAccountId: socialAccount.id
    }
  });

  return (
    <>
      <InfluencerXhsProfileOverview influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerXhsProfileAudience influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerXhsProfileEngagementInsights
        influencerProfileId={influencerProfile.id}
        socialAccountId={socialAccount.id}
      />
      <InfluencerXhsProfilePicturePosts
        influencerId={influencerProfile.id}
        socialMediaAccountId={socialAccount.id.toString()}
        accountName={socialAccount.name}
        picturePosts={data?.xhsProfilePosts?.picturePosts || []}
      />
      <InfluencerXhsProfileVideos
        influencerId={influencerProfile.id}
        socialMediaAccountId={socialAccount.id.toString()}
        accountName={socialAccount.name}
        videoPosts={data?.xhsProfilePosts?.videoPosts || []}
      />
    </>
  );
};
