import { zodResolver } from '@hookform/resolvers/zod';
import React, { FormHTMLAttributes, ReactNode } from 'react';
import { FieldValues, FormProvider as RHFProvider, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { ZodSchema } from 'zod';

export interface FormProviderProps<T extends FieldValues>
  extends Omit<FormHTMLAttributes<HTMLFormElement>, 'children' | 'onSubmit'> {
  /**
   * The values props will react to changes and update the form values, which is useful when your form needs to be updated by external state or server data
   */
  values?: T;
  zodSchema?: ZodSchema<T>;
  children:
    | ((props: UseFormReturn<T, unknown> & { values: T; onSubmit?: () => void }) => ReactNode)
    | JSX.Element[]
    | JSX.Element;
  onSubmit?: (formData: T, methods: UseFormReturn<T, unknown>) => void | Promise<void>;
  /**
   * https://react-hook-form.com/api/useform
   * sync -> defaultValues: {firstName: '', lastName: ''}
   * async -> defaultValues: async () => fetch('/api-endpoint');
   */
  defaultValues?: UseFormProps<T, unknown>['defaultValues'];
  shouldFocusError?: boolean;
}

export const FormProvider = <T extends FieldValues>({
  values,
  children,
  onSubmit,
  zodSchema,
  defaultValues,
  shouldFocusError = true,
  ...nativeFormProps
}: FormProviderProps<T>) => {
  const methods = useForm<T>({
    values,
    defaultValues,
    shouldFocusError,
    resolver: zodSchema ? zodResolver(zodSchema) : undefined
  });

  const handleSubmit = methods.handleSubmit(() => onSubmit?.(methods.getValues(), methods));

  const cloneElements = (children: FormProviderProps<T>['children']): React.ReactNode =>
    typeof children === 'function'
      ? children({ ...methods, values: methods.getValues(), onSubmit: handleSubmit })
      : React.Children.map(children, (child) =>
          child.type === React.Fragment
            ? cloneElements(child.props.children)
            : child.props.name
              ? // if child has type - it is some form element and we can register it
                React.createElement(child.type, { ...child.props, key: child.props.name, register: methods.register })
              : // if no name - means child is some HOC wrapper and we need to pass onSubmit to it's props
                React.createElement(child.type, { ...child.props, onSubmit: handleSubmit })
        );

  return (
    <RHFProvider {...methods}>
      <form onSubmit={handleSubmit} css={{ width: 'inherit', borderRadius: 'inherit' }} noValidate {...nativeFormProps}>
        {cloneElements(children)}
      </form>
    </RHFProvider>
  );
};
