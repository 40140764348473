export enum Language {
  ja = 'ja',
  en = 'en',
  cn = 'cn',
  tw = 'tw',
  ko = 'ko',
  'en-JA' = 'en-JA'
}

export type AppLanguage = keyof typeof Language;
