import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateTiktokSpecialCampaign.graphql';
import { Mutation, MutationupdateTiktokSpecialCampaignArgs } from '../../__generated__/globalTypes';

export const useUpdateTiktokSpecialCampaignMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateTiktokSpecialCampaignArgs>
) => {
  const [callUpdateTiktokSpecialCampaign, result] = useMutation<Mutation, MutationupdateTiktokSpecialCampaignArgs>(
    MUTATION,
    options
  );

  return { callUpdateTiktokSpecialCampaign, ...result };
};

export type UpdateTiktokSpecialCampaignMutationFunction = Mutation['updateTiktokSpecialCampaign'];
