import { css } from '@emotion/react';
import 'highcharts/modules/treemap';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import {
  TextCutter,
  NoDataTitle,
  NoDataContentWrapper,
  ChartTooltipSingleMetric,
  NoDataContentWrapperProps
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatPercent } from '@/shared/utils';
import { HighChart, Highcharts } from '../HighChart';

export interface AccountsInterestChartProps
  extends Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText' | 'dataNotAvailable'> {
  data?: readonly { category: string; rate: number }[];
  skipDataLabelTranslate?: boolean;
  treemapOptions?: Highcharts.PlotTreemapOptions;
}

export const AccountsInterestChart = ({
  data,
  header,
  dataNotAvailable,
  skipDataLabelTranslate,
  treemapOptions,
  ...noDataContentProps
}: AccountsInterestChartProps) => {
  const { t } = useTranslation();

  const options: Highcharts.Options = {
    title: { text: '' },
    chart: { type: 'treemap', height: '296px' },
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      borderColor: THEME.chart.tooltip.border.colors.green,
      backgroundColor: THEME.chart.tooltip.background.colors.black,
      style: { color: THEME.chart.tooltip.text.colors.white },
      formatter() {
        return renderToStaticMarkup(<ChartTooltipSingleMetric value={formatPercent(this.value)} />);
      }
    },
    plotOptions: {
      treemap: {
        ...treemapOptions,
        layoutAlgorithm: 'squarified',
        animation: { duration: 500 },
        states: { hover: { borderColor: '#ED183E' } }
      }
    },
    series: [
      {
        type: 'treemap',
        data:
          data?.map((item, idx) => ({
            value: item.rate,
            opacity: item.rate,
            name: item.category,
            color: `rgba(255, 128, 151, ${idx ? 1 - idx * 0.15 : 1}`
          })) || [],
        dataLabels: {
          crop: false,
          useHTML: true,
          enabled: true,
          align: 'center',
          overflow: 'allow',
          verticalAlign: 'middle',
          style: { fontSize: '11px', color: THEME.text.colors.white },
          formatter() {
            const width = this?.shapeArgs?.width ? `${this.shapeArgs.width - 8}px` : 'auto';

            return renderToStaticMarkup(
              <TextCutter
                text={skipDataLabelTranslate ? this.name : t(`Option.${this.name}`)}
                lines={2}
                css={[styles.label, { width }]}
              />
            );
          }
        }
      }
    ]
  };

  return (
    <NoDataContentWrapper
      css={{ padding: '10px 8px 0' }}
      dataNotAvailable={!data?.length || dataNotAvailable}
      header={header ?? <NoDataTitle css={{ marginLeft: '10px' }} title={t(`Selector.Account's Interest`)} />}
      {...noDataContentProps}
    >
      <HighChart options={options} />
    </NoDataContentWrapper>
  );
};
const styles = {
  label: css({
    textAlign: 'center',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    overflowWrap: 'break-word'
  })
};
