import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';
import { InfluencerAvatar, SingleSelector } from '@/shared/molecules';
import { AnalyticsComparableAccountType } from './types';
import { supportedCompareAccountColors } from './utils';

type AccountId = AnalyticsComparableAccountType['id'];

export interface AnalyticsCompareOverviewAccountsProps {
  visibleAccountIds?: AccountId[];
  onSetVisibleAccountIds?: (ids: AccountId[]) => void;
  comparableAccounts: AnalyticsComparableAccountType[];
}
export const AnalyticsCompareOverviewAccounts = ({
  comparableAccounts,
  visibleAccountIds = [],
  onSetVisibleAccountIds
}: AnalyticsCompareOverviewAccountsProps) => {
  const remainingAccounts = comparableAccounts.filter((account) => !visibleAccountIds.includes(account.id));

  return visibleAccountIds.length ? (
    <div css={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
      {visibleAccountIds.map((id, index) => (
        <SingleSelector
          value={String(id)}
          key={`account-${index}-${id}`}
          name={`account-${index}-${id}`}
          css={{ flex: '20%', maxWidth: '20%' }}
          styles={{ control: (base) => ({ ...base, height: '40px', paddingLeft: '8px' }) }}
          options={buildOptions(
            comparableAccounts.find((acc) => acc.id === id),
            remainingAccounts,
            index
          )}
          onChange={(id) => {
            const newVisibleAccountIds = [...visibleAccountIds];
            newVisibleAccountIds[index] = Number(id);
            onSetVisibleAccountIds?.(newVisibleAccountIds);
          }}
          hideDeselectOption
          hideInput
        />
      ))}
    </div>
  ) : null;
};

const styles = {
  hiddenItemWrapper: css({
    width: '20px',
    height: '20px',
    display: 'flex',
    marginRight: '4px',
    borderRadius: '50%',
    alignItems: 'center',
    backgroundColor: '#000',
    justifyContent: 'center'
  }),
  color: (index: number) =>
    css({
      width: '14px',
      height: '14px',
      flexShrink: '0',
      marginRight: '16px',
      backgroundColor: supportedCompareAccountColors[index]
    })
};

const buildOptions = (
  account: AnalyticsComparableAccountType | undefined,
  allAccounts: AnalyticsComparableAccountType[],
  idx: number
) => [
  ...(account ? prepareDropdownOptions([account], idx) : []),
  ...prepareDropdownOptions(allAccounts, idx),
  // Hidden option
  {
    title: '',
    value: '-1',
    label: 'Hidden',
    icon: (
      <div css={styles.hiddenItemWrapper}>
        <Icon icon="eye-mask" size="16px" color="#fff" />
      </div>
    )
  }
];

const prepareDropdownOptions = (accounts: AnalyticsComparableAccountType[], index: number) =>
  accounts.map((acc) => ({
    ...acc,
    label: acc.username,
    value: String(acc.id),
    prefixIcon: <div css={styles.color(index)} />,
    icon: <InfluencerAvatar size="20px" css={{ marginRight: '4px', borderRadius: '50%' }} src={acc.avatarUrl} />
  }));
