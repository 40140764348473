import { CSSObject, SerializedStyles, css } from '@emotion/react';
import { Anchor, ButtonDropdown, Icon } from '@/shared/atoms';
import { LANGUAGE_OPTIONS, THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { DropdownOption } from '@/shared/types';

export interface SignInSignUpFooterProps {
  hasCopyRight?: boolean;
  className?: string | CSSObject | SerializedStyles;
}

export const SignInSignUpFooter = ({ hasCopyRight = true, className }: SignInSignUpFooterProps) => {
  const { t, i18n, pathname } = useQueryHelper();

  const handleLanguageSelect = (option: DropdownOption) => {
    i18n.changeLanguage(option.value);
  };

  return (
    <footer css={[styles.wrapper, { justifyContent: hasCopyRight ? 'space-between' : 'center' }, className]}>
      {hasCopyRight ? <p css={styles.copyRight}>{t('CopyrightSentence')}</p> : null}

      <div css={{ display: 'flex' }}>
        <Anchor css={styles.link} to="/privacy">
          {t('Password.Privacy Policy')}
        </Anchor>

        <Anchor css={styles.link} to="/terms-combined">
          {t('Password.Terms of Service')}
        </Anchor>

        <Anchor css={styles.link} href="https://anytag.jp/contact/" target="_blank">
          {t('Contact')}
        </Anchor>

        <ButtonDropdown
          options={LANGUAGE_OPTIONS}
          onSelectOption={handleLanguageSelect}
          css={{ padding: 0, border: 'none', height: 'unset' }}
          placement={pathname === '/sign-up' ? 'top-end' : 'bottom-end'}
          title={
            <Anchor css={[styles.link, { borderRight: 'unset', paddingRight: 0 }]}>
              <span css={{ fontWeight: 'normal' }}>{t('languageTitle')}</span>
              <Icon size={6} css={{ marginLeft: '6px' }} icon="caret-down" />
            </Anchor>
          }
        />
      </div>
    </footer>
  );
};
const styles = {
  wrapper: css({
    display: 'flex',
    paddingTop: '16px',
    alignItems: 'center',
    width: THEME.container.dashboard.maxWidth
  }),
  link: css({
    fontSize: '12px',
    padding: '0 14px',
    lineHeight: '16px',
    color: THEME.text.colors.gray.lv3,
    borderRight: THEME.border.base
  }),
  copyRight: css({
    fontSize: '12px',
    lineHeight: '100%',
    marginRight: '24px',
    color: THEME.text.colors.gray.lv3
  })
};
