import { ReactNode } from 'react';
import { PostDetailsTemplateType } from '../types';
import { InfluencerInfo } from './InfluencerInfo';
import { PostCaption } from './PostCaption';
import { PostComments } from './PostComments';
import { PostFooter } from './PostFooter';
import { PostMediaPreview, PostMediaPreviewProps } from './PostMediaPreview';
import { PostStatistics } from './PostStatistics';
import { SimilarPosts } from './SimilarPosts';
import * as PostStyles from './StyledComponent';

export interface DefaultPostDetailsTemplateProps
  extends Pick<PostMediaPreviewProps, 'onMediaChange' | 'onTagClick' | 'currentMediaIndex' | 'reactPlayerProps'> {
  loading?: boolean;
  className?: string;
  children?: ReactNode;
  footerNode?: ReactNode;
  postStatus?: ReactNode;
  objectFit?: 'contain' | 'cover';
  postDetails?: PostDetailsTemplateType;
}

export const DefaultPostDetailsTemplate = ({
  loading,
  children,
  objectFit,
  className,
  postStatus,
  footerNode,
  onTagClick,
  postDetails,
  onMediaChange,
  reactPlayerProps,
  currentMediaIndex
}: DefaultPostDetailsTemplateProps) => {
  const similarPosts = postDetails?.similarPosts;
  const hasExtraHeight = !!similarPosts?.length || !!children || !!footerNode;

  return (
    <PostStyles.Wrapper loading={loading} className={className} hasExtraHeight={hasExtraHeight}>
      {postDetails ? (
        <>
          <div css={{ flex: 1, display: 'flex' }}>
            <PostMediaPreview
              onTagClick={onTagClick}
              video={postDetails?.video}
              images={postDetails?.images}
              isReel={postDetails?.isReel}
              onMediaChange={onMediaChange}
              currentMediaIndex={currentMediaIndex}
              reactPlayerProps={reactPlayerProps}
            />

            <div css={{ flex: 1, display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
              <InfluencerInfo info={postDetails.info} objectFit={objectFit} />
              <PostStatistics
                postStatus={postStatus}
                postUrl={postDetails?.postUrl}
                statistics={postDetails.statistics}
                socialMedia={postDetails.socialMedia}
              />
              <PostCaption caption={postDetails?.caption} />
              <PostComments socialMedia={postDetails.socialMedia} comments={postDetails?.comments} />
            </div>
          </div>
          <SimilarPosts posts={similarPosts || []} socialMedia={postDetails.socialMedia} />

          {children}

          <PostFooter>{footerNode}</PostFooter>
        </>
      ) : null}
    </PostStyles.Wrapper>
  );
};
