import axios from 'axios';
import {
  useUploadEngagementReportFileMutation,
  useGenerateUploadReportSignedUrlPromiseQuery,
  namedOperations
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';

interface Props {
  campaignId: number;
}

export const useUploadEngagementReportFile = ({ campaignId }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { getGenerateUploadReportSignedUrl } = useGenerateUploadReportSignedUrlPromiseQuery();
  const { callUploadEngagementReportFile, loading } = useUploadEngagementReportFileMutation({
    refetchQueries: [namedOperations.Query.EngagementCampaignReport]
  });

  const uploadFileToGCSAndOurBackend = async (signedUrl: string, accepted: File) => {
    try {
      await axios(signedUrl, { method: 'PUT', data: accepted });
      const urlObjFromSignedUrl = new URL(signedUrl);
      // full path url without search
      const uploadFileUrl = urlObjFromSignedUrl.origin + urlObjFromSignedUrl.pathname;
      await callUploadEngagementReportFile({ variables: { input: { id: campaignId, uploadFileUrl } } });
      enqueueSnackbar(t('Your CSV file was successfully uploaded, report information should reflect those changes'), {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(t(error?.message || 'fileUploadFail'), { variant: 'error' });
    }
  };

  const handleUploadFile = async (file: File) => {
    const { data } = await getGenerateUploadReportSignedUrl({ variables: { fileNames: [file.name] } });

    const signedUrlResp = data?.generateUploadReportSignedUrl.fileUploadUrls[0] || null;

    if (signedUrlResp) {
      await uploadFileToGCSAndOurBackend(signedUrlResp.signedUrl, file);
    }
  };

  return { handleUploadFile, uploading: loading };
};
