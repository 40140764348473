import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ColorVariant } from '@/shared/types';
import { SpreadButton } from '@/shared/molecules';
import { useSelectItemIds, useTranslateCountry } from '@/shared/hooks';
import { AUTO_MANAGED_CAMPAIGN_TYPE_OPTIONS } from '@/shared/constants';
import { formatIntNumber, formatNumber, formatPercent } from '@/shared/utils';
import { Status, RowProps, CheckBox, TableCell, ColumnProps, StatusProps } from '@/shared/atoms';
import { AutoManagedCampaignStatus, AutoManagedCampaignInfoPayload, GetAutoManagedCampaignsSortField } from '@/graphql';

type CustomColumnProps = ColumnProps<GetAutoManagedCampaignsSortField>;
type SelectCampaignsProps = Pick<
  ReturnType<typeof useSelectItemIds<number>>,
  'selectItem' | 'selectedItemIds' | 'hasAllItemsSelected' | 'toggleSelectAllItems'
>;
interface Props extends SelectCampaignsProps {
  data: readonly AutoManagedCampaignInfoPayload[];
}

export const useAutoManagedCampaignsTable = ({
  data,
  selectItem,
  selectedItemIds,
  hasAllItemsSelected,
  toggleSelectAllItems
}: Props) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const getStatusColumnProps = (status: AutoManagedCampaignStatus): StatusProps => {
    switch (status) {
      case AutoManagedCampaignStatus.ACTIVE:
        return { label: t('Option.Active'), variant: ColorVariant.LIGHT_GREEN };
      case AutoManagedCampaignStatus.INACTIVE:
        return { label: t('Option.Inactive'), variant: ColorVariant.LIGHT_GREY };
      case AutoManagedCampaignStatus.FINISHED:
        return { label: t('Option.Finished'), variant: ColorVariant.LIGHT_GREY };
      default:
        return { label: '', variant: ColorVariant.LIGHT_GREY };
    }
  };

  const columns: CustomColumnProps[] = [
    {
      title: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <CheckBox
            size="16px"
            onClick={toggleSelectAllItems}
            tooltipProps={{ help: t(hasAllItemsSelected ? 'Unselect all' : 'Select all') }}
          />
          <span css={{ marginLeft: '8px' }}>{t('HeaderColumn.Campaign Title')}</span>
        </div>
      ),
      sticky: { active: true, hasBorderRight: true },
      styles: { width: '324px' }
    },
    { title: t('HeaderColumn.Marketplace') },
    { title: t('HeaderColumn.Country') },
    { title: t('HeaderColumn.Status') },
    { title: t('HeaderColumn.Seller Name') },
    getMetricColumnProps({
      title: t('HeaderColumn.Joined Influencers'),
      sortField: GetAutoManagedCampaignsSortField.JOINED_CREATORS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Orders'),
      sortField: GetAutoManagedCampaignsSortField.ORDERS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Sales'),
      sortField: GetAutoManagedCampaignsSortField.SALES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Commission Rate'),
      sortField: GetAutoManagedCampaignsSortField.COMMISSION_RATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      title,
      sales,
      orders,
      status,
      country,
      sellerName,
      commissionRate,
      numberOfJoinedCreators,
      autoManagedCampaignType
    }) => {
      const selected = selectedItemIds.includes(id);

      return [
        <div css={styles.nameColumnWrapper}>
          <CheckBox
            size="16px"
            checked={selected}
            onClick={() => selectItem(id)}
            tooltipProps={{ help: t(selected ? 'Unselect' : 'Select') }}
          />
          <TableCell.Link
            variant="blue"
            label={title}
            css={{ marginLeft: '8px', flex: 1 }}
            to="/auto-managed/$id"
            params={{ id: id.toString() }}
            lines={1}
          />
          <SpreadButton
            options={[
              {
                icon: 'chart',
                label: t('Report'),
                tooltip: { help: t('Button.Report') },
                to: '/auto-managed/$id/report',
                params: { id: id.toString() }
              }
            ]}
          />
        </div>,
        <TableCell.Text
          value={AUTO_MANAGED_CAMPAIGN_TYPE_OPTIONS.find((opt) => opt.value === autoManagedCampaignType)?.label || '-'}
        />,
        <TableCell.Text value={tc(country.name)} css={{ minWidth: '120px' }} />,
        <Status {...getStatusColumnProps(status)} />,
        <TableCell.Text value={sellerName} css={{ minWidth: '120px' }} />,
        <TableCell.Number value={formatIntNumber(numberOfJoinedCreators)} />,
        <TableCell.Number value={formatIntNumber(orders)} />,
        <TableCell.Number value={formatNumber(sales.amount)} unit={t(sales.currencyId)} />,
        <TableCell.Number value={formatPercent(commissionRate, false)} unit="%" />
      ];
    }
  );

  return { rows, columns };
};

const styles = {
  nameColumnWrapper: css({
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  })
};
