import { ChartsContainer } from '@/shared/atoms';
import { AnalyticsAudienceWrapper } from '@/shared/organisms';
import { GenderBreakdownChart, AgeBreakdownChart } from '@/shared/molecules';
import { SocialAccountType, InfluencerNewProfileHeaderV2, useInfluencerProfileAudienceQuery } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsFacebookProfileAudienceProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerAnalyticsFacebookProfileAudience = ({
  socialAccount,
  influencerProfile: { id: influencerId, status }
}: InfluencerAnalyticsFacebookProfileAudienceProps) => {
  // We used to use InfluencerFacebookPageAnalyticsAudience api to get audience data
  // But FB deprecated it for now, so we changed to use the api InfluencerProfileAudience - which being used for scraping account
  // https://adasiaholdings.atlassian.net/browse/AT-6462
  const { data, loading } = useInfluencerProfileAudienceQuery({
    fetchPolicy: 'no-cache',
    variables: {
      pk: influencerId,
      socialAccountId: socialAccount.id,
      socialMedia: socialAccount.type as SocialAccountType
    }
  });

  return (
    <AnalyticsAudienceWrapper loading={loading} data={data?.influencerProfileAudience}>
      {({ data: { maleRate, femaleRate, ageGenderRates } }) => (
        <ChartsContainer css={{ height: '385px' }}>
          <GenderBreakdownChart maleRate={maleRate} femaleRate={femaleRate} />
          <AgeBreakdownChart {...ageGenderRates} />
        </ChartsContainer>
      )}
    </AnalyticsAudienceWrapper>
  );
};
