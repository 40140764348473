import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '@/shared/utils';
import { SelectedRangeOption } from '@/shared/types';
import { useAllInfluencerCategoryOptions, useTranslateOptions } from '@/shared/hooks';
import {
  MIN_VIEWS,
  MAX_VIEWS,
  MAX_FOLLOWERS,
  MIN_FOLLOWERS,
  GENDER_OPTIONS,
  MAX_ENGAGEMENT_RATE,
  MIN_ENGAGEMENT_RATE,
  MIN_MAX_VIEW_OPTIONS,
  MIN_MAX_EN_RATE_OPTIONS,
  MIN_MAX_FOLLOWERS_OPTIONS
} from '@/shared/constants';
import {
  SelectorField,
  FILTER_SECTIONS,
  MoreFilterPopover,
  RangeDatePickerField,
  RangeSliderInputField
} from '@/shared/molecules';
import { defaultPostsFilterValues } from './utils';
import { PostsFilterFormKeys, PostsFilterFormValues } from './schemaTypes';

interface AdvancedFilterProps {
  count: number;
  onSubmit?: () => void;
}

export const AdvancedFilter = ({ count, onSubmit }: AdvancedFilterProps) => {
  const { t } = useTranslation();
  const genderOptions = useTranslateOptions(GENDER_OPTIONS);
  const { watch, reset } = useFormContext<PostsFilterFormValues>();
  const { allInfluencerCategoryOptions } = useAllInfluencerCategoryOptions();

  const values = watch();
  const isAllTime = !values.minPostDate && !values.maxPostDate;
  const postFilterRangeList = [
    SelectedRangeOption.TODAY,
    SelectedRangeOption.LAST_SEVEN_DAYS,
    SelectedRangeOption.TODAY_TO_MONTH_AGO,
    SelectedRangeOption.THIS_MONTH,
    SelectedRangeOption.LAST_MONTH,
    SelectedRangeOption.CUSTOM,
    SelectedRangeOption.CLEAR
  ];

  const handleClear = () => {
    reset({
      ...defaultPostsFilterValues,
      // Basic filter
      keyword: values.keyword,
      countryId: values.countryId,
      socialMedia: values.socialMedia
    });
  };

  return (
    <MoreFilterPopover
      count={count}
      onClear={handleClear}
      onSubmit={onSubmit}
      defaultOpened={[FILTER_SECTIONS.Posts]}
      items={[
        {
          value: FILTER_SECTIONS.Posts,
          node: (
            <>
              <RangeDatePickerField<PostsFilterFormKeys>
                // we can't use existing "All time" range since in here desired behavior is to clear post date selection, but text should be "All time"
                clearTitle={t('All time')}
                hasCalendarIcon={false}
                title={t('Selector.Post Date')}
                rangeList={postFilterRangeList}
                disabledRange={{ after: new Date() }}
                name={{ startDate: 'minPostDate', endDate: 'maxPostDate' }}
                customInputTextNode={isAllTime ? <p>{t('All time')}</p> : undefined}
                hasBorderBottom
                withinPortal={false}
              />
              <SelectorField<PostsFilterFormKeys>
                name="categoryIds"
                title={t('Selector.Categories')}
                options={allInfluencerCategoryOptions}
                hasBorderBottom
                multiple
              />
              <SelectorField<PostsFilterFormKeys>
                name="genders"
                title={t('Selector.Gender')}
                options={genderOptions}
                hasBorderBottom
                multiple
              />
              <RangeSliderInputField<PostsFilterFormKeys>
                step={10000}
                title={t('Views')}
                formatLabel={formatIntNumber}
                options={MIN_MAX_VIEW_OPTIONS}
                range={{ min: MIN_VIEWS, max: MAX_VIEWS }}
                minField={{ name: 'minViews', placeholder: String(MIN_VIEWS) }}
                maxField={{ name: 'maxViews', placeholder: `${MAX_VIEWS}+` }}
                hasBorderBottom
              />
              <RangeSliderInputField<PostsFilterFormKeys>
                step={10000}
                formatLabel={formatIntNumber}
                options={MIN_MAX_FOLLOWERS_OPTIONS}
                title={t('General.Number of Followers')}
                range={{ min: MIN_FOLLOWERS, max: MAX_FOLLOWERS }}
                minField={{ name: 'minFollowers', placeholder: String(MIN_FOLLOWERS) }}
                maxField={{ name: 'maxFollowers', placeholder: `${MAX_FOLLOWERS}+` }}
                hasBorderBottom
              />
              <RangeSliderInputField<PostsFilterFormKeys>
                step={0.1}
                options={MIN_MAX_EN_RATE_OPTIONS}
                title={t('General.Engagement Rate')}
                range={{ min: MIN_ENGAGEMENT_RATE, max: MAX_ENGAGEMENT_RATE }}
                minField={{ name: 'minEngagementRate', placeholder: `${MIN_ENGAGEMENT_RATE}%` }}
                maxField={{ name: 'maxEngagementRate', placeholder: `${MAX_ENGAGEMENT_RATE}%` }}
                hasBorderBottom
              />
            </>
          )
        }
      ]}
    />
  );
};
