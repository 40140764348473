import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { formatIntNumber } from '@/shared/utils';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar } from '@/shared/molecules';
import { YoutubeAnalyticsTrendPayload } from '@/graphql';
import { useGenerateInfluencerProfileLink, usePostDetailsParam } from '@/shared/hooks';
import { ColumnProps, RowProps, MediaPreview, TextCutter, TableCell } from '@/shared/atoms';
import { TrendingPostsTabType } from '../../TrendingPostsFilter/schemaTypes';
import { useTrendingPostsFilter } from '../../TrendingPostsFilter';

interface Props {
  tab: TrendingPostsTabType;
  data: readonly YoutubeAnalyticsTrendPayload[];
}

export const useTrendingPostsTable = ({ tab, data }: Props) => {
  const { t } = useTranslation();
  const { page, limit } = useTrendingPostsFilter();
  const { setPostDetailsParam } = usePostDetailsParam();
  const { isAdvertiser, isTalentAgency } = useUserRoles();
  const { generateProfileLink } = useGenerateInfluencerProfileLink({ disabled: isAdvertiser || isTalentAgency });

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Ranking'), styles: { textAlign: 'center', paddingRight: '16px' } },
    { title: t('HeaderColumn.Post') },
    { title: t('HeaderColumn.Channel') },
    { title: t('HeaderColumn.View'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Comments'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Post date') }
  ];
  const rows: RowProps[] = data.map(
    (
      {
        id,
        views,
        channel,
        postDate,
        comments,
        videoTitle,
        videoCaption,
        influencerId,
        channelAvatar,
        videoThumbnail,
        socialAccountId
      },
      index
    ) => {
      const rankNumber = page && limit ? (page - 1) * limit + index + 1 : 0;

      return [
        <TableCell.Text
          value={String(rankNumber)}
          css={{ minWidth: '60px', textAlign: 'center', paddingRight: '8px' }}
        />,
        <div css={{ maxWidth: '500px', display: 'flex', alignItems: 'center', padding: '8px 0' }}>
          <MediaPreview
            src={videoThumbnail}
            onClick={() => setPostDetailsParam(id)}
            css={{ width: '80px', height: '80px', borderRadius: '5px' }}
          />
          <div css={{ marginLeft: '16px' }}>
            <TableCell.Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setPostDetailsParam(id);
              }}
              label={videoTitle}
            />
            <TextCutter text={videoCaption} lines={4} css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3 }} />
          </div>
        </div>,
        <TableCell.Link
          css={{ display: 'flex', maxWidth: '200px', alignItems: 'center' }}
          {...generateProfileLink(
            influencerId,
            socialAccountId ? { filter: { id: String(socialAccountId), sm: tab } } : undefined
          )}
        >
          <InfluencerAvatar size="32px" src={channelAvatar} css={{ borderRadius: '50%' }} asChild />
          <TableCell.Text value={channel} css={{ marginLeft: '8px' }} />
        </TableCell.Link>,
        <TableCell.Number css={{ minWidth: '100px' }} value={formatIntNumber(views)} />,
        <TableCell.Number css={{ minWidth: '100px' }} value={formatIntNumber(comments)} />,
        <TableCell.Date value={postDate} />
      ];
    }
  );

  return { rows, columns };
};
