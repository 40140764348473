import { ReactNode } from 'react';

interface NumberProps {
  unit?: string | null;
  value: ReactNode;
  className?: string;
}

export const Number = ({ value, unit, className }: NumberProps) => (
  <div
    className={className}
    css={{ fontSize: '14px', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', justifyContent: 'flex-end' }}
  >
    <span>{value}</span>
    {unit ? <span css={{ color: '#8694a2', fontSize: '10px', marginLeft: '4px' }}>{unit}</span> : null}
  </div>
);
