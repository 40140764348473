import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerYoutubeAnalyticsPostsByDate.graphql';
import { Query, QueryinfluencerYoutubeAnalyticsPostsByDateArgs } from '../../__generated__/globalTypes';

export const useInfluencerYoutubeAnalyticsPostsByDateQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsPostsByDateArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerYoutubeAnalyticsPostsByDateArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerYoutubeAnalyticsPostsByDateLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsPostsByDateArgs>
): QueryResult<Query, QueryinfluencerYoutubeAnalyticsPostsByDateArgs> & {
  getInfluencerYoutubeAnalyticsPostsByDate: LazyQueryExecFunction<
    Query,
    QueryinfluencerYoutubeAnalyticsPostsByDateArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerYoutubeAnalyticsPostsByDate, result] = useLazyQuery<
    Query,
    QueryinfluencerYoutubeAnalyticsPostsByDateArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerYoutubeAnalyticsPostsByDate, ...result };
};

export const useInfluencerYoutubeAnalyticsPostsByDatePromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerYoutubeAnalyticsPostsByDate = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerYoutubeAnalyticsPostsByDateArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerYoutubeAnalyticsPostsByDateArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerYoutubeAnalyticsPostsByDate };
};
