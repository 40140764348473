import { CSSProperties, ReactElement, ReactNode } from 'react';
import { THEME } from '@/shared/constants';
import { Delayed } from '../Delayed';
import { ListEmpty } from '../ListEmpty';
import { Loading, LoadingProps } from '../Loading';

export interface RenderDataWithFallbackProps {
  loading?: boolean;
  hasNoData?: boolean;
  children?: ReactNode;
  awaitedTime?: number;
  skipDelayed?: boolean;
  noDataNode?: ReactElement | null;
  loadingNode?: ReactElement;
  loadingProps?: { css?: CSSProperties } & LoadingProps;
  // TODO: we may consider fallback UI for error
}

export const RenderDataWithFallback = ({
  loading,
  children,
  hasNoData,
  awaitedTime,
  skipDelayed,
  loadingProps,
  loadingNode = (
    <Delayed asChild={skipDelayed} awaitedTime={awaitedTime}>
      <Loading {...loadingProps} css={{ backgroundColor: THEME.background.colors.white, ...loadingProps?.css }} />
    </Delayed>
  ),
  noDataNode = <ListEmpty />
}: RenderDataWithFallbackProps) => <>{loading ? loadingNode : hasNoData ? noDataNode : children}</>;
