import {
  namedOperations,
  useCreateInstagramUGCManagementHashtagMutation,
  useDeleteInstagramUGCManagementHashtagMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { AnalyticsSelectedAccountInfo } from '../../../../types';

interface Props {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const useCreateDeleteAnalyticsInstagramUGCHashtag = ({ analyticsAccount }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callCreateInstagramUGCManagementHashtag, loading: creating } = useCreateInstagramUGCManagementHashtagMutation(
    { refetchQueries: [namedOperations.Query.InstagramUGCManagementBasicInfo] }
  );
  const { callDeleteInstagramUGCManagementHashtag, loading: deleting } = useDeleteInstagramUGCManagementHashtagMutation(
    {
      refetchQueries: [
        namedOperations.Query.InstagramUGCManagementBasicInfo,
        namedOperations.Query.InstagramUGCManagementListPosts
      ]
    }
  );

  const handleAddNewHashtag = async (newHashtag: string, callback: () => void) => {
    try {
      await callCreateInstagramUGCManagementHashtag({
        variables: { input: { analyticsAccountId: analyticsAccount.id, hashtag: newHashtag } }
      });

      callback();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleDeleteHashtag = async (hashtagId: string, callback: () => void) => {
    try {
      await callDeleteInstagramUGCManagementHashtag({
        variables: { input: { analyticsAccountId: analyticsAccount.id, hashtagSystemId: Number(hashtagId) } }
      });

      callback();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return {
    creating,
    deleting,
    handleAddNewHashtag,
    handleDeleteHashtag
  };
};
