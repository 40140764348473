import { InstagramStoryPost } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerInstagramAnalyticsStoryDetailsModal } from '@/shared/molecules';
import { PostDetailsType, SelectColumnsAction, TableData } from '@/shared/types';
import { useAnalyticsInstagramStoryPostsListTable } from './hooks/useAnalyticsInstagramStoryPostsListTable';

export interface AnalyticsInstagramStoryPostsListProps
  extends TableData<InstagramStoryPost>,
    SelectColumnsAction<keyof InstagramStoryPost> {
  mainSocialAccountId?: number;
}

export const AnalyticsInstagramStoryPostsList = ({
  loading,
  data = [],
  mainSocialAccountId,
  selectedColumns,
  setSelectedColumns
}: AnalyticsInstagramStoryPostsListProps) => {
  const { rows, columns } = useAnalyticsInstagramStoryPostsListTable({
    data,
    selectedColumns,
    setSelectedColumns
  });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerInstagramAnalyticsStoryDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId }))}
        isAnalytics
      />
    </>
  );
};
