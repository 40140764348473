import { usePermissions } from '@/auth';
import {
  UserRoles,
  useCreateAdminUserMutation,
  useCreateStaffUserMutation,
  useCreateAgencyUserMutation,
  useCreatePartnerUserMutation,
  useCreateAdvertiserUserMutation,
  useCreateTalentAgencyUserMutation
} from '@/graphql';
import { EMAIL_USED, UNEXPECTED_ERROR, EMAIL_DUPLICATED, SUPPORTED_USER_ROLES } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { UserFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const useCreateUser = () => {
  const { canCallCustomPermissions } = usePermissions();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const refetchQueries = canCallCustomPermissions ? ['GetUserPermissions'] : [];

  const { callCreateAdminUser } = useCreateAdminUserMutation({ refetchQueries });
  const { callCreateStaffUser } = useCreateStaffUserMutation({ refetchQueries });
  const { callCreateAgencyUser } = useCreateAgencyUserMutation({ refetchQueries });
  const { callCreatePartnerUser } = useCreatePartnerUserMutation({ refetchQueries });
  const { callCreateAdvertiserUser } = useCreateAdvertiserUserMutation({ refetchQueries });
  const { callCreateTalentAgencyUser } = useCreateTalentAgencyUserMutation({ refetchQueries });

  const createUser: FormAction<UserFormValues>['onSubmit'] = async (
    {
      name,
      email,
      userType,
      password,
      agencyId,
      countryId,
      partnerId,
      permissions,
      advertiserId,
      advertiserIds,
      talentAgencyId,
      hasInvitationEmail,
      notificationSetting,
      autoLinkToAdvertiser
    },
    { setError }
  ) => {
    const input = {
      name,
      email,
      countryId,
      hasInvitationEmail,
      permissions: SUPPORTED_USER_ROLES.includes(userType) ? permissions : undefined,
      ...(!hasInvitationEmail && { password })
    };
    const allAdvertisersIds = advertiserIds.map(Number);

    try {
      switch (userType) {
        case UserRoles.ADMINISTRATOR:
          await callCreateAdminUser({ variables: { input } });
          break;
        case UserRoles.STAFF:
          await callCreateStaffUser({
            variables: { input: { ...input, autoLinkToAdvertiser, advertiserIds: allAdvertisersIds } }
          });
          break;
        case UserRoles.AGENCY:
          await callCreateAgencyUser({
            variables: { input: { ...input, agencyId: Number(agencyId) } }
          });
          break;
        case UserRoles.PARTNER:
          await callCreatePartnerUser({
            variables: { input: { ...input, partnerId: Number(partnerId) } }
          });
          break;
        case UserRoles.ADVERTISER:
          await callCreateAdvertiserUser({
            variables: {
              input: { ...input, notificationSetting, advertiserId: Number(advertiserId) }
            }
          });
          break;
        case UserRoles.TALENT_AGENCY:
          await callCreateTalentAgencyUser({
            variables: { input: { ...input, talentAgencyId: Number(talentAgencyId) } }
          });
          break;
        default:
          break;
      }

      enqueueSnackbar(t('succeededInCreating', { name }), { variant: 'success' });
      navigate({ to: '/user' });
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name }), { variant: 'error' });

      if (error.message === EMAIL_USED) {
        setError('email', { type: 'server', message: EMAIL_DUPLICATED });
      } else {
        enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
      }
    }
  };

  return { createUser };
};
