import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppLanguage } from '@/shared/types';
import { Highcharts } from '@/shared/molecules';
import { TikTokSpecialCampaignChartReport } from '@/graphql';
import { formatIntNumber, localizedDateFormatter, generateYAxisBasedOnDisplayedCharts } from '@/shared/utils';
import { TikTokCampaignReportChartMetricKeys, TikTokCampaignReportOption } from '../types';

interface Props {
  data?: readonly TikTokSpecialCampaignChartReport[];
}

export const useTikTokCampaignReportChart = ({ data }: Props) => {
  const { t, i18n } = useTranslation();
  const allMetrics: Record<TikTokCampaignReportChartMetricKeys, TikTokCampaignReportOption> = {
    cost: { label: t<string>('Cost'), value: 'cost', color: '#FF2B52' },
    engagement: { label: t<string>('Engagement'), value: 'engagement', color: '#FF772B' }
  };
  const [selectedMetric, setSelectedMetric] = useState<TikTokCampaignReportOption['value']>(allMetrics.cost.value);

  const categories = data?.map((d) => d.date) || [];
  const yAxis = generateYAxisBasedOnDisplayedCharts(Object.keys(allMetrics), false);
  const series = [
    {
      zIndex: 1,
      type: 'line',
      name: selectedMetric ? allMetrics[selectedMetric]?.label : '',
      color: selectedMetric ? allMetrics[selectedMetric]?.color : '',
      data: data?.map((item) => (selectedMetric && item[selectedMetric]) ?? 0) || [],
      marker: { lineWidth: 2, symbol: 'circle', fillColor: '#fff', lineColor: undefined }
    }
  ];
  const tooltipData = categories.reduce<Record<string, string>>(
    (rs, cat, index) => ({ ...rs, [cat]: formatIntNumber(series.at(0)?.data[index]) }),
    {}
  );
  const chartOptions: Highcharts.Options = {
    chart: { type: 'column' },
    title: { text: '' },
    xAxis: {
      categories,
      crosshair: true,
      type: 'category',
      labels: {
        formatter: function formatter() {
          return localizedDateFormatter(this.value, 'MMM do', i18n.language as AppLanguage);
        }
      }
    },
    yAxis,
    legend: { enabled: false },
    tooltip: {
      useHTML: true,
      borderRadius: 3,
      borderColor: '#27313b',
      shadow: false,
      formatter: function formatter(): string {
        return `
          <div style="font-size: 12px; padding: 4px; box-sizing: border-box; min-width: 100px;">
            <h3 style="margin-bottom: 8px; font-size: 13px; color: #fff;">${this.key!}</h3>                        
            <p style="color: #c5d0da; margin-bottom: 4px; font-size: 11px;">${t(
              (selectedMetric && allMetrics[selectedMetric]?.label) || ''
            )}</p>
            <p style="font-size: 13px; color: #fff;">${tooltipData[this.key!]}</p>
            </div>
          </div>
        `;
      },
      pointFormat: '',
      style: { zIndex: 1000 }
    },
    series: series as Highcharts.SeriesOptionsType[]
  };

  return {
    allMetrics,
    chartOptions,
    selectedMetric,
    setSelectedMetric
  };
};
