import { SocialAccountType, UserProposalSocialMediaType, EngagementProposalSocialMediaType } from '@/graphql';

export interface SocialPairsIdsType {
  influencerId: number;
  socialAccountId?: number | null;
  socialAccountMedia?: SocialAccountType | UserProposalSocialMediaType | EngagementProposalSocialMediaType | null;
}
export const getSocialPairsIds = (selectedSocialAccounts: SocialPairsIdsType[]) => {
  const ids = new Set<number>();
  const pairIds: {
    influencerId: number;
    socialAccountId?: number | null;
    socialType?: SocialAccountType | null;
  }[] = [];

  selectedSocialAccounts.forEach(({ influencerId, socialAccountId, socialAccountMedia }) => {
    if (influencerId) {
      if (!socialAccountId) {
        ids.add(influencerId);
      }
      pairIds.push({
        influencerId,
        socialType: mapProposalSocialMediaTypeToSocialAccountType(socialAccountMedia),
        socialAccountId: socialAccountId ? Number(socialAccountId) : null
      });
    }
  });

  return {
    socialPairs: pairIds
  };
};

const mapProposalSocialMediaTypeToSocialAccountType = (type?: SocialPairsIdsType['socialAccountMedia']) => {
  switch (type) {
    case SocialAccountType.DOUYIN:
    case UserProposalSocialMediaType.DOUYIN:
    case EngagementProposalSocialMediaType.DOUYIN:
      return SocialAccountType.DOUYIN;
    case SocialAccountType.FACEBOOK:
    case UserProposalSocialMediaType.FACEBOOK:
    case EngagementProposalSocialMediaType.FACEBOOK:
      return SocialAccountType.FACEBOOK;
    case SocialAccountType.FACEBOOK_PAGE:
    case UserProposalSocialMediaType.FACEBOOK_PAGE:
    case EngagementProposalSocialMediaType.FACEBOOK_PAGE:
      return SocialAccountType.FACEBOOK_PAGE;
    case SocialAccountType.INSTAGRAM:
    case UserProposalSocialMediaType.INSTAGRAM:
    case EngagementProposalSocialMediaType.INSTAGRAM:
      return SocialAccountType.INSTAGRAM;
    case SocialAccountType.THREADS:
    case UserProposalSocialMediaType.THREADS:
    case EngagementProposalSocialMediaType.THREADS:
      return SocialAccountType.THREADS;
    case SocialAccountType.TIKTOK:
    case UserProposalSocialMediaType.TIKTOK:
    case EngagementProposalSocialMediaType.TIKTOK:
      return SocialAccountType.TIKTOK;
    case SocialAccountType.TWITTER:
    case UserProposalSocialMediaType.TWITTER:
    case EngagementProposalSocialMediaType.TWITTER:
      return SocialAccountType.TWITTER;
    case SocialAccountType.YOUTUBE:
    case UserProposalSocialMediaType.YOUTUBE:
    case EngagementProposalSocialMediaType.YOUTUBE:
      return SocialAccountType.YOUTUBE;
    case SocialAccountType.XHS:
    case UserProposalSocialMediaType.XHS:
    case EngagementProposalSocialMediaType.XHS:
      return SocialAccountType.XHS;

    case UserProposalSocialMediaType.INSTAGRAM_STORY:
    case EngagementProposalSocialMediaType.INSTAGRAM_STORY:
    default:
      return null;
  }
};
