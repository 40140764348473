import {
  namedOperations,
  UserProposalSocialMediaType,
  useAllowInfluencersUserProposalMutation,
  useDeleteUserProposalInfluencerMutation,
  useDenyInfluencersUserProposalMutation,
  useRequestUserProposalExcelMutation,
  useRequestUserProposalPptxMutation,
  useDeleteUserProposalSocialAccountIndividuallyMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';

interface RemoveSmAccountFromProposalProps {
  influencerId: number;
  socialAccountId: number;
  socialAccountMedia: UserProposalSocialMediaType;
}

export const useProposeInfluencersApi = () => {
  const { t, enqueueSnackbar, trimDotsFromMessage } = useQueryHelper();
  const { callRequestUserProposalExcel } = useRequestUserProposalExcelMutation({
    fetchPolicy: 'no-cache'
  });
  const { callRequestUserProposalPptx } = useRequestUserProposalPptxMutation({ fetchPolicy: 'no-cache' });

  const { callDenyInfluencersUserProposal } = useDenyInfluencersUserProposalMutation({
    refetchQueries: [namedOperations.Query.UserProposal]
  });
  const { callAllowInfluencersUserProposal } = useAllowInfluencersUserProposalMutation({
    refetchQueries: [namedOperations.Query.UserProposal]
  });
  const { callDeleteUserProposalInfluencer, loading } = useDeleteUserProposalInfluencerMutation({
    refetchQueries: [namedOperations.Query.UserProposal]
  });
  const { callDeleteUserProposalSocialAccountIndividually } = useDeleteUserProposalSocialAccountIndividuallyMutation({
    refetchQueries: [namedOperations.Query.UserProposal]
  });

  const handleRemoveInfluencers = async (influencerIds: number[] = []) => {
    if (influencerIds.length === 0) {
      return;
    }

    try {
      await callDeleteUserProposalInfluencer({ variables: { input: { influencerIds } } });
      // we don't want to notify user on each save/delete, just show an error if happens
    } catch (error) {
      enqueueSnackbar(t(trimDotsFromMessage((error as any).message)), { variant: 'error' });
    }
  };

  const handleRemoveSmAccountFromProposal = async ({
    influencerId,
    socialAccountId,
    socialAccountMedia
  }: RemoveSmAccountFromProposalProps) => {
    try {
      await callDeleteUserProposalSocialAccountIndividually({
        variables: {
          input: {
            influencerIds: [influencerId],
            socialAccountIds: [socialAccountId],
            socialAccountMedia
          }
        }
      });
      // we don't want to notify user on each save/delete, just show an error if happens
    } catch (error) {
      enqueueSnackbar(t(trimDotsFromMessage((error as any).message)), { variant: 'error' });
    }
  };

  return {
    loading,
    handleRemoveInfluencers,
    callRequestUserProposalPptx,
    callRequestUserProposalExcel,
    handleRemoveSmAccountFromProposal,
    denyInfluencer: callDenyInfluencersUserProposal,
    allowInfluencer: callAllowInfluencersUserProposal
  };
};
