import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { useUserRoles } from '@/auth';
import { MarketplaceAffiliateReportSummary as MarketplaceAffiliateReportSummaryType } from '@/graphql';
import { ReportSummary, ReportSummaryType } from '@/shared/atoms';
import { formatPercent, formatIntNumber } from '@/shared/utils';

export interface MarketplaceAffiliateReportSummaryProps {
  currency: string;
  isReferralCampaign?: boolean;
  data?: MarketplaceAffiliateReportSummaryType;
}

export const MarketplaceAffiliateReportSummary = ({
  data,
  currency,
  isReferralCampaign
}: MarketplaceAffiliateReportSummaryProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();

  const summaries: ReportSummaryType[] = [
    { label: t<string>('Total Participants'), value: formatIntNumber(data?.joinedCount) },
    ...(!isReferralCampaign ? [{ label: t<string>('Clicks'), value: formatIntNumber(data?.summary?.clicks) }] : []),
    { label: t<string>('Orders'), value: formatIntNumber(data?.summary?.conversions) },
    ...(!isReferralCampaign
      ? [{ label: t<string>('CVR'), value: formatPercent(data?.summary?.cvr, false), unit: '%' }]
      : []),
    { label: t<string>('Store Sales'), value: formatIntNumber(data?.summary?.sales), unit: t<string>(currency) },
    { label: t<string>('Cost'), value: formatIntNumber(data?.summary?.cost), unit: t<string>(currency) },
    ...(isAdminStaff
      ? [
          {
            unit: t<string>(currency),
            label: t<string>('HeaderColumn.Influencer Cost'),
            value: formatIntNumber(data?.summary?.influencerCost)
          },
          {
            unit: t<string>(currency),
            label: t<string>('Gross Profit'),
            value: formatIntNumber(data?.summary?.grossProfit)
          }
        ]
      : [])
  ];

  return <ReportSummary summaries={summaries} css={css({ '> div': { padding: '0 48px 0 16px' } })} />;
};
