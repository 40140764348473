import { InfluencerNewProfileHeaderV2, useInfluencerYoutubeAnalyticsShortsQuery } from '@/graphql';
import { LIMIT_30 } from '@/shared/constants';
import { InfluencerYoutubeAnalyticsPostDetailsModal } from '@/shared/molecules';
import { AnalyticsYoutubeDashboardShortsTemplate } from '@/shared/organisms';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { PostDetailsType } from '@/shared/types';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsYoutubeProfileShortsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: InfluencerNewProfileHeaderV2['id'];
}

export const InfluencerAnalyticsYoutubeProfileShorts = ({
  influencerId,
  socialAccount: { id: socialAccountId, name }
}: InfluencerAnalyticsYoutubeProfileShortsProps) => {
  const { data } = useInfluencerYoutubeAnalyticsShortsQuery({
    variables: {
      offset: 0,
      limit: LIMIT_30,
      socialAccountId,
      endDate: getToday(),
      startDate: getThirtyDaysAgo(),
      orderBy: { field: null, order: null }
    }
  });

  const shorts = data?.influencerYoutubeAnalyticsShorts.shorts || [];

  return (
    <>
      <AnalyticsYoutubeDashboardShortsTemplate
        data={shorts}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/shorts',
          params: { socialMediaAccountId: socialAccountId.toString() },
          search: { filter: { name } }
        }}
      />

      <InfluencerYoutubeAnalyticsPostDetailsModal
        posts={shorts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: socialAccountId }))}
      />
    </>
  );
};
