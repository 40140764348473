import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestInstagramUGCManagementPost.graphql';
import { Mutation, MutationrequestInstagramUGCManagementPostArgs } from '../../__generated__/globalTypes';

export const useRequestInstagramUGCManagementPostMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestInstagramUGCManagementPostArgs>
) => {
  const [callRequestInstagramUGCManagementPost, result] = useMutation<
    Mutation,
    MutationrequestInstagramUGCManagementPostArgs
  >(MUTATION, options);

  return { callRequestInstagramUGCManagementPost, ...result };
};

export type RequestInstagramUGCManagementPostMutationFunction = Mutation['requestInstagramUGCManagementPost'];
