import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { ChatBoxToastMessage } from './ChatBoxToastMessage';

export interface ChatBoxContentProps {
  className?: string;
  children?: ReactNode;
}

export const ChatBoxContent = ({ children, className }: ChatBoxContentProps) => (
  <div css={styles.wrapper} className={className}>
    {children}
    <ChatBoxToastMessage />
  </div>
);

const styles = {
  wrapper: css({
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white
  })
};
