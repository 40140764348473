import { TwitterAnalyticsPostPayload, TwitterAnalyticsPostsByDatePayload } from '@/graphql';
import { THEME } from '@/shared/constants';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface TwitterPostInDateDetailsProps {
  className?: string;
  data: Pick<TwitterAnalyticsPostsByDatePayload, 'avatar' | 'followers' | 'username'> & TwitterAnalyticsPostPayload;
}

export const TwitterPostInDateDetails = ({ data, className }: TwitterPostInDateDetailsProps) => {
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = {
    caption: data.content,
    socialMedia: 'TWITTER',
    images: data.images || [],
    postUrl: data.postUrl || '',
    comments: data.comments.map((comment) => ({ content: comment.content, username: comment.posterName })) || [],
    info: {
      avatar: data.avatar,
      name: data.username,
      socialMedia: 'TWITTER',
      postDate: data.postDate,
      followers: data.followers
    },
    statistics: {
      likes: data.like,
      views: data.view,
      replies: data.reply,
      retweets: data.retweet,
      quoteTweet: data.quoteTweet,
      impressions: data.impression,
      engagement: data.engagement
    }
  };

  return (
    <DefaultPostDetailsTemplate
      className={className}
      postDetails={postDetails}
      css={{ width: postDetails.images?.length ? THEME.modal.size.lv3 : THEME.modal.size.lv1 }}
    />
  );
};
