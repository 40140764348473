import { z } from 'zod';
import { SearchFilterDefaults } from '@/shared/validations';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { YoutubeAnalyticsPostsSortOrder, YoutubeAnalyticsComparePostsSortOrder } from '@/graphql';

export interface InfluencerCompareAccountType {
  accountId: number;
  avatarUrl: string;
  channelName: string;
}

export const InfluencerYoutubeCompareFilterSchema = ProfilePostsFilterSchema;

export const InfluencerYoutubeCompareMainSearchSchema = z.object({
  filter: InfluencerYoutubeCompareFilterSchema.default({}),
  sort: SearchFilterDefaults.sort(YoutubeAnalyticsPostsSortOrder).default({}),
  ...SearchFilterDefaults.pageLimit()
});
export const InfluencerYoutubeCompareOtherSearchSchema = z.object({
  filter: InfluencerYoutubeCompareFilterSchema.default({}),
  sort: SearchFilterDefaults.sort(YoutubeAnalyticsComparePostsSortOrder).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type InfluencerYoutubeCompareMainSearchType = z.infer<typeof InfluencerYoutubeCompareMainSearchSchema>;
export type InfluencerYoutubeCompareOtherSearchType = z.infer<typeof InfluencerYoutubeCompareOtherSearchSchema>;
export type InfluencerYoutubeCompareFilterFormValues = z.infer<typeof InfluencerYoutubeCompareFilterSchema>;
