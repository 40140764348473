import { FacebookAccountInfluencerV2, SocialAccountType } from '@/graphql';
import { InfluencersTabType } from '@/shared/types';
import { FacebookInfluencerProfile, FacebookInfluencersFilterFormValues } from './schemaTypes';

export const getInitialFacebookInfluencersFormValues = (
  defaultFilter?: Partial<FacebookInfluencersFilterFormValues>
): Partial<FacebookInfluencersFilterFormValues> => ({ tab: InfluencersTabType.FACEBOOK, ...defaultFilter });

export const formatInfluencerProfile = (infl: FacebookAccountInfluencerV2): FacebookInfluencerProfile => ({
  id: Number(infl.id),
  age: infl.age,
  name: infl.name,
  gender: infl.gender,
  country: infl.country,
  likes: infl.averageLikes,
  avatar: infl.avatar || '',
  status: infl.socialAccountStatus,
  isBrandAccount: infl.isBrandAccount,
  socialAccountId: infl.socialAccountId,
  followers: infl.socialAccountFollowers,
  socialAccountType: SocialAccountType.FACEBOOK,
  engagementRate: infl.socialAccountEngagementRate,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail, caption }) => ({
      thumbNail,
      id: postId,
      influencerId: Number(infl.id),
      caption
    })) || []
});
