import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteInstagramCompareAccountAnalytics.graphql';
import { Mutation, MutationdeleteInstagramCompareAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useDeleteInstagramCompareAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteInstagramCompareAccountAnalyticsArgs>
) => {
  const [callDeleteInstagramCompareAccountAnalytics, result] = useMutation<
    Mutation,
    MutationdeleteInstagramCompareAccountAnalyticsArgs
  >(MUTATION, options);

  return { callDeleteInstagramCompareAccountAnalytics, ...result };
};

export type DeleteInstagramCompareAccountAnalyticsMutationFunction = Mutation['deleteInstagramCompareAccountAnalytics'];
