import { MarketplaceDetail, NewCampaignReportSummary } from '@/graphql';
import { PostReportSummary } from '../../../PostReport';

export interface MarketplaceReportSummaryProps {
  marketplace: MarketplaceDetail;
  data?: NewCampaignReportSummary;
}
export const MarketplaceReportSummary = ({
  data,
  marketplace: { socialMedia, trackingOptions }
}: MarketplaceReportSummaryProps) => (
  <PostReportSummary
    data={data}
    currency={data?.currency}
    socialMedias={[socialMedia]}
    trackingOptions={trackingOptions || []}
  />
);
