import { useEffect } from 'react';
import { useGlobalLayoutProps } from './useGlobalLayoutProps';

export const useCustomHeader = () => {
  const { updateGlobalLayout } = useGlobalLayoutProps();

  useEffect(() => {
    updateGlobalLayout({ hasCustomHeader: true });

    return () => {
      updateGlobalLayout({ hasCustomHeader: false });
    };
  }, []);

  return { updateGlobalLayout };
};
