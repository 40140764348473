import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UnblockInfluencerMarketplace.graphql';
import { Mutation, MutationunblockInfluencerMarketplaceArgs } from '../../__generated__/globalTypes';

export const useUnblockInfluencerMarketplaceMutation = (
  options?: MutationHookOptions<Mutation, MutationunblockInfluencerMarketplaceArgs>
) => {
  const [callUnblockInfluencerMarketplace, result] = useMutation<Mutation, MutationunblockInfluencerMarketplaceArgs>(
    MUTATION,
    options
  );

  return { callUnblockInfluencerMarketplace, ...result };
};

export type UnblockInfluencerMarketplaceMutationFunction = Mutation['unblockInfluencerMarketplace'];
