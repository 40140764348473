import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { MarketplaceCampaignPostStatusForSearch } from '@/graphql';

export const MarketplacePostsFilterSchema = z.object({
  keyword: FilterValidations.string(),
  status: FilterValidations.enum(MarketplaceCampaignPostStatusForSearch)
});
export const MarketplacePostsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(MarketplacePostsFilterSchema, {
    keyword: '',
    status: ''
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type MarketplacePostsSearchSchemaTypes = z.infer<typeof MarketplacePostsSearchSchema>;
export type MarketplacePostsFilterFormValues = z.infer<typeof MarketplacePostsFilterSchema>;
export type MarketplacePostsFilterFormKeys = UnionOfKeys<MarketplacePostsFilterFormValues>;
