import { css, SerializedStyles } from '@emotion/react';
import React, { useRef, useState, useEffect } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDimensions } from '@/shared/hooks';
import { SliderArrow } from './SliderArrow';

export interface ImageSliderProps {
  total: number;
  className?: string;
  slideWidth?: number;
  hasBgImage?: boolean;
  sliderLength?: number;
  children: JSX.Element[];
  currentSlideIdx?: number;
  sliderArrowCss?: SerializedStyles;
}

export const ImageSlider = ({
  total,
  children,
  className,
  hasBgImage,
  sliderArrowCss,
  currentSlideIdx,
  sliderLength = 0,
  slideWidth = 176
}: ImageSliderProps) => {
  const sliderWrapperRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);
  const { width } = useDimensions(sliderWrapperRef);

  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [maxNumberOfCardsToShow, setMaxNumberOfCardsToShow] = useState<number>(0);

  const slidesToScroll = Math.floor(width / slideWidth) - 1; // this should scroll approx on numbers of slides fits into view - 1

  const settings: Settings = {
    className: 'slider variable-width',
    centerMode: false,
    slidesToScroll,
    variableWidth: true,
    infinite: false,
    arrows: false,
    afterChange: (currentSlide: number) => handleChangeSlide(currentSlide)
  };

  const handleChangeSlide = (currentSlide: number) => {
    const leftArrowVisible = currentSlide !== 0;
    const rightArrowVisible = currentSlide <= total - maxNumberOfCardsToShow;
    setShowLeftArrow(leftArrowVisible);
    setShowRightArrow(rightArrowVisible);
  };

  useEffect(() => {
    const maxNumberOfCards = Math.ceil(width / slideWidth);
    setMaxNumberOfCardsToShow(maxNumberOfCards);
    setShowRightArrow(width < total * (slideWidth + 16)); // 16 is slider margin
  }, [width]);

  useEffect(() => {
    if (sliderRef.current && currentSlideIdx !== undefined && sliderLength > 8) {
      sliderRef.current.slickGoTo(currentSlideIdx);
    }
  }, [currentSlideIdx]);

  return (
    <div css={{ position: 'relative' }} ref={sliderWrapperRef} className={className}>
      {showLeftArrow && (
        <SliderArrow
          type="prev"
          css={sliderArrowCss}
          hasBgImage={hasBgImage}
          onClick={() => sliderRef.current?.slickPrev()}
        />
      )}

      <Slider
        {...settings}
        ref={sliderRef}
        css={css({ '& .slick-track': { display: 'flex', width: 'calc(100vw - 340px) !important' } })}
      >
        {React.Children.map(children, (child) => (
          <div css={styles.item}>{child}</div>
        ))}
      </Slider>

      {showRightArrow && (
        <SliderArrow
          type="next"
          css={sliderArrowCss}
          hasBgImage={hasBgImage}
          onClick={() => sliderRef.current?.slickNext()}
        />
      )}
    </div>
  );
};
const styles = {
  item: css({
    marginRight: '16px',
    position: 'relative',
    '&:focus': { outline: 'none' }
  })
};
