import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteInfluencerPackage.graphql';
import { Mutation, MutationdeleteInfluencerPackageArgs } from '../../__generated__/globalTypes';

export const useDeleteInfluencerPackageMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteInfluencerPackageArgs>
) => {
  const [callDeleteInfluencerPackage, result] = useMutation<Mutation, MutationdeleteInfluencerPackageArgs>(
    MUTATION,
    options
  );

  return { callDeleteInfluencerPackage, ...result };
};

export type DeleteInfluencerPackageMutationFunction = Mutation['deleteInfluencerPackage'];
