import {
  ProposalJobDescriptionBreakDownMode,
  EngagementSocialAcccountBudgetInput,
  useBulkUpdateEngagementInfluencersBudgetMutation,
  useBulkUpdateEngagementSocialAccountsBudgetMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { FormAction } from '@/shared/types';
import { useSelectedInfluencersContext } from '../../../SelectedInfluencersContext';
import { EngagementBulkEditFormValues } from '../BulkEditModal';

interface Props {
  engagementId: number;
  selectedInfluencerIds: number[];
  jdMode: ProposalJobDescriptionBreakDownMode;
  selectedAccounts: EngagementSocialAcccountBudgetInput[];
}

export const useBulkUpdateInfluencerAccountsBudget = ({
  jdMode,
  engagementId,
  selectedAccounts,
  selectedInfluencerIds
}: Props) => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { resetViewMode } = useSelectedInfluencersContext();
  const { callBulkUpdateEngagementInfluencersBudget, loading: updatingInfluencerBudget } =
    useBulkUpdateEngagementInfluencersBudgetMutation({
      onCompleted: invalidateRouteLoader
    });
  const { callBulkUpdateEngagementSocialAccountsBudget, loading: updatingSocialAccountsBudget } =
    useBulkUpdateEngagementSocialAccountsBudgetMutation({
      onCompleted: invalidateRouteLoader
    });

  const updateBulkInfluencerAccountsBudget: FormAction<EngagementBulkEditFormValues>['onSubmit'] = async (values) => {
    const netBudget = Number(values.netBudget || 0);
    const influencerCost = Number(values.influencerCost || 0);

    if (!netBudget) {
      return;
    }

    try {
      const input = { netBudget, campaignId: engagementId, influencerCost, profit: netBudget - influencerCost };

      if (jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN) {
        await callBulkUpdateEngagementInfluencersBudget({
          variables: { input: { ...input, influencerIds: selectedInfluencerIds || [] } }
        });
      } else {
        await callBulkUpdateEngagementSocialAccountsBudget({
          variables: {
            input: {
              ...input,
              socialAccounts: selectedAccounts.map(({ influencerId, socialAccountId, socialAccountMedia }) => ({
                influencerId,
                socialAccountId,
                socialAccountMedia
              }))
            }
          }
        });
      }

      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
      resetViewMode();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return {
    updateBulkInfluencerAccountsBudget,
    loading: updatingInfluencerBudget || updatingSocialAccountsBudget
  };
};
