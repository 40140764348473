import { useTranslation } from 'react-i18next';
import { IconNames } from '@/shared/assets';
import { TikTokProductCategoryName, useAllTiktokProductCategoryQuery } from '@/graphql';

const PRODUCT_CATEGORY_ICONS: Record<TikTokProductCategoryName, IconNames> = {
  [TikTokProductCategoryName.LIFESTYLE]: 'lamp-colors',
  [TikTokProductCategoryName.FMCG]: 'shopping-colors',
  [TikTokProductCategoryName.FASHION]: 't-shirt-colors',
  [TikTokProductCategoryName.ELECTRONIC]: 'headphones-colors',
  [TikTokProductCategoryName.BEAUTY]: 'cosmetics-colors',
  [TikTokProductCategoryName.OTHERS]: 'gift-colors'
};

export const useTikTokProductCategories = () => {
  const { t } = useTranslation();
  const { data, loading } = useAllTiktokProductCategoryQuery({ fetchPolicy: 'cache-and-network' });

  const allTiktokProductCategory = data?.allTiktokProductCategory || [];
  const productCategories = allTiktokProductCategory.length
    ? (Object.keys(PRODUCT_CATEGORY_ICONS) as TikTokProductCategoryName[]).map((key) => ({
        ...allTiktokProductCategory.find((product) => product.name === key),
        name: t<string>(`Product.${key}`),
        icon: PRODUCT_CATEGORY_ICONS[key]
      }))
    : [];

  return { loading, productCategories };
};
