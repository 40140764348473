import { createContext, Dispatch, ReactNode, Reducer, useReducer } from 'react';
import { AnalyticsSubscriptionPlanType } from '@/graphql';
import { decodeAuthToken, getToken } from '@/shared/utils';
import { StateType } from '@/shared/types';

export interface ActionType {
  type: 'APP_USER' | 'AUTH_USER' | 'UPDATE_ANALYTICS_HEADER_INFO' | 'LOGOUT' | 'UPDATE_GLOBAL_LAYOUT' | 'PERMISSIONS';
  payload?: Partial<StateType>;
}
interface AdminStoreProviderProps {
  children: ReactNode;
}

export const defaultBrandAccountInfo = {
  country: '',
  brandAccountId: 0,
  brandAccountName: '',
  plan: undefined,
  socialMediaAccounts: { instagram: null, youtube: null, facebookPage: null, twitter: null },
  subscriptionPlan: {
    id: 0,
    type: AnalyticsSubscriptionPlanType.FREE,
    availableFeatures: {
      dashboardEnabled: false,
      hashtagsEnabled: false,
      compareEnabled: false,
      interactionEnabled: false,
      trendEnabled: false
    },
    capabilities: {
      maxCompareAccounts: 0,
      maxHashtags: 0
    }
  }
};

export const initialState: StateType = {
  status: 'idle',
  ok: false,
  name: '',
  userId: null,
  role: null,
  title: '',
  href: '',
  params: {},
  hash: null,
  company: null,
  email: '',
  countryId: '',
  currencyId: '',
  alwaysBackToParent: false,
  backInHistory: undefined,
  globalLayout: {
    hasCustomHeader: false
  },
  brandAccountHeaderInfo: defaultBrandAccountInfo,
  permissions: null
};

export const AdminStore = createContext<{
  state: StateType;
  dispatch: Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null
});
export const AdminStoreProvider = ({ children }: AdminStoreProviderProps) => {
  const tokenFromStorage = getToken();
  const decoded = tokenFromStorage ? decodeAuthToken(tokenFromStorage) : undefined;

  const [state, dispatch] = useReducer<Reducer<StateType, ActionType>>(reducer, {
    ...initialState,
    ...(decoded && { userId: decoded.user_id, role: decoded.role, company: decoded.company })
  });
  const value = { state, dispatch };

  return <AdminStore.Provider value={value}>{children}</AdminStore.Provider>;
};

const reducer = (state: StateType, { type, payload }: ActionType) => {
  switch (type) {
    case 'APP_USER':
    case 'AUTH_USER':
    case 'UPDATE_ANALYTICS_HEADER_INFO':
    case 'PERMISSIONS':
      return {
        ...state,
        ...payload
      };
    case 'LOGOUT':
      return { ...initialState, hash: state.hash };
    case 'UPDATE_GLOBAL_LAYOUT':
      return {
        ...state,
        globalLayout: {
          ...state.globalLayout,
          ...payload?.globalLayout
        }
      };
    default:
      return state;
  }
};
