import { forwardRef } from 'react';
import Markdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { ChatDemoMessageType } from './types';

export interface ChatDemoMarkdownReaderProps {
  value?: ChatDemoMessageType['text'];
}
export const ChatDemoMarkdownReader = forwardRef<HTMLDivElement, ChatDemoMarkdownReaderProps>(({ value }, ref) =>
  value ? (
    <div css={styles.wrapper} ref={ref}>
      <Markdown
        children={value}
        components={{ pre: 'div' as 'pre' }}
        // https://github.com/remarkjs/react-markdown/blob/main/changelog.md#remove-linktarget
        rehypePlugins={[[rehypeExternalLinks, { target: '_blank', rel: 'noopener noreferrer' }]]}
      />
    </div>
  ) : null
);
ChatDemoMarkdownReader.displayName = 'ChatDemoMarkdownReader';

const styles = {
  wrapper: css({
    // Custom html tags to let match with chatbox styles
    '& pre': { margin: 0 },
    '& strong': { fontWeight: 'bold' },
    '& hr': { height: '1px', backgroundColor: 'var(--textColor)' },
    '& ol, ul': { whiteSpace: 'normal', '& strong': { fontWeight: 'normal' } },
    '& h1, h2, h3, h4': { fontSize: '16px', fontWeight: 'bold', lineHeight: '2.0em' },
    '& h5, h6': { fontSize: '16px', fontWeight: 'normal', lineHeight: '2.0em' },
    '& li': {
      listStyle: 'unset',
      listStylePosition: 'inside',
      '& p': { display: 'inline' },
      '&::marker': { marginRight: '4px' }
    },
    '& code': {
      padding: '8px',
      display: 'block',
      borderRadius: '4px',
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
      boxSizing: 'border-box',
      border: '1px solid var(--textColor)'
    },
    '& a': {
      display: 'inline',
      color: THEME.text.colors.blue,
      '&:hover': { textDecoration: 'underline' }
    }
  })
};
