import { CampaignType, MarketplaceDetail, useMarketplaceCampaignReportGridQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';

import { PostReportEmpty, PostReportGrid } from '../../../PostReport';

export interface MarketplaceReportGridProps {
  marketplace: MarketplaceDetail;
}
export const MarketplaceReportGrid = ({ marketplace: { id, trackingOptions } }: MarketplaceReportGridProps) => {
  const { data, loading } = useMarketplaceCampaignReportGridQuery({ variables: { campaignId: id } });

  const gridData = data?.marketplaceCampaignReportGrid || undefined;

  return (
    <RenderDataWithFallback loading={loading} hasNoData={!gridData} noDataNode={<PostReportEmpty />}>
      {gridData ? (
        <PostReportGrid
          data={gridData}
          hasTrackingOption={!!trackingOptions}
          campaignType={CampaignType.MARKETPLACE}
          hideTitle
        />
      ) : null}
    </RenderDataWithFallback>
  );
};
