import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdatePartnerUser.graphql';
import { Mutation, MutationupdatePartnerUserArgs } from '../../__generated__/globalTypes';

export const useUpdatePartnerUserMutation = (
  options?: MutationHookOptions<Mutation, MutationupdatePartnerUserArgs>
) => {
  const [callUpdatePartnerUser, result] = useMutation<Mutation, MutationupdatePartnerUserArgs>(MUTATION, options);

  return { callUpdatePartnerUser, ...result };
};

export type UpdatePartnerUserMutationFunction = Mutation['updatePartnerUser'];
