import { SC } from './StyledComponents';

export const TermsJP = () => (
  <SC.Wrapper>
    <SC.Title css={{ marginBottom: '40px' }}>AnyTag利用規約</SC.Title>

    <SC.Text>
      本利用規約（以下「本規約」といいます。）には、AnyMind Group
      Pte.Ltd.（以下「当社」といいます。）の提供する本サービス（第2条に定義）のご利用にあたり、利用者の皆様に遵守していただかなければならない事項並びに利用者の皆様と当社の間の権利義務関係が定められております。本サービスをご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
    </SC.Text>

    <SC.Head>第1条 適　用</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          本規約は、本サービスの利用に関する登録利用者（第2条に定義）と当社の間の権利義務関係を定めることを目的とし、登録利用者と当社の間の本サービスの利用に関わる一切の関係に適用されます。
        </SC.ListItem>
        <SC.ListItem>
          当社が当社ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
        </SC.ListItem>
        <SC.ListItem>
          本規約の条項と個別サービス契約（第2条に定義）の条項が異なる場合には、個別サービス契約の条項が優先して効力を有するものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第2条 定　義</SC.Head>
    <SC.Text>
      <SC.ListWrapper css={{ paddingLeft: '24px' }}>
        <SC.ListItem idx="(1)">
          「アカウント情報」とは、第12条第1項で定義される「アカウント情報」を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(2)">
          「アナリティクスサービス」とは、第11条第1項で定義される「アナリティクスサービス」を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(3)">
          「アナリティクスサービス契約」とは、第11条第2項で定義される「アナリティクスサービス契約」を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(4)">
          「インフルエンサーキャンペーン」とは、第9条第1項で定義される「インフルエンサーキャンペーン」を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(5)">
          「インフルエンサーキャンペーン契約」とは、第9条第2項で定義される「インフルエンサーキャンペーン契約」
          <br />
          を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(6)">
          「SNS」とは、Facebook、その他の当社以外の事業者が提供している当社所定のソーシャル・ネットワーキング・サービスで、登録利用者の認証、友人関係の開示、当該外部ソーシャル・ネットワーク内へのコンテンツの公開などの機能を持ち、本サービスの実施に利用されるサービスを意味します。
        </SC.ListItem>
        <SC.ListItem idx="(7)">
          「SNSアカウント」とは、SNSにおいて特定の個人又は法人が管理するアカウントを意味します。
        </SC.ListItem>
        <SC.ListItem idx="(8)">「SNS事業者」とは、SNSのサービス提供者を意味します。</SC.ListItem>
        <SC.ListItem idx="(9)">
          「SNS利用規約」とは、登録利用者とSNS事業者との権利関係を定める規約を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(10)">
          「公式アカウント」とは、第10条第1項に定義する「公式アカウント」を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(11)">
          「公式アカウント運用サービス契約」とは、第10条第2項に定義される「公式アカウント運用サービス契約」を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(12)">
          「公式アカウント運用サービス契約」とは、第10条第2項に定義される「公式アカウント運用サービス契約」を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(13)">
          「個別サービス契約」とは、インフルエンサーキャンペーン契約、公式アカウント運用サービス契約及びアナリティクスサービス契約の総称を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(14)">
          「個別サービス契約」とは、インフルエンサーキャンペーン契約、公式アカウント運用サービス契約及びアナリティクスサービス契約の総称を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(15)">
          「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(16)">
          「当社ウェブサイト」とは、そのドメインが「anytag.jp」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(17)">
          「登録希望者」とは、第3条第1項において定義された「登録希望者」を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(18)">「登録情報」とは、第3条第1項において定義された「登録情報」を意味します。</SC.ListItem>
        <SC.ListItem idx="(19)">
          「登録利用者」とは、第3条第4項に基づき本サービスの利用者としての登録がなされた法人を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(20)">
          「本サービス」とは、当社が提供するAnyTagという名称のインフルエンサーを利用したマーケティング等の支援サービス（個別サービスを含み、理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
        </SC.ListItem>
        <SC.ListItem idx="(21)">「利用契約」とは、第3条第4項に定義される「利用契約」を意味します。</SC.ListItem>
      </SC.ListWrapper>
    </SC.Text>

    <SC.Head>第3条 登　録</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
        </SC.ListItem>
        <SC.ListItem>
          登録の申請は必ず本サービスを利用する法人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
        </SC.ListItem>
        <SC.ListItem>
          当社は、登録希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
        </SC.ListItem>
        <SC.ListWrapper css={{ paddingLeft: '20px', marginTop: '10px' }}>
          <SC.ListItem idx="(1)">本規約に違反するおそれがあると当社が判断した場合</SC.ListItem>
          <SC.ListItem idx="(2)">
            当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
          </SC.ListItem>
          <SC.ListItem idx="(3)">過去に本サービスの利用の登録を取り消された者である場合</SC.ListItem>
          <SC.ListItem idx="(4)">その他、当社が登録を適当でないと合理的に判断した場合</SC.ListItem>
        </SC.ListWrapper>

        <SC.ListItem>
          当社は、前項その他当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知します。かかる通知により登録希望者の登録利用者としての登録は完了し、本規約の諸規定に従った本サービスの利用にかかる契約（以下「利用契約」といいます。）が登録利用者と当社の間に成立します。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第4条 本サービスの利用</SC.Head>
    <SC.Text>
      登録利用者は、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
    </SC.Text>

    <SC.Head>第5条 料金</SC.Head>
    <SC.Text>
      本サービスの利用は無料です。但し、登録利用者が個別サービスを受ける場合には、登録利用者は、個別サービス契約の定めに従い、当社に個別サービスの提供の対価を支払うものとします。
    </SC.Text>

    <SC.Head>第6条 業務遂行体制</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者及び当社は、利用契約締結後速やかに、本サービスに関する連絡及び確認を行う主任担当者を定めるとともに、その他必要な業務遂行体制を定め、それぞれ相手方に通知するものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者及び当社は、本サービスに関する要請、指示等の連絡を、原則として前項で定めたそれぞれの主任担当者を通じてのみ行うものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者及び当社は、本サービスに関する要請、指示等の連絡を、原則として前項で定めたそれぞれの主任担当者を通じてのみ行うものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第7条 情報提供等</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者は、当社からの要請に応じ、本サービスの提供に必要なデータを、当社が定める方法により提出するものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、当社からの要請に応じ、本サービスの提供に必要なデータを、当社が定める方法により提出するものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、当社及び第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する内容（かかる侵害を直接又は間接に惹起する内容を含みます。）並びに法令及び公序良俗に反する内容を含むものを前2項に基づき当社に提供してはならないものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者が前項に違反したことにより、当社と第三者の間に紛争が生じ又は当社に損害が生じた場合、登録利用者は自己の責任で当該紛争を解決し、当社に生じた損害を賠償するものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、当社から要請がある場合には、本サービスの提供に関する打ち合わせを開催し、個別サービスの詳細等の本サービスに関する事項について誠意をもって当社と協議するものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、本サービスの実施のために必要なものとして当社から設備、システム等の貸与の要請があった場合には、合理的な条件でこれに応じるものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者が本条に定める義務を怠ったことにより生じた本サービスの提供の遅延等について、当社は責任を負わないものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第8条 再委託</SC.Head>
    <SC.Text>
      当社は、自己の裁量に基づき、本規約に基づき当社が提供するサービスに関する業務の全部又は一部を他の第三者に再委託することができるものとします。
    </SC.Text>

    <SC.Head>第9条 インフルエンサーキャンペーン</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者は、当社に対して、当社所定の方法により、インフルエンサーを利用したキャンペーン業務（以下「インフルエンサーキャンペーン」といいます。）の委託を申込むことができるものとします。
        </SC.ListItem>
        <SC.ListItem>
          前項に基づき登録利用者がインフルエンサーキャンペーンの委託を申込んだ場合、当該登録利用者及び当社の主任担当者は、個別のインフルエンサーキャンペーンのスケジュール、施策の内容その他必要な事項について協議の上、別途個別のインフルエンサーキャンペーンに関する契約（以下「インフルエンサーキャンペーン契約」といいます。）を締結するものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第10条 公式アカウント運用サービス</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者は、登録利用者が希望する場合、当社に対し、登録利用者が管理するSNSアカウント（以下「公式アカウント」といいます。）の運用に関する以下のサービス（以下「公式アカウント運用サービス」といいます。）の提供を申込むことができるものとします。
        </SC.ListItem>

        <SC.ListWrapper css={{ paddingLeft: '20px', marginTop: '10px' }}>
          <SC.ListItem idx="(1)">
            公式アカウントのインサイト情報その他当社が別途定める公式アカウントに関する情報の提供及びその分析結果の提供
          </SC.ListItem>
          <SC.ListItem idx="(2)">公式アカウントの運用計画の立案</SC.ListItem>
          <SC.ListItem idx="(3)">公式アカウントにおいて用いるクリエイティブの製作</SC.ListItem>
          <SC.ListItem idx="(4)">その他登録利用者と当社が合意した事項</SC.ListItem>
        </SC.ListWrapper>

        <SC.ListItem>
          前項に基づき登録利用者が公式アカウント運用サービスの提供を申込んだ場合、当該登録利用者及び当社の主任担当者は、公式アカウント運用サービスの内容その他必要な事項について協議の上、別途公式アカウント運用サービスに関する契約（以下「公式アカウント運用サービス契約」といいます。）を締結するものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、公式アカウント運用サービスの申込みに際し、当社による公式アカウント運用サービスの提供にSNS事業者の同意、公式アカウントの設定の変更その他必要な手続がある場合、当該手続を行うものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第11条 アナリティクスサービス</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者は、登録利用者が希望する場合、当社に対し、公式アカウントへのアクセスに関する情報その他の当社が別途定める情報の解析に関するサービス（以下「アナリティクスサービス」といいます。）の提供を申込むことができるものとします。
        </SC.ListItem>
        <SC.ListItem>
          前項に基づき登録利用者がアナリティクスサービスの提供を申込んだ場合、当該登録利用者及び当社の主任担当者は、アナリティクスサービスの内容その他必要な事項について協議の上、別途アナリティクスサービスに関する契約（以下「アナリティクスサービス契約」といいます。）を締結するものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、アナリティクスサービスの申込みに際し、当社によるアナリティクスサービスの提供にSNS事業者の同意、公式アカウントの設定の変更その他必要な手続がある場合、当該手続を行うものとします。
        </SC.ListItem>
        <SC.ListItem>
          当社は、アナリティクスサービスの対象とする情報及び当該情報の分析方法を、合理的な範囲で変更することができるものとし、登録利用者はこれに異議を述べないものとします。
        </SC.ListItem>
        <SC.ListItem>
          当社は、SNS事業者が提供する情報に基づいてアナリティクスサービスを提供するものとし、SNS事業者が提供する情報の正確性及び真実性その他一切の事項について保証を行うものではありません。当社は、登録利用者がアナリティクスサービスを利用したこと及び分析の結果に基づき行った行為により生じた損害につき、当社の責に帰すべき場合を除き、責任を負いません。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第12条 アカウント情報の管理</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者は、自己の責任において、本サービスにかかるID及びパスワード（以下「アカウント情報」といいます。）を管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
        </SC.ListItem>
        <SC.ListItem>
          アカウント情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録利用者が負うものとし、当社は一切の責任を負いません。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、アカウント情報が盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第13条 禁止行為</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
        </SC.ListItem>

        <SC.ListWrapper css={{ paddingLeft: '20px', marginTop: '10px' }}>
          <SC.ListItem idx="(1)">
            当社、その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
          </SC.ListItem>
          <SC.ListItem idx="(2)">犯罪行為に関連する行為又は公序良俗に反する行為</SC.ListItem>
          <SC.ListItem idx="(3)">法令又は当社若しくは登録利用者が所属する業界団体の内部規則に違反する行為</SC.ListItem>
          <SC.ListItem idx="(4)">
            コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
          </SC.ListItem>
          <SC.ListItem idx="(5)">当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為</SC.ListItem>
          <SC.ListItem idx="(6)">
            本サービスを通じて得た情報を用いて、当社を介さず直接第三者とインフルエンサーキャンペーン及びこれに類似する業務の委託に関する契約を締結し、又は締結しようとする行為（但し、事前に当社の書面による承諾を得た場合を除きます。）
          </SC.ListItem>
          <SC.ListItem idx="(7)">当社による本サービスの運営を妨害するおそれのあると合理的に認められる行為</SC.ListItem>
          <SC.ListItem idx="(8)">その他、当社が不適切と合理的に判断する行為</SC.ListItem>
        </SC.ListWrapper>

        <SC.ListItem>
          当社は、本サービスにおける登録利用者による情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が合理的に判断した場合には、登録利用者に事前に通知することなく、当該情報の全部又は一部について削除、送信停止その他の措置をとることができるものとします。当社は、本項に基づき当社が行った措置に基づき登録利用者に生じた損害について一切の責任を負いません。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第14条 本サービスの停止等</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          当社は、以下のいずれかに該当する場合には、登録利用者に事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
        </SC.ListItem>

        <SC.ListWrapper css={{ paddingLeft: '20px', marginTop: '10px' }}>
          <SC.ListItem idx="(1)">
            本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
          </SC.ListItem>
          <SC.ListItem idx="(2)">コンピューター、通信回線等が事故により停止した場合</SC.ListItem>
          <SC.ListItem idx="(3)">
            火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
          </SC.ListItem>
          <SC.ListItem idx="(4)">
            SNSに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合
          </SC.ListItem>
          <SC.ListItem idx="(5)">その他、当社が停止又は中断を合理的に必要と判断した場合</SC.ListItem>
        </SC.ListWrapper>

        <SC.ListItem>
          当社は、当社の合理的な判断により、本サービスの提供を終了することができます。この場合、当社は登録利用者に事前に通知するものとします。
        </SC.ListItem>
        <SC.ListItem>
          当社は、本条に基づき当社が行った措置に基づき登録利用者に生じた損害について一切の責任を負いません。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第15条 設備の負担等</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          本サービスの提供を受けるために必要な、コンピューター、スマートフォン、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、登録利用者の費用と責任において行うものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
        </SC.ListItem>
        <SC.ListItem>
          当社は、登録利用者が送受信したメッセージその他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第16条 権利帰属</SC.Head>
    <SC.Text>
      当社ウェブサイト及び本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本規約において明示されているものを除き、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありません。登録利用者は、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。
    </SC.Text>

    <SC.Head>第17条 登録取消等</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          当社は、登録利用者が、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該登録利用者について本サービスの利用を一時的に停止し、又は登録利用者としての登録を取り消すことができ、個別サービス契約を解除することができるものとします。
        </SC.ListItem>

        <SC.ListWrapper css={{ paddingLeft: '20px', marginTop: '10px' }}>
          <SC.ListItem idx="(1)">本規約又は個別サービス契約のいずれかの条項に違反した場合</SC.ListItem>
          <SC.ListItem idx="(2)">登録情報に虚偽の事実があることが判明した場合</SC.ListItem>
          <SC.ListItem idx="(3)">
            当社、他の登録利用者、その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
          </SC.ListItem>
          <SC.ListItem idx="(4)">
            SNS利用規約に違反したことその他の理由によって、登録利用者がSNS事業者からサービスの提供を受けられなくなった場合
          </SC.ListItem>
          <SC.ListItem idx="(5)">手段の如何を問わず、本サービスの運営を妨害した場合</SC.ListItem>
          <SC.ListItem idx="(6)">
            支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
          </SC.ListItem>
          <SC.ListItem idx="(7)">
            自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
          </SC.ListItem>
          <SC.ListItem idx="(8)">差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合</SC.ListItem>
          <SC.ListItem idx="(9)">租税公課の滞納処分を受けた場合</SC.ListItem>
          <SC.ListItem idx="(10)">3ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合</SC.ListItem>
          <SC.ListItem idx="(11)">第3条第3項各号に該当する場合</SC.ListItem>
          <SC.ListItem idx="(12)">
            その他、当社が登録利用者としての登録の継続を適当でないと合理的に判断した場合
          </SC.ListItem>
        </SC.ListWrapper>

        <SC.ListItem>
          前項各号のいずれかの事由に該当した場合、登録利用者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、7日前までに当社所定の方法で当社に通知することにより、登録利用者の登録を取り消すことができます。
        </SC.ListItem>
        <SC.ListItem>
          当社は、本条に基づき当社が行った行為により登録利用者に生じた損害について一切の責任を負いません。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者の登録が取り消された場合、登録利用者は、当社の指示に基づき、当社から提供を受けた本サービスに関連するソフトウェア、マニュアルその他の物につき、返還、廃棄その他の処分を行うものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第18条 保証の否認及び免責</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          当社は、登録利用者の売上の上昇、知名度の向上その他の本サービスを利用したことによる効果につき如何なる保証も行うものではありません。本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証をいたしません。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者が、当社から直接又は間接に、本サービス、当社ウェブサイトその他の事項に関する何らかの情報を得た場合であっても、当社は登録利用者に対し本規約において規定されている内容を超えて如何なる保証も行うものではありません
        </SC.ListItem>
        <SC.ListItem>
          本サービスは、SNSとの連携を保証するものではなく、SNSとの連携の支障等について、当社の責に帰すべき場合を除き、当社は一切の責任を負いません。
        </SC.ListItem>
        <SC.ListItem>
          本サービスがSNSと連携している場合において、登録利用者はSNS利用規約を自己の費用と責任で遵守するものとし、その違反によって登録利用者と当該SNSを運営するSNS事業者との間で紛争等が生じた場合でも、当社は、当社の責めに帰すべき場合を除き、当該紛争等について一切の責任を負いません。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、本サービスを利用すること並びに個別サービスの委託及びその内容が、登録利用者に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、登録利用者による本サービスの利用並びに個別サービスの委託及びその内容が、登録利用者に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません
        </SC.ListItem>
        <SC.ListItem>
          登録利用者と当社の再委託先その他の第三者との間において生じた紛争等については、登録利用者の責任において処理及び解決するものとし、当社の責に帰すべき場合を除き、当社は当該紛争等に関して一切責任を負いません。
        </SC.ListItem>
        <SC.ListItem>
          当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、登録利用者のメッセージ又は情報の削除又は消失､登録利用者の登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して登録利用者が被った損害につき、当社の責に帰すべき場合を除き、賠償する責任を一切負わないものとします。
        </SC.ListItem>
        <SC.ListItem>
          当社は、当社の合理的な支配の及ばない状況（火事、停電、ハッキング、コンピューター・ウィルスの侵入、地震、洪水、戦争、疫病、通商停止、ストライキ、暴動、物資及び輸送施設の確保不能、政府当局若しくは地方自治体による介入、指示若しくは要請、又は内外法令の制定若しくは改廃を含みますがこれらに限定されません。）により利用契約及び個別サービス契約上の義務を履行できない場合、その状態が継続する期間中登録利用者に対し債務不履行責任を負わないものとします。
        </SC.ListItem>
        <SC.ListItem>
          何らかの理由により、当社が登録利用者に対して損害賠償責任を負う場合においても、当社の賠償責任は、当該賠償責任に関する個別サービス契約に基づき当社が受領した対価の額を上限とします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第19条 賠償等の責任</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者及び当社は、本規約若しくは個別サービス契約に違反することにより、又は本サービスの利用に関連して相手方に損害を与えた場合、相手方に対しその損害を賠償するものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者は、本サービスに関連して第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第20条 秘密保持</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          本規約において「秘密情報」とは、利用契約又は本サービスに関連して、登録利用者又は当社が、相手方より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し、(1)提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)相手方から秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者及び当社は、秘密情報を本サービスの利用の目的のみに利用するとともに、相手方の書面による承諾なしに第三者（当社の関係会社を除きます。）に秘密情報を提供、開示又は漏洩しないものとします。
        </SC.ListItem>
        <SC.ListItem>
          第2項の定めにかかわらず、登録利用者及び当社は、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を相手方に通知するものとします。
        </SC.ListItem>
        <SC.ListItem>
          第2項の定めにかかわらず、当社は、第8条に基づく当社の業務の再委託先に対し、再委託に必要な範囲で秘密情報を開示することができるものとします。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者及び当社は、相手方から求められた場合にはいつでも、遅滞なく、相手方の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄するものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第21条 反社会的勢力の排除</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者及び当社は、相手方が反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団員又は暴力団準構成員でなくなった日から5年を経過しない者、暴力団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団その他暴力、威力又は詐欺的手法を使用して経済的利益を追求する集団又は個人を意味します。以下同じ。）であることが判明したときには、催告を要せず相手方に書面で通知することにより直ちに利用契約及び個別サービス契約を解除することができます。
        </SC.ListItem>
        <SC.ListItem>
          登録利用者及び当社は、相手方が自ら又は第三者を利用して以下の各号の一にでも該当する行為をした場合には、催告を要せず相手方に書面で通知することにより直ちに利用契約及び個別サービス契約を解除することができるものとします。
        </SC.ListItem>

        <SC.ListWrapper css={{ paddingLeft: '20px', marginTop: '10px' }}>
          <SC.ListItem idx="(1)">暴力的な要求行為</SC.ListItem>
          <SC.ListItem idx="(2)">法的な責任を超えた不当な要求行為</SC.ListItem>
          <SC.ListItem idx="(3)">取引に関して、脅迫的な言動をし、又は暴力を用いる行為</SC.ListItem>
          <SC.ListItem idx="(4)">
            風説を流布し、偽計若しくは威力を用いて信用を毀損し、又は業務を妨害する行為
          </SC.ListItem>
          <SC.ListItem idx="(5)">その他前各号に準ずる行為</SC.ListItem>
        </SC.ListWrapper>

        <SC.ListItem>
          前各項に定める場合を除き、登録利用者及び当社は、相手方の取締役、監査役、従業員その他の構成員、株主、取引先、若しくは顧問その他のアドバイザーが反社会的勢力であること、又は相手方が資金提供その他を通じて反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力との何らかの交流若しくは関与を行っていることが判明した場合は、相手方に書面で通知することにより直ちに利用契約及び個別サービス契約を解除することができるものとします。
        </SC.ListItem>
        <SC.ListItem>
          本条に基づき利用契約又は個別サービス契約を解除した当事者は、当該取消しにより相手方に生じた損害の賠償責任を負わないものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第22条 個人情報等の取扱い</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          当社による登録利用者から提供された個人情報（個人情報の保護に関する法律第2条第1項に定める「個人情報」を意味します。）の取扱いについては、別途定める当社のプライバシーポリシーの定めによるものとし、登録利用者はこのプライバシーポリシーに従って当社が登録利用者の個人情報を取扱うことについて同意するものとします。
        </SC.ListItem>
        <SC.ListItem>
          当社は、登録利用者が当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録利用者はこれに異議を述べないものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第23条 有効期間</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          利用契約は、登録利用者について第3条に基づく登録が完了した日に効力を生じ、当該登録利用者の登録が取り消された日又は本サービスの提供が終了した日のいずれか早い日まで、当社と登録利用者との間で有効に存続するものとします。
        </SC.ListItem>
        <SC.ListItem>
          利用契約が終了した場合であっても、個別サービス契約は、当該個別サービス契約所定の有効期間中有効に存続するものとし、本規約の定めは当該個別サービス契約に関する限りにおいて、当該個別サービス契約の有効期間中効力を有するものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第24条 本規約等の変更</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>当社は、本サービスの内容を自由に変更できるものとします。</SC.ListItem>
        <SC.ListItem>
          当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更する場合には、変更の内容及び変更の効力発生時期を、当該効力発生時期までに当社所定の方法で告知するものとします。告知された効力発生時期以降に登録利用者が本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、登録利用者は、本規約の変更に同意したものとみなします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第25条 連絡/通知</SC.Head>
    <SC.Text>
      本サービスに関する問い合わせその他登録利用者から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から登録利用者に対する連絡又は通知は、当社の定める方法で行うものとします。
    </SC.Text>

    <SC.Head>第26条 本規約の譲渡等</SC.Head>
    <SC.Text>
      <SC.OrderedListWrapper>
        <SC.ListItem>
          登録利用者は、当社の書面による事前の承諾なく、利用契約及び個別サービス契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
        </SC.ListItem>
        <SC.ListItem>
          当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約及び個別サービス契約上の地位、本規約に基づく権利及び義務並びに登録利用者の登録情報その他の顧客情報を当該譲渡の譲受人に譲渡することができるものとし、登録利用者は、かかる譲渡につき本項において予め同意したものとします。
        </SC.ListItem>
      </SC.OrderedListWrapper>
    </SC.Text>

    <SC.Head>第27条 完全合意</SC.Head>
    <SC.Text>
      本規約は、本規約に含まれる事項に関する当社と登録利用者との完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社と登録利用者との事前の合意、表明及び了解に優先します。
    </SC.Text>

    <SC.Head>第28条 分離可能性</SC.Head>
    <SC.Text>
      本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び登録利用者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
    </SC.Text>

    <SC.Head>第29条 存続規定</SC.Head>
    <SC.Text>
      第5条（未払がある場合に限ります。）、第7条第4項及び第7項、第11条第4項及び第5項、第12条第2項、第13条第2項、第14条第3項、第15条、第16条、第17条第2項、第4項及び第5項、第18条から第20条まで、第21条第4項、第22条、並びに第26条から第30条までの規定は利用契約の終了後も有効に存続するものとします。但し、第20条については、利用契約終了後5年間に限り存続するものとします。
    </SC.Text>

    <SC.Head>第30条 準拠法及び管轄裁判所　</SC.Head>
    <SC.Text>
      本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
    </SC.Text>

    <SC.Head>第31条 協議解決</SC.Head>
    <SC.Text>
      登録利用者及び当社は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
    </SC.Text>

    <SC.Text css={{ marginTop: '40px', textAlign: 'end' }}>【2021年7月1日制定】</SC.Text>
  </SC.Wrapper>
);
