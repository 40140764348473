import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { formatIntNumber, formatNumber } from '@/shared/utils';

interface SummaryType {
  name: string;
  unit?: string;
  value?: number | null;
  engagementValue?: number | null;
  marketplaceValue?: number | null;
  autoManagedValue?: number | null;
}

export interface CampaignsListSummaryProps {
  summaries: SummaryType[];
  hideMetrics?: { engagement?: boolean; marketplace?: boolean; autoManaged?: boolean };
  className?: string;
}
export const CampaignsListSummary = ({ summaries, hideMetrics, className }: CampaignsListSummaryProps) => {
  const { t } = useTranslation();

  const renderMetric = (
    title: string,
    color: string,
    value?: number | null,
    unit?: string,
    formatValue: (n: number | null | undefined) => string = formatIntNumber
  ) => (
    <div css={styles.row}>
      <div css={[styles.campaignTitle, { color }]}>{title}</div>
      {unit ? (
        <div css={styles.unitWrapper}>
          <div css={styles.value}>{formatValue(value)}</div>
          <div css={styles.unit}>{unit}</div>
        </div>
      ) : (
        <div css={styles.value}>{value || '-'}</div>
      )}
    </div>
  );

  return (
    <div css={[styles.wrapper, THEME.shadows.scroll]} className={className}>
      {summaries.map(({ name, value, unit, engagementValue, marketplaceValue, autoManagedValue }) => (
        <div key={name} css={styles.itemWrapper}>
          <div css={{ fontSize: '11px', fontWeight: 600, lineHeight: '20px' }}>{name}</div>

          {value ? (
            <div css={[styles.value, { marginTop: '12px' }]}>{formatIntNumber(value)}</div>
          ) : (
            <>
              {!hideMetrics?.engagement
                ? renderMetric(t('Engagement'), THEME.campaign.engagement.color, engagementValue, unit, formatIntNumber)
                : null}

              {!hideMetrics?.marketplace
                ? renderMetric(t('Marketplace'), THEME.campaign.marketplace.color, marketplaceValue, unit, formatNumber)
                : null}

              {!hideMetrics?.autoManaged
                ? renderMetric(
                    t('Auto-managed'),
                    THEME.campaign.autoManaged.color,
                    autoManagedValue,
                    unit,
                    formatNumber
                  )
                : null}
            </>
          )}
        </div>
      ))}
    </div>
  );
};
const styles = {
  wrapper: css({
    display: 'flex',
    padding: '24px',
    overflow: 'auto',
    borderRadius: '3px',
    boxSizing: 'border-box',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  }),
  itemWrapper: css({
    fontWeight: 600,
    padding: '0 15px',
    marginRight: '1vw',
    color: THEME.text.colors.black.lv1,
    borderLeft: '2px solid #dee5ec'
  }),
  row: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  campaignTitle: css({
    fontWeight: 600,
    fontSize: '11px',
    marginRight: '8px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal'
  }),
  value: css({ fontSize: '20px', fontWeight: 600 }),
  unitWrapper: css({ display: 'flex', alignItems: 'baseline' }),
  unit: css({ marginLeft: '6px', fontSize: '10px' })
};
