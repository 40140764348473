import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '@/shared/utils';
import { RangeSliderInputField } from '@/shared/molecules';
import { MIN_JOINED_CAMPAIGNS, MAX_JOINED_CAMPAIGNS, MIN_MAX_JOINED_CAMPAIGN_OPTIONS } from '@/shared/constants';
import { InfluencersFilterFormKeys } from '../types';

export const CampaignJoinedField = () => {
  const { t } = useTranslation();

  return (
    <RangeSliderInputField<InfluencersFilterFormKeys>
      step={1}
      formatLabel={formatIntNumber}
      options={MIN_MAX_JOINED_CAMPAIGN_OPTIONS}
      title={t('Number of campaigns participated')}
      range={{ min: MIN_JOINED_CAMPAIGNS, max: MAX_JOINED_CAMPAIGNS }}
      minField={{ name: 'minCampaignsJoined', placeholder: formatIntNumber(MIN_JOINED_CAMPAIGNS) }}
      maxField={{ name: 'maxCampaignsJoined', placeholder: `${formatIntNumber(MAX_JOINED_CAMPAIGNS)}+` }}
    />
  );
};
