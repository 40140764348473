import { useTranslation } from 'react-i18next';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { useYoutubeAnalyticsCommentsQuery } from '@/graphql';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { ProfilePostsFilter, AnalyticsYoutubeCommentsList } from '@/shared/organisms';
import { useInitialAnalyticsPosts } from '../hooks';
import { AnalyticsYoutubeCommentsSearchSchemaType } from './schemaTypes';

export const AnalyticsYoutubeComments = () => {
  const { t } = useTranslation();
  const { brandAccountId, mainSocialAccountId } = useInitialAnalyticsPosts({
    socialMedia: 'youtube',
    document: (name) => ({
      title: name ? 'appHeader.IGCommentListWithAccountName' : 'Comment List',
      appHeader: name ? 'appHeader.IGCommentListWithAccountName' : 'Comment List'
    })
  });
  const { page, limit, filter, setFilter } = useParamsSearch<AnalyticsYoutubeCommentsSearchSchemaType>();
  const { data, previousData, loading } = useYoutubeAnalyticsCommentsQuery({
    skip: !brandAccountId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      keyword: filter.keyword,
      offset: getOffset(page, limit),
      youtubeAnalyticsAccountId: mainSocialAccountId
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.youtubeAnalyticsCommentsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} keywordPlaceholder={t<string>('Search.keywords')} />

      <AnalyticsYoutubeCommentsList
        loading={loading}
        totalRecords={totalRecords}
        data={result?.youtubeAnalyticsComments.comments || []}
      />
    </ListWithPagination>
  );
};
