import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { SocialAccountType } from '@/graphql';
import { defaultAvatarImage } from '@/shared/assets';
import { Anchor, SocialIcon, MediaPreview, SocialIconProps, MediaPreviewProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';

export interface InfluencerAvatarProps extends Pick<MediaPreviewProps, 'src' | 'objectFit'> {
  id?: number | string | null;
  size?: string;
  asChild?: boolean;
  className?: string;
  disabled?: boolean;
  src?: string | null;
  isBrandAccount?: boolean;
  socialAccountType?: SocialAccountType | '';
  search?: {
    filter?: {
      id: string;
      sm?: SocialAccountType;
    };
  };
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const InfluencerAvatar = ({
  id,
  src,
  asChild,
  disabled,
  search,
  className,
  objectFit = 'contain',
  size = '32px',
  isBrandAccount,
  socialAccountType,
  onClick,
  ...restProps
}: InfluencerAvatarProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const profileLink = generateProfileLink(id);

  const Comp = asChild ? Slot : Anchor;

  return (
    <Comp
      {...profileLink}
      search={search}
      disabled={disabled || (!id && !onClick)}
      css={styles.anchor(size)}
      className={className}
      onClick={onClick}
      {...restProps}
    >
      {/* if asChild=true then Comp props will pass into Slottable */}
      <Slottable>
        <MediaPreview
          src={src}
          alt="avatar"
          objectFit={objectFit}
          defaultImage={defaultAvatarImage}
          css={{ width: size, height: size, backgroundColor: 'inherit' }}
        />
      </Slottable>
      {socialAccountType ? (
        <div css={styles.socialIconWrapper}>
          <SocialIcon size="16px" type={socialAccountType as SocialIconProps['type']} css={{ display: 'block' }} />
        </div>
      ) : null}
      {isBrandAccount && <div css={styles.brandBadge}>{t('Option.Brand')}</div>}
    </Comp>
  );
};

const styles = {
  anchor: (size: string) =>
    css({
      flex: 'none',
      position: 'relative',
      borderRadius: '5px',
      backgroundColor: THEME.background.colors.white,
      boxSizing: 'border-box',
      width: size,
      height: size
    }),
  socialIconWrapper: css({
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '4px',
    borderRadius: '4px 0 5px 0',
    backgroundColor: THEME.background.colors.white
  }),
  brandBadge: css({
    left: 0,
    bottom: 0,
    width: '100%',
    fontSize: '10px',
    lineHeight: '14px',
    padding: '2px',
    position: 'absolute',
    textAlign: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#27313b',
    borderRadius: '0 0 5px 5px',
    color: THEME.text.colors.white
  })
};
