import { z } from 'zod';
import { CampaignTrackingOption, EngagementCampaignStatus } from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';
import { BaseValidations } from '@/shared/validations';

const baseSchema = z.object({
  id: z.number().optional(),
  couponList: z.string(),
  materials: z.array(z.string()).readonly(),
  oldCouponList: z.string(),
  newCouponList: z.string(),
  isTtcmCampaign: z.boolean(),
  hubspotDealIds: z.array(z.string()).readonly(),
  serviceInformation: z.string(),
  couponUploadFiles: z.array(z.string()).readonly(),
  needToSendCurrency: z.boolean().optional(),
  campaignMaterialLink: z.string(),
  agencyMarginRate: z.string(),
  defaultPostRequirement: z.string().optional(),
  numberOfInfluencers: z.string().optional(),
  status: z.nativeEnum(EngagementCampaignStatus),
  budget: BaseValidations.BudgetSchema,
  serviceUrl: BaseValidations.UrlSchema.optional(),
  thumbnail: z.string().optional(),
  title: BaseValidations.CampaignTitleSchema,
  countryIds: BaseValidations.CountryIdsSchema,
  trackingOptions: z.array(z.nativeEnum(CampaignTrackingOption)).nullable().readonly(),
  objective: BaseValidations.CampaignDetails,
  socialMedias: BaseValidations.SocialMediasSchema.readonly(),
  advertiser: BaseValidations.AdvertiserSchema,
  campaignCategoryId: BaseValidations.CampaignCategorySchema,
  currency: z.string().optional(),
  landingPageUrl: z.string().optional(),
  period: BaseValidations.PeriodWithStartDateRequiredSchema,
  salesPics: z.array(z.string()),
  influencerManagementPics: z.array(z.string())
});
const adminOrStaffSchema = baseSchema.extend({
  isAdminStaff: z.literal(true),
  salesPics: BaseValidations.SalesPicsSchema,
  influencerManagementPics: BaseValidations.InfluencerManagementPicsSchema
});

export const EggCampaignFormSchema = z
  .discriminatedUnion('isAdminStaff', [adminOrStaffSchema, baseSchema.extend({ isAdminStaff: z.literal(false) })])
  .superRefine((values, ctx) => {
    if (values.countryIds.length > 1 && !values.currency?.length) {
      ctx.addIssue({
        path: ['currency'],
        code: z.ZodIssueCode.custom,
        message: AllTranslationKeys.Error.requiredCurrencyMessage
      });
    }
    if (values.trackingOptions?.includes(CampaignTrackingOption.TUNE) && !values.landingPageUrl?.length) {
      ctx.addIssue({
        path: ['landingPageUrl'],
        code: z.ZodIssueCode.custom,
        message: AllTranslationKeys.Error.requiredLandingPageUrlMessage
      });
    }
    if (values.status !== EngagementCampaignStatus.FINISHED && !values.period.endDate && !values.period.startDate) {
      ctx.addIssue({
        path: ['startDate'],
        code: z.ZodIssueCode.custom,
        message: AllTranslationKeys.Error.requiredPeriodMessage
      });
    }
  });
