import { ReactNode } from 'react';

import { THEME } from '@/shared/constants';

export interface TTCMBadgeProps {
  className?: string;
  children?: ReactNode;
}

export const TTCMBadge = ({ className, children }: TTCMBadgeProps) => (
  <div
    css={{
      fontWeight: 600,
      fontSize: '10px',
      marginLeft: '4px',
      padding: '1px 4px',
      borderRadius: '1px',
      color: THEME.text.colors.white,
      background: 'linear-gradient(102.99deg, #00f2ea 0%, #ff004f 101.56%)'
    }}
    className={className}
  >
    {children}
  </div>
);
