import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useTiktokAdAccountsQuery, useTiktokAdCampaignsQuery, useTiktokAdGroupsQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';
import { THEME } from '@/shared/constants';
import { Anchor, Icon } from '@/shared/atoms';
import { SparkAdsSettingsFormValues } from '../types';

export const useTikTokSparkAdForm = (values: SparkAdsSettingsFormValues, id?: string) => {
  const { t } = useTranslation();
  const tkSpecialCampaignId = id || '';
  const { data: adData } = useTiktokAdAccountsQuery({
    variables: {
      tkSpecialCampaignId
    }
  });
  const { data: campaignData } = useTiktokAdCampaignsQuery({
    skip: !values.adAccountSystemId || !tkSpecialCampaignId,
    fetchPolicy: 'no-cache',
    variables: {
      tkSpecialCampaignId,
      adAccountSystemId: values.adAccountSystemId
    }
  });
  const { data: adGroupData } = useTiktokAdGroupsQuery({
    skip: !values.tkCampaignId || !tkSpecialCampaignId,
    fetchPolicy: 'no-cache',
    variables: {
      tkSpecialCampaignId,
      adAccountSystemId: values.adAccountSystemId,
      adCampaignId: values.tkCampaignId
    }
  });

  return {
    allAdAccountsOptions: getOptions(adData?.tiktokAdAccounts?.map((el) => ({ ...el, id: el.systemId }))),
    allAdCampaignsOptions: [
      ...getOptions(campaignData?.tiktokAdCampaigns),
      {
        label: (
          <Anchor target="_blank" href={`https://ads.tiktok.com/i18n/perf/campaign?aadvid=${values.adAccountSystemId}`}>
            <div css={styles.linkOption}>
              <span>{t('Button.Create New')}</span>
              <Icon icon="external-link" size={12} color={THEME.text.colors.gray.lv3} style={{ height: '14px' }} />
            </div>
          </Anchor>
        ),
        value: 'external link'
      }
    ],
    allAdGroupsOptions: getOptions(adGroupData?.tiktokAdGroups)
  };
};

const styles = {
  linkOption: css({
    height: '32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '8px 10px',
    boxSizing: 'border-box',
    '&:hover': {
      svg: {
        color: THEME.text.colors.blue
      }
    }
  })
};
