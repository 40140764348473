import { AnalyticsSubscriptionPlanType } from '@/graphql';
import { AppLanguage, DropdownOption, Language } from '@/shared/types';

export const LANGUAGE_OPTIONS: DropdownOption<AppLanguage>[] = [
  { value: Language.en, label: 'English' },
  { value: Language.ja, label: '日本語' },
  { value: Language.cn, label: '简体中文' },
  { value: Language.tw, label: '繁體中文' },
  { value: Language.ko, label: '한국어' }
];

export const ANALYTICS_SUBSCRIPTION_TRANSLATION_KEYS: Record<keyof typeof AnalyticsSubscriptionPlanType, string> = {
  [AnalyticsSubscriptionPlanType.FREE]: 'Option.Free',
  [AnalyticsSubscriptionPlanType.TRIAL]: 'Trial',
  [AnalyticsSubscriptionPlanType.BASIC_V2]: 'Option.Basic',
  [AnalyticsSubscriptionPlanType.BASIC]: 'Option.Basic',
  [AnalyticsSubscriptionPlanType.BASIC_PLUS]: 'Option.Basic +',
  [AnalyticsSubscriptionPlanType.BASIC_PLUS_PLUS]: 'Option.Basic ++',
  [AnalyticsSubscriptionPlanType.ENTERPRISE_V2]: 'Option.Enterprise',
  [AnalyticsSubscriptionPlanType.ENTERPRISE]: 'Option.Enterprise',
  [AnalyticsSubscriptionPlanType.ENTERPRISE_PLUS]: 'Option.Enterprise +',
  [AnalyticsSubscriptionPlanType.ENTERPRISE_PLUS_PLUS]: 'Option.Enterprise ++'
};
