import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useToggleState } from '@/shared/hooks';
import { DeleteHashtagModal } from '@/shared/molecules';
import { Icon, Popover, TextCutter, DropdownMenu } from '@/shared/atoms';
import { UGCHashtagsType } from './types';

interface HashtagProps extends UGCHashtagsType {
  active?: boolean;
  onClick?: () => void;
  onDelete?: (id: string, callback: () => void) => void;
}

export const Hashtag = ({ id, title, label, active, onClick, onDelete }: HashtagProps) => {
  const { t } = useTranslation();
  const deleteModal = useToggleState();

  return (
    <div css={{ position: 'relative' }}>
      {onDelete ? (
        <Popover
          position="bottom-start"
          target={({ setOpen }) => (
            <div
              css={styles.iconWrapper(active)}
              onClick={() => {
                if (!active) {
                  setOpen(true);
                }
              }}
            >
              <Icon icon="more-vertical" size={13} />
            </div>
          )}
        >
          {({ setOpen }) => (
            <DropdownMenu
              css={{ position: 'relative', margin: 0 }}
              options={[
                {
                  value: '',
                  label: (
                    <div
                      css={{ width: '100%' }}
                      onClick={() => {
                        setOpen(false);
                        deleteModal.open();
                      }}
                    >
                      {t<string>('Delete')}
                    </div>
                  )
                }
              ]}
            />
          )}
        </Popover>
      ) : null}

      <div css={styles.wrapper(active)} onClick={onClick}>
        {title ? (
          <TextCutter
            text={title}
            lines={1}
            css={{ color: active ? '#3892e5' : '#27313B', fontWeight: 600, marginBottom: '16px' }}
          />
        ) : null}

        <TextCutter text={label} lines={2} />
      </div>

      {onDelete ? (
        <DeleteHashtagModal
          hashtag={`#${label}`}
          open={deleteModal.status}
          onClose={deleteModal.close}
          onDelete={() => onDelete(id, deleteModal.close)}
        />
      ) : null}
    </div>
  );
};
const styles = {
  wrapper: (active?: boolean) =>
    css({
      flex: 'none',
      width: '112px',
      padding: '4px',
      height: '112px',
      display: 'flex',
      fontSize: '12px',
      cursor: 'pointer',
      textAlign: 'center',
      borderRadius: '3px',
      alignItems: 'center',
      position: 'relative',
      flexDirection: 'column',
      boxSizing: 'border-box',
      justifyContent: 'center',
      color: active ? '#3892e5' : '#6e7c89',
      backgroundColor: active ? '#ebf4fc' : '#fff',
      border: active ? '1px solid #3892e5' : '1px solid #EEF3F7',
      transition: 'background-color 0.3s ease, border-color 0.3s ease',
      ...(!active ? { '&:hover': { borderColor: '#dee5ec', backgroundColor: '#ebf4fc' } } : {})
    }),
  iconWrapper: (disabled?: boolean) =>
    css({
      zIndex: 2,
      top: '6px',
      right: '2px',
      flex: 'none',
      width: '16px',
      height: '16px',
      border: 'none',
      display: 'flex',
      fontWeight: 600,
      fontSize: '22px',
      textAlign: 'right',
      position: 'absolute',
      backgroundColor: 'transparent',
      cursor: disabled ? 'not-allowed' : 'pointer',
      '&:hover': { opacity: 0.8 }
    })
};
