import { z } from 'zod';
import { InstagramReelPostSortField } from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { FilterValidations } from '@/shared/validations';

export const InfluencerInstagramReelPostsListSearchSchema = z.object({
  filter: z
    .object({
      name: z.string().optional(),
      keyword: z.string().optional()
    })
    .default({}),
  sort: FilterValidations.sort(InstagramReelPostSortField).default({ field: null, order: null }),
  page: z.number().default(1),
  limit: z.number().default(LIMIT)
});

export type InfluencerInstagramReelPostsListSearchType = z.infer<typeof InfluencerInstagramReelPostsListSearchSchema>;
