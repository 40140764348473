import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from './Button';

export type UploadButtonProps = ButtonProps;

export const UploadButton = ({ loading, ...restProps }: UploadButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="white"
      css={{ minWidth: '133px' }}
      title={t('Button.CSV Upload')}
      prefixIcon={{ icon: 'upload', size: '14px' }}
      {...restProps}
    />
  );
};
