import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { TiktokSpecialCampaignReportSummary } from '@/graphql';
import { formatIntNumber, formatPercent } from '@/shared/utils';
import { ReportSummary, ReportSummaryType } from '@/shared/atoms';

export interface TikTokCampaignReportSummaryProps {
  data?: TiktokSpecialCampaignReportSummary | null;
}
export const TikTokCampaignReportSummary = ({ data }: TikTokCampaignReportSummaryProps) => {
  const { t } = useTranslation();

  const summaries: ReportSummaryType[] = [
    { label: t<string>('total post'), value: formatIntNumber(data?.postCount) },
    { label: t<string>('HeaderColumn.Engagement'), value: formatIntNumber(data?.engagement) },
    { label: t<string>('HeaderColumn.Engagement Rate'), value: formatPercent(data?.engagementRate, false), unit: '%' },
    { label: t<string>('HeaderColumn.Like'), value: formatIntNumber(data?.likes) },
    { label: t<string>('HeaderColumn.Comment'), value: formatIntNumber(data?.comments) },
    { label: t<string>('HeaderColumn.Share'), value: formatIntNumber(data?.shares) }
  ];

  return <ReportSummary summaries={summaries} css={css({ '> div': { padding: '0 46px 0 8px' } })} />;
};
