import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@/shared/atoms';

export const RefreshProfileButton = ({ disabled, ...restProps }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="white"
      disabled={disabled}
      title={t('Button.Refresh Profile')}
      prefixIcon={{ icon: 'reload', size: '14px' }}
      tooltip={{ help: t<string>('Tooltip.RefreshProfileHelp'), placement: 'bottom', disabled }}
      {...restProps}
    />
  );
};
