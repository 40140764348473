import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeCompareTagRanking.graphql';
import { Query, QueryyoutubeCompareTagRankingArgs } from '../../__generated__/globalTypes';

export const useYoutubeCompareTagRankingQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeCompareTagRankingArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeCompareTagRankingArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeCompareTagRankingLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeCompareTagRankingArgs>
): QueryResult<Query, QueryyoutubeCompareTagRankingArgs> & {
  getYoutubeCompareTagRanking: LazyQueryExecFunction<Query, QueryyoutubeCompareTagRankingArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeCompareTagRanking, result] = useLazyQuery<Query, QueryyoutubeCompareTagRankingArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeCompareTagRanking, ...result };
};

export const useYoutubeCompareTagRankingPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeCompareTagRanking = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeCompareTagRankingArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeCompareTagRankingArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeCompareTagRanking };
};
