import { useParams } from '@tanstack/react-router';
import { useYoutubeComparePostsQuery, YoutubeComparePosts } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { HAS_NO_SELECTED_ACCOUNTS } from '@/shared/constants';
import { AnalyticsPostsOverview } from '../../../shared';
import { AnalyticsCompareYoutubeAccountsProps } from '../AnalyticsCompareYoutubeAccounts';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { Template } from './Template';

export interface AnalyticsCompareYoutubePostsProps
  extends Pick<
    AnalyticsCompareYoutubeAccountsProps,
    'accounts' | 'mainAccountId' | 'hiddenAccountIds' | 'hasFreePlan'
  > {
  brandAccountName?: string;
  postsDataMock: YoutubeComparePosts;
}

export const AnalyticsCompareYoutubePosts = ({
  accounts,
  mainAccountId,
  brandAccountName,
  hiddenAccountIds,
  hasFreePlan,
  postsDataMock
}: AnalyticsCompareYoutubePostsProps) => {
  const { t } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const params = useParams({
    from: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/'
  });
  const { data, loading } = useYoutubeComparePostsQuery({
    variables: getValuableVariables({
      mainAccountId,
      endDate: filter.endDate,
      startDate: filter.startDate
    }),
    skip: hasFreePlan
  });

  const postsData = hasFreePlan ? postsDataMock : data?.youtubeComparePosts;
  const comparableAccounts = accounts.filter((account) => !hiddenAccountIds?.includes(account.id));

  return (
    <AnalyticsPostsOverview
      data={postsData}
      loading={loading}
      help={t('Tooltip.comparePosts')}
      dataNotAvailable={!comparableAccounts.length || !postsData}
      wrapperText={!comparableAccounts.length ? HAS_NO_SELECTED_ACCOUNTS : undefined}
      moreDetailProps={{
        to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos',
        params,
        search: { filter: { ...filter, brandAccountName, accountId: comparableAccounts.at(0)?.id ?? '' } }
      }}
    >
      {({ data }) => <Template data={data} comparableAccounts={comparableAccounts} />}
    </AnalyticsPostsOverview>
  );
};
