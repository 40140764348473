import { ReactNode } from 'react';

import { Radio, RadioProps } from './Radio';

interface RadioWithSubsectionProps extends RadioProps {
  children?: ReactNode;
}
export const RadioWithSubsection = ({ children, ...props }: RadioWithSubsectionProps) => (
  <>
    <Radio {...props} />
    {props.checked && children ? children : null}
  </>
);
