/* eslint-disable react/jsx-key */
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { LinkProps } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import { SpreadButton } from '@/shared/molecules';
import { formatIntNumber } from '@/shared/utils';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { useTranslateCountry } from '@/shared/hooks';
import { shieldInvalidImage, shieldValidImage } from '@/shared/assets';
import { NetSuiteVerifiedStatus } from '@/graphql';
import { ANALYTICS_SUBSCRIPTION_TRANSLATION_KEYS } from '@/shared/constants';
import { DeleteAccountType, AccountType } from '../types';
import { useAccountsFilterOptions } from '../../AccountsFilter/hooks';
import { AccountListDomain } from '../../types';

export interface AccountsListTableProps {
  editRoute?: LinkProps['to'];
  data: readonly AccountType[];
  firstColumnTitle: string;
  onDeleting?: (account: DeleteAccountType | null) => void;
  domain: AccountListDomain;
}

export const useAccountsListTable = ({
  data,
  editRoute,
  onDeleting,
  firstColumnTitle,
  domain
}: AccountsListTableProps) => {
  const { tc } = useTranslateCountry();
  const { t } = useTranslation();
  const { isAdmin, isAdminStaff } = useUserRoles();
  const isAdvertiserPage = domain === 'advertiser';
  const { verifiedOptions, tikTokAdvertiserPlanOptions } = useAccountsFilterOptions();

  const getVerificationStatusProps = (status?: NetSuiteVerifiedStatus | null) => {
    const text = verifiedOptions.find((option) => option.value === status)?.label;

    switch (status) {
      case NetSuiteVerifiedStatus.VERIFIED:
        return { icon: shieldValidImage, text, color: '#40B87C' };
      case NetSuiteVerifiedStatus.NOT_VERIFIED:
        return { icon: shieldInvalidImage, text, color: '#FF5F5F' };
      case NetSuiteVerifiedStatus.NO_STATUS:
      default:
        return { icon: '', text, color: '#3b4146' };
    }
  };

  const columns: ColumnProps[] = [
    { title: firstColumnTitle || t('HeaderColumn.Company Name') },
    { title: t('HeaderColumn.TikTok Special Plan'), hidden: !isAdvertiserPage },
    { title: t('HeaderColumn.Analytics Plan'), hidden: !isAdvertiserPage },
    { title: t('HeaderColumn.Verification Status'), hidden: !isAdminStaff },
    { title: t('HeaderColumn.Phone Number') },
    { title: t('HeaderColumn.Users'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Country') }
  ];
  const rows: RowProps[] = data.map(
    ({ id, name, phoneNumber, tiktokPlan, analyticsPlan, users, country, verifiedStatus }) => {
      const isDisabledMoreOptions = !editRoute && !onDeleting;
      const { icon, text, color } = getVerificationStatusProps(verifiedStatus);

      return [
        <div css={styles.nameColumnWrapper}>
          <TableCell.Link label={name} to={editRoute} params={{ id: id.toString() }} disabled={!editRoute} />
          <SpreadButton
            collapseFrom={0}
            moreButtonProps={{ disabled: isDisabledMoreOptions }}
            options={[
              {
                label: t('Edit'),
                icon: 'edit',
                to: editRoute,
                params: { id: id.toString() },
                hidden: !editRoute,
                tooltip: { help: t('Button.Edit') }
              },
              {
                icon: 'delete',
                label: t('Delete'),
                disabled: !isAdmin,
                hidden: !onDeleting,
                onClick: () => onDeleting?.({ id, name }),
                tooltip: { help: t('Button.Delete') }
              }
            ]}
          />
        </div>,
        <TableCell.Text
          css={styles.textColumn}
          value={
            (!!tiktokPlan && tikTokAdvertiserPlanOptions.find((option) => option.value === tiktokPlan)?.label) || '-'
          }
        />,
        <TableCell.Text
          css={styles.textColumn}
          value={(!!analyticsPlan && t(ANALYTICS_SUBSCRIPTION_TRANSLATION_KEYS[analyticsPlan])) || '-'}
        />,
        verifiedStatus ? (
          <div css={{ display: 'flex', alignItems: 'center', color }}>
            <img alt="" src={icon} css={{ marginRight: '3px' }} />
            {text}
          </div>
        ) : (
          <div css={{ display: 'flex', width: '100px', justifyContent: 'center' }}>-</div>
        ),
        <TableCell.Text css={styles.textColumn} value={phoneNumber || '-'} />,
        <TableCell.Number css={styles.textColumn} value={formatIntNumber(users)} />,
        <TableCell.Text css={styles.textColumn} value={country?.name ? tc(country?.name) : '-'} />
      ];
    }
  );

  return { rows, columns };
};

const styles = {
  textColumn: css({ minWidth: '100px' }),
  nameColumnWrapper: css({
    display: 'flex',
    maxWidth: '400px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  })
};
