import type { RefCallback } from 'react';
import type { UseFormRegisterReturn, UseFormTrigger } from 'react-hook-form';

export const withMaskitoRegister = <T extends Record<string, string>>(
  registerResult: UseFormRegisterReturn,
  maskitoRef: RefCallback<HTMLElement | null>,
  trigger?: UseFormTrigger<T>
): UseFormRegisterReturn & { onInput: UseFormRegisterReturn['onChange'] } => {
  const ref: RefCallback<HTMLElement | null> = (node): void => {
    registerResult.ref(node);
    maskitoRef(node);
  };

  return {
    ...registerResult,
    ref,
    onInput: (e) => {
      const result = registerResult.onChange(e);
      trigger?.(); // revalidate the input on each change

      return result;
    }
  };
};
