import { ReactNode, useEffect } from 'react';
import { ReactChannelIO } from 'react-channel-plugin';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { useAuthData } from '@/auth';

declare const CHANNEL_ID_PLUGIN_KEY: string;

export const WithChannelTalk = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const { auth } = useAuthData();
  const { shutdown } = useIntercom();

  useEffect(() => {
    shutdown();
  }, []);

  const plugin = document.querySelector('#ch-plugin-entry > div');
  if (plugin?.shadowRoot) {
    const chatButtonContainer = plugin.shadowRoot.querySelector('[data-ch-testid="launcher"] > button > div'); // .ChatButtonContainer
    if (chatButtonContainer instanceof HTMLElement) {
      chatButtonContainer.style.width = '48px';
      chatButtonContainer.style.height = '48px';
    }
  }

  return auth.company === 'TIKTOK' ? (
    <ReactChannelIO
      pluginKey={CHANNEL_ID_PLUGIN_KEY}
      language={i18n.language}
      autoBoot
      rebootOnOptionChanged
      memberId={auth.userId?.toString()}
      profile={{
        name: auth.name || '',
        email: auth.email || ''
      }}
    >
      {children}
    </ReactChannelIO>
  ) : (
    children
  );
};
