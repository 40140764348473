import {
  Genders,
  MarketplaceDetail,
  CampaignTrackingOption,
  CreateMarketplaceInput,
  CampaignSocialMediaType,
  MarketplaceCampaignStatus,
  AutoManagedCampaignPayload,
  MarketplaceCampaignDetailType
} from '@/graphql';
import {
  MIN_AGE,
  MAX_AGE_V2,
  MIN_AGE_V2,
  MIN_FOLLOWERS,
  GENDER_OPTIONS,
  MAX_FOLLOWERS_MARKETPLACE
} from '@/shared/constants';
import { ToggleOption } from '@/shared/types';
import { convertGenders, floatify, formatHashTags } from '@/shared/utils';
import { DisabledMarketplaceFieldsType, MarketplaceFormKeys, MarketplaceFormValues } from './types';

const DEFAULT_MARGIN_RATE = 0.33;
const DEFAULT_AGENCY_RATE = 0.1;

export const marketplaceSectionTitles = {
  budgetSettings: 'Budget Settings',
  campaignDetails: 'Campaign Detail',
  offerInformation: 'Offer Information',
  trackingSettings: 'Tracking Settings',
  picSettings: 'Advertiser / PIC Settings',
  paymentInformation: 'Payment Information',
  campaignInformation: 'Campaign Information',
  commissionRate: 'Commission rate / Budget settings'
};

const disabledFieldsUpcoming: MarketplaceFormKeys[] = [
  MarketplaceFormKeys.socialMedia,
  MarketplaceFormKeys.campaignType,
  MarketplaceFormKeys.agencyMarginRate,
  MarketplaceFormKeys.isAllowMultiplePosts,
  MarketplaceFormKeys.campaignMaterialLink,
  MarketplaceFormKeys.costPerPost,
  MarketplaceFormKeys.costPerLike,
  MarketplaceFormKeys.costPerView,
  MarketplaceFormKeys.costPerShare,
  MarketplaceFormKeys.costPerClick,
  MarketplaceFormKeys.costPerOrder,
  MarketplaceFormKeys.costPerAction,
  MarketplaceFormKeys.costPerComment,
  MarketplaceFormKeys.costPerSaleTune,
  MarketplaceFormKeys.costPerSaleReferral,
  MarketplaceFormKeys.allowNewInfluencer
];

const disabledFieldsOnGoing: MarketplaceFormKeys[] = [
  ...disabledFieldsUpcoming,
  MarketplaceFormKeys.hashtags,
  MarketplaceFormKeys.countryId,
  MarketplaceFormKeys.marginRate,
  MarketplaceFormKeys.socialMedia,
  MarketplaceFormKeys.advertiser,
  MarketplaceFormKeys.preLaunchDate,
  MarketplaceFormKeys.trackingOptions,
  MarketplaceFormKeys.requireDraftPost,
  MarketplaceFormKeys.couponUploadFiles,
  MarketplaceFormKeys.maximumRevenuePerInfluencer
];

const disabledFieldsSuspended: MarketplaceFormKeys[] = [
  ...disabledFieldsOnGoing,
  MarketplaceFormKeys.minAge,
  MarketplaceFormKeys.maxAge,
  MarketplaceFormKeys.genders,
  MarketplaceFormKeys.regionIds,
  MarketplaceFormKeys.minFollowers,
  MarketplaceFormKeys.maxFollowers,
  MarketplaceFormKeys.isAutoInfluencerApproval
];

const disabledFieldsFinished: MarketplaceFormKeys[] = [
  ...disabledFieldsSuspended,
  MarketplaceFormKeys.title,
  MarketplaceFormKeys.period,
  MarketplaceFormKeys.budget,
  MarketplaceFormKeys.clickUrl,
  MarketplaceFormKeys.thumbnails,
  MarketplaceFormKeys.materials,
  MarketplaceFormKeys.requirement,
  MarketplaceFormKeys.hubspotDealIds,
  MarketplaceFormKeys.landingPageUrl,
  MarketplaceFormKeys.campaignCategoryId,
  MarketplaceFormKeys.serviceInformation,
  MarketplaceFormKeys.influencerCategoryIds,
  MarketplaceFormKeys.additionalRequirement
];

const getDisabledFields = (disabledFields: MarketplaceFormKeys[] = []) =>
  (Object.keys(MarketplaceFormKeys) as MarketplaceFormKeys[]).reduce(
    (rs, key) => ({ ...rs, [key]: disabledFields.includes(key) }),
    {} as DisabledMarketplaceFieldsType
  );

interface MarketplaceDisabledFieldProps {
  campaignStatus?: MarketplaceCampaignStatus;
  campaignType?: MarketplaceCampaignDetailType;
}

export const getMarketplaceDisabledFields = ({ campaignStatus, campaignType }: MarketplaceDisabledFieldProps) => {
  const disabledFieldsBase =
    campaignType === MarketplaceCampaignDetailType.E_COMMERCE ? [MarketplaceFormKeys.countryId] : [];

  switch (campaignStatus) {
    case MarketplaceCampaignStatus.UPCOMING:
      return getDisabledFields([...disabledFieldsBase, ...disabledFieldsUpcoming]);
    case MarketplaceCampaignStatus.ONGOING:
    case MarketplaceCampaignStatus.OUT_OF_COUPON:
      return getDisabledFields([...disabledFieldsBase, ...disabledFieldsOnGoing]);
    case MarketplaceCampaignStatus.SUSPENDED:
      return getDisabledFields([...disabledFieldsBase, ...disabledFieldsSuspended]);
    case MarketplaceCampaignStatus.FINISHED:
      return getDisabledFields([...disabledFieldsBase, ...disabledFieldsFinished]);
    case MarketplaceCampaignStatus.REVIEWING:
    default:
      return getDisabledFields(disabledFieldsBase);
  }
};

export const compareChangedValues = (formValues: MarketplaceFormValues, payload: MarketplaceFormValues) => {
  const hasChangedAge =
    payload.minAge !== MIN_AGE ? formValues.minAge !== payload.minAge : false || formValues.maxAge !== payload.maxAge;
  const hasChangedNumberFollowers =
    formValues.minFollowers !== payload.minFollowers || formValues.maxFollowers !== payload.maxFollowers;
  const hasChangedRegion = formValues.regionIds ? formValues.regionIds.length !== payload.regionIds.length : false;
  const hasChangedCategory = formValues.influencerCategoryIds
    ? formValues.influencerCategoryIds.length !== payload.influencerCategoryIds.length
    : false;
  const hasChangedGenders = formValues.genders ? formValues.genders.length !== payload.genders.length : false;
  const hasChangedPeriod = formValues.period
    ? formValues.period.startDate !== payload.period.startDate || formValues.period.endDate !== payload.period.endDate
    : false;

  return (
    hasChangedAge ||
    hasChangedRegion ||
    hasChangedPeriod ||
    hasChangedGenders ||
    hasChangedCategory ||
    hasChangedNumberFollowers
  );
};

export const generateHashtagVariables = ({
  title,
  hashtags,
  countryId,
  socialMedia,
  advertiser,
  serviceInformation,
  influencerCategoryIds
}: Partial<MarketplaceFormValues>) => ({
  ...(!!socialMedia && { socialMedia }),
  ...(!!title && { campaignTitle: title }),
  ...(!!countryId && { campaignCountry: countryId }),
  ...(!!advertiser && { advertiserId: advertiser.value }),
  ...(!!serviceInformation && { productInfo: serviceInformation }),
  ...(!!influencerCategoryIds?.length && { influencerCategory: influencerCategoryIds.map((id) => Number(id)) }),
  tags: hashtags?.filter((tag) => !!tag) || []
});

interface InitialFormProps {
  isAgency?: boolean;
  defaultAdvertiser?: MarketplaceDetail['advertiser'];
  data?: MarketplaceDetail | null;
  campaignType: MarketplaceCampaignDetailType;
  autoManagedCampaignData?: AutoManagedCampaignPayload | null;
}

export const getMarketplaceInitialFormValues = ({
  data,
  isAgency,
  campaignType,
  autoManagedCampaignData,
  defaultAdvertiser
}: InitialFormProps): MarketplaceFormValues => ({
  id: data?.id,
  budget: data?.budget ?? '',
  currency: data?.currency || '',
  clickUrl: data?.clickUrl || '',
  materials: data?.materials || [],
  thumbnails: data?.thumbnails || [],
  sampleUrl: data?.sampleUrl || '',
  countryId:
    campaignType === MarketplaceCampaignDetailType.E_COMMERCE && autoManagedCampaignData
      ? autoManagedCampaignData.country.id
      : data?.country?.id || '',
  socialMedia: data?.socialMedia || '',
  requirement: data?.requirement || '',
  preLaunchDate: data?.preLaunchDate || '',
  hubspotDealIds: data?.hubspotDealIds || [],
  campaignMaterialLink: data?.materialUrl || '',
  needCouponsCount: data?.needCouponsCount ?? null,
  couponUploadFiles: data?.couponUploadFiles || [],
  campaignType: data?.campaignType || campaignType,
  requireDraftPost: data?.requireDraftPost ?? false,
  serviceInformation: data?.serviceInformation || '',
  minAge: Math.max(data?.minAge ?? MIN_AGE_V2, MIN_AGE_V2),
  maxAge: Math.min(data?.maxAge ?? MAX_AGE_V2, MAX_AGE_V2),
  additionalRequirement: data?.additionalRequirement || '',
  title: data?.title || autoManagedCampaignData?.title || '',
  status: data?.status || MarketplaceCampaignStatus.REVIEWING,
  campaignCategoryId: String(data?.campaignCategory?.id || ''),
  hashtags: data?.hashtags ? formatHashTags(data.hashtags) : [],
  salesPicIds: data?.salesPics?.map((sale) => String(sale.id)) || [],
  advertiser: data?.advertiser
    ? { value: data.advertiser.id.toString(), label: data.advertiser.name }
    : defaultAdvertiser
      ? { value: defaultAdvertiser.id.toString(), label: defaultAdvertiser.name }
      : null,
  maximumRevenuePerInfluencer: String(data?.maximumRevenuePerInfluencer ?? ''),
  marginRate: floatify((data?.marginRate ?? DEFAULT_MARGIN_RATE) * 100),
  period: { endDate: data?.endDate || '', startDate: data?.startDate || '' },
  minFollowers: Math.max(data?.minFollowers ?? MIN_FOLLOWERS, MIN_FOLLOWERS),
  sellerName: String(data?.brandName || autoManagedCampaignData?.sellerName || ''),
  campaignUrl: String(data?.campaignUrl || autoManagedCampaignData?.campaignUrl || ''),
  landingPageUrl: data?.landingPageUrl || autoManagedCampaignData?.landingPageUrl || '',
  mkpServiceCampaignId: String(data?.mkpServiceCampaignId || autoManagedCampaignData?.id || ''),
  isAllowMultiplePosts: data?.isAllowMultiplePosts ? ToggleOption.TRUE : ToggleOption.FALSE,
  allowNewInfluencer: data?.allowNewInfluencer === false ? ToggleOption.FALSE : ToggleOption.TRUE, // true|undefined -> true
  isAutoInfluencerApproval: data?.isAutoInfluencerApproval === false ? ToggleOption.FALSE : ToggleOption.TRUE, // true|undefined -> true
  influencerManagementPicIds: data?.influencerManagementPics?.map((infl) => String(infl.id)) || [],
  productThumbnail: data?.productThumbNail || autoManagedCampaignData?.campaignThumbnailUrl || '',
  maxFollowers: Math.min(data?.maxFollowers ?? MAX_FOLLOWERS_MARKETPLACE, MAX_FOLLOWERS_MARKETPLACE),
  costPerPost: String(data?.costPerPost ?? ''),
  costPerLike: String(data?.costPerLike ?? ''),
  costPerView: String(data?.costPerView ?? ''),
  costPerShare: String(data?.costPerShare ?? ''),
  costPerOrder: String(data?.costPerOrder ?? ''),
  costPerClick: String(data?.costPerClick ?? ''),
  costPerAction: String(data?.costPerAction ?? ''),
  costPerComment: String(data?.costPerComment ?? ''),
  costPerFollower: String(data?.costPerFollower ?? ''),
  costPerSaleTune: typeof data?.costPerSaleTune === 'number' ? floatify(data?.costPerSaleTune * 100).toString() : '',
  costPerSaleReferral:
    typeof data?.costPerSaleReferral === 'number' ? floatify(data?.costPerSaleReferral * 100).toString() : '',
  genders:
    data?.genders?.filter((gender): gender is Genders => !!gender) || GENDER_OPTIONS.map((gender) => gender.value),
  trackingOptions: data?.trackingOptions?.length
    ? data?.trackingOptions
    : campaignType === MarketplaceCampaignDetailType.REFERRAL_CODE
      ? [CampaignTrackingOption.REFERRAL_CODE]
      : campaignType === MarketplaceCampaignDetailType.URL_TRACKING
        ? [CampaignTrackingOption.TUNE]
        : [],
  regionIds:
    data?.regions
      ?.map((region) => (region ? String(region.id) : null))
      ?.filter((regionId): regionId is string => !!regionId) || [],
  influencerCategoryIds:
    data?.categories
      ?.map((category) => (category ? String(category.id) : null))
      ?.filter((categoryId): categoryId is string => !!categoryId) || [],
  agencyMarginRate: isAgency
    ? floatify((data?.agencyMarginRate || DEFAULT_AGENCY_RATE) * 100).toString()
    : typeof data?.agencyMarginRate === 'number'
      ? floatify(data?.agencyMarginRate * 100).toString()
      : '',
  autoManagedCampaignType: undefined
});

interface CalculateInfluencerCommissionRateProps {
  marginRate: MarketplaceFormValues['marginRate'];
  costPerSaleTune: MarketplaceFormValues['costPerSaleTune'];
  costPerSaleReferral: MarketplaceFormValues['costPerSaleReferral'];
}
export const calculateInfluencerCommissionRate = ({
  marginRate,
  costPerSaleTune,
  costPerSaleReferral
}: CalculateInfluencerCommissionRateProps) => {
  const commissionRate = (+costPerSaleTune || +costPerSaleReferral || 0) / 100;

  return commissionRate && marginRate ? (100 - marginRate) * commissionRate : null;
};

export const getCreateUpdateMarketplaceInput = ({
  title,
  minAge,
  budget,
  maxAge,
  period,
  genders,
  hashtags,
  clickUrl,
  countryId,
  sampleUrl,
  regionIds,
  materials,
  thumbnails,
  sellerName,
  marginRate,
  campaignUrl,
  salesPicIds,
  socialMedia,
  requirement,
  campaignType,
  minFollowers,
  maxFollowers,
  advertiser,
  preLaunchDate,
  landingPageUrl,
  hubspotDealIds,
  trackingOptions,
  costPerFollower,
  agencyMarginRate,
  productThumbnail,
  requireDraftPost,
  couponUploadFiles,
  allowNewInfluencer,
  serviceInformation,
  campaignCategoryId,
  isAllowMultiplePosts,
  mkpServiceCampaignId,
  campaignMaterialLink,
  influencerCategoryIds,
  additionalRequirement,
  isAutoInfluencerApproval,
  influencerManagementPicIds,
  maximumRevenuePerInfluencer,
  costPerLike,
  costPerPost,
  costPerOrder,
  costPerView,
  costPerShare,
  costPerClick,
  costPerAction,
  costPerComment,
  costPerSaleTune,
  costPerSaleReferral
}: MarketplaceFormValues): CreateMarketplaceInput => ({
  title,
  minAge: Number(minAge),
  maxAge: Number(maxAge),
  hashtags,
  sampleUrl,
  materials,
  countryId,
  thumbnails,
  requirement,
  minFollowers: Number(minFollowers),
  campaignType,
  requireDraftPost,
  serviceInformation,
  budget: Number(budget),
  startDate: period.startDate.toString(),
  genders: convertGenders(genders),
  regionIds: regionIds.map(Number),
  advertiserId: Number(advertiser?.value),
  marginRate: Number(marginRate) / 100,
  categoryIds: influencerCategoryIds.map(Number),
  campaignCategoryId: Number(campaignCategoryId),
  socialMedia: socialMedia || CampaignSocialMediaType.UNSELECT,
  // We still show 1M on UI but behind the scene, BE want 10M
  maxFollowers: Number(maxFollowers) === MAX_FOLLOWERS_MARKETPLACE ? 10000000 : Number(maxFollowers),
  allowNewInfluencer: allowNewInfluencer === ToggleOption.TRUE ? true : false,
  isAllowMultiplePosts: isAllowMultiplePosts === ToggleOption.TRUE ? true : false,
  isAutoInfluencerApproval: isAutoInfluencerApproval === ToggleOption.TRUE ? true : false,
  // Optional fields
  ...(clickUrl ? { clickUrl } : {}),
  ...(campaignUrl ? { campaignUrl } : {}),
  ...(preLaunchDate ? { preLaunchDate } : {}),
  ...(landingPageUrl ? { landingPageUrl } : {}),
  ...(sellerName ? { brandName: sellerName } : {}),
  ...(hubspotDealIds.length ? { hubspotDealIds } : {}),
  ...(additionalRequirement ? { additionalRequirement } : {}),
  ...(period.endDate ? { endDate: period.endDate.toString() } : {}),
  ...(trackingOptions.length ? { trackingOptions } : {}),
  ...(mkpServiceCampaignId ? { mkpServiceCampaignId: Number(mkpServiceCampaignId) } : {}),
  ...(couponUploadFiles.length ? { couponUploadFiles } : {}),
  ...(productThumbnail ? { productThumbNail: productThumbnail } : {}),
  ...(campaignMaterialLink ? { materialUrl: campaignMaterialLink } : {}),
  ...(salesPicIds.length > 0 ? { salesPicIds: salesPicIds.map(Number) } : {}),
  ...(maximumRevenuePerInfluencer ? { maximumRevenuePerInfluencer: Number(maximumRevenuePerInfluencer) } : {}),
  ...(costPerPost ? { costPerPost: Number(costPerPost) } : {}),
  ...(costPerLike ? { costPerLike: Number(costPerLike) } : {}),
  ...(costPerView ? { costPerView: Number(costPerView) } : {}),
  ...(costPerShare ? { costPerShare: Number(costPerShare) } : {}),
  ...(costPerClick ? { costPerClick: Number(costPerClick) } : {}),
  ...(costPerOrder ? { costPerOrder: Number(costPerOrder) } : {}),
  ...(costPerAction ? { costPerAction: Number(costPerAction) } : {}),
  ...(costPerComment ? { costPerComment: Number(costPerComment) } : {}),
  ...(costPerFollower ? { costPerFollower: Number(costPerFollower) } : {}),
  ...(costPerSaleTune ? { costPerSaleTune: Number(costPerSaleTune) / 100 } : {}),
  ...(agencyMarginRate ? { agencyMarginRate: Number(agencyMarginRate) / 100 } : {}),
  ...(costPerSaleReferral ? { costPerSaleReferral: Number(costPerSaleReferral) / 100 } : {}),
  ...(influencerManagementPicIds.length > 0
    ? { influencerManagementPicIds: influencerManagementPicIds.map(Number) }
    : {})
});
