import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/EngagementAddInfluencersToProposalFromAnotherCampaign.graphql';
import {
  Mutation,
  MutationengagementAddInfluencersToProposalFromAnotherCampaignArgs
} from '../../__generated__/globalTypes';

export const useEngagementAddInfluencersToProposalFromAnotherCampaignMutation = (
  options?: MutationHookOptions<Mutation, MutationengagementAddInfluencersToProposalFromAnotherCampaignArgs>
) => {
  const [callEngagementAddInfluencersToProposalFromAnotherCampaign, result] = useMutation<
    Mutation,
    MutationengagementAddInfluencersToProposalFromAnotherCampaignArgs
  >(MUTATION, options);

  return { callEngagementAddInfluencersToProposalFromAnotherCampaign, ...result };
};

export type EngagementAddInfluencersToProposalFromAnotherCampaignMutationFunction =
  Mutation['engagementAddInfluencersToProposalFromAnotherCampaign'];
