export const INDUSTRY_OPTIONS = [
  {
    value: 'sports_entertainment',
    label: 'Sports & Entertainment',
    submenu: [
      { value: '292104', label: 'Musical Instruments' },
      { value: '292103', label: 'Stationary, Toys & Gifts' },
      { value: '292106', label: 'Fitness' },
      { value: '292107', label: 'Sporting Equipment' },
      { value: '292105', label: 'Indoor Recreation' },
      { value: '292113', label: 'Other' }
    ]
  },
  {
    value: 'education_training',
    label: 'Education & Training',
    submenu: [
      { value: '291002', label: 'Primary & Secondary Education' },
      { value: '291003', label: 'College Education' },
      { value: '291001', label: 'Early Childhood Education' },
      { value: '291006', label: 'Vocational Education' },
      { value: '291007', label: 'Corporate Development' },
      { value: '291004', label: 'Language Learning & Study Abroad' },
      { value: '291005', label: 'Interest & Hobby Training' },
      { value: '291008', label: 'Other' },
      { value: '291009', label: 'Special Needs Education' }
    ]
  },
  {
    value: 'media_content_creation',
    label: 'Media & Content Creation',
    submenu: [
      { value: '292210', label: 'Other' },
      { value: '292205', label: 'Books & Magazines' },
      { value: '292204', label: 'Audio & Video Production' },
      { value: '292207', label: 'News' }
    ]
  },
  {
    value: 'ecommerce',
    label: 'E-commerce',
    submenu: [
      { value: '290505', label: 'Medicine' },
      { value: '290506', label: 'Health & Personal Care' },
      { value: '290216', label: 'Pet Supplies' },
      { value: '290210', label: 'Cosmetics & Personal Care' },
      { value: '291408', label: 'Other' },
      { value: '290303', label: 'Alcoholic Beverages' },
      { value: '290301', label: 'Food & Snacks' },
      { value: '290306', label: 'Non-alcoholic Beverages' },
      { value: '290405', label: 'Watches' },
      { value: '290407', label: 'Jewelry' },
      { value: '290401', label: 'Clothing & Shoes' },
      { value: '290403', label: 'Glasses, Bags & Accessories' },
      { value: '290101', label: 'Electrical Appliances' },
      { value: '290108', label: '3C (Computers, Communications & Consumer)' },
      { value: '290904', label: 'Furniture' },
      { value: '290905', label: 'Home Decor & Accessories' }
    ]
  },
  {
    value: 'other',
    label: 'Other',
    submenu: [
      { value: '291903', label: 'Electrical' },
      { value: '291905', label: 'Other' },
      { value: '292001', label: 'General Mechanical Equipment' },
      { value: '291202', label: 'Nonprofit & NGOs' }
    ]
  },
  {
    value: 'games',
    label: 'Games',
    submenu: [
      { value: '291310', label: 'Parent & Child Games' },
      { value: '291311', label: 'Adventure Games' },
      { value: '291309', label: 'Other' },
      { value: '291308', label: 'Multiplayer Online Games' },
      { value: '291301', label: 'Casual Games' },
      { value: '291303', label: 'RPGs (Roleplaying Games)' },
      { value: '291302', label: 'Tabletop Games' },
      { value: '291305', label: 'Racing & Sports Games' },
      { value: '291304', label: 'Action & Shooting Games' },
      { value: '291307', label: 'Strategy Games' },
      { value: '291306', label: 'Simulation Games' }
    ]
  },
  {
    value: 'services',
    label: 'Services',
    submenu: [
      { value: '291102', label: 'Hospitality' },
      { value: '290614', label: 'Others' },
      { value: '290608', label: 'Design' },
      { value: '290606', label: 'Advertising & PR services' },
      { value: '290605', label: 'Consulting Services' },
      { value: '290603', label: 'Packaging & Printing' },
      { value: '290602', label: 'Publishing' },
      { value: '290601', label: 'Safety & Security Services' },
      { value: '292701', label: 'Catering Services' },
      { value: '290706', label: 'Beauty Services' },
      { value: '290705', label: 'Photography' },
      { value: '291706', label: 'Agriculture, Forestry, Fishing & Hunting' },
      { value: '290803', label: 'Property Management' },
      { value: '290802', label: 'Real Estate & Rental' },
      { value: '291604', label: 'Automotive Services' },
      { value: '291104', label: 'Travel Agencies' }
    ]
  },
  {
    value: 'offline_retail',
    label: 'Offline Retail',
    submenu: [
      { value: '290501', label: 'Health & Personal Care' },
      { value: '290502', label: 'Medicine' },
      { value: '290910', label: 'Furniture' },
      { value: '290211', label: 'Cosmetics & Personal Care' },
      { value: '291805', label: 'Other' },
      { value: '290206', label: 'Pet Supplies' },
      { value: '290302', label: 'Alcoholic Beverages' },
      { value: '290305', label: 'Food & Snacks' },
      { value: '290312', label: 'Non-alcoholic Beverages' },
      { value: '290408', label: 'Jewelry' },
      { value: '290404', label: 'Glasses, Bags & Accessories' },
      { value: '290406', label: 'Watches' },
      { value: '290402', label: 'Clothing & Shoes' },
      { value: '290102', label: 'Electrical Appliances' },
      { value: '290109', label: '3C (Computers, Communications & Consumer)' },
      { value: '290901', label: 'Home Decor & Accessories' }
    ]
  },
  {
    value: 'utility_software',
    label: 'Utility Software',
    submenu: [
      { value: '292802', label: 'Multimedia Processing' },
      { value: '292804', label: 'Utility Tools' },
      { value: '292803', label: 'Social Media & Instant Messaging' },
      { value: '292801', label: 'Software Tools' },
      { value: '292805', label: 'Other' }
    ]
  },
  {
    value: 'sensitive_industries',
    label: 'Sensitive Industries',
    submenu: [
      { value: '4000', label: 'Cigarettes and Tobacco Products' },
      { value: '4001', label: 'Drugs Related Products and Services' },
      { value: '4002', label: 'Weapons' },
      { value: '4003', label: 'Police and Military Equipment' },
      { value: '4004', label: 'Gambling' },
      { value: '4005', label: 'Sexual products and services' },
      { value: '4006', label: 'Political Ads' },
      { value: '4007', label: 'Counterfeit Goods' },
      { value: '4008', label: 'Animals' }
    ]
  }
];
