import { LinkOptions } from '@tanstack/react-router';
import { SocialAccountType, TTCMStatus } from '@/graphql';
import { SupportedSocialMediaType } from '@/shared/types';

export enum InfluencerPromotionMethodsType {
  websites = 'websites',
  podCasts = 'podCasts',
  offlines = 'offlines',
  mobileApps = 'mobileApps',
  emailNewsLetters = 'emailNewsLetters'
}
export type InfluencerProfileMediaType = SocialAccountType | InfluencerPromotionMethodsType | SupportedSocialMediaType;
export interface InfluencerSelectedAccountInfo {
  id: number;
  name: string;
  url?: string;
  linkOption?: LinkOptions;
  profile?: string;
  ttcmStatus?: TTCMStatus;
  analyticsEnabled?: boolean;
  type: InfluencerProfileMediaType;
}
