import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AutoManagedCommissionFixedDates.graphql';
import { Query, QueryautoManagedCommissionFixedDatesArgs } from '../../__generated__/globalTypes';

export const useAutoManagedCommissionFixedDatesQuery = (
  options?: QueryHookOptions<Query, QueryautoManagedCommissionFixedDatesArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryautoManagedCommissionFixedDatesArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAutoManagedCommissionFixedDatesLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryautoManagedCommissionFixedDatesArgs>
): QueryResult<Query, QueryautoManagedCommissionFixedDatesArgs> & {
  getAutoManagedCommissionFixedDates: LazyQueryExecFunction<Query, QueryautoManagedCommissionFixedDatesArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAutoManagedCommissionFixedDates, result] = useLazyQuery<Query, QueryautoManagedCommissionFixedDatesArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getAutoManagedCommissionFixedDates, ...result };
};

export const useAutoManagedCommissionFixedDatesPromiseQuery = () => {
  const client = useApolloClient();

  const getAutoManagedCommissionFixedDates = useCallback(
    (options?: Omit<QueryOptions<QueryautoManagedCommissionFixedDatesArgs, Query>, 'query'>) =>
      client.query<Query, QueryautoManagedCommissionFixedDatesArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAutoManagedCommissionFixedDates };
};
