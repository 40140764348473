import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import { InfluencerThreadsProfileOverview, InfluencerThreadsProfilePosts } from '../../InfluencerProfiles';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerThreadsProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerThreadsProfile = ({ socialAccount }: InfluencerThreadsProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerThreadsProfileOverview influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerThreadsProfilePosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
    </>
  );
};
