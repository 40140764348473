import { Link, useSearch } from '@tanstack/react-router';
import { TextCutter } from '@/shared/atoms';
import { InfluencerAvatar } from '@/shared/molecules';
import { ProfilePostsTabs, ProfilePostsTabType } from '@/shared/organisms';
import { InfluencerCompareAccountType } from '../types';

export interface TemplateProps {
  comparableAccounts: InfluencerCompareAccountType[];
}

export const Template = ({ comparableAccounts }: TemplateProps) => {
  const search = useSearch({ strict: false });
  const tabOptions = comparableAccounts.map<ProfilePostsTabType>(({ accountId, channelName, avatarUrl }, i) => ({
    value: String(accountId),
    label: (props: any) => (
      <Link
        key={`${accountId}_${i}`}
        to={
          i === 0
            ? '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos'
            : '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId'
        }
        activeOptions={{ exact: true, includeSearch: false }}
        params={{ compareId: String(accountId) }}
        search={search}
        {...props}
      >
        <InfluencerAvatar size="32px" css={{ marginRight: '4px', borderRadius: '50%' }} src={avatarUrl} asChild />
        <TextCutter text={channelName} />
      </Link>
    )
  }));

  return <ProfilePostsTabs options={tabOptions} />;
};
