import { useEffect, useRef, useState } from 'react';
import { ActionMeta, GroupBase, OptionProps, Options, components, SelectInstance } from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  useHubspotCompanyListPromiseQuery,
  useHubspotCompanyNamePromiseQuery,
  useNetsuiteVendorListPromiseQuery,
  useNetsuiteVendorNamePromiseQuery
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { ClearIndicator, DropdownIndicator } from '../Selector';
import { AsyncSelectStyles, debounce, emptyNsCustomer } from './utils';

export interface CustomerValues {
  value: string | null;
  label: string;
  countryId?: string | null;
  phoneNumber?: string | null;
  companyUrl?: string | null;
  internalNetsuiteId?: string | null;
}
export interface BigQuerySearchSelectorProps {
  fillFormValues: (formProps: CustomerValues) => void;
  type: 'netsuiteVendor' | 'hubspotCustomer';
  entityIdFromApi?: string | null;
  hideDeselectOption?: boolean;
  disabled?: boolean;
}
export const BigQuerySearchSelector = ({
  entityIdFromApi,
  disabled,
  fillFormValues,
  hideDeselectOption,
  type
}: BigQuerySearchSelectorProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [option, setOption] = useState<CustomerValues | ''>('');
  const [isLoading, setLoading] = useState(false);
  const { getNetsuiteVendorList } = useNetsuiteVendorListPromiseQuery();
  const { getHubspotCompanyList } = useHubspotCompanyListPromiseQuery();
  const { getNetsuiteVendorName } = useNetsuiteVendorNamePromiseQuery();
  const { getHubspotCompanyName } = useHubspotCompanyNamePromiseQuery();
  const ref = useRef<SelectInstance<CustomerValues | '', false, any>>(null);

  const isVendor = type === 'netsuiteVendor';
  const placeholder = isVendor ? 'Please type customer name' : 'Search by Customer name or Company ID';

  useEffect(() => {
    getEntityName();
    if (entityIdFromApi === null) {
      setOption('');
    }
  }, [entityIdFromApi]);
  // we can't inline debounce func in render, it will break debounce logic each render
  const debouncedFetch: (searchTerm: string, callback: (options: Options<CustomerValues>) => void) => void = debounce<
    (_: string, callback: (options: Options<CustomerValues>) => void) => void
  >((searchTerm, callback) => {
    loadOptions(searchTerm, callback);
  }, 300);

  const loadOptions = async (inputValue: string, callback: (options: Options<CustomerValues>) => void) => {
    if (inputValue.length >= 3) {
      let entitiesList = [] as CustomerValues[];
      try {
        if (isVendor) {
          const { data, errors } = await getNetsuiteVendorList({
            variables: { keyword: inputValue }
          });
          entitiesList =
            data.netsuiteVendorList?.map(({ id, name, ...rest }) => ({ value: id, label: name, ...rest })) || [];
          if (errors?.length) {
            enqueueSnackbar(t(errors.at(0)?.message || ''), { variant: 'error' });
          }
        } else {
          const { data, errors } = await getHubspotCompanyList({
            variables: { keyword: inputValue }
          });
          entitiesList =
            data.hubspotCompanyList?.map(({ id, name, ...rest }) => ({ value: id, label: name, ...rest })) || [];
          if (errors?.length) {
            enqueueSnackbar(t(errors.at(0)?.message || ''), { variant: 'error' });
          }
        }

        callback(entitiesList.length ? entitiesList : []);
      } catch (error) {
        enqueueSnackbar(t((error as any).message), { variant: 'error' });
      }
    }
  };

  const getEntityName = async () => {
    if (entityIdFromApi) {
      setLoading(true);
      try {
        let entityId;
        let entityLabel = '';

        if (isVendor) {
          const { data, errors } = await getNetsuiteVendorName({
            variables: { netsuiteId: entityIdFromApi },
            fetchPolicy: 'cache-first'
          });
          if (errors?.length) {
            enqueueSnackbar(t(errors.at(0)?.message || ''), { variant: 'error' });
          }
          entityId = data?.netsuiteVendorName?.id;
          entityLabel = data?.netsuiteVendorName?.name || '';
        } else {
          const { data, errors } = await getHubspotCompanyName({
            variables: { hubspotId: entityIdFromApi },
            fetchPolicy: 'cache-first'
          });

          if (errors?.length) {
            enqueueSnackbar(t(errors.at(0)?.message || ''), { variant: 'error' });
          }
          entityId = data?.hubspotCompanyName?.id;
          entityLabel = data?.hubspotCompanyName?.name || '';
        }

        setOption(entityId ? { value: entityId, label: entityLabel } : '');
      } catch (error) {
        enqueueSnackbar(t((error as any).message), { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const onChange = (selectedValues: any, { action }: ActionMeta<any>) => {
    switch (action) {
      case 'select-option':
        if (selectedValues?.value === '') {
          fillFormValues(emptyNsCustomer);
          setOption('');
        } else {
          fillFormValues(selectedValues);
          setOption(selectedValues);
        }
        break;
      case 'clear':
        fillFormValues(emptyNsCustomer);
        setOption('');
        break;

      default:
        break;
    }
  };

  const CustomOption: React.ComponentType<OptionProps<'' | CustomerValues, false, GroupBase<'' | CustomerValues>>> = (
    props
  ) => (
    <components.Option {...props}>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        {props.children}
        {typeof props.data !== 'string' ? <span>{`${t('ID')} : ${props.data.value}`}</span> : null}
      </div>
    </components.Option>
  );

  return (
    <AsyncSelect<CustomerValues | ''>
      ref={ref}
      isClearable={!hideDeselectOption}
      placeholder={t(placeholder)}
      cacheOptions
      loadOptions={debouncedFetch}
      onChange={onChange}
      styles={AsyncSelectStyles()}
      value={option}
      isLoading={isLoading}
      components={{
        Option: !isVendor ? CustomOption : components.Option,
        DropdownIndicator: (dropdownProps) => (!dropdownProps.hasValue ? <DropdownIndicator /> : null),
        ClearIndicator: (clearProps) => <ClearIndicator {...clearProps} ref={ref} />
      }}
      isDisabled={disabled}
    />
  );
};
