import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthData } from '@/auth';
import {
  MIN_PRICE,
  MAX_PRICE,
  MIN_COMMISSION_RATE,
  MAX_COMMISSION_RATE,
  MIN_MAX_PRICE_OPTIONS,
  MIN_MAX_COMMISSION_RATE_OPTIONS,
  AUTO_MANAGED_CAMPAIGN_TYPE_OPTIONS
} from '@/shared/constants';
import { SelectorField, RangeSliderInputField, MoreFilterPopover, FILTER_SECTIONS } from '@/shared/molecules';
import { formatIntNumber } from '@/shared/utils';
import { AutoManagedCampaignsFilterKeys, AutoManagedCampaignsFilterFormValues } from './schemaTypes';
import { defaultAutoManagedCampaignFilterValues } from './utils';

interface AutoManagedMoreFilterProps {
  onSubmit?: () => void;
  count: number;
}

export const AutoManagedMoreFilter = ({ onSubmit, count }: AutoManagedMoreFilterProps) => {
  const {
    auth: { currencyId }
  } = useAuthData();
  const { t } = useTranslation();
  const { watch, reset } = useFormContext<AutoManagedCampaignsFilterFormValues>();

  const values = watch();

  const handleClear = () => {
    reset({
      ...defaultAutoManagedCampaignFilterValues,
      // Basic filter
      keyword: values.keyword,
      countryId: values.countryId,
      categoryIds: values.categoryIds
    });
  };

  return (
    <MoreFilterPopover
      count={count}
      onSubmit={onSubmit}
      onClear={handleClear}
      defaultOpened={[FILTER_SECTIONS.AutoManaged]}
      items={[
        {
          value: FILTER_SECTIONS.AutoManaged,
          node: (
            <>
              <SelectorField<AutoManagedCampaignsFilterKeys>
                title={t('Selector.Marketplace')}
                name="marketplaceType"
                options={AUTO_MANAGED_CAMPAIGN_TYPE_OPTIONS}
              />

              <RangeSliderInputField<AutoManagedCampaignsFilterKeys>
                step={0.1}
                title={t('Commission rate')}
                options={MIN_MAX_COMMISSION_RATE_OPTIONS}
                range={{ min: MIN_COMMISSION_RATE, max: MAX_COMMISSION_RATE }}
                minField={{ name: 'minCommissionRate', placeholder: `${MIN_COMMISSION_RATE}%` }}
                maxField={{ name: 'maxCommissionRate', placeholder: `${MAX_COMMISSION_RATE}%` }}
              />

              <RangeSliderInputField<AutoManagedCampaignsFilterKeys>
                step={10000}
                title={t('Price')}
                options={MIN_MAX_PRICE_OPTIONS}
                range={{ min: MIN_PRICE, max: MAX_PRICE }}
                minField={{ name: 'minPrice', placeholder: `${formatIntNumber(MIN_PRICE)} ${currencyId}` }}
                maxField={{ name: 'maxPrice', placeholder: `${formatIntNumber(MAX_PRICE)}+ ${currencyId}` }}
                formatLabel={(label) => (typeof label === 'number' ? `${formatIntNumber(label)} ${currencyId}` : '')}
              />
            </>
          )
        }
      ]}
    />
  );
};
