import { useTranslation } from 'react-i18next';
import { ToggleOption } from '@/shared/types';
import { useTranslateOptions } from '@/shared/hooks';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../types';

export const CampaignDisplayField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const campaignDisplayOptions = useTranslateOptions([
    { label: 'Show', value: ToggleOption.TRUE },
    { label: 'Hide', value: ToggleOption.FALSE }
  ]);

  return (
    <SelectorField<InfluencersFilterFormKeys>
      name="campaignDisplay"
      title={t('Selector.Display Campaign')}
      options={campaignDisplayOptions}
      {...props}
    />
  );
};
