export const getValuableVariables = <T extends Record<string, any>>(variables: T) =>
  (Object.keys(variables) as (keyof T)[]).reduce(
    (obj, key) =>
      (Array.isArray(variables[key]) && !variables[key].length) || ['', null, undefined].includes(variables[key]) // Still keep 0, FALSE
        ? obj
        : { ...obj, [key]: variables[key] },
    {} as NonNullable<T>
  );

export const getVariableNumber = (value: number | '', comparedValue?: number) =>
  value === '' || value === comparedValue ? null : value;

export const getVariableRateValue = (value: number | '', compareValue?: string | number, digits = 5) =>
  value === '' || value === compareValue ? null : Number((value / 100).toFixed(digits));

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
