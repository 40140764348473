import { SocialAccountType, useInfluencerProfileAudienceQuery } from '@/graphql';
import { ChartsContainer } from '@/shared/atoms';
import { HAS_NO_ESTIMATED_TEXT } from '@/shared/constants';
import { AgeBreakdownChart, GenderBreakdownChart } from '@/shared/molecules';

import { useInfluencerProfileOpacityContent } from '../../hooks';
import { InfluencerSelectedAccountInfo } from '../../types';

import { InfluencerProfileAudienceWrapper } from './InfluencerProfileAudienceWrapper';

export interface InfluencerProfileAudienceProps {
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerProfileAudience = ({ influencerId, socialAccount }: InfluencerProfileAudienceProps) => {
  const { opacityContent, hasAudienceDemographics } = useInfluencerProfileOpacityContent({
    influencerId,
    socialAccount
  });
  const { data, loading } = useInfluencerProfileAudienceQuery({
    variables: {
      pk: influencerId,
      socialAccountId: socialAccount.id,
      socialMedia: socialAccount.type as SocialAccountType
    }
  });

  const chartOpacityContent = hasAudienceDemographics ? opacityContent : { wrapperText: HAS_NO_ESTIMATED_TEXT };

  return (
    <InfluencerProfileAudienceWrapper
      loading={loading}
      data={data?.influencerProfileAudience}
      hasAudienceDemographics={hasAudienceDemographics}
    >
      {({ data: { maleRate, femaleRate, ageGenderRates } }) => (
        <ChartsContainer css={{ height: '385px' }}>
          <GenderBreakdownChart maleRate={maleRate} femaleRate={femaleRate} {...chartOpacityContent} />
          <AgeBreakdownChart {...ageGenderRates} {...chartOpacityContent} />
        </ChartsContainer>
      )}
    </InfluencerProfileAudienceWrapper>
  );
};
