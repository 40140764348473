import React, { createContext, useContext } from 'react';
import { FilterAction } from '@/shared/types';
import { PackageProposal, PackageProposalSocialAcccountBudgetInput, PackageProposalSummary } from '@/graphql';
import { PackageProposalFilterFormValues, PackageProposedInfluencer } from './PackageContentList/types';

export interface PackageProposalContextType extends FilterAction<PackageProposalFilterFormValues> {
  loading: boolean;
  packageProposal?: Omit<PackageProposal, 'summary' | 'id' | 'name' | 'jdMode' | 'currency'> | null;
  proposalSummary?: PackageProposalSummary | null;
  packageSocialAccountIds: number[];
  packageInfluencers: PackageProposedInfluencer[];
  packageSocialAccounts: PackageProposalSocialAcccountBudgetInput[];
}

const PackageProposalContext = createContext<PackageProposalContextType>({} as PackageProposalContextType);

interface PackageProposalContextProviderProps {
  children: React.ReactElement | Array<React.ReactElement>;
  value: Pick<PackageProposalContextType, 'packageProposal' | 'loading' | 'filter' | 'setFilter' | 'proposalSummary'>;
}

export const PackageProposalProvider = ({
  children,
  value: { packageProposal, proposalSummary, loading, filter, setFilter }
}: PackageProposalContextProviderProps) => {
  const packageInfluencers = !packageProposal
    ? []
    : packageProposal.influencers.map(({ accountList, summary, ...restInfluencerInfo }) => ({
        accountList,
        ...summary,
        ...restInfluencerInfo
      }));

  const packageSocialAccounts: PackageProposalSocialAcccountBudgetInput[] = [];
  const packageSocialAccountIds =
    packageProposal?.influencers.reduce((ids, infl) => {
      const accountIds = infl.accountList.map(({ id, socialMedia, summary: { budget, influencerCost, profit } }) => {
        packageSocialAccounts.push({
          influencerId: infl.id,
          socialAccountId: id,
          socialAccountType: socialMedia,
          profit: profit || 0,
          budget: budget || 0,
          influencerCost: influencerCost || 0
        });

        return id;
      });

      return [...ids, ...accountIds];
    }, [] as number[]) || [];

  return (
    <PackageProposalContext.Provider
      value={{
        filter,
        setFilter,
        loading,
        packageSocialAccounts,
        packageProposal,
        proposalSummary,
        packageSocialAccountIds,
        packageInfluencers
      }}
    >
      {children}
    </PackageProposalContext.Provider>
  );
};

export const usePackageProposalContext = () => useContext<PackageProposalContextType>(PackageProposalContext);
