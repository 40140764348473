import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteEngagementInfluencerProposal.graphql';
import { Mutation, MutationdeleteEngagementInfluencerProposalArgs } from '../../__generated__/globalTypes';

export const useDeleteEngagementInfluencerProposalMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteEngagementInfluencerProposalArgs>
) => {
  const [callDeleteEngagementInfluencerProposal, result] = useMutation<
    Mutation,
    MutationdeleteEngagementInfluencerProposalArgs
  >(MUTATION, options);

  return { callDeleteEngagementInfluencerProposal, ...result };
};

export type DeleteEngagementInfluencerProposalMutationFunction = Mutation['deleteEngagementInfluencerProposal'];
