import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/TransferMoneyToAdAccount.graphql';
import { Mutation, MutationtransferMoneyToAdAccountArgs } from '../../__generated__/globalTypes';

export const useTransferMoneyToAdAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationtransferMoneyToAdAccountArgs>
) => {
  const [callTransferMoneyToAdAccount, result] = useMutation<Mutation, MutationtransferMoneyToAdAccountArgs>(
    MUTATION,
    options
  );

  return { callTransferMoneyToAdAccount, ...result };
};

export type TransferMoneyToAdAccountMutationFunction = Mutation['transferMoneyToAdAccount'];
