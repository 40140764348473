import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { AnalyticsSubscriptionPlanType } from '@/graphql';
import { hashtagSubscriptionImage } from '@/shared/assets';
import { SocialIcon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface SubscriptionPlansProps {
  className?: string;
}

export const SubscriptionPlans = ({ className }: SubscriptionPlansProps) => {
  const { t } = useTranslation();

  const subscriptionsPlansCards = [
    {
      id: 1,
      thousand: '',
      currency: '¥',
      month: 'month',
      planHeader: 'Free',
      headerColor: '#c5d0da',
      selectButton: 'Select Plan',
      amount: 'FREE_SUBSCRIPTION_PRICE',
      planDescription: 'For individuals',
      planType: AnalyticsSubscriptionPlanType.FREE,
      checkList: [
        { id: 1, isChecked: true, title: 'Dashboard' },
        { id: 2, isChecked: false, title: 'Compare' },
        { id: 3, isChecked: false, title: 'HashTags' },
        { id: 4, isChecked: false, title: 'Interaction' }
      ]
    },
    {
      id: 2,
      thousand: '',
      currency: '¥',
      month: 'month',
      planType: 'TRIAL', // TODO: we don't have trial plan now
      planHeader: 'Trial',
      headerColor: '#ffb63d',
      selectButton: 'Select Plan',
      planDescription: '14 days Free!',
      amount: 'TRIAL_SUBSCRIPTION_PRICE',
      extraFeatures: { comparedAccounts: '15', hashtags: '15' },
      checkList: [
        { id: 1, isChecked: true, title: 'Dashboard' },
        { id: 2, isChecked: true, title: 'Compare' },
        { id: 3, isChecked: true, title: 'HashTags' },
        { id: 4, isChecked: true, title: 'Interaction' }
      ]
    },
    {
      id: 3,
      currency: '¥',
      thousand: '万',
      month: 'month',
      planHeader: 'Basic',
      headerColor: '#ff5f5f',
      selectButton: 'Select Plan',
      amount: 'BASIC_SUBSCRIPTION_PRICE',
      planDescription: 'All Pages Available',
      extraFeatures: { comparedAccounts: '5~', hashtags: '5~' },
      planType: AnalyticsSubscriptionPlanType.BASIC, // TODO: should work for Basic+ and Basic ++ too?
      checkList: [
        { id: 1, isChecked: true, title: 'Dashboard' },
        { id: 2, isChecked: true, title: 'Compare' },
        { id: 3, isChecked: true, title: 'HashTags' },
        { id: 4, isChecked: true, title: 'Interaction' }
      ]
    },
    {
      id: 4,
      month: '',
      currency: '',
      thousand: '',
      amount: 'ASK',
      headerColor: '#966acc',
      selectButton: 'Contact',
      planHeader: 'Enterprise',
      planDescription: 'Including Posts Management',
      planType: AnalyticsSubscriptionPlanType.ENTERPRISE, // TODO: should work for Enterprise+ and Basic ++ too?
      extraFeatures: { comparedAccounts: '5~', hashtags: '5~' },
      checkList: [
        { id: 1, isChecked: true, title: 'Dashboard' },
        { id: 2, isChecked: true, title: 'Compare' },
        { id: 3, isChecked: true, title: 'HashTags' },
        { id: 4, isChecked: true, title: 'Interaction' }
      ]
    }
  ];

  return (
    <div css={{ gap: '24px', display: 'flex', paddingTop: '32px' }} className={className}>
      {subscriptionsPlansCards.map(
        ({
          month,
          amount,
          planType,
          thousand,
          currency,
          checkList,
          planHeader,
          headerColor,
          extraFeatures,
          planDescription
        }) => (
          <div css={{ flex: 1 }} key={planType}>
            <div css={{ height: '5px', borderRadius: '16px 16px 0 0', backgroundColor: headerColor }} />
            <div css={styles.card}>
              <h3 css={styles.planHeader}>{t(planHeader)}</h3>
              <p css={styles.planDescription}>{t(planDescription)}</p>
              <div css={styles.priceSection}>
                {/* TODO: currency & amount should be based on users country */}
                {currency ? <p css={{ fontSize: '24px', marginRight: '4px' }}>{currency}</p> : null}
                <p css={{ fontSize: '32px', marginRight: '3px' }}>{t(amount, '')}</p>
                {thousand ? <p css={{ fontWeight: 'normal', marginRight: '4px' }}>{thousand}</p> : null}
                {month ? <p>/{t(month)}</p> : null}
              </div>

              <ul css={{ marginTop: '24px' }}>
                {checkList.map((item) => {
                  const mark = item.isChecked ? '\u2713' : '\u00D7';

                  return (
                    <li css={styles.listItem} key={item.id}>
                      <span css={styles.checkMark(item.isChecked)}>{mark}</span>
                      <span css={styles.label(item.isChecked)}>{t(item.title)}</span>
                    </li>
                  );
                })}
              </ul>

              {extraFeatures?.comparedAccounts ? (
                <div css={styles.extraFeatures}>
                  <SocialIcon type="INSTAGRAM" size="18px" />
                  <p css={{ marginLeft: '6px', fontWeight: 600 }}>{extraFeatures?.comparedAccounts}</p>
                  <p css={{ marginLeft: '4px' }}>{t('compared accounts')}</p>
                </div>
              ) : null}

              {extraFeatures?.hashtags ? (
                <div css={[styles.extraFeatures, { marginTop: '6px' }]}>
                  <img css={{ width: '18px', height: '18px' }} src={hashtagSubscriptionImage} alt="hashtag icon" />
                  <p css={{ marginLeft: '6px', fontWeight: 600 }}>{extraFeatures?.hashtags}</p>
                  <p css={{ marginLeft: '4px' }}>{t('hashtags')}</p>
                </div>
              ) : null}
            </div>
          </div>
        )
      )}
    </div>
  );
};
const styles = {
  card: css({
    padding: '24px',
    height: '360px',
    minWidth: '240px',
    boxSizing: 'border-box',
    borderRadius: '0 0 3px 3px',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0 1px 2px 0 #dee5ec, 0 0 0 1px #dee5ec80'
  }),
  planHeader: css({
    lineHeight: 1.1,
    fontSize: '20px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal'
  }),
  planDescription: css({
    fontSize: '14px',
    lineHeight: 1.57,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal'
  }),
  priceSection: css({
    display: 'flex',
    fontSize: '14px',
    marginTop: '32px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    alignItems: 'baseline',
    letterSpacing: 'normal'
  }),
  listItem: css({
    display: 'flex',
    fontSize: '16px',
    lineHeight: 1.38,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    alignItems: 'baseline',
    letterSpacing: 'normal'
  }),
  checkMark: (checked: boolean) =>
    css({
      width: '22px',
      fontSize: '20px',
      ...(checked ? { color: THEME.icon.colors.red.lv1 } : { color: THEME.icon.colors.gray.lv1, fontWeight: 600 })
    }),
  label: (checked: boolean) =>
    css({
      fontSize: '16px',
      lineHeight: 1.38,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      color: checked ? 'inherit' : THEME.text.colors.gray.lv1
    }),
  extraFeatures: css({
    display: 'flex',
    fontSize: '14px',
    marginTop: '31px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal'
  })
};
