import { CSSProperties, ReactElement, ReactNode } from 'react';
import { THEME } from '@/shared/constants';
import { Delayed } from '../Delayed';
import { ListEmpty } from '../ListEmpty';
import { Loading, LoadingProps } from '../Loading';

export interface RenderDataWithFallbackPropsV2<T extends Record<string, unknown>> {
  loading?: boolean;
  data?: T | null;
  children: (data: T) => ReactNode;
  awaitedTime?: number;
  skipDelayed?: boolean;
  noDataNode?: ReactElement | null;
  loadingNode?: ReactElement;
  loadingProps?: { css?: CSSProperties } & LoadingProps;
}

export const RenderDataWithFallbackV2 = <T extends Record<string, unknown>>({
  loading,
  children,
  data,
  awaitedTime,
  skipDelayed,
  loadingProps,
  loadingNode = (
    <Delayed asChild={skipDelayed} awaitedTime={awaitedTime}>
      <Loading {...loadingProps} css={{ backgroundColor: THEME.background.colors.white, ...loadingProps?.css }} />
    </Delayed>
  ),
  noDataNode = <ListEmpty />
}: RenderDataWithFallbackPropsV2<T>) => (loading ? loadingNode : data ? children(data) : noDataNode);
