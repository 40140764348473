import { useCreateInfluencerV2Mutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useUpdateDocumentTitle, useAllTalentAgencyOrPartnerOptions } from '@/shared/hooks';
import { InfluencerForm, InfluencerFormValues, getCreateUpdateInfluencerInput } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const AddInfluencerForOthers = () => {
  useUpdateDocumentTitle({
    href: '/influencers',
    title: 'documentTitle.AddInfluencer',
    appHeader: 'appHeader.Add New Influencer'
  });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateInfluencerV2 } = useCreateInfluencerV2Mutation();
  const { allTalentAgenciesPartners } = useAllTalentAgencyOrPartnerOptions();

  const handleSubmit: FormAction<InfluencerFormValues>['onSubmit'] = async (values) => {
    try {
      const input = getCreateUpdateInfluencerInput(values, allTalentAgenciesPartners);
      await callCreateInfluencerV2({ variables: { input } });

      enqueueSnackbar(t('succeededInCreating', { name: values.name }), { variant: 'success' });
      navigate({ to: '/influencers' });
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name: values.name }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return <InfluencerForm onSubmit={handleSubmit} />;
};
