import { Trans } from 'react-i18next';
import { Outlet } from '@tanstack/react-router';
import { useUpdateEngagementCouponListMutation } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { UploadCSVCouponModal } from '@/shared/molecules';
import { InfluencerAISearchWidget } from '@/shared/organisms';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';

export const EngagementInfluencers = () => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { engagementId, uploadModal } = useEngagementDetailsContext();
  const { callUpdateEngagementCouponList, loading } = useUpdateEngagementCouponListMutation({
    onCompleted: invalidateRouteLoader
  });

  const handleUploadFile = async (fileUrl: string) => {
    try {
      await callUpdateEngagementCouponList({
        variables: { input: { id: engagementId, couponUploadFileUrl: fileUrl } }
      });

      enqueueSnackbar(t('fileUploadSuccess'), { variant: 'success' });
      uploadModal.setOff();
    } catch (error) {
      enqueueSnackbar(t(error.message || 'fileUploadFail'), { variant: 'error' });
    }
  };

  return (
    <>
      <InfluencerAISearchWidget />

      <Outlet />

      <UploadCSVCouponModal
        loading={loading}
        isOpen={uploadModal.on}
        onClose={uploadModal.setOff}
        title="Upload referral codes"
        onUploadFile={handleUploadFile}
        description={
          <div css={{ marginBottom: '16px' }}>
            <Trans i18nKey="uploadCouponModalDescription" components={{ br: <br /> }} />
          </div>
        }
      />
    </>
  );
};
