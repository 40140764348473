import { useTranslation } from 'react-i18next';
import { FormStyle, ButtonDropdownSingleSelect } from '@/shared/atoms';
import { SortOption, SortType } from '@/shared/types';

export interface UGCPostSortProps<T extends string> {
  sort: SortType<T>;
  options: SortOption<T>[];
  onChange?: (sortOption: SortOption<T>) => void;
}

export const UGCPostSort = <T extends string>({ sort, options, onChange }: UGCPostSortProps<T>) => {
  const { t } = useTranslation();

  return (
    <FormStyle.FieldWrapper css={{ flex: 'none' }}>
      <ButtonDropdownSingleSelect
        variant="white"
        options={options}
        title={t('Button.Sort')}
        prefixIcon={{ icon: 'sort', size: '14px' }}
        onSelectOption={(option) => onChange?.(option)}
        selectedOption={options.find((o) => o.field === sort.field && o.order === sort.order)}
      />
    </FormStyle.FieldWrapper>
  );
};
