import { FormatOptionLabelMeta } from 'react-select';
import { Option } from '@/shared/types';
import { TextCutter } from '@/shared/atoms';
import { CommonSelectProps } from '../types';

export interface OptionLabelProps extends Omit<Option, 'value'>, Pick<CommonSelectProps<any, any, any>, 'menuWidth'> {
  className?: string;
  meta: FormatOptionLabelMeta<Option<any, any>>;
}

export const OptionLabel = ({ meta: { context }, prefixIcon, label, className, menuWidth }: OptionLabelProps) => {
  const menuWidthAuto = menuWidth === 'auto' && context === 'menu';

  return (
    <div
      className={className}
      css={{
        height: '100%',
        display: 'flex',
        maxWidth: '100%',
        placeItems: 'center',
        flexDirection: 'row',
        ...(menuWidthAuto && { minWidth: '100%', width: 'max-content', maxWidth: 'max-content' })
      }}
    >
      {prefixIcon ? <div css={{ lineHeight: 0, marginRight: '4px' }}>{prefixIcon}</div> : null}
      <TextCutter
        lines={1}
        text={label}
        css={[{ whiteSpace: 'normal' }, menuWidthAuto && { minWidth: 'max-content', whiteSpace: 'nowrap' }]}
      />
    </div>
  );
};
