import { ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { NoDataContentWrapper, ChartTooltipSingleMetric, NoDataContentWrapperProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatPercent, getEstimatedWrapperText } from '@/shared/utils';
import { HighChart, Highcharts } from '../HighChart';

interface AgeBreakdownData {
  maleRate?: number[] | null;
  otherRate?: number[] | null;
  totalRate?: number[] | null;
  femaleRate?: number[] | null;
  ageGroup?: { start: number; end: number }[] | null;
  series?: Highcharts.SeriesOptionsType[];
}

export interface VerticalBarChartProps
  extends AgeBreakdownData,
    Pick<Highcharts.ChartOptions, 'width'>,
    Pick<NoDataContentWrapperProps, 'className' | 'wrapperLink' | 'wrapperText'> {
  hasEstimatedDemographics?: boolean;
  dataNotAvailable?: boolean;
  header: ReactNode;
  categories?: string[];
}

export const VerticalBarChart = ({
  series = [],
  width,
  header,
  ageGroup,
  maleRate,
  otherRate,
  categories,
  totalRate,
  femaleRate,
  wrapperText,
  dataNotAvailable,
  hasEstimatedDemographics,
  ...noDataContentProps
}: VerticalBarChartProps) => {
  const { t } = useTranslation();

  const options: Highcharts.Options = {
    title: { text: '' },
    chart: { width, type: 'column', height: 300 },
    xAxis: {
      categories,
      title: { text: null }
    },
    yAxis: {
      min: 0,
      max: 1,
      title: { text: null },
      labels: {
        formatter() {
          return formatPercent(Number(this.value), true, 0);
        }
      }
    },
    legend: {
      enabled: false,
      reversed: true,
      symbolWidth: 15,
      symbolRadius: 2,
      symbolHeight: 15,
      width: '100%',
      itemStyle: { fontSize: '13px', color: THEME.text.colors.black.lv1, fontWeight: 'normal' }
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick() {
            return false;
          }
        }
      }
    },
    series,
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: THEME.chart.tooltip.background.colors.black,
      style: { color: THEME.chart.tooltip.text.colors.white },
      formatter(): string {
        return renderToStaticMarkup(
          <ChartTooltipSingleMetric
            category={this.x}
            name={this.series.name}
            color={this.color as string}
            value={formatPercent(this.y)}
          />
        );
      }
    }
  };

  return (
    <NoDataContentWrapper
      css={{ padding: '24px 16px' }}
      wrapperText={getEstimatedWrapperText({ type: 'age', wrapperText, hasEstimatedDemographics })}
      dataNotAvailable={dataNotAvailable}
      header={header}
      {...noDataContentProps}
    >
      <HighChart options={options} />
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <span
            css={{
              width: '15px',
              height: '15px',
              marginRight: '8px',
              borderRadius: '2px',
              position: 'relative',
              backgroundColor: '#9ea0e0'
            }}
          />
          <p css={{ color: THEME.text.colors.black.lv1, fontSize: '13px' }}>{t('Total')}</p>
        </div>
      </div>
    </NoDataContentWrapper>
  );
};
