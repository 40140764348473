import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import {
  InfluencerDouyinProfilePosts,
  InfluencerDouyinProfileOverview,
  InfluencerDouyinProfileAudience,
  InfluencerDouyinProfileAccountInterest
} from '../../InfluencerProfiles';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerDouyinProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerDouyinProfile = ({ socialAccount }: InfluencerDouyinProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerDouyinProfileOverview influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerDouyinProfileAudience influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerDouyinProfileAccountInterest influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerDouyinProfilePosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
    </>
  );
};
