import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateAdminUser.graphql';
import { Mutation, MutationupdateAdminUserArgs } from '../../__generated__/globalTypes';

export const useUpdateAdminUserMutation = (options?: MutationHookOptions<Mutation, MutationupdateAdminUserArgs>) => {
  const [callUpdateAdminUser, result] = useMutation<Mutation, MutationupdateAdminUserArgs>(MUTATION, options);

  return { callUpdateAdminUser, ...result };
};

export type UpdateAdminUserMutationFunction = Mutation['updateAdminUser'];
