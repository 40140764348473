import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, SocialIcon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PostDetailStatistics, SupportedSocialMediaType } from '@/shared/types';
import { getSocialMediaTextLink } from '@/shared/utils';
import { getSupportedStatistics } from './utils';

export interface PostStatisticsProps {
  className?: string;
  postStatus?: ReactNode;
  postUrl?: string | null;
  statistics: PostDetailStatistics;
  socialMedia: keyof typeof SupportedSocialMediaType;
}

export const PostStatistics = ({ postUrl, className, statistics, postStatus, socialMedia }: PostStatisticsProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ display: 'flex', width: '100%', gap: '20px' }} className={className}>
      <div css={{ flex: 1 }}>
        <h6 css={[styles.title, { alignItems: 'center' }]}>
          {t('General.Engagement')}
          {postUrl && socialMedia !== SupportedSocialMediaType.INSTAGRAM_STORY ? (
            <>
              <SocialIcon size="16px" type={socialMedia} css={{ marginLeft: '4px' }} />
              <Anchor
                variant="blue"
                href={postUrl}
                target="_blank"
                rel="noreferrer"
                css={{ fontSize: '12px', fontWeight: 400, marginLeft: '4px' }}
              >
                {t('View on', { socialMedia: getSocialMediaTextLink(socialMedia) })}
              </Anchor>
            </>
          ) : null}
          {postStatus ? (
            <div css={{ display: 'flex', alignItems: 'center', marginInlineStart: 'auto', columnGap: '8px' }}>
              <p css={{ fontWeight: 600, fontSize: '10px' }}>{t('General.Post Status')}</p> {postStatus}
            </div>
          ) : null}
        </h6>

        <div css={{ display: 'flex', alignItems: 'flex-start', marginLeft: '-8px' }}>
          <div css={{ width: '100%', display: 'flex', rowGap: '12px', flexWrap: 'wrap' }}>
            {getSupportedStatistics({ statistics }).map((item, index) => (
              <div css={styles.item} key={index}>
                <p css={{ whiteSpace: 'nowrap', }} className='stat-label'>
                  {t(item.label)}
                </p>
                <p title={item.value} className='stat-value'>
                  {item.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = {
  title: css({
    fontWeight: 600,
    display: 'flex',
    fontSize: '12px',
    marginBottom: '16px',
    color: THEME.text.colors.black.lv1
  }),
  item: css({
    padding: '0 8px',
    '&:not(:last-of-type)': { borderRight: THEME.border.base },
    '.stat-label': {
      fontWeight: 400,
      fontSize: '14px',
      color: THEME.text.colors.gray.lv3,
      maxWidth: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.stat-value': {
      fontWeight: 400,
      fontSize: '14px',
      maxWidth: '80px',
      overflow: 'hidden',
      marginTop: '2px',
    },
  })
};
