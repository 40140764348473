import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { THEME } from '@/shared/constants';
import { convertDurationToHhMmSs } from '@/shared/utils';
import { TextCutter } from '../TextCutter';
import { MediaPreview, MediaPreviewProps } from './MediaPreview';

export interface MediaPreviewWithMetricsProps extends MediaPreviewProps {
  size?: string;
  title?: string;
  className?: string;
  video?: { duration?: number };
  radius?: string;
  statistics: { label: ReactNode; value?: string | number | null }[]; // If don't have statistics, pls use MediaPreview component
}

export const MediaPreviewWithMetrics = ({
  video,
  title,
  className,
  statistics,
  size = '100%',
  radius = '0px',
  ...mediaPreviewProps
}: MediaPreviewWithMetricsProps) => {
  const hasTitle = typeof title !== 'undefined';

  return (
    <div
      css={[styles.wrapper(size, radius), hasTitle && { display: 'flex', flexDirection: 'column' }]}
      className={className}
    >
      <div css={[styles.previewWrapper, hasTitle && { flex: 1 }]}>
        <MediaPreview {...mediaPreviewProps} />
        {hasTitle && video?.duration ? (
          <div css={styles.videoDuration}>{convertDurationToHhMmSs(video.duration)}</div>
        ) : null}
      </div>

      {hasTitle ? <TextCutter lines={2} text={title} css={styles.title} /> : null}

      <div css={hasTitle ? { display: 'flex', flex: 'none', marginTop: '8px' } : styles.statistic}>
        {statistics.map(({ label, value }, index) => (
          <div key={index} css={styles.item(statistics.length, hasTitle)}>
            <TextCutter text={label} css={styles.label} />
            <TextCutter text={value} css={styles.value} />
          </div>
        ))}
      </div>
    </div>
  );
};
const styles = {
  wrapper: (size: string, radius: string) =>
    css({
      width: size,
      height: size,
      position: 'relative',
      '&:focus': { outline: 'none' },
      ...(radius && { borderRadius: radius, img: { borderRadius: radius } })
    }),
  statistic: css({
    left: 0,
    bottom: 0,
    zIndex: 1,
    width: '100%',
    display: 'flex',
    paddingBottom: '8px',
    position: 'absolute',
    boxSizing: 'border-box',
    borderRadius: 'inherit',
    color: THEME.text.colors.white,
    background: 'linear-gradient(0, #000 -100%, rgba(255, 255,255, 0.01) 100%)'
  }),
  item: (length: number, hasTitle: boolean) =>
    css({
      '--size': `calc(100% / ${length})`,
      position: 'relative',
      boxSizing: 'border-box',
      letterSpacing: '0.26px',
      flex: length > 2 ? '1 0 auto' : 1,
      paddingLeft: length > 2 ? '4px' : '8px',
      '&:not(:first-of-type)': {
        borderLeft: `1px solid ${hasTitle ? THEME.border.colors.gray.lv1 : 'rgba(255, 255, 255, 0.4)'}`
      },
      ...(hasTitle ? { '&:first-of-type': { paddingLeft: 0 } } : {})
    }),
  label: css({ fontWeight: 400, fontSize: '11px' }),
  value: css({ fontWeight: 600, fontSize: '12px', marginTop: '4px' }),
  previewWrapper: css({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative'
  }),
  title: css({
    flex: 'none',
    height: '30px',
    marginTop: '4px',
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '0.28px'
  }),
  videoDuration: css({
    right: '6px',
    bottom: '3px',
    height: '12px',
    fontWeight: 600,
    fontSize: '10px',
    width: 'max-content',
    position: 'absolute',
    backgroundColor: '#000',
    color: THEME.text.colors.white
  })
};
