import { LinkProps } from '@tanstack/react-router';
import { AccountAnalyticsDetail } from '@/graphql';
import { jsonParseOrStringify } from '@/shared/utils';
import { AnalyticsFormValues } from './schemaTypes';

export const getAnalyticsFormValues = (analyticsAccount?: AccountAnalyticsDetail): AnalyticsFormValues => ({
  id: analyticsAccount?.id,
  accountName: analyticsAccount?.accountName || '',
  advertiserId: String(analyticsAccount?.advertiser.id || ''),
  accountManagerIds: analyticsAccount?.accountManagers.map((account) => String(account.id)) || [],
  // Social accounts
  youtube: analyticsAccount?.socialMediaAccounts.youtube || undefined,
  twitter: analyticsAccount?.socialMediaAccounts.twitter || undefined,
  instagram: analyticsAccount?.socialMediaAccounts.instagram || undefined,
  facebookPage: analyticsAccount?.socialMediaAccounts.facebookPage || undefined
});

export const defaultAnalyticsFormValues = getAnalyticsFormValues();

export const ANALYTICS_FORM_ROUTES = ['/analytics/add', '/analytics/$brandAccountId'] as const satisfies ReadonlyArray<
  LinkProps['to']
>;
interface LocalStorageValueShape {
  to: (typeof ANALYTICS_FORM_ROUTES)[number];
  formState: AnalyticsFormValues;
  params?: { brandAccountId: string };
}
interface AnalyticsAccountFormStateProps {
  params?: LocalStorageValueShape;
  stringifiedData?: string | null;
}

export const setAnalyticsAccountFormState = jsonParseOrStringify<AnalyticsAccountFormStateProps>;
export const getAnalyticsAccountFormState = jsonParseOrStringify<
  AnalyticsAccountFormStateProps,
  LocalStorageValueShape
>;
