import { useParamsSearch } from '@/shared/hooks';
import { convertOrderToORDER } from '@/shared/utils';
import { ListWithPagination } from '@/shared/molecules';
import { useInfluencerCampaignListQuery } from '@/graphql';
import {
  useInfluencerProfileContext,
  InfluencerProfileCampaignsList,
  InfluencerProfileCampaignsFilter,
  InfluencerProfileCampaignsSummary,
  InfluencerProfileCampaignsSearchSchemaType
} from '@/shared/organisms';

export const InfluencerProfileCampaigns = () => {
  const { influencerId } = useInfluencerProfileContext();
  const { sort, filter, setFilter } = useParamsSearch<InfluencerProfileCampaignsSearchSchemaType>();
  const { data, previousData, loading } = useInfluencerCampaignListQuery({
    fetchPolicy: 'network-only',
    variables: {
      pk: influencerId,
      keyword: filter.keyword,
      socialMedias: filter.socialMedias, // Issue: https://adasiaholdings.atlassian.net/browse/AT-5493
      campaignType: filter.campaignType || null,
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) }
    }
  });

  const result = loading ? previousData : data;

  return (
    <>
      <InfluencerProfileCampaignsSummary influencerId={influencerId} css={{ marginBottom: '16px' }} />
      <ListWithPagination>
        <InfluencerProfileCampaignsFilter filter={filter} setFilter={setFilter} />
        <InfluencerProfileCampaignsList loading={loading} data={result?.influencerCampaignList || []} />
      </ListWithPagination>
    </>
  );
};
