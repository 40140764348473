import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateAdminUser.graphql';
import { Mutation, MutationcreateAdminUserArgs } from '../../__generated__/globalTypes';

export const useCreateAdminUserMutation = (options?: MutationHookOptions<Mutation, MutationcreateAdminUserArgs>) => {
  const [callCreateAdminUser, result] = useMutation<Mutation, MutationcreateAdminUserArgs>(MUTATION, options);

  return { callCreateAdminUser, ...result };
};

export type CreateAdminUserMutationFunction = Mutation['createAdminUser'];
