import { Option } from '@/shared/types';

export const getInitialSelectedItems = <T extends Option = Option>(values: string[], options: readonly T[]): T[] =>
  values.map((val) => options.find((option) => option.value === val)).filter((val): val is T => !!val);

export const getInitialSelectedSubmenuItems = (values: string[], options: readonly Option[]): Option[] =>
  options
    .map((o) =>
      (o.submenu || []).reduce<Option[]>(
        (acc, curr) => (curr.value && values.includes(curr.value) ? [...acc, curr] : acc),
        []
      )
    )
    .flat();

export const MENU_ITEMS_HEIGHT = 210;
