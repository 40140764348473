import { useTranslation } from 'react-i18next';
import { TikTokSpecialCampaignDetail } from '@/graphql';
import { RenderDataWithFallback, TableSegment } from '@/shared/atoms';
import { PostReportEmpty, PostReportSegmentKeys } from '@/shared/organisms';
import { TikTokCampaignReportChart } from '../TikTokCampaignReportChart';
import { TikTokCampaignReportTable } from '../TikTokCampaignReportTable';
import { useTikTokCampaignReportDetailsData } from './hooks';

export interface TikTokCampaignReportDetailsProps {
  tiktokSpecialCampaign: TikTokSpecialCampaignDetail;
}
export const TikTokCampaignReportDetails = ({ tiktokSpecialCampaign }: TikTokCampaignReportDetailsProps) => {
  const { t } = useTranslation();
  const { reportData, loading, networkStatus, segmentStatus, handleSegmentChange } = useTikTokCampaignReportDetailsData(
    { tiktokSpecialCampaign }
  );

  return (
    <RenderDataWithFallback
      hasNoData={!reportData}
      noDataNode={<PostReportEmpty />}
      loading={loading && networkStatus === 1} // Loading 1st time
    >
      {reportData ? (
        <>
          <TikTokCampaignReportChart data={reportData.chartData} />
          <TableSegment
            type="switch"
            onChange={handleSegmentChange}
            options={[
              { label: t('Total'), value: segmentStatus.total, key: PostReportSegmentKeys.total },
              { label: t('General.Post'), value: segmentStatus.post, key: PostReportSegmentKeys.post }
            ]}
          />
          <TikTokCampaignReportTable data={reportData} loading={loading} />
        </>
      ) : null}
    </RenderDataWithFallback>
  );
};
