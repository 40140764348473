import { useFormContext } from 'react-hook-form';
import { InfluencersTabType, Option } from '@/shared/types';
import { Icon, SocialIcon } from '@/shared/atoms';
import { SelectorFieldV2 } from '@/shared/molecules';
import { useChatDemo, useQueryHelper } from '@/shared/hooks';
import { useUserRoles, useInfluencerPackagePermissions } from '@/auth';
import { SocialPostType } from '@/graphql';
import { getInfluencerFilterTabUrl, getInfluencerFilterValuesBasedOnTab } from '../utils';
import { InfluencersBaseSearchSchemaType, InfluencersFilterFormKeys, InfluencersFilterFormValues } from '../types';
import { getInitialInfluencerPostsFormValues } from '../InfluencerPosts/utils';
import { InfluencerPostsFilterFormValues } from '../InfluencerPosts';

interface InfluencerTabFieldProps {
  className?: string;
  onSubmit?: () => void;
  isPackagePage?: boolean;
  isEngagementPage?: boolean;
}
interface TabOptions<T> extends Option<T> {
  defaultValues?: Partial<InfluencerPostsFilterFormValues>;
}

export const InfluencerTabField = ({ className, isEngagementPage, isPackagePage }: InfluencerTabFieldProps) => {
  const { isAdminStaff } = useUserRoles();
  const { isChatDemoAccount } = useChatDemo();
  const { t, navigate } = useQueryHelper();
  const { hidePackageTab } = useInfluencerPackagePermissions();
  const { watch } = useFormContext<InfluencersFilterFormValues>();

  const values = watch();
  const tabOptions: TabOptions<InfluencersTabType>[] = [
    {
      label: t('General.Influencer'),
      value: InfluencersTabType.INFLUENCERS,
      prefixIcon: <Icon icon="contact-two-tones" size={16} />
    },
    { label: t('Instagram'), value: InfluencersTabType.INSTAGRAM, prefixIcon: <SocialIcon type="INSTAGRAM" /> },
    { label: t('Youtube'), value: InfluencersTabType.YOUTUBE, prefixIcon: <SocialIcon type="YOUTUBE" /> },
    { label: t('X(Twitter)'), value: InfluencersTabType.TWITTER, prefixIcon: <SocialIcon type="TWITTER" /> },
    { label: t('Facebook'), value: InfluencersTabType.FACEBOOK, prefixIcon: <SocialIcon type="FACEBOOK" /> },
    {
      label: t('Facebook Fanpage'),
      value: InfluencersTabType.FACEBOOK_PAGE,
      prefixIcon: <SocialIcon type="FACEBOOK_PAGE" />
    },
    { label: t('TikTok'), value: InfluencersTabType.TIKTOK, prefixIcon: <SocialIcon type="TIKTOK" /> },
    { label: t('Douyin'), value: InfluencersTabType.DOUYIN, prefixIcon: <SocialIcon type="DOUYIN" /> },
    { label: t('Xiaohongshu'), value: InfluencersTabType.XHS, prefixIcon: <SocialIcon type="XHS" /> },
    { label: t('Threads'), value: InfluencersTabType.THREADS, prefixIcon: <SocialIcon type="THREADS" /> },
    {
      label: t('Post'),
      hidden: !isEngagementPage,
      value: InfluencersTabType.POST,
      prefixIcon: <Icon icon="comment-two-tones" size={14} css={{ paddingRight: '2px' }} />,
      defaultValues: getInitialInfluencerPostsFormValues({
        countryId: '',
        socialMedia: SocialPostType.INSTAGRAM
      })
    },
    {
      label: t('Package'),
      value: InfluencersTabType.PACKAGE,
      hidden: !isEngagementPage || hidePackageTab,
      prefixIcon: <Icon icon="adress-book" size={16} css={{ paddingRight: '2px' }} />
    },
    {
      label: t('Campaign'),
      value: InfluencersTabType.CAMPAIGN,
      hidden: !isEngagementPage || !isAdminStaff,
      prefixIcon: <Icon icon="pad-two-tones" size={16} css={{ paddingRight: '2px' }} />
    }
  ].filter((tab) => !tab.hidden);

  return (
    <SelectorFieldV2<InfluencersFilterFormKeys>
      name="tab"
      menuWidth="auto"
      menuHeight="auto"
      clearable={false}
      options={tabOptions}
      className={className}
      disabled={isChatDemoAccount} // TODO DEMO: remove later
      css={{ width: '160px', flex: 'none' }}
      placeholder={t<string>('Selector.Social Media')}
      // using onChange instead of onSubmit to prevent default form behavior
      // We need to use native navigate function to redirect instead of using setFilter as default
      // Because when tab changed, we need to show the Prompt from the Chatbox Ai Search to alert user before leaving
      onChange={(field, value) => {
        const defaultValues = tabOptions.find((tabO) => tabO.value === value)?.defaultValues;

        navigate({
          // We will keep current searchParams on tab changes when AI chatbox not used
          to: getInfluencerFilterTabUrl(value, isEngagementPage, isPackagePage),
          search: (current: InfluencersBaseSearchSchemaType) => ({
            ...current,
            // when tab changed, we only keep next SNS's filter values, reset other things: sort, limit, page,...
            filter: getInfluencerFilterValuesBasedOnTab({
              ...defaultValues,
              ...values,
              [field]: value
            })
          })
        } as any);
      }}
    />
  );
};
