import { useUserRoles } from '@/auth';
import { useCreatePackageMutation, CompanyAndRolePair, namedOperations } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useCustomHeader, useUpdateDocumentTitle, useAllPackageCompanyOptions } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { PackageForm, PackageFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const AddPackage = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    title: 'appHeader.Add Package',
    appHeader: 'appHeader.Add Package',
    backInHistory: { fallback: '/package' }
  });
  const { isAdmin } = useUserRoles();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { allPackageCompanies } = useAllPackageCompanyOptions({ skip: !isAdmin });
  const { callCreatePackage } = useCreatePackageMutation({ refetchQueries: [namedOperations.Query.AllPackages] });

  const handleSubmit: FormAction<PackageFormValues>['onSubmit'] = async ({ name, countryId, companies }) => {
    try {
      const companyAndRolePairs = isAdmin
        ? allPackageCompanies.reduce<CompanyAndRolePair[]>(
            (rs, cr) => (companies.includes(String(cr.id)) ? [...rs, { companyId: cr.companyId, role: cr.role }] : rs),
            []
          )
        : [];
      await callCreatePackage({ variables: { input: { name, countryId, companyAndRolePairs } } });

      enqueueSnackbar(t('package has been saved successfully'), { variant: 'success' });
      navigate({ to: '/package' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <div css={{ margin: '0 auto', width: THEME.container.details.width }}>
      <Header />

      <PackageForm onSubmit={handleSubmit} />
    </div>
  );
};
