import { useTranslation } from 'react-i18next';
import { UserRoles } from '@/graphql';
import { useAllTalentAgencyOrPartnerOptions } from '@/shared/hooks';
import { SelectorField, SelectorFieldProps } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const TalentAgencyPartnerField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { allTalentAgenciesPartners } = useAllTalentAgencyOrPartnerOptions();

  const options: Option[] = allTalentAgenciesPartners.map(({ name, id, role }) => ({
    value: String(id),
    label: `${name}  (${t(role === UserRoles.TALENT_AGENCY ? 'talent' : 'partner')})`
  }));

  return (
    <SelectorField<InfluencerFormKeys>
      options={options}
      title={t('Selector.Partner/Talent Agency')}
      name={InfluencerSharedKeys.talentAgencyOrPartnerIds}
      multiple
      {...props}
    />
  );
};
