import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { TableCell, Table, ColumnProps, RowProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAuthData } from '@/auth';
import { PaymentPlanName } from '@/graphql';

interface KeyFeaturesTableProps {
  className?: string;
  features: any[];
  skipLastBorder?: boolean;
}

export const FeaturesTable = ({ className, features, skipLastBorder }: KeyFeaturesTableProps) => {
  const { t } = useTranslation();
  const { auth } = useAuthData();

  const currentPlan = auth.subscriptionPlan;
  const columns: ColumnProps[] = [
    { title: t('Key Features') },
    { title: t(`Plan.${PaymentPlanName.BEGINNER}`), styles: styles.column(currentPlan === PaymentPlanName.BEGINNER) },
    { title: t(`Plan.${PaymentPlanName.STANDARD}`), styles: styles.column(currentPlan === PaymentPlanName.STANDARD) },
    {
      title: t(`Plan.${PaymentPlanName.ENTERPRISE}`),
      styles: styles.column(currentPlan === PaymentPlanName.ENTERPRISE)
    }
  ];

  const rows: RowProps[] = features.map((f) => ({
    styles: styles.cell(skipLastBorder),
    cells: [<TableCell.Text value={f.keyFeatureTitle} css={styles.featureName} />, f.tier1, f.tier2, f.tier3]
  }));

  return (
    <div css={styles.wrapper} className={className}>
      <Table hasBorder={false} data={{ rows, columns, rowHeight: '40px' }} />
    </div>
  );
};

const styles = {
  featureName: css({
    fontSize: '12px',
    '& li': { color: THEME.text.colors.gray.lv3 }
  }),
  text: css({ fontSize: '12px', minWidth: 'unset', fontWeight: 700, textAlign: 'center' }),
  wrapper: css({ borderRadius: '3px' }),
  column: (isSelected: boolean) =>
    css({
      width: '208px',
      fontSize: '12px',
      '&:last-of-type': { paddingRight: '8px' },
      '&:not(:first-of-type)': { textAlign: 'center' },
      ...(isSelected ? { backgroundColor: '#FBFBFD !important' } : '')
    }),
  cell: (skipLastBorder?: boolean) =>
    css({
      '&:first-of-type': { borderTop: THEME.border.base },
      '&:last-of-type': skipLastBorder ? {} : { borderBottom: THEME.border.base },
      '> td': {
        '&:last-of-type': { paddingRight: '8px' }
      }
    })
};
