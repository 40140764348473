import { css, CSSObject, Interpolation, SerializedStyles, Theme } from '@emotion/react';
import { ElementType, forwardRef, ReactNode, Ref, MouseEventHandler, HTMLAttributes } from 'react';
import { THEME } from '@/shared/constants';
import { Icon, IconProps } from '../Icon';
import { CircularProgress, CircularProgressProps } from '../Loading';
import { Tooltip, TooltipProps } from '../Tooltip';

type ColorVariants = keyof Omit<typeof THEME.button.variants, 'disabled'>;
interface LoadingProps extends CircularProgressProps {
  size?: number;
  color?: string;
  status?: boolean;
  title?: ReactNode;
  showIcon?: boolean;
  disabledStyles?: SerializedStyles;
  icon?: ReactNode;
}
export interface ButtonProps extends Pick<HTMLAttributes<HTMLElement>, 'id'> {
  type?: string;
  as?: ElementType;
  title?: ReactNode;
  className?: string;
  disabled?: boolean;
  prefixIcon?: IconProps;
  suffixIcon?: IconProps;
  onClick?: MouseEventHandler<HTMLElement>;
  tooltip?: Omit<TooltipProps, 'children'>;
  variant?: ColorVariants;
  loading?: boolean | LoadingProps;
  css?: Interpolation<Theme>;
}

const DefaultButton = <WrapperProps extends Record<string, unknown>>(
  {
    title,
    tooltip,
    loading,
    disabled,
    prefixIcon,
    suffixIcon,
    type = 'button',
    variant = 'default',
    as: Wrapper = 'button',
    customLoader,
    children,
    ...restProps
  }: ButtonProps & WrapperProps,
  ref: Ref<HTMLElement>
) => {
  const { isLoading, loadingTitle, loadingIcon, showLoadingIcon, color, disabledStyles, size, thickness } =
    typeof loading === 'boolean'
      ? {
          isLoading: !!loading,
          loadingTitle: undefined,
          showLoadingIcon: false,
          disabledStyles: '',
          size: undefined,
          thickness: undefined,
          loadingIcon: undefined,
          color: THEME.button.variants.disabled.color || THEME.text.colors.white
        }
      : {
          loadingIcon: loading?.icon,
          isLoading: !!loading?.status,
          loadingTitle: loading?.title,
          color: loading?.color || THEME.button.variants.disabled.color || THEME.text.colors.white,
          showLoadingIcon: !!loading?.showIcon,
          disabledStyles: loading?.disabledStyles,
          size: loading?.size || 18,
          thickness: loading?.thickness || 0.3
        };
  const isDisabled = isLoading || disabled;
  const Content = (
    <Wrapper
      type={type}
      disabled={isDisabled}
      css={[styles.wrapper, styleVariants[variant], disabledStyles]}
      {...restProps}
      ref={ref}
      aria-disabled={isDisabled} // restProps may override this prop
    >
      {isLoading && showLoadingIcon ? (
        <div css={{ marginRight: title ? '8px' : 0 }}>
          {loadingIcon || <CircularProgress size={size} thickness={thickness} backgroundColor={color} />}
        </div>
      ) : prefixIcon ? (
        <Icon size="16px" css={title ? { marginRight: '8px' } : undefined} {...prefixIcon} />
      ) : null}
      {/* Button component require title or icon to display (both of them are optional)
        title undefined mean we are using button icon, so we don't show loading text to avoid break the UI
      */}
      {title ? (isLoading && loadingTitle) || title : null}

      {suffixIcon ? <Icon size="16px" css={title ? { marginLeft: '8px' } : undefined} {...suffixIcon} /> : null}
      {children}
    </Wrapper>
  );

  return (
    <Tooltip position="top" withinPortal {...tooltip}>
      {Content}
    </Tooltip>
  );
};

interface BaseButtonStyles {
  color: string;
  opacity?: number;
  outline?: string;
  background?: string;
  borderColor?: string;
  borderImage?: CSSObject;
}
interface ButtonStylesType extends BaseButtonStyles {
  disabled?: Partial<Pick<BaseButtonStyles, 'color' | 'borderColor' | 'background'>> | CSSObject;
  click?: Partial<Pick<BaseButtonStyles, 'color' | 'borderColor' | 'background' | 'opacity'>>;
  hover?: Partial<Pick<BaseButtonStyles, 'color' | 'borderColor' | 'background' | 'opacity' | 'outline'>>;
}

const generatePaletteStyles = (styleType: ButtonStylesType) =>
  css({
    color: styleType.color,
    background: styleType.background,
    border: styleType.borderColor ? `1px solid ${styleType.borderColor}` : 'none',
    ...(styleType.borderImage ? styleType.borderImage : {}),
    // [aria-disabled="true"] selector as an alternative to the :disabled pseudo-class to support Tanstack's Link component
    '&[aria-disabled="true"]': { ...THEME.button.variants.disabled, ...styleType.disabled },
    '&:not([aria-disabled="true"]):hover': { ...styleType.hover },
    '&:not([aria-disabled="true"]):active': { ...styleType.click }
  });

const styles = {
  wrapper: css({
    flex: 'none',
    display: 'flex',
    padding: '0 8px',
    fontSize: '12px',
    cursor: 'pointer',
    minWidth: '32px',
    fontWeight: 600,
    borderWidth: '1px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    borderStyle: 'solid',
    letterSpacing: '0.5px',
    boxSizing: 'border-box',
    justifyContent: 'center',
    textTransform: 'inherit',
    borderColor: 'transparent',
    height: THEME.button.base.height.lv2,
    borderRadius: THEME.button.base.borderRadius,
    transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease'
  })
};
const styleVariants: Record<ColorVariants, SerializedStyles> = {
  red: generatePaletteStyles(THEME.button.variants.red),
  blue: generatePaletteStyles(THEME.button.variants.blue),
  white: generatePaletteStyles(THEME.button.variants.white),
  green: generatePaletteStyles(THEME.button.variants.green),
  black: generatePaletteStyles(THEME.button.variants.black),
  purple: generatePaletteStyles(THEME.button.variants.purple),
  blueInverted: generatePaletteStyles(THEME.button.variants.blueInverted),
  anyAiGradient: generatePaletteStyles(THEME.button.variants.anyAiGradient),
  orange: generatePaletteStyles(THEME.button.variants.orange),
  coralRed: generatePaletteStyles(THEME.button.variants.coralRed),
  attentionRed: generatePaletteStyles(THEME.button.variants.attentionRed),
  pinkRed: generatePaletteStyles(THEME.button.variants.pinkRed),
  pinkRedInverted: generatePaletteStyles(THEME.button.variants.pinkRedInverted),
  default: generatePaletteStyles(THEME.button.variants.default)
};

export const Button = forwardRef(DefaultButton) as <WrapperProps extends Record<string, unknown>>(
  props: ButtonProps & WrapperProps & { ref?: Ref<HTMLFormElement> }
) => ReturnType<typeof DefaultButton>;
