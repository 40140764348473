import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchFilterDefaults } from '@/shared/validations';
import { DefaultInfluencersProps, InfluencersFilterSchema } from '../types';

export const CampaignInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  keyword: true,
  countryIds: true,
  campaignCategoryIds: true
});
export const CampaignInfluencersSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(CampaignInfluencersFilterSchema, {
    keyword: '',
    tab: '',
    countryIds: [],
    campaignCategoryIds: []
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type CampaignInfluencersSearchSchemaType = z.infer<typeof CampaignInfluencersSearchSchema>;
export type CampaignInfluencersFilterFormValues = z.infer<typeof CampaignInfluencersFilterSchema>;
export type CampaignInfluencersFilterFormKeys = UnionOfKeys<CampaignInfluencersFilterFormValues>;
export interface CampaignInfluencersProps extends DefaultInfluencersProps<CampaignInfluencersFilterFormValues> {
  campaignId: number;
}
