import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteUserProposalSocialAccount.graphql';
import { Mutation, MutationdeleteUserProposalSocialAccountArgs } from '../../__generated__/globalTypes';

export const useDeleteUserProposalSocialAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteUserProposalSocialAccountArgs>
) => {
  const [callDeleteUserProposalSocialAccount, result] = useMutation<
    Mutation,
    MutationdeleteUserProposalSocialAccountArgs
  >(MUTATION, options);

  return { callDeleteUserProposalSocialAccount, ...result };
};

export type DeleteUserProposalSocialAccountMutationFunction = Mutation['deleteUserProposalSocialAccount'];
