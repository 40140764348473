import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SwitchRoleToAdmin.graphql';
import { Mutation, MutationswitchRoleToAdminArgs } from '../../__generated__/globalTypes';

export const useSwitchRoleToAdminMutation = (
  options?: MutationHookOptions<Mutation, MutationswitchRoleToAdminArgs>
) => {
  const [callSwitchRoleToAdmin, result] = useMutation<Mutation, MutationswitchRoleToAdminArgs>(MUTATION, options);

  return { callSwitchRoleToAdmin, ...result };
};

export type SwitchRoleToAdminMutationFunction = Mutation['switchRoleToAdmin'];
