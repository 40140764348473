import { useAuthData, usePermissions } from '@/auth';
import {
  UserRoles,
  useUpdateAdminUserMutation,
  useUpdateStaffUserMutation,
  useUpdateAgencyUserMutation,
  useSwitchRoleToStaffMutation,
  useUpdatePartnerUserMutation,
  useSwitchRoleToAdminMutation,
  useSwitchRoleToAgencyMutation,
  useSwitchRoleToPartnerMutation,
  useUpdateAdvertiserUserMutation,
  useSwitchRoleToAdvertiserMutation,
  useUpdateTalentAgencyUserMutation,
  useSwitchRoleToTalentAgencyMutation,
  useAddStaffAdvertisersToAnotherStaffMutation
} from '@/graphql';
import { UNEXPECTED_ERROR, SUPPORTED_USER_ROLES } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { UserFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

interface Props {
  isUserSettingsForAdvertisers?: boolean;
}

export const useUpdateUser = ({ isUserSettingsForAdvertisers }: Props) => {
  const {
    auth: { userId, role }
  } = useAuthData();
  const { canCallCustomPermissions } = usePermissions();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const refetchQueries = canCallCustomPermissions ? ['GetUserPermissions'] : [];

  const { callUpdateAdminUser } = useUpdateAdminUserMutation({ refetchQueries });
  const { callUpdateStaffUser } = useUpdateStaffUserMutation({ refetchQueries });
  const { callUpdateAgencyUser } = useUpdateAgencyUserMutation({ refetchQueries });
  const { callUpdatePartnerUser } = useUpdatePartnerUserMutation({ refetchQueries });
  const { callUpdateAdvertiserUser } = useUpdateAdvertiserUserMutation({ refetchQueries });
  const { callUpdateTalentAgencyUser } = useUpdateTalentAgencyUserMutation({ refetchQueries });

  const { callSwitchRoleToAdmin } = useSwitchRoleToAdminMutation({ refetchQueries });
  const { callSwitchRoleToStaff } = useSwitchRoleToStaffMutation({ refetchQueries });
  const { callSwitchRoleToAgency } = useSwitchRoleToAgencyMutation({ refetchQueries });
  const { callSwitchRoleToPartner } = useSwitchRoleToPartnerMutation({ refetchQueries });
  const { callSwitchRoleToAdvertiser } = useSwitchRoleToAdvertiserMutation({ refetchQueries });
  const { callSwitchRoleToTalentAgency } = useSwitchRoleToTalentAgencyMutation({ refetchQueries });
  const { callAddStaffAdvertisersToAnotherStaff } = useAddStaffAdvertisersToAnotherStaffMutation();

  const updateUser: (oldUserType?: UserRoles) => FormAction<UserFormValues>['onSubmit'] =
    (oldUserType) =>
    async ({
      id,
      name,
      email,
      userType,
      password,
      agencyId,
      countryId,
      partnerId,
      permissions,
      advertiserId,
      advertiserIds,
      talentAgencyId,
      notificationSetting,
      autoLinkToAdvertiser
    }) => {
      const input = {
        name,
        email,
        countryId,
        id: Number(id),
        permissions: SUPPORTED_USER_ROLES.includes(userType) ? permissions : undefined,
        ...(!!password && { password })
      };
      const isEditingHimself = userId === id;
      const isRoleChanged = oldUserType !== userType;
      const allAdvertisersIds = advertiserIds.map(Number);

      try {
        if (isRoleChanged) {
          switch (userType) {
            case UserRoles.ADMINISTRATOR:
              await callSwitchRoleToAdmin({ variables: { input } });
              break;
            case UserRoles.STAFF:
              await callSwitchRoleToStaff({ variables: { input: { ...input, advertiserIds: allAdvertisersIds } } });
              break;
            case UserRoles.AGENCY:
              await callSwitchRoleToAgency({ variables: { input: { ...input, agencyId: Number(agencyId) } } });
              break;
            case UserRoles.TALENT_AGENCY:
              await callSwitchRoleToTalentAgency({
                variables: { input: { ...input, talentAgencyId: Number(talentAgencyId) } }
              });
              break;
            case UserRoles.PARTNER:
              await callSwitchRoleToPartner({ variables: { input: { ...input, partnerId: Number(partnerId) } } });
              break;
            case UserRoles.ADVERTISER:
              await callSwitchRoleToAdvertiser({
                variables: { input: { ...input, notificationSetting, advertiserId: Number(advertiserId) } }
              });
              break;
            default:
              break;
          }
        } else {
          switch (userType) {
            case UserRoles.ADMINISTRATOR:
              await callUpdateAdminUser({ variables: { input } });
              break;
            case UserRoles.STAFF:
              if (role === UserRoles.STAFF && !isEditingHimself) {
                await callAddStaffAdvertisersToAnotherStaff({
                  variables: { input: { otherStaffUserId: Number(id), advertiserIds: allAdvertisersIds } }
                });
              } else {
                await callUpdateStaffUser({
                  variables: { input: { ...input, autoLinkToAdvertiser, advertiserIds: allAdvertisersIds } }
                });
              }
              break;
            case UserRoles.AGENCY:
              await callUpdateAgencyUser({ variables: { input: { ...input, agencyId: Number(agencyId) } } });
              break;
            case UserRoles.TALENT_AGENCY:
              await callUpdateTalentAgencyUser({ variables: { input } });
              break;
            case UserRoles.PARTNER:
              await callUpdatePartnerUser({ variables: { input } });
              break;
            case UserRoles.ADVERTISER:
              await callUpdateAdvertiserUser({
                variables: { input: { ...input, notificationSetting, advertiserId: Number(advertiserId) } }
              });
              break;
            default:
              break;
          }
        }

        enqueueSnackbar(t('succeededInUpdating', { name }), { variant: 'success' });
        if (!isUserSettingsForAdvertisers) {
          navigate({ to: '/user' });
        }
      } catch (error) {
        enqueueSnackbar(t('failedToUpdate', { name }), { variant: 'error' });
        enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
      }
    };

  return { updateUser };
};
