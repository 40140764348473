import { useTranslation } from 'react-i18next';
import { Modal, TextCutter, ModalTitle, ModalProps, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { PostingHabits, InfluencerAvatar } from '@/shared/molecules';

export interface AnalyticsComparePostsHabitModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  className?: string;
  account?: { name?: string; avatar?: string };
  data?: readonly {
    hour: number;
    weekday: number;
    averageLikes: number;
    engagementRate: number;
    averageComments: number;
  }[];
}

export const AnalyticsComparePostsHabitModal = ({
  open,
  account,
  onClose,
  data = [],
  className
}: AnalyticsComparePostsHabitModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} css={{ width: '488px' }} className={className}>
      <ModalContent css={{ paddingBottom: 0 }}>
        {account ? (
          <ModalTitle css={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
            <InfluencerAvatar size="24px" src={account?.avatar} css={{ borderRadius: '50%', marginRight: '8px' }} />
            <TextCutter lines={1} text={t('Dialog.PostingHabits', { name: account?.name })} />
          </ModalTitle>
        ) : null}

        <PostingHabits header="" data={data} css={{ padding: 0, minHeight: '284px' }} />
      </ModalContent>
      <ModalFooterActions cancelButtonProps={{ onClick: onClose }} />
    </Modal>
  );
};
