import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { Input, Modal, ModalContent, ModalFooterActions, ModalProps, ModalTitle } from '@/shared/atoms';

export interface AddHashtagModalProps extends ModalProps {
  loading?: boolean;
  onAdd: (newHashtag: string) => void;
}

export const AddHashtagModal = ({ open, loading, onAdd, onClose, ...restProps }: AddHashtagModalProps) => {
  const { t } = useTranslation();
  const [hashtagValue, setHashtagValue] = useState<string>('');

  useEffect(
    () => () => {
      if (!open && hashtagValue) {
        setHashtagValue('');
      }
    },
    [open, hashtagValue]
  );

  const handleSubmit = () => {
    if (hashtagValue) {
      onAdd(hashtagValue);
    }
  };

  const handlePressEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Modal open={open} onClose={onClose} css={{ width: THEME.modal.size.lv1 }} {...restProps}>
      <ModalContent>
        <ModalTitle>{t('Dialog.Add Hashtag')}</ModalTitle>
        <p css={{ margin: '16px 0', fontSize: '13px' }}>{t('Dialog.Enter the name of the hashtag you wish to add')}</p>

        <Input
          value={hashtagValue}
          onKeyPress={handlePressEnter}
          placeholder={t<string>('TextForm.Hashtag')}
          onChange={(e) => setHashtagValue(e.target.value)}
          autoFocus
        />
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{
          loading,
          title: t('Button.Add'),
          disabled: !hashtagValue,
          onClick: () => onAdd(hashtagValue)
        }}
      />
    </Modal>
  );
};
