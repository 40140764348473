import { Toaster } from 'sonner';
import { NotiIcon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export const NotificationProvider = () => (
  <Toaster
    duration={3000}
    icons={{
      success: <NotiIcon icon="check" color={THEME.attention.colors.green} size="14px" />,
      error: <NotiIcon icon="error" color={THEME.attention.colors.red} />,
      info: <NotiIcon icon="info" color={THEME.attention.colors.blue} />,
      warning: <NotiIcon icon="warning" color={THEME.attention.colors.yellow} />
    }}
    style={{}}
    toastOptions={{
      style: {
        borderRadius: '3px',
        padding: '12px'
      }
    }}
  />
);
