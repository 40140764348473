import { useTranslation } from 'react-i18next';
import { useInfluencerPermissions } from '@/auth';
import { SocialAccountStatus, namedOperations, useDeleteInfluencerMutation } from '@/graphql';
import { Modal, ModalContent, ModalTitle, ModalFooterActions } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useDeleteMutation, useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { InfluencerAvatar, InfluencerWidgetInformation } from '@/shared/molecules';
import { bigIntFormatter, formatIntNumber, formatPercent } from '@/shared/utils';
import { TagsSection, StatsSection, SocialMediasSection, ProfileWidgetTemplate } from '../../shared';
import { DefaultProfileWidgetProps } from '../../types';
import { InfluencerProfile } from '../schemaTypes';

interface ProfileWidgetProps extends DefaultProfileWidgetProps<InfluencerProfile> {
  isEngagementPage?: boolean;
}

export const ProfileWidget = ({
  className,
  isEngagementPage,
  selectButtonProps,
  profile: {
    id,
    age,
    xhs,
    tags,
    name,
    email,
    avatar,
    status,
    tiktok,
    douyin,
    gender,
    twitter,
    threads,
    youtube,
    country,
    facebook,
    instagram,
    followers,
    engagement,
    engagementViewsRate,
    engagementFollowersRate,
    isBrandAccount
  }
}: ProfileWidgetProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const { hideEditInfluencerBtn, hideDeleteInfluencerBtn } = useInfluencerPermissions();
  const { loading, callDeleteInfluencer } = useDeleteInfluencerMutation({
    refetchQueries: [namedOperations.Query.AllInfluencersV4]
  });
  const { isOpenConfirmModal, handleDeleteItem, toggleConfirmModal } = useDeleteMutation({ id, name });

  const stats = [
    { label: t('HeaderColumn.Total Followers'), value: bigIntFormatter(followers, 0) },
    { label: t('HeaderColumn.Engagement'), value: formatIntNumber(engagement) },
    { label: t('HeaderColumn.Egg Rate by views'), value: formatPercent(engagementViewsRate) },
    { label: t('HeaderColumn.Egg Rate by followers'), value: formatPercent(engagementFollowersRate) }
  ];
  const isSignedUp = status === SocialAccountStatus.SIGNED_UP;
  const profileUrl = generateProfileLink(id);
  const hasSpreadButton = !isEngagementPage && !hideEditInfluencerBtn && !hideDeleteInfluencerBtn;

  return (
    <>
      <ProfileWidgetTemplate className={className} selectButtonProps={selectButtonProps}>
        <InfluencerAvatar size="80px" src={avatar} isBrandAccount={isBrandAccount} />

        <InfluencerWidgetInformation
          name={name}
          age={age}
          gender={gender}
          country={country}
          profileUrl={profileUrl}
          isSignedUp={isSignedUp}
          spreadButtonProps={
            hasSpreadButton
              ? {
                  collapseFrom: 0,
                  options: [
                    {
                      icon: 'edit',
                      label: t('Edit'),
                      disabled: !id,
                      to: '/influencer/$id',
                      params: { id: id.toString() },
                      tooltip: { help: t('Button.Edit') }
                    },
                    {
                      icon: 'delete',
                      label: t('Delete'),
                      onClick: toggleConfirmModal,
                      tooltip: { help: t('Button.Delete') }
                    }
                  ]
                }
              : undefined
          }
        />

        <StatsSection id={id} stats={stats} css={{ marginRight: '16px' }} />

        <TagsSection influencerId={id} tags={tags} />

        <SocialMediasSection
          xhs={xhs}
          email={email}
          tiktok={tiktok}
          twitter={twitter}
          youtube={youtube}
          threads={threads}
          facebook={facebook}
          instagram={instagram}
          douyin={douyin}
          profileUrl={profileUrl}
        />
      </ProfileWidgetTemplate>

      <Modal open={isOpenConfirmModal} onClose={toggleConfirmModal} css={{ width: 'max-content', maxWidth: '600px' }}>
        <ModalContent>
          <ModalTitle>{t('Dialog.Are you sure you want to delete', { name })}?</ModalTitle>

          <div css={{ paddingTop: '16px', color: THEME.text.colors.red.lv1, lineHeight: '20px' }}>
            <p>{t(`The influencer's revenue with this account will be removed`)}</p>
            <p>{t(`The campaign's history the influencer has joined will be deleted`)}</p>
          </div>
        </ModalContent>

        <ModalFooterActions
          cancelButtonProps={{ onClick: toggleConfirmModal }}
          submitButtonProps={{
            loading,
            title: t('Button.Delete'),
            onClick: () => handleDeleteItem(async () => await callDeleteInfluencer({ variables: { id } }))
          }}
        />
      </Modal>
    </>
  );
};
