import { DownloadFileProps, downloadFile } from '@/shared/utils';
import { useQueryHelper } from './useQueryHelper';
import { useToggleState } from './useToggleState';

export const useDownloadFile = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { status: downloading, toggleStatus: toggleDownloading } = useToggleState();

  const handleDownloadFile = async (props: DownloadFileProps) => {
    toggleDownloading();
    const { ok, error } = await downloadFile(props);

    if (!ok || !!error) {
      console.error(error);
      enqueueSnackbar(t('failedToDownload'), { variant: 'error' });
    }

    toggleDownloading();
  };

  return { downloading, handleDownloadFile };
};
