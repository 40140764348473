import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/EngagementCampaignPostReportAudienceBreakdown.graphql';
import { Query, QueryengagementCampaignPostReportAudienceBreakdownArgs } from '../../__generated__/globalTypes';

export const useEngagementCampaignPostReportAudienceBreakdownQuery = (
  options?: QueryHookOptions<Query, QueryengagementCampaignPostReportAudienceBreakdownArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryengagementCampaignPostReportAudienceBreakdownArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useEngagementCampaignPostReportAudienceBreakdownLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryengagementCampaignPostReportAudienceBreakdownArgs>
): QueryResult<Query, QueryengagementCampaignPostReportAudienceBreakdownArgs> & {
  getEngagementCampaignPostReportAudienceBreakdown: LazyQueryExecFunction<
    Query,
    QueryengagementCampaignPostReportAudienceBreakdownArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getEngagementCampaignPostReportAudienceBreakdown, result] = useLazyQuery<
    Query,
    QueryengagementCampaignPostReportAudienceBreakdownArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getEngagementCampaignPostReportAudienceBreakdown, ...result };
};

export const useEngagementCampaignPostReportAudienceBreakdownPromiseQuery = () => {
  const client = useApolloClient();

  const getEngagementCampaignPostReportAudienceBreakdown = useCallback(
    (options?: Omit<QueryOptions<QueryengagementCampaignPostReportAudienceBreakdownArgs, Query>, 'query'>) =>
      client.query<Query, QueryengagementCampaignPostReportAudienceBreakdownArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getEngagementCampaignPostReportAudienceBreakdown };
};
