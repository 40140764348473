import { useUserRoles } from '@/auth';
import { EngagementCampaignStatus } from '@/graphql';
import { useTranslateOptions } from '@/shared/hooks';

export const useEngagementStatusOptions = () => {
  const { isAdvertiser } = useUserRoles();
  const engagementStatusOptions = useTranslateOptions([
    { label: isAdvertiser ? 'Waiting Proposal' : 'Draft', value: EngagementCampaignStatus.DRAFT },
    { label: 'Demo', value: EngagementCampaignStatus.DEMO },
    { label: 'Reviewing', value: EngagementCampaignStatus.REVIEWING },
    { label: 'Upcoming', value: EngagementCampaignStatus.UPCOMING },
    { label: 'Ongoing', value: EngagementCampaignStatus.ONGOING },
    { label: 'Finished', value: EngagementCampaignStatus.FINISHED },
    ...(!isAdvertiser ? [{ label: 'Lost', value: EngagementCampaignStatus.LOST }] : [])
  ]);

  return { engagementStatusOptions };
};
