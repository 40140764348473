import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/BulkUpdatePackageProposalInfluencersBudget.graphql';
import { Mutation, MutationbulkUpdatePackageProposalInfluencersBudgetArgs } from '../../__generated__/globalTypes';

export const useBulkUpdatePackageProposalInfluencersBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationbulkUpdatePackageProposalInfluencersBudgetArgs>
) => {
  const [callBulkUpdatePackageProposalInfluencersBudget, result] = useMutation<
    Mutation,
    MutationbulkUpdatePackageProposalInfluencersBudgetArgs
  >(MUTATION, options);

  return { callBulkUpdatePackageProposalInfluencersBudget, ...result };
};

export type BulkUpdatePackageProposalInfluencersBudgetMutationFunction =
  Mutation['bulkUpdatePackageProposalInfluencersBudget'];
