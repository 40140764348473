import { useTranslation } from 'react-i18next';
import { CustomerValues, BigQuerySearchSelector, BigQuerySearchSelectorProps } from '../BigQuerySearchSelector';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export interface BigQuerySearchFieldProps<T extends string>
  extends Omit<DefaultFieldProps<T, string>, 'onChange'>,
    Pick<BigQuerySearchSelectorProps, 'type'> {
  onChange?: (field: T, value: CustomerValues) => void;
}

export const BigQuerySearchField = <T extends string>(props: BigQuerySearchFieldProps<T>) => {
  const { t } = useTranslation();
  const {
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, onChange, value, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper {...fieldWrapperProps} title={fieldWrapperProps.title ?? t('Selector.Hubspot Customer')}>
      <BigQuerySearchSelector
        {...restFieldProps}
        entityIdFromApi={value}
        fillFormValues={(customer) =>
          onChange ? onChange(name, customer) : setValue<string>(name, String(customer.value || ''), setValueOptions)
        }
      />
    </RHFFieldWrapper>
  );
};

export { type CustomerValues };
