import { getValuableVariables } from '@/shared/utils';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { SocialPostType, useAllInfluencerPostsV3Query } from '@/graphql';
import { formatInfluencerPostProfile } from '../utils';
import { InfluencersPostsSearchSchemaType } from '../schemaTypes';
import { useGetInfluencersQueryVariables } from '../../hooks';

type Props = Pick<ParamsSearchReturnType<InfluencersPostsSearchSchemaType>, 'page' | 'limit' | 'filter' | 'sort'>;

export const useInfluencerPostsList = ({ page, sort, limit, filter }: Props) => {
  const { variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { data, loading } = useAllInfluencerPostsV3Query({
    skip: !filter.socialMedia,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: getValuableVariables({
      ...variables,
      orderBy: sort,
      socialPostType: filter.socialMedia as SocialPostType
    })
  });

  const allPosts = data?.allInfluencerPostsV3.posts || [];

  return {
    loading,
    limit: variables.limit,
    totalRecords: data?.allInfluencerPostsV3?.totalCount || 0,
    listRecords: data?.allInfluencerPostsV3.posts.map((post) => formatInfluencerPostProfile(post, allPosts)) || []
  };
};
