import { getBarDataBasedOnPointer } from '@/shared/utils';

interface Props {
  postColors: string[];
  postsData: number[][];
  onFetchPosts: (date: string) => Promise<void> | void;
}

export const useFetchDailyPostsOnChart = ({ postColors, postsData, onFetchPosts }: Props) => {
  const customChartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      events: {
        click(e) {
          const selectedPoint = getBarDataBasedOnPointer(this, e as Highcharts.ChartClickEventObject, postColors);
          // if Y-axis value is other than 0, means that there are some posts on this day
          if (selectedPoint?.length && selectedPoint.some((point) => point.y)) {
            onFetchPosts(String(selectedPoint.at(0)?.category));
          }
        }
      }
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click() {
              if (this.color && postColors.includes(this.color as string)) {
                onFetchPosts(String(this.category));
              }
            }
          }
        }
      }
    }
  };

  return { customChartOptions };
};
