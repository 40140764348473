import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeComparePostList.graphql';
import { Query, QueryyoutubeComparePostListArgs } from '../../__generated__/globalTypes';

export const useYoutubeComparePostListQuery = (options?: QueryHookOptions<Query, QueryyoutubeComparePostListArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeComparePostListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeComparePostListLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeComparePostListArgs>
): QueryResult<Query, QueryyoutubeComparePostListArgs> & {
  getYoutubeComparePostList: LazyQueryExecFunction<Query, QueryyoutubeComparePostListArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeComparePostList, result] = useLazyQuery<Query, QueryyoutubeComparePostListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeComparePostList, ...result };
};

export const useYoutubeComparePostListPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeComparePostList = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeComparePostListArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeComparePostListArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeComparePostList };
};
