import React, { createContext, useContext } from 'react';
import { ListWithPagination } from '@/shared/molecules';
import { SocialAccountInfluencerListSortFieldV2 } from '@/graphql';
import { useInfluencerParamsSearch } from '../hooks';
import { InfluencersContextReturnType } from '../types';
import { useInstagramInfluencersList } from './hooks';
import {
  InstagramInfluencersProps,
  InstagramInfluencerProfile,
  InstagramInfluencersFilterFormValues,
  InstagramInfluencersSearchSchemaType
} from './schemaTypes';

type ContextType = InfluencersContextReturnType<
  InstagramInfluencerProfile,
  InstagramInfluencersFilterFormValues,
  SocialAccountInfluencerListSortFieldV2
>;

const Context = createContext<ContextType>({} as ContextType); // Context must have values, so we won't set empty values here

interface Props {
  value: InstagramInfluencersProps;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const InstagramInfluencersProvider = ({
  children,
  value: { isTtcmCampaign, isPackagePage, isEngagementPage, getSortSelectButtonProps, getListSelectButtonProps }
}: Props) => {
  const paramsSearch = useInfluencerParamsSearch<InstagramInfluencersSearchSchemaType>();
  const { page, sort, limit, filter } = paramsSearch;
  const listProps = useInstagramInfluencersList({ page, sort, limit, filter });

  return (
    <Context.Provider
      value={{
        ...paramsSearch,
        ...listProps,
        isPackagePage,
        isTtcmCampaign,
        isEngagementPage,
        getListSelectButtonProps,
        sortSelectButtonProps: getSortSelectButtonProps?.(listProps.listRecords, listProps.totalRecords)
      }}
    >
      <ListWithPagination pagination={{ totalRecords: listProps.totalRecords }}>{children}</ListWithPagination>
    </Context.Provider>
  );
};

export const useInstagramInfluencersContext = () => useContext<ContextType>(Context);
