import { useTranslation } from 'react-i18next';
import { AutoManagedJoinedInfluencer } from '@/graphql';
import { ColumnProps, RowProps, TableCell, Anchor } from '@/shared/atoms';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { InfluencerAvatar } from '@/shared/molecules';

interface Props {
  data: readonly AutoManagedJoinedInfluencer[];
}

export const useAutoManagedJoinedInfluencersTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Influencer'), sticky: { active: true } },
    { title: t('HeaderColumn.Social Media / Promotion method'), styles: { width: '20%' } },
    { title: t('HeaderColumn.Joined Date') }
  ];
  const rows: RowProps[] = data.map(({ joinedDate, influencer, promotionMethods }) => {
    const profileLink = generateProfileLink(influencer.id);

    return [
      <div css={{ minWidth: '200px', display: 'flex', alignItems: 'center' }}>
        <InfluencerAvatar
          size="48px"
          src={influencer.avatar}
          css={{ borderRadius: 0, backgroundColor: '#EEF3F7' }}
          id={influencer.id}
        />
        <Anchor label={influencer.name} css={{ marginLeft: '8px' }} {...profileLink} />
      </div>,
      <TableCell.Text value={promotionMethods.join(', ') || '-'} />,
      <TableCell.Date value={joinedDate} />
    ];
  });

  return { rows, columns };
};
