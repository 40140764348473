import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, FormStyle } from '@/shared/atoms';
import { TextField } from '@/shared/molecules';
import { formatPercent } from '@/shared/utils';
import { AutoManagedCampaignFormKeys, AutoManagedCampaignFormValues } from '../types';

export const Rates = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<AutoManagedCampaignFormValues>();

  const [marginRateValue, commissionRateValue, influencerCommissionRateValue] = watch([
    'marginRate',
    'commissionRate',
    'influencerCommissionRate'
  ]);

  useEffect(() => {
    const marginRate = Number(marginRateValue || 0);
    const influencerCommissionRate = commissionRateValue ? ((100 - marginRate) * commissionRateValue) / 100 : null;

    setValue('influencerCommissionRate', influencerCommissionRate);
  }, [marginRateValue, commissionRateValue]);

  return (
    <div css={{ padding: '24px' }}>
      <FormStyle.SectionTitle index={2} label={t('Commission rate / Margin rate')} />

      <FormStyle.FieldsGroup itemsPerRow={4}>
        <FormStyle.FieldWrapper>
          <Label
            css={styles.label}
            help={t('Tooltip.commissionRateHelp')}
            title={t<string>('TextForm.Commission Rate')}
          />
          <p css={styles.text}>{formatPercent(commissionRateValue, false)}%</p>
        </FormStyle.FieldWrapper>

        <TextField<AutoManagedCampaignFormKeys>
          unit="%"
          name="marginRate"
          placeholder="10"
          numberWithDecimals
          css={styles.textForm}
          title={t('TextForm.AnyMind Margin Rate')}
          help={t('Tooltip.AutoManagedAnyMindMarginRate')}
        />

        <FormStyle.FieldWrapper>
          <Label
            css={styles.label}
            help={t('Tooltip.influencerCommissionRateHelp')}
            title={t('TextForm.Influencer Commission Rate')}
          />
          <p css={styles.text}>{formatPercent(influencerCommissionRateValue, false)}%</p>
        </FormStyle.FieldWrapper>
      </FormStyle.FieldsGroup>
    </div>
  );
};

const styles = {
  text: css({ fontSize: '14px' }),
  textForm: css({ label: { fontSize: '14px' } }),
  label: css({ padding: 0, fontSize: '14px', marginBottom: '8px' })
};
