import {
  useSearchPrivateTalentInfluencersPromiseQuery,
  useSearchPrivatePartnerInfluencersPromiseQuery
} from '@/graphql';
import { Option } from '@/shared/types';

interface Props {
  agencyId: number;
  alreadySelectedInfluencers: number[];
  agencyType: 'talent' | 'partner';
}

export const useSearchPrivateInfluencers = ({ agencyId, agencyType, alreadySelectedInfluencers }: Props) => {
  const { getSearchPrivateTalentInfluencers } = useSearchPrivateTalentInfluencersPromiseQuery();
  const { getSearchPrivatePartnerInfluencers } = useSearchPrivatePartnerInfluencersPromiseQuery();

  const searchTalentInfluencers = async (inputValue: string): Promise<Option<any>[]> =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await getSearchPrivateTalentInfluencers({
          variables: {
            keyword: inputValue,
            talentAgencyId: agencyId,
            alreadySelectedInfluencers
          }
        });
        const formattedData =
          data?.searchPrivateTalentInfluencers?.map((infl) => ({ value: infl.id, label: infl.name })) || [];

        resolve(formattedData);
      }, 500);
    });

  const searchPartnerInfluencers = async (inputValue: string): Promise<Option<any>[]> =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await getSearchPrivatePartnerInfluencers({
          variables: {
            keyword: inputValue,
            partnerId: agencyId,
            alreadySelectedInfluencers
          }
        });
        const formattedData =
          data?.searchPrivatePartnerInfluencers?.map((infl) => ({ value: infl.id, label: infl.name })) || [];

        resolve(formattedData);
      }, 500);
    });

  const getQuery = () => {
    switch (agencyType) {
      case 'talent':
        return searchTalentInfluencers;
      case 'partner':
        return searchPartnerInfluencers;

      default:
        return (): void => undefined;
    }
  };

  return {
    searchPrivateInfluencers: getQuery()
  };
};
