import { useState } from 'react';
import { UserProposalSocialAcccountBudgetInput } from '@/graphql';
import { useDeepCompareEffect } from '@/shared/hooks';
import { ProposedInfluencer } from '../schemaTypes';

interface SocialAccountsStateHandlerProps {
  allSocialAccounts: UserProposalSocialAcccountBudgetInput[];
  allSocialAccountIds: number[];
}
export const useSocialAccountsStateHandler = ({
  allSocialAccounts,
  allSocialAccountIds
}: SocialAccountsStateHandlerProps) => {
  const [selectedSocialAccountIds, setSelectedSocialAccountIds] = useState<number[]>([]);

  const selectedSocialAccounts = allSocialAccounts.filter((acc) =>
    selectedSocialAccountIds.includes(acc.socialAccountId)
  );

  const handleToggleSelectAllAccounts = () => {
    setSelectedSocialAccountIds(
      selectedSocialAccountIds.length === allSocialAccountIds.length ? [] : allSocialAccountIds
    );
  };

  const handleSelectSingleAccount = (id: number) => {
    const newIds = [...selectedSocialAccountIds];

    if (newIds.includes(id)) {
      const index = newIds.findIndex((newId) => newId === id);
      newIds.splice(index, 1);
    } else {
      newIds.push(id);
    }

    setSelectedSocialAccountIds(newIds);
  };

  return {
    selectedSocialAccounts,
    selectedSocialAccountIds,
    setSelectedSocialAccountIds,
    handleToggleSelectAllAccounts,
    handleSelectSingleAccount
  };
};

export const useInfluencersStateHandler = (listInfluencers: ProposedInfluencer[]) => {
  const [selectedInfluencerAccountIds, setSelectedInfluencerAccountIds] = useState<number[]>([]);

  useDeepCompareEffect(() => {
    setSelectedInfluencerAccountIds([]);
  }, [listInfluencers]);

  const allInfluencers: Pick<
    UserProposalSocialAcccountBudgetInput,
    'budget' | 'profit' | 'influencerId' | 'influencerCost'
  >[] = [];
  const allInfluencerIds = listInfluencers.map(({ id, budget, influencerCost, profit }) => {
    allInfluencers.push({
      influencerId: id,
      profit: profit || 0,
      budget: budget || 0,
      influencerCost: influencerCost || 0
    });

    return id;
  });
  const selectedInfluencers = allInfluencers.filter((infl) => selectedInfluencerAccountIds.includes(infl.influencerId));

  const handleToggleSelectAllInfluencers = () => {
    setSelectedInfluencerAccountIds(
      selectedInfluencerAccountIds.length === allInfluencerIds.length ? [] : allInfluencerIds
    );
  };

  const handleSelectSingleInfluencer = (id: number) => {
    const newIds = [...selectedInfluencerAccountIds];

    if (newIds.includes(id)) {
      const index = newIds.findIndex((newId) => newId === id);
      newIds.splice(index, 1);
    } else {
      newIds.push(id);
    }

    setSelectedInfluencerAccountIds(newIds);
  };

  return {
    allInfluencers,
    allInfluencerIds,
    selectedInfluencers,
    selectedInfluencerAccountIds,
    setSelectedInfluencerAccountIds,
    handleToggleSelectAllInfluencers,
    handleSelectSingleInfluencer
  };
};
