import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { InputUnitProps, InputUnit } from '../../Input';

interface InputProps extends InputUnitProps {
  className?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({ unit, className, ...restProps }, ref) => (
  <div css={{ display: 'flex', alignItems: 'center' }} className={className}>
    <InputUnit ref={ref} css={styles.input} {...restProps} />

    {unit ? <span css={styles.unit}>{unit}</span> : null}
  </div>
));

const styles = {
  input: css({ width: '100px', fontSize: '14px', textAlign: 'right' }),
  unit: css({ color: '#8694a2', fontSize: '10px', marginLeft: '4px' })
};
