import { ReactNode } from 'react';

import { THEME } from '@/shared/constants';

export interface ChartTooltipSingleMetricProps {
  color?: string;
  name?: ReactNode;
  value?: ReactNode;
  category?: ReactNode;
}

// Must be inline-style because this component will be rendered via renderToStaticMarkup where it used
export const ChartTooltipSingleMetric = ({ color, name, category, value = '-' }: ChartTooltipSingleMetricProps) => (
  <div
    style={{
      borderRadius: '3px',
      padding: '13px 16px',
      color: THEME.text.colors.white,
      backgroundColor: THEME.background.colors.black
    }}
  >
    {category ? <p style={{ marginBottom: '8px' }}>{category}</p> : null}
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {color ? (
        <div
          style={{
            width: '12px',
            height: '12px',
            marginRight: '8px',
            display: 'inline-block',
            backgroundColor: color as string
          }}
        />
      ) : null}
      {name ? <div style={{ marginRight: '16px' }}>{name}</div> : null}
      {value}
    </div>
  </div>
);
