import { useTranslation } from 'react-i18next';
import { HubspotDealData } from '@/graphql';
import { HubspotDealsMultiSelector } from '../HubspotDealsMultiSelector';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export interface HubspotDealsFieldProps<T extends string = string>
  extends Omit<DefaultFieldProps<T, string[]>, 'onChange'> {
  countryId?: string;
  onChange?: (field: T, value: string[], options?: HubspotDealData[]) => void;
}

export const HubspotDealsField = <T extends string>(props: HubspotDealsFieldProps<T>) => {
  const { t } = useTranslation();
  const {
    fieldValue,
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, onChange, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  const handleHubspotDealsChange = (hubspotDeals: HubspotDealData[]) => {
    const newValues = hubspotDeals.map((deal) => deal.id);
    if (onChange) {
      onChange(name, newValues, hubspotDeals);
    } else {
      setValue<string>(name, newValues, setValueOptions);
    }
  };

  return (
    <RHFFieldWrapper
      {...fieldWrapperProps}
      title={fieldWrapperProps.title ?? t('Selector.Hubspot Deal')}
      help={fieldWrapperProps.help ?? t('Tooltip.Hubspot deal update may delay')}
    >
      <HubspotDealsMultiSelector {...restFieldProps} hubspotDealIds={fieldValue} onChange={handleHubspotDealsChange} />
    </RHFFieldWrapper>
  );
};
