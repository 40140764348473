import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { generateTimeSlots } from '@/shared/utils';

export interface AnalyticsComparePostsHabitProps {
  className?: string;
  buttonProps?: ButtonProps;
  postsHabit?: readonly {
    hour: number;
    weekday: number;
    averageLikes: number;
    engagementRate: number;
    averageComments: number;
  }[];
}
export const AnalyticsComparePostsHabit = ({ className, postsHabit, buttonProps }: AnalyticsComparePostsHabitProps) => {
  const { t, i18n } = useTranslation();
  const timeslots = generateTimeSlots(postsHabit, i18n.language as AppLanguage);

  return (
    <div css={styles.wrapper} className={className}>
      <div>
        <div css={styles.label}>{t('First time zone')}</div>
        <div css={styles.value}>{timeslots[0]}</div>
      </div>
      <div>
        <div css={styles.label}>{t('Second time zone')}</div>
        <div css={styles.value}>{timeslots[1]}</div>
      </div>
      <div>
        <div css={styles.label}>{t('Third time zone')}</div>
        <div css={styles.value}>{timeslots[2]}</div>
      </div>

      {buttonProps ? <Button prefixIcon={{ icon: 'plus', size: '10px' }} {...buttonProps} /> : null}
    </div>
  );
};
const styles = {
  label: css({ fontSize: '11px' }),
  value: css({ marginTop: '12px', fontSize: '13px', fontWeight: 600 }),
  wrapper: css({
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    color: THEME.text.colors.black.lv1,
    justifyContent: 'space-between'
  })
};
