import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { ToggleOption } from '@/shared/types';
import { RadiosField, RadiosFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const AllowNewInfluencerField = ({
  className,
  required,
  disabled
}: Pick<RadiosFieldProps, 'className' | 'disabled' | 'required'>) => {
  const { t } = useTranslation();

  const options = [
    { value: ToggleOption.TRUE, label: t('Normal campaign'), help: t('Tooltip.NormalCampaign') },
    { value: ToggleOption.FALSE, label: t('Closed campaign'), help: t('Tooltip.ClosedCampaign') }
  ];

  return (
    <FormStyle.FieldWrapper className={className}>
      <p css={{ fontWeight: 600, fontSize: '14px', marginBottom: '16px' }}>
        {t('Choose whether to make this campaign as Closed campaign')}
      </p>
      <RadiosField
        itemsPerRow={2}
        options={options}
        required={required}
        disabled={disabled}
        css={{ padding: 0, fontSize: '14px' }}
        name={MarketplaceFormKeys.allowNewInfluencer}
      />
    </FormStyle.FieldWrapper>
  );
};
