import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AutoManagedReportSummary.graphql';
import { Query, QueryautoManagedReportSummaryArgs } from '../../__generated__/globalTypes';

export const useAutoManagedReportSummaryQuery = (
  options?: QueryHookOptions<Query, QueryautoManagedReportSummaryArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryautoManagedReportSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAutoManagedReportSummaryLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryautoManagedReportSummaryArgs>
): QueryResult<Query, QueryautoManagedReportSummaryArgs> & {
  getAutoManagedReportSummary: LazyQueryExecFunction<Query, QueryautoManagedReportSummaryArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAutoManagedReportSummary, result] = useLazyQuery<Query, QueryautoManagedReportSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAutoManagedReportSummary, ...result };
};

export const useAutoManagedReportSummaryPromiseQuery = () => {
  const client = useApolloClient();

  const getAutoManagedReportSummary = useCallback(
    (options?: Omit<QueryOptions<QueryautoManagedReportSummaryArgs, Query>, 'query'>) =>
      client.query<Query, QueryautoManagedReportSummaryArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAutoManagedReportSummary };
};
