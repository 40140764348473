import { MenuTitle } from '@/router';
import { IconNames } from '@/shared/assets';
import { MenuGroup, MenuType, UserPermissions } from '@/shared/types';
import { getAllMenuItems, getAllowMenuTitlesFromPermission, GetAllMenuItemsProps } from '@/shared/utils';

export const getMenuIcon = (title?: MenuTitle | MenuGroup): IconNames | '' => {
  switch (title) {
    case MenuTitle.Analytics:
      return 'analytics-two-tones';
    case MenuTitle.User:
      return 'contact-two-tones';
    case MenuTitle.Payment:
    case MenuTitle.Account:
    case MenuTitle.Invoice:
      return 'wallet-two-tones';
    case MenuTitle.Settings:
      return 'settings';
    case MenuTitle.HelpCenter:
      return 'help-center';
    case MenuGroup.Campaigns:
    case MenuGroup.InfluencerCampaign:
      return 'promotion-two-tones';
    case MenuGroup.Influencers:
    case MenuTitle.Packages:
      return 'search-two-tones';
    case MenuGroup.Accounts:
    case MenuTitle.TikTokAdsReport:
      return 'book-two-tones';
    case MenuGroup.Trends:
      return 'crown-two-tones';
    case MenuTitle.ReleaseNotes:
      return 'release-notes';
    case MenuTitle.UserSettings:
      return 'user-two-tones';
    case MenuTitle.Language:
      return 'earth';
    case MenuTitle.SignOut:
      return 'sign-out';
    case MenuTitle.YoutubeCMS:
      return 'growth';
    // TODO DEMO: remove later
    case MenuTitle.ChatDemo:
      return 'chat-demo-chat';
    case MenuTitle.AssetPreview:
      return 'tag-fullfill';

    default:
      return '';
  }
};

export const loadingMenuItems: MenuType[] = [
  { groupTitle: MenuGroup.Campaigns, subMenus: [] },
  { groupTitle: MenuGroup.Influencers, subMenus: [] },
  { groupTitle: MenuGroup.Trends, subMenus: [] },
  { groupTitle: MenuGroup.Accounts, subMenus: [] },
  { title: MenuTitle.ReleaseNotes },
  { title: MenuTitle.HelpCenter }
];

export const tiktokMenuItems: MenuType[] = [
  {
    title: MenuTitle.TikTokAdsReport,
    to: '/tiktok/ad-campaign-report',
    subMenus: [
      {
        title: MenuTitle.BudgetAllocation,
        to: '/tiktok/budget-allocation'
      },
      {
        title: MenuTitle.AdAccounts,
        to: '/tiktok/ad-accounts'
      }
    ]
  },
  {
    title: MenuTitle.TikTokCampaigns,
    groupTitle: MenuGroup.InfluencerCampaign,
    to: '/tiktok/campaigns'
  },
  {
    title: MenuTitle.Account,
    to: '/tiktok/advertiser-details',
    subMenus: [
      {
        title: MenuTitle.Billing,
        to: '/tiktok/subscription-billing'
      }
    ]
  }
];

export const getInternalMenuItems = (isAdminStaff: boolean): MenuType[] => [
  { title: MenuTitle.YoutubeCMS, to: '/youtube-cms' },
  ...((isAdminStaff ? [{ title: MenuTitle.Invoice, to: '/invoices' }] : []) satisfies MenuType[])
];

interface AllowMenuItemsFromPermissionsProps extends GetAllMenuItemsProps {
  permissions: UserPermissions | null;
  hideOptions?: MenuTitle[];
}

// Idea
// All menu items will be shown
// permissions: We allow hide some menu items from api permissions
// hideOptions: We allow hide some menu items from FE side logics also
// Main menu will be added automatically if at least a submenu exists
export const getAllowMenuItemsFromPermissions = ({
  permissions,
  isAdvertiser = false,
  hideOptions = []
}: AllowMenuItemsFromPermissionsProps) => {
  const result: MenuType[] = [];
  const menu = getAllMenuItems({ isAdvertiser });

  if (!permissions) {
    return result;
  }

  const allowTitles = getAllowMenuTitlesFromPermission(permissions);

  menu.reduce((rs, item) => {
    if (!hideOptions.includes(item.title as MenuTitle) && allowTitles.includes(item.title as MenuTitle)) {
      let newItem: MenuType = { ...item, subMenus: [] };
      const hasSubMenu = item.subMenus && item.subMenus.length > 0;

      if (hasSubMenu) {
        const subMenus =
          item.subMenus?.filter(
            (sm) => !hideOptions.includes(sm?.title as MenuTitle) && allowTitles.includes(sm?.title as MenuTitle)
          ) || [];

        if (subMenus.length > 0) {
          newItem = { ...newItem, subMenus };
        }
      }

      rs.push(newItem);
    }

    return rs;
  }, result);

  return result;
};
