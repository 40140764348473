import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateAutoManagedCampaign.graphql';
import { Mutation, MutationupdateAutoManagedCampaignArgs } from '../../__generated__/globalTypes';

export const useUpdateAutoManagedCampaignMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateAutoManagedCampaignArgs>
) => {
  const [callUpdateAutoManagedCampaign, result] = useMutation<Mutation, MutationupdateAutoManagedCampaignArgs>(
    MUTATION,
    options
  );

  return { callUpdateAutoManagedCampaign, ...result };
};

export type UpdateAutoManagedCampaignMutationFunction = Mutation['updateAutoManagedCampaign'];
