import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useInfluencerPermissions } from '@/auth';
import { SocialAccountType, InstagramBrandAmbassadorPostsByUsername } from '@/graphql';
import { Anchor, Button, SocialIcon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';
import { AnalyticsSelectedAccountInfo } from '../../AnalyticsProfile';
import { AmbassadorPostsSlider } from './AmbassadorPostsSlider';
import { SimilarAmbassadorPostsList } from './SimilarAmbassadorPostsList';

interface AmbassadorPostWidgetProps {
  data: InstagramBrandAmbassadorPostsByUsername;
  analyticsAccountId: AnalyticsSelectedAccountInfo['id'];
}

export const AmbassadorPostWidget = ({
  analyticsAccountId,
  data: { posts, igUsername, brandMentionedPosts }
}: AmbassadorPostWidgetProps) => {
  const { t } = useTranslation();
  const similarPostsState = useToggleState();
  const { hideSelectInfluencers } = useInfluencerPermissions();

  return (
    <>
      <div css={styles.wrapper}>
        <div css={styles.content}>
          <div css={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <SocialIcon size="18px" type={SocialAccountType.INSTAGRAM} css={{ marginRight: '4px' }} />
            <Anchor
              target="_blank"
              label={igUsername}
              href={`https://www.instagram.com/${igUsername}`}
              css={{ fontWeight: 600, fontSize: '16px', lineHeight: '18px' }}
            />
          </div>
          <p css={{ fontSize: '12px', marginTop: '8px' }}>
            {t('Brand mention posts')}:&nbsp;{formatIntNumber(brandMentionedPosts)}
          </p>
          {!hideSelectInfluencers ? (
            <Button
              title={t('Similar Influencers')}
              onClick={similarPostsState.toggleStatus}
              css={styles.button(similarPostsState.status)}
            />
          ) : null}
        </div>

        <AmbassadorPostsSlider analyticsAccountId={analyticsAccountId} posts={posts} />
      </div>

      {similarPostsState.status ? <SimilarAmbassadorPostsList username={igUsername} /> : null}
    </>
  );
};
const styles = {
  wrapper: css({
    height: '160px',
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-of-type)': { borderBottom: THEME.border.base }
  }),
  content: css({
    flex: 'none',
    width: '190px',
    display: 'flex',
    padding: '0 16px',
    boxSizing: 'border-box',
    flexDirection: 'column'
  }),
  button: (isActive?: boolean) =>
    css({
      height: '18px',
      fontSize: '10px',
      marginTop: '10px',
      color: isActive ? '#3892e5' : '#6E7C89',
      borderColor: isActive ? '#3892e5' : '#DEE5EC',
      backgroundColor: isActive ? '#ebf4fc' : '#fff'
    })
};
