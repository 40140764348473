import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramDashboardOverview.graphql';
import { Query, QueryinstagramDashboardOverviewArgs } from '../../__generated__/globalTypes';

export const useInstagramDashboardOverviewQuery = (
  options?: QueryHookOptions<Query, QueryinstagramDashboardOverviewArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramDashboardOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramDashboardOverviewLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramDashboardOverviewArgs>
): QueryResult<Query, QueryinstagramDashboardOverviewArgs> & {
  getInstagramDashboardOverview: LazyQueryExecFunction<Query, QueryinstagramDashboardOverviewArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramDashboardOverview, result] = useLazyQuery<Query, QueryinstagramDashboardOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInstagramDashboardOverview, ...result };
};

export const useInstagramDashboardOverviewPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramDashboardOverview = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramDashboardOverviewArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramDashboardOverviewArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramDashboardOverview };
};
