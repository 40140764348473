import { ReactNode, createContext, useContext } from 'react';
import { Advertiser, AnalyticsSubscriptionPlan } from '@/graphql';
import { useGetDocumentParentHref } from '@/shared/hooks';

interface ContextType {
  advertiser: Advertiser;
  subscription?: AnalyticsSubscriptionPlan;
}

const AdvertiserDetailsContext = createContext<ContextType>({} as ContextType);

interface Props {
  children: ReactNode;
  values: ContextType;
}

export const AdvertiserDetailsProvider = ({ values: { advertiser, subscription }, children }: Props) => {
  useGetDocumentParentHref({
    href: '/advertiser',
    alwaysBackToParent: true,
    title: 'documentTitle.EditAdvertiser',
    appHeader: advertiser.name ? 'appHeader.EditWithTheName' : 'appHeader.Edit Advertiser',
    ...(advertiser.name ? { interpolation: { name: advertiser.name } } : {})
  });

  return (
    <AdvertiserDetailsContext.Provider value={{ advertiser, subscription }}>
      {children}
    </AdvertiserDetailsContext.Provider>
  );
};

export const useAdvertiserDetailsContext = () => useContext<ContextType>(AdvertiserDetailsContext);
