import { RenderDataWithFallback } from '@/shared/atoms';
import { getValuableVariables } from '@/shared/utils';
import { useTiktokSpecialCampaignReportSummaryQuery } from '@/graphql';
import {
  PostReportEmpty,
  TikTokCampaignReportGrid,
  TikTokCampaignReportFilter,
  TikTokCampaignReportDetails,
  TikTokCampaignReportSummary,
  TikTokCampaignReportProvider,
  useTikTokCampaignReportFilter
} from '@/shared/organisms';
import { PostReportMode } from '@/shared/types';
import { useTikTokCampaignDetailsContext } from '../TikTokCampaignDetailsContext';

export const TikTokCampaignDetailsReport = () => {
  const { tiktokSpecialCampaign } = useTikTokCampaignDetailsContext();
  const {
    filter: { mode, keyword, endDate, startDate }
  } = useTikTokCampaignReportFilter();
  const { data, previousData, loading } = useTiktokSpecialCampaignReportSummaryQuery({
    variables: getValuableVariables({ keyword, endDate, startDate, campaignId: tiktokSpecialCampaign.id })
  });

  const result = loading ? previousData : data;
  const summaryData = result?.tiktokSpecialCampaignReportSummary;
  const hasNoData = !summaryData?.postCount;

  return (
    <TikTokCampaignReportProvider>
      <TikTokCampaignReportFilter hasNoData={hasNoData} tiktokSpecialCampaign={tiktokSpecialCampaign} />

      <RenderDataWithFallback loading={loading} hasNoData={hasNoData} noDataNode={<PostReportEmpty />}>
        <TikTokCampaignReportSummary data={summaryData} />

        {mode === PostReportMode.GRID ? (
          <TikTokCampaignReportGrid tiktokSpecialCampaign={tiktokSpecialCampaign} />
        ) : (
          <TikTokCampaignReportDetails tiktokSpecialCampaign={tiktokSpecialCampaign} />
        )}
      </RenderDataWithFallback>
    </TikTokCampaignReportProvider>
  );
};
