import { FieldValues, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

export interface FormAction<T extends FieldValues> {
  className?: string;
  defaultValues?: Partial<T>;
  onSubmit: (values: T, methods: UseFormReturn<T, unknown>) => void | Promise<void>;
}
export type DisabledFields<T extends string> = Partial<Record<T, boolean>>;
/**
 * @description Will list a superset of a all Union's keys
 */
export type UnionOfKeys<T> = T extends object ? keyof T : never;
/**
 * @description Will help annotate z.object if we already have TS type|interface
 * util from the zod maintainer -> https://github.com/colinhacks/zod/issues/372#issuecomment-826380330
 */
export const getSchemaForType =
  <T>() =>
  <S extends z.ZodType<T, any, any>>(arg: S) =>
    arg;
export type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T;
