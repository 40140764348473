import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { CheckBox, CheckBoxProps, CircularProgress, Icon, Tooltip } from '@/shared/atoms';

export interface WidgetSelectButtonProps extends Pick<CheckBoxProps, 'tooltipProps'> {
  checked?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'default' | 'checkbox';
  className?: string;
}

export const WidgetSelectButton = ({
  onClick,
  className,
  tooltipProps,
  disabled = false,
  checked = false,
  loading = false,
  type = 'default'
}: WidgetSelectButtonProps) => {
  const { t } = useTranslation();
  const isCheckBox = type === 'checkbox';
  const help = isCheckBox ? t(!checked ? 'Select' : 'Unselect') : t(`Button.${checked ? 'Remove' : 'Add'}`);

  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick?.();
  };

  return isCheckBox ? (
    <div css={styles.wrapper} className={className}>
      <CheckBox
        loading={loading}
        checked={checked}
        disabled={disabled}
        onClick={handleClick}
        tooltipProps={{ help, disabled, ...tooltipProps }}
      />
    </div>
  ) : (
    <Tooltip css={{ height: '100%' }} disabled={disabled} help={help} position="top" {...tooltipProps}>
      <button css={styles.button(disabled, checked)} onClick={handleClick} className={className}>
        {loading ? (
          <CircularProgress size={18} thickness={0.3} backgroundColor={checked ? '#fff' : '#000'} />
        ) : (
          <Icon size={12} icon={checked ? 'check' : 'plus'} color={checked ? '#fff' : '#6E7C89'} />
        )}
      </button>
    </Tooltip>
  );
};
const styles = {
  wrapper: css({
    flex: 'none',
    width: '32px',
    height: '100%',
    display: 'flex',
    padding: '0 8px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    borderRight: THEME.border.base,
    backgroundColor: THEME.background.colors.white,
    '& > div > button': { marginRight: 0 }
  }),
  button: (isDisabled: boolean, isChecked: boolean) =>
    css({
      flex: 'none',
      width: '40px',
      height: '100%',
      border: 'none',
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: THEME.border.base,
      backgroundColor: THEME.background.colors.white,
      transition: 'background-color 0.3s ease',
      ...(!isDisabled
        ? { '&:hover': { backgroundColor: '#EEF3F7' } }
        : { opacity: 0.5, cursor: 'not-allowed', backgroundColor: '#DEE5EC' }),
      ...(isChecked ? { backgroundColor: THEME.background.colors.blue } : {}),
      '> img': { width: '24px', height: '24px' }
    })
};
