import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/SearchPrivatePartnerInfluencers.graphql';
import { Query, QuerysearchPrivatePartnerInfluencersArgs } from '../../__generated__/globalTypes';

export const useSearchPrivatePartnerInfluencersQuery = (
  options?: QueryHookOptions<Query, QuerysearchPrivatePartnerInfluencersArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerysearchPrivatePartnerInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useSearchPrivatePartnerInfluencersLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerysearchPrivatePartnerInfluencersArgs>
): QueryResult<Query, QuerysearchPrivatePartnerInfluencersArgs> & {
  getSearchPrivatePartnerInfluencers: LazyQueryExecFunction<Query, QuerysearchPrivatePartnerInfluencersArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getSearchPrivatePartnerInfluencers, result] = useLazyQuery<Query, QuerysearchPrivatePartnerInfluencersArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getSearchPrivatePartnerInfluencers, ...result };
};

export const useSearchPrivatePartnerInfluencersPromiseQuery = () => {
  const client = useApolloClient();

  const getSearchPrivatePartnerInfluencers = useCallback(
    (options?: Omit<QueryOptions<QuerysearchPrivatePartnerInfluencersArgs, Query>, 'query'>) =>
      client.query<Query, QuerysearchPrivatePartnerInfluencersArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getSearchPrivatePartnerInfluencers };
};
