import { MouseEvent, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { ToggleOption } from '@/shared/types';
import { useToggleState } from '@/shared/hooks';
import { WarningModal } from '@/shared/molecules';
import { MarketplaceCampaignStatus } from '@/graphql';
import { FormStyle, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { MarketplaceFormValues } from '../types';
import { compareChangedValues } from '../utils';

interface FormTemplateProps {
  children: ReactNode;
  hideSubmit?: boolean;
  onSubmit?: () => void;
  defaultValues: MarketplaceFormValues;
}

export const FormLayoutTemplate = ({ children, onSubmit, defaultValues, hideSubmit }: FormTemplateProps) => {
  const { t } = useTranslation();
  const warningModal = useToggleState();
  const allowNewInfluencerModal = useToggleState();
  const {
    getValues,
    formState: { isSubmitting }
  } = useFormContext<MarketplaceFormValues>();

  const values = getValues();
  const isEditMode = !!values.id;
  const isAllowNewInfluencer = values.allowNewInfluencer === ToggleOption.TRUE;

  const handleClickSave = (e: MouseEvent<HTMLButtonElement>) => {
    if (isEditMode) {
      const hasSensitiveChanges = compareChangedValues(defaultValues, values);
      if (hasSensitiveChanges && values.status === MarketplaceCampaignStatus.ONGOING) {
        e.preventDefault();
        warningModal.open();

        return;
      }

      if (defaultValues.allowNewInfluencer !== values.allowNewInfluencer) {
        e.preventDefault();
        allowNewInfluencerModal.open();

        return;
      }
    }

    onSubmit?.();
  };

  const handleSubmitWarningModal = () => {
    onSubmit?.();
    warningModal.close();
  };

  const handleSubmitAllowNewInfluencerModal = () => {
    onSubmit?.();
    allowNewInfluencerModal.close();
  };

  return (
    <>
      <FormStyle.Wrapper hasBorder>
        <div css={{ padding: '24px' }}>{children}</div>

        {!hideSubmit ? (
          <FormStyle.ButtonSubmitWrapper>
            <FormStyle.ButtonSubmit title={t('Button.Save')} loading={isSubmitting} onClick={handleClickSave} />
          </FormStyle.ButtonSubmitWrapper>
        ) : null}
      </FormStyle.Wrapper>

      {isEditMode ? (
        <>
          <WarningModal
            open={warningModal.status}
            onClose={warningModal.close}
            description={t('Dialog.Edit Ongoing Campaign')}
            footerProps={{ submitButtonProps: { title: t('Button.Update'), onClick: handleSubmitWarningModal } }}
          />

          <Modal
            open={allowNewInfluencerModal.status}
            onClose={allowNewInfluencerModal.close}
            css={{ maxWidth: THEME.modal.size.lv1 }}
            hasCloseIcon
          >
            <ModalContent>
              <ModalTitle>
                {t(isAllowNewInfluencer ? 'Modal.saveAsNormalCampaignTitle' : 'Modal.saveAsClosedCampaignTitle')}
              </ModalTitle>
              <p css={{ marginTop: '16px' }}>
                {t(
                  isAllowNewInfluencer
                    ? 'Modal.saveAsNormalCampaignDescription'
                    : 'Modal.saveAsClosedCampaignDescription'
                )}
              </p>
            </ModalContent>

            <ModalFooterActions
              cancelButtonProps={{ onClick: allowNewInfluencerModal.close }}
              submitButtonProps={{
                variant: 'blue',
                loading: isSubmitting,
                title: t('Button.Save'),
                onClick: handleSubmitAllowNewInfluencerModal
              }}
            />
          </Modal>
        </>
      ) : null}
    </>
  );
};
