import { css, Global } from '@emotion/react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { THEME } from '@/shared/constants';

export enum MapConstructor {
  CHART = 'chart',
  MAP_CHART = 'mapChart'
}

interface HighchartsType {
  /* Chart configuration object */
  options: Highcharts.Options;
  /* Constructor method type. Default is `chart` */
  constructorType?: MapConstructor;
}

// Set global default options
Highcharts.setOptions({
  tooltip: {
    shadow: false
  },
  plotOptions: {
    pie: {
      size: undefined, // responsive sizing based on container
      center: ['50%', '50%'],
      minSize: 200,
      dataLabels: {
        crop: false,
        style: { fontSize: '16px', fontWeight: '400', lineHeight: '1.13', width: undefined },
        shadow: {
          color: 'rgba(39, 49, 59, 0.20)',
          width: 4,
          offsetX: 0,
          offsetY: 2
        }
      }
    }
  },
  yAxis: {
    lineColor: THEME.colors.black[20]
  },
  xAxis: {
    lineColor: THEME.colors.black[20]
  }
});

export const HighChart = ({ options, constructorType = MapConstructor.CHART }: HighchartsType) => (
  <>
    <HighchartsReact
      highcharts={Highcharts}
      options={{ credits: { enabled: false }, ...options }}
      constructorType={constructorType}
      allowChartUpdate
      oneToOne
    />
    <Global
      styles={css({
        '.highcharts-tooltip path': { fill: '#27313b' }
      })}
    />
  </>
);

export { Highcharts };
