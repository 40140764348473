import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { InfluencerFollowerGrowthSection } from '@/graphql';
import { userPinkImage, pictureImage } from '@/shared/assets';
import { MediaPreview } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatIntNumber } from '@/shared/utils';

interface InfluencerProfileOverviewSummaryProps {
  data: InfluencerFollowerGrowthSection;
}

export const InfluencerProfileOverviewSummary = ({
  data: { followersCount, postsCount }
}: InfluencerProfileOverviewSummaryProps) => {
  const { t } = useTranslation();

  const stats = [
    { label: t('Follower'), value: formatIntNumber(followersCount), image: userPinkImage },
    { label: t('Post'), value: formatIntNumber(postsCount), image: pictureImage }
  ];

  return (
    <div css={{ display: 'flex', borderBottom: THEME.border.base }}>
      {stats.map(({ label, image, value }, index) => (
        <div css={styles.item} key={index}>
          <MediaPreview css={{ width: '32px', height: '32px', background: 'transparent' }} src={image} />
          <p css={{ fontWeight: 600, fontSize: '11px', margin: '0 24px 0 8px' }}>{label}</p>
          <p css={{ fontWeight: 600, fontSize: '16px' }}>{value}</p>
        </div>
      ))}
    </div>
  );
};

const styles = {
  item: css({
    flex: '1 1 0',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    '&:not(:first-of-type)': { borderLeft: THEME.border.base }
  })
};
