import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramAnalyticsReelDetails.graphql';
import { Query, QueryinstagramAnalyticsReelDetailsArgs } from '../../__generated__/globalTypes';

export const useInstagramAnalyticsReelDetailsQuery = (
  options?: QueryHookOptions<Query, QueryinstagramAnalyticsReelDetailsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramAnalyticsReelDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramAnalyticsReelDetailsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramAnalyticsReelDetailsArgs>
): QueryResult<Query, QueryinstagramAnalyticsReelDetailsArgs> & {
  getInstagramAnalyticsReelDetails: LazyQueryExecFunction<Query, QueryinstagramAnalyticsReelDetailsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramAnalyticsReelDetails, result] = useLazyQuery<Query, QueryinstagramAnalyticsReelDetailsArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getInstagramAnalyticsReelDetails, ...result };
};

export const useInstagramAnalyticsReelDetailsPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramAnalyticsReelDetails = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramAnalyticsReelDetailsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramAnalyticsReelDetailsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramAnalyticsReelDetails };
};
