import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreatePackage.graphql';
import { Mutation, MutationcreatePackageArgs } from '../../__generated__/globalTypes';

export const useCreatePackageMutation = (options?: MutationHookOptions<Mutation, MutationcreatePackageArgs>) => {
  const [callCreatePackage, result] = useMutation<Mutation, MutationcreatePackageArgs>(MUTATION, options);

  return { callCreatePackage, ...result };
};

export type CreatePackageMutationFunction = Mutation['createPackage'];
