import { InstagramAnalyticsHashtag, InstagramHashtagFeedPostsPayload } from '@/graphql';

export const hashtagsMock: InstagramAnalyticsHashtag[] = [
  {
    blocked: false,
    hashTag: '#beauty',
    hashTagUrl: 'https://www.instagram.com/p/DHNhobMIf4Z/',
    id: 2351493
  },
  {
    blocked: false,
    hashTag: '#bestfoodin',
    hashTagUrl: 'https://www.instagram.com/p/DHN9H7fMiYn/',
    id: 12212
  },
  {
    blocked: false,
    hashTag: '#gurumi',
    hashTagUrl: 'https://www.instagram.com/p/DHN9H7fMiYa/',
    id: 12213
  }
];

export const postsMock: InstagramHashtagFeedPostsPayload = {
  count: 10,
  posts: [
    {
      id: 2351493,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351493-213818-thumbnail.jpg',
      comments: 5,
      content:
        '🪻💅🏽🔮🛍️✨Dress by amazing swag.store21 🤎✨#beauty #fashion #quality #business #dream #life #health #successful #behappy #lifestyle #friends #classy',
      engagement: 11047,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351493-213818-thumbnail.jpg'],
      influencerId: null,
      likes: 11042,
      name: 'beauty',
      postDate: '2025-03-15',
      postUrl: 'https://www.instagram.com/p/DHNhobMIf4Z/',
      rank: 0
    },
    {
      id: 2351494,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351494-196941-thumbnail.jpg',
      comments: 31,
      content:
        '👩‍🍳👨‍🍳🩵🥹ossymarwahsherinsbeauty ..#sherinsbeauty #sherinbeauty #sherin #sherii #narins_beauty #cedras_beauty #beauty #dubai #jalal_amara #shero_amara #amara #ossymarwah #explore',
      engagement: 31,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351494-196941-thumbnail.jpg'],
      influencerId: null,
      likes: 0,
      name: 'beauty',
      postDate: '2025-03-15',
      postUrl: 'https://www.instagram.com/p/DHN9H7fMiYn/',
      rank: 1
    },
    {
      id: 2351495,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351495-659826-thumbnail.jpg',
      comments: 107,
      content:
        'My4ever 🦢 🥂🤍💍...........#photography #photooftheday #love #instagood #photo #instagram #nature #photographer #picoftheday #art #travel #fashion #like #photoshoot #beautiful #follow #naturephotography #model #instadaily #bhfyp #likeforlikes #travelphotography #style #beauty #instalike #portrait #happy #canon #india #followforfollowback',
      engagement: 452,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351495-659826-thumbnail.jpg'],
      influencerId: null,
      likes: 345,
      name: 'beauty',
      postDate: '2025-03-15',
      postUrl: 'https://www.instagram.com/p/DHNv0Dzybv8/',
      rank: 2
    },
    {
      id: 2351496,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351496-650464-thumbnail.jpg',
      comments: 27,
      content: 'New college outfit#aiyanafalleris #model #beauty #school #uniform #fashion #lingerie #art #ai',
      engagement: 473,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351496-650464-thumbnail.jpg'],
      influencerId: null,
      likes: 446,
      name: 'beauty',
      postDate: '2025-03-14',
      postUrl: 'https://www.instagram.com/p/DHLqy_9g7QF/',
      rank: 3
    },
    {
      id: 2351497,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351497-378410-thumbnail.jpg',
      comments: 576,
      content:
        '🎁HUGE GIVEAWAY🎁Σε συνεργασία με το κουκλίστικο elona_beautystudio που μόλις άνοιξε έναν πολύ όμορφο και ζεστό χώρο.. και για γνωρίσεις όλες τις υπηρεσίες και τα προϊόντα του, κάνουμε δώρο σε 5 τυχερές δώρα συνολικής αξίας 500€!!1🎁 1 box 300€ με φοβερά προϊόντα περιποίησης προσώπου της',
      engagement: 968,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351497-378410-thumbnail.jpg'],
      influencerId: null,
      likes: 392,
      name: 'beauty',
      postDate: '2025-03-14',
      postUrl: 'https://www.instagram.com/p/DHL7djwI6-2/',
      rank: 4
    },
    {
      id: 2351498,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351498-229765-thumbnail.jpg',
      comments: 20,
      content:
        'Rang de tu mohe Gerua! 🧡 #happyholi #instapost #instaaesthetic #viralpost #postoftheday #instapic #instaphoto #pictureoftheday #holivibes #aesthetics #instagood #instadaily #newpost #sareeandme #beauty #viralpost #portrait #saree_aur_aesthetic[aesthetic photography, beauty, portrait photography, inspiration, eyes that speak, soulful eyes]',
      engagement: 610,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351498-229765-thumbnail.jpg'],
      influencerId: null,
      likes: 590,
      name: 'beauty',
      postDate: '2025-03-14',
      postUrl: 'https://www.instagram.com/p/DHLTuuUTc7y/',
      rank: 5
    },
    {
      id: 2351499,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351499-217798-thumbnail.jpg',
      comments: 0,
      content:
        '#dubai #mydubai #travel #holiday #vacation #beauty #luxury #lifestyle #fashion #massageindubai #massagedubai #style #lifedubai #instatravel #instago #instagood #trip #holiday #photooftheday #fun #travelling #tourism #tourist #instapassport #instatraveling #mytravelgram #travelgram #travelingram #massagedubai #massage',
      engagement: 520,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351499-217798-thumbnail.jpg'],
      influencerId: null,
      likes: 520,
      name: 'beauty',
      postDate: '2025-03-15',
      postUrl: 'https://www.instagram.com/p/DHN8_11SaiH/',
      rank: 6
    },
    {
      id: 2351500,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351500-109793-thumbnail.jpg',
      comments: 0,
      content:
        '#dubai #mydubai #travel #holiday #vacation #beauty #luxury #lifestyle #fashion #massageindubai #massagedubai #style #lifedubai #instatravel #instago #instagood #trip #holiday #photooftheday #fun #travelling #tourism #tourist #instapassport #instatraveling #mytravelgram #travelgram #travelingram #massagedubai #massage',
      engagement: 520,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351500-109793-thumbnail.jpg'],
      influencerId: null,
      likes: 520,
      name: 'beauty',
      postDate: '2025-03-15',
      postUrl: 'https://www.instagram.com/p/DHN81D8Sxe-/',
      rank: 7
    },
    {
      id: 2351395,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351395-969858-thumbnail.jpg',
      comments: 19,
      content:
        '♥️🩷Happy holi to all my pookies♥️🩷#radha #barsanewaliradhe #mathurakiholi #babygirl #beauty #holi #holivibes #holicelebration #festival #ethnicfashion #babyfashion #toddlerfashionista #littlefashionista #indianwear #lehengalove',
      engagement: 539,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351395-969858-thumbnail.jpg'],
      influencerId: null,
      likes: 520,
      name: 'beauty',
      postDate: '2025-03-14',
      postUrl: 'https://www.instagram.com/p/DHK1BeSzE-m/',
      rank: 8
    },
    {
      id: 2351502,
      accountId: null,
      avatar: 'https://storage.googleapis.com/am-ig-stg/igh/470/2351502-39691-thumbnail.jpg',
      comments: 0,
      content:
        '#dubai #mydubai #travel #holiday #vacation #beauty #luxury #lifestyle #fashion #massageindubai #massagedubai #style #lifedubai #instatravel #instago #instagood #trip #holiday #photooftheday #fun #travelling #tourism #tourist #instapassport #instatraveling #mytravelgram #travelgram #travelingram #massagedubai #massage',
      engagement: 519,
      followersCount: null,
      images: ['https://storage.googleapis.com/am-ig-stg/igh/470/2351502-39691-thumbnail.jpg'],
      influencerId: null,
      likes: 519,
      name: 'beauty',
      postDate: '2025-03-15',
      postUrl: 'https://www.instagram.com/p/DHN8gyvzPrm/',
      rank: 9
    }
  ]
};
