import { useTranslation } from 'react-i18next';
import { ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { Table } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useUserProposalContext } from '../UserProposalContext';
import { useInfluencersStateHandler, useProposedInfluencersTable } from '../hooks';
import { ProposeInfluencersTableMode } from '../schemaTypes';
import { ProposeInfluencersTableTitle } from '../TableComponents';
import { RejectProposeInfluencersActionBar } from './RejectInfluencersActionBar';

interface RejectedProposedInfluencersTableProps {
  jdMode?: ProposalJobDescriptionBreakDownMode;
  hasInfluencerCostPermission?: boolean;
}

export const RejectedProposedInfluencersTable = ({ jdMode }: RejectedProposedInfluencersTableProps) => {
  const { t } = useTranslation();
  const { userProposal, rejectedInfluencers } = useUserProposalContext();
  const {
    allInfluencerIds,
    selectedInfluencerAccountIds,
    setSelectedInfluencerAccountIds,
    handleSelectSingleInfluencer,
    handleToggleSelectAllInfluencers
  } = useInfluencersStateHandler(rejectedInfluencers);

  const handleSelectAllIds = () => {
    setSelectedInfluencerAccountIds(allInfluencerIds);
  };

  const { rows, columns } = useProposedInfluencersTable({
    jdMode,
    currency: userProposal?.currency || '',
    influencers: rejectedInfluencers,
    tableViewMode: ProposeInfluencersTableMode.VIEW,
    listSelectedIds: selectedInfluencerAccountIds,
    onRowSelect: handleSelectSingleInfluencer,
    selectButtonProps: { onClick: handleSelectAllIds }
  });

  return !rejectedInfluencers.length ? null : (
    <>
      <ProposeInfluencersTableTitle css={{ borderTop: THEME.border.base }} title={t('Rejected Influencers')} />

      <div css={{ position: 'relative' }}>
        {selectedInfluencerAccountIds.length > 0 ? (
          <RejectProposeInfluencersActionBar
            selectedInfluencerIds={selectedInfluencerAccountIds}
            numberSelected={selectedInfluencerAccountIds.length}
            hasAllSelected={selectedInfluencerAccountIds.length === rejectedInfluencers.length}
            onSelectAll={handleSelectAllIds}
            onToggleSelect={handleToggleSelectAllInfluencers}
          />
        ) : null}

        <Table
          noTopBorderRadius
          data={{ rows, columns, rowHeight: '62px' }}
          css={{ td: { backgroundColor: `${THEME.table.row.background.colors.disabled} !important` } }}
          stickyHeader
        />
      </div>
    </>
  );
};
