import { useParamsSearch } from '@/shared/hooks';
import { InfluencerAccountSearchSchemaType } from '../schemaTypes';

export const useInfluencerProfileSearch = () => {
  const { filter, reinitParamsSearch, ...paramsSearch } = useParamsSearch<InfluencerAccountSearchSchemaType>();

  return {
    ...paramsSearch,
    filter,
    reinitParamsSearch,
    socialMedia: filter?.sm,
    socialAccountId: filter?.id
  };
};
