import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/BulkUpdatePackageProposalAccountsBudget.graphql';
import { Mutation, MutationbulkUpdatePackageProposalAccountsBudgetArgs } from '../../__generated__/globalTypes';

export const useBulkUpdatePackageProposalAccountsBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationbulkUpdatePackageProposalAccountsBudgetArgs>
) => {
  const [callBulkUpdatePackageProposalAccountsBudget, result] = useMutation<
    Mutation,
    MutationbulkUpdatePackageProposalAccountsBudgetArgs
  >(MUTATION, options);

  return { callBulkUpdatePackageProposalAccountsBudget, ...result };
};

export type BulkUpdatePackageProposalAccountsBudgetMutationFunction =
  Mutation['bulkUpdatePackageProposalAccountsBudget'];
