import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import {
  InfluencerYoutubeProfilePosts,
  InfluencerYoutubeProfileOverview,
  InfluencerYoutubeProfileAudience,
  InfluencerYoutubeProfileSponsoredPosts,
  InfluencerYoutubeProfileMentionedAccounts
} from '../../InfluencerProfiles';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerYoutubeProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerYoutubeProfile = ({ socialAccount }: InfluencerYoutubeProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerYoutubeProfileOverview influencerId={influencerProfile.id} socialAccount={socialAccount} />

      {!influencerProfile.hideSensitiveMetrics ? (
        <InfluencerYoutubeProfileAudience influencerId={influencerProfile.id} socialAccount={socialAccount} />
      ) : null}

      <InfluencerYoutubeProfilePosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
      <InfluencerYoutubeProfileSponsoredPosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
      <InfluencerYoutubeProfileMentionedAccounts influencerId={influencerProfile.id} socialAccount={socialAccount} />
    </>
  );
};
