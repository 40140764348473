import { Tooltip, TooltipProps } from '../Tooltip';
import { Icon } from './Icon';

export interface HelpIconProps extends Omit<TooltipProps, 'children'> {
  size?: string;
  className?: string;
}

export const HelpIcon = ({ className, size = '12px', ...restProps }: HelpIconProps) => (
  <Tooltip position="top" {...restProps}>
    {/* IcoMoon itself cannot forward ref, but Tooltip require it to work */}
    <div css={{ display: 'inline-flex', width: size, height: size }} className={className}>
      <Icon
        size={size}
        icon="tooltip"
        color="#C5D0DA"
        css={{ cursor: 'pointer', '&:hover': { filter: 'brightness(0.9)' } }}
      />
    </div>
  </Tooltip>
);
