import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestInfluencersProfilePptx.graphql';
import { Mutation, MutationrequestInfluencersProfilePptxArgs } from '../../__generated__/globalTypes';

export const useRequestInfluencersProfilePptxMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestInfluencersProfilePptxArgs>
) => {
  const [callRequestInfluencersProfilePptx, result] = useMutation<Mutation, MutationrequestInfluencersProfilePptxArgs>(
    MUTATION,
    options
  );

  return { callRequestInfluencersProfilePptx, ...result };
};

export type RequestInfluencersProfilePptxMutationFunction = Mutation['requestInfluencersProfilePptx'];
