import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/BulkUpdateEngagementSocialAccountsBudget.graphql';
import { Mutation, MutationbulkUpdateEngagementSocialAccountsBudgetArgs } from '../../__generated__/globalTypes';

export const useBulkUpdateEngagementSocialAccountsBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationbulkUpdateEngagementSocialAccountsBudgetArgs>
) => {
  const [callBulkUpdateEngagementSocialAccountsBudget, result] = useMutation<
    Mutation,
    MutationbulkUpdateEngagementSocialAccountsBudgetArgs
  >(MUTATION, options);

  return { callBulkUpdateEngagementSocialAccountsBudget, ...result };
};

export type BulkUpdateEngagementSocialAccountsBudgetMutationFunction =
  Mutation['bulkUpdateEngagementSocialAccountsBudget'];
