import { useTranslation } from 'react-i18next';
import { MediaPreviewWithMetrics } from '@/shared/atoms';
import { LIMIT_30, THEME } from '@/shared/constants';
import { bigIntFormatter, getValuableVariables } from '@/shared/utils';
import { usePostDetailsParam, useQueryHelper } from '@/shared/hooks';
import { YoutubeAnalyticsShortsPayload, useYoutubeAnalyticsShortsQuery } from '@/graphql';
import { ImageSlider, InfluencerYoutubeAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsFeedPostsWrapper, AnalyticsFeedPostsWrapperProps } from '../../shared';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { useAnalyticsProfileSearch } from '../../hooks';

export interface AnalyticsYoutubeDashboardShortsTemplateProps
  extends Pick<AnalyticsFeedPostsWrapperProps, 'title' | 'loading' | 'help' | 'moreDetailProps'> {
  data?: readonly YoutubeAnalyticsShortsPayload[];
}

export const AnalyticsYoutubeDashboardShortsTemplate = ({
  data = [],
  ...restProps
}: AnalyticsYoutubeDashboardShortsTemplateProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  return (
    <AnalyticsFeedPostsWrapper title={t('Shorts')} dataNotAvailable={!data.length} {...restProps}>
      <ImageSlider total={data.length} css={{ paddingLeft: '16px' }}>
        {data.map(({ id, videoThumbnail, videoTitle, likes, views, comments }) => (
          <MediaPreviewWithMetrics
            key={id}
            objectFit="cover"
            title={videoTitle}
            src={videoThumbnail}
            css={THEME.mediaPreview.ytShort}
            onClick={() => setPostDetailsParam(id)}
            statistics={[
              { label: t('View'), value: bigIntFormatter(views, 0, { k: true, m: true }) },
              { label: t('Like'), value: bigIntFormatter(likes, 0, { k: true, m: true }) },
              { label: t('Comment'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
            ]}
          />
        ))}
      </ImageSlider>
    </AnalyticsFeedPostsWrapper>
  );
};

export interface AnalyticsYoutubeDashboardShortsProps {
  brandAccountName?: string;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsYoutubeDashboardShorts = ({
  analyticsAccount,
  brandAccountName
}: AnalyticsYoutubeDashboardShortsProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter, params } = useAnalyticsProfileSearch();
  const { data, loading } = useYoutubeAnalyticsShortsQuery({
    variables: getValuableVariables({
      offset: 0,
      limit: LIMIT_30,
      endDate: filter.endDate,
      startDate: filter.startDate,
      orderBy: { order: null, field: null },
      youtubeAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  const shorts = data?.youtubeAnalyticsShorts.shorts || [];

  return (
    <>
      <AnalyticsYoutubeDashboardShortsTemplate
        data={shorts}
        loading={loading}
        moreDetailProps={{
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts',
          params,
          search: {
            filter: {
              brandAccountName,
              endDate: filter.endDate,
              startDate: filter.startDate
            }
          }
        }}
      />

      <InfluencerYoutubeAnalyticsPostDetailsModal
        isAnalytics
        posts={shorts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: analyticsAccount.id }))}
      />
    </>
  );
};
