import { Trans } from 'react-i18next';
import { Anchor } from '@/shared/atoms';
import { useCustomerPortalLinkQuery } from '@/graphql';

export const TikTokBillingHistory = () => {
  const { data } = useCustomerPortalLinkQuery({ variables: { redirectUrl: window.location.href } });

  return (
    <div css={{ marginTop: '24px' }}>
      <Trans
        i18nKey="Check Billing Information and Billing History"
        components={{
          a: (
            <Anchor variant="blue" href={data?.customerPortalLink?.url} target="_blank" css={{ display: 'contents' }} />
          )
        }}
      />
      <br css={{ marginBottom: '4px' }} />
      <Trans
        i18nKey="analyticsPlanNote"
        components={{
          a: (
            <Anchor
              target="_blank"
              variant="blue"
              css={{ display: 'inline-block' }}
              href="mailto:info-dev@anymindgroup.com"
            />
          )
        }}
      />
    </div>
  );
};
