import { useTranslation } from 'react-i18next';
import { Tabs } from '@/shared/atoms';
import { useUserPermissions, useUserRoles } from '@/auth';
import { THEME } from '@/shared/constants';

export interface AdvertiserTabsProps {
  advertiserId: number;
}

export const AdvertiserTabs = ({ advertiserId }: AdvertiserTabsProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { hideUserTab } = useUserPermissions();
  const params = { id: advertiserId.toString() };

  return (
    <Tabs
      css={{ boxShadow: THEME.shadows.boxShadow, borderTopLeftRadius: '3px', borderTopRightRadius: '3px' }}
      options={[
        {
          end: true,
          label: t('Advertiser.Advertiser'),
          to: '/advertiser/$id',
          params
        },
        {
          end: true,
          label: t('User'),
          hidden: hideUserTab,
          to: '/advertiser/$id/users',
          params
        },
        {
          end: true,
          hidden: !isAdminStaff,
          label: t('Budget Allocation'),
          to: '/advertiser/$id/budget-allocation',
          params
        }
      ]}
    />
  );
};
