import { useAuthData } from '@/auth';
import { StateType } from '@/shared/types';
export const useGlobalLayoutProps = () => {
  const {
    auth: { globalLayout },
    dispatch
  } = useAuthData();

  const updateGlobalLayout = (props: Partial<StateType['globalLayout']>) => {
    dispatch({
      type: 'UPDATE_GLOBAL_LAYOUT',
      payload: {
        globalLayout: { ...globalLayout, ...props }
      }
    });
  };

  return { globalLayout, updateGlobalLayout };
};
