import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SocialAccountInfluencerListSortFieldV2, SocialAccountType, TiktokInfluencer } from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { FilterValidations } from '@/shared/validations';
import { PostType, DefaultInfluencersProps, InfluencersFilterSchema, InfluencersBaseSearchSchema } from '../types';
import { getInitialTiktokInfluencersFormValues } from './utils';

export const TiktokInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  ttcmStatus: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  talentAgencyOrPartnerIds: true,
  minEngagementViewsRate: true,
  maxEngagementViewsRate: true,
  minEngagementFollowersRate: true,
  maxEngagementFollowersRate: true,
  demographicAge: true,
  demographicGender: true,
  demographicAgeMinRate: true,
  demographicGenderMinRate: true
});

export const TiktokInfluencersSearchSchema = z.object({
  filter: TiktokInfluencersFilterSchema.default(
    TiktokInfluencersFilterSchema.parse(getInitialTiktokInfluencersFormValues())
  ),
  page: z.number().default(1),
  sort: FilterValidations.sort(SocialAccountInfluencerListSortFieldV2).default({ field: null, order: null }),
  limit: z.number().default(LIMIT),
  state: InfluencersBaseSearchSchema.shape.state
});

export type TiktokInfluencersSearchSchemaType = z.infer<typeof TiktokInfluencersSearchSchema>;
export type TiktokInfluencersFilterFormValues = z.infer<typeof TiktokInfluencersFilterSchema>;
export type TiktokInfluencersFilterFormKeys = UnionOfKeys<TiktokInfluencersFilterFormValues>;
export type TiktokInfluencersProps = DefaultInfluencersProps<TiktokInfluencerProfile>;
export type TiktokInfluencerProfile = {
  posts: PostType[];
  isTTCMCreator?: boolean;
  socialAccountType: SocialAccountType;
} & Pick<
  TiktokInfluencer,
  | 'id'
  | 'age'
  | 'name'
  | 'avatar'
  | 'gender'
  | 'country'
  | 'averageLikes'
  | 'averageViews'
  | 'isBrandAccount'
  | 'socialAccountId'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementViewsRate'
  | 'socialAccountEngagementFollowersRate'
>;
