import update from 'immutability-helper';
import { useEffect, useState } from 'react';
import { checkSignedIn } from '@/shared/utils';
import { ChatDemoMessageType } from '../shared';
import { useManageOpenAiThread } from './useManageOpenAiThread';
import { useManageLastLocation } from './useManageLastLocation';

export const useManageChatDemoStates = () => {
  const isSignedIn = checkSignedIn();
  const [messages, setMessages] = useState<ChatDemoMessageType[]>([]);
  const { savedLocation, setSavedLocation, clearLastLocation } = useManageLastLocation();
  const { threadId, setThreadId, createOpenAiThread, deleteOpenAiThread } = useManageOpenAiThread();

  // Clear after sign out
  useEffect(() => {
    if (!isSignedIn) {
      setMessages([]);
      setThreadId(null);
    }
  }, [isSignedIn]);

  const removeLastTypingMessage = () => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages.at(-1);

      return lastMessage?.typing ? update(prevMessages, { $splice: [[-1, 1]] }) : prevMessages;
    });
  };

  const clearCharBoxError = () => {
    removeLastTypingMessage();
  };

  const updateMessages = (newMessages: ChatDemoMessageType[]) => {
    setMessages((prevMessages) => {
      const prevMessage = prevMessages.at(-1);

      return prevMessage?.typing
        ? update(prevMessages, { $splice: [[-1, 1]], $push: newMessages }) // Remove typing message
        : update(prevMessages, { $push: newMessages });
    });
  };

  const refreshChatDemo = async () => {
    clearLastLocation();
    setMessages([{ position: 'left', typing: true }]);

    const oldThreadId = threadId;
    await createOpenAiThread({ onError: removeLastTypingMessage });
    await deleteOpenAiThread(oldThreadId);

    updateMessages([
      {
        position: 'left',
        text: 'どのようなインフルエンサーをお探しですか？\n条件を教えていただければ、最適なインフルエンサーを提案します。'
      }
    ]);
  };

  return {
    threadId,
    messages,
    savedLocation,
    updateMessages,
    refreshChatDemo,
    setSavedLocation,
    clearCharBoxError,
    createOpenAiThread,
    removeLastTypingMessage
  };
};
