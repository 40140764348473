import { MarketplaceDetail } from '@/graphql';
import { formatIntNumber } from '@/shared/utils';
import { useTranslateOptions, useAllInfluencerCategoryOptions } from '@/shared/hooks';
import {
  MAX_AGE_V2,
  MIN_AGE_V2,
  MAX_FOLLOWERS,
  MIN_FOLLOWERS,
  GENDER_OPTIONS,
  MIN_ENGAGEMENT_RATE,
  MAX_ENGAGEMENT_RATE,
  MIN_MAX_AGE_OPTIONS_V2,
  MIN_MAX_EN_RATE_OPTIONS
} from '@/shared/constants';

interface Props {
  marketplace: MarketplaceDetail;
}

export const useMarketplaceInfluencersFilterOptions = ({
  marketplace: { minAge, maxAge, minFollowers, maxFollowers, genders, categories }
}: Props) => {
  const allGenderOptions = useTranslateOptions(GENDER_OPTIONS);
  const { allInfluencerCategoryOptions } = useAllInfluencerCategoryOptions();

  const followerAmountOptions = 10;
  const customMinAge = minAge ?? MIN_AGE_V2;
  const customMaxAge = maxAge || MAX_AGE_V2;
  const customMinFollowers = minFollowers || MIN_FOLLOWERS;
  const customMaxFollowers = maxFollowers || MAX_FOLLOWERS;
  const followerStep = Math.floor((customMaxFollowers - customMinFollowers) / followerAmountOptions);
  const followerOptions = Array.from({ length: followerAmountOptions + 1 }, (_, i) => {
    const value = i * followerStep;

    return {
      value: String(value),
      label: `${formatIntNumber(value)}${value === customMaxFollowers ? '+' : ''}`
    };
  });
  const categoryIds = categories.map(({ id }) => String(id));
  const genderOptions = allGenderOptions.map((option) => ({
    ...option,
    disabled: !genders.includes(option.value)
  }));
  const influencerCategoryOptions = allInfluencerCategoryOptions.map((option) => ({
    ...option,
    disabled: !categoryIds.includes(option.value)
  }));

  return {
    genderOptions,
    influencerCategoryOptions,
    ageConfigs: {
      step: 1,
      options: MIN_MAX_AGE_OPTIONS_V2,
      range: { min: customMinAge, max: customMaxAge }
    },
    followerConfigs: {
      step: followerStep,
      options: followerOptions,
      range: { min: customMinFollowers, max: customMaxFollowers }
    },
    engagementRateConfigs: {
      step: 0.1,
      options: MIN_MAX_EN_RATE_OPTIONS,
      range: { min: MIN_ENGAGEMENT_RATE, max: MAX_ENGAGEMENT_RATE }
    }
  };
};
