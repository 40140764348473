import { css } from '@emotion/react';
import React, { forwardRef } from 'react';

import { THEME } from '@/shared/constants';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onSubmit'> {
  hasError?: boolean;
  className?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ hasError, disabled, placeholder, ...restProps }, ref) => (
    <input
      ref={ref}
      type="text"
      disabled={disabled}
      css={styles.wrapper(hasError, disabled)}
      placeholder={disabled ? '' : placeholder}
      {...restProps}
    />
  )
);
Input.displayName = 'Input';

const styles = {
  wrapper: (hasError?: boolean, disabled?: boolean) =>
    css({
      width: '100%',
      height: '32px',
      padding: '0 8px',
      fontSize: '12px',
      borderRadius: '3px',
      textAlign: 'inherit',
      boxSizing: 'border-box',
      border: '1px solid transparent',
      color: THEME.input.text.colors.default,
      backgroundColor: disabled ? THEME.input.background.colors.disabled : THEME.input.background.colors.default,
      borderColor: hasError ? THEME.input.border.colors.error : THEME.input.border.colors.default,
      '&:not(:disabled):focus': { borderColor: THEME.input.border.colors.focus },
      '&::placeholder': {
        fontWeight: 400,
        fontSize: '14px',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: THEME.input.text.colors.placeholder
      },

      /* stylelint-disable */
      appearance: 'textfield',
      MozAppearance: 'textfield',
      '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
    })
};
