import { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import { formatDecimalNumber, limitDecimalDigits } from '@/shared/utils';

import { InputUnit, InputUnitProps } from './InputUnit';

export interface InputNumberFormatterProps extends Omit<InputUnitProps, 'type' | 'onChange'> {
  format?: 'number' | 'percent';
  // We are controlling the value format, so get value from event.target.value might incorrect
  onChange?: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
}

export const InputNumberFormatter = forwardRef<HTMLInputElement, InputNumberFormatterProps>(
  ({ format, onChange, ...restProps }, ref) => {
    const defaultValue = limitDecimalDigits(
      format === 'percent'
        ? Number(restProps.value || restProps.defaultValue || '') * 100
        : String(restProps.value || restProps.defaultValue || ''),
      2
    );
    const [value, setValue] = useState(formatDecimalNumber(defaultValue));

    useEffect(() => {
      setValue(formatDecimalNumber(defaultValue));
    }, [defaultValue]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value.replaceAll(',', '');

      if (rawValue.match(/^\d*\.?\d*$/)) {
        setValue(formatDecimalNumber(rawValue));

        if (rawValue.slice(-1) !== '.') {
          const formattedValue =
            rawValue && Number(rawValue) && format === 'percent'
              ? limitDecimalDigits(Number(rawValue) / 100, 10)
              : rawValue;
          onChange?.(formattedValue, e);
        }
      }
    };

    return <InputUnit ref={ref} {...restProps} type="text" value={value} onChange={handleInputChange} />;
  }
);
InputNumberFormatter.displayName = 'InputNumberFormatter';
