import { useQueryHelper } from '@/shared/hooks';
import { TableSegmentOption } from '@/shared/atoms';
import { convertOrderToORDER, getOffset, getValuableVariables } from '@/shared/utils';
import {
  EngagementDetail,
  useEngagementCampaignReportQuery,
  useEngagementCampaignTotalDailyLazyQuery,
  useEngagementCampaignInfluencerDailyLazyQuery,
  useEngagementCampaignInfluencerPostDailyLazyQuery
} from '@/graphql';
import { usePostReportSearch } from '@/shared/molecules';
import {
  PostReportSegment,
  PostReportSegmentKeys,
  formatPostReportSegmentTotal,
  formatPostReportSegmentInfluencerDaily,
  formatPostReportSegmentInfluencerPostDaily
} from '@/shared/organisms';

export interface EngagementReportTableDataProps {
  engagement: EngagementDetail;
  totalRecords: number;
}
export const useEngagementReportTableData = ({ engagement: { id } }: EngagementReportTableDataProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const {
    state,
    filter: { keyword, endDate, startDate },
    setParamsState,
    page,
    limit,
    sort
  } = usePostReportSearch();

  const variables = getValuableVariables({
    limit,
    endDate,
    keyword,
    startDate,
    campaignId: id,
    offset: getOffset(page, limit),
    orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) }
  });

  const handleError = () => {
    enqueueSnackbar(t('failToLoadDailyInformation'), { variant: 'error' });
  };

  const { data, previousData, loading, networkStatus } = useEngagementCampaignReportQuery({
    fetchPolicy: 'no-cache',
    variables
  });
  const {
    data: totalDailyData,
    loading: fetchingTotalDaily,
    getEngagementCampaignTotalDaily
  } = useEngagementCampaignTotalDailyLazyQuery({
    variables,
    onError: handleError
  });
  const {
    data: influencerDailyData,
    loading: fetchingInfluencerDaily,
    getEngagementCampaignInfluencerDaily
  } = useEngagementCampaignInfluencerDailyLazyQuery({
    variables,
    onError: handleError
  });
  const {
    data: influencerPostDailyData,
    loading: fetchingInfluencerPostDaily,
    getEngagementCampaignInfluencerPostDaily
  } = useEngagementCampaignInfluencerPostDailyLazyQuery({
    variables,
    onError: handleError
  });

  const handleSegmentChange = async ({ key, value }: TableSegmentOption<PostReportSegmentKeys>) => {
    if (!state[key]) {
      switch (key) {
        case PostReportSegmentKeys.total:
          await getEngagementCampaignTotalDaily();
          setParamsState({ total: !value });
          break;
        case PostReportSegmentKeys.influencer:
          await getEngagementCampaignInfluencerDaily();
          setParamsState({ influencer: !value });
          break;
        case PostReportSegmentKeys.post:
          await getEngagementCampaignInfluencerPostDaily();
          setParamsState({ post: !value });
          break;
        default:
          break;
      }
    } else {
      setParamsState({ [key]: !value });
    }
  };

  const result = loading ? previousData : data;
  const segment: PostReportSegment = {
    total: {
      loading: fetchingTotalDaily,
      data: formatPostReportSegmentTotal(
        state.total ? totalDailyData?.engagementCampaignReport?.orderedSummaryDaily : null
      )
    },
    post: {
      loading: fetchingInfluencerPostDaily,
      data: formatPostReportSegmentInfluencerPostDaily(
        state.post ? influencerPostDailyData?.engagementCampaignReport?.influencerReports : null
      )
    },
    influencer: {
      loading: fetchingInfluencerDaily,
      data: formatPostReportSegmentInfluencerDaily(
        state.influencer ? influencerDailyData?.engagementCampaignReport?.influencerReports : null
      )
    }
  };

  return {
    loading,
    networkStatus,
    segment,
    segmentStatus: {
      post: !!state.post,
      total: !!state.total,
      influencer: !!state.influencer
    },
    handleSegmentChange,
    reportData: result?.engagementCampaignReport || undefined
  };
};
