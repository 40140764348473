import { useUpdateEngagementMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useParamsSearch, useQueryHelper } from '@/shared/hooks';
import {
  EngagementCampaignForm,
  EngagementCampaignFormValues,
  formatEngagementFormVariables,
  getDefaultEngagementCampaignFormValues
} from '@/shared/organisms';
import { FormAction } from '@/shared/types';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';
import { EditEngagementCampaignSearchSchemaType } from './schemaTypes';

export const EditEngagementCampaign = () => {
  const { engagement } = useEngagementDetailsContext();
  const { t, navigate, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { state } = useParamsSearch<EditEngagementCampaignSearchSchemaType>();
  const { callUpdateEngagement } = useUpdateEngagementMutation({ onCompleted: invalidateRouteLoader });

  const redirectUrlAfterUpdateEngagement = state?.redirectUrlAfterUpdateEngagement;

  const handleSubmit: FormAction<EngagementCampaignFormValues>['onSubmit'] = async (values) => {
    const input = formatEngagementFormVariables(values);
    const { budget, socialMedias, advertiserId, campaignCategoryId } = input;

    if (!advertiserId || !budget || socialMedias.length === 0 || !campaignCategoryId) {
      return;
    }

    try {
      await callUpdateEngagement({ variables: { input: { id: engagement.id, ...input } } });
      enqueueSnackbar(t('succeededInUpdating', { name: values.title }), { variant: 'success' });

      if (redirectUrlAfterUpdateEngagement) {
        navigate({ href: redirectUrlAfterUpdateEngagement });
      }
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: values.title }), { variant: 'error' });
      enqueueSnackbar(t(error || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <EngagementCampaignForm
      onSubmit={handleSubmit}
      defaultValues={getDefaultEngagementCampaignFormValues(engagement)}
    />
  );
};
