import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagementPostStatus.graphql';
import { Mutation, MutationupdateEngagementPostStatusArgs } from '../../__generated__/globalTypes';

export const useUpdateEngagementPostStatusMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateEngagementPostStatusArgs>
) => {
  const [callUpdateEngagementPostStatus, result] = useMutation<Mutation, MutationupdateEngagementPostStatusArgs>(
    MUTATION,
    options
  );

  return { callUpdateEngagementPostStatus, ...result };
};

export type UpdateEngagementPostStatusMutationFunction = Mutation['updateEngagementPostStatus'];
