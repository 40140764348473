import { useTranslation } from 'react-i18next';
import { chatImage } from '@/shared/assets';
import { ChartsContainer, NoDataTitle, SplitButton } from '@/shared/atoms';
import { PostingHabits, AverageEngagement, SectionWithHeader, PieChart } from '@/shared/molecules';
import { useXhsProfileEngagementInsightsQuery, XhsPostType } from '@/graphql';
import { useParamsSearch } from '@/shared/hooks';
import { PIE_CHART_COLORS } from '../InfluencerDouyinProfile/utils';
import { getXhsAvgEngagement } from './utils';

interface InfluencerXhsProfileEngagementInsightsProps {
  influencerProfileId: number;
  socialAccountId: number;
}

const CHART_HEIGHT = 346;

export const InfluencerXhsProfileEngagementInsights = ({
  influencerProfileId,
  socialAccountId
}: InfluencerXhsProfileEngagementInsightsProps) => {
  const { t } = useTranslation();

  const { filter, setFilter } = useParamsSearch();

  const postType = filter?.xhsPostType || XhsPostType.NORMAL;

  const { data } = useXhsProfileEngagementInsightsQuery({
    variables: {
      pk: influencerProfileId,
      postType,
      socialAccountId: socialAccountId
    }
  });

  const handleXhsPostTypeChange = (xhsPostType: XhsPostType) => {
    setFilter({ ...filter, xhsPostType }, { resetScroll: false });
  };

  const xhsProfilePosts = data?.xhsProfileEngagementInsights;

  return (
    <SectionWithHeader
      title={t('Engagement Insights')}
      headerChildren={
        <SplitButton
          options={[
            {
              title: t('Button.Normal'),
              variant: 'default',
              isActive: postType === XhsPostType.NORMAL,
              onClick: () => handleXhsPostTypeChange(XhsPostType.NORMAL)
            },
            {
              title: t('Button.Sponsored'),
              variant: 'default',
              isActive: postType === XhsPostType.SPONSORED,
              onClick: () => handleXhsPostTypeChange(XhsPostType.SPONSORED)
            }
          ]}
          css={{ marginLeft: 'auto' }}
        />
      }
      prefixIcon={chatImage}
      css={{ marginTop: '16px' }}
    >
      <ChartsContainer css={{ height: '392px' }}>
        <AverageEngagement data={getXhsAvgEngagement({ xhsProfilePosts, t })} title="Videos Average Engagement" />

        <PieChart
          dataNotAvailable={!xhsProfilePosts?.postCategories?.length}
          header={<NoDataTitle title={t('Post Categories')} />}
          chart={{ type: 'pie', height: CHART_HEIGHT, spacingBottom: 0 }}
          data={
            xhsProfilePosts?.postCategories?.map((vt, i) => ({
              y: vt.rate,
              name: vt.name,
              color: PIE_CHART_COLORS[i] || ''
            })) || []
          }
          css={{ padding: '10px 8px 16px' }}
        />

        <PostingHabits data={xhsProfilePosts?.postsHabit} height={CHART_HEIGHT} />
      </ChartsContainer>
    </SectionWithHeader>
  );
};
