import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UploadEngagementReportFile.graphql';
import { Mutation, MutationuploadEngagementReportFileArgs } from '../../__generated__/globalTypes';

export const useUploadEngagementReportFileMutation = (
  options?: MutationHookOptions<Mutation, MutationuploadEngagementReportFileArgs>
) => {
  const [callUploadEngagementReportFile, result] = useMutation<Mutation, MutationuploadEngagementReportFileArgs>(
    MUTATION,
    options
  );

  return { callUploadEngagementReportFile, ...result };
};

export type UploadEngagementReportFileMutationFunction = Mutation['uploadEngagementReportFile'];
