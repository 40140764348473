import { Option } from '@/shared/types';
import {
  MultiPrivateInfluencersSearchSelector,
  MultiPrivateInfluencersSearchSelectorProps
} from '../MultiPrivateInfluencersSearchSelector';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export type PrivateInfluencersSearchFieldProps<T extends string = string> = DefaultFieldProps<T, Option[]> &
  Omit<MultiPrivateInfluencersSearchSelectorProps, 'value' | 'isDisabled' | 'setFieldValue'>;

export const PrivateInfluencersSearchField = <T extends string>(props: PrivateInfluencersSearchFieldProps<T>) => {
  const {
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, onChange, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <MultiPrivateInfluencersSearchSelector
        {...restFieldProps}
        setFieldValue={onChange ? (value) => onChange(name, value) : setValue.bind(null, name as string)}
      />
    </RHFFieldWrapper>
  );
};
