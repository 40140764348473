import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/VerifyToken.graphql';
import { Mutation, MutationverifyTokenArgs } from '../../__generated__/globalTypes';

export const useVerifyTokenMutation = (options?: MutationHookOptions<Mutation, MutationverifyTokenArgs>) => {
  const [callVerifyToken, result] = useMutation<Mutation, MutationverifyTokenArgs>(MUTATION, options);

  return { callVerifyToken, ...result };
};

export type VerifyTokenMutationFunction = Mutation['verifyToken'];
