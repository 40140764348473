import { useTranslation } from 'react-i18next';
import { SocialAccountStatus, InfluencerPostSection, InfluencerNewProfileHeaderV2 } from '@/graphql';
import {
  heartImage,
  pictureImage,
  chatGreenImage,
  networkBlueImage,
  eyeUserOrangeImage,
  bookmarkLightBlueImage
} from '@/shared/assets';
import { ChartsContainer } from '@/shared/atoms';
import { HAS_NO_ESTIMATED_TEXT } from '@/shared/constants';
import { PostingHabits, AverageEngagement, AccountsInterestChart } from '@/shared/molecules';
import { useInfluencerProfileOpacityContent } from '../../../hooks';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfilePostsOverviewWrapper } from '../../shared';

interface InfluencerInstagramProfilePostsOverviewProps {
  data?: InfluencerPostSection;
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerInstagramProfilePostsOverview = ({
  data,
  socialAccount,
  influencerProfile: { id, status, hideSensitiveMetrics }
}: InfluencerInstagramProfilePostsOverviewProps) => {
  const { t } = useTranslation();
  const { hasAccountInterests, opacityContent } = useInfluencerProfileOpacityContent({
    socialAccount,
    influencerId: id,
    skipEstimateContent: true
  });

  const isSignedUp = status === SocialAccountStatus.SIGNED_UP;

  return (
    <InfluencerProfilePostsOverviewWrapper data={data}>
      <ChartsContainer css={{ height: '324px' }}>
        <AverageEngagement
          data={[
            {
              icon: pictureImage,
              label: t('Avg posts/week'),
              value: data?.averagePostsPerWeek || null
            },
            {
              isPercentage: true,
              icon: networkBlueImage,
              value: data?.engagementFollowersRate || null,
              label: t('HeaderColumn.Avg ER')
            },
            {
              icon: heartImage,
              value: data?.averageLikes || null,
              label: t('Avg Likes/latest 30 posts')
            },
            ...(!hideSensitiveMetrics
              ? [
                  {
                    icon: bookmarkLightBlueImage,
                    value: data?.averageSaved || null,
                    label: t('Avg Saves/latest 30 posts')
                  }
                ]
              : []),
            {
              icon: chatGreenImage,
              value: data?.averageComments || null,
              label: t('Avg Comments/latest 30 posts')
            },
            ...(!hideSensitiveMetrics
              ? [
                  {
                    icon: eyeUserOrangeImage,
                    value: data?.estimateReach || null,
                    label: t(isSignedUp ? 'HeaderColumn.Avg Reach' : 'HeaderColumn.Est Reach')
                  }
                ]
              : [])
          ]}
        />

        <AccountsInterestChart
          data={data?.accountInterests}
          wrapperText={HAS_NO_ESTIMATED_TEXT}
          dataNotAvailable={!hasAccountInterests}
        />

        <PostingHabits {...opacityContent} data={data?.postsHabit} />
      </ChartsContainer>
    </InfluencerProfilePostsOverviewWrapper>
  );
};
