import {
  useMarketplacePostQuery,
  CampaignSocialMediaType,
  useMarketplacePostCommentsQuery
} from '@/graphql';
import { POST_COMMENTS_LIMIT } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailStatistics, PostDetailsType } from '@/shared/types';
import { checkIsReelPost } from '@/shared/utils';
import { PostDetailsTemplateType } from '../../types';
import { MarketplacePostDetailsTabProps } from '../MarketplacePostDetailsTab';
import { MarketplacePostStatus } from '../MarketplacePostStatus';

interface Props extends Pick<PostDetailsType, 'id'> {
  onCloseModal?: () => void;
}

export const useMarketplacePostDetailsData = ({ id, onCloseModal }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useMarketplacePostQuery({
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: { pk: Number(id) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const { data: commentData, loading: fetchingComments } = useMarketplacePostCommentsQuery({
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: { postId: Number(id), limit: POST_COMMENTS_LIMIT }
  });

  const details = data?.marketplacePost;
  const statistics: PostDetailStatistics = (() => {
    switch (details?.socialMedia) {
      case CampaignSocialMediaType.INSTAGRAM:
        return { likes: details?.likes, reach: details?.reach, comments: details?.comments };
      case CampaignSocialMediaType.INSTAGRAM_STORY:
        return { likes: details?.likes, reach: details?.reach, replies: details?.comments };
      case CampaignSocialMediaType.YOUTUBE:
        return { likes: details?.likes, views: details?.views, comments: details?.comments };
      case CampaignSocialMediaType.TWITTER:
        return { likes: details?.likes, retweets: details?.shares, replies: details?.comments };
      case CampaignSocialMediaType.THREADS:
        return { likes: details?.likes, replies: details?.comments };
      case CampaignSocialMediaType.FACEBOOK:
        return { reactions: details?.likes, comments: details?.comments, shares: details?.shares };
      case CampaignSocialMediaType.TIKTOK:
        return {
          likes: details?.likes,
          views: details?.views,
          comments: details?.comments,
          shares: details?.shares
        };
      default:
        return {};
    }
  })();
  const postDetails: MarketplacePostDetailsTabProps['postDetails'] = details
    ? {
        statistics,
        caption: details?.caption,
        images: details?.images || [],
        postUrl: details?.postUrl || '',
        socialMedia: details?.socialMedia as PostDetailsTemplateType['socialMedia'], // we don't support type OTHERS, UNSELECT, ...
        isReel: checkIsReelPost(details?.postUrl),
        comments: commentData?.marketplacePostComments || [],
        info: {
          postDate: details?.postedDate,
          id: String(details?.postDetailInfluencer?.id ?? ''),
          name: details?.postDetailInfluencer?.name,
          avatar: details?.postDetailInfluencer?.avatar,
          followers: details?.postDetailInfluencer?.followersCount
        }
      }
    : undefined;

  return {
    postDetails,
    loading: loading || fetchingComments,
    postStatus: details?.status ? (
      <MarketplacePostStatus
        warningReason={details.warningReason || undefined}
        status={details?.status}
        css={{ fontSize: '10px', height: '16px' }}
      />
    ) : null
  };
};
