import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SendBcaRequest.graphql';
import { Mutation, MutationsendBcaRequestArgs } from '../../__generated__/globalTypes';

export const useSendBcaRequestMutation = (options?: MutationHookOptions<Mutation, MutationsendBcaRequestArgs>) => {
  const [callSendBcaRequest, result] = useMutation<Mutation, MutationsendBcaRequestArgs>(MUTATION, options);

  return { callSendBcaRequest, ...result };
};

export type SendBcaRequestMutationFunction = Mutation['sendBcaRequest'];
