import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateDraftBoostPost.graphql';
import { Mutation, MutationcreateDraftBoostPostArgs } from '../../__generated__/globalTypes';

export const useCreateDraftBoostPostMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateDraftBoostPostArgs>
) => {
  const [callCreateDraftBoostPost, result] = useMutation<Mutation, MutationcreateDraftBoostPostArgs>(MUTATION, options);

  return { callCreateDraftBoostPost, ...result };
};

export type CreateDraftBoostPostMutationFunction = Mutation['createDraftBoostPost'];
