import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useInfluencerTwitterAnalyticsPostsQuery } from '@/graphql';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  InfluencerTwitterPostsSearchSchemaType,
  InfluencerTwitterPostsList
} from '@/shared/organisms';
import { useInitialInfluencerPostsListWithName } from '../hooks';

export const InfluencerTwitterPosts = () => {
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName();
  const { page, limit, sort, filter, setFilter } = useParamsSearch<InfluencerTwitterPostsSearchSchemaType>();
  const { data, previousData, loading } = useInfluencerTwitterAnalyticsPostsQuery({
    skip: !influencerId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      influencerId,
      socialAccountId: mainSocialAccountId,
      orderBy: sort,
      offset: getOffset(page, limit),
      endDate: null,
      startDate: null,
      keyword: filter.keyword
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerTwitterAnalyticsPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} />

      <ProfilePostsCount count={totalRecords} />

      <InfluencerTwitterPostsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.influencerTwitterAnalyticsPosts?.posts || []}
      />
    </ListWithPagination>
  );
};
