import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { TikTokSpecialCampaignStatus } from '@/graphql';
import { FilterValidations } from '@/shared/validations';
import { INITIAL_PAGE_NUMBER, LIMIT } from '@/shared/constants';

export const TikTokCampaignsFilterSchema = z.object({
  keyword: FilterValidations.string(),
  status: FilterValidations.enum(TikTokSpecialCampaignStatus)
});
export const TikTokCampaignsSearchSchema = z.object({
  filter: TikTokCampaignsFilterSchema.default(TikTokCampaignsFilterSchema.parse({})),
  limit: z.number().default(LIMIT),
  page: z.number().default(INITIAL_PAGE_NUMBER)
});
export type TikTokCampaignsSearchType = z.infer<typeof TikTokCampaignsSearchSchema>;
export type TikTokCampaignsFilterFormValues = z.infer<typeof TikTokCampaignsFilterSchema>;
export type TikTokCampaignsFilterFormKeys = UnionOfKeys<TikTokCampaignsFilterFormValues>;
