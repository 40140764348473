import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/TokenAuth.graphql';
import { Mutation, MutationtokenAuthArgs } from '../../__generated__/globalTypes';

export const useTokenAuthMutation = (options?: MutationHookOptions<Mutation, MutationtokenAuthArgs>) => {
  const [callTokenAuth, result] = useMutation<Mutation, MutationtokenAuthArgs>(MUTATION, options);

  return { callTokenAuth, ...result };
};

export type TokenAuthMutationFunction = Mutation['tokenAuth'];
