import { ReactNode, forwardRef } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Input, InputProps } from './Input';
import { InputNumber } from './InputNumber';

export interface InputUnitProps extends InputProps {
  unit?: ReactNode;
}

export const InputUnit = forwardRef<HTMLInputElement, InputUnitProps>(
  ({ type, unit, max, className, ...restProps }, ref) => (
    <div css={{ position: 'relative', display: 'grid' }} className={className}>
      {type === 'number' ? (
        <InputNumber
          ref={ref}
          onWheel={(e) => e.currentTarget.blur()}
          css={unit ? styles.input : undefined}
          {...restProps}
        />
      ) : (
        <Input css={unit ? styles.input : undefined} ref={ref} type={type} {...restProps} />
      )}

      <div css={styles.overlayEnd}>{unit ? <span css={styles.unit}>{unit}</span> : null}</div>
    </div>
  )
);
InputUnit.displayName = 'InputUnit';

const styles = {
  unit: css({
    fontWeight: 400,
    display: 'flex',
    padding: '0 4px',
    fontSize: '12px',
    alignItems: 'center',
    pointerEvents: 'none',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    borderLeft: THEME.border.base
  }),
  overlayEnd: css({
    display: 'flex',
    height: 'calc(100% - 12px)',
    gridArea: '1 / 1',
    placeSelf: 'end',
    alignSelf: 'center'
  }),
  input: css({
    paddingRight: '34px',
    gridArea: '1 / 1'
  })
};
