import { useCreateAgencyWithUserMutation } from '@/graphql';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useUpdateDocumentTitle, useQueryHelper, useCustomHeader } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { AgencyFormValues, AgencyForm } from '@/shared/organisms';

export const AddAgency = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    href: '/agency',
    title: 'appHeader.Add New Agency',
    appHeader: 'appHeader.Add New Agency'
  });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateAgencyWithUser } = useCreateAgencyWithUserMutation();

  const handleSubmit = async ({
    logo,
    hubspotId,
    countryId,
    companyUrl,
    agencyName,
    phoneNumber,
    advertiserIds,
    internalNetsuiteId,
    hasInvitationEmail,
    ...rest
  }: AgencyFormValues) => {
    try {
      if (rest.type === 'add') {
        await callCreateAgencyWithUser({
          variables: {
            input: {
              name: rest.name,
              email: rest.email,
              countryId,
              agencyName,
              hasInvitationEmail,
              logo: logo || null,
              hubspotId: hubspotId || null,
              companyUrl: companyUrl || null,
              password: rest.password || undefined,
              phoneNumber: phoneNumber || null,
              advertiserIds: advertiserIds.map(Number),
              internalNetsuiteId: internalNetsuiteId || null
            }
          }
        });
      }
      enqueueSnackbar(t('succeededInCreating', { name: agencyName }), { variant: 'success' });
      navigate({ to: '/agency' });
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name: agencyName }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
      <Header />

      <AgencyForm onSubmit={handleSubmit} />
    </div>
  );
};
