import { z } from 'zod';
import { SocialAccountStatus, SocialAccountType } from '@/graphql';
import { BaseValidations } from '@/shared/validations';
import { InternalMemoSchema, PostsPriceSchema } from '../shared';
import { InfluencerSharedKeys } from '../shared/types';
import { SocialAccountsTaSchema } from './formSections';

export const TalentInfluencerFormSchema = z
  .object({
    [InfluencerSharedKeys.introduce]: z.string(),
    [InfluencerSharedKeys.id]: z.number().optional(),
    [InfluencerSharedKeys.isProAccount]: z.boolean(),
    [InfluencerSharedKeys.name]: BaseValidations.NameSchema,
    [InfluencerSharedKeys.gender]: BaseValidations.GenderSchema,
    [InfluencerSharedKeys.regionId]: BaseValidations.RegionIdSchema,
    [InfluencerSharedKeys.email]: BaseValidations.EmailSchema,
    [InfluencerSharedKeys.countryId]: BaseValidations.CountryIdSchema,
    [InfluencerSharedKeys.defaultEngagementSelectionReason]: z.string(),
    [InfluencerSharedKeys.dateOfBirth]: BaseValidations.DateOfBirthSchema,
    [InfluencerSharedKeys.phoneNumber]: BaseValidations.PhoneNumberOptionalSchema,
    [InfluencerSharedKeys.categoryIds]: BaseValidations.getMaxCategoryIdsSchema()
  })
  .and(SocialAccountsTaSchema)
  .and(PostsPriceSchema)
  .and(InternalMemoSchema);

// We split to small forms because:
// This forms can be reused for Admin and Talent
// Some of small forms will get data from an api (independent, not dependent on influencer data) and that form can be show/hide based on user role and permissions
// So to avoid call the redundant apis, we call them inside the form when they init and add that data into Parent form
export type TalentInfluencerFormValues = z.infer<typeof TalentInfluencerFormSchema>;
export type TalentInfluencerFormKeys = keyof TalentInfluencerFormValues;
export interface TalentInfluencerScrappingAccountInfo {
  screenName?: string; // For Twitter
  channelId?: string | number; // For YT
  instagramAccountName?: string; // For IG
  needOverrideFbFanpages?: boolean; // if we upgrading FB acc(or FB_fanpage) from scrapping - we need to override current scrapping FB account and all scrapping FB_fanpages
}
// export interface TalentUserAddLocationState {
//   connectType?: SocialAccountType; // can we make it optional?
//   connectableFbAccount?: {
//     fbName: SocialAuthTalentSignInFacebookPayload['fbName'];
//     status: SocialAuthTalentSignInFacebookPayload['status'];
//     fbUserId?: SocialAuthTalentSignInFacebookPayload['fbUserId'];
//     fbAvatar?: SocialAuthTalentSignInFacebookPayload['fbAvatar'];
//     fbFollowersCount?: SocialAuthTalentSignInFacebookPayload['fbFollowersCount'];
//   };
//   connectableFbFanpages: (Pick<
//     ConnectableOauthPageAndIgAccountForSignInFacebook,
//     'igAccount' | 'isPageAlreadyConnected'
//   > &
//     ConnectableOauthPageAndIgAccountForSignInFacebook['page'])[];
//   connectableIgAccounts: readonly ConnectableOauthPageAndIgAccountForSignInFacebook[];
//   connectableYtChannels?: readonly ConnectableOauthYouTubeAccountForTalentSignIn[];
//   connectableTwAccount?: SocialAuthTalentSignInTwitterPayload;
//   parsedScrappingAccountInfo?: TalentInfluencerScrappingAccountInfo;
//   talentAgencyUserFormValues?: TalentInfluencerFormValues;
// }

export const TalentUserAddLocationStateSchema = z.object({
  connectType: z.nativeEnum(SocialAccountType).optional(),
  connectableFbAccount: z
    .object({
      fbAvatar: z.string().optional(),
      fbFollowersCount: z.number().optional(),
      fbName: z.string(),
      fbUserId: z.string(),
      pages: z
        .array(
          z.object({
            id: z.string(),
            name: z.string()
          })
        )
        .optional(),
      status: z.nativeEnum(SocialAccountStatus)
    })
    .optional(),
  connectableFbFanpages: z
    .array(
      z.object({
        isPageAlreadyConnected: z.boolean(),
        igAccount: z
          .object({
            id: z.string(),
            name: z.string(),
            followersCount: z.number(),
            avatar: z.string().nullish(),
            status: z.nativeEnum(SocialAccountStatus)
          })
          .optional(),
        id: z.string(),
        name: z.string(),
        followersCount: z.number(),
        avatar: z.string().nullish(),
        status: z.nativeEnum(SocialAccountStatus)
      })
    )
    .optional(),
  connectableIgAccounts: z
    .array(
      z.object({
        isPageAlreadyConnected: z.boolean(),
        page: z.object({
          id: z.string(),
          name: z.string(),
          followersCount: z.number(),
          avatar: z.string().nullish(),
          status: z.nativeEnum(SocialAccountStatus)
        }),
        igAccount: z
          .object({
            id: z.string(),
            name: z.string(),
            followersCount: z.number(),
            avatar: z.string().nullish(),
            status: z.nativeEnum(SocialAccountStatus)
          })
          .nullish()
      })
    )
    .readonly()
    .optional(),
  connectableYtChannels: z
    .array(
      z.object({
        channelId: z.string(),
        channelName: z.string(),
        avatar: z.string(),
        subscribersCount: z.number(),
        status: z.nativeEnum(SocialAccountStatus).optional(),
        isUsed: z.boolean().optional(),
        isChannelAlreadyConnected: z.boolean().optional()
      })
    )
    .readonly()
    .optional(),
  connectableTwAccount: z
    .object({
      twAccount: z.string().optional(),
      twName: z.string(),
      twScreenName: z.string(),
      twUserId: z.string(),
      status: z.nativeEnum(SocialAccountStatus),
      isAccountAlreadyConnected: z.boolean().optional()
    })
    .optional(),
  parsedScrappingAccountInfo: z
    .object({
      screenName: z.string().optional(),
      channelId: z.string().optional(),
      instagramAccountName: z.string().optional(),
      needOverrideFbFanpages: z.boolean().optional()
    })
    .optional(),
  talentAgencyUserFormValues: TalentInfluencerFormSchema.optional()
});

export const TalentUserAddLocationSearchStateSchema = z.object({
  state: TalentUserAddLocationStateSchema.optional()
});

export type TalentUserAddLocationStateType = z.infer<typeof TalentUserAddLocationStateSchema>;
export type TalentUserAddLocationSearchStateType = z.infer<typeof TalentUserAddLocationSearchStateSchema>;
