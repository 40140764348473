import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { EngagementDetail, EngagementProposal, ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatIntNumber, formatPercent, formatPrice, getSocialPairsIds } from '@/shared/utils';
import { AddPackageWithAccounts } from '@/shared/organisms';
import { ProposalDownloadButton } from '../shared';
import { ProposalDerivedProps } from '../shared/types';

interface ProposalSummaryProps
  extends Pick<ProposalDerivedProps, 'jdMode' | 'formattedInfluencers' | 'formattedSocialAccounts'> {
  engagement: EngagementDetail;
  hasTrackingReferral?: boolean;
  hasInfluencerCostPermission?: boolean;
  engagementProposal: EngagementProposal;
}

export const ProposalSummary = ({
  jdMode,
  engagement,
  hasTrackingReferral,
  formattedInfluencers,
  formattedSocialAccounts,
  hasInfluencerCostPermission = true,
  engagementProposal: { couponCodeCount, influencers }
}: ProposalSummaryProps) => {
  const { t } = useTranslation();
  const { isAgency, isAdminStaffTalentAgencyPartner } = useUserRoles();

  const couponCodes = couponCodeCount || 0;
  const currency = engagement?.currency || '';
  const numberOfInfluencers = engagement?.numberOfInfluencers ?? null;
  const selectedInfluencers = influencers.filter((infl) => !infl.isDenied);
  const numberOfSelectedInfluencers = selectedInfluencers.length;
  const {
    budget,
    followers,
    agencyCost,
    profit,
    engagementViewRate,
    engagementFollowerRate,
    engagementPostedCount,
    engagementProposedCount,
    influencerCost
  } = (() =>
    selectedInfluencers.reduce(
      (rs, { summary }) => ({
        budget: rs.budget + (summary.budget || 0),
        followers: rs.followers + (summary.followersCount || 0),
        agencyCost: rs.agencyCost + (summary.adAgencyMarginValue || 0),
        influencerCost: rs.influencerCost + (summary.influencerCost || 0),
        avgEngagement: rs.avgEngagement + (summary.averageEngagement || 0),
        engagementViewRate: rs.engagementViewRate + (summary.engagementViewRate || 0),
        engagementFollowerRate: rs.engagementFollowerRate + (summary.engagementFollowerRate || 0),
        engagementPostedCount: rs.engagementPostedCount + (summary.engagementPostedCount || 0),
        engagementProposedCount: rs.engagementProposedCount + (summary.engagementProposedCount || 0),
        profit: rs.profit + (summary.profit || 0)
      }),
      {
        budget: 0,
        followers: 0,
        agencyCost: 0,
        avgEngagement: 0,
        influencerCost: 0,
        profit: 0,
        engagementViewRate: 0,
        engagementPostedCount: 0,
        engagementFollowerRate: 0,
        engagementProposedCount: 0
      }
    ))();

  const stats = [
    {
      label: t('Suggested Influencers'),
      value:
        numberOfInfluencers !== null
          ? `${formatIntNumber(numberOfSelectedInfluencers)}/${formatIntNumber(numberOfInfluencers)}`
          : formatIntNumber(numberOfSelectedInfluencers)
    },
    ...(hasTrackingReferral
      ? [
          {
            label: (
              <div css={{ display: 'flex', alignItems: 'center' }}>
                {t('Coupon Codes')}
                {couponCodes < numberOfSelectedInfluencers ? (
                  <Icon icon="warning" color="#FFB63D" size="14px" css={{ marginLeft: '6px' }} />
                ) : null}
              </div>
            ),
            value: formatIntNumber(couponCodes)
          }
        ]
      : []),
    { label: t('General.Total Followers'), value: formatIntNumber(followers) },
    {
      unit: '%',
      label: t('HeaderColumn.Agg Egg Rate by views'),
      value: formatPercent(engagementViewRate, false)
    },
    {
      unit: '%',
      label: t('HeaderColumn.Agg Egg Rate by followers'),
      value: formatPercent(engagementFollowerRate, false)
    },
    {
      label: t('JobDescription.Budget'),
      value: formatPrice(budget, currency),
      unit: `/${formatPrice(engagement?.budget, currency)} ${t(currency)}`
    },
    ...(isAdminStaffTalentAgencyPartner && hasInfluencerCostPermission
      ? [
          {
            label: t('JobDescription.Influencer Cost'),
            value: formatPrice(influencerCost, currency),
            unit: t(currency)
          },
          {
            unit: t(currency),
            label: t('JobDescription.Profit'),
            value: formatPrice(profit, currency)
          },
          ...(agencyCost
            ? [
                {
                  label: t('TextForm.Advertiser Agency Cost'),
                  value: formatPrice(agencyCost, currency),
                  unit: t(currency)
                }
              ]
            : [])
        ]
      : []),
    ...(isAgency && agencyCost
      ? [{ label: t('JobDescription.Profit'), value: formatPrice(agencyCost, currency), unit: t(currency) }]
      : []),
    ...(isAdminStaffTalentAgencyPartner
      ? [
          {
            label: t('HeaderColumn.Avg Engagement Proposed'),
            value: formatIntNumber(engagementProposedCount)
          },
          {
            label: t('HeaderColumn.Avg Engagement Posted'),
            value: formatIntNumber(engagementPostedCount)
          }
        ]
      : [])
  ];

  const { socialPairs } = getSocialPairsIds(formattedSocialAccounts);

  return (
    <div css={{ backgroundColor: THEME.background.colors.white }}>
      <div css={styles.header}>
        <p css={{ color: THEME.text.colors.black.lv1, fontSize: '16px', fontWeight: 600, lineHeight: '16px' }}>
          {t('Campaign Proposal')}
        </p>
        <div css={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
          <AddPackageWithAccounts
            socialPairs={socialPairs}
            influencerIds={formattedInfluencers.map((infl) => infl.influencerId)}
            isInfluencerAccount={jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN}
          />
          <ProposalDownloadButton engagementId={engagement.id} disabled={numberOfSelectedInfluencers === 0} />
        </div>
      </div>

      <div css={styles.list}>
        {stats.map(({ label, value, unit }, index) => (
          <div css={styles.item} key={index} className="stat-item">
            <div css={styles.label}>{label}</div>
            <div css={styles.value} title={`${value} ${unit || ''}`}>
              {value}
              &nbsp;
              {unit ? <span>{unit}</span> : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const styles = {
  header: css({
    display: 'flex',
    padding: '8px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    borderBottom: THEME.border.base
  }),
  list: css({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',
    padding: '8px',
    gap: '8px'
  }),
  item: css({
    padding: '8px',
    width: '144px',
    borderLeft: THEME.border.base
  }),
  label: css({
    fontWeight: 600,
    fontSize: '12px',
    color: THEME.text.colors.gray.lv3,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }),
  value: css({
    marginTop: '4px',
    fontSize: '16px',
    fontWeight: '600',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: THEME.text.colors.black.lv1,
    '> span': { fontSize: '12px', color: THEME.text.colors.gray.lv3 }
  })
};
