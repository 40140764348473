import { useInstagramAnalyticsStoryDetailsQuery, useInfluencerInstagramAnalyticsStoryDetailsQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InfluencerInstagramAnalyticsStoryDetailsProps
  extends Pick<PostDetailsType, 'id' | 'mainSocialAccountId'> {
  className?: string;
  isAnalytics?: boolean;
  onCloseModal?: () => void;
}

export const InfluencerInstagramAnalyticsStoryDetails = ({
  id,
  className,
  isAnalytics,
  onCloseModal,
  mainSocialAccountId
}: InfluencerInstagramAnalyticsStoryDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useInfluencerInstagramAnalyticsStoryDetailsQuery({
    skip: !id || !mainSocialAccountId || isAnalytics,
    variables: { storyId: Number(id), socialAccountId: Number(mainSocialAccountId) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const { data: analyticsData, loading: fetchingAnalyticsData } = useInstagramAnalyticsStoryDetailsQuery({
    skip: !id || !mainSocialAccountId || !isAnalytics,
    variables: { storyId: Number(id), accountId: Number(mainSocialAccountId) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const details = data?.influencerInstagramAnalyticsStoryDetails || analyticsData?.instagramAnalyticsStoryDetails;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'INSTAGRAM',
        postUrl: details?.postUrl,
        caption: details?.content,
        images: details?.storyVideoUrl
          ? [details?.storyVideoUrl]
          : details?.postImageUrl
            ? [details?.postImageUrl]
            : [],
        statistics: {
          comments: details?.comments,
          reach: details?.reach,
          impressions: details?.impressions,
          exits: details?.exits,
          tapsForward: details?.tapsForward,
          swipeForward: details?.swipeForward,
          tapsBack: details?.tapsBack
        },
        info: {
          socialMedia: 'INSTAGRAM',
          avatar: details?.avatarUrl,
          postDate: details?.postDate,
          followers: details.followers,
          name: details?.instagramAccountName
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      className={className}
      postDetails={postDetails}
      css={{ width: THEME.modal.size.lv3 }}
      loading={loading || fetchingAnalyticsData}
    />
  );
};
