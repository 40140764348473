import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar } from '@/shared/molecules';
import { ButtonLink, ButtonLinkProps, Icon } from '@/shared/atoms';

type TikTokReviewInfluencersButtonProps = ButtonLinkProps;

// TODO: are they static?
const mockingInfluencerAvatars = [
  'https://storage.googleapis.com/ca-avatar-stg/youtube/35867/avatar.jpg',
  'https://storage.googleapis.com/ca-avatar-stg/youtube/35855/avatar.jpg',
  'https://storage.googleapis.com/ca-avatar-stg/youtube/35848/avatar.jpg'
];

export const TikTokReviewInfluencersButton = (props: TikTokReviewInfluencersButtonProps) => {
  const { t } = useTranslation();

  return (
    <ButtonLink variant="white" css={styles.button} {...props}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {mockingInfluencerAvatars.map((avatar, index) => (
          <InfluencerAvatar
            key={index}
            size="14px"
            src={avatar}
            css={[styles.avatar, props.disabled && { opacity: 0.5 }]}
            asChild
          />
        ))}
      </div>
      {t('Button.Review Influencers')}
      <Icon icon="arrow-right" size={10} color="inherit" css={{ marginLeft: 'auto' }} />
    </ButtonLink>
  );
};
const styles = {
  button: css({
    gap: '4px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '20px',
    letterSpacing: '0.2px',
    padding: '0 20px 0 16px',
    color: THEME.text.colors.black.lv1
  }),
  avatar: css({
    borderRadius: '50%',
    boxSizing: 'content-box',
    border: `1px solid ${THEME.border.colors.white}`,
    '& + &': { zIndex: 1, marginLeft: '-8px' }
  })
};
