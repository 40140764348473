import { FormAction } from '@/shared/types';
import { useDownloadFile, useToggleState } from '@/shared/hooks';
import { usePostsFilter } from '@/shared/organisms';
import { getAdvancedSearchApiUrl } from '@/shared/utils';
import { DownloadCsvPagesAndRowsModalValues, getDownloadCSVRangeRows } from '@/shared/molecules';

export const usePostsCsvDownload = () => {
  const downloadModal = useToggleState();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const {
    limit,
    filter: {
      field,
      order,
      keyword,
      genders,
      countryId,
      socialMedia,
      minViews,
      maxViews,
      minPostDate,
      minFollowers,
      maxFollowers,
      maxPostDate,
      minEngagementRate,
      maxEngagementRate
    }
  } = usePostsFilter();

  const handlePostsCsvDownload: FormAction<DownloadCsvPagesAndRowsModalValues>['onSubmit'] = async (formValues) => {
    const { firstRow, lastRow } = getDownloadCSVRangeRows(formValues, limit);

    await handleDownloadFile({
      filenamePrefix: `posts_csv_${new Date().toUTCString()}`,
      method: 'GET',
      url: getAdvancedSearchApiUrl('api/post/csv'),
      params: {
        countryId,
        firstRow,
        genders,
        keyword,
        lastRow,
        maxEngagementRate: maxEngagementRate ? maxEngagementRate / 100 : null,
        maxFollowers,
        maxPostDate,
        maxViews,
        minEngagementRate: minEngagementRate ? minEngagementRate / 100 : null,
        minFollowers,
        minPostDate,
        minViews,
        orderByField: field,
        orderByOrder: order,
        socialPostType: socialMedia
      }
    }).then(downloadModal.close);
  };

  return { downloadModal, downloading, handlePostsCsvDownload };
};
