import { useTranslation } from 'react-i18next';
import { useAllInfluencerCategoryOptions } from '@/shared/hooks';
import { SelectorField, SelectorFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const InfluencerCategoriesField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { allInfluencerCategoryOptions } = useAllInfluencerCategoryOptions();

  return (
    <SelectorField<InfluencerFormKeys>
      title={t('Selector.Influencer Categories')}
      help={t('Tooltip.Influencer Categories')}
      options={allInfluencerCategoryOptions}
      name={InfluencerSharedKeys.categoryIds}
      multipleSelectorProps={{ hasSelectAll: false }}
      multiple
      {...props}
    />
  );
};
