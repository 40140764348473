import { TwitterInfluencers, TwitterInfluencerProfile } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSocialAccountSelectButtonProps } from './hooks';

export const TwitterPackageInfluencersSearch = () => {
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialAccountSelectButtonProps<TwitterInfluencerProfile>({
      socialMedia: InfluencersTabType.TWITTER
    });

  return (
    <TwitterInfluencers
      isPackagePage
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
