import { useInfluencerInstagramDashboardOverviewQuery } from '@/graphql';
import { useInfluencerProfileSearch, AnalyticsInstagramDashboardOverviewTemplate } from '@/shared/organisms';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsInstagramProfileOverviewProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerAnalyticsInstagramProfileOverview = ({
  socialAccount
}: InfluencerAnalyticsInstagramProfileOverviewProps) => {
  const { reinitParamsSearch } = useInfluencerProfileSearch();
  const { data, loading } = useInfluencerInstagramDashboardOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: { socialAccountId: socialAccount.id },
    onCompleted: ({ influencerInstagramDashboardOverview }) => {
      reinitParamsSearch({ lastUpdated: influencerInstagramDashboardOverview?.lastUpdated || '' });
    }
  });

  return (
    <div css={{ marginTop: '16px' }}>
      <AnalyticsInstagramDashboardOverviewTemplate
        loading={loading}
        isInfluencerProfile
        accountId={socialAccount.id}
        data={data?.influencerInstagramDashboardOverview}
      />
    </div>
  );
};
