import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { useAutoManagedCommissionsFilter } from './hooks';

export type AutoManagedCommissionsFilterProps = Omit<TemplateProps, 'onSubmit'>;

export const AutoManagedCommissionsFilter = (props: AutoManagedCommissionsFilterProps) => {
  const { filter, setFilterForm } = useAutoManagedCommissionsFilter();

  return (
    <FilterWrapper css={{ padding: '16px' }}>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<Template {...props} />} />
    </FilterWrapper>
  );
};

export { useAutoManagedCommissionsFilter };
export * from './schemaTypes';
