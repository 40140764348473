import { CampaignInfluencerReport, CampaignSummaryDaily, NewCampaignStatsItemRow } from '@/graphql';
import {
  PostReportStats,
  PostReportSegmentData,
  PostReportTableStatKeys,
  InfluencerPostReportStats,
  PostReportSegmentDataType
} from './types';

export const DEFAULT_SELECTED_COLUMNS: PostReportTableStatKeys[] = [
  'cost',
  'like',
  'comment',
  'followers',
  'engagement',
  'engagementRate'
];

export const getPostReportStats = (
  summaries?: NewCampaignStatsItemRow | null,
  insightDataAcquisition: string | null = null
): PostReportStats => ({
  insightDataAcquisition,
  cost: summaries?.cost ?? null,
  like: summaries?.like ?? null,
  view: summaries?.view ?? null,
  back: summaries?.back ?? null,
  share: summaries?.share ?? null,
  reach: summaries?.reach ?? null,
  saved: summaries?.saved ?? null,
  click: summaries?.click ?? null,
  sales: summaries?.sales ?? null,
  exited: summaries?.exited ?? null,
  retweets: summaries?.share ?? null, // Retweets get data from share
  comment: summaries?.comment ?? null,
  forward: summaries?.forward ?? null,
  dislikes: summaries?.dislikes ?? null,
  reachRate: summaries?.reachRate ?? null,
  followers: summaries?.followers ?? null,
  nextStory: summaries?.nextStory ?? null,
  conversion: summaries?.conversion ?? null,
  engagement: summaries?.engagement ?? null,
  linkClicks: summaries?.linkClicks ?? null,
  navigation: summaries?.navigation ?? null,
  impressions: summaries?.impressions ?? null,
  stickerTaps: summaries?.stickerTaps ?? null,
  interaction: summaries?.interaction ?? null,
  costsPerLike: summaries?.costsPerLike ?? null,
  costsPerView: summaries?.costsPerView ?? null,
  costsPerSale: summaries?.costsPerSale ?? null,
  newFollowers: summaries?.newFollowers ?? null,
  followerReach: summaries?.followerReach ?? null,
  costsPerShare: summaries?.costsPerShare ?? null,
  costsPerClick: summaries?.costsPerClick ?? null,
  costsPerReach: summaries?.costsPerReach ?? null,
  costsPerSaved: summaries?.costsPerSaved ?? null,
  totalPlayTime: summaries?.totalPlayTime ?? null,
  engagementRate: summaries?.engagementRate ?? null,
  engagementViewsRate: summaries?.engagementViewsRate ?? null,
  impressionRate: summaries?.impressionRate ?? null,
  malePercentage: summaries?.malePercentage ?? null,
  costsPerAction: summaries?.costsPerAction ?? null,
  costsPerComment: summaries?.costsPerComment ?? null,
  averageViewTime: summaries?.averageViewTime ?? null,
  profileActivity: summaries?.profileActivity ?? null,
  nonFollowerReach: summaries?.nonFollowerReach ?? null,
  costsPerFollower: summaries?.costsPerFollower ?? null,
  femalePercentage: summaries?.femalePercentage ?? null,
  followerReachRate: summaries?.followerReachRate ?? null,
  age1824Percentage: summaries?.age1824Percentage ?? null,
  age2534Percentage: summaries?.age2534Percentage ?? null,
  age35UpPercentage: summaries?.age35UpPercentage ?? null,
  costsPerEngagement: summaries?.costsPerEngagement ?? null,
  videoCompletionRate: summaries?.videoCompletionRate ?? null,
  costsPerImpressions: summaries?.costsPerImpressions ?? null,
  impressionsFromHome: summaries?.impressionsFromHome ?? null,
  impressionsFromOther: summaries?.impressionsFromOther ?? null,
  nonFollowerReachRate: summaries?.nonFollowerReachRate ?? null,
  impressionsFromHashtag: summaries?.impressionsFromHashtag ?? null,
  impressionsFromProfile: summaries?.impressionsFromProfile ?? null,
  impressionsFromDiscovery: summaries?.impressionsFromDiscovery ?? null,
  averageViewDurationMinutes: summaries?.averageViewDurationMinutes ?? null,
  averageViewDurationPercentage: summaries?.averageViewDurationPercentage ?? null,
  viewsFromHome: summaries?.viewsFromHome ?? null,
  viewsFromOther: summaries?.viewsFromOther ?? null,
  viewsFromSearch: summaries?.viewsFromSearch ?? null,
  viewsFromProfile: summaries?.viewsFromProfile ?? null,
  viewsFromFollowing: summaries?.viewsFromFollowing ?? null
});

export const getInfluencerPostReportStats = (influencerReports: readonly CampaignInfluencerReport[]) =>
  influencerReports?.map<InfluencerPostReportStats>(
    ({ summary, postReports, influencerInfo }) => ({
      influencerDetails: {
        id: influencerInfo.id,
        name: influencerInfo.name,
        thumbNail: influencerInfo.thumbNail,
        posts:
          postReports?.map(({ postInfo, summary: postSummary }) => ({
            stats: getPostReportStats(postSummary, postInfo.insightDataAcquisition),
            details: {
              id: postInfo.id,
              url: postInfo.postUrl,
              content: postInfo.content,
              socialMedia: postInfo.socialMedia,
              thumbNail: postInfo.thumbNail || '',
              hasTrafficSource: !!postInfo.hasTrafficSource
            }
          })) || []
      },
      stats: getPostReportStats(
        summary,
        postReports && postReports.length <= 1 ? postReports.at(0)?.postInfo.insightDataAcquisition : undefined
      )
    }),
    []
  ) || [];
/**
 * since table column cannot respect min-width - we need to explicitly set width on it's parts
 * */
export const POST_REPORT_STICKY_COL_DIMS = {
  totalWidth: '20vw',
  totalMinWidth: '350px',
  influencerProfileWidth: '40%',
  postCaptionLinkWidth: '58%' // (100% - influencerProfileWidth) to have some gap between profile section and post section, but also align on the vertical line if post is alone in the row
};

// Format segment data
export const formatPostReportSegmentTotal = (data?: readonly CampaignSummaryDaily[] | null) =>
  data?.map<PostReportSegmentDataType>(({ date, stats }) => ({ date, stats: getPostReportStats(stats) })) || [];

export const formatPostReportSegmentInfluencerPostDaily = (
  data?: readonly CampaignInfluencerReport[] | null
): PostReportSegmentData['post'] =>
  data?.reduce<PostReportSegmentData['post']>(
    (results, { postReports }) => ({
      ...results,
      ...(postReports?.reduce<PostReportSegmentData['post']>(
        (posts, { postInfo: { id }, orderedSummaryDaily }) => ({
          ...posts,
          [id]:
            orderedSummaryDaily?.map<PostReportSegmentDataType>(({ date, stats }) => ({
              date,
              stats: getPostReportStats(stats)
            })) || []
        }),
        {}
      ) || {})
    }),
    {}
  ) || null;

export const formatPostReportSegmentInfluencerDaily = (
  data?: readonly CampaignInfluencerReport[] | null
): PostReportSegmentData['influencer'] =>
  data?.reduce<PostReportSegmentData['influencer']>(
    (results, { influencerInfo, orderedSummaryDaily }) => ({
      ...results,
      [influencerInfo.id]:
        orderedSummaryDaily?.map<PostReportSegmentDataType>(({ date, stats }) => ({
          date,
          stats: getPostReportStats(stats)
        })) || []
    }),
    {}
  ) || null;
