import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthReconnectTwitterAccountAnalyticsV2.graphql';
import { Mutation, MutationsocialAuthReconnectTwitterAccountAnalyticsV2Args } from '../../__generated__/globalTypes';

export const useSocialAuthReconnectTwitterAccountAnalyticsV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthReconnectTwitterAccountAnalyticsV2Args>
) => {
  const [callSocialAuthReconnectTwitterAccountAnalyticsV2, result] = useMutation<
    Mutation,
    MutationsocialAuthReconnectTwitterAccountAnalyticsV2Args
  >(MUTATION, options);

  return { callSocialAuthReconnectTwitterAccountAnalyticsV2, ...result };
};

export type SocialAuthReconnectTwitterAccountAnalyticsV2MutationFunction =
  Mutation['socialAuthReconnectTwitterAccountAnalyticsV2'];
