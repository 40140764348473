import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { AutoManagedCampaignType } from '@/graphql';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const MarketplaceAutoManagedCampaignFilterSchema = z.object({
  keyword: FilterValidations.string(),
  countryId: FilterValidations.string(),
  categoryIds: FilterValidations.arrayString(),
  minPrice: FilterValidations.number(),
  maxPrice: FilterValidations.number(),
  minCommissionRate: FilterValidations.number(),
  maxCommissionRate: FilterValidations.number(),
  marketplaceType: FilterValidations.enum(AutoManagedCampaignType)
});
export const MarketplaceAutoManagedCampaignSearchSchema = z.object({
  filter: MarketplaceAutoManagedCampaignFilterSchema.default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type MarketplaceAutoManagedCampaignSearchSchemaType = z.infer<typeof MarketplaceAutoManagedCampaignSearchSchema>;
export type MarketplaceAutoManagedCampaignFilterFormValues = z.infer<typeof MarketplaceAutoManagedCampaignFilterSchema>;
export type MarketplaceAutoManagedCampaignFilterKeys = UnionOfKeys<MarketplaceAutoManagedCampaignFilterFormValues>;
