import { useTranslation } from 'react-i18next';
import { Anchor, TextCutter } from '@/shared/atoms';
import { useGenerateInfluencerProfileLink, usePostDetailsParam } from '@/shared/hooks';
import { InfluencerAvatar } from '@/shared/molecules';
import { Post } from './Post';
import { PostReportStats, PostReportDetails } from './types';
import { POST_REPORT_STICKY_COL_DIMS } from './utils';

interface InfluencerPostProps {
  id: number;
  name: string;
  thumbNail: string;
  isEngagement?: boolean;
  hasTrafficSource?: boolean;
  posts?: { stats: PostReportStats; details: PostReportDetails }[];
}

export const InfluencerPost = ({ id, name, thumbNail, posts = [], isEngagement }: InfluencerPostProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const firstPost = posts.at(0)?.details;
  const totalPosts = posts.length || 0;

  return (
    <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Anchor
        css={{ display: 'flex', alignItems: 'center', width: POST_REPORT_STICKY_COL_DIMS.influencerProfileWidth }}
        {...generateProfileLink(id)}
      >
        <InfluencerAvatar
          size="48px"
          src={thumbNail}
          css={{ borderRadius: '2px', border: '1px solid #f5f5f5' }}
          asChild
        />
        <TextCutter lines={2} css={{ fontSize: '13px', marginLeft: '8px' }} text={name || '-'} />
      </Anchor>

      {totalPosts > 1 ? (
        <span css={{ fontSize: '13px', marginLeft: '8px' }}>
          <b>{totalPosts}</b>&nbsp;
          <span css={{ fontSize: '10px', textTransform: 'uppercase' }}>{t('Post')}</span>
        </span>
      ) : firstPost ? (
        <Post
          url={firstPost.url}
          content={firstPost.content}
          isEngagement={isEngagement}
          thumbNail={firstPost.thumbNail}
          socialMedia={firstPost.socialMedia}
          hasTrafficSource={firstPost.hasTrafficSource}
          onClickPost={() => setPostDetailsParam(firstPost.id)}
        />
      ) : null}
    </div>
  );
};
