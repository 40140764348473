import {
  namedOperations,
  TiktokAdAccountStatus,
  useTransferMoneyToAdAccountMutation,
  useWithdrawMoneyFromAdAccountMutation
} from '@/graphql';
import { Button, Modal, FormProvider } from '@/shared/atoms';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useToggle } from '@/shared/hooks';
import { FormAction } from '@/shared/types';
import { Template } from './Template';
import { TransferModalFormValues, TransferModalProps } from './types';
import { maskitoParseNumber, getTransferModalSchema } from './schema';

export const TransferModal = ({ className, adAccount, prepaidBalance }: TransferModalProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { on, setOn, setOff } = useToggle();
  const { callTransferMoneyToAdAccount, loading } = useTransferMoneyToAdAccountMutation({
    refetchQueries: [namedOperations.Query.TiktokBusinessAdAccounts]
  });
  const { callWithdrawMoneyFromAdAccount, loading: withdrawLoading } = useWithdrawMoneyFromAdAccountMutation({
    refetchQueries: [namedOperations.Query.TiktokBusinessAdAccounts]
  });

  let reset: () => void | undefined;

  const handleSubmit: FormAction<TransferModalFormValues>['onSubmit'] = async ({ amount, step }, methods) => {
    reset = methods.reset;

    try {
      if (step === 'charge') {
        await callTransferMoneyToAdAccount({
          variables: {
            input: {
              adAccountId: adAccount.id,
              amount: maskitoParseNumber(amount)
            }
          }
        });

        enqueueSnackbar(t('You have successfully transferred', { amount: `${amount} ${adAccount.currencyId}` }), {
          variant: 'success'
        });
      } else {
        await callWithdrawMoneyFromAdAccount({
          variables: {
            input: {
              adAccountId: adAccount.id,
              amount: maskitoParseNumber(amount)
            }
          }
        });

        enqueueSnackbar(t('You have successfully withdrawn', { amount: `${amount} ${adAccount.currencyId}` }), {
          variant: 'success'
        });
      }

      setOff();
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <div className={className}>
      <Button
        variant="black"
        title={t('Button.Transfer')}
        css={{ minWidth: '104px' }}
        onClick={setOn}
        disabled={adAccount.status !== TiktokAdAccountStatus.VERIFIED}
      />
      <Modal
        open={on}
        css={{ width: THEME.modal.size.lv1 }}
        onClose={setOff}
        onAfterClose={() => reset?.()}
        shouldCloseOnOverlayClick
        hasCloseIcon
      >
        <FormProvider<TransferModalFormValues>
          onSubmit={handleSubmit}
          zodSchema={getTransferModalSchema(prepaidBalance, adAccount.balanceAmount)}
          defaultValues={{
            amount: '',
            step: 'charge'
          }}
        >
          <Template
            onClose={setOff}
            loading={loading || withdrawLoading}
            adAccount={adAccount}
            prepaidBalance={prepaidBalance}
          />
        </FormProvider>
      </Modal>
    </div>
  );
};
