import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { EngagementCampaignStatus } from '@/graphql';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const EngagementCampaignsFilterSchema = z.object({
  keyword: FilterValidations.string(),
  countryIds: FilterValidations.arrayString(),
  salesPicIds: FilterValidations.arrayString(),
  hubspotDealIds: FilterValidations.arrayString(),
  influencerPicIds: FilterValidations.arrayString(),
  campaignCategoryIds: FilterValidations.arrayString(),
  status: FilterValidations.enum(EngagementCampaignStatus)
});
export const EngagementCampaignsSearchSchema = z.object({
  filter: EngagementCampaignsFilterSchema.default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type EngagementCampaignsSearchSchemaType = z.infer<typeof EngagementCampaignsSearchSchema>;
export type EngagementCampaignsFilterFormValues = z.infer<typeof EngagementCampaignsFilterSchema>;
export type EngagementCampaignsFilterFormKeys = UnionOfKeys<EngagementCampaignsFilterFormValues>;
