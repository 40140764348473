import { useParams, Navigate } from '@tanstack/react-router';
import { useResetPasswordAndLoginMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { TikTokPasswordForm, TikTokPasswordFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';
import { setToken } from '@/shared/utils';

export const TikTokPasswordRegister = () => {
  const { callResetPasswordAndLogin } = useResetPasswordAndLoginMutation();
  const params = useParams({ from: '/_public-routes/tiktok-onboarding/password-register/$token' });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  useUpdateDocumentTitle({
    title: 'appHeader.Set a password and sign in',
    appHeader: 'appHeader.Set a password and sign in'
  });

  const token = params.token;

  if (!token) {
    return <Navigate to="/tiktok-onboarding/ad-account" />;
  }

  const handleSubmit: FormAction<TikTokPasswordFormValues>['onSubmit'] = async (payload) => {
    try {
      const result = await callResetPasswordAndLogin({
        variables: { input: { token, password: payload.password } }
      });

      if (result?.data?.resetPasswordAndLogin) {
        setToken(result?.data?.resetPasswordAndLogin.token);

        enqueueSnackbar(t('succeededInUpdatingPassword'), { variant: 'success' });
        navigate({ to: '/tiktok-onboarding/select-plan' });
      } else {
        enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('failedToUpdatePassword'), { variant: 'error' });
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return <TikTokPasswordForm onSubmit={handleSubmit} />;
};
