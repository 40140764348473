import { linkOptions, Navigate, redirect, useParams } from '@tanstack/react-router';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';
import { useAnalyticsProfileContext } from '../AnalyticsProfileContext';
import { AnalyticsUGCInstagram } from './AnalyticsUGCInstagram';

export const AnalyticsUGC = () => {
  const params = useParams({
    from: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc'
  });
  const {
    selectedSocialAccount,
    brandAccount: { subscriptionPlan }
  } = useAnalyticsProfileContext();

  const redirectUrl = linkOptions({
    to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard',
    params
  });

  if (!subscriptionPlan.availableFeatures?.hashtagsEnabled) {
    return <Navigate {...redirectUrl} />;
  }

  const navigateBackToDashboard = () => {
    redirect(redirectUrl);
  };

  // Analytics just support UGC for IG now. We might have others later
  switch (selectedSocialAccount.socialMedia) {
    case AnalyticsSupportedSocialMediaType.instagram:
      return <AnalyticsUGCInstagram navigateBackToDashboard={navigateBackToDashboard} />;
    default:
      return <Navigate {...redirectUrl} />;
  }
};
