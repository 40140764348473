import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestProposalSpreadsheet.graphql';
import { Mutation, MutationrequestProposalSpreadsheetArgs } from '../../__generated__/globalTypes';

export const useRequestProposalSpreadsheetMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestProposalSpreadsheetArgs>
) => {
  const [callRequestProposalSpreadsheet, result] = useMutation<Mutation, MutationrequestProposalSpreadsheetArgs>(
    MUTATION,
    options
  );

  return { callRequestProposalSpreadsheet, ...result };
};

export type RequestProposalSpreadsheetMutationFunction = Mutation['requestProposalSpreadsheet'];
