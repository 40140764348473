import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '@/shared/types';
import { CampaignReportOrderField } from '@/graphql';
import { ColumnCustomiser } from '@/shared/molecules';
import { ReportColumnKeys, ReportColumnProps } from '../types';
import { initialValuesReport, defaultSelectedColumns } from '../utils';

interface Props {
  total?: number;
}

export const useReportColumns = ({ total = 0 }: Props) => {
  const { t } = useTranslation();
  const [selectedColumns, setSelectedColumns] = useState<ReportColumnKeys[]>(defaultSelectedColumns);

  const getMetricColumnProps = ({ key, hidden, styles, ...columnProps }: ReportColumnProps): ReportColumnProps => ({
    ...columnProps,
    key,
    styles: { textAlign: 'right', ...styles },
    hidden: hidden || (key && !selectedColumns.includes(key))
  });

  const reportMetrics: ReportColumnProps[] = [
    {
      key: 'cost',
      help: t<string>('Tooltip.costHelp'),
      title: t('HeaderColumn.Cost'),
      sortField: CampaignReportOrderField.COST
    },
    {
      key: 'followers',
      help: t<string>('Tooltip.UniqueReachHelp'),
      title: t('HeaderColumn.Follower'),
      sortField: CampaignReportOrderField.FOLLOWERS
    },
    {
      key: 'impressions',
      help: t<string>('Tooltip.impressionsHelp'),
      title: t('HeaderColumn.Impression'),
      sortField: CampaignReportOrderField.IMPRESSIONS
    },
    {
      key: 'impressionRate',
      help: t<string>('Tooltip.impressionsRateHelp'),
      title: t('HeaderColumn.Impression Rate'),
      sortField: CampaignReportOrderField.IMPRESSION_RATE
    },
    {
      key: 'impressionsFromDiscovery',
      help: t<string>('Tooltip.impressionsFromDiscoveryHelp'),
      title: t('HeaderColumn.Impression from Discovery'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_DISCOVERY
    },
    {
      key: 'impressionsFromHashtag',
      help: t<string>('Tooltip.impressionsFromHashtagHelp'),
      title: t('HeaderColumn.Impression from Hashtag'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_HASHTAG
    },
    {
      key: 'impressionsFromHome',
      help: t<string>('Tooltip.impressionsFromHomeHelp'),
      title: t('HeaderColumn.Impression from Home'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_HOME
    },
    {
      key: 'impressionsFromProfile',
      help: t<string>('Tooltip.impressionsFromProfileHelp'),
      title: t('HeaderColumn.Impression from Profile'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_PROFILE
    },
    {
      key: 'impressionsFromOther',
      help: t<string>('Tooltip.impressionsFromOtherHelp'),
      title: t('HeaderColumn.Impression from Other'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_OTHER
    },
    {
      key: 'view',
      help: t<string>('Tooltip.videoViewHelp'),
      title: t('HeaderColumn.Video view'),
      sortField: CampaignReportOrderField.VIEW
    },
    {
      key: 'averageViewDurationMinutes',
      help: t<string>('Tooltip.averageViewDurationMinutesHelp'),
      title: t('HeaderColumn.Average View Duration (m)'),
      sortField: CampaignReportOrderField.AVERAGE_VIEW_DURATION_MINUTES
    },
    {
      key: 'averageViewDurationPercentage',
      help: t<string>('Tooltip.averageViewDurationPercentageHelp'),
      title: t('HeaderColumn.Average View Duration Rate'),
      sortField: CampaignReportOrderField.AVERAGE_VIEW_DURATION_PERCENTAGE
    },
    {
      key: 'reach',
      help: t<string>('Tooltip.reachHelp'),
      title: t('HeaderColumn.Reach'),
      sortField: CampaignReportOrderField.REACH
    },
    {
      key: 'reachRate',
      help: t<string>('Tooltip.reachRateHelp'),
      title: t('HeaderColumn.Reach Rate'),
      sortField: CampaignReportOrderField.REACH_RATE
    },
    {
      key: 'nonFollowerReach',
      help: t<string>('Tooltip.nonFollowerReachHelp'),
      title: t('HeaderColumn.Non Follower Reach'),
      sortField: CampaignReportOrderField.NON_FOLLOWER_REACH
    },
    {
      key: 'nonFollowerReachRate',
      help: t<string>('Tooltip.nonFollowerReachRateHelp'),
      title: t('HeaderColumn.Non Follower Reach Rate'),
      sortField: CampaignReportOrderField.NON_FOLLOWER_REACH_RATE
    },
    {
      key: 'engagement',
      help: t<string>('Tooltip.TotalEngagementsHelp'),
      title: t('HeaderColumn.Engagement'),
      sortField: CampaignReportOrderField.ENGAGEMENT
    },
    {
      key: 'engagementRate',
      help: t<string>('Tooltip.engagementRateHelp'),
      title: t('HeaderColumn.Egg Rate by followers'),
      sortField: CampaignReportOrderField.ENGAGEMENT_RATE
    },
    {
      key: 'engagementViewsRate',
      help: t<string>('Tooltip.engagementViewsRateHelp'),
      title: t('HeaderColumn.Egg Rate by views'),
      sortField: CampaignReportOrderField.ENGAGEMENT_VIEWS_RATE
    },
    {
      key: 'like',
      help: t<string>('Tooltip.LikeReactionHelp'),
      title: t('HeaderColumn.Like'),
      sortField: CampaignReportOrderField.LIKE
    },
    {
      key: 'dislikes',
      help: t<string>('Tooltip.dislikesHelp'),
      title: t('HeaderColumn.Dislike'),
      sortField: CampaignReportOrderField.DISLIKES
    },
    {
      key: 'comment',
      help: t<string>('Tooltip.commentHelp'),
      title: t('HeaderColumn.Comment'),
      sortField: CampaignReportOrderField.COMMENT
    },
    {
      key: 'share',
      help: t<string>('Tooltip.shareHelp'),
      title: t('HeaderColumn.Share'),
      sortField: CampaignReportOrderField.SHARE
    },
    {
      key: 'retweets',
      help: t<string>('Tooltip.retweetHelp'),
      title: t('HeaderColumn.Retweets'),
      sortField: CampaignReportOrderField.SHARE
    },
    {
      key: 'saved',
      help: t<string>('Tooltip.SaveHelp'),
      title: t('HeaderColumn.Save'),
      sortField: CampaignReportOrderField.SAVED
    },
    {
      key: 'interaction',
      help: t<string>('Tooltip.interactionHelp'),
      title: t('HeaderColumn.Interaction'),
      sortField: CampaignReportOrderField.INTERACTION
    },
    {
      key: 'linkClicks',
      help: t<string>('Tooltip.linkClicksHelp'),
      title: t('HeaderColumn.Link Clicks'),
      sortField: CampaignReportOrderField.LINK_CLICKS
    },
    {
      key: 'stickerTaps',
      help: t<string>('Tooltip.stickerTapsHelp'),
      title: t('HeaderColumn.Sticker Taps'),
      sortField: CampaignReportOrderField.STICKER_TAPS
    },
    {
      key: 'click',
      help: t<string>('Tooltip.Click Action Help'),
      title: t('HeaderColumn.Click'),
      sortField: CampaignReportOrderField.CLICK
    },
    {
      key: 'conversion',
      help: t<string>('Tooltip.Click Conversion Help'),
      title: t('HeaderColumn.Conversion'),
      sortField: CampaignReportOrderField.CONVERSION
    },
    {
      key: 'orders',
      title: t('HeaderColumn.Orders'),
      sortField: CampaignReportOrderField.ORDER
    },
    {
      key: 'sales',
      title: t('HeaderColumn.Sales'),
      sortField: CampaignReportOrderField.SALES
    },
    {
      key: 'costsPerSale',
      title: t('HeaderColumn.Commission rate'),
      sortField: CampaignReportOrderField.COST_PER_SALE
    },
    {
      key: 'costsPerFollower',
      title: t('HeaderColumn.Cost / Follower'),
      sortField: CampaignReportOrderField.COST_PER_FOLLOWER
    },
    {
      key: 'costsPerImpressions',
      title: t('HeaderColumn.Cost / Impression'),
      sortField: CampaignReportOrderField.COST_PER_IMPRESSIONS
    },
    {
      key: 'costsPerView',
      title: t('HeaderColumn.Cost / Video view'),
      sortField: CampaignReportOrderField.COST_PER_VIEW
    },
    {
      key: 'costsPerReach',
      title: t('HeaderColumn.Cost / Reach'),
      sortField: CampaignReportOrderField.COST_PER_REACH
    },
    {
      key: 'costsPerEngagement',
      title: t('HeaderColumn.Cost / Engagement'),
      sortField: CampaignReportOrderField.COST_PER_ENGAGEMENT
    },
    {
      key: 'costsPerLike',
      title: t('HeaderColumn.Cost / Like'),
      sortField: CampaignReportOrderField.COST_PER_LIKE
    },
    {
      key: 'costsPerComment',
      title: t('HeaderColumn.Cost / Comment'),
      sortField: CampaignReportOrderField.COST_PER_COMMENT
    },
    {
      key: 'costsPerShare',
      title: t('HeaderColumn.Cost / Share'),
      sortField: CampaignReportOrderField.COST_PER_SHARE
    },
    {
      key: 'costsPerSaved',
      title: t('HeaderColumn.Cost / Save'),
      sortField: CampaignReportOrderField.COST_PER_SAVED
    },
    {
      key: 'costsPerClick',
      help: t<string>('Tooltip.CPC Help'),
      title: t('HeaderColumn.CPC'),
      sortField: CampaignReportOrderField.COST_PER_CLICK
    },
    {
      key: 'costsPerAction',
      help: t<string>('Tooltip.CPA Help'),
      title: t('HeaderColumn.CPA'),
      sortField: CampaignReportOrderField.COST_PER_ACTION
    },
    {
      key: 'costsPerOrder',
      title: t('HeaderColumn.CPO'),
      sortField: CampaignReportOrderField.COST_PER_ORDER
    },
    {
      key: 'back',
      help: t<string>('Tooltip.backHelp'),
      title: t('HeaderColumn.Back'),
      sortField: CampaignReportOrderField.BACK
    },
    {
      key: 'exited',
      help: t<string>('Tooltip.exitedHelp'),
      title: t('HeaderColumn.Exited'),
      sortField: CampaignReportOrderField.EXITED
    },
    {
      key: 'forward',
      help: t<string>('Tooltip.forwardHelp'),
      title: t('HeaderColumn.Forward'),
      sortField: CampaignReportOrderField.FORWARD
    },
    {
      key: 'nextStory',
      help: t<string>('Tooltip.nextStoryHelp'),
      title: t('HeaderColumn.Next Story'),
      sortField: CampaignReportOrderField.NEXT_STORY
    },
    {
      key: 'navigation',
      help: t<string>('Tooltip.navigationHelp'),
      title: t('HeaderColumn.Navigation'),
      sortField: CampaignReportOrderField.NAVIGATION
    },
    {
      key: 'profileActivity',
      help: t<string>('Tooltip.profileActivityHelp'),
      title: t('HeaderColumn.Profile Activity'),
      sortField: CampaignReportOrderField.PROFILE_ACTIVITY
    }
  ];
  const metricColumns: ReportColumnProps[] = reportMetrics.map(getMetricColumnProps);
  const selectableColumnOptions = reportMetrics.reduce<Option<ReportColumnKeys>[]>(
    (rs, cr) => (cr.hidden ? rs : [...rs, { label: cr.title as string, value: cr.key as ReportColumnKeys }]),
    []
  );
  const campaignTitleColumn: ReportColumnProps = {
    title: (
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {t('HeaderColumn.Campaign Title')}
        <ColumnCustomiser<ReportColumnKeys>
          defaultOptions={selectedColumns}
          initialValues={initialValuesReport}
          savedValuesKey="reportTableColumns"
          setSelectedColumns={setSelectedColumns}
          options={selectableColumnOptions}
          position={total < 6 ? 'right-end' : 'right-start'}
        />
      </div>
    ),
    sticky: { active: true, hasBorderRight: true }
  };

  return { selectedColumns, columns: [campaignTitleColumn, ...metricColumns] };
};
