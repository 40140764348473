import { css } from '@emotion/react';
import { EngagementCampaignPostStatus, EngagementPost, EngagementPostDetail } from '@/graphql';
import { Button } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';

interface FooterNodeProps {
  isReviewing: boolean;
  hasReportInappropriate?: boolean | null;
  handleRejectPost: (postDetails: EngagementPostDetail) => void;
  handleSelectStatus: (status: EngagementCampaignPostStatus, posts: EngagementPost[]) => void;
  postDetails: EngagementPostDetail;
  selectedPost: EngagementPost;
}
export const FooterNode = ({
  isReviewing,
  hasReportInappropriate,
  handleRejectPost,
  handleSelectStatus,
  postDetails,
  selectedPost
}: FooterNodeProps) => {
  const { t } = useQueryHelper();

  return (
    <>
      {isReviewing ? (
        <div css={{ gap: '8px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
            variant="red"
            css={styles.button}
            title={t('Button.Reject')}
            onClick={() => handleRejectPost(postDetails)}
          />
          <Button
            variant="blue"
            css={styles.button}
            title={t('Button.Approve Post')}
            onClick={() => handleSelectStatus(EngagementCampaignPostStatus.READY_TO_POST, [selectedPost])}
          />
        </div>
      ) : null}

      {hasReportInappropriate ? (
        <>
          <Button
            variant="white"
            title={t('Button.Modification Request')}
            onClick={() => handleRejectPost(postDetails)}
            css={[styles.button, { marginLeft: 'auto' }]}
          />
          <p css={styles.modificationNote}>{t('You can send feedback to creator via Email and AnyCreator')}</p>
        </>
      ) : null}
    </>
  );
};

const styles = {
  button: css({ padding: '0 24px', textTransform: 'capitalize' }),
  modificationNote: css({
    marginTop: '8px',
    fontSize: '12px',
    lineHeight: '140%',
    textAlign: 'right',
    color: THEME.text.colors.gray.lv3
  })
};
