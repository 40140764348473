import { LIMIT_6 } from '@/shared/constants';
import { getValuableVariables } from '@/shared/utils';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { BasicAccountAnalyticsPayload, useInstagramDashboardPostAnalyticsQuery } from '@/graphql';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { AnalyticsInstagramDashboardComments } from './AnalyticsInstagramDashboardComments';
import { AnalyticsInstagramDashboardFeedPosts } from './AnalyticsInstagramDashboardFeedPosts';
import { AnalyticsInstagramDashboardReelPosts } from './AnalyticsInstagramDashboardReelPosts';
import { AnalyticsInstagramDashboardStoryPosts } from './AnalyticsInstagramDashboardStoryPosts';
import { AnalyticsInstagramDashboardPostsOverview } from './AnalyticsInstagramDashboardPostsOverview';

export interface AnalyticsInstagramDashboardPostsProps {
  brandAccount: BasicAccountAnalyticsPayload;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsInstagramDashboardPosts = ({
  brandAccount,
  analyticsAccount
}: AnalyticsInstagramDashboardPostsProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useInstagramDashboardPostAnalyticsQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      accountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  const postsData = data?.instagramDashboardPostAnalytics || undefined;

  return (
    <div css={{ marginTop: '24px' }}>
      <RenderDataWithFallback loading={loading}>
        <AnalyticsInstagramDashboardPostsOverview
          postsHabit={postsData?.postsHabit || undefined}
          engagement={postsData?.averageEngagement || undefined}
        />

        <AnalyticsInstagramDashboardFeedPosts
          accountId={analyticsAccount.id}
          data={postsData?.feedPosts || []}
          brandAccountName={brandAccount.brandAccountName}
        />

        <AnalyticsInstagramDashboardReelPosts
          accountId={analyticsAccount.id}
          data={postsData?.reelsPosts || []}
          brandAccountName={brandAccount.brandAccountName}
        />

        <AnalyticsInstagramDashboardStoryPosts
          accountId={analyticsAccount.id}
          data={postsData?.storyPosts || []}
          brandAccountName={brandAccount.brandAccountName}
        />

        <AnalyticsInstagramDashboardComments
          brandAccountName={brandAccount.brandAccountName}
          data={postsData?.comments?.slice(0, LIMIT_6) || []}
        />
      </RenderDataWithFallback>
    </div>
  );
};
