import { css } from '@emotion/react';

import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';

import { TextCutter } from '../TextCutter';

import { Radio } from './Radio';

export interface RadioButtonsProps<T> {
  items: Option<T>[];
  value?: T;
  disabled?: boolean;
  disableOptions?: string[];
  handleSelect: (value: T) => void;
  className?: string;
}

export const RadioButtons = <T extends string = string>({
  items,
  value,
  disabled,
  disableOptions = [],
  handleSelect,
  className
}: RadioButtonsProps<T>) => (
  <div className={className} css={styles.wrapper}>
    {items.map((item) => {
      const checked = item.value === value;
      const disabledItem = disabled || disableOptions.includes(item.value);

      return (
        <div onClick={() => handleSelect(item.value)} key={item.value}>
          <Radio
            checked={checked}
            disabled={disabledItem}
            label={
              <TextCutter
                text={item.label}
                lines={1}
                css={{ marginLeft: '6px', color: checked ? THEME.text.colors.blue : THEME.text.colors.black.lv1 }}
              />
            }
          />
        </div>
      );
    })}
  </div>
);

const styles = {
  wrapper: css({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '12px'
  })
};
