import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ConnectPackageWithSocialAccounts.graphql';
import { Mutation, MutationconnectPackageWithSocialAccountsArgs } from '../../__generated__/globalTypes';

export const useConnectPackageWithSocialAccountsMutation = (
  options?: MutationHookOptions<Mutation, MutationconnectPackageWithSocialAccountsArgs>
) => {
  const [callConnectPackageWithSocialAccounts, result] = useMutation<
    Mutation,
    MutationconnectPackageWithSocialAccountsArgs
  >(MUTATION, options);

  return { callConnectPackageWithSocialAccounts, ...result };
};

export type ConnectPackageWithSocialAccountsMutationFunction = Mutation['connectPackageWithSocialAccounts'];
