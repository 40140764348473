import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/ExistingProfileTags.graphql';
import { Query, QueryexistingProfileTagsArgs } from '../../__generated__/globalTypes';

export const useExistingProfileTagsQuery = (options?: QueryHookOptions<Query, QueryexistingProfileTagsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryexistingProfileTagsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useExistingProfileTagsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryexistingProfileTagsArgs>
): QueryResult<Query, QueryexistingProfileTagsArgs> & {
  getExistingProfileTags: LazyQueryExecFunction<Query, QueryexistingProfileTagsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getExistingProfileTags, result] = useLazyQuery<Query, QueryexistingProfileTagsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getExistingProfileTags, ...result };
};

export const useExistingProfileTagsPromiseQuery = () => {
  const client = useApolloClient();

  const getExistingProfileTags = useCallback(
    (options?: Omit<QueryOptions<QueryexistingProfileTagsArgs, Query>, 'query'>) =>
      client.query<Query, QueryexistingProfileTagsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getExistingProfileTags };
};
