import { FormProvider } from '@/shared/atoms';
import { FormAction } from '@/shared/types';
import { ChangePasswordSchema } from './schema';
import { Template, TemplateProps } from './Template';
import { ChangePasswordFormValues } from './types';

export const ChangePasswordFormModal = ({
  onSubmit,
  defaultValues,
  ...templateProps
}: ChangePasswordFormModalProps) => (
  <FormProvider<ChangePasswordFormValues>
    onSubmit={onSubmit}
    zodSchema={ChangePasswordSchema}
    defaultValues={{
      password: defaultValues?.password || '',
      currentPassword: defaultValues?.currentPassword || '',
      passwordConfirm: defaultValues?.passwordConfirm || ''
    }}
  >
    <Template {...templateProps} />
  </FormProvider>
);

export * from './types';
export type ChangePasswordFormModalProps = FormAction<ChangePasswordFormValues> & Omit<TemplateProps, 'onSubmit'>;
