import { useTranslation } from 'react-i18next';
import { TextAreaField, TextAreaFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const AdditionalRequirementField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  ...restProps
}: Partial<TextAreaFieldProps<T>>) => {
  const { t } = useTranslation();

  return (
    <TextAreaField<T>
      title={t('TextForm.Additional Requirement')}
      name={name || (MarketplaceFormKeys.additionalRequirement as T)}
      placeholder={t<string>('TextForm.AdditionalRequirementPlaceholder')}
      {...restProps}
    />
  );
};
