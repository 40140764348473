import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeAnalyticsTagsTopRankingCount.graphql';
import { Query, QueryyoutubeAnalyticsTagsTopRankingCountArgs } from '../../__generated__/globalTypes';

export const useYoutubeAnalyticsTagsTopRankingCountQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeAnalyticsTagsTopRankingCountArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeAnalyticsTagsTopRankingCountArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeAnalyticsTagsTopRankingCountLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeAnalyticsTagsTopRankingCountArgs>
): QueryResult<Query, QueryyoutubeAnalyticsTagsTopRankingCountArgs> & {
  getYoutubeAnalyticsTagsTopRankingCount: LazyQueryExecFunction<Query, QueryyoutubeAnalyticsTagsTopRankingCountArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeAnalyticsTagsTopRankingCount, result] = useLazyQuery<
    Query,
    QueryyoutubeAnalyticsTagsTopRankingCountArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeAnalyticsTagsTopRankingCount, ...result };
};

export const useYoutubeAnalyticsTagsTopRankingCountPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeAnalyticsTagsTopRankingCount = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeAnalyticsTagsTopRankingCountArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeAnalyticsTagsTopRankingCountArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeAnalyticsTagsTopRankingCount };
};
