import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Icon, SocialButton, SocialLinkedAccounts } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { SupportedSocialMediaType } from '@/shared/types';
import { AnalyticsConnectAccountType } from '../schemaTypes';

interface SocialAccountProps<S = keyof typeof SupportedSocialMediaType> {
  socialMedia: S;
  loading?: boolean;
  onSignIn: (socialMedia: S) => void;
  onReconnect: (socialMedia: S) => void;
  account?: AnalyticsConnectAccountType;
}

export const SocialAccount = ({ account, loading, onSignIn, socialMedia, onReconnect }: SocialAccountProps) => {
  const { t } = useTranslation();

  const signInTitle = (() => {
    switch (socialMedia) {
      case 'INSTAGRAM':
        return t('Sign in with Instagram');
      case 'YOUTUBE':
        return t('Sign in with YouTube');
      case 'FACEBOOK_PAGE':
        return t('Sign in with Facebook');
      case 'TWITTER':
        return t('Sign in with Twitter');
      default:
        return '';
    }
  })();

  return account ? (
    <div css={styles.wrapper}>
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <SocialLinkedAccounts
          accounts={[{ name: account.name, type: socialMedia }, ...(account.linkedAccounts || [])]}
        />

        {account.needReconnect ? (
          <SocialButton
            loading={loading}
            socialMedia={socialMedia}
            title={t('Button.Reconnect')}
            onClick={() => onReconnect(socialMedia)}
            css={{ marginLeft: 'auto', padding: '0 16px', textTransform: 'uppercase' }}
          />
        ) : (
          <Icon size={20} icon="success" color="#40B87C" />
        )}
      </div>

      {account.needReconnect ? <p css={styles.reconnectMessage}>{t('ReconnectAnalyticsMessage')}</p> : null}
    </div>
  ) : (
    <SocialButton
      loading={loading}
      title={signInTitle}
      socialMedia={socialMedia}
      onClick={() => onSignIn(socialMedia)}
      css={{ gap: '16px', width: '197px', padding: '0 16px', marginTop: '16px' }}
      hasIcon
    />
  );
};
const styles = {
  wrapper: css({
    marginTop: '24px',
    borderRadius: '3px',
    padding: '12px 16px',
    boxSizing: 'border-box',
    border: THEME.border.base
  }),
  reconnectMessage: css({
    fontSize: '13px',
    lineHeight: 1.38,
    paddingTop: '8px',
    letterSpacing: 'normal',
    color: THEME.text.colors.red.lv1
  })
};
