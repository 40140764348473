import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { FacebookInfluencersProps } from './schemaTypes';
import { FacebookInfluencersList } from './FacebookInfluencersList';
import { FacebookInfluencersSort } from './FacebookInfluencersSort';
import { FacebookInfluencersFilter } from './FacebookInfluencersFilter';
import { FacebookInfluencersProvider } from './FacebookInfluencersContext';

export const FacebookInfluencers = (props: FacebookInfluencersProps) => (
  <FacebookInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <FacebookInfluencersFilter />
      <FacebookInfluencersSort />
    </InfluencersFilterWrapper>

    <FacebookInfluencersList />
  </FacebookInfluencersProvider>
);

export * from './schemaTypes';
