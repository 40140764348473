import { useYoutubeAnalyticsPostQuery, useInfluencerYoutubeAnalyticsPostQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useSelectItem } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { getYoutubePostUrl, getYoutubeVideoUrl } from '@/shared/utils';
import { YoutubeAnalyticsRelatedPostsModal } from '../../Modals';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InfluencerYoutubeAnalyticsPostDetailsProps
  extends Pick<PostDetailsType, 'id' | 'mainSocialAccountId'> {
  className?: string;
  isAnalytics?: boolean;
  onCloseModal?: () => void;
}

export const InfluencerYoutubeAnalyticsPostDetails = ({
  id,
  className,
  isAnalytics,
  onCloseModal,
  mainSocialAccountId
}: InfluencerYoutubeAnalyticsPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem<string>();
  const { data, loading } = useInfluencerYoutubeAnalyticsPostQuery({
    skip: !id || !mainSocialAccountId || isAnalytics,
    variables: { socialAccountId: Number(mainSocialAccountId), youtubeAnalyticsPostId: Number(id) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const { data: analyticsData, loading: fetchingAnalyticsData } = useYoutubeAnalyticsPostQuery({
    skip: !id || !mainSocialAccountId || !isAnalytics,
    variables: { youtubeAnalyticsAccountId: Number(mainSocialAccountId), youtubeAnalyticsPostId: Number(id) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const details = data?.influencerYoutubeAnalyticsPost || analyticsData?.youtubeAnalyticsPost;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'YOUTUBE',
        caption: details?.videoCaption,
        postUrl: getYoutubePostUrl(details?.videoId),
        video: { tags: details?.videoTags, title: details?.videoTitle, url: getYoutubeVideoUrl(details?.videoId) },
        comments: details.videoComments.map((comment) => ({ content: comment.content, username: comment.posterName })),
        statistics: {
          views: details?.views,
          averageViewDuration: details.averageViewDuration,
          averageViewPercentage: details.averageViewPercentage,
          likes: details?.likes,
          dislikes: details.dislikes,
          comments: details?.comments
        },
        info: {
          socialMedia: 'YOUTUBE',
          avatar: details?.avatar,
          name: details?.channelName,
          postDate: details?.postDate,
          followers: details?.subscribers
        }
      }
    : undefined;

  return (
    <>
      <DefaultPostDetailsTemplate
        className={className}
        postDetails={postDetails}
        onTagClick={setSelectedItem}
        css={{ width: THEME.modal.size.lv3 }}
        loading={loading || fetchingAnalyticsData}
      />

      <YoutubeAnalyticsRelatedPostsModal
        tag={selectedItem}
        postId={details?.id}
        onClose={resetSelectedItem}
        channelId={mainSocialAccountId}
        channelName={details?.channelName}
      />
    </>
  );
};
