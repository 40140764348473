import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateInfluencerRateCardsByStaff.graphql';
import { Mutation, MutationupdateInfluencerRateCardsByStaffArgs } from '../../__generated__/globalTypes';

export const useUpdateInfluencerRateCardsByStaffMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateInfluencerRateCardsByStaffArgs>
) => {
  const [callUpdateInfluencerRateCardsByStaff, result] = useMutation<
    Mutation,
    MutationupdateInfluencerRateCardsByStaffArgs
  >(MUTATION, options);

  return { callUpdateInfluencerRateCardsByStaff, ...result };
};

export type UpdateInfluencerRateCardsByStaffMutationFunction = Mutation['updateInfluencerRateCardsByStaff'];
