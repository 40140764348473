import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, ModalContent, ModalFooterActions, RadioWithSubsection } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TextField } from '@/shared/molecules';
import { CSV_EXPORT_TYPE, DownloadCsvPagesAndRowsModalKeys } from './types';

export interface TemplateProps {
  maxRows: number;
  onClose?: () => void;
  downloading?: boolean;
}

export const Template = ({ onClose, downloading, maxRows }: TemplateProps) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();
  const values = watch();

  return (
    <>
      <ModalContent css={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <h3 css={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 600 }}>{t('CSV Export')}</h3>
        <p>{t('Since the data is huge, you can export at most 500 data units at once')}</p>

        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <RadioWithSubsection
            iconMargin="4px"
            label={t('General.Pages')}
            checked={values.exportType === CSV_EXPORT_TYPE.PAGES}
            onClick={() => setValue('exportType', CSV_EXPORT_TYPE.PAGES)}
          >
            <div css={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
              <TextField<DownloadCsvPagesAndRowsModalKeys> name="firstPage" type="number">
                {!errors?.firstPage && !errors.lastPage ? (
                  <ErrorMessage
                    css={{ color: THEME.text.colors.gray.lv3, width: 'max-content' }}
                    message={t<string>('exportAtMostPagesAmount', { count: Math.round(maxRows / values.limit) })}
                  />
                ) : null}
              </TextField>
              <span>-</span>
              <TextField<DownloadCsvPagesAndRowsModalKeys> name="lastPage" type="number" />
              <span>{t('General.Pages').toLowerCase()}</span>
            </div>
          </RadioWithSubsection>
          <RadioWithSubsection
            iconMargin="4px"
            label={t('General.Rows')}
            checked={values.exportType === CSV_EXPORT_TYPE.ROWS}
            onClick={() => setValue('exportType', CSV_EXPORT_TYPE.ROWS)}
            css={{ marginTop: '16px' }}
          >
            <div css={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
              <TextField<DownloadCsvPagesAndRowsModalKeys> name="firstRow" type="number">
                {!errors?.firstRow && !errors.lastRow ? (
                  <ErrorMessage
                    message={t<string>('exportAtMostRowsAmount', { count: maxRows })}
                    css={{ color: THEME.text.colors.gray.lv3, width: 'max-content' }}
                  />
                ) : null}
              </TextField>
              <span>-</span>
              <TextField<DownloadCsvPagesAndRowsModalKeys> type="number" name="lastRow" />
              <span>{t('General.Rows').toLowerCase()}</span>
            </div>
          </RadioWithSubsection>
        </div>
      </ModalContent>
      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{
          loading: downloading,
          title: t('Button.Export'),
          disabled: downloading,
          type: 'submit'
        }}
      />
    </>
  );
};
