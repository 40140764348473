import {
  MutationaddInfluencerToUserProposalArgs,
  MutationdeleteUserProposalInfluencerArgs,
  namedOperations,
  useAddInfluencerToUserProposalMutation,
  useDeleteUserProposalInfluencerMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';

export const useToggleSelectedInfluencers = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callAddInfluencerToUserProposal, loading: isUpdatingProposal } = useAddInfluencerToUserProposalMutation({
    refetchQueries: [namedOperations.Query.UserProposal]
  });
  const { callDeleteUserProposalInfluencer, loading: isDeletingProposal } = useDeleteUserProposalInfluencerMutation({
    refetchQueries: [namedOperations.Query.UserProposal]
  });

  const toggleSelectedInfluencer = async (influencerIds: number[], isChecked: boolean) => {
    let error;
    const variables: MutationaddInfluencerToUserProposalArgs | MutationdeleteUserProposalInfluencerArgs = {
      input: { influencerIds }
    };

    try {
      if (isChecked) {
        await callDeleteUserProposalInfluencer({ variables });
      } else {
        await callAddInfluencerToUserProposal({ variables });
      }
      // we don't want to notify user on each save/delete, just show an error if happens
    } catch (err) {
      error = err.message;
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }

    return { error };
  };

  return { toggleSelectedInfluencer, loading: isUpdatingProposal || isDeletingProposal };
};
