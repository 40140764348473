import { FormProvider } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TikTokSignupFormSchema } from './schema';
import { Template } from './Template';
import { TikTokSignupFormProps, TikTokSignupFormValues } from './types';

export const TikTokSignupForm = ({
  onSubmit,
  defaultValues = {
    name: '',
    email: '',
    countryId: 'TH',
    companyUrl: '',
    companyName: '',
    phoneNumber: '',
    acceptTerms: false
  }
}: TikTokSignupFormProps) => (
  <FormProvider
    onSubmit={onSubmit}
    defaultValues={defaultValues}
    zodSchema={TikTokSignupFormSchema}
    css={{ backgroundColor: THEME.background.colors.white, boxShadow: THEME.shadows.boxShadowContainer }}
  >
    <Template />
  </FormProvider>
);

export { type TikTokSignupFormValues };
