import { useQueryHelper } from '@/shared/hooks';
import { useInfluencerWhitelistQuery } from '@/graphql';
import { useMarketplaceDetailsContext } from '../../MarketplaceDetailsContext';

export const useMarketplaceInfluencersWhiteList = () => {
  const { marketplaceId } = useMarketplaceDetailsContext();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { data, loading, networkStatus } = useInfluencerWhitelistQuery({
    fetchPolicy: 'network-only',
    variables: { marketplaceId },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      navigate({ to: '/marketplace' });
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  return {
    loading: loading && networkStatus === 1,
    influencerWhitelist: data?.influencerWhitelist
  };
};
