import { MarketplaceCampaignStatus } from '@/graphql';
import { Status, StatusProps } from '@/shared/atoms';
import { ColorVariant } from '@/shared/types';

import { useMarketplaceStatusOptions } from '../hooks';

export interface MarketplaceStatusProps {
  className?: string;
  status?: MarketplaceCampaignStatus;
}

export const MarketplaceStatus = ({ status, className }: MarketplaceStatusProps) => {
  const { marketplaceStatusOptions } = useMarketplaceStatusOptions();

  const label = marketplaceStatusOptions.find((o) => o.value === status)?.label || '';
  const statusProps: StatusProps = (() => {
    switch (status) {
      case MarketplaceCampaignStatus.REVIEWING:
        return { label, variant: ColorVariant.LIGHT_PURPLE };
      case MarketplaceCampaignStatus.UPCOMING:
        return { label, variant: ColorVariant.LIGHT_BLUE };
      case MarketplaceCampaignStatus.ONGOING:
        return { label, variant: ColorVariant.LIGHT_GREEN };
      case MarketplaceCampaignStatus.SUSPENDED:
      case MarketplaceCampaignStatus.OUT_OF_COUPON:
        return { label, variant: ColorVariant.LIGHT_RED };
      default:
        return { label, variant: ColorVariant.LIGHT_GREY };
    }
  })();

  return <Status className={className} {...statusProps} />;
};
