import { z } from 'zod';
import { LIMIT } from '@/shared/constants';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const InfluencerYtSponsoredPostsListSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(FilterValidations.keyword, { keyword: '' }),
  sort: FilterValidations.sort(YoutubeAnalyticsPostsSortOrder).default({}),
  lm: z.number().default(LIMIT),
  page: z.number().default(1)
});

export type InfluencerYtSponsoredPostsListSearchSchemaType = z.infer<typeof InfluencerYtSponsoredPostsListSearchSchema>;
