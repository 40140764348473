import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useAuthData, useUserPermissions, useUserRoles } from '@/auth';
import { AllUserDetail } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { useTranslateCountry } from '@/shared/hooks';
import { SpreadButton } from '@/shared/molecules';
import { formatIntNumber, getUserRoleString } from '@/shared/utils';

interface Props {
  data: readonly AllUserDetail[];
  openConfirmModal: (data: { id: number; name: string; email?: string } | null) => void;
}

export const useUsersTable = ({ data, openConfirmModal }: Props) => {
  const { t } = useTranslation();
  const { auth } = useAuthData();
  const { tc } = useTranslateCountry();
  const { hideDeleteUserBtn, hideEditUserBtn } = useUserPermissions();
  const { isAdminStaffTalentAgencyPartner, isPartner } = useUserRoles();

  // TA, PA, Admin and Staff can delete if custom permissions allow it
  const hasDeleteOption = isAdminStaffTalentAgencyPartner && !hideDeleteUserBtn;
  const columns: ColumnProps[] = [
    { title: t<string>('HeaderColumn.Name') },
    { title: t<string>('HeaderColumn.Email Address') },
    { title: t<string>('HeaderColumn.Country') },
    { title: t<string>('HeaderColumn.Role') },
    { title: t<string>('HeaderColumn.Advertiser'), styles: { textAlign: 'right' } }
  ];

  const rows: RowProps[] = data?.map(({ id, name, email, role, advertisersCount, country }) => {
    const cannotEditUsers = hideEditUserBtn && !isPartner && id !== auth.userId; // all Partners can edit (BE will control users list), also user can always edit himself

    /* eslint-disable react/jsx-key */
    return [
      <div css={styles.nameColumnWrapper}>
        <TableCell.Link
          to="/user/$id"
          params={{ id: id.toString() }}
          label={name}
          disabled={cannotEditUsers}
          css={id === auth.userId ? { fontWeight: 600 } : {}}
        />
        <SpreadButton
          collapseFrom={0}
          moreButtonProps={{ disabled: hideDeleteUserBtn && cannotEditUsers }}
          options={[
            {
              label: t('Edit'),
              to: '/user/$id',
              params: { id: id.toString() },
              icon: 'edit',
              hidden: cannotEditUsers,
              tooltip: { help: t('Button.Edit') }
            },
            {
              label: t('Delete'),
              icon: 'delete',
              hidden: !hasDeleteOption,
              onClick: () => openConfirmModal({ id, name, email }),
              tooltip: { help: t('Button.Delete') }
            }
          ]}
        />
      </div>,
      <TableCell.Text css={styles.textColumn} value={email} />,
      <TableCell.Text css={styles.textColumn} value={tc(country.name)} />,
      <TableCell.Text css={styles.textColumn} value={t(`Option.${getUserRoleString(role)}`)} />,
      <TableCell.Number css={styles.textColumn} value={formatIntNumber(advertisersCount)} />
    ];
  });

  return { rows, columns };
};

const styles = {
  nameColumnWrapper: css({
    width: '400px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  }),
  textColumn: css({ minWidth: '100px' })
};
