import { useTranslation } from 'react-i18next';
import { ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { Prompt, Table } from '@/shared/atoms';
import { useToggle } from '@/shared/hooks';
import { THEME } from '@/shared/constants';
import { BudgetBulkEditModal } from '@/shared/organisms';
import { ProposeInfluencersTableModes, ProposeInfluencersTableModesProps } from './ProposeInfluencersModes';
import { SelectProposedInfluencersActionBar } from './SelectProposedInfluencersActionBar';
import { ProposeInfluencersTableEmpty, ProposeInfluencersTableTitle } from './TableComponents';
import { useUserProposalContext } from './UserProposalContext';
import {
  useInfluencersStateHandler,
  useProposalBulkEdit,
  useProposedInfluencersTable,
  useSaveProposal,
  useSocialAccountsStateHandler
} from './hooks';
import { ProposeInfluencersTableMode } from './schemaTypes';

interface SelectedInfluencersTableProps {
  currency?: string;
  tableModeProps: ProposeInfluencersTableModesProps;
}

export const SelectedProposedInfluencersTable = ({ currency, tableModeProps }: SelectedInfluencersTableProps) => {
  const { t } = useTranslation();
  const { on: openBulkModal, toggle: toggleBulkModal } = useToggle();
  const { on: openConfirmModal, toggle: toggleConfirmModal, setOff: closeConfirmModal } = useToggle();
  const { allSocialAccountIds, allSocialAccounts, selectedInfluencers } = useUserProposalContext();

  const {
    allInfluencerIds,
    selectedInfluencerAccountIds,
    setSelectedInfluencerAccountIds,
    handleSelectSingleInfluencer,
    handleToggleSelectAllInfluencers
  } = useInfluencersStateHandler(selectedInfluencers);
  const {
    selectedSocialAccounts,
    selectedSocialAccountIds,
    setSelectedSocialAccountIds,
    handleSelectSingleAccount,
    handleToggleSelectAllAccounts
  } = useSocialAccountsStateHandler({ allSocialAccounts, allSocialAccountIds });
  const { bulkLoading, handleInfluencersBudgetSubmit, handleSocialAccountBudgetSubmit } = useProposalBulkEdit({
    selectedSocialAccounts,
    selectedInfluencerAccountIds
  });

  const { tableViewMode, jdMode, onTableViewModeChange, loading } = tableModeProps;
  const isViewMode = tableViewMode === ProposeInfluencersTableMode.VIEW;
  const isSocialMediaAccountsSelection = jdMode === ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN && !isViewMode;
  const selectedAccountsIds = isSocialMediaAccountsSelection ? selectedSocialAccountIds : selectedInfluencerAccountIds;
  const selectedAccountsIdsLength = selectedAccountsIds.length;
  const hasAllSelectedIds = isSocialMediaAccountsSelection
    ? selectedAccountsIdsLength === allSocialAccountIds.length
    : selectedAccountsIdsLength === allInfluencerIds.length;

  const handleSelectAllIds = () => {
    if (isSocialMediaAccountsSelection) {
      setSelectedSocialAccountIds(allSocialAccountIds);
    } else {
      setSelectedInfluencerAccountIds(allInfluencerIds);
    }
  };

  const handleRowSelect = (influencerId: number, socialAccountId?: number) => {
    if (isSocialMediaAccountsSelection) {
      handleSelectSingleAccount(socialAccountId as number);
    } else {
      handleSelectSingleInfluencer(influencerId);
    }
  };

  const { rows, columns, rowValues, hasRowChange, resetEditingRowsData } = useProposedInfluencersTable({
    tableViewMode,
    currency: currency || '',
    onRowSelect: handleRowSelect,
    jdMode: tableModeProps.jdMode,
    influencers: selectedInfluencers,
    selectButtonProps: { onClick: handleSelectAllIds },
    listSelectedIds: isSocialMediaAccountsSelection ? selectedSocialAccountIds : selectedInfluencerAccountIds
  });
  const { handleSaveProposal, updatingAccountsBudget, updatingInfluencersBudget } = useSaveProposal({
    hasRowChange,
    rowValues,
    jdMode,
    resetEditingRowsData
  });

  const handleToggleAllIds = () => {
    if (isSocialMediaAccountsSelection) {
      handleToggleSelectAllAccounts();
    } else {
      handleToggleSelectAllInfluencers();
    }
  };

  const handleTableViewModeChange = (mode: ProposeInfluencersTableMode) => {
    if (hasRowChange) {
      toggleConfirmModal();
    } else {
      if (mode === ProposeInfluencersTableMode.EDIT) {
        setSelectedSocialAccountIds([]);
      } else {
        setSelectedInfluencerAccountIds([]);
      }

      onTableViewModeChange(mode);
    }
  };

  const handleBulkEditSuccess = () => {
    onTableViewModeChange(ProposeInfluencersTableMode.VIEW);
    resetEditingRowsData();
  };

  return (
    <>
      <ProposeInfluencersTableTitle title={t('Suggested Influencers')} />

      <ProposeInfluencersTableModes
        {...tableModeProps}
        onTableViewModeChange={handleTableViewModeChange}
        saveButtonProps={{
          loading: updatingInfluencersBudget || updatingAccountsBudget,
          disabled: !hasRowChange,
          onClick: handleSaveProposal
        }}
      />

      <div css={{ position: 'relative' }}>
        {selectedAccountsIdsLength > 0 ? (
          <SelectProposedInfluencersActionBar
            isViewMode={isViewMode}
            onSelectAll={handleSelectAllIds}
            hasAllSelected={hasAllSelectedIds}
            onToggleSelect={handleToggleAllIds}
            onToggleBulkEditModal={toggleBulkModal}
            numberSelected={selectedAccountsIdsLength}
            selectedSocialAccounts={selectedSocialAccounts}
            selectedInfluencerAccountIds={selectedInfluencerAccountIds}
            isSocialMediaAccountsSelection={isSocialMediaAccountsSelection}
          />
        ) : null}

        <Table
          noTopBorderRadius
          data={{ rows, columns, rowHeight: '62px' }}
          empty={{
            hideTitle: true,
            description: <ProposeInfluencersTableEmpty />
          }}
          loading={loading}
          stickyHeader
          hasLoadingOverlay={loading}
        />
      </div>
      <BudgetBulkEditModal
        openModal={openBulkModal}
        toggleModal={toggleBulkModal}
        onSuccess={handleBulkEditSuccess}
        currency={currency || ''}
        jdMode={jdMode}
        loading={bulkLoading}
        onInfluencersBudgetSubmit={handleInfluencersBudgetSubmit}
        onSocialAccountBudgetSubmit={handleSocialAccountBudgetSubmit}
      />

      <Prompt
        when={hasRowChange}
        open={openConfirmModal}
        title={t('Dialog.Save your changes')}
        css={{ maxWidth: THEME.modal.size.lv2 }}
        cancelButtonProps={({ proceed }) => ({
          title: t('Button.Leave without saving'),
          onClick: () => {
            onTableViewModeChange(ProposeInfluencersTableMode.VIEW);
            resetEditingRowsData();
            closeConfirmModal();
            proceed?.();
          }
        })}
        submitButtonProps={({ reset }) => ({
          title: t('Button.Keep editing'),
          onClick: () => {
            closeConfirmModal();
            reset?.();
          }
        })}
      >
        <p css={{ fontSize: '14px', fontWeight: 'normal', lineHeight: '18px', margin: '16px 0' }}>
          {t('leaveWithoutSaveWarningText')}
        </p>
      </Prompt>
    </>
  );
};
