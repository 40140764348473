import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ApproveMarketplace.graphql';
import { Mutation, MutationapproveMarketplaceArgs } from '../../__generated__/globalTypes';

export const useApproveMarketplaceMutation = (
  options?: MutationHookOptions<Mutation, MutationapproveMarketplaceArgs>
) => {
  const [callApproveMarketplace, result] = useMutation<Mutation, MutationapproveMarketplaceArgs>(MUTATION, options);

  return { callApproveMarketplace, ...result };
};

export type ApproveMarketplaceMutationFunction = Mutation['approveMarketplace'];
