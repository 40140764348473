import { useTranslation } from 'react-i18next';
import { WarningReason, TikTokSpecialCampaignPostStatus } from '@/graphql';
import { Status, StatusProps } from '@/shared/atoms';
import { ColorVariant } from '@/shared/types';
import { getPostWarningReasonStatusProps } from '@/shared/utils';

export interface TikTokSpecialPostStatusProps {
  className?: string;
  warningReason?: WarningReason;
  status: TikTokSpecialCampaignPostStatus;
}

export const TikTokSpecialPostStatus = ({ status, warningReason, className }: TikTokSpecialPostStatusProps) => {
  const { t } = useTranslation();

  const hasReason = [TikTokSpecialCampaignPostStatus.WARNING].includes(status) && !!warningReason;
  const statusProps: StatusProps = (() => {
    if (hasReason) {
      const { label, variant } = getPostWarningReasonStatusProps(warningReason);

      return { label: t(label), variant };
    } else {
      switch (status) {
        case TikTokSpecialCampaignPostStatus.APPROVED:
          return { label: t('Option.Approved'), variant: ColorVariant.LIGHT_GREEN };
        case TikTokSpecialCampaignPostStatus.EFFECTIVE:
        case TikTokSpecialCampaignPostStatus.WARNING_SOLVED:
          return { label: t('Option.Effective'), variant: ColorVariant.LIGHT_GREEN };
        case TikTokSpecialCampaignPostStatus.WARNING:
          return { label: t('Option.Warning'), variant: ColorVariant.LIGHT_RED };
        case TikTokSpecialCampaignPostStatus.INEFFECTIVE:
          return { label: t('Option.Ineffective'), variant: ColorVariant.LIGHT_GREY };
        case TikTokSpecialCampaignPostStatus.JOINED:
          return { label: t('Option.Joined'), variant: ColorVariant.LIGHT_BLUE };
        default:
          return { label: '', variant: ColorVariant.DARK_GREY };
      }
    }
  })();

  return <Status {...statusProps} className={className} />;
};
