import { z } from 'zod';
import { Order } from '@/graphql';
import { INITIAL_PAGE_NUMBER, LIMIT } from '@/shared/constants';

// We can't export variable named enum/number... So we export an object instead
// We don't set any error for the filter
// We're using `.catch` to avoid failing parse from search param
// DON'T OVERRIDE the default values where validation used
const customValidations = {
  string: () => z.coerce.string().default('').catch(''),
  id: () => z.coerce.string().or(z.number()).or(z.literal(undefined)).default('').catch(''),
  arrayString: () => z.array(z.coerce.string()).default([]).catch([]),
  arrayNumber: () => z.array(z.coerce.number()).default([]).catch([]),
  number: () => z.literal('').or(z.coerce.number()).default('').catch(''),
  arrayEnum: <T extends z.EnumLike>(type: T) => z.array(z.nativeEnum(type)).default([]).catch([]),
  enum: <T extends z.EnumLike>(type: T) => z.nativeEnum(type).or(z.literal('')).default('').catch(''),
  boolean: () =>
    z
      .enum(['true', 'false', 'TRUE', 'FALSE'])
      .transform((value) => value.toLowerCase() === 'true')
      .or(z.literal(''))
      .default('')
      .catch(false)
};

export const FilterValidations = {
  ...customValidations,
  // General filter validation types
  keyword: z.object({ keyword: customValidations.string() }), // TODO: remove KeywordFilterFormValues type
  pagination: z.object({ p: customValidations.number(), lm: customValidations.number() }),
  period: z.object({ endDate: customValidations.string(), startDate: customValidations.string() }),
  sort: <T extends z.EnumLike>(field: T) =>
    z.object({
      field: z.nativeEnum(field).nullable().default(null).catch(null),
      order: z.nativeEnum(Order).nullable().default(null).catch(null)
    })
};

export const SearchFilterDefaults = {
  filter: <T extends z.ZodTypeAny>(schema: T, defaultValues: T['_output']) =>
    schema.default(defaultValues).catch(defaultValues),
  pageLimit: (l: number | undefined = LIMIT) => ({
    limit: z.number().default(l),
    page: z.number().default(INITIAL_PAGE_NUMBER)
  }),
  sort: <T extends z.EnumLike>(field: T) =>
    z.object({
      field: z.nativeEnum(field).nullable().default(null).catch(null),
      order: z.nativeEnum(Order).nullable().default(null).catch(null)
    })
};
