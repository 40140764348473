/* eslint-disable react/jsx-key */
import { css } from '@emotion/react';
import { linkOptions } from '@tanstack/react-router';
import {
  CampaignIdentifierType,
  CampaignPost,
  CampaignPostMetrics,
  CampaignPostSocialType,
  CampaignPostSortField
} from '@/graphql';
import { Anchor, ColumnProps, MediaPreview, SocialIcon, TableCell, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { InfluencerAvatar } from '@/shared/molecules';
import { formatIntNumber, formatNumber, formatPercent, formatPrice, getMkpReportLink } from '@/shared/utils';

export type PostDiscoveryStats = Omit<CampaignPost, 'id'>;
export type PostDiscoveryMetricsStats = Omit<CampaignPostMetrics, 'id'>;
export type PostDiscoveryStatsKey = keyof PostDiscoveryStats;
export interface PostDiscoveryMetric extends ColumnProps<CampaignPostSortField, PostDiscoveryStatsKey> {
  title: string;
}

export const getOrderSelectedColumn = (
  postDiscoveryMetrics: PostDiscoveryMetric[],
  newSelectedColumns: PostDiscoveryStatsKey[]
) =>
  postDiscoveryMetrics.reduce<PostDiscoveryStatsKey[]>(
    (results, metric) => (metric.key && newSelectedColumns.includes(metric.key) ? [...results, metric.key] : results),
    []
  );

export const defaultPostDiscoveryMetrics: Partial<PostDiscoveryStatsKey>[] = [
  'cost',
  'likes',
  'followers',
  'engagement',
  'engagementFollowersRate',
  'engagementViewsRate'
];

export const getStatCells = (rowStats?: PostDiscoveryStats | PostDiscoveryMetricsStats, currencyId?: string) => [
  <TableCell.Number unit={rowStats?.cost ? currencyId : ''} value={formatPrice(rowStats?.cost || null, currencyId)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.followers)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.impressions)} />,
  <TableCell.Number value={formatPercent(rowStats?.impressionsRate, false)} unit="%" />,
  <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromDiscovery)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromHashtag)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromHome)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromProfile)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromOther)} />,
  <TableCell.Number value={formatPercent(rowStats?.impressionsClickThroughRate, false)} unit="%" />,
  <TableCell.Number value={formatIntNumber(rowStats?.totalPlayTime)} />,
  <TableCell.Number value={formatNumber(rowStats?.averageViewTime, 2)} />,
  <TableCell.Number value={formatPercent(rowStats?.videoCompletionRate, false)} unit="%" />,
  <TableCell.Number value={formatIntNumber(rowStats?.views)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.averageViewDurationSeconds)} unit="s" />,
  <TableCell.Number value={formatPercent(rowStats?.averageViewDurationPercentage, false)} unit="%" />,
  <TableCell.Number value={formatIntNumber(rowStats?.reach)} />,
  <TableCell.Number value={formatPercent(rowStats?.reachRate, false)} unit="%" />,
  <TableCell.Number value={formatIntNumber(rowStats?.nonFollowerReach)} />,
  <TableCell.Number value={formatPercent(rowStats?.nonFollowerReachRate, false)} unit="%" />,
  <TableCell.Number value={formatIntNumber(rowStats?.engagement)} />,
  <TableCell.Number value={formatPercent(rowStats?.engagementFollowersRate, false)} unit="%" />,
  <TableCell.Number value={formatPercent(rowStats?.engagementViewsRate, false)} unit="%" />,
  <TableCell.Number value={formatIntNumber(rowStats?.likes)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.dislikes)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.comments)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.shares)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.saved)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.interaction)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.linkClicks)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.stickerTaps)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.clicks)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.conversions)} />,
  <TableCell.Number value={formatPrice(rowStats?.revenue, currencyId)} unit={currencyId} />,
  <TableCell.Number value={formatIntNumber(rowStats?.orders)} />,
  <TableCell.Number value={formatPrice(rowStats?.sales || null, currencyId)} unit={currencyId} />,
  <TableCell.Number value={formatPrice(rowStats?.salesCost || null, currencyId)} unit={currencyId} />,
  <TableCell.Number value={formatPrice(rowStats?.salesReferral || null, currencyId)} unit={currencyId} />,
  <TableCell.Number value={formatPercent(rowStats?.femalePercentage, false)} unit="%" />,
  <TableCell.Number value={formatPercent(rowStats?.malePercentage, false)} unit="%" />,
  <TableCell.Number value={formatPercent(rowStats?.age18to24Percentage, false)} unit="%" />,
  <TableCell.Number value={formatPercent(rowStats?.age25to34Percentage, false)} unit="%" />,
  <TableCell.Number value={formatPercent(rowStats?.age35upPercentage, false)} unit="%" />,
  <TableCell.Number value={formatIntNumber(rowStats?.back)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.exited)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.forward)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.nextStory)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.navigation)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.profileActivity)} />,
  <TableCell.Number value={formatIntNumber(rowStats?.audienceRetention)} />
];

interface PostDiscoveryFirstColumnProps {
  avatar: string;
  content: string;
  thumbnail: string;
  influencerId: number;
  influencerName: string;
  onPostClick?: () => void;
  socialPostType: CampaignPostSocialType;
  isBlockedProfileLink?: boolean;
  campaignName?: CampaignPost['campaignName'];
  campaignIdentifier: CampaignPost['campaignIdentifier'];
}
export const PostDiscoveryFirstColumn = ({
  avatar,
  content,
  thumbnail,
  onPostClick,
  influencerId,
  campaignName,
  influencerName,
  socialPostType,
  campaignIdentifier,
  isBlockedProfileLink
}: PostDiscoveryFirstColumnProps) => {
  const { generateProfileLink } = useGenerateInfluencerProfileLink({ disabled: isBlockedProfileLink });

  return (
    <div css={styles.firstColumnWrapper}>
      <div onClick={onPostClick} css={styles.postThumbnail}>
        <MediaPreview
          alt="post thumbnail"
          src={thumbnail}
          css={{ width: '80px', height: '80px', borderRadius: '3px' }}
          objectFit="cover"
        />
        <div css={styles.socialIconWrapper}>
          <SocialIcon size="24px" type={socialPostType} css={{ display: 'block' }} />
        </div>
      </div>

      <div css={styles.postDescription}>
        <Anchor css={{ display: 'flex', alignItems: 'center', width: '190px' }} {...generateProfileLink(influencerId)}>
          <InfluencerAvatar size="24px" src={avatar} css={{ borderRadius: '2px' }} asChild />
          <TextCutter
            lines={1}
            css={{ fontSize: '14px', fontWeight: 600, marginLeft: '8px' }}
            text={influencerName || '-'}
          />
        </Anchor>

        <TextCutter lines={2} text={content || '-'} css={styles.postContent} />

        <Anchor
          disabled={isBlockedProfileLink}
          {...(campaignIdentifier.campaignType === CampaignIdentifierType.MARKETPLACE
            ? getMkpReportLink({
                id: campaignIdentifier.campaignId.toString(),
                isPostTracking: campaignIdentifier.hasPostTracking,
                mkpCampaignType: campaignIdentifier.campaignDetailType
              })
            : campaignIdentifier.campaignType === CampaignIdentifierType.ENGAGEMENT
              ? linkOptions({ to: '/engagement/$id/report', params: { id: campaignIdentifier.campaignId.toString() } })
              : undefined)}
        >
          <TextCutter lines={1} onClick={() => null} text={campaignName || '-'} css={styles.campaignName} />
        </Anchor>
      </div>
    </div>
  );
};

const styles = {
  firstColumnWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: '24px'
  }),
  columnWrapper: css({
    display: 'flex',
    alignItems: 'center',
    width: '196px',
    marginLeft: 'auto'
  }),
  socialIconWrapper: css({
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '4px',
    borderRadius: '3px 0 5px 0',
    backgroundColor: THEME.background.colors.white
  }),
  postContent: css({ fontSize: '12px', lineHeight: '120%', wordBreak: 'break-word' }),
  campaignName: css({
    fontSize: '12px',
    cursor: 'pointer',
    wordBreak: 'break-all',
    color: THEME.text.colors.blue,
    lineHeight: THEME.input.text.lineHeight
  }),
  postThumbnail: css({
    position: 'relative',
    cursor: 'pointer',
    ':hover': {
      opacity: 0.8
    }
  }),
  postDescription: css({ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '80px' })
};
