import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@/shared/atoms';
import { DEFAULT_DATE_FNS_FORMAT } from '@/shared/constants';
import { getDefaultDate } from '../DayPicker/helpers';
import { MonthPickerDropdown, MonthPickerDropdownProps, MonthPickerInput, MonthPickerInputProps } from './shared';

export interface MonthPickerSingleProps
  extends Omit<MonthPickerInputProps, 'value' | 'onClick' | 'onClear'>,
    Pick<MonthPickerDropdownProps, 'disabledMonths'> {
  value?: string;
  saveOnBlur?: boolean;
  onChange: (date: string) => void;
}
export const MonthPickerSingle = ({
  value,
  onChange,
  disabled,
  hasError,
  className,
  placeholder,
  disabledMonths,
  clearable = true,
  saveOnBlur = true
}: MonthPickerSingleProps) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(getDefaultDate(value));

  let handleClosePopover: () => void;

  useEffect(() => {
    setDate(getDefaultDate(value));
  }, [value]);

  const handleSubmit = () => {
    onChange(date ? format(date, DEFAULT_DATE_FNS_FORMAT) : '');
  };

  const resetDate = () => {
    setDate(getDefaultDate(value));
  };

  const handleClickAway = (opening?: boolean) => {
    if (opening) {
      if (saveOnBlur) {
        handleSubmit();
      } else if (date) {
        resetDate();
      }

      handleClosePopover();
    }
  };

  return (
    <div className={className}>
      <Popover
        id="month-selector"
        disabled={disabled}
        position="bottom-start"
        onClose={handleClickAway}
        styles={{ dropdown: { padding: '16px' } }}
        target={({ open, setOpen }) => {
          handleClosePopover = () => setOpen(false);

          return (
            <div>
              <MonthPickerInput
                value={date}
                hasError={hasError}
                disabled={disabled}
                clearable={clearable}
                onClick={() => setOpen(true)}
                placeholder={placeholder || t<string>('Select Month')}
                onClear={() => {
                  onChange('');
                  setDate(undefined);

                  if (saveOnBlur && open) {
                    setOpen(false);
                  }
                }}
              />
            </div>
          );
        }}
        withBorder
      >
        {({ setOpen }) => (
          <MonthPickerDropdown
            value={date}
            disabledMonths={disabledMonths}
            onCancel={() => {
              setOpen(false);
              resetDate();
            }}
            onChange={({ month, year }) => {
              setDate(new Date(year, month, 1));
            }}
            onSubmit={() => {
              setOpen(false);
              handleSubmit();
            }}
          />
        )}
      </Popover>
    </div>
  );
};
