import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllYoutubeInfluencersV3.graphql';
import { Query, QueryallYoutubeInfluencersV3Args } from '../../__generated__/globalTypes';

export const useAllYoutubeInfluencersV3Query = (
  options?: QueryHookOptions<Query, QueryallYoutubeInfluencersV3Args>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallYoutubeInfluencersV3Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllYoutubeInfluencersV3LazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallYoutubeInfluencersV3Args>
): QueryResult<Query, QueryallYoutubeInfluencersV3Args> & {
  getAllYoutubeInfluencersV3: LazyQueryExecFunction<Query, QueryallYoutubeInfluencersV3Args>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllYoutubeInfluencersV3, result] = useLazyQuery<Query, QueryallYoutubeInfluencersV3Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllYoutubeInfluencersV3, ...result };
};

export const useAllYoutubeInfluencersV3PromiseQuery = () => {
  const client = useApolloClient();

  const getAllYoutubeInfluencersV3 = useCallback(
    (options?: Omit<QueryOptions<QueryallYoutubeInfluencersV3Args, Query>, 'query'>) =>
      client.query<Query, QueryallYoutubeInfluencersV3Args>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllYoutubeInfluencersV3 };
};
