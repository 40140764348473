import { InfluencersProfileFormat, ProposalDownloadFormat } from '@/graphql';
import { ButtonDropdown, DownloadSubmenuIcon } from '@/shared/atoms';
import { useDownloadFile, useQueryHelper, useToggle } from '@/shared/hooks';
import { DownloadFileType, DropdownOption } from '@/shared/types';
import { getRestApiUrl } from '@/shared/utils';
import { useProposeInfluencersApi } from '../hooks';

interface ProposeInfluencersDownloadButtonProps {
  disabled?: boolean;
  className?: string;
  selectedInfluencerIds?: number[];
}

export const ProposeInfluencersDownloadButton = ({
  disabled,
  className,
  selectedInfluencerIds = []
}: ProposeInfluencersDownloadButtonProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { on: downloading, setOn, setOff } = useToggle();
  const { callRequestUserProposalExcel, callRequestUserProposalPptx } = useProposeInfluencersApi();
  const { handleDownloadFile, downloading: csvDownloading } = useDownloadFile();

  const downloadOptions: DropdownOption[] = [
    {
      value: 'Profile Sheet',
      menuType: 'multi-level',
      label: t<string>('Profile Sheet'),
      submenu: [
        {
          label: t<string>('PowerPoint'),
          value: DownloadFileType.PPTX,
          prefixIcon: <DownloadSubmenuIcon icon="powerPoint" />
        },
        {
          label: t<string>('Google Slide'),
          value: DownloadFileType.GOOGLE_SLIDE,
          prefixIcon: <DownloadSubmenuIcon icon="googleSlide" />
        }
      ]
    },
    {
      value: 'Proposal List',
      menuType: 'multi-level',
      label: t('Proposal List'),
      submenu: [
        {
          label: t<string>('CSV'),
          value: DownloadFileType.CSV,
          prefixIcon: <DownloadSubmenuIcon icon="csv" />
        },
        {
          label: t<string>('Excel'),
          value: DownloadFileType.EXCEL,
          prefixIcon: <DownloadSubmenuIcon icon="excel" />
        },
        {
          label: t<string>('Spreadsheet'),
          value: DownloadFileType.SPREADSHEET,
          prefixIcon: <DownloadSubmenuIcon icon="spreadsheet" />
        }
      ]
    }
  ];

  const handleDownloadOptionSelected = async (option: DropdownOption) => {
    setOn();

    try {
      switch (option.value) {
        case DownloadFileType.CSV:
          await handleDownloadFile({
            url: getRestApiUrl('user/proposal/csv/'),
            filenamePrefix: 'propose_influencers',
            params: { ids: selectedInfluencerIds }
          });
          break;
        case DownloadFileType.PPTX:
          await callRequestUserProposalPptx({
            variables: {
              format: InfluencersProfileFormat.PPTX,
              influencerIds: selectedInfluencerIds
            }
          });
          break;
        case DownloadFileType.SPREADSHEET:
          await callRequestUserProposalExcel({
            variables: { influencerIds: selectedInfluencerIds, format: ProposalDownloadFormat.SPREADSHEET }
          });
          break;
        case DownloadFileType.EXCEL:
          await callRequestUserProposalExcel({
            variables: { influencerIds: selectedInfluencerIds, format: ProposalDownloadFormat.EXCEL }
          });
          break;
        case DownloadFileType.GOOGLE_SLIDE:
          await callRequestUserProposalPptx({
            variables: {
              format: InfluencersProfileFormat.GOOGLE_SLIDE,
              influencerIds: selectedInfluencerIds
            }
          });
          break;

        default:
          break;
      }
      if (option.value !== DownloadFileType.CSV) {
        enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t('failedToDownload'), { variant: 'error' });
    } finally {
      setOff();
    }
  };

  return (
    <ButtonDropdown
      variant="white"
      placement="bottom-end"
      disabled={disabled}
      loading={downloading || csvDownloading}
      className={className}
      options={downloadOptions}
      title={t('Button.Download')}
      onSelectOption={handleDownloadOptionSelected}
      prefixIcon={{ icon: 'download', size: '14px' }}
      css={{ minWidth: '133px', marginLeft: '8px' }}
    />
  );
};
