import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';

export interface SectionTitleProps {
  size?: number;
  index?: number;
  className?: string;
  children?: ReactNode;
  label?: string | null;
}

export const SectionTitle = ({ index, label, className, size = 24, children }: SectionTitleProps) => (
  <div css={styles.wrapper} className={className}>
    {index ? (
      <div css={styles.sectionNumber(size)}>
        <span css={styles.index}>{index}</span>
      </div>
    ) : null}
    <span css={{ fontWeight: 600 }}>{label}</span>
    {children}
  </div>
);

const styles = {
  wrapper: css({
    display: 'flex',
    fontSize: '18px',
    alignItems: 'center',
    marginBottom: '12px'
  }),
  sectionNumber: (size: number) =>
    css({
      '--size': `${size}px`,

      display: 'flex',
      marginRight: '8px',
      textAlign: 'center',
      borderRadius: '50%',
      alignItems: 'center',
      width: 'var(--size)',
      height: 'var(--size)',
      justifyContent: 'center',
      backgroundColor: THEME.background.colors.black
    }),
  index: css({
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: 'var(--size)',
    color: THEME.text.colors.white
  })
};
