import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreatePackageWithInfluencerAccounts.graphql';
import { Mutation, MutationcreatePackageWithInfluencerAccountsArgs } from '../../__generated__/globalTypes';

export const useCreatePackageWithInfluencerAccountsMutation = (
  options?: MutationHookOptions<Mutation, MutationcreatePackageWithInfluencerAccountsArgs>
) => {
  const [callCreatePackageWithInfluencerAccounts, result] = useMutation<
    Mutation,
    MutationcreatePackageWithInfluencerAccountsArgs
  >(MUTATION, options);

  return { callCreatePackageWithInfluencerAccounts, ...result };
};

export type CreatePackageWithInfluencerAccountsMutationFunction = Mutation['createPackageWithInfluencerAccounts'];
