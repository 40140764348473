import { UserRoles } from '@/graphql';

import { refineBasedOnRoleSchema } from '../schema';
import { MarketplaceFormKeys } from '../types';

export const getEcommerceCampaignFormSchema = (role: UserRoles) =>
  refineBasedOnRoleSchema(role, {
    [MarketplaceFormKeys.title]: true,
    [MarketplaceFormKeys.period]: true,
    [MarketplaceFormKeys.thumbnails]: true,
    [MarketplaceFormKeys.countryId]: true,
    [MarketplaceFormKeys.marginRate]: true,
    [MarketplaceFormKeys.requirement]: true,
    [MarketplaceFormKeys.socialMedia]: true,
    [MarketplaceFormKeys.salesPicIds]: true,
    [MarketplaceFormKeys.advertiser]: true,
    [MarketplaceFormKeys.costPerOrder]: true,
    [MarketplaceFormKeys.preLaunchDate]: true,
    [MarketplaceFormKeys.campaignCategoryId]: true,
    [MarketplaceFormKeys.serviceInformation]: true,
    [MarketplaceFormKeys.costPerSaleReferral]: true,
    [MarketplaceFormKeys.influencerManagementPicIds]: true
  });
