import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { SelectorField, TextField } from '@/shared/molecules';
import { ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { getOptions } from '@/shared/utils';
import { INDUSTRY_OPTIONS, LOCATION_LIST_OPTIONS, TIMEZONE_OPTIONS } from '../TikTokDataOptions';
import { CreateAdAccountFormKeys, CreateAdAccountFormValues, CreateAdAccountProps } from './types';

interface TemplateProps {
  onClose: () => void;
  loading: boolean;
  bcList: CreateAdAccountProps['bcList'];
}
export const Template = ({ onClose, loading, bcList }: TemplateProps) => {
  const { t } = useTranslation();
  const {
    formState: { isSubmitting }
  } = useFormContext<CreateAdAccountFormValues>();

  const translatedIndustriesOptions = INDUSTRY_OPTIONS.map((item) => ({
    ...item,
    label: t(item.label),
    submenu: item.submenu?.map((subItem) => ({ ...subItem, label: t(subItem.label) }))
  }));
  const translatedLocationOptions = LOCATION_LIST_OPTIONS.map((item) => ({
    ...item,
    label: t(`Country.${item.label}`)
  }));

  return (
    <>
      <ModalContent css={{ overflow: 'visible' }}>
        <ModalTitle>{t('Dialog.Create TikTok Ad Account')}</ModalTitle>
        <SelectorField<CreateAdAccountFormKeys>
          name="businessCenterId"
          title={t('Selector.Business Center')}
          options={getOptions(bcList)}
          required
          placeholder={t<string>('Select Business Center')}
        />
        <TextField<CreateAdAccountFormKeys>
          name="adAccountName"
          title={t('Ad Account Name')}
          placeholder="Business Center name"
          required
        />
        <TextField<CreateAdAccountFormKeys>
          name="adAccountCompanyName"
          title={t('Company Name')}
          placeholder="AnyMind"
          required
        />
        <TextField<CreateAdAccountFormKeys>
          name="promotionLink"
          title={t('Promoted Item URL')}
          placeholder="https://anymindgroup.com"
          required
        />
        <SelectorField<CreateAdAccountFormKeys>
          name="timezone"
          title={t('Selector.Timezone')}
          options={TIMEZONE_OPTIONS}
        />
        <SelectorField<CreateAdAccountFormKeys>
          name="industryId"
          title={t('Selector.Industry of your business')}
          options={translatedIndustriesOptions}
          singleSelectorProps={{
            optionType: 'with-submenu',
            menuPlacement: 'auto',
            menuStyles: {
              maxHeight: 'auto',
              overflow: 'visible',
              marginBottom: '4px', // when menu is open to top
              '& > div': { overflow: 'visible', maxHeight: 'unset' }
            }
          }}
        />
        <SelectorField<CreateAdAccountFormKeys>
          name="locationCode"
          title={t('Selector.Location')}
          options={translatedLocationOptions}
          disabled
        />
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{
          type: 'submit',
          loading: isSubmitting || loading,
          title: t('Button.Create'),
          disabled: isSubmitting || loading
        }}
      />
    </>
  );
};
