import { useEffect } from 'react';
import { Outlet, useParams, useMatchRoute } from '@tanstack/react-router';
import { Header } from '@/shared/molecules';
import { usePackageProposalQuery, usePackageProposalSummaryQuery } from '@/graphql';
import { useCustomHeader, useParamsSearch, useUpdateDocumentTitle } from '@/shared/hooks';
import {
  PackageProposalProvider,
  stickyInfluencersSearchHeader,
  ProposeInfluencersTableMode,
  PackageProposalSearchSchemaType
} from '@/shared/organisms';
import { getOffset } from '@/shared/utils';
import { PackageContentTabs } from './PackageContentTabs';

export const PackageContentRoot = () => {
  useCustomHeader();
  const { setDocumentAndAppTitles } = useUpdateDocumentTitle({ skipUpdate: { appHeader: true, title: true } });
  const params = useParams({ from: '/_private-routes/package/$id/content' });
  const { filter, setFilter, page, limit } = useParamsSearch<PackageProposalSearchSchemaType>();
  const matchedPath = useMatchRoute();
  const matched = !!matchedPath({ to: '/package/$id/content/list' });

  const packageId = params.id;

  const { data, loading } = usePackageProposalQuery({
    skip: !packageId,
    fetchPolicy: 'network-only',
    variables: {
      packageId: Number(packageId),
      keyword: filter.keyword,
      offset: getOffset(page, limit),
      limit
    }
  });
  const { data: summaryData } = usePackageProposalSummaryQuery({
    skip: !packageId,
    variables: {
      packageId: Number(packageId),
      keyword: filter.keyword
    }
  });
  const proposalSummary = summaryData?.packageProposalSummary;

  useEffect(() => {
    if (proposalSummary?.name) {
      setDocumentAndAppTitles({
        title: proposalSummary.name,
        appHeader: proposalSummary.name,
        href: '/package',
        alwaysBackToParent: true
      });
    }
  }, [setDocumentAndAppTitles, proposalSummary?.name]);

  useEffect(() => {
    if (matched && proposalSummary?.jdMode && proposalSummary?.jdMode !== filter.jdMode) {
      setFilter({
        ...filter,
        jdMode: proposalSummary?.jdMode,
        tableViewMode: filter.tableViewMode || ProposeInfluencersTableMode.VIEW
      });
    }
  }, [proposalSummary?.jdMode, filter, setFilter, matched]);

  return (
    <>
      <div css={stickyInfluencersSearchHeader}>
        <Header />
        <PackageContentTabs />
      </div>
      <PackageProposalProvider
        value={{ packageProposal: data?.packageProposal, proposalSummary, loading, filter, setFilter }}
      >
        <Outlet />
      </PackageProposalProvider>
    </>
  );
};
