import {
  InfluencerAnalyticsYoutubeProfileVideos,
  InfluencerAnalyticsYoutubeProfileShorts,
  InfluencerAnalyticsYoutubeProfileOverview,
  InfluencerAnalyticsYoutubeProfileAudience,
  InfluencerAnalyticsYoutubeProfileTagsRanking,
  InfluencerAnalyticsYoutubeProfilePostsOverview,
  InfluencerAnalyticsYoutubeProfileSponsoredPosts,
  InfluencerAnalyticsYoutubeProfileMentionedAccounts
} from '../../InfluencerAnalyticsProfiles';
import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsYoutubeProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerAnalyticsYoutubeProfile = ({ socialAccount }: InfluencerAnalyticsYoutubeProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerAnalyticsYoutubeProfileOverview socialAccount={socialAccount} />

      {!influencerProfile.hideSensitiveMetrics ? (
        <InfluencerAnalyticsYoutubeProfileAudience socialAccount={socialAccount} />
      ) : null}

      <InfluencerAnalyticsYoutubeProfilePostsOverview
        socialAccount={socialAccount}
        influencerId={influencerProfile.id}
      />
      <InfluencerAnalyticsYoutubeProfileSponsoredPosts
        socialAccount={socialAccount}
        influencerProfile={influencerProfile}
      />
      <InfluencerAnalyticsYoutubeProfileVideos socialAccount={socialAccount} />
      <InfluencerAnalyticsYoutubeProfileShorts influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerAnalyticsYoutubeProfileTagsRanking socialAccount={socialAccount} />
      <InfluencerAnalyticsYoutubeProfileMentionedAccounts
        influencerId={influencerProfile.id}
        socialAccount={socialAccount}
      />
    </>
  );
};
