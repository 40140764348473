import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteAdvertiser.graphql';
import { Mutation, MutationdeleteAdvertiserArgs } from '../../__generated__/globalTypes';

export const useDeleteAdvertiserMutation = (options?: MutationHookOptions<Mutation, MutationdeleteAdvertiserArgs>) => {
  const [callDeleteAdvertiser, result] = useMutation<Mutation, MutationdeleteAdvertiserArgs>(MUTATION, options);

  return { callDeleteAdvertiser, ...result };
};

export type DeleteAdvertiserMutationFunction = Mutation['deleteAdvertiser'];
