import { css } from '@emotion/react';
import axios from 'axios';
import { ReactNode, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useGenerateUploadReportSignedUrlPromiseQuery } from '@/graphql';
import { Anchor, Icon, Modal, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { getGoogleStorageUrl } from '@/shared/utils';
import { MIME_ACCEPT } from '@/shared/constants';
import { FileDrop } from '../../FileDrop';

export interface UploadCsvPostReportModalProps {
  isOpen: boolean;
  title?: string;
  loading?: boolean;
  onClose: () => void;
  description?: ReactNode;
  onUploadFile: (fileUrl: string, fileName: string) => void;
}

export const UploadCSVCouponModal = ({
  isOpen,
  onClose,
  loading,
  description,
  onUploadFile,
  title = 'Upload CSV file'
}: UploadCsvPostReportModalProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const childRef = useRef<{ requestUpload: () => Promise<void>; newFile: File }>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const { getGenerateUploadReportSignedUrl } = useGenerateUploadReportSignedUrlPromiseQuery();

  const csvTemplateURL = getGoogleStorageUrl('2022-08-04/Manual-upload-template-coupon.csv').toString();

  const handleStoreUploadedFile = async (acceptedFile: File) => {
    try {
      // Generate file url for storing
      const { data } = await getGenerateUploadReportSignedUrl({
        variables: { fileNames: [acceptedFile.name] }
      });
      const signedUrlData = data.generateUploadReportSignedUrl.fileUploadUrls[0];

      if (!signedUrlData) {
        console.error('signedUrlData undefined');
        enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });

        return;
      }

      // Store file
      await axios(signedUrlData.signedUrl, { method: 'PUT', data: acceptedFile });
      const urlObjFromSignedUrl = new URL(signedUrlData.signedUrl);
      // full path url without search
      const uploadFileUrl = urlObjFromSignedUrl.origin + urlObjFromSignedUrl.pathname;

      onUploadFile(uploadFileUrl, signedUrlData.fileName);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} css={{ maxWidth: '480px' }} hasCloseIcon>
      <ModalContent>
        <div css={{ '> div': { minWidth: 'unset' } }}>
          <FileDrop
            onDropCallback={setUploadedFiles}
            ref={childRef}
            description={
              <div>
                {description}

                <div>
                  <Trans
                    i18nKey="Please use Manual upload template for referral codes"
                    components={{ a: <Anchor variant="blue" css={styles.link} href={csvTemplateURL} /> }}
                  />
                </div>

                <div
                  css={{
                    display: 'flex',
                    fontSize: '14px',
                    marginTop: '26px',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <p css={{ fontWeight: 600 }}>{t('CSV upload')}</p>
                  <Anchor
                    variant="blue"
                    href={csvTemplateURL}
                    css={[styles.link, { display: 'flex', alignItems: 'center' }]}
                  >
                    <Icon icon="download" size="14px" color="#6E7C89" css={{ marginRight: '6px' }} />
                    <span>{t('CSV template')}</span>
                  </Anchor>
                </div>
              </div>
            }
            notes={[
              t('General.DragAndDropMaxSize', { maxSize: '10MB' }),
              t('General.DragAndDropMIME', { MIME: 'CSV' })
            ]}
            multiple={false}
            dropAreaButtonLabel={t('Upload File')}
            dropAreaDescription={t('drop the file and upload')}
            title={title}
            accept={{ ...MIME_ACCEPT.CSV }}
          />
        </div>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{
          loading,
          title: t('Button.Upload'),
          disabled: loading || !uploadedFiles.length,
          onClick: async () => {
            if (childRef.current?.newFile) {
              await handleStoreUploadedFile(childRef.current.newFile);
            }
          }
        }}
      />
    </Modal>
  );
};
const styles = {
  link: css({ display: 'inline', textDecoration: 'underline' })
};
