import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SwitchRoleToTalentAgency.graphql';
import { Mutation, MutationswitchRoleToTalentAgencyArgs } from '../../__generated__/globalTypes';

export const useSwitchRoleToTalentAgencyMutation = (
  options?: MutationHookOptions<Mutation, MutationswitchRoleToTalentAgencyArgs>
) => {
  const [callSwitchRoleToTalentAgency, result] = useMutation<Mutation, MutationswitchRoleToTalentAgencyArgs>(
    MUTATION,
    options
  );

  return { callSwitchRoleToTalentAgency, ...result };
};

export type SwitchRoleToTalentAgencyMutationFunction = Mutation['switchRoleToTalentAgency'];
