import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteEngagement.graphql';
import { Mutation, MutationdeleteEngagementArgs } from '../../__generated__/globalTypes';

export const useDeleteEngagementMutation = (options?: MutationHookOptions<Mutation, MutationdeleteEngagementArgs>) => {
  const [callDeleteEngagement, result] = useMutation<Mutation, MutationdeleteEngagementArgs>(MUTATION, options);

  return { callDeleteEngagement, ...result };
};

export type DeleteEngagementMutationFunction = Mutation['deleteEngagement'];
