import { getValuableVariables } from '@/shared/utils';
import { ChartsContainer, NoDataTitle } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { useYoutubeAnalyticsPostsStatisticsQuery } from '@/graphql';
import { PostingHabits, AverageEngagement } from '@/shared/molecules';
import { eyeBlueImage, likePinkImage, chatGreenImage, videoPinkImage, dislikeBlueImage } from '@/shared/assets';
import { AnalyticsPostsOverview } from '../../shared';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { useAnalyticsProfileSearch } from '../../hooks';

interface AnalyticsYoutubeDashboardPostsOverviewProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsYoutubeDashboardPostsOverview = ({
  analyticsAccount
}: AnalyticsYoutubeDashboardPostsOverviewProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useYoutubeAnalyticsPostsStatisticsQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      youtubeAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsPostsOverview loading={loading} data={data?.youtubeAnalyticsPostsStatistics}>
      {({ data: { postHabit, averageEngagement } }) => (
        <ChartsContainer css={{ height: '324px' }}>
          <AverageEngagement
            itemsPerRow={3}
            header={
              <NoDataTitle
                css={{ padding: '10px' }}
                title={t('Average Engagement')}
                help={t<string>('Tooltip.averageEngagement')}
              />
            }
            data={[
              {
                icon: videoPinkImage,
                label: t('Avg posts/week'),
                value: averageEngagement.averagePostsPerWeek?.value,
                growth: averageEngagement.averagePostsPerWeek?.growth
              },
              {
                icon: likePinkImage,
                label: t('Avg Likes/selected period'),
                value: averageEngagement.averageLikes.value,
                growth: averageEngagement.averageLikes.growth
              },
              {
                icon: dislikeBlueImage,
                label: t('Avg Dislikes/selected period'),
                value: averageEngagement.averageDislikes.value,
                growth: averageEngagement.averageDislikes.growth
              },
              {
                icon: eyeBlueImage,
                label: t('Avg Views/selected period'),
                value: averageEngagement.averageViews.value,
                growth: averageEngagement.averageViews.growth
              },
              {
                icon: chatGreenImage,
                label: t('Avg Comments/selected period'),
                value: averageEngagement.averageComments.value,
                growth: averageEngagement.averageComments.growth
              }
            ]}
          />

          <PostingHabits data={postHabit} />
        </ChartsContainer>
      )}
    </AnalyticsPostsOverview>
  );
};
