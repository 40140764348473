import { useTranslation } from 'react-i18next';
import { Order, SocialAccountInfluencerListSortFieldV2 } from '@/graphql';
import { SortOption } from '@/shared/types';
import { InfluencerSortActionBarTemplate, InfluencersSortTemplate } from '../../shared';
import { useFacebookPageInfluencersContext } from '../FacebookPageInfluencersContext';

export const FacebookPageInfluencersSort = () => {
  const { t } = useTranslation();
  const { sort, limit, setSort, setLimit, isEngagementPage, sortSelectButtonProps } =
    useFacebookPageInfluencersContext();

  const sortOptions: SortOption<SocialAccountInfluencerListSortFieldV2>[] = [
    {
      order: Order.ASC,
      label: t('Follower (ASC)'),
      field: SocialAccountInfluencerListSortFieldV2.SOCIAL_ACCOUNT_FOLLOWERS
    },
    {
      order: Order.DESC,
      label: t('Follower (DESC)'),
      field: SocialAccountInfluencerListSortFieldV2.SOCIAL_ACCOUNT_FOLLOWERS
    },
    {
      order: Order.ASC,
      label: t('ER by followers (ASC)'),
      field: SocialAccountInfluencerListSortFieldV2.SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE
    },
    {
      order: Order.DESC,
      label: t('ER by followers (DESC)'),
      field: SocialAccountInfluencerListSortFieldV2.SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE
    },
    { order: Order.ASC, label: t('Avg Like (ASC)'), field: SocialAccountInfluencerListSortFieldV2.AVG_LIKE },
    { order: Order.DESC, label: t('Avg Like (DESC)'), field: SocialAccountInfluencerListSortFieldV2.AVG_LIKE }
  ].map((option) => ({ ...option, value: `${option.field}-${option.order}` }));

  return (
    <div css={{ position: 'relative', borderRadius: 'inherit' }}>
      <InfluencersSortTemplate
        setSort={setSort}
        setLimit={setLimit}
        sortOptions={sortOptions}
        selectButtonProps={sortSelectButtonProps}
        initialValues={{ sort, limit: String(limit) }}
      />

      {!isEngagementPage && sortSelectButtonProps?.checked ? (
        <InfluencerSortActionBarTemplate selectButtonProps={sortSelectButtonProps} />
      ) : null}
    </div>
  );
};
