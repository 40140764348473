import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Anchor, Icon } from '@/shared/atoms';
import { CURRENCY_SYMBOLS, THEME, TIKTOK_HELP_LINK } from '@/shared/constants';
import { Maybe, PricingTableItem, TiktokAdvertiserSummaryData } from '@/graphql';
import { useGetPaymentPlanData } from '../hooks';
import { UpdatePlanModalWithButton } from './UpdatePlanModalWithButton';
import { getFirstDayOfNthMonth, getPlanName, getPriceTypeLabel } from './utils';

export interface TikTokBillingPlansProps {
  plans: readonly PricingTableItem[];
  summaryData?: Maybe<TiktokAdvertiserSummaryData>;
}

export const TikTokBillingPlans = ({ plans, summaryData }: TikTokBillingPlansProps) => {
  const { t, i18n } = useTranslation();
  const { getPaymentPlan } = useGetPaymentPlanData(plans);

  const selectedPlan = plans.find((p) => p.isCurrentPlan);

  return (
    <div css={styles.wrapper}>
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
        <h3 css={{ fontSize: '14px', fontWeight: 600 }}>{t(getPlanName(selectedPlan?.name))}</h3>
        <Anchor
          href={TIKTOK_HELP_LINK.learnMoreAboutPlans}
          target="_blank"
          css={{ color: THEME.text.colors.blue, display: 'flex', alignItems: 'center', lineHeight: '20px', gap: '4px' }}
        >
          {t('Learn more about plans')}
          <Icon icon="external-link" size="12px" color={THEME.text.colors.blue} css={{}} />
        </Anchor>
      </div>

      {selectedPlan ? (
        <>
          <div>
            <span css={{ fontSize: '32px', fontWeight: 600 }}>{selectedPlan.price}</span>
            <span css={{ fontSize: '14px', color: THEME.text.colors.gray.lv3 }}>{selectedPlan.currency}</span>
            <span css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3 }}>
              / {getPriceTypeLabel(selectedPlan.priceType)}
            </span>
          </div>
          <div css={{ marginTop: '16px' }}>
            <span css={{ fontSize: '14px', color: THEME.text.colors.gray.lv3 }}>
              {t('Next charge is on', {
                date: getFirstDayOfNthMonth(i18n.language)
              })}
            </span>
            {/* TODO: skipped for the 1st release */}
            {/* <span>{t('Cancel plan')}</span> */}
          </div>
        </>
      ) : (
        '-'
      )}

      <hr css={styles.horizontalLine} />
      <div css={{ display: 'flex', marginTop: '16px' }}>
        {plans.map((plan, i) => {
          const isUpcomingPlan = plan.name === summaryData?.upcomingSubscriptionPlan;

          return (
            <>
              <div key={plan.id} css={{ flex: 1 }}>
                <div css={{ fontSize: '14px', fontWeight: 600, textTransform: 'capitalize' }}>
                  {t(`Plan.${plan.name}`)}
                </div>
                <div css={{ marginTop: '12px' }}>
                  <span css={{ fontSize: '24px', fontWeight: 600 }}>
                    {CURRENCY_SYMBOLS[plan?.currency]}
                    {plan?.price}
                  </span>
                  <span css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3 }}>
                    / {getPriceTypeLabel(plan?.priceType)}
                  </span>
                </div>
                <UpdatePlanModalWithButton
                  plan={plan}
                  title={t('Button.Upgrade')}
                  isUpcomingPlan={isUpcomingPlan}
                  selectedPlan={selectedPlan}
                />
                <div css={{ marginTop: '16px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  {getPaymentPlan({ name: plan.name }).features.map(({ label }, i) => (
                    <div key={i} css={{ marginTop: '8px', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                      <Icon
                        icon="check"
                        color={THEME.attention.colors.blue}
                        size={10}
                        css={{ backgroundColor: '#c3def8', padding: '3px', borderRadius: '50%' }}
                      />
                      <span css={{ marginLeft: '4px', textTransform: 'capitalize' }}>{label}</span>
                    </div>
                  ))}
                </div>
              </div>
              {i !== plans.length - 1 && (
                <div key={i} css={{ width: '1px', backgroundColor: THEME.border.colors.gray.lv1, margin: '0 8px' }} />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    boxShadow: THEME.shadows.boxShadow,
    backgroundColor: THEME.background.colors.white,
    padding: '24px',
    borderRadius: '3px'
  }),
  horizontalLine: css({ backgroundColor: THEME.border.colors.gray.lv1, height: '1px', marginTop: '16px' })
};
