import { useTranslation } from 'react-i18next';
import { TableData } from '@/shared/types';
import { usePartnerPermissions } from '@/auth';
import { AccountType, AccountsList } from '../../Accounts';

export type PartnersListProps = TableData<AccountType>;

export const PartnersList = (props: PartnersListProps) => {
  const { t } = useTranslation();
  const { hideEditPartnerBtn } = usePartnerPermissions();

  return (
    <AccountsList
      {...props}
      domain="partner"
      firstColumnTitle={t('HeaderColumn.Partner Name')}
      editRoute={!hideEditPartnerBtn ? '/partner/$id' : undefined}
    />
  );
};
