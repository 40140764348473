import { useMatchRoute } from '@tanstack/react-router';
import { ReactNode, createContext, useContext, useEffect } from 'react';
import { PricingTableItem, TikTokSpecialCampaignDetail } from '@/graphql';
import { UpdateDocumentTitleProps, useLocationHistory, useUpdateDocumentTitle } from '@/shared/hooks';

interface ContextType {
  currentPlan?: PricingTableItem;
  tiktokSpecialCampaign: TikTokSpecialCampaignDetail;
}

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  value: ContextType;
  children: ReactNode;
}

export const TikTokCampaignDetailsProvider = ({ children, value: { tiktokSpecialCampaign } }: Props) => {
  const { lastLocation } = useLocationHistory();
  const { setDocumentAndAppTitles } = useUpdateDocumentTitle({});
  const matchPath = useMatchRoute();

  useEffect(() => {
    let documents: UpdateDocumentTitleProps = {
      alwaysBackToParent: true,
      href: '/tiktok/campaigns',
      search: lastLocation?.search,
      appHeader: 'appHeader.Campaign Title',
      title: 'documentTitle.EditTikTokSpecialCampaign',
      interpolation: { campaignTitle: tiktokSpecialCampaign.title }
    };
    const isPostsPage = !!matchPath({ to: '/tiktok/campaigns/$id/posts' });
    const isReportPage = !!matchPath({ to: '/tiktok/campaigns/$id/report' });

    if (isPostsPage) {
      documents = {
        ...documents,
        interpolation: { campaignTitle: tiktokSpecialCampaign.title },
        title: tiktokSpecialCampaign.title ? 'documentTitle.PostWithTitle' : 'documentTitle.PostDefault',
        appHeader: tiktokSpecialCampaign.title ? 'appHeader.PostWithTitle' : 'appHeader.PostDefault'
      };
    } else if (isReportPage) {
      documents = {
        ...documents,
        interpolation: { title: tiktokSpecialCampaign.title },
        title: tiktokSpecialCampaign.title ? 'documentTitle.PostReportWithTitle' : 'documentTitle.PostReportDefault',
        appHeader: tiktokSpecialCampaign.title ? 'appHeader.PostReportWithTitle' : 'appHeader.PostReportDefault'
      };
    }

    setDocumentAndAppTitles(documents);
  }, []);

  return (
    <Context.Provider
      value={{
        tiktokSpecialCampaign,
        currentPlan: tiktokSpecialCampaign.priceItem
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useTikTokCampaignDetailsContext = () => useContext<ContextType>(Context);
