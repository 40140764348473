import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllTwitterInfluencers.graphql';
import { Query, QueryallTwitterInfluencersArgs } from '../../__generated__/globalTypes';

export const useAllTwitterInfluencersQuery = (options?: QueryHookOptions<Query, QueryallTwitterInfluencersArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallTwitterInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllTwitterInfluencersLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallTwitterInfluencersArgs>
): QueryResult<Query, QueryallTwitterInfluencersArgs> & {
  getAllTwitterInfluencers: LazyQueryExecFunction<Query, QueryallTwitterInfluencersArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllTwitterInfluencers, result] = useLazyQuery<Query, QueryallTwitterInfluencersArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllTwitterInfluencers, ...result };
};

export const useAllTwitterInfluencersPromiseQuery = () => {
  const client = useApolloClient();

  const getAllTwitterInfluencers = useCallback(
    (options?: Omit<QueryOptions<QueryallTwitterInfluencersArgs, Query>, 'query'>) =>
      client.query<Query, QueryallTwitterInfluencersArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllTwitterInfluencers };
};
