import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from './Button';

export type ButtonSubmitProps = ButtonProps;

export const ButtonSubmit = ({ loading, ...restProps }: ButtonSubmitProps) => {
  const { t } = useTranslation();

  return (
    <Button
      type="submit"
      variant="blue"
      loading={{
        title: t('Button.Submitting'),
        ...(typeof loading === 'boolean' ? { status: loading } : loading)
      }}
      {...restProps}
    />
  );
};
