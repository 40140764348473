import {
  useSnackbarHelper,
  ProposalJobDescriptionBreakDownMode,
  useUpdateUserProposalBreakdownModeMutation,
  namedOperations
} from '@/graphql';
import { useParamsSearch } from '@/shared/hooks';
import {
  ProposeInfluencersSearchSchemaType,
  ProposeInfluencersTableMode,
  RejectedProposedInfluencersTable,
  SelectedProposedInfluencersTable,
  UserProposalContextType
} from '@/shared/organisms';

export const ProposeInfluencersList = ({ userProposal }: Pick<UserProposalContextType, 'userProposal'>) => {
  const { enqueueSnackbar } = useSnackbarHelper();
  const { filter, setFilter } = useParamsSearch<ProposeInfluencersSearchSchemaType>();

  const { callUpdateUserProposalBreakdownMode, loading: updatingBreakDownMode } =
    useUpdateUserProposalBreakdownModeMutation({
      refetchQueries: [namedOperations.Query.UserProposal],
      awaitRefetchQueries: true
    });

  const jdMode = userProposal?.jdMode;

  const handleJdModeChange = async (mode: ProposalJobDescriptionBreakDownMode) => {
    try {
      await callUpdateUserProposalBreakdownMode({
        variables: { input: { jdMode: mode } }
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <>
      <SelectedProposedInfluencersTable
        currency={userProposal?.currency}
        tableModeProps={{
          jdMode,
          tableViewMode: filter.tableViewMode || ProposeInfluencersTableMode.VIEW,
          loading: updatingBreakDownMode,
          onTableViewModeChange: (tableViewMode) => setFilter({ tableViewMode }),
          onEditJdMode: handleJdModeChange
        }}
      />

      <RejectedProposedInfluencersTable jdMode={jdMode} />
    </>
  );
};
