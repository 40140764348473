import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import {
  ConnectableOauthPageAndIgAccountForSignInFacebook,
  QuerySocialAccountType,
  SocialAccountStatus
} from '@/graphql';
import { CheckBox, Radio, SocialIcon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar } from '@/shared/molecules';
import { formatIntNumber } from '@/shared/utils';
import { TalentInfluencerFormValues } from './schemaTypes';
import { AddAccountStateType } from './useConnectedTalentAccountsFromState';
import { switchSocialConnectColor } from './utils';

interface SocialAccountProps {
  className?: string;
  src: string | null;
  username: string | null;
  followers?: number | null;
  friends?: number | null;
  socialMedia: QuerySocialAccountType;
  bgcolor?: string;
}

export interface SelectConnectedAccountIdType {
  isUsed: boolean;
  id: number | string;
  connectedAccountIdKey: 'connectedIgAccountId' | 'connectedYtChannelId';
}

const SocialAccount = (props: SocialAccountProps) => {
  const { className, socialMedia, src, username, followers, friends } = props;
  const bgcolor = switchSocialConnectColor(socialMedia);
  const friendsFollowersCount = friends || followers || null;
  const countLabel = friends ? 'Friends' : 'Followers';
  const { t } = useTranslation();

  return (
    <div className={className} css={styles.accountContainer}>
      <div css={styles.iconWrapper(bgcolor, socialMedia)}>
        <SocialIcon type={socialMedia} variant="white" size={24} />
      </div>
      <InfluencerAvatar
        src={src}
        size="42px"
        css={{
          marginLeft: '-5px',
          outline: '3px solid #fff',
          borderRadius: '50%'
        }}
      />
      <div css={{ flex: 1, marginLeft: '8px' }}>
        <span css={styles.userName}>{username || ''}</span>
        <span css={styles.followers}>{`${formatIntNumber(friendsFollowersCount)} ${t(countLabel)}`}</span>
      </div>
    </div>
  );
};

export const SelectAccountModalContent = ({
  state: { draftYtChannel, draftIgAccounts, savedFormValues, connectableYtChannels, connectableIgAccounts },
  selectDraftIgAccount,
  selectDraftYtChannel
}: SelectAccountModalProps) => {
  const { t } = useTranslation();

  if (connectableYtChannels?.length) {
    return (
      <>
        <span css={styles.modalText}>{t('Select an YouTube channel to connect to')}</span>
        <div css={styles.accountsList}>
          {connectableYtChannels.map(({ avatar, channelId, channelName, isUsed, subscribersCount }) => {
            const isChecked = draftYtChannel?.channelId === channelId;
            const isAlreadyUsed =
              isUsed ||
              savedFormValues.connectedYouTubes.findIndex(
                (ch) => ch.channelId === channelId && ch.status !== SocialAccountStatus.SCRAPING
              ) > -1;

            return (
              <div
                key={channelId}
                onClick={() =>
                  !isAlreadyUsed
                    ? selectDraftYtChannel?.({ channelId, channelName, status: SocialAccountStatus.SIGNED_UP })
                    : undefined
                }
                css={styles.igWrapper(isChecked, isAlreadyUsed)}
              >
                <Radio
                  checked={isChecked}
                  onClick={() =>
                    !isAlreadyUsed
                      ? selectDraftYtChannel?.({ channelId, channelName, status: SocialAccountStatus.SIGNED_UP })
                      : undefined
                  }
                  css={{ marginRight: '12px' }}
                />
                <SocialAccount
                  socialMedia={QuerySocialAccountType.YOUTUBE}
                  src={avatar}
                  username={channelName}
                  followers={subscribersCount}
                />
                {isAlreadyUsed && (
                  <div css={styles.disabledCover}>
                    <p css={styles.coverText}>{t('This account already exists')}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <IgAccountModalContent
      connectableIgAccounts={connectableIgAccounts}
      selectDraftIgAccount={selectDraftIgAccount}
      connectedIgAccounts={savedFormValues.connectedInstagrams}
      draftIgAccounts={draftIgAccounts}
    />
  );
};

const IgAccountModalContent = ({
  connectableIgAccounts = [],
  selectDraftIgAccount,
  connectedIgAccounts,
  draftIgAccounts
}: InstagramModalProps) => {
  const { t } = useTranslation();
  const handleSelectDraftIgAccount = (
    page: ConnectableOauthPageAndIgAccountForSignInFacebook['page'],
    igAccount: ConnectableOauthPageAndIgAccountForSignInFacebook['igAccount']
  ) => {
    selectDraftIgAccount({
      pageName: page.name,
      igName: igAccount?.name || '',
      accountId: Number(igAccount?.id),
      pageId: page.id,
      status: SocialAccountStatus.SIGNED_UP
    });
  };

  const igContentTitle =
    !!connectableIgAccounts.length && !!connectableIgAccounts.filter((acc) => !!acc.igAccount).length
      ? 'Select an Instagram account to connect to'
      : 'No Instagram accounts to connect';

  return (
    <>
      <span css={styles.modalText}>{t(igContentTitle)}</span>
      <div css={styles.accountsList}>
        {connectableIgAccounts
          // for fb_pages ig account could be null
          .filter((acc) => !!acc.igAccount)
          .map(({ page, igAccount, isPageAlreadyConnected: isUsed }) => {
            const isChecked = igAccount
              ? draftIgAccounts.findIndex((acc) => acc.accountId === +igAccount.id) > -1
              : false;
            const isAlreadyUsed = igAccount
              ? isUsed || connectedIgAccounts.findIndex((acc) => acc.accountId === +igAccount.id) > -1
              : false;

            return (
              <div key={page.id}>
                <SocialAccount
                  socialMedia={QuerySocialAccountType.FACEBOOK}
                  src={page.avatar || null}
                  username={page.name}
                  followers={page.followersCount}
                />
                <div
                  onClick={() => (!isAlreadyUsed ? handleSelectDraftIgAccount(page, igAccount) : undefined)}
                  css={styles.igWrapper(isChecked, isAlreadyUsed)}
                >
                  <CheckBox
                    checked={isChecked || isAlreadyUsed}
                    disabled={isAlreadyUsed}
                    css={{ marginRight: '12px' }}
                  />
                  <SocialAccount
                    socialMedia={QuerySocialAccountType.INSTAGRAM}
                    src={igAccount?.avatar || ''}
                    username={igAccount?.name || ''}
                    followers={igAccount?.followersCount || 0}
                  />
                  {isAlreadyUsed && (
                    <div css={styles.disabledCover}>
                      <span css={styles.coverText}>{t('This account already exists')}</span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

interface InstagramModalProps {
  connectableIgAccounts?: readonly ConnectableOauthPageAndIgAccountForSignInFacebook[];
  selectDraftIgAccount: (igAccount: AddAccountStateType['draftIgAccounts'][number]) => void;
  connectedIgAccounts: TalentInfluencerFormValues['connectedInstagrams'];
  draftIgAccounts: AddAccountStateType['draftIgAccounts'];
}
interface SelectAccountModalProps {
  state: AddAccountStateType;
  selectDraftFacebookPage?: (pageId: string) => void;
  selectDraftIgAccount: InstagramModalProps['selectDraftIgAccount'];
  allConnectableIgAccounts?: InstagramModalProps['connectableIgAccounts'];
  selectDraftYtChannel?: (draftChannel: AddAccountStateType['draftYtChannel']) => void;
}
export const SelectableFanpagesModalContent = ({
  state: { savedFormValues, connectableFbFanpages, step, draftIgAccounts, draftFacebookAccount, draftFbPagesIds },
  selectDraftIgAccount,
  allConnectableIgAccounts,
  selectDraftFacebookPage
}: SelectAccountModalProps) => {
  const { t } = useTranslation();

  if (step === 2) {
    return (
      <IgAccountModalContent
        // only from selected fanpages
        connectableIgAccounts={allConnectableIgAccounts}
        selectDraftIgAccount={selectDraftIgAccount}
        connectedIgAccounts={savedFormValues.connectedInstagrams}
        draftIgAccounts={draftIgAccounts}
      />
    );
  }

  return (
    <>
      <div css={styles.modalText}>{t('Select the Facebook Pages to connect to')}</div>
      <div css={styles.accountsList}>
        <SocialAccount
          socialMedia={QuerySocialAccountType.FACEBOOK}
          src={draftFacebookAccount?.fbAvatar || null}
          username={draftFacebookAccount?.fbName || ''}
          followers={draftFacebookAccount?.fbFollowersCount || 0}
        />
        {connectableFbFanpages?.map(({ avatar, id, name, followersCount, isPageAlreadyConnected }) => {
          const isChecked = draftFbPagesIds?.includes(id) || isPageAlreadyConnected;
          const isDisabled =
            isPageAlreadyConnected || savedFormValues.connectedFacebookPages.findIndex((fp) => fp.pageId === id) > -1;

          return (
            <div
              key={id}
              onClick={() => (!isPageAlreadyConnected ? selectDraftFacebookPage?.(id) : undefined)}
              css={styles.igWrapper(isChecked && !isDisabled, isDisabled)}
            >
              <CheckBox checked={isChecked} disabled={isDisabled} css={{ marginRight: '12px' }} />
              <SocialAccount
                socialMedia={QuerySocialAccountType.FACEBOOK}
                src={avatar || null}
                username={name}
                followers={followersCount}
              />
              {isDisabled && (
                <div css={styles.disabledCover}>
                  <span css={styles.coverText}>{t('This account already exists')}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  modalText: css({
    padding: '12px 0 24px',
    fontSize: '13px',
    lineHeight: '1.38',
    color: THEME.text.colors.black.lv1
  }),
  accountsList: css({ minWidth: '450px', display: 'flex', flexDirection: 'column', rowGap: '8px' }),
  igWrapper: (selected: boolean, disabled: boolean) =>
    css({
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
      padding: selected ? '10px' : '11px',
      borderWidth: selected ? '3px' : '1px',
      borderColor: selected ? THEME.input.border.colors.focus : THEME.input.border.colors.default,
      borderStyle: 'solid',
      cursor: disabled ? 'unset' : 'pointer',
      marginTop: '8px'
    }),
  disabledCover: css({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    left: 0,
    height: '70px',
    width: '100%',
    background: 'rgba(39, 49, 59, 0.6)',
    borderRadius: '5px'
  }),
  coverText: css({
    flex: 1,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1,
    textAlign: 'center',
    color: THEME.text.colors.white
  }),
  accountContainer: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '48px'
  }),
  userName: css({
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1,
    color: THEME.text.colors.black.lv1
  }),
  followers: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    fontSize: '12px',
    lineHeight: 1.17,
    color: THEME.text.colors.gray.lv3
  }),
  iconWrapper: (bgcolor: string, socialMedia: QuerySocialAccountType) =>
    css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '42px',
      height: '42px',
      backgroundColor: bgcolor,
      backgroundImage:
        socialMedia === QuerySocialAccountType.INSTAGRAM ? 'linear-gradient(72deg, #fccc63, #d92f7e)' : 'none',
      borderRadius: '50%'
    })
};
