import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import {
  useAllAvailableCountryOptions,
  useAllAnalyticsSubscriptionPlansOptions,
  useAllAdvertisersForAnalyticsSearchOptions
} from '@/shared/hooks';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { AnalyticsFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { isAdvertiser } = useUserRoles();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const { allSubscriptionPlanOptions } = useAllAnalyticsSubscriptionPlansOptions({ skip: isAdvertiser });
  const { allAdvertisersOptions } = useAllAdvertisersForAnalyticsSearchOptions({
    fetchPolicy: 'cache-first',
    skip: isAdvertiser
  });

  return (
    <BasicFilterWrapper css={{ padding: '12px 16px 16px' }}>
      <TextSearchField<AnalyticsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.Search by account name')}
        help={t('Tooltip.Account Name, Social Media Analytics can be only search in this field')}
      />

      {!isAdvertiser ? (
        <>
          <SelectorField<AnalyticsFilterFormKeys>
            name="advertiserId"
            onSubmit={onSubmit}
            options={allAdvertisersOptions}
            placeholder={t<string>('Selector.Advertiser')}
          />

          <SelectorField<AnalyticsFilterFormKeys>
            onSubmit={onSubmit}
            name="subscriptionPlan"
            options={allSubscriptionPlanOptions}
            placeholder={t<string>('Selector.Plan')}
          />

          <SelectorField<AnalyticsFilterFormKeys>
            name="countryId"
            onSubmit={onSubmit}
            options={allAvailableCountryOptions}
            placeholder={t<string>('Selector.Country')}
          />
        </>
      ) : null}
    </BasicFilterWrapper>
  );
};
