import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerYoutubeAnalyticsCompareAccountCards.graphql';
import { Query, QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs } from '../../__generated__/globalTypes';

export const useInfluencerYoutubeAnalyticsCompareAccountCardsQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerYoutubeAnalyticsCompareAccountCardsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs>
): QueryResult<Query, QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs> & {
  getInfluencerYoutubeAnalyticsCompareAccountCards: LazyQueryExecFunction<
    Query,
    QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerYoutubeAnalyticsCompareAccountCards, result] = useLazyQuery<
    Query,
    QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerYoutubeAnalyticsCompareAccountCards, ...result };
};

export const useInfluencerYoutubeAnalyticsCompareAccountCardsPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerYoutubeAnalyticsCompareAccountCards = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerYoutubeAnalyticsCompareAccountCards };
};
