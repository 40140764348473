import { MarketplaceCampaignDetailType } from '@/graphql';

export const MKP_FORM_SIZE = 984;

export const AFFILIATE_CAMPAIGN_TYPES = [
  MarketplaceCampaignDetailType.E_COMMERCE,
  MarketplaceCampaignDetailType.URL_TRACKING,
  MarketplaceCampaignDetailType.REFERRAL_CODE
];
/**
 * @description setContents always wrap editor value with <p> tag, so then we are clearing form it becomes `<p><br></p>`
 */
export const SUN_EDITOR_EMPTY_VALUE = '<p><br></p>';
