import { useTiktokSpecialPackageInfluencersQuery } from '@/graphql';
import { FilterWrapper, ListWithPagination } from '@/shared/molecules';
import { convertOrderToORDER, getOffset, getValuableVariables } from '@/shared/utils';
import {
  TikTokCampaignInfluencersSort,
  TikTokCampaignInfluencersList,
  TikTokCampaignInfluencersFilter,
  useTikTokCampaignInfluencersFilter
} from '@/shared/organisms';

export interface TikTokCampaignInfluencersTemplateProps {
  className?: string;
}

export const TikTokCampaignInfluencersTemplate = ({
  className
}: TikTokCampaignInfluencersTemplateProps | undefined = {}) => {
  const { page, limit, sort, filter } = useTikTokCampaignInfluencersFilter(); // TODO: use for getting list later

  const { data, loading } = useTiktokSpecialPackageInfluencersQuery({
    skip: !filter.packageId,
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      countryIds: filter.countryIds,
      genders: filter.genders,
      keyword: filter.keyword,
      packageId: Number(filter.packageId),
      categoryIds: filter.categoryIds.map(Number)
    })
  });

  const totalRecords = data?.tiktokSpecialPackageInfluencersCount?.totalNumber;

  return (
    <ListWithPagination className={className} pagination={{ totalRecords }}>
      <FilterWrapper>
        <TikTokCampaignInfluencersFilter />
        <TikTokCampaignInfluencersSort totalRecords={totalRecords} />
      </FilterWrapper>

      <TikTokCampaignInfluencersList
        loading={loading}
        totalRecords={totalRecords}
        data={data?.tiktokSpecialPackageInfluencers}
      />
    </ListWithPagination>
  );
};
