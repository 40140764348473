import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateInfluencerTagsByStaff.graphql';
import { Mutation, MutationupdateInfluencerTagsByStaffArgs } from '../../__generated__/globalTypes';

export const useUpdateInfluencerTagsByStaffMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateInfluencerTagsByStaffArgs>
) => {
  const [callUpdateInfluencerTagsByStaff, result] = useMutation<Mutation, MutationupdateInfluencerTagsByStaffArgs>(
    MUTATION,
    options
  );

  return { callUpdateInfluencerTagsByStaff, ...result };
};

export type UpdateInfluencerTagsByStaffMutationFunction = Mutation['updateInfluencerTagsByStaff'];
