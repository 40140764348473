import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerYoutubeAnalyticsTagsTopRankingCount.graphql';
import { Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs } from '../../__generated__/globalTypes';

export const useInfluencerYoutubeAnalyticsTagsTopRankingCountQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerYoutubeAnalyticsTagsTopRankingCountLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs>
): QueryResult<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs> & {
  getInfluencerYoutubeAnalyticsTagsTopRankingCount: LazyQueryExecFunction<
    Query,
    QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerYoutubeAnalyticsTagsTopRankingCount, result] = useLazyQuery<
    Query,
    QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerYoutubeAnalyticsTagsTopRankingCount, ...result };
};

export const useInfluencerYoutubeAnalyticsTagsTopRankingCountPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerYoutubeAnalyticsTagsTopRankingCount = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerYoutubeAnalyticsTagsTopRankingCount };
};
