import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateTiktokSpecialCampaign.graphql';
import { Mutation, MutationcreateTiktokSpecialCampaignArgs } from '../../__generated__/globalTypes';

export const useCreateTiktokSpecialCampaignMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateTiktokSpecialCampaignArgs>
) => {
  const [callCreateTiktokSpecialCampaign, result] = useMutation<Mutation, MutationcreateTiktokSpecialCampaignArgs>(
    MUTATION,
    options
  );

  return { callCreateTiktokSpecialCampaign, ...result };
};

export type CreateTiktokSpecialCampaignMutationFunction = Mutation['createTiktokSpecialCampaign'];
