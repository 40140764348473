import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { XhsInfluencersProps } from './schemaTypes';
import { XhsInfluencersList } from './XhsInfluencersList';
import { XhsInfluencersSort } from './XhsInfluencersSort';
import { XhsInfluencersFilter } from './XhsInfluencersFilter';
import { XhsInfluencersProvider } from './XhsInfluencersContext';

export const XhsInfluencers = (props: XhsInfluencersProps) => (
  <XhsInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <XhsInfluencersFilter />
      <XhsInfluencersSort />
    </InfluencersFilterWrapper>

    <XhsInfluencersList />
  </XhsInfluencersProvider>
);

export * from './schemaTypes';
