import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { SocialAccountStatus } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import {
  PostsSection,
  InfluencerAvatar,
  InfluencerPostDetailsModal,
  InfluencerWidgetInformation
} from '@/shared/molecules';
import { bigIntFormatter, formatPercent } from '@/shared/utils';
import { InstagramInfluencerProfile } from '../../Influencers';

interface SimilarAmbassadorPostWidgetProps {
  className?: string;
  profile: InstagramInfluencerProfile;
}

export const SimilarAmbassadorPostWidget = ({
  className,
  profile: {
    id,
    age,
    name,
    posts,
    avatar,
    gender,
    country,
    averageLikes,
    averageReach,
    isBrandAccount,
    socialAccountId,
    analyticsWarning,
    socialAccountType,
    socialAccountStatus,
    socialAccountFollowers,
    socialAccountEngagementRate
  }
}: SimilarAmbassadorPostWidgetProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink({ disabled: !id });

  const isSignedUp = socialAccountStatus === SocialAccountStatus.SIGNED_UP;
  const stats = [
    { label: 'HeaderColumn.Followers', value: bigIntFormatter(socialAccountFollowers || 0) },
    { label: 'HeaderColumn.ER', value: formatPercent(socialAccountEngagementRate) },
    {
      label: isSignedUp ? 'HeaderColumn.Avg Reach' : 'HeaderColumn.Est Reach',
      value: bigIntFormatter(averageReach || 0)
    },
    { label: 'HeaderColumn.Avg Likes', value: bigIntFormatter(averageLikes || 0) }
  ];

  return (
    <div css={styles.wrapper} className={className}>
      <div css={styles.content}>
        <InfluencerAvatar
          size="80px"
          src={avatar}
          isBrandAccount={isBrandAccount}
          socialAccountType={socialAccountType}
        />

        <InfluencerWidgetInformation
          age={age}
          name={name}
          gender={gender}
          country={country}
          isSignedUp={isSignedUp}
          isWarning={!!analyticsWarning}
          profileUrl={generateProfileLink(id, { filter: { id: String(socialAccountId), sm: socialAccountType } })}
        />

        <div css={styles.statictis}>
          {stats.map(({ label, value }) => (
            <div css={{ flex: 1, padding: '0 8px', textAlign: 'right' }} key={`${id}-${label}`}>
              <p css={styles.label} title={t<string>(label)}>
                {t(label)}
              </p>
              <p css={styles.value} title={value}>
                {value}
              </p>
            </div>
          ))}
        </div>

        <PostsSection maxPosts={4} posts={posts} influencerId={Number(id)} css={{ marginLeft: '16px' }} />
        <InfluencerPostDetailsModal posts={posts} />
      </div>
    </div>
  );
};
const styles = {
  wrapper: css({
    height: '96px',
    display: 'flex',
    boxShadow: 'none',
    overflow: 'hidden',
    borderRadius: '3px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: '1px solid #EEF3F7',
    backgroundColor: THEME.background.colors.white,
    '&:not(:first-of-type)': { marginTop: '8px' }
  }),
  content: css({
    flex: 1,
    height: '100%',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  }),
  label: css({
    fontSize: '10px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 'inherit',
    letterSpacing: '0.28px',
    textOverflow: 'ellipsis',
    color: THEME.text.colors.gray.lv3
  }),
  value: css({
    marginTop: '4px',
    fontSize: '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 'inherit',
    textOverflow: 'ellipsis',
    color: THEME.text.colors.black.lv1
  }),
  statictis: css({
    display: 'flex',
    fontWeight: 600,
    flex: '1 1 auto',
    marginRight: '48px',
    justifyContent: 'space-around'
  })
};
