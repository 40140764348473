import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { EngagementDetail, SocialAccountStatus } from '@/graphql';
import { Button, FormStyle, Input, Label } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useCopy } from '@/shared/hooks';
import { TextAreaField, FileUploadField } from '@/shared/molecules';
import { EngagementPostFormKeys, EngagementPostFormValues } from '../types';
import { useEngagementPostFormState } from './hooks';
import { FooterButtons, InfluencerAccountFields } from './shared';

interface TTCMTemplateProps {
  onClose: () => void;
  onSubmit?: () => void;
  engagementId: EngagementDetail['id'];
  initialValues: EngagementPostFormValues;
}
export const TTCMTemplate = ({ onClose, onSubmit, engagementId, initialValues }: TTCMTemplateProps) => {
  const { t } = useTranslation();
  const { copyToClipboard } = useCopy();
  const { isAdminStaff } = useUserRoles();
  const { selectedSocialAccount } = useEngagementPostFormState({ engagementId });

  const inviteLink = selectedSocialAccount?.ttcmCampaignInviteLink || '';
  const isTiktokAccountNotSignedUp = selectedSocialAccount?.status === SocialAccountStatus.SCRAPING;
  const hasUploadFields =
    isAdminStaff &&
    !!initialValues.id &&
    ((!initialValues.materialsUrl.length && !initialValues.content) || isTiktokAccountNotSignedUp);

  return (
    <>
      <FormStyle.Wrapper css={{ padding: '24px', overflow: 'auto' }} gapRow={16}>
        <FormStyle.SectionTitle index={1} label={t('Video Information')} />

        <InfluencerAccountFields engagementId={engagementId} />

        <FormStyle.FieldsGroup css={{ marginBottom: '16px', alignItems: 'flex-end' }} itemsPerRow={2}>
          <FormStyle.FieldWrapper>
            <Label title={t('TextForm.Invitation link')} />
            <Input placeholder="" value={inviteLink} disabled />
          </FormStyle.FieldWrapper>

          {inviteLink ? (
            <FormStyle.FieldWrapper css={{ paddingLeft: '0 !important' }}>
              <Button variant="white" title={t('Button.Copy')} onClick={() => copyToClipboard(inviteLink)} />
            </FormStyle.FieldWrapper>
          ) : null}
        </FormStyle.FieldsGroup>

        <h3 css={styles.subHeader}>{t('Step1_AddTikTokVideoTitle')}</h3>
        <p css={styles.stepDesctiption}>{t('Step1_AddTikTokVideoDescription')}</p>
        <br />
        <h3 css={styles.subHeader}>{t('Step2_AddTikTokVideoTitle')}</h3>
        <p css={styles.stepDesctiption}>{t('Step2_AddTikTokVideoDescription')}</p>

        {hasUploadFields ? (
          <>
            <br />
            <h3 css={styles.subHeader}>{t('Step3_AddTikTokVideoTitle')}</h3>

            {isTiktokAccountNotSignedUp ? (
              <Label css={{ marginTop: '16px', color: '#ff2b52' }} title={t('TtcmPostThumbnailsNote')} />
            ) : null}

            <TextAreaField<EngagementPostFormKeys>
              name="content"
              title={t('TextForm.Contents')}
              help={t('Tooltip.Post Content Help')}
              placeholder={t<string>('TextForm.Contents Placeholder')}
            />

            <FileUploadField<EngagementPostFormKeys>
              target="post"
              itemsPerRow={3}
              name="materialsUrl"
              title={t('Video thumbnail')}
              help={t('Tooltip.Files Help')}
              multiple
            />
          </>
        ) : null}
      </FormStyle.Wrapper>

      {hasUploadFields ? <FooterButtons onClose={onClose} onSubmit={onSubmit} /> : null}
    </>
  );
};

const styles = {
  copyTile: css({
    gap: '10px',
    width: '203px',
    height: '32px',
    display: 'flex',
    borderRadius: '3px',
    padding: '7px 12px',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#eef3f7',
    border: THEME.border.base
  }),
  subHeader: css({
    fontWeight: 600,
    fontSize: '14px'
  }),
  stepDesctiption: css({
    fontWeight: 400,
    fontSize: '12px',
    marginTop: '8px'
  })
};
