import { useTranslation } from 'react-i18next';
import { getRestApiUrl } from '@/shared/utils';
import { useDownloadFile } from '@/shared/hooks';
import { DownloadButton, FormStyle } from '@/shared/atoms';
import { BasicFilterWrapper, TextSearchField } from '@/shared/molecules';
import { AutoManagedJoinedInfluencersFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
  totalRecords?: number;
  autoManagedCampaignId: number;
}

export const Template = ({ onSubmit, totalRecords, autoManagedCampaignId }: TemplateProps) => {
  const { t } = useTranslation();
  const { downloading, handleDownloadFile } = useDownloadFile();

  return (
    <BasicFilterWrapper css={{ padding: 0 }}>
      <TextSearchField<AutoManagedJoinedInfluencersFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.InfluencerFilter')}
      />

      <FormStyle.FieldWrapper css={{ flex: 'none' }}>
        <DownloadButton
          loading={downloading}
          disabled={!totalRecords}
          css={{ minWidth: '115px', textTransform: 'none' }}
          onClick={() =>
            handleDownloadFile({
              filenamePrefix: 'joined_influencers',
              url: getRestApiUrl(`auto_managed/${autoManagedCampaignId}/joined_influencers/csv/`)
            })
          }
        />
      </FormStyle.FieldWrapper>
    </BasicFilterWrapper>
  );
};
