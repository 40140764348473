import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UnApproveApplicantInfluencers.graphql';
import { Mutation, MutationunApproveApplicantInfluencersArgs } from '../../__generated__/globalTypes';

export const useUnApproveApplicantInfluencersMutation = (
  options?: MutationHookOptions<Mutation, MutationunApproveApplicantInfluencersArgs>
) => {
  const [callUnApproveApplicantInfluencers, result] = useMutation<Mutation, MutationunApproveApplicantInfluencersArgs>(
    MUTATION,
    options
  );

  return { callUnApproveApplicantInfluencers, ...result };
};

export type UnApproveApplicantInfluencersMutationFunction = Mutation['unApproveApplicantInfluencers'];
