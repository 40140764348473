import { useEffect, useRef } from 'react';

// Refs: https://usehooks.com/useOnClickOutside/
/**
 * useEffect to detect click outside a element
 * @param {*} handler - function
 */
export const useOnClickOutside = <T extends HTMLElement>(handler: (e: Event) => void) => {
  const ref = useRef<T>(null);

  useEffect(
    () => {
      const listener = (e: Event) => {
        // Do nothing if clicking ref's element or descendent elements or specific element with ID
        const clickTarget = e.target as HTMLElement;
        if (
          ref.current &&
          !ref.current.contains(e.target as Node) &&
          clickTarget?.getAttribute('id') !== 'skipOutsideClick'
        ) {
          handler(e);
        }
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );

  return { ref };
};
