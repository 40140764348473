import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/GetCouponsList.graphql';
import { Query, QuerygetCouponsListArgs } from '../../__generated__/globalTypes';

export const useGetCouponsListQuery = (options?: QueryHookOptions<Query, QuerygetCouponsListArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerygetCouponsListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useGetCouponsListLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerygetCouponsListArgs>
): QueryResult<Query, QuerygetCouponsListArgs> & {
  getGetCouponsList: LazyQueryExecFunction<Query, QuerygetCouponsListArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getGetCouponsList, result] = useLazyQuery<Query, QuerygetCouponsListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getGetCouponsList, ...result };
};

export const useGetCouponsListPromiseQuery = () => {
  const client = useApolloClient();

  const getGetCouponsList = useCallback(
    (options?: Omit<QueryOptions<QuerygetCouponsListArgs, Query>, 'query'>) =>
      client.query<Query, QuerygetCouponsListArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getGetCouponsList };
};
