import { TwitterAnalyticsPostItemPayload } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerTwitterAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useAnalyticsTwitterPostsListTable } from './hooks';

export interface AnalyticsTwitterPostsListProps extends TableData<TwitterAnalyticsPostItemPayload> {
  mainSocialAccountId?: number;
}

export const AnalyticsTwitterPostsList = ({
  loading,
  data = [],
  mainSocialAccountId
}: AnalyticsTwitterPostsListProps) => {
  const { rows, columns } = useAnalyticsTwitterPostsListTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerTwitterAnalyticsPostDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId }))}
        isAnalytics
      />
    </>
  );
};
