import { useAuthData, useUserRoles } from '@/auth';
import { UserRoles } from '@/graphql';

import { UserFormKeys, UserFormValues } from '../types';

interface Props {
  formValues: UserFormValues;
  defaultValues?: UserFormValues;
  isUserSettingsForAdvertisers?: boolean;
}

export const useDisabledFields = ({
  formValues,
  defaultValues,
  isUserSettingsForAdvertisers
}: Props): Partial<Record<UserFormKeys, boolean>> => {
  const {
    auth: { userId }
  } = useAuthData();
  const { isStaff, isAdminStaff } = useUserRoles();

  const isEditMode = defaultValues?.mode === 'edit';
  const isEditingHimself = isEditMode && userId === defaultValues?.id;
  const isRoleChanged = defaultValues?.userType !== formValues.userType;
  const isEditingWithoutChangingRole = isEditMode && !isRoleChanged;
  // staff user can only add advertisers to another staff, do not count himself
  // Staff cannot update other staffs information except update list advertisers of those staffs
  // But incase staff promote someone to role staff, they still can update the user's information in this case
  // and isRoleChanged will detect about this case
  const isStaffUserEditByStaff =
    isEditingWithoutChangingRole && formValues.userType === UserRoles.STAFF && isStaff && !isEditingHimself;
  const isAdminEditByStaff = isEditingWithoutChangingRole && formValues.userType === UserRoles.ADMINISTRATOR && isStaff;

  return {
    agencyId: false,
    advertiserIds: false,
    hasInvitationEmail: false,
    partnerId: isEditingWithoutChangingRole,
    advertiserId: isEditingWithoutChangingRole,
    talentAgencyId: isEditingWithoutChangingRole,
    name: isStaffUserEditByStaff || isAdminEditByStaff,
    email: isStaffUserEditByStaff || isAdminEditByStaff,
    notificationSetting: !!isUserSettingsForAdvertisers,
    password: isStaffUserEditByStaff || isAdminEditByStaff,
    userType: isEditMode && (isAdminEditByStaff || !isAdminStaff),
    passwordConfirm: isStaffUserEditByStaff || isAdminEditByStaff,
    countryId: isStaffUserEditByStaff || isAdminEditByStaff || !!isUserSettingsForAdvertisers
  };
};
