import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateSelfRegisteredAdvertiserUser.graphql';
import { Mutation, MutationcreateSelfRegisteredAdvertiserUserArgs } from '../../__generated__/globalTypes';

export const useCreateSelfRegisteredAdvertiserUserMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateSelfRegisteredAdvertiserUserArgs>
) => {
  const [callCreateSelfRegisteredAdvertiserUser, result] = useMutation<
    Mutation,
    MutationcreateSelfRegisteredAdvertiserUserArgs
  >(MUTATION, options);

  return { callCreateSelfRegisteredAdvertiserUser, ...result };
};

export type CreateSelfRegisteredAdvertiserUserMutationFunction = Mutation['createSelfRegisteredAdvertiserUser'];
