import { SocialAccountStatus } from '@/graphql';
import { signedUpImage } from '@/shared/assets';
import { Anchor, AnchorProps } from './Anchor';

export const AnchorWithStatus = ({ status, ...anchorProps }: AnchorProps & { status: SocialAccountStatus }) => (
  <>
    <Anchor {...anchorProps} />
    {status === SocialAccountStatus.SIGNED_UP && (
      <img css={{ width: '12px', height: '12px', alignSelf: 'baseline' }} src={signedUpImage} alt="profile status" />
    )}
  </>
);
