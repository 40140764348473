import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { useAnalyticsInstagramHashtagsFilter } from './hooks';

export interface AnalyticsInstagramHashtagsFilterProps extends Omit<TemplateProps, 'onSubmit'> {
  className?: string;
  defaultHashtagId?: string;
}

export const AnalyticsInstagramHashtagsFilter = ({
  className,
  defaultHashtagId,
  ...restProps
}: AnalyticsInstagramHashtagsFilterProps) => {
  const { filter, setFilterForm } = useAnalyticsInstagramHashtagsFilter();

  return (
    <FilterWrapper css={{ boxShadow: 'none', borderRadius: 'none' }} className={className}>
      <FilterProvider
        initialValues={{ ...filter, hashtagId: defaultHashtagId || filter.hashtagId }}
        onSubmit={setFilterForm}
        basicFilter={<Template {...restProps} />}
      />
    </FilterWrapper>
  );
};

export * from './schemaTypes';
export { useAnalyticsInstagramHashtagsFilter, useExportAnalyticsInstagramHashtagsFile } from './hooks';
