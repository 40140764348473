import { Trans, useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME, TIKTOK_HELP_LINK } from '@/shared/constants';
import { PaymentPlanType, usePricingTableQuery } from '@/graphql';
import { tiktokInfluencerCampaignPlanBgImage } from '@/shared/assets';
import { Modal, Icon, Anchor, ModalProps, RenderDataWithFallback } from '@/shared/atoms';
import { Plan } from './Plan';

export type TikTokCampaignsPlansModalProps = Pick<ModalProps, 'open'>;

export const TikTokCampaignsPlansModal = ({ open }: TikTokCampaignsPlansModalProps) => {
  const { t } = useTranslation();
  const { data, loading } = usePricingTableQuery({ skip: !open, variables: { planType: PaymentPlanType.TIKTOK_ADS } });

  const plans = data?.pricingTable?.plans.filter((plan) => plan.mkpCampaignEnabled) || [];

  return (
    <Modal
      open={open}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={{ overlay: { left: THEME.container.menu.width } }}
      css={{ '--header-height': '190px', minWidth: '656px', width: 'max-content' }}
    >
      <div css={styles.header}>
        <div css={{ flex: 1, maxWidth: '420px' }}>
          <p css={{ fontWeight: 400, fontSize: '20px', lineHeight: '100%', marginTop: '6px', maxWidth: '280px' }}>
            <Trans i18nKey="Boost your brand by running Influencer Campaigns" components={{ b: <b /> }} />
          </p>
          <p css={styles.description}>{t('marketplacePlanDescription')}</p>
          <Anchor
            variant="inherit"
            href={TIKTOK_HELP_LINK.learnMoreAboutPlans}
            target="_blank"
            css={{ fontSize: '12px', fontWeight: 700, lineHeight: '140%' }}
          >
            {t('Learn more about plans')}&nbsp;
            <Icon icon="caret-right" size={8} color="inherit" />
          </Anchor>
        </div>
      </div>

      <div css={styles.content}>
        <RenderDataWithFallback loading={loading} loadingProps={{ css: { height: 'auto', minHeight: 'inherit' } }}>
          <div css={{ padding: '24px 8px 24px 32px', boxSizing: 'border-box' }}>
            {plans.map((plan) => (
              <Plan key={plan.id} data={plan} />
            ))}
          </div>
        </RenderDataWithFallback>
      </div>
    </Modal>
  );
};
const styles = {
  header: css({
    backgroundSize: 'cover',
    boxSizing: 'border-box',
    backgroundRepeatL: 'no-repeat',
    padding: '39px 39px 24px 24px',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    minHeight: 'var(--header-height)',
    backgroundColor: THEME.background.colors.white,
    backgroundImage: `url(${tiktokInfluencerCampaignPlanBgImage})`
  }),
  description: css({
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '6px',
    lineHeight: '140%',
    color: THEME.text.colors.gray.lv3
  }),
  content: css({
    overflow: 'auto',
    minHeight: '280px',
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    backgroundColor: THEME.background.colors.white,
    maxHeight: 'calc(100vh - var(--header-height) - 32px)' // header height + modal padding
  })
};
