import { useState } from 'react';
import {
  YoutubeAnalyticsPostPayloads,
  useInfluencerYoutubeAnalyticsPostsByDatePromiseQuery,
  useYoutubeAnalyticsPostsByDatePromiseQuery
} from '@/graphql';
import { DAILY_POST_PARAM_KEY } from '@/shared/constants';
import { useFetchDailyPostsOnChart, usePostDetailsParam, useQueryHelper } from '@/shared/hooks';
import { AnalyticsOverviewChartProps } from '../../../../shared';
import { AnalyticsSelectedAccountInfo } from '../../../../types';
import { AnalyticsYoutubeDashboardGraphKeys as Keys } from '../types';
import { legendColors } from '../utils';

interface Props {
  isInfluencerProfile?: boolean;
  accountId: AnalyticsSelectedAccountInfo['id'];
  chartData: AnalyticsOverviewChartProps<Keys>['data'];
}

export const useAnalyticsYoutubeDashboardOverviewPosts = ({ chartData, accountId, isInfluencerProfile }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { setPostDetailsParam } = usePostDetailsParam(DAILY_POST_PARAM_KEY);
  const [dailyPosts, setDailyPosts] = useState<YoutubeAnalyticsPostPayloads | null>(null);
  const { getYoutubeAnalyticsPostsByDate } = useYoutubeAnalyticsPostsByDatePromiseQuery();
  const { getInfluencerYoutubeAnalyticsPostsByDate } = useInfluencerYoutubeAnalyticsPostsByDatePromiseQuery();

  const fetchDailyPosts = async (date: string) => {
    if (!date) return;

    try {
      const { data } = isInfluencerProfile
        ? await getInfluencerYoutubeAnalyticsPostsByDate({ variables: { date, socialAccountId: accountId } })
        : await getYoutubeAnalyticsPostsByDate({ variables: { date, youtubeAnalyticsAccountId: accountId } });

      const postsInDate = isInfluencerProfile
        ? data?.influencerYoutubeAnalyticsPostsByDate
        : data?.youtubeAnalyticsPostsByDate;
      const posts = postsInDate.posts || [];

      if (posts.length) {
        setDailyPosts(postsInDate);
        setPostDetailsParam(posts.at(0)?.id);
      }
    } catch (error) {
      enqueueSnackbar(t('Can not get daily posts data, please try again later '), { variant: 'error' });
    }
  };

  const { customChartOptions } = useFetchDailyPostsOnChart({
    onFetchPosts: fetchDailyPosts,
    postsData: [chartData.post.data],
    postColors: [legendColors.post]
  });

  return { dailyPosts, customChartOptions };
};
