import { InstagramFeedPost } from '@/graphql';
import { Option } from '@/shared/types';

export const defaultSelectedAnalyticsInstagramPostsColumns: Array<keyof InstagramFeedPost> = [
  'likes',
  'comments',
  'saved',
  'engagement',
  'reach',
  'impressions',
  'engagementRate',
  'postDate'
];
export const defaultSelectedAnalyticsInstagramComparePostsColumns: Array<keyof InstagramFeedPost> = [
  'engagement',
  'engagementRate',
  'likes',
  'comments',
  'postDate'
];

export const selectableAnalyticsInstagramPostsColumnsOptions: Option<keyof InstagramFeedPost>[] = [
  { label: 'Follow', value: 'follow' },
  { label: 'Impression from Discovery', value: 'impressionFromDiscovery' },
  { label: 'Impression from Hashtag', value: 'impressionFromHashtag' },
  { label: 'Non Follower Reach', value: 'nonFollowerReach' },
  { label: 'Share', value: 'share' },
  { label: 'Interaction', value: 'interaction' },
  { label: 'Product Page View', value: 'productPageView' },
  { label: 'Product Button Click', value: 'productButtonClick' }
];
