import { ListWithPagination } from '@/shared/molecules';
import { RenderDataWithFallback } from '@/shared/atoms';
import {
  YoutubeCMSTabs,
  YoutubeCMSFilter,
  YoutubeCMSSummary,
  useYoutubeCMSFilter,
  YoutubeCMSAssetsList,
  YoutubeCMSRevenueType,
  YoutubeCMSDescription,
  YoutubeCMSChannelsList
} from '@/shared/organisms';
import { useYoutubeCMSContext } from '../YoutubeCMSContext';
import { useYoutubeCMSDocumentTitle, useYoutubeCMSEstimatedData } from '../hooks';

export const YoutubeCMSEstimatedRevenue = () => {
  useYoutubeCMSDocumentTitle();
  const { canModifyYoutubeCMSData } = useYoutubeCMSContext();
  const { page, limit, filter, setFilter, isSegmentAssets, searchPlaceholder } = useYoutubeCMSFilter();
  const { summary, assetsData, channelsData, totalRecords, fetchingData, fetchingSummary } =
    useYoutubeCMSEstimatedData();

  const type = YoutubeCMSRevenueType.ESTIMATED_REVENUE;
  const defaultListProps = {
    type,
    page,
    limit,
    totalRecords,
    loading: fetchingData,
    modifiable: canModifyYoutubeCMSData
  };

  return (
    <>
      <YoutubeCMSDescription />

      <ListWithPagination pagination={{ totalRecords }}>
        <YoutubeCMSTabs />

        <YoutubeCMSFilter searchPlaceholder={searchPlaceholder} />

        <RenderDataWithFallback loading={fetchingSummary}>
          <YoutubeCMSSummary
            type={type}
            data={summary}
            segment={filter.segment || undefined}
            onSegmentChange={(segment) => setFilter({ ...filter, segment })}
          />

          {isSegmentAssets ? (
            <YoutubeCMSAssetsList {...defaultListProps} data={assetsData} />
          ) : (
            <YoutubeCMSChannelsList {...defaultListProps} data={channelsData} />
          )}
        </RenderDataWithFallback>
      </ListWithPagination>
    </>
  );
};
