import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthTalentSignInTwitter.graphql';
import { Mutation, MutationsocialAuthTalentSignInTwitterArgs } from '../../__generated__/globalTypes';

export const useSocialAuthTalentSignInTwitterMutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthTalentSignInTwitterArgs>
) => {
  const [callSocialAuthTalentSignInTwitter, result] = useMutation<Mutation, MutationsocialAuthTalentSignInTwitterArgs>(
    MUTATION,
    options
  );

  return { callSocialAuthTalentSignInTwitter, ...result };
};

export type SocialAuthTalentSignInTwitterMutationFunction = Mutation['socialAuthTalentSignInTwitter'];
