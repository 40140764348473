import {
  TrafficSource,
  TrafficSourceProps,
  DefaultPostDetailsTemplate,
  DefaultPostDetailsTemplateProps
} from '../shared';

export interface EngagementPostDetailsTabProps extends DefaultPostDetailsTemplateProps {
  trafficSourceData?: TrafficSourceProps['data'];
}

export const EngagementPostDetailsTab = ({ trafficSourceData, ...restProps }: EngagementPostDetailsTabProps) => (
  <DefaultPostDetailsTemplate
    css={[{ width: '856px' }, trafficSourceData?.length ? { overflow: 'auto', height: '683px' } : {}]}
    {...restProps}
  >
    {trafficSourceData?.length ? <TrafficSource data={trafficSourceData} /> : null}
  </DefaultPostDetailsTemplate>
);
