import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { CircularProgress } from './CircularProgress';

interface WithLoadingOverlayProps {
  children: React.ReactNode;
  loading: boolean;
}
export const WithLoadingOverlay = ({ loading, children }: WithLoadingOverlayProps) => (
  <div css={styles.container}>
    {loading ? (
      <div css={styles.loadingOverlay}>
        <CircularProgress size={48} thickness={0.5} backgroundColor="#dee5ec" css={{ margin: 'auto' }} />
      </div>
    ) : null}
    {children}
  </div>
);

const styles = {
  container: css({
    width: '100%',
    position: 'relative',
    minHeight: '100px'
  }),
  loadingOverlay: css({
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'absolute',
    inset: 0,
    zIndex: 1000,
    backgroundColor: THEME.background.colors.white,
    opacity: 0.5
  })
};
