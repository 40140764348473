import { useTranslation } from 'react-i18next';
import { MarketplaceDetail } from '@/graphql';
import { getRestApiUrl } from '@/shared/utils';
import { useDownloadFile } from '@/shared/hooks';
import { Button, FormStyle } from '@/shared/atoms';
import { BasicFilterWrapper, TextSearchField } from '@/shared/molecules';
import { MarketplaceJoinedInfluencersFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
  marketplaceId: MarketplaceDetail['id'];
}

export const Template = ({ onSubmit, marketplaceId }: TemplateProps) => {
  const { t } = useTranslation();
  const { downloading, handleDownloadFile } = useDownloadFile();

  return (
    <BasicFilterWrapper>
      <TextSearchField<MarketplaceJoinedInfluencersFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.keywords')}
      />

      <FormStyle.FieldWrapper css={{ flex: 'none' }}>
        <Button
          variant="white"
          loading={downloading}
          css={{ minWidth: '115px' }}
          title={t('Button.Download')}
          prefixIcon={{ icon: 'download', size: '14px' }}
          onClick={() =>
            handleDownloadFile({
              extension: '.csv',
              filenamePrefix: 'joined_influencers',
              url: getRestApiUrl(`marketplace/${marketplaceId}/affiliate_joined_influencers/csv/`)
            })
          }
        />
      </FormStyle.FieldWrapper>
    </BasicFilterWrapper>
  );
};
