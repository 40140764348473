import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramInteractionOverview.graphql';
import { Query, QueryinstagramInteractionOverviewArgs } from '../../__generated__/globalTypes';

export const useInstagramInteractionOverviewQuery = (
  options?: QueryHookOptions<Query, QueryinstagramInteractionOverviewArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramInteractionOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramInteractionOverviewLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramInteractionOverviewArgs>
): QueryResult<Query, QueryinstagramInteractionOverviewArgs> & {
  getInstagramInteractionOverview: LazyQueryExecFunction<Query, QueryinstagramInteractionOverviewArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramInteractionOverview, result] = useLazyQuery<Query, QueryinstagramInteractionOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInstagramInteractionOverview, ...result };
};

export const useInstagramInteractionOverviewPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramInteractionOverview = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramInteractionOverviewArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramInteractionOverviewArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramInteractionOverview };
};
