import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Input, InputProps, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

const PLACEHOLDERS = ['#ProductName', '#ProductCategory', '#CompanyName'];

export interface HashTagFormProps extends Omit<InputProps, 'onChange' | 'value' | 'defaultValue'> {
  size?: number;
  value?: string[];
  maxHashtags?: number;
  onChange?: (values: string[]) => void;
}

export const HashTagForm = ({
  size = 3,
  disabled,
  className,
  hasError,
  onChange,
  value = [],
  maxHashtags = 9,
  ...restProps
}: HashTagFormProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const [hashtag, setHashtag] = useState('');
  const [hashTagNum, setHashtagNum] = useState(size);

  useEffect(() => {
    setHashtagNum(value.length < size ? size : value.length);
  }, [value.length, size]);

  const handleRemove = (index: number) => {
    const newHashtags = [...value];
    newHashtags.splice(index, 1);

    onChange?.(newHashtags);
  };

  const handleAdd = () => {
    const newHashtags = [...value];
    const emptyIndex = newHashtags.findIndex((v) => !v);
    const newInput = ref.current?.value;

    if (!newInput) return;

    if (emptyIndex !== -1) {
      newHashtags[emptyIndex] = newInput;
      onChange?.(newHashtags);
    } else {
      onChange?.([...newHashtags, newInput]);
    }

    setHashtag('');
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // enter
    if (e.key === 'Enter') {
      handleAdd();
    }
  };

  return (
    <div className={className}>
      <div css={{ display: 'flex' }}>
        <Input
          ref={ref}
          value={hashtag}
          onKeyPress={handleKeypress}
          disabled={disabled || value.length >= maxHashtags}
          onChange={(e) => setHashtag(e.currentTarget.value)}
          {...restProps}
        />
        <Button
          type="button"
          variant="black"
          onClick={handleAdd}
          title={t('Button.Add Hash Tag')}
          disabled={disabled || value.length >= maxHashtags || hashtag === ''}
          css={{ minWidth: '120px', marginLeft: '8px', textTransform: 'uppercase' }}
        />
      </div>

      {size > 0 ? (
        <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {[...Array(hashTagNum)].map((_, index) => {
            const hasValue = !!value[index];
            const placeholder = PLACEHOLDERS[index % PLACEHOLDERS.length];

            return (
              <div css={styles.selectedTagWrapper(hasError, hasValue)} key={index}>
                <TextCutter text={value[index] || placeholder} />

                {!disabled && hasValue ? (
                  <Icon
                    size={9}
                    icon="close"
                    color="#3b4146"
                    onClick={() => handleRemove(index)}
                    css={{ marginLeft: '4px', cursor: 'pointer' }}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
const styles = {
  selectedTagWrapper: (hasError?: boolean, hasValue?: boolean) =>
    css({
      width: '100%',
      height: '32px',
      display: 'flex',
      fontSize: '12px',
      padding: '0 12px',
      marginTop: '12px',
      lineHeight: '100%',
      borderRadius: '3px',
      alignItems: 'center',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      color: hasValue ? '#3b4146' : '#d5d5d5',
      backgroundColor: hasValue ? THEME.background.colors.gray.lv1 : THEME.background.colors.white,
      border: `1px ${hasValue ? 'solid' : 'dashed'} ${hasError ? THEME.input.border.colors.error : THEME.input.border.colors.default}`
    })
};
