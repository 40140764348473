import { InstagramAnalyticsPostsInDate } from '@/graphql';
import {
  InstagramPostInDateFeedPostDetails,
  InstagramPostInDateReelPostDetails,
  InstagramPostInDateStoryPostDetails
} from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

enum InstagramPostInDateDetailsType {
  feedPost = 'feedPost',
  reelPost = 'reelPost',
  storyPost = 'storyPost'
}

export interface InstagramPostInDateDetailsModalProps {
  paramKey?: string;
  data: InstagramAnalyticsPostsInDate | null;
}

export const InstagramPostInDateDetailsModal = ({ data, paramKey }: InstagramPostInDateDetailsModalProps) => {
  const posts = [
    ...(data?.feedPosts?.map((post) => ({ ...post, type: InstagramPostInDateDetailsType.feedPost })) || []),
    ...(data?.storyPosts?.map((post) => ({ ...post, type: InstagramPostInDateDetailsType.storyPost })) || []),
    ...(data?.reelPosts?.map((post) => ({ ...post, type: InstagramPostInDateDetailsType.reelPost })) || [])
  ];

  return (
    <CarouselTemplateModal posts={posts} paramKey={paramKey}>
      {({ selectedPost, closeModal }) =>
        selectedPost.type === InstagramPostInDateDetailsType.feedPost ? (
          <InstagramPostInDateFeedPostDetails
            onCloseModal={closeModal}
            data={data?.feedPosts?.find((post) => post.id === selectedPost.id)}
          />
        ) : selectedPost.type === InstagramPostInDateDetailsType.storyPost ? (
          <InstagramPostInDateStoryPostDetails
            onCloseModal={closeModal}
            data={data?.storyPosts?.find((post) => post.id === selectedPost.id)}
          />
        ) : selectedPost.type === InstagramPostInDateDetailsType.reelPost ? (
          <InstagramPostInDateReelPostDetails
            onCloseModal={closeModal}
            data={data?.reelPosts?.find((post) => post.id === selectedPost.id)}
          />
        ) : null
      }
    </CarouselTemplateModal>
  );
};
