import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdatePartnerAgency.graphql';
import { Mutation, MutationupdatePartnerAgencyArgs } from '../../__generated__/globalTypes';

export const useUpdatePartnerAgencyMutation = (
  options?: MutationHookOptions<Mutation, MutationupdatePartnerAgencyArgs>
) => {
  const [callUpdatePartnerAgency, result] = useMutation<Mutation, MutationupdatePartnerAgencyArgs>(MUTATION, options);

  return { callUpdatePartnerAgency, ...result };
};

export type UpdatePartnerAgencyMutationFunction = Mutation['updatePartnerAgency'];
