import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateStaffUser.graphql';
import { Mutation, MutationupdateStaffUserArgs } from '../../__generated__/globalTypes';

export const useUpdateStaffUserMutation = (options?: MutationHookOptions<Mutation, MutationupdateStaffUserArgs>) => {
  const [callUpdateStaffUser, result] = useMutation<Mutation, MutationupdateStaffUserArgs>(MUTATION, options);

  return { callUpdateStaffUser, ...result };
};

export type UpdateStaffUserMutationFunction = Mutation['updateStaffUser'];
