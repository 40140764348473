import { z } from 'zod';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

const MarketplacesReorderFilterSchema = z.object({ countryId: FilterValidations.string() });
export const MarketplacesReorderSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(MarketplacesReorderFilterSchema, { countryId: '' }),
  ...SearchFilterDefaults.pageLimit()
});

export type MarketplacesReorderSearchSchemaType = z.infer<typeof MarketplacesReorderSearchSchema>;
export type MarketplacesReorderValues = z.infer<typeof MarketplacesReorderFilterSchema>;
