import { css } from '@emotion/react';
import { Navigate, Outlet } from '@tanstack/react-router';
import { BasicAccountAnalyticsPayload } from '@/graphql';
import { useMatchPaths, useQueryHelper } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import {
  AnalyticsTabs,
  AnalyticsHeader,
  AnalyticsFreePlanNotice,
  AnalyticsNeedReconnectNotice
} from '@/shared/organisms';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';
import { THEME } from '@/shared/constants';
import { AnalyticsProfileProvider } from './AnalyticsProfileContext';
import { getAnalyticsBasicSelectedAccountInfo } from './utils';

interface TemplateProps {
  socialMediaAccountId: number;
  brandAccount: BasicAccountAnalyticsPayload;
  socialMedia: AnalyticsSupportedSocialMediaType;
}

export const Template = ({ socialMedia, brandAccount, socialMediaAccountId }: TemplateProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  // matched list pages, we don't need to show AnalyticsHeader on them
  const { matched } = useMatchPaths([
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post',
    '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts'
  ]);

  const selectedSocialAccount = (() => {
    try {
      return getAnalyticsBasicSelectedAccountInfo({
        socialMedia,
        socialMediaAccountId,
        socialMediaAccounts: brandAccount.socialMediaAccounts
      });
    } catch (error) {
      enqueueSnackbar(t(error), { variant: 'error' });
    }
  })();

  if (!selectedSocialAccount) {
    return <Navigate to="/analytics" />;
  }

  return matched ? (
    <AnalyticsProfileProvider value={{ brandAccount, selectedSocialAccount }} isChildList>
      <Header />
      <Outlet />
    </AnalyticsProfileProvider>
  ) : (
    <AnalyticsProfileProvider value={{ brandAccount, selectedSocialAccount }}>
      <div css={styles.analyticsStickyHeader}>
        <AnalyticsFreePlanNotice subscriptionPlan={brandAccount.subscriptionPlan.type} />

        <div css={styles.analyticsHeaderWrapper}>
          <AnalyticsHeader brandAccount={brandAccount} analyticsAccount={selectedSocialAccount} />
          <AnalyticsTabs brandAccount={brandAccount} analyticsAccount={selectedSocialAccount} />
        </div>
      </div>
      {/* NOTE: "position: relative here is for Trial/Free demo modals */}
      <div css={{ marginTop: '24px', position: 'relative' }}>
        <AnalyticsNeedReconnectNotice analyticsAccount={selectedSocialAccount} />
        <Outlet />
      </div>
    </AnalyticsProfileProvider>
  );
};
const styles = {
  analyticsStickyHeader: css({
    top: 0,
    zIndex: 9,
    position: 'sticky'
  }),
  analyticsHeaderWrapper: css({
    height: THEME.container.analytics.header.height,
    borderRadius: '3px',
    boxSizing: 'border-box',
    filter: 'drop-shadow(0px 0px 2px rgba(110, 124, 137, 0.25)) drop-shadow(0px 2px 1px rgba(110, 124, 137, 0.05))'
  })
};
