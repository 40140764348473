import { useAgencyPermissions } from '@/auth';
import { namedOperations, useDeleteAgencyMutation } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { TableData } from '@/shared/types';
import { AccountType, AccountsList, DeleteAccountType } from '../../Accounts';

export type AgenciesListProps = TableData<AccountType>;

export const AgenciesList = (props: AgenciesListProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callDeleteAgency } = useDeleteAgencyMutation({ refetchQueries: [namedOperations.Query.AllAgencies] });
  const { hideEditAgencyBtn, hideAddAgencyBtn, hideDeleteAgencyBtn } = useAgencyPermissions();

  const handleDeleteAccount = async (account: DeleteAccountType) => {
    try {
      await callDeleteAgency({ variables: { id: account.id } });
      enqueueSnackbar(t('succeededInDeleting', { name: account.name }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: account.name }), { variant: 'error' });
    }
  };

  return (
    <AccountsList
      {...props}
      domain="agency"
      firstColumnTitle={t('HeaderColumn.Agency Name')}
      editRoute={!hideEditAgencyBtn ? '/agency/$id' : undefined}
      onDeleteAccount={!hideDeleteAgencyBtn ? handleDeleteAccount : undefined}
      empty={{
        linkProps: !hideAddAgencyBtn ? { to: '/agency/add', label: t('Add an Agency') } : undefined
      }}
    />
  );
};
