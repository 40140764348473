import { InfluencersFilterWrapper } from '../shared';
import { InfluencerPackagesProps } from './schemaTypes';
import { InfluencerPackagesList } from './InfluencerPackagesList';
import { InfluencerPackagesSort } from './InfluencerPackagesSort';
import { InfluencerPackagesFilter } from './InfluencerPackagesFilter';
import { InfluencerPackagesProvider } from './InfluencerPackagesContext';

export const InfluencerPackages = (props: InfluencerPackagesProps) => (
  <InfluencerPackagesProvider value={props}>
    <InfluencersFilterWrapper>
      <InfluencerPackagesFilter />
      <InfluencerPackagesSort />
    </InfluencersFilterWrapper>

    <InfluencerPackagesList />
  </InfluencerPackagesProvider>
);

export * from './schemaTypes';
