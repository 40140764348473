import { useTranslation } from 'react-i18next';
import { Option } from '@/shared/types';
import { ColumnProps } from '@/shared/atoms';
import { ColumnCustomiser, usePostReportSearch } from '@/shared/molecules';
import { CampaignType, CampaignSocialMediaType, CampaignReportOrderField, MarketplaceCampaignReport } from '@/graphql';
import { PostReportTableStatKeys } from '../types';
import { usePostReportContext } from '../../PostReportContext';
import { EngagementCampaignTrafficSource } from '../../../EngagementCampaigns';
import { DEFAULT_SELECTED_COLUMNS, POST_REPORT_STICKY_COL_DIMS } from '../utils';

type CustomColumnProps = ColumnProps<CampaignReportOrderField, PostReportTableStatKeys>;

interface Props {
  data: MarketplaceCampaignReport;
}

export const usePostReportColumns = ({
  data: {
    postCount,
    campaignInfo: { id, socialMedias, hasTrafficSource }
  }
}: Props) => {
  const { t } = useTranslation();
  const { filter } = usePostReportSearch();
  const { hasTracking, campaignType, selectedColumns, isOnlyTikTokTtcm, setSelectedColumns } = usePostReportContext();

  const getMetricColumnProps = ({ key, hidden, styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    key,
    styles: { textAlign: key !== 'insightDataAcquisition' ? 'right' : 'left', ...styles },
    hidden: hidden || (!!key && !selectedColumns.includes(key))
  });

  const isEngagement = campaignType === CampaignType.ENGAGEMENT;
  const hasXhs = socialMedias.includes(CampaignSocialMediaType.XHS);
  const hasTiktok = socialMedias.includes(CampaignSocialMediaType.TIKTOK);
  const hasDouyin = socialMedias.includes(CampaignSocialMediaType.DOUYIN);
  const hasThreads = socialMedias.includes(CampaignSocialMediaType.THREADS);
  const hasTwitter = socialMedias.includes(CampaignSocialMediaType.TWITTER);
  const hasYoutube = socialMedias.includes(CampaignSocialMediaType.YOUTUBE);
  const hasFacebook = socialMedias.includes(CampaignSocialMediaType.FACEBOOK);
  const hasInstagram =
    socialMedias.includes(CampaignSocialMediaType.INSTAGRAM) ||
    socialMedias.includes(CampaignSocialMediaType.INSTAGRAM_STORY);
  const hasIgOrThreads = hasInstagram || hasThreads;
  const hasOnlyTiktok = socialMedias.length === 1 && hasTiktok;
  const hasOnlyTwitter = socialMedias.length === 1 && hasTwitter;
  const hasOnlyInstagram = socialMedias.length === 1 && hasInstagram;
  const hasFbReactions = socialMedias[0] === CampaignSocialMediaType.FACEBOOK;
  const allReportMetrics: CustomColumnProps[] = [
    {
      key: 'cost',
      help: t<string>('Tooltip.costHelp'),
      title: t('HeaderColumn.Cost'),
      sortField: CampaignReportOrderField.COST
    },
    {
      key: 'followers',
      help: t<string>('Tooltip.UniqueReachHelp'),
      title: t('HeaderColumn.Follower'),
      sortField: CampaignReportOrderField.FOLLOWERS
    },
    {
      hidden: !isEngagement || !hasInstagram,
      key: 'followerReach',
      help: t<string>('Tooltip.followerReachHelp'),
      title: t('HeaderColumn.Follower Reach'),
      sortField: CampaignReportOrderField.FOLLOWER_REACH
    },
    {
      hidden: !isEngagement || !hasInstagram,
      key: 'followerReachRate',
      help: t<string>('Tooltip.followerReachRateHelp'),
      title: t('HeaderColumn.Follower Reach Rate'),
      sortField: CampaignReportOrderField.FOLLOWER_REACH_RATE
    },
    {
      hidden: !isEngagement || !hasTwitter,
      key: 'newFollowers',
      help: t<string>('Tooltip.newFollowersHelp'),
      title: t('HeaderColumn.New followers'),
      sortField: CampaignReportOrderField.NEW_FOLLOWERS
    },
    {
      key: 'impressions',
      hidden: isOnlyTikTokTtcm || (isEngagement && hasOnlyTiktok),
      help: t<string>('Tooltip.impressionsHelp'),
      title: t('HeaderColumn.Impression'),
      sortField: CampaignReportOrderField.IMPRESSIONS
    },
    {
      key: 'impressionRate',
      help: t<string>('Tooltip.impressionsRateHelp'),
      hidden: isEngagement && hasOnlyTiktok,
      title: t('HeaderColumn.Impression Rate'),
      sortField: CampaignReportOrderField.IMPRESSION_RATE
    },
    {
      hidden: !isEngagement || !hasIgOrThreads,
      key: 'impressionsFromDiscovery',
      help: t<string>('Tooltip.impressionsFromDiscoveryHelp'),
      title: t('HeaderColumn.Impression from Discovery'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_DISCOVERY
    },
    {
      hidden: !isEngagement || !hasIgOrThreads,
      key: 'impressionsFromHashtag',
      help: t<string>('Tooltip.impressionsFromHashtagHelp'),
      title: t('HeaderColumn.Impression from Hashtag'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_HASHTAG
    },
    {
      hidden: isEngagement && !hasIgOrThreads,
      key: 'impressionsFromHome',
      help: t<string>('Tooltip.impressionsFromHomeHelp'),
      title: t('HeaderColumn.Impression from Home'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_HOME
    },
    {
      hidden: isEngagement && !hasIgOrThreads,
      key: 'impressionsFromProfile',
      help: t<string>('Tooltip.impressionsFromProfileHelp'),
      title: t('HeaderColumn.Impression from Profile'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_PROFILE
    },
    {
      hidden: isEngagement && !hasIgOrThreads,
      key: 'impressionsFromOther',
      help: t<string>('Tooltip.impressionsFromOtherHelp'),
      title: t('HeaderColumn.Impression from Other'),
      sortField: CampaignReportOrderField.IMPRESSIONS_FROM_OTHER
    },
    {
      key: 'totalPlayTime',
      hidden: !isOnlyTikTokTtcm,
      help: t<string>('Tooltip.totalPlayTimeHelp'),
      title: t('HeaderColumn.Total play time'),
      sortField: CampaignReportOrderField.TOTAL_PLAY_TIME
    },
    {
      key: 'averageViewTime',
      hidden: !isOnlyTikTokTtcm,
      help: t<string>('Tooltip.averageViewTimeHelp'),
      title: t('HeaderColumn.Average view time'),
      sortField: CampaignReportOrderField.AVERAGE_VIEW_TIME
    },
    {
      key: 'videoCompletionRate',
      hidden: !isOnlyTikTokTtcm,
      help: t<string>('Tooltip.videoCompletionRateHelp'),
      title: t('HeaderColumn.Video completion rate'),
      sortField: CampaignReportOrderField.VIDEO_COMPLETION_RATE
    },
    {
      key: 'view',
      help: t<string>(isEngagement && (hasInstagram || hasXhs) ? 'Tooltip.ViewHelp' : 'Tooltip.videoViewHelp'),
      title: t(isEngagement && (hasOnlyInstagram || hasXhs) ? 'HeaderColumn.View' : 'HeaderColumn.Video view'),
      sortField: CampaignReportOrderField.VIEW
    },
    {
      hidden: !isEngagement || !(hasYoutube || hasThreads),
      key: 'averageViewDurationMinutes',
      help: t<string>('Tooltip.averageViewDurationMinutesHelp'),
      title: t('HeaderColumn.Average View Duration (m)'),
      sortField: CampaignReportOrderField.AVERAGE_VIEW_DURATION_MINUTES
    },
    {
      hidden: !isEngagement || !(hasYoutube || hasThreads),
      key: 'averageViewDurationPercentage',
      help: t<string>('Tooltip.averageViewDurationPercentageHelp'),
      title: t('HeaderColumn.Average View Duration Rate'),
      sortField: CampaignReportOrderField.AVERAGE_VIEW_DURATION_PERCENTAGE
    },
    {
      key: 'viewsFromHome',
      hidden: !isEngagement || !hasXhs,
      title: t('HeaderColumn.Views from Home'),
      help: t<string>('Tooltip.viewsFromHomeHelp'),
      sortField: CampaignReportOrderField.VIEWS_FROM_HOME
    },
    {
      key: 'viewsFromProfile',
      hidden: !isEngagement || !hasXhs,
      title: t('HeaderColumn.Views from Profile'),
      help: t<string>('Tooltip.viewsFromProfileHelp'),
      sortField: CampaignReportOrderField.VIEWS_FROM_PROFILE
    },
    {
      key: 'viewsFromSearch',
      hidden: !isEngagement || !hasXhs,
      title: t('HeaderColumn.Views from Search'),
      help: t<string>('Tooltip.viewsFromSearchHelp'),
      sortField: CampaignReportOrderField.VIEWS_FROM_SEARCH
    },
    {
      key: 'viewsFromFollowing',
      hidden: !isEngagement || !hasXhs,
      title: t('HeaderColumn.Views from Following'),
      help: t<string>('Tooltip.viewsFromFollowingHelp'),
      sortField: CampaignReportOrderField.VIEWS_FROM_FOLLOWING
    },
    {
      key: 'viewsFromOther',
      hidden: !isEngagement || !hasXhs,
      title: t('HeaderColumn.Views from Other'),
      help: t<string>('Tooltip.viewsFromOtherHelp'),
      sortField: CampaignReportOrderField.VIEWS_FROM_OTHER
    },
    {
      hidden: isEngagement && !(hasIgOrThreads || hasYoutube || hasFacebook),
      key: 'reach',
      help: t<string>('Tooltip.reachHelp'),
      title: t('HeaderColumn.Reach'),
      sortField: CampaignReportOrderField.REACH
    },
    {
      hidden: isEngagement && !(hasIgOrThreads || hasYoutube || hasFacebook),
      key: 'reachRate',
      help: t<string>('Tooltip.reachRateHelp'),
      title: t('HeaderColumn.Reach Rate'),
      sortField: CampaignReportOrderField.REACH_RATE
    },
    {
      hidden: !isEngagement || !hasIgOrThreads,
      key: 'nonFollowerReach',
      help: t<string>('Tooltip.nonFollowerReachHelp'),
      title: t('HeaderColumn.Non Follower Reach'),
      sortField: CampaignReportOrderField.NON_FOLLOWER_REACH
    },
    {
      hidden: !isEngagement || !hasIgOrThreads,
      key: 'nonFollowerReachRate',
      help: t<string>('Tooltip.nonFollowerReachRateHelp'),
      title: t('HeaderColumn.Non Follower Reach Rate'),
      sortField: CampaignReportOrderField.NON_FOLLOWER_REACH_RATE
    },
    {
      key: 'engagement',
      help: t<string>('Tooltip.TotalEngagementsHelp'),
      title: t('HeaderColumn.Engagement'),
      sortField: CampaignReportOrderField.ENGAGEMENT
    },
    {
      key: 'engagementRate',
      help: t<string>('Tooltip.engagementRateHelp'),
      title: t('HeaderColumn.Egg Rate by followers'),
      sortField: CampaignReportOrderField.ENGAGEMENT_RATE
    },
    {
      key: 'engagementViewsRate',
      help: t<string>('Tooltip.engagementViewsRateHelp'),
      hidden: !isEngagement && !(hasYoutube || hasTiktok || hasDouyin),
      title: t('HeaderColumn.Egg Rate by views'),
      sortField: CampaignReportOrderField.ENGAGEMENT_VIEWS_RATE
    },
    {
      key: 'like',
      help: t<string>('Tooltip.LikeReactionHelp'),
      sortField: CampaignReportOrderField.LIKE,
      title: t(hasFbReactions ? 'HeaderColumn.Reactions facebook' : 'HeaderColumn.Like')
    },
    {
      key: 'dislikes',
      help: t<string>('Tooltip.dislikesHelp'),
      hidden: !isEngagement || !hasThreads,
      title: t('HeaderColumn.Dislike'),
      sortField: CampaignReportOrderField.DISLIKES
    },
    {
      key: 'comment',
      help: t<string>('Tooltip.commentHelp'),
      title: t(
        socialMedias.length === 1 &&
          [CampaignSocialMediaType.INSTAGRAM_STORY, CampaignSocialMediaType.THREADS].includes(
            socialMedias.at(0) as CampaignSocialMediaType
          )
          ? 'HeaderColumn.Replies'
          : 'HeaderColumn.Comment'
      ),
      sortField: CampaignReportOrderField.COMMENT
    },
    {
      key: 'share',
      help: t<string>('Tooltip.shareHelp'),
      title: t('HeaderColumn.Share'),
      sortField: CampaignReportOrderField.SHARE
    },
    {
      key: 'retweets',
      help: t<string>('Tooltip.retweetHelp'),
      hidden: isEngagement && !(hasThreads || hasTwitter || hasYoutube || hasFacebook),
      title: t(isEngagement && hasOnlyTwitter ? 'HeaderColumn.Retweets Twitter' : 'HeaderColumn.Retweets'), // Twitter with X in translation
      sortField: CampaignReportOrderField.SHARE
    },
    {
      key: 'saved',
      help: t<string>('Tooltip.SaveHelp'),
      title: t('HeaderColumn.Save'),
      sortField: CampaignReportOrderField.SAVED
    },
    {
      key: 'interaction',
      hidden: !isEngagement || !hasIgOrThreads,
      help: t<string>('Tooltip.interactionHelp'),
      title: t(isEngagement && hasOnlyInstagram ? 'HeaderColumn.Reel interaction' : 'HeaderColumn.Interaction'),
      sortField: CampaignReportOrderField.INTERACTION
    },
    {
      key: 'linkClicks',
      hidden: !isEngagement || !(hasThreads || hasFacebook || hasYoutube),
      help: t<string>('Tooltip.linkClicksHelp'),
      title: t('HeaderColumn.Link Clicks'),
      sortField: CampaignReportOrderField.LINK_CLICKS
    },
    {
      key: 'stickerTaps',
      hidden: !isEngagement || !hasThreads,
      help: t<string>('Tooltip.stickerTapsHelp'),
      title: t('HeaderColumn.Sticker Taps'),
      sortField: CampaignReportOrderField.STICKER_TAPS
    },
    {
      key: 'click',
      help: t<string>('Tooltip.Click Action Help'),
      title: t('HeaderColumn.Click'),
      sortField: CampaignReportOrderField.CLICK
    },
    {
      key: 'conversion',
      hidden: !hasTracking,
      help: t<string>('Tooltip.Click Conversion Help'),
      title: t('HeaderColumn.Conversion'),
      sortField: CampaignReportOrderField.CONVERSION
    },
    {
      key: 'sales',
      hidden: !hasTracking || isOnlyTikTokTtcm,
      title: t('HeaderColumn.Sales'),
      sortField: CampaignReportOrderField.SALES
    },
    {
      key: 'femalePercentage',
      hidden: !isOnlyTikTokTtcm,
      title: t('HeaderColumn.Female Rate'),
      sortField: CampaignReportOrderField.FEMALE_PERCENTAGE
    },
    {
      key: 'malePercentage',
      hidden: !isOnlyTikTokTtcm,
      title: t('HeaderColumn.Male Rate'),
      sortField: CampaignReportOrderField.MALE_PERCENTAGE
    },
    {
      key: 'age1824Percentage',
      hidden: !isOnlyTikTokTtcm,
      title: t('HeaderColumn.Age (18-24)'),
      sortField: CampaignReportOrderField.AGE_18_24_PERCENTAGE
    },
    {
      key: 'age2534Percentage',
      hidden: !isOnlyTikTokTtcm,
      title: t('HeaderColumn.Age (25-34)'),
      sortField: CampaignReportOrderField.AGE_25_34_PERCENTAGE
    },
    {
      key: 'age35UpPercentage',
      hidden: !isOnlyTikTokTtcm,
      title: t('HeaderColumn.Age (35+)'),
      sortField: CampaignReportOrderField.AGE_35_UP_PERCENTAGE
    },
    {
      key: 'costsPerSale',
      hidden: isOnlyTikTokTtcm,
      title: t('HeaderColumn.Commission rate'),
      sortField: CampaignReportOrderField.COST_PER_SALE
    },
    {
      key: 'costsPerFollower',
      title: t('HeaderColumn.Cost / Follower'),
      sortField: CampaignReportOrderField.COST_PER_FOLLOWER
    },
    {
      key: 'costsPerImpressions',
      title: t('HeaderColumn.Cost / Impression'),
      sortField: CampaignReportOrderField.COST_PER_IMPRESSIONS
    },
    {
      key: 'costsPerView',
      title: t('HeaderColumn.Cost / Video view'),
      sortField: CampaignReportOrderField.COST_PER_VIEW
    },
    {
      hidden: isEngagement && !hasIgOrThreads,
      key: 'costsPerReach',
      title: t('HeaderColumn.Cost / Reach'),
      sortField: CampaignReportOrderField.COST_PER_REACH
    },
    {
      key: 'costsPerEngagement',
      title: t('HeaderColumn.Cost / Engagement'),
      sortField: CampaignReportOrderField.COST_PER_ENGAGEMENT
    },
    {
      key: 'costsPerLike',
      sortField: CampaignReportOrderField.COST_PER_LIKE,
      title: t(hasFbReactions ? 'HeaderColumn.Cost / Reaction' : 'HeaderColumn.Cost / Like')
    },
    {
      key: 'costsPerComment',
      title: t('HeaderColumn.Cost / Comment'),
      sortField: CampaignReportOrderField.COST_PER_COMMENT
    },
    {
      key: 'costsPerShare',
      title: t('HeaderColumn.Cost / Share'),
      sortField: CampaignReportOrderField.COST_PER_SHARE
    },
    {
      key: 'costsPerSaved',
      title: t('HeaderColumn.Cost / Save'),
      sortField: CampaignReportOrderField.COST_PER_SAVED
    },
    {
      key: 'costsPerClick',
      help: t<string>('Tooltip.CPC Help'),
      title: t('HeaderColumn.CPC'),
      sortField: CampaignReportOrderField.COST_PER_CLICK
    },
    {
      key: 'costsPerAction',
      help: t<string>('Tooltip.CPA Help'),
      title: t('HeaderColumn.CPA'),
      sortField: CampaignReportOrderField.COST_PER_ACTION
    },
    {
      key: 'back',
      help: t<string>('Tooltip.backHelp'),
      title: t('HeaderColumn.Back'),
      hidden: isEngagement && !hasThreads,
      sortField: CampaignReportOrderField.BACK
    },
    {
      key: 'exited',
      help: t<string>('Tooltip.exitedHelp'),
      hidden: isEngagement && !hasIgOrThreads,
      title: t('HeaderColumn.Exited'),
      sortField: CampaignReportOrderField.EXITED
    },
    {
      key: 'forward',
      help: t<string>('Tooltip.forwardHelp'),
      title: t('HeaderColumn.Forward'),
      sortField: CampaignReportOrderField.FORWARD
    },
    {
      key: 'nextStory',
      help: t<string>('Tooltip.nextStoryHelp'),
      hidden: isEngagement && !hasIgOrThreads,
      title: t('HeaderColumn.Next Story'),
      sortField: CampaignReportOrderField.NEXT_STORY
    },
    {
      key: 'navigation',
      help: t<string>('Tooltip.navigationHelp'),
      hidden: isEngagement && !hasIgOrThreads,
      title: t('HeaderColumn.Navigation'),
      sortField: CampaignReportOrderField.NAVIGATION
    },
    {
      key: 'profileActivity',
      help: t<string>('Tooltip.profileActivityHelp'),
      hidden: isEngagement && !(hasIgOrThreads || hasTwitter),
      title: t(isEngagement && hasOnlyTwitter ? 'HeaderColumn.Profile access' : 'HeaderColumn.Profile Activity'),
      sortField: CampaignReportOrderField.PROFILE_ACTIVITY
    },
    {
      key: 'insightDataAcquisition',
      help: t<string>('Tooltip.insightAcquisitionDateHelp'),
      hidden: !isEngagement,
      title: t('HeaderColumn.Insight acquisition date')
    }
  ];
  const metricColumns: CustomColumnProps[] = allReportMetrics.map(getMetricColumnProps);
  const selectableColumns = allReportMetrics.reduce<Option<PostReportTableStatKeys>[]>(
    (acc, curr) =>
      curr.hidden ? acc : [...acc, { label: curr.title as string, value: curr.key as PostReportTableStatKeys }],
    []
  );

  const handleSelectColumns = (newSelectedColumns: PostReportTableStatKeys[]) => {
    const orderedSelectedColumns = metricColumns.reduce<PostReportTableStatKeys[]>(
      (results, metric) => (metric.key && newSelectedColumns.includes(metric.key) ? [...results, metric.key] : results),
      []
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const postColumn: CustomColumnProps = {
    title: (
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {t('HeaderColumn.Total Post')}

        {hasTrafficSource ? (
          <EngagementCampaignTrafficSource css={{ margin: '0 8px 0 auto' }} campaignId={id} filter={filter} />
        ) : null}

        <ColumnCustomiser<PostReportTableStatKeys>
          options={selectableColumns}
          defaultOptions={selectedColumns}
          savedValuesKey="reportTableColumns"
          initialValues={DEFAULT_SELECTED_COLUMNS}
          setSelectedColumns={handleSelectColumns}
          position={postCount < 6 ? 'right-end' : 'right-start'}
        />
      </div>
    ),
    sticky: { active: true, hasBorderRight: true },
    styles: { width: POST_REPORT_STICKY_COL_DIMS.totalWidth }
  };

  return { columns: [postColumn, ...metricColumns] };
};
