import { ReactNode } from 'react';

import { useSortableItemContext } from './SortableItemContext';

export interface SortableDragAreaProps {
  className?: string;
  children?: ReactNode;
}
export const SortableDragArea = ({ className, children }: SortableDragAreaProps) => {
  const { attributes, listeners, ref } = useSortableItemContext();

  return (
    <div css={{ outline: 'none' }} className={className} {...attributes} {...listeners} ref={ref}>
      {children}
    </div>
  );
};
