import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeCmsConfirmedChannelsSummary.graphql';
import { Query, QueryyoutubeCmsConfirmedChannelsSummaryArgs } from '../../__generated__/globalTypes';

export const useYoutubeCmsConfirmedChannelsSummaryQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeCmsConfirmedChannelsSummaryArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeCmsConfirmedChannelsSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeCmsConfirmedChannelsSummaryLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeCmsConfirmedChannelsSummaryArgs>
): QueryResult<Query, QueryyoutubeCmsConfirmedChannelsSummaryArgs> & {
  getYoutubeCmsConfirmedChannelsSummary: LazyQueryExecFunction<Query, QueryyoutubeCmsConfirmedChannelsSummaryArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeCmsConfirmedChannelsSummary, result] = useLazyQuery<
    Query,
    QueryyoutubeCmsConfirmedChannelsSummaryArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeCmsConfirmedChannelsSummary, ...result };
};

export const useYoutubeCmsConfirmedChannelsSummaryPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeCmsConfirmedChannelsSummary = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeCmsConfirmedChannelsSummaryArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeCmsConfirmedChannelsSummaryArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeCmsConfirmedChannelsSummary };
};
