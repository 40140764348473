import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FollowersGrowthPeriodEnum } from '@/graphql';
import { LIMIT } from '@/shared/constants';

export enum TrendingInfluencersTabType {
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM'
}

export const TrendingInfluencersFilterSchema = z.object({
  countryId: z.string().default('JP'),
  minFollowers: z.string().default('10000'),
  categoryIds: z.array(z.string()).default([]),
  tab: z.nativeEnum(TrendingInfluencersTabType).default(TrendingInfluencersTabType.INSTAGRAM),
  followerGrowthPeriod: z.nativeEnum(FollowersGrowthPeriodEnum).default(FollowersGrowthPeriodEnum.ONE_MONTH)
});
export const TrendingInfluencersSearchFilterSchema = z.object({
  filter: TrendingInfluencersFilterSchema.default({}),
  limit: z.number().default(LIMIT),
  page: z.number().default(1)
});

export type TrendingInfluencersSearchFilterSchemaType = z.infer<typeof TrendingInfluencersSearchFilterSchema>;
export type TrendingInfluencersFilterFormValues = z.infer<typeof TrendingInfluencersFilterSchema>;
export type TrendingInfluencersFilterFormKeys = UnionOfKeys<TrendingInfluencersFilterFormValues>;
