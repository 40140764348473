import { useUpdateTalentAgencyMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { TalentAgencyForm, TalentAgencyFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';
import { floatify } from '@/shared/utils';
import { useTalentAgencyDetailsContext } from './TalentAgencyDetailsContext';

export const EditTalentAgency = () => {
  const { talentAgency } = useTalentAgencyDetailsContext();
  const { callUpdateTalentAgency } = useUpdateTalentAgencyMutation();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();

  const handleSubmit: FormAction<TalentAgencyFormValues>['onSubmit'] = async ({
    countryId,
    agencyIds,
    companyUrl,
    netsuiteId,
    phoneNumber,
    advertiserIds,
    talentAgencyName,
    ...restProps
  }) => {
    try {
      const isEdit = restProps.type === 'edit';

      await callUpdateTalentAgency({
        variables: {
          input: {
            countryId,
            talentAgencyName,
            id: talentAgency.id,
            netsuiteId: netsuiteId || null,
            companyUrl: companyUrl || null,
            phoneNumber: phoneNumber || null,
            agencyIds: agencyIds.map(Number),
            advertiserIds: advertiserIds.map(Number),
            cmsMarginRate: isEdit && restProps.cmsMarginRate ? Number(restProps.cmsMarginRate) / 100 : null,
            privateInfluencersIds: isEdit ? restProps.privateInfluencersIds.map((el) => Number(el.value)) : []
          }
        }
      });
      enqueueSnackbar(t('succeededInUpdating', { name: talentAgencyName }), { variant: 'success' });
      navigate({ to: '/talent-agency' });
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: talentAgencyName }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <TalentAgencyForm
      defaultValues={{
        type: 'edit',
        id: talentAgency.id,
        countryId: talentAgency.country?.id,
        talentAgencyName: talentAgency.name,
        companyUrl: talentAgency.companyUrl || '',
        netsuiteId: talentAgency.netsuiteId || '',
        phoneNumber: talentAgency.phoneNumber || '',
        agencyIds: talentAgency.agencyIds.map(String),
        advertiserIds: talentAgency.advertiserIds.map(String),
        privateInfluencersIds: talentAgency.privateInfluencers.map((infl) => ({
          value: infl.id.toString(),
          label: infl.name
        })),
        cmsMarginRate:
          typeof talentAgency.cmsMarginRate === 'number' ? floatify(talentAgency?.cmsMarginRate * 100).toString() : ''
      }}
      onSubmit={handleSubmit}
    />
  );
};
