import { useTranslation } from 'react-i18next';
import { TextSearchField, BasicFilterWrapper, SingleMonthPickerField } from '@/shared/molecules';
import { InvoicesFilterFormKeys } from './schemaTypes';

interface BasicFilterProps {
  onSubmit?: () => void;
}

export const BasicFilter = ({ onSubmit }: BasicFilterProps) => {
  const { t } = useTranslation();

  return (
    <BasicFilterWrapper css={{ padding: '16px', borderRadius: '3px 3px 0 0' }}>
      <TextSearchField<InvoicesFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.Invoices')}
      />
      <SingleMonthPickerField<InvoicesFilterFormKeys>
        name="month"
        clearable={false}
        onSubmit={onSubmit}
        css={{ maxWidth: '184px' }}
      />
    </BasicFilterWrapper>
  );
};
