import { usePermissions } from './usePermissions';
import { useUserRoles } from './useUserRoles';

const defaultPermissions = {
  hideApprovePostBtn: true,
  hideRejectInappropriatePostBtn: true
};
/**
 * @description we don't have a real permission model yet, but should have in the future
 * https://docs.google.com/spreadsheets/d/1xk4KdwnTxHw5zsgYJSIYy6BNr6GIQAeLVcioX7Fx32o/edit?gid=196080467#gid=196080467&range=109:114
 * for now we will add some basic logic here just based on the userRole to make it easier to update later
 * we can add more cases here based on biz specs, like admin/staff can approve campaign's post but advertisers can't and so on
 */
export const useTikTokSpecialPermissions = () => {
  const { isAdvertiserFromTikTok } = useUserRoles();
  const { permissions, canCallCustomPermissions } = usePermissions();
  const permissionsWithTikTok = {
    ...permissions,
    tiktok: {
      manage: true,
      manageAndDelete: false,
      noView: false,
      view: false
    }
  };

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissionsWithTikTok?.tiktok?.noView || permissionsWithTikTok?.tiktok === null) {
    return {
      ...defaultPermissions
    };
  } else if (permissionsWithTikTok?.tiktok?.view) {
    return {
      ...defaultPermissions
    };
  } else if (permissionsWithTikTok?.tiktok?.manage) {
    return { ...defaultPermissions, hideApprovePostBtn: isAdvertiserFromTikTok, hideRejectInappropriatePostBtn: false };
  }

  return defaultPermissions;
};
