import { useParamsSearch } from '@/shared/hooks';
import { RenderDataWithFallback } from '@/shared/atoms';
import { AffiliateReportChart } from '@/shared/molecules';
import { getValuableVariables } from '@/shared/utils';
import {
  AffiliateReportShowBy,
  MarketplaceCampaignDetailType,
  useMarketplaceAffiliateReportSummaryQuery
} from '@/graphql';
import {
  MarketplaceAffiliateReportTable,
  MarketplaceAffiliateReportFilter,
  MarketplaceAffiliateReportSummary,
  MarketplaceAffiliateReportSearchSchemaType
} from '@/shared/organisms';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';

export const MarketplaceAffiliateReport = () => {
  const {
    marketplace: { id, currency, campaignType }
  } = useMarketplaceDetailsContext();
  const { filter, setFilter } = useParamsSearch<MarketplaceAffiliateReportSearchSchemaType>();

  const { data, loading } = useMarketplaceAffiliateReportSummaryQuery({
    skip: !filter,
    variables: getValuableVariables({
      campaignId: id,
      endDate: filter.endDate,
      startDate: filter.startDate,
      showBy: filter.mode as AffiliateReportShowBy
    })
  });

  const summaryData = data?.marketplaceAffiliateReportSummary || undefined;
  const isReferralCampaign = campaignType === MarketplaceCampaignDetailType.REFERRAL_CODE;

  return (
    <>
      <MarketplaceAffiliateReportFilter filter={filter} setFilter={setFilter} campaignId={id} />

      <RenderDataWithFallback loading={loading}>
        <MarketplaceAffiliateReportSummary
          data={summaryData}
          currency={currency}
          isReferralCampaign={isReferralCampaign}
        />

        <AffiliateReportChart data={summaryData?.chartData} />

        <MarketplaceAffiliateReportTable
          filter={filter}
          campaignId={id}
          currency={currency}
          setFilter={setFilter}
          summaryData={summaryData}
          isReferralCampaign={isReferralCampaign}
        />
      </RenderDataWithFallback>
    </>
  );
};
