import { useAllInfluencersForMarketplaceV2Query } from '@/graphql';
import { useAllTalentAgencyOrPartnerOptions } from '@/shared/hooks';
import { useMarketplaceInfluencersFilter } from '@/shared/organisms';
import { MAX_FOLLOWERS, MIN_FOLLOWERS, MAX_ENGAGEMENT_RATE, MIN_ENGAGEMENT_RATE } from '@/shared/constants';
import {
  getOffset,
  convertGenders,
  getVariableNumber,
  convertOrderToORDER,
  getValuableVariables,
  getVariableRateValue,
  splitPartnerAndTalentAgenciesIds
} from '@/shared/utils';
import { useMarketplaceDetailsContext } from '../../MarketplaceDetailsContext';

export const useMarketplaceInfluencersList = () => {
  const { marketplace, marketplaceId } = useMarketplaceDetailsContext();
  const { page, limit, sort, filter } = useMarketplaceInfluencersFilter();
  const { allTalentAgenciesPartners } = useAllTalentAgencyOrPartnerOptions();

  const minFollowersMKP = marketplace.minFollowers || MIN_FOLLOWERS;
  const maxFollowersMKP = marketplace.maxFollowers || MAX_FOLLOWERS;

  const { data, loading } = useAllInfluencersForMarketplaceV2Query({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      limit,
      id: marketplaceId,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      ...filter,
      ...splitPartnerAndTalentAgenciesIds(filter.talentAgencyOrPartnerIds.map(Number), allTalentAgenciesPartners),
      minAge: filter.maxAge || null,
      maxAge: filter.maxAge || null,
      genders: convertGenders(filter.genders),
      categoryIds: filter.categoryIds.map(Number),
      minFollowers: getVariableNumber(filter.minFollowers, minFollowersMKP),
      maxFollowers: getVariableNumber(filter.maxFollowers, maxFollowersMKP),
      minEngagementRate: getVariableRateValue(filter.minEngagementRate, MIN_ENGAGEMENT_RATE),
      maxEngagementRate: getVariableRateValue(filter.maxEngagementRate, MAX_ENGAGEMENT_RATE)
    })
  });

  return {
    fetchingInfluencersList: loading,
    listInfluencers: data?.allInfluencersForMarketplaceV2 || [],
    totalRecords: data?.allInfluencersCountForMarketplace?.totalNumber
  };
};
