import React, { createContext, useContext, useState } from 'react';
import { ProposalInfluencer, ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { useDeepCompareEffect, useToggleState } from '@/shared/hooks';
import { RowData } from '../types';
import { getInitialRowsData } from '../utils';
import { TableModeType } from './TableModes';

interface ContextType {
  rowsData: RowData[];
  isEditMode?: boolean;
  hasRowChange?: boolean;
  tableMode: TableModeType;
  resetViewMode: () => void;
  selectedInfluencers: readonly ProposalInfluencer[];
  confirmModal: ReturnType<typeof useToggleState>;
  setRowsData: React.Dispatch<React.SetStateAction<RowData[]>>;
  setHasRowChange: React.Dispatch<React.SetStateAction<boolean>>;
  setTableMode: React.Dispatch<React.SetStateAction<TableModeType>>;
}

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  children: React.ReactElement | Array<React.ReactElement>;
  value: { selectedInfluencers: readonly ProposalInfluencer[]; jdMode: ProposalJobDescriptionBreakDownMode };
}

export const SelectedInfluencersProvider = ({ children, value: { jdMode, selectedInfluencers } }: Props) => {
  const confirmModal = useToggleState();
  const [hasRowChange, setHasRowChange] = useState(false);
  const [tableMode, setTableMode] = useState(TableModeType.VIEW);
  const initialRowData = getInitialRowsData(selectedInfluencers, jdMode);
  const [rowsData, setRowsData] = useState<RowData[]>(initialRowData);

  useDeepCompareEffect(() => {
    const newInitialRowData = getInitialRowsData(selectedInfluencers, jdMode);
    setRowsData(newInitialRowData);
  }, [selectedInfluencers]);

  const resetViewMode = () => {
    setTableMode(TableModeType.VIEW);
    setRowsData(initialRowData);
    setHasRowChange(false);
  };

  return (
    <Context.Provider
      value={{
        rowsData,
        tableMode,
        setRowsData,
        setTableMode,
        hasRowChange,
        confirmModal,
        setHasRowChange,
        resetViewMode,
        selectedInfluencers,
        isEditMode: tableMode === TableModeType.EDIT
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSelectedInfluencersContext = () => useContext<ContextType>(Context);
