import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useScrollElementBottom } from '@/shared/hooks';
import { Icon } from '../Icon';
import { Button } from '../Button';
import { MediaPreview } from '../MediaPreview';
import { useChatBoxContext } from './ChatBoxContext';

export interface ChatBoxInputProps {
  loading?: boolean;
  className?: string;
  placeholder?: string;
  onSubmit: (data: { message: string; files: File[] }, onSuccess: () => void) => void;
}

export const ChatBoxInput = ({ loading, placeholder, onSubmit, ...restProps }: ChatBoxInputProps) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const { scrollToBottom } = useScrollElementBottom(textAreaRef, [message]);
  const { isDisabledUploadFile, uploadingFiles, removeFile, clearUploadingFiles, uploadNewFiles, uploadFileConfigs } =
    useChatBoxContext();

  const isUploadFileButtonDisabled = isDisabledUploadFile || loading;

  const handleSendMessage = () => {
    if (message || uploadingFiles.length) {
      onSubmit?.({ message, files: uploadingFiles }, () => {
        setMessage('');
        clearUploadingFiles();
      });
    }
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Shift + Enter for breaking line and Enter for sending message
    // https://adasiaholdings.atlassian.net/browse/AT-6195
    if (e.key === 'Enter') {
      e.preventDefault();

      // User can move the focus point somewhere then break the line. Default Enter key of textarea will handle this behavior
      // But we are custom the breakline keyboard so we need to handle it manually
      if (e.shiftKey) {
        const currentValue = e.currentTarget.value;
        const selectionEnd = e.currentTarget.selectionEnd;
        const selectionStart = e.currentTarget.selectionStart;

        // Insert breakline after the focusing point but don't set
        e.currentTarget.value = `${currentValue.substring(0, selectionStart)}\r\n${currentValue.substring(selectionEnd, currentValue.length)}`;

        // Scroll down to bottom if break line is the last line
        if (selectionEnd === currentValue.length) {
          scrollToBottom();
        }

        // Move the current focus to after new break line
        e.currentTarget.selectionEnd = selectionStart + 1;
        e.currentTarget.selectionStart = selectionStart + 1;
      } else {
        handleSendMessage();
      }
    }
  };

  return (
    <div css={styles.wrapper} {...restProps}>
      <div css={styles.content}>
        {uploadingFiles.length ? (
          <div css={styles.files}>
            {uploadingFiles.map((file, index) => (
              <div key={`upload-file-${index}`} css={styles.imagePreview}>
                <div className="remove-image-icon" css={styles.removeImageIcon} onClick={() => removeFile(index)}>
                  <Icon size={8} icon="close" color={THEME.icon.colors.black} />
                </div>
                <MediaPreview
                  src={URL.createObjectURL(file)}
                  css={{ width: '64px', height: '64px', borderRadius: '8px' }}
                />
              </div>
            ))}
          </div>
        ) : null}

        <div css={{ gap: '8px', paddingTop: '8px', display: 'flex', alignItems: 'center' }}>
          {uploadFileConfigs ? (
            <>
              <Button
                as="label"
                variant="default"
                htmlFor="file-upload"
                disabled={isUploadFileButtonDisabled}
                css={[styles.uploadButton, { cursor: isUploadFileButtonDisabled ? 'default' : 'pointer' }]}
                prefixIcon={{
                  size: 16,
                  icon: 'image',
                  color: isUploadFileButtonDisabled ? THEME.icon.colors.gray.lv1 : THEME.icon.colors.black
                }}
              />
              <input
                type="file"
                id="file-upload"
                css={{ display: 'none' }}
                accept={uploadFileConfigs.accept}
                multiple={uploadFileConfigs.multiple}
                disabled={isUploadFileButtonDisabled}
                onChange={(e) => {
                  const files = e.target.files;

                  if (files?.length) {
                    uploadNewFiles(Array.from(files));
                    /* eslint-disable */
                    // @ts-ignore
                    e.target.value = null; // Can re-upload same file
                  }
                }}
              />
            </>
          ) : null}

          <textarea
            ref={textAreaRef}
            value={message}
            css={styles.input}
            disabled={loading}
            autoCorrect="off"
            autoComplete="off"
            onKeyPress={handlePressEnter}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={placeholder || t<string>('Please Input')}
            autoFocus
          />

          <Button
            variant="black"
            css={styles.sendButton}
            onClick={handleSendMessage}
            loading={{ showIcon: true, status: loading }}
            prefixIcon={{ icon: 'arrow-up', size: 11, color: THEME.icon.colors.white }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    flex: 'none',
    padding: '16px',
    marginTop: 'auto',
    boxSizing: 'border-box'
  }),
  content: css({
    minHeight: '40px',
    borderRadius: '5px',
    padding: '0 8px 8px', // paddingTop 0 to avoid overlap remove icon when hover the preview image
    boxSizing: 'border-box',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  input: css({
    flex: 1,
    fontWeight: 400,
    fontSize: '16px',
    maxHeight: '70px',
    overflowY: 'auto',
    lineHeight: '100%',
    fontStyle: 'normal',
    backgroundColor: 'inherit',
    color: THEME.input.text.colors.default,
    '&::placeholder': { color: THEME.input.text.colors.placeholder },
    /* stylelint-disable */
    appearance: 'textfield',
    MozAppearance: 'textfield',
    '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
  }),
  sendButton: css({
    padding: 0,
    flex: 'none',
    height: '24px',
    minWidth: '24px',
    borderRadius: '50%',
    alignSelf: 'flex-end'
  }),
  files: css({
    gap: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    paddingTop: '8px',
    maxHeight: '150px'
  }),
  uploadButton: css({
    padding: 0,
    flex: 'none',
    height: '24px',
    border: 'none',
    minWidth: '24px',
    alignSelf: 'flex-end'
  }),
  imagePreview: css({
    position: 'relative',
    '&:hover .remove-image-icon': { display: 'flex' }
  }),
  removeImageIcon: css({
    top: 0,
    right: 0,
    zIndex: 1,
    width: '16px',
    height: '16px',
    display: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    alignItems: 'center',
    position: 'absolute',
    boxSizing: 'border-box',
    justifyContent: 'center',
    transform: 'translate(40%, -40%)',
    backgroundColor: THEME.background.colors.white
  })
};
