import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Button, Icon } from '@/shared/atoms';
import { AddHashtagModal, DeleteHashtagModal } from '@/shared/molecules';
import { AnalyticsHashtagType } from '../types';
import { useManageHashtags } from './hooks';

export interface AnalyticsHashtagsListProps<T extends AnalyticsHashtagType = AnalyticsHashtagType> {
  hashtags: T[];
  adding?: boolean;
  deleting?: boolean;
  maxHashtags?: number;
  activeHashtag?: T['id'];
  onSelectHashtag?: (hashtag?: T) => void;
  onDeleteHashtag?: (hashtag: T, cb?: () => void) => void;
  onAddNewHashtag?: (hashtag: string, cb?: () => void) => void;
}

export const AnalyticsHashtagsList = <T extends AnalyticsHashtagType>({
  adding,
  hashtags,
  deleting,
  activeHashtag,
  maxHashtags = 5,
  onSelectHashtag,
  onAddNewHashtag,
  onDeleteHashtag
}: AnalyticsHashtagsListProps<T>) => {
  const { t } = useTranslation();
  const {
    addModalState,
    deletingHashtag,
    setDeletingHashtag,
    handleDeleteHashtag,
    handleCreateHashtag,
    resetDeletingHashtag
  } = useManageHashtags({ onAddNewHashtag, onDeleteHashtag });

  const disabledAddButton = hashtags.length > maxHashtags;

  return (
    <>
      <div css={styles.wrapper}>
        <div css={styles.itemWrapper}>
          <Button
            title="+"
            variant="white"
            onClick={addModalState.open}
            disabled={disabledAddButton}
            css={[styles.item(false), { padding: 0 }]}
            tooltip={{
              help: disabledAddButton ? t('Can’t add tags more than', { count: maxHashtags }) : t('Tooltip.Add Hashtag')
            }}
          />
        </div>

        {hashtags.map((hashtag) => {
          const active = hashtag.id === activeHashtag;

          return (
            <div key={hashtag.id} css={styles.itemWrapper}>
              <Button
                css={styles.item(active)}
                disabled={hashtag.blocked}
                variant={active ? 'black' : 'white'}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectHashtag?.(active ? undefined : hashtag);
                }}
              >
                <p css={styles.itemName(active)}>{hashtag.name}</p>

                {!hashtag.blocked ? (
                  <div
                    css={styles.icon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeletingHashtag(hashtag);
                    }}
                  >
                    <Icon size={8} icon="close" color="inherit" />
                  </div>
                ) : null}
              </Button>
            </div>
          );
        })}
      </div>

      <AddHashtagModal
        loading={adding}
        open={addModalState.status}
        onAdd={handleCreateHashtag}
        onClose={addModalState.close}
      />

      <DeleteHashtagModal
        loading={deleting}
        open={!!deletingHashtag?.id}
        onClose={resetDeletingHashtag}
        onDelete={handleDeleteHashtag}
        hashtag={deletingHashtag?.name}
      />
    </>
  );
};
const styles = {
  wrapper: css({
    margin: '-4px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box'
  }),
  itemWrapper: css({
    padding: '4px',
    boxSizing: 'border-box'
  }),
  item: (active?: boolean) =>
    css({
      minWidth: '120px',
      padding: '0 12px 0 16px',
      color: active ? THEME.text.colors.white : THEME.text.colors.black.lv1
    }),
  itemName: (active?: boolean) =>
    css({
      flex: 1,
      fontSize: '12px',
      lineHeight: '100%',
      letterSpacing: '0.5px',
      fontWeight: active ? 600 : 400
    }),
  icon: css({
    flex: 'none',
    width: '12px',
    height: '12px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center'
  })
};
