import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthConnectableTwitterAccountAnalyticsV2.graphql';
import { Mutation, MutationsocialAuthConnectableTwitterAccountAnalyticsV2Args } from '../../__generated__/globalTypes';

export const useSocialAuthConnectableTwitterAccountAnalyticsV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthConnectableTwitterAccountAnalyticsV2Args>
) => {
  const [callSocialAuthConnectableTwitterAccountAnalyticsV2, result] = useMutation<
    Mutation,
    MutationsocialAuthConnectableTwitterAccountAnalyticsV2Args
  >(MUTATION, options);

  return { callSocialAuthConnectableTwitterAccountAnalyticsV2, ...result };
};

export type SocialAuthConnectableTwitterAccountAnalyticsV2MutationFunction =
  Mutation['socialAuthConnectableTwitterAccountAnalyticsV2'];
