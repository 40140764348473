import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserRoles, useInfluencerPermissions } from '@/auth';
import { EngagementDetail, ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { Table } from '@/shared/atoms';
import { useSelectItems } from '@/shared/hooks';
import { UseEngagementProposalTable } from '../../hooks';
import { useSelectedInfluencersContext } from '../SelectedInfluencersContext';
import { ProposalDerivedProps } from '../../../shared/types';
import { SelectInfluencersActionBar } from './ActionBar';

export interface SelectedInfluencersTableProps
  extends Pick<ProposalDerivedProps, 'formattedInfluencers' | 'formattedSocialAccounts' | 'selectedInfluencers'> {
  loading?: boolean;
  engagement: EngagementDetail;
  hasTrackingReferral?: boolean;
  hasInfluencerCostPermission?: boolean;
  jdMode: ProposalJobDescriptionBreakDownMode;
}

export const SelectedInfluencersTable = ({
  jdMode,
  loading,
  engagement,
  formattedInfluencers,
  formattedSocialAccounts,
  hasTrackingReferral,
  hasInfluencerCostPermission
}: SelectedInfluencersTableProps) => {
  const { t } = useTranslation();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { hideSelectInfluencers } = useInfluencerPermissions();
  const { isEditMode, selectedInfluencers, rowsData, setRowsData, hasRowChange, setHasRowChange } =
    useSelectedInfluencersContext();

  const {
    allItemIds: allInfluencerIds,
    selectedItemIds: selectedInfluencerIds,
    hasAllItemsSelected: hasAllInfluencersSelected,
    selectItem: selectInfluencer,
    setSelectedItemIds: setSelectedInfluencerIds,
    toggleSelectAllItems: toggleSelectAllInfluencers
  } = useSelectItems({ listItems: formattedInfluencers, key: 'influencerId' });
  const {
    allItemIds: allAccountIds,
    selectedItems: selectedAccounts,
    selectedItemIds: selectedAccountIds,
    hasAllItemsSelected: hasAllAccountsSelected,
    selectItem: selectAccount,
    setSelectedItemIds: setSelectedAccountIds,
    toggleSelectAllItems: toggleSelectAllAccounts
  } = useSelectItems({ listItems: formattedSocialAccounts, key: 'socialAccountId' });

  useEffect(() => {
    if (isEditMode) {
      setSelectedAccountIds([]);
    } else {
      setSelectedInfluencerIds([]);
    }
  }, [isEditMode, selectedInfluencers.length]);

  const isEditingWithSocialBreakdownMode =
    jdMode === ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN && isEditMode;
  const hasAllSelectedIds = isEditingWithSocialBreakdownMode ? hasAllAccountsSelected : hasAllInfluencersSelected;
  const numberSelectedIds = isEditingWithSocialBreakdownMode ? selectedAccountIds.length : selectedInfluencerIds.length;

  const handleSelectAllIds = () => {
    if (isEditingWithSocialBreakdownMode) {
      setSelectedAccountIds(allAccountIds);
    } else {
      setSelectedInfluencerIds(allInfluencerIds);
    }
  };

  const handleRowSelect = (influencerId: number, socialAccountId?: number) => {
    if (isEditingWithSocialBreakdownMode) {
      selectAccount(socialAccountId as number);
    } else {
      selectInfluencer(influencerId);
    }
  };

  const { rows, columns } = UseEngagementProposalTable({
    jdMode,
    rowsData,
    isEditMode,
    engagement,
    setRowsData,
    hasRowChange,
    setHasRowChange,
    hasTrackingReferral,
    hasInfluencerCostPermission,
    onRowSelect: handleRowSelect,
    selectButtonProps: { onClick: handleSelectAllIds },
    listSelectedIds: isEditingWithSocialBreakdownMode ? selectedAccountIds : selectedInfluencerIds
  });

  const handleToggleAllIds = () => {
    if (isEditingWithSocialBreakdownMode) {
      toggleSelectAllAccounts();
    } else {
      toggleSelectAllInfluencers();
    }
  };

  return (
    <div css={{ position: 'relative' }}>
      {numberSelectedIds > 0 ? (
        <SelectInfluencersActionBar
          jdMode={jdMode}
          engagement={engagement}
          onSelectAll={handleSelectAllIds}
          numberSelected={numberSelectedIds}
          hasAllSelected={hasAllSelectedIds}
          onToggleSelect={handleToggleAllIds}
          selectedAccounts={selectedAccounts}
          selectedInfluencerIds={selectedInfluencerIds}
        />
      ) : null}

      <Table
        noTopBorderRadius
        data={{ rows, columns, rowHeight: '62px' }}
        empty={
          isAdminStaffTalentAgencyPartner && !hideSelectInfluencers
            ? {
                linkProps: {
                  label: t('Influencer.select influencers'),
                  to: '/engagement/$id/influencer',
                  params: { id: engagement.id.toString() }
                }
              }
            : undefined
        }
        stickyHeader
        hasLoadingOverlay={loading}
      />
    </div>
  );
};
