import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdatePackage.graphql';
import { Mutation, MutationupdatePackageArgs } from '../../__generated__/globalTypes';

export const useUpdatePackageMutation = (options?: MutationHookOptions<Mutation, MutationupdatePackageArgs>) => {
  const [callUpdatePackage, result] = useMutation<Mutation, MutationupdatePackageArgs>(MUTATION, options);

  return { callUpdatePackage, ...result };
};

export type UpdatePackageMutationFunction = Mutation['updatePackage'];
