import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsPermissions } from '@/auth';
import { lockedImage } from '@/shared/assets';
import { Icon, Anchor, Popover, DropdownMenu } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar } from '@/shared/molecules';
import { AnalyticsComparableAccountType } from './types';

export interface AnalyticsComparableAccountsProps<T extends AnalyticsComparableAccountType> {
  accounts: T[];
  maxAccounts?: number;
  mainAccountId: T['id'];
  hiddenAccountIds?: T['id'][];
  onAddNewAccount?: () => void;
  onSelectAccount?: (account: T) => void;
  onDeleteAccount?: (account: T, cb?: () => void) => void;
  hasFreePlan?: boolean;
}
export const AnalyticsComparableAccounts = <T extends AnalyticsComparableAccountType>({
  accounts,
  mainAccountId,
  maxAccounts = 5,
  onAddNewAccount,
  onDeleteAccount,
  onSelectAccount,
  hiddenAccountIds = []
}: AnalyticsComparableAccountsProps<T>) => {
  const { t } = useTranslation();
  const [isHoverId, setIsHoverId] = useState<T['id']>();
  const { hideAddAnalyticsBtn, hideDeleteCompareItem } = useAnalyticsPermissions();

  const canDeleteAccount = !hideDeleteCompareItem && !!onDeleteAccount;
  const canAddNewAccount = !hideAddAnalyticsBtn && !!onAddNewAccount && accounts.length <= maxAccounts;

  return (
    <div css={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
      {accounts.map((account) => {
        const isBlocked = account.blocked;
        const isMainAccount = account.id === mainAccountId;
        const isHidden = hiddenAccountIds.includes(account.id);
        const showOverlay = !isHidden && !isBlocked && isHoverId === account.id;

        return (
          <div
            key={account.id}
            css={styles.item(isBlocked)}
            onMouseEnter={() => setIsHoverId(account.id)}
            onMouseLeave={() => setIsHoverId(undefined)}
            onClick={() => {
              if (!isBlocked) {
                onSelectAccount?.(account);
              }
            }}
          >
            {canDeleteAccount && !isBlocked && !isMainAccount ? (
              <Popover
                position="bottom-start"
                target={({ setOpen }) => (
                  <div
                    css={styles.iconWrapper}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(true);
                    }}
                  >
                    <Icon icon="more-vertical" size={13} color={isHidden ? '#c5d0da' : '#3b4146'} />
                  </div>
                )}
              >
                {({ setOpen }) => (
                  <DropdownMenu
                    css={{ position: 'relative', margin: 0 }}
                    options={[
                      {
                        value: '',
                        label: (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              onDeleteAccount(account);
                              setOpen(false);
                            }}
                          >
                            {t<string>('Delete')}
                          </div>
                        )
                      }
                    ]}
                  />
                )}
              </Popover>
            ) : null}

            <div css={{ position: 'relative', width: '56px', height: '56px', borderRadius: '50%' }}>
              <InfluencerAvatar
                size="100%"
                src={account.avatarUrl}
                css={{ borderRadius: 'inherit', border: 'solid 1px #f6f6f6' }}
              />

              {(isHidden || isBlocked || showOverlay) && onSelectAccount ? (
                <div css={styles.overlayHoverWrapper}>
                  {isHidden || showOverlay ? (
                    <Icon icon="eye-mask" size={24} color="#fff" />
                  ) : (
                    <img css={{ width: '100%', height: '100%' }} src={lockedImage} alt="locked" />
                  )}
                </div>
              ) : null}
            </div>
            <Anchor
              target="_blank"
              label={account.username}
              href={account.profileUrl}
              onClick={(e) => e.stopPropagation()}
              onMouseEnter={() => setIsHoverId(undefined)}
              onMouseLeave={() => setIsHoverId(account.id)}
              css={{ marginTop: '4px', width: '90%', fontSize: '13px', textAlign: 'center' }}
            />
          </div>
        );
      })}

      {canAddNewAccount ? (
        <div css={[styles.item(), styles.addAccount]} onClick={onAddNewAccount}>
          <Icon icon="plus" size={14} color="#808d98" />
        </div>
      ) : null}
    </div>
  );
};
const styles = {
  item: (disabled?: boolean) =>
    css({
      width: '104px',
      display: 'flex',
      height: '110px',
      position: 'relative',
      borderRadius: '3px',
      alignItems: 'center',
      boxSizing: 'border-box',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: THEME.background.colors.white,
      boxShadow: '0 1px 2px 0 #dee5ec, 0 0 0 1px #dee5ec80',
      ...(disabled ? { cursor: 'default' } : { cursor: 'pointer' })
    }),
  addAccount: css({ '&:hover': { opacity: 0.8, boxShadow: 'rgb(222, 229, 239) 1px 1px 2px 1px' } }),
  iconWrapper: css({
    zIndex: 5,
    top: '6px',
    right: '2px',
    flex: 'none',
    width: '16px',
    height: '16px',
    border: 'none',
    fontWeight: 600,
    display: 'flex',
    fontSize: '22px',
    cursor: 'pointer',
    textAlign: 'right',
    position: 'absolute',
    backgroundColor: 'transparent'
  }),
  overlayHoverWrapper: css({
    top: 0,
    left: 0,
    zIndex: 3,
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    borderRadius: 'inherit',
    justifyContent: 'center',
    backgroundColor: 'rgb(39, 49, 59, 0.5)'
  })
};
