import { Wrapper, WrapperProps } from './Wrapper';
import { FocusInput, FocusInputProps } from './FocusInput';
import { FieldsGroup, FieldsGroupProps } from './FieldsGroup';
import { SectionTitle, SectionTitleProps } from './SectionTitle';
import { FieldWrapper, FieldWrapperProps } from './FieldWrapper';
import { HorizontalLine, HorizontalLineProps } from './HorizontalLine';
import { ButtonSubmitWrapper, ButtonSubmit } from './ButtonSubmitWrapper';
import { TwoDayPickersInARow, TwoDayPickersInARowProps } from './TwoDayPickersInARow';

export const FormStyle = {
  Wrapper,
  FocusInput,
  FieldsGroup,
  SectionTitle,
  FieldWrapper,
  ButtonSubmit,
  HorizontalLine,
  ButtonSubmitWrapper,
  TwoDayPickersInARow
};
export type {
  WrapperProps,
  FocusInputProps,
  FieldsGroupProps,
  FieldWrapperProps,
  SectionTitleProps,
  HorizontalLineProps,
  TwoDayPickersInARowProps
};
