import { TiktokInfluencerProfile, TiktokInfluencers } from '@/shared/organisms';
import { useDefaultInfluencerSearchProps, useSocialInfluencersSelectButtonsProps } from './hooks';

export const EngagementTiktokInfluencersSearch = () => {
  const { isEngagementPage, isTtcmCampaign } = useDefaultInfluencerSearchProps();
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialInfluencersSelectButtonsProps<TiktokInfluencerProfile>({ socialMedia: 'TIKTOK' });

  return (
    <TiktokInfluencers
      isTtcmCampaign={isTtcmCampaign}
      isEngagementPage={isEngagementPage}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
