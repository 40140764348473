import React, { createContext, useContext } from 'react';
import { BasicAccountAnalyticsPayload } from '@/graphql';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { AnalyticsSelectedAccountInfo } from '@/shared/organisms';

interface ContextType {
  brandAccountId: number;
  brandAccount: BasicAccountAnalyticsPayload;
  selectedSocialAccount: AnalyticsSelectedAccountInfo;
}

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  value: Pick<ContextType, 'brandAccount' | 'selectedSocialAccount'>;
  children: React.ReactElement | Array<React.ReactElement>;
  isChildList?: boolean;
}

export const AnalyticsProfileProvider = ({
  children,
  value: { brandAccount, selectedSocialAccount },
  isChildList
}: Props) => {
  const { brandAccountId, brandAccountName } = brandAccount;

  useUpdateDocumentTitle({
    skipUpdate: {
      title: !!isChildList,
      appHeader: !!isChildList
    },
    href: '/analytics',
    alwaysBackToParent: true,
    interpolation: { title: brandAccountName },
    title: brandAccountName ? 'documentTitle.AnalyticsDashboardWithAccountName' : 'documentTitle.AnalyticsDashboard',
    appHeader: brandAccountName ? 'appHeader.AnalyticsDashboardWithAccountName' : 'appHeader.AnalyticsDashboard'
  });

  return (
    <Context.Provider
      value={{
        brandAccount,
        brandAccountId,
        selectedSocialAccount
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAnalyticsProfileContext = () => useContext<ContextType>(Context);
