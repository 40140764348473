import { forwardRef } from 'react';
import { Icon, IconProps } from '@/shared/atoms';

export const DropdownIndicator = forwardRef<SVGElement, Partial<IconProps>>(
  ({ icon = 'caret-down', size = 10, color = '27313b', ...restProps }, ref) => (
    <Icon icon={icon} size={size} color={color} css={{ paddingRight: 8 }} {...restProps} ref={ref} />
  )
);

DropdownIndicator.displayName = 'DropdownIndicator';
