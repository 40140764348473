import { useTranslation } from 'react-i18next';
import { Option } from '@/shared/types';
import { useTranslateOptions } from '@/shared/hooks';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { MarketplaceAffiliateCommissionStatus, useMarketplaceAffiliateCommissionFixedDatesQuery } from '@/graphql';

import { MarketplaceCommissionsFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  marketplaceId: number;
  onSubmit?: () => void;
}

export const Template = ({ onSubmit, marketplaceId }: TemplateProps) => {
  const { t } = useTranslation();
  const { data } = useMarketplaceAffiliateCommissionFixedDatesQuery({ variables: { marketplaceId } });

  const monthOptions: Option[] =
    data?.marketplaceAffiliateCommissionFixedDates?.map((date, i) => ({
      value: `${date}_${i}`,
      label: typeof date === 'string' ? date : '-'
    })) || [];
  const statusOptions = useTranslateOptions([
    { label: 'Approved', value: MarketplaceAffiliateCommissionStatus.APPROVED },
    { label: 'Not Approved', value: MarketplaceAffiliateCommissionStatus.UNAPPROVED }
  ]);

  return (
    <BasicFilterWrapper css={{ padding: 0 }}>
      <TextSearchField<MarketplaceCommissionsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.InfluencerFilter')}
      />

      <SelectorField<MarketplaceCommissionsFilterFormKeys>
        name="month"
        onSubmit={onSubmit}
        options={monthOptions}
        css={{ maxWidth: '198px' }}
        placeholder={t<string>('Selector.Month')}
      />

      <SelectorField<MarketplaceCommissionsFilterFormKeys>
        name="status"
        onSubmit={onSubmit}
        options={statusOptions}
        css={{ maxWidth: '198px' }}
        placeholder={t<string>('Selector.Status')}
      />
    </BasicFilterWrapper>
  );
};
