import { useSearch } from '@tanstack/react-router';
import { useEngagementPermissions, useUserRoles } from '@/auth';
import { EngagementCampaignSocialAccountPostStatus, EngagementDetail } from '@/graphql';
import { ButtonLink, DownloadButton, FormStyle } from '@/shared/atoms';
import { GTM_IDS } from '@/shared/constants';
import { useDownloadFile, useQueryHelper } from '@/shared/hooks';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { GenericOption } from '@/shared/types';
import { getRestApiUrl } from '@/shared/utils';
import { EngagementPostsFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
  isOnlyTikTokTtcm?: boolean;
  engagement: EngagementDetail;
}

export const Template = ({
  onSubmit,
  isOnlyTikTokTtcm,
  engagement: { id: engagementId, isTtcmCampaign }
}: TemplateProps) => {
  const { t } = useQueryHelper();
  const search = useSearch({ from: '/_private-routes/engagement/$id/posts' });
  const { hideAddPostBtn } = useEngagementPermissions();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const statusOptions: GenericOption<EngagementCampaignSocialAccountPostStatus>[] = [
    { label: t<string>('Option.Reviewing'), value: EngagementCampaignSocialAccountPostStatus.REVIEWING },
    { label: t<string>('Option.Ready to Post'), value: EngagementCampaignSocialAccountPostStatus.READY_TO_POST },
    { label: t<string>('Option.Effective'), value: EngagementCampaignSocialAccountPostStatus.EFFECTIVE },
    { label: t<string>('Option.Warning'), value: EngagementCampaignSocialAccountPostStatus.WARNING },
    { label: t<string>('Option.Ineffective'), value: EngagementCampaignSocialAccountPostStatus.INEFFECTIVE },
    { label: t<string>('Option.Approved'), value: EngagementCampaignSocialAccountPostStatus.APPROVED },
    { label: t<string>('Option.Rejected'), value: EngagementCampaignSocialAccountPostStatus.REJECT }
  ];

  const hasAddPostBtn = !hideAddPostBtn && isAdminStaffTalentAgencyPartner && !isOnlyTikTokTtcm;

  return (
    <BasicFilterWrapper>
      <TextSearchField<EngagementPostsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.EngagementPostFilter')}
      />

      <SelectorField<EngagementPostsFilterFormKeys>
        name="status"
        onSubmit={onSubmit}
        options={statusOptions}
        css={{ maxWidth: '188px' }}
      />

      {hasAddPostBtn ? (
        <FormStyle.FieldWrapper css={{ flex: 'none' }}>
          <ButtonLink
            variant="blue"
            css={{ minWidth: '96px' }}
            prefixIcon={{ icon: 'plus', size: '10px' }}
            title={t(isTtcmCampaign ? 'Button.Add Video' : 'Button.Add Post')}
            to="/engagement/$id/posts/add"
            search={search}
          />
        </FormStyle.FieldWrapper>
      ) : null}

      <FormStyle.FieldWrapper css={{ flex: 'none' }}>
        <DownloadButton
          disabled={downloading}
          id={GTM_IDS.engagement.posts_csv_download}
          onClick={() =>
            handleDownloadFile({
              filenamePrefix: 'post_comments',
              url: getRestApiUrl(`engagement/${engagementId}/posts/comments/`)
            })
          }
        />
      </FormStyle.FieldWrapper>
    </BasicFilterWrapper>
  );
};
