import { useQueryHelper } from '@/shared/hooks';
import { useMarketplaceApplicantsFilter } from '@/shared/organisms';
import { useAllApplicantInfluencersForMarketplaceQuery } from '@/graphql';
import { NOT_FOUND, MAX_FOLLOWERS, MIN_FOLLOWERS, MAX_ENGAGEMENT_RATE, MIN_ENGAGEMENT_RATE } from '@/shared/constants';
import {
  getOffset,
  convertGenders,
  getVariableNumber,
  convertOrderToORDER,
  getValuableVariables,
  getVariableRateValue
} from '@/shared/utils';
import { useMarketplaceDetailsContext } from '../../MarketplaceDetailsContext';

export const useMarketplaceApplicantsList = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { marketplace, marketplaceId } = useMarketplaceDetailsContext();
  const { page, limit, sort, filter } = useMarketplaceApplicantsFilter();

  const minFollowersMKP = marketplace.minFollowers || MIN_FOLLOWERS;
  const maxFollowersMKP = marketplace.maxFollowers || MAX_FOLLOWERS;

  const { data, error, loading } = useAllApplicantInfluencersForMarketplaceQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      limit,
      id: marketplaceId,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      ...filter,
      minAge: filter.minAge || null,
      maxAge: filter.maxAge || null,
      genders: convertGenders(filter.genders),
      appliedStatus: filter.appliedStatus || null,
      categoryIds: filter.categoryIds.map(Number),
      minFollowers: getVariableNumber(filter.minFollowers, minFollowersMKP),
      maxFollowers: getVariableNumber(filter.maxFollowers, maxFollowersMKP),
      minEngagementRate: getVariableRateValue(filter.minEngagementRate, MIN_ENGAGEMENT_RATE),
      maxEngagementRate: getVariableRateValue(filter.maxEngagementRate, MAX_ENGAGEMENT_RATE)
    }),
    onError: (error) => {
      if (error.message !== NOT_FOUND) {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    }
  });

  return { data, loading, noInfluencersJoined: error?.message === NOT_FOUND };
};
