import { css } from '@emotion/react';
import { ReactNode } from 'react';

import { THEME } from '@/shared/constants';

import { Icon, IconProps } from '../Icon';

export interface ChatBoxNoticeProps {
  className?: string;
  message?: ReactNode;
  type: 'info' | 'success' | 'warning' | 'error' | 'none';
}

export const ChatBoxNotice = ({ type, message, className }: ChatBoxNoticeProps) => {
  const iconProps: IconProps | undefined = (() => {
    switch (type) {
      // Design just support error now
      case 'error':
        return { icon: 'info', color: THEME.attention.colors.red };
      default:
        return undefined;
    }
  })();

  return (
    <div css={styles.wrapper(iconProps?.color)} className={className}>
      <div css={{ gap: '4px', display: 'flex', alignItems: 'center' }}>
        <Icon icon="" size={12} css={{ alignSelf: 'baseline' }} {...iconProps} />
        <p css={styles.message(iconProps?.color)}>{message}</p>
      </div>
    </div>
  );
};

const styles = {
  wrapper: (borderColor?: string) =>
    css({
      gap: '4px',
      flex: 'none',
      display: 'flex',
      minHeight: '40px',
      padding: '8px 16px',
      alignItems: 'center',
      borderRadius: '28px',
      boxSizing: 'border-box',
      backgroundColor: THEME.background.colors.gray.lv1,
      border: `1px solid ${borderColor || 'transparent'}`
    }),
  message: (color?: string) =>
    css({
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '120%',
      fontStyle: 'normal',
      color: color || THEME.text.colors.black.lv1
    })
};
