import { useState } from 'react';
import { useEngagementPermissions } from '@/auth';
import { useDeepCompareEffect, useQueryHelper } from '@/shared/hooks';
import { SortSelectButtonProps, WidgetSelectButtonProps } from '@/shared/organisms';
import {
  ReadPackageAccount,
  ReadPackageInfluencers,
  namedOperations,
  useConnectPackageToEngagementProposalMutation,
  useDeleteEngagementInfluencerProposalMutation,
  useDisconnectPackageFromEngagementProposalMutation
} from '@/graphql';
import { getEngagementProposalAddRemoveButtonDisableState } from '../utils';
import { useEngagementDetailsContext } from '../../EngagementDetailsContext';
import { useDefaultInfluencerSearchProps } from './useDefaultInfluencerSearchProps';

export const useInfluencerPackagesSelectButtonsProps = () => {
  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { selectedInfluencersMapIds } = useDefaultInfluencerSearchProps();
  const { hideTogglePackageFromProposalBtn, hideToggleInfluencerFromProposalBtn } = useEngagementPermissions();
  const {
    engagementId,
    engagement: { status }
  } = useEngagementDetailsContext();
  const { callConnectPackageToEngagementProposal } = useConnectPackageToEngagementProposalMutation({
    refetchQueries: [namedOperations.Query.AllPackages],
    onCompleted: invalidateRouteLoader
  });
  const { callDisconnectPackageFromEngagementProposal } = useDisconnectPackageFromEngagementProposalMutation({
    refetchQueries: [namedOperations.Query.AllPackages],
    onCompleted: invalidateRouteLoader
  });
  const { callDeleteEngagementInfluencerProposal } = useDeleteEngagementInfluencerProposalMutation({
    refetchQueries: [namedOperations.Query.AllPackages],
    onCompleted: invalidateRouteLoader
  });
  const [togglingState, setTogglingState] = useState<{
    loading: boolean;
    packageId: number | null;
    influencerId?: number | null;
  }>({ loading: false, packageId: null, influencerId: null });

  const resetLoadingState = () => {
    setTogglingState({ loading: false, packageId: null, influencerId: null });
  };

  useDeepCompareEffect(() => {
    resetLoadingState();
  }, [selectedInfluencersMapIds]);

  const handleConnectToProposal = async ({
    packageIds,
    influencer
  }: {
    packageIds: ReadPackageAccount['id'][];
    influencer?: ReadPackageInfluencers;
  }) => {
    if (!packageIds.length && !influencer) return;

    try {
      await callConnectPackageToEngagementProposal({
        variables: {
          input: {
            packageIds,
            engagementCampaignId: engagementId,
            influencerIds: influencer ? [influencer.influencerId] : undefined
          }
        }
      });
      enqueueSnackbar(
        influencer
          ? t('influencer from the package was added to the proposal', { name: influencer.name })
          : t('influencers from the packages were added to the proposal'),
        { variant: 'success' }
      );
    } catch (error) {
      // We only reset loading when error. If success, we need to wait selectedInfluencersMapIds recalculated then reset later by useEffect
      resetLoadingState();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleDisconnectFromProposal = async ({
    influencer,
    packageIds = []
  }: {
    packageIds?: ReadPackageAccount['id'][];
    influencer?: ReadPackageInfluencers;
  }) => {
    if (!packageIds.length && !influencer) return;

    try {
      if (influencer) {
        await callDeleteEngagementInfluencerProposal({
          variables: { input: { campaignId: engagementId, influencerIds: [influencer.influencerId] } }
        });
        enqueueSnackbar(t('influencer was removed from the proposal successfully', { name: influencer.name }), {
          variant: 'success'
        });
      } else {
        await callDisconnectPackageFromEngagementProposal({
          variables: { input: { packageIds, engagementCampaignId: engagementId } }
        });
        enqueueSnackbar(t('package was removed from the proposal successfully'), { variant: 'success' });
      }
    } catch (error) {
      // We only reset loading when error. If success, we need to wait selectedInfluencersMapIds recalculated then reset later by useEffect
      resetLoadingState();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const getSortSelectButtonProps = (listRecords: readonly ReadPackageAccount[]): SortSelectButtonProps => {
    const listRecordsLength = listRecords.length;
    const allPackageIdsInPage = listRecords.map((record) => record.id);
    const loading = !togglingState.packageId && !togglingState.influencerId && togglingState.loading;
    const numOfSelectedPackagesInPage = listRecords.reduce<number>(
      (acc, curr) => (curr.campaignSelected ? acc++ : acc),
      0
    );
    const hasAtLeastOneInfluencerSelectedInPage = !!selectedInfluencersMapIds.size;
    const hasAllPackagesSelectedInPage = listRecordsLength > 0 && numOfSelectedPackagesInPage === listRecordsLength;

    return {
      loading,
      checked: hasAtLeastOneInfluencerSelectedInPage,
      hasAllSelected: hasAllPackagesSelectedInPage,
      indeterminate: numOfSelectedPackagesInPage !== listRecordsLength,
      disabled:
        loading ||
        !listRecords.length ||
        hideTogglePackageFromProposalBtn ||
        hideToggleInfluencerFromProposalBtn ||
        getEngagementProposalAddRemoveButtonDisableState(hasAllPackagesSelectedInPage ? 'Remove' : 'Add', status),
      onClick: async () => {
        setTogglingState({ packageId: null, loading: true });

        if (hasAllPackagesSelectedInPage) {
          await handleDisconnectFromProposal({ packageIds: allPackageIdsInPage });
        } else {
          await handleConnectToProposal({ packageIds: allPackageIdsInPage });
        }
      },
      label: hasAtLeastOneInfluencerSelectedInPage
        ? t('numberInfluencersAddedToProposal', { count: selectedInfluencersMapIds.size })
        : t('addInfluencersToProposal')
    };
  };

  const getListSelectButtonProps = ({
    id,
    accounts,
    campaignSelected
  }: ReadPackageAccount): WidgetSelectButtonProps => {
    const checked = !!campaignSelected;
    const loading = togglingState.packageId === id && togglingState.loading;

    return {
      checked,
      loading: loading && !togglingState.influencerId, // Not loading if selecting/unselecting influencer, only disable
      tooltipProps: !accounts ? { help: t('cannotAddEmptyPackage'), disabled: false } : undefined,
      disabled:
        loading ||
        !accounts ||
        hideTogglePackageFromProposalBtn ||
        getEngagementProposalAddRemoveButtonDisableState(checked ? 'Remove' : 'Add', status),
      onClick: async () => {
        setTogglingState({ packageId: id, loading: true });

        if (campaignSelected) {
          await handleDisconnectFromProposal({ packageIds: [id] });
        } else {
          await handleConnectToProposal({ packageIds: [id] });
        }
      }
    };
  };

  const getInfluencersListSelectButtonProps = (
    { id }: ReadPackageAccount,
    influencer: ReadPackageInfluencers
  ): WidgetSelectButtonProps => {
    const isSelected = selectedInfluencersMapIds?.has(influencer.influencerId) || false;
    const loading = togglingState.influencerId === influencer.influencerId && togglingState.loading;

    return {
      loading,
      checked: isSelected,
      disabled:
        loading ||
        hideTogglePackageFromProposalBtn ||
        hideToggleInfluencerFromProposalBtn ||
        (togglingState.packageId === id && togglingState.loading) || // Disable when package is being connected/disconnected
        getEngagementProposalAddRemoveButtonDisableState(isSelected ? 'Remove' : 'Add', status),
      onClick: async () => {
        setTogglingState({ packageId: id, influencerId: influencer.influencerId, loading: true });

        if (isSelected) {
          await handleDisconnectFromProposal({ influencer });
        } else {
          await handleConnectToProposal({ packageIds: [id], influencer });
        }
      }
    };
  };

  return {
    getSortSelectButtonProps: !hideTogglePackageFromProposalBtn ? getSortSelectButtonProps : undefined,
    getListSelectButtonProps: !hideTogglePackageFromProposalBtn ? getListSelectButtonProps : undefined,
    getInfluencersListSelectButtonProps: !hideToggleInfluencerFromProposalBtn
      ? getInfluencersListSelectButtonProps
      : undefined
  };
};
