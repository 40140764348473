import { isAfter, isSameDay } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HubspotDealData } from '@/graphql';
import { useAllPicUserOptions } from '@/shared/hooks';
import { HubspotDealsFieldProps, HubspotDealsField } from '@/shared/molecules';
import { MarketplaceFormKeys, MarketplaceFormValues } from '../types';

export const HubspotDealField = (props: Omit<HubspotDealsFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext<MarketplaceFormValues>();
  const { allPicsUserOptions } = useAllPicUserOptions({ fetchPolicy: 'cache-first' });

  const values = getValues();
  const isAddMode = !values.id;

  const handleHubspotDealsChange = (_: string, value: string[], hubspotDeals: HubspotDealData[] = []) => {
    setValue(MarketplaceFormKeys.hubspotDealIds, value);
    const hubspotDeal = hubspotDeals.length === 1 ? hubspotDeals[0] : undefined;

    if (isAddMode && hubspotDeal) {
      // this may not work cause ownerName from bigQuery may not match our PICs name
      const salesPicId = hubspotDeal?.ownerName
        ? allPicsUserOptions.find((el) => el.label === hubspotDeal?.ownerName)?.value
        : null;
      setValue(MarketplaceFormKeys.salesPicIds, salesPicId ? [salesPicId] : [], { shouldValidate: true });
      setValue(MarketplaceFormKeys.countryId, hubspotDeal?.country || '', { shouldValidate: true });
      const isCampaignPeriodValid =
        hubspotDeal?.startDate &&
        (isAfter(new Date(hubspotDeal?.startDate), new Date()) ||
          isSameDay(new Date(hubspotDeal?.startDate), new Date()));
      if (isCampaignPeriodValid) {
        // if campaign has correct start date it should have correct endDate too
        setValue(
          MarketplaceFormKeys.period,
          { startDate: hubspotDeal?.startDate || '', endDate: hubspotDeal?.endDate || '' },
          { shouldValidate: true }
        );
      }
      setValue(MarketplaceFormKeys.title, hubspotDeal?.dealName || '', { shouldValidate: true });
    }
  };

  return (
    <HubspotDealsField
      title={t('Selector.Hubspot Deal')}
      onChange={handleHubspotDealsChange}
      name={MarketplaceFormKeys.hubspotDealIds}
      {...props}
    />
  );
};
