import { css } from '@emotion/react';
import { UGCPostStatus as UGCPostStatusType, InstagramUGCManagementPostDetailsPayload } from '@/graphql';
import { Anchor, Icon, Loading, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostCaption, PostStatistics, PostMediaPreview, SingleSelectField } from '@/shared/molecules';
import { AppLanguage } from '@/shared/types';
import { checkIsReelPost, localizedDateFormatter } from '@/shared/utils';
import { AnalyticsSelectedAccountInfo } from '../../../../types';
import { useUGCPostOptions, useUpdateUGCPostStatus } from '../../../hooks';
import { UGCPostStatus } from '../../UGCPostStatus';

interface DefaultTemplateProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
  data: InstagramUGCManagementPostDetailsPayload;
}

export const DefaultTemplate = ({
  analyticsAccount,
  data: { status, postUrl, postSNSId, likes, comments, content, mediaURLs, postDate }
}: DefaultTemplateProps) => {
  const { t, i18n } = useQueryHelper();
  const { statusOptions } = useUGCPostOptions();
  const { handleChangeStatus, updating } = useUpdateUGCPostStatus({ postSNSId, analyticsAccount });

  return (
    <>
      {updating ? <Loading css={styles.loading} /> : null}

      <div css={{ flex: 1, display: 'flex', padding: '16px' }}>
        <div css={{ position: 'relative' }}>
          <UGCPostStatus status={status} />
          <PostMediaPreview images={mediaURLs} isReel={checkIsReelPost(postUrl)} />
        </div>

        <div css={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div css={{ display: 'flex' }}>
            <PostStatistics
              postUrl={postUrl}
              statistics={{ likes, comments }}
              socialMedia={analyticsAccount.socialAccountType}
            />
            {postDate ? (
              <div css={styles.postDate}>{localizedDateFormatter(postDate, 'PPP', i18n.language as AppLanguage)}</div>
            ) : null}
          </div>

          <PostCaption caption={content} css={{ marginTop: '16px' }} />

          <p css={{ fontWeight: 600, fontSize: '12px', marginTop: '16px' }}>
            {t("Let's track the permission process")}
          </p>
          <div css={styles.processWrapper}>
            <div css={styles.processItem}>
              <TextCutter lines={2} text={`1. ${t('Ask creator permission by your Instagram DM')}`} />
              <Anchor css={styles.link} href={postUrl} target="_blank">
                {t('Contact via Instagram')}&nbsp;
                <Icon icon="external-link" size={12} />
              </Anchor>
            </div>
            <div css={styles.processItem}>
              <TextCutter lines={1} text={`2. ${t('Update the status')}`} />
              <SingleSelectField
                name="Status"
                value={status}
                hideDeselectOption
                disabled={updating}
                options={statusOptions}
                css={{ marginTop: '18px' }}
                setFieldValue={(_, value) => handleChangeStatus(value as UGCPostStatusType)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const styles = {
  postDate: css({
    fontSize: '13px',
    fontWeight: 400,
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    alignSelf: 'flex-end'
  }),
  link: css({
    display: 'flex',
    fontSize: '14px',
    marginTop: '12px',
    lineHeight: '100%',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    color: THEME.text.colors.blue
  }),
  processWrapper: css({
    display: 'flex',
    marginTop: '8px',
    borderRadius: '3px',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  loading: css({
    top: 0,
    left: 0,
    margin: 0,
    zIndex: 5,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.4)'
  }),
  processItem: css({
    flex: 1,
    boxSizing: 'border-box',
    p: { fontWeight: 400, fontSize: '12px' },
    '&:first-of-type': {
      zIndex: 1,
      maxWidth: '180px',
      position: 'relative',
      backgroundColor: THEME.background.colors.white,
      padding: '24px 19px 21px 11px',
      '&::after': {
        top: 0,
        zIndex: -1,
        content: '""',
        right: '-32px',
        position: 'absolute',
        borderLeft: '32px solid #fff',
        borderTop: '55px solid transparent',
        borderBottom: '55px solid transparent'
      }
    },
    '&:last-of-type': { padding: '24px 19px 21px 50px' }
  })
};
