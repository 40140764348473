import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ApproveTiktokSpecialCampaign.graphql';
import { Mutation, MutationapproveTiktokSpecialCampaignArgs } from '../../__generated__/globalTypes';

export const useApproveTiktokSpecialCampaignMutation = (
  options?: MutationHookOptions<Mutation, MutationapproveTiktokSpecialCampaignArgs>
) => {
  const [callApproveTiktokSpecialCampaign, result] = useMutation<Mutation, MutationapproveTiktokSpecialCampaignArgs>(
    MUTATION,
    options
  );

  return { callApproveTiktokSpecialCampaign, ...result };
};

export type ApproveTiktokSpecialCampaignMutationFunction = Mutation['approveTiktokSpecialCampaign'];
