import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { CircularProgress } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

interface WrapperProps {
  loading?: boolean;
  className?: string;
  children?: ReactNode;
  hasExtraHeight?: boolean;
}
export const Wrapper = ({ loading, children, className, hasExtraHeight }: WrapperProps) => (
  <div css={styles.wrapper(hasExtraHeight)} className={className}>
    {!loading ? (
      children
    ) : (
      <div css={styles.loading}>
        <CircularProgress size={64} thickness={1} backgroundColor="#dee5ec" />
      </div>
    )}
  </div>
);
const styles = {
  wrapper: (hasBiggerHeight?: boolean) => css({
    width: '838px',
    display: 'flex',
    minHeight: '440px',
    height: hasBiggerHeight ? 'max-content' : '440px',
    padding: '24px 24px 16px',
    borderRadius: '5px',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white
  }),
  loading: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: 'inherit',
    justifyContent: 'center'
  })
};
