import { css } from '@emotion/react';

import { useMarketplacePermissions } from '@/auth';
import {
  useMarketplaceDraftPostQuery,
  useMarketplacePostHistoryQuery,
  MarketplaceCampaignDraftPostStatus,
  useRejectMarketplaceDraftPostMutation,
  useApproveMarketplaceDraftPostMutation,
  namedOperations
} from '@/graphql';
import {
  Modal,
  Button,
  FormStyle,
  ModalTitle,
  ModalContent,
  ModalFooterActions,
  RenderDataWithFallback
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useToggleState } from '@/shared/hooks';

import { HistorySection, PostInfoSection, RequestModificationButton, RequestModificationMessage } from './shared';

interface DraftTemplateProps {
  postId: number;
  onClose?: () => void;
}

export const DraftTemplate = ({ postId, onClose }: DraftTemplateProps) => {
  const confirmModal = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { hideApproveRejectInappropriatePostBtn } = useMarketplacePermissions();
  const { data, loading } = useMarketplaceDraftPostQuery({
    variables: { draftId: postId },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      onClose?.();
    }
  });
  const { data: historyData, loading: fetchingHistory } = useMarketplacePostHistoryQuery({
    variables: { draftId: postId },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      onClose?.();
    }
  });
  const { callApproveMarketplaceDraftPost, loading: approving } = useApproveMarketplaceDraftPostMutation({
    refetchQueries: [namedOperations.Query.AllMarketplacePosts]
  });
  const { callRejectMarketplaceDraftPost, loading: rejecting } = useRejectMarketplaceDraftPostMutation({
    refetchQueries: [
      namedOperations.Query.AllMarketplacePosts,
      namedOperations.Query.MarketplaceDraftPost,
      namedOperations.Query.MarketplacePostHistory
    ]
  });

  const marketplaceDraftPost = data?.marketplaceDraftPost;

  if (!marketplaceDraftPost) {
    return null;
  }

  const { id, status, rejectedDate, rejectedMessage, createdDate, caption, images, influencer } = marketplaceDraftPost;
  const isRejected = status === MarketplaceCampaignDraftPostStatus.REJECTED;
  const hasRequestModificationButton =
    !hideApproveRejectInappropriatePostBtn && status === MarketplaceCampaignDraftPostStatus.DRAFT;
  const hasApproveButton =
    !hideApproveRejectInappropriatePostBtn && status !== MarketplaceCampaignDraftPostStatus.APPROVED;

  const handleSendModification = async (message: string) => {
    try {
      await callRejectMarketplaceDraftPost({ variables: { input: { draftId: id, message } } });
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleApproveDraftPost = async () => {
    try {
      await callApproveMarketplaceDraftPost({ variables: { input: { draftId: id } } });
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      onClose?.();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <RenderDataWithFallback loading={loading || fetchingHistory}>
      <div css={{ padding: '24px' }}>
        <PostInfoSection
          hasImageDownload
          caption={caption}
          images={[...(images || [])]}
          influencer={{
            id: influencer.id,
            name: influencer.name,
            postDate: createdDate,
            avatar: influencer.avatar,
            followers: influencer.followersCount
          }}
        />

        {hasRequestModificationButton ? (
          <RequestModificationButton loading={approving || rejecting} onSubmit={handleSendModification} />
        ) : null}

        {isRejected ? <RequestModificationMessage date={rejectedDate || ''} message={rejectedMessage} /> : null}
      </div>

      <HistorySection data={historyData?.marketplacePostHistory} isDraftPost />

      {hasApproveButton ? (
        <FormStyle.ButtonSubmitWrapper css={styles.buttonSubmitWrapper}>
          <p css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3, maxWidth: '204px' }}>
            {t('You can send feedback to creator via Email and AnyCreator')}
          </p>
          <Button
            variant="blue"
            disabled={isRejected}
            onClick={confirmModal.open}
            loading={approving || rejecting}
            title={t('Button.Approve draft')}
            css={{ minWidth: 'unset', padding: '0 32px' }}
            tooltip={{
              disabled: !isRejected,
              help: t('Tooltip.You cannot approve the draft post until the creator submits a new draft post')
            }}
          />
        </FormStyle.ButtonSubmitWrapper>
      ) : null}

      <Modal open={confirmModal.status} onClose={confirmModal.close} css={{ width: '480px' }}>
        <ModalContent>
          <ModalTitle>{t('Approve draft post')}</ModalTitle>

          <p css={{ marginTop: '16px' }}>{t('Are you sure you want to approve this draft post?')}</p>
        </ModalContent>
        <ModalFooterActions
          cancelButtonProps={{ onClick: confirmModal.close }}
          submitButtonProps={{
            title: t('Button.Approve'),
            onClick: handleApproveDraftPost,
            loading: { showIcon: true, status: approving }
          }}
        />
      </Modal>
    </RenderDataWithFallback>
  );
};

const styles = {
  buttonSubmitWrapper: css({
    zIndex: 5,
    bottom: 0,
    position: 'sticky',
    justifyContent: 'space-between',
    backgroundColor: THEME.background.colors.white
  })
};
