import { useTranslation } from 'react-i18next';
import { useInfluencerYoutubeComparePostsQuery } from '@/graphql';
import { useParamsSearch } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { HAS_NO_SELECTED_ACCOUNTS } from '@/shared/constants';
import {
  AnalyticsPostsOverview,
  InfluencerCompareYoutubeAccountsProps,
  InfluencerProfileCompareSearchSchemaType
} from '@/shared/organisms';
import { InfluencerYoutubeComparableAccountType } from '../types';
import { Template } from './Template';

export interface InfluencerCompareYoutubePostsProps
  extends Pick<InfluencerCompareYoutubeAccountsProps, 'accounts' | 'hiddenAccountIds'> {
  mainAccount: InfluencerYoutubeComparableAccountType;
}

export const InfluencerCompareYoutubePosts = ({
  accounts,
  mainAccount,
  hiddenAccountIds
}: InfluencerCompareYoutubePostsProps) => {
  const { t } = useTranslation();
  const { filter } = useParamsSearch<InfluencerProfileCompareSearchSchemaType>();
  const { data, loading } = useInfluencerYoutubeComparePostsQuery({
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      socialAccountId: mainAccount.id
    })
  });

  const postsData = data?.influencerYoutubeComparePosts;
  const comparableAccounts = accounts.filter((account) => !hiddenAccountIds?.includes(account.id));

  return (
    <AnalyticsPostsOverview
      data={postsData}
      loading={loading}
      help={t('Tooltip.comparePosts')}
      dataNotAvailable={!comparableAccounts.length || !postsData}
      wrapperText={!comparableAccounts.length ? HAS_NO_SELECTED_ACCOUNTS : undefined}
      moreDetailProps={{
        to: '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos',
        params: { socialMediaAccountId: mainAccount.id.toString() },
        search: {
          filter: {
            endDate: filter.endDate,
            startDate: filter.startDate,
            name: mainAccount.username
          }
        }
      }}
    >
      {({ data }) => <Template data={data} comparableAccounts={comparableAccounts} />}
    </AnalyticsPostsOverview>
  );
};
