import { QueryHookOptions } from '@apollo/client';

import { Query, useAllTalentAgenciesQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';

export const useAllTalentAgencyOptions = (options?: QueryHookOptions<Query>) => {
  const { data, loading } = useAllTalentAgenciesQuery({
    fetchPolicy: 'cache-and-network',
    ...options
  });

  return {
    loading,
    allTalentAgencyOptions: getOptions(data?.allTalentAgencies)
  };
};
