import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { useTableMaxHeight } from '@/shared/hooks';
import { InstagramUserHashtagListPostsItemPayload } from '@/graphql';
import { InstagramHashtagPostDetailsModal } from '@/shared/molecules';
import { ProfilePostsCount } from '../../ProfilePostsList';
import { useHashtagsAnalyticsPostsListTable } from './hooks';

export type HashtagsAnalyticsPostsListProps = TableData<InstagramUserHashtagListPostsItemPayload>;

export const HashtagsAnalyticsPostsList = ({ loading, data = [], totalRecords }: HashtagsAnalyticsPostsListProps) => {
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();
  const { rows, columns } = useHashtagsAnalyticsPostsListTable({ data });

  return (
    <>
      <ProfilePostsCount count={totalRecords} css={{ boxShadow: 'none' }} />
      <Table
        ref={offsetRef}
        loading={loading}
        maxHeight={defaultTableMaxHeight}
        data={{ rows, columns, rowHeight: '72px' }}
        hasBorder={!!totalRecords}
        noTopBorderRadius
        stickyHeader
      />
      <InstagramHashtagPostDetailsModal posts={data.map(({ postId }) => ({ id: postId }))} />
    </>
  );
};
