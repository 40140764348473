import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CountryOption, Option } from '@/shared/types';

const COUNTRY_KEY = 'Country';

export const useTranslateCountryOptions = (options: CountryOption[] = []): CountryOption[] =>
  useTranslateOptions(options, COUNTRY_KEY);

// Country Translation Function
export const useTranslateCountry = () => {
  const { t } = useTranslation();
  const tc = (value: string) => t(`${COUNTRY_KEY}.${value.includes('.') ? value.replace(/[.]/g, '') : value}`);

  return { tc };
};

export const useTranslateOptions = <V,>(options: Option<V, string>[] = [], key = 'Option'): Option<V, string>[] => {
  const { t, i18n, ready } = useTranslation();

  const translatedOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        label: t(`${key}.${option?.label?.includes('.') ? option?.label?.replace(/[.]/g, '') : option.label}`)
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language, options.length, ready, key]
  );

  return translatedOptions;
};
