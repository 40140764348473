import { EngagementDetail } from '@/graphql';
import { ProposalDerivedProps } from '../shared/types';
import { ProposalInfluencersDetailsModal } from './ProposalInfluencersDetailsModal';
import { RejectedInfluencersTable } from './RejectedInfluencersTable';
import { SelectedInfluencers } from './SelectedInfluencers';

interface ProposalInfluencersProps extends ProposalDerivedProps {
  engagement: EngagementDetail;
  hasTrackingReferral?: boolean;
  hasInfluencerCostPermission?: boolean;
}

export const ProposalInfluencers = ({
  jdMode,
  engagement,
  allInfluencers,
  hasTrackingReferral,
  selectedInfluencers,
  rejectedInfluencers,
  formattedInfluencers,
  formattedSocialAccounts,
  hasInfluencerCostPermission,
  formattedRejectedInfluencers
}: ProposalInfluencersProps) => (
  <>
    <SelectedInfluencers
      jdMode={jdMode}
      engagement={engagement}
      selectedInfluencers={selectedInfluencers}
      formattedInfluencers={formattedInfluencers}
      formattedSocialAccounts={formattedSocialAccounts}
      hasInfluencerCostPermission={hasInfluencerCostPermission}
      hasTrackingReferral={hasTrackingReferral}
    />

    {rejectedInfluencers.length > 0 ? (
      <RejectedInfluencersTable
        jdMode={jdMode}
        engagement={engagement}
        formattedRejectedInfluencers={formattedRejectedInfluencers}
        rejectedInfluencers={rejectedInfluencers}
        hasTrackingReferral={hasTrackingReferral}
        hasInfluencerCostPermission={hasInfluencerCostPermission}
      />
    ) : null}

    <ProposalInfluencersDetailsModal allInfluencers={allInfluencers} engagement={engagement} />
  </>
);
