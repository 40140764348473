import { z } from 'zod';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { YoutubeAnalyticsTagRankingSortOrder } from '@/graphql';

const defaultValues = {
  brandAccountName: '',
  endDate: getToday(),
  startDate: getThirtyDaysAgo()
};
export const AnalyticsProfileFilterSchema = z.object({
  brandAccountName: z.string().optional(),
  endDate: FilterValidations.string().default(defaultValues.endDate),
  startDate: FilterValidations.string().default(defaultValues.startDate)
});

export const AnalyticsProfileSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(AnalyticsProfileFilterSchema, defaultValues),
  sort: SearchFilterDefaults.sort(YoutubeAnalyticsTagRankingSortOrder).default({})
});

export type AnalyticsProfileSearchSchemaType = z.infer<typeof AnalyticsProfileSearchSchema>;
