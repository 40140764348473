import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramDashboardFollowerAnalytics.graphql';
import { Query, QueryinstagramDashboardFollowerAnalyticsArgs } from '../../__generated__/globalTypes';

export const useInstagramDashboardFollowerAnalyticsQuery = (
  options?: QueryHookOptions<Query, QueryinstagramDashboardFollowerAnalyticsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramDashboardFollowerAnalyticsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramDashboardFollowerAnalyticsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramDashboardFollowerAnalyticsArgs>
): QueryResult<Query, QueryinstagramDashboardFollowerAnalyticsArgs> & {
  getInstagramDashboardFollowerAnalytics: LazyQueryExecFunction<Query, QueryinstagramDashboardFollowerAnalyticsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramDashboardFollowerAnalytics, result] = useLazyQuery<
    Query,
    QueryinstagramDashboardFollowerAnalyticsArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInstagramDashboardFollowerAnalytics, ...result };
};

export const useInstagramDashboardFollowerAnalyticsPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramDashboardFollowerAnalytics = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramDashboardFollowerAnalyticsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramDashboardFollowerAnalyticsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramDashboardFollowerAnalytics };
};
