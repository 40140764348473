import { ZodTypeAny, z } from 'zod';
import {
  AutoManagedCampaignType,
  CampaignSocialMediaType,
  CampaignTrackingOption,
  MarketplaceCampaignDetailType,
  MarketplaceCampaignStatus,
  UserRoles
} from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';
import { AFFILIATE_CAMPAIGN_TYPES } from '@/shared/constants';
import { ToggleOption } from '@/shared/types';
import { BaseValidations } from '@/shared/validations';
import { MarketplaceFormKeys } from './types';

/** contains all the fields (optional by default) + some which always needs to be always validated */
export const MarketplaceFormBaseSchema = z.object({
  [MarketplaceFormKeys.id]: z.number().optional(),
  [MarketplaceFormKeys.agencyMarginRate]: z.string(),
  [MarketplaceFormKeys.currency]: z.string(),
  [MarketplaceFormKeys.sampleUrl]: z.string(),
  [MarketplaceFormKeys.sellerName]: z.string(),
  [MarketplaceFormKeys.period]: BaseValidations.PeriodWithStartDateRequiredSchema,
  [MarketplaceFormKeys.hashtags]: z.array(z.string()),
  [MarketplaceFormKeys.marginRate]: z.number(),
  [MarketplaceFormKeys.campaignUrl]: z.string(),
  [MarketplaceFormKeys.regionIds]: z.array(z.string()),
  [MarketplaceFormKeys.advertiser]: BaseValidations.AdvertiserSchemaOptional,
  [MarketplaceFormKeys.salesPicIds]: z.array(z.string()),
  [MarketplaceFormKeys.preLaunchDate]: z.string(),
  [MarketplaceFormKeys.landingPageUrl]: z.string(),
  [MarketplaceFormKeys.hubspotDealIds]: z.array(z.string()).readonly(),
  [MarketplaceFormKeys.productThumbnail]: z.string(),
  [MarketplaceFormKeys.requireDraftPost]: z.boolean(),
  [MarketplaceFormKeys.isAllowMultiplePosts]: z.nativeEnum(ToggleOption),
  [MarketplaceFormKeys.couponUploadFiles]: z.array(z.string()).readonly(),
  [MarketplaceFormKeys.allowNewInfluencer]: z.nativeEnum(ToggleOption),
  [MarketplaceFormKeys.campaignMaterialLink]: z.string(),
  [MarketplaceFormKeys.needCouponsCount]: z.number().nullable(),
  [MarketplaceFormKeys.mkpServiceCampaignId]: z.string(),
  [MarketplaceFormKeys.status]: z.nativeEnum(MarketplaceCampaignStatus),
  [MarketplaceFormKeys.influencerManagementPicIds]: z.array(z.string()),
  [MarketplaceFormKeys.isAutoInfluencerApproval]: z.nativeEnum(ToggleOption),
  [MarketplaceFormKeys.maximumRevenuePerInfluencer]: z.string(),
  [MarketplaceFormKeys.socialMedia]: z.nativeEnum(CampaignSocialMediaType).or(z.literal('')),
  [MarketplaceFormKeys.trackingOptions]: z.array(z.nativeEnum(CampaignTrackingOption)).readonly(),
  [MarketplaceFormKeys.campaignType]: z.nativeEnum(MarketplaceCampaignDetailType),
  [MarketplaceFormKeys.costPerClick]: z.string(),
  [MarketplaceFormKeys.costPerAction]: z.string(),
  [MarketplaceFormKeys.costPerComment]: z.string(),
  [MarketplaceFormKeys.costPerFollower]: z.string(),
  [MarketplaceFormKeys.costPerSaleTune]: z.string(),
  [MarketplaceFormKeys.additionalRequirement]: z.string(),
  [MarketplaceFormKeys.autoManagedCampaignType]: z.nativeEnum(AutoManagedCampaignType).optional(),
  [MarketplaceFormKeys.descriptionGenerator_productUrl]: z.string().optional(),
  [MarketplaceFormKeys.descriptionGenerator_websiteUrl]: z.string().optional(),
  [MarketplaceFormKeys.descriptionGenerator_additionalInstructions]: z.string().optional(),
  /** fields with validation */
  [MarketplaceFormKeys.clickUrl]: BaseValidations.UrlSchema,
  [MarketplaceFormKeys.budget]: BaseValidations.BudgetSchema,
  [MarketplaceFormKeys.minAge]: BaseValidations.MinAgeSchema,
  [MarketplaceFormKeys.maxAge]: BaseValidations.MaxAgeSchema,
  [MarketplaceFormKeys.genders]: BaseValidations.GendersArraySchema,
  [MarketplaceFormKeys.countryId]: BaseValidations.CountryIdSchema,
  [MarketplaceFormKeys.title]: BaseValidations.CampaignTitleSchema,
  [MarketplaceFormKeys.thumbnails]: BaseValidations.ThumbnailsSchema.readonly(),
  [MarketplaceFormKeys.requirement]: BaseValidations.RequirementSchema,
  [MarketplaceFormKeys.minFollowers]: BaseValidations.MinFollowersSchema,
  [MarketplaceFormKeys.maxFollowers]: BaseValidations.MaxFollowersSchema,
  [MarketplaceFormKeys.influencerCategoryIds]: BaseValidations.CategoryIdsSchema,
  [MarketplaceFormKeys.campaignCategoryId]: BaseValidations.CampaignCategorySchema,
  [MarketplaceFormKeys.costPerPost]: BaseValidations.MatchPositiveDecimalOrEmptyStringSchema,
  [MarketplaceFormKeys.costPerLike]: BaseValidations.MatchPositiveDecimalOrEmptyStringSchema,
  [MarketplaceFormKeys.costPerView]: BaseValidations.MatchPositiveDecimalOrEmptyStringSchema,
  [MarketplaceFormKeys.costPerShare]: BaseValidations.MatchPositiveDecimalOrEmptyStringSchema,
  [MarketplaceFormKeys.costPerOrder]: BaseValidations.MatchPositiveDecimalOrEmptyStringSchema,
  [MarketplaceFormKeys.costPerSaleReferral]: BaseValidations.MatchPositiveDecimalOrEmptyStringSchema,
  [MarketplaceFormKeys.serviceInformation]: BaseValidations.ServiceInformationSchema,
  [MarketplaceFormKeys.materials]: z.array(z.string()).or(z.tuple([])).readonly()
});

/** @description will add more validation rules based on the role, then partialProps will pick mandatory fields for validation */
export const refineBasedOnRoleSchema = (role: UserRoles, partialProps: Partial<Record<MarketplaceFormKeys, true>>) => {
  let baseSchema = MarketplaceFormBaseSchema as z.ZodObject<Record<MarketplaceFormKeys, ZodTypeAny>>; // this will keep checking on existing form keys and allows .omit().extend() to work
  switch (role) {
    case UserRoles.ADMINISTRATOR:
    case UserRoles.STAFF: {
      baseSchema = baseSchema
        .omit({
          [MarketplaceFormKeys.marginRate]: true,
          [MarketplaceFormKeys.advertiser]: true,
          [MarketplaceFormKeys.salesPicIds]: true,
          [MarketplaceFormKeys.influencerManagementPicIds]: true
        })
        .extend({
          [MarketplaceFormKeys.marginRate]: BaseValidations.MarginRateSchema,
          [MarketplaceFormKeys.salesPicIds]: BaseValidations.SalesPicsSchema,
          [MarketplaceFormKeys.advertiser]: BaseValidations.AdvertiserSchema,
          [MarketplaceFormKeys.influencerManagementPicIds]: BaseValidations.InfluencerManagementPicsSchema
        });
      break;
    }

    case UserRoles.AGENCY:
      baseSchema = baseSchema.omit({ [MarketplaceFormKeys.advertiser]: true }).merge(
        z.object({
          [MarketplaceFormKeys.advertiser]: BaseValidations.AdvertiserSchema
        })
      );
      break;

    default:
      break;
  }

  return (baseSchema.partial(partialProps) as unknown as typeof MarketplaceFormBaseSchema).superRefine(
    (values, ctx) => {
      const affiliateCampaign = values.campaignType && AFFILIATE_CAMPAIGN_TYPES.includes(values.campaignType);
      const withPostTracking = values.trackingOptions?.includes(CampaignTrackingOption.POST);

      if (partialProps.materials && !values.materials?.length) {
        ctx.addIssue({
          message: AllTranslationKeys.Error.requiredPostingSampleMessage,
          code: z.ZodIssueCode.custom,
          path: [MarketplaceFormKeys.materials]
        });
      }

      if (
        values.campaignType === MarketplaceCampaignDetailType.URL_TRACKING &&
        partialProps.landingPageUrl &&
        !values.landingPageUrl?.length
      ) {
        ctx.addIssue({
          message: 'Error.requiredLandingPageUrlMessage',
          code: z.ZodIssueCode.custom,
          path: [MarketplaceFormKeys.landingPageUrl]
        });
      }
      if (!affiliateCampaign || (affiliateCampaign && withPostTracking)) {
        const htResult = BaseValidations.HashtagsSchema.safeParse(values.hashtags);
        if (!values.socialMedia?.length && partialProps.socialMedia) {
          ctx.addIssue({
            message: AllTranslationKeys.Error.requiredSocialMediaMessage,
            code: z.ZodIssueCode.custom,
            path: [MarketplaceFormKeys.socialMedia]
          });
        }
        if (!htResult.success && partialProps.hashtags) {
          ctx.addIssue({
            message: htResult.error.issues.at(0)?.message,
            code: z.ZodIssueCode.custom,
            path: [MarketplaceFormKeys.hashtags]
          });
        }
      }
      if (partialProps.period && ((affiliateCampaign && withPostTracking) || !affiliateCampaign)) {
        const pResult = BaseValidations.PeriodEndDateRequiredSchema.safeParse(values.period?.endDate);
        if (!pResult.success) {
          ctx.addIssue({
            message: pResult.error.issues.at(0)?.message,
            code: z.ZodIssueCode.custom,
            path: ['period.endDate']
          });
        }
      }
    }
  );
};
