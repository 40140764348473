import { css } from '@emotion/react';
import { CampaignSocialMediaType, CampaignChartReport } from '@/graphql';
import { Radio } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { HighChart } from '@/shared/molecules';
import { useEngagementReportChart } from './useEngagementReportChart';

export interface EngagementReportChartProps {
  className?: string;
  data?: readonly CampaignChartReport[];
  socialMedias: readonly CampaignSocialMediaType[];
}

export const EngagementReportChart = ({ data, className, socialMedias }: EngagementReportChartProps) => {
  const { availableMetricOptions, chartOptions, selectedMetric, setSelectedMetric } = useEngagementReportChart({
    data,
    socialMedias
  });

  if (!data) {
    return null;
  }

  return (
    <div css={{ backgroundColor: THEME.background.colors.white, borderTop: THEME.border.base }} className={className}>
      <div css={styles.legendWrapper}>
        {availableMetricOptions.map((item) => {
          const isChecked = item.value === selectedMetric;

          return (
            <div css={styles.legend} key={item.value} onClick={() => setSelectedMetric(item.value)}>
              <Radio checked={isChecked} />
              <span css={[styles.label, { color: isChecked ? '#3892E5' : '#27313b' }]}>{item.label}</span>
              <span style={{ width: '8px', height: '8px', borderRadius: '2px', backgroundColor: item.color }} />
            </div>
          );
        })}
      </div>

      <HighChart options={chartOptions} />
    </div>
  );
};
const styles = {
  legendWrapper: css({
    gap: '8px 16px',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '24px 16px',
    boxSizing: 'border-box'
  }),
  legend: css({
    padding: '4px',
    display: 'flex',
    cursor: 'pointer',
    borderRadius: '3px',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: 'solid 1px #dee5ec',
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  label: css({
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '100%',
    padding: '0 4px 0 8px',
    letterSpacing: 'normal'
  })
};
