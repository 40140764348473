import { forwardRef } from 'react';
import { useToggleState } from '@/shared/hooks';
import { Icon } from '../Icon';
import { Input, InputProps } from './Input';

export interface InputPasswordProps extends InputProps {
  hasVisibleIcon?: boolean;
}

export const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  ({ className, hasVisibleIcon, ...restProps }, ref) => {
    const { status: isOpening, toggleStatus } = useToggleState(false);

    return (
      <div css={{ position: 'relative' }} className={className}>
        <Input ref={ref} {...restProps} type={isOpening ? 'text' : 'password'} />

        {hasVisibleIcon ? (
          <Icon
            size="18px"
            color="#6e7d89"
            onClick={toggleStatus}
            icon={isOpening ? 'eye' : 'eye-off'}
            css={{ position: 'absolute', top: '7px', right: '10px', cursor: 'pointer' }}
          />
        ) : null}
      </div>
    );
  }
);
InputPassword.displayName = 'InputPassword';
