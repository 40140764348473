import { ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { FormProvider } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { FormAction } from '@/shared/types';
import { BudgetBulkEditForm } from './BudgetBulkEditForm';
import { ProposeInfluencersBulkEditSchema } from './bulkEditSchema';
import { DefaultBudgetInputType, FormValues } from './types';

interface BudgetBulkEditModalProps {
  currency: string;
  loading: boolean;
  openModal: boolean;
  onSuccess?: () => void;
  toggleModal: () => void;
  initialValues?: FormValues;
  jdMode?: ProposalJobDescriptionBreakDownMode;
  onInfluencersBudgetSubmit: (defaultInput: DefaultBudgetInputType) => Promise<void>;
  onSocialAccountBudgetSubmit: (defaultInput: DefaultBudgetInputType) => Promise<void>;
}

export const BudgetBulkEditModal = ({
  jdMode,
  loading,
  currency,
  onSuccess,
  openModal,
  toggleModal,
  onSocialAccountBudgetSubmit,
  onInfluencersBudgetSubmit,
  initialValues = { budget: '', influencerCost: '' }
}: BudgetBulkEditModalProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const handleBulkFormSubmit: FormAction<FormValues>['onSubmit'] = async ({ budget, influencerCost }) => {
    const budgetNum = budget ? Number(budget) : 0;
    const influencerCostNum = influencerCost ? Number(influencerCost) : 0;
    if (!budget) {
      return;
    }

    const defaultInput: DefaultBudgetInputType = {
      budget: budgetNum,
      influencerCost: influencerCostNum,
      profit: budgetNum - influencerCostNum
    };

    try {
      if (jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN) {
        await onInfluencersBudgetSubmit(defaultInput);
      } else {
        await onSocialAccountBudgetSubmit(defaultInput);
      }

      enqueueSnackbar(t('succeededUpdateWhitelist'), { variant: 'success' });
      onSuccess?.();

      toggleModal();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <FormProvider<FormValues>
      defaultValues={initialValues}
      zodSchema={ProposeInfluencersBulkEditSchema}
      onSubmit={handleBulkFormSubmit}
    >
      <BudgetBulkEditForm open={openModal} currency={currency} onClose={toggleModal} loading={loading} />
    </FormProvider>
  );
};
