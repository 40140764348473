import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RejectMarketplaceCampaign.graphql';
import { Mutation, MutationrejectMarketplaceCampaignArgs } from '../../__generated__/globalTypes';

export const useRejectMarketplaceCampaignMutation = (
  options?: MutationHookOptions<Mutation, MutationrejectMarketplaceCampaignArgs>
) => {
  const [callRejectMarketplaceCampaign, result] = useMutation<Mutation, MutationrejectMarketplaceCampaignArgs>(
    MUTATION,
    options
  );

  return { callRejectMarketplaceCampaign, ...result };
};

export type RejectMarketplaceCampaignMutationFunction = Mutation['rejectMarketplaceCampaign'];
