import { useTranslation } from 'react-i18next';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { SignInSignUpLayout, TermsAnytagEn, TermsJP } from '@/shared/organisms';
import { Language } from '@/shared/types';

export const TermsOfService = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.Terms' });
  const { i18n } = useTranslation();

  return (
    <SignInSignUpLayout
      footerProps={{ className: { paddingBottom: '85px' } }}
      headerProps={{ to: '/', className: { paddingTop: '40px' } }}
    >
      {i18n.language === Language.ja ? <TermsJP /> : <TermsAnytagEn />}
    </SignInSignUpLayout>
  );
};
