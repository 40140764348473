import { useTranslation } from 'react-i18next';
import { InstagramCompareOverviewSummaryData, InstagramComparePrimaryAccount } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { InfluencerAvatar } from '@/shared/molecules';
import { AnalyticsCompareOverviewTable } from '../../shared';
import { AnalyticsInstagramComparableAccountType } from '../types';

export interface AnalyticsCompareInstagramOverviewTableProps {
  data: InstagramComparePrimaryAccount[];
  comparableAccounts: AnalyticsInstagramComparableAccountType[];
}

const getNumberGrowthProps = (data?: InstagramCompareOverviewSummaryData) => ({
  total: data?.total || 0,
  growth: data?.growth || 0,
  rate: data?.percentage || 0
});

export const AnalyticsCompareInstagramOverviewTable = ({
  data,
  comparableAccounts
}: AnalyticsCompareInstagramOverviewTableProps) => {
  const { t } = useTranslation();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Account'), sticky: { active: true, hasBorderRight: true } },
    { title: t('HeaderColumn.Followers (Total)'), help: t<string>('Tooltip.compareFollowersTotal') },
    { title: t('HeaderColumn.Posts (Total)'), help: t<string>('Tooltip.comparePostsTotal') },
    { title: t('HeaderColumn.Avg Likes'), help: t<string>('Tooltip.compareAvgLikes') },
    { title: t('HeaderColumn.Maximum Likes'), help: t<string>('Tooltip.compareMaximumLikes') },
    { title: t('HeaderColumn.Avg Comments'), help: t<string>('Tooltip.compareAvgComments') }
  ];
  const rows: RowProps[] = comparableAccounts.map(({ id, username, avatarUrl }) => {
    const summary = data.find((item) => item.id === id)?.summary;

    return [
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <InfluencerAvatar size="42px" src={avatarUrl} css={{ borderRadius: 0 }} />
        <TableCell.Text value={username} css={{ marginLeft: '8px' }} />
      </div>,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.totalFollowers)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.totalPosts)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.averageLikes)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.maximumLikes)} />,
      <TableCell.NumberGrowth {...getNumberGrowthProps(summary?.averageComments)} />
    ];
  });

  return comparableAccounts.length ? (
    <AnalyticsCompareOverviewTable data={{ rows, columns, rowHeight: '62px' }} />
  ) : null;
};
