import { useState } from 'react';
import { useAllMarketplacesQuery } from '@/graphql';
import { NOT_FOUND, THEME } from '@/shared/constants';
import { Header, ListWithPagination } from '@/shared/molecules';
import { useMarketplacePermissions, useUserRoles } from '@/auth';
import { ButtonLink, RenderDataWithFallback } from '@/shared/atoms';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useCustomHeader, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import {
  MarketplaceOnboarding,
  MarketplaceCampaignsList,
  MarketplaceCampaignsFilter,
  useMarketplaceCampaignsFilter
} from '@/shared/organisms';

export const Marketplaces = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'documentTitle.Marketplace', appHeader: 'appHeader.Marketplace' });
  const { isAdvertiser } = useUserRoles();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { hideAddMarketplaceBtn } = useMarketplacePermissions();
  const { page, limit, filter } = useMarketplaceCampaignsFilter();
  const [isOnboarding, setIsOnboarding] = useState(false);
  const { data, previousData, loading } = useAllMarketplacesQuery({
    fetchPolicy: 'no-cache', // will always trigger onError to setIsOnboarding
    variables: getValuableVariables({
      ...filter,
      limit,
      offset: getOffset(page, limit),
      status: filter.status || null,
      campaignDetailType: filter.campaignDetailType || null
    }),
    onError: (error) => {
      if (error.message === NOT_FOUND) {
        setIsOnboarding(true);
      } else {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    }
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allMarketplacesCount?.totalNumber;

  return (
    <RenderDataWithFallback
      hasNoData={isOnboarding}
      loading={isAdvertiser && loading}
      noDataNode={<MarketplaceOnboarding />}
    >
      <Header>
        <ButtonLink
          variant="default"
          title={t('Button.Reorder Campaigns')}
          to="/marketplace/reorder"
          search={{ filter: { countryId: filter.countryId } }}
          css={{
            minWidth: '143px',
            marginLeft: 'auto',
            color: THEME.text.colors.blue,
            borderColor: THEME.border.colors.blue.lv2
          }}
        />

        {!hideAddMarketplaceBtn ? (
          <ButtonLink
            variant="blue"
            to="/marketplace/add"
            css={{ minWidth: '143px' }}
            title={t('Button.Add Campaign')}
            prefixIcon={{ icon: 'plus', size: '10px' }}
          />
        ) : null}
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <MarketplaceCampaignsFilter />
        <MarketplaceCampaignsList loading={loading} totalRecords={totalRecords} data={result?.allMarketplaces || []} />
      </ListWithPagination>
    </RenderDataWithFallback>
  );
};
