import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { SocialAccountType } from '@/graphql';

interface SelectedInfluencer {
  influencerId: number;
  socialAccountId?: number | string | null;
  socialAccountType?: SocialAccountType | null;
}

interface ContextType {
  selectedInfluencers: SelectedInfluencer[];
  setSelectedInfluencers: Dispatch<SetStateAction<SelectedInfluencer[]>>;
}

const Context = createContext<ContextType>({ selectedInfluencers: [], setSelectedInfluencers: () => undefined });

interface Props {
  children: React.ReactElement | Array<React.ReactElement>;
}

export const InfluencersSearchProvider = ({ children }: Props) => {
  const [selectedInfluencers, setSelectedInfluencers] = useState<SelectedInfluencer[]>([]);

  return <Context.Provider value={{ selectedInfluencers, setSelectedInfluencers }}>{children}</Context.Provider>;
};

export const useInfluencersSearchContext = () => useContext<ContextType>(Context);
