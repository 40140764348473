import { namedOperations, useDeleteBrandAccountAnalyticsMutation } from '@/graphql';
import { useSelectItem, useQueryHelper } from '@/shared/hooks';

export const useAnalyticsDeleteAccount = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem();
  const { callDeleteBrandAccountAnalytics, loading: deleting } = useDeleteBrandAccountAnalyticsMutation({
    refetchQueries: [namedOperations.Query.AllAccountAnalyticsForAdminV2]
  });

  const handleDeleteAccount = async () => {
    if (!selectedItem) return;

    try {
      await callDeleteBrandAccountAnalytics({ variables: { input: { brandAccountId: selectedItem.id } } });
      enqueueSnackbar(t('succeededInDeleting', { name: selectedItem.name }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: selectedItem.name }), { variant: 'error' });
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      resetSelectedItem();
    }
  };

  return {
    deleting,
    handleDeleteAccount,
    deletingAccount: selectedItem,
    setDeletingAccount: setSelectedItem,
    resetDeletingAccount: resetSelectedItem
  };
};
