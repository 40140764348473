import { FormProvider } from '@/shared/atoms';
import { TikTokSettingsFormSchema } from './schema';
import { Template, TikTokSettingsTemplateFormProps } from './Template';
import { TikTokSettingsFormProps } from './types';
import { defaultTikTokSettingsFormValues, getTikTokSettingsFormValues } from './utils';

export const TikTokSettingsForm = ({
  onSubmit,
  className,
  onOpenPasswordModal,
  defaultValues = defaultTikTokSettingsFormValues
}: TikTokSettingsFormProps & TikTokSettingsTemplateFormProps) => (
  <FormProvider
    onSubmit={onSubmit}
    zodSchema={TikTokSettingsFormSchema}
    defaultValues={defaultValues}
    className={className}
  >
    <Template onOpenPasswordModal={onOpenPasswordModal} />
  </FormProvider>
);

export { type TikTokSettingsFormProps, getTikTokSettingsFormValues };
