import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/BulkUpdateUserProposalAccountsBudget.graphql';
import { Mutation, MutationbulkUpdateUserProposalAccountsBudgetArgs } from '../../__generated__/globalTypes';

export const useBulkUpdateUserProposalAccountsBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationbulkUpdateUserProposalAccountsBudgetArgs>
) => {
  const [callBulkUpdateUserProposalAccountsBudget, result] = useMutation<
    Mutation,
    MutationbulkUpdateUserProposalAccountsBudgetArgs
  >(MUTATION, options);

  return { callBulkUpdateUserProposalAccountsBudget, ...result };
};

export type BulkUpdateUserProposalAccountsBudgetMutationFunction = Mutation['bulkUpdateUserProposalAccountsBudget'];
