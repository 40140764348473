import { ReactNode, createContext, useContext } from 'react';
import { useUserRoles } from '@/auth';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useYoutubeCMSFilter } from '@/shared/organisms';
import { useYoutubeCmsConfirmedRevenueLatestMonthQuery } from '@/graphql';

interface ContextType {
  canModifyYoutubeCMSData: boolean;
  confirmedRevenueLatestMonth?: string;
}

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  children: ReactNode;
}

export const YoutubeCMSProvider = ({ children }: Props) => {
  const { filter } = useYoutubeCMSFilter();
  const { isAdminStaff, isTalentAgency } = useUserRoles();
  const { data, loading } = useYoutubeCmsConfirmedRevenueLatestMonthQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isAdminStaff // we don't show confirmed revenue page for non-admin/staff, so no need to fetch this api
  });

  const confirmedRevenueLatestMonth = data?.youtubeCmsConfirmedRevenueLatestMonth?.month;
  const selectingDate = filter.month ? new Date(filter.month) : undefined;
  const confirmedDate = confirmedRevenueLatestMonth ? new Date(confirmedRevenueLatestMonth) : undefined;
  const canModifyYoutubeCMSData = isTalentAgency
    ? !!selectingDate // TA doesn't have confirmed revenue, so no confirmed date, they can modify the data anytime
    : !!selectingDate && // Admin/Staff has confirmed revenue, so we need only allow them to modify the data if the selecting date is greater than confirmed date
      !!confirmedDate &&
      selectingDate.getTime() > confirmedDate.getTime();

  return (
    <RenderDataWithFallback loading={loading}>
      <Context.Provider value={{ canModifyYoutubeCMSData, confirmedRevenueLatestMonth }}>{children}</Context.Provider>
    </RenderDataWithFallback>
  );
};

export const useYoutubeCMSContext = () => useContext<ContextType>(Context);
