import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { Button, FormStyle } from '@/shared/atoms';
import { EngagementPostFormValues } from '../../types';

interface FooterButtonsProps {
  onClose?: () => void;
  onSubmit?: () => void;
}

export const FooterButtons = ({ onClose, onSubmit }: FooterButtonsProps) => {
  const { t } = useTranslation();
  const { isAdvertiser } = useUserRoles();
  const {
    formState: { isSubmitting }
  } = useFormContext<EngagementPostFormValues>();

  return onClose || onSubmit ? (
    <FormStyle.ButtonSubmitWrapper css={{ gap: '16px' }}>
      {onClose ? <Button onClick={onClose} title={t('Button.Cancel')} css={{ textTransform: 'uppercase' }} /> : null}

      {onSubmit ? (
        <FormStyle.ButtonSubmit
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={isAdvertiser}
          title={t('Button.Save')}
          css={{ minWidth: 'unset', padding: '0 16px' }}
        />
      ) : null}
    </FormStyle.ButtonSubmitWrapper>
  ) : null;
};
