import { useInfluencerTwitterAnalyticsOverviewQuery } from '@/graphql';
import { useInfluencerProfileSearch, AnalyticsTwitterDashboardOverviewTemplate } from '@/shared/organisms';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsTwitterProfileOverviewProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerAnalyticsTwitterProfileOverview = ({
  socialAccount
}: InfluencerAnalyticsTwitterProfileOverviewProps) => {
  const { reinitParamsSearch } = useInfluencerProfileSearch();
  const { data, loading } = useInfluencerTwitterAnalyticsOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: { socialAccountId: socialAccount.id },
    onCompleted: ({ influencerTwitterAnalyticsOverview }) => {
      reinitParamsSearch({ lastUpdated: influencerTwitterAnalyticsOverview?.lastUpdated || '' });
    }
  });

  return (
    <div css={{ marginTop: '16px' }}>
      <AnalyticsTwitterDashboardOverviewTemplate
        loading={loading}
        isInfluencerProfile
        accountId={socialAccount.id}
        data={data?.influencerTwitterAnalyticsOverview}
      />
    </div>
  );
};
