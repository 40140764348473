export interface Material {
  id?: number;
  url: string;
  modified?: number;
}
export enum DownloadFileType {
  CSV = 'CSV',
  PPTX = 'PPTX',
  EXCEL = 'EXCEL',
  SPREADSHEET = 'SPREADSHEET',
  GOOGLE_SLIDE = 'GOOGLE_SLIDE'
}
export enum EXPORT_TYPE {
  PAGES = 'PAGES',
  ROWS = 'ROWS'
}
