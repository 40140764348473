import { useFormContext } from 'react-hook-form';
import { useUserRoles } from '@/auth';
import {
  EngagementDetail,
  SocialAccountType,
  CampaignSocialMediaType,
  useCampaignHashtagCloudLazyQuery
} from '@/graphql';
import { FormStyle } from '@/shared/atoms';
import { UNEXPECTED_ERROR, UPLOAD_IMAGE_MIME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import {
  TextField,
  TextAreaField,
  FileUploadField,
  SingleDatePickerField,
  HashtagSuggestionField
} from '@/shared/molecules';
import { EngagementPostFormKeys, EngagementPostFormValues } from '../types';
import { FooterButtons, InfluencerAccountFields } from './shared';

interface DefaultTemplateProps {
  onClose: () => void;
  onSubmit?: () => void;
  engagementId: EngagementDetail['id'];
}
export const DefaultTemplate = ({ onClose, onSubmit, engagementId }: DefaultTemplateProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { isAdminStaff, isAdvertiser, isPartner } = useUserRoles();
  const { setValue, watch } = useFormContext<EngagementPostFormValues>();
  const { data, getCampaignHashtagCloud } = useCampaignHashtagCloudLazyQuery();

  const hashtagsData = data?.campaignHashtagCloud;
  const [contentValue, socialAccountMediaValue] = watch(['content', 'socialAccountMedia']);

  const handleFetchCampaignHashtagCloud = async () => {
    try {
      const tagsForAPI = contentValue?.split('#') || [''];
      await getCampaignHashtagCloud({
        variables: {
          ...(!!tagsForAPI.length && { tags: tagsForAPI }),
          ...(!!socialAccountMediaValue && {
            socialMedia:
              socialAccountMediaValue === SocialAccountType.FACEBOOK_PAGE
                ? CampaignSocialMediaType.FACEBOOK
                : (socialAccountMediaValue as unknown as CampaignSocialMediaType)
          })
        }
      });
    } catch (error) {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <>
      <FormStyle.Wrapper css={{ padding: '24px', overflow: 'auto' }}>
        <FormStyle.SectionTitle index={1} label={t('Influencer.Post Information')} />

        <InfluencerAccountFields engagementId={engagementId} />

        <FormStyle.FieldsGroup>
          <TextField<EngagementPostFormKeys>
            name="postUrl"
            disabled={isAdvertiser}
            title={t('TextForm.Post URL')}
            placeholder="https://www.instagram.com/p/BpYTz-JA0g3/"
          />

          <SingleDatePickerField<EngagementPostFormKeys>
            name="planedPostDate"
            disabled={isAdvertiser}
            css={{ flex: 'none', width: '160px' }}
            title={t('TextForm.Plan Date of Post')}
            required
          />
        </FormStyle.FieldsGroup>

        <TextAreaField<EngagementPostFormKeys>
          name="content"
          disabled={isAdvertiser}
          title={t('TextForm.Contents')}
          help={t('Tooltip.Post Content Help')}
          onBlur={handleFetchCampaignHashtagCloud}
          placeholder={t<string>('TextForm.Contents Placeholder')}
        />

        {hashtagsData?.length ? (
          <HashtagSuggestionField
            name="hashtags" // Any name, we don't use this name in form
            hasHashTagForm={false}
            hashtagsCloud={hashtagsData}
            getHashtagsCloud={handleFetchCampaignHashtagCloud}
            onTagClick={(value) => {
              setValue('content', `${contentValue} ${value}`);
              handleFetchCampaignHashtagCloud();
            }}
          />
        ) : null}

        <FileUploadField<EngagementPostFormKeys>
          target="post"
          itemsPerRow={3}
          name="materialsUrl"
          title={t('TextForm.Files')}
          help={t('Tooltip.Files Help')}
          multiple
        />

        {isAdminStaff || isPartner ? (
          <>
            <FormStyle.SectionTitle index={2} css={{ marginTop: '12px' }} label={t('Insight Upload')} />

            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <p css={{ fontSize: '14px', lineHeight: '100%' }}>{t('Date of data acquisition for insights')}</p>
              <SingleDatePickerField<EngagementPostFormKeys>
                name="insightDataAcquisition"
                css={{ padding: 0, maxWidth: '157px' }}
              />
            </div>

            <FileUploadField<EngagementPostFormKeys>
              target="post"
              itemsPerRow={3}
              name="insightUrls"
              notes={[
                t('General.DragAndDropInfo'),
                t('General.DragAndDropMaxSize', { maxSize: '10MB' }),
                t('General.DragAndDropMIME', { MIME: UPLOAD_IMAGE_MIME })
              ]}
              multiple
            />
          </>
        ) : null}
      </FormStyle.Wrapper>

      <FooterButtons onClose={onClose} onSubmit={onSubmit} />
    </>
  );
};
