import { ReactNode, Ref, forwardRef } from 'react';
import { css } from '@emotion/react';
import { Order } from '@/graphql';
import { THEME } from '@/shared/constants';
import { SortAction } from '@/shared/types';
import { HelpIcon, Icon } from '../Icon';
import { ColumnProps, CustomCellValue, CustomRowProps, RowProps } from './types';

export interface TableProps<SortField extends string> extends Partial<SortAction<SortField>> {
  rows: RowProps[];
  rowHeight?: string;
  isOverflow?: boolean;
  stickyHeader?: boolean;
  columns: ColumnProps<SortField>[];
}

const Table = <SortField extends string>(
  { sort, setSort, rows, columns, isOverflow, stickyHeader, rowHeight = '52px' }: TableProps<SortField>,
  ref: Ref<HTMLTableElement>
) => (
  <table css={styles.table} ref={ref}>
    <thead css={{ height: '48px' }}>
      {/* Safari doesn't inherit the height of thead */}
      <tr css={{ height: 'inherit' }}>
        {columns
          .filter((c) => !c.hidden)
          .map(({ title, help, sticky, sortField, styles: columnStyles }, index) => {
            const hasSort = sort && sortField && setSort;
            const sortOrder = hasSort && sort.field === sortField ? sort.order : null;

            return (
              <th
                key={`column-${index}`}
                css={[
                  styles.th,
                  sticky?.active && styles.sticky, // sticky column
                  sticky?.left && { left: sticky?.left },
                  sticky?.hasBorderRight && isOverflow
                    ? styles.overflowBoxShadow
                    : sticky?.hasBorderRight && styles.stickyBorderLine,
                  stickyHeader && { top: 0, position: 'sticky', zIndex: sticky?.active ? 6 : 5 },
                  columnStyles
                ]}
                className={hasSort ? 'with-sort' : ''}
              >
                {help ? <HelpIcon help={help} size="12px" position="top" css={styles.help} /> : null}

                {title}

                {hasSort ? (
                  <div css={[styles.sortWrapper, rows.length <= 1 && { visibility: 'hidden' }]}>
                    <Icon
                      size="8px"
                      icon="triangle-up"
                      css={styles.sortIcon}
                      className={sortOrder === Order.ASC ? 'active' : undefined}
                      onClick={() =>
                        setSort(
                          sortOrder === Order.ASC
                            ? { field: null, order: null }
                            : { field: sortField, order: Order.ASC }
                        )
                      }
                    />
                    <Icon
                      size="8px"
                      icon="triangle-down"
                      css={styles.sortIcon}
                      className={sortOrder === Order.DESC ? 'active' : undefined}
                      onClick={() =>
                        setSort(
                          sortOrder === Order.DESC
                            ? { field: null, order: null }
                            : { field: sortField, order: Order.DESC }
                        )
                      }
                    />
                  </div>
                ) : null}
              </th>
            );
          })}
      </tr>
    </thead>

    <tbody>
      {rows.map((row, rowIndex) => {
        const cells = (row as CustomRowProps).cells || row;
        const cellLength = cells.length;

        return cellLength > 0 ? (
          <tr key={`row-${rowIndex}`} css={[styles.tr, { height: rowHeight }, (row as CustomRowProps).styles]}>
            {cells.map((cell, cellIndex) =>
              !columns.at(cellIndex)?.hidden ? (
                <td
                  {...((cell as CustomCellValue)?.value ? (cell as CustomCellValue)?.props : {})}
                  key={`cell-${rowIndex}-${cellIndex}`}
                  css={[
                    styles.td,
                    columns.at(cellIndex)?.sticky?.active && styles.sticky,
                    columns.at(cellIndex)?.sticky?.hasBorderRight && isOverflow
                      ? styles.overflowBoxShadow
                      : columns.at(cellIndex)?.sticky?.hasBorderRight && styles.stickyBorderLine,
                    columns.at(cellIndex)?.sticky?.left && { left: columns.at(cellIndex)?.sticky?.left },
                    columns.at(cellIndex)?.styles && columns.at(cellIndex)?.styles
                  ]}
                >
                  {((cell as CustomCellValue)?.value || cell) as ReactNode}
                </td>
              ) : null
            )}
          </tr>
        ) : null;
      })}
    </tbody>
  </table>
);

const styles = {
  table: css({ width: 'auto', minWidth: '100%', tableLayout: 'fixed', position: 'relative' }),
  tr: css({
    borderTop: THEME.table.row.border,
    '&:hover td': { backgroundColor: THEME.table.row.background.colors.hover }
  }),
  th: css({
    fontWeight: 600,
    fontSize: '11px',
    paddingLeft: '8px',
    paddingRight: '8px',
    position: 'relative',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: THEME.table.header.background.colors.lv1,
    '&.with-sort': { paddingRight: '24px' },
    '&:first-of-type': { paddingLeft: '16px' },
    '&:last-of-type:not(.with-sort)': { paddingRight: '16px' }
  }),
  td: css({
    fontSize: '13px',
    height: 'inherit',
    paddingLeft: '8px',
    paddingRight: '8px',
    boxSizing: 'border-box',
    backgroundColor: THEME.table.row.background.colors.default,
    '&:first-of-type': { paddingLeft: '16px' },
    '&:last-of-type': { paddingRight: '16px' }
  }),
  sticky: css({
    left: 0,
    zIndex: 3,
    position: 'sticky',
    '& + th, & + td': { paddingLeft: '16px' }
  }),
  stickyBorderLine: css({
    '&::after': {
      top: 0,
      right: 0,
      width: '2px',
      content: '""',
      height: '100%',
      position: 'absolute',
      boxShadow: `2px 0 0 0 #E6ECF0`
    }
  }),
  overflowBoxShadow: css({
    '::after': {
      '--gradient-width': '16px',
      top: 0,
      opacity: 0.1,
      content: '""',
      height: '100%',
      position: 'absolute',
      right: 'calc(-1 * var(--gradient-width))',
      width: 'var(--gradient-width)',
      background: 'linear-gradient(270deg, rgba(39, 49, 59, 0.00) 0%, #6E7C89 100%)'
    }
  }),
  sortWrapper: css({
    top: '8px',
    right: '4px',
    position: 'absolute',
    display: 'inline-flex',
    verticalAlign: 'middle',
    flexDirection: 'column'
  }),
  sortIcon: css({
    padding: '4px',
    color: '#9b9b9b',
    cursor: 'pointer',
    '&:hover': { color: '#666' },
    '&.active': { color: THEME.text.colors.blue, '&:hover': { color: THEME.text.colors.blue } }
  }),
  help: css({
    display: 'block',
    marginTop: '-2px',
    marginLeft: 'auto',
    textAlign: 'inherit',
    width: 'max-content',
    verticalAlign: 'middle'
  })
};

export default forwardRef(Table) as <SortField extends string>(
  props: TableProps<SortField> & { ref?: Ref<HTMLTableElement> }
) => ReturnType<typeof Table>;
