import { NavigateOptions, AnyRouter } from '@tanstack/react-router';
import { useParamsSearch } from '@/shared/hooks';
import { INITIAL_PAGE_NUMBER } from '@/shared/constants';
import { InfluencersBaseSearchSchemaType } from '../types';

export const useInfluencerParamsSearch = <T extends InfluencersBaseSearchSchemaType>() => {
  const { navigate, ...rest } = useParamsSearch<T>();

  const customSetFilter = (newFilter: Partial<T['filter']>) => {
    navigate({
      search: (prev: T) => ({
        ...prev,
        page: INITIAL_PAGE_NUMBER,
        filter: { ...prev.filter, ...newFilter },
        state: { skipSearch: false } // Reset skipSearch to false to allow search
      })
    } as NavigateOptions<AnyRouter, any>);
  };

  return { ...rest, setFilter: customSetFilter };
};
