import { z } from 'zod';
import { useFormContext } from 'react-hook-form';
import { GenericOption } from '@/shared/types';
import { DefaultFieldProps } from '../types';
import { RHFFieldWrapperProps } from '../shared';

export const useFormatRHFFieldProps = <
  V,
  T extends string,
  O extends GenericOption<any, any>,
  P extends Record<string, any>
>({
  test,
  name,
  help,
  note,
  title,
  required,
  optional,
  className,
  iconMargin,
  labelLoader,
  customError,
  dynamicError,
  hasBorderBottom,
  setValueOptions = { shouldDirty: true, shouldValidate: true },
  ...restProps
}: P & Omit<DefaultFieldProps<T, V, O>, 'onChange'>) => {
  const formContext = useFormContext<z.infer<z.ZodType<{ [name: string]: V }>>>();

  const { watch, register } = formContext;
  const fieldValue = watch(name as any) as V;
  const fieldRegister = register(name as any);
  const fieldWrapperProps: Partial<RHFFieldWrapperProps<T, V, O>> = {
    name,
    help,
    note,
    title,
    required,
    optional,
    className,
    iconMargin,
    customError,
    labelLoader,
    dynamicError,
    hasBorderBottom
  };

  return {
    fieldValue,
    formContext,
    fieldRegister,
    setValueOptions,
    fieldWrapperProps,
    fieldProps: { name, test, value: fieldValue, ...restProps }
  };
};
