import { z } from 'zod';

import { AnalyticsSubscriptionPlanType } from '@/graphql';
import { BaseValidations } from '@/shared/validations';

export const SettingsFormSchema = z.object({
  notificationSetting: z.boolean(),
  name: BaseValidations.NameSchema,
  email: BaseValidations.EmailSchema,
  password: BaseValidations.PasswordSchema,
  countryId: BaseValidations.CountryIdSchema,
  advertiserName: BaseValidations.CompanyNameSchema,
  advertiserCountryId: BaseValidations.CountryIdSchema,
  advertiserCompanyUrl: BaseValidations.CompanyUrlSchema,
  advertiserPhoneNumber: BaseValidations.PhoneNumberSchema,
  planType: z.nativeEnum(AnalyticsSubscriptionPlanType).or(z.literal(''))
});
