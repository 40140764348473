import { usePermissions } from '../hooks';

const defaultPermissions = {
  hidePackageTab: false,
  hideAddPackageBtn: false,
  hideEditPackageBtn: false,
  hideDeletePackageBtn: false
};
export const useInfluencerPackagePermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.influencerPackage?.noView || permissions?.influencerPackage === null) {
    return {
      hidePackageTab: true,
      hideAddPackageBtn: true,
      hideEditPackageBtn: true,
      hideDeletePackageBtn: true
    };
  } else if (permissions?.influencerPackage?.view) {
    return {
      hidePackageTab: false,
      hideAddPackageBtn: true,
      hideEditPackageBtn: true,
      hideDeletePackageBtn: true
    };
  } else if (permissions?.influencerPackage?.manage) {
    return { ...defaultPermissions, hideDeletePackageBtn: true };
  }

  return defaultPermissions;
};
