import { ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { NoDataContentWrapper, ChartTooltipSingleMetric, NoDataContentWrapperProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatPercent, getEstimatedWrapperText } from '@/shared/utils';
import { HighChart, Highcharts } from '../HighChart';

export interface PieChartProps extends Pick<NoDataContentWrapperProps, 'className' | 'wrapperLink' | 'wrapperText'> {
  hasEstimatedDemographics?: boolean;
  header: ReactNode;
  dataNotAvailable?: boolean;
  chart?: Highcharts.ChartOptions;
  data: { y: number | null; color: string; name: string }[];
  className?: string;
}

export const PieChart = ({
  header,
  data = [],
  className,
  wrapperText,
  dataNotAvailable,
  hasEstimatedDemographics,
  chart = { width: undefined, type: 'pie', height: 323, spacingBottom: 0 },
  ...noDataContentProps
}: PieChartProps) => {
  const options: Highcharts.Options = {
    title: { text: '' },
    chart,
    plotOptions: {
      pie: {
        cursor: 'pointer',
        showInLegend: true,
        allowPointSelect: true,
        dataLabels: {
          distance: -20,
          enabled: true,
          borderRadius: 5,
          backgroundColor: 'white',
          color: THEME.text.colors.black.lv1,
          formatter() {
            return formatPercent(this.y || null);
          }
        }
      }
    },
    legend: {
      width: '100%',
      symbolHeight: 15,
      itemMarginBottom: 0,
      symbolRadius: 2,
      itemStyle: { fontSize: '13px', color: THEME.text.colors.black.lv1, fontWeight: 'normal' }
    },
    series: [{ data, type: 'pie', innerSize: '50%' }],
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      borderColor: 'green',
      backgroundColor: THEME.chart.tooltip.background.colors.black,
      style: { color: THEME.chart.tooltip.text.colors.white },
      formatter() {
        return renderToStaticMarkup(
          <ChartTooltipSingleMetric name={this.name} value={formatPercent(this.y)} color={this.color as string} />
        );
      }
    }
  };

  return (
    <NoDataContentWrapper
      css={{ padding: '24px 16px' }}
      className={className}
      dataNotAvailable={dataNotAvailable}
      wrapperText={getEstimatedWrapperText({ type: 'gender', wrapperText, hasEstimatedDemographics })}
      header={header}
      {...noDataContentProps}
    >
      <HighChart options={options} />
    </NoDataContentWrapper>
  );
};
