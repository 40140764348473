import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdatePackageProposalInfluencersBudget.graphql';
import { Mutation, MutationupdatePackageProposalInfluencersBudgetArgs } from '../../__generated__/globalTypes';

export const useUpdatePackageProposalInfluencersBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationupdatePackageProposalInfluencersBudgetArgs>
) => {
  const [callUpdatePackageProposalInfluencersBudget, result] = useMutation<
    Mutation,
    MutationupdatePackageProposalInfluencersBudgetArgs
  >(MUTATION, options);

  return { callUpdatePackageProposalInfluencersBudget, ...result };
};

export type UpdatePackageProposalInfluencersBudgetMutationFunction = Mutation['updatePackageProposalInfluencersBudget'];
