import { useState } from 'react';

// Usually used for deleting item, used with ConfirmDeleteModal
export const useSelectItem = <T = { id: number; name: string; email?: string }>(
  defaultSelectedItem: T | null = null
) => {
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

  const resetSelectedItem = () => {
    setSelectedItem(null);
  };

  return { selectedItem, setSelectedItem, resetSelectedItem };
};
