import { ClipboardEvent, KeyboardEvent, forwardRef } from 'react';
import { Input, InputProps } from './Input';

export type InputNumberProps = Omit<InputProps, 'type'>;

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if ([',', 'e', '+', '-'].includes(event.key)) {
      event.preventDefault();
    }
  };

  const handlePasteNumberWithDecimals = (event: ClipboardEvent<HTMLInputElement>) => {
    const onlyDigitsAndDot = /^-?\d*(\.\d*)?$/;
    if (!onlyDigitsAndDot.test(event.clipboardData?.getData('text'))) {
      event.preventDefault();
    }
  };

  return (
    <Input
      step="any" // Decimal issue https://stackoverflow.com/questions/48892570/please-enter-a-valid-value-the-two-nearest-valid-value-is
      type="number"
      ref={ref}
      onKeyDown={handleKeyDown}
      onPaste={handlePasteNumberWithDecimals}
      {...props}
    />
  );
});
InputNumber.displayName = 'InputNumber';
