import { z } from 'zod';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { SearchFilterDefaults } from '@/shared/validations';
import { FacebookPageAnalyticsPostsSortOrder } from '@/graphql';

export const AnalyticsFbFeedPostSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(ProfilePostsFilterSchema, { startDate: getThirtyDaysAgo(), endDate: getToday() }),
  sort: SearchFilterDefaults.sort(FacebookPageAnalyticsPostsSortOrder).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsFbFeedPostSearchSchemaType = z.infer<typeof AnalyticsFbFeedPostSearchSchema>;
