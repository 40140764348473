import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import {
  compareLeftImg,
  compareRightImg,
  hashtagLeftImg,
  hashtagRightImg,
  interactionLeftImg,
  interactionRightImg
} from '@/shared/assets';
import { THEME } from '@/shared/constants';

const SupportTab = ['compare-ig', 'compare-yt', 'hashtag-ig', 'interaction-ig'] as const;
type SupportTabType = (typeof SupportTab)[number];

interface ContentType {
  title: string;
  benefits: {
    leftTitle: string;
    leftDescription: string;
    rightTitle: string;
    rightDescription: string;
  };
  leftImage?: string;
  rightImage?: string;
  coverImage?: string;
  description: string;
}
const configs: Record<SupportTabType, ContentType> = {
  'compare-ig': {
    title: 'Know your competitors',
    description: 'Compare your account with customized competitors to optimize your strategy',
    leftImage: compareLeftImg,
    rightImage: compareRightImg,

    benefits: {
      leftTitle: 'Track audience',
      leftDescription: 'Analyze follower growth and demographics',
      rightTitle: 'Content insights',
      rightDescription: 'Evaluate post content, habits and engagement'
    }
  },
  'compare-yt': {
    title: 'Know your competitors',
    description: 'Compare your account with customized competitors to optimize your strategy',
    leftImage: compareLeftImg,
    rightImage: compareRightImg,

    benefits: {
      leftTitle: 'Track audience',
      leftDescription: 'Analyze follower growth and demographics',
      rightTitle: 'Content insights',
      rightDescription: 'Evaluate post content, habits and engagement'
    }
  },
  'hashtag-ig': {
    title: 'Stay ahead',
    leftImage: hashtagLeftImg,
    rightImage: hashtagRightImg,
    description: 'Monitor hashtag performance and stay on trend',
    benefits: {
      leftTitle: 'Hashtag metrics',
      leftDescription: 'Track hashtag posts and campaigns’ engagement',
      rightTitle: 'Competitor analysis',
      rightDescription: 'Check competitor hashtags stats'
    }
  },
  'interaction-ig': {
    title: 'Get Interaction Insights',
    leftImage: interactionLeftImg,
    rightImage: interactionRightImg,
    description: 'Analyze brand interactions and recognition',
    benefits: {
      leftTitle: 'Track Interaction',
      leftDescription: 'Track brand-mentioning post and performance',
      rightTitle: 'Branding Insights',
      rightDescription: 'See your top taggers/mentioners'
    }
  }
};

interface AnalyticsUpgradePlanProps {
  tab: SupportTabType;
  className?: string;
}

const AnalyticsUpgradePlan = ({ tab, className }: AnalyticsUpgradePlanProps) => {
  const { t } = useTranslation();
  const { title, description, leftImage, rightImage, benefits } = configs[tab];

  return (
    <div css={styles.modalContainer} className={className}>
      <div css={styles.gradientLayer1} />
      <div css={styles.gradientLayer2} />
      <div css={styles.topTextContainer}>
        <h3 css={{ fontSize: '24px', fontWeight: 600 }}>{t(title)}</h3>
        <p css={{ fontSize: '12px', margin: '6px auto', width: '301px', letterSpacing: 'normal' }}>{t(description)}</p>
      </div>
      <div css={styles.topImagesContainer}>
        <img src={leftImage} alt="ad-manager-ui" css={{ width: '156px', borderRadius: '11px' }} />
        <img src={rightImage} alt="anytag-report-preview" css={{ width: '156px', borderRadius: '11px' }} />
      </div>

      <div css={[styles.bottomImageTextContainer, { left: 69 }]}>
        <h3 css={{ fontSize: '14px', fontWeight: 600 }}>{t(benefits.leftTitle)}</h3>
        <p css={{ marginTop: '6px', fontSize: '13px', color: THEME.text.colors.gray.lv3 }}>
          {t(benefits.leftDescription)}
        </p>
      </div>
      <div css={[styles.bottomImageTextContainer, { right: 68 }]}>
        <h3 css={{ fontSize: '14px', fontWeight: 600 }}>{t(benefits.rightTitle)}</h3>
        <p css={{ marginTop: '6px', fontSize: '13px', color: THEME.text.colors.gray.lv3 }}>
          {t(benefits.rightDescription)}
        </p>
      </div>

      <div css={styles.bottomCallToActionContainer}>
        <p css={{ fontSize: '15px', color: THEME.text.colors.black.lv1 }}>
          {t('Please contact our staff to upgrade subscriptions')}
        </p>
      </div>
    </div>
  );
};

interface FreePlanContentOpacityProps extends AnalyticsUpgradePlanProps {
  hasFreePlan: boolean;
  children: ReactNode;
}
export const FreePlanContentOpacity = ({ tab, className, hasFreePlan, children }: FreePlanContentOpacityProps) =>
  hasFreePlan ? (
    <>
      <AnalyticsUpgradePlan tab={tab} />
      <div
        css={{
          opacity: 0.5,
          pointerEvents: 'none',
          '.checkbox-wrapper, .radio-wrapper': {
            pointerEvents: 'none'
          }
        }}
        className={className}
      >
        {children}
      </div>
    </>
  ) : (
    children
  );

const styles = {
  modalContainer: css({
    position: 'fixed',
    top: `calc(50% + calc(${THEME.container.analytics.header.height} + ${THEME.container.analytics.freePlanNotice.height}) / 2)`,
    left: `calc(50% + ${THEME.container.menu.width} / 2)`,
    transform: 'translate(-50%, -50%)',
    borderRadius: '24px',
    width: '560px',
    height: '466px',
    background: THEME.background.colors.white,
    zIndex: 1,
    boxShadow: '0px 2px 1px 0px rgba(110, 124, 137, 0.10), 0px 3px 5px 0px rgba(110, 124, 137, 0.20)'
  }),
  gradientLayer1: css({
    width: 560,
    height: 240,
    left: 0,
    top: 0,
    position: 'absolute',
    borderRadius: '24px 24px 0 0',
    opacity: 0.8,
    background:
      'linear-gradient(97deg, rgba(254, 111, 131, 0.50) 0%, rgba(252, 140, 64, 0.50) 45%, rgba(255, 180, 94, 0.50) 100%)'
  }),
  gradientLayer2: css({
    width: 560,
    height: 174,
    left: 0,
    top: 66,
    position: 'absolute',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 64.03%)'
  }),
  topTextContainer: css({ position: 'absolute', top: 48, width: '100%', textAlign: 'center' }),
  topImagesContainer: css({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    gap: '67px',
    top: 150,
    left: 91
  }),
  bottomImageTextContainer: css({
    display: '',
    position: 'absolute',
    top: 300,
    width: '200px',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  bottomCallToActionContainer: css({
    width: 'max-content',
    textAlign: 'center',
    borderRadius: '23px',
    background: THEME.colors.black['20'],
    padding: '8px 16px',
    margin: '390px auto 0'
  })
};
