import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { InfluencerYoutubeComparePosts, InfluencerYoutubeAnalyticsAccountPosts } from '@/graphql';
import { RowProps, HelpIcon, ColumnProps, MediaPreviewWithMetrics } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { usePostDetailsParam, useSelectItem } from '@/shared/hooks';
import { InfluencerYoutubeAnalyticsPostDetailsModal } from '@/shared/molecules';
import {
  AnalyticsComparePostsHabit,
  AnalyticsComparePostsTable,
  AnalyticsComparePostsSlider,
  AnalyticsComparePostsAccount,
  AnalyticsComparePostsHabitModal,
  AnalyticsComparePostsHabitModalProps
} from '@/shared/organisms';
import { bigIntFormatter } from '@/shared/utils';
import { PostDetailsType } from '@/shared/types';
import { InfluencerYoutubeComparableAccountType } from '../types';

interface TemplateProps {
  data: InfluencerYoutubeComparePosts;
  comparableAccounts: readonly InfluencerYoutubeComparableAccountType[];
}

export const Template = ({ data, comparableAccounts }: TemplateProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();
  const {
    setSelectedItem: setPostingHabit,
    selectedItem: selectedPostingHabit,
    resetSelectedItem: resetPostingHabit
  } = useSelectItem<Pick<AnalyticsComparePostsHabitModalProps, 'account' | 'data'>>();

  const composeData = [
    data.mainAccount,
    ...(data.compareAccounts.reduce<InfluencerYoutubeAnalyticsAccountPosts[]>(
      (acc, curr) =>
        curr.accountId !== data.mainAccount.socialAccountId
          ? [...acc, { ...curr, socialAccountId: curr.accountId }]
          : acc,
      []
    ) || [])
  ];
  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Account') },
    {
      styles: css({ paddingLeft: 0 }),
      title: (
        <div css={{ flex: 1.2, display: 'flex', alignItems: 'center', padding: '0 16px' }}>
          <HelpIcon css={{ marginRight: '4px' }} help={t('Tooltip.comparePostingHabits')} />
          {t('HeaderColumn.Posting Habits')}
        </div>
      )
    }
  ];

  const rows: RowProps[] = comparableAccounts.map((account) => {
    const rowData = composeData.find((item) => item.socialAccountId === account.id);

    return {
      styles: css({
        'td:first-of-type': { width: '160px', borderRight: THEME.border.base },
        'td:last-of-type': { paddingLeft: 0, paddingRight: 0 }
      }),
      cells: [
        <AnalyticsComparePostsAccount account={{ name: account.username, avatar: account.avatarUrl }} />,
        <>
          <div css={{ padding: '12px 0', boxSizing: 'border-box' }}>
            <AnalyticsComparePostsHabit
              postsHabit={rowData?.postHabit || []}
              css={{ flex: 1.2, paddingTop: '4px', paddingBottom: '4px', borderRight: THEME.border.base }}
              buttonProps={{
                onClick: () =>
                  account && rowData?.postHabit
                    ? setPostingHabit({
                        data: rowData.postHabit,
                        account: { name: account.username, avatar: account.avatarUrl }
                      })
                    : undefined
              }}
            />
          </div>

          <AnalyticsComparePostsSlider
            css={{ minHeight: '209px' }}
            posts={rowData?.posts || []}
            renderPost={(post) => (
              <MediaPreviewWithMetrics
                objectFit="cover"
                title={post.title}
                src={post.thumbnail}
                video={{ duration: post.duration }}
                css={THEME.mediaPreview.hasCaptionOrTitle}
                onClick={() => setPostDetailsParam?.(post.id)}
                statistics={[
                  { label: t('View'), value: bigIntFormatter(post.views, 0, { k: true, m: true }) },
                  { label: t('Like'), value: bigIntFormatter(post.likes, 0, { k: true, m: true }) },
                  { label: t('Comment'), value: bigIntFormatter(post.comments, 0, { k: true, m: true }) }
                ]}
              />
            )}
          />

          <InfluencerYoutubeAnalyticsPostDetailsModal
            posts={
              rowData?.posts.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId: account.id })) ||
              []
            }
          />
        </>
      ]
    };
  });

  return (
    <>
      <AnalyticsComparePostsTable data={{ rows, columns }} />

      <AnalyticsComparePostsHabitModal
        onClose={resetPostingHabit}
        open={!!selectedPostingHabit}
        {...selectedPostingHabit}
      />
    </>
  );
};
