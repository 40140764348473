import { css } from '@emotion/react';

interface ProgressBarProps {
  value: number;
  max?: number;
  height?: string;
  accentColor?: string;
  backgroundColor?: string;
  className?: string;
}
export const ProgressBar = ({
  max = 100,
  value,
  height = '4px',
  accentColor = '#3892E5',
  backgroundColor = '#9BC8F2',
  className
}: ProgressBarProps) => (
  <progress
    max={max}
    value={value}
    css={css({
      '--progressBarAccentColor': accentColor,
      '--progressBarBackground': backgroundColor,
      display: 'block',
      appearance: 'none',
      height,
      margin: 0,
      '::-webkit-progress-value': {
        backgroundColor: 'var(--progressBarAccentColor)'
      },
      '::-webkit-progress-bar': {
        backgroundColor: 'var(--progressBarBackground)'
      },
      width: '100%'
    })}
    className={className}
  />
);
