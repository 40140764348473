import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { PostDetailsTemplateType } from '../types';
import { PostFooter } from './PostFooter';
import * as PostStyles from './StyledComponent';

export interface DefaultHistoryTemplateProps {
  loading?: boolean;
  hasNoData?: boolean;
  className?: string;
  children?: ReactNode;
  footerNode?: ReactNode;
  onCloseModal?: () => void;
  postDetails?: PostDetailsTemplateType;
}

export const DefaultHistoryTemplate = ({
  loading,
  children,
  hasNoData,
  className,
  footerNode
}: DefaultHistoryTemplateProps) => {
  const { t } = useTranslation();

  return (
    <PostStyles.Wrapper loading={loading} className={className}>
      <div css={{ display: 'flex', minHeight: 'inherit', flexDirection: 'column' }}>
        <div css={styles.heading}>{t('History')}</div>

        {hasNoData ? <div css={styles.empty}>{t('No history')}</div> : children}
      </div>

      <PostFooter>{footerNode}</PostFooter>
    </PostStyles.Wrapper>
  );
};
const styles = {
  heading: css({
    flex: 'none',
    height: '56px',
    display: 'flex',
    lineHeight: 0.8,
    fontWeight: 600,
    fontSize: '16px',
    padding: '0 24px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: THEME.border.base
  }),
  empty: css({
    display: 'flex',
    flex: '1 1 auto',
    fontSize: '13px',
    lineHeight: 1.38,
    alignItems: 'center',
    justifyContent: 'center',
    color: THEME.text.colors.gray.lv3
  })
};
