import { useTranslation } from 'react-i18next';
import { FacebookPageAnalyticsOverviewItem, FacebookPageAnalyticsOverviewPayload } from '@/graphql';
import { AnalyticsOverviewChartProps } from '../../../../shared';
import { AnalyticsFacebookDashboardGraphKeys as Keys, AnalyticsFacebookDashboarOverviewEngagementType } from '../types';
import { allOverviewEngagements, legendColors } from '../utils';

interface Props {
  selectedLegendKeys: Keys[];
  isInfluencerProfile?: boolean;
  availableEngagementKeys: Keys[];
  data: FacebookPageAnalyticsOverviewPayload;
}

interface ChartValuesType {
  categories: string[];
  postsCount: number[];
  sharesCount: number[];
  pageCtaCount: number[];
  commentsCount: number[];
  reactionsCount: number[];
  followersCount: number[];
  pageLikesCount: number[];
  engagementCount: number[];
  impressionsCount: number[];
  engagementRateCount: number[];
  pageImpressionsCount: number[];
}
const defaultChartValues: ChartValuesType = {
  categories: [],
  postsCount: [],
  sharesCount: [],
  pageCtaCount: [],
  commentsCount: [],
  reactionsCount: [],
  followersCount: [],
  pageLikesCount: [],
  engagementCount: [],
  impressionsCount: [],
  engagementRateCount: [],
  pageImpressionsCount: []
};

const getEngagementMetrics = ({
  total,
  growth,
  percentage
}: Pick<FacebookPageAnalyticsOverviewItem, 'total' | 'growth' | 'percentage'>) => ({
  total: total || 0,
  growth: growth || 0,
  percentage: percentage || 0
});

export const useAnalyticsFacebookDashboardOverviewData = ({
  data,
  selectedLegendKeys,
  isInfluencerProfile,
  availableEngagementKeys
}: Props) => {
  const { t } = useTranslation();

  const {
    post,
    share,
    comment,
    pageCta,
    reaction,
    follower,
    pageLikes,
    impression,
    engagement,
    pageImpression,
    engagementRate
  } = data;
  const overviewEngagements = allOverviewEngagements.map<AnalyticsFacebookDashboarOverviewEngagementType>((item) => ({
    ...item,
    ...getEngagementMetrics(data[item.key]),
    title: t<string>(item.title)
  }));
  const availableOverviewEngagements = overviewEngagements.filter((eng) => availableEngagementKeys.includes(eng.key));

  const {
    categories,
    postsCount,
    sharesCount,
    pageCtaCount,
    commentsCount,
    reactionsCount,
    followersCount,
    pageLikesCount,
    engagementCount,
    impressionsCount,
    engagementRateCount,
    pageImpressionsCount
  } = (follower.history || []).reduce<ChartValuesType>((acc, curr) => {
    const date = curr.date || '';

    return {
      categories: [...acc.categories, date],
      followersCount: [...acc.followersCount, Number(curr.count)],
      postsCount: [...acc.postsCount, post.history?.find((item) => item.date === date)?.count || 0],
      sharesCount: [...acc.sharesCount, share.history?.find((item) => item.date === date)?.count || 0],
      pageCtaCount: [...acc.pageCtaCount, pageCta.history?.find((item) => item.date === date)?.count || 0],
      commentsCount: [...acc.commentsCount, comment.history?.find((item) => item.date === date)?.count || 0],
      reactionsCount: [...acc.reactionsCount, reaction.history?.find((item) => item.date === date)?.count || 0],
      pageLikesCount: [...acc.pageLikesCount, pageLikes.history?.find((item) => item.date === date)?.count || 0],
      engagementCount: [...acc.engagementCount, engagement.history?.find((item) => item.date === date)?.count || 0],
      impressionsCount: [...acc.impressionsCount, impression.history?.find((item) => item.date === date)?.count || 0],
      engagementRateCount: [
        ...acc.engagementRateCount,
        engagementRate.history?.find((item) => item.date === date)?.count || 0
      ],
      pageImpressionsCount: [
        ...acc.pageImpressionsCount,
        pageImpression.history?.find((item) => item.date === date)?.count || 0
      ]
    };
  }, defaultChartValues);

  const chartData: AnalyticsOverviewChartProps<Keys>['data'] = {
    follower: {
      zIndex: 1,
      type: 'line',
      showIncrement: true,
      data: followersCount,
      name: t<string>('Follower'),
      color: legendColors.follower,
      visible: selectedLegendKeys.includes('follower'),
      marker: {
        lineWidth: 2,
        symbol: 'circle',
        fillColor: '#fff',
        lineColor: undefined // inherit from chart
      }
    },
    post: {
      yAxis: 1,
      type: 'column',
      data: postsCount,
      name: t<string>('Post'),
      color: legendColors.post,
      visible: selectedLegendKeys.includes('post')
    },
    reaction: {
      yAxis: 2,
      type: 'column',
      data: reactionsCount,
      name: t<string>('Reaction'),
      color: legendColors.reaction,
      visible: selectedLegendKeys.includes('reaction')
    },
    comment: {
      yAxis: 3,
      type: 'column',
      data: commentsCount,
      name: t<string>('Comment'),
      color: legendColors.comment,
      visible: selectedLegendKeys.includes('comment')
    },
    share: {
      yAxis: 4,
      type: 'column',
      data: sharesCount,
      name: t<string>('Share'),
      color: legendColors.share,
      visible: selectedLegendKeys.includes('share')
    },
    impression: {
      yAxis: 5,
      type: 'column',
      data: impressionsCount,
      name: t<string>('Save'),
      color: legendColors.impression,
      visible: selectedLegendKeys.includes('impression')
    },
    pageCta: {
      yAxis: 6,
      type: 'column',
      data: pageCtaCount,
      name: t<string>('PageCta'),
      color: legendColors.pageCta,
      visible: selectedLegendKeys.includes('pageCta')
    },
    pageImpression: {
      yAxis: 7,
      type: 'column',
      data: pageImpressionsCount,
      name: t<string>('Page Impression'),
      color: legendColors.pageImpression,
      visible: selectedLegendKeys.includes('pageImpression')
    },
    pageLikes: {
      yAxis: 8,
      type: 'column',
      data: pageLikesCount,
      name: t<string>('Like'),
      color: legendColors.pageLikes,
      visible: selectedLegendKeys.includes('pageLikes')
    },
    engagement: {
      yAxis: 9,
      type: 'column',
      data: engagementCount,
      name: t<string>('Engagement'),
      color: legendColors.engagement,
      visible: !isInfluencerProfile && selectedLegendKeys.includes('engagement')
    },
    engagementRate: {
      yAxis: 10,
      type: 'column',
      formatType: 'percentage',
      data: engagementRateCount,
      name: t<string>('Engagement Rate'),
      color: legendColors.engagementRate,
      visible: !isInfluencerProfile && selectedLegendKeys.includes('engagementRate')
    }
  };

  return {
    chartData,
    categories,
    overviewEngagements,
    availableOverviewEngagements
  };
};
