import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { SC } from '../StyledComponents';
import { PostInsightFormKeys } from '../types';

export const PostInsightTwitter = () => {
  const { t } = useTranslation();

  return (
    <SC.SectionWrapper css={{ borderBottom: 'none' }}>
      <FormStyle.FieldsGroup itemsPerRow={3}>
        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="likes"
          title={t('TextForm.Likes')}
          placeholder="10000000"
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="shares"
          placeholder="10000000"
          title={t('TextForm.Retweets')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="comments"
          placeholder="10000000"
          title={t('TextForm.Comments')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="impressions"
          placeholder="10000000"
          title={t('TextForm.Impression')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="linkClicks"
          placeholder="10000000"
          title={t('TextForm.Clicks')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="newFollowers"
          placeholder="10000000"
          title={t('TextForm.New followers')}
        />

        <SC.TextField<PostInsightFormKeys>
          type="number"
          name="profileActivity"
          placeholder="10000000"
          title={t('TextForm.Profile access')}
        />
      </FormStyle.FieldsGroup>
    </SC.SectionWrapper>
  );
};
