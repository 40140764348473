// Error from api
export const NOT_FOUND = 'NOT_FOUND'; // for Advertiser account which does not have any campaign (Report/MKP/EGG);
export const UNEXPECTED_ERROR = 'UnexpectedError';
export const EMAIL_USED = 'this email is already used';
export const PERMISSION_DENIED = 'PERMISSION_DENIED';
export const NOT_ENOUGH_REFERRAL_CODE = 'not enough coupon code'; // This message comes from api, don't change coupon code -> referral code
export const YT_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY = 'YT_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY';
export const TK_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY = 'TK_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY';
export const CAN_NOT_ADD_MORE_TTCM_ACCOUNT = 'Cannot add more tiktok accounts to TTCM campaign';
export const ADVERTISER_ALREADY_RUN_CAMPAIGN_BEFORE = 'the advertiser has already run a campaign before';
export const CAN_NOT_ADD_TIKTOK_ACCOUNT_WITH_LOW_FOLLOWERS = 'Cannot add tiktok account with low followers count';
export const SHOW_ON_BOARDING_DUE_TO_NO_DATA = 'SHOW_ON_BOARDING_DUE_TO_NO_DATA'; // for Advertiser account which does not have any Analytics accounts
export const REFRESH_FOR_NEW_POSTS = "Click 'Refresh' button to fetch new posts";
export const NO_NEW_POSTS = 'There are no Tweets in the last 30 days';
export const CUSTOM_API_MESSAGE_TO_SHOW_ON_TW_FEED_POSTS = [REFRESH_FOR_NEW_POSTS, NO_NEW_POSTS];
export const NO_IN_CHARGE_ADVERTISER = 'NO_IN_CHARGE_ADVERTISER'; // when TA doesn't have any Advertisers connected, Report page (AllCampaignsReport query) will throw it
export const NO_POSTS_LAST_30_DAYS = 'There are no posts in the past 30 days';
export const ACCOUNT_PRIVATE_OR_DELETED = 'This account is private or deleted';

// Others
export const EMAIL_DUPLICATED = 'emailDuplicatedMessage';
export const HAS_NO_ESTIMATED_TEXT = 'noEstimatedText';
export const AWAIT_ESTIMATED_DATA_TEXT = 'awaitEstimatedDataText';
export const HAS_NO_SELECTED_ACCOUNTS = 'Has no selected accounts';
export const CAN_NOT_GET_ANALYTICS_ACCOUNT = 'Can not get analytics for current account';
