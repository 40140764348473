import { useFormContext } from 'react-hook-form';
import { useQueryHelper } from '@/shared/hooks';
import {
  namedOperations,
  useAddInstagramHashtagAnalyticsMutation,
  useDeleteInstagramHashtagAnalyticsMutation
} from '@/graphql';
import { AnalyticsHashtagsListProps } from '../../../shared';
import { AnalyticsInstagramHashtagsFilterFormValues } from '../schemaTypes';

interface Props extends Pick<AnalyticsHashtagsListProps, 'hashtags'> {
  onSubmit?: () => void;
  analyticsAccountId: number;
}

export const useCreateDeleteInstagramHashtag = ({ hashtags, onSubmit, analyticsAccountId }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { watch, setValue } = useFormContext<AnalyticsInstagramHashtagsFilterFormValues>();
  const { callAddInstagramHashtagAnalytics, loading: adding } = useAddInstagramHashtagAnalyticsMutation({
    refetchQueries: [namedOperations.Query.InstagramAnalyticsHashtags]
  });
  const { callDeleteInstagramHashtagAnalytics, loading: deleting } = useDeleteInstagramHashtagAnalyticsMutation({
    refetchQueries: [namedOperations.Query.InstagramAnalyticsHashtags]
  });

  const hashtagIdValue = watch('hashtagId');

  const handleAddNewHashtag: AnalyticsHashtagsListProps['onAddNewHashtag'] = async (hashtag, callback) => {
    if (!hashtag) return;

    try {
      await callAddInstagramHashtagAnalytics({ variables: { input: { hashtag, analyticsAccountId } } });
      enqueueSnackbar(t('succeededInCreating', { name: hashtag }), { variant: 'success' });
      callback?.();
    } catch (error) {
      enqueueSnackbar(t(error.message || 'failedToCreate'), { variant: 'error' });
    }
  };

  const handleDeleteHashtag: AnalyticsHashtagsListProps['onDeleteHashtag'] = async (hashtag, callback) => {
    try {
      await callDeleteInstagramHashtagAnalytics({
        variables: { input: { hashtagId: Number(hashtag.id), analyticsAccountId } }
      });
      enqueueSnackbar(t('succeededInDeleting', { name: hashtag.name }), { variant: 'success' });

      if (hashtag.id === hashtagIdValue) {
        setValue('hashtagId', hashtags.at(0)?.id || '');
        onSubmit?.();
      }
    } catch (error) {
      enqueueSnackbar(t(error.message || 'failedToDelete'), { variant: 'error' });
    } finally {
      callback?.();
    }
  };

  return { adding, deleting, handleAddNewHashtag, handleDeleteHashtag };
};
