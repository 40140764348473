import { TableData } from '@/shared/types';
import { useTableMaxHeight } from '@/shared/hooks';
import { InstagramHashtagFeedPostV2 } from '@/graphql';
import { RenderDataWithFallback, Table } from '@/shared/atoms';
import { InstagramHashtagPostDetailsModal } from '@/shared/molecules';
import { ProfilePostsCount } from '../../../../ProfilePostsList';
import { useAnalyticsInstagramHashtagPostsListTable } from './hooks';

export type AnalyticsInstagramHashtagPostsListProps = TableData<InstagramHashtagFeedPostV2>;

export const AnalyticsInstagramHashtagPostsList = ({
  loading,
  data = [],
  totalRecords
}: AnalyticsInstagramHashtagPostsListProps) => {
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();
  const { rows, columns } = useAnalyticsInstagramHashtagPostsListTable({ data });

  return (
    <RenderDataWithFallback loading={loading}>
      <ProfilePostsCount count={totalRecords} css={{ boxShadow: 'none' }} />
      <Table
        ref={offsetRef}
        maxHeight={defaultTableMaxHeight}
        data={{ rows, columns, rowHeight: '72px' }}
        noTopBorderRadius
        stickyHeader
      />
      <InstagramHashtagPostDetailsModal posts={data.map(({ id }) => ({ id }))} />
    </RenderDataWithFallback>
  );
};
