import { useTranslation } from 'react-i18next';
import { Header } from '@/shared/molecules';
import { Button } from '@/shared/atoms';
import { useDownloadYoutubeCMSCsv } from './hooks';

export const YoutubeCMSHeader = () => {
  const { t } = useTranslation();
  const { openDownloadModal, DownloadCsvModal } = useDownloadYoutubeCMSCsv();

  return (
    <>
      <Header>
        <Button
          variant="blue"
          onClick={openDownloadModal}
          css={{ marginLeft: 'auto' }}
          title={t('Button.Download Revenue')}
        />
      </Header>

      {DownloadCsvModal}
    </>
  );
};
