import { z } from 'zod';
import { AccountAnalyticsSocialMediaAccount } from '@/graphql';
import { DeepPartial, getSchemaForType } from '@/shared/types';

export const AnalyticsSocialAccountsSchema = getSchemaForType<DeepPartial<AccountAnalyticsSocialMediaAccount>>()(
  z.object({
    instagram: z
      .object({
        id: z.number(),
        fbPageId: z.string(),
        fbPageName: z.string(),
        businessAccountId: z.string(),
        instagramAccountName: z.string(),
        needReconnect: z.boolean().optional()
      })
      .optional()
      .readonly(),
    facebookPage: z
      .object({
        fbPageId: z.string(),
        fbPageName: z.string(),
        id: z.number().optional(),
        needReconnect: z.boolean().optional()
      })
      .optional()
      .readonly(),
    twitter: z
      .object({
        name: z.string(),
        twitterUserId: z.string(),
        needReconnect: z.boolean().optional()
      })
      .optional()
      .readonly(),
    youtube: z
      .object({
        channelId: z.string(),
        channelName: z.string(),
        needReconnect: z.boolean().optional()
      })
      .optional()
      .readonly()
  })
);
