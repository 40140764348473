import { CSSProperties, ReactNode } from 'react';
import { FormStyle } from '@/shared/atoms';

export interface BasicFilterWrapperProps {
  className?: string;
  children?: ReactNode;
  alignItems?: CSSProperties['alignItems'];
}

export const BasicFilterWrapper = ({ children, className, alignItems = 'flex-end' }: BasicFilterWrapperProps) => (
  <FormStyle.Wrapper css={{ padding: '0 8px' }} gapRow={16} gapColumn={8} className={className}>
    <FormStyle.FieldsGroup css={{ alignItems }}>{children}</FormStyle.FieldsGroup>
  </FormStyle.Wrapper>
);
