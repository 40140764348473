import { useRefreshPasswordMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { SignInSignUpLayout, RefreshPasswordForm, RefreshPasswordFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const PasswordForgot = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callRefreshPassword } = useRefreshPasswordMutation();

  const handleSubmit: FormAction<RefreshPasswordFormValues>['onSubmit'] = async ({ email }, methods) => {
    try {
      const result = await callRefreshPassword({ variables: { input: { email } } });

      if (result?.data?.refreshPassword) {
        methods.setValue('isSuccess', true);
      } else {
        enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('failedToRequest'), { variant: 'error' });
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <SignInSignUpLayout
      contentClassName={{ padding: '48px 40px' }}
      css={{ paddingTop: '82px', justifyContent: 'flex-start' }}
    >
      <RefreshPasswordForm onSubmit={handleSubmit} />
    </SignInSignUpLayout>
  );
};
