import { useTranslation } from 'react-i18next';
import { SocialAccountInfluencerListSortField, Order } from '@/graphql';
import { SortOption } from '@/shared/types';
import { InfluencerSortActionBarTemplate, InfluencersSortTemplate } from '../../shared';
import { useThreadsInfluencersContext } from '../ThreadsInfluencersContext';

export const ThreadsInfluencersSort = () => {
  const { t } = useTranslation();
  const { sort, limit, setSort, setLimit, isEngagementPage, sortSelectButtonProps } = useThreadsInfluencersContext();

  const sortOptions: SortOption<SocialAccountInfluencerListSortField>[] = [
    {
      order: Order.ASC,
      label: t('Follower (ASC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_FOLLOWERS
    },
    {
      order: Order.DESC,
      label: t('Follower (DESC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_FOLLOWERS
    },
    {
      order: Order.ASC,
      label: t('ER by followers (ASC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE
    },
    {
      order: Order.DESC,
      label: t('ER by followers (DESC)'),
      field: SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE
    },
    {
      order: Order.ASC,
      label: t('Avg Reach (ASC)'),
      field: SocialAccountInfluencerListSortField.AVG_REACH
    },
    {
      order: Order.DESC,
      label: t('Avg Reach (DESC)'),
      field: SocialAccountInfluencerListSortField.AVG_REACH
    },
    {
      order: Order.ASC,
      label: t('Avg Like (ASC)'),
      field: SocialAccountInfluencerListSortField.AVG_LIKE
    },
    {
      order: Order.DESC,
      label: t('Avg Like (DESC)'),
      field: SocialAccountInfluencerListSortField.AVG_LIKE
    }
  ].map((option) => ({ ...option, value: `${option.field}-${option.order}` }));

  return (
    <div css={{ position: 'relative', borderRadius: 'inherit' }}>
      <InfluencersSortTemplate
        setSort={setSort}
        setLimit={setLimit}
        sortOptions={sortOptions}
        selectButtonProps={sortSelectButtonProps}
        initialValues={{ sort, limit: String(limit) }}
      />

      {!isEngagementPage && sortSelectButtonProps?.checked ? (
        <InfluencerSortActionBarTemplate selectButtonProps={sortSelectButtonProps} />
      ) : null}
    </div>
  );
};
