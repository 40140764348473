import { css } from '@emotion/react';
import debounce from 'lodash/debounce';
import React from 'react';
import { useUserRoles, useEngagementPermissions } from '@/auth';
import {
  TTCMStatus,
  EngagementDetail,
  EngagementCampaignStatus,
  EngagementProposalSocialMediaType,
  ProposalJobDescriptionBreakDownMode,
  useDeleteEngagementProposalJoinedAccountMutation
} from '@/graphql';
import {
  Anchor,
  Button,
  RowProps,
  CheckBox,
  TTCMBadge,
  TableCell,
  SocialIcon,
  ColumnProps,
  CheckBoxProps,
  AnchorWithStatus
} from '@/shared/atoms';
import { useCopy, useGenerateInfluencerProfileLink, useQueryHelper } from '@/shared/hooks';
import { InfluencerAvatar, SpreadButton } from '@/shared/molecules';
import { formatIntNumber, formatPercent, formatNumber } from '@/shared/utils';
import { RowData, RowStatsData, EditableFieldKeys, RowSocialAccountData } from '../types';
import { useEngagementProposalInfluencerDetailsParam } from './useEngagementProposalInfluencerDetailsParam';

interface Props {
  rowsData: RowData[];
  isEditMode?: boolean;
  hasRowChange?: boolean;
  listSelectedIds?: number[];
  engagement: EngagementDetail;
  hasTrackingReferral?: boolean;
  selectButtonProps: CheckBoxProps;
  hasInfluencerCostPermission?: boolean;
  setRowsData?: (rowsData: RowData[]) => void;
  jdMode: ProposalJobDescriptionBreakDownMode;
  setHasRowChange?: (hasRowChange: boolean) => void;
  onRowSelect: (influencerId: number, socialAccountId?: number) => void;
}

export const UseEngagementProposalTable = ({
  jdMode,
  rowsData,
  isEditMode,
  onRowSelect,
  setRowsData,
  hasRowChange,
  setHasRowChange,
  selectButtonProps,
  hasTrackingReferral,
  listSelectedIds = [],
  hasInfluencerCostPermission,
  engagement: { id, status, currency, isTtcmCampaign, adAgencyMarginRate }
}: Props) => {
  const { copyToClipboard } = useCopy(null);
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const { t, enqueueSnackbar, trimDotsFromMessage, invalidateRouteLoader } = useQueryHelper();
  const { isAdvertiser, isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { hideToggleInfluencerFromProposalBtn } = useEngagementPermissions();
  const { setInfluencerDetailsParam } = useEngagementProposalInfluencerDetailsParam();
  const { callDeleteEngagementProposalJoinedAccount, loading: removing } =
    useDeleteEngagementProposalJoinedAccountMutation({ onCompleted: invalidateRouteLoader });

  const hasNoAgencyRateOrAdvertiser = !adAgencyMarginRate || isAdvertiser;
  const isDraftOrReviewingOrOngoingOrUpcomingStatus = [
    EngagementCampaignStatus.DRAFT,
    EngagementCampaignStatus.REVIEWING,
    EngagementCampaignStatus.ONGOING,
    EngagementCampaignStatus.UPCOMING
  ].includes(status);

  const handleRowChange = debounce((index: number, stats: RowStatsData) => {
    const newAllRowValues = [...rowsData];
    newAllRowValues[index] = { ...(newAllRowValues[index] as RowData), stats };

    setRowsData?.(newAllRowValues);

    if (!hasRowChange) {
      setHasRowChange?.(true);
    }
  }, 500);

  const handleStatsChange = (name: string, value: string, index: number, stats: RowStatsData) => {
    // Make number always positive
    const inputValue = Math.abs(value ? Number(value) : 0);
    if (isEditMode) {
      const newValues: RowStatsData = { ...stats };
      switch (name) {
        case EditableFieldKeys.netBudget:
          newValues.netBudget = inputValue;
          newValues.budget = inputValue * (1 + Number(adAgencyMarginRate || 0));
          newValues.profit = inputValue - newValues.influencerCost;
          break;
        case EditableFieldKeys.influencerCost:
          newValues.influencerCost = inputValue;
          newValues.profit = newValues.netBudget - inputValue;
          break;
        default:
          break;
      }

      handleRowChange(index, newValues);
    }
  };

  const handleRemoveSmAccountFromProposal = async ({
    influencerId,
    socialAccountId,
    socialAccountMedia
  }: {
    influencerId: number;
    socialAccountId: number;
    socialAccountMedia: EngagementProposalSocialMediaType;
  }) => {
    try {
      await callDeleteEngagementProposalJoinedAccount({
        variables: { input: { campaignId: id, influencerId, socialAccountId, socialAccountMedia } }
      });
      // we don't want to notify user on each save/delete, just show an error if happens
    } catch (error) {
      enqueueSnackbar(t(trimDotsFromMessage(error.message)), { variant: 'error' });
    }
  };

  const renderStatCells = ({ index, stats }: RowData, editable?: boolean) => {
    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      handleStatsChange(name, value, index, stats);
    };

    return [
      <TableCell.Number key={`followers-${index}`} value={formatIntNumber(stats.followersCount)} />,
      <TableCell.Number
        key={`er-by-views-${index}`}
        value={formatPercent(stats.engagementRateByViews, false)}
        unit="%"
      />,
      <TableCell.Number
        key={`er-by-followers${index}`}
        value={formatPercent(stats.engagementRateByFollowers, false)}
        unit="%"
      />,
      !hasNoAgencyRateOrAdvertiser || !editable ? (
        <TableCell.Number key={`budget-${index}`} value={formatNumber(stats.budget, 0)} unit={t(currency || '')} />
      ) : (
        <TableCell.Input
          min={0}
          type="number"
          placeholder="0"
          unit={currency}
          onChange={handleInputChange}
          key={`budget-input-${index}`}
          defaultValue={stats.netBudget}
          name={EditableFieldKeys.netBudget}
        />
      ),
      !hasNoAgencyRateOrAdvertiser && !editable ? (
        <TableCell.Number
          key={`net-budget-${index}`}
          value={formatNumber(stats.netBudget, 0)}
          unit={t(currency || '')}
        />
      ) : (
        <TableCell.Input
          min={0}
          type="number"
          placeholder="0"
          unit={currency}
          onChange={handleInputChange}
          defaultValue={stats.netBudget}
          key={`net-budget-input-${index}`}
          name={EditableFieldKeys.netBudget}
        />
      ),
      !editable ? (
        <TableCell.Number
          key={`influencer-cost-${index}`}
          value={formatNumber(stats.influencerCost, 0)}
          unit={t(currency || '')}
        />
      ) : (
        <TableCell.Input
          key={`influencer-cost-input-${index}`}
          min={0}
          type="number"
          placeholder="0"
          unit={currency}
          onChange={handleInputChange}
          defaultValue={stats.influencerCost}
          name={EditableFieldKeys.influencerCost}
        />
      ),
      <TableCell.Number key={`profit-${index}`} value={formatNumber(stats.profit, 0)} unit={t(currency || '')} />,
      <TableCell.Date key={`date-${index}`} css={{ minWidth: '80px' }} value={stats.addedDate} />,
      <TableCell.Number key={`engagement-proposed-${index}`} value={formatNumber(stats.engagementProposed, 0)} />,
      <TableCell.Number key={`engagement-posted-${index}`} value={formatNumber(stats.engagementPosted, 0)} />,
      stats.ttcmCampaignInviteLink ? (
        <Button
          variant="white"
          key={`ttcm-link-${index}`}
          css={{ padding: '0 24px' }}
          title={t('Button.Copy Link')}
          onClick={() => copyToClipboard(stats.ttcmCampaignInviteLink)}
        />
      ) : (
        <div key={`ttcm-link-${index}`} />
      ),
      <TableCell.Text css={{ minWidth: '50px' }} key={`referral-code-${index}`} value={stats.couponCode || ''} />
    ];
  };

  const renderInfluencerRow = (rowData: RowData): RowProps => {
    const { influencer } = rowData;
    const isTTCMCreator = influencer?.ttcmStatus && influencer?.ttcmStatus === TTCMStatus.APPROVED;

    return [
      !isEditMode || jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN ? (
        <CheckBox
          key={`checkbox-${influencer.id}`}
          onClick={() => onRowSelect(influencer.id)}
          checked={listSelectedIds.includes(influencer.id)}
          tooltipProps={{ help: (selectButtonProps.checked ? t('Unselect') : t('Select')) as string }}
        />
      ) : (
        <div key={`checkbox-blank-${influencer.id}`} css={styles.blankCheckBoxCell} />
      ),
      <div key={`influencer-${influencer.id}`} css={{ display: 'flex', alignItems: 'center' }}>
        <InfluencerAvatar
          size="32px"
          src={influencer.avatar}
          css={{ borderRadius: '3px', cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            setInfluencerDetailsParam(influencer.id);
          }}
        />
        <AnchorWithStatus
          label={influencer.name}
          status={influencer.status}
          css={{ fontSize: '16px', marginLeft: '16px' }}
          {...generateProfileLink(influencer.id)}
        />
        {isTTCMCreator ? <TTCMBadge>{t('TTCM')}</TTCMBadge> : null}
      </div>,
      ...renderStatCells(rowData, jdMode === ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN && isEditMode)
    ];
  };

  const renderSocialAccountRow = (rowData: RowData): RowProps => {
    const { influencer, socialAccount } = rowData;
    const account = socialAccount as RowSocialAccountData;
    const hasRemoveButton = !isEditMode && !hideToggleInfluencerFromProposalBtn && isAdminStaffTalentAgencyPartner;
    const isShownRemoveButton =
      isTtcmCampaign && influencer.hasOnlyOneTiktokAccount
        ? account.socialMedia !== EngagementProposalSocialMediaType.TIKTOK && hasRemoveButton
        : hasRemoveButton;

    return {
      styles: css({
        height: 'auto',
        borderTop: 'none',
        ...(account.lastIndex ? { '> td': { paddingBottom: '16px' } } : {})
      }),
      cells: [
        isEditMode ? (
          <CheckBox
            key={`checkbox-${socialAccount?.id}`}
            checked={listSelectedIds.includes(account.id)}
            onClick={() => onRowSelect(influencer.id, account.id)}
            tooltipProps={{ help: selectButtonProps.checked ? t('Unselect') : t('Select') }}
          />
        ) : (
          <div key={`checkbox-blank-${socialAccount?.id}`} css={styles.blankCheckBoxCell} />
        ),
        <div key={`social-account-${socialAccount?.id}`} css={styles.influencerWrapper}>
          <SocialIcon size="24px" type={account.socialMedia} />
          <Anchor
            label={account.name}
            css={{ fontSize: '12px', marginLeft: '16px' }}
            {...generateProfileLink(influencer.id, {
              filter: {
                id: String(account.id),
                sm:
                  account.socialMedia === EngagementProposalSocialMediaType.INSTAGRAM_STORY
                    ? EngagementProposalSocialMediaType.INSTAGRAM
                    : account.socialMedia
              }
            })}
          />
          {isShownRemoveButton ? (
            <SpreadButton
              options={[
                {
                  icon: 'delete',
                  label: t('Remove'),
                  disabled: !isDraftOrReviewingOrOngoingOrUpcomingStatus || removing,
                  onClick: () =>
                    handleRemoveSmAccountFromProposal({
                      influencerId: influencer.id,
                      socialAccountId: account.id,
                      socialAccountMedia: account.socialMedia
                    })
                }
              ]}
              css={styles.spreadButton}
            />
          ) : null}
        </div>,
        ...renderStatCells(rowData, isEditMode)
      ]
    };
  };

  const columns: ColumnProps[] = [
    {
      sticky: { active: true },
      styles: { width: '48px', minWidth: '48px' },
      title: <CheckBox tooltipProps={{ help: t('Select all') }} {...selectButtonProps} />
    },
    {
      title: t('HeaderColumn.Influencers'),
      sticky: { active: true, left: '48px', hasBorderRight: true },
      styles: { minWidth: '268px', width: '380px', paddingLeft: '0 !important' }
    },
    { title: t('HeaderColumn.Followers'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Avg ER by views'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Avg ER by followers'), styles: { textAlign: 'right' } },
    {
      title: t(`HeaderColumn.${hasNoAgencyRateOrAdvertiser ? 'Budget' : 'Gross Budget'}`),
      styles: { textAlign: 'right' }
    },
    { title: t('HeaderColumn.Net Budget'), styles: { textAlign: 'right' }, hidden: hasNoAgencyRateOrAdvertiser },
    {
      title: t('HeaderColumn.Influencer Cost'),
      styles: { textAlign: 'right' },
      hidden: !hasInfluencerCostPermission || !isAdminStaffTalentAgencyPartner
    },
    {
      title: t('HeaderColumn.Profit'),
      styles: { textAlign: 'right' },
      hidden: !hasInfluencerCostPermission || !isAdminStaffTalentAgencyPartner
    },
    { title: t('HeaderColumn.List Added Date') },
    {
      title: t('HeaderColumn.Engagement Proposed'),
      styles: { textAlign: 'right' },
      hidden: !isAdminStaffTalentAgencyPartner
    },
    {
      title: t('HeaderColumn.Engagement Posted'),
      styles: { textAlign: 'right' },
      hidden: !isAdminStaffTalentAgencyPartner
    },
    { title: t('HeaderColumn.Invitation Link'), hidden: !isTtcmCampaign },
    { title: t('HeaderColumn.Referral Code'), hidden: !hasTrackingReferral }
  ];

  const rows: RowProps[] = rowsData.map((rowData) =>
    rowData.socialAccount ? renderSocialAccountRow(rowData) : renderInfluencerRow(rowData)
  );

  return { rows, columns };
};

const styles = {
  spreadButton: css({ marginLeft: 'auto', img: { width: 'auto', height: 'auto' } }),
  blankCheckBoxCell: css({ flex: 'none', width: '24px', height: 'auto', boxSizing: 'border-box' }),
  influencerWrapper: css({
    height: '40px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    boxSizing: 'border-box',

    '&::before': {
      top: '0',
      left: '0',
      width: '1px',
      content: '""',
      height: 'inherit',
      margin: '0 32px 0 16px',
      backgroundColor: '#dee5ef'
    }
  })
};
