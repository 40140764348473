import { z } from 'zod';
import { Genders } from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';

export const SimilarInfluencersPopperFilterSchema = z
  .object({
    genders: z.array(z.nativeEnum(Genders)),
    minAge: z.string().or(z.number()),
    maxAge: z.string().or(z.number()),
    minFollowers: z.string().or(z.number()),
    maxFollowers: z.string().or(z.number())
  })
  .superRefine((values, ctx) => {
    if (Number(values.minAge) > Number(values.maxAge)) {
      ctx.addIssue({
        message: AllTranslationKeys.Error.minAgeLessThanMaxAge,
        code: z.ZodIssueCode.custom,
        path: ['maxAge']
      });
    }
    if (Number(values.minFollowers) > Number(values.maxFollowers)) {
      ctx.addIssue({
        message: AllTranslationKeys.Error.minFollowersMoreThanMaxFollowers,
        code: z.ZodIssueCode.custom,
        path: ['maxFollowers']
      });
    }
  });
