import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useRouter } from '@tanstack/react-router';
import { useToaster } from '@/graphql';

export const useQueryHelper = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useToaster();
  const location = useLocation();
  const navigate = useNavigate();
  const router = useRouter();
  const trimDotsFromMessage = (message: string) => (message ? message.replace(/\./g, '') : 'UnexpectedError');

  return {
    t,
    i18n,
    router,
    navigate,
    location, // Current search params
    ...location,
    enqueueSnackbar,
    trimDotsFromMessage,
    goBack: () => router.history.back(),
    invalidateRouteLoader: () => router.invalidate()
  };
};
