import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBox, CheckBoxProps } from '@/shared/atoms';

export interface SelectAllProps extends CheckBoxProps {
  className?: string;
  children?: ReactElement;
}

export const SelectAll = ({ className, children, ...checkboxProps }: SelectAllProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={className}
      css={{
        gap: '8px',
        display: 'flex',
        padding: '0 8px',
        minHeight: '32px',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderBottom: '1px solid #e4e4e4'
      }}
    >
      <CheckBox label={t('Select all')} css={{ flex: 1 }} {...checkboxProps} />
      {children} {/* TODO: children still not really working yet. Fix later */}
    </div>
  );
};
