import { EngagementCampaignPostReportAudienceBreakdown, EngagementDetail } from '@/graphql';
import { Template } from './Template';

export interface EngagementReportAudienceBreakdownProps {
  engagement: EngagementDetail;
  data?: EngagementCampaignPostReportAudienceBreakdown | null;
}

export const EngagementReportAudienceBreakdown = ({ data, engagement }: EngagementReportAudienceBreakdownProps) => {
  const ttPostAudienceData = data?.tiktok;
  const ytPostAudienceData = data?.youtube;
  const areaRates = ytPostAudienceData?.areaRates || ttPostAudienceData?.areaRates || [];
  const genderRates = ytPostAudienceData?.genderRates || ttPostAudienceData?.genderRates;
  const ageGenderRates = ytPostAudienceData?.ageGenderRates; // unique for YouTube
  const ageRates = ttPostAudienceData?.ageRates; // unique for TikTok
  const formattedAreaRates = areaRates.map(({ countryId, rate }) => ({ label: countryId, value: rate })) || [];
  const formattedAgeRates = ageRates?.ageGroups.map(({ start, end }, i) => ({
    label: start < 35 ? `${start} - ${end}` : '35+',
    value: ageRates?.rates?.at(i) as number
  }));
  // we cannot have them at the same time, it is safe to check at least one of them is present - then we can show UI
  const hasAgeGenderRates =
    !!ageGenderRates?.ageGroups.length && !!ageGenderRates?.maleRates.length && !!ageGenderRates?.femaleRates.length;
  const hasAgeRates = ageRates?.rates?.length;
  const hasAudienceValue =
    !!genderRates?.femaleRate || !!genderRates?.maleRate || !!areaRates.length || hasAgeGenderRates || hasAgeRates;

  return hasAudienceValue ? (
    <Template
      engagement={engagement}
      genderRates={genderRates}
      ageOptions={formattedAgeRates}
      ageGenderRates={ageGenderRates}
      areaOptions={formattedAreaRates}
    />
  ) : null;
};
