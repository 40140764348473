import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EngagementCampaignPostStatus, Query } from '@/graphql';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter } from '@/shared/utils';
import {
  PostCaption,
  InfluencerInfo,
  PostMediaPreview,
  DefaultHistoryTemplate,
  DefaultHistoryTemplateProps
} from '../shared';

export interface EngagementHistoryDetailsTabProps extends DefaultHistoryTemplateProps {
  historyData?: Query['engagementDraftPostHistory'];
}

export const EngagementHistoryDetailsTab = ({ historyData, ...restProps }: EngagementHistoryDetailsTabProps) => {
  const { t, i18n } = useTranslation();
  // set first history record ID for initial post preview
  const [selectedPostId, setSelectedPostId] = useState(historyData?.historyRecords?.[0]?.id || null);

  const historyRecords = historyData?.historyRecords || [];
  const influencerInfo = historyData?.engagementPostDetailInfluencer;
  const selectedPostPreviewData = historyRecords.find((post) => post.id === selectedPostId);

  return (
    <DefaultHistoryTemplate hasNoData={!historyRecords?.length} {...restProps}>
      <div css={{ display: 'flex', flex: '1 1 auto' }}>
        <div css={styles.wrapper}>
          {historyRecords.map((post, i) => {
            const isRejected = post.reason === 'You rejected the video'; // currently only one case then we need to change UI

            return (
              <div
                key={`${post.id}_${i}`}
                onClick={() => (post.id ? setSelectedPostId(post.id) : null)}
                css={styles.itemWrapper(post.id === selectedPostId, !post.id)}
              >
                <div css={{ flex: 'none', width: '95px', fontSize: '12px', color: THEME.text.colors.black.lv1 }}>
                  {localizedDateFormatter(post.createdDate, 'PPP', i18n.language as AppLanguage)}
                </div>
                <div css={{ flex: 1, fontSize: '13px', fontWeight: 600, lineHeight: 1.38 }}>
                  {post.postStatus === EngagementCampaignPostStatus.READY_TO_POST ? (
                    <span css={{ color: '#40b87c' }}>{t('Approved the post')}</span>
                  ) : (
                    <>
                      <p css={isRejected ? { color: '#FF5F5F' } : {}}>{t(post.reason || '')}</p>
                      <p css={styles.detailedReason}>{post.detailedReason}</p>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {selectedPostId ? (
          <div css={styles.detailsWrapper}>
            {influencerInfo ? (
              <InfluencerInfo
                css={{ width: '250px', height: 'unset', padding: '8px 16px' }}
                info={{
                  id: String(influencerInfo?.id ?? ''),
                  name: influencerInfo?.name,
                  avatar: influencerInfo?.avatar,
                  followers: influencerInfo?.followersCount,
                  postDate: selectedPostPreviewData?.createdDate
                }}
              />
            ) : null}

            <div css={{ flex: 1, display: 'flex', padding: '8px 16px' }}>
              <PostMediaPreview
                css={{ width: '240px', height: '240px', marginRight: '16px' }}
                images={selectedPostPreviewData?.images || []}
              />
              <PostCaption css={{ marginTop: 0 }} caption={selectedPostPreviewData?.postContent} />
            </div>
          </div>
        ) : null}
      </div>
    </DefaultHistoryTemplate>
  );
};
const styles = {
  wrapper: css({
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRight: THEME.border.base,
    height: '380px'
  }),
  detailsWrapper: css({
    flex: 1.7,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box'
  }),
  detailedReason: css({
    fontSize: '12px',
    lineHeight: 1.5,
    wordBreak: 'break-all',
    color: THEME.text.colors.gray.lv3
  }),
  itemWrapper: (isSelected?: boolean, disabled?: boolean) =>
    css({
      width: '100%',
      display: 'flex',
      cursor: 'pointer',
      padding: '16px 24px',
      alignItems: 'center',
      boxSizing: 'border-box',
      borderBottom: THEME.border.base,
      ...(isSelected ? { backgroundColor: '#eef3f7' } : {}),
      ...(disabled ? { pointerEvents: 'none' } : {})
    })
};
