import { useLocationHistory } from './useLocationHistory';
import { useDeepCompareEffect } from './useDeepCompareEffect';
import { UpdateDocumentTitleProps, useUpdateDocumentTitle } from './useUpdateDocumentTitle';

/**
 * https://adasiaholdings.atlassian.net/browse/AT-6006
 * @param parentUrl default document back url
 * @returns back url + latest search
 */
export const useGetDocumentParentHref = (props: UpdateDocumentTitleProps) => {
  const { lastLocation, currentLocation } = useLocationHistory();
  const { setDocumentAndAppTitles } = useUpdateDocumentTitle({});
  const location = lastLocation || currentLocation;

  useDeepCompareEffect(() => {
    if (location) {
      const isMatchParentPath = props.href === location.pathname;

      setDocumentAndAppTitles({
        ...props,
        search: isMatchParentPath ? location.search : undefined
      });
    }
  }, [props, location]);
};
