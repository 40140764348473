import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateYoutubeMCNInfluencerCredentialsForAdmin.graphql';
import { Mutation, MutationupdateYoutubeMCNInfluencerCredentialsArgs } from '../../__generated__/globalTypes';

export const useUpdateYoutubeMCNInfluencerCredentialsForAdminMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateYoutubeMCNInfluencerCredentialsArgs>
) => {
  const [callUpdateYoutubeMCNInfluencerCredentialsForAdmin, result] = useMutation<
    Mutation,
    MutationupdateYoutubeMCNInfluencerCredentialsArgs
  >(MUTATION, options);

  return { callUpdateYoutubeMCNInfluencerCredentialsForAdmin, ...result };
};

export type UpdateYoutubeMCNInfluencerCredentialsForAdminMutationFunction =
  Mutation['updateYoutubeMCNInfluencerCredentials'];
