import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ReSendBcaRequest.graphql';
import { Mutation, MutationreSendBcaRequestArgs } from '../../__generated__/globalTypes';

export const useReSendBcaRequestMutation = (options?: MutationHookOptions<Mutation, MutationreSendBcaRequestArgs>) => {
  const [callReSendBcaRequest, result] = useMutation<Mutation, MutationreSendBcaRequestArgs>(MUTATION, options);

  return { callReSendBcaRequest, ...result };
};

export type ReSendBcaRequestMutationFunction = Mutation['reSendBcaRequest'];
