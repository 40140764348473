import { useTranslation } from 'react-i18next';

import { CampaignSocialMediaType } from '@/graphql';
import {
  Icon,
  Anchor,
  Button,
  TextCutter,
  SocialIcon,
  MediaPreview,
  SocialIconProps
} from '@/shared/atoms';
import { getSocialMediaTextLink } from '@/shared/utils';

import { POST_REPORT_STICKY_COL_DIMS } from './utils';

interface PostProps {
  url: string;
  size?: string;
  content: string;
  isEngagement?: boolean;
  onClickPost: () => void;
  thumbNail: string | null;
  hasTrafficSource: boolean | null;
  socialMedia: CampaignSocialMediaType;
}

export const Post = ({
  url,
  content,
  thumbNail,
  socialMedia,
  onClickPost,
  size = '40px',
  isEngagement,
  hasTrafficSource
}: PostProps) => {
  const { t } = useTranslation();

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        width: POST_REPORT_STICKY_COL_DIMS.postCaptionLinkWidth,
        marginLeft: 'auto'
      }}
    >
      <MediaPreview
        objectFit="contain"
        onClick={onClickPost}
        css={{ width: size, height: size, borderRadius: '2px' }}
        src={thumbNail}
        caption={content}
        captionProps={{ margin: 4 }}
      />
      <div css={{ display: 'flex', marginLeft: '8px', justifyContent: 'space-between', gap: '8px', minWidth: '100px' }}>
        <div>
          <TextCutter
            lines={2}
            onClick={onClickPost}
            text={content || '-'}
            css={{ fontSize: '12px', cursor: 'pointer', lineHeight: '12px' }}
          />
          {url ? (
            <Anchor
              href={url}
              target="_blank"
              variant="blue"
              css={{ display: 'flex', alignItems: 'center', marginTop: '4px', fontSize: '12px' }}
            >
              <SocialIcon size="16px" type={socialMedia as SocialIconProps['type']} css={{ marginRight: '4px' }} />
              {t('View on', { socialMedia: getSocialMediaTextLink(socialMedia) })}
            </Anchor>
          ) : null}
        </div>
        {isEngagement && socialMedia === CampaignSocialMediaType.YOUTUBE && hasTrafficSource ? (
          <Button
            onClick={onClickPost}
            title={<Icon icon="bar-chart" size={12} />}
            tooltip={{ title: t<string>('Traffic Source') }}
            css={{ alignSelf: 'center', minWidth: '24px', padding: '0', height: '24px' }}
          />
        ) : null}
      </div>
    </div>
  );
};
