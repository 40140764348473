import { useQueryHelper } from '@/shared/hooks';
import { ProposalJobDescriptionBreakDownMode, namedOperations, useUpdatePackageProposalModeMutation } from '@/graphql';
import {
  ProposeInfluencersTableMode,
  ProposeInfluencersTableModes,
  usePackageProposalContext
} from '@/shared/organisms';
import { useUpdatePackageProposal } from '../hooks';
import { RowPackageData } from './types';

interface ToggleJdModeAndBudgetEditProps {
  rowValues: RowPackageData[];
  resetEditingRowsData: () => void;
  tableViewMode: ProposeInfluencersTableMode;
  hasRowChange: boolean;
  handleTableViewModeChange: (mode: ProposeInfluencersTableMode) => void;
}
export const ToggleJdModeAndBudgetEdit = ({
  rowValues,
  resetEditingRowsData,
  tableViewMode,
  hasRowChange,
  handleTableViewModeChange
}: ToggleJdModeAndBudgetEditProps) => {
  const { enqueueSnackbar } = useQueryHelper();
  const { loading: updProposalModeLoading, callUpdatePackageProposalMode } = useUpdatePackageProposalModeMutation({
    refetchQueries: [namedOperations.Query.PackageProposal, namedOperations.Query.PackageProposalSummary]
  });
  const { proposalSummary } = usePackageProposalContext();

  const jdMode = proposalSummary?.jdMode || ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN;

  const { handleSaveProposal, updatingAccountsBudget, updatingInfluencersBudget } = useUpdatePackageProposal({
    packageId: proposalSummary?.id || 0,
    hasRowChange,
    rowValues,
    jdMode,
    resetEditingRowsData
  });

  const handleJdModeChange = async (mode: ProposalJobDescriptionBreakDownMode) => {
    if (proposalSummary) {
      try {
        await callUpdatePackageProposalMode({
          variables: { input: { jdMode: mode, packageId: proposalSummary.id } }
        });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  return (
    <ProposeInfluencersTableModes
      jdMode={jdMode}
      tableViewMode={tableViewMode}
      loading={updProposalModeLoading}
      onEditJdMode={handleJdModeChange}
      onTableViewModeChange={handleTableViewModeChange}
      saveButtonProps={{
        loading: updatingInfluencersBudget || updatingAccountsBudget,
        disabled: !hasRowChange,
        onClick: handleSaveProposal
      }}
    />
  );
};
