import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateTiktokAdAccount.graphql';
import { Mutation, MutationcreateTiktokAdAccountArgs } from '../../__generated__/globalTypes';

export const useCreateTiktokAdAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateTiktokAdAccountArgs>
) => {
  const [callCreateTiktokAdAccount, result] = useMutation<Mutation, MutationcreateTiktokAdAccountArgs>(
    MUTATION,
    options
  );

  return { callCreateTiktokAdAccount, ...result };
};

export type CreateTiktokAdAccountMutationFunction = Mutation['createTiktokAdAccount'];
