import { useParams, useSearch } from '@tanstack/react-router';
import {
  SocialAccountType,
  useEngagementPostV2Query,
  useUpdateTtcmPostMutation,
  useUpdateEngagementPostV2Mutation,
  namedOperations
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { EngagementPostForm, EngagementPostFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';

export const EngagementEditPost = () => {
  const { engagementId, engagement } = useEngagementDetailsContext();
  const params = useParams({ from: '/_private-routes/engagement/$id/posts/$postId' });
  const search = useSearch({ from: '/_private-routes/engagement/$id/posts/$postId' });
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { callUpdateTtcmPost } = useUpdateTtcmPostMutation({
    refetchQueries: [namedOperations.Query.AllEngagementPosts]
  });
  const { callUpdateEngagementPostV2 } = useUpdateEngagementPostV2Mutation({
    refetchQueries: [namedOperations.Query.AllEngagementPosts]
  });
  const { data, loading } = useEngagementPostV2Query({
    skip: !params.postId,
    fetchPolicy: 'network-only',
    variables: { pk: Number(params.postId) },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      goToPostsList();
    }
  });

  const postData = data?.engagementPostV2;

  const goToPostsList = () => {
    navigate({ to: '/engagement/$id/posts', params, search, replace: true });
  };

  const handleSubmit: FormAction<EngagementPostFormValues>['onSubmit'] = async (values) => {
    if (!postData) return;

    try {
      if (values.isTtcmForm) {
        await callUpdateTtcmPost({
          variables: {
            input: {
              id: postData.id,
              content: values.content,
              thumbnailUrl: values.materialsUrl[0] || ''
            }
          }
        });
      } else {
        await callUpdateEngagementPostV2({
          variables: {
            input: {
              id: postData.id,
              content: values.content,
              postUrl: values.postUrl,
              campaignId: engagementId,
              insightUrls: values.insightUrls,
              materialsUrl: values.materialsUrl,
              planedPostDate: values.planedPostDate,
              influencerId: Number(values.influencerId),
              socialAccountId: Number(values.socialAccountId),
              insightDataAcquisition: values.insightDataAcquisition,
              socialAccountMedia: values.socialAccountMedia as SocialAccountType
            }
          }
        });
      }
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      goToPostsList();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <EngagementPostForm
      data={postData}
      loading={loading}
      onSubmit={handleSubmit}
      onClose={goToPostsList}
      engagement={engagement}
    />
  );
};
