import { z } from 'zod';
import { AffiliateReportShowBy, MarketplaceAffiliateReportByInfluencerField } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';

export const AutoManagedReportFilterSchema = z.object({
  mode: z.nativeEnum(AffiliateReportShowBy).default(AffiliateReportShowBy.DATE),
  startDate: z.string().optional(),
  endDate: z.string().optional()
});
export const AutoManagedReportSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(AutoManagedReportFilterSchema, {
    mode: AffiliateReportShowBy.DATE,
    startDate: '',
    endDate: ''
  }),
  ...SearchFilterDefaults.pageLimit(),
  sort: SearchFilterDefaults.sort(MarketplaceAffiliateReportByInfluencerField).default({}) // only present for AutoManagedReportByInfluencerTable
});

export type AutoManagedReportSearchSchemaType = z.infer<typeof AutoManagedReportSearchSchema>;
export type AutoManagedReportFilterFormValues = z.infer<typeof AutoManagedReportFilterSchema>;
