import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { FormStyle } from '@/shared/atoms';
import { InstagramUserHashtag } from '@/graphql';
import { useExportAnalyticsInstagramHashtagsFile } from '@/shared/organisms';
import { TextSearchField, BasicFilterWrapper, SingleDatePickerField } from '@/shared/molecules';
import { HashtagsList } from './HashtagsList';
import { HashtagsAnalyticsFilterFormKeys, HashtagsAnalyticsFilterFormValues } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
  hashtags: readonly InstagramUserHashtag[];
}

export const Template = ({ hashtags, onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<HashtagsAnalyticsFilterFormValues>();
  const [dateValue, hashtagIdValue] = watch(['date', 'hashtagId']);
  const { ExportButton, ExportModal } = useExportAnalyticsInstagramHashtagsFile({ hashtagId: String(hashtagIdValue) });

  return (
    <>
      <div css={styles.wrapper}>
        <HashtagsList hashtags={hashtags} onSubmit={onSubmit} />

        <BasicFilterWrapper css={{ padding: 0, marginTop: '8px' }}>
          <TextSearchField<HashtagsAnalyticsFilterFormKeys>
            name="keyword"
            onSubmit={onSubmit}
            placeholder={t<string>('hashtagsAnalyticsKeywordPlaceholder')}
          />

          <SingleDatePickerField<HashtagsAnalyticsFilterFormKeys>
            name="date"
            onSubmit={onSubmit}
            disabledDays={{ after: new Date() }}
            css={{ flex: 'none', width: '184px' }}
            required
            clearable={false}
          />

          <FormStyle.FieldWrapper css={{ flex: 'none' }}>
            <ExportButton disabled={!dateValue || !hashtagIdValue} />
          </FormStyle.FieldWrapper>
        </BasicFilterWrapper>
      </div>

      {ExportModal}
    </>
  );
};
const styles = {
  wrapper: css({
    padding: '16px',
    boxSizing: 'border-box',
    borderTop: THEME.border.base,
    borderBottom: THEME.border.base
  })
};
