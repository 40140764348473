import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RejectEngagementDraftPost.graphql';
import { Mutation, MutationrejectEngagementDraftPostArgs } from '../../__generated__/globalTypes';

export const useRejectEngagementDraftPostMutation = (
  options?: MutationHookOptions<Mutation, MutationrejectEngagementDraftPostArgs>
) => {
  const [callRejectEngagementDraftPost, result] = useMutation<Mutation, MutationrejectEngagementDraftPostArgs>(
    MUTATION,
    options
  );

  return { callRejectEngagementDraftPost, ...result };
};

export type RejectEngagementDraftPostMutationFunction = Mutation['rejectEngagementDraftPost'];
