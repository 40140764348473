import { useParams } from '@tanstack/react-router';
import { useUserQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useCustomHeader, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { UserForm, getUserFormValues } from '@/shared/organisms';
import { useUpdateUser } from './hooks';

export interface UserDetailsProps {
  isUserSettingsForAdvertisers?: boolean;
}
export const UserDetails = ({ isUserSettingsForAdvertisers }: UserDetailsProps | undefined = {}) => {
  useCustomHeader();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/user/$id' });

  useUpdateDocumentTitle({
    title: 'documentTitle.EditUser',
    appHeader: 'appHeader.Edit User',
    href: isUserSettingsForAdvertisers ? undefined : '/user',
    alwaysBackToParent: true
  });

  const { updateUser } = useUpdateUser({ isUserSettingsForAdvertisers });
  const { data, loading } = useUserQuery({
    variables: { pk: Number(params.id) },
    fetchPolicy: 'network-only',
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/user' });
    }
  });

  const userData = data?.user;

  return (
    <RenderDataWithFallback loading={loading}>
      <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
        <Header />

        <UserForm
          onSubmit={updateUser(userData?.role)}
          isUserSettingsForAdvertisers={isUserSettingsForAdvertisers}
          defaultValues={getUserFormValues(userData, 'edit')}
        />
      </div>
    </RenderDataWithFallback>
  );
};
