import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateOpenAiThread.graphql';
import { Mutation } from '../../__generated__/globalTypes';

export const useCreateOpenAiThreadMutation = (options?: MutationHookOptions<Mutation>) => {
  const [callCreateOpenAiThread, result] = useMutation<Mutation>(MUTATION, options);

  return { callCreateOpenAiThread, ...result };
};

export type CreateOpenAiThreadMutationFunction = Mutation['createOpenAiThread'];
