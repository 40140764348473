import { useTranslation } from 'react-i18next';
import { NoDataTitle, NoDataContentWrapper, NoDataContentWrapperProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { getEstimatedWrapperText } from '@/shared/utils';
import { HighChart, Highcharts } from '../HighChart';

export interface GeneralCategoriesChartProps
  extends Pick<Highcharts.ChartOptions, 'width'>,
    Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'> {
  totalRate?: Highcharts.SeriesOptionsType[];
  hasEstimatedDemographics?: boolean;
  dataNotAvailable?: boolean;
  series?: Highcharts.SeriesOptionsType[];
  categories?: string[];
}

export const GeneralCategoriesChart = ({
  width,
  header,
  series,
  categories,
  totalRate,
  wrapperText,
  dataNotAvailable,
  hasEstimatedDemographics,
  ...noDataContentProps
}: GeneralCategoriesChartProps) => {
  const { t } = useTranslation();

  const options: Highcharts.Options = {
    title: { text: '' },
    credits: { enabled: false },
    chart: { width, type: 'bar', height: 300 },
    plotOptions: {
      bar: {
        cursor: 'pointer'
      },
      series: {
        states: {
          select: {
            color: THEME.chart.colors.violet.lv2,
            borderColor: THEME.chart.colors.violet.lv2
          },
          hover: { color: THEME.chart.colors.violet.lv1 }
        }
      },
      column: {
        states: {
          inactive: {
            enabled: false
          }
        }
      }
    },
    yAxis: {
      title: { text: null },
      crosshair: {
        color: 'transparent' // remove vertical line on the edge of bar chart on:hover, related xAxis crosshair
      }
    },
    xAxis: {
      categories,
      title: { text: null },
      crosshair: {
        // this crosshair will respond on:hover and create a hover area around the bar (we may need to calculate the width if many items)
        color: THEME.background.colors.gray.lv1,
        width: 60
      }
    },
    legend: {
      enabled: false
    },
    series: totalRate || [],
    tooltip: {
      enabled: false
    }
  };

  return (
    <NoDataContentWrapper
      css={{ padding: '24px 16px' }}
      wrapperText={getEstimatedWrapperText({ type: 'age', wrapperText, hasEstimatedDemographics })}
      dataNotAvailable={dataNotAvailable}
      header={
        header ?? (
          <NoDataTitle
            css={{ marginBottom: '15px' }}
            title={t(hasEstimatedDemographics ? 'Estimated Age' : 'Age Breakdown')}
            help={!hasEstimatedDemographics ? t<string>('Tooltip.ageBreakdown') : undefined}
          />
        )
      }
      {...noDataContentProps}
    >
      <HighChart options={options} />
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <span
            css={{
              width: '15px',
              height: '15px',
              marginRight: '8px',
              borderRadius: '2px',
              position: 'relative',
              backgroundColor: '#9ea0e0'
            }}
          />
          <p css={{ color: THEME.text.colors.black.lv1, fontSize: '13px' }}>{t('Total')}</p>
        </div>
      </div>
    </NoDataContentWrapper>
  );
};
