import { useTranslation } from 'react-i18next';
import { CampaignChart } from '@/shared/molecules';
import { RenderDataWithFallback, TableSegment } from '@/shared/atoms';
import { MarketplaceDetail } from '@/graphql';
import { PostReportEmpty, PostReportTable, PostReportSegmentKeys } from '../../../PostReport';
import { useMarketplaceReportTableData } from './hooks';

export interface MarketplaceReportTableProps {
  marketplace: MarketplaceDetail;
  totalRecords?: number;
}
export const MarketplaceReportTable = ({ marketplace, totalRecords }: MarketplaceReportTableProps) => {
  const { t } = useTranslation();
  const { reportData, loading, segment, networkStatus, segmentStatus, handleSegmentChange } =
    useMarketplaceReportTableData({ marketplace });

  return (
    <RenderDataWithFallback
      hasNoData={!reportData}
      noDataNode={<PostReportEmpty />}
      loading={loading && networkStatus === 1}
    >
      {reportData ? (
        <>
          <CampaignChart data={reportData.chartData} />
          <TableSegment
            type="switch"
            onChange={handleSegmentChange}
            options={[
              {
                key: PostReportSegmentKeys.total,
                label: t('Total'),
                value: segmentStatus.total,
                loading: segment.total?.loading
              },
              {
                key: PostReportSegmentKeys.post,
                label: t('General.Post'),
                value: segmentStatus.post,
                loading: segment.post?.loading
              }
            ]}
          />
          <PostReportTable loading={loading} data={reportData} segment={segment} pagination={{ totalRecords }} />
        </>
      ) : null}
    </RenderDataWithFallback>
  );
};
