import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MarketplaceDetail } from '@/graphql';
import { formatIntNumber } from '@/shared/utils';
import { useAllTalentAgencyOrPartnerOptions } from '@/shared/hooks';
import { SelectorFieldV2, FILTER_SECTIONS, MoreFilterPopover, RangeSliderInputField } from '@/shared/molecules';
import { useMarketplaceInfluencersFilterOptions } from './hooks';
import { defaultMarketplaceInfluencersFilterValues } from './utils';
import { MarketplaceInfluencersFilterFormKeys, MarketplaceInfluencersFilterFormValues } from './schemaTypes';

interface AdvancedFilterProps {
  count: number;
  onSubmit?: () => void;
  marketplace: MarketplaceDetail;
}

export const AdvancedFilter = ({ count, onSubmit, marketplace }: AdvancedFilterProps) => {
  const { t } = useTranslation();
  const { allTalentAgencyPartnerOptions } = useAllTalentAgencyOrPartnerOptions();
  const { watch, reset } = useFormContext<MarketplaceInfluencersFilterFormValues>();
  const { ageConfigs, genderOptions, influencerCategoryOptions, followerConfigs, engagementRateConfigs } =
    useMarketplaceInfluencersFilterOptions({ marketplace });

  const values = watch();

  const handleClear = () => {
    reset({
      ...defaultMarketplaceInfluencersFilterValues,
      // Basic filter
      keyword: values.keyword,
      participateStatuses: values.participateStatuses
    });
  };

  return (
    <MoreFilterPopover
      count={count}
      onSubmit={onSubmit}
      onClear={handleClear}
      items={[
        {
          value: FILTER_SECTIONS.InfluencerAttributes,
          title: FILTER_SECTIONS.InfluencerAttributes,
          node: (
            <>
              <SelectorFieldV2<MarketplaceInfluencersFilterFormKeys>
                name="genders"
                options={genderOptions}
                title={t('Selector.Gender')}
                multiple
              />
              <SelectorFieldV2<MarketplaceInfluencersFilterFormKeys>
                name="categoryIds"
                title={t('Selector.Categories')}
                options={influencerCategoryOptions}
                multiple
              />
              <SelectorFieldV2<MarketplaceInfluencersFilterFormKeys>
                name="talentAgencyOrPartnerIds"
                options={allTalentAgencyPartnerOptions}
                title={t('Selector.Partner & Talent Agency')}
                multiple
              />
            </>
          )
        },
        {
          value: FILTER_SECTIONS.PerformanceFilter,
          title: FILTER_SECTIONS.PerformanceFilter,
          node: (
            <>
              <RangeSliderInputField<MarketplaceInfluencersFilterFormKeys>
                step={ageConfigs.step}
                range={ageConfigs.range}
                title={t('General.Age')}
                options={ageConfigs.options}
                minField={{ name: 'minAge', placeholder: `${ageConfigs.range.min}` }}
                maxField={{ name: 'maxAge', placeholder: `${ageConfigs.range.max}` }}
              />
              <RangeSliderInputField<MarketplaceInfluencersFilterFormKeys>
                step={followerConfigs.step}
                range={followerConfigs.range}
                options={followerConfigs.options}
                title={t('General.Number of Followers')}
                minField={{ name: 'minFollowers', placeholder: formatIntNumber(followerConfigs.range.min) }}
                maxField={{ name: 'maxFollowers', placeholder: `${formatIntNumber(followerConfigs.range.max)}+` }}
                formatLabel={(value) => `${formatIntNumber(value)}${value === followerConfigs.range.max ? '+' : ''}`}
              />
              <RangeSliderInputField<MarketplaceInfluencersFilterFormKeys>
                step={engagementRateConfigs.step}
                range={engagementRateConfigs.range}
                title={t('General.Engagement Rate')}
                options={engagementRateConfigs.options}
                minField={{ name: 'minEngagementRate', placeholder: `${engagementRateConfigs.range.min}%` }}
                maxField={{ name: 'maxEngagementRate', placeholder: `${engagementRateConfigs.range.max}%` }}
              />
            </>
          )
        }
      ]}
    />
  );
};
