import { InfluencerWhitelist } from '@/graphql';
import { FilterWrapper, ListWithPagination } from '@/shared/molecules';
import {
  MarketplaceInfluencersSort,
  MarketplaceInfluencersList,
  MarketplaceInfluencersFilter
} from '@/shared/organisms';
import { useMarketplaceInfluencersList } from '../hooks';
import { useMarketplaceDetailsContext } from '../../MarketplaceDetailsContext';
import { useMarketplaceInfluencersSelectButtonsProps } from './hooks';
import { ConfirmInfluencersParticipateCampaignModal } from './ConfirmInfluencersParticipateCampaignModal';
import { ConfirmMarkInfluencersAsAvailableModal } from './ConfirmMarkInfluencersAsAvailableModal';

export interface MarketplaceInfluencersForAdminStaffProps {
  influencerWhitelist: InfluencerWhitelist;
}

// This is an advanced Select Influencers tab, replace for current Select influencers tab if `forceInfluencersParticipateCampaign: true`
// Condition for `forceInfluencersParticipateCampaign` can be changed sometimes, please find that variables to see the condition
export const MarketplaceInfluencersForAdminStaff = ({
  influencerWhitelist
}: MarketplaceInfluencersForAdminStaffProps) => {
  const { marketplace } = useMarketplaceDetailsContext();
  const { fetchingInfluencersList, listInfluencers, totalRecords } = useMarketplaceInfluencersList();
  const {
    markAsJoinedModal,
    markAsAvailableModal,
    sortSelectButtonProps,
    generateListSelectButtonProps,
    selectedJoinableInfluencerIds,
    selectedUnavailableInfluencerIds,
    resetSelectedJoinableInfluencerIds,
    resetSelectedUnavailableInfluencerIds
  } = useMarketplaceInfluencersSelectButtonsProps({ totalRecords, listInfluencers, influencerWhitelist });

  return (
    <>
      <ListWithPagination pagination={{ totalRecords }}>
        <FilterWrapper>
          <MarketplaceInfluencersFilter marketplace={marketplace} />
          <MarketplaceInfluencersSort selectButtonProps={sortSelectButtonProps} />
        </FilterWrapper>

        <MarketplaceInfluencersList
          data={listInfluencers}
          totalRecords={totalRecords}
          loading={fetchingInfluencersList}
          generateSelectButtonProps={generateListSelectButtonProps}
        />
      </ListWithPagination>

      <ConfirmInfluencersParticipateCampaignModal
        open={markAsJoinedModal.status}
        onClose={markAsJoinedModal.close}
        selectedInfluencerIds={selectedJoinableInfluencerIds}
        resetSelectedInfluencerIds={resetSelectedJoinableInfluencerIds}
      />

      <ConfirmMarkInfluencersAsAvailableModal
        open={markAsAvailableModal.status}
        onClose={markAsAvailableModal.close}
        selectedInfluencerIds={selectedUnavailableInfluencerIds}
        resetSelectedInfluencerIds={resetSelectedUnavailableInfluencerIds}
      />
    </>
  );
};
