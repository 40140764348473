import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { Genders, InfluencerListSortField, MarketplaceCampaignAppliedStatus } from '@/graphql';
import { LIMIT_50 } from '@/shared/constants';

export const MarketplaceApplicantsFilterSchema = z.object({
  keyword: FilterValidations.string(),
  categoryIds: FilterValidations.arrayString(),
  genders: FilterValidations.arrayEnum(Genders),
  appliedStatus: FilterValidations.enum(MarketplaceCampaignAppliedStatus),
  minAge: FilterValidations.number(),
  maxAge: FilterValidations.number(),
  minFollowers: FilterValidations.number(),
  maxFollowers: FilterValidations.number(),
  minEngagementRate: FilterValidations.number(),
  maxEngagementRate: FilterValidations.number()
});
export const MarketplaceApplicantsSearchSchema = z.object({
  filter: MarketplaceApplicantsFilterSchema.default({}),
  sort: SearchFilterDefaults.sort(InfluencerListSortField).default({}),
  ...SearchFilterDefaults.pageLimit(LIMIT_50)
});

export type MarketplaceApplicantsSearchSchemaType = z.infer<typeof MarketplaceApplicantsSearchSchema>;
export type MarketplaceApplicantsFilterFormValues = z.infer<typeof MarketplaceApplicantsFilterSchema>;
export type MarketplaceApplicantsFilterFormKeys = UnionOfKeys<MarketplaceApplicantsFilterFormValues>;
