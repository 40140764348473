import { useTranslation } from 'react-i18next';
import { useToggleState } from '@/shared/hooks';
import {
  AnalyticsComparableAccounts,
  AnalyticsComparableAccountsProps,
  AddAnalyticsComparableAccountModal,
  DeleteAnalyticsComparableAccountModal
} from '../../shared';
import { AnalyticsInstagramComparableAccountType } from '../types';
import { useCreateDeleteInstagramComparableAccount } from './hooks';

export type AnalyticsCompareInstagramAccountsProps = Pick<
  AnalyticsComparableAccountsProps<AnalyticsInstagramComparableAccountType>,
  'accounts' | 'maxAccounts' | 'mainAccountId' | 'hiddenAccountIds' | 'onSelectAccount'
> & {
  hasFreePlan?: boolean;
};

export const AnalyticsCompareInstagramAccounts = ({
  mainAccountId,
  ...restProps
}: AnalyticsCompareInstagramAccountsProps) => {
  const { t } = useTranslation();
  const addModal = useToggleState();
  const {
    adding,
    deleting,
    deletingAccount,
    setDeletingAccount,
    resetDeletingAccount,
    handleAddNewComparableAccount,
    handleDeleteComparableAccount
  } = useCreateDeleteInstagramComparableAccount({ mainAccountId });

  return (
    <>
      <AnalyticsComparableAccounts
        {...restProps}
        mainAccountId={mainAccountId}
        onAddNewAccount={addModal.open}
        onDeleteAccount={setDeletingAccount}
      />

      <AddAnalyticsComparableAccountModal
        loading={adding}
        open={addModal.status}
        onClose={addModal.close}
        onSubmit={(accountName) => handleAddNewComparableAccount(accountName, addModal.close)}
        customModal={{
          title: t('Dialog.Add Account To Compare'),
          description: t('Dialog.Please enter Instagram username that you wish to compare to'),
          input: { title: t('TextForm.Instagram Account'), placeholder: t<string>('Instagram Username') }
        }}
      />

      <DeleteAnalyticsComparableAccountModal
        loading={deleting}
        onClose={resetDeletingAccount}
        deletingAccount={deletingAccount}
        onSubmit={handleDeleteComparableAccount}
      />
    </>
  );
};
