import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';
import { DisabledProps } from '../types';
import { MenuItemWithCheckBox, MenuItemWithCheckBoxProps } from './MenuItemWithCheckBox';
import { MENU_ITEMS_HEIGHT } from './utils';

export interface MenuItemsWithCheckboxProps<T extends Option = Option> extends DisabledProps {
  options: readonly T[];
  initialValues?: string[];
  allowedOptions?: string[];
  noOptionPlaceholder?: ReactNode;
  type?: MenuItemWithCheckBoxProps['type'];
  handleClick: (option: T) => void;
  checkIsSelected: MenuItemWithCheckBoxProps<T>['checkIsSelected'];
  handleClickSubmenuGroup?: MenuItemWithCheckBoxProps['handleClickSubmenuGroup'];
  isVirtualized?: boolean;
  itemSize?: number;
  menuItemChildren?: ({ option }: { option: T }) => ReactNode;
  menuItemsHeight?: number;
}

export const MenuItemsWithCheckbox = <T extends Option = Option>(props: MenuItemsWithCheckboxProps<T>) => {
  const {
    type,
    options,
    disabled,
    handleClick,
    itemSize = 32,
    isVirtualized,
    allowedOptions,
    checkIsSelected,
    menuItemChildren,
    initialValues = [],
    noOptionPlaceholder,
    handleClickSubmenuGroup,
    menuItemsHeight = MENU_ITEMS_HEIGHT
  } = props;
  const { t } = useTranslation();

  return isVirtualized ? (
    <FixedSizeList width="auto" height={menuItemsHeight} itemCount={options.length} itemSize={itemSize}>
      {({ index, style }) => {
        const option = options.at(index) as T;
        const isSelected = !!checkIsSelected?.(option);
        const isDisabled =
          disabled ||
          (option.value && initialValues.includes(option.value)) ||
          (allowedOptions && !(option.value && allowedOptions.includes(option.value)));

        return (
          <div style={style} key={index}>
            <MenuItemWithCheckBox<T>
              key={option.value}
              value={option.value}
              isSelected={isSelected}
              label={option.label}
              handleClick={(submenuOption) => {
                handleClick(submenuOption || option);
              }}
              handleClickSubmenuGroup={handleClickSubmenuGroup}
              disabled={isDisabled}
              type={type}
              submenu={option?.submenu as T[]}
              checkIsSelected={checkIsSelected}
            >
              {menuItemChildren?.({ option })}
            </MenuItemWithCheckBox>
          </div>
        );
      }}
    </FixedSizeList>
  ) : (
    <div>
      {options.length ? (
        options.map((option) => {
          const isSelected = !!checkIsSelected?.(option);
          const isDisabled =
            disabled ||
            (!!option.value && initialValues.includes(option.value)) ||
            (!!allowedOptions && !!option.value && !allowedOptions.includes(option.value));

          return (
            <MenuItemWithCheckBox
              key={option.value}
              value={option.value}
              isSelected={isSelected}
              label={option.label || ''}
              handleClick={(submenuOption) => {
                handleClick(submenuOption || option);
              }}
              handleClickSubmenuGroup={handleClickSubmenuGroup}
              disabled={isDisabled}
              type={type}
              submenu={option?.submenu as T[]}
              checkIsSelected={checkIsSelected}
            >
              {menuItemChildren?.({ option })}
            </MenuItemWithCheckBox>
          );
        })
      ) : (
        <div css={{ padding: '16px', textAlign: 'center', color: THEME.text.colors.gray.lv2 }}>
          {noOptionPlaceholder ?? t('No options')}
        </div>
      )}
    </div>
  );
};
