import { useTranslation } from 'react-i18next';
import { InstagramComparePrimaryAccount } from '@/graphql';
import {
  getCompareAccountColorOpacity,
  supportedCompareAccountColors,
  AnalyticsCompareOverviewChartProps
} from '../../../shared';
import { AnalyticsInstagramComparableAccountType } from '../../types';
import { AnalyticsInstagramCompareGraphKeys as Keys } from '../types';

interface Props {
  selectedLegendKeys: Keys[];
  visibleAccountIds: number[];
  data: InstagramComparePrimaryAccount[];
  comparableAccounts: AnalyticsInstagramComparableAccountType[];
}

interface ChartValuesType {
  categories: string[];
  postsCount: number[];
  likesCount: number[];
  commentsCount: number[];
  followersCount: number[];
  engagementCount: number[];
}
const defaultChartValues: ChartValuesType = {
  categories: [],
  postsCount: [],
  likesCount: [],
  commentsCount: [],
  followersCount: [],
  engagementCount: []
};

export const useAnalyticsInstagramCompareOverviewData = ({
  data,
  visibleAccountIds,
  selectedLegendKeys,
  comparableAccounts
}: Props) => {
  const { t } = useTranslation();

  let allCategories: string[] = [];
  const comparableAccountsData = data.filter((account) => visibleAccountIds.includes(account.id));
  const chartData = comparableAccountsData.reduce<AnalyticsCompareOverviewChartProps<Keys>['data']>(
    (acc, { id, graph: { followers, posts, likes, comments, engagement } }) => {
      const { categories, postsCount, likesCount, commentsCount, followersCount, engagementCount } = (
        followers || []
      ).reduce<ChartValuesType>((acc, curr) => {
        const date = curr.date || '';

        return {
          categories: [...acc.categories, date],
          followersCount: [...acc.followersCount, Number(curr.count)],
          likesCount: [...acc.likesCount, likes.find((item) => item.date === date)?.count || 0],
          postsCount: [...acc.postsCount, posts.find((item) => item.date === date)?.count || 0],
          commentsCount: [...acc.commentsCount, comments.find((item) => item.date === date)?.count || 0],
          engagementCount: [...acc.engagementCount, engagement.find((item) => item.date === date)?.count || 0]
        };
      }, defaultChartValues);

      if (!allCategories.length) {
        allCategories = [...categories];
      }

      const color = supportedCompareAccountColors.at(visibleAccountIds.indexOf(id)) || '';
      const colorOpacity = getCompareAccountColorOpacity(color);
      const comparableAccount = comparableAccounts.find((account) => account.id === id);

      acc.push({
        account: { name: comparableAccount?.username, avatar: comparableAccount?.avatarUrl },
        data: {
          followers: {
            color,
            zIndex: 1,
            type: 'line',
            showIncrement: true,
            data: followersCount,
            name: t<string>('Follower'),
            visible: selectedLegendKeys.includes('followers'),
            marker: {
              lineWidth: 2,
              symbol: 'circle',
              fillColor: '#fff',
              lineColor: undefined // inherit from chart
            }
          },
          posts: {
            yAxis: 1,
            type: 'column',
            data: postsCount,
            color: colorOpacity,
            name: t<string>('Post'),
            visible: selectedLegendKeys.includes('posts')
          },
          likes: {
            yAxis: 2,
            type: 'column',
            data: likesCount,
            color: colorOpacity,
            name: t<string>('Like'),
            visible: selectedLegendKeys.includes('likes')
          },
          comments: {
            yAxis: 3,
            type: 'column',
            data: commentsCount,
            color: colorOpacity,
            name: t<string>('Comment'),
            visible: selectedLegendKeys.includes('comments')
          },
          engagement: {
            yAxis: 4,
            type: 'column',
            color: colorOpacity,
            data: engagementCount,
            name: t<string>('Engagement'),
            visible: selectedLegendKeys.includes('engagement')
          }
        }
      });

      return acc;
    },
    []
  );

  return { chartData, allCategories };
};
