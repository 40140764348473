import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/EditSocialAccountAnalyticsAdvertiserV2.graphql';
import { Mutation, MutationeditSocialAccountAnalyticsAdvertiserV2Args } from '../../__generated__/globalTypes';

export const useEditSocialAccountAnalyticsAdvertiserV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationeditSocialAccountAnalyticsAdvertiserV2Args>
) => {
  const [callEditSocialAccountAnalyticsAdvertiserV2, result] = useMutation<
    Mutation,
    MutationeditSocialAccountAnalyticsAdvertiserV2Args
  >(MUTATION, options);

  return { callEditSocialAccountAnalyticsAdvertiserV2, ...result };
};

export type EditSocialAccountAnalyticsAdvertiserV2MutationFunction = Mutation['editSocialAccountAnalyticsAdvertiserV2'];
