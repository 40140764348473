import { ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { PackageProposedInfluencer, RowPackageData, RowPackageInfluencerData } from './PackageContentList/types';

export const getPackageProposedInfluencersRowsData = (
  influencers: PackageProposedInfluencer[],
  jdMode?: ProposalJobDescriptionBreakDownMode
) =>
  influencers.reduce<RowPackageData[]>((results, { accountList, ...influencer }) => {
    const currentIndex = results.length;
    const hasSocialAccount = jdMode === ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN && !!accountList;
    const influencerData: RowPackageInfluencerData = {
      id: influencer.id,
      name: influencer.name,
      status: influencer.status,
      avatar: influencer.avatar
    };
    results.push({
      index: currentIndex,
      influencer: influencerData,
      stats: {
        budget: influencer.budget || 0,
        profit: influencer.profit || 0,
        engagementRateByViews: influencer.engagementViewRate,
        engagementRateByFollowers: influencer.engagementFollowerRate,
        followersCount: influencer.followersCount,
        influencerCost: influencer.influencerCost || 0,
        addedDate: influencer.addedDate,
        engagementPosted: influencer.engagementPostedCount,
        engagementProposed: influencer.engagementProposedCount
      }
    });

    if (hasSocialAccount) {
      accountList.forEach(({ id: accId, name: accName, socialMedia: accSm, summary, addedDate }, index) => {
        results.push({
          index: currentIndex + index + 1,
          influencer: influencerData,
          socialAccount: {
            id: accId,
            name: accName,
            socialMedia: accSm,
            lastIndex: index === accountList.length - 1
          },
          stats: {
            budget: summary.budget || 0,
            profit: summary.profit || 0,
            engagementRateByViews: summary.engagementViewRate,
            engagementRateByFollowers: summary.engagementFollowerRate,
            followersCount: summary.followersCount,
            influencerCost: summary.influencerCost || 0,
            addedDate,
            engagementPosted: summary.engagementPostedCount,
            engagementProposed: summary.engagementProposedCount
          }
        });
      });
    }

    return results;
  }, []);
