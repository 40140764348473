import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreatePackageWithSocialAccounts.graphql';
import { Mutation, MutationcreatePackageWithSocialAccountsArgs } from '../../__generated__/globalTypes';

export const useCreatePackageWithSocialAccountsMutation = (
  options?: MutationHookOptions<Mutation, MutationcreatePackageWithSocialAccountsArgs>
) => {
  const [callCreatePackageWithSocialAccounts, result] = useMutation<
    Mutation,
    MutationcreatePackageWithSocialAccountsArgs
  >(MUTATION, options);

  return { callCreatePackageWithSocialAccounts, ...result };
};

export type CreatePackageWithSocialAccountsMutationFunction = Mutation['createPackageWithSocialAccounts'];
