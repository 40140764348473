import { usePermissions } from './usePermissions';

const defaultPermissions = {
  hideAdvertiserTab: false,
  hideAddAdvertiserBtn: false,
  hideEditAdvertiserBtn: false,
  hideDeleteAdvertiserBtn: false
};
export const useAdvertiserPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.advertiser?.noView || permissions?.advertiser === null) {
    return {
      hideAdvertiserTab: true,
      hideAddAdvertiserBtn: true,
      hideEditAdvertiserBtn: true,
      hideDeleteAdvertiserBtn: true
    };
  } else if (permissions?.advertiser?.view) {
    return {
      hideAdvertiserTab: false,
      hideAddAdvertiserBtn: true,
      hideEditAdvertiserBtn: true,
      hideDeleteAdvertiserBtn: true
    };
  } else if (permissions?.advertiser?.manage) {
    return { ...defaultPermissions, hideDeleteAdvertiserBtn: true };
  }

  return defaultPermissions;
};
