import { useTranslation } from 'react-i18next';
import { SocialAccountType, InfluencerNewProfileHeaderV2, useInfluencerProfilePostsQuery } from '@/graphql';
import { pictureImage, likePinkImage, chatGreenImage, networkBlueImage } from '@/shared/assets';
import { ChartsContainer } from '@/shared/atoms';
import { PostingHabits, AverageEngagement } from '@/shared/molecules';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerAnalyticsPostsOverview } from '../shared';

interface InfluencerAnalyticsFacebookProfilePostsOverviewProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: InfluencerNewProfileHeaderV2['id'];
}

export const InfluencerAnalyticsFacebookProfilePostsOverview = ({
  influencerId,
  socialAccount
}: InfluencerAnalyticsFacebookProfilePostsOverviewProps) => {
  const { t } = useTranslation();
  const { data } = useInfluencerProfilePostsQuery({
    variables: {
      pk: influencerId,
      socialAccountId: socialAccount.id,
      socialMedia: socialAccount.type as SocialAccountType
    }
  });

  return (
    <InfluencerAnalyticsPostsOverview data={data?.influencerProfilePosts}>
      {({ data: { postsHabit, averageShares, averageComments, averageReactions, averagePostsPerWeek } }) => (
        <ChartsContainer css={{ height: '324px' }}>
          <AverageEngagement
            data={[
              { icon: pictureImage, value: averagePostsPerWeek, label: t('Avg posts/week') },
              { icon: likePinkImage, value: averageReactions, label: t('Avg Reactions/latest 30 posts') },
              { icon: chatGreenImage, value: averageComments, label: t('Avg Comments/latest 30 posts') },
              { icon: networkBlueImage, value: averageShares, label: t('Avg Shares/latest 30 posts') }
            ]}
          />

          {postsHabit ? <PostingHabits data={postsHabit} /> : null}
        </ChartsContainer>
      )}
    </InfluencerAnalyticsPostsOverview>
  );
};
