import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface ChatDemoHistoryProps {
  className?: string;
}

export const ChatDemoHistory = ({ className }: ChatDemoHistoryProps) => {
  const mockData = [
    { time: 'Today', messages: ['帽子のキャンペーン作成'] },
    { time: 'Yesterday', messages: ['コスメのキャンペーン作成', '韓国インフルエンサー検索'] }
  ];

  return (
    <div className={className}>
      {mockData.map((history, index) => (
        <div css={{ marginBottom: '16px' }} key={`history-${index}`}>
          <div css={styles.timer}>{history.time}</div>

          {history.messages.map((message, idx) => (
            <div key={`message-${index}-${idx}`} css={styles.message}>
              {message}

              <Icon icon="chat-demo-trash" size={14} color={THEME.icon.colors.gray.lv2} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const styles = {
  timer: css({ fontSize: '12px', lineHeight: '140%', color: THEME.text.colors.gray.lv3 }),
  message: css({
    height: '32px',
    display: 'flex',
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '100%',
    padding: '4px 8px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  })
};
