import { useQueryHelper } from '@/shared/hooks';
import { Tabs, TabsProps } from '@/shared/atoms';
import { useUserRoles } from '@/auth';

export const YoutubeCMSTabs = () => {
  const { t, search } = useQueryHelper();
  const { isAdminStaff } = useUserRoles();

  const options: TabsProps['options'] = [
    { end: true, label: t('Estimated Revenue'), to: '/youtube-cms', search },
    {
      end: true,
      label: t('Confirmed Revenue'),
      to: '/youtube-cms/confirmed-revenue',
      search
    }
  ];

  return isAdminStaff ? <Tabs css={{ padding: '0 16px' }} options={options} /> : null;
};
