import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddressAndPaymentInfoPermissions } from '@/auth';
import { usePaymentInformationQuery } from '@/graphql';
import { FormStyle, Label } from '@/shared/atoms';
import {
  useAllBankOptions,
  useDeepCompareEffect,
  useAllBankBrandOptions,
  useAllAvailableCountryOptions
} from '@/shared/hooks';
import { SelectorField, TextField } from '@/shared/molecules';
import { defaultInfluencerFormValues } from '../../InfluencerForm';
import { PaymentInformationFormValues } from './types';

interface PaymentInformationProps {
  className?: string;
  hasBorder?: boolean;
  influencerId?: number;
}

export const PaymentInformation = ({ className, hasBorder, influencerId }: PaymentInformationProps) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    formState: { defaultValues }
  } = useFormContext<PaymentInformationFormValues>();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const { disablePaymentEditing } = useAddressAndPaymentInfoPermissions();
  const { data } = usePaymentInformationQuery({
    skip: !influencerId,
    variables: { influencerId: Number(influencerId) },
    onCompleted: ({ paymentInformation }) => {
      if (paymentInformation?.info) {
        setValue('paymentInformation', paymentInformation.info);
      }
    }
  });

  useDeepCompareEffect(() => {
    // When parent form reset to update new values, we need to sync current value into parent form as well
    setValue('paymentInformation', data?.paymentInformation?.info || defaultInfluencerFormValues.paymentInformation);
  }, [defaultValues]);

  const { countryId, isMenaCountry, paymentInformation } = watch();
  const isEditable = disablePaymentEditing ? false : data?.paymentInformation?.isEditable || false;
  const isAvailableCountry = allAvailableCountryOptions.find((c) => c.value === countryId);
  const { allBankNameOptions, allBanks } = useAllBankOptions({
    variables: { countryId },
    skip: !isAvailableCountry || !countryId
  });
  const { allBankBranchNameOptions } = useAllBankBrandOptions({
    variables: { bankId: allBanks?.find((bank) => bank?.name === paymentInformation?.bankName)?.id || 0 },
    skip: !isAvailableCountry || isMenaCountry || !allBankNameOptions.length || !paymentInformation?.bankName
  });

  return (
    <FormStyle.Wrapper css={{ padding: '24px' }} className={className} hasBorder={hasBorder}>
      <Label title={t('Payment Information')} css={{ fontWeight: '600' }} />

      {isAvailableCountry ? (
        <SelectorField
          title={t('Selector.Bank Name')}
          disabled={!isEditable}
          options={allBankNameOptions}
          name="paymentInformation.bankName"
        />
      ) : (
        <TextField
          disabled={!isEditable}
          title={t('TextForm.Bank Name')}
          name="paymentInformation.bankName"
          placeholder={t<string>('TextForm.Bank Name')}
        />
      )}

      {isAvailableCountry && !isMenaCountry && allBankBranchNameOptions.length ? (
        <SelectorField
          title={t('Selector.Branch Name')}
          disabled={!isEditable}
          options={allBankBranchNameOptions}
          name="paymentInformation.branchName"
        />
      ) : (
        <TextField
          disabled={!isEditable}
          title={t('TextForm.Branch Name')}
          name="paymentInformation.branchName"
          placeholder={t<string>('Branch Name')}
        />
      )}

      <TextField
        type="number"
        disabled={!isEditable}
        placeholder="000000000000"
        title={t('TextForm.Account Number')}
        name="paymentInformation.accountNumber"
      />

      <TextField
        disabled={!isEditable}
        name="paymentInformation.accountName"
        title={t('TextForm.Account holder name')}
        placeholder={t<string>('TextForm.Account holder name')}
      />
    </FormStyle.Wrapper>
  );
};
