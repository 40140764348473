import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeAnalyticsCompareAccountCards.graphql';
import { Query, QueryyoutubeAnalyticsCompareAccountCardsArgs } from '../../__generated__/globalTypes';

export const useYoutubeAnalyticsCompareAccountCardsQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeAnalyticsCompareAccountCardsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeAnalyticsCompareAccountCardsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeAnalyticsCompareAccountCardsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeAnalyticsCompareAccountCardsArgs>
): QueryResult<Query, QueryyoutubeAnalyticsCompareAccountCardsArgs> & {
  getYoutubeAnalyticsCompareAccountCards: LazyQueryExecFunction<Query, QueryyoutubeAnalyticsCompareAccountCardsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeAnalyticsCompareAccountCards, result] = useLazyQuery<
    Query,
    QueryyoutubeAnalyticsCompareAccountCardsArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeAnalyticsCompareAccountCards, ...result };
};

export const useYoutubeAnalyticsCompareAccountCardsPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeAnalyticsCompareAccountCards = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeAnalyticsCompareAccountCardsArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeAnalyticsCompareAccountCardsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeAnalyticsCompareAccountCards };
};
