import { usePermissions } from './usePermissions';

const defaultPermissions = {
  hideEngagementReportLink: false,
  hideAddEngagementBtn: false,
  hideEditEngagementBtn: false,
  hideDeleteEngagementBtn: false,
  hideMakePublicBtn: false,
  hideApproveCampaignBtn: false,
  hideToggleInfluencerFromProposalBtn: false,
  hideSaveBtnOfJobDescription: false,
  hideAllowDenyForModal: false,
  hideAddPostBtn: false,
  hideEditPostBtn: false,
  hideDeletePostBtn: false,
  hideApproveRejectInappropriatePostBtn: false,
  hideAddPackageToProposal: false,
  hideTogglePackageFromProposalBtn: false
};
export const useEngagementPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.engagement?.noView || permissions?.engagement === null) {
    return {
      hideEngagementReportLink: true,
      hideAddEngagementBtn: true,
      hideEditEngagementBtn: true,
      hideDeleteEngagementBtn: true,
      hideMakePublicBtn: true,
      hideApproveCampaignBtn: true,
      hideToggleInfluencerFromProposalBtn: true,
      hideSaveBtnOfJobDescription: true,
      hideAllowDenyForModal: true,
      hideAddPostBtn: true,
      hideEditPostBtn: true,
      hideDeletePostBtn: true,
      hideApproveRejectInappropriatePostBtn: true,
      hideAddPackageToProposal: true,
      hideTogglePackageFromProposalBtn: true
    };
  } else if (permissions?.engagement?.view) {
    return {
      hideEngagementReportLink: false,
      hideAddEngagementBtn: true,
      hideEditEngagementBtn: true,
      hideDeleteEngagementBtn: true,
      hideMakePublicBtn: true,
      hideApproveCampaignBtn: true,
      hideToggleInfluencerFromProposalBtn: true,
      hideSaveBtnOfJobDescription: true,
      hideAllowDenyForModal: true,
      hideAddPostBtn: true,
      hideEditPostBtn: true,
      hideDeletePostBtn: true,
      hideApproveRejectInappropriatePostBtn: true,
      hideAddPackageToProposal: true,
      hideTogglePackageFromProposalBtn: true
    };
  } else if (permissions?.engagement?.manage) {
    return { ...defaultPermissions, hideDeleteEngagementBtn: true };
  }

  return defaultPermissions;
};
