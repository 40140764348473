import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Navigate, useMatchRoute, useParams } from '@tanstack/react-router';
import { useQueryHelper } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { PERMISSION_DENIED, THEME } from '@/shared/constants';
import { ListEmpty, RenderDataWithFallback, Button } from '@/shared/atoms';
import { useAllEngagementPostsForInsightCheckQuery, EngagementCampaignPostStatusForInsightCheck } from '@/graphql';
import { PostsList } from './PostsList';
import { PostDetails } from './PostDetails';
import { usePostInsightFilter } from './hooks';
import { PostInsightDetailsSearchFilterSchemaType } from './schemaTypes';

export const PostsInsightDetails = () => {
  const { filter } = usePostInsightFilter();
  const { t, navigate, enqueueSnackbar, search } = useQueryHelper();
  const matchRoute = useMatchRoute();
  const params = useParams({ strict: false });
  const matchPostsInsightDetails = matchRoute({ to: '/posts-insight/$id/$postId' });

  const campaignId = Number(params?.id);
  const { data, loading } = useAllEngagementPostsForInsightCheckQuery({
    variables: getValuableVariables({
      campaignId,
      status: filter.status as EngagementCampaignPostStatusForInsightCheck
    }),
    onError: (error) => {
      if (error.message.includes(PERMISSION_DENIED)) {
        navigate({ to: '/not-found' });
      } else {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    }
  });

  const eggPostsData = data?.allEngagementPostsForInsightCheck;
  const campaignTitle = useMemo(() => eggPostsData?.campaignTitle, [eggPostsData?.campaignTitle]); // cached
  const listPosts = eggPostsData?.posts || [];
  const postId = params?.postId ? Number(params?.postId) : listPosts[0]?.id || null;
  const postIndex = listPosts.findIndex((post) => post.id === postId);
  const postDetails = postIndex !== -1 ? listPosts[postIndex] : undefined;

  const handleMoveToPost = (index: number) => {
    const post = listPosts[index - 1];

    if (post) {
      navigate({
        to: '/posts-insight/$id/$postId',
        params: { id: campaignId.toString(), postId: post.id.toString() },
        replace: true
      });
    }
  };

  return (
    <div css={styles.wrapper}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <Button
          prefixIcon={{ icon: 'caret-left', size: 11 }}
          onClick={() => navigate({ to: '/posts-insight' })}
          variant="white"
        />
        <div css={styles.campaignTitle}>{campaignTitle || t('Post Details')}</div>
      </div>

      <div css={styles.content}>
        <RenderDataWithFallback loading={loading}>
          <PostsList posts={listPosts} campaignId={campaignId} postId={postId} />
          {listPosts.length === 0 ? (
            <ListEmpty />
          ) : matchPostsInsightDetails ? (
            postDetails ? (
              <PostDetails
                post={postDetails}
                campaignId={campaignId}
                postIndex={postIndex + 1}
                totalPosts={listPosts.length}
                onMoveToPost={handleMoveToPost}
              />
            ) : (
              <ListEmpty title={t('Post was not found')} />
            )
          ) : (
            <Navigate
              to="/posts-insight/$id/$postId"
              params={{ id: campaignId.toString(), postId: postId?.toString() || '' }}
              search={search as PostInsightDetailsSearchFilterSchemaType}
              replace
            />
          )}
        </RenderDataWithFallback>
      </div>
    </div>
  );
};
const styles = {
  campaignTitle: css({ fontWeight: 600, fontSize: '18px', marginLeft: '16px' }),
  content: css({
    flex: 1,
    width: '100%',
    display: 'flex',
    marginTop: '16px',
    backgroundColor: THEME.background.colors.white
  }),
  wrapper: css({
    height: '100%',
    width: '1160px',
    display: 'flex',
    margin: '0 auto',
    padding: '16px 0 24px',
    boxSizing: 'border-box',
    flexDirection: 'column'
  })
};
