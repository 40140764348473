import { css } from '@emotion/react';
import { Button, ButtonLink, DropdownMenu, ButtonLinkProps, Popover } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { DropdownOption } from '@/shared/types';
import { getSpreadButtonIcon, SpreadButtonIcon } from './utils';

interface OptionItem
  extends Pick<DropdownOption<string>, 'label'>,
    Partial<Pick<ButtonLinkProps, 'tooltip' | 'disabled' | 'to' | 'params' | 'search'>> {
  hidden?: boolean;
  onClick?: () => void;
  icon?: keyof typeof SpreadButtonIcon;
}

export interface SpreadButtonProps {
  className?: string;
  options: OptionItem[];
  collapseFrom?: number;
  moreButtonProps?: Pick<ButtonLinkProps, 'disabled'>;
}

export const SpreadButton = ({ options, className, moreButtonProps, collapseFrom }: SpreadButtonProps) => {
  // Example: collapseFrom = 2, options.length = 3 so mean visibleOptions.length = 2 and moreOptions.length = 1
  // When moreOptions.length = 1, it means we will show more button with 1 option only. In this case, we don't need to show the more button, show that option directly.
  const visibleOptions = options.filter((o) => !o.hidden);
  const moreOptions =
    typeof collapseFrom === 'number' && visibleOptions.length - 1 > collapseFrom
      ? visibleOptions.splice(collapseFrom)
      : [];

  return (
    <div css={{ display: 'flex', gap: '4px' }} className={className}>
      {visibleOptions.length > 0
        ? visibleOptions.map(({ to, params, search, icon, disabled, onClick, tooltip }, index) =>
            to ? (
              <ButtonLink
                key={`visible-button-${index}`}
                to={to}
                params={params}
                search={search}
                variant="white"
                css={styles.button}
                disabled={disabled}
                tooltip={{ ...tooltip, disabled }}
                prefixIcon={getSpreadButtonIcon(icon)}
                className="single-button"
              />
            ) : (
              <Button
                key={`visible-button-${index}`}
                variant="white"
                onClick={onClick}
                css={styles.button}
                disabled={disabled}
                tooltip={{ ...tooltip, disabled }}
                prefixIcon={getSpreadButtonIcon(icon)}
                className="single-button"
              />
            )
          )
        : null}

      {moreOptions.length > 0 ? (
        <Popover
          target={({ setOpen }) => (
            <div css={{ pointerEvents: moreButtonProps?.disabled ? 'none' : 'initial' }}>
              <Button
                variant="white"
                onClick={() => setOpen(true)}
                prefixIcon={getSpreadButtonIcon('more')}
                css={[styles.button, { position: 'relative', margin: '0' }]}
                className="menu-button"
                {...moreButtonProps}
              />
            </div>
          )}
          position="bottom-start"
          styles={{
            dropdown: {
              minWidth: '120px'
            }
          }}
        >
          <DropdownMenu
            css={{ all: 'unset' }}
            options={moreOptions.map(({ icon, to, params, search, label, tooltip: _, ...restOptionProps }, index) => ({
              value: index.toString(),
              disabled: restOptionProps.disabled,
              label: to ? (
                <ButtonLink
                  to={to}
                  params={params}
                  search={search}
                  title={label}
                  variant="default"
                  css={styles.moreOptionItem(restOptionProps.disabled)}
                  prefixIcon={getSpreadButtonIcon(icon)}
                  {...restOptionProps}
                />
              ) : (
                <Button
                  title={label}
                  variant="default"
                  css={styles.moreOptionItem(restOptionProps.disabled)}
                  prefixIcon={getSpreadButtonIcon(icon)}
                  {...restOptionProps}
                />
              )
            }))}
          />
        </Popover>
      ) : null}
    </div>
  );
};

const styles = {
  button: css({ padding: 0, width: '24px', height: '24px', minWidth: 'unset' }),
  moreOptionItem: (disabled?: boolean) =>
    css({
      padding: 0,
      width: '100%',
      height: 'auto',
      lineHeight: 1,
      fontSize: '13px',
      fontWeight: 400,
      minWidth: 'unset',
      border: 'none !important',
      justifyContent: 'flex-start',
      opacity: disabled ? 0.5 : 1,
      color: THEME.text.colors.black.lv1,
      backgroundColor: 'transparent !important',
      '&:disabled': {
        border: 'none'
      }
    })
};
