import { useTranslation } from 'react-i18next';
import { SelectedRangeOption } from '@/shared/types';
import { SelectorField, RangeDatePickerField, TextSearchField, BasicFilterWrapper } from '@/shared/molecules';
import { TikTokAdStatus } from '@/graphql';
import { AdsReportFilterFormKeys } from './types';

interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();

  const adCampaignStatusOptions = [
    { value: TikTokAdStatus.ENABLE, label: t('Option.Active') },
    { value: TikTokAdStatus.DISABLE, label: t('Option.Inactive') }
  ];

  return (
    <BasicFilterWrapper>
      <TextSearchField<AdsReportFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.keywords')}
        css={{ flex: 1 }}
      />
      <SelectorField<AdsReportFilterFormKeys>
        name="status"
        onSubmit={onSubmit}
        options={adCampaignStatusOptions}
        placeholder={t<string>('Selector.Campaign Type')}
        css={{ maxWidth: '225px' }}
      />
      <RangeDatePickerField<AdsReportFilterFormKeys>
        onSubmit={onSubmit}
        name={{ startDate: 'startDate', endDate: 'endDate' }}
        clearable={false}
        rangeList={[
          SelectedRangeOption.TODAY,
          SelectedRangeOption.LAST_SEVEN_DAYS,
          SelectedRangeOption.TODAY_TO_MONTH_AGO,
          SelectedRangeOption.THIS_MONTH,
          SelectedRangeOption.LAST_MONTH,
          SelectedRangeOption.CUSTOM
          // SelectedRangeOption.CLEAR TODO: enable after campaignsChartForAllTime supported
        ]}
        css={{ maxWidth: '250px' }}
      />
    </BasicFilterWrapper>
  );
};
