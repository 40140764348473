import { z } from 'zod';
import { InstagramFeedPostSortField } from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const InfluencerInstagramPostsListSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(
    z.object({
      keyword: z.string().optional(),
      name: z.string().optional()
    }),
    {
      keyword: '',
      name: ''
    }
  ),
  sort: FilterValidations.sort(InstagramFeedPostSortField).default({ field: null, order: null }),
  page: z.number().default(1),
  limit: z.number().default(LIMIT)
});

export type InfluencerInstagramPostsListSearchType = z.infer<typeof InfluencerInstagramPostsListSearchSchema>;
