import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { PostDetailsType } from '@/shared/types';
import { RowProps, TableCell } from '@/shared/atoms';
import { TiktokSpecialCampaignReport } from '@/graphql';
import { useTikTokCampaignReportContext } from '@/shared/organisms';
import { formatPrice, formatIntNumber, formatPercent } from '@/shared/utils';
import { InfluencerPost } from '../InfluencerPost';
import { TikTokCampaignReportSegmentData, TikTokCampaignReportStats } from '../types';
import {
  getPostReportStats,
  POST_REPORT_STICKY_COL_DIMS,
  getInfluencerPostReportStats,
  formatTikTokCampaignReportSegmentTotal,
  formatTikTokCampaignReportSegmentInfluencerDaily
} from '../utils';
import { useTikTokCampaignReportColumns } from './useTikTokCampaignReportColumns';

const CellAdValue = ({ value, adValue }: { value?: number | null; adValue?: number | null }) => {
  const { t } = useTranslation();

  return (
    <>
      {formatIntNumber(value)}
      {!!adValue && (
        <>
          <br />
          <span css={styles.adValue}>
            <span>{t('Ad')}</span>&nbsp;
            <span>+{formatIntNumber(adValue)}</span>
          </span>
        </>
      )}
    </>
  );
};

interface Props {
  data: TiktokSpecialCampaignReport;
}

export const useTikTokCampaignReportTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { segmentStatus } = useTikTokCampaignReportContext();
  const { columns } = useTikTokCampaignReportColumns({ data });

  const { summary, average, postCount, influencerReports } = data;
  const currency = t(data.currency || '');
  const influencerPostReportStats = getInfluencerPostReportStats(influencerReports);
  const segment: TikTokCampaignReportSegmentData = {
    total: formatTikTokCampaignReportSegmentTotal(segmentStatus.total ? data?.summaryDaily : null),
    post: formatTikTokCampaignReportSegmentInfluencerDaily(segmentStatus.post ? data?.influencerReports : null)
  };

  const getStatCells = (rowStats?: TikTokCampaignReportStats) => [
    <TableCell.Number value={formatPrice(rowStats?.cost || null, currency)} unit={rowStats?.cost ? currency : ''} />,
    <TableCell.Number value={formatIntNumber(rowStats?.followers)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.engagement)} />,
    <TableCell.Number value={formatPercent(rowStats?.engagementRate, false)} unit="%" />,
    <TableCell.Number value={formatPercent(rowStats?.engagementViewsRate, false)} unit="%" />,
    <TableCell.Number value={<CellAdValue value={rowStats?.views} adValue={rowStats?.videoPlayActions} />} />,
    <TableCell.Number value={formatIntNumber(rowStats?.adImpressions)} />,
    <TableCell.Number value={formatPercent(rowStats?.adImpressionsRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.adReach)} />,
    <TableCell.Number value={formatPercent(rowStats?.adReachRate, false)} unit="%" />,
    <TableCell.Number value={<CellAdValue value={rowStats?.likes} adValue={rowStats?.adLikes} />} />,
    <TableCell.Number value={<CellAdValue value={rowStats?.comments} adValue={rowStats?.adComments} />} />,
    <TableCell.Number value={<CellAdValue value={rowStats?.shares} adValue={rowStats?.adShares} />} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerFollower || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerView || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerEngagement || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerLike || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerComment || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerShare || null, currency)} unit={currency} />
  ];

  const totalRows: RowProps[] = [
    {
      styles: styles.totalRow,
      cells: [
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: POST_REPORT_STICKY_COL_DIMS.totalMinWidth
          }}
        >
          {t('HeaderColumn.Total')}
          <span>
            {postCount}&nbsp;
            <span css={{ textTransform: 'uppercase', fontWeight: 'normal', fontSize: '10px' }}>{t('Post')}</span>
          </span>
        </div>,
        ...getStatCells(getPostReportStats(summary))
      ]
    },
    {
      styles: styles.averageRow,
      cells: [
        <div css={{ minWidth: POST_REPORT_STICKY_COL_DIMS.totalMinWidth }}>{t('Average')}</div>,
        ...getStatCells(getPostReportStats(average))
      ]
    },
    ...(segment.total?.map<RowProps>((row) => ({
      styles: styles.totalDailyRow,
      cells: [<div css={{ textAlign: 'right' }}>{row.date}</div>, ...getStatCells(row.stats)]
    })) || [])
  ];
  const otherRows = influencerPostReportStats.reduce<RowProps[]>((results, { influencerDetails, stats }) => {
    const influencerDailyData = segment.post && segment.post[influencerDetails.id];
    const influencerRows: RowProps[] = [
      [<InfluencerPost {...influencerDetails} />, ...getStatCells(stats)],
      ...(influencerDailyData?.map((dailyData) => ({
        styles: styles.dailyRow,
        cells: [<div css={{ textAlign: 'right' }}>{dailyData.date}</div>, ...getStatCells(dailyData.stats)]
      })) || [])
    ];

    return [...results, ...influencerRows];
  }, []);

  return {
    columns,
    rows: [...totalRows, ...otherRows],
    allPosts: influencerPostReportStats.reduce<PostDetailsType[]>(
      (acc, { influencerDetails: { post } }) => (post ? [...acc, { id: post.id }] : acc),
      []
    )
  };
};

const styles = {
  totalRow: css({
    height: '32px',
    pointerEvents: 'none',
    '> td': {
      fontWeight: 600,
      fontSize: '14px',
      backgroundColor: THEME.table.header.background.colors.lv2,
      '> *': { fontSize: 'inherit' }
    }
  }),
  averageRow: css({
    height: '32px',
    borderTop: 'none',
    pointerEvents: 'none',
    '> td': {
      fontWeight: 600,
      fontSize: '12px',
      backgroundColor: THEME.table.header.background.colors.lv2,
      color: THEME.text.colors.gray.lv3,
      '> *': { fontSize: 'inherit' }
    }
  }),
  adValue: css({
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '100%',
    color: THEME.text.colors.green.lv1,
    '& > span:first-of-type': {
      fontSize: '10px',
      fontWeight: 600,
      padding: '2px 4px',
      borderRadius: '10px',
      boxSizing: 'border-box',
      color: THEME.text.colors.white,
      backgroundColor: THEME.background.colors.green.lv1
    }
  }),
  totalDailyRow: css({
    height: '36px',
    pointerEvents: 'none',
    '> td': { backgroundColor: THEME.table.header.background.colors.lv2 }
  }),
  dailyRow: css({
    height: '36px',
    pointerEvents: 'none',
    '> td': { backgroundColor: THEME.background.colors.gray.lv1 }
  })
};
