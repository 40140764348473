import { THEME } from '@/shared/constants';
import { TrackingReferral } from '@/shared/organisms';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';

export const MarketplaceTrackingReferral = () => {
  const { marketplace } = useMarketplaceDetailsContext();

  return (
    <TrackingReferral
      css={{ boxShadow: THEME.shadows.boxShadow }}
      campaign={{ id: marketplace.id, title: marketplace.title, type: 'marketplace' }}
    />
  );
};
