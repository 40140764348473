import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Label } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { ToggleOption } from '@/shared/types';
import { SwitchToggleFieldProps, SwitchToggleField } from '@/shared/molecules';
import { MarketplaceFormKeys, MarketplaceFormValues } from '../types';

export const DraftPostSubmissionField = ({
  disabled,
  className,
  ...restProps
}: Omit<SwitchToggleFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<MarketplaceFormValues>();
  const isAllowMultiplePostsValue = watch(MarketplaceFormKeys.isAllowMultiplePosts);

  return (
    <div className={className} css={{ marginTop: '4px', padding: '16px', border: '1px solid #eef3f7' }}>
      <Label
        iconMargin="4px"
        title={t('Require draft post submission')}
        css={{ fontSize: '13px', fontWeight: 600, lineHeight: '100%', margin: 0 }}
      />
      <p css={{ fontSize: '12px', lineHeight: '100%', marginTop: '8px', color: THEME.text.colors.gray.lv3 }}>
        {t('requireDraftPostDescription')}
      </p>
      <SwitchToggleField
        css={{ padding: 0, marginTop: '8px' }}
        name={MarketplaceFormKeys.requireDraftPost}
        disabled={disabled || isAllowMultiplePostsValue === ToggleOption.TRUE}
        {...restProps}
      />
    </div>
  );
};
