import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { convertOrderToORDER, getOffset, getValuableVariables } from '@/shared/utils';
import { useInfluencerAllInstagramReelPostsQuery } from '@/graphql';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  InfluencerInstagramReelPostsList,
  InfluencerInstagramReelPostsListSearchType
} from '@/shared/organisms';
import { useInitialInfluencerPostsListWithName } from '../hooks';

export const InfluencerInstagramReelPosts = () => {
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName({
    titleWithName: 'appHeader.PostListWithAccountName',
    title: 'appHeader.Reel Post List'
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<InfluencerInstagramReelPostsListSearchType>();

  const { data, previousData, loading } = useInfluencerAllInstagramReelPostsQuery({
    skip: !influencerId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      socialAccountId: mainSocialAccountId,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      endDate: null,
      startDate: null,
      keyword: filter.keyword
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerAllInstagramReelPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} />

      <ProfilePostsCount count={totalRecords} />

      <InfluencerInstagramReelPostsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.influencerAllInstagramReelPosts || []}
      />
    </ListWithPagination>
  );
};
