import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagementSocialAccountProposalList.graphql';
import { Mutation, MutationupdateEngagementSocialAccountProposalListArgs } from '../../__generated__/globalTypes';

export const useUpdateEngagementSocialAccountProposalListMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateEngagementSocialAccountProposalListArgs>
) => {
  const [callUpdateEngagementSocialAccountProposalList, result] = useMutation<
    Mutation,
    MutationupdateEngagementSocialAccountProposalListArgs
  >(MUTATION, options);

  return { callUpdateEngagementSocialAccountProposalList, ...result };
};

export type UpdateEngagementSocialAccountProposalListMutationFunction =
  Mutation['updateEngagementSocialAccountProposalList'];
