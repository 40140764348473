import { z } from 'zod';
import { BaseValidations } from '@/shared/validations';

const BaseSchema = z.object({
  advertiserId: z.number()
});

export const AccountManagerPlanSchema = BaseSchema.extend({
  accountManagerPlan: BaseValidations.AccountManagerPlanSchema
});
export const AccountManagerRequiredPlanSchema = BaseSchema.extend({
  accountManagerPlan: BaseValidations.AccountManagerPlanRequireSchema
});
