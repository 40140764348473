import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/EditSocialAccountAnalyticsAdminV2.graphql';
import { Mutation, MutationeditSocialAccountAnalyticsAdminV2Args } from '../../__generated__/globalTypes';

export const useEditSocialAccountAnalyticsAdminV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationeditSocialAccountAnalyticsAdminV2Args>
) => {
  const [callEditSocialAccountAnalyticsAdminV2, result] = useMutation<
    Mutation,
    MutationeditSocialAccountAnalyticsAdminV2Args
  >(MUTATION, options);

  return { callEditSocialAccountAnalyticsAdminV2, ...result };
};

export type EditSocialAccountAnalyticsAdminV2MutationFunction = Mutation['editSocialAccountAnalyticsAdminV2'];
