import { InfluencerNewProfileHeaderV2 } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfilePostsWrapper } from '../../shared';
import { InfluencerTwitterProfileFeedPosts } from './InfluencerTwitterProfileFeedPosts';
import { InfluencerTwitterProfilePostsOverview } from './InfluencerTwitterProfilePostsOverview';

export interface InfluencerTwitterProfilePostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerTwitterProfilePosts = ({
  socialAccount,
  influencerProfile
}: InfluencerTwitterProfilePostsProps) => (
  <InfluencerProfilePostsWrapper socialAccount={socialAccount} influencerProfile={influencerProfile}>
    {({ data }) => (
      <>
        <InfluencerTwitterProfilePostsOverview
          data={data}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
        <InfluencerTwitterProfileFeedPosts
          data={data?.feedPosts || []}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
      </>
    )}
  </InfluencerProfilePostsWrapper>
);
