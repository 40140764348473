import { useMemo, useRef } from 'react';
import { GroupBase, SingleValue, SelectInstance } from 'react-select';
import { Option } from '@/shared/types';
import { CommonSelectProps } from '../types';
import { SelectComponents } from '../shared';

export type SingleSelectProps<V extends string, O extends Option<V, any>> = Omit<
  CommonSelectProps<V, O, false>,
  'multiple'
>;

export const SingleSelect = <V extends string, O extends Option<V, any>>({
  value,
  onChange,
  options = [],
  clearable = true,
  searchable = false,
  ...restProps
}: SingleSelectProps<V, O>) => {
  const ref = useRef<SelectInstance<O, false, GroupBase<O>> | null>(null);
  const customValue = useMemo(() => (value ? options.find((el) => el.value === value) : undefined), [value, options]);

  const handleChange = (selectedOption: SingleValue<O>) => {
    onChange?.((selectedOption?.value || '') as V);
  };

  return (
    <SelectComponents.BaseSelect<V, O, false>
      {...restProps}
      ref={ref}
      options={options}
      value={customValue}
      clearable={clearable}
      searchable={searchable}
      onChange={handleChange}
    />
  );
};
