import { usePermissions } from './usePermissions';

const defaultPermissions = {
  hideMarketplaceReportLink: false,
  hideAddMarketplaceBtn: false,
  hideEditMarketplaceBtn: false,
  hideDeleteMarketplaceBtn: false,
  hideApproveCampaignBtn: false,
  hideApproveRejectInappropriatePostBtn: false,
  hideToggleApplicantFromProposalBtn: false,
  hideCommissionsTab: false
};
export const useMarketplacePermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.marketplace?.noView || permissions?.marketplace === null) {
    return {
      ...defaultPermissions,
      hideMarketplaceReportLink: true,
      hideAddMarketplaceBtn: true,
      hideEditMarketplaceBtn: true,
      hideDeleteMarketplaceBtn: true,
      hideApproveCampaignBtn: true,
      hideApproveRejectInappropriatePostBtn: true,
      hideToggleApplicantFromProposalBtn: true,
      hideCommissionsTab: true
    };
  } else if (permissions?.marketplace?.view) {
    return {
      ...defaultPermissions,
      hideAddMarketplaceBtn: true,
      hideEditMarketplaceBtn: true,
      hideDeleteMarketplaceBtn: true,
      hideApproveCampaignBtn: true,
      hideApproveRejectInappropriatePostBtn: true,
      hideToggleApplicantFromProposalBtn: true,
      hideCommissionsTab: true
    };
  } else if (permissions?.marketplace?.manage) {
    return { ...defaultPermissions, hideDeleteMarketplaceBtn: true };
  }

  return defaultPermissions;
};
