import { z } from 'zod';
import { UserRoles } from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';
import { BaseValidations } from '@/shared/validations';
import { AnalyticsSocialAccountsSchema } from './SocialAccounts';

export const AnalyticsFormSchema = z
  .object({
    id: z.number().optional(),
    advertiserId: z.string().optional(),
    accountManagerIds: z.array(z.string()).optional(),
    accountName: BaseValidations.NameSchema
  })
  .merge(AnalyticsSocialAccountsSchema);

export const getAnalyticsFormSchema = (role?: UserRoles) => {
  switch (role) {
    case UserRoles.ADVERTISER:
      return AnalyticsFormSchema;
    case UserRoles.AGENCY:
      return AnalyticsFormSchema.extend({ advertiserId: BaseValidations.AdvertiserIdSchema });
    default:
      return AnalyticsFormSchema.extend({
        advertiserId: BaseValidations.AdvertiserIdSchema,
        accountManagerIds: z.array(z.string()).min(1, AllTranslationKeys.requiredAccountManagers)
      });
  }
};
