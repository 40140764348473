import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { THEME } from '@/shared/constants';
import { TikTokSpecialCampaignStatus } from '@/graphql';
import { Prompt, PromptBlockerResolver } from '@/shared/atoms';
import { TikTokCampaignFormValues } from './types';

interface LeaveWarningModalProps {
  onSubmit: () => void;
}

// Support for editing campaign only
export const LeaveWarningModal = ({ onSubmit }: LeaveWarningModalProps) => {
  const closePromptRef = useRef<PromptBlockerResolver | null>(null);
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    getValues,
    formState: { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful, defaultValues }
  } = useFormContext<TikTokCampaignFormValues>();

  const [idValue, saveOnLeaveValue] = watch(['id', 'saveOnLeave']);
  const manualSubmitSuccess = isSubmitted && isSubmitSuccessful && !saveOnLeaveValue;
  const isPromptShown = manualSubmitSuccess
    ? false // We won't show promt when user click button "Save" and "Save as draft" manually or navigate to an add route after created campaign successfully
    : defaultValues?.status === TikTokSpecialCampaignStatus.DRAFT // When is a draft campaign we will show if
      ? defaultValues.id // the campagin has been created
        ? isDirty // and it has been modified
        : false
      : false;

  // This is customized to keep Prompt stay while submmiting, then navigate when submit success or stay when submit failed
  useEffect(() => {
    if (saveOnLeaveValue && isSubmitted && !isSubmitting) {
      if (isSubmitSuccessful) {
        closePromptRef.current?.proceed?.();
      } else {
        closePromptRef.current?.reset?.();
      }

      if (!isSubmitSuccessful) {
        setValue('saveOnLeave', false); // Reset flag when save on leave failed, prompt will be closed and keep staying page
      }
    }
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, idValue, saveOnLeaveValue]);

  return (
    <Prompt
      when={isPromptShown}
      title={t('Modal.Save as draft')}
      css={{ maxWidth: THEME.modal.size.lv1 }}
      cancelButtonProps={({ proceed }) => ({ title: t<string>("Button.Don't save"), onClick: proceed })}
      submitButtonProps={(blockerResolver) => {
        closePromptRef.current = blockerResolver;

        return {
          type: 'submit',
          variant: 'blue',
          title: t<string>('Button.Save as draft'),
          loading: { title: t('Button.Submitting'), showIcon: true, status: isSubmitting },
          onClick: () => {
            if (!getValues().title) {
              setValue('title', defaultValues?.title || 'New Campaign');
            }

            onSubmit();
          }
        };
      }}
    >
      <p css={{ marginTop: '24px', fontSize: '14px', lineHeight: '140%' }}>{t('modalSaveAsDraftDescription')}</p>
    </Prompt>
  );
};
