import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/InfluencerAnalyticsTwitterRefresh.graphql';
import { Mutation, MutationinfluencerAnalyticsTwitterRefreshArgs } from '../../__generated__/globalTypes';

export const useInfluencerAnalyticsTwitterRefreshMutation = (
  options?: MutationHookOptions<Mutation, MutationinfluencerAnalyticsTwitterRefreshArgs>
) => {
  const [callInfluencerAnalyticsTwitterRefresh, result] = useMutation<
    Mutation,
    MutationinfluencerAnalyticsTwitterRefreshArgs
  >(MUTATION, options);

  return { callInfluencerAnalyticsTwitterRefresh, ...result };
};

export type InfluencerAnalyticsTwitterRefreshMutationFunction = Mutation['influencerAnalyticsTwitterRefresh'];
