import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useMarketplaceAffiliateJoinedInfluencersQuery } from '@/graphql';
import {
  MarketplaceJoinedInfluencersList,
  MarketplaceJoinedInfluencersFilter,
  useMarketplaceJoinedInfluencersFilter
} from '@/shared/organisms';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';

export const MarketplaceJoinedInfluencers = () => {
  const { marketplace, marketplaceId } = useMarketplaceDetailsContext();
  const { page, limit, filter } = useMarketplaceJoinedInfluencersFilter();
  const { data, previousData, loading } = useMarketplaceAffiliateJoinedInfluencersQuery({
    variables: getValuableVariables({
      limit,
      marketplaceId,
      keyword: filter.keyword,
      offset: getOffset(page, limit)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.marketplaceAffiliateJoinedInfluencersCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <MarketplaceJoinedInfluencersFilter marketplaceId={marketplaceId} />
      <MarketplaceJoinedInfluencersList
        loading={loading}
        marketplace={marketplace}
        data={result?.marketplaceAffiliateJoinedInfluencers || []}
      />
    </ListWithPagination>
  );
};
