import { useUserRoles } from '@/auth';
import { getRestApiUrl } from '@/shared/utils';
import { GTM_IDS, THEME } from '@/shared/constants';
import { DownloadButton, Icon } from '@/shared/atoms';
import { DownloadFileType, DropdownOption } from '@/shared/types';
import { useDownloadFile, useQueryHelper, useToggleState } from '@/shared/hooks';
import {
  CampaignType,
  useMarketplaceGoogleSlidesHistoryQuery,
  useRequestPowerpointCustomisedReportMutation,
  useRequestGoogleSlideCustomisedReportMutation
} from '@/graphql';
import { PostReportFilterFormValues, PostReportSearchSchemaType, usePostReportSearch } from '@/shared/molecules';
import { usePostReportContext } from '../PostReportContext';
import { PostReportSegmentKeys, PostReportTableStatKeys } from '../PostReportTable';
import { GoogleSlidesHistoryModal } from './GoogleSlidesHistoryModal';

enum CustomDownloadType {
  GG_SLIDE_HISTORY = 'GG_SLIDE_HISTORY'
}
type PostDownloadOption = DropdownOption<DownloadFileType | CustomDownloadType>;

export interface DownloadPostReportsButtonProps {
  className?: string;
  campaignId: number;
  filter: PostReportFilterFormValues;
  segmentState: PostReportSearchSchemaType['state'];
}

export const DownloadPostReportsButton = ({
  filter,
  className,
  campaignId,
  segmentState
}: DownloadPostReportsButtonProps) => {
  const ggSlidesModal = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { campaignType, selectedColumns } = usePostReportContext();
  const { sort } = usePostReportSearch();
  const { isAdminStaff, isAdvertiser, isAdminStaffAgencyTalentAgencyPartner, isPartnerFromUuum } = useUserRoles();
  const { callRequestPowerpointCustomisedReport, loading: requestingPptxReport } =
    useRequestPowerpointCustomisedReportMutation();
  const { callRequestGoogleSlideCustomisedReport, loading: requestingGgSlideReport } =
    useRequestGoogleSlideCustomisedReportMutation();
  const { data } = useMarketplaceGoogleSlidesHistoryQuery({
    skip: campaignType !== CampaignType.MARKETPLACE || !isAdminStaff,
    variables: { campaignId }
  });

  const ggSlidesHistory =
    data?.marketplaceGoogleSlidesHistory?.map((history) => ({ date: history.date, url: history.slideUrl })) || [];
  const hasGgSlidesHistory = isAdminStaff && campaignType === CampaignType.MARKETPLACE && !!ggSlidesHistory.length;
  const campaignText = campaignType === CampaignType.ENGAGEMENT ? 'engagement' : 'marketplace';
  const options: PostDownloadOption[] = [
    {
      label: t<string>('CSV'),
      value: DownloadFileType.CSV,
      gtmId: GTM_IDS[campaignText].report_csv_download
    },
    {
      label: t('Google Slide'),
      value: DownloadFileType.GOOGLE_SLIDE,
      gtmId: GTM_IDS[campaignText].report_google_slide_download,
      hidden: !isAdminStaff && !isPartnerFromUuum
    },
    {
      label: t('Powerpoint'),
      value: DownloadFileType.PPTX,
      gtmId: GTM_IDS[campaignText].report_pptx_download,
      hidden: !(isAdvertiser || isAdminStaffAgencyTalentAgencyPartner)
    },
    {
      value: CustomDownloadType.GG_SLIDE_HISTORY,
      hidden: !hasGgSlidesHistory,
      label: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Icon icon="cloud" size={12} css={{ marginRight: '4px' }} />
          {t('Google Slides previously exported')}
        </div>
      ),
      css: { backgroundColor: THEME.background.colors.gray.lv1, borderTop: THEME.border.base }
    }
  ].filter((option) => !option.hidden);

  const handleDownloadPostReport = async (fileType?: PostDownloadOption['value']) => {
    if (fileType === CustomDownloadType.GG_SLIDE_HISTORY) {
      if (hasGgSlidesHistory) {
        ggSlidesModal.open();
      }

      return;
    } else if (
      !fileType ||
      ![DownloadFileType.CSV, DownloadFileType.GOOGLE_SLIDE, DownloadFileType.PPTX].includes(fileType)
    ) {
      return;
    }

    try {
      const variables = { campaignId, campaignType, selectedMetrics: selectedColumns };

      switch (fileType) {
        case DownloadFileType.CSV:
          {
            const unsupportedDownloadColumns: PostReportTableStatKeys[] = [
              'malePercentage',
              'femalePercentage',
              'age1824Percentage',
              'age2534Percentage',
              'age35UpPercentage'
            ];

            await handleDownloadFile({
              filenamePrefix: 'report_post',
              url: getRestApiUrl(`report/campaign/${campaignType.valueOf().toLowerCase()}/${campaignId}/report/csv/`),
              params: {
                ...sort,
                keyword: filter.keyword,
                endDate: filter.endDate,
                startDate: filter.startDate,
                selectedColumns: selectedColumns.filter((key) => !unsupportedDownloadColumns.includes(key)),
                groups: (Object.keys(segmentState) as PostReportSegmentKeys[])
                  .reduce<PostReportSegmentKeys[]>((acc, curr) => (segmentState[curr] ? [...acc, curr] : acc), [])
                  .join()
              }
            });
          }
          break;
        case DownloadFileType.GOOGLE_SLIDE:
          await callRequestGoogleSlideCustomisedReport({ variables });
          enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
          break;
        case DownloadFileType.PPTX:
          await callRequestPowerpointCustomisedReport({ variables });
          enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
          break;
        default:
          break;
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <DownloadButton<PostDownloadOption>
        options={options}
        className={className}
        onSelectOption={({ value }) => handleDownloadPostReport(value)}
        loading={downloading || requestingPptxReport || requestingGgSlideReport}
      />

      {hasGgSlidesHistory ? (
        <GoogleSlidesHistoryModal open={ggSlidesModal.status} onClose={ggSlidesModal.close} data={ggSlidesHistory} />
      ) : null}
    </>
  );
};
