export enum SupportedSocialMediaType {
  LINE = 'LINE',
  DOUYIN = 'DOUYIN',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  THREADS = 'THREADS',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_REEL = 'INSTAGRAM_REEL',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  INSTAGRAM_BUSINESS = 'INSTAGRAM_BUSINESS',
  XHS = 'XHS'
}
