import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeCmsChannelInfo.graphql';
import { Query, QueryyoutubeCmsChannelInfoArgs } from '../../__generated__/globalTypes';

export const useYoutubeCmsChannelInfoQuery = (options?: QueryHookOptions<Query, QueryyoutubeCmsChannelInfoArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeCmsChannelInfoArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeCmsChannelInfoLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeCmsChannelInfoArgs>
): QueryResult<Query, QueryyoutubeCmsChannelInfoArgs> & {
  getYoutubeCmsChannelInfo: LazyQueryExecFunction<Query, QueryyoutubeCmsChannelInfoArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeCmsChannelInfo, result] = useLazyQuery<Query, QueryyoutubeCmsChannelInfoArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeCmsChannelInfo, ...result };
};

export const useYoutubeCmsChannelInfoPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeCmsChannelInfo = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeCmsChannelInfoArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeCmsChannelInfoArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeCmsChannelInfo };
};
