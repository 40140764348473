import { z } from 'zod';
import { Order, SocialAccountStatus, SocialAccountType, SponsoredPostSortField } from '@/graphql';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { LIMIT } from '@/shared/constants';

export const InfluencerSponsoredPostsListSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(
    z.object({
      name: z.string().default(''),
      keyword: z.string().optional(),
      status: z.nativeEnum(SocialAccountStatus).optional(),
      sm: z.nativeEnum(SocialAccountType).default(SocialAccountType.INSTAGRAM)
    }),
    {
      name: '',
      keyword: '',
      sm: SocialAccountType.INSTAGRAM
    }
  ),
  sort: FilterValidations.sort(SponsoredPostSortField).default({
    field: SponsoredPostSortField.POST_DATE,
    order: Order.DESC
  }),
  lm: z.number().default(LIMIT),
  page: z.number().default(1)
});

export type InfluencerSponsoredPostsListSearchSchemaType = z.infer<typeof InfluencerSponsoredPostsListSearchSchema>;
