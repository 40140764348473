import { useSelectItemIds } from './useSelectItemIds';

interface Props<T extends { [Key in K]: any }, K extends keyof T> {
  key: K;
  listItems: readonly T[];
  defaultSelectedIds?: T[K][];
}
/**
 * Used for selecting item in table, list to handle something then like download, edit,...
 * @param listItems must be Object. If you want to use for Array<string | number>, please use useSelectItemIds instead
 * @param key unique. Ex: 'id', 'influencerId', 'socialAccountId'
 * @returns
 */
export const useSelectItems = <T extends { [Key in K]?: any }, K extends keyof T>({
  key,
  listItems,
  defaultSelectedIds = []
}: Props<T, K>) => {
  const allItemIds = listItems.map((item) => item[key]);
  const { selectItem, selectedItemIds, setSelectedItemIds, toggleSelectAllItems, hasAllItemsSelected } =
    useSelectItemIds<T[K]>({ allItemIds, defaultSelectedIds });

  return {
    allItemIds,
    selectItem,
    selectedItemIds,
    hasAllItemsSelected,
    setSelectedItemIds,
    toggleSelectAllItems,
    selectedItems: listItems.filter((item) => selectedItemIds.includes(item[key]))
  };
};
