import React, { ReactElement } from 'react';
import { UseFormProps } from 'react-hook-form';
import { FormProvider, FormProviderProps } from '@/shared/atoms';

interface TemplateProps {
  onSubmit?: () => void;
  basicFilter: ReactElement<{ onSubmit?: () => void; toggleAdvancedFilter?: () => void }>;
}

const Template = ({ onSubmit, basicFilter }: TemplateProps) =>
  React.cloneElement(basicFilter, { ...basicFilter.props, onSubmit });

export interface FilterProviderProps<T extends NonNullable<unknown>> extends Omit<TemplateProps, 'onSubmit'> {
  initialValues: UseFormProps<T, unknown>['defaultValues'];
  onSubmit: FormProviderProps<T>['onSubmit'];
}

export const FilterProvider = <T extends NonNullable<unknown>>({
  onSubmit,
  initialValues,
  ...restProps
}: FilterProviderProps<T>) => (
  <FormProvider onSubmit={onSubmit} values={initialValues as T}>
    <Template {...restProps} />
  </FormProvider>
);
