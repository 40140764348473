import { SocialAccountType, DouyinInfluencer } from '@/graphql';
import { InfluencersTabType } from '@/shared/types';
import { DouyinInfluencerProfile, DouyinInfluencersFilterFormValues } from './schemaTypes';

export const getInitialDouyinInfluencersFormValues = (
  defaultFilter?: Partial<DouyinInfluencersFilterFormValues>
): Partial<DouyinInfluencersFilterFormValues> => ({
  countryIds: ['CN'], // fixed for douyin
  tab: InfluencersTabType.DOUYIN,
  ...defaultFilter
});

export const formatInfluencerProfile = (infl: DouyinInfluencer): DouyinInfluencerProfile => ({
  ...infl,
  socialAccountType: SocialAccountType.DOUYIN,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail }) => ({
      thumbNail,
      id: postId,
      influencerId: Number(infl.id)
    })) || []
});
