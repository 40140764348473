import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useUserRoles } from '@/auth';
import { FormStyle, Label } from '@/shared/atoms';
import {
  useToggleOption,
  useAllAvailableCountryOptions,
  useAllAgenciesForSearchOptions,
  useAllAdvertisersForSearchOptions
} from '@/shared/hooks';
import {
  TextField,
  RadiosField,
  SelectorField,
  CustomerValues,
  BigQuerySearchField,
  PrivateInfluencersSearchField,
  UploadAgencyLogo
} from '@/shared/molecules';
import { MIME_ACCEPT } from '@/shared/constants';
import { PartnerFormValues, PartnerFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const { isAdmin, isStaff } = useUserRoles();
  const { allAgencyOptions } = useAllAgenciesForSearchOptions();
  const { toggleOptions: influencerCostOptions } = useToggleOption();
  const { allAdvertiserOptions } = useAllAdvertisersForSearchOptions();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const {
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting }
  } = useFormContext<PartnerFormValues>();

  const values = watch();
  const canSelectNetSuite = isAdmin || isStaff;

  const handleNetsuiteIdChange = (_: PartnerFormKeys, customerValues: CustomerValues) => {
    reset({
      ...values,
      partnerAgencyName: customerValues.label,
      netsuiteId: customerValues.value || '',
      countryId: customerValues.countryId || '',
      companyUrl: customerValues.companyUrl || '',
      phoneNumber: customerValues.phoneNumber || ''
    });
  };

  return (
    <FormStyle.Wrapper css={{ margin: 0, flex: 1 }} hasBorder>
      <div css={{ padding: '24px' }}>
        <FormStyle.SectionTitle label={t('Partner Agency Information')} />

        {canSelectNetSuite ? (
          <BigQuerySearchField<PartnerFormKeys>
            name="netsuiteId"
            type="netsuiteVendor"
            onChange={handleNetsuiteIdChange}
            title={t('Selector.NetSuite Vendor')}
          />
        ) : null}

        <TextField<PartnerFormKeys>
          name="partnerAgencyName"
          title={t('TextForm.Partner Agency Name')}
          placeholder={t<string>('TextForm.AdAsiaLtd')}
          required
        />

        <SelectorField<PartnerFormKeys>
          title={t('Selector.Agency')}
          name="agencyIds"
          help={t('Tooltip.User Agency')}
          options={allAgencyOptions}
          multiple
        />

        <SelectorField<PartnerFormKeys>
          title={t('Selector.Advertiser')}
          name="advertiserIds"
          options={allAdvertiserOptions}
          multiple
        />

        <TextField<PartnerFormKeys>
          name="companyUrl"
          title={t('TextForm.Partner Agency URL')}
          placeholder="https://app.anytag.jp"
        />

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <TextField<PartnerFormKeys>
            name="phoneNumber"
            title={t('TextForm.Phone Number')}
            help={t('Tooltip.Phone Number')}
            placeholder="+66020485707"
          />

          <SelectorField<PartnerFormKeys>
            title={t('Selector.Country')}
            name="countryId"
            options={allAvailableCountryOptions}
            required
          />
        </FormStyle.FieldsGroup>

        <PrivateInfluencersSearchField<PartnerFormKeys>
          agencyType="partner"
          disabled={!isAdmin}
          help={t('Tooltip.Private Influencers')}
          name="privateInfluencersOptions"
          title={t('Selector.Private Influencers')}
          agencyId={Number(values.id)}
          placeholder={t<string>('Influencer name')}
        />

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <FormStyle.FieldWrapper css={styles.logoWrapper}>
            <Label title={t('Partner Logo')} help={t('Tooltip.customLogoHelp')} />
            <UploadAgencyLogo
              name="logo"
              multiple={false}
              hasError={!!errors.logo}
              value={values.logo ? [values.logo] : []}
              accept={{ ...MIME_ACCEPT.IMAGE, ...MIME_ACCEPT.GIF }}
              onChange={(_, value) => setValue('logo', value[0] || '')}
              notes={[
                t('General.DragAndDropInfo'),
                t('General.DragAndDropMaxSize', { maxSize: '10MB' }),
                t('General.DragAndDropMIME', { MIME: 'PNG, GIF, JPEG' }),
                t('General.DragAndDropImageRatio', { ratio: '16:9' })
              ]}
            />
          </FormStyle.FieldWrapper>
        </FormStyle.FieldsGroup>

        {isAdmin ? (
          <>
            <FormStyle.SectionTitle label={t('Access Permission')} css={{ marginTop: '40px' }} />

            <RadiosField<PartnerFormKeys>
              name="influencerCostPermission"
              options={influencerCostOptions}
              title={t('Influencer Cost and Profit')}
            />
          </>
        ) : null}
      </div>

      <FormStyle.ButtonSubmitWrapper>
        <FormStyle.ButtonSubmit loading={isSubmitting} title={t('Button.Save')} disabled={isSubmitting || isStaff} />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};

const styles = {
  logoWrapper: css({
    '& > div > div:first-of-type': {
      border: '1px dashed #e0e8ed',
      '> div > i': { margin: 0, border: 'none', padding: '8px 24px 24px' }
    }
  })
};
