import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { CampaignSocialMediaType, EngagementCampaignStatus } from '@/graphql';
import { Anchor, FormStyle } from '@/shared/atoms';
import { CheckBoxField, RichEditorField, SelectorField, TextField } from '@/shared/molecules';
import { DisabledFields, Option } from '@/shared/types';
import { getSocialMediaText } from '@/shared/utils';
import { EngagementCampaignFormValues, EngagementCampaignFormKeys } from '../types';

interface CastingInformationProps {
  disabledFields: DisabledFields<EngagementCampaignFormKeys>;
}
export const CastingInformation = ({ disabledFields }: CastingInformationProps) => {
  const { t, i18n } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const {
    watch,
    setValue,
    formState: { defaultValues }
  } = useFormContext<EngagementCampaignFormValues>();

  const [isTtcmCampaignValue, socialMediasValue] = watch(['isTtcmCampaign', 'socialMedias']);
  const socialMediaOptions: Option[] = [
    CampaignSocialMediaType.FACEBOOK,
    CampaignSocialMediaType.INSTAGRAM,
    CampaignSocialMediaType.INSTAGRAM_STORY,
    CampaignSocialMediaType.TWITTER,
    CampaignSocialMediaType.YOUTUBE,
    CampaignSocialMediaType.TIKTOK,
    CampaignSocialMediaType.DOUYIN,
    CampaignSocialMediaType.THREADS,
    CampaignSocialMediaType.XHS
  ].map((type) => ({ value: type, label: getSocialMediaText(type) }));

  const handleSocialMediaChange = (name: EngagementCampaignFormKeys, value: string[]) => {
    setValue(name, value, { shouldValidate: true });

    if (isAdminStaff && !value.includes(CampaignSocialMediaType.TIKTOK) && isTtcmCampaignValue) {
      setValue('isTtcmCampaign', false);
    }
  };

  return (
    <div css={{ padding: '24px 24px 12px 24px' }}>
      <FormStyle.SectionTitle index={2} label={t('Casting Information')} />
      <FormStyle.FieldsGroup itemsPerRow={3}>
        <SelectorField<EngagementCampaignFormKeys>
          name="socialMedias"
          help={t('Tooltip.Social Media')}
          title={t('Selector.Social Media')}
          options={socialMediaOptions}
          onChange={handleSocialMediaChange}
          disabled={disabledFields?.socialMedias}
          multipleSelectorProps={{ canOnlyAddMore: defaultValues?.status === EngagementCampaignStatus.UPCOMING }}
          multiple
          required
        />

        <TextField<EngagementCampaignFormKeys>
          type="number"
          placeholder="0"
          name="numberOfInfluencers"
          title={t('TextForm.Number of Influencers')}
          help={t('Tooltip.Number of influencers Help')}
          disabled={disabledFields?.numberOfInfluencers}
        />
      </FormStyle.FieldsGroup>

      {isAdminStaff && socialMediasValue.includes(CampaignSocialMediaType.TIKTOK) ? (
        <FormStyle.FieldWrapper>
          <CheckBoxField<EngagementCampaignFormKeys>
            css={{ padding: 0 }}
            name="isTtcmCampaign"
            disabled={disabledFields?.isTtcmCampaign}
            label={t('Run this campaign as a TikTok Creator Marketplace (TTCM) campaign')}
          />
          <Anchor
            variant="blue"
            target="_blank"
            rel="noopener noreferrer"
            label={t('How to run TTCM campaigns?')}
            css={{ fontSize: '13px', marginTop: '6px', display: 'inline-block' }}
            href={
              i18n.language === 'ja'
                ? 'https://anymind-group.gitbook.io/anytag-1/kyanpn/tiktok-creator-marketplacettcm-kyanpn'
                : 'https://anymind-group.gitbook.io/english-1/kyanpn/tiktok-creator-marketplace-ttcm-campaign'
            }
          />
        </FormStyle.FieldWrapper>
      ) : null}

      <RichEditorField<EngagementCampaignFormKeys>
        name="defaultPostRequirement"
        help={t('Tooltip.Post Requirements Help')}
        title={t('TextForm.Post Requirements')}
        disabled={disabledFields?.defaultPostRequirement}
        placeholder={t<string>('TextForm.Post Requirements')}
      />
    </div>
  );
};
