import { useEngagementPermissions } from '@/auth';
import { Engagement, namedOperations, useDeleteEngagementMutation } from '@/graphql';
import { Table } from '@/shared/atoms';
import { useQueryHelper, useSelectItem } from '@/shared/hooks';
import { ConfirmDeleteModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { useEngagementCampaignsTable } from './hooks';

export type EngagementCampaignsListProps = TableData<Engagement>;

export const EngagementCampaignsList = ({ loading, data = [] }: EngagementCampaignsListProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { hideAddEngagementBtn } = useEngagementPermissions();
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem();
  const { rows, columns } = useEngagementCampaignsTable({ data, onDeleteEngagement: setSelectedItem });
  const { callDeleteEngagement, loading: deleting } = useDeleteEngagementMutation({
    refetchQueries: [namedOperations.Query.AllEngagements]
  });

  const handleDeleteEngagement = async () => {
    if (!selectedItem) {
      return;
    }

    try {
      await callDeleteEngagement({ variables: { id: selectedItem.id } });
      enqueueSnackbar(t('succeededInDeleting', { name: selectedItem.name }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: selectedItem.name }), { variant: 'error' });
    } finally {
      resetSelectedItem();
    }
  };

  return (
    <>
      <Table
        loading={loading}
        data={{ columns, rows }}
        empty={{
          linkProps: !hideAddEngagementBtn ? { to: '/engagement/add', label: t('General.add a campaign') } : undefined
        }}
        hasBorder
        noTopBorderRadius
      />

      <ConfirmDeleteModal
        deleting={deleting}
        onClose={resetSelectedItem}
        deletingItem={selectedItem}
        onDelete={handleDeleteEngagement}
      />
    </>
  );
};
