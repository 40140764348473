import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/XhsProfileAudience.graphql';
import { Query, QueryxhsProfileAudienceArgs } from '../../__generated__/globalTypes';

export const useXhsProfileAudienceQuery = (options?: QueryHookOptions<Query, QueryxhsProfileAudienceArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryxhsProfileAudienceArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useXhsProfileAudienceLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryxhsProfileAudienceArgs>
): QueryResult<Query, QueryxhsProfileAudienceArgs> & {
  getXhsProfileAudience: LazyQueryExecFunction<Query, QueryxhsProfileAudienceArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getXhsProfileAudience, result] = useLazyQuery<Query, QueryxhsProfileAudienceArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getXhsProfileAudience, ...result };
};

export const useXhsProfileAudiencePromiseQuery = () => {
  const client = useApolloClient();

  const getXhsProfileAudience = useCallback(
    (options?: Omit<QueryOptions<QueryxhsProfileAudienceArgs, Query>, 'query'>) =>
      client.query<Query, QueryxhsProfileAudienceArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getXhsProfileAudience };
};
