import { ReactNode } from 'react';
import { Switch } from '@/shared/atoms';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';
import { RHFFieldWrapper } from './shared';

export interface SwitchToggleFieldProps<T extends string = string> extends DefaultFieldProps<T, boolean> {
  label?: ReactNode;
  labelHelp?: ReactNode;
}

export const SwitchToggleField = <T extends string>(props: SwitchToggleFieldProps<T>) => {
  const {
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, value, label, disabled, labelHelp, onChange }
  } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper {...fieldWrapperProps} iconMargin="10px">
      <Switch
        title={label}
        help={labelHelp}
        isChecked={value}
        disabled={disabled}
        onChange={() => (onChange ? onChange(name, !value) : setValue<string>(name, !value, setValueOptions))}
      />
    </RHFFieldWrapper>
  );
};
