import { CheckBox, CheckBoxProps } from '@/shared/atoms';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export type CheckBoxFieldProps<T extends string = string> = DefaultFieldProps<T, boolean> &
  Pick<CheckBoxProps, 'label'>;

export const CheckBoxField = <T extends string>(props: CheckBoxFieldProps<T>) => {
  const {
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, value, onChange, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <CheckBox
        {...restFieldProps}
        checked={value}
        help={!fieldWrapperProps.title ? fieldWrapperProps.help : undefined}
        onClick={(value) => (onChange ? onChange(name, value) : setValue<string>(name, value, setValueOptions))}
      />
    </RHFFieldWrapper>
  );
};
