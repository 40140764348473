import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { Modal, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';

export interface ApproveModalProps {
  open?: boolean;
  loading?: boolean;
  onClose?: () => void;
  isTtcmCampaign?: boolean;
  onUpdateStatus?: () => void;
}

export const ApproveModal = ({ open, onClose, loading, onUpdateStatus, isTtcmCampaign }: ApproveModalProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();

  return (
    <Modal open={open} onClose={onClose} css={{ maxWidth: '600px' }} hasCloseIcon>
      <ModalContent>
        <ModalTitle>{t('Approve Proposal')}</ModalTitle>
        <div css={{ paddingTop: '16px' }}>
          {isAdminStaff && isTtcmCampaign
            ? t('TTCM invitations will be sent to creators through AnyCreator')
            : t('Are you sure you want to approve?')}
        </div>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{ loading, title: t('Button.Approve'), onClick: () => onUpdateStatus?.() }}
      />
    </Modal>
  );
};
