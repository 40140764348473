import { Ref, useRef, useState, forwardRef, useCallback, ReactElement, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, {
  GroupBase,
  ActionMeta,
  InputAction,
  OnChangeValue,
  SelectInstance,
  InputActionMeta,
  Props as ReactSelectProps
} from 'react-select';
import { THEME } from '@/shared/constants';
import { Option as OptionType } from '@/shared/types';
import { CommonSelectProps } from '../types';
import { formatOptions, formatOptionLabel, getDefaultSelectStyles } from '../utils';
import { Option } from './Option';
import { useAlignSelectMenu } from './hooks';
import { ClearIndicator } from './ClearIndicator';
import { MenuList, MenuListProps } from './MenuList';
import { DropdownIndicator } from './DropdownIndicator';

export type BaseSelectProps<V extends string, O extends OptionType<V, any>, IsMulti extends boolean> = Omit<
  CommonSelectProps<V, O, IsMulti>,
  'value' | 'onChange'
> &
  Pick<
    ReactSelectProps<O, IsMulti, GroupBase<O>>,
    'value' | 'onChange' | 'components' | 'onMenuOpen' | 'onMenuClose' | 'onInputChange' | 'formatOptionLabel'
  >;

const Template = <V extends string, O extends OptionType<V, any>, IsMulti extends boolean>(
  {
    styles,
    loading,
    options,
    hasError,
    multiple,
    disabled,
    onSubmit,
    onChange,
    menuWidth,
    clearable,
    menuHeight,
    components,
    searchable,
    placeholder,
    onMenuOpen,
    onMenuClose,
    onInputChange,
    loadingMessage,
    optionLabelType,
    ...restProps
  }: BaseSelectProps<V, O, IsMulti>,
  ref: Ref<SelectInstance<O, IsMulti, GroupBase<O>>>
) => {
  const { t } = useTranslation();
  const selectRef = useRef<SelectInstance<O, IsMulti, GroupBase<O>> | null>(null);
  const { alignRight, handleMenuAlignment } = useAlignSelectMenu({ ref: selectRef });
  // Detect action to show/hide selected values when searching
  const [action, setAction] = useState<InputAction | ActionMeta<O>['action'] | null>(null);

  // Pass ref to parent component
  useImperativeHandle(ref, () => selectRef.current || ({} as SelectInstance<O, IsMulti, GroupBase<O>>));

  const handleMenuOpen = () => {
    onMenuOpen?.();
    handleMenuAlignment();
  };

  const handleCloseMenu = () => {
    onMenuClose?.();
    selectRef.current?.blur();
    onSubmit?.();
  };

  const handleChange = (selectedOptions: OnChangeValue<O, IsMulti>, actionMeta: ActionMeta<O>) => {
    if (multiple) {
      setAction(actionMeta?.action || null);
    }

    onChange?.(selectedOptions, actionMeta);
  };

  const handleInputChange = (inputValue: string, actionMeta: InputActionMeta) => {
    if (multiple) {
      setAction(inputValue ? actionMeta.action : null);
    }

    onInputChange?.(inputValue, actionMeta);
  };

  // Issue: https://github.com/JedWatson/react-select/issues/1634
  const CustomMenuList = useCallback(
    (props: MenuListProps) => <MenuList {...props} menuWidth={menuWidth} menuHeight={menuHeight} />,
    [menuWidth, menuHeight]
  );

  return (
    <ReactSelect<O, IsMulti, GroupBase<O>>
      ref={selectRef}
      isLoading={loading}
      isDisabled={disabled}
      onChange={handleChange}
      isClearable={clearable}
      isSearchable={searchable}
      onMenuOpen={handleMenuOpen}
      backspaceRemovesValue={false}
      onMenuClose={handleCloseMenu}
      isMulti={multiple as IsMulti}
      options={formatOptions(options)}
      onInputChange={handleInputChange}
      placeholder={disabled ? '' : placeholder ?? t('Selector.Please Select')}
      controlShouldRenderValue={multiple ? action !== 'input-change' : undefined} // Hide selected values when searching
      loadingMessage={loading ? loadingMessage ?? (() => t('Please wait')) : undefined}
      theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: THEME.colors.black[10] } })}
      formatOptionLabel={(optProps, meta) =>
        formatOptionLabel(optProps, meta, { multiple, disabled, menuWidth, optionLabelType })
      }
      // IMPORTANT https://react-select.com/components#defining-components
      styles={getDefaultSelectStyles({
        disabled,
        hasError,
        menuWidth,
        styles: {
          ...styles,
          menu: (_, optProps) => ({ ...(!!alignRight && { right: 0 }), ...styles?.menu?.({}, optProps) })
        }
      })}
      components={{
        Option,
        ClearIndicator,
        MenuList: CustomMenuList,
        IndicatorSeparator: () => null,
        DropdownIndicator: (props) => (!props.hasValue || !clearable ? <DropdownIndicator /> : null),
        ...components
      }}
      {...restProps}
    />
  );
};

export const BaseSelect = forwardRef(Template) as <
  V extends string,
  O extends OptionType<V, any>,
  IsMulti extends boolean
>(
  p: BaseSelectProps<V, O, IsMulti> & { ref?: Ref<SelectInstance<O, IsMulti, GroupBase<O>>> }
) => ReactElement;
