import { useQueryHelper } from '@/shared/hooks';
import { TableSegmentOption } from '@/shared/atoms';
import { convertOrderToORDER, getOffset, getValuableVariables } from '@/shared/utils';
import {
  MarketplaceDetail,
  useMarketplaceCampaignReportQuery,
  useMarketplaceCampaignTotalDailyQuery,
  useMarketplaceCampaignInfluencerDailyQuery
} from '@/graphql';
import { usePostReportSearch } from '@/shared/molecules';
import {
  PostReportSegment,
  PostReportSegmentKeys,
  formatPostReportSegmentTotal,
  formatPostReportSegmentInfluencerDaily
} from '../../../../PostReport';

interface Props {
  marketplace: MarketplaceDetail;
}
export const useMarketplaceReportTableData = ({ marketplace: { id } }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const {
    state,
    filter: { keyword, endDate, startDate },
    setParamsState,
    page,
    limit,
    sort
  } = usePostReportSearch();

  const variables = getValuableVariables({
    keyword,
    endDate,
    startDate,
    campaignId: id,
    offset: getOffset(page, limit),
    orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
    limit
  });

  const handleError = () => {
    enqueueSnackbar(t('failToLoadDailyInformation'), { variant: 'error' });
  };

  const { data, previousData, loading, networkStatus } = useMarketplaceCampaignReportQuery({
    variables,
    fetchPolicy: 'no-cache'
  });
  const { data: totalDailyData, loading: fetchingTotalDaily } = useMarketplaceCampaignTotalDailyQuery({
    skip: !state.total,
    fetchPolicy: 'no-cache',
    variables: {
      ...variables,
      orderBy: { field: null, order: null } // sort doesn't apply to total daily, always sorted by date
    },
    onError: handleError
  });
  const { data: influencerDailyData, loading: fetchingInfluencerDaily } = useMarketplaceCampaignInfluencerDailyQuery({
    skip: !state.post,
    fetchPolicy: 'no-cache',
    variables: {
      ...variables,
      orderBy: { field: null, order: null } // sort doesn't apply to influencer daily, always sorted by date
    },
    onError: handleError
  });

  const handleSegmentChange = ({ key, value }: TableSegmentOption<PostReportSegmentKeys>) => {
    switch (key) {
      case PostReportSegmentKeys.total:
        setParamsState({ total: !value });
        break;
      case PostReportSegmentKeys.post:
        setParamsState({ post: !value });
        break;
      default:
        break;
    }
  };

  const result = loading ? previousData : data;
  const segment: PostReportSegment = {
    post: undefined,
    total: {
      loading: fetchingTotalDaily,
      data: formatPostReportSegmentTotal(
        state.total ? totalDailyData?.marketplaceCampaignReport?.orderedSummaryDaily : null
      )
    },
    // It's post but displayed under influencer segment type
    influencer: {
      loading: fetchingInfluencerDaily,
      data: formatPostReportSegmentInfluencerDaily(
        state.post ? influencerDailyData?.marketplaceCampaignReport?.influencerReports : null
      )
    }
  };

  return {
    loading,
    networkStatus,
    segment,
    segmentStatus: {
      post: !!state.post,
      total: !!state.total,
      influencer: !!state.influencer
    },
    handleSegmentChange,
    reportData: result?.marketplaceCampaignReport || undefined
  };
};
