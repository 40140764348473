import { useTranslation } from 'react-i18next';

import { Option, ToggleOption } from '@/shared/types';

export const useToggleOption = () => {
  const { t } = useTranslation();

  const toggleOptions: Option<ToggleOption>[] = [
    { label: t<string>('Show'), value: ToggleOption.TRUE },
    { label: t<string>('Hide'), value: ToggleOption.FALSE }
  ];

  return { toggleOptions };
};
