import { InfluencerNewProfileHeaderV2 } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfilePostsWrapper } from '../../shared';
import { InfluencerYoutubeProfileFeedPosts } from './InfluencerYoutubeProfileFeedPosts';
import { InfluencerYoutubeProfilePostsOverview } from './InfluencerYoutubeProfilePostsOverview';

export interface InfluencerYoutubeProfilePostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerYoutubeProfilePosts = ({
  socialAccount,
  influencerProfile
}: InfluencerYoutubeProfilePostsProps) => (
  <InfluencerProfilePostsWrapper socialAccount={socialAccount} influencerProfile={influencerProfile}>
    {({ data }) => (
      <>
        <InfluencerYoutubeProfilePostsOverview
          data={data}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
        <InfluencerYoutubeProfileFeedPosts
          data={data?.feedPosts || []}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
      </>
    )}
  </InfluencerProfilePostsWrapper>
);
