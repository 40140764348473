import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

interface HashtagsNoticeProps {
  className?: string;
}
export const HashtagsNotice = ({ className }: HashtagsNoticeProps) => {
  const { t } = useTranslation();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        borderRadius: '2px',
        border: `1px solid ${THEME.border.colors.blue.lv1}`,
        background: 'rgba(195, 222, 247, 0.20)'
      }}
      className={className}
    >
      <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Icon icon="info" size="14px" color={THEME.icon.colors.blue} />
        <h3 css={{ fontSize: '14px', fontWeight: 700 }}>{t('trackCreatorsPostTitle')}</h3>
      </div>

      <div css={{ paddingLeft: '24px', lineHeight: THEME.input.text.lineHeight }}>
        <Trans
          i18nKey="trackCreatorsPostText_1"
          components={{
            span: <span css={{ color: THEME.text.colors.red.lv1, textDecoration: 'underline', fontWeight: 600 }} />
          }}
        />
        <br />
        <span>{t('trackCreatorsPostText_2')}</span>
      </div>
    </div>
  );
};
