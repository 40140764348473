import { useTranslation } from 'react-i18next';
import { useParams } from '@tanstack/react-router';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useInfluencerYoutubeComparePostListQuery } from '@/graphql';
import { ProfilePostsFilter, ProfilePostsCount } from '@/shared/organisms';
import { InfluencerYoutubeCompareOtherSearchType } from '../types';
import { YoutubeCompareVideosTable } from '../YoutubeCompareVideosTable';

export const CompareAccountTemplate = () => {
  const { socialMediaAccountId: mainAccountId, compareId } = useParams({
    from: '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId'
  });
  const { t } = useTranslation();
  const {
    page,
    sort,
    limit,
    setFilter,
    filter: { endDate, startDate, keyword }
  } = useParamsSearch<InfluencerYoutubeCompareOtherSearchType>();
  const { data, previousData, loading } = useInfluencerYoutubeComparePostListQuery({
    skip: !compareId || !mainAccountId,
    variables: getValuableVariables({
      limit,
      keyword,
      endDate,
      startDate,
      socialAccountId: Number(mainAccountId),
      orderBy: sort,
      offset: getOffset(page, limit),
      compareAccountId: Number(compareId)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerYoutubeComparePostsCount.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter
        css={{ borderRadius: 0 }}
        filter={{ keyword, endDate, startDate }}
        setFilter={setFilter}
        hasPeriod
      />

      <ProfilePostsCount count={totalRecords} countLabel={t('Videos found')} />

      <YoutubeCompareVideosTable
        loading={loading}
        socialMediaAccountId={Number(mainAccountId)}
        data={result?.influencerYoutubeComparePostList.posts || []}
      />
    </ListWithPagination>
  );
};
