import { EngagementCampaignStatus } from '@/graphql';

export const getEngagementProposalAddRemoveButtonDisableState = (
  buttonState: 'Add' | 'Remove',
  status?: EngagementCampaignStatus
) => {
  switch (status) {
    case EngagementCampaignStatus.DRAFT:
    case EngagementCampaignStatus.REVIEWING:
      return false;

    case EngagementCampaignStatus.ONGOING:
    case EngagementCampaignStatus.UPCOMING:
      return buttonState === 'Add' ? false : true;

    default:
      return true;
  }
};
