import { linkOptions } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import { TalentAgencyOrPartner, InfluencerUpdateDetailV2, useUpdateInfluencerV2Mutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import {
  InfluencerForm,
  InfluencerFormValues,
  getInfluencerFormValues,
  getCreateUpdateInfluencerInput
} from '@/shared/organisms';
import { FormAction } from '@/shared/types';

interface TemplateProps {
  influencer: InfluencerUpdateDetailV2;
  allTalentAgenciesPartners: readonly TalentAgencyOrPartner[];
}

export const Template = ({ influencer, allTalentAgenciesPartners }: TemplateProps) => {
  const goBackUrl = linkOptions({ to: '/influencer/$id/profile', params: { id: influencer.id.toString() } });

  useUpdateDocumentTitle({
    href: goBackUrl.to,
    params: goBackUrl.params,
    title: 'documentTitle.EditInfluencer',
    appHeader: influencer.name || 'appHeader.Edit Influencer'
  });
  const { isAdmin } = useUserRoles();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callUpdateInfluencerV2 } = useUpdateInfluencerV2Mutation();

  const handleSubmit: FormAction<InfluencerFormValues>['onSubmit'] = async (values) => {
    try {
      const input = getCreateUpdateInfluencerInput(values, allTalentAgenciesPartners);
      await callUpdateInfluencerV2({
        variables: { input: { ...input, id: influencer.id, isProAccount: isAdmin ? values.isProAccount : null } }
      });

      enqueueSnackbar(t('succeededInUpdating', { name: values.name }), { variant: 'success' });
      navigate(goBackUrl);
    } catch (error) {
      enqueueSnackbar(t('failedToUpdate', { name: values.name }), { variant: 'error' });
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <InfluencerForm
      onSubmit={handleSubmit}
      defaultValues={getInfluencerFormValues(influencer, allTalentAgenciesPartners)}
    />
  );
};
