import { z } from 'zod';
import { InstagramStoryPostSortField } from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { FilterValidations } from '@/shared/validations';

export const InfluencerInstagramStoryPostsSearchSchema = z.object({
  filter: z
    .object({
      keyword: z.string().optional(),
      name: z.string().optional()
    })
    .default({}),
  sort: FilterValidations.sort(InstagramStoryPostSortField).default({ field: null, order: null }),
  limit: z.number().default(LIMIT),
  page: z.number().default(1)
});

export type InfluencerInstagramStoryPostsSearchType = z.infer<typeof InfluencerInstagramStoryPostsSearchSchema>;
