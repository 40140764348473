import { useTranslation } from 'react-i18next';
import {
  MarketplacePostInfluencer,
  MarketplaceAffiliateCommissionStatus,
  MarketplaceAffiliateCommissionInfluencer
} from '@/graphql';
import { Button, Status, RowProps, CheckBox, TableCell, TextCutter, ColumnProps, StatusProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useSelectItemIds } from '@/shared/hooks';
import { InfluencerAvatar } from '@/shared/molecules';
import { ColorVariant } from '@/shared/types';
import { formatPrice } from '@/shared/utils';

type SelectCommissionProps = Pick<
  ReturnType<typeof useSelectItemIds<number>>,
  'selectItem' | 'selectedItemIds' | 'hasAllItemsSelected' | 'toggleSelectAllItems'
>;

interface AffiliateInfluencersTableProps extends SelectCommissionProps {
  data: readonly MarketplaceAffiliateCommissionInfluencer[];
  onApprove: (influencer: MarketplacePostInfluencer) => void;
}

export const useMarketplaceCommissionsInfluencersTable = ({
  data,
  onApprove,
  selectItem,
  selectedItemIds,
  hasAllItemsSelected,
  toggleSelectAllItems
}: AffiliateInfluencersTableProps) => {
  const { t } = useTranslation();

  const getStatusColumnProps = (status: MarketplaceAffiliateCommissionStatus): StatusProps => {
    switch (status) {
      case MarketplaceAffiliateCommissionStatus.APPROVED:
        return { label: t('Option.Approved'), variant: ColorVariant.LIGHT_GREEN };
      case MarketplaceAffiliateCommissionStatus.UNAPPROVED:
        return { label: t('Option.Not Approved'), variant: ColorVariant.LIGHT_GREY };
      default:
        return { label: '', variant: ColorVariant.LIGHT_GREY };
    }
  };

  const hasAllApproved = data.every((infl) => infl.status === MarketplaceAffiliateCommissionStatus.APPROVED);
  const columns: ColumnProps[] = [
    {
      title: (
        <div css={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <CheckBox
            size="16px"
            disabled={hasAllApproved}
            onClick={!hasAllApproved ? toggleSelectAllItems : undefined}
            tooltipProps={{ help: t(hasAllItemsSelected ? 'Unselect all' : 'Select all') }}
          />
          <span>{t('HeaderColumn.Fixed date of commission')}</span>
        </div>
      ),
      sticky: { active: true }
    },
    { title: t('HeaderColumn.Influencer'), styles: { width: '25%' } },
    { title: t('HeaderColumn.Payout'), styles: { textAlign: 'left' } },
    { title: t('HeaderColumn.Status'), styles: { textAlign: 'left' } },
    { title: '' }
  ];

  const rows: RowProps[] = data.map(
    ({ influencer, currency, endCoverageDate, fixedDate, revenue, startCoverageDate, status }) => {
      const isSelected = selectedItemIds.includes(influencer.id);
      const isUnapproved = status === MarketplaceAffiliateCommissionStatus.UNAPPROVED;

      return [
        <div css={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <CheckBox
            size="16px"
            checked={isSelected}
            css={{ gap: '16px' }}
            disabled={!isUnapproved}
            key={`checkbox-${influencer.id}`}
            tooltipProps={{ help: isSelected ? t('Unselect') : t('Select') }}
            onClick={isUnapproved ? () => selectItem(influencer.id) : undefined} // without onClick just Children will be rendered
          />
          <TableCell.Text
            // BE gives us `yyyy-MM-DD` already
            value={
              <div css={!isUnapproved && !hasAllApproved ? { marginLeft: '32px' } : undefined}>
                <span>{fixedDate}</span>&nbsp;
                <span
                  css={{ fontSize: '11px', color: THEME.text.colors.gray.lv3 }}
                >{`(${startCoverageDate} - ${endCoverageDate})`}</span>
              </div>
            }
          />
        </div>,
        <div css={{ minWidth: '200px', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <InfluencerAvatar
            size="48px"
            src={influencer.avatar}
            css={{ backgroundColor: '#EEF3F7' }}
            id={influencer.id}
          />
          <TextCutter lines={3} text={influencer.name} />
        </div>,
        <TableCell.Text value={`${formatPrice(revenue, currency)} ${currency}`} css={{ fontWeight: 600 }} />,
        <Status {...getStatusColumnProps(status)} />,
        isUnapproved ? (
          <Button css={{ padding: '16px' }} title={t('Button.Approve')} onClick={() => onApprove(influencer)} />
        ) : null
      ];
    }
  );

  return { rows, columns };
};
