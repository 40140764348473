import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteOpenAiThread.graphql';
import { Mutation, MutationdeleteOpenAiThreadArgs } from '../../__generated__/globalTypes';

export const useDeleteOpenAiThreadMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteOpenAiThreadArgs>
) => {
  const [callDeleteOpenAiThread, result] = useMutation<Mutation, MutationdeleteOpenAiThreadArgs>(MUTATION, options);

  return { callDeleteOpenAiThread, ...result };
};

export type DeleteOpenAiThreadMutationFunction = Mutation['deleteOpenAiThread'];
