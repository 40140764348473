import { QueryHookOptions } from '@apollo/client';

import { Query, useAllAgenciesForSearchQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';

export const useAllAgenciesForSearchOptions = (options?: QueryHookOptions<Query>) => {
  const { data, loading } = useAllAgenciesForSearchQuery({ fetchPolicy: 'cache-and-network', ...options });

  return {
    loading,
    allAgencyOptions: getOptions(data?.allAgenciesForSearch)
  };
};
