import { useMarketplacePostHistoryQuery } from '@/graphql';
import { PostDetailsType } from '@/shared/types';
import { useMarketplacePostDetailsData } from './hooks';
import { Template } from './Template';

export interface MarketplaceInfluencerPostDetailsProps extends Pick<PostDetailsType, 'id'> {
  onCloseModal?: () => void;
  skip?: { postHistory?: boolean };
}

export const MarketplaceInfluencerPostDetails = ({ id, skip, onCloseModal }: MarketplaceInfluencerPostDetailsProps) => {
  const { postDetails, postStatus, loading } = useMarketplacePostDetailsData({ id, onCloseModal });
  const { data, loading: fetchingHistory } = useMarketplacePostHistoryQuery({
    skip: !id || skip?.postHistory,
    variables: { pk: Number(id) },
    fetchPolicy: 'cache-and-network'
  });

  return (
    <Template
      id={id}
      postStatus={postStatus}
      postDetails={postDetails}
      loading={loading || fetchingHistory}
      historyData={data?.marketplacePostHistory}
    />
  );
};
