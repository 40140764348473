import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { useMarketplaceJoinedInfluencersFilter } from './hooks';

type MarketplaceJoinedInfluencersFilterProps = Omit<TemplateProps, 'onSubmit'>;

export const MarketplaceJoinedInfluencersFilter = (props: MarketplaceJoinedInfluencersFilterProps) => {
  const { filter, setFilterForm } = useMarketplaceJoinedInfluencersFilter();

  return (
    <FilterWrapper>
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template {...props} />} />
    </FilterWrapper>
  );
};

export { useMarketplaceJoinedInfluencersFilter };
export * from './schemaTypes';
