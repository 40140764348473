import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga4';
import { NavigateOptions, useNavigate } from '@tanstack/react-router';
import { useAuthData, useLoggedInUserPermissions } from '@/auth';
import { AppName, UserRoles, useTokenAuthMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useDefaultAppRoute, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { InitialLoading } from '@/shared/molecules';
import { SignInCover, SignInForm, SignInFormValues, SignInSignUpLayout } from '@/shared/organisms';
import { FormAction, StateType } from '@/shared/types';
import { manageLocalStorage, setToken } from '@/shared/utils';

export const SignIn = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.SignIn' });
  const { dispatch } = useAuthData();
  const { t } = useQueryHelper();
  const navigate = useNavigate();
  const { defaultRoute } = useDefaultAppRoute();
  const { callTokenAuth } = useTokenAuthMutation();
  const { permissionLoading } = useLoggedInUserPermissions();

  if (permissionLoading) {
    return <InitialLoading />;
  }

  const handleSubmit: FormAction<SignInFormValues>['onSubmit'] = async (payload, { setError }) => {
    try {
      const result = await callTokenAuth({ variables: { input: { ...payload, appName: AppName.ANYTAG } } });

      if (result?.data?.tokenAuth) {
        const { token, userId, role, hash, currencyId, countryId, name, company } = result.data.tokenAuth;

        setToken(token);

        dispatch({
          type: 'AUTH_USER',
          payload: {
            role,
            name,
            hash,
            userId,
            countryId: countryId || '',
            currencyId,
            status: 'success',
            email: payload.email,
            company: company as StateType['company']
          }
        });
        Sentry.setUser({ id: String(userId) });
        ReactGA.set({ userId });
        ReactGA.set({ user_properties: { role, email: payload.email } });

        const redirectUrlBeforeSignIn = manageLocalStorage('get', 'redirectUrlBeforeSignIn');
        const isTikTokAdvertiser = role === UserRoles.ADVERTISER && company === 'TIKTOK';

        const redirectUrl = isTikTokAdvertiser ? '/tiktok/ad-campaign-report' : defaultRoute;

        navigate(
          redirectUrlBeforeSignIn ? ({ href: redirectUrlBeforeSignIn } as NavigateOptions) : { to: redirectUrl }
        );
        manageLocalStorage('remove', 'redirectUrlBeforeSignIn');
      } else {
        setError('password', { type: 'server', message: UNEXPECTED_ERROR });
      }
    } catch (error) {
      const graphQLerrors = error.graphQLErrors;
      if (graphQLerrors?.length > 0 && ['NOT_FOUND', 'LOGIN_FAILED'].includes(graphQLerrors[0].message)) {
        setError('password', { type: 'server', message: 'loginFailureMessage' });
      } else {
        setError('password', { type: 'server', message: UNEXPECTED_ERROR });
      }
    }
  };

  return (
    <SignInSignUpLayout
      footerProps={{ hasCopyRight: true }}
      headerProps={{
        signLink: { title: t('Sign up'), to: '/sign-up', description: t('Don’t have an account?') }
      }}
    >
      <SignInForm onSubmit={handleSubmit} />
      <SignInCover />
    </SignInSignUpLayout>
  );
};
