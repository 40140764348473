import { useUpdateDocumentTitle } from '@/shared/hooks';
import { SignInSignUpLayout, TermsAnytagEn, TermsSelfServiceEn } from '@/shared/organisms';

export const TermsOfServiceCombined = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.Terms' });

  return (
    <SignInSignUpLayout
      footerProps={{ className: { paddingBottom: '85px' } }}
      headerProps={{ to: '/', className: { paddingTop: '40px' } }}
    >
      <div>
        <TermsAnytagEn />
        <TermsSelfServiceEn css={{ marginTop: '0' }} />
      </div>
    </SignInSignUpLayout>
  );
};
