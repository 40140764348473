import { useTranslation } from 'react-i18next';
import { useAllCampaignCategoryOptions } from '@/shared/hooks';
import { SelectorField, SelectorFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const CampaignCategoryField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  ...restProps
}: Partial<SelectorFieldProps<T>>) => {
  const { t } = useTranslation();
  const { allCampaignCategoryOptions } = useAllCampaignCategoryOptions();

  return (
    <SelectorField<T>
      title={t('Selector.Campaign Category')}
      help={t('Tooltip.Campaign Category')}
      options={allCampaignCategoryOptions}
      name={name || (MarketplaceFormKeys.campaignCategoryId as T)}
      {...restProps}
    />
  );
};
