import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TwitterAnalyticsPost.graphql';
import { Query, QuerytwitterAnalyticsPostArgs } from '../../__generated__/globalTypes';

export const useTwitterAnalyticsPostQuery = (options?: QueryHookOptions<Query, QuerytwitterAnalyticsPostArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytwitterAnalyticsPostArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTwitterAnalyticsPostLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytwitterAnalyticsPostArgs>
): QueryResult<Query, QuerytwitterAnalyticsPostArgs> & {
  getTwitterAnalyticsPost: LazyQueryExecFunction<Query, QuerytwitterAnalyticsPostArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTwitterAnalyticsPost, result] = useLazyQuery<Query, QuerytwitterAnalyticsPostArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTwitterAnalyticsPost, ...result };
};

export const useTwitterAnalyticsPostPromiseQuery = () => {
  const client = useApolloClient();

  const getTwitterAnalyticsPost = useCallback(
    (options?: Omit<QueryOptions<QuerytwitterAnalyticsPostArgs, Query>, 'query'>) =>
      client.query<Query, QuerytwitterAnalyticsPostArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTwitterAnalyticsPost };
};
