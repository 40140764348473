import { css } from '@emotion/react';
import { LinkProps } from '@tanstack/react-router';
import { THEME } from '@/shared/constants';
import { useAuthData } from '@/auth';
import { useMatchPaths } from '@/shared/hooks';
import { useTiktokBusinessAccountInfoQuery } from '@/graphql';

const STEP_ROUTES = [
  '/tiktok-onboarding',
  '/tiktok-onboarding/password-enter',
  '/tiktok-onboarding/select-plan',
  '/tiktok-onboarding/ad-account',
  '/tiktok-onboarding/success'
] as const satisfies LinkProps['to'][];

export type StepType = {
  isActive: boolean;
  isCompleted: boolean;
  isDefault: boolean;
  href: (typeof STEP_ROUTES)[number];
  isDisabled: boolean;
};
export const useTiktokSignupSteps = () => {
  const { auth } = useAuthData();
  const { matchPaths, matchRoute } = useMatchPaths();
  const { data } = useTiktokBusinessAccountInfoQuery({ skip: !auth.userId });

  const isStepInitial = !!matchRoute({ to: '/tiktok-onboarding' });
  const isStepPassword = matchPaths([
    '/tiktok-onboarding/password-enter',
    '/tiktok-onboarding/password-register/$token'
  ]);
  const isStepSuccess = !!matchRoute({ to: '/tiktok-onboarding/success' });
  const isStepOne = isStepInitial || isStepPassword || isStepSuccess;
  const isStepTwo = !!matchRoute({ to: '/tiktok-onboarding/select-plan' });
  const isStepThree = !!matchRoute({ to: '/tiktok-onboarding/ad-account' });

  return {
    stepOne: {
      isActive: isStepOne,
      isCompleted: !!auth.userId,
      isDefault: false,
      href: '/tiktok-onboarding',
      isDisabled: false
    },
    stepTwo: {
      isActive: isStepTwo,
      isCompleted: !!auth.subscriptionPlan,
      isDefault: !auth.subscriptionPlan,
      href: '/tiktok-onboarding/select-plan',
      isDisabled: !auth.userId && !isStepTwo
    },
    stepThree: {
      isActive: isStepThree,
      isCompleted: !!data?.tiktokBusinessAccountInfo?.connected,
      isDefault: !data?.tiktokBusinessAccountInfo?.connected,
      href: '/tiktok-onboarding/ad-account',
      isDisabled: !auth.subscriptionPlan && !isStepThree
    }
  } satisfies Record<string, StepType>;
};

export const getStepStyles = (step: StepType) =>
  step.isActive
    ? styles.activeStep
    : step.isCompleted
      ? styles.completedStep
      : step.isDefault
        ? styles.defaultStep
        : '';

const styles = {
  activeStep: css({ border: `9px solid ${THEME.icon.colors.black}` }),
  completedStep: css({
    '&:before': {
      content: "'✓'",
      position: 'absolute',
      left: 4,
      top: 1,
      fontSize: '16px',
      color: `${THEME.icon.colors.black} !important`
    }
  }),
  defaultStep: css({
    ':after': {
      content: "''",
      position: 'absolute',
      top: 9,
      left: 9,
      borderRadius: '50%',
      display: 'block',
      width: '4px',
      height: '4px',
      background: THEME.icon.colors.gray.lv2
    }
  })
};
