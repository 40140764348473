import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthConnectableYouTubeChannelsAnalyticsV2.graphql';
import { Mutation, MutationsocialAuthConnectableYouTubeChannelsAnalyticsV2Args } from '../../__generated__/globalTypes';

export const useSocialAuthConnectableYouTubeChannelsAnalyticsV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthConnectableYouTubeChannelsAnalyticsV2Args>
) => {
  const [callSocialAuthConnectableYouTubeChannelsAnalyticsV2, result] = useMutation<
    Mutation,
    MutationsocialAuthConnectableYouTubeChannelsAnalyticsV2Args
  >(MUTATION, options);

  return { callSocialAuthConnectableYouTubeChannelsAnalyticsV2, ...result };
};

export type SocialAuthConnectableYouTubeChannelsAnalyticsV2MutationFunction =
  Mutation['socialAuthConnectableYouTubeChannelsAnalyticsV2'];
