import { css } from '@emotion/react';
import { ReactNode, useState } from 'react';
import { CheckBox, Icon, Popover, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';
import { DisabledProps } from '../types';

export interface MenuItemWithCheckBoxProps<T extends Option = Option> extends DisabledProps {
  name?: string;
  label?: string;
  className?: string;
  children?: ReactNode;
  value?: string | null;
  handleClick: (option?: T) => void;
  isSelected: boolean;
  type?: 'checkbox' | 'radio' | 'checkbox-with-submenu';
  submenu?: T[];
  checkIsSelected?: (option: T) => boolean;
  handleClickSubmenuGroup?: (submenuOptions: T[], allSubmenusSelected?: boolean) => void;
}

export const MenuItemWithCheckBox = <T extends Option = Option>({
  value = null,
  name,
  label,
  disabled,
  children,
  className,
  isSelected,
  handleClick,
  submenu = [],
  checkIsSelected,
  type = 'checkbox',
  handleClickSubmenuGroup
}: MenuItemWithCheckBoxProps<T>) => {
  const [openedId, setOpenedId] = useState<string | null>(null);

  return type === 'checkbox' ? (
    <CheckBox
      label={label}
      disabled={disabled}
      checked={isSelected}
      className={className}
      onClick={() => handleClick()}
      css={styles.checkbox(disabled)}
    />
  ) : type === 'radio' ? (
    <label css={styles.label(isSelected, disabled)}>
      <input type="radio" name={name} checked={isSelected} onClick={() => handleClick()} readOnly />
      <div css={styles.icon(isSelected)} />
      <TextCutter text={label} lines={3} />
    </label>
  ) : type === 'checkbox-with-submenu' ? (
    <Popover
      position="right"
      clickOutsideEvents={[]}
      open={openedId === value}
      closeOnClickOutside={false}
      withinPortal={false}
      offset={-3}
      target={() => {
        const allSubmenusSelected = submenu.every((s) => checkIsSelected?.(s));
        const atLeastOneSubmenuSelected = submenu.some((s) => checkIsSelected?.(s));

        return (
          <div
            onMouseOver={() => {
              setOpenedId(value);
            }}
            onMouseOut={() => {
              setOpenedId(null);
            }}
          >
            <CheckBox
              indeterminate={!allSubmenusSelected && atLeastOneSubmenuSelected}
              onClick={() => {
                handleClickSubmenuGroup?.(submenu, allSubmenusSelected);
              }}
              label={
                <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <TextCutter text={label} />
                  <Icon icon="caret-right" size="10px" color="#6E7C89" />
                </div>
              }
              checked={atLeastOneSubmenuSelected}
              disabled={disabled}
              css={[
                styles.checkbox(disabled),
                ...(openedId === value ? [{ backgroundColor: THEME.dropdown.hover }] : [])
              ]}
            ></CheckBox>
          </div>
        );
      }}
    >
      <div
        onMouseEnter={() => {
          setOpenedId(value);
        }}
        onMouseLeave={() => {
          setOpenedId(null);
        }}
        css={{ width: '186px' }}
      >
        {submenu.map((s) => (
          <div id="skipOutsideClick" key={s.value}>
            <CheckBox
              key={s.value}
              id="skipOutsideClick"
              onClick={() => {
                handleClick(s);
              }}
              label={s.label}
              checked={!!checkIsSelected?.(s)}
              disabled={disabled}
              css={styles.checkbox(disabled)}
            />
          </div>
        ))}
      </div>
    </Popover>
  ) : null;
};

const styles = {
  checkbox: (disabled?: boolean) =>
    css({
      padding: '8px',
      '&:hover': { backgroundColor: disabled ? 'transparent' : THEME.dropdown.hover }
    }),
  label: (disabled?: boolean, checked?: boolean) =>
    css({
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      fontSize: '13px',
      lineHeight: '20px',
      paddingRight: '16px',
      color: disabled ? THEME.text.colors.gray.lv1 : checked ? THEME.text.colors.blue : THEME.text.colors.black.lv1,
      cursor: disabled ? 'not-allowed' : 'pointer',
      pointerEvents: disabled ? 'none' : 'auto',
      userSelect: 'none',
      '&:focus-within > div': { border: `solid 1px ${checked ? '#2a78c0' : THEME.border.colors.blue}` }
    }),
  icon: (checked?: boolean) =>
    css({
      marginRight: '6px',
      position: 'relative',
      height: '16px',
      width: '16px',
      border: 'solid 1px #c5d0da',
      backgroundColor: checked ? THEME.background.colors.blue : THEME.background.colors.white,
      borderRadius: '50%',
      '&::after': {
        content: '',
        display: 'block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: THEME.background.colors.white,
        margin: '4px auto'
      }
    })
};
