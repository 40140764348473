import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PricingTableItem } from '@/graphql';
import {
  useGetPaymentPlanData,
  useTikTokCampaignRoutes,
  TikTokCampaignFormValues,
  useTikTokProductCategories,
  TikTokReviewInfluencersButton
} from '@/shared/organisms';

interface PlanInformationProps {
  currentPlan: PricingTableItem;
}
export const PlanInformation = ({ currentPlan }: PlanInformationProps) => {
  const { t } = useTranslation();
  const { productCategories } = useTikTokProductCategories();
  const { getPaymentPlan } = useGetPaymentPlanData([currentPlan]);
  const { getTikTokCampaignDetailsRoute } = useTikTokCampaignRoutes();
  const {
    formState: { defaultValues }
  } = useFormContext<TikTokCampaignFormValues>();

  const { name, packageId } = currentPlan;
  const currentProductCategory = productCategories.find((product) => product.packageId === packageId);

  return (
    !!currentProductCategory && (
      <div css={{ padding: '24px 24px 0', boxSizing: 'border-box' }}>
        <div css={styles.wrapper}>
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div css={{ display: 'flex', gap: 4, alignItems: 'center' }}>
              <Icon icon={currentProductCategory.icon} size={32} />
              <div>
                <p css={{ fontSize: '18px', fontWeight: 600, lineHeight: '100%' }}>{currentProductCategory.name}</p>
                <p css={{ marginTop: '4px', fontSize: '14px', lineHeight: '100%' }}>{t(`Plan.${name}`)}</p>
              </div>
            </div>

            <TikTokReviewInfluencersButton
              css={{ minWidth: '200px', width: 'max-content' }}
              to={defaultValues?.id ? getTikTokCampaignDetailsRoute('Influencers') : undefined}
              params={{ id: defaultValues?.id?.toString() }}
              search={{ filter: { packageId } }}
            />
          </div>
          <div css={styles.features}>
            {getPaymentPlan({ name }).features.map(({ label, value }, index) => (
              <div key={`feature-${index}`} css={styles.feature}>
                <span css={{ flex: 1 }}>{label}</span>
                <span css={{ fontWeight: 600 }}>{value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};
const styles = {
  wrapper: css({
    padding: '16px',
    borderRadius: '5px',
    boxSizing: 'border-box',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  features: css({
    gap: '16px 24px',
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: 400,
    fontSize: '12px',
    marginTop: '16px',
    lineHeight: '100%'
  }),
  feature: css({
    gap: '4px',
    flex: 'none',
    display: 'flex',
    width: 'max-content',
    whiteSpace: 'nowrap',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '&:not(:first-of-type)': {
      paddingLeft: '8px',
      borderLeft: `1px solid ${THEME.border.colors.gray.lv3}`
    }
  })
};
