import { z } from 'zod';

import { CustomPermissionLevel, CustomPermissions, UserRoles } from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';
import { getSchemaForType } from '@/shared/types';
import { BaseValidations } from '@/shared/validations';

const CustomPermissionLevelShape = getSchemaForType<CustomPermissionLevel | undefined | null>()(
  z
    .object({
      manage: z.boolean().nullish(),
      manageAndDelete: z.boolean().nullish(),
      noView: z.boolean().nullish(),
      view: z.boolean().nullish()
    })
    .nullish()
);

const BaseSchema = z.object({
  id: z.number().optional(),
  userType: z.nativeEnum(UserRoles),
  mode: z.literal('add').or(z.literal('edit')),
  name: BaseValidations.NameSchema,
  email: BaseValidations.EmailSchema,
  countryId: BaseValidations.CountryIdSchema,
  advertiserId: z.string(),
  agencyId: z.string(),
  advertiserIds: z.array(z.string()),
  talentAgencyId: z.string(),
  partnerId: z.string(),
  hasInvitationEmail: z.boolean(),
  advertiserCountryId: z.string(),
  notificationSetting: z.boolean(),
  autoLinkToAdvertiser: z.boolean(),
  permissions: z.literal(null).or(
    getSchemaForType<CustomPermissions>()(
      z.object({
        user: CustomPermissionLevelShape,
        agency: CustomPermissionLevelShape,
        report: CustomPermissionLevelShape,
        payment: CustomPermissionLevelShape,
        analytics: CustomPermissionLevelShape,
        advertiser: CustomPermissionLevelShape,
        engagement: CustomPermissionLevelShape,
        influencer: CustomPermissionLevelShape,
        marketplace: CustomPermissionLevelShape,
        talentAgency: CustomPermissionLevelShape,
        partnerAgency: CustomPermissionLevelShape,
        influencerPackage: CustomPermissionLevelShape,
        customizeUserPermissions: CustomPermissionLevelShape,
        influencerAddressAndPaymentInformation: CustomPermissionLevelShape
      })
    )
  ),
  password: BaseValidations.PasswordAdvancedSchemaDynamic.or(z.literal('')),
  passwordConfirm: BaseValidations.PasswordAdvancedSchemaDynamic.or(z.literal(''))
});
const AdminSchema = z.object({
  userType: z.literal(UserRoles.ADMINISTRATOR)
});
const StaffSchema = z.object({
  userType: z.literal(UserRoles.STAFF),
  advertiserIds: BaseValidations.AdvertiserIdsSchema
});
const TalentAgencySchema = z.object({
  userType: z.literal(UserRoles.TALENT_AGENCY),
  talentAgencyId: BaseValidations.TalentAgencySchema
});
const PartnerSchema = z.object({
  userType: z.literal(UserRoles.PARTNER),
  partnerId: z.string().min(1, AllTranslationKeys.Error.requiredPartnerMessage)
});
const AgencySchema = z.object({
  userType: z.literal(UserRoles.AGENCY),
  agencyId: z.string().min(1, AllTranslationKeys.Error.requiredAgencyMessage)
});
const AdvertiserSchema = z.object({
  userType: z.literal(UserRoles.ADVERTISER),
  advertiserId: BaseValidations.AdvertiserIdSchema
});

export const UserFormSchema = z
  .discriminatedUnion('userType', [
    AdminSchema,
    StaffSchema,
    TalentAgencySchema,
    PartnerSchema,
    AgencySchema,
    AdvertiserSchema
  ])
  .and(BaseSchema)
  .transform((data) => ({ ...data, userType: data.userType as UserRoles }))
  .superRefine((values, ctx) => {
    if (values.password !== values.passwordConfirm) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: AllTranslationKeys.Error.passwordsDoNotMatch,
        path: ['passwordConfirm']
      });
    }

    if (values.mode === 'add') {
      if (!values.hasInvitationEmail) {
        if (!values.password) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: AllTranslationKeys.Error.requiredPasswordMessage,
            path: ['password']
          });
        }
        if (!values.passwordConfirm) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: AllTranslationKeys.Error.requiredPasswordConfirmMessage,
            path: ['passwordConfirm']
          });
        }
      }
    }
  });
