import { useTranslation } from 'react-i18next';
import { AdvertisersAsyncSearchSelector, AdvertisersOption } from '../AdvertisersAsyncSelector';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export type AdvertiserSearchFieldProps<T extends string = string> = DefaultFieldProps<T, AdvertisersOption>;

export const AdvertiserSearchField = <T extends string>(props: AdvertiserSearchFieldProps<T>) => {
  const { t } = useTranslation();
  const {
    fieldRegister,
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, value, onChange, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper
      {...fieldWrapperProps}
      help={fieldWrapperProps.help ?? t('Tooltip.Advertiser')}
      title={fieldWrapperProps.title ?? t('Selector.Advertiser')}
    >
      <AdvertisersAsyncSearchSelector
        {...fieldRegister}
        {...restFieldProps}
        advertiserOption={value}
        fillFormValues={(option) => {
          onChange ? onChange(name, option) : setValue<string>(name, option || null, setValueOptions);
        }}
      />
    </RHFFieldWrapper>
  );
};
