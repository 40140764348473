import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataContentWrapper, RenderDataWithFallback, NoDataContentWrapperProps } from '@/shared/atoms';
import { SectionWithHeader, SectionWithHeaderProps } from '@/shared/molecules';

export interface AnalyticsAudienceWrapperProps<T = NonNullable<unknown>>
  extends Omit<SectionWithHeaderProps, 'children'>,
    Pick<NoDataContentWrapperProps, 'dataNotAvailable' | 'wrapperText'> {
  data?: T | null;
  loading?: boolean;
  className?: string;
  children?: ReactNode | ((props: { data: T }) => ReactNode);
}

export const AnalyticsAudienceWrapper = <T,>({
  help,
  data,
  loading,
  children,
  className,
  wrapperText,
  dataNotAvailable,
  ...restProps
}: AnalyticsAudienceWrapperProps<T>) => {
  const { t } = useTranslation();

  return (
    <div css={{ marginTop: '24px' }} className={className}>
      <RenderDataWithFallback loading={loading} loadingProps={{ css: { height: '400px' } }} skipDelayed>
        <SectionWithHeader
          title={t('Audience (Followers)')}
          help={help ?? t('Tooltip.dashboardAudience')}
          prefixIcon={{ icon: 'user-two-tones', size: '24px' }}
          {...restProps}
        >
          <NoDataContentWrapper wrapperText={wrapperText} dataNotAvailable={dataNotAvailable ?? !data}>
            {!data ? null : typeof children === 'function' ? children({ data }) : children}
          </NoDataContentWrapper>
        </SectionWithHeader>
      </RenderDataWithFallback>
    </div>
  );
};
