import { useControlPostDetailsModal } from '@/shared/hooks';
import { CarouselModal } from '@/shared/molecules';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { UGCPostDetails } from './UGCPostDetails';

export interface UGCPostDetailsModalProps {
  posts: PostDetailsType[];
  onRefetchList?: () => void;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const UGCPostDetailsModal = ({ posts, onRefetchList, analyticsAccount }: UGCPostDetailsModalProps) => {
  const { selectedPost, siblingPostId, closePost, handleClickNext, handleClickPrev } = useControlPostDetailsModal({
    posts
  });

  const handleCloseModal = () => {
    closePost();
    onRefetchList?.();
  };

  return (
    <CarouselModal
      open={!!selectedPost}
      onClose={handleCloseModal}
      onClickNext={handleClickNext}
      onClickPrev={handleClickPrev}
      siblingPostId={siblingPostId}
      hasCloseIcon
    >
      {selectedPost ? (
        <UGCPostDetails id={selectedPost.id} onCloseModal={handleCloseModal} analyticsAccount={analyticsAccount} />
      ) : null}
    </CarouselModal>
  );
};
