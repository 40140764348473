import { ListWithPagination } from '@/shared/molecules';
import { useYoutubeAnalyticsTrendsQuery } from '@/graphql';
import { getOffset, getValuableVariables } from '@/shared/utils';
import {
  TrendingPostsList,
  TrendingPostsFilter,
  TrendingPostsTabType,
  useTrendingPostsFilter
} from '@/shared/organisms';
import { Option } from '@/shared/types';

interface TrendingYoutubePostsProps {
  allCountryOptions: Option[];
  allCategoryOptions: Option[];
}
export const TrendingYoutubePosts = ({ allCountryOptions, allCategoryOptions }: TrendingYoutubePostsProps) => {
  const { page, limit, filter } = useTrendingPostsFilter();
  const { data, previousData, loading } = useYoutubeAnalyticsTrendsQuery({
    skip: !filter.categoryId || !filter.countryId,
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      date: filter.date,
      country: filter.countryId,
      category: Number(filter.categoryId)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.youtubeAnalyticsTrendsCount.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <TrendingPostsFilter allCountryOptions={allCountryOptions} allCategoryOptions={allCategoryOptions} />
      <TrendingPostsList
        loading={loading}
        tab={TrendingPostsTabType.YOUTUBE}
        data={result?.youtubeAnalyticsTrends.posts || []}
      />
    </ListWithPagination>
  );
};
