import { useTranslation } from 'react-i18next';
import { useAllCampaignCategoryOptions } from '@/shared/hooks';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../types';

export const CampaignCategoryField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { allCampaignCategoryOptions } = useAllCampaignCategoryOptions();

  return (
    <SelectorField<InfluencersFilterFormKeys>
      title={t("Selector.Account's Interest")}
      name="campaignCategoryIds"
      options={allCampaignCategoryOptions}
      multiple
      {...props}
    />
  );
};
