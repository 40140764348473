import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMeta, SingleValue, SelectInstance } from 'react-select';
import AsyncSelect from 'react-select/async';
import { THEME } from '@/shared/constants';
import { GenericOption } from '@/shared/types';
import { AsyncSelectStyles } from '../BigQuerySearchSelector/utils';
import { ClearIndicator, DropdownIndicator } from '../Selector';
import { useAdvertisersLoadOptions } from './useAdvertisersLoadOptions';

export type AdvertisersOption = GenericOption<string> | null;
export interface AdvertisersAsyncSearchSelectorProps {
  disabled?: boolean;
  hasError?: boolean;
  placeholder?: string;
  hideDeselectOption?: boolean;
  advertiserOption?: AdvertisersOption;
  fillFormValues: (o: AdvertisersOption) => void;
}
export const AdvertisersAsyncSearchSelector = forwardRef<
  SelectInstance<AdvertisersOption | '', false, any>,
  AdvertisersAsyncSearchSelectorProps
>(
  (
    {
      hasError,
      disabled,
      fillFormValues,
      advertiserOption,
      hideDeselectOption,
      placeholder = 'Selector.Please Select'
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { debouncedLoadAdvertisersOptions } = useAdvertisersLoadOptions();
    const [option, setOption] = useState(advertiserOption);

    const onChange = (selectedValues: SingleValue<AdvertisersOption | ''>, { action }: ActionMeta<any>) => {
      switch (action) {
        case 'select-option':
          if (selectedValues === '') {
            fillFormValues(null);
            setOption(null);
          } else {
            fillFormValues(selectedValues);
            setOption(selectedValues);
          }
          break;

        case 'clear':
          setOption(null);
          fillFormValues(null);
          break;

        default:
          break;
      }
    };

    return (
      <AsyncSelect
        ref={ref}
        isClearable={!hideDeselectOption}
        placeholder={placeholder ? t(placeholder) : ''}
        cacheOptions
        loadOptions={debouncedLoadAdvertisersOptions}
        onChange={onChange}
        styles={{
          ...AsyncSelectStyles(hasError),
          menu: (base) => ({
            ...base,
            border: THEME.border.base,
            borderRadius: '3px',
            marginTop: '1px',
            boxShadow: 'unset',
            width: 'max-content',
            minWidth: '100%'
          })
        }}
        value={option}
        components={{
          DropdownIndicator: (dropdownProps) => (!dropdownProps.hasValue ? <DropdownIndicator /> : null),
          ClearIndicator: (clearProps) => <ClearIndicator {...clearProps} ref={ref} />
        }}
        isDisabled={disabled}
      />
    );
  }
);
