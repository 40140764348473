import { useAllPaymentsQuery } from '@/graphql';
import { ListWithPagination } from '@/shared/molecules';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { PaymentsFilter, PaymentsList, usePaymentsFilter } from '@/shared/organisms';

export const Payments = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.Payment', appHeader: 'appHeader.Payment' });
  const { page, limit, filter } = usePaymentsFilter();
  const { data, previousData, loading } = useAllPaymentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      status: filter.status || null,
      keyword: filter.keyword,
      countryId: filter.countryId,
      maxDueDate: filter.endDate,
      minDueDate: filter.startDate
    })
  });

  const result = loading ? previousData : data;
  const allPayments = result?.allPayments || [];
  const totalRecords = result?.allPaymentsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <PaymentsFilter disabledDownload={allPayments.length === 0} />
      <PaymentsList loading={loading} data={allPayments} totalRecords={totalRecords} />
    </ListWithPagination>
  );
};
