/// <reference types="@emotion/react/types/css-prop" /> to make css prop recognized
import './assets/reset.css';
import * as ReactDOM from 'react-dom/client';
import { initI18Next, initSentry } from '@/shared/utils';
import { App } from './app';

initSentry();
initI18Next();
const root = ReactDOM.createRoot(document.getElementById('container') as HTMLElement);

root.render(<App />);
