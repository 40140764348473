import {
  eyeBlueImage,
  likePinkImage,
  userPinkImage,
  videoPinkImage,
  eyePurpleImage,
  chatGreenImage,
  quoteBlueImage,
  networkBlueImage,
  websiteBlueImage,
  websiteUserBlueImage,
  bulbLightYellowImage,
  chartLightYellowImage
} from '@/shared/assets';
import {
  AnalyticsTwitterDashboardGraphKeys,
  AnalyticsTwitterDashboardLegendType,
  AnalyticsTwitterDashboarOverviewEngagementType
} from './types';

export const legendColors: Record<AnalyticsTwitterDashboardGraphKeys, string> = {
  post: '#ffd7c1',
  like: '#ffb6b7',
  view: '#ff96ae',
  reply: '#aec0e4',
  retweet: '#a0c8df',
  follower: '#ff2b52',
  engagement: '#98ec9d',
  impression: '#9dd9f4',
  quotedTweet: '#a0dfd5',
  // Unsupported
  urlClick: '',
  profileClick: '',
  engagementRate: ''
};

export const getAllLegendOptions = (isInfluencerProfile?: boolean): AnalyticsTwitterDashboardLegendType[] => [
  { label: 'Follower', value: 'follower', color: legendColors.follower },
  { label: 'Post', value: 'post', color: legendColors.post },
  { label: 'Like', value: 'like', color: legendColors.like },
  { label: 'Views', value: 'view', color: legendColors.view },
  { label: 'Reply', value: 'reply', color: legendColors.reply },
  { label: 'Retweet', value: 'retweet', color: legendColors.retweet },
  { label: 'Quote Tweet', value: 'quotedTweet', color: legendColors.quotedTweet },
  { label: 'Impression', value: 'impression', color: legendColors.impression },
  ...(!isInfluencerProfile
    ? ([
        { label: 'Engagement', value: 'engagement', color: legendColors.engagement },
        { label: 'Engagement Rate', value: 'engagementRate', color: legendColors.engagementRate }
      ] as AnalyticsTwitterDashboardLegendType[])
    : []),
  { label: 'URL Clicks', value: 'urlClick', color: legendColors.urlClick },
  { label: 'User Profile Clicks', value: 'profileClick', color: legendColors.profileClick }
];

export const allOverviewEngagements: Pick<
  AnalyticsTwitterDashboarOverviewEngagementType,
  'key' | 'title' | 'icon' | 'css' | 'isPercentage'
>[] = [
  { key: 'follower', title: 'Follower', icon: userPinkImage },
  { key: 'post', title: 'Post', icon: videoPinkImage },
  { key: 'like', title: 'Like', icon: likePinkImage },
  { key: 'view', title: 'Views', icon: eyeBlueImage },
  { key: 'reply', title: 'Reply', icon: chatGreenImage },
  { key: 'retweet', title: 'Retweet', icon: networkBlueImage },
  { key: 'quotedTweet', title: 'Quote Tweet', icon: quoteBlueImage },
  { key: 'impression', title: 'Impression', icon: eyePurpleImage },
  { key: 'engagement', title: 'Engagement', icon: bulbLightYellowImage },
  { key: 'engagementRate', title: 'Engagement Rate', icon: chartLightYellowImage, isPercentage: true },
  { key: 'urlClick', title: 'URL Clicks', icon: websiteBlueImage },
  { key: 'profileClick', title: 'User Profile Clicks', icon: websiteUserBlueImage }
];
