import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerInstagramDashboardFollowerAnalytics.graphql';
import { Query, QueryinfluencerInstagramDashboardFollowerAnalyticsArgs } from '../../__generated__/globalTypes';

export const useInfluencerInstagramDashboardFollowerAnalyticsQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerInstagramDashboardFollowerAnalyticsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerInstagramDashboardFollowerAnalyticsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerInstagramDashboardFollowerAnalyticsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerInstagramDashboardFollowerAnalyticsArgs>
): QueryResult<Query, QueryinfluencerInstagramDashboardFollowerAnalyticsArgs> & {
  getInfluencerInstagramDashboardFollowerAnalytics: LazyQueryExecFunction<
    Query,
    QueryinfluencerInstagramDashboardFollowerAnalyticsArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerInstagramDashboardFollowerAnalytics, result] = useLazyQuery<
    Query,
    QueryinfluencerInstagramDashboardFollowerAnalyticsArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerInstagramDashboardFollowerAnalytics, ...result };
};

export const useInfluencerInstagramDashboardFollowerAnalyticsPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerInstagramDashboardFollowerAnalytics = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerInstagramDashboardFollowerAnalyticsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerInstagramDashboardFollowerAnalyticsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerInstagramDashboardFollowerAnalytics };
};
