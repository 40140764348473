import { DropAnimation, DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core';
import { ReactNode } from 'react';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({ styles: { active: { visibility: 'hidden' } } })
};

interface SortableOverlayProps {
  children?: ReactNode;
}

export const SortableOverlay = ({ children }: SortableOverlayProps) => (
  <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
);
