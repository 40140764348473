import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAllExistingPackagesQuery } from '@/graphql';
import { FormStyle, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAllCountryOptions } from '@/shared/hooks';
import { SelectorField, TextField } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { PackageWithAccountsFormKeys, PackageWithAccountsFormValues } from './types';

interface TemplateProps {
  count: number;
  isOpenModal: boolean;
  onSubmit?: () => void;
  toggleModal: () => void;
  allPackageCompanyOptions?: Option[];
}
export const Template = ({ count, onSubmit, toggleModal, isOpenModal, allPackageCompanyOptions }: TemplateProps) => {
  const { t } = useTranslation();
  const { allCountryOptions } = useAllCountryOptions();
  const {
    formState: { isSubmitting },
    setValue,
    watch
  } = useFormContext<PackageWithAccountsFormValues>();
  const { data: allPackagesData } = useAllExistingPackagesQuery({ fetchPolicy: 'cache-and-network' });
  const isAddMode = watch('type') === 'add';

  const allPackageOptions =
    allPackagesData?.allExistingPackages?.map((el) => ({ label: el.name, value: `${el.id}` })) || [];

  return (
    <Modal open={isOpenModal} onClose={toggleModal} css={{ width: '500px' }}>
      <ModalContent>
        <ModalTitle>{t(isAddMode ? 'Create a new package' : 'Select an existing package')}</ModalTitle>

        <div css={{ marginTop: '16px' }}>
          <p>{t(isAddMode ? 'CreateNewPackage' : 'SelectPackageContent')}</p>
          <div css={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <div css={styles.count}>{t('Selected account', { count })}</div>
            <button css={styles.toggleButton} onClick={() => setValue('type', isAddMode ? 'edit' : 'add')}>
              {t(isAddMode ? 'Select an existing package' : 'Create a new package')}
            </button>
          </div>

          <FormStyle.Wrapper css={{ marginTop: '8px' }} gapRow={16}>
            {isAddMode ? (
              <>
                <TextField<PackageWithAccountsFormKeys>
                  name="name"
                  title={t('TextForm.Package Name')}
                  placeholder={t<string>('package name')}
                  required
                />

                <FormStyle.FieldsGroup itemsPerRow={2}>
                  <SelectorField<PackageWithAccountsFormKeys>
                    name="countryId"
                    title={t('Selector.Country')}
                    options={allCountryOptions}
                    required
                  />

                  {allPackageCompanyOptions ? (
                    <SelectorField<PackageWithAccountsFormKeys>
                      title={t('Selector.Company')}
                      name="companies"
                      options={allPackageCompanyOptions}
                      multiple
                    />
                  ) : null}
                </FormStyle.FieldsGroup>
              </>
            ) : (
              <SelectorField<PackageWithAccountsFormKeys>
                name="id"
                title={t('Selector.Package')}
                options={allPackageOptions}
                required
              />
            )}
          </FormStyle.Wrapper>
        </div>
      </ModalContent>
      <ModalFooterActions
        cancelButtonProps={{ onClick: toggleModal }}
        submitButtonProps={{
          onClick: onSubmit,
          loading: isSubmitting,
          disabled: isSubmitting,
          title: t(isAddMode ? 'Save&Add' : 'Add')
        }}
      />
    </Modal>
  );
};
const styles = {
  toggleButton: css({
    fontWeight: 600,
    fontSize: '13px',
    cursor: 'pointer',
    lineHeight: '18px',
    color: THEME.text.colors.blue
  }),
  count: css({
    fontWeight: 600,
    lineHeight: '21px',
    padding: '6px 10px',
    borderRadius: '29px',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.gray.lv1
  })
};
