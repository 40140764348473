import { FormProvider } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TikTokPasswordFormSchema } from './schema';
import { Template } from './Template';
import { TikTokPasswordFormProps, TikTokPasswordFormValues } from './types';

export const TikTokPasswordForm = ({
  onSubmit,
  defaultValues = { password: '', passwordConfirm: '' }
}: TikTokPasswordFormProps) => (
  <div
    css={{
      width: '100%',
      backgroundColor: THEME.background.colors.white,
      boxShadow: THEME.shadows.boxShadowContainer,
      borderRadius: '3px'
    }}
  >
    <FormProvider onSubmit={onSubmit} defaultValues={defaultValues} zodSchema={TikTokPasswordFormSchema}>
      <Template />
    </FormProvider>
  </div>
);

export { type TikTokPasswordFormValues };
