import { useParams } from '@tanstack/react-router';
import { useUserRoles } from '@/auth';
import { useUpdatePackageMutation, usePackageQuery, CompanyAndRolePair, namedOperations } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useCustomHeader, useUpdateDocumentTitle, useAllPackageCompanyOptions } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { PackageFormValues, PackageForm } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const PackageDetails = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    href: '/package',
    title: 'appHeader.Edit Package',
    appHeader: 'appHeader.Edit Package'
  });
  const { isAdmin } = useUserRoles();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/package/$id/' });
  const { allPackageCompanies } = useAllPackageCompanyOptions({ skip: !isAdmin });
  const { callUpdatePackage } = useUpdatePackageMutation({ refetchQueries: [namedOperations.Query.AllPackages] });

  const packageId = params.id;

  const { data, loading } = usePackageQuery({
    skip: !packageId,
    variables: { packageId: Number(packageId) },
    onError: (err) => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
      navigate({ to: '/package' });
    }
  });

  const packageData = data?.package;

  const handleSubmit: FormAction<PackageFormValues>['onSubmit'] = async ({ name, countryId, companies }) => {
    try {
      const companyAndRolePairs = isAdmin
        ? allPackageCompanies.reduce<CompanyAndRolePair[]>(
            (rs, cr) => (companies.includes(String(cr.id)) ? [...rs, { companyId: cr.companyId, role: cr.role }] : rs),
            []
          )
        : [];
      await callUpdatePackage({
        variables: { input: { name, countryId, companyAndRolePairs, id: Number(packageId) } }
      });

      enqueueSnackbar(t('package has been saved successfully'), { variant: 'success' });
      navigate({ to: '/package' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const companies =
    packageData?.companies?.reduce<string[]>((rs, cr) => {
      const savedPackage = allPackageCompanies.find((el) => el.companyId === cr?.companyId && el.role === cr.role);

      if (savedPackage?.id !== undefined && savedPackage?.id !== null) {
        rs.push(savedPackage.id?.toString());
      }

      return rs;
    }, []) || [];

  return (
    <RenderDataWithFallback loading={loading}>
      <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
        <Header />

        <PackageForm
          onSubmit={handleSubmit}
          defaultValues={{
            companies,
            id: packageData?.id,
            name: packageData?.name,
            countryId: packageData?.country.id
          }}
        />
      </div>
    </RenderDataWithFallback>
  );
};
