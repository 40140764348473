import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { MarketplaceDetail } from '@/graphql';
import {
  Modal,
  FormStyle,
  ModalTitle,
  ModalContent,
  FormProvider,
  DownloadButton,
  ModalFooterActions
} from '@/shared/atoms';
import { GTM_IDS } from '@/shared/constants';
import { useDownloadFile, useToggleState } from '@/shared/hooks';
import { RadiosField } from '@/shared/molecules';
import { FormAction } from '@/shared/types';
import { getRestApiUrl } from '@/shared/utils';
import { MarketplacePostsFilterFormValues } from './schemaTypes';

enum DownloadType {
  COMMENT = 'COMMENT',
  INFLUENCER = 'INFLUENCER'
}

interface DownloadMarketplacePostsButtonProps {
  marketplaceId: MarketplaceDetail['id'];
}

export const DownloadMarketplacePostsButton = ({ marketplaceId }: DownloadMarketplacePostsButtonProps) => {
  const { t } = useTranslation();
  const modalState = useToggleState();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { getValues } = useFormContext<MarketplacePostsFilterFormValues>();

  const values = getValues();

  const handleDownload: FormAction<{ type: DownloadType }>['onSubmit'] = async ({ type }) => {
    await handleDownloadFile(
      type === DownloadType.INFLUENCER
        ? {
            params: values,
            filenamePrefix: 'influencer_list',
            url: getRestApiUrl(`marketplace/${marketplaceId}/post/csv/`)
          }
        : {
            params: values,
            filenamePrefix: 'post_comments',
            url: getRestApiUrl(`marketplace/${marketplaceId}/posts/comments/`)
          }
    );
    modalState.close();
  };

  return (
    <>
      <FormStyle.FieldWrapper css={{ flex: 'none' }}>
        <DownloadButton
          title=""
          onClick={modalState.open}
          id={GTM_IDS.marketplace.posts_csv_download}
          tooltip={{ help: t('Button.CSV Download') }}
        />
      </FormStyle.FieldWrapper>

      <Modal open={modalState.status} onClose={modalState.close} css={{ width: 'max-content' }}>
        <FormProvider<{ type: DownloadType }>
          onSubmit={handleDownload}
          defaultValues={{ type: DownloadType.INFLUENCER }}
        >
          <ModalContent>
            <ModalTitle>{t('Dialog.Export')}</ModalTitle>

            <p css={{ marginTop: '16px' }}>
              {t('Dialog.Select type of information you wish to include in exported csv file')}
            </p>

            <RadiosField
              name="type"
              itemsPerRow={1}
              css={{ paddingTop: '16px' }}
              options={[
                { label: t('Influencer list'), value: DownloadType.INFLUENCER },
                { label: t('Post comment list'), value: DownloadType.COMMENT }
              ]}
            />
          </ModalContent>

          <ModalFooterActions
            cancelButtonProps={{ onClick: modalState.close }}
            submitButtonProps={{ type: 'submit', loading: downloading, title: t('Button.Submit') }}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
