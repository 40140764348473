import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ProposalJobDescriptionBreakDownMode } from '@/graphql';
import { Button, RadioButtons, SplitButton } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useSelectedInfluencersContext } from '../SelectedInfluencersContext';
import { useUpdateInfluencerAccountsBudget } from './hooks';
import { TableModeType } from './types';

export interface TableModesProps {
  engagementId: number;
  jdMode: ProposalJobDescriptionBreakDownMode;
  onEditJdMode: (mode: ProposalJobDescriptionBreakDownMode) => void;
}

export const TableModes = ({ jdMode, engagementId, onEditJdMode }: TableModesProps) => {
  const { t } = useTranslation();
  const { rowsData, isEditMode, confirmModal, setTableMode, hasRowChange } = useSelectedInfluencersContext();

  const { updateInfluencerAccountsBudget, loading: updatingInfluencerAccountsBudget } =
    useUpdateInfluencerAccountsBudget({ jdMode, engagementId });

  const jdModeOptions = [
    { label: t('Social Media'), value: ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN },
    { label: t('General.Influencer'), value: ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN }
  ];

  return (
    <div css={styles.wrapper}>
      <SplitButton
        options={[
          {
            title: t('View'),
            variant: 'default',
            prefixIcon: { icon: 'hamburger-menu', size: 11 },
            onClick: () => {
              if (hasRowChange) {
                confirmModal.open();
              } else {
                setTableMode(TableModeType.VIEW);
              }
            },
            isActive: !isEditMode
          },
          {
            title: t('Budget Edit'),
            variant: 'default',
            prefixIcon: { icon: 'eraser', size: 11 },
            onClick: () => setTableMode(TableModeType.EDIT),
            isActive: isEditMode
          }
        ]}
      />

      <div css={{ display: 'flex', alignItems: 'center' }}>
        <p css={{ fontSize: '12px', lineHeight: '18px', marginRight: '8px' }}>{t('SetBudgetBy')}:</p>
        <RadioButtons items={jdModeOptions} handleSelect={(value) => onEditJdMode(value)} value={jdMode} />
        <Button
          variant="blue"
          title={t('Button.Save')}
          disabled={!hasRowChange}
          loading={updatingInfluencerAccountsBudget}
          css={{ padding: '0 16px', marginLeft: '16px' }}
          onClick={() => updateInfluencerAccountsBudget(rowsData)}
        />
      </div>
    </div>
  );
};
const styles = {
  wrapper: css({
    height: '48px',
    display: 'flex',
    padding: '8px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '3px 3px 0 0',
    justifyContent: 'space-between',
    backgroundColor: THEME.background.colors.white
  }),
  radio: (checked?: boolean) =>
    css({
      flex: '1 1 0',
      height: '64px',
      borderRadius: '5px',
      padding: '16px 24px',
      border: `2px solid ${checked ? '#3892e5' : '#dee5ec'}`,
      ...(checked ? { backgroundColor: '#eaf5ff' } : {}),
      '&:last-of-type': { marginLeft: '16px' }
    }),
  button: (isActive?: boolean) =>
    css({
      letterSpacing: 0,
      minWidth: '120px',
      '&:first-of-type': { borderRadius: '3px 0 0 3px' },
      '&:last-of-type': { marginLeft: '-1px', borderRadius: '0 3px 3px 0' },
      ...(isActive
        ? {
            zIndex: 1,
            boxSizing: 'border-box',
            color: THEME.text.colors.blue,
            borderColor: THEME.border.colors.blue.lv2,
            backgroundColor: 'rgba(56, 146, 229, 0.1)'
          }
        : { '&:hover': { opacity: 0.7 } })
    })
};

export * from './types';
