import { MouseEvent, ReactNode } from 'react';
import { LinkProps, useBlocker, RegisteredRouter, useLocation } from '@tanstack/react-router';
import { Modal, ModalContent, ModalFooterActions, ModalFooterActionsProps, ModalTitle } from '../Modal';

// Default Prompt always has 2 buttons: Cancel (stay) and Confirm (navigating)
// Default Cancel button = stay, Submit button = navigate
// Use custom props function when you want to custom button onClick event
export type PromptBlockerResolver = ReturnType<typeof useBlocker<RegisteredRouter, true>>;
type FooterButtonProps = ModalFooterActionsProps['submitButtonProps'];
type PromptFooterButtonProps = FooterButtonProps | ((props: PromptBlockerResolver) => FooterButtonProps);

export interface PromptProps {
  id?: string;
  when: boolean; // Auto toggle Prompt when navigating
  open?: boolean; // Toggle Prompt manually
  title?: ReactNode;
  className?: string;
  children: ReactNode;
  limitPaths?: ReadonlyArray<LinkProps['to']>; // Limit next pathnames which can trigger this Prompt, default any path can trigger this Prompt
  cancelButtonProps?: PromptFooterButtonProps;
  submitButtonProps?: PromptFooterButtonProps;
}

// TODO: Some cases missed, do more later
export const Prompt = ({
  title,
  children,
  className,
  open = false,
  when = false,
  limitPaths = [],
  cancelButtonProps,
  submitButtonProps,
  id = 'prompt-dialog-container'
}: PromptProps) => {
  const { pathname } = useLocation();
  const blockerResolver = useBlocker({
    disabled: !when,
    withResolver: true, // help return object instead of void when calling useBlocker
    shouldBlockFn: ({ next }) => {
      const isIgnoreCurrentPath = pathname === next.pathname;
      const isNotMatchLimitPaths =
        !!limitPaths.length &&
        // Remove trailing slash
        limitPaths.findIndex((path) => path?.replace(/\/$/, '') === next.fullPath.replace(/\/$/, '')) === -1;

      // Ignore current path and paths which not in limitPaths
      if (isIgnoreCurrentPath || isNotMatchLimitPaths) {
        return false;
      }

      return true;
    }
  });

  const { proceed, reset, status } = blockerResolver;
  const customCancelButtonProps =
    typeof cancelButtonProps === 'function'
      ? cancelButtonProps(blockerResolver)
      : {
          ...cancelButtonProps,
          onClick: (e: MouseEvent<HTMLElement>) => {
            reset?.(); // Auto reset to close prompt
            cancelButtonProps?.onClick?.(e); // Manual close prompt
          }
        };
  const customSubmitButtonProps =
    typeof submitButtonProps === 'function'
      ? submitButtonProps(blockerResolver)
      : { ...submitButtonProps, onClick: proceed };

  return status === 'blocked' || open ? (
    <Modal
      hasCloseIcon={false} // No close icon
      className={className}
      shouldCloseOnOverlayClick={false}
      parentSelector={() => document.getElementById(id) || document.body}
      open
    >
      <ModalContent>
        {title ? <ModalTitle>{title}</ModalTitle> : null}
        {children}
      </ModalContent>

      <ModalFooterActions cancelButtonProps={customCancelButtonProps} submitButtonProps={customSubmitButtonProps} />
    </Modal>
  ) : null;
};
