import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateAdvertiserUser.graphql';
import { Mutation, MutationcreateAdvertiserUserArgs } from '../../__generated__/globalTypes';

export const useCreateAdvertiserUserMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateAdvertiserUserArgs>
) => {
  const [callCreateAdvertiserUser, result] = useMutation<Mutation, MutationcreateAdvertiserUserArgs>(MUTATION, options);

  return { callCreateAdvertiserUser, ...result };
};

export type CreateAdvertiserUserMutationFunction = Mutation['createAdvertiserUser'];
