import { TableData } from '@/shared/types';
import { RenderDataWithFallback } from '@/shared/atoms';
import { TikTokSpecialPackageInfluencer } from '@/graphql';
import { ProfileWidget } from './ProfileWidget';

export type TikTokCampaignInfluencersListProps = TableData<TikTokSpecialPackageInfluencer>;

export const TikTokCampaignInfluencersList = ({ loading, data = [] }: TikTokCampaignInfluencersListProps) => (
  <RenderDataWithFallback loading={loading} hasNoData={data?.length === 0}>
    {data.map((profile, index) => (
      <ProfileWidget profile={profile} key={`${profile.id}-${index}`} />
    ))}
  </RenderDataWithFallback>
);
