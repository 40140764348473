import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/BulkUpdateEngagementInfluencersBudget.graphql';
import { Mutation, MutationbulkUpdateEngagementInfluencersBudgetArgs } from '../../__generated__/globalTypes';

export const useBulkUpdateEngagementInfluencersBudgetMutation = (
  options?: MutationHookOptions<Mutation, MutationbulkUpdateEngagementInfluencersBudgetArgs>
) => {
  const [callBulkUpdateEngagementInfluencersBudget, result] = useMutation<
    Mutation,
    MutationbulkUpdateEngagementInfluencersBudgetArgs
  >(MUTATION, options);

  return { callBulkUpdateEngagementInfluencersBudget, ...result };
};

export type BulkUpdateEngagementInfluencersBudgetMutationFunction = Mutation['bulkUpdateEngagementInfluencersBudget'];
