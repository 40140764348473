import { QueryHookOptions } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Query, useAllAnalyticsSubscriptionPlansQuery } from '@/graphql';
import { ANALYTICS_SUBSCRIPTION_TRANSLATION_KEYS } from '@/shared/constants';
import { Option } from '@/shared/types';

interface Props extends QueryHookOptions<Query> {
  hasNoPlanOption?: boolean;
}

export const useAllAnalyticsSubscriptionPlansOptions = (
  { hasNoPlanOption, ...options }: Props = { hasNoPlanOption: false }
) => {
  const { t } = useTranslation();
  const { data, loading } = useAllAnalyticsSubscriptionPlansQuery({ fetchPolicy: 'cache-first', ...options });

  const noPlanOption: Option = { label: t<string>('Option.No plan subscribed'), value: '' };
  const apiPlanOptions: Option[] =
    data?.allAnalyticsSubscriptionPlans?.map((plan) => ({
      value: plan.type,
      label: t(ANALYTICS_SUBSCRIPTION_TRANSLATION_KEYS[plan.type] || '') || plan.name || ''
    })) || [];
  const allSubscriptionPlanOptions = hasNoPlanOption ? [noPlanOption, ...apiPlanOptions] : apiPlanOptions;

  return { loading, allSubscriptionPlanOptions };
};
