import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { useAnalyticsConnectSocialAccount } from '../hooks';
import { AnalyticsFormValues } from '../schemaTypes';
import { SocialAccount } from './SocialAccount';

export const SocialAccounts = () => {
  const { t } = useTranslation();
  const { watch, register } = useFormContext<AnalyticsFormValues>();
  const { loading, handleConnect, handleReconnect } = useAnalyticsConnectSocialAccount();

  const [igAccount, ytAccount, twAccount, fbAccount] = watch(['instagram', 'youtube', 'twitter', 'facebookPage']);

  return (
    <div css={{ padding: '24px' }}>
      <FormStyle.SectionTitle index={1} label={t('Connect Social Accounts')} />

      <SocialAccount
        loading={loading}
        socialMedia="INSTAGRAM"
        onSignIn={handleConnect}
        onReconnect={handleReconnect}
        account={
          igAccount
            ? {
                needReconnect: igAccount.needReconnect,
                name: igAccount.instagramAccountName || '',
                linkedAccounts: [{ name: igAccount.fbPageName, type: 'FACEBOOK' }]
              }
            : undefined
        }
      />
      <SocialAccount
        loading={loading}
        socialMedia="YOUTUBE"
        onSignIn={handleConnect}
        onReconnect={handleReconnect}
        account={ytAccount ? { needReconnect: ytAccount.needReconnect, name: ytAccount.channelName } : undefined}
      />
      <SocialAccount
        loading={loading}
        socialMedia="FACEBOOK_PAGE"
        onSignIn={handleConnect}
        onReconnect={handleReconnect}
        account={fbAccount ? { needReconnect: fbAccount.needReconnect, name: fbAccount.fbPageName } : undefined}
      />
      {/* we need to register twitter to trigger setValue update on UI https://react-hook-form.com/docs/useform/setvalue */}
      <div {...register('twitter')} />
      <SocialAccount
        loading={loading}
        socialMedia="TWITTER"
        onSignIn={handleConnect}
        onReconnect={handleReconnect}
        account={twAccount ? { needReconnect: twAccount.needReconnect, name: twAccount.name } : undefined}
      />
    </div>
  );
};
