import { useAllInstagramSimilarInfluencersV3LazyQuery } from '@/graphql';
import { DEFAULT_SIMILAR_INFLUENCERS_LIMITATION } from '@/shared/constants';
import { getValuableVariables } from '@/shared/utils';
import { formatInfluencerProfile } from '../utils';

interface Props {
  username?: string;
  socialAccountId?: string;
}

export const useInstagramSimilarInfluencersList = ({ username, socialAccountId }: Props) => {
  const variables = getValuableVariables({
    username,
    limit: DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.LIST.FETCH,
    accountId: socialAccountId ? Number(socialAccountId) : null
  });
  const { data, loading, refetch, getAllInstagramSimilarInfluencersV3 } = useAllInstagramSimilarInfluencersV3LazyQuery({
    variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  const similarInfluencers = data?.allInstagramSimilarInfluencersV3 || [];

  return {
    loading,
    onRefetchData: refetch,
    limit: variables.limit,
    totalRecords: similarInfluencers.length || 0,
    onFetchData: getAllInstagramSimilarInfluencersV3,
    listRecords: similarInfluencers?.map(formatInfluencerProfile) || []
  };
};
