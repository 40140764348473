import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DisconnectPackageFromEngagementProposal.graphql';
import { Mutation, MutationdisconnectPackageFromEngagementProposalArgs } from '../../__generated__/globalTypes';

export const useDisconnectPackageFromEngagementProposalMutation = (
  options?: MutationHookOptions<Mutation, MutationdisconnectPackageFromEngagementProposalArgs>
) => {
  const [callDisconnectPackageFromEngagementProposal, result] = useMutation<
    Mutation,
    MutationdisconnectPackageFromEngagementProposalArgs
  >(MUTATION, options);

  return { callDisconnectPackageFromEngagementProposal, ...result };
};

export type DisconnectPackageFromEngagementProposalMutationFunction =
  Mutation['disconnectPackageFromEngagementProposal'];
