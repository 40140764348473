import { useState } from 'react';
import { Options } from 'react-select';
import debounce from 'lodash/debounce';
import { GenericOption } from '@/shared/types';
import { useQueryHelper } from '@/shared/hooks';
import { useAllAdvertisersForSearchPromiseQuery } from '@/graphql';

export const useAdvertisersLoadOptions = (includeIds?: string[]) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { getAllAdvertisersForSearch } = useAllAdvertisersForSearchPromiseQuery();
  const [allAdvertisersOptions, setAllAdvertisersOptions] = useState<GenericOption<string>[]>([]);

  const loadAdvertisersOptions = async (inputValue: string, callback: (options: Options<any>) => void) => {
    if (inputValue.length >= 1) {
      let entitiesList = [] as GenericOption<string>[];
      try {
        const { data, errors } = await getAllAdvertisersForSearch({
          variables: { keyword: inputValue }
        });
        entitiesList =
          data.allAdvertisersForSearch.advertisers.map((o) => ({ value: o.id.toString(), label: o.name })) || [];

        if (errors?.length) {
          enqueueSnackbar(t(errors.at(0)?.message || ''), { variant: 'error' });
        }

        callback(entitiesList.length ? entitiesList : []);
        setAllAdvertisersOptions(entitiesList.length ? entitiesList : []);
      } catch (error) {
        enqueueSnackbar(t((error as any).message), { variant: 'error' });
      }
    }
  };

  // we can't inline debounce func in render, it will break debounce logic each renred
  const debouncedLoadAdvertisersOptions: (searchTerm: string, callback: (options: Options<any>) => void) => void =
    debounce((searchTerm, callback) => {
      loadAdvertisersOptions(searchTerm, callback);
    }, 300);

  return {
    allAdvertisersOptions,
    loadAdvertisersOptions,
    debouncedLoadAdvertisersOptions
  };
};
