import { z } from 'zod';
import { PostListSortField, XhsPostType } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
import { LIMIT } from '@/shared/constants';

export const InfluencerXhsPostsFilterSchema = z.object({
  postType: z.array(z.nativeEnum(XhsPostType)).default([]),
  keyword: z.string().optional()
});

export const InfluencerXhsPostsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(InfluencerXhsPostsFilterSchema, {
    postType: [],
    keyword: ''
  }),
  sort: SearchFilterDefaults.sort(PostListSortField).default({}),
  ...SearchFilterDefaults.pageLimit(LIMIT)
});

export type InfluencerXhsPostsSearchSchemaType = z.infer<typeof InfluencerXhsPostsSearchSchema>;
