import { useTranslation } from 'react-i18next';
import { getValuableVariables } from '@/shared/utils';
import { SectionWithHeader } from '@/shared/molecules';
import { useYoutubeCompareTagRankingQuery, YoutubeCompareTagRanking } from '@/graphql';
import { HAS_NO_SELECTED_ACCOUNTS } from '@/shared/constants';
import { NoDataContentWrapper, RenderDataWithFallback } from '@/shared/atoms';
import { AnalyticsCompareYoutubeAccountsProps } from '../AnalyticsCompareYoutubeAccounts';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { Template } from './Template';

export interface AnalyticsCompareYoutubeTagsRankingProps
  extends Pick<
    AnalyticsCompareYoutubeAccountsProps,
    'accounts' | 'mainAccountId' | 'hiddenAccountIds' | 'hasFreePlan'
  > {
  tagsRankingMock: YoutubeCompareTagRanking;
}

export const AnalyticsCompareYoutubeTagsRanking = ({
  accounts,
  mainAccountId,
  hiddenAccountIds,
  hasFreePlan,
  tagsRankingMock
}: AnalyticsCompareYoutubeTagsRankingProps) => {
  const { t } = useTranslation();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useYoutubeCompareTagRankingQuery({
    variables: getValuableVariables({
      mainAccountId,
      endDate: filter.endDate,
      startDate: filter.startDate
    }),
    skip: hasFreePlan
  });

  const compareTagsData = hasFreePlan ? tagsRankingMock : data?.youtubeCompareTagRanking;
  const comparableAccounts = accounts.filter((account) => !hiddenAccountIds?.includes(account.id));
  const hasNoData = !comparableAccounts.length || !compareTagsData;

  return (
    <div css={{ marginTop: '24px' }}>
      <RenderDataWithFallback loading={loading}>
        <SectionWithHeader title={t('Tag Ranking')} prefixIcon={{ icon: 'user-two-tones' }}>
          <NoDataContentWrapper
            dataNotAvailable={hasNoData}
            wrapperText={HAS_NO_SELECTED_ACCOUNTS}
            css={!hasNoData && { minHeight: 'unset' }}
          >
            {compareTagsData ? <Template data={compareTagsData} comparableAccounts={comparableAccounts} /> : null}
          </NoDataContentWrapper>
        </SectionWithHeader>
      </RenderDataWithFallback>
    </div>
  );
};
