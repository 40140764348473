import { css } from '@emotion/react';
import { linkOptions } from '@tanstack/react-router';
import { useUserRoles, useReportPermissions, useAdvertiserPermissions, useEngagementPermissions } from '@/auth';
import { Engagement, EngagementCampaignStatus, namedOperations, useUpdateEngagementStatusMutation } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useTranslateCountry } from '@/shared/hooks';
import { SpreadButton } from '@/shared/molecules';
import { formatNumber } from '@/shared/utils';
import { EngagementStatus } from '../../EngagementStatus';

interface Props {
  data: readonly Engagement[];
  onDeleteEngagement: (egg: { id: number; name: string }) => void;
}

export const useEngagementCampaignsTable = ({ data, onDeleteEngagement }: Props) => {
  const { tc } = useTranslateCountry();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { hideReportLink } = useReportPermissions();
  const { hideEditAdvertiserBtn } = useAdvertiserPermissions();
  const { hideEditEngagementBtn, hideDeleteEngagementBtn } = useEngagementPermissions();
  const { isAdvertiser, isAdminStaff, isAdminStaffAgency, isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { callUpdateEngagementStatus, loading } = useUpdateEngagementStatusMutation({
    refetchQueries: [namedOperations.Query.AllEngagements]
  });

  const handleUpdateEngagementStatus = async ({ id, status }: { id: number; status: EngagementCampaignStatus }) => {
    try {
      await callUpdateEngagementStatus({ variables: { input: { id, status } } });
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Campaign Title'), sticky: { active: true, hasBorderRight: true } },
    { title: t('HeaderColumn.Country') },
    { title: t('HeaderColumn.Status') },
    { title: t('HeaderColumn.Advertiser'), hidden: !isAdminStaffAgency },
    { title: t('HeaderColumn.Date Range') },
    { title: t('HeaderColumn.Budget'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Sales PIC'), hidden: !isAdminStaffTalentAgencyPartner },
    { title: t('HeaderColumn.Influencer Management PIC'), hidden: !isAdminStaffTalentAgencyPartner }
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      title,
      status,
      budget,
      endDate,
      currency,
      salesPics,
      countries,
      startDate,
      advertiser,
      influencerManagementPics
    }) => {
      const eggId = id.toString();
      const isDraft = status === EngagementCampaignStatus.DRAFT;
      const proposalPageLink =
        // Advertisers can't edit Proposal for the DRAFT campaigns
        isAdvertiser && isDraft
          ? linkOptions({ to: '/engagement/$id', params: { id: eggId } })
          : linkOptions({ to: '/engagement/$id/proposal', params: { id: eggId } });
      const filteredCountries = countries.filter((el) => el !== null) as Array<{ name: string; id: string }>;
      const translatedCountries = filteredCountries.map(({ name }) => tc(name)).join(', ');

      /* eslint-disable react/jsx-key */
      return [
        <div css={styles.nameColumnWrapper}>
          <TableCell.Link label={title} {...proposalPageLink} />
          <SpreadButton
            collapseFrom={2}
            options={[
              {
                icon: 'post',
                label: t('Posts'),
                tooltip: { help: t('Button.Posts') },
                to: '/engagement/$id/posts',
                params: { id: eggId },
                disabled: [EngagementCampaignStatus.DRAFT, EngagementCampaignStatus.DEMO].includes(status)
              },
              {
                icon: 'chart',
                label: t('Report'),
                hidden: hideReportLink,
                tooltip: { help: t('Button.Report') },
                to: '/engagement/$id/report',
                params: { id: eggId },
                disabled: [
                  EngagementCampaignStatus.DRAFT,
                  EngagementCampaignStatus.REVIEWING,
                  EngagementCampaignStatus.UPCOMING,
                  EngagementCampaignStatus.DEMO
                ].includes(status)
              },
              {
                icon: 'edit',
                label: t('Edit'),
                hidden: hideEditEngagementBtn,
                to: '/engagement/$id',
                params: { id: id.toString() }
              },
              {
                icon: 'delete',
                label: t('Delete'),
                hidden: hideDeleteEngagementBtn,
                onClick: () => onDeleteEngagement({ id, name: title }),
                disabled: !isAdminStaff || status === EngagementCampaignStatus.FINISHED
              },
              {
                icon: 'flag',
                hidden: !isAdminStaff,
                label: t('Button.Mark as Lost'),
                onClick: () => handleUpdateEngagementStatus({ id, status: EngagementCampaignStatus.LOST }),
                disabled:
                  loading || ![EngagementCampaignStatus.REVIEWING, EngagementCampaignStatus.DRAFT].includes(status)
              },
              {
                icon: 'flag',
                hidden: !isAdminStaff,
                label: t('Button.Mark as Draft'),
                disabled: loading || status !== EngagementCampaignStatus.LOST,
                onClick: () => handleUpdateEngagementStatus({ id, status: EngagementCampaignStatus.DRAFT })
              }
            ]}
          />
        </div>,
        <TableCell.Text value={translatedCountries} />,
        <EngagementStatus status={status} />,
        <TableCell.Link
          label={advertiser.name}
          disabled={hideEditAdvertiserBtn}
          to="/advertiser/$id"
          params={{ id: advertiser.id.toString() }}
        />,
        <TableCell.Period period={{ startDate, endDate }} />,
        <TableCell.Number value={formatNumber(budget)} unit={currency ? t(currency) : undefined} />,
        <TableCell.Text
          value={salesPics
            ?.slice(0, 2)
            ?.map((pic) => pic.name)
            ?.join(', ')}
        />,
        <TableCell.Text
          value={influencerManagementPics
            ?.slice(0, 2)
            ?.map((pic) => pic.name)
            ?.join(', ')}
        />
      ];
    }
  );

  return { rows, columns };
};

const styles = {
  nameColumnWrapper: css({
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  })
};
