import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ChangeInstagramUGCManagementPostStatus.graphql';
import { Mutation, MutationchangeInstagramUGCManagementPostStatusArgs } from '../../__generated__/globalTypes';

export const useChangeInstagramUGCManagementPostStatusMutation = (
  options?: MutationHookOptions<Mutation, MutationchangeInstagramUGCManagementPostStatusArgs>
) => {
  const [callChangeInstagramUGCManagementPostStatus, result] = useMutation<
    Mutation,
    MutationchangeInstagramUGCManagementPostStatusArgs
  >(MUTATION, options);

  return { callChangeInstagramUGCManagementPostStatus, ...result };
};

export type ChangeInstagramUGCManagementPostStatusMutationFunction = Mutation['changeInstagramUGCManagementPostStatus'];
