import { FilterProvider } from '@/shared/molecules';
import { useTwitterInfluencersContext } from '../TwitterInfluencersContext';
import { BasicFilter } from './BasicFilter';

export const TwitterInfluencersFilter = () => {
  const { filter, setFilter, isEngagementPage, isPackagePage } = useTwitterInfluencersContext();

  return (
    <FilterProvider
      onSubmit={setFilter}
      initialValues={filter}
      basicFilter={<BasicFilter isEngagementPage={isEngagementPage} isPackagePage={isPackagePage} />}
    />
  );
};
