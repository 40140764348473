import { useEffect, useRef } from 'react';

export const useDebounce = () => {
  const timeout = useRef<NodeJS.Timeout>();

  const debounce = (callback: () => Promise<void> | void, delay = 500) => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(callback, delay);
  };

  useEffect(
    () => () => {
      clearTimeout(timeout.current);
    },
    []
  );

  return { debounce };
};
