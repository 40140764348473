import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { FormStyle, ErrorMessage } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAllAvailableCountryOptions } from '@/shared/hooks';
import {
  TextField,
  SelectorField,
  PasswordField,
  CheckBoxField,
  CustomerValues,
  BigQuerySearchField
} from '@/shared/molecules';
import { getSubscriptionTypeLabel } from '@/shared/utils';
import { AdvertiserSearchSelector } from './AdvertiserSearchSelector';
import { SingleSelectOption } from './AdvertiserSearchSelector/SingleCreatableSelect';
import { AdvertiserFormKeys, AdvertiserFormValues } from './types';

interface TemplateProps {
  className?: string;
}
export const Template = ({ className }: TemplateProps) => {
  const { t } = useTranslation();
  const { isAdminStaff, isTalentAgencyPartner } = useUserRoles();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions();
  const {
    watch,
    reset,
    setValue,
    formState: { isSubmitting, errors }
  } = useFormContext<AdvertiserFormValues>();

  const values = watch();
  const isAddMode = values?.type === 'add';
  const canSelectNetSuite = isAdminStaff;
  const planTitle = getSubscriptionTypeLabel(isAddMode ? undefined : values.subscriptionType);
  const isRequiredUserFields =
    isAddMode && !!(values.userName || values.email || values.password || values.passwordConfirm);
  const isDisabledForExistingAdvertiser = isAddMode && values.isNewlyCreated === false;

  const handleClickSendEmail = (_: string, value: boolean) => {
    if (isAddMode) {
      // we need to clear password fields each time user click send email to prevent validation err on password
      reset({ ...values, password: '', passwordConfirm: '', shouldSendInvitation: value });
    }
  };

  const handleHubSpotChange = (field: AdvertiserFormKeys, customerValues: CustomerValues) => {
    setValue(field, customerValues.value || '');
    setValue('advertiserName', customerValues.label);
    setValue('internalNetsuiteId', customerValues.internalNetsuiteId || '');
  };
  const handleAdvertiserChange = (option: SingleSelectOption) => {
    if (option) {
      setValue('advertiserName', option.label || '', { shouldValidate: true });
      setValue('advertiserId', +option.value ? Number(option.value) : option.value, { shouldValidate: true });
      setValue('isNewlyCreated', option?.isNew);
      setValue('companyUrl', option.companyUrl || '');
      setValue('phoneNumber', option.phoneNumber || '');
      setValue('countryId', option.countryId || '', { shouldValidate: true });
      setValue('hubspotId', option.isNew ? null : option.hubspotId !== undefined ? option.hubspotId : ''); // setting `null` means that we want to clear hubspot selector
    }
  };

  return (
    <div className={className} css={styles.wrapper}>
      <FormStyle.Wrapper
        className="advertiser-form-wrapper"
        css={{ margin: 0, flex: 1, borderRadius: 'inherit' }}
        hasBorder
      >
        <div css={{ padding: '24px' }}>
          <FormStyle.SectionTitle index={isAddMode ? 1 : undefined} label={t('Company Information')} />

          {canSelectNetSuite ? (
            <BigQuerySearchField<AdvertiserFormKeys>
              name="hubspotId"
              type="hubspotCustomer"
              onChange={handleHubSpotChange}
              disabled={isDisabledForExistingAdvertiser}
            />
          ) : null}

          {isAddMode ? (
            <FormStyle.FieldWrapper>
              <AdvertiserSearchSelector
                title={t('Selector.Advertiser Name')}
                help={t<string>('Tooltip.Advertiser Name')}
                placeholder={t('TextForm.AdAsiaLtd')}
                setFieldValue={handleAdvertiserChange}
                value={{ label: values.advertiserName, value: values.advertiserId?.toString() || '' }}
                required
                hasError={!!errors.advertiserName?.message}
              />
              <ErrorMessage message={t<string>(errors.advertiserName?.message || '')} />
            </FormStyle.FieldWrapper>
          ) : (
            <TextField<keyof AdvertiserFormValues>
              name="advertiserName"
              help={'Advertiser Name'}
              title={t('TextForm.Advertiser Name')}
              placeholder={t<string>('TextForm.AdAsiaLtd')}
              required
            />
          )}

          <TextField<AdvertiserFormKeys>
            name="companyUrl"
            help={t('Tooltip.Company URL')}
            title={t('TextForm.Company URL')}
            placeholder="https://anymindgroup.com"
            disabled={isDisabledForExistingAdvertiser}
          />

          <FormStyle.FieldsGroup itemsPerRow={2}>
            <TextField<AdvertiserFormKeys>
              name="phoneNumber"
              help={t('Tooltip.Phone Number')}
              placeholder="+66020485707"
              title={t('TextForm.Phone Number')}
              disabled={isDisabledForExistingAdvertiser}
            />
            <SelectorField<AdvertiserFormKeys>
              title={t('Selector.Country')}
              name="countryId"
              help={t('Tooltip.Advertiser Country')}
              required
              options={allAvailableCountryOptions}
              disabled={isDisabledForExistingAdvertiser}
            />
          </FormStyle.FieldsGroup>

          {isAddMode ? (
            <>
              <FormStyle.SectionTitle index={2} label={t('User Information')} css={{ marginTop: '24px' }} />

              <TextField<AdvertiserFormKeys>
                name="userName"
                title={t('TextForm.Name')}
                required={isRequiredUserFields}
                placeholder={t<string>('NameExample')}
              />

              <FormStyle.FieldsGroup itemsPerRow={2}>
                <TextField<AdvertiserFormKeys>
                  name="email"
                  placeholder="your@email.com"
                  required={isRequiredUserFields}
                  title={t('TextForm.Email Address')}
                />
              </FormStyle.FieldsGroup>

              {!values.shouldSendInvitation && (
                <FormStyle.FieldsGroup itemsPerRow={2}>
                  <PasswordField<AdvertiserFormKeys>
                    name="password"
                    title={t('Password.Password')}
                    required={isRequiredUserFields}
                    dynamicError
                  />

                  <PasswordField<AdvertiserFormKeys>
                    name="passwordConfirm"
                    required={isRequiredUserFields}
                    title={t('Password.Confirm Password')}
                  />
                </FormStyle.FieldsGroup>
              )}

              <CheckBoxField<AdvertiserFormKeys>
                name="shouldSendInvitation"
                label={t('Send invitation email')}
                onChange={handleClickSendEmail}
              />
            </>
          ) : null}
        </div>

        <FormStyle.ButtonSubmitWrapper>
          <FormStyle.ButtonSubmit type="submit" title={t('Button.Save')} loading={isSubmitting} />
        </FormStyle.ButtonSubmitWrapper>
      </FormStyle.Wrapper>
      {/* Account Manager Plan */}
      {isTalentAgencyPartner && (
        <div css={styles.accountManagerPlan}>
          <h2 css={{ fontSize: '18px', fontWeight: 600 }}>{t('Account Manager Plan')}</h2>
          {planTitle ? (
            <span css={styles.accountPlan}>{t(planTitle)}</span>
          ) : (
            <span css={{ fontSize: '14px', fontWeight: 400 }}>{t('No plan subscription')}</span>
          )}
          <p css={{ fontSize: '12px', fontWeight: 400, color: THEME.text.colors.gray.lv3 }}>
            {t('Please contact our staff to upgrade subscriptions')}
          </p>
        </div>
      )}
    </div>
  );
};
const styles = {
  wrapper: css({
    display: 'flex',
    width: '100%',
    borderRadius: '0 0 3px 3px',
    boxShadow: THEME.shadows.boxShadow
  }),
  header: css({ display: 'flex', color: THEME.text.colors.black.lv1, fontSize: '18px', alignItems: 'center' }),
  accountManagerPlan: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    width: '320px',
    padding: '24px',
    marginLeft: '24px',
    borderRadius: '3px',
    height: 'max-content',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5)'
  }),
  accountPlan: css({
    fontWeight: 600,
    fontSize: '16px',
    margin: '0 auto'
  })
};
