import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { NoDataTitle, NoDataContentWrapper, ChartTooltipSingleMetric, NoDataContentWrapperProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatPercent, getEstimatedWrapperText } from '@/shared/utils';
import { HighChart, Highcharts } from '../HighChart';

interface AgeBreakdownData {
  maleRate?: readonly number[] | null;
  otherRate?: readonly number[] | null;
  totalRate?: readonly number[] | null;
  femaleRate?: readonly number[] | null;
  ageGroup?: readonly { start?: number; end?: number }[] | null;
}

export interface AgeBreakdownChartProps
  extends AgeBreakdownData,
    Pick<Highcharts.ChartOptions, 'width'>,
    Pick<NoDataContentWrapperProps, 'header' | 'className' | 'wrapperLink' | 'wrapperText'> {
  hasEstimatedDemographics?: boolean;
  xAxisCategories?: string[];
}

export const AgeBreakdownChart = ({
  width,
  header,
  ageGroup,
  maleRate,
  otherRate,
  totalRate,
  femaleRate,
  wrapperText,
  hasEstimatedDemographics,
  xAxisCategories,
  ...noDataContentProps
}: AgeBreakdownChartProps) => {
  const { t } = useTranslation();

  const options: Highcharts.Options = {
    title: { text: '' },
    chart: { width, type: 'bar', height: 305 },
    plotOptions: { series: { stacking: 'normal' } },
    xAxis: {
      categories: xAxisCategories || ageGroup?.map((item) => `${item.start}-${item.end}`) || []
    },
    yAxis: {
      min: 0,
      title: { text: '' },
      labels: {
        formatter() {
          return formatPercent(Number(this.value), true, 0);
        }
      }
    },
    legend: {
      reversed: true,
      symbolWidth: 15,
      symbolRadius: 2,
      symbolHeight: 15,
      itemMarginBottom: 6,
      enabled: !totalRate?.length,
      itemStyle: { fontSize: '13px', color: THEME.text.colors.black.lv1, fontWeight: 'normal' }
    },
    series: totalRate
      ? [
          {
            type: 'bar',
            name: t<string>('Total'),
            data: [...(totalRate || [])],
            color: THEME.chart.colors.gender.total
          }
        ]
      : [
          ...(otherRate
            ? [
                {
                  name: t<string>('Other'),
                  data: [...(otherRate || [])],
                  color: THEME.chart.colors.gender.other,
                  type: 'bar' as Highcharts.SeriesBarOptions['type']
                }
              ]
            : []),
          { type: 'bar', color: THEME.chart.colors.gender.male, data: [...(maleRate || [])], name: t<string>('Male') },
          {
            type: 'bar',
            name: t<string>('Female'),
            data: [...(femaleRate || [])],
            color: THEME.chart.colors.gender.female
          }
        ],
    tooltip: {
      padding: 0,
      useHTML: true,
      borderWidth: 0,
      borderRadius: 3,
      borderColor: THEME.chart.tooltip.border.colors.green,
      backgroundColor: THEME.chart.tooltip.background.colors.black,
      style: { color: THEME.chart.tooltip.text.colors.white },
      formatter(): string {
        return renderToStaticMarkup(
          <ChartTooltipSingleMetric
            category={this.category}
            name={this.series.name}
            color={this.color as string}
            value={formatPercent(this.y)}
          />
        );
      }
    }
  };

  return (
    <NoDataContentWrapper
      css={{ padding: '24px 16px' }}
      wrapperText={getEstimatedWrapperText({ type: 'age', wrapperText, hasEstimatedDemographics })}
      dataNotAvailable={!femaleRate?.length && !maleRate?.length && !otherRate?.length && !totalRate?.length}
      header={
        header ?? (
          <NoDataTitle
            css={{ marginBottom: '15px' }}
            title={t(hasEstimatedDemographics ? 'Estimated Age' : 'Age Breakdown')}
            help={!hasEstimatedDemographics ? t<string>('Tooltip.ageBreakdown') : undefined}
          />
        )
      }
      {...noDataContentProps}
    >
      <HighChart options={options} />
    </NoDataContentWrapper>
  );
};
