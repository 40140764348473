import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { TiktokInfluencersProps } from './schemaTypes';
import { TiktokInfluencersSort } from './TiktokInfluencersSort';
import { TiktokInfluencersList } from './TiktokInfluencersList';
import { TiktokInfluencersFilter } from './TiktokInfluencersFilter';
import { TiktokInfluencersProvider } from './TiktokInfluencersContext';

export const TiktokInfluencers = (props: TiktokInfluencersProps) => (
  <TiktokInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <TiktokInfluencersFilter />
      <TiktokInfluencersSort />
    </InfluencersFilterWrapper>

    <TiktokInfluencersList />
  </TiktokInfluencersProvider>
);

export * from './schemaTypes';
