import { QueryHookOptions } from '@apollo/client';

import { useUserRoles } from '@/auth';
import { Query, useAllPicUsersQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';

export const useAllPicUserOptions = (options?: QueryHookOptions<Query>) => {
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { data } = useAllPicUsersQuery({
    skip: !isAdminStaffTalentAgencyPartner,
    fetchPolicy: 'cache-and-network',
    ...options
  });

  return { allPicsUserOptions: getOptions(data?.allPicUsers) };
};
