import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CampaignSocialMediaType } from '@/graphql';
import { TextField, TextFieldProps } from '@/shared/molecules';

import { MarketplaceFormValues, MarketplaceFormKeys } from '../types';

export const CostPerLikeField = (props: Omit<TextFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<MarketplaceFormValues>();

  const [socialMediaValue, currency] = watch([MarketplaceFormKeys.socialMedia, MarketplaceFormKeys.currency]);
  const likeOrReactionLabel = socialMediaValue === CampaignSocialMediaType.FACEBOOK ? 'Reaction' : 'Like';

  return (
    <TextField
      placeholder="3.5"
      unit={t(currency)}
      name={MarketplaceFormKeys.costPerLike}
      help={t(`Tooltip.Cost ${likeOrReactionLabel} Help`)}
      title={t(`TextForm.Cost per ${likeOrReactionLabel}`)}
      {...props}
    />
  );
};
