import { useInfluencerTwitterAnalyticsPostQuery, useTwitterAnalyticsPostQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InfluencerTwitterAnalyticsPostDetailsProps
  extends Pick<PostDetailsType, 'id' | 'mainSocialAccountId'> {
  className?: string;
  isAnalytics?: boolean;
  onCloseModal?: () => void;
}

export const InfluencerTwitterAnalyticsPostDetails = ({
  id,
  className,
  isAnalytics,
  onCloseModal,
  mainSocialAccountId
}: InfluencerTwitterAnalyticsPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useInfluencerTwitterAnalyticsPostQuery({
    skip: !id || !mainSocialAccountId || isAnalytics,
    variables: { twitterAnalyticsPostId: Number(id), socialAccountId: Number(mainSocialAccountId) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const { data: analyticsData, loading: fetchingAnalyticsData } = useTwitterAnalyticsPostQuery({
    skip: !id || !mainSocialAccountId || !isAnalytics,
    variables: { twitterAnalyticsPostId: Number(id), twitterAnalyticsAccountId: Number(mainSocialAccountId) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const details = data?.influencerTwitterAnalyticsPost || analyticsData?.twitterAnalyticsPost;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'TWITTER',
        postUrl: details?.post?.postUrl,
        caption: details?.post?.content,
        images: details?.post?.images || [],
        comments:
          details?.post?.comments?.map((comment) => ({ content: comment.content, username: comment.posterName })) || [],
        statistics: {
          likes: details?.post?.like,
          views: details?.post?.view,
          replies: details?.post?.reply,
          retweets: details?.post?.retweet,
          quoteTweet: details?.post?.quoteTweet,
          impressions: details?.post?.impression,
          engagement: details?.post?.engagement
        },
        info: {
          socialMedia: 'TWITTER',
          name: details?.username,
          avatar: details?.avatar,
          followers: details?.followers,
          postDate: details?.post?.postDate
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      className={className}
      postDetails={postDetails}
      css={{ width: THEME.modal.size.lv3 }}
      loading={loading || fetchingAnalyticsData}
    />
  );
};
