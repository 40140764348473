import { LIMIT_30 } from '@/shared/constants';
import { convertOrderToORDER } from '@/shared/utils';
import { InfluencerInstagramAnalyticsPostDetailsModal } from '@/shared/molecules';
import { AnalyticsInstagramDashboardFeedPostsTemplate } from '@/shared/organisms';
import { Order, InstagramFeedPostSortField, useInfluencerAllInstagramFeedPostsQuery } from '@/graphql';
import { PostDetailsType } from '@/shared/types';
import { InfluencerSelectedAccountInfo } from '../../types';

interface InfluencerAnalyticsInstagramProfileFeedPostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: number;
}

export const InfluencerAnalyticsInstagramProfileFeedPosts = ({
  socialAccount: { id: socialAccountId, name },
  influencerId
}: InfluencerAnalyticsInstagramProfileFeedPostsProps) => {
  const { data } = useInfluencerAllInstagramFeedPostsQuery({
    variables: {
      offset: 0,
      limit: LIMIT_30,
      socialAccountId,
      orderBy: { field: InstagramFeedPostSortField.POST_DATE, order: convertOrderToORDER(Order.DESC) }
    }
  });

  const posts = data?.influencerAllInstagramFeedPosts || [];

  return (
    <>
      <AnalyticsInstagramDashboardFeedPostsTemplate
        data={posts}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/instagram_feed_post',
          params: { socialMediaAccountId: socialAccountId.toString(), id: influencerId.toString() },
          search: { filter: { name } }
        }}
      />

      <InfluencerInstagramAnalyticsPostDetailsModal
        posts={posts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: socialAccountId }))}
      />
    </>
  );
};
