import { namedOperations, useDeleteSocialAccountPackageMutation } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { RowPackageSocialAccountData } from '../types';

interface DeleteFromPackageProps {
  packageId?: number;
  resetSelectedItem: () => void;
}
export const useDeleteFromPackage = ({ packageId, resetSelectedItem }: DeleteFromPackageProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callDeleteSocialAccountPackage, loading: deleting } = useDeleteSocialAccountPackageMutation({
    refetchQueries: [namedOperations.Query.PackageProposal]
  });

  const handleRemoveSmAccountFromPackage = async (
    deletingItem: Pick<RowPackageSocialAccountData, 'id' | 'socialMedia' | 'name'>
  ) => {
    if (!deletingItem) return;

    try {
      await callDeleteSocialAccountPackage({
        variables: {
          packageId: Number(packageId),
          socialAccountIds: [deletingItem.id],
          socialMedia: deletingItem.socialMedia
        }
      });
      enqueueSnackbar(t('succeededInDeleting', { name: deletingItem.name }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      resetSelectedItem();
    }
  };

  return {
    deleting,
    handleRemoveSmAccountFromPackage
  };
};
