import {
  InfluencerAnalyticsTwitterProfileOverview,
  InfluencerAnalyticsTwitterProfileFeedPosts,
  InfluencerAnalyticsTwitterProfilePostsOverview,
  InfluencerAnalyticsTwitterProfileSimilarInfluencers
} from '../../InfluencerAnalyticsProfiles';
import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsTwitterProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerAnalyticsTwitterProfile = ({ socialAccount }: InfluencerAnalyticsTwitterProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerAnalyticsTwitterProfileOverview socialAccount={socialAccount} />
      <InfluencerAnalyticsTwitterProfilePostsOverview
        socialAccount={socialAccount}
        influencerId={influencerProfile.id}
      />
      <InfluencerAnalyticsTwitterProfileFeedPosts socialAccount={socialAccount} influencerId={influencerProfile.id} />
      <InfluencerAnalyticsTwitterProfileSimilarInfluencers
        socialAccount={socialAccount}
        influencerProfile={influencerProfile}
      />
    </>
  );
};
