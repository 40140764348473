import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import {
  InfluencerTiktokProfilePosts,
  InfluencerTiktokProfileOverview,
  InfluencerTiktokProfileAudience,
  InfluencerTiktokProfileSimilarInfluencers
} from '../../InfluencerProfiles';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerTiktokProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerTiktokProfile = ({ socialAccount }: InfluencerTiktokProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerTiktokProfileOverview influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerTiktokProfileAudience influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerTiktokProfilePosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
      <InfluencerTiktokProfileSimilarInfluencers influencerProfile={influencerProfile} socialAccount={socialAccount} />
    </>
  );
};
