import { z } from 'zod';
import { AddressInput } from '@/graphql';
import { getSchemaForType } from '@/shared/types';

export const AddressInformationSchema = z.object({
  addressInformation: getSchemaForType<AddressInput>()(
    z.object({
      city: z.string(),
      address: z.string(),
      address2: z.string(),
      fullName: z.string(),
      province: z.string(),
      postalCode: z.string()
    })
  ).nullable()
});
