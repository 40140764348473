import { Outlet } from '@tanstack/react-router';
import { InfluencerAISearchWidget } from '@/shared/organisms';
import { InfluencersSearchProvider } from './InfluencersSearchContext';

export const InfluencersSearch = () => (
  <InfluencersSearchProvider>
    <InfluencerAISearchWidget />
    <Outlet />
  </InfluencersSearchProvider>
);
