import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/RefreshTwitterAccountAvailability.graphql';
import { Query, QueryrefreshTwitterAccountAvailabilityArgs } from '../../__generated__/globalTypes';

export const useRefreshTwitterAccountAvailabilityQuery = (
  options?: QueryHookOptions<Query, QueryrefreshTwitterAccountAvailabilityArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryrefreshTwitterAccountAvailabilityArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useRefreshTwitterAccountAvailabilityLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryrefreshTwitterAccountAvailabilityArgs>
): QueryResult<Query, QueryrefreshTwitterAccountAvailabilityArgs> & {
  getRefreshTwitterAccountAvailability: LazyQueryExecFunction<Query, QueryrefreshTwitterAccountAvailabilityArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getRefreshTwitterAccountAvailability, result] = useLazyQuery<
    Query,
    QueryrefreshTwitterAccountAvailabilityArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getRefreshTwitterAccountAvailability, ...result };
};

export const useRefreshTwitterAccountAvailabilityPromiseQuery = () => {
  const client = useApolloClient();

  const getRefreshTwitterAccountAvailability = useCallback(
    (options?: Omit<QueryOptions<QueryrefreshTwitterAccountAvailabilityArgs, Query>, 'query'>) =>
      client.query<Query, QueryrefreshTwitterAccountAvailabilityArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getRefreshTwitterAccountAvailability };
};
