import { ReactNode, createContext, useContext } from 'react';
import { PartnerAgencyWithCompanies } from '@/graphql';
import { useGetDocumentParentHref } from '@/shared/hooks';

interface ContextType {
  partner: PartnerAgencyWithCompanies;
}

const PartnerDetailsContext = createContext<ContextType>({} as ContextType);

interface Props {
  children: ReactNode;
  values: ContextType;
}

export const PartnerDetailsProvider = ({ values: { partner }, children }: Props) => {
  useGetDocumentParentHref({
    href: '/partner',
    alwaysBackToParent: true,
    title: 'appHeader.Edit Partner',
    appHeader: partner.name ? 'appHeader.EditWithTheName' : 'appHeader.Edit Partner',
    ...(partner.name ? { interpolation: { name: partner.name } } : {})
  });

  return <PartnerDetailsContext.Provider value={{ partner }}>{children}</PartnerDetailsContext.Provider>;
};

export const usePartnerDetailsContext = () => useContext<ContextType>(PartnerDetailsContext);
