import { useTranslation } from 'react-i18next';
import { MediaPreviewWithMetrics } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { usePostDetailsParam } from '@/shared/hooks';
import { ImageSlider, InfluencerPostDetailsModal } from '@/shared/molecules';
import { bigIntFormatter } from '@/shared/utils';
import { InfluencerFeedPost, SocialPostType } from '@/graphql';
import { InfluencerProfileFeedPostsWrapper } from '../shared';

const FEED_VIDEO_PARAMS_KEY = 'feedVideoPopup';

interface InfluencerXhsProfileVideosProps {
  videoPosts: readonly InfluencerFeedPost[];
  influencerId: number;
  socialMediaAccountId: string;
  accountName: string;
}

export const InfluencerXhsProfileVideos = ({
  videoPosts,
  influencerId,
  socialMediaAccountId,
  accountName
}: InfluencerXhsProfileVideosProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam(FEED_VIDEO_PARAMS_KEY);

  return (
    <>
      <InfluencerProfileFeedPostsWrapper
        dataNotAvailable={!videoPosts.length}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/xhs_video_posts',
          params: {
            id: influencerId.toString(),
            socialMediaAccountId: socialMediaAccountId.toString()
          },
          search: {
            filter: {
              type: SocialPostType.XHS,
              name: accountName
            }
          }
        }}
      >
        <div css={{ fontSize: '16px', fontWeight: 400, lineHeight: '18px', padding: '12px 16px 0' }}>
          {t('Video Posts')}
        </div>
        <ImageSlider total={videoPosts.length} css={{ padding: '12px 16px' }}>
          {videoPosts?.map(({ id, thumbnail, likes, comments }) => (
            <MediaPreviewWithMetrics
              key={id}
              src={thumbnail}
              css={THEME.mediaPreview.igReelOrStory}
              onClick={() => setPostDetailsParam(id)}
              objectFit="cover"
              radius="8px"
              statistics={[
                { label: t('General.Like'), value: bigIntFormatter(likes, 0, { k: true, m: true }) },
                { label: t('General.Save'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
              ]}
            />
          ))}
        </ImageSlider>
      </InfluencerProfileFeedPostsWrapper>

      <InfluencerPostDetailsModal
        paramKey={FEED_VIDEO_PARAMS_KEY}
        posts={videoPosts?.map(({ id }) => ({ id, influencerId: influencerId }))}
      />
    </>
  );
};
