import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddressAndPaymentInfoPermissions } from '@/auth';
import { useAddressQuery } from '@/graphql';
import { FormStyle, Label } from '@/shared/atoms';
import { useDeepCompareEffect } from '@/shared/hooks';
import { TextField } from '@/shared/molecules';
import { defaultInfluencerFormValues } from '../../InfluencerForm';
import { AddressInformationFormValues } from './types';

interface AddressInformationProps {
  className?: string;
  hasBorder?: boolean;
  influencerId?: number;
}

export const AddressInformation = ({ className, hasBorder, influencerId }: AddressInformationProps) => {
  const { t } = useTranslation();
  const { disableAddressEditing } = useAddressAndPaymentInfoPermissions();
  const {
    setValue,
    formState: { defaultValues }
  } = useFormContext<AddressInformationFormValues>();
  const { data } = useAddressQuery({
    skip: !influencerId,
    variables: { influencerId: Number(influencerId) },
    onCompleted: ({ address }) => {
      if (address?.info) {
        setValue('addressInformation', address.info);
      }
    }
  });

  useDeepCompareEffect(() => {
    // When parent form reset to update new values, we need to sync current value into parent form as well
    setValue('addressInformation', data?.address?.info || defaultInfluencerFormValues.addressInformation);
  }, [defaultValues]);

  const isEditable = disableAddressEditing ? false : data?.address?.isEditable || false;

  return (
    <FormStyle.Wrapper css={{ padding: '24px' }} className={className} hasBorder={hasBorder}>
      <Label title={t('Address Information')} css={{ fontWeight: '600' }} />

      <TextField
        disabled={!isEditable}
        title={t('TextForm.Full name')}
        name="addressInformation.fullName"
        placeholder={t<string>('TextForm.Full name')}
      />

      <TextField
        type="number"
        disabled={!isEditable}
        placeholder="000000000000"
        title={t('TextForm.Postal code')}
        name="addressInformation.postalCode"
      />

      <TextField
        disabled={!isEditable}
        placeholder="Bangkok"
        title={t('TextForm.Province')}
        name="addressInformation.province"
      />

      <TextField
        disabled={!isEditable}
        placeholder="Watthana"
        title={t('TextForm.City')}
        name="addressInformation.city"
      />

      <TextField
        disabled={!isEditable}
        title={t('TextForm.Address')}
        name="addressInformation.address"
        placeholder="Khlong Tan Nuea 46 Sukhumvit Rd"
      />

      <TextField
        disabled={!isEditable}
        name="addressInformation.address2"
        placeholder="apartment AnyTag 101"
        title={t('TextForm.apartment, suite, etc')}
      />
    </FormStyle.Wrapper>
  );
};
