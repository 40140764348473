import { YoutubeCompareAccountOverview } from '@/graphql';
import { useSelectItems } from '@/shared/hooks';
import { YoutubeComparePostsDetailsModal, YoutubeComparePostsDetailsModalProps } from '@/shared/molecules';
import {
  AnalyticsCompareOverviewChart,
  AnalyticsCompareOverviewLegend,
  AnalyticsCompareOverviewAccounts
} from '../../shared';
import { AnalyticsYoutubeComparableAccountType } from '../types';
import { AnalyticsCompareYoutubeOverviewTable } from './AnalyticsCompareYoutubeOverviewTable';
import {
  useAnalyticsYoutubeCompareOverviewData,
  useAnalyticsYoutubeCompareOverviewPosts,
  useAnalyticsYoutubeCompareOverviewLegend
} from './hooks';

interface TemplateProps {
  mainAccountId: number;
  data: YoutubeCompareAccountOverview[];
  comparableAccounts: AnalyticsYoutubeComparableAccountType[];
}

const MAX_VISIBLE_ACCOUNTS = 5;

export const Template = ({ data, mainAccountId, comparableAccounts }: TemplateProps) => {
  const { legendOptions, selectedLegendKeys, handleSelectLegendOption } = useAnalyticsYoutubeCompareOverviewLegend();
  const { selectedItemIds: visibleAccountIds, setSelectedItemIds: setVisibleAccountIds } = useSelectItems({
    key: 'id',
    listItems: comparableAccounts,
    defaultSelectedIds: comparableAccounts.slice(0, MAX_VISIBLE_ACCOUNTS).map((acc) => acc.id)
  });
  const { chartData, allCategories } = useAnalyticsYoutubeCompareOverviewData({
    data,
    visibleAccountIds,
    selectedLegendKeys,
    comparableAccounts
  });
  const { selectedPostDay, setSelectedPostDay, customChartOptions } = useAnalyticsYoutubeCompareOverviewPosts({
    chartData
  });

  return (
    <>
      <div css={{ padding: '16px 12px' }}>
        <AnalyticsCompareOverviewLegend
          options={legendOptions}
          css={{ marginBottom: '20px' }}
          selectedOptions={selectedLegendKeys}
          onSelectOption={handleSelectLegendOption}
        />
        <AnalyticsCompareOverviewAccounts
          visibleAccountIds={visibleAccountIds}
          comparableAccounts={comparableAccounts}
          onSetVisibleAccountIds={setVisibleAccountIds}
        />
      </div>

      <AnalyticsCompareOverviewChart data={chartData} categories={allCategories} customOptions={customChartOptions} />

      <AnalyticsCompareYoutubeOverviewTable data={data} comparableAccounts={comparableAccounts} />

      <YoutubeComparePostsDetailsModal
        postDate={selectedPostDay}
        mainAccountId={mainAccountId}
        onClose={() => setSelectedPostDay('')}
        comparableAccounts={comparableAccounts.reduce<YoutubeComparePostsDetailsModalProps['comparableAccounts']>(
          (acc, curr) =>
            visibleAccountIds.includes(curr.id)
              ? [...acc, { id: curr.id, name: curr.username, avatar: curr.avatarUrl }]
              : acc,
          []
        )}
        isAnalytics
      />
    </>
  );
};
