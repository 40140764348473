import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Anchor } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface FooterProps {
  className?: string;
  hasCombinedTerms?: boolean;
}

export const Footer = ({ className, hasCombinedTerms }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <footer css={styles.wrapper} className={className}>
      <p css={{ marginRight: 'auto' }}>{t('CopyrightSentence')}</p>
      <Anchor css={styles.link} target="_blank" to="/privacy">
        {t('Button.Privacy Policy')}
      </Anchor>
      <p css={{ fontWeight: 100 }}>|</p>
      <Anchor css={styles.link} target="_blank" to={hasCombinedTerms ? '/terms-combined' : '/terms'}>
        {t('Button.Terms Of Service')}
      </Anchor>
    </footer>
  );
};
const styles = {
  wrapper: css({
    display: 'flex',
    marginTop: 'auto',
    fontWeight: 300,
    alignItems: 'end',
    boxSizing: 'border-box',
    color: THEME.text.colors.gray.lv2,
    height: THEME.container.footer.height
  }),
  link: css({
    padding: '0 16px',
    color: THEME.text.colors.gray.lv2,
    '&:not(disabled):hover': { color: THEME.text.colors.gray.lv2 }
  })
};
