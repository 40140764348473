import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { InfluencerAvatar } from '@/shared/molecules';
import { Anchor, ColumnProps, RowProps, Table, TableCell } from '@/shared/atoms';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import {
  getOffset,
  formatPrice,
  formatPercent,
  formatIntNumber,
  convertOrderToORDER,
  getValuableVariables
} from '@/shared/utils';
import {
  AutoManagedReportSummary,
  AutoManagedReportByInfluencer,
  useAutoManagedReportByInfluencerQuery,
  MarketplaceAffiliateReportByInfluencerField
} from '@/graphql';
import { useAutoManagedReportFilter } from '../hooks';
import { formatAffiliatePeriod, styles } from './utils';

interface AutoManagedReportByInfluencerTableProps {
  currency: string;
  campaignId: number;
  summaryData?: AutoManagedReportSummary;
}

export const AutoManagedReportByInfluencerTable = ({
  currency,
  campaignId,
  summaryData
}: AutoManagedReportByInfluencerTableProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const {
    page,
    limit,
    sort,
    setSort,
    filter: { endDate, startDate }
  } = useAutoManagedReportFilter();
  const { data, loading } = useAutoManagedReportByInfluencerQuery({
    variables: getValuableVariables({
      limit,
      endDate,
      startDate,
      id: campaignId,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) }
    })
  });

  const withSort = (sortField: MarketplaceAffiliateReportByInfluencerField) => ({ sort, setSort, sortField });

  const getStatCells = (rowStats?: Partial<AutoManagedReportByInfluencer> | null) => [
    <TableCell.Number value={formatIntNumber(rowStats?.clicks)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.conversions)} />,
    <TableCell.Number value={formatPercent(rowStats?.cvr, false)} unit="%" />,
    <TableCell.Number value={formatPrice(rowStats?.sales || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.cost || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPercent(rowStats?.commissionRate, false)} unit="%" />,
    <TableCell.Number value={formatPrice(rowStats?.influencerCost || null, currency)} unit={currency} />,
    <TableCell.Text value={rowStats?.promotionMethods?.join(', ') || '-'} />
  ];

  const columns: ColumnProps<MarketplaceAffiliateReportByInfluencerField>[] = [
    { title: t('HeaderColumn.Influencer Name'), sticky: { active: true, hasBorderRight: true } },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Click'),
      ...withSort(MarketplaceAffiliateReportByInfluencerField.CLICK)
    },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Order'),
      ...withSort(MarketplaceAffiliateReportByInfluencerField.ORDER)
    },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.CVR'),
      ...withSort(MarketplaceAffiliateReportByInfluencerField.CVR)
    },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Store Sales'),
      ...withSort(MarketplaceAffiliateReportByInfluencerField.SALE)
    },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Cost'),
      ...withSort(MarketplaceAffiliateReportByInfluencerField.COST)
    },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Influencer Commission Rate'),
      hidden: !isAdminStaff,
      ...withSort(MarketplaceAffiliateReportByInfluencerField.INFLUENCER_COMMISSION_RATE)
    },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Influencer Commission'),
      hidden: !isAdminStaff,
      ...withSort(MarketplaceAffiliateReportByInfluencerField.INFLUENCER_COST)
    },
    { styles: { textAlign: 'left' }, title: t('HeaderColumn.Promotion method') }
  ];

  const totalRow: RowProps = {
    styles: styles.totalRow,
    cells: [
      <TableCell.Text
        value={formatAffiliatePeriod({
          period: { endDate: endDate || '', startDate: startDate || '' },
          title: t('Total')
        })}
      />,
      ...getStatCells({
        cvr: summaryData?.cvr,
        cost: summaryData?.cost,
        sales: summaryData?.sales,
        clicks: summaryData?.clicks,
        conversions: summaryData?.conversions,
        influencerCost: summaryData?.influencerCost,
        commissionRate: undefined
      })
    ]
  };
  const campaignRows: RowProps[] =
    data?.autoManagedReportByInfluencer?.map((stats) => {
      const profileLink = generateProfileLink(stats?.influencerInfo?.id);

      return [
        <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <InfluencerAvatar size="48px" src={stats?.influencerInfo?.thumbNail} id={stats?.influencerInfo?.id} />
          <Anchor lines={3} label={stats?.influencerInfo?.name} {...profileLink} />
        </div>,
        ...getStatCells(stats)
      ];
    }) || [];

  return (
    <Table
      loading={loading}
      data={{ columns, rows: [totalRow, ...campaignRows], rowHeight: '73px' }}
      pagination={{ totalRecords: data?.autoManagedReportByInfluencerCount?.totalNumber }}
      noTopBorderRadius
      stickyHeader
    />
  );
};
