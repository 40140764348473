import { XhsInfluencerProfile, XhsInfluencers } from '@/shared/organisms';
import { useDefaultInfluencerSearchProps, useSocialInfluencersSelectButtonsProps } from './hooks';

export const EngagementXhsInfluencersSearch = () => {
  const { isEngagementPage, isTtcmCampaign } = useDefaultInfluencerSearchProps();
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialInfluencersSelectButtonsProps<XhsInfluencerProfile>({ socialMedia: 'XHS' });

  return (
    <XhsInfluencers
      isTtcmCampaign={isTtcmCampaign}
      isEngagementPage={isEngagementPage}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
