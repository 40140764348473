import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@/shared/atoms';
import { useAgencyPermissions } from '@/auth';
import { useAllAgenciesQuery } from '@/graphql';
import { Header, ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { AgenciesFilter, AgenciesList, useAccountsFilter } from '@/shared/organisms';

export const Agencies = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'appHeader.Agency', appHeader: 'appHeader.Agency' });
  const { t } = useTranslation();
  const { hideAddAgencyBtn } = useAgencyPermissions();
  const { page, limit, filter } = useAccountsFilter();
  const { data, previousData, loading } = useAllAgenciesQuery({
    fetchPolicy: 'network-only',
    variables: getValuableVariables({
      ...filter,
      limit,
      offset: getOffset(page, limit),
      verifiedStatus: filter.verifiedStatus || null
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allAgenciesCount?.totalNumber;

  return (
    <>
      <Header>
        {!hideAddAgencyBtn ? (
          <ButtonLink
            to="/agency/add"
            title={t<string>('Button.Add Agency')}
            variant="blue"
            prefixIcon={{ icon: 'plus', size: '10px' }}
            css={{ minWidth: '140px', textTransform: 'capitalize', marginLeft: 'auto' }}
          />
        ) : null}
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <AgenciesFilter />
        <AgenciesList loading={loading} totalRecords={totalRecords} data={result?.allAgencies || []} />
      </ListWithPagination>
    </>
  );
};
