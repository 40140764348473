import { MultiValueProps } from 'react-select';
import { useTranslation } from 'react-i18next';
import { TextCutter } from '@/shared/atoms';

// https://github.com/JedWatson/react-select/issues/4557#issuecomment-827654568
export const MultiValue = ({ index, getValue, selectProps }: MultiValueProps<any, any, any>) => {
  const { t } = useTranslation();

  const value = getValue();

  return !index ? (
    Array.isArray(value) ? (
      <TextCutter
        lines={1}
        text={value.length > 2 ? t('Selected', { count: value.length }) : value.map((item) => item.label).join(', ')}
      />
    ) : null
  ) : null;
};
