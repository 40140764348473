import { QueryHookOptions } from '@apollo/client';

import { useUserRoles } from '@/auth';
import { Query, QueryallStaffsArgs, useAllStaffsQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';

export const useAllStaffOptions = (options?: QueryHookOptions<Query, QueryallStaffsArgs>) => {
  const { isAdmin, isStaff } = useUserRoles();

  const { data, loading } = useAllStaffsQuery({
    skip: !(isAdmin || isStaff),
    fetchPolicy: 'cache-first',
    ...options
  });

  return { loading, allStaffOptions: getOptions(data?.allStaffs) };
};
