import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { InfluencerYoutubeCompareTagRanking, InfluencerYoutubeAnalyticsAccountTagRanking } from '@/graphql';
import { RowProps, ColumnProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import {
  AnalyticsComparePostsAccount,
  AnalyticsCompareTagsRankingTable,
  AnalyticsCompareTagsRankingSlider
} from '@/shared/organisms';
import { InfluencerYoutubeComparableAccountType } from '../types';

interface TemplateProps {
  data: InfluencerYoutubeCompareTagRanking;
  comparableAccounts: InfluencerYoutubeComparableAccountType[];
}

export const Template = ({ data, comparableAccounts }: TemplateProps) => {
  const { t } = useTranslation();

  const composeData = [
    data.mainAccount,
    ...(data.compareAccounts.reduce<InfluencerYoutubeAnalyticsAccountTagRanking[]>(
      (acc, curr) =>
        curr.accountId !== data.mainAccount.socialAccountId
          ? [...acc, { ...curr, socialAccountId: curr.accountId }]
          : acc,
      []
    ) || [])
  ];
  const columns: ColumnProps[] = [{ title: t('HeaderColumn.Account') }, { title: null }];

  const rows: RowProps[] = comparableAccounts.map((account) => {
    const rowData = composeData.find((item) => item.socialAccountId === account.id);

    return {
      styles: css({
        'td:first-of-type': { width: '160px', borderRight: THEME.border.base },
        'td:last-of-type': { paddingLeft: 0, paddingRight: 0 }
      }),
      cells: [
        <AnalyticsComparePostsAccount account={{ name: account.username, avatar: account.avatarUrl }} />,
        <AnalyticsCompareTagsRankingSlider data={rowData?.tagsRanking} />
      ]
    };
  });

  return <AnalyticsCompareTagsRankingTable data={{ rows, columns }} />;
};
