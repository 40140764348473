import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { formatNumber, formatPrice } from '@/shared/utils';
import { CURRENCY_SYMBOLS, THEME } from '@/shared/constants';
import { TransferModalFormValues, TransferModalTemplateProps } from './types';
import { maskitoParseNumber } from './schema';
import { MoneyField } from './MoneyField';

export const Template = ({ onClose, loading, adAccount, prepaidBalance, onSubmit }: TransferModalTemplateProps) => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<TransferModalFormValues>();

  const { amount, step } = watch();
  const currencyPrefix = CURRENCY_SYMBOLS[adAccount.currencyId];
  const unmaskedAmount = maskitoParseNumber(amount);

  return (
    <>
      <ModalContent>
        <ModalTitle css={{ marginBottom: '4px' }}>{t('Dialog.Transfer money')}</ModalTitle>
        {/* we should have only charge step due to some TT limitations, but let's keep withdraw for now */}
        <MoneyField
          required
          hasMaxValue
          name="amount"
          prefix={currencyPrefix}
          triggerFormRevalidation
          placeholder={formatPrice(10000, null, 0)}
          balance={step === 'charge' ? prepaidBalance : adAccount.balanceAmount}
        />

        <div css={[styles.account, { marginTop: '24px' }]}>
          {step === 'withdraw' ? (
            <>
              <div css={{ fontWeight: 600 }}>{t('New Ad Account Balance')}</div>
              <span css={{ fontWeight: 600 }}>
                {amount ? `${currencyPrefix}${formatNumber(prepaidBalance || 0 + unmaskedAmount, 0)}` : '-'}
              </span>
            </>
          ) : (
            <>
              <div css={{ fontWeight: 600 }}>{t('New Prepaid Balance')}</div>
              <span css={{ fontWeight: 600 }}>
                {amount ? `${currencyPrefix}${formatNumber(prepaidBalance || 0 + unmaskedAmount, 0)}` : '-'}
              </span>
            </>
          )}
        </div>

        <div css={{ padding: '8px 12px 4px', backgroundColor: THEME.colors.black['10'] }}>
          <div css={styles.account}>
            <div css={{ color: THEME.text.colors.gray.lv3 }}>{t('Ad Account Name')}</div>
            <span>{adAccount.name}</span>
          </div>
          <div css={styles.account}>
            <div css={{ color: THEME.text.colors.gray.lv3 }}>{t('Ad Account ID')}</div>
            <span>{adAccount.id}</span>
          </div>
        </div>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{
          onClick: onSubmit,
          loading: isSubmitting || loading,
          title: t(step === 'charge' ? 'Button.Charge' : 'Button.Withdraw'),
          disabled: !amount
        }}
      />
    </>
  );
};

const styles = {
  account: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    marginBottom: '8px',
    fontSize: '14px'
  }),
  navLabel: css({
    fontWeight: 400,
    fontSize: '14px',
    color: THEME.text.colors.gray.lv3,
    '.active': {
      color: THEME.text.colors.black.lv1
    }
  })
};
