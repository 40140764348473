import { css } from '@emotion/react';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { MenuProps, StylesConfig, Options } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Icon, Label } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';
import { MenuItemWithCheckBox } from '../Selector/MultiSelector/MenuItemWithCheckBox';

export interface MultiSearchSelectorProps<T extends Option = Option> {
  className?: string;
  title: string;
  required?: boolean;
  help?: string;
  setFieldValue: (value: T[]) => void;
  placeholder: string;
  disabled?: boolean;
  value: T[];
  creatable?: boolean;
  loadOptions: (inputValue: string, callback: (options: Options<T>) => void) => Promise<Option[]> | void;
}

export const MultiSearchSelector = ({
  className,
  title,
  required,
  placeholder,
  value: tagsFieldValue,
  help,
  setFieldValue,
  disabled = false,
  creatable = true,
  loadOptions
}: MultiSearchSelectorProps<Option>) => {
  const { t } = useTranslation();
  const Component = creatable ? AsyncCreatableSelect : AsyncSelect;

  return (
    <div className={className}>
      <Label title={t(`Selector.${title}`)} required={required} help={help} />
      <Component
        cacheOptions={true}
        loadOptions={loadOptions}
        isMulti={true}
        value={tagsFieldValue}
        styles={selectStyles}
        isDisabled={disabled}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        components={{
          IndicatorsContainer: () => null,
          Menu: (props) => <CustomMenu {...props} setFieldValue={setFieldValue} />,
          MultiValue: () => null
        }}
      />
    </div>
  );
};

const CustomMenu: ComponentType<MenuProps<Option, true> & { setFieldValue: (value: Option[]) => void }> = ({
  setFieldValue,
  ...props
}) => {
  const allValues = props.getValue();
  const { t } = useTranslation();

  const options = props.options as Option[];
  // eslint-disable-next-line no-underscore-dangle
  const newOption = options.find((option) => option.__isNew__);

  return (
    <components.Menu {...props} css={[styles.menu, !props.options?.length && { display: 'none' }]}>
      <div>
        {newOption ? (
          <div
            css={styles.newOption}
            onClick={() => {
              setFieldValue([
                ...allValues.filter((v) => v.value !== newOption.value),
                { value: newOption.value, label: newOption.value }
              ]);
            }}
          >
            <Icon icon="plus" size={11} color="#6E7C89" css={{ paddingLeft: '4px' }} />
            <label css={{ cursor: 'pointer' }}>
              {t('Add')}&nbsp;<span css={{ fontWeight: 600 }}>"{newOption.value}"</span>
            </label>
          </div>
        ) : null}
        {options.map((option) => {
          const alreadySelectedValue = allValues.find((opt) => opt.value === option.value);

          // eslint-disable-next-line no-underscore-dangle
          return !option.__isNew__ ? (
            <MenuItemWithCheckBox
              isSelected={!!alreadySelectedValue}
              handleClick={() => {
                const newValue = alreadySelectedValue
                  ? allValues.filter((v) => v.value !== option.value)
                  : [...allValues, option];

                setFieldValue(newValue);
              }}
              key={option.value}
              label={option.label}
            />
          ) : null;
        })}
      </div>
    </components.Menu>
  );
};

const styles = {
  menu: css({
    maxHeight: '400px',
    overflow: 'auto',
    borderRadius: '3px !important',
    borderTop: `${THEME.border.base} !important`,
    marginTop: '3px !important'
  }),
  newOption: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '8px',
    padding: '8px',
    borderBottom: THEME.border.base,
    ':hover': {
      backgroundColor: THEME.dropdown.hover
    }
  })
};

export const selectStyles = {
  control: (base) => ({
    ...base,
    border: THEME.border.base,
    borderRadius: '2px',
    minHeight: '32px',
    outline: 'none',
    boxShadow: 'none',
    lineHeight: '15px',

    '&:hover': {
      borderColor: '#dee5ec'
    }
  }),
  menu: (base) => ({
    ...base,
    border: THEME.border.base,
    borderRadius: '3px',
    borderTop: 'none',
    borderTopLeftRadius: 'unset',
    borderTopRightRadius: 'unset',
    marginTop: 0,
    boxShadow: 'unset'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (base) => ({
    ...base,
    width: '100%'
  })
} satisfies StylesConfig<any>;
