import { RenderDataWithFallback } from '@/shared/atoms';
import { AffiliateReportChart } from '@/shared/molecules';
import { getValuableVariables } from '@/shared/utils';
import { AutoManagedCampaignPayload, useAutoManagedReportSummaryQuery } from '@/graphql';
import { AutoManagedReportTable } from './AutoManagedReportTable';
import { AutoManagedReportFilter } from './AutoManagedReportFilter';
import { AutoManagedReportSummary } from './AutoManagedReportSummary';
import { useAutoManagedReportFilter } from './hooks';

export interface AutoManagedReportProps {
  autoManagedCampaign: AutoManagedCampaignPayload;
}
export const AutoManagedReport = ({ autoManagedCampaign: { id, currency } }: AutoManagedReportProps) => {
  const { filter, setFilter } = useAutoManagedReportFilter();
  const { data, loading } = useAutoManagedReportSummaryQuery({
    variables: getValuableVariables({ id, endDate: filter.endDate, startDate: filter.startDate })
  });

  const summaryData = data?.autoManagedReportSummary || undefined;

  return (
    <>
      <AutoManagedReportFilter filter={filter} setFilter={setFilter} campaignId={id} />

      <RenderDataWithFallback loading={loading}>
        <AutoManagedReportSummary currency={currency} data={summaryData} />
        {/* We don't have data for auto-managed chart but it still shown.
        I already asked PM about that, need PM confirmed but not yet
        https://adasiaholdings.slack.com/archives/C7THRJAKD/p1692869392714219 */}
        <AffiliateReportChart data={undefined} />
        <AutoManagedReportTable
          filter={filter}
          campaignId={id}
          currency={currency}
          setFilter={setFilter}
          summaryData={summaryData}
        />
      </RenderDataWithFallback>
    </>
  );
};

export * from './schemaTypes';
