import { useTranslation } from 'react-i18next';
import { ResponsiveContainer, CartesianGrid, Legend, AreaChart, Area, Tooltip, XAxis, YAxis } from 'recharts';
import { TikTokAdChartReport } from '@/graphql';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter, numberFormatter, tickFormatter } from '@/shared/utils';

export interface AdCampaignChartProps {
  isAllTime?: boolean;
  data?: readonly TikTokAdChartReport[];
}

export const AdCampaignChart = ({ data, isAllTime }: AdCampaignChartProps) => {
  const { t, i18n } = useTranslation();

  if (!data) {
    return null;
  }

  const formatChartDate = (date: string, format = 'MMM do') =>
    localizedDateFormatter(date, format, i18n.language as AppLanguage);

  return data ? (
    <ResponsiveContainer css={{ height: '325px !important', backgroundColor: THEME.background.colors.white }}>
      <AreaChart margin={{ top: 0, left: 5, right: 5, bottom: 20 }} data={data as TikTokAdChartReport[]}>
        <CartesianGrid stroke={THEME.chart.colors.report.stroke} strokeDasharray="3 3" />
        <YAxis
          yAxisId="adCost"
          tick={{ fill: THEME.chart.colors.report.fill, fontSize: 12 }}
          stroke={THEME.chart.colors.report.stroke}
          tickFormatter={tickFormatter}
        />
        <YAxis
          stroke={THEME.chart.colors.report.stroke}
          orientation="right"
          yAxisId="adImpressions"
          tickFormatter={tickFormatter}
          tick={{ fill: THEME.chart.colors.report.fill, fontSize: 12 }}
        />
        <XAxis
          name="Date"
          dataKey="date"
          stroke={THEME.chart.colors.report.stroke}
          tick={{ fill: THEME.chart.colors.report.fill, fontSize: 12 }}
          interval="preserveStartEnd"
          tickFormatter={(date) => formatChartDate(date, isAllTime ? 'PPP' : undefined)}
        />
        <Legend
          align="left"
          iconSize={20}
          iconType="line"
          verticalAlign="top"
          wrapperStyle={{ fontSize: 12, lineHeight: '36px', paddingBottom: '5px', marginLeft: '8px' }}
        />
        <Tooltip
          formatter={numberFormatter}
          itemStyle={{ fontSize: 13, fontWeight: 400 }}
          labelStyle={{ paddingBottom: '4px', color: THEME.chart.colors.report.fill, fontSize: 13, fontWeight: 600 }}
        />
        <Area
          dot
          yAxisId="adCost"
          dataKey="adCost"
          type="linear"
          fill={THEME.chart.colors.report.fillRed}
          stroke={THEME.chart.colors.report.red}
          strokeWidth={1.2}
          animationDuration={800}
          name={t<string>('Cost')}
        />
        <Area
          dot
          type="linear"
          fill={THEME.chart.colors.report.fillBlue}
          stroke={THEME.chart.colors.report.blue}
          strokeWidth={1.2}
          yAxisId="adImpressions"
          dataKey="adImpressions"
          animationDuration={800}
          name={t<string>('Impressions')}
        />
      </AreaChart>
    </ResponsiveContainer>
  ) : null;
};
