import { useEffect, useRef, useState } from 'react';
import { SunEditorRefType, SunEditorRef } from '@/shared/atoms';

// 50s is an approx wait time based on DiFy speed (can also be longer)
export const useDescriptionGeneratorState = (duration = 50000) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<'idle' | 'loading' | 'resolved'>('idle');
  const serviceInformationRef = useRef<SunEditorRef>();
  const postRequirementsRef = useRef<SunEditorRef>();

  const getSunEditorInstance = (sunEditor: SunEditorRef, refType: SunEditorRefType) => {
    switch (refType) {
      case 'serviceInfo':
        serviceInformationRef.current = sunEditor;
        break;
      case 'postReq':
        postRequirementsRef.current = sunEditor;
        break;

      default:
        throw new Error('Invalid refType');
    }
  };

  const setContentForce = (value: string, refType: SunEditorRefType) => {
    switch (refType) {
      case 'serviceInfo':
        if (serviceInformationRef.current) {
          serviceInformationRef.current.setContents(value);
        }
        break;

      case 'postReq':
        if (postRequirementsRef.current) {
          postRequirementsRef.current.setContents(value);
        }
        break;

      default:
        throw new Error('Invalid refType');
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (loading) {
      const startTime = Date.now();
      interval = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const percent = Math.floor(Math.min((elapsed / duration) * 100, 100));
        setProgress(status === 'loading' && percent === 100 ? 99 : percent); // in case timer will ends before the results come from DiFy, we will stop at 99

        if (percent >= 100 && status === 'resolved') {
          clearInterval(interval);
          setLoading(false);
        }
      }, 100);
    }
    return () => clearInterval(interval);
  }, [loading, duration, status]);

  const onStartLoading = () => {
    setLoading(true);
    setProgress(0);
  };
  const onClear = () => {
    setLoading(false);
    setProgress(0);
  };

  return { progress, onStartLoading, onClear, loading, setStatus, getSunEditorInstance, setContentForce };
};

export type DescriptionGeneratorStateReturnType = ReturnType<typeof useDescriptionGeneratorState>;
