import { useInstagramUGCManagementPostDetailsQuery } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostStyles } from '@/shared/molecules';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsSelectedAccountInfo } from '../../../../types';
import { DefaultTemplate } from './DefaultTemplate';
import { StaffTemplate } from './StaffTemplate';

export interface UGCPostDetailsProps extends Pick<PostDetailsType, 'id'> {
  onCloseModal?: () => void;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const UGCPostDetails = ({ id, onCloseModal, analyticsAccount }: UGCPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const { data, loading } = useInstagramUGCManagementPostDetailsQuery({
    skip: !id || !analyticsAccount.id,
    fetchPolicy: 'cache-and-network',
    variables: { instagramAnalyticsAccountId: analyticsAccount.id, postSNSId: String(id) },
    onError: (error) => {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
      onCloseModal?.();
    }
  });

  const postDetails = data?.instagramUGCManagementPostDetails;
  const showStaffRequest = !!postDetails?.showStaffRequest;

  return (
    <PostStyles.Wrapper loading={loading} css={{ width: showStaffRequest ? '816px' : '850px' }}>
      {postDetails ? (
        showStaffRequest ? (
          <StaffTemplate data={postDetails} analyticsAccount={analyticsAccount} />
        ) : (
          <DefaultTemplate data={postDetails} analyticsAccount={analyticsAccount} />
        )
      ) : null}
    </PostStyles.Wrapper>
  );
};
