import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { namedOperations, useAllAdvertiserPicsQuery } from '@/graphql';
import { AccountUsersList } from '../../Accounts';

export interface AdvertiserUsersListProps {
  advertiserId: number;
}

export const AdvertiserUsersList = ({ advertiserId }: AdvertiserUsersListProps) => {
  const { t } = useTranslation();
  const { data, loading } = useAllAdvertiserPicsQuery({ variables: { advertiserId } });

  return (
    <AccountUsersList
      loading={loading}
      data={data?.allAdvertiserPics || []}
      title={t<string>('Accessible Users')}
      css={{ boxShadow: THEME.shadows.boxShadow }}
      refetchQueriesAfterDelete={[namedOperations.Query.AllAdvertiserPics]}
    />
  );
};
