import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ResendEmailForAdvertiser.graphql';
import { Mutation, MutationresendEmailForAdvertiserArgs } from '../../__generated__/globalTypes';

export const useResendEmailForAdvertiserMutation = (
  options?: MutationHookOptions<Mutation, MutationresendEmailForAdvertiserArgs>
) => {
  const [callResendEmailForAdvertiser, result] = useMutation<Mutation, MutationresendEmailForAdvertiserArgs>(
    MUTATION,
    options
  );

  return { callResendEmailForAdvertiser, ...result };
};

export type ResendEmailForAdvertiserMutationFunction = Mutation['resendEmailForAdvertiser'];
