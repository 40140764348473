import { z } from 'zod';

import { BaseValidations } from '@/shared/validations';

export const SignUpFormSchema = z.object({
  email: BaseValidations.EmailSchema,
  name: z.string(),
  countryId: z.string(),
  companyUrl: z.string(),
  companyName: z.string(),
  phoneNumber: BaseValidations.PhoneNumberSchema
});
