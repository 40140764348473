import { LinkProps } from '@tanstack/react-router';
import { useMatchPaths } from '@/shared/hooks';

export const useChatDemoSearchPathnames = () => {
  const { matchPaths } = useMatchPaths();

  const influencerSearchPaths = [
    '/influencers/search',
    '/influencers/search/instagram',
    '/influencers/search/tiktok',
    '/influencers/search/youtube',
    '/influencers/search/twitter',
    '/influencers/search/facebook',
    '/influencers/search/threads',
    '/influencers/search/facebook_page'
  ] as const satisfies LinkProps['to'][];
  const isInfluencersSearchPage = matchPaths(influencerSearchPaths);
  const isSupportedSNS = matchPaths([
    '/influencers/search',
    '/influencers/search/instagram',
    '/influencers/search/tiktok'
  ]);

  return {
    isSupportedSNS,
    influencerSearchPaths,
    isInfluencersSearchPage,
    supportedInfluencerSearchPaths: influencerSearchPaths
  };
};
