import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/Agency.graphql';
import { Query, QueryagencyArgs } from '../../__generated__/globalTypes';

export const useAgencyQuery = (options?: QueryHookOptions<Query, QueryagencyArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryagencyArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAgencyLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryagencyArgs>
): QueryResult<Query, QueryagencyArgs> & { getAgency: LazyQueryExecFunction<Query, QueryagencyArgs> } => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAgency, result] = useLazyQuery<Query, QueryagencyArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAgency, ...result };
};

export const useAgencyPromiseQuery = () => {
  const client = useApolloClient();

  const getAgency = useCallback(
    (options?: Omit<QueryOptions<QueryagencyArgs, Query>, 'query'>) =>
      client.query<Query, QueryagencyArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAgency };
};
