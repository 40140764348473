import { CSSObject, SerializedStyles, css } from '@emotion/react';
import { ReactNode } from 'react';
import { THEME } from '@/shared/constants';
import { SignInSignUpFooter, SignInSignUpFooterProps } from '../SignInSignUpFooter';
import { SignInSignUpHeader, SignInSignUpHeaderProps } from '../SignInSignUpHeader';

export interface SignInSignUpLayoutProps {
  className?: string;
  children: ReactNode;
  contentClassName?: CSSObject | SerializedStyles;
  headerProps?: Omit<SignInSignUpHeaderProps, 'page'>;
  footerProps?: Omit<SignInSignUpFooterProps, 'page'>;
}

export const SignInSignUpLayout = ({
  children,
  className,
  headerProps,
  footerProps,
  contentClassName
}: SignInSignUpLayoutProps) => (
  <div css={styles.wrapper} className={className}>
    <SignInSignUpHeader {...headerProps} />

    <div css={[styles.content, contentClassName]}>{children}</div>

    <SignInSignUpFooter {...footerProps} />
  </div>
);
const styles = {
  wrapper: css({
    width: '100vw',
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  content: css({
    display: 'flex',
    position: 'relative',
    alignContent: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    filter: THEME.shadows.dropShadow,
    width: THEME.container.dashboard.maxWidth,
    backgroundColor: THEME.background.colors.white,
    borderRadius: THEME.container.dashboard.borderRadius
  })
};
