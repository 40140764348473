import { useInfluencerAllInstagramReelPostsQuery } from '@/graphql';
import { LIMIT_30 } from '@/shared/constants';
import { InfluencerInstagramAnalyticsReelDetailsModal } from '@/shared/molecules';
import { AnalyticsInstagramDashboardReelPostsTemplate } from '@/shared/organisms';
import { PostDetailsType } from '@/shared/types';
import { InfluencerSelectedAccountInfo } from '../../types';

interface InfluencerAnalyticsInstagramProfileReelPostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: number;
}

export const InfluencerAnalyticsInstagramProfileReelPosts = ({
  socialAccount: { id: socialAccountId, name },
  influencerId
}: InfluencerAnalyticsInstagramProfileReelPostsProps) => {
  const { data } = useInfluencerAllInstagramReelPostsQuery({
    variables: {
      offset: 0,
      limit: LIMIT_30,
      socialAccountId,
      orderBy: { field: null, order: null }
    }
  });

  const posts = data?.influencerAllInstagramReelPosts || [];

  return (
    <>
      <AnalyticsInstagramDashboardReelPostsTemplate
        data={posts}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/instagram_reel_post',
          params: { socialMediaAccountId: socialAccountId.toString(), id: influencerId.toString() },
          search: { filter: { name } }
        }}
      />

      <InfluencerInstagramAnalyticsReelDetailsModal
        posts={posts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: socialAccountId }))}
      />
    </>
  );
};
