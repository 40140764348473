import React, { ClipboardEventHandler, KeyboardEventHandler, ReactNode } from 'react';
import { InputUnit, InputUnitProps } from '@/shared/atoms';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export interface TextFieldProps<T extends string = string>
  extends DefaultFieldProps<T, string>,
    Omit<InputUnitProps, 'name' | 'value' | 'title' | 'onChange'> {
  children?: ReactNode;
  numberWithDecimals?: boolean;
  onChangeCallback?: () => void;
  triggerFormRevalidation?: boolean;
}

export const TextField = <T extends string = string>(props: TextFieldProps<T>) => {
  const {
    fieldValue,
    fieldRegister,
    fieldWrapperProps,
    formContext: { trigger },
    fieldProps: {
      name,
      children,
      onChange,
      onKeyPress,
      onChangeCallback,
      numberWithDecimals,
      triggerFormRevalidation,
      ...restFieldProps
    }
  } = useFormatRHFFieldProps(props);

  const onlyDigitsAndDot = /^-?\d*(\.\d*)?$/;
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event): void => {
    const key = event?.key;
    const isNumberKey = onlyDigitsAndDot.test(`${fieldValue}${key}`);

    if (!isNumberKey) {
      event.preventDefault();
    }
  };
  const handlePasteNumberWithDecimals: ClipboardEventHandler<HTMLInputElement> = (event): void => {
    if (!onlyDigitsAndDot.test(event.clipboardData?.getData('text'))) {
      event.preventDefault();
    }
  };

  const handleKeyPress = numberWithDecimals ? handleKeyDown : onKeyPress; // for number + decimals

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      {({ hasError }) => (
        <>
          <InputUnit
            {...fieldRegister}
            {...restFieldProps}
            hasError={hasError}
            onKeyPress={handleKeyPress}
            onPaste={numberWithDecimals ? handlePasteNumberWithDecimals : undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeCallback?.();
              onChange?.(name, e.target.value) || fieldRegister.onChange(e);
              if (triggerFormRevalidation) {
                trigger();
              }
            }}
          />
          {children}
        </>
      )}
    </RHFFieldWrapper>
  );
};
