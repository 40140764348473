import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/NetsuiteVendorList.graphql';
import { Query, QuerynetsuiteVendorListArgs } from '../../__generated__/globalTypes';

export const useNetsuiteVendorListQuery = (options?: QueryHookOptions<Query, QuerynetsuiteVendorListArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerynetsuiteVendorListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useNetsuiteVendorListLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerynetsuiteVendorListArgs>
): QueryResult<Query, QuerynetsuiteVendorListArgs> & {
  getNetsuiteVendorList: LazyQueryExecFunction<Query, QuerynetsuiteVendorListArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getNetsuiteVendorList, result] = useLazyQuery<Query, QuerynetsuiteVendorListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getNetsuiteVendorList, ...result };
};

export const useNetsuiteVendorListPromiseQuery = () => {
  const client = useApolloClient();

  const getNetsuiteVendorList = useCallback(
    (options?: Omit<QueryOptions<QuerynetsuiteVendorListArgs, Query>, 'query'>) =>
      client.query<Query, QuerynetsuiteVendorListArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getNetsuiteVendorList };
};
