import { useTranslation } from 'react-i18next';
import { EngagementForInsightCheck } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { useTranslateCountry } from '@/shared/hooks';
import { SpreadButton } from '@/shared/molecules';
import { formatIntNumber } from '@/shared/utils';
import { PostInsightStatus } from '../../PostInsightStatus';

interface Props {
  data?: readonly EngagementForInsightCheck[];
}

export const usePostsInsightListTable = ({ data = [] }: Props) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Campaign') },
    { title: t('HeaderColumn.Country') },
    { title: t('HeaderColumn.Campaign Duration') },
    { title: t('HeaderColumn.Number of Submissions') },
    { title: t('HeaderColumn.Status'), styles: { textAlign: 'center' } },
    { title: null }
  ];
  const rows: RowProps[] = data.map(
    ({ id, title, startDate, endDate, status, totalSubmissions, countries, totalConfirmations }) => [
      <TableCell.Link to="/posts-insight/$id" params={{ id: id.toString() }} label={title} />,
      <TableCell.Text value={countries.length > 0 ? countries.map(({ name }) => tc(name)).join(', ') : '-'} />,
      <TableCell.Period period={{ startDate, endDate }} />,
      <span>
        <span css={{ fontSize: '16px' }}>{formatIntNumber(totalConfirmations)}</span>/
        <span css={{ fontSize: '12px' }}>{formatIntNumber(totalSubmissions)}</span>
      </span>,
      <PostInsightStatus
        status={status}
        css={{ height: '24px', fontSize: '12px', padding: '5px 12px', minWidth: '105px' }}
      />,
      <SpreadButton
        options={[
          {
            icon: 'edit',
            label: t('Edit'),
            tooltip: { help: t('Button.Edit') },
            to: '/posts-insight/$id',
            params: { id: id.toString() }
          }
        ]}
      />
    ]
  );

  return { rows, columns };
};
