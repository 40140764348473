import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagementPostV2.graphql';
import { Mutation, MutationupdateEngagementPostV2Args } from '../../__generated__/globalTypes';

export const useUpdateEngagementPostV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationupdateEngagementPostV2Args>
) => {
  const [callUpdateEngagementPostV2, result] = useMutation<Mutation, MutationupdateEngagementPostV2Args>(
    MUTATION,
    options
  );

  return { callUpdateEngagementPostV2, ...result };
};

export type UpdateEngagementPostV2MutationFunction = Mutation['updateEngagementPostV2'];
