import {
  Order,
  InfluencerNewProfileHeaderV2,
  TwitterAnalyticsPostsSortOrder,
  useInfluencerTwitterAnalyticsPostsQuery
} from '@/graphql';
import { LIMIT_30 } from '@/shared/constants';
import { InfluencerTwitterAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsTwitterDashboardFeedPostsTemplate } from '../../../AnalyticsProfile';
import { InfluencerSelectedAccountInfo } from '../../types';

interface InfluencerAnalyticsTwitterProfileFeedPostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: InfluencerNewProfileHeaderV2['id'];
}

export const InfluencerAnalyticsTwitterProfileFeedPosts = ({
  socialAccount: { id: socialAccountId, name }
}: InfluencerAnalyticsTwitterProfileFeedPostsProps) => {
  const { data, loading } = useInfluencerTwitterAnalyticsPostsQuery({
    variables: {
      offset: 0,
      limit: LIMIT_30,
      socialAccountId,
      orderBy: { field: TwitterAnalyticsPostsSortOrder.POST_DATE, order: Order.DESC }
    }
  });

  const posts = data?.influencerTwitterAnalyticsPosts.posts || [];

  return (
    <>
      <AnalyticsTwitterDashboardFeedPostsTemplate
        data={posts}
        loading={loading}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/twitter_feed_posts',
          params: { socialMediaAccountId: socialAccountId.toString() },
          search: { filter: { name } }
        }}
      />

      <InfluencerTwitterAnalyticsPostDetailsModal
        posts={posts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: socialAccountId }))}
      />
    </>
  );
};
