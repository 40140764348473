import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import {
  InfluencerTwitterProfilePosts,
  InfluencerTwitterProfileOverview,
  InfluencerTwitterProfileSponsoredPosts,
  InfluencerTwitterProfileSimilarInfluencers
} from '../../InfluencerProfiles';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerTwitterProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerTwitterProfile = ({ socialAccount }: InfluencerTwitterProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerTwitterProfileOverview influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerTwitterProfilePosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
      <InfluencerTwitterProfileSponsoredPosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
      <InfluencerTwitterProfileSimilarInfluencers influencerProfile={influencerProfile} socialAccount={socialAccount} />
    </>
  );
};
