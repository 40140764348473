import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeCmsConfirmedAssetsSummary.graphql';
import { Query, QueryyoutubeCmsConfirmedAssetsSummaryArgs } from '../../__generated__/globalTypes';

export const useYoutubeCmsConfirmedAssetsSummaryQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeCmsConfirmedAssetsSummaryArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeCmsConfirmedAssetsSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeCmsConfirmedAssetsSummaryLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeCmsConfirmedAssetsSummaryArgs>
): QueryResult<Query, QueryyoutubeCmsConfirmedAssetsSummaryArgs> & {
  getYoutubeCmsConfirmedAssetsSummary: LazyQueryExecFunction<Query, QueryyoutubeCmsConfirmedAssetsSummaryArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeCmsConfirmedAssetsSummary, result] = useLazyQuery<Query, QueryyoutubeCmsConfirmedAssetsSummaryArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getYoutubeCmsConfirmedAssetsSummary, ...result };
};

export const useYoutubeCmsConfirmedAssetsSummaryPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeCmsConfirmedAssetsSummary = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeCmsConfirmedAssetsSummaryArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeCmsConfirmedAssetsSummaryArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeCmsConfirmedAssetsSummary };
};
