import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateAdvertiserUserSettings.graphql';
import { Mutation, MutationupdateAdvertiserUserSettingsArgs } from '../../__generated__/globalTypes';

export const useUpdateAdvertiserUserSettingsMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateAdvertiserUserSettingsArgs>
) => {
  const [callUpdateAdvertiserUserSettings, result] = useMutation<Mutation, MutationupdateAdvertiserUserSettingsArgs>(
    MUTATION,
    options
  );

  return { callUpdateAdvertiserUserSettings, ...result };
};

export type UpdateAdvertiserUserSettingsMutationFunction = Mutation['updateAdvertiserUserSettings'];
