import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DenyEngagementInfluencerProposalList.graphql';
import { Mutation, MutationdenyEngagementInfluencerProposalListArgs } from '../../__generated__/globalTypes';

export const useDenyEngagementInfluencerProposalListMutation = (
  options?: MutationHookOptions<Mutation, MutationdenyEngagementInfluencerProposalListArgs>
) => {
  const [callDenyEngagementInfluencerProposalList, result] = useMutation<
    Mutation,
    MutationdenyEngagementInfluencerProposalListArgs
  >(MUTATION, options);

  return { callDenyEngagementInfluencerProposalList, ...result };
};

export type DenyEngagementInfluencerProposalListMutationFunction = Mutation['denyEngagementInfluencerProposalList'];
