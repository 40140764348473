import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteEngagementSocialAccountProposal.graphql';
import { Mutation, MutationdeleteEngagementSocialAccountProposalArgs } from '../../__generated__/globalTypes';

export const useDeleteEngagementSocialAccountProposalMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteEngagementSocialAccountProposalArgs>
) => {
  const [callDeleteEngagementSocialAccountProposal, result] = useMutation<
    Mutation,
    MutationdeleteEngagementSocialAccountProposalArgs
  >(MUTATION, options);

  return { callDeleteEngagementSocialAccountProposal, ...result };
};

export type DeleteEngagementSocialAccountProposalMutationFunction = Mutation['deleteEngagementSocialAccountProposal'];
