import { Navigate } from '@tanstack/react-router';
import { SocialAccountType } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '@/shared/organisms';
import { InfluencerAnalyticsFacebookProfile } from './InfluencerAnalyticsFacebookProfile';
import { InfluencerAnalyticsInstagramProfile } from './InfluencerAnalyticsInstagramProfile';
import { InfluencerAnalyticsTwitterProfile } from './InfluencerAnalyticsTwitterProfile';
import { InfluencerAnalyticsYoutubeProfile } from './InfluencerAnalyticsYoutubeProfile';

interface InfluencerAnalyticsProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerAnalyticsProfile = ({ socialAccount }: InfluencerAnalyticsProfileProps) => {
  const defaultProps = { socialAccount };

  switch (socialAccount.type) {
    case SocialAccountType.YOUTUBE:
      return <InfluencerAnalyticsYoutubeProfile {...defaultProps} />;
    case SocialAccountType.TWITTER:
      return <InfluencerAnalyticsTwitterProfile {...defaultProps} />;
    case SocialAccountType.INSTAGRAM:
      return <InfluencerAnalyticsInstagramProfile {...defaultProps} />;
    case SocialAccountType.FACEBOOK_PAGE:
      return <InfluencerAnalyticsFacebookProfile {...defaultProps} />;
    default:
      return <Navigate to="/influencers" />;
  }
};
