import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Icon } from '@/shared/atoms';
import { useChatDemoContext } from '../ChatDemoProvider';

export interface ChatDemoHeaderProps {
  onClear?: () => void;
}

export const ChatDemoHeader = ({ onClear }: ChatDemoHeaderProps) => {
  const { toggleHistoryList } = useChatDemoContext();

  return (
    <div css={styles.wrapper}>
      <Icon
        size={18}
        icon="chat-demo-history"
        css={{ cursor: 'pointer' }}
        onClick={toggleHistoryList}
        color={THEME.icon.colors.gray.lv2}
      />

      {onClear ? (
        <>
          <p css={{ fontSize: '14px', fontWeight: 500, lineHeight: '18px' }}>Search Influencer</p>

          <div css={{ display: 'flex' }}>
            <Icon
              size={18}
              onClick={onClear}
              icon="chat-demo-clear"
              css={{ cursor: 'pointer' }}
              color={THEME.icon.colors.gray.lv2}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

const styles = {
  wrapper: css({
    top: 0,
    zIndex: 1,
    flex: 'none',
    height: '48px',
    display: 'flex',
    padding: '0 24px',
    position: 'sticky',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0px 2px 1px 0px rgba(110, 124, 137, 0.05), 0px 0px 2px 0px rgba(110, 124, 137, 0.25)'
  })
};
