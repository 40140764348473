import { Outlet } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import {
  DouyinInfluencersSearchSchema,
  FacebookInfluencersSearchSchema,
  FacebookPageInfluencersSearchSchema,
  InstagramInfluencersSearchSchema,
  ThreadsInfluencersSearchSchema,
  TiktokInfluencersSearchSchema,
  TwitterInfluencersSearchSchema,
  XhsInfluencersSearchSchema,
  YoutubeInfluencersSearchSchema,
  ProposeInfluencersSearchSchema,
  AllInfluencersSearchSchema
} from '@/shared/organisms';
import {
  AllInfluencersSearch,
  DouyinInfluencersSearch,
  FacebookInfluencersSearch,
  FacebookPageInfluencersSearch,
  InfluencersSearch,
  InstagramInfluencersSearch,
  ProposeInfluencers,
  ThreadsInfluencersSearch,
  TiktokInfluencersSearch,
  TwitterInfluencersSearch,
  XhsInfluencersSearch,
  YoutubeInfluencersSearch,
  InfluencersRoot
} from '@/pages/Influencers';
import { adminStaffAgencyTalentAgencyPartnerRoles } from '@/shared/constants';
import { PermissionRoute } from '@/app-components';
import { PartialRouter } from '../../types';

export const InfluencersRouter = {
  '/_private-routes/influencers': [
    () => <PermissionRoute permissions={adminStaffAgencyTalentAgencyPartnerRoles} element={<InfluencersRoot />} />,
    {}
  ],
  '/_private-routes/influencers/': [() => <Outlet />, {}],
  '/_private-routes/influencers/proposal': [
    ProposeInfluencers,
    { validateSearch: zodValidator(ProposeInfluencersSearchSchema) }
  ],
  '/_private-routes/influencers/search': [InfluencersSearch, {}],
  '/_private-routes/influencers/search/': [
    AllInfluencersSearch,
    { validateSearch: zodValidator(AllInfluencersSearchSchema) }
  ],
  '/_private-routes/influencers/search/douyin': [
    DouyinInfluencersSearch,
    { validateSearch: zodValidator(DouyinInfluencersSearchSchema) }
  ],
  '/_private-routes/influencers/search/facebook_page': [
    FacebookPageInfluencersSearch,
    { validateSearch: zodValidator(FacebookPageInfluencersSearchSchema) }
  ],
  '/_private-routes/influencers/search/facebook': [
    FacebookInfluencersSearch,
    { validateSearch: zodValidator(FacebookInfluencersSearchSchema) }
  ],
  '/_private-routes/influencers/search/instagram': [
    InstagramInfluencersSearch,
    { validateSearch: zodValidator(InstagramInfluencersSearchSchema) }
  ],
  '/_private-routes/influencers/search/threads': [
    ThreadsInfluencersSearch,
    { validateSearch: zodValidator(ThreadsInfluencersSearchSchema) }
  ],
  '/_private-routes/influencers/search/tiktok': [
    TiktokInfluencersSearch,
    {
      validateSearch: zodValidator(TiktokInfluencersSearchSchema)
    }
  ],
  '/_private-routes/influencers/search/twitter': [
    TwitterInfluencersSearch,
    { validateSearch: zodValidator(TwitterInfluencersSearchSchema) }
  ],
  '/_private-routes/influencers/search/xhs': [
    XhsInfluencersSearch,
    { validateSearch: zodValidator(XhsInfluencersSearchSchema) }
  ],
  '/_private-routes/influencers/search/youtube': [
    YoutubeInfluencersSearch,
    { validateSearch: zodValidator(YoutubeInfluencersSearchSchema) }
  ]
} as const satisfies PartialRouter;
