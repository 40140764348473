import { CreateEngagementInput, EngagementCampaignStatus, EngagementDetail } from '@/graphql';
import { DisabledFields } from '@/shared/types';
import { EngagementCampaignFormKeys, EngagementCampaignFormValues } from './types';

const disabledFieldsUpComing: DisabledFields<EngagementCampaignFormKeys> = { advertiser: true };
const disabledFieldsOnGoing: DisabledFields<EngagementCampaignFormKeys> = {
  ...disabledFieldsUpComing,
  materials: true,
  countryIds: true,
  couponList: true,
  isTtcmCampaign: true,
  landingPageUrl: true,
  trackingOptions: true,
  agencyMarginRate: true,
  couponUploadFiles: true
};
const disabledFieldsFinished: DisabledFields<EngagementCampaignFormKeys> = {
  ...disabledFieldsOnGoing,
  title: true,
  budget: true,
  objective: true,
  serviceUrl: true,
  socialMedias: true,
  campaignMaterialLink: true,
  defaultPostRequirement: true
};
const disabledFieldsDemo: DisabledFields<EngagementCampaignFormKeys> = {
  currency: true,
  materials: true,
  countryIds: true,
  advertiser: true,
  agencyMarginRate: true
};
export const getDisabledFields = (status?: EngagementCampaignStatus): DisabledFields<EngagementCampaignFormKeys> => {
  switch (status) {
    case EngagementCampaignStatus.DRAFT:
    case EngagementCampaignStatus.REVIEWING:
      return {};
    case EngagementCampaignStatus.UPCOMING:
      return disabledFieldsUpComing;
    case EngagementCampaignStatus.ONGOING:
      return disabledFieldsOnGoing;
    case EngagementCampaignStatus.DEMO:
      return disabledFieldsDemo;
    default:
      return disabledFieldsFinished;
  }
};

export const defaultEngagementCampaignFormValues: EngagementCampaignFormValues = {
  isAdminStaff: false,
  id: undefined,
  title: '',
  budget: '',
  currency: '',
  materials: [],
  objective: '',
  salesPics: [],
  countryIds: [],
  thumbnail: '',
  serviceUrl: '',
  couponList: '',
  advertiser: null,
  socialMedias: [],
  oldCouponList: '',
  newCouponList: '',
  hubspotDealIds: [],
  landingPageUrl: '',
  trackingOptions: [],
  agencyMarginRate: '',
  isTtcmCampaign: false,
  couponUploadFiles: [],
  serviceInformation: '',
  campaignCategoryId: '',
  numberOfInfluencers: '',
  campaignMaterialLink: '',
  needToSendCurrency: false,
  defaultPostRequirement: '',
  influencerManagementPics: [],
  status: EngagementCampaignStatus.DRAFT,
  period: { startDate: '', endDate: '' }
};

export const getDefaultEngagementCampaignFormValues = (
  engagement?: EngagementDetail
): EngagementCampaignFormValues => ({
  ...defaultEngagementCampaignFormValues,
  id: engagement?.id || defaultEngagementCampaignFormValues.id,
  title: engagement?.title || defaultEngagementCampaignFormValues.title,
  status: engagement?.status || defaultEngagementCampaignFormValues.status,
  budget: engagement?.budget ?? defaultEngagementCampaignFormValues.budget,
  currency: engagement?.currency || defaultEngagementCampaignFormValues.currency,
  materials: engagement?.materials || defaultEngagementCampaignFormValues.materials,
  objective: engagement?.objective || defaultEngagementCampaignFormValues.objective,
  serviceUrl: engagement?.serviceUrl || defaultEngagementCampaignFormValues.serviceUrl,
  couponList: engagement?.couponList || defaultEngagementCampaignFormValues.couponList,
  oldCouponList: engagement?.couponList || defaultEngagementCampaignFormValues.oldCouponList,
  socialMedias: engagement?.socialMedias || defaultEngagementCampaignFormValues.socialMedias,
  hubspotDealIds: engagement?.hubspotDealIds || defaultEngagementCampaignFormValues.hubspotDealIds,
  isTtcmCampaign: engagement?.isTtcmCampaign ?? defaultEngagementCampaignFormValues.isTtcmCampaign,
  landingPageUrl: engagement?.landingPageUrl || defaultEngagementCampaignFormValues.landingPageUrl,
  trackingOptions: engagement?.trackingOptions || defaultEngagementCampaignFormValues.trackingOptions,
  advertiser: engagement?.advertiser
    ? { value: engagement.advertiser.id.toString(), label: engagement.advertiser.name }
    : defaultEngagementCampaignFormValues.advertiser || null,
  campaignMaterialLink: engagement?.materialUrl || defaultEngagementCampaignFormValues.campaignMaterialLink,
  couponUploadFiles: engagement?.couponUploadFiles || defaultEngagementCampaignFormValues.couponUploadFiles,
  thumbnail: engagement?.thumbNail || defaultEngagementCampaignFormValues.thumbnail,
  serviceInformation: engagement?.serviceInformation || defaultEngagementCampaignFormValues.serviceInformation,
  salesPics: engagement?.salesPics?.map(({ id }) => String(id)) || defaultEngagementCampaignFormValues.salesPics,
  numberOfInfluencers:
    typeof engagement?.numberOfInfluencers === 'number'
      ? engagement?.numberOfInfluencers.toString()
      : defaultEngagementCampaignFormValues.numberOfInfluencers,
  defaultPostRequirement:
    engagement?.defaultPostRequirement || defaultEngagementCampaignFormValues.defaultPostRequirement,
  campaignCategoryId: String(
    engagement?.campaignCategory?.id || defaultEngagementCampaignFormValues.campaignCategoryId
  ),
  period: {
    startDate: engagement?.startDate || defaultEngagementCampaignFormValues.period.startDate,
    endDate: engagement?.endDate || defaultEngagementCampaignFormValues.period.endDate
  },
  influencerManagementPics:
    engagement?.influencerManagementPics?.map(({ id }) => String(id)) ||
    defaultEngagementCampaignFormValues.influencerManagementPics,
  agencyMarginRate:
    typeof engagement?.adAgencyMarginRate === 'number'
      ? (engagement.adAgencyMarginRate * 100).toString()
      : defaultEngagementCampaignFormValues.agencyMarginRate,
  countryIds:
    engagement?.countries?.reduce<string[]>((rs, country) => (country ? [...rs, country.id] : rs), []) ||
    defaultEngagementCampaignFormValues.countryIds,
  needToSendCurrency:
    (engagement?.countries.length ?? 0) > 1 ? true : defaultEngagementCampaignFormValues.needToSendCurrency
});
export const formatEngagementFormVariables = (values: EngagementCampaignFormValues): CreateEngagementInput => ({
  title: values.title,
  materials: values.materials,
  objective: values.objective,
  budget: Number(values.budget),
  serviceUrl: values.serviceUrl,
  countryIds: values.countryIds,
  couponList: values.couponList,
  endDate: values.period.endDate as string,
  socialMedias: values.socialMedias,
  startDate: values.period.startDate as string,
  isTtcmCampaign: values.isTtcmCampaign,
  hubspotDealIds: values.hubspotDealIds,
  thumbNail: values.thumbnail || '',
  trackingOptions: values.trackingOptions,
  materialUrl: values.campaignMaterialLink,
  advertiserId: Number(values.advertiser?.value),
  couponUploadFiles: values.couponUploadFiles,
  landingPageUrl: values.landingPageUrl || null,
  campaignCategoryId: Number(values.campaignCategoryId),
  serviceInformation: values.serviceInformation || null,
  defaultPostRequirement: values.defaultPostRequirement,
  salesPicIds: values.salesPics.map((salesId) => +salesId),
  influencerManagementPicIds: values.influencerManagementPics.map((inflId) => +inflId),
  adAgencyMarginRate: values.agencyMarginRate ? Number(values.agencyMarginRate) / 100 : null,
  numberOfInfluencers: values.numberOfInfluencers !== '' ? Number(values.numberOfInfluencers) : null,
  ...(values.needToSendCurrency && values.currency && { currency: values.currency })
});
