import { InfluencerNewProfileHeaderV2 } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfilePostsWrapper } from '../../shared';
import { InfluencerDouyinProfileFeedPosts } from './InfluencerDouyinProfileFeedPosts';
import { InfluencerDouyinProfilePostsOverview } from './InfluencerDouyinProfilePostsOverview';

export interface InfluencerDouyinProfilePostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerDouyinProfilePosts = ({
  socialAccount,
  influencerProfile
}: InfluencerDouyinProfilePostsProps) => (
  <InfluencerProfilePostsWrapper socialAccount={socialAccount} influencerProfile={influencerProfile}>
    {({ data }) => (
      <>
        <InfluencerDouyinProfilePostsOverview
          data={data}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
        <InfluencerDouyinProfileFeedPosts
          data={data?.feedPosts || []}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
      </>
    )}
  </InfluencerProfilePostsWrapper>
);
