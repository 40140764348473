import { CSSObject, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { UGCPostStatus as UGCPostStatusType } from '@/graphql';
import { IconNames } from '@/shared/assets';
import { Icon } from '@/shared/atoms';

export interface UGCPostStatusProps {
  status: UGCPostStatusType | null;
}

export const UGCPostStatus = ({ status }: UGCPostStatusProps) => {
  const { t } = useTranslation();

  const isNotRequested = status === UGCPostStatusType.NOT_REQUESTED;
  const statusData = ((): {
    label: string;
    iconProps: { icon: IconNames; size: number };
    css: CSSObject;
  } | null => {
    switch (status) {
      case UGCPostStatusType.REQUESTED:
        return {
          label: t('Option.UGC Requested'),
          iconProps: { icon: 'warning-circle', size: 14 },
          css: { color: '#FFB63D', backgroundColor: '#FFF4E2' }
        };
      case UGCPostStatusType.APPROVED:
        return {
          label: t('Option.UGC Approved'),
          iconProps: { icon: 'success', size: 14 },
          css: { color: '#40B87C', backgroundColor: '#E2F4EB' }
        };
      case UGCPostStatusType.REJECTED:
        return {
          label: t('Option.UGC Rejected'),
          iconProps: { icon: 'close', size: 9 },
          css: { color: '#FF5F5F', backgroundColor: '#FFE7E7' }
        };
      default:
        return null;
    }
  })();

  return !isNotRequested && statusData ? (
    <div css={[styles.wrapper, statusData.css]}>
      <Icon {...statusData.iconProps} color={statusData.css?.color as string} />
      {statusData.label}
    </div>
  ) : null;
};
const styles = {
  wrapper: css({
    top: 0,
    left: 0,
    zIndex: 1,
    fontWeight: 600,
    gap: '4px',
    display: 'flex',
    padding: '4px',
    fontSize: '10px',
    position: 'absolute',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottomRightRadius: '5px'
  })
};
