import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateAdvertiser.graphql';
import { Mutation, MutationupdateAdvertiserArgs } from '../../__generated__/globalTypes';

export const useUpdateAdvertiserMutation = (options?: MutationHookOptions<Mutation, MutationupdateAdvertiserArgs>) => {
  const [callUpdateAdvertiser, result] = useMutation<Mutation, MutationupdateAdvertiserArgs>(MUTATION, options);

  return { callUpdateAdvertiser, ...result };
};

export type UpdateAdvertiserMutationFunction = Mutation['updateAdvertiser'];
