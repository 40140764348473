import { useYoutubeCMSFilter } from '@/shared/organisms';
import { getOffset, getValuableVariables } from '@/shared/utils';
import {
  useAllYoutubeCmsConfirmedAssetsQuery,
  useAllYoutubeCmsConfirmedChannelsQuery,
  useYoutubeCmsConfirmedAssetsSummaryQuery,
  useYoutubeCmsConfirmedChannelsSummaryQuery
} from '@/graphql';
import { useYoutubeCMSContext } from '../YoutubeCMSContext';

export const useYoutubeCMSConfirmedData = () => {
  const { canModifyYoutubeCMSData } = useYoutubeCMSContext();
  const {
    page,
    limit,
    isSegmentAssets,
    isSegmentChannels,
    filter: { keyword, month }
  } = useYoutubeCMSFilter();

  const variables = getValuableVariables({ keyword, month });
  const listVariables = { ...variables, limit, offset: getOffset(page, limit) };

  const { data: channelSummaryData, loading: fetchingChannelSummary } = useYoutubeCmsConfirmedChannelsSummaryQuery({
    variables,
    notifyOnNetworkStatusChange: true,
    skip: canModifyYoutubeCMSData || !month || !isSegmentChannels
  });
  const { data: assetsSummaryData, loading: fetchingAssetsSummary } = useYoutubeCmsConfirmedAssetsSummaryQuery({
    variables,
    notifyOnNetworkStatusChange: true,
    skip: canModifyYoutubeCMSData || !month || !isSegmentAssets
  });
  const { data: channelsData, loading: fetchingYtChannels } = useAllYoutubeCmsConfirmedChannelsQuery({
    variables: listVariables,
    notifyOnNetworkStatusChange: true,
    skip: canModifyYoutubeCMSData || !month || !isSegmentChannels
  });
  const { data: assetsData, loading: fetchingYtAssets } = useAllYoutubeCmsConfirmedAssetsQuery({
    variables: listVariables,
    notifyOnNetworkStatusChange: true,
    skip: canModifyYoutubeCMSData || !month || !isSegmentAssets
  });

  return {
    assetsData: assetsData?.allYoutubeCmsConfirmedAssets || [],
    channelsData: channelsData?.allYoutubeCmsConfirmedChannels || [],
    fetchingData: fetchingYtChannels || fetchingYtAssets,
    fetchingSummary: fetchingChannelSummary || fetchingAssetsSummary,
    totalRecords: isSegmentAssets
      ? assetsData?.allYoutubeCmsConfirmedAssetsCount?.totalNumber
      : channelsData?.allYoutubeCmsConfirmedChannelsCount?.totalNumber,
    summary: isSegmentAssets
      ? assetsSummaryData?.youtubeCmsConfirmedAssetsSummary || undefined
      : channelSummaryData?.youtubeCmsConfirmedChannelsSummary || undefined
  };
};
