import { ListEmptyProps, Table } from '@/shared/atoms';
import { useSelectItem } from '@/shared/hooks';
import { ConfirmDeleteModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { useAccountsListTable, AccountsListTableProps } from './hooks';
import { AccountType, DeleteAccountType } from './types';

export interface AccountsListProps
  extends TableData<AccountType>,
    Pick<AccountsListTableProps, 'domain' | 'editRoute' | 'firstColumnTitle'> {
  deleting?: boolean;
  empty?: ListEmptyProps;
  onDeleteAccount?: (account: DeleteAccountType) => Promise<void>;
}

export const AccountsList = ({
  empty,
  domain,
  loading,
  deleting,
  data = [],
  editRoute,
  onDeleteAccount,
  firstColumnTitle
}: AccountsListProps) => {
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem();
  const { rows, columns } = useAccountsListTable({
    data,
    domain,
    editRoute,
    firstColumnTitle,
    onDeleting: onDeleteAccount ? setSelectedItem : undefined
  });

  const handleDeleteAccount = async () => {
    if (selectedItem) {
      await onDeleteAccount?.(selectedItem);
    }
    resetSelectedItem();
  };

  return (
    <>
      <Table loading={loading} data={{ rows, columns }} noTopBorderRadius empty={empty} hasBorder />

      <ConfirmDeleteModal
        deleting={deleting}
        onClose={resetSelectedItem}
        deletingItem={selectedItem}
        onDelete={handleDeleteAccount}
      />
    </>
  );
};

export * from './types';
