import { ProposalJobDescriptionBreakDownMode, UserProposal } from '@/graphql';
import { ProposedInfluencer, RowData, RowInfluencerData } from './schemaTypes';

export const getInitialInfluencers = (proposal?: UserProposal | null): ProposedInfluencer[] =>
  !proposal
    ? []
    : proposal.influencers.map(({ accountList, summary, ...restInfluencerInfo }) => ({
        accountList,
        ...summary,
        ...restInfluencerInfo
      }));

export const splitProposalInfluencersByIsDenied = (proposalInfluencers: readonly ProposedInfluencer[]) =>
  proposalInfluencers.reduce<{
    rejectedInfluencers: ProposedInfluencer[];
    selectedInfluencers: ProposedInfluencer[];
  }>(
    (rs, infl) => ({
      rejectedInfluencers: infl.isDenied ? [...rs.rejectedInfluencers, infl] : rs.rejectedInfluencers,
      selectedInfluencers: !infl.isDenied ? [...rs.selectedInfluencers, infl] : rs.selectedInfluencers
    }),
    {
      selectedInfluencers: [],
      rejectedInfluencers: []
    }
  );

export const getProposedInfluencersRowsData = (
  influencers: ProposedInfluencer[],
  jdMode?: ProposalJobDescriptionBreakDownMode
) =>
  influencers.reduce<RowData[]>((results, { accountList, ...influencer }) => {
    const currentIndex = results.length;
    const hasSocialAccount = jdMode === ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN && !!accountList;
    const influencerData: RowInfluencerData = {
      id: influencer.id,
      name: influencer.name,
      status: influencer.status,
      avatar: influencer.avatar
    };
    results.push({
      index: currentIndex,
      influencer: influencerData,
      stats: {
        budget: influencer.budget || 0,
        profit: influencer.profit || 0,
        engagementRateByViews: influencer.engagementViewRate,
        engagementRateByFollowers: influencer.engagementFollowerRate,
        followersCount: influencer.followersCount ? Number(influencer.followersCount) : null,
        influencerCost: influencer.influencerCost || 0,
        addedDate: influencer.addedDate,
        engagementPosted: influencer.engagementPostedCount,
        engagementProposed: influencer.engagementProposedCount
      }
    });

    if (hasSocialAccount) {
      accountList.forEach(({ id: accId, name: accName, socialMedia: accSm, summary, addedDate }, index) => {
        results.push({
          index: currentIndex + index + 1,
          influencer: influencerData,
          socialAccount: {
            id: accId,
            name: accName,
            socialMedia: accSm,
            lastIndex: index === accountList.length - 1
          },
          stats: {
            budget: summary.budget || 0,
            profit: summary.profit || 0,
            engagementRateByViews: summary.engagementViewRate,
            engagementRateByFollowers: summary.engagementFollowerRate,
            followersCount: summary.followersCount ? Number(summary.followersCount) : null,
            influencerCost: summary.influencerCost || 0,
            addedDate,
            engagementPosted: summary.engagementPostedCount,
            engagementProposed: summary.engagementProposedCount
          }
        });
      });
    }

    return results;
  }, []);
