import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Option } from '@/shared/types';
import { FormStyle, Radio } from '@/shared/atoms';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

interface RadioOptions<V, L> extends Option<V, L> {
  help?: ReactNode;
  children?: ReactNode;
}

export interface RadiosFieldProps<
  T extends string = string,
  V = string,
  O extends RadioOptions<V, ReactNode> = RadioOptions<V, ReactNode>
> extends DefaultFieldProps<T, V, O> {
  label?: ReactNode;
  itemsPerRow?: number;
}

export const RadiosField = <
  T extends string,
  V = string,
  O extends RadioOptions<V, ReactNode> = RadioOptions<V, ReactNode>
>(
  props: RadiosFieldProps<T, V, O>
) => {
  const {
    fieldValue,
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldProps: { name, disabled, onChange, itemsPerRow, options = [] }
  } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <FormStyle.FieldsGroup itemsPerRow={itemsPerRow} css={!fieldWrapperProps.title && styles.fieldsGroup}>
        {options.map((option, index) => (
          <FormStyle.FieldWrapper
            key={index}
            css={[{ flex: 'none' }, itemsPerRow === 1 && { paddingTop: '8px', paddingBottom: '8px' }]}
          >
            <Radio
              iconMargin="4px"
              help={option.help}
              label={option.label}
              checked={option.value === fieldValue}
              disabled={option.disabled || disabled}
              onClick={() =>
                onChange ? onChange(name, option.value, options) : setValue<any>(name, option.value, setValueOptions)
              }
            />
          </FormStyle.FieldWrapper>
        ))}
      </FormStyle.FieldsGroup>
    </RHFFieldWrapper>
  );
};
const styles = {
  fieldsGroup: (theme: any) =>
    css({
      '--gap': `-${theme.form.gapRow / 2}px`,

      marginTop: 'var(--gap)',
      marginBottom: 'var(--gap)'
    })
};
