import { useTranslation } from 'react-i18next';

import { THEME } from '@/shared/constants';
import { TextField, TextFieldProps } from '@/shared/molecules';

import { MarketplaceFormKeys } from '../types';

export const LandingPageURLField = (props: Omit<TextFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return (
    <div css={{ marginTop: '16px' }}>
      <h3 css={{ fontSize: '14px', fontWeight: 400, lineHeight: THEME.input.text.lineHeight }}>
        {t('landingPageTitle')}
        {props.required && <span css={{ color: 'tomato' }}>&nbsp;*</span>}
      </h3>
      <span css={{ color: THEME.text.colors.gray.lv3, fontSize: '12px' }}>{t('landingPageDescription_1')}</span>
      <br />
      <span css={{ color: THEME.text.colors.gray.lv3, fontSize: '12px' }}>{t('landingPageDescription_2')}</span>
      <TextField
        placeholder="https://anytag.jp/"
        help={t('Tooltip.Landing URL Help')}
        name={MarketplaceFormKeys.landingPageUrl}
        css={{ paddingTop: '4px' }}
        {...props}
      />
    </div>
  );
};
