import { useTranslation } from 'react-i18next';

import { TextField, TextFieldProps } from '@/shared/molecules';

import { MarketplaceFormKeys } from '../types';

export const MarginRateField = (props: Omit<TextFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return (
    <TextField
      unit="%"
      placeholder="33"
      name={MarketplaceFormKeys.marginRate}
      title={t('TextForm.AnyMind Margin Rate')}
      help={t('Tooltip.anyMindMarginRateHelp')}
      {...props}
    />
  );
};
