import { useTranslation } from 'react-i18next';
import { useUserRoles, useReportPermissions, useEngagementPermissions, useMarketplacePermissions } from '@/auth';
import { InfluencerNewProfileHeaderV2 } from '@/graphql';
import { Tabs, TabsProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface InfluencerTabsProps {
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerTabs = ({
  influencerProfile: { id, youtubeAccounts, isTaggedToCompany }
}: InfluencerTabsProps) => {
  const { t } = useTranslation();
  const { hideReportLink } = useReportPermissions();
  const { hideEngagementReportLink } = useEngagementPermissions();
  const { hideMarketplaceReportLink } = useMarketplacePermissions();
  const { isAdminStaff, isAdminStaffTalentAgencyPartner } = useUserRoles();

  const hasCompareTab = youtubeAccounts?.findIndex((account) => account.analyticsEnabled) !== -1;
  const hasCampaignList = !hideReportLink && (!hideEngagementReportLink || !hideMarketplaceReportLink);
  const options: TabsProps['options'] = [
    {
      end: true,
      label: t('Influencer Profile'),
      to: '/influencer/$id/profile',
      params: { id: id.toString() }
    },
    {
      end: false,
      label: t('Compare'),
      hidden: !isAdminStaffTalentAgencyPartner || !hasCompareTab,
      to: '/influencer/$id/profile/compare',
      params: { id: id.toString() }
    },
    {
      end: true,
      label: t('Campaign List'),
      hidden: !isAdminStaff || isTaggedToCompany === false || !hasCampaignList,
      to: '/influencer/$id/profile/campaigns',
      params: { id: id.toString() }
    }
  ];

  return options.length ? (
    <Tabs
      options={options}
      hasBorder={false}
      css={{ padding: '0 24px', marginBottom: '16px', boxShadow: THEME.shadows.boxShadow }}
    />
  ) : null;
};
