import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import {
  MarketplacePost,
  MarketplaceDetail,
  MarketplaceCampaignStatus,
  MarketplaceCampaignPostStatus
} from '@/graphql';
import { BlackBar, Button } from '@/shared/atoms';
import { useSelectItems, useToggleState } from '@/shared/hooks';
import { SingleSelector } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { useUpdateMarketplacePostsStatus } from '../hooks';

interface ActionBarProps extends ReturnType<typeof useSelectItems<MarketplacePost, keyof MarketplacePost>> {
  marketplace: MarketplaceDetail;
  isDirty: boolean;
  costLoading: boolean;
}

export const ActionBar = ({
  isDirty,
  allItemIds,
  costLoading,
  selectedItems,
  selectedItemIds,
  setSelectedItemIds,
  hasAllItemsSelected,
  toggleSelectAllItems,
  marketplace: { id: marketplaceId, status, isGiftingCampaign }
}: ActionBarProps) => {
  const { t } = useTranslation();
  const loadingState = useToggleState();
  const { isAdminStaff } = useUserRoles();
  const { handleSelectStatus, UpdateMarketplacePostStatusModal } = useUpdateMarketplacePostsStatus({ marketplaceId });

  const numberSelectedIds = selectedItemIds.length;
  const options = [
    {
      label: t<string>('Option.Approve post'),
      value: MarketplaceCampaignPostStatus.APPROVED,
      hidden: status !== MarketplaceCampaignStatus.FINISHED || !isAdminStaff
    },
    {
      hidden: !isGiftingCampaign,
      label: t<string>('Option.Shipped'),
      value: MarketplaceCampaignPostStatus.SHIPPED
    },
    {
      label: t<string>('Option.Request to modify'),
      value: MarketplaceCampaignPostStatus.WARNING
    },
    {
      label: t<string>('Option.Change to “Effective”'),
      value: MarketplaceCampaignPostStatus.WARNING_SOLVED
    },
    {
      hidden: !isAdminStaff,
      label: t<string>('Option.Mark as “Ineffective”'),
      value: MarketplaceCampaignPostStatus.INEFFECTIVE
    }
  ].filter((option) => !option.hidden) as Option<MarketplaceCampaignPostStatus>[];

  return numberSelectedIds > 0 || isDirty ? (
    <>
      <BlackBar
        disabled={loadingState.status}
        selectAllProps={{ onClick: toggleSelectAllItems }}
        checkboxProps={
          allItemIds.length
            ? {
                onClick: toggleSelectAllItems,
                indeterminate: !hasAllItemsSelected,
                label: t('Selected', { count: numberSelectedIds })
              }
            : undefined
        }
      >
        <SingleSelector
          name="status"
          options={options}
          disabled={loadingState.status}
          css={{ width: '180px', marginLeft: '16px' }}
          onChange={(value) => handleSelectStatus(value as MarketplaceCampaignPostStatus, selectedItems)}
          hideDeselectOption
        />
        <Button
          variant="blue"
          title={t('Button.Save')}
          type="submit"
          css={{ marginLeft: 'auto', padding: '0 16px' }}
          disabled={!isDirty}
          loading={costLoading}
        />
      </BlackBar>

      <UpdateMarketplacePostStatusModal onSuccess={() => setSelectedItemIds([])} />
    </>
  ) : null;
};
