import {
  Genders,
  TTCMStatus,
  SocialAccountStatus,
  AutoManagedCampaignType,
  FollowersGrowthPeriodEnum,
  InfluencerStatusForSearch,
  AnalyticsSubscriptionPlanType,
  AutoManagedCampaignStatus
} from '@/graphql';
import { ToggleOption, TtcmStatusOption } from '@/shared/types';

import {
  MAX_AGE,
  MIN_AGE,
  MAX_AGE_V2,
  MAX_ENGAGEMENT_RATE,
  MAX_JOINED_CAMPAIGNS,
  MIN_ENGAGEMENT_RATE,
  MIN_JOINED_CAMPAIGNS
} from './filter';

export const FOLLOWER_GROWTH_PERIOD_OPTIONS = [
  { value: FollowersGrowthPeriodEnum.ONE_MONTH, label: '1 month' },
  { value: FollowersGrowthPeriodEnum.THREE_MONTHS, label: '3 months' },
  { value: FollowersGrowthPeriodEnum.SIX_MONTHS, label: '6 months' },
  { value: FollowersGrowthPeriodEnum.ONE_YEAR, label: '1 year' }
];

export const FOLLOWER_GROWTH_RATE_RATIO_OPTIONS = [
  { value: '0', label: '0%' },
  { value: '10', label: '10%' },
  { value: '20', label: '20%' },
  { value: '30', label: '30%' },
  { value: '40', label: '40%' },
  { value: '50', label: '50%' },
  { value: '60', label: '60%' },
  { value: '70', label: '70%' },
  { value: '80', label: '80%' },
  { value: '90', label: '90%' },
  { value: '100', label: '100%' }
];

export const SUBSCRIPTIONS_TYPES_OPTIONS = [
  { value: AnalyticsSubscriptionPlanType.FREE, label: 'Free' },
  { value: AnalyticsSubscriptionPlanType.BASIC, label: 'Basic' },
  { value: AnalyticsSubscriptionPlanType.BASIC_PLUS, label: 'Basic +' },
  { value: AnalyticsSubscriptionPlanType.BASIC_PLUS_PLUS, label: 'Basic ++' },
  { value: AnalyticsSubscriptionPlanType.ENTERPRISE, label: 'Enterprise' },
  { value: AnalyticsSubscriptionPlanType.ENTERPRISE_PLUS, label: 'Enterprise +' },
  { value: AnalyticsSubscriptionPlanType.ENTERPRISE_PLUS_PLUS, label: 'Enterprise ++' }
];

export const GENDER_OPTIONS = [
  { value: Genders.FEMALE, label: 'Female' },
  { value: Genders.MALE, label: 'Male' },
  { value: Genders.NOT_APPLICABLE, label: 'Other' }
];
export const AUDIENCE_GENDER_OPTIONS = [
  { value: Genders.FEMALE, label: 'Female' },
  { value: Genders.MALE, label: 'Male' }
];
export const MIN_MAX_FOLLOWERS_OPTIONS = [
  { value: '0', label: '0' },
  { value: '10000', label: '10,000' },
  { value: '100000', label: '100,000' },
  { value: '300000', label: '300,000' },
  { value: '400000', label: '400,000' },
  { value: '500000', label: '500,000' },
  { value: '600000', label: '600,000' },
  { value: '700000', label: '700,000' },
  { value: '800000', label: '800,000' },
  { value: '900000', label: '900,000' },
  { value: '1000000', label: '1,000,000+' }
];

const minMaxEngagementRateOptionsLength = (MAX_ENGAGEMENT_RATE - MIN_ENGAGEMENT_RATE) / 0.1 + 1;
export const MIN_MAX_EN_RATE_OPTIONS = Array.from({ length: minMaxEngagementRateOptionsLength }, (_, i) => {
  const value = (i * 0.1).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });

  return { value, label: `${value}%${i === minMaxEngagementRateOptionsLength - 1 ? '~' : ''}` };
});
export const MIN_MAX_SAVE_RATE_OPTIONS = MIN_MAX_EN_RATE_OPTIONS;
export const MIN_MAX_COMMISSION_RATE_OPTIONS = MIN_MAX_EN_RATE_OPTIONS;
// it is same as min_max followers for now
export const MIN_MAX_VIEW_OPTIONS = MIN_MAX_FOLLOWERS_OPTIONS;
export const MIN_MAX_PRICE_OPTIONS = MIN_MAX_FOLLOWERS_OPTIONS;
export const MIN_MAX_REACH_OPTIONS = MIN_MAX_FOLLOWERS_OPTIONS;
export const MIN_MAX_POST_ENGAGEMENT_OPTIONS = MIN_MAX_FOLLOWERS_OPTIONS;

export const SOCIAL_ACCOUNT_STATUS_OPTIONS = [
  { value: SocialAccountStatus.SIGNED_UP, label: 'Signed Up' },
  { value: SocialAccountStatus.SCRAPING, label: 'Not Signed Up' }
];
export const INFLUENCER_STATUS_OPTIONS = [
  { value: InfluencerStatusForSearch.SIGNED_UP, label: 'Signed Up' },
  { value: InfluencerStatusForSearch.SCRAPING, label: 'Not Signed Up' },
  { value: InfluencerStatusForSearch.SIGNED_UP_EMAIL, label: 'Signed Up with Email' }
];
export const NON_TTCM_STATUSES = [TTCMStatus.INVITED, TTCMStatus.NOT_INVITED];
export const TTCM_STATUS_CUSTOM_OPTIONS = [
  { value: TtcmStatusOption.ALL, label: 'All' },
  { value: TtcmStatusOption.TTCM, label: 'TTCM' },
  { value: TtcmStatusOption.NON_TTCM, label: 'non TTCM' }
];
export const MIN_MAX_AGE_OPTIONS = Array.from({ length: MAX_AGE - MIN_AGE + 1 }, (_, i) => ({
  value: (i + MIN_AGE).toString(),
  label: (i === 0 ? `${MIN_AGE}-` : `${i + MIN_AGE}${i === MAX_AGE - MIN_AGE ? '+' : ''}`).toString()
}));
/**
 * @description
 * values are [0, 18 ... 50, 100]
 */
export const MIN_MAX_AGE_OPTIONS_V2 = Array.from({ length: MAX_AGE_V2 + 1 }, (_, i) => i).reduce<
  {
    value: string;
    label: string;
  }[]
>((acc, curr) => {
  if ((curr > 0 && curr < 18) || (curr > 50 && curr < 100)) {
    return acc;
  } else {
    return [
      ...acc,
      {
        value: curr.toString(),
        label: curr.toString()
      }
    ];
  }
}, []);
export const MIN_MAX_JOINED_CAMPAIGN_OPTIONS = Array.from(
  { length: MAX_JOINED_CAMPAIGNS - MIN_JOINED_CAMPAIGNS + 1 },
  (_, i) => ({
    value: (i + MIN_JOINED_CAMPAIGNS).toString(),
    label: (i === 0
      ? MIN_JOINED_CAMPAIGNS
      : `${i + MIN_JOINED_CAMPAIGNS}${i === MAX_JOINED_CAMPAIGNS - MIN_JOINED_CAMPAIGNS ? '+' : ''}`
    ).toString()
  })
);
export const AUDIENCE_AGE_GROUP_OPTIONS = [
  { value: '13, 17', label: '13-17' },
  { value: '18, 24', label: '18-24' },
  { value: '25, 34', label: '25-34' },
  { value: '35, 44', label: '35-44' },
  { value: '45, 54', label: '45-54' },
  { value: '55, 64', label: '55-64' },
  { value: '65, 100', label: '65-' }
];
export const AUDIENCE_RATIO_OPTIONS = [
  { value: '0', label: '0%' },
  { value: '0.1', label: '10%' },
  { value: '0.2', label: '20%' },
  { value: '0.3', label: '30%' },
  { value: '0.4', label: '40%' },
  { value: '0.5', label: '50%' },
  { value: '0.6', label: '60%' },
  { value: '0.7', label: '70%' },
  { value: '0.8', label: '80%' },
  { value: '0.9', label: '90%' }
];
export const TIKTOK_AUDIENCE_AGE_GROUPS_OPTIONS = [
  { value: '18, 24', label: '18-24' },
  { value: '25, 34', label: '25-34' },
  { value: '35, 100', label: '35+' }
];
export const XHS_AUDIENCE_AGE_GROUPS_OPTIONS = [
  { value: '13, 17', label: '13-17' },
  { value: '18, 24', label: '18-24' },
  { value: '25, 34', label: '25-34' },
  { value: '35, 44', label: '35-44' },
  { value: '45, 100', label: '45+' }
];
export const DOUYIN_AUDIENCE_AGE_GROUPS_OPTIONS = [
  { value: '18, 23', label: '18-23' },
  { value: '24, 30', label: '24-30' },
  { value: '31, 40', label: '31-40' },
  { value: '41, 50', label: '41-50' },
  { value: '50, 100', label: '50+' }
];
export const AUTO_MANAGED_COUNTRY_OPTIONS = [
  { value: 'TH', label: 'Thailand' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'SG', label: 'Singapore' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'PH', label: 'Philippines' },
  { value: 'TW', label: 'Taiwan' }
];
export const AUTO_MANAGED_CAMPAIGN_TYPE_OPTIONS = [
  { label: 'Lazada', value: AutoManagedCampaignType.LAZADA },
  { label: 'Shopee', value: AutoManagedCampaignType.SHOPEE }
];
export const AUTO_MANAGED_STATUS_OPTIONS = [
  { value: AutoManagedCampaignStatus.ACTIVE, label: 'Active' },
  { value: AutoManagedCampaignStatus.INACTIVE, label: 'Inactive' }
];
export const DISPLAY_CAMPAIGN_OPTIONS = [
  { label: 'Show', value: ToggleOption.TRUE },
  { label: 'Hide', value: ToggleOption.FALSE }
];
export const ACCOUNT_TYPE_OPTIONS = [
  { label: 'Influencer', value: ToggleOption.FALSE },
  { label: 'Brand', value: ToggleOption.TRUE }
];
