import { z } from 'zod';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import {
  SocialAccountStatus,
  UserProposalInfluencer,
  UserProposalJobSummary,
  UserProposalSocialAccount,
  UserProposalSocialMediaType,
  ProposalJobDescriptionBreakDownMode
} from '@/graphql';

export interface ProposedInfluencer
  extends UserProposalJobSummary,
    Omit<UserProposalInfluencer, 'accountList' | 'summary'> {
  accountList: readonly UserProposalSocialAccount[];
}
export interface RowInfluencerData {
  id: number;
  name: string;
  avatar?: string | null;
  status: SocialAccountStatus;
}

export interface RowSocialAccountData {
  id: number;
  name?: string | null;
  lastIndex?: boolean;
  socialMedia: UserProposalSocialMediaType;
}
export interface RowStatsData {
  budget: number;
  profit: number;
  influencerCost: number;
  addedDate?: string | null;
  followersCount?: number | null;
  engagementRateByViews?: number | null;
  engagementRateByFollowers?: number | null;
  engagementPosted?: number | null;
  engagementProposed?: number | null;
}

export interface RowData {
  index: number;
  stats: RowStatsData;
  influencer: RowInfluencerData;
  socialAccount?: RowSocialAccountData;
}
export enum EditableProposeInfluencersFieldKeys {
  budget = 'budget',
  profit = 'profit',
  influencerCost = 'influencerCost'
}
export enum ProposeInfluencersTableMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}
export const ProposeInfluencersFilterSchema = z.object({
  tableViewMode: FilterValidations.enum(ProposeInfluencersTableMode).default(ProposeInfluencersTableMode.VIEW),
  jdMode: FilterValidations.enum(ProposalJobDescriptionBreakDownMode)
});
export const ProposeInfluencersSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(ProposeInfluencersFilterSchema, {
    tableViewMode: ProposeInfluencersTableMode.VIEW,
    jdMode: ProposalJobDescriptionBreakDownMode.INFLUENCER_BREAKDOWN
  })
});

export type ProposeInfluencersSearchSchemaType = z.infer<typeof ProposeInfluencersSearchSchema>;
export type ProposeInfluencersFilterSchemaType = z.infer<typeof ProposeInfluencersFilterSchema>;
