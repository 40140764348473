import { useRef } from 'react';
import { Resizable, ResizeCallback, ResizeStartCallback } from 're-resizable';
import { useDraggable } from '@dnd-kit/core';
import { useDeepCompareEffect } from '@/shared/hooks';
import { ChatBoxCoordinates } from '../types';
import { ChatBoxRndCoordinates } from '../utils';
import { useChatBoxRndContext } from '../ChatBoxRndContext';

interface Props {
  node: ReturnType<typeof useDraggable>['node'];
}

export const useChatBoxResizable = ({ node }: Props) => {
  const resizableRef = useRef<Resizable>(null);
  const initialSize = useRef<DOMRect | undefined>(undefined);
  const { resizable, chatBoxSize, setChatBoxSize, chatBoxCoordinates, setChatBoxCoordinates } = useChatBoxRndContext();

  // Init/Clear chatBoxSize when states changed
  useDeepCompareEffect(() => {
    if (resizable) {
      // resizable is boolean but we only care the rect value after resizable is "true"
      // chatbox size can be diffrent between true/false state so we need to get latest rect
      const rect = node.current?.getBoundingClientRect();

      if (rect) {
        const newRndCoordinates = new ChatBoxRndCoordinates(rect);

        if (!chatBoxSize) {
          initialSize.current = rect;
          setChatBoxSize({ width: rect.width, height: rect.height });
        }
        // If chatbox size changed not via resizing (it can be toggled UI Button <-> ChatBox Form), we need to check coordinates again
        else if (!newRndCoordinates.checkInViewport()) {
          setChatBoxCoordinates(newRndCoordinates.alignInViewport().getNearestEgdes());
        }
      }
    } else {
      if (chatBoxSize) {
        setChatBoxSize(undefined);
      }

      initialSize.current = undefined;
    }
  }, [resizable, chatBoxSize]); // Don't put rect in deps

  // Adjust chatbox position before resizing
  const handleResizeStart: ResizeStartCallback = (e, direction, ref) => {
    const refCoordinates = new ChatBoxRndCoordinates(ref.getBoundingClientRect()).getValue();
    const resizingCoordinates: ChatBoxCoordinates = {
      top: chatBoxCoordinates?.top ?? refCoordinates.top,
      left: chatBoxCoordinates?.left ?? refCoordinates.left,
      right: chatBoxCoordinates?.right ?? refCoordinates.right,
      bottom: chatBoxCoordinates?.bottom ?? refCoordinates.bottom
    };

    switch (direction) {
      case 'top':
        resizingCoordinates.top = undefined;
        break;
      case 'bottom':
        resizingCoordinates.bottom = undefined;
        break;
      case 'left':
        resizingCoordinates.left = undefined;
        break;
      case 'right':
        resizingCoordinates.right = undefined;
        break;
      case 'topLeft':
        resizingCoordinates.top = undefined;
        resizingCoordinates.left = undefined;
        break;
      case 'topRight':
        resizingCoordinates.top = undefined;
        resizingCoordinates.right = undefined;
        break;
      case 'bottomLeft':
        resizingCoordinates.bottom = undefined;
        resizingCoordinates.left = undefined;
        break;
      case 'bottomRight':
        resizingCoordinates.bottom = undefined;
        resizingCoordinates.right = undefined;
        break;
      default:
        break;
    }

    setChatBoxCoordinates(resizingCoordinates);
  };

  // Update chatbox position after resizing
  const handleResizeStop: ResizeCallback = (e, direction, ref, d) => {
    setChatBoxSize((prevSize) => ({
      width: (prevSize?.width ?? 0) + d.width,
      height: (prevSize?.height ?? 0) + d.height
    }));
    setChatBoxCoordinates(new ChatBoxRndCoordinates(ref.getBoundingClientRect()).getNearestEgdes());
  };

  return { initialSize, resizableRef, handleResizeStop, handleResizeStart };
};
