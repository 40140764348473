import { useState } from 'react';
import {
  namedOperations,
  CampaignSocialMediaType,
  EngagementXhsPostMetricsDataForInsight,
  EngagementPostMetricsDataForInsightCheck,
  useUploadEngagementXhsInsightCheckMutation,
  EngagementCampaignPostStatusForInsightCheck,
  useUploadEngagementReportFromInsightCheckMutation
} from '@/graphql';
import { useQueryHelper, useDeepCompareEffect } from '@/shared/hooks';
import { PostInsightFormProps, PostInsightFormValues } from '@/shared/organisms';
import { FormAction } from '@/shared/types';
import { getPostInsightDataByOCR } from '@/shared/utils';
import {
  hasAnyOCRDataValid,
  getInitialPostInsightFormValues,
  convertOCRDataFromApiToPostInsightOCR,
  convertPostInsightFormValueToVariables,
  convertPostInsightFormValueToXhsVariables
} from '../utils';

interface Props {
  postId: number;
  currentImageUrl: string;
  postData: EngagementPostMetricsDataForInsightCheck | null;
  xhsPostData?: EngagementXhsPostMetricsDataForInsight | null;
}

export const usePostsInsightFormProps = ({
  postId,
  postData,
  xhsPostData,
  currentImageUrl
}: Props): PostInsightFormProps => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [isReloadData, setReloadStatus] = useState<boolean>(false);
  const [hasOCRData, setOCRDataStatus] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<PostInsightFormValues>(
    getInitialPostInsightFormValues(postData, xhsPostData, null)
  );
  const { callUploadEngagementReportFromInsightCheck } = useUploadEngagementReportFromInsightCheckMutation({
    refetchQueries: [namedOperations.Query.EngagementPostMetricsForInsightCheck]
  });
  const { callUploadEngagementXhsInsightCheck } = useUploadEngagementXhsInsightCheckMutation({
    refetchQueries: [namedOperations.Query.EngagementXhsPostMetricsForInsight]
  });

  useDeepCompareEffect(() => {
    setInitialValues(getInitialPostInsightFormValues(postData, xhsPostData, null));
  }, [postData]);

  const isIGPost =
    postData?.socialMedia &&
    [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(postData.socialMedia);
  const isXhsPost = !!xhsPostData;

  const handleSubmit: FormAction<PostInsightFormValues>['onSubmit'] = async (payload) => {
    try {
      if (isXhsPost) {
        await callUploadEngagementXhsInsightCheck({
          variables: { input: convertPostInsightFormValueToXhsVariables(postId, payload) }
        });
      } else {
        await callUploadEngagementReportFromInsightCheck({
          variables: { input: convertPostInsightFormValueToVariables(postId, payload) }
        });
      }
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      setOCRDataStatus(false);
    } catch (error) {
      enqueueSnackbar(t('failedToSave'), { variant: 'error' });
      console.error(error);
    }
  };

  const handleReloadData = async () => {
    if (!currentImageUrl) {
      enqueueSnackbar(t('Missing image url'), { variant: 'error' });

      return;
    }

    setReloadStatus(true);
    const response = await getPostInsightDataByOCR(postId, currentImageUrl);

    if (response.ok && !response.error) {
      enqueueSnackbar(t('succeededInFetchFetchPostInsightDataFromOCR'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('failedToFetchPostInsightDataFromOCR'), { variant: 'error' });
      console.error(response.error);
    }

    const newOCRData = convertOCRDataFromApiToPostInsightOCR(response.data);

    if (hasAnyOCRDataValid(newOCRData)) {
      if (!hasOCRData) {
        setOCRDataStatus(true);
      }

      setInitialValues((prevFormValues) => getInitialPostInsightFormValues(prevFormValues, xhsPostData, newOCRData));
    }

    setReloadStatus(false);
  };

  return {
    onSubmit: handleSubmit,
    isReloading: isReloadData,
    defaultValues: initialValues,
    socialMedia: isXhsPost ? CampaignSocialMediaType.XHS : postData?.socialMedia,
    onReloadData: isIGPost ? handleReloadData : undefined,
    status: hasOCRData
      ? EngagementCampaignPostStatusForInsightCheck.UNCONFIRMED
      : isXhsPost
        ? xhsPostData?.status
        : postData?.status
  };
};
