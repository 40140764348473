import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { CampaignInformation, Rates, Settings } from './formSections';
import { AutoManagedCampaignFormValues } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    formState: { isSubmitting }
  } = useFormContext<AutoManagedCampaignFormValues>();

  return (
    <FormStyle.Wrapper gapRow={32} hasBorder>
      <CampaignInformation />

      <FormStyle.HorizontalLine gap={24} />

      <Rates />

      <FormStyle.HorizontalLine gap={24} />

      <Settings />

      <FormStyle.ButtonSubmitWrapper>
        <FormStyle.ButtonSubmit type="submit" title={t('Button.Save')} loading={isSubmitting} />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};
