import { ListWithPagination } from '@/shared/molecules';
import { useAllInstagramCompareFeedPostsQuery } from '@/graphql';
import { convertOrderToORDER, getOffset, getValuableVariables } from '@/shared/utils';
import { ProfilePostsCount, ProfilePostsFilter, AnalyticsInstagramComparePostsList } from '@/shared/organisms';
import { useAnalyticsIgComparePostsFilter } from './hooks';

export interface AnalyticsInstagramComparePostsTemplateProps {
  socialAccountId: number;
}

export const AnalyticsInstagramComparePostsTemplate = ({
  socialAccountId
}: AnalyticsInstagramComparePostsTemplateProps) => {
  const {
    page,
    sort,
    limit,
    setFilter,
    filter: { endDate, startDate, keyword }
  } = useAnalyticsIgComparePostsFilter();
  const { data, previousData, loading } = useAllInstagramCompareFeedPostsQuery({
    skip: !socialAccountId,
    variables: getValuableVariables({
      limit,
      keyword,
      endDate,
      startDate,
      accountId: socialAccountId,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) }
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allInstagramCompareFeedPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter
        setFilter={setFilter}
        css={{ borderRadius: 0 }}
        filter={{ keyword, endDate, startDate }}
        hasPeriod
      />

      <ProfilePostsCount count={totalRecords} />

      <AnalyticsInstagramComparePostsList loading={loading} data={result?.allInstagramCompareFeedPosts || []} />
    </ListWithPagination>
  );
};
