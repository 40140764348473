import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Anchor, ButtonSubmit, FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PasswordField } from '@/shared/molecules';
import { TikTokPasswordFormValues, TikTokPasswordFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<TikTokPasswordFormValues>();

  const values = watch();

  return (
    <FormStyle.Wrapper css={{ position: 'relative', margin: '48px auto', width: 'max-content' }}>
      <div css={{ width: '348px', margin: '0 auto' }}>
        <PasswordField<TikTokPasswordFormKeys> name="password" title={t('Password.Password')} dynamicError required />
        <PasswordField<TikTokPasswordFormKeys> name="passwordConfirm" title={t('Password.Confirm Password')} required />

        <ButtonSubmit
          variant="blue"
          loading={isSubmitting}
          title={t('Button.Sign Up')}
          css={{ width: '100%', marginTop: '16px' }}
          disabled={!values.password || !values.passwordConfirm}
        />
      </div>

      <p css={{ marginTop: '32px', color: THEME.text.colors.gray.lv3, fontSize: '11px', lineHeight: '140%' }}>
        <Trans
          i18nKey="SignInConfirmation"
          components={{
            a1: <Anchor variant="blue" css={{ display: 'inline', lineHeight: 'inherit' }} to="/privacy" />,
            a2: <Anchor variant="blue" css={{ display: 'inline', lineHeight: 'inherit' }} to="/terms" />
          }}
        />
      </p>
    </FormStyle.Wrapper>
  );
};
