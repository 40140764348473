import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  OperationVariables,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllPackageCompanies.graphql';
import { Query } from '../../__generated__/globalTypes';

export const useAllPackageCompaniesQuery = (options?: QueryHookOptions<Query>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllPackageCompaniesLazyQuery = (
  options?: LazyQueryHookOptions<Query>
): QueryResult<Query> & { getAllPackageCompaniesQuery: LazyQueryExecFunction<Query, OperationVariables> } => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  // TODO: should be getAllPackageCompanies
  const [getAllPackageCompaniesQuery, result] = useLazyQuery<Query>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllPackageCompaniesQuery, ...result };
};

export const useAllPackageCompaniesPromiseQuery = () => {
  const client = useApolloClient();

  const getAllPackageCompanies = useCallback(
    (options?: Omit<QueryOptions<Record<string, unknown>, Query>, 'query'>) =>
      client.query<Query>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllPackageCompanies };
};
