import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SendOpenAiMessage.graphql';
import { Mutation, MutationsendOpenAiMessageArgs } from '../../__generated__/globalTypes';

export const useSendOpenAiMessageMutation = (
  options?: MutationHookOptions<Mutation, MutationsendOpenAiMessageArgs>
) => {
  const [callSendOpenAiMessage, result] = useMutation<Mutation, MutationsendOpenAiMessageArgs>(MUTATION, options);

  return { callSendOpenAiMessage, ...result };
};

export type SendOpenAiMessageMutationFunction = Mutation['sendOpenAiMessage'];
