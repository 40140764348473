import { QueryHookOptions } from '@apollo/client';
import { Query, useAllAvailableCountriesQuery } from '@/graphql';
import { useTranslateCountryOptions } from './useTranslateOptions';

interface AllAvailableCountriesProps extends QueryHookOptions<Query> {
  hasUSCountry?: boolean;
}
export const useAllAvailableCountryOptions = ({ hasUSCountry, ...options }: AllAvailableCountriesProps = {}) => {
  const { data, loading } = useAllAvailableCountriesQuery({ fetchPolicy: 'no-cache', ...options });

  const usCountryOption = hasUSCountry ? [{ value: 'US', label: 'United States', currency: 'USD' }] : [];
  const availableCountryOptions =
    data?.allAvailableCountries.map(({ id, name, currency }) => ({ currency, value: id, label: name })) || [];

  return {
    loading,
    allAvailableCountryOptions: useTranslateCountryOptions([...availableCountryOptions, ...usCountryOption])
  };
};
