import { usePermissions } from '../hooks/usePermissions';
import { useUserRoles } from './useUserRoles';

const defaultPermissions = {
  hideAddInfluencerBtn: false,
  hideEditInfluencerBtn: false,
  hideDeleteInfluencerBtn: false,
  hideSelectInfluencers: false,
  hideViewInfluencerProfile: false
};
export const useInfluencerPermissions = () => {
  const { isAdvertiser } = useUserRoles();
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.influencer?.noView || permissions?.influencer === null) {
    return {
      ...defaultPermissions,
      hideSelectInfluencers: !isAdvertiser,
      hideAddInfluencerBtn: true,
      hideViewInfluencerProfile: true,
      hideEditInfluencerBtn: true,
      hideDeleteInfluencerBtn: true
    };
  } else if (permissions?.influencer?.view) {
    return {
      ...defaultPermissions,
      hideSelectInfluencers: false,
      hideAddInfluencerBtn: true,
      hideEditInfluencerBtn: true,
      hideDeleteInfluencerBtn: true
    };
  } else if (permissions?.influencer?.manage) {
    return { ...defaultPermissions, hideDeleteInfluencerBtn: true };
  }

  return defaultPermissions;
};
