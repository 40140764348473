import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateInfluencerV2.graphql';
import { Mutation, MutationcreateInfluencerV2Args } from '../../__generated__/globalTypes';

export const useCreateInfluencerV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationcreateInfluencerV2Args>
) => {
  const [callCreateInfluencerV2, result] = useMutation<Mutation, MutationcreateInfluencerV2Args>(MUTATION, options);

  return { callCreateInfluencerV2, ...result };
};

export type CreateInfluencerV2MutationFunction = Mutation['createInfluencerV2'];
