import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramAnalyticsPostDetails.graphql';
import { Query, QueryinstagramAnalyticsPostDetailsArgs } from '../../__generated__/globalTypes';

export const useInstagramAnalyticsPostDetailsQuery = (
  options?: QueryHookOptions<Query, QueryinstagramAnalyticsPostDetailsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramAnalyticsPostDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramAnalyticsPostDetailsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramAnalyticsPostDetailsArgs>
): QueryResult<Query, QueryinstagramAnalyticsPostDetailsArgs> & {
  getInstagramAnalyticsPostDetails: LazyQueryExecFunction<Query, QueryinstagramAnalyticsPostDetailsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramAnalyticsPostDetails, result] = useLazyQuery<Query, QueryinstagramAnalyticsPostDetailsArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getInstagramAnalyticsPostDetails, ...result };
};

export const useInstagramAnalyticsPostDetailsPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramAnalyticsPostDetails = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramAnalyticsPostDetailsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramAnalyticsPostDetailsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramAnalyticsPostDetails };
};
