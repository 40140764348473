import { useAuthData, useUserPermissions } from '@/auth';
import { AllUserDetail, namedOperations, useDeleteUserMutation } from '@/graphql';
import { Table } from '@/shared/atoms';
import { useSelectItem, useQueryHelper, useToggleState } from '@/shared/hooks';
import { ConfirmDeleteModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { removeToken } from '@/shared/utils';
import { useUsersTable } from './hooks';

export type UsersListProps = TableData<AllUserDetail>;

export const UsersList = ({ loading, data = [] }: UsersListProps) => {
  const { auth, dispatch } = useAuthData();
  const { hideAddUserBtn } = useUserPermissions();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { callDeleteUser } = useDeleteUserMutation({ refetchQueries: [namedOperations.Query.AllUsers] });
  const { status: deleting, toggleStatus: toggleDeleting } = useToggleState();
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem();
  const { rows, columns } = useUsersTable({ data, openConfirmModal: setSelectedItem });

  const handleDeleteUser = async () => {
    if (!selectedItem) {
      return;
    }

    try {
      toggleDeleting();
      await callDeleteUser({ variables: { id: selectedItem.id } });
      enqueueSnackbar(t('succeededInDeleting', { name: selectedItem.name }), { variant: 'success' });

      if (auth.email === selectedItem.email) {
        removeToken();
        dispatch({ type: 'LOGOUT' });
        navigate({ to: '/' });
      }
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: selectedItem.name }), { variant: 'error' });
    } finally {
      resetSelectedItem();
      toggleDeleting();
    }
  };

  return (
    <>
      <Table
        loading={loading}
        data={{ rows, columns }}
        empty={{
          linkProps: !hideAddUserBtn ? { to: '/user/add', label: t('Dialog.add an user') } : undefined
        }}
        hasBorder
        noTopBorderRadius
      />

      <ConfirmDeleteModal
        deleting={deleting}
        onClose={resetSelectedItem}
        deletingItem={selectedItem}
        onDelete={handleDeleteUser}
      />
    </>
  );
};
