import { useUserRoles, useReportPermissions, useInfluencerPermissions, useEngagementPermissions } from '@/auth';
import {
  CampaignTrackingOption,
  EngagementCampaignStatus,
  EngagementDetail,
  InfluencerStatusForSearch
} from '@/graphql';
import { Tabs } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { InfluencersTabType } from '@/shared/types';

export interface EngagementTabsProps {
  engagement: EngagementDetail;
}

export const EngagementTabs = ({
  engagement: { id, status, isTtcmCampaign, trackingOptions, countries, endDate, startDate, reportStartDate }
}: EngagementTabsProps) => {
  const { t, navigate } = useQueryHelper();
  const { hideReportLink } = useReportPermissions();
  const { hideSelectInfluencers } = useInfluencerPermissions();
  const { hideEditEngagementBtn } = useEngagementPermissions();
  const { isAdvertiser, isAdminStaffTalentAgencyPartner } = useUserRoles();
  const stringId = id.toString();
  const countryIds = countries.map(({ id }) => id) || [];

  return (
    <Tabs
      hasIndex
      css={THEME.campaign.customTabStyles}
      options={[
        {
          end: true,
          hidden: hideEditEngagementBtn,
          label: t('Campaign Information'),
          to: '/engagement/$id',
          params: { id: stringId }
        },
        {
          end: true,
          label: t('URL Tracking'),
          to: '/engagement/$id/tracking',
          params: { id: stringId },
          hidden: !trackingOptions?.includes(CampaignTrackingOption.TUNE)
        },
        {
          end: true,
          label: t('Referral Code Tracking'),
          to: '/engagement/$id/tracking-referral-codes',
          params: { id: stringId },
          hidden: !trackingOptions?.includes(CampaignTrackingOption.REFERRAL_CODE)
        },
        {
          end: false,
          label: t('General.Select Influencers'),
          to: '/engagement/$id/influencer',
          params: { id: stringId },
          search: {
            filter: {
              countryIds,
              tab: InfluencersTabType.INFLUENCERS,
              statuses: [
                InfluencerStatusForSearch.SCRAPING,
                InfluencerStatusForSearch.SIGNED_UP,
                InfluencerStatusForSearch.SIGNED_UP_EMAIL
              ]
            }
          },

          hidden:
            status === EngagementCampaignStatus.FINISHED || hideSelectInfluencers || !isAdminStaffTalentAgencyPartner,
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (isTtcmCampaign) {
              e.preventDefault();
              navigate({ to: '/engagement/$id/influencer/tiktok', params: { id: stringId } });
            }
          }
        },
        {
          end: false,
          label: t('General.Proposal'),
          to: '/engagement/$id/proposal',
          params: { id: stringId },
          hidden: isAdvertiser && status === EngagementCampaignStatus.DRAFT
        },
        {
          end: false,
          label: t('General.Posts'),
          to: '/engagement/$id/posts',
          params: { id: stringId },
          hidden: [EngagementCampaignStatus.DEMO, EngagementCampaignStatus.DRAFT].includes(status)
        },
        {
          end: true,
          label: t('General.Report'),
          to: '/engagement/$id/report',
          params: { id: stringId },
          hidden:
            hideReportLink ||
            ![
              EngagementCampaignStatus.ONGOING,
              EngagementCampaignStatus.FINISHED,
              EngagementCampaignStatus.LOST
            ].includes(status)
        }
      ]}
    />
  );
};
