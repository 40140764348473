import { useEffect } from 'react';
import { RedirectType, useInfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation } from '@/graphql';
import { Loading } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { getCallbackUrl, manageLocalStorage } from '@/shared/utils';

export const RedirectTalentAnalyticsConnect = () => {
  const response = window.location.search.slice(1);
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callInfluencerSocialAuthReconnectInstagramAccountAnalytics } =
    useInfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation();

  useEffect(() => {
    reconnectAnalyticsAccount();
  }, []);

  const { talentInfluencerId, reconnectStatus } = (() => {
    // TODO: fix any type
    const result: { talentInfluencerId: string; reconnectStatus: any } = {
      talentInfluencerId: '',
      reconnectStatus: undefined
    };

    try {
      result.reconnectStatus = JSON.parse(manageLocalStorage('get', 'reconnectStatus') || '');
      result.talentInfluencerId = manageLocalStorage('get', 'talentInfluencerId') || '';
      if (!result.reconnectStatus) {
        throw Error('no data');
      }
    } catch (e) {
      enqueueSnackbar(t('Fail to reconnect account, please try again later'), { variant: 'error' });
      manageLocalStorage('remove', 'reconnectStatus');
    }

    return result;
  })();

  const reconnectAnalyticsAccount = async () => {
    try {
      await callInfluencerSocialAuthReconnectInstagramAccountAnalytics({
        variables: {
          input: {
            response,
            analyticsAccountId: Number(reconnectStatus.dataForReconnectanalyticsAccountId),
            callbackUrl: getCallbackUrl('instagram', RedirectType.RECONNECT_IG)
          }
        }
      });
      enqueueSnackbar(t('Your account has been reconnected successfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('Fail to reconnect account, please try again later'), { variant: 'error' });
    } finally {
      navigate({ to: '/influencer/$id', params: { id: talentInfluencerId } });
      manageLocalStorage('remove', 'reconnectStatus');
      manageLocalStorage('remove', 'talentInfluencerId');
    }
  };

  return <Loading />;
};
