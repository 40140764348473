import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { ThreadsInfluencersProps } from './schemaTypes';
import { ThreadsInfluencersSort } from './ThreadsInfluencersSort';
import { ThreadsInfluencersList } from './ThreadsInfluencersList';
import { ThreadsInfluencersFilter } from './ThreadsInfluencersFilter';
import { ThreadsInfluencersProvider } from './ThreadsInfluencersContext';

export const ThreadsInfluencers = (props: ThreadsInfluencersProps) => (
  <ThreadsInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <ThreadsInfluencersFilter />
      <ThreadsInfluencersSort />
    </InfluencersFilterWrapper>

    <ThreadsInfluencersList />
  </ThreadsInfluencersProvider>
);

export * from './schemaTypes';
