import { TFunction } from 'i18next';
import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

const checkIcon = (
  <div css={{ display: 'flex', justifyContent: 'center' }}>
    <Icon icon="check" color={THEME.attention.colors.blue} size={12} />
  </div>
);
const tableNumber = (num: number) => (
  <span css={{ fontSize: '14px', fontStyle: 'normal', fontWeight: 400 }}>{num}</span>
);

export const getSubscriptionFeatures = (t: TFunction) => ({
  group1: {
    title: t('Support from TikTok ads & troubleshooting'),
    features: [
      {
        keyFeatureTitle: t('Quickly issued TikTok ads account'),
        tier1: checkIcon,
        tier2: checkIcon,
        tier3: checkIcon
      },
      {
        keyFeatureTitle: (
          <div css={styles.keyFeaturesTitle}>
            <h3>{t('Technical support')}</h3>
            <ul>
              <li>- {t('Basic FAQs / Guidelines')}</li>
              <li>- {t('Live Chat')}</li>
            </ul>
          </div>
        ),
        tier1: checkIcon,
        tier2: checkIcon,
        tier3: checkIcon
      },
      {
        keyFeatureTitle: (
          <div css={styles.keyFeaturesTitle}>
            <h3>{t('Technical support (Special)')}</h3>
            <ul>
              <li>- {t('Dedicated AM')}</li>
            </ul>
          </div>
        ),
        tier1: '',
        tier2: '',
        tier3: checkIcon
      }
    ]
  },
  group2: {
    title: t('Buy ads easy & get ads credit'),
    features: [
      {
        keyFeatureTitle: (
          <div css={styles.keyFeaturesTitle}>
            <h3>{t('Ads Spending')}</h3>
            <ul>
              <li>- {t('Unlimited Ads Spending')}</li>
              <li>- {t('Easily to refund from account')}</li>
            </ul>
          </div>
        ),
        tier1: checkIcon,
        tier2: checkIcon,
        tier3: checkIcon
      }
    ]
  },
  group3: {
    title: t('Ad account number'),
    features: [
      {
        keyFeatureTitle: (
          <div css={styles.keyFeaturesTitle}>
            <h3>{t('Ad Account number')}</h3>
          </div>
        ),
        tier1: tableNumber(1),
        tier2: tableNumber(5),
        tier3: tableNumber(20)
      },
      {
        keyFeatureTitle: (
          <div css={styles.keyFeaturesTitle}>
            <h3>{t('Target ads worldwide (+55 markets)')}</h3>
            <ul>
              <li>- {t('Summarize ads & suggestion')}</li>
            </ul>
          </div>
        ),
        tier1: checkIcon,
        tier2: checkIcon,
        tier3: checkIcon
      }
    ]
  },
  group4: {
    title: t('Make content easily accessible'),
    features: [
      {
        keyFeatureTitle: (
          <div css={styles.keyFeaturesTitle}>
            <h3>{t('AnyMind Exclusive Package')}</h3>
            <ul>
              <li>- {t('AnyTag Marketplace')}</li>
            </ul>
          </div>
        ),
        tier1: '',
        tier2: checkIcon,
        tier3: checkIcon
      },
      {
        keyFeatureTitle: (
          <div css={styles.keyFeaturesTitle}>
            <h3>{t('Boost posts As Ads')}</h3>
            <ul>
              <li>- {t('TikTok Ads Code')}</li>
            </ul>
          </div>
        ),
        tier1: '',
        tier2: checkIcon,
        tier3: checkIcon
      }
    ]
  }
});

const styles = {
  keyFeaturesTitle: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 0',
    h3: {
      fontSize: '12px'
    },
    li: {
      fontSize: '10px'
    }
  })
};
