import { useState } from 'react';
import {
  FacebookPageAnalyticsPostsByDatePayload,
  useFacebookPageAnalyticsPostsByDatePromiseQuery,
  useInfluencerFacebookPageAnalyticsPostsByDatePromiseQuery
} from '@/graphql';
import { DAILY_POST_PARAM_KEY } from '@/shared/constants';
import { useFetchDailyPostsOnChart, usePostDetailsParam, useQueryHelper } from '@/shared/hooks';
import { AnalyticsOverviewChartProps } from '../../../../shared';
import { AnalyticsSelectedAccountInfo } from '../../../../types';
import { AnalyticsFacebookDashboardGraphKeys as Keys } from '../types';
import { legendColors } from '../utils';

interface Props {
  influencerId?: number;
  isInfluencerProfile?: boolean;
  accountId: AnalyticsSelectedAccountInfo['id'];
  chartData: AnalyticsOverviewChartProps<Keys>['data'];
}

export const useAnalyticsFacebookDashboardOverviewPosts = ({
  chartData,
  accountId,
  influencerId,
  isInfluencerProfile
}: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { setPostDetailsParam } = usePostDetailsParam(DAILY_POST_PARAM_KEY);
  const [dailyPosts, setDailyPosts] = useState<FacebookPageAnalyticsPostsByDatePayload | null>(null);
  const { getFacebookPageAnalyticsPostsByDate } = useFacebookPageAnalyticsPostsByDatePromiseQuery();
  const { getInfluencerFacebookPageAnalyticsPostsByDate } = useInfluencerFacebookPageAnalyticsPostsByDatePromiseQuery();

  const isInfluencerProfilePage = isInfluencerProfile && influencerId;

  const fetchDailyPosts = async (date: string) => {
    if (!date) return;

    try {
      const { data } = isInfluencerProfilePage
        ? await getInfluencerFacebookPageAnalyticsPostsByDate({
            variables: { socialAccountId: accountId, date, influencerId }
          })
        : await getFacebookPageAnalyticsPostsByDate({
            variables: { facebookPageAnalyticsAccountId: accountId, date }
          });
      const postsInDate = isInfluencerProfilePage
        ? data?.influencerFacebookPageAnalyticsPostsByDate
        : data?.facebookPageAnalyticsPostsByDate;
      const posts = postsInDate.posts || [];

      if (posts.length) {
        setDailyPosts(postsInDate);
        setPostDetailsParam(posts?.at(0)?.id || '');
      }
    } catch (error) {
      enqueueSnackbar(t('Can not get daily posts data, please try again later '), { variant: 'error' });
    }
  };

  const { customChartOptions } = useFetchDailyPostsOnChart({
    onFetchPosts: fetchDailyPosts,
    postsData: [chartData.post.data],
    postColors: [legendColors.post]
  });

  return { dailyPosts, customChartOptions };
};
