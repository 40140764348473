import { Trans } from 'react-i18next';
import { ProposalInfluencer } from '@/graphql';
import { Icon, Modal, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';

export interface WarningCouponModalProps {
  open?: boolean;
  couponCodes: number;
  onClose?: () => void;
  engagementId: number;
  influencers: readonly ProposalInfluencer[];
}

export const WarningCouponModal = ({
  open,
  onClose,
  influencers,
  couponCodes,
  engagementId
}: WarningCouponModalProps) => {
  const { t, navigate, pathname } = useQueryHelper();

  const numberOfSelectedInfluencers = influencers.filter((infl) => !infl.isDenied).length;

  const handleNavigateToCampaignDetails = () => {
    navigate({
      to: '/engagement/$id',
      params: { id: engagementId.toString() },
      search: { state: { redirectUrlAfterUpdateEngagement: pathname } }
    });
    onClose?.();
  };

  return (
    <Modal open={open} onClose={onClose} css={{ maxWidth: '600px' }} hasCloseIcon>
      <ModalContent>
        <ModalTitle css={{ display: 'flex' }}>
          <Icon icon="warning" color="#FFB63D" size="20px" css={{ marginRight: '10px' }} />
          {t('warningCouponModalTitle')}
        </ModalTitle>

        <p css={{ fontSize: '14px', lineHeight: '16px', marginTop: '24px' }}>
          <Trans
            i18nKey="warningCouponModalDescription"
            values={{ amount: numberOfSelectedInfluencers - couponCodes }}
            components={{ b: <b /> }}
          />
        </p>
        <p css={{ fontSize: '14px', lineHeight: '16px', marginTop: '24px' }}>
          {t('Suggested influencers')}: <b>{numberOfSelectedInfluencers}</b>
          <br />
          {t('Uploaded referral codes')}: <b>{couponCodes}</b>
        </p>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{ title: t('Button.Edit campaign detail'), onClick: handleNavigateToCampaignDetails }}
      />
    </Modal>
  );
};
