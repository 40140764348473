import { RefCallBack, SetValueConfig, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Order } from '@/graphql';
import { SingleSelector } from '../Selector';
import { ReactSingleSelectProps, SingleSelectorProps, SingleSelectorWithSubmenu } from '../Selector/SingleSelector';

export interface SingleSelectFieldProps extends Omit<SingleSelectorProps, 'onChange'> {
  className?: string;
  handleSubmit?: () => void;
  setFieldValue?: UseFormSetValue<Record<string, any>>;
  hideDeselectOption?: boolean;
  setSort?: (value?: string, order?: Order | null) => void;
  order?: Order | null;
  onMenuOpen?: () => void;
  setValueOptions?: SetValueConfig;
  innerRef?: RefCallBack;
  optionType?: 'with-submenu';
}

export const SingleSelectField = (props: SingleSelectFieldProps & ReactSingleSelectProps) => {
  const { t } = useTranslation();
  const {
    name,
    type,
    value,
    styles,
    loading,
    options,
    hasError,
    setSort,
    className,
    hideInput,
    menuStyles,
    optionType,
    onMenuOpen,
    placeholder,
    handleSubmit,
    setFieldValue,
    menuPlacement,
    setValueOptions,
    hideDeselectOption
  } = props;
  const disabled = props.disabled || options.length === 0;
  const customPlaceholder = disabled ? '' : placeholder ?? t('Selector.Please Select');

  const handleClose = async () => {
    if (handleSubmit) {
      await Promise.resolve();
      handleSubmit();
    }
  };
  const handleSetValues = (item: string, order?: Order | null) => {
    if (setSort) {
      setSort(item, order || null);
    } else if (setFieldValue) {
      setFieldValue(name, item, setValueOptions);
    }
  };

  return optionType === 'with-submenu' ? (
    <SingleSelectorWithSubmenu
      name={name}
      type={type}
      value={value}
      hasError={hasError}
      order={props.order}
      styles={styles}
      options={options}
      onClose={handleClose}
      loading={loading}
      disabled={disabled}
      onMenuOpen={onMenuOpen}
      onChange={handleSetValues}
      hideInput={hideInput}
      className={className}
      placeholder={customPlaceholder}
      hideDeselectOption={hideDeselectOption}
      menuPlacement={menuPlacement}
      menuStyles={menuStyles}
    />
  ) : (
    <SingleSelector
      name={name}
      type={type}
      value={value}
      hasError={hasError}
      order={props.order}
      styles={styles}
      options={options}
      onClose={handleClose}
      loading={loading}
      disabled={disabled}
      onMenuOpen={onMenuOpen}
      onChange={handleSetValues}
      hideInput={hideInput}
      className={className}
      placeholder={customPlaceholder}
      hideDeselectOption={hideDeselectOption}
      menuPlacement={menuPlacement}
      menuStyles={menuStyles}
    />
  );
};
SingleSelectField.displayName = 'SingleSelectField';

export { type ReactSingleSelectProps };
