import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { formatPrice } from '@/shared/utils';
import { useSelectItem } from '@/shared/hooks';
import { useGetPaymentPlanData } from '@/shared/organisms';
import { tiktokDiscountBannerImage } from '@/shared/assets';
import { Button, Icon, MediaPreview } from '@/shared/atoms';
import { CURRENCY_SYMBOLS, THEME } from '@/shared/constants';
import { PaymentPlanName, PricingTableItem, TikTokProductCategory } from '@/graphql';
import { useSelectCampaignPlan } from '../hooks';

interface CampaignPlansListProps
  extends Pick<
    ReturnType<typeof useSelectItem<Partial<Pick<TikTokProductCategory, 'id' | 'packageId'>>>>,
    'selectedItem'
  > {
  marketplacePlans: readonly PricingTableItem[];
}

// hardcoded: put an number to enable discount or let null|undefined to hide discount
const SALE_OFF = null; // TODO: skip for now https://anymindgroup.slack.com/archives/C02C5J5B7KL/p1730258245872489?thread_ts=1730256501.376769&cid=C02C5J5B7KL

export const CampaignPlansList = ({ marketplacePlans, selectedItem }: CampaignPlansListProps) => {
  const { t } = useTranslation();
  const { getPaymentPlan } = useGetPaymentPlanData(marketplacePlans);
  const { selectedPlanId, handleSelectPlan } = useSelectCampaignPlan();

  return (
    <>
      {!!SALE_OFF && <MediaPreview src={tiktokDiscountBannerImage} css={styles.banner} objectFit="cover" />}

      <div css={styles.wrapper}>
        {marketplacePlans.map(({ id, name, currency, price }) => (
          <div key={id} css={styles.itemSizing}>
            <div css={styles.item}>
              <div css={styles.name}>{t(`Plan.${name}`)}</div>

              <div css={{ display: 'flex', alignItems: 'flex-end', marginTop: '24px' }}>
                <p css={{ lineHeight: '100%', fontSize: '28px' }}>
                  <span css={{ lineHeight: '100%', fontWeight: 600 }}>
                    {CURRENCY_SYMBOLS[currency]}
                    {formatPrice(price, null, 0)}
                  </span>
                  <span css={{ lineHeight: '100%', fontSize: '12px', color: THEME.text.colors.gray.lv3 }}>
                    /{t('campaign')}
                  </span>
                </p>

                {!!SALE_OFF && (
                  <div css={styles.discountTag}>
                    <Icon icon="tag-fullfill" size={11} color="inherit" />
                    {SALE_OFF}%{t('off')}
                  </div>
                )}
              </div>

              {!!SALE_OFF && (
                <p css={styles.discountPrice}>
                  {CURRENCY_SYMBOLS[currency]}
                  {formatPrice(SALE_OFF ? price / ((100 - SALE_OFF) / 100) : price, null, 0)}
                </p>
              )}

              <div css={{ flex: 1, marginTop: '24px' }}>
                {getPaymentPlan({ name, formatJoinerInfluencers: true }).features.map(
                  ({ label, value }, index) => (
                    <div key={`plan-${id}-feature-${index}`} css={styles.feature}>
                      <div css={styles.featureIcon}>
                        <Icon icon="check" color={THEME.icon.colors.red.lv2} size={7} />
                      </div>
                      <div css={{ flex: 1 }}>{label}</div>
                      <div css={styles.featureValue}>{value}</div>
                    </div>
                  )
                )}
              </div>

              <Button
                title={t('Button.Get Started')}
                disabled={!selectedItem?.packageId}
                onClick={() => handleSelectPlan(id, selectedItem?.packageId || null)}
                loading={{ showIcon: selectedItem === id, status: !!selectedPlanId }}
                variant={name === PaymentPlanName.PRO_CAMPAIGN ? 'pinkRed' : 'pinkRedInverted'}
                css={{ borderRadius: '20px', marginTop: '24px', height: THEME.button.base.height.lv3 }}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
const styles = {
  banner: css({
    width: '100%',
    height: 'auto',
    marginTop: '6px',
    borderRadius: '12px',
    boxSizing: 'border-box',
    background: 'transparent'
  }),
  wrapper: css({
    '--item-per-row': 3,

    display: 'grid',
    gridAutoRows: '1fr',
    gridTemplateColumns: 'repeat(var(--item-per-row), 1fr)'
  }),
  itemSizing: css({
    marginTop: '24px',
    paddingLeft: '16px',
    paddingRight: '16px',
    boxSizing: 'border-box',
    borderLeft: THEME.border.base,
    flexBasis: 'calc(100% / var(--item-per-row))',
    '&:nth-of-type(3n + 1)': { border: 'none', paddingLeft: 0 },
    '&:nth-of-type(3n + 3)': { paddingRight: 0 }
  }),
  item: css({
    height: '100%',
    display: 'flex',
    padding: '0 16px',
    flexDirection: 'column',
    boxSizing: 'border-box'
  }),
  name: css({
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '100%'
  }),
  discountTag: css({
    gap: '4px',
    height: '18px',
    display: 'flex',
    padding: '0 8px',
    fontSize: '12px',
    fontWeight: 500,
    marginLeft: '8px',
    lineHeight: '100%',
    width: 'max-content',
    borderRadius: '13px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    color: THEME.text.colors.red.lv2,
    backgroundColor: 'rgba(255, 207, 207, 0.50)'
  }),
  discountPrice: css({
    fontSize: '16px',
    marginTop: '8px',
    lineHeight: '100%',
    width: 'max-content',
    position: 'relative',
    '&:after': {
      left: 0,
      top: '50%',
      content: '""',
      width: '100%',
      height: '1px',
      position: 'absolute',
      transform: 'rotate(-11deg)',
      backgroundColor: THEME.background.colors.red.lv1
    }
  }),
  feature: css({
    gap: '5px',
    display: 'flex',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '140%',
    alignItems: 'center',
    '&:not(:first-of-type)': { marginTop: '16px' }
  }),
  featureIcon: css({
    '--size': '13px',

    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    width: 'var(--size)',
    height: 'var(--size)',
    alignSelf: 'baseline',
    boxSizing: 'border-box',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 207, 207, 0.50)'
  }),
  featureValue: css({
    fontWeight: 600,
    textAlign: 'right',
    whiteSpace: 'nowrap',
    alignSelf: 'baseline'
  })
};
