import { ClearIndicatorProps } from 'react-select';
import { THEME } from '@/shared/constants';
import { Icon, clearIconStyles } from '@/shared/atoms';

// TODO: Some old components passing `ref`, do we need that behavior?
export const ClearIndicator = ({ className, clearValue, innerProps }: ClearIndicatorProps<any, any, any>) => (
  <div
    {...innerProps}
    className={className}
    css={clearIconStyles.iconWrapper}
    onClick={(e) => {
      e.preventDefault();
      clearValue();
    }}
  >
    <Icon icon="close" size="7px" color={THEME.icon.colors.white} />
  </div>
);
