import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { FacebookPageInfluencersProps } from './schemaTypes';
import { FacebookPageInfluencersSort } from './FacebookPageInfluencersSort';
import { FacebookPageInfluencersList } from './FacebookPageInfluencersList';
import { FacebookPageInfluencersFilter } from './FacebookPageInfluencersFilter';
import { FacebookPageInfluencersProvider } from './FacebookPageInfluencersContext';

export const FacebookPageInfluencers = (props: FacebookPageInfluencersProps) => (
  <FacebookPageInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <FacebookPageInfluencersFilter />
      <FacebookPageInfluencersSort />
    </InfluencersFilterWrapper>

    <FacebookPageInfluencersList />
  </FacebookPageInfluencersProvider>
);

export * from './schemaTypes';
