import { useUserRoles } from '@/auth';
import {
  useAllPartnerOptions,
  useAllCountryOptions,
  useAllTalentAgencyOptions,
  useAllAvailableCountryOptions,
  useAllAgenciesForSearchOptions,
  useAllAdvertisersForSearchOptions
} from '@/shared/hooks';

interface Props {
  countryId: string;
}

export const useUserFormDropdownsOptions = ({ countryId }: Props) => {
  const { isAdminStaffPartner, isAdminStaffTalentAgencyPartner, isAdminStaffTalentAgency } = useUserRoles();
  const fetchPolicy = 'cache-first';

  const { allCountryOptions } = useAllCountryOptions();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions({ hasUSCountry: true });
  const { allPartnerOptions } = useAllPartnerOptions({ fetchPolicy, skip: !isAdminStaffPartner });
  const { allAdvertiserOptions } = useAllAdvertisersForSearchOptions({ fetchPolicy, variables: { countryId } });
  const { allTalentAgencyOptions } = useAllTalentAgencyOptions({ fetchPolicy, skip: !isAdminStaffTalentAgency });
  const { allAgencyOptions } = useAllAgenciesForSearchOptions({ fetchPolicy, skip: !isAdminStaffTalentAgencyPartner });

  return {
    allAgencyOptions,
    allCountryOptions,
    allPartnerOptions,
    allAdvertiserOptions,
    allTalentAgencyOptions,
    allAvailableCountryOptions
  };
};
