import { ColorVariant } from '@/shared/types';
import { EngagementCampaignStatus } from '@/graphql';
import { Status, StatusProps } from '@/shared/atoms';
import { useEngagementStatusOptions } from '../hooks';

export interface EngagementStatusProps {
  className?: string;
  status?: EngagementCampaignStatus;
}

export const EngagementStatus = ({ status, className }: EngagementStatusProps) => {
  const { engagementStatusOptions } = useEngagementStatusOptions();

  const label = engagementStatusOptions.find((o) => o.value === status)?.label || '';
  const statusProps: StatusProps = (() => {
    switch (status) {
      case EngagementCampaignStatus.DRAFT:
        return { label, variant: ColorVariant.LIGHT_YELLOW };
      case EngagementCampaignStatus.REVIEWING:
        return { label, variant: ColorVariant.LIGHT_PURPLE };
      case EngagementCampaignStatus.UPCOMING:
        return { label, variant: ColorVariant.LIGHT_BLUE };
      case EngagementCampaignStatus.ONGOING:
        return { label, variant: ColorVariant.LIGHT_GREEN };
      case EngagementCampaignStatus.LOST:
        return { label, variant: ColorVariant.LIGHT_RED };
      case EngagementCampaignStatus.DEMO:
        return { label, variant: ColorVariant.LIGHT_CYAN };
      default:
        return { label, variant: ColorVariant.LIGHT_GREY };
    }
  })();

  return <Status {...statusProps} className={className} />;
};
