import { useTranslation } from 'react-i18next';
import { SocialAccountType, InfluencerNewProfileHeaderV2, useInfluencerProfilePostsQuery } from '@/graphql';
import { eyeBlueImage, pictureImage, likePinkImage, chatGreenImage, dislikeBlueImage } from '@/shared/assets';
import { ChartsContainer, NoDataTitle } from '@/shared/atoms';
import { PostingHabits, AverageEngagement } from '@/shared/molecules';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerAnalyticsPostsOverview } from '../shared';

interface InfluencerAnalyticsYoutubeProfilePostsOverviewProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: InfluencerNewProfileHeaderV2['id'];
}

export const InfluencerAnalyticsYoutubeProfilePostsOverview = ({
  socialAccount,
  influencerId
}: InfluencerAnalyticsYoutubeProfilePostsOverviewProps) => {
  const { t } = useTranslation();
  const { data } = useInfluencerProfilePostsQuery({
    variables: {
      pk: influencerId,
      socialAccountId: socialAccount.id,
      socialMedia: socialAccount.type as SocialAccountType
    }
  });

  return (
    <InfluencerAnalyticsPostsOverview data={data?.influencerProfilePosts}>
      {({ data: { postsHabit, averageLikes, averageViews, averageComments, averagePostsPerWeek } }) => (
        <ChartsContainer css={{ height: '324px' }}>
          <AverageEngagement
            header={
              <NoDataTitle
                css={{ padding: '10px' }}
                title={t('Average Engagement')}
                help={t<string>('Tooltip.averageEngagement')}
              />
            }
            data={[
              { icon: pictureImage, label: t('Avg posts/week'), value: averagePostsPerWeek, growth: 0 },
              { icon: likePinkImage, value: averageLikes, label: t('Avg Likes/latest 30 posts'), growth: 0 },
              { icon: dislikeBlueImage, label: t('Avg Dislikes/latest 30 posts'), value: 0, growth: 0 },
              { icon: chatGreenImage, value: averageComments, label: t('Avg Comments/latest 30 posts'), growth: 0 },
              { icon: eyeBlueImage, value: averageViews, label: t('Avg Views/latest 30 posts'), growth: 0 }
            ]}
          />

          {postsHabit ? <PostingHabits data={postsHabit} /> : null}
        </ChartsContainer>
      )}
    </InfluencerAnalyticsPostsOverview>
  );
};
