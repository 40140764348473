import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { RegionSingleSelector, RegionMultiSelector } from '../RegionSelector';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export interface RegionFieldProps<T extends string = string> extends DefaultFieldProps<T, string | string[]> {
  countryId?: string;
}

export const RegionField = <T extends string = string>(props: RegionFieldProps<T>) => {
  const { t } = useTranslation();
  const {
    setValueOptions,
    fieldWrapperProps,
    formContext: { setValue },
    fieldRegister: { ref, ...restRegister },
    fieldProps: { value, disabled, onSubmit, onChange, countryId = '', multiple = true, ...restFieldProps }
  } = useFormatRHFFieldProps(props);

  const commonProps = {
    ...restRegister,
    ...restFieldProps,
    countryId,
    setValueOptions,
    handleSubmit: onSubmit,
    disabled: disabled || !countryId,
    setFieldValue: onChange ? (field: string, value: any) => onChange(field as T, value) : setValue
  };

  return (
    <RHFFieldWrapper {...fieldWrapperProps} title={fieldWrapperProps.title ?? t('Selector.Region')}>
      {({ hasError }) => (
        <>
          <FormStyle.FocusInput ref={ref} />
          {multiple ? (
            <RegionMultiSelector {...commonProps} hasError={hasError} value={value as string[]} />
          ) : (
            <RegionSingleSelector {...commonProps} hasError={hasError} value={value as string} />
          )}
        </>
      )}
    </RHFFieldWrapper>
  );
};
