import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllAccountAnalyticsForAdvertiserV2.graphql';
import { Query, QueryallAccountAnalyticsForAdvertiserV2Args } from '../../__generated__/globalTypes';

export const useAllAccountAnalyticsForAdvertiserV2Query = (
  options?: QueryHookOptions<Query, QueryallAccountAnalyticsForAdvertiserV2Args>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallAccountAnalyticsForAdvertiserV2Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllAccountAnalyticsForAdvertiserV2LazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallAccountAnalyticsForAdvertiserV2Args>
): QueryResult<Query, QueryallAccountAnalyticsForAdvertiserV2Args> & {
  getAllAccountAnalyticsForAdvertiserV2: LazyQueryExecFunction<Query, QueryallAccountAnalyticsForAdvertiserV2Args>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllAccountAnalyticsForAdvertiserV2, result] = useLazyQuery<
    Query,
    QueryallAccountAnalyticsForAdvertiserV2Args
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllAccountAnalyticsForAdvertiserV2, ...result };
};

export const useAllAccountAnalyticsForAdvertiserV2PromiseQuery = () => {
  const client = useApolloClient();

  const getAllAccountAnalyticsForAdvertiserV2 = useCallback(
    (options?: Omit<QueryOptions<QueryallAccountAnalyticsForAdvertiserV2Args, Query>, 'query'>) =>
      client.query<Query, QueryallAccountAnalyticsForAdvertiserV2Args>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllAccountAnalyticsForAdvertiserV2 };
};
