import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggleState } from '@/shared/hooks';
import { SignedUrlData, useGeneratePostFileSignedUrlsPromiseQuery } from '@/graphql';
import { DEFAULT_UPLOAD_LIMIT, IMAGE_MIME, VIDEO_UPLOAD_LIMIT } from '@/shared/constants';
import { useInitInfluencerAISearchWidget } from './useInitInfluencerAISearchWidget';

type Props = ReturnType<typeof useInitInfluencerAISearchWidget>;

export const useInfluencerAISearchUploadFiles = ({ setChatBoxError }: Props) => {
  const { t } = useTranslation();
  const uploadingState = useToggleState();
  const { getGeneratePostFileSignedUrls } = useGeneratePostFileSignedUrlsPromiseQuery();
  const [uploadProgress, setUploadProgress] = useState<{ progressUrl?: string | null; progress: number }>({
    progressUrl: null,
    progress: 0
  });

  const generateSignedUrl = async (fileNames: string[]) => {
    const { data } = await getGeneratePostFileSignedUrls({ variables: { fileNames } });

    return data?.generatePostFileSignedUrls.fileUploadUrls || null;
  };

  const uploadFileToGCS = async (generatedSignedUrls: readonly SignedUrlData[], files: File[]) => {
    let fileUrls: string[] = [];

    if (!generatedSignedUrls.length || !files.length || generatedSignedUrls.length !== files.length) {
      return fileUrls;
    }
    uploadingState.open();

    try {
      await Promise.all(
        generatedSignedUrls.map(({ signedUrl, fileName }) => {
          const videoUrlName = signedUrl.split('?')[0];
          const file = files.find((f) => f.name === fileName);

          if (!file) {
            return null;
          }

          return axios(signedUrl, {
            data: file,
            method: 'PUT',
            onUploadProgress: (p) => {
              setUploadProgress({ progress: (p.loaded / (p.total || 1)) * 100, progressUrl: videoUrlName });
            }
          });
        })
      );

      fileUrls = generatedSignedUrls.reduce<string[]>((acc, { signedUrl }) => {
        const url = signedUrl.split('?').at(0);

        return url ? [...acc, url] : acc;
      }, []);
    } catch (err) {
      setChatBoxError({ message: t('fileUploadFail') });
    } finally {
      setUploadProgress({ progress: 100, progressUrl: null });
    }

    uploadingState.close();

    return fileUrls;
  };

  const handleUploadFiles = async (files: File[]) => {
    let fileUrls: string[] = [];

    if (!files.length) {
      return fileUrls;
    }

    const fileError = files.find((file) => {
      const isVideoFile = file && ['video/mp4', 'video/quicktime'].includes(file.type);
      const sizeLimit = isVideoFile ? VIDEO_UPLOAD_LIMIT : DEFAULT_UPLOAD_LIMIT;

      return file && file.size > sizeLimit;
    });

    if (fileError) {
      setChatBoxError({ message: t('General.UploadSizeError') });

      return;
    }

    const fileNames = files.map((file) => file.name);
    const generatedSignedUrl = await generateSignedUrl(fileNames);

    if (generatedSignedUrl?.length) {
      fileUrls = await uploadFileToGCS(generatedSignedUrl, files);
    }

    return fileUrls;
  };

  return {
    uploadProgress,
    handleUploadFiles,
    multiple: false, // Support single upload for now
    accept: IMAGE_MIME, // Only support upload image for now
    uploading: uploadingState.status
  };
};
