import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { ReadPackageAccount } from '@/graphql';
import { ColumnProps, RowProps } from '@/shared/atoms';
import { useInfluencerPackagesRowWidget } from './useInfluencerPackagesRowWidget';

interface Props {
  listRecords: readonly ReadPackageAccount[];
}

export const useInfluencerPackagesTable = ({ listRecords }: Props) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { generateInfluencerPackagesRowWidget } = useInfluencerPackagesRowWidget();

  const columns: ColumnProps[] = [
    { sticky: { active: true }, styles: { width: '32px' }, title: <div /> },
    { title: t('HeaderColumn.Package'), sticky: { active: true, left: '32px', hasBorderRight: true } },
    { title: t('HeaderColumn.Company'), hidden: !isAdminStaff },
    { title: t('HeaderColumn.Country') },
    { title: t('HeaderColumn.Account'), styles: { textAlign: 'right' } },
    { title: null, styles: { width: '200px' } }
  ];
  const rows: RowProps[] = listRecords.reduce(
    (acc, curr) => [
      ...acc,
      ...generateInfluencerPackagesRowWidget({ packageAccount: curr, columnsLength: columns.length })
    ],
    [] as RowProps[]
  );

  return { rows, columns };
};
