import { CSSObject, css } from '@emotion/react';
import { ReactNode } from 'react';
import { AnalyticsAuthSocialAccountType } from '@/graphql';
import { checkIconImage } from '@/shared/assets';
import { THEME } from '@/shared/constants';
import { Anchor, AnchorProps } from '../Anchor';
import { Tooltip } from '../Tooltip';
import { Icon, IconProps } from './Icon';

export enum SocialMediaIconType {
  XHS = 'XHS',
  EMAIL = 'EMAIL',
  TIKTOK = 'TIKTOK',
  DOUYIN = 'DOUYIN',
  TWITTER = 'TWITTER',
  THREADS = 'THREADS',
  YOUTUBE = 'YOUTUBE',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE'
}

export interface CustomSocialIconProps extends AnchorProps {
  active?: boolean;
  help?: ReactNode;
  className?: string;
  isWarning?: boolean;
  isSignedUp?: boolean;
  size?: IconProps['size'];
  type: SocialMediaIconType | AnalyticsAuthSocialAccountType;
}

export const CustomSocialIcon = ({
  type,
  help,
  isWarning,
  isSignedUp,
  className,
  size = '16px',
  active = true,
  ...anchorProps
}: CustomSocialIconProps) => {
  // https://www.figma.com/design/4FcfqHTdafr6BTRxiTVoRr/AnyTag-Dashboard?node-id=32331-41870&m=dev
  const { iconProps, activeBg } = ((): {
    iconProps?: Pick<IconProps, 'icon' | 'size' | 'color'>;
    activeBg?: Pick<CSSObject, 'backgroundColor' | 'backgroundImage'>;
  } => {
    switch (type) {
      case SocialMediaIconType.THREADS:
        return { iconProps: { icon: 'threads-transform' }, activeBg: { backgroundColor: '#130B18' } };
      case SocialMediaIconType.EMAIL:
        return { iconProps: { icon: 'email' }, activeBg: { backgroundColor: '#9EA0E0' } };
      case SocialMediaIconType.YOUTUBE:
        return { iconProps: { icon: 'youtube' }, activeBg: { backgroundColor: '#FF0302' } };
      case SocialMediaIconType.TWITTER:
        return { iconProps: { icon: 'x-logo' }, activeBg: { backgroundColor: '#000' } };
      case SocialMediaIconType.FACEBOOK:
      case SocialMediaIconType.FACEBOOK_PAGE:
        return { iconProps: { icon: 'facebook' }, activeBg: { backgroundColor: '#1877f2' } };
      case SocialMediaIconType.TIKTOK:
        return {
          iconProps: { icon: active ? 'tiktok-transform' : 'tiktok' },
          activeBg: { backgroundColor: '#010101' }
        };
      case SocialMediaIconType.DOUYIN:
        return {
          iconProps: { icon: active ? 'douyin-text-transform' : 'douyin-text', size: '22px' },
          activeBg: { backgroundColor: '#130B18' }
        };
      case SocialMediaIconType.XHS:
        return { iconProps: { icon: 'xhs-text', size: '26px' }, activeBg: { backgroundColor: '#FF2842' } };
      case SocialMediaIconType.INSTAGRAM:
        return {
          iconProps: { icon: 'instagram' },
          activeBg: {
            backgroundImage: 'linear-gradient(77deg, #EBB752 1.18%, #FF6641 30.45%, #E83262 61.16%, #CE3396 93.31%)'
          }
        };
      default:
        return { iconProps: undefined, activeBg: undefined };
    }
  })();

  return iconProps ? (
    <Tooltip
      help={help}
      position="top"
      css={!anchorProps.to && !anchorProps.href ? { pointerEvents: 'none' } : { cursor: 'pointer' }}
    >
      <Anchor
        variant="inherit"
        className={className}
        css={[styles.wrapper, active ? activeBg : { backgroundColor: '#dee5ec' }]}
        {...anchorProps}
      >
        <Icon icon={iconProps?.icon || ''} size={iconProps?.size || size} color={iconProps?.color || '#fff'} />
        {isSignedUp && <img css={styles.icon} width="16" height="16" src={checkIconImage} alt="check icon" />}
        {isWarning && (
          <div css={styles.warning}>
            <Icon icon="warning" size="10px" color={THEME.icon.colors.white} css={{ height: '11px !important' }} />
          </div>
        )}
      </Anchor>
    </Tooltip>
  ) : null;
};
const styles = {
  wrapper: css({
    flex: 'none',
    position: 'relative',
    borderRadius: '3px',
    alignItems: 'center',
    objectFit: 'contain',
    display: 'inline-flex',
    justifyContent: 'center',
    color: THEME.text.colors.white
  }),
  icon: css({
    top: '-4px',
    right: '-4px',
    borderRadius: '50%',
    position: 'absolute',
    boxSizing: 'border-box',
    border: '1px solid #fff',
    backgroundColor: THEME.background.colors.white
  }),
  warning: css({
    position: 'absolute',
    top: '-4px',
    right: '-4px',
    width: '16px',
    height: '14px',
    textAlign: 'center',
    borderRadius: '2px',
    backgroundColor: THEME.attention.colors.yellow
  })
};
