import ReactModal, { Props } from 'react-modal';
import { Icon, IconProps } from '../Icon';

ReactModal.setAppElement('#container');

export interface ModalProps extends Omit<Props, 'isOpen' | 'onRequestClose'> {
  open?: boolean;
  onClose?: () => void;
  hasCloseIcon?: boolean;
  closeIconProps?: IconProps;
}

export const Modal = ({
  style,
  onClose,
  children,
  open = false,
  hasCloseIcon,
  closeIconProps,
  shouldReturnFocusAfterClose = false,
  ...restProps
}: ModalProps) => (
  <ReactModal
    isOpen={open}
    style={customStyles(style)}
    onRequestClose={onClose}
    ariaHideApp={false}
    shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    {...restProps}
  >
    {hasCloseIcon ? (
      <Icon
        size="12px"
        icon="close"
        color="#DEE5EC"
        onClick={onClose}
        css={{ cursor: 'pointer', position: 'absolute', top: '14px', right: '14px', zIndex: 15 }}
        {...closeIconProps}
      />
    ) : null}

    {children}
  </ReactModal>
);

const customStyles = (style?: Props['style']): ReactModal.Styles => ({
  ...style,
  content: {
    padding: 0,
    inset: '32px',
    border: 'none',
    margin: 'auto',
    outline: 'none',
    borderRadius: '4px',
    position: 'absolute',
    height: 'max-content',
    boxSizing: 'border-box',
    transition: 'transform 0.5s ease-in-out',
    ...style?.content
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 900,
    display: 'flex',
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'background-color .35s linear',
    ...style?.overlay
  }
});
