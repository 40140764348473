import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { FormStyle, Button } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { BasicFilterWrapper, TextSearchField } from '@/shared/molecules';

interface TemplateProps {
  onSubmit?: () => void;
  loading: boolean;
}

export const Template = ({ onSubmit, loading }: TemplateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div css={styles.header}>
        <h3 css={styles.title}>{t('Influencers')}</h3>
      </div>
      <BasicFilterWrapper css={{ padding: '16px' }}>
        <TextSearchField
          name="keyword"
          onSubmit={onSubmit}
          placeholder={t<string>('Search.UserFilter')}
          css={{ flex: 1 }}
        />

        <FormStyle.FieldWrapper css={{ maxWidth: '132px' }}>
          <Button
            variant="white"
            title={t('Button.Search')}
            loading={loading}
            disabled={loading}
            css={{ padding: '0 24px', width: '100%' }}
          />
        </FormStyle.FieldWrapper>
      </BasicFilterWrapper>
    </>
  );
};
const styles = {
  header: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    borderTop: THEME.border.base,
    borderBottom: THEME.border.base,
    height: '48px',
    boxSizing: 'border-box'
  }),
  title: css({
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    color: THEME.text.colors.black.lv1
  })
};
