import { useTiktokBusinessAdAccountsQuery } from '@/graphql';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { BudgetAllocationTable, PrepaidBalanceSummaryData, TikTokSummaryDataContext } from '@/shared/organisms';

export const TikTokBudgetAllocation = ({ summaryData }: TikTokSummaryDataContext) => {
  useUpdateDocumentTitle({ title: 'appHeader.Budget Allocation', appHeader: 'appHeader.Budget Allocation' });
  const { data, loading } = useTiktokBusinessAdAccountsQuery();

  return (
    <div>
      <PrepaidBalanceSummaryData summaryData={summaryData} />
      <BudgetAllocationTable
        data={data?.tiktokBusinessAdAccounts || []}
        loading={loading}
        prepaidBalance={summaryData?.prepaidBalance || 0}
      />
    </div>
  );
};
