import { useTranslation } from 'react-i18next';
import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { CampaignPost, CampaignPostsSummary } from '@/graphql';
import { DownloadCsvPagesAndRowsModal, PostDiscoveryDetailsModal } from '@/shared/molecules';
import { useAllCampaignPostsListTable, useDownloadCampaignPostsDiscovery } from './hooks';

const MAX_CSV_ROWS_EXPORT_FOR_POST_DISCOVERY = 10_000;

export interface CampaignPostsDiscoveryListProps extends TableData<CampaignPost> {
  open?: boolean;
  onClose?: () => void;
  summary?: CampaignPostsSummary;
}

export const CampaignPostsDiscoveryList = ({
  open,
  onClose,
  loading,
  summary,
  data = [],
  totalRecords
}: CampaignPostsDiscoveryListProps) => {
  const { t } = useTranslation();
  const { rows, columns, selectedColumns } = useAllCampaignPostsListTable({
    total: totalRecords,
    postDiscoveryData: data,
    summaries: summary?.sum,
    average: summary?.average,
    currencyId: summary?.currencyId
  });
  const { limit, downloading, handleDownloadCsv } = useDownloadCampaignPostsDiscovery({
    columns,
    onClose,
    selectedColumns
  });

  return (
    <Table
      loading={loading}
      data={{ rows, columns, rowHeight: '112px' }}
      empty={{ hideDescription: true, title: t('No campaigns has been found') }}
      noTopBorderRadius
      hasBorder
    >
      <PostDiscoveryDetailsModal posts={data} />
      <DownloadCsvPagesAndRowsModal
        open={open}
        limit={limit}
        onClose={onClose}
        downloading={downloading}
        onSubmit={handleDownloadCsv}
        maxRows={MAX_CSV_ROWS_EXPORT_FOR_POST_DISCOVERY}
      />
    </Table>
  );
};
