import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/MarketplaceCampaignReportGrid.graphql';
import { Query, QuerymarketplaceCampaignReportGridArgs } from '../../__generated__/globalTypes';

export const useMarketplaceCampaignReportGridQuery = (
  options?: QueryHookOptions<Query, QuerymarketplaceCampaignReportGridArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerymarketplaceCampaignReportGridArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useMarketplaceCampaignReportGridLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerymarketplaceCampaignReportGridArgs>
): QueryResult<Query, QuerymarketplaceCampaignReportGridArgs> & {
  getMarketplaceCampaignReportGrid: LazyQueryExecFunction<Query, QuerymarketplaceCampaignReportGridArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getMarketplaceCampaignReportGrid, result] = useLazyQuery<Query, QuerymarketplaceCampaignReportGridArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getMarketplaceCampaignReportGrid, ...result };
};

export const useMarketplaceCampaignReportGridPromiseQuery = () => {
  const client = useApolloClient();

  const getMarketplaceCampaignReportGrid = useCallback(
    (options?: Omit<QueryOptions<QuerymarketplaceCampaignReportGridArgs, Query>, 'query'>) =>
      client.query<Query, QuerymarketplaceCampaignReportGridArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getMarketplaceCampaignReportGrid };
};
