import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType, useBrandAccountsQuery } from '@/graphql';
import { NoDataContentWrapperProps, TextCutter } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { InfluencerAvatar, InfluencerPostDetailsModal } from '@/shared/molecules';
import { formatIntNumber, formatPercent } from '@/shared/utils';
import { useInfluencerProfileOpacityContent } from '../../hooks';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerProfileMentionedAccountsWrapper } from '../shared';

const BRAND_POST_PARAMS_KEY = 'brandPostId';

export interface InfluencerInstagramProfileMentionedAccountsProps
  extends Pick<NoDataContentWrapperProps, 'wrapperText' | 'wrapperLink'> {
  className?: string;
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerInstagramProfileMentionedAccounts = ({
  wrapperText,
  wrapperLink,
  influencerId,
  socialAccount
}: InfluencerInstagramProfileMentionedAccountsProps) => {
  const { t } = useTranslation();
  const [selectedPostIds, setSelectedPostIds] = useState<readonly number[]>([]);
  const { setPostDetailsParam } = usePostDetailsParam(BRAND_POST_PARAMS_KEY);
  const { opacityContent } = useInfluencerProfileOpacityContent({
    influencerId,
    socialAccount,
    skipEstimateContent: true
  });
  const { data, loading } = useBrandAccountsQuery({
    variables: {
      influencerId,
      socialAccountId: socialAccount.id,
      socialMedia: socialAccount.type as SocialAccountType
    }
  });

  const brandAccountsData = data?.brandAccounts;

  return (
    <>
      <InfluencerProfileMentionedAccountsWrapper
        {...opacityContent}
        loading={loading}
        data={brandAccountsData?.brandAccounts || []}
        wrapperText={wrapperText || opacityContent.wrapperText}
        wrapperLink={wrapperLink || opacityContent.wrapperLink}
        headerMetrics={[
          {
            label: t<string>('HeaderColumn.Avg Engagement Rate'),
            value: formatPercent(brandAccountsData?.averageEngagementRate)
          },
          {
            label: t<string>('HeaderColumn.Avg Engagement'),
            value: formatIntNumber(brandAccountsData?.averageEngagement)
          }
        ]}
        renderItem={({ styles, account: { name, avatar, postIds, mentionPostsCount, averageEngagementRate } }) => (
          <div css={styles.brandAccountItem}>
            <InfluencerAvatar size="64px" src={avatar} css={styles.brandAccountAvatar} />
            <TextCutter text={name} lines={1} css={styles.brandAccountName} />
            <p
              css={styles.brandAccountMention}
              onClick={() => {
                if (postIds.length) {
                  setSelectedPostIds(postIds);
                  setPostDetailsParam(postIds[0]);
                }
              }}
            >
              {t('mention', { count: mentionPostsCount })}
            </p>
            <TextCutter
              lines={1}
              css={styles.brandAccountRate}
              text={`${t(`HeaderColumn.ER`)}: ${formatPercent(averageEngagementRate)}`}
            />
          </div>
        )}
      />

      <InfluencerPostDetailsModal
        paramKey={BRAND_POST_PARAMS_KEY}
        posts={selectedPostIds.map((postId) => ({ id: postId, influencerId }))}
      />
    </>
  );
};
