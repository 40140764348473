import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';
import { PricingTableItem } from '@/graphql';
import { formatPrice } from '@/shared/utils';
import { CURRENCY_SYMBOLS, THEME } from '@/shared/constants';
import { useGetPaymentPlanData, UpdatePlanModalWithButton, useTikTokAdvertiserSummaryData } from '@/shared/organisms';

interface PlanProps {
  data: PricingTableItem;
}

export const Plan = ({ data }: PlanProps) => {
  const { t } = useTranslation();
  const { getPaymentPlan } = useGetPaymentPlanData([data]);
  const { summaryData } = useTikTokAdvertiserSummaryData();

  const { name, price, currency, priceType } = data;
  const isUpcomingPlan = name === summaryData?.upcomingSubscriptionPlan;

  return (
    <div css={styles.wrapper}>
      <div css={{ flex: 'none', width: '170px' }}>
        <p css={{ fontSize: '16px', fontWeight: 600, lineHeight: '90%', textTransform: 'capitalize' }}>
          {t(`Plan.${name}`)}
        </p>
        <p css={{ lineHeight: '100%', marginTop: '16px' }}>
          <span css={{ fontSize: '24px', fontWeight: 600 }}>
            {CURRENCY_SYMBOLS[currency]}
            {formatPrice(price, null, 0)}
          </span>
          <span css={{ fontSize: '12px', fontWeight: 400, color: THEME.text.colors.gray.lv3 }}>
            /{priceType.toLowerCase()}
          </span>
        </p>
        <UpdatePlanModalWithButton
          plan={data}
          title={t('Button.Upgrade')}
          isUpcomingPlan={isUpcomingPlan}
          css={{ width: 'max-content', padding: '0 24px', marginTop: '16px', height: THEME.button.base.height.lv1 }}
        />
      </div>

      <div css={styles.features}>
        {getPaymentPlan({ name }).features.map(({ label }, i) => (
          <div key={i} css={styles.feature}>
            <Icon icon="check" css={styles.icon} color={THEME.attention.colors.blue} size={9} />
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    gap: '16px',
    display: 'flex',
    alignItems: 'flex-start',
    '&:not(:first-of-type)': {
      marginTop: '24px',
      paddingTop: '24px',
      boxSizing: 'border-box',
      borderTop: `1px solid ${THEME.border.colors.gray.lv1}`
    }
  }),
  features: css({
    flex: 1,
    gap: '8px 24px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)'
  }),
  feature: css({
    gap: '5px',
    display: 'flex',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '100%',
    alignItems: 'center'
  }),
  icon: css({
    padding: '2.5px',
    borderRadius: '50%',
    boxSizing: 'content-box',
    backgroundColor: 'rgba(56, 146, 229, 0.3)'
  })
};
