import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { FilterAction } from '@/shared/types';
import { Template, TemplateProps } from './Template';
import { MarketplacePostsFilterFormValues } from './schemaTypes';

export type MarketplacePostsFilterProps = FilterAction<MarketplacePostsFilterFormValues> &
  Omit<TemplateProps, 'onSubmit'>;

export const MarketplacePostsFilter = ({ filter, setFilter, ...templateProps }: MarketplacePostsFilterProps) => (
  <FilterWrapper css={{ borderRadius: 0 }}>
    <FilterProvider onSubmit={setFilter} initialValues={filter} basicFilter={<Template {...templateProps} />} />
  </FilterWrapper>
);

export * from './schemaTypes';
