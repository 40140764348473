import { useState } from 'react';
import { useAllEngagementsQuery } from '@/graphql';
import { NOT_FOUND } from '@/shared/constants';
import { Header, ListWithPagination } from '@/shared/molecules';
import { useEngagementPermissions, useUserRoles } from '@/auth';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { ButtonLink, RenderDataWithFallback } from '@/shared/atoms';
import { useQueryHelper, useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import {
  EngagementOnboarding,
  EngagementCampaignsList,
  EngagementCampaignsFilter,
  useEngagementCampaignsFilter
} from '@/shared/organisms';

export const Engagements = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'documentTitle.Engagement', appHeader: 'appHeader.Engagement' });
  const { isAdvertiser } = useUserRoles();
  const { t, enqueueSnackbar } = useQueryHelper();
  const [isOnboarding, setIsOnboarding] = useState(false);
  const { hideAddEngagementBtn } = useEngagementPermissions();
  const { page, limit, filter } = useEngagementCampaignsFilter();

  const { data, previousData, loading } = useAllEngagementsQuery({
    fetchPolicy: 'no-cache', // will always trigger onError to setIsOnboarding
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      ...filter,
      status: filter.status || null,
      salesPicIds: filter.salesPicIds.map(Number),
      influencerPicIds: filter.influencerPicIds.map(Number),
      campaignCategoryIds: filter.campaignCategoryIds.map(Number)
    }),
    onError: (err) => {
      if (err.message === NOT_FOUND && isAdvertiser) {
        setIsOnboarding(true);
      } else {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      }
    }
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allEngagementsCount?.totalNumber;

  return (
    <RenderDataWithFallback
      hasNoData={isOnboarding}
      loading={isAdvertiser && loading}
      noDataNode={<EngagementOnboarding />}
    >
      <Header>
        {!hideAddEngagementBtn ? (
          <ButtonLink
            variant="blue"
            to="/engagement/add"
            title={t('Button.Add Campaign')}
            prefixIcon={{ icon: 'plus', size: '10px' }}
            css={{ minWidth: '143px', textTransform: 'capitalize', marginLeft: 'auto' }}
          />
        ) : null}
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <EngagementCampaignsFilter />
        <EngagementCampaignsList loading={loading} totalRecords={totalRecords} data={result?.allEngagements || []} />
      </ListWithPagination>
    </RenderDataWithFallback>
  );
};
