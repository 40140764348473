import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useUserRoles } from '@/auth';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAllAnalyticsSubscriptionPlansOptions } from '@/shared/hooks';
import { SelectorField } from '@/shared/molecules';
import { useTiktokAdvertiserPlanDataQuery } from '@/graphql';
import { formatPrice, localizedDateFormatter } from '@/shared/utils';
import { useAccountsFilterOptions } from '../../Accounts/AccountsFilter/hooks';
import { AccountManagerPlanFormValues, AccountManagerPlanFormKeys } from './types';

export const Template = () => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const {
    watch,
    formState: { isSubmitting, defaultValues }
  } = useFormContext<AccountManagerPlanFormValues>();
  const { data } = useTiktokAdvertiserPlanDataQuery({
    skip: !isAdminStaff || !defaultValues?.advertiserId,
    variables: { advertiserId: defaultValues?.advertiserId as number }
  });
  const { tikTokAdvertiserPlanOptions } = useAccountsFilterOptions();
  const { allSubscriptionPlanOptions } = useAllAnalyticsSubscriptionPlansOptions({
    hasNoPlanOption: !defaultValues?.accountManagerPlan
  });

  const accountManagerPlanValue = watch('accountManagerPlan');
  const tiktokPlan = data?.tiktokAdvertiserPlanData;

  return (
    <FormStyle.Wrapper css={{ margin: 0, flex: 1, boxShadow: THEME.shadows.boxShadow }} hasBorder>
      <div css={{ padding: '24px' }}>
        <FormStyle.SectionTitle label={t('Manage Account Plan')} />

        <FormStyle.FieldsGroup itemsPerRow={2}>
          <SelectorField<AccountManagerPlanFormKeys>
            name="accountManagerPlan"
            title={t('Selector.Account Manager Plan')}
            options={allSubscriptionPlanOptions}
            required
          />
        </FormStyle.FieldsGroup>

        {isAdminStaff && (
          <>
            <FormStyle.SectionTitle
              label={t('TikTok Special Plan')}
              css={{ marginTop: '16px', marginBottom: '24px' }}
            />
            {tiktokPlan ? (
              <div css={styles.tiktokPlan}>
                <div>
                  <p>{t('Plan Name')}</p>
                  <p>
                    {tikTokAdvertiserPlanOptions.find((option) => option.value === tiktokPlan.subscriptionPlan)
                      ?.label || '-'}
                  </p>
                </div>
                <div>
                  <p>{t('Plan Cost')}</p>
                  <p>
                    {formatPrice(tiktokPlan.planPrice, tiktokPlan.currencyId, 0)}
                    {typeof tiktokPlan.planPrice === 'number' ? tiktokPlan.currencyId : null}
                  </p>
                </div>
                <div>
                  <p>{t('Expiration Date')}</p>
                  <p>
                    {tiktokPlan.expirationDate ? localizedDateFormatter(tiktokPlan.expirationDate, 'yyyy/MM/dd') : '-'}
                  </p>
                </div>
                <div>
                  <p>{t('Payment Date')}</p>
                  <p>{tiktokPlan.paymentDate ? localizedDateFormatter(tiktokPlan.paymentDate, 'yyyy/MM/dd') : '-'}</p>
                </div>
              </div>
            ) : (
              <div css={{ fontSize: '14px' }}>{t('No plan')}</div>
            )}
          </>
        )}
      </div>

      <FormStyle.ButtonSubmitWrapper>
        <FormStyle.ButtonSubmit
          type="submit"
          title={t('Button.Save')}
          loading={isSubmitting}
          disabled={!accountManagerPlanValue}
        />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};
const styles = {
  tiktokPlan: css({
    gap: '8px',
    display: 'grid',
    fontSize: '14px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    '& div > p:last-of-type': { fontWeight: 600, marginTop: '4px' }
  })
};
