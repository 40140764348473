import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateTalentAgencyUser.graphql';
import { Mutation, MutationcreateTalentAgencyUserArgs } from '../../__generated__/globalTypes';

export const useCreateTalentAgencyUserMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateTalentAgencyUserArgs>
) => {
  const [callCreateTalentAgencyUser, result] = useMutation<Mutation, MutationcreateTalentAgencyUserArgs>(
    MUTATION,
    options
  );

  return { callCreateTalentAgencyUser, ...result };
};

export type CreateTalentAgencyUserMutationFunction = Mutation['createTalentAgencyUser'];
