import { YoutubeInfluencerProfile, YoutubeInfluencers } from '@/shared/organisms';
import { useDefaultInfluencerSearchProps, useSocialInfluencersSelectButtonsProps } from './hooks';

export const EngagementYoutubeInfluencersSearch = () => {
  const { isEngagementPage, isTtcmCampaign } = useDefaultInfluencerSearchProps();
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialInfluencersSelectButtonsProps<YoutubeInfluencerProfile>({ socialMedia: 'YOUTUBE' });

  return (
    <YoutubeInfluencers
      isTtcmCampaign={isTtcmCampaign}
      isEngagementPage={isEngagementPage}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
