import { useTranslation } from 'react-i18next';
import { SocialPostType } from '@/graphql';
import { DownloadButton } from '@/shared/atoms';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { PostsList, PostsFilter, usePostsFilter } from '@/shared/organisms';
import { DownloadCsvPagesAndRowsModal, Header, ListWithPagination } from '@/shared/molecules';
import { usePostsData, usePostsCsvDownload } from './hooks';

const MAX_CSV_ROWS_EXPORT_FOR_POSTS = 10000;

export const Posts = () => {
  useCustomHeader();
  useUpdateDocumentTitle({ title: 'documentTitle.Posts Search', appHeader: 'appHeader.Posts Search', href: '' });
  const { t } = useTranslation();
  const { limit, filter } = usePostsFilter();
  const { posts, loading, totalRecords } = usePostsData();
  const { downloadModal, downloading, handlePostsCsvDownload } = usePostsCsvDownload();

  return (
    <>
      <Header>
        <DownloadButton title={t('CSV Download')} css={{ marginLeft: 'auto' }} onClick={downloadModal.open} />
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <PostsFilter />
        <PostsList
          data={posts}
          loading={loading}
          totalRecords={totalRecords}
          hasSimilarPosts={filter.socialMedia === SocialPostType.INSTAGRAM}
        />
      </ListWithPagination>

      <DownloadCsvPagesAndRowsModal
        limit={limit}
        downloading={downloading}
        open={downloadModal.status}
        onClose={downloadModal.close}
        onSubmit={handlePostsCsvDownload}
        maxRows={MAX_CSV_ROWS_EXPORT_FOR_POSTS}
      />
    </>
  );
};
