import { Navigate, Outlet, useLoaderData, useMatchRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useUserRoles } from '@/auth';
import { EngagementCampaignStatus } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useCustomHeader, useQueryHelper } from '@/shared/hooks';
import { Header } from '@/shared/molecules';
import { EngagementStatus, EngagementTabs, stickyInfluencersSearchHeader } from '@/shared/organisms';
import { EngagementRouter } from '../../../app/_private-routes/engagement';
import { EngagementDetailsProvider } from './EngagementDetailsContext';

export const EngagementDetails = () => {
  useCustomHeader();
  const { isAdvertiser } = useUserRoles();
  const matchPath = useMatchRoute();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { loading, networkStatus, engagement, engagementProposal, errorMessage } = useLoaderData({
    from: '/_private-routes/engagement/$id'
  }) as Awaited<ReturnType<(typeof EngagementRouter)['/_private-routes/engagement/$id']['1']['loader']>>;

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(t(errorMessage), { variant: 'error' });
      navigate({ to: '/engagement' });
    }
  }, [errorMessage]);

  if (
    isAdvertiser &&
    matchPath({ to: '/engagement/$id/proposal' }) &&
    engagement?.status === EngagementCampaignStatus.DRAFT
  ) {
    return <Navigate to="/not-found" />;
  }

  return (
    <RenderDataWithFallback loading={loading && networkStatus === 1}>
      {!!engagement && !!engagementProposal ? (
        <EngagementDetailsProvider value={{ engagement, engagementProposal }}>
          <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
            <div css={stickyInfluencersSearchHeader}>
              <Header>
                <EngagementStatus status={engagement?.status} css={{ marginLeft: '4px' }} />
              </Header>

              <EngagementTabs engagement={engagement} />
            </div>
            <Outlet />
          </div>
        </EngagementDetailsProvider>
      ) : null}
    </RenderDataWithFallback>
  );
};
