import { FilterAction } from '@/shared/types';
import { FilterWrapper, FilterWrapperProps, FilterProvider } from '../Filter';
import { Template } from './Template';
import { KeywordFilterFormValues } from './types';

export interface KeywordFilterProps extends FilterAction<KeywordFilterFormValues>, FilterWrapperProps {
  placeholder?: string;
}

export const KeywordFilter = ({ filter, children, setFilter, className, placeholder }: KeywordFilterProps) => (
  <FilterWrapper css={{ padding: '12px 16px 16px' }} className={className}>
    <FilterProvider
      onSubmit={setFilter}
      initialValues={filter}
      basicFilter={<Template placeholder={placeholder}>{children}</Template>}
    />
  </FilterWrapper>
);

export * from './types';
