import { zodValidator } from '@tanstack/zod-adapter';
import { PermissionRoute } from '@/app-components';
import { HashtagsAnalytics } from '@/pages/HashtagsAnalytics';
import { adminStaffRoles } from '@/shared/constants';
import { HashtagsAnalyticsSearchSchema } from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const HashtagAnalyticsRouter = {
  '/_private-routes/hashtag-analytics': [
    () => <PermissionRoute permissions={adminStaffRoles} element={<HashtagsAnalytics />} />,
    { validateSearch: zodValidator(HashtagsAnalyticsSearchSchema) }
  ]
} as const satisfies PartialRouter;
