import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { Genders, InfluencerSearchSortField, SocialAccountStatus } from '@/graphql';
import { FilterValidations } from '@/shared/validations';
import { LIMIT } from '@/shared/constants';
import { DefaultInfluencersProps, InfluencersBaseSearchSchema, InfluencersFilterSchema } from '../types';
import { getInitialAllInfluencersFormValues } from './utils';

export const AllInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  keyword: true,
  genders: true,
  statuses: true,
  regionIds: true,
  countryIds: true,
  includeIds: true,
  categoryIds: true,
  keywordField: true,
  socialMedias: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  campaignDisplay: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  minCampaignsJoined: true,
  maxCampaignsJoined: true,
  minEngagementViewsRate: true,
  maxEngagementViewsRate: true,
  minEngagementFollowersRate: true,
  maxEngagementFollowersRate: true,
  talentAgencyOrPartnerIds: true
});
export const AllInfluencersSearchSchema = z.object({
  filter: AllInfluencersFilterSchema.default(AllInfluencersFilterSchema.parse(getInitialAllInfluencersFormValues())),
  sort: FilterValidations.sort(InfluencerSearchSortField).default({ field: null, order: null }),
  page: z.number().default(1),
  limit: z.number().default(LIMIT),
  state: InfluencersBaseSearchSchema.shape.state
});

export type AllInfluencersSearchSchemaType = z.infer<typeof AllInfluencersSearchSchema>;
export type AllInfluencersFilterFormValues = z.infer<typeof AllInfluencersFilterSchema>;
export type AllInfluencersFilterFormKeys = UnionOfKeys<AllInfluencersFilterFormValues>;
export type AllInfluencersProps = DefaultInfluencersProps<InfluencerProfile>;
export interface InfluencerProfile {
  id: number;
  name: string;
  tags: readonly string[];
  xhs?: boolean;
  email?: boolean;
  avatar?: string;
  tiktok?: boolean;
  douyin?: boolean;
  twitter?: boolean;
  youtube?: boolean;
  threads?: boolean;
  facebook?: boolean;
  instagram?: boolean;
  age?: number | null;
  gender: Genders | '';
  isBrandAccount: boolean;
  followers?: number | null;
  engagement?: number | null;
  engagementRate?: number | null;
  engagementViewsRate?: number | null;
  engagementFollowersRate?: number | null;
  socialAccountId?: number | null;
  status: SocialAccountStatus | null;
  country: { id?: string; name?: string };
}
