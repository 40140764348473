import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashtagType } from '@/graphql';
import { FormStyle } from '@/shared/atoms';
import { formatHashTags } from '@/shared/utils';
import { HashtagCloud } from '../HashtagCloud';
import { HashTagForm } from '../HashTagForm';
import { RHFFieldWrapper } from './shared';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';

export interface HashtagSuggestionFieldProps<T extends string> extends DefaultFieldProps<T, string[]> {
  maxHashtags?: number;
  hasHashTagForm?: boolean;
  getHashtagsCloud?: () => void;
  onTagClick?: (tag: string) => void;
  hashtagsCloud?: readonly HashtagType[];
}

export const HashtagSuggestionField = <T extends string>({ className, ...props }: HashtagSuggestionFieldProps<T>) => {
  const { t } = useTranslation();
  // help to prevent onHashTagFormBlur on being called when hashtag is clicked
  const [hoverHashtagCloud, setHoverHashtagCloud] = useState(false);
  const [hashtagIndex, setHashtagIndex] = useState<number | null>(null);
  const {
    fieldValue,
    fieldRegister,
    setValueOptions,
    fieldWrapperProps,
    formContext: { trigger, setValue },
    fieldProps: {
      name,
      onChange,
      onTagClick,
      getHashtagsCloud,
      hashtagsCloud = [],
      hasHashTagForm = true,
      ...restFieldProps
    }
  } = useFormatRHFFieldProps(props);

  const handleChangeHashtags = (newHashtags: string[]) => {
    if (onChange) {
      onChange(name, newHashtags);
    } else {
      setValue<string>(name, newHashtags, setValueOptions);
      trigger(name);
    }
  };

  const onHashTagFormBlur = () => {
    if (!hoverHashtagCloud) {
      handleChangeHashtags(formatHashTags(fieldValue));
    }
    getHashtagsCloud?.();
  };

  const handleTagClick = (value: string) => {
    let newIndex = hashtagIndex || fieldValue.length;
    const emptyTagIndex = fieldValue.indexOf('');
    // no empty inputs & no focused inputs
    if (emptyTagIndex !== -1 && !hashtagIndex) {
      newIndex = emptyTagIndex;
    }
    // reach hashtag limit & no empty hashtag inputs
    if (fieldValue.length >= 9 && emptyTagIndex === -1) {
      return;
    }

    fieldValue[newIndex] = value; // set new hashtag value
    setHashtagIndex(null); // clear focused input
    setValue<string>(name, formatHashTags(fieldValue), setValueOptions);
    trigger(name);
    getHashtagsCloud?.();
  };

  const toggleMouseEnter = () => {
    setHoverHashtagCloud(true);
  };

  const toggleMouseOut = () => {
    setHoverHashtagCloud(false);
  };

  return (
    <FormStyle.FieldsGroup itemsPerRow={hasHashTagForm ? 2 : 1} className={className}>
      <FormStyle.FocusInput ref={fieldRegister.ref} />
      {hasHashTagForm ? (
        <RHFFieldWrapper
          {...fieldWrapperProps}
          title={fieldWrapperProps.title ?? t('TextForm.Hash Tags')}
          help={fieldWrapperProps.help ?? t('Tooltip.At least three hashtags')}
          dynamicError
        >
          <HashTagForm
            maxHashtags={3}
            {...restFieldProps}
            onBlur={onHashTagFormBlur}
            onChange={(value) => {
              setValue<string>(name, formatHashTags(value), setValueOptions);
              trigger(name);
            }}
          />
        </RHFFieldWrapper>
      ) : null}

      {hashtagsCloud?.length > 0 ? (
        <RHFFieldWrapper
          onMouseLeave={toggleMouseOut}
          onMouseEnter={toggleMouseEnter}
          title={t('Recommended Hash Tags')}
          help={t('Tooltip.HastagRecommendationHelp')}
        >
          <HashtagCloud onSelect={onTagClick || handleTagClick} data={hashtagsCloud} />
        </RHFFieldWrapper>
      ) : null}
    </FormStyle.FieldsGroup>
  );
};
