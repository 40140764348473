import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { FormAction } from '@/shared/types';
import { FormProvider } from '@/shared/atoms';
import { TextField } from '@/shared/molecules';
import { BaseValidations } from '@/shared/validations';
import { Modal, ModalFooterActions } from '@/shared/atoms';
import { ModalContent, ModalProps, ModalTitle } from '@/shared/atoms';

const DuplicateCampaignFormSchema = z.object({ title: BaseValidations.CampaignTitleSchema });
export type DuplicateCampaignFormValues = z.infer<typeof DuplicateCampaignFormSchema>;

export interface DuplicateCampaignModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  cloning?: boolean;
  onDuplicate: FormAction<DuplicateCampaignFormValues>['onSubmit'];
}

export const DuplicateCampaignModal = ({
  onClose,
  cloning,
  onDuplicate,
  ...restProps
}: DuplicateCampaignModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal {...restProps} onClose={onClose} hasCloseIcon css={{ width: THEME.modal.size.lv1 }}>
      <FormProvider<DuplicateCampaignFormValues> zodSchema={DuplicateCampaignFormSchema} onSubmit={onDuplicate}>
        {({ values }) => (
          <>
            <ModalContent>
              <ModalTitle>{t('Modal.Duplicate this Campaign')}</ModalTitle>
              <p css={{ margin: '16px 0 4px' }}>{t('Modal.DuplicateCampaignDescription')}</p>

              <TextField<keyof DuplicateCampaignFormValues>
                name="title"
                title={t('TextForm.Campaign Title')}
                placeholder={t<string>('TextForm.InstagramCampaign')}
                required
              />
            </ModalContent>

            <ModalFooterActions
              cancelButtonProps={{ onClick: onClose }}
              submitButtonProps={{
                type: 'submit',
                loading: cloning,
                disabled: !values.title,
                title: t(cloning ? 'Button.Duplicating' : 'Button.Duplicate')
              }}
            />
          </>
        )}
      </FormProvider>
    </Modal>
  );
};
