import { YoutubeAnalyticsPostsPayload } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerYoutubeAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useInfluencerYoutubeVideosTable } from './hooks';

interface InfluencerYoutubeVideosTableProps extends TableData<YoutubeAnalyticsPostsPayload> {
  mainSocialAccountId?: number;
}

export const InfluencerYoutubeVideosTable = ({
  loading,
  data = [],
  mainSocialAccountId
}: InfluencerYoutubeVideosTableProps) => {
  const { rows, columns } = useInfluencerYoutubeVideosTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '57px' }} noTopBorderRadius hasBorder />

      <InfluencerYoutubeAnalyticsPostDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId }))}
      />
    </>
  );
};
