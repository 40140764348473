import { useTranslation } from 'react-i18next';
import { AppLanguage } from '@/shared/types';
import { FormStyle, Icon } from '@/shared/atoms';
import { localizedDateFormatter } from '@/shared/utils';
import { useYoutubeAnalyticsCountriesCategoriesOptions } from '@/shared/hooks';
import { BasicFilterWrapper, SelectorField, SingleDatePickerField } from '@/shared/molecules';
import { AnalyticsTrendingYoutubePostsFilterFormKeys } from './schemaTypes';

interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t, i18n } = useTranslation();
  const { date, allCountryOptions, allCategoryOptions } = useYoutubeAnalyticsCountriesCategoriesOptions();

  return (
    <BasicFilterWrapper alignItems="center">
      <FormStyle.FieldWrapper css={{ display: 'flex', alignItems: 'center', flex: 'none' }}>
        <Icon icon="trend-two-tones" size={20} />
        <p css={{ fontSize: '18px', lineHeight: '22px', marginLeft: '8px' }}>{t('Heading.Trend Ranking')}</p>
        <p css={{ fontSize: '11px', marginLeft: '22px' }}>
          {`${t('Last Updated')}: ${localizedDateFormatter(date || null, 'PPP', i18n.language as AppLanguage)}`}
        </p>
      </FormStyle.FieldWrapper>

      <SelectorField<AnalyticsTrendingYoutubePostsFilterFormKeys>
        name="country"
        onSubmit={onSubmit}
        options={allCountryOptions}
        css={{ maxWidth: '200px', marginLeft: 'auto' }}
      />
      <SelectorField<AnalyticsTrendingYoutubePostsFilterFormKeys>
        name="category"
        onSubmit={onSubmit}
        css={{ maxWidth: '200px' }}
        options={allCategoryOptions}
      />
      <SingleDatePickerField<AnalyticsTrendingYoutubePostsFilterFormKeys>
        name="date"
        onSubmit={onSubmit}
        css={{ maxWidth: '200px' }}
        disabledDays={{ after: new Date() }}
      />
    </BasicFilterWrapper>
  );
};
