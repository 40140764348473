import { EngagementPostEditV2 } from '@/graphql';
import { getToday } from '@/shared/utils';
import { EngagementPostFormValues } from './types';

export const getEngagementPostFormValues = (post?: Partial<EngagementPostEditV2> | null): EngagementPostFormValues => {
  const socialAccountMedia = post?.influencer?.socialAccountMedia || '';
  const socialAccountId = String(post?.influencer?.socialAccountId || '');

  return {
    socialAccountId,
    socialAccountMedia,
    id: post?.id,
    isTtcmForm: false,
    content: post?.content || '',
    postUrl: post?.postUrl || '',
    planedPostDate: post?.planedPostDate || '',
    insightUrls: [...(post?.insightUrls || [])],
    influencerId: String(post?.influencer?.id || ''),
    materialsUrl: post?.materials?.map(({ url }) => url) || [],
    insightDataAcquisition: post?.insightDataAcquisition || getToday(),
    selectedAccount: socialAccountId && socialAccountMedia ? `${socialAccountId}-${socialAccountMedia}` : ''
  };
};
