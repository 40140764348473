import { css } from '@emotion/react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { POST_VIDEO_DIM, POST_THUMBNAIL_DIM, THEME } from '@/shared/constants';
import { Carousel, CarouselProps } from '@/shared/molecules';

export interface PostMediaPreviewProps {
  isReel?: boolean;
  images?: readonly string[];
  className?: string;
  onTagClick?: (tag: string) => void;
  currentMediaIndex?: CarouselProps['currSlideIdx'];
  onMediaChange?: CarouselProps['afterChangeHandler'];
  video?: { url?: string; title?: string; tags?: readonly string[] };
  reactPlayerProps?: ReactPlayerProps;
}

export const PostMediaPreview = ({
  video,
  isReel,
  className,
  onTagClick,
  images = [],
  onMediaChange,
  reactPlayerProps,
  currentMediaIndex
}: PostMediaPreviewProps) =>
  video && !isReel ? (
    <div css={styles.videoWrapper} className={className}>
      <ReactPlayer url={video.url} width={POST_VIDEO_DIM.width} height={POST_VIDEO_DIM.height} {...reactPlayerProps} />
      {video?.tags?.length ? (
        <p css={styles.tagWrapper}>
          {video.tags.map((tag, index) => (
            <span key={index} css={styles.tag} onClick={() => onTagClick?.(tag)}>
              #{tag}
            </span>
          ))}
        </p>
      ) : null}
      {video?.title ? (
        <p css={{ marginTop: '8px', fontSize: '14px', fontWeight: 600, lineHeight: 1.43 }}>{video.title}</p>
      ) : null}
    </div>
  ) : !!images.length || isReel ? (
    <div css={styles.imageWrapper} className={className}>
      <Carousel
        isReel={isReel}
        sources={images}
        currSlideIdx={currentMediaIndex}
        afterChangeHandler={onMediaChange}
        css={{ width: 'inherit', height: 'inherit' }}
        showIcon
      />
    </div>
  ) : null;

const styles = {
  videoWrapper: css({
    position: 'relative',
    marginBottom: '8px',
    marginRight: '24px',
    width: POST_VIDEO_DIM.width,
    height: POST_VIDEO_DIM.height
  }),
  imageWrapper: css({
    marginRight: '24px',
    width: POST_THUMBNAIL_DIM.width,
    height: POST_THUMBNAIL_DIM.height
  }),
  tagWrapper: css({
    fontSize: '12px',
    overflow: 'auto',
    marginTop: '8px',
    lineHeight: 1.17,
    maxHeight: '70px',
    color: THEME.text.colors.blue
  }),
  tag: css({
    cursor: 'pointer',
    '&:not(:first-of-type)': { paddingLeft: '8px' }
  })
};
