import { css } from '@emotion/react';
import React, { useMemo } from 'react';
import { Accept, DropEvent, FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

const baseStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  minHeight: '200px',
  border: '2px dashed #dee5ec',
  borderColor: '#dee5ec',
  borderRadius: '3px',
  outline: 'none',
  transition: 'border-color 0.3s ease-in-out',
  padding: '16px',
  boxSizing: 'border-box'
};

const activeStyle: React.CSSProperties = {
  borderColor: '#2196f3'
};

const acceptStyle: React.CSSProperties = {
  borderColor: '#00e676'
};

const rejectStyle: React.CSSProperties = {
  borderColor: 'tomato'
};

export interface DropAreaProps {
  notes: string[];
  multiple?: boolean;
  buttonLabel?: string | null;
  description?: string | null;
  accept?: Accept;
  onDrop: (acceptedFiles: File[], rejectedFiles?: FileRejection[], event?: DropEvent) => void;
  disabled: boolean;
}

export const DropArea = ({ notes, multiple, accept, onDrop, disabled, buttonLabel, description }: DropAreaProps) => {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    multiple,
    accept,
    disabled,
    onDrop
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const acceptedFileItems = (files: FileWithPath[]) =>
    files.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <div css={styles.center}>
          <input {...getInputProps()} />
          {acceptedFiles.length > 0 ? (
            <ul>{acceptedFileItems(acceptedFiles)}</ul>
          ) : (
            <>
              <Button
                variant="white"
                disabled={disabled}
                css={{ minWidth: '105px' }}
                title={buttonLabel || t('Button.Add File')}
              />
              <p css={{ fontSize: '14px', color: THEME.text.colors.gray.lv3, marginTop: '16px' }}>
                {description || t('General.DragAndDropInfo')}
              </p>
            </>
          )}
        </div>

        {notes.map((note) => (
          <p css={styles.note} key={note}>
            {note}
          </p>
        ))}
      </div>
    </div>
  );
};
const styles = {
  center: css({ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }),
  note: css({
    width: '100%',
    fontSize: '11px',
    lineHeight: 1.18,
    textAlign: 'center',
    color: THEME.text.colors.gray.lv3,
    '&:nth-of-type(1)': { marginTop: '16px' }
  })
};
