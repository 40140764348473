import { useTranslation } from 'react-i18next';
import { SwitchToggleField, SwitchToggleFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

declare const IS_DISABLED_ON_PROD: boolean;

export const ProAccountField = (props: Omit<SwitchToggleFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return !IS_DISABLED_ON_PROD ? (
    <SwitchToggleField<InfluencerFormKeys>
      title={t('CRM')}
      help={t('Tooltip.CRM toggle help')}
      name={InfluencerSharedKeys.isProAccount}
      {...props}
    />
  ) : null;
};
