import { InputPassword, InputPasswordProps } from '@/shared/atoms';
import { DefaultFieldProps } from './types';
import { useFormatRHFFieldProps } from './hooks';
import { RHFFieldWrapper } from './shared';

export type PasswordFieldProps<T extends string = string> = DefaultFieldProps<T, string[]> &
  Omit<InputPasswordProps, 'name' | 'title' | 'type' | 'onChange'>;

export const PasswordField = <T extends string = string>(props: PasswordFieldProps<T>) => {
  const { fieldRegister, fieldProps, fieldWrapperProps } = useFormatRHFFieldProps(props);

  return (
    <RHFFieldWrapper {...fieldWrapperProps}>
      <InputPassword
        {...fieldProps}
        {...fieldRegister}
        css={{ lineHeight: '30px', borderRadius: '3px' }}
        hasVisibleIcon
      />
    </RHFFieldWrapper>
  );
};
