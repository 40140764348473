import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { getFirstDay } from '@/shared/utils';

export const InvoicesFilterSchema = z.object({
  month: FilterValidations.string(),
  keyword: FilterValidations.string()
});
export const InvoicesSearchSchema = z.object({
  filter: InvoicesFilterSchema.default({
    month: getFirstDay()
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type InvoicesSearchSchemaType = z.infer<typeof InvoicesSearchSchema>;
export type InvoicesFilterFormValues = z.infer<typeof InvoicesFilterSchema>;
export type InvoicesFilterFormKeys = UnionOfKeys<InvoicesFilterFormValues>;
