import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagementInfluencerProposalList.graphql';
import { Mutation, MutationupdateEngagementInfluencerProposalListArgs } from '../../__generated__/globalTypes';

export const useUpdateEngagementInfluencerProposalListMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateEngagementInfluencerProposalListArgs>
) => {
  const [callUpdateEngagementInfluencerProposalList, result] = useMutation<
    Mutation,
    MutationupdateEngagementInfluencerProposalListArgs
  >(MUTATION, options);

  return { callUpdateEngagementInfluencerProposalList, ...result };
};

export type UpdateEngagementInfluencerProposalListMutationFunction = Mutation['updateEngagementInfluencerProposalList'];
