import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Icon, Popover, PopoverItem } from '@/shared/atoms';
import { LANGUAGE_OPTIONS, THEME } from '@/shared/constants';
import { styles as menuStyles } from './MenuItem';

export const LanguageSelector = () => {
  const { i18n, t } = useTranslation();

  return (
    <Popover
      withinPortal={false}
      target={({ togglePopover }) => (
        <div css={styles.trigger} onClick={togglePopover}>
          <div css={[menuStyles.anchor, styles.menuItem, { gap: '8px' }]}>
            <Icon icon={'earth'} width={20} height={20} className="icon-earth" />
            <span className="menu-item-title">{t('languageTitle')}</span>
            <Icon icon={'caret-down'} width={12} height={12} className="caret-down" css={{ marginLeft: 'auto' }} />
          </div>
        </div>
      )}
      position="bottom-start"
    >
      {({ togglePopover }) => (
        <ul css={styles.listMenu}>
          {LANGUAGE_OPTIONS.map((language) => (
            <PopoverItem
              onClick={() => {
                i18n.changeLanguage(language.value);
                togglePopover();
              }}
              key={language.value}
            >
              {language.label}
            </PopoverItem>
          ))}
        </ul>
      )}
    </Popover>
  );
};

const styles = {
  listMenu: css({
    width: '224px',
    border: THEME.border.base,
    background: THEME.background.colors.white,
    boxShadow: '0px 1px 2px rgba(39, 49, 59, 0.1)',
    borderRadius: '3px',
    outline: 'none'
  }),
  trigger: css({
    '&[data-state="open"]': {
      '.icon-earth': { color: THEME.text.colors.blue }
    }
  }),
  menuItem: css({
    height: '36px',
    margin: 0
  })
};
