import { css } from '@emotion/react';
import { Drawer as MantineDrawer, DrawerProps as MantineDrawerProp } from '@mantine/core';
import { ReactNode } from 'react';

import { THEME } from '@/shared/constants';


import { Icon } from '../Icon';

export interface DrawerProps extends MantineDrawerProp {
  title?: ReactNode;
  children: ReactNode;
  hasCloseButton?: boolean;
}

export const Drawer = ({ title, children, onClose, hasCloseButton = true, ...restProps }: DrawerProps) => (
  <MantineDrawer
    position="right"
    onClose={onClose}
    withCloseButton={false}
    closeOnClickOutside={false}
    styles={{ body: { padding: 0, height: '100%' } }}
    {...restProps}
  >
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {title ? (
        <div css={styles.header}>
          {title}

          {hasCloseButton ? (
            <Icon css={{ cursor: 'pointer' }} icon="close" size={12} color="#C5D0DA" onClick={onClose} />
          ) : null}
        </div>
      ) : null}

      {children}
    </div>
  </MantineDrawer>
);

const styles = {
  header: css({
    width: '100%',
    display: 'flex',
    fontSize: '18px',
    padding: '16px 24px',
    fontWeight: 'normal',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    borderBottom: THEME.border.base
  })
};
