import { z } from 'zod';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { SearchFilterDefaults } from '@/shared/validations';

export const AnalyticsIgCommentsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(ProfilePostsFilterSchema, { startDate: getThirtyDaysAgo(), endDate: getToday() }),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsIgCommentsSearchSchemaType = z.infer<typeof AnalyticsIgCommentsSearchSchema>;
