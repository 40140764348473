import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ExportCmsInvoiceToSpreadsheet.graphql';
import { Mutation, MutationexportCmsInvoiceToSpreadsheetArgs } from '../../__generated__/globalTypes';

export const useExportCmsInvoiceToSpreadsheetMutation = (
  options?: MutationHookOptions<Mutation, MutationexportCmsInvoiceToSpreadsheetArgs>
) => {
  const [callExportCmsInvoiceToSpreadsheet, result] = useMutation<Mutation, MutationexportCmsInvoiceToSpreadsheetArgs>(
    MUTATION,
    options
  );

  return { callExportCmsInvoiceToSpreadsheet, ...result };
};

export type ExportCmsInvoiceToSpreadsheetMutationFunction = Mutation['exportCmsInvoiceToSpreadsheet'];
