import { useTranslation } from 'react-i18next';
import { UGCPostType } from '@/graphql';
import { capitalizeText } from '@/shared/utils';
import { AddHashtag } from './AddHashtag';
import { Hashtag } from './HashTag';
import { UGCHashtagsType } from './types';
import { defaultUGCPostsHashtags } from './utils';

interface UGCHashTagsFilterProps {
  loading?: boolean;
  maxHashtag?: number; // Not includes 2 default hashtags
  hashtags?: UGCHashtagsType[];
  brandInstagramUserName?: string;
  activeId?: UGCHashtagsType['id'];
  onHashtagChange: (hashtag: UGCHashtagsType | null) => void;
  onCreateHashtag?: (hashtagId: string, callback: () => void) => void;
  onDeleteHashtag?: (hashtagId: string, callback: () => void) => void;
}

export const UGCHashTagsFilter = ({
  loading,
  activeId,
  hashtags = [],
  maxHashtag = 5,
  onCreateHashtag,
  onDeleteHashtag,
  onHashtagChange,
  brandInstagramUserName
}: UGCHashTagsFilterProps) => {
  const { t } = useTranslation();

  const defaultHashtags: UGCHashtagsType[] = brandInstagramUserName
    ? [
        {
          label: `@${brandInstagramUserName}`,
          title: capitalizeText(t('Brand mentions')),
          id: defaultUGCPostsHashtags.mentioned.id,
          postType: defaultUGCPostsHashtags.mentioned.postType
        },
        {
          title: t('Tagged Posts'),
          label: brandInstagramUserName,
          id: defaultUGCPostsHashtags.tagged.id,
          postType: defaultUGCPostsHashtags.tagged.postType
        }
      ]
    : [];

  return (
    <div css={{ display: 'flex', flexWrap: 'wrap', margin: '8px -8px -8px -8px' }}>
      {[...defaultHashtags, ...hashtags].map((hashtag) => (
        <div css={{ padding: '8px', boxSizing: 'border-box' }} key={hashtag.id}>
          <Hashtag
            {...hashtag}
            active={hashtag.id === activeId}
            onDelete={
              ![UGCPostType.MENTIONED, UGCPostType.TAGGED].includes(hashtag.postType as UGCPostType)
                ? onDeleteHashtag
                : undefined
            }
            onClick={() => onHashtagChange(hashtag.id === activeId ? null : hashtag)}
          />
        </div>
      ))}
      {hashtags.length < maxHashtag && onCreateHashtag ? (
        <div css={{ padding: '8px', boxSizing: 'border-box' }}>
          <AddHashtag loading={loading} onAdd={onCreateHashtag} />
        </div>
      ) : null}
    </div>
  );
};
