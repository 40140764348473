import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { AnchorPropsInternal } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export type ProfilePostsTabType = {
  label: (props: AnchorPropsInternal) => ReactNode;
};

export interface ProfilePostsTabsProps {
  options: ProfilePostsTabType[];
  className?: string;
}

export const ProfilePostsTabs = ({ options, className }: ProfilePostsTabsProps) => (
  <div css={styles.wrapper} className={className}>
    {options.map((option) => option.label({ css: styles.item }))}
  </div>
);
const styles = {
  wrapper: css({
    zIndex: 1,
    display: 'flex',
    position: 'relative'
  }),
  item: css({
    flex: 1,
    lineHeight: 1.38,
    height: '56px',
    display: 'flex',
    padding: '0 12px',
    cursor: 'pointer',
    fontSize: '13px',
    maxWidth: '160px',
    position: 'relative',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white,
    borderBottom: THEME.border.base,
    color: THEME.text.colors.black.lv1,
    borderTop: '2px solid transparent',

    '&:not(:first-of-type)': { borderLeft: THEME.border.base },
    '&.active': {
      color: THEME.text.colors.blue,
      borderBottom: '1px solid transparent',
      borderTop: `2px solid ${THEME.border.colors.blue.lv2}`
    },
    '&:hover': {
      fontWeight: 600
    }
  })
};
