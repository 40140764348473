import { getValuableVariables } from '@/shared/utils';
import { useTwitterAnalyticsOverviewQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { AnalyticsTwitterDashboardOverviewTemplate } from './AnalyticsTwitterDashboardOverviewTemplate';

export interface AnalyticsTwitterDashboardOverviewProps {
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsTwitterDashboardOverview = ({ analyticsAccount }: AnalyticsTwitterDashboardOverviewProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useTwitterAnalyticsOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      twitterAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  return (
    <AnalyticsTwitterDashboardOverviewTemplate
      loading={loading}
      accountId={analyticsAccount.id}
      data={data?.twitterAnalyticsOverview}
    />
  );
};
