import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMeta, MultiValue, PlaceholderProps, StylesConfig, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { HubspotDealData, useHubspotDealNameLazyQuery } from '@/graphql';
import { CheckBox, DropdownMenu } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useDeepCompareEffect } from '@/shared/hooks';
import { Option } from '@/shared/types';
import { DropdownIndicator } from '../Selector';
import { useHubspotDealsLoadOptions } from './hooks';

export interface HubspotDealsMultiSelectorProps {
  disabled?: boolean;
  countryId?: string;
  placeholder?: string;
  hubspotDealIds: string[];
  hideDeselectOption?: boolean;
  onChange?: (values: HubspotDealData[]) => void;
}

export const HubspotDealsMultiSelector = ({
  disabled,
  onChange,
  countryId,
  placeholder,
  hubspotDealIds
}: HubspotDealsMultiSelectorProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);
  const { debouncedLoadHubspotDealsOptions } = useHubspotDealsLoadOptions(countryId);
  const { getHubspotDealName, loading } = useHubspotDealNameLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: ({ hubspotDealName }) => {
      setOptions(hubspotDealName?.map((o) => ({ label: o.dealName, value: o.id, ...o })) || []);
    }
  });

  const numberOfSelectedOptions = options.length;
  const hasSelectedOption = numberOfSelectedOptions > 0;

  useDeepCompareEffect(() => {
    if (hubspotDealIds.length > 0) {
      getHubspotDealName({ variables: { hubspotDealIds } });
    }
  }, [hubspotDealIds]);

  const handleChange = (newOptions: Option[]) => {
    onChange?.(newOptions.map(({ label, value, ...restProps }) => restProps as HubspotDealData));
    setOptions(newOptions);
  };

  const handleSelectorChange = (selectedValues: MultiValue<Option>, { action }: ActionMeta<Option>) => {
    if (action === 'select-option') {
      handleChange(selectedValues as Option[]);
    }
  };

  const handleRemoveOption = (option: Option) => {
    const newOptions = options.filter((o) => o.value !== option.value);
    handleChange(newOptions);
  };

  return (
    <AsyncSelect
      cacheOptions
      styles={styles}
      value={options}
      options={options}
      isLoading={loading}
      onChange={handleSelectorChange}
      loadOptions={debouncedLoadHubspotDealsOptions}
      placeholder={!loading ? placeholder || t('Search by Deal name or full Deal ID') : ''}
      components={{
        DropdownIndicator: () => <DropdownIndicator />,
        MultiValueContainer: () => null,
        ValueContainer: ({ children, ...props }) => (
          <components.ValueContainer {...props}>
            {hasSelectedOption ? (
              <>
                {!props.selectProps.inputValue ? (
                  <components.Placeholder
                    {...(props as PlaceholderProps<Option, boolean>)}
                    {...(!props.selectProps.menuIsOpen
                      ? { css: { color: '#27313b !important', opacity: '1 !important', margin: '0 !important' } }
                      : {})}
                  >
                    {numberOfSelectedOptions > 1
                      ? t('Selected', { count: options.length })
                      : options.map((item) => item.label).join(', ')}
                  </components.Placeholder>
                ) : null}
                {children as ReactNode[][1]}
              </>
            ) : (
              children
            )}
          </components.ValueContainer>
        ),
        Option: ({ children, ...props }) => (
          <components.Option {...props}>
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              {props.data.label}
              <span css={{ marginLeft: '8px' }}>{`${t('ID')} : ${props.data.value}`}</span>
            </div>
          </components.Option>
        ),
        NoOptionsMessage: ({ children, ...props }) => (
          <components.NoOptionsMessage {...props} css={hasSelectedOption ? { padding: '0 !important' } : undefined}>
            {hasSelectedOption ? (
              <DropdownMenu
                css={{ position: 'relative', border: 'none', margin: 0, width: '100%', boxShadow: 'none' }}
                options={options.map((o) => ({
                  value: o.value,
                  label: (
                    <CheckBox css={{ width: '100%' }} label={o.label} onClick={() => handleRemoveOption(o)} checked />
                  )
                }))}
              />
            ) : (
              children
            )}
          </components.NoOptionsMessage>
        )
      }}
      isDisabled={disabled}
      isClearable={false}
      isMulti
    />
  );
};

const styles: StylesConfig<Option, true> = {
  control: (base, props) => ({
    ...base,
    outline: 'none',
    minHeight: '32px',
    boxShadow: 'none',
    lineHeight: '15px',
    borderRadius: '2px',
    border: THEME.border.base,
    background: props.isDisabled ? THEME.input.background.colors.disabled : base.background,
    '&:hover': { borderColor: THEME.input.border.colors.hover }
  }),
  option: (base, props) => ({
    ...base,
    backgroundColor: props.isFocused ? THEME.dropdown.hover : base.backgroundColor,
    '&:hover': {
      backgroundColor: props.isDisabled ? base.backgroundColor : THEME.dropdown.hover
    }
  }),
  menu: (base) => ({
    ...base,
    minWidth: '100%',
    marginTop: '1px',
    boxShadow: 'unset',
    borderRadius: '3px',
    width: 'max-content',
    border: THEME.border.base
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    cursor: 'pointer',
    paddingRight: '7px',
    svg: { color: THEME.text.colors.gray.lv3, height: '17px' }
  }),
  placeholder: (base) => ({
    ...base,
    width: '95%',
    fontWeight: 400,
    fontSize: '14px',
    marginLeft: '5px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: THEME.input.text.colors.placeholder
  }),
  valueContainer: (base) => ({
    ...base,
    flexWrap: 'nowrap',
    pointerEvents: 'none',
    color: THEME.input.text.colors.default
  })
};
