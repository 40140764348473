import { useEngagementPermissions } from '@/auth';
import {
  EngagementCampaignSearchSelectInfluencers,
  useEngagementAddInfluencersToProposalFromAnotherCampaignMutation
} from '@/graphql';
import { Button, ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { useTranslateCountry, useQueryHelper } from '@/shared/hooks';
import { formatPercent, formatIntNumber } from '@/shared/utils';

interface Props {
  campaignId?: number;
  listRecords: readonly EngagementCampaignSearchSelectInfluencers[];
}

export const useCampaignInfluencersTable = ({ listRecords, campaignId }: Props) => {
  const { tc } = useTranslateCountry();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { hideAddPackageToProposal } = useEngagementPermissions();
  const { callEngagementAddInfluencersToProposalFromAnotherCampaign, loading: addingProposal } =
    useEngagementAddInfluencersToProposalFromAnotherCampaignMutation();

  const handleAddCampaignInfluencersToProposal = async (fromCampaignId: number, name: string) => {
    if (!campaignId) {
      return;
    }

    try {
      await callEngagementAddInfluencersToProposalFromAnotherCampaign({
        variables: { input: { fromCampaignId, toCampaignId: campaignId } }
      });
      enqueueSnackbar(t('influencers from the campaign were added to the proposal', { name }), {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Package'), sticky: { active: true, hasBorderRight: true } },
    { title: t('HeaderColumn.Campaign Category') },
    { title: t('HeaderColumn.Country') },
    { title: t('HeaderColumn.ER'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Number of influencers'), styles: { textAlign: 'right' } }
  ];
  const rows: RowProps[] = listRecords.map(({ id, name, category, countries, engagementRate, numberOfInfluencers }) => [
    <div css={{ display: 'flex', justifyContent: 'space-between', minWidth: '350px' }}>
      <TableCell.Link label={name} target="_blank" to="/engagement/$id" params={{ id: id.toString() }} />
      {!hideAddPackageToProposal ? (
        <Button
          variant="blue"
          loading={addingProposal}
          disabled={addingProposal}
          suffixIcon={{ icon: 'plus', size: '10px' }}
          callback={() => handleAddCampaignInfluencersToProposal(id, name)}
          css={{ width: '24px', height: '24px', padding: 0, minWidth: 'unset' }}
          tooltip={{ disabled: addingProposal, help: t("Button.Add campaign's influencers to the proposal") }}
        />
      ) : null}
    </div>,
    <TableCell.Text value={t(`Option.${category}`)} />,
    <TableCell.Text
      value={countries.length > 0 ? countries.map((country) => tc(country)).join(', ') : '-'}
      tooltip={{
        help: countries?.length ? <ul>{countries?.map((country) => <li key={country}>{tc(country)}</li>)}</ul> : null
      }}
    />,
    <TableCell.Number css={{ minWidth: 'unset' }} value={formatPercent(engagementRate, false)} unit="%" />,
    <TableCell.Number css={{ minWidth: 'unset' }} value={formatIntNumber(numberOfInfluencers)} />
  ]);

  return { rows, columns };
};
