import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SwitchRoleToAdvertiser.graphql';
import { Mutation, MutationswitchRoleToAdvertiserArgs } from '../../__generated__/globalTypes';

export const useSwitchRoleToAdvertiserMutation = (
  options?: MutationHookOptions<Mutation, MutationswitchRoleToAdvertiserArgs>
) => {
  const [callSwitchRoleToAdvertiser, result] = useMutation<Mutation, MutationswitchRoleToAdvertiserArgs>(
    MUTATION,
    options
  );

  return { callSwitchRoleToAdvertiser, ...result };
};

export type SwitchRoleToAdvertiserMutationFunction = Mutation['switchRoleToAdvertiser'];
