import {
  BulkPackageSocialAccount,
  PackageProposalSocialAcccountBudgetInput,
  namedOperations,
  useBulkUpdatePackageProposalAccountsBudgetMutation,
  useBulkUpdatePackageProposalInfluencersBudgetMutation
} from '@/graphql';
import { DefaultBudgetInputType } from '@/shared/organisms';

interface PackageBulkEditProps {
  packageId: number;
  selectedInfluencerAccountIds: number[];
  selectedSocialAccounts: PackageProposalSocialAcccountBudgetInput[];
}
export const usePackageBulkEdit = ({
  selectedSocialAccounts,
  selectedInfluencerAccountIds,
  packageId
}: PackageBulkEditProps) => {
  const { callBulkUpdatePackageProposalInfluencersBudget, loading: updatingInfluencersBudget } =
    useBulkUpdatePackageProposalInfluencersBudgetMutation({ refetchQueries: [namedOperations.Query.PackageProposal] });
  const { callBulkUpdatePackageProposalAccountsBudget, loading: updatingAccountsBudget } =
    useBulkUpdatePackageProposalAccountsBudgetMutation({ refetchQueries: [namedOperations.Query.PackageProposal] });

  const handleInfluencersBudgetSubmit = async (defaultInput: DefaultBudgetInputType) => {
    await callBulkUpdatePackageProposalInfluencersBudget({
      variables: {
        input: { ...defaultInput, packageId, influencerIds: selectedInfluencerAccountIds }
      }
    });
  };
  const handleSocialAccountBudgetSubmit = async (defaultInput: DefaultBudgetInputType) => {
    const socialAccounts: BulkPackageSocialAccount[] = selectedSocialAccounts.map(
      ({ influencerId, socialAccountId, socialAccountType }) => ({
        influencerId,
        socialAccountId,
        socialAccountType
      })
    );

    await callBulkUpdatePackageProposalAccountsBudget({
      variables: {
        input: { ...defaultInput, socialAccounts, packageId }
      }
    });
  };

  return {
    handleInfluencersBudgetSubmit,
    handleSocialAccountBudgetSubmit,
    bulkLoading: updatingAccountsBudget || updatingInfluencersBudget
  };
};
