import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useBillingInformationQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { getExpiresCardDate } from '../../Billing/utils';

export const CardInfo = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { data: billingData } = useBillingInformationQuery();

  const billingInformation = billingData?.billingInformation;
  const hasBilling = billingData ? Object.values(billingData).some(Boolean) : false;

  return (
    <div className={className}>
      {billingInformation && hasBilling ? (
        <div css={styles.billingGrid}>
          <span css={{ color: THEME.text.colors.gray.lv3 }}>{t('Card Information')}</span>
          <div css={{ textAlign: 'right' }}>
            <span css={{ textTransform: 'uppercase' }}>{billingInformation.cardBrand}</span>
            <span> {t('ending in')} </span>
            <span>{billingInformation.cardLast4}</span>
          </div>
          <div css={{ color: THEME.text.colors.gray.lv3 }}>{t('Expires')}</div>
          {billingInformation.cardExpMonth && billingInformation.cardExpYear ? (
            <span css={{ textAlign: 'right' }}>
              {t('Expires')} {getExpiresCardDate(billingInformation)}
            </span>
          ) : (
            '-'
          )}
        </div>
      ) : (
        '-'
      )}
    </div>
  );
};

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    width: '280px',
    height: 'max-content',
    backgroundColor: THEME.background.colors.white,
    boxShadow: THEME.shadows.boxShadow
  }),
  billingGrid: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '8px',
    padding: '12px 8px',
    fontSize: '14px',
    borderRadius: '5px',
    background: THEME.background.colors.gray.lv1,
    '& > span, div': {
      lineHeight: '100%'
    }
  })
};
