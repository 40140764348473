import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteInstagramUGCManagementHashtag.graphql';
import { Mutation, MutationdeleteInstagramUGCManagementHashtagArgs } from '../../__generated__/globalTypes';

export const useDeleteInstagramUGCManagementHashtagMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteInstagramUGCManagementHashtagArgs>
) => {
  const [callDeleteInstagramUGCManagementHashtag, result] = useMutation<
    Mutation,
    MutationdeleteInstagramUGCManagementHashtagArgs
  >(MUTATION, options);

  return { callDeleteInstagramUGCManagementHashtag, ...result };
};

export type DeleteInstagramUGCManagementHashtagMutationFunction = Mutation['deleteInstagramUGCManagementHashtag'];
