import { z } from 'zod';
import { BaseValidations } from '@/shared/validations';
import { AllTranslationKeys } from '@/shared/assets';

export const CreateAdAccountSchema = z.object({
  industryId: z.string().min(1, AllTranslationKeys.Error.requiredIndustry),
  businessCenterId: z.string().min(1, AllTranslationKeys.Error.requiredBusinessCenter),
  adAccountCompanyName: BaseValidations.NameSchema.max(255),
  adAccountName: BaseValidations.NameSchema.max(100),
  promotionLink: z
    .string()
    .min(1, AllTranslationKeys.Error.requiredPromotionLink)
    .url(AllTranslationKeys.Error.invalidUrlMessage),
  timezone: BaseValidations.TimezoneSchema,
  locationCode: z.string().min(1, AllTranslationKeys.Error.requiredLocation)
});
