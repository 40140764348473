import { z } from 'zod';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { SearchFilterDefaults } from '@/shared/validations';
import { InstagramFeedPostSortField } from '@/graphql';

export const AnalyticsIgMentionedPostsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(ProfilePostsFilterSchema, { startDate: getThirtyDaysAgo(), endDate: getToday() }),
  sort: SearchFilterDefaults.sort(InstagramFeedPostSortField).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsIgMentionedPostsSearchSchemaType = z.infer<typeof AnalyticsIgMentionedPostsSearchSchema>;
