import { getFileTypeFromUrl } from './routes';

export const isVideoThumbnail = (url?: string | null, fileType?: string) => {
  if (!url) {
    return false;
  }

  const urlFileType = getFileTypeFromUrl(url);

  return ['avi', 'mp4', 'mov'].includes(fileType || urlFileType);
};
