import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { ListEmpty } from '@/shared/atoms';
import { SocialAccountStatus } from '@/graphql';
import { InfluencerDisconnectedNotice } from '../InfluencerDisconnectedNotice';
import { useInfluencerProfileContext } from '../InfluencerProfileContext';
import { InfluencerProfileInternalMemo } from '../InfluencerProfileInternalMemo';
import { InfluencerProfileIntro } from '../InfluencerProfileIntro';
import { InfluencerProfileStatsOverview } from '../InfluencerProfileStatsOverview';
import { InfluencerSignedUpProfile } from '../InfluencerSignedUpProfile';
import { checkSocialAccountType } from '../utils';
import { useInitialInfluencerAccountProfile } from './hooks';
import { InfluencerAnalyticsProfile } from './InfluencerAnalyticsProfile';
import { InfluencerDefaultProfile } from './InfluencerDefaultProfile';
import { AnyCreatorAccessPreviewButton } from './AnyCreatorAccessPreviewButton';

export const InfluencerProfileTemplates = ({ isEngagementProposal }: { isEngagementProposal?: boolean }) => {
  const { t } = useTranslation();
  const { isAdminStaff, isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { influencerProfile } = useInfluencerProfileContext();
  const { allAvailableAccounts, selectedSocialAccount } = useInitialInfluencerAccountProfile();

  const isSocialAccount = checkSocialAccountType(selectedSocialAccount?.type);
  const hasAnyCreatorPreview = isAdminStaff && influencerProfile.status === SocialAccountStatus.SIGNED_UP;

  return (
    <div css={{ display: 'flex' }}>
      <InfluencerProfileIntro influencerProfile={influencerProfile} isEngagementProposal={isEngagementProposal} />

      <div css={{ flex: 1, marginLeft: '24px', overflow: 'hidden' }}>
        {allAvailableAccounts?.length && selectedSocialAccount ? (
          <>
            <InfluencerDisconnectedNotice influencerId={influencerProfile.id} socialAccount={selectedSocialAccount} />
            <InfluencerProfileStatsOverview
              influencerId={influencerProfile.id}
              socialAccount={selectedSocialAccount}
              allAvailableAccounts={allAvailableAccounts}
            />
            {isAdminStaffTalentAgencyPartner ? (
              <InfluencerProfileInternalMemo influencerProfile={influencerProfile} />
            ) : null}
            {!isSocialAccount ? (
              <InfluencerSignedUpProfile
                influencerId={influencerProfile.id}
                socialAccountId={selectedSocialAccount.id}
              />
            ) : selectedSocialAccount.analyticsEnabled ? (
              <InfluencerAnalyticsProfile socialAccount={selectedSocialAccount} />
            ) : (
              <InfluencerDefaultProfile socialAccount={selectedSocialAccount} />
            )}

            {hasAnyCreatorPreview ? <AnyCreatorAccessPreviewButton userId={influencerProfile.id} /> : null}
          </>
        ) : (
          <ListEmpty
            title={t('No data available')}
            css={{ height: '100%', background: 'transparent' }}
            description={t('The creator has not connected any social media accounts or added any promotion methods')}
          />
        )}
      </div>
    </div>
  );
};
