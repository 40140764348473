import { FilterProvider } from '@/shared/molecules';
import { useInfluencerPackagesContext } from '../InfluencerPackagesContext';
import { BasicFilter } from './BasicFilter';

export const InfluencerPackagesFilter = () => {
  const { filter, setFilter, isEngagementPage } = useInfluencerPackagesContext();

  return (
    <FilterProvider
      onSubmit={setFilter}
      initialValues={filter}
      basicFilter={<BasicFilter isEngagementPage={isEngagementPage} />}
    />
  );
};
