import { Outlet, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { NetworkStatus } from '@apollo/client';
import { getLastDay, getThirtyDaysAgo } from '@/shared/utils';
import { PostReportMode } from '@/shared/types';
import {
  AffiliateReportShowBy,
  MarketplaceCampaignDetailType,
  MKP_QUERY,
  Query,
  QuerymarketplaceArgs
} from '@/graphql';
import { PostReportSearchSchema } from '@/shared/molecules';
import {
  AddMarketplace,
  EditMarketplaceCampaign,
  MarketplaceAffiliateReport,
  MarketplaceApplicants,
  MarketplaceAutoManagedCampaigns,
  MarketplaceCampaignForm,
  MarketplaceCommissions,
  MarketplaceDetails,
  MarketplaceEditPost,
  MarketplaceEditPostSearchSchema,
  MarketplaceInfluencers,
  MarketplaceJoinedInfluencers,
  MarketplacePosts,
  MarketplaceReport,
  Marketplaces,
  MarketplaceSelectCampaignType,
  MarketplacesReorder,
  MarketplacesReorderSearchSchema,
  MarketplaceTrackingReferral,
  MarketplaceTrackingUrl
} from '@/pages/Marketplaces';
import {
  MarketplaceAffiliateReportSearchSchema,
  MarketplaceApplicantsSearchSchema,
  MarketplaceAutoManagedCampaignSearchSchema,
  MarketplaceCampaignsSearchSchema,
  MarketplaceCommissionsSearchSchema,
  MarketplaceInfluencersSearchSchema,
  MarketplaceJoinedInfluencersSearchSchema,
  MarketplacePostsSearchSchema
} from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const MarketplaceRouter = {
  '/_private-routes/marketplace/': [Marketplaces, { validateSearch: zodValidator(MarketplaceCampaignsSearchSchema) }],
  '/_private-routes/marketplace/add': [AddMarketplace, {}],
  '/_private-routes/marketplace/add/': [MarketplaceSelectCampaignType, {}],
  '/_private-routes/marketplace/add/referral-code-tracking': [
    () => <MarketplaceCampaignForm type={MarketplaceCampaignDetailType.REFERRAL_CODE} />,
    {}
  ],
  '/_private-routes/marketplace/add/social-media-tracking': [
    () => <MarketplaceCampaignForm type={MarketplaceCampaignDetailType.NORMAL} />,
    {}
  ],
  '/_private-routes/marketplace/add/url-tracking': [
    () => <MarketplaceCampaignForm type={MarketplaceCampaignDetailType.URL_TRACKING} />,
    {}
  ],
  '/_private-routes/marketplace/add/e-commerce/': [
    MarketplaceAutoManagedCampaigns,
    { validateSearch: zodValidator(MarketplaceAutoManagedCampaignSearchSchema) }
  ],
  '/_private-routes/marketplace/add/e-commerce/$autoMangedCampaignId': [
    () => <MarketplaceCampaignForm type={MarketplaceCampaignDetailType.E_COMMERCE} />,
    {}
  ],
  '/_private-routes/marketplace/reorder': [
    MarketplacesReorder,
    { validateSearch: zodValidator(MarketplacesReorderSearchSchema) }
  ],
  '/_private-routes/marketplace/$id': [
    MarketplaceDetails,
    {
      loader: async ({ context, params }) => {
        if (!context.queryClient) {
          return {
            loading: true,
            networkStatus: NetworkStatus.loading,
            errorMessage: null,
            marketplace: null
          };
        }
        const { data, loading, errors, networkStatus } = await context.queryClient.query<Query, QuerymarketplaceArgs>({
          query: MKP_QUERY,
          variables: {
            pk: +params.id
          },
          notifyOnNetworkStatusChange: true
        });

        return {
          loading,
          networkStatus,
          errorMessage: errors?.at(0)?.message,
          marketplace: data?.marketplace || null
        };
      }
    }
  ],
  '/_private-routes/marketplace/$id/': [EditMarketplaceCampaign, {}],
  '/_private-routes/marketplace/$id/applicant': [
    MarketplaceApplicants,
    { validateSearch: zodValidator(MarketplaceApplicantsSearchSchema) }
  ],
  '/_private-routes/marketplace/$id/commissions': [
    MarketplaceCommissions,
    { validateSearch: zodValidator(MarketplaceCommissionsSearchSchema) }
  ],
  '/_private-routes/marketplace/$id/influencer': [
    MarketplaceInfluencers,
    { validateSearch: zodValidator(MarketplaceInfluencersSearchSchema) }
  ],
  '/_private-routes/marketplace/$id/joined-influencers': [
    MarketplaceJoinedInfluencers,
    { validateSearch: zodValidator(MarketplaceJoinedInfluencersSearchSchema) }
  ],
  '/_private-routes/marketplace/$id/posts': [MarketplacePosts, {}],
  '/_private-routes/marketplace/$id/posts/': [
    () => <Outlet />,
    { validateSearch: zodValidator(MarketplacePostsSearchSchema) }
  ],
  '/_private-routes/marketplace/$id/posts/$postId': [
    MarketplaceEditPost,
    { validateSearch: zodValidator(MarketplaceEditPostSearchSchema) }
  ],
  '/_private-routes/marketplace/$id/report-affiliate': [
    MarketplaceAffiliateReport,
    {
      validateSearch: zodValidator(MarketplaceAffiliateReportSearchSchema),
      loaderDeps: ({ search }) => search,
      loader: async ({ context, params, deps }) => {
        if (!context.queryClient) {
          return {
            loading: true,
            networkStatus: NetworkStatus.loading,
            errorMessage: null,
            marketplace: null
          };
        }
        const mkpCashedData = context.queryClient.readQuery<Query>({
          query: MKP_QUERY,
          variables: {
            pk: Number(params.id)
          }
        });

        if (deps.filter.startDate === undefined && deps.filter.endDate === undefined) {
          throw redirect({
            replace: true,
            to: '/marketplace/$id/report-affiliate',
            params: {
              id: params.id
            },
            search: {
              filter: {
                mode: AffiliateReportShowBy.DATE,
                startDate: mkpCashedData?.marketplace?.startDate || getThirtyDaysAgo(),
                endDate: mkpCashedData?.marketplace?.endDate || getLastDay()
              }
            }
          });
        }
      }
    }
  ],
  '/_private-routes/marketplace/$id/report': [
    MarketplaceReport,
    {
      validateSearch: zodValidator(PostReportSearchSchema),
      loaderDeps: ({ search }) => search,
      loader: async ({ context, params, deps }) => {
        if (!context.queryClient) {
          return {
            loading: true,
            networkStatus: NetworkStatus.loading,
            errorMessage: null,
            marketplace: null
          };
        }
        const mkpCashedData = context.queryClient.readQuery<Query>({
          query: MKP_QUERY,
          variables: {
            pk: Number(params.id)
          }
        });

        if (
          deps.filter.mode === PostReportMode.TABLE &&
          deps.filter.startDate === undefined &&
          deps.filter.endDate === undefined
        ) {
          throw redirect({
            replace: true,
            to: '/marketplace/$id/report',
            params: {
              id: params.id
            },
            search: {
              filter: {
                ...deps.filter,
                startDate: mkpCashedData?.marketplace?.startDate || getThirtyDaysAgo(),
                endDate: mkpCashedData?.marketplace?.endDate || getLastDay()
              }
            }
          });
        }
      }
    }
  ],
  '/_private-routes/marketplace/$id/tracking-referral-codes': [MarketplaceTrackingReferral, {}],
  '/_private-routes/marketplace/$id/tracking': [MarketplaceTrackingUrl, {}]
} as const satisfies PartialRouter;
