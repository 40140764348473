import { useAutoManagedCampaignsFilter } from '@/shared/organisms';
import { MAX_COMMISSION_RATE, MAX_PRICE, MIN_PRICE } from '@/shared/constants';
import { getOffset, getValuableVariables, rateToApiFormat } from '@/shared/utils';
import { useGetAutoManagedCampaignsQuery, useGetAutoManagedCampaignsCountQuery } from '@/graphql';

export const useAutoManagedCampaignsData = () => {
  const { sort, page, limit, filter } = useAutoManagedCampaignsFilter();
  const { categoryIds, minPrice, maxPrice, minCommissionRate, maxCommissionRate } = filter;
  const variables = getValuableVariables({
    ...filter,
    status: filter.status || undefined,
    marketplaceType: filter.marketplaceType || null,
    minPrice: minPrice && Number(minPrice) !== MIN_PRICE ? minPrice : null,
    maxPrice: maxPrice && Number(maxPrice) !== MAX_PRICE ? maxPrice : null,
    minCommissionRate: rateToApiFormat(minCommissionRate), // BE logic require both min|max values to be provided\
    maxCommissionRate:
      maxCommissionRate && Number(maxCommissionRate) !== MAX_COMMISSION_RATE ? rateToApiFormat(maxCommissionRate) : null
  });

  const { data, loading, previousData } = useGetAutoManagedCampaignsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ...variables,
        limit,
        categoryIds, // require field. Don't put inside getValuableVariables
        orderBy: sort,
        offset: getOffset(page, limit)
      }
    }
  });
  const {
    data: countData,
    loading: fetchingCount,
    previousData: previousCountData
  } = useGetAutoManagedCampaignsCountQuery({
    fetchPolicy: 'cache-and-network',
    variables: { input: { ...variables, categoryIds } }
  });

  const result = loading ? previousData : data;
  const countResult = fetchingCount ? previousCountData : countData;

  return {
    loading: loading || fetchingCount,
    campaigns: result?.getAutoManagedCampaigns?.campaigns,
    totalRecords: countResult?.getAutoManagedCampaignsCount?.count
  };
};
