import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/MarketplaceCampaignReportSummary.graphql';
import { Query, QuerymarketplaceCampaignReportSummaryArgs } from '../../__generated__/globalTypes';

export const useMarketplaceCampaignReportSummaryQuery = (
  options?: QueryHookOptions<Query, QuerymarketplaceCampaignReportSummaryArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerymarketplaceCampaignReportSummaryArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useMarketplaceCampaignReportSummaryLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerymarketplaceCampaignReportSummaryArgs>
): QueryResult<Query, QuerymarketplaceCampaignReportSummaryArgs> & {
  getMarketplaceCampaignReportSummary: LazyQueryExecFunction<Query, QuerymarketplaceCampaignReportSummaryArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getMarketplaceCampaignReportSummary, result] = useLazyQuery<Query, QuerymarketplaceCampaignReportSummaryArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getMarketplaceCampaignReportSummary, ...result };
};

export const useMarketplaceCampaignReportSummaryPromiseQuery = () => {
  const client = useApolloClient();

  const getMarketplaceCampaignReportSummary = useCallback(
    (options?: Omit<QueryOptions<QuerymarketplaceCampaignReportSummaryArgs, Query>, 'query'>) =>
      client.query<Query, QuerymarketplaceCampaignReportSummaryArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getMarketplaceCampaignReportSummary };
};
