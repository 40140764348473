import { css } from '@emotion/react';
import { Button, Icon } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { THEME } from '@/shared/constants';
import { useChatDemoContext } from '../ChatDemoProvider';
import { ChatDemoHistory } from './ChatDemoHistory';

export const ChatDemoHistorySidebar = () => {
  const { t, navigate } = useQueryHelper();
  const { toggleHistoryList, refreshChatDemo } = useChatDemoContext();

  return (
    <div css={styles.wrapper}>
      <div css={styles.titleWrapper}>
        <p css={{ fontSize: '14px', fontWeight: '400' }}>{t('Chat history')}</p>
        <Icon
          size={18}
          icon="chat-demo-back"
          css={{ cursor: 'pointer' }}
          onClick={toggleHistoryList}
          color={THEME.icon.colors.black}
        />
      </div>

      <div css={{ flex: '1 1 auto', overflow: 'auto', padding: '16px', boxSizing: 'border-box' }}>
        <Button
          css={{ width: '100%' }}
          variant="white"
          title="+ 新規チャット"
          onClick={() => {
            navigate({ to: '/chat-demo/chat', search: { state: { unsaveLastLocation: true } } });
            refreshChatDemo();
          }}
        />
        <ChatDemoHistory
          css={{
            marginTop: '16px',
            // Active newest history
            '& > div:first-of-type > div:last-of-type': {
              borderRadius: '4px',
              backgroundColor: THEME.background.colors.gray.lv1
            }
          }}
        />
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    top: 0,
    left: 0,
    zIndex: 300,
    display: 'flex',
    height: '100vh',
    position: 'fixed',
    overflow: 'hidden',
    flexDirection: 'column',
    width: THEME.container.menu.width,
    backgroundColor: THEME.background.colors.white
  }),
  titleWrapper: css({
    flex: 'none',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    borderBottom: THEME.border.base
  })
};
