import { useInstagramHashtagPostDetailsQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InstagramHashtagPostDetailsProps extends Pick<PostDetailsType, 'id' | 'mainSocialAccountId'> {
  className?: string;
  onCloseModal?: () => void;
}

export const InstagramHashtagPostDetails = ({ id, className, onCloseModal }: InstagramHashtagPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useInstagramHashtagPostDetailsQuery({
    skip: !id,
    variables: { postId: Number(id) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const details = data?.instagramHashtagPostDetails;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'INSTAGRAM',
        postUrl: details?.postUrl,
        caption: details?.content,
        images: details?.images || [],
        comments: details.commentList || [],
        statistics: { likes: details?.analysis?.likes, comments: details?.analysis?.comments },
        info: {
          socialMedia: 'INSTAGRAM',
          avatar: details?.avatarUrl,
          postDate: details?.postDate,
          name: details?.instagramAccountName
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      loading={loading}
      className={className}
      postDetails={postDetails}
      css={{ width: THEME.modal.size.lv3 }}
    />
  );
};
