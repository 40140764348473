import { useCallback, useRef, useState } from 'react';

type CustomRefCallback = (node: HTMLElement | null) => void;

export const useMeasure = () => {
  const [dimensions, setDimensions] = useState<{ width: number | null; height: number | null }>({
    width: null,
    height: null
  });

  const previousObserver = useRef<ResizeObserver | null>(null);
  const targetRef = useCallback<CustomRefCallback>((node) => {
    if (previousObserver.current) {
      previousObserver.current.disconnect();
      previousObserver.current = null;
    }

    if (node?.nodeType === Node.ELEMENT_NODE) {
      const observer = new ResizeObserver(([entry]) => {
        if (entry && entry.borderBoxSize) {
          const { inlineSize: width, blockSize: height } = entry.borderBoxSize.at(0) || {
            inlineSize: null,
            blockSize: null
          };

          setDimensions({ width, height });
        }
      });

      observer.observe(node);
      previousObserver.current = observer;
    }
  }, []);

  return [targetRef, dimensions] as const;
};
