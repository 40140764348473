import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { buildingImage } from '@/shared/assets';
import {
  Button,
  NoDataContentWrapper,
  RenderDataWithFallback,
  NoDataContentWrapperProps
} from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { SectionWithHeader, SectionWithHeaderProps } from '@/shared/molecules';
import { Option } from '@/shared/types';

const DEFAULT_DISPLAY_ITEMS = 8;

export interface InfluencerProfileMentionedAccountsWrapperProps<T = unknown>
  extends Omit<SectionWithHeaderProps, 'children' | 'headerChildren'>,
    Pick<NoDataContentWrapperProps, 'dataNotAvailable' | 'wrapperText' | 'wrapperLink'> {
  data: readonly T[];
  loading?: boolean;
  className?: string;
  headerMetrics?: Option[];
  renderItem: (props: { account: T; styles: typeof styles }) => ReactNode;
}

export const InfluencerProfileMentionedAccountsWrapper = <T,>({
  data,
  loading,
  className,
  renderItem,
  wrapperLink,
  wrapperText,
  moreDetailProps,
  dataNotAvailable,
  headerMetrics = [],
  ...restProps
}: InfluencerProfileMentionedAccountsWrapperProps<T>) => {
  const { t } = useTranslation();
  const toggleButton = useToggleState();

  const accounts = toggleButton.status ? data : data.slice(0, DEFAULT_DISPLAY_ITEMS);

  return (
    <div css={{ marginTop: '16px' }} className={className}>
      <RenderDataWithFallback loading={loading} loadingProps={{ css: { height: '400px' } }}>
        <SectionWithHeader
          prefixIcon={buildingImage}
          title={t('Mentioned Accounts')}
          help={t('Tooltip.CollaborativeBrands')}
          css={{ '.section-header': { borderBottom: 'none' } }}
          headerChildren={
            accounts.length && headerMetrics.length
              ? headerMetrics.map(({ label, value }, index) => (
                  <p key={index} css={styles.headerMetrics}>
                    <span>{label}</span>
                    <span css={styles.headerMetricsValue}>{value}</span>
                  </p>
                ))
              : null
          }
          {...restProps}
        >
          <NoDataContentWrapper
            wrapperLink={wrapperLink}
            dataNotAvailable={dataNotAvailable ?? !accounts.length}
            css={{ minHeight: !accounts.length ? '200px' : 'unset' }}
            wrapperText={wrapperText || t('No brand mentioned posts to show')}
          >
            <div css={{ padding: '0 16px 16px', boxSizing: 'border-box' }}>
              <div css={{ display: 'flex', flexWrap: 'wrap', margin: '-4px', flex: 1 }}>
                {accounts.map((account, index) => (
                  <div key={index} css={{ flexBasis: '25%', boxSizing: 'border-box', padding: '4px' }}>
                    {renderItem({ account, styles })}
                  </div>
                ))}
              </div>
              {data.length > 8 ? (
                <Button
                  variant="white"
                  onClick={toggleButton.toggleStatus}
                  css={{ minWidth: '216px', margin: '8px auto 0' }}
                  title={t(toggleButton.status ? 'Button.Less' : 'Button.More')}
                />
              ) : null}
            </div>
          </NoDataContentWrapper>
        </SectionWithHeader>
      </RenderDataWithFallback>
    </div>
  );
};

const styles = {
  headerMetrics: css({
    fontSize: '13px',
    marginLeft: '16px',
    whiteSpace: 'nowrap',
    color: THEME.text.colors.gray.lv3,
    '&:first-of-type': { marginLeft: 'auto' }
  }),
  headerMetricsValue: css({
    fontWeight: 600,
    fontSize: '16px',
    marginLeft: '8px',
    display: 'inline-block'
  }),
  brandAccountItem: css({
    padding: '16px',
    display: 'flex',
    flexBasis: '25%',
    borderRadius: '5px',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  }),
  brandAccountAvatar: css({
    borderRadius: '50%',
    marginBottom: '8px'
  }),
  brandAccountName: css({
    width: '100%',
    fontSize: '15px',
    lineHeight: '20px',
    textAlign: 'center'
  }),
  brandAccountMention: css({
    fontSize: '13px',
    marginTop: '10px',
    cursor: 'pointer',
    lineHeight: '100%',
    color: THEME.attention.colors.blue,
    '&:hover': { opacity: 0.8 }
  }),
  brandAccountRate: css({
    fontSize: '13px',
    marginTop: '8px',
    lineHeight: 'calc(1em + 1ex)',
    color: THEME.text.colors.gray.lv3
  })
};
