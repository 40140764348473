import { BlackBar, DownloadButton } from '@/shared/atoms';
import { DropdownOption } from '@/shared/types';
import { ReportGroupBy } from './hooks';

interface ActionBarProps {
  loading: boolean;
  allItemIds: string[];
  selectedIds: string[];
  hasAllItemsSelected: boolean;
  toggleSelectAllItems: () => void;
  handleDownloadReport: (o: ReportGroupBy) => void;
  downloadOptions: DropdownOption[];
}
export const ActionBar = ({
  loading,
  allItemIds,
  selectedIds,
  downloadOptions,
  hasAllItemsSelected,
  toggleSelectAllItems,
  handleDownloadReport
}: ActionBarProps) =>
  selectedIds.length ? (
    <BlackBar
      disabled={loading}
      selectAllProps={{ onClick: toggleSelectAllItems, label: false }}
      checkboxProps={
        allItemIds.length
          ? {
              onClick: toggleSelectAllItems,
              indeterminate: !hasAllItemsSelected,
              label: false
            }
          : undefined
      }
      css={{ '.select-all-btn': { marginLeft: 0 } }}
    >
      <DownloadButton
        variant="white"
        options={downloadOptions}
        onSelectOption={(o) => handleDownloadReport(o.value as ReportGroupBy)}
        css={{ minWidth: 'max-content', paddingRight: '16px', marginLeft: '16px' }}
        loading={loading}
      />
    </BlackBar>
  ) : null;
