import { zodValidator } from '@tanstack/zod-adapter';
import { UserLinkedDetails, Users, UserDetails, AddUser, UserLinkedSearchSchema } from '@/pages/Users';
import { adminStaffAgencyTalentAgencyPartnerRoles } from '@/shared/constants';
import { PermissionRoute } from '@/app-components';
import { UsersSearchSchema } from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const UserRouter = {
  '/_private-routes/user': [() => <PermissionRoute permissions={adminStaffAgencyTalentAgencyPartnerRoles} />, {}],
  '/_private-routes/user/': [Users, { validateSearch: zodValidator(UsersSearchSchema) }],
  '/_private-routes/user/$id': [UserDetails, {}],
  '/_private-routes/user/add': [AddUser, {}],
  '/_private-routes/user/linked': [UserLinkedDetails, { validateSearch: zodValidator(UserLinkedSearchSchema) }]
} as const satisfies PartialRouter;
