import { CSSObject, SerializedStyles } from '@emotion/react';
import { useToggleState } from '@/shared/hooks';
import { DropdownOption } from '@/shared/types';
import { DropdownMenu, DropdownMenuProps } from '../DropdownMenu';
import { Popover, PopoverProps } from '../Popover';
import { Button, ButtonProps } from './Button';

export interface ButtonDropdownProps<T extends DropdownOption> extends ButtonProps, DropdownMenuProps<T> {
  placement?: PopoverProps['position'];
  dropdownClassName?: CSSObject | SerializedStyles;
}

export const ButtonDropdown = <T extends DropdownOption>({
  options,
  onSelectOption,
  dropdownClassName,
  placement = 'bottom-end',
  ...restProps
}: ButtonDropdownProps<T>) => {
  const { status: isOpening, toggleStatus, close } = useToggleState(false);

  const handleSelectOption = (option: T) => {
    onSelectOption?.(option);
    close();
  };

  return (
    <Popover
      open={isOpening}
      onClose={close}
      position={placement}
      target={<Button css={[{ minWidth: 'inherit' }]} onClick={toggleStatus} {...restProps} />}
      withBorder
    >
      <DropdownMenu options={options} css={dropdownClassName} onSelectOption={handleSelectOption} />
    </Popover>
  );
};
