import { TableData } from '@/shared/types';
import { Table, Modal, Button, BlackBar, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { useToggleState, useQueryHelper, useSelectItem, useSelectItemIds, useDeepCompareEffect } from '@/shared/hooks';
import {
  MarketplaceAffiliatePayoutPeriodInput,
  MarketplaceAffiliateCommissionInfluencer,
  useApproveMarketplaceAffiliatePayoutMutation,
  namedOperations
} from '@/graphql';
import { useMarketplaceCommissionsFilter } from '../MarketplaceCommissionsFilter';
import { useMarketplaceCommissionsInfluencersTable } from './hooks';

export interface MarketplaceCommissionsListProps extends TableData<MarketplaceAffiliateCommissionInfluencer> {
  marketplaceId: number;
}
export const MarketplaceCommissionsList = ({ loading, data = [], marketplaceId }: MarketplaceCommissionsListProps) => {
  const confirmModal = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { filter } = useMarketplaceCommissionsFilter();
  const { selectedItem, resetSelectedItem, setSelectedItem } = useSelectItem();
  const { selectItem, selectedItemIds, hasAllItemsSelected, setSelectedItemIds, toggleSelectAllItems } =
    useSelectItemIds({ allItemIds: data.map(({ influencer }) => influencer.id) });
  const { rows, columns } = useMarketplaceCommissionsInfluencersTable({
    data,
    selectItem,
    selectedItemIds,
    hasAllItemsSelected,
    toggleSelectAllItems,
    onApprove: (influencer) => {
      setSelectedItem(influencer);
      confirmModal.open();
    }
  });
  const { callApproveMarketplaceAffiliatePayout, loading: approving } = useApproveMarketplaceAffiliatePayoutMutation({
    refetchQueries: [namedOperations.Query.MarketplaceAffiliateCommissionInfluencers]
  });

  useDeepCompareEffect(() => {
    resetSelectedItems();
  }, [data]);

  const resetSelectedItems = () => {
    resetSelectedItem();
    setSelectedItemIds([]);
  };

  const handleCloseModal = () => {
    resetSelectedItems();
    confirmModal.close();
  };

  const handleApprove = async () => {
    try {
      const allSelectedItemIds = selectedItem ? [selectedItem.id] : selectedItemIds;
      await callApproveMarketplaceAffiliatePayout({
        variables: {
          input: {
            marketplaceId,
            coveragePeriods: data.reduce<MarketplaceAffiliatePayoutPeriodInput[]>(
              (acc, curr) =>
                allSelectedItemIds.includes(curr.influencer.id)
                  ? [...acc, { fixedDate: curr.fixedDate, postId: curr.postId }]
                  : acc,
              []
            )
          }
        }
      });

      handleCloseModal();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <div css={{ position: 'relative' }}>
        {selectedItemIds.length > 0 ? (
          <BlackBar
            disabled={approving}
            selectAllProps={{ onClick: toggleSelectAllItems }}
            checkboxProps={{
              onClick: toggleSelectAllItems,
              indeterminate: !hasAllItemsSelected,
              label: t('Selected', { count: selectedItemIds.length })
            }}
          >
            <Button
              variant="green"
              title={t('Button.Approve Commission')}
              onClick={confirmModal.open}
              css={{ padding: '0 16px', marginLeft: '24px' }}
            />
          </BlackBar>
        ) : null}

        <Table
          loading={loading}
          data={{ columns, rows, rowHeight: '64px' }}
          empty={{ description: filter.keyword ? undefined : t('Nobody joined this campaign yet') }}
          noTopBorderRadius
          stickyHeader
        />
      </div>

      <Modal css={{ width: '488px' }} onClose={handleCloseModal} open={confirmModal.status}>
        <ModalContent>
          <ModalTitle>{t('Are you sure to approve the payout?')}</ModalTitle>

          <p css={{ fontSize: '14px', marginTop: '12px' }}>
            {t('Creators will be able to withdraw commission fee after you approved')}
            <br />
            {t('This operation cannot be canceled once you click “approve”')}
          </p>
        </ModalContent>

        <ModalFooterActions
          cancelButtonProps={{ onClick: handleCloseModal }}
          submitButtonProps={{ loading: approving, onClick: handleApprove, title: t('Button.Approve') }}
        />
      </Modal>
    </>
  );
};
