import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteUserProposalInfluencer.graphql';
import { Mutation, MutationdeleteUserProposalInfluencerArgs } from '../../__generated__/globalTypes';

export const useDeleteUserProposalInfluencerMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteUserProposalInfluencerArgs>
) => {
  const [callDeleteUserProposalInfluencer, result] = useMutation<Mutation, MutationdeleteUserProposalInfluencerArgs>(
    MUTATION,
    options
  );

  return { callDeleteUserProposalInfluencer, ...result };
};

export type DeleteUserProposalInfluencerMutationFunction = Mutation['deleteUserProposalInfluencer'];
