import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useInfluencerFacebookPageAnalyticsPostsQuery } from '@/graphql';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  InfluencerFacebookPostsList,
  InfluencerFacebookPostsListSearchType
} from '@/shared/organisms';
import { useInitialInfluencerPostsListWithName } from '../hooks';

export const InfluencerFacebookPosts = () => {
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName();
  const { page, limit, sort, filter, setFilter } = useParamsSearch<InfluencerFacebookPostsListSearchType>();
  const { data, previousData, loading } = useInfluencerFacebookPageAnalyticsPostsQuery({
    skip: !influencerId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      influencerId,
      socialAccountId: mainSocialAccountId,
      orderBy: sort,
      offset: getOffset(page, limit),
      endDate: null,
      startDate: null,
      keyword: filter.keyword
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerFacebookPageAnalyticsPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} />

      <ProfilePostsCount count={totalRecords} />

      <InfluencerFacebookPostsList
        loading={loading}
        influencerId={influencerId}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.influencerFacebookPageAnalyticsPosts?.posts || []}
      />
    </ListWithPagination>
  );
};
