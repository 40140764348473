// TODO: Refactor. This component replaced for Image and VideoPreview component and any place using img,video
import { css } from '@emotion/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { isVideoThumbnail } from '@/shared/utils';
import { useContainerQuery } from '@/shared/hooks';
import { IconNames, emptyImage, emptyVideoImage } from '@/shared/assets';
import { Icon } from '../Icon';

export interface MediaPreviewProps {
  alt?: string;
  counter?: { index: number; total: number };
  icon?: IconNames;
  isReel?: boolean;
  caption?: string | null;
  className?: string;
  showIcon?: boolean;
  playVideo?: boolean;
  src?: string | null;
  onClick?: () => void;
  defaultImage?: string;
  objectFit?: 'cover' | 'contain';
  captionProps?: {
    height?: number;
    margin?: number;
    padding?: number;
    lineHeight?: number;
  };
  postReportCaption?: ReactNode;
}

export const MediaPreview = ({
  alt,
  src,
  icon,
  isReel,
  caption,
  onClick,
  showIcon,
  className,
  playVideo,
  defaultImage,
  objectFit = 'contain',
  counter,
  captionProps,
  postReportCaption: PostReportCaption
}: MediaPreviewProps) => {
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();
  const isVideo = isVideoThumbnail(src);
  const { containerRef, height } = useContainerQuery();
  const customIcon = icon || ((isReel ? 'clapper' : isVideo ? 'play' : '') satisfies IconNames | '');
  const videoProps = playVideo ? { loop: true, muted: true, autoPlay: true, playsInline: true, controls: true } : {};
  const hasPreview = src && !error;

  return (
    <div
      onClick={onClick}
      css={[styles.wrapper, !onClick && { pointerEvents: 'none' }]}
      className={className}
      ref={containerRef}
    >
      {hasPreview ? (
        <>
          {showIcon && !!customIcon ? <Icon size="16px" color="#fff" icon={customIcon} css={styles.icon} /> : null}

          {isVideo ? (
            <video preload="metadata" css={styles.mediaSize} {...videoProps}>
              <source src={`${src}#t=1`} type="video/mp4" onError={() => setError(true)} />
            </video>
          ) : (
            <img
              alt={alt}
              src={src}
              loading="lazy"
              onError={() => setError(true)}
              css={[styles.mediaSize, { objectFit }]}
              className="media-preview-img"
            />
          )}
        </>
      ) : isReel ? (
        <div css={styles.igReelNoPreview}>
          <div css={styles.igReelNoPreviewText}>
            <Icon icon="eye-off" color="#fff" width={24} />
            <p className="no-preview-text">
              {t('Reels video is not available due to the media contains copyrighted material')}
            </p>
          </div>
        </div>
      ) : // don't have preview, either image placeholder or captionUI
      PostReportCaption ? (
        PostReportCaption
      ) : caption ? (
        <div css={styles.caption({ height, ...captionProps })}>
          <p title={caption} className="text-caption">
            {caption}
          </p>
        </div>
      ) : (
        <img
          alt={alt}
          src={defaultImage || (isVideo ? emptyVideoImage : emptyImage)}
          css={[styles.mediaSize, { objectFit: 'contain', backgroundColor: '#dee5ec' }]}
        />
      )}

      {counter ? (
        <div css={styles.counterWrapper}>
          <Icon icon="img" size={8} color={THEME.icon.colors.white} />
          <p css={{ fontSize: '8px', fontWeight: 600, lineHeight: '100%', color: THEME.text.colors.white }}>
            {counter.index}/{counter.total}
          </p>
        </div>
      ) : null}
    </div>
  );
};

const styles = {
  mediaSize: css({
    width: 'inherit',
    height: 'inherit',
    maxWidth: 'inherit',
    maxHeight: 'inherit'
  }),
  wrapper: css({
    flex: 'none',
    opacity: 1,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 'inherit',
    boxSizing: 'border-box',
    backgroundColor: THEME.colors.black[100],
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 0.9,
      cursor: 'pointer'
    }
  }),
  icon: css({ position: 'absolute', right: '4px', top: '4px', zIndex: 1 }),
  igReelNoPreview: css({
    containerType: 'inline-size',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#27313b',
    margin: 'auto'
  }),
  igReelNoPreviewText: css({
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
    alignItems: 'center',
    color: THEME.text.colors.white,
    fontWeight: 400,
    fontSize: '14px',
    textAlign: 'center',
    padding: '24px',
    // using css container query https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries to hide text on small preview size
    '@container (max-width: 200px)': {
      '.no-preview-text': {
        display: 'none'
      }
    }
  }),
  caption: ({ height = 0, lineHeight = 10, margin = 8, padding = 3 }: NonNullable<MediaPreviewProps['captionProps']>) =>
    css({
      position: 'relative',
      margin: `${margin}px`,
      height: `${height - (margin * 2 + 2 * padding)}px`,
      borderRadius: 5,
      backgroundColor: THEME.background.colors.white,
      padding: `${padding}px`,
      fontWeight: 400,
      fontSize: `${lineHeight - 3}px`,

      '.text-caption': {
        overflow: 'hidden',
        display: '-webkit-box',
        wordBreak: 'break-all',
        lineHeight: `${lineHeight}px`,
        ' -webkit-line-clamp': Math.floor((height - margin * 2) / lineHeight),
        '-webkit-box-orient': 'vertical'
      },
      ':after': {
        position: 'absolute',
        content: '""',
        /* triangle */
        borderColor: '#fff transparent transparent transparent',
        borderStyle: 'solid',
        borderWidth: '4px',
        bottom: '-8px',
        left: '6px'
        /* triangle */
      }
    }),
  counterWrapper: css({
    gap: '3px',
    right: '2px',
    bottom: '2px',
    height: '14px',
    display: 'flex',
    minWidth: '32px',
    padding: '3px 4px',
    alignItems: 'center',
    position: 'absolute',
    borderRadius: '13px',
    justifyContent: 'center',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  })
};
