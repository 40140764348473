import { SectionWithHeader } from '@/shared/molecules';
import { InstagramBrandAmbassadorPostsByUsername, useAllInstagramAmbassadorPostsQuery } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { CAN_NOT_GET_ANALYTICS_ACCOUNT, LIMIT_5 } from '@/shared/constants';
import { AnalyticsInstagramAmbassadorPostsList } from '../../../../AnalyticsPostsList';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { useAnalyticsProfileSearch } from '../../../hooks';

export interface AnalyticsInstagramInteractionBrandAmbassadorsProps {
  brandAccountName?: string;
  analyticsAccount: AnalyticsSelectedAccountInfo;
  hasFreePlan: boolean;
  mockBrandAmbassadorsPosts?: InstagramBrandAmbassadorPostsByUsername[];
}

export const AnalyticsInstagramInteractionBrandAmbassadors = ({
  brandAccountName,
  analyticsAccount,
  hasFreePlan,
  mockBrandAmbassadorsPosts
}: AnalyticsInstagramInteractionBrandAmbassadorsProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter, params } = useAnalyticsProfileSearch();

  const { data, loading } = useAllInstagramAmbassadorPostsQuery({
    fetchPolicy: 'network-only',
    skip: hasFreePlan,
    variables: getValuableVariables({
      offset: 0,
      limit: LIMIT_5, // Fixed
      endDate: filter.endDate,
      startDate: filter.startDate,
      accountId: analyticsAccount.id
    }),
    onError: () => {
      enqueueSnackbar(t(CAN_NOT_GET_ANALYTICS_ACCOUNT), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  const ambassadorPosts =
    (hasFreePlan ? mockBrandAmbassadorsPosts : data?.allInstagramAmbassadorPosts?.ambassadorPosts) || [];

  return (
    <SectionWithHeader
      css={{ marginTop: '24px' }}
      title={t('Brand Ambassadors')}
      prefixIcon={{ icon: 'medal-two-tones', size: '24px' }}
      moreDetailProps={
        !loading && ambassadorPosts.length
          ? {
              hasTopBorder: true,
              to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors',
              params,
              search: {
                filter: {
                  brandAccountName,
                  endDate: filter.endDate,
                  startDate: filter.startDate
                }
              }
            }
          : undefined
      }
    >
      <AnalyticsInstagramAmbassadorPostsList
        loading={loading}
        data={ambassadorPosts}
        css={{ boxShadow: 'none' }}
        analyticsAccountId={analyticsAccount.id}
      />
    </SectionWithHeader>
  );
};
