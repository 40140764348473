import { useTranslation } from 'react-i18next';
import { Notice, SocialButton } from '@/shared/atoms';
import { SocialReconnectModal } from '@/shared/molecules';
import { getSocialMediaText } from '@/shared/utils';
import { AnalyticsSelectedAccountInfo } from '../types';
import { useAnalyticsReconnectAccount } from '../../Analytics/AnalyticsList/hooks';

export interface AnalyticsNeedReconnectNoticeProps {
  className?: string;
  needReconnect?: boolean;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsNeedReconnectNotice = ({ className, analyticsAccount }: AnalyticsNeedReconnectNoticeProps) => {
  const { t } = useTranslation();
  const {
    reconnected,
    reconnecting,
    reconnectingAccount,
    setReconnectingAccount,
    handleReconnectAccount,
    resetReconnectingAccount
  } = useAnalyticsReconnectAccount();

  const { needReconnect, socialAccountType } = analyticsAccount;

  return needReconnect ? (
    <>
      <Notice
        type="warning"
        className={className}
        title={t('Please reconnect')}
        css={{ marginBottom: '24px' }}
        description={t('Your account is disconnected', { socialMedia: getSocialMediaText(socialAccountType) })}
      >
        <SocialButton
          title={t('Button.Reconnect')}
          socialMedia={socialAccountType}
          onClick={() => setReconnectingAccount(analyticsAccount)}
          css={{ marginLeft: 'auto', padding: '0 16px', textTransform: 'uppercase' }}
        />
      </Notice>

      <SocialReconnectModal
        reconnected={reconnected}
        reconnecting={reconnecting}
        open={!!reconnectingAccount}
        onClose={resetReconnectingAccount}
        onReconnect={handleReconnectAccount}
        socialMedia={reconnectingAccount?.socialAccountType}
        accounts={[
          ...(reconnectingAccount ? [reconnectingAccount] : []),
          ...(reconnectingAccount?.linkedAccount ? [reconnectingAccount?.linkedAccount] : [])
        ].map((acc) => ({ name: acc.socialAccountName, type: acc.socialAccountType }))}
      />
    </>
  ) : null;
};
