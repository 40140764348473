import { CSSObject, SerializedStyles, css } from '@emotion/react';
import { useState } from 'react';
import { PopoverStylesNames, PopoverStylesParams, Styles } from '@mantine/core';
import { useUpdateInfluencerTagsByStaffMutation } from '@/graphql';
import { Icon, Button, Popover, PopoverProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useDeepCompareEffect } from '@/shared/hooks';
import { MultiTagsSearchSelector } from '@/shared/molecules';
import { Option } from '@/shared/types';

export interface InfluencerTagsPopoverProps extends Omit<PopoverProps, 'width' | 'children' | 'open' | 'onClose'> {
  tags?: readonly string[];
  className?: string;
  influencerId: number;
  refetchQueries?: string[];
  onTagsChange?: (tags: string[]) => void;
  dropdownCss?: CSSObject | SerializedStyles;
}

const formatTagOptions = (tags: readonly string[]) => tags.map<Option<string>>((tag) => ({ label: tag, value: tag }));

export const InfluencerTagsPopover = ({
  target,
  tags = [],
  dropdownCss,
  onTagsChange,
  influencerId,
  refetchQueries,
  ...restProps
}: InfluencerTagsPopoverProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const defaultTags = formatTagOptions(tags);
  const [tagsOptions, setTagsOptions] = useState(defaultTags);
  const { callUpdateInfluencerTagsByStaff, loading } = useUpdateInfluencerTagsByStaffMutation({ refetchQueries });

  useDeepCompareEffect(() => {
    setTagsOptions(formatTagOptions(tags));
  }, [tags]);

  const handleSaveTags = (closePopover: () => void) => async () => {
    try {
      const tags = tagsOptions.map((tag) => tag.value) as string[];
      await callUpdateInfluencerTagsByStaff({ variables: { input: { tags, influencerId } } });

      onTagsChange?.(tags);
      closePopover();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <Popover
      width="max-content"
      position="bottom-start"
      onClose={() => setTagsOptions(defaultTags)}
      target={(targetProps) => (
        // We need empty div here
        <div>
          {(typeof target === 'function' ? target(targetProps) : target) || (
            <div onClick={() => targetProps.setOpen(true)} css={styles.addLabel}>
              + {t('Button.Add Tag')}
            </div>
          )}
        </div>
      )}
      {...restProps}
      styles={
        {
          dropdown: {
            borderRadius: 3,
            boxSizing: 'border-box',
            backgroundColor: 'white',
            boxShadow: '0 10px 38px -10px hsl(206 22% 7% / 35%), 0 10px 20px -15px hsl(206 22% 7% / 20%)',
            ...((restProps?.styles as Partial<Record<'dropdown', CSSObject>>)?.dropdown || {})
          },
          ...restProps?.styles
        } as Styles<PopoverStylesNames, PopoverStylesParams>
      }
    >
      {({ closePopover }) => (
        <div css={[{ width: '200px', padding: '20px 20px 16px', boxSizing: 'border-box' }, dropdownCss]}>
          <MultiTagsSearchSelector
            title="Tag List"
            value={tagsOptions}
            placeholder={t('input tag')}
            setFieldValue={setTagsOptions}
          />
          <div css={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginTop: '8px' }}>
            {tagsOptions.map((tag, index) => (
              <div key={index} css={[styles.tag, { paddingRight: '12px' }]}>
                <span css={styles.label} title={tag.label}>
                  {tag.label}
                </span>
                <Icon
                  size={8}
                  icon="close"
                  color="#6E7C89"
                  css={styles.closeIcon}
                  onClick={() => {
                    const newOptions = tagsOptions.filter((o) => o.value !== tag.value);
                    setTagsOptions(newOptions);
                  }}
                />
              </div>
            ))}
          </div>

          <Button
            variant="blue"
            loading={loading}
            title={t('Button.Save')}
            onClick={handleSaveTags(closePopover)}
            css={{ width: '100%', height: '28px', marginTop: '16px' }}
          />
        </div>
      )}
    </Popover>
  );
};

const styles = {
  tag: css({
    maxWidth: 240,
    borderRadius: 3,
    padding: '4px 8px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
    background: '#EEF3F7',
    boxSizing: 'border-box',
    display: 'inline-block',
    textOverflow: 'ellipsis'
  }),
  label: css({
    flex: 1,
    fontSize: 12,
    overflow: 'hidden',
    lineHeight: '12px',
    paddingRight: '8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: THEME.text.colors.black.lv1
  }),
  closeIcon: css({
    right: '6px',
    marginLeft: 4,
    cursor: 'pointer',
    position: 'absolute',
    transform: 'translateY(50%)',
    ':hover': { color: THEME.text.colors.black.lv1 }
  }),
  addLabel: css({
    borderRadius: 3,
    fontWeight: 400,
    fontSize: '12px',
    cursor: 'pointer',
    padding: '2px 8px',
    color: THEME.text.colors.blue,
    backgroundColor: THEME.background.colors.gray.lv1,
    ':hover': { opacity: 0.8 }
  })
};
