import { useTranslation } from 'react-i18next';
import { useParamsSearch } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { SectionWithHeader } from '@/shared/molecules';
import { HAS_NO_SELECTED_ACCOUNTS } from '@/shared/constants';
import { useInfluencerYoutubeCompareTagRankingQuery } from '@/graphql';
import { InfluencerCompareYoutubeAccountsProps, InfluencerProfileCompareSearchSchemaType } from '@/shared/organisms';
import { NoDataContentWrapper, RenderDataWithFallback } from '@/shared/atoms';
import { Template } from './Template';

export type InfluencerCompareYoutubeTagsRankingProps = Pick<
  InfluencerCompareYoutubeAccountsProps,
  'accounts' | 'mainAccountId' | 'hiddenAccountIds'
>;

export const InfluencerCompareYoutubeTagsRanking = ({
  accounts,
  mainAccountId,
  hiddenAccountIds
}: InfluencerCompareYoutubeTagsRankingProps) => {
  const { t } = useTranslation();
  const { filter } = useParamsSearch<InfluencerProfileCompareSearchSchemaType>();
  const { data, loading } = useInfluencerYoutubeCompareTagRankingQuery({
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      socialAccountId: mainAccountId
    })
  });

  const compareTagsData = data?.influencerYoutubeCompareTagRanking;
  const comparableAccounts = accounts.filter((account) => !hiddenAccountIds?.includes(account.id));
  const hasNoData = !comparableAccounts.length || !compareTagsData;

  return (
    <div css={{ marginTop: '24px' }}>
      <RenderDataWithFallback loading={loading}>
        <SectionWithHeader title={t('Tag Ranking')} prefixIcon={{ icon: 'user-two-tones' }}>
          <NoDataContentWrapper
            dataNotAvailable={hasNoData}
            wrapperText={HAS_NO_SELECTED_ACCOUNTS}
            css={!hasNoData && { minHeight: 'unset' }}
          >
            {compareTagsData ? <Template data={compareTagsData} comparableAccounts={comparableAccounts} /> : null}
          </NoDataContentWrapper>
        </SectionWithHeader>
      </RenderDataWithFallback>
    </div>
  );
};
