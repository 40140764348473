import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InstagramUserHashtagPostList.graphql';
import { Query, QueryinstagramUserHashtagPostListArgs } from '../../__generated__/globalTypes';

export const useInstagramUserHashtagPostListQuery = (
  options?: QueryHookOptions<Query, QueryinstagramUserHashtagPostListArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinstagramUserHashtagPostListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInstagramUserHashtagPostListLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinstagramUserHashtagPostListArgs>
): QueryResult<Query, QueryinstagramUserHashtagPostListArgs> & {
  getInstagramUserHashtagPostList: LazyQueryExecFunction<Query, QueryinstagramUserHashtagPostListArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInstagramUserHashtagPostList, result] = useLazyQuery<Query, QueryinstagramUserHashtagPostListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInstagramUserHashtagPostList, ...result };
};

export const useInstagramUserHashtagPostListPromiseQuery = () => {
  const client = useApolloClient();

  const getInstagramUserHashtagPostList = useCallback(
    (options?: Omit<QueryOptions<QueryinstagramUserHashtagPostListArgs, Query>, 'query'>) =>
      client.query<Query, QueryinstagramUserHashtagPostListArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInstagramUserHashtagPostList };
};
