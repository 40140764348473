import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthReconnectInstagramAccountAnalytics.graphql';
import { Mutation, MutationsocialAuthReconnectInstagramAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useSocialAuthReconnectInstagramAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthReconnectInstagramAccountAnalyticsArgs>
) => {
  const [callSocialAuthReconnectInstagramAccountAnalytics, result] = useMutation<
    Mutation,
    MutationsocialAuthReconnectInstagramAccountAnalyticsArgs
  >(MUTATION, options);

  return { callSocialAuthReconnectInstagramAccountAnalytics, ...result };
};

export type SocialAuthReconnectInstagramAccountAnalyticsMutationFunction =
  Mutation['socialAuthReconnectInstagramAccountAnalytics'];
