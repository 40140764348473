import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { MarketplaceCampaignPostHistoryStatus, MarketplacePostHistory, Query } from '@/graphql';
import { THEME } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { localizedDateFormatter } from '@/shared/utils';
import { DefaultHistoryTemplate } from '../shared';

export interface MarketplaceHistoryDetailsTabProps {
  className?: string;
  historyData?: Query['marketplacePostHistory'];
}

export const MarketplaceHistoryDetailsTab = ({ className, historyData = [] }: MarketplaceHistoryDetailsTabProps) => {
  const { t, i18n } = useTranslation();

  const getHistoryPostDetailsText = (post: MarketplacePostHistory) => {
    switch (post.status) {
      case MarketplaceCampaignPostHistoryStatus.APPROVED:
        return t('username approved the post', { username: post.operatedUserName });
      case MarketplaceCampaignPostHistoryStatus.REJECTED:
        return (
          <>
            <p css={{ color: '#FF5F5F' }}>
              {post.reason
                ? t('username rejected the post', { username: post.operatedUserName, reason: post.reason })
                : t('username rejected the post without a reason', { username: post.operatedUserName })}
            </p>
            <p css={{ fontSize: '12px', lineHeight: 1.5, color: THEME.text.colors.gray.lv3, wordBreak: 'break-all' }}>
              {post.detailedReason}
            </p>
          </>
        );
      case MarketplaceCampaignPostHistoryStatus.CREATED:
        return t('The creator published the post');
      default:
        return '';
    }
  };

  return (
    <DefaultHistoryTemplate className={className} hasNoData={!historyData?.length}>
      {historyData?.map((post, i) => (
        <div css={styles.historyItemWrapper} key={`${post.id}_${i}`}>
          <div css={{ flex: 'none', width: '95px', fontSize: '12px', color: THEME.text.colors.black.lv1 }}>
            {localizedDateFormatter(post.operatedDate, 'PPP', i18n.language as AppLanguage)}
          </div>
          <div css={{ flex: 1, fontSize: '13px', fontWeight: 600, lineHeight: 1.38 }}>
            {getHistoryPostDetailsText(post)}
          </div>
        </div>
      ))}
    </DefaultHistoryTemplate>
  );
};
const styles = {
  historyItemWrapper: css({
    width: '100%',
    display: 'flex',
    padding: '16px 24px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: THEME.border.base
  })
};
