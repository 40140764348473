import { ObservableQuery, OnQueryUpdated } from '@apollo/client';

export const wait = (ms: number | undefined = 0) => new Promise((res) => setTimeout(res, ms));
export const delayRefetchedQuery: OnQueryUpdated<any> = async (
  observableQuery: ObservableQuery<any>,
  _, // diff
  __, // lastDiff
  delay: number | undefined = 500
) => {
  await wait(delay);
  observableQuery.refetch();
};
