import { z } from 'zod';
import { LIMIT } from '@/shared/constants';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations } from '@/shared/validations';
import { SocialAccountType, XhsInfluencer, SocialAccountInfluencerListSortFieldV2 } from '@/graphql';
import { PostType, DefaultInfluencersProps, InfluencersFilterSchema, InfluencersBaseSearchSchema } from '../types';
import { getInitialXhsInfluencersFormValues } from './utils';

export const XhsInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  countryIds: true,
  includeIds: true,
  categoryIds: true,
  keywordField: true,
  minVideoPrice: true,
  maxVideoPrice: true,
  minFollowers: true,
  maxFollowers: true,
  demographicAge: true,
  minPicturePrice: true,
  maxPicturePrice: true,
  demographicGender: true,
  demographicAgeMinRate: true,
  demographicGenderMinRate: true,
  talentAgencyOrPartnerIds: true,
  minEngagementViewsRate: true,
  maxEngagementViewsRate: true,
  minEngagementFollowersRate: true,
  maxEngagementFollowersRate: true
});
export const XhsInfluencersSearchSchema = z.object({
  filter: XhsInfluencersFilterSchema.default(XhsInfluencersFilterSchema.parse(getInitialXhsInfluencersFormValues())),
  page: z.number().default(1),
  limit: z.number().default(LIMIT),
  state: InfluencersBaseSearchSchema.shape.state,
  sort: FilterValidations.sort(SocialAccountInfluencerListSortFieldV2).default({ field: null, order: null })
});

export type XhsInfluencersSearchSchemaType = z.infer<typeof XhsInfluencersSearchSchema>;
export type XhsInfluencersFilterFormValues = z.infer<typeof XhsInfluencersFilterSchema>;
export type XhsInfluencersFilterFormKeys = UnionOfKeys<XhsInfluencersFilterFormValues>;
export type XhsInfluencersProps = DefaultInfluencersProps<XhsInfluencerProfile>;
export type XhsInfluencerProfile = { posts: PostType[]; socialAccountType: SocialAccountType } & Pick<
  XhsInfluencer,
  | 'id'
  | 'age'
  | 'name'
  | 'gender'
  | 'avatar'
  | 'country'
  | 'averageLikes'
  | 'averageViews'
  | 'socialAccountId'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementViewsRate'
  | 'socialAccountEngagementFollowersRate'
>;
