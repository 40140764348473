import { useParams, useSearch } from '@tanstack/react-router';
import { useUpdateDocumentTitle } from '@/shared/hooks';

export const useInitialInfluencerPostsListWithName = (props?: { title?: string; titleWithName?: string }) => {
  const params = useParams({ strict: false });
  const influencerId = Number(params.id || 0);
  const mainSocialAccountId = Number(params.socialMediaAccountId || 0);
  const { filter } = useSearch({ strict: false }) as { filter?: { name: string } };

  const title = filter?.name
    ? props?.titleWithName || 'appHeader.PostListWithAccountName'
    : props?.title || 'appHeader.Post List';

  useUpdateDocumentTitle({
    backInHistory: {
      fallback: '/influencer/$id/profile'
    },
    interpolation: { title: filter?.name || '' },
    title,
    appHeader: title
  });

  return { influencerId, mainSocialAccountId, params };
};
