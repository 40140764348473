import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { HelpIcon } from '../Icon';
import { TextCutter } from '../TextCutter';

export interface LabelProps {
  title: ReactNode;
  help?: ReactNode;
  className?: string;
  required?: boolean;
  optional?: boolean;
  iconMargin?: string;
  labelLoader?: ReactNode;
}

export const Label = ({ help, title, required, optional, className, labelLoader, iconMargin = 'auto' }: LabelProps) => {
  const { t } = useTranslation();

  return (
    <label css={styles.wrapper} className={className}>
      {typeof title === 'string' ? <TextCutter text={title} lines={1} /> : title}
      {required && <span css={{ color: 'tomato' }}>&nbsp;*</span>}
      {labelLoader}
      {optional ? <span css={{ color: THEME.text.colors.gray.lv3, marginLeft: 'auto' }}>({t('Optional')})</span> : null}
      {help && <HelpIcon help={help} position="top-end" css={{ marginLeft: optional ? '4px' : iconMargin }} />}
    </label>
  );
};
const styles = {
  wrapper: css({
    width: '100%',
    display: 'flex',
    fontSize: '12px',
    marginBottom: '4px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    alignItems: 'center',
    boxSizing: 'border-box',
    lineHeight: 'normal',
    color: THEME.text.colors.black.lv1
  })
};
