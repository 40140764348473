import update from 'immutability-helper';
import React, { useEffect, useState } from 'react';
import { useQueryHelper } from '@/shared/hooks';
import { namedOperations, useUpdateCmsChannelShareRateMutation } from '@/graphql';
import { useYoutubeCMSFilter } from './useYoutubeCMSFilter';

interface RowState {
  id: number;
  value?: string;
  error?: boolean;
  channelId: string;
  defaultValue?: string;
}

export const useUpdateYoutubeChannelShareRate = () => {
  const { filter } = useYoutubeCMSFilter();
  const { t, search, enqueueSnackbar } = useQueryHelper();
  const [editingRows, setEditingRows] = useState<RowState[]>([]);
  const { callUpdateCmsChannelShareRate, loading: updatingCmsChannelRate } = useUpdateCmsChannelShareRateMutation({
    refetchQueries: [namedOperations.Query.AllYoutubeCmsEstimateChannels, namedOperations.Query.YoutubeCmsChannelDetail]
  });

  useEffect(
    () => () => {
      setEditingRows([]);
    },
    [search]
  );

  const handleSaveChange = async () => {
    if (!editingRows.length) {
      return;
    }

    try {
      await callUpdateCmsChannelShareRate({
        variables: {
          input: {
            month: filter.month,
            channels: editingRows.map((row) => ({ channelId: row.channelId, shareRate: Number(row.value || 0) }))
          }
        }
      });

      enqueueSnackbar(t('Share rate has been updated'), { variant: 'success' });
      setEditingRows([]);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveChange();
    }
  };

  const handleRowChange = (editingRow: RowState) => {
    const index = editingRows.findIndex((row) => row.id === editingRow.id);

    setEditingRows(
      index !== -1
        ? editingRow.value === editingRow.defaultValue
          ? update(editingRows, { $splice: [[index, 1]] }) // Remove if nothing changes
          : update(editingRows, { [index]: { value: { $set: editingRow.value } } }) // Update new value
        : update(editingRows, { $push: [editingRow] }) // Add new
    );
  };

  return {
    handleEnter,
    editingRows,
    handleRowChange,
    handleSaveChange,
    saving: updatingCmsChannelRate,
    hasRowChanged: !!editingRows.length
  };
};
