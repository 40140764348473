import { usePackageProposalContext } from '@/shared/organisms';

export const useSelectPackageInfluencersMapper = () => {
  const { packageProposal, proposalSummary } = usePackageProposalContext();
  const selectedInfluencersMapIds = (() =>
    (packageProposal?.influencers || []).reduce((rs, el) => {
      const socialMediaHashTable = el.accountList.reduce(
        (tb, acc) => tb.set(acc.socialMedia, [...(tb.get(acc.socialMedia) || []), acc.id]),
        new Map<string, number[]>()
      );

      return rs.set(el.id, socialMediaHashTable);
    }, new Map<number, Map<string, number[]>>()))();

  return {
    selectedInfluencersMapIds,
    packageProposal,
    proposalSummary
  };
};
