import {
  AnalyticsInstagramDashboardPosts,
  AnalyticsInstagramDashboardOverview,
  AnalyticsInstagramDashboardAudience
} from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';

export const AnalyticsInstagramDashboard = () => {
  const { brandAccount, selectedSocialAccount } = useAnalyticsProfileContext();

  return (
    <>
      <AnalyticsInstagramDashboardOverview analyticsAccount={selectedSocialAccount} />
      <AnalyticsInstagramDashboardAudience analyticsAccount={selectedSocialAccount} />
      <AnalyticsInstagramDashboardPosts brandAccount={brandAccount} analyticsAccount={selectedSocialAccount} />
    </>
  );
};
