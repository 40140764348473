import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllInstagramTaggedPosts.graphql';
import { Query, QueryallInstagramTaggedPostsArgs } from '../../__generated__/globalTypes';

export const useAllInstagramTaggedPostsQuery = (
  options?: QueryHookOptions<Query, QueryallInstagramTaggedPostsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallInstagramTaggedPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllInstagramTaggedPostsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallInstagramTaggedPostsArgs>
): QueryResult<Query, QueryallInstagramTaggedPostsArgs> & {
  getAllInstagramTaggedPosts: LazyQueryExecFunction<Query, QueryallInstagramTaggedPostsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllInstagramTaggedPosts, result] = useLazyQuery<Query, QueryallInstagramTaggedPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllInstagramTaggedPosts, ...result };
};

export const useAllInstagramTaggedPostsPromiseQuery = () => {
  const client = useApolloClient();

  const getAllInstagramTaggedPosts = useCallback(
    (options?: Omit<QueryOptions<QueryallInstagramTaggedPostsArgs, Query>, 'query'>) =>
      client.query<Query, QueryallInstagramTaggedPostsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllInstagramTaggedPosts };
};
