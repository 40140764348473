import { z } from 'zod';
import { getToday } from '@/shared/utils';
import { getThirtyDaysAgo } from '@/shared/utils';
import { FilterValidations } from '@/shared/validations';
import { YoutubeCompareOverviewHistory } from '@/graphql';

const InfluencerProfileCompareFilterSchema = z
  .object({ name: z.string().optional(), id: z.string().optional() })
  .merge(FilterValidations.period);

export const InfluencerProfileCompareSearchSchema = z.object({
  filter: InfluencerProfileCompareFilterSchema.default({
    endDate: getToday(),
    startDate: getThirtyDaysAgo(),
    name: ''
  })
});

export type InfluencerProfileCompareSearchSchemaType = z.infer<typeof InfluencerProfileCompareSearchSchema>;
export type InfluencerYoutubeCompareGraphKeys = keyof YoutubeCompareOverviewHistory;
