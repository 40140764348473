import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { Genders, SocialAccountInfluencerListSortFieldV2, SocialAccountStatus, SocialAccountType } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
import { PostType, DefaultInfluencersProps, InfluencersFilterSchema, InfluencersBaseSearchSchema } from '../types';
import { getInitialFacebookInfluencersFormValues } from './utils';

export const FacebookInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  talentAgencyOrPartnerIds: true
});
export const FacebookInfluencersSearchSchema = z.object({
  filter: FacebookInfluencersFilterSchema.default(
    FacebookInfluencersFilterSchema.parse(getInitialFacebookInfluencersFormValues())
  ),
  ...SearchFilterDefaults.pageLimit(),
  sort: SearchFilterDefaults.sort(SocialAccountInfluencerListSortFieldV2).default({ field: null, order: null }),
  state: InfluencersBaseSearchSchema.shape.state
});

export type FacebookInfluencersSearchSchemaType = z.infer<typeof FacebookInfluencersSearchSchema>;
export type FacebookInfluencersFilterFormValues = z.infer<typeof FacebookInfluencersFilterSchema>;
export type FacebookInfluencersFilterFormKeys = UnionOfKeys<FacebookInfluencersFilterFormValues>;
export type FacebookInfluencersProps = DefaultInfluencersProps<FacebookInfluencerProfile>;
export interface FacebookInfluencerProfile {
  id: number;
  name: string;
  avatar?: string;
  posts: PostType[];
  age?: number | null;
  gender: Genders | '';
  likes?: number | null;
  isBrandAccount?: boolean;
  followers?: number | null;
  analyticsWarning?: boolean;
  engagementRate?: number | null;
  socialAccountId?: string;
  status: SocialAccountStatus | null;
  socialAccountType: SocialAccountType;
  country: { id?: string; name?: string };
}
