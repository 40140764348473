import {
  createStyles,
  PopoverTargetProps,
  PopoverDropdownProps,
  Popover as MantinePopover,
  PopoverProps as MantinePopoverProps
} from '@mantine/core';
import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';

import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';

interface InnerProps {
  open?: boolean;
  closePopover: () => void;
  togglePopover: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
export interface PopoverProps extends Omit<MantinePopoverProps, 'opened' | 'children'> {
  open?: boolean;
  withBorder?: boolean;
  targetProps?: PopoverTargetProps;
  dropdownProps?: PopoverDropdownProps;
  target: ((props: InnerProps) => ReactNode) | ReactNode;
  children: ((props: InnerProps) => ReactNode) | ReactNode;
  onClose?: (open?: boolean) => void;
}

export const Popover = ({
  open,
  target,
  onClose,
  children,
  withBorder,
  targetProps,
  dropdownProps,
  withinPortal = true,
  ...baseProps
}: PopoverProps) => {
  const popoverState = useToggleState();
  const { classes } = useStyles(!!withBorder);

  useEffect(() => {
    if (typeof open !== 'undefined' && open !== popoverState.status) {
      popoverState.setStatus(open);
    }
  }, [open, popoverState.status]);

  const handleClose = () => {
    popoverState.close();
    onClose?.(popoverState.status);
  };

  const innerProps: InnerProps = {
    open: popoverState.status,
    closePopover: handleClose,
    setOpen: popoverState.setStatus,
    togglePopover: popoverState.toggleStatus
  };

  return (
    <MantinePopover
      onClose={handleClose}
      withinPortal={withinPortal}
      opened={popoverState.status}
      closeOnClickOutside
      offset={THEME.dropdown.offset}
      {...baseProps}
    >
      <MantinePopover.Target {...targetProps}>
        {typeof target === 'function' ? target(innerProps) : target}
      </MantinePopover.Target>
      <MantinePopover.Dropdown {...dropdownProps} className={classes.dropdown}>
        {typeof children === 'function' ? children(innerProps) : children}
      </MantinePopover.Dropdown>
    </MantinePopover>
  );
};

const useStyles = createStyles((_, withBorder?: boolean) => ({
  dropdown: {
    padding: 0,
    ...(withBorder ? { border: THEME.border.base } : { filter: 'drop-shadow(0px 1px 2px rgba(39, 49, 59, 0.10))' })
  }
}));
