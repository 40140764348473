import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { PricingTableItem, TikTokSpecialCampaignStatus } from '@/graphql';
import { TikTokCampaignFormValues } from './types';
import { LeaveWarningModal } from './LeaveWarningModal';
import { getTikTokCampaignDisabledFields } from './utils';
import { CampaignInformation, PackageInformation, PlanInformation } from './formSections';

export interface TemplateProps {
  onSubmit?: () => void;
  currentPlan?: PricingTableItem;
}
export const Template = ({ onSubmit, currentPlan }: TemplateProps) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    formState: { isSubmitting }
  } = useFormContext<TikTokCampaignFormValues>();

  const [isValue, isDraftValue, statusValue] = watch(['id', 'isDraft', 'status']);
  const disabledFields = getTikTokCampaignDisabledFields(statusValue);

  const handleSubmit =
    ({ isDraft, saveOnLeave }: Pick<TikTokCampaignFormValues, 'isDraft' | 'saveOnLeave'>) =>
    () => {
      setValue('isDraft', isDraft);
      setValue('saveOnLeave', saveOnLeave);
      onSubmit?.();
    };

  return (
    <FormStyle.Wrapper css={{ flex: 1, boxShadow: THEME.shadows.boxShadow }} hasBorder>
      {currentPlan ? <PlanInformation currentPlan={currentPlan} /> : null}
      <PackageInformation disabledFields={disabledFields} />
      <CampaignInformation disabledFields={disabledFields} />

      <FormStyle.ButtonSubmitWrapper>
        {statusValue === TikTokSpecialCampaignStatus.DRAFT && !!isValue && (
          <>
            <FormStyle.ButtonSubmit
              type="button"
              variant="blueInverted"
              title={t('Button.Save as draft')}
              loading={isDraftValue && isSubmitting}
              onClick={handleSubmit({ isDraft: true, saveOnLeave: false })}
            />

            <LeaveWarningModal onSubmit={handleSubmit({ isDraft: true, saveOnLeave: true })} />
          </>
        )}

        <FormStyle.ButtonSubmit
          type="button"
          title={t('Button.Save')}
          loading={!isDraftValue && isSubmitting}
          onClick={handleSubmit({ isDraft: false, saveOnLeave: false })}
        />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};
