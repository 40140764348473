import { useTranslation } from 'react-i18next';
import { useCopy } from '@/shared/hooks';
import { THEME } from '@/shared/constants';
import { Icon } from '../../../Icon';
import { Tooltip } from '../../../Tooltip';
import { useChatBoxContext } from '../../ChatBoxContext';

export interface ChatBoxCopyButtonProps {
  copyText?: string;
}
export const ChatBoxCopyButton = ({ copyText }: ChatBoxCopyButtonProps) => {
  const { t } = useTranslation();
  const { copyToClipboard } = useCopy();
  const { setToastMessage } = useChatBoxContext();

  return (
    <Tooltip help={t<string>('Tooltip.Copy')}>
      <div
        css={{ display: 'flex' }}
        onClick={() => {
          if (copyText) {
            copyToClipboard(copyText, false);
            setToastMessage({ type: 'success', message: t('chatboxCopyTextSuccess') });
          }
        }}
      >
        <Icon
          icon="copy"
          size={11}
          color={THEME.icon.colors.white}
          css={{ ':hover': { cursor: 'pointer', opacity: 0.85 } }}
        />
      </div>
    </Tooltip>
  );
};
