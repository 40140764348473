import { InstagramAnalyticsStoryDetails } from '@/graphql';
import { THEME } from '@/shared/constants';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InstagramPostInDateStoryPostDetailsProps {
  className?: string;
  onCloseModal?: () => void;
  data?: InstagramAnalyticsStoryDetails;
}

export const InstagramPostInDateStoryPostDetails = ({
  data,
  className,
  onCloseModal
}: InstagramPostInDateStoryPostDetailsProps) => {
  if (!data) {
    onCloseModal?.();

    return null;
  }

  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = data
    ? {
        caption: data.content,
        socialMedia: 'INSTAGRAM',
        postUrl: data.postUrl || '',
        images: data.storyVideoUrl ? [data.storyVideoUrl] : data.postImageUrl ? [data.postImageUrl] : [],
        info: {
          avatar: data.avatarUrl,
          postDate: data.postDate,
          socialMedia: 'INSTAGRAM',
          followers: data.followers,
          name: data.instagramAccountName
        },
        statistics: {
          comments: data.comments,
          reach: data.reach,
          impressions: data.impressions,
          exits: data.exits,
          tapsForward: data.tapsForward,
          swipeForward: data.swipeForward,
          tapsBack: data.tapsBack
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate className={className} postDetails={postDetails} css={{ width: THEME.modal.size.lv3 }} />
  );
};
