import { MarketplaceDetail, MarketplacePost, namedOperations } from '@/graphql';
import { Table } from '@/shared/atoms';
import { useTableMaxHeight } from '@/shared/hooks';
import { useUpdatePostPrices } from '../../hooks';
import { useUpdateMarketplacePostsStatus } from '../hooks';
import { ActionBar } from './ActionBar';
import { useMarketplacePostsListTable } from './hooks';

export interface MarketplacePostsListProps {
  loading?: boolean;
  hasTrackingReferral?: boolean;
  marketplace: MarketplaceDetail;
  posts: readonly MarketplacePost[];
}

export const MarketplacePostsList = ({
  posts,
  loading,
  marketplace,
  hasTrackingReferral
}: MarketplacePostsListProps) => {
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();
  const { UpdateMarketplacePostStatusModal } = useUpdateMarketplacePostsStatus({ marketplaceId: marketplace.id });
  const { rows, columns, postsActions, formState, handleSubmit, reset } = useMarketplacePostsListTable({
    posts,
    marketplace,
    hasTrackingReferral
  });
  const { onSubmit, costLoading } = useUpdatePostPrices({
    defaultValues: formState.defaultValues,
    marketplaceId: marketplace.id,
    reset,
    refetchQueries: [namedOperations.Query.AllMarketplacePosts]
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} css={{ position: 'relative' }}>
        <Table
          ref={offsetRef}
          loading={loading}
          noTopBorderRadius
          maxHeight={defaultTableMaxHeight}
          data={{ rows, columns, rowHeight: '61px' }}
          stickyHeader
        />
        <ActionBar {...postsActions} marketplace={marketplace} isDirty={formState.isDirty} costLoading={costLoading} />
      </form>

      <UpdateMarketplacePostStatusModal onSuccess={() => postsActions.setSelectedItemIds([])} />
    </>
  );
};
