import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerAllInstagramReelPosts.graphql';
import { Query, QueryinfluencerAllInstagramReelPostsArgs } from '../../__generated__/globalTypes';

export const useInfluencerAllInstagramReelPostsQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerAllInstagramReelPostsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerAllInstagramReelPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerAllInstagramReelPostsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerAllInstagramReelPostsArgs>
): QueryResult<Query, QueryinfluencerAllInstagramReelPostsArgs> & {
  getInfluencerAllInstagramReelPosts: LazyQueryExecFunction<Query, QueryinfluencerAllInstagramReelPostsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerAllInstagramReelPosts, result] = useLazyQuery<Query, QueryinfluencerAllInstagramReelPostsArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getInfluencerAllInstagramReelPosts, ...result };
};

export const useInfluencerAllInstagramReelPostsPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerAllInstagramReelPosts = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerAllInstagramReelPostsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerAllInstagramReelPostsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerAllInstagramReelPosts };
};
