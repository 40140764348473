import { css } from '@emotion/react';
import { CheckBox, Radio } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { AnalyticsCompareOverviewLegendType } from './types';

export interface AnalyticsCompareOverviewLegendProps<T extends string> {
  className?: string;
  selectedOptions: T[];
  options: AnalyticsCompareOverviewLegendType<T>[];
  onSelectOption?: (option: AnalyticsCompareOverviewLegendType<T>) => void;
}
export const AnalyticsCompareOverviewLegend = <T extends string>({
  options,
  className,
  onSelectOption,
  selectedOptions
}: AnalyticsCompareOverviewLegendProps<T>) => (
  <div css={{ display: 'flex', flexWrap: 'wrap' }} className={className}>
    {options.map((option, index) => {
      const { label, value, type } = option;
      const isCheckBox = type === 'checkbox';
      const isChecked = selectedOptions.includes(value);
      const isLastCheckBox = isCheckBox && options[index + 1]?.type === 'radio';

      return (
        <div key={value} css={styles.item(isLastCheckBox)} onClick={() => onSelectOption?.(option)}>
          {isCheckBox ? <CheckBox checked={isChecked} /> : <Radio checked={isChecked} />}
          <p css={styles.label}>{label}</p>
        </div>
      );
    })}
  </div>
);
const styles = {
  item: (isLastCheckBox?: boolean) =>
    css({
      padding: '4px',
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      ...(isLastCheckBox ? { paddingRight: '12px', borderRight: '1px solid #dee5ef' } : {}),
      '&:not(:last-of-type)': { marginRight: '8px' }
    }),
  label: css({
    fontSize: '12px',
    lineHeight: 1.17,
    paddingLeft: '4px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: THEME.text.colors.black.lv1
  })
};
