import { UserDetails } from '@/pages/Users/UserDetails';
import { advertiserRoles } from '@/shared/constants';
import { PermissionRoute } from '@/app-components';
import { PartialRouter } from '../../types';

export const UserSettingsRouter = {
  '/_private-routes/user-settings': [
    () => <PermissionRoute permissions={advertiserRoles} element={<UserDetails />} />,
    {}
  ]
} as const satisfies PartialRouter;
