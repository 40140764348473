import { useTranslation } from 'react-i18next';
import { AutoManagedCampaignStatus } from '@/graphql';
import { FormStyle } from '@/shared/atoms';
import { RadiosField } from '@/shared/molecules';
import { AutoManagedCampaignFormKeys } from '../types';

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <div css={{ padding: '24px' }}>
      <FormStyle.SectionTitle index={3} label={t('Display setting')} />

      <RadiosField<AutoManagedCampaignFormKeys>
        name="status"
        itemsPerRow={1}
        css={{ marginTop: '16px', fontSize: '14px' }}
        options={[
          {
            value: AutoManagedCampaignStatus.ACTIVE,
            label: t('Activate this campaign: Show this campaign on AnyCreator')
          },
          { value: AutoManagedCampaignStatus.INACTIVE, label: t('Inactivate this campaign') }
        ]}
      />
    </div>
  );
};
