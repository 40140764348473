import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { HelpIcon } from '../Icon';
import { TextCutter } from '../TextCutter';

export interface RadioProps {
  lines?: number;
  checked?: boolean;
  label?: ReactNode;
  disabled?: boolean;
  className?: string;
  iconMargin?: string;
  help?: React.ReactNode;
  onClick?: (checked: boolean) => void;
}

export const Radio = ({
  help,
  lines,
  onClick,
  checked,
  disabled,
  className,
  label = null,
  iconMargin = 'auto'
}: RadioProps) => (
  <label
    css={styles.wrapper(checked, disabled)}
    className={`${className} radio-wrapper`}
    onClick={() => onClick?.(!checked)}
  >
    <div css={styles.icon(checked, disabled)} />
    {typeof label === 'string' ? <TextCutter text={label} lines={lines} css={{ marginLeft: '6px' }} /> : label}
    {help && <HelpIcon help={help} css={{ marginLeft: iconMargin }} />}
  </label>
);
const styles = {
  wrapper: (checked?: boolean, disabled?: boolean) =>
    css({
      display: 'flex',
      userSelect: 'none',
      fontSize: 'inherit',
      alignItems: 'center',
      boxSizing: 'border-box',
      pointerEvents: disabled ? 'none' : 'auto',
      cursor: disabled ? 'not-allowed' : 'pointer',
      color: disabled && !checked ? THEME.text.colors.gray.lv1 : THEME.text.colors.black.lv1,
      ':hover': {
        opacity: 0.8
      }
    }),
  icon: (checked?: boolean, disabled?: boolean) =>
    css({
      flexShrink: 0,
      width: '16px',
      height: '16px',
      position: 'relative',
      borderRadius: '50%',
      boxSizing: 'border-box',
      backgroundColor:
        disabled && checked
          ? THEME.background.colors.gray.lv2
          : checked
            ? THEME.background.colors.blue
            : disabled
              ? THEME.background.colors.gray.lv1
              : THEME.background.colors.white,
      ...(!checked
        ? { border: '1px solid #c5d0da', boxShadow: !disabled ? 'inset 1px 1px 2px rgba(0, 0, 0, 0.2)' : 'none' }
        : {}),
      '&::after': {
        top: '50%',
        left: '50%',
        width: '8px',
        height: '8px',
        content: '""',
        display: 'block',
        borderRadius: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        backgroundColor: disabled ? THEME.background.colors.gray.lv1 : THEME.background.colors.white
      }
    })
};
