import { useExistingProfileTagsPromiseQuery } from '@/graphql';
import { Option } from '@/shared/types';
import { MultiSearchSelector, MultiSearchSelectorProps } from './MultiSearchSelector';

export type MultiTagsSearchSelectorProps = Omit<MultiSearchSelectorProps<Option>, 'loadOptions'>;

export const MultiTagsSearchSelector = (props: MultiTagsSearchSelectorProps) => {
  const { getExistingProfileTags } = useExistingProfileTagsPromiseQuery();

  const promiseOptions = async (inputValue: string): Promise<Option<any>[]> =>
    new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await getExistingProfileTags({
          variables: { keyword: inputValue }
        });
        const formattedData = data?.existingProfileTags?.tags.map((tag) => ({ value: tag, label: tag })) || [];

        resolve(formattedData);
      }, 500);
    });

  return <MultiSearchSelector {...props} loadOptions={promiseOptions} />;
};
