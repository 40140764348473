import { useTranslation } from 'react-i18next';
import { InfluencerFeedPost, InfluencerNewProfileHeaderV2, SocialPostType } from '@/graphql';
import { MediaPreviewWithMetrics } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { usePostDetailsParam } from '@/shared/hooks';
import { ImageSlider, InfluencerPostDetailsModal } from '@/shared/molecules';
import { bigIntFormatter } from '@/shared/utils';
import { useInfluencerProfileOpacityContent } from '../../../hooks';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfileFeedPostsWrapper } from '../../shared';

const FEED_PARAMS_KEY = 'feedPopup';

interface InfluencerThreadsProfileFeedPostsProps {
  data?: readonly InfluencerFeedPost[];
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerThreadsProfileFeedPosts = ({
  data = [],
  socialAccount,
  influencerProfile: { id: influencerId, name }
}: InfluencerThreadsProfileFeedPostsProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam(FEED_PARAMS_KEY);
  const { opacityContent } = useInfluencerProfileOpacityContent({
    influencerId,
    socialAccount,
    skipEstimateContent: true
  });

  return (
    <>
      <InfluencerProfileFeedPostsWrapper
        dataNotAvailable={!data.length}
        title={t('HeaderColumn.Feed Posts')}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/scraping_feed_posts',
          params: { socialMediaAccountId: socialAccount.id.toString() },
          search: { filter: { name, type: SocialPostType.THREADS } }
        }}
        {...opacityContent}
      >
        <ImageSlider total={data.length} css={{ paddingLeft: '16px' }}>
          {data.map(({ id, thumbnail, caption, likes, comments }) => (
            <MediaPreviewWithMetrics
              key={id}
              src={thumbnail}
              caption={caption}
              css={THEME.mediaPreview.default}
              onClick={() => setPostDetailsParam(id)}
              captionProps={THEME.mediaPreview.feedPostCaptionDims}
              statistics={[
                { label: t('Like'), value: likes ? bigIntFormatter(likes, 0, { k: true, m: true }) : '-' },
                { label: t('General.Replies'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
              ]}
              showIcon
            />
          ))}
        </ImageSlider>
      </InfluencerProfileFeedPostsWrapper>

      <InfluencerPostDetailsModal paramKey={FEED_PARAMS_KEY} posts={data.map(({ id }) => ({ id, influencerId }))} />
    </>
  );
};
