import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useDeepCompareEffect } from '@/shared/hooks';
import { CampaignInformation, CastingInformation, Tracking } from './formSections';
import { EngagementCampaignFormValues } from './types';
import { getDisabledFields } from './utils';

export const Template = () => {
  const { t } = useTranslation();
  const {
    reset,
    formState: { isSubmitting, defaultValues }
  } = useFormContext<EngagementCampaignFormValues>();

  useDeepCompareEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const disabledFields = getDisabledFields(defaultValues?.status);

  return (
    <FormStyle.Wrapper css={{ flex: 1, boxShadow: THEME.shadows.boxShadow }} hasBorder>
      <CampaignInformation disabledFields={disabledFields} />

      <FormStyle.HorizontalLine gap={24} />

      <CastingInformation disabledFields={disabledFields} />

      <FormStyle.HorizontalLine gap={24} />

      <Tracking disabledFields={disabledFields} />

      <FormStyle.ButtonSubmitWrapper>
        <FormStyle.ButtonSubmit title={t('Button.Save')} loading={isSubmitting} />
      </FormStyle.ButtonSubmitWrapper>
    </FormStyle.Wrapper>
  );
};
