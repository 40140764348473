import {
  AddSocialAccountToUserProposalInput,
  MutationaddSocialAccountToUserProposalArgs,
  MutationdeleteUserProposalSocialAccountArgs,
  namedOperations,
  useAddSocialAccountToUserProposalMutation,
  useDeleteUserProposalSocialAccountMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';

interface ToggleSocialAccountProps extends AddSocialAccountToUserProposalInput {
  isChecked?: boolean;
}
export const useToggleSelectedSocialAccounts = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callAddSocialAccountToUserProposal, loading: isUpdatingProposal } = useAddSocialAccountToUserProposalMutation(
    {
      refetchQueries: [namedOperations.Query.UserProposal]
    }
  );
  const { callDeleteUserProposalSocialAccount, loading: isDeletingProposal } =
    useDeleteUserProposalSocialAccountMutation({
      refetchQueries: [namedOperations.Query.UserProposal]
    });

  const toggleSocialMediaSelectedInfluencer = async ({
    influencerIds,
    isChecked,
    socialAccountIds,
    socialAccountMedia
  }: ToggleSocialAccountProps) => {
    let error;
    const variables: MutationaddSocialAccountToUserProposalArgs | MutationdeleteUserProposalSocialAccountArgs = {
      input: { influencerIds, socialAccountIds, socialAccountMedia }
    };

    try {
      if (isChecked) {
        await callDeleteUserProposalSocialAccount({ variables });
      } else {
        await callAddSocialAccountToUserProposal({ variables });
      }
      // we don't want to notify user on each save/delete, just show an error if happens
    } catch (err) {
      error = err.message;
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }

    return { error };
  };

  return { toggleSocialMediaSelectedInfluencer, loading: isUpdatingProposal || isDeletingProposal };
};
