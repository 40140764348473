import { UserRoles } from '@/graphql';
import { refineBasedOnRoleSchema } from '../schema';
import { MarketplaceFormKeys } from '../types';

export const getSocialMediaTrackingFormSchema = (role: UserRoles) =>
  refineBasedOnRoleSchema(role, {
    [MarketplaceFormKeys.title]: true,
    [MarketplaceFormKeys.budget]: true,
    [MarketplaceFormKeys.minAge]: true,
    [MarketplaceFormKeys.maxAge]: true,
    [MarketplaceFormKeys.genders]: true,
    [MarketplaceFormKeys.period]: true,
    [MarketplaceFormKeys.hashtags]: true,
    [MarketplaceFormKeys.clickUrl]: true,
    [MarketplaceFormKeys.countryId]: true,
    [MarketplaceFormKeys.thumbnails]: true,
    [MarketplaceFormKeys.marginRate]: true,
    [MarketplaceFormKeys.socialMedia]: true,
    [MarketplaceFormKeys.requirement]: true,
    [MarketplaceFormKeys.salesPicIds]: true,
    [MarketplaceFormKeys.advertiser]: true,
    [MarketplaceFormKeys.minFollowers]: true,
    [MarketplaceFormKeys.maxFollowers]: true,
    [MarketplaceFormKeys.preLaunchDate]: true,
    [MarketplaceFormKeys.campaignCategoryId]: true,
    [MarketplaceFormKeys.serviceInformation]: true,
    [MarketplaceFormKeys.influencerCategoryIds]: true,
    [MarketplaceFormKeys.influencerManagementPicIds]: true,
    [MarketplaceFormKeys.costPerPost]: true,
    [MarketplaceFormKeys.costPerLike]: true,
    [MarketplaceFormKeys.costPerView]: true,
    [MarketplaceFormKeys.costPerShare]: true
  });
