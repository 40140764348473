import { Navigate, useSearch } from '@tanstack/react-router';
import { Header } from '@/shared/molecules';
import { FormAction } from '@/shared/types';
import { useAdvertiserPermissions } from '@/auth';
import { THEME, UNEXPECTED_ERROR } from '@/shared/constants';
import { UserLinkedForm, UserLinkedFormValues } from '@/shared/organisms';
import { useQueryHelper, useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { UserRoles, useCreateAdvertiserUserMutation, useAssignAdvertiserToStaffsMutation } from '@/graphql';
import { UserLinkedSearchSchemaType } from './schemaTypes';

export const UserLinkedDetails = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    title: 'documentTitle.LinkUsers',
    appHeader: 'appHeader.LinkUsers',
    href: '/user',
    alwaysBackToParent: true
  });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { hideEditAdvertiserBtn } = useAdvertiserPermissions();
  const { callCreateAdvertiserUser } = useCreateAdvertiserUserMutation();
  const { callAssignAdvertiserToStaffs } = useAssignAdvertiserToStaffsMutation();
  const { adv_id } = useSearch({ from: '/_private-routes/user/linked' }) satisfies UserLinkedSearchSchemaType;

  if (!adv_id || hideEditAdvertiserBtn) {
    return <Navigate to="/advertiser" />;
  }

  const handleSubmit: FormAction<UserLinkedFormValues>['onSubmit'] = async (formValues) => {
    try {
      if (formValues.userType === UserRoles.STAFF) {
        await callAssignAdvertiserToStaffs({
          variables: {
            input: { staffUserIds: formValues.staffIds.map(Number), advertiserId: Number(formValues.advertiserId) }
          }
        });
      } else {
        await callCreateAdvertiserUser({
          variables: {
            input: {
              name: formValues.name,
              email: formValues.email,
              countryId: formValues.countryId,
              permissions: null,
              hasInvitationEmail: formValues.hasInvitationEmail,
              notificationSetting: formValues.notificationSetting,
              advertiserId: Number(formValues.advertiserId),
              ...(!formValues.hasInvitationEmail ? { password: formValues.password } : {})
            }
          }
        });
      }

      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      navigate({ to: '/advertiser/$id/users', params: { id: formValues.advertiserId } });
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <div css={{ margin: '0 auto', width: THEME.container.details.width }}>
      <Header />

      <UserLinkedForm defaultValues={{ advertiserId: adv_id }} onSubmit={handleSubmit} />
    </div>
  );
};
