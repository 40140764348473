import { z } from 'zod';
import { SearchFilterDefaults } from '@/shared/validations';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { InstagramFeedPostSortField, InstagramReelPostSortField, InstagramStoryPostSortField } from '@/graphql';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';

export const AnalyticsPostsListFilterSchema = z
  .object({
    accountId: z.number().optional()
  })
  .merge(ProfilePostsFilterSchema);
export const AnalyticsPostsListSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(AnalyticsPostsListFilterSchema, {
    startDate: getThirtyDaysAgo(),
    endDate: getToday()
  }),
  ...SearchFilterDefaults.pageLimit()
});
export const AnalyticsIgFeedPostsListSearchSchema = AnalyticsPostsListSearchSchema.merge(
  z.object({
    sort: SearchFilterDefaults.sort(InstagramFeedPostSortField).default({})
  })
);
export const AnalyticsIgReelPostsListSearchSchema = AnalyticsPostsListSearchSchema.merge(
  z.object({
    sort: SearchFilterDefaults.sort(InstagramReelPostSortField).default({})
  })
);
export const AnalyticsIgStoryPostsListSearchSchema = AnalyticsPostsListSearchSchema.merge(
  z.object({
    sort: SearchFilterDefaults.sort(InstagramStoryPostSortField).default({})
  })
);

export type AnalyticsPostsListSearchSchemaType = z.infer<typeof AnalyticsPostsListSearchSchema>;
export type AnalyticsIgFeedPostsListSearchSchemaType = z.infer<typeof AnalyticsIgFeedPostsListSearchSchema>;
export type AnalyticsIgReelPostsListSearchSchemaType = z.infer<typeof AnalyticsIgReelPostsListSearchSchema>;
export type AnalyticsIgStoryPostsListSearchSchemaType = z.infer<typeof AnalyticsIgStoryPostsListSearchSchema>;
