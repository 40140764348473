import { css } from '@emotion/react';
import { BasicAccountAnalyticsPayload } from '@/graphql';
import { Anchor, Icon, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { InfluencerAvatar } from '@/shared/molecules';
import { AnalyticsSelectedAccountInfo } from '../types';
import { AnalyticsHeaderActions } from './AnalyticsHeaderActions';

export interface AnalyticsHeaderProps {
  className?: string;
  brandAccount: BasicAccountAnalyticsPayload;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsHeader = ({ className, brandAccount, analyticsAccount }: AnalyticsHeaderProps) => (
  <div css={styles.wrapper} className={className}>
    <div css={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
      <InfluencerAvatar
        size="56px"
        src={analyticsAccount.avatar}
        css={{ borderRadius: '50%', border: THEME.border.base }}
      />
      <div>
        <TextCutter
          lines={2}
          text={brandAccount.brandAccountName}
          css={{ color: THEME.text.colors.black.lv1, fontSize: '18px', fontWeight: '600', maxWidth: '350px' }}
        />
        <Anchor
          variant="blue"
          target="_blank"
          href={analyticsAccount.socialMediaUrl}
          css={{ gap: '6px', display: 'flex', fontSize: '13px', lineHeight: '16px' }}
        >
          {analyticsAccount.socialAccountName}
          <Icon icon="external-link" size={14} />
        </Anchor>
      </div>
    </div>

    <AnalyticsHeaderActions brandAccount={brandAccount} analyticsAccount={analyticsAccount} />
  </div>
);
const styles = {
  wrapper: css({
    height: '72px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px 0 16px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    backgroundColor: THEME.background.colors.white
  })
};
