import { components, OptionProps } from 'react-select';

export const Option = (props: OptionProps<any, any, any>) =>
  // TODO: do we need this?
  typeof props.label === 'object' ? (
    props.label
  ) : (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        // Mouse events cause the menu reset scroll to the top.
        onMouseMove: () => undefined,
        onMouseOver: () => undefined
      }}
    />
  );
