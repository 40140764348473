import { useState } from 'react';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { convertOrderToORDER, getValuableVariables } from '@/shared/utils';
import { TwitterInfluencer, Maybe, useAllTwitterInfluencersQuery } from '@/graphql';
import { formatInfluencerProfile } from '../utils';
import { TwitterInfluencersSearchSchemaType } from '../schemaTypes';
import { useGetInfluencersQueryVariables } from '../../hooks';

type Props = Pick<ParamsSearchReturnType<TwitterInfluencersSearchSchemaType>, 'page' | 'limit' | 'filter' | 'sort'>;

export const useTwitterInfluencersList = ({ page, sort, limit, filter }: Props) => {
  // Using data directly from query can be empty if skipSearch is true
  // But we still want to keep old data in that case, so we store it in state
  const [data, setData] = useState<{
    totalNumber?: number;
    twitterAccounts?: Maybe<readonly TwitterInfluencer[]>;
  }>();
  const { skipSearch, variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { loading } = useAllTwitterInfluencersQuery({
    skip: skipSearch || !filter,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: getValuableVariables({
      ...variables,
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) }
    }),
    onCompleted: ({ allTwitterInfluencers, allTwitterInfluencersCount }) => {
      setData({
        twitterAccounts: allTwitterInfluencers,
        totalNumber: allTwitterInfluencersCount?.totalNumber
      });
    }
  });

  return {
    loading,
    totalRecords: data?.totalNumber || 0,
    listRecords: data?.twitterAccounts?.map(formatInfluencerProfile) || []
  };
};
