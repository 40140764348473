import { useRequestPackageProposalExcelMutation, useRequestPackageProposalPptxMutation } from '@/graphql';

export const usePackageDownloads = () => {
  const { callRequestPackageProposalExcel } = useRequestPackageProposalExcelMutation({ fetchPolicy: 'no-cache' });
  const { callRequestPackageProposalPptx } = useRequestPackageProposalPptxMutation({ fetchPolicy: 'no-cache' });

  return {
    callRequestPackageProposalExcel,
    callRequestPackageProposalPptx
  };
};
