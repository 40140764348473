import { css } from '@emotion/react';

import { THEME } from '@/shared/constants';
import {
  formatNumber,
  formatPercent,
  getGrowthArrow,
  getGrowthPrefix,
  bigIntFormatter,
  intlNumberFormat
} from '@/shared/utils';

interface NumberGrowthProps {
  className?: string;
  hasHyphen?: boolean;
  hasDecimals?: boolean;
  total?: number | null;
  growth?: number | null;
  rate?: number | null;
}

export const NumberGrowth = ({ total, growth, rate, className, hasDecimals, hasHyphen }: NumberGrowthProps) => {
  const isPositiveGrowth = growth === 0 ? undefined : Number(growth) > 0;
  const arrow = getGrowthArrow(growth);
  const prefix = getGrowthPrefix(growth) || '';
  // FIXME: remove after BE will support null for newly fetched hashtags
  const hoverTotal = intlNumberFormat(total);
  const hoverGrowth = intlNumberFormat(growth);
  const temporatyNullableTotal = hasHyphen ? total || null : total;
  const formattedTotal = hasDecimals
    ? formatNumber(temporatyNullableTotal, 2)
    : bigIntFormatter(temporatyNullableTotal, 0);
  const formattedGrowth = hasDecimals ? formatNumber(growth, 2) : bigIntFormatter(growth, 0);

  return (
    <div className={className}>
      <div css={styles.total} title={hoverTotal}>
        {formattedTotal}
      </div>
      {formattedTotal !== '-' && (
        <div title={hoverGrowth} css={styles.growth(isPositiveGrowth)}>
          {`${prefix}${formattedGrowth}  ${arrow}  (${formatPercent(rate)})`}
        </div>
      )}
    </div>
  );
};
const styles = {
  total: css({
    fontWeight: 600,
    lineHeight: 1.2,
    fontSize: '20px',
    fontStyle: 'normal',
    paddingBottom: '4px',
    fontStretch: 'normal',
    letterSpacing: '-0.3px'
  }),
  growth: (isPositive?: boolean) =>
    css({
      fontWeight: 600,
      lineHeight: 1.38,
      fontSize: '13px',
      fontStyle: 'normal',
      width: 'max-content',
      fontStretch: 'normal',
      whiteSpace: 'pre-wrap',
      letterSpacing: 'normal',
      color: isPositive === undefined ? 'inherit' : isPositive ? THEME.growth.positive : THEME.growth.negative
    })
};
