import { useAllTwitterSimilarInfluencersLazyQuery } from '@/graphql';
import { DEFAULT_SIMILAR_INFLUENCERS_LIMITATION } from '@/shared/constants';
import { formatInfluencerProfile } from '../utils';

interface Props {
  socialAccountId: number;
}

export const useTwitterSimilarInfluencersList = ({ socialAccountId }: Props) => {
  const variables = {
    accountId: socialAccountId,
    limit: DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.LIST.FETCH
  };
  const { data, loading, refetch, getAllTwitterSimilarInfluencers } = useAllTwitterSimilarInfluencersLazyQuery({
    variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  const similarInfluencers = data?.allTwitterSimilarInfluencers || [];

  return {
    loading,
    onRefetchData: refetch,
    limit: variables.limit,
    totalRecords: similarInfluencers.length || 0,
    onFetchData: getAllTwitterSimilarInfluencers,
    listRecords: similarInfluencers?.map(formatInfluencerProfile) || []
  };
};
