import { InstagramStoryPost } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerInstagramAnalyticsStoryDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useInfluencerInstagramStoryPostsListTable } from './hooks';

interface InfluencerInstagramStoryPostsListProps extends TableData<InstagramStoryPost> {
  mainSocialAccountId?: number;
}

export const InfluencerInstagramStoryPostsList = ({
  loading,
  data = [],
  mainSocialAccountId
}: InfluencerInstagramStoryPostsListProps) => {
  const { rows, columns } = useInfluencerInstagramStoryPostsListTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerInstagramAnalyticsStoryDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId }))}
      />
    </>
  );
};
