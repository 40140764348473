import { useTranslation } from 'react-i18next';
import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { AutoManagedJoinedInfluencer } from '@/graphql';
import { useAutoManagedJoinedInfluencersFilter } from '../AutoManagedJoinedInfluencersFilter';
import { useAutoManagedJoinedInfluencersTable } from './hooks';

export type AutoManagedJoinedInfluencersListProps = TableData<AutoManagedJoinedInfluencer>;

export const AutoManagedJoinedInfluencersList = ({ loading, data = [] }: AutoManagedJoinedInfluencersListProps) => {
  const { t } = useTranslation();
  const { filter } = useAutoManagedJoinedInfluencersFilter();
  const { rows, columns } = useAutoManagedJoinedInfluencersTable({ data });

  return (
    <Table
      loading={loading}
      data={{ columns, rows, rowHeight: '66px' }}
      empty={{ description: filter.keyword ? undefined : t('Nobody joined this campaign yet') }}
      noTopBorderRadius
    />
  );
};
