import { useRouter, ParsedLocation } from '@tanstack/react-router';
import { useEffect, useRef } from 'react';

export const useLastLocation = () => {
  const router = useRouter();
  const lastLocation = useRef<ParsedLocation | null>(null);

  useEffect(() => {
    const unsubscribe = router.subscribe('onBeforeLoad', ({ fromLocation }) => {
      if (fromLocation) {
        lastLocation.current = fromLocation;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [router]);

  return {
    lastLocation: lastLocation.current
  };
};
