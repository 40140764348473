import {
  csvIcon,
  excelIcon,
  powerPointIcon,
  spreadsheetIcon,
  googleSlideIcon
} from '@/shared/assets';

interface DownloadSubmenuIconProps {
  icon: 'csv' | 'googleSlide' | 'powerPoint' | 'excel' | 'spreadsheet';
}

export const DownloadSubmenuIcon = ({ icon }: DownloadSubmenuIconProps) => (
  <img src={getDownloadSubmenuIconByName(icon)} css={{ width: '16px', height: '16px', marginRight: '4px' }} alt="" />
);

export const getDownloadSubmenuIconByName = (icon: DownloadSubmenuIconProps['icon']) => {
  switch (icon) {
    case 'csv':
      return csvIcon;
    case 'excel':
      return excelIcon;
    case 'googleSlide':
      return googleSlideIcon;
    case 'powerPoint':
      return powerPointIcon;
    case 'spreadsheet':
      return spreadsheetIcon;

    default:
      return undefined;
  }
};
