import { css } from '@emotion/react';
import { ReactNode, forwardRef } from 'react';
import { THEME } from '@/shared/constants';
import { HelpIcon, Icon } from '../Icon';
import { TextCutter } from '../TextCutter';
import { CircularProgress } from '../Loading';
import { Tooltip, TooltipProps } from '../Tooltip';

export interface CheckBoxProps {
  id?: string;
  size?: string;
  lines?: number;
  help?: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  loading?: boolean | { color?: string; status?: boolean };
  indeterminate?: boolean; // Show minus icon when at least an item selected, else show check icon as default
  label?: ReactNode;
  onClick?: (checked: boolean) => void;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
}

export const CheckBox = forwardRef<HTMLLabelElement, CheckBoxProps>(
  (
    {
      id,
      help,
      lines,
      checked,
      loading,
      onClick,
      children,
      disabled,
      className,
      tooltipProps,
      label = null,
      size = '16px',
      indeterminate
    },
    ref
  ) => {
    const { isLoading, loadingColor } =
      typeof loading === 'boolean'
        ? { isLoading: loading, loadingColor: '#000' }
        : { isLoading: loading?.status, loadingColor: loading?.color || '#000' };
    const isDisabled = disabled || isLoading;

    return (
      <Tooltip id={id} position="top" {...tooltipProps} help={(!isDisabled && tooltipProps?.help) || ''}>
        <label
          id={id}
          ref={ref}
          className={`${className} checkbox-wrapper`}
          css={styles.wrapper(isDisabled)}
          onClick={() => onClick?.(!checked)}
        >
          {isLoading ? (
            <span css={styles.loading(size)}>
              <CircularProgress size={12} thickness={0.3} backgroundColor={loadingColor} />
            </span>
          ) : (
            <div css={styles.iconWrapper(size, checked, isDisabled)} id={id}>
              {checked ? <Icon id={id} icon={indeterminate ? 'minus' : 'check'} size="10px" color="#fff" /> : null}
            </div>
          )}
          {label ? (
            <div css={{ textAlign: 'left', width: '100%', marginLeft: '6px' }} className="checkbox-label">
              {typeof label === 'string' ? <TextCutter id={id} text={label} lines={lines} /> : label}
            </div>
          ) : null}
          {help && <HelpIcon id={id} help={help} css={{ marginLeft: '4px' }} />}
          {children}
        </label>
      </Tooltip>
    );
  }
);
const styles = {
  wrapper: (disabled?: boolean) =>
    css({
      display: 'flex',
      userSelect: 'none',
      fontSize: 'inherit',
      alignItems: 'center',
      pointerEvents: disabled ? 'none' : 'auto',
      cursor: disabled ? 'not-allowed' : 'pointer',
      color: disabled ? THEME.text.colors.gray.lv1 : THEME.text.colors.black.lv1
    }),
  iconWrapper: (size: string, checked?: boolean, disabled?: boolean) =>
    css({
      width: size,
      height: size,
      flex: 'none',
      display: 'flex',
      borderRadius: '3px',
      alignItems: 'center',
      boxSizing: 'border-box',
      justifyContent: 'center',
      backgroundColor: checked
        ? disabled
          ? THEME.background.colors.gray.lv2
          : THEME.background.colors.blue
        : disabled
          ? THEME.background.colors.gray.lv1
          : THEME.background.colors.white,
      ...(!checked
        ? { border: '1px solid #c5d0da', boxShadow: !disabled ? 'inset 1px 1px 2px rgba(0, 0, 0, 0.2)' : 'none' }
        : {})
    }),
  loading: (size: string) =>
    css({
      width: size,
      height: size,
      flex: 'none',
      display: 'flex',
      borderRadius: '3px',
      alignItems: 'center',
      boxSizing: 'border-box',
      justifyContent: 'center',
      border: `1px solid ${THEME.border.colors.gray.lv3}`
    })
};
