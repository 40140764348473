import { EngagementForInsightCheck } from '@/graphql';
import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { usePostsInsightListTable } from './hooks';

export type PostsInsightListProps = TableData<EngagementForInsightCheck>;

export const PostsInsightList = ({ loading, data }: PostsInsightListProps) => {
  const { rows, columns } = usePostsInsightListTable({ data });

  return <Table loading={loading} hasBorder={false} data={{ rows, columns }} noTopBorderRadius />;
};
