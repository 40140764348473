import {
  namedOperations,
  TiktokAdAccountInfo,
  useDisconnectTiktokAdAccountMutation,
  useReconnectTiktokAdAccountMutation
} from '@/graphql';
import { Button, Icon, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';

interface DisconnectAdAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  isDisconnected: boolean;
  onDisconnect: () => void;
  adAccount: TiktokAdAccountInfo;
  hasAccountsLimitWarning?: boolean;
}
export const DisconnectAdAccountModal = ({
  isOpen,
  onClose,
  adAccount,
  onDisconnect,
  isDisconnected,
  hasAccountsLimitWarning
}: DisconnectAdAccountModalProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callDisconnectTiktokAdAccount, loading } = useDisconnectTiktokAdAccountMutation({
    refetchQueries: [namedOperations.Query.TiktokBusinessAdAccounts]
  });
  const { callReconnectTiktokAdAccount, loading: isReconnecting } = useReconnectTiktokAdAccountMutation({
    refetchQueries: [namedOperations.Query.TiktokBusinessAdAccounts]
  });

  const handleReconnect = async () => {
    try {
      await callReconnectTiktokAdAccount({
        variables: {
          input: {
            adAccountId: adAccount.id
          }
        }
      });

      enqueueSnackbar(t('succeededInReconnecting', { name: adAccount.name }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleDisconnect = async () => {
    try {
      await callDisconnectTiktokAdAccount({
        variables: {
          input: {
            adAccountId: adAccount.id
          }
        }
      });

      enqueueSnackbar(t('succeededInDisconnecting', { name: adAccount.name }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
    onClose();
  };

  return (
    <>
      {isDisconnected ? (
        <Button
          variant="white"
          title={t('Button.Reconnect')}
          onClick={handleReconnect}
          loading={isReconnecting}
          css={{ marginLeft: 'auto' }}
          disabled={hasAccountsLimitWarning}
        />
      ) : (
        <Button
          variant="white"
          title={t('Button.Disconnect')}
          onClick={onDisconnect}
          loading={loading}
          css={{ marginLeft: 'auto' }}
        />
      )}
      <Modal
        open={isOpen}
        css={{ width: THEME.modal.size.lv1 }}
        onClose={onClose}
        shouldCloseOnOverlayClick
        hasCloseIcon
      >
        <ModalContent css={{ overflow: 'visible' }}>
          <ModalTitle css={{ display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: 600 }}>
            <Icon size={22} icon="warning" color={THEME.attention.colors.yellow} css={{ marginRight: '8px' }} />
            {t('Dialog.Are you sure to disconnect?')}
          </ModalTitle>
          <p css={{ marginTop: '24px', fontSize: '14px' }}>
            {t('Dialog.This operation cannot be undone, please reconnect if necessary')}
          </p>
        </ModalContent>
        <ModalFooterActions
          cancelButtonProps={{ title: t('Button.Cancel'), onClick: onClose }}
          submitButtonProps={{
            title: t('Button.Disconnect'),
            variant: 'attentionRed',
            loading,
            onClick: handleDisconnect
          }}
        />
      </Modal>
    </>
  );
};
