import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { MarketplaceCampaignPostStatusForSearch, MarketplaceDetail } from '@/graphql';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { DownloadMarketplacePostsButton } from './DownloadMarketplacePostsButton';
import { MarketplacePostsFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  totalRecords?: number;
  onSubmit?: () => void;
  marketplaceId: MarketplaceDetail['id'];
}

export const Template = ({ onSubmit, totalRecords, marketplaceId }: TemplateProps) => {
  const { t } = useTranslation();
  const { isAdvertiser, isAdminStaffAgency } = useUserRoles();

  const statusOptions: Option<MarketplaceCampaignPostStatusForSearch>[] = [
    { label: t<string>('Option.Joined'), value: MarketplaceCampaignPostStatusForSearch.JOINED },
    { label: t<string>('Option.Reviewing'), value: MarketplaceCampaignPostStatusForSearch.DRAFT },
    { label: t<string>('Option.Approved'), value: MarketplaceCampaignPostStatusForSearch.APPROVED },
    { label: t<string>('Option.Warning'), value: MarketplaceCampaignPostStatusForSearch.WARNING },
    { label: t<string>('Option.Ready to Post'), value: MarketplaceCampaignPostStatusForSearch.READY_TO_POST },
    { label: t<string>('Option.Effective'), value: MarketplaceCampaignPostStatusForSearch.EFFECTIVE },
    { label: t<string>('Option.Ineffective'), value: MarketplaceCampaignPostStatusForSearch.INEFFECTIVE }
  ];

  return (
    <BasicFilterWrapper>
      <TextSearchField<MarketplacePostsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.EngagementPostFilter')}
      />

      <SelectorField<MarketplacePostsFilterFormKeys>
        name="status"
        onSubmit={onSubmit}
        options={statusOptions}
        css={{ maxWidth: '188px' }}
        placeholder={t<string>('Selector.Status')}
      />

      {(isAdminStaffAgency || isAdvertiser) && !!totalRecords ? (
        <DownloadMarketplacePostsButton marketplaceId={marketplaceId} />
      ) : null}
    </BasicFilterWrapper>
  );
};
