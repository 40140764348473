import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { InstagramUserHashtag, useInstagramUserHashtagPostListQuery } from '@/graphql';
import { HashtagsAnalyticsFilter, HashtagsAnalyticsPostsList, useHashtagsAnalyticsFilter } from '@/shared/organisms';

interface TemplateProps {
  hashtags: readonly InstagramUserHashtag[];
}

export const Template = ({ hashtags }: TemplateProps) => {
  const { t } = useTranslation();
  const { page, limit, sort, filter } = useHashtagsAnalyticsFilter();
  const { data, loading, previousData } = useInstagramUserHashtagPostListQuery({
    skip: !filter.hashtagId,
    fetchPolicy: 'cache-and-network',
    variables: {
      orderBy: sort,
      hashtagId: Number(filter.hashtagId),
      ...getValuableVariables({
        limit,
        date: filter.date,
        keyword: filter.keyword,
        offset: getOffset(page, limit)
      })
    }
  });

  const result = loading ? previousData : data;
  const hashtagData = result?.instagramUserHashtagPostList;
  const totalRecords = hashtagData?.count;

  return (
    <ListWithPagination css={{ backgroundColor: THEME.background.colors.white }}>
      <div css={{ display: 'flex', gap: '4px', padding: '21px 24px', boxSizing: 'border-box' }}>
        <div css={{ flex: 1 }}>
          <p css={{ fontSize: '18px', fontWeight: 600, lineHeight: '100%' }}>{t('Hashtag tag analysis')}</p>
          <p css={{ fontSize: '14px', marginTop: '8px', lineHeight: '100%' }}>{t('Analyze Instagram hashtags')}</p>
        </div>
      </div>

      <HashtagsAnalyticsFilter hashtags={hashtags} />
      <HashtagsAnalyticsPostsList loading={loading} totalRecords={totalRecords} data={hashtagData?.posts || []} />
    </ListWithPagination>
  );
};
