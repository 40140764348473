import { YoutubeAnalyticsAudiencePayload } from '@/graphql';
import { ChartsContainer } from '@/shared/atoms';
import { AgeBreakdownChart, AreaBreakdownChart, GenderBreakdownChart } from '@/shared/molecules';
import { AnalyticsAudienceWrapper } from '../../../shared';
import { AnalyticsSelectedAccountInfo } from '../../../types';

export interface AnalyticsYoutubeDashboardAudienceTemplateProps {
  loading?: boolean;
  className?: string;
  data?: YoutubeAnalyticsAudiencePayload | null;
  accountAvatar?: AnalyticsSelectedAccountInfo['avatar'];
}

export const AnalyticsYoutubeDashboardAudienceTemplate = ({
  data,
  loading,
  className,
  accountAvatar
}: AnalyticsYoutubeDashboardAudienceTemplateProps) => (
  <AnalyticsAudienceWrapper loading={loading} data={data} className={className}>
    {({ data: { ageRate, genderRate, regionRate } }) => (
      <ChartsContainer css={{ height: '385px' }}>
        <GenderBreakdownChart {...genderRate} />
        <AgeBreakdownChart {...ageRate} />
        <AreaBreakdownChart avatar={accountAvatar} followerRegionRate={regionRate} />
      </ChartsContainer>
    )}
  </AnalyticsAudienceWrapper>
);
