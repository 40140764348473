import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SubscribeAnalyticsPlan.graphql';
import { Mutation, MutationsubscribeAnalyticsPlanArgs } from '../../__generated__/globalTypes';

export const useSubscribeAnalyticsPlanMutation = (
  options?: MutationHookOptions<Mutation, MutationsubscribeAnalyticsPlanArgs>
) => {
  const [callSubscribeAnalyticsPlan, result] = useMutation<Mutation, MutationsubscribeAnalyticsPlanArgs>(
    MUTATION,
    options
  );

  return { callSubscribeAnalyticsPlan, ...result };
};

export type SubscribeAnalyticsPlanMutationFunction = Mutation['subscribeAnalyticsPlan'];
