import { SocialPostType, InfluencerSocialPost } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerPostDetailsModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { useInfluencerScrapingPostsListTable } from './hooks';

export interface InfluencerScrapingPostsListProps extends TableData<InfluencerSocialPost> {
  influencerId?: number;
  socialPostType: SocialPostType;
}

export const InfluencerScrapingPostsList = ({
  loading,
  data = [],
  influencerId,
  socialPostType
}: InfluencerScrapingPostsListProps) => {
  const { rows, columns } = useInfluencerScrapingPostsListTable({ data, socialPostType });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerPostDetailsModal posts={data.map(({ id }) => ({ id: String(id), influencerId }))} />
    </>
  );
};
