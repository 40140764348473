import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllFacebookAccountInfluencersV2.graphql';
import { Query, QueryallFacebookAccountInfluencersV2Args } from '../../__generated__/globalTypes';

export const useAllFacebookAccountInfluencersV2Query = (
  options?: QueryHookOptions<Query, QueryallFacebookAccountInfluencersV2Args>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallFacebookAccountInfluencersV2Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllFacebookAccountInfluencersV2LazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallFacebookAccountInfluencersV2Args>
): QueryResult<Query, QueryallFacebookAccountInfluencersV2Args> & {
  getAllFacebookAccountInfluencersV2: LazyQueryExecFunction<Query, QueryallFacebookAccountInfluencersV2Args>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllFacebookAccountInfluencersV2, result] = useLazyQuery<Query, QueryallFacebookAccountInfluencersV2Args>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getAllFacebookAccountInfluencersV2, ...result };
};

export const useAllFacebookAccountInfluencersV2PromiseQuery = () => {
  const client = useApolloClient();

  const getAllFacebookAccountInfluencersV2 = useCallback(
    (options?: Omit<QueryOptions<QueryallFacebookAccountInfluencersV2Args, Query>, 'query'>) =>
      client.query<Query, QueryallFacebookAccountInfluencersV2Args>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllFacebookAccountInfluencersV2 };
};
