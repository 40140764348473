import { FormProvider } from '@/shared/atoms';
import { SignUpFormSchema } from './schema';
import { Template } from './Template';
import { SignUpFormProps, SignUpFormValues } from './types';

export const SignUpForm = ({
  onSubmit,
  defaultValues = { name: '', email: '', countryId: '', companyUrl: '', companyName: '', phoneNumber: '' }
}: SignUpFormProps) => (
  <FormProvider onSubmit={onSubmit} defaultValues={defaultValues} zodSchema={SignUpFormSchema}>
    <Template />
  </FormProvider>
);

export { type SignUpFormValues };
