import { Navigate } from '@tanstack/react-router';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';
import { useAnalyticsProfileContext } from '../AnalyticsProfileContext';
import { AnalyticsInstagramInteraction } from './AnalyticsInstagramInteraction';

export const AnalyticsInteraction = () => {
  const { selectedSocialAccount } = useAnalyticsProfileContext();

  switch (selectedSocialAccount.socialMedia) {
    case AnalyticsSupportedSocialMediaType.instagram:
      return <AnalyticsInstagramInteraction />;
    default:
      return <Navigate to="/analytics" />;
  }
};
