import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchFilterDefaults } from '@/shared/validations';

export const PackagesFilterSchema = z.object({
  keyword: z.string().optional(),
  countryIds: z.array(z.string()).optional()
});
export const PackagesSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(PackagesFilterSchema, {
    keyword: '',
    countryIds: []
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type PackagesSearchSchemaType = z.infer<typeof PackagesSearchSchema>;
export type PackagesFilterFormValues = z.infer<typeof PackagesFilterSchema>;
export type PackagesFilterFormKeys = UnionOfKeys<PackagesFilterFormValues>;
