import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { SC } from '../StyledComponents';
import { PostInsightFormKeys } from '../types';

export const PostInsightInstagram = () => {
  const { t } = useTranslation();

  return (
    <>
      <SC.SectionWrapper>
        <SC.SectionTitle>{t('Engagements')}</SC.SectionTitle>

        <FormStyle.FieldsGroup itemsPerRow={4} css={{ paddingBottom: '24px' }}>
          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="likes"
            placeholder="10000000"
            title={t('TextForm.Likes')}
          />

          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="comments"
            placeholder="10000000"
            title={t('TextForm.Comments')}
          />

          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="saves"
            placeholder="10000000"
            title={t('TextForm.Save')}
          />

          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="views"
            placeholder="10000000"
            title={t('TextForm.Views')}
          />
        </FormStyle.FieldsGroup>
      </SC.SectionWrapper>

      <SC.SectionWrapper>
        <SC.SectionTitle>{t('Impression')}</SC.SectionTitle>

        <FormStyle.FieldsGroup itemsPerRow={3} css={{ paddingBottom: '24px' }}>
          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="impressions"
            placeholder="10000000"
            title={t('TextForm.Impression')}
          />

          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="10000000"
            name="impressionsFromHome"
            title={t('TextForm.Imp (from Home)')}
          />

          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="10000000"
            name="impressionsFromProfile"
            title={t('TextForm.Imp (from Profile)')}
          />

          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="10000000"
            name="impressionsFromOther"
            title={t('TextForm.Imp (from Other)')}
          />

          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="10000000"
            name="impressionsFromHashtag"
            title={t('TextForm.Imp (from Hashtags)')}
          />

          <SC.TextField<PostInsightFormKeys>
            type="number"
            placeholder="10000000"
            name="impressionsFromDiscovery"
            title={t('TextForm.Imp (from Explore)')}
          />
        </FormStyle.FieldsGroup>
      </SC.SectionWrapper>

      <SC.SectionWrapper css={{ borderBottom: 'none' }}>
        <SC.SectionTitle>{t('General.Reach')}</SC.SectionTitle>

        <FormStyle.FieldsGroup itemsPerRow={3}>
          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="reaches"
            placeholder="10000000"
            title={t('TextForm.Reach total')}
          />
          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="followerReach"
            placeholder="10000000"
            title={t('TextForm.Reach followers')}
          />

          <SC.TextField<PostInsightFormKeys>
            type="number"
            name="nonFollowerReach"
            placeholder="10000000"
            title={t('TextForm.Reach non follower')}
          />
        </FormStyle.FieldsGroup>
      </SC.SectionWrapper>
    </>
  );
};
