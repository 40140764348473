import { useYoutubeAnalyticsTrendPostByIdQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { getYoutubePostUrl, getYoutubeVideoUrl } from '@/shared/utils';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface YoutubeTrendingPostDetailsProps extends Pick<PostDetailsType, 'id'> {
  className?: string;
  onCloseModal?: () => void;
}

export const YoutubeTrendingPostDetails = ({ id, className, onCloseModal }: YoutubeTrendingPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useYoutubeAnalyticsTrendPostByIdQuery({
    skip: !id,
    variables: { id: Number(id) },
    onError: (err) => {
      onCloseModal?.();
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  });

  const details = data?.youtubeAnalyticsTrendPostById;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'YOUTUBE',
        caption: details?.videoCaption,
        postUrl: getYoutubePostUrl(details?.videoId),
        video: { title: details?.videoTitle, url: getYoutubeVideoUrl(details?.videoId) },
        statistics: { likes: details?.likes, views: details?.views, comments: details?.comments },
        info: {
          socialMedia: 'YOUTUBE',
          name: details?.channel,
          postDate: details?.postDate,
          avatar: details?.channelAvatar
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      loading={loading}
      className={className}
      postDetails={postDetails}
      css={{ width: THEME.modal.size.lv3 }}
    />
  );
};
