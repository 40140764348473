import { LocalStorageKey } from '@/shared/types';

export const manageLocalStorage = (method: 'set' | 'get' | 'remove', key: LocalStorageKey, value?: string) => {
  try {
    switch (method) {
      case 'set':
        localStorage.setItem(key, value || '');
        return undefined;
      case 'get':
        return localStorage.getItem(key);
      case 'remove':
        localStorage.removeItem(key);
        return undefined;
      default:
        return undefined;
    }
  } catch (error) {
    console.error(error);

    return undefined;
  }
};

type JsonParseOrStringifyBase = Record<string, any>;

export function jsonParseOrStringify<P extends JsonParseOrStringifyBase>(method: 'stringify', props?: P): string;
export function jsonParseOrStringify<P extends JsonParseOrStringifyBase, R extends JsonParseOrStringifyBase>(
  method: 'parse',
  props?: P,
  onParseErrorCb?: () => void
): R;

export function jsonParseOrStringify<P extends JsonParseOrStringifyBase>(
  method: 'parse' | 'stringify',
  props?: P,
  onParseErrorCb?: () => void
) {
  try {
    if (method === 'parse' && props?.stringifiedData) {
      return JSON.parse(props.stringifiedData);
    } else if (method === 'stringify') {
      return JSON.stringify(props?.params) as string;
    }
  } catch (error) {
    console.error('Error accessing AnalyticsAccountFormState:', error);
    onParseErrorCb?.();
  }
}
