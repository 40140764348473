import { useEngagementTrackingQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { TrackingUrl } from '@/shared/organisms';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';

export const EngagementTrackingUrl = () => {
  const { engagementId } = useEngagementDetailsContext();
  const { data, loading } = useEngagementTrackingQuery({ fetchPolicy: 'cache-first', variables: { engagementId } });

  return (
    <RenderDataWithFallback loading={loading}>
      <TrackingUrl postbackUrl={data?.engagementTracking?.postbackUrl} />
    </RenderDataWithFallback>
  );
};
