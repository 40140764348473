import { AdvertiserUserSettingsDetails } from '@/graphql';

import { SettingsFormValues } from './types';

export const getSettingsFormValues = (data?: AdvertiserUserSettingsDetails | null): SettingsFormValues => ({
  password: '*********', // we don't want to show current password to the user, instead we just show `*****` and disable this field
  name: data?.name || '',
  email: data?.email || '',
  countryId: data?.country.id || '',
  advertiserName: data?.advertiser.name || '',
  advertiserCountryId: data?.country.id || '',
  planType: data?.subscriptionPlan?.type || '',
  notificationSetting: data?.notificationSetting ?? true,
  advertiserCompanyUrl: data?.advertiser.companyUrl || '',
  advertiserPhoneNumber: data?.advertiser.phoneNumber || ''
});
export const defaultSettingsFormValues = getSettingsFormValues();
