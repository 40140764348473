import { useTranslation } from 'react-i18next';
import { MediaPreviewWithMetrics } from '@/shared/atoms';
import { LIMIT_30, THEME } from '@/shared/constants';
import { bigIntFormatter, getValuableVariables } from '@/shared/utils';
import { usePostDetailsParam, useQueryHelper } from '@/shared/hooks';
import { ImageSlider, InfluencerYoutubeAnalyticsPostDetailsModal } from '@/shared/molecules';
import {
  Order,
  YoutubeAnalyticsPostsPayload,
  useYoutubeAnalyticsPostsQuery,
  YoutubeAnalyticsPostsSortOrder
} from '@/graphql';
import { PostDetailsType } from '@/shared/types';
import { AnalyticsFeedPostsWrapper, AnalyticsFeedPostsWrapperProps } from '../../shared';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { useAnalyticsProfileSearch } from '../../hooks';

export interface AnalyticsYoutubeDashboardVideosTemplateProps
  extends Pick<AnalyticsFeedPostsWrapperProps, 'title' | 'loading' | 'help' | 'moreDetailProps'> {
  data?: readonly YoutubeAnalyticsPostsPayload[];
}

export const AnalyticsYoutubeDashboardVideosTemplate = ({
  data = [],
  ...restProps
}: AnalyticsYoutubeDashboardVideosTemplateProps) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  return (
    <AnalyticsFeedPostsWrapper
      title={t('Videos')}
      help={t('Tooltip.VideosHelp')}
      dataNotAvailable={!data.length}
      {...restProps}
    >
      <ImageSlider total={data.length} css={{ paddingLeft: '16px' }}>
        {data.map(({ id, videoThumbnail, videoDuration, videoTitle, likes, views, comments }) => (
          <MediaPreviewWithMetrics
            key={id}
            objectFit="cover"
            title={videoTitle}
            src={videoThumbnail}
            video={{ duration: videoDuration }}
            onClick={() => setPostDetailsParam(id)}
            css={THEME.mediaPreview.hasCaptionOrTitle}
            statistics={[
              { label: t('View'), value: bigIntFormatter(views, 0, { k: true, m: true }) },
              { label: t('Like'), value: bigIntFormatter(likes, 0, { k: true, m: true }) },
              { label: t('Comment'), value: bigIntFormatter(comments, 0, { k: true, m: true }) }
            ]}
          />
        ))}
      </ImageSlider>
    </AnalyticsFeedPostsWrapper>
  );
};

export interface AnalyticsYoutubeDashboardVideosProps {
  brandAccountName?: string;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsYoutubeDashboardVideos = ({
  analyticsAccount,
  brandAccountName
}: AnalyticsYoutubeDashboardVideosProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter, params } = useAnalyticsProfileSearch();
  const { data, loading } = useYoutubeAnalyticsPostsQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      offset: 0,
      limit: LIMIT_30,
      endDate: filter.endDate,
      startDate: filter.startDate,
      youtubeAnalyticsAccountId: analyticsAccount.id,
      orderBy: { field: YoutubeAnalyticsPostsSortOrder.POST_DATE, order: Order.DESC }
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  const posts = data?.youtubeAnalyticsPosts.posts || [];

  return (
    <>
      <AnalyticsYoutubeDashboardVideosTemplate
        data={posts}
        loading={loading}
        moreDetailProps={{
          to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos',
          params,
          search: {
            filter: {
              brandAccountName,
              endDate: filter.endDate,
              startDate: filter.startDate
            }
          }
        }}
      />

      <InfluencerYoutubeAnalyticsPostDetailsModal
        isAnalytics
        posts={posts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: analyticsAccount.id }))}
      />
    </>
  );
};
