import { z } from 'zod';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { FilterAction, UnionOfKeys } from '@/shared/types';
import { CampaignType, CampaignSocialMediaType, CampaignReportOrderField } from '@/graphql';
import { getToday, getThirtyDaysAgo } from '@/shared/utils';

export const ReportFilterSchema = z.object({
  endDate: FilterValidations.string(),
  countryId: FilterValidations.string(),
  startDate: FilterValidations.string(),
  campaigns: FilterValidations.arrayString(),
  campaignCategoryIds: FilterValidations.arrayString(),
  campaignTypes: FilterValidations.arrayEnum(CampaignType),
  socialMedias: FilterValidations.arrayEnum(CampaignSocialMediaType)
});

export const ReportSearchSchema = z.object({
  filter: ReportFilterSchema.default({
    endDate: getToday(),
    startDate: getThirtyDaysAgo(),
    campaignTypes: [CampaignType.ENGAGEMENT, CampaignType.MARKETPLACE]
  }),
  sort: SearchFilterDefaults.sort(CampaignReportOrderField).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type ReportSearchSchemaType = z.infer<typeof ReportSearchSchema>;
export type ReportFilterFormValues = z.infer<typeof ReportFilterSchema>;
export type ReportFilterFormKeys = UnionOfKeys<ReportFilterFormValues>;
export type ReportFilterProps = FilterAction<ReportFilterFormValues>;
