import { useTranslation } from 'react-i18next';

import { useDownloadFile } from '@/shared/hooks';
import { getRestApiUrl, getValuableParams } from '@/shared/utils';

import { PaymentFilterFormValues } from '../types';

interface Props {
  filter: PaymentFilterFormValues;
}

export const useDownloadPayments = ({ filter }: Props) => {
  const { t } = useTranslation();
  const { downloading, handleDownloadFile } = useDownloadFile();

  const downloadPaymentOptions = [
    { label: t<string>('Payment Details'), value: '' },
    { label: t<string>('Payment Breakdown'), value: 'breakdown' },
    ...(filter.countryId === 'JP'
      ? [
          { label: t<string>('JP Bank format'), value: 'jp_format' },
          { label: t<string>('SMBC format'), value: 'smbc_format' }
        ]
      : [])
  ];

  const downloadPayments = async (format = '') => {
    if (!filter.countryId) return;

    await handleDownloadFile({
      filenamePrefix: 'payment',
      url: getRestApiUrl(`payment/${filter.countryId}/csv/${format ? `${format}/` : ''}`),
      params: getValuableParams({
        status: filter.status,
        keyword: filter.keyword,
        maxDueDate: filter.endDate,
        minDueDate: filter.startDate
      })
    });
  };

  return { downloading, downloadPaymentOptions, downloadPayments };
};
