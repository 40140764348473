import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ResetPassword.graphql';
import { Mutation, MutationresetPasswordArgs } from '../../__generated__/globalTypes';

export const useResetPasswordMutation = (options?: MutationHookOptions<Mutation, MutationresetPasswordArgs>) => {
  const [callResetPassword, result] = useMutation<Mutation, MutationresetPasswordArgs>(MUTATION, options);

  return { callResetPassword, ...result };
};

export type ResetPasswordMutationFunction = Mutation['resetPassword'];
