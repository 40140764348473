import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import {
  SocialAccountType,
  useInfluencerAnalyticsTwitterAccessTokenWarningQuery,
  useInfluencerAnalyticsInstagramAccessTokenWarningQuery,
  useInfluencerAnalyticsFacebookPageAccessTokenWarningQuery
} from '@/graphql';
import { Anchor, Notice } from '@/shared/atoms';
import { InfluencerSelectedAccountInfo } from '../types';

export interface InfluencerDisconnectedNoticeProps {
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerDisconnectedNotice = ({
  influencerId,
  socialAccount: { id: socialAccountId, type, analyticsEnabled }
}: InfluencerDisconnectedNoticeProps) => {
  const { t } = useTranslation();
  const { isTalentAgency } = useUserRoles();

  const isInstagram = type === SocialAccountType.INSTAGRAM;

  const { data: igData } = useInfluencerAnalyticsInstagramAccessTokenWarningQuery({
    skip: !analyticsEnabled || !isInstagram,
    fetchPolicy: 'network-only',
    variables: { socialAccountId }
  });
  const { data: fbData } = useInfluencerAnalyticsFacebookPageAccessTokenWarningQuery({
    fetchPolicy: 'network-only',
    variables: { influencerId, socialAccountId },
    skip: !analyticsEnabled || type !== SocialAccountType.FACEBOOK_PAGE
  });
  const { data: xData } = useInfluencerAnalyticsTwitterAccessTokenWarningQuery({
    fetchPolicy: 'network-only',
    variables: { socialAccountId },
    skip: !analyticsEnabled || type !== SocialAccountType.TWITTER
  });

  const { needReconnect, reconnectText } = (() => {
    const needReconnectIg = igData?.influencerAnalyticsInstagramAccessTokenWarning.needReconnect;
    const needReconnectFb = fbData?.influencerAnalyticsFacebookPageAccessTokenWarning.needReconnect;
    const needReconnectX = xData?.influencerAnalyticsTwitterAccessTokenWarning.needReconnect;

    if (needReconnectIg) {
      return { needReconnect: needReconnectIg, reconnectText: t('Your Instagram account is disconnected') };
    }
    if (needReconnectFb) {
      return { needReconnect: needReconnectFb, reconnectText: t('Facebook page account is disconnected') };
    }
    if (needReconnectX) {
      return { needReconnect: needReconnectX, reconnectText: t('X(Twitter) account is disconnected') };
    }

    return { reconnectText: '', needReconnect: false };
  })();

  return analyticsEnabled && needReconnect ? (
    <Notice
      type="warning"
      title={t('Please reconnect')}
      css={{ marginBottom: '16px' }}
      description={
        <>
          {reconnectText}
          {isTalentAgency && isInstagram ? (
            <Anchor
              variant="blue"
              label={t('Please reconnect from here')}
              to="/influencer/$id"
              params={{ id: influencerId.toString() }}
              css={{ textDecoration: 'underline', marginLeft: '8px', display: 'inline-flex' }}
            />
          ) : null}
        </>
      }
    />
  ) : null;
};
