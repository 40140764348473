import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RejectMarketplaceDraftPost.graphql';
import { Mutation, MutationrejectMarketplaceDraftPostArgs } from '../../__generated__/globalTypes';

export const useRejectMarketplaceDraftPostMutation = (
  options?: MutationHookOptions<Mutation, MutationrejectMarketplaceDraftPostArgs>
) => {
  const [callRejectMarketplaceDraftPost, result] = useMutation<Mutation, MutationrejectMarketplaceDraftPostArgs>(
    MUTATION,
    options
  );

  return { callRejectMarketplaceDraftPost, ...result };
};

export type RejectMarketplaceDraftPostMutationFunction = Mutation['rejectMarketplaceDraftPost'];
