import { useTranslation } from 'react-i18next';
import { InfluencerPostSection, InfluencerNewProfileHeaderV2 } from '@/graphql';
import { chatGreenImage, likePinkImage, videoPinkImage, eyeBlueImage } from '@/shared/assets';
import { ChartsContainer } from '@/shared/atoms';
import { PostingHabits, AverageEngagement } from '@/shared/molecules';
import { useInfluencerProfileOpacityContent } from '../../../hooks';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfilePostsOverviewWrapper } from '../../shared';

interface InfluencerYoutubeProfilePostsOverviewProps {
  data?: InfluencerPostSection;
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerYoutubeProfilePostsOverview = ({
  data,
  socialAccount,
  influencerProfile: { id }
}: InfluencerYoutubeProfilePostsOverviewProps) => {
  const { t } = useTranslation();
  const { opacityContent } = useInfluencerProfileOpacityContent({
    socialAccount,
    influencerId: id,
    skipEstimateContent: true
  });

  return (
    <InfluencerProfilePostsOverviewWrapper data={data}>
      <ChartsContainer css={{ height: '324px' }}>
        <AverageEngagement
          data={[
            {
              icon: videoPinkImage,
              label: t('Avg posts/week'),
              value: data?.averagePostsPerWeek || null
            },
            {
              icon: likePinkImage,
              value: data?.averageLikes || null,
              label: t('Avg Likes/latest 30 posts')
            },
            {
              icon: eyeBlueImage,
              value: data?.averageViews || null,
              label: t('Avg Views/latest 30 posts')
            },
            {
              icon: chatGreenImage,
              value: data?.averageComments || null,
              label: t('Avg Comments/latest 30 posts')
            }
          ]}
        />

        <PostingHabits {...opacityContent} data={data?.postsHabit} />
      </ChartsContainer>
    </InfluencerProfilePostsOverviewWrapper>
  );
};
