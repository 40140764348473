import { Modal, ModalProps } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { EngagementPostTrafficSource } from '@/graphql';
import { PostStyles, TrafficSource } from '../../PostDetails';

export interface TemplateProps extends Pick<ModalProps, 'open' | 'onClose'> {
  loading?: boolean;
  engagementCampaignTrafficSource?: readonly EngagementPostTrafficSource[] | null;
}

export const Template = ({ open, onClose, loading, engagementCampaignTrafficSource }: TemplateProps) => {
  const { t } = useQueryHelper();
  
  return (
    <Modal open={open} onClose={onClose} css={{ width: 'max-content' }} hasCloseIcon>
      <PostStyles.Wrapper loading={loading} css={{ height: 'max-content', overflow: 'auto', maxHeight: '800px' }}>
        <TrafficSource
          data={engagementCampaignTrafficSource || []}
          title={t('Total traffic source for this campaign')}
        />
      </PostStyles.Wrapper>
    </Modal>
  );
};
