import { Table } from '@/shared/atoms';
import { InfluencerYoutubeAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useYoutubeCompareVideosTable } from './hooks';
import { InfluencerYoutubeCompareVideosType } from './types';

export interface YoutubeCompareVideosTableProps extends TableData<InfluencerYoutubeCompareVideosType> {
  socialMediaAccountId?: number;
}

export const YoutubeCompareVideosTable = ({
  loading,
  data = [],
  socialMediaAccountId
}: YoutubeCompareVideosTableProps) => {
  const { rows, columns } = useYoutubeCompareVideosTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerYoutubeAnalyticsPostDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId: socialMediaAccountId }))}
      />
    </>
  );
};
