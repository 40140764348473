import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useInfluencerPermissions, usePaymentPermissions, useUserRoles } from '@/auth';
import { Payment, PaymentRequestStatus } from '@/graphql';
import { checkIconImage, closeIconImage, checkIconGrayImage, closeIconGrayImage } from '@/shared/assets';
import { ColumnProps, RowProps, Status, StatusProps, TableCell } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { ColorVariant } from '@/shared/types';
import { formatNumber } from '@/shared/utils';

interface Props {
  data: readonly Payment[];
  onSelectedPayment: (payment: { id: number; name: string; status: PaymentRequestStatus }) => void;
}

export const usePaymentsTable = ({ data, onSelectedPayment }: Props) => {
  const { t } = useTranslation();
  const { isAdmin } = useUserRoles();
  const { hideSelectInfluencers } = useInfluencerPermissions();
  const { hideFailedBtn, hidePaidBtn } = usePaymentPermissions();
  const { generateProfileLink } = useGenerateInfluencerProfileLink({ disabled: hideSelectInfluencers });

  const getStatusColumnProps = (status: PaymentRequestStatus): StatusProps => {
    switch (status) {
      case PaymentRequestStatus.PAID:
        return { label: t('Option.Paid'), variant: ColorVariant.DARK_GREY };
      case PaymentRequestStatus.FAILED:
        return { label: t('Option.Failed'), variant: ColorVariant.DARK_RED };
      case PaymentRequestStatus.REJECTED:
        return { label: t('Option.Rejected'), variant: ColorVariant.DARK_YELLOW };
      case PaymentRequestStatus.REVIEWING:
        return { label: t('Option.Reviewing'), variant: ColorVariant.DARK_PURPLE };
      default:
        return { label: '', variant: ColorVariant.DARK_GREY };
    }
  };

  const hasPaidFailed = isAdmin && !hideFailedBtn && !hidePaidBtn;
  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Influencer') },
    { title: t('HeaderColumn.Email Address') },
    { title: t('HeaderColumn.Status') },
    { title: t('HeaderColumn.Revenue'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Fee'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Amount'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Finalized Date') },
    { title: t('HeaderColumn.Due Date') },
    { title: t('HeaderColumn.Paid Date') },
    { title: t('HeaderColumn.Paid'), styles: { textAlign: 'right' }, hidden: !hasPaidFailed },
    { title: t('HeaderColumn.Failed'), styles: { textAlign: 'right' }, hidden: !hasPaidFailed }
  ];
  const rows: RowProps[] = data.map(
    ({ id, fee, status, dueDate, paidDate, currency, netRevenue, influencer, grossRevenue, requestedDate }) => {
      const disabledPaidFailedColumn = status !== 'REVIEWING';

      return [
        <TableCell.Link label={influencer.name} {...generateProfileLink(influencer.id)} />,
        <TableCell.Text value={influencer.email} css={{ minWidth: '100px' }} />,
        <Status {...getStatusColumnProps(status)} />,
        <TableCell.Number value={formatNumber(grossRevenue)} unit={t(currency)} />,
        <TableCell.Number value={formatNumber(fee)} unit={t(currency)} />,
        <TableCell.Number value={formatNumber(netRevenue)} unit={t(currency)} />,
        <TableCell.Date value={requestedDate} />,
        <TableCell.Date value={dueDate} />,
        <TableCell.Date value={paidDate || ''} />,
        <img
          css={styles.icon(disabledPaidFailedColumn)}
          src={disabledPaidFailedColumn ? checkIconGrayImage : checkIconImage}
          onClick={() => onSelectedPayment({ id, name: influencer.name, status: PaymentRequestStatus.PAID })}
          alt="Paid"
        />,
        <img
          css={styles.icon(disabledPaidFailedColumn)}
          src={disabledPaidFailedColumn ? closeIconGrayImage : closeIconImage}
          onClick={() => onSelectedPayment({ id, name: influencer.name, status: PaymentRequestStatus.FAILED })}
          alt="Failed"
        />
      ];
    }
  );

  return { rows, columns };
};
const styles = {
  icon: (disabled?: boolean) =>
    css({
      width: '22px',
      padding: '4px',
      margin: '0 auto',
      cursor: 'pointer',
      borderRadius: '3px',
      border: THEME.border.base,
      backgroundColor: THEME.background.colors.white,
      ...(disabled ? { pointerEvents: 'none', backgroundColor: '#dee5ec' } : {})
    })
};
