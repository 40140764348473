import { useTranslation } from 'react-i18next';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { useYoutubeComparePostListQuery } from '@/graphql';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { AnalyticsYoutubeCompareVideosList, ProfilePostsCount, ProfilePostsFilter } from '@/shared/organisms';
import { AnalyticsYoutubeCompareSearchSchemaType } from './schemaTypes';

interface CompareAccountTemplateProps {
  mainSocialAccountId: number;
}

export const CompareAccountTemplate = ({ mainSocialAccountId }: CompareAccountTemplateProps) => {
  const { t } = useTranslation();
  const {
    page,
    sort,
    limit,
    setFilter,
    filter: { accountId, endDate, startDate, keyword }
  } = useParamsSearch<AnalyticsYoutubeCompareSearchSchemaType>();
  const { data, previousData, loading } = useYoutubeComparePostListQuery({
    skip: !accountId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      keyword,
      endDate,
      startDate,
      orderBy: sort,
      mainAccountId: mainSocialAccountId,
      offset: getOffset(page, limit),
      compareAccountId: Number(accountId)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.youtubeComparePostsCount.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter
        css={{ borderRadius: 0 }}
        filter={{ keyword, endDate, startDate }}
        setFilter={(values) => setFilter({ ...values, accountId })}
        hasPeriod
      />

      <ProfilePostsCount count={totalRecords} countLabel={t('Videos found')} />

      <AnalyticsYoutubeCompareVideosList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.youtubeComparePostList.posts || []}
      />
    </ListWithPagination>
  );
};
