import { z } from 'zod';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export const CheckingPlanSubscriptionFilterSchema = z.object({
  priceId: FilterValidations.number(),
  packageId: FilterValidations.number(),
  sessionId: FilterValidations.string()
});
export const CheckingPlanSubscriptionSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(CheckingPlanSubscriptionFilterSchema, {
    priceId: '',
    packageId: '',
    sessionId: ''
  })
});

export type CheckingPlanSubscriptionFilterSchemaType = z.infer<typeof CheckingPlanSubscriptionFilterSchema>;
export type CheckingPlanSubscriptionSearchSchemaType = z.infer<typeof CheckingPlanSubscriptionSearchSchema>;
