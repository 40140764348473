import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useUserRoles } from '@/auth';
import { Option } from '@/shared/types';
import { SelectorField } from '@/shared/molecules';
import { getSocialMediaText } from '@/shared/utils';
import { FormStyle, SocialIcon } from '@/shared/atoms';
import { EngagementDetail, EngagementPostSocialAccount } from '@/graphql';
import { useEngagementPostFormState } from '../hooks';
import { EngagementPostFormKeys, EngagementPostFormValues } from '../../types';

interface InfluencerAccountFieldsProps {
  engagementId: EngagementDetail['id'];
}

export const InfluencerAccountFields = ({ engagementId }: InfluencerAccountFieldsProps) => {
  const { t } = useTranslation();
  const { isAdvertiser } = useUserRoles();
  const { setValue } = useFormContext<EngagementPostFormValues>();
  const { influencerOptions, availableSocialAccounts } = useEngagementPostFormState({ engagementId });

  const socialAccountOptions =
    availableSocialAccounts?.map<Option & Pick<EngagementPostSocialAccount, 'id' | 'socialMedia'>>(
      ({ name, socialMedia, id }) => ({
        id,
        socialMedia,
        value: `${id}-${socialMedia}`,
        label: name || getSocialMediaText(socialMedia),
        prefixIcon: <SocialIcon size={16} type={socialMedia} css={{ marginRight: '8px', display: 'inline-block' }} />
      })
    ) || [];

  return (
    <FormStyle.FieldsGroup itemsPerRow={2}>
      <SelectorField<EngagementPostFormKeys>
        title={t('Selector.Influencer')}
        name="influencerId"
        disabled={isAdvertiser}
        options={influencerOptions}
        onChange={(field, value) => {
          setValue(field, value, { shouldValidate: true });
          setValue('selectedAccount', '');
          setValue('socialAccountId', '');
          setValue('socialAccountMedia', '');
        }}
        required
      />

      <SelectorField<EngagementPostFormKeys>
        name="selectedAccount"
        disabled={isAdvertiser}
        title={t('Selector.Select Social Account')}
        options={socialAccountOptions}
        onChange={(field, value) => {
          const [id, socialMedia] = value.split('-');
          setValue(field, value, { shouldValidate: true });
          setValue('socialAccountId', id || '');
          setValue('socialAccountMedia', socialMedia || '');
        }}
        required
      />
    </FormStyle.FieldsGroup>
  );
};
