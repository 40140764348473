import React, { ReactNode } from 'react';
import { NoDataContentWrapper } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { ImageSlider } from '@/shared/molecules';

interface PostType {
  id: number | null;
  likes?: number | null;
  comments?: number | null;
  thumbNail?: string | null;
}

export interface AnalyticsComparePostsSliderProps<T extends PostType> {
  posts?: readonly T[];
  className?: string;
  renderPost: (post: T) => ReactNode;
}

export const AnalyticsComparePostsSlider = <T extends PostType>({
  className,
  posts = [],
  renderPost
}: AnalyticsComparePostsSliderProps<T>) => {
  const hasNoPost = !posts.length;

  return (
    <div css={{ minHeight: '192px', position: 'relative', borderTop: THEME.border.base }} className={className}>
      <NoDataContentWrapper
        dataNotAvailable={hasNoPost}
        css={[
          {
            width: '100%',
            height: 'unset',
            backgroundColor: 'transparent',
            minHeight: hasNoPost ? 'inherit' : 'calc(100% - 32px)'
          },
          // We need to use `absolute` here, hard to control table width with element has dynamic width as ImageSlider
          !hasNoPost && { top: 0, left: 0, position: 'absolute' }
        ]}
      >
        {posts ? (
          <ImageSlider total={posts.length} css={{ padding: '16px' }}>
            {posts.map((post) => (
              <React.Fragment key={post.id}>{renderPost(post)}</React.Fragment>
            ))}
          </ImageSlider>
        ) : null}
      </NoDataContentWrapper>
    </div>
  );
};
