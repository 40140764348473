import { useTranslation } from 'react-i18next';
import { Table } from '@/shared/atoms';
import { useAnalyticsPermissions } from '@/auth';
import { ConfirmDeleteModal, SocialReconnectModal } from '@/shared/molecules';
import { AnalyticsListProps } from './types';
import { useAnalyticsDeleteAccount, useAnalyticsListTable, useAnalyticsReconnectAccount } from './hooks';

export const AnalyticsList = ({ loading, data = [] }: AnalyticsListProps) => {
  const { t } = useTranslation();
  const { hideAddAnalyticsBtn } = useAnalyticsPermissions();
  const { deleting, deletingAccount, setDeletingAccount, resetDeletingAccount, handleDeleteAccount } =
    useAnalyticsDeleteAccount();
  const {
    reconnected,
    reconnecting,
    reconnectingAccount,
    setReconnectingAccount,
    handleReconnectAccount,
    resetReconnectingAccount
  } = useAnalyticsReconnectAccount();
  const { rows, columns } = useAnalyticsListTable({
    data,
    onDelete: setDeletingAccount,
    onReconnectAccount: setReconnectingAccount
  });

  return (
    <>
      <Table
        loading={loading}
        data={{ rows, columns }}
        empty={{
          linkProps: !hideAddAnalyticsBtn ? { to: '/analytics/add', label: t('Analytics.AddAnAnalytics') } : undefined
        }}
        noTopBorderRadius
      />

      <SocialReconnectModal
        reconnected={reconnected}
        reconnecting={reconnecting}
        open={!!reconnectingAccount}
        onClose={resetReconnectingAccount}
        onReconnect={handleReconnectAccount}
        socialMedia={reconnectingAccount?.socialAccountType}
        accounts={[
          ...(reconnectingAccount ? [reconnectingAccount] : []),
          ...(reconnectingAccount?.linkedAccount ? [reconnectingAccount?.linkedAccount] : [])
        ].map((acc) => ({ name: acc.socialAccountName, type: acc.socialAccountType }))}
      />

      <ConfirmDeleteModal
        deleting={deleting}
        onClose={resetDeletingAccount}
        deletingItem={deletingAccount}
        onDelete={handleDeleteAccount}
      />
    </>
  );
};

export * from './utils';
