import { APP_NAME } from '@/shared/constants';

import { SC } from './StyledComponents';

export const PrivacyJP = () => {
  const links = {
    app: (
      <SC.Link target="_blank" variant="inherit" href="https://app.anytag.jp/">
        https://app.anytag.jp/
      </SC.Link>
    ),
    anymind: (
      <SC.Link target="_blank" variant="inherit" href="mailto:at-jp@anymindgroup.com">
        at-jp@anymindgroup.com
      </SC.Link>
    )
  };

  return (
    <SC.Wrapper>
      <SC.Title>{APP_NAME}プライバシーポリシー</SC.Title>

      <SC.Text css={{ marginTop: '40px' }}>
        AnyMind Group株式会社（以下「当社」といいます。）は、当社の提供するサービスAnyTag（URL：&nbsp;
        {links.app}
        &nbsp;、以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
      </SC.Text>

      <SC.Head>1. 収集する利用者情報及び収集方法</SC.Head>
      <SC.Text>
        本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。
        本サービスにおいて当社が収集する利用者情報は、以下のものとなります。
      </SC.Text>
      <SC.Text>
        <SC.WrapperContent>
          <p>1</p>
          <SC.RightContent>
            ユーザーからご提供いただく情報
            <br />
            本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。
            <SC.ListWrapper>
              <SC.ListItem>氏名（法人の場合は、法人名及び担当者の氏名）</SC.ListItem>
              <SC.ListItem>メールアドレス、電話番号、住所等連絡先に関する情報</SC.ListItem>
              <SC.ListItem>入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報</SC.ListItem>
            </SC.ListWrapper>
          </SC.RightContent>
        </SC.WrapperContent>

        <SC.WrapperContent>
          <p>2</p>
          <SC.RightContent>
            ユーザーが本サービスを利用するにあたって、当社が収集する情報
            <br />
            当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがありま
            <br />
            す。これには以下の情報が含まれます。
            <SC.ListWrapper>
              <SC.ListItem>リファラ</SC.ListItem>
              <SC.ListItem>IPアドレス</SC.ListItem>
              <SC.ListItem>デバイスID</SC.ListItem>
              <SC.ListItem>位置データ</SC.ListItem>
              <SC.ListItem>ブラウザーの種類とバージョン等のコンピューター情報と接続情報</SC.ListItem>
              <SC.ListItem>タイムゾーン（時間帯）設定情報</SC.ListItem>
              <SC.ListItem>ブラウザーのプラグインの種類とバージョン</SC.ListItem>
              <SC.ListItem>オペレーティングシステム</SC.ListItem>
              <SC.ListItem>サーバーアクセスログに関する情報</SC.ListItem>
              <SC.ListItem>Cookie、ADID、IDFAその他の識別子</SC.ListItem>
            </SC.ListWrapper>
          </SC.RightContent>
        </SC.WrapperContent>
      </SC.Text>

      <SC.Head>2. 利用目的</SC.Head>
      <SC.Text>本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。</SC.Text>
      <SC.Text>
        <SC.ListWrapper>
          <SC.ListItem>
            (1)
            本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため
          </SC.ListItem>
          <SC.ListItem>(2) 本サービスに関する当社ウェブサイトの機能改善のため</SC.ListItem>
          <SC.ListItem>(3) ユーザー毎にカスタマイズされた広告の表示及び効果測定のため</SC.ListItem>
          <SC.ListItem>(4) 本サービスに関するご案内、お問い合わせ等への対応のため</SC.ListItem>
          <SC.ListItem>(5) 本サービスに関する規約等の変更などを通知するため</SC.ListItem>
        </SC.ListWrapper>
      </SC.Text>

      <SC.Head>3. 利用者情報の保護について</SC.Head>
      <SC.Text>
        当社は、法令または業界団体の一般的基準に基づいて、利用者情報の保護に努めますが、法令又は業界団体の一般的基準に基づく努力によっても防ぐことのできない利用者情報への不正アクセス等については、当社は責任を負いませんのでご了承ください。
      </SC.Text>

      <SC.Head>4. 利用中止要請の方法</SC.Head>
      <SC.Text>
        ユーザーは、当社に対して電子メールを送信する（宛先：&nbsp;
        {links.anymind}
        ）ことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。
      </SC.Text>

      <SC.Head>5. 第三者提供</SC.Head>
      <SC.Text>
        当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。但し、当社の親会社、最終親会社、子会社及び関連会社を除きます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
      </SC.Text>
      <SC.Text>
        <SC.ListWrapper>
          <SC.ListItem>
            (1) 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
          </SC.ListItem>
          <SC.ListItem>(2) 合併その他の事由による事業の承継に伴って個人情報が提供される場合</SC.ListItem>
          <SC.ListItem>
            (3)
            国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
          </SC.ListItem>
          <SC.ListItem>
            (4) その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
          </SC.ListItem>
        </SC.ListWrapper>
      </SC.Text>

      <SC.Head>6. 個人情報の開示</SC.Head>
      <SC.Text>
        当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。
      </SC.Text>

      <SC.Head>7. 個人情報の訂正及び利用停止等</SC.Head>
      <SC.Text>
        <SC.WrapperContent>
          <p>7-1</p>
          <SC.RightContent>
            当社は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
          </SC.RightContent>
        </SC.WrapperContent>
        <SC.WrapperContent css={{ marginTop: '8px' }}>
          <p>7-2</p>
          <SC.RightContent>
            当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
          </SC.RightContent>
        </SC.WrapperContent>
        <SC.WrapperContent css={{ marginTop: '8px' }}>
          <p>7-3</p>
          <SC.RightContent>
            個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、7-1および7-2の規定は適用されません。
          </SC.RightContent>
        </SC.WrapperContent>
      </SC.Text>

      <SC.Head>8. お問い合わせ窓口</SC.Head>
      <SC.Text>
        ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。
      </SC.Text>
      <SC.Text>
        住所：〒106-0046
        <br />
        AnyMind Japan株式会社
        <br />
        個人情報取扱責任者：小堤 音彦
        <br />
        連絡先：03-6384-5540
      </SC.Text>

      <SC.Head>9. プライバシーポリシーの変更手続</SC.Head>
      <SC.Text>
        当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知するものとし、施行日以降も本サービスの利用を継続した場合には、変更後の本ポリシーに同意したものとみなします。
      </SC.Text>

      <SC.Text css={{ marginTop: '40px' }}>【2021年4月1日制定】</SC.Text>
    </SC.Wrapper>
  );
};
