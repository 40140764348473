import { TableSegmentOption } from '@/shared/atoms';
import { convertOrderToORDER, getValuableVariables } from '@/shared/utils';
import { TikTokSpecialCampaignDetail, useTiktokSpecialCampaignReportQuery } from '@/graphql';
import {
  PostReportSegmentKeys,
  useTikTokCampaignReportFilter,
  useTikTokCampaignReportContext
} from '@/shared/organisms';

interface Props {
  tiktokSpecialCampaign: TikTokSpecialCampaignDetail;
}
export const useTikTokCampaignReportDetailsData = ({ tiktokSpecialCampaign }: Props) => {
  const { segmentStatus, setSegmentStatus } = useTikTokCampaignReportContext();
  const {
    sort,
    filter: { keyword, endDate, startDate }
  } = useTikTokCampaignReportFilter();
  const { data, previousData, loading, networkStatus } = useTiktokSpecialCampaignReportQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      keyword,
      endDate,
      startDate,
      campaignId: tiktokSpecialCampaign.id,
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) }
    })
  });

  const result = loading ? previousData : data;
  const reportData = result?.tiktokSpecialCampaignReport || undefined;

  const handleSegmentChange = ({ key, value }: TableSegmentOption<PostReportSegmentKeys>) => {
    setSegmentStatus((prevState) => ({ ...prevState, [key]: !value }));
  };

  return {
    loading,
    reportData,
    networkStatus,
    segmentStatus,
    handleSegmentChange
  };
};
