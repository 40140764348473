import { YoutubeAnalyticsTrendPayload } from '@/graphql';
import { Table } from '@/shared/atoms';
import { YoutubeTrendPostDetailsModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { useAnalyticsTrendingYoutubePostsTable } from './hooks';

export type AnalyticsTrendingYoutubePostsListProps = TableData<YoutubeAnalyticsTrendPayload>;

export const AnalyticsTrendingYoutubePostsList = ({ loading, data = [] }: AnalyticsTrendingYoutubePostsListProps) => {
  const { rows, columns } = useAnalyticsTrendingYoutubePostsTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '130px' }} noTopBorderRadius hasBorder />
      <YoutubeTrendPostDetailsModal posts={data.map(({ id }) => ({ id: String(id) }))} />
    </>
  );
};
