import { useFormContext } from 'react-hook-form';
import { EngagementDetail } from '@/graphql';
import { useEngagementPostInfluencersOptions } from '@/shared/hooks';
import { EngagementPostFormValues } from '../../types';

interface Props {
  engagementId: EngagementDetail['id'];
}

export const useEngagementPostFormState = ({ engagementId }: Props) => {
  const { watch } = useFormContext<EngagementPostFormValues>();
  const { influencers, influencerOptions } = useEngagementPostInfluencersOptions({ engagementId });

  const [influencerIdValue, socialAccountIdValue, socialAccountMediaValue] = watch([
    'influencerId',
    'socialAccountId',
    'socialAccountMedia'
  ]);
  const availableSocialAccounts =
    influencers?.find((account) => account.id === Number(influencerIdValue))?.socialAccounts || [];
  const selectedSocialAccount = availableSocialAccounts.find(
    (acc) => acc.id === Number(socialAccountIdValue) && acc.socialMedia === socialAccountMediaValue
  );

  return { influencers, influencerOptions, selectedSocialAccount, availableSocialAccounts };
};
