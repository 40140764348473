import { TiktokInfluencerProfile, TiktokInfluencers } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSocialAccountSelectButtonProps } from './hooks';

export const TiktokInfluencersSearch = () => {
  const { getSortSelectButtonProps, getListSelectButtonProps } =
    useSocialAccountSelectButtonProps<TiktokInfluencerProfile>({
      socialMedia: InfluencersTabType.TIKTOK
    });

  return (
    <TiktokInfluencers
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
