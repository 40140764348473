import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TwitterAnalyticsOverview.graphql';
import { Query, QuerytwitterAnalyticsOverviewArgs } from '../../__generated__/globalTypes';

export const useTwitterAnalyticsOverviewQuery = (
  options?: QueryHookOptions<Query, QuerytwitterAnalyticsOverviewArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytwitterAnalyticsOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTwitterAnalyticsOverviewLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytwitterAnalyticsOverviewArgs>
): QueryResult<Query, QuerytwitterAnalyticsOverviewArgs> & {
  getTwitterAnalyticsOverview: LazyQueryExecFunction<Query, QuerytwitterAnalyticsOverviewArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTwitterAnalyticsOverview, result] = useLazyQuery<Query, QuerytwitterAnalyticsOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTwitterAnalyticsOverview, ...result };
};

export const useTwitterAnalyticsOverviewPromiseQuery = () => {
  const client = useApolloClient();

  const getTwitterAnalyticsOverview = useCallback(
    (options?: Omit<QueryOptions<QuerytwitterAnalyticsOverviewArgs, Query>, 'query'>) =>
      client.query<Query, QuerytwitterAnalyticsOverviewArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTwitterAnalyticsOverview };
};
