import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { AnalyticsAdvertiserPlanFilter, NetSuiteVerifiedStatus, TikTokAdvertiserPlanFilter } from '@/graphql';

export const AccountFilterSchema = z.object({
  keyword: FilterValidations.string(),
  countryIds: FilterValidations.arrayString(),
  verifiedStatus: FilterValidations.enum(NetSuiteVerifiedStatus),
  tiktokPlan: FilterValidations.enum(TikTokAdvertiserPlanFilter),
  analyticsPlan: FilterValidations.enum(AnalyticsAdvertiserPlanFilter)
});
export const AccountFilterSearchSchema = z.object({
  filter: AccountFilterSchema.default(AccountFilterSchema.parse({})),
  ...SearchFilterDefaults.pageLimit()
});

export type AccountFilterSearchSchemaType = z.infer<typeof AccountFilterSearchSchema>;
export type AccountFilterFormValues = z.infer<typeof AccountFilterSchema>;
export type AccountFilterFormKeys = UnionOfKeys<AccountFilterFormValues>;
