import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { useTableMaxHeight } from '@/shared/hooks';
import { YoutubeAnalyticsTrendPayload } from '@/graphql';
import { YoutubeTrendPostDetailsModal } from '@/shared/molecules';
import { TrendingPostsTabType } from '../TrendingPostsFilter';
import { useTrendingPostsTable } from './hooks';

export interface TrendingPostsListProps extends TableData<YoutubeAnalyticsTrendPayload> {
  tab: TrendingPostsTabType;
}

export const TrendingPostsList = ({ tab, loading, data = [] }: TrendingPostsListProps) => {
  const { rows, columns } = useTrendingPostsTable({ tab, data });
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();

  return (
    <>
      <Table
        loading={loading}
        data={{ rows, columns }}
        noTopBorderRadius
        stickyHeader
        hasBorder
        ref={offsetRef}
        maxHeight={defaultTableMaxHeight}
      />

      <YoutubeTrendPostDetailsModal posts={data.map(({ id }) => ({ id: String(id) }))} />
    </>
  );
};
