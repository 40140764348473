import { useTranslation } from 'react-i18next';
import { WarningModal, WarningModalProps } from '../WarningModal';

export interface ConfirmDeleteModalProps extends Omit<WarningModalProps, 'open' | 'description'> {
  deleting?: boolean;
  onDelete?: () => void;
  deletingItem?: { id: number; name: string } | null;
}

export const ConfirmDeleteModal = ({
  onClose,
  deleting,
  onDelete,
  deletingItem,
  ...restProps
}: ConfirmDeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <WarningModal
      {...restProps}
      onClose={onClose}
      open={!!deletingItem}
      description={`${t('Dialog.Are you sure you want to delete', { name: deletingItem?.name })}?`}
      footerProps={{
        cancelButtonProps: { title: t('Button.Cancel'), onClick: onClose },
        submitButtonProps: { loading: deleting, title: t('Button.Delete'), onClick: onDelete }
      }}
    />
  );
};
