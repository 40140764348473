import { useParams } from '@tanstack/react-router';
import { FormAction } from '@/shared/types';
import { getRestApiUrl } from '@/shared/utils';
import { useToggleState, useDownloadFile } from '@/shared/hooks';
import {
  getDownloadCSVRangeRows,
  DownloadCsvPagesAndRowsModal,
  DownloadCsvPagesAndRowsModalValues
} from '@/shared/molecules';
import { useYoutubeCMSFilter } from '../../hooks';
import { YoutubeCMSSegmentMode } from '../../types';

export const useDownloadYoutubeCMSCsv = () => {
  const params = useParams({ strict: false });
  const csvModal = useToggleState();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const {
    limit,
    filter: { segment, keyword, month }
  } = useYoutubeCMSFilter();

  const cmsChannelId = params?.cmsChannelId || '';
  const isSegmentAssets = segment === YoutubeCMSSegmentMode.ASSETS;

  const handleDownloadCsv: FormAction<DownloadCsvPagesAndRowsModalValues>['onSubmit'] = async (formValues) => {
    const { firstRow, lastRow } = getDownloadCSVRangeRows(formValues, limit);

    await handleDownloadFile({
      method: 'GET',
      filenamePrefix: `youtube-cms-${new Date().toUTCString()}`,
      url: getRestApiUrl(isSegmentAssets ? 'cms/assets/csv/' : 'cms/channels/csv/'),
      params: { firstRow, lastRow, keyword, month, ...(isSegmentAssets && cmsChannelId ? { cmsChannelId } : {}) }
    });

    csvModal.close();
  };

  return {
    openDownloadModal: csvModal.open,
    DownloadCsvModal: (
      <DownloadCsvPagesAndRowsModal
        limit={limit}
        maxRows={1000}
        open={csvModal.status}
        onClose={csvModal.close}
        downloading={downloading}
        onSubmit={handleDownloadCsv}
      />
    )
  };
};
