import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AllowEngagementInfluencerProposalList.graphql';
import { Mutation, MutationallowEngagementInfluencerProposalListArgs } from '../../__generated__/globalTypes';

export const useAllowEngagementInfluencerProposalListMutation = (
  options?: MutationHookOptions<Mutation, MutationallowEngagementInfluencerProposalListArgs>
) => {
  const [callAllowEngagementInfluencerProposalList, result] = useMutation<
    Mutation,
    MutationallowEngagementInfluencerProposalListArgs
  >(MUTATION, options);

  return { callAllowEngagementInfluencerProposalList, ...result };
};

export type AllowEngagementInfluencerProposalListMutationFunction = Mutation['allowEngagementInfluencerProposalList'];
