import { FormAction } from '@/shared/types';
import { FormProvider } from '@/shared/atoms';
import { Template, TemplateProps } from './Template';
import { TikTokCampaignSchema } from './schema';
import { TikTokCampaignFormValues } from './types';
import { defaultTikTokCampaignFormValues } from './utils';

export type TikTokCampaignFormProps = FormAction<TikTokCampaignFormValues> & Omit<TemplateProps, 'onSubmit'>;

export const TikTokCampaignForm = ({ onSubmit, defaultValues, ...templateProps }: TikTokCampaignFormProps) => (
  <FormProvider<TikTokCampaignFormValues>
    onSubmit={onSubmit}
    zodSchema={TikTokCampaignSchema}
    values={{ ...defaultTikTokCampaignFormValues, ...defaultValues }}
  >
    <Template {...templateProps} />
  </FormProvider>
);

export * from './types';
export * from './utils';
