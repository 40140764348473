import { BcaRequestStatus } from '@/graphql';
import { ColorVariant } from '@/shared/types';
import { BrandContentPermissionsType } from './types';

export const getBrandContentPermissionsStatusInfo = (status: BcaRequestStatus | null) => {
  switch (status) {
    case BcaRequestStatus.APPROVED:
      return { message: 'Approved', variant: ColorVariant.LIGHT_GREEN };
    case BcaRequestStatus.REQUESTED:
      return { message: 'Requested', variant: ColorVariant.LIGHT_PURPLE };
    case BcaRequestStatus.REVOKED:
      return { message: 'Rejected', variant: ColorVariant.LIGHT_GREY };
    default:
      return { message: '', variant: ColorVariant.LIGHT_CYAN };
  }
};

export const EMPTY_BRAND_CONTENT_FIELD: BrandContentPermissionsType = {
  instagram_account_id: '',
  instagram_account_name: '',
  brand_id: '',
  status: null,
  isResendable: null,
  disabled: false
};
