import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { RowProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useInfluencerPermissions } from '@/auth';
import { usePostDetailsParam } from '@/shared/hooks';
import { CampaignPost, CampaignPostMetrics } from '@/graphql';
import { PostDiscoveryFirstColumn, getStatCells } from '../utils';
import { useAllCampaignPostsListColumns } from './useAllCampaignPostsListColumns';

interface Props {
  total?: number;
  currencyId?: string;
  average?: CampaignPostMetrics;
  summaries?: CampaignPostMetrics;
  postDiscoveryData: readonly CampaignPost[];
}

export const useAllCampaignPostsListTable = ({ total, average, summaries, currencyId, postDiscoveryData }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();
  const { hideViewInfluencerProfile } = useInfluencerPermissions();
  const { columns, selectedColumns } = useAllCampaignPostsListColumns();

  const totalRows: RowProps[] = [
    {
      styles: styles.totalRow,
      cells: [
        <div css={{ minWidth: '290px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span css={{ fontSize: '14px', fontWeight: 600 }}>{t('HeaderColumn.Total')}</span>
          <span>{total}</span>
        </div>,
        ...getStatCells(summaries, currencyId)
      ]
    },
    {
      styles: styles.averageRow,
      cells: [
        <div css={{ fontSize: '12px', fontWeight: 600 }}>{t('Average')}</div>,
        ...getStatCells(average, currencyId)
      ]
    }
  ];

  const campaignRows: RowProps[] = postDiscoveryData.map((stats) => [
    <PostDiscoveryFirstColumn
      avatar={stats.avatar}
      content={stats.content}
      thumbnail={stats.thumbnail || ''}
      onPostClick={() => {
        setPostDetailsParam(stats.id);
      }}
      influencerId={stats.influencerId}
      influencerName={stats.influencerName}
      socialPostType={stats.socialPostType}
      isBlockedProfileLink={hideViewInfluencerProfile}
      campaignIdentifier={stats.campaignIdentifier}
      campaignName={stats.campaignName}
    />,
    ...getStatCells(stats, currencyId)
  ]);

  return { columns, selectedColumns, rows: [...totalRows, ...campaignRows] };
};

const styles = {
  totalRow: css({
    height: '38px',
    pointerEvents: 'none',

    '> td': {
      fontWeight: 600,
      backgroundColor: THEME.table.header.background.colors.lv2
    }
  }),
  averageRow: css({
    height: '28px',
    borderTop: 'none',
    pointerEvents: 'none',
    verticalAlign: 'top',

    '> td': {
      fontSize: '12px',
      fontWeight: '600',
      color: THEME.text.colors.gray.lv3,
      backgroundColor: THEME.table.header.background.colors.lv2,
      verticalAlign: 'top',

      '> *': {
        fontSize: 'inherit'
      }
    }
  })
};
