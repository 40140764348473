import { TextCutter } from '@/shared/atoms';
import { InfluencerAvatar } from '@/shared/molecules';

export interface AnalyticsComparePostsAccountProps {
  className?: string;
  account: { name?: string | null; avatar?: string | null };
}

export const AnalyticsComparePostsAccount = ({ account, className }: AnalyticsComparePostsAccountProps) => (
  <div css={{ width: '160px', boxSizing: 'border-box', display: 'flex', alignItems: 'center' }} className={className}>
    <InfluencerAvatar src={account?.avatar} size="40px" css={{ borderRadius: '50%', marginRight: '8px' }} />
    <TextCutter css={{ fontSize: '13px', lineHeight: '16px' }} text={account?.name || '-'} lines={3} />
  </div>
);
