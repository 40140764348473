import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { useAccountsFilter } from './hooks';
import { Template, TemplateProps } from './Template';

export type AccountsFilterProps = Omit<TemplateProps, 'onSubmit'>;

export const AccountsFilter = (props: AccountsFilterProps) => {
  const { filter, setFilterForm } = useAccountsFilter();

  return (
    <FilterWrapper>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<Template {...props} />} />
    </FilterWrapper>
  );
};

export { useAccountsFilter };
export * from './types';
