import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeAnalyticsPostsStatistics.graphql';
import { Query, QueryyoutubeAnalyticsPostsStatisticsArgs } from '../../__generated__/globalTypes';

export const useYoutubeAnalyticsPostsStatisticsQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeAnalyticsPostsStatisticsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeAnalyticsPostsStatisticsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeAnalyticsPostsStatisticsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeAnalyticsPostsStatisticsArgs>
): QueryResult<Query, QueryyoutubeAnalyticsPostsStatisticsArgs> & {
  getYoutubeAnalyticsPostsStatistics: LazyQueryExecFunction<Query, QueryyoutubeAnalyticsPostsStatisticsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeAnalyticsPostsStatistics, result] = useLazyQuery<Query, QueryyoutubeAnalyticsPostsStatisticsArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getYoutubeAnalyticsPostsStatistics, ...result };
};

export const useYoutubeAnalyticsPostsStatisticsPromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeAnalyticsPostsStatistics = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeAnalyticsPostsStatisticsArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeAnalyticsPostsStatisticsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeAnalyticsPostsStatistics };
};
