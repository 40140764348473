import { css } from '@emotion/react';
import { formatIntNumber, getOffset } from '@/shared/utils';
import { useParamsSearch } from '@/shared/hooks';
import { INITIAL_PAGE_NUMBER, LIMIT, THEME } from '@/shared/constants';
import { Button } from '../Button';

export interface PaginationProps {
  className?: string;
  totalRecords?: number;
}

export const Pagination = ({ className, totalRecords = 0 }: PaginationProps) => {
  const { page = INITIAL_PAGE_NUMBER, limit = LIMIT, setPage } = useParamsSearch();

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const totalPages = Math.ceil(totalRecords / (limit || 1)); // avoid limit = 0
  const firstIndex = getOffset(page, limit) + 1;
  const lastIndex = Math.min(firstIndex + limit - 1, totalRecords);
  const pageNumbers = (() => {
    const length = Math.min(totalPages, 5);
    const slide = Math.floor(length / 2);

    let startIndex: number;
    startIndex = Math.max(1, page - slide);
    startIndex = Math.min(startIndex, totalPages - length + 1);

    const allPageNumbers = Array.from(Array(length), (_, n) => n + startIndex);

    return allPageNumbers.filter((n) => n < totalPages);
  })();

  if (!totalRecords || totalPages <= 1) {
    return null;
  }

  return (
    <div css={styles.wrapper} className={className}>
      <div css={{ fontSize: '13px', color: THEME.text.colors.gray.lv3 }}>
        {formatIntNumber(firstIndex)}-{formatIntNumber(lastIndex)} / {formatIntNumber(totalRecords)}
      </div>

      <div css={{ display: 'flex', alignItems: 'center' }}>
        <Button
          disabled={page <= 1}
          onClick={() => handlePageChange(page - 1)}
          css={[styles.arrow(page <= 1), { marginRight: '12px' }]}
          prefixIcon={{ size: 12, color: '#8694a2', icon: 'caret-left' }}
        />

        {/* nav to first page */}
        {(pageNumbers[0] as number) > 2 ? (
          <>
            <Button css={styles.page()} title={1} onClick={() => handlePageChange(1)} />
            <div css={{ width: '24px' }}>...</div>
          </>
        ) : null}

        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            title={pageNumber}
            disabled={pageNumber === page}
            css={styles.page(pageNumber === page)}
            onClick={() => handlePageChange(pageNumber)}
          />
        ))}

        {/* nav to last page */}
        {totalPages > 1 ? (
          <>
            {(pageNumbers[pageNumbers.length - 1] as number) < totalPages - 1 ? (
              <div css={{ width: '24px' }}>...</div>
            ) : null}
            <Button
              title={totalPages}
              disabled={totalPages === page}
              css={styles.page(totalPages === page)}
              onClick={() => handlePageChange(totalPages)}
            />
          </>
        ) : null}

        <Button
          disabled={page >= totalPages}
          css={styles.arrow(page >= totalPages)}
          onClick={() => handlePageChange(page + 1)}
          prefixIcon={{ size: 12, color: '#8694a2', icon: 'caret-right' }}
        />
      </div>
    </div>
  );
};
const styles = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: THEME.table.pagination.height,
    marginTop: THEME.table.pagination.offsetTop
  }),
  arrow: (disabled?: boolean) =>
    css({
      padding: 0,
      width: '90px',
      ...(disabled
        ? { backgroundColor: '#dee5ec !important', opacity: 0.5, border: THEME.border.base }
        : { backgroundColor: THEME.background.colors.white, '&:hover': { borderColor: '#c5d0da', color: '#3b4146' } })
    }),
  page: (disabled?: boolean) =>
    css({
      padding: '0 4px',
      minWidth: '32px',
      fontSize: '12px',
      marginRight: '12px',
      fontWeight: 'normal',
      ...(disabled
        ? { backgroundColor: '#27313b !important' }
        : { backgroundColor: THEME.background.colors.white, '&:hover': { borderColor: '#c5d0da', color: '#3b4146' } })
    })
};
