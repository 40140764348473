import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AuthTiktokBusinessAccount.graphql';
import { Mutation, MutationauthTiktokBusinessAccountArgs } from '../../__generated__/globalTypes';

export const useAuthTiktokBusinessAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationauthTiktokBusinessAccountArgs>
) => {
  const [callAuthTiktokBusinessAccount, result] = useMutation<Mutation, MutationauthTiktokBusinessAccountArgs>(
    MUTATION,
    options
  );

  return { callAuthTiktokBusinessAccount, ...result };
};

export type AuthTiktokBusinessAccountMutationFunction = Mutation['authTiktokBusinessAccount'];
