import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { AutoManagedCampaignStatus, AutoManagedCampaignType, GetAutoManagedCampaignsSortField } from '@/graphql';

export const AutoManagedCampaignsFilterSchema = z.object({
  keyword: FilterValidations.string(),
  countryId: FilterValidations.string(),
  minPrice: FilterValidations.number(),
  maxPrice: FilterValidations.number(),
  categoryIds: FilterValidations.arrayString(),
  minCommissionRate: FilterValidations.number(),
  maxCommissionRate: FilterValidations.number(),
  status: FilterValidations.enum(AutoManagedCampaignStatus),
  marketplaceType: FilterValidations.enum(AutoManagedCampaignType)
});
export const AutoManagedCampaignsSearchSchema = z.object({
  filter: AutoManagedCampaignsFilterSchema.default({
    status: AutoManagedCampaignStatus.ACTIVE
  }),
  sort: SearchFilterDefaults.sort(GetAutoManagedCampaignsSortField).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type AutoManagedCampaignsSearchSchemaType = z.infer<typeof AutoManagedCampaignsSearchSchema>;
export type AutoManagedCampaignsFilterFormValues = z.infer<typeof AutoManagedCampaignsFilterSchema>;
export type AutoManagedCampaignsFilterKeys = UnionOfKeys<AutoManagedCampaignsFilterFormValues>;
