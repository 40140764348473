import { TikTokSpecialCampaignStatus } from '@/graphql';
import { useTranslateOptions } from '@/shared/hooks';

export const useTikTokCampaignStatusOptions = () => {
  const statusOptions = useTranslateOptions([
    { label: 'Draft', value: TikTokSpecialCampaignStatus.DRAFT },
    { label: 'Reviewing', value: TikTokSpecialCampaignStatus.REVIEWING },
    { label: 'Upcoming', value: TikTokSpecialCampaignStatus.UPCOMING },
    { label: 'Ongoing', value: TikTokSpecialCampaignStatus.ONGOING },
    { label: 'Over Budget', value: TikTokSpecialCampaignStatus.SUSPENDED },
    { label: 'Finished', value: TikTokSpecialCampaignStatus.FINISHED },
    { label: 'Rejected', value: TikTokSpecialCampaignStatus.REJECTED }
  ]);

  return { statusOptions };
};
