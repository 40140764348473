import { Header } from '@/shared/molecules';
import { ExportButton } from './ExportButton';
import { ApproveButton } from './ApproveButton';

interface InvoicesHeaderProps {
  hasNoData?: boolean;
}

export const InvoicesHeader = ({ hasNoData }: InvoicesHeaderProps) => (
  <Header>
    <div css={{ display: 'flex', alignItems: 'center', gap: '4px', marginLeft: 'auto' }}>
      <ExportButton hasNoData={hasNoData} />
      <ApproveButton hasNoData={hasNoData} />
    </div>
  </Header>
);
