import { css, SerializedStyles } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { Option } from '@/shared/types';
import { BaseSelector, MenuType } from '../BaseSelector';
import { MenuControlIcon } from './MenuControlIcon';
import { MenuItemsWithCheckbox, MenuItemsWithCheckboxProps } from './MenuItemsWithCheckbox';
import { SelectAll } from './SelectAll';
import { getInitialSelectedItems, MENU_ITEMS_HEIGHT } from './utils';

export interface MultiSelectorProps<T extends Option = Option> {
  name: string;
  value?: string[];
  hasError?: boolean;
  options: readonly T[];
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  onClose?: () => void;
  onClear?: () => void;
  hasSelectAll?: boolean;
  isAlwaysOpen?: boolean;
  skipFiltering?: boolean;
  subSelector?: ReactNode;
  canOnlyAddMore?: boolean;
  initialValues?: string[];
  menuCss?: SerializedStyles;
  placeholder?: string | null;
  noOptionPlaceholder?: ReactNode;
  onChange?: (items: string[]) => void;
  onClickInputBox?: (menu: MenuType) => void;
  onSearchChange?: (inputText: string) => void;
  menuItemsProps?: Pick<
    MenuItemsWithCheckboxProps<T>,
    'menuItemsHeight' | 'menuItemChildren' | 'isVirtualized' | 'type' | 'allowedOptions'
  >;
}

export const MultiSelector = <T extends Option = Option>(props: MultiSelectorProps<T>) => {
  const {
    name,
    hasError,
    options,
    onClose,
    menuCss,
    loading,
    onClear,
    disabled,
    onChange,
    value = [],
    placeholder,
    subSelector,
    isAlwaysOpen,
    skipFiltering,
    initialValues,
    onSearchChange,
    canOnlyAddMore,
    onClickInputBox,
    noOptionPlaceholder,
    hasSelectAll = true,
    menuItemsProps: { type, allowedOptions, isVirtualized, menuItemChildren, menuItemsHeight = MENU_ITEMS_HEIGHT } = {}
  } = props;
  const { t } = useTranslation();

  // Items
  const selectedValue = getInitialSelectedItems<T>(value, options);
  const setSelectedValue = (items: readonly T[]) => {
    if (onChange) {
      onChange(items.map((item) => String(item.value)));
    }
  };
  const removeValue = (item: T) => {
    const isInitialValue = item?.value && initialValues?.includes(item?.value);
    if (canOnlyAddMore && isInitialValue) {
      return;
    }

    setSelectedValue(selectedValue.filter((i) => i !== item));
  };

  return (
    <BaseSelector<T>
      name={name}
      items={{ selectedValue, setSelectedValue, removeValue }}
      hasError={hasError}
      onSearchChange={onSearchChange}
      options={options}
      loading={loading}
      skipFiltering={skipFiltering}
      disabled={disabled}
      disabledInputBox={false}
      isAlwaysOpen={isAlwaysOpen}
      onClickInputBox={onClickInputBox}
      placeholder={selectedValue.length === 0 ? placeholder : ''}
      onClose={onClose}
      MenuControlIcon={MenuControlIcon}
      renderSelectedValue={({ selectedValue: values }) => (
        <TextCutter
          lines={1}
          text={
            values.length > 2 ? t('Selected', { count: values.length }) : values.map((item) => item.label).join(', ')
          }
          css={{ position: 'absolute', padding: '0 16px 0 8px' }}
        />
      )}
      menuCss={menuCss}
      renderMenu={({ items, search, menu }) => {
        const isSelectedAll = search.filteredValue.every((v) => items.selectedValue.includes(v));
        const isSelectedNoting = items.selectedValue.length === 0;

        const handleSelectAll = () => {
          if (disabled) {
            return;
          }
          // can unselect all values except initialValues
          const allUnselectedValues = canOnlyAddMore
            ? search.filteredValue.filter((val) => val.value && initialValues?.includes(val.value))
            : [];
          items.setSelectedValue(isSelectedAll ? allUnselectedValues : search.filteredValue);
        };

        const handleClickItem = (item: T) => {
          if (disabled) {
            return;
          }
          const isSelected = items.selectedValue.includes(item);

          // Remove Item
          if (isSelected) {
            items.removeValue(item);

            return;
          }
          // Add Item
          items.setSelectedValue([...items.selectedValue, item]);
        };

        return (
          <div css={loading ? styles.loadingOverlay : {}}>
            {hasSelectAll && search.filteredValue.length ? (
              <div css={{ display: 'flex', borderBottom: THEME.border.base }}>
                <SelectAll
                  disabled={disabled}
                  css={{ border: 'none' }}
                  onClick={handleSelectAll}
                  checked={!isSelectedNoting}
                  indeterminate={!isSelectedNoting && !isSelectedAll}
                />

                {subSelector}
              </div>
            ) : null}
            <div css={styles.menuWrapper(menuItemsHeight)}>
              <MenuItemsWithCheckbox<T>
                disabled={disabled}
                options={search.filteredValue}
                handleClick={handleClickItem}
                noOptionPlaceholder={noOptionPlaceholder}
                checkIsSelected={(option: T) => items.selectedValue.map((item) => item.value).includes(option.value)}
                allowedOptions={allowedOptions}
                type={type}
                isVirtualized={isVirtualized}
                menuItemChildren={menuItemChildren}
                menuItemsHeight={menuItemsHeight}
              />
            </div>
            <div css={{ display: 'flex', borderTop: THEME.border.base, padding: '8px 12px' }}>
              {onClear ? (
                <Button
                  variant="white"
                  title={t('Button.Clear')}
                  onClick={onClear}
                  css={{ height: '24px', minWidth: '64px', textTransform: 'capitalize' }}
                />
              ) : null}
              <Button
                variant="blue"
                title={t('Button.Apply')}
                onClick={() => {
                  menu.setIsOpen(false);
                  onClose?.();
                }}
                css={{ marginLeft: 'auto', height: '24px', minWidth: '67px', textTransform: 'capitalize' }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export const styles = {
  menuWrapper: (menuItemsHeight?: number, hasSubmenu?: boolean) =>
    css({
      maxHeight: menuItemsHeight || MENU_ITEMS_HEIGHT,
      overflowY: 'auto',
      overflowX: 'hidden',
      position: hasSubmenu ? 'static' : 'relative'
    }),
  loadingOverlay: css({
    opacity: '0.5',
    pointerEvents: 'none',
    '*': {
      pointerEvents: 'none'
    }
  })
};
