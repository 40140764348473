import { useTranslation } from 'react-i18next';
import { RenderDataWithFallback, ListEmpty } from '@/shared/atoms';
import { useInfluencerAddRoute } from '@/shared/hooks';
import { useInfluencerPostsContext } from '../InfluencerPostsContext';
import { InfluencerPostWidget } from './InfluencerPostWidget';

export const InfluencerPostsList = () => {
  const { t } = useTranslation();
  const { allowAddInfluencer, addInfluencerRoute } = useInfluencerAddRoute();
  const { filter, loading, listRecords, getListSelectButtonProps } = useInfluencerPostsContext();

  const skipPostSearch = !(filter?.socialMedia && filter?.countryId);
  const hasAddInfluencerLink = allowAddInfluencer && !skipPostSearch;

  return (
    <RenderDataWithFallback
      loading={loading}
      hasNoData={listRecords.length === 0}
      noDataNode={
        <ListEmpty
          {...(skipPostSearch
            ? {
                title: t('Influencer.Do you want to search posts'),
                description: t('Influencer.Please select Social Media and Country')
              }
            : {})}
          linkProps={hasAddInfluencerLink ? { label: t('add an influencer'), href: addInfluencerRoute } : undefined}
        />
      }
    >
      {listRecords.map((profile, index) => (
        <InfluencerPostWidget
          key={`influencer-${profile.id}-${index}`}
          profile={profile}
          selectButtonProps={getListSelectButtonProps?.(profile)}
        />
      ))}
    </RenderDataWithFallback>
  );
};
