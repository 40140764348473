import { FilterProvider } from '@/shared/molecules';
import { useTiktokInfluencersContext } from '../TiktokInfluencersContext';
import { BasicFilter } from './BasicFilter';

export const TiktokInfluencersFilter = () => {
  const { filter, setFilter, isTtcmCampaign, isEngagementPage, isPackagePage } = useTiktokInfluencersContext();

  return (
    <FilterProvider
      onSubmit={setFilter}
      initialValues={filter}
      basicFilter={
        <BasicFilter
          isEngagementPage={isEngagementPage}
          isTtcmCampaign={isTtcmCampaign}
          isPackagePage={isPackagePage}
        />
      }
    />
  );
};
