import { useState } from 'react';
import { useDeepCompareEffect } from './useDeepCompareEffect';

interface Props<T extends string | number> {
  allItemIds?: T[];
  defaultSelectedIds?: T[];
}
// Same with useSelectItems but for Array<string | number> only
export const useSelectItemIds = <T extends string | number>({
  allItemIds = [],
  defaultSelectedIds = []
}: Props<T> = {}) => {
  const [selectedItemIds, setSelectedItemIds] = useState<T[]>(defaultSelectedIds);

  useDeepCompareEffect(() => {
    setSelectedItemIds(defaultSelectedIds);
  }, [defaultSelectedIds]);

  const toggleSelectAllItems = () => {
    setSelectedItemIds(selectedItemIds.length === allItemIds.length ? [] : allItemIds);
  };

  const selectItem = (id: T) => {
    const newIds = [...selectedItemIds];

    if (newIds.includes(id)) {
      const index = newIds.findIndex((newId) => newId === id);
      newIds.splice(index, 1);
    } else {
      newIds.push(id);
    }

    setSelectedItemIds(newIds);
  };

  return {
    selectItem,
    selectedItemIds,
    setSelectedItemIds,
    toggleSelectAllItems,
    hasAllItemsSelected: !!allItemIds.length && selectedItemIds.length === allItemIds.length
  };
};
