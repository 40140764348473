import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Anchor, SocialIcon } from '@/shared/atoms';
import { DayPickerRange } from '@/shared/molecules';
import { SelectedRangeOption } from '@/shared/types';
import { useInitialInfluencerProfileCompare } from './hooks';
import { InfluencerProfileCompareYoutube } from './InfluencerProfileCompareYoutube';

export const InfluencerProfileCompare = () => {
  const { t } = useTranslation();
  const { filter, setFilter, compareAccount } = useInitialInfluencerProfileCompare();

  // We only support compare Youtube accounts for now
  return compareAccount ? (
    <>
      <div css={styles.accountInfoWrapper}>
        <SocialIcon type="YOUTUBE" />
        <p css={{ fontSize: '13px', lineHeight: 1.5, marginLeft: '12px', whiteSpace: 'nowrap' }}>
          {`${t('Account URL')}: `}
        </p>
        <Anchor
          variant="blue"
          target="_blank"
          href={compareAccount.profileUrl}
          label={compareAccount.profileUrl}
          css={{ marginLeft: '8px', textDecoration: 'underline' }}
        />

        <DayPickerRange
          initialPeriod={filter}
          period={{ endDate: filter.endDate, startDate: filter.startDate }}
          handleChangePeriod={(period) => setFilter({ ...filter, ...period })}
          disabledRange={{ after: new Date() }}
          css={{ flex: '25% 0 0', marginLeft: 'auto' }}
          rangeList={[
            SelectedRangeOption.TODAY,
            SelectedRangeOption.TODAY_TO_MONTH_AGO,
            SelectedRangeOption.THIS_MONTH,
            SelectedRangeOption.LAST_MONTH,
            SelectedRangeOption.CUSTOM
          ]}
        />
      </div>

      <InfluencerProfileCompareYoutube compareAccount={compareAccount} />
    </>
  ) : null;
};
const styles = {
  accountInfoWrapper: css({
    width: '100%',
    height: '48px',
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '8px 8px 8px 20px',
    backgroundColor: THEME.background.colors.white
  })
};
