import { useParams, Navigate } from '@tanstack/react-router';
import { useYoutubeAnalyticsCompareAccountCardsQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { useInitialAnalyticsPosts } from '../hooks';
import { TemplateProps, Template } from './Template';

export const AnalyticsYoutubeCompareVideos = () => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const params = useParams({
    from: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos'
  });
  const { goBackUrl, mainSocialAccountId } = useInitialAnalyticsPosts({
    socialMedia: 'youtube',
    analyticsProfileUrl: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare',
    document: (name) => ({
      alwaysBackToParent: true,
      appHeader: name ? 'appHeader.PostListWithAccountName' : 'appHeader.Post List',
      title: name ? 'documentTitle.PostListWithAccountName' : 'documentTitle.Post List'
    })
  });

  const { data, loading, called } = useYoutubeAnalyticsCompareAccountCardsQuery({
    skip: !mainSocialAccountId,
    fetchPolicy: 'cache-and-network',
    variables: { mainAccountId: Number(mainSocialAccountId) },
    onCompleted: ({ youtubeAnalyticsCompareAccountCards: { mainAccount, compareAccounts } }) => {
      if (!mainAccount && !compareAccounts.length) {
        enqueueSnackbar(t('Can not find comparable accounts information'), { variant: 'error' });
        navigate({ to: goBackUrl, params });
      }
    },
    onError: (err) => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
      navigate({ to: goBackUrl, params });
    }
  });

  const allAccounts = data?.youtubeAnalyticsCompareAccountCards;
  const comparableAccounts: TemplateProps['comparableAccounts'] = [
    ...(allAccounts?.mainAccount
      ? [
          {
            accountId: allAccounts.mainAccount.accountId,
            avatarUrl: allAccounts.mainAccount.avatarUrl,
            channelName: allAccounts.mainAccount.channelName
          }
        ]
      : []),
    ...(allAccounts?.compareAccounts?.map((account) => ({
      accountId: account.accountId,
      avatarUrl: account.avatarUrl,
      channelName: account.channelName
    })) || [])
  ];

  if (called && !loading && !comparableAccounts.length) {
    return <Navigate to={goBackUrl} params={params} />;
  }

  return (
    <RenderDataWithFallback loading={loading} noDataNode={null}>
      {comparableAccounts?.length ? (
        <Template mainSocialAccountId={mainSocialAccountId} comparableAccounts={comparableAccounts} />
      ) : null}
    </RenderDataWithFallback>
  );
};

export * from './schemaTypes';
