import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { FormStyle } from '@/shared/atoms';
import { TextAreaField } from '@/shared/molecules';
import { InfluencerSharedKeys } from '../types';
import { InternalMemoFormValues } from './types';

interface InternalMemoProps {
  className?: string;
  hasBorder?: boolean;
}

export const InternalMemo = ({ className, hasBorder }: InternalMemoProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();

  return (
    <FormStyle.Wrapper css={{ padding: '24px' }} className={className} hasBorder={hasBorder}>
      <TextAreaField<keyof InternalMemoFormValues>
        css={{ padding: 0 }}
        title={t('TextForm.Internal memo')}
        name={InfluencerSharedKeys.internalMemo}
        note={`(${t('InternalMemoOnProfileForm')})`}
        help={t(isAdminStaff ? 'Tooltip.Internal Memo Help Admin&Staff' : 'Tooltip.Internal Memo Help External Users')}
      />
    </FormStyle.Wrapper>
  );
};
