import { z } from 'zod';
import { FacebookPageAnalyticsPostsSortOrder } from '@/graphql';
import { LIMIT } from '@/shared/constants';
import { FilterValidations } from '@/shared/validations';

export const InfluencerFacebookPostsListSearchSchema = z.object({
  filter: z
    .object({
      keyword: z.string().optional(),
      name: z.string().optional()
    })
    .default({}),
  sort: FilterValidations.sort(FacebookPageAnalyticsPostsSortOrder).default({ field: null, order: null }),
  page: z.number().default(1),
  limit: z.number().default(LIMIT)
});

export type InfluencerFacebookPostsListSearchType = z.infer<typeof InfluencerFacebookPostsListSearchSchema>;
