import { FilterProvider } from '@/shared/molecules';
import { useAllInfluencersContext } from '../AllInfluencersContext';
import { BasicFilter } from './BasicFilter';

export const AllInfluencersFilter = () => {
  const { filter, setFilter, isEngagementPage, isPackagePage } = useAllInfluencersContext();

  return (
    <FilterProvider
      onSubmit={setFilter}
      initialValues={filter}
      basicFilter={<BasicFilter isEngagementPage={isEngagementPage} isPackagePage={isPackagePage} />}
    />
  );
};
