import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useAllAdvertisersForSearchOptions, useAllPicUserOptions } from '@/shared/hooks';
import { SelectorField, TextField } from '@/shared/molecules';
import { ConnectableAccountsModal } from './ConnectableAccountsModal';
import { SocialAccounts } from './SocialAccounts';
import { AnalyticsFormValues, AnalyticsFormKeys } from './schemaTypes';

export const Template = () => {
  const { t } = useTranslation();
  const { isAgency, isAdvertiser } = useUserRoles();
  const { allPicsUserOptions } = useAllPicUserOptions({ skip: isAdvertiser || isAgency });
  const { allAdvertiserOptions } = useAllAdvertisersForSearchOptions({ skip: isAdvertiser });
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<AnalyticsFormValues>();

  const id = watch('id');

  return (
    <>
      <FormStyle.Wrapper css={{ boxShadow: THEME.shadows.boxShadow }} hasBorder>
        <SocialAccounts />

        <div css={{ padding: '24px' }}>
          <FormStyle.SectionTitle index={2} label={t('Account Information')} />

          <FormStyle.FieldsGroup itemsPerRow={3}>
            <TextField<AnalyticsFormKeys>
              name="accountName"
              title={t('TextForm.Account Name')}
              placeholder={t<string>('account name')}
              help={t('Tooltip.Analytics Account Name')}
              required
            />

            {!isAdvertiser ? (
              <SelectorField<AnalyticsFormKeys>
                disabled={!!id}
                name="advertiserId"
                options={allAdvertiserOptions}
                title={t('Selector.Advertiser')}
                help={t('Tooltip.Analytics Advertiser')}
                required
              />
            ) : null}

            {!(isAdvertiser || isAgency) ? (
              <SelectorField<AnalyticsFormKeys>
                name="accountManagerIds"
                options={allPicsUserOptions}
                title={t('Selector.Account Management PIC')}
                help={t('Tooltip.Analytics Account Management PIC')}
                required
                multiple
              />
            ) : null}
          </FormStyle.FieldsGroup>
        </div>

        <FormStyle.ButtonSubmitWrapper>
          <FormStyle.ButtonSubmit title={t('Button.Save')} loading={isSubmitting} />
        </FormStyle.ButtonSubmitWrapper>
      </FormStyle.Wrapper>

      <ConnectableAccountsModal />
    </>
  );
};
