import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MarketplaceDetail } from '@/graphql';
import { formatIntNumber } from '@/shared/utils';
import { SelectorFieldV2, RangeSliderInputField, MoreFilterPopover, FILTER_SECTIONS } from '@/shared/molecules';
import { useMarketplaceInfluencersFilterOptions } from '../../MarketplaceInfluencers';
import { MarketplaceApplicantsFilterFormKeys, MarketplaceApplicantsFilterFormValues } from './schemaTypes';
import { defaultMarketplaceApplicantsFilterValues } from './utils';
interface AdvancedFilterProps {
  count: number;
  onSubmit?: () => void;
  marketplace: MarketplaceDetail;
}

export const AdvancedFilter = ({ count, onSubmit, marketplace }: AdvancedFilterProps) => {
  const { t } = useTranslation();
  const { watch, reset } = useFormContext<MarketplaceApplicantsFilterFormValues>();
  const { ageConfigs, genderOptions, influencerCategoryOptions, followerConfigs, engagementRateConfigs } =
    useMarketplaceInfluencersFilterOptions({ marketplace });

  const values = watch();

  const handleClear = () => {
    reset({
      ...defaultMarketplaceApplicantsFilterValues,
      // Basic filter
      keyword: values.keyword,
      appliedStatus: values.appliedStatus
    });
  };

  return (
    <MoreFilterPopover
      count={count}
      onClear={handleClear}
      onSubmit={onSubmit}
      items={[
        {
          value: FILTER_SECTIONS.InfluencerAttributes,
          title: FILTER_SECTIONS.InfluencerAttributes,
          node: (
            <>
              <SelectorFieldV2<MarketplaceApplicantsFilterFormKeys>
                name="genders"
                options={genderOptions}
                title={t('Selector.Gender')}
                multiple
              />
              <SelectorFieldV2<MarketplaceApplicantsFilterFormKeys>
                name="categoryIds"
                title={t('Selector.Categories')}
                options={influencerCategoryOptions}
                multiple
              />
            </>
          )
        },
        {
          value: FILTER_SECTIONS.PerformanceFilter,
          title: FILTER_SECTIONS.PerformanceFilter,
          node: (
            <>
              <RangeSliderInputField<MarketplaceApplicantsFilterFormKeys>
                step={ageConfigs.step}
                title={t('General.Age')}
                range={ageConfigs.range}
                options={ageConfigs.options}
                minField={{ name: 'minAge', placeholder: `${ageConfigs.range.min}` }}
                maxField={{ name: 'maxAge', placeholder: `${ageConfigs.range.max}` }}
              />
              <RangeSliderInputField<MarketplaceApplicantsFilterFormKeys>
                step={followerConfigs.step}
                range={followerConfigs.range}
                options={followerConfigs.options}
                title={t('General.Number of Followers')}
                minField={{ name: 'minFollowers', placeholder: formatIntNumber(followerConfigs.range.min) }}
                maxField={{ name: 'maxFollowers', placeholder: `${formatIntNumber(followerConfigs.range.max)}+` }}
                formatLabel={(value) => `${formatIntNumber(value)}${value === followerConfigs.range.max ? '+' : ''}`}
              />
              <RangeSliderInputField<MarketplaceApplicantsFilterFormKeys>
                step={engagementRateConfigs.step}
                range={engagementRateConfigs.range}
                title={t('General.Engagement Rate')}
                options={engagementRateConfigs.options}
                minField={{ name: 'minEngagementRate', placeholder: `${engagementRateConfigs.range.min}%` }}
                maxField={{ name: 'maxEngagementRate', placeholder: `${engagementRateConfigs.range.max}%` }}
              />
            </>
          )
        }
      ]}
    />
  );
};
