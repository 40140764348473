import { InstagramStoryPost } from '@/graphql';
import { Option } from '@/shared/types';

export const defaultSelectedAnalyticsInstagramStoryPostsColumns: Array<keyof InstagramStoryPost> = [
  'comments',
  'reach',
  'impressions',
  'exits',
  'tapsForward',
  'tapsBack',
  'postDate'
];

export const selectableAnalyticsInstagramStoryPostsColumnOptions: Option<keyof InstagramStoryPost>[] = [
  { label: 'Follow', value: 'follow' },
  { label: 'Link Clicks', value: 'linkClicks' },
  { label: 'Sticker Taps', value: 'stickerTaps' },
  { label: 'Interaction', value: 'interaction' },
  { label: 'Product Page View', value: 'productPageView' },
  { label: 'Product Button Click', value: 'productButtonClick' }
];
