import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { AddHashtagModal } from '@/shared/molecules';

interface AddHashtagProps {
  loading?: boolean;
  onAdd: (newHashtag: string, callback: () => void) => void;
}

export const AddHashtag = ({ loading, onAdd }: AddHashtagProps) => {
  const modalState = useToggleState();

  return (
    <>
      <div css={styles.wrapper} onClick={modalState.open}>
        <span css={{ fontSize: '16px' }}>+</span>
      </div>

      <AddHashtagModal
        loading={loading}
        open={modalState.status}
        onClose={modalState.close}
        onAdd={(hashtag) => onAdd(hashtag, modalState.close)}
      />
    </>
  );
};
const styles = {
  wrapper: css({
    flex: 'none',
    width: '112px',
    height: '112px',
    display: 'flex',
    fontSize: '12px',
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    borderRadius: '3px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white,
    border: '1px dashed #dee5ec',
    color: THEME.text.colors.gray.lv3,
    transition: 'background-color 0.3s ease, border-color 0.3s ease; ',
    '&:hover': { borderColor: '#dee5ec', backgroundColor: '#ebf4fc' }
  })
};
