import { Navigate, Outlet } from '@tanstack/react-router';
import { useInfluencerYoutubeAnalyticsCompareAccountCardsQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useDeepCompareEffect, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { useInitialInfluencerPostsList } from '../../hooks';
import { Template, TemplateProps } from './Template';

export const InfluencerYoutubeCompareVideos = () => {
  const { t, goBack, enqueueSnackbar } = useQueryHelper();
  const { setDocumentAndAppTitles } = useUpdateDocumentTitle({});
  const { goBackUrl, socialAccountId, params } = useInitialInfluencerPostsList({
    influencerProfileUrl: '/influencer/$id/profile/compare'
  });

  const { data, loading, called } = useInfluencerYoutubeAnalyticsCompareAccountCardsQuery({
    skip: !socialAccountId,
    fetchPolicy: 'cache-and-network',
    variables: { socialAccountId },
    onCompleted: ({ youtubeAnalyticsCompareAccountCards: { mainAccount, compareAccounts } }) => {
      if (!mainAccount && !compareAccounts.length) {
        enqueueSnackbar(t('Can not find comparable accounts information'), { variant: 'error' });
        goBack();
      }
    },
    onError: (err) => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
      goBack();
    }
  });

  const allAccounts = data?.influencerYoutubeAnalyticsCompareAccountCards;
  const comparableAccounts: TemplateProps['comparableAccounts'] = [
    ...(allAccounts?.mainAccount
      ? [
          {
            avatarUrl: allAccounts.mainAccount.avatarUrl,
            channelName: allAccounts.mainAccount.channelName,
            accountId: allAccounts.mainAccount.socialAccountId
          }
        ]
      : []),
    ...(allAccounts?.compareAccounts?.map((account) => ({
      accountId: account.accountId,
      avatarUrl: account.avatarUrl,
      channelName: account.channelName
    })) || [])
  ];

  useDeepCompareEffect(() => {
    const selectedAccount =
      comparableAccounts.find((account) => account.accountId === Number(params.compareId)) || comparableAccounts[0];
    setDocumentAndAppTitles({
      href: goBackUrl.to,
      alwaysBackToParent: true,
      params: goBackUrl.params,
      interpolation: { title: selectedAccount?.channelName || '' },
      title: selectedAccount?.channelName ? 'appHeader.PostListWithAccountName' : 'documentTitle.Post List',
      appHeader: selectedAccount?.channelName ? 'appHeader.PostListWithAccountName' : 'appHeader.Post List'
    });
  }, [params.compareId, comparableAccounts]);

  if (called && !loading && !comparableAccounts.length) {
    return <Navigate to={goBackUrl.to} params={goBackUrl.params} />;
  }

  return (
    <>
      <Template comparableAccounts={comparableAccounts} />
      <RenderDataWithFallback loading={loading} noDataNode={null}>
        <Outlet />
      </RenderDataWithFallback>
    </>
  );
};
