import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { DisabledProps } from '../types';

interface InputBoxProps extends InputBoxWrapperProps {
  loading?: boolean;
  className?: string;
  searchText: string;
  hideInput?: boolean;
  handleClickInputBox: () => void;
  renderSelectedValue: () => React.ReactNode;
  placeholder?: string | null;
  MenuControlIcon?: React.ComponentType;
  inputRef: React.RefObject<HTMLInputElement>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClickInput: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export const InputBox = (props: InputBoxProps) => {
  const {
    hasError,
    loading,
    disabled,
    inputRef,
    className,
    hideInput,
    isMenuOpen,
    searchText,
    placeholder,
    handleChange,
    handleKeyDown,
    MenuControlIcon,
    handleClickInput,
    renderSelectedValue,
    handleClickInputBox
  } = props;
  const { t } = useTranslation();
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <div
      onClick={handleClickInputBox}
      css={styles.inputBoxWrapper({ isMenuOpen, hasError, disabled, loading })}
      className={className}
    >
      <div css={styles.inputWrapper}>
        {/* for better UX we just hiding already selected results so user can perform type search easily */}
        {hasFocus ? null : renderSelectedValue()}
        {!hideInput && (
          <input
            value={hasFocus ? searchText : ''}
            ref={inputRef}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            disabled={disabled}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onClick={handleClickInput}
            placeholder={
              loading
                ? t<string>('Please wait')
                : disabled
                  ? ''
                  : placeholder === undefined || placeholder === null
                    ? t<string>('Selector.Please Select')
                    : placeholder
            }
            css={styles.input(disabled)}
          />
        )}
      </div>
      {loading ? (
        <CircularProgress
          size={20}
          thickness={0.5}
          backgroundColor="#dee5ec"
          css={{
            position: 'absolute',
            right: 5,
            top: 5
          }}
        />
      ) : MenuControlIcon ? (
        <MenuControlIcon />
      ) : undefined}
    </div>
  );
};

interface InputBoxWrapperProps extends DisabledProps {
  isMenuOpen: boolean;
  hasError?: boolean;
  loading?: boolean;
}

const styles = {
  inputWrapper: css({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: 'calc(100% - 30px)' /* approx input text container's width, excluding dropdown arrow */
  }),
  inputBoxWrapper: ({ disabled, loading, isMenuOpen, hasError: isError }: InputBoxWrapperProps) =>
    css({
      minHeight: '30px',
      backgroundColor:
        disabled && !loading ? THEME.input.background.colors.disabled : THEME.input.background.colors.default,
      border: '1px solid',
      position: 'relative',
      borderRadius: '3px',
      borderColor: isError
        ? THEME.input.border.colors.error
        : isMenuOpen
          ? THEME.input.border.colors.focus
          : THEME.input.border.colors.default,
      ':hover': {
        borderColor: isError
          ? THEME.input.border.colors.error
          : isMenuOpen
            ? THEME.input.border.colors.focus
            : THEME.input.border.colors.hover
      }
    }),
  input: (disabled?: boolean) =>
    css({
      flex: 1,
      display: 'inline-block',
      width: '100%',
      height: 0,
      minHeight: '30px',
      outline: 0,
      color: THEME.input.text.colors.default,
      fontSize: '13px',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      padding: '0 4px 0 10px',
      transition: 'box-shadow 0.1s ease, width 0.1s ease',
      boxShadow: 'none',
      border: 'none',
      borderRadius: '3px',
      cursor: disabled ? 'not-allowed' : 'default',
      backgroundColor: disabled ? THEME.input.background.colors.disabled : THEME.input.background.colors.default,
      '::placeholder': {
        color: THEME.input.text.colors.placeholder,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: 'normal'
      }
    })
};
