import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { GroupBase, MenuPlacement, SingleValue, StylesConfig, SelectInstance } from 'react-select';
import { Order } from '@/graphql';
import { Option } from '@/shared/types';
import { ClearIndicator } from '../ClearIndicator';
import { DropdownIndicator } from '../DropdownIndicator';
import { DefaultSingleSelectStylesProps, formatOptionLabel, getDefaultSingleSelectStyles, separator } from './utils';
import { SingleSelectSubmenuOption } from './SingleSelectSubmenuOption';
import { MenuItemsProps } from './MenuItems';

export interface SingleSelectorProps extends DefaultSingleSelectStylesProps {
  name: string;
  onClose?: () => void;
  value?: string;
  options: Option[];
  loading?: boolean;
  className?: string;
  hideInput?: boolean;
  order?: Order | null;
  onMenuOpen?: () => void;
  placeholder?: string | null;
  type?: MenuItemsProps['type'];
  hideDeselectOption?: boolean;
  onChange?: (value: string, order?: Option['order']) => void;
  styles?: StylesConfig<Option, boolean, GroupBase<Option>>;
  menuPlacement?: MenuPlacement;
}
export type ReactSingleSelectProps = Partial<
  Omit<SelectInstance<Option, false, GroupBase<Option>>, 'onChange' | 'isClearable' | 'formatOptionLabel'>
>;

export const SingleSelectorWithSubmenu = (props: SingleSelectorProps & ReactSingleSelectProps) => {
  const {
    name,
    type,
    value = '',
    hasError,
    order,
    height,
    styles,
    options,
    onClose,
    loading,
    disabled,
    onChange,
    className,
    hideInput,
    onMenuOpen,
    menuStyles,
    placeholder,
    hideDeselectOption,
    ...restProps
  } = props;
  const ref = useRef<SelectInstance<Option, false, GroupBase<Option>>>(null);
  const { t } = useTranslation();

  const handleCloseMenu = () => {
    onClose?.();
    ref.current?.blur();
  };

  const setSelectedValue = (item: SingleValue<Option>) => {
    if (onChange) {
      if (item?.value) {
        onChange(item.value?.includes(separator) ? item.value.split(separator)?.at(0) || '' : item.value, item.order);
      } else {
        onChange('');
        handleCloseMenu();
      }
    }
  };

  const valueOption = useCallback(
    (targetValue: string) =>
      options
        .flatMap(
          (option) =>
            (option.submenu || [])
              .filter((subOption) => subOption.value === targetValue)
              .map((subOption) => ({ parent: option, ...subOption })) // parent is needed to highligh where selected submenu item is located
        )
        .find(Boolean),
    [options]
  );

  return (
    <ReactSelect
      ref={ref}
      placeholder={placeholder}
      name={name}
      onMenuOpen={onMenuOpen}
      value={valueOption(value)}
      isClearable={!hideDeselectOption}
      onChange={setSelectedValue}
      formatOptionLabel={(optProps, formatOptionLabelMeta) =>
        formatOptionLabel({ type, optProps, formatOptionLabelMeta })
      }
      options={options}
      className={className}
      isDisabled={disabled}
      loadingMessage={loading ? () => t('Please wait') : undefined}
      styles={{
        ...getDefaultSingleSelectStyles({
          hasError,
          styles,
          height,
          disabled,
          menuStyles
        })
      }}
      onMenuClose={handleCloseMenu}
      isSearchable={!hideInput}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: (indicatorProps) =>
          !indicatorProps.hasValue || hideDeselectOption ? <DropdownIndicator /> : null,
        ClearIndicator: (clearProps) => <ClearIndicator {...clearProps} ref={ref} />,
        Option: (props) => <SingleSelectSubmenuOption options={props} selectedOption={valueOption(value)} />
      }}
      {...restProps}
    />
  );
};
export { getDefaultSingleSelectStyles };
