import { useState } from 'react';
import { linkOptions, useMatchRoute } from '@tanstack/react-router';
import { checkDemoAccounts, useParamsSearch, useQueryHelper } from '@/shared/hooks';
import {
  getOpenAiSourceFrom,
  getInfluencerFilterTabUrl,
  getInitialInstagramInfluencersFormValues,
  InstagramInfluencersSearchSchemaType
} from '@/shared/organisms';
import {
  OpenAIMessage,
  OpenAINextAction,
  QueryopenAiMessageArgs,
  useOpenAiMessagePromiseQuery,
  useSendOpenAiMessageMutation
} from '@/graphql';
import { InfluencersTabType } from '@/shared/types';
import { ChatDemoMessageType } from '../shared';
import { useManageChatDemoStates } from './useManageChatDemoStates';
import { useChatDemoSearchPathnames } from './useChatDemoSearchPathnames';

const MAX_RETRY = 5;
const defaultInstagramFilter = getInitialInstagramInfluencersFormValues(undefined, true);

export const useInitChatDemo = () => {
  const isChatDemoPage = checkDemoAccounts();
  const demoStates = useManageChatDemoStates();
  const matchRoute = useMatchRoute();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const [retryCounter, setRetryCounter] = useState(MAX_RETRY);
  const { getOpenAiMessage } = useOpenAiMessagePromiseQuery();
  const { callSendOpenAiMessage } = useSendOpenAiMessageMutation();
  const { isSupportedSNS, isInfluencersSearchPage } = useChatDemoSearchPathnames();
  const { filter } = useParamsSearch<InstagramInfluencersSearchSchemaType>();

  // The filter hook above use in 2 pages, so it can be empty and can has value
  // So we need to check when we use the default filter and when we use the filtered filter
  // If staying influencer page, we will use the default filter + changed filter by user
  // If staying another pages we will you the default instagram filter only
  const formattedFilterParams = isInfluencersSearchPage ? filter : defaultInstagramFilter;
  const tab = formattedFilterParams.tab || InfluencersTabType.INSTAGRAM;
  const sourceFrom = getOpenAiSourceFrom(tab);
  const { messages, threadId, updateMessages, createOpenAiThread, removeLastTypingMessage } = demoStates;

  const generateInfluencerSearchRoute = (routeParams: { tab: InfluencersTabType } & Record<string, any>) => {
    const route = linkOptions({
      to: getInfluencerFilterTabUrl(routeParams.tab) || '/',
      search: { filter: formattedFilterParams }
    });

    if (!route) {
      enqueueSnackbar(t('Unsupported search on this page'), { variant: 'error' });
    }

    return route;
  };

  const resetCurrentSearchParams = () => {
    if (tab) {
      // Current requirement is clear all the filter when chatbox closed
      // https://adasiaholdings.atlassian.net/browse/AT-5859
      const route = generateInfluencerSearchRoute({ tab });

      if (route) {
        navigate(route);
      }
    }
  };

  const resetRetryCounter = () => {
    if (retryCounter < MAX_RETRY) {
      setRetryCounter(MAX_RETRY);
    }
  };

  // Because only chat page has the messages list
  // We need to navigate to chat page in case response not READY_TO_SEARCH to view the chat
  const navigateToChatPage = () => {
    if (!matchRoute({ to: '/chat-demo/chat' })) {
      navigate({ to: '/chat-demo/chat' });
    }
  };

  const handleOpenAiResponse = (response: OpenAIMessage) => {
    switch (response.nextAction) {
      case OpenAINextAction.ERROR:
      case OpenAINextAction.WAITING_FOR_RESPONSE:
        enqueueSnackbar(t('Can not get the response message'), { variant: 'error' });
        removeLastTypingMessage();
        break;
      case OpenAINextAction.READY_TO_SEARCH:
        {
          const route = generateInfluencerSearchRoute({
            ...formattedFilterParams,
            tab,
            includeIds: response.influencerIds
          });

          if (response.text) {
            updateMessages([{ position: 'left', text: response.text }]);
          }

          if (route) {
            const SHOW_LINK_MESSAGE = false; // https://adasiaholdings.atlassian.net/browse/AT-6197 Temporary hide for the demo
            const isShowLinkMessage = SHOW_LINK_MESSAGE && isChatDemoPage;

            // TODO DEMO:
            // If we are in chat demo page, we will show the "Back to list" link to let user clicks manually
            // Else we are in influencers search page, we will update the search filter
            if (isShowLinkMessage) {
              updateMessages([
                {
                  position: 'left',
                  searchLink: {
                    to: route,
                    label: 'インフルエンサー検索の結果',
                    // @ts-expect-error state is not implemented
                    state: { skipSearch: !response.influencerIds.length }
                  }
                }
              ]);
            } else {
              removeLastTypingMessage();
              navigate({ ...route, search: { state: { skipSearch: !response.influencerIds.length } } });
            }
          }

          resetRetryCounter();
        }
        break;
      default:
        if (response.text) {
          navigateToChatPage();
          updateMessages([{ position: 'left', text: response.text }]);
        } else {
          enqueueSnackbar(t(`OpenAi doesn't respond`), { variant: 'error' });
          removeLastTypingMessage();
        }
        resetRetryCounter();
        break;
    }
  };

  const resendOpenAiMessage = async (runId?: QueryopenAiMessageArgs['runId']) => {
    if (!threadId || !runId) {
      return;
    }

    updateMessages([{ position: 'left', typing: true }]);

    try {
      const { data: openAiData } = await getOpenAiMessage({
        variables: { runId, sourceFrom, threadSystemId: threadId }
      });

      if (openAiData.openAiMessage) {
        handleOpenAiResponse(openAiData.openAiMessage);
      } else {
        enqueueSnackbar(t('Can not get the response message'), { variant: 'error' });
        removeLastTypingMessage();
      }
    } catch (error) {
      enqueueSnackbar(t(error.message || 'Got unknown error after recall api'), { variant: 'error' });
      removeLastTypingMessage();
    }
  };

  const sendOpenAiMessage = async (openAiThreadId?: number, message?: ChatDemoMessageType['text']) => {
    if (!openAiThreadId || !message) {
      return;
    }

    updateMessages([{ position: 'left', typing: true }]);

    try {
      const { data } = await callSendOpenAiMessage({
        variables: { input: { id: openAiThreadId, message, sourceFrom } }
      });

      if (data?.sendOpenAiMessage?.message) {
        const response = { ...data.sendOpenAiMessage.message };

        // AI still process the answer, we need to call another API to get answer
        if (response.nextAction === OpenAINextAction.WAITING_FOR_RESPONSE) {
          await resendOpenAiMessage(response.runId);
        } else {
          handleOpenAiResponse(response);
        }
      } else {
        enqueueSnackbar(t('Can not get the response message'), { variant: 'error' });
        removeLastTypingMessage();
      }
    } catch (error) {
      enqueueSnackbar(t(error.message || 'Got unknown error after call api'), { variant: 'error' });
      removeLastTypingMessage();
    }
  };

  const addNewMessages = async (newMessages: ChatDemoMessageType[]) => {
    if (!newMessages.length || messages.at(-1)?.typing) {
      return;
    }

    updateMessages(newMessages); // Always add new message into the list

    // Only call api if messages come from 'right' position. Mean sent by user
    const isMessage = newMessages.length === 1 && !!newMessages.at(0)?.text && newMessages.at(0)?.position === 'right';

    if (!isMessage) {
      return;
    }

    if (!threadId) {
      updateMessages([{ position: 'left', typing: true }]);
      await createOpenAiThread({
        onSuccess: (newThreadId) => {
          sendOpenAiMessage(newThreadId, newMessages.at(0)?.text);
        },
        onError: removeLastTypingMessage
      });
    } else {
      await sendOpenAiMessage(threadId, newMessages.at(0)?.text);
    }
  };

  return {
    ...demoStates,
    isSupportedSNS,
    addNewMessages,
    resetCurrentSearchParams
  };
};
