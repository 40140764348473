import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { TwitterInfluencersProps } from './schemaTypes';
import { TwitterInfluencersSort } from './TwitterInfluencersSort';
import { TwitterInfluencersList } from './TwitterInfluencersList';
import { TwitterInfluencersFilter } from './TwitterInfluencersFilter';
import { TwitterInfluencersProvider } from './TwitterInfluencersContext';

export const TwitterInfluencers = (props: TwitterInfluencersProps) => (
  <TwitterInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <TwitterInfluencersFilter />
      <TwitterInfluencersSort />
    </InfluencersFilterWrapper>

    <TwitterInfluencersList />
  </TwitterInfluencersProvider>
);

export * from './schemaTypes';
