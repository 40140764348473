import { FormProvider } from '@/shared/atoms';
import { AccountManagerPlanSchema, AccountManagerRequiredPlanSchema } from './schema';
import { Template } from './Template';
import { AccountManagerPlanFormProps, AccountManagerPlanFormValues } from './types';

export const AccountManagerPlanForm = ({
  onSubmit,
  className,
  defaultValues = { accountManagerPlan: '' }
}: AccountManagerPlanFormProps) => (
  <FormProvider<AccountManagerPlanFormValues>
    onSubmit={onSubmit}
    className={className}
    defaultValues={defaultValues}
    zodSchema={defaultValues?.accountManagerPlan ? AccountManagerRequiredPlanSchema : AccountManagerPlanSchema}
  >
    <Template />
  </FormProvider>
);

export * from './types';
