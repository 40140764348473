import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '@/shared/utils';
import { RangeSliderInputField } from '@/shared/molecules';
import { MIN_MAX_REACH_OPTIONS, MIN_REACH, MAX_REACH } from '@/shared/constants';
import { InfluencersFilterFormKeys } from '../types';

export const ReachField = () => {
  const { t } = useTranslation();

  return (
    <RangeSliderInputField<InfluencersFilterFormKeys>
      step={10000}
      title={t('Reach')}
      formatLabel={formatIntNumber}
      options={MIN_MAX_REACH_OPTIONS}
      range={{ min: MIN_REACH, max: MAX_REACH }}
      minField={{ name: 'minReach', placeholder: formatIntNumber(MIN_REACH) }}
      maxField={{ name: 'maxReach', placeholder: `${formatIntNumber(MAX_REACH)}+` }}
    />
  );
};
