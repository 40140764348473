import { InstagramCompareOverview, useInstagramCompareOverviewQuery } from '@/graphql';
import { AnalyticsCompareOverviewWrapper } from '../../shared';
import { AnalyticsCompareInstagramAccountsProps } from '../AnalyticsCompareInstagramAccounts';
import { useAnalyticsProfileSearch } from '../../../hooks';
import { Template } from './Template';

export interface AnalyticsCompareInstagramOverviewProps
  extends Pick<
    AnalyticsCompareInstagramAccountsProps,
    'accounts' | 'mainAccountId' | 'hiddenAccountIds' | 'hasFreePlan'
  > {
  overviewDataMock?: InstagramCompareOverview;
}

export const AnalyticsCompareInstagramOverview = ({
  accounts,
  mainAccountId,
  hiddenAccountIds,
  hasFreePlan,
  overviewDataMock
}: AnalyticsCompareInstagramOverviewProps) => {
  const { filter } = useAnalyticsProfileSearch();
  const { data, loading } = useInstagramCompareOverviewQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      mainAccountId,
      endDate: filter.endDate || null,
      startDate: filter.startDate || null,
      comparableAccountIds: accounts.reduce<number[]>(
        (acc, curr) => (curr.id === mainAccountId ? acc : [...acc, curr.id]),
        []
      )
    },
    skip: hasFreePlan
  });

  const overviewData = hasFreePlan ? overviewDataMock : data?.instagramCompareOverview;

  return (
    <AnalyticsCompareOverviewWrapper
      loading={loading}
      data={overviewData}
      dataNotAvailable={!overviewData || !(overviewData.compareAccounts.length || overviewData.mainAccount)}
    >
      {({ data: overviewData }) => (
        <Template
          defaultSelectedLegendKeys={hasFreePlan ? ['followers', 'posts'] : ['followers']}
          mainAccountId={mainAccountId}
          comparableAccounts={accounts.filter((account) => !hiddenAccountIds?.includes(account.id))}
          data={[
            overviewData.mainAccount,
            ...overviewData.compareAccounts.filter((account) => account.id !== overviewData.mainAccount.id)
          ]}
        />
      )}
    </AnalyticsCompareOverviewWrapper>
  );
};
