import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@/shared/atoms';
import { useAllTalentAgencyCompanyQuery } from '@/graphql';
import { Header, ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useTalentAgencyPermissions, useUserRoles } from '@/auth';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { TalentAgenciesFilter, TalentAgenciesList, useAccountsFilter } from '@/shared/organisms';

export const TalentAgencies = () => {
  useUpdateDocumentTitle({ title: 'appHeader.Talent Agency', appHeader: 'appHeader.Talent Agency' });
  useCustomHeader();
  const { t } = useTranslation();
  const { isAdmin } = useUserRoles();
  const { page, limit, filter } = useAccountsFilter();
  const { hideAddTalentAgencyBtn } = useTalentAgencyPermissions();
  const { data, previousData, loading } = useAllTalentAgencyCompanyQuery({
    fetchPolicy: 'network-only',
    variables: getValuableVariables({
      ...filter,
      limit,
      offset: getOffset(page, limit),
      verifiedStatus: filter.verifiedStatus || null
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allTalentAgenciesCount?.totalNumber;

  return (
    <>
      <Header>
        {isAdmin && !hideAddTalentAgencyBtn ? (
          <ButtonLink
            variant="blue"
            title={t('Button.Add Talent Agency')}
            to="/talent-agency/add"
            prefixIcon={{ icon: 'plus', size: '10px' }}
            css={{ minWidth: '109px', padding: '0 16px', textTransform: 'capitalize', marginLeft: 'auto' }}
          />
        ) : null}
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <TalentAgenciesFilter />
        <TalentAgenciesList loading={loading} totalRecords={totalRecords} data={result?.allTalentAgencyCompany || []} />
      </ListWithPagination>
    </>
  );
};
