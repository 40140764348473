import { TTCMStatus, useTiktokProfileAudienceQuery } from '@/graphql';
import { ChartsContainer } from '@/shared/atoms';
import { HAS_NO_ESTIMATED_TEXT } from '@/shared/constants';
import { AgeBreakdownChart, AreaBreakdownChart, GenderBreakdownChart } from '@/shared/molecules';
import { useInfluencerProfileOpacityContent } from '../../hooks';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerProfileAudienceWrapper } from '../shared';

export interface InfluencerTiktokProfileAudienceProps {
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerTiktokProfileAudience = ({
  influencerId,
  socialAccount
}: InfluencerTiktokProfileAudienceProps) => {
  const { opacityContent, hasAudienceDemographics } = useInfluencerProfileOpacityContent({
    influencerId,
    socialAccount
  });
  const { data, loading } = useTiktokProfileAudienceQuery({
    variables: { pk: influencerId, socialAccountId: socialAccount.id }
  });

  const isTTCMCreator = !!socialAccount.ttcmStatus && socialAccount.ttcmStatus === TTCMStatus.APPROVED;
  const chartOpacityContent = hasAudienceDemographics ? opacityContent : { wrapperText: HAS_NO_ESTIMATED_TEXT };

  return (
    <InfluencerProfileAudienceWrapper
      loading={loading}
      data={data?.tiktokProfileAudience}
      hasAudienceDemographics={hasAudienceDemographics}
    >
      {({ data: { maleRate, femaleRate, ageRates, countryRates } }) => (
        <ChartsContainer css={{ height: '385px' }}>
          <GenderBreakdownChart maleRate={maleRate} femaleRate={femaleRate} {...chartOpacityContent} />
          <AgeBreakdownChart ageGroup={ageRates.ageGroup} totalRate={ageRates.rate} {...chartOpacityContent} />
          {isTTCMCreator ? (
            <AreaBreakdownChart
              followerCountryRate={countryRates?.map((item) => ({ id: item.countryId, rate: item.rate })) || []}
              hasCountriesRates
            />
          ) : null}
        </ChartsContainer>
      )}
    </InfluencerProfileAudienceWrapper>
  );
};
