import { useTranslation } from 'react-i18next';
import { useParams } from '@tanstack/react-router';
import { Tabs } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useUserRoles } from '@/auth';

export const PackageContentTabs = () => {
  const { t } = useTranslation();
  const params = useParams({ from: '/_private-routes/package/$id/content' });
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const routeId = params.id || '';

  return (
    <Tabs
      options={[
        {
          end: false,
          label: t('Influencer List'),
          to: '/package/$id/content/list',
          params: {
            id: routeId
          }
        },
        {
          end: false,
          label: t('Add Influencer'),
          to: '/package/$id/content/search',
          params: {
            id: routeId
          },
          hidden: !isAdminStaffTalentAgencyPartner
        }
      ]}
      css={{
        padding: '0 10px',
        boxShadow: THEME.shadows.boxShadow,
        '.tab-title': { padding: '0 8px' }
      }}
    />
  );
};
