import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RefreshProfileAccount.graphql';
import { Mutation, MutationrefreshProfileAccountArgs } from '../../__generated__/globalTypes';

export const useRefreshProfileAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationrefreshProfileAccountArgs>
) => {
  const [callRefreshProfileAccount, result] = useMutation<Mutation, MutationrefreshProfileAccountArgs>(
    MUTATION,
    options
  );

  return { callRefreshProfileAccount, ...result };
};

export type RefreshProfileAccountMutationFunction = Mutation['refreshProfileAccount'];
