import { RenderDataWithFallback } from '@/shared/atoms';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';
import { useMarketplaceInfluencersWhiteList } from './hooks';
import { MarketplaceInfluencersForOtherUsers } from './MarketplaceInfluencersForOtherUsers';
import { MarketplaceInfluencersForAdminStaff } from './MarketplaceInfluencersForAdminStaff';

export const MarketplaceInfluencers = () => {
  const { loading, influencerWhitelist } = useMarketplaceInfluencersWhiteList();
  const { forceInfluencersParticipateCampaign } = useMarketplaceDetailsContext();

  return (
    <RenderDataWithFallback loading={loading}>
      {influencerWhitelist ? (
        forceInfluencersParticipateCampaign ? (
          <MarketplaceInfluencersForAdminStaff influencerWhitelist={influencerWhitelist} />
        ) : (
          <MarketplaceInfluencersForOtherUsers influencerWhitelist={influencerWhitelist} />
        )
      ) : null}
    </RenderDataWithFallback>
  );
};
