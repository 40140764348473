import { useState } from 'react';
import { linkOptions, useParams } from '@tanstack/react-router';
import { ChatBoxMessageType } from '@/shared/atoms';
import { useParamsSearch, useQueryHelper } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import {
  OpenAIMessage,
  GeminiMessage,
  GeminiNextAction,
  GeminiSourceFrom,
  OpenAINextAction,
  QueryopenAiMessageArgs,
  useOpenAiMessagePromiseQuery,
  useSendOpenAiMessageMutation,
  useSendGeminiMessageMutation
} from '@/graphql';
import { InfluencersTabType } from '@/shared/types';
import { getInfluencerFilterTabUrl, getOpenAiSourceFrom } from '../../utils';
import { useManageChatBoxStates } from './useManageChatBoxStates';
import { useInfluencerAISearchPathnames } from './useInfluencerAISearchPathnames';

const MAX_RETRY = 5;

export const useInitInfluencerAISearchWidget = () => {
  const { t, navigate } = useQueryHelper();
  const params = useParams({ strict: false });
  const [retryCounter, setRetryCounter] = useState(MAX_RETRY);
  const { limit, filter } = useParamsSearch();

  const { getOpenAiMessage } = useOpenAiMessagePromiseQuery();
  const { callSendOpenAiMessage } = useSendOpenAiMessageMutation();
  const { callSendGeminiMessage } = useSendGeminiMessageMutation();
  const { isSupportedSNS, isInfluencersSearchPage, isEngagementSearchPage } = useInfluencerAISearchPathnames();
  const {
    threadId,
    messages,
    isOpening,
    chatBoxError,
    isOpenAiModel,
    openChatBox,
    closeChatBox,
    refreshChatBox,
    clearMessages,
    updateMessages,
    setChatBoxError,
    clearCharBoxError,
    removeLastTypingMessage
  } = useManageChatBoxStates();

  const tab = filter.tab;
  const sourceFrom = getOpenAiSourceFrom(tab);

  const generateInfluencerSearchRoute = (routeParams: { tab: InfluencersTabType; includeIds?: readonly number[] }) => {
    const route = isInfluencersSearchPage
      ? linkOptions({ to: getInfluencerFilterTabUrl(routeParams.tab), search: { filter: routeParams, limit } })
      : isEngagementSearchPage
        ? linkOptions({
            to: getInfluencerFilterTabUrl(routeParams.tab, true),
            params,
            search: { filter: routeParams, limit }
          })
        : null;

    if (!route) {
      setChatBoxError({ message: t('Unsupported search on this page') });
    }

    return route;
  };

  const resetCurrentSearchParams = () => {
    if (tab) {
      // Current requirement is clear all the filter when chatbox closed
      // https://adasiaholdings.atlassian.net/browse/AT-5859
      const route = generateInfluencerSearchRoute({ tab });

      if (route) {
        navigate(route);
      }
    }
  };

  const resetRetryCounter = () => {
    if (retryCounter < MAX_RETRY) {
      setRetryCounter(MAX_RETRY);
    }
  };

  const handleResponse = (response: OpenAIMessage | GeminiMessage) => {
    switch (response.nextAction) {
      case GeminiNextAction.ERROR:
      case OpenAINextAction.ERROR:
      case OpenAINextAction.WAITING_FOR_RESPONSE:
        setChatBoxError(
          isOpenAiModel
            ? { type: 'generate-failed', runId: (response as OpenAIMessage)?.runId }
            : { message: 'Something went wrong!' }
        );
        break;
      case GeminiNextAction.READY_TO_SEARCH:
      case OpenAINextAction.READY_TO_SEARCH:
        {
          const route = tab ? generateInfluencerSearchRoute({ tab, includeIds: response.influencerIds }) : '';

          if (response.text) {
            updateMessages([{ position: 'left', text: response.text, copyable: true, regenerable: true }]);
          }

          if (route) {
            removeLastTypingMessage();
            navigate({ ...route, search: { ...route.search, state: { skipSearch: !response.influencerIds.length } } });
          }

          resetRetryCounter();
        }
        break;
      case GeminiNextAction.COLLECTING:
      case OpenAINextAction.COLLECTING:
      default:
        if (response.text) {
          updateMessages([{ position: 'left', text: response.text, copyable: true, regenerable: true }]);
        } else {
          setChatBoxError({ message: t("OpenAi doesn't respond") });
        }
        resetRetryCounter();
        break;
    }
  };

  const resendOpenAiMessage = async (runId?: QueryopenAiMessageArgs['runId']) => {
    if (!threadId || !runId) {
      return;
    }

    updateMessages([{ position: 'left', typing: true }]);

    try {
      const { data: openAiData } = await getOpenAiMessage({
        variables: { runId, sourceFrom, threadSystemId: threadId }
      });

      if (openAiData.openAiMessage) {
        handleResponse(openAiData.openAiMessage);
      } else {
        setChatBoxError({ type: 'generate-failed' });
      }
    } catch (error) {
      setChatBoxError({ message: t(error.message || 'Got unknown error after recall api') });
    }
  };

  const sendChatAiMessage = async (chatBoxMessage?: ChatBoxMessageType) => {
    const message = chatBoxMessage?.text;
    const imageUrl = (chatBoxMessage?.file?.type === 'image' && chatBoxMessage.file.url) || '';

    if (!threadId || !(message || imageUrl)) return;

    updateMessages([{ position: 'left', typing: true }]);

    try {
      if (isOpenAiModel) {
        const { data } = await callSendOpenAiMessage({
          variables: { input: getValuableVariables({ id: threadId, message, imageUrl, sourceFrom }) }
        });

        if (data?.sendOpenAiMessage?.message) {
          const response = { ...data.sendOpenAiMessage.message };

          // AI still process the answer, we need to call another API to get answer
          if (response.nextAction === OpenAINextAction.WAITING_FOR_RESPONSE) {
            await resendOpenAiMessage(response.runId);
          } else {
            handleResponse(response);
          }
        } else {
          setChatBoxError({ type: 'generate-failed' });
        }
      } else {
        const { data } = await callSendGeminiMessage({
          variables: {
            input: getValuableVariables({
              id: threadId,
              message,
              imageUrl,
              sourceFrom: sourceFrom as unknown as GeminiSourceFrom
            })
          }
        });

        if (data?.sendGeminiMessage?.message) {
          handleResponse(data.sendGeminiMessage.message);
        } else {
          setChatBoxError({ message: 'Something went wrong!' });
        }
      }
    } catch (error) {
      setChatBoxError({ message: t(error.message || 'Got unknown error after call api') });
    }
  };

  const addNewMessages = async (newMessages: ChatBoxMessageType[]) => {
    if (!newMessages.length || !threadId) return;

    if (chatBoxError) {
      clearCharBoxError();
    }

    updateMessages(newMessages); // Always add new message into the list

    // Only call api if messages come from 'right' position. Mean sent by user
    const isFiles = newMessages.every((message) => !!message.file && !message.text && message.position === 'right');
    const isMessage = newMessages.length === 1 && !!newMessages.at(0)?.text && newMessages.at(0)?.position === 'right';

    if (!isFiles && !isMessage) {
      return;
    }

    await sendChatAiMessage(newMessages.at(0));
  };

  const regenerateResponse = async () => {
    // Backup runId then clear error to return back screen first before calling resend api
    const runId = chatBoxError?.runId;
    clearCharBoxError();

    if (runId) {
      await resendOpenAiMessage(runId);
    } else {
      await sendChatAiMessage({ position: 'right', text: 'Hello!' });
    }

    setRetryCounter((prevCounter) => prevCounter--);
  };

  const regenerateMessage = async (index: number) => {
    const message = messages.at(index);
    const previousMessage = messages.at(index - 1);

    if (
      !index || // Ignore first default hello message
      !message ||
      message.typing ||
      !message.regenerable ||
      !previousMessage || // We need to resend previous message again
      !(previousMessage.text || previousMessage.file) || // it has to have text or file
      previousMessage.position !== 'right' // must be sent by user
    )
      return;

    clearMessages(index);
    await sendChatAiMessage(previousMessage);
  };

  return {
    isOpening,
    messages,
    openChatBox,
    closeChatBox,
    chatBoxError,
    isOpenAiModel,
    refreshChatBox,
    isSupportedSNS,
    addNewMessages,
    setChatBoxError,
    regenerateMessage,
    resetCurrentSearchParams,
    regenerateResponse: retryCounter ? regenerateResponse : undefined
  };
};
