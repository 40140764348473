import { z } from 'zod';
import { zodValidator } from '@tanstack/zod-adapter';
import {
  RedirectAnalyticsConnect,
  RedirectAnalyticsListConnect,
  RedirectAnalyticsSignin,
  RedirectHiddenYoutubeListConnect,
  RedirectTalentInfluencerSignIn,
  RedirectTalentAnalyticsConnect,
  RedirectProviderParamsSchema,
  RedirectTikTokBusinessConnect
} from '@/pages/Redirect';
import { PartialRouter } from '../../types';

export const RedirectRouter = {
  '/_private-routes/redirect/analytics-connect/$provider': [
    RedirectAnalyticsConnect,
    {
      params: {
        parse: RedirectProviderParamsSchema.parse
      }
    }
  ],
  '/_private-routes/redirect/analytics-list-connect/$provider': [
    RedirectAnalyticsListConnect,
    {
      params: {
        parse: RedirectProviderParamsSchema.parse
      }
    }
  ],
  '/_private-routes/redirect/analytics-signin/$provider': [
    RedirectAnalyticsSignin,
    {
      params: {
        parse: RedirectProviderParamsSchema.parse
      }
    }
  ],
  '/_private-routes/redirect/connect/tiktok-business': [
    RedirectTikTokBusinessConnect,
    {
      validateSearch: zodValidator(
        z.object({
          auth_code: z.string()
        })
      )
    }
  ],
  '/_private-routes/redirect/hidden/youtube-list-connect': [RedirectHiddenYoutubeListConnect, {}],
  '/_private-routes/redirect/talent-analytics-connect/$provider': [
    RedirectTalentAnalyticsConnect,
    {
      params: {
        parse: RedirectProviderParamsSchema.parse
      }
    }
  ],
  '/_private-routes/redirect/talent-signin/$provider': [
    RedirectTalentInfluencerSignIn,
    {
      params: {
        parse: RedirectProviderParamsSchema.parse
      }
    }
  ]
} as const satisfies PartialRouter;
