import { ReactNode } from 'react';
import { ExternalToast, toast } from 'sonner';

type ToastType = typeof toast;
type PromiseType = Parameters<typeof toast.promise>[0];
type BaseOption = { variant?: Exclude<keyof ToastType, 'promise'> };
type PromiseOption = { variant: 'promise'; promise: PromiseType };

export const useToaster = () => ({
  enqueueSnackbar: (message: string | ReactNode, options?: (ExternalToast & BaseOption) | PromiseOption) => {
    switch (options?.variant) {
      case 'error': {
        const toastId = toast('error');
        return toast.error(message, {
          id: toastId,
          cancel: <CloseToast toastId={toastId} />
        });
      }

      case 'success': {
        const toastId = toast('success');
        return toast.success(message, {
          id: toastId,
          cancel: <CloseToast toastId={toastId} />
        });
      }

      case 'info': {
        const toastId = toast('info');
        return toast.info(message, {
          id: toastId,
          cancel: <CloseToast toastId={toastId} />
        });
      }

      case 'warning': {
        const toastId = toast('warning');
        return toast.warning(message, {
          id: toastId,
          cancel: <CloseToast toastId={toastId} />
        });
      }

      case 'loading': {
        const toastId = toast('loading');
        return toast.loading(message, {
          id: toastId,
          cancel: <CloseToast toastId={toastId} />
        });
      }
      case 'promise': {
        const toastId = toast('promise');
        return toast.promise(options.promise, {
          id: toastId,
          loading: 'Loading...',
          success: (data) => `${(data as { name: string }).name} toast has been added`,
          error: 'Error',
          cancel: <CloseToast toastId={toastId} />
        });
      }

      default: {
        const toastId = toast('default');
        return toast(message, {
          id: toastId,
          cancel: <CloseToast toastId={toastId} />
        });
      }
    }
  }
});

export const CloseToast = ({ toastId }: { toastId: string | number }) => (
  <button
    type="button"
    onClick={() => toast.dismiss(toastId)}
    css={{
      cursor: 'pointer',
      opacity: 0.7,
      marginLeft: 'auto',
      alignSelf: 'flex-start',
      ':hover': {
        opacity: 1
      }
    }}
  >
    &#10005;
  </button>
);
