import { useState } from 'react';
import { useCreateOpenAiThreadMutation, useDeleteOpenAiThreadMutation } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';

export const useManageOpenAiThread = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [threadId, setThreadId] = useState<number | null>(null);
  const { callDeleteOpenAiThread } = useDeleteOpenAiThreadMutation();
  const { callCreateOpenAiThread } = useCreateOpenAiThreadMutation();

  const createOpenAiThread = async ({
    onError,
    onSuccess
  }: {
    onError?: () => void;
    onSuccess?: (openAiThreadId: number) => void;
  }) => {
    try {
      const { data } = await callCreateOpenAiThread();
      const openAiThreadId = data?.createOpenAiThread?.threadId;

      if (openAiThreadId) {
        setThreadId(openAiThreadId);
        onSuccess?.(openAiThreadId);
      } else {
        enqueueSnackbar(t<string>('Can not get threadId after created'), { variant: 'error' });
        onError?.();
      }
    } catch (error) {
      onError?.();
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const deleteOpenAiThread = async (specifyThreadId?: number | null, needResetState = false) => {
    if (!specifyThreadId && !threadId) return;

    try {
      await callDeleteOpenAiThread({ variables: { input: { id: Number(specifyThreadId || threadId) } } });
      if (needResetState) {
        setThreadId(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    threadId,
    setThreadId,
    createOpenAiThread,
    deleteOpenAiThread
  };
};
