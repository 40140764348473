import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerInstagramAnalyticsStoryDetails.graphql';
import { Query, QueryinfluencerInstagramAnalyticsStoryDetailsArgs } from '../../__generated__/globalTypes';

export const useInfluencerInstagramAnalyticsStoryDetailsQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerInstagramAnalyticsStoryDetailsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerInstagramAnalyticsStoryDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerInstagramAnalyticsStoryDetailsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerInstagramAnalyticsStoryDetailsArgs>
): QueryResult<Query, QueryinfluencerInstagramAnalyticsStoryDetailsArgs> & {
  getInfluencerInstagramAnalyticsStoryDetails: LazyQueryExecFunction<
    Query,
    QueryinfluencerInstagramAnalyticsStoryDetailsArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerInstagramAnalyticsStoryDetails, result] = useLazyQuery<
    Query,
    QueryinfluencerInstagramAnalyticsStoryDetailsArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerInstagramAnalyticsStoryDetails, ...result };
};

export const useInfluencerInstagramAnalyticsStoryDetailsPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerInstagramAnalyticsStoryDetails = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerInstagramAnalyticsStoryDetailsArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerInstagramAnalyticsStoryDetailsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerInstagramAnalyticsStoryDetails };
};
