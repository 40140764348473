import { QueryHookOptions } from '@apollo/client';
import { Query, useEngagementPostInfluencersQuery, QueryengagementPostInfluencersArgs } from '@/graphql';
import { getOptions } from '@/shared/utils';

interface Props {
  engagementId: number;
  options?: QueryHookOptions<Query, QueryengagementPostInfluencersArgs>;
}

export const useEngagementPostInfluencersOptions = ({ engagementId, options }: Props) => {
  const { data, loading } = useEngagementPostInfluencersQuery({
    fetchPolicy: 'cache-and-network',
    variables: { pk: engagementId },
    ...options
  });

  const influencers = data?.engagementPostInfluencers || [];

  return {
    loading,
    influencers,
    influencerOptions: getOptions(influencers)
  };
};
