import { Trans } from 'react-i18next';
import {
  EngagementPostDetail,
  CampaignSocialMediaType,
  EngagementCampaignPostStatus,
  useRejectEngagementDraftPostMutation,
  useReportEngagementPostStatusMutation
} from '@/graphql';
import { Modal, ModalTitle, ModalContent, ModalFooterActions, FormProvider } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useToggleState, useQueryHelper, useSelectItem } from '@/shared/hooks';
import { SelectorField, TextAreaField } from '@/shared/molecules';

interface Props {
  isTtcmCampaign: boolean;
  refetchQueries?: string[];
}

interface RejectFormValues {
  reason: string;
  detailedReason: string;
}

export const useRejectEngagementPost = ({ isTtcmCampaign, refetchQueries }: Props) => {
  const loadingState = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem<EngagementPostDetail>();
  const { callRejectEngagementDraftPost } = useRejectEngagementDraftPostMutation({ refetchQueries });
  const { callReportEngagementPostStatus } = useReportEngagementPostStatusMutation({ refetchQueries });

  const hasAskToEdit =
    !!selectedItem &&
    [CampaignSocialMediaType.YOUTUBE, CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.FACEBOOK].includes(
      selectedItem.socialMedia
    );
  const hasAskToRePost =
    !!selectedItem &&
    [CampaignSocialMediaType.TIKTOK, CampaignSocialMediaType.TWITTER, CampaignSocialMediaType.INSTAGRAM_STORY].includes(
      selectedItem.socialMedia
    );
  const isTiktokPost = selectedItem?.socialMedia === CampaignSocialMediaType.TIKTOK;
  const isReviewing = selectedItem?.status === EngagementCampaignPostStatus.REVIEWING;
  const isTtcmReviewPost = isTiktokPost && isTtcmCampaign && isReviewing;

  const getSocialMediaText = (socialMedia: CampaignSocialMediaType) => {
    switch (socialMedia) {
      case CampaignSocialMediaType.INSTAGRAM:
        return t('Instagram Feed post');
      case CampaignSocialMediaType.INSTAGRAM_STORY:
        return t('Instagram Story post');
      case CampaignSocialMediaType.YOUTUBE:
        return t('YouTube video');
      case CampaignSocialMediaType.DOUYIN:
        return t('Douyin video');
      case CampaignSocialMediaType.TIKTOK:
        return t('TikTok video');
      case CampaignSocialMediaType.TWITTER:
        return t('X(Twitter) post');
      default:
        return '';
    }
  };

  const handleSubmit =
    (onSuccess?: () => void) =>
    async ({ reason, detailedReason }: RejectFormValues) => {
      if (!selectedItem?.id) return;
      loadingState.open();

      // Either effective or warning_solved status can be changeable.
      const isTikTokTtcmPost = isTiktokPost && isTtcmCampaign;
      const variables = {
        input: {
          id: selectedItem?.id,
          ...(isTikTokTtcmPost ? { reason: detailedReason } : { reason, detailedReason })
        }
      };

      try {
        if (!isReviewing) {
          await callReportEngagementPostStatus({ variables });
        } else {
          await callRejectEngagementDraftPost({ variables }); // we can only reject reviewing status
        }
        onSuccess?.();
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        loadingState.close();
        resetSelectedItem();
      }
    };

  return {
    handleRejectPost: setSelectedItem,
    RejectEngagementPostModal: ({ onSuccess }: { onSuccess?: () => void }) => (
      <Modal css={{ width: THEME.modal.size.lv2 }} open={!!selectedItem?.id} onClose={resetSelectedItem}>
        <FormProvider<RejectFormValues>
          onSubmit={handleSubmit(onSuccess)}
          defaultValues={{ reason: selectedItem?.reason || '', detailedReason: selectedItem?.detailedReason || '' }}
        >
          {({ values }) => (
            <>
              <ModalContent>
                <ModalTitle css={{ marginBottom: '10px' }}>{t('Dialog.Report Inappropriate')}</ModalTitle>

                {hasAskToEdit || hasAskToRePost ? (
                  <p css={{ marginBottom: '16px' }}>
                    {hasAskToEdit ? (
                      <>
                        {getSocialMediaText(selectedItem.socialMedia)}&nbsp;
                        <Trans i18nKey="Dialog.ReportInappropriateEditDescription" components={{ b: <b /> }} />
                      </>
                    ) : hasAskToRePost ? (
                      <Trans i18nKey="Dialog.ReportInappropriateRePostDescription" components={{ b: <b /> }} />
                    ) : null}
                  </p>
                ) : null}

                {!isTtcmReviewPost ? (
                  <SelectorField<keyof RejectFormValues>
                    name="reason"
                    title={t('Selector.Reason')}
                    options={[
                      { label: t<string>('Option.Inappropriate contents'), value: 'Inappropriate contents' },
                      {
                        label: t<string>('Option.Doesn’t match the conditions'),
                        value: 'Doesn’t match the conditions'
                      },
                      ...(isReviewing ? [{ label: t<string>('Option.Other'), value: 'Other' }] : [])
                    ]}
                    required
                  />
                ) : null}

                <TextAreaField<keyof RejectFormValues>
                  name="detailedReason"
                  required={!!isTtcmReviewPost}
                  title={t('TextForm.Detailed Reason')}
                  help={!isTtcmReviewPost ? t('Tooltip.Detailed Reason Help') : undefined}
                  placeholder={!isTtcmReviewPost ? t<string>('TextForm.Detailed Reason Placeholder') : ''}
                />
              </ModalContent>

              <ModalFooterActions
                cancelButtonProps={{ onClick: resetSelectedItem }}
                submitButtonProps={{
                  type: 'submit',
                  title: t('Button.Send'),
                  loading: loadingState.status,
                  disabled: isTtcmReviewPost ? !values.reason || !values.detailedReason : !values.reason
                }}
              />
            </>
          )}
        </FormProvider>
      </Modal>
    )
  };
};
