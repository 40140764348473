import { InstagramCompareFeedPost } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InstagramComparePostDetailsModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { useAnalyticsInstagramComparePostsListTable } from './hooks';

export type AnalyticsInstagramComparePostsListProps = TableData<InstagramCompareFeedPost>;

export const AnalyticsInstagramComparePostsList = ({ loading, data = [] }: AnalyticsInstagramComparePostsListProps) => {
  const { rows, columns } = useAnalyticsInstagramComparePostsListTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InstagramComparePostDetailsModal posts={data} />
    </>
  );
};
