import {
  SocialAccountType,
  CompanyAndRolePair,
  useCreatePackageWithSocialAccountsMutation,
  useConnectPackageWithSocialAccountsMutation,
  useConnectPackageWithInfluencerAccountsMutation,
  useCreatePackageWithInfluencerAccountsMutation,
  namedOperations
} from '@/graphql';
import { Button, FormProvider } from '@/shared/atoms';
import { useQueryHelper, useAllPackageCompanyOptions, useToggleState } from '@/shared/hooks';
import { AddPackageWithAccountsSchema } from './schema';
import { Template } from './Template';
import { PackageWithAccountsFormValues } from './types';

interface AddPackageWithAccountsProps {
  className?: string;
  socialPairs: {
    influencerId: number;
    socialAccountId?: number | null;
    socialType?: SocialAccountType | null;
  }[];
  isInfluencerAccount?: boolean;
  influencerIds?: number[];
}

export const AddPackageWithAccounts = ({
  className,
  socialPairs,
  influencerIds = [],
  isInfluencerAccount
}: AddPackageWithAccountsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { status: isOpenModal, toggleStatus: toggleModal } = useToggleState();
  const { allPackageCompanies, allPackageCompanyOptions } = useAllPackageCompanyOptions();

  const { callConnectPackageWithSocialAccounts } = useConnectPackageWithSocialAccountsMutation();
  const { callCreatePackageWithSocialAccounts } = useCreatePackageWithSocialAccountsMutation({
    refetchQueries: [namedOperations.Query.AllExistingPackages]
  });
  const { callConnectPackageWithInfluencerAccounts } = useConnectPackageWithInfluencerAccountsMutation();
  const { callCreatePackageWithInfluencerAccounts } = useCreatePackageWithInfluencerAccountsMutation({
    refetchQueries: [namedOperations.Query.AllExistingPackages]
  });

  const count = (isInfluencerAccount ? influencerIds.length : socialPairs.length) || 0;

  const handleSubmit = async (formValues: PackageWithAccountsFormValues) => {
    try {
      if (formValues.type === 'add') {
        const companyAndRolePairs =
          allPackageCompanies.reduce<CompanyAndRolePair[]>((rs, cr) => {
            if (formValues.companies?.includes(String(cr.id))) {
              rs.push({ companyId: cr.companyId, role: cr.role });
            }

            return rs;
          }, []) || [];

        if (formValues.name && formValues.countryId) {
          isInfluencerAccount
            ? await callCreatePackageWithInfluencerAccounts({
                variables: {
                  input: {
                    influencerIds,
                    companyAndRolePairs,
                    name: formValues.name,
                    countryId: formValues.countryId
                  }
                }
              })
            : await callCreatePackageWithSocialAccounts({
                variables: {
                  input: { name: formValues.name, countryId: formValues.countryId, socialPairs, companyAndRolePairs }
                }
              });
        }
      } else if (formValues.type === 'edit') {
        isInfluencerAccount
          ? await callConnectPackageWithInfluencerAccounts({
              variables: {
                input: {
                  influencerIds,
                  packageId: Number(formValues.id)
                }
              }
            })
          : await callConnectPackageWithSocialAccounts({
              variables: { input: { socialPairs, packageId: Number(formValues.id) } }
            });
      }

      enqueueSnackbar(t('package has been saved successfully'), { variant: 'success' });
      toggleModal();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <Button
        variant="white"
        onClick={toggleModal}
        className={className}
        title={t('Button.package')}
        css={{ minWidth: '108px' }}
        prefixIcon={{ icon: 'plus', size: '10px' }}
      />

      <FormProvider<PackageWithAccountsFormValues>
        onSubmit={handleSubmit}
        zodSchema={AddPackageWithAccountsSchema}
        defaultValues={{ id: '', type: 'edit' }}
        css={{ width: 'auto' }}
      >
        <Template
          count={count}
          toggleModal={toggleModal}
          isOpenModal={isOpenModal}
          allPackageCompanyOptions={allPackageCompanyOptions}
        />
      </FormProvider>
    </>
  );
};
