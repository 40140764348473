import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RemoveAllSelectedInfluencers.graphql';
import { Mutation, MutationremoveAllSelectedInfluencersArgs } from '../../__generated__/globalTypes';

export const useRemoveAllSelectedInfluencersMutation = (
  options?: MutationHookOptions<Mutation, MutationremoveAllSelectedInfluencersArgs>
) => {
  const [callRemoveAllSelectedInfluencers, result] = useMutation<Mutation, MutationremoveAllSelectedInfluencersArgs>(
    MUTATION,
    options
  );

  return { callRemoveAllSelectedInfluencers, ...result };
};

export type RemoveAllSelectedInfluencersMutationFunction = Mutation['removeAllSelectedInfluencers'];
