import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { LIMIT_50 } from '@/shared/constants';
import { InstagramUserHashtagPostsSortOrder, Order } from '@/graphql';
import { getYesterday } from '@/shared/utils';

export const HashtagsAnalyticsFilterSchema = z.object({
  date: FilterValidations.string(),
  keyword: FilterValidations.string(),
  hashtagId: FilterValidations.number()
});
export const HashtagsAnalyticsSearchSchema = z.object({
  filter: HashtagsAnalyticsFilterSchema.default({
    date: getYesterday(),
    keyword: '',
    hashtagId: ''
  }),
  sort: SearchFilterDefaults.sort(InstagramUserHashtagPostsSortOrder).default({
    order: Order.ASC,
    field: InstagramUserHashtagPostsSortOrder.RANK
  }),
  ...SearchFilterDefaults.pageLimit(LIMIT_50)
});

export type HashtagsAnalyticsSearchSchemaType = z.infer<typeof HashtagsAnalyticsSearchSchema>;
export type HashtagsAnalyticsFilterFormValues = z.infer<typeof HashtagsAnalyticsFilterSchema>;
export type HashtagsAnalyticsFilterFormKeys = UnionOfKeys<HashtagsAnalyticsFilterFormValues>;
