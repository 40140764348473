import { useYoutubeAnalyticsCompareAccountCardsQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useQueryHelper, useSelectItemIds } from '@/shared/hooks';
import {
  AnalyticsCompareYoutubePosts,
  AnalyticsCompareYoutubeAccounts,
  AnalyticsCompareYoutubeOverview,
  AnalyticsCompareYoutubeTagsRanking,
  AnalyticsCompareYoutubeAccountsProps,
  AnalyticsYoutubeComparableAccountType
} from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';
import { FreePlanContentOpacity } from '../../shared/AnalyticsUpgradePlan';
import { compareMock } from '../AnalyticsCompareInstagram/compare.mock';

export const AnalyticsCompareYoutube = ({ hasFreePlan }: { hasFreePlan: boolean }) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { selectedItemIds: hiddenAccountIds, selectItem: setHiddenAccountId } = useSelectItemIds<number>();
  const {
    selectedSocialAccount,
    brandAccount: { brandAccountName }
  } = useAnalyticsProfileContext();
  const { data, loading } = useYoutubeAnalyticsCompareAccountCardsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { mainAccountId: selectedSocialAccount.id },
    onError: (error) => {
      navigate({ to: '/analytics' });
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const youtubeAccounts = data?.youtubeAnalyticsCompareAccountCards;
  const allAccounts: AnalyticsCompareYoutubeAccountsProps['accounts'] = (() => {
    if (!youtubeAccounts) return [];

    const mainAccount = youtubeAccounts?.mainAccount;
    const compareAccounts =
      youtubeAccounts?.compareAccounts.map<AnalyticsYoutubeComparableAccountType>((account) => ({
        blocked: false, // api not support
        id: account.accountId,
        avatarUrl: account.avatarUrl,
        username: account.channelName,
        profileUrl: account.profileUrl
      })) || [];

    return [
      {
        blocked: false, // api not support
        id: mainAccount?.accountId || selectedSocialAccount.id,
        avatarUrl: mainAccount?.avatarUrl || selectedSocialAccount.avatar,
        profileUrl: mainAccount?.profileUrl || selectedSocialAccount.socialMediaUrl,
        username: mainAccount?.channelName || selectedSocialAccount.socialAccountName
      },
      ...compareAccounts
    ];
  })();

  const accounts = hasFreePlan ? compareMock.accounts : allAccounts;

  return (
    <RenderDataWithFallback loading={loading}>
      <FreePlanContentOpacity hasFreePlan={hasFreePlan} tab="compare-yt">
        <AnalyticsCompareYoutubeAccounts
          accounts={accounts}
          hiddenAccountIds={hiddenAccountIds}
          mainAccountId={selectedSocialAccount.id}
          onSelectAccount={({ id }) => setHiddenAccountId(id)}
          maxAccounts={5} // TODO: if BE support it we need to take it from subscription schema
        />

        <AnalyticsCompareYoutubeOverview
          accounts={accounts}
          hiddenAccountIds={hiddenAccountIds}
          mainAccountId={selectedSocialAccount.id}
          hasFreePlan={hasFreePlan}
          overviewDataMock={compareMock.overview_yt}
        />

        <AnalyticsCompareYoutubeTagsRanking
          accounts={accounts}
          hiddenAccountIds={hiddenAccountIds}
          mainAccountId={selectedSocialAccount.id}
          hasFreePlan={hasFreePlan}
          tagsRankingMock={compareMock.tagsRanking}
        />

        <AnalyticsCompareYoutubePosts
          accounts={accounts}
          hiddenAccountIds={hiddenAccountIds}
          brandAccountName={brandAccountName}
          mainAccountId={selectedSocialAccount.id}
          postsDataMock={compareMock.posts_yt}
          hasFreePlan={hasFreePlan}
        />
      </FreePlanContentOpacity>
    </RenderDataWithFallback>
  );
};
