import { useState } from 'react';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { useChatBoxToastMessage } from './useChatBoxToastMessage';

interface Props extends Pick<ReturnType<typeof useChatBoxToastMessage>, 'setToastMessage'> {
  uploadFileConfigs?: {
    limit?: number;
    accept?: string;
    disabled?: boolean;
    multiple?: boolean;
  };
}

export const useChatBoxUploadFiles = ({ uploadFileConfigs, setToastMessage }: Props) => {
  const { t } = useTranslation();
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);

  const isDisabledUploadFile =
    uploadFileConfigs?.disabled ||
    (uploadFileConfigs?.multiple
      ? !!uploadFileConfigs.limit && uploadingFiles.length === uploadFileConfigs.limit
      : !!uploadingFiles.length);

  const uploadNewFiles = (newFiles?: File[]) => {
    if (uploadFileConfigs?.multiple) {
      if (uploadFileConfigs.limit && uploadingFiles.length === uploadFileConfigs.limit) {
        setToastMessage({
          type: 'error',
          message: t('Unable to upload more than picture for now', { count: uploadFileConfigs.limit })
        });

        return;
      }
    } else if (uploadingFiles.length) {
      setToastMessage({
        type: 'error',
        message: t('Unable to upload more than picture for now', { count: 1 })
      });

      return;
    }

    if (newFiles?.length) {
      setUploadingFiles((prevState) =>
        uploadFileConfigs?.multiple
          ? uploadFileConfigs?.limit
            ? [...prevState, ...Array.from(newFiles)].splice(0, uploadFileConfigs.limit)
            : [...prevState, ...Array.from(newFiles)]
          : Array.from(newFiles)
      );
    }
  };

  const removeFile = (index: number) => {
    setUploadingFiles((prevFiles) => update(prevFiles, { $splice: [[index, 1]] }));
  };

  const clearUploadingFiles = () => {
    setUploadingFiles([]);
  };

  return {
    removeFile,
    uploadingFiles,
    uploadNewFiles,
    uploadFileConfigs,
    clearUploadingFiles,
    isDisabledUploadFile
  };
};
