import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ConnectPackageToEngagementProposal.graphql';
import { Mutation, MutationconnectPackageToEngagementProposalArgs } from '../../__generated__/globalTypes';

export const useConnectPackageToEngagementProposalMutation = (
  options?: MutationHookOptions<Mutation, MutationconnectPackageToEngagementProposalArgs>
) => {
  const [callConnectPackageToEngagementProposal, result] = useMutation<
    Mutation,
    MutationconnectPackageToEngagementProposalArgs
  >(MUTATION, options);

  return { callConnectPackageToEngagementProposal, ...result };
};

export type ConnectPackageToEngagementProposalMutationFunction = Mutation['connectPackageToEngagementProposal'];
