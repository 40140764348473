import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ExportYoutubeDashboardAccountAnalytics.graphql';
import { Mutation, MutationexportYoutubeDashboardAccountAnalyticsArgs } from '../../__generated__/globalTypes';

export const useExportYoutubeDashboardAccountAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationexportYoutubeDashboardAccountAnalyticsArgs>
) => {
  const [callExportYoutubeDashboardAccountAnalytics, result] = useMutation<
    Mutation,
    MutationexportYoutubeDashboardAccountAnalyticsArgs
  >(MUTATION, options);

  return { callExportYoutubeDashboardAccountAnalytics, ...result };
};

export type ExportYoutubeDashboardAccountAnalyticsMutationFunction = Mutation['exportYoutubeDashboardAccountAnalytics'];
