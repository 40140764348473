import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/YoutubeAnalyticsAudience.graphql';
import { Query, QueryyoutubeAnalyticsAudienceArgs } from '../../__generated__/globalTypes';

export const useYoutubeAnalyticsAudienceQuery = (
  options?: QueryHookOptions<Query, QueryyoutubeAnalyticsAudienceArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryyoutubeAnalyticsAudienceArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useYoutubeAnalyticsAudienceLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryyoutubeAnalyticsAudienceArgs>
): QueryResult<Query, QueryyoutubeAnalyticsAudienceArgs> & {
  getYoutubeAnalyticsAudience: LazyQueryExecFunction<Query, QueryyoutubeAnalyticsAudienceArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getYoutubeAnalyticsAudience, result] = useLazyQuery<Query, QueryyoutubeAnalyticsAudienceArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getYoutubeAnalyticsAudience, ...result };
};

export const useYoutubeAnalyticsAudiencePromiseQuery = () => {
  const client = useApolloClient();

  const getYoutubeAnalyticsAudience = useCallback(
    (options?: Omit<QueryOptions<QueryyoutubeAnalyticsAudienceArgs, Query>, 'query'>) =>
      client.query<Query, QueryyoutubeAnalyticsAudienceArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getYoutubeAnalyticsAudience };
};
