import { getUnixTime } from 'date-fns';

import { Material } from '@/shared/types';

import { formatHashTag } from './format';
import { getFileTypeFromUrl, getFilenameFromUrl } from './routes';

export const pathToFile = async (url: string, lastModified?: number) => {
  const filename = getFilenameFromUrl(url);
  const now = getUnixTime(new Date()); // current unix time(ms)

  try {
    const res = await fetch(url);
    const responseTypes = res.headers.get('Content-Type'); // normally get 3 of them like image/jpeg, application/json; charset=utf-8
    const blobDataType = responseTypes ? responseTypes.split(',')[0] : getFileTypeFromUrl(url);
    const blob = await res.blob(); // Gets the response and returns it as a blob
    const option = { lastModified: lastModified ? lastModified : now, type: blobDataType };

    return new File([blob], filename, option);
  } catch (e) {
    console.error(e);

    return;
  }
};

export const getDecodedUrlFileName = (url: string) => decodeURI(getFilenameFromUrl(url));

export const getFileObjectFromStorage = async (materials: Material[]) => {
  const files = await Promise.all(
    materials
      // we don't fetch preview for video, instead create empty File for D&D
      .map(async ({ url, modified }) => {
        if (['avi', 'mp4', 'mov'].includes(getFileTypeFromUrl(url))) {
          return new File([], getFilenameFromUrl(url), {
            lastModified: modified ? modified : getUnixTime(new Date()),
            type: getFileTypeFromUrl(url)
          });
        }

        return await pathToFile(url, modified);
      })
  );

  return files.filter((file): file is File => !!file);
};

export const getInstagramHashtagUrl = (name?: string | null) =>
  name ? `https://www.instagram.com/explore/tags/${formatHashTag(name)}/` : '';

export const getYoutubePostUrl = (videoId?: string | null) => (videoId ? `https://youtu.be/${videoId}` : '');

export const getYoutubeVideoUrl = (videoId?: string | null) =>
  videoId ? `https://www.youtube.com/watch?v=${videoId}` : '';
