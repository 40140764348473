import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestProposalExcel.graphql';
import { Mutation, MutationrequestProposalExcelArgs } from '../../__generated__/globalTypes';

export const useRequestProposalExcelMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestProposalExcelArgs>
) => {
  const [callRequestProposalExcel, result] = useMutation<Mutation, MutationrequestProposalExcelArgs>(MUTATION, options);

  return { callRequestProposalExcel, ...result };
};

export type RequestProposalExcelMutationFunction = Mutation['requestProposalExcel'];
