import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthReconnectYouTubeAccountAnalyticsV2.graphql';
import { Mutation, MutationsocialAuthReconnectYouTubeAccountAnalyticsV2Args } from '../../__generated__/globalTypes';

export const useSocialAuthReconnectYouTubeAccountAnalyticsV2Mutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthReconnectYouTubeAccountAnalyticsV2Args>
) => {
  const [callSocialAuthReconnectYouTubeAccountAnalyticsV2, result] = useMutation<
    Mutation,
    MutationsocialAuthReconnectYouTubeAccountAnalyticsV2Args
  >(MUTATION, options);

  return { callSocialAuthReconnectYouTubeAccountAnalyticsV2, ...result };
};

export type SocialAuthReconnectYouTubeAccountAnalyticsV2MutationFunction =
  Mutation['socialAuthReconnectYouTubeAccountAnalyticsV2'];
