import React, { useRef } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useDeepCompareEffect, useScrollElementBottom, useToggleState } from '@/shared/hooks';
import { Button } from '../../Button';
import { useChatBoxContext } from '../ChatBoxContext';
import { ChatBoxMessage } from './ChatBoxMessage';
import { ChatBoxMessageFileType, ChatBoxMessageType } from './types';

// Continuously messages have same type will be grouped in same row if needed
const SUPPORTED_GROUP_TYPES: ChatBoxMessageFileType['type'][] = ['image'];

const checkGroupFileMessages = (message?: ChatBoxMessageType) =>
  !!message?.file?.type && SUPPORTED_GROUP_TYPES.includes(message.file.type);

export interface ChatBoxMessagesListProps {
  messages: ChatBoxMessageType[];
  onRegenerate?: (index: number) => void;
}

export const ChatBoxMessagesList = ({ messages, onRegenerate }: ChatBoxMessagesListProps) => {
  const { t } = useTranslation();
  const goDownButton = useToggleState();
  const ref = useRef<HTMLDivElement | null>(null);
  const { uploadNewFiles, uploadFileConfigs } = useChatBoxContext();
  const { scrollToBottom } = useScrollElementBottom(ref, [messages]);

  // Recheck/Update go down button state when messages list changed
  useDeepCompareEffect(() => {
    if (
      ref.current &&
      goDownButton.status &&
      ref.current.scrollHeight - ref.current.clientHeight === ref.current.scrollTop
    ) {
      goDownButton.close();
    }
  }, [messages, goDownButton.status]);

  // Group message in same row if they are files and make them faltten if a message includes both text & file
  const formattedMessages = messages.reduce<(ChatBoxMessageType & { index: number })[][]>((acc, curr, index) => {
    const prevMessages = acc.at(-1);

    // Check if current and prev message can be grouped and must be same position
    if (
      checkGroupFileMessages(curr) &&
      checkGroupFileMessages(prevMessages?.at(-1)) &&
      prevMessages?.at(-1)?.position === curr.position && // Must same left | right position
      prevMessages?.at(-1)?.file?.type === curr.file?.type // Must same file type
    ) {
      acc.at(acc.length - 1)?.push({ ...curr, index });
    } else if (curr.file?.url && curr.text) {
      acc.push([{ ...curr, text: undefined, index }]); // Insert image message first
      acc.push([{ ...curr, file: undefined, index }]);
    } else {
      acc.push([{ ...curr, index }]);
    }

    return acc;
  }, []);

  return (
    <Dropzone
      {...uploadFileConfigs}
      onDrop={uploadNewFiles}
      accept={uploadFileConfigs?.accept ? { [uploadFileConfigs.accept]: [] } : undefined}
    >
      {({ isDragAccept, getRootProps, getInputProps }) => (
        <>
          <div
            ref={ref}
            css={styles.wrapper}
            onScroll={(e) => {
              if (
                e.currentTarget.scrollTop < e.currentTarget.scrollHeight - e.currentTarget.clientHeight - 50 &&
                !goDownButton.status
              ) {
                goDownButton.open();
              } else if (
                e.currentTarget.scrollHeight - e.currentTarget.clientHeight === e.currentTarget.scrollTop &&
                goDownButton.status
              ) {
                goDownButton.close();
              }
            }}
          >
            <div
              css={{ height: '100%', minHeight: 'inherit' }}
              // Prevent open upload file window when click the messages list, just allow drag/drop here
              {...getRootProps({ onClick: (event) => event.stopPropagation() })}
            >
              {isDragAccept ? (
                <div css={styles.dragingWrapper}>
                  <div css={styles.dragingText}>{t('drag and drop a file here')}</div>
                </div>
              ) : (
                <div css={styles.messages}>
                  {formattedMessages.map((items, index) => (
                    <React.Fragment key={`message-${index}`}>
                      {items.length > 1 ? (
                        <div css={styles.group(items.at(0)?.position === 'left')}>
                          {items.map((msg, idx) => (
                            <ChatBoxMessage key={`group-message-${index}-${idx}`} message={msg} />
                          ))}
                        </div>
                      ) : items.at(0) ? (
                        <ChatBoxMessage
                          message={items.at(0)}
                          onRegenerate={() => onRegenerate?.(items.at(0)?.index as number)}
                        />
                      ) : null}
                    </React.Fragment>
                  ))}
                </div>
              )}

              <input name="chatboxUploadInput" {...getInputProps()} />
            </div>
          </div>

          {goDownButton.status ? (
            <Button
              variant="black"
              onClick={scrollToBottom}
              css={styles.scrollDownButton}
              prefixIcon={{ icon: 'arrow-down', size: 11, color: THEME.icon.colors.white }}
            />
          ) : null}
        </>
      )}
    </Dropzone>
  );
};

const styles = {
  wrapper: css({
    flex: 1,
    minHeight: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: THEME.background.colors.white,
    '::-webkit-scrollbar': { display: 'none' }
  }),
  messages: css({
    gap: '16px',
    display: 'flex',
    minHeight: 'inherit',
    padding: '24px 16px',
    boxSizing: 'border-box',
    flexDirection: 'column'
  }),
  group: (isLeft: boolean) =>
    css({
      gap: '8px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: isLeft ? 'flex-start' : 'flex-end'
    }),
  dragingWrapper: css({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    backgroundColor: 'rgb(39, 49, 59, 0.5)'
  }),
  dragingText: css({
    padding: '8px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '100%',
    borderRadius: '5px',
    boxSizing: 'border-box',
    color: THEME.text.colors.white,
    border: `1px solid ${THEME.border.colors.white}`
  }),
  scrollDownButton: css({
    padding: 0,
    left: '50%',
    flex: 'none',
    bottom: '8px',
    height: '24px',
    minWidth: '24px',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    border: `1.5px solid ${THEME.border.colors.white}`
  })
};
