export const CURRENCY_SYMBOLS: Record<string, string> = {
  AED: 'د.إ',
  BHD: '.د.ب',
  CNY: '¥',
  EGP: 'ج.م',
  HKD: '$',
  IDR: 'Rp',
  INR: '₹',
  JOD: 'د.ا',
  JPY: '¥',
  KHR: '៛',
  KWD: 'د.ك',
  MMK: 'K',
  MYR: 'RM',
  OMR: 'ر.ع.',
  PHP: '₱',
  QAR: 'ر.ق',
  SAR: 'ر.س',
  SGD: '$',
  THB: '฿',
  TWD: '$',
  USD: '$',
  VND: '₫'
};
