import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { BasicFilter } from './BasicFilter';
import { useAutoManagedCampaignsFilter } from './hooks';

export const AutoManagedCampaignsFilter = () => {
  const { filter, setFilterForm } = useAutoManagedCampaignsFilter();

  return (
    <FilterWrapper>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<BasicFilter />} />
    </FilterWrapper>
  );
};

export { useAutoManagedCampaignsFilter };
export * from './schemaTypes';
