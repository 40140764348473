import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { useParamsSearch } from '@/shared/hooks';
import { ColumnProps, RowProps, Table, TableCell } from '@/shared/atoms';
import { getOffset, formatPrice, formatPercent, formatIntNumber, getValuableVariables } from '@/shared/utils';
import {
  MarketplaceAffiliateReportByDate,
  MarketplaceAffiliateReportSummary,
  useMarketplaceAffiliateReportByDateQuery
} from '@/graphql';
import { MarketplaceAffiliateReportSearchSchemaType } from '../MarketplaceAffiliateReportFilter';
import { formatAffiliatePeriod, styles } from './utils';

interface MarketplaceAffiliateReportByDateTableProps {
  currency: string;
  campaignId: number;
  isReferralCampaign?: boolean;
  summaryData?: MarketplaceAffiliateReportSummary;
}

export const MarketplaceAffiliateReportByDateTable = ({
  currency,
  campaignId,
  summaryData,
  isReferralCampaign
}: MarketplaceAffiliateReportByDateTableProps) => {
  const { t } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const {
    page,
    limit,
    filter: { startDate, endDate }
  } = useParamsSearch<MarketplaceAffiliateReportSearchSchemaType>();
  const { data, loading } = useMarketplaceAffiliateReportByDateQuery({
    variables: getValuableVariables({
      limit,
      endDate,
      startDate,
      campaignId,
      offset: getOffset(page, limit)
    })
  });

  const getStatCells = (rowStats?: Partial<MarketplaceAffiliateReportByDate> | null) => [
    <TableCell.Number value={formatIntNumber(rowStats?.clicks)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.conversions)} />,
    <TableCell.Number value={formatPercent(rowStats?.cvr, false)} unit="%" />,
    <TableCell.Number value={formatPrice(rowStats?.sales || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.cost || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.influencerCost || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.grossProfit || null, currency)} unit={currency} />
  ];

  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Date'), sticky: { active: true, hasBorderRight: true } },
    { title: t('HeaderColumn.Click'), styles: { textAlign: 'right' }, hidden: isReferralCampaign },
    { title: t('HeaderColumn.Order'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.CVR'), styles: { textAlign: 'right' }, hidden: isReferralCampaign },
    { title: t('HeaderColumn.Store Sales'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Cost'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Influencer Cost'), styles: { textAlign: 'right' }, hidden: !isAdminStaff },
    { title: t('HeaderColumn.Gross Profit'), styles: { textAlign: 'right' }, hidden: !isAdminStaff }
  ];
  const totalRow: RowProps = {
    styles: styles.totalRow,
    cells: [
      <TableCell.Text value={formatAffiliatePeriod({ period: { endDate, startDate }, title: t('Total') })} />,
      ...getStatCells({
        cvr: summaryData?.summary?.cvr,
        cost: summaryData?.summary?.cost,
        sales: summaryData?.summary?.sales,
        clicks: summaryData?.summary?.clicks,
        conversions: summaryData?.summary?.conversions,
        grossProfit: summaryData?.summary?.grossProfit,
        influencerCost: summaryData?.summary?.influencerCost
      })
    ]
  };
  const averageRow: RowProps = {
    styles: styles.averageRow,
    cells: [<TableCell.Text value={t('Average')} />, ...getStatCells(summaryData?.average)]
  };
  const campaignRows: RowProps[] =
    data?.marketplaceAffiliateReportByDate?.map((stats) => [
      <TableCell.Date value={stats?.date} />,
      ...getStatCells(stats)
    ]) || [];

  return (
    <Table
      loading={loading}
      data={{ columns, rows: [totalRow, averageRow, ...campaignRows], rowHeight: '73px' }}
      pagination={{ totalRecords: data?.marketplaceAffiliateReportByDateCount?.totalNumber }}
      noTopBorderRadius
      stickyHeader
    />
  );
};
