import { UserRoles } from '@/graphql';
import { UserLinkedFormValues } from './types';

export const DEFAULT_VALUES: Record<UserLinkedFormValues['userType'], Partial<UserLinkedFormValues>> = {
  [UserRoles.ADVERTISER]: {
    userType: UserRoles.ADVERTISER,
    name: '',
    email: '',
    password: '',
    countryId: '',
    passwordConfirm: '',
    hasInvitationEmail: true,
    notificationSetting: true
  },
  [UserRoles.STAFF]: {
    userType: UserRoles.STAFF,
    staffIds: [],
    countryId: ''
  }
};
