import 'suneditor/src/assets/css/suneditor.css';
import { SerializedStyles, css } from '@emotion/react';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import SunEditor from 'suneditor-react';
import SunEditorRef from 'suneditor/src/lib/core';
import { THEME } from '@/shared/constants';
import { DEFAULT_BUTTON_LIST, mapSuneditorLanguage } from './utils';

type SunEditorReactProps = ComponentProps<typeof SunEditor>;
export type SunEditorRefType = 'serviceInfo' | 'postReq';
export { SunEditorRef };
export interface RichEditorProps extends Omit<SunEditorReactProps, 'setContents'> {
  value?: string;
  hasError?: boolean;
  editableCss?: SerializedStyles;
  editorCss?: SerializedStyles;
}

export const RichEditor = ({
  value,
  disable,
  hasError,
  readOnly,
  editorCss,
  setOptions,
  editableCss,
  getSunEditorInstance,
  ...restProps
}: RichEditorProps) => {
  const { i18n } = useTranslation();

  return (
    <div css={styles.wrapper(editorCss, editableCss, disable)}>
      <div css={styles.editorWrapper(hasError)}>
        <SunEditor
          {...restProps}
          defaultValue={value}
          getSunEditorInstance={getSunEditorInstance}
          disable={readOnly || disable}
          lang={mapSuneditorLanguage(i18n.language)}
          setOptions={{
            iframe: false,
            buttonList: DEFAULT_BUTTON_LIST,
            linkTargetNewWindow: true,
            ...setOptions
          }}
        />
      </div>
    </div>
  );
};
RichEditor.displayName = 'RichEditor';

const styles = {
  editorWrapper: (hasError?: boolean) =>
    css({
      width: '100%',
      fontSize: '13px',
      borderRadius: '3px',
      boxSizing: 'border-box',
      backgroundColor: THEME.background.colors.white,
      border: `1px solid ${hasError ? THEME.input.border.colors.error : THEME.input.border.colors.default}`
    }),
  // All our native css is reseted in our general css.. so need to add back those default styles
  wrapper: (editorCss?: SerializedStyles, editableCss?: SerializedStyles, disabled?: boolean) =>
    css({
      h1: {
        marginLeft: 0,
        marginRight: 0,
        fontSize: '2em',
        display: 'block',
        fontWeight: 'bold',
        marginTop: '0.67em',
        marginBottom: '0.67em'
      },
      h2: {
        marginLeft: 0,
        marginRight: 0,
        display: 'block',
        fontSize: '1.5em',
        fontWeight: 'bold',
        marginTop: '0.83em',
        marginBottom: '0.83em'
      },
      h3: {
        marginLeft: 0,
        marginRight: 0,
        display: 'block',
        marginTop: '1em',
        fontWeight: 'bold',
        fontSize: '1.17em',
        marginBottom: '1em'
      },
      h4: {
        marginLeft: 0,
        marginRight: 0,
        display: 'block',
        fontWeight: 'bold',
        marginTop: '1.33em',
        marginBottom: '1.33em'
      },
      h5: {
        marginLeft: 0,
        marginRight: 0,
        display: 'block',
        fontSize: '0.83em',
        fontWeight: 'bold',
        marginTop: '1.67em',
        marginBottom: '1.67em'
      },
      h6: {
        marginLeft: 0,
        marginRight: 0,
        display: 'block',
        fontSize: '0.67em',
        fontWeight: 'bold',
        marginTop: '2.33em',
        marginBottom: '2.33em'
      },
      ul: { listStyleType: 'disc', listStylePosition: 'inside' },
      'ul ul, ol ul': {
        marginLeft: '15px',
        listStyleType: 'circle',
        listStylePosition: 'inside'
      },
      ol: {
        listStyleType: 'decimal',
        listStylePosition: 'inside'
      },
      'ol ol, ul ol': {
        marginLeft: '15px',
        listStylePosition: 'inside',
        listStyleType: 'lower-latin'
      },
      li: {
        listStyle: 'inherit',
        display: 'list-item'
      },
      pre: {
        margin: 0,
        borderRadius: '10px',
        backgroundColor: '#e0e0e0',
        color: 'rgba(0, 0, 0, 0.9)',
        fontFamily: 'Consolas, monospace'
      },
      blockquote: {
        color: '#666',
        fontStyle: 'italic',
        borderLeft: '5px solid #eee',
        fontFamily: 'Hoefler Text, Georgia, serif'
      },
      strong: { fontWeight: 'bold' },
      a: {
        color: '#004cff',
        textDecoration: ' none',
        '&:hover': {
          color: '#0093ff',
          cursor: 'pointer',
          textDecoration: 'underline'
        }
      },
      '.sun-editor': {
        border: 'none',
        fontFamily: 'Inter, sans-serif',
        editorCss
      },
      '.sun-editor .se-toolbar': {
        outline: 'none',
        borderBottom: THEME.border.base,
        zIndex: 'auto',
        '& .se-tooltip-inner': {
          zIndex: 999
        }
      },
      '.sun-editor .se-wrapper .se-placeholder': {
        fontSize: '14px',
        fontWeight: 400,
        color: THEME.input.text.colors.placeholder
      },
      '.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable': {
        color: THEME.input.text.colors.default,
        wordBreak: 'break-word',
        padding: '16px 16px 0 16px',
        backgroundColor: disabled ? THEME.input.background.colors.disabled : THEME.input.background.colors.default,
        editableCss,
        userSelect: 'text'
      }
    })
};
