import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerYoutubeCompareOverview.graphql';
import { Query, QueryinfluencerYoutubeCompareOverviewArgs } from '../../__generated__/globalTypes';

export const useInfluencerYoutubeCompareOverviewQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerYoutubeCompareOverviewArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerYoutubeCompareOverviewArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerYoutubeCompareOverviewLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerYoutubeCompareOverviewArgs>
): QueryResult<Query, QueryinfluencerYoutubeCompareOverviewArgs> & {
  getInfluencerYoutubeCompareOverview: LazyQueryExecFunction<Query, QueryinfluencerYoutubeCompareOverviewArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerYoutubeCompareOverview, result] = useLazyQuery<Query, QueryinfluencerYoutubeCompareOverviewArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getInfluencerYoutubeCompareOverview, ...result };
};

export const useInfluencerYoutubeCompareOverviewPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerYoutubeCompareOverview = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerYoutubeCompareOverviewArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerYoutubeCompareOverviewArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerYoutubeCompareOverview };
};
