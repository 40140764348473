import { useAllRegionsQuery } from '@/graphql';
import { getOptions } from '@/shared/utils';
import { SingleSelectFieldProps, SingleSelectField } from '../SingleSelectedField';

export interface RegionSingleSelectorProps extends Omit<SingleSelectFieldProps, 'options'> {
  countryId: string;
}

export const RegionSingleSelector = ({
  className,
  name,
  value,
  countryId,
  disabled,
  innerRef,
  ...field
}: RegionSingleSelectorProps) => {
  const { data, error } = useAllRegionsQuery({
    variables: { countryId },
    skip: !countryId,
    fetchPolicy: 'cache-and-network'
  });
  const options = data ? getOptions(data.allRegions) : [];

  return (
    <SingleSelectField
      className={className}
      name={name}
      value={value}
      disabled={disabled || !!error || options.length === 0}
      options={options}
      {...field}
      innerRef={innerRef}
    />
  );
};
RegionSingleSelector.displayName = 'RegionSingleSelector';
