import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { Anchor, SocialIcon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { AppLanguage, PostDetailInfluencerInfo, SupportedSocialMediaType } from '@/shared/types';
import { formatIntNumber, getSocialMediaTextLink, localizedDateFormatter } from '@/shared/utils';
import { InfluencerAvatar } from '../../InfluencerAvatar';

export interface InfluencerInfoProps {
  className?: string;
  info: PostDetailInfluencerInfo;
  objectFit?: 'contain' | 'cover';
}

export const InfluencerInfo = ({
  className,
  objectFit = 'contain',
  info: { id, name, avatar, followers, postUrl, postDate, socialMedia }
}: InfluencerInfoProps) => {
  const { t, i18n } = useTranslation();
  const { isAdvertiser } = useUserRoles();
  const { generateProfileLink } = useGenerateInfluencerProfileLink({ disabled: isAdvertiser || !id });

  const hasPostUrl = postUrl && socialMedia;
  const hasFollowers = typeof followers !== 'undefined';
  const profileLink = generateProfileLink(id);

  return (
    <div css={styles.wrapper} className={className}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {socialMedia && !postUrl ? (
          <div css={styles.socialIconWrapper}>
            <SocialIcon type={socialMedia} size={18} css={{ borderRadius: 0 }} />
          </div>
        ) : null}

        <InfluencerAvatar src={avatar} size="32px" objectFit={objectFit} css={{ borderRadius: '50%' }} id={id} />
      </div>

      <div css={{ display: 'flex', flexWrap: 'wrap', rowGap: '2px', alignItems: 'center', flex: 1, marginLeft: '8px' }}>
        <Anchor label={name} css={{ flex: '1 1 60%', fontWeight: 500, lineHeight: '16px' }} {...profileLink} />

        {hasPostUrl ? (
          <Anchor
            variant="blue"
            target="_blank"
            href={postUrl || '#'}
            css={{ flex: '1 1 40%', maxWidth: 'max-content', fontSize: '12px' }}
            label={t('View on', { socialMedia: getSocialMediaTextLink(socialMedia) })}
          />
        ) : null}

        {hasFollowers ? (
          <span css={{ flex: '1 1 60%', color: THEME.text.colors.gray.lv3, fontSize: '12px' }}>
            {formatIntNumber(followers)}{' '}
            {t(
              socialMedia &&
                [SupportedSocialMediaType.FACEBOOK_PAGE, SupportedSocialMediaType.YOUTUBE].includes(
                  socialMedia as SupportedSocialMediaType
                )
                ? 'Subscribers'
                : 'Followers'
            )}
          </span>
        ) : null}

        {postDate ? (
          <span css={styles.postDate}>{localizedDateFormatter(postDate, 'PPP', i18n.language as AppLanguage)}</span>
        ) : null}
      </div>
    </div>
  );
};
const styles = {
  wrapper: css({
    flex: 'none',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    color: THEME.text.colors.black.lv1,
    width: '100%'
  }),
  socialIconWrapper: css({
    zIndex: 1,
    width: '32px',
    height: '32px',
    display: 'flex',
    marginRight: '-8px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: THEME.background.colors.white
  }),
  postDate: css({
    flex: '1 1 40%',
    color: '#9b9b9b',
    fontSize: '13px',
    marginLeft: 'auto',
    maxWidth: 'max-content'
  })
};
