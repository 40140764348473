import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestGoogleSlideCustomisedReport.graphql';
import { Mutation, MutationrequestGoogleSlideCustomisedReportArgs } from '../../__generated__/globalTypes';

export const useRequestGoogleSlideCustomisedReportMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestGoogleSlideCustomisedReportArgs>
) => {
  const [callRequestGoogleSlideCustomisedReport, result] = useMutation<
    Mutation,
    MutationrequestGoogleSlideCustomisedReportArgs
  >(MUTATION, options);

  return { callRequestGoogleSlideCustomisedReport, ...result };
};

export type RequestGoogleSlideCustomisedReportMutationFunction = Mutation['requestGoogleSlideCustomisedReport'];
