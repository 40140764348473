import { usePermissions } from '../hooks';

const defaultPermissions = {
  hideTalentAgencyTab: false,
  hideAddTalentAgencyBtn: false,
  hideEditTalentAgencyBtn: false,
  hideDeleteTalentAgencyBtn: false
};
export const useTalentAgencyPermissions = () => {
  const { permissions, canCallCustomPermissions } = usePermissions();

  if (!canCallCustomPermissions) {
    return defaultPermissions;
  }

  if (permissions?.talentAgency?.noView || permissions?.talentAgency === null) {
    return {
      hideTalentAgencyTab: true,
      hideAddTalentAgencyBtn: true,
      hideEditTalentAgencyBtn: true,
      hideDeleteTalentAgencyBtn: true
    };
  } else if (permissions?.talentAgency?.view) {
    return {
      hideTalentAgencyTab: false,
      hideAddTalentAgencyBtn: true,
      hideEditTalentAgencyBtn: true,
      hideDeleteTalentAgencyBtn: true
    };
  } else if (permissions?.talentAgency?.manage) {
    return { ...defaultPermissions, hideDeleteTalentAgencyBtn: true };
  }

  return defaultPermissions;
};
