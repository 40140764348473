import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';
import { Genders, InfluencerListSortField, MarketplaceCampaignParticipateStatus } from '@/graphql';
import { LIMIT_50 } from '@/shared/constants';

export const MarketplaceInfluencersFilterSchema = z.object({
  keyword: FilterValidations.string(),
  minAge: FilterValidations.number(),
  maxAge: FilterValidations.number(),
  minFollowers: FilterValidations.number(),
  maxFollowers: FilterValidations.number(),
  minEngagementRate: FilterValidations.number(),
  maxEngagementRate: FilterValidations.number(),
  categoryIds: FilterValidations.arrayString(),
  genders: FilterValidations.arrayEnum(Genders),
  talentAgencyOrPartnerIds: FilterValidations.arrayString(),
  participateStatuses: FilterValidations.arrayEnum(MarketplaceCampaignParticipateStatus)
});
export const MarketplaceInfluencersSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(MarketplaceInfluencersFilterSchema, {
    keyword: '',
    minAge: '',
    maxAge: '',
    minFollowers: '',
    maxFollowers: '',
    minEngagementRate: '',
    maxEngagementRate: '',
    categoryIds: [],
    genders: [],
    talentAgencyOrPartnerIds: [],
    participateStatuses: []
  }),
  sort: SearchFilterDefaults.sort(InfluencerListSortField).default({}),
  ...SearchFilterDefaults.pageLimit(LIMIT_50)
});

export type MarketplaceInfluencersSearchSchemaType = z.infer<typeof MarketplaceInfluencersSearchSchema>;
export type MarketplaceInfluencersFilterFormValues = z.infer<typeof MarketplaceInfluencersFilterSchema>;
export type MarketplaceInfluencersFilterFormKeys = UnionOfKeys<MarketplaceInfluencersFilterFormValues>;
