import { THEME } from '@/shared/constants';
import { Button, ButtonProps, FormStyle } from '@/shared/atoms';

export default {
  FieldsGroup: FormStyle.FieldsGroup,
  FieldWrapper: FormStyle.FieldWrapper,
  HeaderButton: (props: ButtonProps) => <Button css={{ paddingLeft: '24px', paddingRight: '24px' }} {...props} />,
  Content: (props: React.ComponentProps<typeof FormStyle.Wrapper>) => (
    <FormStyle.Wrapper css={{ padding: '16px 12px' }} gapRow={16} {...props} />
  ),
  Header: (props: React.HTMLAttributes<HTMLDivElement>) => (
    <div
      css={{
        height: '48px',
        display: 'flex',
        padding: '0 16px',
        borderRadius: '3px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: THEME.background.colors.gray.lv1
      }}
      {...props}
    />
  ),
  Wrapper: (props: React.HTMLAttributes<HTMLDivElement>) => (
    <div
      css={{
        margin: '0 auto',
        boxSizing: 'border-box',
        border: '2px solid #f6f8fa',
        borderRadius: '0 0 3px 3px',
        maxWidth: THEME.container.details.width,
        boxShadow: '0 5px 12px rgba(39, 49, 59, 0.2)',
        backgroundColor: THEME.background.colors.white
      }}
      {...props}
    />
  ),
  Container: (props: React.HTMLAttributes<HTMLDivElement>) => (
    <div
      css={{
        top: 0,
        left: 0,
        zIndex: 100,
        width: '100%',
        height: '100vh',
        padding: '24px',
        overflow: 'auto',
        position: 'fixed',
        boxSizing: 'border-box',
        background: 'rgba(0, 0, 0, 0.4'
      }}
      {...props}
    />
  ),
  HeaderTitle: (props: React.HTMLAttributes<HTMLDivElement>) => (
    <div
      css={{
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '24px',
        fontStyle: 'normal',
        marginRight: 'auto',
        whiteSpace: 'nowrap',
        color: THEME.text.colors.black.lv1
      }}
      {...props}
    />
  ),
  SectionTitle: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h2
      css={{
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '100%',
        fontStyle: 'normal',
        paddingBottom: '9px',
        marginBottom: '16px',
        borderBottom: THEME.border.base,
        color: THEME.text.colors.black.lv1
      }}
      {...props}
    />
  )
};
