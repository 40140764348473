import { useUserRoles } from '@/auth';
import { SearchMode, useFetchAutoManagedCustomShopeeCampaignsMutation } from '@/graphql';
import { Button, Modal, FormProvider } from '@/shared/atoms';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { FormAction } from '@/shared/types';
import { Template } from './Template';
import { AutoManagedCustomShopeeCampaignsFormValues } from './types';

export const FetchAutoManagedCustomShopeeCampaigns = ({ className }: { className?: string }) => {
  const { isAdminStaff } = useUserRoles();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { status, toggleStatus } = useToggleState();
  const { callFetchAutoManagedCustomShopeeCampaigns, loading } = useFetchAutoManagedCustomShopeeCampaignsMutation();

  let reset: () => void | undefined;

  const handleSubmit: FormAction<AutoManagedCustomShopeeCampaignsFormValues>['onSubmit'] = async (
    { countryId, searchMode, keyword, itemId },
    methods
  ) => {
    reset = methods.reset;

    try {
      await callFetchAutoManagedCustomShopeeCampaigns({
        variables: {
          input: {
            countryId,
            searchMode,
            ...(searchMode === SearchMode.KEYWORD_SEARCH ? { keyword } : { itemId })
          }
        }
      });

      enqueueSnackbar(
        t('Request to update campaigns list has been submitted, please wait around 5 min until they are fetched'),
        { variant: 'success' }
      );
      toggleStatus();
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return isAdminStaff ? (
    <>
      <Button variant="blue" title={t('Button.Pull more campaigns')} onClick={toggleStatus} className={className} />
      <Modal
        open={status}
        css={{ width: '500px' }}
        onClose={toggleStatus}
        onAfterClose={() => reset?.()}
        shouldCloseOnOverlayClick
        hasCloseIcon
      >
        <FormProvider<AutoManagedCustomShopeeCampaignsFormValues>
          onSubmit={handleSubmit}
          defaultValues={{ keyword: '', countryId: '', searchMode: SearchMode.KEYWORD_SEARCH, itemId: null }}
        >
          <Template onClose={toggleStatus} loading={loading} />
        </FormProvider>
      </Modal>
    </>
  ) : null;
};
