import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SocialAuthConnectableInstagramAccountsAnalytics.graphql';
import { Mutation, MutationsocialAuthConnectableInstagramAccountsAnalyticsArgs } from '../../__generated__/globalTypes';

export const useSocialAuthConnectableInstagramAccountsAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationsocialAuthConnectableInstagramAccountsAnalyticsArgs>
) => {
  const [callSocialAuthConnectableInstagramAccountsAnalytics, result] = useMutation<
    Mutation,
    MutationsocialAuthConnectableInstagramAccountsAnalyticsArgs
  >(MUTATION, options);

  return { callSocialAuthConnectableInstagramAccountsAnalytics, ...result };
};

export type SocialAuthConnectableInstagramAccountsAnalyticsMutationFunction =
  Mutation['socialAuthConnectableInstagramAccountsAnalytics'];
