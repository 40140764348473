import { useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { ProposalSummary, useUserProposalContext } from '@/shared/organisms';
import { ProposeInfluencersList } from './ProposeInfluencersList';

export const ProposeInfluencers = () => {
  const { userProposal } = useUserProposalContext();
  const navigate = useNavigate({ from: '/influencers/proposal' });

  useEffect(() => {
    if (userProposal) {
      navigate({ params: { filter: { jdMode: userProposal.jdMode } } });
    }
  }, [userProposal?.jdMode]);

  return (
    <>
      <ProposalSummary summary={userProposal?.summary} currency={userProposal?.currency} />
      <ProposeInfluencersList userProposal={userProposal} />
    </>
  );
};
