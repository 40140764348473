import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateTalentAgencyUser.graphql';
import { Mutation, MutationupdateTalentAgencyUserArgs } from '../../__generated__/globalTypes';

export const useUpdateTalentAgencyUserMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateTalentAgencyUserArgs>
) => {
  const [callUpdateTalentAgencyUser, result] = useMutation<Mutation, MutationupdateTalentAgencyUserArgs>(
    MUTATION,
    options
  );

  return { callUpdateTalentAgencyUser, ...result };
};

export type UpdateTalentAgencyUserMutationFunction = Mutation['updateTalentAgencyUser'];
