import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AddSocialAccountToUserProposal.graphql';
import { Mutation, MutationaddSocialAccountToUserProposalArgs } from '../../__generated__/globalTypes';

export const useAddSocialAccountToUserProposalMutation = (
  options?: MutationHookOptions<Mutation, MutationaddSocialAccountToUserProposalArgs>
) => {
  const [callAddSocialAccountToUserProposal, result] = useMutation<
    Mutation,
    MutationaddSocialAccountToUserProposalArgs
  >(MUTATION, options);

  return { callAddSocialAccountToUserProposal, ...result };
};

export type AddSocialAccountToUserProposalMutationFunction = Mutation['addSocialAccountToUserProposal'];
