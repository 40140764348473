import { useTranslation } from 'react-i18next';
import { SocialIcon } from '@/shared/atoms';
import { SelectorField, SelectorFieldV2, BasicFilterWrapper, SingleDatePickerField } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { TrendingPostsFilterFormKeys, TrendingPostsTabType } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
  allCountryOptions: Option[];
  allCategoryOptions: Option[];
}

export const Template = ({ allCountryOptions, allCategoryOptions, onSubmit }: TemplateProps) => {
  const { t } = useTranslation();

  const socialMediaOptions: Option[] = [
    {
      label: t<string>('Instagram'),
      value: TrendingPostsTabType.INSTAGRAM,
      prefixIcon: <SocialIcon type="INSTAGRAM" />
    },
    { label: t<string>('Youtube'), value: TrendingPostsTabType.YOUTUBE, prefixIcon: <SocialIcon type="YOUTUBE" /> },
    { label: t<string>('X(Twitter)'), value: TrendingPostsTabType.TWITTER, prefixIcon: <SocialIcon type="TWITTER" /> },
    { label: t<string>('Facebook'), value: TrendingPostsTabType.FACEBOOK, prefixIcon: <SocialIcon type="FACEBOOK" /> },
    {
      label: t<string>('Facebook Fanpage'),
      value: TrendingPostsTabType.FACEBOOK_PAGE,
      prefixIcon: <SocialIcon type="FACEBOOK_PAGE" />
    },
    { label: t<string>('TikTok'), value: TrendingPostsTabType.TIKTOK, prefixIcon: <SocialIcon type="TIKTOK" /> }
  ];

  return (
    <BasicFilterWrapper>
      <SelectorFieldV2<TrendingPostsFilterFormKeys>
        name="tab"
        menuWidth="auto"
        menuHeight="auto"
        clearable={false}
        title={t('Selector.Social Media')}
        onSubmit={onSubmit}
        css={{ maxWidth: '160px' }}
        options={socialMediaOptions}
        placeholder={t<string>('Selector.Social Media')}
        disabled // TODO-Trend: Currently we only show default social media YT and disable the selector
      />

      <SelectorField<TrendingPostsFilterFormKeys>
        name="countryId"
        title={t('Selector.Country')}
        onSubmit={onSubmit}
        css={{ maxWidth: '188px' }}
        options={allCountryOptions}
        placeholder={t<string>('Selector.Country')}
        singleSelectorProps={{ hideDeselectOption: true }}
      />

      <SelectorField<TrendingPostsFilterFormKeys>
        title={t('Selector.Topic')}
        name="categoryId"
        onSubmit={onSubmit}
        css={{ maxWidth: '188px' }}
        options={allCategoryOptions}
        singleSelectorProps={{ hideDeselectOption: true }}
      />

      <SingleDatePickerField<TrendingPostsFilterFormKeys>
        name="date"
        title={t('Date')}
        onSubmit={onSubmit}
        css={{ maxWidth: '188px' }}
        disabledDays={{ after: new Date() }}
      />
    </BasicFilterWrapper>
  );
};
