import { UseFormReset, DefaultValues } from 'react-hook-form';
import { MarketplaceInfluencerRevenueInput, useUpdateMarketplaceInfluencerCostMutation } from '@/graphql';
import { objectKeys } from '@/shared/assets';
import { useQueryHelper } from '@/shared/hooks';
import { Writeable } from '@/shared/utils';

interface UpdatePostPricesProps {
  marketplaceId: number;
  reset: UseFormReset<UpdateCostsFormValues>;
  refetchQueries: string[];
  defaultValues?: DefaultValues<UpdateCostsFormValues>;
}
export const useUpdatePostPrices = ({ reset, marketplaceId, refetchQueries, defaultValues }: UpdatePostPricesProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callUpdateMarketplaceInfluencerCost, loading: costLoading } = useUpdateMarketplaceInfluencerCostMutation({
    refetchQueries
  });

  const onSubmit = async (formData: UpdateCostsFormValues) => {
    try {
      await callUpdateMarketplaceInfluencerCost({
        variables: {
          input: {
            marketplaceId,
            influencers: objectKeys(formData).reduce<MarketplaceInfluencerRevenueInputShape[]>((rs, formKey) => {
              if (defaultValues?.[formKey] !== formData[formKey]) {
                const [influencerId, costType] = formKey.split('_') as [string, RevenueInputKeys];
                const value = formData[formKey];
                // Find the existing object for this influencerId and update it's cost or create a new one
                const existingEntry = rs.find((entry) => entry.influencerId === Number(influencerId));
                if (existingEntry) {
                  existingEntry[costType] =
                    value !== ''
                      ? costType === 'influencerCommissionRate'
                        ? Number(value) / 100
                        : Number(value)
                      : null;
                } else {
                  const newEntry: MarketplaceInfluencerRevenueInput = {
                    influencerId: Number(influencerId),
                    [costType]:
                      value !== ''
                        ? costType === 'influencerCommissionRate'
                          ? Number(value) / 100
                          : Number(value)
                        : null
                  };
                  rs.push(newEntry);
                }
              }

              return rs;
            }, [])
          }
        }
      });
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      reset(formData);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return {
    onSubmit,
    costLoading
  };
};

export type RevenueInputKeys = keyof Omit<MarketplaceInfluencerRevenueInput, 'influencerId'>;
export type UpdateCostsFormValues = {
  [K in `${number}_${RevenueInputKeys}`]?: string;
};
export type MarketplaceInfluencerRevenueInputShape = Writeable<MarketplaceInfluencerRevenueInput>;
