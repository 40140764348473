import { useAuthData } from '@/auth';
import { UserRoles } from '@/graphql';
import { useTranslateOptions } from '@/shared/hooks';
import { Option } from '@/shared/types';

import { UserFormValues } from '../types';

type UserTypeOption = Option<UserRoles, string>;

const staffRole: UserTypeOption = { value: UserRoles.STAFF, label: 'Staff' };
const agencyRole: UserTypeOption = { value: UserRoles.AGENCY, label: 'Agency' };
const partnerRole: UserTypeOption = { value: UserRoles.PARTNER, label: 'Partner' };
const adminRole: UserTypeOption = { value: UserRoles.ADMINISTRATOR, label: 'Admin' };
const advertiserRole: UserTypeOption = { value: UserRoles.ADVERTISER, label: 'Advertiser' };
const talentAgencyRole: UserTypeOption = { value: UserRoles.TALENT_AGENCY, label: 'Talent Agency' };
const staffRoleOptions: UserTypeOption[] = [staffRole, partnerRole, talentAgencyRole, agencyRole, advertiserRole];
const adminRoleOptions: UserTypeOption[] = [
  adminRole,
  staffRole,
  partnerRole,
  talentAgencyRole,
  agencyRole,
  advertiserRole
];
const adminStaffRoleOptions: UserTypeOption[] = [
  { ...adminRole, disabled: true },
  staffRole,
  partnerRole,
  talentAgencyRole,
  agencyRole,
  advertiserRole
];

// order of radioboxes -> Admin > Staff > Partner Agency > Talent Agency > Agency > Advertiser
export const useUserRoleOptions = (mode?: UserFormValues['mode']): UserTypeOption[] => {
  const {
    auth: { role }
  } = useAuthData();
  let options: UserTypeOption[];

  switch (role) {
    case UserRoles.ADMINISTRATOR:
      options = adminRoleOptions;
      break;
    case mode === 'add' && UserRoles.AGENCY:
      options = [advertiserRole];
      break;
    case UserRoles.PARTNER:
      options = [partnerRole, agencyRole, advertiserRole];
      break;
    case UserRoles.TALENT_AGENCY:
      options = [talentAgencyRole, agencyRole, advertiserRole];
      break;
    case UserRoles.STAFF:
      options = mode === 'edit' ? adminStaffRoleOptions : staffRoleOptions;
      break;
    default:
      options = staffRoleOptions;
  }

  return useTranslateOptions<UserRoles>(options);
};
