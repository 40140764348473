import { zodValidator } from '@tanstack/zod-adapter';
import { PermissionRoute } from '@/app-components';
import { Agencies, AddAgency, AgencyDetails, EditAgency, AgencyUsers, AgencyBusiness } from '@/pages/Agencies';
import { adminStaffTalentAgencyPartnerRoles } from '@/shared/constants';
import { AccountFilterSearchSchema, AssociatedAccountSearchSchema } from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const AgencyRouter = {
  '/_private-routes/agency': [() => <PermissionRoute permissions={adminStaffTalentAgencyPartnerRoles} />, {}],
  '/_private-routes/agency/': [Agencies, { validateSearch: zodValidator(AccountFilterSearchSchema) }],
  '/_private-routes/agency/add': [AddAgency, {}],
  '/_private-routes/agency/$id': [AgencyDetails, {}],
  '/_private-routes/agency/$id/': [EditAgency, {}],
  '/_private-routes/agency/$id/users': [AgencyUsers, {}],
  '/_private-routes/agency/$id/business': [
    AgencyBusiness,
    { validateSearch: zodValidator(AssociatedAccountSearchSchema) }
  ]
} as const satisfies PartialRouter;
