import { UGCPostStatus, namedOperations, useChangeInstagramUGCManagementPostStatusMutation } from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { AnalyticsSelectedAccountInfo } from '../../types';

interface Props {
  postSNSId: string;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const useUpdateUGCPostStatus = ({ postSNSId, analyticsAccount }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callChangeInstagramUGCManagementPostStatus, loading } = useChangeInstagramUGCManagementPostStatusMutation({
    refetchQueries: [namedOperations.Query.InstagramUGCManagementPostDetails]
  });

  const handleChangeStatus = async (status: UGCPostStatus) => {
    if (!analyticsAccount.id || !postSNSId) {
      return;
    }

    try {
      // We can switch/case to support others SNS
      await callChangeInstagramUGCManagementPostStatus({
        variables: {
          input: {
            status,
            postSnsId: postSNSId,
            analyticsAccountId: analyticsAccount.id
          }
        }
      });
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return { updating: loading, handleChangeStatus };
};
