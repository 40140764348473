import { useState } from 'react';
import { Trans } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { formatIntNumber } from '@/shared/utils';
import { useQueryHelper, useToggleState, useDeepCompareEffect } from '@/shared/hooks';
import { Button, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { MarketplaceInfluencersSortProps, MarketplaceInfluencersListProps } from '@/shared/organisms';
import {
  namedOperations,
  InfluencerWhitelist,
  MarketplaceInfluencer,
  MarketplaceCampaignStatus,
  useAddSelectedInfluencersMutation,
  useRemoveSelectedInfluencersMutation,
  useAddAllSelectedInfluencersMutation,
  useRemoveAllSelectedInfluencersMutation
} from '@/graphql';
import { useMarketplaceDetailsContext } from '../../../MarketplaceDetailsContext';

interface Props {
  totalRecords?: number;
  listInfluencers: readonly MarketplaceInfluencer[];
  influencerWhitelist?: InfluencerWhitelist | null;
}

const refetchQueries = [
  namedOperations.Query.InfluencerWhitelist,
  namedOperations.Query.AllInfluencersForMarketplaceV2
];
export const useToggleSelectedInfluencers = ({ totalRecords = 0, listInfluencers, influencerWhitelist }: Props) => {
  const actionBarState = useToggleState();
  const selectAllState = useToggleState();
  const addAllInfluencersModal = useToggleState();
  const removeAllInfluencersModal = useToggleState();
  const [togglingId, setTogglingId] = useState<number | null>(null);

  const { t, enqueueSnackbar, invalidateRouteLoader } = useQueryHelper();
  const { marketplace, marketplaceId } = useMarketplaceDetailsContext();
  const { callAddSelectedInfluencers, loading: adding } = useAddSelectedInfluencersMutation({ refetchQueries });
  const { callRemoveSelectedInfluencers, loading: removing } = useRemoveSelectedInfluencersMutation({ refetchQueries });
  const { callAddAllSelectedInfluencers, loading: addingAll } = useAddAllSelectedInfluencersMutation({
    refetchQueries: [...refetchQueries],
    onCompleted: invalidateRouteLoader
  });
  const { callRemoveAllSelectedInfluencers, loading: removingAll } = useRemoveAllSelectedInfluencersMutation({
    refetchQueries: [...refetchQueries],
    onCompleted: invalidateRouteLoader
  });

  useDeepCompareEffect(() => {
    if (!togglingId) {
      actionBarState.close();
      selectAllState.close();
    }

    setTogglingId(null);
  }, [listInfluencers, influencerWhitelist]);

  const isWhitelist = !!influencerWhitelist?.isWhitelist;
  const loading = adding || removing || addingAll || removingAll;
  const isUpdatingAll = !togglingId && loading;
  const influencerIds = influencerWhitelist?.influencerIds || [];
  const allInfluencerIdsInPage = listInfluencers.map((infl) => infl.id);
  const shouldOnlyAddAllowed = [MarketplaceCampaignStatus.UPCOMING, MarketplaceCampaignStatus.ONGOING].includes(
    marketplace.status
  );
  const shouldDisableCheckbox = [
    MarketplaceCampaignStatus.SUSPENDED,
    MarketplaceCampaignStatus.REJECTED,
    MarketplaceCampaignStatus.FINISHED
  ].includes(marketplace.status);

  const toggleSelectedInfluencer = async (influencerId: number, selected: boolean) => {
    setTogglingId(influencerId);
    await toggleSelectedInfluencers([influencerId], selected);
  };

  const toggleSelectedInfluencers = async (influencerIds: number[], selected?: boolean) => {
    try {
      const toggle = !selected ? callAddSelectedInfluencers : callRemoveSelectedInfluencers;
      await toggle({ variables: { input: { marketplaceId, influencerIds } } });
    } catch (error) {
      enqueueSnackbar(t('failedUpdateWhitelist', { name: 'Update Whitelist Failed' }), { variant: 'error' });
    }
  };

  const toggleAllSelectedInfluencers = async (selected?: boolean) => {
    try {
      const toggle = !selected ? callAddAllSelectedInfluencers : callRemoveAllSelectedInfluencers;
      await toggle({ variables: { input: { marketplaceId } } });
    } catch (error) {
      enqueueSnackbar(t('failedUpdateWhitelist', { name: 'Update Whitelist Failed' }), { variant: 'error' });
    } finally {
      if (!selected) {
        addAllInfluencersModal.close();
      } else {
        removeAllInfluencersModal.close();
      }
    }
  };

  const toggleInfluencers = async (selected?: boolean) => {
    if (selectAllState.status) {
      await toggleAllSelectedInfluencers(selected);
    } else {
      await toggleSelectedInfluencers(allInfluencerIdsInPage, selected);
    }
  };

  const sortSelectButtonProps: MarketplaceInfluencersSortProps['selectButtonProps'] = (() => {
    const hasSelectAllButton = !selectAllState.status && listInfluencers.length < totalRecords;
    const isAllInfluencersInPageAdded = listInfluencers.every((infl) => {
      const index = influencerIds.findIndex((inflId) => inflId === infl.id);

      return isWhitelist ? index !== -1 : index === -1;
    });
    const disabled =
      listInfluencers.length === 0 || (isAllInfluencersInPageAdded && shouldOnlyAddAllowed) || shouldDisableCheckbox;

    return {
      disabled,
      selectAllProps: { label: t('Select all influencers'), onClick: selectAllState.toggleStatus },
      checkboxProps: {
        disabled,
        loading: isUpdatingAll,
        checked: actionBarState.status,
        indeterminate: hasSelectAllButton,
        onClick: () => {
          if (actionBarState.status && hasSelectAllButton) {
            selectAllState.open();
          } else {
            actionBarState.toggleStatus();
            selectAllState.close();
          }
        },
        label: actionBarState.status ? (
          <Trans
            i18nKey="Influencer to add"
            values={{ count: formatIntNumber(selectAllState.status ? totalRecords : listInfluencers.length) }}
          />
        ) : (
          `${formatIntNumber(totalRecords)} ${t('Influencers found')}`
        )
      },
      children: (
        <>
          <Button
            variant="red"
            title={t('Button.Remove Influencer')}
            disabled={shouldOnlyAddAllowed || isUpdatingAll}
            css={{ padding: '0 24px', marginLeft: 'auto', textTransform: 'uppercase' }}
            onClick={() => {
              if (selectAllState.status) {
                removeAllInfluencersModal.open();
              } else {
                toggleInfluencers?.(true);
              }
            }}
          />
          <Button
            variant="blue"
            disabled={isUpdatingAll}
            title={t('Button.Add Influencer')}
            css={{ padding: '0 24px', marginLeft: '8px', textTransform: 'uppercase' }}
            onClick={() => {
              if (selectAllState.status) {
                addAllInfluencersModal.open();
              } else {
                toggleInfluencers?.(false);
              }
            }}
          />
        </>
      )
    };
  })();

  const generateListSelectButtonProps: MarketplaceInfluencersListProps['generateSelectButtonProps'] = ({ id }) => {
    const index = influencerIds.findIndex((inflId) => inflId === id);
    const checked = isWhitelist ? index !== -1 : index === -1;

    return {
      checked,
      loading: togglingId === id,
      onClick: () => toggleSelectedInfluencer(id, checked),
      disabled: shouldDisableCheckbox || (shouldOnlyAddAllowed && checked) || isUpdatingAll
    };
  };

  return {
    sortSelectButtonProps,
    generateListSelectButtonProps,
    RemoveAllInfluencersModal: (
      <Modal
        open={removeAllInfluencersModal.status}
        onClose={removeAllInfluencersModal.close}
        css={{ maxWidth: THEME.modal.size.lv1 }}
        hasCloseIcon
      >
        <ModalContent>
          <ModalTitle>{t('Modal.removeAllInfluencersTitle')}</ModalTitle>
          <p css={{ marginTop: '16px' }}>
            <Trans i18nKey="Modal.removeAllInfluencersDescription" components={{ br: <br /> }} />
          </p>
        </ModalContent>
        <ModalFooterActions
          cancelButtonProps={{ onClick: removeAllInfluencersModal.close }}
          submitButtonProps={{
            variant: 'red',
            loading: removingAll,
            onClick: () => toggleInfluencers?.(true),
            title: t('Button.Remove All Influencers')
          }}
        />
      </Modal>
    ),
    AddAllInfluencersModal: (
      <Modal
        open={addAllInfluencersModal.status}
        onClose={addAllInfluencersModal.close}
        css={{ maxWidth: THEME.modal.size.lv1 }}
        hasCloseIcon
      >
        <ModalContent>
          <ModalTitle>{t('Modal.addAllInfluencersTitle')}</ModalTitle>
          <p css={{ marginTop: '16px' }}>
            <Trans i18nKey="Modal.addAllInfluencersDescription" components={{ br: <br /> }} />
          </p>
        </ModalContent>
        <ModalFooterActions
          cancelButtonProps={{ onClick: addAllInfluencersModal.close }}
          submitButtonProps={{
            variant: 'blue',
            loading: addingAll,
            onClick: () => toggleInfluencers?.(false),
            title: t('Button.Add All Influencers')
          }}
        />
      </Modal>
    )
  };
};
