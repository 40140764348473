import { ReactNode } from 'react';
import { css, ThemeProvider } from '@emotion/react';
import { THEME } from '@/shared/constants';

export interface WrapperProps {
  id?: string;
  gapRow?: number;
  gapColumn?: number;
  hasBorder?: boolean;
  className?: string;
  children: ReactNode;
}

export const Wrapper = ({ hasBorder, gapRow, gapColumn, ...restProps }: WrapperProps) => (
  <ThemeProvider
    theme={{
      ...THEME,
      form: {
        ...THEME.form,
        gapRow: gapRow || THEME.form.gapRow,
        gapColumn: gapColumn || THEME.form.gapColumn
      }
    }}
  >
    <div css={styles.wrapper(hasBorder)} {...restProps} />
  </ThemeProvider>
);

const styles = {
  wrapper: (hasBorder?: boolean) =>
    css({
      borderRadius: 'inherit',
      boxSizing: 'border-box',
      backgroundColor: THEME.background.colors.white,
      ...(hasBorder ? { marginLeft: 'auto', marginRight: 'auto', borderRadius: '3px' } : {})
    })
};
