import { TextareaHTMLAttributes, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';

export interface TextareaProps extends Partial<TextareaHTMLAttributes<HTMLTextAreaElement>> {
  hasError?: boolean;
  heightAuto?: boolean; // Height will fit with current content automatically, can work with min/max height
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ disabled, placeholder, hasError, heightAuto, ...restProps }, ref) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => textAreaRef.current || ({} as HTMLTextAreaElement), []);

    useEffect(() => {
      if (heightAuto && textAreaRef.current) {
        // We need to reset the height momentarily to get the correct scrollHeight
        textAreaRef.current.style.height = '0px';
        const scrollHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = scrollHeight + 'px';
      }
    }, [textAreaRef.current?.value]);

    return (
      <textarea
        ref={textAreaRef}
        disabled={disabled}
        placeholder={!disabled ? placeholder : ''}
        css={[styles.textarea({ disabled, hasError }), heightAuto && { resize: 'none' }]}
        {...restProps}
      />
    );
  }
);
Textarea.displayName = 'Textarea';

const styles = {
  textarea: ({ disabled, hasError }: Pick<TextareaProps, 'disabled' | 'hasError'>) =>
    css({
      width: '100%',
      padding: '12px',
      fontSize: '12px',
      borderRadius: '3px',
      border: '1px solid',
      boxSizing: 'border-box',
      color: THEME.input.text.colors.default,
      backgroundColor: disabled ? THEME.input.background.colors.disabled : THEME.input.background.colors.default,
      borderColor: hasError ? THEME.input.border.colors.error : THEME.input.border.colors.default,
      '::placeholder': {
        fontSize: '14px',
        fontWeight: 400,
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: THEME.input.text.colors.placeholder
      },
      ':not(:disabled):focus': { borderColor: THEME.input.border.colors.focus }
    })
};
