import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllInstagramSimilarInfluencersV3.graphql';
import { Query, QueryallInstagramSimilarInfluencersV3Args } from '../../__generated__/globalTypes';

export const useAllInstagramSimilarInfluencersV3Query = (
  options?: QueryHookOptions<Query, QueryallInstagramSimilarInfluencersV3Args>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallInstagramSimilarInfluencersV3Args>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllInstagramSimilarInfluencersV3LazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallInstagramSimilarInfluencersV3Args>
): QueryResult<Query, QueryallInstagramSimilarInfluencersV3Args> & {
  getAllInstagramSimilarInfluencersV3: LazyQueryExecFunction<Query, QueryallInstagramSimilarInfluencersV3Args>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllInstagramSimilarInfluencersV3, result] = useLazyQuery<Query, QueryallInstagramSimilarInfluencersV3Args>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getAllInstagramSimilarInfluencersV3, ...result };
};

export const useAllInstagramSimilarInfluencersV3PromiseQuery = () => {
  const client = useApolloClient();

  const getAllInstagramSimilarInfluencersV3 = useCallback(
    (options?: Omit<QueryOptions<QueryallInstagramSimilarInfluencersV3Args, Query>, 'query'>) =>
      client.query<Query, QueryallInstagramSimilarInfluencersV3Args>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllInstagramSimilarInfluencersV3 };
};
