import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/auth';
import { ButtonLink } from '@/shared/atoms';
import { useAllUsersQuery } from '@/graphql';
import { Header, ListWithPagination } from '@/shared/molecules';
import { getValuableVariables, getOffset } from '@/shared/utils';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { UsersFilter, UsersList, useUsersFilter } from '@/shared/organisms';

export const Users = () => {
  useCustomHeader();
  const { t } = useTranslation();
  useUpdateDocumentTitle({ title: 'documentTitle.User', appHeader: 'appHeader.User', href: '' });
  const { hideAddUserBtn } = useUserPermissions();
  const { page, limit, filter } = useUsersFilter();
  const { data, previousData, loading } = useAllUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      keyword: filter.keyword,
      countryId: filter.countryId,
      advertiserIds: filter.advertiserIds.map(Number)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allUsersCount?.totalNumber;

  return (
    <>
      <Header>
        {!hideAddUserBtn ? (
          <ButtonLink
            variant="blue"
            to="/user/add"
            title={t('Button.Add User')}
            prefixIcon={{ icon: 'plus', size: '10px' }}
            css={{ minWidth: '140px', textTransform: 'capitalize', marginLeft: 'auto' }}
          />
        ) : null}
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <UsersFilter />
        <UsersList loading={loading} data={result?.allUsers || []} totalRecords={totalRecords} />
      </ListWithPagination>
    </>
  );
};
