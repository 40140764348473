import { DownloadButton } from '@/shared/atoms';
import { ListWithPagination } from '@/shared/molecules';
import { useDownloadFile, useParamsSearch } from '@/shared/hooks';
import { convertOrderToORDER, getOffset, getRestApiUrl, getValuableVariables } from '@/shared/utils';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  InfluencerSponsoredPostsList,
  InfluencerSponsoredPostsListSearchSchemaType
} from '@/shared/organisms';
import { SocialAccountType, useSponsoredPostsQuery, useSponsoredPostsCountQuery } from '@/graphql';
import { useInitialInfluencerPostsListWithName } from '../hooks';

export const InfluencerSponsoredPosts = () => {
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName({
    titleWithName: 'appHeader.Sponsored Post List'
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<InfluencerSponsoredPostsListSearchSchemaType>();

  const skip = !influencerId || !mainSocialAccountId;
  const socialAccountType = (filter.sm || SocialAccountType.INSTAGRAM) as SocialAccountType;
  const variables = {
    influencerId,
    socialAccountId: mainSocialAccountId,
    keyword: filter.keyword,
    socialMedia: socialAccountType
  };

  const { data, previousData, loading } = useSponsoredPostsQuery({
    skip,
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      ...variables
    })
  });
  const { data: countData, previousData: previousCountData } = useSponsoredPostsCountQuery({ skip, variables });

  const result = loading ? previousData : data;
  const countResult = loading ? previousCountData : countData;
  const totalRecords = countResult?.sponsoredPostsCount?.total;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} />

      <ProfilePostsCount count={totalRecords}>
        <DownloadButton
          loading={downloading}
          onClick={() =>
            handleDownloadFile({
              filenamePrefix: 'sponsored_posts',
              url: getRestApiUrl(
                `influencer/sponsored_post/${influencerId}/${mainSocialAccountId}/${socialAccountType}/csv/`
              )
            })
          }
        />
      </ProfilePostsCount>

      <InfluencerSponsoredPostsList
        loading={loading}
        influencerId={influencerId}
        data={result?.sponsoredPosts?.posts || []}
        status={filter?.status}
      />
    </ListWithPagination>
  );
};
