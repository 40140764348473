import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { DouyinInfluencersProps } from './schemaTypes';
import { DouyinInfluencersList } from './DouyinInfluencersList';
import { DouyinInfluencersSort } from './DouyinInfluencersSort';
import { DouyinInfluencersFilter } from './DouyinInfluencersFilter';
import { DouyinInfluencersProvider } from './DouyinInfluencersContext';

export const DouyinInfluencers = (props: DouyinInfluencersProps) => (
  <DouyinInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <DouyinInfluencersFilter />
      <DouyinInfluencersSort />
    </InfluencersFilterWrapper>

    <DouyinInfluencersList />
  </DouyinInfluencersProvider>
);

export * from './schemaTypes';
