import { z } from 'zod';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { SearchFilterDefaults } from '@/shared/validations';

export const AnalyticsIgBrandAmbassadorPostsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(ProfilePostsFilterSchema, {
    startDate: getThirtyDaysAgo(),
    endDate: getToday()
  }),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsIgBrandAmbassadorPostsSearchSchemaType = z.infer<
  typeof AnalyticsIgBrandAmbassadorPostsSearchSchema
>;
