import { useParams } from '@tanstack/react-router';
import {
  Maybe,
  namedOperations,
  TikTokSpecialAdPostInfo,
  TikTokSpecialDraftAdPostInfo,
  useBoostTiktokAdPostMutation,
  useCreateDraftBoostPostMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { FormProvider } from '@/shared/atoms';
import { SparkAdsSettingsFormValues, SparkAdsSettingsSchema } from './types';
import { Template } from './Template';
import { getSparkAdsSettingsDefaultValues } from './utils';

interface SparkAdsSettingsProps {
  boostedInfo?: Maybe<TikTokSpecialAdPostInfo>;
  draftBoostedInfo?: Maybe<TikTokSpecialDraftAdPostInfo>;
  postSystemId?: Maybe<number>;
  campaignFinished: boolean;
}
export const SparkAdsSettings = ({
  postSystemId,
  boostedInfo,
  draftBoostedInfo,
  campaignFinished
}: SparkAdsSettingsProps) => {
  const { id: tkSpecialCampaignId } = useParams({ from: '/_private-routes/tiktok/campaigns/$id/posts/$postId' });
  const { callBoostTiktokAdPost } = useBoostTiktokAdPostMutation({
    refetchQueries: [namedOperations.Query.TiktokSpecialPost]
  });
  const { callCreateDraftBoostPost } = useCreateDraftBoostPostMutation({
    refetchQueries: [namedOperations.Query.TiktokSpecialPost]
  });
  const { t, enqueueSnackbar } = useQueryHelper();

  const onSubmit = async (formData: SparkAdsSettingsFormValues) => {
    if (postSystemId && tkSpecialCampaignId) {
      try {
        await callBoostTiktokAdPost({
          variables: {
            input: {
              landingPageUrl: formData.landingPageUrl,
              adAccountSystemId: Number(formData.adAccountSystemId),
              adGroupId: formData.adGroupId,
              adName: formData.adName,
              authCode: formData.authCode,
              callToAction: formData.callToAction,
              postSystemId: postSystemId.toString(),
              tkSpecialCampaignId: Number(tkSpecialCampaignId)
            }
          }
        });
        enqueueSnackbar('Post was successfully boosted', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    }
  };
  const onSaveAsDraft = async (formData: SparkAdsSettingsFormValues) => {
    if (postSystemId && tkSpecialCampaignId) {
      try {
        await callCreateDraftBoostPost({
          variables: {
            input: {
              landingUrl: formData.landingPageUrl,
              adAccountSystemId: Number(formData.adAccountSystemId),
              adGroupId: formData.adGroupId,
              adName: formData.adName,
              authPostCode: formData.authCode,
              callToAction: formData.callToAction,
              postSystemId: postSystemId.toString(),
              tkSpecialCampaignId: Number(tkSpecialCampaignId)
            }
          }
        });
        enqueueSnackbar('Draft was successfully saved', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    }
  };

  return (
    <FormProvider<SparkAdsSettingsFormValues>
      onSubmit={onSubmit}
      defaultValues={
        boostedInfo === null
          ? getSparkAdsSettingsDefaultValues(draftBoostedInfo)
          : getSparkAdsSettingsDefaultValues(boostedInfo)
      }
      zodSchema={SparkAdsSettingsSchema}
    >
      <Template
        onSaveAsDraft={onSaveAsDraft}
        hasBeenBoosted={!!boostedInfo}
        campaignFinished={campaignFinished}
        tkSpecialCampaignId={tkSpecialCampaignId}
      />
    </FormProvider>
  );
};
