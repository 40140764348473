import { useTranslation } from 'react-i18next';
import { SwitchToggleField, SwitchToggleFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const NotificationField = (props: Omit<SwitchToggleFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return (
    <SwitchToggleField<InfluencerFormKeys>
      help={t('Tooltip.Notification settings help')}
      title={t('TextForm.Notification Settings')}
      name={InfluencerSharedKeys.notificationSetting}
      {...props}
    />
  );
};
