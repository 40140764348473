import { QueryHookOptions } from '@apollo/client';

import { Query, useYoutubeAnalyticsCountriesCategoriesQuery } from '@/graphql';
import { useTranslateOptions, useTranslateCountryOptions } from '@/shared/hooks';
import { getOptions } from '@/shared/utils';

export const useYoutubeAnalyticsCountriesCategoriesOptions = (options?: QueryHookOptions<Query>) => {
  const { data, loading } = useYoutubeAnalyticsCountriesCategoriesQuery({ fetchPolicy: 'cache-first', ...options });

  const response = data?.youtubeAnalyticsCountriesCategories;
  const allCountryOptions = useTranslateCountryOptions(getOptions(response?.countries || []));
  const allCategoryOptions = useTranslateOptions(getOptions(response?.categories || []));

  return {
    loading,
    allCountryOptions,
    allCategoryOptions,
    date: response?.date
  };
};
