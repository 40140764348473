import { zodValidator } from '@tanstack/zod-adapter';
import { PermissionRoute } from '@/app-components';
import { EditPartner, PartnerBusiness, PartnerDetails, Partners, PartnerUsers } from '@/pages/Partners';
import { adminStaffRoles } from '@/shared/constants';
import { AccountFilterSearchSchema, AssociatedAccountSearchSchema } from '@/shared/organisms';
import { PartialRouter } from '../../types';

export const PartnerRouter = {
  '/_private-routes/partner': [() => <PermissionRoute permissions={adminStaffRoles} />, {}],
  '/_private-routes/partner/': [Partners, { validateSearch: zodValidator(AccountFilterSearchSchema) }],
  '/_private-routes/partner/$id': [PartnerDetails, {}],
  '/_private-routes/partner/$id/': [EditPartner, {}],
  '/_private-routes/partner/$id/business': [
    PartnerBusiness,
    { validateSearch: zodValidator(AssociatedAccountSearchSchema) }
  ],
  '/_private-routes/partner/$id/users': [PartnerUsers, {}]
} as const satisfies PartialRouter;
