import {
  YoutubeCmsEstimateAsset,
  YoutubeCmsConfirmedAsset,
  YoutubeCmsEstimateChannel,
  YoutubeCmsConfirmedChannel
} from '@/graphql';

export enum YoutubeCMSSegmentMode {
  ASSETS = 'ASSETS',
  CHANNELS = 'CHANNELS'
}
export enum YoutubeCMSRevenueType {
  ESTIMATED_REVENUE = 'ESTIMATED_REVENUE',
  CONFIRMED_REVENUE = 'CONFIRMED_REVENUE'
}

// Create common types between Estimated & Confirmed CMS
export type YoutubeCMSAsset = YoutubeCmsConfirmedAsset &
  Partial<Pick<YoutubeCmsEstimateAsset, 'id' | 'taRevenue' | 'taProfit' | 'taShareRate' | 'rpm' | 'taRpm' | 'isOwner'>>;
export type YoutubeCMSChannel = YoutubeCmsConfirmedChannel &
  Partial<Pick<YoutubeCmsEstimateChannel, 'id' | 'taRevenue' | 'taProfit' | 'taShareRate' | 'rpm' | 'taRpm'>>;
