import { FormProvider } from '@/shared/atoms';
import { FormAction } from '@/shared/types';
import { InfluencerFormSchema } from './schema';
import { Template } from './Template';
import { InfluencerFormValues } from './types';
import { defaultInfluencerFormValues } from './utils';

export type InfluencerFormProps = FormAction<InfluencerFormValues>;

export const InfluencerForm = ({ onSubmit, defaultValues = defaultInfluencerFormValues }: InfluencerFormProps) => (
  <FormProvider<InfluencerFormValues>
    onSubmit={onSubmit}
    zodSchema={InfluencerFormSchema}
    values={defaultValues as InfluencerFormValues}
  >
    <Template />
  </FormProvider>
);

export * from './types';
export { defaultInfluencerFormValues, getInfluencerFormValues, getCreateUpdateInfluencerInput } from './utils';
