import { QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';

import { Query, useAllPackageCompaniesQuery } from '@/graphql';

export const useAllPackageCompanyOptions = (options?: QueryHookOptions<Query>) => {
  const { data, loading } = useAllPackageCompaniesQuery({ fetchPolicy: 'cache-first', ...options });

  const allPackageCompanies = data?.allPackageCompanies || [];

  return {
    loading,
    allPackageCompanies,
    allPackageCompanyOptions: useMemo(
      () => allPackageCompanies.map(({ id, name }) => ({ label: name, value: String(id) })),
      [allPackageCompanies]
    )
  };
};
