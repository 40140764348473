import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAuthData, useUserRoles } from '@/auth';

export const useIntercomInitializer = () => {
  const {
    auth: { email, userId, role }
  } = useAuthData();
  const { isAdvertiserFromTikTok } = useUserRoles();
  const { boot, update } = useIntercom();

  update({ hideDefaultLauncher: true });

  useEffect(() => {
    if (email && userId && !isAdvertiserFromTikTok) {
      boot({
        email,
        userId: userId.toString(),
        customAttributes: { userRole: role }
      });
    }
  }, []);
};
