import { TwitterInfluencer, SocialAccountType } from '@/graphql';
import { InfluencersTabType } from '@/shared/types';
import { TwitterInfluencerProfile, TwitterInfluencersFilterFormValues } from './schemaTypes';

export const getInitialTwitterInfluencersFormValues = (
  defaultFilter?: Partial<TwitterInfluencersFilterFormValues>
): Partial<TwitterInfluencersFilterFormValues> => ({ tab: InfluencersTabType.TWITTER, ...defaultFilter });

export const formatInfluencerProfile = (infl: TwitterInfluencer): TwitterInfluencerProfile => ({
  id: infl.id,
  age: infl.age,
  name: infl.name,
  gender: infl.gender,
  avatar: infl.avatar,
  country: infl.country,
  averageLikes: infl.averageLikes,
  isBrandAccount: infl.isBrandAccount,
  socialAccountId: infl.socialAccountId,
  analyticsWarning: infl.analyticsWarning,
  socialAccountType: SocialAccountType.TWITTER,
  socialAccountStatus: infl.socialAccountStatus,
  socialAccountFollowers: infl.socialAccountFollowers,
  socialAccountEngagement: infl.socialAccountEngagement,
  socialAccountEngagementFollowersRate: infl.socialAccountEngagementFollowersRate,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail, caption }) => ({
      thumbNail,
      id: postId,
      influencerId: Number(infl.id),
      caption
    })) || []
});
