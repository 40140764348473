import { ReactNode } from 'react';

export interface ModalTitleProps {
  className?: string;
  children: ReactNode;
}

export const ModalTitle = ({ children, ...restProps }: ModalTitleProps) => (
  <div css={{ lineHeight: 1.2, fontSize: '18px', fontWeight: 600 }} {...restProps}>
    {children}
  </div>
);
