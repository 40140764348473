import { useTranslation } from 'react-i18next';
import { GENDER_OPTIONS } from '@/shared/constants';
import { useTranslateOptions } from '@/shared/hooks';
import { CheckBoxesField, CheckBoxesFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const GenderCheckBoxesField = (props: Omit<CheckBoxesFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const genderOptions = useTranslateOptions(GENDER_OPTIONS);

  return (
    <CheckBoxesField
      options={genderOptions}
      title={t('General.Gender')}
      help={t('Tooltip.Campaign Gender')}
      name={MarketplaceFormKeys.genders}
      {...props}
    />
  );
};
