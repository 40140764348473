import { ReactNode } from 'react';
import { THEME } from '@/shared/constants';
import { ButtonSubmit as Button, ButtonSubmitProps } from '@/shared/atoms';

export interface ButtonSubmitWrapperProps {
  className?: string;
  children: ReactNode;
}

export const ButtonSubmitWrapper = (props: ButtonSubmitWrapperProps) => (
  <div
    css={{
      gap: '16px',
      display: 'flex',
      padding: '16px 24px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderTop: THEME.border.base
    }}
    {...props}
  />
);

export const ButtonSubmit = (props: ButtonSubmitProps) => <Button css={{ padding: '0 24px' }} {...props} />;
