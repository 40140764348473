import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RefreshTwitterAccount.graphql';
import { Mutation, MutationrefreshTwitterAccountArgs } from '../../__generated__/globalTypes';

export const useRefreshTwitterAccountMutation = (
  options?: MutationHookOptions<Mutation, MutationrefreshTwitterAccountArgs>
) => {
  const [callRefreshTwitterAccount, result] = useMutation<Mutation, MutationrefreshTwitterAccountArgs>(
    MUTATION,
    options
  );

  return { callRefreshTwitterAccount, ...result };
};

export type RefreshTwitterAccountMutationFunction = Mutation['refreshTwitterAccount'];
