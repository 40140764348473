import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { Icon } from '../../../Icon';
import { Tooltip } from '../../../Tooltip';

export interface ChatBoxRegenerateButtonProps {
  onRegenerate: () => void;
}
export const ChatBoxRegenerateButton = ({ onRegenerate }: ChatBoxRegenerateButtonProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip help={t<string>('Tooltip.RegenerateMessage')}>
      <div css={{ display: 'flex' }} onClick={onRegenerate}>
        <Icon
          size={11}
          icon="refresh"
          color={THEME.icon.colors.white}
          css={{ ':hover': { cursor: 'pointer', opacity: 0.85 } }}
        />
      </div>
    </Tooltip>
  );
};
