import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AddAllSelectedInfluencers.graphql';
import { Mutation, MutationaddAllSelectedInfluencersArgs } from '../../__generated__/globalTypes';

export const useAddAllSelectedInfluencersMutation = (
  options?: MutationHookOptions<Mutation, MutationaddAllSelectedInfluencersArgs>
) => {
  const [callAddAllSelectedInfluencers, result] = useMutation<Mutation, MutationaddAllSelectedInfluencersArgs>(
    MUTATION,
    options
  );

  return { callAddAllSelectedInfluencers, ...result };
};

export type AddAllSelectedInfluencersMutationFunction = Mutation['addAllSelectedInfluencers'];
