import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllPartnerAgencyCompany.graphql';
import { Query, QueryallPartnerAgencyCompanyArgs } from '../../__generated__/globalTypes';

export const useAllPartnerAgencyCompanyQuery = (
  options?: QueryHookOptions<Query, QueryallPartnerAgencyCompanyArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallPartnerAgencyCompanyArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllPartnerAgencyCompanyLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallPartnerAgencyCompanyArgs>
): QueryResult<Query, QueryallPartnerAgencyCompanyArgs> & {
  getAllPartnerAgencyCompany: LazyQueryExecFunction<Query, QueryallPartnerAgencyCompanyArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllPartnerAgencyCompany, result] = useLazyQuery<Query, QueryallPartnerAgencyCompanyArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllPartnerAgencyCompany, ...result };
};

export const useAllPartnerAgencyCompanyPromiseQuery = () => {
  const client = useApolloClient();

  const getAllPartnerAgencyCompany = useCallback(
    (options?: Omit<QueryOptions<QueryallPartnerAgencyCompanyArgs, Query>, 'query'>) =>
      client.query<Query, QueryallPartnerAgencyCompanyArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllPartnerAgencyCompany };
};
