import { useTranslation } from 'react-i18next';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useAgencyBusinessAssociateCompaniesQuery } from '@/graphql';
import { AssociatedAccountsList, AssociatedAccountsFilter, useAssociatedAccountsFilter } from '@/shared/organisms';

export interface AgencyAssociatedAccountsProps {
  agencyId: number;
}

export const AgencyAssociatedAccounts = ({ agencyId }: AgencyAssociatedAccountsProps) => {
  const { t } = useTranslation();
  const { page, limit, filter } = useAssociatedAccountsFilter();
  const { data, previousData, loading } = useAgencyBusinessAssociateCompaniesQuery({
    fetchPolicy: 'network-only',
    variables: getValuableVariables({ limit, agencyId, offset: getOffset(page, limit), ...filter })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.agencyBusinessAssociateCompaniesCount?.totalNumber || 0;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <AssociatedAccountsFilter keywordPlaceholder={t<string>('Search.Search by Advertiser Name')} />
      <AssociatedAccountsList
        loading={loading}
        data={result?.agencyBusinessAssociateCompanies || []}
        refetchQueries={['AgencyBusinessAssociateCompanies']}
      />
    </ListWithPagination>
  );
};
