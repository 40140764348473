import { DownloadButton } from '@/shared/atoms';
import { ListWithPagination } from '@/shared/molecules';
import { useDownloadFile, useParamsSearch } from '@/shared/hooks';
import { useAllInstagramTaggedPostsQuery } from '@/graphql';
import { getOffset, getUploadApiUrl, getValuableVariables, convertOrderToORDER } from '@/shared/utils';
import { ProfilePostsCount, ProfilePostsFilter, AnalyticsInstagramTaggedPostsList } from '@/shared/organisms';
import { useInitialAnalyticsPosts } from '../hooks';
import { AnalyticsIgTaggedPostsSearchSchemaType } from './schemaTypes';

export const AnalyticsInstagramTaggedPosts = () => {
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { brandAccountId, mainSocialAccountId } = useInitialAnalyticsPosts({
    socialMedia: 'instagram',
    analyticsProfileUrl: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction',
    document: (name) => ({
      appHeader: name ? 'appHeader.TaggedPostsListWithAccountName' : 'appHeader.Tagged Posts List',
      title: name ? 'documentTitle.TaggedPostsListWithAccountName' : 'documentTitle.Tagged Posts List'
    })
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<AnalyticsIgTaggedPostsSearchSchemaType>();
  const { data, previousData, loading } = useAllInstagramTaggedPostsQuery({
    skip: !brandAccountId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      accountId: Number(mainSocialAccountId),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      ...filter
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allInstagramTaggedPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

      <ProfilePostsCount count={totalRecords}>
        <DownloadButton
          loading={downloading}
          onClick={() =>
            handleDownloadFile({
              filenamePrefix: 'taggedPosts',
              url: getUploadApiUrl(`analytics/tagged_post/${mainSocialAccountId}/csv`),
              params: getValuableVariables({ endDate: filter.endDate, startDate: filter.startDate })
            })
          }
        />
      </ProfilePostsCount>

      <AnalyticsInstagramTaggedPostsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.allInstagramTaggedPosts || []}
      />
    </ListWithPagination>
  );
};
