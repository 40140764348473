import { useTranslation } from 'react-i18next';
import { linkOptions } from '@tanstack/react-router';
import { AppLanguage } from '@/shared/types';
import { THEME } from '@/shared/constants';
import { formatNumber, localizedDateFormatter, formatPercent } from '@/shared/utils';
import {
  Anchor,
  RowProps,
  TableCell,
  TextCutter,
  ColumnProps,
  MediaPreview,
  InputNumberFormatter
} from '@/shared/atoms';
import { useUserRoles } from '@/auth';
import { useUpdateYoutubeChannelShareRate } from '../../hooks';
import { YoutubeCMSChannel, YoutubeCMSRevenueType } from '../../types';

interface Props {
  modifiable?: boolean;
  type: YoutubeCMSRevenueType;
  data: readonly YoutubeCMSChannel[];
}

export const useYoutubeCMSChannelsTable = ({ type, data, modifiable = true }: Props) => {
  const { t, i18n } = useTranslation();
  const { isAdminStaff } = useUserRoles();
  const { editingRows, handleEnter, handleRowChange, handleSaveChange, saving } = useUpdateYoutubeChannelShareRate();

  const isConfirmedRevenue = type === YoutubeCMSRevenueType.CONFIRMED_REVENUE;
  const canEditShareRate = !isConfirmedRevenue && modifiable;
  const columns: ColumnProps[] = [
    {
      title: t('HeaderColumn.Channel name'),
      sticky: { active: true, hasBorderRight: isAdminStaff && !isConfirmedRevenue }
    },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Estimated RPM'),
      hidden: !isAdminStaff || isConfirmedRevenue
    },
    {
      hidden: isConfirmedRevenue,
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Estimated talent agency RPM')
    },
    {
      hidden: !isAdminStaff,
      styles: { textAlign: 'right' },
      title: t(isConfirmedRevenue ? 'HeaderColumn.Confirmed partner revenue' : 'HeaderColumn.Estimated partner revenue')
    },
    {
      hidden: isConfirmedRevenue,
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Estimated talent agency revenue')
    },
    {
      styles: { textAlign: 'right' },
      title: t(
        isConfirmedRevenue ? 'HeaderColumn.Confirmed share to creator' : 'HeaderColumn.Estimated share to creator'
      )
    },
    {
      hidden: !isAdminStaff,
      styles: { textAlign: 'right' },
      title: t(isConfirmedRevenue ? 'HeaderColumn.Confirmed gross profit' : 'HeaderColumn.Estimated GP')
    },
    {
      hidden: isConfirmedRevenue,
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Estimated talent agency GP')
    },
    {
      styles: { textAlign: 'right' },
      hidden: !isAdminStaff || isConfirmedRevenue,
      title: t('HeaderColumn.Talent agency margin rate')
    },
    { title: t('HeaderColumn.Creator margin rate') }
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      rpm,
      taRpm,
      avatar,
      profit,
      taProfit,
      currency,
      channelId,
      taRevenue,
      shareRate,
      taShareRate,
      channelName,
      shareCreator,
      partnerRevenue
    }) => {
      const savedShareRate = editingRows.find((row) => row.id === id)?.value;
      const formattedShareRate = savedShareRate ?? String(shareRate ?? '');
      const link =
        !isConfirmedRevenue && id
          ? linkOptions({ to: '/youtube-cms/$cmsChannelId', params: { cmsChannelId: id.toString() || '' } })
          : undefined;

      return [
        <div css={{ display: 'flex', minWidth: '200px' }}>
          <MediaPreview css={{ borderRadius: '3px', width: '32px', height: '32px' }} src={avatar} />
          <div css={{ marginLeft: '8px', lineHeight: '100%' }}>
            <Anchor
              lines={2}
              label={channelName || '-'}
              css={{ fontSize: '14px' }}
              disabled={isConfirmedRevenue || !id}
              {...link}
            />
            <p css={{ fontSize: '10px', color: THEME.text.colors.gray.lv3, marginTop: '4px' }}>
              {t('id')}:&nbsp;
              <span css={{ userSelect: 'all' }}>{channelId}</span>
            </p>
          </div>
        </div>,
        <TableCell.Number value={formatNumber(rpm)} unit={t(currency)} />,
        <TableCell.Number value={formatNumber(taRpm)} unit={t(currency)} />,
        <TableCell.Number value={formatNumber(partnerRevenue)} unit={t(currency)} />,
        <TableCell.Number value={formatNumber(taRevenue)} unit={t(currency)} />,
        <TableCell.Number value={formatNumber(shareCreator)} unit={t(currency)} />,
        <TableCell.Number value={formatNumber(profit || null)} unit={t(currency)} />,
        <TableCell.Number value={formatNumber(taProfit || null)} unit={t(currency)} />,
        <TableCell.Number value={formatPercent(taShareRate, false)} unit="%" />,
        <div css={{ minWidth: '170px' }}>
          <InputNumberFormatter
            unit="%"
            format="percent"
            placeholder="0"
            onKeyPress={handleEnter}
            css={{ width: '144px' }}
            value={formattedShareRate}
            disabled={!canEditShareRate}
            onChange={(value) => {
              if (canEditShareRate && id) {
                handleRowChange({ id, channelId, value, defaultValue: String(shareRate ?? '') });
              }
            }}
          />
          <TextCutter
            css={{ color: THEME.text.colors.gray.lv3, fontSize: '12px', marginTop: '2px' }}
            text={`${t('Last Updated').toLowerCase()}: ${localizedDateFormatter(
              null,
              'PPP',
              i18n.language as AppLanguage
            )}`}
          />
        </div>
      ];
    }
  );

  return {
    rows,
    saving,
    columns,
    handleSaveChange,
    hasRowChange: !!editingRows.length
  };
};
