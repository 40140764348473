import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerAnalyticsFacebookPageAccessTokenWarning.graphql';
import { Query, QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs } from '../../__generated__/globalTypes';

export const useInfluencerAnalyticsFacebookPageAccessTokenWarningQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerAnalyticsFacebookPageAccessTokenWarningLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs>
): QueryResult<Query, QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs> & {
  getInfluencerAnalyticsFacebookPageAccessTokenWarning: LazyQueryExecFunction<
    Query,
    QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs
  >;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerAnalyticsFacebookPageAccessTokenWarning, result] = useLazyQuery<
    Query,
    QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs
  >(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getInfluencerAnalyticsFacebookPageAccessTokenWarning, ...result };
};

export const useInfluencerAnalyticsFacebookPageAccessTokenWarningPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerAnalyticsFacebookPageAccessTokenWarning = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerAnalyticsFacebookPageAccessTokenWarning };
};
