import { useInstagramAnalyticsReelDetailsQuery, useInfluencerInstagramAnalyticsReelDetailsQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface InfluencerInstagramAnalyticsReelDetailsProps
  extends Pick<PostDetailsType, 'id' | 'mainSocialAccountId'> {
  className?: string;
  isAnalytics?: boolean;
  onCloseModal?: () => void;
}

export const InfluencerInstagramAnalyticsReelDetails = ({
  id,
  className,
  isAnalytics,
  onCloseModal,
  mainSocialAccountId
}: InfluencerInstagramAnalyticsReelDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useInfluencerInstagramAnalyticsReelDetailsQuery({
    skip: !id || !mainSocialAccountId || isAnalytics,
    variables: { postId: Number(id), socialAccountId: Number(mainSocialAccountId) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const { data: analyticsData, loading: fetchingAnalyticsData } = useInstagramAnalyticsReelDetailsQuery({
    skip: !id || !mainSocialAccountId || !isAnalytics,
    variables: { postId: Number(id), accountId: Number(mainSocialAccountId) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const details = data?.influencerInstagramAnalyticsReelDetails || analyticsData?.instagramAnalyticsReelDetails;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        isReel: true,
        socialMedia: 'INSTAGRAM',
        postUrl: details?.postUrl,
        caption: details?.content,
        comments: details.commentList || [],
        images: details?.thumbnail ? [details?.thumbnail] : [],
        statistics: {
          likes: details?.analysis?.likes,
          comments: details?.analysis?.comments,
          views: details?.analysis?.views,
          saves: details?.analysis?.saves,
          reach: details?.analysis?.reach,
          shares: details?.analysis?.shares
        },
        info: {
          socialMedia: 'INSTAGRAM',
          avatar: details?.avatarUrl,
          postDate: details?.postDate,
          followers: details.followers,
          name: details?.instagramAccountName
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      className={className}
      postDetails={postDetails}
      css={{ width: THEME.modal.size.lv3 }}
      loading={loading || fetchingAnalyticsData}
    />
  );
};
