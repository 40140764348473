import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { emptyImage2, videoThumbnailImage } from '@/shared/assets';
import { getDecodedUrlFileName, getFileTypeFromUrl } from '@/shared/utils';
import { Icon } from '../../Icon';
import { ProgressBar } from '../../ProgressBar';
import { SortableDragArea, SortableItem } from '../../SortableList';

export interface PreviewItemProps {
  id: number;
  url: string;
  preview: string;
  disabled?: boolean;
  className?: string;
  onDelete: () => void;
  widthPercentage: string;
  uploadProgress?: { progress: number; progressUrl: string | null };
}

export const PreviewItem = ({
  id,
  url,
  preview,
  onDelete,
  disabled,
  className,
  uploadProgress,
  widthPercentage
}: PreviewItemProps) => {
  const fileType = getFileTypeFromUrl(url);
  const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileType);
  // when we create preview from Blob fileType=[avi, mov, mp4], when we upload file fileType is normal MIME
  const isVideo = ['video/avi', 'avi', 'video/mp4', 'mp4', 'video/quicktime', 'mov'].includes(fileType);
  const isEmptyImage = !(isImage || isVideo);
  const imagePreview = preview && isImage ? preview : emptyImage2;
  const hasProgressBar = url === uploadProgress?.progressUrl && uploadProgress?.progress !== 100;

  return (
    <div className={className} css={{ width: widthPercentage, flexBasis: widthPercentage }}>
      <SortableItem id={id}>
        {({ isDragging }) => (
          <div css={{ padding: '8px', boxSizing: 'border-box' }}>
            <div css={isDragging && { width: '100%', visibility: 'hidden' }}>
              <div css={styles.previewContent}>
                <SortableDragArea css={{ width: '100%' }}>
                  <div css={{ height: 0, paddingTop: '100%', position: 'relative' }}>
                    {isImage && <img css={styles.image} src={imagePreview} />}
                    {isVideo && <img css={styles.video} src={videoThumbnailImage} />}
                    {isEmptyImage && (
                      <a download={url} href={url}>
                        <img css={styles.video} src={imagePreview} />
                      </a>
                    )}
                  </div>
                </SortableDragArea>

                {!disabled && (
                  <span css={styles.button} onClick={onDelete}>
                    <Icon icon="close" size={6} />
                  </span>
                )}
              </div>

              {hasProgressBar ? <ProgressBar value={uploadProgress.progress} /> : <div css={{ height: '4px' }} />}

              <p css={styles.label} title={getDecodedUrlFileName(preview)}>
                {getDecodedUrlFileName(preview)}
              </p>
            </div>
          </div>
        )}
      </SortableItem>
    </div>
  );
};

const styles = {
  previewContent: css({
    borderRadius: '3px',
    position: 'relative',
    boxSizing: 'border-box',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  }),
  image: css({
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    position: 'absolute',
    transform: 'translate(-50%, -50%)'
  }),
  video: css({
    top: '50%',
    left: '50%',
    width: '100%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)'
  }),
  label: css({
    marginTop: '2px',
    fontSize: '12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }),
  button: css({
    top: 0,
    right: 0,
    zIndex: 1,
    width: '12px',
    height: '12px',
    padding: '4px',
    cursor: 'pointer',
    borderRadius: '50%',
    position: 'absolute',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    backgroundColor: '#27313b',
    color: THEME.text.colors.white,
    transform: 'translate(50%, -50%)',
    transition: 'color 0.3s ease-in-out',
    textShadow: '0 0 4px rgba(0, 0, 0, 0.7)',
    '&:hover': { opacity: 0.8 }
  })
};
