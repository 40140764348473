import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { MkpFormFields } from '@/shared/organisms';
import { TikTokCampaignDisabledFieldsType, TikTokCampaignFormKeys } from '../types';

interface PackageInformationProps {
  disabledFields: TikTokCampaignDisabledFieldsType;
}
export const PackageInformation = ({ disabledFields }: PackageInformationProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ padding: '24px 24px 0' }}>
      <FormStyle.SectionTitle index={1} label={t('Advanced Campaign Package')} />

      <FormStyle.FieldsGroup itemsPerRow={2}>
        <MkpFormFields.PreLaunchDateField<TikTokCampaignFormKeys>
          name="preLaunchDate"
          disabled={disabledFields.preLaunchDate}
        />

        <MkpFormFields.CampaignPeriodField<TikTokCampaignFormKeys>
          endDateProps={{ name: 'endDate', disabled: disabledFields.endDate }}
          startDateProps={{ name: 'startDate', disabled: disabledFields.startDate }}
          required
        />

        <MkpFormFields.CampaignCategoryField<TikTokCampaignFormKeys>
          name="campaignCategoryId"
          disabled={disabledFields.campaignCategoryId}
          required
        />
      </FormStyle.FieldsGroup>
    </div>
  );
};
