import { useQueryHelper } from './useQueryHelper';

/**
 * Copy Text to Clipboard
 * Ref: https://qiita.com/simiraaaa/items/2e7478d72f365aa48356
 */
const copy = (target: string) => {
  // blank div
  const tmp = document.createElement('div');
  // tag for select
  const pre = document.createElement('pre');

  pre.style.webkitUserSelect = 'auto';
  pre.style.userSelect = 'auto';

  tmp.appendChild(pre).textContent = target;

  // Move the element to outside
  const s = tmp.style;
  s.position = 'fixed';
  s.right = '200%';

  document.body.appendChild(tmp);
  const selection = document.getSelection();
  if (!selection) {
    return false;
  }
  selection.selectAllChildren(tmp);

  // copy to clipboard
  const success = document.execCommand('copy');

  document.body.removeChild(tmp);

  return success;
};

export const useCopy = (defaultText?: string | null) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const copyToClipboard = (text?: string | null, showToast = true) => {
    const copiedText = text || defaultText;

    if (copiedText && copy(copiedText) && showToast) {
      enqueueSnackbar(t('Copied'), { variant: 'success' });
    }
  };

  return { copyToClipboard };
};
