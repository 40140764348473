import { TwitterAnalyticsPostsByDatePayload } from '@/graphql';
import { TwitterPostInDateDetails, TwitterPostInDateDetailsProps } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface TwitterPostInDateDetailsModalProps {
  paramKey?: string;
  data: TwitterAnalyticsPostsByDatePayload | null;
}

export const TwitterPostInDateDetailsModal = ({ data, paramKey }: TwitterPostInDateDetailsModalProps) => {
  const posts =
    data?.posts.map<NonNullable<TwitterPostInDateDetailsProps['data']>>((post) => ({
      ...post,
      avatar: data.avatar,
      username: data.username,
      followers: data.followers
    })) || [];

  return (
    <CarouselTemplateModal posts={posts} paramKey={paramKey}>
      {({ selectedPost }) => <TwitterPostInDateDetails data={selectedPost} />}
    </CarouselTemplateModal>
  );
};
