import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ReorderMarketplaceCampaigns.graphql';
import { Mutation, MutationreorderMarketplaceCampaignsArgs } from '../../__generated__/globalTypes';

export const useReorderMarketplaceCampaignsMutation = (
  options?: MutationHookOptions<Mutation, MutationreorderMarketplaceCampaignsArgs>
) => {
  const [callReorderMarketplaceCampaigns, result] = useMutation<Mutation, MutationreorderMarketplaceCampaignsArgs>(
    MUTATION,
    options
  );

  return { callReorderMarketplaceCampaigns, ...result };
};

export type ReorderMarketplaceCampaignsMutationFunction = Mutation['reorderMarketplaceCampaigns'];
