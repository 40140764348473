import { z } from 'zod';
import { AllTranslationKeys } from '@/shared/assets';
import { BaseValidations } from '@/shared/validations';

const AddSchema = z.object({
  type: z.literal('add'),
  name: BaseValidations.PackageNameSchema,
  countryId: BaseValidations.CountryIdSchema,
  companies: z.array(z.string()).optional()
});

const EditSchema = z.object({
  type: z.literal('edit'),
  id: z.string().min(1, AllTranslationKeys.Error.requiredPackageIdMessage)
});

export const AddPackageWithAccountsSchema = z.discriminatedUnion('type', [AddSchema, EditSchema]);
