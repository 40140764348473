import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteUser.graphql';
import { Mutation, MutationdeleteUserArgs } from '../../__generated__/globalTypes';

export const useDeleteUserMutation = (options?: MutationHookOptions<Mutation, MutationdeleteUserArgs>) => {
  const [callDeleteUser, result] = useMutation<Mutation, MutationdeleteUserArgs>(MUTATION, options);

  return { callDeleteUser, ...result };
};

export type DeleteUserMutationFunction = Mutation['deleteUser'];
