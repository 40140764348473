import { useUserRoles } from '@/auth';
import { FormProvider } from '@/shared/atoms';
import { EggCampaignFormSchema } from './schema';
import { Template } from './Template';
import { EngagementCampaignFormProps, EngagementCampaignFormValues } from './types';
import { defaultEngagementCampaignFormValues } from './utils';

export const EngagementCampaignForm = ({
  onSubmit,
  defaultValues = defaultEngagementCampaignFormValues
}: EngagementCampaignFormProps) => {
  const { isAdminStaff } = useUserRoles();

  return (
    <FormProvider<EngagementCampaignFormValues>
      onSubmit={onSubmit}
      defaultValues={{ ...defaultValues, isAdminStaff }}
      zodSchema={EggCampaignFormSchema}
    >
      <Template />
    </FormProvider>
  );
};

export * from './types';
export {
  formatEngagementFormVariables,
  defaultEngagementCampaignFormValues,
  getDefaultEngagementCampaignFormValues
} from './utils';
