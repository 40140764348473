import { QueryHookOptions } from '@apollo/client';
import { Option } from '@/shared/types';
import { Query, useAllCampaignCategoriesQuery } from '@/graphql';
import { useTranslateOptions } from './useTranslateOptions';

export const useAllCampaignCategoryOptions = (options?: QueryHookOptions<Query>) => {
  const { data, loading } = useAllCampaignCategoriesQuery({ fetchPolicy: 'cache-first', ...options });
  const allCampaignCategories: Option[] =
    data?.allCampaignCategories?.map(({ id, categoryName }) => ({ value: String(id), label: categoryName })) || [];

  return {
    loading,
    allCampaignCategoryOptions: useTranslateOptions(allCampaignCategories)
  };
};
