import React, { ReactNode } from 'react';

import { THEME } from '@/shared/constants';

export interface ModalContentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: ReactNode;
}

export const ModalContent = ({ children, ...restProps }: ModalContentProps) => (
  <div
    css={{
      padding: '30px 24px 24px',
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
      backgroundColor: THEME.background.colors.white
    }}
    {...restProps}
  >
    {children}
  </div>
);
