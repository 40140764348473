import { Outlet, useLoaderData } from '@tanstack/react-router';
import { useEffect } from 'react';
import { RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useCustomHeader, useQueryHelper } from '@/shared/hooks';
import { MarketplaceHeader, MarketplaceTabs } from '@/shared/organisms';
import { MarketplaceRouter } from '../../../app/_private-routes/marketplace';
import { MarketplaceDetailsProvider } from './MarketplaceDetailsContext';

export const MarketplaceDetails = () => {
  useCustomHeader();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { loading, errorMessage, marketplace, networkStatus } = useLoaderData({
    from: '/_private-routes/marketplace/$id'
  }) as Awaited<ReturnType<(typeof MarketplaceRouter)['/_private-routes/marketplace/$id']['1']['loader']>>;

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(t(errorMessage), { variant: 'error' });
      navigate({ to: '/marketplace' });
    }
  }, [errorMessage]);

  return (
    <RenderDataWithFallback loading={loading && networkStatus === 1}>
      <div css={{ width: THEME.container.details.mkpWidth, margin: '0 auto' }}>
        {marketplace ? (
          <MarketplaceDetailsProvider value={{ marketplace }}>
            <MarketplaceHeader marketplace={marketplace} />

            <MarketplaceTabs marketplace={marketplace} />
            <Outlet />
          </MarketplaceDetailsProvider>
        ) : null}
      </div>
    </RenderDataWithFallback>
  );
};
