import { useSearch } from '@tanstack/react-router';
import { useInfluencerPackagePermissions, useUserRoles } from '@/auth';
import { ReadPackageAccount, namedOperations, useDeletePackageMutation } from '@/graphql';
import { Table } from '@/shared/atoms';
import { useQueryHelper, useSelectItem, useToggle } from '@/shared/hooks';
import { ConfirmDeleteModal, WarningModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { PackagesSearchSchemaType, usePackagesFilter } from '../PackagesFilter';
import { usePackageListTable } from './hooks';

export type PackagesListProps = TableData<ReadPackageAccount>;

export const PackagesList = ({ data = [], loading }: PackagesListProps) => {
  const { page } = useSearch({ from: '/_private-routes/package/' }) as PackagesSearchSchemaType;
  const { setPage } = usePackagesFilter();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { isAdminStaffTalentAgencyPartner } = useUserRoles();
  const { hideAddPackageBtn } = useInfluencerPackagePermissions();
  const { selectedItem, setSelectedItem, resetSelectedItem } = useSelectItem();
  const { on, toggle } = useToggle(false);
  const { callDeletePackage, loading: deleting } = useDeletePackageMutation({
    refetchQueries: [namedOperations.Query.AllPackages]
  });

  const canDeletePackage = isAdminStaffTalentAgencyPartner && !hideAddPackageBtn;
  const { rows, columns } = usePackageListTable({ data, canDeletePackage, onDeletePackage: setSelectedItem });

  const handleDeletePackage = async () => {
    if (!selectedItem) return;

    try {
      callDeletePackage({ variables: { id: selectedItem.id } });
      enqueueSnackbar(t('succeededInDeleting', { name: selectedItem.name }), { variant: 'success' });

      if (data.length === 1 && typeof page === 'number' && page > 1) {
        setPage(page - 1, { replace: true });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      resetSelectedItem();
    }
  };

  return (
    <>
      <Table
        loading={loading}
        data={{ rows, columns }}
        empty={{ linkProps: canDeletePackage ? { to: '/package/add', label: t('Add package') } : undefined }}
        noTopBorderRadius
        hasBorder
      />

      <ConfirmDeleteModal
        deleting={deleting}
        onClose={resetSelectedItem}
        deletingItem={selectedItem}
        onDelete={handleDeletePackage}
      />

      <WarningModal open={on} onClose={toggle} description={`${t('Dialog.Has Campaign Before')}?`} />
    </>
  );
};

export default PackagesList;
