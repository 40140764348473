import { useTranslation } from 'react-i18next';
import { GTM_IDS } from '@/shared/constants';
import { getRestApiUrl } from '@/shared/utils';
import { useDownloadFile } from '@/shared/hooks';
import { DownloadFileType, DropdownOption } from '@/shared/types';
import { useReportFilter } from '../../ReportFilter';
import { ReportColumnKeys, ReportColumnProps, ReportCsvDownloadKeys } from '../types';

interface Props {
  columns: ReportColumnProps[];
  selectedColumns: ReportColumnKeys[];
}

export const useDownloadReport = ({ columns, selectedColumns }: Props) => {
  const { t } = useTranslation();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const {
    sort,
    filter: { endDate, startDate, campaigns, socialMedias, countryId, campaignCategoryIds, campaignTypes }
  } = useReportFilter();

  const downloadOptions: DropdownOption<DownloadFileType>[] = [
    { label: t<string>('CSV'), value: DownloadFileType.CSV, gtmId: GTM_IDS.report.csv_download }
  ];

  const handleDownloadReport = async (fileType?: DownloadFileType) => {
    // Only support download CSV for now
    if (fileType === DownloadFileType.CSV) {
      const unsupportedDownloadColumns: Partial<ReportColumnKeys>[] = [
        'sales',
        'retweets', // share
        'conversion',
        'averageViewDurationMinutes'
      ];
      const filteredColumns = columns.reduce<ReportCsvDownloadKeys[]>(
        (results, column) => {
          if (column.key && selectedColumns.includes(column.key) && !unsupportedDownloadColumns.includes(column.key)) {
            // inconsistency on BE CRUD typing
            if (column.key === 'orders') {
              return [...results, 'order'];
            }

            return [...results, column.key];
          }

          return results;
        },

        []
      );

      await handleDownloadFile({
        filenamePrefix: 'report_campaign',
        url: getRestApiUrl('report/campaign/csv/'),
        params: {
          ...sort,
          endDate,
          campaigns,
          countryId,
          startDate,
          socialMedias,
          campaignTypes,
          campaignCategoryIds,
          selectedColumns: filteredColumns
        }
      });
    }
  };

  return { downloading, downloadOptions, handleDownloadReport };
};
