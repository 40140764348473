import { CrowdinButton } from '@/shared/atoms';
import { Header, ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { PERMISSION_DENIED, THEME } from '@/shared/constants';
import { useCustomHeader, useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { PostsInsightList, PostsInsightFilter, usePostsInsightFilter } from '@/shared/organisms';
import { useAllEngagementsForInsightCheckQuery, EngagementCampaignPostStatusForInsightCheck } from '@/graphql';

export const PostsInsight = () => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  useCustomHeader();
  useUpdateDocumentTitle({
    href: '/report',
    alwaysBackToParent: true,
    title: 'appHeader.Posts Insight',
    appHeader: 'appHeader.Posts Insight'
  });
  const { page, limit, filter } = usePostsInsightFilter();
  const { data, loading } = useAllEngagementsForInsightCheckQuery({
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      status: filter.status || null,
      keyword: filter.keyword
    }),
    onError: (error) => {
      if (error.message.includes(PERMISSION_DENIED)) {
        navigate({ to: '/not-found' });
      } else {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    }
  });

  const totalConfirmed = data?.allEngagementsForInsightCheckCount?.totalConfirmed || 0;
  const totalUnconfirmed = data?.allEngagementsForInsightCheckCount?.totalUnconfirmed || 0;
  const totalRecords =
    filter.status === EngagementCampaignPostStatusForInsightCheck.CONFIRMED
      ? totalConfirmed
      : filter.status === EngagementCampaignPostStatusForInsightCheck.UNCONFIRMED
        ? totalUnconfirmed
        : totalConfirmed + totalUnconfirmed;

  return (
    <div css={{ width: THEME.container.details.width, margin: '0 auto', padding: '24px 0' }}>
      <Header>
        <CrowdinButton css={{ marginLeft: 'auto' }} />
      </Header>
      <ListWithPagination pagination={{ totalRecords }}>
        <PostsInsightFilter totalConfirmed={totalConfirmed} totalUnconfirmed={totalUnconfirmed} />
        <PostsInsightList
          loading={loading}
          totalRecords={totalRecords}
          data={data?.allEngagementsForInsightCheck?.engagementCampaigns}
        />
      </ListWithPagination>
    </div>
  );
};
