import { css } from '@emotion/react';
import { ReactNode, forwardRef } from 'react';

export interface TextCutterProps {
  id?: string;
  lines?: number;
  title?: string;
  className?: string;
  onClick?: () => void;
  text: ReactNode | null;
}

export const TextCutter = forwardRef<HTMLParagraphElement, TextCutterProps>(
  ({ id, text, title, lines, className, onClick }, ref) => (
    <p
      tabIndex={ref ? -1 : undefined} // to make shouldFocusError ref work, element needs to be focusable
      id={id}
      ref={ref}
      onClick={onClick}
      className={className}
      css={styles.wrapper(lines)}
      title={typeof title === 'undefined' ? (typeof text === 'string' ? text : '') : title}
    >
      {text}
    </p>
  )
);
const styles = {
  wrapper: (lines: number | undefined = 1) =>
    css({
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      height: 'fit-content',
      wordBreak: 'break-all',
      display: '-webkit-box',
      /* number of lines to show */
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: lines
    })
};
