import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllInstagramComparePostsInDate.graphql';
import { Query, QueryallInstagramComparePostsInDateArgs } from '../../__generated__/globalTypes';

export const useAllInstagramComparePostsInDateQuery = (
  options?: QueryHookOptions<Query, QueryallInstagramComparePostsInDateArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallInstagramComparePostsInDateArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllInstagramComparePostsInDateLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallInstagramComparePostsInDateArgs>
): QueryResult<Query, QueryallInstagramComparePostsInDateArgs> & {
  getAllInstagramComparePostsInDate: LazyQueryExecFunction<Query, QueryallInstagramComparePostsInDateArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllInstagramComparePostsInDate, result] = useLazyQuery<Query, QueryallInstagramComparePostsInDateArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getAllInstagramComparePostsInDate, ...result };
};

export const useAllInstagramComparePostsInDatePromiseQuery = () => {
  const client = useApolloClient();

  const getAllInstagramComparePostsInDate = useCallback(
    (options?: Omit<QueryOptions<QueryallInstagramComparePostsInDateArgs, Query>, 'query'>) =>
      client.query<Query, QueryallInstagramComparePostsInDateArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllInstagramComparePostsInDate };
};
