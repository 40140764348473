import { useTranslation } from 'react-i18next';
import { InstagramInteractionOverview, InstagramInteractionOverviewSummaryData } from '@/graphql';
import {
  heartImage,
  pictureImage,
  chatGreenImage,
  chatAtSignImage,
  heartAtSignImage,
  pictureAtSignImage
} from '@/shared/assets';
import { AnalyticsOverviewChartProps, AnalyticsOverviewEngagementType } from '../../../../shared';
import { InstagramInteractionGraphKeys } from '../types';

interface Props {
  data: InstagramInteractionOverview;
  selectedMetrics: InstagramInteractionGraphKeys[];
}

interface ChartValuesType {
  categories: string[];
  followersCount: number[];
  taggedPostsCount: number[];
  taggedLikesCount: number[];
  taggedCommentsCount: number[];
  mentionedPostsCount: number[];
  mentionedLikesCount: number[];
  mentionedCommentsCount: number[];
}
const defaultChartValues: ChartValuesType = {
  categories: [],
  followersCount: [],
  taggedPostsCount: [],
  taggedLikesCount: [],
  taggedCommentsCount: [],
  mentionedPostsCount: [],
  mentionedLikesCount: [],
  mentionedCommentsCount: []
};

const getEngagementMetrics = ({ total, growth, percentage }: InstagramInteractionOverviewSummaryData) => ({
  total: total || 0,
  growth: growth || 0,
  percentage: percentage || 0
});

export const useAnalyticsInstagramInteractionOverviewData = ({
  selectedMetrics,
  data: {
    summaries,
    graph: { followers, taggedPosts, taggedLikes, taggedComments, mentionedPosts, mentionedLikes, mentionedComments }
  }
}: Props) => {
  const { t } = useTranslation();

  const engagement: AnalyticsOverviewEngagementType[] = [
    { icon: pictureImage, title: t<string>('Tagged Posts'), ...getEngagementMetrics(summaries.taggedPosts) },
    { icon: heartImage, title: t<string>('Tagged Likes'), ...getEngagementMetrics(summaries.taggedLikes) },
    { icon: chatGreenImage, title: t<string>('Tagged Comments'), ...getEngagementMetrics(summaries.taggedComments) },
    { icon: pictureAtSignImage, title: t<string>('Mentions Posts'), ...getEngagementMetrics(summaries.mentionPosts) },
    { icon: heartAtSignImage, title: t<string>('Mention Likes'), ...getEngagementMetrics(summaries.mentionLikes) },
    { icon: chatAtSignImage, title: t<string>('Mention Comments'), ...getEngagementMetrics(summaries.mentionComments) }
  ];
  const {
    categories,
    followersCount,
    taggedPostsCount,
    taggedLikesCount,
    taggedCommentsCount,
    mentionedPostsCount,
    mentionedLikesCount,
    mentionedCommentsCount
  } = followers.reduce<ChartValuesType>((acc, curr) => {
    const date = curr.date || '';

    return {
      categories: [...acc.categories, date],
      followersCount: [...acc.followersCount, Number(curr.count)],
      taggedPostsCount: [...acc.taggedPostsCount, taggedPosts.find((p) => p.date === date)?.count || 0],
      taggedLikesCount: [...acc.taggedLikesCount, taggedLikes.find((p) => p.date === date)?.count || 0],
      taggedCommentsCount: [...acc.taggedCommentsCount, taggedComments.find((p) => p.date === date)?.count || 0],
      mentionedPostsCount: [...acc.mentionedPostsCount, mentionedPosts.find((p) => p.date === date)?.count || 0],
      mentionedLikesCount: [...acc.mentionedLikesCount, mentionedLikes.find((p) => p.date === date)?.count || 0],
      mentionedCommentsCount: [
        ...acc.mentionedCommentsCount,
        mentionedComments.find((p) => p.date === date)?.count || 0
      ]
    };
  }, defaultChartValues);
  const chartData = {
    followers: {
      zIndex: 1,
      type: 'line',
      color: '#ff2b52',
      showIncrement: true,
      data: followersCount,
      name: t<string>('Follower'),
      visible: selectedMetrics.includes('followers'),
      marker: {
        lineWidth: 2,
        symbol: 'circle',
        fillColor: '#fff',
        lineColor: undefined // inherit from chart
      }
    },
    taggedPosts: {
      yAxis: 1,
      type: 'column',
      color: '#ffd7c1',
      data: taggedPostsCount,
      name: t<string>('Tagged Posts'),
      visible: selectedMetrics.includes('taggedPosts')
    },
    taggedLikes: {
      yAxis: 2,
      type: 'column',
      color: '#ff96ae',
      data: taggedLikesCount,
      name: t<string>('Tagged Likes'),
      visible: selectedMetrics.includes('taggedLikes')
    },
    taggedComments: {
      yAxis: 3,
      type: 'column',
      color: '#e9b8d7',
      data: taggedCommentsCount,
      name: t<string>('Tagged Comments'),
      visible: selectedMetrics.includes('taggedComments')
    },
    mentionedPosts: {
      yAxis: 4,
      type: 'column',
      color: '#aec0e4',
      data: mentionedPostsCount,
      name: t<string>('Mentioned Posts'),
      visible: selectedMetrics.includes('mentionedPosts')
    },
    mentionedLikes: {
      yAxis: 5,
      type: 'column',
      color: '#a0c8df',
      data: mentionedLikesCount,
      name: t<string>('Mentioned Likes'),
      visible: selectedMetrics.includes('mentionedLikes')
    },
    mentionedComments: {
      yAxis: 6,
      type: 'column',
      color: '#9dd9f4',
      data: mentionedCommentsCount,
      name: t<string>('Mentioned Comments'),
      visible: selectedMetrics.includes('mentionedComments')
    }
  } as const satisfies AnalyticsOverviewChartProps<InstagramInteractionGraphKeys>['data'];

  return { chartData, engagement, categories };
};
