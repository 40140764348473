import { FormProvider } from '@/shared/atoms';
import { useUserRoleOptions } from './hooks';
import { UserFormSchema } from './schema';
import { Template } from './Template';
import { UserFormProps, UserFormValues } from './types';
import { defaultUserFormValues } from './utils';

export const UserForm = ({
  defaultValues = defaultUserFormValues,
  onSubmit,
  isUserSettingsForAdvertisers
}: UserFormProps) => {
  const userRoleOptions = useUserRoleOptions(defaultValues?.mode);

  return (
    <FormProvider<UserFormValues>
      onSubmit={onSubmit}
      zodSchema={UserFormSchema}
      defaultValues={{
        ...defaultValues,
        userType: (defaultValues?.userType || userRoleOptions.at(0)?.value || '') as UserFormValues['userType']
      }}
    >
      <Template userRoleOptions={userRoleOptions} isUserSettingsForAdvertisers={isUserSettingsForAdvertisers} />
    </FormProvider>
  );
};

export * from './types';
export * from './utils';
