import { CampaignIdentifierType, CampaignPost } from '@/graphql';
import { EngagementInfluencerPostDetails, MarketplaceInfluencerPostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface PostDiscoveryDetailsModalProps {
  posts: readonly CampaignPost[];
}
export const PostDiscoveryDetailsModal = ({ posts = [] }: PostDiscoveryDetailsModalProps) => (
  <CarouselTemplateModal posts={posts}>
    {({ selectedPost, closeModal }) =>
      selectedPost.campaignIdentifier.campaignType === CampaignIdentifierType.ENGAGEMENT ? (
        <EngagementInfluencerPostDetails id={selectedPost.id} onCloseModal={closeModal} />
      ) : (
        <MarketplaceInfluencerPostDetails id={selectedPost.id} onCloseModal={closeModal} skip={{ postHistory: true }} />
      )
    }
  </CarouselTemplateModal>
);
