import {
  targetImage,
  pictureImage,
  userPinkImage,
  likePinkImage,
  eyePurpleImage,
  chatGreenImage,
  networkBlueImage,
  websiteLikeBlueImage,
  websiteViewBlueImage,
  bulbLightYellowImage,
  chartLightYellowImage
} from '@/shared/assets';
import {
  AnalyticsFacebookDashboardGraphKeys,
  AnalyticsFacebookDashboardLegendType,
  AnalyticsFacebookDashboarOverviewEngagementType
} from './types';

export const getDropdownLegendKeys = (isInfluencerProfile?: boolean): AnalyticsFacebookDashboardGraphKeys[] =>
  isInfluencerProfile
    ? ['impression', 'pageCta', 'pageImpression', 'pageLikes']
    : ['impression', 'engagement', 'engagementRate', 'pageCta', 'pageImpression', 'pageLikes'];

export const legendColors: Record<AnalyticsFacebookDashboardGraphKeys, string> = {
  post: '#ffd7c1',
  share: '#a0c8df',
  comment: '#aec0e4',
  pageCta: '#a5a8e0',
  follower: '#ff2b52',
  reaction: '#e9b8d7',
  pageLikes: '#ffaae1',
  impression: '#9dd9f4',
  engagement: '#ffb6b7',
  pageImpression: '#c484eb',
  engagementRate: '#a0dfd5'
};

export const getAllLegendOptions = (isInfluencerProfile?: boolean): AnalyticsFacebookDashboardLegendType[] => [
  { label: 'Follower', value: 'follower', color: legendColors.follower },
  { label: 'Post', value: 'post', color: legendColors.post },
  { label: 'PageCta', value: 'pageCta', color: legendColors.pageCta },
  { label: 'Page Impression', value: 'pageImpression', color: legendColors.pageImpression },
  { label: 'Page Likes', value: 'pageLikes', color: legendColors.pageLikes },
  { label: 'Reach', value: 'impression', color: legendColors.impression },
  ...(!isInfluencerProfile
    ? ([
        { label: 'Engagement', value: 'engagement', color: legendColors.engagement },
        { label: 'Engagement Rate', value: 'engagementRate', color: legendColors.engagementRate }
      ] as AnalyticsFacebookDashboardLegendType[])
    : []),
  { label: 'Reaction', value: 'reaction', color: legendColors.reaction },
  { label: 'Comment', value: 'comment', color: legendColors.comment },
  { label: 'Share', value: 'share', color: legendColors.share }
];

export const allOverviewEngagements: Pick<
  AnalyticsFacebookDashboarOverviewEngagementType,
  'key' | 'title' | 'icon' | 'css' | 'isPercentage'
>[] = [
  { key: 'follower', title: 'Follower (Total)', icon: userPinkImage },
  { key: 'post', title: 'Post', icon: pictureImage },
  { key: 'reaction', title: 'Reaction', icon: likePinkImage },
  { key: 'comment', title: 'Comment', icon: chatGreenImage },
  { key: 'share', title: 'Share', icon: networkBlueImage },
  { key: 'impression', title: 'Reach', icon: eyePurpleImage },
  { key: 'engagement', title: 'Engagement', icon: bulbLightYellowImage },
  { key: 'engagementRate', title: 'Engagement Rate', icon: chartLightYellowImage, isPercentage: true },
  { key: 'pageCta', title: 'PageCta', icon: targetImage },
  { key: 'pageImpression', title: 'Page Impression', icon: websiteViewBlueImage },
  { key: 'pageLikes', title: 'Page Likes', icon: websiteLikeBlueImage }
];
