import { useInfluencerInstagramDashboardFollowerAnalyticsQuery } from '@/graphql';
import { AnalyticsInstagramDashboardAudienceTemplate } from '@/shared/organisms';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerAnalyticsInstagramProfileAudienceProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerAnalyticsInstagramProfileAudience = ({
  socialAccount
}: InfluencerAnalyticsInstagramProfileAudienceProps) => {
  const { data, loading } = useInfluencerInstagramDashboardFollowerAnalyticsQuery({
    fetchPolicy: 'no-cache',
    variables: { socialAccountId: socialAccount.id }
  });

  return (
    <AnalyticsInstagramDashboardAudienceTemplate
      loading={loading}
      data={data?.influencerInstagramDashboardFollowerAnalytics}
    />
  );
};
