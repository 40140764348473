import { Navigate, useParams } from '@tanstack/react-router';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useCustomHeader, useQueryHelper } from '@/shared/hooks';
import { useAccountAnalyticsBasicDetailsV2Query } from '@/graphql';
import { AnalyticsSupportedSocialMediaType } from '@/shared/types';
import { Template } from './Template';

export const AnalyticsProfile = () => {
  useCustomHeader();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId' });

  const { data, loading } = useAccountAnalyticsBasicDetailsV2Query({
    skip: !params.brandAccountId,
    variables: { brandAccountId: Number(params.brandAccountId) },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/' });
    }
  });

  if (!params.brandAccountId || !params.socialMediaAccountId || !params.socialMedia) {
    return <Navigate to="/analytics" />;
  }

  const brandAccount = data?.accountAnalyticsBasicDetailsV2;

  return (
    <RenderDataWithFallback loading={loading}>
      {/* We only continue when brandAccount valid */}
      {brandAccount ? (
        <Template
          brandAccount={brandAccount}
          socialMedia={params.socialMedia as AnalyticsSupportedSocialMediaType}
          socialMediaAccountId={Number(params.socialMediaAccountId)}
        />
      ) : null}
    </RenderDataWithFallback>
  );
};
