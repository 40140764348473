import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateTiktokSpecialPostStatus.graphql';
import { Mutation, MutationupdateTiktokSpecialPostStatusArgs } from '../../__generated__/globalTypes';

export const useUpdateTiktokSpecialPostStatusMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateTiktokSpecialPostStatusArgs>
) => {
  const [callUpdateTiktokSpecialPostStatus, result] = useMutation<Mutation, MutationupdateTiktokSpecialPostStatusArgs>(
    MUTATION,
    options
  );

  return { callUpdateTiktokSpecialPostStatus, ...result };
};

export type UpdateTiktokSpecialPostStatusMutationFunction = Mutation['updateTiktokSpecialPostStatus'];
