import { useTranslation } from 'react-i18next';
import { RegionField as RHFRegionField, RegionFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const RegionField = (props: Omit<RegionFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return (
    <RHFRegionField<InfluencerFormKeys>
      help={t('Tooltip.Region')}
      title={t('Selector.Region')}
      multiple={false}
      name={InfluencerSharedKeys.regionId}
      {...props}
    />
  );
};
