import { useTranslation } from 'react-i18next';
import { SortOption } from '@/shared/types';
import { InfluencerSearchSortField, Order } from '@/graphql';
import { InfluencersSortTemplate } from '../../shared';
import { useInfluencerPackagesContext } from '../InfluencerPackagesContext';

export const InfluencerPackagesSort = () => {
  const { t } = useTranslation();
  const { sort, limit, setSort, setLimit, sortSelectButtonProps } = useInfluencerPackagesContext();

  const sortOptions: SortOption<InfluencerSearchSortField>[] = [
    {
      order: Order.ASC,
      label: t('Total Followers (ASC)'),
      field: InfluencerSearchSortField.FOLLOWERS
    },
    {
      order: Order.DESC,
      label: t('Total Followers (DESC)'),
      field: InfluencerSearchSortField.FOLLOWERS
    },
    {
      order: Order.ASC,
      label: t('Engagement (ASC)'),
      field: InfluencerSearchSortField.ENGAGEMENT
    },
    {
      order: Order.DESC,
      label: t('Engagement (DESC)'),
      field: InfluencerSearchSortField.ENGAGEMENT
    },
    {
      order: Order.ASC,
      label: t('ER by views (ASC)'),
      field: InfluencerSearchSortField.ENGAGEMENT_VIEWS_RATE
    },
    {
      order: Order.DESC,
      label: t('ER by views (DESC)'),
      field: InfluencerSearchSortField.ENGAGEMENT_VIEWS_RATE
    },
    {
      order: Order.ASC,
      label: t('ER by followers (ASC)'),
      field: InfluencerSearchSortField.ENGAGEMENT_FOLLOWERS_RATE
    },
    {
      order: Order.DESC,
      label: t('ER by followers (DESC)'),
      field: InfluencerSearchSortField.ENGAGEMENT_FOLLOWERS_RATE
    },
    {
      order: Order.ASC,
      label: t('Campaign Participated (ASC)'),
      field: InfluencerSearchSortField.CAMPAIGNS_JOINED
    },
    {
      order: Order.DESC,
      label: t('Campaign Participated (DESC)'),
      field: InfluencerSearchSortField.CAMPAIGNS_JOINED
    }
  ].map((option) => ({ ...option, value: `${option.field}-${option.order}` }));

  return (
    <InfluencersSortTemplate
      setSort={setSort}
      setLimit={setLimit}
      sortOptions={sortOptions}
      selectButtonProps={sortSelectButtonProps}
      initialValues={{ sort, limit: String(limit) }}
    />
  );
};
