import { Table } from '@/shared/atoms';
import { TableData } from '@/shared/types';
import { TikTokSpecialCampaign } from '@/graphql';
import { useTikTokCampaignsTable } from './hooks';

export type TikTokCampaignsListProps = TableData<TikTokSpecialCampaign>;

export const TikTokCampaignsList = ({ loading, data = [] }: TikTokCampaignsListProps) => {
  const { rows, columns } = useTikTokCampaignsTable({ data });

  return <Table loading={loading} data={{ columns, rows }} noTopBorderRadius hasBorder />;
};
