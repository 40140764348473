import { Table, TableProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export type AnalyticsComparePostsTableProps = TableProps;
export const AnalyticsComparePostsTable = ({
  hasBorder = false,
  noTopBorderRadius = true,
  ...restProps
}: AnalyticsComparePostsTableProps) => (
  <Table
    hasBorder={false}
    noTopBorderRadius
    css={{ borderBottom: THEME.border.base, '.table-wrapper': { overflow: 'hidden' } }}
    {...restProps}
  />
);
