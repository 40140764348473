import { css, keyframes } from '@emotion/react';
import { Skeleton as MantineSkeleton, SkeletonProps as MantineSkeletonProps } from '@mantine/core';
import { THEME } from '@/shared/constants';

export interface SkeletonProps extends MantineSkeletonProps {
  count?: number;
}

export const Skeleton = ({ count = 1, height = 16, className, ...restProps }: SkeletonProps) => (
  <div css={styles.wrapper} className={className}>
    {[...Array(count)].map((_, index) => (
      <MantineSkeleton css={[styles.skeleton(index), { height }]} key={`skeleton-line-${index}`} {...restProps} />
    ))}
  </div>
);

const styles = {
  wrapper: css({
    gap: '12px',
    display: 'flex',
    flexDirection: 'column'
  }),
  skeleton: (index: number) =>
    css({
      '--baseColor': THEME.skeleton.baseColor,
      '--highlightColor': THEME.skeleton.highlightColor,

      borderRadius: '8px',
      '&::before': { background: 'var(--baseColor)' },
      '&::after': {
        top: 0,
        left: 0,
        right: 0,
        height: '100%',
        content: "''",
        display: 'block',
        position: 'absolute',
        borderRadius: 'inherit',
        backgroundRepeat: 'no-repeat',
        transform: 'translateX(-100%)',
        animation: `${keyframes`100% { transform: translateX(100%); }`} 1.5s ease-in-out ${index * 0.25}s infinite`,
        backgroundImage: 'linear-gradient(90deg, var(--baseColor), var(--highlightColor) 80%, var(--baseColor))'
      }
    })
};
