import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@/shared/atoms';
import { useAllPackagesQuery } from '@/graphql';
import { Header, ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useInfluencerPackagePermissions, useUserRoles } from '@/auth';
import { useUpdateDocumentTitle, useCustomHeader } from '@/shared/hooks';
import { usePackagesFilter, PackagesFilter, PackagesList } from '@/shared/organisms';

export const Packages = () => {
  useCustomHeader();
  const { t } = useTranslation();
  const { isAgency, isAdvertiser } = useUserRoles();
  const { hideAddPackageBtn } = useInfluencerPackagePermissions();
  useUpdateDocumentTitle({ title: 'documentTitle.Packages Search', appHeader: 'appHeader.Packages Search', href: '' });
  const { page, limit, filter } = usePackagesFilter();
  const { data, previousData, loading } = useAllPackagesQuery({
    fetchPolicy: 'no-cache',
    skip: !filter,
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      keyword: filter?.keyword,
      countryIds: filter?.countryIds
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allPackagesCount?.totalNumber;
  const hasCreatePackageBtn = !(isAgency || isAdvertiser) && !hideAddPackageBtn;

  return (
    <>
      <Header>
        {hasCreatePackageBtn ? (
          <ButtonLink
            variant="blue"
            to="/package/add"
            title={t('Button.Add Package')}
            prefixIcon={{ icon: 'plus', size: '10px' }}
            css={{ minWidth: '143px', textTransform: 'capitalize', marginLeft: 'auto' }}
          />
        ) : null}
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <PackagesFilter />
        <PackagesList loading={loading} totalRecords={totalRecords} data={result?.allPackages || []} />
      </ListWithPagination>
    </>
  );
};
