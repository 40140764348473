import { instagramDarkGradientImage, instagramImage, instagramStoryImage } from '@/shared/assets';
import { THEME } from '@/shared/constants';
import { SocialIconConfigType } from './types';

const defaultVariantConfigs = { white: { color: THEME.icon.colors.white }, gray: { color: '#97a4af' } };

export const socialIconConfigs: SocialIconConfigType = {
  INSTAGRAM: {
    icon: 'instagram',
    variant: {
      ...defaultVariantConfigs,
      original: { src: instagramImage },
      transform: { src: instagramDarkGradientImage }
    }
  },
  INSTAGRAM_STORY: {
    icon: 'instagram-story',
    variant: { ...defaultVariantConfigs, original: { src: instagramStoryImage } }
  },
  YOUTUBE: {
    icon: 'youtube',
    variant: { ...defaultVariantConfigs, original: { color: '#ce1312' } }
  },
  FACEBOOK: {
    icon: 'facebook',
    variant: {
      ...defaultVariantConfigs,
      original: { color: '#1877f2' },
      transform: { icon: 'facebook-transform', color: '#1877f2' }
    }
  },
  TWITTER: {
    icon: 'x-logo',
    variant: { ...defaultVariantConfigs, original: { color: '#000' } }
  },
  TIKTOK: {
    icon: 'tiktok',
    variant: { ...defaultVariantConfigs, original: { color: '#000' }, transform: { icon: 'tiktok-transform' } }
  },
  // We still have state 'white' and 'gray' for threads, currently we don't have UI case or design yet. Will update later
  THREADS: {
    icon: 'threads',
    variant: { ...defaultVariantConfigs, original: { color: '#000' }, transform: { icon: 'threads-transform' } } // threads-transform not used yet, we can replace later
  },
  DOUYIN: {
    icon: 'douyin',
    variant: { ...defaultVariantConfigs }
  },
  XHS: {
    icon: 'xiaohongshu',
    variant: { ...defaultVariantConfigs, original: { color: '#EB4553' } }
  }
};
