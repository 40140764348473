import { css } from '@emotion/react';
import { ReactElement } from 'react';
import { Outlet } from '@tanstack/react-router';
import { THEME } from '@/shared/constants';
import { MenuTitle } from '@/router';
import { Footer, Header } from '@/shared/molecules';
import { useChatDemo, useGlobalLayoutProps } from '@/shared/hooks';
import { Menu } from './Menu';

export interface LayoutProps {
  title?: MenuTitle;
  className?: string;
  element?: ReactElement;
  internal?: boolean;
  hasCombinedTerms?: boolean;
}

export const Layout = ({ title: menuTitle, element, className, internal, hasCombinedTerms }: LayoutProps) => {
  const {
    globalLayout: { hasCustomHeader, hasFailedSubscriptionPayment }
  } = useGlobalLayoutProps();
  const { isChatDemoPage } = useChatDemo();

  return (
    <div
      css={{
        display: 'flex',
        minHeight: '100%',
        backgroundColor: hasFailedSubscriptionPayment ? '#43484e' : THEME.background.colors.gray.lv1
      }}
      className={className}
    >
      <Menu activeTitle={menuTitle} internal={internal} />

      <div css={[styles.content, isChatDemoPage && { padding: 0 }]} className="layout-wrapper">
        {!hasCustomHeader && !isChatDemoPage && !hasFailedSubscriptionPayment ? <Header /> : null}
        {element || <Outlet />}
        {!isChatDemoPage && !hasFailedSubscriptionPayment ? <Footer hasCombinedTerms={hasCombinedTerms} /> : null}
      </div>
    </div>
  );
};

const styles = {
  content: css({
    flex: 1,
    display: 'flex',
    padding: '16px 8px 24px 16px',
    boxSizing: 'border-box',
    flexDirection: 'column',
    scrollBehavior: 'smooth',
    marginLeft: THEME.container.menu.width,
    width: `calc(100% - ${THEME.container.menu.width})`
  })
};
