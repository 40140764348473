import { zodValidator } from '@tanstack/zod-adapter';
import {
  TikTokSignupLayout,
  TikTokRegistration,
  TikTokSignupSuccess,
  TikTokBusinessAccountCenter,
  TikTokSubscription,
  TikTokPasswordEnter,
  TikTokPasswordRegister
} from '@/pages/TikTokRegistration';
import { LogoSchema } from '@/shared/molecules';
import { WithTikTokOnboardingToken } from '@/app-components';
import { PartialRouter } from '../../types';

export const TikTokOnboardingRouter = {
  '/_public-routes/tiktok-onboarding': [TikTokSignupLayout, { validateSearch: zodValidator(LogoSchema) }],
  '/_public-routes/tiktok-onboarding/': [TikTokRegistration, {}],
  '/_public-routes/tiktok-onboarding/success': [TikTokSignupSuccess, {}],
  '/_public-routes/tiktok-onboarding/ad-account': [
    () => (
      <WithTikTokOnboardingToken>
        <TikTokBusinessAccountCenter />
      </WithTikTokOnboardingToken>
    ),
    {}
  ],
  '/_public-routes/tiktok-onboarding/select-plan': [
    () => (
      <WithTikTokOnboardingToken>
        <TikTokSubscription />
      </WithTikTokOnboardingToken>
    ),
    {}
  ],
  '/_public-routes/tiktok-onboarding/password-enter': [TikTokPasswordEnter, {}],
  '/_public-routes/tiktok-onboarding/password-register/$token': [TikTokPasswordRegister, {}]
} as const satisfies PartialRouter;
