import { z } from 'zod';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { SearchFilterDefaults } from '@/shared/validations';
import { InstagramFeedPostSortField } from '@/graphql';

export const AnalyticsIgTaggedPostsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(ProfilePostsFilterSchema, { startDate: getThirtyDaysAgo(), endDate: getToday() }),
  sort: SearchFilterDefaults.sort(InstagramFeedPostSortField).default({}),
  ...SearchFilterDefaults.pageLimit()
});

export type AnalyticsIgTaggedPostsSearchSchemaType = z.infer<typeof AnalyticsIgTaggedPostsSearchSchema>;
