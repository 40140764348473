import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormStyle, Portal } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useOnClickOutside } from '@/shared/hooks';

export interface AdvancedFilterWrapperProps {
  gapRow?: number;
  title?: ReactNode;
  className?: string;
  gapColumn?: number;
  children?: ReactNode;
  onSave: () => void;
  onClear?: () => void;
}

export const AdvancedFilterWrapper = ({
  title,
  onSave,
  onClear,
  children,
  className,
  gapRow = 16,
  gapColumn = 16
}: AdvancedFilterWrapperProps) => {
  const { t } = useTranslation();
  const { ref } = useOnClickOutside<HTMLDivElement>(onSave);

  const headerTitle = title ?? t('More Filter');

  return (
    <Portal portalId="portal-root">
      <div css={styles.container}>
        <div css={styles.wrapper} ref={ref} className={className} id="advancedFilterContent">
          {headerTitle ? (
            <div css={styles.header}>
              <div css={styles.title}>{headerTitle}</div>
              {onClear ? (
                <Button variant="white" onClick={onClear} title={t('Button.Clear All')} css={{ padding: '0 24px' }} />
              ) : null}
              <Button
                variant="blue"
                onClick={onSave}
                title={t('Button.Save')}
                css={{ marginLeft: '8px', padding: '0 24px' }}
              />
            </div>
          ) : null}

          <FormStyle.Wrapper gapRow={gapRow} gapColumn={gapColumn} css={{ padding: '16px 12px' }}>
            {children}
          </FormStyle.Wrapper>
        </div>
      </div>
    </Portal>
  );
};
const styles = {
  container: css({
    top: 0,
    left: 0,
    width: '100%',
    zIndex: '100',
    height: '100vh',
    padding: '24px',
    overflow: 'auto',
    position: 'fixed',
    boxSizing: 'border-box',
    background: 'rgba(0, 0, 0, 0.4)'
  }),
  wrapper: css({
    margin: '0 auto',
    boxSizing: 'border-box',
    border: '2px solid #f6f8fa',
    borderRadius: '0 0 3px 3px',
    boxShadow: '0 5px 12px rgba(39, 49, 59, 0.2)',
    maxWidth: THEME.container.details.width,
    backgroundColor: THEME.background.colors.white
  }),
  header: css({
    height: '48px',
    display: 'flex',
    padding: '0 16px',
    borderRadius: '3px',
    alignItems: 'center',
    backgroundColor: THEME.background.colors.gray.lv1,
    justifyContent: 'space-between'
  }),
  title: css({
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    fontStyle: 'normal',
    marginRight: 'auto',
    whiteSpace: 'nowrap',
    color: THEME.text.colors.black.lv1
  })
};
