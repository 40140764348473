import { Interpolation, Theme, css } from '@emotion/react';
import { Accordion } from '@mantine/core';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormStyle, ModalFooterActions, Popover } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';

export interface MoreFilterButtonProps {
  count: number;
  onClear: () => void;
  onSubmit?: () => void;
  items: {
    node: ReactNode;
    value: FILTER_SECTIONS;
    title?: string;
    css?: Interpolation<Theme>;
  }[];
  defaultOpened?: FILTER_SECTIONS[];
  className?: string;
}
export enum FILTER_SECTIONS {
  Posts = 'Posts',
  Audiences = 'Audiences',
  AutoManaged = 'Auto Managed',
  PerformanceFilter = 'Performance Filter',
  CampaignInformation = 'Campaign Information',
  InfluencerAttributes = 'Influencer Attributes',
  CampaignPostsDiscovery = 'Campaign Posts Discovery'
}

export const MoreFilterPopover = ({
  count,
  defaultOpened,
  items,
  onClear,
  onSubmit,
  className
}: MoreFilterButtonProps) => {
  const { t } = useTranslation();
  const active = !!count;

  const { status, toggleStatus, close } = useToggleState();
  const [accordionValue, setAccordionValue] = useState<FILTER_SECTIONS[]>(defaultOpened || []);

  const handleSave = () => {
    onSubmit?.();
    close();
  };

  return (
    <Popover
      open={status}
      position="bottom-end"
      offset={-3}
      onClose={close}
      closeOnClickOutside
      target={
        <FormStyle.FieldWrapper css={{ maxWidth: '151px', flex: 'none' }} className={className}>
          <Button
            onClick={toggleStatus}
            prefixIcon={{ icon: 'filter', size: '18px' }}
            css={styles.moreFilter(active)}
            title={
              <>
                {t('More Filter')}
                <span css={styles.counter(active)}>{count}</span>
              </>
            }
          />
        </FormStyle.FieldWrapper>
      }
    >
      <div>
        <Accordion
          multiple
          disableChevronRotation
          value={accordionValue}
          onChange={(v: FILTER_SECTIONS[]) => setAccordionValue(v)}
          css={{
            width: '320px',
            '.mantine-Accordion-content': {
              padding: 0,
              background: THEME.background.colors.gray.lv1,
              borderTop: THEME.border.base
            }
          }}
        >
          {items.map(({ value, node, title }) => (
            <Accordion.Item key={value} value={value}>
              <Accordion.Control chevron={<Chevron isOpened={accordionValue.includes(value)} />}>
                {t(title || 'More Filter')}
              </Accordion.Control>
              <Accordion.Panel>
                {
                  <div
                    css={{
                      '> div': {
                        borderBottom: `1px solid ${THEME.border.colors.gray.lv1}`,
                        padding: '12px 16px',
                        '&:last-of-type': { borderBottom: 'none' }
                      }
                    }}
                  >
                    {node}
                  </div>
                }
              </Accordion.Panel>
            </Accordion.Item>
          ))}

          <ModalFooterActions
            cancelButtonProps={{
              title: t('Button.Clear All'),
              onClick: onClear
            }}
            submitButtonProps={{
              title: t('Button.Save'),
              onClick: handleSave
            }}
            css={{ padding: '8px', background: THEME.background.colors.white }}
          />
        </Accordion>
      </div>
    </Popover>
  );
};

const styles = {
  moreFilter: (active: boolean) =>
    css({
      borderWidth: '1px',
      borderStyle: 'solid',
      backgroundColor: 'transparent',
      color: THEME.text.colors.gray.lv3,
      borderColor: THEME.border.colors.gray.lv2,
      ...(active
        ? {
            borderColor: 'transparent',
            color: THEME.button.group.active.color,
            backgroundColor: THEME.button.group.active.backgroundColor
          }
        : {})
    }),
  counter: (active: boolean) =>
    css({
      width: '24px',
      height: '24px',
      display: 'flex',
      overflow: 'hidden',
      marginLeft: '8px',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      visibility: active ? 'visible' : 'hidden',
      backgroundColor: THEME.background.colors.white
    })
};

const Chevron = ({ isOpened }: { isOpened: boolean }) => (
  <span css={{ fontSize: '16px', height: '20px' }}>{isOpened ? '-' : '+'}</span>
);
