import { FacebookPageAnalyticsPostsByDatePayload } from '@/graphql';
import { FacebookPostInDateDetails, FacebookPostInDateDetailsProps } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface FacebookPostInDateDetailsModalProps {
  paramKey?: string;
  data: FacebookPageAnalyticsPostsByDatePayload | null;
}

export const FacebookPostInDateDetailsModal = ({ data, paramKey }: FacebookPostInDateDetailsModalProps) => {
  const posts =
    data?.posts.map<NonNullable<FacebookPostInDateDetailsProps['data']>>((post) => ({
      ...post,
      avatar: data.avatar,
      pageName: data.pageName,
      followers: data.followers
    })) || [];

  return (
    <CarouselTemplateModal posts={posts} paramKey={paramKey}>
      {({ selectedPost }) => <FacebookPostInDateDetails data={selectedPost} />}
    </CarouselTemplateModal>
  );
};
