import { FilterProvider } from '@/shared/molecules';
import { useMarketplaceInfluencersFilter } from './hooks';
import { BasicFilter, BasicFilterProps } from './BasicFilter';

export type MarketplaceInfluencersFilterProps = Omit<BasicFilterProps, 'onSubmit'>;

export const MarketplaceInfluencersFilter = (props: MarketplaceInfluencersFilterProps) => {
  const { filter, setFilterForm } = useMarketplaceInfluencersFilter();

  return <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<BasicFilter {...props} />} />;
};

export { useMarketplaceInfluencersFilter };
export * from './schemaTypes';
export * from './hooks';
