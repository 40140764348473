import { css } from '@emotion/react';

import { THEME } from '@/shared/constants';

import { CircularProgress, CircularProgressProps } from './CircularProgress';

export type LoadingProps = CircularProgressProps;

export const Loading = ({ className, ...restProps }: LoadingProps) => (
  <div css={styles.wrapper} className={className}>
    <CircularProgress size={64} thickness={1.3} backgroundColor={THEME.loading.color} {...restProps} />
  </div>
);

const styles = {
  wrapper: css({
    zIndex: 5,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    borderRadius: 'inherit',
    justifyContent: 'center',
    backgroundColor: 'inherit'
  })
};
