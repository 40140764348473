import { LIMIT_30 } from '@/shared/constants';
import { convertOrderToORDER } from '@/shared/utils';
import { InfluencerInstagramAnalyticsStoryDetailsModal } from '@/shared/molecules';
import { InfluencerSelectedAccountInfo, AnalyticsInstagramDashboardStoryPostsTemplate } from '@/shared/organisms';
import {
  Order,
  InstagramStoryPostSortField,
  InfluencerNewProfileHeaderV2,
  useInfluencerAllInstagramStoryPostsQuery
} from '@/graphql';
import { PostDetailsType } from '@/shared/types';

interface InfluencerAnalyticsInstagramProfileStoryPostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerId: InfluencerNewProfileHeaderV2['id'];
}

export const InfluencerAnalyticsInstagramProfileStoryPosts = ({
  socialAccount: { id: socialAccountId, name }
}: InfluencerAnalyticsInstagramProfileStoryPostsProps) => {
  const { data } = useInfluencerAllInstagramStoryPostsQuery({
    variables: {
      offset: 0,
      limit: LIMIT_30,
      socialAccountId,
      orderBy: { field: InstagramStoryPostSortField.REACH, order: convertOrderToORDER(Order.DESC) }
    }
  });

  const posts = data?.influencerAllInstagramStoryPosts || [];

  return (
    <>
      <AnalyticsInstagramDashboardStoryPostsTemplate
        data={posts}
        moreDetailProps={{
          to: '/influencer/$id/$socialMediaAccountId/instagram_story_post',
          params: { socialMediaAccountId: socialAccountId.toString() },
          search: { filter: { name } }
        }}
      />

      <InfluencerInstagramAnalyticsStoryDetailsModal
        posts={posts.map(({ id }): PostDetailsType => ({ id, mainSocialAccountId: socialAccountId }))}
      />
    </>
  );
};
