import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useParamsSearch, useQueryHelper } from '@/shared/hooks';
import { Pagination, RenderDataWithFallback } from '@/shared/atoms';
import { getOffset, getValuableVariables } from '@/shared/utils';
import {
  Order,
  UGCPostType,
  InstagramUGCPostsSortOrder,
  useInstagramUGCManagementBasicInfoQuery,
  useInstagramUGCManagementListPostsQuery
} from '@/graphql';
import {
  AnalyticsInstagramUGCPostsList,
  AnalyticsInstagramUGCPostsFilter,
  AnalyticsInstagramUGCPostsSearchSchemaType
} from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';

interface AnalyticsUGCInstagramProps {
  navigateBackToDashboard: () => void;
}

export const AnalyticsUGCInstagram = ({ navigateBackToDashboard }: AnalyticsUGCInstagramProps) => {
  const { selectedSocialAccount } = useAnalyticsProfileContext();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { page, limit, filter, setFilter } = useParamsSearch<AnalyticsInstagramUGCPostsSearchSchemaType>();
  const { data, loading } = useInstagramUGCManagementBasicInfoQuery({
    variables: { instagramAnalyticsAccountId: selectedSocialAccount.id },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigateBackToDashboard();
    }
  });

  const { keyword, postType, endDate, startDate, status, hashtagId, field, order } = filter;
  const {
    refetch,
    data: postsData,
    loading: fetchingPosts
  } = useInstagramUGCManagementListPostsQuery({
    fetchPolicy: 'cache-first',
    skip: !selectedSocialAccount.id || !field || !order,
    variables: getValuableVariables({
      limit,
      keyword,
      endDate,
      startDate,
      offset: getOffset(page, limit),
      postType: postType || undefined,
      statuses: status ? [status] : [],
      instagramAnalyticsAccountId: selectedSocialAccount.id,
      orderBy: { field: field as InstagramUGCPostsSortOrder, order: order as Order }, // TODO: can BE make orderBy optional?
      hashtagSystemId:
        hashtagId && ![UGCPostType.MENTIONED, UGCPostType.TAGGED].includes(postType as UGCPostType)
          ? Number(hashtagId)
          : undefined
    }),
    onError: (err) => {
      enqueueSnackbar(t(err.message || 'Cannot get post list data'), { variant: 'error' });
    }
  });

  const basicInfo = data?.instagramUGCManagementBasicInfo;

  return (
    <RenderDataWithFallback loading={loading}>
      {basicInfo ? (
        <>
          <div css={styles.wrapper}>
            <AnalyticsInstagramUGCPostsFilter
              filter={filter}
              setFilter={setFilter}
              basicInfo={basicInfo}
              analyticsAccount={selectedSocialAccount}
            />
            <AnalyticsInstagramUGCPostsList
              onRefetchList={refetch}
              loading={fetchingPosts}
              analyticsAccount={selectedSocialAccount}
              data={postsData?.instagramUGCManagementListPosts.posts}
            />
          </div>

          <Pagination totalRecords={postsData?.instagramUGCManagementListPostsCount.totalNumber} />
        </>
      ) : null}
    </RenderDataWithFallback>
  );
};
const styles = {
  wrapper: css({
    borderRadius: '3px',
    padding: '24px 16px 16px',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25)'
  })
};
