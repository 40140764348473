import { css } from '@emotion/react';
import { Header } from '@/shared/molecules';
import { useInstagramUserHashtagsQuery } from '@/graphql';
import { useHashtagsAnalyticsFilter } from '@/shared/organisms';
import { CrowdinButton, RenderDataWithFallback } from '@/shared/atoms';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { Template } from './Template';

export const HashtagsAnalytics = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    href: '/report',
    alwaysBackToParent: true,
    title: 'appHeader.Hashtag Analytics',
    appHeader: 'appHeader.Hashtag Analytics'
  });
  const { filter, setFilter } = useHashtagsAnalyticsFilter();
  const { data, loading, called } = useInstagramUserHashtagsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ instagramUserHashtags }) => {
      const isValidHashtag =
        instagramUserHashtags?.hashtags?.findIndex((hashtag) => hashtag.hashtagId === filter.hashtagId) !== -1;

      if (!filter.hashtagId || !isValidHashtag) {
        setFilter({ hashtagId: instagramUserHashtags?.hashtags?.at(0)?.hashtagId || '' }, { replace: true });
      }
    }
  });

  return (
    <div css={{ padding: '24px 40px' }}>
      <div css={styles.wrapper}>
        <Header>
          <CrowdinButton css={{ marginLeft: 'auto' }} />
        </Header>
        <RenderDataWithFallback loading={loading && !called} hasNoData={!filter.date}>
          <Template hashtags={data?.instagramUserHashtags.hashtags || []} />
        </RenderDataWithFallback>
      </div>
    </div>
  );
};
const styles = {
  wrapper: css({
    margin: '0 auto',
    width: '1192px',
    boxSizing: 'border-box',
    borderRadius: '3px 3px 0 0'
  })
};
