import { NOT_ENOUGH_REFERRAL_CODE } from '@/shared/constants';
import { useEngagementDetailsContext } from '../../EngagementDetailsContext';

export const useDefaultInfluencerSearchProps = () => {
  const { engagement, engagementProposal, uploadModal } = useEngagementDetailsContext();

  // A formatted Map from list influencers added in proposal. Log to see structure
  // Format: { [InfluencerId]: { [SocialMedia]: [SocialAccountId] } }
  const selectedInfluencersMapIds = (() =>
    engagementProposal.influencers.reduce((rs, el) => {
      const socialMediaHashTable = el.accountList.reduce(
        (tb, acc) => tb.set(acc.socialMedia, [...(tb.get(acc.socialMedia) || []), acc.id]),
        new Map<string, number[]>()
      );

      return rs.set(el.id, socialMediaHashTable);
    }, new Map<number, Map<string, number[]>>()))();

  const showUploadCouponModal = (error?: string) => {
    if (error === NOT_ENOUGH_REFERRAL_CODE) {
      uploadModal.setOn();
    }
  };

  return {
    showUploadCouponModal,
    isEngagementPage: true,
    selectedInfluencersMapIds,
    engagementStatus: engagement.status,
    isTtcmCampaign: engagement.isTtcmCampaign
    // defaultFilter: { countryIds: engagement?.countries.map(({ id }) => id) || [] }
  };
};
