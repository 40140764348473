import { useTranslation } from 'react-i18next';
import { InstagramInteractionTaggedPost, InstagramFeedPostSortField } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatIntNumber } from '@/shared/utils';

interface Props {
  data: readonly InstagramInteractionTaggedPost[];
}

export const useAnalyticsInstagramTaggedPostsListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const columns: ColumnProps<InstagramFeedPostSortField>[] = [
    { title: t('HeaderColumn.Post'), sticky: { active: true, hasBorderRight: true } },
    {
      title: t('HeaderColumn.Like'),
      styles: { textAlign: 'right' },
      sortField: InstagramFeedPostSortField.LIKE
    },
    {
      styles: { textAlign: 'right' },
      title: t('HeaderColumn.Comment'),
      sortField: InstagramFeedPostSortField.COMMENT
    },
    {
      title: t('HeaderColumn.Post date'),
      sortField: InstagramFeedPostSortField.POST_DATE
    }
  ];
  const rows: RowProps[] = data.map(({ id, postDate, comments, likes, thumbNail, content }) => [
    <TableCell.Post title={content || ''} thumbnail={thumbNail || ''} onClick={() => setPostDetailsParam(id)} />,
    <TableCell.Number css={{ minWidth: '150px' }} value={formatIntNumber(likes)} />,
    <TableCell.Number css={{ minWidth: '150px' }} value={formatIntNumber(comments)} />,
    <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
  ]);

  return { rows, columns };
};
