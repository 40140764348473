import { useTranslation } from 'react-i18next';
import { TextAreaField, TextAreaFieldProps } from '@/shared/molecules';
import { InfluencerFormKeys } from '../InfluencerForm';
import { InfluencerSharedKeys } from './types';

export const IntroduceField = (props: Omit<TextAreaFieldProps, 'name'>) => {
  const { t } = useTranslation();

  return (
    <TextAreaField<InfluencerFormKeys>
      help={t('Tooltip.IntroduceHelp')}
      title={t('TextForm.Introduce')}
      name={InfluencerSharedKeys.introduce}
      placeholder={t<string>('TextForm.Contents Placeholder')}
      {...props}
    />
  );
};
