import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { THEME } from '@/shared/constants';

export interface FilterWrapperProps {
  children?: ReactNode;
  className?: string;
}

export const FilterWrapper = ({ className, children }: FilterWrapperProps) => (
  <div css={styles.wrapper} className={className}>
    {children}
  </div>
);
const styles = {
  wrapper: css({
    position: 'relative',
    borderRadius: '3px 3px 0 0',
    backgroundColor: THEME.background.colors.white
  })
};
