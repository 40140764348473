import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/InfluencerYoutubeAnalyticsPost.graphql';
import { Query, QueryinfluencerYoutubeAnalyticsPostArgs } from '../../__generated__/globalTypes';

export const useInfluencerYoutubeAnalyticsPostQuery = (
  options?: QueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsPostArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryinfluencerYoutubeAnalyticsPostArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useInfluencerYoutubeAnalyticsPostLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryinfluencerYoutubeAnalyticsPostArgs>
): QueryResult<Query, QueryinfluencerYoutubeAnalyticsPostArgs> & {
  getInfluencerYoutubeAnalyticsPost: LazyQueryExecFunction<Query, QueryinfluencerYoutubeAnalyticsPostArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getInfluencerYoutubeAnalyticsPost, result] = useLazyQuery<Query, QueryinfluencerYoutubeAnalyticsPostArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getInfluencerYoutubeAnalyticsPost, ...result };
};

export const useInfluencerYoutubeAnalyticsPostPromiseQuery = () => {
  const client = useApolloClient();

  const getInfluencerYoutubeAnalyticsPost = useCallback(
    (options?: Omit<QueryOptions<QueryinfluencerYoutubeAnalyticsPostArgs, Query>, 'query'>) =>
      client.query<Query, QueryinfluencerYoutubeAnalyticsPostArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getInfluencerYoutubeAnalyticsPost };
};
