import { router } from './provider';

export const enum MenuTitle {
  Report = 'Report',
  Marketplace = 'Marketplace',
  Engagement = 'Engagement',
  AutoManaged = 'Affiliate',
  TikTokCampaigns = 'TikTok Special',
  CampaignPosts = 'Campaign Posts',
  Influencers = 'Influencers',
  Posts = 'Post',
  Packages = 'Package',
  TrendingPosts = 'Trending Posts',
  TrendingInfluencers = 'Trending Influencers',
  Analytics = 'Analytics',
  Partner = 'Partner',
  TalentAgency = 'Talent Agency',
  Agency = 'Agency',
  Advertiser = 'Advertiser',
  User = 'User',
  Payment = 'Payment',
  Settings = 'Settings',
  ReleaseNotes = 'Release Notes',
  HelpCenter = 'Help Center',
  UserSettings = 'User Settings',
  Language = 'languageTitle',
  SignOut = 'Sign Out',
  ChatDemo = 'AI Chat', // TODO: remove later
  // TikTok in-app
  TikTokAdsReport = 'TikTok Ads',
  BudgetAllocation = 'Budget Allocation',
  AdAccounts = 'Ad Accounts',
  Billing = 'Billing',
  Account = 'Account',
  InfluencerCampaigns = 'Influencer Campaign',
  // Dev only
  AssetPreview = 'Asset Preview',
  // Internal
  YoutubeCMS = 'Youtube CMS',
  Invoice = 'Invoice'
}

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
  interface StaticDataRouteOption {
    menuTitle?: MenuTitle;
    hasCombinedTerms?: boolean;
  }
}
