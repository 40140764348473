import { css } from '@emotion/react';
import { useResendEmailForAdvertiserMutation } from '@/graphql';
import { mailOpenedImage } from '@/shared/assets';
import { Anchor, ButtonLink, RenderDataWithFallback } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useToggleState, useUpdateDocumentTitle } from '@/shared/hooks';
import { SignInSignUpLayout } from '@/shared/organisms';
import { manageLocalStorage } from '@/shared/utils';

export const SignUpSuccess = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.SignUp' });
  const { t, enqueueSnackbar } = useQueryHelper();
  const { status: loading, setStatus: setLoading } = useToggleState(false);
  const { callResendEmailForAdvertiser } = useResendEmailForAdvertiserMutation();

  const email = manageLocalStorage('get', 'confirmationEmail') || '';

  const handleResendEmail = async () => {
    setLoading(true);
    try {
      await callResendEmailForAdvertiser({ variables: { input: { email } } });
      enqueueSnackbar('Confirmation email has been resended', { variant: 'success' });
      manageLocalStorage('remove', 'confirmationEmail');
    } catch (error) {
      enqueueSnackbar('Failed to resend email, please try again', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SignInSignUpLayout headerProps={{ to: '/' }} footerProps={{ hasCopyRight: true }}>
      <RenderDataWithFallback loading={loading} loadingProps={{ css: { height: '370px' } }}>
        <div css={{ textAlign: 'center', padding: '48px 24px' }}>
          <img width="48px" height="48px" css={{ margin: '0 auto' }} src={mailOpenedImage} alt="opened mail" />

          <p css={{ fontSize: '20px', marginTop: '24px', lineHeight: '100%', letterSpacing: 'normal' }}>
            {t('We have sent a confirmation email')}
          </p>

          {email ? <p css={styles.email}>{email}</p> : null}

          <p css={{ marginTop: '40px', fontSize: '14px', lineHeight: '160%' }}>
            {t('SignUp.Confirmation_1')}
            <br />
            {t('SignUp.Confirmation_2')}
          </p>

          <Anchor css={styles.link} variant="blue" onClick={handleResendEmail}>
            {t('Resend confirmation email')}
          </Anchor>

          <ButtonLink
            to="/"
            title={t('Button.Back')}
            css={{ padding: '0 24px', width: 'max-content', margin: '24px auto 0' }}
          />
        </div>
      </RenderDataWithFallback>
    </SignInSignUpLayout>
  );
};
const styles = {
  email: css({
    display: 'block',
    fontSize: '14px',
    lineHeight: '160%',
    padding: '4px 8px',
    fontStyle: 'normal',
    width: 'max-content',
    margin: '16px auto 0',
    fontStretch: 'normal',
    boxSizing: 'border-box',
    letterSpacing: 'normal',
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  link: css({
    justifySelf: 'center',
    fontSize: '12px',
    marginTop: '24px',
    lineHeight: '15px',
    letterSpacing: '0.5px',
    '&:hover': { opacity: 0.8, textDecoration: 'none' }
  })
};
