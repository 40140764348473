import { RefObject, useState, useRef, useEffect } from 'react';
import { SelectInstance } from 'react-select';

interface Props {
  ref: RefObject<SelectInstance<any, any>> | null;
}

// Detect align the menu to the right if the menu is out of the viewport
export const useAlignSelectMenu = ({ ref }: Props) => {
  const menuObserver = useRef<IntersectionObserver | null>(null);
  const [alignRight, setAlignRight] = useState<boolean | undefined>(undefined);

  useEffect(
    () => () => {
      menuObserver.current?.disconnect();
    },
    []
  );

  const handleMenuAlignment = () => {
    // Only run if alignRight is undefined
    if (ref && alignRight === undefined) {
      const observeOnscreen: IntersectionObserverCallback = (entries = []) => {
        if (entries.at(0)) {
          const { boundingClientRect } = entries.at(0) as IntersectionObserverEntry;
          const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
          const needToAlignRight = boundingClientRect.x + boundingClientRect.width + 16 > viewportWidth; // 16 is padding gap

          if (needToAlignRight) setAlignRight(needToAlignRight);
        }
      };

      // Delay to ensure menuList is rendered
      setTimeout(() => {
        const menuList = ref.current?.menuListRef;

        if (menuList) {
          menuObserver.current = new IntersectionObserver(observeOnscreen);
          menuObserver.current.observe(menuList);
        }
      }, 1);
    }
  };

  return { alignRight, handleMenuAlignment };
};
