import { css } from '@emotion/react';
import { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from '../Label';
import { CircularProgress } from '../Loading';

export interface SwitchProps {
  help?: ReactNode;
  loading?: boolean;
  title?: ReactNode;
  disabled?: boolean;
  className?: string;
  isChecked?: boolean;
  onChange: () => void;
}

export const Switch = forwardRef<HTMLDivElement, SwitchProps>(
  ({ isChecked, onChange, title, disabled, className, help, loading = false }, ref) => {
    const { t } = useTranslation();

    return (
      <div css={{ display: 'flex', alignItems: 'center' }} ref={ref} className={className}>
        <div css={styles.switchWrapper(isChecked, disabled)} onClick={!loading ? onChange : () => null}>
          <input type="checkbox" checked={isChecked} onClick={!loading ? onChange : () => null} readOnly />

          <div css={styles.label(isChecked)}>
            {!loading ? (
              t(isChecked ? 'Option.ON' : 'Option.OFF')
            ) : (
              <CircularProgress size={18} thickness={0.3} css={{ margin: '3px auto' }} />
            )}
          </div>
        </div>

        {title ? (
          <Label css={{ fontSize: '13px', marginLeft: '16px', paddingBottom: 0 }} title={title} help={help} />
        ) : null}
      </div>
    );
  }
);
Switch.displayName = 'Switch';

const getSwitchBgColorByState = ({ disabled, isChecked }: Pick<SwitchProps, 'isChecked' | 'disabled'>) => {
  switch (disabled) {
    case true:
      return isChecked ? '#9bc8f2' : '#eef2f5';

    case false:
    default:
      return isChecked ? '#3892e5' : '#dee5ec';
  }
};

const styles = {
  label: (isChecked?: boolean) =>
    css({
      right: '8px',
      fontWeight: 600,
      fontSize: '12px',
      position: 'absolute',
      color: isChecked ? '#fff' : '#A8B4BF',
      transform: isChecked ? 'translateX(-22px)' : 'none'
    }),
  switchWrapper: (isChecked?: boolean, disabled?: boolean) =>
    css({
      flex: 'none',
      width: '58px',
      height: '24px',
      cursor: 'pointer',
      lineHeight: '26px',
      position: 'relative',
      borderRadius: '24px',
      transition: 'all 0.1s',
      display: 'inline-block',
      pointerEvents: disabled ? 'none' : 'auto',
      backgroundColor: getSwitchBgColorByState({ isChecked, disabled }),
      '&::after': {
        top: '13%',
        content: '""',
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        position: 'absolute',
        transition: 'all 0.3s',
        backgroundColor: 'white',
        left: isChecked ? '37px' : '3px'
      },
      '&:focus-within::after': { boxShadow: '0 2px 4px 0 #0000003d' }
    })
};
