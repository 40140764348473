import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/Package.graphql';
import { Query, QuerypackageArgs } from '../../__generated__/globalTypes';

export const usePackageQuery = (options?: QueryHookOptions<Query, QuerypackageArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerypackageArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const usePackageLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerypackageArgs>
): QueryResult<Query, QuerypackageArgs> & { getPackage: LazyQueryExecFunction<Query, QuerypackageArgs> } => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getPackage, result] = useLazyQuery<Query, QuerypackageArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getPackage, ...result };
};

export const usePackagePromiseQuery = () => {
  const client = useApolloClient();

  const getPackage = useCallback(
    (options?: Omit<QueryOptions<QuerypackageArgs, Query>, 'query'>) =>
      client.query<Query, QuerypackageArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getPackage };
};
