import { ReactNode } from 'react';
import { useControlPostDetailsModal } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { CarouselModal, CarouselModalProps } from '../CarouselModal';

type PostDetailsModalType = Pick<PostDetailsType, 'id'>;

export interface CarouselTemplateModalProps<T extends PostDetailsModalType>
  extends Omit<CarouselModalProps, 'children'> {
  posts: readonly T[];
  paramKey?: string;
  children: ReactNode | ((props: { selectedPost: T; closeModal: () => void }) => ReactNode);
}

export const CarouselTemplateModal = <T extends PostDetailsModalType>({
  posts,
  children,
  paramKey,
  hasCloseIcon = true,
  ...restProps
}: CarouselTemplateModalProps<T>) => {
  const { selectedPost, siblingPostId, closePost, handleClickNext, handleClickPrev } = useControlPostDetailsModal<T>({
    posts,
    paramKey
  });

  return posts.length ? (
    <CarouselModal
      {...restProps}
      onClose={closePost}
      open={!!selectedPost}
      hasCloseIcon={hasCloseIcon}
      onClickNext={handleClickNext}
      onClickPrev={handleClickPrev}
      siblingPostId={siblingPostId}
    >
      {selectedPost
        ? typeof children === 'function'
          ? children({ selectedPost, closeModal: closePost })
          : children
        : null}
    </CarouselModal>
  ) : null;
};
