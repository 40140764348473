import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { AdsReportFilterFormValues } from './types';
import { Template } from './Template';

export const AdCampaignReportFilter = ({
  filter,
  setFilter
}: {
  filter: AdsReportFilterFormValues;
  setFilter: (values: AdsReportFilterFormValues) => void;
}) => (
  <FilterWrapper>
    <FilterProvider onSubmit={setFilter} initialValues={filter} basicFilter={<Template />} />
  </FilterWrapper>
);

export * from './hooks';
export * from './types';
