import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, SocialIcon, SocialIconProps, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { validateUrl } from '@/shared/utils';
import { InfluencerPromotionMethodsType, InfluencerSelectedAccountInfo } from '../types';
import { checkSocialAccountType } from '../utils';

interface InfluencerProfileUrlDescriptionProps {
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerProfileUrlDescription = ({
  socialAccount: { type, url, profile }
}: InfluencerProfileUrlDescriptionProps) => {
  const { t } = useTranslation();
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [showMoreState, setShowMoreState] = useState<{ hasButton: boolean; isShowMore: boolean }>({
    hasButton: false,
    isShowMore: true
  });

  useEffect(() => {
    if ((descriptionRef.current as HTMLParagraphElement)?.clientHeight > 36) {
      setShowMoreState((prevState) => ({ ...prevState, hasButton: true, isShowMore: false }));
    }

    return () => {
      setShowMoreState({ hasButton: false, isShowMore: true });
    };
  }, [descriptionRef, profile]);

  const isSocialAccount = checkSocialAccountType(type);

  return url || profile ? (
    <div css={styles.infoWrapper}>
      {url ? (
        <div css={styles.accountUrl}>
          {isSocialAccount ? <SocialIcon css={{ marginRight: '8px' }} type={type as SocialIconProps['type']} /> : null}
          <p css={{ marginRight: '8px' }}>
            {t(
              isSocialAccount
                ? 'Account URL'
                : type === InfluencerPromotionMethodsType.emailNewsLetters
                  ? 'Send from Email Address'
                  : 'URL'
            )}
            :
          </p>
          {validateUrl(url) ? (
            <Anchor href={url} target="_blank" label={url} css={{ textDecoration: 'underline' }} />
          ) : (
            <TextCutter lines={1} text={url} />
          )}
        </div>
      ) : null}

      {profile ? (
        <div css={[styles.profile, url && { borderTop: '1px solid #e6ecf0' }]}>
          <p>{t('Description')}:</p>

          <div css={{ marginLeft: '28px' }}>
            {showMoreState.isShowMore ? (
              <p css={{ whiteSpace: 'pre-line', color: THEME.text.colors.black.lv2 }} ref={descriptionRef}>
                {profile}
              </p>
            ) : (
              <TextCutter
                lines={2}
                text={profile}
                css={{ whiteSpace: 'pre-line', color: THEME.text.colors.black.lv2 }}
              />
            )}
            {showMoreState.hasButton && (
              <p
                css={styles.viewMore}
                onClick={() => setShowMoreState((prevState) => ({ ...prevState, isShowMore: !prevState.isShowMore }))}
              >
                {t(showMoreState.isShowMore ? 'View less' : 'View more')}
              </p>
            )}
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
};
const styles = {
  infoWrapper: css({
    fontSize: '12px',
    boxSizing: 'border-box',
    borderRadius: '0 0 3px 3px',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;}'
  }),
  accountUrl: css({
    height: '48px',
    display: 'flex',
    padding: '0 16px',
    alignItems: 'center',
    boxSizing: 'border-box'
  }),
  profile: css({
    display: 'flex',
    lineHeight: '18px',
    padding: '14px 16px',
    boxSizing: 'border-box'
  }),
  viewMore: css({
    padding: 0,
    cursor: 'pointer',
    display: 'inline-block',
    color: THEME.text.colors.gray.lv3,
    '&:hover': { opacity: 0.8 }
  })
};
