import { css } from '@emotion/react';
import { InfluencerRateCardPayload, namedOperations, useUpdateInfluencerRateCardsByStaffMutation } from '@/graphql';
import { Button, FormProvider, Popover } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostsPrice, PostsPriceFormValues } from '../../Influencer';
import { SocialMediasWithNumber } from './SocialMediasWithNumber';
import { getPostPriceOptions } from './utils';

interface PostPricesProps {
  influencerId: number;
  data?: InfluencerRateCardPayload | null;
}
export const PostPrices = ({ influencerId, data }: PostPricesProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callUpdateInfluencerRateCardsByStaff, loading } = useUpdateInfluencerRateCardsByStaffMutation({
    refetchQueries: [namedOperations.Query.GetInfluencerRateCard]
  });

  const handleSubmit =
    (closePopup: () => void) =>
    async ({ rateCards }: PostsPriceFormValues) => {
      try {
        await callUpdateInfluencerRateCardsByStaff({
          variables: {
            input: {
              influencerId,
              rateCards: {
                tiktok: rateCards.tiktok !== '' ? { price: +rateCards.tiktok } : null,
                youtube: rateCards.youtube !== '' ? { price: +rateCards.youtube } : null,
                twitter: rateCards.twitter !== '' ? { price: +rateCards.twitter } : null,
                threads: rateCards.threads !== '' ? { price: +rateCards.threads } : null,
                facebook: rateCards.facebook !== '' ? { price: +rateCards.facebook } : null,
                instagram: rateCards.instagram !== '' ? { price: +rateCards.instagram } : null,
                instagramStory: rateCards.instagramStory !== '' ? { price: +rateCards.instagramStory } : null
              }
            }
          }
        });

        closePopup();
      } catch (error) {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    };

  return (
    <Popover
      position="bottom"
      styles={{ dropdown: { transform: 'translateY(-80px)' } }}
      target={({ open, togglePopover }) => (
        <div css={[THEME.shadows.pseudoHover, { marginTop: '8px' }]} onClick={togglePopover}>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            <h3 css={{ fontSize: '12px' }}>
              {`${t('post price')}${data?.currencyId ? ` (${t(data.currencyId)})` : ''}`}
            </h3>
            {!open ? <button css={styles.editButton}>{t('Button.Edit')}</button> : null}
          </div>
          <SocialMediasWithNumber currency={data?.currencyId} css={{ marginTop: '8px' }} data={getPostPriceOptions(data)} />
        </div>
      )}
    >
      {({ togglePopover }) => (
        <FormProvider<PostsPriceFormValues>
          onSubmit={handleSubmit(togglePopover)}
          css={{ width: `${THEME.container.influencerProfile.intro.width + 16}px !important` }}
        >
          <div css={{ padding: '20px 20px 16px' }}>
            <PostsPrice influencerId={influencerId} isProfilePage />
            <Button
              type="submit"
              variant="blue"
              title={t('Save')}
              loading={loading}
              css={{ width: '100%', height: '28px', marginTop: '8px' }}
            />
          </div>
        </FormProvider>
      )}
    </Popover>
  );
};

const styles = {
  editButton: css({
    fontWeight: 400,
    fontSize: '12px',
    cursor: 'pointer',
    color: '#3892E5',
    ':hover': { opacity: 0.8 }
  })
};
