import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateAgencyUser.graphql';
import { Mutation, MutationupdateAgencyUserArgs } from '../../__generated__/globalTypes';

export const useUpdateAgencyUserMutation = (options?: MutationHookOptions<Mutation, MutationupdateAgencyUserArgs>) => {
  const [callUpdateAgencyUser, result] = useMutation<Mutation, MutationupdateAgencyUserArgs>(MUTATION, options);

  return { callUpdateAgencyUser, ...result };
};

export type UpdateAgencyUserMutationFunction = Mutation['updateAgencyUser'];
