import { useUserRoles } from '@/auth';
import { usePartnerAgencyPermissionQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { EngagementCampaignProposal } from '@/shared/organisms';
import { useEngagementDetailsContext } from '../EngagementDetailsContext';

export const EngagementProposal = () => {
  const { engagement, engagementProposal, hasTrackingReferral } = useEngagementDetailsContext();
  const { isPartner } = useUserRoles();
  const { data, loading } = usePartnerAgencyPermissionQuery({ skip: !isPartner });

  const hasInfluencerCostPermission = isPartner ? !!data?.partnerAgencyPermission?.influencerCostPermission : true;

  return (
    <RenderDataWithFallback loading={loading}>
      <EngagementCampaignProposal
        engagement={engagement}
        engagementProposal={engagementProposal}
        hasTrackingReferral={hasTrackingReferral}
        hasInfluencerCostPermission={hasInfluencerCostPermission}
      />
    </RenderDataWithFallback>
  );
};
