import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LIMIT_10, THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { YoutubeAnalyticsTagRankingPayload, useYoutubeAnalyticsTagsTopRankingQuery } from '@/graphql';
import { AnalyticsSelectedAccountInfo } from '../../types';
import { AnalyticsYoutubeTagsRankingList } from '../../../AnalyticsPostsList';
import { AnalyticsFeedPostsWrapper, AnalyticsFeedPostsWrapperProps } from '../../shared';
import { useAnalyticsProfileSearch } from '../../hooks';

export interface AnalyticsYoutubeDashboardTagsRankingTemplateProps
  extends Pick<AnalyticsFeedPostsWrapperProps, 'title' | 'loading' | 'help' | 'moreDetailProps'> {
  data?: readonly YoutubeAnalyticsTagRankingPayload[];
}

export const AnalyticsYoutubeDashboardTagsRankingTemplate = ({
  loading,
  data = [],
  ...restProps
}: AnalyticsYoutubeDashboardTagsRankingTemplateProps) => {
  const { t } = useTranslation();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!loading) {
      setMounted(true);
    }
  }, [loading]);

  return (
    <AnalyticsFeedPostsWrapper
      help=""
      title={t('Tag Ranking')}
      loading={loading && !mounted} // Render loading 1st time
      dataNotAvailable={!data.length}
      {...restProps}
    >
      <AnalyticsYoutubeTagsRankingList
        data={data}
        loading={loading && mounted} // After has data, just render loading the table content
        css={{ margin: '0 16px', borderBottom: THEME.border.base }}
      />
    </AnalyticsFeedPostsWrapper>
  );
};

export interface AnalyticsYoutubeDashboardTagsRankingProps {
  brandAccountName?: string;
  analyticsAccount: AnalyticsSelectedAccountInfo;
}

export const AnalyticsYoutubeDashboardTagsRanking = ({
  analyticsAccount,
  brandAccountName
}: AnalyticsYoutubeDashboardTagsRankingProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { filter, sort, params } = useAnalyticsProfileSearch();
  const { data, previousData, loading } = useYoutubeAnalyticsTagsTopRankingQuery({
    fetchPolicy: 'no-cache',
    variables: getValuableVariables({
      offset: 0,
      orderBy: sort,
      limit: LIMIT_10,
      endDate: filter.endDate,
      startDate: filter.startDate,
      youtubeAnalyticsAccountId: analyticsAccount.id
    }),
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      navigate({ to: '/analytics' });
    }
  });

  const result = loading ? previousData : data;

  return (
    <AnalyticsYoutubeDashboardTagsRankingTemplate
      loading={loading}
      data={result?.youtubeAnalyticsTagsTopRanking.tags}
      moreDetailProps={{
        to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list',
        params,
        search: {
          filter: {
            brandAccountName,
            endDate: filter.endDate,
            startDate: filter.startDate
          }
        }
      }}
    />
  );
};
