import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { Anchor, FormStyle } from '@/shared/atoms';
import { SelectorField, SwitchToggleField, TextField } from '@/shared/molecules';
import { sparkAdImage } from '@/shared/assets';
import { useUserRoles } from '@/auth';
import { GenericOption } from '@/shared/types';
import { THEME, TIKTOK_HELP_LINK } from '@/shared/constants';
import { SparkAdsSettingsFormKeys, SparkAdsSettingsFormValues } from './types';
import { useTikTokSparkAdForm } from './hooks';

export interface TemplateProps {
  onSubmit?: () => void;
  tkSpecialCampaignId?: string;
  hasBeenBoosted: boolean;
  campaignFinished: boolean;
  onSaveAsDraft: (formData: SparkAdsSettingsFormValues) => void;
}
export const Template = ({
  onSubmit,
  tkSpecialCampaignId,
  hasBeenBoosted,
  onSaveAsDraft,
  campaignFinished
}: TemplateProps) => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<SparkAdsSettingsFormValues>();
  const { isAdminStaff } = useUserRoles();

  const values = watch();
  const { allAdAccountsOptions, allAdCampaignsOptions, allAdGroupsOptions } = useTikTokSparkAdForm(
    values,
    tkSpecialCampaignId
  );
  const isFormValid = Object.values(values).every((v) => v !== '');

  return (
    <FormStyle.Wrapper css={{ flex: 1 }} gapRow={8} hasBorder>
      <div css={{ display: 'flex', gap: '4px', fontSize: '16px', fontWeight: 600, alignItems: 'center' }}>
        <img src={sparkAdImage} css={{ width: '16px', height: '16px' }} alt="spark ad" />
        {t('Spark Ads Settings')}
      </div>
      {!isAdminStaff ? (
        <>
          <div css={styles.runSparkAds}>
            <Trans
              i18nKey="To run Spark Ads, you need a pre-created Campaign in TikTok Ad Manager"
              components={{
                a: (
                  <Anchor
                    href={`https://ads.tiktok.com/i18n/dashboard?refer=from_help_create&aadvid=${values.adAccountSystemId}`}
                    target="_blank"
                    variant="blue"
                    css={{ display: 'inline' }}
                  />
                )
              }}
            />
          </div>
          <Anchor href={TIKTOK_HELP_LINK.howToCreateCampaign} target="_blank" variant="blue">
            {t('How to create a Campaign?')}
          </Anchor>
        </>
      ) : null}

      <SelectorField<SparkAdsSettingsFormKeys>
        title={t('Selector.Ad Account')}
        name="adAccountSystemId"
        placeholder={t<string>('Select Ad Account')}
        options={allAdAccountsOptions}
        required
        css={{ marginTop: '16px' }}
        disabled={hasBeenBoosted}
      />
      <SelectorField<SparkAdsSettingsFormKeys, GenericOption<string, ReactNode>>
        title={t('Selector.Campaign')}
        name="tkCampaignId"
        placeholder={t<string>('Select Campaign')}
        options={allAdCampaignsOptions}
        required
        disabled={hasBeenBoosted}
      />
      <SelectorField<SparkAdsSettingsFormKeys>
        title={t('Selector.Ad group')}
        placeholder={t<string>('Select Ad group')}
        name="adGroupId"
        options={allAdGroupsOptions}
        required
        disabled={hasBeenBoosted}
      />
      <TextField<SparkAdsSettingsFormKeys>
        name="adName"
        title={t('TextForm.Ad name')}
        placeholder={t<string>('Enter Spark Ad name')}
        required
        disabled={hasBeenBoosted}
      />
      <TextField<SparkAdsSettingsFormKeys>
        name="authCode"
        title={t('TextForm.Post code')}
        placeholder={t<string>('Enter Post code')}
        required
        disabled={hasBeenBoosted}
      />
      <SwitchToggleField<SparkAdsSettingsFormKeys>
        name="callToAction"
        label={t('Call to action')}
        css={{ label: { marginBottom: 0 } }}
        labelHelp={t('You can add the URL of the product you want to advertise in the post')}
        disabled={hasBeenBoosted}
      />

      {values.callToAction ? (
        <>
          <div css={{ color: THEME.text.colors.gray.lv3, fontSize: '12px' }}>
            <Trans
              i18nKey="This Ad will be called to action as Standard"
              components={{
                span: <span css={{ fontWeight: 500 }} />
              }}
            />
          </div>
          <TextField<SparkAdsSettingsFormKeys>
            name="landingPageUrl"
            title={t('TextForm.Landing URL')}
            placeholder="https://"
            disabled={hasBeenBoosted}
          />
        </>
      ) : null}
      <div css={{ marginTop: '16px', display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
        <FormStyle.ButtonSubmit
          type="button"
          variant="white"
          title={t('Button.Save as Draft')}
          disabled={hasBeenBoosted || campaignFinished}
          loading={isSubmitting}
          onClick={() => onSaveAsDraft(values)}
        />
        <FormStyle.ButtonSubmit
          type="button"
          title={t('Button.Publish')}
          disabled={!isFormValid || hasBeenBoosted || campaignFinished}
          loading={isSubmitting}
          onClick={onSubmit}
        />
      </div>
    </FormStyle.Wrapper>
  );
};

const styles = {
  runSparkAds: css({
    marginTop: '8px',
    fontSize: '11.8px', // text in Figma is 12px but somehow can fit in 1 line, but on web it is not same
    wordWrap: 'break-word',
    fontFamily: 'Roboto',
    fontWeight: 400
  })
};
