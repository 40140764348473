import { ReactNode, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  TikTokSpecialCampaignPostStatus,
  namedOperations,
  useUpdateTiktokSpecialPostStatusMutation,
  TikTokSpecialCampaignPost
} from '@/graphql';
import { Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useToggle } from '@/shared/hooks';

const getPostStatusValidator = (newStatus: TikTokSpecialCampaignPostStatus) => {
  switch (newStatus) {
    case TikTokSpecialCampaignPostStatus.WARNING:
      return (status: TikTokSpecialCampaignPostStatus) =>
        [TikTokSpecialCampaignPostStatus.WARNING, TikTokSpecialCampaignPostStatus.EFFECTIVE].includes(status);
    case TikTokSpecialCampaignPostStatus.WARNING_SOLVED:
      return (status: TikTokSpecialCampaignPostStatus) => status === TikTokSpecialCampaignPostStatus.WARNING;
    case TikTokSpecialCampaignPostStatus.APPROVED:
    case TikTokSpecialCampaignPostStatus.INEFFECTIVE:
      return (_: TikTokSpecialCampaignPostStatus) => true;
    default:
      return null;
  }
};

export const useUpdateTikTokPostsStatus = (campaignId: number) => {
  const loadingState = useToggle();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callUpdateTiktokSpecialPostStatus } = useUpdateTiktokSpecialPostStatusMutation({
    refetchQueries: [namedOperations.Query.TiktokSpecialCampaignPosts]
  });

  const [errorModalState, setErrorModalState] = useState<{
    title: string;
    content?: ReactNode;
    newStatus: TikTokSpecialCampaignPostStatus;
  } | null>(null);
  const [confirmModalState, setConfirmModalState] = useState<{
    selectedPostsDetails: Partial<TikTokSpecialCampaignPost>[];
    newStatus: TikTokSpecialCampaignPostStatus;
    updatingPostStatuses: TikTokSpecialCampaignPostStatus[];
  } | null>(null);

  const statuses = {
    [TikTokSpecialCampaignPostStatus.WARNING]: { color: '#ffb63d', text: t('Option.Warning') },
    [TikTokSpecialCampaignPostStatus.APPROVED]: { color: '#40b87c', text: t('Option.Approved') },
    [TikTokSpecialCampaignPostStatus.INEFFECTIVE]: { color: '#ff2b52', text: t('Option.Ineffective') },
    [TikTokSpecialCampaignPostStatus.EFFECTIVE]: { color: 'rgb(56, 146, 229)', text: t('Option.Effective') },
    [TikTokSpecialCampaignPostStatus.WARNING_SOLVED]: { color: '#ffb63d', text: t('Option.Warning Solved') },
    // Unsupported changes
    [TikTokSpecialCampaignPostStatus.JOINED]: { color: '', text: '' }
  };

  const handleSelectStatus = (
    newStatus: TikTokSpecialCampaignPostStatus,
    selectedPostsDetails: Partial<TikTokSpecialCampaignPost>[]
  ) => {
    const updatingPostStatuses = selectedPostsDetails.reduce<TikTokSpecialCampaignPostStatus[]>(
      (acc, curr) => (curr.status && curr.status.valueOf() !== newStatus.valueOf() ? [...acc, curr.status] : acc),
      []
    );

    if (!updatingPostStatuses.length) {
      setErrorModalState({ newStatus, title: t('Dialog.No need to update status') });

      return;
    }

    const checkPostStatus = getPostStatusValidator(newStatus);
    if (!checkPostStatus) {
      setErrorModalState({ newStatus, title: t('Dialog.Unable to save'), content: t('Dialog.Invalid status') });

      return;
    }

    const invalidPostStatus = updatingPostStatuses.filter((status) => !checkPostStatus(status)).at(0);

    if (invalidPostStatus) {
      setErrorModalState({
        newStatus,
        title: t('Dialog.Unable to save'),
        content: (
          <Trans
            i18nKey="Dialog.FailedToChangeMarketplacePostStatus" // we can use it has no MKP mention
            components={{
              1: <span css={{ color: statuses[newStatus].color }} />,
              3: <span css={{ color: statuses[invalidPostStatus].color }} />
            }}
            values={{ to: statuses[newStatus].text, from: statuses[invalidPostStatus].text }}
          />
        )
      });
    } else {
      setConfirmModalState({ newStatus, selectedPostsDetails, updatingPostStatuses });
    }
  };

  const resetConfirmModalState = () => {
    setConfirmModalState(null);
  };

  const resetErrorModalState = () => {
    setErrorModalState(null);
  };

  const { postIds } = (confirmModalState?.selectedPostsDetails || []).reduce<{
    postIds: (number | null)[];
  }>(
    (acc, { id }) => ({
      postIds: [...acc.postIds, id ?? null]
    }),
    { postIds: [] }
  );

  const handleUpdatePostsStatus = async (onSuccess?: () => void) => {
    if (
      !confirmModalState?.newStatus ||
      !confirmModalState.selectedPostsDetails.length ||
      !confirmModalState.updatingPostStatuses.length
    ) {
      return;
    }
    loadingState.setOn();

    try {
      await callUpdateTiktokSpecialPostStatus({
        variables: { input: { postIds, campaignId: Number(campaignId), status: confirmModalState.newStatus } }
      });
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
      onSuccess?.();
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }

    resetConfirmModalState();
    loadingState.setOff();
  };

  return {
    campaignId: Number(campaignId),
    callUpdateTiktokSpecialPostStatus,
    handleSelectStatus,
    updating: loadingState.on,
    selectedStatus: confirmModalState?.newStatus || errorModalState?.newStatus,
    UpdateTikTokSpecialPostStatusModal: ({ onSuccess }: { onSuccess?: () => void }) => (
      <>
        <Modal onClose={resetConfirmModalState} open={!!confirmModalState?.newStatus} css={{ width: 'max-content' }}>
          <ModalContent>
            <ModalTitle>{t('Dialog.Confirm Changes')}</ModalTitle>
            {confirmModalState?.newStatus ? (
              <div css={{ marginTop: '8px' }}>
                <Trans
                  i18nKey="Dialog.PostUpdateNotice"
                  values={{ status: statuses[confirmModalState.newStatus].text }}
                  components={{ 1: <span css={{ color: statuses[confirmModalState.newStatus].color }} /> }}
                />
              </div>
            ) : null}
          </ModalContent>
          <ModalFooterActions
            cancelButtonProps={{ onClick: resetConfirmModalState }}
            submitButtonProps={{
              title: t('Button.Approve'),
              loading: loadingState.on,
              onClick: () => handleUpdatePostsStatus(onSuccess)
            }}
          />
        </Modal>

        <Modal open={!!errorModalState?.title} onClose={resetErrorModalState} css={{ width: 'max-content' }}>
          <ModalContent>
            <ModalTitle>{errorModalState?.title}</ModalTitle>

            {errorModalState?.content ? <div css={{ marginTop: '16px' }}>{errorModalState.content}</div> : null}
          </ModalContent>
          <ModalFooterActions cancelButtonProps={{ title: t('Button.OK'), onClick: resetErrorModalState }} />
        </Modal>
      </>
    )
  };
};
