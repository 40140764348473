import { MarketplaceCampaignStatus } from '@/graphql';
import { useTranslateOptions } from '@/shared/hooks';

export const useMarketplaceStatusOptions = (forSearch?: boolean) => {
  const marketplaceStatusOptions = useTranslateOptions([
    { label: 'Reviewing', value: MarketplaceCampaignStatus.REVIEWING },
    { label: 'Upcoming', value: MarketplaceCampaignStatus.UPCOMING },
    { label: 'Ongoing', value: MarketplaceCampaignStatus.ONGOING },
    { label: 'Over Budget', value: MarketplaceCampaignStatus.SUSPENDED },
    { label: 'Finished', value: MarketplaceCampaignStatus.FINISHED },
    { label: 'Rejected', value: MarketplaceCampaignStatus.REJECTED },
    ...(!forSearch ? [{ label: 'Upload codes', value: MarketplaceCampaignStatus.OUT_OF_COUPON }] : [])
  ]);

  return { marketplaceStatusOptions };
};
