import { useTranslation } from 'react-i18next';
import { MIN_MAX_FOLLOWERS_OPTIONS } from '@/shared/constants';
import { RangeSliderInputFieldProps, RangeSliderInputField } from '@/shared/molecules';
import { CampaignSocialMediaType } from '@/graphql';
import { MarketplaceFormKeys } from '../types';

interface FollowerRangeInputFieldProps extends Omit<RangeSliderInputFieldProps, 'minField' | 'maxField' | 'options'> {
  minField: Omit<RangeSliderInputFieldProps['minField'], 'name' | 'value' | 'defaultValue'>;
  maxField: Omit<RangeSliderInputFieldProps['maxField'], 'name' | 'value' | 'defaultValue'>;
  socialMedia: CampaignSocialMediaType | '';
}

export const FollowerRangeInputField = ({ minField, maxField, socialMedia }: FollowerRangeInputFieldProps) => {
  const { t } = useTranslation();

  return (
    <RangeSliderInputField
      options={MIN_MAX_FOLLOWERS_OPTIONS}
      help={
        <div>
          {t('Tooltip.Number Of Followers')}
          {socialMedia === CampaignSocialMediaType.FACEBOOK && (
            <>
              <br />
              {t('Tooltip.Please note that if the campaign is Facebook it is Friend not Follower')}
            </>
          )}
        </div>
      }
      title={t('General.Number of Followers')}
      minField={{ ...minField, name: MarketplaceFormKeys.minFollowers }}
      maxField={{ ...maxField, name: MarketplaceFormKeys.maxFollowers }}
      hideDeselectOption
      hideSlider
    />
  );
};
