import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TwitterAnalyticsPostsStatistic.graphql';
import { Query, QuerytwitterAnalyticsPostsStatisticArgs } from '../../__generated__/globalTypes';

export const useTwitterAnalyticsPostsStatisticQuery = (
  options?: QueryHookOptions<Query, QuerytwitterAnalyticsPostsStatisticArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytwitterAnalyticsPostsStatisticArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTwitterAnalyticsPostsStatisticLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytwitterAnalyticsPostsStatisticArgs>
): QueryResult<Query, QuerytwitterAnalyticsPostsStatisticArgs> & {
  getTwitterAnalyticsPostsStatistic: LazyQueryExecFunction<Query, QuerytwitterAnalyticsPostsStatisticArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTwitterAnalyticsPostsStatistic, result] = useLazyQuery<Query, QuerytwitterAnalyticsPostsStatisticArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getTwitterAnalyticsPostsStatistic, ...result };
};

export const useTwitterAnalyticsPostsStatisticPromiseQuery = () => {
  const client = useApolloClient();

  const getTwitterAnalyticsPostsStatistic = useCallback(
    (options?: Omit<QueryOptions<QuerytwitterAnalyticsPostsStatisticArgs, Query>, 'query'>) =>
      client.query<Query, QuerytwitterAnalyticsPostsStatisticArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTwitterAnalyticsPostsStatistic };
};
