import { PostDetailsType } from '@/shared/types';
import { TikTokSpecialPostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface TikTokSpecialPostDetailsModalProps {
  posts: PostDetailsType[];
}

export const TikTokSpecialPostDetailsModal = ({ posts }: TikTokSpecialPostDetailsModalProps) => (
  <CarouselTemplateModal posts={posts}>
    {({ selectedPost, closeModal }) => <TikTokSpecialPostDetails id={selectedPost.id} onCloseModal={closeModal} />}
  </CarouselTemplateModal>
);
