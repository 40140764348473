import { useUserRoles } from '@/auth';
import { MarketplaceCampaignDetailType } from '@/graphql';
import { FormProvider } from '@/shared/atoms';
import { DefaultFormProps } from '../types';
import { getMarketplaceInitialFormValues } from '../utils';
import { getSocialMediaTrackingFormSchema } from './schema';
import { Template } from './Template';

export const SocialMediaTrackingForm = ({ data, onSubmit, disabledFields, defaultAdvertiser }: DefaultFormProps) => {
  const { isAgency, role } = useUserRoles();

  const defaultValues = getMarketplaceInitialFormValues({
    data,
    isAgency,
    defaultAdvertiser,
    campaignType: MarketplaceCampaignDetailType.NORMAL
  });

  return (
    <FormProvider onSubmit={onSubmit} defaultValues={defaultValues} zodSchema={getSocialMediaTrackingFormSchema(role)}>
      <Template defaultValues={defaultValues} disabledFields={disabledFields} />
    </FormProvider>
  );
};
