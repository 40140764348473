import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { MantineProvider, type MantineThemeOverride } from '@mantine/core';
import { ReactNode } from 'react';
import { THEME } from '@/shared/constants';

export const ANYTAG_THEME = {
  // TODO: we can have global theme for mantine here, but also we are already having Emotion theme object
} satisfies MantineThemeOverride;

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <MantineProvider theme={ANYTAG_THEME}>
    <EmotionThemeProvider theme={THEME}>{children}</EmotionThemeProvider>
  </MantineProvider>
);
