import { ReactNode } from 'react';
import { useFormContext, get, GlobalError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMaskito } from '@maskito/react';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { Input } from 'react-field-sizing-content';
import { css } from '@emotion/react';
import { Label, FormStyle, InputUnitProps, LabelProps } from '@/shared/atoms';
import { DefaultFieldProps } from '@/shared/molecules';
import { formatDynamicErrorMessage, formatNumber } from '@/shared/utils';
import { THEME } from '@/shared/constants';
import { withMaskitoRegister } from './withMaskitoRegister';
import { TransferModalFormValues } from './types';

export interface MoneyFieldProps
  extends DefaultFieldProps,
    Omit<InputUnitProps, 'name' | 'value' | 'title' | 'onChange'>,
    Pick<LabelProps, 'labelLoader'> {
  numberWithDecimals?: boolean;
  children?: ReactNode;
  dynamicError?: boolean;
  triggerFormRevalidation?: boolean;
  onChangeCallback?: () => void;
  balance?: number;
  hasMaxValue?: boolean;
}

export const MoneyField = ({
  help,
  name,
  title,
  prefix,
  onBlur,
  balance,
  onSubmit,
  children,
  className,
  onKeyDown,
  hasMaxValue,
  labelLoader,
  dynamicError,
  onChangeCallback,
  numberWithDecimals,
  triggerFormRevalidation,
  ...restProps
}: MoneyFieldProps) => {
  const {
    watch,
    trigger,
    register,
    formState: { errors }
  } = useFormContext<TransferModalFormValues>();
  const { t } = useTranslation();

  const inputRegister = register('amount');
  const amount = watch('amount');
  const step = watch('step');

  const maskitoRef = useMaskito({
    options: maskitoNumberOptionsGenerator({
      precision: 2,
      thousandSeparator: ',',
      decimalSeparator: '.'
    })
  });

  const error = get(errors, name) as GlobalError; // we can use get for getting nested error messages errors[name.nestedName]
  const errorMessage = dynamicError ? formatDynamicErrorMessage(t, error?.message) : error?.message;

  return (
    <>
      <FormStyle.FieldWrapper className={className} css={styles.moneyInput(amount !== '')}>
        {title && <Label title={title} required={restProps.required} help={help} labelLoader={labelLoader} />}
        <span css={styles.prefix(amount)}>{prefix}</span>
        <Input
          type="text"
          fieldSizing="content"
          placeholder="10,000"
          onKeyDown={onKeyDown}
          {...withMaskitoRegister(inputRegister, maskitoRef, triggerFormRevalidation ? trigger : undefined)}
          css={{ gridArea: '1 / 1' }}
        />
        {children}
      </FormStyle.FieldWrapper>
      {hasMaxValue ? (
        <div css={styles.errorField(!!(amount && errorMessage))}>
          {step === 'charge'
            ? t(balance ? 'Max to' : 'Max to please recharge', {
                amount: `${prefix}${formatNumber(balance || 0, 0)}`
              })
            : t('Max to', {
                amount: `${prefix}${formatNumber(balance || 0, 0)}`
              })}
        </div>
      ) : (
        <div css={styles.errorField(!!errorMessage)}>{errorMessage}</div>
      )}
    </>
  );
};

const styles = {
  errorField: (hasError: boolean) =>
    css({
      height: '24px',
      fontSize: '14px',
      textAlign: 'center',
      marginTop: '4px',
      width: '100%',
      color: hasError ? THEME.colors.red['50'] : THEME.colors.black['50']
    }),
  prefix: (value: string) =>
    css({
      gridArea: '1 / 1',
      lineHeight: 'normal',
      width: 'max-content',
      color: value ? THEME.text.colors.black.lv1 : THEME.colors.black['30']
    }),
  moneyInput: (hasValue: boolean) =>
    css({
      display: 'grid',
      padding: 0,
      fontSize: '32px',
      width: 'max-content',
      margin: '0 auto',
      fontWeight: 600,
      marginTop: '24px',
      input: {
        minWidth: hasValue ? 'unset' : '110px',
        border: 'none',
        paddingLeft: '24px',
        fontSize: '32px',
        '::placeholder': { fontSize: '32px', fontWeight: 600, color: THEME.colors.black['30'] }
      }
    })
};
