import React, { createContext, useContext } from 'react';
import { UserProposal, UserProposalSocialAcccountBudgetInput } from '@/graphql';
import { ProposedInfluencer } from './schemaTypes';
import { getInitialInfluencers, splitProposalInfluencersByIsDenied } from './utils';

export interface UserProposalContextType {
  loading: boolean;
  allSocialAccountIds: number[];
  userProposal?: UserProposal | null;
  rejectedInfluencers: ProposedInfluencer[];
  selectedInfluencers: ProposedInfluencer[];
  allSocialAccounts: UserProposalSocialAcccountBudgetInput[];
}

const UserProposalContext = createContext<UserProposalContextType>({} as UserProposalContextType);

interface UserProposalProviderProps {
  children: React.ReactElement | Array<React.ReactElement>;
  value: Pick<UserProposalContextType, 'userProposal' | 'loading'>;
}

export const UserProposalProvider = ({ children, value: { userProposal, loading } }: UserProposalProviderProps) => {
  const proposalInfluencers = getInitialInfluencers(userProposal);
  const { selectedInfluencers, rejectedInfluencers } = splitProposalInfluencersByIsDenied(proposalInfluencers);

  const allSocialAccounts: UserProposalSocialAcccountBudgetInput[] = [];
  const allSocialAccountIds =
    userProposal?.influencers.reduce((ids, infl) => {
      const accountIds = infl.accountList.map(({ id, socialMedia, summary: { budget, influencerCost, profit } }) => {
        allSocialAccounts.push({
          influencerId: infl.id,
          socialAccountId: id,
          socialAccountMedia: socialMedia,
          profit: profit || 0,
          budget: budget || 0,
          influencerCost: influencerCost || 0
        });

        return id;
      });

      return [...ids, ...accountIds];
    }, [] as number[]) || [];

  return (
    <UserProposalContext.Provider
      value={{
        loading,
        allSocialAccounts,
        userProposal,
        allSocialAccountIds,
        rejectedInfluencers,
        selectedInfluencers
      }}
    >
      {children}
    </UserProposalContext.Provider>
  );
};

export const useUserProposalContext = () => useContext<UserProposalContextType>(UserProposalContext);
