import { FormStyle } from '@/shared/atoms';
import { FilterAction } from '@/shared/types';
import { PostReportFilterFormValues, PostReportSearchSchemaType } from '@/shared/molecules';
import { PostReportFilter, DownloadPostReportsButton } from '../../../PostReport';

export interface MarketplaceReportFilterProps extends FilterAction<PostReportFilterFormValues> {
  campaignId: number;
  segmentState: PostReportSearchSchemaType['state'];
}
export const MarketplaceReportFilter = ({
  filter,
  setFilter,
  campaignId,
  segmentState
}: MarketplaceReportFilterProps) => (
  <PostReportFilter filter={filter} setFilter={setFilter}>
    <FormStyle.FieldWrapper css={{ flex: 'none' }}>
      <DownloadPostReportsButton filter={filter} campaignId={campaignId} segmentState={segmentState} />
    </FormStyle.FieldWrapper>
  </PostReportFilter>
);
