import { css } from '@emotion/react';
import { createStyles } from '@mantine/core';
import { forwardRef, useRef, useState } from 'react';
import { Tooltip, TextCutter, TextCutterProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useDeepCompareEffect, useDimensions } from '@/shared/hooks';
import { InfluencerTagsPopover } from './InfluencerTagsPopover';

export interface TagsSectionProps {
  tags: readonly string[];
  className?: string;
  influencerId: number;
}

export const TagsSection = ({ influencerId, tags, className }: TagsSectionProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { classes } = useStyles();
  const { height } = useDimensions(ref);
  const [tagsValue, setTagsValue] = useState(tags);

  useDeepCompareEffect(() => {
    setTagsValue(tags);
  }, [tags]);

  const defaultHeight = 63; // 2 lines
  const hasTags = !!tagsValue.length;

  const Tags = forwardRef<HTMLParagraphElement, Pick<TextCutterProps, 'lines'>>(({ lines }, ref) => (
    <TextCutter
      ref={ref}
      lines={lines}
      css={{ margin: '-4px' }}
      text={tagsValue.map((tag, index) => (
        <span key={`${influencerId}-${index}`} css={{ padding: '4px', display: 'inline-flex' }}>
          <span css={styles.tag}>{tag}</span>
        </span>
      ))}
    />
  ));

  return (
    <div css={{ width: '240px', display: 'flex', alignItems: 'center' }} className={className}>
      <InfluencerTagsPopover
        tags={tagsValue}
        onTagsChange={setTagsValue}
        influencerId={influencerId}
        target={({ setOpen }) =>
          tagsValue.length ? (
            <Tooltip help={height > defaultHeight ? <Tags lines={5} /> : ''} classNames={classes} multiline>
              <div
                ref={ref}
                css={hasTags && THEME.shadows.pseudoHover}
                onClick={() => (hasTags ? setOpen(true) : null)}
              >
                <Tags lines={height > defaultHeight ? 2 : 3} />
              </div>
            </Tooltip>
          ) : undefined
        }
      />
    </div>
  );
};

const useStyles = createStyles({
  arrow: { color: THEME.background.colors.white },
  tooltip: {
    padding: '8px',
    fontSize: '12px',
    maxWidth: '300px',
    marginBottom: '8px',
    width: 'max-content',
    height: 'fit-content',
    color: THEME.text.colors.black.lv1,
    backgroundColor: THEME.background.colors.white,
    filter:
      'drop-shadow(0px 0px 1px rgba(154, 154, 154, 0.65)) drop-shadow(0px 2px 1px rgba(154, 154, 154, 0.1)) drop-shadow(0px 3px 5px rgba(154, 154, 154, 0.2))'
  }
});

const styles = {
  tag: css({
    maxWidth: 240,
    borderRadius: 3,
    padding: '4px 8px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
    background: '#EEF3F7',
    display: 'inline-block',
    boxSizing: 'border-box',
    textOverflow: 'ellipsis'
  })
};
