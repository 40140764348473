import { SocialPostType, InfluencerSocialPost, PostListSortField } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerPostDetailsModal } from '@/shared/molecules';
import { SortAction, TableData } from '@/shared/types';
import { useInfluencerXhsPostsListTable } from './hooks';

export interface InfluencerXhsPostsListProps extends TableData<InfluencerSocialPost>, SortAction<PostListSortField> {
  influencerId?: number;
  socialPostType: SocialPostType;
}

export const InfluencerXhsPostsList = ({
  sort,
  loading,
  setSort,
  data = [],
  influencerId,
  socialPostType
}: InfluencerXhsPostsListProps) => {
  const { rows, columns } = useInfluencerXhsPostsListTable({ sort, data, setSort, socialPostType });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />
      <InfluencerPostDetailsModal posts={data.map(({ id }) => ({ id: String(id), influencerId }))} />
    </>
  );
};
