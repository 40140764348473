import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: string;
  Date: string;
  DateTime: string;
  JSON: any;
  Long: number;
  _FieldSet: any;
};

/** An enumeration. */
export enum AIModelName {
  GEMINI = 'GEMINI',
  OPENAI = 'OPENAI'
}

export type AcceptedPayload = {
  readonly accepted: Scalars['Boolean'];
};

export type AccountAnalyticsAccountManager = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type AccountAnalyticsAdvertiser = {
  readonly country: Country;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type AccountAnalyticsAdvertiserDetails = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type AccountAnalyticsDetail = {
  readonly accountManagers: ReadonlyArray<AccountAnalyticsAccountManager>;
  readonly accountName: Scalars['String'];
  readonly advertiser: AccountAnalyticsAdvertiserDetails;
  readonly id: Scalars['Int'];
  readonly socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  readonly subscriptionPlan: AnalyticsSubscriptionPlanType;
};

export type AccountAnalyticsFacebookPageAccount = {
  readonly avatar: Scalars['String'];
  readonly fbPageId: Scalars['String'];
  readonly fbPageName: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly needReconnect: Scalars['Boolean'];
  readonly pageUrl: Scalars['String'];
};

export type AccountAnalyticsForAdvertiserPayload = {
  readonly accountName: Scalars['String'];
  readonly avatar: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  readonly subscriptionPlan: AnalyticsSubscriptionPlanType;
};

export type AccountAnalyticsInstagramAccount = {
  readonly avatar: Scalars['String'];
  readonly businessAccountId: Scalars['String'];
  readonly fbPageId: Scalars['String'];
  readonly fbPageName: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly instagramAccountName: Scalars['String'];
  readonly needReconnect: Scalars['Boolean'];
};

export type AccountAnalyticsPayload = {
  readonly accountManagers: ReadonlyArray<AccountAnalyticsAccountManager>;
  readonly accountName: Scalars['String'];
  readonly advertiser: AccountAnalyticsAdvertiser;
  readonly avatar: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  readonly subscriptionPlan: AnalyticsSubscriptionPlanType;
};

export type AccountAnalyticsSocialMediaAccount = {
  readonly facebookPage?: Maybe<AccountAnalyticsFacebookPageAccount>;
  readonly instagram?: Maybe<AccountAnalyticsInstagramAccount>;
  readonly twitter?: Maybe<AccountAnalyticsTwitterAccount>;
  readonly youtube?: Maybe<AccountAnalyticsYouTubeAccount>;
};

export type AccountAnalyticsTwitterAccount = {
  readonly avatar: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly needReconnect: Scalars['Boolean'];
  readonly twitterUserId: Scalars['String'];
  readonly username: Scalars['String'];
};

export type AccountAnalyticsYouTubeAccount = {
  readonly avatar: Scalars['String'];
  readonly channelId: Scalars['String'];
  readonly channelName: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly needReconnect: Scalars['Boolean'];
};

export type AccountAudienceDataRate = {
  readonly name: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type ActionLabelInput = {
  readonly label: Scalars['String'];
  readonly url: Scalars['String'];
};

export type ActionLabelPayload = {
  readonly label: Scalars['String'];
  readonly url: Scalars['String'];
};

export type ActivityChange = {
  readonly key: ChangeType;
  readonly newValue?: Maybe<Scalars['String']>;
  readonly oldValue?: Maybe<Scalars['String']>;
};

export type AddAllSelectedInfluencers = {
  readonly ok: Scalars['Boolean'];
};

export type AddAllSelectedInfluencersInput = {
  readonly marketplaceId: Scalars['Int'];
};

export type AddCommentInput = {
  readonly comment: Scalars['String'];
  readonly fanId: Scalars['Long'];
};

export type AddCommentPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AddCompareInstagramAccountAnalytics = {
  readonly ok: Scalars['Boolean'];
};

export type AddEmailTemplateInput = {
  readonly fans: ReadonlyArray<Scalars['Long']>;
  readonly isDraft: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly scheduleDate?: InputMaybe<Scalars['String']>;
  readonly sendNow: Scalars['Boolean'];
  readonly sender: Scalars['String'];
  readonly subject: Scalars['String'];
  readonly tags: ReadonlyArray<Scalars['Long']>;
};

export type AddEmailTemplatePayload = {
  readonly ok: Scalars['Boolean'];
};

export type AddFanInput = {
  readonly contactNumber?: InputMaybe<Scalars['String']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
};

export type AddFanPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AddFanTagsInput = {
  readonly fanIds: ReadonlyArray<Scalars['Long']>;
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type AddFanTagsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AddFormInput = {
  readonly description: Scalars['String'];
  readonly hash: Scalars['String'];
  readonly questions: ReadonlyArray<QuestionsInput>;
  readonly thankDescription: Scalars['String'];
  readonly thankTitle: Scalars['String'];
  readonly title: Scalars['String'];
};

export type AddFormPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AddInfluencerToUserProposal = {
  readonly ok: Scalars['Boolean'];
};

export type AddInfluencerToUserProposalInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
};

export type AddInstagramCompareAccountAnalyticsInput = {
  /** an Instagram username of an account that we want to compare */
  readonly compareAccountIgUsername: Scalars['String'];
  /** an analytics account's system id that will be compared with the selected Instagram account */
  readonly mainAnalyticsAccountId: Scalars['Int'];
};

export type AddInstagramHashtagAnalytics = {
  readonly ok: Scalars['Boolean'];
};

export type AddInstagramHashtagAnalyticsInput = {
  /** an analytic account's system id where the selected hashtag will be added to */
  readonly analyticsAccountId: Scalars['Int'];
  /** a hashtag that we want to add */
  readonly hashtag: Scalars['String'];
};

export type AddMarketplacePostUrl = {
  readonly ok: Scalars['Boolean'];
};

export type AddMarketplacePostUrlForInfluencer = {
  readonly ok: Scalars['Boolean'];
};

export type AddMarketplacePostUrlInput = {
  readonly influencerId: Scalars['Int'];
  readonly marketplaceId: Scalars['Int'];
  readonly postUrl: Scalars['String'];
};

export type AddMarketplacePostUrlInputForInfluencer = {
  readonly marketplaceId: Scalars['Int'];
  readonly postUrl: Scalars['String'];
};

export type AddReadTutorial = {
  readonly ok: Scalars['Boolean'];
};

export type AddReadTutorialInput = {
  readonly tutorialName: TutorialName;
};

export type AddSelectedInfluencers = {
  readonly ok: Scalars['Boolean'];
};

export type AddSelectedInfluencersInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly marketplaceId: Scalars['Int'];
};

export type AddSocialAccountToUserProposal = {
  readonly ok: Scalars['Boolean'];
};

export type AddSocialAccountToUserProposalInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountMedia: SocialAccountType;
};

export type AddStaffAdvertisersToAnotherStaff = {
  readonly ok: Scalars['Boolean'];
};

export type AddStaffAdvertisersToAnotherStaffInput = {
  /** List of added advertiser ids */
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  /** The id of a staff user who we need to add advertisers to, */
  readonly otherStaffUserId: Scalars['Int'];
};

export type AddYoutubeCompareAccountAnalyticsInput = {
  readonly mainAccountId: Scalars['Int'];
  readonly url: Scalars['String'];
};

export type AddYoutubeCompareAccountAnalyticsOutput = {
  readonly ok: Scalars['Boolean'];
};

export type Address = {
  readonly info?: Maybe<AddressDetails>;
  readonly isEditable: Scalars['Boolean'];
};

export type AddressDetails = {
  readonly address: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly city: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
};

export type AddressForInfluencer = {
  readonly address: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly city: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
};

export type AddressInput = {
  readonly address: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly city: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
};

export type Advertiser = {
  readonly anyxMasterId?: Maybe<Scalars['Int']>;
  readonly anyxMasterName?: Maybe<Scalars['String']>;
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly hubspotId?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly internalNetsuiteId?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly users: ReadonlyArray<AdvertiserUser>;
};

export type AdvertiserForAdvertiserUser = {
  readonly country: CountryName;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
};

export type AdvertiserForAdvertiserUserSettings = {
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
};

export type AdvertiserForList = {
  readonly analyticsPlan?: Maybe<AnalyticsSubscriptionPlanType>;
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly hubspotId?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly internalNetsuiteId?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly tiktokPlan?: Maybe<TikTokAdvertiserPlanFilter>;
  readonly users: Scalars['Int'];
  readonly verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
};

export type AdvertiserForSearch = {
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly hubspotId?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
};

export type AdvertiserName = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

/** An enumeration. */
export enum AdvertiserType {
  NORMAL = 'NORMAL',
  TIKTOK_BUSINESS = 'TIKTOK_BUSINESS'
}

export type AdvertiserUser = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type AdvertiserUserInput = {
  readonly email: Scalars['String'];
  readonly hasInvitationEmail: Scalars['Boolean'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly userName: Scalars['String'];
};

export type AdvertiserUserSettingsDetails = {
  readonly advertiser: AdvertiserForAdvertiserUserSettings;
  readonly country: CountryName;
  readonly email: Scalars['String'];
  readonly hasStripeSubscriptionPlan: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly notificationSetting: Scalars['Boolean'];
  readonly subscriptionPlan?: Maybe<SubscriptionPlanForAdvertiserUserSettings>;
};

export type AdvertiserUserWithRole = {
  readonly companyName?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly role: UserRoles;
};

export type AffiliateCampaignChartReport = {
  readonly cost: Scalars['Float'];
  readonly date: Scalars['Date'];
  readonly grossProfit: Scalars['Float'];
  readonly sales: Scalars['Float'];
};

/** An enumeration. */
export enum AffiliateReportShowBy {
  DATE = 'DATE',
  INFLUENCER = 'INFLUENCER'
}

export type AgenciesForSearch = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type AgencyLogo = {
  readonly logoUrl?: Maybe<Scalars['String']>;
};

export type AgencyUser = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type AgencyUserWithRole = {
  readonly companyName?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly role: UserRoles;
};

export type AgencyWithAdvertisers = {
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly hubspotId?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly internalNetsuiteId?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly users: ReadonlyArray<AgencyUser>;
};

export type AgencyWithNSVerification = {
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly hubspotId?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly internalNetsuiteId?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly users: Scalars['Int'];
  readonly verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
};

export type AiChatModelName = {
  readonly modelName: AIModelName;
};

export type AllAccountAnalyticsForAdminPayload = {
  readonly accounts: ReadonlyArray<AccountAnalyticsPayload>;
};

export type AllAccountAnalyticsForAdvertiserPayload = {
  readonly accounts: ReadonlyArray<AccountAnalyticsForAdvertiserPayload>;
};

export type AllAdvertisersForSearch = {
  readonly advertisers: ReadonlyArray<AdvertiserForSearch>;
  readonly includedAdvertisers: ReadonlyArray<AdvertiserForSearch>;
};

/** An enumeration. */
export enum AllCampaignStatus {
  DEMO = 'DEMO',
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  LOST = 'LOST',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  REVIEWING = 'REVIEWING',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING'
}

export type AllCampaignsReportSummary = {
  readonly chartData: ReadonlyArray<CampaignChartReport>;
  readonly currency: Scalars['String'];
  readonly summary?: Maybe<CampaignReportSummary>;
  readonly total: Scalars['Int'];
};

export enum AllCampaignsSearchJobsOrderBy {
  CREATED_DATE = 'CREATED_DATE',
  HIGH_REWARD = 'HIGH_REWARD',
  ORDER_NUMBER = 'ORDER_NUMBER',
  POPULAR = 'POPULAR'
}

export type AllInfluencerV4 = {
  readonly influencers: ReadonlyArray<InfluencerV4>;
  readonly totalCount: Scalars['Int'];
};

export type AllStaffDetail = {
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type AllTiktokAdReportSummary = {
  readonly adCampaignId?: Maybe<Scalars['String']>;
  readonly adCampaignName?: Maybe<Scalars['String']>;
  readonly adGroupId?: Maybe<Scalars['String']>;
  readonly adGroupName?: Maybe<Scalars['String']>;
  readonly chartData: ReadonlyArray<TikTokAdChartReport>;
  readonly currency: Scalars['String'];
  readonly summary?: Maybe<TikTokAdReportSummary>;
  readonly total: Scalars['Int'];
};

export type AllUserDetail = {
  readonly advertisersCount: Scalars['Int'];
  readonly country: CountryName;
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly role: UserRoles;
};

export type AllowEngagementInfluencerProposalList = {
  readonly ok: Scalars['Boolean'];
};

export type AllowEngagementInfluencerProposalListInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
};

export type AllowInfluencersUserProposal = {
  readonly ok: Scalars['Boolean'];
};

export type AllowInfluencersUserProposalInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
};

export type AnalyticSubscriptionAvailableFeature = {
  readonly compareEnabled: Scalars['Boolean'];
  readonly dashboardEnabled: Scalars['Boolean'];
  readonly hashtagsEnabled: Scalars['Boolean'];
  readonly interactionEnabled: Scalars['Boolean'];
  readonly trendEnabled: Scalars['Boolean'];
};

export type AnalyticSubscriptionAvailableFeatures = {
  readonly compareEnabled: Scalars['Boolean'];
  readonly dashboardEnabled: Scalars['Boolean'];
  readonly hashtagsEnabled: Scalars['Boolean'];
  readonly interactionEnabled: Scalars['Boolean'];
};

export type AnalyticSubscriptionCapabilities = {
  readonly maxCompareAccounts: Scalars['Int'];
  readonly maxHashtags: Scalars['Int'];
};

export type AnalyticSubscriptionCapability = {
  readonly maxCompareAccounts: Scalars['Int'];
  readonly maxHashtags: Scalars['Int'];
};

/** An enumeration. */
export enum AnalyticsAdvertiserPlanFilter {
  BASIC = 'BASIC',
  ENTERPRISE = 'ENTERPRISE',
  FREE = 'FREE',
  TRIAL = 'TRIAL'
}

export enum AnalyticsAuthSocialAccountType {
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE'
}

export enum AnalyticsRedirectType {
  ANALYTICS_CONNECT = 'ANALYTICS_CONNECT',
  ANALYTICS_LIST_CONNECT = 'ANALYTICS_LIST_CONNECT',
  ANALYTICS_SIGNIN = 'ANALYTICS_SIGNIN'
}

export type AnalyticsSelectSubscriptionPlan = {
  readonly ok: Scalars['Boolean'];
};

export type AnalyticsSelectSubscriptionPlanInput = {
  /** advertiser's system id */
  readonly advertiserId: Scalars['Int'];
  /** the selected plan */
  readonly plan: AnalyticsSubscriptionPlanType;
};

/**  Stores a redirect URL that FE uses to communicate with social network APIs */
export type AnalyticsSocialAuthRedirectUrlPayload = {
  readonly redirectUri: Scalars['String'];
};

export type AnalyticsSubscriptionPlan = {
  readonly availableFeatures: AnalyticSubscriptionAvailableFeatures;
  readonly capabilities: AnalyticSubscriptionCapabilities;
  /** subscription plan ID */
  readonly id: Scalars['Int'];
  readonly type: AnalyticsSubscriptionPlanType;
};

export type AnalyticsSubscriptionPlanDetails = {
  readonly availableFeatures: AnalyticSubscriptionAvailableFeature;
  readonly capabilities: AnalyticSubscriptionCapability;
  readonly id: Scalars['Int'];
  readonly type: AnalyticsSubscriptionPlanType;
};

export type AnalyticsSubscriptionPlanName = {
  readonly name?: Maybe<Scalars['String']>;
  readonly type: AnalyticsSubscriptionPlanType;
};

export enum AnalyticsSubscriptionPlanType {
  BASIC = 'BASIC',
  BASIC_PLUS = 'BASIC_PLUS',
  BASIC_PLUS_PLUS = 'BASIC_PLUS_PLUS',
  BASIC_V2 = 'BASIC_V2',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  ENTERPRISE_PLUS_PLUS = 'ENTERPRISE_PLUS_PLUS',
  ENTERPRISE_V2 = 'ENTERPRISE_V2',
  FREE = 'FREE',
  TRIAL = 'TRIAL'
}

export type AnyCreatorCategory = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type AnyXAccountInfoPayload = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export enum AnyXAuthSocialAccountType {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE'
}

export type AnyXCheckConnectedSocialAccountsForLinkBioPayload = {
  readonly facebooks: ReadonlyArray<AnyXConnectedFacebookAccountAndPageForLinkBio>;
  readonly instagramAccounts: ReadonlyArray<AnyXConnectedSocialAccountForLinkBio>;
  readonly tiktokAccounts: ReadonlyArray<AnyXConnectedSocialAccountForLinkBio>;
  readonly twitterAccounts: ReadonlyArray<AnyXConnectedSocialAccountForLinkBio>;
  readonly youtubeAccounts: ReadonlyArray<AnyXConnectedSocialAccountForLinkBio>;
};

export type AnyXCheckConnectedSocialAccountsPayload = {
  readonly facebookPages: ReadonlyArray<AnyXConnectedFacebookPage>;
  readonly facebooks: ReadonlyArray<AnyXConnectedFacebookAccount>;
  readonly instagramAccounts: ReadonlyArray<AnyXConnectedInstagramAccount>;
  readonly tiktokAccounts: ReadonlyArray<AnyXConnectedTikTokAccount>;
  readonly twitterAccounts: ReadonlyArray<AnyXConnectedSocialAccount>;
  readonly youtubeAccounts: ReadonlyArray<AnyXConnectedYoutubeAccount>;
};

/**  ANY-X */
export type AnyXCheckEmailInput = {
  readonly email: Scalars['String'];
};

export type AnyXCheckEmailPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AnyXConnectedChildYoutubeAccount = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly channelId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type AnyXConnectedFacebookAccount = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly idForDisconnect: Scalars['String'];
  readonly name: Scalars['String'];
  readonly needReconnect: Scalars['Boolean'];
  readonly url?: Maybe<Scalars['String']>;
};

export type AnyXConnectedFacebookAccountAndPageForLinkBio = {
  readonly avatar: Scalars['String'];
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly needReconnect: Scalars['Boolean'];
  readonly post?: Maybe<FacebookPostForLinkBio>;
  readonly socialAccountType: SocialAccountType;
  readonly url: Scalars['String'];
};

export type AnyXConnectedFacebookPage = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly idForDisconnect: Scalars['String'];
  readonly name: Scalars['String'];
  readonly needReconnect: Scalars['Boolean'];
  readonly url?: Maybe<Scalars['String']>;
};

export type AnyXConnectedInstagramAccount = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly idForDisconnect: Scalars['String'];
  readonly isBusiness?: Maybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly needReconnect: Scalars['Boolean'];
  readonly url?: Maybe<Scalars['String']>;
};

export type AnyXConnectedSocialAccount = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly idForDisconnect: Scalars['String'];
  readonly name: Scalars['String'];
  readonly url?: Maybe<Scalars['String']>;
};

export type AnyXConnectedSocialAccountForLinkBio = {
  readonly avatar: Scalars['String'];
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly needReconnect: Scalars['Boolean'];
  readonly url: Scalars['String'];
};

export type AnyXConnectedTikTokAccount = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly idForDisconnect: Scalars['String'];
  readonly name: Scalars['String'];
  readonly needReconnect: Scalars['Boolean'];
  readonly url?: Maybe<Scalars['String']>;
};

export type AnyXConnectedYoutubeAccount = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly childAccounts: ReadonlyArray<AnyXConnectedChildYoutubeAccount>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly idForDisconnect: Scalars['String'];
  readonly name: Scalars['String'];
  readonly url?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum AnyXEngagementCampaignStatus {
  DRAFTING = 'DRAFTING',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  POSTING = 'POSTING',
  REVIEWING = 'REVIEWING',
  WARNING_EFFECTIVE = 'WARNING_EFFECTIVE',
  WARNING_REVIEWING = 'WARNING_REVIEWING'
}

/** An enumeration. */
export enum AnyXMarketplaceCampaignStatus {
  APPROVED = 'APPROVED',
  ASSESSING = 'ASSESSING',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  POSTING = 'POSTING',
  REVIEWING = 'REVIEWING',
  SHIPPED = 'SHIPPED',
  WAITING_FOR_SHIPMENT = 'WAITING_FOR_SHIPMENT',
  WARNING_EFFECTIVE = 'WARNING_EFFECTIVE'
}

export type AnyXSignIn = {
  readonly token: Scalars['String'];
};

export type AnyXSignUp = {
  readonly email?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly uuid: Scalars['ID'];
};

export type AnyXSocialAuthConnectForLinkBioInput = {
  readonly callbackUrl?: InputMaybe<Scalars['String']>;
  readonly provider: AnyXAuthSocialAccountType;
  readonly response: Scalars['String'];
};

export type AnyXSocialAuthConnectForLinkBioPayload = {
  readonly connectedAccounts: ReadonlyArray<ConnectedAccountForLinkBio>;
};

export type AnyXSocialAuthConnectInput = {
  readonly callbackUrl?: InputMaybe<Scalars['String']>;
  readonly provider: AnyXAuthSocialAccountType;
  readonly response: Scalars['String'];
};

export type AnyXSocialAuthConnectPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AnyXSocialAuthDisconnectInput = {
  readonly socialAccountId: Scalars['ID'];
  readonly socialAccountType: SocialAccountType;
};

export type AnyXSocialAuthDisconnectPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AnyXSocialAuthReconnectForLinkBioInput = {
  readonly callbackUrl?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly response: Scalars['String'];
  readonly socialAccountType: SocialAccountType;
};

export type AnyXSocialAuthReconnectForLinkBioPayload = {
  readonly accounts: ReadonlyArray<ReconnectedAccountForLinkBio>;
};

export type AnyXSocialAuthReconnectTTCMInput = {
  readonly id: Scalars['ID'];
  readonly response: Scalars['String'];
};

export type AnyXSocialAuthReconnectTTCMPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AnyXSocialAuthReconnectTikTokInput = {
  readonly callbackUrl?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly response: Scalars['String'];
};

export type AnyXSocialAuthReconnectTikTokPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AnyXSocialAuthSignInInput = {
  readonly callbackUrl?: InputMaybe<Scalars['String']>;
  readonly provider: AnyXAuthSocialAccountType;
  readonly response: Scalars['String'];
};

export type AnyXSocialAuthSignInInstagramLineInput = {
  readonly fbOauth?: InputMaybe<FacebookOauthInput>;
  readonly lineIDToken: Scalars['String'];
};

export type AnyXSocialAuthSignInInstagramLinePayload = {
  readonly ok: Scalars['Boolean'];
};

export type AnyXSocialAuthSignInOrSignUpInput = {
  readonly callbackUrl?: InputMaybe<Scalars['String']>;
  readonly provider: AnyXAuthSocialAccountType;
  readonly response: Scalars['String'];
};

export type AnyXSocialAuthSignInOrSignUpPayload = {
  readonly hasIgAccount: Scalars['Boolean'];
  readonly signIn?: Maybe<AnyXSignIn>;
  readonly signUp?: Maybe<AnyXSignUp>;
};

export type AnyXSocialAuthSignInPayload = {
  readonly token: Scalars['String'];
};

export type AnyXSocialAuthSignUpInput = {
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly countryId: Scalars['String'];
  readonly dateOfBirth: Scalars['String'];
  readonly email: Scalars['String'];
  readonly gender: Genders;
  readonly name: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
  readonly provider: AnyXAuthSocialAccountType;
  readonly regionId?: InputMaybe<Scalars['Int']>;
  readonly uuid: Scalars['ID'];
};

export type AnyXSocialAuthSignUpPartnerInput = {
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly countryId: Scalars['String'];
  readonly dateOfBirth: Scalars['String'];
  readonly email: Scalars['String'];
  readonly gender: Genders;
  readonly name: Scalars['String'];
  readonly partnerUserOwnerHash: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
  readonly provider: AnyXAuthSocialAccountType;
  readonly regionId: Scalars['Int'];
  readonly uuid: Scalars['ID'];
};

export type AnyXSocialAuthSignUpPartnerPayload = {
  readonly token: Scalars['String'];
};

export type AnyXSocialAuthSignUpPayload = {
  readonly token: Scalars['String'];
};

export type AnyXSocialAuthSignUpTalentInput = {
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly countryId: Scalars['String'];
  readonly dateOfBirth: Scalars['String'];
  readonly email: Scalars['String'];
  readonly gender: Genders;
  readonly name: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
  readonly provider: AnyXAuthSocialAccountType;
  readonly regionId: Scalars['Int'];
  readonly talentAgencyOwnerHash: Scalars['String'];
  readonly uuid: Scalars['ID'];
};

export type AnyXSocialAuthSignUpTalentPayload = {
  readonly token: Scalars['String'];
};

/** An enumeration. */
export enum AppName {
  ANYCREATOR = 'ANYCREATOR',
  ANYTAG = 'ANYTAG'
}

export enum AppType {
  ENGAGEMENT_CAMPAIGN = 'ENGAGEMENT_CAMPAIGN',
  MARKETPLACE_CAMPAIGN = 'MARKETPLACE_CAMPAIGN'
}

export type ApplicantInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly appliedStatus: MarketplaceCampaignAppliedStatus;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly country: CountryNameForInfluencer;
  readonly email?: Maybe<Scalars['Boolean']>;
  readonly engagement: InfluencerEngagement;
  readonly facebook?: Maybe<SocialAccountV2>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  readonly id: Scalars['Int'];
  readonly instagram?: Maybe<SocialAccountV2>;
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
  readonly name: Scalars['String'];
  readonly status: SocialAccountStatus;
  readonly tiktok?: Maybe<SocialAccountV2>;
  readonly twitter?: Maybe<SocialAccountV2>;
  readonly youtube?: Maybe<SocialAccountV2>;
};

export type ApproveApplicantInfluencers = {
  readonly ok: Scalars['Boolean'];
};

export type ApproveApplicantInfluencersInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly marketplaceCampaignId: Scalars['Int'];
};

export type ApproveAutoManagedPayout = {
  readonly ok: Scalars['Boolean'];
};

export type ApproveAutoManagedPayoutInput = {
  readonly coveragePeriods: ReadonlyArray<AutoManagedPayoutPeriodInput>;
  readonly id: Scalars['Int'];
};

export type ApproveCmsInvoice = {
  readonly ok: Scalars['Boolean'];
};

export type ApproveCmsInvoiceInput = {
  readonly month: Scalars['Date'];
};

export type ApproveMarketplace = {
  readonly ok: Scalars['Boolean'];
};

export type ApproveMarketplaceAffiliatePayout = {
  readonly ok: Scalars['Boolean'];
};

export type ApproveMarketplaceAffiliatePayoutInput = {
  readonly coveragePeriods: ReadonlyArray<MarketplaceAffiliatePayoutPeriodInput>;
  readonly marketplaceId: Scalars['Int'];
};

export type ApproveMarketplaceDraftPost = {
  readonly ok: Scalars['Boolean'];
};

export type ApproveMarketplaceDraftPostInput = {
  readonly draftId: Scalars['Int'];
};

export type ApproveMarketplaceInput = {
  readonly id: Scalars['Int'];
};

export type ApproveTikTokSpecialCampaign = {
  readonly ok: Scalars['Boolean'];
};

export type ApproveTikTokSpecialCampaignInput = {
  readonly campaignId: Scalars['Int'];
};

export type AssignAdvertiserForStaffs = {
  readonly ok: Scalars['Boolean'];
};

export type AssignAdvertiserForStaffsInput = {
  readonly advertiserId: Scalars['Int'];
  readonly staffUserIds: ReadonlyArray<Scalars['Int']>;
};

export type AssignChatPICInput = {
  readonly chatId: Scalars['String'];
};

export type AssignChatPICPayload = {
  readonly ok: Scalars['Boolean'];
};

export type AssociateCompany = {
  readonly companyType: AssociateCompanyType;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly usersCount: Scalars['Int'];
};

/** An enumeration. */
export enum AssociateCompanyType {
  ADVERTISER = 'ADVERTISER',
  AGENCY = 'AGENCY'
}

export type AudienceBreakdownAgeGenderRates = {
  readonly ageGroups: ReadonlyArray<AudienceBreakdownAgeGroup>;
  readonly femaleRates: ReadonlyArray<Scalars['Float']>;
  readonly maleRates: ReadonlyArray<Scalars['Float']>;
};

export type AudienceBreakdownAgeGroup = {
  readonly end: Scalars['Int'];
  readonly start: Scalars['Int'];
};

export type AudienceBreakdownAgeRates = {
  readonly ageGroups: ReadonlyArray<AudienceBreakdownAgeGroup>;
  readonly rates: ReadonlyArray<Scalars['Float']>;
};

export type AudienceBreakdownAreaRate = {
  readonly countryId: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type AudienceBreakdownGenderRates = {
  readonly femaleRate: Scalars['Float'];
  readonly maleRate: Scalars['Float'];
};

export type Auth0ToLocalJWT = {
  readonly email: Scalars['String'];
  readonly hash?: Maybe<Scalars['String']>;
  readonly role: UserRoles;
  readonly token: Scalars['String'];
  readonly userId: Scalars['Int'];
};

export type Auth0ToLocalJWTInput = {
  readonly idToken: Scalars['String'];
};

export enum AuthSocialAccountType {
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  TTCM = 'TTCM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE'
}

export type AuthTiktokBusinessAccount = {
  readonly ok: Scalars['Boolean'];
};

export type AuthTiktokBusinessAccountInput = {
  readonly authCode: Scalars['String'];
};

export enum AutoManagedAffiliateCampaignStatus {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  INACTIVE = 'INACTIVE'
}

export type AutoManagedAffiliateCommissionRateInput = {
  readonly commissionRate: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
};

export type AutoManagedCampaignCountry = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type AutoManagedCampaignForInfluencerSearchJobPayload = {
  readonly campaignUrl?: Maybe<Scalars['String']>;
  readonly commissionRate?: Maybe<Scalars['Float']>;
  readonly createdDate: Scalars['String'];
  readonly currencyId?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isJoined: Scalars['Boolean'];
  readonly minimumPaymentAmount: Scalars['Float'];
  readonly requirement?: Maybe<Scalars['String']>;
  readonly serviceInfo?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['String']>;
  readonly status: AutoManagedAffiliateCampaignStatus;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly type: AutoManagedCampaignType;
};

export type AutoManagedCampaignForInfluencerYourJobPayload = {
  readonly campaignUrl?: Maybe<Scalars['String']>;
  readonly commissionRate?: Maybe<Scalars['Float']>;
  readonly createdDate: Scalars['String'];
  readonly currencyId?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isJoined: Scalars['Boolean'];
  readonly minimumPaymentAmount: Scalars['Float'];
  readonly requirement?: Maybe<Scalars['String']>;
  readonly serviceInfo?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['String']>;
  readonly status: AutoManagedAffiliateCampaignStatus;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingUrl: Scalars['String'];
  readonly type: AutoManagedCampaignType;
};

export type AutoManagedCampaignInfoPayload = {
  readonly autoManagedCampaignType: AutoManagedCampaignType;
  readonly category: Category;
  readonly commissionRate: Scalars['Float'];
  readonly country: AutoManagedCampaignCountry;
  readonly endDate?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly landingPageUrl?: Maybe<Scalars['String']>;
  readonly numberOfJoinedCreators: Scalars['Int'];
  readonly orders: Scalars['Int'];
  readonly price: AutoManagedCampaignMoney;
  readonly revenue: AutoManagedCampaignMoney;
  readonly sales: AutoManagedCampaignMoney;
  readonly sellerName: Scalars['String'];
  readonly startDate?: Maybe<Scalars['String']>;
  readonly status: AutoManagedCampaignStatus;
  readonly thumbnailUrl?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type AutoManagedCampaignMoney = {
  readonly amount: Scalars['Float'];
  readonly currencyId: Scalars['String'];
};

export type AutoManagedCampaignPayload = {
  readonly autoManagedCampaignType: AutoManagedCampaignType;
  readonly campaignThumbnailUrl?: Maybe<Scalars['String']>;
  readonly campaignUrl?: Maybe<Scalars['String']>;
  readonly category: Category;
  readonly commissionRate?: Maybe<Scalars['Float']>;
  readonly country: Country;
  readonly createdDate: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly endDate?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly influencerCommissionRate?: Maybe<Scalars['Float']>;
  readonly landingPageUrl?: Maybe<Scalars['String']>;
  readonly marginRate?: Maybe<Scalars['Float']>;
  readonly requirement?: Maybe<Scalars['String']>;
  readonly sellerName?: Maybe<Scalars['String']>;
  readonly serviceInfo?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['String']>;
  readonly status: AutoManagedCampaignStatus;
  readonly title: Scalars['String'];
};

export enum AutoManagedCampaignStatus {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  INACTIVE = 'INACTIVE'
}

export enum AutoManagedCampaignType {
  LAZADA = 'LAZADA',
  SHOPEE = 'SHOPEE'
}

export type AutoManagedCommissionInfluencer = {
  readonly avatar: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly endCoverageDate: Scalars['Date'];
  readonly fixedDate: Scalars['Date'];
  readonly influencerId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly postId: Scalars['Int'];
  readonly revenue: Scalars['Float'];
  readonly startCoverageDate: Scalars['Date'];
  readonly status: AutoManagedCommissionStatus;
};

/** An enumeration. */
export enum AutoManagedCommissionStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED'
}

export type AutoManagedInfluencerInfo = {
  readonly avatar: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type AutoManagedJoinedInfluencer = {
  readonly influencer: AutoManagedInfluencerInfo;
  readonly joinedDate: Scalars['Date'];
  readonly promotionMethods: ReadonlyArray<CampaignPromotionMethod>;
};

export type AutoManagedJoinedMethodsInput = {
  readonly campaignId: Scalars['ID'];
};

export type AutoManagedJoinedMethodsPayload = {
  readonly emailNewsLetters: ReadonlyArray<JoinedPromotionMethodEmailNewsLetterPayload>;
  readonly facebookAccount?: Maybe<JoinedPromotionMethodSocialAccountPayload>;
  readonly facebookPages: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
  readonly instagramAccounts: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
  readonly mobileApps: ReadonlyArray<JoinedPromotionMethodMobileAppPayload>;
  readonly offlines: ReadonlyArray<JoinedPromotionMethodOfflinePayload>;
  readonly podCasts: ReadonlyArray<JoinedPromotionMethodPodCastPayload>;
  readonly tiktokAccounts: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
  readonly twitterAccounts: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
  readonly websites: ReadonlyArray<JoinedPromotionMethodWebsitePayload>;
  readonly youtubeAccounts: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
};

export type AutoManagedJoinedMethodsPayloadV2 = {
  readonly methods: ReadonlyArray<CampaignPromotionMethod>;
};

export type AutoManagedPayoutPeriodInput = {
  readonly fixedDate: Scalars['Date'];
  readonly postId: Scalars['Int'];
};

export type AutoManagedReportByDate = {
  readonly clicks: Scalars['Int'];
  readonly conversions: Scalars['Int'];
  readonly cost: Scalars['Float'];
  readonly cvr?: Maybe<Scalars['Float']>;
  readonly date: Scalars['Date'];
  readonly grossProfit: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly sales: Scalars['Float'];
};

export type AutoManagedReportByInfluencer = {
  readonly clicks: Scalars['Int'];
  readonly commissionRate: Scalars['Float'];
  readonly conversions: Scalars['Int'];
  readonly cost: Scalars['Float'];
  readonly cvr?: Maybe<Scalars['Float']>;
  readonly influencerCost: Scalars['Float'];
  readonly influencerInfo: CampaignInfluencerInfo;
  readonly promotionMethods: ReadonlyArray<CampaignPromotionMethod>;
  readonly sales: Scalars['Float'];
};

export type AutoManagedReportForInfluencer = {
  readonly clicks: Scalars['Int'];
  readonly commission: Scalars['Float'];
  readonly conversions: Scalars['Int'];
  readonly date: Scalars['Date'];
};

export type AutoManagedReportSummary = {
  readonly clicks: Scalars['Int'];
  readonly conversions: Scalars['Int'];
  readonly cost: Scalars['Float'];
  readonly cvr?: Maybe<Scalars['Float']>;
  readonly grossProfit: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly joinedCount: Scalars['Int'];
  readonly sales: Scalars['Float'];
};

export type AutoManagedReportSummaryForInfluencer = {
  readonly clicks: Scalars['Int'];
  readonly commission: Scalars['Float'];
  readonly conversions: Scalars['Int'];
  readonly currency: Scalars['String'];
};

export type AvailableInfluencer = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly selected: Scalars['Boolean'];
};

export type AvailableInfluencersForStaffPayload = {
  readonly influencers: ReadonlyArray<AvailableInfluencer>;
};

export type Bank = {
  readonly hasBranches: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type BankBranch = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type BankBranchForInfluencer = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type BankForInfluencer = {
  readonly hasBranches: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type BasicAccountAnalyticsPayload = {
  readonly brandAccountId: Scalars['Int'];
  readonly brandAccountName: Scalars['String'];
  readonly country: Scalars['String'];
  readonly socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  readonly subscriptionPlan: AnalyticsSubscriptionPlanDetails;
};

/** An enumeration. */
export enum BcaRequestStatus {
  APPROVED = 'APPROVED',
  REQUESTED = 'REQUESTED',
  REVOKED = 'REVOKED'
}

export type BillingInformation = {
  readonly cardBrand?: Maybe<Scalars['String']>;
  readonly cardCountry?: Maybe<Scalars['String']>;
  readonly cardExpMonth?: Maybe<Scalars['Int']>;
  readonly cardExpYear?: Maybe<Scalars['Int']>;
  readonly cardLast4?: Maybe<Scalars['String']>;
};

export type BioAnalyticsCity = {
  readonly city: Scalars['String'];
  readonly count: Scalars['Float'];
};

export type BioAnalyticsCountry = {
  readonly count: Scalars['Float'];
  readonly country: Scalars['String'];
};

export type BioAnalyticsOtherLinkClicks = {
  readonly clicks: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly linkName: Scalars['String'];
};

export type BioAnalyticsReferral = {
  readonly count: Scalars['Int'];
  readonly url: Scalars['String'];
};

export type BioAnalyticsSocialMediaClicks = {
  readonly clicks: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly linkName: Scalars['String'];
  readonly socialMediaType: BioSocialMediaType;
};

export type BioAnalyticsTotalClicks = {
  readonly clicks: Scalars['Int'];
  readonly sectionType: BioSectionType;
};

export enum BioButtonStyle {
  BLACK_FULL_ROUNDED_EDGE_BUTTON = 'BLACK_FULL_ROUNDED_EDGE_BUTTON',
  BLACK_ROUNDED_EDGE_BUTTON = 'BLACK_ROUNDED_EDGE_BUTTON',
  BLACK_SHARP_EDGE_BUTTON = 'BLACK_SHARP_EDGE_BUTTON',
  WHITE_FULL_ROUNDED_EDGE_BUTTON = 'WHITE_FULL_ROUNDED_EDGE_BUTTON',
  WHITE_ROUNDED_EDGE_BUTTON = 'WHITE_ROUNDED_EDGE_BUTTON',
  WHITE_SHARP_EDGE_BUTTON = 'WHITE_SHARP_EDGE_BUTTON'
}

export enum BioFontStyle {
  KAISEI_DECOL = 'KAISEI_DECOL',
  KOSUGI = 'KOSUGI',
  M_PLUS_1 = 'M_PLUS_1',
  NOTO_SANS_JP = 'NOTO_SANS_JP',
  NOTO_SERIF_JP = 'NOTO_SERIF_JP',
  POTTA_ONE = 'POTTA_ONE',
  SAWARABI_MINCHO = 'SAWARABI_MINCHO',
  SHIPPORI_MINCHO = 'SHIPPORI_MINCHO',
  TRAIN_ONE = 'TRAIN_ONE'
}

export type BioImage = {
  readonly image: Scalars['String'];
  readonly linkUrl?: Maybe<Scalars['String']>;
};

export type BioImageInput = {
  readonly image: Scalars['String'];
  readonly linkUrl?: InputMaybe<Scalars['String']>;
};

export type BioInfluencerProfile = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export enum BioLinkIcon {
  BOOK = 'BOOK',
  CALENDAR = 'CALENDAR',
  CART = 'CART',
  CHAT = 'CHAT',
  CLOUD = 'CLOUD',
  COFFEE = 'COFFEE',
  DOCUMENT = 'DOCUMENT',
  ENVELOPE = 'ENVELOPE',
  HEART = 'HEART',
  HOUSE = 'HOUSE',
  KEY = 'KEY',
  LOCATION = 'LOCATION',
  MOON = 'MOON',
  MUSIC_NOTE = 'MUSIC_NOTE',
  PHONE = 'PHONE',
  SMILE_EMOJI = 'SMILE_EMOJI',
  STAR = 'STAR',
  SUN = 'SUN',
  USER = 'USER',
  VIDEO_CAMERA = 'VIDEO_CAMERA'
}

export type BioLinkInput = {
  readonly linkIcon?: InputMaybe<BioLinkIcon>;
  readonly linkName: Scalars['String'];
  readonly linkUrl: Scalars['String'];
};

export type BioProfileInput = {
  readonly avatar?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BioSection = {
  readonly id: Scalars['Int'];
  readonly image?: Maybe<BioImage>;
  readonly isPublic: Scalars['Boolean'];
  readonly link?: Maybe<BioUrlLink>;
  readonly order: Scalars['Int'];
  readonly profile?: Maybe<BioInfluencerProfile>;
  readonly sectionType: BioSectionType;
  readonly socialMedia?: Maybe<BioSocialMedia>;
  readonly text?: Maybe<BioText>;
};

export enum BioSectionType {
  IMAGE = 'IMAGE',
  LINK = 'LINK',
  PROFILE = 'PROFILE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  TEXT = 'TEXT'
}

export type BioSectionV2 = {
  readonly id: Scalars['Int'];
  readonly image?: Maybe<BioImage>;
  readonly link?: Maybe<BioUrlLink>;
  readonly order: Scalars['Int'];
  readonly sectionType: BioSectionType;
  readonly socialMedia?: Maybe<BioSocialMedia>;
  readonly text?: Maybe<BioText>;
};

export type BioSectionsInput = {
  readonly id?: InputMaybe<Scalars['Int']>;
  readonly image?: InputMaybe<BioImageInput>;
  readonly isPublic: Scalars['Boolean'];
  readonly link?: InputMaybe<BioLinkInput>;
  readonly order: Scalars['Int'];
  readonly profile?: InputMaybe<BioProfileInput>;
  readonly sectionType: BioSectionType;
  readonly socialMedia?: InputMaybe<BioSocialMediaInput>;
  readonly text?: InputMaybe<BioTextInput>;
};

export type BioSectionsInputV2 = {
  readonly id?: InputMaybe<Scalars['Int']>;
  readonly image?: InputMaybe<BioImageInput>;
  readonly link?: InputMaybe<BioLinkInput>;
  readonly order: Scalars['Int'];
  readonly sectionType: BioSectionType;
  readonly socialMedia?: InputMaybe<BioSocialMediaInput>;
  readonly text?: InputMaybe<BioTextInput>;
};

export type BioSocialMedia = {
  readonly linkName: Scalars['String'];
  readonly linkUrl: Scalars['String'];
  readonly socialAccountId?: Maybe<Scalars['String']>;
  readonly socialAccountType?: Maybe<SocialAccountType>;
  readonly socialMedia: BioSocialMediaType;
};

export type BioSocialMediaInput = {
  readonly linkName: Scalars['String'];
  readonly linkUrl: Scalars['String'];
  readonly socialAccountId?: InputMaybe<Scalars['String']>;
  readonly socialAccountType?: InputMaybe<SocialAccountType>;
  readonly socialMedia: BioSocialMediaType;
};

export enum BioSocialMediaType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LINE = 'LINE',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE'
}

export type BioText = {
  readonly text: Scalars['String'];
};

export type BioTextInput = {
  readonly text: Scalars['String'];
};

export enum BioTheme {
  THEME_1 = 'THEME_1',
  THEME_2 = 'THEME_2',
  THEME_3 = 'THEME_3',
  THEME_4 = 'THEME_4',
  THEME_5 = 'THEME_5',
  THEME_6 = 'THEME_6',
  THEME_7 = 'THEME_7',
  THEME_8 = 'THEME_8',
  THEME_9 = 'THEME_9',
  THEME_10 = 'THEME_10',
  THEME_11 = 'THEME_11'
}

export enum BioThemeV2 {
  THEME2_1 = 'THEME2_1',
  THEME2_2 = 'THEME2_2',
  THEME2_3 = 'THEME2_3',
  THEME2_4 = 'THEME2_4',
  THEME2_5 = 'THEME2_5',
  THEME2_6 = 'THEME2_6',
  THEME2_7 = 'THEME2_7',
  THEME2_8 = 'THEME2_8',
  THEME2_9 = 'THEME2_9',
  THEME2_10 = 'THEME2_10',
  THEME2_11 = 'THEME2_11',
  THEME2_12 = 'THEME2_12',
  THEME2_13 = 'THEME2_13',
  THEME2_14 = 'THEME2_14',
  THEME2_15 = 'THEME2_15',
  THEME2_16 = 'THEME2_16',
  THEME2_17 = 'THEME2_17'
}

export type BioUrlLink = {
  readonly linkIcon?: Maybe<BioLinkIcon>;
  readonly linkName: Scalars['String'];
  readonly linkUrl: Scalars['String'];
};

export type BoostTikTokAdPostInput = {
  readonly adAccountSystemId: Scalars['Int'];
  readonly adGroupId: Scalars['String'];
  readonly adName: Scalars['String'];
  readonly authCode: Scalars['String'];
  readonly callToAction: Scalars['Boolean'];
  readonly landingPageUrl?: InputMaybe<Scalars['String']>;
  readonly postSystemId: Scalars['String'];
  readonly tkSpecialCampaignId: Scalars['Int'];
};

export type BoostTiktokAdPost = {
  readonly ok: Scalars['Boolean'];
};

export type BotTextMessageEvent = ChatEvent & {
  readonly authorId: Scalars['Int'];
  readonly authorName?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly text: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type BrandAccount = {
  readonly avatar: Scalars['String'];
  readonly averageEngagementRate: Scalars['Float'];
  /** mentioned brand account id */
  readonly id: Scalars['Int'];
  readonly mentionPostsCount: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly postIds: ReadonlyArray<Scalars['Int']>;
};

export type BulkEngagementProposalSocialAccount = {
  readonly influencerId: Scalars['Int'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialAccountMedia: EngagementProposalSocialMediaType;
};

export type BulkPackageSocialAccount = {
  readonly influencerId: Scalars['Int'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialAccountType: SocialAccountType;
};

export type BulkUpdateEngagementInfluencersBudget = {
  readonly ok: Scalars['Boolean'];
};

export type BulkUpdateEngagementInfluencersBudgetInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerCost: Scalars['Float'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly netBudget: Scalars['Float'];
  readonly profit: Scalars['Float'];
};

export type BulkUpdateEngagementSocialAccountsBudget = {
  readonly ok: Scalars['Boolean'];
};

export type BulkUpdateEngagementSocialAccountsBudgetInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerCost: Scalars['Float'];
  readonly netBudget: Scalars['Float'];
  readonly profit: Scalars['Float'];
  readonly socialAccounts: ReadonlyArray<BulkEngagementProposalSocialAccount>;
};

export type BulkUpdatePackageProposalAccountsBudget = {
  readonly ok: Scalars['Boolean'];
};

export type BulkUpdatePackageProposalAccountsBudgetInput = {
  readonly budget: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly packageId: Scalars['Int'];
  readonly profit: Scalars['Float'];
  readonly socialAccounts: ReadonlyArray<BulkPackageSocialAccount>;
};

export type BulkUpdatePackageProposalInfluencersBudget = {
  readonly ok: Scalars['Boolean'];
};

export type BulkUpdatePackageProposalInfluencersBudgetInput = {
  readonly budget: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly packageId: Scalars['Int'];
  readonly profit: Scalars['Float'];
};

export type BulkUpdateUserProposalAccountsBudget = {
  readonly ok: Scalars['Boolean'];
};

export type BulkUpdateUserProposalAccountsBudgetInput = {
  readonly budget: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly profit: Scalars['Float'];
  readonly socialAccounts: ReadonlyArray<BulkUserProposalSocialAccount>;
};

export type BulkUpdateUserProposalInfluencersBudget = {
  readonly ok: Scalars['Boolean'];
};

export type BulkUpdateUserProposalInfluencersBudgetInput = {
  readonly budget: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly profit: Scalars['Float'];
};

export type BulkUserProposalSocialAccount = {
  readonly influencerId: Scalars['Int'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialAccountMedia: UserProposalSocialMediaType;
};

export type ButtonMessageInput = {
  readonly cards: ReadonlyArray<CardInput>;
};

export type ButtonMessagePayload = {
  readonly cards: ReadonlyArray<CardPayload>;
};

export enum CRMChatTypeFilter {
  ACTIVATED = 'ACTIVATED',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME'
}

export type CampaignCategoryName = {
  readonly categoryName: Scalars['String'];
  readonly id: Scalars['Int'];
};

export type CampaignChartReport = {
  readonly averageViewDurationSeconds?: Maybe<Scalars['Int']>;
  readonly comment?: Maybe<Scalars['Int']>;
  readonly cost: Scalars['Float'];
  readonly date: Scalars['Date'];
  readonly engagement: Scalars['Float'];
  readonly like?: Maybe<Scalars['Int']>;
  readonly share?: Maybe<Scalars['Int']>;
  readonly view?: Maybe<Scalars['Int']>;
};

export type CampaignChartReportForAllTime = {
  readonly cost: Scalars['Float'];
  readonly engagement: Scalars['Float'];
  readonly midDate: Scalars['Date'];
};

export type CampaignCreator = {
  readonly id: Scalars['Int'];
  readonly role: UserRoles;
};

export type CampaignCreatorWithName = {
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly role: UserRoles;
};

export type CampaignDailyStats = {
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  readonly cost: Scalars['Float'];
  readonly costsPerAction?: Maybe<Scalars['Float']>;
  readonly costsPerClick?: Maybe<Scalars['Float']>;
  /** For Vietnam campaigns */
  readonly costsPerComment?: Maybe<Scalars['Float']>;
  readonly costsPerEngagement?: Maybe<Scalars['Float']>;
  readonly costsPerFollower?: Maybe<Scalars['Float']>;
  readonly costsPerImpressions?: Maybe<Scalars['Float']>;
  readonly costsPerLike?: Maybe<Scalars['Float']>;
  readonly costsPerReach?: Maybe<Scalars['Float']>;
  readonly costsPerSaved?: Maybe<Scalars['Float']>;
  readonly costsPerShare?: Maybe<Scalars['Float']>;
  readonly costsPerView?: Maybe<Scalars['Float']>;
  readonly date: Scalars['Date'];
  readonly engagement: Scalars['Float'];
  readonly engagementRate?: Maybe<Scalars['Float']>;
  readonly followers: Scalars['Int'];
  readonly impressions: Scalars['Int'];
  readonly like: Scalars['Int'];
  /** For admin or staff user, and EngagementCampaign */
  readonly net?: Maybe<Scalars['Float']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly profit?: Maybe<Scalars['Float']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly profitRate?: Maybe<Scalars['Float']>;
  readonly reach: Scalars['Float'];
  readonly saved?: Maybe<Scalars['Int']>;
  readonly share: Scalars['Int'];
  readonly view: Scalars['Int'];
};

export type CampaignDetailReport = {
  readonly age35UpPercentage?: Maybe<Scalars['Float']>;
  readonly age1824Percentage?: Maybe<Scalars['Float']>;
  readonly age2534Percentage?: Maybe<Scalars['Float']>;
  readonly averageViewDurationMinutes?: Maybe<Scalars['Int']>;
  readonly averageViewDurationPercentage?: Maybe<Scalars['Float']>;
  readonly averageViewDurationSeconds?: Maybe<Scalars['Int']>;
  readonly back?: Maybe<Scalars['Int']>;
  readonly campaign: CampaignReportCampaignInfo;
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  readonly cost: Scalars['Float'];
  readonly costsPerAction?: Maybe<Scalars['Float']>;
  readonly costsPerClick?: Maybe<Scalars['Float']>;
  /** For Vietnam campaigns */
  readonly costsPerComment?: Maybe<Scalars['Float']>;
  readonly costsPerEngagement?: Maybe<Scalars['Float']>;
  readonly costsPerFollower?: Maybe<Scalars['Float']>;
  readonly costsPerImpressions?: Maybe<Scalars['Float']>;
  readonly costsPerLike?: Maybe<Scalars['Float']>;
  readonly costsPerOrder?: Maybe<Scalars['Float']>;
  readonly costsPerReach?: Maybe<Scalars['Float']>;
  readonly costsPerSale?: Maybe<Scalars['Float']>;
  readonly costsPerSaleReferral?: Maybe<Scalars['Float']>;
  readonly costsPerSaleTune?: Maybe<Scalars['Float']>;
  readonly costsPerSaved?: Maybe<Scalars['Float']>;
  readonly costsPerShare?: Maybe<Scalars['Float']>;
  readonly costsPerView?: Maybe<Scalars['Float']>;
  readonly daily: ReadonlyArray<CampaignDailyStats>;
  readonly dislikes?: Maybe<Scalars['Int']>;
  readonly engagement: Scalars['Float'];
  /** Engagement Rate by Followers for IG, FB, FB Page, X, Threads */
  readonly engagementRate?: Maybe<Scalars['Float']>;
  /** Engagement Rate by Views for TikTok, YT, Douyin */
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
  readonly exited?: Maybe<Scalars['Int']>;
  readonly femalePercentage?: Maybe<Scalars['Float']>;
  readonly followers: Scalars['Float'];
  readonly forward?: Maybe<Scalars['Int']>;
  readonly impressionRate?: Maybe<Scalars['Float']>;
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly impressionsFromDiscovery?: Maybe<Scalars['Int']>;
  readonly impressionsFromHashtag?: Maybe<Scalars['Int']>;
  readonly impressionsFromHome?: Maybe<Scalars['Int']>;
  readonly impressionsFromOther?: Maybe<Scalars['Int']>;
  readonly impressionsFromProfile?: Maybe<Scalars['Int']>;
  readonly interaction?: Maybe<Scalars['Int']>;
  readonly like?: Maybe<Scalars['Int']>;
  readonly linkClicks?: Maybe<Scalars['Int']>;
  readonly malePercentage?: Maybe<Scalars['Float']>;
  readonly navigation?: Maybe<Scalars['Int']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly net?: Maybe<Scalars['Float']>;
  readonly nextStory?: Maybe<Scalars['Int']>;
  readonly nonFollowerReach?: Maybe<Scalars['Int']>;
  readonly nonFollowerReachRate?: Maybe<Scalars['Float']>;
  readonly orders?: Maybe<Scalars['Int']>;
  readonly profileActivity?: Maybe<Scalars['Int']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly profit?: Maybe<Scalars['Float']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly profitRate?: Maybe<Scalars['Float']>;
  readonly reach?: Maybe<Scalars['Float']>;
  readonly reachRate?: Maybe<Scalars['Float']>;
  /** @deprecated please use sales_tune or sales_referral */
  readonly sales?: Maybe<Scalars['Float']>;
  readonly salesReferral?: Maybe<Scalars['Float']>;
  readonly salesTune?: Maybe<Scalars['Float']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly share?: Maybe<Scalars['Int']>;
  readonly stickerTaps?: Maybe<Scalars['Int']>;
  readonly view?: Maybe<Scalars['Float']>;
};

export type CampaignFilter = {
  readonly id: Scalars['Int'];
  readonly type: CampaignType;
};

export type CampaignForInfluencerForYourJob = {
  readonly autoManaged?: Maybe<NewAutoManagedCampaignForInfluencerForYourJob>;
  readonly createdDate: Scalars['Date'];
  readonly engagement?: Maybe<NewEngagementCampaignForInfluencerForYourJob>;
  readonly marketplace?: Maybe<NewMarketplaceCampaignForInfluencerForYourJob>;
  readonly tiktokSpecial?: Maybe<NewTiktokSpecialCampaignForInfluencerForYourJob>;
  readonly type: CampaignType;
};

export type CampaignForInfluencerForYourJobCounts = {
  readonly finishedCampaignCount: Scalars['Int'];
  readonly progressCampaignCount: Scalars['Int'];
};

export type CampaignForPostFilter = {
  readonly campaigns: ReadonlyArray<CampaignForSearch>;
  readonly includedCampaigns?: Maybe<ReadonlyArray<CampaignForSearch>>;
};

export type CampaignForSearch = {
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly id: Scalars['Int'];
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly status: CampaignStatusForReport;
  readonly title: Scalars['String'];
  readonly type: CampaignType;
};

export type CampaignGridReport = {
  /** For all campaign SNSs */
  readonly engagementRate: PostsMetricsGridReport;
  readonly reachRate?: Maybe<PostsMetricsGridReport>;
  readonly sales?: Maybe<PostsMetricsGridReport>;
  readonly saveRate?: Maybe<PostsMetricsGridReport>;
  readonly shareRate?: Maybe<PostsMetricsGridReport>;
  readonly viewRate?: Maybe<PostsMetricsGridReport>;
};

export type CampaignIdentifierInput = {
  readonly campaignId?: InputMaybe<Scalars['Int']>;
  readonly campaignType?: InputMaybe<CampaignIdentifierType>;
};

export enum CampaignIdentifierType {
  ENGAGEMENT = 'ENGAGEMENT',
  MARKETPLACE = 'MARKETPLACE'
}

export type CampaignInfluencerInfo = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly thumbNail: Scalars['String'];
};

export type CampaignInfluencerReport = {
  readonly InternalInfo?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly influencerInfo: CampaignInfluencerInfo;
  readonly orderedSummaryDaily?: Maybe<ReadonlyArray<CampaignSummaryDaily>>;
  readonly postCount?: Maybe<Scalars['Int']>;
  readonly postReports?: Maybe<ReadonlyArray<NewCampaignPostReport>>;
  readonly summary: NewCampaignStatsItemRow;
  /** @deprecated use ordered_summary_daily */
  readonly summaryDaily?: Maybe<ReadonlyArray<CampaignSummaryDaily>>;
};

export type CampaignPayment = {
  readonly campaignType: CampaignType;
  /** Marketplace OR AutoManaged campaign */
  readonly id: Scalars['Int'];
  readonly revenue: Scalars['Float'];
  readonly title: Scalars['String'];
};

export type CampaignPost = {
  readonly actions?: Maybe<Scalars['Int']>;
  readonly affiliatePaidStatus?: Maybe<CommissionStatus>;
  readonly age18to24Percentage?: Maybe<Scalars['Float']>;
  readonly age25to34Percentage?: Maybe<Scalars['Float']>;
  readonly age35upPercentage?: Maybe<Scalars['Float']>;
  readonly audienceRetention?: Maybe<Scalars['Float']>;
  readonly avatar: Scalars['String'];
  readonly averageViewDurationPercentage?: Maybe<Scalars['Float']>;
  readonly averageViewDurationSeconds?: Maybe<Scalars['Int']>;
  readonly averageViewTime?: Maybe<Scalars['Float']>;
  readonly back?: Maybe<Scalars['Int']>;
  readonly campaignIdentifier: ReadCampaignIdentifier;
  readonly campaignName: Scalars['String'];
  readonly clicks: Scalars['Int'];
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly conversions?: Maybe<Scalars['Int']>;
  readonly cost?: Maybe<Scalars['Float']>;
  readonly currencyId: Scalars['String'];
  readonly dislikes?: Maybe<Scalars['Int']>;
  readonly engagement: Scalars['Int'];
  readonly engagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
  readonly exited?: Maybe<Scalars['Int']>;
  readonly femalePercentage?: Maybe<Scalars['Float']>;
  readonly followers: Scalars['Int'];
  readonly forward?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly impressions: Scalars['Int'];
  readonly impressionsClickThroughRate?: Maybe<Scalars['Float']>;
  readonly impressionsFromDiscovery?: Maybe<Scalars['Int']>;
  readonly impressionsFromHashtag?: Maybe<Scalars['Int']>;
  readonly impressionsFromHome?: Maybe<Scalars['Int']>;
  readonly impressionsFromOther?: Maybe<Scalars['Int']>;
  readonly impressionsFromProfile?: Maybe<Scalars['Int']>;
  readonly impressionsRate: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
  readonly influencerName: Scalars['String'];
  readonly interaction?: Maybe<Scalars['Int']>;
  readonly likes: Scalars['Int'];
  readonly linkClicks?: Maybe<Scalars['Int']>;
  readonly malePercentage?: Maybe<Scalars['Float']>;
  readonly navigation?: Maybe<Scalars['Int']>;
  readonly nextStory?: Maybe<Scalars['Int']>;
  readonly nonFollowerReach?: Maybe<Scalars['Int']>;
  readonly nonFollowerReachRate?: Maybe<Scalars['Float']>;
  readonly orders?: Maybe<Scalars['Int']>;
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly profileActivity?: Maybe<Scalars['Int']>;
  readonly reach: Scalars['Int'];
  readonly reachRate: Scalars['Float'];
  readonly revenue: Scalars['Float'];
  readonly sales: Scalars['Float'];
  readonly salesCost?: Maybe<Scalars['Float']>;
  readonly salesReferral?: Maybe<Scalars['Float']>;
  readonly saved: Scalars['Int'];
  readonly shares: Scalars['Int'];
  readonly socialPostType: CampaignPostSocialType;
  readonly stickerTaps?: Maybe<Scalars['Int']>;
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly totalPlayTime?: Maybe<Scalars['Int']>;
  readonly videoCompletionRate?: Maybe<Scalars['Float']>;
  readonly views: Scalars['Int'];
};

export type CampaignPostGridInfo = {
  readonly content: Scalars['String'];
  readonly hasTrafficSource?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['Int'];
  readonly influencerId: Scalars['Int'];
  readonly insightDataAcquisition?: Maybe<Scalars['Date']>;
  readonly postUrl: Scalars['String'];
  readonly socialAccountAvatar?: Maybe<Scalars['String']>;
  readonly socialAccountId: Scalars['Int'];
  readonly socialMedia: CampaignSocialMediaType;
  readonly socialUsername: Scalars['String'];
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type CampaignPostInfo = {
  readonly content: Scalars['String'];
  readonly hasTrafficSource?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['Int'];
  readonly influencerId: Scalars['Int'];
  readonly insightDataAcquisition?: Maybe<Scalars['Date']>;
  readonly postUrl: Scalars['String'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialMedia: CampaignSocialMediaType;
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type CampaignPostMetrics = {
  readonly actions?: Maybe<Scalars['Float']>;
  readonly age18to24Percentage?: Maybe<Scalars['Float']>;
  readonly age25to34Percentage?: Maybe<Scalars['Float']>;
  readonly age35upPercentage?: Maybe<Scalars['Float']>;
  readonly audienceRetention?: Maybe<Scalars['Float']>;
  readonly averageViewDurationPercentage?: Maybe<Scalars['Float']>;
  readonly averageViewDurationSeconds?: Maybe<Scalars['Float']>;
  readonly averageViewTime?: Maybe<Scalars['Float']>;
  readonly back?: Maybe<Scalars['Float']>;
  readonly clicks: Scalars['Float'];
  readonly comments: Scalars['Float'];
  readonly conversions?: Maybe<Scalars['Float']>;
  readonly cost?: Maybe<Scalars['Float']>;
  readonly dislikes?: Maybe<Scalars['Float']>;
  readonly engagement: Scalars['Float'];
  readonly engagementFollowersRate: Scalars['Float'];
  readonly engagementViewsRate: Scalars['Float'];
  readonly exited?: Maybe<Scalars['Float']>;
  readonly femalePercentage?: Maybe<Scalars['Float']>;
  readonly followers: Scalars['Float'];
  readonly forward?: Maybe<Scalars['Float']>;
  readonly impressions: Scalars['Float'];
  readonly impressionsClickThroughRate?: Maybe<Scalars['Float']>;
  readonly impressionsFromDiscovery?: Maybe<Scalars['Float']>;
  readonly impressionsFromHashtag?: Maybe<Scalars['Float']>;
  readonly impressionsFromHome?: Maybe<Scalars['Float']>;
  readonly impressionsFromOther?: Maybe<Scalars['Float']>;
  readonly impressionsFromProfile?: Maybe<Scalars['Float']>;
  readonly impressionsRate: Scalars['Float'];
  readonly interaction?: Maybe<Scalars['Float']>;
  readonly likes: Scalars['Float'];
  readonly linkClicks?: Maybe<Scalars['Float']>;
  readonly malePercentage?: Maybe<Scalars['Float']>;
  readonly navigation?: Maybe<Scalars['Float']>;
  readonly nextStory?: Maybe<Scalars['Float']>;
  readonly nonFollowerReach?: Maybe<Scalars['Float']>;
  readonly nonFollowerReachRate?: Maybe<Scalars['Float']>;
  readonly orders?: Maybe<Scalars['Float']>;
  readonly profileActivity?: Maybe<Scalars['Float']>;
  readonly reach: Scalars['Float'];
  readonly reachRate: Scalars['Float'];
  readonly revenue: Scalars['Float'];
  readonly sales: Scalars['Float'];
  readonly salesCost?: Maybe<Scalars['Float']>;
  readonly salesReferral?: Maybe<Scalars['Float']>;
  readonly saved: Scalars['Float'];
  readonly shares: Scalars['Float'];
  readonly stickerTaps?: Maybe<Scalars['Float']>;
  readonly totalPlayTime?: Maybe<Scalars['Float']>;
  readonly videoCompletionRate?: Maybe<Scalars['Float']>;
  readonly views: Scalars['Float'];
};

export type CampaignPostOrderBy = {
  readonly field?: InputMaybe<CampaignPostSortField>;
  readonly order?: InputMaybe<Order>;
};

export type CampaignPostReportGrid = {
  /** For all SNSs */
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  /** For all SNSs */
  readonly engagementRate: Scalars['Float'];
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly like?: Maybe<Scalars['Int']>;
  readonly orders?: Maybe<Scalars['Int']>;
  readonly postInfo: CampaignPostGridInfo;
  /** Rate of current Comparable metrics */
  readonly rate?: Maybe<Scalars['Float']>;
  readonly reach?: Maybe<Scalars['Int']>;
  /** @deprecated please use sales_tune or sales_referral */
  readonly sales?: Maybe<Scalars['Float']>;
  readonly salesReferral?: Maybe<Scalars['Float']>;
  readonly salesTune?: Maybe<Scalars['Float']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly share?: Maybe<Scalars['Int']>;
  readonly view?: Maybe<Scalars['Int']>;
};

export enum CampaignPostSocialType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE'
}

export enum CampaignPostSortField {
  ACTIONS = 'ACTIONS',
  AFFILIATE_PAID_STATUS = 'AFFILIATE_PAID_STATUS',
  AGE_18_TO_24_PERCENTAGE = 'AGE_18_TO_24_PERCENTAGE',
  AGE_25_TO_34_PERCENTAGE = 'AGE_25_TO_34_PERCENTAGE',
  AGE_35_UP_PERCENTAGE = 'AGE_35_UP_PERCENTAGE',
  AUDIENCE_RETENTION = 'AUDIENCE_RETENTION',
  AVERAGE_VIEW_DURATION_PERCENTAGE = 'AVERAGE_VIEW_DURATION_PERCENTAGE',
  AVERAGE_VIEW_DURATION_SECONDS = 'AVERAGE_VIEW_DURATION_SECONDS',
  AVERAGE_VIEW_TIME = 'AVERAGE_VIEW_TIME',
  BACK = 'BACK',
  CLICKS = 'CLICKS',
  COMMENTS = 'COMMENTS',
  CONVERSIONS = 'CONVERSIONS',
  COST = 'COST',
  DATE = 'DATE',
  DISLIKES = 'DISLIKES',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_FOLLOWERS_RATE = 'ENGAGEMENT_FOLLOWERS_RATE',
  ENGAGEMENT_VIEWS_RATE = 'ENGAGEMENT_VIEWS_RATE',
  EXITED = 'EXITED',
  FEMALE_PERCENTAGE = 'FEMALE_PERCENTAGE',
  FOLLOWERS = 'FOLLOWERS',
  FORWARD = 'FORWARD',
  IMPRESSIONS = 'IMPRESSIONS',
  IMPRESSIONS_CLICK_THROUGH_RATE = 'IMPRESSIONS_CLICK_THROUGH_RATE',
  IMPRESSIONS_FROM_DISCOVERY = 'IMPRESSIONS_FROM_DISCOVERY',
  IMPRESSIONS_FROM_HASHTAG = 'IMPRESSIONS_FROM_HASHTAG',
  IMPRESSIONS_FROM_HOME = 'IMPRESSIONS_FROM_HOME',
  IMPRESSIONS_FROM_OTHER = 'IMPRESSIONS_FROM_OTHER',
  IMPRESSIONS_FROM_PROFILE = 'IMPRESSIONS_FROM_PROFILE',
  IMPRESSIONS_RATE = 'IMPRESSIONS_RATE',
  INTERACTION = 'INTERACTION',
  LIKES = 'LIKES',
  LINK_CLICKS = 'LINK_CLICKS',
  MALE_PERCENTAGE = 'MALE_PERCENTAGE',
  NAVIGATION = 'NAVIGATION',
  NEXT_STORY = 'NEXT_STORY',
  NON_FOLLOWER_REACH = 'NON_FOLLOWER_REACH',
  NON_FOLLOWER_REACH_RATE = 'NON_FOLLOWER_REACH_RATE',
  ORDERS = 'ORDERS',
  PROFILE_ACTIVITY = 'PROFILE_ACTIVITY',
  REACH = 'REACH',
  REACH_RATE = 'REACH_RATE',
  REVENUE = 'REVENUE',
  SALES = 'SALES',
  SALES_COST = 'SALES_COST',
  SALES_REFERRAL = 'SALES_REFERRAL',
  SAVED = 'SAVED',
  SHARES = 'SHARES',
  STICKER_TAPS = 'STICKER_TAPS',
  TOTAL_PLAY_TIME = 'TOTAL_PLAY_TIME',
  VIDEO_COMPLETION_RATE = 'VIDEO_COMPLETION_RATE',
  VIEWS = 'VIEWS'
}

/** An enumeration. */
export enum CampaignPostStatusForInfluencer {
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  REVIEWING = 'REVIEWING',
  WARNING = 'WARNING'
}

export type CampaignPosts = {
  readonly posts: ReadonlyArray<CampaignPost>;
  readonly summary: CampaignPostsSummary;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type CampaignPostsSummary = {
  readonly average: CampaignPostMetrics;
  readonly currencyId: Scalars['String'];
  readonly sum: CampaignPostMetrics;
};

export enum CampaignPromotionMethod {
  EMAIL_NEWSLETTER = 'EMAIL_NEWSLETTER',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  MOBILE_APP = 'MOBILE_APP',
  OFFLINE = 'OFFLINE',
  OTHER_SOCIAL_MEDIA = 'OTHER_SOCIAL_MEDIA',
  PODCAST = 'PODCAST',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  WEBSITE = 'WEBSITE',
  YOUTUBE = 'YOUTUBE'
}

export type CampaignReportCampaignInfo = {
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly hasPostTracking?: Maybe<Scalars['Boolean']>;
  readonly hasTrafficSource?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['Int'];
  /** Campaign detail type for MKP */
  readonly mkpDetailType?: Maybe<MarketplaceCampaignDetailType>;
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly status: CampaignStatusForReport;
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly type: CampaignType;
};

export type CampaignReportCampaignInfoForInfluencer = {
  readonly endDate: Scalars['Date'];
  readonly id: Scalars['Int'];
  readonly socialMedias: ReadonlyArray<Maybe<CampaignSocialMediaType>>;
  readonly startDate: Scalars['Date'];
  readonly status: CampaignStatusForInfluencer;
  readonly suspendedDate?: Maybe<Scalars['Date']>;
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly type: CampaignType;
};

export type CampaignReportOrderBy = {
  readonly field?: InputMaybe<CampaignReportOrderField>;
  readonly order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum CampaignReportOrderField {
  AGE_18_24_PERCENTAGE = 'AGE_18_24_PERCENTAGE',
  AGE_25_34_PERCENTAGE = 'AGE_25_34_PERCENTAGE',
  AGE_35_UP_PERCENTAGE = 'AGE_35_UP_PERCENTAGE',
  AVERAGE_VIEW_DURATION_MINUTES = 'AVERAGE_VIEW_DURATION_MINUTES',
  AVERAGE_VIEW_DURATION_PERCENTAGE = 'AVERAGE_VIEW_DURATION_PERCENTAGE',
  AVERAGE_VIEW_DURATION_SECONDS = 'AVERAGE_VIEW_DURATION_SECONDS',
  AVERAGE_VIEW_TIME = 'AVERAGE_VIEW_TIME',
  BACK = 'BACK',
  CLICK = 'CLICK',
  COMMENT = 'COMMENT',
  CONVERSION = 'CONVERSION',
  COST = 'COST',
  COST_PER_ACTION = 'COST_PER_ACTION',
  COST_PER_CLICK = 'COST_PER_CLICK',
  COST_PER_COMMENT = 'COST_PER_COMMENT',
  COST_PER_ENGAGEMENT = 'COST_PER_ENGAGEMENT',
  COST_PER_FOLLOWER = 'COST_PER_FOLLOWER',
  COST_PER_IMPRESSIONS = 'COST_PER_IMPRESSIONS',
  COST_PER_LIKE = 'COST_PER_LIKE',
  COST_PER_ORDER = 'COST_PER_ORDER',
  COST_PER_REACH = 'COST_PER_REACH',
  COST_PER_SALE = 'COST_PER_SALE',
  COST_PER_SALE_REFERRAL = 'COST_PER_SALE_REFERRAL',
  COST_PER_SALE_TUNE = 'COST_PER_SALE_TUNE',
  COST_PER_SAVED = 'COST_PER_SAVED',
  COST_PER_SHARE = 'COST_PER_SHARE',
  COST_PER_VIEW = 'COST_PER_VIEW',
  DATE = 'DATE',
  DISLIKES = 'DISLIKES',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  ENGAGEMENT_VIEWS_RATE = 'ENGAGEMENT_VIEWS_RATE',
  EXITED = 'EXITED',
  FEMALE_PERCENTAGE = 'FEMALE_PERCENTAGE',
  FOLLOWERS = 'FOLLOWERS',
  FOLLOWER_REACH = 'FOLLOWER_REACH',
  FOLLOWER_REACH_RATE = 'FOLLOWER_REACH_RATE',
  FORWARD = 'FORWARD',
  IMPRESSIONS = 'IMPRESSIONS',
  IMPRESSIONS_FROM_DISCOVERY = 'IMPRESSIONS_FROM_DISCOVERY',
  IMPRESSIONS_FROM_HASHTAG = 'IMPRESSIONS_FROM_HASHTAG',
  IMPRESSIONS_FROM_HOME = 'IMPRESSIONS_FROM_HOME',
  IMPRESSIONS_FROM_OTHER = 'IMPRESSIONS_FROM_OTHER',
  IMPRESSIONS_FROM_PROFILE = 'IMPRESSIONS_FROM_PROFILE',
  IMPRESSION_RATE = 'IMPRESSION_RATE',
  INTERACTION = 'INTERACTION',
  LIKE = 'LIKE',
  LINK_CLICKS = 'LINK_CLICKS',
  MALE_PERCENTAGE = 'MALE_PERCENTAGE',
  NAVIGATION = 'NAVIGATION',
  NET = 'NET',
  NEW_FOLLOWERS = 'NEW_FOLLOWERS',
  NEXT_STORY = 'NEXT_STORY',
  NON_FOLLOWER_REACH = 'NON_FOLLOWER_REACH',
  NON_FOLLOWER_REACH_RATE = 'NON_FOLLOWER_REACH_RATE',
  ORDER = 'ORDER',
  PROFILE_ACTIVITY = 'PROFILE_ACTIVITY',
  PROFIT = 'PROFIT',
  PROFIT_RATE = 'PROFIT_RATE',
  REACH = 'REACH',
  REACH_RATE = 'REACH_RATE',
  SALES = 'SALES',
  SALES_REFERRAL = 'SALES_REFERRAL',
  SALES_TUNE = 'SALES_TUNE',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  STICKER_TAPS = 'STICKER_TAPS',
  TOTAL_PLAY_TIME = 'TOTAL_PLAY_TIME',
  VIDEO_COMPLETION_RATE = 'VIDEO_COMPLETION_RATE',
  VIEW = 'VIEW',
  VIEWS_FROM_FOLLOWING = 'VIEWS_FROM_FOLLOWING',
  VIEWS_FROM_HOME = 'VIEWS_FROM_HOME',
  VIEWS_FROM_OTHER = 'VIEWS_FROM_OTHER',
  VIEWS_FROM_PROFILE = 'VIEWS_FROM_PROFILE',
  VIEWS_FROM_SEARCH = 'VIEWS_FROM_SEARCH'
}

export type CampaignReportSummary = {
  readonly age35UpPercentage?: Maybe<Scalars['Float']>;
  readonly age1824Percentage?: Maybe<Scalars['Float']>;
  readonly age2534Percentage?: Maybe<Scalars['Float']>;
  readonly averageViewDurationMinutes?: Maybe<Scalars['Int']>;
  readonly averageViewDurationPercentage?: Maybe<Scalars['Float']>;
  readonly averageViewDurationSeconds?: Maybe<Scalars['Int']>;
  readonly back?: Maybe<Scalars['Int']>;
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  readonly cost: Scalars['Float'];
  readonly costsPerAction?: Maybe<Scalars['Float']>;
  readonly costsPerClick?: Maybe<Scalars['Float']>;
  /** For Vietnam campaigns */
  readonly costsPerComment?: Maybe<Scalars['Float']>;
  readonly costsPerEngagement?: Maybe<Scalars['Float']>;
  readonly costsPerFollower?: Maybe<Scalars['Float']>;
  readonly costsPerImpressions?: Maybe<Scalars['Float']>;
  readonly costsPerLike?: Maybe<Scalars['Float']>;
  readonly costsPerOrder?: Maybe<Scalars['Float']>;
  readonly costsPerReach?: Maybe<Scalars['Float']>;
  readonly costsPerSale?: Maybe<Scalars['Float']>;
  readonly costsPerSaleReferral?: Maybe<Scalars['Float']>;
  readonly costsPerSaleTune?: Maybe<Scalars['Float']>;
  readonly costsPerSaved?: Maybe<Scalars['Float']>;
  readonly costsPerShare?: Maybe<Scalars['Float']>;
  readonly costsPerView?: Maybe<Scalars['Float']>;
  readonly dislikes?: Maybe<Scalars['Int']>;
  readonly engagement: Scalars['Float'];
  /** Engagement Rate by Followers for IG, FB, FB Page, X, Threads */
  readonly engagementRate?: Maybe<Scalars['Float']>;
  /** Engagement Rate by Views for TikTok, YT, Douyin */
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
  readonly exited?: Maybe<Scalars['Int']>;
  readonly femalePercentage?: Maybe<Scalars['Float']>;
  readonly followers: Scalars['Float'];
  readonly forward?: Maybe<Scalars['Int']>;
  readonly impressionRate?: Maybe<Scalars['Float']>;
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly impressionsFromDiscovery?: Maybe<Scalars['Int']>;
  readonly impressionsFromHashtag?: Maybe<Scalars['Int']>;
  readonly impressionsFromHome?: Maybe<Scalars['Int']>;
  readonly impressionsFromOther?: Maybe<Scalars['Int']>;
  readonly impressionsFromProfile?: Maybe<Scalars['Int']>;
  readonly interaction?: Maybe<Scalars['Int']>;
  readonly like?: Maybe<Scalars['Int']>;
  readonly linkClicks?: Maybe<Scalars['Int']>;
  readonly malePercentage?: Maybe<Scalars['Float']>;
  readonly navigation?: Maybe<Scalars['Int']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly net?: Maybe<Scalars['Float']>;
  readonly nextStory?: Maybe<Scalars['Int']>;
  readonly nonFollowerReach?: Maybe<Scalars['Int']>;
  readonly nonFollowerReachRate?: Maybe<Scalars['Float']>;
  readonly orders?: Maybe<Scalars['Int']>;
  readonly profileActivity?: Maybe<Scalars['Int']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly profit?: Maybe<Scalars['Float']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly profitRate?: Maybe<Scalars['Float']>;
  readonly reach?: Maybe<Scalars['Float']>;
  readonly reachRate?: Maybe<Scalars['Float']>;
  /** @deprecated please use sales_tune or sales_referral */
  readonly sales?: Maybe<Scalars['Float']>;
  readonly salesReferral?: Maybe<Scalars['Float']>;
  readonly salesTune?: Maybe<Scalars['Float']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly share?: Maybe<Scalars['Int']>;
  readonly stickerTaps?: Maybe<Scalars['Int']>;
  readonly view?: Maybe<Scalars['Float']>;
};

export enum CampaignSocialMediaType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  OTHER = 'OTHER',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  UNSELECT = 'UNSELECT',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE'
}

/** An enumeration. */
export enum CampaignStatusForInfluencer {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING'
}

/** An enumeration. */
export enum CampaignStatusForReport {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  OVER_BUDGET = 'OVER_BUDGET'
}

export type CampaignSummaryDaily = {
  readonly date: Scalars['Date'];
  readonly stats: NewCampaignStatsItemRow;
};

export enum CampaignTrackingOption {
  POST = 'POST',
  REFERRAL_CODE = 'REFERRAL_CODE',
  TUNE = 'TUNE'
}

export enum CampaignType {
  AFFILIATE = 'AFFILIATE',
  AUTO_MANAGED = 'AUTO_MANAGED',
  ENGAGEMENT = 'ENGAGEMENT',
  MARKETPLACE = 'MARKETPLACE',
  TIKTOK_SPECIAL = 'TIKTOK_SPECIAL'
}

export type CampaignsForSearchJobPayload = {
  readonly commissionRate?: Maybe<Scalars['Float']>;
  readonly createdDate: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly endDate?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly isAutoInfluencerApproval: Scalars['Boolean'];
  readonly isGiftingCampaign: Scalars['Boolean'];
  readonly isSelectionCampaign: Scalars['Boolean'];
  readonly marketplaceCampaignType?: Maybe<MarketplaceCampaignDetailType>;
  readonly minFollowers?: Maybe<Scalars['Int']>;
  readonly preLaunchDate?: Maybe<Scalars['String']>;
  readonly revenuePerAction?: Maybe<Scalars['Float']>;
  readonly revenuePerClick?: Maybe<Scalars['Float']>;
  readonly revenuePerComment?: Maybe<Scalars['Float']>;
  readonly revenuePerFollower?: Maybe<Scalars['Float']>;
  readonly revenuePerLike?: Maybe<Scalars['Float']>;
  readonly revenuePerOrder?: Maybe<Scalars['Float']>;
  readonly revenuePerPost?: Maybe<Scalars['Float']>;
  readonly revenuePerShare?: Maybe<Scalars['Float']>;
  readonly revenuePerView?: Maybe<Scalars['Float']>;
  /**  null for auto-managed */
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate?: Maybe<Scalars['String']>;
  readonly status: MarketplaceCampaignStatus;
  readonly thumbnail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly trackingOptions: ReadonlyArray<CampaignTrackingOption>;
  readonly type: CampaignType;
};

export type CancelSubscriptionPlan = {
  readonly ok: Scalars['Boolean'];
};

export type CardInput = {
  readonly actionLabels: ReadonlyArray<ActionLabelInput>;
  readonly description?: InputMaybe<TextComponentInput>;
  readonly imageUrl?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<TextComponentInput>;
};

export type CardPayload = {
  readonly actionLabels: ReadonlyArray<ActionLabelPayload>;
  readonly description?: Maybe<TextComponentPayload>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly title?: Maybe<TextComponentPayload>;
};

export type Category = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type CategoryName = {
  readonly description: Scalars['String'];
  readonly id: Scalars['Int'];
};

export type ChangeInstagramUGCManagementPostStatusInput = {
  readonly analyticsAccountId: Scalars['Int'];
  readonly postSnsId: Scalars['String'];
  readonly status: UGCPostStatus;
};

export type ChangeInstagramUGCManagementPostStatusOutput = {
  readonly ok: Scalars['Boolean'];
};

export enum ChangeType {
  ADDRESS1 = 'ADDRESS1',
  ADDRESS2 = 'ADDRESS2',
  BIRTHDAY = 'BIRTHDAY',
  CITY = 'CITY',
  COMMENT = 'COMMENT',
  COUNTRY_ID = 'COUNTRY_ID',
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  GENDER = 'GENDER',
  LAST_NAME = 'LAST_NAME',
  ORDER_ID = 'ORDER_ID',
  PHONE = 'PHONE',
  PROVINCE = 'PROVINCE',
  TAGS = 'TAGS',
  ZIP = 'ZIP'
}

export type ChatEvent = {
  readonly id: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type CheckBioLinkNameAvailabilityInput = {
  readonly linkName: Scalars['String'];
};

export type CheckBioLinkNameAvailabilityPayload = {
  readonly isAvailable: Scalars['Boolean'];
};

export type City = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type CityForInfluencer = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type ClearCmsChannelAssetOwner = {
  readonly ok: Scalars['Boolean'];
};

export type ClearCmsChannelAssetOwnerInput = {
  readonly channelId: Scalars['String'];
  /** Asset System Id */
  readonly id: Scalars['Int'];
};

/**  `ClickNotificationByEventIdInput` input defines the uuid string `eventId` to be clicked. */
export type ClickNotificationByEventIdInput = {
  readonly eventId: Scalars['String'];
};

/**  `ClickNotificationByEventIdPayload` payload houses an `ok` boolean to determine the success of the operation. */
export type ClickNotificationByEventIdPayload = {
  readonly ok: Scalars['Boolean'];
};

/**  `ClickNotificationInput` input defines the `notificationId` to be clicked. */
export type ClickNotificationInput = {
  readonly id: Scalars['Long'];
};

/**  `ClickNotificationPayload` payload houses an `ok` boolean to determine the success of the operation. */
export type ClickNotificationPayload = {
  readonly ok: Scalars['Boolean'];
};

export type CloneMarketplace = {
  readonly id: Scalars['Int'];
  readonly ok: Scalars['Boolean'];
};

export type CloneMarketplaceInput = {
  readonly duplicateId: Scalars['Int'];
  readonly title: Scalars['String'];
};

export type CmsAssetShareRateInput = {
  /** Asset System Id */
  readonly id: Scalars['Int'];
  readonly shareRate: Scalars['Float'];
};

export type CmsChannelAssetInput = {
  readonly channelAvatar?: InputMaybe<Scalars['String']>;
  readonly channelId: Scalars['String'];
  readonly channelName?: InputMaybe<Scalars['String']>;
  /** Asset System Id */
  readonly id: Scalars['Int'];
};

export type CmsChannelShareRateInput = {
  /** Channel Id */
  readonly channelId: Scalars['String'];
  readonly shareRate: Scalars['Float'];
};

export type CommentContent = {
  readonly content?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly username?: Maybe<Scalars['String']>;
};

export enum CommissionStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED'
}

export type CompanyAndRolePair = {
  /** id of company */
  readonly companyId: Scalars['Int'];
  /** company role */
  readonly role: UserRoles;
};

export type ConnectFacebookPagesAndInstagramAccountsInput = {
  readonly fbPageIds: ReadonlyArray<Scalars['String']>;
  readonly igUserIds: ReadonlyArray<Scalars['String']>;
};

export type ConnectFacebookPagesAndInstagramAccountsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ConnectNewShopifyAccountPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ConnectPackageToEngagementProposal = {
  readonly ok: Scalars['Boolean'];
};

export type ConnectPackageToEngagementProposalInput = {
  readonly engagementCampaignId: Scalars['Int'];
  readonly influencerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly packageIds: ReadonlyArray<Scalars['Int']>;
};

export type ConnectPackageWithInfluencerAccounts = {
  readonly ok: Scalars['Boolean'];
};

export type ConnectPackageWithInfluencerAccountsInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly packageId: Scalars['Int'];
};

export type ConnectPackageWithSocialAccounts = {
  readonly ok: Scalars['Boolean'];
};

export type ConnectPackageWithSocialAccountsInput = {
  readonly packageId: Scalars['Int'];
  readonly socialPairs?: InputMaybe<ReadonlyArray<SocialAccountPairInput>>;
};

export type ConnectPackageWithUserProposal = {
  readonly ok: Scalars['Boolean'];
};

export type ConnectPackageWithUserProposalInput = {
  readonly packageId: Scalars['Int'];
};

export type ConnectPromotionMethodsInput = {
  readonly methods: ReadonlyArray<PromotionMethodInput>;
};

export type ConnectPromotionMethodsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ConnectableFacebookPage = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly isAlreadyConnected: Scalars['Boolean'];
  readonly name: Scalars['String'];
};

export type ConnectableFacebookPageAccount = {
  readonly avatar: Scalars['String'];
  readonly followers: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly isAlreadyUsed: Scalars['Boolean'];
  readonly name: Scalars['String'];
};

export type ConnectableFacebookPageAndInstagramAccount = {
  readonly igAccount?: Maybe<ConnectableInstagramAccount>;
  readonly page: ConnectableFacebookPage;
};

export type ConnectableFacebookPageMainAccount = {
  readonly avatar: Scalars['String'];
  readonly friendCount: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type ConnectableFacebookPagesAndInstagramAccountsPayload = {
  readonly accounts: ReadonlyArray<ConnectableFacebookPageAndInstagramAccount>;
};

export type ConnectableFacebookPagesPayload = {
  readonly pages: ReadonlyArray<ConnectableFacebookPage>;
};

export type ConnectableInstagramAccount = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly isAlreadyConnected: Scalars['Boolean'];
  readonly name: Scalars['String'];
};

export type ConnectableInstagramAccountWithNoConnectedFlag = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type ConnectableInstagramAccountsPayload = {
  readonly igAccounts: ReadonlyArray<ConnectableInstagramAccountWithNoConnectedFlag>;
};

/**
 *  `id`: social network account id
 *  `avatar`: account's avatar URL from a remote social network server
 *  `name`: account's name
 *  `followersCount`: number of followers
 */
export type ConnectableOauthAccount = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type ConnectableOauthAccountForTalentSignIn = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly status: SocialAccountStatus;
};

export type ConnectableOauthFacebookAccountForTalentSignIn = {
  readonly fbName: Scalars['String'];
  readonly fbUserId: Scalars['String'];
  readonly status: SocialAccountStatus;
};

export type ConnectableOauthInstagramAccountForTalentSignIn = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly status: SocialAccountStatus;
  readonly username: Scalars['String'];
};

/**
 *  `page`: Instagram account's Facebook page
 *  `igAccount`: Instagram account data
 *  `isAlreadyUsed`: a flag that tells if an account has been used/connected/created in analytics by someone else.
 */
export type ConnectableOauthPageAndIgAccount = {
  readonly igAccount: ConnectableOauthAccount;
  readonly isAlreadyUsed: Scalars['Boolean'];
  readonly page: ConnectableOauthAccount;
};

export type ConnectableOauthPageAndIgAccountForSignInFacebook = {
  readonly igAccount?: Maybe<ConnectableOauthAccountForTalentSignIn>;
  readonly isPageAlreadyConnected: Scalars['Boolean'];
  readonly page: ConnectableOauthAccountForTalentSignIn;
};

export type ConnectableOauthPageAndIgAccountForTalentSignIn = {
  readonly igAccount: ConnectableOauthInstagramAccountForTalentSignIn;
  readonly isAlreadyUsed: Scalars['Boolean'];
  readonly page: ConnectableOauthAccountForTalentSignIn;
};

export type ConnectableOauthYouTubeAccountForTalentSignIn = {
  readonly avatar: Scalars['String'];
  readonly channelId: Scalars['String'];
  readonly channelName: Scalars['String'];
  readonly isUsed: Scalars['Boolean'];
  readonly status: SocialAccountStatus;
  readonly subscribersCount: Scalars['Int'];
};

export type ConnectedAccountForLinkBio = {
  readonly avatar: Scalars['String'];
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly socialAccountType: SocialAccountType;
  readonly url: Scalars['String'];
};

export type ConnectedFacebook = {
  readonly fbName: Scalars['String'];
  readonly fbUserId?: Maybe<Scalars['String']>;
  readonly status: SocialAccountStatus;
};

export type ConnectedFacebookPage = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type ConnectedPage = {
  readonly pageId?: Maybe<Scalars['String']>;
  readonly pageName: Scalars['String'];
  readonly status: SocialAccountStatus;
};

export type ConnectedPageAndIgAccount = {
  readonly accountId: Scalars['Int'];
  readonly igName: Scalars['String'];
  readonly igUserId?: Maybe<Scalars['String']>;
  readonly pageId?: Maybe<Scalars['String']>;
  readonly pageName?: Maybe<Scalars['String']>;
  readonly status: SocialAccountStatus;
};

export type ConnectedTwitter = {
  readonly status: SocialAccountStatus;
  readonly twName: Scalars['String'];
  readonly twUserId?: Maybe<Scalars['String']>;
};

export type ConnectedYouTube = {
  readonly channelId: Scalars['String'];
  readonly channelName: Scalars['String'];
  readonly status: SocialAccountStatus;
};

/** An enumeration. */
export enum ConstantHasEstimateMessage {
  YT_HAVE_DATA_PLEASE_WAIT_1_2_DAYS = 'YT_HAVE_DATA_PLEASE_WAIT_1_2_DAYS',
  YT_HAVE_NO_DATA_FOLLOWERS_LESS_THAN_10K = 'YT_HAVE_NO_DATA_FOLLOWERS_LESS_THAN_10K',
  YT_HAVE_NO_DATA_LATEST_POST_OVER_1_YEAR = 'YT_HAVE_NO_DATA_LATEST_POST_OVER_1_YEAR',
  YT_HAVE_NO_DATA_NOT_SUPPORTED_COUNTRY = 'YT_HAVE_NO_DATA_NOT_SUPPORTED_COUNTRY'
}

/**  `CountUnreadNotificationsByAppInput` input accepts `appType`: a enum type which represents the notification scope of application */
export type CountUnreadNotificationsByAppInput = {
  readonly appType: AppType;
};

export type CountriesPayload = {
  readonly countries: ReadonlyArray<Country>;
};

export type Country = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type CountryAndCurrency = {
  readonly currency: Scalars['String'];
  readonly id: Scalars['String'];
  readonly isAvailableCountry: Scalars['Boolean'];
  readonly name: Scalars['String'];
};

export type CountryName = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type CountryNameForInfluencer = {
  readonly id: Scalars['String'];
  readonly isMena: Scalars['Boolean'];
  readonly name: Scalars['String'];
};

export type CreateAdmin = {
  readonly ok: Scalars['Boolean'];
};

export type CreateAdminInput = {
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly hasInvitationEmail: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreateAdvertiser = {
  readonly ok: Scalars['Boolean'];
};

export type CreateAdvertiserInput = {
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateAdvertiserInputV2 = {
  readonly advertiserName: Scalars['String'];
  readonly anyxMasterId?: InputMaybe<Scalars['Int']>;
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId: Scalars['String'];
  readonly hubspotId?: InputMaybe<Scalars['String']>;
  readonly internalNetsuiteId?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateAdvertiserUser = {
  readonly ok: Scalars['Boolean'];
};

export type CreateAdvertiserUserInput = {
  readonly advertiserId: Scalars['Int'];
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly hasInvitationEmail: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly notificationSetting: Scalars['Boolean'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreateAdvertiserWithUser = {
  readonly ok: Scalars['Boolean'];
};

export type CreateAdvertiserWithUserInput = {
  readonly advertiserName: Scalars['String'];
  readonly anyxMasterId?: InputMaybe<Scalars['Int']>;
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId: Scalars['String'];
  readonly hubspotId?: InputMaybe<Scalars['String']>;
  readonly internalNetsuiteId?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<AdvertiserUserInput>;
};

export type CreateAdvertiserWithUserInputV2 = {
  /** Needed when want to create new Advertiser */
  readonly newAdvertiserInput?: InputMaybe<CreateAdvertiserInputV2>;
  /** None means we will create new Advertiser */
  readonly selectedAdvertiserId?: InputMaybe<Scalars['Int']>;
  readonly user?: InputMaybe<AdvertiserUserInput>;
};

export type CreateAdvertiserWithUserV2 = {
  readonly ok: Scalars['Boolean'];
};

export type CreateAgency = {
  readonly ok: Scalars['Boolean'];
};

export type CreateAgencyInput = {
  readonly agencyId: Scalars['Int'];
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly hasInvitationEmail: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreateAgencyWithUser = {
  readonly ok: Scalars['Boolean'];
};

export type CreateAgencyWithUserInput = {
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  readonly agencyName: Scalars['String'];
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly hasInvitationEmail: Scalars['Boolean'];
  readonly hubspotId?: InputMaybe<Scalars['String']>;
  readonly internalNetsuiteId?: InputMaybe<Scalars['String']>;
  readonly logo?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateCreatorStaffInput = {
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
};

export type CreateCreatorStaffPayload = {
  readonly ok: Scalars['Boolean'];
};

export type CreateDraftBoostPost = {
  readonly ok: Scalars['Boolean'];
};

export type CreateDraftBoostPostInput = {
  readonly adAccountSystemId?: InputMaybe<Scalars['Int']>;
  readonly adCampaignId?: InputMaybe<Scalars['String']>;
  readonly adGroupId?: InputMaybe<Scalars['String']>;
  readonly adName?: InputMaybe<Scalars['String']>;
  readonly authPostCode?: InputMaybe<Scalars['String']>;
  readonly callToAction: Scalars['Boolean'];
  readonly landingUrl?: InputMaybe<Scalars['String']>;
  readonly postSystemId: Scalars['String'];
  readonly tkSpecialCampaignId: Scalars['Int'];
};

export type CreateEngagement = {
  readonly id: Scalars['Int'];
  readonly ok: Scalars['Boolean'];
};

export type CreateEngagementInput = {
  readonly adAgencyMarginRate?: InputMaybe<Scalars['Float']>;
  readonly advertiserId: Scalars['Int'];
  readonly budget: Scalars['Float'];
  readonly campaignCategoryId: Scalars['Int'];
  readonly categoryIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly countryIds: ReadonlyArray<Scalars['String']>;
  readonly couponList?: InputMaybe<Scalars['String']>;
  readonly couponUploadFiles?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly defaultPostRequirement?: InputMaybe<Scalars['String']>;
  readonly endDate: Scalars['Date'];
  readonly hashtags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hubspotDealIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly influencerManagementPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly isTtcmCampaign?: InputMaybe<Scalars['Boolean']>;
  readonly landingPageUrl?: InputMaybe<Scalars['String']>;
  readonly marginRate?: InputMaybe<Scalars['Float']>;
  readonly materialUrl?: InputMaybe<Scalars['String']>;
  readonly materials?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly numberOfInfluencers?: InputMaybe<Scalars['Int']>;
  readonly objective: Scalars['String'];
  readonly salesPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly serviceInformation?: InputMaybe<Scalars['String']>;
  readonly serviceUrl?: InputMaybe<Scalars['String']>;
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate: Scalars['Date'];
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly trackingOptions?: InputMaybe<ReadonlyArray<CampaignTrackingOption>>;
};

export type CreateEngagementPostForInfluencerV2 = {
  readonly ok: Scalars['Boolean'];
};

export type CreateEngagementPostInputForInfluencerV2 = {
  readonly campaignId: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly materialsUrl?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly planedPostDate: Scalars['Date'];
  /** Represents social account system id */
  readonly socialAccountId: Scalars['Int'];
  /** Represents social account media type */
  readonly socialAccountMedia: SocialAccountType;
  readonly socialMedia: CampaignSocialMediaType;
};

export type CreateEngagementPostInputV2 = {
  readonly campaignId: Scalars['Int'];
  readonly content?: InputMaybe<Scalars['String']>;
  readonly influencerId: Scalars['Int'];
  readonly insightDataAcquisition?: InputMaybe<Scalars['Date']>;
  readonly insightUrls?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly materialsUrl?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly planedPostDate?: InputMaybe<Scalars['Date']>;
  readonly postUrl?: InputMaybe<Scalars['String']>;
  /** Represents social account system id */
  readonly socialAccountId: Scalars['Int'];
  /** Represents social account media type */
  readonly socialAccountMedia: SocialAccountType;
};

export type CreateEngagementPostV2 = {
  readonly ok: Scalars['Boolean'];
};

export type CreateGeminiThread = {
  readonly threadId: Scalars['Int'];
};

export type CreateInfluencerBioInput = {
  readonly linkName: Scalars['String'];
};

export type CreateInfluencerBioPayload = {
  readonly ok: Scalars['Boolean'];
};

export type CreateInfluencerInputV2 = {
  readonly addressInformation?: InputMaybe<AddressInput>;
  readonly campaignDisplay: Scalars['Boolean'];
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly countryId: Scalars['String'];
  readonly dateOfBirth?: InputMaybe<Scalars['Date']>;
  readonly defaultEngagementSelectionReason?: InputMaybe<Scalars['String']>;
  readonly douyinAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly facebookAccount?: InputMaybe<InfluencerSocialNetworkAccountInput>;
  readonly facebookPages?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly gender: Genders;
  readonly instagramAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly internalMemo?: InputMaybe<Scalars['String']>;
  readonly introduce?: InputMaybe<Scalars['String']>;
  readonly isBrandAccount?: InputMaybe<Scalars['Boolean']>;
  readonly managerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly name: Scalars['String'];
  readonly notificationSetting: Scalars['Boolean'];
  readonly partnerIds: ReadonlyArray<Scalars['Int']>;
  readonly paymentInformation?: InputMaybe<PaymentInformationInput>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly rateCards?: InputMaybe<InfluencerRateCardsInput>;
  readonly regionId?: InputMaybe<Scalars['Int']>;
  readonly tags: ReadonlyArray<Scalars['String']>;
  readonly talentAgencyIds: ReadonlyArray<Scalars['Int']>;
  readonly threadsAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly tiktokAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly twitterAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly xhsAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly youtubeAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
};

export type CreateInfluencerStaffInput = {
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
};

export type CreateInfluencerStaffPayload = {
  readonly ok: Scalars['Boolean'];
};

export type CreateInfluencerV2 = {
  readonly ok: Scalars['Boolean'];
};

export type CreateInstagramUGCManagementHashtagInput = {
  readonly analyticsAccountId: Scalars['Int'];
  readonly hashtag: Scalars['String'];
};

export type CreateInstagramUGCManagementHashtagOutput = {
  readonly ok: Scalars['Boolean'];
};

export type CreateInstagramUserHashtagInput = {
  readonly hashtag: Scalars['String'];
};

export type CreateInstagramUserHashtagOutput = {
  readonly ok: Scalars['Boolean'];
};

export type CreateLineBroadcastMessageInput = {
  readonly deliveryTiming: DeliveryTimingItemInput;
  readonly lineContent: ReadonlyArray<LineContentItemInput>;
  readonly recipient: RecipientItemInput;
  readonly title: Scalars['String'];
};

export type CreateLineBroadcastMessagePayload = {
  readonly messageId: Scalars['String'];
};

export type CreateLineChannelInput = {
  readonly liffId: Scalars['String'];
  readonly lineChannelId: Scalars['String'];
  readonly lineChannelSecret: Scalars['String'];
  readonly lineKid: Scalars['String'];
  readonly lineLoginChannelId: Scalars['String'];
  readonly publicKeyId: Scalars['Long'];
};

export type CreateLineChannelPayload = {
  readonly ok: Scalars['Boolean'];
};

export type CreateMarketplace = {
  readonly ok: Scalars['Boolean'];
};

export type CreateMarketplaceDraftPost = {
  readonly ok: Scalars['Boolean'];
};

export type CreateMarketplaceDraftPostInput = {
  readonly campaignId: Scalars['Int'];
  readonly caption: Scalars['String'];
  readonly materialsUrl: ReadonlyArray<Scalars['String']>;
};

export type CreateMarketplaceInput = {
  readonly additionalRequirement?: InputMaybe<Scalars['String']>;
  readonly advertiserId: Scalars['Int'];
  readonly agencyMarginRate?: InputMaybe<Scalars['Float']>;
  readonly allowNewInfluencer: Scalars['Boolean'];
  readonly brandName?: InputMaybe<Scalars['String']>;
  readonly budget: Scalars['Float'];
  readonly campaignCategoryId: Scalars['Int'];
  readonly campaignType: MarketplaceCampaignDetailType;
  readonly campaignUrl?: InputMaybe<Scalars['String']>;
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly clickUrl?: InputMaybe<Scalars['String']>;
  /** for TUNE Tracking */
  readonly costPerAction?: InputMaybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerClick?: InputMaybe<Scalars['Float']>;
  readonly costPerComment?: InputMaybe<Scalars['Float']>;
  readonly costPerFollower?: InputMaybe<Scalars['Float']>;
  readonly costPerLike?: InputMaybe<Scalars['Float']>;
  /** for referral code Tracking */
  readonly costPerOrder?: InputMaybe<Scalars['Float']>;
  readonly costPerPost?: InputMaybe<Scalars['Float']>;
  /** for referral code Tracking */
  readonly costPerSaleReferral?: InputMaybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerSaleTune?: InputMaybe<Scalars['Float']>;
  readonly costPerShare?: InputMaybe<Scalars['Float']>;
  readonly costPerView?: InputMaybe<Scalars['Float']>;
  readonly countryId: Scalars['String'];
  readonly couponUploadFiles?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly genders: ReadonlyArray<Genders>;
  readonly hashtags: ReadonlyArray<Scalars['String']>;
  readonly hubspotDealIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly influencerManagementPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly isAllowMultiplePosts?: InputMaybe<Scalars['Boolean']>;
  readonly isAutoInfluencerApproval?: InputMaybe<Scalars['Boolean']>;
  readonly isGiftingCampaign?: InputMaybe<Scalars['Boolean']>;
  readonly landingPageUrl?: InputMaybe<Scalars['String']>;
  readonly marginRate: Scalars['Float'];
  readonly materialUrl?: InputMaybe<Scalars['String']>;
  readonly materials?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly maxAge: Scalars['Int'];
  readonly maxFollowers: Scalars['Int'];
  readonly maximumRevenuePerInfluencer?: InputMaybe<Scalars['Float']>;
  readonly minAge: Scalars['Int'];
  readonly minFollowers: Scalars['Int'];
  readonly mkpServiceCampaignId?: InputMaybe<Scalars['Int']>;
  readonly preLaunchDate?: InputMaybe<Scalars['Date']>;
  readonly productThumbNail?: InputMaybe<Scalars['String']>;
  readonly regionIds: ReadonlyArray<Scalars['Int']>;
  readonly requireDraftPost?: InputMaybe<Scalars['Boolean']>;
  readonly requirement: Scalars['String'];
  readonly salesPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly sampleUrl?: InputMaybe<Scalars['String']>;
  readonly serviceInformation: Scalars['String'];
  readonly socialMedia: CampaignSocialMediaType;
  readonly startDate: Scalars['Date'];
  readonly thumbnails: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingOptions?: InputMaybe<ReadonlyArray<CampaignTrackingOption>>;
};

export type CreateMobileAppVersion = {
  readonly ok: Scalars['Boolean'];
};

export type CreateMobileAppVersionInput = {
  /** for ios */
  readonly buildNumber: Scalars['String'];
  /** semantic versioning */
  readonly version: Scalars['String'];
  /** for Android */
  readonly versionCode: Scalars['String'];
};

export type CreateOpenAIThread = {
  readonly threadId: Scalars['Int'];
};

export type CreatePackage = {
  readonly ok: Scalars['Boolean'];
};

export type CreatePackageInput = {
  readonly companyAndRolePairs: ReadonlyArray<CompanyAndRolePair>;
  readonly countryId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type CreatePackageWithInfluencerAccounts = {
  readonly ok: Scalars['Boolean'];
};

export type CreatePackageWithInfluencerAccountsInput = {
  readonly companyAndRolePairs: ReadonlyArray<CompanyAndRolePair>;
  readonly countryId: Scalars['String'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly name: Scalars['String'];
};

export type CreatePackageWithSocialAccounts = {
  readonly ok: Scalars['Boolean'];
};

export type CreatePackageWithSocialAccountsInput = {
  readonly companyAndRolePairs: ReadonlyArray<CompanyAndRolePair>;
  readonly countryId: Scalars['String'];
  readonly name: Scalars['String'];
  readonly socialPairs?: InputMaybe<ReadonlyArray<SocialAccountPairInput>>;
};

export type CreatePackageWithUserProposal = {
  readonly ok: Scalars['Boolean'];
};

export type CreatePackageWithUserProposalInput = {
  readonly companyAndRolePairs: ReadonlyArray<CompanyAndRolePair>;
  readonly countryId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type CreatePartnerUser = {
  readonly ok: Scalars['Boolean'];
};

export type CreatePartnerUserInput = {
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly hasInvitationEmail: Scalars['Boolean'];
  readonly name: Scalars['String'];
  /** Partner Agency Id */
  readonly partnerId: Scalars['Int'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreateSelfRegisteredAdvertiserUser = {
  readonly ok: Scalars['Boolean'];
};

export type CreateSelfRegisteredAdvertiserUserInput = {
  readonly advertiserCompanyName: Scalars['String'];
  readonly advertiserCompanyUrl: Scalars['String'];
  readonly advertiserPhoneNumber: Scalars['String'];
  readonly advertiserType?: InputMaybe<AdvertiserType>;
  /** Represents country of Advertiser and its User */
  readonly countryId: Scalars['String'];
  readonly userEmail: Scalars['String'];
  readonly userName: Scalars['String'];
};

export type CreateSocialAccountAnalyticsAdminInputV2 = {
  readonly accountManagersPICs?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly accountName: Scalars['String'];
  readonly advertiserId: Scalars['Int'];
  readonly facebookPageId?: InputMaybe<Scalars['String']>;
  readonly instagramUserId?: InputMaybe<Scalars['String']>;
  readonly twitterUserId?: InputMaybe<Scalars['String']>;
  readonly youtubeChannelId?: InputMaybe<Scalars['String']>;
};

export type CreateSocialAccountAnalyticsAdminOutputV2 = {
  readonly ok: Scalars['Boolean'];
};

export type CreateSocialAccountAnalyticsAdvertiserInputV2 = {
  readonly accountName: Scalars['String'];
  readonly facebookPageId?: InputMaybe<Scalars['String']>;
  readonly instagramUserId?: InputMaybe<Scalars['String']>;
  readonly twitterUserId?: InputMaybe<Scalars['String']>;
  readonly youtubeChannelId?: InputMaybe<Scalars['String']>;
};

export type CreateSocialAccountAnalyticsAdvertiserOutputV2 = {
  readonly ok: Scalars['Boolean'];
};

export type CreateStaff = {
  readonly ok: Scalars['Boolean'];
};

export type CreateStaffInput = {
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  readonly autoLinkToAdvertiser: Scalars['Boolean'];
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly hasInvitationEmail: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreateTalentAgencyUser = {
  readonly ok: Scalars['Boolean'];
};

export type CreateTalentAgencyUserInput = {
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly hasInvitationEmail: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
  /** Talent Agency Id */
  readonly talentAgencyId: Scalars['Int'];
};

export type CreateTalentAgencyWithUser = {
  readonly ok: Scalars['Boolean'];
};

export type CreateTalentAgencyWithUserInput = {
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  readonly agencyIds: ReadonlyArray<Scalars['Int']>;
  readonly cmsMarginRate?: InputMaybe<Scalars['Float']>;
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly hasInvitationEmail: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly netsuiteId?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly talentAgencyName: Scalars['String'];
};

export type CreateTalentInfluencerInput = {
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly countryId: Scalars['String'];
  readonly dateOfBirth: Scalars['String'];
  readonly defaultEngagementSelectionReason?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly gender: Genders;
  readonly internalMemo?: InputMaybe<Scalars['String']>;
  readonly introduce?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
  readonly rateCards?: InputMaybe<RateCardsInput>;
  readonly regionId: Scalars['Int'];
  readonly selectedSocialAccounts: SelectedSocialAccounts;
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type CreateTalentInfluencerPayload = {
  readonly ok: Scalars['Boolean'];
};

export type CreateTikTokSpecialCampaign = {
  readonly id: Scalars['Int'];
};

export type CreateTikTokSpecialCampaignInput = {
  readonly campaignCategoryId?: InputMaybe<Scalars['Int']>;
  /** nullable for draft */
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly hashtags: ReadonlyArray<Scalars['String']>;
  readonly isDraft: Scalars['Boolean'];
  readonly materialUrl?: InputMaybe<Scalars['String']>;
  readonly materials: ReadonlyArray<Scalars['String']>;
  readonly preLaunchDate?: InputMaybe<Scalars['Date']>;
  readonly productUrl?: InputMaybe<Scalars['String']>;
  readonly requirement: Scalars['String'];
  readonly sampleUrl?: InputMaybe<Scalars['String']>;
  readonly serviceInformation: Scalars['String'];
  /** nullable for draft */
  readonly startDate?: InputMaybe<Scalars['Date']>;
  readonly thumbnails: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type CreateTiktokAdAccount = {
  readonly ok: Scalars['Boolean'];
};

export type CreateTiktokAdAccountInput = {
  readonly bcId: Scalars['String'];
  readonly company: Scalars['String'];
  readonly industryId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly promotionLink: Scalars['String'];
  readonly registeredArea: Scalars['String'];
  readonly timezone: Scalars['String'];
};

export type CreateTiktokBusinessCenter = {
  readonly ok: Scalars['Boolean'];
};

export type CreateTiktokBusinessCenterInput = {
  readonly name: Scalars['String'];
  readonly timezone: Scalars['String'];
};

export type CreateTiktokUserHashtagInput = {
  readonly hashtag: Scalars['String'];
};

export type CreateTiktokUserHashtagOutput = {
  readonly ok: Scalars['Boolean'];
};

export type CreateTiktokUserKeywordInput = {
  readonly keyword: Scalars['String'];
};

export type CreateTiktokUserKeywordOutput = {
  readonly ok: Scalars['Boolean'];
};

export type CreatorAddressPayload = {
  readonly address: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly city: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
};

export type CreatorStaff = {
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type CreatorStaffsCountPayload = {
  readonly total: Scalars['Int'];
};

export type CreatorStaffsInput = {
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
};

export type CreatorStaffsPayload = {
  readonly avatar: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly staffs: ReadonlyArray<CreatorStaff>;
};

export type CurrentUserCurrency = {
  readonly currency: Scalars['String'];
};

export type CustomPermissionLevel = {
  readonly manage?: Maybe<Scalars['Boolean']>;
  readonly manageAndDelete?: Maybe<Scalars['Boolean']>;
  readonly noView?: Maybe<Scalars['Boolean']>;
  readonly view?: Maybe<Scalars['Boolean']>;
};

export type CustomPermissionLevelInput = {
  readonly manage?: InputMaybe<Scalars['Boolean']>;
  readonly manageAndDelete?: InputMaybe<Scalars['Boolean']>;
  readonly noView?: InputMaybe<Scalars['Boolean']>;
  readonly view?: InputMaybe<Scalars['Boolean']>;
};

export type CustomPermissions = {
  readonly advertiser?: Maybe<CustomPermissionLevel>;
  readonly agency?: Maybe<CustomPermissionLevel>;
  readonly analytics?: Maybe<CustomPermissionLevel>;
  readonly customizeUserPermissions?: Maybe<CustomPermissionLevel>;
  readonly engagement?: Maybe<CustomPermissionLevel>;
  readonly influencer?: Maybe<CustomPermissionLevel>;
  readonly influencerAddressAndPaymentInformation?: Maybe<CustomPermissionLevel>;
  readonly influencerPackage?: Maybe<CustomPermissionLevel>;
  readonly marketplace?: Maybe<CustomPermissionLevel>;
  readonly partnerAgency?: Maybe<CustomPermissionLevel>;
  readonly payment?: Maybe<CustomPermissionLevel>;
  readonly report?: Maybe<CustomPermissionLevel>;
  readonly talentAgency?: Maybe<CustomPermissionLevel>;
  readonly user?: Maybe<CustomPermissionLevel>;
};

export type CustomPermissionsInput = {
  readonly advertiser?: InputMaybe<CustomPermissionLevelInput>;
  readonly agency?: InputMaybe<CustomPermissionLevelInput>;
  readonly analytics?: InputMaybe<CustomPermissionLevelInput>;
  readonly customizeUserPermissions?: InputMaybe<CustomPermissionLevelInput>;
  readonly engagement?: InputMaybe<CustomPermissionLevelInput>;
  readonly influencer?: InputMaybe<CustomPermissionLevelInput>;
  readonly influencerAddressAndPaymentInformation?: InputMaybe<CustomPermissionLevelInput>;
  readonly influencerPackage?: InputMaybe<CustomPermissionLevelInput>;
  readonly marketplace?: InputMaybe<CustomPermissionLevelInput>;
  readonly partnerAgency?: InputMaybe<CustomPermissionLevelInput>;
  readonly payment?: InputMaybe<CustomPermissionLevelInput>;
  readonly report?: InputMaybe<CustomPermissionLevelInput>;
  readonly talentAgency?: InputMaybe<CustomPermissionLevelInput>;
  readonly user?: InputMaybe<CustomPermissionLevelInput>;
};

export enum CustomerTagOrder {
  BY_ALPHABETICAL = 'BY_ALPHABETICAL',
  BY_FREQUENTLY_USED = 'BY_FREQUENTLY_USED'
}

export type CustomerTagPayload = {
  readonly name: Scalars['String'];
};

export type DeleteAdvertiser = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteAgency = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteBrandAccountAnalyticsInput = {
  readonly brandAccountId: Scalars['Int'];
};

export type DeleteBrandAccountAnalyticsOutput = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteCommentInput = {
  readonly id: Scalars['Long'];
};

export type DeleteCommentPayload = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteCompareInstagramAccountAnalytics = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteEngagement = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteEngagementInfluencerProposal = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteEngagementInfluencerProposalInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
};

export type DeleteEngagementPostForInfluencer = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteEngagementProposalJoinedAccount = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteEngagementProposalJoinedAccountInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerId: Scalars['Int'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialAccountMedia: EngagementProposalSocialMediaType;
};

export type DeleteEngagementSocialAccountProposal = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteEngagementSocialAccountProposalInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountMedia: SocialAccountType;
};

export type DeleteFanTagsInput = {
  readonly fanIds: ReadonlyArray<Scalars['Long']>;
  readonly tagIds: ReadonlyArray<Scalars['Long']>;
};

export type DeleteFanTagsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteGeminiThread = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteGeminiThreadInput = {
  /** thread system id */
  readonly id: Scalars['Int'];
};

export type DeleteInfluencer = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteInfluencerPackage = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteInstagramCompareAccountAnalyticsInput = {
  /** system id of a compare account that we want to delete */
  readonly compareAccountId: Scalars['Int'];
  /** an analytics account system id the selected compare account was connected to */
  readonly mainAnalyticsAccountId: Scalars['Int'];
};

export type DeleteInstagramHashtagAnalytics = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteInstagramHashtagAnalyticsInput = {
  /** an analytics account system id the selected hashtag was connected to */
  readonly analyticsAccountId: Scalars['Int'];
  /** a hashtag's system id that we want to delete */
  readonly hashtagId: Scalars['Int'];
};

export type DeleteInstagramUGCManagementHashtagInput = {
  readonly analyticsAccountId: Scalars['Int'];
  readonly hashtagSystemId: Scalars['Int'];
};

export type DeleteInstagramUGCManagementHashtagOutput = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteInstagramUserHashtagInput = {
  readonly hashtagId: Scalars['Long'];
};

export type DeleteInstagramUserHashtagOutput = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteMarketplace = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteOpenAIThread = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteOpenAIThreadInput = {
  /** thread system id */
  readonly id: Scalars['Int'];
};

export type DeletePackage = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteSocialAccountPackage = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteTalentAgency = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteTikTokSpecialCampaign = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteTikTokSpecialCampaignInput = {
  readonly campaignId: Scalars['Int'];
};

export type DeleteTiktokUserHashtagInput = {
  readonly hashtagId: Scalars['Long'];
};

export type DeleteTiktokUserHashtagOutput = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteTiktokUserKeywordInput = {
  readonly keywordId: Scalars['Long'];
};

export type DeleteTiktokUserKeywordOutput = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteUser = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteUserProposalInfluencer = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteUserProposalInfluencerInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
};

export type DeleteUserProposalSocialAccount = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteUserProposalSocialAccountIndividually = {
  readonly ok: Scalars['Boolean'];
};

export type DeleteUserProposalSocialAccountIndividuallyInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountMedia: UserProposalSocialMediaType;
};

export type DeleteUserProposalSocialAccountInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountMedia: SocialAccountType;
};

export type DeleteYoutubeCompareAccountAnalyticsInput = {
  readonly compareAccountId: Scalars['Int'];
  readonly mainAccountId: Scalars['Int'];
};

export type DeleteYoutubeCompareAccountAnalyticsOutput = {
  readonly ok: Scalars['Boolean'];
};

export type DeliveryTimingItemInput = {
  readonly scheduleDate?: InputMaybe<Scalars['String']>;
  readonly type: DeliveryTimingType;
};

export type DeliveryTimingItemPayload = {
  readonly scheduleDate?: Maybe<Scalars['String']>;
  readonly type: DeliveryTimingType;
};

export enum DeliveryTimingType {
  IMMEDIATELY_SEND = 'IMMEDIATELY_SEND',
  SCHEDULE_SEND = 'SCHEDULE_SEND'
}

export type DenyEngagementInfluencerProposalList = {
  readonly ok: Scalars['Boolean'];
};

export type DenyEngagementInfluencerProposalListInput = {
  readonly campaignId: Scalars['Int'];
  readonly deniedReason: Scalars['String'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
};

export type DenyInfluencersUserProposal = {
  readonly ok: Scalars['Boolean'];
};

export type DenyInfluencersUserProposalInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
};

export type DisconnectPackageFromEngagementProposal = {
  readonly ok: Scalars['Boolean'];
};

export type DisconnectPackageFromEngagementProposalInput = {
  readonly engagementCampaignId: Scalars['Int'];
  readonly packageIds: ReadonlyArray<Scalars['Int']>;
};

export type DisconnectPromotionMethodsInput = {
  readonly promotionMethodId: Scalars['ID'];
};

export type DisconnectPromotionMethodsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type DisconnectTiktokAdAccount = {
  readonly ok: Scalars['Boolean'];
};

export type DisconnectTiktokAdAccountInput = {
  readonly adAccountId: Scalars['String'];
};

export enum DisplayLanguage {
  ARABIC = 'ARABIC',
  BAHASA = 'BAHASA',
  CAMBODIA = 'CAMBODIA',
  ENGLISH = 'ENGLISH',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  MYANMAR = 'MYANMAR',
  SIMPLIFIED_CHINESE = 'SIMPLIFIED_CHINESE',
  THAI = 'THAI',
  TRADITIONAL_CHINESE = 'TRADITIONAL_CHINESE',
  VIETNAMESE = 'VIETNAMESE'
}

export type DouyinAccountGeneralCategory = {
  readonly count: Scalars['Int'];
  readonly detailCategory?: Maybe<ReadonlyArray<DouyinAccountInterestRate>>;
  readonly name: Scalars['String'];
};

export type DouyinAccountInterest = {
  readonly generalCategory?: Maybe<ReadonlyArray<DouyinAccountGeneralCategory>>;
  readonly videoType?: Maybe<ReadonlyArray<DouyinAccountInterestRate>>;
};

export type DouyinAccountInterestRate = {
  readonly name: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type DouyinAudienceSection = {
  readonly ageRates: TiktokFollowersAgeGroup;
  readonly femaleRate?: Maybe<Scalars['Float']>;
  readonly maleRate?: Maybe<Scalars['Float']>;
  readonly regionRates?: Maybe<ReadonlyArray<DouyinFollowerRegionRate>>;
};

/** An enumeration. */
export enum DouyinDistributionType {
  ANYONE = 'ANYONE',
  FOLLOWER = 'FOLLOWER'
}

export type DouyinFollowerRegionRate = {
  readonly name: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type DouyinInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly bio?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly gender: Genders;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly popularPosts: ReadonlyArray<SocialAccountPopularPost>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountEngagementViewsRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  readonly socialAccountId: Scalars['ID'];
  readonly socialAccountStatus: SocialAccountStatus;
};

export type DouyinInfluencerSearchResult = {
  readonly douyinAccounts: ReadonlyArray<DouyinInfluencer>;
  readonly totalNumber: Scalars['Int'];
};

export type EditSocialAccountAnalyticsAdminInputV2 = {
  readonly accountManagersPICs?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly accountName: Scalars['String'];
  readonly brandAccountId: Scalars['Int'];
  readonly facebookPageId?: InputMaybe<Scalars['String']>;
  readonly igFacebookPageId?: InputMaybe<Scalars['String']>;
  readonly twitterUserId?: InputMaybe<Scalars['String']>;
  readonly youtubeChannelId?: InputMaybe<Scalars['String']>;
};

export type EditSocialAccountAnalyticsAdminOutputV2 = {
  readonly ok: Scalars['Boolean'];
};

export type EditSocialAccountAnalyticsAdvertiserInputV2 = {
  readonly accountName: Scalars['String'];
  readonly brandAccountId: Scalars['Int'];
  readonly facebookPageId?: InputMaybe<Scalars['String']>;
  readonly igFacebookPageId?: InputMaybe<Scalars['String']>;
  readonly twitterUserId?: InputMaybe<Scalars['String']>;
  readonly youtubeChannelId?: InputMaybe<Scalars['String']>;
};

export type EditSocialAccountAnalyticsAdvertiserOutputV2 = {
  readonly ok: Scalars['Boolean'];
};

export enum EmailEventStatus {
  BLOCKED = 'BLOCKED',
  BOUNCE = 'BOUNCE',
  CLICK = 'CLICK',
  DEFERRED = 'DEFERRED',
  DELIVERED = 'DELIVERED',
  DROPPED = 'DROPPED',
  GROUP_RESUBSCRIBE = 'GROUP_RESUBSCRIBE',
  GROUP_UNSUBSCRIBE = 'GROUP_UNSUBSCRIBE',
  OPEN = 'OPEN',
  PROCESSED = 'PROCESSED',
  SPAMREPORT = 'SPAMREPORT',
  UNSUBSCRIBE = 'UNSUBSCRIBE'
}

export enum EmailEventStatusForSearch {
  BOUNCE = 'BOUNCE',
  CLICK = 'CLICK',
  OPEN = 'OPEN'
}

export type EmailInfo = {
  readonly fromEmail: Scalars['String'];
  readonly message: Scalars['String'];
  readonly sentAt: Scalars['String'];
  readonly subject: Scalars['String'];
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly toEmails?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type EmailInfoForList = {
  readonly clickRate?: Maybe<Scalars['Float']>;
  readonly id: Scalars['Long'];
  readonly openRate?: Maybe<Scalars['Float']>;
  readonly scheduleDate?: Maybe<Scalars['String']>;
  readonly sentAt?: Maybe<Scalars['String']>;
  readonly status: EmailTemplateStatus;
  readonly subject: Scalars['String'];
};

export type EmailListOrderBy = {
  readonly field: EmailListSortableField;
  readonly order: Order;
};

export enum EmailListSortableField {
  CLICK_RATE = 'CLICK_RATE',
  CREATED = 'CREATED',
  OPEN_RATE = 'OPEN_RATE'
}

export type EmailStatistics = {
  readonly bounceCount: Scalars['Long'];
  readonly clickCount: Scalars['Long'];
  readonly openCount: Scalars['Long'];
  readonly recipientsCount: Scalars['Long'];
  readonly unsubscribeCount: Scalars['Long'];
};

export enum EmailTemplateStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED'
}

export type Engagement = {
  readonly advertiser: AdvertiserName;
  readonly budget: Scalars['Float'];
  readonly countries: ReadonlyArray<CountryName>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly defaultPostRequirement?: Maybe<Scalars['String']>;
  readonly endDate: Scalars['Date'];
  readonly id: Scalars['Int'];
  readonly influencerManagementPics?: Maybe<ReadonlyArray<PicName>>;
  readonly salesPics?: Maybe<ReadonlyArray<PicName>>;
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate: Scalars['Date'];
  readonly status: EngagementCampaignStatus;
  readonly title: Scalars['String'];
};

export type EngagementAddInfluencersToProposalFromAnotherCampaign = {
  readonly ok: Scalars['Boolean'];
};

export type EngagementAddInfluencersToProposalFromAnotherCampaignInput = {
  /** Get influencers from campaign */
  readonly fromCampaignId: Scalars['Int'];
  /** Put influencers to campaign */
  readonly toCampaignId: Scalars['Int'];
};

export type EngagementCampaignCouponTrackingInput = {
  readonly campaignId: Scalars['Int'];
  readonly couponCode: Scalars['String'];
  readonly saleAmount: Scalars['Float'];
  readonly transactionId: Scalars['String'];
};

export type EngagementCampaignCouponTrackingPayload = {
  readonly ok: Scalars['Boolean'];
};

export type EngagementCampaignPostReportAudienceBreakdown = {
  readonly tiktok?: Maybe<EngagementCampaignPostReportAudienceBreakdownForTikTok>;
  readonly youtube?: Maybe<EngagementCampaignPostReportAudienceBreakdownForYouTube>;
};

export type EngagementCampaignPostReportAudienceBreakdownForTikTok = {
  readonly ageRates: AudienceBreakdownAgeRates;
  readonly areaRates: ReadonlyArray<AudienceBreakdownAreaRate>;
  readonly genderRates: AudienceBreakdownGenderRates;
};

export type EngagementCampaignPostReportAudienceBreakdownForYouTube = {
  readonly ageGenderRates: AudienceBreakdownAgeGenderRates;
  readonly areaRates: ReadonlyArray<AudienceBreakdownAreaRate>;
  readonly genderRates: AudienceBreakdownGenderRates;
};

export type EngagementCampaignPostReportForInfluencer = {
  readonly currency: Scalars['String'];
  /** campaign ID */
  readonly id: Scalars['Int'];
  readonly postReport: ReadonlyArray<InfluencerPostReportStatsEngagement>;
  /** campaign title */
  readonly title: Scalars['String'];
};

/** An enumeration. */
export enum EngagementCampaignPostStatus {
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  READY_TO_POST = 'READY_TO_POST',
  REJECT = 'REJECT',
  REVIEWING = 'REVIEWING',
  TTCM_REVIEWING = 'TTCM_REVIEWING',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED'
}

/** An enumeration. */
export enum EngagementCampaignPostStatusForInsightCheck {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED'
}

/** An enumeration. */
export enum EngagementCampaignPostStatusForSearch {
  AD_FRAUD = 'AD_FRAUD',
  API_ERROR = 'API_ERROR',
  APPROVED = 'APPROVED',
  DELETED_POST = 'DELETED_POST',
  EFFECTIVE = 'EFFECTIVE',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  INEFFECTIVE = 'INEFFECTIVE',
  INVALID_CONTENT = 'INVALID_CONTENT',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  REJECT = 'REJECT',
  REVIEWING = 'REVIEWING',
  SCRAPING_ERROR = 'SCRAPING_ERROR',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED'
}

export type EngagementCampaignPostsForInsightCheck = {
  readonly campaignTitle: Scalars['String'];
  readonly posts: ReadonlyArray<EngagementPostForInsightCheck>;
};

export type EngagementCampaignReport = {
  readonly InternalInfo?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly average?: Maybe<NewCampaignStatsItemRow>;
  readonly campaignInfo: CampaignReportCampaignInfo;
  readonly chartData: ReadonlyArray<CampaignChartReport>;
  readonly currency: Scalars['String'];
  readonly influencerReports: ReadonlyArray<CampaignInfluencerReport>;
  readonly orderedSummaryDaily?: Maybe<ReadonlyArray<CampaignSummaryDaily>>;
  readonly postCount: Scalars['Int'];
  readonly summary: NewCampaignStatsItemRow;
  readonly summaryDaily?: Maybe<ReadonlyArray<CampaignSummaryDaily>>;
};

export type EngagementCampaignSearchSelectInfluencers = {
  /** Campaign's category */
  readonly category: Scalars['String'];
  /** Campaign's countries */
  readonly countries: ReadonlyArray<Scalars['String']>;
  /** Campaign's engagement rate */
  readonly engagementRate?: Maybe<Scalars['Float']>;
  /** Campaign's id */
  readonly id: Scalars['Int'];
  /** Campaign's name */
  readonly name: Scalars['String'];
  /** Number of selected influencers in the campaign */
  readonly numberOfInfluencers: Scalars['Int'];
};

/** An enumeration. */
export enum EngagementCampaignSocialAccountPostStatus {
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  JOINED = 'JOINED',
  JOINED_WITH_PENDING_INVITATION = 'JOINED_WITH_PENDING_INVITATION',
  JOINED_WITH_PENDING_ORDER = 'JOINED_WITH_PENDING_ORDER',
  READY_TO_POST = 'READY_TO_POST',
  REJECT = 'REJECT',
  REVIEWING = 'REVIEWING',
  TTCM_REVIEWING = 'TTCM_REVIEWING',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED'
}

/** An enumeration. */
export enum EngagementCampaignStatus {
  DEMO = 'DEMO',
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  LOST = 'LOST',
  ONGOING = 'ONGOING',
  REVIEWING = 'REVIEWING',
  UPCOMING = 'UPCOMING'
}

export type EngagementDetail = {
  readonly adAgencyMarginRate?: Maybe<Scalars['Float']>;
  readonly advertiser: AdvertiserName;
  readonly budget: Scalars['Float'];
  readonly campaignCategory?: Maybe<CampaignCategoryName>;
  readonly campaignCreator?: Maybe<CampaignCreator>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly countries: ReadonlyArray<CountryName>;
  readonly couponList?: Maybe<Scalars['String']>;
  readonly couponUploadFiles?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly defaultPostRequirement?: Maybe<Scalars['String']>;
  readonly endDate: Scalars['Date'];
  readonly genders: ReadonlyArray<Genders>;
  readonly hashtags?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly hubspotDealIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['Int'];
  readonly influencerManagementPics?: Maybe<ReadonlyArray<PicName>>;
  readonly isTtcmCampaign: Scalars['Boolean'];
  readonly landingPageUrl?: Maybe<Scalars['String']>;
  readonly marginRate?: Maybe<Scalars['Float']>;
  readonly materialUrl?: Maybe<Scalars['String']>;
  readonly materials?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly maxAge: Scalars['Int'];
  readonly maxFollowers: Scalars['Int'];
  readonly minAge: Scalars['Int'];
  readonly minFollowers: Scalars['Int'];
  readonly numberOfInfluencers?: Maybe<Scalars['Int']>;
  readonly objective: Scalars['String'];
  readonly reportStartDate?: Maybe<Scalars['Date']>;
  readonly salesPics?: Maybe<ReadonlyArray<PicName>>;
  readonly serviceInformation: Scalars['String'];
  readonly serviceUrl?: Maybe<Scalars['String']>;
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate: Scalars['Date'];
  readonly status: EngagementCampaignStatus;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingOptions?: Maybe<ReadonlyArray<CampaignTrackingOption>>;
};

export type EngagementDetailForInfluencer = {
  /** campaign status using for AnyX */
  readonly anyXStatus: AnyXEngagementCampaignStatus;
  readonly couponCode?: Maybe<Scalars['String']>;
  readonly createdDate: Scalars['Date'];
  readonly currency: Scalars['String'];
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly hasDraftPosts: Scalars['Boolean'];
  /** Whether she uploaded her post on social media or not. */
  readonly hasReport: Scalars['Boolean'];
  readonly hasWarningPosts: Scalars['Boolean'];
  readonly hashtags: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly isCouponCampaign?: Maybe<Scalars['Boolean']>;
  readonly isJoined: Scalars['Boolean'];
  readonly isTtcmCampaign: Scalars['Boolean'];
  readonly isTtcmReady?: Maybe<Scalars['Boolean']>;
  readonly materialUrl?: Maybe<Scalars['String']>;
  readonly materials?: Maybe<ReadonlyArray<MaterialName>>;
  readonly objective?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated since new Influencer app */
  readonly postStatus?: Maybe<CampaignPostStatusForInfluencer>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<Scalars['String']>;
  /** influencer revenue */
  readonly rewardAmount: Scalars['Float'];
  readonly serviceInformation: Scalars['String'];
  readonly serviceUrl?: Maybe<Scalars['String']>;
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate: Scalars['Date'];
  readonly status: CampaignStatusForInfluencer;
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly trackingUrl?: Maybe<Scalars['String']>;
  readonly ttcmCampaignCode?: Maybe<Scalars['String']>;
  readonly ttcmStatus?: Maybe<TTCMStatus>;
  readonly type: CampaignType;
};

export type EngagementDraftPostHistory = {
  /** Influencer info */
  readonly engagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  /** List of rejected post history */
  readonly historyRecords?: Maybe<ReadonlyArray<EngagementDraftPostHistoryRecord>>;
};

export type EngagementDraftPostHistoryForInfluencer = {
  /** List of rejected post history */
  readonly historyRecords?: Maybe<ReadonlyArray<EngagementDraftPostHistoryRecordForInfluencer>>;
};

export type EngagementDraftPostHistoryRecord = {
  /** History date */
  readonly createdDate: Scalars['Date'];
  /** Reason detail */
  readonly detailedReason?: Maybe<Scalars['String']>;
  /** History record id */
  readonly id?: Maybe<Scalars['Int']>;
  readonly images?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Draft post content */
  readonly postContent: Scalars['String'];
  /** History post status */
  readonly postStatus?: Maybe<EngagementCampaignPostStatus>;
  /** Reason for report */
  readonly reason?: Maybe<Scalars['String']>;
};

export type EngagementDraftPostHistoryRecordForInfluencer = {
  /** History date */
  readonly createdDate: Scalars['Date'];
  /** Reason detail */
  readonly detailedReason?: Maybe<Scalars['String']>;
  /** History record id */
  readonly id?: Maybe<Scalars['Int']>;
  /** History post status */
  readonly postStatus?: Maybe<EngagementCampaignPostStatus>;
  /** Reason for report */
  readonly reason?: Maybe<Scalars['String']>;
};

export type EngagementForInsightCheck = {
  readonly countries: ReadonlyArray<CountryName>;
  readonly endDate: Scalars['Date'];
  readonly id: Scalars['Int'];
  readonly startDate: Scalars['Date'];
  readonly status: EngagementCampaignPostStatusForInsightCheck;
  readonly title: Scalars['String'];
  readonly totalConfirmations: Scalars['Int'];
  readonly totalSubmissions: Scalars['Int'];
};

export type EngagementInfluencerBudgetInput = {
  readonly influencerCost: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
  readonly netBudget: Scalars['Float'];
  readonly profit: Scalars['Float'];
};

export type EngagementPost = {
  readonly content?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly influencer: EngagementPostInfluencer;
  readonly planedPostDate?: Maybe<Scalars['Date']>;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly postedDate?: Maybe<Scalars['Date']>;
  readonly socialAccountId?: Maybe<Scalars['Int']>;
  /** support Tiktok for now */
  readonly socialAccountStatus?: Maybe<SocialAccountStatus>;
  readonly socialMedia: CampaignSocialMediaType;
  readonly status?: Maybe<EngagementCampaignSocialAccountPostStatus>;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly warningReason?: Maybe<WarningReason>;
};

export type EngagementPostComment = {
  readonly commentedDate?: Maybe<Scalars['DateTime']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly username?: Maybe<Scalars['String']>;
};

export type EngagementPostDetail = {
  readonly caption: Scalars['String'];
  readonly comments?: Maybe<Scalars['Int']>;
  readonly detailedReason?: Maybe<Scalars['String']>;
  readonly engagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  readonly id: Scalars['Int'];
  readonly images?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly postedDate?: Maybe<Scalars['Date']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly saves?: Maybe<Scalars['Int']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialMedia: CampaignSocialMediaType;
  readonly status?: Maybe<EngagementCampaignPostStatus>;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
  readonly warningReason?: Maybe<WarningReason>;
};

export type EngagementPostDetailForInfluencer = {
  /** Represents draft post or posted post content */
  readonly caption: Scalars['String'];
  readonly engagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  readonly id: Scalars['Int'];
  readonly images?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly postedDate?: Maybe<Scalars['Date']>;
  readonly socialMedia: CampaignSocialMediaType;
};

export type EngagementPostDetailForInfluencerV2 = {
  readonly content: Scalars['String'];
  readonly id: Scalars['Int'];
  /** represents warning/reject detail reason */
  readonly inappropriateDetailedReason?: Maybe<Scalars['String']>;
  /** represents warning/reject reason */
  readonly inappropriateReason?: Maybe<Scalars['String']>;
  readonly materials?: Maybe<ReadonlyArray<MaterialName>>;
  readonly planedPostDate: Scalars['Date'];
  readonly postStatus: EngagementCampaignPostStatus;
  /** represents selected social account system id */
  readonly socialAccountId?: Maybe<Scalars['Int']>;
  readonly socialAccountMedia?: Maybe<SocialAccountType>;
  readonly socialAccountName?: Maybe<Scalars['String']>;
  /** represents campaign social media type */
  readonly socialMedia: CampaignSocialMediaType;
};

export type EngagementPostDetailInfluencer = {
  readonly avatar: Scalars['String'];
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type EngagementPostEditV2 = {
  readonly content?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly influencer: EngagementPostInfluencerWithSocialAccount;
  readonly insightDataAcquisition?: Maybe<Scalars['Date']>;
  readonly insightUrls?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly materials?: Maybe<ReadonlyArray<MaterialName>>;
  readonly planedPostDate?: Maybe<Scalars['Date']>;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly postedDate?: Maybe<Scalars['Date']>;
  /** represents campaign social media type */
  readonly socialMedia: CampaignSocialMediaType;
  readonly status?: Maybe<EngagementCampaignPostStatus>;
};

export type EngagementPostForInfluencer = {
  /** campaign status using for AnyX */
  readonly anyXStatus: AnyXEngagementCampaignStatus;
  readonly content: Scalars['String'];
  /** Post Id */
  readonly id: Scalars['Int'];
  /** represents reason details */
  readonly inappropriateDetailedReason?: Maybe<Scalars['String']>;
  /** represents reason title */
  readonly inappropriateReason?: Maybe<Scalars['String']>;
  /** currently for mobile */
  readonly isPreviouslyModified: Scalars['Boolean'];
  readonly planedPostDate: Scalars['Date'];
  readonly postedDate?: Maybe<Scalars['Date']>;
  readonly socialMedia: CampaignSocialMediaType;
  readonly status: EngagementCampaignPostStatus;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly ttcmInviteLink?: Maybe<Scalars['String']>;
};

export type EngagementPostForInsightCheck = {
  readonly id: Scalars['Int'];
  /** Influencer ID */
  readonly influencerId: Scalars['Int'];
  /** Influencer Name */
  readonly influencerName: Scalars['String'];
  /** Draft OR Posted content */
  readonly postContent: Scalars['String'];
  readonly socialMedia: CampaignSocialMediaType;
  readonly status: EngagementCampaignPostStatusForInsightCheck;
};

export type EngagementPostInfluencer = {
  readonly avatar: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type EngagementPostInfluencerWithSocialAccount = {
  /** represents influencer id */
  readonly id: Scalars['Int'];
  /** represents influencer name */
  readonly name: Scalars['String'];
  /** represents selected social account system id */
  readonly socialAccountId?: Maybe<Scalars['Int']>;
  readonly socialAccountMedia?: Maybe<SocialAccountType>;
  readonly socialAccountName?: Maybe<Scalars['String']>;
};

export type EngagementPostInfluencerWithSocialAccounts = {
  /** Influencer Id */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly socialAccounts: ReadonlyArray<EngagementPostSocialAccount>;
};

export type EngagementPostMetricsDataForInsightCheck = {
  readonly audienceRetention?: Maybe<Scalars['Float']>;
  readonly averageViewDurationHours?: Maybe<Scalars['Float']>;
  readonly averageViewDurationMinutes?: Maybe<Scalars['Float']>;
  readonly back?: Maybe<Scalars['Int']>;
  readonly comments?: Maybe<Scalars['Int']>;
  readonly exited?: Maybe<Scalars['Int']>;
  readonly followerReach?: Maybe<Scalars['Int']>;
  /** for Douyin post */
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly forward?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly impressionsClickThroughRate?: Maybe<Scalars['Float']>;
  readonly impressionsFromDiscovery?: Maybe<Scalars['Int']>;
  readonly impressionsFromHashtag?: Maybe<Scalars['Int']>;
  readonly impressionsFromHome?: Maybe<Scalars['Int']>;
  readonly impressionsFromOther?: Maybe<Scalars['Int']>;
  readonly impressionsFromProfile?: Maybe<Scalars['Int']>;
  readonly insightUrls?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly interaction?: Maybe<Scalars['Int']>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly linkClicks?: Maybe<Scalars['Int']>;
  readonly navigation?: Maybe<Scalars['Int']>;
  readonly newFollowers?: Maybe<Scalars['Int']>;
  readonly nextStory?: Maybe<Scalars['Int']>;
  readonly nonFollowerReach?: Maybe<Scalars['Int']>;
  /** Draft OR Posted content */
  readonly postContent: Scalars['String'];
  readonly profileActivity?: Maybe<Scalars['Int']>;
  readonly reaches?: Maybe<Scalars['Int']>;
  readonly saves?: Maybe<Scalars['Int']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialMedia: CampaignSocialMediaType;
  readonly status: EngagementCampaignPostStatusForInsightCheck;
  readonly stickerTaps?: Maybe<Scalars['Int']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type EngagementPostSocialAccount = {
  /** Represents social account system id */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly socialMedia: SocialAccountType;
  readonly status: SocialAccountStatus;
  readonly ttcmCampaignInviteLink?: Maybe<Scalars['String']>;
  readonly ttcmStatus?: Maybe<TTCMStatus>;
  readonly username?: Maybe<Scalars['String']>;
};

export type EngagementPostTrafficSource = {
  readonly estimatedMinutesWatched?: Maybe<Scalars['Float']>;
  readonly sourceType: YoutubeTrafficSource;
  readonly views: Scalars['Int'];
};

export type EngagementProposal = {
  readonly activeTtcmCreatorsCount?: Maybe<Scalars['Int']>;
  readonly couponCodeCount?: Maybe<Scalars['Int']>;
  /** Engagement Id */
  readonly id: Scalars['Int'];
  readonly influencers: ReadonlyArray<ProposalInfluencer>;
  readonly jdMode: ProposalJobDescriptionBreakDownMode;
  readonly summary: ProposalJobSummary;
};

/** An enumeration. */
export enum EngagementProposalSocialMediaType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE'
}

export type EngagementSocialAcccountBudgetInput = {
  readonly influencerCost: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
  readonly netBudget: Scalars['Float'];
  readonly profit: Scalars['Float'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialAccountMedia: EngagementProposalSocialMediaType;
};

export type EngagementTracking = {
  /** only for Pixel tracking campaign */
  readonly pixelCode?: Maybe<Scalars['String']>;
  /** only for postback URL campaign */
  readonly postbackUrl?: Maybe<Scalars['String']>;
};

export type EngagementXhsPostMetricsDataForInsight = {
  readonly age45UpPercentage?: Maybe<Scalars['Float']>;
  readonly age1824Percentage?: Maybe<Scalars['Float']>;
  readonly age2534Percentage?: Maybe<Scalars['Float']>;
  readonly age3544Percentage?: Maybe<Scalars['Float']>;
  readonly ageUp17Percentage?: Maybe<Scalars['Float']>;
  readonly averageViewDurationSeconds?: Maybe<Scalars['Int']>;
  readonly comments?: Maybe<Scalars['Int']>;
  readonly femalePercentage?: Maybe<Scalars['Float']>;
  readonly id: Scalars['Int'];
  readonly insightUrls?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly malePercentage?: Maybe<Scalars['Float']>;
  readonly newFollowers?: Maybe<Scalars['Int']>;
  /** Draft OR Posted content */
  readonly postContent: Scalars['String'];
  readonly saves?: Maybe<Scalars['Int']>;
  readonly status: EngagementCampaignPostStatusForInsightCheck;
  readonly views?: Maybe<Scalars['Int']>;
  readonly viewsFromFollowing?: Maybe<Scalars['Int']>;
  readonly viewsFromHome?: Maybe<Scalars['Int']>;
  readonly viewsFromOther?: Maybe<Scalars['Int']>;
  readonly viewsFromProfile?: Maybe<Scalars['Int']>;
  readonly viewsFromSearch?: Maybe<Scalars['Int']>;
};

export type EngagementsForInsightCheck = {
  readonly engagementCampaigns: ReadonlyArray<EngagementForInsightCheck>;
};

export type EngagementsForInsightCheckCount = {
  readonly totalConfirmed: Scalars['Int'];
  readonly totalUnconfirmed: Scalars['Int'];
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DEADLINE_EXCEEDED = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  ENHANCE_YOUR_CALM = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FIELD_NOT_FOUND = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  INVALID_CURSOR = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MISSING_RESOURCE = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  SERVICE_ERROR = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TCP_FAILURE = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CONCURRENCY = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CPU = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  UNIMPLEMENTED = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  UNKNOWN = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BAD_REQUEST = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  INTERNAL = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NOT_FOUND = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  UNAVAILABLE = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  UNKNOWN = 'UNKNOWN'
}

export type ExistingProfileTags = {
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type ExportCmsInvoiceToSpreadsheet = {
  readonly ok: Scalars['Boolean'];
};

export type ExportCmsInvoiceToSpreadsheetInput = {
  readonly month: Scalars['Date'];
};

export type ExportDashboardInstagramAccountAnalytics = {
  readonly ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum ExportIGPresentationType {
  GOOGLE_SLIDE = 'GOOGLE_SLIDE',
  PPTX = 'PPTX'
}

export type ExportInstagramDashboardAccountAnalyticsInput = {
  readonly endDate: Scalars['Date'];
  /** Analytics Instagram account id */
  readonly instagramAccountId: Scalars['Int'];
  readonly startDate: Scalars['Date'];
  /** type of a presentation to send on your email address */
  readonly type: ExportIGPresentationType;
};

export type ExportInstagramDashboardAccountInfluencerInput = {
  readonly endDate: Scalars['Date'];
  /** Social account id of an influencer's IG account */
  readonly socialAccountId: Scalars['Int'];
  readonly startDate: Scalars['Date'];
  /** type of a presentation to send on your email address */
  readonly type: ExportIGPresentationType;
};

export type ExportInstagramHashtagPostsToGoogleSpreadsheetInput = {
  readonly endDate: Scalars['Date'];
  readonly hashtagId: Scalars['Long'];
  readonly startDate: Scalars['Date'];
};

export type ExportInstagramHashtagPostsToGoogleSpreadsheetOutput = {
  readonly status: Scalars['String'];
};

export enum ExportYoutubeAccountAnalyticsType {
  GOOGLE_SLIDE = 'GOOGLE_SLIDE',
  PPTX = 'PPTX'
}

export type ExportYoutubeDashboardAccountAnalyticsInput = {
  readonly endDate: Scalars['String'];
  readonly startDate: Scalars['String'];
  readonly type?: InputMaybe<ExportYoutubeAccountAnalyticsType>;
  readonly youtubeAccountId: Scalars['Int'];
};

export type ExportYoutubeDashboardAccountAnalyticsOutput = {
  readonly ok: Scalars['Boolean'];
};

export enum ExternalAnalyticsAuthSocialAccountType {
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  YOUTUBE = 'YOUTUBE'
}

export type ExternalAnalyticsFacebookGetAccountDetailsOutput = {
  readonly fbPageAvatar: Scalars['String'];
  readonly fbPageName: Scalars['String'];
};

export type ExternalAnalyticsFacebookGetTokenWarningsOutput = {
  readonly analyticsAccountsWithInvalidTokens: ReadonlyArray<Scalars['Int']>;
};

export type ExternalAnalyticsInstagramGetAccountDetailsOutput = {
  readonly fbPageAvatar: Scalars['String'];
  readonly fbPageName: Scalars['String'];
  readonly igAvatar: Scalars['String'];
  readonly igUsername: Scalars['String'];
};

export type ExternalAnalyticsInstagramGetTokenWarningsOutput = {
  readonly analyticsAccountsWithInvalidTokens: ReadonlyArray<Scalars['Int']>;
};

/**  Stores a redirect URL that FE uses to communicate with social network APIs */
export type ExternalAnalyticsSocialAuthRedirectUrlPayload = {
  readonly redirectUrl: Scalars['String'];
};

export type ExternalConnectableFacebookPageAccount = {
  readonly avatar: Scalars['String'];
  readonly followers: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type ExternalConnectableFacebookPageAnalyticsInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type ExternalConnectableFacebookPageAnalyticsPayload = {
  readonly account?: Maybe<ExternalConnectableFacebookPageMainAccount>;
  readonly pages: ReadonlyArray<ExternalConnectableFacebookPageAccount>;
};

export type ExternalConnectableFacebookPageMainAccount = {
  readonly avatar: Scalars['String'];
  readonly friendCount: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

/**
 *  `id`: social network account id
 *  `avatar`: account's avatar URL from a remote social network server
 *  `name`: account's name
 *  `followersCount`: number of followers
 */
export type ExternalConnectableOauthAccount = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

/**
 *  `page`: Instagram account's Facebook page
 *  `igAccount`: Instagram account data
 */
export type ExternalConnectableOauthPageAndIgAccount = {
  readonly igAccount: ExternalConnectableOauthAccount;
  readonly page: ExternalConnectableOauthAccount;
};

export type ExternalCreateFacebookPageAccountAnalyticsInput = {
  readonly facebookPageId: Scalars['String'];
};

export type ExternalCreateFacebookPageAccountAnalyticsOutput = {
  readonly analyticsAccountId: Scalars['Int'];
};

export type ExternalCreateInstagramAccountAnalyticsInput = {
  readonly instagramUserId: Scalars['String'];
};

export type ExternalCreateInstagramAccountAnalyticsOutput = {
  readonly analyticsAccountId: Scalars['Int'];
};

export type ExternalCreateYoutubeAccountAnalyticsInput = {
  readonly youtubeChannelId: Scalars['String'];
};

export type ExternalCreateYoutubeAccountAnalyticsOutput = {
  readonly analyticsAccountId: Scalars['Int'];
};

export type ExternalInstagramAnalyticsPostDetails = {
  readonly analysis?: Maybe<InstagramAnalyticsPostAnalysisDetails>;
  readonly avatarUrl: Scalars['String'];
  readonly commentList?: Maybe<ReadonlyArray<InstagramAnalyticsPostCommentDetails>>;
  readonly content: Scalars['String'];
  readonly followers?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly instagramAccountName: Scalars['String'];
  readonly mediaUrls?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly sentiment?: Maybe<InstagramAnalyticsPostSentimentDetails>;
};

export type ExternalInstagramAnalyticsPostsInDate = {
  readonly feedPosts?: Maybe<ReadonlyArray<ExternalInstagramAnalyticsPostDetails>>;
  readonly reelPosts?: Maybe<ReadonlyArray<ExternalInstagramAnalyticsReelDetails>>;
  readonly storyPosts?: Maybe<ReadonlyArray<ExternalInstagramAnalyticsStoryDetails>>;
};

export type ExternalInstagramAnalyticsReelDetails = {
  readonly analysis?: Maybe<InstagramAnalyticsReelAnalysisDetails>;
  readonly avatarUrl: Scalars['String'];
  readonly commentList?: Maybe<ReadonlyArray<InstagramAnalyticsReelCommentDetails>>;
  readonly content: Scalars['String'];
  readonly followers?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly instagramAccountName: Scalars['String'];
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly thumbnail?: Maybe<Scalars['String']>;
};

export type ExternalInstagramAnalyticsStoryDetails = {
  readonly avatarUrl: Scalars['String'];
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly exits: Scalars['Int'];
  readonly followers?: Maybe<Scalars['Int']>;
  /** Instagram analytics story id */
  readonly id: Scalars['Int'];
  readonly impressions: Scalars['Int'];
  readonly instagramAccountName: Scalars['String'];
  readonly postDate: Scalars['Date'];
  readonly postImageUrl?: Maybe<Scalars['String']>;
  readonly postUrl: Scalars['String'];
  readonly reach: Scalars['Int'];
  readonly storyVideoUrl?: Maybe<Scalars['String']>;
  readonly tapsBack: Scalars['Int'];
  readonly tapsForward: Scalars['Int'];
};

export type ExternalInstagramDashboardFollowerAnalytics = {
  readonly followerAgeRate: InstagramFollowersAgeRate;
  readonly followerGenderRate: InstagramFollowerGenderRate;
  readonly followerRegionRate?: Maybe<ReadonlyArray<InstagramFollowersRegionRate>>;
};

export type ExternalInstagramDashboardOverview = {
  readonly comments: InstagramAnalyticsOverviewData;
  readonly engagement: InstagramAnalyticsOverviewData;
  readonly engagementRate: InstagramAnalyticsOverviewEngagementRateData;
  readonly followers: InstagramAnalyticsOverviewData;
  /** impressions of posts */
  readonly impressions: InstagramAnalyticsOverviewData;
  readonly likes: InstagramAnalyticsOverviewData;
  readonly posts: InstagramAnalyticsOverviewData;
  readonly profileViews: InstagramAnalyticsOverviewData;
  readonly reach: InstagramAnalyticsOverviewData;
  readonly saved: InstagramAnalyticsOverviewData;
  readonly websiteClicks: InstagramAnalyticsOverviewData;
};

export type ExternalInstagramDashboardPostAnalytics = {
  readonly averageEngagement?: Maybe<InstagramPostAverageEngagement>;
  readonly commentAnalytics?: Maybe<InstagramCommentAnalytics>;
  readonly feedPosts?: Maybe<ReadonlyArray<ExternalInstagramFeedPost>>;
  readonly postsHabit?: Maybe<ReadonlyArray<InstagramPostsHabit>>;
  readonly storyPosts?: Maybe<ReadonlyArray<ExternalInstagramStoryPost>>;
};

export type ExternalInstagramFeedPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly engagement?: Maybe<Scalars['Int']>;
  readonly engagementRate?: Maybe<Scalars['Float']>;
  readonly follow?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly impressionFromDiscovery?: Maybe<Scalars['Int']>;
  readonly impressionFromHashtag?: Maybe<Scalars['Int']>;
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly interaction?: Maybe<Scalars['Int']>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly negativeRate?: Maybe<Scalars['Float']>;
  readonly nonFollowerReach?: Maybe<Scalars['Int']>;
  readonly positiveRate?: Maybe<Scalars['Float']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
  readonly productButtonClick?: Maybe<Scalars['Int']>;
  readonly productPageView?: Maybe<Scalars['Int']>;
  readonly reach?: Maybe<Scalars['Int']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly share?: Maybe<Scalars['Int']>;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type ExternalInstagramReelPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
  readonly reach?: Maybe<Scalars['Int']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type ExternalInstagramStoryPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly exits?: Maybe<Scalars['Int']>;
  readonly follow?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly interaction?: Maybe<Scalars['Int']>;
  readonly linkClicks?: Maybe<Scalars['Int']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
  readonly productButtonClick?: Maybe<Scalars['Int']>;
  readonly productPageView?: Maybe<Scalars['Int']>;
  readonly reach?: Maybe<Scalars['Int']>;
  readonly stickerTaps?: Maybe<Scalars['Int']>;
  readonly tapsBack?: Maybe<Scalars['Int']>;
  readonly tapsForward?: Maybe<Scalars['Int']>;
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

/**  Stores Instagram accounts that can be connected based on a Facebook account */
export type ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload = {
  readonly accounts: ReadonlyArray<ExternalConnectableOauthPageAndIgAccount>;
};

export type ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput = {
  readonly accountId: Scalars['Int'];
  readonly callbackUrl: Scalars['String'];
  readonly fbPageId: Scalars['String'];
  readonly response: Scalars['String'];
};

export type ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput = {
  readonly accountId: Scalars['Int'];
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload = {
  readonly end: Scalars['Int'];
  readonly start: Scalars['Int'];
};

export type ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload = {
  readonly ageGroup: ReadonlyArray<ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  readonly femaleRate: ReadonlyArray<Scalars['Float']>;
  readonly maleRate: ReadonlyArray<Scalars['Float']>;
  readonly otherRate: ReadonlyArray<Scalars['Float']>;
};

export type ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload = {
  readonly femaleRate: Scalars['Float'];
  readonly maleRate: Scalars['Float'];
  readonly otherRate: Scalars['Float'];
};

export type ExternalYoutubeAnalyticsAudiencePayload = {
  readonly ageRate: ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload;
  readonly genderRate: ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload;
  readonly regionRate: ReadonlyArray<ExternalYoutubeAnalyticsAudienceRegionPayload>;
};

export type ExternalYoutubeAnalyticsAudienceRegionPayload = {
  readonly name: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type ExternalYoutubeAnalyticsOverviewHistoryItem = {
  readonly count: Scalars['Int'];
  readonly date: Scalars['Date'];
};

export type ExternalYoutubeAnalyticsOverviewItem = {
  readonly growth: Scalars['Int'];
  readonly history: ReadonlyArray<ExternalYoutubeAnalyticsOverviewHistoryItem>;
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Int'];
};

export type ExternalYoutubeAnalyticsOverviewPayload = {
  readonly comment: ExternalYoutubeAnalyticsOverviewItem;
  readonly dislike: ExternalYoutubeAnalyticsOverviewItem;
  readonly like: ExternalYoutubeAnalyticsOverviewItem;
  readonly post: ExternalYoutubeAnalyticsOverviewItem;
  readonly subscriber: ExternalYoutubeAnalyticsOverviewItem;
  readonly view: ExternalYoutubeAnalyticsOverviewItem;
};

export type ExternalYoutubeAnalyticsPostComment = {
  readonly commentId: Scalars['String'];
  readonly content: Scalars['String'];
  readonly posterName: Scalars['String'];
};

export type ExternalYoutubeAnalyticsPostListByDatePayload = {
  readonly posts: ReadonlyArray<ExternalYoutubeAnalyticsPostListItemByDatePayload>;
};

export type ExternalYoutubeAnalyticsPostListItemByDatePayload = {
  readonly averageViewDuration?: Maybe<Scalars['Int']>;
  readonly averageViewPercentage?: Maybe<Scalars['Float']>;
  readonly comments: Scalars['Int'];
  readonly dislikes: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postDate: Scalars['Date'];
  readonly subscribers: Scalars['Int'];
  readonly videoCaption: Scalars['String'];
  readonly videoComments: ReadonlyArray<YoutubeAnalyticsPostComment>;
  readonly videoDuration: Scalars['Int'];
  readonly videoEmbed: Scalars['String'];
  readonly videoId: Scalars['String'];
  readonly videoTags: ReadonlyArray<Scalars['String']>;
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type ExternalYoutubeAnalyticsPostListItemPayload = {
  readonly averageViewDuration?: Maybe<Scalars['Int']>;
  readonly averageViewPercentage?: Maybe<Scalars['Float']>;
  readonly comments: Scalars['Int'];
  readonly dislikes: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postDate: Scalars['Date'];
  readonly shares: Scalars['Int'];
  readonly videoDuration: Scalars['Int'];
  readonly videoId: Scalars['String'];
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type ExternalYoutubeAnalyticsPostListPayload = {
  readonly posts: ReadonlyArray<ExternalYoutubeAnalyticsPostListItemPayload>;
};

export type ExternalYoutubeAnalyticsPostsAverageEngagement = {
  readonly averageComments: ExternalYoutubeStatisticsData;
  readonly averageDislikes: ExternalYoutubeStatisticsData;
  readonly averageLikes: ExternalYoutubeStatisticsData;
  readonly averagePostsPerWeek?: Maybe<ExternalYoutubeStatisticsData>;
  readonly averageViews: ExternalYoutubeStatisticsData;
};

export type ExternalYoutubeAnalyticsPostsPostingHabit = {
  readonly averageComments: Scalars['Float'];
  readonly averageLikes: Scalars['Float'];
  readonly engagementRate: Scalars['Float'];
  readonly hour: Scalars['Int'];
  readonly weekday: Scalars['Int'];
};

export type ExternalYoutubeAnalyticsPostsSortInput = {
  readonly field?: InputMaybe<ExternalYoutubeAnalyticsPostsSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum ExternalYoutubeAnalyticsPostsSortOrder {
  AVERAGE_VIEW_DURATION = 'AVERAGE_VIEW_DURATION',
  AVERAGE_VIEW_PERCENTAGE = 'AVERAGE_VIEW_PERCENTAGE',
  COMMENT = 'COMMENT',
  DISLIKE = 'DISLIKE',
  DURATION = 'DURATION',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKE = 'LIKE',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  SHARE = 'SHARE',
  VIEWS = 'VIEWS'
}

export type ExternalYoutubeAnalyticsPostsStatisticsPayload = {
  readonly averageEngagement: ExternalYoutubeAnalyticsPostsAverageEngagement;
  readonly postHabit: ReadonlyArray<ExternalYoutubeAnalyticsPostsPostingHabit>;
};

export type ExternalYoutubeAnalyticsRelatedPostPayload = {
  readonly comments: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly postDate: Scalars['Date'];
  readonly videoCaption: Scalars['String'];
  readonly videoDuration: Scalars['Int'];
  readonly videoEmbed: Scalars['String'];
  readonly videoId: Scalars['String'];
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type ExternalYoutubeAnalyticsRelatedPostsPayload = {
  readonly posts: ReadonlyArray<ExternalYoutubeAnalyticsRelatedPostPayload>;
};

export type ExternalYoutubeAnalyticsTagRankingPayload = {
  readonly averageComment: Scalars['Float'];
  readonly averageDislike: Scalars['Float'];
  readonly averageEngagement: Scalars['Float'];
  readonly averageLike: Scalars['Float'];
  readonly averageShare: Scalars['Float'];
  readonly engagementRate: Scalars['Float'];
  readonly posts: Scalars['Int'];
  readonly tag: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type ExternalYoutubeAnalyticsTagRankingPayloads = {
  readonly tags: ReadonlyArray<ExternalYoutubeAnalyticsTagRankingPayload>;
};

export type ExternalYoutubeAnalyticsTagRankingSortInput = {
  readonly field?: InputMaybe<ExternalYoutubeAnalyticsTagRankingSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum ExternalYoutubeAnalyticsTagRankingSortOrder {
  AVERAGE_COMMENT = 'AVERAGE_COMMENT',
  AVERAGE_DISLIKE = 'AVERAGE_DISLIKE',
  AVERAGE_ENGAGEMENT = 'AVERAGE_ENGAGEMENT',
  AVERAGE_LIKE = 'AVERAGE_LIKE',
  AVERAGE_SHARE = 'AVERAGE_SHARE',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  POST = 'POST',
  VIEWS = 'VIEWS'
}

export type ExternalYoutubePostPayload = {
  readonly averageViewDuration?: Maybe<Scalars['Int']>;
  readonly averageViewPercentage?: Maybe<Scalars['Float']>;
  readonly comments: Scalars['Int'];
  readonly dislikes: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postDate: Scalars['Date'];
  readonly subscribers: Scalars['Int'];
  readonly videoCaption: Scalars['String'];
  readonly videoComments: ReadonlyArray<ExternalYoutubeAnalyticsPostComment>;
  readonly videoDuration: Scalars['Int'];
  readonly videoEmbed: Scalars['String'];
  readonly videoId: Scalars['String'];
  readonly videoTags: ReadonlyArray<Scalars['String']>;
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type ExternalYoutubeStatisticsData = {
  readonly growth: Scalars['Float'];
  readonly value: Scalars['Float'];
};

export type FacebookAccountInfluencerSearchResultV2 = {
  readonly facebookAccounts: ReadonlyArray<FacebookAccountInfluencerV2>;
  readonly totalNumber: Scalars['Int'];
};

export type FacebookAccountInfluencerV2 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly country: CountryName;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  readonly id: Scalars['ID'];
  readonly influencerEngagement?: Maybe<Scalars['Int']>;
  readonly influencerEngagementRate?: Maybe<Scalars['Float']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  readonly socialAccountId: Scalars['ID'];
  readonly socialAccountStatus: SocialAccountStatus;
};

export type FacebookInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly comments?: Maybe<Scalars['Int']>;
  readonly country: CountryName;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  /** Influencer Id */
  readonly id: Scalars['Int'];
  readonly influencerEngagement?: Maybe<Scalars['Int']>;
  readonly influencerEngagementRate?: Maybe<Scalars['Float']>;
  readonly influencerFollowers?: Maybe<Scalars['Int']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  /** Social Account System Id */
  readonly socialAccountId: Scalars['Int'];
  /** Social Account Status */
  readonly socialAccountStatus: SocialAccountStatus;
  readonly views?: Maybe<Scalars['Int']>;
};

export type FacebookOauthInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload = {
  readonly end: Scalars['Int'];
  readonly start: Scalars['Int'];
};

export type FacebookPageAnalyticsAudienceAgeBreakdownPayload = {
  readonly ageGroup: ReadonlyArray<FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  readonly femaleRate: ReadonlyArray<Scalars['Float']>;
  readonly maleRate: ReadonlyArray<Scalars['Float']>;
  readonly otherRate: ReadonlyArray<Scalars['Float']>;
};

export type FacebookPageAnalyticsAudienceGenderBreakdownPayload = {
  readonly femaleRate: Scalars['Float'];
  readonly maleRate: Scalars['Float'];
  readonly otherRate: Scalars['Float'];
};

export type FacebookPageAnalyticsAudiencePayload = {
  readonly ageRate: FacebookPageAnalyticsAudienceAgeBreakdownPayload;
  readonly genderRate: FacebookPageAnalyticsAudienceGenderBreakdownPayload;
  readonly regionRate: ReadonlyArray<FacebookPageAnalyticsAudienceRegionPayload>;
};

export type FacebookPageAnalyticsAudienceRegionPayload = {
  readonly name: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type FacebookPageAnalyticsOverviewHistoryItem = {
  readonly count: Scalars['Int'];
  readonly date: Scalars['Date'];
};

export type FacebookPageAnalyticsOverviewHistoryRateItem = {
  readonly count: Scalars['Float'];
  readonly date: Scalars['Date'];
};

export type FacebookPageAnalyticsOverviewItem = {
  readonly growth: Scalars['Long'];
  readonly history: ReadonlyArray<FacebookPageAnalyticsOverviewHistoryItem>;
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Long'];
};

export type FacebookPageAnalyticsOverviewPayload = {
  readonly comment: FacebookPageAnalyticsOverviewItem;
  readonly engagement: FacebookPageAnalyticsOverviewItem;
  readonly engagementRate: FacebookPageAnalyticsOverviewRateItem;
  readonly follower: FacebookPageAnalyticsOverviewItem;
  readonly impression: FacebookPageAnalyticsOverviewItem;
  readonly lastUpdated: Scalars['DateTime'];
  readonly pageCta: FacebookPageAnalyticsOverviewItem;
  readonly pageImpression: FacebookPageAnalyticsOverviewItem;
  readonly pageLikes: FacebookPageAnalyticsOverviewItem;
  readonly post: FacebookPageAnalyticsOverviewItem;
  readonly reaction: FacebookPageAnalyticsOverviewItem;
  readonly share: FacebookPageAnalyticsOverviewItem;
  readonly view: FacebookPageAnalyticsOverviewItem;
};

export type FacebookPageAnalyticsOverviewRateItem = {
  readonly growth: Scalars['Float'];
  readonly history: ReadonlyArray<FacebookPageAnalyticsOverviewHistoryRateItem>;
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Float'];
};

export type FacebookPageAnalyticsPostComment = {
  readonly commentId: Scalars['String'];
  readonly content: Scalars['String'];
  readonly posterName: Scalars['String'];
};

export type FacebookPageAnalyticsPostPayload = {
  readonly adImpressions: Scalars['Long'];
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly image?: Maybe<Scalars['String']>;
  readonly postComments: ReadonlyArray<FacebookPageAnalyticsPostComment>;
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly reactions: Scalars['Int'];
  readonly shares: Scalars['Int'];
  readonly subscribers: Scalars['Int'];
  readonly views: Scalars['Int'];
};

export type FacebookPageAnalyticsPostWithPageDetailsPayload = {
  readonly adImpressions: Scalars['Long'];
  readonly avatar: Scalars['String'];
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly image?: Maybe<Scalars['String']>;
  readonly pageName: Scalars['String'];
  readonly postComments: ReadonlyArray<FacebookPageAnalyticsPostComment>;
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly reactions: Scalars['Int'];
  readonly shares: Scalars['Int'];
  readonly subscribers: Scalars['Int'];
  readonly views: Scalars['Int'];
};

export type FacebookPageAnalyticsPostsAnalyticsPayload = {
  readonly averageEngagement?: Maybe<FacebookPageAnalyticsPostsAverageEngagement>;
  readonly fanActivity: ReadonlyArray<FacebookPagePostsAnalyticsFanActivity>;
};

export type FacebookPageAnalyticsPostsAverageEngagement = {
  readonly averageComments?: Maybe<FacebookPageStatisticsData>;
  readonly averageImpressions?: Maybe<FacebookPageStatisticsData>;
  readonly averagePostsPerWeek?: Maybe<FacebookPageStatisticsData>;
  readonly averageReactions?: Maybe<FacebookPageStatisticsData>;
  readonly averageShares?: Maybe<FacebookPageStatisticsData>;
  readonly averageViews?: Maybe<FacebookPageStatisticsData>;
};

export type FacebookPageAnalyticsPostsByDatePayload = {
  readonly avatar: Scalars['String'];
  readonly followers: Scalars['Int'];
  readonly pageName: Scalars['String'];
  readonly posts: ReadonlyArray<FacebookPageAnalyticsPostPayload>;
};

export type FacebookPageAnalyticsPostsPayload = {
  readonly adImpressions: Scalars['Long'];
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly engagement: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly image?: Maybe<Scalars['String']>;
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly reactions: Scalars['Int'];
  readonly shares: Scalars['Int'];
  readonly views: Scalars['Int'];
};

export type FacebookPageAnalyticsPostsPayloads = {
  readonly posts: ReadonlyArray<FacebookPageAnalyticsPostsPayload>;
};

export type FacebookPageAnalyticsPostsSortInput = {
  readonly field?: InputMaybe<FacebookPageAnalyticsPostsSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum FacebookPageAnalyticsPostsSortOrder {
  AD_IMPRESSIONS = 'AD_IMPRESSIONS',
  COMMENT = 'COMMENT',
  ENGAGEMENT = 'ENGAGEMENT',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  REACTIONS = 'REACTIONS',
  SHARE = 'SHARE',
  VIEWS = 'VIEWS'
}

export type FacebookPageForProfileV2 = {
  readonly analyticsEnabled: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
};

export type FacebookPageInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly analyticsWarning: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly comments?: Maybe<Scalars['Int']>;
  readonly country: CountryName;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  /** Influencer Id */
  readonly id: Scalars['Int'];
  readonly influencerEngagement?: Maybe<Scalars['Int']>;
  readonly influencerEngagementRate?: Maybe<Scalars['Float']>;
  readonly influencerFollowers?: Maybe<Scalars['Int']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  /** Social Account System Id */
  readonly socialAccountId: Scalars['Int'];
  /** Social Account Status */
  readonly socialAccountStatus: SocialAccountStatus;
  readonly views?: Maybe<Scalars['Int']>;
};

export type FacebookPageInfluencerSearchResultV2 = {
  readonly facebookPages: ReadonlyArray<FacebookPageInfluencerV2>;
  readonly totalNumber: Scalars['Int'];
};

export type FacebookPageInfluencerV2 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly analyticsWarning?: Maybe<Scalars['Boolean']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly bio?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  readonly id: Scalars['ID'];
  readonly influencerEngagement?: Maybe<Scalars['Int']>;
  readonly influencerEngagementRate?: Maybe<Scalars['Float']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  readonly socialAccountId: Scalars['ID'];
  readonly socialAccountStatus: SocialAccountStatus;
};

export type FacebookPagePostsAnalyticsFanActivity = {
  readonly hour: Scalars['Int'];
  readonly onlineCount: Scalars['Int'];
  readonly weekday: Scalars['Int'];
};

export type FacebookPageStatisticsData = {
  readonly growth: Scalars['Float'];
  readonly value: Scalars['Float'];
};

export type FacebookPostForLinkBio = {
  readonly id: Scalars['ID'];
  readonly thumbnail: Scalars['String'];
  readonly totalPostsCount: Scalars['Int'];
};

export type FanActivity = {
  readonly activityType: FanActivityType;
  readonly changes: ReadonlyArray<ActivityChange>;
  readonly commenterId?: Maybe<Scalars['Long']>;
  readonly created: Scalars['String'];
  readonly id: Scalars['Long'];
};

export enum FanActivityType {
  COMMENT_CREATE = 'COMMENT_CREATE',
  FAN_CREATE = 'FAN_CREATE',
  FAN_UPDATE = 'FAN_UPDATE',
  ORDER_CANCEL = 'ORDER_CANCEL',
  ORDER_CREATE = 'ORDER_CREATE',
  ORDER_UPDATE = 'ORDER_UPDATE'
}

export type FanDetails = {
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly countryId?: Maybe<Scalars['String']>;
  readonly created: Scalars['String'];
  readonly email?: Maybe<Scalars['String']>;
  readonly id: Scalars['Long'];
  readonly lineAccount?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly shopifyAccount?: Maybe<Scalars['Long']>;
  readonly tags: ReadonlyArray<FanTag>;
};

export type FanFormAnswer = {
  readonly answerOptionTitles: ReadonlyArray<Scalars['String']>;
  readonly answerText?: Maybe<Scalars['String']>;
  readonly order: Scalars['Int'];
  readonly questionId: Scalars['Long'];
  readonly questionTitle: Scalars['String'];
  readonly questionType: QuestionType;
};

export enum FanGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER'
}

export type FanListOrderBy = {
  readonly field: FanListSortableField;
  readonly order: Order;
};

export enum FanListSortableField {
  CREATED = 'CREATED'
}

export type FanOrderDetails = {
  readonly currency: Scalars['String'];
  readonly items: ReadonlyArray<FanOrderItem>;
  readonly orderId: Scalars['String'];
  readonly orderTime: Scalars['String'];
  readonly paymentStatus: ShopifyOrderPaymentStatus;
  readonly shippingStatus?: Maybe<ShopifyOrderShippingStatus>;
  readonly total: Scalars['Float'];
};

export type FanOrderItem = {
  readonly image?: Maybe<Scalars['String']>;
  readonly price: Scalars['Float'];
  readonly productName: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly variant?: Maybe<Scalars['String']>;
};

export type FanTag = {
  readonly id: Scalars['Long'];
  readonly tag: Scalars['String'];
};

export type FileUploadUrls = {
  readonly fileUploadUrls: ReadonlyArray<SignedUrlData>;
};

export enum FilterByDateRange {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS'
}

export enum FilterByNumber {
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN'
}

export type FollowersAgeGenderGroup = {
  readonly ageGroup?: Maybe<ReadonlyArray<FollowersAgeGroup>>;
  readonly femaleRate?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly maleRate?: Maybe<ReadonlyArray<Scalars['Float']>>;
};

export type FollowersAgeGroup = {
  readonly end: Scalars['Int'];
  readonly start: Scalars['Int'];
};

export type FollowersAgeGroupInput = {
  readonly end: Scalars['Int'];
  readonly start: Scalars['Int'];
};

export type FollowersByTime = {
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly time: Scalars['DateTime'];
};

export type FollowersCountry = {
  /** Country ID */
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export enum FollowersGrowthPeriodEnum {
  ONE_MONTH = 'ONE_MONTH',
  ONE_YEAR = 'ONE_YEAR',
  SIX_MONTHS = 'SIX_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS'
}

export enum FontSize {
  LG = 'LG',
  MD = 'MD',
  SM = 'SM',
  XL = 'XL',
  XXL = 'XXL'
}

export type FormItem = {
  readonly id: Scalars['Long'];
  readonly lastUpdated: Scalars['String'];
  readonly status: FormStatus;
  readonly submissionCount: Scalars['Long'];
  readonly title: Scalars['String'];
};

export type FormListOrderBy = {
  readonly field?: InputMaybe<FormListSortableField>;
  readonly order?: InputMaybe<Order>;
};

export enum FormListSortableField {
  CREATED = 'CREATED',
  TITLE = 'TITLE',
  UPDATED = 'UPDATED'
}

export type FormQuestionAnswer = {
  readonly answerOptionIds: ReadonlyArray<Scalars['Long']>;
  readonly answerText?: Maybe<Scalars['String']>;
  readonly questionId: Scalars['Long'];
  readonly questionTitle: Scalars['String'];
  readonly questionType: QuestionType;
};

export type FormReportAnswerOption = {
  readonly count: Scalars['Int'];
  readonly optionTitle: Scalars['String'];
};

export type FormReportQuestionAnswer = {
  readonly answerOptions: ReadonlyArray<FormReportAnswerOption>;
  readonly answerTexts: ReadonlyArray<Scalars['String']>;
  readonly numberResponses: Scalars['Int'];
  readonly questionId: Scalars['Long'];
  readonly questionTitle: Scalars['String'];
  readonly questionType: QuestionType;
};

export type FormReportSummaryInput = {
  readonly id: Scalars['Long'];
};

export type FormReportSummaryPayload = {
  readonly answers: ReadonlyArray<FormReportQuestionAnswer>;
};

export type FormRespondentItem = {
  readonly email?: Maybe<Scalars['String']>;
  readonly fanId?: Maybe<Scalars['Long']>;
  readonly firstName: Scalars['String'];
  readonly lastName?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Scalars['Long']>;
  readonly submissionId: Scalars['Long'];
};

export type FormRespondentsCountInput = {
  readonly id: Scalars['Long'];
};

export type FormRespondentsCountPayload = {
  readonly total: Scalars['Long'];
};

export type FormRespondentsListInput = {
  readonly id: Scalars['Long'];
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
};

export type FormRespondentsListPayload = {
  readonly respondents: ReadonlyArray<FormRespondentItem>;
};

export enum FormStatus {
  DRAFT = 'DRAFT',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type GeminiMessage = {
  /** value will return when status is READY_TO_SEARCH */
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  /** for debugging */
  readonly jsonResponses?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly nextAction: GeminiNextAction;
  readonly text?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum GeminiNextAction {
  COLLECTING = 'COLLECTING',
  ERROR = 'ERROR',
  READY_TO_SEARCH = 'READY_TO_SEARCH'
}

/** An enumeration. */
export enum GeminiSourceFrom {
  ALL_INFLUENCERS_TAB = 'ALL_INFLUENCERS_TAB',
  INSTAGRAM_TAB = 'INSTAGRAM_TAB',
  TIKTOK_TAB = 'TIKTOK_TAB',
  YOUTUBE_TAB = 'YOUTUBE_TAB'
}

export enum Genders {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  UNKNOWN = 'UNKNOWN',
  UPDATE_LATER = 'UPDATE_LATER'
}

export type GenerateImportFansUploadURLPayload = {
  readonly fileName: Scalars['String'];
  readonly signedUrl: Scalars['String'];
};

export type GenerateMarketplaceCampaignDetailInput = {
  readonly campaignType: MarketplaceCampaignDetailType;
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId: Scalars['String'];
  readonly productUrl?: InputMaybe<Scalars['String']>;
  readonly prompt?: InputMaybe<Scalars['String']>;
};

export type GenerateMarketplaceCampaignDetailPayload = {
  readonly requestId: Scalars['ID'];
};

export enum GenerateMarketplaceCampaignDetailStatus {
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
  SUCCEEDED = 'SUCCEEDED'
}

export type GenerateMarketplacePostCaptionInput = {
  readonly marketplaceCampaignId: Scalars['ID'];
  readonly userInputCaption?: InputMaybe<Scalars['String']>;
};

export type GeneratePartnerInfluencerSignUpUrl = {
  readonly url: Scalars['String'];
};

export type GenerateTalentInfluencerSignUpUrl = {
  readonly url: Scalars['String'];
};

export type GenerateTikTokSpecialPostCaptionInput = {
  readonly tikTokSpecialCampaignId: Scalars['ID'];
  readonly userInputCaption?: InputMaybe<Scalars['String']>;
};

export type GetAccountInformationPayload = {
  readonly brandName?: Maybe<Scalars['String']>;
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly countryId: Scalars['String'];
  readonly dateOfBirth?: Maybe<Scalars['String']>;
  readonly gender: Genders;
  readonly introduce: Scalars['String'];
  readonly name: Scalars['String'];
  readonly regionId?: Maybe<Scalars['Int']>;
};

export type GetAllCampaignsCountForSearchJobInput = {
  /**  new filters */
  readonly anyCreatorCategoryId?: InputMaybe<Scalars['Int']>;
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly keyword?: InputMaybe<Scalars['String']>;
  readonly maxPrice?: InputMaybe<Scalars['Float']>;
  readonly maxReward?: InputMaybe<Scalars['Float']>;
  readonly minPrice?: InputMaybe<Scalars['Float']>;
  readonly minReward?: InputMaybe<Scalars['Float']>;
  readonly needOnlyAvailableJobs?: InputMaybe<Scalars['Boolean']>;
  readonly participationTypes?: InputMaybe<ReadonlyArray<SearchJobFilterParticipationType>>;
  /**  deprecated */
  readonly promotionMethods: ReadonlyArray<CampaignSocialMediaType>;
  readonly revenueConditions?: InputMaybe<ReadonlyArray<SearchJobFilterRevenueCondition>>;
  readonly revenueTypes?: InputMaybe<ReadonlyArray<SearchJobFilterRevenueType>>;
};

export type GetAllCampaignsCountForSearchJobPayload = {
  readonly count?: Maybe<Scalars['Int']>;
};

export type GetAllCampaignsForSearchJobInput = {
  /**  new filters */
  readonly anyCreatorCategoryId?: InputMaybe<Scalars['Int']>;
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly keyword?: InputMaybe<Scalars['String']>;
  readonly limit: Scalars['Int'];
  readonly maxPrice?: InputMaybe<Scalars['Float']>;
  readonly maxReward?: InputMaybe<Scalars['Float']>;
  readonly minPrice?: InputMaybe<Scalars['Float']>;
  readonly minReward?: InputMaybe<Scalars['Float']>;
  readonly needOnlyAvailableJobs?: InputMaybe<Scalars['Boolean']>;
  readonly offset: Scalars['Int'];
  readonly orderBy: AllCampaignsSearchJobsOrderBy;
  readonly participationTypes?: InputMaybe<ReadonlyArray<SearchJobFilterParticipationType>>;
  /**  deprecated */
  readonly promotionMethods: ReadonlyArray<CampaignSocialMediaType>;
  readonly revenueConditions?: InputMaybe<ReadonlyArray<SearchJobFilterRevenueCondition>>;
  readonly revenueTypes?: InputMaybe<ReadonlyArray<SearchJobFilterRevenueType>>;
};

export type GetAllCampaignsForSearchJobPayload = {
  readonly campaigns: ReadonlyArray<CampaignsForSearchJobPayload>;
};

export type GetAnyXAccountsByKeywordInput = {
  readonly countryId: Scalars['String'];
  readonly keyword: Scalars['String'];
};

export type GetAnyXAccountsByKeywordPayload = {
  readonly accounts: ReadonlyArray<AnyXAccountInfoPayload>;
};

export type GetAutoManagedCampaignByIdInput = {
  readonly id: Scalars['Int'];
};

export type GetAutoManagedCampaignForInfluencerSearchJobInput = {
  readonly id: Scalars['Int'];
};

export type GetAutoManagedCampaignForInfluencerYourJobInput = {
  readonly id: Scalars['Int'];
};

export type GetAutoManagedCampaignsCountInput = {
  readonly categoryIds: ReadonlyArray<Scalars['ID']>;
  readonly countryId?: InputMaybe<Scalars['String']>;
  readonly keyword?: InputMaybe<Scalars['String']>;
  readonly marketplaceType?: InputMaybe<AutoManagedCampaignType>;
  readonly maxCommissionRate?: InputMaybe<Scalars['Float']>;
  readonly maxPrice?: InputMaybe<Scalars['Float']>;
  readonly minCommissionRate?: InputMaybe<Scalars['Float']>;
  readonly minPrice?: InputMaybe<Scalars['Float']>;
  readonly status?: InputMaybe<AutoManagedCampaignStatus>;
};

export type GetAutoManagedCampaignsCountPayload = {
  readonly count: Scalars['Int'];
};

export type GetAutoManagedCampaignsInput = {
  readonly categoryIds: ReadonlyArray<Scalars['ID']>;
  readonly countryId?: InputMaybe<Scalars['String']>;
  readonly keyword?: InputMaybe<Scalars['String']>;
  readonly limit: Scalars['Int'];
  readonly marketplaceType?: InputMaybe<AutoManagedCampaignType>;
  readonly maxCommissionRate?: InputMaybe<Scalars['Float']>;
  readonly maxPrice?: InputMaybe<Scalars['Float']>;
  readonly minCommissionRate?: InputMaybe<Scalars['Float']>;
  readonly minPrice?: InputMaybe<Scalars['Float']>;
  readonly offset: Scalars['Int'];
  readonly orderBy: GetAutoManagedCampaignsOrderBy;
  readonly status?: InputMaybe<AutoManagedCampaignStatus>;
};

export type GetAutoManagedCampaignsOrderBy = {
  readonly field?: InputMaybe<GetAutoManagedCampaignsSortField>;
  readonly order?: InputMaybe<Order>;
};

export type GetAutoManagedCampaignsPayload = {
  readonly campaigns: ReadonlyArray<AutoManagedCampaignInfoPayload>;
};

export enum GetAutoManagedCampaignsSortField {
  COMMISSION_RATE = 'COMMISSION_RATE',
  JOINED_CREATORS = 'JOINED_CREATORS',
  ORDERS = 'ORDERS',
  SALES = 'SALES'
}

export type GetChatEventsInput = {
  readonly chatId: Scalars['String'];
  readonly keyword?: InputMaybe<Scalars['String']>;
  readonly newerThan?: InputMaybe<Scalars['String']>;
  readonly olderThan?: InputMaybe<Scalars['String']>;
};

export type GetChatEventsPayload = {
  readonly chatEvents: ReadonlyArray<ChatEvent>;
  readonly lastCheckedAt?: Maybe<Scalars['String']>;
  readonly userName: Scalars['String'];
};

export type GetCreatorTagsByIdInput = {
  readonly tagIds: ReadonlyArray<Scalars['Long']>;
};

export type GetCreatorTagsByIdPayload = {
  readonly tags: ReadonlyArray<FanTag>;
};

export type GetCreatorTagsInput = {
  readonly keyword: Scalars['String'];
};

export type GetCreatorTagsPayload = {
  readonly tags: ReadonlyArray<FanTag>;
};

export type GetCustomerTagsInput = {
  readonly order: CustomerTagOrder;
};

export type GetCustomerTagsPayload = {
  readonly tags: ReadonlyArray<CustomerTagPayload>;
};

export type GetEmailListCountInput = {
  readonly keyword: Scalars['String'];
  readonly status?: InputMaybe<EmailTemplateStatus>;
};

export type GetEmailListCountPayload = {
  readonly total: Scalars['Long'];
};

export type GetEmailListInput = {
  readonly keyword: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
  readonly orderBy: EmailListOrderBy;
  readonly status?: InputMaybe<EmailTemplateStatus>;
};

export type GetEmailListPayload = {
  readonly emails: ReadonlyArray<EmailInfoForList>;
};

export type GetEmailOverviewInput = {
  readonly emailId: Scalars['Long'];
};

export type GetEmailOverviewPayload = {
  readonly info: EmailInfo;
  readonly stats: EmailStatistics;
};

export type GetEmailRecipientFan = {
  readonly email?: Maybe<Scalars['String']>;
  readonly id: Scalars['Long'];
  readonly name: Scalars['String'];
};

export type GetEmailRecipientTag = {
  readonly count: Scalars['Long'];
  readonly id: Scalars['Long'];
  readonly tag: Scalars['String'];
};

export type GetEmailRecipientsCountInput = {
  readonly emailId: Scalars['Long'];
  readonly keyword: Scalars['String'];
  readonly status?: InputMaybe<EmailEventStatusForSearch>;
};

export type GetEmailRecipientsCountPayload = {
  readonly total: Scalars['Long'];
};

export type GetEmailRecipientsInput = {
  readonly emailId: Scalars['Long'];
  readonly keyword: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
  readonly status?: InputMaybe<EmailEventStatusForSearch>;
};

export type GetEmailRecipientsPayload = {
  readonly recipients: ReadonlyArray<RecipientDetail>;
};

export type GetEmailTemplateInput = {
  readonly id: Scalars['Long'];
};

export type GetEmailTemplatePayload = {
  readonly fans: ReadonlyArray<Scalars['Long']>;
  readonly id: Scalars['Long'];
  readonly message: Scalars['String'];
  readonly scheduleDate?: Maybe<Scalars['String']>;
  readonly sendNow?: Maybe<Scalars['Boolean']>;
  readonly sender: Scalars['String'];
  readonly status: EmailTemplateStatus;
  readonly subject: Scalars['String'];
  readonly tags: ReadonlyArray<Scalars['Long']>;
};

export type GetFanActivitiesInput = {
  readonly fanId: Scalars['Long'];
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
};

export type GetFanActivitiesPayload = {
  readonly activities: ReadonlyArray<FanActivity>;
  readonly total: Scalars['Int'];
};

export type GetFanFormResponseInput = {
  readonly fanId: Scalars['Long'];
  readonly submissionId: Scalars['Long'];
};

export type GetFanFormResponsePayload = {
  readonly answers: ReadonlyArray<FanFormAnswer>;
  readonly formId: Scalars['Long'];
  readonly formTitle: Scalars['String'];
};

export type GetFanHeaderPayload = {
  readonly avatar: Scalars['String'];
  readonly created: Scalars['String'];
  readonly currencyId?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly id: Scalars['Long'];
  readonly lastName: Scalars['String'];
  readonly lastOrder?: Maybe<Scalars['String']>;
  readonly lineAccount?: Maybe<Scalars['String']>;
  readonly shopifyAccount?: Maybe<Scalars['Long']>;
  readonly totalOrders?: Maybe<Scalars['Int']>;
  readonly totalSpent?: Maybe<Scalars['Float']>;
};

export type GetFanInput = {
  readonly fanId: Scalars['Long'];
};

export type GetFanOrdersInput = {
  readonly fanId: Scalars['Long'];
};

export type GetFanOrdersPayload = {
  readonly orders: ReadonlyArray<FanOrderDetails>;
};

export type GetFanParticipationFormListInput = {
  readonly fanId: Scalars['Long'];
};

export type GetFanParticipationFormListPayload = {
  readonly forms: ReadonlyArray<ParticipationForm>;
};

export type GetFanPayload = {
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly birthday?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly countryId?: Maybe<Scalars['String']>;
  readonly editable: Scalars['Boolean'];
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly gender?: Maybe<FanGender>;
  readonly id: Scalars['Long'];
  readonly lastName: Scalars['String'];
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly province?: Maybe<Scalars['String']>;
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type GetFanProvincesInput = {
  readonly countryId: Scalars['String'];
};

export type GetFansCountInput = {
  readonly createdDate?: InputMaybe<FilterByDateRange>;
  readonly exactAmountSpent?: InputMaybe<Scalars['Float']>;
  readonly exactNumberOfOrders?: InputMaybe<Scalars['Int']>;
  readonly keyword: Scalars['String'];
  readonly lastOrderDate?: InputMaybe<FilterByDateRange>;
  readonly maxAmountSpent?: InputMaybe<Scalars['Float']>;
  readonly maxNumberOfOrders?: InputMaybe<Scalars['Int']>;
  readonly minAmountSpent?: InputMaybe<Scalars['Float']>;
  readonly minNumberOfOrders?: InputMaybe<Scalars['Int']>;
  readonly tagIds: ReadonlyArray<Scalars['Long']>;
};

export type GetFansCountPayload = {
  readonly total: Scalars['Int'];
};

export type GetFansInput = {
  readonly createdDate?: InputMaybe<FilterByDateRange>;
  readonly exactAmountSpent?: InputMaybe<Scalars['Float']>;
  readonly exactNumberOfOrders?: InputMaybe<Scalars['Int']>;
  readonly keyword: Scalars['String'];
  readonly lastOrderDate?: InputMaybe<FilterByDateRange>;
  readonly limit: Scalars['Int'];
  readonly maxAmountSpent?: InputMaybe<Scalars['Float']>;
  readonly maxNumberOfOrders?: InputMaybe<Scalars['Int']>;
  readonly minAmountSpent?: InputMaybe<Scalars['Float']>;
  readonly minNumberOfOrders?: InputMaybe<Scalars['Int']>;
  readonly offset: Scalars['Int'];
  readonly orderBy: FanListOrderBy;
  readonly tagIds: ReadonlyArray<Scalars['Long']>;
};

export type GetFansPayload = {
  readonly fans: ReadonlyArray<FanDetails>;
};

export type GetFormInput = {
  readonly id: Scalars['Long'];
};

export type GetFormInputPayload = {
  readonly description: Scalars['String'];
  readonly hash: Scalars['String'];
  readonly id: Scalars['Long'];
  readonly questions: ReadonlyArray<GetFormQuestion>;
  readonly status: FormStatus;
  readonly thankDescription: Scalars['String'];
  readonly thankTitle: Scalars['String'];
  readonly title: Scalars['String'];
};

export type GetFormQuestion = {
  readonly id: Scalars['Long'];
  readonly image?: Maybe<Scalars['String']>;
  readonly isRequired: Scalars['Boolean'];
  readonly options: ReadonlyArray<GetFormQuestionOption>;
  readonly order: Scalars['Int'];
  readonly questionType: QuestionType;
  readonly title: Scalars['String'];
};

export type GetFormQuestionOption = {
  readonly id: Scalars['Long'];
  readonly optionTitle: Scalars['String'];
  readonly order: Scalars['Int'];
};

export type GetFormResponseByPageInput = {
  readonly formId: Scalars['Long'];
  readonly page: Scalars['Long'];
};

export type GetFormResponseInput = {
  readonly submissionId: Scalars['Long'];
};

export type GetFormResponsePayload = {
  readonly answers: ReadonlyArray<FormQuestionAnswer>;
  readonly created: Scalars['String'];
  readonly formTitle: Scalars['String'];
  readonly submissionId: Scalars['Long'];
};

export type GetFormTemplateInput = {
  readonly hash: Scalars['String'];
};

export type GetFormTemplateOption = {
  readonly id: Scalars['Long'];
  readonly optionTitle: Scalars['String'];
  readonly order: Scalars['Int'];
};

export type GetFormTemplatePayload = {
  readonly description: Scalars['String'];
  readonly id: Scalars['Long'];
  readonly questions: ReadonlyArray<GetFormTemplateQuestion>;
  readonly thankDescription: Scalars['String'];
  readonly thankTitle: Scalars['String'];
  readonly title: Scalars['String'];
};

export type GetFormTemplateQuestion = {
  readonly id: Scalars['Long'];
  readonly image?: Maybe<Scalars['String']>;
  readonly isRequired: Scalars['Boolean'];
  readonly options: ReadonlyArray<GetFormTemplateOption>;
  readonly order: Scalars['Int'];
  readonly questionType: QuestionType;
  readonly title: Scalars['String'];
};

export type GetFormsCountInput = {
  readonly keyword: Scalars['String'];
  readonly status?: InputMaybe<FormStatus>;
};

export type GetFormsCountPayload = {
  readonly total: Scalars['Long'];
};

export type GetFormsListInput = {
  readonly keyword: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
  readonly orderBy: FormListOrderBy;
  readonly status?: InputMaybe<FormStatus>;
};

export type GetFormsListPayload = {
  readonly forms: ReadonlyArray<FormItem>;
};

export type GetGeneratedMarketplaceCampaignDetailInput = {
  readonly requestId: Scalars['ID'];
};

export type GetGeneratedMarketplaceCampaignDetailPayload = {
  readonly generatedDetail?: Maybe<GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail>;
  readonly status: GenerateMarketplaceCampaignDetailStatus;
};

export type GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail = {
  readonly requirement: Scalars['String'];
  readonly serviceInformation: Scalars['String'];
  readonly title: Scalars['String'];
};

export type GetGeneratedMarketplacePostCaptionInput = {
  readonly marketplaceCampaignId: Scalars['ID'];
};

export type GetGeneratedMarketplacePostCaptionPayload = {
  readonly attemptsRemaining: Scalars['Int'];
  readonly generatedCaption?: Maybe<Scalars['String']>;
  readonly recentRequestStatus: GetGeneratedPostCaptionRecentRequestStatus;
};

export enum GetGeneratedPostCaptionRecentRequestStatus {
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_REQUESTED = 'NOT_REQUESTED',
  SUCCEEDED = 'SUCCEEDED'
}

export type GetGeneratedTikTokSpecialPostCaptionInput = {
  readonly tikTokSpecialCampaignId: Scalars['ID'];
};

export type GetGeneratedTikTokSpecialPostCaptionPayload = {
  readonly attemptsRemaining: Scalars['Int'];
  readonly generatedCaption?: Maybe<Scalars['String']>;
  readonly recentRequestStatus: GetGeneratedPostCaptionRecentRequestStatus;
};

export type GetInfluencerBioAnalyticsInput = {
  readonly endDate: Scalars['String'];
  readonly startDate: Scalars['String'];
};

export type GetInfluencerBioAnalyticsPayload = {
  readonly audience?: Maybe<InfluencerBioAnalyticsAudience>;
  readonly clicks?: Maybe<InfluencerBioAnalyticsOverview>;
  readonly sessions?: Maybe<InfluencerBioAnalyticsOverview>;
  readonly views?: Maybe<InfluencerBioAnalyticsOverview>;
};

export type GetInfluencerBioPayload = {
  readonly id: Scalars['Int'];
  readonly isPublic: Scalars['Boolean'];
  readonly linkName: Scalars['String'];
  readonly sections: ReadonlyArray<BioSection>;
};

export type GetInfluencerBioPayloadV2 = {
  readonly id: Scalars['Int'];
  readonly isFinish: Scalars['Boolean'];
  readonly isPublic: Scalars['Boolean'];
  readonly linkName?: Maybe<Scalars['String']>;
  readonly profile: BioInfluencerProfile;
  readonly sections: ReadonlyArray<BioSectionV2>;
  readonly theme?: Maybe<BioTheme>;
  readonly themeV2?: Maybe<BioThemeV2>;
};

export type GetInfluencerChatServicesPayload = {
  readonly line?: Maybe<GetLineServicePayload>;
};

export type GetLineBroadcastEstimateRecipientsInput = {
  readonly recipientType: RecipientType;
  readonly tagNames: ReadonlyArray<Scalars['String']>;
};

export type GetLineBroadcastEstimateRecipientsPayload = {
  readonly total: Scalars['Int'];
};

export type GetLineBroadcastMessageInput = {
  readonly lineBroadcastMessageId: Scalars['String'];
};

export type GetLineBroadcastMessagePayload = {
  readonly content: ReadonlyArray<LineContentItemPayload>;
  readonly createdDateTime: Scalars['String'];
  readonly deliveredDateTime?: Maybe<Scalars['String']>;
  readonly deliveryTiming: DeliveryTimingItemPayload;
  readonly id: Scalars['String'];
  readonly imageUrl: Scalars['String'];
  readonly recipient: RecipientItemPayload;
  readonly status?: Maybe<LineBroadcastStatus>;
  readonly title: Scalars['String'];
};

export type GetLineBroadcastSummaryInput = {
  readonly from?: InputMaybe<Scalars['String']>;
  readonly to?: InputMaybe<Scalars['String']>;
};

export type GetLineBroadcastSummaryPayload = {
  readonly clickRate: LineBroadcastFloatValue;
  readonly delivered: LineBroadcastIntValue;
  readonly openRate: LineBroadcastFloatValue;
};

export type GetLineBroadcastsCountInput = {
  readonly from?: InputMaybe<Scalars['String']>;
  readonly keyword?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<LineBroadcastStatus>;
  readonly tag?: InputMaybe<Scalars['String']>;
  readonly to?: InputMaybe<Scalars['String']>;
};

export type GetLineBroadcastsCountPayload = {
  readonly total: Scalars['Int'];
};

export type GetLineBroadcastsInput = {
  readonly from?: InputMaybe<Scalars['String']>;
  readonly keyword?: InputMaybe<Scalars['String']>;
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
  readonly status?: InputMaybe<LineBroadcastStatus>;
  readonly tag?: InputMaybe<Scalars['String']>;
  readonly to?: InputMaybe<Scalars['String']>;
};

export type GetLineBroadcastsPayload = {
  readonly broadcasts: ReadonlyArray<LineBroadcast>;
};

export type GetLineChannelPayload = {
  readonly id: Scalars['Long'];
  readonly lineChannelId: Scalars['String'];
  readonly lineChannelSecret: Scalars['String'];
  readonly lineKid: Scalars['String'];
  readonly publicKey: Scalars['String'];
  readonly publicKeyId: Scalars['Long'];
};

export type GetLineChatCountInput = {
  readonly keyword: Scalars['String'];
};

export type GetLineChatCountPayload = {
  readonly activatedCount: Scalars['Int'];
  readonly assignedToMe: Scalars['Int'];
};

export type GetLineChatInput = {
  readonly chatId: Scalars['String'];
};

export type GetLineChatPayload = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly chatId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type GetLineChatsInput = {
  readonly keyword: Scalars['String'];
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
  readonly tagName?: InputMaybe<Scalars['String']>;
  readonly typeFilter: CRMChatTypeFilter;
};

export type GetLineChatsPayload = {
  readonly chats: ReadonlyArray<LineChat>;
};

export type GetLineLoginDetailPayload = {
  readonly liffId?: Maybe<Scalars['String']>;
  readonly lineLoginChannelId?: Maybe<Scalars['String']>;
};

export type GetLinePublicKeyPayload = {
  readonly id: Scalars['Long'];
  readonly publicKey: Scalars['String'];
};

export type GetLineServicePayload = {
  readonly liffUrl: Scalars['String'];
};

export type GetLinkBioFacebookAccountPostsInput = {
  readonly id: Scalars['ID'];
};

export type GetLinkBioFacebookAccountPostsPayload = {
  readonly posts: ReadonlyArray<GetLinkBioFacebookPostPayload>;
};

export type GetLinkBioFacebookPagePostsInput = {
  readonly id: Scalars['ID'];
};

export type GetLinkBioFacebookPagePostsPayload = {
  readonly posts: ReadonlyArray<GetLinkBioFacebookPostPayload>;
};

export type GetLinkBioFacebookPostPayload = {
  readonly postSystemId: Scalars['ID'];
  readonly thumbnail: Scalars['String'];
};

export type GetLinkBioThemeColorPayload = {
  readonly bg: LinkBioBackground;
  readonly button?: Maybe<BioButtonStyle>;
  readonly color: LinkBioPageColor;
  readonly font?: Maybe<BioFontStyle>;
  readonly theme?: Maybe<BioTheme>;
};

export type GetMessageTemplatesPayload = {
  readonly templates: ReadonlyArray<MessageTemplateItem>;
};

export type GetPublicInfluencerBioInput = {
  readonly linkName: Scalars['String'];
};

export type GetPublicInfluencerBioPayload = {
  readonly id: Scalars['Int'];
  readonly isPublic: Scalars['Boolean'];
  readonly profile: BioInfluencerProfile;
  readonly sections: ReadonlyArray<PublicBioSection>;
  readonly themeV2?: Maybe<BioThemeV2>;
  readonly themes?: Maybe<PublicBioTheme>;
};

export type GetSelectableYoutubeAnalyticsChannelsInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type GetShopifyAccountPayload = {
  readonly id: Scalars['Long'];
  readonly shopName: Scalars['String'];
};

export type GetTagsAndFansRecipientsInput = {
  readonly keyword: Scalars['String'];
};

export type GetTagsAndFansRecipientsPayload = {
  readonly fans: ReadonlyArray<GetEmailRecipientFan>;
  readonly tags: ReadonlyArray<GetEmailRecipientTag>;
};

export type GoogleSlideFile = {
  readonly date: Scalars['Date'];
  readonly slideUrl: Scalars['String'];
};

export type Hashtag = {
  readonly blocked: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type HashtagType = {
  readonly count?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['String']>;
};

export type HubspotCompanyData = {
  readonly id: Scalars['String'];
  readonly internalNetsuiteId?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type HubspotDealData = {
  readonly companyId: Scalars['String'];
  readonly country?: Maybe<Scalars['String']>;
  readonly dealName: Scalars['String'];
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly id: Scalars['String'];
  readonly ownerName?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Date']>;
};

export type HubspotDealDataForSearch = {
  readonly dealName: Scalars['String'];
  readonly id: Scalars['String'];
};

export type ImageInput = {
  readonly fileName: Scalars['String'];
  readonly mimeType: Scalars['String'];
  readonly previewUrl: Scalars['String'];
  readonly url: Scalars['String'];
};

export type ImageItemInput = {
  readonly imageUrl: Scalars['String'];
  readonly label?: InputMaybe<Scalars['String']>;
  readonly url: Scalars['String'];
};

export type ImageItemPayload = {
  readonly imageUrl: Scalars['String'];
  readonly label?: Maybe<Scalars['String']>;
  readonly url: Scalars['String'];
};

export type ImageMessageInput = {
  readonly images: ReadonlyArray<ImageItemInput>;
};

export type ImageMessagePayload = {
  readonly images: ReadonlyArray<ImageItemPayload>;
};

export type ImagePayload = {
  readonly fileName: Scalars['String'];
  readonly mimeType: Scalars['String'];
  readonly previewUrl: Scalars['String'];
  readonly url: Scalars['String'];
};

export type ImageVideoMessageInput = {
  readonly media: MediaItemInput;
};

export type ImageVideoMessagePayload = {
  readonly media: MediaItemPayload;
};

export type ImportFansInput = {
  readonly uploadFileUrl: Scalars['String'];
};

export type ImportFansPayload = {
  readonly ok: Scalars['Boolean'];
};

/**  TODO old API. don't forget to remove */
export type Influencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar: Scalars['String'];
  readonly categories: ReadonlyArray<CategoryName>;
  readonly country: CountryName;
  readonly engagement: InfluencerEngagement;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly facebook?: Maybe<SocialAccount>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  readonly id: Scalars['Int'];
  readonly instagram?: Maybe<SocialAccount>;
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
  readonly name: Scalars['String'];
  readonly status: SocialAccountStatus;
  readonly twitter?: Maybe<SocialAccount>;
  readonly youtube?: Maybe<SocialAccount>;
};

export type InfluencerAddYoutubeCompareAccountAnalyticsInput = {
  readonly socialAccountId: Scalars['Int'];
  readonly url: Scalars['String'];
};

export type InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload = {
  readonly reconnectStatuses: ReadonlyArray<InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload>;
};

export type InfluencerAnalyticsSponsoredPostCountPayload = {
  readonly total: Scalars['Long'];
};

export type InfluencerAnalyticsSponsoredPostPayload = {
  readonly averageEngagement: Scalars['Float'];
  readonly averageEngagementRate: Scalars['Float'];
  readonly posts: ReadonlyArray<InfluencerYoutubeSponsoredPost>;
};

export type InfluencerAnalyticsTwitterRefreshAvailabilityPayload = {
  readonly isRefreshAvailable: Scalars['Boolean'];
};

export type InfluencerAnalyticsTwitterRefreshInput = {
  readonly socialAccountId: Scalars['Int'];
};

export type InfluencerAnalyticsTwitterRefreshOutput = {
  readonly ok: Scalars['Boolean'];
};

export type InfluencerAudienceSection = {
  readonly ageGenderRates: FollowersAgeGenderGroup;
  readonly femaleRate?: Maybe<Scalars['Float']>;
  readonly maleRate?: Maybe<Scalars['Float']>;
};

export type InfluencerBioAnalyticsAudience = {
  readonly cities: ReadonlyArray<BioAnalyticsCity>;
  readonly countries: ReadonlyArray<BioAnalyticsCountry>;
  readonly otherLinkClicks: ReadonlyArray<BioAnalyticsOtherLinkClicks>;
  readonly referral: ReadonlyArray<BioAnalyticsReferral>;
  readonly socialMediaClicks: ReadonlyArray<BioAnalyticsSocialMediaClicks>;
  readonly totalClicks: ReadonlyArray<BioAnalyticsTotalClicks>;
};

export type InfluencerBioAnalyticsHistory = {
  readonly count: Scalars['Int'];
  readonly date: Scalars['String'];
};

export type InfluencerBioAnalyticsOverview = {
  readonly growth: Scalars['Int'];
  readonly history: ReadonlyArray<InfluencerBioAnalyticsHistory>;
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Int'];
};

export type InfluencerBrandAccounts = {
  readonly averageEngagement?: Maybe<Scalars['Float']>;
  readonly averageEngagementRate?: Maybe<Scalars['Float']>;
  readonly brandAccounts?: Maybe<ReadonlyArray<BrandAccount>>;
};

export type InfluencerCampaignDetail = {
  /** Campaign info */
  readonly campaignInfo: InfluencerCampaignDetailInfo;
  /** User currency */
  readonly currency: Scalars['String'];
  readonly joinedDate: Scalars['Date'];
  /** List of posted posts in the campaign */
  readonly posts: ReadonlyArray<InfluencerCampaignPostData>;
  /** Summary stats for campaign */
  readonly summary: InfluencerCampaignStat;
};

export type InfluencerCampaignDetailByPromotion = {
  /** Campaign info */
  readonly campaignInfo: InfluencerCampaignDetailInfoByPromotion;
  /** User currency */
  readonly currency: Scalars['String'];
  /** Summary stats for campaign */
  readonly summary: InfluencerCampaignStatByPromotion;
};

export type InfluencerCampaignDetailInfo = {
  readonly hasPostTracking?: Maybe<Scalars['Boolean']>;
  /** Campaign ID */
  readonly id?: Maybe<Scalars['Int']>;
  /** Campaign detail type for MKP */
  readonly mkpDetailType?: Maybe<MarketplaceCampaignDetailType>;
  /** Campaign status */
  readonly status: AllCampaignStatus;
  /** Campaign thumbnail */
  readonly thumbNail?: Maybe<Scalars['String']>;
  /** Campaign title */
  readonly title: Scalars['String'];
  /** Campaign type */
  readonly type: CampaignType;
};

export type InfluencerCampaignDetailInfoByPromotion = {
  readonly hasPostTracking?: Maybe<Scalars['Boolean']>;
  /** Campaign ID */
  readonly id?: Maybe<Scalars['Int']>;
  /** Campaign detail type for MKP */
  readonly mkpDetailType?: Maybe<MarketplaceCampaignDetailType>;
  readonly promotionMethod: CampaignPromotionMethod;
  /** Campaign thumbnail */
  readonly thumbNail?: Maybe<Scalars['String']>;
  /** Campaign title */
  readonly title: Scalars['String'];
  /** Campaign type */
  readonly type: CampaignType;
};

/** An enumeration. */
export enum InfluencerCampaignListSortField {
  CLICK = 'CLICK',
  COMMENT = 'COMMENT',
  CONVERSION = 'CONVERSION',
  COST = 'COST',
  CVR = 'CVR',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  JOINED_DATE = 'JOINED_DATE',
  LIKE = 'LIKE',
  REACH = 'REACH',
  SHARE = 'SHARE'
}

export type InfluencerCampaignOrderBy = {
  readonly field?: InputMaybe<InfluencerCampaignListSortField>;
  readonly order?: InputMaybe<ORDER>;
};

export type InfluencerCampaignPostData = {
  /** Post info */
  readonly postInfo: InfluencerCampaignPostInfo;
  /** Summary stats for post */
  readonly summary: InfluencerCampaignStat;
};

export type InfluencerCampaignPostInfo = {
  readonly content?: Maybe<Scalars['String']>;
  /** Post Id */
  readonly id: Scalars['Int'];
  readonly mkpCampaignType?: Maybe<MarketplaceCampaignType>;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly promotionMethods: ReadonlyArray<CampaignPromotionMethod>;
  readonly socialMedia: CampaignSocialMediaType;
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type InfluencerCampaignStat = {
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  /** Influencer cost */
  readonly cost?: Maybe<Scalars['Float']>;
  readonly cvr?: Maybe<Scalars['Float']>;
  readonly engagement: Scalars['Int'];
  readonly engagementRate?: Maybe<Scalars['Float']>;
  readonly like?: Maybe<Scalars['Int']>;
  readonly reach?: Maybe<Scalars['Int']>;
  readonly share?: Maybe<Scalars['Int']>;
};

export type InfluencerCampaignStatByPromotion = {
  readonly actions?: Maybe<Scalars['Int']>;
  readonly clicks?: Maybe<Scalars['Int']>;
  readonly orders?: Maybe<Scalars['Int']>;
  readonly sales?: Maybe<Scalars['Int']>;
};

export type InfluencerCampaignSummary = {
  /** average cost for all posts in auto_managed campaigns */
  readonly autoManagedAvgCost?: Maybe<Scalars['Float']>;
  /** number of joined auto_managed campaigns */
  readonly autoManagedJoinedCount?: Maybe<Scalars['Int']>;
  /** average comments for all posts */
  readonly avgComments?: Maybe<Scalars['Float']>;
  /** average engagement for all posts */
  readonly avgEngagement?: Maybe<Scalars['Float']>;
  /** average likes for all posts */
  readonly avgLikes?: Maybe<Scalars['Float']>;
  /** average reach for all posts */
  readonly avgReach?: Maybe<Scalars['Float']>;
  /** User currency */
  readonly currency: Scalars['String'];
  /** average cost for all posts in engagement campaigns */
  readonly engagementAvgCost?: Maybe<Scalars['Float']>;
  /** number of joined engagement campaigns */
  readonly engagementJoinedCount?: Maybe<Scalars['Int']>;
  /** number of posted engagement posts */
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  /** average cost for all posts in marketplace campaigns */
  readonly marketplaceAvgCost?: Maybe<Scalars['Float']>;
  /** number of joined marketplace campaigns */
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  /** number of posted marketplace posts */
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
};

export type InfluencerDeleteYoutubeCompareAccountAnalyticsInput = {
  readonly compareAccountId: Scalars['Int'];
  readonly socialAccountId: Scalars['Int'];
};

export type InfluencerEngagement = {
  readonly averageEngagement?: Maybe<Scalars['Float']>;
  readonly engagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly engagementRate?: Maybe<Scalars['Float']>;
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
};

export type InfluencerExportDashboardInstagramAccount = {
  readonly ok: Scalars['Boolean'];
};

export type InfluencerExportYoutubeDashboardAccountAnalyticsInput = {
  readonly endDate: Scalars['String'];
  readonly socialAccountId: Scalars['Int'];
  readonly startDate: Scalars['String'];
  readonly type?: InputMaybe<ExportYoutubeAccountAnalyticsType>;
};

export type InfluencerFacebookPageAnalyticsAccessTokenWarningPayload = {
  readonly needReconnect: Scalars['Boolean'];
};

export type InfluencerFeedPost = {
  readonly caption?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['Int']>;
  /** SocialPost ID */
  readonly id: Scalars['Int'];
  readonly isVideo?: Maybe<Scalars['Boolean']>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly reactions?: Maybe<Scalars['Int']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialMedia: SocialAccountType;
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type InfluencerFollowerGrowthSection = {
  readonly activeFollowersCount?: Maybe<Scalars['Int']>;
  readonly engageFansRate?: Maybe<Scalars['Float']>;
  readonly followersCount: Scalars['Int'];
  readonly followersGrowth: ReadonlyArray<FollowersByTime>;
  readonly hasPost?: Maybe<Scalars['Boolean']>;
  /** last updated datetime in UTC */
  readonly lastUpdatedDatetime: Scalars['DateTime'];
  readonly postsCount: Scalars['Int'];
  readonly postsGrowth: ReadonlyArray<PostsByTime>;
  readonly readFansRate?: Maybe<Scalars['Float']>;
};

export type InfluencerHasEstimateApi = {
  readonly accountInterest: Scalars['Boolean'];
  readonly audienceDemographics: Scalars['Boolean'];
  readonly message?: Maybe<ConstantHasEstimateMessage>;
};

export type InfluencerInstagramAnalyticsAccessTokenWarningPayload = {
  readonly dataForReconnect: InfluencerInstagramDataForReconnectPayload;
  readonly needReconnect: Scalars['Boolean'];
};

export type InfluencerInstagramDataForReconnectPayload = {
  readonly analyticsAccountId: Scalars['Int'];
};

export type InfluencerInterest = {
  readonly category: Scalars['String'];
  readonly rate: Scalars['Float'];
};

/** An enumeration. */
export enum InfluencerListSortField {
  DATE = 'DATE',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_POSTED_COUNT = 'ENGAGEMENT_POSTED_COUNT',
  ENGAGEMENT_PROPOSED_COUNT = 'ENGAGEMENT_PROPOSED_COUNT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
  MARKETPLACE_JOINED_COUNT = 'MARKETPLACE_JOINED_COUNT',
  MARKETPLACE_POSTED_COUNT = 'MARKETPLACE_POSTED_COUNT'
}

export type InfluencerManagerOutput = {
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly userId: Scalars['Int'];
};

export type InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload = {
  readonly dataForReconnect: InfluencerMultipleInstagramDataForReconnectPayload;
  readonly needReconnect: Scalars['Boolean'];
  readonly socialAccountId: Scalars['Int'];
};

export type InfluencerMultipleInstagramDataForReconnectPayload = {
  readonly analyticsAccountId: Scalars['Int'];
};

export type InfluencerNewProfileDetail = {
  readonly followers: InfluencerNewProfileFollowers;
  /** last updated datetime in UTC */
  readonly lastUpdatedDatetime: Scalars['DateTime'];
  readonly popularPosts: ReadonlyArray<InfluencerPopularPost>;
  readonly postsHabit: ReadonlyArray<InfluencerNewProfilePostsHabit>;
  readonly postsStats: InfluencerNewProfilePostsStats;
  readonly sentimentAnalysis?: Maybe<InfluencerNewProfileSentimentAnalysis>;
};

export type InfluencerNewProfileEngagement = {
  readonly averageEngagement?: Maybe<Scalars['Float']>;
  readonly engagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
};

export type InfluencerNewProfileFollowers = {
  readonly ageGenderRates?: Maybe<FollowersAgeGenderGroup>;
  readonly countryRates?: Maybe<ReadonlyArray<FollowersCountry>>;
  readonly femaleRate?: Maybe<Scalars['Float']>;
  readonly followersGrowth?: Maybe<ReadonlyArray<FollowersByTime>>;
  readonly maleRate?: Maybe<Scalars['Float']>;
};

export type InfluencerNewProfileHeaderV2 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly country: CountryNameForInfluencer;
  readonly cover?: Maybe<Scalars['String']>;
  readonly defaultSelectionReason?: Maybe<Scalars['String']>;
  readonly douyinAccounts?: Maybe<ReadonlyArray<SocialAccountForProfileV2>>;
  readonly emailNewsLetters?: Maybe<ReadonlyArray<PromotionMethodEmailNewsLetterForProfile>>;
  readonly engagement: InfluencerNewProfileEngagement;
  readonly facebookAccount?: Maybe<SocialAccountForProfileV2>;
  readonly facebookPages?: Maybe<ReadonlyArray<FacebookPageForProfileV2>>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  readonly hideSensitiveMetrics: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly instagramAccounts?: Maybe<ReadonlyArray<InstagramAccountForProfileV2>>;
  readonly internalMemo?: Maybe<Scalars['String']>;
  readonly introduce?: Maybe<Scalars['String']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly isTaggedToCompany?: Maybe<Scalars['Boolean']>;
  readonly mobileApps?: Maybe<ReadonlyArray<PromotionMethodMobileAppForProfile>>;
  readonly name: Scalars['String'];
  readonly offlines?: Maybe<ReadonlyArray<PromotionMethodOfflineForProfile>>;
  readonly partnerIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly partnerTalentAgencies: ReadonlyArray<Scalars['String']>;
  readonly podCasts?: Maybe<ReadonlyArray<PromotionMethodPodCastForProfile>>;
  readonly region?: Maybe<RegionName>;
  readonly status: SocialAccountStatus;
  readonly tags: ReadonlyArray<Scalars['String']>;
  readonly talentAgencyIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly threadsAccounts?: Maybe<ReadonlyArray<SocialAccountForProfileV2>>;
  readonly tiktokAccounts?: Maybe<ReadonlyArray<TiktokAccountForProfileV2>>;
  readonly twitterAccounts?: Maybe<ReadonlyArray<TwitterForProfileV2>>;
  readonly websites?: Maybe<ReadonlyArray<PromotionMethodWebsiteForProfile>>;
  readonly xhsAccounts?: Maybe<ReadonlyArray<SocialAccountForProfileV2>>;
  readonly youtubeAccounts?: Maybe<ReadonlyArray<YoutubeAccountForProfileV2>>;
};

export type InfluencerNewProfilePostsHabit = {
  readonly averageComments: Scalars['Float'];
  readonly averageLikes: Scalars['Float'];
  readonly averageShares: Scalars['Float'];
  readonly averageViews: Scalars['Float'];
  readonly engagementRate: Scalars['Float'];
  readonly hour: Scalars['Int'];
  readonly weekday: Scalars['Int'];
};

export type InfluencerNewProfilePostsStats = {
  readonly averageComments?: Maybe<Scalars['Float']>;
  readonly averageEngagement?: Maybe<Scalars['Float']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageShares?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly engagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
};

export type InfluencerNewProfileSentimentAnalysis = {
  readonly negativeRatio?: Maybe<Scalars['Float']>;
  readonly neutralRatio?: Maybe<Scalars['Float']>;
  readonly positiveRatio?: Maybe<Scalars['Float']>;
};

export type InfluencerOrderBy = {
  readonly field?: InputMaybe<InfluencerListSortField>;
  readonly order?: InputMaybe<ORDER>;
};

export type InfluencerPaymentInformation = {
  readonly accountName?: Maybe<Scalars['String']>;
  readonly accountNumber?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly bankId?: Maybe<Scalars['Int']>;
  readonly branchId?: Maybe<Scalars['Int']>;
  /** Bank Branch Name */
  readonly branchName?: Maybe<Scalars['String']>;
  readonly cityId?: Maybe<Scalars['Int']>;
  /** necessary for JP influencer */
  readonly fullName?: Maybe<Scalars['String']>;
  /** IBAN */
  readonly iban?: Maybe<Scalars['String']>;
  readonly paymentEmail?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly subject?: Maybe<Scalars['String']>;
  /** SWIFT Code */
  readonly swiftCode?: Maybe<Scalars['String']>;
};

export type InfluencerPaymentRequest = {
  readonly campaigns?: Maybe<ReadonlyArray<CampaignPayment>>;
  /** For JP influencer only */
  readonly consumptionTax?: Maybe<Scalars['Float']>;
  readonly currency: Scalars['String'];
  readonly dueDate: Scalars['Date'];
  readonly fee: Scalars['Float'];
  readonly grossRevenue: Scalars['Float'];
  readonly id: Scalars['Int'];
  readonly netRevenue: Scalars['Float'];
  readonly paidDate?: Maybe<Scalars['Date']>;
  readonly requestedDate: Scalars['Date'];
  readonly status: PaymentRequestStatusForInfluencer;
  /** For JP influencer only */
  readonly withholdingFee?: Maybe<Scalars['Float']>;
};

export type InfluencerPaymentRequests = {
  readonly currency: Scalars['String'];
  readonly paymentRequests: ReadonlyArray<InfluencerPaymentRequest>;
  readonly totalNetRevenue: Scalars['Float'];
};

export type InfluencerPopularPost = {
  readonly comments: Scalars['Int'];
  readonly content?: Maybe<Scalars['String']>;
  /** SocialPost ID */
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialMedia: SocialAccountType;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type InfluencerPostDetail = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly caption: Scalars['String'];
  readonly comments?: Maybe<Scalars['Int']>;
  readonly commentsContent?: Maybe<ReadonlyArray<CommentContent>>;
  readonly engagement?: Maybe<Scalars['Int']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly influencerId: Scalars['Int'];
  readonly influencerName: Scalars['String'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly reactions?: Maybe<Scalars['Int']>;
  readonly saves?: Maybe<Scalars['Int']>;
  readonly sentimentNegativeRatio?: Maybe<Scalars['Float']>;
  readonly sentimentPositiveRatio?: Maybe<Scalars['Float']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly similarPosts?: Maybe<ReadonlyArray<SimilarPost>>;
  readonly socialMedia: SocialPostType;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type InfluencerPostReportStatsEngagement = {
  /** campaign status using for AnyX */
  readonly anyXStatus: AnyXEngagementCampaignStatus;
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  /** post ID */
  readonly id: Scalars['Int'];
  readonly like: Scalars['Int'];
  readonly postCaption: Scalars['String'];
  readonly postSocialMedia: CampaignSocialMediaType;
  readonly postUrl: Scalars['String'];
  readonly share: Scalars['Int'];
  readonly status: EngagementCampaignPostStatus;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly view: Scalars['Int'];
};

export type InfluencerPostReportStatsMarketplace = {
  /** campaign status using for AnyX */
  readonly anyXStatus?: Maybe<AnyXMarketplaceCampaignStatus>;
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  /** post ID */
  readonly id: Scalars['Int'];
  /** represents reason details */
  readonly inappropriateDetailedReason?: Maybe<Scalars['String']>;
  /** represents reason title */
  readonly inappropriateReason?: Maybe<Scalars['String']>;
  readonly like: Scalars['Int'];
  readonly missingCoupon?: Maybe<Scalars['String']>;
  readonly postCaption: Scalars['String'];
  readonly postSocialMedia: CampaignSocialMediaType;
  readonly postUrl: Scalars['String'];
  readonly share: Scalars['Int'];
  readonly status: MarketplaceCampaignPostStatus;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly view: Scalars['Int'];
};

export type InfluencerPostReportStatsTikTokSpecial = {
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  /** post ID */
  readonly id: Scalars['Int'];
  readonly like: Scalars['Int'];
  readonly postCaption: Scalars['String'];
  readonly postSocialMedia: CampaignSocialMediaType;
  readonly postUrl: Scalars['String'];
  readonly share: Scalars['Int'];
  readonly status: TikTokSpecialCampaignPostStatus;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly view: Scalars['Int'];
};

export type InfluencerPostSection = {
  readonly accountInterests: ReadonlyArray<InfluencerInterest>;
  readonly averageComments: Scalars['Float'];
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averagePostsPerWeek: Scalars['Float'];
  readonly averageReactions?: Maybe<Scalars['Float']>;
  readonly averageSaved?: Maybe<Scalars['Float']>;
  readonly averageShares?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly engagementFollowersRate: Scalars['Float'];
  readonly engagementRate: Scalars['Float'];
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
  readonly estimateReach?: Maybe<Scalars['Float']>;
  readonly feedPosts: ReadonlyArray<InfluencerFeedPost>;
  readonly postsHabit: ReadonlyArray<InfluencerNewProfilePostsHabit>;
  /** available for Instagram */
  readonly reelPosts?: Maybe<ReadonlyArray<InfluencerReelPost>>;
  readonly sentimentNegativeRatio?: Maybe<Scalars['Float']>;
  readonly sentimentNeutralRatio?: Maybe<Scalars['Float']>;
  readonly sentimentPositiveRatio?: Maybe<Scalars['Float']>;
};

export type InfluencerPostV3 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly engagementRate?: Maybe<Scalars['Float']>;
  readonly followers?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  readonly influencerId: Scalars['Int'];
  readonly isBrandAccount: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly postDate: Scalars['Date'];
  readonly postId?: Maybe<Scalars['Int']>;
  readonly postUrl: Scalars['String'];
  readonly socialAccountId?: Maybe<Scalars['Int']>;
  readonly socialAccountStatus?: Maybe<SocialAccountStatus>;
  readonly socialMedia: SocialAccountType;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type InfluencerPostsV3 = {
  readonly posts: ReadonlyArray<InfluencerPostV3>;
  readonly totalCount?: Maybe<Scalars['Int']>;
};

export type InfluencerProfileDetailForEngagementProposal = {
  readonly followers: InfluencerNewProfileFollowers;
  /** last updated datetime in UTC */
  readonly lastUpdatedDatetime: Scalars['DateTime'];
  readonly popularPosts: ReadonlyArray<InfluencerPopularPost>;
  readonly postsHabit: ReadonlyArray<InfluencerNewProfilePostsHabit>;
  readonly postsStats: InfluencerNewProfilePostsStats;
  readonly sentimentAnalysis?: Maybe<InfluencerNewProfileSentimentAnalysis>;
};

export type InfluencerProfileForEngagementProposal = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly country: CountryNameForInfluencer;
  readonly cover?: Maybe<Scalars['String']>;
  readonly defaultSelectionReason?: Maybe<Scalars['String']>;
  readonly douyinAccounts?: Maybe<ReadonlyArray<SocialAccountForProfileV2>>;
  readonly emailNewsLetters?: Maybe<ReadonlyArray<PromotionMethodEmailNewsLetterForProfile>>;
  readonly engagement: InfluencerNewProfileEngagement;
  readonly facebookAccount?: Maybe<SocialAccountForProfileV2>;
  readonly facebookPages?: Maybe<ReadonlyArray<FacebookPageForProfileV2>>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  readonly hideSensitiveMetrics: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly instagramAccounts?: Maybe<ReadonlyArray<InstagramAccountForProfileV2>>;
  readonly internalMemo?: Maybe<Scalars['String']>;
  readonly introduce?: Maybe<Scalars['String']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly isTaggedToCompany?: Maybe<Scalars['Boolean']>;
  readonly mobileApps?: Maybe<ReadonlyArray<PromotionMethodMobileAppForProfile>>;
  readonly name: Scalars['String'];
  readonly offlines?: Maybe<ReadonlyArray<PromotionMethodOfflineForProfile>>;
  readonly partnerIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly partnerTalentAgencies: ReadonlyArray<Scalars['String']>;
  readonly podCasts?: Maybe<ReadonlyArray<PromotionMethodPodCastForProfile>>;
  readonly region?: Maybe<RegionName>;
  readonly status: SocialAccountStatus;
  readonly tags: ReadonlyArray<Scalars['String']>;
  readonly talentAgencyIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly threadsAccounts?: Maybe<ReadonlyArray<SocialAccountForProfileV2>>;
  readonly tiktokAccounts?: Maybe<ReadonlyArray<TiktokAccountForProfileV2>>;
  readonly twitterAccounts?: Maybe<ReadonlyArray<TwitterForProfileV2>>;
  readonly websites?: Maybe<ReadonlyArray<PromotionMethodWebsiteForProfile>>;
  readonly xhsAccounts?: Maybe<ReadonlyArray<SocialAccountForProfileV2>>;
  readonly youtubeAccounts?: Maybe<ReadonlyArray<YoutubeAccountForProfileV2>>;
};

export type InfluencerPromotionMethodsPayload = {
  readonly emailNewsLetters: ReadonlyArray<PromotionMethodEmailNewsLetterPayload>;
  readonly mobileApps: ReadonlyArray<PromotionMethodMobileAppPayload>;
  readonly offlines: ReadonlyArray<PromotionMethodOfflinePayload>;
  readonly podCasts: ReadonlyArray<PromotionMethodPodCastPayload>;
  readonly websites: ReadonlyArray<PromotionMethodWebsitePayload>;
};

export type InfluencerRateCardData = {
  readonly price: Scalars['Float'];
};

export type InfluencerRateCardDetailData = {
  readonly description: Scalars['String'];
  readonly price: Scalars['Float'];
};

export type InfluencerRateCardInput = {
  readonly influencerId: Scalars['Int'];
};

export type InfluencerRateCardPayload = {
  readonly currencyId: Scalars['String'];
  readonly douyin: ReadonlyArray<InfluencerRateCardDetailData>;
  readonly facebook?: Maybe<InfluencerRateCardData>;
  readonly instagram?: Maybe<InfluencerRateCardData>;
  readonly instagramStory?: Maybe<InfluencerRateCardData>;
  readonly threads?: Maybe<InfluencerRateCardData>;
  readonly tiktok?: Maybe<InfluencerRateCardData>;
  readonly twitter?: Maybe<InfluencerRateCardData>;
  readonly xhs: ReadonlyArray<InfluencerRateCardDetailData>;
  readonly youtube?: Maybe<InfluencerRateCardData>;
};

export type InfluencerRateCardPriceInput = {
  readonly price: Scalars['Float'];
};

export type InfluencerRateCardsInput = {
  readonly facebook?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly instagram?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly instagramStory?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly threads?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly tiktok?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly twitter?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly youtube?: InputMaybe<InfluencerRateCardPriceInput>;
};

export type InfluencerReelPost = {
  readonly caption?: Maybe<Scalars['String']>;
  readonly comments: Scalars['Int'];
  /** SocialPost ID */
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly socialMedia: SocialPostType;
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export enum InfluencerSearchKeywordField {
  BIO = 'BIO',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  PROFILE = 'PROFILE',
  SOCIAL_USERNAME = 'SOCIAL_USERNAME',
  TAG = 'TAG'
}

export type InfluencerSearchOrderBy = {
  readonly field?: InputMaybe<InfluencerSearchSortField>;
  readonly order?: InputMaybe<Order>;
};

export type InfluencerSearchSortBy = {
  readonly field?: InputMaybe<InfluencerSearchSortField>;
  readonly order?: InputMaybe<ORDER>;
};

export enum InfluencerSearchSortField {
  CAMPAIGNS_JOINED = 'CAMPAIGNS_JOINED',
  DATE = 'DATE',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_FOLLOWERS_RATE = 'ENGAGEMENT_FOLLOWERS_RATE',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  ENGAGEMENT_VIEWS_RATE = 'ENGAGEMENT_VIEWS_RATE',
  FOLLOWERS = 'FOLLOWERS'
}

export type InfluencerSignUpInput = {
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly countryId: Scalars['String'];
  readonly dateOfBirth: Scalars['String'];
  readonly email: Scalars['String'];
  readonly gender: Genders;
  readonly name: Scalars['String'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly regionId?: InputMaybe<Scalars['Int']>;
};

export type InfluencerSignUpInputPayload = {
  readonly ok: Scalars['Boolean'];
};

export type InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput = {
  readonly analyticsAccountId: Scalars['Int'];
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type InfluencerSocialNetworkAccountInput = {
  readonly username: Scalars['String'];
};

export type InfluencerSocialNetworkAccountOutput = {
  readonly socialAccountId: Scalars['Int'];
  /** Social Account Status */
  readonly status: SocialAccountStatus;
  readonly username: Scalars['String'];
};

export type InfluencerSocialPost = {
  readonly caption?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['Int']>;
  readonly date: Scalars['Date'];
  /** SocialPost ID */
  readonly id: Scalars['Int'];
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly isSponsoredPost?: Maybe<Scalars['Boolean']>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly reach?: Maybe<Scalars['Int']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type InfluencerSponsoredPost = {
  readonly campaignType?: Maybe<CampaignType>;
  readonly comments: Scalars['Int'];
  readonly content?: Maybe<Scalars['String']>;
  /** Post ID */
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postUrl: Scalars['String'];
  readonly postedDate: Scalars['Date'];
  readonly reaches?: Maybe<Scalars['Int']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialMedia: SocialPostType;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type InfluencerSponsoredPostCountResponse = {
  readonly total: Scalars['Int'];
};

export type InfluencerSponsoredPostResponse = {
  readonly averageEngagement?: Maybe<Scalars['Float']>;
  readonly averageEngagementRate?: Maybe<Scalars['Float']>;
  readonly averageReach?: Maybe<Scalars['Float']>;
  readonly averageSaved?: Maybe<Scalars['Float']>;
  readonly posts: ReadonlyArray<InfluencerSponsoredPost>;
};

export enum InfluencerStatusForSearch {
  SCRAPING = 'SCRAPING',
  SIGNED_UP = 'SIGNED_UP',
  SIGNED_UP_EMAIL = 'SIGNED_UP_EMAIL'
}

export type InfluencerTwitterAnalyticsAccessTokenWarningPayload = {
  readonly needReconnect: Scalars['Boolean'];
};

/** An enumeration. */
export enum InfluencerType {
  NORMAL = 'NORMAL',
  PRO = 'PRO'
}

export type InfluencerUpdateDetailV2 = {
  readonly campaignDisplay: Scalars['Boolean'];
  readonly categories: ReadonlyArray<CategoryName>;
  readonly country: CountryNameForInfluencer;
  readonly dateOfBirth?: Maybe<Scalars['Date']>;
  readonly defaultEngagementSelectionReason?: Maybe<Scalars['String']>;
  readonly douyinAccounts?: Maybe<ReadonlyArray<InfluencerSocialNetworkAccountOutput>>;
  readonly email?: Maybe<Scalars['String']>;
  readonly facebookAccount?: Maybe<InfluencerSocialNetworkAccountOutput>;
  readonly facebookPages?: Maybe<ReadonlyArray<InfluencerSocialNetworkAccountOutput>>;
  readonly gender: Genders;
  readonly id: Scalars['Int'];
  readonly instagramAccounts?: Maybe<ReadonlyArray<InfluencerSocialNetworkAccountOutput>>;
  readonly internalMemo?: Maybe<Scalars['String']>;
  readonly introduce?: Maybe<Scalars['String']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly isProAccount: Scalars['Boolean'];
  readonly managerIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly name: Scalars['String'];
  readonly notificationSetting: Scalars['Boolean'];
  readonly partners?: Maybe<ReadonlyArray<Partner>>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly region?: Maybe<RegionName>;
  readonly status: SocialAccountStatus;
  readonly tags: ReadonlyArray<Scalars['String']>;
  readonly talentAgencies?: Maybe<ReadonlyArray<TalentAgency>>;
  readonly threadsAccounts?: Maybe<ReadonlyArray<InfluencerSocialNetworkAccountOutput>>;
  readonly tiktokAccounts?: Maybe<ReadonlyArray<InfluencerSocialNetworkAccountOutput>>;
  readonly twitterAccounts?: Maybe<ReadonlyArray<InfluencerSocialNetworkAccountOutput>>;
  readonly xhsAccounts?: Maybe<ReadonlyArray<InfluencerSocialNetworkAccountOutput>>;
  readonly youtubeAccounts?: Maybe<ReadonlyArray<InfluencerSocialNetworkAccountOutput>>;
};

export type InfluencerV4 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar: Scalars['String'];
  readonly country: CountryName;
  readonly douyin?: Maybe<SocialAccountV2>;
  readonly email?: Maybe<Scalars['Boolean']>;
  readonly engagement: InfluencerEngagement;
  readonly facebook?: Maybe<SocialAccountV2>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  readonly id: Scalars['Int'];
  readonly instagram?: Maybe<SocialAccountV2>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly isTaggedToCompany?: Maybe<Scalars['Boolean']>;
  readonly line?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly socialAccounts: ReadonlyArray<SocialAccountDescription>;
  readonly status: SocialAccountStatus;
  readonly tags: ReadonlyArray<Scalars['String']>;
  readonly threads?: Maybe<SocialAccountV2>;
  readonly tiktok?: Maybe<SocialAccountV2>;
  readonly twitter?: Maybe<SocialAccountV2>;
  readonly xhs?: Maybe<SocialAccountV2>;
  readonly youtube?: Maybe<SocialAccountV2>;
};

export type InfluencerWhitelist = {
  /**
   *
   *         If `is_whitelist` is true, this is a whitelist,
   *         which means a list of influencers who can join the marketplace campaign.
   *         If `is_whitelist` is false, this is a blacklist.
   *
   */
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly isWhitelist: Scalars['Boolean'];
};

export type InfluencerWordCloud = {
  readonly count: Scalars['Int'];
  readonly hashtag: Scalars['String'];
};

export type InfluencerYoutubeAnalyticsAccountCard = {
  readonly avatarUrl: Scalars['String'];
  readonly channelName: Scalars['String'];
  readonly elementId: Scalars['Int'];
  readonly profileUrl: Scalars['String'];
  readonly socialAccountId: Scalars['Int'];
};

export type InfluencerYoutubeAnalyticsAccountPosts = {
  readonly postHabit: ReadonlyArray<YoutubeAnalyticsPostsPostingHabit>;
  readonly posts: ReadonlyArray<YoutubeCompareAccountPost>;
  readonly socialAccountId: Scalars['Int'];
};

export type InfluencerYoutubeAnalyticsAccountPostsInDate = {
  readonly posts: ReadonlyArray<YoutubeAnalyticsPostPayload>;
  readonly socialAccountId: Scalars['Int'];
};

export type InfluencerYoutubeAnalyticsAccountTagRanking = {
  readonly socialAccountId: Scalars['Int'];
  readonly tagsRanking: ReadonlyArray<YoutubeTagRanking>;
};

export type InfluencerYoutubeAnalyticsChannelDetailsPayload = {
  readonly channelAvatar: Scalars['String'];
  readonly channelId: Scalars['String'];
  readonly channelName: Scalars['String'];
};

export type InfluencerYoutubeAnalyticsCompareAccountCard = {
  readonly accountId: Scalars['Int'];
  readonly avatarUrl: Scalars['String'];
  readonly channelName: Scalars['String'];
  readonly elementId: Scalars['Int'];
  readonly profileUrl: Scalars['String'];
};

export type InfluencerYoutubeAnalyticsCompareAccountCards = {
  readonly compareAccounts: ReadonlyArray<InfluencerYoutubeAnalyticsCompareAccountCard>;
  readonly mainAccount?: Maybe<InfluencerYoutubeAnalyticsAccountCard>;
};

export type InfluencerYoutubeCompareAccountOverview = {
  readonly graph: YoutubeCompareOverviewHistory;
  readonly socialAccountId: Scalars['Int'];
  readonly summary: YoutubeCompareOverviewSummary;
};

export type InfluencerYoutubeCompareAllPostsInDate = {
  readonly compareAccounts: ReadonlyArray<YoutubeCompareAccountPostsInDate>;
  readonly mainAccount: InfluencerYoutubeAnalyticsAccountPostsInDate;
};

export type InfluencerYoutubeCompareOverview = {
  readonly compareAccounts: ReadonlyArray<YoutubeCompareAccountOverview>;
  readonly mainAccount: InfluencerYoutubeCompareAccountOverview;
};

export type InfluencerYoutubeComparePosts = {
  readonly compareAccounts: ReadonlyArray<YoutubeCompareAccountPosts>;
  readonly mainAccount: InfluencerYoutubeAnalyticsAccountPosts;
};

export type InfluencerYoutubeCompareTagRanking = {
  readonly compareAccounts: ReadonlyArray<YoutubeCompareAccountTagRanking>;
  readonly mainAccount: InfluencerYoutubeAnalyticsAccountTagRanking;
};

export type InfluencerYoutubeSponsoredPost = {
  readonly comments: Scalars['Long'];
  readonly content: Scalars['String'];
  readonly duration: Scalars['Long'];
  readonly id: Scalars['Long'];
  readonly likes: Scalars['Long'];
  readonly postUrl: Scalars['String'];
  readonly postedDate: Scalars['DateTime'];
  readonly shares: Scalars['Long'];
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly views: Scalars['Long'];
};

export type InfluencersPptxSocialAccountInput = {
  readonly influencerId: Scalars['Int'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialType: SocialAccountType;
};

/** An enumeration. */
export enum InfluencersProfileFormat {
  GOOGLE_SLIDE = 'GOOGLE_SLIDE',
  PPTX = 'PPTX'
}

export type InquiryEvent = ChatEvent & {
  readonly id: Scalars['String'];
  readonly inquiry: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type InquiryFinishEvent = ChatEvent & {
  readonly id: Scalars['String'];
  readonly timestamp: Scalars['String'];
  readonly username?: Maybe<Scalars['String']>;
};

export type InstagramAccountForProfileV2 = {
  readonly analyticsEnabled: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly isBusiness: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
};

export type InstagramAnalyticsAccountInfoHeader = {
  readonly accountName: Scalars['String'];
  readonly avatar: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly socialAccountUsername: Scalars['String'];
};

export type InstagramAnalyticsComments = {
  readonly commentId?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly created?: Maybe<Scalars['Date']>;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly username?: Maybe<Scalars['String']>;
};

export type InstagramAnalyticsCompareAccount = {
  readonly avatarUrl: Scalars['String'];
  readonly blocked: Scalars['Boolean'];
  readonly elementId: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly profileUrl: Scalars['String'];
  readonly username: Scalars['String'];
};

export type InstagramAnalyticsCompareAccounts = {
  readonly accounts?: Maybe<ReadonlyArray<InstagramAnalyticsCompareAccount>>;
};

export type InstagramAnalyticsComparePostsInDate = {
  readonly accountId: Scalars['Int'];
  readonly feedPosts?: Maybe<ReadonlyArray<InstagramComparePostDetails>>;
};

export type InstagramAnalyticsGetCommentItemsPayload = {
  readonly commentId: Scalars['String'];
  readonly content: Scalars['String'];
  readonly created: Scalars['String'];
  readonly postUrl: Scalars['String'];
  readonly username: Scalars['String'];
};

export type InstagramAnalyticsGetCommentsPayload = {
  readonly comments: ReadonlyArray<InstagramAnalyticsGetCommentItemsPayload>;
};

export type InstagramAnalyticsHashtag = {
  readonly blocked: Scalars['Boolean'];
  readonly hashTag: Scalars['String'];
  readonly hashTagUrl: Scalars['String'];
  readonly id: Scalars['Int'];
};

export type InstagramAnalyticsHashtags = {
  readonly hashTags?: Maybe<ReadonlyArray<InstagramAnalyticsHashtag>>;
};

export type InstagramAnalyticsHistoryDataPoint = {
  readonly count?: Maybe<Scalars['Int']>;
  readonly date?: Maybe<Scalars['Date']>;
};

export type InstagramAnalyticsHistoryEngagementRateDataPoint = {
  readonly count?: Maybe<Scalars['Float']>;
  readonly date?: Maybe<Scalars['Date']>;
};

export type InstagramAnalyticsOverviewData = {
  readonly growth?: Maybe<Scalars['Int']>;
  readonly history?: Maybe<ReadonlyArray<InstagramAnalyticsHistoryDataPoint>>;
  readonly percentage?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Int']>;
};

export type InstagramAnalyticsOverviewEngagementRateData = {
  readonly growth?: Maybe<Scalars['Float']>;
  readonly history?: Maybe<ReadonlyArray<InstagramAnalyticsHistoryEngagementRateDataPoint>>;
  readonly percentage?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Float']>;
};

export type InstagramAnalyticsPostAnalysisDetails = {
  readonly comments: Scalars['Int'];
  readonly impressions: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly reach: Scalars['Int'];
  readonly saves: Scalars['Int'];
  readonly views: Scalars['Int'];
};

export type InstagramAnalyticsPostCommentDetails = {
  readonly content: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly username?: Maybe<Scalars['String']>;
};

export type InstagramAnalyticsPostDetails = {
  readonly analysis?: Maybe<InstagramAnalyticsPostAnalysisDetails>;
  readonly avatarUrl: Scalars['String'];
  readonly commentList?: Maybe<ReadonlyArray<InstagramAnalyticsPostCommentDetails>>;
  readonly content: Scalars['String'];
  readonly followers?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly instagramAccountName: Scalars['String'];
  readonly mediaUrls?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
};

export type InstagramAnalyticsPostSentimentDetails = {
  readonly negativeRate: Scalars['Float'];
  readonly positiveRate: Scalars['Float'];
};

export type InstagramAnalyticsPostsInDate = {
  readonly feedPosts?: Maybe<ReadonlyArray<InstagramAnalyticsPostDetails>>;
  readonly reelPosts?: Maybe<ReadonlyArray<InstagramAnalyticsReelDetails>>;
  readonly storyPosts?: Maybe<ReadonlyArray<InstagramAnalyticsStoryDetails>>;
};

export type InstagramAnalyticsReelAnalysisDetails = {
  readonly comments: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly reach: Scalars['Int'];
  readonly saves: Scalars['Int'];
  readonly shares: Scalars['Int'];
  readonly views: Scalars['Int'];
};

export type InstagramAnalyticsReelCommentDetails = {
  readonly content: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly username?: Maybe<Scalars['String']>;
};

export type InstagramAnalyticsReelDetails = {
  readonly analysis?: Maybe<InstagramAnalyticsReelAnalysisDetails>;
  readonly avatarUrl: Scalars['String'];
  readonly commentList?: Maybe<ReadonlyArray<InstagramAnalyticsReelCommentDetails>>;
  readonly content: Scalars['String'];
  readonly followers?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly instagramAccountName: Scalars['String'];
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly thumbnail?: Maybe<Scalars['String']>;
};

export type InstagramAnalyticsStoryDetails = {
  readonly avatarUrl: Scalars['String'];
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly exits: Scalars['Int'];
  readonly followers?: Maybe<Scalars['Int']>;
  /** Instagram analytics story id */
  readonly id: Scalars['Int'];
  readonly impressions: Scalars['Int'];
  readonly instagramAccountName: Scalars['String'];
  readonly postDate: Scalars['Date'];
  readonly postImageUrl?: Maybe<Scalars['String']>;
  readonly postUrl: Scalars['String'];
  readonly reach: Scalars['Int'];
  readonly storyVideoUrl?: Maybe<Scalars['String']>;
  readonly swipeForward: Scalars['Int'];
  readonly tapsBack: Scalars['Int'];
  readonly tapsForward: Scalars['Int'];
};

export type InstagramAnalyticsSummaryPostsInDate = {
  readonly compareAccounts: ReadonlyArray<InstagramAnalyticsComparePostsInDate>;
  readonly mainAccount: InstagramAnalyticsPostsInDate;
};

export type InstagramBrandAmbassadorPost = {
  readonly comments: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postId: Scalars['String'];
  readonly postUrl: Scalars['String'];
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type InstagramBrandAmbassadorPostDetails = {
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly likes: Scalars['Int'];
  readonly postId: Scalars['String'];
  readonly postUrl: Scalars['String'];
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly username: Scalars['String'];
};

export type InstagramBrandAmbassadorPosts = {
  readonly ambassadorPosts: ReadonlyArray<InstagramBrandAmbassadorPostsByUsername>;
};

export type InstagramBrandAmbassadorPostsByUsername = {
  readonly brandMentionedPosts: Scalars['Int'];
  readonly igUsername: Scalars['String'];
  readonly posts: ReadonlyArray<InstagramBrandAmbassadorPost>;
};

export type InstagramCommentAnalytics = {
  readonly negativeRatio?: Maybe<Scalars['Float']>;
  readonly neutralRatio?: Maybe<Scalars['Float']>;
  readonly positiveRatio?: Maybe<Scalars['Float']>;
};

export type InstagramCompareAccount = {
  readonly avatar: Scalars['String'];
  readonly blocked: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly profileUrl: Scalars['String'];
  readonly username: Scalars['String'];
};

export type InstagramCompareAccountPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type InstagramCompareAccountsPayload = {
  readonly compareAccounts: ReadonlyArray<InstagramCompareAccount>;
  readonly mainAccount: InstagramCompareAccount;
};

export type InstagramCompareFeedPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly engagement?: Maybe<Scalars['Int']>;
  readonly engagementRate?: Maybe<Scalars['Float']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type InstagramCompareFollowers = {
  readonly compareAccounts: ReadonlyArray<InstagramCompareFollowersSecondaryAccount>;
  /** primary compare account */
  readonly mainAccount: InstagramCompareFollowersPrimaryAccount;
};

export type InstagramCompareFollowersPrimaryAccount = {
  readonly followerAgeRate: InstagramFollowersAgeRate;
  readonly followerGenderRate: InstagramFollowerGenderRate;
  /** represents Instagram account system id */
  readonly id: Scalars['Int'];
};

export type InstagramCompareFollowersSecondaryAccount = {
  readonly followerAgeRate: InstagramFollowersAgeRate;
  readonly followerGenderRate: InstagramFollowerGenderRate;
  /** represents Instagram compare account system id */
  readonly id: Scalars['Int'];
};

export type InstagramCompareOverview = {
  /** list of comparable accounts */
  readonly compareAccounts: ReadonlyArray<InstagramCompareSecondaryAccount>;
  /** primary compare account */
  readonly mainAccount: InstagramComparePrimaryAccount;
};

export type InstagramCompareOverviewHistory = {
  readonly comments: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly engagement: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly followers: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly likes: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly posts: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
};

export type InstagramCompareOverviewSummary = {
  readonly averageComments: InstagramCompareOverviewSummaryAverageData;
  readonly averageLikes: InstagramCompareOverviewSummaryAverageData;
  readonly maximumLikes: InstagramCompareOverviewSummaryData;
  readonly totalFollowers: InstagramCompareOverviewSummaryData;
  readonly totalPosts: InstagramCompareOverviewSummaryData;
};

export type InstagramCompareOverviewSummaryAverageData = {
  readonly growth?: Maybe<Scalars['Float']>;
  readonly percentage?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Float']>;
};

export type InstagramCompareOverviewSummaryData = {
  readonly growth?: Maybe<Scalars['Int']>;
  readonly percentage?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Int']>;
};

export type InstagramComparePostAnalysisDetails = {
  readonly comments: Scalars['Int'];
  readonly likes: Scalars['Int'];
};

export type InstagramComparePostDetails = {
  readonly analysis?: Maybe<InstagramComparePostAnalysisDetails>;
  readonly avatarUrl?: Maybe<Scalars['String']>;
  readonly commentList?: Maybe<ReadonlyArray<InstagramAnalyticsPostCommentDetails>>;
  readonly content: Scalars['String'];
  readonly followers?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly instagramAccountName?: Maybe<Scalars['String']>;
  readonly postDate: Scalars['Date'];
  readonly postImageUrl?: Maybe<Scalars['String']>;
  readonly postUrl: Scalars['String'];
};

export type InstagramComparePosts = {
  readonly compareAccounts: ReadonlyArray<InstagramComparePostsData>;
  readonly mainAccount: InstagramComparePostsData;
};

export type InstagramComparePostsData = {
  readonly feedPosts?: Maybe<ReadonlyArray<InstagramCompareAccountPost>>;
  readonly id: Scalars['Int'];
  readonly popularHashtags?: Maybe<ReadonlyArray<InstagramPopularHashtags>>;
  readonly postsHabit?: Maybe<ReadonlyArray<InstagramPostsHabit>>;
  readonly sentiment?: Maybe<InstagramCommentAnalytics>;
};

export type InstagramComparePrimaryAccount = {
  readonly graph: InstagramCompareOverviewHistory;
  /** represents Instagram account system id */
  readonly id: Scalars['Int'];
  readonly summary: InstagramCompareOverviewSummary;
};

export type InstagramCompareSecondaryAccount = {
  readonly graph: InstagramCompareOverviewHistory;
  /** represents Instagram compare account system id */
  readonly id: Scalars['Int'];
  readonly summary: InstagramCompareOverviewSummary;
};

export type InstagramDashboardFollowerAnalytics = {
  readonly followerAgeRate: InstagramFollowersAgeRate;
  readonly followerCountryRate?: Maybe<ReadonlyArray<InstagramFollowersCountryRate>>;
  readonly followerGenderRate: InstagramFollowerGenderRate;
  readonly followerRegionRate?: Maybe<ReadonlyArray<InstagramFollowersRegionRate>>;
};

export type InstagramDashboardOverview = {
  readonly comments: InstagramAnalyticsOverviewData;
  readonly engagement: InstagramAnalyticsOverviewData;
  readonly engagementRate: InstagramAnalyticsOverviewEngagementRateData;
  readonly followers: InstagramAnalyticsOverviewData;
  /** impressions of posts */
  readonly impressions: InstagramAnalyticsOverviewData;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly likes: InstagramAnalyticsOverviewData;
  readonly posts: InstagramAnalyticsOverviewData;
  readonly profileViews: InstagramAnalyticsOverviewData;
  readonly reach: InstagramAnalyticsOverviewData;
  readonly saved: InstagramAnalyticsOverviewData;
  readonly websiteClicks: InstagramAnalyticsOverviewData;
};

export type InstagramDashboardPostAnalytics = {
  readonly averageEngagement?: Maybe<InstagramPostAverageEngagement>;
  readonly commentAnalytics?: Maybe<InstagramCommentAnalytics>;
  readonly comments?: Maybe<ReadonlyArray<InstagramAnalyticsComments>>;
  readonly feedPosts?: Maybe<ReadonlyArray<InstagramFeedPost>>;
  readonly postsHabit?: Maybe<ReadonlyArray<InstagramPostsHabit>>;
  readonly reelsPosts?: Maybe<ReadonlyArray<InstagramReelPost>>;
  readonly storyPosts?: Maybe<ReadonlyArray<InstagramStoryPost>>;
};

export type InstagramDataForReconnectPayload = {
  readonly accountId: Scalars['Int'];
  readonly businessAccountId: Scalars['String'];
  readonly fbPageId: Scalars['String'];
  readonly username: Scalars['String'];
};

export type InstagramFeedPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly engagement?: Maybe<Scalars['Int']>;
  readonly engagementRate?: Maybe<Scalars['Float']>;
  readonly follow?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly impressionFromDiscovery?: Maybe<Scalars['Int']>;
  readonly impressionFromHashtag?: Maybe<Scalars['Int']>;
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly interaction?: Maybe<Scalars['Int']>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly nonFollowerReach?: Maybe<Scalars['Int']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
  readonly productButtonClick?: Maybe<Scalars['Int']>;
  readonly productPageView?: Maybe<Scalars['Int']>;
  readonly reach?: Maybe<Scalars['Int']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly share?: Maybe<Scalars['Int']>;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type InstagramFeedPostOrderBy = {
  readonly field?: InputMaybe<InstagramFeedPostSortField>;
  readonly order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum InstagramFeedPostSortField {
  COMMENT = 'COMMENT',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOW = 'FOLLOW',
  IMPRESSIONS = 'IMPRESSIONS',
  IMPRESSION_FROM_DISCOVERY = 'IMPRESSION_FROM_DISCOVERY',
  IMPRESSION_FROM_HASHTAG = 'IMPRESSION_FROM_HASHTAG',
  INTERACTION = 'INTERACTION',
  LIKE = 'LIKE',
  NON_FOLLOWER_REACH = 'NON_FOLLOWER_REACH',
  POST_DATE = 'POST_DATE',
  PRODUCT_BUTTON_CLICK = 'PRODUCT_BUTTON_CLICK',
  PRODUCT_PAGE_VIEW = 'PRODUCT_PAGE_VIEW',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  VIEW = 'VIEW'
}

export type InstagramFollowerGenderRate = {
  readonly femaleRate?: Maybe<Scalars['Float']>;
  readonly maleRate?: Maybe<Scalars['Float']>;
  readonly otherRate?: Maybe<Scalars['Float']>;
};

export type InstagramFollowersAgeGroup = {
  readonly end: Scalars['Int'];
  readonly start: Scalars['Int'];
};

export type InstagramFollowersAgeRate = {
  readonly ageGroup?: Maybe<ReadonlyArray<InstagramFollowersAgeGroup>>;
  readonly femaleRate?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly maleRate?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly otherRate?: Maybe<ReadonlyArray<Scalars['Float']>>;
};

export type InstagramFollowersCountryRate = {
  /** Country ID */
  readonly id: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type InstagramFollowersRegionRate = {
  readonly name: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type InstagramHashtagFeedPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly engagement?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type InstagramHashtagFeedPostV2 = {
  readonly accountId?: Maybe<Scalars['Int']>;
  readonly avatar: Scalars['String'];
  readonly comments: Scalars['Int'];
  readonly content?: Maybe<Scalars['String']>;
  readonly engagement: Scalars['Int'];
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly images: ReadonlyArray<Scalars['String']>;
  readonly influencerId?: Maybe<Scalars['Int']>;
  readonly likes: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly rank: Scalars['Int'];
};

export type InstagramHashtagFeedPostsPayload = {
  readonly count: Scalars['Int'];
  readonly posts: ReadonlyArray<InstagramHashtagFeedPostV2>;
};

export type InstagramHashtagPostAnalysisDetails = {
  readonly comments: Scalars['Int'];
  readonly likes: Scalars['Int'];
};

export type InstagramHashtagPostDetails = {
  readonly analysis?: Maybe<InstagramHashtagPostAnalysisDetails>;
  readonly avatarUrl?: Maybe<Scalars['String']>;
  readonly commentList?: Maybe<ReadonlyArray<InstagramAnalyticsPostCommentDetails>>;
  readonly content: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly images?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly instagramAccountName?: Maybe<Scalars['String']>;
  readonly postDate: Scalars['Date'];
  readonly postImageUrl?: Maybe<Scalars['String']>;
  readonly postUrl: Scalars['String'];
};

export type InstagramHashtagPosts = {
  readonly hashtags: ReadonlyArray<InstagramHashtagPostsData>;
};

export type InstagramHashtagPostsData = {
  readonly feedPosts?: Maybe<ReadonlyArray<InstagramHashtagFeedPost>>;
  readonly id: Scalars['Int'];
};

export type InstagramHashtagPostsPayload = {
  readonly hashtags: ReadonlyArray<Hashtag>;
  readonly postsByHashtag: ReadonlyArray<PostByHashtag>;
};

export type InstagramHashtagsMetricsData = {
  readonly comments: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  /** Hashtag system id */
  readonly id: Scalars['Int'];
  readonly likes: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly posts: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
};

export type InstagramHashtagsOverview = {
  readonly graph: InstagramHashtagsOverviewHistory;
  readonly summaries: ReadonlyArray<InstagramHashtagsOverviewSummary>;
};

export type InstagramHashtagsOverviewHistory = {
  /** Historical followers data of Analytics account */
  readonly followers: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly hashtags: ReadonlyArray<InstagramHashtagsMetricsData>;
};

export type InstagramHashtagsOverviewSummary = {
  readonly comments: InstagramHashtagsOverviewSummaryData;
  readonly hashtag: InstagramAnalyticsHashtag;
  readonly likes: InstagramHashtagsOverviewSummaryData;
  readonly posts: InstagramHashtagsOverviewSummaryData;
};

export type InstagramHashtagsOverviewSummaryData = {
  readonly growth?: Maybe<Scalars['Int']>;
  readonly percentage?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Int']>;
};

export type InstagramInfluencerSearchResultV3 = {
  readonly instagramAccounts: ReadonlyArray<InstagramInfluencerV3>;
  readonly totalNumber: Scalars['Int'];
};

export type InstagramInfluencerV3 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly analyticsWarning?: Maybe<Scalars['Boolean']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageReach?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly country: CountryName;
  readonly gender: Genders;
  readonly id: Scalars['ID'];
  readonly isBrandAccount: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  readonly socialAccountId: Scalars['ID'];
  readonly socialAccountStatus: SocialAccountStatus;
};

export type InstagramInteractionCommonPostData = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly mediaUrl?: Maybe<Scalars['String']>;
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
};

export type InstagramInteractionCommonTaggedPostData = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type InstagramInteractionOverview = {
  readonly graph: InstagramInteractionOverviewHistory;
  readonly summaries: InstagramInteractionOverviewSummary;
};

export type InstagramInteractionOverviewHistory = {
  /** Historical followers data of Analytics account */
  readonly followers: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly mentionedComments: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly mentionedLikes: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly mentionedPosts: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly taggedComments: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly taggedLikes: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
  readonly taggedPosts: ReadonlyArray<InstagramAnalyticsHistoryDataPoint>;
};

export type InstagramInteractionOverviewSummary = {
  readonly mentionComments: InstagramInteractionOverviewSummaryData;
  readonly mentionLikes: InstagramInteractionOverviewSummaryData;
  readonly mentionPosts: InstagramInteractionOverviewSummaryData;
  readonly taggedComments: InstagramInteractionOverviewSummaryData;
  readonly taggedLikes: InstagramInteractionOverviewSummaryData;
  readonly taggedPosts: InstagramInteractionOverviewSummaryData;
};

export type InstagramInteractionOverviewSummaryData = {
  readonly growth?: Maybe<Scalars['Int']>;
  readonly percentage?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Int']>;
};

export type InstagramInteractionPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly mediaUrl?: Maybe<Scalars['String']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
};

export type InstagramInteractionPostDetails = {
  readonly avatarUrl?: Maybe<Scalars['String']>;
  readonly commentList?: Maybe<ReadonlyArray<InstagramAnalyticsPostCommentDetails>>;
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly instagramAccountName?: Maybe<Scalars['String']>;
  readonly likes: Scalars['Int'];
  readonly postDate: Scalars['Date'];
  readonly postImageUrl?: Maybe<Scalars['String']>;
  readonly postUrl: Scalars['String'];
};

export type InstagramInteractionPosts = {
  readonly mentionedPosts: ReadonlyArray<InstagramInteractionCommonPostData>;
  readonly taggedPosts: ReadonlyArray<InstagramInteractionCommonTaggedPostData>;
  readonly userAnalytics: ReadonlyArray<InstagramInteractionUserAnalytics>;
};

export type InstagramInteractionTaggedPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type InstagramInteractionUserAnalytics = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postsCount?: Maybe<Scalars['Int']>;
  readonly username: Scalars['String'];
};

export type InstagramInteractionUserAnalyticsDetails = {
  /** average total comments for Mentioned only */
  readonly avgMentionedComments: Scalars['Float'];
  /** average total likes for Mentioned only */
  readonly avgMentionedLikes: Scalars['Float'];
  /** average total comments for Tagged only */
  readonly avgTaggedComments: Scalars['Float'];
  /** average total likes for Tagged only */
  readonly avgTaggedLikes: Scalars['Float'];
  /** average total comments for Tagged OR Mentioned */
  readonly avgTotalComments: Scalars['Float'];
  /** average total likes for Tagged OR Mentioned */
  readonly avgTotalLikes: Scalars['Float'];
  /** total posts count for Mentioned only */
  readonly mentionedPosts: Scalars['Int'];
  /** total posts count for Tagged only */
  readonly taggedPosts: Scalars['Int'];
  /** total posts count for Tagged OR Mentioned */
  readonly totalPostsCount: Scalars['Int'];
  readonly username: Scalars['String'];
};

export type InstagramInteractionUserAnalyticsDetailsAndCount = {
  readonly totalNumber: Scalars['Int'];
  readonly usernames?: Maybe<ReadonlyArray<InstagramInteractionUserAnalyticsDetails>>;
};

export type InstagramInteractionUserOrderBy = {
  readonly field?: InputMaybe<InstagramInteractionUserSortField>;
  readonly order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum InstagramInteractionUserSortField {
  COMMENT = 'COMMENT',
  LIKE = 'LIKE',
  MENTIONED_COMMENT = 'MENTIONED_COMMENT',
  MENTIONED_LIKE = 'MENTIONED_LIKE',
  MENTIONED_POST = 'MENTIONED_POST',
  POST = 'POST',
  TAGGED_COMMENT = 'TAGGED_COMMENT',
  TAGGED_LIKE = 'TAGGED_LIKE',
  TAGGED_POST = 'TAGGED_POST'
}

export type InstagramPopularHashtags = {
  readonly averageComments?: Maybe<Scalars['Int']>;
  readonly averageEngagement?: Maybe<Scalars['Int']>;
  readonly averageLikes?: Maybe<Scalars['Int']>;
  readonly hashtag?: Maybe<Scalars['String']>;
  readonly usedPosts?: Maybe<Scalars['Int']>;
};

export type InstagramPostAverageEngagement = {
  readonly averageComments?: Maybe<Scalars['Float']>;
  readonly averageCommentsGrowth?: Maybe<Scalars['Float']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageLikesGrowth?: Maybe<Scalars['Float']>;
  readonly averagePostsGrowth?: Maybe<Scalars['Float']>;
  readonly averagePostsPerWeek?: Maybe<Scalars['Float']>;
  readonly averageSaved?: Maybe<Scalars['Float']>;
  readonly averageSavedGrowth?: Maybe<Scalars['Float']>;
};

export type InstagramPostSortInput = {
  readonly field?: InputMaybe<InstagramPostSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum InstagramPostSortOrder {
  COMMENTS = 'COMMENTS',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  RANK = 'RANK'
}

export type InstagramPostsHabit = {
  readonly averageComments: Scalars['Float'];
  readonly averageLikes: Scalars['Float'];
  readonly engagementRate: Scalars['Float'];
  readonly hour: Scalars['Int'];
  readonly weekday: Scalars['Int'];
};

export type InstagramReelPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postUrl: Scalars['String'];
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type InstagramReelPostItem = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
  readonly reach?: Maybe<Scalars['Int']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type InstagramReelPostOrderBy = {
  readonly field?: InputMaybe<InstagramReelPostSortField>;
  readonly order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum InstagramReelPostSortField {
  COMMENT = 'COMMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARES = 'SHARES',
  VIEWS = 'VIEWS'
}

export type InstagramStoryPost = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly exits?: Maybe<Scalars['Int']>;
  readonly follow?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly interaction?: Maybe<Scalars['Int']>;
  readonly linkClicks?: Maybe<Scalars['Int']>;
  readonly postDate?: Maybe<Scalars['DateTime']>;
  readonly postUrl: Scalars['String'];
  readonly productButtonClick?: Maybe<Scalars['Int']>;
  readonly productPageView?: Maybe<Scalars['Int']>;
  readonly reach?: Maybe<Scalars['Int']>;
  readonly stickerTaps?: Maybe<Scalars['Int']>;
  readonly swipeForward?: Maybe<Scalars['Int']>;
  readonly tapsBack?: Maybe<Scalars['Int']>;
  readonly tapsForward?: Maybe<Scalars['Int']>;
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type InstagramStoryPostOrderBy = {
  readonly field?: InputMaybe<InstagramStoryPostSortField>;
  readonly order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum InstagramStoryPostSortField {
  COMMENT = 'COMMENT',
  EXITS = 'EXITS',
  FOLLOW = 'FOLLOW',
  IMPRESSIONS = 'IMPRESSIONS',
  INTERACTION = 'INTERACTION',
  LINK_CLICKS = 'LINK_CLICKS',
  POST_DATE = 'POST_DATE',
  PRODUCT_BUTTON_CLICK = 'PRODUCT_BUTTON_CLICK',
  PRODUCT_PAGE_VIEW = 'PRODUCT_PAGE_VIEW',
  REACH = 'REACH',
  STICKER_TAPS = 'STICKER_TAPS',
  SWIPE_FORWARD = 'SWIPE_FORWARD',
  TAPS_BACK = 'TAPS_BACK',
  TAPS_FORWARD = 'TAPS_FORWARD'
}

export type InstagramUGCAddedHashtagPayload = {
  readonly hashtag: Scalars['String'];
  readonly hashtagSystemId: Scalars['Int'];
};

export type InstagramUGCManagementBasicInfoPayload = {
  readonly addedHashtags: ReadonlyArray<InstagramUGCAddedHashtagPayload>;
  readonly brandInstagramUserName: Scalars['String'];
};

export type InstagramUGCManagementListPostsCountPayload = {
  readonly totalNumber: Scalars['Int'];
};

export type InstagramUGCManagementListPostsItemPayload = {
  readonly comments: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postSnsId: Scalars['String'];
  readonly status: UGCPostStatus;
  readonly thumbnail?: Maybe<Scalars['String']>;
};

export type InstagramUGCManagementListPostsPayload = {
  readonly posts: ReadonlyArray<InstagramUGCManagementListPostsItemPayload>;
};

export type InstagramUGCManagementPostDetailsPayload = {
  readonly commentingAllowed: Scalars['Boolean'];
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly likes: Scalars['Int'];
  readonly mediaURLs: ReadonlyArray<Scalars['String']>;
  readonly postDate: Scalars['Date'];
  readonly postSNSId: Scalars['String'];
  readonly postUrl: Scalars['String'];
  readonly showStaffRequest: Scalars['Boolean'];
  readonly staffComment?: Maybe<StaffComment>;
  readonly status: UGCPostStatus;
};

export type InstagramUGCPostsSortInput = {
  readonly field: InstagramUGCPostsSortOrder;
  readonly order: Order;
};

export enum InstagramUGCPostsSortOrder {
  ENGAGEMENT = 'ENGAGEMENT',
  POST_DATE = 'POST_DATE'
}

export type InstagramUserHashtag = {
  readonly hashtag: Scalars['String'];
  readonly hashtagId: Scalars['Int'];
};

export type InstagramUserHashtagListPostsItemPayload = {
  readonly accountId?: Maybe<Scalars['Int']>;
  readonly avatar: Scalars['String'];
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly engagement: Scalars['Int'];
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly influencerId?: Maybe<Scalars['Int']>;
  readonly likes: Scalars['Int'];
  readonly media: ReadonlyArray<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly postDate: Scalars['Date'];
  readonly postId: Scalars['Int'];
  readonly rank: Scalars['Int'];
};

export type InstagramUserHashtagListPostsPayload = {
  readonly count: Scalars['Int'];
  readonly posts: ReadonlyArray<InstagramUserHashtagListPostsItemPayload>;
};

export type InstagramUserHashtagPayload = {
  readonly hashtags: ReadonlyArray<InstagramUserHashtag>;
};

export type InstagramUserHashtagPostsSortInput = {
  readonly field?: InputMaybe<InstagramUserHashtagPostsSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum InstagramUserHashtagPostsSortOrder {
  COMMENTS = 'COMMENTS',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  RANK = 'RANK'
}

export type IsInfluencerUUUM = {
  readonly isUuum: Scalars['Boolean'];
};

export type JoinAutoManagedCampaignInput = {
  readonly campaignId: Scalars['ID'];
  readonly methodIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly methods?: InputMaybe<ReadonlyArray<CampaignPromotionMethod>>;
};

export type JoinAutoManagedCampaignPayload = {
  readonly id: Scalars['ID'];
  readonly ok: Scalars['Boolean'];
};

export type JoinMarketplaceByStaff = {
  readonly ok: Scalars['Boolean'];
};

export type JoinMarketplaceByStaffInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  /** for mkp affiliate campaign without post-tracking */
  readonly methods?: InputMaybe<ReadonlyArray<CampaignPromotionMethod>>;
};

export type JoinMarketplaceV2 = {
  readonly ok: Scalars['Boolean'];
};

export type JoinMarketplaceV2Input = {
  readonly campaignId: Scalars['Int'];
  readonly isMobile?: InputMaybe<Scalars['Boolean']>;
  readonly methodIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly methods?: InputMaybe<ReadonlyArray<CampaignPromotionMethod>>;
  readonly socialAccountId?: InputMaybe<Scalars['Int']>;
  readonly socialMedia?: InputMaybe<SocialAccountType>;
};

export type JoinTiktokSpecialCampaign = {
  readonly ok: Scalars['Boolean'];
};

export type JoinTiktokSpecialCampaignInput = {
  readonly campaignId: Scalars['Int'];
  readonly socialAccountId: Scalars['Int'];
};

export type JoinableAccount = {
  readonly activeToken: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly disabled: Scalars['Boolean'];
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly username: Scalars['String'];
};

export type JoinableFbAccount = {
  readonly activeToken: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly disabled: Scalars['Boolean'];
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly userId?: Maybe<Scalars['String']>;
  readonly username: Scalars['String'];
};

export type JoinableInstagramAccount = {
  readonly activeToken: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly disabled: Scalars['Boolean'];
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly isBusinessAccount: Scalars['Boolean'];
  readonly username: Scalars['String'];
};

export type JoinablePromotionMethodEmailNewsLetterPayload = {
  readonly description: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type JoinablePromotionMethodMobileAppPayload = {
  readonly id: Scalars['ID'];
  readonly mobileAppType: PromotionMobileAppType;
  readonly url: Scalars['String'];
};

export type JoinablePromotionMethodOfflinePayload = {
  readonly description: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type JoinablePromotionMethodPodCastPayload = {
  readonly description: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly url: Scalars['String'];
};

export type JoinablePromotionMethodSocialAccountPayload = {
  readonly avatar: Scalars['String'];
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type JoinablePromotionMethodWebsitePayload = {
  readonly description: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly url: Scalars['String'];
};

export type JoinedCampaign = {
  readonly id: Scalars['Int'];
  readonly socialAccountId?: Maybe<Scalars['Int']>;
  readonly socialAccountType?: Maybe<SocialAccountType>;
  readonly title: Scalars['String'];
  readonly type: CampaignType;
};

export type JoinedPromotionMethodEmailNewsLetterPayload = {
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type JoinedPromotionMethodMobileAppPayload = {
  readonly id: Scalars['ID'];
  readonly mobileAppType?: Maybe<PromotionMobileAppType>;
  readonly url?: Maybe<Scalars['String']>;
};

export type JoinedPromotionMethodOfflinePayload = {
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type JoinedPromotionMethodPodCastPayload = {
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly url?: Maybe<Scalars['String']>;
};

export type JoinedPromotionMethodSocialAccountPayload = {
  readonly avatar: Scalars['String'];
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type JoinedPromotionMethodWebsitePayload = {
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly url?: Maybe<Scalars['String']>;
};

export type LineBroadcast = {
  readonly click?: Maybe<Scalars['Int']>;
  readonly clickRate?: Maybe<Scalars['Float']>;
  readonly createdDateTime: Scalars['String'];
  readonly delivered?: Maybe<Scalars['Int']>;
  readonly deliveredDateTime?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly imageUrl: Scalars['String'];
  readonly open?: Maybe<Scalars['Int']>;
  readonly openRate?: Maybe<Scalars['Float']>;
  readonly status: LineBroadcastStatus;
  readonly tags: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type LineBroadcastFloatValue = {
  readonly count: Scalars['Float'];
  readonly growth?: Maybe<Scalars['Float']>;
  readonly percentage?: Maybe<Scalars['Float']>;
};

export type LineBroadcastIntValue = {
  readonly count: Scalars['Int'];
  readonly growth?: Maybe<Scalars['Int']>;
  readonly percentage?: Maybe<Scalars['Float']>;
};

export enum LineBroadcastStatus {
  DELIVERED = 'DELIVERED',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  SCHEDULED = 'SCHEDULED',
  SENDING = 'SENDING'
}

export type LineChat = {
  readonly chatId: Scalars['String'];
  readonly checked: Scalars['Boolean'];
  readonly checkedAt?: Maybe<Scalars['Long']>;
  readonly fanId?: Maybe<Scalars['Long']>;
  readonly lastMessage: Scalars['String'];
  readonly lastMessageAt: Scalars['Long'];
  readonly pic?: Maybe<LineChatPIC>;
  readonly resolved: Scalars['Boolean'];
  readonly userAvatar?: Maybe<Scalars['String']>;
  readonly userEmail?: Maybe<Scalars['String']>;
  readonly userId: Scalars['String'];
  readonly userName: Scalars['String'];
};

export type LineChatPIC = {
  readonly id: Scalars['Long'];
  readonly name: Scalars['String'];
};

export type LineContentItemInput = {
  readonly buttonMessage?: InputMaybe<ButtonMessageInput>;
  readonly imageMessage?: InputMaybe<ImageMessageInput>;
  readonly mediaMessage?: InputMaybe<ImageVideoMessageInput>;
  readonly plainTextMessage?: InputMaybe<PlainTextMessageInput>;
  readonly richMessage?: InputMaybe<RichMessageInput>;
  readonly type: LineMessageType;
};

export type LineContentItemPayload = {
  readonly buttonMessage?: Maybe<ButtonMessagePayload>;
  readonly imageMessage?: Maybe<ImageMessagePayload>;
  readonly mediaMessage?: Maybe<ImageVideoMessagePayload>;
  readonly plainTextMessage?: Maybe<PlainTextMessagePayload>;
  readonly richMessage?: Maybe<RichMessagePayload>;
  readonly type: LineMessageType;
};

export enum LineMessageType {
  BUTTON = 'BUTTON',
  IMAGES = 'IMAGES',
  IMAGE_VIDEO = 'IMAGE_VIDEO',
  PLAIN_TEXT = 'PLAIN_TEXT',
  RICH = 'RICH'
}

export type LinkBioBackground = {
  readonly bgColor?: Maybe<Scalars['String']>;
  readonly media?: Maybe<Scalars['String']>;
};

export type LinkBioPageColor = {
  readonly buttonColor?: Maybe<Scalars['String']>;
  readonly buttonFontColor?: Maybe<Scalars['String']>;
  readonly fontColor?: Maybe<Scalars['String']>;
};

export type LinkCmsChannelAssets = {
  readonly ok: Scalars['Boolean'];
};

export type LinkCmsChannelAssetsInput = {
  readonly records: ReadonlyArray<CmsChannelAssetInput>;
};

export type Logo = {
  readonly logoUrl?: Maybe<Scalars['String']>;
};

export type MadeMarketplaceCampaignPost = {
  readonly ok: Scalars['Boolean'];
};

export type MadeMarketplaceCampaignPostInput = {
  readonly campaignId: Scalars['Int'];
};

export type MajorCategory = {
  readonly id: Scalars['Int'];
  readonly minorCategories: ReadonlyArray<MinorCategory>;
  readonly name: Scalars['String'];
};

/**  `MarkReadNotificationByAppInput` input accepts `appType`: a enum type which represents the notification scope of application */
export type MarkReadNotificationByAppInput = {
  readonly appType: AppType;
};

/**  `MarkReadNotificationPayload` payload houses an `ok` boolean to determine the success of the operation. */
export type MarkReadNotificationPayload = {
  readonly ok: Scalars['Boolean'];
};

export type Marketplace = {
  readonly advertiser: AdvertiserName;
  readonly agencyMarginRate?: Maybe<Scalars['Float']>;
  readonly budget: Scalars['Float'];
  readonly campaignType: MarketplaceCampaignDetailType;
  readonly commissionRate?: Maybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerAction?: Maybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerClick?: Maybe<Scalars['Float']>;
  readonly costPerComment?: Maybe<Scalars['Float']>;
  readonly costPerFollower?: Maybe<Scalars['Float']>;
  readonly costPerLike?: Maybe<Scalars['Float']>;
  /** for referral code Tracking */
  readonly costPerOrder?: Maybe<Scalars['Float']>;
  readonly costPerPost?: Maybe<Scalars['Float']>;
  /** for referral code Tracking */
  readonly costPerSaleReferral?: Maybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerSaleTune?: Maybe<Scalars['Float']>;
  readonly costPerShare?: Maybe<Scalars['Float']>;
  readonly costPerView?: Maybe<Scalars['Float']>;
  readonly country: CountryName;
  readonly currency: Scalars['String'];
  readonly currentCost?: Maybe<Scalars['Int']>;
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly id: Scalars['Int'];
  readonly influencerManagementPics?: Maybe<ReadonlyArray<PicName>>;
  readonly isPostTracking: Scalars['Boolean'];
  readonly isSelectionCampaign: Scalars['Boolean'];
  readonly marginRate: Scalars['Float'];
  readonly salesPics?: Maybe<ReadonlyArray<PicName>>;
  readonly socialMedia: CampaignSocialMediaType;
  readonly startDate: Scalars['Date'];
  readonly status: MarketplaceCampaignStatus;
  readonly title: Scalars['String'];
};

export type MarketplaceAffiliateCommissionFeeInput = {
  readonly commissionFee: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
};

export type MarketplaceAffiliateCommissionInfluencer = {
  readonly currency: Scalars['String'];
  readonly endCoverageDate: Scalars['Date'];
  readonly fixedDate: Scalars['Date'];
  readonly influencer: MarketplacePostInfluencer;
  readonly postId: Scalars['Int'];
  readonly revenue: Scalars['Float'];
  readonly startCoverageDate: Scalars['Date'];
  readonly status: MarketplaceAffiliateCommissionStatus;
};

export type MarketplaceAffiliateCommissionRateInput = {
  readonly commissionRate: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
};

/** An enumeration. */
export enum MarketplaceAffiliateCommissionStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED'
}

export type MarketplaceAffiliateJoinedInfluencer = {
  readonly costEditable?: Maybe<Scalars['Boolean']>;
  readonly couponCode?: Maybe<Scalars['String']>;
  readonly influencer: MarketplacePostInfluencer;
  readonly joinedDate: Scalars['Date'];
  readonly promotionMethods: ReadonlyArray<CampaignPromotionMethod>;
  readonly revenueItems: ReadonlyArray<MarketplaceRevenueItem>;
};

export type MarketplaceAffiliatePayoutPeriodInput = {
  readonly fixedDate: Scalars['Date'];
  readonly postId: Scalars['Int'];
};

export type MarketplaceAffiliateReportByDate = {
  readonly clicks: Scalars['Int'];
  readonly conversions: Scalars['Int'];
  readonly cost: Scalars['Float'];
  readonly cvr?: Maybe<Scalars['Float']>;
  readonly date: Scalars['Date'];
  readonly grossProfit: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly sales: Scalars['Float'];
};

export type MarketplaceAffiliateReportByInfluencer = {
  readonly clicks: Scalars['Int'];
  readonly commissionRate: Scalars['Float'];
  readonly conversions: Scalars['Int'];
  readonly cost: Scalars['Float'];
  readonly cvr?: Maybe<Scalars['Float']>;
  readonly influencerCost: Scalars['Float'];
  readonly influencerInfo: CampaignInfluencerInfo;
  readonly promotionMethods: ReadonlyArray<CampaignPromotionMethod>;
  readonly sales: Scalars['Float'];
};

/** An enumeration. */
export enum MarketplaceAffiliateReportByInfluencerField {
  CLICK = 'CLICK',
  COST = 'COST',
  CVR = 'CVR',
  INFLUENCER_COMMISSION_RATE = 'INFLUENCER_COMMISSION_RATE',
  INFLUENCER_COST = 'INFLUENCER_COST',
  ORDER = 'ORDER',
  SALE = 'SALE'
}

export type MarketplaceAffiliateReportByInfluencerOrderBy = {
  readonly field?: InputMaybe<MarketplaceAffiliateReportByInfluencerField>;
  readonly order?: InputMaybe<ORDER>;
};

export type MarketplaceAffiliateReportForInfluencer = {
  readonly clicks: Scalars['Int'];
  readonly commission: Scalars['Float'];
  readonly conversions: Scalars['Int'];
  readonly date: Scalars['Date'];
};

export type MarketplaceAffiliateReportSummary = {
  readonly average: MarketplaceAffiliateStatsItem;
  readonly chartData: ReadonlyArray<AffiliateCampaignChartReport>;
  readonly joinedCount: Scalars['Int'];
  readonly summary: MarketplaceAffiliateStatsItem;
};

export type MarketplaceAffiliateReportSummaryForInfluencer = {
  readonly clicks: Scalars['Int'];
  readonly commission: Scalars['Float'];
  readonly conversions: Scalars['Int'];
  readonly currency: Scalars['String'];
};

export type MarketplaceAffiliateStatsItem = {
  readonly clicks: Scalars['Int'];
  readonly conversions: Scalars['Int'];
  readonly cost: Scalars['Float'];
  readonly cvr?: Maybe<Scalars['Float']>;
  readonly grossProfit: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly sales: Scalars['Float'];
};

/** An enumeration. */
export enum MarketplaceCampaignAppliedStatus {
  APPLYING = 'APPLYING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export type MarketplaceCampaignCouponTrackingInput = {
  readonly campaignId: Scalars['Int'];
  readonly couponCode: Scalars['String'];
  readonly saleAmount: Scalars['Float'];
  readonly transactionId: Scalars['String'];
};

export type MarketplaceCampaignCouponTrackingPayload = {
  readonly ok: Scalars['Boolean'];
};

export enum MarketplaceCampaignDetailType {
  E_COMMERCE = 'E_COMMERCE',
  NORMAL = 'NORMAL',
  REFERRAL_CODE = 'REFERRAL_CODE',
  URL_TRACKING = 'URL_TRACKING'
}

/** An enumeration. */
export enum MarketplaceCampaignDraftPostStatus {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED'
}

/**
 *
 *     status of influencer and MKP participation for Staff to force influencer joins campaign
 *
 */
export enum MarketplaceCampaignParticipateStatus {
  APPLIED = 'APPLIED',
  AVAILABLE = 'AVAILABLE',
  JOINED = 'JOINED',
  REJECTED = 'REJECTED',
  UNAVAILABLE = 'UNAVAILABLE',
  WAITING = 'WAITING'
}

/** An enumeration. */
export enum MarketplaceCampaignPostHistoryStatus {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  DRAFT_REJECTED = 'DRAFT_REJECTED',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  REJECTED = 'REJECTED'
}

export type MarketplaceCampaignPostReportForInfluencer = {
  readonly currency: Scalars['String'];
  /** campaign ID */
  readonly id: Scalars['Int'];
  readonly postReport: InfluencerPostReportStatsMarketplace;
  readonly postReports: ReadonlyArray<InfluencerPostReportStatsMarketplace>;
  /** campaign revenue */
  readonly revenue: Scalars['Float'];
  /** campaign title */
  readonly title: Scalars['String'];
};

/** An enumeration. */
export enum MarketplaceCampaignPostStatus {
  AFFILIATE = 'AFFILIATE',
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  SHIPPED = 'SHIPPED',
  TRACKING = 'TRACKING',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED'
}

/** An enumeration. */
export enum MarketplaceCampaignPostStatusForAdvertiser {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  DRAFT_REJECTED = 'DRAFT_REJECTED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  SHIPPED = 'SHIPPED',
  TRACKING = 'TRACKING',
  WARNING = 'WARNING'
}

/** An enumeration. */
export enum MarketplaceCampaignPostStatusForSearch {
  AD_FRAUD = 'AD_FRAUD',
  API_ERROR = 'API_ERROR',
  APPROVED = 'APPROVED',
  DELETED_POST = 'DELETED_POST',
  DRAFT = 'DRAFT',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  INVALID_CONTENT = 'INVALID_CONTENT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  SCRAPING_ERROR = 'SCRAPING_ERROR',
  TRACKING = 'TRACKING',
  WARNING = 'WARNING'
}

/** An enumeration. */
export enum MarketplaceCampaignRejectedStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING'
}

export type MarketplaceCampaignReport = {
  readonly InternalInfo?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly average?: Maybe<NewCampaignStatsItemRow>;
  readonly campaignInfo: CampaignReportCampaignInfo;
  readonly chartData: ReadonlyArray<CampaignChartReport>;
  readonly currency: Scalars['String'];
  readonly influencerReports: ReadonlyArray<CampaignInfluencerReport>;
  readonly orderedSummaryDaily?: Maybe<ReadonlyArray<CampaignSummaryDaily>>;
  readonly postCount: Scalars['Int'];
  readonly summary: NewCampaignStatsItemRow;
  readonly summaryDaily?: Maybe<ReadonlyArray<CampaignSummaryDaily>>;
};

/** An enumeration. */
export enum MarketplaceCampaignRevenueType {
  INFLUENCER_COMMISSION_RATE = 'INFLUENCER_COMMISSION_RATE',
  REVENUE_PER_ACTION = 'REVENUE_PER_ACTION',
  REVENUE_PER_CLICK = 'REVENUE_PER_CLICK',
  REVENUE_PER_COMMENT = 'REVENUE_PER_COMMENT',
  REVENUE_PER_LIKE = 'REVENUE_PER_LIKE',
  REVENUE_PER_POST = 'REVENUE_PER_POST',
  REVENUE_PER_SHARE = 'REVENUE_PER_SHARE',
  REVENUE_PER_VIEW = 'REVENUE_PER_VIEW'
}

export enum MarketplaceCampaignStatus {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING'
}

/** An enumeration. */
export enum MarketplaceCampaignType {
  AFFILIATE = 'AFFILIATE',
  AFFILIATE_SPECIAL_DEAL = 'AFFILIATE_SPECIAL_DEAL',
  NORMAL = 'NORMAL',
  SELECTION = 'SELECTION'
}

export type MarketplaceDetail = {
  readonly additionalRequirement?: Maybe<Scalars['String']>;
  readonly advertiser: AdvertiserName;
  readonly agencyMarginRate?: Maybe<Scalars['Float']>;
  readonly allowNewInfluencer?: Maybe<Scalars['Boolean']>;
  readonly brandName?: Maybe<Scalars['String']>;
  readonly budget: Scalars['Float'];
  readonly campaignCategory?: Maybe<CampaignCategoryName>;
  readonly campaignCreator?: Maybe<CampaignCreatorWithName>;
  readonly campaignType: MarketplaceCampaignDetailType;
  readonly campaignUrl?: Maybe<Scalars['String']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly clickUrl?: Maybe<Scalars['String']>;
  readonly commissionRate?: Maybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerAction?: Maybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerClick?: Maybe<Scalars['Float']>;
  readonly costPerComment?: Maybe<Scalars['Float']>;
  readonly costPerFollower?: Maybe<Scalars['Float']>;
  readonly costPerLike?: Maybe<Scalars['Float']>;
  /** for referral code Tracking */
  readonly costPerOrder?: Maybe<Scalars['Float']>;
  readonly costPerPost?: Maybe<Scalars['Float']>;
  /** for referral code Tracking */
  readonly costPerSaleReferral?: Maybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerSaleTune?: Maybe<Scalars['Float']>;
  readonly costPerShare?: Maybe<Scalars['Float']>;
  readonly costPerView?: Maybe<Scalars['Float']>;
  readonly country: CountryName;
  readonly couponUploadFiles?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly currency: Scalars['String'];
  readonly currentCost?: Maybe<Scalars['Int']>;
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly genders: ReadonlyArray<Genders>;
  readonly hashtags: ReadonlyArray<Scalars['String']>;
  readonly hubspotDealIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['Int'];
  readonly influencerManagementPics?: Maybe<ReadonlyArray<PicName>>;
  readonly isAllowMultiplePosts?: Maybe<Scalars['Boolean']>;
  readonly isAutoInfluencerApproval?: Maybe<Scalars['Boolean']>;
  readonly isGiftingCampaign: Scalars['Boolean'];
  readonly isPostTracking: Scalars['Boolean'];
  readonly isSelectionCampaign: Scalars['Boolean'];
  readonly landingPageUrl?: Maybe<Scalars['String']>;
  readonly marginRate: Scalars['Float'];
  readonly materialUrl?: Maybe<Scalars['String']>;
  readonly materials?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly maxAge: Scalars['Int'];
  readonly maxFollowers: Scalars['Int'];
  readonly maximumRevenuePerInfluencer?: Maybe<Scalars['Float']>;
  readonly minAge: Scalars['Int'];
  readonly minFollowers: Scalars['Int'];
  readonly mkpServiceCampaignId?: Maybe<Scalars['Int']>;
  readonly needCouponsCount?: Maybe<Scalars['Int']>;
  readonly preLaunchDate?: Maybe<Scalars['Date']>;
  readonly productThumbNail?: Maybe<Scalars['String']>;
  readonly regions?: Maybe<ReadonlyArray<RegionName>>;
  readonly rejectedInfo?: Maybe<MarketplaceRejectedAdvertiserInfo>;
  readonly requireDraftPost: Scalars['Boolean'];
  readonly requirement: Scalars['String'];
  readonly salesPics?: Maybe<ReadonlyArray<PicName>>;
  readonly sampleUrl?: Maybe<Scalars['String']>;
  readonly serviceInformation: Scalars['String'];
  readonly socialMedia: CampaignSocialMediaType;
  readonly startDate: Scalars['Date'];
  readonly status: MarketplaceCampaignStatus;
  readonly thumbnails: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingOptions?: Maybe<ReadonlyArray<CampaignTrackingOption>>;
};

export type MarketplaceDetailForInfluencer = {
  readonly ableToJoin?: Maybe<Scalars['Boolean']>;
  readonly additionalRequirement?: Maybe<Scalars['String']>;
  /** campaign status using for AnyX */
  readonly anyXStatus?: Maybe<AnyXMarketplaceCampaignStatus>;
  /** exists when campaign type is SELECTION */
  readonly appliedStatus?: Maybe<MarketplaceCampaignAppliedStatus>;
  readonly campaignDetailType: MarketplaceCampaignDetailType;
  /** @deprecated not useful anymore */
  readonly campaignType: MarketplaceCampaignType;
  readonly clickUrl?: Maybe<Scalars['String']>;
  readonly commissionRate?: Maybe<Scalars['Float']>;
  readonly confirmMadePostAvailability?: Maybe<Scalars['Boolean']>;
  readonly couponCode?: Maybe<Scalars['String']>;
  readonly createdDate: Scalars['Date'];
  readonly currency: Scalars['String'];
  readonly endDate?: Maybe<Scalars['Date']>;
  /** Whether she uploaded her post on social media or not. */
  readonly hasReport: Scalars['Boolean'];
  readonly hashtags: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly isApplied: Scalars['Boolean'];
  readonly isAutoInfluencerApproval?: Maybe<Scalars['Boolean']>;
  readonly isCouponCampaign?: Maybe<Scalars['Boolean']>;
  readonly isGiftingCampaign?: Maybe<Scalars['Boolean']>;
  readonly isJoined: Scalars['Boolean'];
  readonly isPostTracking?: Maybe<Scalars['Boolean']>;
  readonly isSelectionCampaign?: Maybe<Scalars['Boolean']>;
  readonly isWaiting: Scalars['Boolean'];
  readonly materialUrl?: Maybe<Scalars['String']>;
  readonly materials?: Maybe<ReadonlyArray<MaterialName>>;
  readonly maxFollowers: Scalars['Int'];
  readonly maximumRevenuePerInfluencer?: Maybe<Scalars['Float']>;
  readonly minFollowers: Scalars['Int'];
  readonly minimumPaymentAmount: Scalars['Float'];
  readonly postStatus?: Maybe<CampaignPostStatusForInfluencer>;
  readonly postTrackingUrl?: Maybe<Scalars['String']>;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly preLaunchDate?: Maybe<Scalars['Date']>;
  readonly remainingAmount: Scalars['Float'];
  readonly requireDraftPost: Scalars['Boolean'];
  readonly requirement: Scalars['String'];
  readonly revenuePerAction?: Maybe<Scalars['Float']>;
  readonly revenuePerClick?: Maybe<Scalars['Float']>;
  readonly revenuePerComment?: Maybe<Scalars['Float']>;
  readonly revenuePerFollower?: Maybe<Scalars['Float']>;
  readonly revenuePerLike?: Maybe<Scalars['Float']>;
  readonly revenuePerOrder?: Maybe<Scalars['Float']>;
  readonly revenuePerPost?: Maybe<Scalars['Float']>;
  readonly revenuePerShare?: Maybe<Scalars['Float']>;
  readonly revenuePerView?: Maybe<Scalars['Float']>;
  readonly sampleUrl?: Maybe<Scalars['String']>;
  readonly serviceInformation: Scalars['String'];
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate: Scalars['Date'];
  readonly status: CampaignStatusForInfluencer;
  readonly thumbNail: Scalars['String'];
  readonly thumbnails: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly totalAmount: Scalars['Float'];
  readonly trackingOptions?: Maybe<ReadonlyArray<CampaignTrackingOption>>;
  readonly trackingUrl?: Maybe<Scalars['String']>;
  readonly type: CampaignType;
  readonly warningReason?: Maybe<WarningReason>;
};

export type MarketplaceDraftPostDetail = {
  readonly caption: Scalars['String'];
  readonly createdDate?: Maybe<Scalars['Date']>;
  readonly id: Scalars['Int'];
  readonly images?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly influencer: MarketplacePostDetailInfluencer;
  readonly rejectedDate?: Maybe<Scalars['Date']>;
  readonly rejectedMessage?: Maybe<Scalars['String']>;
  readonly status: MarketplaceCampaignDraftPostStatus;
};

export type MarketplaceDraftPostForInfluencer = {
  readonly content: Scalars['String'];
  readonly materials: ReadonlyArray<Scalars['String']>;
  readonly rejectedMessage?: Maybe<Scalars['String']>;
  readonly status: MarketplaceCampaignDraftPostStatus;
};

export type MarketplaceForReorder = {
  readonly id: Scalars['Int'];
  readonly status: MarketplaceCampaignStatus;
  readonly thumbnail: Scalars['String'];
  readonly title: Scalars['String'];
};

export type MarketplaceInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly country: CountryNameForInfluencer;
  readonly email?: Maybe<Scalars['Boolean']>;
  readonly engagement: InfluencerEngagement;
  readonly facebook?: Maybe<SocialAccountV2>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  readonly id: Scalars['Int'];
  readonly instagram?: Maybe<SocialAccountV2>;
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
  readonly name: Scalars['String'];
  readonly participateStatus: MarketplaceCampaignParticipateStatus;
  readonly status: SocialAccountStatus;
  readonly tiktok?: Maybe<SocialAccountV2>;
  readonly twitter?: Maybe<SocialAccountV2>;
  readonly youtube?: Maybe<SocialAccountV2>;
};

export type MarketplaceInfluencerRevenueInput = {
  readonly influencerCommissionRate?: InputMaybe<Scalars['Float']>;
  readonly influencerId: Scalars['Int'];
  readonly revenuePerAction?: InputMaybe<Scalars['Float']>;
  readonly revenuePerClick?: InputMaybe<Scalars['Float']>;
  readonly revenuePerComment?: InputMaybe<Scalars['Float']>;
  readonly revenuePerLike?: InputMaybe<Scalars['Float']>;
  readonly revenuePerPost?: InputMaybe<Scalars['Float']>;
  readonly revenuePerShare?: InputMaybe<Scalars['Float']>;
  readonly revenuePerView?: InputMaybe<Scalars['Float']>;
};

export type MarketplaceJoinableAccountsPayload = {
  readonly facebookAccount?: Maybe<JoinableFbAccount>;
  readonly facebookPages: ReadonlyArray<JoinableAccount>;
  readonly instagramAccounts: ReadonlyArray<JoinableInstagramAccount>;
  readonly tiktokAccounts: ReadonlyArray<JoinableAccount>;
  readonly twitterAccounts: ReadonlyArray<JoinableAccount>;
  readonly youtubeAccounts: ReadonlyArray<JoinableAccount>;
};

export type MarketplaceJoinablePromotionMethodsPayload = {
  readonly emailNewsLetters: ReadonlyArray<JoinablePromotionMethodEmailNewsLetterPayload>;
  readonly facebookAccount?: Maybe<JoinablePromotionMethodSocialAccountPayload>;
  readonly facebookPages: ReadonlyArray<JoinablePromotionMethodSocialAccountPayload>;
  readonly instagramAccounts: ReadonlyArray<JoinablePromotionMethodSocialAccountPayload>;
  readonly mobileApps: ReadonlyArray<JoinablePromotionMethodMobileAppPayload>;
  readonly offlines: ReadonlyArray<JoinablePromotionMethodOfflinePayload>;
  readonly podCasts: ReadonlyArray<JoinablePromotionMethodPodCastPayload>;
  readonly tiktokAccounts: ReadonlyArray<JoinablePromotionMethodSocialAccountPayload>;
  readonly twitterAccounts: ReadonlyArray<JoinablePromotionMethodSocialAccountPayload>;
  readonly websites: ReadonlyArray<JoinablePromotionMethodWebsitePayload>;
  readonly youtubeAccounts: ReadonlyArray<JoinablePromotionMethodSocialAccountPayload>;
};

export type MarketplaceJoinedAccountPayload = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount: Scalars['Int'];
  readonly profileUrl: Scalars['String'];
  readonly socialAccountType: SocialAccountType;
  readonly username: Scalars['String'];
};

export type MarketplaceJoinedMethodsInput = {
  readonly campaignId: Scalars['ID'];
};

export type MarketplaceJoinedMethodsPayload = {
  readonly emailNewsLetters: ReadonlyArray<JoinedPromotionMethodEmailNewsLetterPayload>;
  readonly facebookAccount?: Maybe<JoinedPromotionMethodSocialAccountPayload>;
  readonly facebookPages: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
  readonly instagramAccounts: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
  readonly mobileApps: ReadonlyArray<JoinedPromotionMethodMobileAppPayload>;
  readonly offlines: ReadonlyArray<JoinedPromotionMethodOfflinePayload>;
  readonly podCasts: ReadonlyArray<JoinedPromotionMethodPodCastPayload>;
  readonly tiktokAccounts: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
  readonly twitterAccounts: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
  readonly websites: ReadonlyArray<JoinedPromotionMethodWebsitePayload>;
  readonly youtubeAccounts: ReadonlyArray<JoinedPromotionMethodSocialAccountPayload>;
};

export type MarketplaceJoinedMethodsPayloadV2 = {
  readonly methods: ReadonlyArray<CampaignPromotionMethod>;
};

export type MarketplacePost = {
  readonly content?: Maybe<Scalars['String']>;
  readonly costEditable?: Maybe<Scalars['Boolean']>;
  readonly couponCode?: Maybe<Scalars['String']>;
  readonly draftId?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly influencer: MarketplacePostInfluencer;
  readonly joinedDate: Scalars['Date'];
  readonly needReview: Scalars['Boolean'];
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly postedDate?: Maybe<Scalars['Date']>;
  readonly revenueItems: ReadonlyArray<MarketplaceRevenueItem>;
  readonly socialAccountId?: Maybe<Scalars['Int']>;
  readonly socialMedia: CampaignSocialMediaType;
  readonly status?: Maybe<MarketplaceCampaignPostStatusForAdvertiser>;
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly warningReason?: Maybe<WarningReason>;
};

export type MarketplacePostComment = {
  readonly commentedDate?: Maybe<Scalars['DateTime']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly username?: Maybe<Scalars['String']>;
};

export type MarketplacePostDetail = {
  readonly caption: Scalars['String'];
  readonly comments?: Maybe<Scalars['Int']>;
  readonly detailedReason?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly images?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postDetailInfluencer: MarketplacePostDetailInfluencer;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly postedDate?: Maybe<Scalars['Date']>;
  readonly reach?: Maybe<Scalars['Int']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialMedia: CampaignSocialMediaType;
  readonly status?: Maybe<MarketplaceCampaignPostStatus>;
  readonly views?: Maybe<Scalars['Int']>;
  readonly warningReason?: Maybe<WarningReason>;
};

export type MarketplacePostDetailInfluencer = {
  readonly avatar: Scalars['String'];
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type MarketplacePostHistory = {
  /** exist only status is REJECTED */
  readonly detailedReason?: Maybe<Scalars['String']>;
  /** Draft post Id */
  readonly draftId?: Maybe<Scalars['Int']>;
  readonly draftPostContent?: Maybe<Scalars['String']>;
  readonly draftPostMaterials?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly operatedDate: Scalars['Date'];
  /** exist only statuses are REJECTED and APPROVED */
  readonly operatedUserName?: Maybe<Scalars['String']>;
  /** exist only status is REJECTED */
  readonly reason?: Maybe<Scalars['String']>;
  readonly status: MarketplaceCampaignPostHistoryStatus;
};

export type MarketplacePostInfluencer = {
  readonly avatar: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type MarketplacePostNoRevenue = {
  readonly content: Scalars['String'];
  readonly influencerAvatar: Scalars['String'];
  readonly influencerId: Scalars['Int'];
  readonly influencerName: Scalars['String'];
  readonly postId: Scalars['Int'];
  readonly postedDate: Scalars['Date'];
  readonly thumbnails: ReadonlyArray<Scalars['String']>;
};

export type MarketplacePostsNoRevenue = {
  readonly count: Scalars['Int'];
  readonly posts: ReadonlyArray<MarketplacePostNoRevenue>;
};

export type MarketplaceRejectedAdvertiserInfo = {
  readonly operatedDate: Scalars['Date'];
  readonly operatedUser: OperatedUserInfo;
  readonly reason?: Maybe<Scalars['String']>;
  readonly status: MarketplaceCampaignRejectedStatus;
};

export type MarketplaceRevenueItem = {
  readonly revenueType: MarketplaceCampaignRevenueType;
  readonly updatedDate?: Maybe<Scalars['Date']>;
  readonly value?: Maybe<Scalars['Float']>;
};

export type MarketplaceTracking = {
  /** only for Pixel tracking campaign */
  readonly pixelCode?: Maybe<Scalars['String']>;
  /** only for postback URL campaign */
  readonly postbackUrl?: Maybe<Scalars['String']>;
};

export type MarketplaceTrackingPostUrlForInfluencer = {
  /** Available only if influencer joined on-going campaign and post is not fetched yet */
  readonly postUrl?: Maybe<Scalars['String']>;
};

export type MaterialName = {
  readonly id: Scalars['Int'];
  readonly url: Scalars['String'];
};

export type MediaItemInput = {
  readonly image?: InputMaybe<ImageInput>;
  readonly type: MediaType;
  readonly video?: InputMaybe<VideoInput>;
};

export type MediaItemPayload = {
  readonly image?: Maybe<ImagePayload>;
  readonly type: MediaType;
  readonly video?: Maybe<VideoPayload>;
};

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export type MessageTemplateEvent = ChatEvent & {
  readonly id: Scalars['String'];
  readonly templateId: Scalars['String'];
  readonly templateName: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type MessageTemplateItem = {
  readonly name: Scalars['String'];
  readonly templateId: Scalars['String'];
  readonly text: Scalars['String'];
};

export type MinorCategory = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type MobileVersion = {
  /** for ios */
  readonly buildNumber: Scalars['String'];
  /** semantic versioning */
  readonly version: Scalars['String'];
  /** for Android */
  readonly versionCode: Scalars['String'];
};

export type Mutation = {
  readonly addAllSelectedInfluencers?: Maybe<AddAllSelectedInfluencers>;
  readonly addComment?: Maybe<AddCommentPayload>;
  readonly addEmailTemplate?: Maybe<AddEmailTemplatePayload>;
  readonly addFan?: Maybe<AddFanPayload>;
  readonly addFanTags?: Maybe<AddFanTagsPayload>;
  readonly addForm?: Maybe<AddFormPayload>;
  /** Add influencer to user proposal list, on Select Influencers Tab */
  readonly addInfluencerToUserProposal?: Maybe<AddInfluencerToUserProposal>;
  /**
   * Adds an Instagram account to a 'compare' list.
   *         May be used by admin, staff, agency and advertiser users.
   */
  readonly addInstagramCompareAccountAnalytics?: Maybe<AddCompareInstagramAccountAnalytics>;
  /**
   * Adds an Instagram hashtag to a 'hashtag' list.
   *         May be used by admin, staff, agency and advertiser users.
   */
  readonly addInstagramHashtagAnalytics?: Maybe<AddInstagramHashtagAnalytics>;
  /**
   *
   *         Add tracking post url for marketplace campaign on Advertiser platform
   *
   */
  readonly addMarketplacePostUrl?: Maybe<AddMarketplacePostUrl>;
  /**
   *
   *         Add tracking post url for marketplace campaign on Influencer platform
   *
   */
  readonly addMarketplacePostUrlForInfluencer?: Maybe<AddMarketplacePostUrlForInfluencer>;
  readonly addReadTutorial?: Maybe<AddReadTutorial>;
  readonly addSelectedInfluencers?: Maybe<AddSelectedInfluencers>;
  /** Add SNS account of influencer to user proposal list, on Select Influencers Tab */
  readonly addSocialAccountToUserProposal?: Maybe<AddSocialAccountToUserProposal>;
  /** Add Staff's Advertisers To Another Staff */
  readonly addStaffAdvertisersToAnotherStaff?: Maybe<AddStaffAdvertisersToAnotherStaff>;
  /**
   *  Adds a Youtube account to a 'compare' list.
   *  May be used by admin, staff, agency and advertiser users.
   */
  readonly addYoutubeCompareAccountAnalytics: AddYoutubeCompareAccountAnalyticsOutput;
  readonly allowEngagementInfluencerProposalList?: Maybe<AllowEngagementInfluencerProposalList>;
  readonly allowInfluencersUserProposal?: Maybe<AllowInfluencersUserProposal>;
  /**
   * Sets a subscription plan for a specific advertiser.
   *         May be used by admin and staff users only.
   */
  readonly analyticsSelectSubscriptionPlan?: Maybe<AnalyticsSelectSubscriptionPlan>;
  /**  validate the email availability */
  readonly anyXCheckEmail?: Maybe<AnyXCheckEmailPayload>;
  /**  Allow creator user to Connect Social account on AnyX Platform */
  readonly anyXSocialAuthConnect?: Maybe<AnyXSocialAuthConnectPayload>;
  /**  Allow creator user to Connect Social account for LinkBio on AnyX Platform */
  readonly anyXSocialAuthConnectForLinkBio?: Maybe<AnyXSocialAuthConnectForLinkBioPayload>;
  /**  Allow creator user to Disconnect Social account on AnyX Platform */
  readonly anyXSocialAuthDisconnect?: Maybe<AnyXSocialAuthDisconnectPayload>;
  /**  Allow creator user to Reconnect Social accounts on LinkBio feature */
  readonly anyXSocialAuthReconnectForLinkBio?: Maybe<AnyXSocialAuthReconnectForLinkBioPayload>;
  /**  Allow creator user to Reconnect TikTok account on AnyX Platform */
  readonly anyXSocialAuthReconnectTikTok?: Maybe<AnyXSocialAuthReconnectTikTokPayload>;
  readonly anyXSocialAuthReconnectTtcm?: Maybe<AnyXSocialAuthReconnectTTCMPayload>;
  /**  Allow creator user to SignIn with Social account on AnyX Platform */
  readonly anyXSocialAuthSignIn?: Maybe<AnyXSocialAuthSignInPayload>;
  /**  Allow creator user to SignIn with Instagram and LineIDToken by LIFF URL */
  readonly anyXSocialAuthSignInInstagramLine?: Maybe<AnyXSocialAuthSignInInstagramLinePayload>;
  /**  To check SignIn or SignUp is enable of logged-in account from social media platform */
  readonly anyXSocialAuthSignInOrSignUp?: Maybe<AnyXSocialAuthSignInOrSignUpPayload>;
  /**  Allow creator user to SignUp with Social account on AnyX Platform */
  readonly anyXSocialAuthSignUp?: Maybe<AnyXSocialAuthSignUpPayload>;
  /**  Allow creator user to SignUp with Social account on AnyX Platform as Partner Influencer account. */
  readonly anyXSocialAuthSignUpPartner?: Maybe<AnyXSocialAuthSignUpPartnerPayload>;
  /**  Allow creator user to SignUp with Social account on AnyX Platform as Talent Influencer account. */
  readonly anyXSocialAuthSignUpTalent?: Maybe<AnyXSocialAuthSignUpTalentPayload>;
  /**
   *
   *         Approve Applicant influencers to Selection Marketplace campaign
   *
   */
  readonly approveApplicantInfluencers?: Maybe<ApproveApplicantInfluencers>;
  readonly approveAutoManagedPayout?: Maybe<ApproveAutoManagedPayout>;
  readonly approveCmsInvoice?: Maybe<ApproveCmsInvoice>;
  readonly approveMarketplace?: Maybe<ApproveMarketplace>;
  readonly approveMarketplaceAffiliatePayout?: Maybe<ApproveMarketplaceAffiliatePayout>;
  /** approve a draft post */
  readonly approveMarketplaceDraftPost?: Maybe<ApproveMarketplaceDraftPost>;
  readonly approveTiktokSpecialCampaign?: Maybe<ApproveTikTokSpecialCampaign>;
  readonly assignAdvertiserToStaffs?: Maybe<AssignAdvertiserForStaffs>;
  readonly assignChatPic?: Maybe<AssignChatPICPayload>;
  /** Generates a local JWT token from a given Auth0 ID token */
  readonly auth0ToLocalJwt?: Maybe<Auth0ToLocalJWT>;
  readonly authTiktokBusinessAccount?: Maybe<AuthTiktokBusinessAccount>;
  readonly boostTiktokAdPost?: Maybe<BoostTiktokAdPost>;
  /** Bulk update influencers budget */
  readonly bulkUpdateEngagementInfluencersBudget?: Maybe<BulkUpdateEngagementInfluencersBudget>;
  /** Bulk update social accounts budget */
  readonly bulkUpdateEngagementSocialAccountsBudget?: Maybe<BulkUpdateEngagementSocialAccountsBudget>;
  readonly bulkUpdatePackageProposalAccountsBudget?: Maybe<BulkUpdatePackageProposalAccountsBudget>;
  readonly bulkUpdatePackageProposalInfluencersBudget?: Maybe<BulkUpdatePackageProposalInfluencersBudget>;
  readonly bulkUpdateUserProposalAccountsBudget?: Maybe<BulkUpdateUserProposalAccountsBudget>;
  readonly bulkUpdateUserProposalInfluencersBudget?: Maybe<BulkUpdateUserProposalInfluencersBudget>;
  readonly cancelSubscriptionPlan?: Maybe<CancelSubscriptionPlan>;
  /**  Changes a UGC post's status */
  readonly changeInstagramUGCManagementPostStatus: ChangeInstagramUGCManagementPostStatusOutput;
  readonly clearCmsChannelAssetOwner?: Maybe<ClearCmsChannelAssetOwner>;
  /**
   *  `clickNotification` mutation sets a notification as clicked.
   *  The user is known through the `Authorization` header.
   *  Click is defined as a user click.
   */
  readonly clickNotification?: Maybe<ClickNotificationPayload>;
  /**
   *  `clickNotificationByEventId` mutation sets a notification as clicked by event_id.
   *  The user is known through the `Authorization` header.
   *  Click is defined as a user click.
   */
  readonly clickNotificationByEventId?: Maybe<ClickNotificationByEventIdPayload>;
  readonly cloneMarketplace?: Maybe<CloneMarketplace>;
  readonly connectFacebookPagesAndInstagramAccounts?: Maybe<ConnectFacebookPagesAndInstagramAccountsPayload>;
  readonly connectNewShopifyAccount?: Maybe<ConnectNewShopifyAccountPayload>;
  readonly connectPackageToEngagementProposal?: Maybe<ConnectPackageToEngagementProposal>;
  readonly connectPackageWithInfluencerAccounts?: Maybe<ConnectPackageWithInfluencerAccounts>;
  readonly connectPackageWithSocialAccounts?: Maybe<ConnectPackageWithSocialAccounts>;
  readonly connectPackageWithUserProposal?: Maybe<ConnectPackageWithUserProposal>;
  /**  Allow influencer to connect promotion methods */
  readonly connectPromotionMethods?: Maybe<ConnectPromotionMethodsPayload>;
  readonly createAdminUser?: Maybe<CreateAdmin>;
  readonly createAdvertiser?: Maybe<CreateAdvertiser>;
  /** Create Advertiser User */
  readonly createAdvertiserUser?: Maybe<CreateAdvertiserUser>;
  readonly createAdvertiserWithUser?: Maybe<CreateAdvertiserWithUser>;
  /** create AdvertiserId if selected id not exist, and create advertiser user */
  readonly createAdvertiserWithUserV2?: Maybe<CreateAdvertiserWithUserV2>;
  /** Create Ad Agency User */
  readonly createAgencyUser?: Maybe<CreateAgency>;
  readonly createAgencyWithUser?: Maybe<CreateAgencyWithUser>;
  readonly createCreatorStaff?: Maybe<CreateCreatorStaffPayload>;
  readonly createDraftBoostPost?: Maybe<CreateDraftBoostPost>;
  readonly createEngagement?: Maybe<CreateEngagement>;
  readonly createEngagementPostForInfluencerV2?: Maybe<CreateEngagementPostForInfluencerV2>;
  readonly createEngagementPostV2?: Maybe<CreateEngagementPostV2>;
  readonly createGeminiThread?: Maybe<CreateGeminiThread>;
  /**  Influencer bio */
  readonly createInfluencerBio?: Maybe<CreateInfluencerBioPayload>;
  readonly createInfluencerV2?: Maybe<CreateInfluencerV2>;
  /**  Creates hashtag for UGC management */
  readonly createInstagramUGCManagementHashtag: CreateInstagramUGCManagementHashtagOutput;
  readonly createInstagramUserHashtag: CreateInstagramUserHashtagOutput;
  readonly createLineBroadcastMessage?: Maybe<CreateLineBroadcastMessagePayload>;
  readonly createLineChannel?: Maybe<CreateLineChannelPayload>;
  readonly createMarketplace?: Maybe<CreateMarketplace>;
  /** Influencer submit a draft post */
  readonly createMarketplaceDraftPost?: Maybe<CreateMarketplaceDraftPost>;
  readonly createMobileAppVersion?: Maybe<CreateMobileAppVersion>;
  readonly createOpenAiThread?: Maybe<CreateOpenAIThread>;
  readonly createPackage?: Maybe<CreatePackage>;
  readonly createPackageWithInfluencerAccounts?: Maybe<CreatePackageWithInfluencerAccounts>;
  readonly createPackageWithSocialAccounts?: Maybe<CreatePackageWithSocialAccounts>;
  readonly createPackageWithUserProposal?: Maybe<CreatePackageWithUserProposal>;
  /** Create Partner User */
  readonly createPartnerUser?: Maybe<CreatePartnerUser>;
  /**
   *
   *         Creates a self registered advertiser and an advertiser user attached to it with no authentication.
   *
   */
  readonly createSelfRegisteredAdvertiserUser?: Maybe<CreateSelfRegisteredAdvertiserUser>;
  /**
   *  Creates an Analytics account with a connected Youtube or Instagram account.
   *  May be used by admin, staff and agency users.
   */
  readonly createSocialAccountAnalyticsAdminV2: CreateSocialAccountAnalyticsAdminOutputV2;
  /**
   *  Creates an Analytics account with a connected Youtube or Instagram account.
   *  May be used by advertiser users only.
   */
  readonly createSocialAccountAnalyticsAdvertiserV2: CreateSocialAccountAnalyticsAdvertiserOutputV2;
  readonly createStaffUser?: Maybe<CreateStaff>;
  /** Create Talent Agency User */
  readonly createTalentAgencyUser?: Maybe<CreateTalentAgencyUser>;
  readonly createTalentAgencyWithUser?: Maybe<CreateTalentAgencyWithUser>;
  /**  Create Talent Influencer by Talent agency user with multiple social accounts (Facebook, Instagram, Youtube, and Twitter) */
  readonly createTalentInfluencer?: Maybe<CreateTalentInfluencerPayload>;
  readonly createTiktokAdAccount?: Maybe<CreateTiktokAdAccount>;
  readonly createTiktokBusinessCenter?: Maybe<CreateTiktokBusinessCenter>;
  readonly createTiktokSpecialCampaign?: Maybe<CreateTikTokSpecialCampaign>;
  readonly createTiktokUserHashtag: CreateTiktokUserHashtagOutput;
  readonly createTiktokUserKeyword: CreateTiktokUserKeywordOutput;
  readonly deleteAdvertiser?: Maybe<DeleteAdvertiser>;
  readonly deleteAgency?: Maybe<DeleteAgency>;
  /**
   *  Delete brand account and relations to facebook, instagram, youtube accounts and managers
   *  May be used by admin, staff
   */
  readonly deleteBrandAccountAnalytics: DeleteBrandAccountAnalyticsOutput;
  readonly deleteComment?: Maybe<DeleteCommentPayload>;
  readonly deleteEngagement?: Maybe<DeleteEngagement>;
  readonly deleteEngagementInfluencerProposal?: Maybe<DeleteEngagementInfluencerProposal>;
  readonly deleteEngagementPostForInfluencer?: Maybe<DeleteEngagementPostForInfluencer>;
  readonly deleteEngagementProposalJoinedAccount?: Maybe<DeleteEngagementProposalJoinedAccount>;
  readonly deleteEngagementSocialAccountProposal?: Maybe<DeleteEngagementSocialAccountProposal>;
  readonly deleteFanTags?: Maybe<DeleteFanTagsPayload>;
  readonly deleteGeminiThread?: Maybe<DeleteGeminiThread>;
  readonly deleteInfluencer?: Maybe<DeleteInfluencer>;
  readonly deleteInfluencerPackage?: Maybe<DeleteInfluencerPackage>;
  /**
   * Removes a specified 'compare' account from the 'compare' page.
   *         May be used by admin, staff, agency and advertiser users.
   */
  readonly deleteInstagramCompareAccountAnalytics?: Maybe<DeleteCompareInstagramAccountAnalytics>;
  /**
   * Removes an Instagram hashtag.
   *         May be used by admin, staff, agency and advertiser users.
   */
  readonly deleteInstagramHashtagAnalytics?: Maybe<DeleteInstagramHashtagAnalytics>;
  /**  Deletes hashtag for UGC management */
  readonly deleteInstagramUGCManagementHashtag: DeleteInstagramUGCManagementHashtagOutput;
  readonly deleteInstagramUserHashtag: DeleteInstagramUserHashtagOutput;
  readonly deleteMarketplace?: Maybe<DeleteMarketplace>;
  readonly deleteOpenAiThread?: Maybe<DeleteOpenAIThread>;
  readonly deletePackage?: Maybe<DeletePackage>;
  readonly deleteSocialAccountPackage?: Maybe<DeleteSocialAccountPackage>;
  readonly deleteTalentAgency?: Maybe<DeleteTalentAgency>;
  readonly deleteTiktokSpecialCampaign?: Maybe<DeleteTikTokSpecialCampaign>;
  readonly deleteTiktokUserHashtag: DeleteTiktokUserHashtagOutput;
  readonly deleteTiktokUserKeyword: DeleteTiktokUserKeywordOutput;
  readonly deleteUser?: Maybe<DeleteUser>;
  /** Delete influencer on user proposal list, on Select Influencers Tab */
  readonly deleteUserProposalInfluencer?: Maybe<DeleteUserProposalInfluencer>;
  /** Delete SNS account of influencer on user proposal list, on Select Influencers Tab */
  readonly deleteUserProposalSocialAccount?: Maybe<DeleteUserProposalSocialAccount>;
  /** Delete individual SNS account of influencer on user proposal list, on Propose Influencers Tab */
  readonly deleteUserProposalSocialAccountIndividually?: Maybe<DeleteUserProposalSocialAccountIndividually>;
  /**
   *  Deletes a Youtube account from a 'compare' list
   *  May be used by admin, staff, agency and advertiser users.
   */
  readonly deleteYoutubeCompareAccountAnalytics: DeleteYoutubeCompareAccountAnalyticsOutput;
  readonly denyEngagementInfluencerProposalList?: Maybe<DenyEngagementInfluencerProposalList>;
  readonly denyInfluencersUserProposal?: Maybe<DenyInfluencersUserProposal>;
  readonly disconnectPackageFromEngagementProposal?: Maybe<DisconnectPackageFromEngagementProposal>;
  /**  Allow influencer to disconnect promotion methods */
  readonly disconnectPromotionMethods?: Maybe<DisconnectPromotionMethodsPayload>;
  readonly disconnectTiktokAdAccount?: Maybe<DisconnectTiktokAdAccount>;
  /**
   *  Edit an Analytics account with a connected Youtube or Instagram account.
   *  May be used by admin, staff and agency users.
   */
  readonly editSocialAccountAnalyticsAdminV2: EditSocialAccountAnalyticsAdminOutputV2;
  /**
   *  Edit an Analytics account with a connected Youtube or Instagram account.
   *  May be used by advertiser users only.
   */
  readonly editSocialAccountAnalyticsAdvertiserV2: EditSocialAccountAnalyticsAdvertiserOutputV2;
  /** Adds all influencers from one campaign to another */
  readonly engagementAddInfluencersToProposalFromAnotherCampaign?: Maybe<EngagementAddInfluencersToProposalFromAnotherCampaign>;
  /**  Public API to save Engagement Coupon Tracking */
  readonly engagementCampaignCouponTracking?: Maybe<EngagementCampaignCouponTrackingPayload>;
  readonly exportCmsInvoiceToSpreadsheet?: Maybe<ExportCmsInvoiceToSpreadsheet>;
  /** Creates a presentation with the dashboard data and sends it to the user's email address as a file */
  readonly exportInstagramAccountDashboardAnalytics?: Maybe<ExportDashboardInstagramAccountAnalytics>;
  readonly exportInstagramHashtagPostsToGoogleSpreadsheet: ExportInstagramHashtagPostsToGoogleSpreadsheetOutput;
  /**
   *  Get the exported google slide/pptx and send to email.
   *  May be used by admin, staff, advertiser, agency, talent agency and partner users
   */
  readonly exportYoutubeDashboardAccountAnalytics: ExportYoutubeDashboardAccountAnalyticsOutput;
  /**
   *  Get the exported google slide/pptx and send to email for an influencer.
   *  May be used by admin, staff, partner, and talent agency users
   */
  readonly exportYoutubeDashboardAccountAnalyticsForInfluencer: ExportYoutubeDashboardAccountAnalyticsOutput;
  /**
   *  Creates a facebook page analutics account.
   *  May be used by external users only.
   */
  readonly externalCreateFacebookPageAccountAnalytics: ExternalCreateFacebookPageAccountAnalyticsOutput;
  /**
   *  Creates an instagram analytics account.
   *  The service is idempotent. You may create the same account multiple times. The returned id is the same in this situation.
   *  May be used by external users only.
   */
  readonly externalCreateInstagramAccountAnalytics: ExternalCreateInstagramAccountAnalyticsOutput;
  /**
   *  Creates an youtube analytics account.
   *  The service is idempotent. You may create the same account multiple times. The returned id is the same in this situation.
   *  May be used by external users only.
   */
  readonly externalCreateYoutubeAccountAnalytics: ExternalCreateYoutubeAccountAnalyticsOutput;
  /**
   *  Returns a list of Facebook page accounts that can be connected based on your Facebook account.
   *  May be used by external users only.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  readonly externalSocialAuthConnectableFacebookPageAnalytics?: Maybe<ExternalConnectableFacebookPageAnalyticsPayload>;
  /**
   *  Returns a list of Instagram accounts that can be connected based on your Facebook account.
   *  May be used by external users only.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  readonly externalSocialAuthConnectableInstagramAccountsAnalytics?: Maybe<ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload>;
  /**
   *  Reconnects a Facebook Page analytics account.
   *  May be used by external users only.
   */
  readonly externalSocialAuthReconnectFacebookPageAccountAnalytics?: Maybe<ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload>;
  /**
   *  Reconnects an Instagram analytics account.
   *  May be used by external users only.
   */
  readonly externalUserSocialAuthReconnectInstagramAccountAnalytics?: Maybe<ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload>;
  readonly fetchAutoManagedCustomShopeeCampaigns?: Maybe<ShopeeCustomCampaignPayload>;
  readonly generateMarketplaceCampaignDetail?: Maybe<GenerateMarketplaceCampaignDetailPayload>;
  readonly generateMarketplacePostCaption?: Maybe<AcceptedPayload>;
  /** Generates Partner Influencer sign-up url */
  readonly generatePartnerInfluencerSignUpUrl?: Maybe<GeneratePartnerInfluencerSignUpUrl>;
  /** Generates Talent Influencer sign-up url */
  readonly generateTalentInfluencerSignUpUrl?: Maybe<GenerateTalentInfluencerSignUpUrl>;
  readonly generateTikTokSpecialPostCaption?: Maybe<AcceptedPayload>;
  readonly importFans?: Maybe<ImportFansPayload>;
  /**  Adds a Youtube account to a 'compare' list. For influencers only. */
  readonly influencerAddYoutubeCompareAccountAnalytics: AddYoutubeCompareAccountAnalyticsOutput;
  /**
   *  Updates twitter account data
   *  May be used by admin, staff, agency, advertisers, and influencers
   */
  readonly influencerAnalyticsTwitterRefresh: InfluencerAnalyticsTwitterRefreshOutput;
  /**  Deletes a Youtube account from a 'compare' list. For influencers only. */
  readonly influencerDeleteYoutubeCompareAccountAnalytics: DeleteYoutubeCompareAccountAnalyticsOutput;
  /**
   * Creates a presentation with the dashboard data and
   *             sends it to the user's email address as a file. For influencers only.
   */
  readonly influencerExportInstagramAccountDashboard?: Maybe<InfluencerExportDashboardInstagramAccount>;
  /**  Allow influencer to sign-up by email */
  readonly influencerSignUp?: Maybe<InfluencerSignUpInputPayload>;
  /**
   *  Reconnects an Instagram analytics account.
   *  May be used for influencers only.
   */
  readonly influencerSocialAuthReconnectInstagramAccountAnalytics?: Maybe<InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload>;
  readonly joinAutoManagedCampaign?: Maybe<JoinAutoManagedCampaignPayload>;
  readonly joinMarketplaceByStaff?: Maybe<JoinMarketplaceByStaff>;
  readonly joinMarketplaceV2?: Maybe<JoinMarketplaceV2>;
  readonly joinTiktokSpecialCampaign?: Maybe<JoinTiktokSpecialCampaign>;
  readonly linkCmsChannelAssets?: Maybe<LinkCmsChannelAssets>;
  /** Influencer made a post */
  readonly madeMarketplaceCampaignPost?: Maybe<MadeMarketplaceCampaignPost>;
  /**
   *  `markReadNotification` mutation marks all notification of a influencer user as read.
   *  The user is known through the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  readonly markReadNotification: MarkReadNotificationPayload;
  /**
   *  `markReadNotificationByApp` mutation marks all specific app notification of a influencer user as read.
   *  The user is known through the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  readonly markReadNotificationByApp: MarkReadNotificationPayload;
  /**  Public API to save Marketplace Coupon Tracking */
  readonly marketplaceCampaignCouponTracking?: Maybe<MarketplaceCampaignCouponTrackingPayload>;
  readonly publishInfluencerBio?: Maybe<PublishInfluencerBioPayload>;
  readonly reSendBcaRequest?: Maybe<ReSendBcaRequestPayload>;
  readonly rechargeAdvertiserAccount?: Maybe<RechargeAdvertiserAccount>;
  readonly reconnectTiktokAdAccount?: Maybe<ReconnectTiktokAdAccount>;
  readonly refreshPassword?: Maybe<RefreshPasswordPayload>;
  readonly refreshProfileAccount?: Maybe<RefreshProfileAccountPayload>;
  readonly refreshTwitterAccount?: Maybe<RefreshTwitterAccountPayload>;
  readonly registerPushToken?: Maybe<RegisterPushToken>;
  /** Reject engagement draft post (it can be used by admin, staff, agency and advertiser) */
  readonly rejectEngagementDraftPost?: Maybe<RejectEngagementDraftPost>;
  /** Reject Marketplace campaign that have been created by Advertiser */
  readonly rejectMarketplaceCampaign?: Maybe<RejectMarketplaceCampaign>;
  /** reject a draft post */
  readonly rejectMarketplaceDraftPost?: Maybe<RejectMarketplaceDraftPost>;
  /** Reject TikTokSpecial campaign that have been created by Advertiser */
  readonly rejectTiktokSpecialCampaign?: Maybe<RejectTikTokSpecialCampaign>;
  readonly removeAllFormResponses?: Maybe<RemoveAllFormResponsesPayload>;
  readonly removeAllSelectedInfluencers?: Maybe<RemoveAllSelectedInfluencers>;
  readonly removeCreatorStaff?: Maybe<RemoveCreatorStaffPayload>;
  readonly removeEmails?: Maybe<RemoveEmailsPayload>;
  readonly removeFans?: Maybe<RemoveFansPayload>;
  readonly removeFormResponse?: Maybe<RemoveFormResponsePayload>;
  readonly removeForms?: Maybe<RemoveFormsPayload>;
  readonly removeSelectedInfluencers?: Maybe<RemoveSelectedInfluencers>;
  readonly removeShopifyAccount?: Maybe<RemoveShopifyAccountPayload>;
  readonly reorderMarketplaceCampaigns?: Maybe<ReorderMarketplaceCampaigns>;
  readonly reportEngagementPostStatus?: Maybe<ReportEngagementPostStatus>;
  readonly reportMarketplacePostStatus?: Maybe<ReportMarketplacePostStatus>;
  readonly reportTiktokSpecialPostStatus?: Maybe<ReportTiktokSpecialPostStatus>;
  readonly requestDeleteAccount?: Maybe<RequestDeleteAccount>;
  readonly requestGoogleSlideCustomisedReport?: Maybe<RequestGoogleSlideCustomisedReport>;
  readonly requestGoogleSlideReport?: Maybe<RequestGoogleSlideReport>;
  readonly requestInfluencerProfilePptx?: Maybe<RequestInfluencerProfilePptx>;
  readonly requestInfluencersProfilePptx?: Maybe<RequestInfluencersProfilePptx>;
  readonly requestInfluencersSocialAccountPptx?: Maybe<RequestInfluencersSocialAccountPptx>;
  /**  Request a UGC post's via comment */
  readonly requestInstagramUGCManagementPost: RequestInstagramUGCManagementPostOutput;
  readonly requestPackageProposalExcel?: Maybe<RequestPackageProposalExcel>;
  readonly requestPackageProposalPptx?: Maybe<RequestPackageProposalPptx>;
  readonly requestPackageSpreadsheet?: Maybe<RequestPackageSpreadsheet>;
  readonly requestPowerpointCustomisedReport?: Maybe<RequestPowerpointCustomisedReport>;
  readonly requestPowerpointReport?: Maybe<RequestPowerpointReport>;
  readonly requestProposalExcel?: Maybe<RequestProposalExcel>;
  readonly requestProposalPptx?: Maybe<RequestProposalPptx>;
  readonly requestProposalSpreadsheet?: Maybe<RequestProposalSpreadsheet>;
  readonly requestUserProposalExcel?: Maybe<RequestUserProposalExcel>;
  readonly requestUserProposalPptx?: Maybe<RequestUserProposalPptx>;
  /** Re-send an email for self-registered Advertiser */
  readonly resendEmailForAdvertiser?: Maybe<ResendEmailForAdvertiser>;
  /**  Resend forgot password's email to influencer which contains generated hash url to let influencer to set new password */
  readonly resendInfluencerForgotPasswordEmail?: Maybe<ResendInfluencerForgotPasswordEmailPayload>;
  /**  Resend sign-up's email to influencer which contains generated hash url to let influencer to set new password */
  readonly resendInfluencerSignUpEmail?: Maybe<ResendInfluencerSignUpEmailPayload>;
  /** temporary internal api */
  readonly resendPartnerEmails?: Maybe<ResendPartnerEmails>;
  readonly resetPassword?: Maybe<ResetPasswordPayload>;
  readonly resetPasswordAndLogin?: Maybe<ResetPasswordAndLoginPayload>;
  readonly resolveChat?: Maybe<ResolveChatPayload>;
  readonly sendBcaRequest?: Maybe<SendBcaRequestPayload>;
  readonly sendContact?: Maybe<SendContact>;
  readonly sendGeminiMessage?: Maybe<SendGeminiMessage>;
  readonly sendLineBroadcastMessage?: Maybe<SendLineBroadcastMessagePayload>;
  readonly sendMessage?: Maybe<SendMessagePayload>;
  readonly sendOpenAiMessage?: Maybe<SendOpenAIMessage>;
  readonly shopifyValidateAuth?: Maybe<ShopifyValidateAuthPayload>;
  /**
   *  Returns a list of Facebook page accounts that can be connected based on your Facebook account.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  readonly socialAuthConnectableFacebookPageAnalytics?: Maybe<SocialAuthConnectableFacebookPageAnalyticsPayload>;
  /**
   *  Returns a list of Instagram accounts that can be connected based on your Facebook account.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  readonly socialAuthConnectableInstagramAccountsAnalytics?: Maybe<SocialAuthConnectableInstagramAccountsAnalyticsPayload>;
  /**
   *  Returns a twitter account that can be connected to analytics account.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  readonly socialAuthConnectableTwitterAccountAnalyticsV2: SocialAuthConnectableTwitterAccountAnalyticsOutput;
  /**
   *  Get selectable youtube analytics account channels and save tokens in DB.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  readonly socialAuthConnectableYouTubeChannelsAnalyticsV2: ReadonlyArray<SelectableYoutubeAnalyticsChannel>;
  readonly socialAuthForJoinCampaign?: Maybe<SocialAuthForJoinCampaignPayload>;
  readonly socialAuthReConnectInstagram?: Maybe<SocialAuthReConnectInstagramPayload>;
  /**
   *  Reconnects a Facebook Page analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token invalid.
   */
  readonly socialAuthReconnectFacebookPageAccountAnalytics?: Maybe<SocialAuthReconnectFacebookPageAccountAnalyticsPayload>;
  /**
   *  Reconnects an Instagram analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token is not valid no more.
   */
  readonly socialAuthReconnectInstagramAccountAnalytics?: Maybe<SocialAuthReconnectInstagramAccountAnalyticsPayload>;
  /**
   *  Reconnects a Twitter analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token invalid.
   */
  readonly socialAuthReconnectTwitterAccountAnalyticsV2?: Maybe<SocialAuthReconnectTwitterAccountAnalyticsPayload>;
  /**
   *  Reconnects a YouTube analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token invalid.
   */
  readonly socialAuthReconnectYouTubeAccountAnalyticsV2?: Maybe<SocialAuthReconnectYouTubeAccountAnalyticsPayload>;
  /**
   *  Make Facebook OAuth when signing-in and returns Facebook UserId and account name
   *  Used by only Talent Agency user
   */
  readonly socialAuthTalentSignInFacebook?: Maybe<SocialAuthTalentSignInFacebookPayload>;
  /**
   *  Make Facebook OAuth when signing-in and returns a list of Page & Instagram account pairs and your Facebook account.
   *  Used by only Talent Agency user
   */
  readonly socialAuthTalentSignInInstagram?: Maybe<SocialAuthTalentSignInInstagramPayload>;
  /**
   *  Make Twitter OAuth when signing-in and returns Twitter user id  and account name
   *  Used by only Talent Agency user
   */
  readonly socialAuthTalentSignInTwitter?: Maybe<SocialAuthTalentSignInTwitterPayload>;
  /**
   *  Make YouTube OAuth when signing-in and returns a list of YouTube Channels.
   *  Used by only Talent Agency user
   */
  readonly socialAuthTalentSignInYouTube?: Maybe<SocialAuthTalentSignInYouTubePayload>;
  readonly submitForm?: Maybe<SubmitFormPayload>;
  readonly submitFormInternal?: Maybe<SubmitFormInternalPayload>;
  readonly subscribeAnalyticsPlan?: Maybe<SubscribeAnalyticsPlan>;
  readonly subscribeAnalyticsTrialPlan?: Maybe<SubscribeAnalyticsTrialPlan>;
  readonly sudoLimitedLogin?: Maybe<SudoLimitedLogin>;
  /** Allow creator staff to change account */
  readonly switchInfluencerForStaff?: Maybe<SwitchInfluencerForStaffPayload>;
  readonly switchRoleToAdmin?: Maybe<SwitchRoleToAdmin>;
  readonly switchRoleToAdvertiser?: Maybe<SwitchRoleToAdvertiser>;
  readonly switchRoleToAgency?: Maybe<SwitchRoleToAgency>;
  readonly switchRoleToPartner?: Maybe<SwitchRoleToPartner>;
  readonly switchRoleToStaff?: Maybe<SwitchRoleToStaff>;
  readonly switchRoleToTalentAgency?: Maybe<SwitchRoleToTalentAgency>;
  readonly tokenAuth?: Maybe<ObtainJSONWebTokenPayload>;
  /** for internal use */
  readonly tokenAuthLongLive?: Maybe<TokenAuthLongLive>;
  readonly trackYoutubeMusicDownload?: Maybe<TrackYoutubeMusicDownload>;
  readonly transferMoneyToAdAccount?: Maybe<TransferMoneyToAdAccount>;
  /**
   *
   *             Unapprove Applicant influencers to Selection Marketplace campaign
   *
   */
  readonly unApproveApplicantInfluencers?: Maybe<UnApproveApplicantInfluencers>;
  readonly unassignChatPic?: Maybe<UnassignChatPICPayload>;
  readonly unblockInfluencerMarketplace?: Maybe<UnblockInfluencerMarketplace>;
  readonly unresolveChat?: Maybe<UnresolveChatPayload>;
  /**  Update crm account information */
  readonly updateAccountInformation?: Maybe<UpdateAccountInformationPayload>;
  readonly updateAddress?: Maybe<UpdateAddress>;
  /**  Update crm address information */
  readonly updateAddressInformation?: Maybe<UpdateAddressInformationPayload>;
  readonly updateAdminUser?: Maybe<UpdateAdmin>;
  readonly updateAdvertiser?: Maybe<UpdateAdvertiser>;
  readonly updateAdvertiserByAdvertiser?: Maybe<UpdateAdvertiser>;
  /** Update Advertiser User */
  readonly updateAdvertiserUser?: Maybe<UpdateAdvertiserUser>;
  readonly updateAdvertiserUserSettings?: Maybe<UpdateAdvertiserUserSettings>;
  readonly updateAgency?: Maybe<UpdatePlainAgency>;
  /** Update Ad Agency User */
  readonly updateAgencyUser?: Maybe<UpdateAgency>;
  readonly updateAutoManagedAffiliateCommissionRates?: Maybe<UpdateAutoManagedAffiliateCommissionRates>;
  readonly updateAutoManagedCampaign?: Maybe<UpdateAutoManagedCampaignPayload>;
  /**  Update auto-managed campaign */
  readonly updateAutoManagedCampaignsStatus?: Maybe<UpdateAutoManagedCampaignsStatusPayload>;
  readonly updateBioSections?: Maybe<UpdateBioSectionsPayload>;
  readonly updateCmsAssetShareRate?: Maybe<UpdateCmsAssetShareRate>;
  readonly updateCmsChannelShareRate?: Maybe<UpdateCmsChannelShareRate>;
  readonly updateComment?: Maybe<UpdateCommentPayload>;
  readonly updateEmailTemplate?: Maybe<UpdateEmailTemplatePayload>;
  readonly updateEngagement?: Maybe<UpdateEngagement>;
  /** Change budget mode for engagement campaign */
  readonly updateEngagementBreakdownMode?: Maybe<UpdateEngagementBreakDownMode>;
  readonly updateEngagementCouponList?: Maybe<UpdateEngagementCouponList>;
  /**
   *
   *         Join Influencers and their social accounts to Engagement Proposal
   *
   */
  readonly updateEngagementInfluencerProposalList?: Maybe<UpdateEngagementInfluencerProposalList>;
  readonly updateEngagementInfluencersBudget?: Maybe<UpdateEngagementInfluencersBudget>;
  readonly updateEngagementPostForInfluencerV2?: Maybe<UpdateEngagementPostForInfluencerV2>;
  readonly updateEngagementPostStatus?: Maybe<UpdateEngagementPostStatus>;
  readonly updateEngagementPostV2?: Maybe<UpdateEngagementPostV2>;
  /**
   *
   *         Join a specific social account and its own influencer to Engagement Proposal
   *
   */
  readonly updateEngagementSocialAccountProposalList?: Maybe<UpdateEngagementSocialAccountProposalList>;
  readonly updateEngagementSocialAccountsBudget?: Maybe<UpdateEngagementSocialAccountsBudget>;
  readonly updateEngagementStatus?: Maybe<UpdateEngagementStatus>;
  readonly updateFan?: Maybe<UpdateFanPayload>;
  readonly updateForm?: Maybe<UpdateFormPayload>;
  readonly updateFormStatus?: Maybe<UpdateFormStatusPayload>;
  readonly updateInfluencerBio?: Maybe<UpdateInfluencerBioPayload>;
  readonly updateInfluencerPhoneNumber?: Maybe<UpdateInfluencerPhoneNumberPayload>;
  readonly updateInfluencerProfile?: Maybe<UpdateInfluencerProfile>;
  /**  Update the influencer rate card for specific social account type */
  readonly updateInfluencerRateCard?: Maybe<UpdateInfluencerRateCardPayload>;
  /**  Update the influencer rate cards for specific social account type */
  readonly updateInfluencerRateCards?: Maybe<UpdateInfluencerRateCardsPayload>;
  readonly updateInfluencerRateCardsByStaff?: Maybe<UpdateInfluencerRateCardsByStaff>;
  readonly updateInfluencerTagsByStaff?: Maybe<UpdateInfluencerTagsByStaff>;
  readonly updateInfluencerV2?: Maybe<UpdateInfluencerV2>;
  readonly updateInternalMemo?: Maybe<UpdateInternalMemo>;
  readonly updateLineBroadcastMessage?: Maybe<UpdateLineBroadcastMessagePayload>;
  /**  update LinkBio Theme and Colors */
  readonly updateLinkBioThemeColor?: Maybe<UpdateLinkBioThemeColorPayload>;
  readonly updateMarketplace?: Maybe<UpdateMarketplace>;
  readonly updateMarketplaceAffiliateCommissionFees?: Maybe<UpdateMarketplaceAffiliateCommissionFees>;
  readonly updateMarketplaceAffiliateCommissionRates?: Maybe<UpdateMarketplaceAffiliateCommissionRates>;
  readonly updateMarketplaceCouponList?: Maybe<UpdateMarketplaceCouponList>;
  readonly updateMarketplaceInfluencerCost?: Maybe<UpdateMarketplaceInfluencerCost>;
  /**
   *
   *         Update Marketplace post status v2
   *
   */
  readonly updateMarketplacePostStatusV2?: Maybe<UpdateMarketplacePostStatusV2>;
  /** Influencer update post tracking url */
  readonly updateMarketplacePostTrackingUrl?: Maybe<UpdateMarketplacePostTrackingUrl>;
  readonly updatePackage?: Maybe<UpdatePackage>;
  readonly updatePackageProposalInfluencersBudget?: Maybe<UpdatePackageProposalInfluencersBudget>;
  readonly updatePackageProposalMode?: Maybe<UpdatePackageProposalMode>;
  readonly updatePackageProposalSocialAccountsBudget?: Maybe<UpdatePackageProposalSocialAccountsBudget>;
  readonly updatePartnerAgency?: Maybe<UpdatePartnerAgency>;
  /** Update Partner User */
  readonly updatePartnerUser?: Maybe<UpdatePartnerUser>;
  readonly updatePasswordByAdvertiserUser?: Maybe<UpdatePasswordByAdvertiserUser>;
  readonly updatePaymentInformation?: Maybe<UpdatePaymentInformation>;
  readonly updatePaymentStatus?: Maybe<UpdatePaymentStatus>;
  /**  Update crm personal user detail */
  readonly updatePersonalUserDetail?: Maybe<UpdatePersoanlUserDetailPayload>;
  /**  Update all signed-up/ scraping social accounts */
  readonly updateSocialAccounts?: Maybe<UpdateSocialAccountsPayload>;
  readonly updateSocialUsername?: Maybe<UpdateSocialUsername>;
  readonly updateStaffUser?: Maybe<UpdateStaff>;
  readonly updateSubscriptionPlan?: Maybe<UpdateSubscriptionPlan>;
  readonly updateTalentAgency?: Maybe<UpdateTalentAgency>;
  /** Update Talent Agency User */
  readonly updateTalentAgencyUser?: Maybe<UpdateTalentAgencyUser>;
  /**  Update Talent Influencer by Talent agency user with multiple social accounts (Facebook, Instagram, Youtube, and Twitter) */
  readonly updateTalentInfluencer?: Maybe<UpdateTalentInfluencerPayload>;
  readonly updateTiktokAdvertiserInfo?: Maybe<UpdateTiktokAdvertiserInfo>;
  readonly updateTiktokSpecialCampaign?: Maybe<UpdateTikTokSpecialCampaign>;
  readonly updateTiktokSpecialPostStatus?: Maybe<UpdateTiktokSpecialPostStatus>;
  readonly updateTtcmPost?: Maybe<UpdateTtcmPost>;
  /**  Update crm user detail */
  readonly updateUserDetail?: Maybe<UpdateUserDetailPayload>;
  /** Change budget mode for engagement campaign */
  readonly updateUserProposalBreakdownMode?: Maybe<UpdateUserProposalBreakdownMode>;
  /** update budget of individual influencer account, on Propose Influencers Tab */
  readonly updateUserProposalInfluencersBudget?: Maybe<UpdateUserProposalInfluencersBudget>;
  /** update budget of individual SNS account, on Propose Influencers Tab */
  readonly updateUserProposalSocialAccountsBudget?: Maybe<UpdateUserProposalSocialAccountsBudget>;
  /**  Update the credentials(tokens, owner id) for Youtube MCN influencers */
  readonly updateYoutubeMCNInfluencerCredentials?: Maybe<UpdateYoutubeMCNCredentialsPayload>;
  /**
   *
   *             Upload csv report file for engagement campaign
   *
   */
  readonly uploadEngagementReportFile?: Maybe<UploadEngagementReportFile>;
  /**
   *
   *                Upload report of engagement campaign from Insight screenshot check
   *
   */
  readonly uploadEngagementReportFromInsightCheck?: Maybe<UploadEngagementReportFromInsightCheck>;
  readonly uploadEngagementXhsInsightCheck?: Maybe<UploadEngagementXhsInsightCheck>;
  readonly uploadOpenAiImages?: Maybe<UploadOpenAIImages>;
  /**  Signs-up UUUM influencers using youtube */
  readonly uuumSocialAuthSignUp?: Maybe<UUUMSocialAuthSignUpPayload>;
  readonly verifyLineIDToken?: Maybe<VerifyLineIDTokenPayload>;
  readonly verifyToken?: Maybe<VerifyPayload>;
  readonly withdrawMoneyFromAdAccount?: Maybe<WithdrawMoneyFromAdAccount>;
};

export type MutationaddAllSelectedInfluencersArgs = {
  input: AddAllSelectedInfluencersInput;
};

export type MutationaddCommentArgs = {
  input: AddCommentInput;
};

export type MutationaddEmailTemplateArgs = {
  input: AddEmailTemplateInput;
};

export type MutationaddFanArgs = {
  input: AddFanInput;
};

export type MutationaddFanTagsArgs = {
  input: AddFanTagsInput;
};

export type MutationaddFormArgs = {
  input: AddFormInput;
};

export type MutationaddInfluencerToUserProposalArgs = {
  input: AddInfluencerToUserProposalInput;
};

export type MutationaddInstagramCompareAccountAnalyticsArgs = {
  input: AddInstagramCompareAccountAnalyticsInput;
};

export type MutationaddInstagramHashtagAnalyticsArgs = {
  input: AddInstagramHashtagAnalyticsInput;
};

export type MutationaddMarketplacePostUrlArgs = {
  input: AddMarketplacePostUrlInput;
};

export type MutationaddMarketplacePostUrlForInfluencerArgs = {
  input: AddMarketplacePostUrlInputForInfluencer;
};

export type MutationaddReadTutorialArgs = {
  input: AddReadTutorialInput;
};

export type MutationaddSelectedInfluencersArgs = {
  input: AddSelectedInfluencersInput;
};

export type MutationaddSocialAccountToUserProposalArgs = {
  input: AddSocialAccountToUserProposalInput;
};

export type MutationaddStaffAdvertisersToAnotherStaffArgs = {
  input: AddStaffAdvertisersToAnotherStaffInput;
};

export type MutationaddYoutubeCompareAccountAnalyticsArgs = {
  input: AddYoutubeCompareAccountAnalyticsInput;
};

export type MutationallowEngagementInfluencerProposalListArgs = {
  input: AllowEngagementInfluencerProposalListInput;
};

export type MutationallowInfluencersUserProposalArgs = {
  input: AllowInfluencersUserProposalInput;
};

export type MutationanalyticsSelectSubscriptionPlanArgs = {
  input: AnalyticsSelectSubscriptionPlanInput;
};

export type MutationanyXCheckEmailArgs = {
  input: AnyXCheckEmailInput;
};

export type MutationanyXSocialAuthConnectArgs = {
  input: AnyXSocialAuthConnectInput;
};

export type MutationanyXSocialAuthConnectForLinkBioArgs = {
  input: AnyXSocialAuthConnectForLinkBioInput;
};

export type MutationanyXSocialAuthDisconnectArgs = {
  input: AnyXSocialAuthDisconnectInput;
};

export type MutationanyXSocialAuthReconnectForLinkBioArgs = {
  input: AnyXSocialAuthReconnectForLinkBioInput;
};

export type MutationanyXSocialAuthReconnectTikTokArgs = {
  input: AnyXSocialAuthReconnectTikTokInput;
};

export type MutationanyXSocialAuthReconnectTtcmArgs = {
  input: AnyXSocialAuthReconnectTTCMInput;
};

export type MutationanyXSocialAuthSignInArgs = {
  input: AnyXSocialAuthSignInInput;
};

export type MutationanyXSocialAuthSignInInstagramLineArgs = {
  input: AnyXSocialAuthSignInInstagramLineInput;
};

export type MutationanyXSocialAuthSignInOrSignUpArgs = {
  input: AnyXSocialAuthSignInOrSignUpInput;
};

export type MutationanyXSocialAuthSignUpArgs = {
  input: AnyXSocialAuthSignUpInput;
};

export type MutationanyXSocialAuthSignUpPartnerArgs = {
  input: AnyXSocialAuthSignUpPartnerInput;
};

export type MutationanyXSocialAuthSignUpTalentArgs = {
  input: AnyXSocialAuthSignUpTalentInput;
};

export type MutationapproveApplicantInfluencersArgs = {
  input: ApproveApplicantInfluencersInput;
};

export type MutationapproveAutoManagedPayoutArgs = {
  input: ApproveAutoManagedPayoutInput;
};

export type MutationapproveCmsInvoiceArgs = {
  input: ApproveCmsInvoiceInput;
};

export type MutationapproveMarketplaceArgs = {
  input: ApproveMarketplaceInput;
};

export type MutationapproveMarketplaceAffiliatePayoutArgs = {
  input: ApproveMarketplaceAffiliatePayoutInput;
};

export type MutationapproveMarketplaceDraftPostArgs = {
  input: ApproveMarketplaceDraftPostInput;
};

export type MutationapproveTiktokSpecialCampaignArgs = {
  input: ApproveTikTokSpecialCampaignInput;
};

export type MutationassignAdvertiserToStaffsArgs = {
  input: AssignAdvertiserForStaffsInput;
};

export type MutationassignChatPicArgs = {
  input: AssignChatPICInput;
};

export type Mutationauth0ToLocalJwtArgs = {
  input: Auth0ToLocalJWTInput;
};

export type MutationauthTiktokBusinessAccountArgs = {
  input: AuthTiktokBusinessAccountInput;
};

export type MutationboostTiktokAdPostArgs = {
  input: BoostTikTokAdPostInput;
};

export type MutationbulkUpdateEngagementInfluencersBudgetArgs = {
  input: BulkUpdateEngagementInfluencersBudgetInput;
};

export type MutationbulkUpdateEngagementSocialAccountsBudgetArgs = {
  input: BulkUpdateEngagementSocialAccountsBudgetInput;
};

export type MutationbulkUpdatePackageProposalAccountsBudgetArgs = {
  input: BulkUpdatePackageProposalAccountsBudgetInput;
};

export type MutationbulkUpdatePackageProposalInfluencersBudgetArgs = {
  input: BulkUpdatePackageProposalInfluencersBudgetInput;
};

export type MutationbulkUpdateUserProposalAccountsBudgetArgs = {
  input: BulkUpdateUserProposalAccountsBudgetInput;
};

export type MutationbulkUpdateUserProposalInfluencersBudgetArgs = {
  input: BulkUpdateUserProposalInfluencersBudgetInput;
};

export type MutationchangeInstagramUGCManagementPostStatusArgs = {
  input: ChangeInstagramUGCManagementPostStatusInput;
};

export type MutationclearCmsChannelAssetOwnerArgs = {
  input: ClearCmsChannelAssetOwnerInput;
};

export type MutationclickNotificationArgs = {
  input?: InputMaybe<ClickNotificationInput>;
};

export type MutationclickNotificationByEventIdArgs = {
  input?: InputMaybe<ClickNotificationByEventIdInput>;
};

export type MutationcloneMarketplaceArgs = {
  input: CloneMarketplaceInput;
};

export type MutationconnectFacebookPagesAndInstagramAccountsArgs = {
  input?: InputMaybe<ConnectFacebookPagesAndInstagramAccountsInput>;
};

export type MutationconnectNewShopifyAccountArgs = {
  input: ShopifyValidateAuthInput;
};

export type MutationconnectPackageToEngagementProposalArgs = {
  input: ConnectPackageToEngagementProposalInput;
};

export type MutationconnectPackageWithInfluencerAccountsArgs = {
  input: ConnectPackageWithInfluencerAccountsInput;
};

export type MutationconnectPackageWithSocialAccountsArgs = {
  input: ConnectPackageWithSocialAccountsInput;
};

export type MutationconnectPackageWithUserProposalArgs = {
  input: ConnectPackageWithUserProposalInput;
};

export type MutationconnectPromotionMethodsArgs = {
  input: ConnectPromotionMethodsInput;
};

export type MutationcreateAdminUserArgs = {
  input: CreateAdminInput;
};

export type MutationcreateAdvertiserArgs = {
  input: CreateAdvertiserInput;
};

export type MutationcreateAdvertiserUserArgs = {
  input: CreateAdvertiserUserInput;
};

export type MutationcreateAdvertiserWithUserArgs = {
  input: CreateAdvertiserWithUserInput;
};

export type MutationcreateAdvertiserWithUserV2Args = {
  input: CreateAdvertiserWithUserInputV2;
};

export type MutationcreateAgencyUserArgs = {
  input: CreateAgencyInput;
};

export type MutationcreateAgencyWithUserArgs = {
  input: CreateAgencyWithUserInput;
};

export type MutationcreateCreatorStaffArgs = {
  input: CreateCreatorStaffInput;
};

export type MutationcreateDraftBoostPostArgs = {
  input: CreateDraftBoostPostInput;
};

export type MutationcreateEngagementArgs = {
  input: CreateEngagementInput;
};

export type MutationcreateEngagementPostForInfluencerV2Args = {
  input: CreateEngagementPostInputForInfluencerV2;
};

export type MutationcreateEngagementPostV2Args = {
  input: CreateEngagementPostInputV2;
};

export type MutationcreateInfluencerBioArgs = {
  input: CreateInfluencerBioInput;
};

export type MutationcreateInfluencerV2Args = {
  input: CreateInfluencerInputV2;
};

export type MutationcreateInstagramUGCManagementHashtagArgs = {
  input: CreateInstagramUGCManagementHashtagInput;
};

export type MutationcreateInstagramUserHashtagArgs = {
  input: CreateInstagramUserHashtagInput;
};

export type MutationcreateLineBroadcastMessageArgs = {
  input: CreateLineBroadcastMessageInput;
};

export type MutationcreateLineChannelArgs = {
  input: CreateLineChannelInput;
};

export type MutationcreateMarketplaceArgs = {
  input: CreateMarketplaceInput;
};

export type MutationcreateMarketplaceDraftPostArgs = {
  input: CreateMarketplaceDraftPostInput;
};

export type MutationcreateMobileAppVersionArgs = {
  input: CreateMobileAppVersionInput;
};

export type MutationcreatePackageArgs = {
  input: CreatePackageInput;
};

export type MutationcreatePackageWithInfluencerAccountsArgs = {
  input: CreatePackageWithInfluencerAccountsInput;
};

export type MutationcreatePackageWithSocialAccountsArgs = {
  input: CreatePackageWithSocialAccountsInput;
};

export type MutationcreatePackageWithUserProposalArgs = {
  input: CreatePackageWithUserProposalInput;
};

export type MutationcreatePartnerUserArgs = {
  input: CreatePartnerUserInput;
};

export type MutationcreateSelfRegisteredAdvertiserUserArgs = {
  input: CreateSelfRegisteredAdvertiserUserInput;
};

export type MutationcreateSocialAccountAnalyticsAdminV2Args = {
  input: CreateSocialAccountAnalyticsAdminInputV2;
};

export type MutationcreateSocialAccountAnalyticsAdvertiserV2Args = {
  input: CreateSocialAccountAnalyticsAdvertiserInputV2;
};

export type MutationcreateStaffUserArgs = {
  input: CreateStaffInput;
};

export type MutationcreateTalentAgencyUserArgs = {
  input: CreateTalentAgencyUserInput;
};

export type MutationcreateTalentAgencyWithUserArgs = {
  input: CreateTalentAgencyWithUserInput;
};

export type MutationcreateTalentInfluencerArgs = {
  input: CreateTalentInfluencerInput;
};

export type MutationcreateTiktokAdAccountArgs = {
  input: CreateTiktokAdAccountInput;
};

export type MutationcreateTiktokBusinessCenterArgs = {
  input: CreateTiktokBusinessCenterInput;
};

export type MutationcreateTiktokSpecialCampaignArgs = {
  input: CreateTikTokSpecialCampaignInput;
};

export type MutationcreateTiktokUserHashtagArgs = {
  input: CreateTiktokUserHashtagInput;
};

export type MutationcreateTiktokUserKeywordArgs = {
  input: CreateTiktokUserKeywordInput;
};

export type MutationdeleteAdvertiserArgs = {
  id: Scalars['Int'];
};

export type MutationdeleteAgencyArgs = {
  id: Scalars['Int'];
};

export type MutationdeleteBrandAccountAnalyticsArgs = {
  input: DeleteBrandAccountAnalyticsInput;
};

export type MutationdeleteCommentArgs = {
  input: DeleteCommentInput;
};

export type MutationdeleteEngagementArgs = {
  id: Scalars['Int'];
};

export type MutationdeleteEngagementInfluencerProposalArgs = {
  input: DeleteEngagementInfluencerProposalInput;
};

export type MutationdeleteEngagementPostForInfluencerArgs = {
  id: Scalars['Int'];
};

export type MutationdeleteEngagementProposalJoinedAccountArgs = {
  input: DeleteEngagementProposalJoinedAccountInput;
};

export type MutationdeleteEngagementSocialAccountProposalArgs = {
  input: DeleteEngagementSocialAccountProposalInput;
};

export type MutationdeleteFanTagsArgs = {
  input: DeleteFanTagsInput;
};

export type MutationdeleteGeminiThreadArgs = {
  input: DeleteGeminiThreadInput;
};

export type MutationdeleteInfluencerArgs = {
  id: Scalars['Int'];
};

export type MutationdeleteInfluencerPackageArgs = {
  influencerIds: ReadonlyArray<Scalars['Int']>;
  packageId: Scalars['Int'];
};

export type MutationdeleteInstagramCompareAccountAnalyticsArgs = {
  input: DeleteInstagramCompareAccountAnalyticsInput;
};

export type MutationdeleteInstagramHashtagAnalyticsArgs = {
  input: DeleteInstagramHashtagAnalyticsInput;
};

export type MutationdeleteInstagramUGCManagementHashtagArgs = {
  input: DeleteInstagramUGCManagementHashtagInput;
};

export type MutationdeleteInstagramUserHashtagArgs = {
  input: DeleteInstagramUserHashtagInput;
};

export type MutationdeleteMarketplaceArgs = {
  id: Scalars['Int'];
};

export type MutationdeleteOpenAiThreadArgs = {
  input: DeleteOpenAIThreadInput;
};

export type MutationdeletePackageArgs = {
  id: Scalars['Int'];
};

export type MutationdeleteSocialAccountPackageArgs = {
  packageId: Scalars['Int'];
  socialAccountIds: ReadonlyArray<Scalars['Int']>;
  socialMedia: SocialAccountType;
};

export type MutationdeleteTalentAgencyArgs = {
  id: Scalars['Int'];
};

export type MutationdeleteTiktokSpecialCampaignArgs = {
  input: DeleteTikTokSpecialCampaignInput;
};

export type MutationdeleteTiktokUserHashtagArgs = {
  input: DeleteTiktokUserHashtagInput;
};

export type MutationdeleteTiktokUserKeywordArgs = {
  input: DeleteTiktokUserKeywordInput;
};

export type MutationdeleteUserArgs = {
  id: Scalars['Int'];
};

export type MutationdeleteUserProposalInfluencerArgs = {
  input: DeleteUserProposalInfluencerInput;
};

export type MutationdeleteUserProposalSocialAccountArgs = {
  input: DeleteUserProposalSocialAccountInput;
};

export type MutationdeleteUserProposalSocialAccountIndividuallyArgs = {
  input: DeleteUserProposalSocialAccountIndividuallyInput;
};

export type MutationdeleteYoutubeCompareAccountAnalyticsArgs = {
  input: DeleteYoutubeCompareAccountAnalyticsInput;
};

export type MutationdenyEngagementInfluencerProposalListArgs = {
  input: DenyEngagementInfluencerProposalListInput;
};

export type MutationdenyInfluencersUserProposalArgs = {
  input: DenyInfluencersUserProposalInput;
};

export type MutationdisconnectPackageFromEngagementProposalArgs = {
  input: DisconnectPackageFromEngagementProposalInput;
};

export type MutationdisconnectPromotionMethodsArgs = {
  input: DisconnectPromotionMethodsInput;
};

export type MutationdisconnectTiktokAdAccountArgs = {
  input: DisconnectTiktokAdAccountInput;
};

export type MutationeditSocialAccountAnalyticsAdminV2Args = {
  input: EditSocialAccountAnalyticsAdminInputV2;
};

export type MutationeditSocialAccountAnalyticsAdvertiserV2Args = {
  input: EditSocialAccountAnalyticsAdvertiserInputV2;
};

export type MutationengagementAddInfluencersToProposalFromAnotherCampaignArgs = {
  input: EngagementAddInfluencersToProposalFromAnotherCampaignInput;
};

export type MutationengagementCampaignCouponTrackingArgs = {
  input: EngagementCampaignCouponTrackingInput;
};

export type MutationexportCmsInvoiceToSpreadsheetArgs = {
  input: ExportCmsInvoiceToSpreadsheetInput;
};

export type MutationexportInstagramAccountDashboardAnalyticsArgs = {
  input: ExportInstagramDashboardAccountAnalyticsInput;
};

export type MutationexportInstagramHashtagPostsToGoogleSpreadsheetArgs = {
  input: ExportInstagramHashtagPostsToGoogleSpreadsheetInput;
};

export type MutationexportYoutubeDashboardAccountAnalyticsArgs = {
  input: ExportYoutubeDashboardAccountAnalyticsInput;
};

export type MutationexportYoutubeDashboardAccountAnalyticsForInfluencerArgs = {
  input: InfluencerExportYoutubeDashboardAccountAnalyticsInput;
};

export type MutationexternalCreateFacebookPageAccountAnalyticsArgs = {
  input: ExternalCreateFacebookPageAccountAnalyticsInput;
};

export type MutationexternalCreateInstagramAccountAnalyticsArgs = {
  input: ExternalCreateInstagramAccountAnalyticsInput;
};

export type MutationexternalCreateYoutubeAccountAnalyticsArgs = {
  input: ExternalCreateYoutubeAccountAnalyticsInput;
};

export type MutationexternalSocialAuthConnectableFacebookPageAnalyticsArgs = {
  input: ExternalConnectableFacebookPageAnalyticsInput;
};

export type MutationexternalSocialAuthConnectableInstagramAccountsAnalyticsArgs = {
  input: ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput;
};

export type MutationexternalSocialAuthReconnectFacebookPageAccountAnalyticsArgs = {
  input: ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput;
};

export type MutationexternalUserSocialAuthReconnectInstagramAccountAnalyticsArgs = {
  input: ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput;
};

export type MutationfetchAutoManagedCustomShopeeCampaignsArgs = {
  input: ShopeeCustomCampaignInput;
};

export type MutationgenerateMarketplaceCampaignDetailArgs = {
  input: GenerateMarketplaceCampaignDetailInput;
};

export type MutationgenerateMarketplacePostCaptionArgs = {
  input: GenerateMarketplacePostCaptionInput;
};

export type MutationgenerateTikTokSpecialPostCaptionArgs = {
  input: GenerateTikTokSpecialPostCaptionInput;
};

export type MutationimportFansArgs = {
  input: ImportFansInput;
};

export type MutationinfluencerAddYoutubeCompareAccountAnalyticsArgs = {
  input: InfluencerAddYoutubeCompareAccountAnalyticsInput;
};

export type MutationinfluencerAnalyticsTwitterRefreshArgs = {
  input: InfluencerAnalyticsTwitterRefreshInput;
};

export type MutationinfluencerDeleteYoutubeCompareAccountAnalyticsArgs = {
  input: InfluencerDeleteYoutubeCompareAccountAnalyticsInput;
};

export type MutationinfluencerExportInstagramAccountDashboardArgs = {
  input: ExportInstagramDashboardAccountInfluencerInput;
};

export type MutationinfluencerSignUpArgs = {
  input: InfluencerSignUpInput;
};

export type MutationinfluencerSocialAuthReconnectInstagramAccountAnalyticsArgs = {
  input: InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput;
};

export type MutationjoinAutoManagedCampaignArgs = {
  input: JoinAutoManagedCampaignInput;
};

export type MutationjoinMarketplaceByStaffArgs = {
  input: JoinMarketplaceByStaffInput;
};

export type MutationjoinMarketplaceV2Args = {
  input: JoinMarketplaceV2Input;
};

export type MutationjoinTiktokSpecialCampaignArgs = {
  input: JoinTiktokSpecialCampaignInput;
};

export type MutationlinkCmsChannelAssetsArgs = {
  input: LinkCmsChannelAssetsInput;
};

export type MutationmadeMarketplaceCampaignPostArgs = {
  input: MadeMarketplaceCampaignPostInput;
};

export type MutationmarkReadNotificationByAppArgs = {
  input?: InputMaybe<MarkReadNotificationByAppInput>;
};

export type MutationmarketplaceCampaignCouponTrackingArgs = {
  input: MarketplaceCampaignCouponTrackingInput;
};

export type MutationpublishInfluencerBioArgs = {
  input: PublishInfluencerBioInput;
};

export type MutationreSendBcaRequestArgs = {
  input: ReSendBcaRequestInput;
};

export type MutationrechargeAdvertiserAccountArgs = {
  input: RechargeAdvertiserAccountInput;
};

export type MutationreconnectTiktokAdAccountArgs = {
  input: ReconnectTiktokAdAccountInput;
};

export type MutationrefreshPasswordArgs = {
  input: RefreshPasswordInput;
};

export type MutationrefreshProfileAccountArgs = {
  input: RefreshProfileAccountInput;
};

export type MutationrefreshTwitterAccountArgs = {
  input: RefreshTwitterAccountInput;
};

export type MutationregisterPushTokenArgs = {
  input: RegisterPushTokenInput;
};

export type MutationrejectEngagementDraftPostArgs = {
  input: RejectEngagementDraftPostInput;
};

export type MutationrejectMarketplaceCampaignArgs = {
  input: RejectMarketplaceCampaignInput;
};

export type MutationrejectMarketplaceDraftPostArgs = {
  input: RejectMarketplaceDraftPostInput;
};

export type MutationrejectTiktokSpecialCampaignArgs = {
  input: RejectTikTokSpecialCampaignInput;
};

export type MutationremoveAllFormResponsesArgs = {
  input: RemoveAllFormResponsesInput;
};

export type MutationremoveAllSelectedInfluencersArgs = {
  input: RemoveAllSelectedInfluencersInput;
};

export type MutationremoveCreatorStaffArgs = {
  input: RemoveCreatorStaffInput;
};

export type MutationremoveEmailsArgs = {
  input: RemoveEmailsInput;
};

export type MutationremoveFansArgs = {
  input: RemoveFansInput;
};

export type MutationremoveFormResponseArgs = {
  input: RemoveFormResponseInput;
};

export type MutationremoveFormsArgs = {
  input: RemoveFormsInput;
};

export type MutationremoveSelectedInfluencersArgs = {
  input: RemoveSelectedInfluencersInput;
};

export type MutationremoveShopifyAccountArgs = {
  input: RemoveShopifyAccountInput;
};

export type MutationreorderMarketplaceCampaignsArgs = {
  campaignIds: ReadonlyArray<InputMaybe<Scalars['Int']>>;
};

export type MutationreportEngagementPostStatusArgs = {
  input: ReportEngagementPostStatusInput;
};

export type MutationreportMarketplacePostStatusArgs = {
  input: ReportMarketplacePostStatusInput;
};

export type MutationreportTiktokSpecialPostStatusArgs = {
  input: ReportTiktokSpecialPostStatusInput;
};

export type MutationrequestDeleteAccountArgs = {
  input: RequestDeleteAccountInput;
};

export type MutationrequestGoogleSlideCustomisedReportArgs = {
  campaignId: Scalars['Int'];
  campaignType: CampaignType;
  selectedMetrics: ReadonlyArray<Scalars['String']>;
};

export type MutationrequestGoogleSlideReportArgs = {
  campaignId: Scalars['Int'];
  campaignType: CampaignType;
};

export type MutationrequestInfluencerProfilePptxArgs = {
  format: Scalars['String'];
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: Scalars['String'];
};

export type MutationrequestInfluencersProfilePptxArgs = {
  format: Scalars['String'];
  influencerIds: ReadonlyArray<InputMaybe<Scalars['Int']>>;
  socialMedias: ReadonlyArray<Scalars['String']>;
};

export type MutationrequestInfluencersSocialAccountPptxArgs = {
  format: Scalars['String'];
  influencerSocialAccountIds: ReadonlyArray<InfluencersPptxSocialAccountInput>;
};

export type MutationrequestInstagramUGCManagementPostArgs = {
  input: RequestInstagramUGCManagementPostInput;
};

export type MutationrequestPackageProposalExcelArgs = {
  format: ProposalDownloadFormat;
  influencerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  packageId: Scalars['Int'];
};

export type MutationrequestPackageProposalPptxArgs = {
  format: InfluencersProfileFormat;
  influencerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  packageId: Scalars['Int'];
};

export type MutationrequestPackageSpreadsheetArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  packageId: Scalars['Int'];
};

export type MutationrequestPowerpointCustomisedReportArgs = {
  campaignId: Scalars['Int'];
  campaignType: CampaignType;
  selectedMetrics: ReadonlyArray<Scalars['String']>;
};

export type MutationrequestPowerpointReportArgs = {
  campaignId: Scalars['Int'];
  campaignType: CampaignType;
};

export type MutationrequestProposalExcelArgs = {
  engagementId: Scalars['Int'];
  influencerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type MutationrequestProposalPptxArgs = {
  engagementId: Scalars['Int'];
  format: Scalars['String'];
  influencerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type MutationrequestProposalSpreadsheetArgs = {
  engagementId: Scalars['Int'];
  influencerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type MutationrequestUserProposalExcelArgs = {
  format: ProposalDownloadFormat;
  influencerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type MutationrequestUserProposalPptxArgs = {
  format: InfluencersProfileFormat;
  influencerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type MutationresendEmailForAdvertiserArgs = {
  input: ResendEmailForAdvertiserInput;
};

export type MutationresendInfluencerForgotPasswordEmailArgs = {
  input: ResendInfluencerForgotPasswordEmailInput;
};

export type MutationresendInfluencerSignUpEmailArgs = {
  input: ResendInfluencerSignUpEmailInput;
};

export type MutationresendPartnerEmailsArgs = {
  input: ResendPartnerEmailsInput;
};

export type MutationresetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationresetPasswordAndLoginArgs = {
  input: ResetPasswordInput;
};

export type MutationresolveChatArgs = {
  input: ResolveChatInput;
};

export type MutationsendBcaRequestArgs = {
  input: SendBcaRequestInput;
};

export type MutationsendContactArgs = {
  input: SendContactInput;
};

export type MutationsendGeminiMessageArgs = {
  input: SendGeminiMessageInput;
};

export type MutationsendLineBroadcastMessageArgs = {
  input: SendLineBroadcastMessageInput;
};

export type MutationsendMessageArgs = {
  input: SendMessageInput;
};

export type MutationsendOpenAiMessageArgs = {
  input: SendOpenAIMessageInput;
};

export type MutationshopifyValidateAuthArgs = {
  input: ShopifyValidateAuthInput;
};

export type MutationsocialAuthConnectableFacebookPageAnalyticsArgs = {
  input: SocialAuthConnectableFacebookPageAnalyticsInput;
};

export type MutationsocialAuthConnectableInstagramAccountsAnalyticsArgs = {
  input: SocialAuthConnectableInstagramAccountsAnalyticsInput;
};

export type MutationsocialAuthConnectableTwitterAccountAnalyticsV2Args = {
  input?: InputMaybe<SocialAuthConnectableTwitterAccountAnalyticsInput>;
};

export type MutationsocialAuthConnectableYouTubeChannelsAnalyticsV2Args = {
  input?: InputMaybe<GetSelectableYoutubeAnalyticsChannelsInput>;
};

export type MutationsocialAuthForJoinCampaignArgs = {
  input: SocialAuthForJoinCampaignInput;
};

export type MutationsocialAuthReConnectInstagramArgs = {
  input: SocialAuthReConnectInstagramInput;
};

export type MutationsocialAuthReconnectFacebookPageAccountAnalyticsArgs = {
  input: SocialAuthReconnectFacebookPageAccountAnalyticsInput;
};

export type MutationsocialAuthReconnectInstagramAccountAnalyticsArgs = {
  input: SocialAuthReconnectInstagramAccountAnalyticsInput;
};

export type MutationsocialAuthReconnectTwitterAccountAnalyticsV2Args = {
  input: SocialAuthReconnectTwitterAccountAnalyticsInput;
};

export type MutationsocialAuthReconnectYouTubeAccountAnalyticsV2Args = {
  input: SocialAuthReconnectYouTubeAccountAnalyticsInput;
};

export type MutationsocialAuthTalentSignInFacebookArgs = {
  input: SocialAuthTalentSignInFacebookInput;
};

export type MutationsocialAuthTalentSignInInstagramArgs = {
  input: SocialAuthTalentSignInInstagramInput;
};

export type MutationsocialAuthTalentSignInTwitterArgs = {
  input: SocialAuthTalentSignInTwitterInput;
};

export type MutationsocialAuthTalentSignInYouTubeArgs = {
  input: SocialAuthTalentSignInYouTubeInput;
};

export type MutationsubmitFormArgs = {
  input: SubmitFormInput;
};

export type MutationsubmitFormInternalArgs = {
  input: SubmitFormInternalInput;
};

export type MutationsubscribeAnalyticsPlanArgs = {
  input: SubscribeAnalyticsPlanInput;
};

export type MutationsudoLimitedLoginArgs = {
  input: SudoLimitedLoginInput;
};

export type MutationswitchInfluencerForStaffArgs = {
  input: SwitchInfluencerForStaffInput;
};

export type MutationswitchRoleToAdminArgs = {
  input: SwitchRoleToAdminInput;
};

export type MutationswitchRoleToAdvertiserArgs = {
  input: SwitchRoleToAdvertiserInput;
};

export type MutationswitchRoleToAgencyArgs = {
  input: SwitchRoleToAgencyInput;
};

export type MutationswitchRoleToPartnerArgs = {
  input: SwitchRoleToPartnerInput;
};

export type MutationswitchRoleToStaffArgs = {
  input: SwitchRoleToStaffInput;
};

export type MutationswitchRoleToTalentAgencyArgs = {
  input: SwitchRoleToTalentAgencyInput;
};

export type MutationtokenAuthArgs = {
  input: ObtainJSONWebTokenInput;
};

export type MutationtokenAuthLongLiveArgs = {
  input: TokenAuthLongLiveInput;
};

export type MutationtrackYoutubeMusicDownloadArgs = {
  input: TrackYoutubeMusicDownloadInput;
};

export type MutationtransferMoneyToAdAccountArgs = {
  input: TransferMoneyToAdAccountInput;
};

export type MutationunApproveApplicantInfluencersArgs = {
  input: UnApproveApplicantInfluencersInput;
};

export type MutationunassignChatPicArgs = {
  input: UnassignChatPICInput;
};

export type MutationunblockInfluencerMarketplaceArgs = {
  input: UnblockInfluencerMarketplaceInput;
};

export type MutationunresolveChatArgs = {
  input: UnresolveChatInput;
};

export type MutationupdateAccountInformationArgs = {
  input: UpdateAccountInformationInput;
};

export type MutationupdateAddressArgs = {
  input: UpdateAddressInput;
};

export type MutationupdateAddressInformationArgs = {
  input: UpdateAddressInformationInput;
};

export type MutationupdateAdminUserArgs = {
  input: UpdateAdminInput;
};

export type MutationupdateAdvertiserArgs = {
  input: UpdateAdvertiserInput;
};

export type MutationupdateAdvertiserByAdvertiserArgs = {
  input: UpdateAdvertiserInput;
};

export type MutationupdateAdvertiserUserArgs = {
  input: UpdateAdvertiserUserInput;
};

export type MutationupdateAdvertiserUserSettingsArgs = {
  input: UpdateAdvertiserUserSettingsInput;
};

export type MutationupdateAgencyArgs = {
  input: UpdatePlainAgencyInput;
};

export type MutationupdateAgencyUserArgs = {
  input: UpdateAgencyInput;
};

export type MutationupdateAutoManagedAffiliateCommissionRatesArgs = {
  input: UpdateAutoManagedAffiliateCommissionRatesInput;
};

export type MutationupdateAutoManagedCampaignArgs = {
  input: UpdateAutoManagedCampaignInput;
};

export type MutationupdateAutoManagedCampaignsStatusArgs = {
  input: UpdateAutoManagedCampaignsStatusInput;
};

export type MutationupdateBioSectionsArgs = {
  input: UpdateBioSectionsInput;
};

export type MutationupdateCmsAssetShareRateArgs = {
  input: UpdateCmsAssetShareRateInput;
};

export type MutationupdateCmsChannelShareRateArgs = {
  input: UpdateCmsChannelShareRateInput;
};

export type MutationupdateCommentArgs = {
  input: UpdateCommentInput;
};

export type MutationupdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};

export type MutationupdateEngagementArgs = {
  input: UpdateEngagementInput;
};

export type MutationupdateEngagementBreakdownModeArgs = {
  input: UpdateEngagementBreakDownModeInput;
};

export type MutationupdateEngagementCouponListArgs = {
  input: UpdateEngagementCouponListInput;
};

export type MutationupdateEngagementInfluencerProposalListArgs = {
  input: UpdateEngagementInfluencerProposalListInput;
};

export type MutationupdateEngagementInfluencersBudgetArgs = {
  input: UpdateEngagementInfluencersBudgetInput;
};

export type MutationupdateEngagementPostForInfluencerV2Args = {
  input: UpdateEngagementPostInputForInfluencerV2;
};

export type MutationupdateEngagementPostStatusArgs = {
  input: UpdateEngagementPostStatusInput;
};

export type MutationupdateEngagementPostV2Args = {
  input: UpdateEngagementPostInputV2;
};

export type MutationupdateEngagementSocialAccountProposalListArgs = {
  input: UpdateEngagementSocialAccountProposalListInput;
};

export type MutationupdateEngagementSocialAccountsBudgetArgs = {
  input: UpdateEngagementSocialAccountsBudgetInput;
};

export type MutationupdateEngagementStatusArgs = {
  input: UpdateEngagementStatusInput;
};

export type MutationupdateFanArgs = {
  input: UpdateFanInput;
};

export type MutationupdateFormArgs = {
  input: UpdateFormInput;
};

export type MutationupdateFormStatusArgs = {
  input: UpdateFormStatusInput;
};

export type MutationupdateInfluencerBioArgs = {
  input: UpdateInfluencerBioInput;
};

export type MutationupdateInfluencerPhoneNumberArgs = {
  input: UpdateInfluencerPhoneNumberInput;
};

export type MutationupdateInfluencerProfileArgs = {
  input: UpdateInfluencerProfileInput;
};

export type MutationupdateInfluencerRateCardArgs = {
  input: UpdateInfluencerRateCardInput;
};

export type MutationupdateInfluencerRateCardsArgs = {
  input: UpdateInfluencerRateCardsInput;
};

export type MutationupdateInfluencerRateCardsByStaffArgs = {
  input: UpdateInfluencerRateCardsByStaffInput;
};

export type MutationupdateInfluencerTagsByStaffArgs = {
  input: UpdateInfluencerTagsByStaffInput;
};

export type MutationupdateInfluencerV2Args = {
  input: UpdateInfluencerInputV2;
};

export type MutationupdateInternalMemoArgs = {
  input: UpdateInternalMemoInput;
};

export type MutationupdateLineBroadcastMessageArgs = {
  input: UpdateLineBroadcastMessageInput;
};

export type MutationupdateLinkBioThemeColorArgs = {
  input: UpdateLinkBioThemeColorInput;
};

export type MutationupdateMarketplaceArgs = {
  input: UpdateMarketplaceInput;
};

export type MutationupdateMarketplaceAffiliateCommissionFeesArgs = {
  input: UpdateMarketplaceAffiliateCommissionFeesInput;
};

export type MutationupdateMarketplaceAffiliateCommissionRatesArgs = {
  input: UpdateMarketplaceAffiliateCommissionRatesInput;
};

export type MutationupdateMarketplaceCouponListArgs = {
  input: UpdateMarketplaceCouponListInput;
};

export type MutationupdateMarketplaceInfluencerCostArgs = {
  input: UpdateMarketplaceInfluencerCostInput;
};

export type MutationupdateMarketplacePostStatusV2Args = {
  input: UpdateMarketplacePostStatusInputV2;
};

export type MutationupdateMarketplacePostTrackingUrlArgs = {
  input: UpdateMarketplacePostTrackingUrlInput;
};

export type MutationupdatePackageArgs = {
  input: UpdatePackageInput;
};

export type MutationupdatePackageProposalInfluencersBudgetArgs = {
  input: UpdatePackageProposalInfluencersBudgetInput;
};

export type MutationupdatePackageProposalModeArgs = {
  input: UpdatePackageProposalModeInput;
};

export type MutationupdatePackageProposalSocialAccountsBudgetArgs = {
  input: UpdatePackageProposalSocialAccountsBudgetInput;
};

export type MutationupdatePartnerAgencyArgs = {
  input: UpdatePartnerAgencyInput;
};

export type MutationupdatePartnerUserArgs = {
  input: UpdatePartnerUserInput;
};

export type MutationupdatePasswordByAdvertiserUserArgs = {
  input: UpdatePasswordByAdvertiserUserInput;
};

export type MutationupdatePaymentInformationArgs = {
  input: UpdatePaymentInformationInput;
};

export type MutationupdatePaymentStatusArgs = {
  input: UpdatePaymentStatusInput;
};

export type MutationupdatePersonalUserDetailArgs = {
  input: UpdatePersonalUserDetailInput;
};

export type MutationupdateSocialAccountsArgs = {
  input?: InputMaybe<UpdateSocialAccountsInput>;
};

export type MutationupdateSocialUsernameArgs = {
  input: UpdateSocialUsernameInput;
};

export type MutationupdateStaffUserArgs = {
  input: UpdateStaffInput;
};

export type MutationupdateSubscriptionPlanArgs = {
  input: UpdateSubscriptionPlanInput;
};

export type MutationupdateTalentAgencyArgs = {
  input: UpdateTalentAgencyInput;
};

export type MutationupdateTalentAgencyUserArgs = {
  input: UpdateTalentAgencyUserInput;
};

export type MutationupdateTalentInfluencerArgs = {
  input: UpdateTalentInfluencerInput;
};

export type MutationupdateTiktokAdvertiserInfoArgs = {
  input: UpdateTiktokAdvertiserInfoInput;
};

export type MutationupdateTiktokSpecialCampaignArgs = {
  input: UpdateTikTokSpecialCampaignInput;
};

export type MutationupdateTiktokSpecialPostStatusArgs = {
  input: UpdateTiktokSpecialPostStatusInput;
};

export type MutationupdateTtcmPostArgs = {
  input: UpdateTtcmPostInput;
};

export type MutationupdateUserDetailArgs = {
  input: UpdateUserDetailInput;
};

export type MutationupdateUserProposalBreakdownModeArgs = {
  input: UpdateUserProposalBreakdownModeInput;
};

export type MutationupdateUserProposalInfluencersBudgetArgs = {
  input: UpdateUserProposalInfluencersBudgetInput;
};

export type MutationupdateUserProposalSocialAccountsBudgetArgs = {
  input: UpdateUserProposalSocialAccountsBudgetInput;
};

export type MutationupdateYoutubeMCNInfluencerCredentialsArgs = {
  input: UpdateYoutubeMCNCredentialsInput;
};

export type MutationuploadEngagementReportFileArgs = {
  input: UploadEngagementReportFileInput;
};

export type MutationuploadEngagementReportFromInsightCheckArgs = {
  input: UploadEngagementReportFromInsightCheckInput;
};

export type MutationuploadEngagementXhsInsightCheckArgs = {
  input: UploadEngagementXhsInsightCheckInput;
};

export type MutationuploadOpenAiImagesArgs = {
  input: UploadOpenAIImagesInput;
};

export type MutationuuumSocialAuthSignUpArgs = {
  input: UUUMSocialAuthSignUpInput;
};

export type MutationverifyLineIDTokenArgs = {
  input: VerifyLineIDTokenInput;
};

export type MutationverifyTokenArgs = {
  input: VerifyInput;
};

export type MutationwithdrawMoneyFromAdAccountArgs = {
  input: TransferMoneyToAdAccountInput;
};

export type NeedReconnectForFacebookPayload = {
  readonly need: Scalars['Boolean'];
};

export type NeedReviewMarketplacePost = {
  readonly id: Scalars['Int'];
  readonly influencer: MarketplacePostInfluencer;
};

export type NetSuiteCustomerData = {
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly countryId?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
};

export type NetSuiteCustomerDataForSearch = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type NetSuiteVendorData = {
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly countryId?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
};

export type NetSuiteVendorDataForSearch = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

/** An enumeration. */
export enum NetSuiteVerifiedStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  NO_STATUS = 'NO_STATUS',
  VERIFIED = 'VERIFIED'
}

export type NewAutoManagedCampaignForInfluencerForYourJob = {
  readonly brandName?: Maybe<Scalars['String']>;
  readonly commissionRate?: Maybe<Scalars['Float']>;
  readonly currency: Scalars['String'];
  readonly endDate?: Maybe<Scalars['Date']>;
  /** Whether she uploaded her post on social media or not. */
  readonly hasReport: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly mkpServerId: Scalars['Int'];
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly status: AutoManagedAffiliateCampaignStatus;
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly type: CampaignType;
};

export type NewCampaignPostReport = {
  readonly InternalInfo?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly orderedSummaryDaily?: Maybe<ReadonlyArray<CampaignSummaryDaily>>;
  readonly postInfo: CampaignPostInfo;
  readonly summary: NewCampaignStatsItemRow;
  readonly summaryDaily?: Maybe<ReadonlyArray<CampaignSummaryDaily>>;
};

export type NewCampaignReportSummary = {
  readonly campaignSocialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly comment: Scalars['Int'];
  readonly conversions?: Maybe<Scalars['Int']>;
  readonly cost: Scalars['Float'];
  readonly currency: Scalars['String'];
  readonly engagement: Scalars['Float'];
  readonly engagementRate: Scalars['Float'];
  readonly like?: Maybe<Scalars['Int']>;
  readonly orders?: Maybe<Scalars['Int']>;
  readonly postCount: Scalars['Int'];
  /** @deprecated please use sales_tune or sales_referral */
  readonly sales?: Maybe<Scalars['Float']>;
  readonly salesReferral?: Maybe<Scalars['Float']>;
  readonly salesTune?: Maybe<Scalars['Float']>;
};

export type NewCampaignStatsItemRow = {
  readonly age35UpPercentage?: Maybe<Scalars['Float']>;
  readonly age1824Percentage?: Maybe<Scalars['Float']>;
  readonly age2534Percentage?: Maybe<Scalars['Float']>;
  readonly averageViewDurationMinutes?: Maybe<Scalars['Float']>;
  readonly averageViewDurationPercentage?: Maybe<Scalars['Float']>;
  readonly averageViewDurationSeconds?: Maybe<Scalars['Int']>;
  readonly averageViewTime?: Maybe<Scalars['Float']>;
  readonly back?: Maybe<Scalars['Int']>;
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  readonly cost: Scalars['Float'];
  readonly costsPerAction?: Maybe<Scalars['Float']>;
  readonly costsPerClick?: Maybe<Scalars['Float']>;
  /** For Vietnam campaigns */
  readonly costsPerComment?: Maybe<Scalars['Float']>;
  readonly costsPerEngagement?: Maybe<Scalars['Float']>;
  readonly costsPerFollower?: Maybe<Scalars['Float']>;
  readonly costsPerImpressions?: Maybe<Scalars['Float']>;
  readonly costsPerLike?: Maybe<Scalars['Float']>;
  readonly costsPerOrder?: Maybe<Scalars['Float']>;
  readonly costsPerReach?: Maybe<Scalars['Float']>;
  readonly costsPerSale?: Maybe<Scalars['Float']>;
  readonly costsPerSaleReferral?: Maybe<Scalars['Float']>;
  readonly costsPerSaleTune?: Maybe<Scalars['Float']>;
  readonly costsPerSaved?: Maybe<Scalars['Float']>;
  readonly costsPerShare?: Maybe<Scalars['Float']>;
  readonly costsPerView?: Maybe<Scalars['Float']>;
  readonly dislikes?: Maybe<Scalars['Int']>;
  readonly engagement: Scalars['Float'];
  /** Engagement Rate by Followers for IG, FB, FB Page, X, Threads */
  readonly engagementRate?: Maybe<Scalars['Float']>;
  /** Engagement Rate by Views for TikTok, YT, Douyin */
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
  readonly exited?: Maybe<Scalars['Int']>;
  readonly femalePercentage?: Maybe<Scalars['Float']>;
  readonly followerReach?: Maybe<Scalars['Int']>;
  readonly followerReachRate?: Maybe<Scalars['Float']>;
  readonly followers: Scalars['Int'];
  readonly forward?: Maybe<Scalars['Int']>;
  readonly impressionRate?: Maybe<Scalars['Float']>;
  readonly impressions?: Maybe<Scalars['Int']>;
  readonly impressionsFromDiscovery?: Maybe<Scalars['Int']>;
  readonly impressionsFromHashtag?: Maybe<Scalars['Int']>;
  readonly impressionsFromHome?: Maybe<Scalars['Int']>;
  readonly impressionsFromOther?: Maybe<Scalars['Int']>;
  readonly impressionsFromProfile?: Maybe<Scalars['Int']>;
  readonly interaction?: Maybe<Scalars['Int']>;
  readonly like?: Maybe<Scalars['Int']>;
  readonly linkClicks?: Maybe<Scalars['Int']>;
  readonly malePercentage?: Maybe<Scalars['Float']>;
  readonly navigation?: Maybe<Scalars['Int']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly net?: Maybe<Scalars['Float']>;
  readonly newFollowers?: Maybe<Scalars['Int']>;
  readonly nextStory?: Maybe<Scalars['Int']>;
  readonly nonFollowerReach?: Maybe<Scalars['Int']>;
  readonly nonFollowerReachRate?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly profileActivity?: Maybe<Scalars['Int']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly profit?: Maybe<Scalars['Float']>;
  /** For admin or staff user, and EngagementCampaign */
  readonly profitRate?: Maybe<Scalars['Float']>;
  readonly reach?: Maybe<Scalars['Int']>;
  readonly reachRate?: Maybe<Scalars['Float']>;
  /** @deprecated please use sales_tune or sales_referral */
  readonly sales?: Maybe<Scalars['Float']>;
  readonly salesReferral?: Maybe<Scalars['Float']>;
  readonly salesTune?: Maybe<Scalars['Float']>;
  readonly saved?: Maybe<Scalars['Int']>;
  readonly share?: Maybe<Scalars['Int']>;
  readonly stickerTaps?: Maybe<Scalars['Int']>;
  readonly totalPlayTime?: Maybe<Scalars['Int']>;
  readonly videoCompletionRate?: Maybe<Scalars['Float']>;
  readonly view?: Maybe<Scalars['Int']>;
  /** XHS metric */
  readonly viewsFromFollowing?: Maybe<Scalars['Int']>;
  /** XHS metric */
  readonly viewsFromHome?: Maybe<Scalars['Int']>;
  /** XHS metric */
  readonly viewsFromOther?: Maybe<Scalars['Int']>;
  /** XHS metric */
  readonly viewsFromProfile?: Maybe<Scalars['Int']>;
  /** XHS metric */
  readonly viewsFromSearch?: Maybe<Scalars['Int']>;
};

export type NewEngagementCampaignForInfluencerForYourJob = {
  readonly currency: Scalars['String'];
  readonly endDate: Scalars['Date'];
  readonly hasDraftPosts: Scalars['Boolean'];
  /** Whether she uploaded her post on social media or not. */
  readonly hasReport: Scalars['Boolean'];
  readonly hasWarningPosts: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly isTtcmCampaign: Scalars['Boolean'];
  /** influencer revenue */
  readonly rewardAmount?: Maybe<Scalars['Float']>;
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate: Scalars['Date'];
  readonly status: AnyXEngagementCampaignStatus;
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly ttcmStatus?: Maybe<TTCMStatus>;
  readonly type: CampaignType;
};

export type NewMarketplaceCampaignForInfluencerForYourJob = {
  /** exists when campaign type is SELECTION */
  readonly appliedStatus?: Maybe<MarketplaceCampaignAppliedStatus>;
  readonly campaignDetailType: MarketplaceCampaignDetailType;
  readonly campaignStatus?: Maybe<CampaignStatusForInfluencer>;
  readonly commissionRate?: Maybe<Scalars['Float']>;
  readonly currency: Scalars['String'];
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly hasPostsCouponMissing?: Maybe<Scalars['Boolean']>;
  /** Whether she uploaded her post on social media or not. */
  readonly hasReport: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  /** For Selection Marketplace Campaign */
  readonly isApplying?: Maybe<Scalars['Boolean']>;
  readonly isAutoInfluencerApproval?: Maybe<Scalars['Boolean']>;
  readonly isGiftingCampaign?: Maybe<Scalars['Boolean']>;
  readonly isPostTracking?: Maybe<Scalars['Boolean']>;
  readonly isSelectionCampaign?: Maybe<Scalars['Boolean']>;
  readonly isWaiting: Scalars['Boolean'];
  readonly mkpServerId?: Maybe<Scalars['Int']>;
  readonly preLaunchDate?: Maybe<Scalars['Date']>;
  readonly remainingAmount?: Maybe<Scalars['Float']>;
  readonly revenuePerAction?: Maybe<Scalars['Float']>;
  readonly revenuePerClick?: Maybe<Scalars['Float']>;
  readonly revenuePerComment?: Maybe<Scalars['Float']>;
  readonly revenuePerFollower?: Maybe<Scalars['Float']>;
  readonly revenuePerLike?: Maybe<Scalars['Float']>;
  readonly revenuePerOrder?: Maybe<Scalars['Float']>;
  readonly revenuePerPost?: Maybe<Scalars['Float']>;
  readonly revenuePerShare?: Maybe<Scalars['Float']>;
  readonly revenuePerView?: Maybe<Scalars['Float']>;
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate: Scalars['Date'];
  readonly status?: Maybe<AnyXMarketplaceCampaignStatus>;
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly totalAmount?: Maybe<Scalars['Float']>;
  readonly type: CampaignType;
};

export type NewTiktokSpecialCampaignForInfluencerForYourJob = {
  readonly campaignStatus: CampaignStatusForInfluencer;
  readonly currency: Scalars['String'];
  readonly endDate: Scalars['Date'];
  /** Whether she uploaded her post on social media or not. */
  readonly hasReport: Scalars['Boolean'];
  readonly id: Scalars['Int'];
  readonly mkpServerId: Scalars['Int'];
  readonly preLaunchDate?: Maybe<Scalars['Date']>;
  readonly revenuePerPost: Scalars['Float'];
  readonly socialMedia: CampaignSocialMediaType;
  readonly startDate: Scalars['Date'];
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly type: CampaignType;
};

export enum NotificationId {
  AD_HOC_PAYMENT_FLOW_CHANGED = 'AD_HOC_PAYMENT_FLOW_CHANGED',
  BANK_ACCOUNT_CHECK = 'BANK_ACCOUNT_CHECK',
  ENGAGEMENT_DRAFT_POST_APPROVED = 'ENGAGEMENT_DRAFT_POST_APPROVED',
  ENGAGEMENT_DRAFT_POST_REJECTED = 'ENGAGEMENT_DRAFT_POST_REJECTED',
  ENGAGEMENT_FETCHED = 'ENGAGEMENT_FETCHED',
  ENGAGEMENT_FINISH = 'ENGAGEMENT_FINISH',
  MARKETPLACE_CAMPAIGN_FINISHED = 'MARKETPLACE_CAMPAIGN_FINISHED',
  MARKETPLACE_CAMPAIGN_JOINED = 'MARKETPLACE_CAMPAIGN_JOINED',
  MARKETPLACE_CAMPAIGN_REACH_BUDGET = 'MARKETPLACE_CAMPAIGN_REACH_BUDGET',
  MARKETPLACE_CAMPAIGN_REACH_BUDGET_NP = 'MARKETPLACE_CAMPAIGN_REACH_BUDGET_NP',
  MARKETPLACE_CAMPAIGN_STARTED = 'MARKETPLACE_CAMPAIGN_STARTED',
  MARKETPLACE_CAMPAIGN_UPCOMING = 'MARKETPLACE_CAMPAIGN_UPCOMING',
  MARKETPLACE_CAMPAIGN_UPDATE = 'MARKETPLACE_CAMPAIGN_UPDATE',
  MARKETPLACE_DRAFT_POST_APPROVED = 'MARKETPLACE_DRAFT_POST_APPROVED',
  MARKETPLACE_DRAFT_POST_REJECTED = 'MARKETPLACE_DRAFT_POST_REJECTED',
  MARKETPLACE_SHIPPED = 'MARKETPLACE_SHIPPED',
  MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED = 'MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED',
  MP_CAMPAIGNS_APPLICANT_INFLS_INAPPROPRIATE = 'MP_CAMPAIGNS_APPLICANT_INFLS_INAPPROPRIATE',
  MP_CAMPAIGNS_APPLICANT_INFLS_REJECTED = 'MP_CAMPAIGNS_APPLICANT_INFLS_REJECTED',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_REQUEST_DONE = 'PAYMENT_REQUEST_DONE'
}

/**  `NotificationPayload` payload consists of the data of a single notification. */
export type NotificationPayload = {
  readonly content: Scalars['JSON'];
  readonly created: Scalars['DateTime'];
  readonly id: Scalars['Long'];
  readonly isClicked: Scalars['Boolean'];
  readonly notificationId: NotificationId;
};

/**
 *  `checkpoint`: a checkpoint to get notifications beyond the checkpoint. A value of `null` can be provided to start from the latest notification.
 *  `appType`: a enum type which represents the notification scope of application
 */
export type NotificationsByAppInput = {
  readonly appType: AppType;
  readonly checkpoint?: InputMaybe<Scalars['Long']>;
};

/**
 *  `checkpoint`: an offset used for the next query for the API to know where we left off. (Lookup keyset pagination for more info)
 *  `hasMore`: a boolean informing whether is there any more notifications available to grab
 */
export type NotificationsPayload = {
  readonly checkpoint: Scalars['Long'];
  readonly hasMore: Scalars['Boolean'];
  readonly notifications: ReadonlyArray<NotificationPayload>;
};

/** An enumeration. */
export enum ORDER {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type ObtainJSONWebTokenInput = {
  readonly appName?: InputMaybe<AppName>;
  readonly email: Scalars['String'];
  readonly password: Scalars['String'];
};

export type ObtainJSONWebTokenPayload = {
  readonly company?: Maybe<Scalars['String']>;
  /** Users's country's id */
  readonly countryId?: Maybe<Scalars['String']>;
  /** Users's country's currency id  */
  readonly currencyId: Scalars['String'];
  readonly hash?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly role: UserRoles;
  readonly subscriptionPaymentFailed?: Maybe<Scalars['Boolean']>;
  readonly token: Scalars['String'];
  readonly userId: Scalars['Int'];
};

export type OpenAIMessage = {
  /** value will return when status is READY_TO_SEARCH */
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  /** for debugging */
  readonly jsonResponses?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly nextAction: OpenAINextAction;
  readonly runId: Scalars['String'];
  readonly text?: Maybe<Scalars['String']>;
  readonly threadId: Scalars['Int'];
};

/** An enumeration. */
export enum OpenAINextAction {
  COLLECTING = 'COLLECTING',
  ERROR = 'ERROR',
  READY_TO_SEARCH = 'READY_TO_SEARCH',
  WAITING_FOR_RESPONSE = 'WAITING_FOR_RESPONSE'
}

/** An enumeration. */
export enum OpenAIRunSourceFrom {
  ALL_INFLUENCERS_TAB = 'ALL_INFLUENCERS_TAB',
  INSTAGRAM_TAB = 'INSTAGRAM_TAB',
  TIKTOK_TAB = 'TIKTOK_TAB',
  YOUTUBE_TAB = 'YOUTUBE_TAB'
}

export type OperatedUserInfo = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type OptionInput = {
  readonly optionTitle: Scalars['String'];
  readonly order: Scalars['Int'];
};

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type PackageCompany = {
  /** id of company */
  readonly companyId: Scalars['Int'];
  /** unique result index used for FE purpose */
  readonly id?: Maybe<Scalars['Int']>;
  /** name of company */
  readonly name: Scalars['String'];
  /** company role */
  readonly role: UserRoles;
};

export type PackageInfluencer = {
  readonly accountList: ReadonlyArray<PackageSocialAccount>;
  readonly addedDate?: Maybe<Scalars['Date']>;
  readonly avatar?: Maybe<Scalars['String']>;
  /** Influencer id */
  readonly id: Scalars['Int'];
  /** Influencer name */
  readonly name: Scalars['String'];
  readonly status: SocialAccountStatus;
  readonly summary: PackageSummary;
};

export type PackageProposal = {
  readonly influencers: ReadonlyArray<PackageInfluencer>;
  readonly totalCount: Scalars['Int'];
};

export type PackageProposalInfluencerBudgetInput = {
  readonly budget: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
  readonly profit: Scalars['Float'];
};

export type PackageProposalSocialAcccountBudgetInput = {
  readonly budget: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
  readonly profit: Scalars['Float'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialAccountType: SocialAccountType;
};

export type PackageProposalSummary = {
  readonly currency: Scalars['String'];
  /** Package Id */
  readonly id: Scalars['Int'];
  readonly jdMode: ProposalJobDescriptionBreakDownMode;
  readonly name: Scalars['String'];
  readonly summary: PackageSummaryV2;
};

export type PackageSocialAccount = {
  readonly addedDate?: Maybe<Scalars['Date']>;
  /** Social account id */
  readonly id: Scalars['Int'];
  /** Social account name */
  readonly name?: Maybe<Scalars['String']>;
  /** Social account type */
  readonly socialMedia: SocialAccountType;
  readonly summary: PackageSummary;
};

export type PackageSummary = {
  readonly averageEngagement?: Maybe<Scalars['Float']>;
  readonly budget?: Maybe<Scalars['Float']>;
  readonly engagementFollowerRate?: Maybe<Scalars['Float']>;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly engagementViewRate?: Maybe<Scalars['Float']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly influencerCost?: Maybe<Scalars['Float']>;
  readonly profit?: Maybe<Scalars['Float']>;
};

export type PackageSummaryV2 = {
  readonly avgEngagementPosted?: Maybe<Scalars['Float']>;
  readonly avgEngagementProposed?: Maybe<Scalars['Float']>;
  readonly budget?: Maybe<Scalars['Float']>;
  readonly engagementFollowerRate?: Maybe<Scalars['Float']>;
  readonly engagementViewRate?: Maybe<Scalars['Float']>;
  readonly followersCount?: Maybe<Scalars['BigInt']>;
  readonly influencerCost?: Maybe<Scalars['Float']>;
  readonly profit?: Maybe<Scalars['Float']>;
};

export type ParticipationForm = {
  readonly formName: Scalars['String'];
  readonly submissionId: Scalars['Long'];
  readonly submittedAt: Scalars['String'];
};

export type Partner = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type PartnerAgencyUserWithRole = {
  readonly companyName?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly role: UserRoles;
};

export type PartnerAgencyWithCompanies = {
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  readonly agencyIds: ReadonlyArray<Scalars['Int']>;
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country?: Maybe<CountryName>;
  readonly id: Scalars['Int'];
  readonly influencerCostPermission: Scalars['Boolean'];
  readonly logo?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly netsuiteId?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly privateInfluencers: ReadonlyArray<PrivatePartnerInfluencer>;
};

export type PartnerAgencyWithNSVerification = {
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country?: Maybe<CountryName>;
  readonly id: Scalars['Int'];
  readonly logo?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly netsuiteId?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly users: Scalars['Int'];
  readonly verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
};

export type PartnerAgencyWithPermission = {
  readonly id: Scalars['Int'];
  readonly influencerCostPermission: Scalars['Boolean'];
  readonly name: Scalars['String'];
};

/**  Stores a redirect URL that FE uses to reconnect partner influencer accounts */
export type PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload = {
  readonly redirectUri: Scalars['String'];
};

export type PayableCampaign = {
  readonly campaignType: CampaignType;
  readonly currency: Scalars['String'];
  /** Marketplace OR AutoManaged campaign */
  readonly id: Scalars['Int'];
  readonly revenue: Scalars['Float'];
  readonly socialMedia?: Maybe<SocialAccountType>;
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
};

export type Payment = {
  readonly currency: Scalars['String'];
  readonly dueDate: Scalars['Date'];
  readonly fee: Scalars['Float'];
  readonly grossRevenue: Scalars['Float'];
  readonly id: Scalars['Int'];
  readonly influencer: PaymentInfluencerAccount;
  readonly netRevenue: Scalars['Float'];
  readonly paidDate?: Maybe<Scalars['Date']>;
  readonly requestedDate: Scalars['Date'];
  readonly status: PaymentRequestStatus;
};

export type PaymentInfluencerAccount = {
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type PaymentInformation = {
  readonly info?: Maybe<PaymentInformationDetails>;
  readonly isEditable: Scalars['Boolean'];
};

export type PaymentInformationDetails = {
  readonly accountName: Scalars['String'];
  readonly accountNumber: Scalars['String'];
  readonly bankName: Scalars['String'];
  readonly branchName: Scalars['String'];
};

export type PaymentInformationInput = {
  readonly accountName: Scalars['String'];
  readonly accountNumber: Scalars['String'];
  readonly bankName: Scalars['String'];
  readonly branchName: Scalars['String'];
};

/** An enumeration. */
export enum PaymentPackageStatus {
  FAILED = 'FAILED',
  INVALID = 'INVALID',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID = 'PAID'
}

export type PaymentPackageStatusResponse = {
  readonly failureCode?: Maybe<Scalars['String']>;
  readonly failureMessage?: Maybe<Scalars['String']>;
  readonly status: PaymentPackageStatus;
};

/** An enumeration. */
export enum PaymentPlanName {
  ADVANCED_CAMPAIGN = 'ADVANCED_CAMPAIGN',
  BASIC_CAMPAIGN = 'BASIC_CAMPAIGN',
  BEGINNER = 'BEGINNER',
  ENTERPRISE = 'ENTERPRISE',
  PRO_CAMPAIGN = 'PRO_CAMPAIGN',
  STANDARD = 'STANDARD'
}

/** An enumeration. */
export enum PaymentPlanType {
  ANALYTICS = 'ANALYTICS',
  MARKETPLACE = 'MARKETPLACE',
  TIKTOK_ADS = 'TIKTOK_ADS'
}

/** An enumeration. */
export enum PaymentPriceType {
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
  SIX_MONTHS = 'SIX_MONTHS',
  TWELVE_MONTHS = 'TWELVE_MONTHS'
}

export type PaymentRequest = {
  readonly currency: Scalars['String'];
  readonly fee: Scalars['Float'];
  readonly grossRevenue: Scalars['Float'];
  readonly minimumPaymentAmount: Scalars['Float'];
};

/** An enumeration. */
export enum PaymentRequestStatus {
  FAILED = 'FAILED',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING'
}

/** An enumeration. */
export enum PaymentRequestStatusForInfluencer {
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  TRANSFERRED = 'TRANSFERRED'
}

export type PersonalUserDetailPayload = {
  readonly displayLanguage?: Maybe<DisplayLanguage>;
  readonly email: Scalars['String'];
  readonly isAvailableCountry: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly notificationSetting?: Maybe<Scalars['Boolean']>;
};

export type PicName = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type PicUser = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type PlainTextMessageInput = {
  readonly text: Scalars['String'];
};

export type PlainTextMessagePayload = {
  readonly text: Scalars['String'];
};

export type PostByHashtag = {
  readonly hashtagId: Scalars['Int'];
  readonly posts: ReadonlyArray<PostsForHashtagDashboard>;
};

/** An enumeration. */
export enum PostListSortField {
  AGE = 'AGE',
  COMMENT = 'COMMENT',
  COUNTRY = 'COUNTRY',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
  GENDER = 'GENDER',
  IMPRESSION = 'IMPRESSION',
  LIKE = 'LIKE',
  POST_DATE = 'POST_DATE',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  VIEW = 'VIEW'
}

export enum PostListSortFieldV3 {
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
  POST_DATE = 'POST_DATE',
  RELEVANT = 'RELEVANT'
}

export type PostOrderBy = {
  readonly field?: InputMaybe<PostListSortField>;
  readonly order?: InputMaybe<ORDER>;
};

export type PostOrderByV3 = {
  readonly field?: InputMaybe<PostListSortFieldV3>;
  readonly order?: InputMaybe<Order>;
};

export type PostsByTime = {
  readonly date: Scalars['Date'];
  readonly postIds: ReadonlyArray<Scalars['Int']>;
  readonly postsCount: Scalars['Int'];
};

export type PostsForHashtagDashboard = {
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly thumbnail: Scalars['String'];
};

export type PostsMetricsGridReport = {
  readonly averageMetricRate?: Maybe<Scalars['Float']>;
  readonly currency?: Maybe<Scalars['String']>;
  readonly posts: ReadonlyArray<CampaignPostReportGrid>;
};

export type PricingTable = {
  readonly plans: ReadonlyArray<PricingTableItem>;
};

export type PricingTableItem = {
  readonly currency: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  /** price id */
  readonly id: Scalars['Int'];
  readonly isCurrentPlan: Scalars['Boolean'];
  readonly mkpCampaignEnabled: Scalars['Boolean'];
  readonly name: PaymentPlanName;
  readonly numberOfInfluencers?: Maybe<Scalars['Int']>;
  readonly packageId?: Maybe<Scalars['Int']>;
  readonly price: Scalars['Float'];
  readonly priceType: PaymentPriceType;
  readonly trialDays?: Maybe<Scalars['Int']>;
};

export type PrivatePartnerInfluencer = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type PrivateTalentInfluencer = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type PromotionMethodEmailNewsLetterForProfile = {
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
};

export type PromotionMethodEmailNewsLetterPayload = {
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type PromotionMethodInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly method: CampaignPromotionMethod;
  readonly mobileAppType?: InputMaybe<PromotionMobileAppType>;
  readonly url?: InputMaybe<Scalars['String']>;
};

export type PromotionMethodMobileAppForProfile = {
  readonly id: Scalars['Int'];
  readonly mobileAppType?: Maybe<PromotionMobileAppType>;
  readonly url?: Maybe<Scalars['String']>;
};

export type PromotionMethodMobileAppPayload = {
  readonly id: Scalars['ID'];
  readonly mobileAppType?: Maybe<PromotionMobileAppType>;
  readonly url?: Maybe<Scalars['String']>;
};

export type PromotionMethodOfflineForProfile = {
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
};

export type PromotionMethodOfflinePayload = {
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type PromotionMethodPodCastForProfile = {
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly url?: Maybe<Scalars['String']>;
};

export type PromotionMethodPodCastPayload = {
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly url?: Maybe<Scalars['String']>;
};

export type PromotionMethodWebsiteForProfile = {
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly url?: Maybe<Scalars['String']>;
};

export type PromotionMethodWebsitePayload = {
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly url?: Maybe<Scalars['String']>;
};

export enum PromotionMobileAppType {
  ANDROID = 'ANDROID',
  APPLE = 'APPLE'
}

/** An enumeration. */
export enum ProposalDownloadFormat {
  EXCEL = 'EXCEL',
  SPREADSHEET = 'SPREADSHEET'
}

export type ProposalInfluencer = {
  readonly accountList: ReadonlyArray<ProposalSocialAccount>;
  readonly addedDate?: Maybe<Scalars['Date']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly couponCode?: Maybe<Scalars['String']>;
  readonly deniedReason?: Maybe<Scalars['String']>;
  /** Influencer id */
  readonly id: Scalars['Int'];
  readonly isDenied?: Maybe<Scalars['Boolean']>;
  readonly jdMode: ProposalJobDescriptionBreakDownMode;
  /** Influencer name */
  readonly name: Scalars['String'];
  readonly reasons?: Maybe<Scalars['String']>;
  readonly requirements?: Maybe<Scalars['String']>;
  readonly status: SocialAccountStatus;
  readonly summary: ProposalJobSummary;
  readonly ttcmCampaignCode?: Maybe<Scalars['String']>;
  readonly ttcmCampaignInviteLink?: Maybe<Scalars['String']>;
  readonly ttcmStatus?: Maybe<TTCMStatus>;
};

/** An enumeration. */
export enum ProposalJobDescriptionBreakDownMode {
  INFLUENCER_BREAKDOWN = 'INFLUENCER_BREAKDOWN',
  SOCIAL_BREAKDOWN = 'SOCIAL_BREAKDOWN'
}

export type ProposalJobSummary = {
  readonly adAgencyMarginRate?: Maybe<Scalars['Float']>;
  readonly adAgencyMarginValue?: Maybe<Scalars['Int']>;
  readonly averageEngagement?: Maybe<Scalars['Float']>;
  readonly budget?: Maybe<Scalars['Float']>;
  readonly engagementFollowerRate?: Maybe<Scalars['Float']>;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly engagementViewRate?: Maybe<Scalars['Float']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly influencerCost?: Maybe<Scalars['Float']>;
  readonly marginRate?: Maybe<Scalars['Float']>;
  readonly netBudget?: Maybe<Scalars['Float']>;
  readonly posts?: Maybe<Scalars['Int']>;
  readonly profit?: Maybe<Scalars['Float']>;
};

export type ProposalSocialAccount = {
  readonly addedDate?: Maybe<Scalars['Date']>;
  /** Social account id */
  readonly id: Scalars['Int'];
  /** Social account name */
  readonly name?: Maybe<Scalars['String']>;
  /** Social account type */
  readonly socialMedia: EngagementProposalSocialMediaType;
  readonly summary: ProposalJobSummary;
  readonly ttcmCampaignInviteLink?: Maybe<Scalars['String']>;
  readonly ttcmStatus?: Maybe<TTCMStatus>;
};

export type Province = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type ProvincesPayload = {
  readonly provinces: ReadonlyArray<Province>;
};

export type PublicBioSection = {
  readonly id: Scalars['Int'];
  readonly image?: Maybe<BioImage>;
  readonly link?: Maybe<BioUrlLink>;
  readonly sectionType: BioSectionType;
  readonly socialMedia?: Maybe<BioSocialMedia>;
  readonly text?: Maybe<BioText>;
};

export type PublicBioTheme = {
  readonly bg: PublicLinkBioBackground;
  readonly button?: Maybe<BioButtonStyle>;
  readonly color: PublicLinkBioPageColor;
  readonly font?: Maybe<BioFontStyle>;
  readonly theme?: Maybe<BioTheme>;
};

export type PublicLinkBioBackground = {
  readonly bgColor?: Maybe<Scalars['String']>;
  readonly media?: Maybe<Scalars['String']>;
};

export type PublicLinkBioPageColor = {
  readonly buttonColor?: Maybe<Scalars['String']>;
  readonly buttonFontColor?: Maybe<Scalars['String']>;
  readonly fontColor?: Maybe<Scalars['String']>;
};

export type PublishInfluencerBioInput = {
  readonly isPublic: Scalars['Boolean'];
};

export type PublishInfluencerBioPayload = {
  readonly ok: Scalars['Boolean'];
};

export type Query = {
  readonly _service: _Service;
  /**
   *  Gets a specific account analytics based on the input id
   *  May be used by admin, staff, agency, and advertisers
   *  Multiple social accounts should be expected
   */
  readonly accountAnalyticDetailsV2: AccountAnalyticsDetail;
  /**
   *  Returns basic information of an analytics account
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly accountAnalyticsBasicDetailsV2: BasicAccountAnalyticsPayload;
  readonly address?: Maybe<Address>;
  readonly addressForInfluencer?: Maybe<AddressForInfluencer>;
  readonly advertiser?: Maybe<Advertiser>;
  readonly advertiserForAdvertiserUser?: Maybe<AdvertiserForAdvertiserUser>;
  readonly advertiserUserSettingsDetails?: Maybe<AdvertiserUserSettingsDetails>;
  readonly agency?: Maybe<AgencyWithAdvertisers>;
  readonly agencyBusinessAssociateCompanies?: Maybe<ReadonlyArray<AssociateCompany>>;
  readonly agencyBusinessAssociateCompaniesCount?: Maybe<TotalNumber>;
  readonly agencyLogo?: Maybe<AgencyLogo>;
  readonly aiChatModel: AiChatModelName;
  /**
   *  Returns the total number of analytic accounts.
   *  May be used by admin, staff, and agency
   */
  readonly allAccountAnalyticsCountForAdminV2: TotalNumber;
  /**
   *  Returns a paginated list of analytic accounts.
   *  May be used by admin, staff, and agency
   *  Multiple social accounts should be expected
   */
  readonly allAccountAnalyticsForAdminV2: AllAccountAnalyticsForAdminPayload;
  /**
   *  Returns a paginated list of analytic accounts.
   *  May be used by advertisers
   *  Multiple social accounts should be expected
   */
  readonly allAccountAnalyticsForAdvertiserV2: AllAccountAnalyticsForAdvertiserPayload;
  readonly allAdvertiserPics?: Maybe<ReadonlyArray<AdvertiserUserWithRole>>;
  readonly allAdvertisers?: Maybe<ReadonlyArray<AdvertiserForList>>;
  readonly allAdvertisersCount?: Maybe<TotalNumber>;
  readonly allAdvertisersForSearch: AllAdvertisersForSearch;
  readonly allAgencies?: Maybe<ReadonlyArray<AgencyWithNSVerification>>;
  readonly allAgenciesCount?: Maybe<TotalNumber>;
  readonly allAgenciesForSearch?: Maybe<ReadonlyArray<AgenciesForSearch>>;
  readonly allAgencyUsers?: Maybe<ReadonlyArray<AgencyUserWithRole>>;
  readonly allAnalyticsSubscriptionPlans?: Maybe<ReadonlyArray<AnalyticsSubscriptionPlanName>>;
  readonly allAnycreatorCategories?: Maybe<ReadonlyArray<AnyCreatorCategory>>;
  readonly allApplicantInfluencersForMarketplace?: Maybe<ReadonlyArray<ApplicantInfluencer>>;
  readonly allApplicantInfluencersForMarketplaceCount?: Maybe<TotalNumber>;
  readonly allAvailableCountries: ReadonlyArray<CountryAndCurrency>;
  readonly allBankBranches?: Maybe<ReadonlyArray<Maybe<BankBranch>>>;
  readonly allBankBranchesForInfluencer?: Maybe<ReadonlyArray<Maybe<BankBranchForInfluencer>>>;
  readonly allBanks?: Maybe<ReadonlyArray<Maybe<Bank>>>;
  readonly allBanksForInfluencer?: Maybe<ReadonlyArray<Maybe<BankForInfluencer>>>;
  readonly allCampaignCategories?: Maybe<ReadonlyArray<CampaignCategoryName>>;
  readonly allCampaignPosts: CampaignPosts;
  readonly allCampaignsForFilter: CampaignForPostFilter;
  readonly allCampaignsForSearch?: Maybe<ReadonlyArray<CampaignForSearch>>;
  readonly allCampaignsForYourJob?: Maybe<ReadonlyArray<CampaignForInfluencerForYourJob>>;
  readonly allCampaignsForYourJobCounts?: Maybe<CampaignForInfluencerForYourJobCounts>;
  readonly allCampaignsReport?: Maybe<ReadonlyArray<CampaignDetailReport>>;
  readonly allCampaignsSummary?: Maybe<AllCampaignsReportSummary>;
  readonly allCategories?: Maybe<ReadonlyArray<MajorCategory>>;
  readonly allCities?: Maybe<ReadonlyArray<Maybe<City>>>;
  readonly allCitiesForInfluencer?: Maybe<ReadonlyArray<Maybe<CityForInfluencer>>>;
  readonly allConnectedFacebookPages?: Maybe<ReadonlyArray<ConnectedFacebookPage>>;
  readonly allCountries: ReadonlyArray<CountryAndCurrency>;
  /**  Searches douyin accounts */
  readonly allDouyinInfluencers: DouyinInfluencerSearchResult;
  readonly allEngagementPosts?: Maybe<ReadonlyArray<EngagementPost>>;
  readonly allEngagementPostsCount?: Maybe<TotalNumber>;
  readonly allEngagementPostsForInfluencer?: Maybe<ReadonlyArray<EngagementPostForInfluencer>>;
  readonly allEngagementPostsForInsightCheck?: Maybe<EngagementCampaignPostsForInsightCheck>;
  readonly allEngagements?: Maybe<ReadonlyArray<Engagement>>;
  readonly allEngagementsCount?: Maybe<TotalNumber>;
  readonly allEngagementsForInsightCheck?: Maybe<EngagementsForInsightCheck>;
  readonly allEngagementsForInsightCheckCount?: Maybe<EngagementsForInsightCheckCount>;
  readonly allExistingPackages?: Maybe<ReadonlyArray<ReadPackage>>;
  readonly allFacebookAccountInfluencers?: Maybe<ReadonlyArray<FacebookInfluencer>>;
  readonly allFacebookAccountInfluencersCount?: Maybe<TotalNumber>;
  /**  Searches facebook accounts */
  readonly allFacebookAccountInfluencersV2: FacebookAccountInfluencerSearchResultV2;
  readonly allFacebookPageInfluencers?: Maybe<ReadonlyArray<FacebookPageInfluencer>>;
  readonly allFacebookPageInfluencersCount?: Maybe<TotalNumber>;
  /**  Searches facebook pages */
  readonly allFacebookPageInfluencersV2: FacebookPageInfluencerSearchResultV2;
  readonly allInfluencerAccountPosts?: Maybe<ReadonlyArray<InfluencerSocialPost>>;
  readonly allInfluencerAccountPostsCount?: Maybe<TotalNumber>;
  readonly allInfluencerCategories?: Maybe<ReadonlyArray<CategoryName>>;
  readonly allInfluencerManagers?: Maybe<ReadonlyArray<InfluencerManagerOutput>>;
  readonly allInfluencerPostsV3: InfluencerPostsV3;
  readonly allInfluencersCountForMarketplace?: Maybe<TotalNumber>;
  readonly allInfluencersForMarketplaceV2?: Maybe<ReadonlyArray<MarketplaceInfluencer>>;
  /**  Searches for influencers */
  readonly allInfluencersV4: AllInfluencerV4;
  readonly allInstagramAmbassadorPosts?: Maybe<InstagramBrandAmbassadorPosts>;
  readonly allInstagramAmbassadorPostsCount?: Maybe<TotalNumber>;
  /** Returns a list of feed posts for a specified compare account */
  readonly allInstagramCompareFeedPosts?: Maybe<ReadonlyArray<InstagramCompareFeedPost>>;
  readonly allInstagramCompareFeedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of posts in a specific date(not a date range!)
   *         for the main account and compare accounts
   */
  readonly allInstagramComparePostsInDate?: Maybe<InstagramAnalyticsSummaryPostsInDate>;
  /**
   * Returns Instagram feed posts for a specific analytics account
   *         that were posted in a specified period of time.
   *
   */
  readonly allInstagramFeedPosts?: Maybe<ReadonlyArray<InstagramFeedPost>>;
  readonly allInstagramFeedPostsCount?: Maybe<TotalNumber>;
  readonly allInstagramHashtagFeedPosts?: Maybe<ReadonlyArray<InstagramHashtagFeedPost>>;
  readonly allInstagramHashtagFeedPostsCount?: Maybe<TotalNumber>;
  /**  Searches instagram accounts */
  readonly allInstagramInfluencersV3: InstagramInfluencerSearchResultV3;
  readonly allInstagramInteractionUsers: InstagramInteractionUserAnalyticsDetailsAndCount;
  readonly allInstagramMentionedPosts?: Maybe<ReadonlyArray<InstagramInteractionPost>>;
  readonly allInstagramMentionedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of Instagram story, reel and feed posts of a specific analytics account
   *         that were posted on a specific date .
   */
  readonly allInstagramPostsInDate?: Maybe<InstagramAnalyticsPostsInDate>;
  /**
   * Returns Instagram reel posts for a specific analytics account
   *         that were posted in a specified period of time.
   *
   */
  readonly allInstagramReelPosts?: Maybe<ReadonlyArray<InstagramReelPostItem>>;
  readonly allInstagramReelPostsCount?: Maybe<TotalNumber>;
  /**  Searches similar instagram accounts */
  readonly allInstagramSimilarInfluencersV3: ReadonlyArray<InstagramInfluencerV3>;
  /**
   * Returns Instagram story posts that were posted in a specified period of time
   *         for a specific analytics account.
   */
  readonly allInstagramStoryPosts?: Maybe<ReadonlyArray<InstagramStoryPost>>;
  readonly allInstagramStoryPostsCount?: Maybe<TotalNumber>;
  readonly allInstagramTaggedPosts?: Maybe<ReadonlyArray<InstagramInteractionTaggedPost>>;
  readonly allInstagramTaggedPostsCount?: Maybe<TotalNumber>;
  readonly allMarketplacePosts?: Maybe<ReadonlyArray<MarketplacePost>>;
  readonly allMarketplacePostsCount?: Maybe<TotalNumber>;
  readonly allMarketplaces?: Maybe<ReadonlyArray<Marketplace>>;
  readonly allMarketplacesCount?: Maybe<TotalNumber>;
  readonly allMarketplacesForReorder?: Maybe<ReadonlyArray<MarketplaceForReorder>>;
  readonly allNeedReviewMarketplacePosts?: Maybe<ReadonlyArray<NeedReviewMarketplacePost>>;
  readonly allPackageCompanies?: Maybe<ReadonlyArray<PackageCompany>>;
  readonly allPackages?: Maybe<ReadonlyArray<ReadPackageAccount>>;
  readonly allPackagesCount?: Maybe<TotalNumber>;
  readonly allPartnerAgenciesCount?: Maybe<TotalNumber>;
  readonly allPartnerAgencyCompany?: Maybe<ReadonlyArray<PartnerAgencyWithNSVerification>>;
  readonly allPartnerAgencyUsers?: Maybe<ReadonlyArray<PartnerAgencyUserWithRole>>;
  readonly allPartners?: Maybe<ReadonlyArray<Partner>>;
  readonly allPayments?: Maybe<ReadonlyArray<Payment>>;
  readonly allPaymentsCount?: Maybe<TotalNumber>;
  readonly allPicUsers?: Maybe<ReadonlyArray<PicUser>>;
  readonly allRegions?: Maybe<ReadonlyArray<Maybe<Region>>>;
  readonly allStaffs?: Maybe<ReadonlyArray<AllStaffDetail>>;
  readonly allStaffsCount?: Maybe<TotalNumber>;
  readonly allTalentAgencies?: Maybe<ReadonlyArray<TalentAgency>>;
  readonly allTalentAgenciesCount?: Maybe<TotalNumber>;
  readonly allTalentAgenciesPartners?: Maybe<ReadonlyArray<TalentAgencyOrPartner>>;
  readonly allTalentAgencyCompany?: Maybe<ReadonlyArray<TalentAgencyWithNSVerification>>;
  readonly allTalentAgencyUsers?: Maybe<ReadonlyArray<TalentAgencyUserWithRole>>;
  readonly allThreadsInfluencers?: Maybe<ReadonlyArray<ThreadsInfluencer>>;
  readonly allThreadsInfluencersCount?: Maybe<TotalNumber>;
  readonly allTiktokAdReportSummary?: Maybe<AllTiktokAdReportSummary>;
  readonly allTiktokAdsReport?: Maybe<ReadonlyArray<TiktokAdDetailReport>>;
  readonly allTiktokInfluencers?: Maybe<ReadonlyArray<TiktokInfluencer>>;
  readonly allTiktokInfluencersCount?: Maybe<TotalNumber>;
  /**  Searches tiktok accounts */
  readonly allTiktokInfluencersV2: TiktokInfluencerSearchResultV2;
  readonly allTiktokProductCategory: ReadonlyArray<TikTokProductCategory>;
  readonly allTiktokSimilarInfluencers?: Maybe<ReadonlyArray<TiktokInfluencer>>;
  readonly allTiktokSpecialCampaigns?: Maybe<ReadonlyArray<TikTokSpecialCampaign>>;
  readonly allTiktokSpecialCampaignsCount?: Maybe<TotalNumber>;
  readonly allTwitterInfluencers?: Maybe<ReadonlyArray<TwitterInfluencer>>;
  readonly allTwitterInfluencersCount?: Maybe<TotalNumber>;
  readonly allTwitterSimilarInfluencers?: Maybe<ReadonlyArray<TwitterInfluencer>>;
  readonly allUsers?: Maybe<ReadonlyArray<AllUserDetail>>;
  readonly allUsersCount?: Maybe<TotalNumber>;
  readonly allXHSInfluencers: XhsInfluencerSearchResult;
  readonly allYoutubeCmsConfirmedAssets?: Maybe<ReadonlyArray<YoutubeCmsConfirmedAsset>>;
  readonly allYoutubeCmsConfirmedAssetsCount?: Maybe<TotalNumber>;
  readonly allYoutubeCmsConfirmedChannels?: Maybe<ReadonlyArray<YoutubeCmsConfirmedChannel>>;
  readonly allYoutubeCmsConfirmedChannelsCount?: Maybe<TotalNumber>;
  readonly allYoutubeCmsEstimateAssets?: Maybe<ReadonlyArray<YoutubeCmsEstimateAsset>>;
  readonly allYoutubeCmsEstimateAssetsCount?: Maybe<TotalNumber>;
  readonly allYoutubeCmsEstimateChannels?: Maybe<ReadonlyArray<YoutubeCmsEstimateChannel>>;
  readonly allYoutubeCmsEstimateChannelsCount?: Maybe<TotalNumber>;
  /**  Searches youtube accounts */
  readonly allYoutubeInfluencersV3: YoutubeInfluencerSearchResultV3;
  readonly allYoutubeSimilarInfluencers?: Maybe<ReadonlyArray<YouTubeInfluencer>>;
  /**
   *  Returns a URL that FE uses to communicate with social network APIs.
   *  May be used by anyone with no authentication.
   */
  readonly analyticsSocialAuthRedirectUrl?: Maybe<AnalyticsSocialAuthRedirectUrlPayload>;
  readonly analyticsSubscriptionPlan?: Maybe<AnalyticsSubscriptionPlan>;
  readonly analyticsSubscriptionPlanByAdvId?: Maybe<AnalyticsSubscriptionPlan>;
  readonly anyXCheckConnectedSocialAccounts?: Maybe<AnyXCheckConnectedSocialAccountsPayload>;
  readonly anyXCheckConnectedSocialAccountsForLinkBio?: Maybe<AnyXCheckConnectedSocialAccountsForLinkBioPayload>;
  readonly autoManagedCommissionFixedDates?: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly autoManagedCommissionInfluencers?: Maybe<ReadonlyArray<AutoManagedCommissionInfluencer>>;
  readonly autoManagedCommissionInfluencersCounts?: Maybe<TotalNumber>;
  readonly autoManagedJoinedInfluencers?: Maybe<ReadonlyArray<AutoManagedJoinedInfluencer>>;
  readonly autoManagedJoinedInfluencersCount?: Maybe<TotalNumber>;
  readonly autoManagedJoinedMethods?: Maybe<AutoManagedJoinedMethodsPayload>;
  readonly autoManagedJoinedMethodsV2?: Maybe<AutoManagedJoinedMethodsPayloadV2>;
  readonly autoManagedReportByDate?: Maybe<ReadonlyArray<AutoManagedReportByDate>>;
  readonly autoManagedReportByDateCount?: Maybe<TotalNumber>;
  readonly autoManagedReportByInfluencer?: Maybe<ReadonlyArray<AutoManagedReportByInfluencer>>;
  readonly autoManagedReportByInfluencerCount?: Maybe<TotalNumber>;
  readonly autoManagedReportCountForInfluencer?: Maybe<TotalNumber>;
  readonly autoManagedReportForInfluencer?: Maybe<ReadonlyArray<AutoManagedReportForInfluencer>>;
  readonly autoManagedReportSummary?: Maybe<AutoManagedReportSummary>;
  readonly autoManagedReportSummaryForInfluencer?: Maybe<AutoManagedReportSummaryForInfluencer>;
  readonly billingInformation?: Maybe<BillingInformation>;
  readonly brandAccounts?: Maybe<InfluencerBrandAccounts>;
  readonly campaignCheckoutSessionLink?: Maybe<StripeDeepLink>;
  readonly campaignHashtagCloud?: Maybe<ReadonlyArray<HashtagType>>;
  readonly campaignsChartForAllTime: ReadonlyArray<CampaignChartReportForAllTime>;
  readonly checkBioLinkNameAvailability?: Maybe<CheckBioLinkNameAvailabilityPayload>;
  readonly checkoutSessionLink?: Maybe<StripeDeepLink>;
  readonly cmsInvoiceDownloadUrl?: Maybe<YoutubeCmsInvoiceDownloadUrl>;
  readonly connectableFacebookPages?: Maybe<ConnectableFacebookPagesPayload>;
  readonly connectableFacebookPagesAndInstagramAccounts?: Maybe<ConnectableFacebookPagesAndInstagramAccountsPayload>;
  readonly connectableInstagramAccounts?: Maybe<ConnectableInstagramAccountsPayload>;
  /**
   *  `countUnreadNotifications` query gets the count of notifications that are not read.
   *  The user is known thorugh the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  readonly countUnreadNotifications?: Maybe<UnreadNotificationCountPayload>;
  /**
   *  `countUnreadNotificationsByApp` query gets the count of notifications that are not read of specific application.
   *  The user is known thorugh the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  readonly countUnreadNotificationsByApp?: Maybe<UnreadNotificationCountByAppPayload>;
  readonly creatorAddressInformation?: Maybe<CreatorAddressPayload>;
  readonly customerPortalLink?: Maybe<StripeDeepLink>;
  readonly douyinAccountInterest?: Maybe<DouyinAccountInterest>;
  readonly douyinProfileAudience?: Maybe<DouyinAudienceSection>;
  readonly engagement?: Maybe<EngagementDetail>;
  readonly engagementCampaignPostReportAudienceBreakdown?: Maybe<EngagementCampaignPostReportAudienceBreakdown>;
  readonly engagementCampaignPostReportForInfluencer?: Maybe<EngagementCampaignPostReportForInfluencer>;
  readonly engagementCampaignReport?: Maybe<EngagementCampaignReport>;
  readonly engagementCampaignReportGrid?: Maybe<CampaignGridReport>;
  readonly engagementCampaignReportSummary?: Maybe<NewCampaignReportSummary>;
  readonly engagementCampaignTrafficSource?: Maybe<ReadonlyArray<EngagementPostTrafficSource>>;
  /** Returns history list for a specific engagement draft post */
  readonly engagementDraftPostHistory?: Maybe<EngagementDraftPostHistory>;
  /** Returns history list for a specific engagement draft post for influencer */
  readonly engagementDraftPostHistoryForInfluencer?: Maybe<EngagementDraftPostHistoryForInfluencer>;
  readonly engagementForInfluencer?: Maybe<EngagementDetailForInfluencer>;
  readonly engagementHashtagCloud?: Maybe<ReadonlyArray<HashtagType>>;
  readonly engagementPostComments?: Maybe<ReadonlyArray<EngagementPostComment>>;
  readonly engagementPostDetail?: Maybe<EngagementPostDetail>;
  /**
   *
   *                                                            To fetch Engagement post details to show on Influencer platform
   *
   */
  readonly engagementPostDetailForInfluencer?: Maybe<EngagementPostDetailForInfluencer>;
  readonly engagementPostForInfluencerV2?: Maybe<EngagementPostDetailForInfluencerV2>;
  readonly engagementPostInfluencers?: Maybe<ReadonlyArray<EngagementPostInfluencerWithSocialAccounts>>;
  readonly engagementPostMetricsForInsightCheck?: Maybe<EngagementPostMetricsDataForInsightCheck>;
  readonly engagementPostSocialAccountsForInfluencer?: Maybe<ReadonlyArray<EngagementPostSocialAccount>>;
  readonly engagementPostTrafficSource?: Maybe<ReadonlyArray<EngagementPostTrafficSource>>;
  readonly engagementPostV2?: Maybe<EngagementPostEditV2>;
  readonly engagementProposal?: Maybe<EngagementProposal>;
  readonly engagementTracking?: Maybe<EngagementTracking>;
  readonly engagementXhsPostMetricsForInsight?: Maybe<EngagementXhsPostMetricsDataForInsight>;
  readonly existingProfileTags?: Maybe<ExistingProfileTags>;
  /**
   * Returns Instagram feed posts for a specified external analytics account
   *             that were posted in a specified period of time.
   *
   */
  readonly externalAllInstagramFeedPosts?: Maybe<ReadonlyArray<ExternalInstagramFeedPost>>;
  readonly externalAllInstagramFeedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of Instagram story, reel and feed posts that were posted on a specified date
   *             made by a given external analytics account.
   */
  readonly externalAllInstagramPostsInDate?: Maybe<ExternalInstagramAnalyticsPostsInDate>;
  /**
   * Returns Instagram reel posts for a specified external analytics account
   *             that were posted in a specified period of time.
   *
   */
  readonly externalAllInstagramReelPosts?: Maybe<ReadonlyArray<ExternalInstagramReelPost>>;
  readonly externalAllInstagramReelPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns Instagram story posts that were posted in a specified period of time
   *             for a specified external analytics account.
   */
  readonly externalAllInstagramStoryPosts?: Maybe<ReadonlyArray<ExternalInstagramStoryPost>>;
  readonly externalAllInstagramStoryPostsCount?: Maybe<TotalNumber>;
  /**
   *  Returns basic FB account details
   *  May be used by external users only
   */
  readonly externalAnalyticsFacebookGetAccountDetails: ExternalAnalyticsFacebookGetAccountDetailsOutput;
  /**
   *  Checks if reconnection is needed
   *  May be used by external users only
   */
  readonly externalAnalyticsFacebookGetTokenWarnings: ExternalAnalyticsFacebookGetTokenWarningsOutput;
  /**
   *  Returns basic IG account details
   *  May be used by external users only
   */
  readonly externalAnalyticsInstagramGetAccountDetails: ExternalAnalyticsInstagramGetAccountDetailsOutput;
  /**
   *  Checks if reconnection is needed
   *  May be used by external users only
   */
  readonly externalAnalyticsInstagramGetTokenWarnings: ExternalAnalyticsInstagramGetTokenWarningsOutput;
  /**
   *  Returns a URL that FE uses to communicate with social network APIs.
   *  May be used by external users only.
   */
  readonly externalAnalyticsSocialAuthRedirectUrl?: Maybe<ExternalAnalyticsSocialAuthRedirectUrlPayload>;
  /**
   *  Returns the audience of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by external users
   */
  readonly externalFacebookPageAnalyticsAudience: FacebookPageAnalyticsAudiencePayload;
  /**  Returns the overview data of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period */
  readonly externalFacebookPageAnalyticsOverview: FacebookPageAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `facebookPageAnalyticsAccountId`
   *  May be used by external users only
   */
  readonly externalFacebookPageAnalyticsPost: FacebookPageAnalyticsPostPayload;
  /**
   *  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by external users only
   */
  readonly externalFacebookPageAnalyticsPosts: FacebookPageAnalyticsPostsPayloads;
  /**
   *  Returns the statistics of the posts by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by external users only
   */
  readonly externalFacebookPageAnalyticsPostsAnalytics: FacebookPageAnalyticsPostsAnalyticsPayload;
  /**  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId` by date */
  readonly externalFacebookPageAnalyticsPostsByDate: FacebookPageAnalyticsPostsByDatePayload;
  /**
   *  Returns the total count of posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by external users
   */
  readonly externalFacebookPageAnalyticsPostsCount: TotalNumber;
  /**
   * Returns details for a specified Instagram feed post. For example, the content,
   *             comments, and etc.
   *
   */
  readonly externalInstagramAnalyticsPostDetails?: Maybe<ExternalInstagramAnalyticsPostDetails>;
  /** Returns details for a specific Instagram reel post. For external users only. */
  readonly externalInstagramAnalyticsReelDetails?: Maybe<ExternalInstagramAnalyticsReelDetails>;
  /** Returns details of a specified Instagram story post made by an external analytics account. */
  readonly externalInstagramAnalyticsStoryDetails?: Maybe<ExternalInstagramAnalyticsStoryDetails>;
  /**
   * Returns follower demographics analytics information for a specified external analytics account
   *             in a specified period of time. For example, age-gender distribution, followers countries, and etc.
   */
  readonly externalInstagramDashboardFollowerAnalytics?: Maybe<ExternalInstagramDashboardFollowerAnalytics>;
  /**
   * Returns the main analytics information for a specified external analytics account
   *             in a specified period of time. For example, the number of followers, posting frequency/activity,
   *             comments growth, and etc.
   */
  readonly externalInstagramDashboardOverview?: Maybe<ExternalInstagramDashboardOverview>;
  /**
   * Returns Instagram posts analytics data for a specified external analytics account
   *             in a specified period of time. For example, posting habits, last feed and story posts, and etc.
   */
  readonly externalInstagramDashboardPostAnalytics?: Maybe<ExternalInstagramDashboardPostAnalytics>;
  /**
   *  Returns the audience of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by external
   */
  readonly externalYoutubeAnalyticsAudience: ExternalYoutubeAnalyticsAudiencePayload;
  /**
   *  Returns the overview data of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by external
   */
  readonly externalYoutubeAnalyticsOverview: ExternalYoutubeAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `youtubeAnalyticsPostId`
   *  May be used by external
   *  Views can be larger than 32-bit, please keep note
   */
  readonly externalYoutubeAnalyticsPost: ExternalYoutubePostPayload;
  /**
   *  Returns a list of the posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by external
   *  Views can be larger than 32-bit, please keep note
   */
  readonly externalYoutubeAnalyticsPosts: ExternalYoutubeAnalyticsPostListPayload;
  /**  Returns posts for external user for account `socialAccountId` */
  readonly externalYoutubeAnalyticsPostsByDate: ExternalYoutubeAnalyticsPostListByDatePayload;
  /**
   *  Returns the total count of posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by external
   */
  readonly externalYoutubeAnalyticsPostsCount: TotalNumber;
  /**
   *  Returns the statistics of the posts by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by external
   */
  readonly externalYoutubeAnalyticsPostsStatistics: ExternalYoutubeAnalyticsPostsStatisticsPayload;
  /**
   *  Returns the related posts with the same tag of the same analytics account
   *  May be used by external
   *  `youtubeAnalyticsPostId` is the id of the current shown post. This will be excluded from the result
   */
  readonly externalYoutubeAnalyticsRelatedPosts: ExternalYoutubeAnalyticsRelatedPostsPayload;
  /**
   *  Returns a paginated list of the tags sorted by the specified field.
   *  May be used by external
   *  views can be larger than 32-bit, please keep note.
   */
  readonly externalYoutubeAnalyticsTagsTopRanking: ExternalYoutubeAnalyticsTagRankingPayloads;
  /**
   *  Returns the total count of the tags specified by the inputs
   *  May be used by external
   *  views can be larger than 32-bit, please keep note.
   */
  readonly externalYoutubeAnalyticsTagsTopRankingCount: TotalNumber;
  /**
   *  Returns the audience of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly facebookPageAnalyticsAudience: FacebookPageAnalyticsAudiencePayload;
  /**  Returns the overview data of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period */
  readonly facebookPageAnalyticsOverview: FacebookPageAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `facebookPageAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly facebookPageAnalyticsPost: FacebookPageAnalyticsPostWithPageDetailsPayload;
  /**
   *  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly facebookPageAnalyticsPosts: FacebookPageAnalyticsPostsPayloads;
  /**
   *  Returns the statistics of the posts by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly facebookPageAnalyticsPostsAnalytics: FacebookPageAnalyticsPostsAnalyticsPayload;
  /**  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId` by date */
  readonly facebookPageAnalyticsPostsByDate: FacebookPageAnalyticsPostsByDatePayload;
  /**
   *  Returns the total count of posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly facebookPageAnalyticsPostsCount: TotalNumber;
  readonly findJoinedCampaigns?: Maybe<ReadonlyArray<JoinedCampaign>>;
  readonly generateAgencyLogoSignedUrl: FileUploadUrls;
  readonly generateImportFansUploadURL?: Maybe<GenerateImportFansUploadURLPayload>;
  readonly generateMaterialSignedUrl: FileUploadUrls;
  readonly generatePostFileSignedUrl?: Maybe<SignedUrlData>;
  readonly generatePostFileSignedUrls: FileUploadUrls;
  readonly generateThumbnailSignedUrl: FileUploadUrls;
  readonly generateUploadReportSignedUrl: FileUploadUrls;
  readonly getAccountInformation?: Maybe<GetAccountInformationPayload>;
  readonly getAllCampaignsCountForSearchJob?: Maybe<GetAllCampaignsCountForSearchJobPayload>;
  readonly getAllCampaignsForSearchJob?: Maybe<GetAllCampaignsForSearchJobPayload>;
  readonly getAnyXAccountsByKeyword?: Maybe<GetAnyXAccountsByKeywordPayload>;
  readonly getAutoManagedCampaignById?: Maybe<AutoManagedCampaignPayload>;
  /**  query to get AM campaign detail from search jobs. Id input is remote campaign id */
  readonly getAutoManagedCampaignForInfluencerSearchJob?: Maybe<AutoManagedCampaignForInfluencerSearchJobPayload>;
  /**  query to get AM campaign detail from your jobs. Id input is local campaign id */
  readonly getAutoManagedCampaignForInfluencerYourJob?: Maybe<AutoManagedCampaignForInfluencerYourJobPayload>;
  readonly getAutoManagedCampaigns?: Maybe<GetAutoManagedCampaignsPayload>;
  readonly getAutoManagedCampaignsCount?: Maybe<GetAutoManagedCampaignsCountPayload>;
  readonly getAvailableInfluencersForStaff?: Maybe<AvailableInfluencersForStaffPayload>;
  readonly getChatEvents?: Maybe<GetChatEventsPayload>;
  readonly getCouponsList?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly getCreatorStaffs?: Maybe<CreatorStaffsPayload>;
  readonly getCreatorStaffsCount?: Maybe<CreatorStaffsCountPayload>;
  readonly getCreatorTags?: Maybe<GetCreatorTagsPayload>;
  readonly getCreatorTagsById?: Maybe<GetCreatorTagsByIdPayload>;
  readonly getCurrentUserCurrency: CurrentUserCurrency;
  readonly getCustomerTags?: Maybe<GetCustomerTagsPayload>;
  readonly getEmailList?: Maybe<GetEmailListPayload>;
  readonly getEmailListCount?: Maybe<GetEmailListCountPayload>;
  readonly getEmailOverview?: Maybe<GetEmailOverviewPayload>;
  readonly getEmailRecipients?: Maybe<GetEmailRecipientsPayload>;
  readonly getEmailRecipientsCount?: Maybe<GetEmailRecipientsCountPayload>;
  readonly getEmailTemplate?: Maybe<GetEmailTemplatePayload>;
  readonly getFan?: Maybe<GetFanPayload>;
  readonly getFanActivities?: Maybe<GetFanActivitiesPayload>;
  readonly getFanCountries?: Maybe<CountriesPayload>;
  readonly getFanFormResponse?: Maybe<GetFanFormResponsePayload>;
  readonly getFanHeader?: Maybe<GetFanHeaderPayload>;
  readonly getFanOrders?: Maybe<GetFanOrdersPayload>;
  readonly getFanParticipationFormList?: Maybe<GetFanParticipationFormListPayload>;
  readonly getFanProvinces?: Maybe<ProvincesPayload>;
  readonly getFans?: Maybe<GetFansPayload>;
  readonly getFansCount?: Maybe<GetFansCountPayload>;
  readonly getForm?: Maybe<GetFormInputPayload>;
  readonly getFormReportSummary?: Maybe<FormReportSummaryPayload>;
  readonly getFormRespondentsCount?: Maybe<FormRespondentsCountPayload>;
  readonly getFormRespondentsList?: Maybe<FormRespondentsListPayload>;
  readonly getFormResponse?: Maybe<GetFormResponsePayload>;
  readonly getFormResponseByPage?: Maybe<GetFormResponsePayload>;
  readonly getFormTemplate?: Maybe<GetFormTemplatePayload>;
  readonly getFormsCount?: Maybe<GetFormsCountPayload>;
  readonly getFormsList?: Maybe<GetFormsListPayload>;
  readonly getGeneratedMarketplaceCampaignDetail?: Maybe<GetGeneratedMarketplaceCampaignDetailPayload>;
  readonly getGeneratedMarketplacePostCaption?: Maybe<GetGeneratedMarketplacePostCaptionPayload>;
  readonly getGeneratedTikTokSpecialPostCaption?: Maybe<GetGeneratedTikTokSpecialPostCaptionPayload>;
  readonly getInfluencerBio?: Maybe<GetInfluencerBioPayload>;
  readonly getInfluencerBioAnalytics?: Maybe<GetInfluencerBioAnalyticsPayload>;
  readonly getInfluencerBioV2?: Maybe<GetInfluencerBioPayloadV2>;
  readonly getInfluencerChatServices?: Maybe<GetInfluencerChatServicesPayload>;
  readonly getInfluencerRateCard?: Maybe<InfluencerRateCardPayload>;
  readonly getInfluencerRateCardForInfluencer?: Maybe<InfluencerRateCardPayload>;
  readonly getLineBroadcastEstimateRecipients?: Maybe<GetLineBroadcastEstimateRecipientsPayload>;
  readonly getLineBroadcastMessage?: Maybe<GetLineBroadcastMessagePayload>;
  readonly getLineBroadcastSummary?: Maybe<GetLineBroadcastSummaryPayload>;
  readonly getLineBroadcasts?: Maybe<GetLineBroadcastsPayload>;
  readonly getLineBroadcastsCount?: Maybe<GetLineBroadcastsCountPayload>;
  readonly getLineChannels?: Maybe<GetLineChannelPayload>;
  readonly getLineChat?: Maybe<GetLineChatPayload>;
  readonly getLineChatCount?: Maybe<GetLineChatCountPayload>;
  readonly getLineChats?: Maybe<GetLineChatsPayload>;
  readonly getLineLoginDetail?: Maybe<GetLineLoginDetailPayload>;
  readonly getLinePublicKey?: Maybe<GetLinePublicKeyPayload>;
  readonly getLinkBioFacebookAccountPosts?: Maybe<GetLinkBioFacebookAccountPostsPayload>;
  readonly getLinkBioFacebookPagePosts?: Maybe<GetLinkBioFacebookPagePostsPayload>;
  readonly getLinkBioThemeColor?: Maybe<GetLinkBioThemeColorPayload>;
  readonly getMarketplaceCampaignForInfluencer?: Maybe<MarketplaceDetailForInfluencer>;
  readonly getMessageTemplates?: Maybe<GetMessageTemplatesPayload>;
  /**
   *  `getNotifications` query gets the notificataions of an  influencer user.
   *  The user is known through the `Authorization` header.
   */
  readonly getNotifications?: Maybe<NotificationsPayload>;
  /**
   *  `getNotificationsByApp` query gets the notificataions a specific application type of an  influencer user.
   *  The user is known through the `Authorization` header.
   */
  readonly getNotificationsByApp?: Maybe<NotificationsPayload>;
  readonly getPersonalUserDetail?: Maybe<PersonalUserDetailPayload>;
  readonly getPublicInfluencerBio?: Maybe<GetPublicInfluencerBioPayload>;
  readonly getReadTutorials?: Maybe<ReadTutorials>;
  readonly getShopifyAccount?: Maybe<GetShopifyAccountPayload>;
  /**  Returns required permissions for each social network */
  readonly getSocialAccountPermissions: ReadonlyArray<Scalars['String']>;
  readonly getTagsAndFansRecipients?: Maybe<GetTagsAndFansRecipientsPayload>;
  readonly getUserDefaultPermissions?: Maybe<UserDefaultPermissions>;
  readonly getUserDetail?: Maybe<UserDetailPayload>;
  readonly getUserPermissions?: Maybe<UserDefaultPermissions>;
  readonly getYoutubeMCNRedirectUrlForAdmin?: Maybe<YoutubeMCNAdminRedirectUrlPayload>;
  readonly hubspotCompanyList?: Maybe<ReadonlyArray<HubspotCompanyData>>;
  readonly hubspotCompanyName?: Maybe<HubspotCompanyData>;
  readonly hubspotDealName?: Maybe<ReadonlyArray<HubspotDealDataForSearch>>;
  readonly hubspotDealsList?: Maybe<ReadonlyArray<HubspotDealData>>;
  /**
   * Returns Instagram feed posts for a specified influencer analytics account
   *             that were posted in a specified period of time.
   *
   */
  readonly influencerAllInstagramFeedPosts?: Maybe<ReadonlyArray<InstagramFeedPost>>;
  readonly influencerAllInstagramFeedPostsCount?: Maybe<TotalNumber>;
  readonly influencerAllInstagramInteractionUsers: InstagramInteractionUserAnalyticsDetailsAndCount;
  readonly influencerAllInstagramMentionedPosts?: Maybe<ReadonlyArray<InstagramInteractionPost>>;
  readonly influencerAllInstagramMentionedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of Instagram story, reel and feed posts that were posted on a specified date
   *             made by a given influencer analytics account.
   */
  readonly influencerAllInstagramPostsInDate?: Maybe<InstagramAnalyticsPostsInDate>;
  /**
   * Returns Instagram reel posts for a specified influencer analytics account
   *             that were posted in a specified period of time.
   *
   */
  readonly influencerAllInstagramReelPosts?: Maybe<ReadonlyArray<InstagramReelPostItem>>;
  readonly influencerAllInstagramReelPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns Instagram story posts that were posted in a specified period of time
   *             for a specified influencer analytics account.
   */
  readonly influencerAllInstagramStoryPosts?: Maybe<ReadonlyArray<InstagramStoryPost>>;
  readonly influencerAllInstagramStoryPostsCount?: Maybe<TotalNumber>;
  readonly influencerAllInstagramTaggedPosts?: Maybe<ReadonlyArray<InstagramInteractionTaggedPost>>;
  readonly influencerAllInstagramTaggedPostsCount?: Maybe<TotalNumber>;
  /**  Checks if reconnection is needed + provides data for successfull reconnection for influencers */
  readonly influencerAnalyticsFacebookPageAccessTokenWarning: InfluencerFacebookPageAnalyticsAccessTokenWarningPayload;
  /**  Checks if reconnection is needed + provides data for successfull reconnection for influencers */
  readonly influencerAnalyticsInstagramAccessTokenWarning: InfluencerInstagramAnalyticsAccessTokenWarningPayload;
  /**  Checks if reconnection is needed + provides data for successfull reconnection for multiple influencer IG accounts */
  readonly influencerAnalyticsMultipleInstagramAccessTokenWarning: InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload;
  /**  Checks if reconnection is needed for twitter accounts */
  readonly influencerAnalyticsTwitterAccessTokenWarning: InfluencerTwitterAnalyticsAccessTokenWarningPayload;
  /**
   *  Returns true if twitter account available to update
   *  May be used by admin, staff, agency, advertisers, and influencers
   */
  readonly influencerAnalyticsTwitterRefreshAvailability: InfluencerAnalyticsTwitterRefreshAvailabilityPayload;
  /** List of joined campaign for influencers */
  readonly influencerCampaignList?: Maybe<ReadonlyArray<InfluencerCampaignDetail>>;
  /** List of joined MKP and Auto-Managed campaign for influencers independently by promotion method id */
  readonly influencerCampaignListByPromotionMethod?: Maybe<ReadonlyArray<InfluencerCampaignDetailByPromotion>>;
  /** campaigns summary for influencer */
  readonly influencerCampaignSummary: InfluencerCampaignSummary;
  readonly influencerFacebookPageAnalyticsAudience: FacebookPageAnalyticsAudiencePayload;
  /**
   *  Returns the overview data of the influencer facebook page account by `socialAccountId` and `influencerId`
   *  within the specified period
   */
  readonly influencerFacebookPageAnalyticsOverview: FacebookPageAnalyticsOverviewPayload;
  /**  Returns a post specified by `socialAccountId`, `influencerId` and `postId` */
  readonly influencerFacebookPageAnalyticsPost: FacebookPageAnalyticsPostWithPageDetailsPayload;
  /**  Returns a list of the posts made by the account specified by `socialAccountId`, `influencerId` and `postId` */
  readonly influencerFacebookPageAnalyticsPosts: FacebookPageAnalyticsPostsPayloads;
  /**
   *  Returns the statistics of the posts by `influencerFacebookPageAnalyticsPostsAnalytics` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly influencerFacebookPageAnalyticsPostsAnalytics: FacebookPageAnalyticsPostsAnalyticsPayload;
  /**  Returns a list of the posts made by the account specified by `socialAccountId`, `influencerId` and by date */
  readonly influencerFacebookPageAnalyticsPostsByDate: FacebookPageAnalyticsPostsByDatePayload;
  /**  Returns the total count of posts made by the account specified by `socialAccountId`, `influencerId` and `postId` */
  readonly influencerFacebookPageAnalyticsPostsCount: TotalNumber;
  /** checking social account has estimate api */
  readonly influencerHasEstimateApi: InfluencerHasEstimateApi;
  /**
   * Returns details for a specified Instagram feed post. For example, the content,
   *             comments, and etc.
   */
  readonly influencerInstagramAnalyticsPostDetails?: Maybe<InstagramAnalyticsPostDetails>;
  /** Returns details for a specific Instagram reel post. For influencers only. */
  readonly influencerInstagramAnalyticsReelDetails?: Maybe<InstagramAnalyticsReelDetails>;
  /** Returns details of a specified Instagram story post made by an influencer analytics account. */
  readonly influencerInstagramAnalyticsStoryDetails?: Maybe<InstagramAnalyticsStoryDetails>;
  /**
   * Returns follower demographics analytics information for a specified influencer analytics account
   *             in a specified period of time. For example, age-gender distribution, followers countries, and etc.
   */
  readonly influencerInstagramDashboardFollowerAnalytics?: Maybe<InstagramDashboardFollowerAnalytics>;
  /**
   * Returns the main analytics information for a specified influencer analytics account
   *             in a specified period of time. For example, the number of followers, posting frequency/activity,
   *             comments growth, and etc.
   */
  readonly influencerInstagramDashboardOverview?: Maybe<InstagramDashboardOverview>;
  /**
   * Returns Instagram posts analytics data for a specified influencer analytics account
   *             in a specified period of time. For example, posting habits, last feed and story posts, and etc.
   */
  readonly influencerInstagramDashboardPostAnalytics?: Maybe<InstagramDashboardPostAnalytics>;
  readonly influencerInstagramInteractionMentionedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  readonly influencerInstagramInteractionOverview?: Maybe<InstagramInteractionOverview>;
  readonly influencerInstagramInteractionPosts?: Maybe<InstagramInteractionPosts>;
  readonly influencerInstagramInteractionTaggedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  readonly influencerInterests?: Maybe<ReadonlyArray<InfluencerInterest>>;
  readonly influencerMultipleSocialAccountsCount?: Maybe<TotalNumber>;
  readonly influencerPaymentInformation?: Maybe<InfluencerPaymentInformation>;
  readonly influencerPaymentRequests: InfluencerPaymentRequests;
  readonly influencerPostDetail?: Maybe<InfluencerPostDetail>;
  readonly influencerPostDetailForTalentAgency?: Maybe<InfluencerPostDetail>;
  readonly influencerProfileAudience?: Maybe<InfluencerAudienceSection>;
  readonly influencerProfileDetailForEngagementProposal?: Maybe<InfluencerProfileDetailForEngagementProposal>;
  readonly influencerProfileDetailV2?: Maybe<InfluencerNewProfileDetail>;
  readonly influencerProfileFollowerGrowth?: Maybe<InfluencerFollowerGrowthSection>;
  readonly influencerProfileForEngagementProposal?: Maybe<InfluencerProfileForEngagementProposal>;
  readonly influencerProfilePosts?: Maybe<InfluencerPostSection>;
  readonly influencerProfileV2?: Maybe<InfluencerNewProfileHeaderV2>;
  /**  query to return list of influencer connected promotion methods */
  readonly influencerPromotionMethods?: Maybe<InfluencerPromotionMethodsPayload>;
  /**
   *  Returns the overview data of the twitter account with specified `socialAccountId` and specified period
   *  May be used by admin, staff, agency, advertisers, influencers
   */
  readonly influencerTwitterAnalyticsOverview: TwitterAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `socialAccountId` and `twitterAnalyticsPostId`
   *  May be used by admin, staff, agency, and advertisers, influencers
   */
  readonly influencerTwitterAnalyticsPost: TwitterAnalyticsPostByIdPayload;
  /**
   *  Returns a list of the posts made by the account with specified `socialAccountId`
   *  May be used by admin, staff, agency, advertisers, influencers
   */
  readonly influencerTwitterAnalyticsPosts: TwitterAnalyticsPostsPayload;
  /**  Returns the posts that are made on the date */
  readonly influencerTwitterAnalyticsPostsByDate: TwitterAnalyticsPostsByDatePayload;
  /**  Returns the total count of posts made for influencer account `socialAccountId` */
  readonly influencerTwitterAnalyticsPostsCount: TotalNumber;
  /**  Posts section for twitter dashboard for influencer account `socialAccountId` */
  readonly influencerTwitterAnalyticsPostsStatistic?: Maybe<TwitterAnalyticsPostsStatisticPayload>;
  readonly influencerUpdateDetailV2?: Maybe<InfluencerUpdateDetailV2>;
  readonly influencerWhitelist?: Maybe<InfluencerWhitelist>;
  /**  Returns the audience of the youtube account by `socialAccountId` within the specified period */
  readonly influencerYoutubeAnalyticsAudience: YoutubeAnalyticsAudiencePayload;
  /**
   *  Returns basic details of a youtube channel by a given 'socialAccountId'.
   *  May be used for influencers only
   */
  readonly influencerYoutubeAnalyticsChannelDetails: InfluencerYoutubeAnalyticsChannelDetailsPayload;
  /**  Returns a list of Youtube accounts compared with account `socialAccountId`. For influencers only. */
  readonly influencerYoutubeAnalyticsCompareAccountCards: InfluencerYoutubeAnalyticsCompareAccountCards;
  /**
   *  Returns the related posts with the same tag of the same compare account. For influencers only
   *  `postId` is the id of the current shown post. This will be excluded from the result
   */
  readonly influencerYoutubeAnalyticsCompareRelatedPosts: YoutubeAnalyticsCompareRelatedPosts;
  /**  Returns the overview data of the youtube account by `socialAccountId` within the specified period for influencers */
  readonly influencerYoutubeAnalyticsOverview: YoutubeAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `socialAccountId` for influencers
   *  Views can be larger than 32-bit, please keep note
   */
  readonly influencerYoutubeAnalyticsPost: YoutubeAnalyticsPostWithChannelDetailsPayload;
  /**  Returns a list of the posts made by the account `socialAccountId` for influencers */
  readonly influencerYoutubeAnalyticsPosts: YoutubeAnalyticsPostsPayloads;
  /**  Returns the posts that are made on the date for influencers */
  readonly influencerYoutubeAnalyticsPostsByDate: YoutubeAnalyticsPostPayloads;
  /**  Returns the total count of posts made by the account `socialAccountId` for influencers */
  readonly influencerYoutubeAnalyticsPostsCount: TotalNumber;
  /**  Returns the statistics of the posts by `socialAccountId` within the specified period for influencers */
  readonly influencerYoutubeAnalyticsPostsStatistics: YoutubeAnalyticsPostsStatisticsPayload;
  /**
   *  Returns the related posts with the same tag of the same analytics account
   *  `youtubeAnalyticsPostId` is the id of the current shown post. This will be excluded from the result. For influencers.
   */
  readonly influencerYoutubeAnalyticsRelatedPosts: YoutubeAnalyticsRelatedPostsPayload;
  /**  Returns a list of the posts made by the account `socialAccountId` for influencers */
  readonly influencerYoutubeAnalyticsShorts: YoutubeAnalyticsShortsPayloads;
  /**  Returns the total count of shorts made by the account `socialAccountId` for influencers */
  readonly influencerYoutubeAnalyticsShortsCount: TotalNumber;
  readonly influencerYoutubeAnalyticsSponsoredPosts: InfluencerAnalyticsSponsoredPostPayload;
  readonly influencerYoutubeAnalyticsSponsoredPostsCount: InfluencerAnalyticsSponsoredPostCountPayload;
  /**
   *  Returns a paginated list of the tags sorted by the specified field.
   *  For influencers.
   *  views can be larger than 32-bit, please keep note.
   */
  readonly influencerYoutubeAnalyticsTagsTopRanking: YoutubeAnalyticsTagRankingPayloads;
  /**
   *  Returns the total count of the tags specified by the inputs
   *  For influencers.
   *  views can be larger than 32-bit, please keep note.
   */
  readonly influencerYoutubeAnalyticsTagsTopRankingCount: TotalNumber;
  /**  Returns all Youtube compare posts in date. For influencers only. */
  readonly influencerYoutubeCompareAllPostsInDate?: Maybe<InfluencerYoutubeCompareAllPostsInDate>;
  /**  Returns the Youtube overview data for the main analytics account and compare accounts. For influencers only. */
  readonly influencerYoutubeCompareOverview: InfluencerYoutubeCompareOverview;
  /**  Returns a compare youtube post by id. For influencers only. */
  readonly influencerYoutubeComparePostById?: Maybe<YoutubeComparePost>;
  /**  Returns Youtube compare posts with pagination. For influencers only. */
  readonly influencerYoutubeComparePostList: YoutubeComparePostList;
  /**  Returns comparable posts for Youtube. For influencers only. */
  readonly influencerYoutubeComparePosts: InfluencerYoutubeComparePosts;
  /**  Returns the total count of compare posts. For influencers only. */
  readonly influencerYoutubeComparePostsCount: TotalNumber;
  /**  Returns the Youtube compare accounts tag ranking data. For influencers only. */
  readonly influencerYoutubeCompareTagRanking: InfluencerYoutubeCompareTagRanking;
  readonly instagramAccountInfo?: Maybe<InstagramAnalyticsAccountInfoHeader>;
  readonly instagramAmbassadorPostDetails?: Maybe<InstagramBrandAmbassadorPostDetails>;
  readonly instagramAnalyticsHashtags?: Maybe<InstagramAnalyticsHashtags>;
  /**
   *  Returns a list of comments of `instagramAnalyticsAccountId` account, comments of posts where it mentioned,
   *  comments where it mentioned, and regular comments
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly instagramAnalyticsListComments: InstagramAnalyticsGetCommentsPayload;
  /**
   *  Returns the total count of comments of `instagramAnalyticsAccountId` account, comments of posts where it mentioned
   *  comments where it mentioned, and regular comments
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly instagramAnalyticsListCommentsCount: TotalNumber;
  /**
   * Returns details for a specific Instagram feed post. For example, the content,
   *         comments, and etc.
   *
   */
  readonly instagramAnalyticsPostDetails?: Maybe<InstagramAnalyticsPostDetails>;
  /** Returns details for a specific Instagram reel post. */
  readonly instagramAnalyticsReelDetails?: Maybe<InstagramAnalyticsReelDetails>;
  /** Returns details for a specific Instagram story post. */
  readonly instagramAnalyticsStoryDetails?: Maybe<InstagramAnalyticsStoryDetails>;
  readonly instagramCompareAccounts: InstagramCompareAccountsPayload;
  /** Returns followers age-gender breakdown data for the main account and compare accounts */
  readonly instagramCompareFollowers?: Maybe<InstagramCompareFollowers>;
  /** Returns a list of accounts to compare */
  readonly instagramCompareGetAccounts?: Maybe<InstagramAnalyticsCompareAccounts>;
  /** Returns the overview data for the main analytics account and compare accounts */
  readonly instagramCompareOverview?: Maybe<InstagramCompareOverview>;
  /** Returns details of a specific post of a compare account */
  readonly instagramComparePostDetails?: Maybe<InstagramComparePostDetails>;
  /**
   * Returns posts analytics data(posting habits, recent posts, popular hashtags, and etc)
   *         for the main account and compare accounts
   */
  readonly instagramComparePosts?: Maybe<InstagramComparePosts>;
  /**
   * Returns follower demographics analytics information for a specific analytics account
   *         in a specified period of time. For example, age-gender distribution, followers countries, and etc.
   */
  readonly instagramDashboardFollowerAnalytics?: Maybe<InstagramDashboardFollowerAnalytics>;
  /**
   * Returns the main analytics information for a specific analytics account
   *         in a specified period of time. For example, the number of followers, posting frequency/activity,
   *         comments growth, and etc.
   */
  readonly instagramDashboardOverview?: Maybe<InstagramDashboardOverview>;
  /**
   * Returns Instagram posts analytics data for a specific analytics account
   *         in a specified period of time. For example, posting habits, last feed and story posts, and etc.
   */
  readonly instagramDashboardPostAnalytics?: Maybe<InstagramDashboardPostAnalytics>;
  readonly instagramHashtagFeedPostsV2: InstagramHashtagFeedPostsPayload;
  readonly instagramHashtagPostDetails?: Maybe<InstagramHashtagPostDetails>;
  readonly instagramHashtagPosts?: Maybe<InstagramHashtagPosts>;
  readonly instagramHashtagPostsV2: InstagramHashtagPostsPayload;
  readonly instagramHashtagsOverview?: Maybe<InstagramHashtagsOverview>;
  readonly instagramInteractionMentionedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  readonly instagramInteractionOverview?: Maybe<InstagramInteractionOverview>;
  readonly instagramInteractionPosts?: Maybe<InstagramInteractionPosts>;
  readonly instagramInteractionTaggedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  /**  Returns basic info for UGC management: ig username, added hashtags */
  readonly instagramUGCManagementBasicInfo: InstagramUGCManagementBasicInfoPayload;
  /**  Returns UGC posts */
  readonly instagramUGCManagementListPosts: InstagramUGCManagementListPostsPayload;
  /**  Returns UGC posts' count. Used for pagination */
  readonly instagramUGCManagementListPostsCount: InstagramUGCManagementListPostsCountPayload;
  /**  Returns UGC post's details */
  readonly instagramUGCManagementPostDetails: InstagramUGCManagementPostDetailsPayload;
  readonly instagramUserHashtagPostList?: Maybe<InstagramUserHashtagListPostsPayload>;
  readonly instagramUserHashtags: InstagramUserHashtagPayload;
  /** Tells if an influencer belongs to UUUM */
  readonly isInfluencerUuum?: Maybe<IsInfluencerUUUM>;
  readonly logo?: Maybe<Logo>;
  readonly marketplace?: Maybe<MarketplaceDetail>;
  readonly marketplaceAffiliateCommissionFixedDates?: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly marketplaceAffiliateCommissionInfluencers?: Maybe<ReadonlyArray<MarketplaceAffiliateCommissionInfluencer>>;
  readonly marketplaceAffiliateCommissionInfluencersCount?: Maybe<TotalNumber>;
  readonly marketplaceAffiliateJoinedInfluencers?: Maybe<ReadonlyArray<MarketplaceAffiliateJoinedInfluencer>>;
  readonly marketplaceAffiliateJoinedInfluencersCount?: Maybe<TotalNumber>;
  readonly marketplaceAffiliateReportByDate?: Maybe<ReadonlyArray<MarketplaceAffiliateReportByDate>>;
  readonly marketplaceAffiliateReportByDateCount?: Maybe<TotalNumber>;
  readonly marketplaceAffiliateReportByInfluencer?: Maybe<ReadonlyArray<MarketplaceAffiliateReportByInfluencer>>;
  readonly marketplaceAffiliateReportByInfluencerCount?: Maybe<TotalNumber>;
  readonly marketplaceAffiliateReportCountForInfluencer?: Maybe<TotalNumber>;
  readonly marketplaceAffiliateReportForInfluencer?: Maybe<ReadonlyArray<MarketplaceAffiliateReportForInfluencer>>;
  readonly marketplaceAffiliateReportSummary?: Maybe<MarketplaceAffiliateReportSummary>;
  readonly marketplaceAffiliateReportSummaryForInfluencer?: Maybe<MarketplaceAffiliateReportSummaryForInfluencer>;
  readonly marketplaceCampaignPostReportForInfluencer?: Maybe<MarketplaceCampaignPostReportForInfluencer>;
  readonly marketplaceCampaignReport?: Maybe<MarketplaceCampaignReport>;
  readonly marketplaceCampaignReportGrid?: Maybe<CampaignGridReport>;
  readonly marketplaceCampaignReportSummary?: Maybe<NewCampaignReportSummary>;
  readonly marketplaceDraftPost?: Maybe<MarketplaceDraftPostDetail>;
  readonly marketplaceDraftPostsForInfluencer?: Maybe<ReadonlyArray<MarketplaceDraftPostForInfluencer>>;
  readonly marketplaceForInfluencer?: Maybe<MarketplaceDetailForInfluencer>;
  readonly marketplaceGoogleSlidesHistory?: Maybe<ReadonlyArray<GoogleSlideFile>>;
  readonly marketplaceHashtagCloud?: Maybe<ReadonlyArray<HashtagType>>;
  readonly marketplaceJoinableAccounts?: Maybe<MarketplaceJoinableAccountsPayload>;
  readonly marketplaceJoinablePromotionMethods?: Maybe<MarketplaceJoinablePromotionMethodsPayload>;
  readonly marketplaceJoinedAccount?: Maybe<MarketplaceJoinedAccountPayload>;
  readonly marketplaceJoinedMethods?: Maybe<MarketplaceJoinedMethodsPayload>;
  readonly marketplaceJoinedMethodsV2?: Maybe<MarketplaceJoinedMethodsPayloadV2>;
  readonly marketplacePost?: Maybe<MarketplacePostDetail>;
  readonly marketplacePostComments?: Maybe<ReadonlyArray<MarketplacePostComment>>;
  readonly marketplacePostHistory?: Maybe<ReadonlyArray<MarketplacePostHistory>>;
  readonly marketplacePostsNoRevenue?: Maybe<MarketplacePostsNoRevenue>;
  readonly marketplaceTracking?: Maybe<MarketplaceTracking>;
  /**
   *
   *         Returns Marketplace tracking post url of its influencer if campaign is on-going and post is not fetched
   *
   */
  readonly marketplaceTrackingPostUrlForInfluencer?: Maybe<MarketplaceTrackingPostUrlForInfluencer>;
  readonly mobileAppVersion: MobileVersion;
  readonly needReconnectForFacebook?: Maybe<NeedReconnectForFacebookPayload>;
  readonly netsuiteCustomerList?: Maybe<ReadonlyArray<NetSuiteCustomerData>>;
  readonly netsuiteCustomerName?: Maybe<NetSuiteCustomerDataForSearch>;
  readonly netsuiteVendorList?: Maybe<ReadonlyArray<NetSuiteVendorData>>;
  readonly netsuiteVendorName?: Maybe<NetSuiteVendorDataForSearch>;
  readonly openAiMessage?: Maybe<OpenAIMessage>;
  readonly package?: Maybe<ReadPackage>;
  readonly packageInfluencers?: Maybe<ReadonlyArray<ReadPackageInfluencers>>;
  readonly packageProposal?: Maybe<PackageProposal>;
  readonly packageProposalSummary?: Maybe<PackageProposalSummary>;
  readonly partnerAgency?: Maybe<PartnerAgencyWithCompanies>;
  readonly partnerAgencyBusinessAssociates?: Maybe<ReadonlyArray<AssociateCompany>>;
  readonly partnerAgencyBusinessAssociatesCount?: Maybe<TotalNumber>;
  readonly partnerAgencyPermission?: Maybe<PartnerAgencyWithPermission>;
  /**
   *  Returns a URL that FE uses to reconnect partner influencer accounts
   *  May be used by anyone with no authentication.
   */
  readonly partnerReconnectAnalyticsSocialAuthRedirectUrl?: Maybe<PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload>;
  /**
   *  Returns a post specified by `partnerInfluencerAccountId` for partner influencers
   *  Views can be larger than 32-bit, please keep note
   */
  readonly partnerYoutubeAnalyticsPost: YoutubeAnalyticsPostPayload;
  readonly payableCampaign?: Maybe<ReadonlyArray<PayableCampaign>>;
  readonly paymentInformation?: Maybe<PaymentInformation>;
  readonly paymentPackageStatus?: Maybe<PaymentPackageStatusResponse>;
  readonly paymentRequest?: Maybe<PaymentRequest>;
  readonly pricingTable?: Maybe<PricingTable>;
  readonly refreshProfileAccountAvailability?: Maybe<RefreshProfileAccountAvailabilityPayload>;
  readonly refreshTwitterAccountAvailability?: Maybe<RefreshTwitterAccountAvailabilityPayload>;
  readonly reportForInfluencer?: Maybe<ReadonlyArray<ReportForInfluencer>>;
  readonly requestedBcaBrands?: Maybe<ReadonlyArray<RequestedBCABrand>>;
  readonly searchEngCampaignsSelectInfluencers?: Maybe<ReadonlyArray<EngagementCampaignSearchSelectInfluencers>>;
  readonly searchEngCampaignsSelectInfluencersCount?: Maybe<TotalNumber>;
  /**
   * Searches for private partner influencers by a keyword and partner id.
   *         Already added influencers are excluded
   */
  readonly searchPrivatePartnerInfluencers?: Maybe<ReadonlyArray<PrivatePartnerInfluencer>>;
  /**
   * Searches for private talent influencers by a keyword and talent agency id.
   *         Already added influencers are excluded
   */
  readonly searchPrivateTalentInfluencers?: Maybe<ReadonlyArray<PrivateTalentInfluencer>>;
  readonly selectableBcaBrands?: Maybe<SelectableBCABrands>;
  readonly shopifyAuthRedirectUrl?: Maybe<ShopifyAuthRedirectUrlPayload>;
  readonly socialAuthCheckFacebookToken?: Maybe<SocialAuthCheckFacebookTokenPayload>;
  /**  Returns a simplified redirect URL that FE uses to communicate with social network APIs for AnyTag/AnyCreator. */
  readonly socialAuthRedirectUrlForInfluencer?: Maybe<SocialAuthRedirectUrlForInfluencerPayload>;
  readonly sponsoredPosts?: Maybe<InfluencerSponsoredPostResponse>;
  readonly sponsoredPostsCount?: Maybe<InfluencerSponsoredPostCountResponse>;
  readonly subscriptionPlan?: Maybe<SubscriptionPlan>;
  readonly talentAgency?: Maybe<TalentAgencyWithCompanies>;
  readonly talentAgencyBusinessAssociateCompanies?: Maybe<ReadonlyArray<AssociateCompany>>;
  readonly talentAgencyBusinessAssociateCompaniesCount?: Maybe<TotalNumber>;
  /**
   *  Returns a URL that FE uses to reconnect talent influencer accounts
   *  May be used by anyone with no authentication.
   */
  readonly talentAgencyReconnectAnalyticsSocialAuthRedirectUrl?: Maybe<TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload>;
  /**  Returns Talent Influencer details for Edit page including connected social accounts */
  readonly talentInfluencerDetailsForEdit?: Maybe<TalentInfluencerDetailsForEditPayload>;
  readonly tiktokAdAccounts?: Maybe<ReadonlyArray<TiktokAdAccountInfo>>;
  readonly tiktokAdAuthRedirectUrl?: Maybe<TiktokAuthRedirectUrl>;
  readonly tiktokAdCampaigns?: Maybe<ReadonlyArray<TiktokAdCampaign>>;
  readonly tiktokAdGroups?: Maybe<ReadonlyArray<TiktokAdGroup>>;
  readonly tiktokAdvertiserAccountInfo?: Maybe<TiktokAdvertiserAccount>;
  readonly tiktokAdvertiserPlanData?: Maybe<TiktokAdvertiserPlanData>;
  readonly tiktokAdvertiserSummaryData?: Maybe<TiktokAdvertiserSummaryData>;
  readonly tiktokBudgetAllocation?: Maybe<TiktokBudgetAllocation>;
  readonly tiktokBusinessAccountInfo?: Maybe<TiktokBusinessAccountInfo>;
  readonly tiktokBusinessAdAccounts?: Maybe<ReadonlyArray<TiktokAdAccountInfo>>;
  readonly tiktokProfileAudience?: Maybe<TiktokAudienceSection>;
  readonly tiktokSpecialCampaign?: Maybe<TikTokSpecialCampaignDetail>;
  readonly tiktokSpecialCampaignForInfluencerSearchJob?: Maybe<TikTokSpecialCampaignDetailForInfluencer>;
  readonly tiktokSpecialCampaignForInfluencerYourJob?: Maybe<TikTokSpecialCampaignDetailForInfluencer>;
  readonly tiktokSpecialCampaignPostReportForInfluencer?: Maybe<TikTokSpecialCampaignPostReportForInfluencer>;
  readonly tiktokSpecialCampaignPosts?: Maybe<ReadonlyArray<TikTokSpecialCampaignPost>>;
  readonly tiktokSpecialCampaignPostsCount?: Maybe<TotalNumber>;
  readonly tiktokSpecialCampaignReport?: Maybe<TiktokSpecialCampaignReport>;
  readonly tiktokSpecialCampaignReportGrid?: Maybe<CampaignGridReport>;
  readonly tiktokSpecialCampaignReportSummary?: Maybe<TiktokSpecialCampaignReportSummary>;
  readonly tiktokSpecialJoinableAccounts?: Maybe<TiktokSpecialJoinableAccountsPayload>;
  readonly tiktokSpecialJoinedAccount?: Maybe<TikTokSpecialJoinedAccountPayload>;
  readonly tiktokSpecialPackageInfluencers: ReadonlyArray<TikTokSpecialPackageInfluencer>;
  readonly tiktokSpecialPackageInfluencersCount?: Maybe<TotalNumber>;
  readonly tiktokSpecialPost?: Maybe<TikTokSpecialPostDetail>;
  readonly tiktokSpecialPostHistory?: Maybe<ReadonlyArray<TikTokSpecialPostHistory>>;
  readonly tiktokTagPostDetails: TiktokUserTagPostDetailsPayload;
  readonly tiktokUserHashtagPostList?: Maybe<TiktokUserHashtagListPostsPayload>;
  readonly tiktokUserHashtags: TiktokUserHashtagPayload;
  readonly tiktokUserKeywordPostList?: Maybe<TiktokUserHashtagListPostsPayload>;
  readonly tiktokUserKeywords: TiktokUserKeywordPayload;
  readonly totalInfluencerReport?: Maybe<TotalInfluencerReport>;
  /**  Returns trending facebook influencers */
  readonly trendingFacebookInfluencers: TrendingFacebookInfluencerSearchResult;
  /**  Returns trending instagram influencers */
  readonly trendingInstagramInfluencersV2: TrendingInstagramInfluencerSearchResultV2;
  /**  Returns trending youtube influencers */
  readonly trendingYoutubeInfluencersV2: TrendingYoutubeInfluencerSearchResultV2;
  /**
   *  Returns the overview data of the twitter account by `twitterAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly twitterAnalyticsOverview: TwitterAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly twitterAnalyticsPost: TwitterAnalyticsPostByIdPayload;
  /**
   *  Returns a list of the posts made by the account `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly twitterAnalyticsPosts: TwitterAnalyticsPostsPayload;
  /**
   *  Returns the posts that are made on the date
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly twitterAnalyticsPostsByDate: TwitterAnalyticsPostsByDatePayload;
  /**
   *  Returns the total count of posts made by the account `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly twitterAnalyticsPostsCount: TotalNumber;
  /**
   *  Posts section for twitter dashboard for account `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly twitterAnalyticsPostsStatistic?: Maybe<TwitterAnalyticsPostsStatisticPayload>;
  readonly updatePaymentMethodLink?: Maybe<StripeDeepLink>;
  readonly user?: Maybe<UserDetail>;
  readonly userProposal?: Maybe<UserProposal>;
  /** List of influencer word cloud */
  readonly wordCloudList?: Maybe<ReadonlyArray<InfluencerWordCloud>>;
  readonly xhsAccountInterest?: Maybe<XhsAccountInterest>;
  readonly xhsProfileAudience?: Maybe<XhsAudienceSection>;
  readonly xhsProfileEngagementInsights?: Maybe<XhsEngagementInsightSection>;
  readonly xhsProfilePosts?: Maybe<XhsPostSection>;
  /**
   *  Returns the audience of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly youtubeAnalyticsAudience: YoutubeAnalyticsAudiencePayload;
  /**
   *  Returns a list of the comments made at videos of the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly youtubeAnalyticsComments: YoutubeAnalyticsCommentsPayload;
  /**
   *  Returns the total count of comments made at videos of the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly youtubeAnalyticsCommentsCount: TotalNumber;
  /**
   *  Returns a list of Youtube accounts compared with account `mainAccountId`
   *  May be used by admin, agency, staff, advertiser, talent_agency, partner
   */
  readonly youtubeAnalyticsCompareAccountCards: YoutubeAnalyticsCompareAccountCards;
  /**
   *  Returns the related posts with the same tag of the same compare account
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   *  `postId` is the id of the current shown post. This will be excluded from the result
   */
  readonly youtubeAnalyticsCompareRelatedPosts: YoutubeAnalyticsCompareRelatedPosts;
  readonly youtubeAnalyticsCountriesCategories: YoutubeAnalyticsCountriesCategoriesPayload;
  /**
   *  Returns the overview data of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly youtubeAnalyticsOverview: YoutubeAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `youtubeAnalyticsPostId`
   *  May be used by admin, staff, agency, and advertisers
   *  Views can be larger than 32-bit, please keep note
   */
  readonly youtubeAnalyticsPost: YoutubeAnalyticsPostWithChannelDetailsPayload;
  /**
   *  Returns a list of the posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   *  Views can be larger than 32-bit, please keep note
   */
  readonly youtubeAnalyticsPosts: YoutubeAnalyticsPostsPayloads;
  /**
   *  Returns the posts that are made on the date
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly youtubeAnalyticsPostsByDate: YoutubeAnalyticsPostPayloads;
  /**
   *  Returns the total count of posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly youtubeAnalyticsPostsCount: TotalNumber;
  /**
   *  Returns the statistics of the posts by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  readonly youtubeAnalyticsPostsStatistics: YoutubeAnalyticsPostsStatisticsPayload;
  /**
   *  Returns the related posts with the same tag of the same analytics account
   *  May be used by admin, staff, agency, and advertisers
   *  `youtubeAnalyticsPostId` is the id of the current shown post. This will be excluded from the result
   */
  readonly youtubeAnalyticsRelatedPosts: YoutubeAnalyticsRelatedPostsPayload;
  readonly youtubeAnalyticsShorts: YoutubeAnalyticsShortsPayloads;
  readonly youtubeAnalyticsShortsCount: TotalNumber;
  /**
   *  Returns a paginated list of the tags sorted by the specified field.
   *  May be used by admin, staff, agency, and advertisers
   *  views can be larger than 32-bit, please keep note.
   */
  readonly youtubeAnalyticsTagsTopRanking: YoutubeAnalyticsTagRankingPayloads;
  /**
   *  Returns the total count of the tags specified by the inputs
   *  May be used by admin, staff, agency, and advertisers
   *  views can be larger than 32-bit, please keep note.
   */
  readonly youtubeAnalyticsTagsTopRankingCount: TotalNumber;
  /**
   *  Returns a trending post by id
   *  May be used by any logged in user
   */
  readonly youtubeAnalyticsTrendPostById: YoutubeAnalyticsTrendPostDetailsPayload;
  /**
   *  Returns a list of the trends for country `country` and category `category` in date `date`
   *  sorted by position in chart from 1 to 100
   *  May be used by any logged in user
   */
  readonly youtubeAnalyticsTrends: YoutubeAnalyticsTrendsPayloads;
  /**
   *  Returns count of the trends for country `country` and category `category` in date `date`
   *  May be used by any logged in user
   */
  readonly youtubeAnalyticsTrendsCount: TotalNumber;
  readonly youtubeBrandAccounts?: Maybe<YouTubeBrandAccounts>;
  readonly youtubeCmsChannelDetail?: Maybe<YoutubeCmsChannelDetail>;
  readonly youtubeCmsChannelInfo?: Maybe<ReadonlyArray<YoutubeCmsChannelInfo>>;
  readonly youtubeCmsChannelsForInfluencer: ReadonlyArray<YoutubeCmsChannelForInfluencer>;
  readonly youtubeCmsConfirmedAssetsSummary?: Maybe<YoutubeCmsConfirmedChannelSummary>;
  readonly youtubeCmsConfirmedChannelsForInfluencer: ReadonlyArray<YoutubeCmsChannelForInfluencer>;
  readonly youtubeCmsConfirmedChannelsSummary?: Maybe<YoutubeCmsConfirmedChannelSummary>;
  readonly youtubeCmsConfirmedMusicsForInfluencer: ReadonlyArray<YoutubeCmsChannelForInfluencer>;
  readonly youtubeCmsConfirmedRevenueForInfluencer?: Maybe<YouTubeCmsRevenueForInfluencer>;
  readonly youtubeCmsConfirmedRevenueLatestMonth?: Maybe<YoutubeCmsConfirmedRevenueLatestMonth>;
  readonly youtubeCmsConfirmedVideosForInfluencer: ReadonlyArray<YoutubeCmsChannelForInfluencer>;
  readonly youtubeCmsEstimateAssetsSummary?: Maybe<YoutubeCmsEstimateChannelSummary>;
  readonly youtubeCmsEstimateChannelsSummary?: Maybe<YoutubeCmsEstimateChannelSummary>;
  readonly youtubeCmsInvoiceCount?: Maybe<TotalNumber>;
  readonly youtubeCmsInvoiceList?: Maybe<ReadonlyArray<YoutubeCmsInvoice>>;
  readonly youtubeCmsInvoiceListForInfluencer?: Maybe<ReadonlyArray<Maybe<YoutubeCmsInvoiceForInfluencer>>>;
  readonly youtubeCmsMusicsForInfluencer: ReadonlyArray<YoutubeCmsMusicForInfluencer>;
  readonly youtubeCmsRevenueForInfluencer?: Maybe<YouTubeCmsRevenueForInfluencer>;
  readonly youtubeCmsStartMonthForInfluencer?: Maybe<YoutubeCmsStartMonth>;
  readonly youtubeCmsVideoAudience?: Maybe<YouTubeCmsVideoAudience>;
  readonly youtubeCmsVideoStats?: Maybe<YouTubeCmsVideoStats>;
  readonly youtubeCmsVideosForInfluencer: ReadonlyArray<YoutubeCmsVideo>;
  /**
   *  Returns all Youtube compare posts in date
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  readonly youtubeCompareAllPostsInDate?: Maybe<YoutubeCompareAllPostsInDate>;
  /**
   *  Returns the Youtube overview data for the main analytics account and compare accounts
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  readonly youtubeCompareOverview: YoutubeCompareOverview;
  /**  Returns a compare youtube post by id */
  readonly youtubeComparePostById?: Maybe<YoutubeComparePost>;
  /**
   *  Returns the Youtube compare posts with pagination
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  readonly youtubeComparePostList: YoutubeComparePostList;
  /**
   *  Returns comparable posts for Youtube
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  readonly youtubeComparePosts: YoutubeComparePosts;
  /**  Returns the total count of compare posts made for the account `compareAccountId` */
  readonly youtubeComparePostsCount: TotalNumber;
  /**
   *  Returns the Youtube compare accounts tag ranking data
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  readonly youtubeCompareTagRanking: YoutubeCompareTagRanking;
  readonly youtubeMusicCategories: ReadonlyArray<YoutubeMusicCategory>;
  readonly youtubeMusicList: ReadonlyArray<YoutubeMusicItem>;
};

export type QueryaccountAnalyticDetailsV2Args = {
  id: Scalars['Int'];
};

export type QueryaccountAnalyticsBasicDetailsV2Args = {
  brandAccountId: Scalars['Int'];
};

export type QueryaddressArgs = {
  influencerId: Scalars['Int'];
};

export type QueryadvertiserArgs = {
  pk: Scalars['Int'];
};

export type QueryagencyArgs = {
  pk: Scalars['Int'];
};

export type QueryagencyBusinessAssociateCompaniesArgs = {
  agencyId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryagencyBusinessAssociateCompaniesCountArgs = {
  agencyId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryagencyLogoArgs = {
  hash: Scalars['String'];
};

export type QueryallAccountAnalyticsCountForAdminV2Args = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  subscriptionPlan?: InputMaybe<AnalyticsSubscriptionPlanType>;
};

export type QueryallAccountAnalyticsForAdminV2Args = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  subscriptionPlan?: InputMaybe<AnalyticsSubscriptionPlanType>;
};

export type QueryallAccountAnalyticsForAdvertiserV2Args = {
  keyword?: InputMaybe<Scalars['String']>;
  subscriptionPlan?: InputMaybe<AnalyticsSubscriptionPlanType>;
};

export type QueryallAdvertiserPicsArgs = {
  advertiserId: Scalars['Int'];
};

export type QueryallAdvertisersArgs = {
  analyticsPlan?: InputMaybe<AnalyticsAdvertiserPlanFilter>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  tiktokPlan?: InputMaybe<TikTokAdvertiserPlanFilter>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallAdvertisersCountArgs = {
  analyticsPlan?: InputMaybe<AnalyticsAdvertiserPlanFilter>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  tiktokPlan?: InputMaybe<TikTokAdvertiserPlanFilter>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallAdvertisersForSearchArgs = {
  countryId?: InputMaybe<Scalars['String']>;
  includeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryallAgenciesArgs = {
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallAgenciesCountArgs = {
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallAgencyUsersArgs = {
  agencyId: Scalars['Int'];
};

export type QueryallApplicantInfluencersForMarketplaceArgs = {
  appliedStatus?: InputMaybe<MarketplaceCampaignAppliedStatus>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  id: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InfluencerOrderBy>;
};

export type QueryallApplicantInfluencersForMarketplaceCountArgs = {
  appliedStatus?: InputMaybe<MarketplaceCampaignAppliedStatus>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  id: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
};

export type QueryallBankBranchesArgs = {
  bankId: Scalars['Int'];
};

export type QueryallBankBranchesForInfluencerArgs = {
  bankId: Scalars['Int'];
};

export type QueryallBanksArgs = {
  countryId: Scalars['String'];
};

export type QueryallCampaignPostsArgs = {
  advertiserIds: ReadonlyArray<Scalars['Int']>;
  campaignTypes: ReadonlyArray<CampaignIdentifierType>;
  campaigns: ReadonlyArray<CampaignIdentifierInput>;
  categoryIds: ReadonlyArray<Scalars['Int']>;
  countryIds: ReadonlyArray<Scalars['String']>;
  influencerIds: ReadonlyArray<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  maxPostDate?: InputMaybe<Scalars['Date']>;
  minPostDate?: InputMaybe<Scalars['Date']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<CampaignPostOrderBy>;
  socialPostTypes: ReadonlyArray<CampaignPostSocialType>;
};

export type QueryallCampaignsForFilterArgs = {
  campaignTypes?: InputMaybe<ReadonlyArray<CampaignType>>;
  campaigns?: InputMaybe<ReadonlyArray<CampaignFilter>>;
};

export type QueryallCampaignsForSearchArgs = {
  campaignTypes?: InputMaybe<ReadonlyArray<CampaignType>>;
};

export type QueryallCampaignsForYourJobArgs = {
  isFinished: Scalars['Boolean'];
};

export type QueryallCampaignsReportArgs = {
  campaignCategoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  campaignTypes?: InputMaybe<ReadonlyArray<CampaignType>>;
  campaigns?: InputMaybe<ReadonlyArray<CampaignFilter>>;
  countryId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<CampaignReportOrderBy>;
  socialMedias?: InputMaybe<ReadonlyArray<CampaignSocialMediaType>>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallCampaignsSummaryArgs = {
  campaignCategoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  campaignTypes?: InputMaybe<ReadonlyArray<CampaignType>>;
  campaigns?: InputMaybe<ReadonlyArray<CampaignFilter>>;
  countryId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  socialMedias?: InputMaybe<ReadonlyArray<CampaignSocialMediaType>>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallCitiesArgs = {
  countryId: Scalars['String'];
};

export type QueryallConnectedFacebookPagesArgs = {
  pk: Scalars['Int'];
};

export type QueryallDouyinInfluencersArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  demographicsAge?: InputMaybe<SocialAccountInfluencerDemographicsAge>;
  demographicsGender?: InputMaybe<SocialAccountInfluencerDemographicsGender>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallEngagementPostsArgs = {
  engagementId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<EngagementCampaignPostStatusForSearch>;
};

export type QueryallEngagementPostsCountArgs = {
  engagementId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EngagementCampaignPostStatusForSearch>;
};

export type QueryallEngagementPostsForInfluencerArgs = {
  engagementId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryallEngagementPostsForInsightCheckArgs = {
  campaignId: Scalars['Int'];
  status?: InputMaybe<EngagementCampaignPostStatusForInsightCheck>;
};

export type QueryallEngagementsArgs = {
  campaignCategoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  hubspotDealIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  influencerPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  salesPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<EngagementCampaignStatus>;
  tiktokCampaignTypes?: InputMaybe<ReadonlyArray<TikTokCampaignType>>;
};

export type QueryallEngagementsCountArgs = {
  campaignCategoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  hubspotDealIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  influencerPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  keyword?: InputMaybe<Scalars['String']>;
  salesPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<EngagementCampaignStatus>;
  tiktokCampaignTypes?: InputMaybe<ReadonlyArray<TikTokCampaignType>>;
};

export type QueryallEngagementsForInsightCheckArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<EngagementCampaignPostStatusForInsightCheck>;
};

export type QueryallEngagementsForInsightCheckCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryallFacebookAccountInfluencersArgs = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallFacebookAccountInfluencersCountArgs = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallFacebookAccountInfluencersV2Args = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallFacebookPageInfluencersArgs = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallFacebookPageInfluencersCountArgs = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallFacebookPageInfluencersV2Args = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallInfluencerAccountPostsArgs = {
  isSponsoredPost?: InputMaybe<Scalars['Boolean']>;
  isVideo?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<PostOrderBy>;
  socialAccountId: Scalars['Int'];
  socialPostType: SocialPostType;
};

export type QueryallInfluencerAccountPostsCountArgs = {
  isSponsoredPost?: InputMaybe<Scalars['Boolean']>;
  isVideo?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  socialPostType: SocialPostType;
};

export type QueryallInfluencerPostsV3Args = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryId?: InputMaybe<Scalars['String']>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostDate?: InputMaybe<Scalars['Date']>;
  maxViews?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostDate?: InputMaybe<Scalars['Date']>;
  minViews?: InputMaybe<Scalars['Int']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<PostOrderByV3>;
  socialPostType?: InputMaybe<SocialPostType>;
};

export type QueryallInfluencersCountForMarketplaceArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  id: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  participateStatuses?: InputMaybe<ReadonlyArray<MarketplaceCampaignParticipateStatus>>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  selectedInfluencers?: InputMaybe<ReadonlyArray<SelectedInfluencers>>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallInfluencersForMarketplaceV2Args = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  id: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InfluencerOrderBy>;
  participateStatuses?: InputMaybe<ReadonlyArray<MarketplaceCampaignParticipateStatus>>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  selectedInfluencers?: InputMaybe<ReadonlyArray<SelectedInfluencers>>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallInfluencersV4Args = {
  campaignDisplay?: InputMaybe<Scalars['Boolean']>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  includeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxAvgPostPrice?: InputMaybe<Scalars['Float']>;
  maxCampaignsJoined?: InputMaybe<Scalars['Int']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minAvgPostPrice?: InputMaybe<Scalars['Float']>;
  minCampaignsJoined?: InputMaybe<Scalars['Int']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InfluencerSearchOrderBy>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  socialMedias?: InputMaybe<ReadonlyArray<QuerySocialAccountType>>;
  statuses?: InputMaybe<ReadonlyArray<InfluencerStatusForSearch>>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallInstagramAmbassadorPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramAmbassadorPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramCompareFeedPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramCompareFeedPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramComparePostsInDateArgs = {
  accountId: Scalars['Int'];
  comparableAccountIds: ReadonlyArray<Scalars['Int']>;
  postDate: Scalars['Date'];
};

export type QueryallInstagramFeedPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramFeedPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramHashtagFeedPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  hashtagId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramHashtagFeedPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  hashtagId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramInfluencersV3Args = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  followerGrowthPercentage?: InputMaybe<Scalars['Float']>;
  followerGrowthPeriod?: InputMaybe<FollowersGrowthPeriodEnum>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  includeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  interests?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  maxReach?: InputMaybe<Scalars['Float']>;
  maxSaveRate?: InputMaybe<Scalars['Float']>;
  maxStoryPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  minReach?: InputMaybe<Scalars['Float']>;
  minSaveRate?: InputMaybe<Scalars['Float']>;
  minStoryPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallInstagramInteractionUsersArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramInteractionUserOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramMentionedPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramMentionedPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramPostsInDateArgs = {
  accountId: Scalars['Int'];
  postDate: Scalars['Date'];
};

export type QueryallInstagramReelPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramReelPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramSimilarInfluencersV3Args = {
  accountId?: InputMaybe<Scalars['Int']>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  username?: InputMaybe<Scalars['String']>;
};

export type QueryallInstagramStoryPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramStoryPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramTaggedPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallInstagramTaggedPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryallMarketplacePostsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  marketplaceId: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<MarketplaceCampaignPostStatusForSearch>;
};

export type QueryallMarketplacePostsCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  marketplaceId: Scalars['Int'];
  status?: InputMaybe<MarketplaceCampaignPostStatusForSearch>;
};

export type QueryallMarketplacesArgs = {
  advertiserIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  campaignDetailType?: InputMaybe<MarketplaceCampaignDetailType>;
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<MarketplaceCampaignStatus>;
};

export type QueryallMarketplacesCountArgs = {
  campaignDetailType?: InputMaybe<MarketplaceCampaignDetailType>;
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MarketplaceCampaignStatus>;
};

export type QueryallMarketplacesForReorderArgs = {
  countryId?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryallNeedReviewMarketplacePostsArgs = {
  marketplaceId: Scalars['Int'];
};

export type QueryallPackagesArgs = {
  campaignId?: InputMaybe<Scalars['Int']>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryallPackagesCountArgs = {
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryallPartnerAgenciesCountArgs = {
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallPartnerAgencyCompanyArgs = {
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallPartnerAgencyUsersArgs = {
  partnerAgencyId: Scalars['Int'];
};

export type QueryallPaymentsArgs = {
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  maxDueDate?: InputMaybe<Scalars['Date']>;
  minDueDate?: InputMaybe<Scalars['Date']>;
  offset: Scalars['Int'];
  status?: InputMaybe<PaymentRequestStatus>;
};

export type QueryallPaymentsCountArgs = {
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  maxDueDate?: InputMaybe<Scalars['Date']>;
  minDueDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<PaymentRequestStatus>;
};

export type QueryallRegionsArgs = {
  countryId: Scalars['String'];
};

export type QueryallStaffsArgs = {
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryallStaffsCountArgs = {
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryallTalentAgenciesCountArgs = {
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallTalentAgencyCompanyArgs = {
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallTalentAgencyUsersArgs = {
  talentAgencyId: Scalars['Int'];
};

export type QueryallThreadsInfluencersArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallThreadsInfluencersCountArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallTiktokAdReportSummaryArgs = {
  adCampaignId?: InputMaybe<Scalars['String']>;
  adGroupId?: InputMaybe<Scalars['String']>;
  adReportType: TikTokAdReportType;
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<ReadonlyArray<TikTokAdStatus>>;
};

export type QueryallTiktokAdsReportArgs = {
  adCampaignId?: InputMaybe<Scalars['String']>;
  adGroupId?: InputMaybe<Scalars['String']>;
  adReportType: TikTokAdReportType;
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<TiktokAdReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<ReadonlyArray<TikTokAdStatus>>;
};

export type QueryallTiktokInfluencersArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  includeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  isTtcm?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallTiktokInfluencersCountArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  includeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  isTtcm?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallTiktokInfluencersV2Args = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  demographicsAge?: InputMaybe<SocialAccountInfluencerDemographicsAge>;
  demographicsGender?: InputMaybe<SocialAccountInfluencerDemographicsGender>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  includeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  isTtcm?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallTiktokSimilarInfluencersArgs = {
  accountId: Scalars['Int'];
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
};

export type QueryallTiktokSpecialCampaignsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<TikTokSpecialCampaignStatus>;
};

export type QueryallTiktokSpecialCampaignsCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TikTokSpecialCampaignStatus>;
};

export type QueryallTwitterInfluencersArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallTwitterInfluencersCountArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallTwitterSimilarInfluencersArgs = {
  accountId: Scalars['Int'];
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
};

export type QueryallUsersArgs = {
  advertiserIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryallUsersCountArgs = {
  advertiserIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryId?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryallXHSInfluencersArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  demographicsAge?: InputMaybe<SocialAccountInfluencerDemographicsAge>;
  demographicsGender?: InputMaybe<SocialAccountInfluencerDemographicsGender>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPicturePrice?: InputMaybe<Scalars['Float']>;
  maxVideoPrice?: InputMaybe<Scalars['Float']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPicturePrice?: InputMaybe<Scalars['Float']>;
  minVideoPrice?: InputMaybe<Scalars['Float']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallYoutubeCmsConfirmedAssetsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  month: Scalars['Date'];
  offset: Scalars['Int'];
};

export type QueryallYoutubeCmsConfirmedAssetsCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  month: Scalars['Date'];
};

export type QueryallYoutubeCmsConfirmedChannelsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  month: Scalars['Date'];
  offset: Scalars['Int'];
};

export type QueryallYoutubeCmsConfirmedChannelsCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  month: Scalars['Date'];
};

export type QueryallYoutubeCmsEstimateAssetsArgs = {
  cmsChannelId?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  month?: InputMaybe<Scalars['Date']>;
  offset: Scalars['Int'];
};

export type QueryallYoutubeCmsEstimateAssetsCountArgs = {
  cmsChannelId?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryallYoutubeCmsEstimateChannelsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  month?: InputMaybe<Scalars['Date']>;
  offset: Scalars['Int'];
};

export type QueryallYoutubeCmsEstimateChannelsCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryallYoutubeInfluencersV3Args = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  followerGrowthPercentage?: InputMaybe<Scalars['Float']>;
  followerGrowthPeriod?: InputMaybe<FollowersGrowthPeriodEnum>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  maxPostPrice?: InputMaybe<Scalars['Float']>;
  maxViews?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
  minPostPrice?: InputMaybe<Scalars['Float']>;
  minViews?: InputMaybe<Scalars['Int']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  regionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type QueryallYoutubeSimilarInfluencersArgs = {
  accountId: Scalars['Int'];
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  limit: Scalars['Int'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxFollowers?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  minFollowers?: InputMaybe<Scalars['Int']>;
};

export type QueryanalyticsSocialAuthRedirectUrlArgs = {
  callbackUrl: Scalars['String'];
  provider: AnalyticsAuthSocialAccountType;
};

export type QueryanalyticsSubscriptionPlanArgs = {
  accountId: Scalars['Int'];
};

export type QueryanalyticsSubscriptionPlanByAdvIdArgs = {
  advId: Scalars['Int'];
};

export type QueryautoManagedCommissionFixedDatesArgs = {
  id: Scalars['Int'];
};

export type QueryautoManagedCommissionInfluencersArgs = {
  fixedDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<AutoManagedCommissionStatus>;
};

export type QueryautoManagedCommissionInfluencersCountsArgs = {
  fixedDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AutoManagedCommissionStatus>;
};

export type QueryautoManagedJoinedInfluencersArgs = {
  id: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryautoManagedJoinedInfluencersCountArgs = {
  id: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryautoManagedJoinedMethodsArgs = {
  input: AutoManagedJoinedMethodsInput;
};

export type QueryautoManagedJoinedMethodsV2Args = {
  input: AutoManagedJoinedMethodsInput;
};

export type QueryautoManagedReportByDateArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryautoManagedReportByDateCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryautoManagedReportByInfluencerArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<MarketplaceAffiliateReportByInfluencerOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryautoManagedReportByInfluencerCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryautoManagedReportCountForInfluencerArgs = {
  campaignId: Scalars['Int'];
};

export type QueryautoManagedReportForInfluencerArgs = {
  campaignId: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryautoManagedReportSummaryArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryautoManagedReportSummaryForInfluencerArgs = {
  campaignId: Scalars['Int'];
};

export type QuerybrandAccountsArgs = {
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QuerycampaignCheckoutSessionLinkArgs = {
  packageId: Scalars['Int'];
  priceId: Scalars['Int'];
  redirectUrl: Scalars['String'];
};

export type QuerycampaignHashtagCloudArgs = {
  campaignObjective?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  socialMedia?: InputMaybe<CampaignSocialMediaType>;
  tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type QuerycampaignsChartForAllTimeArgs = {
  campaignCategoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  campaignTypes?: InputMaybe<ReadonlyArray<CampaignType>>;
  campaigns?: InputMaybe<ReadonlyArray<CampaignFilter>>;
  countryId?: InputMaybe<Scalars['String']>;
  socialMedias?: InputMaybe<ReadonlyArray<CampaignSocialMediaType>>;
};

export type QuerycheckBioLinkNameAvailabilityArgs = {
  input?: InputMaybe<CheckBioLinkNameAvailabilityInput>;
};

export type QuerycheckoutSessionLinkArgs = {
  priceId: Scalars['Int'];
  redirectUrl: Scalars['String'];
};

export type QuerycmsInvoiceDownloadUrlArgs = {
  id: Scalars['Int'];
};

export type QueryconnectableInstagramAccountsArgs = {
  fbPageIds: ReadonlyArray<InputMaybe<Scalars['String']>>;
};

export type QuerycountUnreadNotificationsByAppArgs = {
  input?: InputMaybe<CountUnreadNotificationsByAppInput>;
};

export type QuerycustomerPortalLinkArgs = {
  redirectUrl: Scalars['String'];
};

export type QuerydouyinAccountInterestArgs = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QuerydouyinProfileAudienceArgs = {
  distributionType: DouyinDistributionType;
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryengagementArgs = {
  pk: Scalars['Int'];
};

export type QueryengagementCampaignPostReportAudienceBreakdownArgs = {
  campaignId: Scalars['Int'];
};

export type QueryengagementCampaignPostReportForInfluencerArgs = {
  campaignId: Scalars['Int'];
};

export type QueryengagementCampaignReportArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<CampaignReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryengagementCampaignReportGridArgs = {
  campaignId: Scalars['Int'];
};

export type QueryengagementCampaignReportSummaryArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryengagementCampaignTrafficSourceArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  pk: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryengagementDraftPostHistoryArgs = {
  pk: Scalars['Int'];
};

export type QueryengagementDraftPostHistoryForInfluencerArgs = {
  pk: Scalars['Int'];
};

export type QueryengagementForInfluencerArgs = {
  pk: Scalars['Int'];
};

export type QueryengagementHashtagCloudArgs = {
  advertiser?: InputMaybe<Scalars['String']>;
  campaignCountry?: InputMaybe<Scalars['String']>;
  campaignObjective?: InputMaybe<Scalars['String']>;
  campaignTitle?: InputMaybe<Scalars['String']>;
  influencerCategory?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  productInfo?: InputMaybe<Scalars['String']>;
  socialMedia?: InputMaybe<CampaignSocialMediaType>;
  tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type QueryengagementPostCommentsArgs = {
  limit: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QueryengagementPostDetailArgs = {
  pk: Scalars['Int'];
};

export type QueryengagementPostDetailForInfluencerArgs = {
  pk: Scalars['Int'];
};

export type QueryengagementPostForInfluencerV2Args = {
  pk: Scalars['Int'];
};

export type QueryengagementPostInfluencersArgs = {
  pk: Scalars['Int'];
};

export type QueryengagementPostMetricsForInsightCheckArgs = {
  postId: Scalars['Int'];
};

export type QueryengagementPostSocialAccountsForInfluencerArgs = {
  pk: Scalars['Int'];
};

export type QueryengagementPostTrafficSourceArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  pk: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryengagementPostV2Args = {
  pk: Scalars['Int'];
};

export type QueryengagementProposalArgs = {
  pk: Scalars['Int'];
};

export type QueryengagementTrackingArgs = {
  engagementId: Scalars['Int'];
};

export type QueryengagementXhsPostMetricsForInsightArgs = {
  postId: Scalars['Int'];
};

export type QueryexistingProfileTagsArgs = {
  keyword: Scalars['String'];
};

export type QueryexternalAllInstagramFeedPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalAllInstagramFeedPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalAllInstagramPostsInDateArgs = {
  accountId: Scalars['Int'];
  postDate: Scalars['Date'];
};

export type QueryexternalAllInstagramReelPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalAllInstagramReelPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalAllInstagramStoryPostsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalAllInstagramStoryPostsCountArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalAnalyticsFacebookGetAccountDetailsArgs = {
  analyticsAccountId: Scalars['Int'];
};

export type QueryexternalAnalyticsFacebookGetTokenWarningsArgs = {
  analyticsAccountIdsToCheck: ReadonlyArray<Scalars['Int']>;
};

export type QueryexternalAnalyticsInstagramGetAccountDetailsArgs = {
  analyticsAccountId: Scalars['Int'];
};

export type QueryexternalAnalyticsInstagramGetTokenWarningsArgs = {
  analyticsAccountIdsToCheck: ReadonlyArray<Scalars['Int']>;
};

export type QueryexternalAnalyticsSocialAuthRedirectUrlArgs = {
  callbackUrl: Scalars['String'];
  provider: ExternalAnalyticsAuthSocialAccountType;
};

export type QueryexternalFacebookPageAnalyticsAudienceArgs = {
  endDate: Scalars['Date'];
  facebookPageAnalyticsAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryexternalFacebookPageAnalyticsOverviewArgs = {
  endDate: Scalars['Date'];
  facebookPageAnalyticsAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryexternalFacebookPageAnalyticsPostArgs = {
  facebookPageAnalyticsAccountId: Scalars['Int'];
  facebookPageAnalyticsPostId: Scalars['Int'];
};

export type QueryexternalFacebookPageAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  facebookPageAnalyticsAccountId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalFacebookPageAnalyticsPostsAnalyticsArgs = {
  endDate: Scalars['Date'];
  facebookPageAnalyticsAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
  timezoneOffset: Scalars['Int'];
};

export type QueryexternalFacebookPageAnalyticsPostsByDateArgs = {
  date: Scalars['Date'];
  facebookPageAnalyticsAccountId: Scalars['Int'];
};

export type QueryexternalFacebookPageAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  facebookPageAnalyticsAccountId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalInstagramAnalyticsPostDetailsArgs = {
  accountId: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QueryexternalInstagramAnalyticsReelDetailsArgs = {
  accountId: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QueryexternalInstagramAnalyticsStoryDetailsArgs = {
  accountId: Scalars['Int'];
  storyId: Scalars['Int'];
};

export type QueryexternalInstagramDashboardFollowerAnalyticsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalInstagramDashboardOverviewArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryexternalInstagramDashboardPostAnalyticsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  timezoneOffset: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsAudienceArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsOverviewArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsPostArgs = {
  youtubeAnalyticsAccountId: Scalars['Int'];
  youtubeAnalyticsPostId: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: ExternalYoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsPostsByDateArgs = {
  date: Scalars['Date'];
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsPostsStatisticsArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  timeDifferenceHour: Scalars['Int'];
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsRelatedPostsArgs = {
  tag: Scalars['String'];
  youtubeAnalyticsAccountId: Scalars['Int'];
  youtubeAnalyticsPostId: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsTagsTopRankingArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: ExternalYoutubeAnalyticsTagRankingSortInput;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryexternalYoutubeAnalyticsTagsTopRankingCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryfacebookPageAnalyticsAudienceArgs = {
  endDate: Scalars['Date'];
  facebookPageAnalyticsAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryfacebookPageAnalyticsOverviewArgs = {
  endDate: Scalars['Date'];
  facebookPageAnalyticsAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryfacebookPageAnalyticsPostArgs = {
  facebookPageAnalyticsAccountId: Scalars['Int'];
  facebookPageAnalyticsPostId: Scalars['Int'];
};

export type QueryfacebookPageAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  facebookPageAnalyticsAccountId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryfacebookPageAnalyticsPostsAnalyticsArgs = {
  endDate: Scalars['Date'];
  facebookPageAnalyticsAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryfacebookPageAnalyticsPostsByDateArgs = {
  date: Scalars['Date'];
  facebookPageAnalyticsAccountId: Scalars['Int'];
};

export type QueryfacebookPageAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  facebookPageAnalyticsAccountId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryfindJoinedCampaignsArgs = {
  influencerId: Scalars['Int'];
};

export type QuerygenerateAgencyLogoSignedUrlArgs = {
  fileNames: ReadonlyArray<Scalars['String']>;
};

export type QuerygenerateMaterialSignedUrlArgs = {
  fileNames: ReadonlyArray<Scalars['String']>;
};

export type QuerygeneratePostFileSignedUrlArgs = {
  fileName: Scalars['String'];
};

export type QuerygeneratePostFileSignedUrlsArgs = {
  fileNames: ReadonlyArray<Scalars['String']>;
};

export type QuerygenerateThumbnailSignedUrlArgs = {
  fileNames: ReadonlyArray<Scalars['String']>;
};

export type QuerygenerateUploadReportSignedUrlArgs = {
  fileNames: ReadonlyArray<Scalars['String']>;
};

export type QuerygetAllCampaignsCountForSearchJobArgs = {
  input: GetAllCampaignsCountForSearchJobInput;
};

export type QuerygetAllCampaignsForSearchJobArgs = {
  input: GetAllCampaignsForSearchJobInput;
};

export type QuerygetAnyXAccountsByKeywordArgs = {
  input: GetAnyXAccountsByKeywordInput;
};

export type QuerygetAutoManagedCampaignByIdArgs = {
  input: GetAutoManagedCampaignByIdInput;
};

export type QuerygetAutoManagedCampaignForInfluencerSearchJobArgs = {
  input: GetAutoManagedCampaignForInfluencerSearchJobInput;
};

export type QuerygetAutoManagedCampaignForInfluencerYourJobArgs = {
  input: GetAutoManagedCampaignForInfluencerYourJobInput;
};

export type QuerygetAutoManagedCampaignsArgs = {
  input: GetAutoManagedCampaignsInput;
};

export type QuerygetAutoManagedCampaignsCountArgs = {
  input: GetAutoManagedCampaignsCountInput;
};

export type QuerygetChatEventsArgs = {
  input: GetChatEventsInput;
};

export type QuerygetCouponsListArgs = {
  fileUrl: Scalars['String'];
};

export type QuerygetCreatorStaffsArgs = {
  input: CreatorStaffsInput;
};

export type QuerygetCreatorTagsArgs = {
  input: GetCreatorTagsInput;
};

export type QuerygetCreatorTagsByIdArgs = {
  input: GetCreatorTagsByIdInput;
};

export type QuerygetCustomerTagsArgs = {
  input: GetCustomerTagsInput;
};

export type QuerygetEmailListArgs = {
  input: GetEmailListInput;
};

export type QuerygetEmailListCountArgs = {
  input: GetEmailListCountInput;
};

export type QuerygetEmailOverviewArgs = {
  input: GetEmailOverviewInput;
};

export type QuerygetEmailRecipientsArgs = {
  input: GetEmailRecipientsInput;
};

export type QuerygetEmailRecipientsCountArgs = {
  input: GetEmailRecipientsCountInput;
};

export type QuerygetEmailTemplateArgs = {
  input: GetEmailTemplateInput;
};

export type QuerygetFanArgs = {
  input: GetFanInput;
};

export type QuerygetFanActivitiesArgs = {
  input: GetFanActivitiesInput;
};

export type QuerygetFanFormResponseArgs = {
  input: GetFanFormResponseInput;
};

export type QuerygetFanHeaderArgs = {
  input: GetFanInput;
};

export type QuerygetFanOrdersArgs = {
  input: GetFanOrdersInput;
};

export type QuerygetFanParticipationFormListArgs = {
  input: GetFanParticipationFormListInput;
};

export type QuerygetFanProvincesArgs = {
  input: GetFanProvincesInput;
};

export type QuerygetFansArgs = {
  input: GetFansInput;
};

export type QuerygetFansCountArgs = {
  input: GetFansCountInput;
};

export type QuerygetFormArgs = {
  input: GetFormInput;
};

export type QuerygetFormReportSummaryArgs = {
  input: FormReportSummaryInput;
};

export type QuerygetFormRespondentsCountArgs = {
  input: FormRespondentsCountInput;
};

export type QuerygetFormRespondentsListArgs = {
  input: FormRespondentsListInput;
};

export type QuerygetFormResponseArgs = {
  input: GetFormResponseInput;
};

export type QuerygetFormResponseByPageArgs = {
  input: GetFormResponseByPageInput;
};

export type QuerygetFormTemplateArgs = {
  input: GetFormTemplateInput;
};

export type QuerygetFormsCountArgs = {
  input: GetFormsCountInput;
};

export type QuerygetFormsListArgs = {
  input: GetFormsListInput;
};

export type QuerygetGeneratedMarketplaceCampaignDetailArgs = {
  input: GetGeneratedMarketplaceCampaignDetailInput;
};

export type QuerygetGeneratedMarketplacePostCaptionArgs = {
  input: GetGeneratedMarketplacePostCaptionInput;
};

export type QuerygetGeneratedTikTokSpecialPostCaptionArgs = {
  input: GetGeneratedTikTokSpecialPostCaptionInput;
};

export type QuerygetInfluencerBioAnalyticsArgs = {
  input: GetInfluencerBioAnalyticsInput;
};

export type QuerygetInfluencerRateCardArgs = {
  input: InfluencerRateCardInput;
};

export type QuerygetLineBroadcastEstimateRecipientsArgs = {
  input: GetLineBroadcastEstimateRecipientsInput;
};

export type QuerygetLineBroadcastMessageArgs = {
  input: GetLineBroadcastMessageInput;
};

export type QuerygetLineBroadcastSummaryArgs = {
  input: GetLineBroadcastSummaryInput;
};

export type QuerygetLineBroadcastsArgs = {
  input: GetLineBroadcastsInput;
};

export type QuerygetLineBroadcastsCountArgs = {
  input: GetLineBroadcastsCountInput;
};

export type QuerygetLineChatArgs = {
  input: GetLineChatInput;
};

export type QuerygetLineChatCountArgs = {
  input: GetLineChatCountInput;
};

export type QuerygetLineChatsArgs = {
  input: GetLineChatsInput;
};

export type QuerygetLinkBioFacebookAccountPostsArgs = {
  input: GetLinkBioFacebookAccountPostsInput;
};

export type QuerygetLinkBioFacebookPagePostsArgs = {
  input: GetLinkBioFacebookPagePostsInput;
};

export type QuerygetMarketplaceCampaignForInfluencerArgs = {
  pk: Scalars['Int'];
};

export type QuerygetNotificationsArgs = {
  input?: InputMaybe<UnreadNotificationInput>;
};

export type QuerygetNotificationsByAppArgs = {
  input?: InputMaybe<NotificationsByAppInput>;
};

export type QuerygetPublicInfluencerBioArgs = {
  input: GetPublicInfluencerBioInput;
};

export type QuerygetSocialAccountPermissionsArgs = {
  input: SocialAccountPermissionsInput;
};

export type QuerygetTagsAndFansRecipientsArgs = {
  input: GetTagsAndFansRecipientsInput;
};

export type QuerygetUserDefaultPermissionsArgs = {
  role: UserRoles;
};

export type QuerygetUserDetailArgs = {
  input: UserDetailInput;
};

export type QuerygetYoutubeMCNRedirectUrlForAdminArgs = {
  input: YoutubeMCNAdminRedirectUrlInput;
};

export type QueryhubspotCompanyListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryhubspotCompanyNameArgs = {
  hubspotId: Scalars['String'];
};

export type QueryhubspotDealNameArgs = {
  hubspotDealIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type QueryhubspotDealsListArgs = {
  countryId?: InputMaybe<Scalars['String']>;
  keyword: Scalars['String'];
};

export type QueryinfluencerAllInstagramFeedPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramFeedPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramInteractionUsersArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramInteractionUserOrderBy>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramMentionedPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramMentionedPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramPostsInDateArgs = {
  postDate: Scalars['Date'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerAllInstagramReelPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramReelPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramStoryPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramStoryPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramTaggedPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAllInstagramTaggedPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs = {
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs = {
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs = {
  socialAccountIds: ReadonlyArray<Scalars['Int']>;
};

export type QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs = {
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerAnalyticsTwitterRefreshAvailabilityArgs = {
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerCampaignListArgs = {
  campaignType?: InputMaybe<CampaignType>;
  keyword?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<InfluencerCampaignOrderBy>;
  pk: Scalars['Int'];
  socialMedias?: InputMaybe<ReadonlyArray<CampaignSocialMediaType>>;
};

export type QueryinfluencerCampaignListByPromotionMethodArgs = {
  influencerId: Scalars['Int'];
  promotionMethodId: Scalars['Int'];
};

export type QueryinfluencerCampaignSummaryArgs = {
  pk: Scalars['Int'];
};

export type QueryinfluencerFacebookPageAnalyticsAudienceArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerFacebookPageAnalyticsOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerFacebookPageAnalyticsPostArgs = {
  influencerId: Scalars['Int'];
  postId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerFacebookPageAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  influencerId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerFacebookPageAnalyticsPostsAnalyticsArgs = {
  endDate: Scalars['Date'];
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryinfluencerFacebookPageAnalyticsPostsByDateArgs = {
  date: Scalars['Date'];
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerFacebookPageAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  influencerId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerHasEstimateApiArgs = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerInstagramAnalyticsPostDetailsArgs = {
  postId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerInstagramAnalyticsReelDetailsArgs = {
  postId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerInstagramAnalyticsStoryDetailsArgs = {
  socialAccountId: Scalars['Int'];
  storyId: Scalars['Int'];
};

export type QueryinfluencerInstagramDashboardFollowerAnalyticsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerInstagramDashboardOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerInstagramDashboardPostAnalyticsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerInstagramInteractionMentionedPostDetailsArgs = {
  postId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerInstagramInteractionOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerInstagramInteractionPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerInstagramInteractionTaggedPostDetailsArgs = {
  postId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerInterestsArgs = {
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerMultipleSocialAccountsCountArgs = {
  influencerIds: ReadonlyArray<Scalars['Int']>;
  socialMedia: CampaignSocialMediaType;
};

export type QueryinfluencerPaymentRequestsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryinfluencerPostDetailArgs = {
  needSimilarPosts?: InputMaybe<Scalars['Boolean']>;
  pk: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QueryinfluencerPostDetailForTalentAgencyArgs = {
  needSimilarPosts?: InputMaybe<Scalars['Boolean']>;
  pk: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QueryinfluencerProfileAudienceArgs = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileDetailForEngagementProposalArgs = {
  engagementId: Scalars['Int'];
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileDetailV2Args = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileFollowerGrowthArgs = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileForEngagementProposalArgs = {
  engagementId: Scalars['Int'];
  influencerId: Scalars['Int'];
};

export type QueryinfluencerProfilePostsArgs = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileV2Args = {
  pk: Scalars['Int'];
};

export type QueryinfluencerTwitterAnalyticsOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerTwitterAnalyticsPostArgs = {
  socialAccountId: Scalars['Int'];
  twitterAnalyticsPostId: Scalars['Int'];
};

export type QueryinfluencerTwitterAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: TwitterAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerTwitterAnalyticsPostsByDateArgs = {
  date: Scalars['Date'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerTwitterAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerTwitterAnalyticsPostsStatisticArgs = {
  endDate: Scalars['Date'];
  socialAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryinfluencerUpdateDetailV2Args = {
  pk: Scalars['Int'];
};

export type QueryinfluencerWhitelistArgs = {
  marketplaceId: Scalars['Int'];
};

export type QueryinfluencerYoutubeAnalyticsAudienceArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeAnalyticsChannelDetailsArgs = {
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs = {
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerYoutubeAnalyticsCompareRelatedPostsArgs = {
  compareAccountId: Scalars['Int'];
  postId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  tag: Scalars['String'];
};

export type QueryinfluencerYoutubeAnalyticsOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeAnalyticsPostArgs = {
  socialAccountId: Scalars['Int'];
  youtubeAnalyticsPostId: Scalars['Int'];
};

export type QueryinfluencerYoutubeAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeAnalyticsPostsByDateArgs = {
  date: Scalars['Date'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerYoutubeAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeAnalyticsPostsStatisticsArgs = {
  endDate: Scalars['Date'];
  socialAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryinfluencerYoutubeAnalyticsRelatedPostsArgs = {
  socialAccountId: Scalars['Int'];
  tag: Scalars['String'];
  youtubeAnalyticsPostId: Scalars['Int'];
};

export type QueryinfluencerYoutubeAnalyticsShortsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeAnalyticsShortsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeAnalyticsSponsoredPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<YoutubeAnalyticsSponsoredPostsSortInput>;
  socialAccountId: Scalars['Long'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeAnalyticsSponsoredPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Long'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: YoutubeAnalyticsTagRankingSortInput;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeCompareAllPostsInDateArgs = {
  date: Scalars['Date'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerYoutubeCompareOverviewArgs = {
  endDate: Scalars['Date'];
  socialAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryinfluencerYoutubeComparePostByIdArgs = {
  compareAccountId: Scalars['Int'];
  postId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryinfluencerYoutubeComparePostListArgs = {
  compareAccountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: YoutubeAnalyticsComparePostsSort;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeComparePostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeComparePostsCountArgs = {
  compareAccountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinfluencerYoutubeCompareTagRankingArgs = {
  endDate: Scalars['Date'];
  socialAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryinstagramAccountInfoArgs = {
  accountId: Scalars['Int'];
};

export type QueryinstagramAmbassadorPostDetailsArgs = {
  accountId: Scalars['Int'];
  postId: Scalars['String'];
};

export type QueryinstagramAnalyticsHashtagsArgs = {
  accountId: Scalars['Int'];
};

export type QueryinstagramAnalyticsListCommentsArgs = {
  instagramAnalyticsAccountId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryinstagramAnalyticsListCommentsCountArgs = {
  instagramAnalyticsAccountId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
};

export type QueryinstagramAnalyticsPostDetailsArgs = {
  postId: Scalars['Int'];
};

export type QueryinstagramAnalyticsReelDetailsArgs = {
  accountId: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QueryinstagramAnalyticsStoryDetailsArgs = {
  accountId: Scalars['Int'];
  storyId: Scalars['Int'];
};

export type QueryinstagramCompareAccountsArgs = {
  instagramAnalyticsAccountId: Scalars['Int'];
};

export type QueryinstagramCompareFollowersArgs = {
  accountId: Scalars['Int'];
  comparableAccountIds: ReadonlyArray<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramCompareGetAccountsArgs = {
  accountToCompareWithId: Scalars['Int'];
};

export type QueryinstagramCompareOverviewArgs = {
  comparableAccountIds: ReadonlyArray<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  mainAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramComparePostDetailsArgs = {
  postId: Scalars['Int'];
};

export type QueryinstagramComparePostsArgs = {
  accountId: Scalars['Int'];
  comparableAccountIds: ReadonlyArray<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramDashboardFollowerAnalyticsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramDashboardOverviewArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramDashboardPostAnalyticsArgs = {
  accountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramHashtagFeedPostsV2Args = {
  date: Scalars['Date'];
  hashtagId: Scalars['Int'];
  instagramAnalyticsAccountId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramPostSortInput>;
};

export type QueryinstagramHashtagPostDetailsArgs = {
  postId: Scalars['Int'];
};

export type QueryinstagramHashtagPostsArgs = {
  analyticsAccountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramHashtagPostsV2Args = {
  date: Scalars['Date'];
  instagramAnalyticsAccountId: Scalars['Int'];
};

export type QueryinstagramHashtagsOverviewArgs = {
  analyticsAccountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramInteractionMentionedPostDetailsArgs = {
  accountId: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QueryinstagramInteractionOverviewArgs = {
  analyticsAccountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramInteractionPostsArgs = {
  analyticsAccountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryinstagramInteractionTaggedPostDetailsArgs = {
  accountId: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QueryinstagramUGCManagementBasicInfoArgs = {
  instagramAnalyticsAccountId: Scalars['Int'];
};

export type QueryinstagramUGCManagementListPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  hashtagSystemId?: InputMaybe<Scalars['Int']>;
  instagramAnalyticsAccountId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: InstagramUGCPostsSortInput;
  postType?: InputMaybe<UGCPostType>;
  startDate?: InputMaybe<Scalars['Date']>;
  statuses?: InputMaybe<ReadonlyArray<UGCPostStatus>>;
};

export type QueryinstagramUGCManagementListPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  hashtagSystemId?: InputMaybe<Scalars['Int']>;
  instagramAnalyticsAccountId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  postType?: InputMaybe<UGCPostType>;
  startDate?: InputMaybe<Scalars['Date']>;
  statuses?: InputMaybe<ReadonlyArray<UGCPostStatus>>;
};

export type QueryinstagramUGCManagementPostDetailsArgs = {
  instagramAnalyticsAccountId: Scalars['Int'];
  postSNSId: Scalars['String'];
};

export type QueryinstagramUserHashtagPostListArgs = {
  date: Scalars['Date'];
  hashtagId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<InstagramUserHashtagPostsSortInput>;
};

export type QuerymarketplaceArgs = {
  pk: Scalars['Int'];
};

export type QuerymarketplaceAffiliateCommissionFixedDatesArgs = {
  marketplaceId: Scalars['Int'];
};

export type QuerymarketplaceAffiliateCommissionInfluencersArgs = {
  fixedDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  marketplaceId: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<MarketplaceAffiliateCommissionStatus>;
};

export type QuerymarketplaceAffiliateCommissionInfluencersCountArgs = {
  fixedDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  marketplaceId: Scalars['Int'];
  status?: InputMaybe<MarketplaceAffiliateCommissionStatus>;
};

export type QuerymarketplaceAffiliateJoinedInfluencersArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  marketplaceId: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QuerymarketplaceAffiliateJoinedInfluencersCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  marketplaceId: Scalars['Int'];
};

export type QuerymarketplaceAffiliateReportByDateArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QuerymarketplaceAffiliateReportByDateCountArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QuerymarketplaceAffiliateReportByInfluencerArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<MarketplaceAffiliateReportByInfluencerOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QuerymarketplaceAffiliateReportByInfluencerCountArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QuerymarketplaceAffiliateReportCountForInfluencerArgs = {
  campaignId: Scalars['Int'];
};

export type QuerymarketplaceAffiliateReportForInfluencerArgs = {
  campaignId: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QuerymarketplaceAffiliateReportSummaryArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  showBy?: InputMaybe<AffiliateReportShowBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QuerymarketplaceAffiliateReportSummaryForInfluencerArgs = {
  campaignId: Scalars['Int'];
};

export type QuerymarketplaceCampaignPostReportForInfluencerArgs = {
  campaignId: Scalars['Int'];
};

export type QuerymarketplaceCampaignReportArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<CampaignReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QuerymarketplaceCampaignReportGridArgs = {
  campaignId: Scalars['Int'];
};

export type QuerymarketplaceCampaignReportSummaryArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QuerymarketplaceDraftPostArgs = {
  draftId: Scalars['Int'];
};

export type QuerymarketplaceDraftPostsForInfluencerArgs = {
  marketplaceId: Scalars['Int'];
};

export type QuerymarketplaceForInfluencerArgs = {
  pk: Scalars['Int'];
};

export type QuerymarketplaceGoogleSlidesHistoryArgs = {
  campaignId: Scalars['Int'];
};

export type QuerymarketplaceHashtagCloudArgs = {
  advertiser?: InputMaybe<Scalars['String']>;
  campaignCountry?: InputMaybe<Scalars['String']>;
  campaignTitle?: InputMaybe<Scalars['String']>;
  influencerCategory?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  postRequirements?: InputMaybe<Scalars['String']>;
  productInfo?: InputMaybe<Scalars['String']>;
  socialMedia?: InputMaybe<CampaignSocialMediaType>;
  tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type QuerymarketplaceJoinableAccountsArgs = {
  campaignId: Scalars['Int'];
};

export type QuerymarketplaceJoinedAccountArgs = {
  campaignId: Scalars['Int'];
};

export type QuerymarketplaceJoinedMethodsArgs = {
  input: MarketplaceJoinedMethodsInput;
};

export type QuerymarketplaceJoinedMethodsV2Args = {
  input: MarketplaceJoinedMethodsInput;
};

export type QuerymarketplacePostArgs = {
  pk: Scalars['Int'];
};

export type QuerymarketplacePostCommentsArgs = {
  limit: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QuerymarketplacePostHistoryArgs = {
  draftId?: InputMaybe<Scalars['Int']>;
  pk?: InputMaybe<Scalars['Int']>;
};

export type QuerymarketplacePostsNoRevenueArgs = {
  marketplacePostIds: ReadonlyArray<Scalars['Int']>;
};

export type QuerymarketplaceTrackingArgs = {
  marketplaceId: Scalars['Int'];
};

export type QuerymarketplaceTrackingPostUrlForInfluencerArgs = {
  marketplaceId: Scalars['Int'];
};

export type QuerynetsuiteCustomerListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type QuerynetsuiteCustomerNameArgs = {
  netsuiteId: Scalars['String'];
};

export type QuerynetsuiteVendorListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type QuerynetsuiteVendorNameArgs = {
  netsuiteId: Scalars['String'];
};

export type QueryopenAiMessageArgs = {
  runId: Scalars['String'];
  sourceFrom?: InputMaybe<OpenAIRunSourceFrom>;
  threadSystemId: Scalars['Int'];
};

export type QuerypackageArgs = {
  packageId: Scalars['Int'];
};

export type QuerypackageInfluencersArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<InfluencerSearchSortBy>;
  packageId: Scalars['Int'];
};

export type QuerypackageProposalArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  packageId: Scalars['Int'];
};

export type QuerypackageProposalSummaryArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  packageId: Scalars['Int'];
};

export type QuerypartnerAgencyArgs = {
  pk: Scalars['Int'];
};

export type QuerypartnerAgencyBusinessAssociatesArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  partnerAgencyId: Scalars['Int'];
};

export type QuerypartnerAgencyBusinessAssociatesCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  partnerAgencyId: Scalars['Int'];
};

export type QuerypartnerReconnectAnalyticsSocialAuthRedirectUrlArgs = {
  callbackUrl: Scalars['String'];
  provider: AnalyticsAuthSocialAccountType;
};

export type QuerypartnerYoutubeAnalyticsPostArgs = {
  partnerInfluencerAccountId: Scalars['Int'];
  youtubeAnalyticsPostId: Scalars['Int'];
};

export type QuerypaymentInformationArgs = {
  influencerId: Scalars['Int'];
};

export type QuerypaymentPackageStatusArgs = {
  sessionId: Scalars['String'];
};

export type QuerypricingTableArgs = {
  planType: PaymentPlanType;
};

export type QueryrefreshProfileAccountAvailabilityArgs = {
  input: RefreshProfileAccountAvailabilityInput;
};

export type QueryrefreshTwitterAccountAvailabilityArgs = {
  input: RefreshTwitterAccountAvailabilityInput;
};

export type QueryreportForInfluencerArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryrequestedBcaBrandsArgs = {
  influencerId: Scalars['Int'];
};

export type QuerysearchEngCampaignsSelectInfluencersArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  currentCampaignId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QuerysearchEngCampaignsSelectInfluencersCountArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  currentCampaignId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
};

export type QuerysearchPrivatePartnerInfluencersArgs = {
  alreadySelectedInfluencers: ReadonlyArray<Scalars['Int']>;
  keyword: Scalars['String'];
  partnerId: Scalars['Int'];
};

export type QuerysearchPrivateTalentInfluencersArgs = {
  alreadySelectedInfluencers: ReadonlyArray<Scalars['Int']>;
  keyword: Scalars['String'];
  talentAgencyId: Scalars['Int'];
};

export type QueryselectableBcaBrandsArgs = {
  influencerId: Scalars['Int'];
};

export type QueryshopifyAuthRedirectUrlArgs = {
  input: ShopifyAuthRedirectUrlInput;
};

export type QuerysocialAuthRedirectUrlForInfluencerArgs = {
  input: SocialAuthRedirectUrlForInfluencerInput;
};

export type QuerysponsoredPostsArgs = {
  influencerId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SponsoredPostOrderBy>;
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QuerysponsoredPostsCountArgs = {
  influencerId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QuerytalentAgencyArgs = {
  pk: Scalars['Int'];
};

export type QuerytalentAgencyBusinessAssociateCompaniesArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  talentAgencyId: Scalars['Int'];
};

export type QuerytalentAgencyBusinessAssociateCompaniesCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  talentAgencyId: Scalars['Int'];
};

export type QuerytalentAgencyReconnectAnalyticsSocialAuthRedirectUrlArgs = {
  callbackUrl: Scalars['String'];
  provider: AnalyticsAuthSocialAccountType;
};

export type QuerytalentInfluencerDetailsForEditArgs = {
  input: TalentInfluencerDetailsForEditInput;
};

export type QuerytiktokAdAccountsArgs = {
  tkSpecialCampaignId: Scalars['String'];
};

export type QuerytiktokAdAuthRedirectUrlArgs = {
  callbackUrl: Scalars['String'];
};

export type QuerytiktokAdCampaignsArgs = {
  adAccountSystemId: Scalars['String'];
  tkSpecialCampaignId: Scalars['String'];
};

export type QuerytiktokAdGroupsArgs = {
  adAccountSystemId: Scalars['String'];
  adCampaignId: Scalars['String'];
  tkSpecialCampaignId: Scalars['String'];
};

export type QuerytiktokAdvertiserPlanDataArgs = {
  advertiserId: Scalars['Int'];
};

export type QuerytiktokBudgetAllocationArgs = {
  advertiserId: Scalars['Int'];
};

export type QuerytiktokProfileAudienceArgs = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QuerytiktokSpecialCampaignArgs = {
  id: Scalars['Int'];
};

export type QuerytiktokSpecialCampaignForInfluencerSearchJobArgs = {
  id: Scalars['Int'];
};

export type QuerytiktokSpecialCampaignForInfluencerYourJobArgs = {
  id: Scalars['Int'];
};

export type QuerytiktokSpecialCampaignPostReportForInfluencerArgs = {
  campaignId: Scalars['Int'];
};

export type QuerytiktokSpecialCampaignPostsArgs = {
  campaignId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status?: InputMaybe<TikTokSpecialCampaignPostStatus>;
};

export type QuerytiktokSpecialCampaignPostsCountArgs = {
  campaignId: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TikTokSpecialCampaignPostStatus>;
};

export type QuerytiktokSpecialCampaignReportArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<TiktokSpecialReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QuerytiktokSpecialCampaignReportGridArgs = {
  campaignId: Scalars['Int'];
};

export type QuerytiktokSpecialCampaignReportSummaryArgs = {
  campaignId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QuerytiktokSpecialJoinableAccountsArgs = {
  campaignId: Scalars['Int'];
};

export type QuerytiktokSpecialJoinedAccountArgs = {
  campaignId: Scalars['Int'];
};

export type QuerytiktokSpecialPackageInfluencersArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  packageId: Scalars['Int'];
};

export type QuerytiktokSpecialPackageInfluencersCountArgs = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  genders?: InputMaybe<ReadonlyArray<Genders>>;
  keyword?: InputMaybe<Scalars['String']>;
  packageId: Scalars['Int'];
};

export type QuerytiktokSpecialPostArgs = {
  pk: Scalars['Int'];
};

export type QuerytiktokSpecialPostHistoryArgs = {
  pk: Scalars['Int'];
};

export type QuerytiktokTagPostDetailsArgs = {
  postId: Scalars['Int'];
};

export type QuerytiktokUserHashtagPostListArgs = {
  date: Scalars['Date'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<TiktokUserTagPostsSortInput>;
  tagId: Scalars['Int'];
};

export type QuerytiktokUserKeywordPostListArgs = {
  date: Scalars['Date'];
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<TiktokUserTagPostsSortInput>;
  tagId: Scalars['Int'];
};

export type QuerytrendingFacebookInfluencersArgs = {
  categoryIds: ReadonlyArray<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['String']>;
  followerGrowthPeriod: FollowersGrowthPeriodEnum;
  limit: Scalars['Int'];
  minFollowers?: InputMaybe<Scalars['Int']>;
  offset: Scalars['Int'];
};

export type QuerytrendingInstagramInfluencersV2Args = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryId?: InputMaybe<Scalars['String']>;
  followerGrowthPeriod: FollowersGrowthPeriodEnum;
  limit: Scalars['Int'];
  minFollowers?: InputMaybe<Scalars['Int']>;
  offset: Scalars['Int'];
};

export type QuerytrendingYoutubeInfluencersV2Args = {
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  countryId?: InputMaybe<Scalars['String']>;
  followerGrowthPeriod: FollowersGrowthPeriodEnum;
  limit: Scalars['Int'];
  minFollowers?: InputMaybe<Scalars['Int']>;
  offset: Scalars['Int'];
};

export type QuerytwitterAnalyticsOverviewArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  twitterAnalyticsAccountId: Scalars['Int'];
};

export type QuerytwitterAnalyticsPostArgs = {
  twitterAnalyticsAccountId: Scalars['Int'];
  twitterAnalyticsPostId: Scalars['Int'];
};

export type QuerytwitterAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: TwitterAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']>;
  twitterAnalyticsAccountId: Scalars['Int'];
};

export type QuerytwitterAnalyticsPostsByDateArgs = {
  date: Scalars['Date'];
  twitterAnalyticsAccountId: Scalars['Int'];
};

export type QuerytwitterAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  twitterAnalyticsAccountId: Scalars['Int'];
};

export type QuerytwitterAnalyticsPostsStatisticArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  twitterAnalyticsAccountId: Scalars['Int'];
};

export type QueryupdatePaymentMethodLinkArgs = {
  redirectUrl: Scalars['String'];
};

export type QueryuserArgs = {
  pk: Scalars['Int'];
};

export type QuerywordCloudListArgs = {
  influencerId: Scalars['Int'];
  socialAccountId: Scalars['Int'];
  socialMedia: SocialAccountType;
};

export type QueryxhsAccountInterestArgs = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryxhsProfileAudienceArgs = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryxhsProfileEngagementInsightsArgs = {
  pk: Scalars['Int'];
  postType: XhsPostType;
  socialAccountId: Scalars['Int'];
};

export type QueryxhsProfilePostsArgs = {
  pk: Scalars['Int'];
  socialAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsAudienceArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsCommentsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsCommentsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsCompareAccountCardsArgs = {
  mainAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsCompareRelatedPostsArgs = {
  compareAccountId: Scalars['Int'];
  mainAccountId: Scalars['Int'];
  postId: Scalars['Int'];
  tag: Scalars['String'];
};

export type QueryyoutubeAnalyticsOverviewArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsPostArgs = {
  youtubeAnalyticsAccountId: Scalars['Int'];
  youtubeAnalyticsPostId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsPostsByDateArgs = {
  date: Scalars['Date'];
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsPostsStatisticsArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsRelatedPostsArgs = {
  tag: Scalars['String'];
  youtubeAnalyticsAccountId: Scalars['Int'];
  youtubeAnalyticsPostId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsShortsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsShortsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsTagsTopRankingArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: YoutubeAnalyticsTagRankingSortInput;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsTagsTopRankingCountArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  youtubeAnalyticsAccountId: Scalars['Int'];
};

export type QueryyoutubeAnalyticsTrendPostByIdArgs = {
  id: Scalars['Int'];
};

export type QueryyoutubeAnalyticsTrendsArgs = {
  category: Scalars['Int'];
  country: Scalars['String'];
  date?: InputMaybe<Scalars['Date']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryyoutubeAnalyticsTrendsCountArgs = {
  category: Scalars['Int'];
  country: Scalars['String'];
  date?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeBrandAccountsArgs = {
  influencerId: Scalars['Int'];
  youtubeAccountSystemId: Scalars['Int'];
};

export type QueryyoutubeCmsChannelDetailArgs = {
  id?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeCmsChannelInfoArgs = {
  channelIds: ReadonlyArray<Scalars['String']>;
};

export type QueryyoutubeCmsChannelsForInfluencerArgs = {
  maxDate?: InputMaybe<Scalars['Date']>;
  minDate?: InputMaybe<Scalars['Date']>;
  month?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeCmsConfirmedAssetsSummaryArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  month: Scalars['Date'];
};

export type QueryyoutubeCmsConfirmedChannelsForInfluencerArgs = {
  month: Scalars['Date'];
};

export type QueryyoutubeCmsConfirmedChannelsSummaryArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  month: Scalars['Date'];
};

export type QueryyoutubeCmsConfirmedMusicsForInfluencerArgs = {
  month: Scalars['Date'];
};

export type QueryyoutubeCmsConfirmedRevenueForInfluencerArgs = {
  month?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeCmsConfirmedVideosForInfluencerArgs = {
  month: Scalars['Date'];
};

export type QueryyoutubeCmsEstimateAssetsSummaryArgs = {
  cmsChannelId?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeCmsEstimateChannelsSummaryArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeCmsInvoiceCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  month: Scalars['Date'];
};

export type QueryyoutubeCmsInvoiceListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  month: Scalars['Date'];
  offset: Scalars['Int'];
};

export type QueryyoutubeCmsInvoiceListForInfluencerArgs = {
  year: Scalars['Int'];
};

export type QueryyoutubeCmsMusicsForInfluencerArgs = {
  maxDate?: InputMaybe<Scalars['Date']>;
  minDate?: InputMaybe<Scalars['Date']>;
  month?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeCmsRevenueForInfluencerArgs = {
  maxDate?: InputMaybe<Scalars['Date']>;
  minDate?: InputMaybe<Scalars['Date']>;
  month?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeCmsVideoAudienceArgs = {
  month: Scalars['Date'];
  videoId: Scalars['String'];
};

export type QueryyoutubeCmsVideoStatsArgs = {
  month: Scalars['Date'];
  videoId: Scalars['String'];
};

export type QueryyoutubeCmsVideosForInfluencerArgs = {
  maxDate?: InputMaybe<Scalars['Date']>;
  minDate?: InputMaybe<Scalars['Date']>;
  month?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeCompareAllPostsInDateArgs = {
  date: Scalars['Date'];
  mainAccountId: Scalars['Int'];
};

export type QueryyoutubeCompareOverviewArgs = {
  endDate: Scalars['Date'];
  mainAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryyoutubeComparePostByIdArgs = {
  compareAccountId: Scalars['Int'];
  mainAccountId: Scalars['Int'];
  postId: Scalars['Int'];
};

export type QueryyoutubeComparePostListArgs = {
  compareAccountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  mainAccountId: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: YoutubeAnalyticsComparePostsSort;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeComparePostsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  mainAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeComparePostsCountArgs = {
  compareAccountId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  keyword?: InputMaybe<Scalars['String']>;
  mainAccountId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryyoutubeCompareTagRankingArgs = {
  endDate: Scalars['Date'];
  mainAccountId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type QueryyoutubeMusicListArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
};

export enum QuerySocialAccountType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_BUSINESS = 'INSTAGRAM_BUSINESS',
  LINE = 'LINE',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE'
}

export enum QuestionType {
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  SHORT_ANSWER = 'SHORT_ANSWER'
}

export type QuestionsInput = {
  readonly image?: InputMaybe<Scalars['String']>;
  readonly isRequired: Scalars['Boolean'];
  readonly options: ReadonlyArray<OptionInput>;
  readonly order: Scalars['Int'];
  readonly questionType: QuestionType;
  readonly title: Scalars['String'];
};

export type RateCardsInput = {
  readonly facebook?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly instagram?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly instagramStory?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly tiktok?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly twitter?: InputMaybe<InfluencerRateCardPriceInput>;
  readonly youtube?: InputMaybe<InfluencerRateCardPriceInput>;
};

export type ReSendBcaRequestInput = {
  readonly brandIgId: Scalars['Int'];
  readonly creatorIgId: Scalars['Int'];
};

export type ReSendBcaRequestPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ReadCampaignIdentifier = {
  readonly campaignDetailType?: Maybe<MarketplaceCampaignDetailType>;
  readonly campaignId: Scalars['Int'];
  readonly campaignType: CampaignIdentifierType;
  readonly hasPostTracking: Scalars['Boolean'];
};

export type ReadPackage = {
  readonly companies?: Maybe<ReadonlyArray<Maybe<PackageCompany>>>;
  readonly country: CountryName;
  /** Package Id */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type ReadPackageAccount = {
  readonly accounts?: Maybe<Scalars['Int']>;
  readonly campaignSelected?: Maybe<Scalars['Boolean']>;
  readonly companies?: Maybe<ReadonlyArray<PackageCompany>>;
  readonly country: CountryName;
  /** Package Id */
  readonly id: Scalars['Int'];
  readonly isDefault: Scalars['Boolean'];
  readonly name: Scalars['String'];
};

export type ReadPackageInfluencers = {
  readonly age: Scalars['Int'];
  readonly avatar: Scalars['String'];
  readonly averageEngagement: Scalars['Int'];
  readonly averageEngagementRate: Scalars['Float'];
  readonly averageEngagementViewsRate: Scalars['Float'];
  readonly categories: ReadonlyArray<Scalars['String']>;
  readonly country: CountryName;
  readonly engagementPostedCount: Scalars['Int'];
  readonly engagementProposedCount: Scalars['Int'];
  readonly followersCount: Scalars['Int'];
  readonly gender: Genders;
  readonly influencerId: Scalars['Int'];
  /** none is empty, true is green-icon, false is without gree-icon */
  readonly isAnySignedUpAccountForFacebook?: Maybe<Scalars['Boolean']>;
  /** none is empty, true is green-icon, false is without gree-icon */
  readonly isAnySignedUpAccountForInstagram?: Maybe<Scalars['Boolean']>;
  /** none is empty, true is green-icon, false is without gree-icon */
  readonly isAnySignedUpAccountForTiktok?: Maybe<Scalars['Boolean']>;
  /** none is empty, true is green-icon, false is without gree-icon */
  readonly isAnySignedUpAccountForTwitter?: Maybe<Scalars['Boolean']>;
  /** none is empty, true is green-icon, false is without gree-icon */
  readonly isAnySignedUpAccountForYoutube?: Maybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly socialAccounts: ReadonlyArray<ReadSocialAccountForPackage>;
  readonly status: SocialAccountStatus;
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type ReadSocialAccountForPackage = {
  readonly averageEngagement: Scalars['Int'];
  readonly averageEngagementRate: Scalars['Float'];
  readonly followersCount: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialMedia: SocialAccountType;
};

export type ReadTutorials = {
  readonly readTutorials: ReadonlyArray<TutorialName>;
};

export type RechargeAdvertiserAccount = {
  readonly checkoutUrl?: Maybe<Scalars['String']>;
  readonly ok: Scalars['Boolean'];
};

export type RechargeAdvertiserAccountInput = {
  readonly amount: Scalars['Int'];
  readonly redirectUrl: Scalars['String'];
};

export type RecipientDetail = {
  readonly email: Scalars['String'];
  readonly fanId: Scalars['Long'];
  readonly name: Scalars['String'];
  readonly statuses: ReadonlyArray<EmailEventStatus>;
};

export type RecipientItemInput = {
  readonly tagNames: ReadonlyArray<Scalars['String']>;
  readonly type: RecipientType;
};

export type RecipientItemPayload = {
  readonly tagNames: ReadonlyArray<Scalars['String']>;
  readonly type: RecipientType;
};

export enum RecipientType {
  ALL = 'ALL',
  WITH_CUSTOMER_TAGS = 'WITH_CUSTOMER_TAGS'
}

export type ReconnectTiktokAdAccount = {
  readonly ok: Scalars['Boolean'];
};

export type ReconnectTiktokAdAccountInput = {
  readonly adAccountId: Scalars['String'];
};

export type ReconnectedAccountForLinkBio = {
  readonly avatar: Scalars['String'];
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly socialAccountType: SocialAccountType;
  readonly url: Scalars['String'];
};

export enum RedirectType {
  CONNECT = 'CONNECT',
  JOIN_CAMPAIGN = 'JOIN_CAMPAIGN',
  RECONNECT_IG = 'RECONNECT_IG',
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  SIGNUP_CONNECT = 'SIGNUP_CONNECT',
  SIGNUP_ENABLED = 'SIGNUP_ENABLED',
  TALENT_SIGNIN = 'TALENT_SIGNIN',
  TALENT_SIGNUP = 'TALENT_SIGNUP'
}

export type RefreshPasswordInput = {
  readonly email?: InputMaybe<Scalars['String']>;
};

export type RefreshPasswordPayload = {
  readonly ok?: Maybe<Scalars['Boolean']>;
};

export type RefreshProfileAccountAvailabilityInput = {
  readonly socialAccountId: Scalars['Int'];
  readonly socialMedia: SocialAccountType;
};

export type RefreshProfileAccountAvailabilityPayload = {
  readonly status: RefreshProfileAvailabilityStatus;
};

export type RefreshProfileAccountInput = {
  readonly socialAccountId: Scalars['Int'];
  readonly socialMedia: SocialAccountType;
};

export type RefreshProfileAccountPayload = {
  readonly ok: Scalars['Boolean'];
};

export enum RefreshProfileAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NO_DATA = 'NO_DATA'
}

export type RefreshTwitterAccountAvailabilityInput = {
  readonly socialAccountId: Scalars['Int'];
};

export type RefreshTwitterAccountAvailabilityPayload = {
  readonly isRefreshAvailable: Scalars['Boolean'];
};

export type RefreshTwitterAccountInput = {
  readonly socialAccountId: Scalars['Int'];
};

export type RefreshTwitterAccountPayload = {
  readonly ok: Scalars['Boolean'];
};

export type Region = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type RegionName = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type RegisterPushToken = {
  readonly ok: Scalars['Boolean'];
};

export type RegisterPushTokenInput = {
  readonly influencerId: Scalars['Int'];
  readonly token: Scalars['String'];
};

export type RejectEngagementDraftPost = {
  readonly ok: Scalars['Boolean'];
};

export type RejectEngagementDraftPostInput = {
  readonly detailedReason?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly reason: Scalars['String'];
};

export type RejectMarketplaceCampaign = {
  readonly ok: Scalars['Boolean'];
};

export type RejectMarketplaceCampaignInput = {
  readonly marketplaceId: Scalars['Int'];
  readonly reason?: InputMaybe<Scalars['String']>;
};

export type RejectMarketplaceDraftPost = {
  readonly ok: Scalars['Boolean'];
};

export type RejectMarketplaceDraftPostInput = {
  readonly draftId: Scalars['Int'];
  readonly message: Scalars['String'];
};

export type RejectTikTokSpecialCampaign = {
  readonly ok: Scalars['Boolean'];
};

export type RejectTikTokSpecialCampaignInput = {
  readonly campaignId: Scalars['Int'];
  readonly reason?: InputMaybe<Scalars['String']>;
};

export type RemoveAllFormResponsesInput = {
  readonly formId: Scalars['Long'];
};

export type RemoveAllFormResponsesPayload = {
  readonly ok: Scalars['Boolean'];
};

export type RemoveAllSelectedInfluencers = {
  readonly ok: Scalars['Boolean'];
};

export type RemoveAllSelectedInfluencersInput = {
  readonly marketplaceId: Scalars['Int'];
};

export type RemoveCreatorStaffInput = {
  readonly creatorStaffId: Scalars['Int'];
};

export type RemoveCreatorStaffPayload = {
  readonly ok: Scalars['Boolean'];
};

export type RemoveEmailsInput = {
  readonly emailIds: ReadonlyArray<Scalars['Long']>;
};

export type RemoveEmailsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type RemoveFansInput = {
  readonly fanIds: ReadonlyArray<Scalars['Long']>;
};

export type RemoveFansPayload = {
  readonly ok: Scalars['Boolean'];
};

export type RemoveFormResponseInput = {
  readonly submissionId: Scalars['Long'];
};

export type RemoveFormResponsePayload = {
  readonly ok: Scalars['Boolean'];
};

export type RemoveFormsInput = {
  readonly formIds: ReadonlyArray<Scalars['Long']>;
};

export type RemoveFormsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type RemoveSelectedInfluencers = {
  readonly ok: Scalars['Boolean'];
};

export type RemoveSelectedInfluencersInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly marketplaceId: Scalars['Int'];
};

export type RemoveShopifyAccountInput = {
  readonly id: Scalars['Long'];
};

export type RemoveShopifyAccountPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ReorderMarketplaceCampaigns = {
  readonly ok: Scalars['Boolean'];
};

export type ReportEngagementPostStatus = {
  readonly ok: Scalars['Boolean'];
};

export type ReportEngagementPostStatusInput = {
  readonly detailedReason?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly reason: Scalars['String'];
};

export type ReportForInfluencer = {
  readonly campaign: CampaignReportCampaignInfoForInfluencer;
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment?: Maybe<Scalars['Int']>;
  readonly conversion?: Maybe<Scalars['Int']>;
  readonly currency: Scalars['String'];
  readonly like?: Maybe<Scalars['Int']>;
  readonly postStatus?: Maybe<CampaignPostStatusForInfluencer>;
  readonly revenue?: Maybe<Scalars['Float']>;
  readonly share?: Maybe<Scalars['Int']>;
  readonly view?: Maybe<Scalars['Int']>;
};

export type ReportMarketplacePostStatus = {
  readonly ok: Scalars['Boolean'];
};

export type ReportMarketplacePostStatusInput = {
  readonly detailedReason: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly reason: Scalars['String'];
};

export type ReportTiktokSpecialPostStatus = {
  readonly ok: Scalars['Boolean'];
};

export type ReportTiktokSpecialPostStatusInput = {
  readonly detailedReason: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly reason: Scalars['String'];
};

export type RequestDeleteAccount = {
  readonly ok: Scalars['Boolean'];
};

export type RequestDeleteAccountInput = {
  readonly comments?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly otherServices?: InputMaybe<Scalars['String']>;
  readonly reasons: ReadonlyArray<Scalars['String']>;
};

export type RequestGoogleSlideCustomisedReport = {
  readonly ok: Scalars['Boolean'];
};

export type RequestGoogleSlideReport = {
  readonly ok: Scalars['Boolean'];
};

export type RequestInfluencerProfilePptx = {
  readonly ok: Scalars['Boolean'];
};

export type RequestInfluencersProfilePptx = {
  readonly ok: Scalars['Boolean'];
};

export type RequestInfluencersSocialAccountPptx = {
  readonly ok: Scalars['Boolean'];
};

export type RequestInstagramUGCManagementPostInput = {
  readonly analyticsAccountId: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly postSnsId: Scalars['String'];
};

export type RequestInstagramUGCManagementPostOutput = {
  readonly ok: Scalars['Boolean'];
};

export type RequestPackageProposalExcel = {
  readonly ok: Scalars['Boolean'];
};

export type RequestPackageProposalPptx = {
  readonly ok: Scalars['Boolean'];
};

export type RequestPackageSpreadsheet = {
  readonly ok: Scalars['Boolean'];
};

export type RequestPowerpointCustomisedReport = {
  readonly ok: Scalars['Boolean'];
};

export type RequestPowerpointReport = {
  readonly ok: Scalars['Boolean'];
};

export type RequestProposalExcel = {
  readonly ok: Scalars['Boolean'];
};

export type RequestProposalPptx = {
  readonly ok: Scalars['Boolean'];
};

export type RequestProposalSpreadsheet = {
  readonly ok: Scalars['Boolean'];
};

export type RequestUserProposalExcel = {
  readonly ok: Scalars['Boolean'];
};

export type RequestUserProposalPptx = {
  readonly ok: Scalars['Boolean'];
};

export type RequestedBCABrand = {
  readonly brand: RequestedBrandForBCA;
  readonly instagramAccount: RequestedInstagramAccountForBCA;
  readonly isResendable: Scalars['Boolean'];
  readonly status: BcaRequestStatus;
};

export type RequestedBrandForBCA = {
  /** Analytics Instagram Account System Id */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type RequestedInstagramAccountForBCA = {
  /** Instagram Account System Id */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type ResendEmailForAdvertiser = {
  readonly ok?: Maybe<Scalars['Boolean']>;
};

export type ResendEmailForAdvertiserInput = {
  readonly email: Scalars['String'];
};

export type ResendInfluencerForgotPasswordEmailInput = {
  readonly email: Scalars['String'];
};

export type ResendInfluencerForgotPasswordEmailPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ResendInfluencerSignUpEmailInput = {
  readonly email: Scalars['String'];
};

export type ResendInfluencerSignUpEmailPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ResendPartnerEmails = {
  readonly ok: Scalars['Boolean'];
};

export type ResendPartnerEmailsInput = {
  readonly emails: ReadonlyArray<Scalars['String']>;
};

export type ResetPasswordAndLoginPayload = {
  readonly hash?: Maybe<Scalars['String']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly role: UserRoles;
  readonly token: Scalars['String'];
  readonly userId: Scalars['Int'];
};

export type ResetPasswordInput = {
  readonly password?: InputMaybe<Scalars['String']>;
  readonly token?: InputMaybe<Scalars['String']>;
};

export type ResetPasswordPayload = {
  readonly ok?: Maybe<Scalars['Boolean']>;
};

export type ResolveChatInput = {
  readonly chatId: Scalars['String'];
};

export type ResolveChatPayload = {
  readonly ok: Scalars['Boolean'];
};

export type RichMessageInput = {
  readonly imageUrl: Scalars['String'];
  readonly url?: InputMaybe<Scalars['String']>;
};

export type RichMessagePayload = {
  readonly imageUrl: Scalars['String'];
  readonly url?: Maybe<Scalars['String']>;
};

export enum SearchJobFilterParticipationType {
  OPEN_CAMPAIGN = 'OPEN_CAMPAIGN',
  SELECTION_CAMPAIGN = 'SELECTION_CAMPAIGN'
}

export enum SearchJobFilterRevenueCondition {
  PER_COMMENT = 'PER_COMMENT',
  PER_COMMISSION_RATE = 'PER_COMMISSION_RATE',
  PER_CONVERSION = 'PER_CONVERSION',
  PER_LIKE = 'PER_LIKE',
  PER_POST = 'PER_POST',
  PER_SHARE = 'PER_SHARE',
  PER_VIEW = 'PER_VIEW'
}

export enum SearchJobFilterRevenueType {
  AFFILIATE_CAMPAIGN = 'AFFILIATE_CAMPAIGN',
  POST_CAMPAIGN = 'POST_CAMPAIGN'
}

export enum SearchMode {
  ITEM_SEARCH = 'ITEM_SEARCH',
  KEYWORD_SEARCH = 'KEYWORD_SEARCH'
}

export type SelectableBCABrands = {
  readonly brands: ReadonlyArray<SelectableBrandForBCA>;
  readonly instagramAccounts: ReadonlyArray<SelectableInstagramAccountForBCA>;
  readonly isBcaAvailable: Scalars['Boolean'];
};

export type SelectableBrandForBCA = {
  /** Analytics Instagram Account System Id */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type SelectableInstagramAccountForBCA = {
  /** Instagram Account System Id */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type SelectableYoutubeAnalyticsChannel = {
  readonly avatar: Scalars['String'];
  readonly channelId: Scalars['String'];
  readonly channelName: Scalars['String'];
  readonly isUsed: Scalars['Boolean'];
  readonly subscribersCount: Scalars['Int'];
};

/** An enumeration. */
export enum SelectedInfluencers {
  SELECTED = 'SELECTED',
  UNSELECTED = 'UNSELECTED'
}

export type SelectedOauthPageAndIgAccount = {
  readonly igUserId: Scalars['String'];
  readonly pageId: Scalars['String'];
};

export type SelectedSocialAccounts = {
  readonly fbUserId?: InputMaybe<Scalars['String']>;
  readonly pageIds: ReadonlyArray<Scalars['String']>;
  readonly pagesAndIgAccounts: ReadonlyArray<SelectedOauthPageAndIgAccount>;
  readonly twitterUserIds: ReadonlyArray<Scalars['String']>;
  readonly youTubeChannelIds: ReadonlyArray<Scalars['String']>;
};

export type SendBcaRequestInput = {
  readonly brandIgId: Scalars['Int'];
  readonly creatorIgId: Scalars['Int'];
};

export type SendBcaRequestPayload = {
  readonly ok: Scalars['Boolean'];
};

export type SendContact = {
  readonly ok: Scalars['Boolean'];
};

export type SendContactInput = {
  readonly enquiry: Scalars['String'];
};

export type SendGeminiMessage = {
  readonly message: GeminiMessage;
};

export type SendGeminiMessageInput = {
  /** thread system id */
  readonly id: Scalars['Int'];
  readonly imageUrl?: InputMaybe<Scalars['String']>;
  readonly message?: InputMaybe<Scalars['String']>;
  readonly sourceFrom?: InputMaybe<GeminiSourceFrom>;
};

export type SendLineBroadcastMessageInput = {
  readonly isTest: Scalars['Boolean'];
  readonly lineBroadcastMessageId: Scalars['String'];
};

export type SendLineBroadcastMessagePayload = {
  readonly ok: Scalars['Boolean'];
};

export type SendMessageInput = {
  readonly chatId: Scalars['String'];
  readonly message: Scalars['String'];
};

export type SendMessagePayload = {
  readonly ok: Scalars['Boolean'];
};

export type SendOpenAIMessage = {
  readonly message: OpenAIMessage;
};

export type SendOpenAIMessageInput = {
  readonly id: Scalars['Int'];
  readonly imageUrl?: InputMaybe<Scalars['String']>;
  readonly message?: InputMaybe<Scalars['String']>;
  readonly sourceFrom?: InputMaybe<OpenAIRunSourceFrom>;
};

export type ShopeeCustomCampaignInput = {
  readonly countryId: Scalars['String'];
  readonly itemId?: InputMaybe<Scalars['String']>;
  readonly keyword?: InputMaybe<Scalars['String']>;
  readonly searchMode: SearchMode;
};

export type ShopeeCustomCampaignPayload = {
  readonly ok: Scalars['Boolean'];
};

export type ShopifyAuthRedirectUrlInput = {
  readonly callbackUrl: Scalars['String'];
  readonly shopName: Scalars['String'];
};

export type ShopifyAuthRedirectUrlPayload = {
  readonly redirectUri: Scalars['String'];
};

export enum ShopifyOrderPaymentStatus {
  AUTHORIZED = 'AUTHORIZED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  VOIDED = 'VOIDED'
}

export enum ShopifyOrderShippingStatus {
  FULFILLED = 'FULFILLED',
  PARTIAL = 'PARTIAL',
  RESTOCKED = 'RESTOCKED'
}

export type ShopifyValidateAuthInput = {
  readonly response: Scalars['String'];
  readonly shopName: Scalars['String'];
};

export type ShopifyValidateAuthPayload = {
  readonly ok: Scalars['Boolean'];
};

export type SignedUrlData = {
  readonly fileName: Scalars['String'];
  readonly signedUrl: Scalars['String'];
};

export type SimilarPost = {
  readonly image?: Maybe<Scalars['String']>;
  readonly influencerId: Scalars['Int'];
  readonly postId: Scalars['Int'];
  readonly postType: SocialPostType;
  readonly socialAccountId: Scalars['Int'];
  readonly username: Scalars['String'];
};

/**  TODO old API. don't forget to remove */
export type SocialAccount = {
  readonly exclusive?: Maybe<Scalars['Boolean']>;
  readonly url?: Maybe<Scalars['String']>;
};

export type SocialAccountDescription = {
  readonly socialAccountName: Scalars['String'];
  readonly socialAccountType: SocialAccountType;
};

export type SocialAccountForProfileV2 = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
};

export type SocialAccountInfluencerAudience = {
  readonly age?: InputMaybe<FollowersAgeGroupInput>;
  readonly gender: Genders;
  readonly minPercentage?: InputMaybe<Scalars['Float']>;
};

export type SocialAccountInfluencerDemographicsAge = {
  readonly age: FollowersAgeGroupInput;
  readonly minPercentage: Scalars['Float'];
};

export type SocialAccountInfluencerDemographicsGender = {
  readonly gender: Genders;
  readonly minPercentage: Scalars['Float'];
};

/** An enumeration. */
export enum SocialAccountInfluencerListSortField {
  AGE = 'AGE',
  AVG_LIKE = 'AVG_LIKE',
  AVG_REACH = 'AVG_REACH',
  AVG_VIEW = 'AVG_VIEW',
  CATEGORY = 'CATEGORY',
  COMMENT = 'COMMENT',
  COUNTRY = 'COUNTRY',
  DISLIKE = 'DISLIKE',
  ENGAGEMENT_POSTED_COUNT = 'ENGAGEMENT_POSTED_COUNT',
  ENGAGEMENT_PROPOSED_COUNT = 'ENGAGEMENT_PROPOSED_COUNT',
  GENDER = 'GENDER',
  ID = 'ID',
  INFLUENCER_ENGAGEMENT = 'INFLUENCER_ENGAGEMENT',
  INFLUENCER_ENGAGEMENT_RATE = 'INFLUENCER_ENGAGEMENT_RATE',
  INFLUENCER_FOLLOWERS = 'INFLUENCER_FOLLOWERS',
  LIKE = 'LIKE',
  MARKETPLACE_JOINED_COUNT = 'MARKETPLACE_JOINED_COUNT',
  MARKETPLACE_POSTED_COUNT = 'MARKETPLACE_POSTED_COUNT',
  ML_API = 'ML_API',
  REACH = 'REACH',
  REPLY = 'REPLY',
  RETWEET = 'RETWEET',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  SOCIAL_ACCOUNT_AVG_ENGAGEMENT = 'SOCIAL_ACCOUNT_AVG_ENGAGEMENT',
  SOCIAL_ACCOUNT_ENGAGEMENT = 'SOCIAL_ACCOUNT_ENGAGEMENT',
  SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE',
  SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE',
  SOCIAL_ACCOUNT_FOLLOWERS = 'SOCIAL_ACCOUNT_FOLLOWERS',
  STATUS = 'STATUS',
  VIEW = 'VIEW'
}

export enum SocialAccountInfluencerListSortFieldV2 {
  AVG_LIKE = 'AVG_LIKE',
  AVG_REACH = 'AVG_REACH',
  AVG_VIEW = 'AVG_VIEW',
  CREATED = 'CREATED',
  RELEVANT = 'RELEVANT',
  SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE',
  SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE',
  SOCIAL_ACCOUNT_FOLLOWERS = 'SOCIAL_ACCOUNT_FOLLOWERS'
}

export type SocialAccountInfluencerOrderBy = {
  readonly field?: InputMaybe<SocialAccountInfluencerListSortField>;
  readonly order?: InputMaybe<ORDER>;
};

export type SocialAccountInfluencerOrderByV2 = {
  readonly field?: InputMaybe<SocialAccountInfluencerListSortFieldV2>;
  readonly order?: InputMaybe<Order>;
};

export type SocialAccountPairInput = {
  readonly influencerId: Scalars['Int'];
  readonly socialAccountId?: InputMaybe<Scalars['Int']>;
  readonly socialType?: InputMaybe<SocialAccountType>;
};

export type SocialAccountPermissionsInput = {
  readonly type: SocialAccountType;
};

export type SocialAccountPopularPost = {
  readonly caption: Scalars['String'];
  readonly postId: Scalars['ID'];
  readonly thumbNail?: Maybe<Scalars['String']>;
  readonly type: SocialPostType;
};

export enum SocialAccountStatus {
  SCRAPING = 'SCRAPING',
  SIGNED_UP = 'SIGNED_UP'
}

export enum SocialAccountType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  LINE = 'LINE',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE'
}

export type SocialAccountV2 = {
  readonly isAnySignedUpAccount: Scalars['Boolean'];
};

export type SocialAuthCheckFacebookTokenPayload = {
  readonly needReconnect: Scalars['Boolean'];
};

export type SocialAuthConnectableFacebookPageAnalyticsInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type SocialAuthConnectableFacebookPageAnalyticsPayload = {
  readonly account?: Maybe<ConnectableFacebookPageMainAccount>;
  readonly pages: ReadonlyArray<ConnectableFacebookPageAccount>;
};

export type SocialAuthConnectableInstagramAccountsAnalyticsInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

/**  Stores Instagram accounts that can be connected based on a Facebook account */
export type SocialAuthConnectableInstagramAccountsAnalyticsPayload = {
  readonly accounts: ReadonlyArray<ConnectableOauthPageAndIgAccount>;
};

export type SocialAuthConnectableTwitterAccountAnalyticsInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type SocialAuthConnectableTwitterAccountAnalyticsOutput = {
  readonly avatar: Scalars['String'];
  readonly followersCount: Scalars['Int'];
  readonly isUsed: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly twitterUserId: Scalars['String'];
  readonly username: Scalars['String'];
};

/**  socialAuthForJoinCampaign */
export type SocialAuthForJoinCampaignInput = {
  readonly callbackUrl?: InputMaybe<Scalars['String']>;
  readonly provider: AuthSocialAccountType;
  readonly response: Scalars['String'];
  readonly selectedSocialAccountId?: InputMaybe<Scalars['ID']>;
};

export type SocialAuthForJoinCampaignPayload = {
  readonly ok: Scalars['Boolean'];
};

export type SocialAuthReConnectInstagramInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type SocialAuthReConnectInstagramPayload = {
  readonly token: Scalars['String'];
};

/**
 *  `response`: response from Facebook API. Used to get the access token
 *  `callbackUrl`: callback URL from Facebook API. Used to get the access token
 *  `accountId`: analytics account id.
 *  `fbPageId`: id of the Facebook page the reconnected account is connected to.
 */
export type SocialAuthReconnectFacebookPageAccountAnalyticsInput = {
  readonly accountId: Scalars['Int'];
  readonly callbackUrl: Scalars['String'];
  readonly fbPageId: Scalars['String'];
  readonly response: Scalars['String'];
};

export type SocialAuthReconnectFacebookPageAccountAnalyticsPayload = {
  readonly ok: Scalars['Boolean'];
};

/**
 *  `businessAccountId`: Instagram business account id.
 *  `response`: response from Facebook API. Used to get the access token
 *  `callbackUrl`: callback URL from Facebook API. Used to get the access token
 *  `redirectType`: type of redirect. We have many pages to redirect to when Facebook is done with processing our requests.
 *   We can go back to "account list", "add accounts" page, and etc. Used to get the access token
 *  `accountId`: analytics account id.
 *  `username`: reconnected Instagram account username.
 *  `fbPageId`: id of the Facebook page the reconnected account is connected to.
 */
export type SocialAuthReconnectInstagramAccountAnalyticsInput = {
  readonly accountId: Scalars['Int'];
  readonly businessAccountId: Scalars['String'];
  readonly callbackUrl: Scalars['String'];
  readonly fbPageId?: InputMaybe<Scalars['String']>;
  readonly redirectType: AnalyticsRedirectType;
  readonly response: Scalars['String'];
  readonly username: Scalars['String'];
};

export type SocialAuthReconnectInstagramAccountAnalyticsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type SocialAuthReconnectTwitterAccountAnalyticsInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type SocialAuthReconnectTwitterAccountAnalyticsPayload = {
  readonly ok: Scalars['Boolean'];
};

/**
 *  `channelId`: id of the YouTube channel the reconnected account is connected to.
 *  `callbackUrl`: callback URL from Google API. Used to get the access token
 *  `response`: response from Google API. Used to get the access token
 */
export type SocialAuthReconnectYouTubeAccountAnalyticsInput = {
  readonly callbackUrl: Scalars['String'];
  readonly channelId: Scalars['String'];
  readonly response: Scalars['String'];
};

export type SocialAuthReconnectYouTubeAccountAnalyticsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type SocialAuthRedirectUrlForInfluencerInput = {
  readonly callbackUrl: Scalars['String'];
  readonly provider: AuthSocialAccountType;
};

export type SocialAuthRedirectUrlForInfluencerPayload = {
  readonly redirectUri: Scalars['String'];
};

/**  Talent & Partner */
export type SocialAuthTalentSignInFacebookInput = {
  readonly callbackUrl: Scalars['String'];
  readonly influencerId?: InputMaybe<Scalars['Int']>;
  readonly response: Scalars['String'];
};

export type SocialAuthTalentSignInFacebookPayload = {
  readonly fbAvatar: Scalars['String'];
  readonly fbFollowersCount: Scalars['Int'];
  readonly fbName: Scalars['String'];
  readonly fbUserId: Scalars['String'];
  readonly pages: ReadonlyArray<ConnectableOauthPageAndIgAccountForSignInFacebook>;
  readonly status: SocialAccountStatus;
};

export type SocialAuthTalentSignInInstagramInput = {
  readonly callbackUrl: Scalars['String'];
  readonly influencerId?: InputMaybe<Scalars['Int']>;
  readonly response: Scalars['String'];
  readonly username?: InputMaybe<Scalars['String']>;
};

export type SocialAuthTalentSignInInstagramPayload = {
  readonly facebook: ConnectableOauthFacebookAccountForTalentSignIn;
  readonly pagesAndIgAccounts: ReadonlyArray<ConnectableOauthPageAndIgAccountForTalentSignIn>;
};

export type SocialAuthTalentSignInTwitterInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
  readonly screenName?: InputMaybe<Scalars['String']>;
};

export type SocialAuthTalentSignInTwitterPayload = {
  readonly status: SocialAccountStatus;
  readonly twName: Scalars['String'];
  readonly twScreenName: Scalars['String'];
  readonly twUserId: Scalars['String'];
};

export type SocialAuthTalentSignInYouTubeInput = {
  readonly callbackUrl: Scalars['String'];
  readonly channelId?: InputMaybe<Scalars['String']>;
  readonly response: Scalars['String'];
};

export type SocialAuthTalentSignInYouTubePayload = {
  readonly channels: ReadonlyArray<ConnectableOauthYouTubeAccountForTalentSignIn>;
};

export enum SocialMediaTypeForRateCard {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE'
}

export enum SocialPostType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_REEL = 'INSTAGRAM_REEL',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE'
}

export type SponsoredPostOrderBy = {
  readonly field?: InputMaybe<SponsoredPostSortField>;
  readonly order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum SponsoredPostSortField {
  COMMENT = 'COMMENT',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKE = 'LIKE',
  POST_DATE = 'POST_DATE',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  VIEW = 'VIEW'
}

export type StaffComment = {
  readonly content: Scalars['String'];
  readonly date: Scalars['String'];
};

export type StateUpdateEvent = ChatEvent & {
  readonly id: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type StripeDeepLink = {
  readonly url: Scalars['String'];
};

export type SubmitFormInput = {
  readonly id: Scalars['Long'];
  readonly questions: ReadonlyArray<SubmitQuestion>;
  readonly recaptchaResponse: Scalars['String'];
};

export type SubmitFormInternalInput = {
  readonly id: Scalars['Long'];
  readonly questions: ReadonlyArray<SubmitQuestionInternal>;
};

export type SubmitFormInternalPayload = {
  readonly ok: Scalars['Boolean'];
};

export type SubmitFormPayload = {
  readonly ok: Scalars['Boolean'];
};

export type SubmitQuestion = {
  readonly answeredOptionIds: ReadonlyArray<Scalars['Long']>;
  readonly answeredTexts: ReadonlyArray<Scalars['String']>;
  readonly questionId: Scalars['Long'];
};

export type SubmitQuestionInternal = {
  readonly answeredOptionIds: ReadonlyArray<Scalars['Long']>;
  readonly answeredTexts: ReadonlyArray<Scalars['String']>;
  readonly questionId: Scalars['Long'];
};

export type SubscribeAnalyticsPlan = {
  readonly ok: Scalars['Boolean'];
};

export type SubscribeAnalyticsPlanInput = {
  /** advertiser's system id */
  readonly advertiserId: Scalars['Int'];
  /** the selected plan */
  readonly plan: AnalyticsSubscriptionPlanType;
};

export type SubscribeAnalyticsTrialPlan = {
  readonly ok: Scalars['Boolean'];
};

export type SubscriptionAvailableFeatures = {
  readonly compareEnabled: Scalars['Boolean'];
  readonly dashboardEnabled: Scalars['Boolean'];
  readonly hashtagsEnabled: Scalars['Boolean'];
  readonly interactionEnabled: Scalars['Boolean'];
};

export type SubscriptionCapabilities = {
  readonly maxCompareAccounts: Scalars['Int'];
  readonly maxHashtags: Scalars['Int'];
};

export type SubscriptionPlan = {
  readonly analytics?: Maybe<SubscriptionPlanDetail>;
  readonly marketplace?: Maybe<SubscriptionPlanDetail>;
};

export type SubscriptionPlanDetail = {
  readonly currency: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly expiredAt?: Maybe<Scalars['DateTime']>;
  /** price id */
  readonly id?: Maybe<Scalars['Int']>;
  readonly isCanceled: Scalars['Boolean'];
  readonly isExpired: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly price: Scalars['Float'];
};

export type SubscriptionPlanForAdvertiserUserSettings = {
  readonly availableFeatures: SubscriptionAvailableFeatures;
  readonly capabilities: SubscriptionCapabilities;
  readonly id: Scalars['Int'];
  readonly type: AnalyticsSubscriptionPlanType;
};

export type SudoLimitedLogin = {
  readonly token: Scalars['String'];
};

export type SudoLimitedLoginInput = {
  readonly userId: Scalars['Int'];
};

export type SwitchInfluencerForStaffInput = {
  readonly influencerId: Scalars['Int'];
};

export type SwitchInfluencerForStaffPayload = {
  readonly token: Scalars['String'];
};

export type SwitchRoleToAdmin = {
  readonly ok: Scalars['Boolean'];
};

export type SwitchRoleToAdminInput = {
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToAdvertiser = {
  readonly ok: Scalars['Boolean'];
};

export type SwitchRoleToAdvertiserInput = {
  readonly advertiserId: Scalars['Int'];
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly notificationSetting: Scalars['Boolean'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToAgency = {
  readonly ok: Scalars['Boolean'];
};

export type SwitchRoleToAgencyInput = {
  readonly agencyId: Scalars['Int'];
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToPartner = {
  readonly ok: Scalars['Boolean'];
};

export type SwitchRoleToPartnerInput = {
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly partnerId: Scalars['Int'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToStaff = {
  readonly ok: Scalars['Boolean'];
};

export type SwitchRoleToStaffInput = {
  readonly advertiserIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToTalentAgency = {
  readonly ok: Scalars['Boolean'];
};

export type SwitchRoleToTalentAgencyInput = {
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
  readonly talentAgencyId: Scalars['Int'];
};

export enum TTCMStatus {
  APPROVED = 'APPROVED',
  INVITED = 'INVITED',
  NOT_INVITED = 'NOT_INVITED',
  REJECTED = 'REJECTED'
}

export type TalentAgency = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type TalentAgencyOrPartner = {
  /** unique result index used for FE purpose */
  readonly id: Scalars['Int'];
  /** id of Talent Agency or Partner */
  readonly idOfTalentOrPartner: Scalars['Int'];
  /** name of Talent Agency or Partner */
  readonly name: Scalars['String'];
  /** TALENT_AGENCY or PARTNER */
  readonly role: UserRoles;
};

export enum TalentAgencyReconnectAnalyticsRedirectType {
  ANY_CREATOR_INFLUENCER_PROFILE_CONNECT = 'ANY_CREATOR_INFLUENCER_PROFILE_CONNECT',
  ANY_TAG_INFLUENCER_PROFILE_CONNECT = 'ANY_TAG_INFLUENCER_PROFILE_CONNECT'
}

/**  Stores a redirect URL that FE uses to reconnect talent influencer accounts */
export type TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload = {
  readonly redirectUri: Scalars['String'];
};

export type TalentAgencyUserWithRole = {
  readonly companyName?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly role: UserRoles;
};

export type TalentAgencyWithCompanies = {
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  readonly agencyIds: ReadonlyArray<Scalars['Int']>;
  readonly cmsMarginRate?: Maybe<Scalars['Float']>;
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country?: Maybe<CountryName>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly netsuiteId?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly privateInfluencers: ReadonlyArray<PrivateTalentInfluencer>;
};

export type TalentAgencyWithNSVerification = {
  readonly companyUrl?: Maybe<Scalars['String']>;
  readonly country?: Maybe<CountryName>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly netsuiteId?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly users: Scalars['Int'];
  readonly verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
};

export type TalentInfluencerDetailsForEditInput = {
  readonly influencerId: Scalars['Int'];
};

export type TalentInfluencerDetailsForEditPayload = {
  readonly category: ReadonlyArray<Category>;
  readonly connectedFacebook?: Maybe<ConnectedFacebook>;
  readonly connectedFacebookPages: ReadonlyArray<ConnectedPage>;
  readonly connectedInstagrams: ReadonlyArray<ConnectedPageAndIgAccount>;
  readonly connectedTwitters: ReadonlyArray<ConnectedTwitter>;
  readonly connectedYouTubes: ReadonlyArray<ConnectedYouTube>;
  readonly country: Country;
  readonly dateOfBirth?: Maybe<Scalars['String']>;
  readonly defaultEngagementSelectionReason: Scalars['String'];
  readonly email?: Maybe<Scalars['String']>;
  readonly gender: Genders;
  readonly internalMemo: Scalars['String'];
  readonly introduce: Scalars['String'];
  readonly isProAccount: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Region>;
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type TextComponentInput = {
  readonly fontSize: FontSize;
  readonly text: Scalars['String'];
  readonly weighted: Scalars['Boolean'];
};

export type TextComponentPayload = {
  readonly fontSize: FontSize;
  readonly text: Scalars['String'];
  readonly weighted: Scalars['Boolean'];
};

export type ThreadsInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly comments?: Maybe<Scalars['Int']>;
  readonly country: CountryName;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  /** Influencer Id */
  readonly id: Scalars['Int'];
  readonly influencerEngagement?: Maybe<Scalars['Int']>;
  readonly influencerEngagementRate?: Maybe<Scalars['Float']>;
  readonly influencerFollowers?: Maybe<Scalars['Int']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly socialAccountAverageEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  /** Social Account System Id */
  readonly socialAccountId: Scalars['Int'];
  /** Social Account Status */
  readonly socialAccountStatus: SocialAccountStatus;
};

export type TikTokAdChartReport = {
  readonly adCost: Scalars['Float'];
  readonly adImpressions: Scalars['Int'];
  readonly date: Scalars['Date'];
};

/** An enumeration. */
export enum TikTokAdReportOrderField {
  AD_BUDGET = 'AD_BUDGET',
  AD_CLICKS = 'AD_CLICKS',
  AD_COMMENTS = 'AD_COMMENTS',
  AD_COST = 'AD_COST',
  AD_COST_PER_1000_REACHED = 'AD_COST_PER_1000_REACHED',
  AD_COST_PER_RESULT = 'AD_COST_PER_RESULT',
  AD_CPC = 'AD_CPC',
  AD_CPM = 'AD_CPM',
  AD_CTR = 'AD_CTR',
  AD_ENGAGEMENTS = 'AD_ENGAGEMENTS',
  AD_ER = 'AD_ER',
  AD_FOLLOWS = 'AD_FOLLOWS',
  AD_FREQUENCY = 'AD_FREQUENCY',
  AD_IMPRESSIONS = 'AD_IMPRESSIONS',
  AD_LIKES = 'AD_LIKES',
  AD_REACH = 'AD_REACH',
  AD_RESULT = 'AD_RESULT',
  AD_RESULT_RATE = 'AD_RESULT_RATE',
  AD_SHARES = 'AD_SHARES',
  AD_STATUS = 'AD_STATUS',
  AVERAGE_VIDEO_PLAY = 'AVERAGE_VIDEO_PLAY',
  AVERAGE_VIDEO_PLAY_PER_USER = 'AVERAGE_VIDEO_PLAY_PER_USER',
  INTERACTIVE_ADD_ON_DESTINATION_CLICKS = 'INTERACTIVE_ADD_ON_DESTINATION_CLICKS',
  INTERACTIVE_ADD_ON_IMPRESSIONS = 'INTERACTIVE_ADD_ON_IMPRESSIONS',
  VIDEO_PLAY_ACTIONS = 'VIDEO_PLAY_ACTIONS',
  VIDEO_VIEWS_P25 = 'VIDEO_VIEWS_P25',
  VIDEO_VIEWS_P50 = 'VIDEO_VIEWS_P50',
  VIDEO_VIEWS_P75 = 'VIDEO_VIEWS_P75',
  VIDEO_VIEWS_P100 = 'VIDEO_VIEWS_P100',
  VIDEO_WATCHED_2S = 'VIDEO_WATCHED_2S',
  VIDEO_WATCHED_6S = 'VIDEO_WATCHED_6S'
}

export type TikTokAdReportSummary = {
  readonly adBudget?: Maybe<Scalars['Float']>;
  readonly adClicks?: Maybe<Scalars['Int']>;
  readonly adComments?: Maybe<Scalars['Int']>;
  readonly adCost?: Maybe<Scalars['Float']>;
  readonly adCostPer1000Reached?: Maybe<Scalars['Float']>;
  readonly adCostPerResult?: Maybe<Scalars['Float']>;
  readonly adCpc?: Maybe<Scalars['Float']>;
  readonly adCpm?: Maybe<Scalars['Float']>;
  readonly adCtr?: Maybe<Scalars['Float']>;
  readonly adEngagements?: Maybe<Scalars['Int']>;
  readonly adEr?: Maybe<Scalars['Float']>;
  readonly adFollows?: Maybe<Scalars['Int']>;
  readonly adFrequency?: Maybe<Scalars['Int']>;
  readonly adImpressions?: Maybe<Scalars['Int']>;
  readonly adLikes?: Maybe<Scalars['Int']>;
  readonly adReach?: Maybe<Scalars['Int']>;
  readonly adResult?: Maybe<Scalars['Int']>;
  readonly adResultRate?: Maybe<Scalars['Float']>;
  readonly adShares?: Maybe<Scalars['Int']>;
  readonly averageVideoPlay?: Maybe<Scalars['Int']>;
  readonly averageVideoPlayPerUser?: Maybe<Scalars['Int']>;
  readonly interactiveAddOnDestinationClicks?: Maybe<Scalars['Int']>;
  readonly interactiveAddOnImpressions?: Maybe<Scalars['Int']>;
  readonly videoPlayActions?: Maybe<Scalars['Int']>;
  readonly videoViewsP25?: Maybe<Scalars['Int']>;
  readonly videoViewsP50?: Maybe<Scalars['Int']>;
  readonly videoViewsP75?: Maybe<Scalars['Int']>;
  readonly videoViewsP100?: Maybe<Scalars['Int']>;
  readonly videoWatched2s?: Maybe<Scalars['Int']>;
  readonly videoWatched6s?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum TikTokAdReportType {
  AD = 'AD',
  AD_CAMPAIGN = 'AD_CAMPAIGN',
  AD_GROUP = 'AD_GROUP'
}

/** An enumeration. */
export enum TikTokAdStatus {
  DELETE = 'DELETE',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE'
}

/** An enumeration. */
export enum TikTokAdvertiserPlanFilter {
  BEGINNER = 'BEGINNER',
  ENTERPRISE = 'ENTERPRISE',
  NO_PLAN = 'NO_PLAN',
  STANDARD = 'STANDARD'
}

/** An enumeration. */
export enum TikTokCampaignType {
  NORMAL = 'NORMAL',
  TTCM = 'TTCM'
}

export type TikTokProductCategory = {
  readonly id: Scalars['Int'];
  readonly name: TikTokProductCategoryName;
  readonly packageId: Scalars['Int'];
};

/** An enumeration. */
export enum TikTokProductCategoryName {
  BEAUTY = 'BEAUTY',
  ELECTRONIC = 'ELECTRONIC',
  FASHION = 'FASHION',
  FMCG = 'FMCG',
  LIFESTYLE = 'LIFESTYLE',
  OTHERS = 'OTHERS'
}

export type TikTokSpecialAdPostInfo = {
  readonly adAccountSystemId: Scalars['Int'];
  readonly adCampaignId: Scalars['String'];
  readonly adGroupId: Scalars['String'];
  readonly adName: Scalars['String'];
  readonly authPostCode: Scalars['String'];
  readonly callToAction: Scalars['Boolean'];
  readonly landingUrl?: Maybe<Scalars['String']>;
};

export type TikTokSpecialCampaign = {
  readonly advertiser: AdvertiserName;
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly id: Scalars['Int'];
  readonly joinedInfluencersCount: Scalars['Int'];
  readonly maxNumberInfluencers: Scalars['Int'];
  readonly plan: PaymentPlanName;
  readonly preLaunchDate?: Maybe<Scalars['Date']>;
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly status: TikTokSpecialCampaignStatus;
  readonly title: Scalars['String'];
};

export type TikTokSpecialCampaignChartReport = {
  readonly cost: Scalars['Float'];
  readonly date: Scalars['Date'];
  readonly engagement: Scalars['Int'];
};

export type TikTokSpecialCampaignDetail = {
  readonly advertiser: AdvertiserName;
  readonly campaignCategoryId?: Maybe<Scalars['Int']>;
  readonly country: CountryName;
  readonly createdDate: Scalars['Date'];
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly hashtags: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly materialUrl?: Maybe<Scalars['String']>;
  readonly materials: ReadonlyArray<Scalars['String']>;
  readonly preLaunchDate?: Maybe<Scalars['Date']>;
  readonly priceItem: PricingTableItem;
  readonly productUrl?: Maybe<Scalars['String']>;
  readonly rejectedDate?: Maybe<Scalars['Date']>;
  readonly rejectedReason?: Maybe<Scalars['String']>;
  readonly requirement?: Maybe<Scalars['String']>;
  readonly sampleUrl?: Maybe<Scalars['String']>;
  readonly serviceInformation?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly status: TikTokSpecialCampaignStatus;
  readonly thumbnails: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type TikTokSpecialCampaignDetailForInfluencer = {
  readonly currency: Scalars['String'];
  readonly endDate: Scalars['Date'];
  readonly hasReport: Scalars['Boolean'];
  readonly hashtags: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly isJoined: Scalars['Boolean'];
  readonly materialUrl?: Maybe<Scalars['String']>;
  readonly materials?: Maybe<ReadonlyArray<MaterialName>>;
  readonly minimumPaymentAmount: Scalars['Float'];
  readonly preLaunchDate?: Maybe<Scalars['Date']>;
  readonly productUrl?: Maybe<Scalars['String']>;
  readonly requirement?: Maybe<Scalars['String']>;
  readonly revenuePerPost: Scalars['Float'];
  readonly sampleUrl?: Maybe<Scalars['String']>;
  readonly serviceInformation?: Maybe<Scalars['String']>;
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate: Scalars['Date'];
  readonly status: CampaignStatusForInfluencer;
  readonly thumbnails: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type TikTokSpecialCampaignPost = {
  readonly content?: Maybe<Scalars['String']>;
  readonly currency: Scalars['String'];
  readonly id?: Maybe<Scalars['Int']>;
  readonly influencer: TiktokPostInfluencer;
  readonly joinedDate: Scalars['Date'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly postCode?: Maybe<Scalars['String']>;
  readonly postedDate?: Maybe<Scalars['Date']>;
  readonly revenuePerPost?: Maybe<Scalars['Float']>;
  readonly socialAccountId: Scalars['Int'];
  readonly status?: Maybe<TikTokSpecialCampaignPostStatus>;
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type TikTokSpecialCampaignPostReportForInfluencer = {
  readonly currency: Scalars['String'];
  /** campaign ID */
  readonly id: Scalars['Int'];
  readonly postReports: ReadonlyArray<InfluencerPostReportStatsTikTokSpecial>;
  /** campaign revenue */
  readonly revenue: Scalars['Float'];
  /** campaign title */
  readonly title: Scalars['String'];
};

/** An enumeration. */
export enum TikTokSpecialCampaignPostStatus {
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  JOINED = 'JOINED',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED'
}

/** An enumeration. */
export enum TikTokSpecialCampaignStatus {
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING'
}

export type TikTokSpecialDraftAdPostInfo = {
  readonly adAccountSystemId?: Maybe<Scalars['Int']>;
  readonly adCampaignId?: Maybe<Scalars['String']>;
  readonly adGroupId?: Maybe<Scalars['String']>;
  readonly adName?: Maybe<Scalars['String']>;
  readonly authPostCode?: Maybe<Scalars['String']>;
  readonly callToAction?: Maybe<Scalars['Boolean']>;
  readonly landingUrl?: Maybe<Scalars['String']>;
};

export type TikTokSpecialJoinedAccountPayload = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount: Scalars['Int'];
  readonly profileUrl: Scalars['String'];
  readonly socialAccountType: SocialAccountType;
  readonly username: Scalars['String'];
};

export type TikTokSpecialPackageInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly country: CountryName;
  readonly engagement?: Maybe<Scalars['Int']>;
  readonly engagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
  readonly followers?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  /** Influencer Id */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  /** Social Account System Id */
  readonly socialAccountId: Scalars['Int'];
};

export type TikTokSpecialPostDetail = {
  readonly boostedInfo?: Maybe<TikTokSpecialAdPostInfo>;
  readonly caption: Scalars['String'];
  readonly comments?: Maybe<Scalars['Int']>;
  readonly detailedReason?: Maybe<Scalars['String']>;
  readonly draftBoostedInfo?: Maybe<TikTokSpecialDraftAdPostInfo>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly images?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly influencer: TiktokPostDetailInfluencer;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postUrl?: Maybe<Scalars['String']>;
  readonly postedDate?: Maybe<Scalars['Date']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly status?: Maybe<TikTokSpecialCampaignPostStatus>;
  readonly views?: Maybe<Scalars['Int']>;
  readonly warningReason?: Maybe<WarningReason>;
};

export type TikTokSpecialPostHistory = {
  /** exist only status is REJECTED */
  readonly detailedReason?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly operatedDate: Scalars['Date'];
  readonly operatedUserName?: Maybe<Scalars['String']>;
  /** exist only status is REJECTED */
  readonly reason?: Maybe<Scalars['String']>;
  readonly status: TikTokSpecialPostHistoryStatus;
};

/** An enumeration. */
export enum TikTokSpecialPostHistoryStatus {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  REJECTED = 'REJECTED'
}

/** An enumeration. */
export enum TikTokSpecialReportOrderField {
  AD_COMMENTS = 'AD_COMMENTS',
  AD_ENGAGEMENTS = 'AD_ENGAGEMENTS',
  AD_IMPRESSIONS = 'AD_IMPRESSIONS',
  AD_IMPRESSIONS_RATE = 'AD_IMPRESSIONS_RATE',
  AD_LIKES = 'AD_LIKES',
  AD_REACH = 'AD_REACH',
  AD_REACH_RATE = 'AD_REACH_RATE',
  AD_SHARES = 'AD_SHARES',
  COMMENTS = 'COMMENTS',
  COST = 'COST',
  COSTS_PER_COMMENT = 'COSTS_PER_COMMENT',
  COSTS_PER_ENGAGEMENT = 'COSTS_PER_ENGAGEMENT',
  COSTS_PER_FOLLOWER = 'COSTS_PER_FOLLOWER',
  COSTS_PER_LIKE = 'COSTS_PER_LIKE',
  COSTS_PER_SHARE = 'COSTS_PER_SHARE',
  COSTS_PER_VIEW = 'COSTS_PER_VIEW',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  ENGAGEMENT_VIEWS_RATE = 'ENGAGEMENT_VIEWS_RATE',
  FOLLOWERS = 'FOLLOWERS',
  LIKES = 'LIKES',
  SHARES = 'SHARES',
  VIDEO_PLAY_ACTIONS = 'VIDEO_PLAY_ACTIONS',
  VIEWS = 'VIEWS'
}

export type TiktokAccountForProfileV2 = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<Scalars['String']>;
  readonly ttcmStatus?: Maybe<TTCMStatus>;
  readonly url?: Maybe<Scalars['String']>;
};

export type TiktokAdAccountInfo = {
  readonly balanceAmount: Scalars['Float'];
  readonly bcId: Scalars['String'];
  readonly bcName: Scalars['String'];
  readonly currencyId: Scalars['String'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly status: TiktokAdAccountStatus;
  readonly systemId: Scalars['String'];
};

/** An enumeration. */
export enum TiktokAdAccountStatus {
  DISCONNECT = 'DISCONNECT',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED'
}

export type TiktokAdCampaign = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type TiktokAdDetailReport = {
  readonly adBudget?: Maybe<Scalars['Float']>;
  readonly adCampaignId?: Maybe<Scalars['String']>;
  readonly adCampaignName?: Maybe<Scalars['String']>;
  readonly adClicks?: Maybe<Scalars['Int']>;
  readonly adComments?: Maybe<Scalars['Int']>;
  readonly adCost?: Maybe<Scalars['Float']>;
  readonly adCostPer1000Reached?: Maybe<Scalars['Float']>;
  readonly adCostPerResult?: Maybe<Scalars['Float']>;
  readonly adCpc?: Maybe<Scalars['Float']>;
  readonly adCpm?: Maybe<Scalars['Float']>;
  readonly adCtr?: Maybe<Scalars['Float']>;
  readonly adEngagements?: Maybe<Scalars['Int']>;
  readonly adEr?: Maybe<Scalars['Float']>;
  readonly adFollows?: Maybe<Scalars['Int']>;
  readonly adFrequency?: Maybe<Scalars['Int']>;
  readonly adGroupId?: Maybe<Scalars['String']>;
  readonly adGroupName?: Maybe<Scalars['String']>;
  readonly adId?: Maybe<Scalars['String']>;
  readonly adImpressions?: Maybe<Scalars['Int']>;
  readonly adLikes?: Maybe<Scalars['Int']>;
  readonly adName?: Maybe<Scalars['String']>;
  readonly adReach?: Maybe<Scalars['Int']>;
  readonly adResult?: Maybe<Scalars['Int']>;
  readonly adResultRate?: Maybe<Scalars['Float']>;
  readonly adShares?: Maybe<Scalars['Int']>;
  readonly averageVideoPlay?: Maybe<Scalars['Int']>;
  readonly averageVideoPlayPerUser?: Maybe<Scalars['Int']>;
  readonly campaignId?: Maybe<Scalars['Int']>;
  readonly interactiveAddOnDestinationClicks?: Maybe<Scalars['Int']>;
  readonly interactiveAddOnImpressions?: Maybe<Scalars['Int']>;
  readonly postId?: Maybe<Scalars['Int']>;
  readonly status: TikTokAdStatus;
  readonly videoPlayActions?: Maybe<Scalars['Int']>;
  readonly videoViewsP25?: Maybe<Scalars['Int']>;
  readonly videoViewsP50?: Maybe<Scalars['Int']>;
  readonly videoViewsP75?: Maybe<Scalars['Int']>;
  readonly videoViewsP100?: Maybe<Scalars['Int']>;
  readonly videoWatched2s?: Maybe<Scalars['Int']>;
  readonly videoWatched6s?: Maybe<Scalars['Int']>;
};

export type TiktokAdGroup = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type TiktokAdReportOrderBy = {
  readonly field?: InputMaybe<TikTokAdReportOrderField>;
  readonly order?: InputMaybe<ORDER>;
};

export type TiktokAdvertiserAccount = {
  readonly advertiserName: Scalars['String'];
  readonly advertiserUrl: Scalars['String'];
  readonly countryId: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
};

export type TiktokAdvertiserPlanData = {
  readonly currencyId: Scalars['String'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly paymentDate?: Maybe<Scalars['Date']>;
  readonly planPrice?: Maybe<Scalars['Float']>;
  readonly subscriptionPlan?: Maybe<TikTokAdvertiserPlanFilter>;
};

export type TiktokAdvertiserSummaryData = {
  readonly cancelNextMonth: Scalars['Boolean'];
  readonly currencyId: Scalars['String'];
  readonly numberCreatedCampaigns: Scalars['Int'];
  readonly planPrice?: Maybe<Scalars['Float']>;
  readonly prepaidBalance?: Maybe<Scalars['Float']>;
  readonly subscriptionPlan?: Maybe<PaymentPlanName>;
  readonly upcomingSubscriptionPlan?: Maybe<PaymentPlanName>;
  readonly warningsList: ReadonlyArray<TiktokBusinessWarningStatus>;
};

export type TiktokAudienceSection = {
  readonly ageRates: TiktokFollowersAgeGroup;
  readonly countryRates?: Maybe<ReadonlyArray<TiktokFollowerCountryRate>>;
  readonly femaleRate?: Maybe<Scalars['Float']>;
  readonly maleRate?: Maybe<Scalars['Float']>;
};

export type TiktokAuthRedirectUrl = {
  readonly url: Scalars['String'];
};

export type TiktokBudgetAllocation = {
  readonly adAccounts: ReadonlyArray<TiktokAdAccountInfo>;
  readonly currencyId: Scalars['String'];
  readonly prepaidBalance: Scalars['Float'];
};

export type TiktokBusinessAccountInfo = {
  readonly bcList: ReadonlyArray<TiktokBusinessCenterInfo>;
  readonly connected: Scalars['Boolean'];
};

export type TiktokBusinessCenterInfo = {
  readonly bcType: TiktokBusinessCenterType;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

/** An enumeration. */
export enum TiktokBusinessCenterType {
  AGENCY = 'AGENCY',
  DIRECT = 'DIRECT',
  NORMAL = 'NORMAL',
  SELF_SERVICE = 'SELF_SERVICE',
  SELF_SERVICE_AGENCY = 'SELF_SERVICE_AGENCY'
}

/** An enumeration. */
export enum TiktokBusinessWarningStatus {
  MKP_CAMPAIGN_DISABLE = 'MKP_CAMPAIGN_DISABLE',
  REACH_ADS_ACCOUNTS_LIMIT = 'REACH_ADS_ACCOUNTS_LIMIT',
  SUBSCRIPTION_PAYMENT_FAILED = 'SUBSCRIPTION_PAYMENT_FAILED'
}

export type TiktokFollowerCountryRate = {
  readonly countryId: Scalars['String'];
  readonly name: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type TiktokFollowersAgeGroup = {
  readonly ageGroup?: Maybe<ReadonlyArray<FollowersAgeGroup>>;
  readonly rate?: Maybe<ReadonlyArray<Scalars['Float']>>;
};

export type TiktokInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly comments?: Maybe<Scalars['Int']>;
  readonly country: CountryName;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  /** Influencer Id */
  readonly id: Scalars['Int'];
  readonly influencerEngagement?: Maybe<Scalars['Int']>;
  readonly influencerEngagementRate?: Maybe<Scalars['Float']>;
  readonly influencerFollowers?: Maybe<Scalars['Int']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountEngagementViewsRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  /** Social Account System Id */
  readonly socialAccountId: Scalars['Int'];
  /** Social Account Status */
  readonly socialAccountStatus: SocialAccountStatus;
  readonly ttcmStatus?: Maybe<TTCMStatus>;
  readonly views?: Maybe<Scalars['BigInt']>;
};

export type TiktokInfluencerSearchResultV2 = {
  readonly tiktokAccounts: ReadonlyArray<TiktokInfluencerV2>;
  readonly totalNumber: Scalars['Int'];
};

export type TiktokInfluencerV2 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly bio?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly gender: Genders;
  readonly id: Scalars['ID'];
  readonly isBrandAccount: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly popularPosts: ReadonlyArray<SocialAccountPopularPost>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountEngagementViewsRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  readonly socialAccountId: Scalars['ID'];
  readonly socialAccountStatus: SocialAccountStatus;
  readonly ttcmStatus?: Maybe<TTCMStatus>;
};

export type TiktokPostDetailInfluencer = {
  readonly avatar: Scalars['String'];
  readonly followersCount: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type TiktokPostInfluencer = {
  readonly avatar: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type TiktokSpecialCampaignInfo = {
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly id: Scalars['Int'];
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly status: TikTokSpecialCampaignStatus;
  readonly title: Scalars['String'];
};

export type TiktokSpecialCampaignReport = {
  readonly average?: Maybe<TiktokSpecialStatsItemRow>;
  readonly campaignInfo: TiktokSpecialCampaignInfo;
  readonly chartData: ReadonlyArray<TikTokSpecialCampaignChartReport>;
  readonly currency: Scalars['String'];
  readonly influencerReports: ReadonlyArray<TiktokSpecialInfluencerReport>;
  readonly postCount: Scalars['Int'];
  readonly summary: TiktokSpecialStatsItemRow;
  readonly summaryDaily?: Maybe<ReadonlyArray<TiktokSpecialSummaryDaily>>;
};

export type TiktokSpecialCampaignReportSummary = {
  readonly comments?: Maybe<Scalars['Int']>;
  readonly engagement?: Maybe<Scalars['Int']>;
  readonly engagementRate?: Maybe<Scalars['Float']>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly postCount: Scalars['Int'];
  readonly shares?: Maybe<Scalars['Int']>;
};

export type TiktokSpecialInfluencerReport = {
  readonly influencerInfo: CampaignInfluencerInfo;
  readonly postInfo: TiktokSpecialPostInfo;
  readonly summary: TiktokSpecialStatsItemRow;
  readonly summaryDaily?: Maybe<ReadonlyArray<TiktokSpecialSummaryDaily>>;
};

export type TiktokSpecialJoinableAccountsPayload = {
  readonly tiktokAccounts: ReadonlyArray<JoinableAccount>;
};

export type TiktokSpecialPostInfo = {
  readonly content: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly postUrl: Scalars['String'];
  readonly socialAccountId: Scalars['Int'];
  readonly thumbNail?: Maybe<Scalars['String']>;
};

export type TiktokSpecialReportOrderBy = {
  readonly field?: InputMaybe<TikTokSpecialReportOrderField>;
  readonly order?: InputMaybe<ORDER>;
};

export type TiktokSpecialStatsItemRow = {
  readonly adComments?: Maybe<Scalars['Int']>;
  readonly adImpressions?: Maybe<Scalars['Int']>;
  readonly adImpressionsRate?: Maybe<Scalars['Float']>;
  readonly adLikes?: Maybe<Scalars['Int']>;
  readonly adReach?: Maybe<Scalars['Int']>;
  readonly adReachRate?: Maybe<Scalars['Float']>;
  readonly adShares?: Maybe<Scalars['Int']>;
  readonly comments?: Maybe<Scalars['Int']>;
  readonly cost?: Maybe<Scalars['Float']>;
  readonly costsPerComment?: Maybe<Scalars['Float']>;
  readonly costsPerEngagement?: Maybe<Scalars['Float']>;
  readonly costsPerFollower?: Maybe<Scalars['Float']>;
  readonly costsPerLike?: Maybe<Scalars['Float']>;
  readonly costsPerShare?: Maybe<Scalars['Float']>;
  readonly costsPerView?: Maybe<Scalars['Float']>;
  readonly engagement?: Maybe<Scalars['Int']>;
  readonly engagementRate?: Maybe<Scalars['Float']>;
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
  readonly followers?: Maybe<Scalars['Float']>;
  readonly likes?: Maybe<Scalars['Int']>;
  readonly shares?: Maybe<Scalars['Int']>;
  readonly videoPlayActions?: Maybe<Scalars['Int']>;
  readonly views?: Maybe<Scalars['Int']>;
};

export type TiktokSpecialSummaryDaily = {
  readonly date: Scalars['Date'];
  readonly stats: TiktokSpecialStatsItemRow;
};

export type TiktokUserHashtag = {
  readonly hashtag: Scalars['String'];
  readonly id: Scalars['Int'];
};

export type TiktokUserHashtagListPostsPayload = {
  readonly count: Scalars['Int'];
  readonly posts: ReadonlyArray<TiktokUserTagListPostsItemPayload>;
};

export type TiktokUserHashtagPayload = {
  readonly hashtags: ReadonlyArray<TiktokUserHashtag>;
};

export type TiktokUserKeyword = {
  readonly id: Scalars['Int'];
  readonly keyword: Scalars['String'];
};

export type TiktokUserKeywordListPostsPayload = {
  readonly count: Scalars['Int'];
  readonly posts: ReadonlyArray<TiktokUserTagListPostsItemPayload>;
};

export type TiktokUserKeywordPayload = {
  readonly keywords: ReadonlyArray<TiktokUserKeyword>;
};

export type TiktokUserTagListPostsItemPayload = {
  readonly comments: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly engagement: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postDate: Scalars['Date'];
  readonly postId: Scalars['Int'];
  readonly rank: Scalars['Int'];
  readonly thumbnail: Scalars['String'];
};

export type TiktokUserTagPostDetailsPayload = {
  readonly comments: Scalars['Int'];
  readonly content?: Maybe<Scalars['String']>;
  readonly engagement: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly saves: Scalars['Int'];
  readonly shares: Scalars['Int'];
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly views: Scalars['Int'];
};

export type TiktokUserTagPostsSortInput = {
  readonly field?: InputMaybe<TiktokUserTagPostsSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum TiktokUserTagPostsSortOrder {
  COMMENTS = 'COMMENTS',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  RANK = 'RANK'
}

export type TokenAuthLongLive = {
  readonly role: UserRoles;
  readonly token: Scalars['String'];
  readonly userId: Scalars['Int'];
};

export type TokenAuthLongLiveInput = {
  readonly email: Scalars['String'];
  readonly password: Scalars['String'];
};

export type TotalCmsRevenue = {
  readonly currency: Scalars['String'];
  readonly isOwner: Scalars['Boolean'];
  readonly rpm?: Maybe<Scalars['Float']>;
  readonly total: Scalars['Float'];
};

export type TotalInfluencerReport = {
  readonly click?: Maybe<Scalars['Int']>;
  readonly comment: Scalars['Int'];
  readonly conversion?: Maybe<Scalars['Int']>;
  readonly currency: Scalars['String'];
  readonly like: Scalars['Int'];
  readonly pendingRevenue: Scalars['Float'];
  readonly revenue: Scalars['Float'];
  readonly share: Scalars['Int'];
  readonly view: Scalars['Int'];
};

export type TotalNumber = {
  readonly totalNumber: Scalars['Int'];
};

export type TrackYoutubeMusicDownload = {
  readonly ok: Scalars['Boolean'];
};

export type TrackYoutubeMusicDownloadInput = {
  readonly musicId: Scalars['Int'];
};

export type TransferMoneyToAdAccount = {
  readonly ok: Scalars['Boolean'];
};

export type TransferMoneyToAdAccountInput = {
  readonly adAccountId: Scalars['String'];
  readonly amount: Scalars['Float'];
};

export type TrendingFacebookInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly facebookType: SocialAccountType;
  readonly followersGrowthRate: Scalars['Float'];
  readonly gender: Genders;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly rank: Scalars['Int'];
  readonly socialAccountEngagementRate: Scalars['Float'];
  readonly socialAccountFollowers: Scalars['Int'];
  readonly socialAccountId: Scalars['ID'];
  readonly status: SocialAccountStatus;
};

export type TrendingFacebookInfluencerSearchResult = {
  readonly totalNumber: Scalars['Int'];
  readonly trending: ReadonlyArray<TrendingFacebookInfluencer>;
};

export type TrendingInstagramInfluencerSearchResultV2 = {
  readonly totalNumber: Scalars['Int'];
  readonly trendingAccounts: ReadonlyArray<TrendingInstagramInfluencerV2>;
};

export type TrendingInstagramInfluencerV2 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly followersGrowthRate: Scalars['Float'];
  readonly gender: Genders;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly rank: Scalars['Int'];
  readonly socialAccountEngagementRate: Scalars['Float'];
  readonly socialAccountFollowers: Scalars['Int'];
  readonly socialAccountId: Scalars['ID'];
  readonly status: SocialAccountStatus;
};

export type TrendingYoutubeInfluencerSearchResultV2 = {
  readonly totalNumber: Scalars['Int'];
  readonly trendingAccounts: ReadonlyArray<TrendingYoutubeInfluencerV2>;
};

export type TrendingYoutubeInfluencerV2 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly followersGrowthRate: Scalars['Float'];
  readonly gender: Genders;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly rank: Scalars['Int'];
  readonly socialAccountAverageViews: Scalars['Float'];
  readonly socialAccountFollowers: Scalars['Int'];
  readonly socialAccountId: Scalars['ID'];
  readonly status: SocialAccountStatus;
};

/** An enumeration. */
export enum TutorialName {
  PAYMENT = 'PAYMENT',
  SEARCH_JOB = 'SEARCH_JOB',
  YOUR_JOB = 'YOUR_JOB'
}

export type TwitterAnalyticsFeedPostPayload = {
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly replies: Scalars['Int'];
  readonly retweets: Scalars['Int'];
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type TwitterAnalyticsOverviewHistoryItem = {
  readonly count: Scalars['Int'];
  readonly date: Scalars['String'];
};

export type TwitterAnalyticsOverviewHistoryRealItem = {
  readonly count: Scalars['Float'];
  readonly date: Scalars['String'];
};

export type TwitterAnalyticsOverviewItem = {
  readonly growth: Scalars['Int'];
  readonly history: ReadonlyArray<TwitterAnalyticsOverviewHistoryItem>;
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Int'];
};

export type TwitterAnalyticsOverviewPayload = {
  readonly engagement: TwitterAnalyticsOverviewItem;
  readonly engagementRate: TwitterAnalyticsOverviewRealTotalItem;
  readonly engagementRates?: Maybe<TwitterAnalyticsOverviewRealItem>;
  readonly follower: TwitterAnalyticsOverviewItem;
  readonly impression: TwitterAnalyticsOverviewItem;
  readonly lastUpdated: Scalars['DateTime'];
  readonly like: TwitterAnalyticsOverviewItem;
  readonly post: TwitterAnalyticsOverviewItem;
  readonly profileClick: TwitterAnalyticsOverviewTotalItem;
  readonly profileClicks?: Maybe<TwitterAnalyticsOverviewItem>;
  readonly quotedTweet: TwitterAnalyticsOverviewItem;
  readonly reply: TwitterAnalyticsOverviewItem;
  readonly retweet: TwitterAnalyticsOverviewItem;
  readonly urlClick: TwitterAnalyticsOverviewTotalItem;
  readonly urlClicks?: Maybe<TwitterAnalyticsOverviewItem>;
  readonly view: TwitterAnalyticsOverviewItem;
};

export type TwitterAnalyticsOverviewRealItem = {
  readonly growth: Scalars['Float'];
  readonly history: ReadonlyArray<TwitterAnalyticsOverviewHistoryRealItem>;
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Float'];
};

export type TwitterAnalyticsOverviewRealTotalItem = {
  readonly growth: Scalars['Float'];
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Float'];
};

export type TwitterAnalyticsOverviewTotalItem = {
  readonly growth: Scalars['Int'];
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Int'];
};

export type TwitterAnalyticsPostByIdPayload = {
  readonly avatar: Scalars['String'];
  readonly followers: Scalars['Int'];
  readonly post: TwitterAnalyticsPostPayload;
  readonly username: Scalars['String'];
};

export type TwitterAnalyticsPostComment = {
  readonly commentId: Scalars['String'];
  readonly content: Scalars['String'];
  readonly posterName: Scalars['String'];
};

export type TwitterAnalyticsPostItemPayload = {
  readonly engagement: Scalars['Int'];
  readonly engagementRate: Scalars['Float'];
  readonly id: Scalars['Int'];
  readonly impression: Scalars['Int'];
  readonly like: Scalars['Int'];
  readonly postDate: Scalars['String'];
  readonly postUrl: Scalars['String'];
  readonly reply: Scalars['Int'];
  readonly retweet: Scalars['Int'];
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly view?: Maybe<Scalars['Int']>;
};

export type TwitterAnalyticsPostPayload = {
  readonly comments: ReadonlyArray<TwitterAnalyticsPostComment>;
  readonly content: Scalars['String'];
  readonly engagement: Scalars['Float'];
  readonly id: Scalars['Int'];
  readonly images: ReadonlyArray<Scalars['String']>;
  readonly impression: Scalars['Int'];
  readonly like: Scalars['Int'];
  readonly postDate: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly quoteTweet: Scalars['Int'];
  readonly reply: Scalars['Int'];
  readonly retweet: Scalars['Int'];
  readonly view: Scalars['Int'];
};

export type TwitterAnalyticsPostsByDatePayload = {
  readonly avatar: Scalars['String'];
  readonly followers: Scalars['Int'];
  readonly posts: ReadonlyArray<TwitterAnalyticsPostPayload>;
  readonly username: Scalars['String'];
};

export type TwitterAnalyticsPostsPayload = {
  readonly posts: ReadonlyArray<TwitterAnalyticsPostItemPayload>;
};

export type TwitterAnalyticsPostsSortInput = {
  readonly field?: InputMaybe<TwitterAnalyticsPostsSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum TwitterAnalyticsPostsSortOrder {
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  IMPRESSIONS = 'IMPRESSIONS',
  LIKES = 'LIKES',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  REPLIES = 'REPLIES',
  RETWEETS = 'RETWEETS',
  VIEWS = 'VIEWS'
}

export type TwitterAnalyticsPostsStatisticPayload = {
  readonly averageEngagement: TwitterAverageEngagementPayload;
  readonly postingHabits: ReadonlyArray<TwitterPostingHabitsPayload>;
};

export type TwitterAverageEngagementItemPayload = {
  readonly growth: Scalars['Float'];
  readonly total: Scalars['Float'];
};

export type TwitterAverageEngagementPayload = {
  readonly avgImpression: TwitterAverageEngagementItemPayload;
  readonly avgLike: TwitterAverageEngagementItemPayload;
  readonly avgPostsWeek?: Maybe<TwitterAverageEngagementItemPayload>;
  readonly avgReply: TwitterAverageEngagementItemPayload;
  readonly avgRetweet: TwitterAverageEngagementItemPayload;
  readonly avgView?: Maybe<TwitterAverageEngagementItemPayload>;
};

export type TwitterForProfileV2 = {
  readonly analyticsEnabled: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
};

export type TwitterInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly analyticsWarning: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly country: CountryName;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  /** Influencer Id */
  readonly id: Scalars['Int'];
  readonly influencerEngagement?: Maybe<Scalars['Int']>;
  readonly influencerEngagementRate?: Maybe<Scalars['Float']>;
  readonly influencerFollowers?: Maybe<Scalars['Int']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly likes?: Maybe<Scalars['Int']>;
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly replies?: Maybe<Scalars['Int']>;
  readonly retweets?: Maybe<Scalars['Int']>;
  /** only for Twitter to show on UI */
  readonly socialAccountAverageEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  /** Social Account System Id */
  readonly socialAccountId: Scalars['Int'];
  /** Social Account Status */
  readonly socialAccountStatus: SocialAccountStatus;
  readonly views?: Maybe<Scalars['Int']>;
};

export type TwitterPostingHabitsPayload = {
  readonly averageComments: Scalars['Float'];
  readonly averageLikes: Scalars['Float'];
  readonly engagementRate: Scalars['Float'];
  readonly hour: Scalars['Int'];
  readonly weekday: Scalars['Int'];
};

export enum UGCPostStatus {
  APPROVED = 'APPROVED',
  NOT_REQUESTED = 'NOT_REQUESTED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED'
}

export enum UGCPostType {
  HASHTAG = 'HASHTAG',
  MENTIONED = 'MENTIONED',
  TAGGED = 'TAGGED'
}

export type UUUMSocialAuthSignUpInput = {
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly countryId: Scalars['String'];
  readonly dateOfBirth: Scalars['String'];
  readonly email: Scalars['String'];
  readonly gender: Genders;
  readonly name: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
  readonly regionId: Scalars['Int'];
  readonly uuid: Scalars['ID'];
};

export type UUUMSocialAuthSignUpPayload = {
  readonly token?: Maybe<Scalars['String']>;
};

export type UnApproveApplicantInfluencers = {
  readonly ok: Scalars['Boolean'];
};

export type UnApproveApplicantInfluencersInput = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly marketplaceCampaignId: Scalars['Int'];
};

export type UnassignChatPICInput = {
  readonly chatId: Scalars['String'];
};

export type UnassignChatPICPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UnblockInfluencerMarketplace = {
  readonly ok: Scalars['Boolean'];
};

export type UnblockInfluencerMarketplaceInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
};

/**  `UnreadNotificationCountByAppPayload` payload houses a `count` integers to show how many notification are not read yet. */
export type UnreadNotificationCountByAppPayload = {
  readonly count: Scalars['Long'];
};

/**  `UnreadNotificationCountPayload` payload houses a `count` integers to show how many notification are not read yet. */
export type UnreadNotificationCountPayload = {
  readonly count: Scalars['Long'];
};

/**
 *  `UnreadNotificationInput` input accepts a `checkpoint` to get notifications beyond the checkpoint. A value of `null`
 *  can be provided to start from the latest notification.
 */
export type UnreadNotificationInput = {
  readonly checkpoint?: InputMaybe<Scalars['Long']>;
};

export type UnresolveChatInput = {
  readonly chatId: Scalars['String'];
};

export type UnresolveChatPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAccountInformationInput = {
  readonly brandName: Scalars['String'];
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly countryId: Scalars['String'];
  readonly dateOfBirth: Scalars['String'];
  readonly gender: Genders;
  readonly introduce: Scalars['String'];
  readonly name: Scalars['String'];
  readonly regionId?: InputMaybe<Scalars['Int']>;
};

export type UpdateAccountInformationPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAddress = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAddressInformationInput = {
  readonly address: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly city: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
};

export type UpdateAddressInformationPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAddressInput = {
  readonly address: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly city: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly province: Scalars['String'];
};

export type UpdateAdmin = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAdminInput = {
  readonly countryId?: InputMaybe<Scalars['String']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateAdvertiser = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAdvertiserInput = {
  readonly anyxMasterId?: InputMaybe<Scalars['Int']>;
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId?: InputMaybe<Scalars['String']>;
  readonly hubspotId?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly internalNetsuiteId?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateAdvertiserUser = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAdvertiserUserInput = {
  readonly advertiserId?: InputMaybe<Scalars['Int']>;
  readonly countryId?: InputMaybe<Scalars['String']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name?: InputMaybe<Scalars['String']>;
  readonly notificationSetting?: InputMaybe<Scalars['Boolean']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateAdvertiserUserSettings = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAdvertiserUserSettingsInput = {
  readonly advertiserCompanyUrl: Scalars['String'];
  readonly advertiserCountryId: Scalars['String'];
  readonly advertiserName: Scalars['String'];
  readonly advertiserPhoneNumber: Scalars['String'];
  readonly countryId: Scalars['String'];
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
  readonly notificationSetting: Scalars['Boolean'];
};

export type UpdateAgency = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAgencyInput = {
  readonly agencyId: Scalars['Int'];
  readonly countryId?: InputMaybe<Scalars['String']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateAutoManagedAffiliateCommissionRates = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAutoManagedAffiliateCommissionRatesInput = {
  readonly id: Scalars['Int'];
  readonly influencers: ReadonlyArray<AutoManagedAffiliateCommissionRateInput>;
};

export type UpdateAutoManagedCampaignInput = {
  readonly campaignId: Scalars['Int'];
  readonly marginRate: Scalars['Float'];
  readonly requirement: Scalars['String'];
  readonly serviceInfo: Scalars['String'];
  readonly status: AutoManagedCampaignStatus;
};

export type UpdateAutoManagedCampaignPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateAutoManagedCampaignsStatusInput = {
  readonly campaignIds: ReadonlyArray<Scalars['Int']>;
  readonly status: AutoManagedCampaignStatus;
};

export type UpdateAutoManagedCampaignsStatusPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateBioSectionsInput = {
  readonly sections: ReadonlyArray<BioSectionsInput>;
};

export type UpdateBioSectionsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateCmsAssetShareRate = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateCmsAssetShareRateInput = {
  readonly assets: ReadonlyArray<CmsAssetShareRateInput>;
  /** Channel System Id */
  readonly cmsChannelId: Scalars['Int'];
  readonly month: Scalars['Date'];
};

export type UpdateCmsChannelShareRate = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateCmsChannelShareRateInput = {
  readonly channels: ReadonlyArray<CmsChannelShareRateInput>;
  readonly month: Scalars['Date'];
};

export type UpdateCommentInput = {
  readonly activityId: Scalars['Long'];
  readonly comment: Scalars['String'];
};

export type UpdateCommentPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEmailTemplateInput = {
  readonly fans: ReadonlyArray<Scalars['Long']>;
  readonly id: Scalars['Long'];
  readonly isDraft: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly scheduleDate?: InputMaybe<Scalars['String']>;
  readonly sendNow: Scalars['Boolean'];
  readonly sender: Scalars['String'];
  readonly subject: Scalars['String'];
  readonly tags: ReadonlyArray<Scalars['Long']>;
};

export type UpdateEmailTemplatePayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagement = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementBreakDownMode = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementBreakDownModeInput = {
  /** Engagement campaign id */
  readonly campaignId: Scalars['Int'];
  /** breakdown mode */
  readonly jdMode: ProposalJobDescriptionBreakDownMode;
};

export type UpdateEngagementCouponList = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementCouponListInput = {
  readonly couponUploadFileUrl: Scalars['String'];
  readonly id: Scalars['Int'];
};

export type UpdateEngagementInfluencerProposalList = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementInfluencerProposalListInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
};

export type UpdateEngagementInfluencersBudget = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementInfluencersBudgetInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencers: ReadonlyArray<EngagementInfluencerBudgetInput>;
};

export type UpdateEngagementInput = {
  readonly adAgencyMarginRate?: InputMaybe<Scalars['Float']>;
  readonly advertiserId: Scalars['Int'];
  readonly budget: Scalars['Float'];
  readonly campaignCategoryId: Scalars['Int'];
  readonly categoryIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly countryIds: ReadonlyArray<Scalars['String']>;
  readonly couponList?: InputMaybe<Scalars['String']>;
  readonly couponUploadFiles?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly currency?: InputMaybe<Scalars['String']>;
  readonly defaultPostRequirement?: InputMaybe<Scalars['String']>;
  readonly endDate: Scalars['Date'];
  readonly hashtags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly hubspotDealIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['Int'];
  readonly influencerManagementPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly isTtcmCampaign?: InputMaybe<Scalars['Boolean']>;
  readonly landingPageUrl?: InputMaybe<Scalars['String']>;
  readonly marginRate?: InputMaybe<Scalars['Float']>;
  readonly materialUrl?: InputMaybe<Scalars['String']>;
  readonly materials?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly numberOfInfluencers?: InputMaybe<Scalars['Int']>;
  readonly objective: Scalars['String'];
  readonly salesPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly serviceInformation?: InputMaybe<Scalars['String']>;
  readonly serviceUrl?: InputMaybe<Scalars['String']>;
  readonly socialMedias: ReadonlyArray<CampaignSocialMediaType>;
  readonly startDate: Scalars['Date'];
  readonly thumbNail: Scalars['String'];
  readonly title: Scalars['String'];
  readonly trackingOptions?: InputMaybe<ReadonlyArray<CampaignTrackingOption>>;
};

export type UpdateEngagementPostForInfluencerV2 = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementPostInputForInfluencerV2 = {
  readonly campaignId: Scalars['Int'];
  readonly content: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly materialsUrl?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly planedPostDate: Scalars['Date'];
  /** Represents social account system id */
  readonly socialAccountId: Scalars['Int'];
  /** Represents social account media type */
  readonly socialAccountMedia: SocialAccountType;
  readonly socialMedia: CampaignSocialMediaType;
};

export type UpdateEngagementPostInputV2 = {
  readonly campaignId: Scalars['Int'];
  readonly content?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly influencerId: Scalars['Int'];
  readonly insightDataAcquisition?: InputMaybe<Scalars['Date']>;
  readonly insightUrls?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly materialsUrl?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly planedPostDate?: InputMaybe<Scalars['Date']>;
  readonly postUrl?: InputMaybe<Scalars['String']>;
  /** Represents social account system id */
  readonly socialAccountId: Scalars['Int'];
  /** Represents social account media type */
  readonly socialAccountMedia: SocialAccountType;
};

export type UpdateEngagementPostStatus = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementPostStatusInput = {
  readonly id: Scalars['Int'];
  readonly status: EngagementCampaignPostStatus;
};

export type UpdateEngagementPostV2 = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementSocialAccountProposalList = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementSocialAccountProposalListInput = {
  readonly campaignId: Scalars['Int'];
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountIds: ReadonlyArray<Scalars['Int']>;
  readonly socialAccountMedia: SocialAccountType;
};

export type UpdateEngagementSocialAccountsBudget = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementSocialAccountsBudgetInput = {
  readonly campaignId: Scalars['Int'];
  readonly socialAccounts: ReadonlyArray<EngagementSocialAcccountBudgetInput>;
};

export type UpdateEngagementStatus = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateEngagementStatusInput = {
  readonly id: Scalars['Int'];
  readonly status: EngagementCampaignStatus;
};

export type UpdateFanInput = {
  readonly address1?: InputMaybe<Scalars['String']>;
  readonly address2?: InputMaybe<Scalars['String']>;
  readonly birthday?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly contactNumber?: InputMaybe<Scalars['String']>;
  readonly countryId?: InputMaybe<Scalars['String']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly gender?: InputMaybe<FanGender>;
  readonly id: Scalars['Long'];
  readonly lastName: Scalars['String'];
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly province?: InputMaybe<Scalars['String']>;
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type UpdateFanPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateFormInput = {
  readonly description: Scalars['String'];
  readonly id: Scalars['Long'];
  readonly questions: ReadonlyArray<UpdateQuestionInput>;
  readonly thankDescription: Scalars['String'];
  readonly thankTitle: Scalars['String'];
  readonly title: Scalars['String'];
};

export type UpdateFormPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateFormStatusInput = {
  readonly id: Scalars['Long'];
  readonly status: FormStatus;
};

export type UpdateFormStatusPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInfluencerBioInput = {
  readonly isFinish: Scalars['Boolean'];
  readonly isPublic: Scalars['Boolean'];
  readonly linkName: Scalars['String'];
  readonly profile: BioProfileInput;
  readonly sections: ReadonlyArray<BioSectionsInputV2>;
  readonly theme?: InputMaybe<BioTheme>;
  readonly themeV2?: InputMaybe<BioThemeV2>;
};

export type UpdateInfluencerBioPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInfluencerInputV2 = {
  readonly addressInformation?: InputMaybe<AddressInput>;
  readonly campaignDisplay: Scalars['Boolean'];
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  /** allow only scraping influencer */
  readonly countryId: Scalars['String'];
  readonly dateOfBirth?: InputMaybe<Scalars['Date']>;
  readonly defaultEngagementSelectionReason?: InputMaybe<Scalars['String']>;
  readonly douyinAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly facebookAccount?: InputMaybe<InfluencerSocialNetworkAccountInput>;
  readonly facebookPages?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly gender: Genders;
  readonly id: Scalars['Int'];
  readonly instagramAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly internalMemo?: InputMaybe<Scalars['String']>;
  readonly introduce?: InputMaybe<Scalars['String']>;
  readonly isBrandAccount?: InputMaybe<Scalars['Boolean']>;
  readonly isProAccount?: InputMaybe<Scalars['Boolean']>;
  readonly managerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly name: Scalars['String'];
  readonly notificationSetting: Scalars['Boolean'];
  readonly partnerIds: ReadonlyArray<Scalars['Int']>;
  readonly paymentInformation?: InputMaybe<PaymentInformationInput>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly rateCards?: InputMaybe<InfluencerRateCardsInput>;
  readonly regionId?: InputMaybe<Scalars['Int']>;
  readonly tags: ReadonlyArray<Scalars['String']>;
  readonly talentAgencyIds: ReadonlyArray<Scalars['Int']>;
  readonly threadsAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly tiktokAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly twitterAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly xhsAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
  readonly youtubeAccounts?: InputMaybe<ReadonlyArray<InfluencerSocialNetworkAccountInput>>;
};

export type UpdateInfluencerPhoneNumberInput = {
  readonly phoneNumber: Scalars['String'];
};

export type UpdateInfluencerPhoneNumberPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInfluencerProfile = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInfluencerProfileInput = {
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly dateOfBirth: Scalars['Date'];
  readonly email: Scalars['String'];
  readonly gender: Genders;
  readonly id: Scalars['Int'];
  readonly introduce?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly notificationSetting: Scalars['Boolean'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly regionId: Scalars['Int'];
};

export type UpdateInfluencerRateCardInput = {
  readonly price: Scalars['Float'];
  readonly socialAccountType: SocialMediaTypeForRateCard;
};

export type UpdateInfluencerRateCardPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInfluencerRateCardsByStaff = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInfluencerRateCardsByStaffInput = {
  readonly influencerId: Scalars['Int'];
  readonly rateCards: InfluencerRateCardsInput;
};

export type UpdateInfluencerRateCardsInput = {
  readonly facebook?: InputMaybe<Scalars['Float']>;
  readonly instagram?: InputMaybe<Scalars['Float']>;
  readonly instagramStory?: InputMaybe<Scalars['Float']>;
  readonly tiktok?: InputMaybe<Scalars['Float']>;
  readonly twitter?: InputMaybe<Scalars['Float']>;
  readonly youtube?: InputMaybe<Scalars['Float']>;
};

export type UpdateInfluencerRateCardsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInfluencerTagsByStaff = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInfluencerTagsByStaffInput = {
  readonly influencerId: Scalars['Int'];
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type UpdateInfluencerV2 = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInternalMemo = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateInternalMemoInput = {
  readonly id: Scalars['Int'];
  readonly internalMemo: Scalars['String'];
};

export type UpdateLineBroadcastMessageInput = {
  readonly deliveryTiming: DeliveryTimingItemInput;
  readonly lineBroadcastMessageId: Scalars['String'];
  readonly lineContent: ReadonlyArray<LineContentItemInput>;
  readonly recipient: RecipientItemInput;
  readonly title: Scalars['String'];
};

export type UpdateLineBroadcastMessagePayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateLinkBioThemeColorInput = {
  readonly bgColor?: InputMaybe<Scalars['String']>;
  readonly button?: InputMaybe<BioButtonStyle>;
  readonly buttonBgColor?: InputMaybe<Scalars['String']>;
  readonly buttonFontColor?: InputMaybe<Scalars['String']>;
  readonly font?: InputMaybe<BioFontStyle>;
  readonly fontColor?: InputMaybe<Scalars['String']>;
  readonly media?: InputMaybe<Scalars['String']>;
  readonly theme?: InputMaybe<BioTheme>;
};

export type UpdateLinkBioThemeColorPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateMarketplace = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateMarketplaceAffiliateCommissionFees = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateMarketplaceAffiliateCommissionFeesInput = {
  readonly influencers: ReadonlyArray<MarketplaceAffiliateCommissionFeeInput>;
  readonly marketplaceId: Scalars['Int'];
};

export type UpdateMarketplaceAffiliateCommissionRates = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateMarketplaceAffiliateCommissionRatesInput = {
  readonly influencers: ReadonlyArray<MarketplaceAffiliateCommissionRateInput>;
  readonly marketplaceId: Scalars['Int'];
};

export type UpdateMarketplaceCouponList = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateMarketplaceCouponListInput = {
  readonly couponUploadFileUrl: Scalars['String'];
  readonly id: Scalars['Int'];
};

export type UpdateMarketplaceInfluencerCost = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateMarketplaceInfluencerCostInput = {
  readonly influencers: ReadonlyArray<MarketplaceInfluencerRevenueInput>;
  readonly marketplaceId: Scalars['Int'];
};

export type UpdateMarketplaceInput = {
  readonly additionalRequirement?: InputMaybe<Scalars['String']>;
  readonly advertiserId: Scalars['Int'];
  readonly agencyMarginRate?: InputMaybe<Scalars['Float']>;
  readonly allowNewInfluencer: Scalars['Boolean'];
  readonly brandName?: InputMaybe<Scalars['String']>;
  readonly budget: Scalars['Float'];
  readonly campaignCategoryId: Scalars['Int'];
  readonly campaignType: MarketplaceCampaignDetailType;
  readonly campaignUrl?: InputMaybe<Scalars['String']>;
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly clickUrl?: InputMaybe<Scalars['String']>;
  /** for TUNE Tracking */
  readonly costPerAction?: InputMaybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerClick?: InputMaybe<Scalars['Float']>;
  readonly costPerComment?: InputMaybe<Scalars['Float']>;
  readonly costPerFollower?: InputMaybe<Scalars['Float']>;
  readonly costPerLike?: InputMaybe<Scalars['Float']>;
  /** for referral code Tracking */
  readonly costPerOrder?: InputMaybe<Scalars['Float']>;
  readonly costPerPost?: InputMaybe<Scalars['Float']>;
  /** for referral code Tracking */
  readonly costPerSaleReferral?: InputMaybe<Scalars['Float']>;
  /** for TUNE Tracking */
  readonly costPerSaleTune?: InputMaybe<Scalars['Float']>;
  readonly costPerShare?: InputMaybe<Scalars['Float']>;
  readonly costPerView?: InputMaybe<Scalars['Float']>;
  readonly countryId: Scalars['String'];
  readonly couponUploadFiles?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly genders: ReadonlyArray<Genders>;
  readonly hashtags: ReadonlyArray<Scalars['String']>;
  readonly hubspotDealIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['Int'];
  readonly influencerManagementPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly isAllowMultiplePosts?: InputMaybe<Scalars['Boolean']>;
  readonly isAutoInfluencerApproval?: InputMaybe<Scalars['Boolean']>;
  readonly isGiftingCampaign?: InputMaybe<Scalars['Boolean']>;
  readonly landingPageUrl?: InputMaybe<Scalars['String']>;
  readonly marginRate: Scalars['Float'];
  readonly materialUrl?: InputMaybe<Scalars['String']>;
  readonly materials?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly maxAge: Scalars['Int'];
  readonly maxFollowers: Scalars['Int'];
  readonly maximumRevenuePerInfluencer?: InputMaybe<Scalars['Float']>;
  readonly minAge: Scalars['Int'];
  readonly minFollowers: Scalars['Int'];
  readonly mkpServiceCampaignId?: InputMaybe<Scalars['Int']>;
  readonly preLaunchDate?: InputMaybe<Scalars['Date']>;
  readonly productThumbNail?: InputMaybe<Scalars['String']>;
  readonly regionIds: ReadonlyArray<Scalars['Int']>;
  readonly requireDraftPost?: InputMaybe<Scalars['Boolean']>;
  readonly requirement: Scalars['String'];
  readonly salesPicIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly sampleUrl?: InputMaybe<Scalars['String']>;
  readonly serviceInformation: Scalars['String'];
  readonly socialMedia: CampaignSocialMediaType;
  readonly startDate: Scalars['Date'];
  readonly thumbnails: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingOptions?: InputMaybe<ReadonlyArray<CampaignTrackingOption>>;
};

export type UpdateMarketplacePostStatusInputV2 = {
  readonly influencerIds: ReadonlyArray<Scalars['Int']>;
  /** Campaign Id */
  readonly marketplaceId: Scalars['Int'];
  readonly postIds: ReadonlyArray<InputMaybe<Scalars['Int']>>;
  readonly status: MarketplaceCampaignPostStatus;
};

export type UpdateMarketplacePostStatusV2 = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateMarketplacePostTrackingUrl = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateMarketplacePostTrackingUrlInput = {
  readonly campaignId: Scalars['Int'];
  readonly postTrackingUrl: Scalars['String'];
};

export type UpdateOptionInput = {
  readonly id?: InputMaybe<Scalars['Long']>;
  readonly optionTitle: Scalars['String'];
  readonly order: Scalars['Int'];
};

export type UpdatePackage = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePackageInput = {
  readonly companyAndRolePairs: ReadonlyArray<CompanyAndRolePair>;
  readonly countryId: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type UpdatePackageProposalInfluencersBudget = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePackageProposalInfluencersBudgetInput = {
  readonly influencers: ReadonlyArray<PackageProposalInfluencerBudgetInput>;
  readonly packageId: Scalars['Int'];
};

export type UpdatePackageProposalMode = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePackageProposalModeInput = {
  /** breakdown mode */
  readonly jdMode: ProposalJobDescriptionBreakDownMode;
  readonly packageId: Scalars['Int'];
};

export type UpdatePackageProposalSocialAccountsBudget = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePackageProposalSocialAccountsBudgetInput = {
  readonly packageId: Scalars['Int'];
  readonly socialAccounts: ReadonlyArray<PackageProposalSocialAcccountBudgetInput>;
};

export type UpdatePartnerAgency = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePartnerAgencyInput = {
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  readonly agencyIds: ReadonlyArray<Scalars['Int']>;
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly influencerCostPermission: Scalars['Boolean'];
  readonly logo?: InputMaybe<Scalars['String']>;
  readonly netsuiteId?: InputMaybe<Scalars['String']>;
  readonly partnerAgencyName: Scalars['String'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly privateInfluencersIds: ReadonlyArray<Scalars['Int']>;
};

export type UpdatePartnerUser = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePartnerUserInput = {
  /** Partner User Country */
  readonly countryId: Scalars['String'];
  /** Partner User Email */
  readonly email: Scalars['String'];
  /** Partner User Id */
  readonly id: Scalars['Int'];
  /** Partner User Name */
  readonly name: Scalars['String'];
  /** Partner User Password */
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdatePasswordByAdvertiserUser = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePasswordByAdvertiserUserInput = {
  readonly newPassword: Scalars['String'];
  readonly originalPassword: Scalars['String'];
};

export type UpdatePaymentInformation = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePaymentInformationInput = {
  readonly accountName: Scalars['String'];
  readonly accountNumber: Scalars['String'];
  readonly address?: InputMaybe<Scalars['String']>;
  readonly bankId: Scalars['Int'];
  /** Branch ID */
  readonly branchId?: InputMaybe<Scalars['Int']>;
  /** Branch Name when user manually inserts it */
  readonly branchName?: InputMaybe<Scalars['String']>;
  readonly cityId?: InputMaybe<Scalars['Int']>;
  readonly email: Scalars['String'];
  readonly fullName?: InputMaybe<Scalars['String']>;
  /** IBAN */
  readonly iban?: InputMaybe<Scalars['String']>;
  readonly postalCode?: InputMaybe<Scalars['String']>;
  readonly subject?: InputMaybe<Scalars['String']>;
  /** SWIFT Code */
  readonly swiftCode?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentStatus = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePaymentStatusInput = {
  readonly id: Scalars['Int'];
  readonly status: PaymentRequestStatus;
};

export type UpdatePersoanlUserDetailPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePersonalUserDetailInput = {
  readonly crmNotificationSetting?: InputMaybe<Scalars['Boolean']>;
  readonly displayLanguage: DisplayLanguage;
  readonly eggNotificationSetting?: InputMaybe<Scalars['Boolean']>;
  readonly email: Scalars['String'];
  readonly mkpNotificationSetting?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notificationSetting?: InputMaybe<Scalars['Boolean']>;
  readonly password?: InputMaybe<Scalars['String']>;
};

export type UpdatePlainAgency = {
  readonly ok: Scalars['Boolean'];
};

export type UpdatePlainAgencyInput = {
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  readonly agencyName: Scalars['String'];
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId: Scalars['String'];
  readonly hubspotId?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly internalNetsuiteId?: InputMaybe<Scalars['String']>;
  readonly logo?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateQuestionInput = {
  readonly id?: InputMaybe<Scalars['Long']>;
  readonly image?: InputMaybe<Scalars['String']>;
  readonly isRequired: Scalars['Boolean'];
  readonly options: ReadonlyArray<UpdateOptionInput>;
  readonly order: Scalars['Int'];
  readonly questionType: QuestionType;
  readonly title: Scalars['String'];
};

export type UpdateSocialAccountsInput = {
  readonly influencerId: Scalars['Int'];
};

export type UpdateSocialAccountsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateSocialUsername = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateSocialUsernameInput = {
  readonly influencerId: Scalars['Int'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialMedia: SocialAccountType;
  readonly username: Scalars['String'];
};

export type UpdateStaff = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateStaffInput = {
  readonly advertiserIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly autoLinkToAdvertiser: Scalars['Boolean'];
  readonly countryId?: InputMaybe<Scalars['String']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateSubscriptionPlan = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateSubscriptionPlanInput = {
  readonly priceId: Scalars['Int'];
};

export type UpdateTalentAgency = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateTalentAgencyInput = {
  readonly advertiserIds: ReadonlyArray<Scalars['Int']>;
  readonly agencyIds: ReadonlyArray<Scalars['Int']>;
  readonly cmsMarginRate?: InputMaybe<Scalars['Float']>;
  readonly companyUrl?: InputMaybe<Scalars['String']>;
  readonly countryId: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly netsuiteId?: InputMaybe<Scalars['String']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly privateInfluencersIds: ReadonlyArray<Scalars['Int']>;
  readonly talentAgencyName: Scalars['String'];
};

export type UpdateTalentAgencyUser = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateTalentAgencyUserInput = {
  /** Talent Agency User Country */
  readonly countryId: Scalars['String'];
  /** Talent Agency User Email */
  readonly email: Scalars['String'];
  /** Talent Agency User Id */
  readonly id: Scalars['Int'];
  /** Talent Agency User Name */
  readonly name: Scalars['String'];
  /** Talent Agency User Password */
  readonly password?: InputMaybe<Scalars['String']>;
  readonly permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateTalentInfluencerInput = {
  readonly categoryIds: ReadonlyArray<Scalars['Int']>;
  readonly dateOfBirth: Scalars['String'];
  readonly defaultEngagementSelectionReason?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly gender: Genders;
  readonly influencerId: Scalars['Int'];
  readonly internalMemo?: InputMaybe<Scalars['String']>;
  readonly introduce?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
  readonly rateCards?: InputMaybe<RateCardsInput>;
  readonly regionId: Scalars['Int'];
  readonly selectedSocialAccounts: SelectedSocialAccounts;
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type UpdateTalentInfluencerPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateTikTokSpecialCampaign = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateTikTokSpecialCampaignInput = {
  readonly campaignCategoryId?: InputMaybe<Scalars['Int']>;
  /** nullable for draft */
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly hashtags: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly isDraft: Scalars['Boolean'];
  readonly materialUrl?: InputMaybe<Scalars['String']>;
  readonly materials: ReadonlyArray<Scalars['String']>;
  readonly preLaunchDate?: InputMaybe<Scalars['Date']>;
  readonly productUrl?: InputMaybe<Scalars['String']>;
  readonly requirement: Scalars['String'];
  readonly sampleUrl?: InputMaybe<Scalars['String']>;
  readonly serviceInformation: Scalars['String'];
  /** nullable for draft */
  readonly startDate?: InputMaybe<Scalars['Date']>;
  readonly thumbnails: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type UpdateTiktokAdvertiserInfo = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateTiktokAdvertiserInfoInput = {
  readonly advertiserName: Scalars['String'];
  readonly advertiserUrl: Scalars['String'];
  readonly countryId: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
};

export type UpdateTiktokSpecialPostStatus = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateTiktokSpecialPostStatusInput = {
  /** Campaign Id */
  readonly campaignId: Scalars['Int'];
  readonly postIds: ReadonlyArray<InputMaybe<Scalars['Int']>>;
  readonly status: TikTokSpecialCampaignPostStatus;
};

export type UpdateTtcmPost = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateTtcmPostInput = {
  readonly content: Scalars['String'];
  /** Post id */
  readonly id: Scalars['Int'];
  readonly thumbnailUrl: Scalars['String'];
};

export type UpdateUserDetailInput = {
  readonly crmNotificationSetting?: InputMaybe<Scalars['Boolean']>;
  readonly eggNotificationSetting?: InputMaybe<Scalars['Boolean']>;
  readonly email: Scalars['String'];
  readonly mkpNotificationSetting?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notificationSetting?: InputMaybe<Scalars['Boolean']>;
  readonly userId: Scalars['Int'];
};

export type UpdateUserDetailPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateUserProposalBreakdownMode = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateUserProposalBreakdownModeInput = {
  /** breakdown mode */
  readonly jdMode: ProposalJobDescriptionBreakDownMode;
};

export type UpdateUserProposalInfluencersBudget = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateUserProposalInfluencersBudgetInput = {
  readonly influencers: ReadonlyArray<UserProposalInfluencerBudgetInput>;
};

export type UpdateUserProposalSocialAccountsBudget = {
  readonly ok: Scalars['Boolean'];
};

export type UpdateUserProposalSocialAccountsBudgetInput = {
  readonly socialAccounts: ReadonlyArray<UserProposalSocialAcccountBudgetInput>;
};

export type UpdateYoutubeMCNCredentialsInput = {
  readonly callbackUrl: Scalars['String'];
  readonly response: Scalars['String'];
};

export type UpdateYoutubeMCNCredentialsPayload = {
  readonly ok: Scalars['Boolean'];
};

export type UploadEngagementReportFile = {
  readonly ok: Scalars['Boolean'];
};

export type UploadEngagementReportFileInput = {
  /** Engagement Campaign Id */
  readonly id: Scalars['Int'];
  /** Upload file url */
  readonly uploadFileUrl: Scalars['String'];
};

export type UploadEngagementReportFromInsightCheck = {
  readonly ok: Scalars['Boolean'];
};

export type UploadEngagementReportFromInsightCheckInput = {
  readonly audienceRetention?: InputMaybe<Scalars['Float']>;
  readonly averageViewDurationHours?: InputMaybe<Scalars['Float']>;
  readonly averageViewDurationMinutes?: InputMaybe<Scalars['Float']>;
  readonly back?: InputMaybe<Scalars['Int']>;
  readonly comments?: InputMaybe<Scalars['Int']>;
  readonly exited?: InputMaybe<Scalars['Int']>;
  readonly followerReach?: InputMaybe<Scalars['Int']>;
  /** for Douyin only */
  readonly followersCount?: InputMaybe<Scalars['Int']>;
  readonly forward?: InputMaybe<Scalars['Int']>;
  readonly impressions?: InputMaybe<Scalars['Int']>;
  readonly impressionsClickThroughRate?: InputMaybe<Scalars['Float']>;
  readonly impressionsFromDiscovery?: InputMaybe<Scalars['Int']>;
  readonly impressionsFromHashtag?: InputMaybe<Scalars['Int']>;
  readonly impressionsFromHome?: InputMaybe<Scalars['Int']>;
  readonly impressionsFromOther?: InputMaybe<Scalars['Int']>;
  readonly impressionsFromProfile?: InputMaybe<Scalars['Int']>;
  readonly interaction?: InputMaybe<Scalars['Int']>;
  readonly likes?: InputMaybe<Scalars['Int']>;
  readonly linkClicks?: InputMaybe<Scalars['Int']>;
  readonly navigation?: InputMaybe<Scalars['Int']>;
  readonly newFollowers?: InputMaybe<Scalars['Int']>;
  readonly nextStory?: InputMaybe<Scalars['Int']>;
  readonly nonFollowerReach?: InputMaybe<Scalars['Int']>;
  /** Engagement Campaign Post Id */
  readonly postId: Scalars['Int'];
  readonly profileActivity?: InputMaybe<Scalars['Int']>;
  readonly reaches?: InputMaybe<Scalars['Int']>;
  readonly saves?: InputMaybe<Scalars['Int']>;
  readonly shares?: InputMaybe<Scalars['Int']>;
  readonly stickerTaps?: InputMaybe<Scalars['Int']>;
  readonly views?: InputMaybe<Scalars['Int']>;
};

export type UploadEngagementXhsInsightCheck = {
  readonly ok: Scalars['Boolean'];
};

export type UploadEngagementXhsInsightCheckInput = {
  readonly age45UpPercentage?: InputMaybe<Scalars['Float']>;
  readonly age1824Percentage?: InputMaybe<Scalars['Float']>;
  readonly age2534Percentage?: InputMaybe<Scalars['Float']>;
  readonly age3544Percentage?: InputMaybe<Scalars['Float']>;
  readonly ageUp17Percentage?: InputMaybe<Scalars['Float']>;
  readonly averageViewDurationSeconds?: InputMaybe<Scalars['Int']>;
  readonly comments?: InputMaybe<Scalars['Int']>;
  readonly femalePercentage?: InputMaybe<Scalars['Float']>;
  readonly likes?: InputMaybe<Scalars['Int']>;
  readonly malePercentage?: InputMaybe<Scalars['Float']>;
  readonly newFollowers?: InputMaybe<Scalars['Int']>;
  readonly postId: Scalars['Int'];
  readonly saves?: InputMaybe<Scalars['Int']>;
  readonly views?: InputMaybe<Scalars['Int']>;
  readonly viewsFromFollowing?: InputMaybe<Scalars['Int']>;
  readonly viewsFromHome?: InputMaybe<Scalars['Int']>;
  readonly viewsFromOther?: InputMaybe<Scalars['Int']>;
  readonly viewsFromProfile?: InputMaybe<Scalars['Int']>;
  readonly viewsFromSearch?: InputMaybe<Scalars['Int']>;
};

export type UploadOpenAIImages = {
  readonly ok: Scalars['Boolean'];
};

export type UploadOpenAIImagesInput = {
  readonly images?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly threadId: Scalars['Int'];
};

export type UserAudioMessageEvent = ChatEvent & {
  readonly contentUrl: Scalars['String'];
  readonly duration: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type UserDefaultPermissions = {
  readonly permissions?: Maybe<CustomPermissions>;
};

export type UserDetail = {
  readonly advertisers: ReadonlyArray<AdvertiserName>;
  /** Agency Id */
  readonly agencyId?: Maybe<Scalars['Int']>;
  readonly autoLinkToAdvertiser?: Maybe<Scalars['Boolean']>;
  readonly country: CountryName;
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly notificationSetting?: Maybe<Scalars['Boolean']>;
  /** Partner Id */
  readonly partnerId?: Maybe<Scalars['Int']>;
  readonly permissions?: Maybe<CustomPermissions>;
  readonly role: UserRoles;
  /** Talent Agency Id */
  readonly talentAgencyId?: Maybe<Scalars['Int']>;
};

export type UserDetailInput = {
  readonly userId: Scalars['Int'];
};

export type UserDetailPayload = {
  readonly email?: Maybe<Scalars['String']>;
  readonly isAvailableCountry: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly notificationSetting?: Maybe<Scalars['Boolean']>;
};

export type UserImageMessageEvent = ChatEvent & {
  readonly id: Scalars['String'];
  readonly imageUrl: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type UserPostbackEvent = ChatEvent & {
  readonly displayText: Scalars['String'];
  readonly id: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type UserProposal = {
  readonly currency: Scalars['String'];
  /** Proposal Id */
  readonly id: Scalars['Int'];
  readonly influencers: ReadonlyArray<UserProposalInfluencer>;
  readonly jdMode: ProposalJobDescriptionBreakDownMode;
  readonly summary: UserProposalJobSummary;
};

export type UserProposalInfluencer = {
  readonly accountList: ReadonlyArray<UserProposalSocialAccount>;
  readonly addedDate?: Maybe<Scalars['Date']>;
  readonly avatar?: Maybe<Scalars['String']>;
  /** Influencer id */
  readonly id: Scalars['Int'];
  readonly isDenied?: Maybe<Scalars['Boolean']>;
  /** Influencer name */
  readonly name: Scalars['String'];
  readonly status: SocialAccountStatus;
  readonly summary: UserProposalJobSummary;
};

export type UserProposalInfluencerBudgetInput = {
  readonly budget: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
  readonly profit: Scalars['Float'];
};

export type UserProposalJobSummary = {
  readonly averageEngagement?: Maybe<Scalars['Float']>;
  readonly budget?: Maybe<Scalars['Float']>;
  readonly engagementFollowerRate?: Maybe<Scalars['Float']>;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly engagementViewRate?: Maybe<Scalars['Float']>;
  readonly followersCount?: Maybe<Scalars['BigInt']>;
  readonly influencerCost?: Maybe<Scalars['Float']>;
  readonly profit?: Maybe<Scalars['Float']>;
};

export type UserProposalSocialAcccountBudgetInput = {
  readonly budget: Scalars['Float'];
  readonly influencerCost: Scalars['Float'];
  readonly influencerId: Scalars['Int'];
  readonly profit: Scalars['Float'];
  readonly socialAccountId: Scalars['Int'];
  readonly socialAccountMedia: UserProposalSocialMediaType;
};

export type UserProposalSocialAccount = {
  readonly addedDate?: Maybe<Scalars['Date']>;
  /** Social account id */
  readonly id: Scalars['Int'];
  /** Social account name */
  readonly name?: Maybe<Scalars['String']>;
  /** Social account type */
  readonly socialMedia: UserProposalSocialMediaType;
  readonly summary: UserProposalJobSummary;
};

/** An enumeration. */
export enum UserProposalSocialMediaType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE'
}

/** An enumeration. */
export enum UserRoles {
  ADMINISTRATOR = 'ADMINISTRATOR',
  ADVERTISER = 'ADVERTISER',
  AGENCY = 'AGENCY',
  CREATOR_STAFF = 'CREATOR_STAFF',
  EXTERNAL = 'EXTERNAL',
  INFLUENCER = 'INFLUENCER',
  PARTNER = 'PARTNER',
  STAFF = 'STAFF',
  TALENT_AGENCY = 'TALENT_AGENCY'
}

export type UserTextMessageEvent = ChatEvent & {
  readonly id: Scalars['String'];
  readonly text: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type UserVideoMessageEvent = ChatEvent & {
  readonly contentUrl: Scalars['String'];
  readonly duration: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly timestamp: Scalars['String'];
};

export type VerifyInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']>;
  readonly token: Scalars['String'];
};

export type VerifyLineIDTokenInput = {
  readonly idToken: Scalars['String'];
};

export type VerifyLineIDTokenPayload = {
  readonly ok: Scalars['Boolean'];
};

export type VerifyPayload = {
  readonly company?: Maybe<Scalars['String']>;
  /** Users's country's id */
  readonly countryId?: Maybe<Scalars['String']>;
  readonly creatorType?: Maybe<InfluencerType>;
  /** Users's country's currency id */
  readonly currencyId?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly hash?: Maybe<Scalars['String']>;
  readonly limitedLogin: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly ok: Scalars['Boolean'];
  readonly role?: Maybe<UserRoles>;
  readonly subscriptionPaymentFailed?: Maybe<Scalars['Boolean']>;
  readonly subscriptionPlan?: Maybe<PaymentPlanName>;
  readonly userId?: Maybe<Scalars['Int']>;
};

export type VideoInput = {
  readonly fileName: Scalars['String'];
  readonly mimeType: Scalars['String'];
  readonly previewUrl: Scalars['String'];
  readonly url: Scalars['String'];
};

export type VideoPayload = {
  readonly fileName: Scalars['String'];
  readonly mimeType: Scalars['String'];
  readonly previewUrl: Scalars['String'];
  readonly url: Scalars['String'];
};

/** An enumeration. */
export enum WarningReason {
  AD_FRAUD = 'AD_FRAUD',
  API_ERROR = 'API_ERROR',
  COUPON_MISSING = 'COUPON_MISSING',
  DELETED_POST = 'DELETED_POST',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  INVALID_CONTENT = 'INVALID_CONTENT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  SCRAPING_ERROR = 'SCRAPING_ERROR'
}

export type WithdrawMoneyFromAdAccount = {
  readonly ok: Scalars['Boolean'];
};

export type XhsAccountInterest = {
  readonly deviceRates?: Maybe<ReadonlyArray<AccountAudienceDataRate>>;
  readonly interestRates?: Maybe<ReadonlyArray<AccountAudienceDataRate>>;
};

export type XhsAudienceSection = {
  readonly ageRates: TiktokFollowersAgeGroup;
  readonly cityRates?: Maybe<ReadonlyArray<AccountAudienceDataRate>>;
  readonly femaleRate?: Maybe<Scalars['Float']>;
  readonly maleRate?: Maybe<Scalars['Float']>;
  readonly provinceRates?: Maybe<ReadonlyArray<AccountAudienceDataRate>>;
};

export type XhsEngagementInsightSection = {
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averagePostsPerWeek: Scalars['Float'];
  readonly averageSaved?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly engagementViewsRate?: Maybe<Scalars['Float']>;
  readonly postCategories?: Maybe<ReadonlyArray<AccountAudienceDataRate>>;
  readonly postsHabit: ReadonlyArray<InfluencerNewProfilePostsHabit>;
};

export type XhsInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly bio?: Maybe<Scalars['String']>;
  readonly country: CountryName;
  readonly gender: Genders;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly popularPosts: ReadonlyArray<SocialAccountPopularPost>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountEngagementViewsRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  readonly socialAccountId: Scalars['ID'];
  readonly socialAccountStatus: SocialAccountStatus;
};

export type XhsInfluencerSearchResult = {
  readonly totalNumber: Scalars['Int'];
  readonly xhsAccounts: ReadonlyArray<XhsInfluencer>;
};

export type XhsPostSection = {
  readonly picturePosts: ReadonlyArray<InfluencerFeedPost>;
  readonly videoPosts: ReadonlyArray<InfluencerFeedPost>;
};

/** An enumeration. */
export enum XhsPostType {
  NORMAL = 'NORMAL',
  SPONSORED = 'SPONSORED'
}

export type YouTubeBrandAccount = {
  readonly averageViews: Scalars['Float'];
  /** youtube mentioned brand account id */
  readonly id: Scalars['Int'];
  readonly mentionPostsCount: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly postIds: ReadonlyArray<Scalars['Int']>;
};

export type YouTubeBrandAccounts = {
  readonly averageEngagementRate?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly brandAccounts?: Maybe<ReadonlyArray<YouTubeBrandAccount>>;
};

export type YouTubeCmsRevenueForInfluencer = {
  readonly revenue?: Maybe<TotalCmsRevenue>;
};

export type YouTubeCmsVideoAudience = {
  readonly ageGenderRates: YoutubeAgeGenderGroup;
  readonly countryRates: ReadonlyArray<YoutubeAudienceCountryRate>;
  readonly genderRates: YoutubeAudienceGenderRate;
};

export type YouTubeCmsVideoStats = {
  readonly assetTitle?: Maybe<Scalars['String']>;
  readonly avgPercentageView: Scalars['Float'];
  readonly avgViewDuration: Scalars['Float'];
  readonly id: Scalars['String'];
  readonly videoThumbnail: Scalars['String'];
  readonly videoTitle?: Maybe<Scalars['String']>;
  readonly views: Scalars['Int'];
};

export type YouTubeInfluencer = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly analyticsWarning: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly categories: ReadonlyArray<CategoryName>;
  readonly country: CountryName;
  readonly engagementPostedCount?: Maybe<Scalars['Int']>;
  readonly engagementProposedCount?: Maybe<Scalars['Int']>;
  readonly gender: Genders;
  /** Influencer Id */
  readonly id: Scalars['Int'];
  readonly influencerEngagement?: Maybe<Scalars['Int']>;
  readonly influencerEngagementRate?: Maybe<Scalars['Float']>;
  readonly influencerFollowers?: Maybe<Scalars['Int']>;
  readonly isBrandAccount: Scalars['Boolean'];
  readonly marketplaceJoinedCount?: Maybe<Scalars['Int']>;
  readonly marketplacePostedCount?: Maybe<Scalars['Int']>;
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountEngagementViewsRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  /** Social Account System Id */
  readonly socialAccountId: Scalars['Int'];
  /** Social Account Status */
  readonly socialAccountStatus: SocialAccountStatus;
};

export type YoutubeAccountForProfileV2 = {
  readonly analyticsEnabled: Scalars['Boolean'];
  readonly avatar?: Maybe<Scalars['String']>;
  readonly followersCount?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
};

export type YoutubeAgeGenderGroup = {
  readonly ageGroup: ReadonlyArray<FollowersAgeGroup>;
  readonly femaleRate: ReadonlyArray<Scalars['Float']>;
  readonly maleRate: ReadonlyArray<Scalars['Float']>;
  readonly otherRate: ReadonlyArray<Scalars['Float']>;
};

export type YoutubeAnalyticsAccountCard = {
  readonly accountId: Scalars['Int'];
  readonly avatarUrl: Scalars['String'];
  readonly channelName: Scalars['String'];
  readonly elementId: Scalars['Int'];
  readonly profileUrl: Scalars['String'];
};

export type YoutubeAnalyticsAccountOverview = {
  readonly accountId: Scalars['Int'];
  readonly graph: YoutubeCompareOverviewHistory;
  readonly summary: YoutubeCompareOverviewSummary;
};

export type YoutubeAnalyticsAccountPosts = {
  readonly accountId: Scalars['Int'];
  readonly postHabit: ReadonlyArray<YoutubeAnalyticsPostsPostingHabit>;
  readonly posts: ReadonlyArray<YoutubeCompareAccountPost>;
};

export type YoutubeAnalyticsAccountPostsInDate = {
  readonly accountId: Scalars['Int'];
  readonly posts: ReadonlyArray<YoutubeAnalyticsPostPayload>;
};

export type YoutubeAnalyticsAccountTagRanking = {
  readonly accountId: Scalars['Int'];
  readonly tagsRanking: ReadonlyArray<YoutubeTagRanking>;
};

export type YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload = {
  readonly end: Scalars['Int'];
  readonly start: Scalars['Int'];
};

export type YoutubeAnalyticsAudienceAgeBreakdownPayload = {
  readonly ageGroup: ReadonlyArray<YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  readonly femaleRate: ReadonlyArray<Scalars['Float']>;
  readonly maleRate: ReadonlyArray<Scalars['Float']>;
  readonly otherRate: ReadonlyArray<Scalars['Float']>;
};

export type YoutubeAnalyticsAudienceGenderBreakdownPayload = {
  readonly femaleRate: Scalars['Float'];
  readonly maleRate: Scalars['Float'];
  readonly otherRate: Scalars['Float'];
};

export type YoutubeAnalyticsAudiencePayload = {
  readonly ageRate: YoutubeAnalyticsAudienceAgeBreakdownPayload;
  readonly genderRate: YoutubeAnalyticsAudienceGenderBreakdownPayload;
  readonly regionRate: ReadonlyArray<YoutubeAnalyticsAudienceRegionPayload>;
};

export type YoutubeAnalyticsAudienceRegionPayload = {
  readonly name: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type YoutubeAnalyticsCategory = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type YoutubeAnalyticsCommentsPayload = {
  readonly comments: ReadonlyArray<YoutubeAnalyticsPostCommentItem>;
};

export type YoutubeAnalyticsCompareAccountCard = {
  readonly accountId: Scalars['Int'];
  readonly avatarUrl: Scalars['String'];
  readonly channelName: Scalars['String'];
  readonly elementId: Scalars['Int'];
  readonly profileUrl: Scalars['String'];
};

export type YoutubeAnalyticsCompareAccountCards = {
  readonly compareAccounts: ReadonlyArray<YoutubeAnalyticsCompareAccountCard>;
  readonly mainAccount?: Maybe<YoutubeAnalyticsAccountCard>;
};

export type YoutubeAnalyticsComparePostComment = {
  readonly commentId: Scalars['String'];
  readonly content: Scalars['String'];
  readonly posterName: Scalars['String'];
};

export type YoutubeAnalyticsComparePostsSort = {
  readonly field?: InputMaybe<YoutubeAnalyticsComparePostsSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum YoutubeAnalyticsComparePostsSortOrder {
  COMMENT = 'COMMENT',
  DISLIKE = 'DISLIKE',
  DURATION = 'DURATION',
  LIKE = 'LIKE',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  VIEWS = 'VIEWS'
}

export type YoutubeAnalyticsCompareRelatedPost = {
  readonly channelName: Scalars['String'];
  readonly comments: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly postDate: Scalars['DateTime'];
  readonly videoCaption: Scalars['String'];
  readonly videoDuration: Scalars['Int'];
  readonly videoId: Scalars['String'];
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type YoutubeAnalyticsCompareRelatedPosts = {
  readonly posts: ReadonlyArray<YoutubeAnalyticsCompareRelatedPost>;
  readonly tag: Scalars['String'];
};

export type YoutubeAnalyticsCountriesCategoriesPayload = {
  readonly categories: ReadonlyArray<YoutubeAnalyticsCategory>;
  readonly countries: ReadonlyArray<YoutubeAnalyticsCountry>;
  readonly date?: Maybe<Scalars['String']>;
};

export type YoutubeAnalyticsCountry = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type YoutubeAnalyticsHistoryDataPoint = {
  readonly count: Scalars['Int'];
  readonly date: Scalars['Date'];
};

export type YoutubeAnalyticsOverviewHistoryItem = {
  readonly count: Scalars['Int'];
  readonly date: Scalars['Date'];
};

export type YoutubeAnalyticsOverviewItem = {
  readonly growth: Scalars['Long'];
  readonly history: ReadonlyArray<YoutubeAnalyticsOverviewHistoryItem>;
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Long'];
};

export type YoutubeAnalyticsOverviewPayload = {
  readonly comment: YoutubeAnalyticsOverviewItem;
  readonly dislike: YoutubeAnalyticsOverviewItem;
  readonly lastUpdated: Scalars['DateTime'];
  readonly like: YoutubeAnalyticsOverviewItem;
  readonly post: YoutubeAnalyticsOverviewItem;
  readonly subscriber: YoutubeAnalyticsOverviewItem;
  readonly view: YoutubeAnalyticsOverviewItem;
};

export type YoutubeAnalyticsPostComment = {
  readonly commentId: Scalars['String'];
  readonly content: Scalars['String'];
  readonly posterName: Scalars['String'];
};

export type YoutubeAnalyticsPostCommentItem = {
  readonly commentId: Scalars['String'];
  readonly content: Scalars['String'];
  readonly created: Scalars['Date'];
  readonly postUrl: Scalars['String'];
  readonly username: Scalars['String'];
};

export type YoutubeAnalyticsPostPayload = {
  readonly averageViewDuration?: Maybe<Scalars['Long']>;
  readonly averageViewPercentage?: Maybe<Scalars['Float']>;
  readonly comments: Scalars['Long'];
  readonly dislikes: Scalars['Long'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Long'];
  readonly postDate: Scalars['Date'];
  readonly subscribers: Scalars['Int'];
  readonly videoCaption: Scalars['String'];
  readonly videoComments: ReadonlyArray<YoutubeAnalyticsPostComment>;
  readonly videoDuration: Scalars['Int'];
  readonly videoEmbed: Scalars['String'];
  readonly videoId: Scalars['String'];
  readonly videoTags: ReadonlyArray<Scalars['String']>;
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Long'];
};

export type YoutubeAnalyticsPostPayloads = {
  readonly avatar: Scalars['String'];
  readonly channelName: Scalars['String'];
  readonly posts: ReadonlyArray<YoutubeAnalyticsPostPayload>;
  readonly subscribers: Scalars['Int'];
};

export type YoutubeAnalyticsPostWithChannelDetailsPayload = {
  readonly avatar: Scalars['String'];
  readonly averageViewDuration?: Maybe<Scalars['Long']>;
  readonly averageViewPercentage?: Maybe<Scalars['Float']>;
  readonly channelName: Scalars['String'];
  readonly comments: Scalars['Long'];
  readonly dislikes: Scalars['Long'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Long'];
  readonly postDate: Scalars['Date'];
  readonly subscribers: Scalars['Int'];
  readonly videoCaption: Scalars['String'];
  readonly videoComments: ReadonlyArray<YoutubeAnalyticsPostComment>;
  readonly videoDuration: Scalars['Int'];
  readonly videoEmbed: Scalars['String'];
  readonly videoId: Scalars['String'];
  readonly videoTags: ReadonlyArray<Scalars['String']>;
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Long'];
};

export type YoutubeAnalyticsPostsAverageEngagement = {
  readonly averageComments: YoutubeStatisticsData;
  readonly averageDislikes: YoutubeStatisticsData;
  readonly averageLikes: YoutubeStatisticsData;
  readonly averagePostsPerWeek?: Maybe<YoutubeStatisticsData>;
  readonly averageViews: YoutubeStatisticsData;
};

/**
 *  YoutubeAnalyticsPostsPayload is the individual data
 *    for a list of YoutubeAnalyticsPost
 *  Some video information such as video embed, comments, descriptions are not included
 *  Some video analytics data such as revenue are not included
 *  Duration is in seconds
 */
export type YoutubeAnalyticsPostsPayload = {
  readonly averageViewDuration?: Maybe<Scalars['Long']>;
  readonly averageViewPercentage?: Maybe<Scalars['Float']>;
  readonly comments: Scalars['Long'];
  readonly dislikes: Scalars['Long'];
  readonly id: Scalars['Int'];
  readonly isSponsoredPost: Scalars['Boolean'];
  readonly likes: Scalars['Long'];
  readonly postDate: Scalars['DateTime'];
  readonly shares: Scalars['Long'];
  readonly videoDuration: Scalars['Int'];
  readonly videoId: Scalars['String'];
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Long'];
};

export type YoutubeAnalyticsPostsPayloads = {
  readonly posts: ReadonlyArray<YoutubeAnalyticsPostsPayload>;
};

export type YoutubeAnalyticsPostsPostingHabit = {
  readonly averageComments: Scalars['Float'];
  readonly averageLikes: Scalars['Float'];
  readonly engagementRate: Scalars['Float'];
  readonly hour: Scalars['Int'];
  readonly weekday: Scalars['Int'];
};

export type YoutubeAnalyticsPostsSortInput = {
  readonly field?: InputMaybe<YoutubeAnalyticsPostsSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum YoutubeAnalyticsPostsSortOrder {
  AVERAGE_VIEW_DURATION = 'AVERAGE_VIEW_DURATION',
  AVERAGE_VIEW_PERCENTAGE = 'AVERAGE_VIEW_PERCENTAGE',
  COMMENT = 'COMMENT',
  DISLIKE = 'DISLIKE',
  DURATION = 'DURATION',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKE = 'LIKE',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  SHARE = 'SHARE',
  VIEWS = 'VIEWS'
}

export type YoutubeAnalyticsPostsStatisticsPayload = {
  readonly averageEngagement: YoutubeAnalyticsPostsAverageEngagement;
  readonly postHabit: ReadonlyArray<YoutubeAnalyticsPostsPostingHabit>;
};

export type YoutubeAnalyticsRelatedPostPayload = {
  readonly comments: Scalars['Long'];
  readonly id: Scalars['Int'];
  readonly postDate: Scalars['DateTime'];
  readonly videoCaption: Scalars['String'];
  readonly videoDuration: Scalars['Int'];
  readonly videoEmbed: Scalars['String'];
  readonly videoId: Scalars['String'];
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Long'];
};

export type YoutubeAnalyticsRelatedPostsPayload = {
  readonly posts: ReadonlyArray<YoutubeAnalyticsRelatedPostPayload>;
};

export type YoutubeAnalyticsShortsPayload = {
  readonly averageViewDuration?: Maybe<Scalars['Long']>;
  readonly averageViewPercentage?: Maybe<Scalars['Float']>;
  readonly comments: Scalars['Long'];
  readonly dislikes: Scalars['Long'];
  readonly id: Scalars['Int'];
  readonly isSponsoredPost: Scalars['Boolean'];
  readonly likes: Scalars['Long'];
  readonly postDate: Scalars['DateTime'];
  readonly shares: Scalars['Long'];
  readonly videoDuration: Scalars['Int'];
  readonly videoId: Scalars['String'];
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Long'];
};

export type YoutubeAnalyticsShortsPayloads = {
  readonly shorts: ReadonlyArray<YoutubeAnalyticsShortsPayload>;
};

export type YoutubeAnalyticsSponsoredPostsSortInput = {
  readonly field?: InputMaybe<YoutubeAnalyticsSponsoredPostsSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum YoutubeAnalyticsSponsoredPostsSortOrder {
  COMMENT = 'COMMENT',
  DURATION = 'DURATION',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  SHARED = 'SHARED',
  VIEWS = 'VIEWS'
}

export type YoutubeAnalyticsTagRankingPayload = {
  readonly averageComment: Scalars['Float'];
  readonly averageDislike: Scalars['Float'];
  readonly averageEngagement: Scalars['Float'];
  readonly averageLike: Scalars['Float'];
  readonly averageShare: Scalars['Float'];
  readonly engagementRate: Scalars['Float'];
  readonly posts: Scalars['Int'];
  readonly tag: Scalars['String'];
  readonly views: Scalars['Long'];
};

export type YoutubeAnalyticsTagRankingPayloads = {
  readonly tags: ReadonlyArray<YoutubeAnalyticsTagRankingPayload>;
};

export type YoutubeAnalyticsTagRankingSortInput = {
  readonly field?: InputMaybe<YoutubeAnalyticsTagRankingSortOrder>;
  readonly order?: InputMaybe<Order>;
};

export enum YoutubeAnalyticsTagRankingSortOrder {
  AVERAGE_COMMENT = 'AVERAGE_COMMENT',
  AVERAGE_DISLIKE = 'AVERAGE_DISLIKE',
  AVERAGE_ENGAGEMENT = 'AVERAGE_ENGAGEMENT',
  AVERAGE_LIKE = 'AVERAGE_LIKE',
  AVERAGE_SHARE = 'AVERAGE_SHARE',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  POST = 'POST',
  VIEWS = 'VIEWS'
}

export type YoutubeAnalyticsTrendPayload = {
  readonly channel: Scalars['String'];
  readonly channelAvatar: Scalars['String'];
  readonly comments: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly influencerId?: Maybe<Scalars['Int']>;
  readonly likes: Scalars['Int'];
  readonly position: Scalars['Int'];
  readonly postDate: Scalars['DateTime'];
  readonly socialAccountId?: Maybe<Scalars['Int']>;
  readonly videoCaption: Scalars['String'];
  readonly videoDuration: Scalars['Int'];
  readonly videoId: Scalars['String'];
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type YoutubeAnalyticsTrendPostDetailsPayload = {
  readonly channel: Scalars['String'];
  readonly channelAvatar: Scalars['String'];
  readonly comments: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postDate: Scalars['DateTime'];
  readonly videoCaption: Scalars['String'];
  readonly videoDuration: Scalars['Int'];
  readonly videoId: Scalars['String'];
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type YoutubeAnalyticsTrendsPayloads = {
  readonly posts: ReadonlyArray<YoutubeAnalyticsTrendPayload>;
};

export type YoutubeAudienceCountryRate = {
  readonly id: Scalars['String'];
  readonly rate: Scalars['Float'];
};

export type YoutubeAudienceGenderRate = {
  readonly femaleRate: Scalars['Float'];
  readonly maleRate: Scalars['Float'];
  readonly otherRate: Scalars['Float'];
};

export type YoutubeCmsChannelDetail = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly channelId: Scalars['String'];
  readonly channelName?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly influencerId?: Maybe<Scalars['Int']>;
  readonly shareRate?: Maybe<Scalars['Float']>;
  readonly socialAccountId?: Maybe<Scalars['Int']>;
  readonly updatedDate?: Maybe<Scalars['Date']>;
};

export type YoutubeCmsChannelForInfluencer = {
  readonly currency: Scalars['String'];
  readonly id: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly revenue: Scalars['Float'];
  readonly rpm?: Maybe<Scalars['Float']>;
  readonly shareRate: Scalars['Float'];
  readonly thumbnail?: Maybe<Scalars['String']>;
};

export type YoutubeCmsChannelInfo = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly channelId: Scalars['String'];
  readonly channelName?: Maybe<Scalars['String']>;
};

export type YoutubeCmsConfirmedAsset = {
  readonly assetId: Scalars['String'];
  readonly assetName?: Maybe<Scalars['String']>;
  readonly currency: Scalars['String'];
  readonly ownerChannelId?: Maybe<Scalars['String']>;
  readonly ownerChannelName?: Maybe<Scalars['String']>;
  readonly partnerRevenue?: Maybe<Scalars['Float']>;
  readonly profit?: Maybe<Scalars['Float']>;
  readonly shareCreator?: Maybe<Scalars['Float']>;
  readonly shareRate?: Maybe<Scalars['Float']>;
};

export type YoutubeCmsConfirmedChannel = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly channelId: Scalars['String'];
  readonly channelName?: Maybe<Scalars['String']>;
  readonly currency: Scalars['String'];
  readonly partnerRevenue?: Maybe<Scalars['Float']>;
  readonly profit?: Maybe<Scalars['Float']>;
  readonly shareCreator?: Maybe<Scalars['Float']>;
  readonly shareRate?: Maybe<Scalars['Float']>;
};

export type YoutubeCmsConfirmedChannelSummary = {
  readonly currency: Scalars['String'];
  readonly partnerRevenue?: Maybe<Scalars['Float']>;
  readonly profit?: Maybe<Scalars['Float']>;
  readonly shareCreator: Scalars['Float'];
};

export type YoutubeCmsConfirmedRevenueLatestMonth = {
  readonly month: Scalars['Date'];
};

export type YoutubeCmsEstimateAsset = {
  readonly assetId: Scalars['String'];
  readonly assetName?: Maybe<Scalars['String']>;
  readonly belongMultipleChannels: Scalars['Boolean'];
  readonly cmsChannelId?: Maybe<Scalars['Int']>;
  readonly currency: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly isOwner: Scalars['Boolean'];
  readonly lastUpdatedDate?: Maybe<Scalars['Date']>;
  readonly ownerChannelId?: Maybe<Scalars['String']>;
  readonly ownerChannelName?: Maybe<Scalars['String']>;
  readonly partnerRevenue?: Maybe<Scalars['Float']>;
  readonly profit?: Maybe<Scalars['Float']>;
  readonly rpm?: Maybe<Scalars['Float']>;
  readonly shareCreator?: Maybe<Scalars['Float']>;
  readonly shareRate?: Maybe<Scalars['Float']>;
  readonly taProfit?: Maybe<Scalars['Float']>;
  readonly taRevenue?: Maybe<Scalars['Float']>;
  readonly taRpm?: Maybe<Scalars['Float']>;
  readonly taShareRate?: Maybe<Scalars['Float']>;
};

export type YoutubeCmsEstimateChannel = {
  readonly avatar?: Maybe<Scalars['String']>;
  readonly channelId: Scalars['String'];
  readonly channelName?: Maybe<Scalars['String']>;
  readonly currency: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly lastUpdatedDate?: Maybe<Scalars['Date']>;
  readonly partnerRevenue?: Maybe<Scalars['Float']>;
  readonly profit?: Maybe<Scalars['Float']>;
  readonly rpm?: Maybe<Scalars['Float']>;
  readonly shareCreator?: Maybe<Scalars['Float']>;
  readonly shareRate?: Maybe<Scalars['Float']>;
  readonly taProfit?: Maybe<Scalars['Float']>;
  readonly taRevenue?: Maybe<Scalars['Float']>;
  readonly taRpm?: Maybe<Scalars['Float']>;
  readonly taShareRate?: Maybe<Scalars['Float']>;
};

export type YoutubeCmsEstimateChannelSummary = {
  readonly currency: Scalars['String'];
  readonly partnerRevenue?: Maybe<Scalars['Float']>;
  readonly profit?: Maybe<Scalars['Float']>;
  readonly rpm?: Maybe<Scalars['Float']>;
  readonly shareCreator: Scalars['Float'];
  readonly taProfit?: Maybe<Scalars['Float']>;
  readonly taRevenue?: Maybe<Scalars['Float']>;
  readonly taRpm?: Maybe<Scalars['Float']>;
};

export type YoutubeCmsInvoice = {
  readonly approvedDate?: Maybe<Scalars['Date']>;
  readonly createdDate: Scalars['Date'];
  readonly currency: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly influencerName: Scalars['String'];
  /** Invoice name */
  readonly name: Scalars['String'];
  readonly paymentTotal: Scalars['Float'];
};

export type YoutubeCmsInvoiceDownloadUrl = {
  readonly url: Scalars['String'];
};

export type YoutubeCmsInvoiceForInfluencer = {
  readonly id: Scalars['Int'];
  readonly month: Scalars['Date'];
  readonly updatedDate: Scalars['Date'];
};

export type YoutubeCmsMusicForInfluencer = {
  readonly currency: Scalars['String'];
  readonly id: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly revenue: Scalars['Float'];
  readonly rpm?: Maybe<Scalars['Float']>;
  readonly shareRate: Scalars['Float'];
};

export type YoutubeCmsStartMonth = {
  readonly month?: Maybe<Scalars['Date']>;
};

export type YoutubeCmsVideo = {
  readonly currency: Scalars['String'];
  readonly id: Scalars['String'];
  readonly revenue: Scalars['Float'];
  readonly rpm?: Maybe<Scalars['Float']>;
  readonly shareRate: Scalars['Float'];
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type YoutubeCompareAccountOverview = {
  readonly accountId: Scalars['Int'];
  readonly graph: YoutubeCompareOverviewHistory;
  readonly summary: YoutubeCompareOverviewSummary;
};

export type YoutubeCompareAccountPost = {
  readonly comments: Scalars['Int'];
  readonly duration: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type YoutubeCompareAccountPosts = {
  readonly accountId: Scalars['Int'];
  readonly postHabit: ReadonlyArray<YoutubeAnalyticsPostsPostingHabit>;
  readonly posts: ReadonlyArray<YoutubeCompareAccountPost>;
};

export type YoutubeCompareAccountPostsInDate = {
  readonly accountId: Scalars['Int'];
  readonly posts: ReadonlyArray<YoutubeComparePost>;
};

export type YoutubeCompareAccountTagRanking = {
  readonly accountId: Scalars['Int'];
  readonly tagsRanking: ReadonlyArray<YoutubeTagRanking>;
};

export type YoutubeCompareAllPostsInDate = {
  readonly compareAccounts: ReadonlyArray<YoutubeCompareAccountPostsInDate>;
  readonly mainAccount: YoutubeAnalyticsAccountPostsInDate;
};

export type YoutubeCompareOverview = {
  readonly compareAccounts: ReadonlyArray<YoutubeCompareAccountOverview>;
  readonly mainAccount: YoutubeCompareAccountOverview;
};

export type YoutubeCompareOverviewHistory = {
  readonly comments: ReadonlyArray<YoutubeAnalyticsHistoryDataPoint>;
  readonly dislikes: ReadonlyArray<YoutubeAnalyticsHistoryDataPoint>;
  readonly likes: ReadonlyArray<YoutubeAnalyticsHistoryDataPoint>;
  readonly posts: ReadonlyArray<YoutubeAnalyticsHistoryDataPoint>;
  readonly subscribers: ReadonlyArray<YoutubeAnalyticsHistoryDataPoint>;
  readonly views: ReadonlyArray<YoutubeAnalyticsHistoryDataPoint>;
};

export type YoutubeCompareOverviewSummary = {
  readonly averageComments: YoutubeCompareOverviewSummaryData;
  readonly averageLikes: YoutubeCompareOverviewSummaryData;
  readonly averageViews: YoutubeCompareOverviewSummaryData;
  readonly maximumLikes: YoutubeCompareOverviewSummaryData;
  readonly maximumViews: YoutubeCompareOverviewSummaryData;
  readonly totalPosts: YoutubeCompareOverviewSummaryData;
  readonly totalSubscribers: YoutubeCompareOverviewSummaryData;
};

export type YoutubeCompareOverviewSummaryData = {
  readonly growth: Scalars['Int'];
  readonly percentage: Scalars['Float'];
  readonly total: Scalars['Int'];
};

export type YoutubeComparePost = {
  readonly comments: Scalars['Int'];
  readonly dislikes: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postDate: Scalars['Date'];
  readonly subscribers: Scalars['Int'];
  readonly videoCaption: Scalars['String'];
  readonly videoComments: ReadonlyArray<YoutubeAnalyticsComparePostComment>;
  readonly videoDuration: Scalars['Int'];
  readonly videoEmbed: Scalars['String'];
  readonly videoId: Scalars['String'];
  readonly videoTags: ReadonlyArray<Scalars['String']>;
  readonly videoThumbnail?: Maybe<Scalars['String']>;
  readonly videoTitle: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type YoutubeComparePostList = {
  readonly accountId: Scalars['Int'];
  readonly posts: ReadonlyArray<YoutubeComparePostListItem>;
};

export type YoutubeComparePostListItem = {
  readonly comments: Scalars['Int'];
  readonly dislikes: Scalars['Int'];
  readonly duration: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly likes: Scalars['Int'];
  readonly postDate: Scalars['Date'];
  readonly subscribers: Scalars['Int'];
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly url: Scalars['String'];
  readonly views: Scalars['Int'];
};

export type YoutubeComparePosts = {
  readonly compareAccounts: ReadonlyArray<YoutubeCompareAccountPosts>;
  readonly mainAccount: YoutubeAnalyticsAccountPosts;
};

export type YoutubeCompareTagRanking = {
  readonly compareAccounts: ReadonlyArray<YoutubeCompareAccountTagRanking>;
  readonly mainAccount: YoutubeAnalyticsAccountTagRanking;
};

export type YoutubeInfluencerSearchResultV3 = {
  readonly totalNumber: Scalars['Int'];
  readonly youtubeAccounts: ReadonlyArray<YoutubeInfluencerV3>;
};

export type YoutubeInfluencerV3 = {
  readonly age?: Maybe<Scalars['Int']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly averageLikes?: Maybe<Scalars['Float']>;
  readonly averageViews?: Maybe<Scalars['Float']>;
  readonly country: CountryName;
  readonly gender: Genders;
  readonly hasSimilarAccounts: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isBrandAccount: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly popularPosts?: Maybe<ReadonlyArray<SocialAccountPopularPost>>;
  readonly socialAccountEngagement?: Maybe<Scalars['Int']>;
  readonly socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']>;
  readonly socialAccountEngagementViewsRate?: Maybe<Scalars['Float']>;
  readonly socialAccountFollowers?: Maybe<Scalars['Int']>;
  readonly socialAccountId: Scalars['ID'];
  readonly socialAccountStatus: SocialAccountStatus;
};

export type YoutubeMCNAdminRedirectUrlInput = {
  readonly callbackUrl: Scalars['String'];
};

export type YoutubeMCNAdminRedirectUrlPayload = {
  readonly redirectUri: Scalars['String'];
};

export type YoutubeMusicCategory = {
  readonly category: Scalars['String'];
  readonly id: Scalars['Int'];
};

export type YoutubeMusicItem = {
  readonly downloadUrl: Scalars['String'];
  /** Duration in seconds */
  readonly duration?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly thumbnailUrl?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type YoutubeStatisticsData = {
  readonly growth: Scalars['Float'];
  readonly value: Scalars['Float'];
};

export type YoutubeTagRanking = {
  readonly rank: Scalars['Int'];
  readonly tag: Scalars['String'];
  readonly views: Scalars['Int'];
};

/** An enumeration. */
export enum YoutubeTrafficSource {
  ADVERTISING = 'ADVERTISING',
  ANNOTATION = 'ANNOTATION',
  CAMPAIGN_CARD = 'CAMPAIGN_CARD',
  END_SCREEN = 'END_SCREEN',
  EXT_URL = 'EXT_URL',
  HASHTAGS = 'HASHTAGS',
  LIVE_REDIRECT = 'LIVE_REDIRECT',
  NOTIFICATION = 'NOTIFICATION',
  NO_LINK_EMBEDDED = 'NO_LINK_EMBEDDED',
  NO_LINK_OTHER = 'NO_LINK_OTHER',
  PLAYLIST = 'PLAYLIST',
  PRODUCT_PAGE = 'PRODUCT_PAGE',
  PROMOTED = 'PROMOTED',
  RELATED_VIDEO = 'RELATED_VIDEO',
  SHORTS = 'SHORTS',
  SHORTS_CONTENT_LINKS = 'SHORTS_CONTENT_LINKS',
  SOUND_PAGE = 'SOUND_PAGE',
  SUBSCRIBER = 'SUBSCRIBER',
  VIDEO_REMIXES = 'VIDEO_REMIXES',
  YT_CHANNEL = 'YT_CHANNEL',
  YT_OTHER_PAGE = 'YT_OTHER_PAGE',
  YT_PLAYLIST_PAGE = 'YT_PLAYLIST_PAGE',
  YT_SEARCH = 'YT_SEARCH'
}

export type _Service = {
  readonly sdl: Scalars['String'];
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AIModelName: AIModelName;
  AcceptedPayload: ResolverTypeWrapper<AcceptedPayload>;
  AccountAnalyticsAccountManager: ResolverTypeWrapper<AccountAnalyticsAccountManager>;
  AccountAnalyticsAdvertiser: ResolverTypeWrapper<AccountAnalyticsAdvertiser>;
  AccountAnalyticsAdvertiserDetails: ResolverTypeWrapper<AccountAnalyticsAdvertiserDetails>;
  AccountAnalyticsDetail: ResolverTypeWrapper<AccountAnalyticsDetail>;
  AccountAnalyticsFacebookPageAccount: ResolverTypeWrapper<AccountAnalyticsFacebookPageAccount>;
  AccountAnalyticsForAdvertiserPayload: ResolverTypeWrapper<AccountAnalyticsForAdvertiserPayload>;
  AccountAnalyticsInstagramAccount: ResolverTypeWrapper<AccountAnalyticsInstagramAccount>;
  AccountAnalyticsPayload: ResolverTypeWrapper<AccountAnalyticsPayload>;
  AccountAnalyticsSocialMediaAccount: ResolverTypeWrapper<AccountAnalyticsSocialMediaAccount>;
  AccountAnalyticsTwitterAccount: ResolverTypeWrapper<AccountAnalyticsTwitterAccount>;
  AccountAnalyticsYouTubeAccount: ResolverTypeWrapper<AccountAnalyticsYouTubeAccount>;
  AccountAudienceDataRate: ResolverTypeWrapper<AccountAudienceDataRate>;
  ActionLabelInput: ActionLabelInput;
  ActionLabelPayload: ResolverTypeWrapper<ActionLabelPayload>;
  ActivityChange: ResolverTypeWrapper<ActivityChange>;
  AddAllSelectedInfluencers: ResolverTypeWrapper<AddAllSelectedInfluencers>;
  AddAllSelectedInfluencersInput: AddAllSelectedInfluencersInput;
  AddCommentInput: AddCommentInput;
  AddCommentPayload: ResolverTypeWrapper<AddCommentPayload>;
  AddCompareInstagramAccountAnalytics: ResolverTypeWrapper<AddCompareInstagramAccountAnalytics>;
  AddEmailTemplateInput: AddEmailTemplateInput;
  AddEmailTemplatePayload: ResolverTypeWrapper<AddEmailTemplatePayload>;
  AddFanInput: AddFanInput;
  AddFanPayload: ResolverTypeWrapper<AddFanPayload>;
  AddFanTagsInput: AddFanTagsInput;
  AddFanTagsPayload: ResolverTypeWrapper<AddFanTagsPayload>;
  AddFormInput: AddFormInput;
  AddFormPayload: ResolverTypeWrapper<AddFormPayload>;
  AddInfluencerToUserProposal: ResolverTypeWrapper<AddInfluencerToUserProposal>;
  AddInfluencerToUserProposalInput: AddInfluencerToUserProposalInput;
  AddInstagramCompareAccountAnalyticsInput: AddInstagramCompareAccountAnalyticsInput;
  AddInstagramHashtagAnalytics: ResolverTypeWrapper<AddInstagramHashtagAnalytics>;
  AddInstagramHashtagAnalyticsInput: AddInstagramHashtagAnalyticsInput;
  AddMarketplacePostUrl: ResolverTypeWrapper<AddMarketplacePostUrl>;
  AddMarketplacePostUrlForInfluencer: ResolverTypeWrapper<AddMarketplacePostUrlForInfluencer>;
  AddMarketplacePostUrlInput: AddMarketplacePostUrlInput;
  AddMarketplacePostUrlInputForInfluencer: AddMarketplacePostUrlInputForInfluencer;
  AddReadTutorial: ResolverTypeWrapper<AddReadTutorial>;
  AddReadTutorialInput: AddReadTutorialInput;
  AddSelectedInfluencers: ResolverTypeWrapper<AddSelectedInfluencers>;
  AddSelectedInfluencersInput: AddSelectedInfluencersInput;
  AddSocialAccountToUserProposal: ResolverTypeWrapper<AddSocialAccountToUserProposal>;
  AddSocialAccountToUserProposalInput: AddSocialAccountToUserProposalInput;
  AddStaffAdvertisersToAnotherStaff: ResolverTypeWrapper<AddStaffAdvertisersToAnotherStaff>;
  AddStaffAdvertisersToAnotherStaffInput: AddStaffAdvertisersToAnotherStaffInput;
  AddYoutubeCompareAccountAnalyticsInput: AddYoutubeCompareAccountAnalyticsInput;
  AddYoutubeCompareAccountAnalyticsOutput: ResolverTypeWrapper<AddYoutubeCompareAccountAnalyticsOutput>;
  Address: ResolverTypeWrapper<Address>;
  AddressDetails: ResolverTypeWrapper<AddressDetails>;
  AddressForInfluencer: ResolverTypeWrapper<AddressForInfluencer>;
  AddressInput: AddressInput;
  Advertiser: ResolverTypeWrapper<Advertiser>;
  AdvertiserForAdvertiserUser: ResolverTypeWrapper<AdvertiserForAdvertiserUser>;
  AdvertiserForAdvertiserUserSettings: ResolverTypeWrapper<AdvertiserForAdvertiserUserSettings>;
  AdvertiserForList: ResolverTypeWrapper<AdvertiserForList>;
  AdvertiserForSearch: ResolverTypeWrapper<AdvertiserForSearch>;
  AdvertiserName: ResolverTypeWrapper<AdvertiserName>;
  AdvertiserType: AdvertiserType;
  AdvertiserUser: ResolverTypeWrapper<AdvertiserUser>;
  AdvertiserUserInput: AdvertiserUserInput;
  AdvertiserUserSettingsDetails: ResolverTypeWrapper<AdvertiserUserSettingsDetails>;
  AdvertiserUserWithRole: ResolverTypeWrapper<AdvertiserUserWithRole>;
  AffiliateCampaignChartReport: ResolverTypeWrapper<AffiliateCampaignChartReport>;
  AffiliateReportShowBy: AffiliateReportShowBy;
  AgenciesForSearch: ResolverTypeWrapper<AgenciesForSearch>;
  AgencyLogo: ResolverTypeWrapper<AgencyLogo>;
  AgencyUser: ResolverTypeWrapper<AgencyUser>;
  AgencyUserWithRole: ResolverTypeWrapper<AgencyUserWithRole>;
  AgencyWithAdvertisers: ResolverTypeWrapper<AgencyWithAdvertisers>;
  AgencyWithNSVerification: ResolverTypeWrapper<AgencyWithNSVerification>;
  AiChatModelName: ResolverTypeWrapper<AiChatModelName>;
  AllAccountAnalyticsForAdminPayload: ResolverTypeWrapper<AllAccountAnalyticsForAdminPayload>;
  AllAccountAnalyticsForAdvertiserPayload: ResolverTypeWrapper<AllAccountAnalyticsForAdvertiserPayload>;
  AllAdvertisersForSearch: ResolverTypeWrapper<AllAdvertisersForSearch>;
  AllCampaignStatus: AllCampaignStatus;
  AllCampaignsReportSummary: ResolverTypeWrapper<AllCampaignsReportSummary>;
  AllCampaignsSearchJobsOrderBy: AllCampaignsSearchJobsOrderBy;
  AllInfluencerV4: ResolverTypeWrapper<AllInfluencerV4>;
  AllStaffDetail: ResolverTypeWrapper<AllStaffDetail>;
  AllTiktokAdReportSummary: ResolverTypeWrapper<AllTiktokAdReportSummary>;
  AllUserDetail: ResolverTypeWrapper<AllUserDetail>;
  AllowEngagementInfluencerProposalList: ResolverTypeWrapper<AllowEngagementInfluencerProposalList>;
  AllowEngagementInfluencerProposalListInput: AllowEngagementInfluencerProposalListInput;
  AllowInfluencersUserProposal: ResolverTypeWrapper<AllowInfluencersUserProposal>;
  AllowInfluencersUserProposalInput: AllowInfluencersUserProposalInput;
  AnalyticSubscriptionAvailableFeature: ResolverTypeWrapper<AnalyticSubscriptionAvailableFeature>;
  AnalyticSubscriptionAvailableFeatures: ResolverTypeWrapper<AnalyticSubscriptionAvailableFeatures>;
  AnalyticSubscriptionCapabilities: ResolverTypeWrapper<AnalyticSubscriptionCapabilities>;
  AnalyticSubscriptionCapability: ResolverTypeWrapper<AnalyticSubscriptionCapability>;
  AnalyticsAdvertiserPlanFilter: AnalyticsAdvertiserPlanFilter;
  AnalyticsAuthSocialAccountType: AnalyticsAuthSocialAccountType;
  AnalyticsRedirectType: AnalyticsRedirectType;
  AnalyticsSelectSubscriptionPlan: ResolverTypeWrapper<AnalyticsSelectSubscriptionPlan>;
  AnalyticsSelectSubscriptionPlanInput: AnalyticsSelectSubscriptionPlanInput;
  AnalyticsSocialAuthRedirectUrlPayload: ResolverTypeWrapper<AnalyticsSocialAuthRedirectUrlPayload>;
  AnalyticsSubscriptionPlan: ResolverTypeWrapper<AnalyticsSubscriptionPlan>;
  AnalyticsSubscriptionPlanDetails: ResolverTypeWrapper<AnalyticsSubscriptionPlanDetails>;
  AnalyticsSubscriptionPlanName: ResolverTypeWrapper<AnalyticsSubscriptionPlanName>;
  AnalyticsSubscriptionPlanType: AnalyticsSubscriptionPlanType;
  AnyCreatorCategory: ResolverTypeWrapper<AnyCreatorCategory>;
  AnyXAccountInfoPayload: ResolverTypeWrapper<AnyXAccountInfoPayload>;
  AnyXAuthSocialAccountType: AnyXAuthSocialAccountType;
  AnyXCheckConnectedSocialAccountsForLinkBioPayload: ResolverTypeWrapper<AnyXCheckConnectedSocialAccountsForLinkBioPayload>;
  AnyXCheckConnectedSocialAccountsPayload: ResolverTypeWrapper<AnyXCheckConnectedSocialAccountsPayload>;
  AnyXCheckEmailInput: AnyXCheckEmailInput;
  AnyXCheckEmailPayload: ResolverTypeWrapper<AnyXCheckEmailPayload>;
  AnyXConnectedChildYoutubeAccount: ResolverTypeWrapper<AnyXConnectedChildYoutubeAccount>;
  AnyXConnectedFacebookAccount: ResolverTypeWrapper<AnyXConnectedFacebookAccount>;
  AnyXConnectedFacebookAccountAndPageForLinkBio: ResolverTypeWrapper<AnyXConnectedFacebookAccountAndPageForLinkBio>;
  AnyXConnectedFacebookPage: ResolverTypeWrapper<AnyXConnectedFacebookPage>;
  AnyXConnectedInstagramAccount: ResolverTypeWrapper<AnyXConnectedInstagramAccount>;
  AnyXConnectedSocialAccount: ResolverTypeWrapper<AnyXConnectedSocialAccount>;
  AnyXConnectedSocialAccountForLinkBio: ResolverTypeWrapper<AnyXConnectedSocialAccountForLinkBio>;
  AnyXConnectedTikTokAccount: ResolverTypeWrapper<AnyXConnectedTikTokAccount>;
  AnyXConnectedYoutubeAccount: ResolverTypeWrapper<AnyXConnectedYoutubeAccount>;
  AnyXEngagementCampaignStatus: AnyXEngagementCampaignStatus;
  AnyXMarketplaceCampaignStatus: AnyXMarketplaceCampaignStatus;
  AnyXSignIn: ResolverTypeWrapper<AnyXSignIn>;
  AnyXSignUp: ResolverTypeWrapper<AnyXSignUp>;
  AnyXSocialAuthConnectForLinkBioInput: AnyXSocialAuthConnectForLinkBioInput;
  AnyXSocialAuthConnectForLinkBioPayload: ResolverTypeWrapper<AnyXSocialAuthConnectForLinkBioPayload>;
  AnyXSocialAuthConnectInput: AnyXSocialAuthConnectInput;
  AnyXSocialAuthConnectPayload: ResolverTypeWrapper<AnyXSocialAuthConnectPayload>;
  AnyXSocialAuthDisconnectInput: AnyXSocialAuthDisconnectInput;
  AnyXSocialAuthDisconnectPayload: ResolverTypeWrapper<AnyXSocialAuthDisconnectPayload>;
  AnyXSocialAuthReconnectForLinkBioInput: AnyXSocialAuthReconnectForLinkBioInput;
  AnyXSocialAuthReconnectForLinkBioPayload: ResolverTypeWrapper<AnyXSocialAuthReconnectForLinkBioPayload>;
  AnyXSocialAuthReconnectTTCMInput: AnyXSocialAuthReconnectTTCMInput;
  AnyXSocialAuthReconnectTTCMPayload: ResolverTypeWrapper<AnyXSocialAuthReconnectTTCMPayload>;
  AnyXSocialAuthReconnectTikTokInput: AnyXSocialAuthReconnectTikTokInput;
  AnyXSocialAuthReconnectTikTokPayload: ResolverTypeWrapper<AnyXSocialAuthReconnectTikTokPayload>;
  AnyXSocialAuthSignInInput: AnyXSocialAuthSignInInput;
  AnyXSocialAuthSignInInstagramLineInput: AnyXSocialAuthSignInInstagramLineInput;
  AnyXSocialAuthSignInInstagramLinePayload: ResolverTypeWrapper<AnyXSocialAuthSignInInstagramLinePayload>;
  AnyXSocialAuthSignInOrSignUpInput: AnyXSocialAuthSignInOrSignUpInput;
  AnyXSocialAuthSignInOrSignUpPayload: ResolverTypeWrapper<AnyXSocialAuthSignInOrSignUpPayload>;
  AnyXSocialAuthSignInPayload: ResolverTypeWrapper<AnyXSocialAuthSignInPayload>;
  AnyXSocialAuthSignUpInput: AnyXSocialAuthSignUpInput;
  AnyXSocialAuthSignUpPartnerInput: AnyXSocialAuthSignUpPartnerInput;
  AnyXSocialAuthSignUpPartnerPayload: ResolverTypeWrapper<AnyXSocialAuthSignUpPartnerPayload>;
  AnyXSocialAuthSignUpPayload: ResolverTypeWrapper<AnyXSocialAuthSignUpPayload>;
  AnyXSocialAuthSignUpTalentInput: AnyXSocialAuthSignUpTalentInput;
  AnyXSocialAuthSignUpTalentPayload: ResolverTypeWrapper<AnyXSocialAuthSignUpTalentPayload>;
  AppName: AppName;
  AppType: AppType;
  ApplicantInfluencer: ResolverTypeWrapper<ApplicantInfluencer>;
  ApproveApplicantInfluencers: ResolverTypeWrapper<ApproveApplicantInfluencers>;
  ApproveApplicantInfluencersInput: ApproveApplicantInfluencersInput;
  ApproveAutoManagedPayout: ResolverTypeWrapper<ApproveAutoManagedPayout>;
  ApproveAutoManagedPayoutInput: ApproveAutoManagedPayoutInput;
  ApproveCmsInvoice: ResolverTypeWrapper<ApproveCmsInvoice>;
  ApproveCmsInvoiceInput: ApproveCmsInvoiceInput;
  ApproveMarketplace: ResolverTypeWrapper<ApproveMarketplace>;
  ApproveMarketplaceAffiliatePayout: ResolverTypeWrapper<ApproveMarketplaceAffiliatePayout>;
  ApproveMarketplaceAffiliatePayoutInput: ApproveMarketplaceAffiliatePayoutInput;
  ApproveMarketplaceDraftPost: ResolverTypeWrapper<ApproveMarketplaceDraftPost>;
  ApproveMarketplaceDraftPostInput: ApproveMarketplaceDraftPostInput;
  ApproveMarketplaceInput: ApproveMarketplaceInput;
  ApproveTikTokSpecialCampaign: ResolverTypeWrapper<ApproveTikTokSpecialCampaign>;
  ApproveTikTokSpecialCampaignInput: ApproveTikTokSpecialCampaignInput;
  AssignAdvertiserForStaffs: ResolverTypeWrapper<AssignAdvertiserForStaffs>;
  AssignAdvertiserForStaffsInput: AssignAdvertiserForStaffsInput;
  AssignChatPICInput: AssignChatPICInput;
  AssignChatPICPayload: ResolverTypeWrapper<AssignChatPICPayload>;
  AssociateCompany: ResolverTypeWrapper<AssociateCompany>;
  AssociateCompanyType: AssociateCompanyType;
  AudienceBreakdownAgeGenderRates: ResolverTypeWrapper<AudienceBreakdownAgeGenderRates>;
  AudienceBreakdownAgeGroup: ResolverTypeWrapper<AudienceBreakdownAgeGroup>;
  AudienceBreakdownAgeRates: ResolverTypeWrapper<AudienceBreakdownAgeRates>;
  AudienceBreakdownAreaRate: ResolverTypeWrapper<AudienceBreakdownAreaRate>;
  AudienceBreakdownGenderRates: ResolverTypeWrapper<AudienceBreakdownGenderRates>;
  Auth0ToLocalJWT: ResolverTypeWrapper<Auth0ToLocalJWT>;
  Auth0ToLocalJWTInput: Auth0ToLocalJWTInput;
  AuthSocialAccountType: AuthSocialAccountType;
  AuthTiktokBusinessAccount: ResolverTypeWrapper<AuthTiktokBusinessAccount>;
  AuthTiktokBusinessAccountInput: AuthTiktokBusinessAccountInput;
  AutoManagedAffiliateCampaignStatus: AutoManagedAffiliateCampaignStatus;
  AutoManagedAffiliateCommissionRateInput: AutoManagedAffiliateCommissionRateInput;
  AutoManagedCampaignCountry: ResolverTypeWrapper<AutoManagedCampaignCountry>;
  AutoManagedCampaignForInfluencerSearchJobPayload: ResolverTypeWrapper<AutoManagedCampaignForInfluencerSearchJobPayload>;
  AutoManagedCampaignForInfluencerYourJobPayload: ResolverTypeWrapper<AutoManagedCampaignForInfluencerYourJobPayload>;
  AutoManagedCampaignInfoPayload: ResolverTypeWrapper<AutoManagedCampaignInfoPayload>;
  AutoManagedCampaignMoney: ResolverTypeWrapper<AutoManagedCampaignMoney>;
  AutoManagedCampaignPayload: ResolverTypeWrapper<AutoManagedCampaignPayload>;
  AutoManagedCampaignStatus: AutoManagedCampaignStatus;
  AutoManagedCampaignType: AutoManagedCampaignType;
  AutoManagedCommissionInfluencer: ResolverTypeWrapper<AutoManagedCommissionInfluencer>;
  AutoManagedCommissionStatus: AutoManagedCommissionStatus;
  AutoManagedInfluencerInfo: ResolverTypeWrapper<AutoManagedInfluencerInfo>;
  AutoManagedJoinedInfluencer: ResolverTypeWrapper<AutoManagedJoinedInfluencer>;
  AutoManagedJoinedMethodsInput: AutoManagedJoinedMethodsInput;
  AutoManagedJoinedMethodsPayload: ResolverTypeWrapper<AutoManagedJoinedMethodsPayload>;
  AutoManagedJoinedMethodsPayloadV2: ResolverTypeWrapper<AutoManagedJoinedMethodsPayloadV2>;
  AutoManagedPayoutPeriodInput: AutoManagedPayoutPeriodInput;
  AutoManagedReportByDate: ResolverTypeWrapper<AutoManagedReportByDate>;
  AutoManagedReportByInfluencer: ResolverTypeWrapper<AutoManagedReportByInfluencer>;
  AutoManagedReportForInfluencer: ResolverTypeWrapper<AutoManagedReportForInfluencer>;
  AutoManagedReportSummary: ResolverTypeWrapper<AutoManagedReportSummary>;
  AutoManagedReportSummaryForInfluencer: ResolverTypeWrapper<AutoManagedReportSummaryForInfluencer>;
  AvailableInfluencer: ResolverTypeWrapper<AvailableInfluencer>;
  AvailableInfluencersForStaffPayload: ResolverTypeWrapper<AvailableInfluencersForStaffPayload>;
  Bank: ResolverTypeWrapper<Bank>;
  BankBranch: ResolverTypeWrapper<BankBranch>;
  BankBranchForInfluencer: ResolverTypeWrapper<BankBranchForInfluencer>;
  BankForInfluencer: ResolverTypeWrapper<BankForInfluencer>;
  BasicAccountAnalyticsPayload: ResolverTypeWrapper<BasicAccountAnalyticsPayload>;
  BcaRequestStatus: BcaRequestStatus;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  BillingInformation: ResolverTypeWrapper<BillingInformation>;
  BioAnalyticsCity: ResolverTypeWrapper<BioAnalyticsCity>;
  BioAnalyticsCountry: ResolverTypeWrapper<BioAnalyticsCountry>;
  BioAnalyticsOtherLinkClicks: ResolverTypeWrapper<BioAnalyticsOtherLinkClicks>;
  BioAnalyticsReferral: ResolverTypeWrapper<BioAnalyticsReferral>;
  BioAnalyticsSocialMediaClicks: ResolverTypeWrapper<BioAnalyticsSocialMediaClicks>;
  BioAnalyticsTotalClicks: ResolverTypeWrapper<BioAnalyticsTotalClicks>;
  BioButtonStyle: BioButtonStyle;
  BioFontStyle: BioFontStyle;
  BioImage: ResolverTypeWrapper<BioImage>;
  BioImageInput: BioImageInput;
  BioInfluencerProfile: ResolverTypeWrapper<BioInfluencerProfile>;
  BioLinkIcon: BioLinkIcon;
  BioLinkInput: BioLinkInput;
  BioProfileInput: BioProfileInput;
  BioSection: ResolverTypeWrapper<BioSection>;
  BioSectionType: BioSectionType;
  BioSectionV2: ResolverTypeWrapper<BioSectionV2>;
  BioSectionsInput: BioSectionsInput;
  BioSectionsInputV2: BioSectionsInputV2;
  BioSocialMedia: ResolverTypeWrapper<BioSocialMedia>;
  BioSocialMediaInput: BioSocialMediaInput;
  BioSocialMediaType: BioSocialMediaType;
  BioText: ResolverTypeWrapper<BioText>;
  BioTextInput: BioTextInput;
  BioTheme: BioTheme;
  BioThemeV2: BioThemeV2;
  BioUrlLink: ResolverTypeWrapper<BioUrlLink>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BoostTikTokAdPostInput: BoostTikTokAdPostInput;
  BoostTiktokAdPost: ResolverTypeWrapper<BoostTiktokAdPost>;
  BotTextMessageEvent: ResolverTypeWrapper<BotTextMessageEvent>;
  BrandAccount: ResolverTypeWrapper<BrandAccount>;
  BulkEngagementProposalSocialAccount: BulkEngagementProposalSocialAccount;
  BulkPackageSocialAccount: BulkPackageSocialAccount;
  BulkUpdateEngagementInfluencersBudget: ResolverTypeWrapper<BulkUpdateEngagementInfluencersBudget>;
  BulkUpdateEngagementInfluencersBudgetInput: BulkUpdateEngagementInfluencersBudgetInput;
  BulkUpdateEngagementSocialAccountsBudget: ResolverTypeWrapper<BulkUpdateEngagementSocialAccountsBudget>;
  BulkUpdateEngagementSocialAccountsBudgetInput: BulkUpdateEngagementSocialAccountsBudgetInput;
  BulkUpdatePackageProposalAccountsBudget: ResolverTypeWrapper<BulkUpdatePackageProposalAccountsBudget>;
  BulkUpdatePackageProposalAccountsBudgetInput: BulkUpdatePackageProposalAccountsBudgetInput;
  BulkUpdatePackageProposalInfluencersBudget: ResolverTypeWrapper<BulkUpdatePackageProposalInfluencersBudget>;
  BulkUpdatePackageProposalInfluencersBudgetInput: BulkUpdatePackageProposalInfluencersBudgetInput;
  BulkUpdateUserProposalAccountsBudget: ResolverTypeWrapper<BulkUpdateUserProposalAccountsBudget>;
  BulkUpdateUserProposalAccountsBudgetInput: BulkUpdateUserProposalAccountsBudgetInput;
  BulkUpdateUserProposalInfluencersBudget: ResolverTypeWrapper<BulkUpdateUserProposalInfluencersBudget>;
  BulkUpdateUserProposalInfluencersBudgetInput: BulkUpdateUserProposalInfluencersBudgetInput;
  BulkUserProposalSocialAccount: BulkUserProposalSocialAccount;
  ButtonMessageInput: ButtonMessageInput;
  ButtonMessagePayload: ResolverTypeWrapper<ButtonMessagePayload>;
  CRMChatTypeFilter: CRMChatTypeFilter;
  CampaignCategoryName: ResolverTypeWrapper<CampaignCategoryName>;
  CampaignChartReport: ResolverTypeWrapper<CampaignChartReport>;
  CampaignChartReportForAllTime: ResolverTypeWrapper<CampaignChartReportForAllTime>;
  CampaignCreator: ResolverTypeWrapper<CampaignCreator>;
  CampaignCreatorWithName: ResolverTypeWrapper<CampaignCreatorWithName>;
  CampaignDailyStats: ResolverTypeWrapper<CampaignDailyStats>;
  CampaignDetailReport: ResolverTypeWrapper<CampaignDetailReport>;
  CampaignFilter: CampaignFilter;
  CampaignForInfluencerForYourJob: ResolverTypeWrapper<CampaignForInfluencerForYourJob>;
  CampaignForInfluencerForYourJobCounts: ResolverTypeWrapper<CampaignForInfluencerForYourJobCounts>;
  CampaignForPostFilter: ResolverTypeWrapper<CampaignForPostFilter>;
  CampaignForSearch: ResolverTypeWrapper<CampaignForSearch>;
  CampaignGridReport: ResolverTypeWrapper<CampaignGridReport>;
  CampaignIdentifierInput: CampaignIdentifierInput;
  CampaignIdentifierType: CampaignIdentifierType;
  CampaignInfluencerInfo: ResolverTypeWrapper<CampaignInfluencerInfo>;
  CampaignInfluencerReport: ResolverTypeWrapper<CampaignInfluencerReport>;
  CampaignPayment: ResolverTypeWrapper<CampaignPayment>;
  CampaignPost: ResolverTypeWrapper<CampaignPost>;
  CampaignPostGridInfo: ResolverTypeWrapper<CampaignPostGridInfo>;
  CampaignPostInfo: ResolverTypeWrapper<CampaignPostInfo>;
  CampaignPostMetrics: ResolverTypeWrapper<CampaignPostMetrics>;
  CampaignPostOrderBy: CampaignPostOrderBy;
  CampaignPostReportGrid: ResolverTypeWrapper<CampaignPostReportGrid>;
  CampaignPostSocialType: CampaignPostSocialType;
  CampaignPostSortField: CampaignPostSortField;
  CampaignPostStatusForInfluencer: CampaignPostStatusForInfluencer;
  CampaignPosts: ResolverTypeWrapper<CampaignPosts>;
  CampaignPostsSummary: ResolverTypeWrapper<CampaignPostsSummary>;
  CampaignPromotionMethod: CampaignPromotionMethod;
  CampaignReportCampaignInfo: ResolverTypeWrapper<CampaignReportCampaignInfo>;
  CampaignReportCampaignInfoForInfluencer: ResolverTypeWrapper<CampaignReportCampaignInfoForInfluencer>;
  CampaignReportOrderBy: CampaignReportOrderBy;
  CampaignReportOrderField: CampaignReportOrderField;
  CampaignReportSummary: ResolverTypeWrapper<CampaignReportSummary>;
  CampaignSocialMediaType: CampaignSocialMediaType;
  CampaignStatusForInfluencer: CampaignStatusForInfluencer;
  CampaignStatusForReport: CampaignStatusForReport;
  CampaignSummaryDaily: ResolverTypeWrapper<CampaignSummaryDaily>;
  CampaignTrackingOption: CampaignTrackingOption;
  CampaignType: CampaignType;
  CampaignsForSearchJobPayload: ResolverTypeWrapper<CampaignsForSearchJobPayload>;
  CancelSubscriptionPlan: ResolverTypeWrapper<CancelSubscriptionPlan>;
  CardInput: CardInput;
  CardPayload: ResolverTypeWrapper<CardPayload>;
  Category: ResolverTypeWrapper<Category>;
  CategoryName: ResolverTypeWrapper<CategoryName>;
  ChangeInstagramUGCManagementPostStatusInput: ChangeInstagramUGCManagementPostStatusInput;
  ChangeInstagramUGCManagementPostStatusOutput: ResolverTypeWrapper<ChangeInstagramUGCManagementPostStatusOutput>;
  ChangeType: ChangeType;
  ChatEvent:
    | ResolversTypes['BotTextMessageEvent']
    | ResolversTypes['InquiryEvent']
    | ResolversTypes['InquiryFinishEvent']
    | ResolversTypes['MessageTemplateEvent']
    | ResolversTypes['StateUpdateEvent']
    | ResolversTypes['UserAudioMessageEvent']
    | ResolversTypes['UserImageMessageEvent']
    | ResolversTypes['UserPostbackEvent']
    | ResolversTypes['UserTextMessageEvent']
    | ResolversTypes['UserVideoMessageEvent'];
  CheckBioLinkNameAvailabilityInput: CheckBioLinkNameAvailabilityInput;
  CheckBioLinkNameAvailabilityPayload: ResolverTypeWrapper<CheckBioLinkNameAvailabilityPayload>;
  City: ResolverTypeWrapper<City>;
  CityForInfluencer: ResolverTypeWrapper<CityForInfluencer>;
  ClearCmsChannelAssetOwner: ResolverTypeWrapper<ClearCmsChannelAssetOwner>;
  ClearCmsChannelAssetOwnerInput: ClearCmsChannelAssetOwnerInput;
  ClickNotificationByEventIdInput: ClickNotificationByEventIdInput;
  ClickNotificationByEventIdPayload: ResolverTypeWrapper<ClickNotificationByEventIdPayload>;
  ClickNotificationInput: ClickNotificationInput;
  ClickNotificationPayload: ResolverTypeWrapper<ClickNotificationPayload>;
  CloneMarketplace: ResolverTypeWrapper<CloneMarketplace>;
  CloneMarketplaceInput: CloneMarketplaceInput;
  CmsAssetShareRateInput: CmsAssetShareRateInput;
  CmsChannelAssetInput: CmsChannelAssetInput;
  CmsChannelShareRateInput: CmsChannelShareRateInput;
  CommentContent: ResolverTypeWrapper<CommentContent>;
  CommissionStatus: CommissionStatus;
  CompanyAndRolePair: CompanyAndRolePair;
  ConnectFacebookPagesAndInstagramAccountsInput: ConnectFacebookPagesAndInstagramAccountsInput;
  ConnectFacebookPagesAndInstagramAccountsPayload: ResolverTypeWrapper<ConnectFacebookPagesAndInstagramAccountsPayload>;
  ConnectNewShopifyAccountPayload: ResolverTypeWrapper<ConnectNewShopifyAccountPayload>;
  ConnectPackageToEngagementProposal: ResolverTypeWrapper<ConnectPackageToEngagementProposal>;
  ConnectPackageToEngagementProposalInput: ConnectPackageToEngagementProposalInput;
  ConnectPackageWithInfluencerAccounts: ResolverTypeWrapper<ConnectPackageWithInfluencerAccounts>;
  ConnectPackageWithInfluencerAccountsInput: ConnectPackageWithInfluencerAccountsInput;
  ConnectPackageWithSocialAccounts: ResolverTypeWrapper<ConnectPackageWithSocialAccounts>;
  ConnectPackageWithSocialAccountsInput: ConnectPackageWithSocialAccountsInput;
  ConnectPackageWithUserProposal: ResolverTypeWrapper<ConnectPackageWithUserProposal>;
  ConnectPackageWithUserProposalInput: ConnectPackageWithUserProposalInput;
  ConnectPromotionMethodsInput: ConnectPromotionMethodsInput;
  ConnectPromotionMethodsPayload: ResolverTypeWrapper<ConnectPromotionMethodsPayload>;
  ConnectableFacebookPage: ResolverTypeWrapper<ConnectableFacebookPage>;
  ConnectableFacebookPageAccount: ResolverTypeWrapper<ConnectableFacebookPageAccount>;
  ConnectableFacebookPageAndInstagramAccount: ResolverTypeWrapper<ConnectableFacebookPageAndInstagramAccount>;
  ConnectableFacebookPageMainAccount: ResolverTypeWrapper<ConnectableFacebookPageMainAccount>;
  ConnectableFacebookPagesAndInstagramAccountsPayload: ResolverTypeWrapper<ConnectableFacebookPagesAndInstagramAccountsPayload>;
  ConnectableFacebookPagesPayload: ResolverTypeWrapper<ConnectableFacebookPagesPayload>;
  ConnectableInstagramAccount: ResolverTypeWrapper<ConnectableInstagramAccount>;
  ConnectableInstagramAccountWithNoConnectedFlag: ResolverTypeWrapper<ConnectableInstagramAccountWithNoConnectedFlag>;
  ConnectableInstagramAccountsPayload: ResolverTypeWrapper<ConnectableInstagramAccountsPayload>;
  ConnectableOauthAccount: ResolverTypeWrapper<ConnectableOauthAccount>;
  ConnectableOauthAccountForTalentSignIn: ResolverTypeWrapper<ConnectableOauthAccountForTalentSignIn>;
  ConnectableOauthFacebookAccountForTalentSignIn: ResolverTypeWrapper<ConnectableOauthFacebookAccountForTalentSignIn>;
  ConnectableOauthInstagramAccountForTalentSignIn: ResolverTypeWrapper<ConnectableOauthInstagramAccountForTalentSignIn>;
  ConnectableOauthPageAndIgAccount: ResolverTypeWrapper<ConnectableOauthPageAndIgAccount>;
  ConnectableOauthPageAndIgAccountForSignInFacebook: ResolverTypeWrapper<ConnectableOauthPageAndIgAccountForSignInFacebook>;
  ConnectableOauthPageAndIgAccountForTalentSignIn: ResolverTypeWrapper<ConnectableOauthPageAndIgAccountForTalentSignIn>;
  ConnectableOauthYouTubeAccountForTalentSignIn: ResolverTypeWrapper<ConnectableOauthYouTubeAccountForTalentSignIn>;
  ConnectedAccountForLinkBio: ResolverTypeWrapper<ConnectedAccountForLinkBio>;
  ConnectedFacebook: ResolverTypeWrapper<ConnectedFacebook>;
  ConnectedFacebookPage: ResolverTypeWrapper<ConnectedFacebookPage>;
  ConnectedPage: ResolverTypeWrapper<ConnectedPage>;
  ConnectedPageAndIgAccount: ResolverTypeWrapper<ConnectedPageAndIgAccount>;
  ConnectedTwitter: ResolverTypeWrapper<ConnectedTwitter>;
  ConnectedYouTube: ResolverTypeWrapper<ConnectedYouTube>;
  ConstantHasEstimateMessage: ConstantHasEstimateMessage;
  CountUnreadNotificationsByAppInput: CountUnreadNotificationsByAppInput;
  CountriesPayload: ResolverTypeWrapper<CountriesPayload>;
  Country: ResolverTypeWrapper<Country>;
  CountryAndCurrency: ResolverTypeWrapper<CountryAndCurrency>;
  CountryName: ResolverTypeWrapper<CountryName>;
  CountryNameForInfluencer: ResolverTypeWrapper<CountryNameForInfluencer>;
  CreateAdmin: ResolverTypeWrapper<CreateAdmin>;
  CreateAdminInput: CreateAdminInput;
  CreateAdvertiser: ResolverTypeWrapper<CreateAdvertiser>;
  CreateAdvertiserInput: CreateAdvertiserInput;
  CreateAdvertiserInputV2: CreateAdvertiserInputV2;
  CreateAdvertiserUser: ResolverTypeWrapper<CreateAdvertiserUser>;
  CreateAdvertiserUserInput: CreateAdvertiserUserInput;
  CreateAdvertiserWithUser: ResolverTypeWrapper<CreateAdvertiserWithUser>;
  CreateAdvertiserWithUserInput: CreateAdvertiserWithUserInput;
  CreateAdvertiserWithUserInputV2: CreateAdvertiserWithUserInputV2;
  CreateAdvertiserWithUserV2: ResolverTypeWrapper<CreateAdvertiserWithUserV2>;
  CreateAgency: ResolverTypeWrapper<CreateAgency>;
  CreateAgencyInput: CreateAgencyInput;
  CreateAgencyWithUser: ResolverTypeWrapper<CreateAgencyWithUser>;
  CreateAgencyWithUserInput: CreateAgencyWithUserInput;
  CreateCreatorStaffInput: CreateCreatorStaffInput;
  CreateCreatorStaffPayload: ResolverTypeWrapper<CreateCreatorStaffPayload>;
  CreateDraftBoostPost: ResolverTypeWrapper<CreateDraftBoostPost>;
  CreateDraftBoostPostInput: CreateDraftBoostPostInput;
  CreateEngagement: ResolverTypeWrapper<CreateEngagement>;
  CreateEngagementInput: CreateEngagementInput;
  CreateEngagementPostForInfluencerV2: ResolverTypeWrapper<CreateEngagementPostForInfluencerV2>;
  CreateEngagementPostInputForInfluencerV2: CreateEngagementPostInputForInfluencerV2;
  CreateEngagementPostInputV2: CreateEngagementPostInputV2;
  CreateEngagementPostV2: ResolverTypeWrapper<CreateEngagementPostV2>;
  CreateGeminiThread: ResolverTypeWrapper<CreateGeminiThread>;
  CreateInfluencerBioInput: CreateInfluencerBioInput;
  CreateInfluencerBioPayload: ResolverTypeWrapper<CreateInfluencerBioPayload>;
  CreateInfluencerInputV2: CreateInfluencerInputV2;
  CreateInfluencerStaffInput: CreateInfluencerStaffInput;
  CreateInfluencerStaffPayload: ResolverTypeWrapper<CreateInfluencerStaffPayload>;
  CreateInfluencerV2: ResolverTypeWrapper<CreateInfluencerV2>;
  CreateInstagramUGCManagementHashtagInput: CreateInstagramUGCManagementHashtagInput;
  CreateInstagramUGCManagementHashtagOutput: ResolverTypeWrapper<CreateInstagramUGCManagementHashtagOutput>;
  CreateInstagramUserHashtagInput: CreateInstagramUserHashtagInput;
  CreateInstagramUserHashtagOutput: ResolverTypeWrapper<CreateInstagramUserHashtagOutput>;
  CreateLineBroadcastMessageInput: CreateLineBroadcastMessageInput;
  CreateLineBroadcastMessagePayload: ResolverTypeWrapper<CreateLineBroadcastMessagePayload>;
  CreateLineChannelInput: CreateLineChannelInput;
  CreateLineChannelPayload: ResolverTypeWrapper<CreateLineChannelPayload>;
  CreateMarketplace: ResolverTypeWrapper<CreateMarketplace>;
  CreateMarketplaceDraftPost: ResolverTypeWrapper<CreateMarketplaceDraftPost>;
  CreateMarketplaceDraftPostInput: CreateMarketplaceDraftPostInput;
  CreateMarketplaceInput: CreateMarketplaceInput;
  CreateMobileAppVersion: ResolverTypeWrapper<CreateMobileAppVersion>;
  CreateMobileAppVersionInput: CreateMobileAppVersionInput;
  CreateOpenAIThread: ResolverTypeWrapper<CreateOpenAIThread>;
  CreatePackage: ResolverTypeWrapper<CreatePackage>;
  CreatePackageInput: CreatePackageInput;
  CreatePackageWithInfluencerAccounts: ResolverTypeWrapper<CreatePackageWithInfluencerAccounts>;
  CreatePackageWithInfluencerAccountsInput: CreatePackageWithInfluencerAccountsInput;
  CreatePackageWithSocialAccounts: ResolverTypeWrapper<CreatePackageWithSocialAccounts>;
  CreatePackageWithSocialAccountsInput: CreatePackageWithSocialAccountsInput;
  CreatePackageWithUserProposal: ResolverTypeWrapper<CreatePackageWithUserProposal>;
  CreatePackageWithUserProposalInput: CreatePackageWithUserProposalInput;
  CreatePartnerUser: ResolverTypeWrapper<CreatePartnerUser>;
  CreatePartnerUserInput: CreatePartnerUserInput;
  CreateSelfRegisteredAdvertiserUser: ResolverTypeWrapper<CreateSelfRegisteredAdvertiserUser>;
  CreateSelfRegisteredAdvertiserUserInput: CreateSelfRegisteredAdvertiserUserInput;
  CreateSocialAccountAnalyticsAdminInputV2: CreateSocialAccountAnalyticsAdminInputV2;
  CreateSocialAccountAnalyticsAdminOutputV2: ResolverTypeWrapper<CreateSocialAccountAnalyticsAdminOutputV2>;
  CreateSocialAccountAnalyticsAdvertiserInputV2: CreateSocialAccountAnalyticsAdvertiserInputV2;
  CreateSocialAccountAnalyticsAdvertiserOutputV2: ResolverTypeWrapper<CreateSocialAccountAnalyticsAdvertiserOutputV2>;
  CreateStaff: ResolverTypeWrapper<CreateStaff>;
  CreateStaffInput: CreateStaffInput;
  CreateTalentAgencyUser: ResolverTypeWrapper<CreateTalentAgencyUser>;
  CreateTalentAgencyUserInput: CreateTalentAgencyUserInput;
  CreateTalentAgencyWithUser: ResolverTypeWrapper<CreateTalentAgencyWithUser>;
  CreateTalentAgencyWithUserInput: CreateTalentAgencyWithUserInput;
  CreateTalentInfluencerInput: CreateTalentInfluencerInput;
  CreateTalentInfluencerPayload: ResolverTypeWrapper<CreateTalentInfluencerPayload>;
  CreateTikTokSpecialCampaign: ResolverTypeWrapper<CreateTikTokSpecialCampaign>;
  CreateTikTokSpecialCampaignInput: CreateTikTokSpecialCampaignInput;
  CreateTiktokAdAccount: ResolverTypeWrapper<CreateTiktokAdAccount>;
  CreateTiktokAdAccountInput: CreateTiktokAdAccountInput;
  CreateTiktokBusinessCenter: ResolverTypeWrapper<CreateTiktokBusinessCenter>;
  CreateTiktokBusinessCenterInput: CreateTiktokBusinessCenterInput;
  CreateTiktokUserHashtagInput: CreateTiktokUserHashtagInput;
  CreateTiktokUserHashtagOutput: ResolverTypeWrapper<CreateTiktokUserHashtagOutput>;
  CreateTiktokUserKeywordInput: CreateTiktokUserKeywordInput;
  CreateTiktokUserKeywordOutput: ResolverTypeWrapper<CreateTiktokUserKeywordOutput>;
  CreatorAddressPayload: ResolverTypeWrapper<CreatorAddressPayload>;
  CreatorStaff: ResolverTypeWrapper<CreatorStaff>;
  CreatorStaffsCountPayload: ResolverTypeWrapper<CreatorStaffsCountPayload>;
  CreatorStaffsInput: CreatorStaffsInput;
  CreatorStaffsPayload: ResolverTypeWrapper<CreatorStaffsPayload>;
  CurrentUserCurrency: ResolverTypeWrapper<CurrentUserCurrency>;
  CustomPermissionLevel: ResolverTypeWrapper<CustomPermissionLevel>;
  CustomPermissionLevelInput: CustomPermissionLevelInput;
  CustomPermissions: ResolverTypeWrapper<CustomPermissions>;
  CustomPermissionsInput: CustomPermissionsInput;
  CustomerTagOrder: CustomerTagOrder;
  CustomerTagPayload: ResolverTypeWrapper<CustomerTagPayload>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeleteAdvertiser: ResolverTypeWrapper<DeleteAdvertiser>;
  DeleteAgency: ResolverTypeWrapper<DeleteAgency>;
  DeleteBrandAccountAnalyticsInput: DeleteBrandAccountAnalyticsInput;
  DeleteBrandAccountAnalyticsOutput: ResolverTypeWrapper<DeleteBrandAccountAnalyticsOutput>;
  DeleteCommentInput: DeleteCommentInput;
  DeleteCommentPayload: ResolverTypeWrapper<DeleteCommentPayload>;
  DeleteCompareInstagramAccountAnalytics: ResolverTypeWrapper<DeleteCompareInstagramAccountAnalytics>;
  DeleteEngagement: ResolverTypeWrapper<DeleteEngagement>;
  DeleteEngagementInfluencerProposal: ResolverTypeWrapper<DeleteEngagementInfluencerProposal>;
  DeleteEngagementInfluencerProposalInput: DeleteEngagementInfluencerProposalInput;
  DeleteEngagementPostForInfluencer: ResolverTypeWrapper<DeleteEngagementPostForInfluencer>;
  DeleteEngagementProposalJoinedAccount: ResolverTypeWrapper<DeleteEngagementProposalJoinedAccount>;
  DeleteEngagementProposalJoinedAccountInput: DeleteEngagementProposalJoinedAccountInput;
  DeleteEngagementSocialAccountProposal: ResolverTypeWrapper<DeleteEngagementSocialAccountProposal>;
  DeleteEngagementSocialAccountProposalInput: DeleteEngagementSocialAccountProposalInput;
  DeleteFanTagsInput: DeleteFanTagsInput;
  DeleteFanTagsPayload: ResolverTypeWrapper<DeleteFanTagsPayload>;
  DeleteGeminiThread: ResolverTypeWrapper<DeleteGeminiThread>;
  DeleteGeminiThreadInput: DeleteGeminiThreadInput;
  DeleteInfluencer: ResolverTypeWrapper<DeleteInfluencer>;
  DeleteInfluencerPackage: ResolverTypeWrapper<DeleteInfluencerPackage>;
  DeleteInstagramCompareAccountAnalyticsInput: DeleteInstagramCompareAccountAnalyticsInput;
  DeleteInstagramHashtagAnalytics: ResolverTypeWrapper<DeleteInstagramHashtagAnalytics>;
  DeleteInstagramHashtagAnalyticsInput: DeleteInstagramHashtagAnalyticsInput;
  DeleteInstagramUGCManagementHashtagInput: DeleteInstagramUGCManagementHashtagInput;
  DeleteInstagramUGCManagementHashtagOutput: ResolverTypeWrapper<DeleteInstagramUGCManagementHashtagOutput>;
  DeleteInstagramUserHashtagInput: DeleteInstagramUserHashtagInput;
  DeleteInstagramUserHashtagOutput: ResolverTypeWrapper<DeleteInstagramUserHashtagOutput>;
  DeleteMarketplace: ResolverTypeWrapper<DeleteMarketplace>;
  DeleteOpenAIThread: ResolverTypeWrapper<DeleteOpenAIThread>;
  DeleteOpenAIThreadInput: DeleteOpenAIThreadInput;
  DeletePackage: ResolverTypeWrapper<DeletePackage>;
  DeleteSocialAccountPackage: ResolverTypeWrapper<DeleteSocialAccountPackage>;
  DeleteTalentAgency: ResolverTypeWrapper<DeleteTalentAgency>;
  DeleteTikTokSpecialCampaign: ResolverTypeWrapper<DeleteTikTokSpecialCampaign>;
  DeleteTikTokSpecialCampaignInput: DeleteTikTokSpecialCampaignInput;
  DeleteTiktokUserHashtagInput: DeleteTiktokUserHashtagInput;
  DeleteTiktokUserHashtagOutput: ResolverTypeWrapper<DeleteTiktokUserHashtagOutput>;
  DeleteTiktokUserKeywordInput: DeleteTiktokUserKeywordInput;
  DeleteTiktokUserKeywordOutput: ResolverTypeWrapper<DeleteTiktokUserKeywordOutput>;
  DeleteUser: ResolverTypeWrapper<DeleteUser>;
  DeleteUserProposalInfluencer: ResolverTypeWrapper<DeleteUserProposalInfluencer>;
  DeleteUserProposalInfluencerInput: DeleteUserProposalInfluencerInput;
  DeleteUserProposalSocialAccount: ResolverTypeWrapper<DeleteUserProposalSocialAccount>;
  DeleteUserProposalSocialAccountIndividually: ResolverTypeWrapper<DeleteUserProposalSocialAccountIndividually>;
  DeleteUserProposalSocialAccountIndividuallyInput: DeleteUserProposalSocialAccountIndividuallyInput;
  DeleteUserProposalSocialAccountInput: DeleteUserProposalSocialAccountInput;
  DeleteYoutubeCompareAccountAnalyticsInput: DeleteYoutubeCompareAccountAnalyticsInput;
  DeleteYoutubeCompareAccountAnalyticsOutput: ResolverTypeWrapper<DeleteYoutubeCompareAccountAnalyticsOutput>;
  DeliveryTimingItemInput: DeliveryTimingItemInput;
  DeliveryTimingItemPayload: ResolverTypeWrapper<DeliveryTimingItemPayload>;
  DeliveryTimingType: DeliveryTimingType;
  DenyEngagementInfluencerProposalList: ResolverTypeWrapper<DenyEngagementInfluencerProposalList>;
  DenyEngagementInfluencerProposalListInput: DenyEngagementInfluencerProposalListInput;
  DenyInfluencersUserProposal: ResolverTypeWrapper<DenyInfluencersUserProposal>;
  DenyInfluencersUserProposalInput: DenyInfluencersUserProposalInput;
  DisconnectPackageFromEngagementProposal: ResolverTypeWrapper<DisconnectPackageFromEngagementProposal>;
  DisconnectPackageFromEngagementProposalInput: DisconnectPackageFromEngagementProposalInput;
  DisconnectPromotionMethodsInput: DisconnectPromotionMethodsInput;
  DisconnectPromotionMethodsPayload: ResolverTypeWrapper<DisconnectPromotionMethodsPayload>;
  DisconnectTiktokAdAccount: ResolverTypeWrapper<DisconnectTiktokAdAccount>;
  DisconnectTiktokAdAccountInput: DisconnectTiktokAdAccountInput;
  DisplayLanguage: DisplayLanguage;
  DouyinAccountGeneralCategory: ResolverTypeWrapper<DouyinAccountGeneralCategory>;
  DouyinAccountInterest: ResolverTypeWrapper<DouyinAccountInterest>;
  DouyinAccountInterestRate: ResolverTypeWrapper<DouyinAccountInterestRate>;
  DouyinAudienceSection: ResolverTypeWrapper<DouyinAudienceSection>;
  DouyinDistributionType: DouyinDistributionType;
  DouyinFollowerRegionRate: ResolverTypeWrapper<DouyinFollowerRegionRate>;
  DouyinInfluencer: ResolverTypeWrapper<DouyinInfluencer>;
  DouyinInfluencerSearchResult: ResolverTypeWrapper<DouyinInfluencerSearchResult>;
  EditSocialAccountAnalyticsAdminInputV2: EditSocialAccountAnalyticsAdminInputV2;
  EditSocialAccountAnalyticsAdminOutputV2: ResolverTypeWrapper<EditSocialAccountAnalyticsAdminOutputV2>;
  EditSocialAccountAnalyticsAdvertiserInputV2: EditSocialAccountAnalyticsAdvertiserInputV2;
  EditSocialAccountAnalyticsAdvertiserOutputV2: ResolverTypeWrapper<EditSocialAccountAnalyticsAdvertiserOutputV2>;
  EmailEventStatus: EmailEventStatus;
  EmailEventStatusForSearch: EmailEventStatusForSearch;
  EmailInfo: ResolverTypeWrapper<EmailInfo>;
  EmailInfoForList: ResolverTypeWrapper<EmailInfoForList>;
  EmailListOrderBy: EmailListOrderBy;
  EmailListSortableField: EmailListSortableField;
  EmailStatistics: ResolverTypeWrapper<EmailStatistics>;
  EmailTemplateStatus: EmailTemplateStatus;
  Engagement: ResolverTypeWrapper<Engagement>;
  EngagementAddInfluencersToProposalFromAnotherCampaign: ResolverTypeWrapper<EngagementAddInfluencersToProposalFromAnotherCampaign>;
  EngagementAddInfluencersToProposalFromAnotherCampaignInput: EngagementAddInfluencersToProposalFromAnotherCampaignInput;
  EngagementCampaignCouponTrackingInput: EngagementCampaignCouponTrackingInput;
  EngagementCampaignCouponTrackingPayload: ResolverTypeWrapper<EngagementCampaignCouponTrackingPayload>;
  EngagementCampaignPostReportAudienceBreakdown: ResolverTypeWrapper<EngagementCampaignPostReportAudienceBreakdown>;
  EngagementCampaignPostReportAudienceBreakdownForTikTok: ResolverTypeWrapper<EngagementCampaignPostReportAudienceBreakdownForTikTok>;
  EngagementCampaignPostReportAudienceBreakdownForYouTube: ResolverTypeWrapper<EngagementCampaignPostReportAudienceBreakdownForYouTube>;
  EngagementCampaignPostReportForInfluencer: ResolverTypeWrapper<EngagementCampaignPostReportForInfluencer>;
  EngagementCampaignPostStatus: EngagementCampaignPostStatus;
  EngagementCampaignPostStatusForInsightCheck: EngagementCampaignPostStatusForInsightCheck;
  EngagementCampaignPostStatusForSearch: EngagementCampaignPostStatusForSearch;
  EngagementCampaignPostsForInsightCheck: ResolverTypeWrapper<EngagementCampaignPostsForInsightCheck>;
  EngagementCampaignReport: ResolverTypeWrapper<EngagementCampaignReport>;
  EngagementCampaignSearchSelectInfluencers: ResolverTypeWrapper<EngagementCampaignSearchSelectInfluencers>;
  EngagementCampaignSocialAccountPostStatus: EngagementCampaignSocialAccountPostStatus;
  EngagementCampaignStatus: EngagementCampaignStatus;
  EngagementDetail: ResolverTypeWrapper<EngagementDetail>;
  EngagementDetailForInfluencer: ResolverTypeWrapper<EngagementDetailForInfluencer>;
  EngagementDraftPostHistory: ResolverTypeWrapper<EngagementDraftPostHistory>;
  EngagementDraftPostHistoryForInfluencer: ResolverTypeWrapper<EngagementDraftPostHistoryForInfluencer>;
  EngagementDraftPostHistoryRecord: ResolverTypeWrapper<EngagementDraftPostHistoryRecord>;
  EngagementDraftPostHistoryRecordForInfluencer: ResolverTypeWrapper<EngagementDraftPostHistoryRecordForInfluencer>;
  EngagementForInsightCheck: ResolverTypeWrapper<EngagementForInsightCheck>;
  EngagementInfluencerBudgetInput: EngagementInfluencerBudgetInput;
  EngagementPost: ResolverTypeWrapper<EngagementPost>;
  EngagementPostComment: ResolverTypeWrapper<EngagementPostComment>;
  EngagementPostDetail: ResolverTypeWrapper<EngagementPostDetail>;
  EngagementPostDetailForInfluencer: ResolverTypeWrapper<EngagementPostDetailForInfluencer>;
  EngagementPostDetailForInfluencerV2: ResolverTypeWrapper<EngagementPostDetailForInfluencerV2>;
  EngagementPostDetailInfluencer: ResolverTypeWrapper<EngagementPostDetailInfluencer>;
  EngagementPostEditV2: ResolverTypeWrapper<EngagementPostEditV2>;
  EngagementPostForInfluencer: ResolverTypeWrapper<EngagementPostForInfluencer>;
  EngagementPostForInsightCheck: ResolverTypeWrapper<EngagementPostForInsightCheck>;
  EngagementPostInfluencer: ResolverTypeWrapper<EngagementPostInfluencer>;
  EngagementPostInfluencerWithSocialAccount: ResolverTypeWrapper<EngagementPostInfluencerWithSocialAccount>;
  EngagementPostInfluencerWithSocialAccounts: ResolverTypeWrapper<EngagementPostInfluencerWithSocialAccounts>;
  EngagementPostMetricsDataForInsightCheck: ResolverTypeWrapper<EngagementPostMetricsDataForInsightCheck>;
  EngagementPostSocialAccount: ResolverTypeWrapper<EngagementPostSocialAccount>;
  EngagementPostTrafficSource: ResolverTypeWrapper<EngagementPostTrafficSource>;
  EngagementProposal: ResolverTypeWrapper<EngagementProposal>;
  EngagementProposalSocialMediaType: EngagementProposalSocialMediaType;
  EngagementSocialAcccountBudgetInput: EngagementSocialAcccountBudgetInput;
  EngagementTracking: ResolverTypeWrapper<EngagementTracking>;
  EngagementXhsPostMetricsDataForInsight: ResolverTypeWrapper<EngagementXhsPostMetricsDataForInsight>;
  EngagementsForInsightCheck: ResolverTypeWrapper<EngagementsForInsightCheck>;
  EngagementsForInsightCheckCount: ResolverTypeWrapper<EngagementsForInsightCheckCount>;
  ErrorDetail: ErrorDetail;
  ErrorType: ErrorType;
  ExistingProfileTags: ResolverTypeWrapper<ExistingProfileTags>;
  ExportCmsInvoiceToSpreadsheet: ResolverTypeWrapper<ExportCmsInvoiceToSpreadsheet>;
  ExportCmsInvoiceToSpreadsheetInput: ExportCmsInvoiceToSpreadsheetInput;
  ExportDashboardInstagramAccountAnalytics: ResolverTypeWrapper<ExportDashboardInstagramAccountAnalytics>;
  ExportIGPresentationType: ExportIGPresentationType;
  ExportInstagramDashboardAccountAnalyticsInput: ExportInstagramDashboardAccountAnalyticsInput;
  ExportInstagramDashboardAccountInfluencerInput: ExportInstagramDashboardAccountInfluencerInput;
  ExportInstagramHashtagPostsToGoogleSpreadsheetInput: ExportInstagramHashtagPostsToGoogleSpreadsheetInput;
  ExportInstagramHashtagPostsToGoogleSpreadsheetOutput: ResolverTypeWrapper<ExportInstagramHashtagPostsToGoogleSpreadsheetOutput>;
  ExportYoutubeAccountAnalyticsType: ExportYoutubeAccountAnalyticsType;
  ExportYoutubeDashboardAccountAnalyticsInput: ExportYoutubeDashboardAccountAnalyticsInput;
  ExportYoutubeDashboardAccountAnalyticsOutput: ResolverTypeWrapper<ExportYoutubeDashboardAccountAnalyticsOutput>;
  ExternalAnalyticsAuthSocialAccountType: ExternalAnalyticsAuthSocialAccountType;
  ExternalAnalyticsFacebookGetAccountDetailsOutput: ResolverTypeWrapper<ExternalAnalyticsFacebookGetAccountDetailsOutput>;
  ExternalAnalyticsFacebookGetTokenWarningsOutput: ResolverTypeWrapper<ExternalAnalyticsFacebookGetTokenWarningsOutput>;
  ExternalAnalyticsInstagramGetAccountDetailsOutput: ResolverTypeWrapper<ExternalAnalyticsInstagramGetAccountDetailsOutput>;
  ExternalAnalyticsInstagramGetTokenWarningsOutput: ResolverTypeWrapper<ExternalAnalyticsInstagramGetTokenWarningsOutput>;
  ExternalAnalyticsSocialAuthRedirectUrlPayload: ResolverTypeWrapper<ExternalAnalyticsSocialAuthRedirectUrlPayload>;
  ExternalConnectableFacebookPageAccount: ResolverTypeWrapper<ExternalConnectableFacebookPageAccount>;
  ExternalConnectableFacebookPageAnalyticsInput: ExternalConnectableFacebookPageAnalyticsInput;
  ExternalConnectableFacebookPageAnalyticsPayload: ResolverTypeWrapper<ExternalConnectableFacebookPageAnalyticsPayload>;
  ExternalConnectableFacebookPageMainAccount: ResolverTypeWrapper<ExternalConnectableFacebookPageMainAccount>;
  ExternalConnectableOauthAccount: ResolverTypeWrapper<ExternalConnectableOauthAccount>;
  ExternalConnectableOauthPageAndIgAccount: ResolverTypeWrapper<ExternalConnectableOauthPageAndIgAccount>;
  ExternalCreateFacebookPageAccountAnalyticsInput: ExternalCreateFacebookPageAccountAnalyticsInput;
  ExternalCreateFacebookPageAccountAnalyticsOutput: ResolverTypeWrapper<ExternalCreateFacebookPageAccountAnalyticsOutput>;
  ExternalCreateInstagramAccountAnalyticsInput: ExternalCreateInstagramAccountAnalyticsInput;
  ExternalCreateInstagramAccountAnalyticsOutput: ResolverTypeWrapper<ExternalCreateInstagramAccountAnalyticsOutput>;
  ExternalCreateYoutubeAccountAnalyticsInput: ExternalCreateYoutubeAccountAnalyticsInput;
  ExternalCreateYoutubeAccountAnalyticsOutput: ResolverTypeWrapper<ExternalCreateYoutubeAccountAnalyticsOutput>;
  ExternalInstagramAnalyticsPostDetails: ResolverTypeWrapper<ExternalInstagramAnalyticsPostDetails>;
  ExternalInstagramAnalyticsPostsInDate: ResolverTypeWrapper<ExternalInstagramAnalyticsPostsInDate>;
  ExternalInstagramAnalyticsReelDetails: ResolverTypeWrapper<ExternalInstagramAnalyticsReelDetails>;
  ExternalInstagramAnalyticsStoryDetails: ResolverTypeWrapper<ExternalInstagramAnalyticsStoryDetails>;
  ExternalInstagramDashboardFollowerAnalytics: ResolverTypeWrapper<ExternalInstagramDashboardFollowerAnalytics>;
  ExternalInstagramDashboardOverview: ResolverTypeWrapper<ExternalInstagramDashboardOverview>;
  ExternalInstagramDashboardPostAnalytics: ResolverTypeWrapper<ExternalInstagramDashboardPostAnalytics>;
  ExternalInstagramFeedPost: ResolverTypeWrapper<ExternalInstagramFeedPost>;
  ExternalInstagramReelPost: ResolverTypeWrapper<ExternalInstagramReelPost>;
  ExternalInstagramStoryPost: ResolverTypeWrapper<ExternalInstagramStoryPost>;
  ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput: ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput;
  ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload: ResolverTypeWrapper<ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload>;
  ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput: ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput;
  ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload: ResolverTypeWrapper<ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload>;
  ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput: ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput;
  ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload: ResolverTypeWrapper<ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload>;
  ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload>;
  ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload>;
  ExternalYoutubeAnalyticsAudiencePayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsAudiencePayload>;
  ExternalYoutubeAnalyticsAudienceRegionPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsAudienceRegionPayload>;
  ExternalYoutubeAnalyticsOverviewHistoryItem: ResolverTypeWrapper<ExternalYoutubeAnalyticsOverviewHistoryItem>;
  ExternalYoutubeAnalyticsOverviewItem: ResolverTypeWrapper<ExternalYoutubeAnalyticsOverviewItem>;
  ExternalYoutubeAnalyticsOverviewPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsOverviewPayload>;
  ExternalYoutubeAnalyticsPostComment: ResolverTypeWrapper<ExternalYoutubeAnalyticsPostComment>;
  ExternalYoutubeAnalyticsPostListByDatePayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsPostListByDatePayload>;
  ExternalYoutubeAnalyticsPostListItemByDatePayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsPostListItemByDatePayload>;
  ExternalYoutubeAnalyticsPostListItemPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsPostListItemPayload>;
  ExternalYoutubeAnalyticsPostListPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsPostListPayload>;
  ExternalYoutubeAnalyticsPostsAverageEngagement: ResolverTypeWrapper<ExternalYoutubeAnalyticsPostsAverageEngagement>;
  ExternalYoutubeAnalyticsPostsPostingHabit: ResolverTypeWrapper<ExternalYoutubeAnalyticsPostsPostingHabit>;
  ExternalYoutubeAnalyticsPostsSortInput: ExternalYoutubeAnalyticsPostsSortInput;
  ExternalYoutubeAnalyticsPostsSortOrder: ExternalYoutubeAnalyticsPostsSortOrder;
  ExternalYoutubeAnalyticsPostsStatisticsPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsPostsStatisticsPayload>;
  ExternalYoutubeAnalyticsRelatedPostPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsRelatedPostPayload>;
  ExternalYoutubeAnalyticsRelatedPostsPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsRelatedPostsPayload>;
  ExternalYoutubeAnalyticsTagRankingPayload: ResolverTypeWrapper<ExternalYoutubeAnalyticsTagRankingPayload>;
  ExternalYoutubeAnalyticsTagRankingPayloads: ResolverTypeWrapper<ExternalYoutubeAnalyticsTagRankingPayloads>;
  ExternalYoutubeAnalyticsTagRankingSortInput: ExternalYoutubeAnalyticsTagRankingSortInput;
  ExternalYoutubeAnalyticsTagRankingSortOrder: ExternalYoutubeAnalyticsTagRankingSortOrder;
  ExternalYoutubePostPayload: ResolverTypeWrapper<ExternalYoutubePostPayload>;
  ExternalYoutubeStatisticsData: ResolverTypeWrapper<ExternalYoutubeStatisticsData>;
  FacebookAccountInfluencerSearchResultV2: ResolverTypeWrapper<FacebookAccountInfluencerSearchResultV2>;
  FacebookAccountInfluencerV2: ResolverTypeWrapper<FacebookAccountInfluencerV2>;
  FacebookInfluencer: ResolverTypeWrapper<FacebookInfluencer>;
  FacebookOauthInput: FacebookOauthInput;
  FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload: ResolverTypeWrapper<FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  FacebookPageAnalyticsAudienceAgeBreakdownPayload: ResolverTypeWrapper<FacebookPageAnalyticsAudienceAgeBreakdownPayload>;
  FacebookPageAnalyticsAudienceGenderBreakdownPayload: ResolverTypeWrapper<FacebookPageAnalyticsAudienceGenderBreakdownPayload>;
  FacebookPageAnalyticsAudiencePayload: ResolverTypeWrapper<FacebookPageAnalyticsAudiencePayload>;
  FacebookPageAnalyticsAudienceRegionPayload: ResolverTypeWrapper<FacebookPageAnalyticsAudienceRegionPayload>;
  FacebookPageAnalyticsOverviewHistoryItem: ResolverTypeWrapper<FacebookPageAnalyticsOverviewHistoryItem>;
  FacebookPageAnalyticsOverviewHistoryRateItem: ResolverTypeWrapper<FacebookPageAnalyticsOverviewHistoryRateItem>;
  FacebookPageAnalyticsOverviewItem: ResolverTypeWrapper<FacebookPageAnalyticsOverviewItem>;
  FacebookPageAnalyticsOverviewPayload: ResolverTypeWrapper<FacebookPageAnalyticsOverviewPayload>;
  FacebookPageAnalyticsOverviewRateItem: ResolverTypeWrapper<FacebookPageAnalyticsOverviewRateItem>;
  FacebookPageAnalyticsPostComment: ResolverTypeWrapper<FacebookPageAnalyticsPostComment>;
  FacebookPageAnalyticsPostPayload: ResolverTypeWrapper<FacebookPageAnalyticsPostPayload>;
  FacebookPageAnalyticsPostWithPageDetailsPayload: ResolverTypeWrapper<FacebookPageAnalyticsPostWithPageDetailsPayload>;
  FacebookPageAnalyticsPostsAnalyticsPayload: ResolverTypeWrapper<FacebookPageAnalyticsPostsAnalyticsPayload>;
  FacebookPageAnalyticsPostsAverageEngagement: ResolverTypeWrapper<FacebookPageAnalyticsPostsAverageEngagement>;
  FacebookPageAnalyticsPostsByDatePayload: ResolverTypeWrapper<FacebookPageAnalyticsPostsByDatePayload>;
  FacebookPageAnalyticsPostsPayload: ResolverTypeWrapper<FacebookPageAnalyticsPostsPayload>;
  FacebookPageAnalyticsPostsPayloads: ResolverTypeWrapper<FacebookPageAnalyticsPostsPayloads>;
  FacebookPageAnalyticsPostsSortInput: FacebookPageAnalyticsPostsSortInput;
  FacebookPageAnalyticsPostsSortOrder: FacebookPageAnalyticsPostsSortOrder;
  FacebookPageForProfileV2: ResolverTypeWrapper<FacebookPageForProfileV2>;
  FacebookPageInfluencer: ResolverTypeWrapper<FacebookPageInfluencer>;
  FacebookPageInfluencerSearchResultV2: ResolverTypeWrapper<FacebookPageInfluencerSearchResultV2>;
  FacebookPageInfluencerV2: ResolverTypeWrapper<FacebookPageInfluencerV2>;
  FacebookPagePostsAnalyticsFanActivity: ResolverTypeWrapper<FacebookPagePostsAnalyticsFanActivity>;
  FacebookPageStatisticsData: ResolverTypeWrapper<FacebookPageStatisticsData>;
  FacebookPostForLinkBio: ResolverTypeWrapper<FacebookPostForLinkBio>;
  FanActivity: ResolverTypeWrapper<FanActivity>;
  FanActivityType: FanActivityType;
  FanDetails: ResolverTypeWrapper<FanDetails>;
  FanFormAnswer: ResolverTypeWrapper<FanFormAnswer>;
  FanGender: FanGender;
  FanListOrderBy: FanListOrderBy;
  FanListSortableField: FanListSortableField;
  FanOrderDetails: ResolverTypeWrapper<FanOrderDetails>;
  FanOrderItem: ResolverTypeWrapper<FanOrderItem>;
  FanTag: ResolverTypeWrapper<FanTag>;
  FileUploadUrls: ResolverTypeWrapper<FileUploadUrls>;
  FilterByDateRange: FilterByDateRange;
  FilterByNumber: FilterByNumber;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FollowersAgeGenderGroup: ResolverTypeWrapper<FollowersAgeGenderGroup>;
  FollowersAgeGroup: ResolverTypeWrapper<FollowersAgeGroup>;
  FollowersAgeGroupInput: FollowersAgeGroupInput;
  FollowersByTime: ResolverTypeWrapper<FollowersByTime>;
  FollowersCountry: ResolverTypeWrapper<FollowersCountry>;
  FollowersGrowthPeriodEnum: FollowersGrowthPeriodEnum;
  FontSize: FontSize;
  FormItem: ResolverTypeWrapper<FormItem>;
  FormListOrderBy: FormListOrderBy;
  FormListSortableField: FormListSortableField;
  FormQuestionAnswer: ResolverTypeWrapper<FormQuestionAnswer>;
  FormReportAnswerOption: ResolverTypeWrapper<FormReportAnswerOption>;
  FormReportQuestionAnswer: ResolverTypeWrapper<FormReportQuestionAnswer>;
  FormReportSummaryInput: FormReportSummaryInput;
  FormReportSummaryPayload: ResolverTypeWrapper<FormReportSummaryPayload>;
  FormRespondentItem: ResolverTypeWrapper<FormRespondentItem>;
  FormRespondentsCountInput: FormRespondentsCountInput;
  FormRespondentsCountPayload: ResolverTypeWrapper<FormRespondentsCountPayload>;
  FormRespondentsListInput: FormRespondentsListInput;
  FormRespondentsListPayload: ResolverTypeWrapper<FormRespondentsListPayload>;
  FormStatus: FormStatus;
  GeminiMessage: ResolverTypeWrapper<GeminiMessage>;
  GeminiNextAction: GeminiNextAction;
  GeminiSourceFrom: GeminiSourceFrom;
  Genders: Genders;
  GenerateImportFansUploadURLPayload: ResolverTypeWrapper<GenerateImportFansUploadURLPayload>;
  GenerateMarketplaceCampaignDetailInput: GenerateMarketplaceCampaignDetailInput;
  GenerateMarketplaceCampaignDetailPayload: ResolverTypeWrapper<GenerateMarketplaceCampaignDetailPayload>;
  GenerateMarketplaceCampaignDetailStatus: GenerateMarketplaceCampaignDetailStatus;
  GenerateMarketplacePostCaptionInput: GenerateMarketplacePostCaptionInput;
  GeneratePartnerInfluencerSignUpUrl: ResolverTypeWrapper<GeneratePartnerInfluencerSignUpUrl>;
  GenerateTalentInfluencerSignUpUrl: ResolverTypeWrapper<GenerateTalentInfluencerSignUpUrl>;
  GenerateTikTokSpecialPostCaptionInput: GenerateTikTokSpecialPostCaptionInput;
  GetAccountInformationPayload: ResolverTypeWrapper<GetAccountInformationPayload>;
  GetAllCampaignsCountForSearchJobInput: GetAllCampaignsCountForSearchJobInput;
  GetAllCampaignsCountForSearchJobPayload: ResolverTypeWrapper<GetAllCampaignsCountForSearchJobPayload>;
  GetAllCampaignsForSearchJobInput: GetAllCampaignsForSearchJobInput;
  GetAllCampaignsForSearchJobPayload: ResolverTypeWrapper<GetAllCampaignsForSearchJobPayload>;
  GetAnyXAccountsByKeywordInput: GetAnyXAccountsByKeywordInput;
  GetAnyXAccountsByKeywordPayload: ResolverTypeWrapper<GetAnyXAccountsByKeywordPayload>;
  GetAutoManagedCampaignByIdInput: GetAutoManagedCampaignByIdInput;
  GetAutoManagedCampaignForInfluencerSearchJobInput: GetAutoManagedCampaignForInfluencerSearchJobInput;
  GetAutoManagedCampaignForInfluencerYourJobInput: GetAutoManagedCampaignForInfluencerYourJobInput;
  GetAutoManagedCampaignsCountInput: GetAutoManagedCampaignsCountInput;
  GetAutoManagedCampaignsCountPayload: ResolverTypeWrapper<GetAutoManagedCampaignsCountPayload>;
  GetAutoManagedCampaignsInput: GetAutoManagedCampaignsInput;
  GetAutoManagedCampaignsOrderBy: GetAutoManagedCampaignsOrderBy;
  GetAutoManagedCampaignsPayload: ResolverTypeWrapper<GetAutoManagedCampaignsPayload>;
  GetAutoManagedCampaignsSortField: GetAutoManagedCampaignsSortField;
  GetChatEventsInput: GetChatEventsInput;
  GetChatEventsPayload: ResolverTypeWrapper<GetChatEventsPayload>;
  GetCreatorTagsByIdInput: GetCreatorTagsByIdInput;
  GetCreatorTagsByIdPayload: ResolverTypeWrapper<GetCreatorTagsByIdPayload>;
  GetCreatorTagsInput: GetCreatorTagsInput;
  GetCreatorTagsPayload: ResolverTypeWrapper<GetCreatorTagsPayload>;
  GetCustomerTagsInput: GetCustomerTagsInput;
  GetCustomerTagsPayload: ResolverTypeWrapper<GetCustomerTagsPayload>;
  GetEmailListCountInput: GetEmailListCountInput;
  GetEmailListCountPayload: ResolverTypeWrapper<GetEmailListCountPayload>;
  GetEmailListInput: GetEmailListInput;
  GetEmailListPayload: ResolverTypeWrapper<GetEmailListPayload>;
  GetEmailOverviewInput: GetEmailOverviewInput;
  GetEmailOverviewPayload: ResolverTypeWrapper<GetEmailOverviewPayload>;
  GetEmailRecipientFan: ResolverTypeWrapper<GetEmailRecipientFan>;
  GetEmailRecipientTag: ResolverTypeWrapper<GetEmailRecipientTag>;
  GetEmailRecipientsCountInput: GetEmailRecipientsCountInput;
  GetEmailRecipientsCountPayload: ResolverTypeWrapper<GetEmailRecipientsCountPayload>;
  GetEmailRecipientsInput: GetEmailRecipientsInput;
  GetEmailRecipientsPayload: ResolverTypeWrapper<GetEmailRecipientsPayload>;
  GetEmailTemplateInput: GetEmailTemplateInput;
  GetEmailTemplatePayload: ResolverTypeWrapper<GetEmailTemplatePayload>;
  GetFanActivitiesInput: GetFanActivitiesInput;
  GetFanActivitiesPayload: ResolverTypeWrapper<GetFanActivitiesPayload>;
  GetFanFormResponseInput: GetFanFormResponseInput;
  GetFanFormResponsePayload: ResolverTypeWrapper<GetFanFormResponsePayload>;
  GetFanHeaderPayload: ResolverTypeWrapper<GetFanHeaderPayload>;
  GetFanInput: GetFanInput;
  GetFanOrdersInput: GetFanOrdersInput;
  GetFanOrdersPayload: ResolverTypeWrapper<GetFanOrdersPayload>;
  GetFanParticipationFormListInput: GetFanParticipationFormListInput;
  GetFanParticipationFormListPayload: ResolverTypeWrapper<GetFanParticipationFormListPayload>;
  GetFanPayload: ResolverTypeWrapper<GetFanPayload>;
  GetFanProvincesInput: GetFanProvincesInput;
  GetFansCountInput: GetFansCountInput;
  GetFansCountPayload: ResolverTypeWrapper<GetFansCountPayload>;
  GetFansInput: GetFansInput;
  GetFansPayload: ResolverTypeWrapper<GetFansPayload>;
  GetFormInput: GetFormInput;
  GetFormInputPayload: ResolverTypeWrapper<GetFormInputPayload>;
  GetFormQuestion: ResolverTypeWrapper<GetFormQuestion>;
  GetFormQuestionOption: ResolverTypeWrapper<GetFormQuestionOption>;
  GetFormResponseByPageInput: GetFormResponseByPageInput;
  GetFormResponseInput: GetFormResponseInput;
  GetFormResponsePayload: ResolverTypeWrapper<GetFormResponsePayload>;
  GetFormTemplateInput: GetFormTemplateInput;
  GetFormTemplateOption: ResolverTypeWrapper<GetFormTemplateOption>;
  GetFormTemplatePayload: ResolverTypeWrapper<GetFormTemplatePayload>;
  GetFormTemplateQuestion: ResolverTypeWrapper<GetFormTemplateQuestion>;
  GetFormsCountInput: GetFormsCountInput;
  GetFormsCountPayload: ResolverTypeWrapper<GetFormsCountPayload>;
  GetFormsListInput: GetFormsListInput;
  GetFormsListPayload: ResolverTypeWrapper<GetFormsListPayload>;
  GetGeneratedMarketplaceCampaignDetailInput: GetGeneratedMarketplaceCampaignDetailInput;
  GetGeneratedMarketplaceCampaignDetailPayload: ResolverTypeWrapper<GetGeneratedMarketplaceCampaignDetailPayload>;
  GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail: ResolverTypeWrapper<GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail>;
  GetGeneratedMarketplacePostCaptionInput: GetGeneratedMarketplacePostCaptionInput;
  GetGeneratedMarketplacePostCaptionPayload: ResolverTypeWrapper<GetGeneratedMarketplacePostCaptionPayload>;
  GetGeneratedPostCaptionRecentRequestStatus: GetGeneratedPostCaptionRecentRequestStatus;
  GetGeneratedTikTokSpecialPostCaptionInput: GetGeneratedTikTokSpecialPostCaptionInput;
  GetGeneratedTikTokSpecialPostCaptionPayload: ResolverTypeWrapper<GetGeneratedTikTokSpecialPostCaptionPayload>;
  GetInfluencerBioAnalyticsInput: GetInfluencerBioAnalyticsInput;
  GetInfluencerBioAnalyticsPayload: ResolverTypeWrapper<GetInfluencerBioAnalyticsPayload>;
  GetInfluencerBioPayload: ResolverTypeWrapper<GetInfluencerBioPayload>;
  GetInfluencerBioPayloadV2: ResolverTypeWrapper<GetInfluencerBioPayloadV2>;
  GetInfluencerChatServicesPayload: ResolverTypeWrapper<GetInfluencerChatServicesPayload>;
  GetLineBroadcastEstimateRecipientsInput: GetLineBroadcastEstimateRecipientsInput;
  GetLineBroadcastEstimateRecipientsPayload: ResolverTypeWrapper<GetLineBroadcastEstimateRecipientsPayload>;
  GetLineBroadcastMessageInput: GetLineBroadcastMessageInput;
  GetLineBroadcastMessagePayload: ResolverTypeWrapper<GetLineBroadcastMessagePayload>;
  GetLineBroadcastSummaryInput: GetLineBroadcastSummaryInput;
  GetLineBroadcastSummaryPayload: ResolverTypeWrapper<GetLineBroadcastSummaryPayload>;
  GetLineBroadcastsCountInput: GetLineBroadcastsCountInput;
  GetLineBroadcastsCountPayload: ResolverTypeWrapper<GetLineBroadcastsCountPayload>;
  GetLineBroadcastsInput: GetLineBroadcastsInput;
  GetLineBroadcastsPayload: ResolverTypeWrapper<GetLineBroadcastsPayload>;
  GetLineChannelPayload: ResolverTypeWrapper<GetLineChannelPayload>;
  GetLineChatCountInput: GetLineChatCountInput;
  GetLineChatCountPayload: ResolverTypeWrapper<GetLineChatCountPayload>;
  GetLineChatInput: GetLineChatInput;
  GetLineChatPayload: ResolverTypeWrapper<GetLineChatPayload>;
  GetLineChatsInput: GetLineChatsInput;
  GetLineChatsPayload: ResolverTypeWrapper<GetLineChatsPayload>;
  GetLineLoginDetailPayload: ResolverTypeWrapper<GetLineLoginDetailPayload>;
  GetLinePublicKeyPayload: ResolverTypeWrapper<GetLinePublicKeyPayload>;
  GetLineServicePayload: ResolverTypeWrapper<GetLineServicePayload>;
  GetLinkBioFacebookAccountPostsInput: GetLinkBioFacebookAccountPostsInput;
  GetLinkBioFacebookAccountPostsPayload: ResolverTypeWrapper<GetLinkBioFacebookAccountPostsPayload>;
  GetLinkBioFacebookPagePostsInput: GetLinkBioFacebookPagePostsInput;
  GetLinkBioFacebookPagePostsPayload: ResolverTypeWrapper<GetLinkBioFacebookPagePostsPayload>;
  GetLinkBioFacebookPostPayload: ResolverTypeWrapper<GetLinkBioFacebookPostPayload>;
  GetLinkBioThemeColorPayload: ResolverTypeWrapper<GetLinkBioThemeColorPayload>;
  GetMessageTemplatesPayload: ResolverTypeWrapper<GetMessageTemplatesPayload>;
  GetPublicInfluencerBioInput: GetPublicInfluencerBioInput;
  GetPublicInfluencerBioPayload: ResolverTypeWrapper<GetPublicInfluencerBioPayload>;
  GetSelectableYoutubeAnalyticsChannelsInput: GetSelectableYoutubeAnalyticsChannelsInput;
  GetShopifyAccountPayload: ResolverTypeWrapper<GetShopifyAccountPayload>;
  GetTagsAndFansRecipientsInput: GetTagsAndFansRecipientsInput;
  GetTagsAndFansRecipientsPayload: ResolverTypeWrapper<GetTagsAndFansRecipientsPayload>;
  GoogleSlideFile: ResolverTypeWrapper<GoogleSlideFile>;
  Hashtag: ResolverTypeWrapper<Hashtag>;
  HashtagType: ResolverTypeWrapper<HashtagType>;
  HubspotCompanyData: ResolverTypeWrapper<HubspotCompanyData>;
  HubspotDealData: ResolverTypeWrapper<HubspotDealData>;
  HubspotDealDataForSearch: ResolverTypeWrapper<HubspotDealDataForSearch>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  ImageInput: ImageInput;
  ImageItemInput: ImageItemInput;
  ImageItemPayload: ResolverTypeWrapper<ImageItemPayload>;
  ImageMessageInput: ImageMessageInput;
  ImageMessagePayload: ResolverTypeWrapper<ImageMessagePayload>;
  ImagePayload: ResolverTypeWrapper<ImagePayload>;
  ImageVideoMessageInput: ImageVideoMessageInput;
  ImageVideoMessagePayload: ResolverTypeWrapper<ImageVideoMessagePayload>;
  ImportFansInput: ImportFansInput;
  ImportFansPayload: ResolverTypeWrapper<ImportFansPayload>;
  Influencer: ResolverTypeWrapper<Influencer>;
  InfluencerAddYoutubeCompareAccountAnalyticsInput: InfluencerAddYoutubeCompareAccountAnalyticsInput;
  InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload: ResolverTypeWrapper<InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload>;
  InfluencerAnalyticsSponsoredPostCountPayload: ResolverTypeWrapper<InfluencerAnalyticsSponsoredPostCountPayload>;
  InfluencerAnalyticsSponsoredPostPayload: ResolverTypeWrapper<InfluencerAnalyticsSponsoredPostPayload>;
  InfluencerAnalyticsTwitterRefreshAvailabilityPayload: ResolverTypeWrapper<InfluencerAnalyticsTwitterRefreshAvailabilityPayload>;
  InfluencerAnalyticsTwitterRefreshInput: InfluencerAnalyticsTwitterRefreshInput;
  InfluencerAnalyticsTwitterRefreshOutput: ResolverTypeWrapper<InfluencerAnalyticsTwitterRefreshOutput>;
  InfluencerAudienceSection: ResolverTypeWrapper<InfluencerAudienceSection>;
  InfluencerBioAnalyticsAudience: ResolverTypeWrapper<InfluencerBioAnalyticsAudience>;
  InfluencerBioAnalyticsHistory: ResolverTypeWrapper<InfluencerBioAnalyticsHistory>;
  InfluencerBioAnalyticsOverview: ResolverTypeWrapper<InfluencerBioAnalyticsOverview>;
  InfluencerBrandAccounts: ResolverTypeWrapper<InfluencerBrandAccounts>;
  InfluencerCampaignDetail: ResolverTypeWrapper<InfluencerCampaignDetail>;
  InfluencerCampaignDetailByPromotion: ResolverTypeWrapper<InfluencerCampaignDetailByPromotion>;
  InfluencerCampaignDetailInfo: ResolverTypeWrapper<InfluencerCampaignDetailInfo>;
  InfluencerCampaignDetailInfoByPromotion: ResolverTypeWrapper<InfluencerCampaignDetailInfoByPromotion>;
  InfluencerCampaignListSortField: InfluencerCampaignListSortField;
  InfluencerCampaignOrderBy: InfluencerCampaignOrderBy;
  InfluencerCampaignPostData: ResolverTypeWrapper<InfluencerCampaignPostData>;
  InfluencerCampaignPostInfo: ResolverTypeWrapper<InfluencerCampaignPostInfo>;
  InfluencerCampaignStat: ResolverTypeWrapper<InfluencerCampaignStat>;
  InfluencerCampaignStatByPromotion: ResolverTypeWrapper<InfluencerCampaignStatByPromotion>;
  InfluencerCampaignSummary: ResolverTypeWrapper<InfluencerCampaignSummary>;
  InfluencerDeleteYoutubeCompareAccountAnalyticsInput: InfluencerDeleteYoutubeCompareAccountAnalyticsInput;
  InfluencerEngagement: ResolverTypeWrapper<InfluencerEngagement>;
  InfluencerExportDashboardInstagramAccount: ResolverTypeWrapper<InfluencerExportDashboardInstagramAccount>;
  InfluencerExportYoutubeDashboardAccountAnalyticsInput: InfluencerExportYoutubeDashboardAccountAnalyticsInput;
  InfluencerFacebookPageAnalyticsAccessTokenWarningPayload: ResolverTypeWrapper<InfluencerFacebookPageAnalyticsAccessTokenWarningPayload>;
  InfluencerFeedPost: ResolverTypeWrapper<InfluencerFeedPost>;
  InfluencerFollowerGrowthSection: ResolverTypeWrapper<InfluencerFollowerGrowthSection>;
  InfluencerHasEstimateApi: ResolverTypeWrapper<InfluencerHasEstimateApi>;
  InfluencerInstagramAnalyticsAccessTokenWarningPayload: ResolverTypeWrapper<InfluencerInstagramAnalyticsAccessTokenWarningPayload>;
  InfluencerInstagramDataForReconnectPayload: ResolverTypeWrapper<InfluencerInstagramDataForReconnectPayload>;
  InfluencerInterest: ResolverTypeWrapper<InfluencerInterest>;
  InfluencerListSortField: InfluencerListSortField;
  InfluencerManagerOutput: ResolverTypeWrapper<InfluencerManagerOutput>;
  InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload: ResolverTypeWrapper<InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload>;
  InfluencerMultipleInstagramDataForReconnectPayload: ResolverTypeWrapper<InfluencerMultipleInstagramDataForReconnectPayload>;
  InfluencerNewProfileDetail: ResolverTypeWrapper<InfluencerNewProfileDetail>;
  InfluencerNewProfileEngagement: ResolverTypeWrapper<InfluencerNewProfileEngagement>;
  InfluencerNewProfileFollowers: ResolverTypeWrapper<InfluencerNewProfileFollowers>;
  InfluencerNewProfileHeaderV2: ResolverTypeWrapper<InfluencerNewProfileHeaderV2>;
  InfluencerNewProfilePostsHabit: ResolverTypeWrapper<InfluencerNewProfilePostsHabit>;
  InfluencerNewProfilePostsStats: ResolverTypeWrapper<InfluencerNewProfilePostsStats>;
  InfluencerNewProfileSentimentAnalysis: ResolverTypeWrapper<InfluencerNewProfileSentimentAnalysis>;
  InfluencerOrderBy: InfluencerOrderBy;
  InfluencerPaymentInformation: ResolverTypeWrapper<InfluencerPaymentInformation>;
  InfluencerPaymentRequest: ResolverTypeWrapper<InfluencerPaymentRequest>;
  InfluencerPaymentRequests: ResolverTypeWrapper<InfluencerPaymentRequests>;
  InfluencerPopularPost: ResolverTypeWrapper<InfluencerPopularPost>;
  InfluencerPostDetail: ResolverTypeWrapper<InfluencerPostDetail>;
  InfluencerPostReportStatsEngagement: ResolverTypeWrapper<InfluencerPostReportStatsEngagement>;
  InfluencerPostReportStatsMarketplace: ResolverTypeWrapper<InfluencerPostReportStatsMarketplace>;
  InfluencerPostReportStatsTikTokSpecial: ResolverTypeWrapper<InfluencerPostReportStatsTikTokSpecial>;
  InfluencerPostSection: ResolverTypeWrapper<InfluencerPostSection>;
  InfluencerPostV3: ResolverTypeWrapper<InfluencerPostV3>;
  InfluencerPostsV3: ResolverTypeWrapper<InfluencerPostsV3>;
  InfluencerProfileDetailForEngagementProposal: ResolverTypeWrapper<InfluencerProfileDetailForEngagementProposal>;
  InfluencerProfileForEngagementProposal: ResolverTypeWrapper<InfluencerProfileForEngagementProposal>;
  InfluencerPromotionMethodsPayload: ResolverTypeWrapper<InfluencerPromotionMethodsPayload>;
  InfluencerRateCardData: ResolverTypeWrapper<InfluencerRateCardData>;
  InfluencerRateCardDetailData: ResolverTypeWrapper<InfluencerRateCardDetailData>;
  InfluencerRateCardInput: InfluencerRateCardInput;
  InfluencerRateCardPayload: ResolverTypeWrapper<InfluencerRateCardPayload>;
  InfluencerRateCardPriceInput: InfluencerRateCardPriceInput;
  InfluencerRateCardsInput: InfluencerRateCardsInput;
  InfluencerReelPost: ResolverTypeWrapper<InfluencerReelPost>;
  InfluencerSearchKeywordField: InfluencerSearchKeywordField;
  InfluencerSearchOrderBy: InfluencerSearchOrderBy;
  InfluencerSearchSortBy: InfluencerSearchSortBy;
  InfluencerSearchSortField: InfluencerSearchSortField;
  InfluencerSignUpInput: InfluencerSignUpInput;
  InfluencerSignUpInputPayload: ResolverTypeWrapper<InfluencerSignUpInputPayload>;
  InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput: InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput;
  InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload: ResolverTypeWrapper<InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload>;
  InfluencerSocialNetworkAccountInput: InfluencerSocialNetworkAccountInput;
  InfluencerSocialNetworkAccountOutput: ResolverTypeWrapper<InfluencerSocialNetworkAccountOutput>;
  InfluencerSocialPost: ResolverTypeWrapper<InfluencerSocialPost>;
  InfluencerSponsoredPost: ResolverTypeWrapper<InfluencerSponsoredPost>;
  InfluencerSponsoredPostCountResponse: ResolverTypeWrapper<InfluencerSponsoredPostCountResponse>;
  InfluencerSponsoredPostResponse: ResolverTypeWrapper<InfluencerSponsoredPostResponse>;
  InfluencerStatusForSearch: InfluencerStatusForSearch;
  InfluencerTwitterAnalyticsAccessTokenWarningPayload: ResolverTypeWrapper<InfluencerTwitterAnalyticsAccessTokenWarningPayload>;
  InfluencerType: InfluencerType;
  InfluencerUpdateDetailV2: ResolverTypeWrapper<InfluencerUpdateDetailV2>;
  InfluencerV4: ResolverTypeWrapper<InfluencerV4>;
  InfluencerWhitelist: ResolverTypeWrapper<InfluencerWhitelist>;
  InfluencerWordCloud: ResolverTypeWrapper<InfluencerWordCloud>;
  InfluencerYoutubeAnalyticsAccountCard: ResolverTypeWrapper<InfluencerYoutubeAnalyticsAccountCard>;
  InfluencerYoutubeAnalyticsAccountPosts: ResolverTypeWrapper<InfluencerYoutubeAnalyticsAccountPosts>;
  InfluencerYoutubeAnalyticsAccountPostsInDate: ResolverTypeWrapper<InfluencerYoutubeAnalyticsAccountPostsInDate>;
  InfluencerYoutubeAnalyticsAccountTagRanking: ResolverTypeWrapper<InfluencerYoutubeAnalyticsAccountTagRanking>;
  InfluencerYoutubeAnalyticsChannelDetailsPayload: ResolverTypeWrapper<InfluencerYoutubeAnalyticsChannelDetailsPayload>;
  InfluencerYoutubeAnalyticsCompareAccountCard: ResolverTypeWrapper<InfluencerYoutubeAnalyticsCompareAccountCard>;
  InfluencerYoutubeAnalyticsCompareAccountCards: ResolverTypeWrapper<InfluencerYoutubeAnalyticsCompareAccountCards>;
  InfluencerYoutubeCompareAccountOverview: ResolverTypeWrapper<InfluencerYoutubeCompareAccountOverview>;
  InfluencerYoutubeCompareAllPostsInDate: ResolverTypeWrapper<InfluencerYoutubeCompareAllPostsInDate>;
  InfluencerYoutubeCompareOverview: ResolverTypeWrapper<InfluencerYoutubeCompareOverview>;
  InfluencerYoutubeComparePosts: ResolverTypeWrapper<InfluencerYoutubeComparePosts>;
  InfluencerYoutubeCompareTagRanking: ResolverTypeWrapper<InfluencerYoutubeCompareTagRanking>;
  InfluencerYoutubeSponsoredPost: ResolverTypeWrapper<InfluencerYoutubeSponsoredPost>;
  InfluencersPptxSocialAccountInput: InfluencersPptxSocialAccountInput;
  InfluencersProfileFormat: InfluencersProfileFormat;
  InquiryEvent: ResolverTypeWrapper<InquiryEvent>;
  InquiryFinishEvent: ResolverTypeWrapper<InquiryFinishEvent>;
  InstagramAccountForProfileV2: ResolverTypeWrapper<InstagramAccountForProfileV2>;
  InstagramAnalyticsAccountInfoHeader: ResolverTypeWrapper<InstagramAnalyticsAccountInfoHeader>;
  InstagramAnalyticsComments: ResolverTypeWrapper<InstagramAnalyticsComments>;
  InstagramAnalyticsCompareAccount: ResolverTypeWrapper<InstagramAnalyticsCompareAccount>;
  InstagramAnalyticsCompareAccounts: ResolverTypeWrapper<InstagramAnalyticsCompareAccounts>;
  InstagramAnalyticsComparePostsInDate: ResolverTypeWrapper<InstagramAnalyticsComparePostsInDate>;
  InstagramAnalyticsGetCommentItemsPayload: ResolverTypeWrapper<InstagramAnalyticsGetCommentItemsPayload>;
  InstagramAnalyticsGetCommentsPayload: ResolverTypeWrapper<InstagramAnalyticsGetCommentsPayload>;
  InstagramAnalyticsHashtag: ResolverTypeWrapper<InstagramAnalyticsHashtag>;
  InstagramAnalyticsHashtags: ResolverTypeWrapper<InstagramAnalyticsHashtags>;
  InstagramAnalyticsHistoryDataPoint: ResolverTypeWrapper<InstagramAnalyticsHistoryDataPoint>;
  InstagramAnalyticsHistoryEngagementRateDataPoint: ResolverTypeWrapper<InstagramAnalyticsHistoryEngagementRateDataPoint>;
  InstagramAnalyticsOverviewData: ResolverTypeWrapper<InstagramAnalyticsOverviewData>;
  InstagramAnalyticsOverviewEngagementRateData: ResolverTypeWrapper<InstagramAnalyticsOverviewEngagementRateData>;
  InstagramAnalyticsPostAnalysisDetails: ResolverTypeWrapper<InstagramAnalyticsPostAnalysisDetails>;
  InstagramAnalyticsPostCommentDetails: ResolverTypeWrapper<InstagramAnalyticsPostCommentDetails>;
  InstagramAnalyticsPostDetails: ResolverTypeWrapper<InstagramAnalyticsPostDetails>;
  InstagramAnalyticsPostSentimentDetails: ResolverTypeWrapper<InstagramAnalyticsPostSentimentDetails>;
  InstagramAnalyticsPostsInDate: ResolverTypeWrapper<InstagramAnalyticsPostsInDate>;
  InstagramAnalyticsReelAnalysisDetails: ResolverTypeWrapper<InstagramAnalyticsReelAnalysisDetails>;
  InstagramAnalyticsReelCommentDetails: ResolverTypeWrapper<InstagramAnalyticsReelCommentDetails>;
  InstagramAnalyticsReelDetails: ResolverTypeWrapper<InstagramAnalyticsReelDetails>;
  InstagramAnalyticsStoryDetails: ResolverTypeWrapper<InstagramAnalyticsStoryDetails>;
  InstagramAnalyticsSummaryPostsInDate: ResolverTypeWrapper<InstagramAnalyticsSummaryPostsInDate>;
  InstagramBrandAmbassadorPost: ResolverTypeWrapper<InstagramBrandAmbassadorPost>;
  InstagramBrandAmbassadorPostDetails: ResolverTypeWrapper<InstagramBrandAmbassadorPostDetails>;
  InstagramBrandAmbassadorPosts: ResolverTypeWrapper<InstagramBrandAmbassadorPosts>;
  InstagramBrandAmbassadorPostsByUsername: ResolverTypeWrapper<InstagramBrandAmbassadorPostsByUsername>;
  InstagramCommentAnalytics: ResolverTypeWrapper<InstagramCommentAnalytics>;
  InstagramCompareAccount: ResolverTypeWrapper<InstagramCompareAccount>;
  InstagramCompareAccountPost: ResolverTypeWrapper<InstagramCompareAccountPost>;
  InstagramCompareAccountsPayload: ResolverTypeWrapper<InstagramCompareAccountsPayload>;
  InstagramCompareFeedPost: ResolverTypeWrapper<InstagramCompareFeedPost>;
  InstagramCompareFollowers: ResolverTypeWrapper<InstagramCompareFollowers>;
  InstagramCompareFollowersPrimaryAccount: ResolverTypeWrapper<InstagramCompareFollowersPrimaryAccount>;
  InstagramCompareFollowersSecondaryAccount: ResolverTypeWrapper<InstagramCompareFollowersSecondaryAccount>;
  InstagramCompareOverview: ResolverTypeWrapper<InstagramCompareOverview>;
  InstagramCompareOverviewHistory: ResolverTypeWrapper<InstagramCompareOverviewHistory>;
  InstagramCompareOverviewSummary: ResolverTypeWrapper<InstagramCompareOverviewSummary>;
  InstagramCompareOverviewSummaryAverageData: ResolverTypeWrapper<InstagramCompareOverviewSummaryAverageData>;
  InstagramCompareOverviewSummaryData: ResolverTypeWrapper<InstagramCompareOverviewSummaryData>;
  InstagramComparePostAnalysisDetails: ResolverTypeWrapper<InstagramComparePostAnalysisDetails>;
  InstagramComparePostDetails: ResolverTypeWrapper<InstagramComparePostDetails>;
  InstagramComparePosts: ResolverTypeWrapper<InstagramComparePosts>;
  InstagramComparePostsData: ResolverTypeWrapper<InstagramComparePostsData>;
  InstagramComparePrimaryAccount: ResolverTypeWrapper<InstagramComparePrimaryAccount>;
  InstagramCompareSecondaryAccount: ResolverTypeWrapper<InstagramCompareSecondaryAccount>;
  InstagramDashboardFollowerAnalytics: ResolverTypeWrapper<InstagramDashboardFollowerAnalytics>;
  InstagramDashboardOverview: ResolverTypeWrapper<InstagramDashboardOverview>;
  InstagramDashboardPostAnalytics: ResolverTypeWrapper<InstagramDashboardPostAnalytics>;
  InstagramDataForReconnectPayload: ResolverTypeWrapper<InstagramDataForReconnectPayload>;
  InstagramFeedPost: ResolverTypeWrapper<InstagramFeedPost>;
  InstagramFeedPostOrderBy: InstagramFeedPostOrderBy;
  InstagramFeedPostSortField: InstagramFeedPostSortField;
  InstagramFollowerGenderRate: ResolverTypeWrapper<InstagramFollowerGenderRate>;
  InstagramFollowersAgeGroup: ResolverTypeWrapper<InstagramFollowersAgeGroup>;
  InstagramFollowersAgeRate: ResolverTypeWrapper<InstagramFollowersAgeRate>;
  InstagramFollowersCountryRate: ResolverTypeWrapper<InstagramFollowersCountryRate>;
  InstagramFollowersRegionRate: ResolverTypeWrapper<InstagramFollowersRegionRate>;
  InstagramHashtagFeedPost: ResolverTypeWrapper<InstagramHashtagFeedPost>;
  InstagramHashtagFeedPostV2: ResolverTypeWrapper<InstagramHashtagFeedPostV2>;
  InstagramHashtagFeedPostsPayload: ResolverTypeWrapper<InstagramHashtagFeedPostsPayload>;
  InstagramHashtagPostAnalysisDetails: ResolverTypeWrapper<InstagramHashtagPostAnalysisDetails>;
  InstagramHashtagPostDetails: ResolverTypeWrapper<InstagramHashtagPostDetails>;
  InstagramHashtagPosts: ResolverTypeWrapper<InstagramHashtagPosts>;
  InstagramHashtagPostsData: ResolverTypeWrapper<InstagramHashtagPostsData>;
  InstagramHashtagPostsPayload: ResolverTypeWrapper<InstagramHashtagPostsPayload>;
  InstagramHashtagsMetricsData: ResolverTypeWrapper<InstagramHashtagsMetricsData>;
  InstagramHashtagsOverview: ResolverTypeWrapper<InstagramHashtagsOverview>;
  InstagramHashtagsOverviewHistory: ResolverTypeWrapper<InstagramHashtagsOverviewHistory>;
  InstagramHashtagsOverviewSummary: ResolverTypeWrapper<InstagramHashtagsOverviewSummary>;
  InstagramHashtagsOverviewSummaryData: ResolverTypeWrapper<InstagramHashtagsOverviewSummaryData>;
  InstagramInfluencerSearchResultV3: ResolverTypeWrapper<InstagramInfluencerSearchResultV3>;
  InstagramInfluencerV3: ResolverTypeWrapper<InstagramInfluencerV3>;
  InstagramInteractionCommonPostData: ResolverTypeWrapper<InstagramInteractionCommonPostData>;
  InstagramInteractionCommonTaggedPostData: ResolverTypeWrapper<InstagramInteractionCommonTaggedPostData>;
  InstagramInteractionOverview: ResolverTypeWrapper<InstagramInteractionOverview>;
  InstagramInteractionOverviewHistory: ResolverTypeWrapper<InstagramInteractionOverviewHistory>;
  InstagramInteractionOverviewSummary: ResolverTypeWrapper<InstagramInteractionOverviewSummary>;
  InstagramInteractionOverviewSummaryData: ResolverTypeWrapper<InstagramInteractionOverviewSummaryData>;
  InstagramInteractionPost: ResolverTypeWrapper<InstagramInteractionPost>;
  InstagramInteractionPostDetails: ResolverTypeWrapper<InstagramInteractionPostDetails>;
  InstagramInteractionPosts: ResolverTypeWrapper<InstagramInteractionPosts>;
  InstagramInteractionTaggedPost: ResolverTypeWrapper<InstagramInteractionTaggedPost>;
  InstagramInteractionUserAnalytics: ResolverTypeWrapper<InstagramInteractionUserAnalytics>;
  InstagramInteractionUserAnalyticsDetails: ResolverTypeWrapper<InstagramInteractionUserAnalyticsDetails>;
  InstagramInteractionUserAnalyticsDetailsAndCount: ResolverTypeWrapper<InstagramInteractionUserAnalyticsDetailsAndCount>;
  InstagramInteractionUserOrderBy: InstagramInteractionUserOrderBy;
  InstagramInteractionUserSortField: InstagramInteractionUserSortField;
  InstagramPopularHashtags: ResolverTypeWrapper<InstagramPopularHashtags>;
  InstagramPostAverageEngagement: ResolverTypeWrapper<InstagramPostAverageEngagement>;
  InstagramPostSortInput: InstagramPostSortInput;
  InstagramPostSortOrder: InstagramPostSortOrder;
  InstagramPostsHabit: ResolverTypeWrapper<InstagramPostsHabit>;
  InstagramReelPost: ResolverTypeWrapper<InstagramReelPost>;
  InstagramReelPostItem: ResolverTypeWrapper<InstagramReelPostItem>;
  InstagramReelPostOrderBy: InstagramReelPostOrderBy;
  InstagramReelPostSortField: InstagramReelPostSortField;
  InstagramStoryPost: ResolverTypeWrapper<InstagramStoryPost>;
  InstagramStoryPostOrderBy: InstagramStoryPostOrderBy;
  InstagramStoryPostSortField: InstagramStoryPostSortField;
  InstagramUGCAddedHashtagPayload: ResolverTypeWrapper<InstagramUGCAddedHashtagPayload>;
  InstagramUGCManagementBasicInfoPayload: ResolverTypeWrapper<InstagramUGCManagementBasicInfoPayload>;
  InstagramUGCManagementListPostsCountPayload: ResolverTypeWrapper<InstagramUGCManagementListPostsCountPayload>;
  InstagramUGCManagementListPostsItemPayload: ResolverTypeWrapper<InstagramUGCManagementListPostsItemPayload>;
  InstagramUGCManagementListPostsPayload: ResolverTypeWrapper<InstagramUGCManagementListPostsPayload>;
  InstagramUGCManagementPostDetailsPayload: ResolverTypeWrapper<InstagramUGCManagementPostDetailsPayload>;
  InstagramUGCPostsSortInput: InstagramUGCPostsSortInput;
  InstagramUGCPostsSortOrder: InstagramUGCPostsSortOrder;
  InstagramUserHashtag: ResolverTypeWrapper<InstagramUserHashtag>;
  InstagramUserHashtagListPostsItemPayload: ResolverTypeWrapper<InstagramUserHashtagListPostsItemPayload>;
  InstagramUserHashtagListPostsPayload: ResolverTypeWrapper<InstagramUserHashtagListPostsPayload>;
  InstagramUserHashtagPayload: ResolverTypeWrapper<InstagramUserHashtagPayload>;
  InstagramUserHashtagPostsSortInput: InstagramUserHashtagPostsSortInput;
  InstagramUserHashtagPostsSortOrder: InstagramUserHashtagPostsSortOrder;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  IsInfluencerUUUM: ResolverTypeWrapper<IsInfluencerUUUM>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  JoinAutoManagedCampaignInput: JoinAutoManagedCampaignInput;
  JoinAutoManagedCampaignPayload: ResolverTypeWrapper<JoinAutoManagedCampaignPayload>;
  JoinMarketplaceByStaff: ResolverTypeWrapper<JoinMarketplaceByStaff>;
  JoinMarketplaceByStaffInput: JoinMarketplaceByStaffInput;
  JoinMarketplaceV2: ResolverTypeWrapper<JoinMarketplaceV2>;
  JoinMarketplaceV2Input: JoinMarketplaceV2Input;
  JoinTiktokSpecialCampaign: ResolverTypeWrapper<JoinTiktokSpecialCampaign>;
  JoinTiktokSpecialCampaignInput: JoinTiktokSpecialCampaignInput;
  JoinableAccount: ResolverTypeWrapper<JoinableAccount>;
  JoinableFbAccount: ResolverTypeWrapper<JoinableFbAccount>;
  JoinableInstagramAccount: ResolverTypeWrapper<JoinableInstagramAccount>;
  JoinablePromotionMethodEmailNewsLetterPayload: ResolverTypeWrapper<JoinablePromotionMethodEmailNewsLetterPayload>;
  JoinablePromotionMethodMobileAppPayload: ResolverTypeWrapper<JoinablePromotionMethodMobileAppPayload>;
  JoinablePromotionMethodOfflinePayload: ResolverTypeWrapper<JoinablePromotionMethodOfflinePayload>;
  JoinablePromotionMethodPodCastPayload: ResolverTypeWrapper<JoinablePromotionMethodPodCastPayload>;
  JoinablePromotionMethodSocialAccountPayload: ResolverTypeWrapper<JoinablePromotionMethodSocialAccountPayload>;
  JoinablePromotionMethodWebsitePayload: ResolverTypeWrapper<JoinablePromotionMethodWebsitePayload>;
  JoinedCampaign: ResolverTypeWrapper<JoinedCampaign>;
  JoinedPromotionMethodEmailNewsLetterPayload: ResolverTypeWrapper<JoinedPromotionMethodEmailNewsLetterPayload>;
  JoinedPromotionMethodMobileAppPayload: ResolverTypeWrapper<JoinedPromotionMethodMobileAppPayload>;
  JoinedPromotionMethodOfflinePayload: ResolverTypeWrapper<JoinedPromotionMethodOfflinePayload>;
  JoinedPromotionMethodPodCastPayload: ResolverTypeWrapper<JoinedPromotionMethodPodCastPayload>;
  JoinedPromotionMethodSocialAccountPayload: ResolverTypeWrapper<JoinedPromotionMethodSocialAccountPayload>;
  JoinedPromotionMethodWebsitePayload: ResolverTypeWrapper<JoinedPromotionMethodWebsitePayload>;
  LineBroadcast: ResolverTypeWrapper<LineBroadcast>;
  LineBroadcastFloatValue: ResolverTypeWrapper<LineBroadcastFloatValue>;
  LineBroadcastIntValue: ResolverTypeWrapper<LineBroadcastIntValue>;
  LineBroadcastStatus: LineBroadcastStatus;
  LineChat: ResolverTypeWrapper<LineChat>;
  LineChatPIC: ResolverTypeWrapper<LineChatPIC>;
  LineContentItemInput: LineContentItemInput;
  LineContentItemPayload: ResolverTypeWrapper<LineContentItemPayload>;
  LineMessageType: LineMessageType;
  LinkBioBackground: ResolverTypeWrapper<LinkBioBackground>;
  LinkBioPageColor: ResolverTypeWrapper<LinkBioPageColor>;
  LinkCmsChannelAssets: ResolverTypeWrapper<LinkCmsChannelAssets>;
  LinkCmsChannelAssetsInput: LinkCmsChannelAssetsInput;
  Logo: ResolverTypeWrapper<Logo>;
  Long: ResolverTypeWrapper<Scalars['Long']>;
  MadeMarketplaceCampaignPost: ResolverTypeWrapper<MadeMarketplaceCampaignPost>;
  MadeMarketplaceCampaignPostInput: MadeMarketplaceCampaignPostInput;
  MajorCategory: ResolverTypeWrapper<MajorCategory>;
  MarkReadNotificationByAppInput: MarkReadNotificationByAppInput;
  MarkReadNotificationPayload: ResolverTypeWrapper<MarkReadNotificationPayload>;
  Marketplace: ResolverTypeWrapper<Marketplace>;
  MarketplaceAffiliateCommissionFeeInput: MarketplaceAffiliateCommissionFeeInput;
  MarketplaceAffiliateCommissionInfluencer: ResolverTypeWrapper<MarketplaceAffiliateCommissionInfluencer>;
  MarketplaceAffiliateCommissionRateInput: MarketplaceAffiliateCommissionRateInput;
  MarketplaceAffiliateCommissionStatus: MarketplaceAffiliateCommissionStatus;
  MarketplaceAffiliateJoinedInfluencer: ResolverTypeWrapper<MarketplaceAffiliateJoinedInfluencer>;
  MarketplaceAffiliatePayoutPeriodInput: MarketplaceAffiliatePayoutPeriodInput;
  MarketplaceAffiliateReportByDate: ResolverTypeWrapper<MarketplaceAffiliateReportByDate>;
  MarketplaceAffiliateReportByInfluencer: ResolverTypeWrapper<MarketplaceAffiliateReportByInfluencer>;
  MarketplaceAffiliateReportByInfluencerField: MarketplaceAffiliateReportByInfluencerField;
  MarketplaceAffiliateReportByInfluencerOrderBy: MarketplaceAffiliateReportByInfluencerOrderBy;
  MarketplaceAffiliateReportForInfluencer: ResolverTypeWrapper<MarketplaceAffiliateReportForInfluencer>;
  MarketplaceAffiliateReportSummary: ResolverTypeWrapper<MarketplaceAffiliateReportSummary>;
  MarketplaceAffiliateReportSummaryForInfluencer: ResolverTypeWrapper<MarketplaceAffiliateReportSummaryForInfluencer>;
  MarketplaceAffiliateStatsItem: ResolverTypeWrapper<MarketplaceAffiliateStatsItem>;
  MarketplaceCampaignAppliedStatus: MarketplaceCampaignAppliedStatus;
  MarketplaceCampaignCouponTrackingInput: MarketplaceCampaignCouponTrackingInput;
  MarketplaceCampaignCouponTrackingPayload: ResolverTypeWrapper<MarketplaceCampaignCouponTrackingPayload>;
  MarketplaceCampaignDetailType: MarketplaceCampaignDetailType;
  MarketplaceCampaignDraftPostStatus: MarketplaceCampaignDraftPostStatus;
  MarketplaceCampaignParticipateStatus: MarketplaceCampaignParticipateStatus;
  MarketplaceCampaignPostHistoryStatus: MarketplaceCampaignPostHistoryStatus;
  MarketplaceCampaignPostReportForInfluencer: ResolverTypeWrapper<MarketplaceCampaignPostReportForInfluencer>;
  MarketplaceCampaignPostStatus: MarketplaceCampaignPostStatus;
  MarketplaceCampaignPostStatusForAdvertiser: MarketplaceCampaignPostStatusForAdvertiser;
  MarketplaceCampaignPostStatusForSearch: MarketplaceCampaignPostStatusForSearch;
  MarketplaceCampaignRejectedStatus: MarketplaceCampaignRejectedStatus;
  MarketplaceCampaignReport: ResolverTypeWrapper<MarketplaceCampaignReport>;
  MarketplaceCampaignRevenueType: MarketplaceCampaignRevenueType;
  MarketplaceCampaignStatus: MarketplaceCampaignStatus;
  MarketplaceCampaignType: MarketplaceCampaignType;
  MarketplaceDetail: ResolverTypeWrapper<MarketplaceDetail>;
  MarketplaceDetailForInfluencer: ResolverTypeWrapper<MarketplaceDetailForInfluencer>;
  MarketplaceDraftPostDetail: ResolverTypeWrapper<MarketplaceDraftPostDetail>;
  MarketplaceDraftPostForInfluencer: ResolverTypeWrapper<MarketplaceDraftPostForInfluencer>;
  MarketplaceForReorder: ResolverTypeWrapper<MarketplaceForReorder>;
  MarketplaceInfluencer: ResolverTypeWrapper<MarketplaceInfluencer>;
  MarketplaceInfluencerRevenueInput: MarketplaceInfluencerRevenueInput;
  MarketplaceJoinableAccountsPayload: ResolverTypeWrapper<MarketplaceJoinableAccountsPayload>;
  MarketplaceJoinablePromotionMethodsPayload: ResolverTypeWrapper<MarketplaceJoinablePromotionMethodsPayload>;
  MarketplaceJoinedAccountPayload: ResolverTypeWrapper<MarketplaceJoinedAccountPayload>;
  MarketplaceJoinedMethodsInput: MarketplaceJoinedMethodsInput;
  MarketplaceJoinedMethodsPayload: ResolverTypeWrapper<MarketplaceJoinedMethodsPayload>;
  MarketplaceJoinedMethodsPayloadV2: ResolverTypeWrapper<MarketplaceJoinedMethodsPayloadV2>;
  MarketplacePost: ResolverTypeWrapper<MarketplacePost>;
  MarketplacePostComment: ResolverTypeWrapper<MarketplacePostComment>;
  MarketplacePostDetail: ResolverTypeWrapper<MarketplacePostDetail>;
  MarketplacePostDetailInfluencer: ResolverTypeWrapper<MarketplacePostDetailInfluencer>;
  MarketplacePostHistory: ResolverTypeWrapper<MarketplacePostHistory>;
  MarketplacePostInfluencer: ResolverTypeWrapper<MarketplacePostInfluencer>;
  MarketplacePostNoRevenue: ResolverTypeWrapper<MarketplacePostNoRevenue>;
  MarketplacePostsNoRevenue: ResolverTypeWrapper<MarketplacePostsNoRevenue>;
  MarketplaceRejectedAdvertiserInfo: ResolverTypeWrapper<MarketplaceRejectedAdvertiserInfo>;
  MarketplaceRevenueItem: ResolverTypeWrapper<MarketplaceRevenueItem>;
  MarketplaceTracking: ResolverTypeWrapper<MarketplaceTracking>;
  MarketplaceTrackingPostUrlForInfluencer: ResolverTypeWrapper<MarketplaceTrackingPostUrlForInfluencer>;
  MaterialName: ResolverTypeWrapper<MaterialName>;
  MediaItemInput: MediaItemInput;
  MediaItemPayload: ResolverTypeWrapper<MediaItemPayload>;
  MediaType: MediaType;
  MessageTemplateEvent: ResolverTypeWrapper<MessageTemplateEvent>;
  MessageTemplateItem: ResolverTypeWrapper<MessageTemplateItem>;
  MinorCategory: ResolverTypeWrapper<MinorCategory>;
  MobileVersion: ResolverTypeWrapper<MobileVersion>;
  Mutation: ResolverTypeWrapper<{}>;
  NeedReconnectForFacebookPayload: ResolverTypeWrapper<NeedReconnectForFacebookPayload>;
  NeedReviewMarketplacePost: ResolverTypeWrapper<NeedReviewMarketplacePost>;
  NetSuiteCustomerData: ResolverTypeWrapper<NetSuiteCustomerData>;
  NetSuiteCustomerDataForSearch: ResolverTypeWrapper<NetSuiteCustomerDataForSearch>;
  NetSuiteVendorData: ResolverTypeWrapper<NetSuiteVendorData>;
  NetSuiteVendorDataForSearch: ResolverTypeWrapper<NetSuiteVendorDataForSearch>;
  NetSuiteVerifiedStatus: NetSuiteVerifiedStatus;
  NewAutoManagedCampaignForInfluencerForYourJob: ResolverTypeWrapper<NewAutoManagedCampaignForInfluencerForYourJob>;
  NewCampaignPostReport: ResolverTypeWrapper<NewCampaignPostReport>;
  NewCampaignReportSummary: ResolverTypeWrapper<NewCampaignReportSummary>;
  NewCampaignStatsItemRow: ResolverTypeWrapper<NewCampaignStatsItemRow>;
  NewEngagementCampaignForInfluencerForYourJob: ResolverTypeWrapper<NewEngagementCampaignForInfluencerForYourJob>;
  NewMarketplaceCampaignForInfluencerForYourJob: ResolverTypeWrapper<NewMarketplaceCampaignForInfluencerForYourJob>;
  NewTiktokSpecialCampaignForInfluencerForYourJob: ResolverTypeWrapper<NewTiktokSpecialCampaignForInfluencerForYourJob>;
  NotificationId: NotificationId;
  NotificationPayload: ResolverTypeWrapper<NotificationPayload>;
  NotificationsByAppInput: NotificationsByAppInput;
  NotificationsPayload: ResolverTypeWrapper<NotificationsPayload>;
  ORDER: ORDER;
  ObtainJSONWebTokenInput: ObtainJSONWebTokenInput;
  ObtainJSONWebTokenPayload: ResolverTypeWrapper<ObtainJSONWebTokenPayload>;
  OpenAIMessage: ResolverTypeWrapper<OpenAIMessage>;
  OpenAINextAction: OpenAINextAction;
  OpenAIRunSourceFrom: OpenAIRunSourceFrom;
  OperatedUserInfo: ResolverTypeWrapper<OperatedUserInfo>;
  OptionInput: OptionInput;
  Order: Order;
  PackageCompany: ResolverTypeWrapper<PackageCompany>;
  PackageInfluencer: ResolverTypeWrapper<PackageInfluencer>;
  PackageProposal: ResolverTypeWrapper<PackageProposal>;
  PackageProposalInfluencerBudgetInput: PackageProposalInfluencerBudgetInput;
  PackageProposalSocialAcccountBudgetInput: PackageProposalSocialAcccountBudgetInput;
  PackageProposalSummary: ResolverTypeWrapper<PackageProposalSummary>;
  PackageSocialAccount: ResolverTypeWrapper<PackageSocialAccount>;
  PackageSummary: ResolverTypeWrapper<PackageSummary>;
  PackageSummaryV2: ResolverTypeWrapper<PackageSummaryV2>;
  ParticipationForm: ResolverTypeWrapper<ParticipationForm>;
  Partner: ResolverTypeWrapper<Partner>;
  PartnerAgencyUserWithRole: ResolverTypeWrapper<PartnerAgencyUserWithRole>;
  PartnerAgencyWithCompanies: ResolverTypeWrapper<PartnerAgencyWithCompanies>;
  PartnerAgencyWithNSVerification: ResolverTypeWrapper<PartnerAgencyWithNSVerification>;
  PartnerAgencyWithPermission: ResolverTypeWrapper<PartnerAgencyWithPermission>;
  PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload: ResolverTypeWrapper<PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload>;
  PayableCampaign: ResolverTypeWrapper<PayableCampaign>;
  Payment: ResolverTypeWrapper<Payment>;
  PaymentInfluencerAccount: ResolverTypeWrapper<PaymentInfluencerAccount>;
  PaymentInformation: ResolverTypeWrapper<PaymentInformation>;
  PaymentInformationDetails: ResolverTypeWrapper<PaymentInformationDetails>;
  PaymentInformationInput: PaymentInformationInput;
  PaymentPackageStatus: PaymentPackageStatus;
  PaymentPackageStatusResponse: ResolverTypeWrapper<PaymentPackageStatusResponse>;
  PaymentPlanName: PaymentPlanName;
  PaymentPlanType: PaymentPlanType;
  PaymentPriceType: PaymentPriceType;
  PaymentRequest: ResolverTypeWrapper<PaymentRequest>;
  PaymentRequestStatus: PaymentRequestStatus;
  PaymentRequestStatusForInfluencer: PaymentRequestStatusForInfluencer;
  PersonalUserDetailPayload: ResolverTypeWrapper<PersonalUserDetailPayload>;
  PicName: ResolverTypeWrapper<PicName>;
  PicUser: ResolverTypeWrapper<PicUser>;
  PlainTextMessageInput: PlainTextMessageInput;
  PlainTextMessagePayload: ResolverTypeWrapper<PlainTextMessagePayload>;
  PostByHashtag: ResolverTypeWrapper<PostByHashtag>;
  PostListSortField: PostListSortField;
  PostListSortFieldV3: PostListSortFieldV3;
  PostOrderBy: PostOrderBy;
  PostOrderByV3: PostOrderByV3;
  PostsByTime: ResolverTypeWrapper<PostsByTime>;
  PostsForHashtagDashboard: ResolverTypeWrapper<PostsForHashtagDashboard>;
  PostsMetricsGridReport: ResolverTypeWrapper<PostsMetricsGridReport>;
  PricingTable: ResolverTypeWrapper<PricingTable>;
  PricingTableItem: ResolverTypeWrapper<PricingTableItem>;
  PrivatePartnerInfluencer: ResolverTypeWrapper<PrivatePartnerInfluencer>;
  PrivateTalentInfluencer: ResolverTypeWrapper<PrivateTalentInfluencer>;
  PromotionMethodEmailNewsLetterForProfile: ResolverTypeWrapper<PromotionMethodEmailNewsLetterForProfile>;
  PromotionMethodEmailNewsLetterPayload: ResolverTypeWrapper<PromotionMethodEmailNewsLetterPayload>;
  PromotionMethodInput: PromotionMethodInput;
  PromotionMethodMobileAppForProfile: ResolverTypeWrapper<PromotionMethodMobileAppForProfile>;
  PromotionMethodMobileAppPayload: ResolverTypeWrapper<PromotionMethodMobileAppPayload>;
  PromotionMethodOfflineForProfile: ResolverTypeWrapper<PromotionMethodOfflineForProfile>;
  PromotionMethodOfflinePayload: ResolverTypeWrapper<PromotionMethodOfflinePayload>;
  PromotionMethodPodCastForProfile: ResolverTypeWrapper<PromotionMethodPodCastForProfile>;
  PromotionMethodPodCastPayload: ResolverTypeWrapper<PromotionMethodPodCastPayload>;
  PromotionMethodWebsiteForProfile: ResolverTypeWrapper<PromotionMethodWebsiteForProfile>;
  PromotionMethodWebsitePayload: ResolverTypeWrapper<PromotionMethodWebsitePayload>;
  PromotionMobileAppType: PromotionMobileAppType;
  ProposalDownloadFormat: ProposalDownloadFormat;
  ProposalInfluencer: ResolverTypeWrapper<ProposalInfluencer>;
  ProposalJobDescriptionBreakDownMode: ProposalJobDescriptionBreakDownMode;
  ProposalJobSummary: ResolverTypeWrapper<ProposalJobSummary>;
  ProposalSocialAccount: ResolverTypeWrapper<ProposalSocialAccount>;
  Province: ResolverTypeWrapper<Province>;
  ProvincesPayload: ResolverTypeWrapper<ProvincesPayload>;
  PublicBioSection: ResolverTypeWrapper<PublicBioSection>;
  PublicBioTheme: ResolverTypeWrapper<PublicBioTheme>;
  PublicLinkBioBackground: ResolverTypeWrapper<PublicLinkBioBackground>;
  PublicLinkBioPageColor: ResolverTypeWrapper<PublicLinkBioPageColor>;
  PublishInfluencerBioInput: PublishInfluencerBioInput;
  PublishInfluencerBioPayload: ResolverTypeWrapper<PublishInfluencerBioPayload>;
  Query: ResolverTypeWrapper<{}>;
  QuerySocialAccountType: QuerySocialAccountType;
  QuestionType: QuestionType;
  QuestionsInput: QuestionsInput;
  RateCardsInput: RateCardsInput;
  ReSendBcaRequestInput: ReSendBcaRequestInput;
  ReSendBcaRequestPayload: ResolverTypeWrapper<ReSendBcaRequestPayload>;
  ReadCampaignIdentifier: ResolverTypeWrapper<ReadCampaignIdentifier>;
  ReadPackage: ResolverTypeWrapper<ReadPackage>;
  ReadPackageAccount: ResolverTypeWrapper<ReadPackageAccount>;
  ReadPackageInfluencers: ResolverTypeWrapper<ReadPackageInfluencers>;
  ReadSocialAccountForPackage: ResolverTypeWrapper<ReadSocialAccountForPackage>;
  ReadTutorials: ResolverTypeWrapper<ReadTutorials>;
  RechargeAdvertiserAccount: ResolverTypeWrapper<RechargeAdvertiserAccount>;
  RechargeAdvertiserAccountInput: RechargeAdvertiserAccountInput;
  RecipientDetail: ResolverTypeWrapper<RecipientDetail>;
  RecipientItemInput: RecipientItemInput;
  RecipientItemPayload: ResolverTypeWrapper<RecipientItemPayload>;
  RecipientType: RecipientType;
  ReconnectTiktokAdAccount: ResolverTypeWrapper<ReconnectTiktokAdAccount>;
  ReconnectTiktokAdAccountInput: ReconnectTiktokAdAccountInput;
  ReconnectedAccountForLinkBio: ResolverTypeWrapper<ReconnectedAccountForLinkBio>;
  RedirectType: RedirectType;
  RefreshPasswordInput: RefreshPasswordInput;
  RefreshPasswordPayload: ResolverTypeWrapper<RefreshPasswordPayload>;
  RefreshProfileAccountAvailabilityInput: RefreshProfileAccountAvailabilityInput;
  RefreshProfileAccountAvailabilityPayload: ResolverTypeWrapper<RefreshProfileAccountAvailabilityPayload>;
  RefreshProfileAccountInput: RefreshProfileAccountInput;
  RefreshProfileAccountPayload: ResolverTypeWrapper<RefreshProfileAccountPayload>;
  RefreshProfileAvailabilityStatus: RefreshProfileAvailabilityStatus;
  RefreshTwitterAccountAvailabilityInput: RefreshTwitterAccountAvailabilityInput;
  RefreshTwitterAccountAvailabilityPayload: ResolverTypeWrapper<RefreshTwitterAccountAvailabilityPayload>;
  RefreshTwitterAccountInput: RefreshTwitterAccountInput;
  RefreshTwitterAccountPayload: ResolverTypeWrapper<RefreshTwitterAccountPayload>;
  Region: ResolverTypeWrapper<Region>;
  RegionName: ResolverTypeWrapper<RegionName>;
  RegisterPushToken: ResolverTypeWrapper<RegisterPushToken>;
  RegisterPushTokenInput: RegisterPushTokenInput;
  RejectEngagementDraftPost: ResolverTypeWrapper<RejectEngagementDraftPost>;
  RejectEngagementDraftPostInput: RejectEngagementDraftPostInput;
  RejectMarketplaceCampaign: ResolverTypeWrapper<RejectMarketplaceCampaign>;
  RejectMarketplaceCampaignInput: RejectMarketplaceCampaignInput;
  RejectMarketplaceDraftPost: ResolverTypeWrapper<RejectMarketplaceDraftPost>;
  RejectMarketplaceDraftPostInput: RejectMarketplaceDraftPostInput;
  RejectTikTokSpecialCampaign: ResolverTypeWrapper<RejectTikTokSpecialCampaign>;
  RejectTikTokSpecialCampaignInput: RejectTikTokSpecialCampaignInput;
  RemoveAllFormResponsesInput: RemoveAllFormResponsesInput;
  RemoveAllFormResponsesPayload: ResolverTypeWrapper<RemoveAllFormResponsesPayload>;
  RemoveAllSelectedInfluencers: ResolverTypeWrapper<RemoveAllSelectedInfluencers>;
  RemoveAllSelectedInfluencersInput: RemoveAllSelectedInfluencersInput;
  RemoveCreatorStaffInput: RemoveCreatorStaffInput;
  RemoveCreatorStaffPayload: ResolverTypeWrapper<RemoveCreatorStaffPayload>;
  RemoveEmailsInput: RemoveEmailsInput;
  RemoveEmailsPayload: ResolverTypeWrapper<RemoveEmailsPayload>;
  RemoveFansInput: RemoveFansInput;
  RemoveFansPayload: ResolverTypeWrapper<RemoveFansPayload>;
  RemoveFormResponseInput: RemoveFormResponseInput;
  RemoveFormResponsePayload: ResolverTypeWrapper<RemoveFormResponsePayload>;
  RemoveFormsInput: RemoveFormsInput;
  RemoveFormsPayload: ResolverTypeWrapper<RemoveFormsPayload>;
  RemoveSelectedInfluencers: ResolverTypeWrapper<RemoveSelectedInfluencers>;
  RemoveSelectedInfluencersInput: RemoveSelectedInfluencersInput;
  RemoveShopifyAccountInput: RemoveShopifyAccountInput;
  RemoveShopifyAccountPayload: ResolverTypeWrapper<RemoveShopifyAccountPayload>;
  ReorderMarketplaceCampaigns: ResolverTypeWrapper<ReorderMarketplaceCampaigns>;
  ReportEngagementPostStatus: ResolverTypeWrapper<ReportEngagementPostStatus>;
  ReportEngagementPostStatusInput: ReportEngagementPostStatusInput;
  ReportForInfluencer: ResolverTypeWrapper<ReportForInfluencer>;
  ReportMarketplacePostStatus: ResolverTypeWrapper<ReportMarketplacePostStatus>;
  ReportMarketplacePostStatusInput: ReportMarketplacePostStatusInput;
  ReportTiktokSpecialPostStatus: ResolverTypeWrapper<ReportTiktokSpecialPostStatus>;
  ReportTiktokSpecialPostStatusInput: ReportTiktokSpecialPostStatusInput;
  RequestDeleteAccount: ResolverTypeWrapper<RequestDeleteAccount>;
  RequestDeleteAccountInput: RequestDeleteAccountInput;
  RequestGoogleSlideCustomisedReport: ResolverTypeWrapper<RequestGoogleSlideCustomisedReport>;
  RequestGoogleSlideReport: ResolverTypeWrapper<RequestGoogleSlideReport>;
  RequestInfluencerProfilePptx: ResolverTypeWrapper<RequestInfluencerProfilePptx>;
  RequestInfluencersProfilePptx: ResolverTypeWrapper<RequestInfluencersProfilePptx>;
  RequestInfluencersSocialAccountPptx: ResolverTypeWrapper<RequestInfluencersSocialAccountPptx>;
  RequestInstagramUGCManagementPostInput: RequestInstagramUGCManagementPostInput;
  RequestInstagramUGCManagementPostOutput: ResolverTypeWrapper<RequestInstagramUGCManagementPostOutput>;
  RequestPackageProposalExcel: ResolverTypeWrapper<RequestPackageProposalExcel>;
  RequestPackageProposalPptx: ResolverTypeWrapper<RequestPackageProposalPptx>;
  RequestPackageSpreadsheet: ResolverTypeWrapper<RequestPackageSpreadsheet>;
  RequestPowerpointCustomisedReport: ResolverTypeWrapper<RequestPowerpointCustomisedReport>;
  RequestPowerpointReport: ResolverTypeWrapper<RequestPowerpointReport>;
  RequestProposalExcel: ResolverTypeWrapper<RequestProposalExcel>;
  RequestProposalPptx: ResolverTypeWrapper<RequestProposalPptx>;
  RequestProposalSpreadsheet: ResolverTypeWrapper<RequestProposalSpreadsheet>;
  RequestUserProposalExcel: ResolverTypeWrapper<RequestUserProposalExcel>;
  RequestUserProposalPptx: ResolverTypeWrapper<RequestUserProposalPptx>;
  RequestedBCABrand: ResolverTypeWrapper<RequestedBCABrand>;
  RequestedBrandForBCA: ResolverTypeWrapper<RequestedBrandForBCA>;
  RequestedInstagramAccountForBCA: ResolverTypeWrapper<RequestedInstagramAccountForBCA>;
  ResendEmailForAdvertiser: ResolverTypeWrapper<ResendEmailForAdvertiser>;
  ResendEmailForAdvertiserInput: ResendEmailForAdvertiserInput;
  ResendInfluencerForgotPasswordEmailInput: ResendInfluencerForgotPasswordEmailInput;
  ResendInfluencerForgotPasswordEmailPayload: ResolverTypeWrapper<ResendInfluencerForgotPasswordEmailPayload>;
  ResendInfluencerSignUpEmailInput: ResendInfluencerSignUpEmailInput;
  ResendInfluencerSignUpEmailPayload: ResolverTypeWrapper<ResendInfluencerSignUpEmailPayload>;
  ResendPartnerEmails: ResolverTypeWrapper<ResendPartnerEmails>;
  ResendPartnerEmailsInput: ResendPartnerEmailsInput;
  ResetPasswordAndLoginPayload: ResolverTypeWrapper<ResetPasswordAndLoginPayload>;
  ResetPasswordInput: ResetPasswordInput;
  ResetPasswordPayload: ResolverTypeWrapper<ResetPasswordPayload>;
  ResolveChatInput: ResolveChatInput;
  ResolveChatPayload: ResolverTypeWrapper<ResolveChatPayload>;
  RichMessageInput: RichMessageInput;
  RichMessagePayload: ResolverTypeWrapper<RichMessagePayload>;
  SearchJobFilterParticipationType: SearchJobFilterParticipationType;
  SearchJobFilterRevenueCondition: SearchJobFilterRevenueCondition;
  SearchJobFilterRevenueType: SearchJobFilterRevenueType;
  SearchMode: SearchMode;
  SelectableBCABrands: ResolverTypeWrapper<SelectableBCABrands>;
  SelectableBrandForBCA: ResolverTypeWrapper<SelectableBrandForBCA>;
  SelectableInstagramAccountForBCA: ResolverTypeWrapper<SelectableInstagramAccountForBCA>;
  SelectableYoutubeAnalyticsChannel: ResolverTypeWrapper<SelectableYoutubeAnalyticsChannel>;
  SelectedInfluencers: SelectedInfluencers;
  SelectedOauthPageAndIgAccount: SelectedOauthPageAndIgAccount;
  SelectedSocialAccounts: SelectedSocialAccounts;
  SendBcaRequestInput: SendBcaRequestInput;
  SendBcaRequestPayload: ResolverTypeWrapper<SendBcaRequestPayload>;
  SendContact: ResolverTypeWrapper<SendContact>;
  SendContactInput: SendContactInput;
  SendGeminiMessage: ResolverTypeWrapper<SendGeminiMessage>;
  SendGeminiMessageInput: SendGeminiMessageInput;
  SendLineBroadcastMessageInput: SendLineBroadcastMessageInput;
  SendLineBroadcastMessagePayload: ResolverTypeWrapper<SendLineBroadcastMessagePayload>;
  SendMessageInput: SendMessageInput;
  SendMessagePayload: ResolverTypeWrapper<SendMessagePayload>;
  SendOpenAIMessage: ResolverTypeWrapper<SendOpenAIMessage>;
  SendOpenAIMessageInput: SendOpenAIMessageInput;
  ShopeeCustomCampaignInput: ShopeeCustomCampaignInput;
  ShopeeCustomCampaignPayload: ResolverTypeWrapper<ShopeeCustomCampaignPayload>;
  ShopifyAuthRedirectUrlInput: ShopifyAuthRedirectUrlInput;
  ShopifyAuthRedirectUrlPayload: ResolverTypeWrapper<ShopifyAuthRedirectUrlPayload>;
  ShopifyOrderPaymentStatus: ShopifyOrderPaymentStatus;
  ShopifyOrderShippingStatus: ShopifyOrderShippingStatus;
  ShopifyValidateAuthInput: ShopifyValidateAuthInput;
  ShopifyValidateAuthPayload: ResolverTypeWrapper<ShopifyValidateAuthPayload>;
  SignedUrlData: ResolverTypeWrapper<SignedUrlData>;
  SimilarPost: ResolverTypeWrapper<SimilarPost>;
  SocialAccount: ResolverTypeWrapper<SocialAccount>;
  SocialAccountDescription: ResolverTypeWrapper<SocialAccountDescription>;
  SocialAccountForProfileV2: ResolverTypeWrapper<SocialAccountForProfileV2>;
  SocialAccountInfluencerAudience: SocialAccountInfluencerAudience;
  SocialAccountInfluencerDemographicsAge: SocialAccountInfluencerDemographicsAge;
  SocialAccountInfluencerDemographicsGender: SocialAccountInfluencerDemographicsGender;
  SocialAccountInfluencerListSortField: SocialAccountInfluencerListSortField;
  SocialAccountInfluencerListSortFieldV2: SocialAccountInfluencerListSortFieldV2;
  SocialAccountInfluencerOrderBy: SocialAccountInfluencerOrderBy;
  SocialAccountInfluencerOrderByV2: SocialAccountInfluencerOrderByV2;
  SocialAccountPairInput: SocialAccountPairInput;
  SocialAccountPermissionsInput: SocialAccountPermissionsInput;
  SocialAccountPopularPost: ResolverTypeWrapper<SocialAccountPopularPost>;
  SocialAccountStatus: SocialAccountStatus;
  SocialAccountType: SocialAccountType;
  SocialAccountV2: ResolverTypeWrapper<SocialAccountV2>;
  SocialAuthCheckFacebookTokenPayload: ResolverTypeWrapper<SocialAuthCheckFacebookTokenPayload>;
  SocialAuthConnectableFacebookPageAnalyticsInput: SocialAuthConnectableFacebookPageAnalyticsInput;
  SocialAuthConnectableFacebookPageAnalyticsPayload: ResolverTypeWrapper<SocialAuthConnectableFacebookPageAnalyticsPayload>;
  SocialAuthConnectableInstagramAccountsAnalyticsInput: SocialAuthConnectableInstagramAccountsAnalyticsInput;
  SocialAuthConnectableInstagramAccountsAnalyticsPayload: ResolverTypeWrapper<SocialAuthConnectableInstagramAccountsAnalyticsPayload>;
  SocialAuthConnectableTwitterAccountAnalyticsInput: SocialAuthConnectableTwitterAccountAnalyticsInput;
  SocialAuthConnectableTwitterAccountAnalyticsOutput: ResolverTypeWrapper<SocialAuthConnectableTwitterAccountAnalyticsOutput>;
  SocialAuthForJoinCampaignInput: SocialAuthForJoinCampaignInput;
  SocialAuthForJoinCampaignPayload: ResolverTypeWrapper<SocialAuthForJoinCampaignPayload>;
  SocialAuthReConnectInstagramInput: SocialAuthReConnectInstagramInput;
  SocialAuthReConnectInstagramPayload: ResolverTypeWrapper<SocialAuthReConnectInstagramPayload>;
  SocialAuthReconnectFacebookPageAccountAnalyticsInput: SocialAuthReconnectFacebookPageAccountAnalyticsInput;
  SocialAuthReconnectFacebookPageAccountAnalyticsPayload: ResolverTypeWrapper<SocialAuthReconnectFacebookPageAccountAnalyticsPayload>;
  SocialAuthReconnectInstagramAccountAnalyticsInput: SocialAuthReconnectInstagramAccountAnalyticsInput;
  SocialAuthReconnectInstagramAccountAnalyticsPayload: ResolverTypeWrapper<SocialAuthReconnectInstagramAccountAnalyticsPayload>;
  SocialAuthReconnectTwitterAccountAnalyticsInput: SocialAuthReconnectTwitterAccountAnalyticsInput;
  SocialAuthReconnectTwitterAccountAnalyticsPayload: ResolverTypeWrapper<SocialAuthReconnectTwitterAccountAnalyticsPayload>;
  SocialAuthReconnectYouTubeAccountAnalyticsInput: SocialAuthReconnectYouTubeAccountAnalyticsInput;
  SocialAuthReconnectYouTubeAccountAnalyticsPayload: ResolverTypeWrapper<SocialAuthReconnectYouTubeAccountAnalyticsPayload>;
  SocialAuthRedirectUrlForInfluencerInput: SocialAuthRedirectUrlForInfluencerInput;
  SocialAuthRedirectUrlForInfluencerPayload: ResolverTypeWrapper<SocialAuthRedirectUrlForInfluencerPayload>;
  SocialAuthTalentSignInFacebookInput: SocialAuthTalentSignInFacebookInput;
  SocialAuthTalentSignInFacebookPayload: ResolverTypeWrapper<SocialAuthTalentSignInFacebookPayload>;
  SocialAuthTalentSignInInstagramInput: SocialAuthTalentSignInInstagramInput;
  SocialAuthTalentSignInInstagramPayload: ResolverTypeWrapper<SocialAuthTalentSignInInstagramPayload>;
  SocialAuthTalentSignInTwitterInput: SocialAuthTalentSignInTwitterInput;
  SocialAuthTalentSignInTwitterPayload: ResolverTypeWrapper<SocialAuthTalentSignInTwitterPayload>;
  SocialAuthTalentSignInYouTubeInput: SocialAuthTalentSignInYouTubeInput;
  SocialAuthTalentSignInYouTubePayload: ResolverTypeWrapper<SocialAuthTalentSignInYouTubePayload>;
  SocialMediaTypeForRateCard: SocialMediaTypeForRateCard;
  SocialPostType: SocialPostType;
  SponsoredPostOrderBy: SponsoredPostOrderBy;
  SponsoredPostSortField: SponsoredPostSortField;
  StaffComment: ResolverTypeWrapper<StaffComment>;
  StateUpdateEvent: ResolverTypeWrapper<StateUpdateEvent>;
  String: ResolverTypeWrapper<Scalars['String']>;
  StripeDeepLink: ResolverTypeWrapper<StripeDeepLink>;
  SubmitFormInput: SubmitFormInput;
  SubmitFormInternalInput: SubmitFormInternalInput;
  SubmitFormInternalPayload: ResolverTypeWrapper<SubmitFormInternalPayload>;
  SubmitFormPayload: ResolverTypeWrapper<SubmitFormPayload>;
  SubmitQuestion: SubmitQuestion;
  SubmitQuestionInternal: SubmitQuestionInternal;
  SubscribeAnalyticsPlan: ResolverTypeWrapper<SubscribeAnalyticsPlan>;
  SubscribeAnalyticsPlanInput: SubscribeAnalyticsPlanInput;
  SubscribeAnalyticsTrialPlan: ResolverTypeWrapper<SubscribeAnalyticsTrialPlan>;
  SubscriptionAvailableFeatures: ResolverTypeWrapper<SubscriptionAvailableFeatures>;
  SubscriptionCapabilities: ResolverTypeWrapper<SubscriptionCapabilities>;
  SubscriptionPlan: ResolverTypeWrapper<SubscriptionPlan>;
  SubscriptionPlanDetail: ResolverTypeWrapper<SubscriptionPlanDetail>;
  SubscriptionPlanForAdvertiserUserSettings: ResolverTypeWrapper<SubscriptionPlanForAdvertiserUserSettings>;
  SudoLimitedLogin: ResolverTypeWrapper<SudoLimitedLogin>;
  SudoLimitedLoginInput: SudoLimitedLoginInput;
  SwitchInfluencerForStaffInput: SwitchInfluencerForStaffInput;
  SwitchInfluencerForStaffPayload: ResolverTypeWrapper<SwitchInfluencerForStaffPayload>;
  SwitchRoleToAdmin: ResolverTypeWrapper<SwitchRoleToAdmin>;
  SwitchRoleToAdminInput: SwitchRoleToAdminInput;
  SwitchRoleToAdvertiser: ResolverTypeWrapper<SwitchRoleToAdvertiser>;
  SwitchRoleToAdvertiserInput: SwitchRoleToAdvertiserInput;
  SwitchRoleToAgency: ResolverTypeWrapper<SwitchRoleToAgency>;
  SwitchRoleToAgencyInput: SwitchRoleToAgencyInput;
  SwitchRoleToPartner: ResolverTypeWrapper<SwitchRoleToPartner>;
  SwitchRoleToPartnerInput: SwitchRoleToPartnerInput;
  SwitchRoleToStaff: ResolverTypeWrapper<SwitchRoleToStaff>;
  SwitchRoleToStaffInput: SwitchRoleToStaffInput;
  SwitchRoleToTalentAgency: ResolverTypeWrapper<SwitchRoleToTalentAgency>;
  SwitchRoleToTalentAgencyInput: SwitchRoleToTalentAgencyInput;
  TTCMStatus: TTCMStatus;
  TalentAgency: ResolverTypeWrapper<TalentAgency>;
  TalentAgencyOrPartner: ResolverTypeWrapper<TalentAgencyOrPartner>;
  TalentAgencyReconnectAnalyticsRedirectType: TalentAgencyReconnectAnalyticsRedirectType;
  TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload: ResolverTypeWrapper<TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload>;
  TalentAgencyUserWithRole: ResolverTypeWrapper<TalentAgencyUserWithRole>;
  TalentAgencyWithCompanies: ResolverTypeWrapper<TalentAgencyWithCompanies>;
  TalentAgencyWithNSVerification: ResolverTypeWrapper<TalentAgencyWithNSVerification>;
  TalentInfluencerDetailsForEditInput: TalentInfluencerDetailsForEditInput;
  TalentInfluencerDetailsForEditPayload: ResolverTypeWrapper<TalentInfluencerDetailsForEditPayload>;
  TextComponentInput: TextComponentInput;
  TextComponentPayload: ResolverTypeWrapper<TextComponentPayload>;
  ThreadsInfluencer: ResolverTypeWrapper<ThreadsInfluencer>;
  TikTokAdChartReport: ResolverTypeWrapper<TikTokAdChartReport>;
  TikTokAdReportOrderField: TikTokAdReportOrderField;
  TikTokAdReportSummary: ResolverTypeWrapper<TikTokAdReportSummary>;
  TikTokAdReportType: TikTokAdReportType;
  TikTokAdStatus: TikTokAdStatus;
  TikTokAdvertiserPlanFilter: TikTokAdvertiserPlanFilter;
  TikTokCampaignType: TikTokCampaignType;
  TikTokProductCategory: ResolverTypeWrapper<TikTokProductCategory>;
  TikTokProductCategoryName: TikTokProductCategoryName;
  TikTokSpecialAdPostInfo: ResolverTypeWrapper<TikTokSpecialAdPostInfo>;
  TikTokSpecialCampaign: ResolverTypeWrapper<TikTokSpecialCampaign>;
  TikTokSpecialCampaignChartReport: ResolverTypeWrapper<TikTokSpecialCampaignChartReport>;
  TikTokSpecialCampaignDetail: ResolverTypeWrapper<TikTokSpecialCampaignDetail>;
  TikTokSpecialCampaignDetailForInfluencer: ResolverTypeWrapper<TikTokSpecialCampaignDetailForInfluencer>;
  TikTokSpecialCampaignPost: ResolverTypeWrapper<TikTokSpecialCampaignPost>;
  TikTokSpecialCampaignPostReportForInfluencer: ResolverTypeWrapper<TikTokSpecialCampaignPostReportForInfluencer>;
  TikTokSpecialCampaignPostStatus: TikTokSpecialCampaignPostStatus;
  TikTokSpecialCampaignStatus: TikTokSpecialCampaignStatus;
  TikTokSpecialDraftAdPostInfo: ResolverTypeWrapper<TikTokSpecialDraftAdPostInfo>;
  TikTokSpecialJoinedAccountPayload: ResolverTypeWrapper<TikTokSpecialJoinedAccountPayload>;
  TikTokSpecialPackageInfluencer: ResolverTypeWrapper<TikTokSpecialPackageInfluencer>;
  TikTokSpecialPostDetail: ResolverTypeWrapper<TikTokSpecialPostDetail>;
  TikTokSpecialPostHistory: ResolverTypeWrapper<TikTokSpecialPostHistory>;
  TikTokSpecialPostHistoryStatus: TikTokSpecialPostHistoryStatus;
  TikTokSpecialReportOrderField: TikTokSpecialReportOrderField;
  TiktokAccountForProfileV2: ResolverTypeWrapper<TiktokAccountForProfileV2>;
  TiktokAdAccountInfo: ResolverTypeWrapper<TiktokAdAccountInfo>;
  TiktokAdAccountStatus: TiktokAdAccountStatus;
  TiktokAdCampaign: ResolverTypeWrapper<TiktokAdCampaign>;
  TiktokAdDetailReport: ResolverTypeWrapper<TiktokAdDetailReport>;
  TiktokAdGroup: ResolverTypeWrapper<TiktokAdGroup>;
  TiktokAdReportOrderBy: TiktokAdReportOrderBy;
  TiktokAdvertiserAccount: ResolverTypeWrapper<TiktokAdvertiserAccount>;
  TiktokAdvertiserPlanData: ResolverTypeWrapper<TiktokAdvertiserPlanData>;
  TiktokAdvertiserSummaryData: ResolverTypeWrapper<TiktokAdvertiserSummaryData>;
  TiktokAudienceSection: ResolverTypeWrapper<TiktokAudienceSection>;
  TiktokAuthRedirectUrl: ResolverTypeWrapper<TiktokAuthRedirectUrl>;
  TiktokBudgetAllocation: ResolverTypeWrapper<TiktokBudgetAllocation>;
  TiktokBusinessAccountInfo: ResolverTypeWrapper<TiktokBusinessAccountInfo>;
  TiktokBusinessCenterInfo: ResolverTypeWrapper<TiktokBusinessCenterInfo>;
  TiktokBusinessCenterType: TiktokBusinessCenterType;
  TiktokBusinessWarningStatus: TiktokBusinessWarningStatus;
  TiktokFollowerCountryRate: ResolverTypeWrapper<TiktokFollowerCountryRate>;
  TiktokFollowersAgeGroup: ResolverTypeWrapper<TiktokFollowersAgeGroup>;
  TiktokInfluencer: ResolverTypeWrapper<TiktokInfluencer>;
  TiktokInfluencerSearchResultV2: ResolverTypeWrapper<TiktokInfluencerSearchResultV2>;
  TiktokInfluencerV2: ResolverTypeWrapper<TiktokInfluencerV2>;
  TiktokPostDetailInfluencer: ResolverTypeWrapper<TiktokPostDetailInfluencer>;
  TiktokPostInfluencer: ResolverTypeWrapper<TiktokPostInfluencer>;
  TiktokSpecialCampaignInfo: ResolverTypeWrapper<TiktokSpecialCampaignInfo>;
  TiktokSpecialCampaignReport: ResolverTypeWrapper<TiktokSpecialCampaignReport>;
  TiktokSpecialCampaignReportSummary: ResolverTypeWrapper<TiktokSpecialCampaignReportSummary>;
  TiktokSpecialInfluencerReport: ResolverTypeWrapper<TiktokSpecialInfluencerReport>;
  TiktokSpecialJoinableAccountsPayload: ResolverTypeWrapper<TiktokSpecialJoinableAccountsPayload>;
  TiktokSpecialPostInfo: ResolverTypeWrapper<TiktokSpecialPostInfo>;
  TiktokSpecialReportOrderBy: TiktokSpecialReportOrderBy;
  TiktokSpecialStatsItemRow: ResolverTypeWrapper<TiktokSpecialStatsItemRow>;
  TiktokSpecialSummaryDaily: ResolverTypeWrapper<TiktokSpecialSummaryDaily>;
  TiktokUserHashtag: ResolverTypeWrapper<TiktokUserHashtag>;
  TiktokUserHashtagListPostsPayload: ResolverTypeWrapper<TiktokUserHashtagListPostsPayload>;
  TiktokUserHashtagPayload: ResolverTypeWrapper<TiktokUserHashtagPayload>;
  TiktokUserKeyword: ResolverTypeWrapper<TiktokUserKeyword>;
  TiktokUserKeywordListPostsPayload: ResolverTypeWrapper<TiktokUserKeywordListPostsPayload>;
  TiktokUserKeywordPayload: ResolverTypeWrapper<TiktokUserKeywordPayload>;
  TiktokUserTagListPostsItemPayload: ResolverTypeWrapper<TiktokUserTagListPostsItemPayload>;
  TiktokUserTagPostDetailsPayload: ResolverTypeWrapper<TiktokUserTagPostDetailsPayload>;
  TiktokUserTagPostsSortInput: TiktokUserTagPostsSortInput;
  TiktokUserTagPostsSortOrder: TiktokUserTagPostsSortOrder;
  TokenAuthLongLive: ResolverTypeWrapper<TokenAuthLongLive>;
  TokenAuthLongLiveInput: TokenAuthLongLiveInput;
  TotalCmsRevenue: ResolverTypeWrapper<TotalCmsRevenue>;
  TotalInfluencerReport: ResolverTypeWrapper<TotalInfluencerReport>;
  TotalNumber: ResolverTypeWrapper<TotalNumber>;
  TrackYoutubeMusicDownload: ResolverTypeWrapper<TrackYoutubeMusicDownload>;
  TrackYoutubeMusicDownloadInput: TrackYoutubeMusicDownloadInput;
  TransferMoneyToAdAccount: ResolverTypeWrapper<TransferMoneyToAdAccount>;
  TransferMoneyToAdAccountInput: TransferMoneyToAdAccountInput;
  TrendingFacebookInfluencer: ResolverTypeWrapper<TrendingFacebookInfluencer>;
  TrendingFacebookInfluencerSearchResult: ResolverTypeWrapper<TrendingFacebookInfluencerSearchResult>;
  TrendingInstagramInfluencerSearchResultV2: ResolverTypeWrapper<TrendingInstagramInfluencerSearchResultV2>;
  TrendingInstagramInfluencerV2: ResolverTypeWrapper<TrendingInstagramInfluencerV2>;
  TrendingYoutubeInfluencerSearchResultV2: ResolverTypeWrapper<TrendingYoutubeInfluencerSearchResultV2>;
  TrendingYoutubeInfluencerV2: ResolverTypeWrapper<TrendingYoutubeInfluencerV2>;
  TutorialName: TutorialName;
  TwitterAnalyticsFeedPostPayload: ResolverTypeWrapper<TwitterAnalyticsFeedPostPayload>;
  TwitterAnalyticsOverviewHistoryItem: ResolverTypeWrapper<TwitterAnalyticsOverviewHistoryItem>;
  TwitterAnalyticsOverviewHistoryRealItem: ResolverTypeWrapper<TwitterAnalyticsOverviewHistoryRealItem>;
  TwitterAnalyticsOverviewItem: ResolverTypeWrapper<TwitterAnalyticsOverviewItem>;
  TwitterAnalyticsOverviewPayload: ResolverTypeWrapper<TwitterAnalyticsOverviewPayload>;
  TwitterAnalyticsOverviewRealItem: ResolverTypeWrapper<TwitterAnalyticsOverviewRealItem>;
  TwitterAnalyticsOverviewRealTotalItem: ResolverTypeWrapper<TwitterAnalyticsOverviewRealTotalItem>;
  TwitterAnalyticsOverviewTotalItem: ResolverTypeWrapper<TwitterAnalyticsOverviewTotalItem>;
  TwitterAnalyticsPostByIdPayload: ResolverTypeWrapper<TwitterAnalyticsPostByIdPayload>;
  TwitterAnalyticsPostComment: ResolverTypeWrapper<TwitterAnalyticsPostComment>;
  TwitterAnalyticsPostItemPayload: ResolverTypeWrapper<TwitterAnalyticsPostItemPayload>;
  TwitterAnalyticsPostPayload: ResolverTypeWrapper<TwitterAnalyticsPostPayload>;
  TwitterAnalyticsPostsByDatePayload: ResolverTypeWrapper<TwitterAnalyticsPostsByDatePayload>;
  TwitterAnalyticsPostsPayload: ResolverTypeWrapper<TwitterAnalyticsPostsPayload>;
  TwitterAnalyticsPostsSortInput: TwitterAnalyticsPostsSortInput;
  TwitterAnalyticsPostsSortOrder: TwitterAnalyticsPostsSortOrder;
  TwitterAnalyticsPostsStatisticPayload: ResolverTypeWrapper<TwitterAnalyticsPostsStatisticPayload>;
  TwitterAverageEngagementItemPayload: ResolverTypeWrapper<TwitterAverageEngagementItemPayload>;
  TwitterAverageEngagementPayload: ResolverTypeWrapper<TwitterAverageEngagementPayload>;
  TwitterForProfileV2: ResolverTypeWrapper<TwitterForProfileV2>;
  TwitterInfluencer: ResolverTypeWrapper<TwitterInfluencer>;
  TwitterPostingHabitsPayload: ResolverTypeWrapper<TwitterPostingHabitsPayload>;
  UGCPostStatus: UGCPostStatus;
  UGCPostType: UGCPostType;
  UUUMSocialAuthSignUpInput: UUUMSocialAuthSignUpInput;
  UUUMSocialAuthSignUpPayload: ResolverTypeWrapper<UUUMSocialAuthSignUpPayload>;
  UnApproveApplicantInfluencers: ResolverTypeWrapper<UnApproveApplicantInfluencers>;
  UnApproveApplicantInfluencersInput: UnApproveApplicantInfluencersInput;
  UnassignChatPICInput: UnassignChatPICInput;
  UnassignChatPICPayload: ResolverTypeWrapper<UnassignChatPICPayload>;
  UnblockInfluencerMarketplace: ResolverTypeWrapper<UnblockInfluencerMarketplace>;
  UnblockInfluencerMarketplaceInput: UnblockInfluencerMarketplaceInput;
  UnreadNotificationCountByAppPayload: ResolverTypeWrapper<UnreadNotificationCountByAppPayload>;
  UnreadNotificationCountPayload: ResolverTypeWrapper<UnreadNotificationCountPayload>;
  UnreadNotificationInput: UnreadNotificationInput;
  UnresolveChatInput: UnresolveChatInput;
  UnresolveChatPayload: ResolverTypeWrapper<UnresolveChatPayload>;
  UpdateAccountInformationInput: UpdateAccountInformationInput;
  UpdateAccountInformationPayload: ResolverTypeWrapper<UpdateAccountInformationPayload>;
  UpdateAddress: ResolverTypeWrapper<UpdateAddress>;
  UpdateAddressInformationInput: UpdateAddressInformationInput;
  UpdateAddressInformationPayload: ResolverTypeWrapper<UpdateAddressInformationPayload>;
  UpdateAddressInput: UpdateAddressInput;
  UpdateAdmin: ResolverTypeWrapper<UpdateAdmin>;
  UpdateAdminInput: UpdateAdminInput;
  UpdateAdvertiser: ResolverTypeWrapper<UpdateAdvertiser>;
  UpdateAdvertiserInput: UpdateAdvertiserInput;
  UpdateAdvertiserUser: ResolverTypeWrapper<UpdateAdvertiserUser>;
  UpdateAdvertiserUserInput: UpdateAdvertiserUserInput;
  UpdateAdvertiserUserSettings: ResolverTypeWrapper<UpdateAdvertiserUserSettings>;
  UpdateAdvertiserUserSettingsInput: UpdateAdvertiserUserSettingsInput;
  UpdateAgency: ResolverTypeWrapper<UpdateAgency>;
  UpdateAgencyInput: UpdateAgencyInput;
  UpdateAutoManagedAffiliateCommissionRates: ResolverTypeWrapper<UpdateAutoManagedAffiliateCommissionRates>;
  UpdateAutoManagedAffiliateCommissionRatesInput: UpdateAutoManagedAffiliateCommissionRatesInput;
  UpdateAutoManagedCampaignInput: UpdateAutoManagedCampaignInput;
  UpdateAutoManagedCampaignPayload: ResolverTypeWrapper<UpdateAutoManagedCampaignPayload>;
  UpdateAutoManagedCampaignsStatusInput: UpdateAutoManagedCampaignsStatusInput;
  UpdateAutoManagedCampaignsStatusPayload: ResolverTypeWrapper<UpdateAutoManagedCampaignsStatusPayload>;
  UpdateBioSectionsInput: UpdateBioSectionsInput;
  UpdateBioSectionsPayload: ResolverTypeWrapper<UpdateBioSectionsPayload>;
  UpdateCmsAssetShareRate: ResolverTypeWrapper<UpdateCmsAssetShareRate>;
  UpdateCmsAssetShareRateInput: UpdateCmsAssetShareRateInput;
  UpdateCmsChannelShareRate: ResolverTypeWrapper<UpdateCmsChannelShareRate>;
  UpdateCmsChannelShareRateInput: UpdateCmsChannelShareRateInput;
  UpdateCommentInput: UpdateCommentInput;
  UpdateCommentPayload: ResolverTypeWrapper<UpdateCommentPayload>;
  UpdateEmailTemplateInput: UpdateEmailTemplateInput;
  UpdateEmailTemplatePayload: ResolverTypeWrapper<UpdateEmailTemplatePayload>;
  UpdateEngagement: ResolverTypeWrapper<UpdateEngagement>;
  UpdateEngagementBreakDownMode: ResolverTypeWrapper<UpdateEngagementBreakDownMode>;
  UpdateEngagementBreakDownModeInput: UpdateEngagementBreakDownModeInput;
  UpdateEngagementCouponList: ResolverTypeWrapper<UpdateEngagementCouponList>;
  UpdateEngagementCouponListInput: UpdateEngagementCouponListInput;
  UpdateEngagementInfluencerProposalList: ResolverTypeWrapper<UpdateEngagementInfluencerProposalList>;
  UpdateEngagementInfluencerProposalListInput: UpdateEngagementInfluencerProposalListInput;
  UpdateEngagementInfluencersBudget: ResolverTypeWrapper<UpdateEngagementInfluencersBudget>;
  UpdateEngagementInfluencersBudgetInput: UpdateEngagementInfluencersBudgetInput;
  UpdateEngagementInput: UpdateEngagementInput;
  UpdateEngagementPostForInfluencerV2: ResolverTypeWrapper<UpdateEngagementPostForInfluencerV2>;
  UpdateEngagementPostInputForInfluencerV2: UpdateEngagementPostInputForInfluencerV2;
  UpdateEngagementPostInputV2: UpdateEngagementPostInputV2;
  UpdateEngagementPostStatus: ResolverTypeWrapper<UpdateEngagementPostStatus>;
  UpdateEngagementPostStatusInput: UpdateEngagementPostStatusInput;
  UpdateEngagementPostV2: ResolverTypeWrapper<UpdateEngagementPostV2>;
  UpdateEngagementSocialAccountProposalList: ResolverTypeWrapper<UpdateEngagementSocialAccountProposalList>;
  UpdateEngagementSocialAccountProposalListInput: UpdateEngagementSocialAccountProposalListInput;
  UpdateEngagementSocialAccountsBudget: ResolverTypeWrapper<UpdateEngagementSocialAccountsBudget>;
  UpdateEngagementSocialAccountsBudgetInput: UpdateEngagementSocialAccountsBudgetInput;
  UpdateEngagementStatus: ResolverTypeWrapper<UpdateEngagementStatus>;
  UpdateEngagementStatusInput: UpdateEngagementStatusInput;
  UpdateFanInput: UpdateFanInput;
  UpdateFanPayload: ResolverTypeWrapper<UpdateFanPayload>;
  UpdateFormInput: UpdateFormInput;
  UpdateFormPayload: ResolverTypeWrapper<UpdateFormPayload>;
  UpdateFormStatusInput: UpdateFormStatusInput;
  UpdateFormStatusPayload: ResolverTypeWrapper<UpdateFormStatusPayload>;
  UpdateInfluencerBioInput: UpdateInfluencerBioInput;
  UpdateInfluencerBioPayload: ResolverTypeWrapper<UpdateInfluencerBioPayload>;
  UpdateInfluencerInputV2: UpdateInfluencerInputV2;
  UpdateInfluencerPhoneNumberInput: UpdateInfluencerPhoneNumberInput;
  UpdateInfluencerPhoneNumberPayload: ResolverTypeWrapper<UpdateInfluencerPhoneNumberPayload>;
  UpdateInfluencerProfile: ResolverTypeWrapper<UpdateInfluencerProfile>;
  UpdateInfluencerProfileInput: UpdateInfluencerProfileInput;
  UpdateInfluencerRateCardInput: UpdateInfluencerRateCardInput;
  UpdateInfluencerRateCardPayload: ResolverTypeWrapper<UpdateInfluencerRateCardPayload>;
  UpdateInfluencerRateCardsByStaff: ResolverTypeWrapper<UpdateInfluencerRateCardsByStaff>;
  UpdateInfluencerRateCardsByStaffInput: UpdateInfluencerRateCardsByStaffInput;
  UpdateInfluencerRateCardsInput: UpdateInfluencerRateCardsInput;
  UpdateInfluencerRateCardsPayload: ResolverTypeWrapper<UpdateInfluencerRateCardsPayload>;
  UpdateInfluencerTagsByStaff: ResolverTypeWrapper<UpdateInfluencerTagsByStaff>;
  UpdateInfluencerTagsByStaffInput: UpdateInfluencerTagsByStaffInput;
  UpdateInfluencerV2: ResolverTypeWrapper<UpdateInfluencerV2>;
  UpdateInternalMemo: ResolverTypeWrapper<UpdateInternalMemo>;
  UpdateInternalMemoInput: UpdateInternalMemoInput;
  UpdateLineBroadcastMessageInput: UpdateLineBroadcastMessageInput;
  UpdateLineBroadcastMessagePayload: ResolverTypeWrapper<UpdateLineBroadcastMessagePayload>;
  UpdateLinkBioThemeColorInput: UpdateLinkBioThemeColorInput;
  UpdateLinkBioThemeColorPayload: ResolverTypeWrapper<UpdateLinkBioThemeColorPayload>;
  UpdateMarketplace: ResolverTypeWrapper<UpdateMarketplace>;
  UpdateMarketplaceAffiliateCommissionFees: ResolverTypeWrapper<UpdateMarketplaceAffiliateCommissionFees>;
  UpdateMarketplaceAffiliateCommissionFeesInput: UpdateMarketplaceAffiliateCommissionFeesInput;
  UpdateMarketplaceAffiliateCommissionRates: ResolverTypeWrapper<UpdateMarketplaceAffiliateCommissionRates>;
  UpdateMarketplaceAffiliateCommissionRatesInput: UpdateMarketplaceAffiliateCommissionRatesInput;
  UpdateMarketplaceCouponList: ResolverTypeWrapper<UpdateMarketplaceCouponList>;
  UpdateMarketplaceCouponListInput: UpdateMarketplaceCouponListInput;
  UpdateMarketplaceInfluencerCost: ResolverTypeWrapper<UpdateMarketplaceInfluencerCost>;
  UpdateMarketplaceInfluencerCostInput: UpdateMarketplaceInfluencerCostInput;
  UpdateMarketplaceInput: UpdateMarketplaceInput;
  UpdateMarketplacePostStatusInputV2: UpdateMarketplacePostStatusInputV2;
  UpdateMarketplacePostStatusV2: ResolverTypeWrapper<UpdateMarketplacePostStatusV2>;
  UpdateMarketplacePostTrackingUrl: ResolverTypeWrapper<UpdateMarketplacePostTrackingUrl>;
  UpdateMarketplacePostTrackingUrlInput: UpdateMarketplacePostTrackingUrlInput;
  UpdateOptionInput: UpdateOptionInput;
  UpdatePackage: ResolverTypeWrapper<UpdatePackage>;
  UpdatePackageInput: UpdatePackageInput;
  UpdatePackageProposalInfluencersBudget: ResolverTypeWrapper<UpdatePackageProposalInfluencersBudget>;
  UpdatePackageProposalInfluencersBudgetInput: UpdatePackageProposalInfluencersBudgetInput;
  UpdatePackageProposalMode: ResolverTypeWrapper<UpdatePackageProposalMode>;
  UpdatePackageProposalModeInput: UpdatePackageProposalModeInput;
  UpdatePackageProposalSocialAccountsBudget: ResolverTypeWrapper<UpdatePackageProposalSocialAccountsBudget>;
  UpdatePackageProposalSocialAccountsBudgetInput: UpdatePackageProposalSocialAccountsBudgetInput;
  UpdatePartnerAgency: ResolverTypeWrapper<UpdatePartnerAgency>;
  UpdatePartnerAgencyInput: UpdatePartnerAgencyInput;
  UpdatePartnerUser: ResolverTypeWrapper<UpdatePartnerUser>;
  UpdatePartnerUserInput: UpdatePartnerUserInput;
  UpdatePasswordByAdvertiserUser: ResolverTypeWrapper<UpdatePasswordByAdvertiserUser>;
  UpdatePasswordByAdvertiserUserInput: UpdatePasswordByAdvertiserUserInput;
  UpdatePaymentInformation: ResolverTypeWrapper<UpdatePaymentInformation>;
  UpdatePaymentInformationInput: UpdatePaymentInformationInput;
  UpdatePaymentStatus: ResolverTypeWrapper<UpdatePaymentStatus>;
  UpdatePaymentStatusInput: UpdatePaymentStatusInput;
  UpdatePersoanlUserDetailPayload: ResolverTypeWrapper<UpdatePersoanlUserDetailPayload>;
  UpdatePersonalUserDetailInput: UpdatePersonalUserDetailInput;
  UpdatePlainAgency: ResolverTypeWrapper<UpdatePlainAgency>;
  UpdatePlainAgencyInput: UpdatePlainAgencyInput;
  UpdateQuestionInput: UpdateQuestionInput;
  UpdateSocialAccountsInput: UpdateSocialAccountsInput;
  UpdateSocialAccountsPayload: ResolverTypeWrapper<UpdateSocialAccountsPayload>;
  UpdateSocialUsername: ResolverTypeWrapper<UpdateSocialUsername>;
  UpdateSocialUsernameInput: UpdateSocialUsernameInput;
  UpdateStaff: ResolverTypeWrapper<UpdateStaff>;
  UpdateStaffInput: UpdateStaffInput;
  UpdateSubscriptionPlan: ResolverTypeWrapper<UpdateSubscriptionPlan>;
  UpdateSubscriptionPlanInput: UpdateSubscriptionPlanInput;
  UpdateTalentAgency: ResolverTypeWrapper<UpdateTalentAgency>;
  UpdateTalentAgencyInput: UpdateTalentAgencyInput;
  UpdateTalentAgencyUser: ResolverTypeWrapper<UpdateTalentAgencyUser>;
  UpdateTalentAgencyUserInput: UpdateTalentAgencyUserInput;
  UpdateTalentInfluencerInput: UpdateTalentInfluencerInput;
  UpdateTalentInfluencerPayload: ResolverTypeWrapper<UpdateTalentInfluencerPayload>;
  UpdateTikTokSpecialCampaign: ResolverTypeWrapper<UpdateTikTokSpecialCampaign>;
  UpdateTikTokSpecialCampaignInput: UpdateTikTokSpecialCampaignInput;
  UpdateTiktokAdvertiserInfo: ResolverTypeWrapper<UpdateTiktokAdvertiserInfo>;
  UpdateTiktokAdvertiserInfoInput: UpdateTiktokAdvertiserInfoInput;
  UpdateTiktokSpecialPostStatus: ResolverTypeWrapper<UpdateTiktokSpecialPostStatus>;
  UpdateTiktokSpecialPostStatusInput: UpdateTiktokSpecialPostStatusInput;
  UpdateTtcmPost: ResolverTypeWrapper<UpdateTtcmPost>;
  UpdateTtcmPostInput: UpdateTtcmPostInput;
  UpdateUserDetailInput: UpdateUserDetailInput;
  UpdateUserDetailPayload: ResolverTypeWrapper<UpdateUserDetailPayload>;
  UpdateUserProposalBreakdownMode: ResolverTypeWrapper<UpdateUserProposalBreakdownMode>;
  UpdateUserProposalBreakdownModeInput: UpdateUserProposalBreakdownModeInput;
  UpdateUserProposalInfluencersBudget: ResolverTypeWrapper<UpdateUserProposalInfluencersBudget>;
  UpdateUserProposalInfluencersBudgetInput: UpdateUserProposalInfluencersBudgetInput;
  UpdateUserProposalSocialAccountsBudget: ResolverTypeWrapper<UpdateUserProposalSocialAccountsBudget>;
  UpdateUserProposalSocialAccountsBudgetInput: UpdateUserProposalSocialAccountsBudgetInput;
  UpdateYoutubeMCNCredentialsInput: UpdateYoutubeMCNCredentialsInput;
  UpdateYoutubeMCNCredentialsPayload: ResolverTypeWrapper<UpdateYoutubeMCNCredentialsPayload>;
  UploadEngagementReportFile: ResolverTypeWrapper<UploadEngagementReportFile>;
  UploadEngagementReportFileInput: UploadEngagementReportFileInput;
  UploadEngagementReportFromInsightCheck: ResolverTypeWrapper<UploadEngagementReportFromInsightCheck>;
  UploadEngagementReportFromInsightCheckInput: UploadEngagementReportFromInsightCheckInput;
  UploadEngagementXhsInsightCheck: ResolverTypeWrapper<UploadEngagementXhsInsightCheck>;
  UploadEngagementXhsInsightCheckInput: UploadEngagementXhsInsightCheckInput;
  UploadOpenAIImages: ResolverTypeWrapper<UploadOpenAIImages>;
  UploadOpenAIImagesInput: UploadOpenAIImagesInput;
  UserAudioMessageEvent: ResolverTypeWrapper<UserAudioMessageEvent>;
  UserDefaultPermissions: ResolverTypeWrapper<UserDefaultPermissions>;
  UserDetail: ResolverTypeWrapper<UserDetail>;
  UserDetailInput: UserDetailInput;
  UserDetailPayload: ResolverTypeWrapper<UserDetailPayload>;
  UserImageMessageEvent: ResolverTypeWrapper<UserImageMessageEvent>;
  UserPostbackEvent: ResolverTypeWrapper<UserPostbackEvent>;
  UserProposal: ResolverTypeWrapper<UserProposal>;
  UserProposalInfluencer: ResolverTypeWrapper<UserProposalInfluencer>;
  UserProposalInfluencerBudgetInput: UserProposalInfluencerBudgetInput;
  UserProposalJobSummary: ResolverTypeWrapper<UserProposalJobSummary>;
  UserProposalSocialAcccountBudgetInput: UserProposalSocialAcccountBudgetInput;
  UserProposalSocialAccount: ResolverTypeWrapper<UserProposalSocialAccount>;
  UserProposalSocialMediaType: UserProposalSocialMediaType;
  UserRoles: UserRoles;
  UserTextMessageEvent: ResolverTypeWrapper<UserTextMessageEvent>;
  UserVideoMessageEvent: ResolverTypeWrapper<UserVideoMessageEvent>;
  VerifyInput: VerifyInput;
  VerifyLineIDTokenInput: VerifyLineIDTokenInput;
  VerifyLineIDTokenPayload: ResolverTypeWrapper<VerifyLineIDTokenPayload>;
  VerifyPayload: ResolverTypeWrapper<VerifyPayload>;
  VideoInput: VideoInput;
  VideoPayload: ResolverTypeWrapper<VideoPayload>;
  WarningReason: WarningReason;
  WithdrawMoneyFromAdAccount: ResolverTypeWrapper<WithdrawMoneyFromAdAccount>;
  XhsAccountInterest: ResolverTypeWrapper<XhsAccountInterest>;
  XhsAudienceSection: ResolverTypeWrapper<XhsAudienceSection>;
  XhsEngagementInsightSection: ResolverTypeWrapper<XhsEngagementInsightSection>;
  XhsInfluencer: ResolverTypeWrapper<XhsInfluencer>;
  XhsInfluencerSearchResult: ResolverTypeWrapper<XhsInfluencerSearchResult>;
  XhsPostSection: ResolverTypeWrapper<XhsPostSection>;
  XhsPostType: XhsPostType;
  YouTubeBrandAccount: ResolverTypeWrapper<YouTubeBrandAccount>;
  YouTubeBrandAccounts: ResolverTypeWrapper<YouTubeBrandAccounts>;
  YouTubeCmsRevenueForInfluencer: ResolverTypeWrapper<YouTubeCmsRevenueForInfluencer>;
  YouTubeCmsVideoAudience: ResolverTypeWrapper<YouTubeCmsVideoAudience>;
  YouTubeCmsVideoStats: ResolverTypeWrapper<YouTubeCmsVideoStats>;
  YouTubeInfluencer: ResolverTypeWrapper<YouTubeInfluencer>;
  YoutubeAccountForProfileV2: ResolverTypeWrapper<YoutubeAccountForProfileV2>;
  YoutubeAgeGenderGroup: ResolverTypeWrapper<YoutubeAgeGenderGroup>;
  YoutubeAnalyticsAccountCard: ResolverTypeWrapper<YoutubeAnalyticsAccountCard>;
  YoutubeAnalyticsAccountOverview: ResolverTypeWrapper<YoutubeAnalyticsAccountOverview>;
  YoutubeAnalyticsAccountPosts: ResolverTypeWrapper<YoutubeAnalyticsAccountPosts>;
  YoutubeAnalyticsAccountPostsInDate: ResolverTypeWrapper<YoutubeAnalyticsAccountPostsInDate>;
  YoutubeAnalyticsAccountTagRanking: ResolverTypeWrapper<YoutubeAnalyticsAccountTagRanking>;
  YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload: ResolverTypeWrapper<YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  YoutubeAnalyticsAudienceAgeBreakdownPayload: ResolverTypeWrapper<YoutubeAnalyticsAudienceAgeBreakdownPayload>;
  YoutubeAnalyticsAudienceGenderBreakdownPayload: ResolverTypeWrapper<YoutubeAnalyticsAudienceGenderBreakdownPayload>;
  YoutubeAnalyticsAudiencePayload: ResolverTypeWrapper<YoutubeAnalyticsAudiencePayload>;
  YoutubeAnalyticsAudienceRegionPayload: ResolverTypeWrapper<YoutubeAnalyticsAudienceRegionPayload>;
  YoutubeAnalyticsCategory: ResolverTypeWrapper<YoutubeAnalyticsCategory>;
  YoutubeAnalyticsCommentsPayload: ResolverTypeWrapper<YoutubeAnalyticsCommentsPayload>;
  YoutubeAnalyticsCompareAccountCard: ResolverTypeWrapper<YoutubeAnalyticsCompareAccountCard>;
  YoutubeAnalyticsCompareAccountCards: ResolverTypeWrapper<YoutubeAnalyticsCompareAccountCards>;
  YoutubeAnalyticsComparePostComment: ResolverTypeWrapper<YoutubeAnalyticsComparePostComment>;
  YoutubeAnalyticsComparePostsSort: YoutubeAnalyticsComparePostsSort;
  YoutubeAnalyticsComparePostsSortOrder: YoutubeAnalyticsComparePostsSortOrder;
  YoutubeAnalyticsCompareRelatedPost: ResolverTypeWrapper<YoutubeAnalyticsCompareRelatedPost>;
  YoutubeAnalyticsCompareRelatedPosts: ResolverTypeWrapper<YoutubeAnalyticsCompareRelatedPosts>;
  YoutubeAnalyticsCountriesCategoriesPayload: ResolverTypeWrapper<YoutubeAnalyticsCountriesCategoriesPayload>;
  YoutubeAnalyticsCountry: ResolverTypeWrapper<YoutubeAnalyticsCountry>;
  YoutubeAnalyticsHistoryDataPoint: ResolverTypeWrapper<YoutubeAnalyticsHistoryDataPoint>;
  YoutubeAnalyticsOverviewHistoryItem: ResolverTypeWrapper<YoutubeAnalyticsOverviewHistoryItem>;
  YoutubeAnalyticsOverviewItem: ResolverTypeWrapper<YoutubeAnalyticsOverviewItem>;
  YoutubeAnalyticsOverviewPayload: ResolverTypeWrapper<YoutubeAnalyticsOverviewPayload>;
  YoutubeAnalyticsPostComment: ResolverTypeWrapper<YoutubeAnalyticsPostComment>;
  YoutubeAnalyticsPostCommentItem: ResolverTypeWrapper<YoutubeAnalyticsPostCommentItem>;
  YoutubeAnalyticsPostPayload: ResolverTypeWrapper<YoutubeAnalyticsPostPayload>;
  YoutubeAnalyticsPostPayloads: ResolverTypeWrapper<YoutubeAnalyticsPostPayloads>;
  YoutubeAnalyticsPostWithChannelDetailsPayload: ResolverTypeWrapper<YoutubeAnalyticsPostWithChannelDetailsPayload>;
  YoutubeAnalyticsPostsAverageEngagement: ResolverTypeWrapper<YoutubeAnalyticsPostsAverageEngagement>;
  YoutubeAnalyticsPostsPayload: ResolverTypeWrapper<YoutubeAnalyticsPostsPayload>;
  YoutubeAnalyticsPostsPayloads: ResolverTypeWrapper<YoutubeAnalyticsPostsPayloads>;
  YoutubeAnalyticsPostsPostingHabit: ResolverTypeWrapper<YoutubeAnalyticsPostsPostingHabit>;
  YoutubeAnalyticsPostsSortInput: YoutubeAnalyticsPostsSortInput;
  YoutubeAnalyticsPostsSortOrder: YoutubeAnalyticsPostsSortOrder;
  YoutubeAnalyticsPostsStatisticsPayload: ResolverTypeWrapper<YoutubeAnalyticsPostsStatisticsPayload>;
  YoutubeAnalyticsRelatedPostPayload: ResolverTypeWrapper<YoutubeAnalyticsRelatedPostPayload>;
  YoutubeAnalyticsRelatedPostsPayload: ResolverTypeWrapper<YoutubeAnalyticsRelatedPostsPayload>;
  YoutubeAnalyticsShortsPayload: ResolverTypeWrapper<YoutubeAnalyticsShortsPayload>;
  YoutubeAnalyticsShortsPayloads: ResolverTypeWrapper<YoutubeAnalyticsShortsPayloads>;
  YoutubeAnalyticsSponsoredPostsSortInput: YoutubeAnalyticsSponsoredPostsSortInput;
  YoutubeAnalyticsSponsoredPostsSortOrder: YoutubeAnalyticsSponsoredPostsSortOrder;
  YoutubeAnalyticsTagRankingPayload: ResolverTypeWrapper<YoutubeAnalyticsTagRankingPayload>;
  YoutubeAnalyticsTagRankingPayloads: ResolverTypeWrapper<YoutubeAnalyticsTagRankingPayloads>;
  YoutubeAnalyticsTagRankingSortInput: YoutubeAnalyticsTagRankingSortInput;
  YoutubeAnalyticsTagRankingSortOrder: YoutubeAnalyticsTagRankingSortOrder;
  YoutubeAnalyticsTrendPayload: ResolverTypeWrapper<YoutubeAnalyticsTrendPayload>;
  YoutubeAnalyticsTrendPostDetailsPayload: ResolverTypeWrapper<YoutubeAnalyticsTrendPostDetailsPayload>;
  YoutubeAnalyticsTrendsPayloads: ResolverTypeWrapper<YoutubeAnalyticsTrendsPayloads>;
  YoutubeAudienceCountryRate: ResolverTypeWrapper<YoutubeAudienceCountryRate>;
  YoutubeAudienceGenderRate: ResolverTypeWrapper<YoutubeAudienceGenderRate>;
  YoutubeCmsChannelDetail: ResolverTypeWrapper<YoutubeCmsChannelDetail>;
  YoutubeCmsChannelForInfluencer: ResolverTypeWrapper<YoutubeCmsChannelForInfluencer>;
  YoutubeCmsChannelInfo: ResolverTypeWrapper<YoutubeCmsChannelInfo>;
  YoutubeCmsConfirmedAsset: ResolverTypeWrapper<YoutubeCmsConfirmedAsset>;
  YoutubeCmsConfirmedChannel: ResolverTypeWrapper<YoutubeCmsConfirmedChannel>;
  YoutubeCmsConfirmedChannelSummary: ResolverTypeWrapper<YoutubeCmsConfirmedChannelSummary>;
  YoutubeCmsConfirmedRevenueLatestMonth: ResolverTypeWrapper<YoutubeCmsConfirmedRevenueLatestMonth>;
  YoutubeCmsEstimateAsset: ResolverTypeWrapper<YoutubeCmsEstimateAsset>;
  YoutubeCmsEstimateChannel: ResolverTypeWrapper<YoutubeCmsEstimateChannel>;
  YoutubeCmsEstimateChannelSummary: ResolverTypeWrapper<YoutubeCmsEstimateChannelSummary>;
  YoutubeCmsInvoice: ResolverTypeWrapper<YoutubeCmsInvoice>;
  YoutubeCmsInvoiceDownloadUrl: ResolverTypeWrapper<YoutubeCmsInvoiceDownloadUrl>;
  YoutubeCmsInvoiceForInfluencer: ResolverTypeWrapper<YoutubeCmsInvoiceForInfluencer>;
  YoutubeCmsMusicForInfluencer: ResolverTypeWrapper<YoutubeCmsMusicForInfluencer>;
  YoutubeCmsStartMonth: ResolverTypeWrapper<YoutubeCmsStartMonth>;
  YoutubeCmsVideo: ResolverTypeWrapper<YoutubeCmsVideo>;
  YoutubeCompareAccountOverview: ResolverTypeWrapper<YoutubeCompareAccountOverview>;
  YoutubeCompareAccountPost: ResolverTypeWrapper<YoutubeCompareAccountPost>;
  YoutubeCompareAccountPosts: ResolverTypeWrapper<YoutubeCompareAccountPosts>;
  YoutubeCompareAccountPostsInDate: ResolverTypeWrapper<YoutubeCompareAccountPostsInDate>;
  YoutubeCompareAccountTagRanking: ResolverTypeWrapper<YoutubeCompareAccountTagRanking>;
  YoutubeCompareAllPostsInDate: ResolverTypeWrapper<YoutubeCompareAllPostsInDate>;
  YoutubeCompareOverview: ResolverTypeWrapper<YoutubeCompareOverview>;
  YoutubeCompareOverviewHistory: ResolverTypeWrapper<YoutubeCompareOverviewHistory>;
  YoutubeCompareOverviewSummary: ResolverTypeWrapper<YoutubeCompareOverviewSummary>;
  YoutubeCompareOverviewSummaryData: ResolverTypeWrapper<YoutubeCompareOverviewSummaryData>;
  YoutubeComparePost: ResolverTypeWrapper<YoutubeComparePost>;
  YoutubeComparePostList: ResolverTypeWrapper<YoutubeComparePostList>;
  YoutubeComparePostListItem: ResolverTypeWrapper<YoutubeComparePostListItem>;
  YoutubeComparePosts: ResolverTypeWrapper<YoutubeComparePosts>;
  YoutubeCompareTagRanking: ResolverTypeWrapper<YoutubeCompareTagRanking>;
  YoutubeInfluencerSearchResultV3: ResolverTypeWrapper<YoutubeInfluencerSearchResultV3>;
  YoutubeInfluencerV3: ResolverTypeWrapper<YoutubeInfluencerV3>;
  YoutubeMCNAdminRedirectUrlInput: YoutubeMCNAdminRedirectUrlInput;
  YoutubeMCNAdminRedirectUrlPayload: ResolverTypeWrapper<YoutubeMCNAdminRedirectUrlPayload>;
  YoutubeMusicCategory: ResolverTypeWrapper<YoutubeMusicCategory>;
  YoutubeMusicItem: ResolverTypeWrapper<YoutubeMusicItem>;
  YoutubeStatisticsData: ResolverTypeWrapper<YoutubeStatisticsData>;
  YoutubeTagRanking: ResolverTypeWrapper<YoutubeTagRanking>;
  YoutubeTrafficSource: YoutubeTrafficSource;
  _FieldSet: ResolverTypeWrapper<Scalars['_FieldSet']>;
  _Service: ResolverTypeWrapper<_Service>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AcceptedPayload: AcceptedPayload;
  AccountAnalyticsAccountManager: AccountAnalyticsAccountManager;
  AccountAnalyticsAdvertiser: AccountAnalyticsAdvertiser;
  AccountAnalyticsAdvertiserDetails: AccountAnalyticsAdvertiserDetails;
  AccountAnalyticsDetail: AccountAnalyticsDetail;
  AccountAnalyticsFacebookPageAccount: AccountAnalyticsFacebookPageAccount;
  AccountAnalyticsForAdvertiserPayload: AccountAnalyticsForAdvertiserPayload;
  AccountAnalyticsInstagramAccount: AccountAnalyticsInstagramAccount;
  AccountAnalyticsPayload: AccountAnalyticsPayload;
  AccountAnalyticsSocialMediaAccount: AccountAnalyticsSocialMediaAccount;
  AccountAnalyticsTwitterAccount: AccountAnalyticsTwitterAccount;
  AccountAnalyticsYouTubeAccount: AccountAnalyticsYouTubeAccount;
  AccountAudienceDataRate: AccountAudienceDataRate;
  ActionLabelInput: ActionLabelInput;
  ActionLabelPayload: ActionLabelPayload;
  ActivityChange: ActivityChange;
  AddAllSelectedInfluencers: AddAllSelectedInfluencers;
  AddAllSelectedInfluencersInput: AddAllSelectedInfluencersInput;
  AddCommentInput: AddCommentInput;
  AddCommentPayload: AddCommentPayload;
  AddCompareInstagramAccountAnalytics: AddCompareInstagramAccountAnalytics;
  AddEmailTemplateInput: AddEmailTemplateInput;
  AddEmailTemplatePayload: AddEmailTemplatePayload;
  AddFanInput: AddFanInput;
  AddFanPayload: AddFanPayload;
  AddFanTagsInput: AddFanTagsInput;
  AddFanTagsPayload: AddFanTagsPayload;
  AddFormInput: AddFormInput;
  AddFormPayload: AddFormPayload;
  AddInfluencerToUserProposal: AddInfluencerToUserProposal;
  AddInfluencerToUserProposalInput: AddInfluencerToUserProposalInput;
  AddInstagramCompareAccountAnalyticsInput: AddInstagramCompareAccountAnalyticsInput;
  AddInstagramHashtagAnalytics: AddInstagramHashtagAnalytics;
  AddInstagramHashtagAnalyticsInput: AddInstagramHashtagAnalyticsInput;
  AddMarketplacePostUrl: AddMarketplacePostUrl;
  AddMarketplacePostUrlForInfluencer: AddMarketplacePostUrlForInfluencer;
  AddMarketplacePostUrlInput: AddMarketplacePostUrlInput;
  AddMarketplacePostUrlInputForInfluencer: AddMarketplacePostUrlInputForInfluencer;
  AddReadTutorial: AddReadTutorial;
  AddReadTutorialInput: AddReadTutorialInput;
  AddSelectedInfluencers: AddSelectedInfluencers;
  AddSelectedInfluencersInput: AddSelectedInfluencersInput;
  AddSocialAccountToUserProposal: AddSocialAccountToUserProposal;
  AddSocialAccountToUserProposalInput: AddSocialAccountToUserProposalInput;
  AddStaffAdvertisersToAnotherStaff: AddStaffAdvertisersToAnotherStaff;
  AddStaffAdvertisersToAnotherStaffInput: AddStaffAdvertisersToAnotherStaffInput;
  AddYoutubeCompareAccountAnalyticsInput: AddYoutubeCompareAccountAnalyticsInput;
  AddYoutubeCompareAccountAnalyticsOutput: AddYoutubeCompareAccountAnalyticsOutput;
  Address: Address;
  AddressDetails: AddressDetails;
  AddressForInfluencer: AddressForInfluencer;
  AddressInput: AddressInput;
  Advertiser: Advertiser;
  AdvertiserForAdvertiserUser: AdvertiserForAdvertiserUser;
  AdvertiserForAdvertiserUserSettings: AdvertiserForAdvertiserUserSettings;
  AdvertiserForList: AdvertiserForList;
  AdvertiserForSearch: AdvertiserForSearch;
  AdvertiserName: AdvertiserName;
  AdvertiserUser: AdvertiserUser;
  AdvertiserUserInput: AdvertiserUserInput;
  AdvertiserUserSettingsDetails: AdvertiserUserSettingsDetails;
  AdvertiserUserWithRole: AdvertiserUserWithRole;
  AffiliateCampaignChartReport: AffiliateCampaignChartReport;
  AgenciesForSearch: AgenciesForSearch;
  AgencyLogo: AgencyLogo;
  AgencyUser: AgencyUser;
  AgencyUserWithRole: AgencyUserWithRole;
  AgencyWithAdvertisers: AgencyWithAdvertisers;
  AgencyWithNSVerification: AgencyWithNSVerification;
  AiChatModelName: AiChatModelName;
  AllAccountAnalyticsForAdminPayload: AllAccountAnalyticsForAdminPayload;
  AllAccountAnalyticsForAdvertiserPayload: AllAccountAnalyticsForAdvertiserPayload;
  AllAdvertisersForSearch: AllAdvertisersForSearch;
  AllCampaignsReportSummary: AllCampaignsReportSummary;
  AllInfluencerV4: AllInfluencerV4;
  AllStaffDetail: AllStaffDetail;
  AllTiktokAdReportSummary: AllTiktokAdReportSummary;
  AllUserDetail: AllUserDetail;
  AllowEngagementInfluencerProposalList: AllowEngagementInfluencerProposalList;
  AllowEngagementInfluencerProposalListInput: AllowEngagementInfluencerProposalListInput;
  AllowInfluencersUserProposal: AllowInfluencersUserProposal;
  AllowInfluencersUserProposalInput: AllowInfluencersUserProposalInput;
  AnalyticSubscriptionAvailableFeature: AnalyticSubscriptionAvailableFeature;
  AnalyticSubscriptionAvailableFeatures: AnalyticSubscriptionAvailableFeatures;
  AnalyticSubscriptionCapabilities: AnalyticSubscriptionCapabilities;
  AnalyticSubscriptionCapability: AnalyticSubscriptionCapability;
  AnalyticsSelectSubscriptionPlan: AnalyticsSelectSubscriptionPlan;
  AnalyticsSelectSubscriptionPlanInput: AnalyticsSelectSubscriptionPlanInput;
  AnalyticsSocialAuthRedirectUrlPayload: AnalyticsSocialAuthRedirectUrlPayload;
  AnalyticsSubscriptionPlan: AnalyticsSubscriptionPlan;
  AnalyticsSubscriptionPlanDetails: AnalyticsSubscriptionPlanDetails;
  AnalyticsSubscriptionPlanName: AnalyticsSubscriptionPlanName;
  AnyCreatorCategory: AnyCreatorCategory;
  AnyXAccountInfoPayload: AnyXAccountInfoPayload;
  AnyXCheckConnectedSocialAccountsForLinkBioPayload: AnyXCheckConnectedSocialAccountsForLinkBioPayload;
  AnyXCheckConnectedSocialAccountsPayload: AnyXCheckConnectedSocialAccountsPayload;
  AnyXCheckEmailInput: AnyXCheckEmailInput;
  AnyXCheckEmailPayload: AnyXCheckEmailPayload;
  AnyXConnectedChildYoutubeAccount: AnyXConnectedChildYoutubeAccount;
  AnyXConnectedFacebookAccount: AnyXConnectedFacebookAccount;
  AnyXConnectedFacebookAccountAndPageForLinkBio: AnyXConnectedFacebookAccountAndPageForLinkBio;
  AnyXConnectedFacebookPage: AnyXConnectedFacebookPage;
  AnyXConnectedInstagramAccount: AnyXConnectedInstagramAccount;
  AnyXConnectedSocialAccount: AnyXConnectedSocialAccount;
  AnyXConnectedSocialAccountForLinkBio: AnyXConnectedSocialAccountForLinkBio;
  AnyXConnectedTikTokAccount: AnyXConnectedTikTokAccount;
  AnyXConnectedYoutubeAccount: AnyXConnectedYoutubeAccount;
  AnyXSignIn: AnyXSignIn;
  AnyXSignUp: AnyXSignUp;
  AnyXSocialAuthConnectForLinkBioInput: AnyXSocialAuthConnectForLinkBioInput;
  AnyXSocialAuthConnectForLinkBioPayload: AnyXSocialAuthConnectForLinkBioPayload;
  AnyXSocialAuthConnectInput: AnyXSocialAuthConnectInput;
  AnyXSocialAuthConnectPayload: AnyXSocialAuthConnectPayload;
  AnyXSocialAuthDisconnectInput: AnyXSocialAuthDisconnectInput;
  AnyXSocialAuthDisconnectPayload: AnyXSocialAuthDisconnectPayload;
  AnyXSocialAuthReconnectForLinkBioInput: AnyXSocialAuthReconnectForLinkBioInput;
  AnyXSocialAuthReconnectForLinkBioPayload: AnyXSocialAuthReconnectForLinkBioPayload;
  AnyXSocialAuthReconnectTTCMInput: AnyXSocialAuthReconnectTTCMInput;
  AnyXSocialAuthReconnectTTCMPayload: AnyXSocialAuthReconnectTTCMPayload;
  AnyXSocialAuthReconnectTikTokInput: AnyXSocialAuthReconnectTikTokInput;
  AnyXSocialAuthReconnectTikTokPayload: AnyXSocialAuthReconnectTikTokPayload;
  AnyXSocialAuthSignInInput: AnyXSocialAuthSignInInput;
  AnyXSocialAuthSignInInstagramLineInput: AnyXSocialAuthSignInInstagramLineInput;
  AnyXSocialAuthSignInInstagramLinePayload: AnyXSocialAuthSignInInstagramLinePayload;
  AnyXSocialAuthSignInOrSignUpInput: AnyXSocialAuthSignInOrSignUpInput;
  AnyXSocialAuthSignInOrSignUpPayload: AnyXSocialAuthSignInOrSignUpPayload;
  AnyXSocialAuthSignInPayload: AnyXSocialAuthSignInPayload;
  AnyXSocialAuthSignUpInput: AnyXSocialAuthSignUpInput;
  AnyXSocialAuthSignUpPartnerInput: AnyXSocialAuthSignUpPartnerInput;
  AnyXSocialAuthSignUpPartnerPayload: AnyXSocialAuthSignUpPartnerPayload;
  AnyXSocialAuthSignUpPayload: AnyXSocialAuthSignUpPayload;
  AnyXSocialAuthSignUpTalentInput: AnyXSocialAuthSignUpTalentInput;
  AnyXSocialAuthSignUpTalentPayload: AnyXSocialAuthSignUpTalentPayload;
  ApplicantInfluencer: ApplicantInfluencer;
  ApproveApplicantInfluencers: ApproveApplicantInfluencers;
  ApproveApplicantInfluencersInput: ApproveApplicantInfluencersInput;
  ApproveAutoManagedPayout: ApproveAutoManagedPayout;
  ApproveAutoManagedPayoutInput: ApproveAutoManagedPayoutInput;
  ApproveCmsInvoice: ApproveCmsInvoice;
  ApproveCmsInvoiceInput: ApproveCmsInvoiceInput;
  ApproveMarketplace: ApproveMarketplace;
  ApproveMarketplaceAffiliatePayout: ApproveMarketplaceAffiliatePayout;
  ApproveMarketplaceAffiliatePayoutInput: ApproveMarketplaceAffiliatePayoutInput;
  ApproveMarketplaceDraftPost: ApproveMarketplaceDraftPost;
  ApproveMarketplaceDraftPostInput: ApproveMarketplaceDraftPostInput;
  ApproveMarketplaceInput: ApproveMarketplaceInput;
  ApproveTikTokSpecialCampaign: ApproveTikTokSpecialCampaign;
  ApproveTikTokSpecialCampaignInput: ApproveTikTokSpecialCampaignInput;
  AssignAdvertiserForStaffs: AssignAdvertiserForStaffs;
  AssignAdvertiserForStaffsInput: AssignAdvertiserForStaffsInput;
  AssignChatPICInput: AssignChatPICInput;
  AssignChatPICPayload: AssignChatPICPayload;
  AssociateCompany: AssociateCompany;
  AudienceBreakdownAgeGenderRates: AudienceBreakdownAgeGenderRates;
  AudienceBreakdownAgeGroup: AudienceBreakdownAgeGroup;
  AudienceBreakdownAgeRates: AudienceBreakdownAgeRates;
  AudienceBreakdownAreaRate: AudienceBreakdownAreaRate;
  AudienceBreakdownGenderRates: AudienceBreakdownGenderRates;
  Auth0ToLocalJWT: Auth0ToLocalJWT;
  Auth0ToLocalJWTInput: Auth0ToLocalJWTInput;
  AuthTiktokBusinessAccount: AuthTiktokBusinessAccount;
  AuthTiktokBusinessAccountInput: AuthTiktokBusinessAccountInput;
  AutoManagedAffiliateCommissionRateInput: AutoManagedAffiliateCommissionRateInput;
  AutoManagedCampaignCountry: AutoManagedCampaignCountry;
  AutoManagedCampaignForInfluencerSearchJobPayload: AutoManagedCampaignForInfluencerSearchJobPayload;
  AutoManagedCampaignForInfluencerYourJobPayload: AutoManagedCampaignForInfluencerYourJobPayload;
  AutoManagedCampaignInfoPayload: AutoManagedCampaignInfoPayload;
  AutoManagedCampaignMoney: AutoManagedCampaignMoney;
  AutoManagedCampaignPayload: AutoManagedCampaignPayload;
  AutoManagedCommissionInfluencer: AutoManagedCommissionInfluencer;
  AutoManagedInfluencerInfo: AutoManagedInfluencerInfo;
  AutoManagedJoinedInfluencer: AutoManagedJoinedInfluencer;
  AutoManagedJoinedMethodsInput: AutoManagedJoinedMethodsInput;
  AutoManagedJoinedMethodsPayload: AutoManagedJoinedMethodsPayload;
  AutoManagedJoinedMethodsPayloadV2: AutoManagedJoinedMethodsPayloadV2;
  AutoManagedPayoutPeriodInput: AutoManagedPayoutPeriodInput;
  AutoManagedReportByDate: AutoManagedReportByDate;
  AutoManagedReportByInfluencer: AutoManagedReportByInfluencer;
  AutoManagedReportForInfluencer: AutoManagedReportForInfluencer;
  AutoManagedReportSummary: AutoManagedReportSummary;
  AutoManagedReportSummaryForInfluencer: AutoManagedReportSummaryForInfluencer;
  AvailableInfluencer: AvailableInfluencer;
  AvailableInfluencersForStaffPayload: AvailableInfluencersForStaffPayload;
  Bank: Bank;
  BankBranch: BankBranch;
  BankBranchForInfluencer: BankBranchForInfluencer;
  BankForInfluencer: BankForInfluencer;
  BasicAccountAnalyticsPayload: BasicAccountAnalyticsPayload;
  BigInt: Scalars['BigInt'];
  BillingInformation: BillingInformation;
  BioAnalyticsCity: BioAnalyticsCity;
  BioAnalyticsCountry: BioAnalyticsCountry;
  BioAnalyticsOtherLinkClicks: BioAnalyticsOtherLinkClicks;
  BioAnalyticsReferral: BioAnalyticsReferral;
  BioAnalyticsSocialMediaClicks: BioAnalyticsSocialMediaClicks;
  BioAnalyticsTotalClicks: BioAnalyticsTotalClicks;
  BioImage: BioImage;
  BioImageInput: BioImageInput;
  BioInfluencerProfile: BioInfluencerProfile;
  BioLinkInput: BioLinkInput;
  BioProfileInput: BioProfileInput;
  BioSection: BioSection;
  BioSectionV2: BioSectionV2;
  BioSectionsInput: BioSectionsInput;
  BioSectionsInputV2: BioSectionsInputV2;
  BioSocialMedia: BioSocialMedia;
  BioSocialMediaInput: BioSocialMediaInput;
  BioText: BioText;
  BioTextInput: BioTextInput;
  BioUrlLink: BioUrlLink;
  Boolean: Scalars['Boolean'];
  BoostTikTokAdPostInput: BoostTikTokAdPostInput;
  BoostTiktokAdPost: BoostTiktokAdPost;
  BotTextMessageEvent: BotTextMessageEvent;
  BrandAccount: BrandAccount;
  BulkEngagementProposalSocialAccount: BulkEngagementProposalSocialAccount;
  BulkPackageSocialAccount: BulkPackageSocialAccount;
  BulkUpdateEngagementInfluencersBudget: BulkUpdateEngagementInfluencersBudget;
  BulkUpdateEngagementInfluencersBudgetInput: BulkUpdateEngagementInfluencersBudgetInput;
  BulkUpdateEngagementSocialAccountsBudget: BulkUpdateEngagementSocialAccountsBudget;
  BulkUpdateEngagementSocialAccountsBudgetInput: BulkUpdateEngagementSocialAccountsBudgetInput;
  BulkUpdatePackageProposalAccountsBudget: BulkUpdatePackageProposalAccountsBudget;
  BulkUpdatePackageProposalAccountsBudgetInput: BulkUpdatePackageProposalAccountsBudgetInput;
  BulkUpdatePackageProposalInfluencersBudget: BulkUpdatePackageProposalInfluencersBudget;
  BulkUpdatePackageProposalInfluencersBudgetInput: BulkUpdatePackageProposalInfluencersBudgetInput;
  BulkUpdateUserProposalAccountsBudget: BulkUpdateUserProposalAccountsBudget;
  BulkUpdateUserProposalAccountsBudgetInput: BulkUpdateUserProposalAccountsBudgetInput;
  BulkUpdateUserProposalInfluencersBudget: BulkUpdateUserProposalInfluencersBudget;
  BulkUpdateUserProposalInfluencersBudgetInput: BulkUpdateUserProposalInfluencersBudgetInput;
  BulkUserProposalSocialAccount: BulkUserProposalSocialAccount;
  ButtonMessageInput: ButtonMessageInput;
  ButtonMessagePayload: ButtonMessagePayload;
  CampaignCategoryName: CampaignCategoryName;
  CampaignChartReport: CampaignChartReport;
  CampaignChartReportForAllTime: CampaignChartReportForAllTime;
  CampaignCreator: CampaignCreator;
  CampaignCreatorWithName: CampaignCreatorWithName;
  CampaignDailyStats: CampaignDailyStats;
  CampaignDetailReport: CampaignDetailReport;
  CampaignFilter: CampaignFilter;
  CampaignForInfluencerForYourJob: CampaignForInfluencerForYourJob;
  CampaignForInfluencerForYourJobCounts: CampaignForInfluencerForYourJobCounts;
  CampaignForPostFilter: CampaignForPostFilter;
  CampaignForSearch: CampaignForSearch;
  CampaignGridReport: CampaignGridReport;
  CampaignIdentifierInput: CampaignIdentifierInput;
  CampaignInfluencerInfo: CampaignInfluencerInfo;
  CampaignInfluencerReport: CampaignInfluencerReport;
  CampaignPayment: CampaignPayment;
  CampaignPost: CampaignPost;
  CampaignPostGridInfo: CampaignPostGridInfo;
  CampaignPostInfo: CampaignPostInfo;
  CampaignPostMetrics: CampaignPostMetrics;
  CampaignPostOrderBy: CampaignPostOrderBy;
  CampaignPostReportGrid: CampaignPostReportGrid;
  CampaignPosts: CampaignPosts;
  CampaignPostsSummary: CampaignPostsSummary;
  CampaignReportCampaignInfo: CampaignReportCampaignInfo;
  CampaignReportCampaignInfoForInfluencer: CampaignReportCampaignInfoForInfluencer;
  CampaignReportOrderBy: CampaignReportOrderBy;
  CampaignReportSummary: CampaignReportSummary;
  CampaignSummaryDaily: CampaignSummaryDaily;
  CampaignsForSearchJobPayload: CampaignsForSearchJobPayload;
  CancelSubscriptionPlan: CancelSubscriptionPlan;
  CardInput: CardInput;
  CardPayload: CardPayload;
  Category: Category;
  CategoryName: CategoryName;
  ChangeInstagramUGCManagementPostStatusInput: ChangeInstagramUGCManagementPostStatusInput;
  ChangeInstagramUGCManagementPostStatusOutput: ChangeInstagramUGCManagementPostStatusOutput;
  ChatEvent:
    | ResolversParentTypes['BotTextMessageEvent']
    | ResolversParentTypes['InquiryEvent']
    | ResolversParentTypes['InquiryFinishEvent']
    | ResolversParentTypes['MessageTemplateEvent']
    | ResolversParentTypes['StateUpdateEvent']
    | ResolversParentTypes['UserAudioMessageEvent']
    | ResolversParentTypes['UserImageMessageEvent']
    | ResolversParentTypes['UserPostbackEvent']
    | ResolversParentTypes['UserTextMessageEvent']
    | ResolversParentTypes['UserVideoMessageEvent'];
  CheckBioLinkNameAvailabilityInput: CheckBioLinkNameAvailabilityInput;
  CheckBioLinkNameAvailabilityPayload: CheckBioLinkNameAvailabilityPayload;
  City: City;
  CityForInfluencer: CityForInfluencer;
  ClearCmsChannelAssetOwner: ClearCmsChannelAssetOwner;
  ClearCmsChannelAssetOwnerInput: ClearCmsChannelAssetOwnerInput;
  ClickNotificationByEventIdInput: ClickNotificationByEventIdInput;
  ClickNotificationByEventIdPayload: ClickNotificationByEventIdPayload;
  ClickNotificationInput: ClickNotificationInput;
  ClickNotificationPayload: ClickNotificationPayload;
  CloneMarketplace: CloneMarketplace;
  CloneMarketplaceInput: CloneMarketplaceInput;
  CmsAssetShareRateInput: CmsAssetShareRateInput;
  CmsChannelAssetInput: CmsChannelAssetInput;
  CmsChannelShareRateInput: CmsChannelShareRateInput;
  CommentContent: CommentContent;
  CompanyAndRolePair: CompanyAndRolePair;
  ConnectFacebookPagesAndInstagramAccountsInput: ConnectFacebookPagesAndInstagramAccountsInput;
  ConnectFacebookPagesAndInstagramAccountsPayload: ConnectFacebookPagesAndInstagramAccountsPayload;
  ConnectNewShopifyAccountPayload: ConnectNewShopifyAccountPayload;
  ConnectPackageToEngagementProposal: ConnectPackageToEngagementProposal;
  ConnectPackageToEngagementProposalInput: ConnectPackageToEngagementProposalInput;
  ConnectPackageWithInfluencerAccounts: ConnectPackageWithInfluencerAccounts;
  ConnectPackageWithInfluencerAccountsInput: ConnectPackageWithInfluencerAccountsInput;
  ConnectPackageWithSocialAccounts: ConnectPackageWithSocialAccounts;
  ConnectPackageWithSocialAccountsInput: ConnectPackageWithSocialAccountsInput;
  ConnectPackageWithUserProposal: ConnectPackageWithUserProposal;
  ConnectPackageWithUserProposalInput: ConnectPackageWithUserProposalInput;
  ConnectPromotionMethodsInput: ConnectPromotionMethodsInput;
  ConnectPromotionMethodsPayload: ConnectPromotionMethodsPayload;
  ConnectableFacebookPage: ConnectableFacebookPage;
  ConnectableFacebookPageAccount: ConnectableFacebookPageAccount;
  ConnectableFacebookPageAndInstagramAccount: ConnectableFacebookPageAndInstagramAccount;
  ConnectableFacebookPageMainAccount: ConnectableFacebookPageMainAccount;
  ConnectableFacebookPagesAndInstagramAccountsPayload: ConnectableFacebookPagesAndInstagramAccountsPayload;
  ConnectableFacebookPagesPayload: ConnectableFacebookPagesPayload;
  ConnectableInstagramAccount: ConnectableInstagramAccount;
  ConnectableInstagramAccountWithNoConnectedFlag: ConnectableInstagramAccountWithNoConnectedFlag;
  ConnectableInstagramAccountsPayload: ConnectableInstagramAccountsPayload;
  ConnectableOauthAccount: ConnectableOauthAccount;
  ConnectableOauthAccountForTalentSignIn: ConnectableOauthAccountForTalentSignIn;
  ConnectableOauthFacebookAccountForTalentSignIn: ConnectableOauthFacebookAccountForTalentSignIn;
  ConnectableOauthInstagramAccountForTalentSignIn: ConnectableOauthInstagramAccountForTalentSignIn;
  ConnectableOauthPageAndIgAccount: ConnectableOauthPageAndIgAccount;
  ConnectableOauthPageAndIgAccountForSignInFacebook: ConnectableOauthPageAndIgAccountForSignInFacebook;
  ConnectableOauthPageAndIgAccountForTalentSignIn: ConnectableOauthPageAndIgAccountForTalentSignIn;
  ConnectableOauthYouTubeAccountForTalentSignIn: ConnectableOauthYouTubeAccountForTalentSignIn;
  ConnectedAccountForLinkBio: ConnectedAccountForLinkBio;
  ConnectedFacebook: ConnectedFacebook;
  ConnectedFacebookPage: ConnectedFacebookPage;
  ConnectedPage: ConnectedPage;
  ConnectedPageAndIgAccount: ConnectedPageAndIgAccount;
  ConnectedTwitter: ConnectedTwitter;
  ConnectedYouTube: ConnectedYouTube;
  CountUnreadNotificationsByAppInput: CountUnreadNotificationsByAppInput;
  CountriesPayload: CountriesPayload;
  Country: Country;
  CountryAndCurrency: CountryAndCurrency;
  CountryName: CountryName;
  CountryNameForInfluencer: CountryNameForInfluencer;
  CreateAdmin: CreateAdmin;
  CreateAdminInput: CreateAdminInput;
  CreateAdvertiser: CreateAdvertiser;
  CreateAdvertiserInput: CreateAdvertiserInput;
  CreateAdvertiserInputV2: CreateAdvertiserInputV2;
  CreateAdvertiserUser: CreateAdvertiserUser;
  CreateAdvertiserUserInput: CreateAdvertiserUserInput;
  CreateAdvertiserWithUser: CreateAdvertiserWithUser;
  CreateAdvertiserWithUserInput: CreateAdvertiserWithUserInput;
  CreateAdvertiserWithUserInputV2: CreateAdvertiserWithUserInputV2;
  CreateAdvertiserWithUserV2: CreateAdvertiserWithUserV2;
  CreateAgency: CreateAgency;
  CreateAgencyInput: CreateAgencyInput;
  CreateAgencyWithUser: CreateAgencyWithUser;
  CreateAgencyWithUserInput: CreateAgencyWithUserInput;
  CreateCreatorStaffInput: CreateCreatorStaffInput;
  CreateCreatorStaffPayload: CreateCreatorStaffPayload;
  CreateDraftBoostPost: CreateDraftBoostPost;
  CreateDraftBoostPostInput: CreateDraftBoostPostInput;
  CreateEngagement: CreateEngagement;
  CreateEngagementInput: CreateEngagementInput;
  CreateEngagementPostForInfluencerV2: CreateEngagementPostForInfluencerV2;
  CreateEngagementPostInputForInfluencerV2: CreateEngagementPostInputForInfluencerV2;
  CreateEngagementPostInputV2: CreateEngagementPostInputV2;
  CreateEngagementPostV2: CreateEngagementPostV2;
  CreateGeminiThread: CreateGeminiThread;
  CreateInfluencerBioInput: CreateInfluencerBioInput;
  CreateInfluencerBioPayload: CreateInfluencerBioPayload;
  CreateInfluencerInputV2: CreateInfluencerInputV2;
  CreateInfluencerStaffInput: CreateInfluencerStaffInput;
  CreateInfluencerStaffPayload: CreateInfluencerStaffPayload;
  CreateInfluencerV2: CreateInfluencerV2;
  CreateInstagramUGCManagementHashtagInput: CreateInstagramUGCManagementHashtagInput;
  CreateInstagramUGCManagementHashtagOutput: CreateInstagramUGCManagementHashtagOutput;
  CreateInstagramUserHashtagInput: CreateInstagramUserHashtagInput;
  CreateInstagramUserHashtagOutput: CreateInstagramUserHashtagOutput;
  CreateLineBroadcastMessageInput: CreateLineBroadcastMessageInput;
  CreateLineBroadcastMessagePayload: CreateLineBroadcastMessagePayload;
  CreateLineChannelInput: CreateLineChannelInput;
  CreateLineChannelPayload: CreateLineChannelPayload;
  CreateMarketplace: CreateMarketplace;
  CreateMarketplaceDraftPost: CreateMarketplaceDraftPost;
  CreateMarketplaceDraftPostInput: CreateMarketplaceDraftPostInput;
  CreateMarketplaceInput: CreateMarketplaceInput;
  CreateMobileAppVersion: CreateMobileAppVersion;
  CreateMobileAppVersionInput: CreateMobileAppVersionInput;
  CreateOpenAIThread: CreateOpenAIThread;
  CreatePackage: CreatePackage;
  CreatePackageInput: CreatePackageInput;
  CreatePackageWithInfluencerAccounts: CreatePackageWithInfluencerAccounts;
  CreatePackageWithInfluencerAccountsInput: CreatePackageWithInfluencerAccountsInput;
  CreatePackageWithSocialAccounts: CreatePackageWithSocialAccounts;
  CreatePackageWithSocialAccountsInput: CreatePackageWithSocialAccountsInput;
  CreatePackageWithUserProposal: CreatePackageWithUserProposal;
  CreatePackageWithUserProposalInput: CreatePackageWithUserProposalInput;
  CreatePartnerUser: CreatePartnerUser;
  CreatePartnerUserInput: CreatePartnerUserInput;
  CreateSelfRegisteredAdvertiserUser: CreateSelfRegisteredAdvertiserUser;
  CreateSelfRegisteredAdvertiserUserInput: CreateSelfRegisteredAdvertiserUserInput;
  CreateSocialAccountAnalyticsAdminInputV2: CreateSocialAccountAnalyticsAdminInputV2;
  CreateSocialAccountAnalyticsAdminOutputV2: CreateSocialAccountAnalyticsAdminOutputV2;
  CreateSocialAccountAnalyticsAdvertiserInputV2: CreateSocialAccountAnalyticsAdvertiserInputV2;
  CreateSocialAccountAnalyticsAdvertiserOutputV2: CreateSocialAccountAnalyticsAdvertiserOutputV2;
  CreateStaff: CreateStaff;
  CreateStaffInput: CreateStaffInput;
  CreateTalentAgencyUser: CreateTalentAgencyUser;
  CreateTalentAgencyUserInput: CreateTalentAgencyUserInput;
  CreateTalentAgencyWithUser: CreateTalentAgencyWithUser;
  CreateTalentAgencyWithUserInput: CreateTalentAgencyWithUserInput;
  CreateTalentInfluencerInput: CreateTalentInfluencerInput;
  CreateTalentInfluencerPayload: CreateTalentInfluencerPayload;
  CreateTikTokSpecialCampaign: CreateTikTokSpecialCampaign;
  CreateTikTokSpecialCampaignInput: CreateTikTokSpecialCampaignInput;
  CreateTiktokAdAccount: CreateTiktokAdAccount;
  CreateTiktokAdAccountInput: CreateTiktokAdAccountInput;
  CreateTiktokBusinessCenter: CreateTiktokBusinessCenter;
  CreateTiktokBusinessCenterInput: CreateTiktokBusinessCenterInput;
  CreateTiktokUserHashtagInput: CreateTiktokUserHashtagInput;
  CreateTiktokUserHashtagOutput: CreateTiktokUserHashtagOutput;
  CreateTiktokUserKeywordInput: CreateTiktokUserKeywordInput;
  CreateTiktokUserKeywordOutput: CreateTiktokUserKeywordOutput;
  CreatorAddressPayload: CreatorAddressPayload;
  CreatorStaff: CreatorStaff;
  CreatorStaffsCountPayload: CreatorStaffsCountPayload;
  CreatorStaffsInput: CreatorStaffsInput;
  CreatorStaffsPayload: CreatorStaffsPayload;
  CurrentUserCurrency: CurrentUserCurrency;
  CustomPermissionLevel: CustomPermissionLevel;
  CustomPermissionLevelInput: CustomPermissionLevelInput;
  CustomPermissions: CustomPermissions;
  CustomPermissionsInput: CustomPermissionsInput;
  CustomerTagPayload: CustomerTagPayload;
  Date: Scalars['Date'];
  DateTime: Scalars['DateTime'];
  DeleteAdvertiser: DeleteAdvertiser;
  DeleteAgency: DeleteAgency;
  DeleteBrandAccountAnalyticsInput: DeleteBrandAccountAnalyticsInput;
  DeleteBrandAccountAnalyticsOutput: DeleteBrandAccountAnalyticsOutput;
  DeleteCommentInput: DeleteCommentInput;
  DeleteCommentPayload: DeleteCommentPayload;
  DeleteCompareInstagramAccountAnalytics: DeleteCompareInstagramAccountAnalytics;
  DeleteEngagement: DeleteEngagement;
  DeleteEngagementInfluencerProposal: DeleteEngagementInfluencerProposal;
  DeleteEngagementInfluencerProposalInput: DeleteEngagementInfluencerProposalInput;
  DeleteEngagementPostForInfluencer: DeleteEngagementPostForInfluencer;
  DeleteEngagementProposalJoinedAccount: DeleteEngagementProposalJoinedAccount;
  DeleteEngagementProposalJoinedAccountInput: DeleteEngagementProposalJoinedAccountInput;
  DeleteEngagementSocialAccountProposal: DeleteEngagementSocialAccountProposal;
  DeleteEngagementSocialAccountProposalInput: DeleteEngagementSocialAccountProposalInput;
  DeleteFanTagsInput: DeleteFanTagsInput;
  DeleteFanTagsPayload: DeleteFanTagsPayload;
  DeleteGeminiThread: DeleteGeminiThread;
  DeleteGeminiThreadInput: DeleteGeminiThreadInput;
  DeleteInfluencer: DeleteInfluencer;
  DeleteInfluencerPackage: DeleteInfluencerPackage;
  DeleteInstagramCompareAccountAnalyticsInput: DeleteInstagramCompareAccountAnalyticsInput;
  DeleteInstagramHashtagAnalytics: DeleteInstagramHashtagAnalytics;
  DeleteInstagramHashtagAnalyticsInput: DeleteInstagramHashtagAnalyticsInput;
  DeleteInstagramUGCManagementHashtagInput: DeleteInstagramUGCManagementHashtagInput;
  DeleteInstagramUGCManagementHashtagOutput: DeleteInstagramUGCManagementHashtagOutput;
  DeleteInstagramUserHashtagInput: DeleteInstagramUserHashtagInput;
  DeleteInstagramUserHashtagOutput: DeleteInstagramUserHashtagOutput;
  DeleteMarketplace: DeleteMarketplace;
  DeleteOpenAIThread: DeleteOpenAIThread;
  DeleteOpenAIThreadInput: DeleteOpenAIThreadInput;
  DeletePackage: DeletePackage;
  DeleteSocialAccountPackage: DeleteSocialAccountPackage;
  DeleteTalentAgency: DeleteTalentAgency;
  DeleteTikTokSpecialCampaign: DeleteTikTokSpecialCampaign;
  DeleteTikTokSpecialCampaignInput: DeleteTikTokSpecialCampaignInput;
  DeleteTiktokUserHashtagInput: DeleteTiktokUserHashtagInput;
  DeleteTiktokUserHashtagOutput: DeleteTiktokUserHashtagOutput;
  DeleteTiktokUserKeywordInput: DeleteTiktokUserKeywordInput;
  DeleteTiktokUserKeywordOutput: DeleteTiktokUserKeywordOutput;
  DeleteUser: DeleteUser;
  DeleteUserProposalInfluencer: DeleteUserProposalInfluencer;
  DeleteUserProposalInfluencerInput: DeleteUserProposalInfluencerInput;
  DeleteUserProposalSocialAccount: DeleteUserProposalSocialAccount;
  DeleteUserProposalSocialAccountIndividually: DeleteUserProposalSocialAccountIndividually;
  DeleteUserProposalSocialAccountIndividuallyInput: DeleteUserProposalSocialAccountIndividuallyInput;
  DeleteUserProposalSocialAccountInput: DeleteUserProposalSocialAccountInput;
  DeleteYoutubeCompareAccountAnalyticsInput: DeleteYoutubeCompareAccountAnalyticsInput;
  DeleteYoutubeCompareAccountAnalyticsOutput: DeleteYoutubeCompareAccountAnalyticsOutput;
  DeliveryTimingItemInput: DeliveryTimingItemInput;
  DeliveryTimingItemPayload: DeliveryTimingItemPayload;
  DenyEngagementInfluencerProposalList: DenyEngagementInfluencerProposalList;
  DenyEngagementInfluencerProposalListInput: DenyEngagementInfluencerProposalListInput;
  DenyInfluencersUserProposal: DenyInfluencersUserProposal;
  DenyInfluencersUserProposalInput: DenyInfluencersUserProposalInput;
  DisconnectPackageFromEngagementProposal: DisconnectPackageFromEngagementProposal;
  DisconnectPackageFromEngagementProposalInput: DisconnectPackageFromEngagementProposalInput;
  DisconnectPromotionMethodsInput: DisconnectPromotionMethodsInput;
  DisconnectPromotionMethodsPayload: DisconnectPromotionMethodsPayload;
  DisconnectTiktokAdAccount: DisconnectTiktokAdAccount;
  DisconnectTiktokAdAccountInput: DisconnectTiktokAdAccountInput;
  DouyinAccountGeneralCategory: DouyinAccountGeneralCategory;
  DouyinAccountInterest: DouyinAccountInterest;
  DouyinAccountInterestRate: DouyinAccountInterestRate;
  DouyinAudienceSection: DouyinAudienceSection;
  DouyinFollowerRegionRate: DouyinFollowerRegionRate;
  DouyinInfluencer: DouyinInfluencer;
  DouyinInfluencerSearchResult: DouyinInfluencerSearchResult;
  EditSocialAccountAnalyticsAdminInputV2: EditSocialAccountAnalyticsAdminInputV2;
  EditSocialAccountAnalyticsAdminOutputV2: EditSocialAccountAnalyticsAdminOutputV2;
  EditSocialAccountAnalyticsAdvertiserInputV2: EditSocialAccountAnalyticsAdvertiserInputV2;
  EditSocialAccountAnalyticsAdvertiserOutputV2: EditSocialAccountAnalyticsAdvertiserOutputV2;
  EmailInfo: EmailInfo;
  EmailInfoForList: EmailInfoForList;
  EmailListOrderBy: EmailListOrderBy;
  EmailStatistics: EmailStatistics;
  Engagement: Engagement;
  EngagementAddInfluencersToProposalFromAnotherCampaign: EngagementAddInfluencersToProposalFromAnotherCampaign;
  EngagementAddInfluencersToProposalFromAnotherCampaignInput: EngagementAddInfluencersToProposalFromAnotherCampaignInput;
  EngagementCampaignCouponTrackingInput: EngagementCampaignCouponTrackingInput;
  EngagementCampaignCouponTrackingPayload: EngagementCampaignCouponTrackingPayload;
  EngagementCampaignPostReportAudienceBreakdown: EngagementCampaignPostReportAudienceBreakdown;
  EngagementCampaignPostReportAudienceBreakdownForTikTok: EngagementCampaignPostReportAudienceBreakdownForTikTok;
  EngagementCampaignPostReportAudienceBreakdownForYouTube: EngagementCampaignPostReportAudienceBreakdownForYouTube;
  EngagementCampaignPostReportForInfluencer: EngagementCampaignPostReportForInfluencer;
  EngagementCampaignPostsForInsightCheck: EngagementCampaignPostsForInsightCheck;
  EngagementCampaignReport: EngagementCampaignReport;
  EngagementCampaignSearchSelectInfluencers: EngagementCampaignSearchSelectInfluencers;
  EngagementDetail: EngagementDetail;
  EngagementDetailForInfluencer: EngagementDetailForInfluencer;
  EngagementDraftPostHistory: EngagementDraftPostHistory;
  EngagementDraftPostHistoryForInfluencer: EngagementDraftPostHistoryForInfluencer;
  EngagementDraftPostHistoryRecord: EngagementDraftPostHistoryRecord;
  EngagementDraftPostHistoryRecordForInfluencer: EngagementDraftPostHistoryRecordForInfluencer;
  EngagementForInsightCheck: EngagementForInsightCheck;
  EngagementInfluencerBudgetInput: EngagementInfluencerBudgetInput;
  EngagementPost: EngagementPost;
  EngagementPostComment: EngagementPostComment;
  EngagementPostDetail: EngagementPostDetail;
  EngagementPostDetailForInfluencer: EngagementPostDetailForInfluencer;
  EngagementPostDetailForInfluencerV2: EngagementPostDetailForInfluencerV2;
  EngagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  EngagementPostEditV2: EngagementPostEditV2;
  EngagementPostForInfluencer: EngagementPostForInfluencer;
  EngagementPostForInsightCheck: EngagementPostForInsightCheck;
  EngagementPostInfluencer: EngagementPostInfluencer;
  EngagementPostInfluencerWithSocialAccount: EngagementPostInfluencerWithSocialAccount;
  EngagementPostInfluencerWithSocialAccounts: EngagementPostInfluencerWithSocialAccounts;
  EngagementPostMetricsDataForInsightCheck: EngagementPostMetricsDataForInsightCheck;
  EngagementPostSocialAccount: EngagementPostSocialAccount;
  EngagementPostTrafficSource: EngagementPostTrafficSource;
  EngagementProposal: EngagementProposal;
  EngagementSocialAcccountBudgetInput: EngagementSocialAcccountBudgetInput;
  EngagementTracking: EngagementTracking;
  EngagementXhsPostMetricsDataForInsight: EngagementXhsPostMetricsDataForInsight;
  EngagementsForInsightCheck: EngagementsForInsightCheck;
  EngagementsForInsightCheckCount: EngagementsForInsightCheckCount;
  ExistingProfileTags: ExistingProfileTags;
  ExportCmsInvoiceToSpreadsheet: ExportCmsInvoiceToSpreadsheet;
  ExportCmsInvoiceToSpreadsheetInput: ExportCmsInvoiceToSpreadsheetInput;
  ExportDashboardInstagramAccountAnalytics: ExportDashboardInstagramAccountAnalytics;
  ExportInstagramDashboardAccountAnalyticsInput: ExportInstagramDashboardAccountAnalyticsInput;
  ExportInstagramDashboardAccountInfluencerInput: ExportInstagramDashboardAccountInfluencerInput;
  ExportInstagramHashtagPostsToGoogleSpreadsheetInput: ExportInstagramHashtagPostsToGoogleSpreadsheetInput;
  ExportInstagramHashtagPostsToGoogleSpreadsheetOutput: ExportInstagramHashtagPostsToGoogleSpreadsheetOutput;
  ExportYoutubeDashboardAccountAnalyticsInput: ExportYoutubeDashboardAccountAnalyticsInput;
  ExportYoutubeDashboardAccountAnalyticsOutput: ExportYoutubeDashboardAccountAnalyticsOutput;
  ExternalAnalyticsFacebookGetAccountDetailsOutput: ExternalAnalyticsFacebookGetAccountDetailsOutput;
  ExternalAnalyticsFacebookGetTokenWarningsOutput: ExternalAnalyticsFacebookGetTokenWarningsOutput;
  ExternalAnalyticsInstagramGetAccountDetailsOutput: ExternalAnalyticsInstagramGetAccountDetailsOutput;
  ExternalAnalyticsInstagramGetTokenWarningsOutput: ExternalAnalyticsInstagramGetTokenWarningsOutput;
  ExternalAnalyticsSocialAuthRedirectUrlPayload: ExternalAnalyticsSocialAuthRedirectUrlPayload;
  ExternalConnectableFacebookPageAccount: ExternalConnectableFacebookPageAccount;
  ExternalConnectableFacebookPageAnalyticsInput: ExternalConnectableFacebookPageAnalyticsInput;
  ExternalConnectableFacebookPageAnalyticsPayload: ExternalConnectableFacebookPageAnalyticsPayload;
  ExternalConnectableFacebookPageMainAccount: ExternalConnectableFacebookPageMainAccount;
  ExternalConnectableOauthAccount: ExternalConnectableOauthAccount;
  ExternalConnectableOauthPageAndIgAccount: ExternalConnectableOauthPageAndIgAccount;
  ExternalCreateFacebookPageAccountAnalyticsInput: ExternalCreateFacebookPageAccountAnalyticsInput;
  ExternalCreateFacebookPageAccountAnalyticsOutput: ExternalCreateFacebookPageAccountAnalyticsOutput;
  ExternalCreateInstagramAccountAnalyticsInput: ExternalCreateInstagramAccountAnalyticsInput;
  ExternalCreateInstagramAccountAnalyticsOutput: ExternalCreateInstagramAccountAnalyticsOutput;
  ExternalCreateYoutubeAccountAnalyticsInput: ExternalCreateYoutubeAccountAnalyticsInput;
  ExternalCreateYoutubeAccountAnalyticsOutput: ExternalCreateYoutubeAccountAnalyticsOutput;
  ExternalInstagramAnalyticsPostDetails: ExternalInstagramAnalyticsPostDetails;
  ExternalInstagramAnalyticsPostsInDate: ExternalInstagramAnalyticsPostsInDate;
  ExternalInstagramAnalyticsReelDetails: ExternalInstagramAnalyticsReelDetails;
  ExternalInstagramAnalyticsStoryDetails: ExternalInstagramAnalyticsStoryDetails;
  ExternalInstagramDashboardFollowerAnalytics: ExternalInstagramDashboardFollowerAnalytics;
  ExternalInstagramDashboardOverview: ExternalInstagramDashboardOverview;
  ExternalInstagramDashboardPostAnalytics: ExternalInstagramDashboardPostAnalytics;
  ExternalInstagramFeedPost: ExternalInstagramFeedPost;
  ExternalInstagramReelPost: ExternalInstagramReelPost;
  ExternalInstagramStoryPost: ExternalInstagramStoryPost;
  ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput: ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput;
  ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload: ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload;
  ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput: ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput;
  ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload: ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload;
  ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput: ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput;
  ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload: ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload;
  ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload: ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload;
  ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload: ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload;
  ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload: ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload;
  ExternalYoutubeAnalyticsAudiencePayload: ExternalYoutubeAnalyticsAudiencePayload;
  ExternalYoutubeAnalyticsAudienceRegionPayload: ExternalYoutubeAnalyticsAudienceRegionPayload;
  ExternalYoutubeAnalyticsOverviewHistoryItem: ExternalYoutubeAnalyticsOverviewHistoryItem;
  ExternalYoutubeAnalyticsOverviewItem: ExternalYoutubeAnalyticsOverviewItem;
  ExternalYoutubeAnalyticsOverviewPayload: ExternalYoutubeAnalyticsOverviewPayload;
  ExternalYoutubeAnalyticsPostComment: ExternalYoutubeAnalyticsPostComment;
  ExternalYoutubeAnalyticsPostListByDatePayload: ExternalYoutubeAnalyticsPostListByDatePayload;
  ExternalYoutubeAnalyticsPostListItemByDatePayload: ExternalYoutubeAnalyticsPostListItemByDatePayload;
  ExternalYoutubeAnalyticsPostListItemPayload: ExternalYoutubeAnalyticsPostListItemPayload;
  ExternalYoutubeAnalyticsPostListPayload: ExternalYoutubeAnalyticsPostListPayload;
  ExternalYoutubeAnalyticsPostsAverageEngagement: ExternalYoutubeAnalyticsPostsAverageEngagement;
  ExternalYoutubeAnalyticsPostsPostingHabit: ExternalYoutubeAnalyticsPostsPostingHabit;
  ExternalYoutubeAnalyticsPostsSortInput: ExternalYoutubeAnalyticsPostsSortInput;
  ExternalYoutubeAnalyticsPostsStatisticsPayload: ExternalYoutubeAnalyticsPostsStatisticsPayload;
  ExternalYoutubeAnalyticsRelatedPostPayload: ExternalYoutubeAnalyticsRelatedPostPayload;
  ExternalYoutubeAnalyticsRelatedPostsPayload: ExternalYoutubeAnalyticsRelatedPostsPayload;
  ExternalYoutubeAnalyticsTagRankingPayload: ExternalYoutubeAnalyticsTagRankingPayload;
  ExternalYoutubeAnalyticsTagRankingPayloads: ExternalYoutubeAnalyticsTagRankingPayloads;
  ExternalYoutubeAnalyticsTagRankingSortInput: ExternalYoutubeAnalyticsTagRankingSortInput;
  ExternalYoutubePostPayload: ExternalYoutubePostPayload;
  ExternalYoutubeStatisticsData: ExternalYoutubeStatisticsData;
  FacebookAccountInfluencerSearchResultV2: FacebookAccountInfluencerSearchResultV2;
  FacebookAccountInfluencerV2: FacebookAccountInfluencerV2;
  FacebookInfluencer: FacebookInfluencer;
  FacebookOauthInput: FacebookOauthInput;
  FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload: FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload;
  FacebookPageAnalyticsAudienceAgeBreakdownPayload: FacebookPageAnalyticsAudienceAgeBreakdownPayload;
  FacebookPageAnalyticsAudienceGenderBreakdownPayload: FacebookPageAnalyticsAudienceGenderBreakdownPayload;
  FacebookPageAnalyticsAudiencePayload: FacebookPageAnalyticsAudiencePayload;
  FacebookPageAnalyticsAudienceRegionPayload: FacebookPageAnalyticsAudienceRegionPayload;
  FacebookPageAnalyticsOverviewHistoryItem: FacebookPageAnalyticsOverviewHistoryItem;
  FacebookPageAnalyticsOverviewHistoryRateItem: FacebookPageAnalyticsOverviewHistoryRateItem;
  FacebookPageAnalyticsOverviewItem: FacebookPageAnalyticsOverviewItem;
  FacebookPageAnalyticsOverviewPayload: FacebookPageAnalyticsOverviewPayload;
  FacebookPageAnalyticsOverviewRateItem: FacebookPageAnalyticsOverviewRateItem;
  FacebookPageAnalyticsPostComment: FacebookPageAnalyticsPostComment;
  FacebookPageAnalyticsPostPayload: FacebookPageAnalyticsPostPayload;
  FacebookPageAnalyticsPostWithPageDetailsPayload: FacebookPageAnalyticsPostWithPageDetailsPayload;
  FacebookPageAnalyticsPostsAnalyticsPayload: FacebookPageAnalyticsPostsAnalyticsPayload;
  FacebookPageAnalyticsPostsAverageEngagement: FacebookPageAnalyticsPostsAverageEngagement;
  FacebookPageAnalyticsPostsByDatePayload: FacebookPageAnalyticsPostsByDatePayload;
  FacebookPageAnalyticsPostsPayload: FacebookPageAnalyticsPostsPayload;
  FacebookPageAnalyticsPostsPayloads: FacebookPageAnalyticsPostsPayloads;
  FacebookPageAnalyticsPostsSortInput: FacebookPageAnalyticsPostsSortInput;
  FacebookPageForProfileV2: FacebookPageForProfileV2;
  FacebookPageInfluencer: FacebookPageInfluencer;
  FacebookPageInfluencerSearchResultV2: FacebookPageInfluencerSearchResultV2;
  FacebookPageInfluencerV2: FacebookPageInfluencerV2;
  FacebookPagePostsAnalyticsFanActivity: FacebookPagePostsAnalyticsFanActivity;
  FacebookPageStatisticsData: FacebookPageStatisticsData;
  FacebookPostForLinkBio: FacebookPostForLinkBio;
  FanActivity: FanActivity;
  FanDetails: FanDetails;
  FanFormAnswer: FanFormAnswer;
  FanListOrderBy: FanListOrderBy;
  FanOrderDetails: FanOrderDetails;
  FanOrderItem: FanOrderItem;
  FanTag: FanTag;
  FileUploadUrls: FileUploadUrls;
  Float: Scalars['Float'];
  FollowersAgeGenderGroup: FollowersAgeGenderGroup;
  FollowersAgeGroup: FollowersAgeGroup;
  FollowersAgeGroupInput: FollowersAgeGroupInput;
  FollowersByTime: FollowersByTime;
  FollowersCountry: FollowersCountry;
  FormItem: FormItem;
  FormListOrderBy: FormListOrderBy;
  FormQuestionAnswer: FormQuestionAnswer;
  FormReportAnswerOption: FormReportAnswerOption;
  FormReportQuestionAnswer: FormReportQuestionAnswer;
  FormReportSummaryInput: FormReportSummaryInput;
  FormReportSummaryPayload: FormReportSummaryPayload;
  FormRespondentItem: FormRespondentItem;
  FormRespondentsCountInput: FormRespondentsCountInput;
  FormRespondentsCountPayload: FormRespondentsCountPayload;
  FormRespondentsListInput: FormRespondentsListInput;
  FormRespondentsListPayload: FormRespondentsListPayload;
  GeminiMessage: GeminiMessage;
  GenerateImportFansUploadURLPayload: GenerateImportFansUploadURLPayload;
  GenerateMarketplaceCampaignDetailInput: GenerateMarketplaceCampaignDetailInput;
  GenerateMarketplaceCampaignDetailPayload: GenerateMarketplaceCampaignDetailPayload;
  GenerateMarketplacePostCaptionInput: GenerateMarketplacePostCaptionInput;
  GeneratePartnerInfluencerSignUpUrl: GeneratePartnerInfluencerSignUpUrl;
  GenerateTalentInfluencerSignUpUrl: GenerateTalentInfluencerSignUpUrl;
  GenerateTikTokSpecialPostCaptionInput: GenerateTikTokSpecialPostCaptionInput;
  GetAccountInformationPayload: GetAccountInformationPayload;
  GetAllCampaignsCountForSearchJobInput: GetAllCampaignsCountForSearchJobInput;
  GetAllCampaignsCountForSearchJobPayload: GetAllCampaignsCountForSearchJobPayload;
  GetAllCampaignsForSearchJobInput: GetAllCampaignsForSearchJobInput;
  GetAllCampaignsForSearchJobPayload: GetAllCampaignsForSearchJobPayload;
  GetAnyXAccountsByKeywordInput: GetAnyXAccountsByKeywordInput;
  GetAnyXAccountsByKeywordPayload: GetAnyXAccountsByKeywordPayload;
  GetAutoManagedCampaignByIdInput: GetAutoManagedCampaignByIdInput;
  GetAutoManagedCampaignForInfluencerSearchJobInput: GetAutoManagedCampaignForInfluencerSearchJobInput;
  GetAutoManagedCampaignForInfluencerYourJobInput: GetAutoManagedCampaignForInfluencerYourJobInput;
  GetAutoManagedCampaignsCountInput: GetAutoManagedCampaignsCountInput;
  GetAutoManagedCampaignsCountPayload: GetAutoManagedCampaignsCountPayload;
  GetAutoManagedCampaignsInput: GetAutoManagedCampaignsInput;
  GetAutoManagedCampaignsOrderBy: GetAutoManagedCampaignsOrderBy;
  GetAutoManagedCampaignsPayload: GetAutoManagedCampaignsPayload;
  GetChatEventsInput: GetChatEventsInput;
  GetChatEventsPayload: GetChatEventsPayload;
  GetCreatorTagsByIdInput: GetCreatorTagsByIdInput;
  GetCreatorTagsByIdPayload: GetCreatorTagsByIdPayload;
  GetCreatorTagsInput: GetCreatorTagsInput;
  GetCreatorTagsPayload: GetCreatorTagsPayload;
  GetCustomerTagsInput: GetCustomerTagsInput;
  GetCustomerTagsPayload: GetCustomerTagsPayload;
  GetEmailListCountInput: GetEmailListCountInput;
  GetEmailListCountPayload: GetEmailListCountPayload;
  GetEmailListInput: GetEmailListInput;
  GetEmailListPayload: GetEmailListPayload;
  GetEmailOverviewInput: GetEmailOverviewInput;
  GetEmailOverviewPayload: GetEmailOverviewPayload;
  GetEmailRecipientFan: GetEmailRecipientFan;
  GetEmailRecipientTag: GetEmailRecipientTag;
  GetEmailRecipientsCountInput: GetEmailRecipientsCountInput;
  GetEmailRecipientsCountPayload: GetEmailRecipientsCountPayload;
  GetEmailRecipientsInput: GetEmailRecipientsInput;
  GetEmailRecipientsPayload: GetEmailRecipientsPayload;
  GetEmailTemplateInput: GetEmailTemplateInput;
  GetEmailTemplatePayload: GetEmailTemplatePayload;
  GetFanActivitiesInput: GetFanActivitiesInput;
  GetFanActivitiesPayload: GetFanActivitiesPayload;
  GetFanFormResponseInput: GetFanFormResponseInput;
  GetFanFormResponsePayload: GetFanFormResponsePayload;
  GetFanHeaderPayload: GetFanHeaderPayload;
  GetFanInput: GetFanInput;
  GetFanOrdersInput: GetFanOrdersInput;
  GetFanOrdersPayload: GetFanOrdersPayload;
  GetFanParticipationFormListInput: GetFanParticipationFormListInput;
  GetFanParticipationFormListPayload: GetFanParticipationFormListPayload;
  GetFanPayload: GetFanPayload;
  GetFanProvincesInput: GetFanProvincesInput;
  GetFansCountInput: GetFansCountInput;
  GetFansCountPayload: GetFansCountPayload;
  GetFansInput: GetFansInput;
  GetFansPayload: GetFansPayload;
  GetFormInput: GetFormInput;
  GetFormInputPayload: GetFormInputPayload;
  GetFormQuestion: GetFormQuestion;
  GetFormQuestionOption: GetFormQuestionOption;
  GetFormResponseByPageInput: GetFormResponseByPageInput;
  GetFormResponseInput: GetFormResponseInput;
  GetFormResponsePayload: GetFormResponsePayload;
  GetFormTemplateInput: GetFormTemplateInput;
  GetFormTemplateOption: GetFormTemplateOption;
  GetFormTemplatePayload: GetFormTemplatePayload;
  GetFormTemplateQuestion: GetFormTemplateQuestion;
  GetFormsCountInput: GetFormsCountInput;
  GetFormsCountPayload: GetFormsCountPayload;
  GetFormsListInput: GetFormsListInput;
  GetFormsListPayload: GetFormsListPayload;
  GetGeneratedMarketplaceCampaignDetailInput: GetGeneratedMarketplaceCampaignDetailInput;
  GetGeneratedMarketplaceCampaignDetailPayload: GetGeneratedMarketplaceCampaignDetailPayload;
  GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail: GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail;
  GetGeneratedMarketplacePostCaptionInput: GetGeneratedMarketplacePostCaptionInput;
  GetGeneratedMarketplacePostCaptionPayload: GetGeneratedMarketplacePostCaptionPayload;
  GetGeneratedTikTokSpecialPostCaptionInput: GetGeneratedTikTokSpecialPostCaptionInput;
  GetGeneratedTikTokSpecialPostCaptionPayload: GetGeneratedTikTokSpecialPostCaptionPayload;
  GetInfluencerBioAnalyticsInput: GetInfluencerBioAnalyticsInput;
  GetInfluencerBioAnalyticsPayload: GetInfluencerBioAnalyticsPayload;
  GetInfluencerBioPayload: GetInfluencerBioPayload;
  GetInfluencerBioPayloadV2: GetInfluencerBioPayloadV2;
  GetInfluencerChatServicesPayload: GetInfluencerChatServicesPayload;
  GetLineBroadcastEstimateRecipientsInput: GetLineBroadcastEstimateRecipientsInput;
  GetLineBroadcastEstimateRecipientsPayload: GetLineBroadcastEstimateRecipientsPayload;
  GetLineBroadcastMessageInput: GetLineBroadcastMessageInput;
  GetLineBroadcastMessagePayload: GetLineBroadcastMessagePayload;
  GetLineBroadcastSummaryInput: GetLineBroadcastSummaryInput;
  GetLineBroadcastSummaryPayload: GetLineBroadcastSummaryPayload;
  GetLineBroadcastsCountInput: GetLineBroadcastsCountInput;
  GetLineBroadcastsCountPayload: GetLineBroadcastsCountPayload;
  GetLineBroadcastsInput: GetLineBroadcastsInput;
  GetLineBroadcastsPayload: GetLineBroadcastsPayload;
  GetLineChannelPayload: GetLineChannelPayload;
  GetLineChatCountInput: GetLineChatCountInput;
  GetLineChatCountPayload: GetLineChatCountPayload;
  GetLineChatInput: GetLineChatInput;
  GetLineChatPayload: GetLineChatPayload;
  GetLineChatsInput: GetLineChatsInput;
  GetLineChatsPayload: GetLineChatsPayload;
  GetLineLoginDetailPayload: GetLineLoginDetailPayload;
  GetLinePublicKeyPayload: GetLinePublicKeyPayload;
  GetLineServicePayload: GetLineServicePayload;
  GetLinkBioFacebookAccountPostsInput: GetLinkBioFacebookAccountPostsInput;
  GetLinkBioFacebookAccountPostsPayload: GetLinkBioFacebookAccountPostsPayload;
  GetLinkBioFacebookPagePostsInput: GetLinkBioFacebookPagePostsInput;
  GetLinkBioFacebookPagePostsPayload: GetLinkBioFacebookPagePostsPayload;
  GetLinkBioFacebookPostPayload: GetLinkBioFacebookPostPayload;
  GetLinkBioThemeColorPayload: GetLinkBioThemeColorPayload;
  GetMessageTemplatesPayload: GetMessageTemplatesPayload;
  GetPublicInfluencerBioInput: GetPublicInfluencerBioInput;
  GetPublicInfluencerBioPayload: GetPublicInfluencerBioPayload;
  GetSelectableYoutubeAnalyticsChannelsInput: GetSelectableYoutubeAnalyticsChannelsInput;
  GetShopifyAccountPayload: GetShopifyAccountPayload;
  GetTagsAndFansRecipientsInput: GetTagsAndFansRecipientsInput;
  GetTagsAndFansRecipientsPayload: GetTagsAndFansRecipientsPayload;
  GoogleSlideFile: GoogleSlideFile;
  Hashtag: Hashtag;
  HashtagType: HashtagType;
  HubspotCompanyData: HubspotCompanyData;
  HubspotDealData: HubspotDealData;
  HubspotDealDataForSearch: HubspotDealDataForSearch;
  ID: Scalars['ID'];
  ImageInput: ImageInput;
  ImageItemInput: ImageItemInput;
  ImageItemPayload: ImageItemPayload;
  ImageMessageInput: ImageMessageInput;
  ImageMessagePayload: ImageMessagePayload;
  ImagePayload: ImagePayload;
  ImageVideoMessageInput: ImageVideoMessageInput;
  ImageVideoMessagePayload: ImageVideoMessagePayload;
  ImportFansInput: ImportFansInput;
  ImportFansPayload: ImportFansPayload;
  Influencer: Influencer;
  InfluencerAddYoutubeCompareAccountAnalyticsInput: InfluencerAddYoutubeCompareAccountAnalyticsInput;
  InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload: InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload;
  InfluencerAnalyticsSponsoredPostCountPayload: InfluencerAnalyticsSponsoredPostCountPayload;
  InfluencerAnalyticsSponsoredPostPayload: InfluencerAnalyticsSponsoredPostPayload;
  InfluencerAnalyticsTwitterRefreshAvailabilityPayload: InfluencerAnalyticsTwitterRefreshAvailabilityPayload;
  InfluencerAnalyticsTwitterRefreshInput: InfluencerAnalyticsTwitterRefreshInput;
  InfluencerAnalyticsTwitterRefreshOutput: InfluencerAnalyticsTwitterRefreshOutput;
  InfluencerAudienceSection: InfluencerAudienceSection;
  InfluencerBioAnalyticsAudience: InfluencerBioAnalyticsAudience;
  InfluencerBioAnalyticsHistory: InfluencerBioAnalyticsHistory;
  InfluencerBioAnalyticsOverview: InfluencerBioAnalyticsOverview;
  InfluencerBrandAccounts: InfluencerBrandAccounts;
  InfluencerCampaignDetail: InfluencerCampaignDetail;
  InfluencerCampaignDetailByPromotion: InfluencerCampaignDetailByPromotion;
  InfluencerCampaignDetailInfo: InfluencerCampaignDetailInfo;
  InfluencerCampaignDetailInfoByPromotion: InfluencerCampaignDetailInfoByPromotion;
  InfluencerCampaignOrderBy: InfluencerCampaignOrderBy;
  InfluencerCampaignPostData: InfluencerCampaignPostData;
  InfluencerCampaignPostInfo: InfluencerCampaignPostInfo;
  InfluencerCampaignStat: InfluencerCampaignStat;
  InfluencerCampaignStatByPromotion: InfluencerCampaignStatByPromotion;
  InfluencerCampaignSummary: InfluencerCampaignSummary;
  InfluencerDeleteYoutubeCompareAccountAnalyticsInput: InfluencerDeleteYoutubeCompareAccountAnalyticsInput;
  InfluencerEngagement: InfluencerEngagement;
  InfluencerExportDashboardInstagramAccount: InfluencerExportDashboardInstagramAccount;
  InfluencerExportYoutubeDashboardAccountAnalyticsInput: InfluencerExportYoutubeDashboardAccountAnalyticsInput;
  InfluencerFacebookPageAnalyticsAccessTokenWarningPayload: InfluencerFacebookPageAnalyticsAccessTokenWarningPayload;
  InfluencerFeedPost: InfluencerFeedPost;
  InfluencerFollowerGrowthSection: InfluencerFollowerGrowthSection;
  InfluencerHasEstimateApi: InfluencerHasEstimateApi;
  InfluencerInstagramAnalyticsAccessTokenWarningPayload: InfluencerInstagramAnalyticsAccessTokenWarningPayload;
  InfluencerInstagramDataForReconnectPayload: InfluencerInstagramDataForReconnectPayload;
  InfluencerInterest: InfluencerInterest;
  InfluencerManagerOutput: InfluencerManagerOutput;
  InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload: InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload;
  InfluencerMultipleInstagramDataForReconnectPayload: InfluencerMultipleInstagramDataForReconnectPayload;
  InfluencerNewProfileDetail: InfluencerNewProfileDetail;
  InfluencerNewProfileEngagement: InfluencerNewProfileEngagement;
  InfluencerNewProfileFollowers: InfluencerNewProfileFollowers;
  InfluencerNewProfileHeaderV2: InfluencerNewProfileHeaderV2;
  InfluencerNewProfilePostsHabit: InfluencerNewProfilePostsHabit;
  InfluencerNewProfilePostsStats: InfluencerNewProfilePostsStats;
  InfluencerNewProfileSentimentAnalysis: InfluencerNewProfileSentimentAnalysis;
  InfluencerOrderBy: InfluencerOrderBy;
  InfluencerPaymentInformation: InfluencerPaymentInformation;
  InfluencerPaymentRequest: InfluencerPaymentRequest;
  InfluencerPaymentRequests: InfluencerPaymentRequests;
  InfluencerPopularPost: InfluencerPopularPost;
  InfluencerPostDetail: InfluencerPostDetail;
  InfluencerPostReportStatsEngagement: InfluencerPostReportStatsEngagement;
  InfluencerPostReportStatsMarketplace: InfluencerPostReportStatsMarketplace;
  InfluencerPostReportStatsTikTokSpecial: InfluencerPostReportStatsTikTokSpecial;
  InfluencerPostSection: InfluencerPostSection;
  InfluencerPostV3: InfluencerPostV3;
  InfluencerPostsV3: InfluencerPostsV3;
  InfluencerProfileDetailForEngagementProposal: InfluencerProfileDetailForEngagementProposal;
  InfluencerProfileForEngagementProposal: InfluencerProfileForEngagementProposal;
  InfluencerPromotionMethodsPayload: InfluencerPromotionMethodsPayload;
  InfluencerRateCardData: InfluencerRateCardData;
  InfluencerRateCardDetailData: InfluencerRateCardDetailData;
  InfluencerRateCardInput: InfluencerRateCardInput;
  InfluencerRateCardPayload: InfluencerRateCardPayload;
  InfluencerRateCardPriceInput: InfluencerRateCardPriceInput;
  InfluencerRateCardsInput: InfluencerRateCardsInput;
  InfluencerReelPost: InfluencerReelPost;
  InfluencerSearchOrderBy: InfluencerSearchOrderBy;
  InfluencerSearchSortBy: InfluencerSearchSortBy;
  InfluencerSignUpInput: InfluencerSignUpInput;
  InfluencerSignUpInputPayload: InfluencerSignUpInputPayload;
  InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput: InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput;
  InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload: InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload;
  InfluencerSocialNetworkAccountInput: InfluencerSocialNetworkAccountInput;
  InfluencerSocialNetworkAccountOutput: InfluencerSocialNetworkAccountOutput;
  InfluencerSocialPost: InfluencerSocialPost;
  InfluencerSponsoredPost: InfluencerSponsoredPost;
  InfluencerSponsoredPostCountResponse: InfluencerSponsoredPostCountResponse;
  InfluencerSponsoredPostResponse: InfluencerSponsoredPostResponse;
  InfluencerTwitterAnalyticsAccessTokenWarningPayload: InfluencerTwitterAnalyticsAccessTokenWarningPayload;
  InfluencerUpdateDetailV2: InfluencerUpdateDetailV2;
  InfluencerV4: InfluencerV4;
  InfluencerWhitelist: InfluencerWhitelist;
  InfluencerWordCloud: InfluencerWordCloud;
  InfluencerYoutubeAnalyticsAccountCard: InfluencerYoutubeAnalyticsAccountCard;
  InfluencerYoutubeAnalyticsAccountPosts: InfluencerYoutubeAnalyticsAccountPosts;
  InfluencerYoutubeAnalyticsAccountPostsInDate: InfluencerYoutubeAnalyticsAccountPostsInDate;
  InfluencerYoutubeAnalyticsAccountTagRanking: InfluencerYoutubeAnalyticsAccountTagRanking;
  InfluencerYoutubeAnalyticsChannelDetailsPayload: InfluencerYoutubeAnalyticsChannelDetailsPayload;
  InfluencerYoutubeAnalyticsCompareAccountCard: InfluencerYoutubeAnalyticsCompareAccountCard;
  InfluencerYoutubeAnalyticsCompareAccountCards: InfluencerYoutubeAnalyticsCompareAccountCards;
  InfluencerYoutubeCompareAccountOverview: InfluencerYoutubeCompareAccountOverview;
  InfluencerYoutubeCompareAllPostsInDate: InfluencerYoutubeCompareAllPostsInDate;
  InfluencerYoutubeCompareOverview: InfluencerYoutubeCompareOverview;
  InfluencerYoutubeComparePosts: InfluencerYoutubeComparePosts;
  InfluencerYoutubeCompareTagRanking: InfluencerYoutubeCompareTagRanking;
  InfluencerYoutubeSponsoredPost: InfluencerYoutubeSponsoredPost;
  InfluencersPptxSocialAccountInput: InfluencersPptxSocialAccountInput;
  InquiryEvent: InquiryEvent;
  InquiryFinishEvent: InquiryFinishEvent;
  InstagramAccountForProfileV2: InstagramAccountForProfileV2;
  InstagramAnalyticsAccountInfoHeader: InstagramAnalyticsAccountInfoHeader;
  InstagramAnalyticsComments: InstagramAnalyticsComments;
  InstagramAnalyticsCompareAccount: InstagramAnalyticsCompareAccount;
  InstagramAnalyticsCompareAccounts: InstagramAnalyticsCompareAccounts;
  InstagramAnalyticsComparePostsInDate: InstagramAnalyticsComparePostsInDate;
  InstagramAnalyticsGetCommentItemsPayload: InstagramAnalyticsGetCommentItemsPayload;
  InstagramAnalyticsGetCommentsPayload: InstagramAnalyticsGetCommentsPayload;
  InstagramAnalyticsHashtag: InstagramAnalyticsHashtag;
  InstagramAnalyticsHashtags: InstagramAnalyticsHashtags;
  InstagramAnalyticsHistoryDataPoint: InstagramAnalyticsHistoryDataPoint;
  InstagramAnalyticsHistoryEngagementRateDataPoint: InstagramAnalyticsHistoryEngagementRateDataPoint;
  InstagramAnalyticsOverviewData: InstagramAnalyticsOverviewData;
  InstagramAnalyticsOverviewEngagementRateData: InstagramAnalyticsOverviewEngagementRateData;
  InstagramAnalyticsPostAnalysisDetails: InstagramAnalyticsPostAnalysisDetails;
  InstagramAnalyticsPostCommentDetails: InstagramAnalyticsPostCommentDetails;
  InstagramAnalyticsPostDetails: InstagramAnalyticsPostDetails;
  InstagramAnalyticsPostSentimentDetails: InstagramAnalyticsPostSentimentDetails;
  InstagramAnalyticsPostsInDate: InstagramAnalyticsPostsInDate;
  InstagramAnalyticsReelAnalysisDetails: InstagramAnalyticsReelAnalysisDetails;
  InstagramAnalyticsReelCommentDetails: InstagramAnalyticsReelCommentDetails;
  InstagramAnalyticsReelDetails: InstagramAnalyticsReelDetails;
  InstagramAnalyticsStoryDetails: InstagramAnalyticsStoryDetails;
  InstagramAnalyticsSummaryPostsInDate: InstagramAnalyticsSummaryPostsInDate;
  InstagramBrandAmbassadorPost: InstagramBrandAmbassadorPost;
  InstagramBrandAmbassadorPostDetails: InstagramBrandAmbassadorPostDetails;
  InstagramBrandAmbassadorPosts: InstagramBrandAmbassadorPosts;
  InstagramBrandAmbassadorPostsByUsername: InstagramBrandAmbassadorPostsByUsername;
  InstagramCommentAnalytics: InstagramCommentAnalytics;
  InstagramCompareAccount: InstagramCompareAccount;
  InstagramCompareAccountPost: InstagramCompareAccountPost;
  InstagramCompareAccountsPayload: InstagramCompareAccountsPayload;
  InstagramCompareFeedPost: InstagramCompareFeedPost;
  InstagramCompareFollowers: InstagramCompareFollowers;
  InstagramCompareFollowersPrimaryAccount: InstagramCompareFollowersPrimaryAccount;
  InstagramCompareFollowersSecondaryAccount: InstagramCompareFollowersSecondaryAccount;
  InstagramCompareOverview: InstagramCompareOverview;
  InstagramCompareOverviewHistory: InstagramCompareOverviewHistory;
  InstagramCompareOverviewSummary: InstagramCompareOverviewSummary;
  InstagramCompareOverviewSummaryAverageData: InstagramCompareOverviewSummaryAverageData;
  InstagramCompareOverviewSummaryData: InstagramCompareOverviewSummaryData;
  InstagramComparePostAnalysisDetails: InstagramComparePostAnalysisDetails;
  InstagramComparePostDetails: InstagramComparePostDetails;
  InstagramComparePosts: InstagramComparePosts;
  InstagramComparePostsData: InstagramComparePostsData;
  InstagramComparePrimaryAccount: InstagramComparePrimaryAccount;
  InstagramCompareSecondaryAccount: InstagramCompareSecondaryAccount;
  InstagramDashboardFollowerAnalytics: InstagramDashboardFollowerAnalytics;
  InstagramDashboardOverview: InstagramDashboardOverview;
  InstagramDashboardPostAnalytics: InstagramDashboardPostAnalytics;
  InstagramDataForReconnectPayload: InstagramDataForReconnectPayload;
  InstagramFeedPost: InstagramFeedPost;
  InstagramFeedPostOrderBy: InstagramFeedPostOrderBy;
  InstagramFollowerGenderRate: InstagramFollowerGenderRate;
  InstagramFollowersAgeGroup: InstagramFollowersAgeGroup;
  InstagramFollowersAgeRate: InstagramFollowersAgeRate;
  InstagramFollowersCountryRate: InstagramFollowersCountryRate;
  InstagramFollowersRegionRate: InstagramFollowersRegionRate;
  InstagramHashtagFeedPost: InstagramHashtagFeedPost;
  InstagramHashtagFeedPostV2: InstagramHashtagFeedPostV2;
  InstagramHashtagFeedPostsPayload: InstagramHashtagFeedPostsPayload;
  InstagramHashtagPostAnalysisDetails: InstagramHashtagPostAnalysisDetails;
  InstagramHashtagPostDetails: InstagramHashtagPostDetails;
  InstagramHashtagPosts: InstagramHashtagPosts;
  InstagramHashtagPostsData: InstagramHashtagPostsData;
  InstagramHashtagPostsPayload: InstagramHashtagPostsPayload;
  InstagramHashtagsMetricsData: InstagramHashtagsMetricsData;
  InstagramHashtagsOverview: InstagramHashtagsOverview;
  InstagramHashtagsOverviewHistory: InstagramHashtagsOverviewHistory;
  InstagramHashtagsOverviewSummary: InstagramHashtagsOverviewSummary;
  InstagramHashtagsOverviewSummaryData: InstagramHashtagsOverviewSummaryData;
  InstagramInfluencerSearchResultV3: InstagramInfluencerSearchResultV3;
  InstagramInfluencerV3: InstagramInfluencerV3;
  InstagramInteractionCommonPostData: InstagramInteractionCommonPostData;
  InstagramInteractionCommonTaggedPostData: InstagramInteractionCommonTaggedPostData;
  InstagramInteractionOverview: InstagramInteractionOverview;
  InstagramInteractionOverviewHistory: InstagramInteractionOverviewHistory;
  InstagramInteractionOverviewSummary: InstagramInteractionOverviewSummary;
  InstagramInteractionOverviewSummaryData: InstagramInteractionOverviewSummaryData;
  InstagramInteractionPost: InstagramInteractionPost;
  InstagramInteractionPostDetails: InstagramInteractionPostDetails;
  InstagramInteractionPosts: InstagramInteractionPosts;
  InstagramInteractionTaggedPost: InstagramInteractionTaggedPost;
  InstagramInteractionUserAnalytics: InstagramInteractionUserAnalytics;
  InstagramInteractionUserAnalyticsDetails: InstagramInteractionUserAnalyticsDetails;
  InstagramInteractionUserAnalyticsDetailsAndCount: InstagramInteractionUserAnalyticsDetailsAndCount;
  InstagramInteractionUserOrderBy: InstagramInteractionUserOrderBy;
  InstagramPopularHashtags: InstagramPopularHashtags;
  InstagramPostAverageEngagement: InstagramPostAverageEngagement;
  InstagramPostSortInput: InstagramPostSortInput;
  InstagramPostsHabit: InstagramPostsHabit;
  InstagramReelPost: InstagramReelPost;
  InstagramReelPostItem: InstagramReelPostItem;
  InstagramReelPostOrderBy: InstagramReelPostOrderBy;
  InstagramStoryPost: InstagramStoryPost;
  InstagramStoryPostOrderBy: InstagramStoryPostOrderBy;
  InstagramUGCAddedHashtagPayload: InstagramUGCAddedHashtagPayload;
  InstagramUGCManagementBasicInfoPayload: InstagramUGCManagementBasicInfoPayload;
  InstagramUGCManagementListPostsCountPayload: InstagramUGCManagementListPostsCountPayload;
  InstagramUGCManagementListPostsItemPayload: InstagramUGCManagementListPostsItemPayload;
  InstagramUGCManagementListPostsPayload: InstagramUGCManagementListPostsPayload;
  InstagramUGCManagementPostDetailsPayload: InstagramUGCManagementPostDetailsPayload;
  InstagramUGCPostsSortInput: InstagramUGCPostsSortInput;
  InstagramUserHashtag: InstagramUserHashtag;
  InstagramUserHashtagListPostsItemPayload: InstagramUserHashtagListPostsItemPayload;
  InstagramUserHashtagListPostsPayload: InstagramUserHashtagListPostsPayload;
  InstagramUserHashtagPayload: InstagramUserHashtagPayload;
  InstagramUserHashtagPostsSortInput: InstagramUserHashtagPostsSortInput;
  Int: Scalars['Int'];
  IsInfluencerUUUM: IsInfluencerUUUM;
  JSON: Scalars['JSON'];
  JoinAutoManagedCampaignInput: JoinAutoManagedCampaignInput;
  JoinAutoManagedCampaignPayload: JoinAutoManagedCampaignPayload;
  JoinMarketplaceByStaff: JoinMarketplaceByStaff;
  JoinMarketplaceByStaffInput: JoinMarketplaceByStaffInput;
  JoinMarketplaceV2: JoinMarketplaceV2;
  JoinMarketplaceV2Input: JoinMarketplaceV2Input;
  JoinTiktokSpecialCampaign: JoinTiktokSpecialCampaign;
  JoinTiktokSpecialCampaignInput: JoinTiktokSpecialCampaignInput;
  JoinableAccount: JoinableAccount;
  JoinableFbAccount: JoinableFbAccount;
  JoinableInstagramAccount: JoinableInstagramAccount;
  JoinablePromotionMethodEmailNewsLetterPayload: JoinablePromotionMethodEmailNewsLetterPayload;
  JoinablePromotionMethodMobileAppPayload: JoinablePromotionMethodMobileAppPayload;
  JoinablePromotionMethodOfflinePayload: JoinablePromotionMethodOfflinePayload;
  JoinablePromotionMethodPodCastPayload: JoinablePromotionMethodPodCastPayload;
  JoinablePromotionMethodSocialAccountPayload: JoinablePromotionMethodSocialAccountPayload;
  JoinablePromotionMethodWebsitePayload: JoinablePromotionMethodWebsitePayload;
  JoinedCampaign: JoinedCampaign;
  JoinedPromotionMethodEmailNewsLetterPayload: JoinedPromotionMethodEmailNewsLetterPayload;
  JoinedPromotionMethodMobileAppPayload: JoinedPromotionMethodMobileAppPayload;
  JoinedPromotionMethodOfflinePayload: JoinedPromotionMethodOfflinePayload;
  JoinedPromotionMethodPodCastPayload: JoinedPromotionMethodPodCastPayload;
  JoinedPromotionMethodSocialAccountPayload: JoinedPromotionMethodSocialAccountPayload;
  JoinedPromotionMethodWebsitePayload: JoinedPromotionMethodWebsitePayload;
  LineBroadcast: LineBroadcast;
  LineBroadcastFloatValue: LineBroadcastFloatValue;
  LineBroadcastIntValue: LineBroadcastIntValue;
  LineChat: LineChat;
  LineChatPIC: LineChatPIC;
  LineContentItemInput: LineContentItemInput;
  LineContentItemPayload: LineContentItemPayload;
  LinkBioBackground: LinkBioBackground;
  LinkBioPageColor: LinkBioPageColor;
  LinkCmsChannelAssets: LinkCmsChannelAssets;
  LinkCmsChannelAssetsInput: LinkCmsChannelAssetsInput;
  Logo: Logo;
  Long: Scalars['Long'];
  MadeMarketplaceCampaignPost: MadeMarketplaceCampaignPost;
  MadeMarketplaceCampaignPostInput: MadeMarketplaceCampaignPostInput;
  MajorCategory: MajorCategory;
  MarkReadNotificationByAppInput: MarkReadNotificationByAppInput;
  MarkReadNotificationPayload: MarkReadNotificationPayload;
  Marketplace: Marketplace;
  MarketplaceAffiliateCommissionFeeInput: MarketplaceAffiliateCommissionFeeInput;
  MarketplaceAffiliateCommissionInfluencer: MarketplaceAffiliateCommissionInfluencer;
  MarketplaceAffiliateCommissionRateInput: MarketplaceAffiliateCommissionRateInput;
  MarketplaceAffiliateJoinedInfluencer: MarketplaceAffiliateJoinedInfluencer;
  MarketplaceAffiliatePayoutPeriodInput: MarketplaceAffiliatePayoutPeriodInput;
  MarketplaceAffiliateReportByDate: MarketplaceAffiliateReportByDate;
  MarketplaceAffiliateReportByInfluencer: MarketplaceAffiliateReportByInfluencer;
  MarketplaceAffiliateReportByInfluencerOrderBy: MarketplaceAffiliateReportByInfluencerOrderBy;
  MarketplaceAffiliateReportForInfluencer: MarketplaceAffiliateReportForInfluencer;
  MarketplaceAffiliateReportSummary: MarketplaceAffiliateReportSummary;
  MarketplaceAffiliateReportSummaryForInfluencer: MarketplaceAffiliateReportSummaryForInfluencer;
  MarketplaceAffiliateStatsItem: MarketplaceAffiliateStatsItem;
  MarketplaceCampaignCouponTrackingInput: MarketplaceCampaignCouponTrackingInput;
  MarketplaceCampaignCouponTrackingPayload: MarketplaceCampaignCouponTrackingPayload;
  MarketplaceCampaignPostReportForInfluencer: MarketplaceCampaignPostReportForInfluencer;
  MarketplaceCampaignReport: MarketplaceCampaignReport;
  MarketplaceDetail: MarketplaceDetail;
  MarketplaceDetailForInfluencer: MarketplaceDetailForInfluencer;
  MarketplaceDraftPostDetail: MarketplaceDraftPostDetail;
  MarketplaceDraftPostForInfluencer: MarketplaceDraftPostForInfluencer;
  MarketplaceForReorder: MarketplaceForReorder;
  MarketplaceInfluencer: MarketplaceInfluencer;
  MarketplaceInfluencerRevenueInput: MarketplaceInfluencerRevenueInput;
  MarketplaceJoinableAccountsPayload: MarketplaceJoinableAccountsPayload;
  MarketplaceJoinablePromotionMethodsPayload: MarketplaceJoinablePromotionMethodsPayload;
  MarketplaceJoinedAccountPayload: MarketplaceJoinedAccountPayload;
  MarketplaceJoinedMethodsInput: MarketplaceJoinedMethodsInput;
  MarketplaceJoinedMethodsPayload: MarketplaceJoinedMethodsPayload;
  MarketplaceJoinedMethodsPayloadV2: MarketplaceJoinedMethodsPayloadV2;
  MarketplacePost: MarketplacePost;
  MarketplacePostComment: MarketplacePostComment;
  MarketplacePostDetail: MarketplacePostDetail;
  MarketplacePostDetailInfluencer: MarketplacePostDetailInfluencer;
  MarketplacePostHistory: MarketplacePostHistory;
  MarketplacePostInfluencer: MarketplacePostInfluencer;
  MarketplacePostNoRevenue: MarketplacePostNoRevenue;
  MarketplacePostsNoRevenue: MarketplacePostsNoRevenue;
  MarketplaceRejectedAdvertiserInfo: MarketplaceRejectedAdvertiserInfo;
  MarketplaceRevenueItem: MarketplaceRevenueItem;
  MarketplaceTracking: MarketplaceTracking;
  MarketplaceTrackingPostUrlForInfluencer: MarketplaceTrackingPostUrlForInfluencer;
  MaterialName: MaterialName;
  MediaItemInput: MediaItemInput;
  MediaItemPayload: MediaItemPayload;
  MessageTemplateEvent: MessageTemplateEvent;
  MessageTemplateItem: MessageTemplateItem;
  MinorCategory: MinorCategory;
  MobileVersion: MobileVersion;
  Mutation: {};
  NeedReconnectForFacebookPayload: NeedReconnectForFacebookPayload;
  NeedReviewMarketplacePost: NeedReviewMarketplacePost;
  NetSuiteCustomerData: NetSuiteCustomerData;
  NetSuiteCustomerDataForSearch: NetSuiteCustomerDataForSearch;
  NetSuiteVendorData: NetSuiteVendorData;
  NetSuiteVendorDataForSearch: NetSuiteVendorDataForSearch;
  NewAutoManagedCampaignForInfluencerForYourJob: NewAutoManagedCampaignForInfluencerForYourJob;
  NewCampaignPostReport: NewCampaignPostReport;
  NewCampaignReportSummary: NewCampaignReportSummary;
  NewCampaignStatsItemRow: NewCampaignStatsItemRow;
  NewEngagementCampaignForInfluencerForYourJob: NewEngagementCampaignForInfluencerForYourJob;
  NewMarketplaceCampaignForInfluencerForYourJob: NewMarketplaceCampaignForInfluencerForYourJob;
  NewTiktokSpecialCampaignForInfluencerForYourJob: NewTiktokSpecialCampaignForInfluencerForYourJob;
  NotificationPayload: NotificationPayload;
  NotificationsByAppInput: NotificationsByAppInput;
  NotificationsPayload: NotificationsPayload;
  ObtainJSONWebTokenInput: ObtainJSONWebTokenInput;
  ObtainJSONWebTokenPayload: ObtainJSONWebTokenPayload;
  OpenAIMessage: OpenAIMessage;
  OperatedUserInfo: OperatedUserInfo;
  OptionInput: OptionInput;
  PackageCompany: PackageCompany;
  PackageInfluencer: PackageInfluencer;
  PackageProposal: PackageProposal;
  PackageProposalInfluencerBudgetInput: PackageProposalInfluencerBudgetInput;
  PackageProposalSocialAcccountBudgetInput: PackageProposalSocialAcccountBudgetInput;
  PackageProposalSummary: PackageProposalSummary;
  PackageSocialAccount: PackageSocialAccount;
  PackageSummary: PackageSummary;
  PackageSummaryV2: PackageSummaryV2;
  ParticipationForm: ParticipationForm;
  Partner: Partner;
  PartnerAgencyUserWithRole: PartnerAgencyUserWithRole;
  PartnerAgencyWithCompanies: PartnerAgencyWithCompanies;
  PartnerAgencyWithNSVerification: PartnerAgencyWithNSVerification;
  PartnerAgencyWithPermission: PartnerAgencyWithPermission;
  PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload: PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload;
  PayableCampaign: PayableCampaign;
  Payment: Payment;
  PaymentInfluencerAccount: PaymentInfluencerAccount;
  PaymentInformation: PaymentInformation;
  PaymentInformationDetails: PaymentInformationDetails;
  PaymentInformationInput: PaymentInformationInput;
  PaymentPackageStatusResponse: PaymentPackageStatusResponse;
  PaymentRequest: PaymentRequest;
  PersonalUserDetailPayload: PersonalUserDetailPayload;
  PicName: PicName;
  PicUser: PicUser;
  PlainTextMessageInput: PlainTextMessageInput;
  PlainTextMessagePayload: PlainTextMessagePayload;
  PostByHashtag: PostByHashtag;
  PostOrderBy: PostOrderBy;
  PostOrderByV3: PostOrderByV3;
  PostsByTime: PostsByTime;
  PostsForHashtagDashboard: PostsForHashtagDashboard;
  PostsMetricsGridReport: PostsMetricsGridReport;
  PricingTable: PricingTable;
  PricingTableItem: PricingTableItem;
  PrivatePartnerInfluencer: PrivatePartnerInfluencer;
  PrivateTalentInfluencer: PrivateTalentInfluencer;
  PromotionMethodEmailNewsLetterForProfile: PromotionMethodEmailNewsLetterForProfile;
  PromotionMethodEmailNewsLetterPayload: PromotionMethodEmailNewsLetterPayload;
  PromotionMethodInput: PromotionMethodInput;
  PromotionMethodMobileAppForProfile: PromotionMethodMobileAppForProfile;
  PromotionMethodMobileAppPayload: PromotionMethodMobileAppPayload;
  PromotionMethodOfflineForProfile: PromotionMethodOfflineForProfile;
  PromotionMethodOfflinePayload: PromotionMethodOfflinePayload;
  PromotionMethodPodCastForProfile: PromotionMethodPodCastForProfile;
  PromotionMethodPodCastPayload: PromotionMethodPodCastPayload;
  PromotionMethodWebsiteForProfile: PromotionMethodWebsiteForProfile;
  PromotionMethodWebsitePayload: PromotionMethodWebsitePayload;
  ProposalInfluencer: ProposalInfluencer;
  ProposalJobSummary: ProposalJobSummary;
  ProposalSocialAccount: ProposalSocialAccount;
  Province: Province;
  ProvincesPayload: ProvincesPayload;
  PublicBioSection: PublicBioSection;
  PublicBioTheme: PublicBioTheme;
  PublicLinkBioBackground: PublicLinkBioBackground;
  PublicLinkBioPageColor: PublicLinkBioPageColor;
  PublishInfluencerBioInput: PublishInfluencerBioInput;
  PublishInfluencerBioPayload: PublishInfluencerBioPayload;
  Query: {};
  QuestionsInput: QuestionsInput;
  RateCardsInput: RateCardsInput;
  ReSendBcaRequestInput: ReSendBcaRequestInput;
  ReSendBcaRequestPayload: ReSendBcaRequestPayload;
  ReadCampaignIdentifier: ReadCampaignIdentifier;
  ReadPackage: ReadPackage;
  ReadPackageAccount: ReadPackageAccount;
  ReadPackageInfluencers: ReadPackageInfluencers;
  ReadSocialAccountForPackage: ReadSocialAccountForPackage;
  ReadTutorials: ReadTutorials;
  RechargeAdvertiserAccount: RechargeAdvertiserAccount;
  RechargeAdvertiserAccountInput: RechargeAdvertiserAccountInput;
  RecipientDetail: RecipientDetail;
  RecipientItemInput: RecipientItemInput;
  RecipientItemPayload: RecipientItemPayload;
  ReconnectTiktokAdAccount: ReconnectTiktokAdAccount;
  ReconnectTiktokAdAccountInput: ReconnectTiktokAdAccountInput;
  ReconnectedAccountForLinkBio: ReconnectedAccountForLinkBio;
  RefreshPasswordInput: RefreshPasswordInput;
  RefreshPasswordPayload: RefreshPasswordPayload;
  RefreshProfileAccountAvailabilityInput: RefreshProfileAccountAvailabilityInput;
  RefreshProfileAccountAvailabilityPayload: RefreshProfileAccountAvailabilityPayload;
  RefreshProfileAccountInput: RefreshProfileAccountInput;
  RefreshProfileAccountPayload: RefreshProfileAccountPayload;
  RefreshTwitterAccountAvailabilityInput: RefreshTwitterAccountAvailabilityInput;
  RefreshTwitterAccountAvailabilityPayload: RefreshTwitterAccountAvailabilityPayload;
  RefreshTwitterAccountInput: RefreshTwitterAccountInput;
  RefreshTwitterAccountPayload: RefreshTwitterAccountPayload;
  Region: Region;
  RegionName: RegionName;
  RegisterPushToken: RegisterPushToken;
  RegisterPushTokenInput: RegisterPushTokenInput;
  RejectEngagementDraftPost: RejectEngagementDraftPost;
  RejectEngagementDraftPostInput: RejectEngagementDraftPostInput;
  RejectMarketplaceCampaign: RejectMarketplaceCampaign;
  RejectMarketplaceCampaignInput: RejectMarketplaceCampaignInput;
  RejectMarketplaceDraftPost: RejectMarketplaceDraftPost;
  RejectMarketplaceDraftPostInput: RejectMarketplaceDraftPostInput;
  RejectTikTokSpecialCampaign: RejectTikTokSpecialCampaign;
  RejectTikTokSpecialCampaignInput: RejectTikTokSpecialCampaignInput;
  RemoveAllFormResponsesInput: RemoveAllFormResponsesInput;
  RemoveAllFormResponsesPayload: RemoveAllFormResponsesPayload;
  RemoveAllSelectedInfluencers: RemoveAllSelectedInfluencers;
  RemoveAllSelectedInfluencersInput: RemoveAllSelectedInfluencersInput;
  RemoveCreatorStaffInput: RemoveCreatorStaffInput;
  RemoveCreatorStaffPayload: RemoveCreatorStaffPayload;
  RemoveEmailsInput: RemoveEmailsInput;
  RemoveEmailsPayload: RemoveEmailsPayload;
  RemoveFansInput: RemoveFansInput;
  RemoveFansPayload: RemoveFansPayload;
  RemoveFormResponseInput: RemoveFormResponseInput;
  RemoveFormResponsePayload: RemoveFormResponsePayload;
  RemoveFormsInput: RemoveFormsInput;
  RemoveFormsPayload: RemoveFormsPayload;
  RemoveSelectedInfluencers: RemoveSelectedInfluencers;
  RemoveSelectedInfluencersInput: RemoveSelectedInfluencersInput;
  RemoveShopifyAccountInput: RemoveShopifyAccountInput;
  RemoveShopifyAccountPayload: RemoveShopifyAccountPayload;
  ReorderMarketplaceCampaigns: ReorderMarketplaceCampaigns;
  ReportEngagementPostStatus: ReportEngagementPostStatus;
  ReportEngagementPostStatusInput: ReportEngagementPostStatusInput;
  ReportForInfluencer: ReportForInfluencer;
  ReportMarketplacePostStatus: ReportMarketplacePostStatus;
  ReportMarketplacePostStatusInput: ReportMarketplacePostStatusInput;
  ReportTiktokSpecialPostStatus: ReportTiktokSpecialPostStatus;
  ReportTiktokSpecialPostStatusInput: ReportTiktokSpecialPostStatusInput;
  RequestDeleteAccount: RequestDeleteAccount;
  RequestDeleteAccountInput: RequestDeleteAccountInput;
  RequestGoogleSlideCustomisedReport: RequestGoogleSlideCustomisedReport;
  RequestGoogleSlideReport: RequestGoogleSlideReport;
  RequestInfluencerProfilePptx: RequestInfluencerProfilePptx;
  RequestInfluencersProfilePptx: RequestInfluencersProfilePptx;
  RequestInfluencersSocialAccountPptx: RequestInfluencersSocialAccountPptx;
  RequestInstagramUGCManagementPostInput: RequestInstagramUGCManagementPostInput;
  RequestInstagramUGCManagementPostOutput: RequestInstagramUGCManagementPostOutput;
  RequestPackageProposalExcel: RequestPackageProposalExcel;
  RequestPackageProposalPptx: RequestPackageProposalPptx;
  RequestPackageSpreadsheet: RequestPackageSpreadsheet;
  RequestPowerpointCustomisedReport: RequestPowerpointCustomisedReport;
  RequestPowerpointReport: RequestPowerpointReport;
  RequestProposalExcel: RequestProposalExcel;
  RequestProposalPptx: RequestProposalPptx;
  RequestProposalSpreadsheet: RequestProposalSpreadsheet;
  RequestUserProposalExcel: RequestUserProposalExcel;
  RequestUserProposalPptx: RequestUserProposalPptx;
  RequestedBCABrand: RequestedBCABrand;
  RequestedBrandForBCA: RequestedBrandForBCA;
  RequestedInstagramAccountForBCA: RequestedInstagramAccountForBCA;
  ResendEmailForAdvertiser: ResendEmailForAdvertiser;
  ResendEmailForAdvertiserInput: ResendEmailForAdvertiserInput;
  ResendInfluencerForgotPasswordEmailInput: ResendInfluencerForgotPasswordEmailInput;
  ResendInfluencerForgotPasswordEmailPayload: ResendInfluencerForgotPasswordEmailPayload;
  ResendInfluencerSignUpEmailInput: ResendInfluencerSignUpEmailInput;
  ResendInfluencerSignUpEmailPayload: ResendInfluencerSignUpEmailPayload;
  ResendPartnerEmails: ResendPartnerEmails;
  ResendPartnerEmailsInput: ResendPartnerEmailsInput;
  ResetPasswordAndLoginPayload: ResetPasswordAndLoginPayload;
  ResetPasswordInput: ResetPasswordInput;
  ResetPasswordPayload: ResetPasswordPayload;
  ResolveChatInput: ResolveChatInput;
  ResolveChatPayload: ResolveChatPayload;
  RichMessageInput: RichMessageInput;
  RichMessagePayload: RichMessagePayload;
  SelectableBCABrands: SelectableBCABrands;
  SelectableBrandForBCA: SelectableBrandForBCA;
  SelectableInstagramAccountForBCA: SelectableInstagramAccountForBCA;
  SelectableYoutubeAnalyticsChannel: SelectableYoutubeAnalyticsChannel;
  SelectedOauthPageAndIgAccount: SelectedOauthPageAndIgAccount;
  SelectedSocialAccounts: SelectedSocialAccounts;
  SendBcaRequestInput: SendBcaRequestInput;
  SendBcaRequestPayload: SendBcaRequestPayload;
  SendContact: SendContact;
  SendContactInput: SendContactInput;
  SendGeminiMessage: SendGeminiMessage;
  SendGeminiMessageInput: SendGeminiMessageInput;
  SendLineBroadcastMessageInput: SendLineBroadcastMessageInput;
  SendLineBroadcastMessagePayload: SendLineBroadcastMessagePayload;
  SendMessageInput: SendMessageInput;
  SendMessagePayload: SendMessagePayload;
  SendOpenAIMessage: SendOpenAIMessage;
  SendOpenAIMessageInput: SendOpenAIMessageInput;
  ShopeeCustomCampaignInput: ShopeeCustomCampaignInput;
  ShopeeCustomCampaignPayload: ShopeeCustomCampaignPayload;
  ShopifyAuthRedirectUrlInput: ShopifyAuthRedirectUrlInput;
  ShopifyAuthRedirectUrlPayload: ShopifyAuthRedirectUrlPayload;
  ShopifyValidateAuthInput: ShopifyValidateAuthInput;
  ShopifyValidateAuthPayload: ShopifyValidateAuthPayload;
  SignedUrlData: SignedUrlData;
  SimilarPost: SimilarPost;
  SocialAccount: SocialAccount;
  SocialAccountDescription: SocialAccountDescription;
  SocialAccountForProfileV2: SocialAccountForProfileV2;
  SocialAccountInfluencerAudience: SocialAccountInfluencerAudience;
  SocialAccountInfluencerDemographicsAge: SocialAccountInfluencerDemographicsAge;
  SocialAccountInfluencerDemographicsGender: SocialAccountInfluencerDemographicsGender;
  SocialAccountInfluencerOrderBy: SocialAccountInfluencerOrderBy;
  SocialAccountInfluencerOrderByV2: SocialAccountInfluencerOrderByV2;
  SocialAccountPairInput: SocialAccountPairInput;
  SocialAccountPermissionsInput: SocialAccountPermissionsInput;
  SocialAccountPopularPost: SocialAccountPopularPost;
  SocialAccountV2: SocialAccountV2;
  SocialAuthCheckFacebookTokenPayload: SocialAuthCheckFacebookTokenPayload;
  SocialAuthConnectableFacebookPageAnalyticsInput: SocialAuthConnectableFacebookPageAnalyticsInput;
  SocialAuthConnectableFacebookPageAnalyticsPayload: SocialAuthConnectableFacebookPageAnalyticsPayload;
  SocialAuthConnectableInstagramAccountsAnalyticsInput: SocialAuthConnectableInstagramAccountsAnalyticsInput;
  SocialAuthConnectableInstagramAccountsAnalyticsPayload: SocialAuthConnectableInstagramAccountsAnalyticsPayload;
  SocialAuthConnectableTwitterAccountAnalyticsInput: SocialAuthConnectableTwitterAccountAnalyticsInput;
  SocialAuthConnectableTwitterAccountAnalyticsOutput: SocialAuthConnectableTwitterAccountAnalyticsOutput;
  SocialAuthForJoinCampaignInput: SocialAuthForJoinCampaignInput;
  SocialAuthForJoinCampaignPayload: SocialAuthForJoinCampaignPayload;
  SocialAuthReConnectInstagramInput: SocialAuthReConnectInstagramInput;
  SocialAuthReConnectInstagramPayload: SocialAuthReConnectInstagramPayload;
  SocialAuthReconnectFacebookPageAccountAnalyticsInput: SocialAuthReconnectFacebookPageAccountAnalyticsInput;
  SocialAuthReconnectFacebookPageAccountAnalyticsPayload: SocialAuthReconnectFacebookPageAccountAnalyticsPayload;
  SocialAuthReconnectInstagramAccountAnalyticsInput: SocialAuthReconnectInstagramAccountAnalyticsInput;
  SocialAuthReconnectInstagramAccountAnalyticsPayload: SocialAuthReconnectInstagramAccountAnalyticsPayload;
  SocialAuthReconnectTwitterAccountAnalyticsInput: SocialAuthReconnectTwitterAccountAnalyticsInput;
  SocialAuthReconnectTwitterAccountAnalyticsPayload: SocialAuthReconnectTwitterAccountAnalyticsPayload;
  SocialAuthReconnectYouTubeAccountAnalyticsInput: SocialAuthReconnectYouTubeAccountAnalyticsInput;
  SocialAuthReconnectYouTubeAccountAnalyticsPayload: SocialAuthReconnectYouTubeAccountAnalyticsPayload;
  SocialAuthRedirectUrlForInfluencerInput: SocialAuthRedirectUrlForInfluencerInput;
  SocialAuthRedirectUrlForInfluencerPayload: SocialAuthRedirectUrlForInfluencerPayload;
  SocialAuthTalentSignInFacebookInput: SocialAuthTalentSignInFacebookInput;
  SocialAuthTalentSignInFacebookPayload: SocialAuthTalentSignInFacebookPayload;
  SocialAuthTalentSignInInstagramInput: SocialAuthTalentSignInInstagramInput;
  SocialAuthTalentSignInInstagramPayload: SocialAuthTalentSignInInstagramPayload;
  SocialAuthTalentSignInTwitterInput: SocialAuthTalentSignInTwitterInput;
  SocialAuthTalentSignInTwitterPayload: SocialAuthTalentSignInTwitterPayload;
  SocialAuthTalentSignInYouTubeInput: SocialAuthTalentSignInYouTubeInput;
  SocialAuthTalentSignInYouTubePayload: SocialAuthTalentSignInYouTubePayload;
  SponsoredPostOrderBy: SponsoredPostOrderBy;
  StaffComment: StaffComment;
  StateUpdateEvent: StateUpdateEvent;
  String: Scalars['String'];
  StripeDeepLink: StripeDeepLink;
  SubmitFormInput: SubmitFormInput;
  SubmitFormInternalInput: SubmitFormInternalInput;
  SubmitFormInternalPayload: SubmitFormInternalPayload;
  SubmitFormPayload: SubmitFormPayload;
  SubmitQuestion: SubmitQuestion;
  SubmitQuestionInternal: SubmitQuestionInternal;
  SubscribeAnalyticsPlan: SubscribeAnalyticsPlan;
  SubscribeAnalyticsPlanInput: SubscribeAnalyticsPlanInput;
  SubscribeAnalyticsTrialPlan: SubscribeAnalyticsTrialPlan;
  SubscriptionAvailableFeatures: SubscriptionAvailableFeatures;
  SubscriptionCapabilities: SubscriptionCapabilities;
  SubscriptionPlan: SubscriptionPlan;
  SubscriptionPlanDetail: SubscriptionPlanDetail;
  SubscriptionPlanForAdvertiserUserSettings: SubscriptionPlanForAdvertiserUserSettings;
  SudoLimitedLogin: SudoLimitedLogin;
  SudoLimitedLoginInput: SudoLimitedLoginInput;
  SwitchInfluencerForStaffInput: SwitchInfluencerForStaffInput;
  SwitchInfluencerForStaffPayload: SwitchInfluencerForStaffPayload;
  SwitchRoleToAdmin: SwitchRoleToAdmin;
  SwitchRoleToAdminInput: SwitchRoleToAdminInput;
  SwitchRoleToAdvertiser: SwitchRoleToAdvertiser;
  SwitchRoleToAdvertiserInput: SwitchRoleToAdvertiserInput;
  SwitchRoleToAgency: SwitchRoleToAgency;
  SwitchRoleToAgencyInput: SwitchRoleToAgencyInput;
  SwitchRoleToPartner: SwitchRoleToPartner;
  SwitchRoleToPartnerInput: SwitchRoleToPartnerInput;
  SwitchRoleToStaff: SwitchRoleToStaff;
  SwitchRoleToStaffInput: SwitchRoleToStaffInput;
  SwitchRoleToTalentAgency: SwitchRoleToTalentAgency;
  SwitchRoleToTalentAgencyInput: SwitchRoleToTalentAgencyInput;
  TalentAgency: TalentAgency;
  TalentAgencyOrPartner: TalentAgencyOrPartner;
  TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload: TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload;
  TalentAgencyUserWithRole: TalentAgencyUserWithRole;
  TalentAgencyWithCompanies: TalentAgencyWithCompanies;
  TalentAgencyWithNSVerification: TalentAgencyWithNSVerification;
  TalentInfluencerDetailsForEditInput: TalentInfluencerDetailsForEditInput;
  TalentInfluencerDetailsForEditPayload: TalentInfluencerDetailsForEditPayload;
  TextComponentInput: TextComponentInput;
  TextComponentPayload: TextComponentPayload;
  ThreadsInfluencer: ThreadsInfluencer;
  TikTokAdChartReport: TikTokAdChartReport;
  TikTokAdReportSummary: TikTokAdReportSummary;
  TikTokProductCategory: TikTokProductCategory;
  TikTokSpecialAdPostInfo: TikTokSpecialAdPostInfo;
  TikTokSpecialCampaign: TikTokSpecialCampaign;
  TikTokSpecialCampaignChartReport: TikTokSpecialCampaignChartReport;
  TikTokSpecialCampaignDetail: TikTokSpecialCampaignDetail;
  TikTokSpecialCampaignDetailForInfluencer: TikTokSpecialCampaignDetailForInfluencer;
  TikTokSpecialCampaignPost: TikTokSpecialCampaignPost;
  TikTokSpecialCampaignPostReportForInfluencer: TikTokSpecialCampaignPostReportForInfluencer;
  TikTokSpecialDraftAdPostInfo: TikTokSpecialDraftAdPostInfo;
  TikTokSpecialJoinedAccountPayload: TikTokSpecialJoinedAccountPayload;
  TikTokSpecialPackageInfluencer: TikTokSpecialPackageInfluencer;
  TikTokSpecialPostDetail: TikTokSpecialPostDetail;
  TikTokSpecialPostHistory: TikTokSpecialPostHistory;
  TiktokAccountForProfileV2: TiktokAccountForProfileV2;
  TiktokAdAccountInfo: TiktokAdAccountInfo;
  TiktokAdCampaign: TiktokAdCampaign;
  TiktokAdDetailReport: TiktokAdDetailReport;
  TiktokAdGroup: TiktokAdGroup;
  TiktokAdReportOrderBy: TiktokAdReportOrderBy;
  TiktokAdvertiserAccount: TiktokAdvertiserAccount;
  TiktokAdvertiserPlanData: TiktokAdvertiserPlanData;
  TiktokAdvertiserSummaryData: TiktokAdvertiserSummaryData;
  TiktokAudienceSection: TiktokAudienceSection;
  TiktokAuthRedirectUrl: TiktokAuthRedirectUrl;
  TiktokBudgetAllocation: TiktokBudgetAllocation;
  TiktokBusinessAccountInfo: TiktokBusinessAccountInfo;
  TiktokBusinessCenterInfo: TiktokBusinessCenterInfo;
  TiktokFollowerCountryRate: TiktokFollowerCountryRate;
  TiktokFollowersAgeGroup: TiktokFollowersAgeGroup;
  TiktokInfluencer: TiktokInfluencer;
  TiktokInfluencerSearchResultV2: TiktokInfluencerSearchResultV2;
  TiktokInfluencerV2: TiktokInfluencerV2;
  TiktokPostDetailInfluencer: TiktokPostDetailInfluencer;
  TiktokPostInfluencer: TiktokPostInfluencer;
  TiktokSpecialCampaignInfo: TiktokSpecialCampaignInfo;
  TiktokSpecialCampaignReport: TiktokSpecialCampaignReport;
  TiktokSpecialCampaignReportSummary: TiktokSpecialCampaignReportSummary;
  TiktokSpecialInfluencerReport: TiktokSpecialInfluencerReport;
  TiktokSpecialJoinableAccountsPayload: TiktokSpecialJoinableAccountsPayload;
  TiktokSpecialPostInfo: TiktokSpecialPostInfo;
  TiktokSpecialReportOrderBy: TiktokSpecialReportOrderBy;
  TiktokSpecialStatsItemRow: TiktokSpecialStatsItemRow;
  TiktokSpecialSummaryDaily: TiktokSpecialSummaryDaily;
  TiktokUserHashtag: TiktokUserHashtag;
  TiktokUserHashtagListPostsPayload: TiktokUserHashtagListPostsPayload;
  TiktokUserHashtagPayload: TiktokUserHashtagPayload;
  TiktokUserKeyword: TiktokUserKeyword;
  TiktokUserKeywordListPostsPayload: TiktokUserKeywordListPostsPayload;
  TiktokUserKeywordPayload: TiktokUserKeywordPayload;
  TiktokUserTagListPostsItemPayload: TiktokUserTagListPostsItemPayload;
  TiktokUserTagPostDetailsPayload: TiktokUserTagPostDetailsPayload;
  TiktokUserTagPostsSortInput: TiktokUserTagPostsSortInput;
  TokenAuthLongLive: TokenAuthLongLive;
  TokenAuthLongLiveInput: TokenAuthLongLiveInput;
  TotalCmsRevenue: TotalCmsRevenue;
  TotalInfluencerReport: TotalInfluencerReport;
  TotalNumber: TotalNumber;
  TrackYoutubeMusicDownload: TrackYoutubeMusicDownload;
  TrackYoutubeMusicDownloadInput: TrackYoutubeMusicDownloadInput;
  TransferMoneyToAdAccount: TransferMoneyToAdAccount;
  TransferMoneyToAdAccountInput: TransferMoneyToAdAccountInput;
  TrendingFacebookInfluencer: TrendingFacebookInfluencer;
  TrendingFacebookInfluencerSearchResult: TrendingFacebookInfluencerSearchResult;
  TrendingInstagramInfluencerSearchResultV2: TrendingInstagramInfluencerSearchResultV2;
  TrendingInstagramInfluencerV2: TrendingInstagramInfluencerV2;
  TrendingYoutubeInfluencerSearchResultV2: TrendingYoutubeInfluencerSearchResultV2;
  TrendingYoutubeInfluencerV2: TrendingYoutubeInfluencerV2;
  TwitterAnalyticsFeedPostPayload: TwitterAnalyticsFeedPostPayload;
  TwitterAnalyticsOverviewHistoryItem: TwitterAnalyticsOverviewHistoryItem;
  TwitterAnalyticsOverviewHistoryRealItem: TwitterAnalyticsOverviewHistoryRealItem;
  TwitterAnalyticsOverviewItem: TwitterAnalyticsOverviewItem;
  TwitterAnalyticsOverviewPayload: TwitterAnalyticsOverviewPayload;
  TwitterAnalyticsOverviewRealItem: TwitterAnalyticsOverviewRealItem;
  TwitterAnalyticsOverviewRealTotalItem: TwitterAnalyticsOverviewRealTotalItem;
  TwitterAnalyticsOverviewTotalItem: TwitterAnalyticsOverviewTotalItem;
  TwitterAnalyticsPostByIdPayload: TwitterAnalyticsPostByIdPayload;
  TwitterAnalyticsPostComment: TwitterAnalyticsPostComment;
  TwitterAnalyticsPostItemPayload: TwitterAnalyticsPostItemPayload;
  TwitterAnalyticsPostPayload: TwitterAnalyticsPostPayload;
  TwitterAnalyticsPostsByDatePayload: TwitterAnalyticsPostsByDatePayload;
  TwitterAnalyticsPostsPayload: TwitterAnalyticsPostsPayload;
  TwitterAnalyticsPostsSortInput: TwitterAnalyticsPostsSortInput;
  TwitterAnalyticsPostsStatisticPayload: TwitterAnalyticsPostsStatisticPayload;
  TwitterAverageEngagementItemPayload: TwitterAverageEngagementItemPayload;
  TwitterAverageEngagementPayload: TwitterAverageEngagementPayload;
  TwitterForProfileV2: TwitterForProfileV2;
  TwitterInfluencer: TwitterInfluencer;
  TwitterPostingHabitsPayload: TwitterPostingHabitsPayload;
  UUUMSocialAuthSignUpInput: UUUMSocialAuthSignUpInput;
  UUUMSocialAuthSignUpPayload: UUUMSocialAuthSignUpPayload;
  UnApproveApplicantInfluencers: UnApproveApplicantInfluencers;
  UnApproveApplicantInfluencersInput: UnApproveApplicantInfluencersInput;
  UnassignChatPICInput: UnassignChatPICInput;
  UnassignChatPICPayload: UnassignChatPICPayload;
  UnblockInfluencerMarketplace: UnblockInfluencerMarketplace;
  UnblockInfluencerMarketplaceInput: UnblockInfluencerMarketplaceInput;
  UnreadNotificationCountByAppPayload: UnreadNotificationCountByAppPayload;
  UnreadNotificationCountPayload: UnreadNotificationCountPayload;
  UnreadNotificationInput: UnreadNotificationInput;
  UnresolveChatInput: UnresolveChatInput;
  UnresolveChatPayload: UnresolveChatPayload;
  UpdateAccountInformationInput: UpdateAccountInformationInput;
  UpdateAccountInformationPayload: UpdateAccountInformationPayload;
  UpdateAddress: UpdateAddress;
  UpdateAddressInformationInput: UpdateAddressInformationInput;
  UpdateAddressInformationPayload: UpdateAddressInformationPayload;
  UpdateAddressInput: UpdateAddressInput;
  UpdateAdmin: UpdateAdmin;
  UpdateAdminInput: UpdateAdminInput;
  UpdateAdvertiser: UpdateAdvertiser;
  UpdateAdvertiserInput: UpdateAdvertiserInput;
  UpdateAdvertiserUser: UpdateAdvertiserUser;
  UpdateAdvertiserUserInput: UpdateAdvertiserUserInput;
  UpdateAdvertiserUserSettings: UpdateAdvertiserUserSettings;
  UpdateAdvertiserUserSettingsInput: UpdateAdvertiserUserSettingsInput;
  UpdateAgency: UpdateAgency;
  UpdateAgencyInput: UpdateAgencyInput;
  UpdateAutoManagedAffiliateCommissionRates: UpdateAutoManagedAffiliateCommissionRates;
  UpdateAutoManagedAffiliateCommissionRatesInput: UpdateAutoManagedAffiliateCommissionRatesInput;
  UpdateAutoManagedCampaignInput: UpdateAutoManagedCampaignInput;
  UpdateAutoManagedCampaignPayload: UpdateAutoManagedCampaignPayload;
  UpdateAutoManagedCampaignsStatusInput: UpdateAutoManagedCampaignsStatusInput;
  UpdateAutoManagedCampaignsStatusPayload: UpdateAutoManagedCampaignsStatusPayload;
  UpdateBioSectionsInput: UpdateBioSectionsInput;
  UpdateBioSectionsPayload: UpdateBioSectionsPayload;
  UpdateCmsAssetShareRate: UpdateCmsAssetShareRate;
  UpdateCmsAssetShareRateInput: UpdateCmsAssetShareRateInput;
  UpdateCmsChannelShareRate: UpdateCmsChannelShareRate;
  UpdateCmsChannelShareRateInput: UpdateCmsChannelShareRateInput;
  UpdateCommentInput: UpdateCommentInput;
  UpdateCommentPayload: UpdateCommentPayload;
  UpdateEmailTemplateInput: UpdateEmailTemplateInput;
  UpdateEmailTemplatePayload: UpdateEmailTemplatePayload;
  UpdateEngagement: UpdateEngagement;
  UpdateEngagementBreakDownMode: UpdateEngagementBreakDownMode;
  UpdateEngagementBreakDownModeInput: UpdateEngagementBreakDownModeInput;
  UpdateEngagementCouponList: UpdateEngagementCouponList;
  UpdateEngagementCouponListInput: UpdateEngagementCouponListInput;
  UpdateEngagementInfluencerProposalList: UpdateEngagementInfluencerProposalList;
  UpdateEngagementInfluencerProposalListInput: UpdateEngagementInfluencerProposalListInput;
  UpdateEngagementInfluencersBudget: UpdateEngagementInfluencersBudget;
  UpdateEngagementInfluencersBudgetInput: UpdateEngagementInfluencersBudgetInput;
  UpdateEngagementInput: UpdateEngagementInput;
  UpdateEngagementPostForInfluencerV2: UpdateEngagementPostForInfluencerV2;
  UpdateEngagementPostInputForInfluencerV2: UpdateEngagementPostInputForInfluencerV2;
  UpdateEngagementPostInputV2: UpdateEngagementPostInputV2;
  UpdateEngagementPostStatus: UpdateEngagementPostStatus;
  UpdateEngagementPostStatusInput: UpdateEngagementPostStatusInput;
  UpdateEngagementPostV2: UpdateEngagementPostV2;
  UpdateEngagementSocialAccountProposalList: UpdateEngagementSocialAccountProposalList;
  UpdateEngagementSocialAccountProposalListInput: UpdateEngagementSocialAccountProposalListInput;
  UpdateEngagementSocialAccountsBudget: UpdateEngagementSocialAccountsBudget;
  UpdateEngagementSocialAccountsBudgetInput: UpdateEngagementSocialAccountsBudgetInput;
  UpdateEngagementStatus: UpdateEngagementStatus;
  UpdateEngagementStatusInput: UpdateEngagementStatusInput;
  UpdateFanInput: UpdateFanInput;
  UpdateFanPayload: UpdateFanPayload;
  UpdateFormInput: UpdateFormInput;
  UpdateFormPayload: UpdateFormPayload;
  UpdateFormStatusInput: UpdateFormStatusInput;
  UpdateFormStatusPayload: UpdateFormStatusPayload;
  UpdateInfluencerBioInput: UpdateInfluencerBioInput;
  UpdateInfluencerBioPayload: UpdateInfluencerBioPayload;
  UpdateInfluencerInputV2: UpdateInfluencerInputV2;
  UpdateInfluencerPhoneNumberInput: UpdateInfluencerPhoneNumberInput;
  UpdateInfluencerPhoneNumberPayload: UpdateInfluencerPhoneNumberPayload;
  UpdateInfluencerProfile: UpdateInfluencerProfile;
  UpdateInfluencerProfileInput: UpdateInfluencerProfileInput;
  UpdateInfluencerRateCardInput: UpdateInfluencerRateCardInput;
  UpdateInfluencerRateCardPayload: UpdateInfluencerRateCardPayload;
  UpdateInfluencerRateCardsByStaff: UpdateInfluencerRateCardsByStaff;
  UpdateInfluencerRateCardsByStaffInput: UpdateInfluencerRateCardsByStaffInput;
  UpdateInfluencerRateCardsInput: UpdateInfluencerRateCardsInput;
  UpdateInfluencerRateCardsPayload: UpdateInfluencerRateCardsPayload;
  UpdateInfluencerTagsByStaff: UpdateInfluencerTagsByStaff;
  UpdateInfluencerTagsByStaffInput: UpdateInfluencerTagsByStaffInput;
  UpdateInfluencerV2: UpdateInfluencerV2;
  UpdateInternalMemo: UpdateInternalMemo;
  UpdateInternalMemoInput: UpdateInternalMemoInput;
  UpdateLineBroadcastMessageInput: UpdateLineBroadcastMessageInput;
  UpdateLineBroadcastMessagePayload: UpdateLineBroadcastMessagePayload;
  UpdateLinkBioThemeColorInput: UpdateLinkBioThemeColorInput;
  UpdateLinkBioThemeColorPayload: UpdateLinkBioThemeColorPayload;
  UpdateMarketplace: UpdateMarketplace;
  UpdateMarketplaceAffiliateCommissionFees: UpdateMarketplaceAffiliateCommissionFees;
  UpdateMarketplaceAffiliateCommissionFeesInput: UpdateMarketplaceAffiliateCommissionFeesInput;
  UpdateMarketplaceAffiliateCommissionRates: UpdateMarketplaceAffiliateCommissionRates;
  UpdateMarketplaceAffiliateCommissionRatesInput: UpdateMarketplaceAffiliateCommissionRatesInput;
  UpdateMarketplaceCouponList: UpdateMarketplaceCouponList;
  UpdateMarketplaceCouponListInput: UpdateMarketplaceCouponListInput;
  UpdateMarketplaceInfluencerCost: UpdateMarketplaceInfluencerCost;
  UpdateMarketplaceInfluencerCostInput: UpdateMarketplaceInfluencerCostInput;
  UpdateMarketplaceInput: UpdateMarketplaceInput;
  UpdateMarketplacePostStatusInputV2: UpdateMarketplacePostStatusInputV2;
  UpdateMarketplacePostStatusV2: UpdateMarketplacePostStatusV2;
  UpdateMarketplacePostTrackingUrl: UpdateMarketplacePostTrackingUrl;
  UpdateMarketplacePostTrackingUrlInput: UpdateMarketplacePostTrackingUrlInput;
  UpdateOptionInput: UpdateOptionInput;
  UpdatePackage: UpdatePackage;
  UpdatePackageInput: UpdatePackageInput;
  UpdatePackageProposalInfluencersBudget: UpdatePackageProposalInfluencersBudget;
  UpdatePackageProposalInfluencersBudgetInput: UpdatePackageProposalInfluencersBudgetInput;
  UpdatePackageProposalMode: UpdatePackageProposalMode;
  UpdatePackageProposalModeInput: UpdatePackageProposalModeInput;
  UpdatePackageProposalSocialAccountsBudget: UpdatePackageProposalSocialAccountsBudget;
  UpdatePackageProposalSocialAccountsBudgetInput: UpdatePackageProposalSocialAccountsBudgetInput;
  UpdatePartnerAgency: UpdatePartnerAgency;
  UpdatePartnerAgencyInput: UpdatePartnerAgencyInput;
  UpdatePartnerUser: UpdatePartnerUser;
  UpdatePartnerUserInput: UpdatePartnerUserInput;
  UpdatePasswordByAdvertiserUser: UpdatePasswordByAdvertiserUser;
  UpdatePasswordByAdvertiserUserInput: UpdatePasswordByAdvertiserUserInput;
  UpdatePaymentInformation: UpdatePaymentInformation;
  UpdatePaymentInformationInput: UpdatePaymentInformationInput;
  UpdatePaymentStatus: UpdatePaymentStatus;
  UpdatePaymentStatusInput: UpdatePaymentStatusInput;
  UpdatePersoanlUserDetailPayload: UpdatePersoanlUserDetailPayload;
  UpdatePersonalUserDetailInput: UpdatePersonalUserDetailInput;
  UpdatePlainAgency: UpdatePlainAgency;
  UpdatePlainAgencyInput: UpdatePlainAgencyInput;
  UpdateQuestionInput: UpdateQuestionInput;
  UpdateSocialAccountsInput: UpdateSocialAccountsInput;
  UpdateSocialAccountsPayload: UpdateSocialAccountsPayload;
  UpdateSocialUsername: UpdateSocialUsername;
  UpdateSocialUsernameInput: UpdateSocialUsernameInput;
  UpdateStaff: UpdateStaff;
  UpdateStaffInput: UpdateStaffInput;
  UpdateSubscriptionPlan: UpdateSubscriptionPlan;
  UpdateSubscriptionPlanInput: UpdateSubscriptionPlanInput;
  UpdateTalentAgency: UpdateTalentAgency;
  UpdateTalentAgencyInput: UpdateTalentAgencyInput;
  UpdateTalentAgencyUser: UpdateTalentAgencyUser;
  UpdateTalentAgencyUserInput: UpdateTalentAgencyUserInput;
  UpdateTalentInfluencerInput: UpdateTalentInfluencerInput;
  UpdateTalentInfluencerPayload: UpdateTalentInfluencerPayload;
  UpdateTikTokSpecialCampaign: UpdateTikTokSpecialCampaign;
  UpdateTikTokSpecialCampaignInput: UpdateTikTokSpecialCampaignInput;
  UpdateTiktokAdvertiserInfo: UpdateTiktokAdvertiserInfo;
  UpdateTiktokAdvertiserInfoInput: UpdateTiktokAdvertiserInfoInput;
  UpdateTiktokSpecialPostStatus: UpdateTiktokSpecialPostStatus;
  UpdateTiktokSpecialPostStatusInput: UpdateTiktokSpecialPostStatusInput;
  UpdateTtcmPost: UpdateTtcmPost;
  UpdateTtcmPostInput: UpdateTtcmPostInput;
  UpdateUserDetailInput: UpdateUserDetailInput;
  UpdateUserDetailPayload: UpdateUserDetailPayload;
  UpdateUserProposalBreakdownMode: UpdateUserProposalBreakdownMode;
  UpdateUserProposalBreakdownModeInput: UpdateUserProposalBreakdownModeInput;
  UpdateUserProposalInfluencersBudget: UpdateUserProposalInfluencersBudget;
  UpdateUserProposalInfluencersBudgetInput: UpdateUserProposalInfluencersBudgetInput;
  UpdateUserProposalSocialAccountsBudget: UpdateUserProposalSocialAccountsBudget;
  UpdateUserProposalSocialAccountsBudgetInput: UpdateUserProposalSocialAccountsBudgetInput;
  UpdateYoutubeMCNCredentialsInput: UpdateYoutubeMCNCredentialsInput;
  UpdateYoutubeMCNCredentialsPayload: UpdateYoutubeMCNCredentialsPayload;
  UploadEngagementReportFile: UploadEngagementReportFile;
  UploadEngagementReportFileInput: UploadEngagementReportFileInput;
  UploadEngagementReportFromInsightCheck: UploadEngagementReportFromInsightCheck;
  UploadEngagementReportFromInsightCheckInput: UploadEngagementReportFromInsightCheckInput;
  UploadEngagementXhsInsightCheck: UploadEngagementXhsInsightCheck;
  UploadEngagementXhsInsightCheckInput: UploadEngagementXhsInsightCheckInput;
  UploadOpenAIImages: UploadOpenAIImages;
  UploadOpenAIImagesInput: UploadOpenAIImagesInput;
  UserAudioMessageEvent: UserAudioMessageEvent;
  UserDefaultPermissions: UserDefaultPermissions;
  UserDetail: UserDetail;
  UserDetailInput: UserDetailInput;
  UserDetailPayload: UserDetailPayload;
  UserImageMessageEvent: UserImageMessageEvent;
  UserPostbackEvent: UserPostbackEvent;
  UserProposal: UserProposal;
  UserProposalInfluencer: UserProposalInfluencer;
  UserProposalInfluencerBudgetInput: UserProposalInfluencerBudgetInput;
  UserProposalJobSummary: UserProposalJobSummary;
  UserProposalSocialAcccountBudgetInput: UserProposalSocialAcccountBudgetInput;
  UserProposalSocialAccount: UserProposalSocialAccount;
  UserTextMessageEvent: UserTextMessageEvent;
  UserVideoMessageEvent: UserVideoMessageEvent;
  VerifyInput: VerifyInput;
  VerifyLineIDTokenInput: VerifyLineIDTokenInput;
  VerifyLineIDTokenPayload: VerifyLineIDTokenPayload;
  VerifyPayload: VerifyPayload;
  VideoInput: VideoInput;
  VideoPayload: VideoPayload;
  WithdrawMoneyFromAdAccount: WithdrawMoneyFromAdAccount;
  XhsAccountInterest: XhsAccountInterest;
  XhsAudienceSection: XhsAudienceSection;
  XhsEngagementInsightSection: XhsEngagementInsightSection;
  XhsInfluencer: XhsInfluencer;
  XhsInfluencerSearchResult: XhsInfluencerSearchResult;
  XhsPostSection: XhsPostSection;
  YouTubeBrandAccount: YouTubeBrandAccount;
  YouTubeBrandAccounts: YouTubeBrandAccounts;
  YouTubeCmsRevenueForInfluencer: YouTubeCmsRevenueForInfluencer;
  YouTubeCmsVideoAudience: YouTubeCmsVideoAudience;
  YouTubeCmsVideoStats: YouTubeCmsVideoStats;
  YouTubeInfluencer: YouTubeInfluencer;
  YoutubeAccountForProfileV2: YoutubeAccountForProfileV2;
  YoutubeAgeGenderGroup: YoutubeAgeGenderGroup;
  YoutubeAnalyticsAccountCard: YoutubeAnalyticsAccountCard;
  YoutubeAnalyticsAccountOverview: YoutubeAnalyticsAccountOverview;
  YoutubeAnalyticsAccountPosts: YoutubeAnalyticsAccountPosts;
  YoutubeAnalyticsAccountPostsInDate: YoutubeAnalyticsAccountPostsInDate;
  YoutubeAnalyticsAccountTagRanking: YoutubeAnalyticsAccountTagRanking;
  YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload: YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload;
  YoutubeAnalyticsAudienceAgeBreakdownPayload: YoutubeAnalyticsAudienceAgeBreakdownPayload;
  YoutubeAnalyticsAudienceGenderBreakdownPayload: YoutubeAnalyticsAudienceGenderBreakdownPayload;
  YoutubeAnalyticsAudiencePayload: YoutubeAnalyticsAudiencePayload;
  YoutubeAnalyticsAudienceRegionPayload: YoutubeAnalyticsAudienceRegionPayload;
  YoutubeAnalyticsCategory: YoutubeAnalyticsCategory;
  YoutubeAnalyticsCommentsPayload: YoutubeAnalyticsCommentsPayload;
  YoutubeAnalyticsCompareAccountCard: YoutubeAnalyticsCompareAccountCard;
  YoutubeAnalyticsCompareAccountCards: YoutubeAnalyticsCompareAccountCards;
  YoutubeAnalyticsComparePostComment: YoutubeAnalyticsComparePostComment;
  YoutubeAnalyticsComparePostsSort: YoutubeAnalyticsComparePostsSort;
  YoutubeAnalyticsCompareRelatedPost: YoutubeAnalyticsCompareRelatedPost;
  YoutubeAnalyticsCompareRelatedPosts: YoutubeAnalyticsCompareRelatedPosts;
  YoutubeAnalyticsCountriesCategoriesPayload: YoutubeAnalyticsCountriesCategoriesPayload;
  YoutubeAnalyticsCountry: YoutubeAnalyticsCountry;
  YoutubeAnalyticsHistoryDataPoint: YoutubeAnalyticsHistoryDataPoint;
  YoutubeAnalyticsOverviewHistoryItem: YoutubeAnalyticsOverviewHistoryItem;
  YoutubeAnalyticsOverviewItem: YoutubeAnalyticsOverviewItem;
  YoutubeAnalyticsOverviewPayload: YoutubeAnalyticsOverviewPayload;
  YoutubeAnalyticsPostComment: YoutubeAnalyticsPostComment;
  YoutubeAnalyticsPostCommentItem: YoutubeAnalyticsPostCommentItem;
  YoutubeAnalyticsPostPayload: YoutubeAnalyticsPostPayload;
  YoutubeAnalyticsPostPayloads: YoutubeAnalyticsPostPayloads;
  YoutubeAnalyticsPostWithChannelDetailsPayload: YoutubeAnalyticsPostWithChannelDetailsPayload;
  YoutubeAnalyticsPostsAverageEngagement: YoutubeAnalyticsPostsAverageEngagement;
  YoutubeAnalyticsPostsPayload: YoutubeAnalyticsPostsPayload;
  YoutubeAnalyticsPostsPayloads: YoutubeAnalyticsPostsPayloads;
  YoutubeAnalyticsPostsPostingHabit: YoutubeAnalyticsPostsPostingHabit;
  YoutubeAnalyticsPostsSortInput: YoutubeAnalyticsPostsSortInput;
  YoutubeAnalyticsPostsStatisticsPayload: YoutubeAnalyticsPostsStatisticsPayload;
  YoutubeAnalyticsRelatedPostPayload: YoutubeAnalyticsRelatedPostPayload;
  YoutubeAnalyticsRelatedPostsPayload: YoutubeAnalyticsRelatedPostsPayload;
  YoutubeAnalyticsShortsPayload: YoutubeAnalyticsShortsPayload;
  YoutubeAnalyticsShortsPayloads: YoutubeAnalyticsShortsPayloads;
  YoutubeAnalyticsSponsoredPostsSortInput: YoutubeAnalyticsSponsoredPostsSortInput;
  YoutubeAnalyticsTagRankingPayload: YoutubeAnalyticsTagRankingPayload;
  YoutubeAnalyticsTagRankingPayloads: YoutubeAnalyticsTagRankingPayloads;
  YoutubeAnalyticsTagRankingSortInput: YoutubeAnalyticsTagRankingSortInput;
  YoutubeAnalyticsTrendPayload: YoutubeAnalyticsTrendPayload;
  YoutubeAnalyticsTrendPostDetailsPayload: YoutubeAnalyticsTrendPostDetailsPayload;
  YoutubeAnalyticsTrendsPayloads: YoutubeAnalyticsTrendsPayloads;
  YoutubeAudienceCountryRate: YoutubeAudienceCountryRate;
  YoutubeAudienceGenderRate: YoutubeAudienceGenderRate;
  YoutubeCmsChannelDetail: YoutubeCmsChannelDetail;
  YoutubeCmsChannelForInfluencer: YoutubeCmsChannelForInfluencer;
  YoutubeCmsChannelInfo: YoutubeCmsChannelInfo;
  YoutubeCmsConfirmedAsset: YoutubeCmsConfirmedAsset;
  YoutubeCmsConfirmedChannel: YoutubeCmsConfirmedChannel;
  YoutubeCmsConfirmedChannelSummary: YoutubeCmsConfirmedChannelSummary;
  YoutubeCmsConfirmedRevenueLatestMonth: YoutubeCmsConfirmedRevenueLatestMonth;
  YoutubeCmsEstimateAsset: YoutubeCmsEstimateAsset;
  YoutubeCmsEstimateChannel: YoutubeCmsEstimateChannel;
  YoutubeCmsEstimateChannelSummary: YoutubeCmsEstimateChannelSummary;
  YoutubeCmsInvoice: YoutubeCmsInvoice;
  YoutubeCmsInvoiceDownloadUrl: YoutubeCmsInvoiceDownloadUrl;
  YoutubeCmsInvoiceForInfluencer: YoutubeCmsInvoiceForInfluencer;
  YoutubeCmsMusicForInfluencer: YoutubeCmsMusicForInfluencer;
  YoutubeCmsStartMonth: YoutubeCmsStartMonth;
  YoutubeCmsVideo: YoutubeCmsVideo;
  YoutubeCompareAccountOverview: YoutubeCompareAccountOverview;
  YoutubeCompareAccountPost: YoutubeCompareAccountPost;
  YoutubeCompareAccountPosts: YoutubeCompareAccountPosts;
  YoutubeCompareAccountPostsInDate: YoutubeCompareAccountPostsInDate;
  YoutubeCompareAccountTagRanking: YoutubeCompareAccountTagRanking;
  YoutubeCompareAllPostsInDate: YoutubeCompareAllPostsInDate;
  YoutubeCompareOverview: YoutubeCompareOverview;
  YoutubeCompareOverviewHistory: YoutubeCompareOverviewHistory;
  YoutubeCompareOverviewSummary: YoutubeCompareOverviewSummary;
  YoutubeCompareOverviewSummaryData: YoutubeCompareOverviewSummaryData;
  YoutubeComparePost: YoutubeComparePost;
  YoutubeComparePostList: YoutubeComparePostList;
  YoutubeComparePostListItem: YoutubeComparePostListItem;
  YoutubeComparePosts: YoutubeComparePosts;
  YoutubeCompareTagRanking: YoutubeCompareTagRanking;
  YoutubeInfluencerSearchResultV3: YoutubeInfluencerSearchResultV3;
  YoutubeInfluencerV3: YoutubeInfluencerV3;
  YoutubeMCNAdminRedirectUrlInput: YoutubeMCNAdminRedirectUrlInput;
  YoutubeMCNAdminRedirectUrlPayload: YoutubeMCNAdminRedirectUrlPayload;
  YoutubeMusicCategory: YoutubeMusicCategory;
  YoutubeMusicItem: YoutubeMusicItem;
  YoutubeStatisticsData: YoutubeStatisticsData;
  YoutubeTagRanking: YoutubeTagRanking;
  _FieldSet: Scalars['_FieldSet'];
  _Service: _Service;
}>;

export type AcceptedPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AcceptedPayload'] = ResolversParentTypes['AcceptedPayload']
> = ResolversObject<{
  accepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsAccountManagerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AccountAnalyticsAccountManager'] = ResolversParentTypes['AccountAnalyticsAccountManager']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsAdvertiserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AccountAnalyticsAdvertiser'] = ResolversParentTypes['AccountAnalyticsAdvertiser']
> = ResolversObject<{
  country?: Resolver<ResolversTypes['Country'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsAdvertiserDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AccountAnalyticsAdvertiserDetails'] = ResolversParentTypes['AccountAnalyticsAdvertiserDetails']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountAnalyticsDetail'] = ResolversParentTypes['AccountAnalyticsDetail']
> = ResolversObject<{
  accountManagers?: Resolver<ReadonlyArray<ResolversTypes['AccountAnalyticsAccountManager']>, ParentType, ContextType>;
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertiser?: Resolver<ResolversTypes['AccountAnalyticsAdvertiserDetails'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialMediaAccounts?: Resolver<ResolversTypes['AccountAnalyticsSocialMediaAccount'], ParentType, ContextType>;
  subscriptionPlan?: Resolver<ResolversTypes['AnalyticsSubscriptionPlanType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsFacebookPageAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AccountAnalyticsFacebookPageAccount'] = ResolversParentTypes['AccountAnalyticsFacebookPageAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbPageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbPageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsForAdvertiserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AccountAnalyticsForAdvertiserPayload'] = ResolversParentTypes['AccountAnalyticsForAdvertiserPayload']
> = ResolversObject<{
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialMediaAccounts?: Resolver<ResolversTypes['AccountAnalyticsSocialMediaAccount'], ParentType, ContextType>;
  subscriptionPlan?: Resolver<ResolversTypes['AnalyticsSubscriptionPlanType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsInstagramAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AccountAnalyticsInstagramAccount'] = ResolversParentTypes['AccountAnalyticsInstagramAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  businessAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbPageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbPageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountAnalyticsPayload'] = ResolversParentTypes['AccountAnalyticsPayload']
> = ResolversObject<{
  accountManagers?: Resolver<ReadonlyArray<ResolversTypes['AccountAnalyticsAccountManager']>, ParentType, ContextType>;
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertiser?: Resolver<ResolversTypes['AccountAnalyticsAdvertiser'], ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialMediaAccounts?: Resolver<ResolversTypes['AccountAnalyticsSocialMediaAccount'], ParentType, ContextType>;
  subscriptionPlan?: Resolver<ResolversTypes['AnalyticsSubscriptionPlanType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsSocialMediaAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AccountAnalyticsSocialMediaAccount'] = ResolversParentTypes['AccountAnalyticsSocialMediaAccount']
> = ResolversObject<{
  facebookPage?: Resolver<Maybe<ResolversTypes['AccountAnalyticsFacebookPageAccount']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['AccountAnalyticsInstagramAccount']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['AccountAnalyticsTwitterAccount']>, ParentType, ContextType>;
  youtube?: Resolver<Maybe<ResolversTypes['AccountAnalyticsYouTubeAccount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsTwitterAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AccountAnalyticsTwitterAccount'] = ResolversParentTypes['AccountAnalyticsTwitterAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  twitterUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAnalyticsYouTubeAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AccountAnalyticsYouTubeAccount'] = ResolversParentTypes['AccountAnalyticsYouTubeAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountAudienceDataRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountAudienceDataRate'] = ResolversParentTypes['AccountAudienceDataRate']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActionLabelPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActionLabelPayload'] = ResolversParentTypes['ActionLabelPayload']
> = ResolversObject<{
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivityChangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityChange'] = ResolversParentTypes['ActivityChange']
> = ResolversObject<{
  key?: Resolver<ResolversTypes['ChangeType'], ParentType, ContextType>;
  newValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oldValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddAllSelectedInfluencersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddAllSelectedInfluencers'] = ResolversParentTypes['AddAllSelectedInfluencers']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddCommentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddCommentPayload'] = ResolversParentTypes['AddCommentPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddCompareInstagramAccountAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddCompareInstagramAccountAnalytics'] = ResolversParentTypes['AddCompareInstagramAccountAnalytics']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddEmailTemplatePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddEmailTemplatePayload'] = ResolversParentTypes['AddEmailTemplatePayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddFanPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddFanPayload'] = ResolversParentTypes['AddFanPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddFanTagsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddFanTagsPayload'] = ResolversParentTypes['AddFanTagsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddFormPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddFormPayload'] = ResolversParentTypes['AddFormPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddInfluencerToUserProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddInfluencerToUserProposal'] = ResolversParentTypes['AddInfluencerToUserProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddInstagramHashtagAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddInstagramHashtagAnalytics'] = ResolversParentTypes['AddInstagramHashtagAnalytics']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddMarketplacePostUrlResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddMarketplacePostUrl'] = ResolversParentTypes['AddMarketplacePostUrl']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddMarketplacePostUrlForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddMarketplacePostUrlForInfluencer'] = ResolversParentTypes['AddMarketplacePostUrlForInfluencer']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddReadTutorialResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddReadTutorial'] = ResolversParentTypes['AddReadTutorial']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddSelectedInfluencersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddSelectedInfluencers'] = ResolversParentTypes['AddSelectedInfluencers']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddSocialAccountToUserProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddSocialAccountToUserProposal'] = ResolversParentTypes['AddSocialAccountToUserProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddStaffAdvertisersToAnotherStaffResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddStaffAdvertisersToAnotherStaff'] = ResolversParentTypes['AddStaffAdvertisersToAnotherStaff']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddYoutubeCompareAccountAnalyticsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddYoutubeCompareAccountAnalyticsOutput'] = ResolversParentTypes['AddYoutubeCompareAccountAnalyticsOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']
> = ResolversObject<{
  info?: Resolver<Maybe<ResolversTypes['AddressDetails']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddressDetails'] = ResolversParentTypes['AddressDetails']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  province?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressForInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddressForInfluencer'] = ResolversParentTypes['AddressForInfluencer']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  province?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdvertiserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Advertiser'] = ResolversParentTypes['Advertiser']
> = ResolversObject<{
  anyxMasterId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  anyxMasterName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  hubspotId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  internalNetsuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<ReadonlyArray<ResolversTypes['AdvertiserUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdvertiserForAdvertiserUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AdvertiserForAdvertiserUser'] = ResolversParentTypes['AdvertiserForAdvertiserUser']
> = ResolversObject<{
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdvertiserForAdvertiserUserSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AdvertiserForAdvertiserUserSettings'] = ResolversParentTypes['AdvertiserForAdvertiserUserSettings']
> = ResolversObject<{
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdvertiserForListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdvertiserForList'] = ResolversParentTypes['AdvertiserForList']
> = ResolversObject<{
  analyticsPlan?: Resolver<Maybe<ResolversTypes['AnalyticsSubscriptionPlanType']>, ParentType, ContextType>;
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  hubspotId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  internalNetsuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tiktokPlan?: Resolver<Maybe<ResolversTypes['TikTokAdvertiserPlanFilter']>, ParentType, ContextType>;
  users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  verifiedStatus?: Resolver<Maybe<ResolversTypes['NetSuiteVerifiedStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdvertiserForSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdvertiserForSearch'] = ResolversParentTypes['AdvertiserForSearch']
> = ResolversObject<{
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  hubspotId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdvertiserNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdvertiserName'] = ResolversParentTypes['AdvertiserName']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdvertiserUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdvertiserUser'] = ResolversParentTypes['AdvertiserUser']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdvertiserUserSettingsDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AdvertiserUserSettingsDetails'] = ResolversParentTypes['AdvertiserUserSettingsDetails']
> = ResolversObject<{
  advertiser?: Resolver<ResolversTypes['AdvertiserForAdvertiserUserSettings'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasStripeSubscriptionPlan?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationSetting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  subscriptionPlan?: Resolver<
    Maybe<ResolversTypes['SubscriptionPlanForAdvertiserUserSettings']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdvertiserUserWithRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdvertiserUserWithRole'] = ResolversParentTypes['AdvertiserUserWithRole']
> = ResolversObject<{
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AffiliateCampaignChartReportResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AffiliateCampaignChartReport'] = ResolversParentTypes['AffiliateCampaignChartReport']
> = ResolversObject<{
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  grossProfit?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgenciesForSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgenciesForSearch'] = ResolversParentTypes['AgenciesForSearch']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyLogoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyLogo'] = ResolversParentTypes['AgencyLogo']
> = ResolversObject<{
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyUser'] = ResolversParentTypes['AgencyUser']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyUserWithRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyUserWithRole'] = ResolversParentTypes['AgencyUserWithRole']
> = ResolversObject<{
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyWithAdvertisersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyWithAdvertisers'] = ResolversParentTypes['AgencyWithAdvertisers']
> = ResolversObject<{
  advertiserIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  hubspotId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  internalNetsuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<ReadonlyArray<ResolversTypes['AgencyUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyWithNSVerificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyWithNSVerification'] = ResolversParentTypes['AgencyWithNSVerification']
> = ResolversObject<{
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  hubspotId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  internalNetsuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  verifiedStatus?: Resolver<Maybe<ResolversTypes['NetSuiteVerifiedStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AiChatModelNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AiChatModelName'] = ResolversParentTypes['AiChatModelName']
> = ResolversObject<{
  modelName?: Resolver<ResolversTypes['AIModelName'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllAccountAnalyticsForAdminPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AllAccountAnalyticsForAdminPayload'] = ResolversParentTypes['AllAccountAnalyticsForAdminPayload']
> = ResolversObject<{
  accounts?: Resolver<ReadonlyArray<ResolversTypes['AccountAnalyticsPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllAccountAnalyticsForAdvertiserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AllAccountAnalyticsForAdvertiserPayload'] = ResolversParentTypes['AllAccountAnalyticsForAdvertiserPayload']
> = ResolversObject<{
  accounts?: Resolver<ReadonlyArray<ResolversTypes['AccountAnalyticsForAdvertiserPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllAdvertisersForSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllAdvertisersForSearch'] = ResolversParentTypes['AllAdvertisersForSearch']
> = ResolversObject<{
  advertisers?: Resolver<ReadonlyArray<ResolversTypes['AdvertiserForSearch']>, ParentType, ContextType>;
  includedAdvertisers?: Resolver<ReadonlyArray<ResolversTypes['AdvertiserForSearch']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllCampaignsReportSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AllCampaignsReportSummary'] = ResolversParentTypes['AllCampaignsReportSummary']
> = ResolversObject<{
  chartData?: Resolver<ReadonlyArray<ResolversTypes['CampaignChartReport']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['CampaignReportSummary']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllInfluencerV4Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllInfluencerV4'] = ResolversParentTypes['AllInfluencerV4']
> = ResolversObject<{
  influencers?: Resolver<ReadonlyArray<ResolversTypes['InfluencerV4']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllStaffDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllStaffDetail'] = ResolversParentTypes['AllStaffDetail']
> = ResolversObject<{
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllTiktokAdReportSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllTiktokAdReportSummary'] = ResolversParentTypes['AllTiktokAdReportSummary']
> = ResolversObject<{
  adCampaignId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adCampaignName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adGroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chartData?: Resolver<ReadonlyArray<ResolversTypes['TikTokAdChartReport']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['TikTokAdReportSummary']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllUserDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllUserDetail'] = ResolversParentTypes['AllUserDetail']
> = ResolversObject<{
  advertisersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllowEngagementInfluencerProposalListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AllowEngagementInfluencerProposalList'] = ResolversParentTypes['AllowEngagementInfluencerProposalList']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllowInfluencersUserProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AllowInfluencersUserProposal'] = ResolversParentTypes['AllowInfluencersUserProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticSubscriptionAvailableFeatureResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnalyticSubscriptionAvailableFeature'] = ResolversParentTypes['AnalyticSubscriptionAvailableFeature']
> = ResolversObject<{
  compareEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dashboardEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hashtagsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  interactionEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  trendEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticSubscriptionAvailableFeaturesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnalyticSubscriptionAvailableFeatures'] = ResolversParentTypes['AnalyticSubscriptionAvailableFeatures']
> = ResolversObject<{
  compareEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dashboardEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hashtagsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  interactionEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticSubscriptionCapabilitiesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnalyticSubscriptionCapabilities'] = ResolversParentTypes['AnalyticSubscriptionCapabilities']
> = ResolversObject<{
  maxCompareAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxHashtags?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticSubscriptionCapabilityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnalyticSubscriptionCapability'] = ResolversParentTypes['AnalyticSubscriptionCapability']
> = ResolversObject<{
  maxCompareAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxHashtags?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticsSelectSubscriptionPlanResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnalyticsSelectSubscriptionPlan'] = ResolversParentTypes['AnalyticsSelectSubscriptionPlan']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticsSocialAuthRedirectUrlPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnalyticsSocialAuthRedirectUrlPayload'] = ResolversParentTypes['AnalyticsSocialAuthRedirectUrlPayload']
> = ResolversObject<{
  redirectUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticsSubscriptionPlanResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnalyticsSubscriptionPlan'] = ResolversParentTypes['AnalyticsSubscriptionPlan']
> = ResolversObject<{
  availableFeatures?: Resolver<ResolversTypes['AnalyticSubscriptionAvailableFeatures'], ParentType, ContextType>;
  capabilities?: Resolver<ResolversTypes['AnalyticSubscriptionCapabilities'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnalyticsSubscriptionPlanType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticsSubscriptionPlanDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnalyticsSubscriptionPlanDetails'] = ResolversParentTypes['AnalyticsSubscriptionPlanDetails']
> = ResolversObject<{
  availableFeatures?: Resolver<ResolversTypes['AnalyticSubscriptionAvailableFeature'], ParentType, ContextType>;
  capabilities?: Resolver<ResolversTypes['AnalyticSubscriptionCapability'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnalyticsSubscriptionPlanType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticsSubscriptionPlanNameResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnalyticsSubscriptionPlanName'] = ResolversParentTypes['AnalyticsSubscriptionPlanName']
> = ResolversObject<{
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnalyticsSubscriptionPlanType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyCreatorCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnyCreatorCategory'] = ResolversParentTypes['AnyCreatorCategory']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXAccountInfoPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnyXAccountInfoPayload'] = ResolversParentTypes['AnyXAccountInfoPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXCheckConnectedSocialAccountsForLinkBioPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXCheckConnectedSocialAccountsForLinkBioPayload'] = ResolversParentTypes['AnyXCheckConnectedSocialAccountsForLinkBioPayload']
> = ResolversObject<{
  facebooks?: Resolver<
    ReadonlyArray<ResolversTypes['AnyXConnectedFacebookAccountAndPageForLinkBio']>,
    ParentType,
    ContextType
  >;
  instagramAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['AnyXConnectedSocialAccountForLinkBio']>,
    ParentType,
    ContextType
  >;
  tiktokAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['AnyXConnectedSocialAccountForLinkBio']>,
    ParentType,
    ContextType
  >;
  twitterAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['AnyXConnectedSocialAccountForLinkBio']>,
    ParentType,
    ContextType
  >;
  youtubeAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['AnyXConnectedSocialAccountForLinkBio']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXCheckConnectedSocialAccountsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXCheckConnectedSocialAccountsPayload'] = ResolversParentTypes['AnyXCheckConnectedSocialAccountsPayload']
> = ResolversObject<{
  facebookPages?: Resolver<ReadonlyArray<ResolversTypes['AnyXConnectedFacebookPage']>, ParentType, ContextType>;
  facebooks?: Resolver<ReadonlyArray<ResolversTypes['AnyXConnectedFacebookAccount']>, ParentType, ContextType>;
  instagramAccounts?: Resolver<ReadonlyArray<ResolversTypes['AnyXConnectedInstagramAccount']>, ParentType, ContextType>;
  tiktokAccounts?: Resolver<ReadonlyArray<ResolversTypes['AnyXConnectedTikTokAccount']>, ParentType, ContextType>;
  twitterAccounts?: Resolver<ReadonlyArray<ResolversTypes['AnyXConnectedSocialAccount']>, ParentType, ContextType>;
  youtubeAccounts?: Resolver<ReadonlyArray<ResolversTypes['AnyXConnectedYoutubeAccount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXCheckEmailPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnyXCheckEmailPayload'] = ResolversParentTypes['AnyXCheckEmailPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXConnectedChildYoutubeAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXConnectedChildYoutubeAccount'] = ResolversParentTypes['AnyXConnectedChildYoutubeAccount']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXConnectedFacebookAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXConnectedFacebookAccount'] = ResolversParentTypes['AnyXConnectedFacebookAccount']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idForDisconnect?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXConnectedFacebookAccountAndPageForLinkBioResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXConnectedFacebookAccountAndPageForLinkBio'] = ResolversParentTypes['AnyXConnectedFacebookAccountAndPageForLinkBio']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  post?: Resolver<Maybe<ResolversTypes['FacebookPostForLinkBio']>, ParentType, ContextType>;
  socialAccountType?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXConnectedFacebookPageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXConnectedFacebookPage'] = ResolversParentTypes['AnyXConnectedFacebookPage']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idForDisconnect?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXConnectedInstagramAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXConnectedInstagramAccount'] = ResolversParentTypes['AnyXConnectedInstagramAccount']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idForDisconnect?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isBusiness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXConnectedSocialAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXConnectedSocialAccount'] = ResolversParentTypes['AnyXConnectedSocialAccount']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idForDisconnect?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXConnectedSocialAccountForLinkBioResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXConnectedSocialAccountForLinkBio'] = ResolversParentTypes['AnyXConnectedSocialAccountForLinkBio']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXConnectedTikTokAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXConnectedTikTokAccount'] = ResolversParentTypes['AnyXConnectedTikTokAccount']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idForDisconnect?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXConnectedYoutubeAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXConnectedYoutubeAccount'] = ResolversParentTypes['AnyXConnectedYoutubeAccount']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  childAccounts?: Resolver<ReadonlyArray<ResolversTypes['AnyXConnectedChildYoutubeAccount']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idForDisconnect?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSignInResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnyXSignIn'] = ResolversParentTypes['AnyXSignIn']
> = ResolversObject<{
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSignUpResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnyXSignUp'] = ResolversParentTypes['AnyXSignUp']
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthConnectForLinkBioPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthConnectForLinkBioPayload'] = ResolversParentTypes['AnyXSocialAuthConnectForLinkBioPayload']
> = ResolversObject<{
  connectedAccounts?: Resolver<ReadonlyArray<ResolversTypes['ConnectedAccountForLinkBio']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthConnectPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthConnectPayload'] = ResolversParentTypes['AnyXSocialAuthConnectPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthDisconnectPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthDisconnectPayload'] = ResolversParentTypes['AnyXSocialAuthDisconnectPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthReconnectForLinkBioPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthReconnectForLinkBioPayload'] = ResolversParentTypes['AnyXSocialAuthReconnectForLinkBioPayload']
> = ResolversObject<{
  accounts?: Resolver<ReadonlyArray<ResolversTypes['ReconnectedAccountForLinkBio']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthReconnectTTCMPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthReconnectTTCMPayload'] = ResolversParentTypes['AnyXSocialAuthReconnectTTCMPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthReconnectTikTokPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthReconnectTikTokPayload'] = ResolversParentTypes['AnyXSocialAuthReconnectTikTokPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthSignInInstagramLinePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthSignInInstagramLinePayload'] = ResolversParentTypes['AnyXSocialAuthSignInInstagramLinePayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthSignInOrSignUpPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthSignInOrSignUpPayload'] = ResolversParentTypes['AnyXSocialAuthSignInOrSignUpPayload']
> = ResolversObject<{
  hasIgAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  signIn?: Resolver<Maybe<ResolversTypes['AnyXSignIn']>, ParentType, ContextType>;
  signUp?: Resolver<Maybe<ResolversTypes['AnyXSignUp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthSignInPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthSignInPayload'] = ResolversParentTypes['AnyXSocialAuthSignInPayload']
> = ResolversObject<{
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthSignUpPartnerPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthSignUpPartnerPayload'] = ResolversParentTypes['AnyXSocialAuthSignUpPartnerPayload']
> = ResolversObject<{
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthSignUpPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthSignUpPayload'] = ResolversParentTypes['AnyXSocialAuthSignUpPayload']
> = ResolversObject<{
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnyXSocialAuthSignUpTalentPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnyXSocialAuthSignUpTalentPayload'] = ResolversParentTypes['AnyXSocialAuthSignUpTalentPayload']
> = ResolversObject<{
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApplicantInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicantInfluencer'] = ResolversParentTypes['ApplicantInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  appliedStatus?: Resolver<ResolversTypes['MarketplaceCampaignAppliedStatus'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryNameForInfluencer'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['InfluencerEngagement'], ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  tiktok?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  youtube?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApproveApplicantInfluencersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ApproveApplicantInfluencers'] = ResolversParentTypes['ApproveApplicantInfluencers']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApproveAutoManagedPayoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApproveAutoManagedPayout'] = ResolversParentTypes['ApproveAutoManagedPayout']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApproveCmsInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApproveCmsInvoice'] = ResolversParentTypes['ApproveCmsInvoice']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApproveMarketplaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApproveMarketplace'] = ResolversParentTypes['ApproveMarketplace']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApproveMarketplaceAffiliatePayoutResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ApproveMarketplaceAffiliatePayout'] = ResolversParentTypes['ApproveMarketplaceAffiliatePayout']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApproveMarketplaceDraftPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ApproveMarketplaceDraftPost'] = ResolversParentTypes['ApproveMarketplaceDraftPost']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApproveTikTokSpecialCampaignResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ApproveTikTokSpecialCampaign'] = ResolversParentTypes['ApproveTikTokSpecialCampaign']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssignAdvertiserForStaffsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AssignAdvertiserForStaffs'] = ResolversParentTypes['AssignAdvertiserForStaffs']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssignChatPICPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssignChatPICPayload'] = ResolversParentTypes['AssignChatPICPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssociateCompanyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssociateCompany'] = ResolversParentTypes['AssociateCompany']
> = ResolversObject<{
  companyType?: Resolver<ResolversTypes['AssociateCompanyType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AudienceBreakdownAgeGenderRatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AudienceBreakdownAgeGenderRates'] = ResolversParentTypes['AudienceBreakdownAgeGenderRates']
> = ResolversObject<{
  ageGroups?: Resolver<ReadonlyArray<ResolversTypes['AudienceBreakdownAgeGroup']>, ParentType, ContextType>;
  femaleRates?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRates?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AudienceBreakdownAgeGroupResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AudienceBreakdownAgeGroup'] = ResolversParentTypes['AudienceBreakdownAgeGroup']
> = ResolversObject<{
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AudienceBreakdownAgeRatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AudienceBreakdownAgeRates'] = ResolversParentTypes['AudienceBreakdownAgeRates']
> = ResolversObject<{
  ageGroups?: Resolver<ReadonlyArray<ResolversTypes['AudienceBreakdownAgeGroup']>, ParentType, ContextType>;
  rates?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AudienceBreakdownAreaRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AudienceBreakdownAreaRate'] = ResolversParentTypes['AudienceBreakdownAreaRate']
> = ResolversObject<{
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AudienceBreakdownGenderRatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AudienceBreakdownGenderRates'] = ResolversParentTypes['AudienceBreakdownGenderRates']
> = ResolversObject<{
  femaleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Auth0ToLocalJWTResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Auth0ToLocalJWT'] = ResolversParentTypes['Auth0ToLocalJWT']
> = ResolversObject<{
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuthTiktokBusinessAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AuthTiktokBusinessAccount'] = ResolversParentTypes['AuthTiktokBusinessAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedCampaignCountryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedCampaignCountry'] = ResolversParentTypes['AutoManagedCampaignCountry']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedCampaignForInfluencerSearchJobPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedCampaignForInfluencerSearchJobPayload'] = ResolversParentTypes['AutoManagedCampaignForInfluencerSearchJobPayload']
> = ResolversObject<{
  campaignUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isJoined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  minimumPaymentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  requirement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AutoManagedAffiliateCampaignStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AutoManagedCampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedCampaignForInfluencerYourJobPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedCampaignForInfluencerYourJobPayload'] = ResolversParentTypes['AutoManagedCampaignForInfluencerYourJobPayload']
> = ResolversObject<{
  campaignUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isJoined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  minimumPaymentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  requirement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AutoManagedAffiliateCampaignStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trackingUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AutoManagedCampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedCampaignInfoPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedCampaignInfoPayload'] = ResolversParentTypes['AutoManagedCampaignInfoPayload']
> = ResolversObject<{
  autoManagedCampaignType?: Resolver<ResolversTypes['AutoManagedCampaignType'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['Category'], ParentType, ContextType>;
  commissionRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['AutoManagedCampaignCountry'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  landingPageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfJoinedCreators?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  orders?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['AutoManagedCampaignMoney'], ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['AutoManagedCampaignMoney'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['AutoManagedCampaignMoney'], ParentType, ContextType>;
  sellerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AutoManagedCampaignStatus'], ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedCampaignMoneyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AutoManagedCampaignMoney'] = ResolversParentTypes['AutoManagedCampaignMoney']
> = ResolversObject<{
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedCampaignPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedCampaignPayload'] = ResolversParentTypes['AutoManagedCampaignPayload']
> = ResolversObject<{
  autoManagedCampaignType?: Resolver<ResolversTypes['AutoManagedCampaignType'], ParentType, ContextType>;
  campaignThumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  campaignUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['Category'], ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['Country'], ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerCommissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  landingPageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requirement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sellerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AutoManagedCampaignStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedCommissionInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedCommissionInfluencer'] = ResolversParentTypes['AutoManagedCommissionInfluencer']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endCoverageDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  fixedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  startCoverageDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AutoManagedCommissionStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedInfluencerInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedInfluencerInfo'] = ResolversParentTypes['AutoManagedInfluencerInfo']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedJoinedInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedJoinedInfluencer'] = ResolversParentTypes['AutoManagedJoinedInfluencer']
> = ResolversObject<{
  influencer?: Resolver<ResolversTypes['AutoManagedInfluencerInfo'], ParentType, ContextType>;
  joinedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  promotionMethods?: Resolver<ReadonlyArray<ResolversTypes['CampaignPromotionMethod']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedJoinedMethodsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedJoinedMethodsPayload'] = ResolversParentTypes['AutoManagedJoinedMethodsPayload']
> = ResolversObject<{
  emailNewsLetters?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodEmailNewsLetterPayload']>,
    ParentType,
    ContextType
  >;
  facebookAccount?: Resolver<
    Maybe<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  facebookPages?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  instagramAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  mobileApps?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodMobileAppPayload']>,
    ParentType,
    ContextType
  >;
  offlines?: Resolver<ReadonlyArray<ResolversTypes['JoinedPromotionMethodOfflinePayload']>, ParentType, ContextType>;
  podCasts?: Resolver<ReadonlyArray<ResolversTypes['JoinedPromotionMethodPodCastPayload']>, ParentType, ContextType>;
  tiktokAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  twitterAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  websites?: Resolver<ReadonlyArray<ResolversTypes['JoinedPromotionMethodWebsitePayload']>, ParentType, ContextType>;
  youtubeAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedJoinedMethodsPayloadV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedJoinedMethodsPayloadV2'] = ResolversParentTypes['AutoManagedJoinedMethodsPayloadV2']
> = ResolversObject<{
  methods?: Resolver<ReadonlyArray<ResolversTypes['CampaignPromotionMethod']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedReportByDateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AutoManagedReportByDate'] = ResolversParentTypes['AutoManagedReportByDate']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cvr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  grossProfit?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  influencerCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedReportByInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedReportByInfluencer'] = ResolversParentTypes['AutoManagedReportByInfluencer']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  commissionRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cvr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  influencerCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  influencerInfo?: Resolver<ResolversTypes['CampaignInfluencerInfo'], ParentType, ContextType>;
  promotionMethods?: Resolver<ReadonlyArray<ResolversTypes['CampaignPromotionMethod']>, ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedReportForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedReportForInfluencer'] = ResolversParentTypes['AutoManagedReportForInfluencer']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  commission?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedReportSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AutoManagedReportSummary'] = ResolversParentTypes['AutoManagedReportSummary']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cvr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  grossProfit?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  influencerCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  joinedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AutoManagedReportSummaryForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoManagedReportSummaryForInfluencer'] = ResolversParentTypes['AutoManagedReportSummaryForInfluencer']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  commission?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AvailableInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AvailableInfluencer'] = ResolversParentTypes['AvailableInfluencer']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  selected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AvailableInfluencersForStaffPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AvailableInfluencersForStaffPayload'] = ResolversParentTypes['AvailableInfluencersForStaffPayload']
> = ResolversObject<{
  influencers?: Resolver<ReadonlyArray<ResolversTypes['AvailableInfluencer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Bank'] = ResolversParentTypes['Bank']
> = ResolversObject<{
  hasBranches?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankBranchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BankBranch'] = ResolversParentTypes['BankBranch']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankBranchForInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BankBranchForInfluencer'] = ResolversParentTypes['BankBranchForInfluencer']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankForInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BankForInfluencer'] = ResolversParentTypes['BankForInfluencer']
> = ResolversObject<{
  hasBranches?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BasicAccountAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BasicAccountAnalyticsPayload'] = ResolversParentTypes['BasicAccountAnalyticsPayload']
> = ResolversObject<{
  brandAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  brandAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialMediaAccounts?: Resolver<ResolversTypes['AccountAnalyticsSocialMediaAccount'], ParentType, ContextType>;
  subscriptionPlan?: Resolver<ResolversTypes['AnalyticsSubscriptionPlanDetails'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type BillingInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BillingInformation'] = ResolversParentTypes['BillingInformation']
> = ResolversObject<{
  cardBrand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cardCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cardExpMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardExpYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardLast4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioAnalyticsCityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioAnalyticsCity'] = ResolversParentTypes['BioAnalyticsCity']
> = ResolversObject<{
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioAnalyticsCountryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioAnalyticsCountry'] = ResolversParentTypes['BioAnalyticsCountry']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioAnalyticsOtherLinkClicksResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BioAnalyticsOtherLinkClicks'] = ResolversParentTypes['BioAnalyticsOtherLinkClicks']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  linkName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioAnalyticsReferralResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioAnalyticsReferral'] = ResolversParentTypes['BioAnalyticsReferral']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioAnalyticsSocialMediaClicksResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BioAnalyticsSocialMediaClicks'] = ResolversParentTypes['BioAnalyticsSocialMediaClicks']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  linkName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialMediaType?: Resolver<ResolversTypes['BioSocialMediaType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioAnalyticsTotalClicksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioAnalyticsTotalClicks'] = ResolversParentTypes['BioAnalyticsTotalClicks']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sectionType?: Resolver<ResolversTypes['BioSectionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioImage'] = ResolversParentTypes['BioImage']
> = ResolversObject<{
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioInfluencerProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioInfluencerProfile'] = ResolversParentTypes['BioInfluencerProfile']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioSection'] = ResolversParentTypes['BioSection']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['BioImage']>, ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['BioUrlLink']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['BioInfluencerProfile']>, ParentType, ContextType>;
  sectionType?: Resolver<ResolversTypes['BioSectionType'], ParentType, ContextType>;
  socialMedia?: Resolver<Maybe<ResolversTypes['BioSocialMedia']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['BioText']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioSectionV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioSectionV2'] = ResolversParentTypes['BioSectionV2']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['BioImage']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['BioUrlLink']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sectionType?: Resolver<ResolversTypes['BioSectionType'], ParentType, ContextType>;
  socialMedia?: Resolver<Maybe<ResolversTypes['BioSocialMedia']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['BioText']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioSocialMediaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioSocialMedia'] = ResolversParentTypes['BioSocialMedia']
> = ResolversObject<{
  linkName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialAccountType?: Resolver<Maybe<ResolversTypes['SocialAccountType']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['BioSocialMediaType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioTextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioText'] = ResolversParentTypes['BioText']
> = ResolversObject<{
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BioUrlLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BioUrlLink'] = ResolversParentTypes['BioUrlLink']
> = ResolversObject<{
  linkIcon?: Resolver<Maybe<ResolversTypes['BioLinkIcon']>, ParentType, ContextType>;
  linkName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BoostTiktokAdPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BoostTiktokAdPost'] = ResolversParentTypes['BoostTiktokAdPost']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BotTextMessageEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BotTextMessageEvent'] = ResolversParentTypes['BotTextMessageEvent']
> = ResolversObject<{
  authorId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  authorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BrandAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandAccount'] = ResolversParentTypes['BrandAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  averageEngagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mentionPostsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BulkUpdateEngagementInfluencersBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BulkUpdateEngagementInfluencersBudget'] = ResolversParentTypes['BulkUpdateEngagementInfluencersBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BulkUpdateEngagementSocialAccountsBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BulkUpdateEngagementSocialAccountsBudget'] = ResolversParentTypes['BulkUpdateEngagementSocialAccountsBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BulkUpdatePackageProposalAccountsBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BulkUpdatePackageProposalAccountsBudget'] = ResolversParentTypes['BulkUpdatePackageProposalAccountsBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BulkUpdatePackageProposalInfluencersBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BulkUpdatePackageProposalInfluencersBudget'] = ResolversParentTypes['BulkUpdatePackageProposalInfluencersBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BulkUpdateUserProposalAccountsBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BulkUpdateUserProposalAccountsBudget'] = ResolversParentTypes['BulkUpdateUserProposalAccountsBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BulkUpdateUserProposalInfluencersBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BulkUpdateUserProposalInfluencersBudget'] = ResolversParentTypes['BulkUpdateUserProposalInfluencersBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ButtonMessagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ButtonMessagePayload'] = ResolversParentTypes['ButtonMessagePayload']
> = ResolversObject<{
  cards?: Resolver<ReadonlyArray<ResolversTypes['CardPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignCategoryNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignCategoryName'] = ResolversParentTypes['CampaignCategoryName']
> = ResolversObject<{
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignChartReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignChartReport'] = ResolversParentTypes['CampaignChartReport']
> = ResolversObject<{
  averageViewDurationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  like?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignChartReportForAllTimeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CampaignChartReportForAllTime'] = ResolversParentTypes['CampaignChartReportForAllTime']
> = ResolversObject<{
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  midDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignCreatorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignCreator'] = ResolversParentTypes['CampaignCreator']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignCreatorWithNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignCreatorWithName'] = ResolversParentTypes['CampaignCreatorWithName']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignDailyStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignDailyStats'] = ResolversParentTypes['CampaignDailyStats']
> = ResolversObject<{
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  costsPerAction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerClick?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerImpressions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerReach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  like?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  net?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profitRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reach?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  share?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  view?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignDetailReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignDetailReport'] = ResolversParentTypes['CampaignDetailReport']
> = ResolversObject<{
  age35UpPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age1824Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age2534Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageViewDurationPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  back?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  campaign?: Resolver<ResolversTypes['CampaignReportCampaignInfo'], ParentType, ContextType>;
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  costsPerAction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerClick?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerImpressions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerReach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaleReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaleTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  daily?: Resolver<ReadonlyArray<ResolversTypes['CampaignDailyStats']>, ParentType, ContextType>;
  dislikes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exited?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  femalePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  forward?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromDiscovery?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHashtag?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromOther?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromProfile?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  like?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  linkClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  malePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  navigation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  net?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nextStory?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profileActivity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profitRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stickerTaps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignForInfluencerForYourJobResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CampaignForInfluencerForYourJob'] = ResolversParentTypes['CampaignForInfluencerForYourJob']
> = ResolversObject<{
  autoManaged?: Resolver<
    Maybe<ResolversTypes['NewAutoManagedCampaignForInfluencerForYourJob']>,
    ParentType,
    ContextType
  >;
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['NewEngagementCampaignForInfluencerForYourJob']>, ParentType, ContextType>;
  marketplace?: Resolver<
    Maybe<ResolversTypes['NewMarketplaceCampaignForInfluencerForYourJob']>,
    ParentType,
    ContextType
  >;
  tiktokSpecial?: Resolver<
    Maybe<ResolversTypes['NewTiktokSpecialCampaignForInfluencerForYourJob']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignForInfluencerForYourJobCountsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CampaignForInfluencerForYourJobCounts'] = ResolversParentTypes['CampaignForInfluencerForYourJobCounts']
> = ResolversObject<{
  finishedCampaignCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  progressCampaignCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignForPostFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignForPostFilter'] = ResolversParentTypes['CampaignForPostFilter']
> = ResolversObject<{
  campaigns?: Resolver<ReadonlyArray<ResolversTypes['CampaignForSearch']>, ParentType, ContextType>;
  includedCampaigns?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignForSearch']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignForSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignForSearch'] = ResolversParentTypes['CampaignForSearch']
> = ResolversObject<{
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CampaignStatusForReport'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignGridReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignGridReport'] = ResolversParentTypes['CampaignGridReport']
> = ResolversObject<{
  engagementRate?: Resolver<ResolversTypes['PostsMetricsGridReport'], ParentType, ContextType>;
  reachRate?: Resolver<Maybe<ResolversTypes['PostsMetricsGridReport']>, ParentType, ContextType>;
  sales?: Resolver<Maybe<ResolversTypes['PostsMetricsGridReport']>, ParentType, ContextType>;
  saveRate?: Resolver<Maybe<ResolversTypes['PostsMetricsGridReport']>, ParentType, ContextType>;
  shareRate?: Resolver<Maybe<ResolversTypes['PostsMetricsGridReport']>, ParentType, ContextType>;
  viewRate?: Resolver<Maybe<ResolversTypes['PostsMetricsGridReport']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignInfluencerInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignInfluencerInfo'] = ResolversParentTypes['CampaignInfluencerInfo']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignInfluencerReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignInfluencerReport'] = ResolversParentTypes['CampaignInfluencerReport']
> = ResolversObject<{
  InternalInfo?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  influencerInfo?: Resolver<ResolversTypes['CampaignInfluencerInfo'], ParentType, ContextType>;
  orderedSummaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignSummaryDaily']>>, ParentType, ContextType>;
  postCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postReports?: Resolver<Maybe<ReadonlyArray<ResolversTypes['NewCampaignPostReport']>>, ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['NewCampaignStatsItemRow'], ParentType, ContextType>;
  summaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignSummaryDaily']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignPaymentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignPayment'] = ResolversParentTypes['CampaignPayment']
> = ResolversObject<{
  campaignType?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignPost'] = ResolversParentTypes['CampaignPost']
> = ResolversObject<{
  actions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  affiliatePaidStatus?: Resolver<Maybe<ResolversTypes['CommissionStatus']>, ParentType, ContextType>;
  age18to24Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age25to34Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age35upPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  audienceRetention?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  averageViewDurationPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageViewTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  back?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  campaignIdentifier?: Resolver<ResolversTypes['ReadCampaignIdentifier'], ParentType, ContextType>;
  campaignName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dislikes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  engagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exited?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  femalePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  forward?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressionsClickThroughRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressionsFromDiscovery?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHashtag?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromOther?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromProfile?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  linkClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  malePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  navigation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextStory?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileActivity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reach?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reachRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  salesCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  saved?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialPostType?: Resolver<ResolversTypes['CampaignPostSocialType'], ParentType, ContextType>;
  stickerTaps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalPlayTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoCompletionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignPostGridInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignPostGridInfo'] = ResolversParentTypes['CampaignPostGridInfo']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasTrafficSource?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  insightDataAcquisition?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountAvatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  socialUsername?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignPostInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignPostInfo'] = ResolversParentTypes['CampaignPostInfo']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasTrafficSource?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  insightDataAcquisition?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignPostMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignPostMetrics'] = ResolversParentTypes['CampaignPostMetrics']
> = ResolversObject<{
  actions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age18to24Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age25to34Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age35upPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  audienceRetention?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationSeconds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  back?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clicks?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  conversions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dislikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementFollowersRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementViewsRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  exited?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  femalePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  forward?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressions?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  impressionsClickThroughRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressionsFromDiscovery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressionsFromHashtag?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressionsFromHome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressionsFromOther?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressionsFromProfile?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressionsRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  linkClicks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  malePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  navigation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nextStory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nonFollowerReach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nonFollowerReachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profileActivity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reach?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  reachRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  salesCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  saved?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  stickerTaps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalPlayTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  videoCompletionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignPostReportGridResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignPostReportGrid'] = ResolversParentTypes['CampaignPostReportGrid']
> = ResolversObject<{
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  like?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postInfo?: Resolver<ResolversTypes['CampaignPostGridInfo'], ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignPostsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignPosts'] = ResolversParentTypes['CampaignPosts']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['CampaignPost']>, ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['CampaignPostsSummary'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignPostsSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignPostsSummary'] = ResolversParentTypes['CampaignPostsSummary']
> = ResolversObject<{
  average?: Resolver<ResolversTypes['CampaignPostMetrics'], ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sum?: Resolver<ResolversTypes['CampaignPostMetrics'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignReportCampaignInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CampaignReportCampaignInfo'] = ResolversParentTypes['CampaignReportCampaignInfo']
> = ResolversObject<{
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  hasPostTracking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasTrafficSource?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mkpDetailType?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignDetailType']>, ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CampaignStatusForReport'], ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignReportCampaignInfoForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CampaignReportCampaignInfoForInfluencer'] = ResolversParentTypes['CampaignReportCampaignInfoForInfluencer']
> = ResolversObject<{
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<Maybe<ResolversTypes['CampaignSocialMediaType']>>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CampaignStatusForInfluencer'], ParentType, ContextType>;
  suspendedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignReportSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignReportSummary'] = ResolversParentTypes['CampaignReportSummary']
> = ResolversObject<{
  age35UpPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age1824Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age2534Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageViewDurationPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  back?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  costsPerAction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerClick?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerImpressions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerReach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaleReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaleTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dislikes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exited?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  femalePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  forward?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromDiscovery?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHashtag?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromOther?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromProfile?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  like?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  linkClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  malePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  navigation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  net?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nextStory?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profileActivity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profitRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stickerTaps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignSummaryDailyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CampaignSummaryDaily'] = ResolversParentTypes['CampaignSummaryDaily']
> = ResolversObject<{
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  stats?: Resolver<ResolversTypes['NewCampaignStatsItemRow'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignsForSearchJobPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CampaignsForSearchJobPayload'] = ResolversParentTypes['CampaignsForSearchJobPayload']
> = ResolversObject<{
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isAutoInfluencerApproval?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isGiftingCampaign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSelectionCampaign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  marketplaceCampaignType?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignDetailType']>, ParentType, ContextType>;
  minFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preLaunchDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revenuePerAction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerClick?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerPost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceCampaignStatus'], ParentType, ContextType>;
  thumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trackingOptions?: Resolver<ReadonlyArray<ResolversTypes['CampaignTrackingOption']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CancelSubscriptionPlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CancelSubscriptionPlan'] = ResolversParentTypes['CancelSubscriptionPlan']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CardPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CardPayload'] = ResolversParentTypes['CardPayload']
> = ResolversObject<{
  actionLabels?: Resolver<ReadonlyArray<ResolversTypes['ActionLabelPayload']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['TextComponentPayload']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['TextComponentPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CategoryNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategoryName'] = ResolversParentTypes['CategoryName']
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChangeInstagramUGCManagementPostStatusOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ChangeInstagramUGCManagementPostStatusOutput'] = ResolversParentTypes['ChangeInstagramUGCManagementPostStatusOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatEvent'] = ResolversParentTypes['ChatEvent']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    | 'BotTextMessageEvent'
    | 'InquiryEvent'
    | 'InquiryFinishEvent'
    | 'MessageTemplateEvent'
    | 'StateUpdateEvent'
    | 'UserAudioMessageEvent'
    | 'UserImageMessageEvent'
    | 'UserPostbackEvent'
    | 'UserTextMessageEvent'
    | 'UserVideoMessageEvent',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
}>;

export type CheckBioLinkNameAvailabilityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CheckBioLinkNameAvailabilityPayload'] = ResolversParentTypes['CheckBioLinkNameAvailabilityPayload']
> = ResolversObject<{
  isAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['City'] = ResolversParentTypes['City']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CityForInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CityForInfluencer'] = ResolversParentTypes['CityForInfluencer']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClearCmsChannelAssetOwnerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ClearCmsChannelAssetOwner'] = ResolversParentTypes['ClearCmsChannelAssetOwner']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClickNotificationByEventIdPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ClickNotificationByEventIdPayload'] = ResolversParentTypes['ClickNotificationByEventIdPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClickNotificationPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClickNotificationPayload'] = ResolversParentTypes['ClickNotificationPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloneMarketplaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CloneMarketplace'] = ResolversParentTypes['CloneMarketplace']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommentContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommentContent'] = ResolversParentTypes['CommentContent']
> = ResolversObject<{
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectFacebookPagesAndInstagramAccountsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectFacebookPagesAndInstagramAccountsPayload'] = ResolversParentTypes['ConnectFacebookPagesAndInstagramAccountsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectNewShopifyAccountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectNewShopifyAccountPayload'] = ResolversParentTypes['ConnectNewShopifyAccountPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectPackageToEngagementProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectPackageToEngagementProposal'] = ResolversParentTypes['ConnectPackageToEngagementProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectPackageWithInfluencerAccountsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectPackageWithInfluencerAccounts'] = ResolversParentTypes['ConnectPackageWithInfluencerAccounts']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectPackageWithSocialAccountsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectPackageWithSocialAccounts'] = ResolversParentTypes['ConnectPackageWithSocialAccounts']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectPackageWithUserProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectPackageWithUserProposal'] = ResolversParentTypes['ConnectPackageWithUserProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectPromotionMethodsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectPromotionMethodsPayload'] = ResolversParentTypes['ConnectPromotionMethodsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableFacebookPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectableFacebookPage'] = ResolversParentTypes['ConnectableFacebookPage']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAlreadyConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableFacebookPageAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableFacebookPageAccount'] = ResolversParentTypes['ConnectableFacebookPageAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAlreadyUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableFacebookPageAndInstagramAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableFacebookPageAndInstagramAccount'] = ResolversParentTypes['ConnectableFacebookPageAndInstagramAccount']
> = ResolversObject<{
  igAccount?: Resolver<Maybe<ResolversTypes['ConnectableInstagramAccount']>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes['ConnectableFacebookPage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableFacebookPageMainAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableFacebookPageMainAccount'] = ResolversParentTypes['ConnectableFacebookPageMainAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  friendCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableFacebookPagesAndInstagramAccountsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableFacebookPagesAndInstagramAccountsPayload'] = ResolversParentTypes['ConnectableFacebookPagesAndInstagramAccountsPayload']
> = ResolversObject<{
  accounts?: Resolver<
    ReadonlyArray<ResolversTypes['ConnectableFacebookPageAndInstagramAccount']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableFacebookPagesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableFacebookPagesPayload'] = ResolversParentTypes['ConnectableFacebookPagesPayload']
> = ResolversObject<{
  pages?: Resolver<ReadonlyArray<ResolversTypes['ConnectableFacebookPage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableInstagramAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableInstagramAccount'] = ResolversParentTypes['ConnectableInstagramAccount']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAlreadyConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableInstagramAccountWithNoConnectedFlagResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableInstagramAccountWithNoConnectedFlag'] = ResolversParentTypes['ConnectableInstagramAccountWithNoConnectedFlag']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableInstagramAccountsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableInstagramAccountsPayload'] = ResolversParentTypes['ConnectableInstagramAccountsPayload']
> = ResolversObject<{
  igAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['ConnectableInstagramAccountWithNoConnectedFlag']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableOauthAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectableOauthAccount'] = ResolversParentTypes['ConnectableOauthAccount']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableOauthAccountForTalentSignInResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableOauthAccountForTalentSignIn'] = ResolversParentTypes['ConnectableOauthAccountForTalentSignIn']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableOauthFacebookAccountForTalentSignInResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableOauthFacebookAccountForTalentSignIn'] = ResolversParentTypes['ConnectableOauthFacebookAccountForTalentSignIn']
> = ResolversObject<{
  fbName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableOauthInstagramAccountForTalentSignInResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableOauthInstagramAccountForTalentSignIn'] = ResolversParentTypes['ConnectableOauthInstagramAccountForTalentSignIn']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableOauthPageAndIgAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableOauthPageAndIgAccount'] = ResolversParentTypes['ConnectableOauthPageAndIgAccount']
> = ResolversObject<{
  igAccount?: Resolver<ResolversTypes['ConnectableOauthAccount'], ParentType, ContextType>;
  isAlreadyUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['ConnectableOauthAccount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableOauthPageAndIgAccountForSignInFacebookResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableOauthPageAndIgAccountForSignInFacebook'] = ResolversParentTypes['ConnectableOauthPageAndIgAccountForSignInFacebook']
> = ResolversObject<{
  igAccount?: Resolver<Maybe<ResolversTypes['ConnectableOauthAccountForTalentSignIn']>, ParentType, ContextType>;
  isPageAlreadyConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['ConnectableOauthAccountForTalentSignIn'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableOauthPageAndIgAccountForTalentSignInResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableOauthPageAndIgAccountForTalentSignIn'] = ResolversParentTypes['ConnectableOauthPageAndIgAccountForTalentSignIn']
> = ResolversObject<{
  igAccount?: Resolver<ResolversTypes['ConnectableOauthInstagramAccountForTalentSignIn'], ParentType, ContextType>;
  isAlreadyUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['ConnectableOauthAccountForTalentSignIn'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectableOauthYouTubeAccountForTalentSignInResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectableOauthYouTubeAccountForTalentSignIn'] = ResolversParentTypes['ConnectableOauthYouTubeAccountForTalentSignIn']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  subscribersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectedAccountForLinkBioResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectedAccountForLinkBio'] = ResolversParentTypes['ConnectedAccountForLinkBio']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountType?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectedFacebookResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectedFacebook'] = ResolversParentTypes['ConnectedFacebook']
> = ResolversObject<{
  fbName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectedFacebookPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectedFacebookPage'] = ResolversParentTypes['ConnectedFacebookPage']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectedPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectedPage'] = ResolversParentTypes['ConnectedPage']
> = ResolversObject<{
  pageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectedPageAndIgAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectedPageAndIgAccount'] = ResolversParentTypes['ConnectedPageAndIgAccount']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  igName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  igUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pageName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectedTwitterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectedTwitter'] = ResolversParentTypes['ConnectedTwitter']
> = ResolversObject<{
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  twName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  twUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectedYouTubeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectedYouTube'] = ResolversParentTypes['ConnectedYouTube']
> = ResolversObject<{
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CountriesPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesPayload'] = ResolversParentTypes['CountriesPayload']
> = ResolversObject<{
  countries?: Resolver<ReadonlyArray<ResolversTypes['Country']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CountryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CountryAndCurrencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountryAndCurrency'] = ResolversParentTypes['CountryAndCurrency']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAvailableCountry?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CountryNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountryName'] = ResolversParentTypes['CountryName']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CountryNameForInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountryNameForInfluencer'] = ResolversParentTypes['CountryNameForInfluencer']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isMena?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateAdminResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateAdmin'] = ResolversParentTypes['CreateAdmin']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateAdvertiserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateAdvertiser'] = ResolversParentTypes['CreateAdvertiser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateAdvertiserUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateAdvertiserUser'] = ResolversParentTypes['CreateAdvertiserUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateAdvertiserWithUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateAdvertiserWithUser'] = ResolversParentTypes['CreateAdvertiserWithUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateAdvertiserWithUserV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateAdvertiserWithUserV2'] = ResolversParentTypes['CreateAdvertiserWithUserV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateAgency'] = ResolversParentTypes['CreateAgency']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateAgencyWithUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateAgencyWithUser'] = ResolversParentTypes['CreateAgencyWithUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateCreatorStaffPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateCreatorStaffPayload'] = ResolversParentTypes['CreateCreatorStaffPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateDraftBoostPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateDraftBoostPost'] = ResolversParentTypes['CreateDraftBoostPost']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateEngagementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateEngagement'] = ResolversParentTypes['CreateEngagement']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateEngagementPostForInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateEngagementPostForInfluencerV2'] = ResolversParentTypes['CreateEngagementPostForInfluencerV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateEngagementPostV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateEngagementPostV2'] = ResolversParentTypes['CreateEngagementPostV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateGeminiThreadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateGeminiThread'] = ResolversParentTypes['CreateGeminiThread']
> = ResolversObject<{
  threadId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateInfluencerBioPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateInfluencerBioPayload'] = ResolversParentTypes['CreateInfluencerBioPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateInfluencerStaffPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateInfluencerStaffPayload'] = ResolversParentTypes['CreateInfluencerStaffPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateInfluencerV2'] = ResolversParentTypes['CreateInfluencerV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateInstagramUGCManagementHashtagOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateInstagramUGCManagementHashtagOutput'] = ResolversParentTypes['CreateInstagramUGCManagementHashtagOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateInstagramUserHashtagOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateInstagramUserHashtagOutput'] = ResolversParentTypes['CreateInstagramUserHashtagOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateLineBroadcastMessagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateLineBroadcastMessagePayload'] = ResolversParentTypes['CreateLineBroadcastMessagePayload']
> = ResolversObject<{
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateLineChannelPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateLineChannelPayload'] = ResolversParentTypes['CreateLineChannelPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateMarketplaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateMarketplace'] = ResolversParentTypes['CreateMarketplace']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateMarketplaceDraftPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateMarketplaceDraftPost'] = ResolversParentTypes['CreateMarketplaceDraftPost']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateMobileAppVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateMobileAppVersion'] = ResolversParentTypes['CreateMobileAppVersion']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateOpenAIThreadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateOpenAIThread'] = ResolversParentTypes['CreateOpenAIThread']
> = ResolversObject<{
  threadId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatePackageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatePackage'] = ResolversParentTypes['CreatePackage']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatePackageWithInfluencerAccountsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreatePackageWithInfluencerAccounts'] = ResolversParentTypes['CreatePackageWithInfluencerAccounts']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatePackageWithSocialAccountsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreatePackageWithSocialAccounts'] = ResolversParentTypes['CreatePackageWithSocialAccounts']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatePackageWithUserProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreatePackageWithUserProposal'] = ResolversParentTypes['CreatePackageWithUserProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatePartnerUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatePartnerUser'] = ResolversParentTypes['CreatePartnerUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateSelfRegisteredAdvertiserUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateSelfRegisteredAdvertiserUser'] = ResolversParentTypes['CreateSelfRegisteredAdvertiserUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateSocialAccountAnalyticsAdminOutputV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateSocialAccountAnalyticsAdminOutputV2'] = ResolversParentTypes['CreateSocialAccountAnalyticsAdminOutputV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateSocialAccountAnalyticsAdvertiserOutputV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateSocialAccountAnalyticsAdvertiserOutputV2'] = ResolversParentTypes['CreateSocialAccountAnalyticsAdvertiserOutputV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateStaffResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateStaff'] = ResolversParentTypes['CreateStaff']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateTalentAgencyUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateTalentAgencyUser'] = ResolversParentTypes['CreateTalentAgencyUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateTalentAgencyWithUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateTalentAgencyWithUser'] = ResolversParentTypes['CreateTalentAgencyWithUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateTalentInfluencerPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateTalentInfluencerPayload'] = ResolversParentTypes['CreateTalentInfluencerPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateTikTokSpecialCampaignResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateTikTokSpecialCampaign'] = ResolversParentTypes['CreateTikTokSpecialCampaign']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateTiktokAdAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateTiktokAdAccount'] = ResolversParentTypes['CreateTiktokAdAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateTiktokBusinessCenterResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateTiktokBusinessCenter'] = ResolversParentTypes['CreateTiktokBusinessCenter']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateTiktokUserHashtagOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateTiktokUserHashtagOutput'] = ResolversParentTypes['CreateTiktokUserHashtagOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateTiktokUserKeywordOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateTiktokUserKeywordOutput'] = ResolversParentTypes['CreateTiktokUserKeywordOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatorAddressPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatorAddressPayload'] = ResolversParentTypes['CreatorAddressPayload']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  province?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatorStaffResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatorStaff'] = ResolversParentTypes['CreatorStaff']
> = ResolversObject<{
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatorStaffsCountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreatorStaffsCountPayload'] = ResolversParentTypes['CreatorStaffsCountPayload']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatorStaffsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatorStaffsPayload'] = ResolversParentTypes['CreatorStaffsPayload']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  staffs?: Resolver<ReadonlyArray<ResolversTypes['CreatorStaff']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CurrentUserCurrencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentUserCurrency'] = ResolversParentTypes['CurrentUserCurrency']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomPermissionLevelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomPermissionLevel'] = ResolversParentTypes['CustomPermissionLevel']
> = ResolversObject<{
  manage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  manageAndDelete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  noView?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomPermissionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomPermissions'] = ResolversParentTypes['CustomPermissions']
> = ResolversObject<{
  advertiser?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  agency?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  analytics?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  customizeUserPermissions?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  influencer?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  influencerAddressAndPaymentInformation?: Resolver<
    Maybe<ResolversTypes['CustomPermissionLevel']>,
    ParentType,
    ContextType
  >;
  influencerPackage?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  marketplace?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  partnerAgency?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  report?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  talentAgency?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['CustomPermissionLevel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerTagPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerTagPayload'] = ResolversParentTypes['CustomerTagPayload']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeleteAdvertiserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteAdvertiser'] = ResolversParentTypes['DeleteAdvertiser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteAgency'] = ResolversParentTypes['DeleteAgency']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteBrandAccountAnalyticsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteBrandAccountAnalyticsOutput'] = ResolversParentTypes['DeleteBrandAccountAnalyticsOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteCommentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteCommentPayload'] = ResolversParentTypes['DeleteCommentPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteCompareInstagramAccountAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteCompareInstagramAccountAnalytics'] = ResolversParentTypes['DeleteCompareInstagramAccountAnalytics']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteEngagementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteEngagement'] = ResolversParentTypes['DeleteEngagement']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteEngagementInfluencerProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteEngagementInfluencerProposal'] = ResolversParentTypes['DeleteEngagementInfluencerProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteEngagementPostForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteEngagementPostForInfluencer'] = ResolversParentTypes['DeleteEngagementPostForInfluencer']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteEngagementProposalJoinedAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteEngagementProposalJoinedAccount'] = ResolversParentTypes['DeleteEngagementProposalJoinedAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteEngagementSocialAccountProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteEngagementSocialAccountProposal'] = ResolversParentTypes['DeleteEngagementSocialAccountProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteFanTagsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteFanTagsPayload'] = ResolversParentTypes['DeleteFanTagsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteGeminiThreadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteGeminiThread'] = ResolversParentTypes['DeleteGeminiThread']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteInfluencer'] = ResolversParentTypes['DeleteInfluencer']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteInfluencerPackageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteInfluencerPackage'] = ResolversParentTypes['DeleteInfluencerPackage']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteInstagramHashtagAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteInstagramHashtagAnalytics'] = ResolversParentTypes['DeleteInstagramHashtagAnalytics']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteInstagramUGCManagementHashtagOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteInstagramUGCManagementHashtagOutput'] = ResolversParentTypes['DeleteInstagramUGCManagementHashtagOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteInstagramUserHashtagOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteInstagramUserHashtagOutput'] = ResolversParentTypes['DeleteInstagramUserHashtagOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteMarketplaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteMarketplace'] = ResolversParentTypes['DeleteMarketplace']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteOpenAIThreadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteOpenAIThread'] = ResolversParentTypes['DeleteOpenAIThread']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeletePackageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletePackage'] = ResolversParentTypes['DeletePackage']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteSocialAccountPackageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteSocialAccountPackage'] = ResolversParentTypes['DeleteSocialAccountPackage']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteTalentAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteTalentAgency'] = ResolversParentTypes['DeleteTalentAgency']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteTikTokSpecialCampaignResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteTikTokSpecialCampaign'] = ResolversParentTypes['DeleteTikTokSpecialCampaign']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteTiktokUserHashtagOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteTiktokUserHashtagOutput'] = ResolversParentTypes['DeleteTiktokUserHashtagOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteTiktokUserKeywordOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteTiktokUserKeywordOutput'] = ResolversParentTypes['DeleteTiktokUserKeywordOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteUser'] = ResolversParentTypes['DeleteUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteUserProposalInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteUserProposalInfluencer'] = ResolversParentTypes['DeleteUserProposalInfluencer']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteUserProposalSocialAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteUserProposalSocialAccount'] = ResolversParentTypes['DeleteUserProposalSocialAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteUserProposalSocialAccountIndividuallyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteUserProposalSocialAccountIndividually'] = ResolversParentTypes['DeleteUserProposalSocialAccountIndividually']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteYoutubeCompareAccountAnalyticsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteYoutubeCompareAccountAnalyticsOutput'] = ResolversParentTypes['DeleteYoutubeCompareAccountAnalyticsOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryTimingItemPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeliveryTimingItemPayload'] = ResolversParentTypes['DeliveryTimingItemPayload']
> = ResolversObject<{
  scheduleDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DeliveryTimingType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DenyEngagementInfluencerProposalListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DenyEngagementInfluencerProposalList'] = ResolversParentTypes['DenyEngagementInfluencerProposalList']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DenyInfluencersUserProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DenyInfluencersUserProposal'] = ResolversParentTypes['DenyInfluencersUserProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DisconnectPackageFromEngagementProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DisconnectPackageFromEngagementProposal'] = ResolversParentTypes['DisconnectPackageFromEngagementProposal']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DisconnectPromotionMethodsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DisconnectPromotionMethodsPayload'] = ResolversParentTypes['DisconnectPromotionMethodsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DisconnectTiktokAdAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DisconnectTiktokAdAccount'] = ResolversParentTypes['DisconnectTiktokAdAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DouyinAccountGeneralCategoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DouyinAccountGeneralCategory'] = ResolversParentTypes['DouyinAccountGeneralCategory']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  detailCategory?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DouyinAccountInterestRate']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DouyinAccountInterestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DouyinAccountInterest'] = ResolversParentTypes['DouyinAccountInterest']
> = ResolversObject<{
  generalCategory?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['DouyinAccountGeneralCategory']>>,
    ParentType,
    ContextType
  >;
  videoType?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DouyinAccountInterestRate']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DouyinAccountInterestRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DouyinAccountInterestRate'] = ResolversParentTypes['DouyinAccountInterestRate']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DouyinAudienceSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DouyinAudienceSection'] = ResolversParentTypes['DouyinAudienceSection']
> = ResolversObject<{
  ageRates?: Resolver<ResolversTypes['TiktokFollowersAgeGroup'], ParentType, ContextType>;
  femaleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  regionRates?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DouyinFollowerRegionRate']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DouyinFollowerRegionRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DouyinFollowerRegionRate'] = ResolversParentTypes['DouyinFollowerRegionRate']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DouyinInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DouyinInfluencer'] = ResolversParentTypes['DouyinInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountEngagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DouyinInfluencerSearchResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DouyinInfluencerSearchResult'] = ResolversParentTypes['DouyinInfluencerSearchResult']
> = ResolversObject<{
  douyinAccounts?: Resolver<ReadonlyArray<ResolversTypes['DouyinInfluencer']>, ParentType, ContextType>;
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EditSocialAccountAnalyticsAdminOutputV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EditSocialAccountAnalyticsAdminOutputV2'] = ResolversParentTypes['EditSocialAccountAnalyticsAdminOutputV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EditSocialAccountAnalyticsAdvertiserOutputV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EditSocialAccountAnalyticsAdvertiserOutputV2'] = ResolversParentTypes['EditSocialAccountAnalyticsAdvertiserOutputV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailInfo'] = ResolversParentTypes['EmailInfo']
> = ResolversObject<{
  fromEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  toEmails?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailInfoForListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailInfoForList'] = ResolversParentTypes['EmailInfoForList']
> = ResolversObject<{
  clickRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  openRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scheduleDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EmailTemplateStatus'], ParentType, ContextType>;
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailStatisticsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailStatistics'] = ResolversParentTypes['EmailStatistics']
> = ResolversObject<{
  bounceCount?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  clickCount?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  openCount?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  recipientsCount?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  unsubscribeCount?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Engagement'] = ResolversParentTypes['Engagement']
> = ResolversObject<{
  advertiser?: Resolver<ResolversTypes['AdvertiserName'], ParentType, ContextType>;
  budget?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  countries?: Resolver<ReadonlyArray<ResolversTypes['CountryName']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultPostRequirement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerManagementPics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PicName']>>, ParentType, ContextType>;
  salesPics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PicName']>>, ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EngagementCampaignStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementAddInfluencersToProposalFromAnotherCampaignResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementAddInfluencersToProposalFromAnotherCampaign'] = ResolversParentTypes['EngagementAddInfluencersToProposalFromAnotherCampaign']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementCampaignCouponTrackingPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementCampaignCouponTrackingPayload'] = ResolversParentTypes['EngagementCampaignCouponTrackingPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementCampaignPostReportAudienceBreakdownResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementCampaignPostReportAudienceBreakdown'] = ResolversParentTypes['EngagementCampaignPostReportAudienceBreakdown']
> = ResolversObject<{
  tiktok?: Resolver<
    Maybe<ResolversTypes['EngagementCampaignPostReportAudienceBreakdownForTikTok']>,
    ParentType,
    ContextType
  >;
  youtube?: Resolver<
    Maybe<ResolversTypes['EngagementCampaignPostReportAudienceBreakdownForYouTube']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementCampaignPostReportAudienceBreakdownForTikTokResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementCampaignPostReportAudienceBreakdownForTikTok'] = ResolversParentTypes['EngagementCampaignPostReportAudienceBreakdownForTikTok']
> = ResolversObject<{
  ageRates?: Resolver<ResolversTypes['AudienceBreakdownAgeRates'], ParentType, ContextType>;
  areaRates?: Resolver<ReadonlyArray<ResolversTypes['AudienceBreakdownAreaRate']>, ParentType, ContextType>;
  genderRates?: Resolver<ResolversTypes['AudienceBreakdownGenderRates'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementCampaignPostReportAudienceBreakdownForYouTubeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementCampaignPostReportAudienceBreakdownForYouTube'] = ResolversParentTypes['EngagementCampaignPostReportAudienceBreakdownForYouTube']
> = ResolversObject<{
  ageGenderRates?: Resolver<ResolversTypes['AudienceBreakdownAgeGenderRates'], ParentType, ContextType>;
  areaRates?: Resolver<ReadonlyArray<ResolversTypes['AudienceBreakdownAreaRate']>, ParentType, ContextType>;
  genderRates?: Resolver<ResolversTypes['AudienceBreakdownGenderRates'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementCampaignPostReportForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementCampaignPostReportForInfluencer'] = ResolversParentTypes['EngagementCampaignPostReportForInfluencer']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postReport?: Resolver<ReadonlyArray<ResolversTypes['InfluencerPostReportStatsEngagement']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementCampaignPostsForInsightCheckResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementCampaignPostsForInsightCheck'] = ResolversParentTypes['EngagementCampaignPostsForInsightCheck']
> = ResolversObject<{
  campaignTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['EngagementPostForInsightCheck']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementCampaignReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementCampaignReport'] = ResolversParentTypes['EngagementCampaignReport']
> = ResolversObject<{
  InternalInfo?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  average?: Resolver<Maybe<ResolversTypes['NewCampaignStatsItemRow']>, ParentType, ContextType>;
  campaignInfo?: Resolver<ResolversTypes['CampaignReportCampaignInfo'], ParentType, ContextType>;
  chartData?: Resolver<ReadonlyArray<ResolversTypes['CampaignChartReport']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  influencerReports?: Resolver<ReadonlyArray<ResolversTypes['CampaignInfluencerReport']>, ParentType, ContextType>;
  orderedSummaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignSummaryDaily']>>, ParentType, ContextType>;
  postCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['NewCampaignStatsItemRow'], ParentType, ContextType>;
  summaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignSummaryDaily']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementCampaignSearchSelectInfluencersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementCampaignSearchSelectInfluencers'] = ResolversParentTypes['EngagementCampaignSearchSelectInfluencers']
> = ResolversObject<{
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countries?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfInfluencers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementDetail'] = ResolversParentTypes['EngagementDetail']
> = ResolversObject<{
  adAgencyMarginRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  advertiser?: Resolver<ResolversTypes['AdvertiserName'], ParentType, ContextType>;
  budget?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  campaignCategory?: Resolver<Maybe<ResolversTypes['CampaignCategoryName']>, ParentType, ContextType>;
  campaignCreator?: Resolver<Maybe<ResolversTypes['CampaignCreator']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  countries?: Resolver<ReadonlyArray<ResolversTypes['CountryName']>, ParentType, ContextType>;
  couponList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  couponUploadFiles?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultPostRequirement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  genders?: Resolver<ReadonlyArray<ResolversTypes['Genders']>, ParentType, ContextType>;
  hashtags?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  hubspotDealIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerManagementPics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PicName']>>, ParentType, ContextType>;
  isTtcmCampaign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  landingPageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  materialUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  materials?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  maxAge?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxFollowers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minAge?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minFollowers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfInfluencers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  objective?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportStartDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  salesPics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PicName']>>, ParentType, ContextType>;
  serviceInformation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EngagementCampaignStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trackingOptions?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignTrackingOption']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementDetailForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementDetailForInfluencer'] = ResolversParentTypes['EngagementDetailForInfluencer']
> = ResolversObject<{
  anyXStatus?: Resolver<ResolversTypes['AnyXEngagementCampaignStatus'], ParentType, ContextType>;
  couponCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  hasDraftPosts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasWarningPosts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isCouponCampaign?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isJoined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTtcmCampaign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTtcmReady?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  materialUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  materials?: Resolver<Maybe<ReadonlyArray<ResolversTypes['MaterialName']>>, ParentType, ContextType>;
  objective?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postStatus?: Resolver<Maybe<ResolversTypes['CampaignPostStatusForInfluencer']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requirements?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rewardAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  serviceInformation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CampaignStatusForInfluencer'], ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trackingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ttcmCampaignCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ttcmStatus?: Resolver<Maybe<ResolversTypes['TTCMStatus']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementDraftPostHistoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementDraftPostHistory'] = ResolversParentTypes['EngagementDraftPostHistory']
> = ResolversObject<{
  engagementPostDetailInfluencer?: Resolver<ResolversTypes['EngagementPostDetailInfluencer'], ParentType, ContextType>;
  historyRecords?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementDraftPostHistoryRecord']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementDraftPostHistoryForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementDraftPostHistoryForInfluencer'] = ResolversParentTypes['EngagementDraftPostHistoryForInfluencer']
> = ResolversObject<{
  historyRecords?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementDraftPostHistoryRecordForInfluencer']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementDraftPostHistoryRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementDraftPostHistoryRecord'] = ResolversParentTypes['EngagementDraftPostHistoryRecord']
> = ResolversObject<{
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  detailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  images?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  postContent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postStatus?: Resolver<Maybe<ResolversTypes['EngagementCampaignPostStatus']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementDraftPostHistoryRecordForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementDraftPostHistoryRecordForInfluencer'] = ResolversParentTypes['EngagementDraftPostHistoryRecordForInfluencer']
> = ResolversObject<{
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  detailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postStatus?: Resolver<Maybe<ResolversTypes['EngagementCampaignPostStatus']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementForInsightCheckResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementForInsightCheck'] = ResolversParentTypes['EngagementForInsightCheck']
> = ResolversObject<{
  countries?: Resolver<ReadonlyArray<ResolversTypes['CountryName']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EngagementCampaignPostStatusForInsightCheck'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalConfirmations?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSubmissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementPost'] = ResolversParentTypes['EngagementPost']
> = ResolversObject<{
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['EngagementPostInfluencer'], ParentType, ContextType>;
  planedPostDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  socialAccountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountStatus?: Resolver<Maybe<ResolversTypes['SocialAccountStatus']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['EngagementCampaignSocialAccountPostStatus']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  warningReason?: Resolver<Maybe<ResolversTypes['WarningReason']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostCommentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementPostComment'] = ResolversParentTypes['EngagementPostComment']
> = ResolversObject<{
  commentedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementPostDetail'] = ResolversParentTypes['EngagementPostDetail']
> = ResolversObject<{
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  detailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engagementPostDetailInfluencer?: Resolver<ResolversTypes['EngagementPostDetailInfluencer'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  images?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['EngagementCampaignPostStatus']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  warningReason?: Resolver<Maybe<ResolversTypes['WarningReason']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostDetailForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostDetailForInfluencer'] = ResolversParentTypes['EngagementPostDetailForInfluencer']
> = ResolversObject<{
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engagementPostDetailInfluencer?: Resolver<ResolversTypes['EngagementPostDetailInfluencer'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  images?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  postedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostDetailForInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostDetailForInfluencerV2'] = ResolversParentTypes['EngagementPostDetailForInfluencerV2']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inappropriateDetailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inappropriateReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  materials?: Resolver<Maybe<ReadonlyArray<ResolversTypes['MaterialName']>>, ParentType, ContextType>;
  planedPostDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postStatus?: Resolver<ResolversTypes['EngagementCampaignPostStatus'], ParentType, ContextType>;
  socialAccountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountMedia?: Resolver<Maybe<ResolversTypes['SocialAccountType']>, ParentType, ContextType>;
  socialAccountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostDetailInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostDetailInfluencer'] = ResolversParentTypes['EngagementPostDetailInfluencer']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostEditV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementPostEditV2'] = ResolversParentTypes['EngagementPostEditV2']
> = ResolversObject<{
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['EngagementPostInfluencerWithSocialAccount'], ParentType, ContextType>;
  insightDataAcquisition?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  insightUrls?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  materials?: Resolver<Maybe<ReadonlyArray<ResolversTypes['MaterialName']>>, ParentType, ContextType>;
  planedPostDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['EngagementCampaignPostStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostForInfluencer'] = ResolversParentTypes['EngagementPostForInfluencer']
> = ResolversObject<{
  anyXStatus?: Resolver<ResolversTypes['AnyXEngagementCampaignStatus'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inappropriateDetailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inappropriateReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPreviouslyModified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  planedPostDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EngagementCampaignPostStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ttcmInviteLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostForInsightCheckResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostForInsightCheck'] = ResolversParentTypes['EngagementPostForInsightCheck']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postContent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EngagementCampaignPostStatusForInsightCheck'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementPostInfluencer'] = ResolversParentTypes['EngagementPostInfluencer']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostInfluencerWithSocialAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostInfluencerWithSocialAccount'] = ResolversParentTypes['EngagementPostInfluencerWithSocialAccount']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountMedia?: Resolver<Maybe<ResolversTypes['SocialAccountType']>, ParentType, ContextType>;
  socialAccountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostInfluencerWithSocialAccountsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostInfluencerWithSocialAccounts'] = ResolversParentTypes['EngagementPostInfluencerWithSocialAccounts']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccounts?: Resolver<ReadonlyArray<ResolversTypes['EngagementPostSocialAccount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostMetricsDataForInsightCheckResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostMetricsDataForInsightCheck'] = ResolversParentTypes['EngagementPostMetricsDataForInsightCheck']
> = ResolversObject<{
  audienceRetention?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  back?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  exited?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  followerReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  forward?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsClickThroughRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressionsFromDiscovery?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHashtag?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromOther?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromProfile?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  insightUrls?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  linkClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  navigation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  newFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextStory?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postContent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileActivity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reaches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EngagementCampaignPostStatusForInsightCheck'], ParentType, ContextType>;
  stickerTaps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostSocialAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostSocialAccount'] = ResolversParentTypes['EngagementPostSocialAccount']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  ttcmCampaignInviteLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ttcmStatus?: Resolver<Maybe<ResolversTypes['TTCMStatus']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementPostTrafficSourceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementPostTrafficSource'] = ResolversParentTypes['EngagementPostTrafficSource']
> = ResolversObject<{
  estimatedMinutesWatched?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sourceType?: Resolver<ResolversTypes['YoutubeTrafficSource'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementProposalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementProposal'] = ResolversParentTypes['EngagementProposal']
> = ResolversObject<{
  activeTtcmCreatorsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  couponCodeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencers?: Resolver<ReadonlyArray<ResolversTypes['ProposalInfluencer']>, ParentType, ContextType>;
  jdMode?: Resolver<ResolversTypes['ProposalJobDescriptionBreakDownMode'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['ProposalJobSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementTrackingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementTracking'] = ResolversParentTypes['EngagementTracking']
> = ResolversObject<{
  pixelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postbackUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementXhsPostMetricsDataForInsightResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementXhsPostMetricsDataForInsight'] = ResolversParentTypes['EngagementXhsPostMetricsDataForInsight']
> = ResolversObject<{
  age45UpPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age1824Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age2534Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age3544Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ageUp17Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  femalePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  insightUrls?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  malePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  newFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postContent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EngagementCampaignPostStatusForInsightCheck'], ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromFollowing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromHome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromOther?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromProfile?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromSearch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementsForInsightCheckResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementsForInsightCheck'] = ResolversParentTypes['EngagementsForInsightCheck']
> = ResolversObject<{
  engagementCampaigns?: Resolver<ReadonlyArray<ResolversTypes['EngagementForInsightCheck']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EngagementsForInsightCheckCountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementsForInsightCheckCount'] = ResolversParentTypes['EngagementsForInsightCheckCount']
> = ResolversObject<{
  totalConfirmed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUnconfirmed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExistingProfileTagsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExistingProfileTags'] = ResolversParentTypes['ExistingProfileTags']
> = ResolversObject<{
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExportCmsInvoiceToSpreadsheetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExportCmsInvoiceToSpreadsheet'] = ResolversParentTypes['ExportCmsInvoiceToSpreadsheet']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExportDashboardInstagramAccountAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExportDashboardInstagramAccountAnalytics'] = ResolversParentTypes['ExportDashboardInstagramAccountAnalytics']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExportInstagramHashtagPostsToGoogleSpreadsheetOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExportInstagramHashtagPostsToGoogleSpreadsheetOutput'] = ResolversParentTypes['ExportInstagramHashtagPostsToGoogleSpreadsheetOutput']
> = ResolversObject<{
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExportYoutubeDashboardAccountAnalyticsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExportYoutubeDashboardAccountAnalyticsOutput'] = ResolversParentTypes['ExportYoutubeDashboardAccountAnalyticsOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalAnalyticsFacebookGetAccountDetailsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalAnalyticsFacebookGetAccountDetailsOutput'] = ResolversParentTypes['ExternalAnalyticsFacebookGetAccountDetailsOutput']
> = ResolversObject<{
  fbPageAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbPageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalAnalyticsFacebookGetTokenWarningsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalAnalyticsFacebookGetTokenWarningsOutput'] = ResolversParentTypes['ExternalAnalyticsFacebookGetTokenWarningsOutput']
> = ResolversObject<{
  analyticsAccountsWithInvalidTokens?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalAnalyticsInstagramGetAccountDetailsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalAnalyticsInstagramGetAccountDetailsOutput'] = ResolversParentTypes['ExternalAnalyticsInstagramGetAccountDetailsOutput']
> = ResolversObject<{
  fbPageAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbPageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  igAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  igUsername?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalAnalyticsInstagramGetTokenWarningsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalAnalyticsInstagramGetTokenWarningsOutput'] = ResolversParentTypes['ExternalAnalyticsInstagramGetTokenWarningsOutput']
> = ResolversObject<{
  analyticsAccountsWithInvalidTokens?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalAnalyticsSocialAuthRedirectUrlPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalAnalyticsSocialAuthRedirectUrlPayload'] = ResolversParentTypes['ExternalAnalyticsSocialAuthRedirectUrlPayload']
> = ResolversObject<{
  redirectUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalConnectableFacebookPageAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalConnectableFacebookPageAccount'] = ResolversParentTypes['ExternalConnectableFacebookPageAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalConnectableFacebookPageAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalConnectableFacebookPageAnalyticsPayload'] = ResolversParentTypes['ExternalConnectableFacebookPageAnalyticsPayload']
> = ResolversObject<{
  account?: Resolver<Maybe<ResolversTypes['ExternalConnectableFacebookPageMainAccount']>, ParentType, ContextType>;
  pages?: Resolver<ReadonlyArray<ResolversTypes['ExternalConnectableFacebookPageAccount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalConnectableFacebookPageMainAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalConnectableFacebookPageMainAccount'] = ResolversParentTypes['ExternalConnectableFacebookPageMainAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  friendCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalConnectableOauthAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalConnectableOauthAccount'] = ResolversParentTypes['ExternalConnectableOauthAccount']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalConnectableOauthPageAndIgAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalConnectableOauthPageAndIgAccount'] = ResolversParentTypes['ExternalConnectableOauthPageAndIgAccount']
> = ResolversObject<{
  igAccount?: Resolver<ResolversTypes['ExternalConnectableOauthAccount'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['ExternalConnectableOauthAccount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalCreateFacebookPageAccountAnalyticsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalCreateFacebookPageAccountAnalyticsOutput'] = ResolversParentTypes['ExternalCreateFacebookPageAccountAnalyticsOutput']
> = ResolversObject<{
  analyticsAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalCreateInstagramAccountAnalyticsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalCreateInstagramAccountAnalyticsOutput'] = ResolversParentTypes['ExternalCreateInstagramAccountAnalyticsOutput']
> = ResolversObject<{
  analyticsAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalCreateYoutubeAccountAnalyticsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalCreateYoutubeAccountAnalyticsOutput'] = ResolversParentTypes['ExternalCreateYoutubeAccountAnalyticsOutput']
> = ResolversObject<{
  analyticsAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramAnalyticsPostDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramAnalyticsPostDetails'] = ResolversParentTypes['ExternalInstagramAnalyticsPostDetails']
> = ResolversObject<{
  analysis?: Resolver<Maybe<ResolversTypes['InstagramAnalyticsPostAnalysisDetails']>, ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commentList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsPostCommentDetails']>>,
    ParentType,
    ContextType
  >;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mediaUrls?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sentiment?: Resolver<Maybe<ResolversTypes['InstagramAnalyticsPostSentimentDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramAnalyticsPostsInDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramAnalyticsPostsInDate'] = ResolversParentTypes['ExternalInstagramAnalyticsPostsInDate']
> = ResolversObject<{
  feedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ExternalInstagramAnalyticsPostDetails']>>,
    ParentType,
    ContextType
  >;
  reelPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ExternalInstagramAnalyticsReelDetails']>>,
    ParentType,
    ContextType
  >;
  storyPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ExternalInstagramAnalyticsStoryDetails']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramAnalyticsReelDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramAnalyticsReelDetails'] = ResolversParentTypes['ExternalInstagramAnalyticsReelDetails']
> = ResolversObject<{
  analysis?: Resolver<Maybe<ResolversTypes['InstagramAnalyticsReelAnalysisDetails']>, ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commentList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsReelCommentDetails']>>,
    ParentType,
    ContextType
  >;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramAnalyticsStoryDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramAnalyticsStoryDetails'] = ResolversParentTypes['ExternalInstagramAnalyticsStoryDetails']
> = ResolversObject<{
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reach?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  storyVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tapsBack?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tapsForward?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramDashboardFollowerAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramDashboardFollowerAnalytics'] = ResolversParentTypes['ExternalInstagramDashboardFollowerAnalytics']
> = ResolversObject<{
  followerAgeRate?: Resolver<ResolversTypes['InstagramFollowersAgeRate'], ParentType, ContextType>;
  followerGenderRate?: Resolver<ResolversTypes['InstagramFollowerGenderRate'], ParentType, ContextType>;
  followerRegionRate?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramFollowersRegionRate']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramDashboardOverviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramDashboardOverview'] = ResolversParentTypes['ExternalInstagramDashboardOverview']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['InstagramAnalyticsOverviewEngagementRateData'], ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  impressions?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  posts?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  profileViews?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  reach?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  saved?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  websiteClicks?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramDashboardPostAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramDashboardPostAnalytics'] = ResolversParentTypes['ExternalInstagramDashboardPostAnalytics']
> = ResolversObject<{
  averageEngagement?: Resolver<Maybe<ResolversTypes['InstagramPostAverageEngagement']>, ParentType, ContextType>;
  commentAnalytics?: Resolver<Maybe<ResolversTypes['InstagramCommentAnalytics']>, ParentType, ContextType>;
  feedPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ExternalInstagramFeedPost']>>, ParentType, ContextType>;
  postsHabit?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramPostsHabit']>>, ParentType, ContextType>;
  storyPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ExternalInstagramStoryPost']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramFeedPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramFeedPost'] = ResolversParentTypes['ExternalInstagramFeedPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  follow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressionFromDiscovery?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionFromHashtag?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  negativeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nonFollowerReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  positiveRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productButtonClick?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productPageView?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramReelPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramReelPost'] = ResolversParentTypes['ExternalInstagramReelPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalInstagramStoryPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalInstagramStoryPost'] = ResolversParentTypes['ExternalInstagramStoryPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  follow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  linkClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productButtonClick?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productPageView?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stickerTaps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tapsBack?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tapsForward?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload'] = ResolversParentTypes['ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload']
> = ResolversObject<{
  accounts?: Resolver<
    ReadonlyArray<ResolversTypes['ExternalConnectableOauthPageAndIgAccount']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload'] = ResolversParentTypes['ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload'] = ResolversParentTypes['ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload']
> = ResolversObject<{
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsAudienceAgeBreakdownPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload']
> = ResolversObject<{
  ageGroup?: Resolver<
    ReadonlyArray<ResolversTypes['ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload']>,
    ParentType,
    ContextType
  >;
  femaleRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  otherRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsAudienceGenderBreakdownPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload']
> = ResolversObject<{
  femaleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  otherRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsAudiencePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsAudiencePayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsAudiencePayload']
> = ResolversObject<{
  ageRate?: Resolver<ResolversTypes['ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload'], ParentType, ContextType>;
  genderRate?: Resolver<
    ResolversTypes['ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload'],
    ParentType,
    ContextType
  >;
  regionRate?: Resolver<
    ReadonlyArray<ResolversTypes['ExternalYoutubeAnalyticsAudienceRegionPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsAudienceRegionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsAudienceRegionPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsAudienceRegionPayload']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsOverviewHistoryItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsOverviewHistoryItem'] = ResolversParentTypes['ExternalYoutubeAnalyticsOverviewHistoryItem']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsOverviewItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsOverviewItem'] = ResolversParentTypes['ExternalYoutubeAnalyticsOverviewItem']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  history?: Resolver<
    ReadonlyArray<ResolversTypes['ExternalYoutubeAnalyticsOverviewHistoryItem']>,
    ParentType,
    ContextType
  >;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsOverviewPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsOverviewPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsOverviewPayload']
> = ResolversObject<{
  comment?: Resolver<ResolversTypes['ExternalYoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  dislike?: Resolver<ResolversTypes['ExternalYoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  like?: Resolver<ResolversTypes['ExternalYoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  post?: Resolver<ResolversTypes['ExternalYoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['ExternalYoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  view?: Resolver<ResolversTypes['ExternalYoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsPostCommentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsPostComment'] = ResolversParentTypes['ExternalYoutubeAnalyticsPostComment']
> = ResolversObject<{
  commentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posterName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsPostListByDatePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsPostListByDatePayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsPostListByDatePayload']
> = ResolversObject<{
  posts?: Resolver<
    ReadonlyArray<ResolversTypes['ExternalYoutubeAnalyticsPostListItemByDatePayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsPostListItemByDatePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsPostListItemByDatePayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsPostListItemByDatePayload']
> = ResolversObject<{
  averageViewDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageViewPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dislikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  subscribers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoComments?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostComment']>, ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoEmbed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoTags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsPostListItemPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsPostListItemPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsPostListItemPayload']
> = ResolversObject<{
  averageViewDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageViewPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dislikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsPostListPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsPostListPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsPostListPayload']
> = ResolversObject<{
  posts?: Resolver<
    ReadonlyArray<ResolversTypes['ExternalYoutubeAnalyticsPostListItemPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsPostsAverageEngagementResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsPostsAverageEngagement'] = ResolversParentTypes['ExternalYoutubeAnalyticsPostsAverageEngagement']
> = ResolversObject<{
  averageComments?: Resolver<ResolversTypes['ExternalYoutubeStatisticsData'], ParentType, ContextType>;
  averageDislikes?: Resolver<ResolversTypes['ExternalYoutubeStatisticsData'], ParentType, ContextType>;
  averageLikes?: Resolver<ResolversTypes['ExternalYoutubeStatisticsData'], ParentType, ContextType>;
  averagePostsPerWeek?: Resolver<Maybe<ResolversTypes['ExternalYoutubeStatisticsData']>, ParentType, ContextType>;
  averageViews?: Resolver<ResolversTypes['ExternalYoutubeStatisticsData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsPostsPostingHabitResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsPostsPostingHabit'] = ResolversParentTypes['ExternalYoutubeAnalyticsPostsPostingHabit']
> = ResolversObject<{
  averageComments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageLikes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekday?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsPostsStatisticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsPostsStatisticsPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsPostsStatisticsPayload']
> = ResolversObject<{
  averageEngagement?: Resolver<
    ResolversTypes['ExternalYoutubeAnalyticsPostsAverageEngagement'],
    ParentType,
    ContextType
  >;
  postHabit?: Resolver<
    ReadonlyArray<ResolversTypes['ExternalYoutubeAnalyticsPostsPostingHabit']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsRelatedPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsRelatedPostPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsRelatedPostPayload']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoEmbed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsRelatedPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsRelatedPostsPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsRelatedPostsPayload']
> = ResolversObject<{
  posts?: Resolver<
    ReadonlyArray<ResolversTypes['ExternalYoutubeAnalyticsRelatedPostPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsTagRankingPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsTagRankingPayload'] = ResolversParentTypes['ExternalYoutubeAnalyticsTagRankingPayload']
> = ResolversObject<{
  averageComment?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageDislike?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageEngagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageLike?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageShare?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  posts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeAnalyticsTagRankingPayloadsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeAnalyticsTagRankingPayloads'] = ResolversParentTypes['ExternalYoutubeAnalyticsTagRankingPayloads']
> = ResolversObject<{
  tags?: Resolver<ReadonlyArray<ResolversTypes['ExternalYoutubeAnalyticsTagRankingPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubePostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubePostPayload'] = ResolversParentTypes['ExternalYoutubePostPayload']
> = ResolversObject<{
  averageViewDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageViewPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dislikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  subscribers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoComments?: Resolver<
    ReadonlyArray<ResolversTypes['ExternalYoutubeAnalyticsPostComment']>,
    ParentType,
    ContextType
  >;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoEmbed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoTags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalYoutubeStatisticsDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExternalYoutubeStatisticsData'] = ResolversParentTypes['ExternalYoutubeStatisticsData']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookAccountInfluencerSearchResultV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookAccountInfluencerSearchResultV2'] = ResolversParentTypes['FacebookAccountInfluencerSearchResultV2']
> = ResolversObject<{
  facebookAccounts?: Resolver<ReadonlyArray<ResolversTypes['FacebookAccountInfluencerV2']>, ParentType, ContextType>;
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookAccountInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookAccountInfluencerV2'] = ResolversParentTypes['FacebookAccountInfluencerV2']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  influencerEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacebookInfluencer'] = ResolversParentTypes['FacebookInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  influencerFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload'] = ResolversParentTypes['FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload']
> = ResolversObject<{
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsAudienceAgeBreakdownPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsAudienceAgeBreakdownPayload'] = ResolversParentTypes['FacebookPageAnalyticsAudienceAgeBreakdownPayload']
> = ResolversObject<{
  ageGroup?: Resolver<
    ReadonlyArray<ResolversTypes['FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload']>,
    ParentType,
    ContextType
  >;
  femaleRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  otherRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsAudienceGenderBreakdownPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsAudienceGenderBreakdownPayload'] = ResolversParentTypes['FacebookPageAnalyticsAudienceGenderBreakdownPayload']
> = ResolversObject<{
  femaleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  otherRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsAudiencePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsAudiencePayload'] = ResolversParentTypes['FacebookPageAnalyticsAudiencePayload']
> = ResolversObject<{
  ageRate?: Resolver<ResolversTypes['FacebookPageAnalyticsAudienceAgeBreakdownPayload'], ParentType, ContextType>;
  genderRate?: Resolver<ResolversTypes['FacebookPageAnalyticsAudienceGenderBreakdownPayload'], ParentType, ContextType>;
  regionRate?: Resolver<
    ReadonlyArray<ResolversTypes['FacebookPageAnalyticsAudienceRegionPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsAudienceRegionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsAudienceRegionPayload'] = ResolversParentTypes['FacebookPageAnalyticsAudienceRegionPayload']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsOverviewHistoryItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsOverviewHistoryItem'] = ResolversParentTypes['FacebookPageAnalyticsOverviewHistoryItem']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsOverviewHistoryRateItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsOverviewHistoryRateItem'] = ResolversParentTypes['FacebookPageAnalyticsOverviewHistoryRateItem']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsOverviewItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsOverviewItem'] = ResolversParentTypes['FacebookPageAnalyticsOverviewItem']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  history?: Resolver<
    ReadonlyArray<ResolversTypes['FacebookPageAnalyticsOverviewHistoryItem']>,
    ParentType,
    ContextType
  >;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsOverviewPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsOverviewPayload'] = ResolversParentTypes['FacebookPageAnalyticsOverviewPayload']
> = ResolversObject<{
  comment?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewRateItem'], ParentType, ContextType>;
  follower?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  impression?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  lastUpdated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  pageCta?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  pageImpression?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  pageLikes?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  post?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  reaction?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  share?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  view?: Resolver<ResolversTypes['FacebookPageAnalyticsOverviewItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsOverviewRateItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsOverviewRateItem'] = ResolversParentTypes['FacebookPageAnalyticsOverviewRateItem']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  history?: Resolver<
    ReadonlyArray<ResolversTypes['FacebookPageAnalyticsOverviewHistoryRateItem']>,
    ParentType,
    ContextType
  >;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsPostCommentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsPostComment'] = ResolversParentTypes['FacebookPageAnalyticsPostComment']
> = ResolversObject<{
  commentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posterName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsPostPayload'] = ResolversParentTypes['FacebookPageAnalyticsPostPayload']
> = ResolversObject<{
  adImpressions?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postComments?: Resolver<ReadonlyArray<ResolversTypes['FacebookPageAnalyticsPostComment']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subscribers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsPostWithPageDetailsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsPostWithPageDetailsPayload'] = ResolversParentTypes['FacebookPageAnalyticsPostWithPageDetailsPayload']
> = ResolversObject<{
  adImpressions?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postComments?: Resolver<ReadonlyArray<ResolversTypes['FacebookPageAnalyticsPostComment']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subscribers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsPostsAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsPostsAnalyticsPayload'] = ResolversParentTypes['FacebookPageAnalyticsPostsAnalyticsPayload']
> = ResolversObject<{
  averageEngagement?: Resolver<
    Maybe<ResolversTypes['FacebookPageAnalyticsPostsAverageEngagement']>,
    ParentType,
    ContextType
  >;
  fanActivity?: Resolver<
    ReadonlyArray<ResolversTypes['FacebookPagePostsAnalyticsFanActivity']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsPostsAverageEngagementResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsPostsAverageEngagement'] = ResolversParentTypes['FacebookPageAnalyticsPostsAverageEngagement']
> = ResolversObject<{
  averageComments?: Resolver<Maybe<ResolversTypes['FacebookPageStatisticsData']>, ParentType, ContextType>;
  averageImpressions?: Resolver<Maybe<ResolversTypes['FacebookPageStatisticsData']>, ParentType, ContextType>;
  averagePostsPerWeek?: Resolver<Maybe<ResolversTypes['FacebookPageStatisticsData']>, ParentType, ContextType>;
  averageReactions?: Resolver<Maybe<ResolversTypes['FacebookPageStatisticsData']>, ParentType, ContextType>;
  averageShares?: Resolver<Maybe<ResolversTypes['FacebookPageStatisticsData']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['FacebookPageStatisticsData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsPostsByDatePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsPostsByDatePayload'] = ResolversParentTypes['FacebookPageAnalyticsPostsByDatePayload']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['FacebookPageAnalyticsPostPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsPostsPayload'] = ResolversParentTypes['FacebookPageAnalyticsPostsPayload']
> = ResolversObject<{
  adImpressions?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageAnalyticsPostsPayloadsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageAnalyticsPostsPayloads'] = ResolversParentTypes['FacebookPageAnalyticsPostsPayloads']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['FacebookPageAnalyticsPostsPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageForProfileV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacebookPageForProfileV2'] = ResolversParentTypes['FacebookPageForProfileV2']
> = ResolversObject<{
  analyticsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacebookPageInfluencer'] = ResolversParentTypes['FacebookPageInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  analyticsWarning?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  influencerFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageInfluencerSearchResultV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageInfluencerSearchResultV2'] = ResolversParentTypes['FacebookPageInfluencerSearchResultV2']
> = ResolversObject<{
  facebookPages?: Resolver<ReadonlyArray<ResolversTypes['FacebookPageInfluencerV2']>, ParentType, ContextType>;
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacebookPageInfluencerV2'] = ResolversParentTypes['FacebookPageInfluencerV2']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  analyticsWarning?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  influencerEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPagePostsAnalyticsFanActivityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPagePostsAnalyticsFanActivity'] = ResolversParentTypes['FacebookPagePostsAnalyticsFanActivity']
> = ResolversObject<{
  hour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onlineCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekday?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPageStatisticsDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FacebookPageStatisticsData'] = ResolversParentTypes['FacebookPageStatisticsData']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FacebookPostForLinkBioResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacebookPostForLinkBio'] = ResolversParentTypes['FacebookPostForLinkBio']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  thumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalPostsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FanActivityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FanActivity'] = ResolversParentTypes['FanActivity']
> = ResolversObject<{
  activityType?: Resolver<ResolversTypes['FanActivityType'], ParentType, ContextType>;
  changes?: Resolver<ReadonlyArray<ResolversTypes['ActivityChange']>, ParentType, ContextType>;
  commenterId?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FanDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FanDetails'] = ResolversParentTypes['FanDetails']
> = ResolversObject<{
  contactNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  lineAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopifyAccount?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['FanTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FanFormAnswerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FanFormAnswer'] = ResolversParentTypes['FanFormAnswer']
> = ResolversObject<{
  answerOptionTitles?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  answerText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  questionId?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  questionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionType?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FanOrderDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FanOrderDetails'] = ResolversParentTypes['FanOrderDetails']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  items?: Resolver<ReadonlyArray<ResolversTypes['FanOrderItem']>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentStatus?: Resolver<ResolversTypes['ShopifyOrderPaymentStatus'], ParentType, ContextType>;
  shippingStatus?: Resolver<Maybe<ResolversTypes['ShopifyOrderShippingStatus']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FanOrderItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FanOrderItem'] = ResolversParentTypes['FanOrderItem']
> = ResolversObject<{
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  variant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FanTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FanTag'] = ResolversParentTypes['FanTag']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FileUploadUrlsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FileUploadUrls'] = ResolversParentTypes['FileUploadUrls']
> = ResolversObject<{
  fileUploadUrls?: Resolver<ReadonlyArray<ResolversTypes['SignedUrlData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FollowersAgeGenderGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersAgeGenderGroup'] = ResolversParentTypes['FollowersAgeGenderGroup']
> = ResolversObject<{
  ageGroup?: Resolver<Maybe<ReadonlyArray<ResolversTypes['FollowersAgeGroup']>>, ParentType, ContextType>;
  femaleRate?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Float']>>, ParentType, ContextType>;
  maleRate?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Float']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FollowersAgeGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersAgeGroup'] = ResolversParentTypes['FollowersAgeGroup']
> = ResolversObject<{
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FollowersByTimeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersByTime'] = ResolversParentTypes['FollowersByTime']
> = ResolversObject<{
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FollowersCountryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersCountry'] = ResolversParentTypes['FollowersCountry']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FormItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FormItem'] = ResolversParentTypes['FormItem']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  lastUpdated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['FormStatus'], ParentType, ContextType>;
  submissionCount?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FormQuestionAnswerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FormQuestionAnswer'] = ResolversParentTypes['FormQuestionAnswer']
> = ResolversObject<{
  answerOptionIds?: Resolver<ReadonlyArray<ResolversTypes['Long']>, ParentType, ContextType>;
  answerText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  questionId?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  questionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionType?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FormReportAnswerOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FormReportAnswerOption'] = ResolversParentTypes['FormReportAnswerOption']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  optionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FormReportQuestionAnswerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FormReportQuestionAnswer'] = ResolversParentTypes['FormReportQuestionAnswer']
> = ResolversObject<{
  answerOptions?: Resolver<ReadonlyArray<ResolversTypes['FormReportAnswerOption']>, ParentType, ContextType>;
  answerTexts?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  numberResponses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  questionId?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  questionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionType?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FormReportSummaryPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FormReportSummaryPayload'] = ResolversParentTypes['FormReportSummaryPayload']
> = ResolversObject<{
  answers?: Resolver<ReadonlyArray<ResolversTypes['FormReportQuestionAnswer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FormRespondentItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FormRespondentItem'] = ResolversParentTypes['FormRespondentItem']
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fanId?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  submissionId?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FormRespondentsCountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FormRespondentsCountPayload'] = ResolversParentTypes['FormRespondentsCountPayload']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FormRespondentsListPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FormRespondentsListPayload'] = ResolversParentTypes['FormRespondentsListPayload']
> = ResolversObject<{
  respondents?: Resolver<ReadonlyArray<ResolversTypes['FormRespondentItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeminiMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeminiMessage'] = ResolversParentTypes['GeminiMessage']
> = ResolversObject<{
  influencerIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  jsonResponses?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  nextAction?: Resolver<ResolversTypes['GeminiNextAction'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GenerateImportFansUploadURLPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GenerateImportFansUploadURLPayload'] = ResolversParentTypes['GenerateImportFansUploadURLPayload']
> = ResolversObject<{
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GenerateMarketplaceCampaignDetailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GenerateMarketplaceCampaignDetailPayload'] = ResolversParentTypes['GenerateMarketplaceCampaignDetailPayload']
> = ResolversObject<{
  requestId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneratePartnerInfluencerSignUpUrlResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeneratePartnerInfluencerSignUpUrl'] = ResolversParentTypes['GeneratePartnerInfluencerSignUpUrl']
> = ResolversObject<{
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GenerateTalentInfluencerSignUpUrlResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GenerateTalentInfluencerSignUpUrl'] = ResolversParentTypes['GenerateTalentInfluencerSignUpUrl']
> = ResolversObject<{
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAccountInformationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAccountInformationPayload'] = ResolversParentTypes['GetAccountInformationPayload']
> = ResolversObject<{
  brandName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categoryIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  introduce?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  regionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAllCampaignsCountForSearchJobPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllCampaignsCountForSearchJobPayload'] = ResolversParentTypes['GetAllCampaignsCountForSearchJobPayload']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAllCampaignsForSearchJobPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllCampaignsForSearchJobPayload'] = ResolversParentTypes['GetAllCampaignsForSearchJobPayload']
> = ResolversObject<{
  campaigns?: Resolver<ReadonlyArray<ResolversTypes['CampaignsForSearchJobPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAnyXAccountsByKeywordPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAnyXAccountsByKeywordPayload'] = ResolversParentTypes['GetAnyXAccountsByKeywordPayload']
> = ResolversObject<{
  accounts?: Resolver<ReadonlyArray<ResolversTypes['AnyXAccountInfoPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAutoManagedCampaignsCountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAutoManagedCampaignsCountPayload'] = ResolversParentTypes['GetAutoManagedCampaignsCountPayload']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAutoManagedCampaignsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAutoManagedCampaignsPayload'] = ResolversParentTypes['GetAutoManagedCampaignsPayload']
> = ResolversObject<{
  campaigns?: Resolver<ReadonlyArray<ResolversTypes['AutoManagedCampaignInfoPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetChatEventsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetChatEventsPayload'] = ResolversParentTypes['GetChatEventsPayload']
> = ResolversObject<{
  chatEvents?: Resolver<ReadonlyArray<ResolversTypes['ChatEvent']>, ParentType, ContextType>;
  lastCheckedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCreatorTagsByIdPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetCreatorTagsByIdPayload'] = ResolversParentTypes['GetCreatorTagsByIdPayload']
> = ResolversObject<{
  tags?: Resolver<ReadonlyArray<ResolversTypes['FanTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCreatorTagsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCreatorTagsPayload'] = ResolversParentTypes['GetCreatorTagsPayload']
> = ResolversObject<{
  tags?: Resolver<ReadonlyArray<ResolversTypes['FanTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCustomerTagsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCustomerTagsPayload'] = ResolversParentTypes['GetCustomerTagsPayload']
> = ResolversObject<{
  tags?: Resolver<ReadonlyArray<ResolversTypes['CustomerTagPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEmailListCountPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetEmailListCountPayload'] = ResolversParentTypes['GetEmailListCountPayload']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEmailListPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetEmailListPayload'] = ResolversParentTypes['GetEmailListPayload']
> = ResolversObject<{
  emails?: Resolver<ReadonlyArray<ResolversTypes['EmailInfoForList']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEmailOverviewPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetEmailOverviewPayload'] = ResolversParentTypes['GetEmailOverviewPayload']
> = ResolversObject<{
  info?: Resolver<ResolversTypes['EmailInfo'], ParentType, ContextType>;
  stats?: Resolver<ResolversTypes['EmailStatistics'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEmailRecipientFanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetEmailRecipientFan'] = ResolversParentTypes['GetEmailRecipientFan']
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEmailRecipientTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetEmailRecipientTag'] = ResolversParentTypes['GetEmailRecipientTag']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEmailRecipientsCountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetEmailRecipientsCountPayload'] = ResolversParentTypes['GetEmailRecipientsCountPayload']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEmailRecipientsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetEmailRecipientsPayload'] = ResolversParentTypes['GetEmailRecipientsPayload']
> = ResolversObject<{
  recipients?: Resolver<ReadonlyArray<ResolversTypes['RecipientDetail']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEmailTemplatePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetEmailTemplatePayload'] = ResolversParentTypes['GetEmailTemplatePayload']
> = ResolversObject<{
  fans?: Resolver<ReadonlyArray<ResolversTypes['Long']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduleDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendNow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EmailTemplateStatus'], ParentType, ContextType>;
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['Long']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFanActivitiesPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFanActivitiesPayload'] = ResolversParentTypes['GetFanActivitiesPayload']
> = ResolversObject<{
  activities?: Resolver<ReadonlyArray<ResolversTypes['FanActivity']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFanFormResponsePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetFanFormResponsePayload'] = ResolversParentTypes['GetFanFormResponsePayload']
> = ResolversObject<{
  answers?: Resolver<ReadonlyArray<ResolversTypes['FanFormAnswer']>, ParentType, ContextType>;
  formId?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  formTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFanHeaderPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFanHeaderPayload'] = ResolversParentTypes['GetFanHeaderPayload']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyAccount?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  totalOrders?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalSpent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFanOrdersPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFanOrdersPayload'] = ResolversParentTypes['GetFanOrdersPayload']
> = ResolversObject<{
  orders?: Resolver<ReadonlyArray<ResolversTypes['FanOrderDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFanParticipationFormListPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetFanParticipationFormListPayload'] = ResolversParentTypes['GetFanParticipationFormListPayload']
> = ResolversObject<{
  forms?: Resolver<ReadonlyArray<ResolversTypes['ParticipationForm']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFanPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFanPayload'] = ResolversParentTypes['GetFanPayload']
> = ResolversObject<{
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  editable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['FanGender']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFansCountPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFansCountPayload'] = ResolversParentTypes['GetFansCountPayload']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFansPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFansPayload'] = ResolversParentTypes['GetFansPayload']
> = ResolversObject<{
  fans?: Resolver<ReadonlyArray<ResolversTypes['FanDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFormInputPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFormInputPayload'] = ResolversParentTypes['GetFormInputPayload']
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  questions?: Resolver<ReadonlyArray<ResolversTypes['GetFormQuestion']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['FormStatus'], ParentType, ContextType>;
  thankDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thankTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFormQuestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFormQuestion'] = ResolversParentTypes['GetFormQuestion']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  options?: Resolver<ReadonlyArray<ResolversTypes['GetFormQuestionOption']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  questionType?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFormQuestionOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFormQuestionOption'] = ResolversParentTypes['GetFormQuestionOption']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  optionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFormResponsePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFormResponsePayload'] = ResolversParentTypes['GetFormResponsePayload']
> = ResolversObject<{
  answers?: Resolver<ReadonlyArray<ResolversTypes['FormQuestionAnswer']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submissionId?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFormTemplateOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFormTemplateOption'] = ResolversParentTypes['GetFormTemplateOption']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  optionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFormTemplatePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFormTemplatePayload'] = ResolversParentTypes['GetFormTemplatePayload']
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  questions?: Resolver<ReadonlyArray<ResolversTypes['GetFormTemplateQuestion']>, ParentType, ContextType>;
  thankDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thankTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFormTemplateQuestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFormTemplateQuestion'] = ResolversParentTypes['GetFormTemplateQuestion']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  options?: Resolver<ReadonlyArray<ResolversTypes['GetFormTemplateOption']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  questionType?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFormsCountPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFormsCountPayload'] = ResolversParentTypes['GetFormsCountPayload']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetFormsListPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetFormsListPayload'] = ResolversParentTypes['GetFormsListPayload']
> = ResolversObject<{
  forms?: Resolver<ReadonlyArray<ResolversTypes['FormItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetGeneratedMarketplaceCampaignDetailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetGeneratedMarketplaceCampaignDetailPayload'] = ResolversParentTypes['GetGeneratedMarketplaceCampaignDetailPayload']
> = ResolversObject<{
  generatedDetail?: Resolver<
    Maybe<ResolversTypes['GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['GenerateMarketplaceCampaignDetailStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetailResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail'] = ResolversParentTypes['GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail']
> = ResolversObject<{
  requirement?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceInformation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetGeneratedMarketplacePostCaptionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetGeneratedMarketplacePostCaptionPayload'] = ResolversParentTypes['GetGeneratedMarketplacePostCaptionPayload']
> = ResolversObject<{
  attemptsRemaining?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  generatedCaption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recentRequestStatus?: Resolver<ResolversTypes['GetGeneratedPostCaptionRecentRequestStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetGeneratedTikTokSpecialPostCaptionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetGeneratedTikTokSpecialPostCaptionPayload'] = ResolversParentTypes['GetGeneratedTikTokSpecialPostCaptionPayload']
> = ResolversObject<{
  attemptsRemaining?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  generatedCaption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recentRequestStatus?: Resolver<ResolversTypes['GetGeneratedPostCaptionRecentRequestStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetInfluencerBioAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetInfluencerBioAnalyticsPayload'] = ResolversParentTypes['GetInfluencerBioAnalyticsPayload']
> = ResolversObject<{
  audience?: Resolver<Maybe<ResolversTypes['InfluencerBioAnalyticsAudience']>, ParentType, ContextType>;
  clicks?: Resolver<Maybe<ResolversTypes['InfluencerBioAnalyticsOverview']>, ParentType, ContextType>;
  sessions?: Resolver<Maybe<ResolversTypes['InfluencerBioAnalyticsOverview']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['InfluencerBioAnalyticsOverview']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetInfluencerBioPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetInfluencerBioPayload'] = ResolversParentTypes['GetInfluencerBioPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  linkName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sections?: Resolver<ReadonlyArray<ResolversTypes['BioSection']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetInfluencerBioPayloadV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetInfluencerBioPayloadV2'] = ResolversParentTypes['GetInfluencerBioPayloadV2']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isFinish?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  linkName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['BioInfluencerProfile'], ParentType, ContextType>;
  sections?: Resolver<ReadonlyArray<ResolversTypes['BioSectionV2']>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['BioTheme']>, ParentType, ContextType>;
  themeV2?: Resolver<Maybe<ResolversTypes['BioThemeV2']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetInfluencerChatServicesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetInfluencerChatServicesPayload'] = ResolversParentTypes['GetInfluencerChatServicesPayload']
> = ResolversObject<{
  line?: Resolver<Maybe<ResolversTypes['GetLineServicePayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineBroadcastEstimateRecipientsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetLineBroadcastEstimateRecipientsPayload'] = ResolversParentTypes['GetLineBroadcastEstimateRecipientsPayload']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineBroadcastMessagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetLineBroadcastMessagePayload'] = ResolversParentTypes['GetLineBroadcastMessagePayload']
> = ResolversObject<{
  content?: Resolver<ReadonlyArray<ResolversTypes['LineContentItemPayload']>, ParentType, ContextType>;
  createdDateTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deliveredDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deliveryTiming?: Resolver<ResolversTypes['DeliveryTimingItemPayload'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['RecipientItemPayload'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['LineBroadcastStatus']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineBroadcastSummaryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetLineBroadcastSummaryPayload'] = ResolversParentTypes['GetLineBroadcastSummaryPayload']
> = ResolversObject<{
  clickRate?: Resolver<ResolversTypes['LineBroadcastFloatValue'], ParentType, ContextType>;
  delivered?: Resolver<ResolversTypes['LineBroadcastIntValue'], ParentType, ContextType>;
  openRate?: Resolver<ResolversTypes['LineBroadcastFloatValue'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineBroadcastsCountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetLineBroadcastsCountPayload'] = ResolversParentTypes['GetLineBroadcastsCountPayload']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineBroadcastsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetLineBroadcastsPayload'] = ResolversParentTypes['GetLineBroadcastsPayload']
> = ResolversObject<{
  broadcasts?: Resolver<ReadonlyArray<ResolversTypes['LineBroadcast']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineChannelPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetLineChannelPayload'] = ResolversParentTypes['GetLineChannelPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  lineChannelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineChannelSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineKid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publicKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publicKeyId?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineChatCountPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetLineChatCountPayload'] = ResolversParentTypes['GetLineChatCountPayload']
> = ResolversObject<{
  activatedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  assignedToMe?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineChatPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetLineChatPayload'] = ResolversParentTypes['GetLineChatPayload']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chatId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineChatsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetLineChatsPayload'] = ResolversParentTypes['GetLineChatsPayload']
> = ResolversObject<{
  chats?: Resolver<ReadonlyArray<ResolversTypes['LineChat']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineLoginDetailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetLineLoginDetailPayload'] = ResolversParentTypes['GetLineLoginDetailPayload']
> = ResolversObject<{
  liffId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineLoginChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLinePublicKeyPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetLinePublicKeyPayload'] = ResolversParentTypes['GetLinePublicKeyPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  publicKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLineServicePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetLineServicePayload'] = ResolversParentTypes['GetLineServicePayload']
> = ResolversObject<{
  liffUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLinkBioFacebookAccountPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetLinkBioFacebookAccountPostsPayload'] = ResolversParentTypes['GetLinkBioFacebookAccountPostsPayload']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['GetLinkBioFacebookPostPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLinkBioFacebookPagePostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetLinkBioFacebookPagePostsPayload'] = ResolversParentTypes['GetLinkBioFacebookPagePostsPayload']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['GetLinkBioFacebookPostPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLinkBioFacebookPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetLinkBioFacebookPostPayload'] = ResolversParentTypes['GetLinkBioFacebookPostPayload']
> = ResolversObject<{
  postSystemId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  thumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetLinkBioThemeColorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetLinkBioThemeColorPayload'] = ResolversParentTypes['GetLinkBioThemeColorPayload']
> = ResolversObject<{
  bg?: Resolver<ResolversTypes['LinkBioBackground'], ParentType, ContextType>;
  button?: Resolver<Maybe<ResolversTypes['BioButtonStyle']>, ParentType, ContextType>;
  color?: Resolver<ResolversTypes['LinkBioPageColor'], ParentType, ContextType>;
  font?: Resolver<Maybe<ResolversTypes['BioFontStyle']>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['BioTheme']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetMessageTemplatesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetMessageTemplatesPayload'] = ResolversParentTypes['GetMessageTemplatesPayload']
> = ResolversObject<{
  templates?: Resolver<ReadonlyArray<ResolversTypes['MessageTemplateItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetPublicInfluencerBioPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetPublicInfluencerBioPayload'] = ResolversParentTypes['GetPublicInfluencerBioPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['BioInfluencerProfile'], ParentType, ContextType>;
  sections?: Resolver<ReadonlyArray<ResolversTypes['PublicBioSection']>, ParentType, ContextType>;
  themeV2?: Resolver<Maybe<ResolversTypes['BioThemeV2']>, ParentType, ContextType>;
  themes?: Resolver<Maybe<ResolversTypes['PublicBioTheme']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetShopifyAccountPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetShopifyAccountPayload'] = ResolversParentTypes['GetShopifyAccountPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetTagsAndFansRecipientsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetTagsAndFansRecipientsPayload'] = ResolversParentTypes['GetTagsAndFansRecipientsPayload']
> = ResolversObject<{
  fans?: Resolver<ReadonlyArray<ResolversTypes['GetEmailRecipientFan']>, ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['GetEmailRecipientTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GoogleSlideFileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GoogleSlideFile'] = ResolversParentTypes['GoogleSlideFile']
> = ResolversObject<{
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  slideUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HashtagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Hashtag'] = ResolversParentTypes['Hashtag']
> = ResolversObject<{
  blocked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HashtagTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HashtagType'] = ResolversParentTypes['HashtagType']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HubspotCompanyDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HubspotCompanyData'] = ResolversParentTypes['HubspotCompanyData']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  internalNetsuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HubspotDealDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HubspotDealData'] = ResolversParentTypes['HubspotDealData']
> = ResolversObject<{
  companyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dealName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HubspotDealDataForSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HubspotDealDataForSearch'] = ResolversParentTypes['HubspotDealDataForSearch']
> = ResolversObject<{
  dealName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImageItemPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImageItemPayload'] = ResolversParentTypes['ImageItemPayload']
> = ResolversObject<{
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImageMessagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImageMessagePayload'] = ResolversParentTypes['ImageMessagePayload']
> = ResolversObject<{
  images?: Resolver<ReadonlyArray<ResolversTypes['ImageItemPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImagePayload'] = ResolversParentTypes['ImagePayload']
> = ResolversObject<{
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  previewUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImageVideoMessagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImageVideoMessagePayload'] = ResolversParentTypes['ImageVideoMessagePayload']
> = ResolversObject<{
  media?: Resolver<ResolversTypes['MediaItemPayload'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImportFansPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImportFansPayload'] = ResolversParentTypes['ImportFansPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Influencer'] = ResolversParentTypes['Influencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['InfluencerEngagement'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['SocialAccount']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['SocialAccount']>, ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['SocialAccount']>, ParentType, ContextType>;
  youtube?: Resolver<Maybe<ResolversTypes['SocialAccount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload'] = ResolversParentTypes['InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload']
> = ResolversObject<{
  reconnectStatuses?: Resolver<
    ReadonlyArray<ResolversTypes['InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerAnalyticsSponsoredPostCountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerAnalyticsSponsoredPostCountPayload'] = ResolversParentTypes['InfluencerAnalyticsSponsoredPostCountPayload']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerAnalyticsSponsoredPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerAnalyticsSponsoredPostPayload'] = ResolversParentTypes['InfluencerAnalyticsSponsoredPostPayload']
> = ResolversObject<{
  averageEngagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageEngagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['InfluencerYoutubeSponsoredPost']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerAnalyticsTwitterRefreshAvailabilityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerAnalyticsTwitterRefreshAvailabilityPayload'] = ResolversParentTypes['InfluencerAnalyticsTwitterRefreshAvailabilityPayload']
> = ResolversObject<{
  isRefreshAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerAnalyticsTwitterRefreshOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerAnalyticsTwitterRefreshOutput'] = ResolversParentTypes['InfluencerAnalyticsTwitterRefreshOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerAudienceSectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerAudienceSection'] = ResolversParentTypes['InfluencerAudienceSection']
> = ResolversObject<{
  ageGenderRates?: Resolver<ResolversTypes['FollowersAgeGenderGroup'], ParentType, ContextType>;
  femaleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerBioAnalyticsAudienceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerBioAnalyticsAudience'] = ResolversParentTypes['InfluencerBioAnalyticsAudience']
> = ResolversObject<{
  cities?: Resolver<ReadonlyArray<ResolversTypes['BioAnalyticsCity']>, ParentType, ContextType>;
  countries?: Resolver<ReadonlyArray<ResolversTypes['BioAnalyticsCountry']>, ParentType, ContextType>;
  otherLinkClicks?: Resolver<ReadonlyArray<ResolversTypes['BioAnalyticsOtherLinkClicks']>, ParentType, ContextType>;
  referral?: Resolver<ReadonlyArray<ResolversTypes['BioAnalyticsReferral']>, ParentType, ContextType>;
  socialMediaClicks?: Resolver<ReadonlyArray<ResolversTypes['BioAnalyticsSocialMediaClicks']>, ParentType, ContextType>;
  totalClicks?: Resolver<ReadonlyArray<ResolversTypes['BioAnalyticsTotalClicks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerBioAnalyticsHistoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerBioAnalyticsHistory'] = ResolversParentTypes['InfluencerBioAnalyticsHistory']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerBioAnalyticsOverviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerBioAnalyticsOverview'] = ResolversParentTypes['InfluencerBioAnalyticsOverview']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  history?: Resolver<ReadonlyArray<ResolversTypes['InfluencerBioAnalyticsHistory']>, ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerBrandAccountsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerBrandAccounts'] = ResolversParentTypes['InfluencerBrandAccounts']
> = ResolversObject<{
  averageEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brandAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['BrandAccount']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerCampaignDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerCampaignDetail'] = ResolversParentTypes['InfluencerCampaignDetail']
> = ResolversObject<{
  campaignInfo?: Resolver<ResolversTypes['InfluencerCampaignDetailInfo'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  joinedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['InfluencerCampaignPostData']>, ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['InfluencerCampaignStat'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerCampaignDetailByPromotionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerCampaignDetailByPromotion'] = ResolversParentTypes['InfluencerCampaignDetailByPromotion']
> = ResolversObject<{
  campaignInfo?: Resolver<ResolversTypes['InfluencerCampaignDetailInfoByPromotion'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['InfluencerCampaignStatByPromotion'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerCampaignDetailInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerCampaignDetailInfo'] = ResolversParentTypes['InfluencerCampaignDetailInfo']
> = ResolversObject<{
  hasPostTracking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mkpDetailType?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignDetailType']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AllCampaignStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerCampaignDetailInfoByPromotionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerCampaignDetailInfoByPromotion'] = ResolversParentTypes['InfluencerCampaignDetailInfoByPromotion']
> = ResolversObject<{
  hasPostTracking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mkpDetailType?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignDetailType']>, ParentType, ContextType>;
  promotionMethod?: Resolver<ResolversTypes['CampaignPromotionMethod'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerCampaignPostDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerCampaignPostData'] = ResolversParentTypes['InfluencerCampaignPostData']
> = ResolversObject<{
  postInfo?: Resolver<ResolversTypes['InfluencerCampaignPostInfo'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['InfluencerCampaignStat'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerCampaignPostInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerCampaignPostInfo'] = ResolversParentTypes['InfluencerCampaignPostInfo']
> = ResolversObject<{
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mkpCampaignType?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignType']>, ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  promotionMethods?: Resolver<ReadonlyArray<ResolversTypes['CampaignPromotionMethod']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerCampaignStatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerCampaignStat'] = ResolversParentTypes['InfluencerCampaignStat']
> = ResolversObject<{
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cvr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  like?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerCampaignStatByPromotionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerCampaignStatByPromotion'] = ResolversParentTypes['InfluencerCampaignStatByPromotion']
> = ResolversObject<{
  actions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  clicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sales?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerCampaignSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerCampaignSummary'] = ResolversParentTypes['InfluencerCampaignSummary']
> = ResolversObject<{
  autoManagedAvgCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  autoManagedJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avgComments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgReach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engagementAvgCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplaceAvgCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerEngagementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerEngagement'] = ResolversParentTypes['InfluencerEngagement']
> = ResolversObject<{
  averageEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerExportDashboardInstagramAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerExportDashboardInstagramAccount'] = ResolversParentTypes['InfluencerExportDashboardInstagramAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerFacebookPageAnalyticsAccessTokenWarningPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerFacebookPageAnalyticsAccessTokenWarningPayload'] = ResolversParentTypes['InfluencerFacebookPageAnalyticsAccessTokenWarningPayload']
> = ResolversObject<{
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerFeedPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerFeedPost'] = ResolversParentTypes['InfluencerFeedPost']
> = ResolversObject<{
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reactions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerFollowerGrowthSectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerFollowerGrowthSection'] = ResolversParentTypes['InfluencerFollowerGrowthSection']
> = ResolversObject<{
  activeFollowersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engageFansRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  followersGrowth?: Resolver<ReadonlyArray<ResolversTypes['FollowersByTime']>, ParentType, ContextType>;
  hasPost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastUpdatedDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  postsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postsGrowth?: Resolver<ReadonlyArray<ResolversTypes['PostsByTime']>, ParentType, ContextType>;
  readFansRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerHasEstimateApiResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerHasEstimateApi'] = ResolversParentTypes['InfluencerHasEstimateApi']
> = ResolversObject<{
  accountInterest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  audienceDemographics?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['ConstantHasEstimateMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerInstagramAnalyticsAccessTokenWarningPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerInstagramAnalyticsAccessTokenWarningPayload'] = ResolversParentTypes['InfluencerInstagramAnalyticsAccessTokenWarningPayload']
> = ResolversObject<{
  dataForReconnect?: Resolver<ResolversTypes['InfluencerInstagramDataForReconnectPayload'], ParentType, ContextType>;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerInstagramDataForReconnectPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerInstagramDataForReconnectPayload'] = ResolversParentTypes['InfluencerInstagramDataForReconnectPayload']
> = ResolversObject<{
  analyticsAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerInterestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerInterest'] = ResolversParentTypes['InfluencerInterest']
> = ResolversObject<{
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerManagerOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerManagerOutput'] = ResolversParentTypes['InfluencerManagerOutput']
> = ResolversObject<{
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload'] = ResolversParentTypes['InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload']
> = ResolversObject<{
  dataForReconnect?: Resolver<
    ResolversTypes['InfluencerMultipleInstagramDataForReconnectPayload'],
    ParentType,
    ContextType
  >;
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerMultipleInstagramDataForReconnectPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerMultipleInstagramDataForReconnectPayload'] = ResolversParentTypes['InfluencerMultipleInstagramDataForReconnectPayload']
> = ResolversObject<{
  analyticsAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerNewProfileDetailResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerNewProfileDetail'] = ResolversParentTypes['InfluencerNewProfileDetail']
> = ResolversObject<{
  followers?: Resolver<ResolversTypes['InfluencerNewProfileFollowers'], ParentType, ContextType>;
  lastUpdatedDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  popularPosts?: Resolver<ReadonlyArray<ResolversTypes['InfluencerPopularPost']>, ParentType, ContextType>;
  postsHabit?: Resolver<ReadonlyArray<ResolversTypes['InfluencerNewProfilePostsHabit']>, ParentType, ContextType>;
  postsStats?: Resolver<ResolversTypes['InfluencerNewProfilePostsStats'], ParentType, ContextType>;
  sentimentAnalysis?: Resolver<Maybe<ResolversTypes['InfluencerNewProfileSentimentAnalysis']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerNewProfileEngagementResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerNewProfileEngagement'] = ResolversParentTypes['InfluencerNewProfileEngagement']
> = ResolversObject<{
  averageEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerNewProfileFollowersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerNewProfileFollowers'] = ResolversParentTypes['InfluencerNewProfileFollowers']
> = ResolversObject<{
  ageGenderRates?: Resolver<Maybe<ResolversTypes['FollowersAgeGenderGroup']>, ParentType, ContextType>;
  countryRates?: Resolver<Maybe<ReadonlyArray<ResolversTypes['FollowersCountry']>>, ParentType, ContextType>;
  femaleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followersGrowth?: Resolver<Maybe<ReadonlyArray<ResolversTypes['FollowersByTime']>>, ParentType, ContextType>;
  maleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerNewProfileHeaderV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerNewProfileHeaderV2'] = ResolversParentTypes['InfluencerNewProfileHeaderV2']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryNameForInfluencer'], ParentType, ContextType>;
  cover?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultSelectionReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  douyinAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountForProfileV2']>>, ParentType, ContextType>;
  emailNewsLetters?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodEmailNewsLetterForProfile']>>,
    ParentType,
    ContextType
  >;
  engagement?: Resolver<ResolversTypes['InfluencerNewProfileEngagement'], ParentType, ContextType>;
  facebookAccount?: Resolver<Maybe<ResolversTypes['SocialAccountForProfileV2']>, ParentType, ContextType>;
  facebookPages?: Resolver<Maybe<ReadonlyArray<ResolversTypes['FacebookPageForProfileV2']>>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  hideSensitiveMetrics?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAccountForProfileV2']>>,
    ParentType,
    ContextType
  >;
  internalMemo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introduce?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaggedToCompany?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mobileApps?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodMobileAppForProfile']>>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offlines?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodOfflineForProfile']>>,
    ParentType,
    ContextType
  >;
  partnerIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Int']>>, ParentType, ContextType>;
  partnerTalentAgencies?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  podCasts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodPodCastForProfile']>>,
    ParentType,
    ContextType
  >;
  region?: Resolver<Maybe<ResolversTypes['RegionName']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  talentAgencyIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Int']>>, ParentType, ContextType>;
  threadsAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['SocialAccountForProfileV2']>>,
    ParentType,
    ContextType
  >;
  tiktokAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TiktokAccountForProfileV2']>>, ParentType, ContextType>;
  twitterAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TwitterForProfileV2']>>, ParentType, ContextType>;
  websites?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodWebsiteForProfile']>>,
    ParentType,
    ContextType
  >;
  xhsAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountForProfileV2']>>, ParentType, ContextType>;
  youtubeAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['YoutubeAccountForProfileV2']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerNewProfilePostsHabitResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerNewProfilePostsHabit'] = ResolversParentTypes['InfluencerNewProfilePostsHabit']
> = ResolversObject<{
  averageComments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageLikes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageShares?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageViews?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekday?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerNewProfilePostsStatsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerNewProfilePostsStats'] = ResolversParentTypes['InfluencerNewProfilePostsStats']
> = ResolversObject<{
  averageComments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageShares?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerNewProfileSentimentAnalysisResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerNewProfileSentimentAnalysis'] = ResolversParentTypes['InfluencerNewProfileSentimentAnalysis']
> = ResolversObject<{
  negativeRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  neutralRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positiveRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPaymentInformationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerPaymentInformation'] = ResolversParentTypes['InfluencerPaymentInformation']
> = ResolversObject<{
  accountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accountNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  branchId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  branchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cityId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swiftCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPaymentRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerPaymentRequest'] = ResolversParentTypes['InfluencerPaymentRequest']
> = ResolversObject<{
  campaigns?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignPayment']>>, ParentType, ContextType>;
  consumptionTax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  grossRevenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  netRevenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paidDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  requestedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PaymentRequestStatusForInfluencer'], ParentType, ContextType>;
  withholdingFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPaymentRequestsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerPaymentRequests'] = ResolversParentTypes['InfluencerPaymentRequests']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentRequests?: Resolver<ReadonlyArray<ResolversTypes['InfluencerPaymentRequest']>, ParentType, ContextType>;
  totalNetRevenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPopularPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerPopularPost'] = ResolversParentTypes['InfluencerPopularPost']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPostDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerPostDetail'] = ResolversParentTypes['InfluencerPostDetail']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  commentsContent?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CommentContent']>>, ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reactions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sentimentNegativeRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sentimentPositiveRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  similarPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SimilarPost']>>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['SocialPostType'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPostReportStatsEngagementResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerPostReportStatsEngagement'] = ResolversParentTypes['InfluencerPostReportStatsEngagement']
> = ResolversObject<{
  anyXStatus?: Resolver<ResolversTypes['AnyXEngagementCampaignStatus'], ParentType, ContextType>;
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  like?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postSocialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  share?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EngagementCampaignPostStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPostReportStatsMarketplaceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerPostReportStatsMarketplace'] = ResolversParentTypes['InfluencerPostReportStatsMarketplace']
> = ResolversObject<{
  anyXStatus?: Resolver<Maybe<ResolversTypes['AnyXMarketplaceCampaignStatus']>, ParentType, ContextType>;
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inappropriateDetailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inappropriateReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  like?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  missingCoupon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postSocialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  share?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceCampaignPostStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPostReportStatsTikTokSpecialResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerPostReportStatsTikTokSpecial'] = ResolversParentTypes['InfluencerPostReportStatsTikTokSpecial']
> = ResolversObject<{
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  like?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postSocialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  share?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TikTokSpecialCampaignPostStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPostSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerPostSection'] = ResolversParentTypes['InfluencerPostSection']
> = ResolversObject<{
  accountInterests?: Resolver<ReadonlyArray<ResolversTypes['InfluencerInterest']>, ParentType, ContextType>;
  averageComments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averagePostsPerWeek?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageReactions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageSaved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageShares?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementFollowersRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  estimateReach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  feedPosts?: Resolver<ReadonlyArray<ResolversTypes['InfluencerFeedPost']>, ParentType, ContextType>;
  postsHabit?: Resolver<ReadonlyArray<ResolversTypes['InfluencerNewProfilePostsHabit']>, ParentType, ContextType>;
  reelPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InfluencerReelPost']>>, ParentType, ContextType>;
  sentimentNegativeRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sentimentNeutralRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sentimentPositiveRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPostV3Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerPostV3'] = ResolversParentTypes['InfluencerPostV3']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountStatus?: Resolver<Maybe<ResolversTypes['SocialAccountStatus']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPostsV3Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerPostsV3'] = ResolversParentTypes['InfluencerPostsV3']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['InfluencerPostV3']>, ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerProfileDetailForEngagementProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerProfileDetailForEngagementProposal'] = ResolversParentTypes['InfluencerProfileDetailForEngagementProposal']
> = ResolversObject<{
  followers?: Resolver<ResolversTypes['InfluencerNewProfileFollowers'], ParentType, ContextType>;
  lastUpdatedDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  popularPosts?: Resolver<ReadonlyArray<ResolversTypes['InfluencerPopularPost']>, ParentType, ContextType>;
  postsHabit?: Resolver<ReadonlyArray<ResolversTypes['InfluencerNewProfilePostsHabit']>, ParentType, ContextType>;
  postsStats?: Resolver<ResolversTypes['InfluencerNewProfilePostsStats'], ParentType, ContextType>;
  sentimentAnalysis?: Resolver<Maybe<ResolversTypes['InfluencerNewProfileSentimentAnalysis']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerProfileForEngagementProposalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerProfileForEngagementProposal'] = ResolversParentTypes['InfluencerProfileForEngagementProposal']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryNameForInfluencer'], ParentType, ContextType>;
  cover?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultSelectionReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  douyinAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountForProfileV2']>>, ParentType, ContextType>;
  emailNewsLetters?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodEmailNewsLetterForProfile']>>,
    ParentType,
    ContextType
  >;
  engagement?: Resolver<ResolversTypes['InfluencerNewProfileEngagement'], ParentType, ContextType>;
  facebookAccount?: Resolver<Maybe<ResolversTypes['SocialAccountForProfileV2']>, ParentType, ContextType>;
  facebookPages?: Resolver<Maybe<ReadonlyArray<ResolversTypes['FacebookPageForProfileV2']>>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  hideSensitiveMetrics?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAccountForProfileV2']>>,
    ParentType,
    ContextType
  >;
  internalMemo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introduce?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaggedToCompany?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mobileApps?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodMobileAppForProfile']>>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offlines?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodOfflineForProfile']>>,
    ParentType,
    ContextType
  >;
  partnerIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Int']>>, ParentType, ContextType>;
  partnerTalentAgencies?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  podCasts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodPodCastForProfile']>>,
    ParentType,
    ContextType
  >;
  region?: Resolver<Maybe<ResolversTypes['RegionName']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  talentAgencyIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Int']>>, ParentType, ContextType>;
  threadsAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['SocialAccountForProfileV2']>>,
    ParentType,
    ContextType
  >;
  tiktokAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TiktokAccountForProfileV2']>>, ParentType, ContextType>;
  twitterAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TwitterForProfileV2']>>, ParentType, ContextType>;
  websites?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PromotionMethodWebsiteForProfile']>>,
    ParentType,
    ContextType
  >;
  xhsAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountForProfileV2']>>, ParentType, ContextType>;
  youtubeAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['YoutubeAccountForProfileV2']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerPromotionMethodsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerPromotionMethodsPayload'] = ResolversParentTypes['InfluencerPromotionMethodsPayload']
> = ResolversObject<{
  emailNewsLetters?: Resolver<
    ReadonlyArray<ResolversTypes['PromotionMethodEmailNewsLetterPayload']>,
    ParentType,
    ContextType
  >;
  mobileApps?: Resolver<ReadonlyArray<ResolversTypes['PromotionMethodMobileAppPayload']>, ParentType, ContextType>;
  offlines?: Resolver<ReadonlyArray<ResolversTypes['PromotionMethodOfflinePayload']>, ParentType, ContextType>;
  podCasts?: Resolver<ReadonlyArray<ResolversTypes['PromotionMethodPodCastPayload']>, ParentType, ContextType>;
  websites?: Resolver<ReadonlyArray<ResolversTypes['PromotionMethodWebsitePayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerRateCardDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerRateCardData'] = ResolversParentTypes['InfluencerRateCardData']
> = ResolversObject<{
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerRateCardDetailDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerRateCardDetailData'] = ResolversParentTypes['InfluencerRateCardDetailData']
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerRateCardPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerRateCardPayload'] = ResolversParentTypes['InfluencerRateCardPayload']
> = ResolversObject<{
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  douyin?: Resolver<ReadonlyArray<ResolversTypes['InfluencerRateCardDetailData']>, ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['InfluencerRateCardData']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['InfluencerRateCardData']>, ParentType, ContextType>;
  instagramStory?: Resolver<Maybe<ResolversTypes['InfluencerRateCardData']>, ParentType, ContextType>;
  threads?: Resolver<Maybe<ResolversTypes['InfluencerRateCardData']>, ParentType, ContextType>;
  tiktok?: Resolver<Maybe<ResolversTypes['InfluencerRateCardData']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['InfluencerRateCardData']>, ParentType, ContextType>;
  xhs?: Resolver<ReadonlyArray<ResolversTypes['InfluencerRateCardDetailData']>, ParentType, ContextType>;
  youtube?: Resolver<Maybe<ResolversTypes['InfluencerRateCardData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerReelPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerReelPost'] = ResolversParentTypes['InfluencerReelPost']
> = ResolversObject<{
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['SocialPostType'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerSignUpInputPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerSignUpInputPayload'] = ResolversParentTypes['InfluencerSignUpInputPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload'] = ResolversParentTypes['InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerSocialNetworkAccountOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerSocialNetworkAccountOutput'] = ResolversParentTypes['InfluencerSocialNetworkAccountOutput']
> = ResolversObject<{
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerSocialPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerSocialPost'] = ResolversParentTypes['InfluencerSocialPost']
> = ResolversObject<{
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isSponsoredPost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerSponsoredPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerSponsoredPost'] = ResolversParentTypes['InfluencerSponsoredPost']
> = ResolversObject<{
  campaignType?: Resolver<Maybe<ResolversTypes['CampaignType']>, ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  reaches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['SocialPostType'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerSponsoredPostCountResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerSponsoredPostCountResponse'] = ResolversParentTypes['InfluencerSponsoredPostCountResponse']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerSponsoredPostResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerSponsoredPostResponse'] = ResolversParentTypes['InfluencerSponsoredPostResponse']
> = ResolversObject<{
  averageEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageReach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageSaved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['InfluencerSponsoredPost']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerTwitterAnalyticsAccessTokenWarningPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerTwitterAnalyticsAccessTokenWarningPayload'] = ResolversParentTypes['InfluencerTwitterAnalyticsAccessTokenWarningPayload']
> = ResolversObject<{
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerUpdateDetailV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerUpdateDetailV2'] = ResolversParentTypes['InfluencerUpdateDetailV2']
> = ResolversObject<{
  campaignDisplay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryNameForInfluencer'], ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  defaultEngagementSelectionReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  douyinAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerSocialNetworkAccountOutput']>>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facebookAccount?: Resolver<Maybe<ResolversTypes['InfluencerSocialNetworkAccountOutput']>, ParentType, ContextType>;
  facebookPages?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerSocialNetworkAccountOutput']>>,
    ParentType,
    ContextType
  >;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerSocialNetworkAccountOutput']>>,
    ParentType,
    ContextType
  >;
  internalMemo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introduce?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isProAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  managerIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Int']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationSetting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  partners?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Partner']>>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['RegionName']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  talentAgencies?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TalentAgency']>>, ParentType, ContextType>;
  threadsAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerSocialNetworkAccountOutput']>>,
    ParentType,
    ContextType
  >;
  tiktokAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerSocialNetworkAccountOutput']>>,
    ParentType,
    ContextType
  >;
  twitterAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerSocialNetworkAccountOutput']>>,
    ParentType,
    ContextType
  >;
  xhsAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerSocialNetworkAccountOutput']>>,
    ParentType,
    ContextType
  >;
  youtubeAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerSocialNetworkAccountOutput']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerV4Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerV4'] = ResolversParentTypes['InfluencerV4']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  douyin?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['InfluencerEngagement'], ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaggedToCompany?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  line?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccounts?: Resolver<ReadonlyArray<ResolversTypes['SocialAccountDescription']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  threads?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  tiktok?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  xhs?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  youtube?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerWhitelistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerWhitelist'] = ResolversParentTypes['InfluencerWhitelist']
> = ResolversObject<{
  influencerIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  isWhitelist?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerWordCloudResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InfluencerWordCloud'] = ResolversParentTypes['InfluencerWordCloud']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hashtag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeAnalyticsAccountCardResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeAnalyticsAccountCard'] = ResolversParentTypes['InfluencerYoutubeAnalyticsAccountCard']
> = ResolversObject<{
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  elementId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeAnalyticsAccountPostsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeAnalyticsAccountPosts'] = ResolversParentTypes['InfluencerYoutubeAnalyticsAccountPosts']
> = ResolversObject<{
  postHabit?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostsPostingHabit']>, ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeCompareAccountPost']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeAnalyticsAccountPostsInDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeAnalyticsAccountPostsInDate'] = ResolversParentTypes['InfluencerYoutubeAnalyticsAccountPostsInDate']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostPayload']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeAnalyticsAccountTagRankingResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeAnalyticsAccountTagRanking'] = ResolversParentTypes['InfluencerYoutubeAnalyticsAccountTagRanking']
> = ResolversObject<{
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tagsRanking?: Resolver<ReadonlyArray<ResolversTypes['YoutubeTagRanking']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeAnalyticsChannelDetailsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeAnalyticsChannelDetailsPayload'] = ResolversParentTypes['InfluencerYoutubeAnalyticsChannelDetailsPayload']
> = ResolversObject<{
  channelAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeAnalyticsCompareAccountCardResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeAnalyticsCompareAccountCard'] = ResolversParentTypes['InfluencerYoutubeAnalyticsCompareAccountCard']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  elementId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeAnalyticsCompareAccountCardsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeAnalyticsCompareAccountCards'] = ResolversParentTypes['InfluencerYoutubeAnalyticsCompareAccountCards']
> = ResolversObject<{
  compareAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['InfluencerYoutubeAnalyticsCompareAccountCard']>,
    ParentType,
    ContextType
  >;
  mainAccount?: Resolver<Maybe<ResolversTypes['InfluencerYoutubeAnalyticsAccountCard']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeCompareAccountOverviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeCompareAccountOverview'] = ResolversParentTypes['InfluencerYoutubeCompareAccountOverview']
> = ResolversObject<{
  graph?: Resolver<ResolversTypes['YoutubeCompareOverviewHistory'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['YoutubeCompareOverviewSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeCompareAllPostsInDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeCompareAllPostsInDate'] = ResolversParentTypes['InfluencerYoutubeCompareAllPostsInDate']
> = ResolversObject<{
  compareAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeCompareAccountPostsInDate']>,
    ParentType,
    ContextType
  >;
  mainAccount?: Resolver<ResolversTypes['InfluencerYoutubeAnalyticsAccountPostsInDate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeCompareOverviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeCompareOverview'] = ResolversParentTypes['InfluencerYoutubeCompareOverview']
> = ResolversObject<{
  compareAccounts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeCompareAccountOverview']>, ParentType, ContextType>;
  mainAccount?: Resolver<ResolversTypes['InfluencerYoutubeCompareAccountOverview'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeComparePostsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeComparePosts'] = ResolversParentTypes['InfluencerYoutubeComparePosts']
> = ResolversObject<{
  compareAccounts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeCompareAccountPosts']>, ParentType, ContextType>;
  mainAccount?: Resolver<ResolversTypes['InfluencerYoutubeAnalyticsAccountPosts'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeCompareTagRankingResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeCompareTagRanking'] = ResolversParentTypes['InfluencerYoutubeCompareTagRanking']
> = ResolversObject<{
  compareAccounts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeCompareAccountTagRanking']>, ParentType, ContextType>;
  mainAccount?: Resolver<ResolversTypes['InfluencerYoutubeAnalyticsAccountTagRanking'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InfluencerYoutubeSponsoredPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InfluencerYoutubeSponsoredPost'] = ResolversParentTypes['InfluencerYoutubeSponsoredPost']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InquiryEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InquiryEvent'] = ResolversParentTypes['InquiryEvent']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inquiry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InquiryFinishEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InquiryFinishEvent'] = ResolversParentTypes['InquiryFinishEvent']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAccountForProfileV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAccountForProfileV2'] = ResolversParentTypes['InstagramAccountForProfileV2']
> = ResolversObject<{
  analyticsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isBusiness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsAccountInfoHeaderResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsAccountInfoHeader'] = ResolversParentTypes['InstagramAnalyticsAccountInfoHeader']
> = ResolversObject<{
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountUsername?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsCommentsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsComments'] = ResolversParentTypes['InstagramAnalyticsComments']
> = ResolversObject<{
  commentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsCompareAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsCompareAccount'] = ResolversParentTypes['InstagramAnalyticsCompareAccount']
> = ResolversObject<{
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blocked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  elementId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsCompareAccountsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsCompareAccounts'] = ResolversParentTypes['InstagramAnalyticsCompareAccounts']
> = ResolversObject<{
  accounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsCompareAccount']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsComparePostsInDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsComparePostsInDate'] = ResolversParentTypes['InstagramAnalyticsComparePostsInDate']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  feedPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramComparePostDetails']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsGetCommentItemsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsGetCommentItemsPayload'] = ResolversParentTypes['InstagramAnalyticsGetCommentItemsPayload']
> = ResolversObject<{
  commentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsGetCommentsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsGetCommentsPayload'] = ResolversParentTypes['InstagramAnalyticsGetCommentsPayload']
> = ResolversObject<{
  comments?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramAnalyticsGetCommentItemsPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsHashtagResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsHashtag'] = ResolversParentTypes['InstagramAnalyticsHashtag']
> = ResolversObject<{
  blocked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hashTag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hashTagUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsHashtagsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsHashtags'] = ResolversParentTypes['InstagramAnalyticsHashtags']
> = ResolversObject<{
  hashTags?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsHashtag']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsHistoryDataPointResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsHistoryDataPoint'] = ResolversParentTypes['InstagramAnalyticsHistoryDataPoint']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsHistoryEngagementRateDataPointResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsHistoryEngagementRateDataPoint'] = ResolversParentTypes['InstagramAnalyticsHistoryEngagementRateDataPoint']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsOverviewDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsOverviewData'] = ResolversParentTypes['InstagramAnalyticsOverviewData']
> = ResolversObject<{
  growth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  history?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>>,
    ParentType,
    ContextType
  >;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsOverviewEngagementRateDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsOverviewEngagementRateData'] = ResolversParentTypes['InstagramAnalyticsOverviewEngagementRateData']
> = ResolversObject<{
  growth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  history?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryEngagementRateDataPoint']>>,
    ParentType,
    ContextType
  >;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsPostAnalysisDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsPostAnalysisDetails'] = ResolversParentTypes['InstagramAnalyticsPostAnalysisDetails']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reach?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  saves?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsPostCommentDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsPostCommentDetails'] = ResolversParentTypes['InstagramAnalyticsPostCommentDetails']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsPostDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsPostDetails'] = ResolversParentTypes['InstagramAnalyticsPostDetails']
> = ResolversObject<{
  analysis?: Resolver<Maybe<ResolversTypes['InstagramAnalyticsPostAnalysisDetails']>, ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commentList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsPostCommentDetails']>>,
    ParentType,
    ContextType
  >;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mediaUrls?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsPostSentimentDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsPostSentimentDetails'] = ResolversParentTypes['InstagramAnalyticsPostSentimentDetails']
> = ResolversObject<{
  negativeRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  positiveRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsPostsInDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsPostsInDate'] = ResolversParentTypes['InstagramAnalyticsPostsInDate']
> = ResolversObject<{
  feedPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsPostDetails']>>, ParentType, ContextType>;
  reelPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsReelDetails']>>, ParentType, ContextType>;
  storyPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsStoryDetails']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsReelAnalysisDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsReelAnalysisDetails'] = ResolversParentTypes['InstagramAnalyticsReelAnalysisDetails']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reach?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  saves?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsReelCommentDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsReelCommentDetails'] = ResolversParentTypes['InstagramAnalyticsReelCommentDetails']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsReelDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsReelDetails'] = ResolversParentTypes['InstagramAnalyticsReelDetails']
> = ResolversObject<{
  analysis?: Resolver<Maybe<ResolversTypes['InstagramAnalyticsReelAnalysisDetails']>, ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commentList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsReelCommentDetails']>>,
    ParentType,
    ContextType
  >;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsStoryDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsStoryDetails'] = ResolversParentTypes['InstagramAnalyticsStoryDetails']
> = ResolversObject<{
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reach?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  storyVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swipeForward?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tapsBack?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tapsForward?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramAnalyticsSummaryPostsInDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramAnalyticsSummaryPostsInDate'] = ResolversParentTypes['InstagramAnalyticsSummaryPostsInDate']
> = ResolversObject<{
  compareAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramAnalyticsComparePostsInDate']>,
    ParentType,
    ContextType
  >;
  mainAccount?: Resolver<ResolversTypes['InstagramAnalyticsPostsInDate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramBrandAmbassadorPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramBrandAmbassadorPost'] = ResolversParentTypes['InstagramBrandAmbassadorPost']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramBrandAmbassadorPostDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramBrandAmbassadorPostDetails'] = ResolversParentTypes['InstagramBrandAmbassadorPostDetails']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramBrandAmbassadorPostsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramBrandAmbassadorPosts'] = ResolversParentTypes['InstagramBrandAmbassadorPosts']
> = ResolversObject<{
  ambassadorPosts?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramBrandAmbassadorPostsByUsername']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramBrandAmbassadorPostsByUsernameResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramBrandAmbassadorPostsByUsername'] = ResolversParentTypes['InstagramBrandAmbassadorPostsByUsername']
> = ResolversObject<{
  brandMentionedPosts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  igUsername?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['InstagramBrandAmbassadorPost']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCommentAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCommentAnalytics'] = ResolversParentTypes['InstagramCommentAnalytics']
> = ResolversObject<{
  negativeRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  neutralRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positiveRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramCompareAccount'] = ResolversParentTypes['InstagramCompareAccount']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blocked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareAccountPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareAccountPost'] = ResolversParentTypes['InstagramCompareAccountPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareAccountsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareAccountsPayload'] = ResolversParentTypes['InstagramCompareAccountsPayload']
> = ResolversObject<{
  compareAccounts?: Resolver<ReadonlyArray<ResolversTypes['InstagramCompareAccount']>, ParentType, ContextType>;
  mainAccount?: Resolver<ResolversTypes['InstagramCompareAccount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareFeedPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramCompareFeedPost'] = ResolversParentTypes['InstagramCompareFeedPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareFollowersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareFollowers'] = ResolversParentTypes['InstagramCompareFollowers']
> = ResolversObject<{
  compareAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramCompareFollowersSecondaryAccount']>,
    ParentType,
    ContextType
  >;
  mainAccount?: Resolver<ResolversTypes['InstagramCompareFollowersPrimaryAccount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareFollowersPrimaryAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareFollowersPrimaryAccount'] = ResolversParentTypes['InstagramCompareFollowersPrimaryAccount']
> = ResolversObject<{
  followerAgeRate?: Resolver<ResolversTypes['InstagramFollowersAgeRate'], ParentType, ContextType>;
  followerGenderRate?: Resolver<ResolversTypes['InstagramFollowerGenderRate'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareFollowersSecondaryAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareFollowersSecondaryAccount'] = ResolversParentTypes['InstagramCompareFollowersSecondaryAccount']
> = ResolversObject<{
  followerAgeRate?: Resolver<ResolversTypes['InstagramFollowersAgeRate'], ParentType, ContextType>;
  followerGenderRate?: Resolver<ResolversTypes['InstagramFollowerGenderRate'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareOverviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramCompareOverview'] = ResolversParentTypes['InstagramCompareOverview']
> = ResolversObject<{
  compareAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramCompareSecondaryAccount']>,
    ParentType,
    ContextType
  >;
  mainAccount?: Resolver<ResolversTypes['InstagramComparePrimaryAccount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareOverviewHistoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareOverviewHistory'] = ResolversParentTypes['InstagramCompareOverviewHistory']
> = ResolversObject<{
  comments?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  engagement?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  followers?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  likes?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareOverviewSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareOverviewSummary'] = ResolversParentTypes['InstagramCompareOverviewSummary']
> = ResolversObject<{
  averageComments?: Resolver<ResolversTypes['InstagramCompareOverviewSummaryAverageData'], ParentType, ContextType>;
  averageLikes?: Resolver<ResolversTypes['InstagramCompareOverviewSummaryAverageData'], ParentType, ContextType>;
  maximumLikes?: Resolver<ResolversTypes['InstagramCompareOverviewSummaryData'], ParentType, ContextType>;
  totalFollowers?: Resolver<ResolversTypes['InstagramCompareOverviewSummaryData'], ParentType, ContextType>;
  totalPosts?: Resolver<ResolversTypes['InstagramCompareOverviewSummaryData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareOverviewSummaryAverageDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareOverviewSummaryAverageData'] = ResolversParentTypes['InstagramCompareOverviewSummaryAverageData']
> = ResolversObject<{
  growth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareOverviewSummaryDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareOverviewSummaryData'] = ResolversParentTypes['InstagramCompareOverviewSummaryData']
> = ResolversObject<{
  growth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramComparePostAnalysisDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramComparePostAnalysisDetails'] = ResolversParentTypes['InstagramComparePostAnalysisDetails']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramComparePostDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramComparePostDetails'] = ResolversParentTypes['InstagramComparePostDetails']
> = ResolversObject<{
  analysis?: Resolver<Maybe<ResolversTypes['InstagramComparePostAnalysisDetails']>, ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commentList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsPostCommentDetails']>>,
    ParentType,
    ContextType
  >;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramComparePostsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramComparePosts'] = ResolversParentTypes['InstagramComparePosts']
> = ResolversObject<{
  compareAccounts?: Resolver<ReadonlyArray<ResolversTypes['InstagramComparePostsData']>, ParentType, ContextType>;
  mainAccount?: Resolver<ResolversTypes['InstagramComparePostsData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramComparePostsDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramComparePostsData'] = ResolversParentTypes['InstagramComparePostsData']
> = ResolversObject<{
  feedPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramCompareAccountPost']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  popularHashtags?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramPopularHashtags']>>, ParentType, ContextType>;
  postsHabit?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramPostsHabit']>>, ParentType, ContextType>;
  sentiment?: Resolver<Maybe<ResolversTypes['InstagramCommentAnalytics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramComparePrimaryAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramComparePrimaryAccount'] = ResolversParentTypes['InstagramComparePrimaryAccount']
> = ResolversObject<{
  graph?: Resolver<ResolversTypes['InstagramCompareOverviewHistory'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['InstagramCompareOverviewSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramCompareSecondaryAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramCompareSecondaryAccount'] = ResolversParentTypes['InstagramCompareSecondaryAccount']
> = ResolversObject<{
  graph?: Resolver<ResolversTypes['InstagramCompareOverviewHistory'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['InstagramCompareOverviewSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramDashboardFollowerAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramDashboardFollowerAnalytics'] = ResolversParentTypes['InstagramDashboardFollowerAnalytics']
> = ResolversObject<{
  followerAgeRate?: Resolver<ResolversTypes['InstagramFollowersAgeRate'], ParentType, ContextType>;
  followerCountryRate?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramFollowersCountryRate']>>,
    ParentType,
    ContextType
  >;
  followerGenderRate?: Resolver<ResolversTypes['InstagramFollowerGenderRate'], ParentType, ContextType>;
  followerRegionRate?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramFollowersRegionRate']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramDashboardOverviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramDashboardOverview'] = ResolversParentTypes['InstagramDashboardOverview']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['InstagramAnalyticsOverviewEngagementRateData'], ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  impressions?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  lastUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  posts?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  profileViews?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  reach?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  saved?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  websiteClicks?: Resolver<ResolversTypes['InstagramAnalyticsOverviewData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramDashboardPostAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramDashboardPostAnalytics'] = ResolversParentTypes['InstagramDashboardPostAnalytics']
> = ResolversObject<{
  averageEngagement?: Resolver<Maybe<ResolversTypes['InstagramPostAverageEngagement']>, ParentType, ContextType>;
  commentAnalytics?: Resolver<Maybe<ResolversTypes['InstagramCommentAnalytics']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsComments']>>, ParentType, ContextType>;
  feedPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramFeedPost']>>, ParentType, ContextType>;
  postsHabit?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramPostsHabit']>>, ParentType, ContextType>;
  reelsPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramReelPost']>>, ParentType, ContextType>;
  storyPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramStoryPost']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramDataForReconnectPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramDataForReconnectPayload'] = ResolversParentTypes['InstagramDataForReconnectPayload']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  businessAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbPageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramFeedPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramFeedPost'] = ResolversParentTypes['InstagramFeedPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  follow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressionFromDiscovery?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionFromHashtag?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productButtonClick?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productPageView?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramFollowerGenderRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramFollowerGenderRate'] = ResolversParentTypes['InstagramFollowerGenderRate']
> = ResolversObject<{
  femaleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  otherRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramFollowersAgeGroupResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramFollowersAgeGroup'] = ResolversParentTypes['InstagramFollowersAgeGroup']
> = ResolversObject<{
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramFollowersAgeRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramFollowersAgeRate'] = ResolversParentTypes['InstagramFollowersAgeRate']
> = ResolversObject<{
  ageGroup?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramFollowersAgeGroup']>>, ParentType, ContextType>;
  femaleRate?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Float']>>, ParentType, ContextType>;
  maleRate?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Float']>>, ParentType, ContextType>;
  otherRate?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Float']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramFollowersCountryRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramFollowersCountryRate'] = ResolversParentTypes['InstagramFollowersCountryRate']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramFollowersRegionRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramFollowersRegionRate'] = ResolversParentTypes['InstagramFollowersRegionRate']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagFeedPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramHashtagFeedPost'] = ResolversParentTypes['InstagramHashtagFeedPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagFeedPostV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagFeedPostV2'] = ResolversParentTypes['InstagramHashtagFeedPostV2']
> = ResolversObject<{
  accountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  images?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  influencerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagFeedPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagFeedPostsPayload'] = ResolversParentTypes['InstagramHashtagFeedPostsPayload']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['InstagramHashtagFeedPostV2']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagPostAnalysisDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagPostAnalysisDetails'] = ResolversParentTypes['InstagramHashtagPostAnalysisDetails']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagPostDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagPostDetails'] = ResolversParentTypes['InstagramHashtagPostDetails']
> = ResolversObject<{
  analysis?: Resolver<Maybe<ResolversTypes['InstagramHashtagPostAnalysisDetails']>, ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commentList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsPostCommentDetails']>>,
    ParentType,
    ContextType
  >;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  images?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  instagramAccountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagPostsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramHashtagPosts'] = ResolversParentTypes['InstagramHashtagPosts']
> = ResolversObject<{
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['InstagramHashtagPostsData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagPostsDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagPostsData'] = ResolversParentTypes['InstagramHashtagPostsData']
> = ResolversObject<{
  feedPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['InstagramHashtagFeedPost']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagPostsPayload'] = ResolversParentTypes['InstagramHashtagPostsPayload']
> = ResolversObject<{
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['Hashtag']>, ParentType, ContextType>;
  postsByHashtag?: Resolver<ReadonlyArray<ResolversTypes['PostByHashtag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagsMetricsDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagsMetricsData'] = ResolversParentTypes['InstagramHashtagsMetricsData']
> = ResolversObject<{
  comments?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagsOverviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagsOverview'] = ResolversParentTypes['InstagramHashtagsOverview']
> = ResolversObject<{
  graph?: Resolver<ResolversTypes['InstagramHashtagsOverviewHistory'], ParentType, ContextType>;
  summaries?: Resolver<ReadonlyArray<ResolversTypes['InstagramHashtagsOverviewSummary']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagsOverviewHistoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagsOverviewHistory'] = ResolversParentTypes['InstagramHashtagsOverviewHistory']
> = ResolversObject<{
  followers?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['InstagramHashtagsMetricsData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagsOverviewSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagsOverviewSummary'] = ResolversParentTypes['InstagramHashtagsOverviewSummary']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['InstagramHashtagsOverviewSummaryData'], ParentType, ContextType>;
  hashtag?: Resolver<ResolversTypes['InstagramAnalyticsHashtag'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['InstagramHashtagsOverviewSummaryData'], ParentType, ContextType>;
  posts?: Resolver<ResolversTypes['InstagramHashtagsOverviewSummaryData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramHashtagsOverviewSummaryDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramHashtagsOverviewSummaryData'] = ResolversParentTypes['InstagramHashtagsOverviewSummaryData']
> = ResolversObject<{
  growth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInfluencerSearchResultV3Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInfluencerSearchResultV3'] = ResolversParentTypes['InstagramInfluencerSearchResultV3']
> = ResolversObject<{
  instagramAccounts?: Resolver<ReadonlyArray<ResolversTypes['InstagramInfluencerV3']>, ParentType, ContextType>;
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInfluencerV3Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramInfluencerV3'] = ResolversParentTypes['InstagramInfluencerV3']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  analyticsWarning?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageReach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionCommonPostDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionCommonPostData'] = ResolversParentTypes['InstagramInteractionCommonPostData']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mediaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionCommonTaggedPostDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionCommonTaggedPostData'] = ResolversParentTypes['InstagramInteractionCommonTaggedPostData']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionOverviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionOverview'] = ResolversParentTypes['InstagramInteractionOverview']
> = ResolversObject<{
  graph?: Resolver<ResolversTypes['InstagramInteractionOverviewHistory'], ParentType, ContextType>;
  summaries?: Resolver<ResolversTypes['InstagramInteractionOverviewSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionOverviewHistoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionOverviewHistory'] = ResolversParentTypes['InstagramInteractionOverviewHistory']
> = ResolversObject<{
  followers?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  mentionedComments?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>,
    ParentType,
    ContextType
  >;
  mentionedLikes?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>,
    ParentType,
    ContextType
  >;
  mentionedPosts?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>,
    ParentType,
    ContextType
  >;
  taggedComments?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>,
    ParentType,
    ContextType
  >;
  taggedLikes?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  taggedPosts?: Resolver<ReadonlyArray<ResolversTypes['InstagramAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionOverviewSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionOverviewSummary'] = ResolversParentTypes['InstagramInteractionOverviewSummary']
> = ResolversObject<{
  mentionComments?: Resolver<ResolversTypes['InstagramInteractionOverviewSummaryData'], ParentType, ContextType>;
  mentionLikes?: Resolver<ResolversTypes['InstagramInteractionOverviewSummaryData'], ParentType, ContextType>;
  mentionPosts?: Resolver<ResolversTypes['InstagramInteractionOverviewSummaryData'], ParentType, ContextType>;
  taggedComments?: Resolver<ResolversTypes['InstagramInteractionOverviewSummaryData'], ParentType, ContextType>;
  taggedLikes?: Resolver<ResolversTypes['InstagramInteractionOverviewSummaryData'], ParentType, ContextType>;
  taggedPosts?: Resolver<ResolversTypes['InstagramInteractionOverviewSummaryData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionOverviewSummaryDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionOverviewSummaryData'] = ResolversParentTypes['InstagramInteractionOverviewSummaryData']
> = ResolversObject<{
  growth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramInteractionPost'] = ResolversParentTypes['InstagramInteractionPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mediaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionPostDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionPostDetails'] = ResolversParentTypes['InstagramInteractionPostDetails']
> = ResolversObject<{
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commentList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramAnalyticsPostCommentDetails']>>,
    ParentType,
    ContextType
  >;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramAccountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionPostsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionPosts'] = ResolversParentTypes['InstagramInteractionPosts']
> = ResolversObject<{
  mentionedPosts?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramInteractionCommonPostData']>,
    ParentType,
    ContextType
  >;
  taggedPosts?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramInteractionCommonTaggedPostData']>,
    ParentType,
    ContextType
  >;
  userAnalytics?: Resolver<ReadonlyArray<ResolversTypes['InstagramInteractionUserAnalytics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionTaggedPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionTaggedPost'] = ResolversParentTypes['InstagramInteractionTaggedPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionUserAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionUserAnalytics'] = ResolversParentTypes['InstagramInteractionUserAnalytics']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionUserAnalyticsDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionUserAnalyticsDetails'] = ResolversParentTypes['InstagramInteractionUserAnalyticsDetails']
> = ResolversObject<{
  avgMentionedComments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  avgMentionedLikes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  avgTaggedComments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  avgTaggedLikes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  avgTotalComments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  avgTotalLikes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mentionedPosts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taggedPosts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPostsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramInteractionUserAnalyticsDetailsAndCountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramInteractionUserAnalyticsDetailsAndCount'] = ResolversParentTypes['InstagramInteractionUserAnalyticsDetailsAndCount']
> = ResolversObject<{
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  usernames?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramInteractionUserAnalyticsDetails']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramPopularHashtagsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramPopularHashtags'] = ResolversParentTypes['InstagramPopularHashtags']
> = ResolversObject<{
  averageComments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hashtag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usedPosts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramPostAverageEngagementResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramPostAverageEngagement'] = ResolversParentTypes['InstagramPostAverageEngagement']
> = ResolversObject<{
  averageComments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageCommentsGrowth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageLikesGrowth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averagePostsGrowth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averagePostsPerWeek?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageSaved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageSavedGrowth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramPostsHabitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramPostsHabit'] = ResolversParentTypes['InstagramPostsHabit']
> = ResolversObject<{
  averageComments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageLikes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekday?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramReelPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramReelPost'] = ResolversParentTypes['InstagramReelPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramReelPostItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramReelPostItem'] = ResolversParentTypes['InstagramReelPostItem']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramStoryPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramStoryPost'] = ResolversParentTypes['InstagramStoryPost']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  follow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  linkClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productButtonClick?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productPageView?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stickerTaps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  swipeForward?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tapsBack?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tapsForward?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUGCAddedHashtagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramUGCAddedHashtagPayload'] = ResolversParentTypes['InstagramUGCAddedHashtagPayload']
> = ResolversObject<{
  hashtag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hashtagSystemId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUGCManagementBasicInfoPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramUGCManagementBasicInfoPayload'] = ResolversParentTypes['InstagramUGCManagementBasicInfoPayload']
> = ResolversObject<{
  addedHashtags?: Resolver<ReadonlyArray<ResolversTypes['InstagramUGCAddedHashtagPayload']>, ParentType, ContextType>;
  brandInstagramUserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUGCManagementListPostsCountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramUGCManagementListPostsCountPayload'] = ResolversParentTypes['InstagramUGCManagementListPostsCountPayload']
> = ResolversObject<{
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUGCManagementListPostsItemPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramUGCManagementListPostsItemPayload'] = ResolversParentTypes['InstagramUGCManagementListPostsItemPayload']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postSnsId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UGCPostStatus'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUGCManagementListPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramUGCManagementListPostsPayload'] = ResolversParentTypes['InstagramUGCManagementListPostsPayload']
> = ResolversObject<{
  posts?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramUGCManagementListPostsItemPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUGCManagementPostDetailsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramUGCManagementPostDetailsPayload'] = ResolversParentTypes['InstagramUGCManagementPostDetailsPayload']
> = ResolversObject<{
  commentingAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mediaURLs?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postSNSId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  showStaffRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  staffComment?: Resolver<Maybe<ResolversTypes['StaffComment']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UGCPostStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUserHashtagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstagramUserHashtag'] = ResolversParentTypes['InstagramUserHashtag']
> = ResolversObject<{
  hashtag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hashtagId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUserHashtagListPostsItemPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramUserHashtagListPostsItemPayload'] = ResolversParentTypes['InstagramUserHashtagListPostsItemPayload']
> = ResolversObject<{
  accountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  media?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUserHashtagListPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramUserHashtagListPostsPayload'] = ResolversParentTypes['InstagramUserHashtagListPostsPayload']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['InstagramUserHashtagListPostsItemPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstagramUserHashtagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InstagramUserHashtagPayload'] = ResolversParentTypes['InstagramUserHashtagPayload']
> = ResolversObject<{
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['InstagramUserHashtag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IsInfluencerUUUMResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IsInfluencerUUUM'] = ResolversParentTypes['IsInfluencerUUUM']
> = ResolversObject<{
  isUuum?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JSONScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type JoinAutoManagedCampaignPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinAutoManagedCampaignPayload'] = ResolversParentTypes['JoinAutoManagedCampaignPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinMarketplaceByStaffResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinMarketplaceByStaff'] = ResolversParentTypes['JoinMarketplaceByStaff']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinMarketplaceV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinMarketplaceV2'] = ResolversParentTypes['JoinMarketplaceV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinTiktokSpecialCampaignResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinTiktokSpecialCampaign'] = ResolversParentTypes['JoinTiktokSpecialCampaign']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinableAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinableAccount'] = ResolversParentTypes['JoinableAccount']
> = ResolversObject<{
  activeToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinableFbAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinableFbAccount'] = ResolversParentTypes['JoinableFbAccount']
> = ResolversObject<{
  activeToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinableInstagramAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinableInstagramAccount'] = ResolversParentTypes['JoinableInstagramAccount']
> = ResolversObject<{
  activeToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isBusinessAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinablePromotionMethodEmailNewsLetterPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinablePromotionMethodEmailNewsLetterPayload'] = ResolversParentTypes['JoinablePromotionMethodEmailNewsLetterPayload']
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinablePromotionMethodMobileAppPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinablePromotionMethodMobileAppPayload'] = ResolversParentTypes['JoinablePromotionMethodMobileAppPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mobileAppType?: Resolver<ResolversTypes['PromotionMobileAppType'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinablePromotionMethodOfflinePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinablePromotionMethodOfflinePayload'] = ResolversParentTypes['JoinablePromotionMethodOfflinePayload']
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinablePromotionMethodPodCastPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinablePromotionMethodPodCastPayload'] = ResolversParentTypes['JoinablePromotionMethodPodCastPayload']
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinablePromotionMethodSocialAccountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinablePromotionMethodSocialAccountPayload'] = ResolversParentTypes['JoinablePromotionMethodSocialAccountPayload']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinablePromotionMethodWebsitePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinablePromotionMethodWebsitePayload'] = ResolversParentTypes['JoinablePromotionMethodWebsitePayload']
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinedCampaignResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinedCampaign'] = ResolversParentTypes['JoinedCampaign']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountType?: Resolver<Maybe<ResolversTypes['SocialAccountType']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinedPromotionMethodEmailNewsLetterPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinedPromotionMethodEmailNewsLetterPayload'] = ResolversParentTypes['JoinedPromotionMethodEmailNewsLetterPayload']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinedPromotionMethodMobileAppPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinedPromotionMethodMobileAppPayload'] = ResolversParentTypes['JoinedPromotionMethodMobileAppPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mobileAppType?: Resolver<Maybe<ResolversTypes['PromotionMobileAppType']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinedPromotionMethodOfflinePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinedPromotionMethodOfflinePayload'] = ResolversParentTypes['JoinedPromotionMethodOfflinePayload']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinedPromotionMethodPodCastPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinedPromotionMethodPodCastPayload'] = ResolversParentTypes['JoinedPromotionMethodPodCastPayload']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinedPromotionMethodSocialAccountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinedPromotionMethodSocialAccountPayload'] = ResolversParentTypes['JoinedPromotionMethodSocialAccountPayload']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JoinedPromotionMethodWebsitePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinedPromotionMethodWebsitePayload'] = ResolversParentTypes['JoinedPromotionMethodWebsitePayload']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LineBroadcastResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineBroadcast'] = ResolversParentTypes['LineBroadcast']
> = ResolversObject<{
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  clickRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdDateTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  delivered?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deliveredDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  open?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  openRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['LineBroadcastStatus'], ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LineBroadcastFloatValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineBroadcastFloatValue'] = ResolversParentTypes['LineBroadcastFloatValue']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  growth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LineBroadcastIntValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineBroadcastIntValue'] = ResolversParentTypes['LineBroadcastIntValue']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  growth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LineChatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineChat'] = ResolversParentTypes['LineChat']
> = ResolversObject<{
  chatId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  checkedAt?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  fanId?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  lastMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastMessageAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  pic?: Resolver<Maybe<ResolversTypes['LineChatPIC']>, ParentType, ContextType>;
  resolved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  userAvatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LineChatPICResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineChatPIC'] = ResolversParentTypes['LineChatPIC']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LineContentItemPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineContentItemPayload'] = ResolversParentTypes['LineContentItemPayload']
> = ResolversObject<{
  buttonMessage?: Resolver<Maybe<ResolversTypes['ButtonMessagePayload']>, ParentType, ContextType>;
  imageMessage?: Resolver<Maybe<ResolversTypes['ImageMessagePayload']>, ParentType, ContextType>;
  mediaMessage?: Resolver<Maybe<ResolversTypes['ImageVideoMessagePayload']>, ParentType, ContextType>;
  plainTextMessage?: Resolver<Maybe<ResolversTypes['PlainTextMessagePayload']>, ParentType, ContextType>;
  richMessage?: Resolver<Maybe<ResolversTypes['RichMessagePayload']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['LineMessageType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LinkBioBackgroundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkBioBackground'] = ResolversParentTypes['LinkBioBackground']
> = ResolversObject<{
  bgColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LinkBioPageColorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkBioPageColor'] = ResolversParentTypes['LinkBioPageColor']
> = ResolversObject<{
  buttonColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buttonFontColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fontColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LinkCmsChannelAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkCmsChannelAssets'] = ResolversParentTypes['LinkCmsChannelAssets']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LogoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Logo'] = ResolversParentTypes['Logo']
> = ResolversObject<{
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export type MadeMarketplaceCampaignPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MadeMarketplaceCampaignPost'] = ResolversParentTypes['MadeMarketplaceCampaignPost']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MajorCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MajorCategory'] = ResolversParentTypes['MajorCategory']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minorCategories?: Resolver<ReadonlyArray<ResolversTypes['MinorCategory']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarkReadNotificationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarkReadNotificationPayload'] = ResolversParentTypes['MarkReadNotificationPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Marketplace'] = ResolversParentTypes['Marketplace']
> = ResolversObject<{
  advertiser?: Resolver<ResolversTypes['AdvertiserName'], ParentType, ContextType>;
  agencyMarginRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  budget?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  campaignType?: Resolver<ResolversTypes['MarketplaceCampaignDetailType'], ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerAction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerClick?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerPost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerSaleReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerSaleTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentCost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerManagementPics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PicName']>>, ParentType, ContextType>;
  isPostTracking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSelectionCampaign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  marginRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  salesPics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PicName']>>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceCampaignStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceAffiliateCommissionInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceAffiliateCommissionInfluencer'] = ResolversParentTypes['MarketplaceAffiliateCommissionInfluencer']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endCoverageDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  fixedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['MarketplacePostInfluencer'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  startCoverageDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceAffiliateCommissionStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceAffiliateJoinedInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceAffiliateJoinedInfluencer'] = ResolversParentTypes['MarketplaceAffiliateJoinedInfluencer']
> = ResolversObject<{
  costEditable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  couponCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['MarketplacePostInfluencer'], ParentType, ContextType>;
  joinedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  promotionMethods?: Resolver<ReadonlyArray<ResolversTypes['CampaignPromotionMethod']>, ParentType, ContextType>;
  revenueItems?: Resolver<ReadonlyArray<ResolversTypes['MarketplaceRevenueItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceAffiliateReportByDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceAffiliateReportByDate'] = ResolversParentTypes['MarketplaceAffiliateReportByDate']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cvr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  grossProfit?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  influencerCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceAffiliateReportByInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceAffiliateReportByInfluencer'] = ResolversParentTypes['MarketplaceAffiliateReportByInfluencer']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  commissionRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cvr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  influencerCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  influencerInfo?: Resolver<ResolversTypes['CampaignInfluencerInfo'], ParentType, ContextType>;
  promotionMethods?: Resolver<ReadonlyArray<ResolversTypes['CampaignPromotionMethod']>, ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceAffiliateReportForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceAffiliateReportForInfluencer'] = ResolversParentTypes['MarketplaceAffiliateReportForInfluencer']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  commission?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceAffiliateReportSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceAffiliateReportSummary'] = ResolversParentTypes['MarketplaceAffiliateReportSummary']
> = ResolversObject<{
  average?: Resolver<ResolversTypes['MarketplaceAffiliateStatsItem'], ParentType, ContextType>;
  chartData?: Resolver<ReadonlyArray<ResolversTypes['AffiliateCampaignChartReport']>, ParentType, ContextType>;
  joinedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['MarketplaceAffiliateStatsItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceAffiliateReportSummaryForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceAffiliateReportSummaryForInfluencer'] = ResolversParentTypes['MarketplaceAffiliateReportSummaryForInfluencer']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  commission?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceAffiliateStatsItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceAffiliateStatsItem'] = ResolversParentTypes['MarketplaceAffiliateStatsItem']
> = ResolversObject<{
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cvr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  grossProfit?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  influencerCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceCampaignCouponTrackingPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceCampaignCouponTrackingPayload'] = ResolversParentTypes['MarketplaceCampaignCouponTrackingPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceCampaignPostReportForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceCampaignPostReportForInfluencer'] = ResolversParentTypes['MarketplaceCampaignPostReportForInfluencer']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postReport?: Resolver<ResolversTypes['InfluencerPostReportStatsMarketplace'], ParentType, ContextType>;
  postReports?: Resolver<
    ReadonlyArray<ResolversTypes['InfluencerPostReportStatsMarketplace']>,
    ParentType,
    ContextType
  >;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceCampaignReportResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceCampaignReport'] = ResolversParentTypes['MarketplaceCampaignReport']
> = ResolversObject<{
  InternalInfo?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  average?: Resolver<Maybe<ResolversTypes['NewCampaignStatsItemRow']>, ParentType, ContextType>;
  campaignInfo?: Resolver<ResolversTypes['CampaignReportCampaignInfo'], ParentType, ContextType>;
  chartData?: Resolver<ReadonlyArray<ResolversTypes['CampaignChartReport']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  influencerReports?: Resolver<ReadonlyArray<ResolversTypes['CampaignInfluencerReport']>, ParentType, ContextType>;
  orderedSummaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignSummaryDaily']>>, ParentType, ContextType>;
  postCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['NewCampaignStatsItemRow'], ParentType, ContextType>;
  summaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignSummaryDaily']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplaceDetail'] = ResolversParentTypes['MarketplaceDetail']
> = ResolversObject<{
  additionalRequirement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  advertiser?: Resolver<ResolversTypes['AdvertiserName'], ParentType, ContextType>;
  agencyMarginRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  allowNewInfluencer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  brandName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  budget?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  campaignCategory?: Resolver<Maybe<ResolversTypes['CampaignCategoryName']>, ParentType, ContextType>;
  campaignCreator?: Resolver<Maybe<ResolversTypes['CampaignCreatorWithName']>, ParentType, ContextType>;
  campaignType?: Resolver<ResolversTypes['MarketplaceCampaignDetailType'], ParentType, ContextType>;
  campaignUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  clickUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerAction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerClick?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerPost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerSaleReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerSaleTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  couponUploadFiles?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentCost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  genders?: Resolver<ReadonlyArray<ResolversTypes['Genders']>, ParentType, ContextType>;
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  hubspotDealIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerManagementPics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PicName']>>, ParentType, ContextType>;
  isAllowMultiplePosts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAutoInfluencerApproval?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isGiftingCampaign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPostTracking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSelectionCampaign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  landingPageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  materialUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  materials?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  maxAge?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxFollowers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maximumRevenuePerInfluencer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minAge?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minFollowers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mkpServiceCampaignId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  needCouponsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preLaunchDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  productThumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regions?: Resolver<Maybe<ReadonlyArray<ResolversTypes['RegionName']>>, ParentType, ContextType>;
  rejectedInfo?: Resolver<Maybe<ResolversTypes['MarketplaceRejectedAdvertiserInfo']>, ParentType, ContextType>;
  requireDraftPost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requirement?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salesPics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PicName']>>, ParentType, ContextType>;
  sampleUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceInformation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceCampaignStatus'], ParentType, ContextType>;
  thumbnails?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trackingOptions?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignTrackingOption']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceDetailForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceDetailForInfluencer'] = ResolversParentTypes['MarketplaceDetailForInfluencer']
> = ResolversObject<{
  ableToJoin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  additionalRequirement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  anyXStatus?: Resolver<Maybe<ResolversTypes['AnyXMarketplaceCampaignStatus']>, ParentType, ContextType>;
  appliedStatus?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignAppliedStatus']>, ParentType, ContextType>;
  campaignDetailType?: Resolver<ResolversTypes['MarketplaceCampaignDetailType'], ParentType, ContextType>;
  campaignType?: Resolver<ResolversTypes['MarketplaceCampaignType'], ParentType, ContextType>;
  clickUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  confirmMadePostAvailability?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  couponCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  hasReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isApplied?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAutoInfluencerApproval?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isCouponCampaign?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isGiftingCampaign?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isJoined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPostTracking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isSelectionCampaign?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isWaiting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  materialUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  materials?: Resolver<Maybe<ReadonlyArray<ResolversTypes['MaterialName']>>, ParentType, ContextType>;
  maxFollowers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maximumRevenuePerInfluencer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minFollowers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minimumPaymentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  postStatus?: Resolver<Maybe<ResolversTypes['CampaignPostStatusForInfluencer']>, ParentType, ContextType>;
  postTrackingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preLaunchDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  remainingAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  requireDraftPost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requirement?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  revenuePerAction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerClick?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerPost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sampleUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceInformation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CampaignStatusForInfluencer'], ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbnails?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  trackingOptions?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignTrackingOption']>>, ParentType, ContextType>;
  trackingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  warningReason?: Resolver<Maybe<ResolversTypes['WarningReason']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceDraftPostDetailResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceDraftPostDetail'] = ResolversParentTypes['MarketplaceDraftPostDetail']
> = ResolversObject<{
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  images?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['MarketplacePostDetailInfluencer'], ParentType, ContextType>;
  rejectedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  rejectedMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceCampaignDraftPostStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceDraftPostForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceDraftPostForInfluencer'] = ResolversParentTypes['MarketplaceDraftPostForInfluencer']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  materials?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  rejectedMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceCampaignDraftPostStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceForReorderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplaceForReorder'] = ResolversParentTypes['MarketplaceForReorder']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceCampaignStatus'], ParentType, ContextType>;
  thumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplaceInfluencer'] = ResolversParentTypes['MarketplaceInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryNameForInfluencer'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['InfluencerEngagement'], ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participateStatus?: Resolver<ResolversTypes['MarketplaceCampaignParticipateStatus'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  tiktok?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  youtube?: Resolver<Maybe<ResolversTypes['SocialAccountV2']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceJoinableAccountsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceJoinableAccountsPayload'] = ResolversParentTypes['MarketplaceJoinableAccountsPayload']
> = ResolversObject<{
  facebookAccount?: Resolver<Maybe<ResolversTypes['JoinableFbAccount']>, ParentType, ContextType>;
  facebookPages?: Resolver<ReadonlyArray<ResolversTypes['JoinableAccount']>, ParentType, ContextType>;
  instagramAccounts?: Resolver<ReadonlyArray<ResolversTypes['JoinableInstagramAccount']>, ParentType, ContextType>;
  tiktokAccounts?: Resolver<ReadonlyArray<ResolversTypes['JoinableAccount']>, ParentType, ContextType>;
  twitterAccounts?: Resolver<ReadonlyArray<ResolversTypes['JoinableAccount']>, ParentType, ContextType>;
  youtubeAccounts?: Resolver<ReadonlyArray<ResolversTypes['JoinableAccount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceJoinablePromotionMethodsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceJoinablePromotionMethodsPayload'] = ResolversParentTypes['MarketplaceJoinablePromotionMethodsPayload']
> = ResolversObject<{
  emailNewsLetters?: Resolver<
    ReadonlyArray<ResolversTypes['JoinablePromotionMethodEmailNewsLetterPayload']>,
    ParentType,
    ContextType
  >;
  facebookAccount?: Resolver<
    Maybe<ResolversTypes['JoinablePromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  facebookPages?: Resolver<
    ReadonlyArray<ResolversTypes['JoinablePromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  instagramAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinablePromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  mobileApps?: Resolver<
    ReadonlyArray<ResolversTypes['JoinablePromotionMethodMobileAppPayload']>,
    ParentType,
    ContextType
  >;
  offlines?: Resolver<ReadonlyArray<ResolversTypes['JoinablePromotionMethodOfflinePayload']>, ParentType, ContextType>;
  podCasts?: Resolver<ReadonlyArray<ResolversTypes['JoinablePromotionMethodPodCastPayload']>, ParentType, ContextType>;
  tiktokAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinablePromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  twitterAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinablePromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  websites?: Resolver<ReadonlyArray<ResolversTypes['JoinablePromotionMethodWebsitePayload']>, ParentType, ContextType>;
  youtubeAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinablePromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceJoinedAccountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceJoinedAccountPayload'] = ResolversParentTypes['MarketplaceJoinedAccountPayload']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountType?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceJoinedMethodsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceJoinedMethodsPayload'] = ResolversParentTypes['MarketplaceJoinedMethodsPayload']
> = ResolversObject<{
  emailNewsLetters?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodEmailNewsLetterPayload']>,
    ParentType,
    ContextType
  >;
  facebookAccount?: Resolver<
    Maybe<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  facebookPages?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  instagramAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  mobileApps?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodMobileAppPayload']>,
    ParentType,
    ContextType
  >;
  offlines?: Resolver<ReadonlyArray<ResolversTypes['JoinedPromotionMethodOfflinePayload']>, ParentType, ContextType>;
  podCasts?: Resolver<ReadonlyArray<ResolversTypes['JoinedPromotionMethodPodCastPayload']>, ParentType, ContextType>;
  tiktokAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  twitterAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  websites?: Resolver<ReadonlyArray<ResolversTypes['JoinedPromotionMethodWebsitePayload']>, ParentType, ContextType>;
  youtubeAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['JoinedPromotionMethodSocialAccountPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceJoinedMethodsPayloadV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceJoinedMethodsPayloadV2'] = ResolversParentTypes['MarketplaceJoinedMethodsPayloadV2']
> = ResolversObject<{
  methods?: Resolver<ReadonlyArray<ResolversTypes['CampaignPromotionMethod']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplacePostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplacePost'] = ResolversParentTypes['MarketplacePost']
> = ResolversObject<{
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  costEditable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  couponCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['MarketplacePostInfluencer'], ParentType, ContextType>;
  joinedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  needReview?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  revenueItems?: Resolver<ReadonlyArray<ResolversTypes['MarketplaceRevenueItem']>, ParentType, ContextType>;
  socialAccountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignPostStatusForAdvertiser']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  warningReason?: Resolver<Maybe<ResolversTypes['WarningReason']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplacePostCommentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplacePostComment'] = ResolversParentTypes['MarketplacePostComment']
> = ResolversObject<{
  commentedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplacePostDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplacePostDetail'] = ResolversParentTypes['MarketplacePostDetail']
> = ResolversObject<{
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  detailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  images?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postDetailInfluencer?: Resolver<ResolversTypes['MarketplacePostDetailInfluencer'], ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignPostStatus']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  warningReason?: Resolver<Maybe<ResolversTypes['WarningReason']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplacePostDetailInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplacePostDetailInfluencer'] = ResolversParentTypes['MarketplacePostDetailInfluencer']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplacePostHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplacePostHistory'] = ResolversParentTypes['MarketplacePostHistory']
> = ResolversObject<{
  detailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  draftPostContent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftPostMaterials?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  operatedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  operatedUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceCampaignPostHistoryStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplacePostInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplacePostInfluencer'] = ResolversParentTypes['MarketplacePostInfluencer']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplacePostNoRevenueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplacePostNoRevenue'] = ResolversParentTypes['MarketplacePostNoRevenue']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  influencerAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  thumbnails?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplacePostsNoRevenueResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplacePostsNoRevenue'] = ResolversParentTypes['MarketplacePostsNoRevenue']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['MarketplacePostNoRevenue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceRejectedAdvertiserInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceRejectedAdvertiserInfo'] = ResolversParentTypes['MarketplaceRejectedAdvertiserInfo']
> = ResolversObject<{
  operatedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  operatedUser?: Resolver<ResolversTypes['OperatedUserInfo'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MarketplaceCampaignRejectedStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceRevenueItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplaceRevenueItem'] = ResolversParentTypes['MarketplaceRevenueItem']
> = ResolversObject<{
  revenueType?: Resolver<ResolversTypes['MarketplaceCampaignRevenueType'], ParentType, ContextType>;
  updatedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceTrackingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketplaceTracking'] = ResolversParentTypes['MarketplaceTracking']
> = ResolversObject<{
  pixelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postbackUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketplaceTrackingPostUrlForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketplaceTrackingPostUrlForInfluencer'] = ResolversParentTypes['MarketplaceTrackingPostUrlForInfluencer']
> = ResolversObject<{
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MaterialNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MaterialName'] = ResolversParentTypes['MaterialName']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MediaItemPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MediaItemPayload'] = ResolversParentTypes['MediaItemPayload']
> = ResolversObject<{
  image?: Resolver<Maybe<ResolversTypes['ImagePayload']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  video?: Resolver<Maybe<ResolversTypes['VideoPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MessageTemplateEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTemplateEvent'] = ResolversParentTypes['MessageTemplateEvent']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  templateId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  templateName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MessageTemplateItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTemplateItem'] = ResolversParentTypes['MessageTemplateItem']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  templateId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MinorCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MinorCategory'] = ResolversParentTypes['MinorCategory']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MobileVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MobileVersion'] = ResolversParentTypes['MobileVersion']
> = ResolversObject<{
  buildNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  versionCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = ResolversObject<{
  addAllSelectedInfluencers?: Resolver<
    Maybe<ResolversTypes['AddAllSelectedInfluencers']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddAllSelectedInfluencersArgs, 'input'>
  >;
  addComment?: Resolver<
    Maybe<ResolversTypes['AddCommentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddCommentArgs, 'input'>
  >;
  addEmailTemplate?: Resolver<
    Maybe<ResolversTypes['AddEmailTemplatePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddEmailTemplateArgs, 'input'>
  >;
  addFan?: Resolver<
    Maybe<ResolversTypes['AddFanPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddFanArgs, 'input'>
  >;
  addFanTags?: Resolver<
    Maybe<ResolversTypes['AddFanTagsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddFanTagsArgs, 'input'>
  >;
  addForm?: Resolver<
    Maybe<ResolversTypes['AddFormPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddFormArgs, 'input'>
  >;
  addInfluencerToUserProposal?: Resolver<
    Maybe<ResolversTypes['AddInfluencerToUserProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddInfluencerToUserProposalArgs, 'input'>
  >;
  addInstagramCompareAccountAnalytics?: Resolver<
    Maybe<ResolversTypes['AddCompareInstagramAccountAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddInstagramCompareAccountAnalyticsArgs, 'input'>
  >;
  addInstagramHashtagAnalytics?: Resolver<
    Maybe<ResolversTypes['AddInstagramHashtagAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddInstagramHashtagAnalyticsArgs, 'input'>
  >;
  addMarketplacePostUrl?: Resolver<
    Maybe<ResolversTypes['AddMarketplacePostUrl']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddMarketplacePostUrlArgs, 'input'>
  >;
  addMarketplacePostUrlForInfluencer?: Resolver<
    Maybe<ResolversTypes['AddMarketplacePostUrlForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddMarketplacePostUrlForInfluencerArgs, 'input'>
  >;
  addReadTutorial?: Resolver<
    Maybe<ResolversTypes['AddReadTutorial']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddReadTutorialArgs, 'input'>
  >;
  addSelectedInfluencers?: Resolver<
    Maybe<ResolversTypes['AddSelectedInfluencers']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddSelectedInfluencersArgs, 'input'>
  >;
  addSocialAccountToUserProposal?: Resolver<
    Maybe<ResolversTypes['AddSocialAccountToUserProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddSocialAccountToUserProposalArgs, 'input'>
  >;
  addStaffAdvertisersToAnotherStaff?: Resolver<
    Maybe<ResolversTypes['AddStaffAdvertisersToAnotherStaff']>,
    ParentType,
    ContextType,
    RequireFields<MutationaddStaffAdvertisersToAnotherStaffArgs, 'input'>
  >;
  addYoutubeCompareAccountAnalytics?: Resolver<
    ResolversTypes['AddYoutubeCompareAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationaddYoutubeCompareAccountAnalyticsArgs, 'input'>
  >;
  allowEngagementInfluencerProposalList?: Resolver<
    Maybe<ResolversTypes['AllowEngagementInfluencerProposalList']>,
    ParentType,
    ContextType,
    RequireFields<MutationallowEngagementInfluencerProposalListArgs, 'input'>
  >;
  allowInfluencersUserProposal?: Resolver<
    Maybe<ResolversTypes['AllowInfluencersUserProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationallowInfluencersUserProposalArgs, 'input'>
  >;
  analyticsSelectSubscriptionPlan?: Resolver<
    Maybe<ResolversTypes['AnalyticsSelectSubscriptionPlan']>,
    ParentType,
    ContextType,
    RequireFields<MutationanalyticsSelectSubscriptionPlanArgs, 'input'>
  >;
  anyXCheckEmail?: Resolver<
    Maybe<ResolversTypes['AnyXCheckEmailPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXCheckEmailArgs, 'input'>
  >;
  anyXSocialAuthConnect?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthConnectPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthConnectArgs, 'input'>
  >;
  anyXSocialAuthConnectForLinkBio?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthConnectForLinkBioPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthConnectForLinkBioArgs, 'input'>
  >;
  anyXSocialAuthDisconnect?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthDisconnectPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthDisconnectArgs, 'input'>
  >;
  anyXSocialAuthReconnectForLinkBio?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthReconnectForLinkBioPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthReconnectForLinkBioArgs, 'input'>
  >;
  anyXSocialAuthReconnectTikTok?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthReconnectTikTokPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthReconnectTikTokArgs, 'input'>
  >;
  anyXSocialAuthReconnectTtcm?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthReconnectTTCMPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthReconnectTtcmArgs, 'input'>
  >;
  anyXSocialAuthSignIn?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthSignInPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthSignInArgs, 'input'>
  >;
  anyXSocialAuthSignInInstagramLine?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthSignInInstagramLinePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthSignInInstagramLineArgs, 'input'>
  >;
  anyXSocialAuthSignInOrSignUp?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthSignInOrSignUpPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthSignInOrSignUpArgs, 'input'>
  >;
  anyXSocialAuthSignUp?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthSignUpPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthSignUpArgs, 'input'>
  >;
  anyXSocialAuthSignUpPartner?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthSignUpPartnerPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthSignUpPartnerArgs, 'input'>
  >;
  anyXSocialAuthSignUpTalent?: Resolver<
    Maybe<ResolversTypes['AnyXSocialAuthSignUpTalentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationanyXSocialAuthSignUpTalentArgs, 'input'>
  >;
  approveApplicantInfluencers?: Resolver<
    Maybe<ResolversTypes['ApproveApplicantInfluencers']>,
    ParentType,
    ContextType,
    RequireFields<MutationapproveApplicantInfluencersArgs, 'input'>
  >;
  approveAutoManagedPayout?: Resolver<
    Maybe<ResolversTypes['ApproveAutoManagedPayout']>,
    ParentType,
    ContextType,
    RequireFields<MutationapproveAutoManagedPayoutArgs, 'input'>
  >;
  approveCmsInvoice?: Resolver<
    Maybe<ResolversTypes['ApproveCmsInvoice']>,
    ParentType,
    ContextType,
    RequireFields<MutationapproveCmsInvoiceArgs, 'input'>
  >;
  approveMarketplace?: Resolver<
    Maybe<ResolversTypes['ApproveMarketplace']>,
    ParentType,
    ContextType,
    RequireFields<MutationapproveMarketplaceArgs, 'input'>
  >;
  approveMarketplaceAffiliatePayout?: Resolver<
    Maybe<ResolversTypes['ApproveMarketplaceAffiliatePayout']>,
    ParentType,
    ContextType,
    RequireFields<MutationapproveMarketplaceAffiliatePayoutArgs, 'input'>
  >;
  approveMarketplaceDraftPost?: Resolver<
    Maybe<ResolversTypes['ApproveMarketplaceDraftPost']>,
    ParentType,
    ContextType,
    RequireFields<MutationapproveMarketplaceDraftPostArgs, 'input'>
  >;
  approveTiktokSpecialCampaign?: Resolver<
    Maybe<ResolversTypes['ApproveTikTokSpecialCampaign']>,
    ParentType,
    ContextType,
    RequireFields<MutationapproveTiktokSpecialCampaignArgs, 'input'>
  >;
  assignAdvertiserToStaffs?: Resolver<
    Maybe<ResolversTypes['AssignAdvertiserForStaffs']>,
    ParentType,
    ContextType,
    RequireFields<MutationassignAdvertiserToStaffsArgs, 'input'>
  >;
  assignChatPic?: Resolver<
    Maybe<ResolversTypes['AssignChatPICPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationassignChatPicArgs, 'input'>
  >;
  auth0ToLocalJwt?: Resolver<
    Maybe<ResolversTypes['Auth0ToLocalJWT']>,
    ParentType,
    ContextType,
    RequireFields<Mutationauth0ToLocalJwtArgs, 'input'>
  >;
  authTiktokBusinessAccount?: Resolver<
    Maybe<ResolversTypes['AuthTiktokBusinessAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationauthTiktokBusinessAccountArgs, 'input'>
  >;
  boostTiktokAdPost?: Resolver<
    Maybe<ResolversTypes['BoostTiktokAdPost']>,
    ParentType,
    ContextType,
    RequireFields<MutationboostTiktokAdPostArgs, 'input'>
  >;
  bulkUpdateEngagementInfluencersBudget?: Resolver<
    Maybe<ResolversTypes['BulkUpdateEngagementInfluencersBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationbulkUpdateEngagementInfluencersBudgetArgs, 'input'>
  >;
  bulkUpdateEngagementSocialAccountsBudget?: Resolver<
    Maybe<ResolversTypes['BulkUpdateEngagementSocialAccountsBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationbulkUpdateEngagementSocialAccountsBudgetArgs, 'input'>
  >;
  bulkUpdatePackageProposalAccountsBudget?: Resolver<
    Maybe<ResolversTypes['BulkUpdatePackageProposalAccountsBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationbulkUpdatePackageProposalAccountsBudgetArgs, 'input'>
  >;
  bulkUpdatePackageProposalInfluencersBudget?: Resolver<
    Maybe<ResolversTypes['BulkUpdatePackageProposalInfluencersBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationbulkUpdatePackageProposalInfluencersBudgetArgs, 'input'>
  >;
  bulkUpdateUserProposalAccountsBudget?: Resolver<
    Maybe<ResolversTypes['BulkUpdateUserProposalAccountsBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationbulkUpdateUserProposalAccountsBudgetArgs, 'input'>
  >;
  bulkUpdateUserProposalInfluencersBudget?: Resolver<
    Maybe<ResolversTypes['BulkUpdateUserProposalInfluencersBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationbulkUpdateUserProposalInfluencersBudgetArgs, 'input'>
  >;
  cancelSubscriptionPlan?: Resolver<Maybe<ResolversTypes['CancelSubscriptionPlan']>, ParentType, ContextType>;
  changeInstagramUGCManagementPostStatus?: Resolver<
    ResolversTypes['ChangeInstagramUGCManagementPostStatusOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationchangeInstagramUGCManagementPostStatusArgs, 'input'>
  >;
  clearCmsChannelAssetOwner?: Resolver<
    Maybe<ResolversTypes['ClearCmsChannelAssetOwner']>,
    ParentType,
    ContextType,
    RequireFields<MutationclearCmsChannelAssetOwnerArgs, 'input'>
  >;
  clickNotification?: Resolver<
    Maybe<ResolversTypes['ClickNotificationPayload']>,
    ParentType,
    ContextType,
    Partial<MutationclickNotificationArgs>
  >;
  clickNotificationByEventId?: Resolver<
    Maybe<ResolversTypes['ClickNotificationByEventIdPayload']>,
    ParentType,
    ContextType,
    Partial<MutationclickNotificationByEventIdArgs>
  >;
  cloneMarketplace?: Resolver<
    Maybe<ResolversTypes['CloneMarketplace']>,
    ParentType,
    ContextType,
    RequireFields<MutationcloneMarketplaceArgs, 'input'>
  >;
  connectFacebookPagesAndInstagramAccounts?: Resolver<
    Maybe<ResolversTypes['ConnectFacebookPagesAndInstagramAccountsPayload']>,
    ParentType,
    ContextType,
    Partial<MutationconnectFacebookPagesAndInstagramAccountsArgs>
  >;
  connectNewShopifyAccount?: Resolver<
    Maybe<ResolversTypes['ConnectNewShopifyAccountPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationconnectNewShopifyAccountArgs, 'input'>
  >;
  connectPackageToEngagementProposal?: Resolver<
    Maybe<ResolversTypes['ConnectPackageToEngagementProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationconnectPackageToEngagementProposalArgs, 'input'>
  >;
  connectPackageWithInfluencerAccounts?: Resolver<
    Maybe<ResolversTypes['ConnectPackageWithInfluencerAccounts']>,
    ParentType,
    ContextType,
    RequireFields<MutationconnectPackageWithInfluencerAccountsArgs, 'input'>
  >;
  connectPackageWithSocialAccounts?: Resolver<
    Maybe<ResolversTypes['ConnectPackageWithSocialAccounts']>,
    ParentType,
    ContextType,
    RequireFields<MutationconnectPackageWithSocialAccountsArgs, 'input'>
  >;
  connectPackageWithUserProposal?: Resolver<
    Maybe<ResolversTypes['ConnectPackageWithUserProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationconnectPackageWithUserProposalArgs, 'input'>
  >;
  connectPromotionMethods?: Resolver<
    Maybe<ResolversTypes['ConnectPromotionMethodsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationconnectPromotionMethodsArgs, 'input'>
  >;
  createAdminUser?: Resolver<
    Maybe<ResolversTypes['CreateAdmin']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateAdminUserArgs, 'input'>
  >;
  createAdvertiser?: Resolver<
    Maybe<ResolversTypes['CreateAdvertiser']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateAdvertiserArgs, 'input'>
  >;
  createAdvertiserUser?: Resolver<
    Maybe<ResolversTypes['CreateAdvertiserUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateAdvertiserUserArgs, 'input'>
  >;
  createAdvertiserWithUser?: Resolver<
    Maybe<ResolversTypes['CreateAdvertiserWithUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateAdvertiserWithUserArgs, 'input'>
  >;
  createAdvertiserWithUserV2?: Resolver<
    Maybe<ResolversTypes['CreateAdvertiserWithUserV2']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateAdvertiserWithUserV2Args, 'input'>
  >;
  createAgencyUser?: Resolver<
    Maybe<ResolversTypes['CreateAgency']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateAgencyUserArgs, 'input'>
  >;
  createAgencyWithUser?: Resolver<
    Maybe<ResolversTypes['CreateAgencyWithUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateAgencyWithUserArgs, 'input'>
  >;
  createCreatorStaff?: Resolver<
    Maybe<ResolversTypes['CreateCreatorStaffPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateCreatorStaffArgs, 'input'>
  >;
  createDraftBoostPost?: Resolver<
    Maybe<ResolversTypes['CreateDraftBoostPost']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateDraftBoostPostArgs, 'input'>
  >;
  createEngagement?: Resolver<
    Maybe<ResolversTypes['CreateEngagement']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateEngagementArgs, 'input'>
  >;
  createEngagementPostForInfluencerV2?: Resolver<
    Maybe<ResolversTypes['CreateEngagementPostForInfluencerV2']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateEngagementPostForInfluencerV2Args, 'input'>
  >;
  createEngagementPostV2?: Resolver<
    Maybe<ResolversTypes['CreateEngagementPostV2']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateEngagementPostV2Args, 'input'>
  >;
  createGeminiThread?: Resolver<Maybe<ResolversTypes['CreateGeminiThread']>, ParentType, ContextType>;
  createInfluencerBio?: Resolver<
    Maybe<ResolversTypes['CreateInfluencerBioPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateInfluencerBioArgs, 'input'>
  >;
  createInfluencerV2?: Resolver<
    Maybe<ResolversTypes['CreateInfluencerV2']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateInfluencerV2Args, 'input'>
  >;
  createInstagramUGCManagementHashtag?: Resolver<
    ResolversTypes['CreateInstagramUGCManagementHashtagOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationcreateInstagramUGCManagementHashtagArgs, 'input'>
  >;
  createInstagramUserHashtag?: Resolver<
    ResolversTypes['CreateInstagramUserHashtagOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationcreateInstagramUserHashtagArgs, 'input'>
  >;
  createLineBroadcastMessage?: Resolver<
    Maybe<ResolversTypes['CreateLineBroadcastMessagePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateLineBroadcastMessageArgs, 'input'>
  >;
  createLineChannel?: Resolver<
    Maybe<ResolversTypes['CreateLineChannelPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateLineChannelArgs, 'input'>
  >;
  createMarketplace?: Resolver<
    Maybe<ResolversTypes['CreateMarketplace']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateMarketplaceArgs, 'input'>
  >;
  createMarketplaceDraftPost?: Resolver<
    Maybe<ResolversTypes['CreateMarketplaceDraftPost']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateMarketplaceDraftPostArgs, 'input'>
  >;
  createMobileAppVersion?: Resolver<
    Maybe<ResolversTypes['CreateMobileAppVersion']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateMobileAppVersionArgs, 'input'>
  >;
  createOpenAiThread?: Resolver<Maybe<ResolversTypes['CreateOpenAIThread']>, ParentType, ContextType>;
  createPackage?: Resolver<
    Maybe<ResolversTypes['CreatePackage']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreatePackageArgs, 'input'>
  >;
  createPackageWithInfluencerAccounts?: Resolver<
    Maybe<ResolversTypes['CreatePackageWithInfluencerAccounts']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreatePackageWithInfluencerAccountsArgs, 'input'>
  >;
  createPackageWithSocialAccounts?: Resolver<
    Maybe<ResolversTypes['CreatePackageWithSocialAccounts']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreatePackageWithSocialAccountsArgs, 'input'>
  >;
  createPackageWithUserProposal?: Resolver<
    Maybe<ResolversTypes['CreatePackageWithUserProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreatePackageWithUserProposalArgs, 'input'>
  >;
  createPartnerUser?: Resolver<
    Maybe<ResolversTypes['CreatePartnerUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreatePartnerUserArgs, 'input'>
  >;
  createSelfRegisteredAdvertiserUser?: Resolver<
    Maybe<ResolversTypes['CreateSelfRegisteredAdvertiserUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateSelfRegisteredAdvertiserUserArgs, 'input'>
  >;
  createSocialAccountAnalyticsAdminV2?: Resolver<
    ResolversTypes['CreateSocialAccountAnalyticsAdminOutputV2'],
    ParentType,
    ContextType,
    RequireFields<MutationcreateSocialAccountAnalyticsAdminV2Args, 'input'>
  >;
  createSocialAccountAnalyticsAdvertiserV2?: Resolver<
    ResolversTypes['CreateSocialAccountAnalyticsAdvertiserOutputV2'],
    ParentType,
    ContextType,
    RequireFields<MutationcreateSocialAccountAnalyticsAdvertiserV2Args, 'input'>
  >;
  createStaffUser?: Resolver<
    Maybe<ResolversTypes['CreateStaff']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateStaffUserArgs, 'input'>
  >;
  createTalentAgencyUser?: Resolver<
    Maybe<ResolversTypes['CreateTalentAgencyUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateTalentAgencyUserArgs, 'input'>
  >;
  createTalentAgencyWithUser?: Resolver<
    Maybe<ResolversTypes['CreateTalentAgencyWithUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateTalentAgencyWithUserArgs, 'input'>
  >;
  createTalentInfluencer?: Resolver<
    Maybe<ResolversTypes['CreateTalentInfluencerPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateTalentInfluencerArgs, 'input'>
  >;
  createTiktokAdAccount?: Resolver<
    Maybe<ResolversTypes['CreateTiktokAdAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateTiktokAdAccountArgs, 'input'>
  >;
  createTiktokBusinessCenter?: Resolver<
    Maybe<ResolversTypes['CreateTiktokBusinessCenter']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateTiktokBusinessCenterArgs, 'input'>
  >;
  createTiktokSpecialCampaign?: Resolver<
    Maybe<ResolversTypes['CreateTikTokSpecialCampaign']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateTiktokSpecialCampaignArgs, 'input'>
  >;
  createTiktokUserHashtag?: Resolver<
    ResolversTypes['CreateTiktokUserHashtagOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationcreateTiktokUserHashtagArgs, 'input'>
  >;
  createTiktokUserKeyword?: Resolver<
    ResolversTypes['CreateTiktokUserKeywordOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationcreateTiktokUserKeywordArgs, 'input'>
  >;
  deleteAdvertiser?: Resolver<
    Maybe<ResolversTypes['DeleteAdvertiser']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteAdvertiserArgs, 'id'>
  >;
  deleteAgency?: Resolver<
    Maybe<ResolversTypes['DeleteAgency']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteAgencyArgs, 'id'>
  >;
  deleteBrandAccountAnalytics?: Resolver<
    ResolversTypes['DeleteBrandAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationdeleteBrandAccountAnalyticsArgs, 'input'>
  >;
  deleteComment?: Resolver<
    Maybe<ResolversTypes['DeleteCommentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteCommentArgs, 'input'>
  >;
  deleteEngagement?: Resolver<
    Maybe<ResolversTypes['DeleteEngagement']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteEngagementArgs, 'id'>
  >;
  deleteEngagementInfluencerProposal?: Resolver<
    Maybe<ResolversTypes['DeleteEngagementInfluencerProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteEngagementInfluencerProposalArgs, 'input'>
  >;
  deleteEngagementPostForInfluencer?: Resolver<
    Maybe<ResolversTypes['DeleteEngagementPostForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteEngagementPostForInfluencerArgs, 'id'>
  >;
  deleteEngagementProposalJoinedAccount?: Resolver<
    Maybe<ResolversTypes['DeleteEngagementProposalJoinedAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteEngagementProposalJoinedAccountArgs, 'input'>
  >;
  deleteEngagementSocialAccountProposal?: Resolver<
    Maybe<ResolversTypes['DeleteEngagementSocialAccountProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteEngagementSocialAccountProposalArgs, 'input'>
  >;
  deleteFanTags?: Resolver<
    Maybe<ResolversTypes['DeleteFanTagsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteFanTagsArgs, 'input'>
  >;
  deleteGeminiThread?: Resolver<
    Maybe<ResolversTypes['DeleteGeminiThread']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteGeminiThreadArgs, 'input'>
  >;
  deleteInfluencer?: Resolver<
    Maybe<ResolversTypes['DeleteInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteInfluencerArgs, 'id'>
  >;
  deleteInfluencerPackage?: Resolver<
    Maybe<ResolversTypes['DeleteInfluencerPackage']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteInfluencerPackageArgs, 'influencerIds' | 'packageId'>
  >;
  deleteInstagramCompareAccountAnalytics?: Resolver<
    Maybe<ResolversTypes['DeleteCompareInstagramAccountAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteInstagramCompareAccountAnalyticsArgs, 'input'>
  >;
  deleteInstagramHashtagAnalytics?: Resolver<
    Maybe<ResolversTypes['DeleteInstagramHashtagAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteInstagramHashtagAnalyticsArgs, 'input'>
  >;
  deleteInstagramUGCManagementHashtag?: Resolver<
    ResolversTypes['DeleteInstagramUGCManagementHashtagOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationdeleteInstagramUGCManagementHashtagArgs, 'input'>
  >;
  deleteInstagramUserHashtag?: Resolver<
    ResolversTypes['DeleteInstagramUserHashtagOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationdeleteInstagramUserHashtagArgs, 'input'>
  >;
  deleteMarketplace?: Resolver<
    Maybe<ResolversTypes['DeleteMarketplace']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteMarketplaceArgs, 'id'>
  >;
  deleteOpenAiThread?: Resolver<
    Maybe<ResolversTypes['DeleteOpenAIThread']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteOpenAiThreadArgs, 'input'>
  >;
  deletePackage?: Resolver<
    Maybe<ResolversTypes['DeletePackage']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeletePackageArgs, 'id'>
  >;
  deleteSocialAccountPackage?: Resolver<
    Maybe<ResolversTypes['DeleteSocialAccountPackage']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteSocialAccountPackageArgs, 'packageId' | 'socialAccountIds' | 'socialMedia'>
  >;
  deleteTalentAgency?: Resolver<
    Maybe<ResolversTypes['DeleteTalentAgency']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteTalentAgencyArgs, 'id'>
  >;
  deleteTiktokSpecialCampaign?: Resolver<
    Maybe<ResolversTypes['DeleteTikTokSpecialCampaign']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteTiktokSpecialCampaignArgs, 'input'>
  >;
  deleteTiktokUserHashtag?: Resolver<
    ResolversTypes['DeleteTiktokUserHashtagOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationdeleteTiktokUserHashtagArgs, 'input'>
  >;
  deleteTiktokUserKeyword?: Resolver<
    ResolversTypes['DeleteTiktokUserKeywordOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationdeleteTiktokUserKeywordArgs, 'input'>
  >;
  deleteUser?: Resolver<
    Maybe<ResolversTypes['DeleteUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteUserArgs, 'id'>
  >;
  deleteUserProposalInfluencer?: Resolver<
    Maybe<ResolversTypes['DeleteUserProposalInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteUserProposalInfluencerArgs, 'input'>
  >;
  deleteUserProposalSocialAccount?: Resolver<
    Maybe<ResolversTypes['DeleteUserProposalSocialAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteUserProposalSocialAccountArgs, 'input'>
  >;
  deleteUserProposalSocialAccountIndividually?: Resolver<
    Maybe<ResolversTypes['DeleteUserProposalSocialAccountIndividually']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteUserProposalSocialAccountIndividuallyArgs, 'input'>
  >;
  deleteYoutubeCompareAccountAnalytics?: Resolver<
    ResolversTypes['DeleteYoutubeCompareAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationdeleteYoutubeCompareAccountAnalyticsArgs, 'input'>
  >;
  denyEngagementInfluencerProposalList?: Resolver<
    Maybe<ResolversTypes['DenyEngagementInfluencerProposalList']>,
    ParentType,
    ContextType,
    RequireFields<MutationdenyEngagementInfluencerProposalListArgs, 'input'>
  >;
  denyInfluencersUserProposal?: Resolver<
    Maybe<ResolversTypes['DenyInfluencersUserProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationdenyInfluencersUserProposalArgs, 'input'>
  >;
  disconnectPackageFromEngagementProposal?: Resolver<
    Maybe<ResolversTypes['DisconnectPackageFromEngagementProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationdisconnectPackageFromEngagementProposalArgs, 'input'>
  >;
  disconnectPromotionMethods?: Resolver<
    Maybe<ResolversTypes['DisconnectPromotionMethodsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationdisconnectPromotionMethodsArgs, 'input'>
  >;
  disconnectTiktokAdAccount?: Resolver<
    Maybe<ResolversTypes['DisconnectTiktokAdAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationdisconnectTiktokAdAccountArgs, 'input'>
  >;
  editSocialAccountAnalyticsAdminV2?: Resolver<
    ResolversTypes['EditSocialAccountAnalyticsAdminOutputV2'],
    ParentType,
    ContextType,
    RequireFields<MutationeditSocialAccountAnalyticsAdminV2Args, 'input'>
  >;
  editSocialAccountAnalyticsAdvertiserV2?: Resolver<
    ResolversTypes['EditSocialAccountAnalyticsAdvertiserOutputV2'],
    ParentType,
    ContextType,
    RequireFields<MutationeditSocialAccountAnalyticsAdvertiserV2Args, 'input'>
  >;
  engagementAddInfluencersToProposalFromAnotherCampaign?: Resolver<
    Maybe<ResolversTypes['EngagementAddInfluencersToProposalFromAnotherCampaign']>,
    ParentType,
    ContextType,
    RequireFields<MutationengagementAddInfluencersToProposalFromAnotherCampaignArgs, 'input'>
  >;
  engagementCampaignCouponTracking?: Resolver<
    Maybe<ResolversTypes['EngagementCampaignCouponTrackingPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationengagementCampaignCouponTrackingArgs, 'input'>
  >;
  exportCmsInvoiceToSpreadsheet?: Resolver<
    Maybe<ResolversTypes['ExportCmsInvoiceToSpreadsheet']>,
    ParentType,
    ContextType,
    RequireFields<MutationexportCmsInvoiceToSpreadsheetArgs, 'input'>
  >;
  exportInstagramAccountDashboardAnalytics?: Resolver<
    Maybe<ResolversTypes['ExportDashboardInstagramAccountAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<MutationexportInstagramAccountDashboardAnalyticsArgs, 'input'>
  >;
  exportInstagramHashtagPostsToGoogleSpreadsheet?: Resolver<
    ResolversTypes['ExportInstagramHashtagPostsToGoogleSpreadsheetOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationexportInstagramHashtagPostsToGoogleSpreadsheetArgs, 'input'>
  >;
  exportYoutubeDashboardAccountAnalytics?: Resolver<
    ResolversTypes['ExportYoutubeDashboardAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationexportYoutubeDashboardAccountAnalyticsArgs, 'input'>
  >;
  exportYoutubeDashboardAccountAnalyticsForInfluencer?: Resolver<
    ResolversTypes['ExportYoutubeDashboardAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationexportYoutubeDashboardAccountAnalyticsForInfluencerArgs, 'input'>
  >;
  externalCreateFacebookPageAccountAnalytics?: Resolver<
    ResolversTypes['ExternalCreateFacebookPageAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationexternalCreateFacebookPageAccountAnalyticsArgs, 'input'>
  >;
  externalCreateInstagramAccountAnalytics?: Resolver<
    ResolversTypes['ExternalCreateInstagramAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationexternalCreateInstagramAccountAnalyticsArgs, 'input'>
  >;
  externalCreateYoutubeAccountAnalytics?: Resolver<
    ResolversTypes['ExternalCreateYoutubeAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationexternalCreateYoutubeAccountAnalyticsArgs, 'input'>
  >;
  externalSocialAuthConnectableFacebookPageAnalytics?: Resolver<
    Maybe<ResolversTypes['ExternalConnectableFacebookPageAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationexternalSocialAuthConnectableFacebookPageAnalyticsArgs, 'input'>
  >;
  externalSocialAuthConnectableInstagramAccountsAnalytics?: Resolver<
    Maybe<ResolversTypes['ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationexternalSocialAuthConnectableInstagramAccountsAnalyticsArgs, 'input'>
  >;
  externalSocialAuthReconnectFacebookPageAccountAnalytics?: Resolver<
    Maybe<ResolversTypes['ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationexternalSocialAuthReconnectFacebookPageAccountAnalyticsArgs, 'input'>
  >;
  externalUserSocialAuthReconnectInstagramAccountAnalytics?: Resolver<
    Maybe<ResolversTypes['ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationexternalUserSocialAuthReconnectInstagramAccountAnalyticsArgs, 'input'>
  >;
  fetchAutoManagedCustomShopeeCampaigns?: Resolver<
    Maybe<ResolversTypes['ShopeeCustomCampaignPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationfetchAutoManagedCustomShopeeCampaignsArgs, 'input'>
  >;
  generateMarketplaceCampaignDetail?: Resolver<
    Maybe<ResolversTypes['GenerateMarketplaceCampaignDetailPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationgenerateMarketplaceCampaignDetailArgs, 'input'>
  >;
  generateMarketplacePostCaption?: Resolver<
    Maybe<ResolversTypes['AcceptedPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationgenerateMarketplacePostCaptionArgs, 'input'>
  >;
  generatePartnerInfluencerSignUpUrl?: Resolver<
    Maybe<ResolversTypes['GeneratePartnerInfluencerSignUpUrl']>,
    ParentType,
    ContextType
  >;
  generateTalentInfluencerSignUpUrl?: Resolver<
    Maybe<ResolversTypes['GenerateTalentInfluencerSignUpUrl']>,
    ParentType,
    ContextType
  >;
  generateTikTokSpecialPostCaption?: Resolver<
    Maybe<ResolversTypes['AcceptedPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationgenerateTikTokSpecialPostCaptionArgs, 'input'>
  >;
  importFans?: Resolver<
    Maybe<ResolversTypes['ImportFansPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationimportFansArgs, 'input'>
  >;
  influencerAddYoutubeCompareAccountAnalytics?: Resolver<
    ResolversTypes['AddYoutubeCompareAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationinfluencerAddYoutubeCompareAccountAnalyticsArgs, 'input'>
  >;
  influencerAnalyticsTwitterRefresh?: Resolver<
    ResolversTypes['InfluencerAnalyticsTwitterRefreshOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationinfluencerAnalyticsTwitterRefreshArgs, 'input'>
  >;
  influencerDeleteYoutubeCompareAccountAnalytics?: Resolver<
    ResolversTypes['DeleteYoutubeCompareAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationinfluencerDeleteYoutubeCompareAccountAnalyticsArgs, 'input'>
  >;
  influencerExportInstagramAccountDashboard?: Resolver<
    Maybe<ResolversTypes['InfluencerExportDashboardInstagramAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationinfluencerExportInstagramAccountDashboardArgs, 'input'>
  >;
  influencerSignUp?: Resolver<
    Maybe<ResolversTypes['InfluencerSignUpInputPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationinfluencerSignUpArgs, 'input'>
  >;
  influencerSocialAuthReconnectInstagramAccountAnalytics?: Resolver<
    Maybe<ResolversTypes['InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationinfluencerSocialAuthReconnectInstagramAccountAnalyticsArgs, 'input'>
  >;
  joinAutoManagedCampaign?: Resolver<
    Maybe<ResolversTypes['JoinAutoManagedCampaignPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationjoinAutoManagedCampaignArgs, 'input'>
  >;
  joinMarketplaceByStaff?: Resolver<
    Maybe<ResolversTypes['JoinMarketplaceByStaff']>,
    ParentType,
    ContextType,
    RequireFields<MutationjoinMarketplaceByStaffArgs, 'input'>
  >;
  joinMarketplaceV2?: Resolver<
    Maybe<ResolversTypes['JoinMarketplaceV2']>,
    ParentType,
    ContextType,
    RequireFields<MutationjoinMarketplaceV2Args, 'input'>
  >;
  joinTiktokSpecialCampaign?: Resolver<
    Maybe<ResolversTypes['JoinTiktokSpecialCampaign']>,
    ParentType,
    ContextType,
    RequireFields<MutationjoinTiktokSpecialCampaignArgs, 'input'>
  >;
  linkCmsChannelAssets?: Resolver<
    Maybe<ResolversTypes['LinkCmsChannelAssets']>,
    ParentType,
    ContextType,
    RequireFields<MutationlinkCmsChannelAssetsArgs, 'input'>
  >;
  madeMarketplaceCampaignPost?: Resolver<
    Maybe<ResolversTypes['MadeMarketplaceCampaignPost']>,
    ParentType,
    ContextType,
    RequireFields<MutationmadeMarketplaceCampaignPostArgs, 'input'>
  >;
  markReadNotification?: Resolver<ResolversTypes['MarkReadNotificationPayload'], ParentType, ContextType>;
  markReadNotificationByApp?: Resolver<
    ResolversTypes['MarkReadNotificationPayload'],
    ParentType,
    ContextType,
    Partial<MutationmarkReadNotificationByAppArgs>
  >;
  marketplaceCampaignCouponTracking?: Resolver<
    Maybe<ResolversTypes['MarketplaceCampaignCouponTrackingPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationmarketplaceCampaignCouponTrackingArgs, 'input'>
  >;
  publishInfluencerBio?: Resolver<
    Maybe<ResolversTypes['PublishInfluencerBioPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationpublishInfluencerBioArgs, 'input'>
  >;
  reSendBcaRequest?: Resolver<
    Maybe<ResolversTypes['ReSendBcaRequestPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationreSendBcaRequestArgs, 'input'>
  >;
  rechargeAdvertiserAccount?: Resolver<
    Maybe<ResolversTypes['RechargeAdvertiserAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationrechargeAdvertiserAccountArgs, 'input'>
  >;
  reconnectTiktokAdAccount?: Resolver<
    Maybe<ResolversTypes['ReconnectTiktokAdAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationreconnectTiktokAdAccountArgs, 'input'>
  >;
  refreshPassword?: Resolver<
    Maybe<ResolversTypes['RefreshPasswordPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationrefreshPasswordArgs, 'input'>
  >;
  refreshProfileAccount?: Resolver<
    Maybe<ResolversTypes['RefreshProfileAccountPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationrefreshProfileAccountArgs, 'input'>
  >;
  refreshTwitterAccount?: Resolver<
    Maybe<ResolversTypes['RefreshTwitterAccountPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationrefreshTwitterAccountArgs, 'input'>
  >;
  registerPushToken?: Resolver<
    Maybe<ResolversTypes['RegisterPushToken']>,
    ParentType,
    ContextType,
    RequireFields<MutationregisterPushTokenArgs, 'input'>
  >;
  rejectEngagementDraftPost?: Resolver<
    Maybe<ResolversTypes['RejectEngagementDraftPost']>,
    ParentType,
    ContextType,
    RequireFields<MutationrejectEngagementDraftPostArgs, 'input'>
  >;
  rejectMarketplaceCampaign?: Resolver<
    Maybe<ResolversTypes['RejectMarketplaceCampaign']>,
    ParentType,
    ContextType,
    RequireFields<MutationrejectMarketplaceCampaignArgs, 'input'>
  >;
  rejectMarketplaceDraftPost?: Resolver<
    Maybe<ResolversTypes['RejectMarketplaceDraftPost']>,
    ParentType,
    ContextType,
    RequireFields<MutationrejectMarketplaceDraftPostArgs, 'input'>
  >;
  rejectTiktokSpecialCampaign?: Resolver<
    Maybe<ResolversTypes['RejectTikTokSpecialCampaign']>,
    ParentType,
    ContextType,
    RequireFields<MutationrejectTiktokSpecialCampaignArgs, 'input'>
  >;
  removeAllFormResponses?: Resolver<
    Maybe<ResolversTypes['RemoveAllFormResponsesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationremoveAllFormResponsesArgs, 'input'>
  >;
  removeAllSelectedInfluencers?: Resolver<
    Maybe<ResolversTypes['RemoveAllSelectedInfluencers']>,
    ParentType,
    ContextType,
    RequireFields<MutationremoveAllSelectedInfluencersArgs, 'input'>
  >;
  removeCreatorStaff?: Resolver<
    Maybe<ResolversTypes['RemoveCreatorStaffPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationremoveCreatorStaffArgs, 'input'>
  >;
  removeEmails?: Resolver<
    Maybe<ResolversTypes['RemoveEmailsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationremoveEmailsArgs, 'input'>
  >;
  removeFans?: Resolver<
    Maybe<ResolversTypes['RemoveFansPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationremoveFansArgs, 'input'>
  >;
  removeFormResponse?: Resolver<
    Maybe<ResolversTypes['RemoveFormResponsePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationremoveFormResponseArgs, 'input'>
  >;
  removeForms?: Resolver<
    Maybe<ResolversTypes['RemoveFormsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationremoveFormsArgs, 'input'>
  >;
  removeSelectedInfluencers?: Resolver<
    Maybe<ResolversTypes['RemoveSelectedInfluencers']>,
    ParentType,
    ContextType,
    RequireFields<MutationremoveSelectedInfluencersArgs, 'input'>
  >;
  removeShopifyAccount?: Resolver<
    Maybe<ResolversTypes['RemoveShopifyAccountPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationremoveShopifyAccountArgs, 'input'>
  >;
  reorderMarketplaceCampaigns?: Resolver<
    Maybe<ResolversTypes['ReorderMarketplaceCampaigns']>,
    ParentType,
    ContextType,
    RequireFields<MutationreorderMarketplaceCampaignsArgs, 'campaignIds'>
  >;
  reportEngagementPostStatus?: Resolver<
    Maybe<ResolversTypes['ReportEngagementPostStatus']>,
    ParentType,
    ContextType,
    RequireFields<MutationreportEngagementPostStatusArgs, 'input'>
  >;
  reportMarketplacePostStatus?: Resolver<
    Maybe<ResolversTypes['ReportMarketplacePostStatus']>,
    ParentType,
    ContextType,
    RequireFields<MutationreportMarketplacePostStatusArgs, 'input'>
  >;
  reportTiktokSpecialPostStatus?: Resolver<
    Maybe<ResolversTypes['ReportTiktokSpecialPostStatus']>,
    ParentType,
    ContextType,
    RequireFields<MutationreportTiktokSpecialPostStatusArgs, 'input'>
  >;
  requestDeleteAccount?: Resolver<
    Maybe<ResolversTypes['RequestDeleteAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestDeleteAccountArgs, 'input'>
  >;
  requestGoogleSlideCustomisedReport?: Resolver<
    Maybe<ResolversTypes['RequestGoogleSlideCustomisedReport']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestGoogleSlideCustomisedReportArgs, 'campaignId' | 'campaignType' | 'selectedMetrics'>
  >;
  requestGoogleSlideReport?: Resolver<
    Maybe<ResolversTypes['RequestGoogleSlideReport']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestGoogleSlideReportArgs, 'campaignId' | 'campaignType'>
  >;
  requestInfluencerProfilePptx?: Resolver<
    Maybe<ResolversTypes['RequestInfluencerProfilePptx']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationrequestInfluencerProfilePptxArgs,
      'format' | 'influencerId' | 'socialAccountId' | 'socialMedia'
    >
  >;
  requestInfluencersProfilePptx?: Resolver<
    Maybe<ResolversTypes['RequestInfluencersProfilePptx']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestInfluencersProfilePptxArgs, 'format' | 'influencerIds' | 'socialMedias'>
  >;
  requestInfluencersSocialAccountPptx?: Resolver<
    Maybe<ResolversTypes['RequestInfluencersSocialAccountPptx']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestInfluencersSocialAccountPptxArgs, 'format' | 'influencerSocialAccountIds'>
  >;
  requestInstagramUGCManagementPost?: Resolver<
    ResolversTypes['RequestInstagramUGCManagementPostOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationrequestInstagramUGCManagementPostArgs, 'input'>
  >;
  requestPackageProposalExcel?: Resolver<
    Maybe<ResolversTypes['RequestPackageProposalExcel']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestPackageProposalExcelArgs, 'format' | 'packageId'>
  >;
  requestPackageProposalPptx?: Resolver<
    Maybe<ResolversTypes['RequestPackageProposalPptx']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestPackageProposalPptxArgs, 'format' | 'packageId'>
  >;
  requestPackageSpreadsheet?: Resolver<
    Maybe<ResolversTypes['RequestPackageSpreadsheet']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestPackageSpreadsheetArgs, 'packageId'>
  >;
  requestPowerpointCustomisedReport?: Resolver<
    Maybe<ResolversTypes['RequestPowerpointCustomisedReport']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestPowerpointCustomisedReportArgs, 'campaignId' | 'campaignType' | 'selectedMetrics'>
  >;
  requestPowerpointReport?: Resolver<
    Maybe<ResolversTypes['RequestPowerpointReport']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestPowerpointReportArgs, 'campaignId' | 'campaignType'>
  >;
  requestProposalExcel?: Resolver<
    Maybe<ResolversTypes['RequestProposalExcel']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestProposalExcelArgs, 'engagementId'>
  >;
  requestProposalPptx?: Resolver<
    Maybe<ResolversTypes['RequestProposalPptx']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestProposalPptxArgs, 'engagementId' | 'format'>
  >;
  requestProposalSpreadsheet?: Resolver<
    Maybe<ResolversTypes['RequestProposalSpreadsheet']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestProposalSpreadsheetArgs, 'engagementId'>
  >;
  requestUserProposalExcel?: Resolver<
    Maybe<ResolversTypes['RequestUserProposalExcel']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestUserProposalExcelArgs, 'format'>
  >;
  requestUserProposalPptx?: Resolver<
    Maybe<ResolversTypes['RequestUserProposalPptx']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestUserProposalPptxArgs, 'format'>
  >;
  resendEmailForAdvertiser?: Resolver<
    Maybe<ResolversTypes['ResendEmailForAdvertiser']>,
    ParentType,
    ContextType,
    RequireFields<MutationresendEmailForAdvertiserArgs, 'input'>
  >;
  resendInfluencerForgotPasswordEmail?: Resolver<
    Maybe<ResolversTypes['ResendInfluencerForgotPasswordEmailPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationresendInfluencerForgotPasswordEmailArgs, 'input'>
  >;
  resendInfluencerSignUpEmail?: Resolver<
    Maybe<ResolversTypes['ResendInfluencerSignUpEmailPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationresendInfluencerSignUpEmailArgs, 'input'>
  >;
  resendPartnerEmails?: Resolver<
    Maybe<ResolversTypes['ResendPartnerEmails']>,
    ParentType,
    ContextType,
    RequireFields<MutationresendPartnerEmailsArgs, 'input'>
  >;
  resetPassword?: Resolver<
    Maybe<ResolversTypes['ResetPasswordPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationresetPasswordArgs, 'input'>
  >;
  resetPasswordAndLogin?: Resolver<
    Maybe<ResolversTypes['ResetPasswordAndLoginPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationresetPasswordAndLoginArgs, 'input'>
  >;
  resolveChat?: Resolver<
    Maybe<ResolversTypes['ResolveChatPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationresolveChatArgs, 'input'>
  >;
  sendBcaRequest?: Resolver<
    Maybe<ResolversTypes['SendBcaRequestPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsendBcaRequestArgs, 'input'>
  >;
  sendContact?: Resolver<
    Maybe<ResolversTypes['SendContact']>,
    ParentType,
    ContextType,
    RequireFields<MutationsendContactArgs, 'input'>
  >;
  sendGeminiMessage?: Resolver<
    Maybe<ResolversTypes['SendGeminiMessage']>,
    ParentType,
    ContextType,
    RequireFields<MutationsendGeminiMessageArgs, 'input'>
  >;
  sendLineBroadcastMessage?: Resolver<
    Maybe<ResolversTypes['SendLineBroadcastMessagePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsendLineBroadcastMessageArgs, 'input'>
  >;
  sendMessage?: Resolver<
    Maybe<ResolversTypes['SendMessagePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsendMessageArgs, 'input'>
  >;
  sendOpenAiMessage?: Resolver<
    Maybe<ResolversTypes['SendOpenAIMessage']>,
    ParentType,
    ContextType,
    RequireFields<MutationsendOpenAiMessageArgs, 'input'>
  >;
  shopifyValidateAuth?: Resolver<
    Maybe<ResolversTypes['ShopifyValidateAuthPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationshopifyValidateAuthArgs, 'input'>
  >;
  socialAuthConnectableFacebookPageAnalytics?: Resolver<
    Maybe<ResolversTypes['SocialAuthConnectableFacebookPageAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthConnectableFacebookPageAnalyticsArgs, 'input'>
  >;
  socialAuthConnectableInstagramAccountsAnalytics?: Resolver<
    Maybe<ResolversTypes['SocialAuthConnectableInstagramAccountsAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthConnectableInstagramAccountsAnalyticsArgs, 'input'>
  >;
  socialAuthConnectableTwitterAccountAnalyticsV2?: Resolver<
    ResolversTypes['SocialAuthConnectableTwitterAccountAnalyticsOutput'],
    ParentType,
    ContextType,
    Partial<MutationsocialAuthConnectableTwitterAccountAnalyticsV2Args>
  >;
  socialAuthConnectableYouTubeChannelsAnalyticsV2?: Resolver<
    ReadonlyArray<ResolversTypes['SelectableYoutubeAnalyticsChannel']>,
    ParentType,
    ContextType,
    Partial<MutationsocialAuthConnectableYouTubeChannelsAnalyticsV2Args>
  >;
  socialAuthForJoinCampaign?: Resolver<
    Maybe<ResolversTypes['SocialAuthForJoinCampaignPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthForJoinCampaignArgs, 'input'>
  >;
  socialAuthReConnectInstagram?: Resolver<
    Maybe<ResolversTypes['SocialAuthReConnectInstagramPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthReConnectInstagramArgs, 'input'>
  >;
  socialAuthReconnectFacebookPageAccountAnalytics?: Resolver<
    Maybe<ResolversTypes['SocialAuthReconnectFacebookPageAccountAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthReconnectFacebookPageAccountAnalyticsArgs, 'input'>
  >;
  socialAuthReconnectInstagramAccountAnalytics?: Resolver<
    Maybe<ResolversTypes['SocialAuthReconnectInstagramAccountAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthReconnectInstagramAccountAnalyticsArgs, 'input'>
  >;
  socialAuthReconnectTwitterAccountAnalyticsV2?: Resolver<
    Maybe<ResolversTypes['SocialAuthReconnectTwitterAccountAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthReconnectTwitterAccountAnalyticsV2Args, 'input'>
  >;
  socialAuthReconnectYouTubeAccountAnalyticsV2?: Resolver<
    Maybe<ResolversTypes['SocialAuthReconnectYouTubeAccountAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthReconnectYouTubeAccountAnalyticsV2Args, 'input'>
  >;
  socialAuthTalentSignInFacebook?: Resolver<
    Maybe<ResolversTypes['SocialAuthTalentSignInFacebookPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthTalentSignInFacebookArgs, 'input'>
  >;
  socialAuthTalentSignInInstagram?: Resolver<
    Maybe<ResolversTypes['SocialAuthTalentSignInInstagramPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthTalentSignInInstagramArgs, 'input'>
  >;
  socialAuthTalentSignInTwitter?: Resolver<
    Maybe<ResolversTypes['SocialAuthTalentSignInTwitterPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthTalentSignInTwitterArgs, 'input'>
  >;
  socialAuthTalentSignInYouTube?: Resolver<
    Maybe<ResolversTypes['SocialAuthTalentSignInYouTubePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsocialAuthTalentSignInYouTubeArgs, 'input'>
  >;
  submitForm?: Resolver<
    Maybe<ResolversTypes['SubmitFormPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsubmitFormArgs, 'input'>
  >;
  submitFormInternal?: Resolver<
    Maybe<ResolversTypes['SubmitFormInternalPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationsubmitFormInternalArgs, 'input'>
  >;
  subscribeAnalyticsPlan?: Resolver<
    Maybe<ResolversTypes['SubscribeAnalyticsPlan']>,
    ParentType,
    ContextType,
    RequireFields<MutationsubscribeAnalyticsPlanArgs, 'input'>
  >;
  subscribeAnalyticsTrialPlan?: Resolver<Maybe<ResolversTypes['SubscribeAnalyticsTrialPlan']>, ParentType, ContextType>;
  sudoLimitedLogin?: Resolver<
    Maybe<ResolversTypes['SudoLimitedLogin']>,
    ParentType,
    ContextType,
    RequireFields<MutationsudoLimitedLoginArgs, 'input'>
  >;
  switchInfluencerForStaff?: Resolver<
    Maybe<ResolversTypes['SwitchInfluencerForStaffPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationswitchInfluencerForStaffArgs, 'input'>
  >;
  switchRoleToAdmin?: Resolver<
    Maybe<ResolversTypes['SwitchRoleToAdmin']>,
    ParentType,
    ContextType,
    RequireFields<MutationswitchRoleToAdminArgs, 'input'>
  >;
  switchRoleToAdvertiser?: Resolver<
    Maybe<ResolversTypes['SwitchRoleToAdvertiser']>,
    ParentType,
    ContextType,
    RequireFields<MutationswitchRoleToAdvertiserArgs, 'input'>
  >;
  switchRoleToAgency?: Resolver<
    Maybe<ResolversTypes['SwitchRoleToAgency']>,
    ParentType,
    ContextType,
    RequireFields<MutationswitchRoleToAgencyArgs, 'input'>
  >;
  switchRoleToPartner?: Resolver<
    Maybe<ResolversTypes['SwitchRoleToPartner']>,
    ParentType,
    ContextType,
    RequireFields<MutationswitchRoleToPartnerArgs, 'input'>
  >;
  switchRoleToStaff?: Resolver<
    Maybe<ResolversTypes['SwitchRoleToStaff']>,
    ParentType,
    ContextType,
    RequireFields<MutationswitchRoleToStaffArgs, 'input'>
  >;
  switchRoleToTalentAgency?: Resolver<
    Maybe<ResolversTypes['SwitchRoleToTalentAgency']>,
    ParentType,
    ContextType,
    RequireFields<MutationswitchRoleToTalentAgencyArgs, 'input'>
  >;
  tokenAuth?: Resolver<
    Maybe<ResolversTypes['ObtainJSONWebTokenPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationtokenAuthArgs, 'input'>
  >;
  tokenAuthLongLive?: Resolver<
    Maybe<ResolversTypes['TokenAuthLongLive']>,
    ParentType,
    ContextType,
    RequireFields<MutationtokenAuthLongLiveArgs, 'input'>
  >;
  trackYoutubeMusicDownload?: Resolver<
    Maybe<ResolversTypes['TrackYoutubeMusicDownload']>,
    ParentType,
    ContextType,
    RequireFields<MutationtrackYoutubeMusicDownloadArgs, 'input'>
  >;
  transferMoneyToAdAccount?: Resolver<
    Maybe<ResolversTypes['TransferMoneyToAdAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationtransferMoneyToAdAccountArgs, 'input'>
  >;
  unApproveApplicantInfluencers?: Resolver<
    Maybe<ResolversTypes['UnApproveApplicantInfluencers']>,
    ParentType,
    ContextType,
    RequireFields<MutationunApproveApplicantInfluencersArgs, 'input'>
  >;
  unassignChatPic?: Resolver<
    Maybe<ResolversTypes['UnassignChatPICPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationunassignChatPicArgs, 'input'>
  >;
  unblockInfluencerMarketplace?: Resolver<
    Maybe<ResolversTypes['UnblockInfluencerMarketplace']>,
    ParentType,
    ContextType,
    RequireFields<MutationunblockInfluencerMarketplaceArgs, 'input'>
  >;
  unresolveChat?: Resolver<
    Maybe<ResolversTypes['UnresolveChatPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationunresolveChatArgs, 'input'>
  >;
  updateAccountInformation?: Resolver<
    Maybe<ResolversTypes['UpdateAccountInformationPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAccountInformationArgs, 'input'>
  >;
  updateAddress?: Resolver<
    Maybe<ResolversTypes['UpdateAddress']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAddressArgs, 'input'>
  >;
  updateAddressInformation?: Resolver<
    Maybe<ResolversTypes['UpdateAddressInformationPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAddressInformationArgs, 'input'>
  >;
  updateAdminUser?: Resolver<
    Maybe<ResolversTypes['UpdateAdmin']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAdminUserArgs, 'input'>
  >;
  updateAdvertiser?: Resolver<
    Maybe<ResolversTypes['UpdateAdvertiser']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAdvertiserArgs, 'input'>
  >;
  updateAdvertiserByAdvertiser?: Resolver<
    Maybe<ResolversTypes['UpdateAdvertiser']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAdvertiserByAdvertiserArgs, 'input'>
  >;
  updateAdvertiserUser?: Resolver<
    Maybe<ResolversTypes['UpdateAdvertiserUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAdvertiserUserArgs, 'input'>
  >;
  updateAdvertiserUserSettings?: Resolver<
    Maybe<ResolversTypes['UpdateAdvertiserUserSettings']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAdvertiserUserSettingsArgs, 'input'>
  >;
  updateAgency?: Resolver<
    Maybe<ResolversTypes['UpdatePlainAgency']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAgencyArgs, 'input'>
  >;
  updateAgencyUser?: Resolver<
    Maybe<ResolversTypes['UpdateAgency']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAgencyUserArgs, 'input'>
  >;
  updateAutoManagedAffiliateCommissionRates?: Resolver<
    Maybe<ResolversTypes['UpdateAutoManagedAffiliateCommissionRates']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAutoManagedAffiliateCommissionRatesArgs, 'input'>
  >;
  updateAutoManagedCampaign?: Resolver<
    Maybe<ResolversTypes['UpdateAutoManagedCampaignPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAutoManagedCampaignArgs, 'input'>
  >;
  updateAutoManagedCampaignsStatus?: Resolver<
    Maybe<ResolversTypes['UpdateAutoManagedCampaignsStatusPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateAutoManagedCampaignsStatusArgs, 'input'>
  >;
  updateBioSections?: Resolver<
    Maybe<ResolversTypes['UpdateBioSectionsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateBioSectionsArgs, 'input'>
  >;
  updateCmsAssetShareRate?: Resolver<
    Maybe<ResolversTypes['UpdateCmsAssetShareRate']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateCmsAssetShareRateArgs, 'input'>
  >;
  updateCmsChannelShareRate?: Resolver<
    Maybe<ResolversTypes['UpdateCmsChannelShareRate']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateCmsChannelShareRateArgs, 'input'>
  >;
  updateComment?: Resolver<
    Maybe<ResolversTypes['UpdateCommentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateCommentArgs, 'input'>
  >;
  updateEmailTemplate?: Resolver<
    Maybe<ResolversTypes['UpdateEmailTemplatePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEmailTemplateArgs, 'input'>
  >;
  updateEngagement?: Resolver<
    Maybe<ResolversTypes['UpdateEngagement']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementArgs, 'input'>
  >;
  updateEngagementBreakdownMode?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementBreakDownMode']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementBreakdownModeArgs, 'input'>
  >;
  updateEngagementCouponList?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementCouponList']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementCouponListArgs, 'input'>
  >;
  updateEngagementInfluencerProposalList?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementInfluencerProposalList']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementInfluencerProposalListArgs, 'input'>
  >;
  updateEngagementInfluencersBudget?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementInfluencersBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementInfluencersBudgetArgs, 'input'>
  >;
  updateEngagementPostForInfluencerV2?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementPostForInfluencerV2']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementPostForInfluencerV2Args, 'input'>
  >;
  updateEngagementPostStatus?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementPostStatus']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementPostStatusArgs, 'input'>
  >;
  updateEngagementPostV2?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementPostV2']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementPostV2Args, 'input'>
  >;
  updateEngagementSocialAccountProposalList?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementSocialAccountProposalList']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementSocialAccountProposalListArgs, 'input'>
  >;
  updateEngagementSocialAccountsBudget?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementSocialAccountsBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementSocialAccountsBudgetArgs, 'input'>
  >;
  updateEngagementStatus?: Resolver<
    Maybe<ResolversTypes['UpdateEngagementStatus']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEngagementStatusArgs, 'input'>
  >;
  updateFan?: Resolver<
    Maybe<ResolversTypes['UpdateFanPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateFanArgs, 'input'>
  >;
  updateForm?: Resolver<
    Maybe<ResolversTypes['UpdateFormPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateFormArgs, 'input'>
  >;
  updateFormStatus?: Resolver<
    Maybe<ResolversTypes['UpdateFormStatusPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateFormStatusArgs, 'input'>
  >;
  updateInfluencerBio?: Resolver<
    Maybe<ResolversTypes['UpdateInfluencerBioPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateInfluencerBioArgs, 'input'>
  >;
  updateInfluencerPhoneNumber?: Resolver<
    Maybe<ResolversTypes['UpdateInfluencerPhoneNumberPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateInfluencerPhoneNumberArgs, 'input'>
  >;
  updateInfluencerProfile?: Resolver<
    Maybe<ResolversTypes['UpdateInfluencerProfile']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateInfluencerProfileArgs, 'input'>
  >;
  updateInfluencerRateCard?: Resolver<
    Maybe<ResolversTypes['UpdateInfluencerRateCardPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateInfluencerRateCardArgs, 'input'>
  >;
  updateInfluencerRateCards?: Resolver<
    Maybe<ResolversTypes['UpdateInfluencerRateCardsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateInfluencerRateCardsArgs, 'input'>
  >;
  updateInfluencerRateCardsByStaff?: Resolver<
    Maybe<ResolversTypes['UpdateInfluencerRateCardsByStaff']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateInfluencerRateCardsByStaffArgs, 'input'>
  >;
  updateInfluencerTagsByStaff?: Resolver<
    Maybe<ResolversTypes['UpdateInfluencerTagsByStaff']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateInfluencerTagsByStaffArgs, 'input'>
  >;
  updateInfluencerV2?: Resolver<
    Maybe<ResolversTypes['UpdateInfluencerV2']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateInfluencerV2Args, 'input'>
  >;
  updateInternalMemo?: Resolver<
    Maybe<ResolversTypes['UpdateInternalMemo']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateInternalMemoArgs, 'input'>
  >;
  updateLineBroadcastMessage?: Resolver<
    Maybe<ResolversTypes['UpdateLineBroadcastMessagePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateLineBroadcastMessageArgs, 'input'>
  >;
  updateLinkBioThemeColor?: Resolver<
    Maybe<ResolversTypes['UpdateLinkBioThemeColorPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateLinkBioThemeColorArgs, 'input'>
  >;
  updateMarketplace?: Resolver<
    Maybe<ResolversTypes['UpdateMarketplace']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateMarketplaceArgs, 'input'>
  >;
  updateMarketplaceAffiliateCommissionFees?: Resolver<
    Maybe<ResolversTypes['UpdateMarketplaceAffiliateCommissionFees']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateMarketplaceAffiliateCommissionFeesArgs, 'input'>
  >;
  updateMarketplaceAffiliateCommissionRates?: Resolver<
    Maybe<ResolversTypes['UpdateMarketplaceAffiliateCommissionRates']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateMarketplaceAffiliateCommissionRatesArgs, 'input'>
  >;
  updateMarketplaceCouponList?: Resolver<
    Maybe<ResolversTypes['UpdateMarketplaceCouponList']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateMarketplaceCouponListArgs, 'input'>
  >;
  updateMarketplaceInfluencerCost?: Resolver<
    Maybe<ResolversTypes['UpdateMarketplaceInfluencerCost']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateMarketplaceInfluencerCostArgs, 'input'>
  >;
  updateMarketplacePostStatusV2?: Resolver<
    Maybe<ResolversTypes['UpdateMarketplacePostStatusV2']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateMarketplacePostStatusV2Args, 'input'>
  >;
  updateMarketplacePostTrackingUrl?: Resolver<
    Maybe<ResolversTypes['UpdateMarketplacePostTrackingUrl']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateMarketplacePostTrackingUrlArgs, 'input'>
  >;
  updatePackage?: Resolver<
    Maybe<ResolversTypes['UpdatePackage']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePackageArgs, 'input'>
  >;
  updatePackageProposalInfluencersBudget?: Resolver<
    Maybe<ResolversTypes['UpdatePackageProposalInfluencersBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePackageProposalInfluencersBudgetArgs, 'input'>
  >;
  updatePackageProposalMode?: Resolver<
    Maybe<ResolversTypes['UpdatePackageProposalMode']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePackageProposalModeArgs, 'input'>
  >;
  updatePackageProposalSocialAccountsBudget?: Resolver<
    Maybe<ResolversTypes['UpdatePackageProposalSocialAccountsBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePackageProposalSocialAccountsBudgetArgs, 'input'>
  >;
  updatePartnerAgency?: Resolver<
    Maybe<ResolversTypes['UpdatePartnerAgency']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePartnerAgencyArgs, 'input'>
  >;
  updatePartnerUser?: Resolver<
    Maybe<ResolversTypes['UpdatePartnerUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePartnerUserArgs, 'input'>
  >;
  updatePasswordByAdvertiserUser?: Resolver<
    Maybe<ResolversTypes['UpdatePasswordByAdvertiserUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePasswordByAdvertiserUserArgs, 'input'>
  >;
  updatePaymentInformation?: Resolver<
    Maybe<ResolversTypes['UpdatePaymentInformation']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePaymentInformationArgs, 'input'>
  >;
  updatePaymentStatus?: Resolver<
    Maybe<ResolversTypes['UpdatePaymentStatus']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePaymentStatusArgs, 'input'>
  >;
  updatePersonalUserDetail?: Resolver<
    Maybe<ResolversTypes['UpdatePersoanlUserDetailPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdatePersonalUserDetailArgs, 'input'>
  >;
  updateSocialAccounts?: Resolver<
    Maybe<ResolversTypes['UpdateSocialAccountsPayload']>,
    ParentType,
    ContextType,
    Partial<MutationupdateSocialAccountsArgs>
  >;
  updateSocialUsername?: Resolver<
    Maybe<ResolversTypes['UpdateSocialUsername']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateSocialUsernameArgs, 'input'>
  >;
  updateStaffUser?: Resolver<
    Maybe<ResolversTypes['UpdateStaff']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateStaffUserArgs, 'input'>
  >;
  updateSubscriptionPlan?: Resolver<
    Maybe<ResolversTypes['UpdateSubscriptionPlan']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateSubscriptionPlanArgs, 'input'>
  >;
  updateTalentAgency?: Resolver<
    Maybe<ResolversTypes['UpdateTalentAgency']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateTalentAgencyArgs, 'input'>
  >;
  updateTalentAgencyUser?: Resolver<
    Maybe<ResolversTypes['UpdateTalentAgencyUser']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateTalentAgencyUserArgs, 'input'>
  >;
  updateTalentInfluencer?: Resolver<
    Maybe<ResolversTypes['UpdateTalentInfluencerPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateTalentInfluencerArgs, 'input'>
  >;
  updateTiktokAdvertiserInfo?: Resolver<
    Maybe<ResolversTypes['UpdateTiktokAdvertiserInfo']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateTiktokAdvertiserInfoArgs, 'input'>
  >;
  updateTiktokSpecialCampaign?: Resolver<
    Maybe<ResolversTypes['UpdateTikTokSpecialCampaign']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateTiktokSpecialCampaignArgs, 'input'>
  >;
  updateTiktokSpecialPostStatus?: Resolver<
    Maybe<ResolversTypes['UpdateTiktokSpecialPostStatus']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateTiktokSpecialPostStatusArgs, 'input'>
  >;
  updateTtcmPost?: Resolver<
    Maybe<ResolversTypes['UpdateTtcmPost']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateTtcmPostArgs, 'input'>
  >;
  updateUserDetail?: Resolver<
    Maybe<ResolversTypes['UpdateUserDetailPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateUserDetailArgs, 'input'>
  >;
  updateUserProposalBreakdownMode?: Resolver<
    Maybe<ResolversTypes['UpdateUserProposalBreakdownMode']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateUserProposalBreakdownModeArgs, 'input'>
  >;
  updateUserProposalInfluencersBudget?: Resolver<
    Maybe<ResolversTypes['UpdateUserProposalInfluencersBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateUserProposalInfluencersBudgetArgs, 'input'>
  >;
  updateUserProposalSocialAccountsBudget?: Resolver<
    Maybe<ResolversTypes['UpdateUserProposalSocialAccountsBudget']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateUserProposalSocialAccountsBudgetArgs, 'input'>
  >;
  updateYoutubeMCNInfluencerCredentials?: Resolver<
    Maybe<ResolversTypes['UpdateYoutubeMCNCredentialsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateYoutubeMCNInfluencerCredentialsArgs, 'input'>
  >;
  uploadEngagementReportFile?: Resolver<
    Maybe<ResolversTypes['UploadEngagementReportFile']>,
    ParentType,
    ContextType,
    RequireFields<MutationuploadEngagementReportFileArgs, 'input'>
  >;
  uploadEngagementReportFromInsightCheck?: Resolver<
    Maybe<ResolversTypes['UploadEngagementReportFromInsightCheck']>,
    ParentType,
    ContextType,
    RequireFields<MutationuploadEngagementReportFromInsightCheckArgs, 'input'>
  >;
  uploadEngagementXhsInsightCheck?: Resolver<
    Maybe<ResolversTypes['UploadEngagementXhsInsightCheck']>,
    ParentType,
    ContextType,
    RequireFields<MutationuploadEngagementXhsInsightCheckArgs, 'input'>
  >;
  uploadOpenAiImages?: Resolver<
    Maybe<ResolversTypes['UploadOpenAIImages']>,
    ParentType,
    ContextType,
    RequireFields<MutationuploadOpenAiImagesArgs, 'input'>
  >;
  uuumSocialAuthSignUp?: Resolver<
    Maybe<ResolversTypes['UUUMSocialAuthSignUpPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationuuumSocialAuthSignUpArgs, 'input'>
  >;
  verifyLineIDToken?: Resolver<
    Maybe<ResolversTypes['VerifyLineIDTokenPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationverifyLineIDTokenArgs, 'input'>
  >;
  verifyToken?: Resolver<
    Maybe<ResolversTypes['VerifyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationverifyTokenArgs, 'input'>
  >;
  withdrawMoneyFromAdAccount?: Resolver<
    Maybe<ResolversTypes['WithdrawMoneyFromAdAccount']>,
    ParentType,
    ContextType,
    RequireFields<MutationwithdrawMoneyFromAdAccountArgs, 'input'>
  >;
}>;

export type NeedReconnectForFacebookPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NeedReconnectForFacebookPayload'] = ResolversParentTypes['NeedReconnectForFacebookPayload']
> = ResolversObject<{
  need?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NeedReviewMarketplacePostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NeedReviewMarketplacePost'] = ResolversParentTypes['NeedReviewMarketplacePost']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['MarketplacePostInfluencer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NetSuiteCustomerDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetSuiteCustomerData'] = ResolversParentTypes['NetSuiteCustomerData']
> = ResolversObject<{
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NetSuiteCustomerDataForSearchResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NetSuiteCustomerDataForSearch'] = ResolversParentTypes['NetSuiteCustomerDataForSearch']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NetSuiteVendorDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetSuiteVendorData'] = ResolversParentTypes['NetSuiteVendorData']
> = ResolversObject<{
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NetSuiteVendorDataForSearchResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NetSuiteVendorDataForSearch'] = ResolversParentTypes['NetSuiteVendorDataForSearch']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NewAutoManagedCampaignForInfluencerForYourJobResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NewAutoManagedCampaignForInfluencerForYourJob'] = ResolversParentTypes['NewAutoManagedCampaignForInfluencerForYourJob']
> = ResolversObject<{
  brandName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  hasReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mkpServerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AutoManagedAffiliateCampaignStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NewCampaignPostReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewCampaignPostReport'] = ResolversParentTypes['NewCampaignPostReport']
> = ResolversObject<{
  InternalInfo?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  orderedSummaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignSummaryDaily']>>, ParentType, ContextType>;
  postInfo?: Resolver<ResolversTypes['CampaignPostInfo'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['NewCampaignStatsItemRow'], ParentType, ContextType>;
  summaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CampaignSummaryDaily']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NewCampaignReportSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewCampaignReportSummary'] = ResolversParentTypes['NewCampaignReportSummary']
> = ResolversObject<{
  campaignSocialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  like?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NewCampaignStatsItemRowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewCampaignStatsItemRow'] = ResolversParentTypes['NewCampaignStatsItemRow']
> = ResolversObject<{
  age35UpPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age1824Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age2534Percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViewDurationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageViewTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  back?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  costsPerAction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerClick?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerImpressions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerReach?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaleReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaleTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerSaved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dislikes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exited?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  femalePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followerReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  followerReachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  forward?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  impressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromDiscovery?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHashtag?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromHome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromOther?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impressionsFromProfile?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  like?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  linkClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  malePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  navigation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  net?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  newFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextStory?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nonFollowerReachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profileActivity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profitRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesReferral?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTune?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stickerTaps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPlayTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoCompletionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromFollowing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromHome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromOther?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromProfile?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  viewsFromSearch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NewEngagementCampaignForInfluencerForYourJobResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NewEngagementCampaignForInfluencerForYourJob'] = ResolversParentTypes['NewEngagementCampaignForInfluencerForYourJob']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  hasDraftPosts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasWarningPosts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isTtcmCampaign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rewardAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AnyXEngagementCampaignStatus'], ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ttcmStatus?: Resolver<Maybe<ResolversTypes['TTCMStatus']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NewMarketplaceCampaignForInfluencerForYourJobResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NewMarketplaceCampaignForInfluencerForYourJob'] = ResolversParentTypes['NewMarketplaceCampaignForInfluencerForYourJob']
> = ResolversObject<{
  appliedStatus?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignAppliedStatus']>, ParentType, ContextType>;
  campaignDetailType?: Resolver<ResolversTypes['MarketplaceCampaignDetailType'], ParentType, ContextType>;
  campaignStatus?: Resolver<Maybe<ResolversTypes['CampaignStatusForInfluencer']>, ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  hasPostsCouponMissing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isApplying?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAutoInfluencerApproval?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isGiftingCampaign?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPostTracking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isSelectionCampaign?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isWaiting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mkpServerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preLaunchDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  remainingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerAction?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerClick?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerPost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revenuePerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['AnyXMarketplaceCampaignStatus']>, ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NewTiktokSpecialCampaignForInfluencerForYourJobResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NewTiktokSpecialCampaignForInfluencerForYourJob'] = ResolversParentTypes['NewTiktokSpecialCampaignForInfluencerForYourJob']
> = ResolversObject<{
  campaignStatus?: Resolver<ResolversTypes['CampaignStatusForInfluencer'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  hasReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mkpServerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  preLaunchDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  revenuePerPost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['CampaignSocialMediaType'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPayload'] = ResolversParentTypes['NotificationPayload']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  isClicked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationId?: Resolver<ResolversTypes['NotificationId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationsPayload'] = ResolversParentTypes['NotificationsPayload']
> = ResolversObject<{
  checkpoint?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notifications?: Resolver<ReadonlyArray<ResolversTypes['NotificationPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ObtainJSONWebTokenPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ObtainJSONWebTokenPayload'] = ResolversParentTypes['ObtainJSONWebTokenPayload']
> = ResolversObject<{
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  subscriptionPaymentFailed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OpenAIMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpenAIMessage'] = ResolversParentTypes['OpenAIMessage']
> = ResolversObject<{
  influencerIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  jsonResponses?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  nextAction?: Resolver<ResolversTypes['OpenAINextAction'], ParentType, ContextType>;
  runId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threadId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OperatedUserInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OperatedUserInfo'] = ResolversParentTypes['OperatedUserInfo']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PackageCompanyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackageCompany'] = ResolversParentTypes['PackageCompany']
> = ResolversObject<{
  companyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PackageInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackageInfluencer'] = ResolversParentTypes['PackageInfluencer']
> = ResolversObject<{
  accountList?: Resolver<ReadonlyArray<ResolversTypes['PackageSocialAccount']>, ParentType, ContextType>;
  addedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['PackageSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PackageProposalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackageProposal'] = ResolversParentTypes['PackageProposal']
> = ResolversObject<{
  influencers?: Resolver<ReadonlyArray<ResolversTypes['PackageInfluencer']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PackageProposalSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackageProposalSummary'] = ResolversParentTypes['PackageProposalSummary']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  jdMode?: Resolver<ResolversTypes['ProposalJobDescriptionBreakDownMode'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['PackageSummaryV2'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PackageSocialAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackageSocialAccount'] = ResolversParentTypes['PackageSocialAccount']
> = ResolversObject<{
  addedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['PackageSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PackageSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackageSummary'] = ResolversParentTypes['PackageSummary']
> = ResolversObject<{
  averageEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  budget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementFollowerRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementViewRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PackageSummaryV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackageSummaryV2'] = ResolversParentTypes['PackageSummaryV2']
> = ResolversObject<{
  avgEngagementPosted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgEngagementProposed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  budget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementFollowerRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  influencerCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ParticipationFormResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ParticipationForm'] = ResolversParentTypes['ParticipationForm']
> = ResolversObject<{
  formName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submissionId?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  submittedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Partner'] = ResolversParentTypes['Partner']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartnerAgencyUserWithRoleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PartnerAgencyUserWithRole'] = ResolversParentTypes['PartnerAgencyUserWithRole']
> = ResolversObject<{
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartnerAgencyWithCompaniesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PartnerAgencyWithCompanies'] = ResolversParentTypes['PartnerAgencyWithCompanies']
> = ResolversObject<{
  advertiserIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  agencyIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryName']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerCostPermission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privateInfluencers?: Resolver<ReadonlyArray<ResolversTypes['PrivatePartnerInfluencer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartnerAgencyWithNSVerificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PartnerAgencyWithNSVerification'] = ResolversParentTypes['PartnerAgencyWithNSVerification']
> = ResolversObject<{
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryName']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  verifiedStatus?: Resolver<Maybe<ResolversTypes['NetSuiteVerifiedStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartnerAgencyWithPermissionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PartnerAgencyWithPermission'] = ResolversParentTypes['PartnerAgencyWithPermission']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerCostPermission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartnerReconnectAnalyticsSocialAuthRedirectUrlPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload'] = ResolversParentTypes['PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload']
> = ResolversObject<{
  redirectUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PayableCampaignResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayableCampaign'] = ResolversParentTypes['PayableCampaign']
> = ResolversObject<{
  campaignType?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  socialMedia?: Resolver<Maybe<ResolversTypes['SocialAccountType']>, ParentType, ContextType>;
  thumbNail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  grossRevenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['PaymentInfluencerAccount'], ParentType, ContextType>;
  netRevenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paidDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  requestedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PaymentRequestStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentInfluencerAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentInfluencerAccount'] = ResolversParentTypes['PaymentInfluencerAccount']
> = ResolversObject<{
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentInformation'] = ResolversParentTypes['PaymentInformation']
> = ResolversObject<{
  info?: Resolver<Maybe<ResolversTypes['PaymentInformationDetails']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentInformationDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PaymentInformationDetails'] = ResolversParentTypes['PaymentInformationDetails']
> = ResolversObject<{
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bankName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  branchName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentPackageStatusResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PaymentPackageStatusResponse'] = ResolversParentTypes['PaymentPackageStatusResponse']
> = ResolversObject<{
  failureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  failureMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PaymentPackageStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentRequest'] = ResolversParentTypes['PaymentRequest']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  grossRevenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minimumPaymentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PersonalUserDetailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PersonalUserDetailPayload'] = ResolversParentTypes['PersonalUserDetailPayload']
> = ResolversObject<{
  displayLanguage?: Resolver<Maybe<ResolversTypes['DisplayLanguage']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAvailableCountry?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationSetting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PicNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PicName'] = ResolversParentTypes['PicName']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PicUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PicUser'] = ResolversParentTypes['PicUser']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlainTextMessagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlainTextMessagePayload'] = ResolversParentTypes['PlainTextMessagePayload']
> = ResolversObject<{
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PostByHashtagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PostByHashtag'] = ResolversParentTypes['PostByHashtag']
> = ResolversObject<{
  hashtagId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['PostsForHashtagDashboard']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PostsByTimeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PostsByTime'] = ResolversParentTypes['PostsByTime']
> = ResolversObject<{
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  postsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PostsForHashtagDashboardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PostsForHashtagDashboard'] = ResolversParentTypes['PostsForHashtagDashboard']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PostsMetricsGridReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PostsMetricsGridReport'] = ResolversParentTypes['PostsMetricsGridReport']
> = ResolversObject<{
  averageMetricRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['CampaignPostReportGrid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PricingTableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PricingTable'] = ResolversParentTypes['PricingTable']
> = ResolversObject<{
  plans?: Resolver<ReadonlyArray<ResolversTypes['PricingTableItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PricingTableItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PricingTableItem'] = ResolversParentTypes['PricingTableItem']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isCurrentPlan?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mkpCampaignEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['PaymentPlanName'], ParentType, ContextType>;
  numberOfInfluencers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  packageId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  priceType?: Resolver<ResolversTypes['PaymentPriceType'], ParentType, ContextType>;
  trialDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PrivatePartnerInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrivatePartnerInfluencer'] = ResolversParentTypes['PrivatePartnerInfluencer']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PrivateTalentInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrivateTalentInfluencer'] = ResolversParentTypes['PrivateTalentInfluencer']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodEmailNewsLetterForProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodEmailNewsLetterForProfile'] = ResolversParentTypes['PromotionMethodEmailNewsLetterForProfile']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodEmailNewsLetterPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodEmailNewsLetterPayload'] = ResolversParentTypes['PromotionMethodEmailNewsLetterPayload']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodMobileAppForProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodMobileAppForProfile'] = ResolversParentTypes['PromotionMethodMobileAppForProfile']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mobileAppType?: Resolver<Maybe<ResolversTypes['PromotionMobileAppType']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodMobileAppPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodMobileAppPayload'] = ResolversParentTypes['PromotionMethodMobileAppPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mobileAppType?: Resolver<Maybe<ResolversTypes['PromotionMobileAppType']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodOfflineForProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodOfflineForProfile'] = ResolversParentTypes['PromotionMethodOfflineForProfile']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodOfflinePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodOfflinePayload'] = ResolversParentTypes['PromotionMethodOfflinePayload']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodPodCastForProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodPodCastForProfile'] = ResolversParentTypes['PromotionMethodPodCastForProfile']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodPodCastPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodPodCastPayload'] = ResolversParentTypes['PromotionMethodPodCastPayload']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodWebsiteForProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodWebsiteForProfile'] = ResolversParentTypes['PromotionMethodWebsiteForProfile']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionMethodWebsitePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PromotionMethodWebsitePayload'] = ResolversParentTypes['PromotionMethodWebsitePayload']
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProposalInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProposalInfluencer'] = ResolversParentTypes['ProposalInfluencer']
> = ResolversObject<{
  accountList?: Resolver<ReadonlyArray<ResolversTypes['ProposalSocialAccount']>, ParentType, ContextType>;
  addedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  couponCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deniedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isDenied?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  jdMode?: Resolver<ResolversTypes['ProposalJobDescriptionBreakDownMode'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reasons?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requirements?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['ProposalJobSummary'], ParentType, ContextType>;
  ttcmCampaignCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ttcmCampaignInviteLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ttcmStatus?: Resolver<Maybe<ResolversTypes['TTCMStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProposalJobSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProposalJobSummary'] = ResolversParentTypes['ProposalJobSummary']
> = ResolversObject<{
  adAgencyMarginRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adAgencyMarginValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  budget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementFollowerRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementViewRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marginRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netBudget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProposalSocialAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProposalSocialAccount'] = ResolversParentTypes['ProposalSocialAccount']
> = ResolversObject<{
  addedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['EngagementProposalSocialMediaType'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['ProposalJobSummary'], ParentType, ContextType>;
  ttcmCampaignInviteLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ttcmStatus?: Resolver<Maybe<ResolversTypes['TTCMStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProvinceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Province'] = ResolversParentTypes['Province']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProvincesPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProvincesPayload'] = ResolversParentTypes['ProvincesPayload']
> = ResolversObject<{
  provinces?: Resolver<ReadonlyArray<ResolversTypes['Province']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PublicBioSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublicBioSection'] = ResolversParentTypes['PublicBioSection']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['BioImage']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['BioUrlLink']>, ParentType, ContextType>;
  sectionType?: Resolver<ResolversTypes['BioSectionType'], ParentType, ContextType>;
  socialMedia?: Resolver<Maybe<ResolversTypes['BioSocialMedia']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['BioText']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PublicBioThemeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublicBioTheme'] = ResolversParentTypes['PublicBioTheme']
> = ResolversObject<{
  bg?: Resolver<ResolversTypes['PublicLinkBioBackground'], ParentType, ContextType>;
  button?: Resolver<Maybe<ResolversTypes['BioButtonStyle']>, ParentType, ContextType>;
  color?: Resolver<ResolversTypes['PublicLinkBioPageColor'], ParentType, ContextType>;
  font?: Resolver<Maybe<ResolversTypes['BioFontStyle']>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['BioTheme']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PublicLinkBioBackgroundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublicLinkBioBackground'] = ResolversParentTypes['PublicLinkBioBackground']
> = ResolversObject<{
  bgColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PublicLinkBioPageColorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublicLinkBioPageColor'] = ResolversParentTypes['PublicLinkBioPageColor']
> = ResolversObject<{
  buttonColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buttonFontColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fontColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PublishInfluencerBioPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PublishInfluencerBioPayload'] = ResolversParentTypes['PublishInfluencerBioPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = ResolversObject<{
  _service?: Resolver<ResolversTypes['_Service'], ParentType, ContextType>;
  accountAnalyticDetailsV2?: Resolver<
    ResolversTypes['AccountAnalyticsDetail'],
    ParentType,
    ContextType,
    RequireFields<QueryaccountAnalyticDetailsV2Args, 'id'>
  >;
  accountAnalyticsBasicDetailsV2?: Resolver<
    ResolversTypes['BasicAccountAnalyticsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryaccountAnalyticsBasicDetailsV2Args, 'brandAccountId'>
  >;
  address?: Resolver<
    Maybe<ResolversTypes['Address']>,
    ParentType,
    ContextType,
    RequireFields<QueryaddressArgs, 'influencerId'>
  >;
  addressForInfluencer?: Resolver<Maybe<ResolversTypes['AddressForInfluencer']>, ParentType, ContextType>;
  advertiser?: Resolver<
    Maybe<ResolversTypes['Advertiser']>,
    ParentType,
    ContextType,
    RequireFields<QueryadvertiserArgs, 'pk'>
  >;
  advertiserForAdvertiserUser?: Resolver<Maybe<ResolversTypes['AdvertiserForAdvertiserUser']>, ParentType, ContextType>;
  advertiserUserSettingsDetails?: Resolver<
    Maybe<ResolversTypes['AdvertiserUserSettingsDetails']>,
    ParentType,
    ContextType
  >;
  agency?: Resolver<
    Maybe<ResolversTypes['AgencyWithAdvertisers']>,
    ParentType,
    ContextType,
    RequireFields<QueryagencyArgs, 'pk'>
  >;
  agencyBusinessAssociateCompanies?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AssociateCompany']>>,
    ParentType,
    ContextType,
    RequireFields<QueryagencyBusinessAssociateCompaniesArgs, 'agencyId' | 'limit' | 'offset'>
  >;
  agencyBusinessAssociateCompaniesCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryagencyBusinessAssociateCompaniesCountArgs, 'agencyId'>
  >;
  agencyLogo?: Resolver<
    Maybe<ResolversTypes['AgencyLogo']>,
    ParentType,
    ContextType,
    RequireFields<QueryagencyLogoArgs, 'hash'>
  >;
  aiChatModel?: Resolver<ResolversTypes['AiChatModelName'], ParentType, ContextType>;
  allAccountAnalyticsCountForAdminV2?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    Partial<QueryallAccountAnalyticsCountForAdminV2Args>
  >;
  allAccountAnalyticsForAdminV2?: Resolver<
    ResolversTypes['AllAccountAnalyticsForAdminPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryallAccountAnalyticsForAdminV2Args, 'limit' | 'offset'>
  >;
  allAccountAnalyticsForAdvertiserV2?: Resolver<
    ResolversTypes['AllAccountAnalyticsForAdvertiserPayload'],
    ParentType,
    ContextType,
    Partial<QueryallAccountAnalyticsForAdvertiserV2Args>
  >;
  allAdvertiserPics?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AdvertiserUserWithRole']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallAdvertiserPicsArgs, 'advertiserId'>
  >;
  allAdvertisers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AdvertiserForList']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallAdvertisersArgs, 'limit' | 'offset'>
  >;
  allAdvertisersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallAdvertisersCountArgs>
  >;
  allAdvertisersForSearch?: Resolver<
    ResolversTypes['AllAdvertisersForSearch'],
    ParentType,
    ContextType,
    Partial<QueryallAdvertisersForSearchArgs>
  >;
  allAgencies?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AgencyWithNSVerification']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallAgenciesArgs, 'limit' | 'offset'>
  >;
  allAgenciesCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallAgenciesCountArgs>
  >;
  allAgenciesForSearch?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AgenciesForSearch']>>, ParentType, ContextType>;
  allAgencyUsers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AgencyUserWithRole']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallAgencyUsersArgs, 'agencyId'>
  >;
  allAnalyticsSubscriptionPlans?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AnalyticsSubscriptionPlanName']>>,
    ParentType,
    ContextType
  >;
  allAnycreatorCategories?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AnyCreatorCategory']>>,
    ParentType,
    ContextType
  >;
  allApplicantInfluencersForMarketplace?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ApplicantInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallApplicantInfluencersForMarketplaceArgs, 'id' | 'limit' | 'offset'>
  >;
  allApplicantInfluencersForMarketplaceCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallApplicantInfluencersForMarketplaceCountArgs, 'id'>
  >;
  allAvailableCountries?: Resolver<ReadonlyArray<ResolversTypes['CountryAndCurrency']>, ParentType, ContextType>;
  allBankBranches?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['BankBranch']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryallBankBranchesArgs, 'bankId'>
  >;
  allBankBranchesForInfluencer?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['BankBranchForInfluencer']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryallBankBranchesForInfluencerArgs, 'bankId'>
  >;
  allBanks?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Bank']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryallBanksArgs, 'countryId'>
  >;
  allBanksForInfluencer?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['BankForInfluencer']>>>,
    ParentType,
    ContextType
  >;
  allCampaignCategories?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['CampaignCategoryName']>>,
    ParentType,
    ContextType
  >;
  allCampaignPosts?: Resolver<
    ResolversTypes['CampaignPosts'],
    ParentType,
    ContextType,
    RequireFields<
      QueryallCampaignPostsArgs,
      | 'advertiserIds'
      | 'campaignTypes'
      | 'campaigns'
      | 'categoryIds'
      | 'countryIds'
      | 'influencerIds'
      | 'limit'
      | 'offset'
      | 'socialPostTypes'
    >
  >;
  allCampaignsForFilter?: Resolver<
    ResolversTypes['CampaignForPostFilter'],
    ParentType,
    ContextType,
    Partial<QueryallCampaignsForFilterArgs>
  >;
  allCampaignsForSearch?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['CampaignForSearch']>>,
    ParentType,
    ContextType,
    Partial<QueryallCampaignsForSearchArgs>
  >;
  allCampaignsForYourJob?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['CampaignForInfluencerForYourJob']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallCampaignsForYourJobArgs, 'isFinished'>
  >;
  allCampaignsForYourJobCounts?: Resolver<
    Maybe<ResolversTypes['CampaignForInfluencerForYourJobCounts']>,
    ParentType,
    ContextType
  >;
  allCampaignsReport?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['CampaignDetailReport']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallCampaignsReportArgs, 'limit' | 'offset'>
  >;
  allCampaignsSummary?: Resolver<
    Maybe<ResolversTypes['AllCampaignsReportSummary']>,
    ParentType,
    ContextType,
    Partial<QueryallCampaignsSummaryArgs>
  >;
  allCategories?: Resolver<Maybe<ReadonlyArray<ResolversTypes['MajorCategory']>>, ParentType, ContextType>;
  allCities?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['City']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryallCitiesArgs, 'countryId'>
  >;
  allCitiesForInfluencer?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['CityForInfluencer']>>>,
    ParentType,
    ContextType
  >;
  allConnectedFacebookPages?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ConnectedFacebookPage']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallConnectedFacebookPagesArgs, 'pk'>
  >;
  allCountries?: Resolver<ReadonlyArray<ResolversTypes['CountryAndCurrency']>, ParentType, ContextType>;
  allDouyinInfluencers?: Resolver<
    ResolversTypes['DouyinInfluencerSearchResult'],
    ParentType,
    ContextType,
    RequireFields<QueryallDouyinInfluencersArgs, 'limit' | 'offset'>
  >;
  allEngagementPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallEngagementPostsArgs, 'engagementId' | 'limit' | 'offset'>
  >;
  allEngagementPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallEngagementPostsCountArgs, 'engagementId'>
  >;
  allEngagementPostsForInfluencer?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementPostForInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallEngagementPostsForInfluencerArgs, 'engagementId' | 'limit' | 'offset'>
  >;
  allEngagementPostsForInsightCheck?: Resolver<
    Maybe<ResolversTypes['EngagementCampaignPostsForInsightCheck']>,
    ParentType,
    ContextType,
    RequireFields<QueryallEngagementPostsForInsightCheckArgs, 'campaignId'>
  >;
  allEngagements?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['Engagement']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallEngagementsArgs, 'limit' | 'offset'>
  >;
  allEngagementsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallEngagementsCountArgs>
  >;
  allEngagementsForInsightCheck?: Resolver<
    Maybe<ResolversTypes['EngagementsForInsightCheck']>,
    ParentType,
    ContextType,
    RequireFields<QueryallEngagementsForInsightCheckArgs, 'limit' | 'offset'>
  >;
  allEngagementsForInsightCheckCount?: Resolver<
    Maybe<ResolversTypes['EngagementsForInsightCheckCount']>,
    ParentType,
    ContextType,
    Partial<QueryallEngagementsForInsightCheckCountArgs>
  >;
  allExistingPackages?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ReadPackage']>>, ParentType, ContextType>;
  allFacebookAccountInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['FacebookInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallFacebookAccountInfluencersArgs, 'limit' | 'offset'>
  >;
  allFacebookAccountInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallFacebookAccountInfluencersCountArgs>
  >;
  allFacebookAccountInfluencersV2?: Resolver<
    ResolversTypes['FacebookAccountInfluencerSearchResultV2'],
    ParentType,
    ContextType,
    RequireFields<QueryallFacebookAccountInfluencersV2Args, 'limit' | 'offset'>
  >;
  allFacebookPageInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['FacebookPageInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallFacebookPageInfluencersArgs, 'limit' | 'offset'>
  >;
  allFacebookPageInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallFacebookPageInfluencersCountArgs>
  >;
  allFacebookPageInfluencersV2?: Resolver<
    ResolversTypes['FacebookPageInfluencerSearchResultV2'],
    ParentType,
    ContextType,
    RequireFields<QueryallFacebookPageInfluencersV2Args, 'limit' | 'offset'>
  >;
  allInfluencerAccountPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerSocialPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallInfluencerAccountPostsArgs, 'limit' | 'offset' | 'socialAccountId' | 'socialPostType'>
  >;
  allInfluencerAccountPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInfluencerAccountPostsCountArgs, 'socialAccountId' | 'socialPostType'>
  >;
  allInfluencerCategories?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CategoryName']>>, ParentType, ContextType>;
  allInfluencerManagers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerManagerOutput']>>,
    ParentType,
    ContextType
  >;
  allInfluencerPostsV3?: Resolver<
    ResolversTypes['InfluencerPostsV3'],
    ParentType,
    ContextType,
    RequireFields<QueryallInfluencerPostsV3Args, 'limit' | 'offset'>
  >;
  allInfluencersCountForMarketplace?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInfluencersCountForMarketplaceArgs, 'id'>
  >;
  allInfluencersForMarketplaceV2?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplaceInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallInfluencersForMarketplaceV2Args, 'id' | 'limit' | 'offset'>
  >;
  allInfluencersV4?: Resolver<
    ResolversTypes['AllInfluencerV4'],
    ParentType,
    ContextType,
    RequireFields<QueryallInfluencersV4Args, 'limit' | 'offset'>
  >;
  allInstagramAmbassadorPosts?: Resolver<
    Maybe<ResolversTypes['InstagramBrandAmbassadorPosts']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramAmbassadorPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  allInstagramAmbassadorPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramAmbassadorPostsCountArgs, 'accountId'>
  >;
  allInstagramCompareFeedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramCompareFeedPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramCompareFeedPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  allInstagramCompareFeedPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramCompareFeedPostsCountArgs, 'accountId'>
  >;
  allInstagramComparePostsInDate?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsSummaryPostsInDate']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramComparePostsInDateArgs, 'accountId' | 'comparableAccountIds' | 'postDate'>
  >;
  allInstagramFeedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramFeedPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramFeedPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  allInstagramFeedPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramFeedPostsCountArgs, 'accountId'>
  >;
  allInstagramHashtagFeedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramHashtagFeedPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramHashtagFeedPostsArgs, 'hashtagId' | 'limit' | 'offset'>
  >;
  allInstagramHashtagFeedPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramHashtagFeedPostsCountArgs, 'hashtagId'>
  >;
  allInstagramInfluencersV3?: Resolver<
    ResolversTypes['InstagramInfluencerSearchResultV3'],
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramInfluencersV3Args, 'limit' | 'offset'>
  >;
  allInstagramInteractionUsers?: Resolver<
    ResolversTypes['InstagramInteractionUserAnalyticsDetailsAndCount'],
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramInteractionUsersArgs, 'accountId' | 'limit' | 'offset'>
  >;
  allInstagramMentionedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramInteractionPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramMentionedPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  allInstagramMentionedPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramMentionedPostsCountArgs, 'accountId'>
  >;
  allInstagramPostsInDate?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsPostsInDate']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramPostsInDateArgs, 'accountId' | 'postDate'>
  >;
  allInstagramReelPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramReelPostItem']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramReelPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  allInstagramReelPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramReelPostsCountArgs, 'accountId'>
  >;
  allInstagramSimilarInfluencersV3?: Resolver<
    ReadonlyArray<ResolversTypes['InstagramInfluencerV3']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramSimilarInfluencersV3Args, 'limit'>
  >;
  allInstagramStoryPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramStoryPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramStoryPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  allInstagramStoryPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramStoryPostsCountArgs, 'accountId'>
  >;
  allInstagramTaggedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramInteractionTaggedPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramTaggedPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  allInstagramTaggedPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallInstagramTaggedPostsCountArgs, 'accountId'>
  >;
  allMarketplacePosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplacePost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallMarketplacePostsArgs, 'limit' | 'marketplaceId' | 'offset'>
  >;
  allMarketplacePostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallMarketplacePostsCountArgs, 'marketplaceId'>
  >;
  allMarketplaces?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['Marketplace']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallMarketplacesArgs, 'limit' | 'offset'>
  >;
  allMarketplacesCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallMarketplacesCountArgs>
  >;
  allMarketplacesForReorder?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplaceForReorder']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallMarketplacesForReorderArgs, 'limit' | 'offset'>
  >;
  allNeedReviewMarketplacePosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['NeedReviewMarketplacePost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallNeedReviewMarketplacePostsArgs, 'marketplaceId'>
  >;
  allPackageCompanies?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PackageCompany']>>, ParentType, ContextType>;
  allPackages?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ReadPackageAccount']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallPackagesArgs, 'limit' | 'offset'>
  >;
  allPackagesCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallPackagesCountArgs>
  >;
  allPartnerAgenciesCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallPartnerAgenciesCountArgs>
  >;
  allPartnerAgencyCompany?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PartnerAgencyWithNSVerification']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallPartnerAgencyCompanyArgs, 'limit' | 'offset'>
  >;
  allPartnerAgencyUsers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PartnerAgencyUserWithRole']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallPartnerAgencyUsersArgs, 'partnerAgencyId'>
  >;
  allPartners?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Partner']>>, ParentType, ContextType>;
  allPayments?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['Payment']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallPaymentsArgs, 'limit' | 'offset'>
  >;
  allPaymentsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallPaymentsCountArgs>
  >;
  allPicUsers?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PicUser']>>, ParentType, ContextType>;
  allRegions?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Region']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryallRegionsArgs, 'countryId'>
  >;
  allStaffs?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AllStaffDetail']>>,
    ParentType,
    ContextType,
    Partial<QueryallStaffsArgs>
  >;
  allStaffsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallStaffsCountArgs>
  >;
  allTalentAgencies?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TalentAgency']>>, ParentType, ContextType>;
  allTalentAgenciesCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallTalentAgenciesCountArgs>
  >;
  allTalentAgenciesPartners?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TalentAgencyOrPartner']>>,
    ParentType,
    ContextType
  >;
  allTalentAgencyCompany?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TalentAgencyWithNSVerification']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallTalentAgencyCompanyArgs, 'limit' | 'offset'>
  >;
  allTalentAgencyUsers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TalentAgencyUserWithRole']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallTalentAgencyUsersArgs, 'talentAgencyId'>
  >;
  allThreadsInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ThreadsInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallThreadsInfluencersArgs, 'limit' | 'offset'>
  >;
  allThreadsInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallThreadsInfluencersCountArgs>
  >;
  allTiktokAdReportSummary?: Resolver<
    Maybe<ResolversTypes['AllTiktokAdReportSummary']>,
    ParentType,
    ContextType,
    RequireFields<QueryallTiktokAdReportSummaryArgs, 'adReportType'>
  >;
  allTiktokAdsReport?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TiktokAdDetailReport']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallTiktokAdsReportArgs, 'adReportType' | 'limit' | 'offset'>
  >;
  allTiktokInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TiktokInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallTiktokInfluencersArgs, 'limit' | 'offset'>
  >;
  allTiktokInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallTiktokInfluencersCountArgs>
  >;
  allTiktokInfluencersV2?: Resolver<
    ResolversTypes['TiktokInfluencerSearchResultV2'],
    ParentType,
    ContextType,
    RequireFields<QueryallTiktokInfluencersV2Args, 'limit' | 'offset'>
  >;
  allTiktokProductCategory?: Resolver<ReadonlyArray<ResolversTypes['TikTokProductCategory']>, ParentType, ContextType>;
  allTiktokSimilarInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TiktokInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallTiktokSimilarInfluencersArgs, 'accountId' | 'limit'>
  >;
  allTiktokSpecialCampaigns?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TikTokSpecialCampaign']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallTiktokSpecialCampaignsArgs, 'limit' | 'offset'>
  >;
  allTiktokSpecialCampaignsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallTiktokSpecialCampaignsCountArgs>
  >;
  allTwitterInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TwitterInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallTwitterInfluencersArgs, 'limit' | 'offset'>
  >;
  allTwitterInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallTwitterInfluencersCountArgs>
  >;
  allTwitterSimilarInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TwitterInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallTwitterSimilarInfluencersArgs, 'accountId' | 'limit'>
  >;
  allUsers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AllUserDetail']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallUsersArgs, 'limit' | 'offset'>
  >;
  allUsersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallUsersCountArgs>
  >;
  allXHSInfluencers?: Resolver<
    ResolversTypes['XhsInfluencerSearchResult'],
    ParentType,
    ContextType,
    RequireFields<QueryallXHSInfluencersArgs, 'limit' | 'offset'>
  >;
  allYoutubeCmsConfirmedAssets?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['YoutubeCmsConfirmedAsset']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallYoutubeCmsConfirmedAssetsArgs, 'limit' | 'month' | 'offset'>
  >;
  allYoutubeCmsConfirmedAssetsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallYoutubeCmsConfirmedAssetsCountArgs, 'month'>
  >;
  allYoutubeCmsConfirmedChannels?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['YoutubeCmsConfirmedChannel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallYoutubeCmsConfirmedChannelsArgs, 'limit' | 'month' | 'offset'>
  >;
  allYoutubeCmsConfirmedChannelsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryallYoutubeCmsConfirmedChannelsCountArgs, 'month'>
  >;
  allYoutubeCmsEstimateAssets?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['YoutubeCmsEstimateAsset']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallYoutubeCmsEstimateAssetsArgs, 'limit' | 'offset'>
  >;
  allYoutubeCmsEstimateAssetsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallYoutubeCmsEstimateAssetsCountArgs>
  >;
  allYoutubeCmsEstimateChannels?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['YoutubeCmsEstimateChannel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallYoutubeCmsEstimateChannelsArgs, 'limit' | 'offset'>
  >;
  allYoutubeCmsEstimateChannelsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    Partial<QueryallYoutubeCmsEstimateChannelsCountArgs>
  >;
  allYoutubeInfluencersV3?: Resolver<
    ResolversTypes['YoutubeInfluencerSearchResultV3'],
    ParentType,
    ContextType,
    RequireFields<QueryallYoutubeInfluencersV3Args, 'limit' | 'offset'>
  >;
  allYoutubeSimilarInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['YouTubeInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryallYoutubeSimilarInfluencersArgs, 'accountId' | 'limit'>
  >;
  analyticsSocialAuthRedirectUrl?: Resolver<
    Maybe<ResolversTypes['AnalyticsSocialAuthRedirectUrlPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryanalyticsSocialAuthRedirectUrlArgs, 'callbackUrl' | 'provider'>
  >;
  analyticsSubscriptionPlan?: Resolver<
    Maybe<ResolversTypes['AnalyticsSubscriptionPlan']>,
    ParentType,
    ContextType,
    RequireFields<QueryanalyticsSubscriptionPlanArgs, 'accountId'>
  >;
  analyticsSubscriptionPlanByAdvId?: Resolver<
    Maybe<ResolversTypes['AnalyticsSubscriptionPlan']>,
    ParentType,
    ContextType,
    RequireFields<QueryanalyticsSubscriptionPlanByAdvIdArgs, 'advId'>
  >;
  anyXCheckConnectedSocialAccounts?: Resolver<
    Maybe<ResolversTypes['AnyXCheckConnectedSocialAccountsPayload']>,
    ParentType,
    ContextType
  >;
  anyXCheckConnectedSocialAccountsForLinkBio?: Resolver<
    Maybe<ResolversTypes['AnyXCheckConnectedSocialAccountsForLinkBioPayload']>,
    ParentType,
    ContextType
  >;
  autoManagedCommissionFixedDates?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['Date']>>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedCommissionFixedDatesArgs, 'id'>
  >;
  autoManagedCommissionInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AutoManagedCommissionInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedCommissionInfluencersArgs, 'id' | 'limit' | 'offset'>
  >;
  autoManagedCommissionInfluencersCounts?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedCommissionInfluencersCountsArgs, 'id'>
  >;
  autoManagedJoinedInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AutoManagedJoinedInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedJoinedInfluencersArgs, 'id' | 'limit' | 'offset'>
  >;
  autoManagedJoinedInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedJoinedInfluencersCountArgs, 'id'>
  >;
  autoManagedJoinedMethods?: Resolver<
    Maybe<ResolversTypes['AutoManagedJoinedMethodsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedJoinedMethodsArgs, 'input'>
  >;
  autoManagedJoinedMethodsV2?: Resolver<
    Maybe<ResolversTypes['AutoManagedJoinedMethodsPayloadV2']>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedJoinedMethodsV2Args, 'input'>
  >;
  autoManagedReportByDate?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AutoManagedReportByDate']>>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedReportByDateArgs, 'id' | 'limit' | 'offset'>
  >;
  autoManagedReportByDateCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedReportByDateCountArgs, 'id'>
  >;
  autoManagedReportByInfluencer?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AutoManagedReportByInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedReportByInfluencerArgs, 'id' | 'limit' | 'offset'>
  >;
  autoManagedReportByInfluencerCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedReportByInfluencerCountArgs, 'id'>
  >;
  autoManagedReportCountForInfluencer?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedReportCountForInfluencerArgs, 'campaignId'>
  >;
  autoManagedReportForInfluencer?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AutoManagedReportForInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedReportForInfluencerArgs, 'campaignId' | 'limit' | 'offset'>
  >;
  autoManagedReportSummary?: Resolver<
    Maybe<ResolversTypes['AutoManagedReportSummary']>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedReportSummaryArgs, 'id'>
  >;
  autoManagedReportSummaryForInfluencer?: Resolver<
    Maybe<ResolversTypes['AutoManagedReportSummaryForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QueryautoManagedReportSummaryForInfluencerArgs, 'campaignId'>
  >;
  billingInformation?: Resolver<Maybe<ResolversTypes['BillingInformation']>, ParentType, ContextType>;
  brandAccounts?: Resolver<
    Maybe<ResolversTypes['InfluencerBrandAccounts']>,
    ParentType,
    ContextType,
    RequireFields<QuerybrandAccountsArgs, 'influencerId' | 'socialAccountId' | 'socialMedia'>
  >;
  campaignCheckoutSessionLink?: Resolver<
    Maybe<ResolversTypes['StripeDeepLink']>,
    ParentType,
    ContextType,
    RequireFields<QuerycampaignCheckoutSessionLinkArgs, 'packageId' | 'priceId' | 'redirectUrl'>
  >;
  campaignHashtagCloud?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['HashtagType']>>,
    ParentType,
    ContextType,
    Partial<QuerycampaignHashtagCloudArgs>
  >;
  campaignsChartForAllTime?: Resolver<
    ReadonlyArray<ResolversTypes['CampaignChartReportForAllTime']>,
    ParentType,
    ContextType,
    Partial<QuerycampaignsChartForAllTimeArgs>
  >;
  checkBioLinkNameAvailability?: Resolver<
    Maybe<ResolversTypes['CheckBioLinkNameAvailabilityPayload']>,
    ParentType,
    ContextType,
    Partial<QuerycheckBioLinkNameAvailabilityArgs>
  >;
  checkoutSessionLink?: Resolver<
    Maybe<ResolversTypes['StripeDeepLink']>,
    ParentType,
    ContextType,
    RequireFields<QuerycheckoutSessionLinkArgs, 'priceId' | 'redirectUrl'>
  >;
  cmsInvoiceDownloadUrl?: Resolver<
    Maybe<ResolversTypes['YoutubeCmsInvoiceDownloadUrl']>,
    ParentType,
    ContextType,
    RequireFields<QuerycmsInvoiceDownloadUrlArgs, 'id'>
  >;
  connectableFacebookPages?: Resolver<
    Maybe<ResolversTypes['ConnectableFacebookPagesPayload']>,
    ParentType,
    ContextType
  >;
  connectableFacebookPagesAndInstagramAccounts?: Resolver<
    Maybe<ResolversTypes['ConnectableFacebookPagesAndInstagramAccountsPayload']>,
    ParentType,
    ContextType
  >;
  connectableInstagramAccounts?: Resolver<
    Maybe<ResolversTypes['ConnectableInstagramAccountsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryconnectableInstagramAccountsArgs, 'fbPageIds'>
  >;
  countUnreadNotifications?: Resolver<Maybe<ResolversTypes['UnreadNotificationCountPayload']>, ParentType, ContextType>;
  countUnreadNotificationsByApp?: Resolver<
    Maybe<ResolversTypes['UnreadNotificationCountByAppPayload']>,
    ParentType,
    ContextType,
    Partial<QuerycountUnreadNotificationsByAppArgs>
  >;
  creatorAddressInformation?: Resolver<Maybe<ResolversTypes['CreatorAddressPayload']>, ParentType, ContextType>;
  customerPortalLink?: Resolver<
    Maybe<ResolversTypes['StripeDeepLink']>,
    ParentType,
    ContextType,
    RequireFields<QuerycustomerPortalLinkArgs, 'redirectUrl'>
  >;
  douyinAccountInterest?: Resolver<
    Maybe<ResolversTypes['DouyinAccountInterest']>,
    ParentType,
    ContextType,
    RequireFields<QuerydouyinAccountInterestArgs, 'pk' | 'socialAccountId'>
  >;
  douyinProfileAudience?: Resolver<
    Maybe<ResolversTypes['DouyinAudienceSection']>,
    ParentType,
    ContextType,
    RequireFields<QuerydouyinProfileAudienceArgs, 'distributionType' | 'pk' | 'socialAccountId'>
  >;
  engagement?: Resolver<
    Maybe<ResolversTypes['EngagementDetail']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementArgs, 'pk'>
  >;
  engagementCampaignPostReportAudienceBreakdown?: Resolver<
    Maybe<ResolversTypes['EngagementCampaignPostReportAudienceBreakdown']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementCampaignPostReportAudienceBreakdownArgs, 'campaignId'>
  >;
  engagementCampaignPostReportForInfluencer?: Resolver<
    Maybe<ResolversTypes['EngagementCampaignPostReportForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementCampaignPostReportForInfluencerArgs, 'campaignId'>
  >;
  engagementCampaignReport?: Resolver<
    Maybe<ResolversTypes['EngagementCampaignReport']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementCampaignReportArgs, 'campaignId' | 'limit' | 'offset'>
  >;
  engagementCampaignReportGrid?: Resolver<
    Maybe<ResolversTypes['CampaignGridReport']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementCampaignReportGridArgs, 'campaignId'>
  >;
  engagementCampaignReportSummary?: Resolver<
    Maybe<ResolversTypes['NewCampaignReportSummary']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementCampaignReportSummaryArgs, 'campaignId'>
  >;
  engagementCampaignTrafficSource?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementPostTrafficSource']>>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementCampaignTrafficSourceArgs, 'pk'>
  >;
  engagementDraftPostHistory?: Resolver<
    Maybe<ResolversTypes['EngagementDraftPostHistory']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementDraftPostHistoryArgs, 'pk'>
  >;
  engagementDraftPostHistoryForInfluencer?: Resolver<
    Maybe<ResolversTypes['EngagementDraftPostHistoryForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementDraftPostHistoryForInfluencerArgs, 'pk'>
  >;
  engagementForInfluencer?: Resolver<
    Maybe<ResolversTypes['EngagementDetailForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementForInfluencerArgs, 'pk'>
  >;
  engagementHashtagCloud?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['HashtagType']>>,
    ParentType,
    ContextType,
    Partial<QueryengagementHashtagCloudArgs>
  >;
  engagementPostComments?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementPostComment']>>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementPostCommentsArgs, 'limit' | 'postId'>
  >;
  engagementPostDetail?: Resolver<
    Maybe<ResolversTypes['EngagementPostDetail']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementPostDetailArgs, 'pk'>
  >;
  engagementPostDetailForInfluencer?: Resolver<
    Maybe<ResolversTypes['EngagementPostDetailForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementPostDetailForInfluencerArgs, 'pk'>
  >;
  engagementPostForInfluencerV2?: Resolver<
    Maybe<ResolversTypes['EngagementPostDetailForInfluencerV2']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementPostForInfluencerV2Args, 'pk'>
  >;
  engagementPostInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementPostInfluencerWithSocialAccounts']>>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementPostInfluencersArgs, 'pk'>
  >;
  engagementPostMetricsForInsightCheck?: Resolver<
    Maybe<ResolversTypes['EngagementPostMetricsDataForInsightCheck']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementPostMetricsForInsightCheckArgs, 'postId'>
  >;
  engagementPostSocialAccountsForInfluencer?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementPostSocialAccount']>>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementPostSocialAccountsForInfluencerArgs, 'pk'>
  >;
  engagementPostTrafficSource?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementPostTrafficSource']>>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementPostTrafficSourceArgs, 'pk'>
  >;
  engagementPostV2?: Resolver<
    Maybe<ResolversTypes['EngagementPostEditV2']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementPostV2Args, 'pk'>
  >;
  engagementProposal?: Resolver<
    Maybe<ResolversTypes['EngagementProposal']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementProposalArgs, 'pk'>
  >;
  engagementTracking?: Resolver<
    Maybe<ResolversTypes['EngagementTracking']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementTrackingArgs, 'engagementId'>
  >;
  engagementXhsPostMetricsForInsight?: Resolver<
    Maybe<ResolversTypes['EngagementXhsPostMetricsDataForInsight']>,
    ParentType,
    ContextType,
    RequireFields<QueryengagementXhsPostMetricsForInsightArgs, 'postId'>
  >;
  existingProfileTags?: Resolver<
    Maybe<ResolversTypes['ExistingProfileTags']>,
    ParentType,
    ContextType,
    RequireFields<QueryexistingProfileTagsArgs, 'keyword'>
  >;
  externalAllInstagramFeedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ExternalInstagramFeedPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalAllInstagramFeedPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  externalAllInstagramFeedPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalAllInstagramFeedPostsCountArgs, 'accountId'>
  >;
  externalAllInstagramPostsInDate?: Resolver<
    Maybe<ResolversTypes['ExternalInstagramAnalyticsPostsInDate']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalAllInstagramPostsInDateArgs, 'accountId' | 'postDate'>
  >;
  externalAllInstagramReelPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ExternalInstagramReelPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalAllInstagramReelPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  externalAllInstagramReelPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalAllInstagramReelPostsCountArgs, 'accountId'>
  >;
  externalAllInstagramStoryPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ExternalInstagramStoryPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalAllInstagramStoryPostsArgs, 'accountId' | 'limit' | 'offset'>
  >;
  externalAllInstagramStoryPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalAllInstagramStoryPostsCountArgs, 'accountId'>
  >;
  externalAnalyticsFacebookGetAccountDetails?: Resolver<
    ResolversTypes['ExternalAnalyticsFacebookGetAccountDetailsOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalAnalyticsFacebookGetAccountDetailsArgs, 'analyticsAccountId'>
  >;
  externalAnalyticsFacebookGetTokenWarnings?: Resolver<
    ResolversTypes['ExternalAnalyticsFacebookGetTokenWarningsOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalAnalyticsFacebookGetTokenWarningsArgs, 'analyticsAccountIdsToCheck'>
  >;
  externalAnalyticsInstagramGetAccountDetails?: Resolver<
    ResolversTypes['ExternalAnalyticsInstagramGetAccountDetailsOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalAnalyticsInstagramGetAccountDetailsArgs, 'analyticsAccountId'>
  >;
  externalAnalyticsInstagramGetTokenWarnings?: Resolver<
    ResolversTypes['ExternalAnalyticsInstagramGetTokenWarningsOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalAnalyticsInstagramGetTokenWarningsArgs, 'analyticsAccountIdsToCheck'>
  >;
  externalAnalyticsSocialAuthRedirectUrl?: Resolver<
    Maybe<ResolversTypes['ExternalAnalyticsSocialAuthRedirectUrlPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalAnalyticsSocialAuthRedirectUrlArgs, 'callbackUrl' | 'provider'>
  >;
  externalFacebookPageAnalyticsAudience?: Resolver<
    ResolversTypes['FacebookPageAnalyticsAudiencePayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryexternalFacebookPageAnalyticsAudienceArgs,
      'endDate' | 'facebookPageAnalyticsAccountId' | 'startDate'
    >
  >;
  externalFacebookPageAnalyticsOverview?: Resolver<
    ResolversTypes['FacebookPageAnalyticsOverviewPayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryexternalFacebookPageAnalyticsOverviewArgs,
      'endDate' | 'facebookPageAnalyticsAccountId' | 'startDate'
    >
  >;
  externalFacebookPageAnalyticsPost?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostPayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryexternalFacebookPageAnalyticsPostArgs,
      'facebookPageAnalyticsAccountId' | 'facebookPageAnalyticsPostId'
    >
  >;
  externalFacebookPageAnalyticsPosts?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostsPayloads'],
    ParentType,
    ContextType,
    RequireFields<
      QueryexternalFacebookPageAnalyticsPostsArgs,
      'facebookPageAnalyticsAccountId' | 'limit' | 'offset' | 'orderBy'
    >
  >;
  externalFacebookPageAnalyticsPostsAnalytics?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostsAnalyticsPayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryexternalFacebookPageAnalyticsPostsAnalyticsArgs,
      'endDate' | 'facebookPageAnalyticsAccountId' | 'startDate' | 'timezoneOffset'
    >
  >;
  externalFacebookPageAnalyticsPostsByDate?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostsByDatePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalFacebookPageAnalyticsPostsByDateArgs, 'date' | 'facebookPageAnalyticsAccountId'>
  >;
  externalFacebookPageAnalyticsPostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalFacebookPageAnalyticsPostsCountArgs, 'facebookPageAnalyticsAccountId'>
  >;
  externalInstagramAnalyticsPostDetails?: Resolver<
    Maybe<ResolversTypes['ExternalInstagramAnalyticsPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalInstagramAnalyticsPostDetailsArgs, 'accountId' | 'postId'>
  >;
  externalInstagramAnalyticsReelDetails?: Resolver<
    Maybe<ResolversTypes['ExternalInstagramAnalyticsReelDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalInstagramAnalyticsReelDetailsArgs, 'accountId' | 'postId'>
  >;
  externalInstagramAnalyticsStoryDetails?: Resolver<
    Maybe<ResolversTypes['ExternalInstagramAnalyticsStoryDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalInstagramAnalyticsStoryDetailsArgs, 'accountId' | 'storyId'>
  >;
  externalInstagramDashboardFollowerAnalytics?: Resolver<
    Maybe<ResolversTypes['ExternalInstagramDashboardFollowerAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalInstagramDashboardFollowerAnalyticsArgs, 'accountId'>
  >;
  externalInstagramDashboardOverview?: Resolver<
    Maybe<ResolversTypes['ExternalInstagramDashboardOverview']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalInstagramDashboardOverviewArgs, 'accountId'>
  >;
  externalInstagramDashboardPostAnalytics?: Resolver<
    Maybe<ResolversTypes['ExternalInstagramDashboardPostAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<QueryexternalInstagramDashboardPostAnalyticsArgs, 'accountId' | 'timezoneOffset'>
  >;
  externalYoutubeAnalyticsAudience?: Resolver<
    ResolversTypes['ExternalYoutubeAnalyticsAudiencePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalYoutubeAnalyticsAudienceArgs, 'endDate' | 'startDate' | 'youtubeAnalyticsAccountId'>
  >;
  externalYoutubeAnalyticsOverview?: Resolver<
    ResolversTypes['ExternalYoutubeAnalyticsOverviewPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalYoutubeAnalyticsOverviewArgs, 'endDate' | 'startDate' | 'youtubeAnalyticsAccountId'>
  >;
  externalYoutubeAnalyticsPost?: Resolver<
    ResolversTypes['ExternalYoutubePostPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalYoutubeAnalyticsPostArgs, 'youtubeAnalyticsAccountId' | 'youtubeAnalyticsPostId'>
  >;
  externalYoutubeAnalyticsPosts?: Resolver<
    ResolversTypes['ExternalYoutubeAnalyticsPostListPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalYoutubeAnalyticsPostsArgs, 'limit' | 'offset' | 'orderBy' | 'youtubeAnalyticsAccountId'>
  >;
  externalYoutubeAnalyticsPostsByDate?: Resolver<
    ResolversTypes['ExternalYoutubeAnalyticsPostListByDatePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalYoutubeAnalyticsPostsByDateArgs, 'date' | 'youtubeAnalyticsAccountId'>
  >;
  externalYoutubeAnalyticsPostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalYoutubeAnalyticsPostsCountArgs, 'youtubeAnalyticsAccountId'>
  >;
  externalYoutubeAnalyticsPostsStatistics?: Resolver<
    ResolversTypes['ExternalYoutubeAnalyticsPostsStatisticsPayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryexternalYoutubeAnalyticsPostsStatisticsArgs,
      'endDate' | 'startDate' | 'timeDifferenceHour' | 'youtubeAnalyticsAccountId'
    >
  >;
  externalYoutubeAnalyticsRelatedPosts?: Resolver<
    ResolversTypes['ExternalYoutubeAnalyticsRelatedPostsPayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryexternalYoutubeAnalyticsRelatedPostsArgs,
      'tag' | 'youtubeAnalyticsAccountId' | 'youtubeAnalyticsPostId'
    >
  >;
  externalYoutubeAnalyticsTagsTopRanking?: Resolver<
    ResolversTypes['ExternalYoutubeAnalyticsTagRankingPayloads'],
    ParentType,
    ContextType,
    RequireFields<
      QueryexternalYoutubeAnalyticsTagsTopRankingArgs,
      'limit' | 'offset' | 'orderBy' | 'youtubeAnalyticsAccountId'
    >
  >;
  externalYoutubeAnalyticsTagsTopRankingCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryexternalYoutubeAnalyticsTagsTopRankingCountArgs, 'youtubeAnalyticsAccountId'>
  >;
  facebookPageAnalyticsAudience?: Resolver<
    ResolversTypes['FacebookPageAnalyticsAudiencePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryfacebookPageAnalyticsAudienceArgs, 'endDate' | 'facebookPageAnalyticsAccountId' | 'startDate'>
  >;
  facebookPageAnalyticsOverview?: Resolver<
    ResolversTypes['FacebookPageAnalyticsOverviewPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryfacebookPageAnalyticsOverviewArgs, 'endDate' | 'facebookPageAnalyticsAccountId' | 'startDate'>
  >;
  facebookPageAnalyticsPost?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostWithPageDetailsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryfacebookPageAnalyticsPostArgs, 'facebookPageAnalyticsAccountId' | 'facebookPageAnalyticsPostId'>
  >;
  facebookPageAnalyticsPosts?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostsPayloads'],
    ParentType,
    ContextType,
    RequireFields<
      QueryfacebookPageAnalyticsPostsArgs,
      'facebookPageAnalyticsAccountId' | 'limit' | 'offset' | 'orderBy'
    >
  >;
  facebookPageAnalyticsPostsAnalytics?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostsAnalyticsPayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryfacebookPageAnalyticsPostsAnalyticsArgs,
      'endDate' | 'facebookPageAnalyticsAccountId' | 'startDate'
    >
  >;
  facebookPageAnalyticsPostsByDate?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostsByDatePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryfacebookPageAnalyticsPostsByDateArgs, 'date' | 'facebookPageAnalyticsAccountId'>
  >;
  facebookPageAnalyticsPostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryfacebookPageAnalyticsPostsCountArgs, 'facebookPageAnalyticsAccountId'>
  >;
  findJoinedCampaigns?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['JoinedCampaign']>>,
    ParentType,
    ContextType,
    RequireFields<QueryfindJoinedCampaignsArgs, 'influencerId'>
  >;
  generateAgencyLogoSignedUrl?: Resolver<
    ResolversTypes['FileUploadUrls'],
    ParentType,
    ContextType,
    RequireFields<QuerygenerateAgencyLogoSignedUrlArgs, 'fileNames'>
  >;
  generateImportFansUploadURL?: Resolver<
    Maybe<ResolversTypes['GenerateImportFansUploadURLPayload']>,
    ParentType,
    ContextType
  >;
  generateMaterialSignedUrl?: Resolver<
    ResolversTypes['FileUploadUrls'],
    ParentType,
    ContextType,
    RequireFields<QuerygenerateMaterialSignedUrlArgs, 'fileNames'>
  >;
  generatePostFileSignedUrl?: Resolver<
    Maybe<ResolversTypes['SignedUrlData']>,
    ParentType,
    ContextType,
    RequireFields<QuerygeneratePostFileSignedUrlArgs, 'fileName'>
  >;
  generatePostFileSignedUrls?: Resolver<
    ResolversTypes['FileUploadUrls'],
    ParentType,
    ContextType,
    RequireFields<QuerygeneratePostFileSignedUrlsArgs, 'fileNames'>
  >;
  generateThumbnailSignedUrl?: Resolver<
    ResolversTypes['FileUploadUrls'],
    ParentType,
    ContextType,
    RequireFields<QuerygenerateThumbnailSignedUrlArgs, 'fileNames'>
  >;
  generateUploadReportSignedUrl?: Resolver<
    ResolversTypes['FileUploadUrls'],
    ParentType,
    ContextType,
    RequireFields<QuerygenerateUploadReportSignedUrlArgs, 'fileNames'>
  >;
  getAccountInformation?: Resolver<Maybe<ResolversTypes['GetAccountInformationPayload']>, ParentType, ContextType>;
  getAllCampaignsCountForSearchJob?: Resolver<
    Maybe<ResolversTypes['GetAllCampaignsCountForSearchJobPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetAllCampaignsCountForSearchJobArgs, 'input'>
  >;
  getAllCampaignsForSearchJob?: Resolver<
    Maybe<ResolversTypes['GetAllCampaignsForSearchJobPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetAllCampaignsForSearchJobArgs, 'input'>
  >;
  getAnyXAccountsByKeyword?: Resolver<
    Maybe<ResolversTypes['GetAnyXAccountsByKeywordPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetAnyXAccountsByKeywordArgs, 'input'>
  >;
  getAutoManagedCampaignById?: Resolver<
    Maybe<ResolversTypes['AutoManagedCampaignPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetAutoManagedCampaignByIdArgs, 'input'>
  >;
  getAutoManagedCampaignForInfluencerSearchJob?: Resolver<
    Maybe<ResolversTypes['AutoManagedCampaignForInfluencerSearchJobPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetAutoManagedCampaignForInfluencerSearchJobArgs, 'input'>
  >;
  getAutoManagedCampaignForInfluencerYourJob?: Resolver<
    Maybe<ResolversTypes['AutoManagedCampaignForInfluencerYourJobPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetAutoManagedCampaignForInfluencerYourJobArgs, 'input'>
  >;
  getAutoManagedCampaigns?: Resolver<
    Maybe<ResolversTypes['GetAutoManagedCampaignsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetAutoManagedCampaignsArgs, 'input'>
  >;
  getAutoManagedCampaignsCount?: Resolver<
    Maybe<ResolversTypes['GetAutoManagedCampaignsCountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetAutoManagedCampaignsCountArgs, 'input'>
  >;
  getAvailableInfluencersForStaff?: Resolver<
    Maybe<ResolversTypes['AvailableInfluencersForStaffPayload']>,
    ParentType,
    ContextType
  >;
  getChatEvents?: Resolver<
    Maybe<ResolversTypes['GetChatEventsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetChatEventsArgs, 'input'>
  >;
  getCouponsList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['String']>>,
    ParentType,
    ContextType,
    RequireFields<QuerygetCouponsListArgs, 'fileUrl'>
  >;
  getCreatorStaffs?: Resolver<
    Maybe<ResolversTypes['CreatorStaffsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetCreatorStaffsArgs, 'input'>
  >;
  getCreatorStaffsCount?: Resolver<Maybe<ResolversTypes['CreatorStaffsCountPayload']>, ParentType, ContextType>;
  getCreatorTags?: Resolver<
    Maybe<ResolversTypes['GetCreatorTagsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetCreatorTagsArgs, 'input'>
  >;
  getCreatorTagsById?: Resolver<
    Maybe<ResolversTypes['GetCreatorTagsByIdPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetCreatorTagsByIdArgs, 'input'>
  >;
  getCurrentUserCurrency?: Resolver<ResolversTypes['CurrentUserCurrency'], ParentType, ContextType>;
  getCustomerTags?: Resolver<
    Maybe<ResolversTypes['GetCustomerTagsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetCustomerTagsArgs, 'input'>
  >;
  getEmailList?: Resolver<
    Maybe<ResolversTypes['GetEmailListPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetEmailListArgs, 'input'>
  >;
  getEmailListCount?: Resolver<
    Maybe<ResolversTypes['GetEmailListCountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetEmailListCountArgs, 'input'>
  >;
  getEmailOverview?: Resolver<
    Maybe<ResolversTypes['GetEmailOverviewPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetEmailOverviewArgs, 'input'>
  >;
  getEmailRecipients?: Resolver<
    Maybe<ResolversTypes['GetEmailRecipientsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetEmailRecipientsArgs, 'input'>
  >;
  getEmailRecipientsCount?: Resolver<
    Maybe<ResolversTypes['GetEmailRecipientsCountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetEmailRecipientsCountArgs, 'input'>
  >;
  getEmailTemplate?: Resolver<
    Maybe<ResolversTypes['GetEmailTemplatePayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetEmailTemplateArgs, 'input'>
  >;
  getFan?: Resolver<
    Maybe<ResolversTypes['GetFanPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFanArgs, 'input'>
  >;
  getFanActivities?: Resolver<
    Maybe<ResolversTypes['GetFanActivitiesPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFanActivitiesArgs, 'input'>
  >;
  getFanCountries?: Resolver<Maybe<ResolversTypes['CountriesPayload']>, ParentType, ContextType>;
  getFanFormResponse?: Resolver<
    Maybe<ResolversTypes['GetFanFormResponsePayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFanFormResponseArgs, 'input'>
  >;
  getFanHeader?: Resolver<
    Maybe<ResolversTypes['GetFanHeaderPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFanHeaderArgs, 'input'>
  >;
  getFanOrders?: Resolver<
    Maybe<ResolversTypes['GetFanOrdersPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFanOrdersArgs, 'input'>
  >;
  getFanParticipationFormList?: Resolver<
    Maybe<ResolversTypes['GetFanParticipationFormListPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFanParticipationFormListArgs, 'input'>
  >;
  getFanProvinces?: Resolver<
    Maybe<ResolversTypes['ProvincesPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFanProvincesArgs, 'input'>
  >;
  getFans?: Resolver<
    Maybe<ResolversTypes['GetFansPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFansArgs, 'input'>
  >;
  getFansCount?: Resolver<
    Maybe<ResolversTypes['GetFansCountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFansCountArgs, 'input'>
  >;
  getForm?: Resolver<
    Maybe<ResolversTypes['GetFormInputPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFormArgs, 'input'>
  >;
  getFormReportSummary?: Resolver<
    Maybe<ResolversTypes['FormReportSummaryPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFormReportSummaryArgs, 'input'>
  >;
  getFormRespondentsCount?: Resolver<
    Maybe<ResolversTypes['FormRespondentsCountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFormRespondentsCountArgs, 'input'>
  >;
  getFormRespondentsList?: Resolver<
    Maybe<ResolversTypes['FormRespondentsListPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFormRespondentsListArgs, 'input'>
  >;
  getFormResponse?: Resolver<
    Maybe<ResolversTypes['GetFormResponsePayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFormResponseArgs, 'input'>
  >;
  getFormResponseByPage?: Resolver<
    Maybe<ResolversTypes['GetFormResponsePayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFormResponseByPageArgs, 'input'>
  >;
  getFormTemplate?: Resolver<
    Maybe<ResolversTypes['GetFormTemplatePayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFormTemplateArgs, 'input'>
  >;
  getFormsCount?: Resolver<
    Maybe<ResolversTypes['GetFormsCountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFormsCountArgs, 'input'>
  >;
  getFormsList?: Resolver<
    Maybe<ResolversTypes['GetFormsListPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetFormsListArgs, 'input'>
  >;
  getGeneratedMarketplaceCampaignDetail?: Resolver<
    Maybe<ResolversTypes['GetGeneratedMarketplaceCampaignDetailPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetGeneratedMarketplaceCampaignDetailArgs, 'input'>
  >;
  getGeneratedMarketplacePostCaption?: Resolver<
    Maybe<ResolversTypes['GetGeneratedMarketplacePostCaptionPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetGeneratedMarketplacePostCaptionArgs, 'input'>
  >;
  getGeneratedTikTokSpecialPostCaption?: Resolver<
    Maybe<ResolversTypes['GetGeneratedTikTokSpecialPostCaptionPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetGeneratedTikTokSpecialPostCaptionArgs, 'input'>
  >;
  getInfluencerBio?: Resolver<Maybe<ResolversTypes['GetInfluencerBioPayload']>, ParentType, ContextType>;
  getInfluencerBioAnalytics?: Resolver<
    Maybe<ResolversTypes['GetInfluencerBioAnalyticsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetInfluencerBioAnalyticsArgs, 'input'>
  >;
  getInfluencerBioV2?: Resolver<Maybe<ResolversTypes['GetInfluencerBioPayloadV2']>, ParentType, ContextType>;
  getInfluencerChatServices?: Resolver<
    Maybe<ResolversTypes['GetInfluencerChatServicesPayload']>,
    ParentType,
    ContextType
  >;
  getInfluencerRateCard?: Resolver<
    Maybe<ResolversTypes['InfluencerRateCardPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetInfluencerRateCardArgs, 'input'>
  >;
  getInfluencerRateCardForInfluencer?: Resolver<
    Maybe<ResolversTypes['InfluencerRateCardPayload']>,
    ParentType,
    ContextType
  >;
  getLineBroadcastEstimateRecipients?: Resolver<
    Maybe<ResolversTypes['GetLineBroadcastEstimateRecipientsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLineBroadcastEstimateRecipientsArgs, 'input'>
  >;
  getLineBroadcastMessage?: Resolver<
    Maybe<ResolversTypes['GetLineBroadcastMessagePayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLineBroadcastMessageArgs, 'input'>
  >;
  getLineBroadcastSummary?: Resolver<
    Maybe<ResolversTypes['GetLineBroadcastSummaryPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLineBroadcastSummaryArgs, 'input'>
  >;
  getLineBroadcasts?: Resolver<
    Maybe<ResolversTypes['GetLineBroadcastsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLineBroadcastsArgs, 'input'>
  >;
  getLineBroadcastsCount?: Resolver<
    Maybe<ResolversTypes['GetLineBroadcastsCountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLineBroadcastsCountArgs, 'input'>
  >;
  getLineChannels?: Resolver<Maybe<ResolversTypes['GetLineChannelPayload']>, ParentType, ContextType>;
  getLineChat?: Resolver<
    Maybe<ResolversTypes['GetLineChatPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLineChatArgs, 'input'>
  >;
  getLineChatCount?: Resolver<
    Maybe<ResolversTypes['GetLineChatCountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLineChatCountArgs, 'input'>
  >;
  getLineChats?: Resolver<
    Maybe<ResolversTypes['GetLineChatsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLineChatsArgs, 'input'>
  >;
  getLineLoginDetail?: Resolver<Maybe<ResolversTypes['GetLineLoginDetailPayload']>, ParentType, ContextType>;
  getLinePublicKey?: Resolver<Maybe<ResolversTypes['GetLinePublicKeyPayload']>, ParentType, ContextType>;
  getLinkBioFacebookAccountPosts?: Resolver<
    Maybe<ResolversTypes['GetLinkBioFacebookAccountPostsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLinkBioFacebookAccountPostsArgs, 'input'>
  >;
  getLinkBioFacebookPagePosts?: Resolver<
    Maybe<ResolversTypes['GetLinkBioFacebookPagePostsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetLinkBioFacebookPagePostsArgs, 'input'>
  >;
  getLinkBioThemeColor?: Resolver<Maybe<ResolversTypes['GetLinkBioThemeColorPayload']>, ParentType, ContextType>;
  getMarketplaceCampaignForInfluencer?: Resolver<
    Maybe<ResolversTypes['MarketplaceDetailForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetMarketplaceCampaignForInfluencerArgs, 'pk'>
  >;
  getMessageTemplates?: Resolver<Maybe<ResolversTypes['GetMessageTemplatesPayload']>, ParentType, ContextType>;
  getNotifications?: Resolver<
    Maybe<ResolversTypes['NotificationsPayload']>,
    ParentType,
    ContextType,
    Partial<QuerygetNotificationsArgs>
  >;
  getNotificationsByApp?: Resolver<
    Maybe<ResolversTypes['NotificationsPayload']>,
    ParentType,
    ContextType,
    Partial<QuerygetNotificationsByAppArgs>
  >;
  getPersonalUserDetail?: Resolver<Maybe<ResolversTypes['PersonalUserDetailPayload']>, ParentType, ContextType>;
  getPublicInfluencerBio?: Resolver<
    Maybe<ResolversTypes['GetPublicInfluencerBioPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetPublicInfluencerBioArgs, 'input'>
  >;
  getReadTutorials?: Resolver<Maybe<ResolversTypes['ReadTutorials']>, ParentType, ContextType>;
  getShopifyAccount?: Resolver<Maybe<ResolversTypes['GetShopifyAccountPayload']>, ParentType, ContextType>;
  getSocialAccountPermissions?: Resolver<
    ReadonlyArray<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetSocialAccountPermissionsArgs, 'input'>
  >;
  getTagsAndFansRecipients?: Resolver<
    Maybe<ResolversTypes['GetTagsAndFansRecipientsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetTagsAndFansRecipientsArgs, 'input'>
  >;
  getUserDefaultPermissions?: Resolver<
    Maybe<ResolversTypes['UserDefaultPermissions']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetUserDefaultPermissionsArgs, 'role'>
  >;
  getUserDetail?: Resolver<
    Maybe<ResolversTypes['UserDetailPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetUserDetailArgs, 'input'>
  >;
  getUserPermissions?: Resolver<Maybe<ResolversTypes['UserDefaultPermissions']>, ParentType, ContextType>;
  getYoutubeMCNRedirectUrlForAdmin?: Resolver<
    Maybe<ResolversTypes['YoutubeMCNAdminRedirectUrlPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerygetYoutubeMCNRedirectUrlForAdminArgs, 'input'>
  >;
  hubspotCompanyList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['HubspotCompanyData']>>,
    ParentType,
    ContextType,
    Partial<QueryhubspotCompanyListArgs>
  >;
  hubspotCompanyName?: Resolver<
    Maybe<ResolversTypes['HubspotCompanyData']>,
    ParentType,
    ContextType,
    RequireFields<QueryhubspotCompanyNameArgs, 'hubspotId'>
  >;
  hubspotDealName?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['HubspotDealDataForSearch']>>,
    ParentType,
    ContextType,
    Partial<QueryhubspotDealNameArgs>
  >;
  hubspotDealsList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['HubspotDealData']>>,
    ParentType,
    ContextType,
    RequireFields<QueryhubspotDealsListArgs, 'keyword'>
  >;
  influencerAllInstagramFeedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramFeedPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramFeedPostsArgs, 'limit' | 'offset' | 'socialAccountId'>
  >;
  influencerAllInstagramFeedPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramFeedPostsCountArgs, 'socialAccountId'>
  >;
  influencerAllInstagramInteractionUsers?: Resolver<
    ResolversTypes['InstagramInteractionUserAnalyticsDetailsAndCount'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramInteractionUsersArgs, 'limit' | 'offset' | 'socialAccountId'>
  >;
  influencerAllInstagramMentionedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramInteractionPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramMentionedPostsArgs, 'limit' | 'offset' | 'socialAccountId'>
  >;
  influencerAllInstagramMentionedPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramMentionedPostsCountArgs, 'socialAccountId'>
  >;
  influencerAllInstagramPostsInDate?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsPostsInDate']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramPostsInDateArgs, 'postDate' | 'socialAccountId'>
  >;
  influencerAllInstagramReelPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramReelPostItem']>>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramReelPostsArgs, 'limit' | 'offset' | 'socialAccountId'>
  >;
  influencerAllInstagramReelPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramReelPostsCountArgs, 'socialAccountId'>
  >;
  influencerAllInstagramStoryPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramStoryPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramStoryPostsArgs, 'limit' | 'offset' | 'socialAccountId'>
  >;
  influencerAllInstagramStoryPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramStoryPostsCountArgs, 'socialAccountId'>
  >;
  influencerAllInstagramTaggedPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InstagramInteractionTaggedPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramTaggedPostsArgs, 'limit' | 'offset' | 'socialAccountId'>
  >;
  influencerAllInstagramTaggedPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAllInstagramTaggedPostsCountArgs, 'socialAccountId'>
  >;
  influencerAnalyticsFacebookPageAccessTokenWarning?: Resolver<
    ResolversTypes['InfluencerFacebookPageAnalyticsAccessTokenWarningPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs, 'influencerId' | 'socialAccountId'>
  >;
  influencerAnalyticsInstagramAccessTokenWarning?: Resolver<
    ResolversTypes['InfluencerInstagramAnalyticsAccessTokenWarningPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs, 'socialAccountId'>
  >;
  influencerAnalyticsMultipleInstagramAccessTokenWarning?: Resolver<
    ResolversTypes['InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs, 'socialAccountIds'>
  >;
  influencerAnalyticsTwitterAccessTokenWarning?: Resolver<
    ResolversTypes['InfluencerTwitterAnalyticsAccessTokenWarningPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs, 'socialAccountId'>
  >;
  influencerAnalyticsTwitterRefreshAvailability?: Resolver<
    ResolversTypes['InfluencerAnalyticsTwitterRefreshAvailabilityPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerAnalyticsTwitterRefreshAvailabilityArgs, 'socialAccountId'>
  >;
  influencerCampaignList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerCampaignDetail']>>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerCampaignListArgs, 'pk'>
  >;
  influencerCampaignListByPromotionMethod?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerCampaignDetailByPromotion']>>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerCampaignListByPromotionMethodArgs, 'influencerId' | 'promotionMethodId'>
  >;
  influencerCampaignSummary?: Resolver<
    ResolversTypes['InfluencerCampaignSummary'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerCampaignSummaryArgs, 'pk'>
  >;
  influencerFacebookPageAnalyticsAudience?: Resolver<
    ResolversTypes['FacebookPageAnalyticsAudiencePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerFacebookPageAnalyticsAudienceArgs, 'influencerId' | 'socialAccountId'>
  >;
  influencerFacebookPageAnalyticsOverview?: Resolver<
    ResolversTypes['FacebookPageAnalyticsOverviewPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerFacebookPageAnalyticsOverviewArgs, 'influencerId' | 'socialAccountId'>
  >;
  influencerFacebookPageAnalyticsPost?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostWithPageDetailsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerFacebookPageAnalyticsPostArgs, 'influencerId' | 'postId' | 'socialAccountId'>
  >;
  influencerFacebookPageAnalyticsPosts?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostsPayloads'],
    ParentType,
    ContextType,
    RequireFields<
      QueryinfluencerFacebookPageAnalyticsPostsArgs,
      'influencerId' | 'limit' | 'offset' | 'orderBy' | 'socialAccountId'
    >
  >;
  influencerFacebookPageAnalyticsPostsAnalytics?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostsAnalyticsPayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryinfluencerFacebookPageAnalyticsPostsAnalyticsArgs,
      'endDate' | 'influencerId' | 'socialAccountId' | 'startDate'
    >
  >;
  influencerFacebookPageAnalyticsPostsByDate?: Resolver<
    ResolversTypes['FacebookPageAnalyticsPostsByDatePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerFacebookPageAnalyticsPostsByDateArgs, 'date' | 'influencerId' | 'socialAccountId'>
  >;
  influencerFacebookPageAnalyticsPostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerFacebookPageAnalyticsPostsCountArgs, 'influencerId' | 'socialAccountId'>
  >;
  influencerHasEstimateApi?: Resolver<
    ResolversTypes['InfluencerHasEstimateApi'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerHasEstimateApiArgs, 'pk' | 'socialAccountId' | 'socialMedia'>
  >;
  influencerInstagramAnalyticsPostDetails?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramAnalyticsPostDetailsArgs, 'postId' | 'socialAccountId'>
  >;
  influencerInstagramAnalyticsReelDetails?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsReelDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramAnalyticsReelDetailsArgs, 'postId' | 'socialAccountId'>
  >;
  influencerInstagramAnalyticsStoryDetails?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsStoryDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramAnalyticsStoryDetailsArgs, 'socialAccountId' | 'storyId'>
  >;
  influencerInstagramDashboardFollowerAnalytics?: Resolver<
    Maybe<ResolversTypes['InstagramDashboardFollowerAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramDashboardFollowerAnalyticsArgs, 'socialAccountId'>
  >;
  influencerInstagramDashboardOverview?: Resolver<
    Maybe<ResolversTypes['InstagramDashboardOverview']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramDashboardOverviewArgs, 'socialAccountId'>
  >;
  influencerInstagramDashboardPostAnalytics?: Resolver<
    Maybe<ResolversTypes['InstagramDashboardPostAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramDashboardPostAnalyticsArgs, 'socialAccountId'>
  >;
  influencerInstagramInteractionMentionedPostDetails?: Resolver<
    Maybe<ResolversTypes['InstagramInteractionPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramInteractionMentionedPostDetailsArgs, 'postId' | 'socialAccountId'>
  >;
  influencerInstagramInteractionOverview?: Resolver<
    Maybe<ResolversTypes['InstagramInteractionOverview']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramInteractionOverviewArgs, 'socialAccountId'>
  >;
  influencerInstagramInteractionPosts?: Resolver<
    Maybe<ResolversTypes['InstagramInteractionPosts']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramInteractionPostsArgs, 'socialAccountId'>
  >;
  influencerInstagramInteractionTaggedPostDetails?: Resolver<
    Maybe<ResolversTypes['InstagramInteractionPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInstagramInteractionTaggedPostDetailsArgs, 'postId' | 'socialAccountId'>
  >;
  influencerInterests?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerInterest']>>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerInterestsArgs, 'influencerId' | 'socialAccountId' | 'socialMedia'>
  >;
  influencerMultipleSocialAccountsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerMultipleSocialAccountsCountArgs, 'influencerIds' | 'socialMedia'>
  >;
  influencerPaymentInformation?: Resolver<
    Maybe<ResolversTypes['InfluencerPaymentInformation']>,
    ParentType,
    ContextType
  >;
  influencerPaymentRequests?: Resolver<
    ResolversTypes['InfluencerPaymentRequests'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerPaymentRequestsArgs, 'limit' | 'offset'>
  >;
  influencerPostDetail?: Resolver<
    Maybe<ResolversTypes['InfluencerPostDetail']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerPostDetailArgs, 'pk' | 'postId'>
  >;
  influencerPostDetailForTalentAgency?: Resolver<
    Maybe<ResolversTypes['InfluencerPostDetail']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerPostDetailForTalentAgencyArgs, 'pk' | 'postId'>
  >;
  influencerProfileAudience?: Resolver<
    Maybe<ResolversTypes['InfluencerAudienceSection']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerProfileAudienceArgs, 'pk' | 'socialAccountId' | 'socialMedia'>
  >;
  influencerProfileDetailForEngagementProposal?: Resolver<
    Maybe<ResolversTypes['InfluencerProfileDetailForEngagementProposal']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryinfluencerProfileDetailForEngagementProposalArgs,
      'engagementId' | 'influencerId' | 'socialAccountId' | 'socialMedia'
    >
  >;
  influencerProfileDetailV2?: Resolver<
    Maybe<ResolversTypes['InfluencerNewProfileDetail']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerProfileDetailV2Args, 'pk' | 'socialAccountId' | 'socialMedia'>
  >;
  influencerProfileFollowerGrowth?: Resolver<
    Maybe<ResolversTypes['InfluencerFollowerGrowthSection']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerProfileFollowerGrowthArgs, 'pk' | 'socialAccountId' | 'socialMedia'>
  >;
  influencerProfileForEngagementProposal?: Resolver<
    Maybe<ResolversTypes['InfluencerProfileForEngagementProposal']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerProfileForEngagementProposalArgs, 'engagementId' | 'influencerId'>
  >;
  influencerProfilePosts?: Resolver<
    Maybe<ResolversTypes['InfluencerPostSection']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerProfilePostsArgs, 'pk' | 'socialAccountId' | 'socialMedia'>
  >;
  influencerProfileV2?: Resolver<
    Maybe<ResolversTypes['InfluencerNewProfileHeaderV2']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerProfileV2Args, 'pk'>
  >;
  influencerPromotionMethods?: Resolver<
    Maybe<ResolversTypes['InfluencerPromotionMethodsPayload']>,
    ParentType,
    ContextType
  >;
  influencerTwitterAnalyticsOverview?: Resolver<
    ResolversTypes['TwitterAnalyticsOverviewPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerTwitterAnalyticsOverviewArgs, 'socialAccountId'>
  >;
  influencerTwitterAnalyticsPost?: Resolver<
    ResolversTypes['TwitterAnalyticsPostByIdPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerTwitterAnalyticsPostArgs, 'socialAccountId' | 'twitterAnalyticsPostId'>
  >;
  influencerTwitterAnalyticsPosts?: Resolver<
    ResolversTypes['TwitterAnalyticsPostsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerTwitterAnalyticsPostsArgs, 'limit' | 'offset' | 'orderBy' | 'socialAccountId'>
  >;
  influencerTwitterAnalyticsPostsByDate?: Resolver<
    ResolversTypes['TwitterAnalyticsPostsByDatePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerTwitterAnalyticsPostsByDateArgs, 'date' | 'socialAccountId'>
  >;
  influencerTwitterAnalyticsPostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerTwitterAnalyticsPostsCountArgs, 'socialAccountId'>
  >;
  influencerTwitterAnalyticsPostsStatistic?: Resolver<
    Maybe<ResolversTypes['TwitterAnalyticsPostsStatisticPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerTwitterAnalyticsPostsStatisticArgs, 'endDate' | 'socialAccountId' | 'startDate'>
  >;
  influencerUpdateDetailV2?: Resolver<
    Maybe<ResolversTypes['InfluencerUpdateDetailV2']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerUpdateDetailV2Args, 'pk'>
  >;
  influencerWhitelist?: Resolver<
    Maybe<ResolversTypes['InfluencerWhitelist']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerWhitelistArgs, 'marketplaceId'>
  >;
  influencerYoutubeAnalyticsAudience?: Resolver<
    ResolversTypes['YoutubeAnalyticsAudiencePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsAudienceArgs, 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsChannelDetails?: Resolver<
    ResolversTypes['InfluencerYoutubeAnalyticsChannelDetailsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsChannelDetailsArgs, 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsCompareAccountCards?: Resolver<
    ResolversTypes['InfluencerYoutubeAnalyticsCompareAccountCards'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs, 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsCompareRelatedPosts?: Resolver<
    ResolversTypes['YoutubeAnalyticsCompareRelatedPosts'],
    ParentType,
    ContextType,
    RequireFields<
      QueryinfluencerYoutubeAnalyticsCompareRelatedPostsArgs,
      'compareAccountId' | 'postId' | 'socialAccountId' | 'tag'
    >
  >;
  influencerYoutubeAnalyticsOverview?: Resolver<
    ResolversTypes['YoutubeAnalyticsOverviewPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsOverviewArgs, 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsPost?: Resolver<
    ResolversTypes['YoutubeAnalyticsPostWithChannelDetailsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsPostArgs, 'socialAccountId' | 'youtubeAnalyticsPostId'>
  >;
  influencerYoutubeAnalyticsPosts?: Resolver<
    ResolversTypes['YoutubeAnalyticsPostsPayloads'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsPostsArgs, 'limit' | 'offset' | 'orderBy' | 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsPostsByDate?: Resolver<
    ResolversTypes['YoutubeAnalyticsPostPayloads'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsPostsByDateArgs, 'date' | 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsPostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsPostsCountArgs, 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsPostsStatistics?: Resolver<
    ResolversTypes['YoutubeAnalyticsPostsStatisticsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsPostsStatisticsArgs, 'endDate' | 'socialAccountId' | 'startDate'>
  >;
  influencerYoutubeAnalyticsRelatedPosts?: Resolver<
    ResolversTypes['YoutubeAnalyticsRelatedPostsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsRelatedPostsArgs, 'socialAccountId' | 'tag' | 'youtubeAnalyticsPostId'>
  >;
  influencerYoutubeAnalyticsShorts?: Resolver<
    ResolversTypes['YoutubeAnalyticsShortsPayloads'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsShortsArgs, 'limit' | 'offset' | 'orderBy' | 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsShortsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsShortsCountArgs, 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsSponsoredPosts?: Resolver<
    ResolversTypes['InfluencerAnalyticsSponsoredPostPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsSponsoredPostsArgs, 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsSponsoredPostsCount?: Resolver<
    ResolversTypes['InfluencerAnalyticsSponsoredPostCountPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsSponsoredPostsCountArgs, 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsTagsTopRanking?: Resolver<
    ResolversTypes['YoutubeAnalyticsTagRankingPayloads'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs, 'limit' | 'offset' | 'orderBy' | 'socialAccountId'>
  >;
  influencerYoutubeAnalyticsTagsTopRankingCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs, 'socialAccountId'>
  >;
  influencerYoutubeCompareAllPostsInDate?: Resolver<
    Maybe<ResolversTypes['InfluencerYoutubeCompareAllPostsInDate']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeCompareAllPostsInDateArgs, 'date' | 'socialAccountId'>
  >;
  influencerYoutubeCompareOverview?: Resolver<
    ResolversTypes['InfluencerYoutubeCompareOverview'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeCompareOverviewArgs, 'endDate' | 'socialAccountId' | 'startDate'>
  >;
  influencerYoutubeComparePostById?: Resolver<
    Maybe<ResolversTypes['YoutubeComparePost']>,
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeComparePostByIdArgs, 'compareAccountId' | 'postId' | 'socialAccountId'>
  >;
  influencerYoutubeComparePostList?: Resolver<
    ResolversTypes['YoutubeComparePostList'],
    ParentType,
    ContextType,
    RequireFields<
      QueryinfluencerYoutubeComparePostListArgs,
      'compareAccountId' | 'limit' | 'offset' | 'orderBy' | 'socialAccountId'
    >
  >;
  influencerYoutubeComparePosts?: Resolver<
    ResolversTypes['InfluencerYoutubeComparePosts'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeComparePostsArgs, 'socialAccountId'>
  >;
  influencerYoutubeComparePostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeComparePostsCountArgs, 'compareAccountId' | 'socialAccountId'>
  >;
  influencerYoutubeCompareTagRanking?: Resolver<
    ResolversTypes['InfluencerYoutubeCompareTagRanking'],
    ParentType,
    ContextType,
    RequireFields<QueryinfluencerYoutubeCompareTagRankingArgs, 'endDate' | 'socialAccountId' | 'startDate'>
  >;
  instagramAccountInfo?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsAccountInfoHeader']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramAccountInfoArgs, 'accountId'>
  >;
  instagramAmbassadorPostDetails?: Resolver<
    Maybe<ResolversTypes['InstagramBrandAmbassadorPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramAmbassadorPostDetailsArgs, 'accountId' | 'postId'>
  >;
  instagramAnalyticsHashtags?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsHashtags']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramAnalyticsHashtagsArgs, 'accountId'>
  >;
  instagramAnalyticsListComments?: Resolver<
    ResolversTypes['InstagramAnalyticsGetCommentsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinstagramAnalyticsListCommentsArgs, 'instagramAnalyticsAccountId' | 'limit' | 'offset'>
  >;
  instagramAnalyticsListCommentsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryinstagramAnalyticsListCommentsCountArgs, 'instagramAnalyticsAccountId'>
  >;
  instagramAnalyticsPostDetails?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramAnalyticsPostDetailsArgs, 'postId'>
  >;
  instagramAnalyticsReelDetails?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsReelDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramAnalyticsReelDetailsArgs, 'accountId' | 'postId'>
  >;
  instagramAnalyticsStoryDetails?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsStoryDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramAnalyticsStoryDetailsArgs, 'accountId' | 'storyId'>
  >;
  instagramCompareAccounts?: Resolver<
    ResolversTypes['InstagramCompareAccountsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinstagramCompareAccountsArgs, 'instagramAnalyticsAccountId'>
  >;
  instagramCompareFollowers?: Resolver<
    Maybe<ResolversTypes['InstagramCompareFollowers']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramCompareFollowersArgs, 'accountId' | 'comparableAccountIds'>
  >;
  instagramCompareGetAccounts?: Resolver<
    Maybe<ResolversTypes['InstagramAnalyticsCompareAccounts']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramCompareGetAccountsArgs, 'accountToCompareWithId'>
  >;
  instagramCompareOverview?: Resolver<
    Maybe<ResolversTypes['InstagramCompareOverview']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramCompareOverviewArgs, 'comparableAccountIds' | 'mainAccountId'>
  >;
  instagramComparePostDetails?: Resolver<
    Maybe<ResolversTypes['InstagramComparePostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramComparePostDetailsArgs, 'postId'>
  >;
  instagramComparePosts?: Resolver<
    Maybe<ResolversTypes['InstagramComparePosts']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramComparePostsArgs, 'accountId' | 'comparableAccountIds'>
  >;
  instagramDashboardFollowerAnalytics?: Resolver<
    Maybe<ResolversTypes['InstagramDashboardFollowerAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramDashboardFollowerAnalyticsArgs, 'accountId'>
  >;
  instagramDashboardOverview?: Resolver<
    Maybe<ResolversTypes['InstagramDashboardOverview']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramDashboardOverviewArgs, 'accountId'>
  >;
  instagramDashboardPostAnalytics?: Resolver<
    Maybe<ResolversTypes['InstagramDashboardPostAnalytics']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramDashboardPostAnalyticsArgs, 'accountId'>
  >;
  instagramHashtagFeedPostsV2?: Resolver<
    ResolversTypes['InstagramHashtagFeedPostsPayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryinstagramHashtagFeedPostsV2Args,
      'date' | 'hashtagId' | 'instagramAnalyticsAccountId' | 'limit' | 'offset'
    >
  >;
  instagramHashtagPostDetails?: Resolver<
    Maybe<ResolversTypes['InstagramHashtagPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramHashtagPostDetailsArgs, 'postId'>
  >;
  instagramHashtagPosts?: Resolver<
    Maybe<ResolversTypes['InstagramHashtagPosts']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramHashtagPostsArgs, 'analyticsAccountId'>
  >;
  instagramHashtagPostsV2?: Resolver<
    ResolversTypes['InstagramHashtagPostsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinstagramHashtagPostsV2Args, 'date' | 'instagramAnalyticsAccountId'>
  >;
  instagramHashtagsOverview?: Resolver<
    Maybe<ResolversTypes['InstagramHashtagsOverview']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramHashtagsOverviewArgs, 'analyticsAccountId'>
  >;
  instagramInteractionMentionedPostDetails?: Resolver<
    Maybe<ResolversTypes['InstagramInteractionPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramInteractionMentionedPostDetailsArgs, 'accountId' | 'postId'>
  >;
  instagramInteractionOverview?: Resolver<
    Maybe<ResolversTypes['InstagramInteractionOverview']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramInteractionOverviewArgs, 'analyticsAccountId'>
  >;
  instagramInteractionPosts?: Resolver<
    Maybe<ResolversTypes['InstagramInteractionPosts']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramInteractionPostsArgs, 'analyticsAccountId'>
  >;
  instagramInteractionTaggedPostDetails?: Resolver<
    Maybe<ResolversTypes['InstagramInteractionPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramInteractionTaggedPostDetailsArgs, 'accountId' | 'postId'>
  >;
  instagramUGCManagementBasicInfo?: Resolver<
    ResolversTypes['InstagramUGCManagementBasicInfoPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinstagramUGCManagementBasicInfoArgs, 'instagramAnalyticsAccountId'>
  >;
  instagramUGCManagementListPosts?: Resolver<
    ResolversTypes['InstagramUGCManagementListPostsPayload'],
    ParentType,
    ContextType,
    RequireFields<
      QueryinstagramUGCManagementListPostsArgs,
      'instagramAnalyticsAccountId' | 'limit' | 'offset' | 'orderBy'
    >
  >;
  instagramUGCManagementListPostsCount?: Resolver<
    ResolversTypes['InstagramUGCManagementListPostsCountPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinstagramUGCManagementListPostsCountArgs, 'instagramAnalyticsAccountId'>
  >;
  instagramUGCManagementPostDetails?: Resolver<
    ResolversTypes['InstagramUGCManagementPostDetailsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryinstagramUGCManagementPostDetailsArgs, 'instagramAnalyticsAccountId' | 'postSNSId'>
  >;
  instagramUserHashtagPostList?: Resolver<
    Maybe<ResolversTypes['InstagramUserHashtagListPostsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryinstagramUserHashtagPostListArgs, 'date' | 'hashtagId' | 'limit' | 'offset'>
  >;
  instagramUserHashtags?: Resolver<ResolversTypes['InstagramUserHashtagPayload'], ParentType, ContextType>;
  isInfluencerUuum?: Resolver<Maybe<ResolversTypes['IsInfluencerUUUM']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Logo']>, ParentType, ContextType>;
  marketplace?: Resolver<
    Maybe<ResolversTypes['MarketplaceDetail']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceArgs, 'pk'>
  >;
  marketplaceAffiliateCommissionFixedDates?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['Date']>>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateCommissionFixedDatesArgs, 'marketplaceId'>
  >;
  marketplaceAffiliateCommissionInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplaceAffiliateCommissionInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateCommissionInfluencersArgs, 'limit' | 'marketplaceId' | 'offset'>
  >;
  marketplaceAffiliateCommissionInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateCommissionInfluencersCountArgs, 'marketplaceId'>
  >;
  marketplaceAffiliateJoinedInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplaceAffiliateJoinedInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateJoinedInfluencersArgs, 'limit' | 'marketplaceId' | 'offset'>
  >;
  marketplaceAffiliateJoinedInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateJoinedInfluencersCountArgs, 'marketplaceId'>
  >;
  marketplaceAffiliateReportByDate?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplaceAffiliateReportByDate']>>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateReportByDateArgs, 'campaignId' | 'limit' | 'offset'>
  >;
  marketplaceAffiliateReportByDateCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateReportByDateCountArgs, 'campaignId'>
  >;
  marketplaceAffiliateReportByInfluencer?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplaceAffiliateReportByInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateReportByInfluencerArgs, 'campaignId' | 'limit' | 'offset'>
  >;
  marketplaceAffiliateReportByInfluencerCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateReportByInfluencerCountArgs, 'campaignId'>
  >;
  marketplaceAffiliateReportCountForInfluencer?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateReportCountForInfluencerArgs, 'campaignId'>
  >;
  marketplaceAffiliateReportForInfluencer?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplaceAffiliateReportForInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateReportForInfluencerArgs, 'campaignId' | 'limit' | 'offset'>
  >;
  marketplaceAffiliateReportSummary?: Resolver<
    Maybe<ResolversTypes['MarketplaceAffiliateReportSummary']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateReportSummaryArgs, 'campaignId'>
  >;
  marketplaceAffiliateReportSummaryForInfluencer?: Resolver<
    Maybe<ResolversTypes['MarketplaceAffiliateReportSummaryForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceAffiliateReportSummaryForInfluencerArgs, 'campaignId'>
  >;
  marketplaceCampaignPostReportForInfluencer?: Resolver<
    Maybe<ResolversTypes['MarketplaceCampaignPostReportForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceCampaignPostReportForInfluencerArgs, 'campaignId'>
  >;
  marketplaceCampaignReport?: Resolver<
    Maybe<ResolversTypes['MarketplaceCampaignReport']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceCampaignReportArgs, 'campaignId' | 'limit' | 'offset'>
  >;
  marketplaceCampaignReportGrid?: Resolver<
    Maybe<ResolversTypes['CampaignGridReport']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceCampaignReportGridArgs, 'campaignId'>
  >;
  marketplaceCampaignReportSummary?: Resolver<
    Maybe<ResolversTypes['NewCampaignReportSummary']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceCampaignReportSummaryArgs, 'campaignId'>
  >;
  marketplaceDraftPost?: Resolver<
    Maybe<ResolversTypes['MarketplaceDraftPostDetail']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceDraftPostArgs, 'draftId'>
  >;
  marketplaceDraftPostsForInfluencer?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplaceDraftPostForInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceDraftPostsForInfluencerArgs, 'marketplaceId'>
  >;
  marketplaceForInfluencer?: Resolver<
    Maybe<ResolversTypes['MarketplaceDetailForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceForInfluencerArgs, 'pk'>
  >;
  marketplaceGoogleSlidesHistory?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['GoogleSlideFile']>>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceGoogleSlidesHistoryArgs, 'campaignId'>
  >;
  marketplaceHashtagCloud?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['HashtagType']>>,
    ParentType,
    ContextType,
    Partial<QuerymarketplaceHashtagCloudArgs>
  >;
  marketplaceJoinableAccounts?: Resolver<
    Maybe<ResolversTypes['MarketplaceJoinableAccountsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceJoinableAccountsArgs, 'campaignId'>
  >;
  marketplaceJoinablePromotionMethods?: Resolver<
    Maybe<ResolversTypes['MarketplaceJoinablePromotionMethodsPayload']>,
    ParentType,
    ContextType
  >;
  marketplaceJoinedAccount?: Resolver<
    Maybe<ResolversTypes['MarketplaceJoinedAccountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceJoinedAccountArgs, 'campaignId'>
  >;
  marketplaceJoinedMethods?: Resolver<
    Maybe<ResolversTypes['MarketplaceJoinedMethodsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceJoinedMethodsArgs, 'input'>
  >;
  marketplaceJoinedMethodsV2?: Resolver<
    Maybe<ResolversTypes['MarketplaceJoinedMethodsPayloadV2']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceJoinedMethodsV2Args, 'input'>
  >;
  marketplacePost?: Resolver<
    Maybe<ResolversTypes['MarketplacePostDetail']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplacePostArgs, 'pk'>
  >;
  marketplacePostComments?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplacePostComment']>>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplacePostCommentsArgs, 'limit' | 'postId'>
  >;
  marketplacePostHistory?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['MarketplacePostHistory']>>,
    ParentType,
    ContextType,
    Partial<QuerymarketplacePostHistoryArgs>
  >;
  marketplacePostsNoRevenue?: Resolver<
    Maybe<ResolversTypes['MarketplacePostsNoRevenue']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplacePostsNoRevenueArgs, 'marketplacePostIds'>
  >;
  marketplaceTracking?: Resolver<
    Maybe<ResolversTypes['MarketplaceTracking']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceTrackingArgs, 'marketplaceId'>
  >;
  marketplaceTrackingPostUrlForInfluencer?: Resolver<
    Maybe<ResolversTypes['MarketplaceTrackingPostUrlForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QuerymarketplaceTrackingPostUrlForInfluencerArgs, 'marketplaceId'>
  >;
  mobileAppVersion?: Resolver<ResolversTypes['MobileVersion'], ParentType, ContextType>;
  needReconnectForFacebook?: Resolver<
    Maybe<ResolversTypes['NeedReconnectForFacebookPayload']>,
    ParentType,
    ContextType
  >;
  netsuiteCustomerList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['NetSuiteCustomerData']>>,
    ParentType,
    ContextType,
    Partial<QuerynetsuiteCustomerListArgs>
  >;
  netsuiteCustomerName?: Resolver<
    Maybe<ResolversTypes['NetSuiteCustomerDataForSearch']>,
    ParentType,
    ContextType,
    RequireFields<QuerynetsuiteCustomerNameArgs, 'netsuiteId'>
  >;
  netsuiteVendorList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['NetSuiteVendorData']>>,
    ParentType,
    ContextType,
    Partial<QuerynetsuiteVendorListArgs>
  >;
  netsuiteVendorName?: Resolver<
    Maybe<ResolversTypes['NetSuiteVendorDataForSearch']>,
    ParentType,
    ContextType,
    RequireFields<QuerynetsuiteVendorNameArgs, 'netsuiteId'>
  >;
  openAiMessage?: Resolver<
    Maybe<ResolversTypes['OpenAIMessage']>,
    ParentType,
    ContextType,
    RequireFields<QueryopenAiMessageArgs, 'runId' | 'threadSystemId'>
  >;
  package?: Resolver<
    Maybe<ResolversTypes['ReadPackage']>,
    ParentType,
    ContextType,
    RequireFields<QuerypackageArgs, 'packageId'>
  >;
  packageInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ReadPackageInfluencers']>>,
    ParentType,
    ContextType,
    RequireFields<QuerypackageInfluencersArgs, 'packageId'>
  >;
  packageProposal?: Resolver<
    Maybe<ResolversTypes['PackageProposal']>,
    ParentType,
    ContextType,
    RequireFields<QuerypackageProposalArgs, 'limit' | 'offset' | 'packageId'>
  >;
  packageProposalSummary?: Resolver<
    Maybe<ResolversTypes['PackageProposalSummary']>,
    ParentType,
    ContextType,
    RequireFields<QuerypackageProposalSummaryArgs, 'packageId'>
  >;
  partnerAgency?: Resolver<
    Maybe<ResolversTypes['PartnerAgencyWithCompanies']>,
    ParentType,
    ContextType,
    RequireFields<QuerypartnerAgencyArgs, 'pk'>
  >;
  partnerAgencyBusinessAssociates?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AssociateCompany']>>,
    ParentType,
    ContextType,
    RequireFields<QuerypartnerAgencyBusinessAssociatesArgs, 'limit' | 'offset' | 'partnerAgencyId'>
  >;
  partnerAgencyBusinessAssociatesCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerypartnerAgencyBusinessAssociatesCountArgs, 'partnerAgencyId'>
  >;
  partnerAgencyPermission?: Resolver<Maybe<ResolversTypes['PartnerAgencyWithPermission']>, ParentType, ContextType>;
  partnerReconnectAnalyticsSocialAuthRedirectUrl?: Resolver<
    Maybe<ResolversTypes['PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerypartnerReconnectAnalyticsSocialAuthRedirectUrlArgs, 'callbackUrl' | 'provider'>
  >;
  partnerYoutubeAnalyticsPost?: Resolver<
    ResolversTypes['YoutubeAnalyticsPostPayload'],
    ParentType,
    ContextType,
    RequireFields<QuerypartnerYoutubeAnalyticsPostArgs, 'partnerInfluencerAccountId' | 'youtubeAnalyticsPostId'>
  >;
  payableCampaign?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PayableCampaign']>>, ParentType, ContextType>;
  paymentInformation?: Resolver<
    Maybe<ResolversTypes['PaymentInformation']>,
    ParentType,
    ContextType,
    RequireFields<QuerypaymentInformationArgs, 'influencerId'>
  >;
  paymentPackageStatus?: Resolver<
    Maybe<ResolversTypes['PaymentPackageStatusResponse']>,
    ParentType,
    ContextType,
    RequireFields<QuerypaymentPackageStatusArgs, 'sessionId'>
  >;
  paymentRequest?: Resolver<Maybe<ResolversTypes['PaymentRequest']>, ParentType, ContextType>;
  pricingTable?: Resolver<
    Maybe<ResolversTypes['PricingTable']>,
    ParentType,
    ContextType,
    RequireFields<QuerypricingTableArgs, 'planType'>
  >;
  refreshProfileAccountAvailability?: Resolver<
    Maybe<ResolversTypes['RefreshProfileAccountAvailabilityPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryrefreshProfileAccountAvailabilityArgs, 'input'>
  >;
  refreshTwitterAccountAvailability?: Resolver<
    Maybe<ResolversTypes['RefreshTwitterAccountAvailabilityPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryrefreshTwitterAccountAvailabilityArgs, 'input'>
  >;
  reportForInfluencer?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ReportForInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QueryreportForInfluencerArgs, 'limit' | 'offset'>
  >;
  requestedBcaBrands?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['RequestedBCABrand']>>,
    ParentType,
    ContextType,
    RequireFields<QueryrequestedBcaBrandsArgs, 'influencerId'>
  >;
  searchEngCampaignsSelectInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['EngagementCampaignSearchSelectInfluencers']>>,
    ParentType,
    ContextType,
    RequireFields<QuerysearchEngCampaignsSelectInfluencersArgs, 'currentCampaignId' | 'limit' | 'offset'>
  >;
  searchEngCampaignsSelectInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerysearchEngCampaignsSelectInfluencersCountArgs, 'currentCampaignId'>
  >;
  searchPrivatePartnerInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PrivatePartnerInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QuerysearchPrivatePartnerInfluencersArgs, 'alreadySelectedInfluencers' | 'keyword' | 'partnerId'>
  >;
  searchPrivateTalentInfluencers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['PrivateTalentInfluencer']>>,
    ParentType,
    ContextType,
    RequireFields<QuerysearchPrivateTalentInfluencersArgs, 'alreadySelectedInfluencers' | 'keyword' | 'talentAgencyId'>
  >;
  selectableBcaBrands?: Resolver<
    Maybe<ResolversTypes['SelectableBCABrands']>,
    ParentType,
    ContextType,
    RequireFields<QueryselectableBcaBrandsArgs, 'influencerId'>
  >;
  shopifyAuthRedirectUrl?: Resolver<
    Maybe<ResolversTypes['ShopifyAuthRedirectUrlPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryshopifyAuthRedirectUrlArgs, 'input'>
  >;
  socialAuthCheckFacebookToken?: Resolver<
    Maybe<ResolversTypes['SocialAuthCheckFacebookTokenPayload']>,
    ParentType,
    ContextType
  >;
  socialAuthRedirectUrlForInfluencer?: Resolver<
    Maybe<ResolversTypes['SocialAuthRedirectUrlForInfluencerPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerysocialAuthRedirectUrlForInfluencerArgs, 'input'>
  >;
  sponsoredPosts?: Resolver<
    Maybe<ResolversTypes['InfluencerSponsoredPostResponse']>,
    ParentType,
    ContextType,
    RequireFields<QuerysponsoredPostsArgs, 'influencerId' | 'socialAccountId' | 'socialMedia'>
  >;
  sponsoredPostsCount?: Resolver<
    Maybe<ResolversTypes['InfluencerSponsoredPostCountResponse']>,
    ParentType,
    ContextType,
    RequireFields<QuerysponsoredPostsCountArgs, 'influencerId' | 'socialAccountId' | 'socialMedia'>
  >;
  subscriptionPlan?: Resolver<Maybe<ResolversTypes['SubscriptionPlan']>, ParentType, ContextType>;
  talentAgency?: Resolver<
    Maybe<ResolversTypes['TalentAgencyWithCompanies']>,
    ParentType,
    ContextType,
    RequireFields<QuerytalentAgencyArgs, 'pk'>
  >;
  talentAgencyBusinessAssociateCompanies?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AssociateCompany']>>,
    ParentType,
    ContextType,
    RequireFields<QuerytalentAgencyBusinessAssociateCompaniesArgs, 'limit' | 'offset' | 'talentAgencyId'>
  >;
  talentAgencyBusinessAssociateCompaniesCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerytalentAgencyBusinessAssociateCompaniesCountArgs, 'talentAgencyId'>
  >;
  talentAgencyReconnectAnalyticsSocialAuthRedirectUrl?: Resolver<
    Maybe<ResolversTypes['TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerytalentAgencyReconnectAnalyticsSocialAuthRedirectUrlArgs, 'callbackUrl' | 'provider'>
  >;
  talentInfluencerDetailsForEdit?: Resolver<
    Maybe<ResolversTypes['TalentInfluencerDetailsForEditPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerytalentInfluencerDetailsForEditArgs, 'input'>
  >;
  tiktokAdAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TiktokAdAccountInfo']>>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokAdAccountsArgs, 'tkSpecialCampaignId'>
  >;
  tiktokAdAuthRedirectUrl?: Resolver<
    Maybe<ResolversTypes['TiktokAuthRedirectUrl']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokAdAuthRedirectUrlArgs, 'callbackUrl'>
  >;
  tiktokAdCampaigns?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TiktokAdCampaign']>>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokAdCampaignsArgs, 'adAccountSystemId' | 'tkSpecialCampaignId'>
  >;
  tiktokAdGroups?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TiktokAdGroup']>>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokAdGroupsArgs, 'adAccountSystemId' | 'adCampaignId' | 'tkSpecialCampaignId'>
  >;
  tiktokAdvertiserAccountInfo?: Resolver<Maybe<ResolversTypes['TiktokAdvertiserAccount']>, ParentType, ContextType>;
  tiktokAdvertiserPlanData?: Resolver<
    Maybe<ResolversTypes['TiktokAdvertiserPlanData']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokAdvertiserPlanDataArgs, 'advertiserId'>
  >;
  tiktokAdvertiserSummaryData?: Resolver<Maybe<ResolversTypes['TiktokAdvertiserSummaryData']>, ParentType, ContextType>;
  tiktokBudgetAllocation?: Resolver<
    Maybe<ResolversTypes['TiktokBudgetAllocation']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokBudgetAllocationArgs, 'advertiserId'>
  >;
  tiktokBusinessAccountInfo?: Resolver<Maybe<ResolversTypes['TiktokBusinessAccountInfo']>, ParentType, ContextType>;
  tiktokBusinessAdAccounts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TiktokAdAccountInfo']>>,
    ParentType,
    ContextType
  >;
  tiktokProfileAudience?: Resolver<
    Maybe<ResolversTypes['TiktokAudienceSection']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokProfileAudienceArgs, 'pk' | 'socialAccountId'>
  >;
  tiktokSpecialCampaign?: Resolver<
    Maybe<ResolversTypes['TikTokSpecialCampaignDetail']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialCampaignArgs, 'id'>
  >;
  tiktokSpecialCampaignForInfluencerSearchJob?: Resolver<
    Maybe<ResolversTypes['TikTokSpecialCampaignDetailForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialCampaignForInfluencerSearchJobArgs, 'id'>
  >;
  tiktokSpecialCampaignForInfluencerYourJob?: Resolver<
    Maybe<ResolversTypes['TikTokSpecialCampaignDetailForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialCampaignForInfluencerYourJobArgs, 'id'>
  >;
  tiktokSpecialCampaignPostReportForInfluencer?: Resolver<
    Maybe<ResolversTypes['TikTokSpecialCampaignPostReportForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialCampaignPostReportForInfluencerArgs, 'campaignId'>
  >;
  tiktokSpecialCampaignPosts?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TikTokSpecialCampaignPost']>>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialCampaignPostsArgs, 'campaignId' | 'limit' | 'offset'>
  >;
  tiktokSpecialCampaignPostsCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialCampaignPostsCountArgs, 'campaignId'>
  >;
  tiktokSpecialCampaignReport?: Resolver<
    Maybe<ResolversTypes['TiktokSpecialCampaignReport']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialCampaignReportArgs, 'campaignId'>
  >;
  tiktokSpecialCampaignReportGrid?: Resolver<
    Maybe<ResolversTypes['CampaignGridReport']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialCampaignReportGridArgs, 'campaignId'>
  >;
  tiktokSpecialCampaignReportSummary?: Resolver<
    Maybe<ResolversTypes['TiktokSpecialCampaignReportSummary']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialCampaignReportSummaryArgs, 'campaignId'>
  >;
  tiktokSpecialJoinableAccounts?: Resolver<
    Maybe<ResolversTypes['TiktokSpecialJoinableAccountsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialJoinableAccountsArgs, 'campaignId'>
  >;
  tiktokSpecialJoinedAccount?: Resolver<
    Maybe<ResolversTypes['TikTokSpecialJoinedAccountPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialJoinedAccountArgs, 'campaignId'>
  >;
  tiktokSpecialPackageInfluencers?: Resolver<
    ReadonlyArray<ResolversTypes['TikTokSpecialPackageInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialPackageInfluencersArgs, 'limit' | 'offset' | 'packageId'>
  >;
  tiktokSpecialPackageInfluencersCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialPackageInfluencersCountArgs, 'packageId'>
  >;
  tiktokSpecialPost?: Resolver<
    Maybe<ResolversTypes['TikTokSpecialPostDetail']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialPostArgs, 'pk'>
  >;
  tiktokSpecialPostHistory?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['TikTokSpecialPostHistory']>>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokSpecialPostHistoryArgs, 'pk'>
  >;
  tiktokTagPostDetails?: Resolver<
    ResolversTypes['TiktokUserTagPostDetailsPayload'],
    ParentType,
    ContextType,
    RequireFields<QuerytiktokTagPostDetailsArgs, 'postId'>
  >;
  tiktokUserHashtagPostList?: Resolver<
    Maybe<ResolversTypes['TiktokUserHashtagListPostsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokUserHashtagPostListArgs, 'date' | 'limit' | 'offset' | 'tagId'>
  >;
  tiktokUserHashtags?: Resolver<ResolversTypes['TiktokUserHashtagPayload'], ParentType, ContextType>;
  tiktokUserKeywordPostList?: Resolver<
    Maybe<ResolversTypes['TiktokUserHashtagListPostsPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerytiktokUserKeywordPostListArgs, 'date' | 'limit' | 'offset' | 'tagId'>
  >;
  tiktokUserKeywords?: Resolver<ResolversTypes['TiktokUserKeywordPayload'], ParentType, ContextType>;
  totalInfluencerReport?: Resolver<Maybe<ResolversTypes['TotalInfluencerReport']>, ParentType, ContextType>;
  trendingFacebookInfluencers?: Resolver<
    ResolversTypes['TrendingFacebookInfluencerSearchResult'],
    ParentType,
    ContextType,
    RequireFields<QuerytrendingFacebookInfluencersArgs, 'categoryIds' | 'followerGrowthPeriod' | 'limit' | 'offset'>
  >;
  trendingInstagramInfluencersV2?: Resolver<
    ResolversTypes['TrendingInstagramInfluencerSearchResultV2'],
    ParentType,
    ContextType,
    RequireFields<QuerytrendingInstagramInfluencersV2Args, 'followerGrowthPeriod' | 'limit' | 'offset'>
  >;
  trendingYoutubeInfluencersV2?: Resolver<
    ResolversTypes['TrendingYoutubeInfluencerSearchResultV2'],
    ParentType,
    ContextType,
    RequireFields<QuerytrendingYoutubeInfluencersV2Args, 'followerGrowthPeriod' | 'limit' | 'offset'>
  >;
  twitterAnalyticsOverview?: Resolver<
    ResolversTypes['TwitterAnalyticsOverviewPayload'],
    ParentType,
    ContextType,
    RequireFields<QuerytwitterAnalyticsOverviewArgs, 'endDate' | 'startDate' | 'twitterAnalyticsAccountId'>
  >;
  twitterAnalyticsPost?: Resolver<
    ResolversTypes['TwitterAnalyticsPostByIdPayload'],
    ParentType,
    ContextType,
    RequireFields<QuerytwitterAnalyticsPostArgs, 'twitterAnalyticsAccountId' | 'twitterAnalyticsPostId'>
  >;
  twitterAnalyticsPosts?: Resolver<
    ResolversTypes['TwitterAnalyticsPostsPayload'],
    ParentType,
    ContextType,
    RequireFields<QuerytwitterAnalyticsPostsArgs, 'limit' | 'offset' | 'orderBy' | 'twitterAnalyticsAccountId'>
  >;
  twitterAnalyticsPostsByDate?: Resolver<
    ResolversTypes['TwitterAnalyticsPostsByDatePayload'],
    ParentType,
    ContextType,
    RequireFields<QuerytwitterAnalyticsPostsByDateArgs, 'date' | 'twitterAnalyticsAccountId'>
  >;
  twitterAnalyticsPostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QuerytwitterAnalyticsPostsCountArgs, 'twitterAnalyticsAccountId'>
  >;
  twitterAnalyticsPostsStatistic?: Resolver<
    Maybe<ResolversTypes['TwitterAnalyticsPostsStatisticPayload']>,
    ParentType,
    ContextType,
    RequireFields<QuerytwitterAnalyticsPostsStatisticArgs, 'endDate' | 'startDate' | 'twitterAnalyticsAccountId'>
  >;
  updatePaymentMethodLink?: Resolver<
    Maybe<ResolversTypes['StripeDeepLink']>,
    ParentType,
    ContextType,
    RequireFields<QueryupdatePaymentMethodLinkArgs, 'redirectUrl'>
  >;
  user?: Resolver<Maybe<ResolversTypes['UserDetail']>, ParentType, ContextType, RequireFields<QueryuserArgs, 'pk'>>;
  userProposal?: Resolver<Maybe<ResolversTypes['UserProposal']>, ParentType, ContextType>;
  wordCloudList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['InfluencerWordCloud']>>,
    ParentType,
    ContextType,
    RequireFields<QuerywordCloudListArgs, 'influencerId' | 'socialAccountId' | 'socialMedia'>
  >;
  xhsAccountInterest?: Resolver<
    Maybe<ResolversTypes['XhsAccountInterest']>,
    ParentType,
    ContextType,
    RequireFields<QueryxhsAccountInterestArgs, 'pk' | 'socialAccountId'>
  >;
  xhsProfileAudience?: Resolver<
    Maybe<ResolversTypes['XhsAudienceSection']>,
    ParentType,
    ContextType,
    RequireFields<QueryxhsProfileAudienceArgs, 'pk' | 'socialAccountId'>
  >;
  xhsProfileEngagementInsights?: Resolver<
    Maybe<ResolversTypes['XhsEngagementInsightSection']>,
    ParentType,
    ContextType,
    RequireFields<QueryxhsProfileEngagementInsightsArgs, 'pk' | 'postType' | 'socialAccountId'>
  >;
  xhsProfilePosts?: Resolver<
    Maybe<ResolversTypes['XhsPostSection']>,
    ParentType,
    ContextType,
    RequireFields<QueryxhsProfilePostsArgs, 'pk' | 'socialAccountId'>
  >;
  youtubeAnalyticsAudience?: Resolver<
    ResolversTypes['YoutubeAnalyticsAudiencePayload'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsAudienceArgs, 'endDate' | 'startDate' | 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsComments?: Resolver<
    ResolversTypes['YoutubeAnalyticsCommentsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsCommentsArgs, 'limit' | 'offset' | 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsCommentsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsCommentsCountArgs, 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsCompareAccountCards?: Resolver<
    ResolversTypes['YoutubeAnalyticsCompareAccountCards'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsCompareAccountCardsArgs, 'mainAccountId'>
  >;
  youtubeAnalyticsCompareRelatedPosts?: Resolver<
    ResolversTypes['YoutubeAnalyticsCompareRelatedPosts'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsCompareRelatedPostsArgs, 'compareAccountId' | 'mainAccountId' | 'postId' | 'tag'>
  >;
  youtubeAnalyticsCountriesCategories?: Resolver<
    ResolversTypes['YoutubeAnalyticsCountriesCategoriesPayload'],
    ParentType,
    ContextType
  >;
  youtubeAnalyticsOverview?: Resolver<
    ResolversTypes['YoutubeAnalyticsOverviewPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsOverviewArgs, 'endDate' | 'startDate' | 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsPost?: Resolver<
    ResolversTypes['YoutubeAnalyticsPostWithChannelDetailsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsPostArgs, 'youtubeAnalyticsAccountId' | 'youtubeAnalyticsPostId'>
  >;
  youtubeAnalyticsPosts?: Resolver<
    ResolversTypes['YoutubeAnalyticsPostsPayloads'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsPostsArgs, 'limit' | 'offset' | 'orderBy' | 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsPostsByDate?: Resolver<
    ResolversTypes['YoutubeAnalyticsPostPayloads'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsPostsByDateArgs, 'date' | 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsPostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsPostsCountArgs, 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsPostsStatistics?: Resolver<
    ResolversTypes['YoutubeAnalyticsPostsStatisticsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsPostsStatisticsArgs, 'endDate' | 'startDate' | 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsRelatedPosts?: Resolver<
    ResolversTypes['YoutubeAnalyticsRelatedPostsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsRelatedPostsArgs, 'tag' | 'youtubeAnalyticsAccountId' | 'youtubeAnalyticsPostId'>
  >;
  youtubeAnalyticsShorts?: Resolver<
    ResolversTypes['YoutubeAnalyticsShortsPayloads'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsShortsArgs, 'limit' | 'offset' | 'orderBy' | 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsShortsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsShortsCountArgs, 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsTagsTopRanking?: Resolver<
    ResolversTypes['YoutubeAnalyticsTagRankingPayloads'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsTagsTopRankingArgs, 'limit' | 'offset' | 'orderBy' | 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsTagsTopRankingCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsTagsTopRankingCountArgs, 'youtubeAnalyticsAccountId'>
  >;
  youtubeAnalyticsTrendPostById?: Resolver<
    ResolversTypes['YoutubeAnalyticsTrendPostDetailsPayload'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsTrendPostByIdArgs, 'id'>
  >;
  youtubeAnalyticsTrends?: Resolver<
    ResolversTypes['YoutubeAnalyticsTrendsPayloads'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsTrendsArgs, 'category' | 'country' | 'limit' | 'offset'>
  >;
  youtubeAnalyticsTrendsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeAnalyticsTrendsCountArgs, 'category' | 'country'>
  >;
  youtubeBrandAccounts?: Resolver<
    Maybe<ResolversTypes['YouTubeBrandAccounts']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeBrandAccountsArgs, 'influencerId' | 'youtubeAccountSystemId'>
  >;
  youtubeCmsChannelDetail?: Resolver<
    Maybe<ResolversTypes['YoutubeCmsChannelDetail']>,
    ParentType,
    ContextType,
    Partial<QueryyoutubeCmsChannelDetailArgs>
  >;
  youtubeCmsChannelInfo?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['YoutubeCmsChannelInfo']>>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsChannelInfoArgs, 'channelIds'>
  >;
  youtubeCmsChannelsForInfluencer?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeCmsChannelForInfluencer']>,
    ParentType,
    ContextType,
    Partial<QueryyoutubeCmsChannelsForInfluencerArgs>
  >;
  youtubeCmsConfirmedAssetsSummary?: Resolver<
    Maybe<ResolversTypes['YoutubeCmsConfirmedChannelSummary']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsConfirmedAssetsSummaryArgs, 'month'>
  >;
  youtubeCmsConfirmedChannelsForInfluencer?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeCmsChannelForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsConfirmedChannelsForInfluencerArgs, 'month'>
  >;
  youtubeCmsConfirmedChannelsSummary?: Resolver<
    Maybe<ResolversTypes['YoutubeCmsConfirmedChannelSummary']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsConfirmedChannelsSummaryArgs, 'month'>
  >;
  youtubeCmsConfirmedMusicsForInfluencer?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeCmsChannelForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsConfirmedMusicsForInfluencerArgs, 'month'>
  >;
  youtubeCmsConfirmedRevenueForInfluencer?: Resolver<
    Maybe<ResolversTypes['YouTubeCmsRevenueForInfluencer']>,
    ParentType,
    ContextType,
    Partial<QueryyoutubeCmsConfirmedRevenueForInfluencerArgs>
  >;
  youtubeCmsConfirmedRevenueLatestMonth?: Resolver<
    Maybe<ResolversTypes['YoutubeCmsConfirmedRevenueLatestMonth']>,
    ParentType,
    ContextType
  >;
  youtubeCmsConfirmedVideosForInfluencer?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeCmsChannelForInfluencer']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsConfirmedVideosForInfluencerArgs, 'month'>
  >;
  youtubeCmsEstimateAssetsSummary?: Resolver<
    Maybe<ResolversTypes['YoutubeCmsEstimateChannelSummary']>,
    ParentType,
    ContextType,
    Partial<QueryyoutubeCmsEstimateAssetsSummaryArgs>
  >;
  youtubeCmsEstimateChannelsSummary?: Resolver<
    Maybe<ResolversTypes['YoutubeCmsEstimateChannelSummary']>,
    ParentType,
    ContextType,
    Partial<QueryyoutubeCmsEstimateChannelsSummaryArgs>
  >;
  youtubeCmsInvoiceCount?: Resolver<
    Maybe<ResolversTypes['TotalNumber']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsInvoiceCountArgs, 'month'>
  >;
  youtubeCmsInvoiceList?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['YoutubeCmsInvoice']>>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsInvoiceListArgs, 'limit' | 'month' | 'offset'>
  >;
  youtubeCmsInvoiceListForInfluencer?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['YoutubeCmsInvoiceForInfluencer']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsInvoiceListForInfluencerArgs, 'year'>
  >;
  youtubeCmsMusicsForInfluencer?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeCmsMusicForInfluencer']>,
    ParentType,
    ContextType,
    Partial<QueryyoutubeCmsMusicsForInfluencerArgs>
  >;
  youtubeCmsRevenueForInfluencer?: Resolver<
    Maybe<ResolversTypes['YouTubeCmsRevenueForInfluencer']>,
    ParentType,
    ContextType,
    Partial<QueryyoutubeCmsRevenueForInfluencerArgs>
  >;
  youtubeCmsStartMonthForInfluencer?: Resolver<Maybe<ResolversTypes['YoutubeCmsStartMonth']>, ParentType, ContextType>;
  youtubeCmsVideoAudience?: Resolver<
    Maybe<ResolversTypes['YouTubeCmsVideoAudience']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsVideoAudienceArgs, 'month' | 'videoId'>
  >;
  youtubeCmsVideoStats?: Resolver<
    Maybe<ResolversTypes['YouTubeCmsVideoStats']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCmsVideoStatsArgs, 'month' | 'videoId'>
  >;
  youtubeCmsVideosForInfluencer?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeCmsVideo']>,
    ParentType,
    ContextType,
    Partial<QueryyoutubeCmsVideosForInfluencerArgs>
  >;
  youtubeCompareAllPostsInDate?: Resolver<
    Maybe<ResolversTypes['YoutubeCompareAllPostsInDate']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCompareAllPostsInDateArgs, 'date' | 'mainAccountId'>
  >;
  youtubeCompareOverview?: Resolver<
    ResolversTypes['YoutubeCompareOverview'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCompareOverviewArgs, 'endDate' | 'mainAccountId' | 'startDate'>
  >;
  youtubeComparePostById?: Resolver<
    Maybe<ResolversTypes['YoutubeComparePost']>,
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeComparePostByIdArgs, 'compareAccountId' | 'mainAccountId' | 'postId'>
  >;
  youtubeComparePostList?: Resolver<
    ResolversTypes['YoutubeComparePostList'],
    ParentType,
    ContextType,
    RequireFields<
      QueryyoutubeComparePostListArgs,
      'compareAccountId' | 'limit' | 'mainAccountId' | 'offset' | 'orderBy'
    >
  >;
  youtubeComparePosts?: Resolver<
    ResolversTypes['YoutubeComparePosts'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeComparePostsArgs, 'mainAccountId'>
  >;
  youtubeComparePostsCount?: Resolver<
    ResolversTypes['TotalNumber'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeComparePostsCountArgs, 'compareAccountId' | 'mainAccountId'>
  >;
  youtubeCompareTagRanking?: Resolver<
    ResolversTypes['YoutubeCompareTagRanking'],
    ParentType,
    ContextType,
    RequireFields<QueryyoutubeCompareTagRankingArgs, 'endDate' | 'mainAccountId' | 'startDate'>
  >;
  youtubeMusicCategories?: Resolver<ReadonlyArray<ResolversTypes['YoutubeMusicCategory']>, ParentType, ContextType>;
  youtubeMusicList?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeMusicItem']>,
    ParentType,
    ContextType,
    Partial<QueryyoutubeMusicListArgs>
  >;
}>;

export type ReSendBcaRequestPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReSendBcaRequestPayload'] = ResolversParentTypes['ReSendBcaRequestPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReadCampaignIdentifierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReadCampaignIdentifier'] = ResolversParentTypes['ReadCampaignIdentifier']
> = ResolversObject<{
  campaignDetailType?: Resolver<Maybe<ResolversTypes['MarketplaceCampaignDetailType']>, ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  campaignType?: Resolver<ResolversTypes['CampaignIdentifierType'], ParentType, ContextType>;
  hasPostTracking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReadPackageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReadPackage'] = ResolversParentTypes['ReadPackage']
> = ResolversObject<{
  companies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['PackageCompany']>>>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReadPackageAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReadPackageAccount'] = ResolversParentTypes['ReadPackageAccount']
> = ResolversObject<{
  accounts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  campaignSelected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  companies?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PackageCompany']>>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReadPackageInfluencersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReadPackageInfluencers'] = ResolversParentTypes['ReadPackageInfluencers']
> = ResolversObject<{
  age?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  averageEngagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  averageEngagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageEngagementViewsRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  engagementProposedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isAnySignedUpAccountForFacebook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAnySignedUpAccountForInstagram?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAnySignedUpAccountForTiktok?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAnySignedUpAccountForTwitter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAnySignedUpAccountForYoutube?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccounts?: Resolver<ReadonlyArray<ResolversTypes['ReadSocialAccountForPackage']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReadSocialAccountForPackageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReadSocialAccountForPackage'] = ResolversParentTypes['ReadSocialAccountForPackage']
> = ResolversObject<{
  averageEngagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  averageEngagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReadTutorialsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReadTutorials'] = ResolversParentTypes['ReadTutorials']
> = ResolversObject<{
  readTutorials?: Resolver<ReadonlyArray<ResolversTypes['TutorialName']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RechargeAdvertiserAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RechargeAdvertiserAccount'] = ResolversParentTypes['RechargeAdvertiserAccount']
> = ResolversObject<{
  checkoutUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecipientDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecipientDetail'] = ResolversParentTypes['RecipientDetail']
> = ResolversObject<{
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fanId?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statuses?: Resolver<ReadonlyArray<ResolversTypes['EmailEventStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecipientItemPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecipientItemPayload'] = ResolversParentTypes['RecipientItemPayload']
> = ResolversObject<{
  tagNames?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['RecipientType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReconnectTiktokAdAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReconnectTiktokAdAccount'] = ResolversParentTypes['ReconnectTiktokAdAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReconnectedAccountForLinkBioResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReconnectedAccountForLinkBio'] = ResolversParentTypes['ReconnectedAccountForLinkBio']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountType?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RefreshPasswordPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RefreshPasswordPayload'] = ResolversParentTypes['RefreshPasswordPayload']
> = ResolversObject<{
  ok?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RefreshProfileAccountAvailabilityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RefreshProfileAccountAvailabilityPayload'] = ResolversParentTypes['RefreshProfileAccountAvailabilityPayload']
> = ResolversObject<{
  status?: Resolver<ResolversTypes['RefreshProfileAvailabilityStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RefreshProfileAccountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RefreshProfileAccountPayload'] = ResolversParentTypes['RefreshProfileAccountPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RefreshTwitterAccountAvailabilityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RefreshTwitterAccountAvailabilityPayload'] = ResolversParentTypes['RefreshTwitterAccountAvailabilityPayload']
> = ResolversObject<{
  isRefreshAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RefreshTwitterAccountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RefreshTwitterAccountPayload'] = ResolversParentTypes['RefreshTwitterAccountPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Region'] = ResolversParentTypes['Region']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegionNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegionName'] = ResolversParentTypes['RegionName']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegisterPushTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegisterPushToken'] = ResolversParentTypes['RegisterPushToken']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RejectEngagementDraftPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RejectEngagementDraftPost'] = ResolversParentTypes['RejectEngagementDraftPost']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RejectMarketplaceCampaignResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RejectMarketplaceCampaign'] = ResolversParentTypes['RejectMarketplaceCampaign']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RejectMarketplaceDraftPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RejectMarketplaceDraftPost'] = ResolversParentTypes['RejectMarketplaceDraftPost']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RejectTikTokSpecialCampaignResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RejectTikTokSpecialCampaign'] = ResolversParentTypes['RejectTikTokSpecialCampaign']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveAllFormResponsesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveAllFormResponsesPayload'] = ResolversParentTypes['RemoveAllFormResponsesPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveAllSelectedInfluencersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveAllSelectedInfluencers'] = ResolversParentTypes['RemoveAllSelectedInfluencers']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveCreatorStaffPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveCreatorStaffPayload'] = ResolversParentTypes['RemoveCreatorStaffPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveEmailsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveEmailsPayload'] = ResolversParentTypes['RemoveEmailsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveFansPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveFansPayload'] = ResolversParentTypes['RemoveFansPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveFormResponsePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveFormResponsePayload'] = ResolversParentTypes['RemoveFormResponsePayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveFormsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveFormsPayload'] = ResolversParentTypes['RemoveFormsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveSelectedInfluencersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveSelectedInfluencers'] = ResolversParentTypes['RemoveSelectedInfluencers']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveShopifyAccountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveShopifyAccountPayload'] = ResolversParentTypes['RemoveShopifyAccountPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReorderMarketplaceCampaignsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReorderMarketplaceCampaigns'] = ResolversParentTypes['ReorderMarketplaceCampaigns']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReportEngagementPostStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReportEngagementPostStatus'] = ResolversParentTypes['ReportEngagementPostStatus']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReportForInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportForInfluencer'] = ResolversParentTypes['ReportForInfluencer']
> = ResolversObject<{
  campaign?: Resolver<ResolversTypes['CampaignReportCampaignInfoForInfluencer'], ParentType, ContextType>;
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  like?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postStatus?: Resolver<Maybe<ResolversTypes['CampaignPostStatusForInfluencer']>, ParentType, ContextType>;
  revenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  share?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReportMarketplacePostStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReportMarketplacePostStatus'] = ResolversParentTypes['ReportMarketplacePostStatus']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReportTiktokSpecialPostStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReportTiktokSpecialPostStatus'] = ResolversParentTypes['ReportTiktokSpecialPostStatus']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestDeleteAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestDeleteAccount'] = ResolversParentTypes['RequestDeleteAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestGoogleSlideCustomisedReportResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestGoogleSlideCustomisedReport'] = ResolversParentTypes['RequestGoogleSlideCustomisedReport']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestGoogleSlideReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestGoogleSlideReport'] = ResolversParentTypes['RequestGoogleSlideReport']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestInfluencerProfilePptxResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestInfluencerProfilePptx'] = ResolversParentTypes['RequestInfluencerProfilePptx']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestInfluencersProfilePptxResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestInfluencersProfilePptx'] = ResolversParentTypes['RequestInfluencersProfilePptx']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestInfluencersSocialAccountPptxResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestInfluencersSocialAccountPptx'] = ResolversParentTypes['RequestInfluencersSocialAccountPptx']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestInstagramUGCManagementPostOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestInstagramUGCManagementPostOutput'] = ResolversParentTypes['RequestInstagramUGCManagementPostOutput']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestPackageProposalExcelResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestPackageProposalExcel'] = ResolversParentTypes['RequestPackageProposalExcel']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestPackageProposalPptxResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestPackageProposalPptx'] = ResolversParentTypes['RequestPackageProposalPptx']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestPackageSpreadsheetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestPackageSpreadsheet'] = ResolversParentTypes['RequestPackageSpreadsheet']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestPowerpointCustomisedReportResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestPowerpointCustomisedReport'] = ResolversParentTypes['RequestPowerpointCustomisedReport']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestPowerpointReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestPowerpointReport'] = ResolversParentTypes['RequestPowerpointReport']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestProposalExcelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestProposalExcel'] = ResolversParentTypes['RequestProposalExcel']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestProposalPptxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestProposalPptx'] = ResolversParentTypes['RequestProposalPptx']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestProposalSpreadsheetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestProposalSpreadsheet'] = ResolversParentTypes['RequestProposalSpreadsheet']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestUserProposalExcelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestUserProposalExcel'] = ResolversParentTypes['RequestUserProposalExcel']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestUserProposalPptxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestUserProposalPptx'] = ResolversParentTypes['RequestUserProposalPptx']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestedBCABrandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestedBCABrand'] = ResolversParentTypes['RequestedBCABrand']
> = ResolversObject<{
  brand?: Resolver<ResolversTypes['RequestedBrandForBCA'], ParentType, ContextType>;
  instagramAccount?: Resolver<ResolversTypes['RequestedInstagramAccountForBCA'], ParentType, ContextType>;
  isResendable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['BcaRequestStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestedBrandForBCAResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestedBrandForBCA'] = ResolversParentTypes['RequestedBrandForBCA']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestedInstagramAccountForBCAResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RequestedInstagramAccountForBCA'] = ResolversParentTypes['RequestedInstagramAccountForBCA']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResendEmailForAdvertiserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResendEmailForAdvertiser'] = ResolversParentTypes['ResendEmailForAdvertiser']
> = ResolversObject<{
  ok?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResendInfluencerForgotPasswordEmailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ResendInfluencerForgotPasswordEmailPayload'] = ResolversParentTypes['ResendInfluencerForgotPasswordEmailPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResendInfluencerSignUpEmailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ResendInfluencerSignUpEmailPayload'] = ResolversParentTypes['ResendInfluencerSignUpEmailPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResendPartnerEmailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResendPartnerEmails'] = ResolversParentTypes['ResendPartnerEmails']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResetPasswordAndLoginPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ResetPasswordAndLoginPayload'] = ResolversParentTypes['ResetPasswordAndLoginPayload']
> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResetPasswordPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResetPasswordPayload'] = ResolversParentTypes['ResetPasswordPayload']
> = ResolversObject<{
  ok?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResolveChatPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResolveChatPayload'] = ResolversParentTypes['ResolveChatPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RichMessagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RichMessagePayload'] = ResolversParentTypes['RichMessagePayload']
> = ResolversObject<{
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SelectableBCABrandsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SelectableBCABrands'] = ResolversParentTypes['SelectableBCABrands']
> = ResolversObject<{
  brands?: Resolver<ReadonlyArray<ResolversTypes['SelectableBrandForBCA']>, ParentType, ContextType>;
  instagramAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['SelectableInstagramAccountForBCA']>,
    ParentType,
    ContextType
  >;
  isBcaAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SelectableBrandForBCAResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SelectableBrandForBCA'] = ResolversParentTypes['SelectableBrandForBCA']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SelectableInstagramAccountForBCAResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SelectableInstagramAccountForBCA'] = ResolversParentTypes['SelectableInstagramAccountForBCA']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SelectableYoutubeAnalyticsChannelResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SelectableYoutubeAnalyticsChannel'] = ResolversParentTypes['SelectableYoutubeAnalyticsChannel']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  subscribersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SendBcaRequestPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendBcaRequestPayload'] = ResolversParentTypes['SendBcaRequestPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SendContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendContact'] = ResolversParentTypes['SendContact']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SendGeminiMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendGeminiMessage'] = ResolversParentTypes['SendGeminiMessage']
> = ResolversObject<{
  message?: Resolver<ResolversTypes['GeminiMessage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SendLineBroadcastMessagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SendLineBroadcastMessagePayload'] = ResolversParentTypes['SendLineBroadcastMessagePayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SendMessagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendMessagePayload'] = ResolversParentTypes['SendMessagePayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SendOpenAIMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendOpenAIMessage'] = ResolversParentTypes['SendOpenAIMessage']
> = ResolversObject<{
  message?: Resolver<ResolversTypes['OpenAIMessage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShopeeCustomCampaignPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ShopeeCustomCampaignPayload'] = ResolversParentTypes['ShopeeCustomCampaignPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShopifyAuthRedirectUrlPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ShopifyAuthRedirectUrlPayload'] = ResolversParentTypes['ShopifyAuthRedirectUrlPayload']
> = ResolversObject<{
  redirectUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShopifyValidateAuthPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ShopifyValidateAuthPayload'] = ResolversParentTypes['ShopifyValidateAuthPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SignedUrlDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SignedUrlData'] = ResolversParentTypes['SignedUrlData']
> = ResolversObject<{
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SimilarPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SimilarPost'] = ResolversParentTypes['SimilarPost']
> = ResolversObject<{
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  influencerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postType?: Resolver<ResolversTypes['SocialPostType'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SocialAccount'] = ResolversParentTypes['SocialAccount']
> = ResolversObject<{
  exclusive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAccountDescriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SocialAccountDescription'] = ResolversParentTypes['SocialAccountDescription']
> = ResolversObject<{
  socialAccountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountType?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAccountForProfileV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAccountForProfileV2'] = ResolversParentTypes['SocialAccountForProfileV2']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAccountPopularPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SocialAccountPopularPost'] = ResolversParentTypes['SocialAccountPopularPost']
> = ResolversObject<{
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SocialPostType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAccountV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SocialAccountV2'] = ResolversParentTypes['SocialAccountV2']
> = ResolversObject<{
  isAnySignedUpAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthCheckFacebookTokenPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthCheckFacebookTokenPayload'] = ResolversParentTypes['SocialAuthCheckFacebookTokenPayload']
> = ResolversObject<{
  needReconnect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthConnectableFacebookPageAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthConnectableFacebookPageAnalyticsPayload'] = ResolversParentTypes['SocialAuthConnectableFacebookPageAnalyticsPayload']
> = ResolversObject<{
  account?: Resolver<Maybe<ResolversTypes['ConnectableFacebookPageMainAccount']>, ParentType, ContextType>;
  pages?: Resolver<ReadonlyArray<ResolversTypes['ConnectableFacebookPageAccount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthConnectableInstagramAccountsAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthConnectableInstagramAccountsAnalyticsPayload'] = ResolversParentTypes['SocialAuthConnectableInstagramAccountsAnalyticsPayload']
> = ResolversObject<{
  accounts?: Resolver<ReadonlyArray<ResolversTypes['ConnectableOauthPageAndIgAccount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthConnectableTwitterAccountAnalyticsOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthConnectableTwitterAccountAnalyticsOutput'] = ResolversParentTypes['SocialAuthConnectableTwitterAccountAnalyticsOutput']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  twitterUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthForJoinCampaignPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthForJoinCampaignPayload'] = ResolversParentTypes['SocialAuthForJoinCampaignPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthReConnectInstagramPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthReConnectInstagramPayload'] = ResolversParentTypes['SocialAuthReConnectInstagramPayload']
> = ResolversObject<{
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthReconnectFacebookPageAccountAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthReconnectFacebookPageAccountAnalyticsPayload'] = ResolversParentTypes['SocialAuthReconnectFacebookPageAccountAnalyticsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthReconnectInstagramAccountAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthReconnectInstagramAccountAnalyticsPayload'] = ResolversParentTypes['SocialAuthReconnectInstagramAccountAnalyticsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthReconnectTwitterAccountAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthReconnectTwitterAccountAnalyticsPayload'] = ResolversParentTypes['SocialAuthReconnectTwitterAccountAnalyticsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthReconnectYouTubeAccountAnalyticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthReconnectYouTubeAccountAnalyticsPayload'] = ResolversParentTypes['SocialAuthReconnectYouTubeAccountAnalyticsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthRedirectUrlForInfluencerPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthRedirectUrlForInfluencerPayload'] = ResolversParentTypes['SocialAuthRedirectUrlForInfluencerPayload']
> = ResolversObject<{
  redirectUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthTalentSignInFacebookPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthTalentSignInFacebookPayload'] = ResolversParentTypes['SocialAuthTalentSignInFacebookPayload']
> = ResolversObject<{
  fbAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbFollowersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fbName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fbUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pages?: Resolver<
    ReadonlyArray<ResolversTypes['ConnectableOauthPageAndIgAccountForSignInFacebook']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthTalentSignInInstagramPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthTalentSignInInstagramPayload'] = ResolversParentTypes['SocialAuthTalentSignInInstagramPayload']
> = ResolversObject<{
  facebook?: Resolver<ResolversTypes['ConnectableOauthFacebookAccountForTalentSignIn'], ParentType, ContextType>;
  pagesAndIgAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['ConnectableOauthPageAndIgAccountForTalentSignIn']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthTalentSignInTwitterPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthTalentSignInTwitterPayload'] = ResolversParentTypes['SocialAuthTalentSignInTwitterPayload']
> = ResolversObject<{
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  twName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  twScreenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  twUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialAuthTalentSignInYouTubePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialAuthTalentSignInYouTubePayload'] = ResolversParentTypes['SocialAuthTalentSignInYouTubePayload']
> = ResolversObject<{
  channels?: Resolver<
    ReadonlyArray<ResolversTypes['ConnectableOauthYouTubeAccountForTalentSignIn']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StaffCommentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaffComment'] = ResolversParentTypes['StaffComment']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StateUpdateEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StateUpdateEvent'] = ResolversParentTypes['StateUpdateEvent']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StripeDeepLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StripeDeepLink'] = ResolversParentTypes['StripeDeepLink']
> = ResolversObject<{
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubmitFormInternalPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubmitFormInternalPayload'] = ResolversParentTypes['SubmitFormInternalPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubmitFormPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubmitFormPayload'] = ResolversParentTypes['SubmitFormPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscribeAnalyticsPlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscribeAnalyticsPlan'] = ResolversParentTypes['SubscribeAnalyticsPlan']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscribeAnalyticsTrialPlanResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscribeAnalyticsTrialPlan'] = ResolversParentTypes['SubscribeAnalyticsTrialPlan']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionAvailableFeaturesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionAvailableFeatures'] = ResolversParentTypes['SubscriptionAvailableFeatures']
> = ResolversObject<{
  compareEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dashboardEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hashtagsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  interactionEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionCapabilitiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscriptionCapabilities'] = ResolversParentTypes['SubscriptionCapabilities']
> = ResolversObject<{
  maxCompareAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxHashtags?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionPlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscriptionPlan'] = ResolversParentTypes['SubscriptionPlan']
> = ResolversObject<{
  analytics?: Resolver<Maybe<ResolversTypes['SubscriptionPlanDetail']>, ParentType, ContextType>;
  marketplace?: Resolver<Maybe<ResolversTypes['SubscriptionPlanDetail']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionPlanDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscriptionPlanDetail'] = ResolversParentTypes['SubscriptionPlanDetail']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiredAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isCanceled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionPlanForAdvertiserUserSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionPlanForAdvertiserUserSettings'] = ResolversParentTypes['SubscriptionPlanForAdvertiserUserSettings']
> = ResolversObject<{
  availableFeatures?: Resolver<ResolversTypes['SubscriptionAvailableFeatures'], ParentType, ContextType>;
  capabilities?: Resolver<ResolversTypes['SubscriptionCapabilities'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnalyticsSubscriptionPlanType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SudoLimitedLoginResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SudoLimitedLogin'] = ResolversParentTypes['SudoLimitedLogin']
> = ResolversObject<{
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SwitchInfluencerForStaffPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SwitchInfluencerForStaffPayload'] = ResolversParentTypes['SwitchInfluencerForStaffPayload']
> = ResolversObject<{
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SwitchRoleToAdminResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SwitchRoleToAdmin'] = ResolversParentTypes['SwitchRoleToAdmin']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SwitchRoleToAdvertiserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SwitchRoleToAdvertiser'] = ResolversParentTypes['SwitchRoleToAdvertiser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SwitchRoleToAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SwitchRoleToAgency'] = ResolversParentTypes['SwitchRoleToAgency']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SwitchRoleToPartnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SwitchRoleToPartner'] = ResolversParentTypes['SwitchRoleToPartner']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SwitchRoleToStaffResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SwitchRoleToStaff'] = ResolversParentTypes['SwitchRoleToStaff']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SwitchRoleToTalentAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SwitchRoleToTalentAgency'] = ResolversParentTypes['SwitchRoleToTalentAgency']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentAgency'] = ResolversParentTypes['TalentAgency']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentAgencyOrPartnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentAgencyOrPartner'] = ResolversParentTypes['TalentAgencyOrPartner']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  idOfTalentOrPartner?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload'] = ResolversParentTypes['TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload']
> = ResolversObject<{
  redirectUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentAgencyUserWithRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentAgencyUserWithRole'] = ResolversParentTypes['TalentAgencyUserWithRole']
> = ResolversObject<{
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentAgencyWithCompaniesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TalentAgencyWithCompanies'] = ResolversParentTypes['TalentAgencyWithCompanies']
> = ResolversObject<{
  advertiserIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  agencyIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  cmsMarginRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryName']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privateInfluencers?: Resolver<ReadonlyArray<ResolversTypes['PrivateTalentInfluencer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentAgencyWithNSVerificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TalentAgencyWithNSVerification'] = ResolversParentTypes['TalentAgencyWithNSVerification']
> = ResolversObject<{
  companyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryName']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  verifiedStatus?: Resolver<Maybe<ResolversTypes['NetSuiteVerifiedStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentInfluencerDetailsForEditPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TalentInfluencerDetailsForEditPayload'] = ResolversParentTypes['TalentInfluencerDetailsForEditPayload']
> = ResolversObject<{
  category?: Resolver<ReadonlyArray<ResolversTypes['Category']>, ParentType, ContextType>;
  connectedFacebook?: Resolver<Maybe<ResolversTypes['ConnectedFacebook']>, ParentType, ContextType>;
  connectedFacebookPages?: Resolver<ReadonlyArray<ResolversTypes['ConnectedPage']>, ParentType, ContextType>;
  connectedInstagrams?: Resolver<ReadonlyArray<ResolversTypes['ConnectedPageAndIgAccount']>, ParentType, ContextType>;
  connectedTwitters?: Resolver<ReadonlyArray<ResolversTypes['ConnectedTwitter']>, ParentType, ContextType>;
  connectedYouTubes?: Resolver<ReadonlyArray<ResolversTypes['ConnectedYouTube']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['Country'], ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultEngagementSelectionReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  internalMemo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  introduce?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isProAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['Region']>, ParentType, ContextType>;
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TextComponentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextComponentPayload'] = ResolversParentTypes['TextComponentPayload']
> = ResolversObject<{
  fontSize?: Resolver<ResolversTypes['FontSize'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weighted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ThreadsInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThreadsInfluencer'] = ResolversParentTypes['ThreadsInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  influencerFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  socialAccountAverageEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokAdChartReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TikTokAdChartReport'] = ResolversParentTypes['TikTokAdChartReport']
> = ResolversObject<{
  adCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  adImpressions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokAdReportSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TikTokAdReportSummary'] = ResolversParentTypes['TikTokAdReportSummary']
> = ResolversObject<{
  adBudget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adComments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCostPer1000Reached?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCostPerResult?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCpc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCtr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adEngagements?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adEr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adFollows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adFrequency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adImpressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adLikes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adResult?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adResultRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adShares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageVideoPlay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageVideoPlayPerUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interactiveAddOnDestinationClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interactiveAddOnImpressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoPlayActions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoViewsP25?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoViewsP50?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoViewsP75?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoViewsP100?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoWatched2s?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoWatched6s?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokProductCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TikTokProductCategory'] = ResolversParentTypes['TikTokProductCategory']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['TikTokProductCategoryName'], ParentType, ContextType>;
  packageId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialAdPostInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TikTokSpecialAdPostInfo'] = ResolversParentTypes['TikTokSpecialAdPostInfo']
> = ResolversObject<{
  adAccountSystemId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adCampaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authPostCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  callToAction?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  landingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialCampaignResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TikTokSpecialCampaign'] = ResolversParentTypes['TikTokSpecialCampaign']
> = ResolversObject<{
  advertiser?: Resolver<ResolversTypes['AdvertiserName'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  joinedInfluencersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxNumberInfluencers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['PaymentPlanName'], ParentType, ContextType>;
  preLaunchDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TikTokSpecialCampaignStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialCampaignChartReportResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TikTokSpecialCampaignChartReport'] = ResolversParentTypes['TikTokSpecialCampaignChartReport']
> = ResolversObject<{
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialCampaignDetailResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TikTokSpecialCampaignDetail'] = ResolversParentTypes['TikTokSpecialCampaignDetail']
> = ResolversObject<{
  advertiser?: Resolver<ResolversTypes['AdvertiserName'], ParentType, ContextType>;
  campaignCategoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  materialUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  materials?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  preLaunchDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  priceItem?: Resolver<ResolversTypes['PricingTableItem'], ParentType, ContextType>;
  productUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rejectedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  rejectedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requirement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sampleUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TikTokSpecialCampaignStatus'], ParentType, ContextType>;
  thumbnails?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialCampaignDetailForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TikTokSpecialCampaignDetailForInfluencer'] = ResolversParentTypes['TikTokSpecialCampaignDetailForInfluencer']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  hasReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isJoined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  materialUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  materials?: Resolver<Maybe<ReadonlyArray<ResolversTypes['MaterialName']>>, ParentType, ContextType>;
  minimumPaymentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  preLaunchDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  productUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requirement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revenuePerPost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sampleUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialMedias?: Resolver<ReadonlyArray<ResolversTypes['CampaignSocialMediaType']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CampaignStatusForInfluencer'], ParentType, ContextType>;
  thumbnails?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialCampaignPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TikTokSpecialCampaignPost'] = ResolversParentTypes['TikTokSpecialCampaignPost']
> = ResolversObject<{
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['TiktokPostInfluencer'], ParentType, ContextType>;
  joinedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  revenuePerPost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['TikTokSpecialCampaignPostStatus']>, ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialCampaignPostReportForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TikTokSpecialCampaignPostReportForInfluencer'] = ResolversParentTypes['TikTokSpecialCampaignPostReportForInfluencer']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postReports?: Resolver<
    ReadonlyArray<ResolversTypes['InfluencerPostReportStatsTikTokSpecial']>,
    ParentType,
    ContextType
  >;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialDraftAdPostInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TikTokSpecialDraftAdPostInfo'] = ResolversParentTypes['TikTokSpecialDraftAdPostInfo']
> = ResolversObject<{
  adAccountSystemId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adCampaignId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  authPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  callToAction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  landingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialJoinedAccountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TikTokSpecialJoinedAccountPayload'] = ResolversParentTypes['TikTokSpecialJoinedAccountPayload']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountType?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialPackageInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TikTokSpecialPackageInfluencer'] = ResolversParentTypes['TikTokSpecialPackageInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialPostDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TikTokSpecialPostDetail'] = ResolversParentTypes['TikTokSpecialPostDetail']
> = ResolversObject<{
  boostedInfo?: Resolver<Maybe<ResolversTypes['TikTokSpecialAdPostInfo']>, ParentType, ContextType>;
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  detailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftBoostedInfo?: Resolver<Maybe<ResolversTypes['TikTokSpecialDraftAdPostInfo']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  images?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  influencer?: Resolver<ResolversTypes['TiktokPostDetailInfluencer'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['TikTokSpecialCampaignPostStatus']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  warningReason?: Resolver<Maybe<ResolversTypes['WarningReason']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TikTokSpecialPostHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TikTokSpecialPostHistory'] = ResolversParentTypes['TikTokSpecialPostHistory']
> = ResolversObject<{
  detailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  operatedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  operatedUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TikTokSpecialPostHistoryStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAccountForProfileV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokAccountForProfileV2'] = ResolversParentTypes['TiktokAccountForProfileV2']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ttcmStatus?: Resolver<Maybe<ResolversTypes['TTCMStatus']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAdAccountInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokAdAccountInfo'] = ResolversParentTypes['TiktokAdAccountInfo']
> = ResolversObject<{
  balanceAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bcId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bcName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TiktokAdAccountStatus'], ParentType, ContextType>;
  systemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAdCampaignResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokAdCampaign'] = ResolversParentTypes['TiktokAdCampaign']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAdDetailReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokAdDetailReport'] = ResolversParentTypes['TiktokAdDetailReport']
> = ResolversObject<{
  adBudget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCampaignId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adCampaignName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adComments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCostPer1000Reached?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCostPerResult?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCpc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adCtr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adEngagements?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adEr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adFollows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adFrequency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adGroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adImpressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adLikes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adResult?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adResultRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adShares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageVideoPlay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageVideoPlayPerUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  campaignId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interactiveAddOnDestinationClicks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interactiveAddOnImpressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TikTokAdStatus'], ParentType, ContextType>;
  videoPlayActions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoViewsP25?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoViewsP50?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoViewsP75?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoViewsP100?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoWatched2s?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoWatched6s?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAdGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokAdGroup'] = ResolversParentTypes['TiktokAdGroup']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAdvertiserAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokAdvertiserAccount'] = ResolversParentTypes['TiktokAdvertiserAccount']
> = ResolversObject<{
  advertiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertiserUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAdvertiserPlanDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokAdvertiserPlanData'] = ResolversParentTypes['TiktokAdvertiserPlanData']
> = ResolversObject<{
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  paymentDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  planPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subscriptionPlan?: Resolver<Maybe<ResolversTypes['TikTokAdvertiserPlanFilter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAdvertiserSummaryDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokAdvertiserSummaryData'] = ResolversParentTypes['TiktokAdvertiserSummaryData']
> = ResolversObject<{
  cancelNextMonth?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberCreatedCampaigns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  planPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prepaidBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subscriptionPlan?: Resolver<Maybe<ResolversTypes['PaymentPlanName']>, ParentType, ContextType>;
  upcomingSubscriptionPlan?: Resolver<Maybe<ResolversTypes['PaymentPlanName']>, ParentType, ContextType>;
  warningsList?: Resolver<ReadonlyArray<ResolversTypes['TiktokBusinessWarningStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAudienceSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokAudienceSection'] = ResolversParentTypes['TiktokAudienceSection']
> = ResolversObject<{
  ageRates?: Resolver<ResolversTypes['TiktokFollowersAgeGroup'], ParentType, ContextType>;
  countryRates?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TiktokFollowerCountryRate']>>, ParentType, ContextType>;
  femaleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokAuthRedirectUrlResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokAuthRedirectUrl'] = ResolversParentTypes['TiktokAuthRedirectUrl']
> = ResolversObject<{
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokBudgetAllocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokBudgetAllocation'] = ResolversParentTypes['TiktokBudgetAllocation']
> = ResolversObject<{
  adAccounts?: Resolver<ReadonlyArray<ResolversTypes['TiktokAdAccountInfo']>, ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prepaidBalance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokBusinessAccountInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokBusinessAccountInfo'] = ResolversParentTypes['TiktokBusinessAccountInfo']
> = ResolversObject<{
  bcList?: Resolver<ReadonlyArray<ResolversTypes['TiktokBusinessCenterInfo']>, ParentType, ContextType>;
  connected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokBusinessCenterInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokBusinessCenterInfo'] = ResolversParentTypes['TiktokBusinessCenterInfo']
> = ResolversObject<{
  bcType?: Resolver<ResolversTypes['TiktokBusinessCenterType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokFollowerCountryRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokFollowerCountryRate'] = ResolversParentTypes['TiktokFollowerCountryRate']
> = ResolversObject<{
  countryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokFollowersAgeGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokFollowersAgeGroup'] = ResolversParentTypes['TiktokFollowersAgeGroup']
> = ResolversObject<{
  ageGroup?: Resolver<Maybe<ReadonlyArray<ResolversTypes['FollowersAgeGroup']>>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Float']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokInfluencer'] = ResolversParentTypes['TiktokInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  influencerFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountEngagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  ttcmStatus?: Resolver<Maybe<ResolversTypes['TTCMStatus']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokInfluencerSearchResultV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokInfluencerSearchResultV2'] = ResolversParentTypes['TiktokInfluencerSearchResultV2']
> = ResolversObject<{
  tiktokAccounts?: Resolver<ReadonlyArray<ResolversTypes['TiktokInfluencerV2']>, ParentType, ContextType>;
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokInfluencerV2'] = ResolversParentTypes['TiktokInfluencerV2']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountEngagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  ttcmStatus?: Resolver<Maybe<ResolversTypes['TTCMStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokPostDetailInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokPostDetailInfluencer'] = ResolversParentTypes['TiktokPostDetailInfluencer']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokPostInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokPostInfluencer'] = ResolversParentTypes['TiktokPostInfluencer']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokSpecialCampaignInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokSpecialCampaignInfo'] = ResolversParentTypes['TiktokSpecialCampaignInfo']
> = ResolversObject<{
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TikTokSpecialCampaignStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokSpecialCampaignReportResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokSpecialCampaignReport'] = ResolversParentTypes['TiktokSpecialCampaignReport']
> = ResolversObject<{
  average?: Resolver<Maybe<ResolversTypes['TiktokSpecialStatsItemRow']>, ParentType, ContextType>;
  campaignInfo?: Resolver<ResolversTypes['TiktokSpecialCampaignInfo'], ParentType, ContextType>;
  chartData?: Resolver<ReadonlyArray<ResolversTypes['TikTokSpecialCampaignChartReport']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  influencerReports?: Resolver<ReadonlyArray<ResolversTypes['TiktokSpecialInfluencerReport']>, ParentType, ContextType>;
  postCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['TiktokSpecialStatsItemRow'], ParentType, ContextType>;
  summaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TiktokSpecialSummaryDaily']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokSpecialCampaignReportSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokSpecialCampaignReportSummary'] = ResolversParentTypes['TiktokSpecialCampaignReportSummary']
> = ResolversObject<{
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokSpecialInfluencerReportResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokSpecialInfluencerReport'] = ResolversParentTypes['TiktokSpecialInfluencerReport']
> = ResolversObject<{
  influencerInfo?: Resolver<ResolversTypes['CampaignInfluencerInfo'], ParentType, ContextType>;
  postInfo?: Resolver<ResolversTypes['TiktokSpecialPostInfo'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['TiktokSpecialStatsItemRow'], ParentType, ContextType>;
  summaryDaily?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TiktokSpecialSummaryDaily']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokSpecialJoinableAccountsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokSpecialJoinableAccountsPayload'] = ResolversParentTypes['TiktokSpecialJoinableAccountsPayload']
> = ResolversObject<{
  tiktokAccounts?: Resolver<ReadonlyArray<ResolversTypes['JoinableAccount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokSpecialPostInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokSpecialPostInfo'] = ResolversParentTypes['TiktokSpecialPostInfo']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokSpecialStatsItemRowResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokSpecialStatsItemRow'] = ResolversParentTypes['TiktokSpecialStatsItemRow']
> = ResolversObject<{
  adComments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adImpressions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adImpressionsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adLikes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adReach?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adReachRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adShares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerComment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerFollower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerLike?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerShare?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costsPerView?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shares?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoPlayActions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokSpecialSummaryDailyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokSpecialSummaryDaily'] = ResolversParentTypes['TiktokSpecialSummaryDaily']
> = ResolversObject<{
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  stats?: Resolver<ResolversTypes['TiktokSpecialStatsItemRow'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokUserHashtagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokUserHashtag'] = ResolversParentTypes['TiktokUserHashtag']
> = ResolversObject<{
  hashtag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokUserHashtagListPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokUserHashtagListPostsPayload'] = ResolversParentTypes['TiktokUserHashtagListPostsPayload']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['TiktokUserTagListPostsItemPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokUserHashtagPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokUserHashtagPayload'] = ResolversParentTypes['TiktokUserHashtagPayload']
> = ResolversObject<{
  hashtags?: Resolver<ReadonlyArray<ResolversTypes['TiktokUserHashtag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokUserKeywordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokUserKeyword'] = ResolversParentTypes['TiktokUserKeyword']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  keyword?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokUserKeywordListPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokUserKeywordListPostsPayload'] = ResolversParentTypes['TiktokUserKeywordListPostsPayload']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['TiktokUserTagListPostsItemPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokUserKeywordPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiktokUserKeywordPayload'] = ResolversParentTypes['TiktokUserKeywordPayload']
> = ResolversObject<{
  keywords?: Resolver<ReadonlyArray<ResolversTypes['TiktokUserKeyword']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokUserTagListPostsItemPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokUserTagListPostsItemPayload'] = ResolversParentTypes['TiktokUserTagListPostsItemPayload']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TiktokUserTagPostDetailsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TiktokUserTagPostDetailsPayload'] = ResolversParentTypes['TiktokUserTagPostDetailsPayload']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  saves?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenAuthLongLiveResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TokenAuthLongLive'] = ResolversParentTypes['TokenAuthLongLive']
> = ResolversObject<{
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TotalCmsRevenueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TotalCmsRevenue'] = ResolversParentTypes['TotalCmsRevenue']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TotalInfluencerReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TotalInfluencerReport'] = ResolversParentTypes['TotalInfluencerReport']
> = ResolversObject<{
  click?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  like?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pendingRevenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  share?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  view?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TotalNumberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TotalNumber'] = ResolversParentTypes['TotalNumber']
> = ResolversObject<{
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrackYoutubeMusicDownloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TrackYoutubeMusicDownload'] = ResolversParentTypes['TrackYoutubeMusicDownload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransferMoneyToAdAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransferMoneyToAdAccount'] = ResolversParentTypes['TransferMoneyToAdAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrendingFacebookInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TrendingFacebookInfluencer'] = ResolversParentTypes['TrendingFacebookInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  facebookType?: Resolver<ResolversTypes['SocialAccountType'], ParentType, ContextType>;
  followersGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountEngagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  socialAccountFollowers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrendingFacebookInfluencerSearchResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TrendingFacebookInfluencerSearchResult'] = ResolversParentTypes['TrendingFacebookInfluencerSearchResult']
> = ResolversObject<{
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  trending?: Resolver<ReadonlyArray<ResolversTypes['TrendingFacebookInfluencer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrendingInstagramInfluencerSearchResultV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TrendingInstagramInfluencerSearchResultV2'] = ResolversParentTypes['TrendingInstagramInfluencerSearchResultV2']
> = ResolversObject<{
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  trendingAccounts?: Resolver<ReadonlyArray<ResolversTypes['TrendingInstagramInfluencerV2']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrendingInstagramInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TrendingInstagramInfluencerV2'] = ResolversParentTypes['TrendingInstagramInfluencerV2']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  followersGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountEngagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  socialAccountFollowers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrendingYoutubeInfluencerSearchResultV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TrendingYoutubeInfluencerSearchResultV2'] = ResolversParentTypes['TrendingYoutubeInfluencerSearchResultV2']
> = ResolversObject<{
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  trendingAccounts?: Resolver<ReadonlyArray<ResolversTypes['TrendingYoutubeInfluencerV2']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrendingYoutubeInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TrendingYoutubeInfluencerV2'] = ResolversParentTypes['TrendingYoutubeInfluencerV2']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  followersGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountAverageViews?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  socialAccountFollowers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsFeedPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsFeedPostPayload'] = ResolversParentTypes['TwitterAnalyticsFeedPostPayload']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  replies?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  retweets?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsOverviewHistoryItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsOverviewHistoryItem'] = ResolversParentTypes['TwitterAnalyticsOverviewHistoryItem']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsOverviewHistoryRealItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsOverviewHistoryRealItem'] = ResolversParentTypes['TwitterAnalyticsOverviewHistoryRealItem']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsOverviewItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsOverviewItem'] = ResolversParentTypes['TwitterAnalyticsOverviewItem']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  history?: Resolver<ReadonlyArray<ResolversTypes['TwitterAnalyticsOverviewHistoryItem']>, ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsOverviewPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsOverviewPayload'] = ResolversParentTypes['TwitterAnalyticsOverviewPayload']
> = ResolversObject<{
  engagement?: Resolver<ResolversTypes['TwitterAnalyticsOverviewItem'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['TwitterAnalyticsOverviewRealTotalItem'], ParentType, ContextType>;
  engagementRates?: Resolver<Maybe<ResolversTypes['TwitterAnalyticsOverviewRealItem']>, ParentType, ContextType>;
  follower?: Resolver<ResolversTypes['TwitterAnalyticsOverviewItem'], ParentType, ContextType>;
  impression?: Resolver<ResolversTypes['TwitterAnalyticsOverviewItem'], ParentType, ContextType>;
  lastUpdated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  like?: Resolver<ResolversTypes['TwitterAnalyticsOverviewItem'], ParentType, ContextType>;
  post?: Resolver<ResolversTypes['TwitterAnalyticsOverviewItem'], ParentType, ContextType>;
  profileClick?: Resolver<ResolversTypes['TwitterAnalyticsOverviewTotalItem'], ParentType, ContextType>;
  profileClicks?: Resolver<Maybe<ResolversTypes['TwitterAnalyticsOverviewItem']>, ParentType, ContextType>;
  quotedTweet?: Resolver<ResolversTypes['TwitterAnalyticsOverviewItem'], ParentType, ContextType>;
  reply?: Resolver<ResolversTypes['TwitterAnalyticsOverviewItem'], ParentType, ContextType>;
  retweet?: Resolver<ResolversTypes['TwitterAnalyticsOverviewItem'], ParentType, ContextType>;
  urlClick?: Resolver<ResolversTypes['TwitterAnalyticsOverviewTotalItem'], ParentType, ContextType>;
  urlClicks?: Resolver<Maybe<ResolversTypes['TwitterAnalyticsOverviewItem']>, ParentType, ContextType>;
  view?: Resolver<ResolversTypes['TwitterAnalyticsOverviewItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsOverviewRealItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsOverviewRealItem'] = ResolversParentTypes['TwitterAnalyticsOverviewRealItem']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  history?: Resolver<ReadonlyArray<ResolversTypes['TwitterAnalyticsOverviewHistoryRealItem']>, ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsOverviewRealTotalItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsOverviewRealTotalItem'] = ResolversParentTypes['TwitterAnalyticsOverviewRealTotalItem']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsOverviewTotalItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsOverviewTotalItem'] = ResolversParentTypes['TwitterAnalyticsOverviewTotalItem']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsPostByIdPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsPostByIdPayload'] = ResolversParentTypes['TwitterAnalyticsPostByIdPayload']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  post?: Resolver<ResolversTypes['TwitterAnalyticsPostPayload'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsPostCommentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsPostComment'] = ResolversParentTypes['TwitterAnalyticsPostComment']
> = ResolversObject<{
  commentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posterName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsPostItemPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsPostItemPayload'] = ResolversParentTypes['TwitterAnalyticsPostItemPayload']
> = ResolversObject<{
  engagement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impression?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  like?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reply?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  retweet?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsPostPayload'] = ResolversParentTypes['TwitterAnalyticsPostPayload']
> = ResolversObject<{
  comments?: Resolver<ReadonlyArray<ResolversTypes['TwitterAnalyticsPostComment']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  images?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  impression?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  like?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quoteTweet?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reply?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  retweet?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  view?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsPostsByDatePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsPostsByDatePayload'] = ResolversParentTypes['TwitterAnalyticsPostsByDatePayload']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['TwitterAnalyticsPostPayload']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsPostsPayload'] = ResolversParentTypes['TwitterAnalyticsPostsPayload']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['TwitterAnalyticsPostItemPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAnalyticsPostsStatisticPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAnalyticsPostsStatisticPayload'] = ResolversParentTypes['TwitterAnalyticsPostsStatisticPayload']
> = ResolversObject<{
  averageEngagement?: Resolver<ResolversTypes['TwitterAverageEngagementPayload'], ParentType, ContextType>;
  postingHabits?: Resolver<ReadonlyArray<ResolversTypes['TwitterPostingHabitsPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAverageEngagementItemPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAverageEngagementItemPayload'] = ResolversParentTypes['TwitterAverageEngagementItemPayload']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterAverageEngagementPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterAverageEngagementPayload'] = ResolversParentTypes['TwitterAverageEngagementPayload']
> = ResolversObject<{
  avgImpression?: Resolver<ResolversTypes['TwitterAverageEngagementItemPayload'], ParentType, ContextType>;
  avgLike?: Resolver<ResolversTypes['TwitterAverageEngagementItemPayload'], ParentType, ContextType>;
  avgPostsWeek?: Resolver<Maybe<ResolversTypes['TwitterAverageEngagementItemPayload']>, ParentType, ContextType>;
  avgReply?: Resolver<ResolversTypes['TwitterAverageEngagementItemPayload'], ParentType, ContextType>;
  avgRetweet?: Resolver<ResolversTypes['TwitterAverageEngagementItemPayload'], ParentType, ContextType>;
  avgView?: Resolver<Maybe<ResolversTypes['TwitterAverageEngagementItemPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterForProfileV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TwitterForProfileV2'] = ResolversParentTypes['TwitterForProfileV2']
> = ResolversObject<{
  analyticsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TwitterInfluencer'] = ResolversParentTypes['TwitterInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  analyticsWarning?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  influencerFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  replies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  retweets?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountAverageEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TwitterPostingHabitsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TwitterPostingHabitsPayload'] = ResolversParentTypes['TwitterPostingHabitsPayload']
> = ResolversObject<{
  averageComments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageLikes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekday?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UUUMSocialAuthSignUpPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UUUMSocialAuthSignUpPayload'] = ResolversParentTypes['UUUMSocialAuthSignUpPayload']
> = ResolversObject<{
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnApproveApplicantInfluencersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UnApproveApplicantInfluencers'] = ResolversParentTypes['UnApproveApplicantInfluencers']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnassignChatPICPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnassignChatPICPayload'] = ResolversParentTypes['UnassignChatPICPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnblockInfluencerMarketplaceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UnblockInfluencerMarketplace'] = ResolversParentTypes['UnblockInfluencerMarketplace']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnreadNotificationCountByAppPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UnreadNotificationCountByAppPayload'] = ResolversParentTypes['UnreadNotificationCountByAppPayload']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnreadNotificationCountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UnreadNotificationCountPayload'] = ResolversParentTypes['UnreadNotificationCountPayload']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnresolveChatPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnresolveChatPayload'] = ResolversParentTypes['UnresolveChatPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAccountInformationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateAccountInformationPayload'] = ResolversParentTypes['UpdateAccountInformationPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateAddress'] = ResolversParentTypes['UpdateAddress']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAddressInformationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateAddressInformationPayload'] = ResolversParentTypes['UpdateAddressInformationPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAdminResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateAdmin'] = ResolversParentTypes['UpdateAdmin']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAdvertiserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateAdvertiser'] = ResolversParentTypes['UpdateAdvertiser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAdvertiserUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateAdvertiserUser'] = ResolversParentTypes['UpdateAdvertiserUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAdvertiserUserSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateAdvertiserUserSettings'] = ResolversParentTypes['UpdateAdvertiserUserSettings']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateAgency'] = ResolversParentTypes['UpdateAgency']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAutoManagedAffiliateCommissionRatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateAutoManagedAffiliateCommissionRates'] = ResolversParentTypes['UpdateAutoManagedAffiliateCommissionRates']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAutoManagedCampaignPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateAutoManagedCampaignPayload'] = ResolversParentTypes['UpdateAutoManagedCampaignPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateAutoManagedCampaignsStatusPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateAutoManagedCampaignsStatusPayload'] = ResolversParentTypes['UpdateAutoManagedCampaignsStatusPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateBioSectionsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateBioSectionsPayload'] = ResolversParentTypes['UpdateBioSectionsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateCmsAssetShareRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateCmsAssetShareRate'] = ResolversParentTypes['UpdateCmsAssetShareRate']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateCmsChannelShareRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateCmsChannelShareRate'] = ResolversParentTypes['UpdateCmsChannelShareRate']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateCommentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateCommentPayload'] = ResolversParentTypes['UpdateCommentPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEmailTemplatePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateEmailTemplatePayload'] = ResolversParentTypes['UpdateEmailTemplatePayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateEngagement'] = ResolversParentTypes['UpdateEngagement']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementBreakDownModeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateEngagementBreakDownMode'] = ResolversParentTypes['UpdateEngagementBreakDownMode']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementCouponListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateEngagementCouponList'] = ResolversParentTypes['UpdateEngagementCouponList']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementInfluencerProposalListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateEngagementInfluencerProposalList'] = ResolversParentTypes['UpdateEngagementInfluencerProposalList']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementInfluencersBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateEngagementInfluencersBudget'] = ResolversParentTypes['UpdateEngagementInfluencersBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementPostForInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateEngagementPostForInfluencerV2'] = ResolversParentTypes['UpdateEngagementPostForInfluencerV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementPostStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateEngagementPostStatus'] = ResolversParentTypes['UpdateEngagementPostStatus']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementPostV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateEngagementPostV2'] = ResolversParentTypes['UpdateEngagementPostV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementSocialAccountProposalListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateEngagementSocialAccountProposalList'] = ResolversParentTypes['UpdateEngagementSocialAccountProposalList']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementSocialAccountsBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateEngagementSocialAccountsBudget'] = ResolversParentTypes['UpdateEngagementSocialAccountsBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEngagementStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateEngagementStatus'] = ResolversParentTypes['UpdateEngagementStatus']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateFanPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateFanPayload'] = ResolversParentTypes['UpdateFanPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateFormPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateFormPayload'] = ResolversParentTypes['UpdateFormPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateFormStatusPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateFormStatusPayload'] = ResolversParentTypes['UpdateFormStatusPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateInfluencerBioPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateInfluencerBioPayload'] = ResolversParentTypes['UpdateInfluencerBioPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateInfluencerPhoneNumberPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateInfluencerPhoneNumberPayload'] = ResolversParentTypes['UpdateInfluencerPhoneNumberPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateInfluencerProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateInfluencerProfile'] = ResolversParentTypes['UpdateInfluencerProfile']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateInfluencerRateCardPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateInfluencerRateCardPayload'] = ResolversParentTypes['UpdateInfluencerRateCardPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateInfluencerRateCardsByStaffResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateInfluencerRateCardsByStaff'] = ResolversParentTypes['UpdateInfluencerRateCardsByStaff']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateInfluencerRateCardsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateInfluencerRateCardsPayload'] = ResolversParentTypes['UpdateInfluencerRateCardsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateInfluencerTagsByStaffResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateInfluencerTagsByStaff'] = ResolversParentTypes['UpdateInfluencerTagsByStaff']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateInfluencerV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateInfluencerV2'] = ResolversParentTypes['UpdateInfluencerV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateInternalMemoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateInternalMemo'] = ResolversParentTypes['UpdateInternalMemo']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateLineBroadcastMessagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateLineBroadcastMessagePayload'] = ResolversParentTypes['UpdateLineBroadcastMessagePayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateLinkBioThemeColorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateLinkBioThemeColorPayload'] = ResolversParentTypes['UpdateLinkBioThemeColorPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateMarketplaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateMarketplace'] = ResolversParentTypes['UpdateMarketplace']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateMarketplaceAffiliateCommissionFeesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateMarketplaceAffiliateCommissionFees'] = ResolversParentTypes['UpdateMarketplaceAffiliateCommissionFees']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateMarketplaceAffiliateCommissionRatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateMarketplaceAffiliateCommissionRates'] = ResolversParentTypes['UpdateMarketplaceAffiliateCommissionRates']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateMarketplaceCouponListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateMarketplaceCouponList'] = ResolversParentTypes['UpdateMarketplaceCouponList']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateMarketplaceInfluencerCostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateMarketplaceInfluencerCost'] = ResolversParentTypes['UpdateMarketplaceInfluencerCost']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateMarketplacePostStatusV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateMarketplacePostStatusV2'] = ResolversParentTypes['UpdateMarketplacePostStatusV2']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateMarketplacePostTrackingUrlResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateMarketplacePostTrackingUrl'] = ResolversParentTypes['UpdateMarketplacePostTrackingUrl']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePackageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatePackage'] = ResolversParentTypes['UpdatePackage']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePackageProposalInfluencersBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdatePackageProposalInfluencersBudget'] = ResolversParentTypes['UpdatePackageProposalInfluencersBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePackageProposalModeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdatePackageProposalMode'] = ResolversParentTypes['UpdatePackageProposalMode']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePackageProposalSocialAccountsBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdatePackageProposalSocialAccountsBudget'] = ResolversParentTypes['UpdatePackageProposalSocialAccountsBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePartnerAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatePartnerAgency'] = ResolversParentTypes['UpdatePartnerAgency']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePartnerUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatePartnerUser'] = ResolversParentTypes['UpdatePartnerUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePasswordByAdvertiserUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdatePasswordByAdvertiserUser'] = ResolversParentTypes['UpdatePasswordByAdvertiserUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePaymentInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatePaymentInformation'] = ResolversParentTypes['UpdatePaymentInformation']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePaymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatePaymentStatus'] = ResolversParentTypes['UpdatePaymentStatus']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePersoanlUserDetailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdatePersoanlUserDetailPayload'] = ResolversParentTypes['UpdatePersoanlUserDetailPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePlainAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatePlainAgency'] = ResolversParentTypes['UpdatePlainAgency']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateSocialAccountsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateSocialAccountsPayload'] = ResolversParentTypes['UpdateSocialAccountsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateSocialUsernameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSocialUsername'] = ResolversParentTypes['UpdateSocialUsername']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateStaffResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateStaff'] = ResolversParentTypes['UpdateStaff']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateSubscriptionPlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSubscriptionPlan'] = ResolversParentTypes['UpdateSubscriptionPlan']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateTalentAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateTalentAgency'] = ResolversParentTypes['UpdateTalentAgency']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateTalentAgencyUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateTalentAgencyUser'] = ResolversParentTypes['UpdateTalentAgencyUser']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateTalentInfluencerPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateTalentInfluencerPayload'] = ResolversParentTypes['UpdateTalentInfluencerPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateTikTokSpecialCampaignResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateTikTokSpecialCampaign'] = ResolversParentTypes['UpdateTikTokSpecialCampaign']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateTiktokAdvertiserInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateTiktokAdvertiserInfo'] = ResolversParentTypes['UpdateTiktokAdvertiserInfo']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateTiktokSpecialPostStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateTiktokSpecialPostStatus'] = ResolversParentTypes['UpdateTiktokSpecialPostStatus']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateTtcmPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateTtcmPost'] = ResolversParentTypes['UpdateTtcmPost']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateUserDetailPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateUserDetailPayload'] = ResolversParentTypes['UpdateUserDetailPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateUserProposalBreakdownModeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateUserProposalBreakdownMode'] = ResolversParentTypes['UpdateUserProposalBreakdownMode']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateUserProposalInfluencersBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateUserProposalInfluencersBudget'] = ResolversParentTypes['UpdateUserProposalInfluencersBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateUserProposalSocialAccountsBudgetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateUserProposalSocialAccountsBudget'] = ResolversParentTypes['UpdateUserProposalSocialAccountsBudget']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateYoutubeMCNCredentialsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateYoutubeMCNCredentialsPayload'] = ResolversParentTypes['UpdateYoutubeMCNCredentialsPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UploadEngagementReportFileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UploadEngagementReportFile'] = ResolversParentTypes['UploadEngagementReportFile']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UploadEngagementReportFromInsightCheckResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UploadEngagementReportFromInsightCheck'] = ResolversParentTypes['UploadEngagementReportFromInsightCheck']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UploadEngagementXhsInsightCheckResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UploadEngagementXhsInsightCheck'] = ResolversParentTypes['UploadEngagementXhsInsightCheck']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UploadOpenAIImagesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UploadOpenAIImages'] = ResolversParentTypes['UploadOpenAIImages']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAudioMessageEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAudioMessageEvent'] = ResolversParentTypes['UserAudioMessageEvent']
> = ResolversObject<{
  contentUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserDefaultPermissionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDefaultPermissions'] = ResolversParentTypes['UserDefaultPermissions']
> = ResolversObject<{
  permissions?: Resolver<Maybe<ResolversTypes['CustomPermissions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDetail'] = ResolversParentTypes['UserDetail']
> = ResolversObject<{
  advertisers?: Resolver<ReadonlyArray<ResolversTypes['AdvertiserName']>, ParentType, ContextType>;
  agencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  autoLinkToAdvertiser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationSetting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  partnerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  permissions?: Resolver<Maybe<ResolversTypes['CustomPermissions']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  talentAgencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserDetailPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDetailPayload'] = ResolversParentTypes['UserDetailPayload']
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAvailableCountry?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationSetting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserImageMessageEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserImageMessageEvent'] = ResolversParentTypes['UserImageMessageEvent']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPostbackEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserPostbackEvent'] = ResolversParentTypes['UserPostbackEvent']
> = ResolversObject<{
  displayText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProposalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserProposal'] = ResolversParentTypes['UserProposal']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencers?: Resolver<ReadonlyArray<ResolversTypes['UserProposalInfluencer']>, ParentType, ContextType>;
  jdMode?: Resolver<ResolversTypes['ProposalJobDescriptionBreakDownMode'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['UserProposalJobSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProposalInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserProposalInfluencer'] = ResolversParentTypes['UserProposalInfluencer']
> = ResolversObject<{
  accountList?: Resolver<ReadonlyArray<ResolversTypes['UserProposalSocialAccount']>, ParentType, ContextType>;
  addedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isDenied?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['UserProposalJobSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProposalJobSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserProposalJobSummary'] = ResolversParentTypes['UserProposalJobSummary']
> = ResolversObject<{
  averageEngagement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  budget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementFollowerRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementViewRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  influencerCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProposalSocialAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserProposalSocialAccount'] = ResolversParentTypes['UserProposalSocialAccount']
> = ResolversObject<{
  addedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['UserProposalSocialMediaType'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['UserProposalJobSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTextMessageEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserTextMessageEvent'] = ResolversParentTypes['UserTextMessageEvent']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserVideoMessageEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserVideoMessageEvent'] = ResolversParentTypes['UserVideoMessageEvent']
> = ResolversObject<{
  contentUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VerifyLineIDTokenPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerifyLineIDTokenPayload'] = ResolversParentTypes['VerifyLineIDTokenPayload']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VerifyPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerifyPayload'] = ResolversParentTypes['VerifyPayload']
> = ResolversObject<{
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creatorType?: Resolver<Maybe<ResolversTypes['InfluencerType']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  limitedLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['UserRoles']>, ParentType, ContextType>;
  subscriptionPaymentFailed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  subscriptionPlan?: Resolver<Maybe<ResolversTypes['PaymentPlanName']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VideoPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VideoPayload'] = ResolversParentTypes['VideoPayload']
> = ResolversObject<{
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  previewUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WithdrawMoneyFromAdAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WithdrawMoneyFromAdAccount'] = ResolversParentTypes['WithdrawMoneyFromAdAccount']
> = ResolversObject<{
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type XhsAccountInterestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['XhsAccountInterest'] = ResolversParentTypes['XhsAccountInterest']
> = ResolversObject<{
  deviceRates?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AccountAudienceDataRate']>>, ParentType, ContextType>;
  interestRates?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AccountAudienceDataRate']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type XhsAudienceSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['XhsAudienceSection'] = ResolversParentTypes['XhsAudienceSection']
> = ResolversObject<{
  ageRates?: Resolver<ResolversTypes['TiktokFollowersAgeGroup'], ParentType, ContextType>;
  cityRates?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AccountAudienceDataRate']>>, ParentType, ContextType>;
  femaleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  provinceRates?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AccountAudienceDataRate']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type XhsEngagementInsightSectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['XhsEngagementInsightSection'] = ResolversParentTypes['XhsEngagementInsightSection']
> = ResolversObject<{
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averagePostsPerWeek?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageSaved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  engagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  postCategories?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AccountAudienceDataRate']>>, ParentType, ContextType>;
  postsHabit?: Resolver<ReadonlyArray<ResolversTypes['InfluencerNewProfilePostsHabit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type XhsInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['XhsInfluencer'] = ResolversParentTypes['XhsInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountEngagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type XhsInfluencerSearchResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['XhsInfluencerSearchResult'] = ResolversParentTypes['XhsInfluencerSearchResult']
> = ResolversObject<{
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  xhsAccounts?: Resolver<ReadonlyArray<ResolversTypes['XhsInfluencer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type XhsPostSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['XhsPostSection'] = ResolversParentTypes['XhsPostSection']
> = ResolversObject<{
  picturePosts?: Resolver<ReadonlyArray<ResolversTypes['InfluencerFeedPost']>, ParentType, ContextType>;
  videoPosts?: Resolver<ReadonlyArray<ResolversTypes['InfluencerFeedPost']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YouTubeBrandAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YouTubeBrandAccount'] = ResolversParentTypes['YouTubeBrandAccount']
> = ResolversObject<{
  averageViews?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mentionPostsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postIds?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YouTubeBrandAccountsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YouTubeBrandAccounts'] = ResolversParentTypes['YouTubeBrandAccounts']
> = ResolversObject<{
  averageEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brandAccounts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['YouTubeBrandAccount']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YouTubeCmsRevenueForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YouTubeCmsRevenueForInfluencer'] = ResolversParentTypes['YouTubeCmsRevenueForInfluencer']
> = ResolversObject<{
  revenue?: Resolver<Maybe<ResolversTypes['TotalCmsRevenue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YouTubeCmsVideoAudienceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YouTubeCmsVideoAudience'] = ResolversParentTypes['YouTubeCmsVideoAudience']
> = ResolversObject<{
  ageGenderRates?: Resolver<ResolversTypes['YoutubeAgeGenderGroup'], ParentType, ContextType>;
  countryRates?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAudienceCountryRate']>, ParentType, ContextType>;
  genderRates?: Resolver<ResolversTypes['YoutubeAudienceGenderRate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YouTubeCmsVideoStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YouTubeCmsVideoStats'] = ResolversParentTypes['YouTubeCmsVideoStats']
> = ResolversObject<{
  assetTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avgPercentageView?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  avgViewDuration?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoThumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YouTubeInfluencerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YouTubeInfluencer'] = ResolversParentTypes['YouTubeInfluencer']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  analyticsWarning?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categories?: Resolver<ReadonlyArray<ResolversTypes['CategoryName']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  engagementPostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engagementProposedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  influencerEngagementRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  influencerFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  marketplaceJoinedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplacePostedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountEngagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAccountForProfileV2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAccountForProfileV2'] = ResolversParentTypes['YoutubeAccountForProfileV2']
> = ResolversObject<{
  analyticsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAgeGenderGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeAgeGenderGroup'] = ResolversParentTypes['YoutubeAgeGenderGroup']
> = ResolversObject<{
  ageGroup?: Resolver<ReadonlyArray<ResolversTypes['FollowersAgeGroup']>, ParentType, ContextType>;
  femaleRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  otherRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAccountCardResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAccountCard'] = ResolversParentTypes['YoutubeAnalyticsAccountCard']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  elementId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAccountOverviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAccountOverview'] = ResolversParentTypes['YoutubeAnalyticsAccountOverview']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  graph?: Resolver<ResolversTypes['YoutubeCompareOverviewHistory'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['YoutubeCompareOverviewSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAccountPostsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAccountPosts'] = ResolversParentTypes['YoutubeAnalyticsAccountPosts']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postHabit?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostsPostingHabit']>, ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeCompareAccountPost']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAccountPostsInDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAccountPostsInDate'] = ResolversParentTypes['YoutubeAnalyticsAccountPostsInDate']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAccountTagRankingResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAccountTagRanking'] = ResolversParentTypes['YoutubeAnalyticsAccountTagRanking']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tagsRanking?: Resolver<ReadonlyArray<ResolversTypes['YoutubeTagRanking']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload'] = ResolversParentTypes['YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload']
> = ResolversObject<{
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAudienceAgeBreakdownPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAudienceAgeBreakdownPayload'] = ResolversParentTypes['YoutubeAnalyticsAudienceAgeBreakdownPayload']
> = ResolversObject<{
  ageGroup?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload']>,
    ParentType,
    ContextType
  >;
  femaleRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  maleRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  otherRate?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAudienceGenderBreakdownPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAudienceGenderBreakdownPayload'] = ResolversParentTypes['YoutubeAnalyticsAudienceGenderBreakdownPayload']
> = ResolversObject<{
  femaleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  otherRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAudiencePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAudiencePayload'] = ResolversParentTypes['YoutubeAnalyticsAudiencePayload']
> = ResolversObject<{
  ageRate?: Resolver<ResolversTypes['YoutubeAnalyticsAudienceAgeBreakdownPayload'], ParentType, ContextType>;
  genderRate?: Resolver<ResolversTypes['YoutubeAnalyticsAudienceGenderBreakdownPayload'], ParentType, ContextType>;
  regionRate?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeAnalyticsAudienceRegionPayload']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsAudienceRegionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsAudienceRegionPayload'] = ResolversParentTypes['YoutubeAnalyticsAudienceRegionPayload']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeAnalyticsCategory'] = ResolversParentTypes['YoutubeAnalyticsCategory']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsCommentsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsCommentsPayload'] = ResolversParentTypes['YoutubeAnalyticsCommentsPayload']
> = ResolversObject<{
  comments?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostCommentItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsCompareAccountCardResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsCompareAccountCard'] = ResolversParentTypes['YoutubeAnalyticsCompareAccountCard']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  elementId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsCompareAccountCardsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsCompareAccountCards'] = ResolversParentTypes['YoutubeAnalyticsCompareAccountCards']
> = ResolversObject<{
  compareAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeAnalyticsCompareAccountCard']>,
    ParentType,
    ContextType
  >;
  mainAccount?: Resolver<Maybe<ResolversTypes['YoutubeAnalyticsAccountCard']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsComparePostCommentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsComparePostComment'] = ResolversParentTypes['YoutubeAnalyticsComparePostComment']
> = ResolversObject<{
  commentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posterName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsCompareRelatedPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsCompareRelatedPost'] = ResolversParentTypes['YoutubeAnalyticsCompareRelatedPost']
> = ResolversObject<{
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsCompareRelatedPostsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsCompareRelatedPosts'] = ResolversParentTypes['YoutubeAnalyticsCompareRelatedPosts']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsCompareRelatedPost']>, ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsCountriesCategoriesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsCountriesCategoriesPayload'] = ResolversParentTypes['YoutubeAnalyticsCountriesCategoriesPayload']
> = ResolversObject<{
  categories?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsCategory']>, ParentType, ContextType>;
  countries?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsCountry']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsCountryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeAnalyticsCountry'] = ResolversParentTypes['YoutubeAnalyticsCountry']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsHistoryDataPointResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsHistoryDataPoint'] = ResolversParentTypes['YoutubeAnalyticsHistoryDataPoint']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsOverviewHistoryItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsOverviewHistoryItem'] = ResolversParentTypes['YoutubeAnalyticsOverviewHistoryItem']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsOverviewItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsOverviewItem'] = ResolversParentTypes['YoutubeAnalyticsOverviewItem']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  history?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsOverviewHistoryItem']>, ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsOverviewPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsOverviewPayload'] = ResolversParentTypes['YoutubeAnalyticsOverviewPayload']
> = ResolversObject<{
  comment?: Resolver<ResolversTypes['YoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  dislike?: Resolver<ResolversTypes['YoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  lastUpdated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  like?: Resolver<ResolversTypes['YoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  post?: Resolver<ResolversTypes['YoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['YoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  view?: Resolver<ResolversTypes['YoutubeAnalyticsOverviewItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostCommentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostComment'] = ResolversParentTypes['YoutubeAnalyticsPostComment']
> = ResolversObject<{
  commentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posterName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostCommentItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostCommentItem'] = ResolversParentTypes['YoutubeAnalyticsPostCommentItem']
> = ResolversObject<{
  commentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostPayload'] = ResolversParentTypes['YoutubeAnalyticsPostPayload']
> = ResolversObject<{
  averageViewDuration?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  averageViewPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  dislikes?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  subscribers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoComments?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostComment']>, ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoEmbed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoTags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostPayloadsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostPayloads'] = ResolversParentTypes['YoutubeAnalyticsPostPayloads']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostPayload']>, ParentType, ContextType>;
  subscribers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostWithChannelDetailsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostWithChannelDetailsPayload'] = ResolversParentTypes['YoutubeAnalyticsPostWithChannelDetailsPayload']
> = ResolversObject<{
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  averageViewDuration?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  averageViewPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  dislikes?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  subscribers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoComments?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostComment']>, ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoEmbed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoTags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostsAverageEngagementResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostsAverageEngagement'] = ResolversParentTypes['YoutubeAnalyticsPostsAverageEngagement']
> = ResolversObject<{
  averageComments?: Resolver<ResolversTypes['YoutubeStatisticsData'], ParentType, ContextType>;
  averageDislikes?: Resolver<ResolversTypes['YoutubeStatisticsData'], ParentType, ContextType>;
  averageLikes?: Resolver<ResolversTypes['YoutubeStatisticsData'], ParentType, ContextType>;
  averagePostsPerWeek?: Resolver<Maybe<ResolversTypes['YoutubeStatisticsData']>, ParentType, ContextType>;
  averageViews?: Resolver<ResolversTypes['YoutubeStatisticsData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostsPayload'] = ResolversParentTypes['YoutubeAnalyticsPostsPayload']
> = ResolversObject<{
  averageViewDuration?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  averageViewPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  dislikes?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isSponsoredPost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostsPayloadsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostsPayloads'] = ResolversParentTypes['YoutubeAnalyticsPostsPayloads']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostsPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostsPostingHabitResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostsPostingHabit'] = ResolversParentTypes['YoutubeAnalyticsPostsPostingHabit']
> = ResolversObject<{
  averageComments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageLikes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekday?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsPostsStatisticsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsPostsStatisticsPayload'] = ResolversParentTypes['YoutubeAnalyticsPostsStatisticsPayload']
> = ResolversObject<{
  averageEngagement?: Resolver<ResolversTypes['YoutubeAnalyticsPostsAverageEngagement'], ParentType, ContextType>;
  postHabit?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostsPostingHabit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsRelatedPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsRelatedPostPayload'] = ResolversParentTypes['YoutubeAnalyticsRelatedPostPayload']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoEmbed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsRelatedPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsRelatedPostsPayload'] = ResolversParentTypes['YoutubeAnalyticsRelatedPostsPayload']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsRelatedPostPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsShortsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsShortsPayload'] = ResolversParentTypes['YoutubeAnalyticsShortsPayload']
> = ResolversObject<{
  averageViewDuration?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  averageViewPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  dislikes?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isSponsoredPost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsShortsPayloadsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsShortsPayloads'] = ResolversParentTypes['YoutubeAnalyticsShortsPayloads']
> = ResolversObject<{
  shorts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsShortsPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsTagRankingPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsTagRankingPayload'] = ResolversParentTypes['YoutubeAnalyticsTagRankingPayload']
> = ResolversObject<{
  averageComment?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageDislike?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageEngagement?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageLike?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageShare?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  engagementRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  posts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsTagRankingPayloadsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsTagRankingPayloads'] = ResolversParentTypes['YoutubeAnalyticsTagRankingPayloads']
> = ResolversObject<{
  tags?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsTagRankingPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsTrendPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsTrendPayload'] = ResolversParentTypes['YoutubeAnalyticsTrendPayload']
> = ResolversObject<{
  channel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  socialAccountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsTrendPostDetailsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsTrendPostDetailsPayload'] = ResolversParentTypes['YoutubeAnalyticsTrendPostDetailsPayload']
> = ResolversObject<{
  channel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAnalyticsTrendsPayloadsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAnalyticsTrendsPayloads'] = ResolversParentTypes['YoutubeAnalyticsTrendsPayloads']
> = ResolversObject<{
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsTrendPayload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAudienceCountryRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAudienceCountryRate'] = ResolversParentTypes['YoutubeAudienceCountryRate']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeAudienceGenderRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeAudienceGenderRate'] = ResolversParentTypes['YoutubeAudienceGenderRate']
> = ResolversObject<{
  femaleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maleRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  otherRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsChannelDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeCmsChannelDetail'] = ResolversParentTypes['YoutubeCmsChannelDetail']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shareRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsChannelForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCmsChannelForInfluencer'] = ResolversParentTypes['YoutubeCmsChannelForInfluencer']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsChannelInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeCmsChannelInfo'] = ResolversParentTypes['YoutubeCmsChannelInfo']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsConfirmedAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeCmsConfirmedAsset'] = ResolversParentTypes['YoutubeCmsConfirmedAsset']
> = ResolversObject<{
  assetId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assetName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerChannelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partnerRevenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareCreator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsConfirmedChannelResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCmsConfirmedChannel'] = ResolversParentTypes['YoutubeCmsConfirmedChannel']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partnerRevenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareCreator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsConfirmedChannelSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCmsConfirmedChannelSummary'] = ResolversParentTypes['YoutubeCmsConfirmedChannelSummary']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partnerRevenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareCreator?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsConfirmedRevenueLatestMonthResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCmsConfirmedRevenueLatestMonth'] = ResolversParentTypes['YoutubeCmsConfirmedRevenueLatestMonth']
> = ResolversObject<{
  month?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsEstimateAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeCmsEstimateAsset'] = ResolversParentTypes['YoutubeCmsEstimateAsset']
> = ResolversObject<{
  assetId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assetName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  belongMultipleChannels?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cmsChannelId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastUpdatedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  ownerChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerChannelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partnerRevenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareCreator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taProfit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taRevenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taRpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taShareRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsEstimateChannelResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCmsEstimateChannel'] = ResolversParentTypes['YoutubeCmsEstimateChannel']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastUpdatedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  partnerRevenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareCreator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taProfit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taRevenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taRpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taShareRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsEstimateChannelSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCmsEstimateChannelSummary'] = ResolversParentTypes['YoutubeCmsEstimateChannelSummary']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partnerRevenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareCreator?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  taProfit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taRevenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taRpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeCmsInvoice'] = ResolversParentTypes['YoutubeCmsInvoice']
> = ResolversObject<{
  approvedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  influencerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsInvoiceDownloadUrlResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCmsInvoiceDownloadUrl'] = ResolversParentTypes['YoutubeCmsInvoiceDownloadUrl']
> = ResolversObject<{
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsInvoiceForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCmsInvoiceForInfluencer'] = ResolversParentTypes['YoutubeCmsInvoiceForInfluencer']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsMusicForInfluencerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCmsMusicForInfluencer'] = ResolversParentTypes['YoutubeCmsMusicForInfluencer']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsStartMonthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeCmsStartMonth'] = ResolversParentTypes['YoutubeCmsStartMonth']
> = ResolversObject<{
  month?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCmsVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeCmsVideo'] = ResolversParentTypes['YoutubeCmsVideo']
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  revenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rpm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shareRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareAccountOverviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCompareAccountOverview'] = ResolversParentTypes['YoutubeCompareAccountOverview']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  graph?: Resolver<ResolversTypes['YoutubeCompareOverviewHistory'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['YoutubeCompareOverviewSummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareAccountPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCompareAccountPost'] = ResolversParentTypes['YoutubeCompareAccountPost']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareAccountPostsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCompareAccountPosts'] = ResolversParentTypes['YoutubeCompareAccountPosts']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postHabit?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsPostsPostingHabit']>, ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeCompareAccountPost']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareAccountPostsInDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCompareAccountPostsInDate'] = ResolversParentTypes['YoutubeCompareAccountPostsInDate']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeComparePost']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareAccountTagRankingResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCompareAccountTagRanking'] = ResolversParentTypes['YoutubeCompareAccountTagRanking']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tagsRanking?: Resolver<ReadonlyArray<ResolversTypes['YoutubeTagRanking']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareAllPostsInDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCompareAllPostsInDate'] = ResolversParentTypes['YoutubeCompareAllPostsInDate']
> = ResolversObject<{
  compareAccounts?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeCompareAccountPostsInDate']>,
    ParentType,
    ContextType
  >;
  mainAccount?: Resolver<ResolversTypes['YoutubeAnalyticsAccountPostsInDate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareOverviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeCompareOverview'] = ResolversParentTypes['YoutubeCompareOverview']
> = ResolversObject<{
  compareAccounts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeCompareAccountOverview']>, ParentType, ContextType>;
  mainAccount?: Resolver<ResolversTypes['YoutubeCompareAccountOverview'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareOverviewHistoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCompareOverviewHistory'] = ResolversParentTypes['YoutubeCompareOverviewHistory']
> = ResolversObject<{
  comments?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  dislikes?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  likes?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  subscribers?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  views?: Resolver<ReadonlyArray<ResolversTypes['YoutubeAnalyticsHistoryDataPoint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareOverviewSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCompareOverviewSummary'] = ResolversParentTypes['YoutubeCompareOverviewSummary']
> = ResolversObject<{
  averageComments?: Resolver<ResolversTypes['YoutubeCompareOverviewSummaryData'], ParentType, ContextType>;
  averageLikes?: Resolver<ResolversTypes['YoutubeCompareOverviewSummaryData'], ParentType, ContextType>;
  averageViews?: Resolver<ResolversTypes['YoutubeCompareOverviewSummaryData'], ParentType, ContextType>;
  maximumLikes?: Resolver<ResolversTypes['YoutubeCompareOverviewSummaryData'], ParentType, ContextType>;
  maximumViews?: Resolver<ResolversTypes['YoutubeCompareOverviewSummaryData'], ParentType, ContextType>;
  totalPosts?: Resolver<ResolversTypes['YoutubeCompareOverviewSummaryData'], ParentType, ContextType>;
  totalSubscribers?: Resolver<ResolversTypes['YoutubeCompareOverviewSummaryData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareOverviewSummaryDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeCompareOverviewSummaryData'] = ResolversParentTypes['YoutubeCompareOverviewSummaryData']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeComparePostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeComparePost'] = ResolversParentTypes['YoutubeComparePost']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dislikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  subscribers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoCaption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoComments?: Resolver<
    ReadonlyArray<ResolversTypes['YoutubeAnalyticsComparePostComment']>,
    ParentType,
    ContextType
  >;
  videoDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videoEmbed?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoTags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  videoThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeComparePostListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeComparePostList'] = ResolversParentTypes['YoutubeComparePostList']
> = ResolversObject<{
  accountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeComparePostListItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeComparePostListItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeComparePostListItem'] = ResolversParentTypes['YoutubeComparePostListItem']
> = ResolversObject<{
  comments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dislikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  subscribers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeComparePostsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeComparePosts'] = ResolversParentTypes['YoutubeComparePosts']
> = ResolversObject<{
  compareAccounts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeCompareAccountPosts']>, ParentType, ContextType>;
  mainAccount?: Resolver<ResolversTypes['YoutubeAnalyticsAccountPosts'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeCompareTagRankingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeCompareTagRanking'] = ResolversParentTypes['YoutubeCompareTagRanking']
> = ResolversObject<{
  compareAccounts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeCompareAccountTagRanking']>, ParentType, ContextType>;
  mainAccount?: Resolver<ResolversTypes['YoutubeAnalyticsAccountTagRanking'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeInfluencerSearchResultV3Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeInfluencerSearchResultV3'] = ResolversParentTypes['YoutubeInfluencerSearchResultV3']
> = ResolversObject<{
  totalNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  youtubeAccounts?: Resolver<ReadonlyArray<ResolversTypes['YoutubeInfluencerV3']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeInfluencerV3Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeInfluencerV3'] = ResolversParentTypes['YoutubeInfluencerV3']
> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageLikes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageViews?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Genders'], ParentType, ContextType>;
  hasSimilarAccounts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBrandAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularPosts?: Resolver<Maybe<ReadonlyArray<ResolversTypes['SocialAccountPopularPost']>>, ParentType, ContextType>;
  socialAccountEngagement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountEngagementFollowersRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountEngagementViewsRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socialAccountFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  socialAccountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  socialAccountStatus?: Resolver<ResolversTypes['SocialAccountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeMCNAdminRedirectUrlPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['YoutubeMCNAdminRedirectUrlPayload'] = ResolversParentTypes['YoutubeMCNAdminRedirectUrlPayload']
> = ResolversObject<{
  redirectUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeMusicCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeMusicCategory'] = ResolversParentTypes['YoutubeMusicCategory']
> = ResolversObject<{
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeMusicItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeMusicItem'] = ResolversParentTypes['YoutubeMusicItem']
> = ResolversObject<{
  downloadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeStatisticsDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeStatisticsData'] = ResolversParentTypes['YoutubeStatisticsData']
> = ResolversObject<{
  growth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeTagRankingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YoutubeTagRanking'] = ResolversParentTypes['YoutubeTagRanking']
> = ResolversObject<{
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface _FieldSetScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['_FieldSet'], any> {
  name: '_FieldSet';
}

export type _ServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['_Service'] = ResolversParentTypes['_Service']
> = ResolversObject<{
  sdl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  AcceptedPayload?: AcceptedPayloadResolvers<ContextType>;
  AccountAnalyticsAccountManager?: AccountAnalyticsAccountManagerResolvers<ContextType>;
  AccountAnalyticsAdvertiser?: AccountAnalyticsAdvertiserResolvers<ContextType>;
  AccountAnalyticsAdvertiserDetails?: AccountAnalyticsAdvertiserDetailsResolvers<ContextType>;
  AccountAnalyticsDetail?: AccountAnalyticsDetailResolvers<ContextType>;
  AccountAnalyticsFacebookPageAccount?: AccountAnalyticsFacebookPageAccountResolvers<ContextType>;
  AccountAnalyticsForAdvertiserPayload?: AccountAnalyticsForAdvertiserPayloadResolvers<ContextType>;
  AccountAnalyticsInstagramAccount?: AccountAnalyticsInstagramAccountResolvers<ContextType>;
  AccountAnalyticsPayload?: AccountAnalyticsPayloadResolvers<ContextType>;
  AccountAnalyticsSocialMediaAccount?: AccountAnalyticsSocialMediaAccountResolvers<ContextType>;
  AccountAnalyticsTwitterAccount?: AccountAnalyticsTwitterAccountResolvers<ContextType>;
  AccountAnalyticsYouTubeAccount?: AccountAnalyticsYouTubeAccountResolvers<ContextType>;
  AccountAudienceDataRate?: AccountAudienceDataRateResolvers<ContextType>;
  ActionLabelPayload?: ActionLabelPayloadResolvers<ContextType>;
  ActivityChange?: ActivityChangeResolvers<ContextType>;
  AddAllSelectedInfluencers?: AddAllSelectedInfluencersResolvers<ContextType>;
  AddCommentPayload?: AddCommentPayloadResolvers<ContextType>;
  AddCompareInstagramAccountAnalytics?: AddCompareInstagramAccountAnalyticsResolvers<ContextType>;
  AddEmailTemplatePayload?: AddEmailTemplatePayloadResolvers<ContextType>;
  AddFanPayload?: AddFanPayloadResolvers<ContextType>;
  AddFanTagsPayload?: AddFanTagsPayloadResolvers<ContextType>;
  AddFormPayload?: AddFormPayloadResolvers<ContextType>;
  AddInfluencerToUserProposal?: AddInfluencerToUserProposalResolvers<ContextType>;
  AddInstagramHashtagAnalytics?: AddInstagramHashtagAnalyticsResolvers<ContextType>;
  AddMarketplacePostUrl?: AddMarketplacePostUrlResolvers<ContextType>;
  AddMarketplacePostUrlForInfluencer?: AddMarketplacePostUrlForInfluencerResolvers<ContextType>;
  AddReadTutorial?: AddReadTutorialResolvers<ContextType>;
  AddSelectedInfluencers?: AddSelectedInfluencersResolvers<ContextType>;
  AddSocialAccountToUserProposal?: AddSocialAccountToUserProposalResolvers<ContextType>;
  AddStaffAdvertisersToAnotherStaff?: AddStaffAdvertisersToAnotherStaffResolvers<ContextType>;
  AddYoutubeCompareAccountAnalyticsOutput?: AddYoutubeCompareAccountAnalyticsOutputResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  AddressDetails?: AddressDetailsResolvers<ContextType>;
  AddressForInfluencer?: AddressForInfluencerResolvers<ContextType>;
  Advertiser?: AdvertiserResolvers<ContextType>;
  AdvertiserForAdvertiserUser?: AdvertiserForAdvertiserUserResolvers<ContextType>;
  AdvertiserForAdvertiserUserSettings?: AdvertiserForAdvertiserUserSettingsResolvers<ContextType>;
  AdvertiserForList?: AdvertiserForListResolvers<ContextType>;
  AdvertiserForSearch?: AdvertiserForSearchResolvers<ContextType>;
  AdvertiserName?: AdvertiserNameResolvers<ContextType>;
  AdvertiserUser?: AdvertiserUserResolvers<ContextType>;
  AdvertiserUserSettingsDetails?: AdvertiserUserSettingsDetailsResolvers<ContextType>;
  AdvertiserUserWithRole?: AdvertiserUserWithRoleResolvers<ContextType>;
  AffiliateCampaignChartReport?: AffiliateCampaignChartReportResolvers<ContextType>;
  AgenciesForSearch?: AgenciesForSearchResolvers<ContextType>;
  AgencyLogo?: AgencyLogoResolvers<ContextType>;
  AgencyUser?: AgencyUserResolvers<ContextType>;
  AgencyUserWithRole?: AgencyUserWithRoleResolvers<ContextType>;
  AgencyWithAdvertisers?: AgencyWithAdvertisersResolvers<ContextType>;
  AgencyWithNSVerification?: AgencyWithNSVerificationResolvers<ContextType>;
  AiChatModelName?: AiChatModelNameResolvers<ContextType>;
  AllAccountAnalyticsForAdminPayload?: AllAccountAnalyticsForAdminPayloadResolvers<ContextType>;
  AllAccountAnalyticsForAdvertiserPayload?: AllAccountAnalyticsForAdvertiserPayloadResolvers<ContextType>;
  AllAdvertisersForSearch?: AllAdvertisersForSearchResolvers<ContextType>;
  AllCampaignsReportSummary?: AllCampaignsReportSummaryResolvers<ContextType>;
  AllInfluencerV4?: AllInfluencerV4Resolvers<ContextType>;
  AllStaffDetail?: AllStaffDetailResolvers<ContextType>;
  AllTiktokAdReportSummary?: AllTiktokAdReportSummaryResolvers<ContextType>;
  AllUserDetail?: AllUserDetailResolvers<ContextType>;
  AllowEngagementInfluencerProposalList?: AllowEngagementInfluencerProposalListResolvers<ContextType>;
  AllowInfluencersUserProposal?: AllowInfluencersUserProposalResolvers<ContextType>;
  AnalyticSubscriptionAvailableFeature?: AnalyticSubscriptionAvailableFeatureResolvers<ContextType>;
  AnalyticSubscriptionAvailableFeatures?: AnalyticSubscriptionAvailableFeaturesResolvers<ContextType>;
  AnalyticSubscriptionCapabilities?: AnalyticSubscriptionCapabilitiesResolvers<ContextType>;
  AnalyticSubscriptionCapability?: AnalyticSubscriptionCapabilityResolvers<ContextType>;
  AnalyticsSelectSubscriptionPlan?: AnalyticsSelectSubscriptionPlanResolvers<ContextType>;
  AnalyticsSocialAuthRedirectUrlPayload?: AnalyticsSocialAuthRedirectUrlPayloadResolvers<ContextType>;
  AnalyticsSubscriptionPlan?: AnalyticsSubscriptionPlanResolvers<ContextType>;
  AnalyticsSubscriptionPlanDetails?: AnalyticsSubscriptionPlanDetailsResolvers<ContextType>;
  AnalyticsSubscriptionPlanName?: AnalyticsSubscriptionPlanNameResolvers<ContextType>;
  AnyCreatorCategory?: AnyCreatorCategoryResolvers<ContextType>;
  AnyXAccountInfoPayload?: AnyXAccountInfoPayloadResolvers<ContextType>;
  AnyXCheckConnectedSocialAccountsForLinkBioPayload?: AnyXCheckConnectedSocialAccountsForLinkBioPayloadResolvers<ContextType>;
  AnyXCheckConnectedSocialAccountsPayload?: AnyXCheckConnectedSocialAccountsPayloadResolvers<ContextType>;
  AnyXCheckEmailPayload?: AnyXCheckEmailPayloadResolvers<ContextType>;
  AnyXConnectedChildYoutubeAccount?: AnyXConnectedChildYoutubeAccountResolvers<ContextType>;
  AnyXConnectedFacebookAccount?: AnyXConnectedFacebookAccountResolvers<ContextType>;
  AnyXConnectedFacebookAccountAndPageForLinkBio?: AnyXConnectedFacebookAccountAndPageForLinkBioResolvers<ContextType>;
  AnyXConnectedFacebookPage?: AnyXConnectedFacebookPageResolvers<ContextType>;
  AnyXConnectedInstagramAccount?: AnyXConnectedInstagramAccountResolvers<ContextType>;
  AnyXConnectedSocialAccount?: AnyXConnectedSocialAccountResolvers<ContextType>;
  AnyXConnectedSocialAccountForLinkBio?: AnyXConnectedSocialAccountForLinkBioResolvers<ContextType>;
  AnyXConnectedTikTokAccount?: AnyXConnectedTikTokAccountResolvers<ContextType>;
  AnyXConnectedYoutubeAccount?: AnyXConnectedYoutubeAccountResolvers<ContextType>;
  AnyXSignIn?: AnyXSignInResolvers<ContextType>;
  AnyXSignUp?: AnyXSignUpResolvers<ContextType>;
  AnyXSocialAuthConnectForLinkBioPayload?: AnyXSocialAuthConnectForLinkBioPayloadResolvers<ContextType>;
  AnyXSocialAuthConnectPayload?: AnyXSocialAuthConnectPayloadResolvers<ContextType>;
  AnyXSocialAuthDisconnectPayload?: AnyXSocialAuthDisconnectPayloadResolvers<ContextType>;
  AnyXSocialAuthReconnectForLinkBioPayload?: AnyXSocialAuthReconnectForLinkBioPayloadResolvers<ContextType>;
  AnyXSocialAuthReconnectTTCMPayload?: AnyXSocialAuthReconnectTTCMPayloadResolvers<ContextType>;
  AnyXSocialAuthReconnectTikTokPayload?: AnyXSocialAuthReconnectTikTokPayloadResolvers<ContextType>;
  AnyXSocialAuthSignInInstagramLinePayload?: AnyXSocialAuthSignInInstagramLinePayloadResolvers<ContextType>;
  AnyXSocialAuthSignInOrSignUpPayload?: AnyXSocialAuthSignInOrSignUpPayloadResolvers<ContextType>;
  AnyXSocialAuthSignInPayload?: AnyXSocialAuthSignInPayloadResolvers<ContextType>;
  AnyXSocialAuthSignUpPartnerPayload?: AnyXSocialAuthSignUpPartnerPayloadResolvers<ContextType>;
  AnyXSocialAuthSignUpPayload?: AnyXSocialAuthSignUpPayloadResolvers<ContextType>;
  AnyXSocialAuthSignUpTalentPayload?: AnyXSocialAuthSignUpTalentPayloadResolvers<ContextType>;
  ApplicantInfluencer?: ApplicantInfluencerResolvers<ContextType>;
  ApproveApplicantInfluencers?: ApproveApplicantInfluencersResolvers<ContextType>;
  ApproveAutoManagedPayout?: ApproveAutoManagedPayoutResolvers<ContextType>;
  ApproveCmsInvoice?: ApproveCmsInvoiceResolvers<ContextType>;
  ApproveMarketplace?: ApproveMarketplaceResolvers<ContextType>;
  ApproveMarketplaceAffiliatePayout?: ApproveMarketplaceAffiliatePayoutResolvers<ContextType>;
  ApproveMarketplaceDraftPost?: ApproveMarketplaceDraftPostResolvers<ContextType>;
  ApproveTikTokSpecialCampaign?: ApproveTikTokSpecialCampaignResolvers<ContextType>;
  AssignAdvertiserForStaffs?: AssignAdvertiserForStaffsResolvers<ContextType>;
  AssignChatPICPayload?: AssignChatPICPayloadResolvers<ContextType>;
  AssociateCompany?: AssociateCompanyResolvers<ContextType>;
  AudienceBreakdownAgeGenderRates?: AudienceBreakdownAgeGenderRatesResolvers<ContextType>;
  AudienceBreakdownAgeGroup?: AudienceBreakdownAgeGroupResolvers<ContextType>;
  AudienceBreakdownAgeRates?: AudienceBreakdownAgeRatesResolvers<ContextType>;
  AudienceBreakdownAreaRate?: AudienceBreakdownAreaRateResolvers<ContextType>;
  AudienceBreakdownGenderRates?: AudienceBreakdownGenderRatesResolvers<ContextType>;
  Auth0ToLocalJWT?: Auth0ToLocalJWTResolvers<ContextType>;
  AuthTiktokBusinessAccount?: AuthTiktokBusinessAccountResolvers<ContextType>;
  AutoManagedCampaignCountry?: AutoManagedCampaignCountryResolvers<ContextType>;
  AutoManagedCampaignForInfluencerSearchJobPayload?: AutoManagedCampaignForInfluencerSearchJobPayloadResolvers<ContextType>;
  AutoManagedCampaignForInfluencerYourJobPayload?: AutoManagedCampaignForInfluencerYourJobPayloadResolvers<ContextType>;
  AutoManagedCampaignInfoPayload?: AutoManagedCampaignInfoPayloadResolvers<ContextType>;
  AutoManagedCampaignMoney?: AutoManagedCampaignMoneyResolvers<ContextType>;
  AutoManagedCampaignPayload?: AutoManagedCampaignPayloadResolvers<ContextType>;
  AutoManagedCommissionInfluencer?: AutoManagedCommissionInfluencerResolvers<ContextType>;
  AutoManagedInfluencerInfo?: AutoManagedInfluencerInfoResolvers<ContextType>;
  AutoManagedJoinedInfluencer?: AutoManagedJoinedInfluencerResolvers<ContextType>;
  AutoManagedJoinedMethodsPayload?: AutoManagedJoinedMethodsPayloadResolvers<ContextType>;
  AutoManagedJoinedMethodsPayloadV2?: AutoManagedJoinedMethodsPayloadV2Resolvers<ContextType>;
  AutoManagedReportByDate?: AutoManagedReportByDateResolvers<ContextType>;
  AutoManagedReportByInfluencer?: AutoManagedReportByInfluencerResolvers<ContextType>;
  AutoManagedReportForInfluencer?: AutoManagedReportForInfluencerResolvers<ContextType>;
  AutoManagedReportSummary?: AutoManagedReportSummaryResolvers<ContextType>;
  AutoManagedReportSummaryForInfluencer?: AutoManagedReportSummaryForInfluencerResolvers<ContextType>;
  AvailableInfluencer?: AvailableInfluencerResolvers<ContextType>;
  AvailableInfluencersForStaffPayload?: AvailableInfluencersForStaffPayloadResolvers<ContextType>;
  Bank?: BankResolvers<ContextType>;
  BankBranch?: BankBranchResolvers<ContextType>;
  BankBranchForInfluencer?: BankBranchForInfluencerResolvers<ContextType>;
  BankForInfluencer?: BankForInfluencerResolvers<ContextType>;
  BasicAccountAnalyticsPayload?: BasicAccountAnalyticsPayloadResolvers<ContextType>;
  BigInt?: GraphQLScalarType;
  BillingInformation?: BillingInformationResolvers<ContextType>;
  BioAnalyticsCity?: BioAnalyticsCityResolvers<ContextType>;
  BioAnalyticsCountry?: BioAnalyticsCountryResolvers<ContextType>;
  BioAnalyticsOtherLinkClicks?: BioAnalyticsOtherLinkClicksResolvers<ContextType>;
  BioAnalyticsReferral?: BioAnalyticsReferralResolvers<ContextType>;
  BioAnalyticsSocialMediaClicks?: BioAnalyticsSocialMediaClicksResolvers<ContextType>;
  BioAnalyticsTotalClicks?: BioAnalyticsTotalClicksResolvers<ContextType>;
  BioImage?: BioImageResolvers<ContextType>;
  BioInfluencerProfile?: BioInfluencerProfileResolvers<ContextType>;
  BioSection?: BioSectionResolvers<ContextType>;
  BioSectionV2?: BioSectionV2Resolvers<ContextType>;
  BioSocialMedia?: BioSocialMediaResolvers<ContextType>;
  BioText?: BioTextResolvers<ContextType>;
  BioUrlLink?: BioUrlLinkResolvers<ContextType>;
  BoostTiktokAdPost?: BoostTiktokAdPostResolvers<ContextType>;
  BotTextMessageEvent?: BotTextMessageEventResolvers<ContextType>;
  BrandAccount?: BrandAccountResolvers<ContextType>;
  BulkUpdateEngagementInfluencersBudget?: BulkUpdateEngagementInfluencersBudgetResolvers<ContextType>;
  BulkUpdateEngagementSocialAccountsBudget?: BulkUpdateEngagementSocialAccountsBudgetResolvers<ContextType>;
  BulkUpdatePackageProposalAccountsBudget?: BulkUpdatePackageProposalAccountsBudgetResolvers<ContextType>;
  BulkUpdatePackageProposalInfluencersBudget?: BulkUpdatePackageProposalInfluencersBudgetResolvers<ContextType>;
  BulkUpdateUserProposalAccountsBudget?: BulkUpdateUserProposalAccountsBudgetResolvers<ContextType>;
  BulkUpdateUserProposalInfluencersBudget?: BulkUpdateUserProposalInfluencersBudgetResolvers<ContextType>;
  ButtonMessagePayload?: ButtonMessagePayloadResolvers<ContextType>;
  CampaignCategoryName?: CampaignCategoryNameResolvers<ContextType>;
  CampaignChartReport?: CampaignChartReportResolvers<ContextType>;
  CampaignChartReportForAllTime?: CampaignChartReportForAllTimeResolvers<ContextType>;
  CampaignCreator?: CampaignCreatorResolvers<ContextType>;
  CampaignCreatorWithName?: CampaignCreatorWithNameResolvers<ContextType>;
  CampaignDailyStats?: CampaignDailyStatsResolvers<ContextType>;
  CampaignDetailReport?: CampaignDetailReportResolvers<ContextType>;
  CampaignForInfluencerForYourJob?: CampaignForInfluencerForYourJobResolvers<ContextType>;
  CampaignForInfluencerForYourJobCounts?: CampaignForInfluencerForYourJobCountsResolvers<ContextType>;
  CampaignForPostFilter?: CampaignForPostFilterResolvers<ContextType>;
  CampaignForSearch?: CampaignForSearchResolvers<ContextType>;
  CampaignGridReport?: CampaignGridReportResolvers<ContextType>;
  CampaignInfluencerInfo?: CampaignInfluencerInfoResolvers<ContextType>;
  CampaignInfluencerReport?: CampaignInfluencerReportResolvers<ContextType>;
  CampaignPayment?: CampaignPaymentResolvers<ContextType>;
  CampaignPost?: CampaignPostResolvers<ContextType>;
  CampaignPostGridInfo?: CampaignPostGridInfoResolvers<ContextType>;
  CampaignPostInfo?: CampaignPostInfoResolvers<ContextType>;
  CampaignPostMetrics?: CampaignPostMetricsResolvers<ContextType>;
  CampaignPostReportGrid?: CampaignPostReportGridResolvers<ContextType>;
  CampaignPosts?: CampaignPostsResolvers<ContextType>;
  CampaignPostsSummary?: CampaignPostsSummaryResolvers<ContextType>;
  CampaignReportCampaignInfo?: CampaignReportCampaignInfoResolvers<ContextType>;
  CampaignReportCampaignInfoForInfluencer?: CampaignReportCampaignInfoForInfluencerResolvers<ContextType>;
  CampaignReportSummary?: CampaignReportSummaryResolvers<ContextType>;
  CampaignSummaryDaily?: CampaignSummaryDailyResolvers<ContextType>;
  CampaignsForSearchJobPayload?: CampaignsForSearchJobPayloadResolvers<ContextType>;
  CancelSubscriptionPlan?: CancelSubscriptionPlanResolvers<ContextType>;
  CardPayload?: CardPayloadResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  CategoryName?: CategoryNameResolvers<ContextType>;
  ChangeInstagramUGCManagementPostStatusOutput?: ChangeInstagramUGCManagementPostStatusOutputResolvers<ContextType>;
  ChatEvent?: ChatEventResolvers<ContextType>;
  CheckBioLinkNameAvailabilityPayload?: CheckBioLinkNameAvailabilityPayloadResolvers<ContextType>;
  City?: CityResolvers<ContextType>;
  CityForInfluencer?: CityForInfluencerResolvers<ContextType>;
  ClearCmsChannelAssetOwner?: ClearCmsChannelAssetOwnerResolvers<ContextType>;
  ClickNotificationByEventIdPayload?: ClickNotificationByEventIdPayloadResolvers<ContextType>;
  ClickNotificationPayload?: ClickNotificationPayloadResolvers<ContextType>;
  CloneMarketplace?: CloneMarketplaceResolvers<ContextType>;
  CommentContent?: CommentContentResolvers<ContextType>;
  ConnectFacebookPagesAndInstagramAccountsPayload?: ConnectFacebookPagesAndInstagramAccountsPayloadResolvers<ContextType>;
  ConnectNewShopifyAccountPayload?: ConnectNewShopifyAccountPayloadResolvers<ContextType>;
  ConnectPackageToEngagementProposal?: ConnectPackageToEngagementProposalResolvers<ContextType>;
  ConnectPackageWithInfluencerAccounts?: ConnectPackageWithInfluencerAccountsResolvers<ContextType>;
  ConnectPackageWithSocialAccounts?: ConnectPackageWithSocialAccountsResolvers<ContextType>;
  ConnectPackageWithUserProposal?: ConnectPackageWithUserProposalResolvers<ContextType>;
  ConnectPromotionMethodsPayload?: ConnectPromotionMethodsPayloadResolvers<ContextType>;
  ConnectableFacebookPage?: ConnectableFacebookPageResolvers<ContextType>;
  ConnectableFacebookPageAccount?: ConnectableFacebookPageAccountResolvers<ContextType>;
  ConnectableFacebookPageAndInstagramAccount?: ConnectableFacebookPageAndInstagramAccountResolvers<ContextType>;
  ConnectableFacebookPageMainAccount?: ConnectableFacebookPageMainAccountResolvers<ContextType>;
  ConnectableFacebookPagesAndInstagramAccountsPayload?: ConnectableFacebookPagesAndInstagramAccountsPayloadResolvers<ContextType>;
  ConnectableFacebookPagesPayload?: ConnectableFacebookPagesPayloadResolvers<ContextType>;
  ConnectableInstagramAccount?: ConnectableInstagramAccountResolvers<ContextType>;
  ConnectableInstagramAccountWithNoConnectedFlag?: ConnectableInstagramAccountWithNoConnectedFlagResolvers<ContextType>;
  ConnectableInstagramAccountsPayload?: ConnectableInstagramAccountsPayloadResolvers<ContextType>;
  ConnectableOauthAccount?: ConnectableOauthAccountResolvers<ContextType>;
  ConnectableOauthAccountForTalentSignIn?: ConnectableOauthAccountForTalentSignInResolvers<ContextType>;
  ConnectableOauthFacebookAccountForTalentSignIn?: ConnectableOauthFacebookAccountForTalentSignInResolvers<ContextType>;
  ConnectableOauthInstagramAccountForTalentSignIn?: ConnectableOauthInstagramAccountForTalentSignInResolvers<ContextType>;
  ConnectableOauthPageAndIgAccount?: ConnectableOauthPageAndIgAccountResolvers<ContextType>;
  ConnectableOauthPageAndIgAccountForSignInFacebook?: ConnectableOauthPageAndIgAccountForSignInFacebookResolvers<ContextType>;
  ConnectableOauthPageAndIgAccountForTalentSignIn?: ConnectableOauthPageAndIgAccountForTalentSignInResolvers<ContextType>;
  ConnectableOauthYouTubeAccountForTalentSignIn?: ConnectableOauthYouTubeAccountForTalentSignInResolvers<ContextType>;
  ConnectedAccountForLinkBio?: ConnectedAccountForLinkBioResolvers<ContextType>;
  ConnectedFacebook?: ConnectedFacebookResolvers<ContextType>;
  ConnectedFacebookPage?: ConnectedFacebookPageResolvers<ContextType>;
  ConnectedPage?: ConnectedPageResolvers<ContextType>;
  ConnectedPageAndIgAccount?: ConnectedPageAndIgAccountResolvers<ContextType>;
  ConnectedTwitter?: ConnectedTwitterResolvers<ContextType>;
  ConnectedYouTube?: ConnectedYouTubeResolvers<ContextType>;
  CountriesPayload?: CountriesPayloadResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  CountryAndCurrency?: CountryAndCurrencyResolvers<ContextType>;
  CountryName?: CountryNameResolvers<ContextType>;
  CountryNameForInfluencer?: CountryNameForInfluencerResolvers<ContextType>;
  CreateAdmin?: CreateAdminResolvers<ContextType>;
  CreateAdvertiser?: CreateAdvertiserResolvers<ContextType>;
  CreateAdvertiserUser?: CreateAdvertiserUserResolvers<ContextType>;
  CreateAdvertiserWithUser?: CreateAdvertiserWithUserResolvers<ContextType>;
  CreateAdvertiserWithUserV2?: CreateAdvertiserWithUserV2Resolvers<ContextType>;
  CreateAgency?: CreateAgencyResolvers<ContextType>;
  CreateAgencyWithUser?: CreateAgencyWithUserResolvers<ContextType>;
  CreateCreatorStaffPayload?: CreateCreatorStaffPayloadResolvers<ContextType>;
  CreateDraftBoostPost?: CreateDraftBoostPostResolvers<ContextType>;
  CreateEngagement?: CreateEngagementResolvers<ContextType>;
  CreateEngagementPostForInfluencerV2?: CreateEngagementPostForInfluencerV2Resolvers<ContextType>;
  CreateEngagementPostV2?: CreateEngagementPostV2Resolvers<ContextType>;
  CreateGeminiThread?: CreateGeminiThreadResolvers<ContextType>;
  CreateInfluencerBioPayload?: CreateInfluencerBioPayloadResolvers<ContextType>;
  CreateInfluencerStaffPayload?: CreateInfluencerStaffPayloadResolvers<ContextType>;
  CreateInfluencerV2?: CreateInfluencerV2Resolvers<ContextType>;
  CreateInstagramUGCManagementHashtagOutput?: CreateInstagramUGCManagementHashtagOutputResolvers<ContextType>;
  CreateInstagramUserHashtagOutput?: CreateInstagramUserHashtagOutputResolvers<ContextType>;
  CreateLineBroadcastMessagePayload?: CreateLineBroadcastMessagePayloadResolvers<ContextType>;
  CreateLineChannelPayload?: CreateLineChannelPayloadResolvers<ContextType>;
  CreateMarketplace?: CreateMarketplaceResolvers<ContextType>;
  CreateMarketplaceDraftPost?: CreateMarketplaceDraftPostResolvers<ContextType>;
  CreateMobileAppVersion?: CreateMobileAppVersionResolvers<ContextType>;
  CreateOpenAIThread?: CreateOpenAIThreadResolvers<ContextType>;
  CreatePackage?: CreatePackageResolvers<ContextType>;
  CreatePackageWithInfluencerAccounts?: CreatePackageWithInfluencerAccountsResolvers<ContextType>;
  CreatePackageWithSocialAccounts?: CreatePackageWithSocialAccountsResolvers<ContextType>;
  CreatePackageWithUserProposal?: CreatePackageWithUserProposalResolvers<ContextType>;
  CreatePartnerUser?: CreatePartnerUserResolvers<ContextType>;
  CreateSelfRegisteredAdvertiserUser?: CreateSelfRegisteredAdvertiserUserResolvers<ContextType>;
  CreateSocialAccountAnalyticsAdminOutputV2?: CreateSocialAccountAnalyticsAdminOutputV2Resolvers<ContextType>;
  CreateSocialAccountAnalyticsAdvertiserOutputV2?: CreateSocialAccountAnalyticsAdvertiserOutputV2Resolvers<ContextType>;
  CreateStaff?: CreateStaffResolvers<ContextType>;
  CreateTalentAgencyUser?: CreateTalentAgencyUserResolvers<ContextType>;
  CreateTalentAgencyWithUser?: CreateTalentAgencyWithUserResolvers<ContextType>;
  CreateTalentInfluencerPayload?: CreateTalentInfluencerPayloadResolvers<ContextType>;
  CreateTikTokSpecialCampaign?: CreateTikTokSpecialCampaignResolvers<ContextType>;
  CreateTiktokAdAccount?: CreateTiktokAdAccountResolvers<ContextType>;
  CreateTiktokBusinessCenter?: CreateTiktokBusinessCenterResolvers<ContextType>;
  CreateTiktokUserHashtagOutput?: CreateTiktokUserHashtagOutputResolvers<ContextType>;
  CreateTiktokUserKeywordOutput?: CreateTiktokUserKeywordOutputResolvers<ContextType>;
  CreatorAddressPayload?: CreatorAddressPayloadResolvers<ContextType>;
  CreatorStaff?: CreatorStaffResolvers<ContextType>;
  CreatorStaffsCountPayload?: CreatorStaffsCountPayloadResolvers<ContextType>;
  CreatorStaffsPayload?: CreatorStaffsPayloadResolvers<ContextType>;
  CurrentUserCurrency?: CurrentUserCurrencyResolvers<ContextType>;
  CustomPermissionLevel?: CustomPermissionLevelResolvers<ContextType>;
  CustomPermissions?: CustomPermissionsResolvers<ContextType>;
  CustomerTagPayload?: CustomerTagPayloadResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DeleteAdvertiser?: DeleteAdvertiserResolvers<ContextType>;
  DeleteAgency?: DeleteAgencyResolvers<ContextType>;
  DeleteBrandAccountAnalyticsOutput?: DeleteBrandAccountAnalyticsOutputResolvers<ContextType>;
  DeleteCommentPayload?: DeleteCommentPayloadResolvers<ContextType>;
  DeleteCompareInstagramAccountAnalytics?: DeleteCompareInstagramAccountAnalyticsResolvers<ContextType>;
  DeleteEngagement?: DeleteEngagementResolvers<ContextType>;
  DeleteEngagementInfluencerProposal?: DeleteEngagementInfluencerProposalResolvers<ContextType>;
  DeleteEngagementPostForInfluencer?: DeleteEngagementPostForInfluencerResolvers<ContextType>;
  DeleteEngagementProposalJoinedAccount?: DeleteEngagementProposalJoinedAccountResolvers<ContextType>;
  DeleteEngagementSocialAccountProposal?: DeleteEngagementSocialAccountProposalResolvers<ContextType>;
  DeleteFanTagsPayload?: DeleteFanTagsPayloadResolvers<ContextType>;
  DeleteGeminiThread?: DeleteGeminiThreadResolvers<ContextType>;
  DeleteInfluencer?: DeleteInfluencerResolvers<ContextType>;
  DeleteInfluencerPackage?: DeleteInfluencerPackageResolvers<ContextType>;
  DeleteInstagramHashtagAnalytics?: DeleteInstagramHashtagAnalyticsResolvers<ContextType>;
  DeleteInstagramUGCManagementHashtagOutput?: DeleteInstagramUGCManagementHashtagOutputResolvers<ContextType>;
  DeleteInstagramUserHashtagOutput?: DeleteInstagramUserHashtagOutputResolvers<ContextType>;
  DeleteMarketplace?: DeleteMarketplaceResolvers<ContextType>;
  DeleteOpenAIThread?: DeleteOpenAIThreadResolvers<ContextType>;
  DeletePackage?: DeletePackageResolvers<ContextType>;
  DeleteSocialAccountPackage?: DeleteSocialAccountPackageResolvers<ContextType>;
  DeleteTalentAgency?: DeleteTalentAgencyResolvers<ContextType>;
  DeleteTikTokSpecialCampaign?: DeleteTikTokSpecialCampaignResolvers<ContextType>;
  DeleteTiktokUserHashtagOutput?: DeleteTiktokUserHashtagOutputResolvers<ContextType>;
  DeleteTiktokUserKeywordOutput?: DeleteTiktokUserKeywordOutputResolvers<ContextType>;
  DeleteUser?: DeleteUserResolvers<ContextType>;
  DeleteUserProposalInfluencer?: DeleteUserProposalInfluencerResolvers<ContextType>;
  DeleteUserProposalSocialAccount?: DeleteUserProposalSocialAccountResolvers<ContextType>;
  DeleteUserProposalSocialAccountIndividually?: DeleteUserProposalSocialAccountIndividuallyResolvers<ContextType>;
  DeleteYoutubeCompareAccountAnalyticsOutput?: DeleteYoutubeCompareAccountAnalyticsOutputResolvers<ContextType>;
  DeliveryTimingItemPayload?: DeliveryTimingItemPayloadResolvers<ContextType>;
  DenyEngagementInfluencerProposalList?: DenyEngagementInfluencerProposalListResolvers<ContextType>;
  DenyInfluencersUserProposal?: DenyInfluencersUserProposalResolvers<ContextType>;
  DisconnectPackageFromEngagementProposal?: DisconnectPackageFromEngagementProposalResolvers<ContextType>;
  DisconnectPromotionMethodsPayload?: DisconnectPromotionMethodsPayloadResolvers<ContextType>;
  DisconnectTiktokAdAccount?: DisconnectTiktokAdAccountResolvers<ContextType>;
  DouyinAccountGeneralCategory?: DouyinAccountGeneralCategoryResolvers<ContextType>;
  DouyinAccountInterest?: DouyinAccountInterestResolvers<ContextType>;
  DouyinAccountInterestRate?: DouyinAccountInterestRateResolvers<ContextType>;
  DouyinAudienceSection?: DouyinAudienceSectionResolvers<ContextType>;
  DouyinFollowerRegionRate?: DouyinFollowerRegionRateResolvers<ContextType>;
  DouyinInfluencer?: DouyinInfluencerResolvers<ContextType>;
  DouyinInfluencerSearchResult?: DouyinInfluencerSearchResultResolvers<ContextType>;
  EditSocialAccountAnalyticsAdminOutputV2?: EditSocialAccountAnalyticsAdminOutputV2Resolvers<ContextType>;
  EditSocialAccountAnalyticsAdvertiserOutputV2?: EditSocialAccountAnalyticsAdvertiserOutputV2Resolvers<ContextType>;
  EmailInfo?: EmailInfoResolvers<ContextType>;
  EmailInfoForList?: EmailInfoForListResolvers<ContextType>;
  EmailStatistics?: EmailStatisticsResolvers<ContextType>;
  Engagement?: EngagementResolvers<ContextType>;
  EngagementAddInfluencersToProposalFromAnotherCampaign?: EngagementAddInfluencersToProposalFromAnotherCampaignResolvers<ContextType>;
  EngagementCampaignCouponTrackingPayload?: EngagementCampaignCouponTrackingPayloadResolvers<ContextType>;
  EngagementCampaignPostReportAudienceBreakdown?: EngagementCampaignPostReportAudienceBreakdownResolvers<ContextType>;
  EngagementCampaignPostReportAudienceBreakdownForTikTok?: EngagementCampaignPostReportAudienceBreakdownForTikTokResolvers<ContextType>;
  EngagementCampaignPostReportAudienceBreakdownForYouTube?: EngagementCampaignPostReportAudienceBreakdownForYouTubeResolvers<ContextType>;
  EngagementCampaignPostReportForInfluencer?: EngagementCampaignPostReportForInfluencerResolvers<ContextType>;
  EngagementCampaignPostsForInsightCheck?: EngagementCampaignPostsForInsightCheckResolvers<ContextType>;
  EngagementCampaignReport?: EngagementCampaignReportResolvers<ContextType>;
  EngagementCampaignSearchSelectInfluencers?: EngagementCampaignSearchSelectInfluencersResolvers<ContextType>;
  EngagementDetail?: EngagementDetailResolvers<ContextType>;
  EngagementDetailForInfluencer?: EngagementDetailForInfluencerResolvers<ContextType>;
  EngagementDraftPostHistory?: EngagementDraftPostHistoryResolvers<ContextType>;
  EngagementDraftPostHistoryForInfluencer?: EngagementDraftPostHistoryForInfluencerResolvers<ContextType>;
  EngagementDraftPostHistoryRecord?: EngagementDraftPostHistoryRecordResolvers<ContextType>;
  EngagementDraftPostHistoryRecordForInfluencer?: EngagementDraftPostHistoryRecordForInfluencerResolvers<ContextType>;
  EngagementForInsightCheck?: EngagementForInsightCheckResolvers<ContextType>;
  EngagementPost?: EngagementPostResolvers<ContextType>;
  EngagementPostComment?: EngagementPostCommentResolvers<ContextType>;
  EngagementPostDetail?: EngagementPostDetailResolvers<ContextType>;
  EngagementPostDetailForInfluencer?: EngagementPostDetailForInfluencerResolvers<ContextType>;
  EngagementPostDetailForInfluencerV2?: EngagementPostDetailForInfluencerV2Resolvers<ContextType>;
  EngagementPostDetailInfluencer?: EngagementPostDetailInfluencerResolvers<ContextType>;
  EngagementPostEditV2?: EngagementPostEditV2Resolvers<ContextType>;
  EngagementPostForInfluencer?: EngagementPostForInfluencerResolvers<ContextType>;
  EngagementPostForInsightCheck?: EngagementPostForInsightCheckResolvers<ContextType>;
  EngagementPostInfluencer?: EngagementPostInfluencerResolvers<ContextType>;
  EngagementPostInfluencerWithSocialAccount?: EngagementPostInfluencerWithSocialAccountResolvers<ContextType>;
  EngagementPostInfluencerWithSocialAccounts?: EngagementPostInfluencerWithSocialAccountsResolvers<ContextType>;
  EngagementPostMetricsDataForInsightCheck?: EngagementPostMetricsDataForInsightCheckResolvers<ContextType>;
  EngagementPostSocialAccount?: EngagementPostSocialAccountResolvers<ContextType>;
  EngagementPostTrafficSource?: EngagementPostTrafficSourceResolvers<ContextType>;
  EngagementProposal?: EngagementProposalResolvers<ContextType>;
  EngagementTracking?: EngagementTrackingResolvers<ContextType>;
  EngagementXhsPostMetricsDataForInsight?: EngagementXhsPostMetricsDataForInsightResolvers<ContextType>;
  EngagementsForInsightCheck?: EngagementsForInsightCheckResolvers<ContextType>;
  EngagementsForInsightCheckCount?: EngagementsForInsightCheckCountResolvers<ContextType>;
  ExistingProfileTags?: ExistingProfileTagsResolvers<ContextType>;
  ExportCmsInvoiceToSpreadsheet?: ExportCmsInvoiceToSpreadsheetResolvers<ContextType>;
  ExportDashboardInstagramAccountAnalytics?: ExportDashboardInstagramAccountAnalyticsResolvers<ContextType>;
  ExportInstagramHashtagPostsToGoogleSpreadsheetOutput?: ExportInstagramHashtagPostsToGoogleSpreadsheetOutputResolvers<ContextType>;
  ExportYoutubeDashboardAccountAnalyticsOutput?: ExportYoutubeDashboardAccountAnalyticsOutputResolvers<ContextType>;
  ExternalAnalyticsFacebookGetAccountDetailsOutput?: ExternalAnalyticsFacebookGetAccountDetailsOutputResolvers<ContextType>;
  ExternalAnalyticsFacebookGetTokenWarningsOutput?: ExternalAnalyticsFacebookGetTokenWarningsOutputResolvers<ContextType>;
  ExternalAnalyticsInstagramGetAccountDetailsOutput?: ExternalAnalyticsInstagramGetAccountDetailsOutputResolvers<ContextType>;
  ExternalAnalyticsInstagramGetTokenWarningsOutput?: ExternalAnalyticsInstagramGetTokenWarningsOutputResolvers<ContextType>;
  ExternalAnalyticsSocialAuthRedirectUrlPayload?: ExternalAnalyticsSocialAuthRedirectUrlPayloadResolvers<ContextType>;
  ExternalConnectableFacebookPageAccount?: ExternalConnectableFacebookPageAccountResolvers<ContextType>;
  ExternalConnectableFacebookPageAnalyticsPayload?: ExternalConnectableFacebookPageAnalyticsPayloadResolvers<ContextType>;
  ExternalConnectableFacebookPageMainAccount?: ExternalConnectableFacebookPageMainAccountResolvers<ContextType>;
  ExternalConnectableOauthAccount?: ExternalConnectableOauthAccountResolvers<ContextType>;
  ExternalConnectableOauthPageAndIgAccount?: ExternalConnectableOauthPageAndIgAccountResolvers<ContextType>;
  ExternalCreateFacebookPageAccountAnalyticsOutput?: ExternalCreateFacebookPageAccountAnalyticsOutputResolvers<ContextType>;
  ExternalCreateInstagramAccountAnalyticsOutput?: ExternalCreateInstagramAccountAnalyticsOutputResolvers<ContextType>;
  ExternalCreateYoutubeAccountAnalyticsOutput?: ExternalCreateYoutubeAccountAnalyticsOutputResolvers<ContextType>;
  ExternalInstagramAnalyticsPostDetails?: ExternalInstagramAnalyticsPostDetailsResolvers<ContextType>;
  ExternalInstagramAnalyticsPostsInDate?: ExternalInstagramAnalyticsPostsInDateResolvers<ContextType>;
  ExternalInstagramAnalyticsReelDetails?: ExternalInstagramAnalyticsReelDetailsResolvers<ContextType>;
  ExternalInstagramAnalyticsStoryDetails?: ExternalInstagramAnalyticsStoryDetailsResolvers<ContextType>;
  ExternalInstagramDashboardFollowerAnalytics?: ExternalInstagramDashboardFollowerAnalyticsResolvers<ContextType>;
  ExternalInstagramDashboardOverview?: ExternalInstagramDashboardOverviewResolvers<ContextType>;
  ExternalInstagramDashboardPostAnalytics?: ExternalInstagramDashboardPostAnalyticsResolvers<ContextType>;
  ExternalInstagramFeedPost?: ExternalInstagramFeedPostResolvers<ContextType>;
  ExternalInstagramReelPost?: ExternalInstagramReelPostResolvers<ContextType>;
  ExternalInstagramStoryPost?: ExternalInstagramStoryPostResolvers<ContextType>;
  ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload?: ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayloadResolvers<ContextType>;
  ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload?: ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayloadResolvers<ContextType>;
  ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload?: ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload?: ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload?: ExternalYoutubeAnalyticsAudienceAgeBreakdownPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload?: ExternalYoutubeAnalyticsAudienceGenderBreakdownPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsAudiencePayload?: ExternalYoutubeAnalyticsAudiencePayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsAudienceRegionPayload?: ExternalYoutubeAnalyticsAudienceRegionPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsOverviewHistoryItem?: ExternalYoutubeAnalyticsOverviewHistoryItemResolvers<ContextType>;
  ExternalYoutubeAnalyticsOverviewItem?: ExternalYoutubeAnalyticsOverviewItemResolvers<ContextType>;
  ExternalYoutubeAnalyticsOverviewPayload?: ExternalYoutubeAnalyticsOverviewPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsPostComment?: ExternalYoutubeAnalyticsPostCommentResolvers<ContextType>;
  ExternalYoutubeAnalyticsPostListByDatePayload?: ExternalYoutubeAnalyticsPostListByDatePayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsPostListItemByDatePayload?: ExternalYoutubeAnalyticsPostListItemByDatePayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsPostListItemPayload?: ExternalYoutubeAnalyticsPostListItemPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsPostListPayload?: ExternalYoutubeAnalyticsPostListPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsPostsAverageEngagement?: ExternalYoutubeAnalyticsPostsAverageEngagementResolvers<ContextType>;
  ExternalYoutubeAnalyticsPostsPostingHabit?: ExternalYoutubeAnalyticsPostsPostingHabitResolvers<ContextType>;
  ExternalYoutubeAnalyticsPostsStatisticsPayload?: ExternalYoutubeAnalyticsPostsStatisticsPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsRelatedPostPayload?: ExternalYoutubeAnalyticsRelatedPostPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsRelatedPostsPayload?: ExternalYoutubeAnalyticsRelatedPostsPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsTagRankingPayload?: ExternalYoutubeAnalyticsTagRankingPayloadResolvers<ContextType>;
  ExternalYoutubeAnalyticsTagRankingPayloads?: ExternalYoutubeAnalyticsTagRankingPayloadsResolvers<ContextType>;
  ExternalYoutubePostPayload?: ExternalYoutubePostPayloadResolvers<ContextType>;
  ExternalYoutubeStatisticsData?: ExternalYoutubeStatisticsDataResolvers<ContextType>;
  FacebookAccountInfluencerSearchResultV2?: FacebookAccountInfluencerSearchResultV2Resolvers<ContextType>;
  FacebookAccountInfluencerV2?: FacebookAccountInfluencerV2Resolvers<ContextType>;
  FacebookInfluencer?: FacebookInfluencerResolvers<ContextType>;
  FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload?: FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayloadResolvers<ContextType>;
  FacebookPageAnalyticsAudienceAgeBreakdownPayload?: FacebookPageAnalyticsAudienceAgeBreakdownPayloadResolvers<ContextType>;
  FacebookPageAnalyticsAudienceGenderBreakdownPayload?: FacebookPageAnalyticsAudienceGenderBreakdownPayloadResolvers<ContextType>;
  FacebookPageAnalyticsAudiencePayload?: FacebookPageAnalyticsAudiencePayloadResolvers<ContextType>;
  FacebookPageAnalyticsAudienceRegionPayload?: FacebookPageAnalyticsAudienceRegionPayloadResolvers<ContextType>;
  FacebookPageAnalyticsOverviewHistoryItem?: FacebookPageAnalyticsOverviewHistoryItemResolvers<ContextType>;
  FacebookPageAnalyticsOverviewHistoryRateItem?: FacebookPageAnalyticsOverviewHistoryRateItemResolvers<ContextType>;
  FacebookPageAnalyticsOverviewItem?: FacebookPageAnalyticsOverviewItemResolvers<ContextType>;
  FacebookPageAnalyticsOverviewPayload?: FacebookPageAnalyticsOverviewPayloadResolvers<ContextType>;
  FacebookPageAnalyticsOverviewRateItem?: FacebookPageAnalyticsOverviewRateItemResolvers<ContextType>;
  FacebookPageAnalyticsPostComment?: FacebookPageAnalyticsPostCommentResolvers<ContextType>;
  FacebookPageAnalyticsPostPayload?: FacebookPageAnalyticsPostPayloadResolvers<ContextType>;
  FacebookPageAnalyticsPostWithPageDetailsPayload?: FacebookPageAnalyticsPostWithPageDetailsPayloadResolvers<ContextType>;
  FacebookPageAnalyticsPostsAnalyticsPayload?: FacebookPageAnalyticsPostsAnalyticsPayloadResolvers<ContextType>;
  FacebookPageAnalyticsPostsAverageEngagement?: FacebookPageAnalyticsPostsAverageEngagementResolvers<ContextType>;
  FacebookPageAnalyticsPostsByDatePayload?: FacebookPageAnalyticsPostsByDatePayloadResolvers<ContextType>;
  FacebookPageAnalyticsPostsPayload?: FacebookPageAnalyticsPostsPayloadResolvers<ContextType>;
  FacebookPageAnalyticsPostsPayloads?: FacebookPageAnalyticsPostsPayloadsResolvers<ContextType>;
  FacebookPageForProfileV2?: FacebookPageForProfileV2Resolvers<ContextType>;
  FacebookPageInfluencer?: FacebookPageInfluencerResolvers<ContextType>;
  FacebookPageInfluencerSearchResultV2?: FacebookPageInfluencerSearchResultV2Resolvers<ContextType>;
  FacebookPageInfluencerV2?: FacebookPageInfluencerV2Resolvers<ContextType>;
  FacebookPagePostsAnalyticsFanActivity?: FacebookPagePostsAnalyticsFanActivityResolvers<ContextType>;
  FacebookPageStatisticsData?: FacebookPageStatisticsDataResolvers<ContextType>;
  FacebookPostForLinkBio?: FacebookPostForLinkBioResolvers<ContextType>;
  FanActivity?: FanActivityResolvers<ContextType>;
  FanDetails?: FanDetailsResolvers<ContextType>;
  FanFormAnswer?: FanFormAnswerResolvers<ContextType>;
  FanOrderDetails?: FanOrderDetailsResolvers<ContextType>;
  FanOrderItem?: FanOrderItemResolvers<ContextType>;
  FanTag?: FanTagResolvers<ContextType>;
  FileUploadUrls?: FileUploadUrlsResolvers<ContextType>;
  FollowersAgeGenderGroup?: FollowersAgeGenderGroupResolvers<ContextType>;
  FollowersAgeGroup?: FollowersAgeGroupResolvers<ContextType>;
  FollowersByTime?: FollowersByTimeResolvers<ContextType>;
  FollowersCountry?: FollowersCountryResolvers<ContextType>;
  FormItem?: FormItemResolvers<ContextType>;
  FormQuestionAnswer?: FormQuestionAnswerResolvers<ContextType>;
  FormReportAnswerOption?: FormReportAnswerOptionResolvers<ContextType>;
  FormReportQuestionAnswer?: FormReportQuestionAnswerResolvers<ContextType>;
  FormReportSummaryPayload?: FormReportSummaryPayloadResolvers<ContextType>;
  FormRespondentItem?: FormRespondentItemResolvers<ContextType>;
  FormRespondentsCountPayload?: FormRespondentsCountPayloadResolvers<ContextType>;
  FormRespondentsListPayload?: FormRespondentsListPayloadResolvers<ContextType>;
  GeminiMessage?: GeminiMessageResolvers<ContextType>;
  GenerateImportFansUploadURLPayload?: GenerateImportFansUploadURLPayloadResolvers<ContextType>;
  GenerateMarketplaceCampaignDetailPayload?: GenerateMarketplaceCampaignDetailPayloadResolvers<ContextType>;
  GeneratePartnerInfluencerSignUpUrl?: GeneratePartnerInfluencerSignUpUrlResolvers<ContextType>;
  GenerateTalentInfluencerSignUpUrl?: GenerateTalentInfluencerSignUpUrlResolvers<ContextType>;
  GetAccountInformationPayload?: GetAccountInformationPayloadResolvers<ContextType>;
  GetAllCampaignsCountForSearchJobPayload?: GetAllCampaignsCountForSearchJobPayloadResolvers<ContextType>;
  GetAllCampaignsForSearchJobPayload?: GetAllCampaignsForSearchJobPayloadResolvers<ContextType>;
  GetAnyXAccountsByKeywordPayload?: GetAnyXAccountsByKeywordPayloadResolvers<ContextType>;
  GetAutoManagedCampaignsCountPayload?: GetAutoManagedCampaignsCountPayloadResolvers<ContextType>;
  GetAutoManagedCampaignsPayload?: GetAutoManagedCampaignsPayloadResolvers<ContextType>;
  GetChatEventsPayload?: GetChatEventsPayloadResolvers<ContextType>;
  GetCreatorTagsByIdPayload?: GetCreatorTagsByIdPayloadResolvers<ContextType>;
  GetCreatorTagsPayload?: GetCreatorTagsPayloadResolvers<ContextType>;
  GetCustomerTagsPayload?: GetCustomerTagsPayloadResolvers<ContextType>;
  GetEmailListCountPayload?: GetEmailListCountPayloadResolvers<ContextType>;
  GetEmailListPayload?: GetEmailListPayloadResolvers<ContextType>;
  GetEmailOverviewPayload?: GetEmailOverviewPayloadResolvers<ContextType>;
  GetEmailRecipientFan?: GetEmailRecipientFanResolvers<ContextType>;
  GetEmailRecipientTag?: GetEmailRecipientTagResolvers<ContextType>;
  GetEmailRecipientsCountPayload?: GetEmailRecipientsCountPayloadResolvers<ContextType>;
  GetEmailRecipientsPayload?: GetEmailRecipientsPayloadResolvers<ContextType>;
  GetEmailTemplatePayload?: GetEmailTemplatePayloadResolvers<ContextType>;
  GetFanActivitiesPayload?: GetFanActivitiesPayloadResolvers<ContextType>;
  GetFanFormResponsePayload?: GetFanFormResponsePayloadResolvers<ContextType>;
  GetFanHeaderPayload?: GetFanHeaderPayloadResolvers<ContextType>;
  GetFanOrdersPayload?: GetFanOrdersPayloadResolvers<ContextType>;
  GetFanParticipationFormListPayload?: GetFanParticipationFormListPayloadResolvers<ContextType>;
  GetFanPayload?: GetFanPayloadResolvers<ContextType>;
  GetFansCountPayload?: GetFansCountPayloadResolvers<ContextType>;
  GetFansPayload?: GetFansPayloadResolvers<ContextType>;
  GetFormInputPayload?: GetFormInputPayloadResolvers<ContextType>;
  GetFormQuestion?: GetFormQuestionResolvers<ContextType>;
  GetFormQuestionOption?: GetFormQuestionOptionResolvers<ContextType>;
  GetFormResponsePayload?: GetFormResponsePayloadResolvers<ContextType>;
  GetFormTemplateOption?: GetFormTemplateOptionResolvers<ContextType>;
  GetFormTemplatePayload?: GetFormTemplatePayloadResolvers<ContextType>;
  GetFormTemplateQuestion?: GetFormTemplateQuestionResolvers<ContextType>;
  GetFormsCountPayload?: GetFormsCountPayloadResolvers<ContextType>;
  GetFormsListPayload?: GetFormsListPayloadResolvers<ContextType>;
  GetGeneratedMarketplaceCampaignDetailPayload?: GetGeneratedMarketplaceCampaignDetailPayloadResolvers<ContextType>;
  GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail?: GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetailResolvers<ContextType>;
  GetGeneratedMarketplacePostCaptionPayload?: GetGeneratedMarketplacePostCaptionPayloadResolvers<ContextType>;
  GetGeneratedTikTokSpecialPostCaptionPayload?: GetGeneratedTikTokSpecialPostCaptionPayloadResolvers<ContextType>;
  GetInfluencerBioAnalyticsPayload?: GetInfluencerBioAnalyticsPayloadResolvers<ContextType>;
  GetInfluencerBioPayload?: GetInfluencerBioPayloadResolvers<ContextType>;
  GetInfluencerBioPayloadV2?: GetInfluencerBioPayloadV2Resolvers<ContextType>;
  GetInfluencerChatServicesPayload?: GetInfluencerChatServicesPayloadResolvers<ContextType>;
  GetLineBroadcastEstimateRecipientsPayload?: GetLineBroadcastEstimateRecipientsPayloadResolvers<ContextType>;
  GetLineBroadcastMessagePayload?: GetLineBroadcastMessagePayloadResolvers<ContextType>;
  GetLineBroadcastSummaryPayload?: GetLineBroadcastSummaryPayloadResolvers<ContextType>;
  GetLineBroadcastsCountPayload?: GetLineBroadcastsCountPayloadResolvers<ContextType>;
  GetLineBroadcastsPayload?: GetLineBroadcastsPayloadResolvers<ContextType>;
  GetLineChannelPayload?: GetLineChannelPayloadResolvers<ContextType>;
  GetLineChatCountPayload?: GetLineChatCountPayloadResolvers<ContextType>;
  GetLineChatPayload?: GetLineChatPayloadResolvers<ContextType>;
  GetLineChatsPayload?: GetLineChatsPayloadResolvers<ContextType>;
  GetLineLoginDetailPayload?: GetLineLoginDetailPayloadResolvers<ContextType>;
  GetLinePublicKeyPayload?: GetLinePublicKeyPayloadResolvers<ContextType>;
  GetLineServicePayload?: GetLineServicePayloadResolvers<ContextType>;
  GetLinkBioFacebookAccountPostsPayload?: GetLinkBioFacebookAccountPostsPayloadResolvers<ContextType>;
  GetLinkBioFacebookPagePostsPayload?: GetLinkBioFacebookPagePostsPayloadResolvers<ContextType>;
  GetLinkBioFacebookPostPayload?: GetLinkBioFacebookPostPayloadResolvers<ContextType>;
  GetLinkBioThemeColorPayload?: GetLinkBioThemeColorPayloadResolvers<ContextType>;
  GetMessageTemplatesPayload?: GetMessageTemplatesPayloadResolvers<ContextType>;
  GetPublicInfluencerBioPayload?: GetPublicInfluencerBioPayloadResolvers<ContextType>;
  GetShopifyAccountPayload?: GetShopifyAccountPayloadResolvers<ContextType>;
  GetTagsAndFansRecipientsPayload?: GetTagsAndFansRecipientsPayloadResolvers<ContextType>;
  GoogleSlideFile?: GoogleSlideFileResolvers<ContextType>;
  Hashtag?: HashtagResolvers<ContextType>;
  HashtagType?: HashtagTypeResolvers<ContextType>;
  HubspotCompanyData?: HubspotCompanyDataResolvers<ContextType>;
  HubspotDealData?: HubspotDealDataResolvers<ContextType>;
  HubspotDealDataForSearch?: HubspotDealDataForSearchResolvers<ContextType>;
  ImageItemPayload?: ImageItemPayloadResolvers<ContextType>;
  ImageMessagePayload?: ImageMessagePayloadResolvers<ContextType>;
  ImagePayload?: ImagePayloadResolvers<ContextType>;
  ImageVideoMessagePayload?: ImageVideoMessagePayloadResolvers<ContextType>;
  ImportFansPayload?: ImportFansPayloadResolvers<ContextType>;
  Influencer?: InfluencerResolvers<ContextType>;
  InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload?: InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayloadResolvers<ContextType>;
  InfluencerAnalyticsSponsoredPostCountPayload?: InfluencerAnalyticsSponsoredPostCountPayloadResolvers<ContextType>;
  InfluencerAnalyticsSponsoredPostPayload?: InfluencerAnalyticsSponsoredPostPayloadResolvers<ContextType>;
  InfluencerAnalyticsTwitterRefreshAvailabilityPayload?: InfluencerAnalyticsTwitterRefreshAvailabilityPayloadResolvers<ContextType>;
  InfluencerAnalyticsTwitterRefreshOutput?: InfluencerAnalyticsTwitterRefreshOutputResolvers<ContextType>;
  InfluencerAudienceSection?: InfluencerAudienceSectionResolvers<ContextType>;
  InfluencerBioAnalyticsAudience?: InfluencerBioAnalyticsAudienceResolvers<ContextType>;
  InfluencerBioAnalyticsHistory?: InfluencerBioAnalyticsHistoryResolvers<ContextType>;
  InfluencerBioAnalyticsOverview?: InfluencerBioAnalyticsOverviewResolvers<ContextType>;
  InfluencerBrandAccounts?: InfluencerBrandAccountsResolvers<ContextType>;
  InfluencerCampaignDetail?: InfluencerCampaignDetailResolvers<ContextType>;
  InfluencerCampaignDetailByPromotion?: InfluencerCampaignDetailByPromotionResolvers<ContextType>;
  InfluencerCampaignDetailInfo?: InfluencerCampaignDetailInfoResolvers<ContextType>;
  InfluencerCampaignDetailInfoByPromotion?: InfluencerCampaignDetailInfoByPromotionResolvers<ContextType>;
  InfluencerCampaignPostData?: InfluencerCampaignPostDataResolvers<ContextType>;
  InfluencerCampaignPostInfo?: InfluencerCampaignPostInfoResolvers<ContextType>;
  InfluencerCampaignStat?: InfluencerCampaignStatResolvers<ContextType>;
  InfluencerCampaignStatByPromotion?: InfluencerCampaignStatByPromotionResolvers<ContextType>;
  InfluencerCampaignSummary?: InfluencerCampaignSummaryResolvers<ContextType>;
  InfluencerEngagement?: InfluencerEngagementResolvers<ContextType>;
  InfluencerExportDashboardInstagramAccount?: InfluencerExportDashboardInstagramAccountResolvers<ContextType>;
  InfluencerFacebookPageAnalyticsAccessTokenWarningPayload?: InfluencerFacebookPageAnalyticsAccessTokenWarningPayloadResolvers<ContextType>;
  InfluencerFeedPost?: InfluencerFeedPostResolvers<ContextType>;
  InfluencerFollowerGrowthSection?: InfluencerFollowerGrowthSectionResolvers<ContextType>;
  InfluencerHasEstimateApi?: InfluencerHasEstimateApiResolvers<ContextType>;
  InfluencerInstagramAnalyticsAccessTokenWarningPayload?: InfluencerInstagramAnalyticsAccessTokenWarningPayloadResolvers<ContextType>;
  InfluencerInstagramDataForReconnectPayload?: InfluencerInstagramDataForReconnectPayloadResolvers<ContextType>;
  InfluencerInterest?: InfluencerInterestResolvers<ContextType>;
  InfluencerManagerOutput?: InfluencerManagerOutputResolvers<ContextType>;
  InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload?: InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayloadResolvers<ContextType>;
  InfluencerMultipleInstagramDataForReconnectPayload?: InfluencerMultipleInstagramDataForReconnectPayloadResolvers<ContextType>;
  InfluencerNewProfileDetail?: InfluencerNewProfileDetailResolvers<ContextType>;
  InfluencerNewProfileEngagement?: InfluencerNewProfileEngagementResolvers<ContextType>;
  InfluencerNewProfileFollowers?: InfluencerNewProfileFollowersResolvers<ContextType>;
  InfluencerNewProfileHeaderV2?: InfluencerNewProfileHeaderV2Resolvers<ContextType>;
  InfluencerNewProfilePostsHabit?: InfluencerNewProfilePostsHabitResolvers<ContextType>;
  InfluencerNewProfilePostsStats?: InfluencerNewProfilePostsStatsResolvers<ContextType>;
  InfluencerNewProfileSentimentAnalysis?: InfluencerNewProfileSentimentAnalysisResolvers<ContextType>;
  InfluencerPaymentInformation?: InfluencerPaymentInformationResolvers<ContextType>;
  InfluencerPaymentRequest?: InfluencerPaymentRequestResolvers<ContextType>;
  InfluencerPaymentRequests?: InfluencerPaymentRequestsResolvers<ContextType>;
  InfluencerPopularPost?: InfluencerPopularPostResolvers<ContextType>;
  InfluencerPostDetail?: InfluencerPostDetailResolvers<ContextType>;
  InfluencerPostReportStatsEngagement?: InfluencerPostReportStatsEngagementResolvers<ContextType>;
  InfluencerPostReportStatsMarketplace?: InfluencerPostReportStatsMarketplaceResolvers<ContextType>;
  InfluencerPostReportStatsTikTokSpecial?: InfluencerPostReportStatsTikTokSpecialResolvers<ContextType>;
  InfluencerPostSection?: InfluencerPostSectionResolvers<ContextType>;
  InfluencerPostV3?: InfluencerPostV3Resolvers<ContextType>;
  InfluencerPostsV3?: InfluencerPostsV3Resolvers<ContextType>;
  InfluencerProfileDetailForEngagementProposal?: InfluencerProfileDetailForEngagementProposalResolvers<ContextType>;
  InfluencerProfileForEngagementProposal?: InfluencerProfileForEngagementProposalResolvers<ContextType>;
  InfluencerPromotionMethodsPayload?: InfluencerPromotionMethodsPayloadResolvers<ContextType>;
  InfluencerRateCardData?: InfluencerRateCardDataResolvers<ContextType>;
  InfluencerRateCardDetailData?: InfluencerRateCardDetailDataResolvers<ContextType>;
  InfluencerRateCardPayload?: InfluencerRateCardPayloadResolvers<ContextType>;
  InfluencerReelPost?: InfluencerReelPostResolvers<ContextType>;
  InfluencerSignUpInputPayload?: InfluencerSignUpInputPayloadResolvers<ContextType>;
  InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload?: InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayloadResolvers<ContextType>;
  InfluencerSocialNetworkAccountOutput?: InfluencerSocialNetworkAccountOutputResolvers<ContextType>;
  InfluencerSocialPost?: InfluencerSocialPostResolvers<ContextType>;
  InfluencerSponsoredPost?: InfluencerSponsoredPostResolvers<ContextType>;
  InfluencerSponsoredPostCountResponse?: InfluencerSponsoredPostCountResponseResolvers<ContextType>;
  InfluencerSponsoredPostResponse?: InfluencerSponsoredPostResponseResolvers<ContextType>;
  InfluencerTwitterAnalyticsAccessTokenWarningPayload?: InfluencerTwitterAnalyticsAccessTokenWarningPayloadResolvers<ContextType>;
  InfluencerUpdateDetailV2?: InfluencerUpdateDetailV2Resolvers<ContextType>;
  InfluencerV4?: InfluencerV4Resolvers<ContextType>;
  InfluencerWhitelist?: InfluencerWhitelistResolvers<ContextType>;
  InfluencerWordCloud?: InfluencerWordCloudResolvers<ContextType>;
  InfluencerYoutubeAnalyticsAccountCard?: InfluencerYoutubeAnalyticsAccountCardResolvers<ContextType>;
  InfluencerYoutubeAnalyticsAccountPosts?: InfluencerYoutubeAnalyticsAccountPostsResolvers<ContextType>;
  InfluencerYoutubeAnalyticsAccountPostsInDate?: InfluencerYoutubeAnalyticsAccountPostsInDateResolvers<ContextType>;
  InfluencerYoutubeAnalyticsAccountTagRanking?: InfluencerYoutubeAnalyticsAccountTagRankingResolvers<ContextType>;
  InfluencerYoutubeAnalyticsChannelDetailsPayload?: InfluencerYoutubeAnalyticsChannelDetailsPayloadResolvers<ContextType>;
  InfluencerYoutubeAnalyticsCompareAccountCard?: InfluencerYoutubeAnalyticsCompareAccountCardResolvers<ContextType>;
  InfluencerYoutubeAnalyticsCompareAccountCards?: InfluencerYoutubeAnalyticsCompareAccountCardsResolvers<ContextType>;
  InfluencerYoutubeCompareAccountOverview?: InfluencerYoutubeCompareAccountOverviewResolvers<ContextType>;
  InfluencerYoutubeCompareAllPostsInDate?: InfluencerYoutubeCompareAllPostsInDateResolvers<ContextType>;
  InfluencerYoutubeCompareOverview?: InfluencerYoutubeCompareOverviewResolvers<ContextType>;
  InfluencerYoutubeComparePosts?: InfluencerYoutubeComparePostsResolvers<ContextType>;
  InfluencerYoutubeCompareTagRanking?: InfluencerYoutubeCompareTagRankingResolvers<ContextType>;
  InfluencerYoutubeSponsoredPost?: InfluencerYoutubeSponsoredPostResolvers<ContextType>;
  InquiryEvent?: InquiryEventResolvers<ContextType>;
  InquiryFinishEvent?: InquiryFinishEventResolvers<ContextType>;
  InstagramAccountForProfileV2?: InstagramAccountForProfileV2Resolvers<ContextType>;
  InstagramAnalyticsAccountInfoHeader?: InstagramAnalyticsAccountInfoHeaderResolvers<ContextType>;
  InstagramAnalyticsComments?: InstagramAnalyticsCommentsResolvers<ContextType>;
  InstagramAnalyticsCompareAccount?: InstagramAnalyticsCompareAccountResolvers<ContextType>;
  InstagramAnalyticsCompareAccounts?: InstagramAnalyticsCompareAccountsResolvers<ContextType>;
  InstagramAnalyticsComparePostsInDate?: InstagramAnalyticsComparePostsInDateResolvers<ContextType>;
  InstagramAnalyticsGetCommentItemsPayload?: InstagramAnalyticsGetCommentItemsPayloadResolvers<ContextType>;
  InstagramAnalyticsGetCommentsPayload?: InstagramAnalyticsGetCommentsPayloadResolvers<ContextType>;
  InstagramAnalyticsHashtag?: InstagramAnalyticsHashtagResolvers<ContextType>;
  InstagramAnalyticsHashtags?: InstagramAnalyticsHashtagsResolvers<ContextType>;
  InstagramAnalyticsHistoryDataPoint?: InstagramAnalyticsHistoryDataPointResolvers<ContextType>;
  InstagramAnalyticsHistoryEngagementRateDataPoint?: InstagramAnalyticsHistoryEngagementRateDataPointResolvers<ContextType>;
  InstagramAnalyticsOverviewData?: InstagramAnalyticsOverviewDataResolvers<ContextType>;
  InstagramAnalyticsOverviewEngagementRateData?: InstagramAnalyticsOverviewEngagementRateDataResolvers<ContextType>;
  InstagramAnalyticsPostAnalysisDetails?: InstagramAnalyticsPostAnalysisDetailsResolvers<ContextType>;
  InstagramAnalyticsPostCommentDetails?: InstagramAnalyticsPostCommentDetailsResolvers<ContextType>;
  InstagramAnalyticsPostDetails?: InstagramAnalyticsPostDetailsResolvers<ContextType>;
  InstagramAnalyticsPostSentimentDetails?: InstagramAnalyticsPostSentimentDetailsResolvers<ContextType>;
  InstagramAnalyticsPostsInDate?: InstagramAnalyticsPostsInDateResolvers<ContextType>;
  InstagramAnalyticsReelAnalysisDetails?: InstagramAnalyticsReelAnalysisDetailsResolvers<ContextType>;
  InstagramAnalyticsReelCommentDetails?: InstagramAnalyticsReelCommentDetailsResolvers<ContextType>;
  InstagramAnalyticsReelDetails?: InstagramAnalyticsReelDetailsResolvers<ContextType>;
  InstagramAnalyticsStoryDetails?: InstagramAnalyticsStoryDetailsResolvers<ContextType>;
  InstagramAnalyticsSummaryPostsInDate?: InstagramAnalyticsSummaryPostsInDateResolvers<ContextType>;
  InstagramBrandAmbassadorPost?: InstagramBrandAmbassadorPostResolvers<ContextType>;
  InstagramBrandAmbassadorPostDetails?: InstagramBrandAmbassadorPostDetailsResolvers<ContextType>;
  InstagramBrandAmbassadorPosts?: InstagramBrandAmbassadorPostsResolvers<ContextType>;
  InstagramBrandAmbassadorPostsByUsername?: InstagramBrandAmbassadorPostsByUsernameResolvers<ContextType>;
  InstagramCommentAnalytics?: InstagramCommentAnalyticsResolvers<ContextType>;
  InstagramCompareAccount?: InstagramCompareAccountResolvers<ContextType>;
  InstagramCompareAccountPost?: InstagramCompareAccountPostResolvers<ContextType>;
  InstagramCompareAccountsPayload?: InstagramCompareAccountsPayloadResolvers<ContextType>;
  InstagramCompareFeedPost?: InstagramCompareFeedPostResolvers<ContextType>;
  InstagramCompareFollowers?: InstagramCompareFollowersResolvers<ContextType>;
  InstagramCompareFollowersPrimaryAccount?: InstagramCompareFollowersPrimaryAccountResolvers<ContextType>;
  InstagramCompareFollowersSecondaryAccount?: InstagramCompareFollowersSecondaryAccountResolvers<ContextType>;
  InstagramCompareOverview?: InstagramCompareOverviewResolvers<ContextType>;
  InstagramCompareOverviewHistory?: InstagramCompareOverviewHistoryResolvers<ContextType>;
  InstagramCompareOverviewSummary?: InstagramCompareOverviewSummaryResolvers<ContextType>;
  InstagramCompareOverviewSummaryAverageData?: InstagramCompareOverviewSummaryAverageDataResolvers<ContextType>;
  InstagramCompareOverviewSummaryData?: InstagramCompareOverviewSummaryDataResolvers<ContextType>;
  InstagramComparePostAnalysisDetails?: InstagramComparePostAnalysisDetailsResolvers<ContextType>;
  InstagramComparePostDetails?: InstagramComparePostDetailsResolvers<ContextType>;
  InstagramComparePosts?: InstagramComparePostsResolvers<ContextType>;
  InstagramComparePostsData?: InstagramComparePostsDataResolvers<ContextType>;
  InstagramComparePrimaryAccount?: InstagramComparePrimaryAccountResolvers<ContextType>;
  InstagramCompareSecondaryAccount?: InstagramCompareSecondaryAccountResolvers<ContextType>;
  InstagramDashboardFollowerAnalytics?: InstagramDashboardFollowerAnalyticsResolvers<ContextType>;
  InstagramDashboardOverview?: InstagramDashboardOverviewResolvers<ContextType>;
  InstagramDashboardPostAnalytics?: InstagramDashboardPostAnalyticsResolvers<ContextType>;
  InstagramDataForReconnectPayload?: InstagramDataForReconnectPayloadResolvers<ContextType>;
  InstagramFeedPost?: InstagramFeedPostResolvers<ContextType>;
  InstagramFollowerGenderRate?: InstagramFollowerGenderRateResolvers<ContextType>;
  InstagramFollowersAgeGroup?: InstagramFollowersAgeGroupResolvers<ContextType>;
  InstagramFollowersAgeRate?: InstagramFollowersAgeRateResolvers<ContextType>;
  InstagramFollowersCountryRate?: InstagramFollowersCountryRateResolvers<ContextType>;
  InstagramFollowersRegionRate?: InstagramFollowersRegionRateResolvers<ContextType>;
  InstagramHashtagFeedPost?: InstagramHashtagFeedPostResolvers<ContextType>;
  InstagramHashtagFeedPostV2?: InstagramHashtagFeedPostV2Resolvers<ContextType>;
  InstagramHashtagFeedPostsPayload?: InstagramHashtagFeedPostsPayloadResolvers<ContextType>;
  InstagramHashtagPostAnalysisDetails?: InstagramHashtagPostAnalysisDetailsResolvers<ContextType>;
  InstagramHashtagPostDetails?: InstagramHashtagPostDetailsResolvers<ContextType>;
  InstagramHashtagPosts?: InstagramHashtagPostsResolvers<ContextType>;
  InstagramHashtagPostsData?: InstagramHashtagPostsDataResolvers<ContextType>;
  InstagramHashtagPostsPayload?: InstagramHashtagPostsPayloadResolvers<ContextType>;
  InstagramHashtagsMetricsData?: InstagramHashtagsMetricsDataResolvers<ContextType>;
  InstagramHashtagsOverview?: InstagramHashtagsOverviewResolvers<ContextType>;
  InstagramHashtagsOverviewHistory?: InstagramHashtagsOverviewHistoryResolvers<ContextType>;
  InstagramHashtagsOverviewSummary?: InstagramHashtagsOverviewSummaryResolvers<ContextType>;
  InstagramHashtagsOverviewSummaryData?: InstagramHashtagsOverviewSummaryDataResolvers<ContextType>;
  InstagramInfluencerSearchResultV3?: InstagramInfluencerSearchResultV3Resolvers<ContextType>;
  InstagramInfluencerV3?: InstagramInfluencerV3Resolvers<ContextType>;
  InstagramInteractionCommonPostData?: InstagramInteractionCommonPostDataResolvers<ContextType>;
  InstagramInteractionCommonTaggedPostData?: InstagramInteractionCommonTaggedPostDataResolvers<ContextType>;
  InstagramInteractionOverview?: InstagramInteractionOverviewResolvers<ContextType>;
  InstagramInteractionOverviewHistory?: InstagramInteractionOverviewHistoryResolvers<ContextType>;
  InstagramInteractionOverviewSummary?: InstagramInteractionOverviewSummaryResolvers<ContextType>;
  InstagramInteractionOverviewSummaryData?: InstagramInteractionOverviewSummaryDataResolvers<ContextType>;
  InstagramInteractionPost?: InstagramInteractionPostResolvers<ContextType>;
  InstagramInteractionPostDetails?: InstagramInteractionPostDetailsResolvers<ContextType>;
  InstagramInteractionPosts?: InstagramInteractionPostsResolvers<ContextType>;
  InstagramInteractionTaggedPost?: InstagramInteractionTaggedPostResolvers<ContextType>;
  InstagramInteractionUserAnalytics?: InstagramInteractionUserAnalyticsResolvers<ContextType>;
  InstagramInteractionUserAnalyticsDetails?: InstagramInteractionUserAnalyticsDetailsResolvers<ContextType>;
  InstagramInteractionUserAnalyticsDetailsAndCount?: InstagramInteractionUserAnalyticsDetailsAndCountResolvers<ContextType>;
  InstagramPopularHashtags?: InstagramPopularHashtagsResolvers<ContextType>;
  InstagramPostAverageEngagement?: InstagramPostAverageEngagementResolvers<ContextType>;
  InstagramPostsHabit?: InstagramPostsHabitResolvers<ContextType>;
  InstagramReelPost?: InstagramReelPostResolvers<ContextType>;
  InstagramReelPostItem?: InstagramReelPostItemResolvers<ContextType>;
  InstagramStoryPost?: InstagramStoryPostResolvers<ContextType>;
  InstagramUGCAddedHashtagPayload?: InstagramUGCAddedHashtagPayloadResolvers<ContextType>;
  InstagramUGCManagementBasicInfoPayload?: InstagramUGCManagementBasicInfoPayloadResolvers<ContextType>;
  InstagramUGCManagementListPostsCountPayload?: InstagramUGCManagementListPostsCountPayloadResolvers<ContextType>;
  InstagramUGCManagementListPostsItemPayload?: InstagramUGCManagementListPostsItemPayloadResolvers<ContextType>;
  InstagramUGCManagementListPostsPayload?: InstagramUGCManagementListPostsPayloadResolvers<ContextType>;
  InstagramUGCManagementPostDetailsPayload?: InstagramUGCManagementPostDetailsPayloadResolvers<ContextType>;
  InstagramUserHashtag?: InstagramUserHashtagResolvers<ContextType>;
  InstagramUserHashtagListPostsItemPayload?: InstagramUserHashtagListPostsItemPayloadResolvers<ContextType>;
  InstagramUserHashtagListPostsPayload?: InstagramUserHashtagListPostsPayloadResolvers<ContextType>;
  InstagramUserHashtagPayload?: InstagramUserHashtagPayloadResolvers<ContextType>;
  IsInfluencerUUUM?: IsInfluencerUUUMResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JoinAutoManagedCampaignPayload?: JoinAutoManagedCampaignPayloadResolvers<ContextType>;
  JoinMarketplaceByStaff?: JoinMarketplaceByStaffResolvers<ContextType>;
  JoinMarketplaceV2?: JoinMarketplaceV2Resolvers<ContextType>;
  JoinTiktokSpecialCampaign?: JoinTiktokSpecialCampaignResolvers<ContextType>;
  JoinableAccount?: JoinableAccountResolvers<ContextType>;
  JoinableFbAccount?: JoinableFbAccountResolvers<ContextType>;
  JoinableInstagramAccount?: JoinableInstagramAccountResolvers<ContextType>;
  JoinablePromotionMethodEmailNewsLetterPayload?: JoinablePromotionMethodEmailNewsLetterPayloadResolvers<ContextType>;
  JoinablePromotionMethodMobileAppPayload?: JoinablePromotionMethodMobileAppPayloadResolvers<ContextType>;
  JoinablePromotionMethodOfflinePayload?: JoinablePromotionMethodOfflinePayloadResolvers<ContextType>;
  JoinablePromotionMethodPodCastPayload?: JoinablePromotionMethodPodCastPayloadResolvers<ContextType>;
  JoinablePromotionMethodSocialAccountPayload?: JoinablePromotionMethodSocialAccountPayloadResolvers<ContextType>;
  JoinablePromotionMethodWebsitePayload?: JoinablePromotionMethodWebsitePayloadResolvers<ContextType>;
  JoinedCampaign?: JoinedCampaignResolvers<ContextType>;
  JoinedPromotionMethodEmailNewsLetterPayload?: JoinedPromotionMethodEmailNewsLetterPayloadResolvers<ContextType>;
  JoinedPromotionMethodMobileAppPayload?: JoinedPromotionMethodMobileAppPayloadResolvers<ContextType>;
  JoinedPromotionMethodOfflinePayload?: JoinedPromotionMethodOfflinePayloadResolvers<ContextType>;
  JoinedPromotionMethodPodCastPayload?: JoinedPromotionMethodPodCastPayloadResolvers<ContextType>;
  JoinedPromotionMethodSocialAccountPayload?: JoinedPromotionMethodSocialAccountPayloadResolvers<ContextType>;
  JoinedPromotionMethodWebsitePayload?: JoinedPromotionMethodWebsitePayloadResolvers<ContextType>;
  LineBroadcast?: LineBroadcastResolvers<ContextType>;
  LineBroadcastFloatValue?: LineBroadcastFloatValueResolvers<ContextType>;
  LineBroadcastIntValue?: LineBroadcastIntValueResolvers<ContextType>;
  LineChat?: LineChatResolvers<ContextType>;
  LineChatPIC?: LineChatPICResolvers<ContextType>;
  LineContentItemPayload?: LineContentItemPayloadResolvers<ContextType>;
  LinkBioBackground?: LinkBioBackgroundResolvers<ContextType>;
  LinkBioPageColor?: LinkBioPageColorResolvers<ContextType>;
  LinkCmsChannelAssets?: LinkCmsChannelAssetsResolvers<ContextType>;
  Logo?: LogoResolvers<ContextType>;
  Long?: GraphQLScalarType;
  MadeMarketplaceCampaignPost?: MadeMarketplaceCampaignPostResolvers<ContextType>;
  MajorCategory?: MajorCategoryResolvers<ContextType>;
  MarkReadNotificationPayload?: MarkReadNotificationPayloadResolvers<ContextType>;
  Marketplace?: MarketplaceResolvers<ContextType>;
  MarketplaceAffiliateCommissionInfluencer?: MarketplaceAffiliateCommissionInfluencerResolvers<ContextType>;
  MarketplaceAffiliateJoinedInfluencer?: MarketplaceAffiliateJoinedInfluencerResolvers<ContextType>;
  MarketplaceAffiliateReportByDate?: MarketplaceAffiliateReportByDateResolvers<ContextType>;
  MarketplaceAffiliateReportByInfluencer?: MarketplaceAffiliateReportByInfluencerResolvers<ContextType>;
  MarketplaceAffiliateReportForInfluencer?: MarketplaceAffiliateReportForInfluencerResolvers<ContextType>;
  MarketplaceAffiliateReportSummary?: MarketplaceAffiliateReportSummaryResolvers<ContextType>;
  MarketplaceAffiliateReportSummaryForInfluencer?: MarketplaceAffiliateReportSummaryForInfluencerResolvers<ContextType>;
  MarketplaceAffiliateStatsItem?: MarketplaceAffiliateStatsItemResolvers<ContextType>;
  MarketplaceCampaignCouponTrackingPayload?: MarketplaceCampaignCouponTrackingPayloadResolvers<ContextType>;
  MarketplaceCampaignPostReportForInfluencer?: MarketplaceCampaignPostReportForInfluencerResolvers<ContextType>;
  MarketplaceCampaignReport?: MarketplaceCampaignReportResolvers<ContextType>;
  MarketplaceDetail?: MarketplaceDetailResolvers<ContextType>;
  MarketplaceDetailForInfluencer?: MarketplaceDetailForInfluencerResolvers<ContextType>;
  MarketplaceDraftPostDetail?: MarketplaceDraftPostDetailResolvers<ContextType>;
  MarketplaceDraftPostForInfluencer?: MarketplaceDraftPostForInfluencerResolvers<ContextType>;
  MarketplaceForReorder?: MarketplaceForReorderResolvers<ContextType>;
  MarketplaceInfluencer?: MarketplaceInfluencerResolvers<ContextType>;
  MarketplaceJoinableAccountsPayload?: MarketplaceJoinableAccountsPayloadResolvers<ContextType>;
  MarketplaceJoinablePromotionMethodsPayload?: MarketplaceJoinablePromotionMethodsPayloadResolvers<ContextType>;
  MarketplaceJoinedAccountPayload?: MarketplaceJoinedAccountPayloadResolvers<ContextType>;
  MarketplaceJoinedMethodsPayload?: MarketplaceJoinedMethodsPayloadResolvers<ContextType>;
  MarketplaceJoinedMethodsPayloadV2?: MarketplaceJoinedMethodsPayloadV2Resolvers<ContextType>;
  MarketplacePost?: MarketplacePostResolvers<ContextType>;
  MarketplacePostComment?: MarketplacePostCommentResolvers<ContextType>;
  MarketplacePostDetail?: MarketplacePostDetailResolvers<ContextType>;
  MarketplacePostDetailInfluencer?: MarketplacePostDetailInfluencerResolvers<ContextType>;
  MarketplacePostHistory?: MarketplacePostHistoryResolvers<ContextType>;
  MarketplacePostInfluencer?: MarketplacePostInfluencerResolvers<ContextType>;
  MarketplacePostNoRevenue?: MarketplacePostNoRevenueResolvers<ContextType>;
  MarketplacePostsNoRevenue?: MarketplacePostsNoRevenueResolvers<ContextType>;
  MarketplaceRejectedAdvertiserInfo?: MarketplaceRejectedAdvertiserInfoResolvers<ContextType>;
  MarketplaceRevenueItem?: MarketplaceRevenueItemResolvers<ContextType>;
  MarketplaceTracking?: MarketplaceTrackingResolvers<ContextType>;
  MarketplaceTrackingPostUrlForInfluencer?: MarketplaceTrackingPostUrlForInfluencerResolvers<ContextType>;
  MaterialName?: MaterialNameResolvers<ContextType>;
  MediaItemPayload?: MediaItemPayloadResolvers<ContextType>;
  MessageTemplateEvent?: MessageTemplateEventResolvers<ContextType>;
  MessageTemplateItem?: MessageTemplateItemResolvers<ContextType>;
  MinorCategory?: MinorCategoryResolvers<ContextType>;
  MobileVersion?: MobileVersionResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NeedReconnectForFacebookPayload?: NeedReconnectForFacebookPayloadResolvers<ContextType>;
  NeedReviewMarketplacePost?: NeedReviewMarketplacePostResolvers<ContextType>;
  NetSuiteCustomerData?: NetSuiteCustomerDataResolvers<ContextType>;
  NetSuiteCustomerDataForSearch?: NetSuiteCustomerDataForSearchResolvers<ContextType>;
  NetSuiteVendorData?: NetSuiteVendorDataResolvers<ContextType>;
  NetSuiteVendorDataForSearch?: NetSuiteVendorDataForSearchResolvers<ContextType>;
  NewAutoManagedCampaignForInfluencerForYourJob?: NewAutoManagedCampaignForInfluencerForYourJobResolvers<ContextType>;
  NewCampaignPostReport?: NewCampaignPostReportResolvers<ContextType>;
  NewCampaignReportSummary?: NewCampaignReportSummaryResolvers<ContextType>;
  NewCampaignStatsItemRow?: NewCampaignStatsItemRowResolvers<ContextType>;
  NewEngagementCampaignForInfluencerForYourJob?: NewEngagementCampaignForInfluencerForYourJobResolvers<ContextType>;
  NewMarketplaceCampaignForInfluencerForYourJob?: NewMarketplaceCampaignForInfluencerForYourJobResolvers<ContextType>;
  NewTiktokSpecialCampaignForInfluencerForYourJob?: NewTiktokSpecialCampaignForInfluencerForYourJobResolvers<ContextType>;
  NotificationPayload?: NotificationPayloadResolvers<ContextType>;
  NotificationsPayload?: NotificationsPayloadResolvers<ContextType>;
  ObtainJSONWebTokenPayload?: ObtainJSONWebTokenPayloadResolvers<ContextType>;
  OpenAIMessage?: OpenAIMessageResolvers<ContextType>;
  OperatedUserInfo?: OperatedUserInfoResolvers<ContextType>;
  PackageCompany?: PackageCompanyResolvers<ContextType>;
  PackageInfluencer?: PackageInfluencerResolvers<ContextType>;
  PackageProposal?: PackageProposalResolvers<ContextType>;
  PackageProposalSummary?: PackageProposalSummaryResolvers<ContextType>;
  PackageSocialAccount?: PackageSocialAccountResolvers<ContextType>;
  PackageSummary?: PackageSummaryResolvers<ContextType>;
  PackageSummaryV2?: PackageSummaryV2Resolvers<ContextType>;
  ParticipationForm?: ParticipationFormResolvers<ContextType>;
  Partner?: PartnerResolvers<ContextType>;
  PartnerAgencyUserWithRole?: PartnerAgencyUserWithRoleResolvers<ContextType>;
  PartnerAgencyWithCompanies?: PartnerAgencyWithCompaniesResolvers<ContextType>;
  PartnerAgencyWithNSVerification?: PartnerAgencyWithNSVerificationResolvers<ContextType>;
  PartnerAgencyWithPermission?: PartnerAgencyWithPermissionResolvers<ContextType>;
  PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload?: PartnerReconnectAnalyticsSocialAuthRedirectUrlPayloadResolvers<ContextType>;
  PayableCampaign?: PayableCampaignResolvers<ContextType>;
  Payment?: PaymentResolvers<ContextType>;
  PaymentInfluencerAccount?: PaymentInfluencerAccountResolvers<ContextType>;
  PaymentInformation?: PaymentInformationResolvers<ContextType>;
  PaymentInformationDetails?: PaymentInformationDetailsResolvers<ContextType>;
  PaymentPackageStatusResponse?: PaymentPackageStatusResponseResolvers<ContextType>;
  PaymentRequest?: PaymentRequestResolvers<ContextType>;
  PersonalUserDetailPayload?: PersonalUserDetailPayloadResolvers<ContextType>;
  PicName?: PicNameResolvers<ContextType>;
  PicUser?: PicUserResolvers<ContextType>;
  PlainTextMessagePayload?: PlainTextMessagePayloadResolvers<ContextType>;
  PostByHashtag?: PostByHashtagResolvers<ContextType>;
  PostsByTime?: PostsByTimeResolvers<ContextType>;
  PostsForHashtagDashboard?: PostsForHashtagDashboardResolvers<ContextType>;
  PostsMetricsGridReport?: PostsMetricsGridReportResolvers<ContextType>;
  PricingTable?: PricingTableResolvers<ContextType>;
  PricingTableItem?: PricingTableItemResolvers<ContextType>;
  PrivatePartnerInfluencer?: PrivatePartnerInfluencerResolvers<ContextType>;
  PrivateTalentInfluencer?: PrivateTalentInfluencerResolvers<ContextType>;
  PromotionMethodEmailNewsLetterForProfile?: PromotionMethodEmailNewsLetterForProfileResolvers<ContextType>;
  PromotionMethodEmailNewsLetterPayload?: PromotionMethodEmailNewsLetterPayloadResolvers<ContextType>;
  PromotionMethodMobileAppForProfile?: PromotionMethodMobileAppForProfileResolvers<ContextType>;
  PromotionMethodMobileAppPayload?: PromotionMethodMobileAppPayloadResolvers<ContextType>;
  PromotionMethodOfflineForProfile?: PromotionMethodOfflineForProfileResolvers<ContextType>;
  PromotionMethodOfflinePayload?: PromotionMethodOfflinePayloadResolvers<ContextType>;
  PromotionMethodPodCastForProfile?: PromotionMethodPodCastForProfileResolvers<ContextType>;
  PromotionMethodPodCastPayload?: PromotionMethodPodCastPayloadResolvers<ContextType>;
  PromotionMethodWebsiteForProfile?: PromotionMethodWebsiteForProfileResolvers<ContextType>;
  PromotionMethodWebsitePayload?: PromotionMethodWebsitePayloadResolvers<ContextType>;
  ProposalInfluencer?: ProposalInfluencerResolvers<ContextType>;
  ProposalJobSummary?: ProposalJobSummaryResolvers<ContextType>;
  ProposalSocialAccount?: ProposalSocialAccountResolvers<ContextType>;
  Province?: ProvinceResolvers<ContextType>;
  ProvincesPayload?: ProvincesPayloadResolvers<ContextType>;
  PublicBioSection?: PublicBioSectionResolvers<ContextType>;
  PublicBioTheme?: PublicBioThemeResolvers<ContextType>;
  PublicLinkBioBackground?: PublicLinkBioBackgroundResolvers<ContextType>;
  PublicLinkBioPageColor?: PublicLinkBioPageColorResolvers<ContextType>;
  PublishInfluencerBioPayload?: PublishInfluencerBioPayloadResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  ReSendBcaRequestPayload?: ReSendBcaRequestPayloadResolvers<ContextType>;
  ReadCampaignIdentifier?: ReadCampaignIdentifierResolvers<ContextType>;
  ReadPackage?: ReadPackageResolvers<ContextType>;
  ReadPackageAccount?: ReadPackageAccountResolvers<ContextType>;
  ReadPackageInfluencers?: ReadPackageInfluencersResolvers<ContextType>;
  ReadSocialAccountForPackage?: ReadSocialAccountForPackageResolvers<ContextType>;
  ReadTutorials?: ReadTutorialsResolvers<ContextType>;
  RechargeAdvertiserAccount?: RechargeAdvertiserAccountResolvers<ContextType>;
  RecipientDetail?: RecipientDetailResolvers<ContextType>;
  RecipientItemPayload?: RecipientItemPayloadResolvers<ContextType>;
  ReconnectTiktokAdAccount?: ReconnectTiktokAdAccountResolvers<ContextType>;
  ReconnectedAccountForLinkBio?: ReconnectedAccountForLinkBioResolvers<ContextType>;
  RefreshPasswordPayload?: RefreshPasswordPayloadResolvers<ContextType>;
  RefreshProfileAccountAvailabilityPayload?: RefreshProfileAccountAvailabilityPayloadResolvers<ContextType>;
  RefreshProfileAccountPayload?: RefreshProfileAccountPayloadResolvers<ContextType>;
  RefreshTwitterAccountAvailabilityPayload?: RefreshTwitterAccountAvailabilityPayloadResolvers<ContextType>;
  RefreshTwitterAccountPayload?: RefreshTwitterAccountPayloadResolvers<ContextType>;
  Region?: RegionResolvers<ContextType>;
  RegionName?: RegionNameResolvers<ContextType>;
  RegisterPushToken?: RegisterPushTokenResolvers<ContextType>;
  RejectEngagementDraftPost?: RejectEngagementDraftPostResolvers<ContextType>;
  RejectMarketplaceCampaign?: RejectMarketplaceCampaignResolvers<ContextType>;
  RejectMarketplaceDraftPost?: RejectMarketplaceDraftPostResolvers<ContextType>;
  RejectTikTokSpecialCampaign?: RejectTikTokSpecialCampaignResolvers<ContextType>;
  RemoveAllFormResponsesPayload?: RemoveAllFormResponsesPayloadResolvers<ContextType>;
  RemoveAllSelectedInfluencers?: RemoveAllSelectedInfluencersResolvers<ContextType>;
  RemoveCreatorStaffPayload?: RemoveCreatorStaffPayloadResolvers<ContextType>;
  RemoveEmailsPayload?: RemoveEmailsPayloadResolvers<ContextType>;
  RemoveFansPayload?: RemoveFansPayloadResolvers<ContextType>;
  RemoveFormResponsePayload?: RemoveFormResponsePayloadResolvers<ContextType>;
  RemoveFormsPayload?: RemoveFormsPayloadResolvers<ContextType>;
  RemoveSelectedInfluencers?: RemoveSelectedInfluencersResolvers<ContextType>;
  RemoveShopifyAccountPayload?: RemoveShopifyAccountPayloadResolvers<ContextType>;
  ReorderMarketplaceCampaigns?: ReorderMarketplaceCampaignsResolvers<ContextType>;
  ReportEngagementPostStatus?: ReportEngagementPostStatusResolvers<ContextType>;
  ReportForInfluencer?: ReportForInfluencerResolvers<ContextType>;
  ReportMarketplacePostStatus?: ReportMarketplacePostStatusResolvers<ContextType>;
  ReportTiktokSpecialPostStatus?: ReportTiktokSpecialPostStatusResolvers<ContextType>;
  RequestDeleteAccount?: RequestDeleteAccountResolvers<ContextType>;
  RequestGoogleSlideCustomisedReport?: RequestGoogleSlideCustomisedReportResolvers<ContextType>;
  RequestGoogleSlideReport?: RequestGoogleSlideReportResolvers<ContextType>;
  RequestInfluencerProfilePptx?: RequestInfluencerProfilePptxResolvers<ContextType>;
  RequestInfluencersProfilePptx?: RequestInfluencersProfilePptxResolvers<ContextType>;
  RequestInfluencersSocialAccountPptx?: RequestInfluencersSocialAccountPptxResolvers<ContextType>;
  RequestInstagramUGCManagementPostOutput?: RequestInstagramUGCManagementPostOutputResolvers<ContextType>;
  RequestPackageProposalExcel?: RequestPackageProposalExcelResolvers<ContextType>;
  RequestPackageProposalPptx?: RequestPackageProposalPptxResolvers<ContextType>;
  RequestPackageSpreadsheet?: RequestPackageSpreadsheetResolvers<ContextType>;
  RequestPowerpointCustomisedReport?: RequestPowerpointCustomisedReportResolvers<ContextType>;
  RequestPowerpointReport?: RequestPowerpointReportResolvers<ContextType>;
  RequestProposalExcel?: RequestProposalExcelResolvers<ContextType>;
  RequestProposalPptx?: RequestProposalPptxResolvers<ContextType>;
  RequestProposalSpreadsheet?: RequestProposalSpreadsheetResolvers<ContextType>;
  RequestUserProposalExcel?: RequestUserProposalExcelResolvers<ContextType>;
  RequestUserProposalPptx?: RequestUserProposalPptxResolvers<ContextType>;
  RequestedBCABrand?: RequestedBCABrandResolvers<ContextType>;
  RequestedBrandForBCA?: RequestedBrandForBCAResolvers<ContextType>;
  RequestedInstagramAccountForBCA?: RequestedInstagramAccountForBCAResolvers<ContextType>;
  ResendEmailForAdvertiser?: ResendEmailForAdvertiserResolvers<ContextType>;
  ResendInfluencerForgotPasswordEmailPayload?: ResendInfluencerForgotPasswordEmailPayloadResolvers<ContextType>;
  ResendInfluencerSignUpEmailPayload?: ResendInfluencerSignUpEmailPayloadResolvers<ContextType>;
  ResendPartnerEmails?: ResendPartnerEmailsResolvers<ContextType>;
  ResetPasswordAndLoginPayload?: ResetPasswordAndLoginPayloadResolvers<ContextType>;
  ResetPasswordPayload?: ResetPasswordPayloadResolvers<ContextType>;
  ResolveChatPayload?: ResolveChatPayloadResolvers<ContextType>;
  RichMessagePayload?: RichMessagePayloadResolvers<ContextType>;
  SelectableBCABrands?: SelectableBCABrandsResolvers<ContextType>;
  SelectableBrandForBCA?: SelectableBrandForBCAResolvers<ContextType>;
  SelectableInstagramAccountForBCA?: SelectableInstagramAccountForBCAResolvers<ContextType>;
  SelectableYoutubeAnalyticsChannel?: SelectableYoutubeAnalyticsChannelResolvers<ContextType>;
  SendBcaRequestPayload?: SendBcaRequestPayloadResolvers<ContextType>;
  SendContact?: SendContactResolvers<ContextType>;
  SendGeminiMessage?: SendGeminiMessageResolvers<ContextType>;
  SendLineBroadcastMessagePayload?: SendLineBroadcastMessagePayloadResolvers<ContextType>;
  SendMessagePayload?: SendMessagePayloadResolvers<ContextType>;
  SendOpenAIMessage?: SendOpenAIMessageResolvers<ContextType>;
  ShopeeCustomCampaignPayload?: ShopeeCustomCampaignPayloadResolvers<ContextType>;
  ShopifyAuthRedirectUrlPayload?: ShopifyAuthRedirectUrlPayloadResolvers<ContextType>;
  ShopifyValidateAuthPayload?: ShopifyValidateAuthPayloadResolvers<ContextType>;
  SignedUrlData?: SignedUrlDataResolvers<ContextType>;
  SimilarPost?: SimilarPostResolvers<ContextType>;
  SocialAccount?: SocialAccountResolvers<ContextType>;
  SocialAccountDescription?: SocialAccountDescriptionResolvers<ContextType>;
  SocialAccountForProfileV2?: SocialAccountForProfileV2Resolvers<ContextType>;
  SocialAccountPopularPost?: SocialAccountPopularPostResolvers<ContextType>;
  SocialAccountV2?: SocialAccountV2Resolvers<ContextType>;
  SocialAuthCheckFacebookTokenPayload?: SocialAuthCheckFacebookTokenPayloadResolvers<ContextType>;
  SocialAuthConnectableFacebookPageAnalyticsPayload?: SocialAuthConnectableFacebookPageAnalyticsPayloadResolvers<ContextType>;
  SocialAuthConnectableInstagramAccountsAnalyticsPayload?: SocialAuthConnectableInstagramAccountsAnalyticsPayloadResolvers<ContextType>;
  SocialAuthConnectableTwitterAccountAnalyticsOutput?: SocialAuthConnectableTwitterAccountAnalyticsOutputResolvers<ContextType>;
  SocialAuthForJoinCampaignPayload?: SocialAuthForJoinCampaignPayloadResolvers<ContextType>;
  SocialAuthReConnectInstagramPayload?: SocialAuthReConnectInstagramPayloadResolvers<ContextType>;
  SocialAuthReconnectFacebookPageAccountAnalyticsPayload?: SocialAuthReconnectFacebookPageAccountAnalyticsPayloadResolvers<ContextType>;
  SocialAuthReconnectInstagramAccountAnalyticsPayload?: SocialAuthReconnectInstagramAccountAnalyticsPayloadResolvers<ContextType>;
  SocialAuthReconnectTwitterAccountAnalyticsPayload?: SocialAuthReconnectTwitterAccountAnalyticsPayloadResolvers<ContextType>;
  SocialAuthReconnectYouTubeAccountAnalyticsPayload?: SocialAuthReconnectYouTubeAccountAnalyticsPayloadResolvers<ContextType>;
  SocialAuthRedirectUrlForInfluencerPayload?: SocialAuthRedirectUrlForInfluencerPayloadResolvers<ContextType>;
  SocialAuthTalentSignInFacebookPayload?: SocialAuthTalentSignInFacebookPayloadResolvers<ContextType>;
  SocialAuthTalentSignInInstagramPayload?: SocialAuthTalentSignInInstagramPayloadResolvers<ContextType>;
  SocialAuthTalentSignInTwitterPayload?: SocialAuthTalentSignInTwitterPayloadResolvers<ContextType>;
  SocialAuthTalentSignInYouTubePayload?: SocialAuthTalentSignInYouTubePayloadResolvers<ContextType>;
  StaffComment?: StaffCommentResolvers<ContextType>;
  StateUpdateEvent?: StateUpdateEventResolvers<ContextType>;
  StripeDeepLink?: StripeDeepLinkResolvers<ContextType>;
  SubmitFormInternalPayload?: SubmitFormInternalPayloadResolvers<ContextType>;
  SubmitFormPayload?: SubmitFormPayloadResolvers<ContextType>;
  SubscribeAnalyticsPlan?: SubscribeAnalyticsPlanResolvers<ContextType>;
  SubscribeAnalyticsTrialPlan?: SubscribeAnalyticsTrialPlanResolvers<ContextType>;
  SubscriptionAvailableFeatures?: SubscriptionAvailableFeaturesResolvers<ContextType>;
  SubscriptionCapabilities?: SubscriptionCapabilitiesResolvers<ContextType>;
  SubscriptionPlan?: SubscriptionPlanResolvers<ContextType>;
  SubscriptionPlanDetail?: SubscriptionPlanDetailResolvers<ContextType>;
  SubscriptionPlanForAdvertiserUserSettings?: SubscriptionPlanForAdvertiserUserSettingsResolvers<ContextType>;
  SudoLimitedLogin?: SudoLimitedLoginResolvers<ContextType>;
  SwitchInfluencerForStaffPayload?: SwitchInfluencerForStaffPayloadResolvers<ContextType>;
  SwitchRoleToAdmin?: SwitchRoleToAdminResolvers<ContextType>;
  SwitchRoleToAdvertiser?: SwitchRoleToAdvertiserResolvers<ContextType>;
  SwitchRoleToAgency?: SwitchRoleToAgencyResolvers<ContextType>;
  SwitchRoleToPartner?: SwitchRoleToPartnerResolvers<ContextType>;
  SwitchRoleToStaff?: SwitchRoleToStaffResolvers<ContextType>;
  SwitchRoleToTalentAgency?: SwitchRoleToTalentAgencyResolvers<ContextType>;
  TalentAgency?: TalentAgencyResolvers<ContextType>;
  TalentAgencyOrPartner?: TalentAgencyOrPartnerResolvers<ContextType>;
  TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload?: TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayloadResolvers<ContextType>;
  TalentAgencyUserWithRole?: TalentAgencyUserWithRoleResolvers<ContextType>;
  TalentAgencyWithCompanies?: TalentAgencyWithCompaniesResolvers<ContextType>;
  TalentAgencyWithNSVerification?: TalentAgencyWithNSVerificationResolvers<ContextType>;
  TalentInfluencerDetailsForEditPayload?: TalentInfluencerDetailsForEditPayloadResolvers<ContextType>;
  TextComponentPayload?: TextComponentPayloadResolvers<ContextType>;
  ThreadsInfluencer?: ThreadsInfluencerResolvers<ContextType>;
  TikTokAdChartReport?: TikTokAdChartReportResolvers<ContextType>;
  TikTokAdReportSummary?: TikTokAdReportSummaryResolvers<ContextType>;
  TikTokProductCategory?: TikTokProductCategoryResolvers<ContextType>;
  TikTokSpecialAdPostInfo?: TikTokSpecialAdPostInfoResolvers<ContextType>;
  TikTokSpecialCampaign?: TikTokSpecialCampaignResolvers<ContextType>;
  TikTokSpecialCampaignChartReport?: TikTokSpecialCampaignChartReportResolvers<ContextType>;
  TikTokSpecialCampaignDetail?: TikTokSpecialCampaignDetailResolvers<ContextType>;
  TikTokSpecialCampaignDetailForInfluencer?: TikTokSpecialCampaignDetailForInfluencerResolvers<ContextType>;
  TikTokSpecialCampaignPost?: TikTokSpecialCampaignPostResolvers<ContextType>;
  TikTokSpecialCampaignPostReportForInfluencer?: TikTokSpecialCampaignPostReportForInfluencerResolvers<ContextType>;
  TikTokSpecialDraftAdPostInfo?: TikTokSpecialDraftAdPostInfoResolvers<ContextType>;
  TikTokSpecialJoinedAccountPayload?: TikTokSpecialJoinedAccountPayloadResolvers<ContextType>;
  TikTokSpecialPackageInfluencer?: TikTokSpecialPackageInfluencerResolvers<ContextType>;
  TikTokSpecialPostDetail?: TikTokSpecialPostDetailResolvers<ContextType>;
  TikTokSpecialPostHistory?: TikTokSpecialPostHistoryResolvers<ContextType>;
  TiktokAccountForProfileV2?: TiktokAccountForProfileV2Resolvers<ContextType>;
  TiktokAdAccountInfo?: TiktokAdAccountInfoResolvers<ContextType>;
  TiktokAdCampaign?: TiktokAdCampaignResolvers<ContextType>;
  TiktokAdDetailReport?: TiktokAdDetailReportResolvers<ContextType>;
  TiktokAdGroup?: TiktokAdGroupResolvers<ContextType>;
  TiktokAdvertiserAccount?: TiktokAdvertiserAccountResolvers<ContextType>;
  TiktokAdvertiserPlanData?: TiktokAdvertiserPlanDataResolvers<ContextType>;
  TiktokAdvertiserSummaryData?: TiktokAdvertiserSummaryDataResolvers<ContextType>;
  TiktokAudienceSection?: TiktokAudienceSectionResolvers<ContextType>;
  TiktokAuthRedirectUrl?: TiktokAuthRedirectUrlResolvers<ContextType>;
  TiktokBudgetAllocation?: TiktokBudgetAllocationResolvers<ContextType>;
  TiktokBusinessAccountInfo?: TiktokBusinessAccountInfoResolvers<ContextType>;
  TiktokBusinessCenterInfo?: TiktokBusinessCenterInfoResolvers<ContextType>;
  TiktokFollowerCountryRate?: TiktokFollowerCountryRateResolvers<ContextType>;
  TiktokFollowersAgeGroup?: TiktokFollowersAgeGroupResolvers<ContextType>;
  TiktokInfluencer?: TiktokInfluencerResolvers<ContextType>;
  TiktokInfluencerSearchResultV2?: TiktokInfluencerSearchResultV2Resolvers<ContextType>;
  TiktokInfluencerV2?: TiktokInfluencerV2Resolvers<ContextType>;
  TiktokPostDetailInfluencer?: TiktokPostDetailInfluencerResolvers<ContextType>;
  TiktokPostInfluencer?: TiktokPostInfluencerResolvers<ContextType>;
  TiktokSpecialCampaignInfo?: TiktokSpecialCampaignInfoResolvers<ContextType>;
  TiktokSpecialCampaignReport?: TiktokSpecialCampaignReportResolvers<ContextType>;
  TiktokSpecialCampaignReportSummary?: TiktokSpecialCampaignReportSummaryResolvers<ContextType>;
  TiktokSpecialInfluencerReport?: TiktokSpecialInfluencerReportResolvers<ContextType>;
  TiktokSpecialJoinableAccountsPayload?: TiktokSpecialJoinableAccountsPayloadResolvers<ContextType>;
  TiktokSpecialPostInfo?: TiktokSpecialPostInfoResolvers<ContextType>;
  TiktokSpecialStatsItemRow?: TiktokSpecialStatsItemRowResolvers<ContextType>;
  TiktokSpecialSummaryDaily?: TiktokSpecialSummaryDailyResolvers<ContextType>;
  TiktokUserHashtag?: TiktokUserHashtagResolvers<ContextType>;
  TiktokUserHashtagListPostsPayload?: TiktokUserHashtagListPostsPayloadResolvers<ContextType>;
  TiktokUserHashtagPayload?: TiktokUserHashtagPayloadResolvers<ContextType>;
  TiktokUserKeyword?: TiktokUserKeywordResolvers<ContextType>;
  TiktokUserKeywordListPostsPayload?: TiktokUserKeywordListPostsPayloadResolvers<ContextType>;
  TiktokUserKeywordPayload?: TiktokUserKeywordPayloadResolvers<ContextType>;
  TiktokUserTagListPostsItemPayload?: TiktokUserTagListPostsItemPayloadResolvers<ContextType>;
  TiktokUserTagPostDetailsPayload?: TiktokUserTagPostDetailsPayloadResolvers<ContextType>;
  TokenAuthLongLive?: TokenAuthLongLiveResolvers<ContextType>;
  TotalCmsRevenue?: TotalCmsRevenueResolvers<ContextType>;
  TotalInfluencerReport?: TotalInfluencerReportResolvers<ContextType>;
  TotalNumber?: TotalNumberResolvers<ContextType>;
  TrackYoutubeMusicDownload?: TrackYoutubeMusicDownloadResolvers<ContextType>;
  TransferMoneyToAdAccount?: TransferMoneyToAdAccountResolvers<ContextType>;
  TrendingFacebookInfluencer?: TrendingFacebookInfluencerResolvers<ContextType>;
  TrendingFacebookInfluencerSearchResult?: TrendingFacebookInfluencerSearchResultResolvers<ContextType>;
  TrendingInstagramInfluencerSearchResultV2?: TrendingInstagramInfluencerSearchResultV2Resolvers<ContextType>;
  TrendingInstagramInfluencerV2?: TrendingInstagramInfluencerV2Resolvers<ContextType>;
  TrendingYoutubeInfluencerSearchResultV2?: TrendingYoutubeInfluencerSearchResultV2Resolvers<ContextType>;
  TrendingYoutubeInfluencerV2?: TrendingYoutubeInfluencerV2Resolvers<ContextType>;
  TwitterAnalyticsFeedPostPayload?: TwitterAnalyticsFeedPostPayloadResolvers<ContextType>;
  TwitterAnalyticsOverviewHistoryItem?: TwitterAnalyticsOverviewHistoryItemResolvers<ContextType>;
  TwitterAnalyticsOverviewHistoryRealItem?: TwitterAnalyticsOverviewHistoryRealItemResolvers<ContextType>;
  TwitterAnalyticsOverviewItem?: TwitterAnalyticsOverviewItemResolvers<ContextType>;
  TwitterAnalyticsOverviewPayload?: TwitterAnalyticsOverviewPayloadResolvers<ContextType>;
  TwitterAnalyticsOverviewRealItem?: TwitterAnalyticsOverviewRealItemResolvers<ContextType>;
  TwitterAnalyticsOverviewRealTotalItem?: TwitterAnalyticsOverviewRealTotalItemResolvers<ContextType>;
  TwitterAnalyticsOverviewTotalItem?: TwitterAnalyticsOverviewTotalItemResolvers<ContextType>;
  TwitterAnalyticsPostByIdPayload?: TwitterAnalyticsPostByIdPayloadResolvers<ContextType>;
  TwitterAnalyticsPostComment?: TwitterAnalyticsPostCommentResolvers<ContextType>;
  TwitterAnalyticsPostItemPayload?: TwitterAnalyticsPostItemPayloadResolvers<ContextType>;
  TwitterAnalyticsPostPayload?: TwitterAnalyticsPostPayloadResolvers<ContextType>;
  TwitterAnalyticsPostsByDatePayload?: TwitterAnalyticsPostsByDatePayloadResolvers<ContextType>;
  TwitterAnalyticsPostsPayload?: TwitterAnalyticsPostsPayloadResolvers<ContextType>;
  TwitterAnalyticsPostsStatisticPayload?: TwitterAnalyticsPostsStatisticPayloadResolvers<ContextType>;
  TwitterAverageEngagementItemPayload?: TwitterAverageEngagementItemPayloadResolvers<ContextType>;
  TwitterAverageEngagementPayload?: TwitterAverageEngagementPayloadResolvers<ContextType>;
  TwitterForProfileV2?: TwitterForProfileV2Resolvers<ContextType>;
  TwitterInfluencer?: TwitterInfluencerResolvers<ContextType>;
  TwitterPostingHabitsPayload?: TwitterPostingHabitsPayloadResolvers<ContextType>;
  UUUMSocialAuthSignUpPayload?: UUUMSocialAuthSignUpPayloadResolvers<ContextType>;
  UnApproveApplicantInfluencers?: UnApproveApplicantInfluencersResolvers<ContextType>;
  UnassignChatPICPayload?: UnassignChatPICPayloadResolvers<ContextType>;
  UnblockInfluencerMarketplace?: UnblockInfluencerMarketplaceResolvers<ContextType>;
  UnreadNotificationCountByAppPayload?: UnreadNotificationCountByAppPayloadResolvers<ContextType>;
  UnreadNotificationCountPayload?: UnreadNotificationCountPayloadResolvers<ContextType>;
  UnresolveChatPayload?: UnresolveChatPayloadResolvers<ContextType>;
  UpdateAccountInformationPayload?: UpdateAccountInformationPayloadResolvers<ContextType>;
  UpdateAddress?: UpdateAddressResolvers<ContextType>;
  UpdateAddressInformationPayload?: UpdateAddressInformationPayloadResolvers<ContextType>;
  UpdateAdmin?: UpdateAdminResolvers<ContextType>;
  UpdateAdvertiser?: UpdateAdvertiserResolvers<ContextType>;
  UpdateAdvertiserUser?: UpdateAdvertiserUserResolvers<ContextType>;
  UpdateAdvertiserUserSettings?: UpdateAdvertiserUserSettingsResolvers<ContextType>;
  UpdateAgency?: UpdateAgencyResolvers<ContextType>;
  UpdateAutoManagedAffiliateCommissionRates?: UpdateAutoManagedAffiliateCommissionRatesResolvers<ContextType>;
  UpdateAutoManagedCampaignPayload?: UpdateAutoManagedCampaignPayloadResolvers<ContextType>;
  UpdateAutoManagedCampaignsStatusPayload?: UpdateAutoManagedCampaignsStatusPayloadResolvers<ContextType>;
  UpdateBioSectionsPayload?: UpdateBioSectionsPayloadResolvers<ContextType>;
  UpdateCmsAssetShareRate?: UpdateCmsAssetShareRateResolvers<ContextType>;
  UpdateCmsChannelShareRate?: UpdateCmsChannelShareRateResolvers<ContextType>;
  UpdateCommentPayload?: UpdateCommentPayloadResolvers<ContextType>;
  UpdateEmailTemplatePayload?: UpdateEmailTemplatePayloadResolvers<ContextType>;
  UpdateEngagement?: UpdateEngagementResolvers<ContextType>;
  UpdateEngagementBreakDownMode?: UpdateEngagementBreakDownModeResolvers<ContextType>;
  UpdateEngagementCouponList?: UpdateEngagementCouponListResolvers<ContextType>;
  UpdateEngagementInfluencerProposalList?: UpdateEngagementInfluencerProposalListResolvers<ContextType>;
  UpdateEngagementInfluencersBudget?: UpdateEngagementInfluencersBudgetResolvers<ContextType>;
  UpdateEngagementPostForInfluencerV2?: UpdateEngagementPostForInfluencerV2Resolvers<ContextType>;
  UpdateEngagementPostStatus?: UpdateEngagementPostStatusResolvers<ContextType>;
  UpdateEngagementPostV2?: UpdateEngagementPostV2Resolvers<ContextType>;
  UpdateEngagementSocialAccountProposalList?: UpdateEngagementSocialAccountProposalListResolvers<ContextType>;
  UpdateEngagementSocialAccountsBudget?: UpdateEngagementSocialAccountsBudgetResolvers<ContextType>;
  UpdateEngagementStatus?: UpdateEngagementStatusResolvers<ContextType>;
  UpdateFanPayload?: UpdateFanPayloadResolvers<ContextType>;
  UpdateFormPayload?: UpdateFormPayloadResolvers<ContextType>;
  UpdateFormStatusPayload?: UpdateFormStatusPayloadResolvers<ContextType>;
  UpdateInfluencerBioPayload?: UpdateInfluencerBioPayloadResolvers<ContextType>;
  UpdateInfluencerPhoneNumberPayload?: UpdateInfluencerPhoneNumberPayloadResolvers<ContextType>;
  UpdateInfluencerProfile?: UpdateInfluencerProfileResolvers<ContextType>;
  UpdateInfluencerRateCardPayload?: UpdateInfluencerRateCardPayloadResolvers<ContextType>;
  UpdateInfluencerRateCardsByStaff?: UpdateInfluencerRateCardsByStaffResolvers<ContextType>;
  UpdateInfluencerRateCardsPayload?: UpdateInfluencerRateCardsPayloadResolvers<ContextType>;
  UpdateInfluencerTagsByStaff?: UpdateInfluencerTagsByStaffResolvers<ContextType>;
  UpdateInfluencerV2?: UpdateInfluencerV2Resolvers<ContextType>;
  UpdateInternalMemo?: UpdateInternalMemoResolvers<ContextType>;
  UpdateLineBroadcastMessagePayload?: UpdateLineBroadcastMessagePayloadResolvers<ContextType>;
  UpdateLinkBioThemeColorPayload?: UpdateLinkBioThemeColorPayloadResolvers<ContextType>;
  UpdateMarketplace?: UpdateMarketplaceResolvers<ContextType>;
  UpdateMarketplaceAffiliateCommissionFees?: UpdateMarketplaceAffiliateCommissionFeesResolvers<ContextType>;
  UpdateMarketplaceAffiliateCommissionRates?: UpdateMarketplaceAffiliateCommissionRatesResolvers<ContextType>;
  UpdateMarketplaceCouponList?: UpdateMarketplaceCouponListResolvers<ContextType>;
  UpdateMarketplaceInfluencerCost?: UpdateMarketplaceInfluencerCostResolvers<ContextType>;
  UpdateMarketplacePostStatusV2?: UpdateMarketplacePostStatusV2Resolvers<ContextType>;
  UpdateMarketplacePostTrackingUrl?: UpdateMarketplacePostTrackingUrlResolvers<ContextType>;
  UpdatePackage?: UpdatePackageResolvers<ContextType>;
  UpdatePackageProposalInfluencersBudget?: UpdatePackageProposalInfluencersBudgetResolvers<ContextType>;
  UpdatePackageProposalMode?: UpdatePackageProposalModeResolvers<ContextType>;
  UpdatePackageProposalSocialAccountsBudget?: UpdatePackageProposalSocialAccountsBudgetResolvers<ContextType>;
  UpdatePartnerAgency?: UpdatePartnerAgencyResolvers<ContextType>;
  UpdatePartnerUser?: UpdatePartnerUserResolvers<ContextType>;
  UpdatePasswordByAdvertiserUser?: UpdatePasswordByAdvertiserUserResolvers<ContextType>;
  UpdatePaymentInformation?: UpdatePaymentInformationResolvers<ContextType>;
  UpdatePaymentStatus?: UpdatePaymentStatusResolvers<ContextType>;
  UpdatePersoanlUserDetailPayload?: UpdatePersoanlUserDetailPayloadResolvers<ContextType>;
  UpdatePlainAgency?: UpdatePlainAgencyResolvers<ContextType>;
  UpdateSocialAccountsPayload?: UpdateSocialAccountsPayloadResolvers<ContextType>;
  UpdateSocialUsername?: UpdateSocialUsernameResolvers<ContextType>;
  UpdateStaff?: UpdateStaffResolvers<ContextType>;
  UpdateSubscriptionPlan?: UpdateSubscriptionPlanResolvers<ContextType>;
  UpdateTalentAgency?: UpdateTalentAgencyResolvers<ContextType>;
  UpdateTalentAgencyUser?: UpdateTalentAgencyUserResolvers<ContextType>;
  UpdateTalentInfluencerPayload?: UpdateTalentInfluencerPayloadResolvers<ContextType>;
  UpdateTikTokSpecialCampaign?: UpdateTikTokSpecialCampaignResolvers<ContextType>;
  UpdateTiktokAdvertiserInfo?: UpdateTiktokAdvertiserInfoResolvers<ContextType>;
  UpdateTiktokSpecialPostStatus?: UpdateTiktokSpecialPostStatusResolvers<ContextType>;
  UpdateTtcmPost?: UpdateTtcmPostResolvers<ContextType>;
  UpdateUserDetailPayload?: UpdateUserDetailPayloadResolvers<ContextType>;
  UpdateUserProposalBreakdownMode?: UpdateUserProposalBreakdownModeResolvers<ContextType>;
  UpdateUserProposalInfluencersBudget?: UpdateUserProposalInfluencersBudgetResolvers<ContextType>;
  UpdateUserProposalSocialAccountsBudget?: UpdateUserProposalSocialAccountsBudgetResolvers<ContextType>;
  UpdateYoutubeMCNCredentialsPayload?: UpdateYoutubeMCNCredentialsPayloadResolvers<ContextType>;
  UploadEngagementReportFile?: UploadEngagementReportFileResolvers<ContextType>;
  UploadEngagementReportFromInsightCheck?: UploadEngagementReportFromInsightCheckResolvers<ContextType>;
  UploadEngagementXhsInsightCheck?: UploadEngagementXhsInsightCheckResolvers<ContextType>;
  UploadOpenAIImages?: UploadOpenAIImagesResolvers<ContextType>;
  UserAudioMessageEvent?: UserAudioMessageEventResolvers<ContextType>;
  UserDefaultPermissions?: UserDefaultPermissionsResolvers<ContextType>;
  UserDetail?: UserDetailResolvers<ContextType>;
  UserDetailPayload?: UserDetailPayloadResolvers<ContextType>;
  UserImageMessageEvent?: UserImageMessageEventResolvers<ContextType>;
  UserPostbackEvent?: UserPostbackEventResolvers<ContextType>;
  UserProposal?: UserProposalResolvers<ContextType>;
  UserProposalInfluencer?: UserProposalInfluencerResolvers<ContextType>;
  UserProposalJobSummary?: UserProposalJobSummaryResolvers<ContextType>;
  UserProposalSocialAccount?: UserProposalSocialAccountResolvers<ContextType>;
  UserTextMessageEvent?: UserTextMessageEventResolvers<ContextType>;
  UserVideoMessageEvent?: UserVideoMessageEventResolvers<ContextType>;
  VerifyLineIDTokenPayload?: VerifyLineIDTokenPayloadResolvers<ContextType>;
  VerifyPayload?: VerifyPayloadResolvers<ContextType>;
  VideoPayload?: VideoPayloadResolvers<ContextType>;
  WithdrawMoneyFromAdAccount?: WithdrawMoneyFromAdAccountResolvers<ContextType>;
  XhsAccountInterest?: XhsAccountInterestResolvers<ContextType>;
  XhsAudienceSection?: XhsAudienceSectionResolvers<ContextType>;
  XhsEngagementInsightSection?: XhsEngagementInsightSectionResolvers<ContextType>;
  XhsInfluencer?: XhsInfluencerResolvers<ContextType>;
  XhsInfluencerSearchResult?: XhsInfluencerSearchResultResolvers<ContextType>;
  XhsPostSection?: XhsPostSectionResolvers<ContextType>;
  YouTubeBrandAccount?: YouTubeBrandAccountResolvers<ContextType>;
  YouTubeBrandAccounts?: YouTubeBrandAccountsResolvers<ContextType>;
  YouTubeCmsRevenueForInfluencer?: YouTubeCmsRevenueForInfluencerResolvers<ContextType>;
  YouTubeCmsVideoAudience?: YouTubeCmsVideoAudienceResolvers<ContextType>;
  YouTubeCmsVideoStats?: YouTubeCmsVideoStatsResolvers<ContextType>;
  YouTubeInfluencer?: YouTubeInfluencerResolvers<ContextType>;
  YoutubeAccountForProfileV2?: YoutubeAccountForProfileV2Resolvers<ContextType>;
  YoutubeAgeGenderGroup?: YoutubeAgeGenderGroupResolvers<ContextType>;
  YoutubeAnalyticsAccountCard?: YoutubeAnalyticsAccountCardResolvers<ContextType>;
  YoutubeAnalyticsAccountOverview?: YoutubeAnalyticsAccountOverviewResolvers<ContextType>;
  YoutubeAnalyticsAccountPosts?: YoutubeAnalyticsAccountPostsResolvers<ContextType>;
  YoutubeAnalyticsAccountPostsInDate?: YoutubeAnalyticsAccountPostsInDateResolvers<ContextType>;
  YoutubeAnalyticsAccountTagRanking?: YoutubeAnalyticsAccountTagRankingResolvers<ContextType>;
  YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload?: YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayloadResolvers<ContextType>;
  YoutubeAnalyticsAudienceAgeBreakdownPayload?: YoutubeAnalyticsAudienceAgeBreakdownPayloadResolvers<ContextType>;
  YoutubeAnalyticsAudienceGenderBreakdownPayload?: YoutubeAnalyticsAudienceGenderBreakdownPayloadResolvers<ContextType>;
  YoutubeAnalyticsAudiencePayload?: YoutubeAnalyticsAudiencePayloadResolvers<ContextType>;
  YoutubeAnalyticsAudienceRegionPayload?: YoutubeAnalyticsAudienceRegionPayloadResolvers<ContextType>;
  YoutubeAnalyticsCategory?: YoutubeAnalyticsCategoryResolvers<ContextType>;
  YoutubeAnalyticsCommentsPayload?: YoutubeAnalyticsCommentsPayloadResolvers<ContextType>;
  YoutubeAnalyticsCompareAccountCard?: YoutubeAnalyticsCompareAccountCardResolvers<ContextType>;
  YoutubeAnalyticsCompareAccountCards?: YoutubeAnalyticsCompareAccountCardsResolvers<ContextType>;
  YoutubeAnalyticsComparePostComment?: YoutubeAnalyticsComparePostCommentResolvers<ContextType>;
  YoutubeAnalyticsCompareRelatedPost?: YoutubeAnalyticsCompareRelatedPostResolvers<ContextType>;
  YoutubeAnalyticsCompareRelatedPosts?: YoutubeAnalyticsCompareRelatedPostsResolvers<ContextType>;
  YoutubeAnalyticsCountriesCategoriesPayload?: YoutubeAnalyticsCountriesCategoriesPayloadResolvers<ContextType>;
  YoutubeAnalyticsCountry?: YoutubeAnalyticsCountryResolvers<ContextType>;
  YoutubeAnalyticsHistoryDataPoint?: YoutubeAnalyticsHistoryDataPointResolvers<ContextType>;
  YoutubeAnalyticsOverviewHistoryItem?: YoutubeAnalyticsOverviewHistoryItemResolvers<ContextType>;
  YoutubeAnalyticsOverviewItem?: YoutubeAnalyticsOverviewItemResolvers<ContextType>;
  YoutubeAnalyticsOverviewPayload?: YoutubeAnalyticsOverviewPayloadResolvers<ContextType>;
  YoutubeAnalyticsPostComment?: YoutubeAnalyticsPostCommentResolvers<ContextType>;
  YoutubeAnalyticsPostCommentItem?: YoutubeAnalyticsPostCommentItemResolvers<ContextType>;
  YoutubeAnalyticsPostPayload?: YoutubeAnalyticsPostPayloadResolvers<ContextType>;
  YoutubeAnalyticsPostPayloads?: YoutubeAnalyticsPostPayloadsResolvers<ContextType>;
  YoutubeAnalyticsPostWithChannelDetailsPayload?: YoutubeAnalyticsPostWithChannelDetailsPayloadResolvers<ContextType>;
  YoutubeAnalyticsPostsAverageEngagement?: YoutubeAnalyticsPostsAverageEngagementResolvers<ContextType>;
  YoutubeAnalyticsPostsPayload?: YoutubeAnalyticsPostsPayloadResolvers<ContextType>;
  YoutubeAnalyticsPostsPayloads?: YoutubeAnalyticsPostsPayloadsResolvers<ContextType>;
  YoutubeAnalyticsPostsPostingHabit?: YoutubeAnalyticsPostsPostingHabitResolvers<ContextType>;
  YoutubeAnalyticsPostsStatisticsPayload?: YoutubeAnalyticsPostsStatisticsPayloadResolvers<ContextType>;
  YoutubeAnalyticsRelatedPostPayload?: YoutubeAnalyticsRelatedPostPayloadResolvers<ContextType>;
  YoutubeAnalyticsRelatedPostsPayload?: YoutubeAnalyticsRelatedPostsPayloadResolvers<ContextType>;
  YoutubeAnalyticsShortsPayload?: YoutubeAnalyticsShortsPayloadResolvers<ContextType>;
  YoutubeAnalyticsShortsPayloads?: YoutubeAnalyticsShortsPayloadsResolvers<ContextType>;
  YoutubeAnalyticsTagRankingPayload?: YoutubeAnalyticsTagRankingPayloadResolvers<ContextType>;
  YoutubeAnalyticsTagRankingPayloads?: YoutubeAnalyticsTagRankingPayloadsResolvers<ContextType>;
  YoutubeAnalyticsTrendPayload?: YoutubeAnalyticsTrendPayloadResolvers<ContextType>;
  YoutubeAnalyticsTrendPostDetailsPayload?: YoutubeAnalyticsTrendPostDetailsPayloadResolvers<ContextType>;
  YoutubeAnalyticsTrendsPayloads?: YoutubeAnalyticsTrendsPayloadsResolvers<ContextType>;
  YoutubeAudienceCountryRate?: YoutubeAudienceCountryRateResolvers<ContextType>;
  YoutubeAudienceGenderRate?: YoutubeAudienceGenderRateResolvers<ContextType>;
  YoutubeCmsChannelDetail?: YoutubeCmsChannelDetailResolvers<ContextType>;
  YoutubeCmsChannelForInfluencer?: YoutubeCmsChannelForInfluencerResolvers<ContextType>;
  YoutubeCmsChannelInfo?: YoutubeCmsChannelInfoResolvers<ContextType>;
  YoutubeCmsConfirmedAsset?: YoutubeCmsConfirmedAssetResolvers<ContextType>;
  YoutubeCmsConfirmedChannel?: YoutubeCmsConfirmedChannelResolvers<ContextType>;
  YoutubeCmsConfirmedChannelSummary?: YoutubeCmsConfirmedChannelSummaryResolvers<ContextType>;
  YoutubeCmsConfirmedRevenueLatestMonth?: YoutubeCmsConfirmedRevenueLatestMonthResolvers<ContextType>;
  YoutubeCmsEstimateAsset?: YoutubeCmsEstimateAssetResolvers<ContextType>;
  YoutubeCmsEstimateChannel?: YoutubeCmsEstimateChannelResolvers<ContextType>;
  YoutubeCmsEstimateChannelSummary?: YoutubeCmsEstimateChannelSummaryResolvers<ContextType>;
  YoutubeCmsInvoice?: YoutubeCmsInvoiceResolvers<ContextType>;
  YoutubeCmsInvoiceDownloadUrl?: YoutubeCmsInvoiceDownloadUrlResolvers<ContextType>;
  YoutubeCmsInvoiceForInfluencer?: YoutubeCmsInvoiceForInfluencerResolvers<ContextType>;
  YoutubeCmsMusicForInfluencer?: YoutubeCmsMusicForInfluencerResolvers<ContextType>;
  YoutubeCmsStartMonth?: YoutubeCmsStartMonthResolvers<ContextType>;
  YoutubeCmsVideo?: YoutubeCmsVideoResolvers<ContextType>;
  YoutubeCompareAccountOverview?: YoutubeCompareAccountOverviewResolvers<ContextType>;
  YoutubeCompareAccountPost?: YoutubeCompareAccountPostResolvers<ContextType>;
  YoutubeCompareAccountPosts?: YoutubeCompareAccountPostsResolvers<ContextType>;
  YoutubeCompareAccountPostsInDate?: YoutubeCompareAccountPostsInDateResolvers<ContextType>;
  YoutubeCompareAccountTagRanking?: YoutubeCompareAccountTagRankingResolvers<ContextType>;
  YoutubeCompareAllPostsInDate?: YoutubeCompareAllPostsInDateResolvers<ContextType>;
  YoutubeCompareOverview?: YoutubeCompareOverviewResolvers<ContextType>;
  YoutubeCompareOverviewHistory?: YoutubeCompareOverviewHistoryResolvers<ContextType>;
  YoutubeCompareOverviewSummary?: YoutubeCompareOverviewSummaryResolvers<ContextType>;
  YoutubeCompareOverviewSummaryData?: YoutubeCompareOverviewSummaryDataResolvers<ContextType>;
  YoutubeComparePost?: YoutubeComparePostResolvers<ContextType>;
  YoutubeComparePostList?: YoutubeComparePostListResolvers<ContextType>;
  YoutubeComparePostListItem?: YoutubeComparePostListItemResolvers<ContextType>;
  YoutubeComparePosts?: YoutubeComparePostsResolvers<ContextType>;
  YoutubeCompareTagRanking?: YoutubeCompareTagRankingResolvers<ContextType>;
  YoutubeInfluencerSearchResultV3?: YoutubeInfluencerSearchResultV3Resolvers<ContextType>;
  YoutubeInfluencerV3?: YoutubeInfluencerV3Resolvers<ContextType>;
  YoutubeMCNAdminRedirectUrlPayload?: YoutubeMCNAdminRedirectUrlPayloadResolvers<ContextType>;
  YoutubeMusicCategory?: YoutubeMusicCategoryResolvers<ContextType>;
  YoutubeMusicItem?: YoutubeMusicItemResolvers<ContextType>;
  YoutubeStatisticsData?: YoutubeStatisticsDataResolvers<ContextType>;
  YoutubeTagRanking?: YoutubeTagRankingResolvers<ContextType>;
  _FieldSet?: GraphQLScalarType;
  _Service?: _ServiceResolvers<ContextType>;
}>;
