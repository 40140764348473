import { useTranslation } from 'react-i18next';
import { EngagementDetail } from '@/graphql';
import { ChartsContainer, NoDataTitle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { AgeBreakdownChart, GenderBreakdownChart, SingleAreaBreakdownChart } from '@/shared/molecules';
import { Option } from '@/shared/types';

interface TemplateProps {
  engagement: EngagementDetail;
  ageOptions?: Option<number>[];
  areaOptions?: Option<number>[];
  genderRates?: { maleRate: number; femaleRate: number };
  ageGenderRates?: {
    maleRates: readonly number[];
    femaleRates: readonly number[];
    ageGroups: readonly { start: number; end: number }[];
  };
}

export const Template = ({
  genderRates,
  ageGenderRates,
  ageOptions = [],
  areaOptions = [],
  engagement: { isTtcmCampaign }
}: TemplateProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ backgroundColor: THEME.background.colors.white, borderTop: THEME.border.base }}>
      <p css={{ fontSize: '18px', padding: '16px', borderBottom: '1px solid #e6ecf0' }}>
        {t(isTtcmCampaign ? 'Video view audience' : 'YouTube Video Audience Breakdown')}
      </p>

      <ChartsContainer>
        <GenderBreakdownChart {...genderRates} />

        {isTtcmCampaign ? (
          <SingleAreaBreakdownChart valueOptions={ageOptions} />
        ) : (
          <AgeBreakdownChart
            ageGroup={ageGenderRates?.ageGroups}
            maleRate={ageGenderRates?.maleRates}
            femaleRate={ageGenderRates?.femaleRates}
            header={<NoDataTitle css={{ marginBottom: '15px' }} title={t('BasicInfo.Age')} />}
          />
        )}

        <SingleAreaBreakdownChart valueOptions={areaOptions} />
      </ChartsContainer>
    </div>
  );
};
