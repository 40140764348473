import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { YoutubeAnalyticsPostCommentItem } from '@/graphql';
import { Anchor, Icon, RenderDataWithFallback, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TableData } from '@/shared/types';
import { formatDate } from '@/shared/utils';

export interface AnalyticsYoutubeCommentsListProps extends TableData<YoutubeAnalyticsPostCommentItem> {
  className?: string;
}

export const AnalyticsYoutubeCommentsList = ({
  loading,
  data = [],
  className,
  totalRecords
}: AnalyticsYoutubeCommentsListProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper} className={className}>
      <RenderDataWithFallback
        loading={loading}
        hasNoData={!totalRecords}
        noDataNode={<div css={styles.noDataText}>{t('NoMatchedFoundTrySearchingForADifferentWord')}</div>}
      >
        {data.map((comment, index) => (
          <div css={styles.itemWrapper} key={index}>
            <div css={styles.item}>
              <div css={styles.heading}>
                <TextCutter text={`${formatDate(comment.created || '')} ${comment.username}`} />
                <Anchor css={styles.link} href={comment.postUrl || '#'} target="_blank">
                  {t('Post')}
                  <Icon icon="external-link" size="16px" color="#3892e5" css={{ marginLeft: '6px' }} />
                </Anchor>
              </div>
              <TextCutter text={comment.content} lines={2} css={{ marginTop: 'auto' }} />
            </div>
          </div>
        ))}
      </RenderDataWithFallback>
    </div>
  );
};

const styles = {
  wrapper: css({
    padding: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    borderTop: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  }),
  heading: css({
    display: 'flex',
    fontSize: '14px',
    fontWeight: '400',
    alignItems: 'center',
    color: THEME.text.colors.gray.lv3,
    justifyContent: 'space-between'
  }),
  itemWrapper: css({
    '--size': '50%',

    padding: '8px',
    flex: 'var(--size)',
    maxWidth: 'var(--size)',
    boxSizing: 'border-box'
  }),
  item: css({
    '--padding': '16px',

    display: 'flex',
    borderRadius: '5px',
    flexDirection: 'column',
    padding: 'var(--padding)',
    height: 'calc(98px - var(--padding) * 2)',
    backgroundColor: THEME.background.colors.gray.lv1,
    boxShadow: '0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25)'
  }),
  link: css({
    display: 'flex',
    alignItems: 'center',
    color: THEME.text.colors.gray.lv3,
    '&:hover': { textDecoration: 'none' }
  }),
  noDataText: css({
    width: '100%',
    display: 'flex',
    height: '140px',
    alignItems: 'center',
    justifyContent: 'center',
    color: THEME.text.colors.gray.lv3
  })
};
