import { Modal, ModalTitle, ModalContent, ModalFooterActions } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';

export interface MissingHubSpotModalProps {
  open?: boolean;
  onClose?: () => void;
  engagementId: number;
}

export const MissingHubSpotModal = ({ open, onClose, engagementId }: MissingHubSpotModalProps) => {
  const { t, navigate, pathname } = useQueryHelper();

  const handleNavigateToCampaignDetails = () => {
    navigate({
      to: '/engagement/$id',
      params: { id: engagementId.toString() },
      search: { state: { redirectUrlAfterUpdateEngagement: pathname } }
    });
    onClose?.();
  };

  return (
    <Modal css={{ width: '480px' }} open={open} onClose={onClose} hasCloseIcon>
      <ModalContent>
        <ModalTitle>{t('Set HubSpot deal')}</ModalTitle>
        <p css={{ marginTop: '16px' }}>{t('You need to set HubSpot deal in order to approve this campaign')}</p>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{ onClick: handleNavigateToCampaignDetails }}
      />
    </Modal>
  );
};
