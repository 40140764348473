import { format, isAfter, isBefore } from 'date-fns';
import { forwardRef, ReactNode, useEffect, useState } from 'react';
import { DayPicker, DayPickerProps } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ButtonProps, ClearIcon, Icon, Popover } from '@/shared/atoms';
import { DATE_PICKER_INPUT_MASK_FORMAT, DEFAULT_DATE_FNS_FORMAT, THEME } from '@/shared/constants';
import { useDeepCompareEffect } from '@/shared/hooks';
import { AppLanguage, SelectedRangeOption } from '@/shared/types';
import { localeUtils } from '@/shared/utils';
import { ActionButtons } from '../ActionButtons';
import { StyledInputWithMask, WithGlobalStyles } from './Components';
import { getDatePickerLocale, getInputState, getInputText, placeholderMulti } from './helpers';
import { Period, Range } from './types';
import { useCustomRange } from './useCustomRange';
import { useHandlers } from './useDatePickerState';

export interface DayPickerRangeProps {
  period: Period;
  handleChangePeriod: (period: Period) => void;
  rangeList?: SelectedRangeOption[];
  initialPeriod?: Period;
  hasError?: boolean;
  disabled?: boolean;
  disabledRange?: DayPickerProps['disabled'];
  className?: string;
  clearTitle?: string | null;
  hasCalendarIcon?: boolean;
  saveOnBlur?: boolean;
  onClearCallback?: (isAllTimeClicked: boolean) => void;
  customInputTextNode?: ReactNode;
  type?: ButtonProps['type'];
  clearable?: boolean;
  withinPortal?: boolean;
}
export const DayPickerRange = forwardRef<HTMLDivElement, DayPickerRangeProps>(
  (
    {
      className,
      period,
      handleChangePeriod,
      rangeList = [],
      initialPeriod,
      hasError,
      disabled,
      disabledRange,
      clearTitle = '',
      hasCalendarIcon = true,
      onClearCallback,
      customInputTextNode,
      type,
      clearable = true,
      saveOnBlur = true,
      withinPortal = true
    },
    ref
  ) => {
    const defaultInputState = getInputState(period);
    const [state, setState] = useState<Range>(defaultInputState);
    const [datePickerInput, setDatePickerInput] = useState<string>(placeholderMulti);

    const { i18n } = useTranslation();
    let handleClosePopover: () => void;

    // if form has default period values, than set picker input
    useEffect(() => {
      if (resultInput) {
        setDatePickerInput(resultInput);
      }
    }, []);

    useDeepCompareEffect(() => {
      const newInputState = getInputState(period);
      const newResultInput = getInputText(i18n.language as AppLanguage, newInputState.hoverTo, newInputState.hoverFrom);

      // Since state value when period changed
      if (datePickerInput !== newResultInput) {
        setState((prevState) => ({ ...prevState, ...newInputState }));
        setDatePickerInput(newResultInput);
      }
    }, [period, datePickerInput]);

    const CustomRange = useCustomRange({
      state,
      setState,
      initialPeriod,
      rangeList,
      clearTitle
    });

    const { handleDayClick, handleDayMouseEnter } = useHandlers(state, defaultInputState, setState);
    const handleUpdate = () => {
      setDatePickerInput(resultInput || '');
      handleClosePopover();

      if (state.from && state.to) {
        if (onClearCallback) {
          onClearCallback(false);
        }
        const startDate = isBefore(state.from, state.to) ? state.from : state.to;
        const endDate = isAfter(state.to, state.from) ? state.to : state.from;
        handleChangePeriod({
          startDate: format(startDate, DEFAULT_DATE_FNS_FORMAT),
          endDate: format(endDate, DEFAULT_DATE_FNS_FORMAT)
        });
        // means just one date is selected
      } else if (state.from || state.to) {
        if (onClearCallback) {
          onClearCallback(false);
        }
        const startDate = (state.from || state.to) as Date;
        handleChangePeriod({
          startDate: format(startDate, DEFAULT_DATE_FNS_FORMAT),
          endDate: format(startDate, DEFAULT_DATE_FNS_FORMAT)
        });
      } else if (state.from === null && state.to === null) {
        if (onClearCallback) {
          onClearCallback(true);
        }
        handleChangePeriod({ startDate: '', endDate: '' });
      }
    };

    const { from, hoverFrom, to, hoverTo } = state;
    const resultInput = getInputText(i18n.language as AppLanguage, hoverTo, hoverFrom) || clearTitle || '';

    const isPlaceholder = datePickerInput === placeholderMulti;

    const handleClickAway = (popoverOpen?: boolean) => {
      if (saveOnBlur && popoverOpen) {
        handleUpdate();
      } else if (popoverOpen) {
        handleClosePopover();
      }
    };

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleUpdate();
      }
    };

    return (
      <div css={{ display: 'flex', flexDirection: 'column' }} className={className} ref={ref} onKeyDown={handleEnter}>
        <Popover
          withinPortal={withinPortal}
          onClose={handleClickAway}
          target={({ open, setOpen }) => {
            handleClosePopover = () => setOpen(false);

            return (
              <div onClick={() => setOpen(true)} css={styles.input({ disabled, hasError, open })}>
                {hasCalendarIcon ? (
                  <Icon icon="calendar" size="16px" color="#6e7c89" className="calendar-icon" />
                ) : null}
                {customInputTextNode && !open ? (
                  customInputTextNode
                ) : (
                  <>
                    <StyledInputWithMask
                      value={state.from ? format(state.from, DATE_PICKER_INPUT_MASK_FORMAT) : ''}
                      onAcceptCallback={(parsedDate) => {
                        setState((prevState) => ({
                          ...prevState,
                          from: parsedDate,
                          hoverFrom: parsedDate
                        }));
                      }}
                    />
                    &ndash;
                    <StyledInputWithMask
                      value={state.to ? format(state.to, DATE_PICKER_INPUT_MASK_FORMAT) : ''}
                      css={{
                        marginLeft: '2px'
                      }}
                      onAcceptCallback={(parsedDate) => {
                        setState((prevState) => ({
                          ...prevState,
                          to: parsedDate,
                          hoverTo: parsedDate
                        }));
                      }}
                    />
                  </>
                )}
                {!isPlaceholder && datePickerInput && clearable && !disabled ? (
                  <ClearIcon
                    id="clear-indicator"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDatePickerInput('');
                      handleChangePeriod({ startDate: '', endDate: '' });
                      setState({ from: undefined, to: undefined, hoverFrom: undefined, hoverTo: undefined });
                    }}
                    css={{ marginLeft: 'auto' }}
                  />
                ) : null}
              </div>
            );
          }}
          position="bottom-start"
          offset={4}
          withBorder
        >
          {({ setOpen }) => (
            <div>
              <div css={{ display: 'flex', height: '100%' }}>
                {CustomRange}
                <WithGlobalStyles>
                  <DayPicker
                    mode="range"
                    numberOfMonths={2}
                    selected={{
                      from,
                      to
                    }}
                    onDayClick={handleDayClick}
                    locale={getDatePickerLocale(i18n.language)}
                    onDayMouseEnter={handleDayMouseEnter}
                    defaultMonth={from || new Date()}
                    weekStartsOn={1}
                    disabled={disabledRange}
                    components={{
                      CaptionLabel: ({ displayMonth }) => (
                        <div>{localeUtils.formatMonthTitle(displayMonth, i18n.language)}</div>
                      )
                    }}
                  />
                </WithGlobalStyles>
              </div>
              <ActionButtons
                primaryButtonProps={{
                  onClick: handleUpdate,
                  type
                }}
                secondaryButtonProps={{
                  onClick: () => setOpen(false)
                }}
                buttonHeight="32px"
                hasTopBorder
              >
                {resultInput}
              </ActionButtons>
            </div>
          )}
        </Popover>
      </div>
    );
  }
);
DayPickerRange.displayName = 'DayPickerRange';

const styles = {
  input: ({ disabled, hasError, open }: { disabled?: boolean; hasError?: boolean; open?: boolean }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      alignSelf: 'center',
      width: '100%',
      minWidth: '245px',
      height: '32px',
      boxSizing: 'border-box',
      backgroundColor: THEME.input.background.colors.default,
      border: '1px solid',
      borderColor: open ? THEME.input.border.colors.focus : THEME.input.border.colors.default,
      borderRadius: '3px',
      fontSize: '13px',
      lineHeight: 'calc(1em + 1ex)',
      padding: '0 4px 0 12px',
      ...(disabled && {
        cursor: 'default',
        pointerEvents: 'none',
        backgroundColor: THEME.input.background.colors.disabled
      }),
      ...(hasError && { borderColor: THEME.input.border.colors.error })
    })
};
