import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { InstagramInfluencerV3, SocialAccountInfluencerListSortFieldV2, SocialAccountType } from '@/graphql';
import { SearchFilterDefaults } from '@/shared/validations';
import { PostType, DefaultInfluencersProps, InfluencersFilterSchema, InfluencersBaseSearchSchema } from '../types';
import { getInitialInstagramInfluencersFormValues } from './utils';

export const InstagramInfluencersFilterSchema = InfluencersFilterSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  minReach: true,
  maxReach: true,
  regionIds: true,
  interests: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  audienceAge: true,
  minSaveRate: true,
  maxSaveRate: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  minStoryPrice: true,
  maxStoryPrice: true,
  audienceRatio: true,
  audienceGender: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  campaignCategoryIds: true,
  followerGrowthPeriod: true,
  followerGrowthPercentage: true,
  talentAgencyOrPartnerIds: true
});

export const InstagramInfluencersSearchSchema = z.object({
  filter: InstagramInfluencersFilterSchema.default(
    InstagramInfluencersFilterSchema.parse(getInitialInstagramInfluencersFormValues())
  ),
  ...SearchFilterDefaults.pageLimit(),
  sort: SearchFilterDefaults.sort(SocialAccountInfluencerListSortFieldV2).default({ field: null, order: null }),
  popup: z.string().default(''),
  state: InfluencersBaseSearchSchema.shape.state
});

export type InstagramInfluencersSearchSchemaType = z.infer<typeof InstagramInfluencersSearchSchema>;
export type InstagramInfluencersFilterFormValues = z.infer<typeof InstagramInfluencersFilterSchema>;
export type InstagramInfluencersFilterFormKeys = UnionOfKeys<InstagramInfluencersFilterFormValues>;
export type InstagramInfluencersProps = DefaultInfluencersProps<InstagramInfluencerProfile>;
export type InstagramInfluencerProfile = { posts: PostType[]; socialAccountType: SocialAccountType } & Pick<
  InstagramInfluencerV3,
  | 'id'
  | 'age'
  | 'name'
  | 'gender'
  | 'avatar'
  | 'country'
  | 'averageLikes'
  | 'averageReach'
  | 'isBrandAccount'
  | 'socialAccountId'
  | 'analyticsWarning'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementRate'
>;
