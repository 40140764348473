import { css } from '@emotion/react';
import { CSSInterpolation, serializeStyles } from '@emotion/serialize';
import { TFunction } from 'i18next';
import { RefObject } from 'react';
import {
  UserRoles,
  CampaignTrackingOption,
  CampaignSocialMediaType,
  MarketplaceCampaignDetailType
} from '@/graphql';
import { SUN_EDITOR_EMPTY_VALUE } from '@/shared/constants';
import { rateToApiFormat } from '@/shared/utils';
import { MarketplaceFormValues } from '../types';
import { calculateInfluencerCommissionRate } from '../utils';

export interface CampaignDetailsProps {
  campaign: {
    campaignDetailType?: MarketplaceCampaignDetailType;
    clickUrl?: string | null;
    commissionRate?: number | null;
    couponCode?: string | null;
    createdDate: NonNullable<unknown>;
    currency: string | null;
    endDate: NonNullable<unknown>;
    hashtags?: string[];
    isApplied?: boolean;
    isCouponCampaign?: boolean | null;
    isJoined: boolean;
    isPostTracking?: boolean | null;
    materials?:
      | {
          id: number;
          url: string;
        }[]
      | null;
    materialUrl?: string | null;
    maximumRevenuePerInfluencer?: number | null;
    minFollowers?: number;
    remainingAmount?: number;
    requirement: string | null;
    revenuePerAction?: number | null;
    revenuePerClick?: number | null;
    revenuePerComment?: number | null;
    revenuePerFollower?: number | null;
    revenuePerLike?: number | null;
    revenuePerOrder?: number | null;
    revenuePerPost?: number | null;
    revenuePerShare?: number | null;
    revenuePerView?: number | null;
    serviceInformation?: string | null;
    serviceUrl?: string | null;
    sampleUrl?: string | null;
    socialMedias?: CampaignSocialMediaType[];
    startDate: NonNullable<unknown>;
    title: string;
    thumbnails: readonly string[];
    totalAmount?: number;
    trackingUrl?: string | null;
  };
  isAutoManaged?: boolean;
  previewOnlyData?: {
    styles?: CSSInterpolation;
    userRole?: UserRoles | null;
    iframeWidth: number;
  };
  language: string;
  rewardPlaceholder?: string;
}
interface PostFormValuesToPreviewIframeProps {
  t: TFunction;
  role?: UserRoles | null;
  values: MarketplaceFormValues;
  language: string;
  iframeRef: RefObject<HTMLIFrameElement>;
  couponCodes?: string[];
}

export const postFormValuesToPreviewIframe = async ({
  t,
  role,
  values,
  language,
  iframeRef,
  couponCodes
}: PostFormValuesToPreviewIframeProps) => {
  if (iframeRef) {
    const isAdminStaff = !!role && [UserRoles.ADMINISTRATOR, UserRoles.STAFF].includes(role);
    // should match BE calculation https://bitbucket.org/adasiaholdings/casting_asia_django/src/29489106d39ff83be492ae04102322befb2a9e8d/src/domain/read/entity/campaign.py#lines-244
    const revenuePerMetric = (cost: number | string) => {
      if (!cost || !isAdminStaff) {
        return null;
      } else if (values.agencyMarginRate !== '') {
        return Number(cost) * (1 - Number(values.agencyMarginRate) / 100) * (1 - values.marginRate / 100);
      }

      return Number(cost) * (1 - values.marginRate / 100);
    };

    const influencerCommissionRate = calculateInfluencerCommissionRate({
      marginRate: values.marginRate,
      costPerSaleTune: values.costPerSaleTune,
      costPerSaleReferral: values.costPerSaleReferral
    });
    const hasNoCostSet = [
      values.costPerAction,
      values.costPerClick,
      values.costPerComment,
      values.costPerFollower,
      values.costPerLike,
      values.costPerOrder,
      values.costPerPost,
      values.costPerShare,
      values.costPerView
    ].every((val) => val === '');

    const placeholderStyles = {
      title: !values.title && '.campaign-title {color: #C5D0DA;}',
      thumbnail: '.thumbnail-container {background-color: #fff;}',
      startDate: !values.period.startDate && '.start-date {color: #C5D0DA;}',
      endDate: !values.period.endDate && '.end-date {color: #C5D0DA;}',
      // we will show "-" for "Reward" field, to align it better we can set "line-height: 0;"
      rewardPlaceholder: !isAdminStaff
        ? '.reward-placeholder {color: #C5D0DA; line-height: 0;}'
        : hasNoCostSet && '.reward-placeholder {color: #C5D0DA; align-items: flex-start;}',
      serviceInformation:
        (!values.serviceInformation || values.serviceInformation === SUN_EDITOR_EMPTY_VALUE) &&
        '.service-information {.se-wrapper-wysiwyg {color: #C5D0DA !important;}}',
      requirement:
        (!values.requirement || values.requirement === SUN_EDITOR_EMPTY_VALUE) &&
        '.post-requirements {.se-wrapper-wysiwyg {color: #C5D0DA !important;}}',
      contentContainer: '.content-container {padding-bottom: 4px;}'
    };

    const message: {
      type: 'update-preview';
      data: CampaignDetailsProps;
    } = {
      type: 'update-preview',
      data: {
        language,
        campaign: {
          clickUrl: values.clickUrl,
          campaignDetailType: values.campaignType,
          commissionRate: rateToApiFormat(influencerCommissionRate),
          currency: values.currency,
          couponCode: couponCodes?.[Math.floor(Math.random() * 0)] || '', // randomly pick 1 code to preview
          startDate: values.period.startDate || new Date(),
          endDate: values.period.endDate || new Date(),
          createdDate: new Date(),
          hashtags: values.hashtags,
          isApplied: true, // setting it to true to display material URL
          isJoined: true, // setting it to true to show hashtags
          isCouponCampaign:
            values.trackingOptions.includes(CampaignTrackingOption.REFERRAL_CODE) ||
            values.campaignType === MarketplaceCampaignDetailType.REFERRAL_CODE,
          isPostTracking:
            values.trackingOptions.includes(CampaignTrackingOption.POST) ||
            values.campaignType === MarketplaceCampaignDetailType.NORMAL,
          materials: (values.materials as string[])?.map((item, i) => ({ id: i, url: item })),
          maximumRevenuePerInfluencer:
            isAdminStaff && values.maximumRevenuePerInfluencer
              ? Number(values.maximumRevenuePerInfluencer) * (1 - values.marginRate / 100)
              : null,
          minFollowers: Number(values.minFollowers),
          remainingAmount: undefined, // if we want to show this then we need to have some API
          requirement:
            values.requirement !== SUN_EDITOR_EMPTY_VALUE && values.requirement
              ? values.requirement
              : EMPTY_VALUES.requirement,
          revenuePerAction: revenuePerMetric(values.costPerAction),
          revenuePerClick: revenuePerMetric(values.costPerClick),
          revenuePerComment: revenuePerMetric(values.costPerComment),
          revenuePerFollower: revenuePerMetric(values.costPerFollower),
          revenuePerLike: revenuePerMetric(values.costPerLike),
          revenuePerOrder: revenuePerMetric(values.costPerOrder),
          revenuePerPost: revenuePerMetric(values.costPerPost),
          revenuePerShare: revenuePerMetric(values.costPerShare),
          revenuePerView: revenuePerMetric(values.costPerView),
          materialUrl: values.campaignMaterialLink || '',
          sampleUrl: values.sampleUrl,
          serviceInformation:
            values.serviceInformation !== SUN_EDITOR_EMPTY_VALUE && values.serviceInformation
              ? values.serviceInformation
              : EMPTY_VALUES.serviceInformation,
          socialMedias: values.socialMedia ? [values.socialMedia] : [CampaignSocialMediaType.UNSELECT],
          thumbnails: values.thumbnails?.length ? values.thumbnails : [EMPTY_VALUES.thumbnail],
          title: values.title || t(EMPTY_VALUES.title),
          totalAmount: Number(values.budget) || undefined,
          trackingUrl: values.landingPageUrl
        },
        isAutoManaged: values.campaignType === MarketplaceCampaignDetailType.E_COMMERCE,
        rewardPlaceholder: !isAdminStaff ? '-' : hasNoCostSet ? '0' : undefined,
        previewOnlyData: {
          styles: serializeStyles([
            css`
              ${placeholderStyles.title}
              ${placeholderStyles.endDate}
            ${placeholderStyles.thumbnail}
            ${placeholderStyles.startDate}
            ${placeholderStyles.requirement}
            ${placeholderStyles.contentContainer}
            ${placeholderStyles.rewardPlaceholder}
            ${placeholderStyles.serviceInformation}
            `
          ]).styles,
          userRole: role,
          iframeWidth: DIMENSIONS.width
        }
      }
    };

    iframeRef?.current?.contentWindow?.postMessage(message, '*');
  }
};

const EMPTY_VALUES = {
  title: 'Campaign title comes here',
  thumbnail:
    'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAXcAAADTCAYAAAB6OlOyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAVMSURBVHgB7d3JciJHFIbRRKDJ8vD+79iObk1QzCZpW4G7VRJIvaj8OWelCA0r9FFx65I1mnaLbQEgykUBII64AwQSd4BA4g4QSNwBAok7QCBxBwgk7gCBxB0gkLgDBBJ3gEDiDhBI3AECiTtAIHEHCCTuAIHEHSCQuAMEEneAQOIOEEjcAQKJO0AgcQcIJO4AgcQdIJC4AwQSd4BA4g4QSNwBAok7QCBxBwgk7gCBxB0gkLgDBBJ3gEDiDhBI3AECiTtAIHEHCCTuAIHEHSCQuAMEEneAQOIOEEjcAQKJO0AgcQcIJO4AgcQdIJC4AwQSd4BA4g4QSNwBAok7QCBxBwgk7gCBxB0gkLgDBBJ3gEDiDhBI3AECiTtAIHEHCCTuAIHEHSCQuAMEEneAQOIOEEjcAQKJO0AgcQcIJO4AgcQdIJC4AwQSd4BA4g4QSNwBAok7QCBxBwgk7gCBxB0gkLgDBBJ3gEDiDhBI3AECiTtAoEmBgVmu1mW+WJaWXF5OyvWlfyeGw6uRQVlvNuX+aVpa0+3ejK7++r2MRqMCQ2Asw6DUq/ZWLZarAkMh7gCBxB0gkLgDBBJ3gEC2ZeAdq/Vmd6P3+83S8cVFubLySAO8SuENdd+++2Hnvob+7vamwJAZy0CPzXb7U9ireiU/t/bIwIk79Fiv+3fuN5tNgSETd+jh06a0TNyhx2Q8Lhc9gXdTlaETd3jD3W83/wt8vZq/ub7ab83AkLn8gDfUsP9xd7u/ibrd3WCdjC+Ma2iCuMMRatShJV6xRNtsbbVwnsSdWHVP/Xk6F3jOkrgT63na7QM/nc0LnBtxJ1I9NqCGvVpvtqWbLwqcE3EnTj375cdjA+pxAat1u095glOJO1H258HMX3+49qxb7NcZ4RyIO1Hq+GXTE/D9/L0zf+c8iDsx6pz9vQdsv3ei43pts4YM4k6EvuN5X1PfBNavnOr48DQtf98/FUgg7jRvu99n7076+boeeTh/Xy7X5f5ptg//t8fnAq0Td5p3uPZ4rPrz83+v9Oso5su3h5fvPT53L9+DVok7TVvs5ucffSpS/b26Nvn14Xk/iz/05euj+TtNE3eadXj1/VE17LNXPuBU/7b5Oy0Td5o1nXUnj2MO1U+uzhf9V/3m77RM3GnS942Xj4e9vinMjjhzxvydVok7zalrjN0ng1s/xXrsVb/5Oy0Sd5ryK055rFfiq9Xx58yYv9Micacp3QfWHg+9N2fvY/5Oa8SdZtS1x+UH1x6rY+fsfczfaYm404RTjhfoc8qcvY/5O60Qdwbl+vL1Z7bX4wU+c1zvqXP2Pm/N3ycTz5tnOLwaGZTRaFT+vLvdj2D+U6+U725vymdMxuPd3yi/1M3V5cvX17uvxxejAkMxmnYLTy8ACGMsAxBI3AECiTtAIHEHCCTuAIHEHSCQuAMEEneAQOIOEEjcAQKJO0AgcQcIJO4AgcQdIJC4AwQSd4BA4g4QSNwBAok7QCBxBwgk7gCBxB0gkLgDBBJ3gEDiDhBI3AECiTtAIHEHCCTuAIHEHSCQuAMEEneAQOIOEEjcAQKJO0AgcQcIJO4AgcQdIJC4AwQSd4BA4g4QSNwBAok7QCBxBwgk7gCBxB0gkLgDBBJ3gEDiDhBI3AECiTtAIHEHCCTuAIHEHSCQuAMEEneAQOIOEEjcAQKJO0AgcQcIJO4AgcQdIJC4AwQSd4BA4g4QSNwBAok7QCBxBwgk7gCBxB0gkLgDBBJ3gEDiDhBI3AECiTtAIHEHCCTuAIHEHSCQuAMEEneAQOIOEEjcAQKJO0AgcQcIJO4Agf4B4NCXWZkcavoAAAAASUVORK5CYII=',
  requirement:
    "<p>​You can earn a commission for marketing or selling a product that is registered in an affiliate program.</p><p>You may look for a product you like, then promote it and earn a piece of the profit from each sale. The sales are tracked via a unique tracking url called an affiliate link.</p><br><p>Step 1: Know your audience and their interests.</p><br><p>Step 2: Create content according to their interests to get their attention. *content should look natural</p><br><p>Step 3:<span>&nbsp;</span><strong>Time your posts during a sale!</strong><span>&nbsp;</span>You may post and use your tracking link multiple times as long as the campaign is ongoing.</p><br><p>Step 4: Don't forget to add your tracking link to your post or content -<span>&nbsp;</span><strong>Lead your audience to your tracking link</strong><span>&nbsp;</span>for them to buy the product.</p><p>This is how you will get conversions!</p><br><p>Step 5: know where you post! -<span>&nbsp;</span><strong>Facebook Feed</strong>,<span>&nbsp;</span><strong>FB Groups</strong>, I<strong>G posts</strong>,<span>&nbsp;</span><strong>IG Stories</strong>,<span>&nbsp;</span><strong>Websites</strong>.<span>&nbsp;</span><strong>You can also share your tracking link with your friend via chat!</strong></p><br><p>Step 6: Place your tracking link to your product recommendations! - you may use<span>&nbsp;</span><strong>AnyCreator Link in Bio!</strong></p>",
  serviceInformation:
    '<div>The XXXX noise cancelling in-ear headphone is the most advanced ever in wireless neckband headphones, thanks to the power of XXXX’s HD Noise Cancelling Processor.</div><div><span style="box-sizing: border-box; -webkit-user-drag: none; overflow: visible; color: inherit; letter-spacing: 0.02em;">So you can lose yourself completely in your music.</span></div>'
};

export const DIMENSIONS = {
  width: 296,
  height: 526,
  rightDistance: 16,
  bottomDistance: 80,
  statusBarHeight: 44
};
