import { useTranslation } from 'react-i18next';
import { Anchor, Notice } from '@/shared/atoms';

export const NoticeUseChromeExtension = () => {
  const { t, i18n } = useTranslation();
  const helpLink =
    i18n.language === 'ja'
      ? 'https://app.gitbook.com/o/-McDui6aOcgUw7qTt8rq/s/-MgEnU0eJny_DDlJSlQa-887967055/infuruens/ekusutenshon'
      : 'https://app.gitbook.com/o/-McDui6aOcgUw7qTt8rq/s/-MgEnU0eJny_DDlJSlQa-1703796690/infuruens/extension';

  return (
    <Notice
      type="info"
      css={{ marginBottom: '16px' }}
      title={t('Use Chrome Extension to fetch profile data')}
      description={
        <>
          {t('Influencer.UseChromeExtension')}&nbsp;
          <Anchor variant="blue" target="_blank" href={helpLink} css={{ display: 'inline', fontWeight: 'inherit' }}>
            {t('Check help page')}
          </Anchor>
        </>
      }
    />
  );
};
