import { UserRoles } from '@/graphql';
import { FormProvider } from '@/shared/atoms';
import { UserLinkedFormSchema } from './schema';
import { Template } from './Template';
import { UserLinkedFormProps } from './types';

export const UserLinkedForm = ({ onSubmit, defaultValues }: UserLinkedFormProps) => (
  <FormProvider
    onSubmit={onSubmit}
    zodSchema={UserLinkedFormSchema}
    defaultValues={{
      advertiserId: defaultValues?.advertiserId || '',
      userType: defaultValues?.userType || UserRoles.STAFF,
      staffIds: [],
      countryId: ''
    }}
  >
    <Template />
  </FormProvider>
);

export * from './types';
