import { useTranslation } from 'react-i18next';
import { AllCampaignsReportSummary } from '@/graphql';
import { ReportSummary as Summary, ReportSummaryType } from '@/shared/atoms';
import { formatIntNumber } from '@/shared/utils';

export interface ReportSummaryProps {
  data?: AllCampaignsReportSummary;
}

export const ReportSummary = ({ data }: ReportSummaryProps) => {
  const { t } = useTranslation();

  const currency = t(data?.currency || '');
  const summaries: ReportSummaryType[] = [
    { label: t<string>('campaign'), value: formatIntNumber(data?.total) },
    { label: t<string>('cost'), value: formatIntNumber(data?.summary?.cost), unit: currency },
    { label: t<string>('engagement'), value: formatIntNumber(data?.summary?.engagement) },
    { label: t<string>('like'), value: formatIntNumber(data?.summary?.like) },
    { label: t<string>('comment'), value: formatIntNumber(data?.summary?.comment) }
  ];

  return <Summary summaries={summaries} />;
};
