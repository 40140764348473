import React, { createContext, useContext } from 'react';
import { useChatBoxToastMessage, useChatBoxUploadFiles } from './hooks';

export type ChatBoxContextType = ReturnType<typeof useChatBoxToastMessage> & ReturnType<typeof useChatBoxUploadFiles>;

const ChatBoxContext = createContext<ChatBoxContextType>({} as ChatBoxContextType);

export interface ChatBoxProviderProps {
  value: Pick<ChatBoxContextType, 'uploadFileConfigs'>;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const ChatBoxProvider = ({ children, value: { uploadFileConfigs } }: ChatBoxProviderProps) => {
  const toastActions = useChatBoxToastMessage();
  const uploadFileActions = useChatBoxUploadFiles({ uploadFileConfigs, setToastMessage: toastActions.setToastMessage });

  return (
    <ChatBoxContext.Provider value={{ ...toastActions, ...uploadFileActions }}>{children}</ChatBoxContext.Provider>
  );
};

export const useChatBoxContext = () => useContext<ChatBoxContextType>(ChatBoxContext);
