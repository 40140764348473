import { InfluencerSponsoredPost, SocialAccountStatus } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerPostDetailsModal } from '@/shared/molecules';
import { TableData } from '@/shared/types';
import { useInfluencerSponsoredPostsListTable } from './hooks';

export interface InfluencerSponsoredPostsListProps extends TableData<InfluencerSponsoredPost> {
  influencerId?: number;
  status?: SocialAccountStatus;
}

export const InfluencerSponsoredPostsList = ({
  status,
  loading,
  data = [],
  influencerId
}: InfluencerSponsoredPostsListProps) => {
  const { rows, columns } = useInfluencerSponsoredPostsListTable({ data, status });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerPostDetailsModal
        posts={data.map(({ id, campaignType }) => ({ id: String(id), influencerId, campaignType }))}
      />
    </>
  );
};
