import { InfluencerNewProfileHeaderV2 } from '@/graphql';
import { InfluencerSelectedAccountInfo } from '../../../types';
import { InfluencerProfilePostsWrapper } from '../../shared';
import { InfluencerThreadsProfileFeedPosts } from './InfluencerThreadsProfileFeedPosts';
import { InfluencerThreadsProfilePostsOverview } from './InfluencerThreadsProfilePostsOverview';

export interface InfluencerThreadsProfilePostsProps {
  socialAccount: InfluencerSelectedAccountInfo;
  influencerProfile: InfluencerNewProfileHeaderV2;
}

export const InfluencerThreadsProfilePosts = ({
  socialAccount,
  influencerProfile
}: InfluencerThreadsProfilePostsProps) => (
  <InfluencerProfilePostsWrapper socialAccount={socialAccount} influencerProfile={influencerProfile}>
    {({ data }) => (
      <>
        <InfluencerThreadsProfilePostsOverview
          data={data}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
        <InfluencerThreadsProfileFeedPosts
          data={data?.feedPosts || []}
          socialAccount={socialAccount}
          influencerProfile={influencerProfile}
        />
      </>
    )}
  </InfluencerProfilePostsWrapper>
);
