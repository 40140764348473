import { DependencyList, MutableRefObject } from 'react';

import { useDeepCompareEffect } from './useDeepCompareEffect';

/**
 * Auto scroll to element's bottom when deps changed.
 * Need to set height and overflow to scroll, if not element's height auto expanded
 * @param ref - Element ref
 * @param deps - Dependency List
 */
export const useScrollElementBottom = <T extends HTMLElement>(
  ref: MutableRefObject<T | null | undefined>,
  deps: DependencyList
) => {
  const scrollToBottom = () => {
    if (ref.current) {
      // We need to reset the height momentarily to get the correct scrollHeight
      ref.current.style.height = '0px';
      const scrollHeight = ref.current.scrollHeight;
      ref.current.style.height = scrollHeight + 'px';
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  useDeepCompareEffect(() => {
    scrollToBottom();
  }, deps);

  return { scrollToBottom };
};
