import { useTranslation } from 'react-i18next';
import { AutoManagedCommissionStatus, AutoManagedCommissionInfluencer } from '@/graphql';
import { Button, Status, CheckBox, RowProps, TableCell, TextCutter, ColumnProps, StatusProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useSelectItemIds } from '@/shared/hooks';
import { InfluencerAvatar } from '@/shared/molecules';
import { ColorVariant } from '@/shared/types';
import { formatPrice } from '@/shared/utils';

type SelectCommissionProps = Pick<
  ReturnType<typeof useSelectItemIds<number>>,
  'selectItem' | 'selectedItemIds' | 'hasAllItemsSelected' | 'toggleSelectAllItems'
>;

interface Props extends SelectCommissionProps {
  data: readonly AutoManagedCommissionInfluencer[];
  onApprove: (influencer: { id: number; name: string }) => void;
}

export const useAutoManagedCommissionsInfluencersTable = ({
  data,
  onApprove,
  selectItem,
  selectedItemIds,
  hasAllItemsSelected,
  toggleSelectAllItems
}: Props) => {
  const { t } = useTranslation();

  const getStatusColumnProps = (status: AutoManagedCommissionStatus): StatusProps => {
    switch (status) {
      case AutoManagedCommissionStatus.APPROVED:
        return { label: t('Option.Approved'), variant: ColorVariant.LIGHT_GREEN };
      case AutoManagedCommissionStatus.UNAPPROVED:
        return { label: t('Option.Not Approved'), variant: ColorVariant.LIGHT_GREY };
      default:
        return { label: '', variant: ColorVariant.LIGHT_GREY };
    }
  };

  const hasAllApproved = data.every((infl) => infl.status === AutoManagedCommissionStatus.APPROVED);
  const columns: ColumnProps[] = [
    {
      title: (
        <div css={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          {!hasAllApproved ? (
            <CheckBox
              size="16px"
              disabled={hasAllApproved}
              onClick={!hasAllApproved ? toggleSelectAllItems : undefined}
              tooltipProps={{ help: t(hasAllItemsSelected ? 'Unselect all' : 'Select all') }}
            />
          ) : null}
          <span>{t('HeaderColumn.Fixed date of commission')}</span>
        </div>
      ),
      sticky: { active: true }
    },
    { title: t('HeaderColumn.Influencer'), styles: { width: '25%' } },
    { title: t('HeaderColumn.Payout'), styles: { textAlign: 'left' } },
    { title: t('HeaderColumn.Status'), styles: { textAlign: 'left' } },
    { title: '' }
  ];

  const rows: RowProps[] = data.map(
    ({ influencerId, currency, endCoverageDate, fixedDate, revenue, startCoverageDate, status, avatar, name }) => {
      const isSelected = selectedItemIds.includes(influencerId);
      const isUnapproved = status === AutoManagedCommissionStatus.UNAPPROVED;

      return [
        <div css={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          {isUnapproved ? (
            <CheckBox
              size="16px"
              checked={isSelected}
              css={{ gap: '16px' }}
              key={`checkbox-${influencerId}`}
              onClick={() => selectItem(influencerId)}
              tooltipProps={{ help: isSelected ? t('Unselect') : t('Select') }}
            />
          ) : null}
          <TableCell.Text
            // BE gives us `yyyy-MM-DD` already
            value={
              <div css={!isUnapproved && !hasAllApproved ? { marginLeft: '32px' } : undefined}>
                <span>{fixedDate}</span>&nbsp;
                <span
                  css={{ fontSize: '11px', color: THEME.text.colors.gray.lv3 }}
                >{`(${startCoverageDate} - ${endCoverageDate})`}</span>
              </div>
            }
          />
        </div>,
        <div css={{ minWidth: '200px', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <InfluencerAvatar size="48px" src={avatar} css={{ backgroundColor: '#EEF3F7' }} id={influencerId} />
          <TextCutter lines={3} text={name} />
        </div>,
        <TableCell.Text value={`${formatPrice(revenue, currency)} ${currency}`} css={{ fontWeight: 600 }} />,
        <Status {...getStatusColumnProps(status)} />,
        isUnapproved ? (
          <Button
            css={{ padding: '16px' }}
            title={t('Button.Approve')}
            onClick={() => onApprove({ id: influencerId, name })}
          />
        ) : null
      ];
    }
  );

  return { rows, columns };
};
