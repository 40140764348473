import { createFileRoute } from '@tanstack/react-router';
import { MenuTitle } from '../../types';
export const tiktokStaticData = {
  menuTitle: MenuTitle.TikTokCampaigns
};
export const Route = createFileRoute('/_private-routes/tiktok')({
  staticData: {
    hasCombinedTerms: true,
    menuTitle: MenuTitle.TikTokCampaigns
  }
});