import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AUTO_MANAGED_COUNTRY_OPTIONS, AUTO_MANAGED_STATUS_OPTIONS } from '@/shared/constants';
import { useAllInfluencerCategoryOptions, useTranslateCountryOptions, useTranslateOptions } from '@/shared/hooks';
import { SelectorField, TextSearchField, BasicFilterWrapper } from '@/shared/molecules';
import { AutoManagedMoreFilter } from './AdvancedFilter';
import { AutoManagedCampaignsFilterFormValues, AutoManagedCampaignsFilterKeys } from './schemaTypes';

interface BasicFilterProps {
  onSubmit?: () => void;
}

export const BasicFilter = ({ onSubmit }: BasicFilterProps) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<AutoManagedCampaignsFilterFormValues>();
  const { allInfluencerCategoryOptions } = useAllInfluencerCategoryOptions();
  const allCountryOptions = useTranslateCountryOptions(AUTO_MANAGED_COUNTRY_OPTIONS);
  const statusOptions = useTranslateOptions(AUTO_MANAGED_STATUS_OPTIONS);

  const values = getValues();
  const numOfValidFilter =
    [
      values.minPrice,
      values.maxPrice,
      values.minCommissionRate,
      values.maxCommissionRate,
      values.marketplaceType
    ].filter((value) => (Array.isArray(value) ? !!value.length : Boolean(value))).length || 0;

  return (
    <BasicFilterWrapper>
      <TextSearchField<AutoManagedCampaignsFilterKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.Search by keyword, brand')}
      />

      <SelectorField<AutoManagedCampaignsFilterKeys>
        name="countryId"
        onSubmit={onSubmit}
        css={{ maxWidth: '160px' }}
        options={allCountryOptions}
        placeholder={t<string>('Selector.Country')}
      />

      <SelectorField<AutoManagedCampaignsFilterKeys>
        name="status"
        onSubmit={onSubmit}
        options={statusOptions}
        css={{ maxWidth: '100px' }}
        placeholder={t<string>('Selector.Status')}
      />

      <SelectorField<AutoManagedCampaignsFilterKeys>
        name="categoryIds"
        onSubmit={onSubmit}
        css={{ maxWidth: '160px' }}
        options={allInfluencerCategoryOptions}
        placeholder={t<string>('Selector.Categories')}
        multiple
        multipleSelectorProps={{
          menuCss: css({
            width: 'max-content',
            maxWidth: '300px',
            marginTop: '4px',
            right: 0
          })
        }}
      />

      <AutoManagedMoreFilter count={numOfValidFilter} onSubmit={onSubmit} />
    </BasicFilterWrapper>
  );
};
