import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectedRangeOption } from '@/shared/types';
import { BasicFilterWrapper, RangeDatePickerField, TextSearchField } from '@/shared/molecules';
import { CampaignPostsDiscoveryFilterFormValues, CampaignPostsDiscoveryFilterFormKeys } from './schemaTypes';
import { AdvancedFilter } from './AdvancedFilter';
import { InfluencersMultiSearchSelector } from './AsyncSearchSelectors';

interface BasicFilterProps {
  onSubmit?: () => void;
}

export const BasicFilter = ({ onSubmit }: BasicFilterProps) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<CampaignPostsDiscoveryFilterFormValues>();

  const values = getValues();

  const isAllTime = !values.startDate && !values.endDate;
  const rangeList = [
    SelectedRangeOption.TODAY,
    SelectedRangeOption.LAST_SEVEN_DAYS,
    SelectedRangeOption.TODAY_TO_MONTH_AGO,
    SelectedRangeOption.THIS_MONTH,
    SelectedRangeOption.LAST_MONTH,
    SelectedRangeOption.CUSTOM,
    SelectedRangeOption.CLEAR
  ];

  return (
    <BasicFilterWrapper>
      <TextSearchField<CampaignPostsDiscoveryFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.Search by Campaign name, Content, Influencer name')}
        css={{ flex: 1 }}
      />
      <InfluencersMultiSearchSelector onSubmit={onSubmit} css={{ width: '184px', flex: 'unset' }} />
      <RangeDatePickerField<CampaignPostsDiscoveryFilterFormKeys>
        onSubmit={onSubmit}
        rangeList={rangeList}
        clearTitle={t('All time')}
        name={{ startDate: 'startDate', endDate: 'endDate' }}
        customInputTextNode={isAllTime ? <p>{t('All time')}</p> : undefined}
        css={{ width: '245px', flex: 0, margin: '0 4px' }}
        disabledRange={{ after: new Date() }}
      />
      <AdvancedFilter onSubmit={onSubmit} />
    </BasicFilterWrapper>
  );
};
