import { useInstagramCompareGetAccountsQuery } from '@/graphql';
import { Notice, RenderDataWithFallback } from '@/shared/atoms';
import { useQueryHelper, useSelectItemIds } from '@/shared/hooks';
import {
  AnalyticsCompareInstagramPosts,
  AnalyticsCompareInstagramOverview,
  AnalyticsCompareInstagramAccounts,
  AnalyticsCompareInstagramAudience,
  AnalyticsCompareInstagramAccountsProps
} from '@/shared/organisms';
import { useAnalyticsProfileContext } from '../../AnalyticsProfileContext';
import { FreePlanContentOpacity } from '../../shared/AnalyticsUpgradePlan';
import { compareMock } from './compare.mock';

interface AnalyticsCompareInstagramProps {
  hasFreePlan: boolean;
}
export const AnalyticsCompareInstagram = ({ hasFreePlan }: AnalyticsCompareInstagramProps) => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { selectedItemIds: hiddenAccountIds, selectItem: setHiddenAccountId } = useSelectItemIds<number>();
  const {
    selectedSocialAccount,
    brandAccount: { brandAccountName, subscriptionPlan }
  } = useAnalyticsProfileContext();
  const { data, loading } = useInstagramCompareGetAccountsQuery({
    fetchPolicy: 'cache-and-network',
    skip: hasFreePlan,
    variables: { accountToCompareWithId: selectedSocialAccount.id },
    onError: (error) => {
      navigate({ to: '/analytics' });
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const availableAccounts = data?.instagramCompareGetAccounts?.accounts || [];
  const allAccounts: AnalyticsCompareInstagramAccountsProps['accounts'] = [
    {
      blocked: false,
      id: selectedSocialAccount.id,
      avatarUrl: selectedSocialAccount.avatar || '',
      profileUrl: selectedSocialAccount.socialMediaUrl || '',
      username: selectedSocialAccount.socialAccountName || ''
    },
    ...availableAccounts
  ];

  const accounts = hasFreePlan ? compareMock.accounts : allAccounts;

  return (
    <RenderDataWithFallback loading={loading}>
      {!hasFreePlan && (
        <Notice type="info" css={{ marginBottom: '24px' }} title={t('Information')} description={t('compareNotice')} />
      )}
      <FreePlanContentOpacity hasFreePlan={hasFreePlan} tab="compare-ig">
        <AnalyticsCompareInstagramAccounts
          accounts={accounts}
          hiddenAccountIds={hiddenAccountIds}
          mainAccountId={selectedSocialAccount.id}
          onSelectAccount={({ id }) => setHiddenAccountId(id)}
          maxAccounts={subscriptionPlan.capabilities.maxHashtags}
        />

        <AnalyticsCompareInstagramOverview
          accounts={accounts}
          hiddenAccountIds={hiddenAccountIds}
          mainAccountId={selectedSocialAccount.id}
          overviewDataMock={compareMock.overview}
          hasFreePlan={hasFreePlan}
        />

        <AnalyticsCompareInstagramAudience
          accounts={accounts}
          hiddenAccountIds={hiddenAccountIds}
          mainAccountId={selectedSocialAccount.id}
          hasFreePlan={hasFreePlan}
          audienceMockData={compareMock.audience}
        />

        <AnalyticsCompareInstagramPosts
          accounts={accounts}
          hiddenAccountIds={hiddenAccountIds}
          brandAccountName={brandAccountName}
          mainAccountId={selectedSocialAccount.id}
          hasFreePlan={hasFreePlan}
          postsMockData={compareMock.posts}
        />
      </FreePlanContentOpacity>
    </RenderDataWithFallback>
  );
};
