// All of SNS icon should be integrated in this component.
// This is an DYNAMIC component used to provide all social media icon with any state that social media has
// Eg: we can easy to change color of simple social icon like Twitter, Youtube...but sometimes we can't.
// Some complicated icon like Instagram, Tiktok, Threads are need to config for each state.
import { IconNames } from '@/shared/assets';
import { SupportedSocialMediaType } from '@/shared/types';
import { Icon, IconProps } from '../../Icon';
import { MediaPreview } from '../../MediaPreview';
import { IconType, Variant } from './types';
import { socialIconConfigs } from './utils';

export interface SocialIconProps extends Omit<IconProps, 'icon' | 'color'> {
  variant?: Variant;
  onClick?: () => void;
  type: keyof typeof SupportedSocialMediaType;
}

export const SocialIcon = ({ size = '16px', type, variant = 'original', ...restProps }: SocialIconProps) => {
  const iconProps = ((): IconType & { icon: IconNames | '' } => {
    let selected;
    switch (type) {
      case 'FACEBOOK':
      case 'FACEBOOK_PAGE':
        selected = socialIconConfigs.FACEBOOK;
        break;
      case 'INSTAGRAM':
      case 'INSTAGRAM_REEL':
        selected = socialIconConfigs.INSTAGRAM;
        break;
      case 'INSTAGRAM_STORY':
      case 'INSTAGRAM_BUSINESS':
        selected = socialIconConfigs.INSTAGRAM_STORY;
        break;
      case 'XHS':
        selected = socialIconConfigs.XHS;
        break;
      default:
        selected = socialIconConfigs[type];
        break;
    }

    return { icon: selected?.icon || '', ...(selected?.variant?.[variant] || {}) };
  })();

  return iconProps?.src ? (
    // This is temporary solution while Icomoon not support the gradient icon, just need to put necessary props
    <MediaPreview
      src={iconProps.src}
      onClick={restProps.onClick}
      className={restProps.className}
      css={{ width: size, height: size, display: 'inline-block', backgroundColor: 'transparent' }}
    />
  ) : (
    <Icon size={size} {...iconProps} {...restProps} />
  );
};
