import { css } from '@emotion/react';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { Fragment, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAllInfluencerManagersQuery } from '@/graphql';
import { Button, FormStyle, Label } from '@/shared/atoms';
import { SelectorField } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { InfluencerManagersFormValues } from './types';

interface InfluencerManagersProps {
  className?: string;
  hasBorder?: boolean;
}

export const InfluencerManagers = ({ className, hasBorder }: InfluencerManagersProps) => {
  const { t } = useTranslation();
  const { data } = useAllInfluencerManagersQuery();
  const { watch, control } = useFormContext<InfluencerManagersFormValues>();
  const { remove, update } = useFieldArray<InfluencerManagersFormValues>({ control, name: 'managerIds' });
  const managerIdsValue = watch('managerIds');
  const [numOfManagers, setNumOfManagers] = useState<number>(managerIdsValue?.length || 1);

  const allInfluencerManagers = data?.allInfluencerManagers || [];
  const influencerManagerOptions: Option[] = sortBy(
    allInfluencerManagers.map((manager) => ({ label: manager.name, value: String(manager.userId) })) || [],
    'label'
  );

  return (
    <FormStyle.Wrapper css={{ padding: '24px' }} className={className} hasBorder={hasBorder}>
      <Label title={t('Manager')} css={{ fontWeight: '600' }} />

      {range(numOfManagers).map((index) => {
        const selectedMangedId = managerIdsValue?.at(index)?.id;
        const selectedManager = allInfluencerManagers.find((manager) => String(manager.userId) === selectedMangedId);
        const filteredOptions = influencerManagerOptions.filter(
          (manager) =>
            !(manager.value !== selectedMangedId && managerIdsValue.findIndex(({ id }) => id === manager.value) !== -1)
        );

        return (
          <Fragment key={index}>
            <SelectorField
              options={filteredOptions}
              name={`managerIds[${index}]`}
              onChange={(_, value) => {
                if (value) {
                  const firstEmptyIndex = managerIdsValue.findIndex((id) => !id);
                  update(firstEmptyIndex === -1 ? index : firstEmptyIndex, { id: value });
                } else {
                  remove(index);
                }
              }}
            />
            {selectedManager && (
              <>
                <div css={styles.infoWrapper}>
                  <p>{`${t('TextForm.Email Address')}:`}</p>
                  <p>{selectedManager.email}</p>
                </div>
                <div css={styles.infoWrapper}>
                  <p>{`${t('TextForm.Phone Number')}:`}</p>
                  <p>{selectedManager.phoneNumber}</p>
                </div>
              </>
            )}
          </Fragment>
        );
      })}
      <Button
        variant="white"
        css={{ marginTop: '8px' }}
        prefixIcon={{ icon: 'plus', size: '10px' }}
        onClick={() => setNumOfManagers((num) => num + 1)}
      />
    </FormStyle.Wrapper>
  );
};
const styles = {
  infoWrapper: css({
    display: 'flex',
    marginTop: '16px',
    '& + &': { marginTop: '8px', paddingBottom: '16px', borderBottom: '1px solid #e4eaf0' },
    p: {
      flex: '50% 0 0',
      lineHeight: 1.5,
      '&:first-of-type': { whiteSpace: 'nowrap' },
      '&:last-of-type': { display: 'inline-flex', wordBreak: 'break-word', justifyContent: 'flex-end' }
    }
  })
};
