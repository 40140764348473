import { RenderDataWithFallback } from '@/shared/atoms';
import { useUpdateDocumentTitle, useYoutubeAnalyticsCountriesCategoriesOptions } from '@/shared/hooks';
import { TrendingYoutubePosts } from './TrendingYoutubePosts';

export const TrendingPosts = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.Trending Posts', appHeader: 'appHeader.Trending Posts', href: '' });
  const { loading, allCountryOptions, allCategoryOptions } = useYoutubeAnalyticsCountriesCategoriesOptions();

  return (
    <RenderDataWithFallback loading={loading}>
      <TrendingYoutubePosts allCountryOptions={allCountryOptions} allCategoryOptions={allCategoryOptions} />
    </RenderDataWithFallback>
  );
};
