import { ReactNode } from 'react';
import { PostDetailsType } from '@/shared/types';
import { getValuableVariables } from '@/shared/utils';
import {
  EngagementPostDetail,
  CampaignSocialMediaType,
  useEngagementDraftPostHistoryQuery,
  useEngagementPostTrafficSourceQuery
} from '@/graphql';
import { Template, TemplateProps } from './Template';
import { useEngagementPostDetailsData, usePostReportSearch } from './hooks';
import { EngagementPostDetailsTabsType } from './types';

export interface EngagementInfluencerPostDetailsProps
  extends Pick<PostDetailsType, 'id'>,
    Pick<TemplateProps, 'hasHistoryTab'> {
  onCloseModal?: () => void;
  hasTrafficSource?: boolean;
  footerNode?: (props: { tab: EngagementPostDetailsTabsType; postDetails?: EngagementPostDetail | null }) => ReactNode;
}

export const EngagementInfluencerPostDetails = ({
  id,
  footerNode,
  onCloseModal,
  hasHistoryTab,
  hasTrafficSource
}: EngagementInfluencerPostDetailsProps) => {
  const { filter } = usePostReportSearch();
  const { details, postDetails, postStatus, loading } = useEngagementPostDetailsData({ id, onCloseModal });
  const { data: trafficSourceData, loading: trafficSourceLoading } = useEngagementPostTrafficSourceQuery({
    skip: !id || !hasTrafficSource || postDetails?.socialMedia !== CampaignSocialMediaType.YOUTUBE, // Only support EGG - YT post for now
    variables: getValuableVariables({ pk: Number(id), endDate: filter.endDate, startDate: filter.startDate })
  });
  const { data, loading: fetchingHistory } = useEngagementDraftPostHistoryQuery({
    skip: !id || !hasHistoryTab,
    variables: { pk: Number(id) },
    fetchPolicy: 'cache-and-network'
  });

  return (
    <Template
      id={id}
      postStatus={postStatus}
      postDetails={postDetails}
      hasHistoryTab={hasHistoryTab}
      historyData={data?.engagementDraftPostHistory}
      loading={loading || fetchingHistory || trafficSourceLoading}
      footerNode={({ tab }) => footerNode?.({ tab, postDetails: details })}
      trafficSourceData={trafficSourceData?.engagementPostTrafficSource || undefined}
    />
  );
};
