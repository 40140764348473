import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { FollowersGrowthPeriodEnum, useTrendingYoutubeInfluencersV2Query } from '@/graphql';
import {
  TrendingInfluencersList,
  TrendingInfluencersTabType,
  useTrendingInfluencersFilter,
  TrendingInfluencerProfileType
} from '@/shared/organisms';

export const TrendingYoutubeInfluencers = () => {
  const { page, limit, filter } = useTrendingInfluencersFilter();
  const { data, loading } = useTrendingYoutubeInfluencersV2Query({
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      limit,
      countryId: filter.countryId,
      offset: getOffset(page, limit),
      categoryIds: filter.categoryIds.map(Number),
      minFollowers: filter.minFollowers ? Number(filter.minFollowers) : null,
      followerGrowthPeriod: filter.followerGrowthPeriod as FollowersGrowthPeriodEnum
    })
  });

  const trendingInfluencers = data?.trendingYoutubeInfluencersV2;
  const totalRecords = trendingInfluencers?.totalNumber;
  const listRecords: TrendingInfluencerProfileType[] =
    trendingInfluencers?.trendingAccounts.map((infl) => ({
      ...infl,
      popularPosts: infl.popularPosts?.map(({ postId, thumbNail, type }) => ({ id: postId, thumbNail, type })) || []
    })) || [];

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <TrendingInfluencersList
        loading={loading}
        data={listRecords}
        totalRecords={totalRecords}
        tab={TrendingInfluencersTabType.YOUTUBE}
      />
    </ListWithPagination>
  );
};
