import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { CampaignPromotionMethod } from '@/graphql';
import { AllTranslationKeys } from '@/shared/assets';

export const ConfirmInfluencersParticipateCampaignSchema = z.object({
  methods: z.array(z.nativeEnum(CampaignPromotionMethod)).min(1, AllTranslationKeys.Error.requiredSocialMediaMessage)
});
export type ConfirmInfluencersParticipateCampaignFormValues = z.infer<
  typeof ConfirmInfluencersParticipateCampaignSchema
>;
export type ConfirmInfluencersParticipateCampaignFormKeys =
  UnionOfKeys<ConfirmInfluencersParticipateCampaignFormValues>;
