import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEngagementPermissions } from '@/auth';
import { SocialAccountType } from '@/graphql';
import { useDeepCompareEffect } from '@/shared/hooks';
import { getEngagementProposalAddRemoveButtonDisableState } from '../utils';
import { useDefaultInfluencerSearchProps } from './useDefaultInfluencerSearchProps';
import { useToggleSelectedSocialInfluencers } from './useToggleSelectedSocialInfluencers';

interface Props {
  socialMedia:
    | 'TIKTOK'
    | 'YOUTUBE'
    | 'TWITTER'
    | 'FACEBOOK'
    | 'INSTAGRAM'
    | 'FACEBOOK_PAGE'
    | 'THREADS'
    | 'DOUYIN'
    | 'XHS';
}
type UnknownObjectType = object & { id: string | number; socialAccountId?: string | number | null; name?: string };

export const useSocialInfluencersSelectButtonsProps = <Profile extends UnknownObjectType>({ socialMedia }: Props) => {
  const { t } = useTranslation();
  const { hideToggleInfluencerFromProposalBtn } = useEngagementPermissions();
  const { toggleSocialMediaSelectedInfluencer } = useToggleSelectedSocialInfluencers();
  const { engagementStatus, showUploadCouponModal, selectedInfluencersMapIds } = useDefaultInfluencerSearchProps();
  const [togglingState, setTogglingState] = useState<{ id: number | null; loading: boolean }>({
    id: null,
    loading: false
  });

  const resetLoadingState = () => {
    setTogglingState({ id: null, loading: false });
  };

  useDeepCompareEffect(() => {
    resetLoadingState();
  }, [selectedInfluencersMapIds]);

  const getSortSelectButtonProps = (listRecords: readonly Profile[]) => {
    const allInfluencerIdsInPage = listRecords.map((infl) => Number(infl.id));
    const selectedAccountsInPage = listRecords.reduce<number[]>((rs, infl) => {
      const inflId = Number(infl.id);
      const socialAccountId = Number(infl.socialAccountId);

      return selectedInfluencersMapIds.get(inflId)?.get(socialMedia)?.includes(socialAccountId) &&
        !rs.includes(socialAccountId)
        ? [...rs, socialAccountId]
        : rs;
    }, []);
    const listRecordsLength = listRecords.length;
    const loading = !togglingState.id && togglingState.loading;
    const numOfSelectedAccountsInPage = selectedAccountsInPage.length;
    const hasAtLeastOneAccountSelectedInPage = numOfSelectedAccountsInPage > 0;
    const hasAllAccountsSelectedInPage = listRecordsLength > 0 && selectedAccountsInPage.length >= listRecordsLength;

    return {
      loading,
      checked: hasAtLeastOneAccountSelectedInPage,
      hasAllSelected: hasAllAccountsSelectedInPage,
      hasAtLeastSelected: hasAtLeastOneAccountSelectedInPage,
      indeterminate: numOfSelectedAccountsInPage !== listRecordsLength,
      onClick: async () => {
        setTogglingState({ id: null, loading: true });
        const { error } = await toggleSocialMediaSelectedInfluencer({
          influencerIds: allInfluencerIdsInPage,
          isChecked: hasAllAccountsSelectedInPage,
          socialAccountMedia: SocialAccountType[socialMedia],
          socialAccountIds: listRecords.map((i) => Number(i.socialAccountId)) || []
        });
        if (error) {
          resetLoadingState();
          showUploadCouponModal(error);
        }
      },
      disabled:
        loading ||
        listRecords.length === 0 ||
        getEngagementProposalAddRemoveButtonDisableState(
          hasAllAccountsSelectedInPage ? 'Remove' : 'Add',
          engagementStatus
        ),
      label: hasAtLeastOneAccountSelectedInPage
        ? t('numberInfluencersAddedToProposal', { count: numOfSelectedAccountsInPage })
        : t('addInfluencersToProposal')
    };
  };

  const getListSelectButtonProps = (profile: Profile) => {
    const id = Number(profile.id);
    const socialAccountId = Number(profile.socialAccountId);
    const socialAccountMedia = SocialAccountType[socialMedia];
    const loading = togglingState.id === id && togglingState.loading;
    const isSelected = selectedInfluencersMapIds?.get(id)?.get(socialAccountMedia)?.includes(socialAccountId) || false;

    return {
      loading,
      checked: isSelected,
      disabled:
        loading || getEngagementProposalAddRemoveButtonDisableState(isSelected ? 'Remove' : 'Add', engagementStatus),
      onClick: async () => {
        setTogglingState({ id, loading: true });
        const { error } = await toggleSocialMediaSelectedInfluencer({
          name: profile.name,
          socialAccountMedia,
          influencerIds: [id],
          isChecked: isSelected,
          socialAccountIds: [socialAccountId]
        });
        if (error) {
          resetLoadingState();
          showUploadCouponModal(error);
        }
      }
    };
  };

  return {
    getSortSelectButtonProps,
    getListSelectButtonProps: !hideToggleInfluencerFromProposalBtn ? getListSelectButtonProps : undefined
  };
};
