import { LinkProps } from '@tanstack/react-router';
import { useMatchPaths } from '@/shared/hooks';

export const useInfluencerAISearchPathnames = () => {
  const { matchPaths } = useMatchPaths();

  const influencerSearchPaths = [
    '/influencers/search',
    '/influencers/search/instagram',
    '/influencers/search/youtube',
    '/influencers/search/tiktok',
    '/influencers/search/douyin',
    '/influencers/search/facebook',
    '/influencers/search/facebook_page',
    '/influencers/search/twitter',
    '/influencers/search/threads'
  ] as const satisfies LinkProps['to'][];
  const engagementInfluencerSearchPaths = [
    '/engagement/$id/influencer',
    '/engagement/$id/influencer/instagram',
    '/engagement/$id/influencer/youtube',
    '/engagement/$id/influencer/tiktok',
    '/engagement/$id/influencer/douyin',
    '/engagement/$id/influencer/facebook',
    '/engagement/$id/influencer/facebook_page',
    '/engagement/$id/influencer/twitter',
    '/engagement/$id/influencer/threads',
    '/engagement/$id/influencer/campaigns',
    '/engagement/$id/influencer/packages',
    '/engagement/$id/influencer/posts'
  ] as const satisfies LinkProps['to'][];
  const isInfluencersSearchPage = matchPaths(influencerSearchPaths);
  const isEngagementSearchPage = matchPaths(engagementInfluencerSearchPaths);
  const supportedInfluencerSearchPaths = [...influencerSearchPaths, ...engagementInfluencerSearchPaths];
  const isSupportedSNS = matchPaths([
    '/influencers/search',
    '/influencers/search/tiktok',
    '/influencers/search/youtube',
    '/influencers/search/instagram',
    '/engagement/$id/influencer',
    '/engagement/$id/influencer/tiktok',
    '/engagement/$id/influencer/youtube',
    '/engagement/$id/influencer/instagram'
  ]);

  return {
    isSupportedSNS,
    isEngagementSearchPage,
    isInfluencersSearchPage,
    influencerSearchPaths,
    engagementInfluencerSearchPaths,
    supportedInfluencerSearchPaths
  };
};
