import { useTranslation } from 'react-i18next';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  InfluencerYoutubeSponsoredPostsList,
  InfluencerYtSponsoredPostsListSearchSchemaType
} from '@/shared/organisms';
import { YoutubeAnalyticsPostsSortInput, useInfluencerYoutubeAnalyticsPostsQuery } from '@/graphql';
import { useInitialInfluencerPostsListWithName } from '../hooks';

export const InfluencerYoutubeSponsoredPosts = () => {
  const { t } = useTranslation();
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName({
    titleWithName: 'appHeader.Sponsored Post List'
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<InfluencerYtSponsoredPostsListSearchSchemaType>();
  const { data, previousData, loading } = useInfluencerYoutubeAnalyticsPostsQuery({
    skip: !influencerId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      orderBy: sort as YoutubeAnalyticsPostsSortInput, // TODO: can BE make orderBy optional?
      endDate: null,
      startDate: null,
      socialAccountId: mainSocialAccountId,
      keyword: filter.keyword,
      sponsoredPostsOnly: true,
      offset: getOffset(page, limit)
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerYoutubeAnalyticsPostsCount.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} />

      <ProfilePostsCount count={totalRecords} countLabel={t('Videos found')} />

      <InfluencerYoutubeSponsoredPostsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.influencerYoutubeAnalyticsPosts?.posts || []}
      />
    </ListWithPagination>
  );
};
