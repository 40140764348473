import { z } from 'zod';
import { MarketplaceDetail } from '@/graphql';
import { FormAction } from '@/shared/types';
import { MarketplaceFormBaseSchema } from './schema';

export enum MarketplaceFormKeys {
  id = 'id',
  title = 'title',
  status = 'status',
  budget = 'budget',
  period = 'period',
  minAge = 'minAge',
  maxAge = 'maxAge',
  genders = 'genders',
  currency = 'currency',
  clickUrl = 'clickUrl',
  hashtags = 'hashtags',
  sampleUrl = 'sampleUrl',
  regionIds = 'regionIds',
  sellerName = 'sellerName',
  countryId = 'countryId',
  materials = 'materials',
  thumbnails = 'thumbnails',
  marginRate = 'marginRate',
  campaignUrl = 'campaignUrl',
  requirement = 'requirement',
  salesPicIds = 'salesPicIds',
  socialMedia = 'socialMedia',
  minFollowers = 'minFollowers',
  maxFollowers = 'maxFollowers',
  advertiser = 'advertiser',
  campaignType = 'campaignType',
  preLaunchDate = 'preLaunchDate',
  landingPageUrl = 'landingPageUrl',
  hubspotDealIds = 'hubspotDealIds',
  trackingOptions = 'trackingOptions',
  requireDraftPost = 'requireDraftPost',
  productThumbnail = 'productThumbnail',
  needCouponsCount = 'needCouponsCount',
  agencyMarginRate = 'agencyMarginRate',
  couponUploadFiles = 'couponUploadFiles',
  serviceInformation = 'serviceInformation',
  allowNewInfluencer = 'allowNewInfluencer',
  campaignCategoryId = 'campaignCategoryId',
  isAllowMultiplePosts = 'isAllowMultiplePosts',
  campaignMaterialLink = 'campaignMaterialLink',
  mkpServiceCampaignId = 'mkpServiceCampaignId',
  influencerCategoryIds = 'influencerCategoryIds',
  additionalRequirement = 'additionalRequirement',
  autoManagedCampaignType = 'autoManagedCampaignType',
  isAutoInfluencerApproval = 'isAutoInfluencerApproval',
  influencerManagementPicIds = 'influencerManagementPicIds',
  maximumRevenuePerInfluencer = 'maximumRevenuePerInfluencer',
  descriptionGenerator_productUrl = 'descriptionGenerator_productUrl',
  descriptionGenerator_websiteUrl = 'descriptionGenerator_websiteUrl',
  descriptionGenerator_additionalInstructions = 'descriptionGenerator_additionalInstructions',
  costPerPost = 'costPerPost',
  costPerLike = 'costPerLike',
  costPerView = 'costPerView',
  costPerOrder = 'costPerOrder',
  costPerShare = 'costPerShare',
  costPerClick = 'costPerClick',
  costPerAction = 'costPerAction',
  costPerComment = 'costPerComment',
  costPerFollower = 'costPerFollower',
  costPerSaleTune = 'costPerSaleTune',
  costPerSaleReferral = 'costPerSaleReferral'
}

export type MarketplaceFormValues = z.infer<typeof MarketplaceFormBaseSchema>;

export interface DefaultFormProps {
  defaultAdvertiser?: MarketplaceDetail['advertiser'];
  data?: MarketplaceDetail | null;
  disabledFields: DisabledMarketplaceFieldsType;
  onSubmit?: FormAction<MarketplaceFormValues>['onSubmit'];
}

export interface DefaultFormTemplateProps {
  onSubmit?: () => void;
  defaultValues: MarketplaceFormValues;
  disabledFields: DisabledMarketplaceFieldsType;
}

export type DisabledMarketplaceFieldsType = Record<MarketplaceFormKeys, boolean>;
