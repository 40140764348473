import { rateToApiFormat, rateToFormFormat } from '@/shared/utils';
import { PostInsightFormKeys, PostInsightFormValues } from '@/shared/organisms';
import {
  UploadEngagementXhsInsightCheckInput,
  EngagementXhsPostMetricsDataForInsight,
  EngagementPostMetricsDataForInsightCheck,
  UploadEngagementReportFromInsightCheckInput
} from '@/graphql';
import { InstagramPostInsightOCR } from './types';

export const getInitialPostInsightFormValues = (
  postData: EngagementPostMetricsDataForInsightCheck | PostInsightFormValues | null,
  xhsPostData?: EngagementXhsPostMetricsDataForInsight | null,
  ocrData?: InstagramPostInsightOCR | null
): PostInsightFormValues => ({
  shares: postData?.shares ?? '',
  stickerTaps: postData?.stickerTaps ?? '',
  newFollowers: postData?.newFollowers ?? xhsPostData?.newFollowers ?? '',
  followerReach: postData?.followerReach ?? '',
  nonFollowerReach: postData?.nonFollowerReach ?? '',
  averageViewDurationHours: postData?.averageViewDurationHours ?? '',
  averageViewDurationMinutes: postData?.averageViewDurationMinutes ?? '',
  impressionsClickThroughRate: rateToFormFormat(postData?.impressionsClickThroughRate),
  audienceRetention: postData?.audienceRetention ? Number(postData?.audienceRetention) * 100 : '',
  // OCR overridden
  views: ocrData?.views ?? postData?.views ?? xhsPostData?.views ?? '',
  likes: ocrData?.likes ?? postData?.likes ?? xhsPostData?.likes ?? '',
  saves: ocrData?.saved ?? postData?.saves ?? xhsPostData?.saves ?? '',
  comments: ocrData?.comments ?? postData?.comments ?? xhsPostData?.comments ?? '',
  reaches: ocrData?.reach ?? postData?.reaches ?? '',
  back: ocrData?.navigation_back ?? postData?.back ?? '',
  exited: ocrData?.navigation_exited ?? postData?.exited ?? '',
  navigation: ocrData?.navigation ?? postData?.navigation ?? '',
  linkClicks: ocrData?.link_clicks ?? postData?.linkClicks ?? '',
  forward: ocrData?.navigation_forward ?? postData?.forward ?? '',
  interaction: ocrData?.interaction ?? postData?.interaction ?? '',
  impressions: ocrData?.impressions ?? postData?.impressions ?? '',
  nextStory: ocrData?.navigation_next_story ?? postData?.nextStory ?? '',
  profileActivity: ocrData?.profile_activity ?? postData?.profileActivity ?? '',
  impressionsFromHome: ocrData?.impressions_from_home ?? postData?.impressionsFromHome ?? '',
  impressionsFromOther: ocrData?.impressions_from_other ?? postData?.impressionsFromOther ?? '',
  impressionsFromProfile: ocrData?.impressions_from_profile ?? postData?.impressionsFromProfile ?? '',
  impressionsFromHashtag: ocrData?.impressions_from_hashtag ?? postData?.impressionsFromHashtag ?? '',
  impressionsFromDiscovery: ocrData?.impressions_from_discovery ?? postData?.impressionsFromDiscovery ?? '',
  // Xhs
  viewsFromHome: xhsPostData?.viewsFromHome ?? '',
  viewsFromOther: xhsPostData?.viewsFromOther ?? '',
  viewsFromSearch: xhsPostData?.viewsFromSearch ?? '',
  viewsFromProfile: xhsPostData?.viewsFromProfile ?? '',
  viewsFromFollowing: xhsPostData?.viewsFromFollowing ?? '',
  malePercentage: rateToFormFormat(xhsPostData?.malePercentage),
  femalePercentage: rateToFormFormat(xhsPostData?.femalePercentage),
  ageUp17Percentage: rateToFormFormat(xhsPostData?.ageUp17Percentage),
  age1824Percentage: rateToFormFormat(xhsPostData?.age1824Percentage),
  age2534Percentage: rateToFormFormat(xhsPostData?.age2534Percentage),
  age3544Percentage: rateToFormFormat(xhsPostData?.age3544Percentage),
  age45UpPercentage: rateToFormFormat(xhsPostData?.age45UpPercentage),
  averageViewDurationSeconds: xhsPostData?.averageViewDurationSeconds ?? ''
});
const formatNullablePayload = (val: PostInsightFormValues[PostInsightFormKeys]) => (val === '' ? null : Number(val));

export const convertPostInsightFormValueToVariables = (
  postId: number,
  formValues: PostInsightFormValues
): UploadEngagementReportFromInsightCheckInput => ({
  postId,
  back: formatNullablePayload(formValues.back),
  views: formatNullablePayload(formValues.views),
  likes: formatNullablePayload(formValues.likes),
  saves: formatNullablePayload(formValues.saves),
  exited: formatNullablePayload(formValues.exited),
  shares: formatNullablePayload(formValues.shares),
  forward: formatNullablePayload(formValues.forward),
  reaches: formatNullablePayload(formValues.reaches),
  comments: formatNullablePayload(formValues.comments),
  nextStory: formatNullablePayload(formValues.nextStory),
  navigation: formatNullablePayload(formValues.navigation),
  linkClicks: formatNullablePayload(formValues.linkClicks),
  stickerTaps: formatNullablePayload(formValues.stickerTaps),
  interaction: formatNullablePayload(formValues.interaction),
  impressions: formatNullablePayload(formValues.impressions),
  newFollowers: formatNullablePayload(formValues.newFollowers),
  followerReach: formatNullablePayload(formValues.followerReach),
  followersCount: formatNullablePayload(formValues.followersCount),
  profileActivity: formatNullablePayload(formValues.profileActivity),
  nonFollowerReach: formatNullablePayload(formValues.nonFollowerReach),
  impressionsFromHome: formatNullablePayload(formValues.impressionsFromHome),
  audienceRetention: formValues.audienceRetention ? Number(formValues.audienceRetention) / 100 : null,
  impressionsFromOther: formatNullablePayload(formValues.impressionsFromOther),
  impressionsFromProfile: formatNullablePayload(formValues.impressionsFromProfile),
  impressionsFromHashtag: formatNullablePayload(formValues.impressionsFromHashtag),
  impressionsFromDiscovery: formatNullablePayload(formValues.impressionsFromDiscovery),
  averageViewDurationHours: formatNullablePayload(formValues.averageViewDurationHours),
  averageViewDurationMinutes: formatNullablePayload(formValues.averageViewDurationMinutes),
  impressionsClickThroughRate: rateToApiFormat(formValues.impressionsClickThroughRate)
});

export const convertPostInsightFormValueToXhsVariables = (
  postId: number,
  formValues: PostInsightFormValues
): UploadEngagementXhsInsightCheckInput => ({
  postId,
  views: formatNullablePayload(formValues.views),
  likes: formatNullablePayload(formValues.likes),
  saves: formatNullablePayload(formValues.saves),
  comments: formatNullablePayload(formValues.comments),
  newFollowers: formatNullablePayload(formValues.newFollowers),
  viewsFromHome: formatNullablePayload(formValues.viewsFromHome),
  viewsFromOther: formatNullablePayload(formValues.viewsFromOther),
  viewsFromSearch: formatNullablePayload(formValues.viewsFromSearch),
  viewsFromProfile: formatNullablePayload(formValues.viewsFromProfile),
  viewsFromFollowing: formatNullablePayload(formValues.viewsFromFollowing),
  malePercentage: rateToApiFormat(formValues.malePercentage),
  femalePercentage: rateToApiFormat(formValues.femalePercentage),
  ageUp17Percentage: rateToApiFormat(formValues.ageUp17Percentage),
  age1824Percentage: rateToApiFormat(formValues.age1824Percentage),
  age2534Percentage: rateToApiFormat(formValues.age2534Percentage),
  age3544Percentage: rateToApiFormat(formValues.age3544Percentage),
  age45UpPercentage: rateToApiFormat(formValues.age45UpPercentage),
  averageViewDurationSeconds: formatNullablePayload(formValues.averageViewDurationSeconds)
});

export const convertOCRDataFromApiToPostInsightOCR = (data: InstagramPostInsightOCR): InstagramPostInsightOCR => ({
  views: data?.views ?? null,
  reach: data?.reach ?? null,
  likes: data?.likes ?? null,
  saved: data?.saved ?? null,
  comments: data?.comments ?? null,
  navigation: data?.navigation ?? null,
  link_clicks: data?.link_clicks ?? null,
  interaction: data?.interaction ?? null,
  impressions: data?.impressions ?? null,
  navigation_back: data?.navigation_back ?? null,
  profile_activity: data?.profile_activity ?? null,
  navigation_exited: data?.navigation_exited ?? null,
  navigation_forward: data?.navigation_forward ?? null,
  navigation_next_story: data?.navigation_next_story ?? null,
  impressions_from_home: data?.impressions_from_home ?? null,
  impressions_from_other: data?.impressions_from_other ?? null,
  impressions_from_profile: data?.impressions_from_profile ?? null,
  impressions_from_hashtag: data?.impressions_from_hashtag ?? null,
  impressions_from_discovery: data?.impressions_from_discovery ?? null
});

export const hasAnyOCRDataValid = (ocrData: InstagramPostInsightOCR | null) => {
  if (!ocrData) {
    return false;
  }

  return Object.keys(ocrData).some((key) => typeof ocrData[key as keyof InstagramPostInsightOCR] === 'number');
};
