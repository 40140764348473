import { useTranslation } from 'react-i18next';
import { Order, UGCPostType, InstagramUGCPostsSortOrder, InstagramUGCManagementBasicInfoPayload } from '@/graphql';
import { useUGCPostOptions } from '../../../hooks';
import { UGCHashtagsType } from '../../../shared';

interface Props {
  basicInfo: InstagramUGCManagementBasicInfoPayload;
}

export const useAnalyticsInstagramUGCPostsFilterOptions = ({ basicInfo }: Props) => {
  const { t } = useTranslation();
  const { statusOptions } = useUGCPostOptions({ hasAllOption: true });

  const hashtagOptions: UGCHashtagsType[] =
    basicInfo?.addedHashtags?.map(({ hashtag, hashtagSystemId }) => ({
      label: hashtag,
      id: String(hashtagSystemId),
      postType: UGCPostType.HASHTAG
    })) || [];
  const sortOptions = [
    {
      order: Order.DESC,
      label: t('Engagement (DESC)'),
      field: InstagramUGCPostsSortOrder.ENGAGEMENT,
      value: `${InstagramUGCPostsSortOrder.ENGAGEMENT}-${Order.DESC}`
    },
    {
      order: Order.ASC,
      label: t('Post Date (ASC)'),
      field: InstagramUGCPostsSortOrder.POST_DATE,
      value: `${InstagramUGCPostsSortOrder.POST_DATE}-${Order.ASC}`
    },
    {
      order: Order.DESC,
      label: t('Post Date (DESC)'),
      field: InstagramUGCPostsSortOrder.POST_DATE,
      value: `${InstagramUGCPostsSortOrder.POST_DATE}-${Order.DESC}`
    }
  ];

  return { hashtagOptions, statusOptions, sortOptions };
};
