import { useInfluencerYoutubeAnalyticsCompareAccountCardsQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useSelectItemIds } from '@/shared/hooks';
import {
  InfluencerCompareYoutubePosts,
  InfluencerCompareYoutubeAccounts,
  InfluencerCompareYoutubeOverview,
  InfluencerCompareYoutubeTagsRanking,
  InfluencerCompareYoutubeAccountsProps,
  InfluencerYoutubeComparableAccountType
} from '@/shared/organisms';

interface InfluencerProfileCompareYoutubeProps {
  compareAccount: InfluencerYoutubeComparableAccountType;
}
export const InfluencerProfileCompareYoutube = ({ compareAccount }: InfluencerProfileCompareYoutubeProps) => {
  const { selectedItemIds: hiddenAccountIds, selectItem: setHiddenAccountId } = useSelectItemIds<number>();
  const { data, loading } = useInfluencerYoutubeAnalyticsCompareAccountCardsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { socialAccountId: compareAccount.id }
  });

  const youtubeAccounts = data?.influencerYoutubeAnalyticsCompareAccountCards;
  const allAccounts: InfluencerCompareYoutubeAccountsProps['accounts'] = (() => {
    if (!youtubeAccounts) return [];

    const mainAccount = youtubeAccounts?.mainAccount;
    const compareAccounts =
      youtubeAccounts?.compareAccounts.map<InfluencerYoutubeComparableAccountType>((account) => ({
        blocked: false,
        id: account.accountId,
        avatarUrl: account.avatarUrl,
        username: account.channelName,
        profileUrl: account.profileUrl
      })) || [];

    return [
      {
        blocked: false,
        id: mainAccount?.socialAccountId || compareAccount.id,
        avatarUrl: mainAccount?.avatarUrl || compareAccount.avatarUrl || '',
        profileUrl: mainAccount?.profileUrl || compareAccount.profileUrl || '',
        username: mainAccount?.channelName || compareAccount.username || ''
      },
      ...compareAccounts
    ];
  })();

  return (
    <RenderDataWithFallback loading={loading}>
      <InfluencerCompareYoutubeAccounts
        maxAccounts={5}
        accounts={allAccounts}
        mainAccountId={compareAccount.id}
        hiddenAccountIds={hiddenAccountIds}
        onSelectAccount={({ id }) => setHiddenAccountId(id)}
      />

      <InfluencerCompareYoutubeOverview
        accounts={allAccounts}
        mainAccountId={compareAccount.id}
        hiddenAccountIds={hiddenAccountIds}
      />

      <InfluencerCompareYoutubeTagsRanking
        accounts={allAccounts}
        mainAccountId={compareAccount.id}
        hiddenAccountIds={hiddenAccountIds}
      />

      <InfluencerCompareYoutubePosts
        accounts={allAccounts}
        mainAccount={compareAccount}
        hiddenAccountIds={hiddenAccountIds}
      />
    </RenderDataWithFallback>
  );
};
