import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestUserProposalExcel.graphql';
import { Mutation, MutationrequestUserProposalExcelArgs } from '../../__generated__/globalTypes';

export const useRequestUserProposalExcelMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestUserProposalExcelArgs>
) => {
  const [callRequestUserProposalExcel, result] = useMutation<Mutation, MutationrequestUserProposalExcelArgs>(
    MUTATION,
    options
  );

  return { callRequestUserProposalExcel, ...result };
};

export type RequestUserProposalExcelMutationFunction = Mutation['requestUserProposalExcel'];
