import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateEngagementCouponList.graphql';
import { Mutation, MutationupdateEngagementCouponListArgs } from '../../__generated__/globalTypes';

export const useUpdateEngagementCouponListMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateEngagementCouponListArgs>
) => {
  const [callUpdateEngagementCouponList, result] = useMutation<Mutation, MutationupdateEngagementCouponListArgs>(
    MUTATION,
    options
  );

  return { callUpdateEngagementCouponList, ...result };
};

export type UpdateEngagementCouponListMutationFunction = Mutation['updateEngagementCouponList'];
