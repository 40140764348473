import { useTranslation } from 'react-i18next';
import { ListEmpty, ListEmptyProps } from '@/shared/atoms';

export type PostReportEmptyProps = ListEmptyProps;

export const PostReportEmpty = (props: PostReportEmptyProps) => {
  const { t } = useTranslation();

  return (
    <ListEmpty
      title={t('General.No report data has been found')}
      description={t('General.Please try a different query or wait the data is reflected')}
      {...props}
    />
  );
};
