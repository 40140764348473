import { IconNames } from '@/shared/assets';

export enum SpreadButtonIcon {
  flag = 'flag',
  copy = 'copy',
  edit = 'edit',
  more = 'more',
  post = 'post',
  chart = 'chart',
  delete = 'delete'
}

export const getSpreadButtonIcon = (
  icon?: keyof typeof SpreadButtonIcon
):
  | {
      icon: IconNames;
      size: string;
    }
  | undefined => {
  switch (icon) {
    case SpreadButtonIcon.more:
      return { icon: 'more-horizontal', size: '9px' };
    case SpreadButtonIcon.edit:
      return { icon: 'pen', size: '12px' };
    case SpreadButtonIcon.flag:
      return { icon: 'flag', size: '12px' };
    case SpreadButtonIcon.post:
      return { icon: 'post', size: '12px' };
    case SpreadButtonIcon.delete:
      return { icon: 'trash', size: '12px' };
    case SpreadButtonIcon.chart:
      return { icon: 'chart', size: '12px' };
    case SpreadButtonIcon.copy:
      return { icon: 'copy', size: '12px' };
    default:
      return undefined;
  }
};
