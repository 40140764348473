import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/OpenAiMessage.graphql';
import { Query, QueryopenAiMessageArgs } from '../../__generated__/globalTypes';

export const useOpenAiMessageQuery = (options?: QueryHookOptions<Query, QueryopenAiMessageArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryopenAiMessageArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useOpenAiMessageLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryopenAiMessageArgs>
): QueryResult<Query, QueryopenAiMessageArgs> & {
  getOpenAiMessage: LazyQueryExecFunction<Query, QueryopenAiMessageArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getOpenAiMessage, result] = useLazyQuery<Query, QueryopenAiMessageArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getOpenAiMessage, ...result };
};

export const useOpenAiMessagePromiseQuery = () => {
  const client = useApolloClient();

  const getOpenAiMessage = useCallback(
    (options?: Omit<QueryOptions<QueryopenAiMessageArgs, Query>, 'query'>) =>
      client.query<Query, QueryopenAiMessageArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getOpenAiMessage };
};
