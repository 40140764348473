import { useTranslation } from 'react-i18next';
import { DownloadButton } from '@/shared/atoms';
import { useAllCampaignPostsQuery } from '@/graphql';
import { Header, ListWithPagination } from '@/shared/molecules';
import { getOffset, getCampaignsIdentifierFromUrlString } from '@/shared/utils';
import { useCustomHeader, useUpdateDocumentTitle, useToggle } from '@/shared/hooks';
import {
  CampaignPostsDiscoveryList,
  CampaignPostsDiscoveryFilter,
  useCampaignPostsDiscoveryFilter
} from '@/shared/organisms';

export const CampaignPostsDiscovery = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    title: 'appHeader.Campaign Posts Discovery',
    appHeader: 'appHeader.Campaign Posts Discovery'
  });
  const { t } = useTranslation();
  const csvModal = useToggle();
  const {
    page,
    sort,
    limit,
    filter: {
      keyword,
      endDate,
      startDate,
      campaigns,
      countryIds,
      categoryIds,
      influencerIds,
      campaignTypes,
      advertiserIds,
      socialPostTypes
    }
  } = useCampaignPostsDiscoveryFilter();
  const { data, previousData, loading } = useAllCampaignPostsQuery({
    variables: {
      limit,
      keyword,
      countryIds,
      campaignTypes,
      socialPostTypes,
      orderBy: sort,
      offset: getOffset(page, limit),
      maxPostDate: endDate || undefined,
      minPostDate: startDate || undefined,
      categoryIds: categoryIds.map(Number),
      advertiserIds: advertiserIds.map(Number),
      influencerIds: influencerIds.map(Number),
      campaigns: getCampaignsIdentifierFromUrlString(campaigns)
    }
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allCampaignPosts.totalCount || 0;

  return (
    <>
      <Header>
        <DownloadButton title={t('CSV Download')} css={{ marginLeft: 'auto' }} onClick={csvModal.setOn} />
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <CampaignPostsDiscoveryFilter />
        <CampaignPostsDiscoveryList
          loading={loading}
          open={csvModal.on}
          onClose={csvModal.setOff}
          totalRecords={totalRecords}
          data={result?.allCampaignPosts.posts}
          summary={result?.allCampaignPosts.summary}
        />
      </ListWithPagination>
    </>
  );
};
