import { TiktokSpecialStatsItemRow, TiktokSpecialInfluencerReport, TiktokSpecialSummaryDaily } from '@/graphql';
import {
  TikTokCampaignReportStats,
  TikTokCampaignReportTableStatKeys,
  InfluencerPostReportStats,
  TikTokCampaignReportSegmentData,
  TikTokCampaignReportSegmentDataType
} from './types';

export const DEFAULT_TIKTOK_CAMPAIGN_COLUMNS: TikTokCampaignReportTableStatKeys[] = [
  'cost',
  'followers',
  'engagement',
  'engagementRate',
  'views',
  'adImpressions',
  'adReach',
  'likes',
  'comments',
  'shares'
];

export const getPostReportStats = (summaries?: TiktokSpecialStatsItemRow | null): TikTokCampaignReportStats => ({
  cost: summaries?.cost ?? null,
  views: summaries?.views ?? null,
  likes: summaries?.likes ?? null,
  shares: summaries?.shares ?? null,
  adLikes: summaries?.adLikes ?? null,
  adReach: summaries?.adReach ?? null,
  adShares: summaries?.adShares ?? null,
  comments: summaries?.comments ?? null,
  followers: summaries?.followers ?? null,
  engagement: summaries?.engagement ?? null,
  adComments: summaries?.adComments ?? null,
  adReachRate: summaries?.adReachRate ?? null,
  costsPerLike: summaries?.costsPerLike ?? null,
  costsPerView: summaries?.costsPerView ?? null,
  adImpressions: summaries?.adImpressions ?? null,
  costsPerShare: summaries?.costsPerShare ?? null,
  engagementRate: summaries?.engagementRate ?? null,
  costsPerComment: summaries?.costsPerComment ?? null,
  costsPerFollower: summaries?.costsPerFollower ?? null,
  videoPlayActions: summaries?.videoPlayActions ?? null,
  adImpressionsRate: summaries?.adImpressionsRate ?? null,
  costsPerEngagement: summaries?.costsPerEngagement ?? null,
  engagementViewsRate: summaries?.engagementViewsRate ?? null
});

export const getInfluencerPostReportStats = (influencerReports: readonly TiktokSpecialInfluencerReport[]) =>
  influencerReports?.map<InfluencerPostReportStats>(
    ({ summary, postInfo, influencerInfo }) => ({
      influencerDetails: {
        id: influencerInfo.id,
        name: influencerInfo.name,
        thumbNail: influencerInfo.thumbNail,
        post: postInfo
          ? {
              id: postInfo.id,
              url: postInfo.postUrl,
              content: postInfo.content,
              thumbNail: postInfo.thumbNail || ''
            }
          : undefined
      },
      stats: getPostReportStats(summary)
    }),
    []
  ) || [];
/**
 * since table column cannot respect min-width - we need to explicitly set width on it's parts
 * */
export const POST_REPORT_STICKY_COL_DIMS = {
  totalWidth: '20vw',
  totalMinWidth: '350px',
  influencerProfileWidth: '40%',
  postCaptionLinkWidth: '58%' // (100% - influencerProfileWidth) to have some gap between profile section and post section, but also align on the vertical line if post is alone in the row
};

// Format segment data
export const formatTikTokCampaignReportSegmentTotal = (data?: readonly TiktokSpecialSummaryDaily[] | null) =>
  data?.map<TikTokCampaignReportSegmentDataType>(({ date, stats }) => ({ date, stats: getPostReportStats(stats) })) ||
  [];

export const formatTikTokCampaignReportSegmentInfluencerDaily = (
  data?: readonly TiktokSpecialInfluencerReport[] | null
): TikTokCampaignReportSegmentData['post'] =>
  data?.reduce<TikTokCampaignReportSegmentData['post']>(
    (results, { summaryDaily, influencerInfo: { id } }) => ({
      ...results,
      [id]:
        summaryDaily?.map<TikTokCampaignReportSegmentDataType>(({ date, stats }) => ({
          date,
          stats: getPostReportStats(stats)
        })) || []
    }),
    {}
  ) || null;
