import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateAgencyUser.graphql';
import { Mutation, MutationcreateAgencyUserArgs } from '../../__generated__/globalTypes';

export const useCreateAgencyUserMutation = (options?: MutationHookOptions<Mutation, MutationcreateAgencyUserArgs>) => {
  const [callCreateAgencyUser, result] = useMutation<Mutation, MutationcreateAgencyUserArgs>(MUTATION, options);

  return { callCreateAgencyUser, ...result };
};

export type CreateAgencyUserMutationFunction = Mutation['createAgencyUser'];
