import { useTranslation } from 'react-i18next';
import Dropzone, { Accept, DropEvent, FileRejection } from 'react-dropzone';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';

export interface DropAreaProps {
  name: string;
  width: string;
  accept?: Accept;
  multiple?: boolean;
  className?: string;
  onDrop: (acceptedFiles: File[], rejectedFiles?: FileRejection[], event?: DropEvent) => void;
}

export const AddMoreItem = ({ name, width, accept, onDrop, multiple, className }: DropAreaProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper(width)} className={className}>
      <Dropzone multiple={multiple} accept={accept} onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div css={styles.dropzoneContent} {...getRootProps()}>
            <div css={{ height: 0, paddingTop: '100%', position: 'relative' }}>
              <p css={styles.label}>{t('Add File')}</p>
              <input name={name} {...getInputProps()} />
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};
const styles = {
  wrapper: (width: string) =>
    css({
      padding: '8px',
      cursor: 'pointer',
      boxSizing: 'border-box',
      maxWidth: width,
      flexBasis: width
    }),
  dropzoneContent: css({
    width: '100%',
    borderRadius: '2px',
    boxSizing: 'border-box',
    border: '2px dashed #dee5ec',
    transition: 'border-color 0.3s ease',
    '&:hover': { borderColor: '#3892e5' }
  }),
  label: css({
    top: '50%',
    left: '50%',
    fontSize: '11px',
    lineHeight: '15px',
    position: 'absolute',
    whiteSpace: 'nowrap',
    color: THEME.text.colors.blue,
    transform: 'translate(-50%, -50%)'
  })
};
