import { Navigate } from '@tanstack/react-router';
import { css } from '@emotion/react';
import { Icon } from '@/shared/atoms';
import { IconNames } from '@/shared/assets';
import { useQueryHelper } from '@/shared/hooks';
import { THEME } from '@/shared/constants';
import { useChatDemoContext } from './ChatDemoProvider';
import { ChatDemoHeader, ChatDemoInput, ChatDemoHistory } from './shared';

export const ChatDemoHome = () => {
  const { t, navigate } = useQueryHelper();
  const { threadId, addNewMessages } = useChatDemoContext();

  if (!threadId) {
    return <Navigate to="/chat-demo/chat" replace />;
  }

  const items: Array<{ icon: IconNames; title: string }> = [
    { icon: 'chat-demo-hat', title: 'How do you help me?' },
    { icon: 'chat-demo-bulb', title: 'Can you find the influencer who is suitable to promote the cosmetic brand?' },
    { icon: 'chat-demo-pen', title: 'Can you draft the campaign description for ' },
    { icon: 'chat-demo-terminal', title: 'Please give me the deep insight for this campaign!' }
  ];

  return (
    <>
      <ChatDemoHeader />

      <div css={styles.wrapper}>
        <div css={{ width: '648px' }}>
          <h2 css={{ textAlign: 'center', fontSize: '24px', fontWeight: 500, lineHeight: '18px' }}>
            {t('What do you wanna do?')}
          </h2>
          <p css={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, lineHeight: '18px', marginTop: '24px' }}>
            {t('Please let us know how we can help you')}
          </p>

          <ChatDemoInput
            submitIcon={{ icon: 'arrow-right' }}
            css={{ height: '88px', borderRadius: '12px', marginTop: '24px' }}
            onSubmit={(message) => {
              if (message) {
                // Don't use await here. We need to navigate to the chat page and display the conversation together
                navigate({ to: '/chat-demo/chat' });
                addNewMessages([{ position: 'right', text: message }]);
              }
            }}
          />

          <div css={styles.itemsWrapper}>
            {items.map((item, index) => (
              <div key={`intro-${index}`} css={{ padding: '8px', flexBasis: '50%', boxSizing: 'border-box' }}>
                <div
                  css={styles.item}
                  key={`intro-${index}`}
                  onClick={() => {
                    // Don't use await here. We need to navigate to the chat page and display the conversation together
                    navigate({ to: '/chat-demo/chat' });
                    addNewMessages([{ position: 'right', text: item.title }]);
                  }}
                >
                  <Icon icon={item.icon} size={18} />
                  <p>{item.title}</p>
                </div>
              </div>
            ))}
          </div>

          <ChatDemoHistory css={{ marginTop: '24px' }} />
        </div>
      </div>
    </>
  );
};

const styles = {
  wrapper: css({
    height: '100%',
    display: 'flex',
    padding: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box'
  }),
  itemsWrapper: css({
    margin: '-8px',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '24px',
    boxSizing: 'border-box'
  }),
  item: css({
    gap: '16px',
    height: '72px',
    display: 'flex',
    cursor: 'pointer',
    padding: '0 24px',
    borderRadius: '8px',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  })
};
