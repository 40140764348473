import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchMode } from '@/graphql';
import { ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { AUTO_MANAGED_COUNTRY_OPTIONS } from '@/shared/constants';
import { useTranslateCountryOptions } from '@/shared/hooks';
import { SelectorField, TextField } from '../RHFFields';
import { CustomShopeeCampaignsFormValues, CustomShopeeCampaignsFormValuesKeys } from './types';

interface CustomShopeeCampaignFormProps {
  loading: boolean;
  toggleStatus: () => void;
}
export const CustomShopeeCampaignForm = ({ loading, toggleStatus }: CustomShopeeCampaignFormProps) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { isSubmitting }
  } = useFormContext<CustomShopeeCampaignsFormValues>();
  const allAvailableAutoManagedCountriesTranslated = useTranslateCountryOptions(AUTO_MANAGED_COUNTRY_OPTIONS);

  const values = getValues();
  const { searchMode, countryId, keyword, itemId } = values;

  const isKeywordSearch = searchMode === SearchMode.KEYWORD_SEARCH;
  const isFilled = searchMode && countryId ? (isKeywordSearch ? keyword : itemId) : false;

  return (
    <>
      <ModalContent>
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <ModalTitle>{t('Pull campaigns by offer title from Shopee')}</ModalTitle>
          <SelectorField<CustomShopeeCampaignsFormValuesKeys>
            name="countryId"
            title={t('Selector.Country')}
            options={allAvailableAutoManagedCountriesTranslated}
            required
          />
          <SelectorField<CustomShopeeCampaignsFormValuesKeys>
            name="searchMode"
            required
            title={t('Selector.Search type')}
            options={[
              {
                value: SearchMode.KEYWORD_SEARCH,
                label: t<string>('Option.Keyword')
              },
              {
                value: SearchMode.ITEM_SEARCH,
                label: t<string>('Option.Item ID')
              }
            ]}
          />
          {isKeywordSearch ? (
            <TextField<CustomShopeeCampaignsFormValuesKeys>
              name="keyword"
              title={t('TextForm.Full offer title')}
              placeholder={t<string>('Input keyword')}
              required
            />
          ) : (
            <TextField<CustomShopeeCampaignsFormValuesKeys>
              name="itemId"
              title={t('Input item ID')}
              placeholder={t<string>('Item ID')}
              required
            />
          )}
        </div>
        <p css={{ marginBottom: '24px' }}>
          {t(
            isKeywordSearch
              ? '*We fetch offer by exact match, please input full offer title'
              : '*We will fetch offer by Item ID, please copy and input it here'
          )}
        </p>
        <p>
          {t('After you click [Run], please wait for around 5 mins')}&nbsp;
          {t(`If the offer you wanted didn't pulled, try again`)}
        </p>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: toggleStatus }}
        submitButtonProps={{
          type: 'submit',
          loading: isSubmitting || loading,
          title: t('Button.Run'),
          disabled: !isFilled || isSubmitting || loading
        }}
      />
    </>
  );
};
