import { MenuTitle } from '@/router';
import { MenuGroup, MenuType, UserPermissions } from '@/shared/types';

export const getAllowMenuTitlesFromPermission = (permissions?: UserPermissions | null) => {
  const result: MenuTitle[] = [];

  if (!permissions) {
    return result;
  }

  (Object.keys(permissions) as Array<keyof UserPermissions>).forEach((key) => {
    switch (key) {
      case 'report':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Report);
        }
        break;
      case 'marketplace':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Marketplace);
        }
        break;
      case 'engagement':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Engagement);
        }
        break;
      case 'influencer':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Influencers, MenuTitle.Posts);
        }
        break;
      case 'influencerPackage':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Packages);
        }
        break;
      case 'analytics':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Analytics);
        }
        break;
      case 'partnerAgency':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Partner);
        }
        break;
      case 'talentAgency':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.TalentAgency);
        }
        break;
      case 'agency':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Agency);
        }
        break;
      case 'advertiser':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Advertiser);
        }
        break;
      case 'user':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.User);
        }
        break;
      case 'payment':
        if (permissions[key] && !permissions[key]?.noView) {
          result.push(MenuTitle.Payment);
        }
        break;
      default:
        break;
    }
  });

  // We have some menu items don't related to permission api, so we put them in result as default
  result.push(
    // TODO: We might need permission api update, same with egg and mkp
    // https://stg-app.anytag.jp/user/74685
    // Remove MenuTitle.AutoManaged from here and update switch case above
    MenuTitle.AutoManaged,
    MenuTitle.TikTokCampaigns,
    MenuTitle.CampaignPosts,
    MenuTitle.TrendingPosts,
    MenuTitle.TrendingInfluencers,
    MenuTitle.Settings,
    MenuTitle.ReleaseNotes,
    MenuTitle.HelpCenter
  );

  return result;
};

export interface GetAllMenuItemsProps {
  isAdvertiser?: boolean;
}
export const getAllMenuItems = ({ isAdvertiser }: GetAllMenuItemsProps) =>
  [
    {
      title: MenuTitle.Report,
      groupTitle: MenuGroup.Campaigns,
      to: '/report',
      subMenus: [
        { title: MenuTitle.Marketplace, to: '/marketplace' },
        { title: MenuTitle.Engagement, to: '/engagement' },
        { title: MenuTitle.AutoManaged, to: '/auto-managed' },
        { title: MenuTitle.TikTokCampaigns, to: '/tiktok/campaigns' },
        { title: MenuTitle.CampaignPosts, to: '/campaign-posts-discovery' }
      ]
    },
    ...((!isAdvertiser
      ? [
          {
            title: MenuTitle.Influencers,
            groupTitle: MenuGroup.Influencers,
            to: '/influencers',
            subMenus: [
              { title: MenuTitle.Posts, to: '/posts' },
              { title: MenuTitle.Packages, to: '/package' }
            ]
          }
        ]
      : [{ title: MenuTitle.Packages, to: '/package' }]) satisfies MenuType[]),
    {
      groupTitle: MenuGroup.Trends,
      title: MenuTitle.TrendingInfluencers,
      to: '/trending/influencers',
      subMenus: [{ title: MenuTitle.TrendingPosts, to: '/trending/posts' }]
    },
    { title: MenuTitle.Analytics, to: '/analytics' },
    {
      title: MenuTitle.Advertiser,
      groupTitle: MenuGroup.Accounts,
      to: '/advertiser',
      subMenus: [
        { title: MenuTitle.Partner, to: '/partner' },
        { title: MenuTitle.TalentAgency, to: '/talent-agency' },
        { title: MenuTitle.Agency, to: '/agency' }
      ]
    },
    { title: MenuTitle.User, to: '/user' },
    { title: MenuTitle.Payment, to: '/payments' },
    { title: MenuTitle.Settings, to: '/settings' },
    { title: MenuTitle.ReleaseNotes },
    { title: MenuTitle.HelpCenter }
  ] satisfies MenuType[];
