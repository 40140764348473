import { SC } from './StyledComponents';

export const PrivacyEN = () => {
  const links = {
    app: (
      <SC.Link target="_blank" variant="inherit" href="https://app.anytag.jp/">
        https://app.anytag.jp/
      </SC.Link>
    ),
    anymind: (
      <SC.Link target="_blank" variant="inherit" href="mailto:info@anymindgroup.com">
        info@anymindgroup.com
      </SC.Link>
    ),
    anytag: (
      <SC.Link target="_blank" variant="inherit" href="mailto:info@anytag.com">
        info@anytag.com
      </SC.Link>
    ),
    dpo: (
      <SC.Link target="_blank" variant="inherit" href="mailto:dpo@anymindgroup.com">
        dpo@anymindgroup.com
      </SC.Link>
    )
  };

  return (
    <SC.Wrapper>
      <SC.Title>PRIVACY POLICY FOR ANYTAG PLATFORM</SC.Title>
      <br />

      <SC.Text>
        AnyMind Group Pte. Ltd. (“AnyMind”, "us", "we", or "our") operates "AnyTag"&nbsp;
        {links.app}&nbsp;(the "AnyTag Service"). We care about your rights and we acknowledge that your data is yours.
        Please take your time to have a look through our privacy policy. This Privacy Policy of AnyTag will help you
        understand what personal data we collect, why we collect it, how we protect your personal data, how long we keep
        your personal data, and what are your rights. If you have any questions please do not hesitate to contact us by
        sending an email to&nbsp;{links.anymind}.
      </SC.Text>

      <SC.Head>1. WHAT PERSONAL DATA DO WE COLLECT AND HOW DO WE COLLECT IT?</SC.Head>
      <SC.Text>
        Personal data means any information that can be used to identify directly or indirectly a specific individual.
        We collect your personal data for the purpose of using our service, AnyMind as a data controller here to inform
        you of all information you may need to know about using our services.
      </SC.Text>
      <SC.Text>
        You might refuse to provide us your personal data, but if you choose not to do so, we will not be able to
        provide you the AnyTag Service, but you may at any time choose to opt-out by simply sending us an email to&nbsp;
        {links.anytag}.
      </SC.Text>
      <SC.Text>
        We collect personal data as follows:
        <SC.ListWrapper>
          <SC.ListItem>
            Name and surname of your person in charge and name and surname of authorized directors (in case where we
            provide AnyTag Service to juristic persons) or your name and surname (in case where we provide AnyTag
            Service to natural persons); and
          </SC.ListItem>
          <SC.ListItem>
            Telephone number and email of your person in charge (in case where we provide AnyTag Service to juristic
            persons) or your telephone number and email (in case where we provide AnyTag Service to natural persons).
          </SC.ListItem>
        </SC.ListWrapper>
      </SC.Text>

      <SC.Head>2. WHY DO WE COLLECT YOUR DATA?</SC.Head>
      <SC.Text>
        We collect your data to respond to your inquiry, handle any complaints, to develop and improve our services,
        communication methods and the functionality of our websites, to provide personalised services, communications,
        to provide you with requested marketing materials, newsletters and also use it for applications enhancement.
      </SC.Text>

      <SC.Head>3. WHO DO WE SHARE YOUR PERSONAL DATA AND NON-PERSONAL DATA WITH?</SC.Head>
      <SC.Text>
        We know that your personal data is confidential therefore, except as set forth in the Privacy Policy of AnyTag,
        your personal data will never be shared with third parties without your consent. We may pass your personal data
        to our subsidiaries and affiliated companies so that they may contact you in connection with the objective of
        your inquiry or propose to you the run-on campaigns. Where we pass your personal data to our subsidiary or
        affiliated companies in another country, we will make sure that they apply the same level of protection to your
        information as we do. We may also disclose your information to third parties in the event of suspected fraud,
        legal requirement or in connection with our vendors and contractors, all as described below.
      </SC.Text>
      <SC.Text>
        We do not sell, rent, or share your personal data collected on our or other websites to non-affiliated third
        parties.
      </SC.Text>
      <SC.Text>
        We may disclose your personal data to third parties when we reasonably believe we are obligated to do so by law,
        and in order to investigate, prevent, or take action regarding suspected or actual prohibited activities,
        including, but not limited to, fraud and situations involving potential threats to the physical safety of any
        person. In addition and if permitted by applicable law, we may also disclose and provide access to your personal
        data and non personal data to our vendors and contractors who are performing services on our behalf. However,
        such vendors and contractors are not permitted to use such user information for any purpose other than providing
        services to us.
      </SC.Text>

      <SC.Head>4. HOW DO WE PROTECT YOUR PERSONAL DATA?</SC.Head>
      <SC.Text>
        We follow generally accepted industry standards to protect against unauthorized access to, retention of, and
        disclosure of information. This includes undertaking necessary physical, electronic, and management activities
        required to protect information integrity, access, and use. Any information that is stored on our server is
        treated as confidential information. Please keep in mind, however, that despite these reasonable efforts to
        protect information on our servers, no method of transmission over the Internet is guaranteed to be secure.
        Therefore, while we strive to protect your information at all times, we cannot guarantee its absolute security.
      </SC.Text>

      <SC.Head>5. WHERE DO WE KEEP YOUR PERSONAL DATA?</SC.Head>
      <SC.Text>
        We keep your personal data in our database which is held by Google Cloud, only assigned software developers
        which are our employees shall have the permission to edit/check the production environment, such permission is
        managed by Google Cloud Platform and Amazon Web Services. We, every time we update, test our system to ensure
        the security of personal data protection.
      </SC.Text>

      <SC.Head>6. HOW LONG DO WE KEEP YOUR PERSONAL DATA?</SC.Head>
      <SC.Text>
        We will keep your personal data until you require to opt- out or delete your personal data from our website.
      </SC.Text>

      <SC.Head>7. WHAT ARE YOUR RIGHTS?</SC.Head>
      <SC.Text>
        Your rights for your personal data are as follows:
        <SC.ListWrapper>
          <SC.ListItem>
            <b>RIGHT TO BE INFORMED;</b> You have the right to be provided with clear, transparent and easily
            understandable information about how we use your personal data and your rights. Therefore, we’re providing
            you with the information in this Privacy Policy of AnyTag. We also will inform you if we collect your
            personal data for other purposes rather than what we described above.
          </SC.ListItem>
          <SC.ListItem>
            <b>RIGHT TO ACCESS / RIGHT TO RECTIFY;</b> You always have the right to access, correct or update your
            personal data at any time. We understand the importance of this and should you want to exercise your rights,
            please contact us at {links.anymind}
          </SC.ListItem>
          <SC.ListItem>
            <b>RIGHT TO DATA PORTABILITY;</b> The personal data you have provided us with may be portable. This means it
            can be moved, copied or transmitted electronically under certain circumstances.
          </SC.ListItem>
          <SC.ListItem>
            <b>RIGHT TO OBJECT;</b> you have the right to object to certain types of processing, including processing
            for direct marketing (i.e., receiving emails from us notifying you or being contacted with varying potential
            opportunities).
          </SC.ListItem>
          <SC.ListItem>
            <b>RIGHT TO ERASURE / RIGHT TO BE FORGOTTEN;</b> You have the right to request that we delete your data. If
            you wish to delete the personal data we hold about you, please let us know and we will take reasonable steps
            to respond to your request in accordance with legal requirements. If the personal data we collect is no
            longer needed for any purposes and we are not required by law to retain it, we will do what we can to
            delete, destroy or permanently de-identify it.
          </SC.ListItem>
          <SC.ListItem>
            <b>RIGHT TO RESTRICT PROCESSING;</b> you have the right to restrict the processing of your personal data.
          </SC.ListItem>
          <SC.ListItem>
            <b>RIGHT TO WITHDRAW THE CONSENT;</b> you have the right to withdraw your consent that you gave to us
            anytime by contacting us at&nbsp;
            {links.anymind}. or&nbsp;
            {links.dpo}, we will take reasonable steps to respond to your request in accordance with legal requirements.
          </SC.ListItem>
        </SC.ListWrapper>
      </SC.Text>

      <SC.Head>8. CHANGES TO THIS PRIVACY POLICY</SC.Head>
      <SC.Text>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
        Policy on this page.You are advised to review this Privacy Policy periodically for any changes. Changes to this
        Privacy Policy are effective when they are posted on this page.
      </SC.Text>

      <SC.Head>9. HOW TO CONTACT US?</SC.Head>
      <SC.Text>
        If you have any questions about this Privacy Policy, please contact us at #04-03 Anson House, 72 Anson Rd,
        Singapore 079911, Singapore or via email at&nbsp;
        {links.anymind}
        &nbsp;or&nbsp;
        {links.dpo}.
      </SC.Text>

      <br />
      <br />
      <SC.Text>Enacted on January 1, 2025</SC.Text>
    </SC.Wrapper>
  );
};
