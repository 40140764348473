import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  useSendBcaRequestMutation,
  useRequestedBcaBrandsQuery,
  useReSendBcaRequestMutation,
  useSelectableBcaBrandsQuery,
  namedOperations
} from '@/graphql';
import { Button, CircularProgress, FormStyle, Label, Status } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { SelectorField, TextField } from '@/shared/molecules';
import { BrandContentPermissionsFormValues } from './types';
import { EMPTY_BRAND_CONTENT_FIELD, getBrandContentPermissionsStatusInfo } from './utils';

interface BrandContentPermissionsProps {
  className?: string;
  hasBorder?: boolean;
  influencerId: number;
}
export const BrandContentPermissions = ({
  className,
  influencerId,
  hasBorder = true
}: BrandContentPermissionsProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const { control, watch } = useFormContext<BrandContentPermissionsFormValues>();
  const { fields, append, replace } = useFieldArray<BrandContentPermissionsFormValues, 'brandContentPermission'>({
    name: 'brandContentPermission',
    control
  });

  const lastFieldArrayIndex = (fields?.length || 1) - 1;
  const brandContentPermissionValue = watch('brandContentPermission');

  const { data: requestedData, loading: requestedLoading } = useRequestedBcaBrandsQuery({
    variables: { influencerId },
    onCompleted: ({ requestedBcaBrands }) => {
      replace(
        requestedBcaBrands?.map((requestedBcaBrand) => ({
          instagram_account_id: requestedBcaBrand.instagramAccount.id.toString(),
          instagram_account_name: requestedBcaBrand.instagramAccount.name,
          brand_id: requestedBcaBrand.brand.id.toString(),
          status: requestedBcaBrand.status,
          isResendable: requestedBcaBrand.isResendable,
          // disable for all data that get from requestedBcaBrands API
          // https://adasiaholdings.slack.com/archives/C02C5J5B7KL/p1692780907914519
          disabled: true
        })) || []
      );
    }
  });
  const { data } = useSelectableBcaBrandsQuery({
    variables: { influencerId: Number(influencerId) },
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const { callSendBcaRequest, loading: sendLoading } = useSendBcaRequestMutation({
    refetchQueries: [namedOperations.Query.RequestedBcaBrands]
  });
  const { callReSendBcaRequest, loading: reSendLoading } = useReSendBcaRequestMutation({
    refetchQueries: [namedOperations.Query.RequestedBcaBrands]
  });

  useEffect(() => {
    /**
     * we need to wait and see if this account doesn't have any requestedBcaBrands
     * then if there is just 1 selectableBcaBrands?.instagramAccounts - we need to pre-fill "Instagram Account" field and use TextFormField instead of Selector
     * if we have more IG accounts and no pre-selected data - we need to create an empty fieldArray entry
     *  */
    if (requestedData?.requestedBcaBrands?.length === 0 && brandContentPermissionValue?.length === 0) {
      if (data?.selectableBcaBrands?.instagramAccounts.length === 1) {
        append(
          {
            ...EMPTY_BRAND_CONTENT_FIELD,
            instagram_account_id: data.selectableBcaBrands.instagramAccounts.at(0)?.id.toString() || '',
            instagram_account_name: data.selectableBcaBrands.instagramAccounts.at(0)?.name || ''
          },
          { shouldFocus: false }
        );
      } else if ((data?.selectableBcaBrands?.instagramAccounts.length || 0) > 1) {
        append(EMPTY_BRAND_CONTENT_FIELD);
      }
    }
  }, [
    requestedData?.requestedBcaBrands?.length,
    data?.selectableBcaBrands?.instagramAccounts.length,
    brandContentPermissionValue?.length
  ]);

  const handleSendPermissionRequest = async (fieldArrayIndex: number, isReSend?: boolean) => {
    try {
      if (brandContentPermissionValue?.[fieldArrayIndex]) {
        const variables = {
          input: {
            brandIgId: Number(brandContentPermissionValue.at(fieldArrayIndex)?.brand_id || ''),
            creatorIgId: Number(brandContentPermissionValue.at(fieldArrayIndex)?.instagram_account_id || '')
          }
        };
        if (isReSend) {
          await callReSendBcaRequest({ variables });
        } else {
          await callSendBcaRequest({ variables });
        }
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleAddBrandContentFormField = () => {
    // in case of the only one instagramAccount from selectableBcaBrands - we must pre-fill it, because our BE relation in that case is one-to-many
    if (data?.selectableBcaBrands?.instagramAccounts.length === 1) {
      append(
        {
          ...EMPTY_BRAND_CONTENT_FIELD,
          instagram_account_id: data.selectableBcaBrands.instagramAccounts.at(0)?.id?.toString() || '',
          instagram_account_name: data.selectableBcaBrands.instagramAccounts.at(0)?.name || ''
        },
        { shouldFocus: false }
      );
    } else {
      append(EMPTY_BRAND_CONTENT_FIELD, { shouldFocus: false });
    }
  };

  const loading = requestedLoading || sendLoading || reSendLoading;

  return data?.selectableBcaBrands?.isBcaAvailable ? (
    <FormStyle.Wrapper css={{ padding: '24px', position: 'relative' }} hasBorder={hasBorder} className={className}>
      {loading && (
        <div css={styles.loadingOverlay}>
          <CircularProgress size={48} thickness={0.5} backgroundColor="#dee5ec" css={{ margin: 'auto' }} />
        </div>
      )}
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Label title={t('Brand content permission request')} css={{ textTransform: 'none' }} />
        {/* hidden due to https://adasiaholdings.atlassian.net/browse/AT-5028 */}
        {/* <Anchor variant="blue" target="_blank" css={{ whiteSpace: 'nowrap' }}>
          {t('Help page')}
        </Anchor> */}
      </div>
      {fields.map((brandContentPermission, index) => {
        const { message, variant } = getBrandContentPermissionsStatusInfo(brandContentPermission.status);

        return (
          <div key={index}>
            {(data.selectableBcaBrands?.instagramAccounts.length || 0) > 1 ? (
              <SelectorField
                title={t('TextForm.Instagram Account')}
                name={`brandContentPermission.${index}.instagram_account_id`}
                options={data.selectableBcaBrands?.instagramAccounts.map((o) => ({
                  value: o.id.toString(),
                  label: o.name
                }))}
                css={{ marginTop: '8px' }}
                disabled={brandContentPermission.disabled}
              />
            ) : (
              <TextField
                title={t('TextForm.Instagram Account')}
                name={`brandContentPermission.${index}.instagram_account_name`}
                disabled={brandContentPermission.disabled}
                css={{ pointerEvents: 'none' }}
                autoFocus={false}
              />
            )}
            <SelectorField
              name={`brandContentPermission.${index}.brand_id`}
              title={t('Selector.Brand')}
              options={data.selectableBcaBrands?.brands.map((o) => ({ value: o.id.toString(), label: o.name }))}
              singleSelectorProps={{ menuPlacement: 'auto' }}
              disabled={brandContentPermission.disabled}
            />
            {brandContentPermission?.status ? (
              <div css={{ display: 'flex', alignItems: 'baseline', marginTop: '8px' }}>
                <Label title={t('Label.Status')} css={{ width: 'max-content' }} />
                <Status variant={variant} label={t(`Option.${message}`)} css={{ marginLeft: '16px' }} />

                {brandContentPermission?.isResendable ? (
                  <span
                    onClick={() => handleSendPermissionRequest(index, true)}
                    css={{ color: '#3892E5', fontSize: '12px', fontWeight: 400, marginLeft: 'auto' }}
                  >
                    {t('Resend request')}
                  </span>
                ) : null}
              </div>
            ) : null}
          </div>
        );
      })}
      {brandContentPermissionValue?.[lastFieldArrayIndex]?.status === null ? (
        <Button
          title={t('Button.Send')}
          variant="blue"
          disabled={
            !brandContentPermissionValue?.at(lastFieldArrayIndex)?.instagram_account_id ||
            !brandContentPermissionValue?.at(lastFieldArrayIndex)?.brand_id ||
            loading
          }
          onClick={() => handleSendPermissionRequest(lastFieldArrayIndex)}
          css={{ marginLeft: 'auto', marginTop: '8px', minWidth: '80px' }}
        />
      ) : (
        <Button
          title={t('Button.Add Brand')}
          variant="default"
          onClick={handleAddBrandContentFormField}
          css={{ marginLeft: 'auto', marginTop: '8px', minWidth: '80px' }}
        />
      )}
    </FormStyle.Wrapper>
  ) : null;
};

const styles = {
  loadingOverlay: css({
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1000,
    backgroundColor: THEME.background.colors.white,
    opacity: 0.5
  })
};
