import { css } from '@emotion/react';
import { SeriesColumnOptions, SeriesLineOptions, SeriesOptionsType } from 'highcharts';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import {
  NoDataContentWrapper,
  NoDataContentWrapperProps,
  ChartTooltipMultipleMetrics,
  ChartTooltipMultipleMetricsProps,
  ChartTooltipMultipleMetricsSeriesDataType
} from '@/shared/atoms';
import { HAS_NO_SELECTED_ACCOUNTS } from '@/shared/constants';
import { HighChart, Highcharts } from '@/shared/molecules';
import { AppLanguage } from '@/shared/types';
import { generateYAxisBasedOnDisplayedCharts, localizedDateFormatter } from '@/shared/utils';

type SeriesColumnOptionsOrLineOptions = SeriesColumnOptions | SeriesLineOptions;
type AccountType<T extends string> = ChartTooltipMultipleMetricsProps<T>['account'];
type SeriesDataType<T extends string> = Record<
  T,
  SeriesColumnOptionsOrLineOptions & ChartTooltipMultipleMetricsSeriesDataType
>;

export interface AnalyticsCompareOverviewChartProps<T extends string>
  extends Pick<ChartTooltipMultipleMetricsProps<T>, 'categories' | 'customTooltip'>,
    Pick<NoDataContentWrapperProps, 'wrapperText'> {
  customOptions?: Highcharts.Options;
  data: { account: AccountType<T>; data: SeriesDataType<T> }[];
}

export const AnalyticsCompareOverviewChart = <T extends string>({
  data,
  categories,
  customTooltip,
  customOptions,
  wrapperText = HAS_NO_SELECTED_ACCOUNTS
}: AnalyticsCompareOverviewChartProps<T>) => {
  const { i18n } = useTranslation();

  const { keys, series, tooltipData } = data.reduce<{
    keys: string[];
    series: SeriesOptionsType[];
    tooltipData: { colors: string[]; account: AccountType<T>; data: SeriesDataType<T> }[];
  }>(
    (acc, { account, data }) => {
      if (!acc.keys.length && data) {
        acc.keys = Object.keys(data);
      }
      const seriesData = Object.values<SeriesColumnOptionsOrLineOptions>(data);
      acc.series = [...acc.series, ...seriesData];
      acc.tooltipData.push({ colors: seriesData.map((series) => series.color as string), data, account });

      return acc;
    },
    { keys: [], series: [], tooltipData: [] }
  );

  const options: Highcharts.Options = {
    series,
    title: { text: '' },
    chart: { type: 'column' },
    legend: { enabled: false },
    yAxis: generateYAxisBasedOnDisplayedCharts(keys, false),
    plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
    xAxis: {
      categories,
      crosshair: true,
      type: 'category',
      labels: {
        formatter() {
          return localizedDateFormatter(this.value, 'MMM do', i18n.language as AppLanguage);
        }
      }
    },
    tooltip: {
      useHTML: true,
      shadow: false,
      outside: true,
      borderRadius: 3,
      borderColor: '#27313b',
      formatter() {
        const selectedTooltipData = tooltipData.find((item) => item.colors.includes(this.color as string));

        return selectedTooltipData
          ? renderToStaticMarkup(
              <ChartTooltipMultipleMetrics
                categories={categories}
                selectedCategory={this.key as string}
                customTooltip={customTooltip}
                data={selectedTooltipData.data}
                account={selectedTooltipData.account}
              />
            )
          : '';
      }
    }
  };

  return (
    <NoDataContentWrapper
      wrapperText={wrapperText}
      dataNotAvailable={!categories.length || !data.length}
      css={css({ borderRadius: 0, boxShadow: 'none', borderBottom: '1px solid #dee5ef' })}
    >
      <HighChart options={{ ...options, ...customOptions }} />
    </NoDataContentWrapper>
  );
};
