import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SudoLimitedLogin.graphql';
import { Mutation, MutationsudoLimitedLoginArgs } from '../../__generated__/globalTypes';

export const useSudoLimitedLoginMutation = (options?: MutationHookOptions<Mutation, MutationsudoLimitedLoginArgs>) => {
  const [callSudoLimitedLogin, result] = useMutation<Mutation, MutationsudoLimitedLoginArgs>(MUTATION, options);

  return { callSudoLimitedLogin, ...result };
};

export type SudoLimitedLoginMutationFunction = Mutation['sudoLimitedLogin'];
