import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { CampaignType, MarketplaceCampaignReport } from '@/graphql';
import { RowProps, TableCell } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { usePostDetailsParam } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { formatPrice, formatIntNumber, formatPercent, formatNumber } from '@/shared/utils';
import { usePostReportContext } from '../../PostReportContext';
import { InfluencerPost } from '../InfluencerPost';
import { Post } from '../Post';
import { PostReportSegment, PostReportStats } from '../types';
import { POST_REPORT_STICKY_COL_DIMS, getInfluencerPostReportStats, getPostReportStats } from '../utils';
import { usePostReportColumns } from './usePostReportColumns';

interface Props {
  segment: PostReportSegment;
  data: MarketplaceCampaignReport;
}

export const usePostReportTable = ({ data, segment }: Props) => {
  const { t } = useTranslation();
  const { campaignType } = usePostReportContext();
  const { columns } = usePostReportColumns({ data });
  const { setPostDetailsParam } = usePostDetailsParam();

  const { summary, average, postCount, influencerReports } = data;
  const currency = t(data.currency || '');
  const isEngagement = campaignType === CampaignType.ENGAGEMENT;
  const influencerPostReportStats = getInfluencerPostReportStats(influencerReports);

  const getStatCells = (rowStats?: PostReportStats) => [
    <TableCell.Number unit={rowStats?.cost ? currency : ''} value={formatPrice(rowStats?.cost || null, currency)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.followers)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.followerReach)} />,
    <TableCell.Number value={formatPercent(rowStats?.followerReachRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.newFollowers)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressions)} />,
    <TableCell.Number value={formatPercent(rowStats?.impressionRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromDiscovery)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromHashtag)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromHome)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromProfile)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.impressionsFromOther)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.totalPlayTime)} />,
    <TableCell.Number value={formatNumber(rowStats?.averageViewTime, 2)} />,
    <TableCell.Number value={formatPercent(rowStats?.videoCompletionRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.view)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.averageViewDurationMinutes)} unit="m" />,
    <TableCell.Number value={formatPercent(rowStats?.averageViewDurationPercentage, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.viewsFromHome)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.viewsFromProfile)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.viewsFromSearch)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.viewsFromFollowing)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.viewsFromOther)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.reach)} />,
    <TableCell.Number value={formatPercent(rowStats?.reachRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.nonFollowerReach)} />,
    <TableCell.Number value={formatPercent(rowStats?.nonFollowerReachRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.engagement)} />,
    <TableCell.Number value={formatPercent(rowStats?.engagementRate, false)} unit="%" />,
    <TableCell.Number value={formatPercent(rowStats?.engagementViewsRate, false)} unit="%" />,
    <TableCell.Number value={formatIntNumber(rowStats?.like)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.dislikes)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.comment)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.share)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.retweets)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.saved)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.interaction)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.linkClicks)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.stickerTaps)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.click)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.conversion)} />,
    <TableCell.Number value={formatPrice(rowStats?.sales || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPercent(rowStats?.femalePercentage, false)} unit="%" />,
    <TableCell.Number value={formatPercent(rowStats?.malePercentage, false)} unit="%" />,
    <TableCell.Number value={formatPercent(rowStats?.age1824Percentage, false)} unit="%" />,
    <TableCell.Number value={formatPercent(rowStats?.age2534Percentage, false)} unit="%" />,
    <TableCell.Number value={formatPercent(rowStats?.age35UpPercentage, false)} unit="%" />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerSale || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerFollower || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerImpressions || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerView || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerReach || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerEngagement || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerLike || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerComment || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerShare || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerSaved || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerClick || null, currency)} unit={currency} />,
    <TableCell.Number value={formatPrice(rowStats?.costsPerAction || null, currency)} unit={currency} />,
    <TableCell.Number value={formatIntNumber(rowStats?.back)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.exited)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.forward)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.nextStory)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.navigation)} />,
    <TableCell.Number value={formatIntNumber(rowStats?.profileActivity)} />,
    <TableCell.Date value={rowStats?.insightDataAcquisition || null} />
  ];

  const totalRows: RowProps[] = [
    {
      styles: styles.totalRow,
      cells: [
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: POST_REPORT_STICKY_COL_DIMS.totalMinWidth
          }}
        >
          {t('HeaderColumn.Total')}
          <span>
            {postCount}&nbsp;
            <span css={{ textTransform: 'uppercase', fontWeight: 'normal', fontSize: '10px' }}>{t('Post')}</span>
          </span>
        </div>,
        ...getStatCells(getPostReportStats(summary))
      ]
    },
    {
      styles: styles.averageRow,
      cells: [
        <div css={{ minWidth: POST_REPORT_STICKY_COL_DIMS.totalMinWidth }}>{t('Average')}</div>,
        ...getStatCells(getPostReportStats(average))
      ]
    },
    ...(segment.total?.data.map<RowProps>((row) => ({
      styles: styles.totalDailyRow,
      cells: [<div css={{ textAlign: 'right' }}>{row.date}</div>, ...getStatCells(row.stats)]
    })) || [])
  ];
  const otherRows = influencerPostReportStats.reduce<RowProps[]>(
    (results, { influencerDetails: { id, name, thumbNail, posts }, stats }) => {
      let influencerPostRows: RowProps[] = [];
      const influencerDailyData = segment.influencer?.data && segment.influencer.data[id];
      const influencerRows: RowProps[] = [
        [
          <InfluencerPost id={id} name={name} posts={posts} thumbNail={thumbNail} isEngagement={isEngagement} />,
          ...getStatCells(stats)
        ],
        ...(influencerDailyData?.map((dailyData) => ({
          styles: styles.dailyRow,
          cells: [<div css={{ textAlign: 'right' }}>{dailyData.date}</div>, ...getStatCells(dailyData.stats)]
        })) || [])
      ];

      if (posts && posts.length > 1) {
        influencerPostRows = posts.reduce<RowProps[]>((postResults, { details, stats: postStats }) => {
          const influencerPostDailyData = segment.post?.data && segment.post.data[details.id];
          const postRows: RowProps[] = [
            [
              <Post
                url={details.url}
                content={details.content}
                isEngagement={isEngagement}
                thumbNail={details.thumbNail}
                socialMedia={details.socialMedia}
                hasTrafficSource={details.hasTrafficSource}
                onClickPost={() => setPostDetailsParam(details.id)}
              />,
              ...getStatCells(postStats)
            ],
            ...(influencerPostDailyData?.map((dailyData) => ({
              styles: styles.dailyRow,
              cells: [<div css={{ textAlign: 'right' }}>{dailyData.date}</div>, ...getStatCells(dailyData.stats)]
            })) || [])
          ];

          return [...postResults, ...postRows];
        }, []);
      }

      return [...results, ...influencerRows, ...influencerPostRows];
    },
    []
  );

  return {
    columns,
    rows: [...totalRows, ...otherRows],
    allPosts: influencerPostReportStats.reduce<PostDetailsType[]>(
      (acc, { influencerDetails: { id: influencerId, posts } }) =>
        posts?.length
          ? [...acc, ...posts.map(({ details }) => ({ id: String(details.id), influencerId, campaignType }))]
          : acc,
      []
    )
  };
};

const styles = {
  totalRow: css({
    height: '32px',
    pointerEvents: 'none',
    '> td': {
      fontWeight: 600,
      fontSize: '14px',
      backgroundColor: THEME.table.header.background.colors.lv2,
      '> *': { fontSize: 'inherit' }
    }
  }),
  averageRow: css({
    height: '32px',
    borderTop: 'none',
    pointerEvents: 'none',
    '> td': {
      fontWeight: 600,
      fontSize: '12px',
      backgroundColor: THEME.table.header.background.colors.lv2,
      color: THEME.text.colors.gray.lv3,
      '> *': { fontSize: 'inherit' }
    }
  }),
  totalDailyRow: css({
    height: '36px',
    pointerEvents: 'none',
    '> td': { backgroundColor: THEME.table.header.background.colors.lv2 }
  }),
  dailyRow: css({
    height: '36px',
    pointerEvents: 'none',
    '> td': { backgroundColor: THEME.background.colors.gray.lv1 }
  })
};
