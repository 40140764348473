import { useInfluencerProfileContext } from '../../InfluencerProfileContext';
import {
  InfluencerFacebookProfilePosts,
  InfluencerFacebookProfileOverview,
  InfluencerFacebookProfileAudience
} from '../../InfluencerProfiles';
import { InfluencerSelectedAccountInfo } from '../../types';

export interface InfluencerFacebookProfileProps {
  socialAccount: InfluencerSelectedAccountInfo;
}
export const InfluencerFacebookProfile = ({ socialAccount }: InfluencerFacebookProfileProps) => {
  const { influencerProfile } = useInfluencerProfileContext();

  return (
    <>
      <InfluencerFacebookProfileOverview influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerFacebookProfileAudience influencerId={influencerProfile.id} socialAccount={socialAccount} />
      <InfluencerFacebookProfilePosts influencerProfile={influencerProfile} socialAccount={socialAccount} />
    </>
  );
};
