import { useTranslation } from 'react-i18next';
import { linkOptions } from '@tanstack/react-router';
import { useTranslateCountry } from '@/shared/hooks';
import { AnalyticsAuthSocialAccountType } from '@/graphql';
import { capitalizeText } from '@/shared/utils';
import { SpreadButton } from '@/shared/molecules';
import { ColumnProps, CustomSocialIcon, RowProps, TableCell } from '@/shared/atoms';
import { ANALYTICS_SUBSCRIPTION_TRANSLATION_KEYS } from '@/shared/constants';
import { useUserRoles, useAnalyticsPermissions, useAdvertiserPermissions } from '@/auth';
import { AnalyticsSupportedSocialMediaType, ReconnectAnalyticsAccountType } from '@/shared/types';
import { AnalyticsAccountType } from '../types';
import { AnalyticsProfile } from '../AnalyticsProfile';

interface Props {
  data: readonly AnalyticsAccountType[];
  onDelete: (account: { id: number; name: string }) => void;
  onReconnectAccount: (account: ReconnectAnalyticsAccountType) => void;
}

export const useAnalyticsListTable = ({ data, onDelete, onReconnectAccount }: Props) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();
  const { hideEditAdvertiserBtn } = useAdvertiserPermissions();
  const { isAdminStaff, isAdminStaffAgencyTalentAgencyPartner } = useUserRoles();
  const { hideEditAnalyticsBtn, hideDeleteAnalyticsBtn } = useAnalyticsPermissions();

  const hideEditColumn = hideEditAnalyticsBtn && hideDeleteAnalyticsBtn;
  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Account') },
    {
      title: t('HeaderColumn.Edit'),
      styles: { textAlign: 'left', minWidth: '60px', paddingLeft: '12px' },
      hidden: hideEditColumn
    },
    { title: t('HeaderColumn.Social Media Analytics') },
    { title: t('HeaderColumn.Advertiser'), hidden: !isAdminStaffAgencyTalentAgencyPartner },
    { title: t('HeaderColumn.Plan') },
    { title: t('HeaderColumn.PIC'), hidden: !isAdminStaffAgencyTalentAgencyPartner },
    { title: t('HeaderColumn.Country'), hidden: !isAdminStaffAgencyTalentAgencyPartner }
  ];
  const rows: RowProps[] = data.map(
    ({
      id: brandAccountId,
      avatar,
      advertiser,
      accountName,
      accountManagers,
      subscriptionPlan,
      socialMediaAccounts: { instagram, youtube, facebookPage, twitter }
    }) => {
      const accounts: ReconnectAnalyticsAccountType[] = [
        {
          id: instagram?.id,
          needReconnect: instagram?.needReconnect,
          socialAccountId: instagram?.businessAccountId,
          socialAccountName: instagram?.instagramAccountName,
          socialMedia: AnalyticsSupportedSocialMediaType.instagram,
          socialAccountType: AnalyticsAuthSocialAccountType.INSTAGRAM,
          socialMediaUrl: `https://instagram.com/${instagram?.instagramAccountName}`,
          linkedAccount: instagram?.fbPageId
            ? {
                socialAccountId: instagram.fbPageId,
                socialAccountName: instagram.fbPageName,
                socialAccountType: AnalyticsAuthSocialAccountType.FACEBOOK_PAGE
              }
            : undefined
        },
        {
          id: youtube?.id,
          socialAccountId: youtube?.channelId,
          needReconnect: youtube?.needReconnect,
          socialAccountName: youtube?.channelName,
          socialMedia: AnalyticsSupportedSocialMediaType.youtube,
          socialAccountType: AnalyticsAuthSocialAccountType.YOUTUBE,
          socialMediaUrl: `https://youtube.com/channel/${youtube?.channelId}/`
        },
        {
          id: facebookPage?.id,
          socialAccountId: facebookPage?.fbPageId,
          needReconnect: facebookPage?.needReconnect,
          socialAccountName: facebookPage?.fbPageName,
          socialMediaUrl: facebookPage?.pageUrl || '',
          socialMedia: AnalyticsSupportedSocialMediaType.facebook,
          socialAccountType: AnalyticsAuthSocialAccountType.FACEBOOK_PAGE
        },
        {
          id: twitter?.id,
          socialAccountName: twitter?.name,
          needReconnect: twitter?.needReconnect,
          socialAccountId: twitter?.twitterUserId,
          socialMedia: AnalyticsSupportedSocialMediaType.twitter,
          socialAccountType: AnalyticsAuthSocialAccountType.TWITTER,
          socialMediaUrl: `https://twitter.com/${twitter?.username}`
        }
      ];
      const hasMoreButton = isAdminStaff && (!hideDeleteAnalyticsBtn || !hideEditAnalyticsBtn);
      const firstActiveAccount = accounts.find((item) => !!item.id);
      const profileUrl = firstActiveAccount
        ? linkOptions({
            to: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard',
            params: {
              brandAccountId: brandAccountId.toString(),
              socialMedia: firstActiveAccount.socialMedia,
              socialMediaAccountId: firstActiveAccount.id?.toString() as string
            }
          })
        : '';

      const analyticsDetailsLink = linkOptions({
        to: '/analytics/$brandAccountId',
        params: { brandAccountId: brandAccountId.toString() }
      });

      return [
        <div css={{ display: 'flex', alignItems: 'center', height: '80px' }}>
          <AnalyticsProfile {...profileUrl} src={avatar} size="40px" css={{ borderRadius: '50%' }} />
          <TableCell.Link lines={2} {...profileUrl} label={accountName} css={{ marginLeft: '16px' }} />
        </div>,
        hasMoreButton ? (
          <SpreadButton
            collapseFrom={0}
            options={[
              {
                icon: 'edit',
                label: t('Edit'),
                ...analyticsDetailsLink,
                disabled: hideEditAnalyticsBtn,
                tooltip: { help: t('Button.Edit') }
              },
              {
                icon: 'delete',
                label: t('Delete'),
                hidden: !(isAdminStaff && !hideDeleteAnalyticsBtn),
                onClick: () => onDelete({ id: brandAccountId, name: accountName })
              }
            ]}
            css={{ '.single-button, .menu-button': { height: '32px', width: '32px' } }}
          />
        ) : !hideEditAnalyticsBtn ? (
          <SpreadButton
            options={[
              {
                icon: 'edit',
                label: t('Edit'),
                ...analyticsDetailsLink,
                tooltip: { help: t('Button.Edit') }
              }
            ]}
            css={{ '.single-button': { height: '32px', width: '32px' } }}
          />
        ) : (
          <TableCell.Text />
        ),
        <div css={{ display: 'flex', gap: '8px' }}>
          {accounts.map((account, index) => {
            const { id, socialAccountType, socialMediaUrl, needReconnect, socialMedia } = account;

            return (
              <CustomSocialIcon
                key={`${brandAccountId}-${index}`}
                active={!!id}
                target={id ? '_blank' : '_self'}
                type={socialAccountType}
                isWarning={needReconnect}
                {...(id ? analyticsDetailsLink : {})}
                href={!id ? socialMediaUrl : undefined}
                css={{ width: '32px', height: '28px' }}
                help={needReconnect ? t('Need Reconnect') : capitalizeText(socialMedia)}
                onClick={(e) => {
                  if (needReconnect) {
                    e.preventDefault();

                    onReconnectAccount(account);
                  }
                }}
              />
            );
          })}
        </div>,
        <TableCell.Link
          to="/advertiser/$id"
          params={{ id: advertiser?.id.toString() }}
          label={advertiser?.name || ''}
          disabled={hideEditAdvertiserBtn}
        />,
        <TableCell.Text value={t(ANALYTICS_SUBSCRIPTION_TRANSLATION_KEYS[subscriptionPlan])} />,
        <TableCell.Text value={accountManagers?.map(({ name }) => name)?.join(', ')} />,
        <TableCell.Text value={advertiser?.country ? tc(advertiser.country.name) : ''} />
      ];
    }
  );

  return { rows, columns };
};
