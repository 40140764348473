import { useTranslation } from 'react-i18next';
import { RangeSliderInputField } from '@/shared/molecules';
import { MIN_ENGAGEMENT_RATE, MAX_ENGAGEMENT_RATE, MIN_MAX_EN_RATE_OPTIONS } from '@/shared/constants';
import { InfluencersFilterFormKeys } from '../types';

export const EngagementRateField = () => {
  const { t } = useTranslation();

  return (
    <RangeSliderInputField<InfluencersFilterFormKeys>
      step={0.1}
      options={MIN_MAX_EN_RATE_OPTIONS}
      title={t('General.Engagement Rate')}
      range={{ min: MIN_ENGAGEMENT_RATE, max: MAX_ENGAGEMENT_RATE }}
      minField={{ placeholder: `${MIN_ENGAGEMENT_RATE}%`, name: 'minEngagementRate' }}
      maxField={{ placeholder: `${MAX_ENGAGEMENT_RATE}%`, name: 'maxEngagementRate' }}
    />
  );
};
