import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllPayments.graphql';
import { Query, QueryallPaymentsArgs } from '../../__generated__/globalTypes';

export const useAllPaymentsQuery = (options?: QueryHookOptions<Query, QueryallPaymentsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallPaymentsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllPaymentsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallPaymentsArgs>
): QueryResult<Query, QueryallPaymentsArgs> & {
  getAllPayments: LazyQueryExecFunction<Query, QueryallPaymentsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllPayments, result] = useLazyQuery<Query, QueryallPaymentsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllPayments, ...result };
};

export const useAllPaymentsPromiseQuery = () => {
  const client = useApolloClient();

  const getAllPayments = useCallback(
    (options?: Omit<QueryOptions<QueryallPaymentsArgs, Query>, 'query'>) =>
      client.query<Query, QueryallPaymentsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllPayments };
};
