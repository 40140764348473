import {
  namedOperations,
  useInfluencerAddYoutubeCompareAccountAnalyticsMutation,
  useInfluencerDeleteYoutubeCompareAccountAnalyticsMutation
} from '@/graphql';
import { useQueryHelper, useSelectItem } from '@/shared/hooks';
import { InfluencerYoutubeComparableAccountType } from '../../types';

interface Props {
  mainAccountId: number;
}

export const useCreateDeleteInfluencerYoutubeComparableAccount = ({ mainAccountId }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const {
    selectedItem: deletingAccount,
    setSelectedItem: setDeletingAccount,
    resetSelectedItem: resetDeletingAccount
  } = useSelectItem<InfluencerYoutubeComparableAccountType | null>();
  const { callInfluencerAddYoutubeCompareAccountAnalytics, loading: adding } =
    useInfluencerAddYoutubeCompareAccountAnalyticsMutation({
      refetchQueries: [namedOperations.Query.InfluencerYoutubeAnalyticsCompareAccountCards]
    });
  const { callInfluencerDeleteYoutubeCompareAccountAnalytics, loading: deleting } =
    useInfluencerDeleteYoutubeCompareAccountAnalyticsMutation({
      refetchQueries: [namedOperations.Query.InfluencerYoutubeAnalyticsCompareAccountCards]
    });

  const handleAddNewComparableAccount = async (url: string, callback?: () => void) => {
    if (!url) return;

    try {
      await callInfluencerAddYoutubeCompareAccountAnalytics({
        variables: { input: { url, socialAccountId: mainAccountId } }
      });

      callback?.();
      enqueueSnackbar(t('succeededInCreating', { name: url }), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message || 'failedToCreate'), { variant: 'error' });
    }
  };

  const handleDeleteComparableAccount = async () => {
    if (deletingAccount) {
      try {
        await callInfluencerDeleteYoutubeCompareAccountAnalytics({
          variables: {
            input: { compareAccountId: deletingAccount.id, socialAccountId: mainAccountId }
          }
        });
        enqueueSnackbar(t('succeededInDeleting', { name: deletingAccount.username }), { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(t(error.message || 'failedToDelete'), { variant: 'error' });
      } finally {
        setDeletingAccount(null);
      }
    }
  };

  return {
    adding,
    deleting,
    deletingAccount,
    setDeletingAccount,
    resetDeletingAccount,
    handleAddNewComparableAccount,
    handleDeleteComparableAccount
  };
};
