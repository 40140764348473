import { TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { Highcharts } from '@/shared/molecules';
import { formatPercent, getGrowthArrow, bigIntFormatter, getGrowthPrefix, intlNumberFormat } from '@/shared/utils';
import { AverageEngagementType } from './types';

export interface DefaultTemplateProps extends Pick<Highcharts.ChartOptions, 'width'> {
  data: AverageEngagementType[];
}
export const DefaultTemplate = ({ data, width }: DefaultTemplateProps) => (
  <div css={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
    {data.map(({ label, value, icon, growth, isPercentage, css }, index) => {
      const formattedValue = isPercentage ? formatPercent(value) : bigIntFormatter(value, 0);
      const formattedValueTitle = isPercentage ? formattedValue : intlNumberFormat(value);

      return (
        <div
          key={index}
          css={{
            flex: 1,
            display: 'flex',
            borderTop: THEME.border.base,
            padding: '8px',
            gap: '8px',
            alignItems: 'center'
          }}
        >
          {icon && <img src={icon} css={[{ width: '24px', height: '24px' }, css]} alt="" />}
          <div
            css={{
              flex: 1,
              minWidth: 0 // For text truncation
            }}
          >
            <TextCutter
              lines={2}
              text={label}
              css={{
                fontWeight: 400,
                fontSize: '11px',
                lineHeight: 1.2,
                maxWidth: '90%',
                wordBreak: 'break-word'
              }}
            />
          </div>
          <div css={{ textAlign: 'right' }}>
            <p
              css={{
                fontSize: '14px',
                fontWeight: 600,
                marginBottom: '4px'
              }}
              title={formattedValueTitle}
            >
              {formattedValue}
            </p>
            {!!growth && (
              <p
                css={{
                  fontSize: '13px',
                  fontWeight: '600',
                  color: growth > 0 ? THEME.growth.positive : THEME.growth.negative
                }}
                title={intlNumberFormat(growth)}
              >
                {getGrowthPrefix(growth)}
                {bigIntFormatter(growth, 0)}&nbsp;
                {getGrowthArrow(growth)}
              </p>
            )}
          </div>
        </div>
      );
    })}
  </div>
);
