import { zodValidator } from '@tanstack/zod-adapter';
import { Outlet } from '@tanstack/react-router';
import { NetworkStatus } from '@apollo/client';
import {
  AddEngagement,
  EditEngagementCampaign,
  EditEngagementCampaignSearchSchema,
  EggPostSearchSchema,
  EngagementAddPost,
  EngagementAllInfluencersSearch,
  EngagementCampaignInfluencersSearch,
  EngagementDetails,
  EngagementDouyinInfluencersSearch,
  EngagementEditPost,
  EngagementFacebookInfluencersSearch,
  EngagementFacebookPageInfluencersSearch,
  EngagementInfluencerPackagesSearch,
  EngagementInfluencerPostsSearch,
  EngagementInfluencers,
  EngagementInstagramInfluencersSearch,
  EngagementPosts,
  EngagementProposal,
  EngagementReport,
  Engagements,
  EngagementThreadsInfluencersSearch,
  EngagementTiktokInfluencersSearch,
  EngagementTrackingReferral,
  EngagementTrackingUrl,
  EngagementTwitterInfluencersSearch,
  EngagementXhsInfluencersSearch,
  EngagementYoutubeInfluencersSearch
} from '@/pages/Engagements';
import {
  AllInfluencersSearchSchema,
  CampaignInfluencersSearchSchema,
  DouyinInfluencersSearchSchema,
  EngagementCampaignsSearchSchema,
  EngagementPostsSearchSchema,
  FacebookInfluencersSearchSchema,
  FacebookPageInfluencersSearchSchema,
  InfluencerPackagesSearchSchema,
  InfluencersPostsSearchSchema,
  InstagramInfluencersSearchSchema,
  ThreadsInfluencersSearchSchema,
  TiktokInfluencersSearchSchema,
  TwitterInfluencersSearchSchema,
  XhsInfluencersSearchSchema,
  YoutubeInfluencersSearchSchema
} from '@/shared/organisms';
import { EGG_QUERY, Query, QueryengagementArgs } from '@/graphql';
import { PermissionRoute } from '@/app-components';
import { adminStaffTalentAgencyPartnerRoles } from '@/shared/constants';
import { PostReportSearchSchema } from '@/shared/molecules';
import { PartialRouter } from '../../types';

export const EngagementRouter = {
  '/_private-routes/engagement/': [Engagements, { validateSearch: zodValidator(EngagementCampaignsSearchSchema) }],
  '/_private-routes/engagement/$id': [
    EngagementDetails,
    {
      loader: async ({ context, params }) => {
        if (!context.queryClient) {
          return {
            loading: true,
            networkStatus: NetworkStatus.loading,
            errorMessage: null,
            engagement: null,
            engagementProposal: null
          };
        }
        const { data, loading, errors, networkStatus } = await context.queryClient.query<Query, QueryengagementArgs>({
          query: EGG_QUERY,
          fetchPolicy: 'no-cache',
          variables: {
            pk: +params.id
          },
          notifyOnNetworkStatusChange: true
        });

        return {
          loading,
          networkStatus,
          errorMessage: errors?.at(0)?.message,
          engagement: data?.engagement || null,
          engagementProposal: data?.engagementProposal || null
        };
      }
    }
  ],
  '/_private-routes/engagement/add': [AddEngagement, {}],
  '/_private-routes/engagement/$id/': [
    EditEngagementCampaign,
    { validateSearch: zodValidator(EditEngagementCampaignSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer': [
    () => <PermissionRoute element={<EngagementInfluencers />} permissions={adminStaffTalentAgencyPartnerRoles} />,
    {}
  ],
  '/_private-routes/engagement/$id/influencer/': [
    EngagementAllInfluencersSearch,
    { validateSearch: zodValidator(AllInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/campaigns': [
    EngagementCampaignInfluencersSearch,
    { validateSearch: zodValidator(CampaignInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/douyin': [
    EngagementDouyinInfluencersSearch,
    { validateSearch: zodValidator(DouyinInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/facebook': [
    EngagementFacebookInfluencersSearch,
    { validateSearch: zodValidator(FacebookInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/facebook_page': [
    EngagementFacebookPageInfluencersSearch,
    { validateSearch: zodValidator(FacebookPageInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/instagram': [
    EngagementInstagramInfluencersSearch,
    { validateSearch: zodValidator(InstagramInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/packages': [
    EngagementInfluencerPackagesSearch,
    { validateSearch: zodValidator(InfluencerPackagesSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/posts': [
    EngagementInfluencerPostsSearch,
    { validateSearch: zodValidator(InfluencersPostsSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/threads': [
    EngagementThreadsInfluencersSearch,
    { validateSearch: zodValidator(ThreadsInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/tiktok': [
    EngagementTiktokInfluencersSearch,
    { validateSearch: zodValidator(TiktokInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/twitter': [
    EngagementTwitterInfluencersSearch,
    { validateSearch: zodValidator(TwitterInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/xhs': [
    EngagementXhsInfluencersSearch,
    { validateSearch: zodValidator(XhsInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/influencer/youtube': [
    EngagementYoutubeInfluencersSearch,
    { validateSearch: zodValidator(YoutubeInfluencersSearchSchema) }
  ],
  '/_private-routes/engagement/$id/posts': [
    EngagementPosts,
    { validateSearch: zodValidator(EngagementPostsSearchSchema) }
  ],
  '/_private-routes/engagement/$id/posts/': [
    () => <Outlet />,
    { validateSearch: zodValidator(EngagementPostsSearchSchema) }
  ],
  '/_private-routes/engagement/$id/posts/$postId': [EngagementEditPost, {}],
  '/_private-routes/engagement/$id/posts/add': [
    EngagementAddPost,
    { validateSearch: zodValidator(EggPostSearchSchema) }
  ],
  '/_private-routes/engagement/$id/proposal': [EngagementProposal, {}],
  '/_private-routes/engagement/$id/report': [
    EngagementReport,
    { validateSearch: zodValidator(PostReportSearchSchema) }
  ],
  '/_private-routes/engagement/$id/tracking-referral-codes': [EngagementTrackingReferral, {}],
  '/_private-routes/engagement/$id/tracking': [EngagementTrackingUrl, {}]
} as const satisfies PartialRouter;
