import { FacebookPageAnalyticsPostsPayload } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerFacebookAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useAnalyticsFacebookPostsListTable } from './hooks';

export interface AnalyticsFacebookPostsListProps extends TableData<FacebookPageAnalyticsPostsPayload> {
  mainSocialAccountId?: number;
}

export const AnalyticsFacebookPostsList = ({
  loading,
  data = [],
  mainSocialAccountId
}: AnalyticsFacebookPostsListProps) => {
  const { rows, columns } = useAnalyticsFacebookPostsListTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />

      <InfluencerFacebookAnalyticsPostDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId }))}
        isAnalytics
      />
    </>
  );
};
