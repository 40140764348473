import { PostDetailsType } from '@/shared/types';
import { InfluencerTwitterAnalyticsPostDetails } from '../../PostDetails';
import { CarouselTemplateModal } from '../CarouselTemplateModal';

export interface InfluencerTwitterAnalyticsPostDetailsModalProps {
  isAnalytics?: boolean;
  posts: PostDetailsType[];
}

export const InfluencerTwitterAnalyticsPostDetailsModal = ({
  posts,
  isAnalytics
}: InfluencerTwitterAnalyticsPostDetailsModalProps) => (
  <CarouselTemplateModal posts={posts}>
    {({ selectedPost, closeModal }) => (
      <InfluencerTwitterAnalyticsPostDetails
        id={selectedPost.id}
        onCloseModal={closeModal}
        isAnalytics={isAnalytics}
        mainSocialAccountId={selectedPost?.mainSocialAccountId}
      />
    )}
  </CarouselTemplateModal>
);
