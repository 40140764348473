import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UpdateCmsChannelShareRate.graphql';
import { Mutation, MutationupdateCmsChannelShareRateArgs } from '../../__generated__/globalTypes';

export const useUpdateCmsChannelShareRateMutation = (
  options?: MutationHookOptions<Mutation, MutationupdateCmsChannelShareRateArgs>
) => {
  const [callUpdateCmsChannelShareRate, result] = useMutation<Mutation, MutationupdateCmsChannelShareRateArgs>(
    MUTATION,
    options
  );

  return { callUpdateCmsChannelShareRate, ...result };
};

export type UpdateCmsChannelShareRateMutationFunction = Mutation['updateCmsChannelShareRate'];
