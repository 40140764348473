import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { SingleDatePickerField, SingleDatePickerFieldProps } from '@/shared/molecules';
import { MarketplaceFormKeys } from '../types';

export const PreLaunchDateField = <T extends string = keyof typeof MarketplaceFormKeys>({
  name,
  required,
  ...restProps
}: Partial<SingleDatePickerFieldProps<T>>) => {
  const { t } = useTranslation();

  return (
    <SingleDatePickerField<T>
      help={t('Tooltip.preLaunchDateHelp')}
      title={
        <p css={{ width: '100%', display: 'flex', paddingRight: '4px', justifyContent: 'space-between' }}>
          <span>
            {t('Pre-launch Date')}
            {required ? <span css={{ color: 'tomato' }}>&nbsp;*</span> : null}
          </span>
          {!required ? <span css={{ color: THEME.text.colors.gray.lv3 }}>({t('Optional')})</span> : null}
        </p>
      }
      disabledDays={{ before: new Date() }}
      name={name || (MarketplaceFormKeys.preLaunchDate as T)}
      {...restProps}
    />
  );
};
