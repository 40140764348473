import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllInstagramAmbassadorPosts.graphql';
import { Query, QueryallInstagramAmbassadorPostsArgs } from '../../__generated__/globalTypes';

export const useAllInstagramAmbassadorPostsQuery = (
  options?: QueryHookOptions<Query, QueryallInstagramAmbassadorPostsArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallInstagramAmbassadorPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllInstagramAmbassadorPostsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallInstagramAmbassadorPostsArgs>
): QueryResult<Query, QueryallInstagramAmbassadorPostsArgs> & {
  getAllInstagramAmbassadorPosts: LazyQueryExecFunction<Query, QueryallInstagramAmbassadorPostsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllInstagramAmbassadorPosts, result] = useLazyQuery<Query, QueryallInstagramAmbassadorPostsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getAllInstagramAmbassadorPosts, ...result };
};

export const useAllInstagramAmbassadorPostsPromiseQuery = () => {
  const client = useApolloClient();

  const getAllInstagramAmbassadorPosts = useCallback(
    (options?: Omit<QueryOptions<QueryallInstagramAmbassadorPostsArgs, Query>, 'query'>) =>
      client.query<Query, QueryallInstagramAmbassadorPostsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllInstagramAmbassadorPosts };
};
