import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/AllEngagementsForInsightCheck.graphql';
import { Query, QueryallEngagementsForInsightCheckArgs } from '../../__generated__/globalTypes';

export const useAllEngagementsForInsightCheckQuery = (
  options?: QueryHookOptions<Query, QueryallEngagementsForInsightCheckArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QueryallEngagementsForInsightCheckArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useAllEngagementsForInsightCheckLazyQuery = (
  options?: LazyQueryHookOptions<Query, QueryallEngagementsForInsightCheckArgs>
): QueryResult<Query, QueryallEngagementsForInsightCheckArgs> & {
  getAllEngagementsForInsightCheck: LazyQueryExecFunction<Query, QueryallEngagementsForInsightCheckArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getAllEngagementsForInsightCheck, result] = useLazyQuery<Query, QueryallEngagementsForInsightCheckArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getAllEngagementsForInsightCheck, ...result };
};

export const useAllEngagementsForInsightCheckPromiseQuery = () => {
  const client = useApolloClient();

  const getAllEngagementsForInsightCheck = useCallback(
    (options?: Omit<QueryOptions<QueryallEngagementsForInsightCheckArgs, Query>, 'query'>) =>
      client.query<Query, QueryallEngagementsForInsightCheckArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getAllEngagementsForInsightCheck };
};
