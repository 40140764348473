import { Trans } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useToggleState } from '@/shared/hooks';
import { namedOperations, useApproveCmsInvoiceMutation } from '@/graphql';
import { Button, Modal, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { useInvoicesFilter } from '../InvoicesFilter';

interface ApproveButtonProps {
  hasNoData?: boolean;
}

export const ApproveButton = ({ hasNoData }: ApproveButtonProps) => {
  const modal = useToggleState();
  const { filter } = useInvoicesFilter();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { callApproveCmsInvoice, loading } = useApproveCmsInvoiceMutation({
    refetchQueries: [namedOperations.Query.YoutubeCmsInvoiceList]
  });

  const handleApprove = async () => {
    try {
      await callApproveCmsInvoice({ variables: { input: { month: filter.month } } });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      modal.close();
    }
  };

  return (
    <>
      <Button
        variant="blue"
        onClick={modal.open}
        disabled={hasNoData}
        title={t('Button.Approve')}
        css={{ padding: '0 24px', minWidth: '142px' }}
      />

      <Modal open={modal.status} onClose={modal.close} css={{ width: THEME.modal.size.lv1 }}>
        <ModalContent>
          <ModalTitle>{t('Invoice Export')}</ModalTitle>
          <p css={{ marginTop: '24px', fontSize: '14px', lineHeight: '140%' }}>
            <Trans i18nKey="Modal.approveInvoiceDescription" components={{ br: <br /> }} />
          </p>
        </ModalContent>
        <ModalFooterActions
          cancelButtonProps={{ onClick: modal.close }}
          submitButtonProps={{
            onClick: handleApprove,
            title: t('Button.Approve'),
            loading: { status: loading, showIcon: true }
          }}
        />
      </Modal>
    </>
  );
};
