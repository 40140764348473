import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/SwitchRoleToAgency.graphql';
import { Mutation, MutationswitchRoleToAgencyArgs } from '../../__generated__/globalTypes';

export const useSwitchRoleToAgencyMutation = (
  options?: MutationHookOptions<Mutation, MutationswitchRoleToAgencyArgs>
) => {
  const [callSwitchRoleToAgency, result] = useMutation<Mutation, MutationswitchRoleToAgencyArgs>(MUTATION, options);

  return { callSwitchRoleToAgency, ...result };
};

export type SwitchRoleToAgencyMutationFunction = Mutation['switchRoleToAgency'];
