import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateAgencyWithUser.graphql';
import { Mutation, MutationcreateAgencyWithUserArgs } from '../../__generated__/globalTypes';

export const useCreateAgencyWithUserMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateAgencyWithUserArgs>
) => {
  const [callCreateAgencyWithUser, result] = useMutation<Mutation, MutationcreateAgencyWithUserArgs>(MUTATION, options);

  return { callCreateAgencyWithUser, ...result };
};

export type CreateAgencyWithUserMutationFunction = Mutation['createAgencyWithUser'];
