import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/RequestPackageProposalExcel.graphql';
import { Mutation, MutationrequestPackageProposalExcelArgs } from '../../__generated__/globalTypes';

export const useRequestPackageProposalExcelMutation = (
  options?: MutationHookOptions<Mutation, MutationrequestPackageProposalExcelArgs>
) => {
  const [callRequestPackageProposalExcel, result] = useMutation<Mutation, MutationrequestPackageProposalExcelArgs>(
    MUTATION,
    options
  );

  return { callRequestPackageProposalExcel, ...result };
};

export type RequestPackageProposalExcelMutationFunction = Mutation['requestPackageProposalExcel'];
