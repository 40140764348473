import { linkOptions } from '@tanstack/react-router';
import { usePermissionChecker } from '@/auth';
import { ParamsSearchProps } from './useParamsSearch';

interface Props {
  disabled?: boolean;
}

export const useGenerateInfluencerProfileLink = ({ disabled }: Props = {}) => {
  const isBlockedProfileLink = usePermissionChecker('InfluencerProfileLink');

  const generateProfileLink = (id?: string | number | null, search?: ParamsSearchProps) => {
    const profileDisabled = isBlockedProfileLink || !!disabled || !id;

    return {
      disabled: profileDisabled,
      target: '_blank', // always into the new tab - https://adasiaholdings.atlassian.net/browse/AT-6017
      ...(id !== undefined && id !== null
        ? linkOptions({
            to: '/influencer/$id/profile',
            params: { id: id.toString() },
            search
          })
        : {})
    };
  };

  return { generateProfileLink };
};
