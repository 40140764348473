import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { ButtonLink } from '@/shared/atoms';
import { Header, ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useCustomHeader, useUpdateDocumentTitle } from '@/shared/hooks';
import { TiktokBusinessWarningStatus, useAllTiktokSpecialCampaignsQuery } from '@/graphql';
import {
  TikTokCampaignsList,
  TikTokCampaignsFilter,
  useTikTokCampaignsFilter,
  TikTokCampaignsPlansModal,
  TikTokSummaryDataContext
} from '@/shared/organisms';
import { CreateCampaignFailedModal } from './CreateCampaignFailedModal';

export const TikTokCampaigns = ({ summaryData }: Pick<TikTokSummaryDataContext, 'summaryData'>) => {
  useCustomHeader();
  useUpdateDocumentTitle({
    title: 'documentTitle.TikTok Campaigns',
    appHeader: 'appHeader.TikTok Campaigns'
  });
  const { t } = useTranslation();
  const { isAdvertiserFromTikTok } = useUserRoles();
  const { page, limit, filter } = useTikTokCampaignsFilter();

  const needToSubscribeMkpPlan = summaryData?.warningsList.includes(TiktokBusinessWarningStatus.MKP_CAMPAIGN_DISABLE);

  const { data, previousData, loading, refetch } = useAllTiktokSpecialCampaignsQuery({
    skip: needToSubscribeMkpPlan,
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      ...filter,
      status: filter.status || null
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allTiktokSpecialCampaignsCount?.totalNumber;

  return (
    <>
      <Header>
        {isAdvertiserFromTikTok && (
          <ButtonLink
            variant="blue"
            title={t('Button.Add Campaign')}
            to="/tiktok/campaigns/add"
            prefixIcon={{ icon: 'plus', size: '10px' }}
            css={{ minWidth: '143px', marginLeft: 'auto' }}
          />
        )}
      </Header>

      <ListWithPagination pagination={{ totalRecords }}>
        <TikTokCampaignsFilter />
        <TikTokCampaignsList loading={loading} data={result?.allTiktokSpecialCampaigns || []} />
      </ListWithPagination>

      <TikTokCampaignsPlansModal open={needToSubscribeMkpPlan} />

      <CreateCampaignFailedModal onRefetchCampaignsList={refetch} />
    </>
  );
};
