import { useParams } from '@tanstack/react-router';
import {
  PackageContentFilter,
  PackageContentList,
  PackageSummary,
  usePackageProposalContext
} from '@/shared/organisms';
import { ListWithPagination } from '@/shared/molecules';

export const PackageContentListDetails = () => {
  const params = useParams({ from: '/_private-routes/package/$id/content/list' });
  const { packageProposal, proposalSummary, loading, filter, setFilter } = usePackageProposalContext();
  const packageId = params.id;

  return (
    <>
      <PackageSummary proposalSummary={proposalSummary} packageId={Number(packageId)} />
      <ListWithPagination pagination={{ totalRecords: packageProposal?.totalCount || 0 }}>
        <PackageContentFilter filter={filter} setFilter={setFilter} loading={loading} />
        <PackageContentList packageId={Number(packageId)} data={packageProposal?.influencers || []} loading={loading} />
      </ListWithPagination>
    </>
  );
};
