import { YoutubeAnalyticsShortsPayload } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerYoutubeAnalyticsPostDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useInfluencerYoutubeShortsListTable } from './hooks';

export interface InfluencerYoutubeShortsListProps extends TableData<YoutubeAnalyticsShortsPayload> {
  mainSocialAccountId?: number;
  isAnalytics?: boolean;
}

export const InfluencerYoutubeShortsList = ({
  loading,
  data = [],
  isAnalytics,
  mainSocialAccountId
}: InfluencerYoutubeShortsListProps) => {
  const { rows, columns } = useInfluencerYoutubeShortsListTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '57px' }} noTopBorderRadius hasBorder />

      <InfluencerYoutubeAnalyticsPostDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId }))}
        isAnalytics={isAnalytics}
      />
    </>
  );
};
