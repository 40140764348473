import { SerializedStyles } from '@emotion/react';
import { ReactNode } from 'react';
import { RefCallBack, SetValueConfig, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiSelector, MultiSelectorProps, MultiSelectorWithSubmenu } from '../Selector';
import { MenuType } from '../Selector/BaseSelector';

export interface Option {
  label: string;
  value: string;
}

export interface MultiSelectFieldProps extends Omit<MultiSelectorProps, 'onChange' | 'onClose'> {
  isAlwaysOpen?: boolean;
  subSelector?: ReactNode;
  setFieldValue: UseFormSetValue<Record<string, any>>;
  handleSubmit?: () => void;
  initialValues?: string[];
  menuCss?: SerializedStyles;
  setValueOptions?: SetValueConfig;
  onClickInputBox?: (menu: MenuType) => void;
  innerRef?: RefCallBack;
}

export const MultiSelectField = (props: MultiSelectFieldProps) => {
  const { t } = useTranslation();
  const {
    name,
    placeholder,
    isAlwaysOpen,
    value,
    options,
    hasError,
    subSelector,
    hasSelectAll,
    handleSubmit,
    setFieldValue,
    canOnlyAddMore,
    initialValues,
    menuCss,
    setValueOptions,
    loading,
    onClickInputBox,
    menuItemsProps
  } = props;
  const disabled = props.disabled || options.length === 0;
  const customPlaceholder = disabled ? '' : placeholder ?? t('Selector.Please Select');

  const handleSetValues = (items: string[]) => {
    setFieldValue(name, items, setValueOptions);
  };
  const handleClose = async () => {
    if (handleSubmit) {
      await Promise.resolve();
      handleSubmit();
    }
  };

  return menuItemsProps?.type === 'checkbox-with-submenu' ? (
    <MultiSelectorWithSubmenu
      loading={loading}
      options={options}
      name={name}
      disabled={disabled}
      placeholder={customPlaceholder}
      value={value}
      hasError={hasError}
      isAlwaysOpen={isAlwaysOpen}
      hasSelectAll={hasSelectAll}
      onChange={handleSetValues}
      onClose={handleClose}
      onClickInputBox={onClickInputBox}
      canOnlyAddMore={canOnlyAddMore}
      initialValues={initialValues}
      menuCss={menuCss}
      subSelector={subSelector}
      menuItemsProps={menuItemsProps}
    />
  ) : (
    <MultiSelector
      loading={loading}
      options={options}
      name={name}
      disabled={disabled}
      placeholder={customPlaceholder}
      value={value}
      hasError={hasError}
      isAlwaysOpen={isAlwaysOpen}
      hasSelectAll={hasSelectAll}
      onChange={handleSetValues}
      onClose={handleClose}
      onClickInputBox={onClickInputBox}
      canOnlyAddMore={canOnlyAddMore}
      initialValues={initialValues}
      menuCss={menuCss}
      subSelector={subSelector}
      menuItemsProps={menuItemsProps}
    />
  );
};

MultiSelectField.displayName = 'MultiSelectField';
