import IcoMoon, { IconProps as DefaultProps } from 'react-icomoon';
import { IconNames, icoMoonSet, chatDemoIcoMoonSet } from '@/shared/assets';

type IconProps = DefaultProps & { icon: IconNames | '' };
export const Icon = (props: IconProps) => (
  <IcoMoon
    iconSet={{ icons: [...icoMoonSet.icons, ...chatDemoIcoMoonSet.icons] }} // TODO DEMO: remove later
    css={{ flex: 'none' }}
    {...props}
    className={props.className}
  />
);

export type { IconProps };
