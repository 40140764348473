import { FilterProvider } from '@/shared/molecules';
import { useInfluencerPostsContext } from '../InfluencerPostsContext';
import { BasicFilter } from './BasicFilter';

export const InfluencerPostsFilter = () => {
  const { filter, setFilter, isEngagementPage } = useInfluencerPostsContext();

  return (
    <FilterProvider
      onSubmit={setFilter}
      initialValues={filter}
      basicFilter={<BasicFilter isEngagementPage={isEngagementPage} />}
    />
  );
};
