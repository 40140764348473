import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';

export interface ActionButtonsProps {
  loading?: boolean;
  className?: string;
  children?: ReactNode;
  buttonHeight?: string;
  hasTopBorder?: boolean;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
}
export const ActionButtons = ({
  loading,
  children,
  className,
  hasTopBorder = false,
  buttonHeight = '24px',
  primaryButtonProps,
  secondaryButtonProps
}: ActionButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={className}
      css={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '16px',
        gap: '8px',
        borderTop: hasTopBorder ? THEME.border.base : 'unset',
        alignItems: 'center'
      }}
    >
      {children}
      <Button
        variant="white"
        title={t('Button.Cancel')}
        css={{ minWidth: '90px', height: buttonHeight }}
        {...secondaryButtonProps}
      />
      <Button
        variant="blue"
        title={t('Button.Apply')}
        loading={loading}
        css={{ minWidth: '90px', height: buttonHeight }}
        {...primaryButtonProps}
      />
    </div>
  );
};
