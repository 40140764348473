import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/AddInstagramHashtagAnalytics.graphql';
import { Mutation, MutationaddInstagramHashtagAnalyticsArgs } from '../../__generated__/globalTypes';

export const useAddInstagramHashtagAnalyticsMutation = (
  options?: MutationHookOptions<Mutation, MutationaddInstagramHashtagAnalyticsArgs>
) => {
  const [callAddInstagramHashtagAnalytics, result] = useMutation<Mutation, MutationaddInstagramHashtagAnalyticsArgs>(
    MUTATION,
    options
  );

  return { callAddInstagramHashtagAnalytics, ...result };
};

export type AddInstagramHashtagAnalyticsMutationFunction = Mutation['addInstagramHashtagAnalytics'];
